import { useCallback, useMemo, useState } from 'react'
import {
  SidebarPanelRoot,
  SimulationConfigPanel,
  ProspectSimulationEndDateConfigPanel,
  SidebarHeaderArrowIcon,
  SidebarPanelHeader,
  SidebarPanelHeaderIconButton,
  SidebarPanelTitle,
  SidebarPanelMainArea,
} from '.'
import { intl } from '../../../../../i18n'
import { BurndownChartSimulationVariables } from '../../model'
import { UpdateProspectSimulationVariables } from '../../hooks/simulationVariables'

type ActualGraphConfigPanelProps = Pick<
  BurndownChartSimulationVariables,
  'prospectVelocity' | 'prospectResourceRate'
> &
  UpdateProspectSimulationVariables & {
    deadlineDate: Date | undefined
    updateVelocity: (v: number) => void
    updateEndDate: (v: Date) => void
  }

export const ActualGraphSimulationPanel = ({
  prospectVelocity,
  prospectResourceRate,
  updateProspectVelocity,
  updateProspectResourceRate,
  resetProspectVelocity,
  resetProspectResourceRate,
  deadlineDate,
  updateVelocity,
  updateEndDate,
}: ActualGraphConfigPanelProps) => {
  const [velocityLabel, resourceRateLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.simulation.velocity' }),
      intl.formatMessage({
        id: 'progressReport.simulation.resourceDevotionRate',
      }),
    ],
    []
  )
  const [open, setOpen] = useState<boolean>(true)
  const toggle = useCallback(() => setOpen(prev => !prev), [])

  return (
    <SidebarPanelRoot>
      <SidebarPanelHeader>
        <SidebarPanelTitle>完了シミュレーション</SidebarPanelTitle>
        <SidebarPanelHeaderIconButton onClick={toggle}>
          <SidebarHeaderArrowIcon open={open} />
        </SidebarPanelHeaderIconButton>
      </SidebarPanelHeader>
      <SidebarPanelMainArea in={open}>
        <SimulationConfigPanel
          label={velocityLabel}
          value={prospectVelocity}
          update={updateProspectVelocity}
          max={2}
          onReset={resetProspectVelocity}
          onChangeCommited={updateVelocity}
        />
        <SimulationConfigPanel
          label={resourceRateLabel}
          value={prospectResourceRate}
          update={updateProspectResourceRate}
          max={2}
          onReset={resetProspectResourceRate}
        />
        <ProspectSimulationEndDateConfigPanel
          value={deadlineDate}
          update={updateEndDate}
          // onReset={resetSimulatedSimulationFromDate}
        />
      </SidebarPanelMainArea>
    </SidebarPanelRoot>
  )
}
