import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { AggregateTargetUnit } from '../../../../../../lib/functions/wbsProgressLog'
import objects from '../../../../../../utils/objects'
import { FontSize } from '../../../../../../styles/commonStyles'
import {
  getTimeGrainLabel,
  TimeGrain,
  ViewConfigurationBase,
} from '../../../../../../lib/functions/report'
import ExclusiveToggleGroup from '../../../../../components/editors/toggle/ExclusiveToggleGroup'
import { AggregateField } from '../../../../../../domain/entity/WbsItemEntity'
import { styled } from '@mui/system'

// Styles
const RootDiv = styled('div')<{ direction?: string }>(({ direction }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: direction === 'column' ? 'column' : 'row',
  }
})
const ViewConfigurationRootDiv = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const ViewConfiguration = styled('div')<{ direction?: string }>(
  ({ direction }) => {
    return {
      width: '100%',
      display: 'flex',
      flexDirection: direction === 'column' ? 'column' : 'row',
    }
  }
)
const ViewConfigurationItem = styled('div')<{ direction?: string }>(
  ({ direction }) => {
    return {
      width: '100%',
      display: 'flex',
      margin: direction === 'column' ? '0 0 4px' : undefined,
    }
  }
)
const ViewConfigurationItemLabel = styled('span')({
  fontSize: FontSize.SMALL,
})

export interface WbsProgressLogViewConfiguration extends ViewConfigurationBase {
  aggregateTargetType: AggregateField
  aggregateTargetUnit: AggregateTargetUnit
  keyGroup?: string[]
}

export enum WbsProgressLogViewConfigurationKey {
  AGGREGATE_TARGET,
  TIME_GRAIN,
}

export interface Props extends WrappedComponentProps {
  keys: WbsProgressLogViewConfigurationKey[]
  configuration: WbsProgressLogViewConfiguration
  onChange(config: WbsProgressLogViewConfiguration): void
  direction?: 'row' | 'column'
  displayLabel?: boolean
}

class ReportViewConfiguration extends React.Component<Props> {
  private renderTitle = (
    key: WbsProgressLogViewConfigurationKey
  ): JSX.Element => {
    switch (key) {
      case WbsProgressLogViewConfigurationKey.AGGREGATE_TARGET:
        return (
          <ViewConfigurationItemLabel>
            {this.props.intl.formatMessage({
              id: `progressReport.aggregateTarget`,
            })}
          </ViewConfigurationItemLabel>
        )
      case WbsProgressLogViewConfigurationKey.TIME_GRAIN:
        return (
          <ViewConfigurationItemLabel>
            {this.props.intl.formatMessage({
              id: `progressReport.timeGrain`,
            })}
          </ViewConfigurationItemLabel>
        )
    }
    return <></>
  }
  private renderContent = (key: WbsProgressLogViewConfigurationKey) => {
    const { direction } = this.props
    const onChange = (key: string, value: any) => {
      const newConfiguration = { ...this.props.configuration }
      objects.setValue(newConfiguration, key, value)
      this.props.onChange(newConfiguration)
    }
    switch (key) {
      case WbsProgressLogViewConfigurationKey.AGGREGATE_TARGET:
        const typeOptions = Object.values(AggregateField).map(v => ({
          label: this.props.intl.formatMessage({
            id: `progressReport.${v}`,
          }),
          value: v,
        }))
        const unitOptions = Object.values(AggregateTargetUnit).map(v => ({
          label: this.props.intl.formatMessage({
            id: `progressReport.${v}`,
          }),
          value: v,
        }))
        return (
          <>
            <ViewConfigurationItem direction={direction}>
              <ExclusiveToggleGroup
                value={this.props.configuration.aggregateTargetType}
                options={typeOptions}
                onChange={v => onChange('aggregateTargetType', v)}
              />
            </ViewConfigurationItem>
            <ViewConfigurationItem direction={direction}>
              <ExclusiveToggleGroup
                value={this.props.configuration.aggregateTargetUnit}
                options={unitOptions}
                onChange={v => onChange('aggregateTargetUnit', v)}
                disabled={
                  this.props.configuration.aggregateTargetType ===
                  AggregateField.WBS_ITEM_COUNT
                }
              />
            </ViewConfigurationItem>
          </>
        )

      case WbsProgressLogViewConfigurationKey.TIME_GRAIN:
        const timeGrainOptions = Object.values(TimeGrain).map(v => ({
          label: getTimeGrainLabel(v),
          value: v,
        }))
        return (
          <ViewConfigurationItem direction={direction}>
            <ExclusiveToggleGroup
              value={this.props.configuration.timeGrain}
              options={timeGrainOptions}
              onChange={v => onChange('timeGrain', v)}
            />
          </ViewConfigurationItem>
        )
    }
  }
  render() {
    const { direction, displayLabel, keys } = this.props
    return (
      <RootDiv direction={direction}>
        {keys.map((k, i) => (
          <ViewConfigurationRootDiv key={'configRoot' + i}>
            {displayLabel && this.renderTitle(k)}
            <ViewConfiguration direction={direction}>
              {this.renderContent(k)}
            </ViewConfiguration>
          </ViewConfigurationRootDiv>
        ))}
      </RootDiv>
    )
  }
}

export default injectIntl(ReportViewConfiguration)
