import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { EnterpriseReportRepository } from '../../applications/ports/enterpriseReportRepository'
import { ProjectReportVO } from '../../domain/value-object/ProjectReportVO'

export const useEnterpriseRepositoryAdaptor =
  (): EnterpriseReportRepository => {
    const getEnterpriseReport = useCallback(async () => {
      const { json } = await api.functional.request(
        'GET',
        '/api/v1/enterprise/report',
        undefined,
        true
      )
      return json.map(
        res =>
          ({
            ...res,
            basic: {
              ...res.basic,
              scheduledStartDate: new Date(res.basic.scheduledStartDate),
              scheduledEndDate: new Date(res.basic.scheduledEndDate),
            },
          } as ProjectReportVO)
      )
    }, [])
    return { getEnterpriseReport }
  }
