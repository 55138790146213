import { useCallback, useEffect, useState } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { ClickAwayListener } from '@mui/material'
import { styled } from '@mui/system'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import { muiTheme } from '../../../../styles/muiTheme'

const theme = muiTheme
const RootNav = styled('nav')<{ open: boolean; anchor: string }>(
  ({ open, anchor }) => {
    let style: any = {
      display: 'flex',
      position: 'relative',
    }
    if (open) {
      style = {
        ...style,
        flexShrink: 0,
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    } else {
      style = {
        ...style,
        width: 0,
        flexShrink: 0,
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      }
    }
    if (anchor === 'left') {
      style = {
        ...style,
        flexDirection: 'row',
        float: 'left',
      }
    } else {
      style = {
        ...style,
        flexDirection: 'row-reverse',
        float: 'right',
        left: 'auto',
        right: 0,
        top: '60px',
      }
    }

    return style
  }
)
const StyledMuiDrawer = styled(MuiDrawer)({
  width: '100%',
})
const DrawerContent = styled('div')({
  height: '100%',
})
const Dragger = styled('div')({
  width: '5px',
  cursor: 'ew-resize',
  position: 'absolute',
  top: 0,
  bottom: 0,
  zIndex: 100,
})

interface ComponentOwnProps {
  open: boolean
  onClose?: () => void
  defaultWidth: number
  anchor: 'right' | 'left' // TODO: Consider adding 'top' and 'bottom'
  elevation?: boolean
  isResizable?: boolean
  minWidth?: number
  maxWidth?: number
  depth?: number
  closeOnClickAway?: boolean
  toggleTooltip?: string
  onChangeWidth?: (width: number) => void
}

interface StateProps {
  currentDepth: number
}

const Drawer = (
  props: React.PropsWithChildren<ComponentOwnProps> & StateProps
) => {
  const {
    children,
    open,
    onClose,
    defaultWidth,
    anchor,
    elevation,
    isResizable,
    minWidth,
    maxWidth,
    closeOnClickAway,
    onChangeWidth,
    depth,
    currentDepth,
  } = props
  const [rendered, setRendered] = useState<boolean>(false)
  const [width, setWidth] = useState<number>(240)
  const [isResized, setIsResized] = useState<boolean>(false)
  useEffect(() => {
    setWidth(defaultWidth)
    setRendered(true)
    setIsResized(false)
  }, [defaultWidth])

  const handleMouseDown = useCallback(e => {
    setIsResized(true)
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
    e.target.classList.add('resizable-cursor')
  }, [])

  const handleMouseUp = useCallback(e => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
    let elements = document.getElementsByClassName('resizable-cursor')
    while (elements.length > 0) {
      elements[0].classList.remove('resizable-cursor')
    }
  }, [])

  const handleMouseMove = useCallback(
    e => {
      const newWidth =
        anchor === 'left'
          ? e.clientX - document.body.offsetLeft
          : document.body.offsetWidth - (e.clientX - document.body.offsetLeft)

      if (
        (!minWidth || newWidth > minWidth) &&
        (!maxWidth || newWidth < maxWidth)
      ) {
        setWidth(newWidth)
        if (onChangeWidth) {
          onChangeWidth(newWidth)
        }
      }
      e.target.classList.add('resizable-cursor')
      e.preventDefault()
    },
    [minWidth, maxWidth]
  )

  const drawerWidth = open ? width : 0
  const onClickAway = () => {
    if (!closeOnClickAway) {
      return
    }
    // In order not to close when user acts on the other layer.
    if (currentDepth !== (depth || 0)) {
      return
    }
    if (isResized) {
      setIsResized(false)
      return
    }
    onClose && onClose()
  }

  if (!rendered) {
    return <></>
  }
  return (
    <RootNav
      style={{ width: elevation ? 0 : drawerWidth }}
      open={open}
      anchor={anchor}
    >
      <StyledMuiDrawer
        variant="persistent"
        anchor={anchor}
        open={open}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          style: {
            top: 'auto',
            width: drawerWidth,
            height: '100%',
            boxShadow: elevation ? '0 0 10px 1px #aaaaaa' : 'none',
            position: 'absolute',
          },
        }}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <DrawerContent>
            {children}
            {isResizable && (
              <div
                onMouseDown={e => handleMouseDown(e)}
                style={anchor === 'left' ? { right: 0 } : { left: 0 }}
              />
            )}
          </DrawerContent>
        </ClickAwayListener>
      </StyledMuiDrawer>
    </RootNav>
  )
}

const mapStateToProps = (state: AllState) => ({
  currentDepth: state.functionLayer.currentDepth,
})

export default connect(mapStateToProps)(Drawer)
