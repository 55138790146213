import { useRef, useState } from 'react'
import _ from 'lodash'
import { Avatar, Box, Popper } from '@mui/material'
import { ProjectDetail } from '../../../../lib/functions/project'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import { BackgroundColor } from '../../../../styles/commonStyles'
import ProjectMenu from '../commons/ProjectMenu'
import { colorPalette } from '../../../style/colorPallete'

type Props = {
  currentProject?: ProjectDetail
}

const mapStateToProject = (state: AllState) => ({
  currentProject: state.project.current,
})

export const ProjectIcon = connect(mapStateToProject)(
  ({ currentProject }: Props) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const delaySetOpenMenu = _.debounce(setOpenMenu, 100)
    const anchorRef = useRef<HTMLDivElement>(null)

    return (
      <Box
        onMouseEnter={() => delaySetOpenMenu(true)}
        onMouseLeave={() => delaySetOpenMenu(false)}
      >
        <Avatar
          variant="rounded"
          src={currentProject?.iconUrl}
          ref={anchorRef}
          sx={{
            margin: '8px 0px',
            height: '25px',
            width: '25px',
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.8,
            },
            border: `solid ${colorPalette.monotone[2]} 1px`,
          }}
        />
        <Popper
          open={openMenu}
          anchorEl={anchorRef.current}
          placement={'right-start'}
          modifiers={[{ name: 'offset', options: { offset: [-20, 5] } }]}
          sx={{
            paddingBottom: '10px',
            backgroundColor: BackgroundColor.WHITE,
            boxShadow:
              'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
            borderRadius: '4px',
            zIndex: 1300,
          }}
        >
          <ProjectMenu openMenu={openMenu} anchorEl={anchorRef.current} />
        </Popper>
      </Box>
    )
  }
)
