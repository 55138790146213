import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import moment from 'moment'
import { AllState } from '../../../../store'
import Auth from '../../../../lib/commons/auth'
import uiStates, {
  generateCode,
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { formatDateTime } from '../../../../utils/date'
import { generateUuid } from '../../../../utils/uuids'
import SavedUIStateEditorDialog from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateEditorDialog'
import {
  SavedUIState,
  UIState,
} from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'

export interface SaveWbsItemSearchConditionDialogProps {
  applicationFunctionUuid: string
  getCurrentSearchCondition?: () => UIState
  getColumnAndFilterState?: () => UIState
}

interface Props
  extends SaveWbsItemSearchConditionDialogProps,
    WrappedComponentProps {
  projectUuid: string
  open: boolean
  onClose: () => void
}

class SaveWbsItemSearchConditionDialog extends React.PureComponent<Props> {
  constructor(props) {
    super(props)
  }

  private getUIStateKey = (scope: UiStateScope): string => {
    if (!this.props.projectUuid || scope === UiStateScope.CrossProject) {
      return UiStateKey.BulkSheetUIStateSearchCondition
    }
    return `${UiStateKey.BulkSheetUIStateSearchCondition}-${this.props.projectUuid}`
  }

  private getUIStates = async (
    scope: UiStateScope
  ): Promise<SavedUIState[]> => {
    const response = await uiStates.get({
      applicationFunctionUuid: this.props.applicationFunctionUuid,
      key: this.getUIStateKey(scope),
      scope: scope,
    })
    return response.json.value
      ? (JSON.parse(response.json.value) as SavedUIState[])
      : []
  }

  private updateUIStates = async (
    uiState: SavedUIState,
    optionUIState: UIState
  ) => {
    const scope = uiState.scope
    let allScopesSavedUIStates: SavedUIState[] = []
    let savedUIStates: SavedUIState[] = []
    for (const value of Object.values(UiStateScope)) {
      const scopeUIStates: SavedUIState[] = await this.getUIStates(value)
      allScopesSavedUIStates.push(...scopeUIStates)
      if (value === scope) {
        savedUIStates = scopeUIStates
      }
    }

    const newUIState: UIState = this.props.getCurrentSearchCondition
      ? { ...this.props.getCurrentSearchCondition(), ...optionUIState }
      : optionUIState
    const newSavedUIState: SavedUIState = {
      uuid: generateUuid(),
      code: generateCode(allScopesSavedUIStates.map(s => s.code)),
      name: uiState.name,
      scope,
      UIState: newUIState ?? {},
      updatedBy: Auth.getCurrentTenant()!.user!.name,
      updatedAt: formatDateTime(moment()),
    }

    uiStates.update(
      {
        key: this.getUIStateKey(scope),
        scope: scope,
        value: JSON.stringify([...savedUIStates, newSavedUIState]),
      },
      this.props.applicationFunctionUuid
    )
    this.props.onClose()
  }

  render() {
    const { intl, applicationFunctionUuid, getColumnAndFilterState } =
      this.props

    return (
      <SavedUIStateEditorDialog
        savedUIState={{
          uuid: '',
          code: '',
          name: '',
          UIState: {},
          scope: UiStateScope.User,
        }}
        open={this.props.open}
        dialogTitle={intl.formatMessage(
          { id: 'savedUIState.save' },
          {
            title: intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.condition.column',
            }),
          }
        )}
        onSubmit={(uiState: SavedUIState, optionUIState: UIState) =>
          this.updateUIStates(uiState, optionUIState)
        }
        onClose={this.props.onClose}
        optionUIStateListProps={{
          title: intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          }),
          applicationFunctionUuid: applicationFunctionUuid,
          uiStateKey: UiStateKey.BulkSheetUIStateColumnAndFilter,
          customItemTitle: this.props.intl.formatMessage({
            id: 'savedUIState.current.column.state',
          }),
          getCustomItemUIState: this.props.getColumnAndFilterState,
        }}
      />
    )
  }
}
const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected || '',
})

export default connect(mapStateToProps)(
  injectIntl(SaveWbsItemSearchConditionDialog)
)
