export type TenantEntity = {
  uuid: string
  lockVersion: number
  revision: string
  alias: string
  displayName: string
  officialName: string
  iconUrl: string
  defaultTimezoneId: string
  defaultLanguage: string
  emailDomainWhitelist: string
  skipEmailVerification: boolean
  maintenanceMessage: string
  dailyWorkHours: number
  monthlyWorkDays: number
  financialYearEndMonth: string
  // updatedBy: UserBasic
  // updatedAt: string
}

export type AuthProvider = {
  tenantUuid: string
  alias: string
  skipAgreement: boolean
  region: string
  rootUrl: string
  userPoolWebClientId: string
  clientPhrase: string
  userPoolId: string
  oauth: {
    domain: string
    scope: string[]
    redirectSignIn: string
    redirectSignOut: string
    responseType: string
  }
}

export type TenantAuthInfo = {
  appAuthInfo: AppAuthInfo
  providerConfig: AuthProviderConfig
}

export type AppAuthInfo = {
  tenantUuid: string
  rootUrl: string
  alias: string
  skipAgreement: boolean
  maintenanceMessage?: string
}

export type AuthProviderConfig = {
  region: string
  userPoolId: string
  userPoolWebClientId: string
  // clientPhrase: string
  oauth: {
    domain: string
    scope: string[]
    redirectSignIn: string
    redirectSignOut: string
    responseType: string
  }
}

export const createAuthInfo = (
  tenantUuid: string,
  rootUrl: string,
  alias: string,
  domain: string,
  audience: string,
  clientId: string,
  // clientPhrase: string,
  skipAgreement: boolean,
  maintenanceMessage?: string
): TenantAuthInfo => {
  return {
    appAuthInfo: {
      tenantUuid,
      alias,
      rootUrl,
      skipAgreement,
      maintenanceMessage,
    },
    providerConfig: {
      region: 'ap-northeast-1',
      userPoolWebClientId: clientId,
      // clientPhrase,
      userPoolId: audience,
      oauth: {
        domain,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: `${rootUrl}/loggedin`,
        redirectSignOut: `${rootUrl}/loggedout`,
        responseType: 'code',
      },
    },
  }
}
