import React from 'react'
import { styled } from '@mui/system'
import { FontSize } from '../../../../styles/commonStyles'
import { colorPalette } from '../../../style/colorPallete'

export type TabValue = string | number

export interface ITab {
  key: string
  label: string
  value: TabValue
}

interface Props {
  selectedValue: TabValue
  tabs: ITab[]
  onClickTab: (value: TabValue) => void
}

const TabRootDiv = styled('div')<{ selected: boolean }>(({ selected }) => {
  const defaultStyle = {
    minHeight: '22px',
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    opacity: 1,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  } as any
  if (selected) {
    return {
      ...defaultStyle,
      color: '#FFFFFF',
      background: colorPalette.skyBlue[7],
    }
  } else {
    return {
      ...defaultStyle,
      color: colorPalette.monotone[5],
      background: colorPalette.monotone[2],
    }
  }
})
const TabLabel = styled('span')({
  fontSize: FontSize.LARGE,
  verticalAlign: 'middle',
  fontWigth: 700,
})

const Tab = ({ tabKey, label, onClick, selected }) => {
  return (
    <TabRootDiv key={tabKey} onClick={onClick} selected={selected}>
      <TabLabel>{label}</TabLabel>
    </TabRootDiv>
  )
}

const Tabs: React.FC<Props> = ({ selectedValue, tabs, onClickTab }: Props) => {
  return (
    <div style={{ display: 'flex', width: '100%', height: '46px' }}>
      {tabs.map(tab => (
        <Tab
          key={tab.key}
          tabKey={`tab-${tab.key}`}
          label={tab.label}
          onClick={() => onClickTab(tab.value)}
          selected={selectedValue === tab.value}
        />
      ))}
    </div>
  )
}

export default Tabs
