import { IconButton } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import { CSSProperties, useCallback, useMemo } from 'react'
import DateVO from '../../../../vo/DateVO'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import { open } from '../../../router'
import { RootDiv } from '../../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import { CURRENT_MONTH_BACKGROUND_COLOR } from '../../../containers/commons/AgGrid'

interface Props extends ICellRendererParams {}

const WorkTimeCellRenderer = (props: Props) => {
  const getStyle = useCallback(() => {
    let style: CSSProperties = {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 2,
    }
    const isCurrent =
      DateVO.now().format('YYYY/MM') === props.column?.getColId()
    if (isCurrent) {
      style.color = 'blue'
      style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
    } else {
      style.color = 'black'
    }
    if (props.data.type === ResourcePlanType.UNSET) {
      style.color = 'red'
    }
    return style
  }, [props.column, props.data.type])

  const onClickIcon = useCallback(
    event => {
      const date = props.column?.getColId()
      if (!date) return
      const projectCode = window.location.pathname.split('/')[2]
      open(
        `/actualWorkingHoursConfirm/${projectCode}?term=${date.replace(
          '/',
          '-'
        )}`,
        event,
        undefined,
        true
      )
    },
    [props.column]
  )
  const disabled = useMemo(() => {
    return props.value === 0
  }, [props.value])

  return (
    <RootDiv style={{ display: 'flex' }}>
      <IconButton onClick={onClickIcon} sx={{ flex: 1 }} disabled={disabled}>
        <PunchClockIcon
          color={disabled ? 'disabled' : 'primary'}
          style={{ fontSize: 16 }}
        />
      </IconButton>
      <div style={getStyle()}>{props.valueFormatted ?? props.value}</div>
    </RootDiv>
  )
}

export default WorkTimeCellRenderer
