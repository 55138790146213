const CompletionIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="17.1387"
        y="0.548248"
        width="3"
        height="18"
        rx="1.5"
        transform="rotate(45 17.1387 0.548248)"
        fill="#F6FCFE"
      />
      <rect
        x="0.739258"
        y="9.79489"
        width="3"
        height="8"
        rx="1.5"
        transform="rotate(-45 0.739258 9.79489)"
        fill="#F6FCFE"
      />
    </svg>
  )
}

export default CompletionIcon
