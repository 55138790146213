import { ColDef } from 'ag-grid-community'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { WorkloadUnitToggleGroup } from '../../../../components/toggleGroups'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import { QuickFilterKeys } from '../../wbsItemSearch'
import AssignmentFilter from './AssignmentFilter'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import FavoriteColumnFilterButton from '../../../../containers/BulkSheetView/components/header/FavoriteColumnFilterButton'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import { Box } from '@mui/material'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'

type Props = {
  workloadUnit: WorkloadUnit | undefined
  onChangeWorkloadUnit: (value: WorkloadUnit) => void
  onCancel: () => void
  isLoading: boolean
  onChangeQuickFilters: (keys: QuickFilterKeys[]) => void | Promise<void>
  quickFilters: QuickFilterKeys[] | undefined
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  onClickExportExcel: () => void
  onClickFavoriteColumnFilterButton: () => void
  onClickColumnSettingButton: (e) => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  sortColumnsState: SortedColumnState[]
  currentFormat?: string | undefined
  onChangeDateFormat: (value: string) => void
  columnSettingOpen: boolean
}
const WbsItemSearchWbsHeader = ({
  workloadUnit,
  onChangeWorkloadUnit,
  onCancel,
  onChangeQuickFilters,
  quickFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  rowHeight,
  onChangeHeight,
  onClickExportExcel,
  onClickFavoriteColumnFilterButton,
  onClickColumnSettingButton,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  sortColumnsState,
  currentFormat,
  onChangeDateFormat,
  columnSettingOpen,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '5px 20px 10px 20px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <Button
        onClick={onCancel}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      <WorkloadUnitToggleGroup
        refresh={value => {
          onChangeWorkloadUnit(value)
        }}
        value={workloadUnit}
      />
      <AssignmentFilter
        onChangeQuickFilters={onChangeQuickFilters}
        selectedFilterGroups={quickFilters ?? []}
      />
      <FilterButton
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
      />
      <SortButton
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortColumnsState}
      />
      <ColumnSettingButton
        onClick={onClickColumnSettingButton}
        open={columnSettingOpen}
      />
      <FavoriteColumnFilterButton onClick={onClickFavoriteColumnFilterButton} />
      <DisplayFormatButton
        height={rowHeight}
        onChangeHeight={onChangeHeight}
        currentFormat={currentFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <FileExportButton onClickExport={onClickExportExcel} />
      </Box>
    </div>
  )
}

export default WbsItemSearchWbsHeader
