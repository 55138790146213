import { useMemo } from 'react'
import { WbsPivotAggregationDomain } from '../../../../domain/value-object/WbsPivot'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { BurndownChartCondition } from '../model'

export const useBurndownChartCondition = (
  domain: WbsPivotAggregationDomain | undefined,
  type: string | undefined,
  teamUuid: string | undefined
): BurndownChartCondition => {
  const { projectUuid } = useProjectPrivateContext()
  const condition = useMemo(() => {
    const wbsItemType = (
      ['DELIVERABLE', 'TASK'] as ('DELIVERABLE' | 'TASK')[]
    ).find(v => v === type)
    const ticketTypeUuid = !wbsItemType ? type : undefined
    return {
      projectUuid,
      domain,
      wbsItemType,
      ticketTypeUuid,
      teamUuid,
    }
  }, [domain, projectUuid, type, teamUuid])
  return condition
}
