import { intl } from '../../../i18n'
import api, { APIResponse } from '../../../lib/commons/api'
import DateVO from '../../../vo/DateVO'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'
import {
  LedgerAccountsTree,
  LedgerAccountsType,
} from '../LedgerAccounts/ledgerAccounts'
import {
  ProfitLossItemAmountBody,
  ProfitLossItemAmountRowBody,
} from '../ProfitLossItems/profitLossItems'

export const PredictionType = {
  Forecast: 'FORECAST',
  BudgetaryAmount: 'BUDGETARY_AMOUNT',
  Budget: 'BUDGET',
  Result: 'RESULT',
} as const

export const fetchProfitLossSummary = async (params: {
  projectUuid: string
  startDate: string
  endDate: string
  predictionType: string | undefined
}): Promise<APIResponse> => {
  return api.functional.request(
    'GET',
    '/api/v1/profit_loss_items/summary',
    params
  )
}

export class ProfitLossSummarySkeleton
  implements TreeSource<ProfitLossSummarySkeleton>
{
  uuid: string
  children: ProfitLossSummarySkeleton[]

  financialState: LedgerAccountsTree | undefined
  generalLedger: LedgerAccountsTree | undefined
  subsidiary: LedgerAccountsTree | undefined

  displayName: string
  profitLossItemName: string
  rowType: string
  budgetAmounts: ProfitLossItemAmountBody[]
  resultAmounts: ProfitLossItemAmountBody[]
}

export interface ProfitLossSummaryRow extends TreeRow {
  body: ProfitLossSummaryRowBody
}

export class ProfitLossSummaryRowBody {
  name: string
  displayName: string
  type: string | undefined
  financialState: LedgerAccountsTree | undefined
  generalLedger: LedgerAccountsTree | undefined
  subsidiary: LedgerAccountsTree | undefined
  amounts: ProfitLossItemAmountRowBody[]

  constructor(src?: ProfitLossSummarySkeleton) {
    this.name = src?.profitLossItemName ?? ''
    this.displayName = src?.displayName ?? this.name
    this.financialState = src?.financialState
    this.generalLedger = src?.generalLedger
    this.subsidiary = src?.subsidiary
    this.type = src?.rowType
    this.amounts = makeAmounts(
      src?.budgetAmounts ?? [],
      src?.resultAmounts ?? []
    )
    if (
      LedgerAccountsType.SubsidiaryAccounts === this.type &&
      !this.displayName
    ) {
      this.displayName = intl.formatMessage({
        id: 'profitLossSummary.no.subsidiary',
      })
    }
  }
}

const makeAmounts = (
  budgets: ProfitLossItemAmountBody[],
  results: ProfitLossItemAmountBody[]
) => {
  const now = DateVO.now().getFirstDayOfMonth()
  const resList: ProfitLossItemAmountRowBody[] = []
  budgets.forEach(v => {
    const compDate = new DateVO(v.month)
    if (compDate.isSameOrAfter(now)) {
      resList.push(new ProfitLossItemAmountRowBody(v))
    }
  })
  results.forEach(v => {
    const compDate = new DateVO(v.month)
    if (compDate.isBefore(now)) {
      resList.push(new ProfitLossItemAmountRowBody(v))
    }
  })
  return resList
}
