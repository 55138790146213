import _ from 'lodash'
import VOBase from '../../vo/base'
import { CustomEnumCombinationDirection, CustomEnumValue } from './appFunction'

export const CUSTOM_ENUM_NONE = 'NONE'

export const filterByCombination = (
  options: CustomEnumValue[],
  getValueAtPath: (path: string) => any
): CustomEnumValue[] => {
  let filtered: CustomEnumValue[] = []
  options.forEach(option => {
    const combinations = option.combinations
    if (!combinations || combinations.length === 0) {
      filtered.push(option)
      return
    }
    const rowToColumnCombinations = combinations.filter(
      combination =>
        combination.direction === CustomEnumCombinationDirection.ROW_TO_COLUMN
    )
    let included = true
    rowToColumnCombinations.forEach(combination => {
      if (!included) {
        return
      }
      const combinedEnumPath = combination.combinedEnumPath
      if (!combinedEnumPath) {
        return
      }
      const valueAtPath = getValueAtPath(combinedEnumPath)
      if (valueAtPath instanceof VOBase) {
        included = combination.combinedValues.some(
          combinedValue => combinedValue.value === valueAtPath.getValue()
        )
      } else {
        included = combination.combinedValues.some(
          combinedValue =>
            combinedValue.value === valueAtPath ||
            combinedValue.name === valueAtPath
        )
      }
    })
    if (included) {
      filtered.push(option)
    }
  })
  return filtered
}

export const filterValuesByCombination = (
  values: CustomEnumValue[],
  accessor: (code: string) => string,
  bidirection?: boolean
) => {
  return values
    .filter(value => {
      if (_.isEmpty(value.combinations)) return true
      return value.combinations!.every(c => {
        if (
          !bidirection &&
          c.direction !== CustomEnumCombinationDirection.ROW_TO_COLUMN
        ) {
          return true
        }
        const data = accessor(c.combinedEnumCode)
        return (
          (c.combinedValues.some(cv => cv.value === CUSTOM_ENUM_NONE) &&
            (!data || data === CUSTOM_ENUM_NONE)) ||
          c.combinedValues.map(cv => cv.value).includes(data)
        )
      })
    })
    .filter(v => !!v)
}
