import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import {
  BulkSheetFilter,
  FilterSelect,
  FilterInput,
  FilterFooter,
} from '../common'
import { useTextFilter } from '../TextFilter/useTextFilter'
import { TextFilter, TextFilterOperator } from '../TextFilter'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'

type Props = IFilterParams & {
  // response.json must be string[]
  fetch: (v: TextFilter) => Promise<APIResponse>
}

export const ServerSideTextFilter = forwardRef(
  ({ fetch, filterChangedCallback }: Props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const hasRequiredSaveData = useSelector<AllState>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

    // State
    const filter = useTextFilter()
    const [loading, isLoading] = useState(false)
    const [isComposing, setIsComposing] = useState<boolean>(false)
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
      undefined
    )

    const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
    useEffect(() => {
      if (addedRowIds.current) return
      addedRowIds.current = []
    }, [])
    const fetchFilteredIds = async (v: TextFilter) => {
      const response = await fetch(v)
      setFilteredIds(response.json)
    }
    const search = useMemo(
      () =>
        _.debounce(async (v: TextFilter, active: boolean) => {
          if (isComposing) return
          if (!v || !active) {
            setFilteredIds(undefined)
            return
          }
          try {
            isLoading(true)
            await fetchFilteredIds(v)
          } finally {
            isLoading(false)
          }
        }, 300),
      []
    )

    // Ag-grid custom filter
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params: IDoesFilterPassParams) {
          // Don't filter new rows
          if (params.data.added) {
            if (addedRowIds.current && params.node.id) {
              addedRowIds.current.push(params.node.id)
            }
            return true
          }
          if (
            addedRowIds.current &&
            params.node.id &&
            addedRowIds.current.includes(params.node.id)
          ) {
            return true
          }
          if (!filteredIds) return true
          return params.node.id && filteredIds.includes(params.node.id)
        },

        isFilterActive() {
          return !!filteredIds
        },

        getModel() {
          return filter.model()
        },

        setModel(model?: { operator: TextFilterOperator; value: string }) {
          if (!model) {
            filter.reset()
            return
          }
          filter.setOperator(model.operator)
          filter.setValue(model.value)
        },

        onFloatingFilterChanged(_, model: TextFilter) {
          filter.setOperator(model.operator)
          filter.setValue(model.value)
        },

        afterGuiAttached() {
          inputRef.current && inputRef.current.focus()
        },

        onNewRowsLoaded() {
          if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
          const filterModel = filter.model()
          if (filterModel) {
            fetchFilteredIds(filterModel)
          }
        },
      }
    })

    // Filter
    useEffect(() => {
      search(filter.model(), filter.isActive)
    }, [filter.operator, filter.value])

    useEffect(() => {
      const filterModel = filter.model()
      const isAfterSavedOrRefreshed =
        prevHasRequiredSaveData && !hasRequiredSaveData
      if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
        fetchFilteredIds(filterModel).then(() => {
          addedRowIds.current = []
        })
      }
    }, [hasRequiredSaveData])

    useEffect(() => {
      filterChangedCallback()
    }, [filteredIds])

    // Event
    const onOperatorChange = useCallback(v => filter.setOperator(v), [])
    const onTextChange = useCallback(v => filter.setValue(v), [])
    const clearFilter = useCallback(() => filter.reset(), [])

    return (
      <BulkSheetFilter>
        <FilterSelect
          variant="outlined"
          value={filter.operator}
          onChange={e => onOperatorChange(e.target.value as TextFilterOperator)}
          sx={{ width: '100%' }}
        >
          {/* TODO Use select component to customize style */}
          {Object.values(TextFilterOperator).map(v => (
            <option key={`text-filter-option-${v}`} value={v}>
              {`${v.charAt(0)}${_.lowerCase(v.slice(1))}`}
            </option>
          ))}
        </FilterSelect>
        <FilterInput
          inputRef={inputRef}
          value={filter.value}
          onChange={e => {
            onTextChange(e.target.value)
          }}
          onCompositionStart={() => setIsComposing(true)}
          onCompositionEnd={e => {
            setIsComposing(false)
            onTextChange((e.target as HTMLInputElement).value)
          }}
          placeholder={'Filter...'}
          sx={{ height: 22 }}
        />
        <FilterFooter loading={loading} onClick={clearFilter} />
      </BulkSheetFilter>
    )
  }
)
