import React from 'react'
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

interface Props {
  values?: string[]
  options: { value: string; label: string }[]
  onChange: (values: string[]) => void
  disabled?: boolean
  height?: number
}

const MultipleToggleGroup: React.FC<Props> = (props: Props) => {
  const { values, options, onChange, disabled } = props
  const height = props.height || 24
  return (
    <ToggleButtonGroup
      value={values}
      onChange={(e, v) => onChange(v)}
      style={{
        margin: '0 3px',
      }}
    >
      {options.map((option, i) => (
        <ToggleButton
          size="small"
          key={`toggle-${option.value}-${i}`}
          value={option.value}
          sx={{
            width: '100%',
            textTransform: 'none',
            height,
            color: (values || []).includes(option.value)
              ? `${colorPalette.monotone[0]} !important`
              : colorPalette.monotone[4],
            background:
              (values || []).includes(option.value) && !disabled
                ? `${colorPalette.skyBlue[7]} !important`
                : `${colorPalette.monotone[0]} 0% 0% no-repeat padding-box !important`,
          }}
          disabled={disabled}
        >
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              color: disabled ? colorPalette.monotone[2] : '',
            }}
          >
            {option.label}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default MultipleToggleGroup
