type Props = {
  height?: string
  width?: string
}

const FileExportIcon = ({ height, width }: Props) => {
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.125 12C0.825 12 0.5625 11.8875 0.3375 11.6625C0.1125 11.4375 0 11.175 0 10.875V8.19375H1.125V10.875H10.875V8.19375H12V10.875C12 11.175 11.8875 11.4375 11.6625 11.6625C11.4375 11.8875 11.175 12 10.875 12H1.125ZM6 9.13125L2.38125 5.5125L3.1875 4.70625L5.4375 6.95625V0H6.5625V6.95625L8.8125 4.70625L9.61875 5.5125L6 9.13125Z"
        fill="#7B8CAA"
      />
    </svg>
  )
}

export default FileExportIcon
