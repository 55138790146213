import React from 'react'
import { styled } from '@mui/system'
import { Color } from '../../../../../../styles/commonStyles'
import { intl } from '../../../../../../i18n'
import {
  RelativeDate,
  RelativeDateVO,
} from '../../../../../../domain/value-object/DateVO'

const RootContainer = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '10px',
})
const TabDiv = styled('div')<{ selected?: boolean }>(({ selected }) => {
  const defaultStyle: any = {
    height: 22,
    width: '50%',
    margin: 'auto 1px',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: '2px',
    opacity: 1,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
  if (selected) {
    return {
      ...defaultStyle,
      color: '#FFFFFF',
      background: Color.MAIN + ' 0% 0% no-repeat padding-box',
    }
  } else {
    return {
      ...defaultStyle,
      color: Color.MAIN,
      background: '#E8E8E8 0% 0% no-repeat padding-box',
    }
  }
})
const TabLabel = styled('span')({
  verticalAlign: 'middle',
})

const RelativeDateOptions: {
  value: RelativeDate
  label: string
}[] = [
  {
    label: intl.formatMessage({ id: 'yesterday' }),
    value: 'YESTERDAY',
  },
  {
    label: intl.formatMessage({ id: 'today' }),
    value: 'TODAY',
  },
  {
    label: intl.formatMessage({ id: 'tomorrow' }),
    value: 'TOMORROW',
  },
]

const Tab = ({ label, onClick, selected }) => {
  return (
    <TabDiv key={label} onClick={onClick} selected={selected}>
      <TabLabel>{label}</TabLabel>
    </TabDiv>
  )
}

interface Props {
  classes?: any
  className?: string
  value: RelativeDateVO | undefined
  onChange: (value?: RelativeDate) => void
}

const RelativeDateInput: React.FC<Props> = (props: Props) => {
  const { className, value, onChange } = props

  return (
    <RootContainer className={className}>
      {RelativeDateOptions.map(option => {
        return (
          <Tab
            key={option.label}
            label={option.label}
            onClick={() => onChange(option.value)}
            selected={value && value.value === option.value}
          />
        )
      })}
    </RootContainer>
  )
}

export default RelativeDateInput
