import { connect } from 'react-redux'
import { AllState } from '../../../../../store'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../../components/headers/HeaderBar/PageHeader'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import ReloadButton from '../../../../components/buttons/ReloadButton'
import { DateTerm } from '../../../../../utils/date'
import SettingSpeedDial from '../../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  DateRangeSelector,
  SearchUnit,
} from '../../../../components/headers/HeaderBar/DateRangeSelector'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  loading: boolean
  onSubmit: () => void
  onCancel: () => void
  searchUnit: SearchUnit
  dateTerm: DateTerm
  onSearch: (unit: SearchUnit, dateTerm: DateTerm) => void
  onClickExport: () => void
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  rowHeight: number
  onChangeRowHeight: (value: number) => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps

const ActualWorkingHoursHeader = (props: Props) => {
  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <DateRangeSelector
            searchUnit={props.searchUnit}
            dateTerm={props.dateTerm}
            onSearch={props.onSearch}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <SubmitButton
            onClick={props.onSubmit}
            disabled={props.loading || props.notEdited}
            notEdited={props.notEdited}
          />
          <ReloadButton onClick={props.onCancel} disabled={props.loading} />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={props.onClickExport}
              rowHeight={props.rowHeight}
              onClickChangeRowHeight={props.onChangeRowHeight}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.REPORT]}
          value={props.toolbar}
          onChange={props.onChangeToolbar}
          isNotice={false}
        />
      </HeaderContent>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(ActualWorkingHoursHeader)
