import { useCallback } from 'react'
import { ProjectPlanSnapshotRepository } from '../../applications/ports/projectPlanSnapshotRepository'
import api from '../../lib/commons/api'
import { WbsItemStatus, WbsItemType } from '../../domain/entity/WbsItemEntity'

export const useProjectPlanSnapshotRepository =
  (): ProjectPlanSnapshotRepository => {
    const fetchByLeafUuid = useCallback(
      async (snapshotUuid: string, leafUuids: string[]) => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/plans/snapshot/leaf',
          { snapshotUuid, leafUuids }
        )
        return response.json
      },
      []
    )
    const fetchMasterSchedule = useCallback(async (snapshotUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/plans/snapshot/master-schedule',
        { snapshotUuid }
      )
      return response.json
    }, [])
    const fetchReferencedValues = useCallback(
      async (snapshotUuid: string, propertyName: string) => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/plans/snapshot/referenced-values',
          { snapshotUuid, propertyName }
        )
        return response.json
      },
      []
    )
    const pivotByProjectPlanUuids = useCallback(
      async (snapshotUuid: string, projectPlanUuids: string[]) => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/plans/snapshot/pivot/project-plans',
          { snapshotUuid, projectPlanUuids }
        )
        return response.json
      },
      []
    )
    const pivotByTeamUuids = useCallback(
      async (
        snapshotUuid: string,
        rootUuid: string | undefined,
        teamUuids: string[]
      ) => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/plans/snapshot/pivot/teams',
          { snapshotUuid, rootUuid, teamUuids }
        )
        return response.json
      },
      []
    )
    return {
      fetchByLeafUuid,
      fetchMasterSchedule,
      fetchReferencedValues,
      pivotByProjectPlanUuids,
      pivotByTeamUuids,
    }
  }
