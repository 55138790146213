import {
  IconButton,
  List,
  ListItem,
  Popper,
  PopperPlacementType,
  Typography,
} from '@mui/material'
import { ColDef, ColumnState } from 'ag-grid-community'
import { FontSize } from '../../../../../styles/commonStyles'
import CloseIcon from '@mui/icons-material/Close'
import { colorPalette } from '../../../../style/colorPallete'
import { Button } from '../../../../components/buttons'
import { intl } from '../../../../../i18n'
import AscIcon from '../../../../components/icons/AscIcon'
import DescIcon from '../../../../components/icons/DescIcon'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import { useCallback } from 'react'

type Props = {
  anchorEl: any
  placement?: PopperPlacementType
  onDeleteSortedColumn: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  sortColumnsState: SortedColumnState[]
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const SortColumnListPopper = ({
  anchorEl,
  placement,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  sortColumnsState,
  getDisplayColumnName,
}: Props) => {
  const getDisplayValue = useCallback(
    (column: ColDef | string): string => {
      if (typeof column === 'string') return column

      const colDef: ColDef = column
      const value = getDisplayColumnName
        ? getDisplayColumnName(colDef)
        : undefined
      return value ?? colDef.headerName ?? ''
    },
    [getDisplayColumnName]
  )
  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement={placement || 'right-start'}
      disablePortal={true}
      style={{
        zIndex: 2000,
        background: colorPalette.monotone[0],
        borderRadius: '4px',
        boxShadow: '0px 1px 2px #0000004D',
      }}
    >
      <List
        component="nav"
        style={{
          position: 'relative',
          overflow: 'auto',
          width: 'fit-content',
          padding: '10px',
          maxHeight: '430px',
        }}
      >
        {sortColumnsState?.map((column: ColDef | string, index) => {
          return (
            <ListItem
              key={`filteredColumn${index}`}
              onClick={e => {
                e.stopPropagation()
              }}
              sx={{
                padding: '0px',
                background: colorPalette.monotone[1],
                paddingRight: '8px',
                paddingLeft: '8px',
                border: 'none',
                borderRadius: '32px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '5px 0 10px 0',
              }}
            >
              {sortColumnsState &&
                (sortColumnsState[index].sort === 'asc' ? (
                  <AscIcon />
                ) : (
                  <DescIcon />
                ))}
              <Button
                key={`filteredColumn${index}`}
                onClick={
                  onChangeSortColumnState
                    ? () => {
                        onChangeSortColumnState(
                          typeof column === 'string'
                            ? column
                            : column.colId || column.field || '',
                          sortColumnsState &&
                            sortColumnsState[index].sort === 'asc'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                    : undefined
                }
                colorPattern="monotone"
                sx={{
                  width: 'fit-content',
                  minWidth: '120px',
                  height: '30px',
                  border: 'none',
                  color: colorPalette.monotone[4],
                  fontSize: FontSize.MEDIUM,
                  background: 'transparent',
                }}
              >
                {getDisplayValue(column)}
              </Button>
              {onDeleteSortedColumn && (
                <IconButton
                  style={{
                    padding: '3px',
                    background: colorPalette.monotone[0],
                  }}
                  onClick={() =>
                    onDeleteSortedColumn(
                      typeof column === 'string'
                        ? column
                        : column.colId || column.field || ''
                    )
                  }
                >
                  <CloseIcon
                    fontSize="small"
                    style={{ height: '10px', width: '10px' }}
                  />
                </IconButton>
              )}
            </ListItem>
          )
        })}
      </List>
      <Button
        colorPattern="monotone"
        onClick={onDeleteSortedAllColumns}
        sx={{
          border: 'none',
          background: 'transparent',
          padding: '0 15px 15px 15px',
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            color: colorPalette.pink[6],
            fontSize: FontSize.LARGE,
            fontWeight: 400,
          }}
        >
          {intl.formatMessage({ id: 'wbs.header.sort.delete' })}
        </Typography>
      </Button>
    </Popper>
  )
}

export default SortColumnListPopper
