import { parse } from '../../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../../lib/functions/customEnumValue'

const fetchRiskTypeOptions = async projectUuid => {
  const response = await getCustomEnumValues({
    customEnumCode: CustomEnumCode.RISK_TYPE,
    groupUuid: projectUuid,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

const fetchRiskProbabilityOptions = async projectUuid => {
  const response = await getCustomEnumValues({
    customEnumCode: CustomEnumCode.RISK_PROBABILITY,
    groupUuid: projectUuid,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

const fetchRiskImpactOptions = async projectUuid => {
  const response = await getCustomEnumValues({
    customEnumCode: CustomEnumCode.RISK_IMPACT,
    groupUuid: projectUuid,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

const fetchRiskStrategyOptions = async projectUuid => {
  const response = await getCustomEnumValues({
    customEnumCode: CustomEnumCode.RISK_STRATEGY,
    groupUuid: projectUuid,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

export const getRiskSelectColumnOptions = async (projectUuid: string) => {
  const [riskType, riskProbability, riskImpact, riskStrategy] =
    await Promise.all([
      fetchRiskTypeOptions(projectUuid),
      fetchRiskProbabilityOptions(projectUuid),
      fetchRiskImpactOptions(projectUuid),
      fetchRiskStrategyOptions(projectUuid),
    ])
  return {
    riskType,
    riskProbability,
    riskImpact,
    riskStrategy,
  }
}
