import { useRef } from 'react'
import { useTeamSelectGridOptions } from './gridOptions'
import { BulkSheetView } from '../../../../../containers/BulkSheetView'
import { useTeamSelectData } from './hooks/data'

type ProjectPlanSelectProps = {
  snapshotUuid: string
  selectedUuids: string[]
  updateSelectedUuid: (uuid: string, selected: boolean) => void
}
export const TeamSelectGrid = ({
  snapshotUuid,
  selectedUuids,
  updateSelectedUuid,
}: ProjectPlanSelectProps) => {
  const gridOptions = useTeamSelectGridOptions(updateSelectedUuid)
  const ref = useRef<HTMLDivElement>(null)
  const { rows } = useTeamSelectData(snapshotUuid, selectedUuids)
  return <BulkSheetView ref={ref} gridOptions={gridOptions} rowData={rows} />
}
