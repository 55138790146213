import { Link, styled } from '@mui/material'
import { DevelopmentPanelIconArea } from '.'
import { RepositoryVO } from '../../../../domain/value-object/development/RepositoryVO'
import GitRepositoryIcon from '../../icons/GitRepositoryIcon'

type RepositoryFieldProps = {
  repository: RepositoryVO
}

const RepositoryLink = styled(Link)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const RepositoryFieldDiv = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
})

export const RepositoryField = ({ repository }: RepositoryFieldProps) => {
  return (
    <RepositoryFieldDiv>
      <DevelopmentPanelIconArea>
        <GitRepositoryIcon />
      </DevelopmentPanelIconArea>
      <RepositoryLink href={repository.url} target={'_blank'}>
        {repository.name}
      </RepositoryLink>
    </RepositoryFieldDiv>
  )
}
