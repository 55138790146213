/* eslint-disable no-underscore-dangle */
import { Epic, ofType } from 'redux-observable'
import { mergeMap, map } from 'rxjs/operators'
import { TagForWbsItem, fetchTags } from '../lib/functions/tag'

enum ActionType {
  FETCH_TAGS = 'FETCH_TAGS',
  RECEIVED_TAGS = 'SET_TAGS',
}

type State = {
  [projectUuid: string]: TagForWbsItem[]
}

export const fetchTagsByProject = (uuid: string) => ({
  type: ActionType.FETCH_TAGS,
  projectUuid: uuid,
})

const fetchedAtMap: { [projectUuid: string]: number } = {}
const REFETCH_MIN_INTERVAL_MILLSECONDS = 5000

export const fetchTagsByProjectEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_TAGS),
    mergeMap(async action => {
      // TODO Consider the permanent solution.
      if (!fetchedAtMap[action.projectUuid]) {
        fetchedAtMap[action.projectUuid] = new Date().getTime()
      } else if (
        new Date().getTime() - fetchedAtMap[action.projectUuid] <
        REFETCH_MIN_INTERVAL_MILLSECONDS
      ) {
        return {}
      }

      try {
        const response = await fetchTags({
          projectUuid: action.projectUuid,
        })
        const tags = response.json

        return {
          projectUuid: action.projectUuid,
          tags: tags ?? [],
        }
      } catch (err) {
        return { err }
      }
    }),
    map(result => {
      const { projectUuid, tags } = result

      return receivedTags(projectUuid, tags)
    })
  )

export const receivedTags = (projectUuid: string, tags: TagForWbsItem[]) => ({
  type: ActionType.RECEIVED_TAGS,
  projectUuid: projectUuid,
  tags: tags,
})

export const reducer = (state: State = {}, action: any): State => {
  switch (action.type) {
    case ActionType.RECEIVED_TAGS:
      return {
        ...state,
        [action.projectUuid]: action.tags,
      }
    default:
      return state
  }
}
