import { useCallback, useMemo } from 'react'
import { SearchConditionProps } from '../../search-components'
import { WbsItemStatus } from '../../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../../i18n'
import { SelectOption } from '../../../../model/selectOption'
import { CheckboxSelectConditionItemLabel } from '../CheckboxSelectConditionItemLabel'
import WbsItemStatusLabel from '../../../labels/WbsItemStatusLabel'
import { WbsItemStatusCheckboxSelectConditionProps } from '../../search-components/wbs-item-search-components/WbsItemStatusCheckboxSelectCondition'

type WbsItemStatusCheckboxSelectConditionItemLabelProps = Omit<
  WbsItemStatusCheckboxSelectConditionProps,
  'colSpan' | 'onEnter'
> & {
  search: () => void
  onClose?: () => void
}

export const WbsItemStatusCheckboxSelectConditionItemLabel = ({
  value,
  onChange,
  onClose,
  ...other
}: WbsItemStatusCheckboxSelectConditionItemLabelProps) => {
  const options = useMemo(() => {
    return Object.keys(WbsItemStatus).map(k => ({
      name: k,
      value: k,
    }))
  }, [])
  const onChangeValue = useCallback((newValueStr: string[] | undefined) => {
    if (!newValueStr) {
      onChange(undefined)
      return
    }
    const newValue = newValueStr.map(v => WbsItemStatus[v])
    onChange(newValue)
  }, [])
  const renderOption = useCallback((option: SelectOption) => {
    return <WbsItemStatusLabel status={option.value as WbsItemStatus} />
  }, [])
  return (
    <CheckboxSelectConditionItemLabel
      {...other}
      header={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.status' })}
      value={value}
      onChange={onChangeValue}
      onClose={onClose}
      options={options}
      renderOption={renderOption}
    />
  )
}
