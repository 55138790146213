import { useCallback, useEffect, useState } from 'react'
import {
  VersionReportVersionEntity,
  VersionReportVersionPageEntity,
} from '../../../../domain/entity/version-report/VersionReportVersionEntity'
import { useVersionReportVersionRepository } from '../../../../services/adaptors/versionReportVersionRepositoryAdaptor'

export const useEditingState = (
  currentVersion: VersionReportVersionEntity | undefined,
  currentPage: VersionReportVersionPageEntity | undefined
) => {
  const [editing, setEditing] = useState<boolean>(false)
  const startEditing = useCallback(() => {
    if (!currentPage) return
    setEditing(true)
  }, [currentPage])
  const { updatePages } = useVersionReportVersionRepository()
  const finishEditing = useCallback(async () => {
    if (!currentVersion) return
    await updatePages(currentVersion)
    setEditing(false)
  }, [currentVersion, updatePages])
  return {
    editing,
    startEditing,
    finishEditing,
  }
}
