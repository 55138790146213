import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { intl } from '../../../../../i18n'
import Dialog from '@mui/material/Dialog'
import {
  DialogButton,
  DialogContentText,
  useDialogButtonRef,
} from '../../../../components/dialogs'
import { Row } from '../../model'
import { List, ListItem, ListItemText, styled } from '@mui/material'

type DeleteRowConfirmationDialogProps<R extends Row> = {
  target: string[]
  onConfirm: () => void
  onClose: () => void
  additionalContentTitle?: string
  additionalContentTarget?: string[]
}

export const DeleteRowConfirmationDialog = <R extends Row>({
  target,
  onConfirm,
  onClose,
  additionalContentTitle,
  additionalContentTarget,
}: DeleteRowConfirmationDialogProps<R>) => {
  const [buttonRef, focusButton, blurButton] = useDialogButtonRef()

  const displayAdditionalContent =
    additionalContentTitle &&
    additionalContentTarget &&
    additionalContentTarget.length > 0

  return (
    <Dialog
      open={true}
      TransitionProps={{
        onEnter: focusButton,
      }}
      onClose={onClose}
    >
      <DialogTitle>
        {intl.formatMessage({
          id: 'dialog.title.deleteRows',
        })}
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText>
          {intl.formatMessage({
            id: 'dialog.message.deleteRows',
          })}
        </DialogContentText>
        <CandidateList>
          {target.map((displayName, i) => (
            <Candidate key={`delete-candidate-${i}`}>
              <ListItemText>{displayName}</ListItemText>
            </Candidate>
          ))}
        </CandidateList>
        {displayAdditionalContent && (
          <>
            <DialogContentText>{additionalContentTitle}</DialogContentText>
            <CandidateList>
              {additionalContentTarget.map((displayName, i) => (
                <Candidate key={`delete-candidate-additional-${i}`}>
                  <ListItemText>{displayName}</ListItemText>
                </Candidate>
              ))}
            </CandidateList>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={onConfirm} color="primary" ref={buttonRef}>
          {intl.formatMessage({
            id: 'dialog.submit.deleteRows',
          })}
        </DialogButton>
        <DialogButton
          onClick={onClose}
          color="primary"
          onMouseOver={blurButton}
        >
          {intl.formatMessage({ id: 'dialog.cancel' })}
        </DialogButton>
      </DialogActions>
    </Dialog>
  )
}

const CandidateList = styled(List)({
  border: '1px solid #aaa',
  borderRadius: '5px',
  backgroundColor: '#eee',
  paddingLeft: '5px',
  wordWrap: 'break-word',
  '& *': {
    margin: 0,
  },
})

const Candidate = styled(ListItem)({
  padding: '0 6px',
})
