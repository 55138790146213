import _ from 'lodash'
import {
  GetContextMenuItems,
  GetContextMenuItemsParams,
  MenuItemDef,
} from 'ag-grid-community'
import {
  hasChildren,
  WbsItemAdditionalPropertyRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { intl } from '../../../../i18n'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import {
  getAllLeafChildren,
  getSelectedNode,
} from '../../../containers/BulkSheetView/lib/gridApi'
import { useCallback } from 'react'

export const useWbsItemAdditionalPropertyContextMenu = ({
  addGroupHeaderRowAction,
  addAdditionalPropertyRowAction,
  deleteRowAction,
  allRows,
}: {
  addGroupHeaderRowAction: (selectedRow: WbsItemAdditionalPropertyRow) => void
  addAdditionalPropertyRowAction: (
    selectedRow: WbsItemAdditionalPropertyRow
  ) => void
  deleteRowAction: () => void
  allRows: WbsItemAdditionalPropertyRow[]
}): {
  contextMenu: GetContextMenuItems
} => {
  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const selectedNodes = getSelectedNode(params.api)
      if (!params.node) return []
      return [
        {
          name: intl.formatMessage({
            id: 'wbsItemAdditionalProperties.contextMenu.addGroupHeaderRow',
          }),
          icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
          action: () => {
            addGroupHeaderRowAction(params.node!.data)
          },
        },
        {
          name: intl.formatMessage({
            id: 'wbsItemAdditionalProperties.contextMenu.addAdditionalPropertyRow',
          }),
          icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
          disabled: selectedNodes.length !== 1,
          action: () => {
            addAdditionalPropertyRowAction(params.node!.data)
          },
        },
        {
          name: intl.formatMessage(
            { id: 'bulksheet.contextMenu.delete' },
            { count: selectedNodes.length }
          ),
          icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
          disabled: selectedNodes.some(v => hasChildren(v.data, allRows)),
          action: deleteRowAction,
          shortcut: intl.formatMessage({
            id: 'bulksheet.contextMenu.shortcut.row.delete',
          }),
        },
      ] as MenuItemDef[]
    },
    [addGroupHeaderRowAction, addAdditionalPropertyRowAction, deleteRowAction]
  )

  return {
    contextMenu,
  }
}
