import { Expose, Type, Transform, TransformFnParams } from 'class-transformer'
import { intl } from '../../../i18n'
import CodeVO from '../../value-object/CodeVO'
import DateTimeTermVO, { DateTimeTerm } from '../../value-object/DateTimeTermVO'
import DateTimeVO from '../../value-object/DateTimeVO'
import LockVersionVO from '../../value-object/LockVersionVO'
import MultiLineTextVO from '../../value-object/MultiLineTextVO'
import RevisionVO from '../../value-object/RevisionVO'
import SingleLineTextVO from '../../value-object/SingleLineTextVO'
import UserBasicVO, { UserBasic } from '../../value-object/UserBasicVO'
import URLVO from '../../value-object/URLVO'
import UUIDVO from '../../value-object/UUIDVO'
import DomainEntityBase from '../Base'

export enum ManagementNoticeType {
  NewFeature = 'NEW_FEATURE',
  Maintenance = 'MAINTENANCE',
  Bug = 'BUG',
}

const transformDateTerm = (
  planeValue: DateTimeTerm
): DateTimeTermVO | undefined => {
  if (!planeValue) {
    return undefined
  }
  const startDateTime: DateTimeVO = new DateTimeVO(
    new Date(planeValue.startDateTime!)
  )
  const endDateTime: DateTimeVO | undefined = planeValue.endDateTime
    ? new DateTimeVO(new Date(planeValue.endDateTime))
    : undefined
  return new DateTimeTermVO(startDateTime, endDateTime)
}

const transformUserBasic = (planeValue: UserBasic): UserBasicVO | undefined => {
  if (!planeValue) {
    return undefined
  }
  const uuid: UUIDVO = new UUIDVO(planeValue.uuid)
  const code: CodeVO | undefined = planeValue.code
    ? new CodeVO(planeValue.code)
    : undefined
  const iconUrl: URLVO | undefined = planeValue.iconUrl
    ? new URLVO(planeValue.iconUrl)
    : undefined
  const name: string | undefined = planeValue.name ?? undefined
  return new UserBasicVO(uuid, code, iconUrl, name)
}

export const getManagementNoticeTypeLabel = (
  type: ManagementNoticeType
): string | undefined => {
  let messageId: string | undefined = undefined
  switch (type) {
    case ManagementNoticeType.NewFeature:
      messageId = 'management.notice.type.newfeature'
      break
    case ManagementNoticeType.Maintenance:
      messageId = 'management.notice.type.maintenance'
      break
    case ManagementNoticeType.Bug:
      messageId = 'management.notice.type.bug'
      break
  }
  return messageId ? intl.formatMessage({ id: messageId }) : undefined
}

export default class ManagementNoticeEntity extends DomainEntityBase {
  @Expose()
  @Type(() => UUIDVO)
  @Transform(({ value }) => new UUIDVO(value))
  uuid: UUIDVO

  @Expose()
  type: ManagementNoticeType

  @Expose()
  @Type(() => SingleLineTextVO)
  @Transform(({ value }) => new SingleLineTextVO(value))
  title: SingleLineTextVO

  @Expose()
  @Type(() => MultiLineTextVO)
  @Transform(({ value }) => new MultiLineTextVO(value))
  message: MultiLineTextVO

  @Expose()
  @Type(() => DateTimeTermVO)
  @Transform(({ value, key, obj }) => {
    return transformDateTerm(obj.publishPeriod)
  })
  publishPeriod: DateTimeTermVO

  @Expose()
  @Type(() => LockVersionVO)
  @Transform(({ value }) => new LockVersionVO(value))
  lockVersion: LockVersionVO

  @Expose()
  @Type(() => RevisionVO)
  @Transform(({ value }) => new RevisionVO(value))
  revision: RevisionVO

  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => new DateTimeVO(value))
  createdAt: DateTimeVO

  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => new DateTimeVO(value))
  updatedAt: DateTimeVO

  @Expose()
  @Transform(({ value, key, obj }) => {
    return transformUserBasic(obj?.createdBy)
  })
  createdBy: UserBasicVO

  @Expose()
  @Transform(({ value, key, obj }) => {
    return transformUserBasic(obj?.updatedBy)
  })
  updatedBy: UserBasicVO

  getTypeLabel = (): string | undefined => {
    return getManagementNoticeTypeLabel(this.type)
  }
}
