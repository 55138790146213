import PageHeader, {
  Header,
  HeaderContent,
  HeaderDivider,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import ReloadButton from '../../../components/buttons/ReloadButton'
import { intl } from '../../../../i18n'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../components/toggleGroups'
import ColumnFilterButton from '../../../components/buttons/ColumnFilterButtonGroup/ColumnFilterButton'
import { GanttDisplayParameter } from '../../../containers/commons/AgGrid/components/cell/custom/gantt/GanttDisplayParameter'
import {
  AggregateField,
  AggregateTarget,
} from '../../../../domain/entity/WbsItemEntity'
import SettingSpeedDial from '../../../components/headers/HeaderBar/SettingSpeedDial'
import Box from '@mui/material/Box'
import {
  DateBound,
  StartAndEndDateToggleGroup,
} from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import { WorkloadUnit } from '../../../../lib/functions/workload'

type StateProps = {
  title?: string
}

const mapStateToProps = (state: AllState, props: OwnProps) => ({
  title: state.appFunction.functions.find(
    v => v.externalId === props.externalId
  )?.name,
})

type OwnProps = {
  externalId: string
  onRefresh: () => void
  onClickExport: () => void
  aggregateTarget: AggregateTarget
  onAggregateTargetChanged: (value: AggregateTarget) => void
  aggregateField: AggregateField
  onAggregateFieldChanged: (value: AggregateField) => void
  workloadUnit: WorkloadUnit
  onWorkloadUnitChanged: (value: WorkloadUnit) => void
  aggregateBaseDate: DateBound
  onAggregateBaseDateChanged: (value: DateBound) => void
  onColumnReset: () => void
  showGanttParameter: boolean
  currentFormat: string
  onClickChangeDateFormat: (value: string) => void
}

type Props = OwnProps & StateProps

export const ProjectOverviewHeader = connect(mapStateToProps)(
  (props: Props) => {
    if (props.title && props.title !== document.title) {
      document.title = props.title
    }
    return (
      <PageHeader>
        <Header>
          <HeaderContent>
            <ReloadButton onClick={props.onRefresh} />
            <HeaderDivider />
            <IconGroup>
              <SettingSpeedDial
                onClickExport={props.onClickExport}
                currentFormat={props.currentFormat}
                onClickChangeDateFormat={props.onClickChangeDateFormat}
              />
            </IconGroup>
            <HeaderDivider />
            <AggregateTargetToggleGroup
              value={props.aggregateTarget}
              onChange={props.onAggregateTargetChanged}
            />
            <AggregateFieldToggleGroup
              value={props.aggregateField}
              onChange={props.onAggregateFieldChanged}
            />
            <WorkloadUnitToggleGroup
              value={props.workloadUnit}
              refresh={props.onWorkloadUnitChanged}
            />
            <StartAndEndDateToggleGroup
              value={props.aggregateBaseDate}
              onChange={props.onAggregateBaseDateChanged}
            />
            <HeaderDivider />
            <Box sx={{ margin: '0 3px' }}>
              <ColumnFilterButton
                title={intl.formatMessage({
                  id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
                })}
                onClick={props.onColumnReset}
              />
            </Box>
            <HeaderDivider />
            <GanttDisplayParameter visible={props.showGanttParameter} />
          </HeaderContent>
        </Header>
      </PageHeader>
    )
  }
)
