import { ColDef } from 'ag-grid-community'
import ReloadIcon from '../../../components/icons/ReloadIcon'
import DisplayFormatButton from '../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import ColumnSettingButton from '../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import FavoriteColumnFilterButton from '../../../containers/BulkSheetView/components/header/FavoriteColumnFilterButton'
import FilterButton from '../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../containers/BulkSheetView/components/header/SortButton'
import { colorPalette } from '../../../style/colorPallete'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'
import { Button } from '../../../components/buttons'
import { SlackWorkspaceSelect } from './SlackWorkspaceSelect'
import { ProjectNotificationWorkspace } from '../projectNotificationSettings'
import styled from 'styled-components'

type Props = {
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  onReload: () => void
  onClickColumnSettingButton: (e) => void
  onClickFavoriteColumnFilterButton: () => void
  sortColumnsState: SortedColumnState[]
  columnSettingOpen: boolean
  workspace?: ProjectNotificationWorkspace
  onChangeWorkspace: (value?: ProjectNotificationWorkspace) => void
}
const ProjectNotificationSettingsHeader = ({
  rowHeight,
  onChangeHeight,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  onReload,
  onClickColumnSettingButton,
  onClickFavoriteColumnFilterButton,
  sortColumnsState,
  columnSettingOpen,
  workspace,
  onChangeWorkspace,
}: Props) => {
  return (
    <HeaderRoot>
      <Button
        onClick={onReload}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      <SlackWorkspaceSelect
        workspace={workspace}
        onChangeWorkspace={onChangeWorkspace}
      />
      <FilterButton
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
      />
      <SortButton
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortColumnsState}
      />
      <ColumnSettingButton
        onClick={onClickColumnSettingButton}
        open={columnSettingOpen}
      />
      <FavoriteColumnFilterButton onClick={onClickFavoriteColumnFilterButton} />
      <DisplayFormatButton height={rowHeight} onChangeHeight={onChangeHeight} />
    </HeaderRoot>
  )
}

export default ProjectNotificationSettingsHeader

const HeaderRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '10px',
  overflowX: 'auto',
  overflowY: 'hidden',
  minHeight: '47px',
})
