import { SlackChannel } from '../../../domain/value-object/Slack/ChannelVO'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { TicketListBasic } from '../../../lib/functions/ticketList'
import { generateUuid } from '../../../utils/uuids'
import { TreeRow } from '../../containers/BulkSheetView/model'

/**
 * RowData definition
 */
export interface ChatChannelMessageTicketCreationMappingRow extends TreeRow {
  projectUuid: string
  ticketType?: WbsItemTypeVO
  ticketList?: TicketListBasic
  chatChannel?: SlackChannel
}

/**
 * Row data functionality
 */
export const createNewChatChannelMessageTicketCreationMappingRow = (
  projectUuid: string
): ChatChannelMessageTicketCreationMappingRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [uuid],
    uuid,
    projectUuid,
  }
}
