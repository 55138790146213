import { intl } from '../../../../i18n'
import { DecimalAndSexagesimalScale } from '../../../../lib/functions/decimalAndSexagesimalScale'
import ExclusiveToggleGroup from '../../editors/toggle/ExclusiveToggleGroup'

type DecimalAndSexagesimalScaleToggleButtonGroupProps = {
  value: DecimalAndSexagesimalScale
  onChange: (_: DecimalAndSexagesimalScale) => void
}

export const DecimalAndSexagesimalScaleToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: DecimalAndSexagesimalScaleToggleButtonGroupProps) => {
  const onChange = (value: string) => {
    _onChange(value as DecimalAndSexagesimalScale)
  }
  return (
    <ExclusiveToggleGroup
      value={value}
      onChange={onChange}
      options={[
        {
          label: intl.formatMessage({
            id: 'myWbsItems.taskActualWork.decimal',
          }),
          value: DecimalAndSexagesimalScale.DECIMAL,
        },
        {
          label: intl.formatMessage({
            id: 'myWbsItems.taskActualWork.sexagesimal',
          }),
          value: DecimalAndSexagesimalScale.SEXAGESIMAL,
        },
      ]}
    />
  )
}
