import API, { APIResponse } from '../commons/api'
import EntitySearch from '../commons/entitySearch'
import { Tree } from '../commons/tree'
import { FunctionProperty } from '../commons/appFunction'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

// divisions

export interface ResponseOfGetTree {
  data: DivisionDetail[]
}

export interface DivisionBatchDeltaInput {
  added: DivisionInput[]
  edited: DivisionDeltaInput[]
  deleted: { uuid: string; lockVersion: number }[]
}

export interface DivisionBatchResponse {
  added: { uuid: string; lockVersion: number }[]
  edited: { uuid: string; lockVersion: number }[]
  deleted: { uuid: string }[]
}

export interface DivisionDetail extends Tree<DivisionDetail> {
  code: string
  displayName: string
  officialName: string
}

export interface DivisionInput {
  uuid: string
  lockVersion?: number
  revision?: string
  parentUuid?: string
  prevSiblingUuid?: string
  code?: string
  displayName: string
  officialName?: string
}

export interface DivisionDeltaInput {
  uuid: string
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
}

export interface GetDivisionsProps {
  displayName?: string
  officialName?: string
  offset?: number
  limit?: number
}

export interface DivisionGetDivisionsResponse {
  total: number
  hit: number
  data: DivisionProps[]
}

export interface DivisionProps {
  uuid: string
  displayName: string
  officialName: string
}

class Division extends EntitySearch {
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        name: query,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal('', () => undefined, this.toResponse)
  }

  toResponse = (division: DivisionProps) => {
    return { uuid: division.uuid, name: division.displayName }
  }

  entitySearchApi = (
    props: GetDivisionsProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/divisions/find',
      props,
      true,
      signal
    )
  }

  public getAll = async () => {
    return this.searchRequest.switch(signal =>
      API.functional.request(
        'GET',
        '/api/v1/divisions/descendant',
        {},
        true,
        signal
      )
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const division = response.find(v => v.uuid === uuid)
    return { status: 200, json: this.toResponse(division) }
  }

  updateBatchDelta(request: DivisionBatchDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/divisions/delta/batch',
      request
    )
  }
}

export default new Division()
