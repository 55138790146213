import { useCallback, useEffect, useState } from 'react'
import api from '../../lib/commons/api'
import { DevelopmentEventWbsItemStatusMappingRepository } from '../../applications/ports/developmentEventWbsItemStatusMappingRepository'
import { DevelopmentEventWbsItemStatusMappingEntity } from '../../domain/entity/DevelopmentEventWbsItemStatusMappingEntity'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../domain/value-object/WbsItemTypeVO'
import { UserBasic } from '../../lib/functions/user'
import { WbsItemStatus } from '../../domain/entity/WbsItemEntity'
import { DevelopmentEventVO } from '../../domain/value-object/development/DevelopmentEventVO'

export const useDevelopmentEventWbsItemStatusMappingRepository =
  (): DevelopmentEventWbsItemStatusMappingRepository => {
    const getByProjectUuid = useCallback(
      async (
        projectUuid: string
      ): Promise<DevelopmentEventWbsItemStatusMappingEntity[]> => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/development_event_wbs_item_status_mappings',
          { projectUuid }
        )
        const mappings: DevelopmentEventWbsItemStatusMappingsFetchResponse =
          response.json
        return mappings.map(v => {
          return {
            uuid: v.uuid,
            lockVersion: v.lockVersion,
            revision: v.revision,
            projectUuid: v.projectUuid,
            wbsItemType: new WbsItemTypeVO(v.wbsItemType),
            developmentEvent: v.developmentEvent as DevelopmentEventVO,
            wbsItemStatus: v.wbsItemStatus,
            wbsItemSubstatus: v.wbsItemSubstatus,
            createdAt: v.createdAt,
            createdBy: v.createdBy,
            updatedAt: v.updatedAt,
            updatedBy: v.updatedBy,
          }
        })
      },
      []
    )

    const save = useCallback(
      async ({
        added,
        edited,
        deleted,
      }: {
        added: DevelopmentEventWbsItemStatusMappingEntity[]
        edited: DevelopmentEventWbsItemStatusMappingEntity[]
        deleted: DevelopmentEventWbsItemStatusMappingEntity[]
      }): Promise<{ hasError: boolean; hasWarning: boolean }> => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/development_event_wbs_item_status_mappings/batch',
          {
            added: added.map(v => ({
              uuid: v.uuid,
              projectUuid: v.projectUuid,
              wbsItemTypeUuid: v.wbsItemType.uuid,
              developmentEvent: v.developmentEvent,
              wbsItemStatus: v.wbsItemStatus,
              wbsItemSubstatus: v.wbsItemSubstatus,
            })),
            edited: edited.map(v => ({
              uuid: v.uuid,
              projectUuid: v.projectUuid,
              wbsItemTypeUuid: v.wbsItemType.uuid,
              developmentEvent: v.developmentEvent,
              wbsItemStatus: v.wbsItemStatus,
              wbsItemSubstatus: v.wbsItemSubstatus,
            })),
            deleted: deleted.map(v => ({ uuid: v.uuid })),
          }
        )
        return {
          hasError: !!response.hasError,
          hasWarning: !!response.hasWarning,
        }
      },
      []
    )
    return {
      getByProjectUuid,
      save,
    }
  }

interface DevelopmentEventWbsItemStatusMappingObject {
  uuid: string
  lockVersion: number
  revision: string
  projectUuid: string
  wbsItemType: WbsItemTypeObject
  developmentEvent: string
  wbsItemStatus: WbsItemStatus
  wbsItemSubstatus: string
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
type DevelopmentEventWbsItemStatusMappingsFetchResponse =
  DevelopmentEventWbsItemStatusMappingObject[]
