import PageHeader, {
  Header,
  HeaderContent,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
}

type Props = OwnProps & SettingSpeedDialProps

const PermissionsHeader = ({
  onClickExport,
  rowHeight,
  onClickChangeRowHeight,
  toolbar,
  onChangeToolbar,
  isNotice,
}: Props) => {
  return (
    <PageHeader>
      <Header>
        <HeaderContent>
          <HeaderGroup>
            <IconGroup>
              <SettingSpeedDial
                onClickExport={onClickExport}
                rowHeight={rowHeight}
                onClickChangeRowHeight={onClickChangeRowHeight}
              />
            </IconGroup>
          </HeaderGroup>
          <ToolbarToggleGroup
            toggleItems={[ToolbarToggleValue.DISPLAY]}
            value={toolbar}
            onChange={onChangeToolbar}
            noticeItem={ToolbarToggleValue.DISPLAY}
            isNotice={isNotice}
          />
        </HeaderContent>
      </Header>
    </PageHeader>
  )
}

export default PermissionsHeader
