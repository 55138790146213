import { parse } from '../../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../../lib/functions/customEnumValue'
import TicketApi, { TicketType } from '../../../../../lib/functions/ticket'

export const RefinementSelectOptionType = {
  REFINEMENT: 'refinement',
  ENVIRONMENT: 'environment',
  IMPACT: 'impact',
  REFINEMENT_CAUSE: 'refinementCause',
  DETECTION_PHASE: 'detectionPhase',
  DOMAIN: 'domain',
  FEATURE_TYPE: 'featureType',
  IMPORTANCE: 'importance',
} as const

const fetchRefinementOptions = async projectUuid => {
  const response = await TicketApi.getTicketBasicList(
    projectUuid,
    TicketType.REFINEMENT_NEW
  )
  return response.map(v => ({
    ...v,
    name: '[' + v.code + '] ' + v.displayName,
    value: v.uuid,
  }))
}

const fetchCustomEnumOption = async (
  projectUuid: string,
  customEnumCode: CustomEnumCode
) => {
  const response = await getCustomEnumValues({
    customEnumCode,
    groupUuid: projectUuid,
  })
  return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
}

export const getRefinementSelectColumnOptions = async (projectUuid: string) => {
  const [
    refinement,
    environment,
    impact,
    refinementCause,
    detectionPhase,
    domain,
    featureType,
    importance,
  ] = await Promise.all([
    fetchRefinementOptions(projectUuid),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.ENVIRONMENT),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.IMPACT),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.REFINEMENT_CAUSE),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.DETECTION_PHASE),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.DOMAIN),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.FEATURE_TYPE),
    fetchCustomEnumOption(projectUuid, CustomEnumCode.IMPORTANCE),
  ])
  return {
    refinement,
    environment,
    impact,
    refinementCause,
    detectionPhase,
    domain,
    featureType,
    importance,
  }
}
