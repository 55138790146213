import { BreadCrumb } from '../../../../../store/breadcrumb'
import React, {
  ComponentProps,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ProjectPlanBasic,
  getProjectPlanBreadcrumbs,
} from '../../../../../lib/functions/projectPlan'
import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Typography,
  styled,
  Avatar,
  SelectChangeEvent,
} from '@mui/material'
import { FontWeight } from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import SeparatorIcon from '../../../../components/icons/SeparatorIcon'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import Select, {
  Props as SelectProps,
} from '../../../../components/editors/select/Select'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'

interface OwnProps {
  rootProjectPlanUuid: string
  onSelectBreadcrumb: (
    event: MouseEvent | Event,
    breadcrumb: BreadCrumb
  ) => void | Promise<void>
  optionsTypes?: WbsItemType[]
}

const ProjectPlanNewBreadcrumb = ({
  rootProjectPlanUuid,
  onSelectBreadcrumb,
  optionsTypes,
}: OwnProps) => {
  const { projectUuid, projectCode } = useProjectPrivateContext()
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb | undefined>(
    undefined
  )
  const [bottomBreadcrumbOptions, setBottomBreadcrumbOptions] = useState<
    BreadCrumb[] | undefined
  >(undefined)

  useEffect(() => {
    if (!projectUuid || !rootProjectPlanUuid) {
      setBreadcrumbs(undefined)
      setBottomBreadcrumbOptions(undefined)
      return undefined
    }
    getProjectPlanBreadcrumbs(
      projectUuid,
      rootProjectPlanUuid,
      projectCode
    ).then(breadcrumbInfo => {
      setBreadcrumbs(breadcrumbInfo?.breadcrumbs)
      const options = !optionsTypes
        ? breadcrumbInfo?.bottomBreadcrumbOptions
        : breadcrumbInfo?.bottomBreadcrumbOptions.filter(
            (breadcrumb: BreadCrumb) =>
              optionsTypes.includes(breadcrumb.type.rootType)
          )
      setBottomBreadcrumbOptions(options ?? [])
    })
  }, [projectUuid, projectCode, rootProjectPlanUuid, optionsTypes])

  if (!breadcrumbs) return <></>
  return (
    <Box
      sx={{
        padding: '8px 10px',
        background: '#D8DDE533',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        bottomBreadcrumbOptions={bottomBreadcrumbOptions}
        onSelectBreadcrumb={onSelectBreadcrumb}
      />
    </Box>
  )
}

type BreadcrumbProps = {
  breadcrumbs: BreadCrumb
  bottomBreadcrumbOptions: BreadCrumb[] | undefined
  onSelectBreadcrumb: (
    event: MouseEvent | Event,
    breadcrumb: BreadCrumb
  ) => void | Promise<void>
}

const Breadcrumbs = ({
  breadcrumbs,
  bottomBreadcrumbOptions,
  onSelectBreadcrumb,
}: BreadcrumbProps) => {
  const { project } = useProjectPrivateContext()
  const breadcrumbsElems = useMemo(() => {
    const createBreadcrumbs = (
      elements: JSX.Element[],
      breadcrumbs: BreadCrumb
    ): JSX.Element[] => {
      if (breadcrumbs.next) {
        return createBreadcrumbs(
          [
            ...elements,
            <WbsItemBreadcrumb
              key={breadcrumbs.uuid}
              iconUrl={breadcrumbs.type.iconUrl}
              name={breadcrumbs.name}
              onClick={event => onSelectBreadcrumb(event, breadcrumbs)}
            />,
          ],
          breadcrumbs.next
        )
      }
      return [
        ...elements,
        !bottomBreadcrumbOptions ? (
          <WbsItemBreadcrumb
            key={breadcrumbs.uuid}
            iconUrl={breadcrumbs.type.iconUrl}
            name={breadcrumbs.name}
          />
        ) : (
          <WbsItemBreadcrumbSelect
            key={breadcrumbs.uuid}
            value={breadcrumbs.uuid}
            options={bottomBreadcrumbOptions}
            onChange={(event: SelectChangeEvent<unknown>, _) => {
              const selectedBreadcrumb: BreadCrumb | undefined =
                bottomBreadcrumbOptions?.find(
                  (breadcrumb: BreadCrumb) =>
                    breadcrumb.uuid === event.target.value
                )
              if (selectedBreadcrumb) {
                onSelectBreadcrumb(event as Event, selectedBreadcrumb)
              }
            }}
          />
        ),
      ]
    }
    const elems = [
      <ProjectBreadcrumb
        key={project.uuid}
        name={project.displayName}
        iconUrl={project.iconUrl}
        onClick={
          event =>
            onSelectBreadcrumb(event, {
              uuid: undefined,
            } as unknown as BreadCrumb) // TODO: Reconsider the schema
        }
      />,
    ]
    elems.push(...createBreadcrumbs([], breadcrumbs))
    return elems
  }, [
    breadcrumbs,
    bottomBreadcrumbOptions,
    onSelectBreadcrumb,
    project.displayName,
    project.iconUrl,
    project.uuid,
  ])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <MuiBreadcrumbs separator={<SeparatorIcon />}>
        {breadcrumbsElems}
      </MuiBreadcrumbs>
      <div style={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
        <SeparatorIcon />
      </div>
    </Box>
  )
}

type BreadcrumbComponentProps = {
  name: string
  iconUrl?: string
  onClick?: (event: MouseEvent) => void
}
const BreadcrumbComponentRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const BreadcrumbComponentTypography = styled(Typography)({
  color: colorPalette.monotone[10],
  fontWeight: FontWeight.NORMAL,
  textDecoration: 'none',
})
const BreadcrumbComponentLink = styled(
  ({
    onClick,
    children,
  }: {
    onClick: MouseEventHandler<HTMLAnchorElement>
    children?: React.ReactNode
  }) => (
    // @ts-ignore
    <Link color="inherit" component="button" target="_blank" onClick={onClick}>
      {children}
    </Link>
  )
)({
  color: colorPalette.monotone[4],
  fontWeight: FontWeight.NORMAL,
  textDecoration: 'none',
})
const BreadcrumbComponentName = ({
  children,
  onClick,
}: PropsWithChildren<Pick<BreadcrumbComponentProps, 'onClick'>>) => {
  return !!onClick ? (
    <BreadcrumbComponentLink onClick={onClick}>
      {children}
    </BreadcrumbComponentLink>
  ) : (
    <BreadcrumbComponentTypography>{children}</BreadcrumbComponentTypography>
  )
}
const WbsItemBreadcrumb = ({
  name,
  iconUrl,
  onClick,
}: BreadcrumbComponentProps) => {
  return (
    <BreadcrumbComponentRoot>
      <img
        src={iconUrl}
        style={{
          marginRight: '5px',
        }}
      />
      <BreadcrumbComponentName onClick={onClick}>
        {name}
      </BreadcrumbComponentName>
    </BreadcrumbComponentRoot>
  )
}
const ProjectBreadcrumb = ({
  name,
  iconUrl,
  onClick,
}: BreadcrumbComponentProps) => {
  return (
    <BreadcrumbComponentRoot>
      <Avatar
        src={iconUrl}
        style={{
          margin: '0 5px 0 0',
          height: '20px',
          width: '20px',
        }}
      />
      <BreadcrumbComponentName onClick={onClick}>
        {name}
      </BreadcrumbComponentName>
    </BreadcrumbComponentRoot>
  )
}

type WbsItemBreadcrumbSelectProps = {
  value: string
  options: BreadCrumb[] | undefined
}

const WbsItemBreadcrumbSelect = ({
  value,
  options,
  onChange,
}: WbsItemBreadcrumbSelectProps & SelectProps) => {
  return (
    <Select
      value={value}
      options={options}
      getOptionValue={(option: ProjectPlanBasic) => option.uuid}
      getOptionLabel={(option: ProjectPlanBasic) => option.name}
      getOptionIconUrl={(option: ProjectPlanBasic) => option.type.iconUrl}
      onChange={onChange}
      sx={{
        display: 'flex',
        '& .MuiSelect-select': {
          display: 'inline-flex',
          padding: '0',
        },
        '& .MuiAvatar-root': {
          marginLeft: '0',
        },
        '& .MuiTypography-root': {
          lineHeight: '20px !important',
        },
      }}
    />
  )
}

export default ProjectPlanNewBreadcrumb
