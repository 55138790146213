import { useCallback, useState } from 'react'

type DialogStateCallback = [
  open: boolean,
  openDialog: () => void,
  closeDialog: () => void
]

export const useDialogState = (): DialogStateCallback => {
  const [open, setOpen] = useState<boolean>(false)
  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])
  const closeDialog = useCallback(() => {
    setOpen(false)
  }, [])
  return [open, openDialog, closeDialog]
}
