import { useState, useEffect, useCallback } from 'react'
import {
  WbsItemEntity,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WbsItemFormModel, createInitialValue, toDeltaInput } from '../model'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import { useDispatch } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import { intl } from '../../../../i18n'

export const useWbsItemSingleSheetData = (uuid: string) => {
  const { getDetail, updateDelta } = useWbsItemRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<WbsItemEntity | undefined>()
  const [formModel, setFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK)
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await getDetail(uuid)
    setEntity(response)
    setFormModel(response)
    setIsLoading(false)
  }, [getDetail, uuid])
  useEffect(() => {
    fetchData()
    setInitialized(true)
  }, [fetchData, uuid])
  const onChange = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
    },
    []
  )
  const dispatch = useDispatch()
  const submit = useCallback(async () => {
    if (!entity) return
    setIsLoading(true)
    const input = toDeltaInput(entity, formModel)
    const request = {
      input,
      watchers: (formModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: uuid,
        tagUuids: (formModel.tags || []).map(tag => tag.uuid),
      },
    }
    await updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
    await fetchData()
    setIsLoading(false)
  }, [dispatch, entity, fetchData, formModel, updateDelta, uuid])
  return {
    initialized,
    isLoading,
    formModel,
    onChange,
    reload: fetchData,
    submit,
  }
}
