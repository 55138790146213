import React from 'react'
import { Divider, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { FontWeight } from '../../../../styles/commonStyles'

const StyledTableHead = styled(TableHead)({
  width: '100%',
  height: '21px',
  backgroundColor: '#F2F7F8',
})
const StyledTableCell = styled(TableCell)({
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  fontSize: '11px',
  fontWeight: FontWeight.BOLD,
})
const StyledDivider = styled(Divider)({
  width: '2px',
  height: '11px',
  margin: '0 5px',
  backgroundColor: '#0073A8',
})
const HeaderRow = (props: { title: string }) => {
  const { title } = props
  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell colSpan={4}>
          <StyledDivider />
          {title}
        </StyledTableCell>
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </StyledTableHead>
  )
}

export default HeaderRow
