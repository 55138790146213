import { type } from 'os'
import API, { APIResponse } from './api'

export interface RequestOfGetStates {
  applicationFunctionUuid?: string
  key: string
  scope: string
}

export interface RequestOfGetAllStates {
  applicationFunctionUuid: string
}

export interface RequestOfUpdateStates {
  key: string
  value: string
  scope: string
}

export interface UiStateProps {
  key: string
  tenant: string
  value: string
}

export enum UiStateKey {
  ProjectPlanColumnAndFilterState = 'PROJECT_PLAN_COLUMN_AND_FILTER_STATE',
  ProjectPlanTabColumnAndFilterState = 'PROJECT_PLAN_TAB_COLUMN_AND_FILTER_STATE',
  SprintColumnAndFilterState = 'SPRINT_COLUMN_AND_FILTER_STATE',
  TaskActualWorkColumnAndFilterState = 'TASK_ACTUAL_WORK_COLUMN_AND_FILTER_STATE',
  WbsItemSearchColumnAndFilterState = 'WBS_ITEM_SEARCH_COLUMN_AND_FILTER_STATE',
  OrganizationWorkingDayCalendarColumnAndFilterState = 'ORGANIZATION_WORKING_DAY_CALENDAR_COLUMN_AND_FILTER_STATE',
  PositionUuidsColumnAndFilterState = 'POSITION_COLUMN_AND_FILTER_STATE',
  ResourcePlanColumnAndFilterState = 'RESOURCE_PLAN_COLUMN_AND_FILTER_STATE',
  ScheduledOperationTimeColumnAndFilterState = 'SCHEDULED_OPERATION_TIME_COLUMN_AND_FILTER_STATE',
  DivisionColumnAndFilterState = 'DIVISION_COLUMN_AND_FILTER_STATE',
  TeamColumnAndFilterState = 'TEAM_COLUMN_AND_FILTER_STATE',
  PermissionColumnAndFilterState = 'PERMISSION_COLUMN_AND_FILTER_STATE',
  ProjectMemberColumnAndFilterState = 'PROJECT_MEMBER_COLUMN_AND_FILTER_STATE',
  SprintReportColumnAndFilterState = 'SPRINT_REPORT_COLUMN_AND_FILTER_STATE',
  WorkReportColumnAndFilterState = 'WORK_REPORT_COLUMN_AND_FILTER_STATE',
  ProgressReportTableColumnAndFilterState = 'PROGRESS_REPORT_TABLE_COLUMN_AND_FILTER_STATE',
  TagColumnAndFilterState = 'TAG_COLUMN_AND_FILTER_STATE',
  UserColumnAndFilterState = 'USER_COLUMN_AND_FILTER_STATE',
  ProjectColumnAndFilterState = 'PROJECT_COLUMN_AND_FILTER_STATE',
  PropertyMaintenanceColumnAndFilterState = 'PROPERTY_MAINTENANCE_COLUMN_AND_FILTER_STATE',

  BusinessClientColumnAndFilterState = 'BUSINESS_CLIENTS_COLUMN_AND_FILTER_STATE',

  TicketColumnAndFilterState = 'TICKET_COLUMN_AND_FILTER_STATE',
  RefinementListColumnAndFilterState = 'REFINEMENT_LIST_COLUMN_AND_FILTER_STATE',
  IssueListColumnAndFilterState = 'ISSUE_LIST_COLUMN_AND_FILTER_STATE',
  RiskListColumnAndFilterState = 'RISK_LIST_COLUMN_AND_FILTER_STATE',
  ChangeRequestListColumnAndFilterState = 'CHANGE_REQUEST_LIST_COLUMN_AND_FILTER_STATE',
  MeetingListColumnAndFilterState = 'MEETING_LIST_COLUMN_AND_FILTER_STATE',
  RefinementListSearchConditionState = 'REFINEMENT_LIST_SEARCH_CONDITION_STATE',
  IssueListSearchConditionState = 'ISSUE_LIST_SEARCH_CONDITION_STATE',
  RiskListSearchConditionState = 'RISK_LIST_SEARCH_CONDITION_STATE',
  ChangeRequestListSearchConditionState = 'CHANGE_REQUEST_LIST_SEARCH_CONDITION_STATE',
  MeetingListSearchConditionState = 'MEETING_LIST_SEARCH_CONDITION_STATE',
  TicketListSearchColumnAndFilterState = 'TICKET_LIST_SEARCH_COLUMN_AND_FILTER_STATE',
  MultilineTextEditor = 'MULTILINE_TEXT_EDITOR',

  Template = 'TEMPLATE',
  EditorUiState = 'EDITOR_UI_STATE',

  // These are used for ui state which is saved manually by user.
  BulkSheetUIStateSearchCondition = 'BULK_SHEET_UI_STATE_SEARCH_CONDITION',
  BulkSheetUIStateColumnAndFilter = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
  BulkSheetSelectedUIState = 'BULK_SHEET_SELECTED_UI_STATE',

  // These are used for ui state which is saved automatically.
  BulkSheetState = 'BULK_SHEET_STATE',
  BulkSheetSearchCondisionState = 'BULK_SHEET_SEARCH_CONDITION_STATE',

  WorkLoadUnitSelectState = 'WORK_LOAD_UNIT_SELECT_STATE',

  PageState = 'PAGE_STATE',

  ProgressReportUIStateCondition = 'PROGRESS_REPORT_UI_STATE_CONDITION',
  ProgressChartUIStateCondition = 'PROGRESS_CHART_UI_STATE_CONDITION',

  ProgressReportChartUIState = 'PROGRESS_REPORT_CHART_UI_STATE',

  MyTaskColumnAndFilterState = 'MY_TASK_COLUMN_AND_FILTER_STATE',
  IdProvidersColumnAndFilterState = 'IDPROVIDERS_COLUMN_AND_FILTER_STATE',

  SideMenuOpenState = 'SIDE_MENU_OPEN_STATE',

  ResourcePlanMemberView = ResourcePlanColumnAndFilterState,
  ResourcePlanTeamView = 'RESOURCE_PLAN_COLUMN_AND_FILTER_STATE_TEAM_VIEW',
  BulkSheetUIStateColumnAndFilterResourcePlanMemberView = BulkSheetUIStateColumnAndFilter,
  BulkSheetUIStateColumnAndFilterResourcePlanTeamView = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_RESOURCE_PLAN_TEAM_VIEW',

  ResourcePlanCrossProjectsMemberView = 'RESOURCE_PLAN_CROSS_PROJECTS_MEMBER_VIEW',
  ResourcePlanCrossProjectsProjectView = 'RESOURCE_PLAN_CROSS_PROJECTS_PROJECT_VIEW',
  BulkSheetUIStateColumnAndFilterResourcePlanCPMemberView = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_RESOURCE_PLAN_CP_MEMBER_VIEW',
  BulkSheetUIStateColumnAndFilterResourcePlanCPProjectView = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_RESOURCE_PLAN_CP_PROJECT_VIEW',

  LastSelectedTicketTypeToAddTicket = 'LAST_SELECTED_TICKET_TYPE_TO_ADD_TICKET',
  LastSelectedTicketListToAddTicket = 'LAST_SELECTED_TICKET_LIST_TO_ADD_TICKET',

  InformationViewState = 'INFORMATION_VIEW_STATE',
  WbsItemCommentUserSummaryViewState = 'WBS_ITEM_COMMENT_USER_SUMMARY_VIEW_STATE',

  WbsItemCommentUserSummaryLastOpenedAt = 'WBS_ITEM_COMMENT_USER_SUMMARY_LAST_OPENED_AT',

  LedgerAccounts = 'LEDGER_ACCOUNTS',
  ProfitLossItems = 'PROFIT_LOSS_ITEMS',
  ProfitLossMembers = 'PROFIT_LOSS_MEMBERS',
  ProfitLossSummary = 'PROFIT_LOSS_SUMMARY',
  UnitPricePerPosition = 'UNIT_PRICE_PER_POSITION',
  UnitPricePerInHouseMember = 'UNIT_PRICE_PER_IN_HOUSE_MEMBER',
  UnitPricePerPartnerMember = 'UNIT_PRICE_PER_PARTNER_MEMBER',
  UserPositions = 'USER_POSITIONS',
  UnitPricePerUsers = 'UNIT_PRICE_PER_USERS',
  UnitPricePerProjectPartners = 'UNIT_PRICE_PER_PROJECT_PARTNERS',

  MyWbsItemsTaskTab = 'MY_WBS_ITEMS_TASK_TAB',
  MyWbsItemsDeliverableTab = 'MY_WBS_ITEMS_DELIVERABLE_TAB',
  MyWbsItemsWatcherTab = 'MY_WBS_ITEMS_WATCHER_TAB',
  BulkSheetUIStateColumnAndFilterMyWbsItemsTaskTab = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_MY_WBS_ITEMS_TASK_TAB',
  BulkSheetUIStateColumnAndFilterMyWbsItemsDeliverableTab = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_MY_WBS_ITEMS_DELIVERABLE_TAB',
  BulkSheetUIStateColumnAndFilterMyWbsItemsWatcherTab = 'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER_MY_WBS_ITEMS_WATCHER_TAB',
}

export enum UiStateScope {
  CrossProject = 'CROSS_PROJECT',
  Tenant = 'TENANT',
  User = 'USER',
}

class UiStates {
  get(request: RequestOfGetStates): Promise<APIResponse> {
    return API.presentational.request('GET', '/api/v1/ui_states', request)
  }

  getAll(request: RequestOfGetAllStates): Promise<APIResponse> {
    return API.presentational.request('GET', '/api/v1/ui_states/all', request)
  }

  update(
    request: RequestOfUpdateStates,
    applicationFunctionUuid?: string
  ): Promise<APIResponse> {
    return API.presentational.request(
      'POST',
      `/api/v1/ui_states?${
        applicationFunctionUuid
          ? 'applicationFunctionUuid=' + applicationFunctionUuid
          : ''
      }`,
      request
    )
  }
}

export const generateCode = (existingCode: string[]): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const length = 8
  const generateCode = () => {
    return Array.from(crypto.getRandomValues(new Uint8Array(length)))
      .map(n => characters[n % characters.length])
      .join('')
  }
  let code = generateCode()
  while (existingCode.includes(code)) {
    code = generateCode()
  }
  return code
}

type UiState = any

export const getUiState = async (
  applicationFunctionUuid: string,
  key: string,
  scope: UiStateScope
): Promise<UiState> => {
  const request = {
    applicationFunctionUuid,
    key,
    scope,
  }
  const response = await uiStates.get(request)
  if (!response.json.value) return {}
  return JSON.parse(response.json.value)
}

export const updateUiState = async (value, uuid) => {
  const request: RequestOfUpdateStates = {
    key: UiStateKey.PageState,
    scope: UiStateScope.User,
    value: JSON.stringify(value),
  }
  uiStates.update(request, uuid)
}

const uiStates = new UiStates()

export default uiStates
