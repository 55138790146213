import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import { SprintDetail, SprintStatus } from '../../../lib/functions/sprint'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import SprintOptions, {
  ColumnQuickFilterKey,
  QuickFilterKey,
  SprintRow,
  SprintState,
} from './SprintOptions'
import SprintHeader from './Header'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ColDef } from 'ag-grid-community'
import { Collapse } from '@mui/material'
import SprintToolbar from './Toolbar'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { usePageState } from '../../hooks/usePageState'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { pageComponent } from '../../higher-order-components/pageComponent'

type SprintPageState = {
  toolbar: ToolbarToggleValue | undefined
  dateFormat: string
  quickFilters: QuickFilterKey[]
}

const sprintDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  quickFilters: [],
}

const Sprint = (props: PageProps) => {
  const { project } = useProjectPrivateContext()
  const sprintOptions = useMemo(() => new SprintOptions(), [])

  const bulkSheet = useRef<
    | BulkSheet<BulkSheetSpecificProps, SprintDetail, SprintRow, SprintState>
    | undefined
  >()

  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { toolbar, dateFormat, quickFilters, updatePageState } =
    usePageState<SprintPageState>(
      props.uuid,
      sprintDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )
  const onChangeDateFormat = useCallback((dateFormat: string) => {
    updatePageState({ dateFormat })
  }, [])
  const onChangeQuickFilters = useCallback((quickFilters: QuickFilterKey[]) => {
    updatePageState({ quickFilters })
  }, [])

  const onChangeColumnFilter = useCallback((value: ColumnQuickFilterKey) => {
    if (!bulkSheet.current) return
    if (value === ColumnQuickFilterKey.INITIAL) {
      bulkSheet.current.resetColumnAndFilterState()
    } else if (value === ColumnQuickFilterKey.RESTORE) {
      bulkSheet.current.openSavedBulkSheetUIStateDialog(
        UiStateKey.BulkSheetUIStateColumnAndFilter
      )
    }
  }, [])

  const applyQuickFilters = useCallback(() => {
    if (!bulkSheet.current) return
    const filter = bulkSheet.current.gridApi!.getFilterInstance('status')
    if (quickFilters.length === 0) {
      filter!.setModel(null)
    } else {
      const notDoneStatuses = [SprintStatus.INPROGRESS, SprintStatus.STANDBY]
      filter!.setModel({
        filterType: 'set',
        values: notDoneStatuses,
      })
    }
    bulkSheet.current.gridApi!.onFilterChanged()
  }, [quickFilters])

  useEffect(() => {
    applyQuickFilters()
  }, [quickFilters])

  useEffect(() => {
    if (!bulkSheet.current) return
    bulkSheet.current.setContext({ dateFormat })
    bulkSheet.current.gridApi?.refreshCells({
      columns: ['startDate', 'endDate'],
      force: true,
    })
  }, [bulkSheet, dateFormat])

  return (
    <PageArea>
      <SprintHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() =>
          bulkSheet.current?.openExcelOutputColumnSelectDialog()
        }
        rowHeight={rowHeight}
        onClickChangeRowHeight={value =>
          bulkSheet.current?.onChangeHeight(value)
        }
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet.current?.onSubmit()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onCancel={() => bulkSheet.current?.onCancel()}
        onRestoreSearchCondition={() =>
          bulkSheet.current?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateSearchCondition
          )
        }
        quickFilters={quickFilters}
        onChangeQuickFilters={onChangeQuickFilters}
        projectUuid={project.uuid}
        onTeamChanged={teamUuid => {
          sprintOptions.onChangeTeam(teamUuid, bulkSheet.current!)
        }}
        teamUuid={bulkSheet.current?.state.teamUuid}
        currentFormat={dateFormat}
        onClickChangeDateFormat={onChangeDateFormat}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <SprintToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet.current?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={sprintOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => {
          bulkSheet.current = ctx
        }}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
        applyQuickFilters={applyQuickFilters}
      />
    </PageArea>
  )
}

export default pageComponent(projectPrivate(Sprint))
