import {
  Chip,
  ChipProps,
  ClickAwayListener,
  IconButton,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import { TeamSelectOption } from '../../model'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export type BurndownChartTeamSelectHeaderProps = {
  teamOptions: TeamSelectOption[]
  selected: string | undefined
  onSelect: (v: string | undefined) => void
}
export const BurndownChartTeamSelectHeader = ({
  teamOptions,
  selected,
  onSelect,
}: BurndownChartTeamSelectHeaderProps) => {
  return (
    <RootArea>
      <ChipArea>
        {teamOptions.map((team, i) => (
          <TeamChip
            key={`teamChip-${i}`}
            team={team}
            isSelected={team.value === selected}
            onSelect={onSelect}
          />
        ))}
      </ChipArea>
    </RootArea>
  )
}

type TeamChipProps = {
  team: TeamSelectOption
  isSelected: boolean
  onSelect: (v: string | undefined) => void
}
const TeamChip = ({ team, isSelected, onSelect }: TeamChipProps) => {
  const onClick = useMemo(() => {
    if (isSelected) return
    return () => {
      onSelect(team.value)
    }
  }, [isSelected, onSelect, team.value])
  const onDelete = useMemo(() => {
    if (!isSelected) return
    return () => {
      onSelect(undefined)
    }
  }, [isSelected, onSelect])
  return <StyledChip label={team.label} onClick={onClick} onDelete={onDelete} />
}

const RootArea = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const ChipArea = styled('div')({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  overflowX: 'scroll',
})
const StyledChip = styled((props: ChipProps) => (
  <Chip
    {...props}
    size="small"
    deleteIcon={
      <DeleteIconWrapper>
        <StyledDeleteIcon />
      </DeleteIconWrapper>
    }
  />
))({
  '&.MuiChip-filled': {
    backgroundColor: colorPalette.monotone[1],
    color: colorPalette.monotone[5],
  },
  '&.MuiChip-deletable': {
    backgroundColor: colorPalette.skyBlue[1],
    color: colorPalette.skyBlue[8],
    border: `1px solid ${colorPalette.skyBlue[7]}`,
  },
})
const StyledDeleteIcon = styled(CloseRoundedIcon)({
  color: colorPalette.monotone[4],
  width: '14px',
})
const DeleteIconWrapper = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '7px',
  width: '14px',
  height: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
