import api from '../../lib/commons/api'
import { useCallback } from 'react'
import { IdProviderRepository } from '../../applications/ports/idProviderRepository'
import Auth from '../../lib/commons/auth'

export const useIdProviderRepository = (): IdProviderRepository => {
  const getIdProviders = useCallback(async () => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/id_providers',
      undefined,
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
    return response.json
  }, [])
  return { getIdProviders }
}
