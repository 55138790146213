import * as d3 from 'd3'

export type DateV2 = Date

const newDateFromString = (src: string): DateV2 => {
  return new Date(src)
}

const fromApiResponseToVo = (src: string | undefined): DateV2 | undefined => {
  if (!src) return undefined
  return d3.timeDay.floor(new Date(src))
}

const format = (src: DateV2): string => {
  const year = src.getFullYear()
  const month = src.getMonth() + 1
  const fullMonth = month < 10 ? `0${month}` : month.toString()
  const date = src.getDate()
  const fullDate = date < 10 ? `0${date}` : date.toString()
  return `${year}/${fullMonth}/${fullDate}`
}

const isEqual = (a: DateV2 | undefined, b: DateV2 | undefined): boolean => {
  if (!a && !b) return true
  if (!a || !b) return false
  return a.valueOf() === b.valueOf()
}

export const dateV2Service = {
  newDateFromString,
  format,
  fromApiResponseToVo,
  isEqual,
}
