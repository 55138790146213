import React from 'react'
import {
  Button as MuiButton,
  ButtonGroup as MuiButtonGroup,
} from '@mui/material'
import { styled } from '@mui/system'
import {
  BackgroundColor,
  BorderColor,
  TextColor,
} from '../../../../styles/commonStyles'

const RootContainer = styled('div')({
  width: '100%',
  display: 'flex',
  marginRight: '6px',
  alignItems: 'center',
})
const Button = styled(MuiButton)<{ selected: boolean }>(({ selected }) => ({
  height: '24px',
  textTransform: 'none',
  borderRadius: '17px',
  padding: '0 10px',
  borderColor: BorderColor.GREY,
  background: selected
    ? BackgroundColor.BLUE_GRADATION
    : '#FFFFFF 0% 0% no-repeat padding-box',
  color: selected ? TextColor.WHITE : '#868686',
}))
const ButtonLabel = styled('span')({
  whiteSpace: 'nowrap',
})

export const ButtonGroup = (props: {
  value: any
  options: { label: string; value: any; icon?: JSX.Element }[]
  onChange: (value: any) => void
  disabled?: boolean
}) => {
  const { value, options, onChange, disabled } = props
  return (
    <RootContainer>
      <MuiButtonGroup disableElevation={true} disabled={disabled}>
        {options.map((u, i) => (
          <Button
            key={i}
            selected={u.value === value}
            onClick={() => onChange(u.value)}
          >
            {u.icon}
            <ButtonLabel>{u.label}</ButtonLabel>
          </Button>
        ))}
      </MuiButtonGroup>
    </RootContainer>
  )
}
