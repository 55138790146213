import { DailyWbsPivotData } from '../../../../domain/value-object/BurndownChart/index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BurndownChartCondition } from '../model'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'
import { BurndownChartDiffDetailResult } from '../../../../domain/value-object/BurndownChart'
import { WbsPivotValue } from '../../../../domain/value-object/WbsPivot'
import { DatewiseData } from '../../../components/charts/model/timeSeries'
import * as d3 from 'd3'

export type BurndownChartDiffDetail = {
  delayed: DatewiseData<number>[]
  preceding: DatewiseData<number>[]
}
export const useDiffDetail = (
  condition: BurndownChartCondition,
  getValue: (v: WbsPivotValue) => number
): BurndownChartDiffDetail => {
  const [src, setSrc] = useState<BurndownChartDiffDetailResult | undefined>()
  const { getDiffDetail } = useBurndownChartRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await getDiffDetail(condition)
      setSrc(response)
    }
    fn()
  }, [condition, getDiffDetail])

  const diffDetail = useMemo(() => {
    if (!src) {
      return {
        delayed: [],
        preceding: [],
      }
    }
    const delayed = aggregate(src.delayedData).map(d => ({
      date: d.date,
      value: getValue(d.value),
    }))
    const preceding = aggregate(src.precedingData).map(d => ({
      date: d.date,
      value: getValue(d.value),
    }))
    return {
      delayed,
      preceding,
    }
  }, [getValue, src])
  return diffDetail
}

const aggregate = (
  data: DailyWbsPivotData[]
): DatewiseData<WbsPivotValue>[] => {
  const sorted = [...data].sort((a, b) => a.date.valueOf() - b.date.valueOf())
  if (sorted.length === 0) return []
  const result: DatewiseData<WbsPivotValue>[] = []
  result.push({
    date: sorted[0].date,
    value: sorted[0].value,
  })

  const from = d3.timeDay.floor(sorted[0].date)
  const to = d3.timeDay.floor(sorted[sorted.length - 1].date)
  const interval = d3.timeDay.count(from, to)
  let prev = sorted[0].value
  let arrayIndex = 1
  for (let i = 1; i < interval; i++) {
    const date = d3.timeDay.offset(from, i)
    const d = sorted[arrayIndex]
    if (d.date.valueOf() === date.valueOf()) {
      const value = {
        count: prev.count + d.value.count,
        workload: prev.workload + d.value.workload,
      }
      result.push({
        date,
        value,
      })
      prev = value
      arrayIndex++
      continue
    }
    result.push({
      date,
      value: prev,
    })
  }
  return result
}
