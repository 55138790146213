import { CircularProgress, styled } from '@mui/material'
import { DateBound } from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import React, { useCallback, useMemo } from 'react'
import { intl } from '../../../../i18n'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { ProjectDetail } from '../../../../lib/functions/project'
import {
  KpiContainer,
  KpiTitle,
  KpiUnit,
  KpiValue,
  KpiWithUnit,
  WidgetAlternative,
  WidgetArea,
  WidgetWithTitleWrapper,
} from '../components'
import { ProjectReportConfig } from '../model/config'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'
import DateVO from '../../../../vo/DateVO'
import { useSummaryData } from './hooks/summaryData'

type Props = {
  project: ProjectDetail
} & ProjectReportConfig

export const SummaryInformation = ({
  project,
  aggregateRoot,
  aggregateTarget,
  aggregateField,
  workloadUnit,
  aggregateBaseDate,
}: Props) => {
  const {
    progress,
    periodicProgressRate,
    resourceTotal,
    resourceConsumed,
    totalCost,
    actualRecordedCost,
    criticalRiskCount,
    fetching,
  } = useSummaryData({
    project,
    aggregateTarget,
    aggregateField,
    aggregateRoot,
  })
  const workloadUnitState = useWorkloadUnit(workloadUnit)

  const progressLabel = useCallback(
    (id: string) => intl.formatMessage({ id: `projectOverview.${id}` }),
    []
  )

  const formatDate = useCallback((date: string): string => {
    return new DateVO(date).format('YY/MM/DD')
  }, [])

  const formatResource = useCallback(
    (value: number): string => {
      return `${(value / workloadUnitState.hoursPerSelectedUnit).toFixed(1)}`
    },
    [workloadUnitState.hoursPerSelectedUnit]
  )

  const formatWorkload = useCallback(
    (value: number): string => {
      if (aggregateField === AggregateField.WBS_ITEM_COUNT) {
        return `${value.toFixed(1)}`
      }
      return `${(value / workloadUnitState.hoursPerSelectedUnit).toFixed(1)}`
    },
    [aggregateField, workloadUnit, workloadUnitState.hoursPerSelectedUnit]
  )

  const formatPercentage = useCallback((value: number | undefined): string => {
    if (!value) return '-'
    return `${(value * 100).toFixed(1)}`
  }, [])

  const workloadUnitLabel = useMemo(() => {
    switch (workloadUnit) {
      case WorkloadUnit.HOUR:
        return intl.formatMessage({ id: 'workloadUnit.hour' })
      case WorkloadUnit.DAY:
        return intl.formatMessage({ id: 'workloadUnit.day' })
      case WorkloadUnit.MONTH:
        return intl.formatMessage({ id: 'workloadUnit.month' })
    }
  }, [workloadUnit])

  const countAndWorkloadUnitLabel = useMemo(() => {
    if (aggregateField === AggregateField.WBS_ITEM_COUNT) {
      return ''
    }
    return workloadUnitLabel
  }, [aggregateField, workloadUnitLabel])

  const { truncateDigit, costUnit } = useMemo(() => {
    const digit = Math.floor(Math.log10(totalCost)) + 1
    if (digit > 9) {
      return {
        truncateDigit: 9,
        costUnit: intl.formatMessage({ id: 'unit.amount.billion' }),
      }
    }
    if (digit > 6) {
      return {
        truncateDigit: 6,
        costUnit: intl.formatMessage({ id: 'unit.amount.million' }),
      }
    }
    if (digit > 3) {
      return {
        truncateDigit: 3,
        costUnit: intl.formatMessage({ id: 'unit.amount.thousand' }),
      }
    }
    return {
      truncateDigit: 3,
      costUnit: intl.formatMessage({ id: 'unit.amount' }),
    }
  }, [totalCost])
  const { formattedTotalCost, formattedActualRecordedCost } = useMemo(() => {
    const format = (src: number) => (src / 10 ** truncateDigit).toFixed(1)
    return {
      formattedTotalCost: format(totalCost),
      formattedActualRecordedCost: format(actualRecordedCost),
    }
  }, [actualRecordedCost, totalCost, truncateDigit])

  return (
    <WidgetWithTitleWrapper>
      <SummaryWidgetArea>
        {fetching && (
          <WidgetAlternative>
            <CircularProgress />
          </WidgetAlternative>
        )}
        {!fetching && progress && (
          <>
            <KpiContainer sx={{ width: '240px' }}>
              <KpiTitle>{intl.formatMessage({ id: 'scheduledTerm' })}</KpiTitle>
              <KpiWithUnit>
                <KpiUnit sx={{ margin: '5px 5px 0' }}>{'from'}</KpiUnit>
                <SummaryDateValue>
                  {formatDate(project.scheduledDate.startDate)}
                </SummaryDateValue>
                <KpiUnit sx={{ margin: '5px 5px 0' }}>{'to'}</KpiUnit>
                <SummaryDateValue>
                  {formatDate(project.scheduledDate.endDate)}
                </SummaryDateValue>
              </KpiWithUnit>
            </KpiContainer>
            <ProgressValue
              label={intl.formatMessage({ id: 'periodicProgressRate' })}
              value={formatPercentage(periodicProgressRate)}
              unit={'%'}
            />
            <ProgressValue
              label={progressLabel('scheduledProgressRate')}
              value={formatPercentage(
                aggregateBaseDate === DateBound.END
                  ? progress.scheduledProgressRate
                  : progress.scheduledStartRate
              )}
              unit={'%'}
            />
            <ProgressValue
              label={progressLabel('progressRate')}
              value={formatPercentage(
                aggregateBaseDate === DateBound.END
                  ? progress.progressRate
                  : progress.startRate
              )}
              unit={'%'}
            />
            <ProgressValue
              label={intl.formatMessage({ id: 'resourceTotal' })}
              value={formatResource(resourceTotal)}
              unit={workloadUnitLabel}
            />
            <KpiContainer sx={{ width: '100px' }}>
              <KpiTitle>
                {intl.formatMessage({ id: 'resourceConsumed' })}
              </KpiTitle>
              <KpiWithUnit>
                <SummaryValue>{formatResource(resourceConsumed)}</SummaryValue>
                <KpiUnit>{workloadUnitLabel}</KpiUnit>
              </KpiWithUnit>
            </KpiContainer>
            <KpiContainer sx={{ width: '100px' }}>
              <KpiTitle>{intl.formatMessage({ id: 'totalCost' })}</KpiTitle>
              <KpiWithUnit>
                <SummaryValue>{formattedTotalCost}</SummaryValue>
                <KpiUnit>{costUnit}</KpiUnit>
              </KpiWithUnit>
            </KpiContainer>
            <KpiContainer sx={{ width: '100px' }}>
              <KpiTitle>
                {intl.formatMessage({ id: 'actualRecordedCost' })}
              </KpiTitle>
              <KpiWithUnit>
                <SummaryValue>{formattedActualRecordedCost}</SummaryValue>
                <KpiUnit>{costUnit}</KpiUnit>
              </KpiWithUnit>
            </KpiContainer>
            <ProgressValue
              label={progressLabel(
                aggregateBaseDate === DateBound.END ? 'delayed' : 'startDelayed'
              )}
              value={formatWorkload(
                aggregateBaseDate === DateBound.END
                  ? progress.delayed
                  : progress.startDelayed
              )}
              unit={countAndWorkloadUnitLabel}
            />
            <KpiContainer sx={{ width: '130px' }}>
              <KpiTitle>
                {intl.formatMessage({ id: 'risk.count.over.0.8' })}
              </KpiTitle>
              <KpiWithUnit>
                <SummaryValue>{criticalRiskCount}</SummaryValue>
                <KpiUnit>{intl.formatMessage({ id: 'risk.count' })}</KpiUnit>
              </KpiWithUnit>
            </KpiContainer>
          </>
        )}
      </SummaryWidgetArea>
    </WidgetWithTitleWrapper>
  )
}

const SummaryWidgetArea = styled(WidgetArea)({
  width: '100%',
  height: '110px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 35px',
})

const SummaryDateValue = styled(KpiValue)({
  fontSize: '18px',
})

const SummaryValue = styled(KpiValue)({
  fontSize: '24px',
})

const ProgressValue = ({
  label,
  value,
  unit,
}: {
  label: string
  value: string
  unit?: string
}) => {
  return (
    <KpiContainer>
      <KpiTitle>{label}</KpiTitle>
      <KpiWithUnit>
        <SummaryValue>{value}</SummaryValue>
        <KpiUnit>{unit}</KpiUnit>
      </KpiWithUnit>
    </KpiContainer>
  )
}
