import { InputBase, styled, TextField } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { colorPalette } from '../../../../style/colorPallete'

type WbsItemTitleProps = {
  value: string
  onChange: (v: string) => void
}

export const WbsItemTitle = ({ value, onChange }: WbsItemTitleProps) => {
  const [inputValue, setInputValue] = useState<string>(value)
  const [changedByInput, setChangedByInput] = useState<boolean>(false)
  useEffect(() => {
    setInputValue(value)
    setChangedByInput(false)
  }, [value])
  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (changedByInput) {
      onChange && onChange(debouncedValue)
    }
  }, [changedByInput, debouncedValue, onChange])
  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setChangedByInput(true)
  }, [])
  return <StyledTextField value={inputValue} onChange={onChangeInput} />
}
const StyledTextField = styled(InputBase)({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  width: '600px',
  '& .MuiInputBase-input': {
    fontSize: '18px !important',
    color: colorPalette.monotone[10],
    padding: '0 4px',
  },
})
