import { RowNode, ValueSetterParams } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import { getParentNode, refreshAncestors } from '../../../..'
import Workload, {
  WorkloadUnit,
} from '../../../../../../../../lib/functions/workload'
import { getUnitByFieldName, getWorkloadFieldName } from '.'
import store from '../../../../../../../../store'

export default (params: ValueSetterParams) => {
  if (!params.api || params.oldValue === params.newValue) {
    return false
  }
  const thisCellFieldName =
    params.colDef.cellEditorParams.thisFieldName || params.colDef.field || ''
  const input = !params.newValue ? 0 : params.newValue

  const workloadUnit = getUnitByFieldName(thisCellFieldName)

  const { dailyWorkHours, monthlyWorkDays } =
    store.getState().tenant.organization!

  let workload: Workload
  switch (workloadUnit) {
    case WorkloadUnit.MONTH:
      workload = Workload.from({
        month: input,
        standard: { dailyWorkHours, monthlyWorkDays },
      })
      break
    case WorkloadUnit.DAY:
      workload = Workload.from({
        day: input,
        standard: { dailyWorkHours, monthlyWorkDays },
      })
      break
    case WorkloadUnit.HOUR:
      workload = Workload.from({
        hour: input,
        standard: { dailyWorkHours, monthlyWorkDays },
      })
      break
    default:
      workload = new Workload(0, 0, 0, { dailyWorkHours, monthlyWorkDays })
  }

  const targetFieldName = getWorkloadFieldName(thisCellFieldName)
  let refreshFieldNames: string[] =
    targetFieldName === thisCellFieldName
      ? [thisCellFieldName].filter(v => !!v)
      : [
          `${targetFieldName}.hour`,
          `${targetFieldName}.day`,
          `${targetFieldName}.month`,
        ]
  objects.setValue(params.data, targetFieldName, workload)

  // Refresh aggregate at ancestor nodes
  if (params.colDef.cellEditorParams.refreshFieldNames) {
    refreshFieldNames = refreshFieldNames.concat(
      params.colDef.cellEditorParams.refreshFieldNames
    )
  }
  const nodes: RowNode[] = []
  let node: RowNode | null = params.node
  while (node && node.level >= 0) {
    nodes.push(node)
    node = node.parent
  }
  params.api.refreshCells({
    rowNodes: nodes,
    columns: refreshFieldNames,
    force: true,
  })
  params.node &&
    refreshAncestors(
      params.api,
      [thisCellFieldName],
      getParentNode(params.node)
    )
  if (!params.newValue && params.newValue !== 0) {
    refreshFieldNames.forEach(fieldName => {
      objects.setValue(params.data, fieldName, undefined)
    })
  }
  return true
}
