import BulkSheet, {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import appFunctionApi, {
  FunctionProperty,
  FunctionPropertyConfigurationDetail,
  PropertyType,
  UpdateBatchPropertyConfigurationProps,
  UpdatePropertyConfigurationProps,
} from '../../../lib/commons/appFunction'
import store from '../../../store'
import components from '..'
import {
  GetContextMenuItemsParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import BoolExpression from '../../../utils/boolExpression'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import objects from '../../../utils/objects'
import AppFunctionSelect from '../../components/editors/select/AppFunctionSelect'

export interface PropertyMaintenanceState extends BulkSheetState {
  applicationFunctionUuid?: string
  functionType?: FunctionType
  allowEntityExtension?: boolean
  entityExtension?: string
}

enum FunctionType {
  SINGLESHEET,
  BULKSHEET,
}

class PropertyMaintenanceRow extends RowData {
  externalId: string = ''
  name?: string
  propertyType?: PropertyType
  isEntityExtension?: boolean
  requiredIf?: BoolExpression
  editableIfC?: BoolExpression
  editableIfU?: BoolExpression
  hiddenIfC?: BoolExpression
  hiddenIfU?: BoolExpression
  valuesAllowed?: string
  config?: PropertyConfiguratinRow
}

interface PropertyConfiguratinRow {
  name?: string
  requiredIf?: BoolExpression
  editableIfU?: BoolExpression
  editableIfC?: BoolExpression
  hiddenIfC?: BoolExpression
  hiddenIfU?: BoolExpression
}

class PropertyMaintenanceRowDataSpec extends RowDataSpec<
  FunctionPropertyConfigurationDetail,
  PropertyMaintenanceRow
> {
  createNewRow(): PropertyMaintenanceRow {
    return {
      uuid: generateUuid(),
      isEntityExtension: true,
      requiredIf: BoolExpression.of('FALSE'),
      editableIfC: BoolExpression.of('TRUE'),
      editableIfU: BoolExpression.of('TRUE'),
      hiddenIfC: BoolExpression.of('FALSE'),
      hiddenIfU: BoolExpression.of('FALSE'),
      config: {
        requiredIf: BoolExpression.of('FALSE'),
        editableIfC: BoolExpression.of('TRUE'),
        editableIfU: BoolExpression.of('TRUE'),
        hiddenIfC: BoolExpression.of('FALSE'),
        hiddenIfU: BoolExpression.of('FALSE'),
      },
    } as PropertyMaintenanceRow
  }
  overwriteRowItemsWithParents(params: {
    child: PropertyMaintenanceRow
    parent: PropertyMaintenanceRow
  }): PropertyMaintenanceRow {
    return params.child
  }
  createRowByResponse(
    response: FunctionPropertyConfigurationDetail
  ): PropertyMaintenanceRow {
    return {
      ...response,
      isEntityExtension: !!response.entityExtensionUuid,
      requiredIf: BoolExpression.of(response.requiredIf),
      editableIfC: BoolExpression.of(response.editableIfC),
      editableIfU: BoolExpression.of(response.editableIfU),
      hiddenIfC: BoolExpression.of(response.hiddenIfC),
      hiddenIfU: BoolExpression.of(response.hiddenIfU),
      valuesAllowed: response.valuesAllowed
        ? response.valuesAllowed[0].customEnumCode
        : '',
      config: {
        ...response.configuration,
        requiredIf: BoolExpression.of(response.configuration.requiredIf),
        editableIfC: BoolExpression.of(response.configuration.editableIfC),
        editableIfU: BoolExpression.of(response.configuration.editableIfU),
        hiddenIfC: BoolExpression.of(response.configuration.hiddenIfC),
        hiddenIfU: BoolExpression.of(response.configuration.hiddenIfU),
      },
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
}

interface PropertyMaintenanceBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    FunctionPropertyConfigurationDetail,
    PropertyMaintenanceRow,
    PropertyMaintenanceState
  > {}

export default class PropertyMaintenance extends BulkSheetOptions<
  BulkSheetSpecificProps,
  FunctionPropertyConfigurationDetail,
  PropertyMaintenanceRow,
  PropertyMaintenanceState
> {
  addable = false
  draggable = true
  enableExcelExport = false
  showTreeRowNumber = false
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.PropertyMaintenanceColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new PropertyMaintenanceRowDataSpec()
  lockedColumns = ['propertyMaintenance.name']
  pinnedColumns = ['propertyMaintenance.name']
  groupColumnWidth = 200
  async getAll(state: PropertyMaintenanceState): Promise<APIResponse> {
    if (!state.applicationFunctionUuid) {
      return successDummyResponse
    }
    return appFunctionApi
      .getConfig({
        applicationFunctionUuid: state.applicationFunctionUuid,
        groupKeys: store.getState().project.selected
          ? [store.getState().project.selected!]
          : [],
      })
      .then(response => {
        const responseJson = response.json
        state.allowEntityExtension = responseJson.allowEntityExtension
        state.entityExtension = responseJson.entityExtension
        return { ...responseJson, json: responseJson.properties }
      })
  }
  rowDrag = (params: any, ctx: PropertyMaintenanceBulkSheetContext) => {
    const functionType = ctx.state.functionType
    return !(
      functionType === FunctionType.SINGLESHEET && params.data.parentUuid
    )
  }
  canAddChild = (
    row: PropertyMaintenanceRow,
    parentRow: PropertyMaintenanceRow | undefined,
    ctx: PropertyMaintenanceBulkSheetContext
  ) => {
    // unable to add child to the node which is not root.
    if (parentRow?.parentUuid) {
      return false
    }
    if (ctx.state.functionType === FunctionType.SINGLESHEET) {
      return false
    }
    return true
  }

  onChangeFunction(
    applicationFunctionUuid: string,
    externalId: string,
    ctx: PropertyMaintenanceBulkSheetContext
  ) {
    if (!applicationFunctionUuid || !externalId) {
      ctx.setState(
        {
          applicationFunctionUuid: undefined,
          functionType: undefined,
        },
        ctx.refreshDataWithLoading
      )
      return
    }
    const component = components[externalId]
    const functionType =
      component.class === BulkSheet
        ? FunctionType.BULKSHEET
        : FunctionType.SINGLESHEET
    ctx.setState(
      {
        applicationFunctionUuid,
        functionType,
      },
      ctx.refreshDataWithLoading
    )
  }

  onSubmit = async (
    ctx: PropertyMaintenanceBulkSheetContext,
    data: {
      added: PropertyMaintenanceRow[]
      edited: {
        before: PropertyMaintenanceRow
        after: PropertyMaintenanceRow
      }[]
      deleted: PropertyMaintenanceRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const state = ctx.state
    const input: UpdateBatchPropertyConfigurationProps = {
      applicationFunctionUuid: state.applicationFunctionUuid!,
      projectUuid: store.getState().project.selected!,
      configurations: ctx.rowDataManager
        .getAllRows()
        .map(row => this.createRequestByRow(row, ctx)),
    }
    return appFunctionApi.updateConfig(input)
  }

  private createRequestByRow = (
    row: PropertyMaintenanceRow,
    ctx: PropertyMaintenanceBulkSheetContext
  ): UpdatePropertyConfigurationProps => {
    return {
      externalId: row.externalId,
      name: row.config!.name,
      parentProperty: row.parentUuid
        ? ctx.gridApi!.getRowNode(row.parentUuid)?.data.externalId
        : undefined,
      requiredIf: row.config!.requiredIf?.expression,
      editableIfC: row.config!.editableIfC?.expression,
      editableIfU: row.config!.editableIfU?.expression,
      hiddenIfC: row.config!.hiddenIfC?.expression,
      hiddenIfU: row.config!.hiddenIfU?.expression,
    }
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: PropertyMaintenanceBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data || params.node.data.isTotal) return
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params, [
          ContextMenuItemId.ADD_MULTIPLE_ROW,
          ContextMenuItemId.ADD_MULTIPLE_CHILD_ROW,
        ]),
        ctx.generateUtilityContextMenu(params, [
          ContextMenuItemId.EXPAND_ALL,
          ContextMenuItemId.COLLAPSE_ALL,
        ]),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  private isBoolConfig = (field: string): boolean => {
    return field.startsWith('config') && field.includes('If')
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    const boolConfigValueSetter = (fieldName: string) => {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        let newValue
        if (params.newValue) {
          const defaultValue = objects.getValue(params.data, fieldName)
          if (!defaultValue.isFalse) {
            // Restore default value if it is TRUE or some operator.
            newValue = defaultValue
          } else {
            newValue = BoolExpression.of('TRUE')
          }
        } else {
          newValue = BoolExpression.of('FALSE')
        }
        objects.setValue(params.data.config, fieldName, newValue)
        return true
      }
    }
    if (this.isBoolConfig(field)) {
      const fieldName = field.split('.')[1]
      return boolConfigValueSetter(fieldName)
    }
    return undefined
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    // Deal as true when BoolExpression has operator, e.g. "projectPlan.productBacklogItem isTrue"
    const boolConfigValueGetter = (fieldName: string) => {
      return (params: ValueGetterParams) => {
        return !objects.getValue(params.data.config, fieldName).isFalse
      }
    }
    if (this.isBoolConfig(field)) {
      const fieldName = field.split('.')[1]
      return boolConfigValueGetter(fieldName)
    }
    return undefined
  }

  getCellEditorParams = (
    field: string,
    prop: FunctionProperty,
    ctx: PropertyMaintenanceBulkSheetContext
  ): { [key: string]: any } | undefined => {
    if (field === 'isEntityExtension') {
      return {
        disabled: true,
      }
    }
    if (field === 'config.requiredIf') {
      return {
        disabled: (node: RowNode) => {
          if (node.hasChildren()) {
            return true
          }
          return (
            !node.data.requiredIf.isFalse ||
            node.data.editableIfC.isFalse ||
            node.data.editableIfU.isFalse
          )
        },
      }
    }
    if (field === 'config.editableIfC') {
      return {
        disabled: (node: RowNode) => {
          if (node.hasChildren()) {
            return true
          }
          return node.data.editableIfC.isFalse || !node.data.requiredIf.isFalse
        },
      }
    }
    if (field === 'config.editableIfU') {
      return {
        disabled: (node: RowNode) => {
          if (node.hasChildren()) {
            return true
          }
          return node.data.editableIfU.isFalse || !node.data.requiredIf.isFalse
        },
      }
    }
    if (field === 'config.hiddenIfC') {
      return {
        disabled: (node: RowNode) => {
          if (node.hasChildren()) {
            return true
          }
          if (ctx.state.functionType === FunctionType.BULKSHEET) {
            return true
          }
          return !node.data.requiredIf.isFalse
        },
      }
    }
    if (field === 'config.hiddenIfU') {
      return {
        disabled: (node: RowNode) => {
          if (node.hasChildren()) {
            return true
          }
          return !node.data.requiredIf.isFalse
        },
      }
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'config.requiredIf',
        'config.editableIfC',
        'config.editableIfU',
        'config.hiddenIfC',
        'config.hiddenIfU',
      ].includes(field)
    ) {
      return 135
    }
    return undefined
  }

  toolBarItems = ctx => [
    <AppFunctionSelect
      key="1"
      projectUuid={ctx.state.uuid}
      onChangeValue={(uuid, externalId) =>
        this.onChangeFunction(uuid, externalId, ctx)
      }
      applicationFunctionUuid={ctx.state.teamUuid}
    />,
  ]
}
