import React, { useCallback } from 'react'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { RowNode } from 'ag-grid-community'
import AggregateInformation, { AggregateValueType } from './index'
import projectPlanFacadeAggregator, {
  EvmIndicator,
  getDescendantCodes,
} from '../commons/AgGrid/lib/aggregator/projectPlanAggregator'
import { styled } from '@mui/material'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../components/toggleGroups'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

export interface Props {
  rootNode?: RowNode
  aggregateType: AggregateField
  onChangeAggregateType: (value: AggregateField) => void
  wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK
  onChangeWbsItemType: (value) => void
  workloadUnit: WorkloadUnit
  onChangeWorkloadUnit: (value: WorkloadUnit) => void
}

const aggregateTypeGroups = [
  [
    AggregateValueType.ESTIMATED_PROGRESS_RATE,
    AggregateValueType.PROGRESS_RATE,
  ],
  [
    AggregateValueType.BUDGET_AT_COMPLETION,
    AggregateValueType.PLANNED_VALUE,
    AggregateValueType.EARNED_VALUE,
    AggregateValueType.PRECEDING,
    AggregateValueType.DELAYED,
    AggregateValueType.REMAINING,
    AggregateValueType.UNPLANNED,
  ],
  [AggregateValueType.ACTUAL_COST, AggregateValueType.COST_PERFORMANCE_INDEX],
]

const ProjectPlanAggregateInformation = (props: Props) => {
  const [aggregatedValueGroups, setAggregatedValueGroups] = React.useState<any>(
    []
  )
  const { hoursPerSelectedUnit } = useWorkloadUnit(props.workloadUnit)

  const aggregate = useCallback(() => {
    const { rootNode, aggregateType, wbsItemType, workloadUnit } = props
    if (!rootNode) return []
    return aggregateTypeGroups.map(group =>
      group.map(type => ({
        type,
        value: projectPlanFacadeAggregator(
          rootNode,
          EvmIndicator[type],
          props.wbsItemType,
          type === AggregateValueType.ACTUAL_COST
            ? AggregateField.WBS_ITEM_WORKLOAD
            : aggregateType,
          aggregateType === AggregateField.WBS_ITEM_WORKLOAD ||
            type === AggregateValueType.ACTUAL_COST
            ? hoursPerSelectedUnit
            : 1
        ),
        codes: getDescendantCodes(rootNode, wbsItemType, EvmIndicator[type]),
      }))
    )
  }, [
    hoursPerSelectedUnit,
    props.rootNode,
    props.aggregateType,
    props.wbsItemType,
    props.workloadUnit,
  ])

  React.useEffect(() => {
    props.rootNode && setAggregatedValueGroups(aggregate())
  }, [
    props.aggregateType,
    props.wbsItemType,
    props.workloadUnit,
    props.rootNode,
    hoursPerSelectedUnit,
  ])

  return (
    <>
      <AggregateInformation
        aggregatedValueGroups={aggregatedValueGroups}
        aggregateType={props.aggregateType}
        wbsItemType={props.wbsItemType}
      />
      <ParameterButtonGroupDiv>
        <AggregateTargetToggleGroup
          value={props.wbsItemType}
          onChange={props.onChangeWbsItemType}
          height={16}
        />
        <AggregateFieldToggleGroup
          value={props.aggregateType}
          onChange={props.onChangeAggregateType}
          height={16}
        />
      </ParameterButtonGroupDiv>
      <ParameterButtonGroupDiv>
        <WorkloadUnitToggleGroup
          value={props.workloadUnit}
          refresh={props.onChangeWorkloadUnit}
          height={16}
        />
      </ParameterButtonGroupDiv>
    </>
  )
}

export default ProjectPlanAggregateInformation

const ParameterButtonGroupDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})
