import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import { convertStringToHtml } from '../../../utils/html'

interface Props {
  content: string
}

const Viewer = styled(Paper)({
  border: '1px solid #aaa',
  borderRadius: '5px',
  backgroundColor: '#eee',
  paddingLeft: '5px',
  wordWrap: 'break-word',
  '& *': {
    margin: 0,
  },
})

const MarkupViewer = (props: Props) => {
  return (
    <Viewer
      variant="outlined"
      dangerouslySetInnerHTML={{
        __html: convertStringToHtml(props.content),
      }}
    />
  )
}

export default MarkupViewer
