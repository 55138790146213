/* eslint-disable no-underscore-dangle */

export class CalendarDateVO {
  private readonly _date: Date
  private readonly _workHour: number
  private readonly _name: string
  private readonly _legal: boolean

  constructor({
    date,
    workHour,
    name,
    legal,
  }: {
    date: string | Date
    workHour: number
    name: string
    legal: boolean
  }) {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    this._date = d
    this._workHour = workHour
    this._name = name
    this._legal = legal
  }

  get date(): Date {
    return this._date
  }

  static of(date: string | Date): CalendarDateVO {
    const value = new Date(date)
    value.setHours(0, 0, 0, 0)
    return new CalendarDateVO({
      date: value,
      workHour: [0, 6].includes(value.getDay()) ? 0 : 8,
      name: '',
      legal: false,
    })
  }

  public isHoliday(): boolean {
    return this._workHour === 0 || this._date.getDay() === 0
  }

  public isEqual(value: CalendarDateVO): boolean {
    const target = value.date
    return this._date.getTime() === target.getTime()
  }

  public isBefore(value: CalendarDateVO): boolean {
    const target = value.date
    return this._date.getTime() < target.getTime()
  }

  public isAfter(value: CalendarDateVO): boolean {
    const target = value.date
    return target.getTime() < this._date.getTime()
  }

  public isBetween(start: CalendarDateVO, end: CalendarDateVO): boolean {
    const thisDate = this.date.getTime()
    return start.date.getTime() <= thisDate && thisDate <= end.date.getTime()
  }

  // Please format the day of week with React intl provider if you need to format it.
  public format(fmt: 'D' | 'M/D'): string {
    switch (fmt) {
      case 'D':
        return '' + this._date.getDate()
      case 'M/D':
        return this._date.getMonth() + 1 + '/' + this._date.getDate()
    }
  }

  public getDayOfWeek(): number {
    return this._date.getDay()
  }

  static nextWorkDay(
    calendar: CalendarDateVO[],
    date: CalendarDateVO,
    step: number = 1
  ): CalendarDateVO | undefined {
    if (step === 0) return date
    const index = calendar.findIndex(v => v.isEqual(date))
    let nextDate: CalendarDateVO | undefined = undefined
    if (0 <= index + 1) {
      nextDate = calendar[index + 1]
    }
    if (nextDate) {
      return this.nextWorkDay(
        calendar,
        nextDate,
        nextDate.isHoliday() ? step : step - 1
      )
    }
    return date
  }

  static previousWorkDay(
    calendar: CalendarDateVO[],
    date: CalendarDateVO,
    step: number = 1
  ): CalendarDateVO | undefined {
    if (step === 0) return date
    const index = calendar.findIndex(v => v.isEqual(date))
    let previousDate: CalendarDateVO | undefined = undefined
    if (0 <= index - 1) {
      previousDate = calendar[index - 1]
    }
    if (previousDate) {
      return this.previousWorkDay(
        calendar,
        previousDate,
        previousDate.isHoliday() ? step : step - 1
      )
    }
    return date
  }
}
