import { toNumber } from '../../../utils/number'
import {
  WbsItemAdditionalPropertyEntity,
  WbsItemAdditionalPropertyType,
} from '../../entity/WbsItemAdditionalPropertyEntity'

export type ReferenceEntityValueType = {
  uuid: string
  code: string
  name: string
  iconUrl: string
}
export type WbsItemAdditionalPropertyValueType =
  | undefined
  | boolean
  | number
  | string
  | ReferenceEntityValueType
export type WbsItemAdditionalPropertyValueObject = {
  wbsItemAdditionalPropertyUuid: string
  value: WbsItemAdditionalPropertyValueType
}
export type WbsItemAdditionalPropertyValueDeltaInput = {
  wbsItemAdditionalPropertyUuid: string
  oldValue: string | undefined
  newValue: string | undefined
}
export type WbsItemAdditionalPropertyValueVO = {
  wbsItemAdditionalPropertyUuid: string
  value: WbsItemAdditionalPropertyValueType
}
export type WbsItemAdditionalPropertyValuesVO = {
  values: WbsItemAdditionalPropertyValueVO[]
}

// Constructor
const construct = (): WbsItemAdditionalPropertyValuesVO => {
  return {
    values: [],
  }
}
const of = (
  wbsItemUuid: string,
  values: WbsItemAdditionalPropertyValueObject[]
): WbsItemAdditionalPropertyValuesVO => {
  return {
    values,
  }
}
// Services
const getValue = (
  vo: WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyUuid: string
): WbsItemAdditionalPropertyValueVO | undefined => {
  return vo.values.find(
    v => v.wbsItemAdditionalPropertyUuid === wbsItemAdditionalPropertyUuid
  )
}
const setValue = (
  vo: WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyUuid: string,
  value: WbsItemAdditionalPropertyValueType
): void => {
  const wbsItemAdditionalPropertyValue = vo.values.find(
    v => v.wbsItemAdditionalPropertyUuid === wbsItemAdditionalPropertyUuid
  )
  if (wbsItemAdditionalPropertyValue) {
    wbsItemAdditionalPropertyValue.value = value
  } else {
    vo.values.push({
      wbsItemAdditionalPropertyUuid,
      value,
    })
  }
}
const parseValueFromString = (
  propertyType: WbsItemAdditionalPropertyType,
  value: string | null | undefined
): WbsItemAdditionalPropertyValueType => {
  if (value === undefined || value === null) return undefined
  const v = value
  switch (propertyType) {
    case WbsItemAdditionalPropertyType.CHECKBOX:
      if (v.toString().match(/^([tT]|check|1)/)) return true
      if (v.toString().match(/^[fF]|0/)) return false
      return undefined
    case WbsItemAdditionalPropertyType.NUMBER:
      return toNumber(v)
  }
  return v
}
const serialize = (
  vo: WbsItemAdditionalPropertyValuesVO
): WbsItemAdditionalPropertyValueObject[] => {
  return vo.values.map(v => ({
    wbsItemAdditionalPropertyUuid: v.wbsItemAdditionalPropertyUuid,
    value: serializeValue(v.value),
  }))
}
const serializeDelta = (
  oldVo: WbsItemAdditionalPropertyValuesVO | undefined,
  newVo: WbsItemAdditionalPropertyValuesVO | undefined
): WbsItemAdditionalPropertyValueDeltaInput[] => {
  const wbsItemAdditionalPropertyUuids = new Set<string>()
  const oldValueMap: Record<string, WbsItemAdditionalPropertyValueType> = {}
  const newValueMap: Record<string, WbsItemAdditionalPropertyValueType> = {}
  if (oldVo) {
    oldVo.values.forEach(v => {
      oldValueMap[v.wbsItemAdditionalPropertyUuid] = v.value
      wbsItemAdditionalPropertyUuids.add(v.wbsItemAdditionalPropertyUuid)
    })
  }
  if (newVo) {
    newVo.values.forEach(v => {
      newValueMap[v.wbsItemAdditionalPropertyUuid] = v.value
      wbsItemAdditionalPropertyUuids.add(v.wbsItemAdditionalPropertyUuid)
    })
  }

  const deltaInput: WbsItemAdditionalPropertyValueDeltaInput[] = []
  wbsItemAdditionalPropertyUuids.forEach(wbsItemAdditionalPropertyUuid => {
    const oldValue = serializeValue(oldValueMap[wbsItemAdditionalPropertyUuid])
    const newValue = serializeValue(newValueMap[wbsItemAdditionalPropertyUuid])
    if (oldValue === newValue) {
      return
    }

    deltaInput.push({
      wbsItemAdditionalPropertyUuid,
      oldValue,
      newValue,
    })
  })
  return deltaInput
}
export const wbsItemAdditionalPropertyValuesVoService = {
  construct,
  of,
  getValue,
  setValue,
  parseValueFromString,
  serialize,
  serializeDelta,
}

// Private
type WbsItemAdditionalPropertyValueTypeForSerialize = undefined | string
const isReferenceEntityValue = (value: WbsItemAdditionalPropertyValueType) =>
  typeof value === 'object' && value && Boolean(value.uuid)
const serializeValue = (
  value: WbsItemAdditionalPropertyValueType
): WbsItemAdditionalPropertyValueTypeForSerialize => {
  if (value === undefined) {
    return undefined
  }
  if (isReferenceEntityValue(value)) {
    return (value as ReferenceEntityValueType).uuid
  }
  return String(value)
}
