import { useCallback, useMemo, useState } from 'react'
import { Box, ClickAwayListener, IconButton, Popper } from '@mui/material'
import { DateRange } from '@mui/icons-material'
import DatePicker from 'react-datepicker'
import { DateTerm } from '../../../../../../utils/date'
import DateVO from '../../../../../../vo/DateVO'
import {
  DateArea,
  DatePickerAreaDiv,
  DateTermAreaDiv,
  DateTermDivider,
} from './common'
import { colorPalette } from '../../../../../style/colorPallete'
import { FontSize, FontWeight } from '../../../../../../styles/commonStyles'

type Props = {
  dateTerm: DateTerm
  onChangeDateTerm: (dateTerm: DateTerm) => void
}

type MonthSelectButtonProps = {
  month: DateVO
  onChange: (date: Date) => void
}

const MonthSelectButton = ({ month, onChange }: MonthSelectButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined)

  const initialMonth = useMemo(
    () => (month ? month.toDate() : undefined),
    [month]
  )

  const locale = navigator.language.split(/[-_]/)[0]
  const closeHandler = useCallback(() => setAnchorEl(undefined), [])
  const onChangeMonth = useCallback(
    (date: Date) => {
      onChange(date)
      setAnchorEl(undefined)
    },
    [onChange]
  )

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <div>
        <IconButton
          onClick={e => setAnchorEl(e.currentTarget)}
          sx={{ margin: '-12px' }}
        >
          <DateRange sx={{ widht: '16px', height: '16px' }} />
        </IconButton>
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          disablePortal={true}
          placement={'bottom'}
          sx={{
            zIndex: 2000,
            background: colorPalette.monotone[0],
            borderRadius: '4px',
            boxShadow: '0px 4px 16px rgba(123, 140, 170, 0.5)',
          }}
        >
          <Box
            sx={{
              '& .react-datepicker': {
                borderWidth: 0,
                color: colorPalette.monotone[5],
                fontSize: FontSize.MEDIUM,
              },
              '& .react-datepicker-year-header': {
                color: colorPalette.monotone[5],
                fontSize: FontSize.MEDIUM,
                fontWeight: FontWeight.NORMAL,
                padding: '8px',
              },
              '& .react-datepicker__month': {
                margin: 0,
              },
              '& .react-datepicker__month-wrapper': {
                padding: '6px 8px 0px 8px',
              },
              '& .react-datepicker__month-text': {
                width: '61.33px !important',
                height: '20px',
                gap: '4px',
                margin: '0px !important',
              },
              '& .react-datepicker__month--selected, .react-datepicker__month-text--keyboard-selected':
                {
                  backgroundColor: colorPalette.skyBlue[7],
                  borderRadius: '2px',
                },
            }}
          >
            <DatePicker
              locale={locale}
              selected={initialMonth}
              inline={true}
              onChange={onChangeMonth}
              showMonthYearPicker={true}
            />
          </Box>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

const MonthPicker = ({ dateTerm, onChangeDateTerm }: Props) => {
  const startDateVO = useMemo(
    () => (dateTerm.startDate ? new DateVO(dateTerm.startDate) : undefined),
    [dateTerm]
  )
  const endDateVO = useMemo(
    () => (dateTerm.endDate ? new DateVO(dateTerm.endDate) : undefined),
    [dateTerm]
  )
  const startYear = useMemo(
    () => (startDateVO ? startDateVO.format('YYYY') : undefined),
    [startDateVO]
  )
  const endYear = useMemo(
    () => (endDateVO ? endDateVO.format('YYYY') : undefined),
    [endDateVO]
  )
  const getDateTerm = useCallback(
    (startDate: DateVO | undefined, endDate: DateVO | undefined) => {
      return {
        startDate: startDate?.getFirstDayOfMonth().format() || '',
        endDate: endDate?.getLastDayOfMonth().format() || '',
      } as DateTerm
    },
    []
  )

  const onChangeStartDate = useCallback(
    (date: Date) => {
      if (onChangeDateTerm) {
        onChangeDateTerm(getDateTerm(new DateVO(date), endDateVO))
      }
    },
    [endDateVO, onChangeDateTerm, getDateTerm]
  )
  const onChangeEndDate = useCallback(
    (date: Date) => {
      if (onChangeDateTerm) {
        onChangeDateTerm(getDateTerm(startDateVO, new DateVO(date)))
      }
    },
    [startDateVO, onChangeDateTerm, getDateTerm]
  )

  if (!startDateVO || !endDateVO) return <></>

  return (
    <DatePickerAreaDiv>
      <DateTermAreaDiv>
        <DateArea year={startYear} month={startDateVO.format('M')} />
        <MonthSelectButton month={startDateVO} onChange={onChangeStartDate} />
        <DateTermDivider />
        {startYear !== endYear ? (
          <DateArea year={endYear} month={endDateVO.format('M')} />
        ) : (
          <DateArea month={endDateVO.format('M')} />
        )}
        <MonthSelectButton month={endDateVO} onChange={onChangeEndDate} />
      </DateTermAreaDiv>
    </DatePickerAreaDiv>
  )
}

export default MonthPicker
