import { styled } from '@mui/system'
import React from 'react'
import { getWbsItemStatusDeepColorCode } from '../../../../../lib/functions/wbsItem'
import objects from '../../../../../utils/objects'
import { WbsItemStatus } from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  AutoCompleteCell,
  AutocompleteOption,
  CheckBoxCell,
  DateCell,
  DateTimeCell,
  EntitySearchCell,
  FilterInputCell,
  RowHeaderCell,
} from '../../../../containers/DataCell'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Sprint, { SprintStatus } from '../../../../../lib/functions/sprint'
import store, { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import DateVO from '../../../../../vo/DateVO'
import { getWbsItemSearchConditionItemLabel } from '../utils'
import Checkbox from '../../../../components/editors/checkbox/Checkbox'
import CustomEnumValueCondition from './CustomEnumValueCondition'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import { Typography } from '@mui/material'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { formatDateTimeRangeForApi } from '../../../../../utils/date'
import { CustomEnumCode } from '../../../../../lib/functions/customEnumValue'
import { removeEmptyPropertiesFromSearchFilter } from '../WbsItemSearchFilterPanel'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import RelativeDate from '../../../../../vo/DateVO/RelativeDate'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { BaseWbsItemType } from '../../../../../store/project'
import { CheckBoxCondition } from './CheckBoxCondition'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'

export enum WbsItemSearchConditionKey {
  ALL = 'all',
  CODE = 'code',
  TYPES = 'types',
  STATUS = 'status',
  DELIVERABLE = 'deliverable',
  TEAM = 'team',
  ACCOUNTABLE = 'accountable',
  RESPONSIBLE = 'responsible',
  ASSIGNEE = 'assignee',
  SPRINT = 'sprint',
  PRIORITY = 'priority',
  SCHEDULED_START_DATE = 'scheduledStartDate',
  SCHEDULED_END_DATE = 'scheduledEndDate',
  ACTUAL_START_DATE = 'actualStartDate',
  ACTUAL_END_DATE = 'actualEndDate',
  DISPLAY_NAME = 'displayName',
  DESCRIPTION = 'description',
  TICKET_TYPES = 'ticketTypes',
  CRITICAL = 'critical',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  CREATED_BY = 'createdBy',
  UPDATED_BY = 'updatedBy',
}

export interface CellPosition {
  row: number
  column: number
  size: number
}

export interface SearchConditionProps {
  key: WbsItemSearchConditionKey
  position: CellPosition
}

const SelectOptionLabel = styled(Typography)({
  borderRadius: 2,
  width: 'auto',
  fontWeight: FontWeight.BOLD,
  fontSize: FontSize.SMALL,
  padding: '2px 8px',
})

const SelectOptionLabelWithIcon = styled(Typography)({
  borderRadius: 2,
  width: 'auto',
  fontWeight: FontWeight.BOLD,
  fontSize: FontSize.SMALL,
  padding: '2px 8px 2px 0',
})

export const SearchConditionRowHeaderCell = styled(RowHeaderCell)({
  width: 105,
})

const WbsItemIcon = styled('span')({
  '& > img': {
    verticalAlign: 'middle',
  },
})
const WbsItemIconLabel = styled('span')({
  verticalAlign: 'middle',
})

type Props = ComponentOwnProps & WrappedComponentProps & StateProps

export interface ComponentOwnProps {
  conditionKey: SearchConditionProps
  updateSearchFilter: (params: { [key: string]: any }) => void
  search: Function
  searchFilter?: SearchFilter
  getPlaceHolder?: () => string
}

interface StateProps {
  externalId?: string
  projectUuid?: string
  wbsItemTypes?: BaseWbsItemType
  ticketTypes?: WbsItemTypeVO[]
}

class WbsItemSearchCondition extends React.Component<Props> {
  getBlankKey = (key: WbsItemSearchConditionKey) => `blank.${key}`
  getBlankValue = (key: WbsItemSearchConditionKey) => {
    return objects.getValue(this.props.searchFilter, this.getBlankKey(key))
  }
  getRangeKeyFrom = (key: WbsItemSearchConditionKey) => `${key}.from`
  getRangeKeyTo = (key: WbsItemSearchConditionKey) => `${key}.to`
  getRangeValueFrom = (key: WbsItemSearchConditionKey) => {
    const dateVO = objects.getValue(
      this.props.searchFilter,
      this.getRangeKeyFrom(key)
    )
    return dateVO ? new DateVO(dateVO) : undefined
  }
  getRangeValueTo = (key: WbsItemSearchConditionKey) => {
    const dateVO = objects.getValue(
      this.props.searchFilter,
      this.getRangeKeyTo(key)
    )
    return dateVO ? new DateVO(dateVO) : undefined
  }

  getRowHeader = (key: WbsItemSearchConditionKey) => {
    return getWbsItemSearchConditionItemLabel({
      wbsItemSearchConditionKey: key,
    })
  }

  getOnInputChangeWbsItem = (key: WbsItemSearchConditionKey) => {
    return (value: string | AutocompleteOption | undefined) => {
      const wbsItem = objects.getValue(this.props.searchFilter, key)
      this.props.updateSearchFilter({
        [key]: {
          ...wbsItem,
          displayName: value,
        },
      })
    }
  }

  getTextCondition = (
    key: WbsItemSearchConditionKey,
    value: any,
    colspan?: any
  ) => {
    return (
      <>
        <SearchConditionRowHeaderCell align="left">
          {this.getRowHeader(key)}
        </SearchConditionRowHeaderCell>
        <FilterInputCell
          colSpan={colspan}
          value={value}
          type={'text'}
          onChange={value => this.props.updateSearchFilter({ [key]: value })}
          enterHandler={this.props.search}
          clearable={true}
        />
      </>
    )
  }

  getCustomEnumSelectCondition = (
    key: WbsItemSearchConditionKey,
    value: any,
    colSpan: any,
    customEnumCode: CustomEnumCode
  ) => {
    return (
      <CustomEnumValueCondition
        conditionKey={key}
        value={value}
        onChange={v => this.props.updateSearchFilter({ [key]: v })}
        customEnumCode={customEnumCode}
        colSpan={colSpan}
        renderOption={customEnumValue => (
          <SelectOptionLabelWithIcon>
            <WbsItemIcon>
              <img src={customEnumValue.iconUrl} />
            </WbsItemIcon>
            <WbsItemIconLabel>{customEnumValue.name}</WbsItemIconLabel>
          </SelectOptionLabelWithIcon>
        )}
      />
    )
  }

  getSingleCheckCondition = (
    key: WbsItemSearchConditionKey,
    value: any,
    colspan?: any,
    onChange?: (value) => void
  ) => {
    return (
      <>
        <SearchConditionRowHeaderCell align="left">
          {this.getRowHeader(key)}
        </SearchConditionRowHeaderCell>
        <CheckBoxCell
          colSpan={colspan}
          value={value || []}
          options={[{ name: '', value: 'true' }]}
          onChange={value =>
            onChange
              ? onChange(value)
              : this.props.updateSearchFilter({ [key]: value })
          }
          readOnly={false}
        />
      </>
    )
  }

  getAutocompleteCondition = (
    key: WbsItemSearchConditionKey,
    value: any,
    options: any[],
    referenceEntity: string,
    colspan?: any,
    onChange?: (value) => void
  ) => {
    return (
      <>
        <SearchConditionRowHeaderCell align="left">
          {this.getRowHeader(key)}
        </SearchConditionRowHeaderCell>
        <AutoCompleteCell
          colSpan={colspan}
          value={value}
          options={options}
          onChange={value =>
            onChange
              ? onChange(value)
              : this.props.updateSearchFilter({ [key]: value })
          }
          enterHandler={this.props.search}
          referenceEntity={referenceEntity}
        />
      </>
    )
  }

  getAutocompleteConditionWithCheckbox = (
    key: WbsItemSearchConditionKey,
    value: any,
    options: any[],
    referenceEntity: string,
    disabled: Function,
    colspan?: any,
    fixedSearchCondition?: any,
    orderFunction?: (options: any[]) => any[],
    placeHolder?: Function
  ) => {
    return (
      <>
        <Tooltip
          message={this.props.intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.blankCheckBoxNavigation',
          })}
        >
          <SearchConditionRowHeaderCell align="left">
            {this.getRowHeader(key)}
            <Checkbox
              checked={this.getBlankValue(key)}
              onChange={(_, checked) =>
                this.props.updateSearchFilter({
                  [key]: undefined,
                  [this.getBlankKey(key)]: checked,
                })
              }
            />
          </SearchConditionRowHeaderCell>
        </Tooltip>
        <AutoCompleteCell
          colSpan={colspan}
          value={value}
          options={options}
          referenceEntity={referenceEntity}
          disabled={disabled()}
          fixedSearchCondition={fixedSearchCondition}
          orderFunction={orderFunction}
          onChange={value => this.props.updateSearchFilter({ [key]: value })}
          enterHandler={this.props.search}
          placeholder={placeHolder ? placeHolder() : ''}
        />
      </>
    )
  }

  getEntitySearchCondition = (
    key: WbsItemSearchConditionKey,
    value: any,
    referenceEntity: string,
    colspan?: any,
    autoHighlight?: boolean,
    onInputChangeHandler?: (
      value: string | AutocompleteOption | undefined
    ) => void,
    fixedSearchCondition?: any
  ) => {
    return (
      <>
        <SearchConditionRowHeaderCell align="left">
          {this.getRowHeader(key)}
        </SearchConditionRowHeaderCell>
        <EntitySearchCell
          colSpan={colspan}
          value={value}
          onChange={value => this.props.updateSearchFilter({ [key]: value })}
          onInputChange={onInputChangeHandler}
          enterHandler={this.props.search}
          referenceEntity={referenceEntity}
          fixedSearchCondition={fixedSearchCondition}
          autoHighlight={autoHighlight}
        />
      </>
    )
  }

  getDateRangeConditionWithCheckbox = (
    key: WbsItemSearchConditionKey,
    colspan?: any
  ) => {
    return (
      <>
        <Tooltip
          message={this.props.intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.blankCheckBoxNavigation',
          })}
        >
          <SearchConditionRowHeaderCell align="left">
            {this.getRowHeader(key)}
            <Checkbox
              checked={this.getBlankValue(key)}
              onChange={(_, checked) =>
                this.props.updateSearchFilter({
                  [this.getBlankKey(key)]: checked,
                  // when user click blank, date value is cleared
                  [this.getRangeKeyFrom(key)]: undefined,
                  [this.getRangeKeyTo(key)]: undefined,
                })
              }
            />
          </SearchConditionRowHeaderCell>
        </Tooltip>
        <DateCell
          colSpan={colspan}
          value={this.getRangeValueFrom(key)}
          onChange={value =>
            this.props.updateSearchFilter({
              [this.getRangeKeyFrom(key)]: value,
            })
          }
          enterHandler={this.props.search}
          isClearable={true}
          disabled={this.getBlankValue(key)}
          placeholderText={
            this.getBlankValue(key)
              ? this.props.intl.formatMessage({
                  id: `wbsItemSearchFilterPanel.blank`,
                })
              : undefined
          }
        />
        <DateCell
          colSpan={colspan}
          value={this.getRangeValueTo(key)}
          onChange={value =>
            this.props.updateSearchFilter({ [this.getRangeKeyTo(key)]: value })
          }
          enterHandler={this.props.search}
          isClearable={true}
          disabled={this.getBlankValue(key)}
          placeholderText={
            this.getBlankValue(key)
              ? this.props.intl.formatMessage({
                  id: `wbsItemSearchFilterPanel.blank`,
                })
              : undefined
          }
        />
      </>
    )
  }

  getDateTimeRangeCondition = (
    key: WbsItemSearchConditionKey,
    colspan?: any
  ) => {
    return (
      <>
        <SearchConditionRowHeaderCell align="left">
          {this.getRowHeader(key)}
        </SearchConditionRowHeaderCell>
        <DateTimeCell
          colSpan={colspan}
          value={this.getRangeValueFrom(key)}
          onChange={value =>
            this.props.updateSearchFilter({
              [this.getRangeKeyFrom(key)]: value,
            })
          }
          enterHandler={this.props.search}
          isClearable={true}
        />
        <DateTimeCell
          colSpan={colspan}
          value={this.getRangeValueTo(key)}
          onChange={value =>
            this.props.updateSearchFilter({ [this.getRangeKeyTo(key)]: value })
          }
          enterHandler={this.props.search}
          isClearable={true}
        />
      </>
    )
  }

  render() {
    const key = this.props.conditionKey.key
    const colSpan = this.props.conditionKey.position.size
    const value = objects.getValue(this.props.searchFilter, key)
    switch (key) {
      case WbsItemSearchConditionKey.TYPES:
        return (
          <CheckBoxCondition
            conditionKey={key}
            value={value || []}
            options={
              this.props.wbsItemTypes?.getAll().map(v => ({
                code: v.code,
                name: v.name,
                iconUrl: v.iconUrl,
              })) ?? []
            }
            colSpan={colSpan}
            onChange={v => this.props.updateSearchFilter({ [key]: v })}
          />
        )
      case WbsItemSearchConditionKey.CODE:
      case WbsItemSearchConditionKey.DISPLAY_NAME:
      case WbsItemSearchConditionKey.DESCRIPTION:
        return this.getTextCondition(key, value, colSpan)
      case WbsItemSearchConditionKey.TICKET_TYPES:
        return (
          <CheckBoxCondition
            conditionKey={key}
            value={value || []}
            options={
              this.props.ticketTypes?.map(v => ({
                code: v.code,
                name: v.name,
                iconUrl: v.iconUrl,
              })) ?? []
            }
            colSpan={colSpan}
            onChange={v => this.props.updateSearchFilter({ [key]: v })}
            none={true}
          />
        )
      case WbsItemSearchConditionKey.STATUS:
        return (
          <CheckBoxCondition
            conditionKey={key}
            value={value || []}
            options={Object.values(WbsItemStatus).map(v => ({
              code: v,
              name: v,
            }))}
            colSpan={colSpan}
            onChange={v => this.props.updateSearchFilter({ [key]: v })}
            renderOption={option => (
              <SelectOptionLabel
                style={{
                  backgroundColor: getWbsItemStatusDeepColorCode(
                    WbsItemStatus[option.name]
                  ),
                }}
              >
                {option.name}
              </SelectOptionLabel>
            )}
          />
        )
      case WbsItemSearchConditionKey.DELIVERABLE:
        return this.getEntitySearchCondition(
          key,
          value,
          'Deliverable',
          colSpan,
          false,
          this.getOnInputChangeWbsItem(key),
          this.deliverableFixedCondition
        )
      case WbsItemSearchConditionKey.PRIORITY:
        return this.getCustomEnumSelectCondition(
          key,
          value,
          colSpan,
          CustomEnumCode.WBS_PRIORITY
        )
      case WbsItemSearchConditionKey.CRITICAL:
        return this.getSingleCheckCondition(key, value, colSpan)
      case WbsItemSearchConditionKey.TEAM:
        return this.getAutocompleteCondition(
          key,
          value,
          [],
          'Team',
          colSpan,
          this.onTeamChanged
        )
      case WbsItemSearchConditionKey.ACCOUNTABLE:
      case WbsItemSearchConditionKey.RESPONSIBLE:
      case WbsItemSearchConditionKey.ASSIGNEE:
      case WbsItemSearchConditionKey.CREATED_BY:
      case WbsItemSearchConditionKey.UPDATED_BY:
        return this.getEntitySearchCondition(
          key,
          value,
          'ProjectMember',
          colSpan,
          false
        )
      case WbsItemSearchConditionKey.SPRINT:
        return this.getAutocompleteConditionWithCheckbox(
          key,
          value,
          [],
          'Sprint',
          this.sprintDisabledCondition,
          colSpan,
          this.getSprintFixedCondition(),
          Sprint.orderDescWithInprogressFirst,
          this.getSprintPlaceHolder
        )
      case WbsItemSearchConditionKey.SCHEDULED_START_DATE:
      case WbsItemSearchConditionKey.SCHEDULED_END_DATE:
      case WbsItemSearchConditionKey.ACTUAL_START_DATE:
      case WbsItemSearchConditionKey.ACTUAL_END_DATE:
        return this.getDateRangeConditionWithCheckbox(key, colSpan)
      case WbsItemSearchConditionKey.CREATED_AT:
      case WbsItemSearchConditionKey.UPDATED_AT:
        return this.getDateTimeRangeCondition(key, colSpan)
      default:
        return <></>
    }
  }

  private deliverableFixedCondition = {
    hasChildTask: true,
  }

  private getSprintFixedCondition = () => {
    return {
      teamUuid: this.props.searchFilter?.team?.uuid || '',
    }
  }

  private onTeamChanged = value => {
    this.props.updateSearchFilter({
      [WbsItemSearchConditionKey.TEAM]: value,
      [WbsItemSearchConditionKey.SPRINT]: {},
    })
  }

  private sprintDisabledCondition = () => {
    return (
      !objects.getValue(this.props.searchFilter, 'team.uuid') ||
      this.getBlankValue(WbsItemSearchConditionKey.SPRINT)
    )
  }

  private getSprintPlaceHolder = () => {
    if (this.getBlankValue(WbsItemSearchConditionKey.SPRINT)) {
      return this.props.intl.formatMessage({
        id: `wbsItemSearchFilterPanel.blank`,
      })
    } else if (!objects.getValue(this.props.searchFilter, 'team.uuid')) {
      return this.props.intl.formatMessage({
        id: `wbsItemSearchFilterPanel.selectTeam`,
      })
    } else {
      return ''
    }
  }
}

export interface SearchFilter {
  team: {
    uuid?: string
  }
  uuid?: string
  code?: string
  displayName?: string
  description?: string
  deliverable?: AutocompleteOption & {
    aggregate?: boolean
  }
  accountable?: AutocompleteOption
  responsible?: AutocompleteOption
  assignee?: AutocompleteOption
  estimatedStoryPoint: {
    from?: number
    to?: number
  }
  estimatedHour: {
    from?: number
    to?: number
  }
  actualHour: {
    from?: number
    to?: number
  }
  scheduledStartDate: {
    from?: DateVO
    to?: DateVO
  }
  scheduledEndDate: {
    from?: DateVO
    to?: DateVO
  }
  actualStartDate: {
    from?: DateVO
    to?: DateVO
  }
  actualEndDate: {
    from?: DateVO
    to?: DateVO
  }
  sprint: {
    uuid?: string
    code?: string
    name?: string
    startDate?: {
      from?: DateVO
      to?: DateVO
    }
    endDate?: {
      from?: DateVO
      to?: DateVO
    }
    status?: SprintStatus
    teamUuid?: string
  }
  blank: {
    scheduledStartDate?: boolean
    scheduledEndDate?: boolean
    actualStartDate?: boolean
    actualEndDate?: boolean
    sprint?: boolean
  }
  task?: string
  status?: WbsItemStatus[]
  types?: WbsItemType[]
  aggregate?: boolean
  testProcess?: AutocompleteOption
  testScenario?: AutocompleteOption
  ticketTypes?: WbsItemTypeVO[]
  parent?: AutocompleteOption
  ticketList?: AutocompleteOption
  priority?: CustomEnumValue[]
  critical?: boolean
  createdAt: {
    from?: DateVO
    to?: DateVO
  }
  updatedAt: {
    from?: DateVO
    to?: DateVO
  }
  createdBy?: AutocompleteOption
  updatedBy?: AutocompleteOption
  disableRestore?: boolean // disable restoe seach condition
  rootUuid?: string
}

export const toPlainSearchFilterForApi = (searchFilter?: SearchFilter) => {
  if (!searchFilter) {
    return undefined
  }
  const plainSearchFilter: any = removeEmptyPropertiesFromSearchFilter({
    ...searchFilter,
  })
  // Convert CustomEnumValue to plain value.
  if (plainSearchFilter.priority) {
    plainSearchFilter.priority = plainSearchFilter.priority.map(v => v.value)
  }
  // Convert DateVO to plain value.
  if (plainSearchFilter.scheduledStartDate) {
    plainSearchFilter.scheduledStartDate = {
      from: searchFilter.scheduledStartDate.from
        ? searchFilter.scheduledStartDate.from.formatForApi()
        : undefined,
      to: searchFilter.scheduledStartDate.to
        ? searchFilter.scheduledStartDate.to.formatForApi()
        : undefined,
    }
  }
  if (plainSearchFilter.scheduledEndDate) {
    plainSearchFilter.scheduledEndDate = {
      from: searchFilter.scheduledEndDate.from
        ? searchFilter.scheduledEndDate.from.formatForApi()
        : undefined,
      to: searchFilter.scheduledEndDate.to
        ? searchFilter.scheduledEndDate.to.formatForApi()
        : undefined,
    }
  }
  if (plainSearchFilter.actualStartDate) {
    plainSearchFilter.actualStartDate = {
      from: searchFilter.actualStartDate.from
        ? searchFilter.actualStartDate.from.formatForApi()
        : undefined,
      to: searchFilter.actualStartDate.to
        ? searchFilter.actualStartDate.to.formatForApi()
        : undefined,
    }
  }
  if (plainSearchFilter.actualEndDate) {
    plainSearchFilter.actualEndDate = {
      from: searchFilter.actualEndDate.from
        ? searchFilter.actualEndDate.from.formatForApi()
        : undefined,
      to: searchFilter.actualEndDate.to
        ? searchFilter.actualEndDate.to.formatForApi()
        : undefined,
    }
  }
  if (plainSearchFilter.sprint?.startDate) {
    plainSearchFilter.sprint.startDate = {
      from: searchFilter.sprint?.startDate?.from
        ? searchFilter.sprint.startDate.from.formatForApi()
        : undefined,
      to: searchFilter.sprint?.startDate?.to
        ? searchFilter.sprint.startDate.to.formatForApi()
        : undefined,
    }
  }
  if (plainSearchFilter.sprint?.endDate) {
    plainSearchFilter.sprint.endDate = {
      from: searchFilter.sprint.endDate?.from
        ? searchFilter.sprint.endDate.from.formatForApi()
        : undefined,
      to: searchFilter.sprint.endDate?.to
        ? searchFilter.sprint.endDate.to.formatForApi()
        : undefined,
    }
  }
  if (searchFilter.createdAt) {
    plainSearchFilter.createdAt = formatDateTimeRangeForApi({
      from: searchFilter.createdAt.from,
      to: searchFilter.createdAt.to,
    })
  }
  if (searchFilter.updatedAt) {
    plainSearchFilter.updatedAt = formatDateTimeRangeForApi({
      from: searchFilter.updatedAt.from,
      to: searchFilter.updatedAt.to,
    })
  }

  return plainSearchFilter
}

export const toPlainSearchFilter = (searchFilter?: SearchFilter) => {
  if (!searchFilter) {
    return undefined
  }
  const plainSearchFilter: any = removeEmptyPropertiesFromSearchFilter({
    ...searchFilter,
  })
  // Convert DateVO to plain value.
  const dateToLabel = (date: DateVO | undefined): String | undefined => {
    if (!date) return undefined
    if (!!date.relativeDate) {
      return new RelativeDate(date.relativeDate.value).toLabel()
    }
    return date.toLabel()
  }
  if (plainSearchFilter.scheduledStartDate) {
    plainSearchFilter.scheduledStartDate = {
      from: dateToLabel(searchFilter.scheduledStartDate.from),
      to: dateToLabel(searchFilter.scheduledStartDate.to),
    }
  }
  if (plainSearchFilter.scheduledEndDate) {
    plainSearchFilter.scheduledEndDate = {
      from: dateToLabel(searchFilter.scheduledEndDate.from),
      to: dateToLabel(searchFilter.scheduledEndDate.to),
    }
  }
  if (plainSearchFilter.actualStartDate) {
    plainSearchFilter.actualStartDate = {
      from: dateToLabel(searchFilter.actualStartDate.from),
      to: dateToLabel(searchFilter.actualStartDate.to),
    }
  }
  if (plainSearchFilter.actualEndDate) {
    plainSearchFilter.actualEndDate = {
      from: dateToLabel(searchFilter.actualEndDate.from),
      to: dateToLabel(searchFilter.actualEndDate.to),
    }
  }
  if (plainSearchFilter.sprint?.startDate) {
    plainSearchFilter.sprint.startDate = {
      from: dateToLabel(searchFilter.sprint.startDate?.from),
      to: dateToLabel(searchFilter.sprint.startDate?.to),
    }
  }
  if (plainSearchFilter.sprint?.endDate) {
    plainSearchFilter.sprint.endDate = {
      from: dateToLabel(searchFilter.sprint.endDate?.from),
      to: dateToLabel(searchFilter.sprint.endDate?.to),
    }
  }
  if (plainSearchFilter.createdAt) {
    plainSearchFilter.createdAt = {
      from: searchFilter.createdAt.from
        ? searchFilter.createdAt.from.formatYYYYMMDDHHmm()
        : undefined,
      to: searchFilter.createdAt.to
        ? searchFilter.createdAt.to.formatYYYYMMDDHHmm()
        : undefined,
    }
  }
  if (plainSearchFilter.updatedAt) {
    plainSearchFilter.updatedAt = {
      from: searchFilter.updatedAt.from
        ? searchFilter.updatedAt.from.formatYYYYMMDDHHmm()
        : undefined,
      to: searchFilter.updatedAt.to
        ? searchFilter.updatedAt.to.formatYYYYMMDDHHmm()
        : undefined,
    }
  }

  return plainSearchFilter
}

export const toSearchFilter = (plainSearchFilter: any) => {
  const searchFilter: any = { ...plainSearchFilter }
  // Convert DateVO to plain value.
  if (searchFilter.scheduledStartDate) {
    searchFilter.scheduledStartDate = {
      from: searchFilter.scheduledStartDate.from
        ? new DateVO(searchFilter.scheduledStartDate.from)
        : undefined,
      to: searchFilter.scheduledStartDate.to
        ? new DateVO(searchFilter.scheduledStartDate.to)
        : undefined,
    }
  }
  if (searchFilter.scheduledEndDate) {
    searchFilter.scheduledEndDate = {
      from: searchFilter.scheduledEndDate.from
        ? new DateVO(searchFilter.scheduledEndDate.from)
        : undefined,
      to: searchFilter.scheduledEndDate.to
        ? new DateVO(searchFilter.scheduledEndDate.to)
        : undefined,
    }
  }
  if (searchFilter.actualStartDate) {
    searchFilter.actualStartDate = {
      from: searchFilter.actualStartDate.from
        ? new DateVO(searchFilter.actualStartDate.from)
        : undefined,
      to: searchFilter.actualStartDate.to
        ? new DateVO(searchFilter.actualStartDate.to)
        : undefined,
    }
  }
  if (searchFilter.actualEndDate) {
    searchFilter.actualEndDate = {
      from: searchFilter.actualEndDate.from
        ? new DateVO(searchFilter.actualEndDate.from)
        : undefined,
      to: searchFilter.actualEndDate.to
        ? new DateVO(searchFilter.actualEndDate.to)
        : undefined,
    }
  }
  if (searchFilter.sprint?.startDate) {
    searchFilter.sprint.startDate = {
      from: searchFilter.sprint.startDate.from
        ? new DateVO(searchFilter.sprint.startDate.from)
        : undefined,
      to: searchFilter.sprint.startDate.to
        ? new DateVO(searchFilter.sprint.startDate.to)
        : undefined,
    }
  }
  if (searchFilter.sprint?.endDate) {
    searchFilter.sprint.endDate = {
      from: searchFilter.sprint.endDate.from
        ? new DateVO(searchFilter.sprint.endDate.from)
        : undefined,
      to: searchFilter.sprint.endDate.to
        ? new DateVO(searchFilter.sprint.endDate.to)
        : undefined,
    }
  }
  if (searchFilter.createdAt) {
    searchFilter.createdAt = {
      from: searchFilter.createdAt.from
        ? new DateVO(searchFilter.createdAt.from)
        : undefined,
      to: searchFilter.createdAt.to
        ? new DateVO(searchFilter.createdAt.to)
        : undefined,
    }
  }
  if (searchFilter.updatedAt) {
    searchFilter.updatedAt = {
      from: searchFilter.updatedAt.from
        ? new DateVO(searchFilter.updatedAt.from)
        : undefined,
      to: searchFilter.updatedAt.to
        ? new DateVO(searchFilter.updatedAt.to)
        : undefined,
    }
  }

  return searchFilter
}

export const commonDefaultSearchFilter = (): SearchFilter => {
  return {
    deliverable: undefined,
    accountable: undefined,
    responsible: undefined,
    assignee: undefined,
    estimatedStoryPoint: {},
    estimatedHour: {},
    actualHour: {},
    scheduledStartDate: {},
    scheduledEndDate: {},
    actualStartDate: {},
    actualEndDate: {},
    sprint: {},
    team: {},
    code: '',
    uuid: '',
    displayName: '',
    description: '',
    task: '',
    blank: {
      scheduledStartDate: false,
      scheduledEndDate: false,
      actualStartDate: false,
      actualEndDate: false,
      sprint: false,
    },
    status: undefined,
    types: undefined,
    ticketTypes: undefined,
    testProcess: undefined,
    testScenario: undefined,
    parent: undefined,
    ticketList: undefined,
    priority: undefined,
    critical: undefined,
    disableRestore: undefined,
    createdAt: {},
    updatedAt: {},
    createdBy: undefined,
    updatedBy: undefined,
  }
}

export const getDefaultSearchFilter = (): SearchFilter => {
  const externalId = store.getState().appFunction.currentExternalId
  let defaultFilter = {}
  if (externalId === 'taskActualWork.edit') {
    defaultFilter = {
      types: [WbsItemType.TASK],
      status: [
        WbsItemStatus.TODO,
        WbsItemStatus.DOING,
        WbsItemStatus.REVIEW,
        WbsItemStatus.DONE,
      ],
    }
  }
  if (externalId === 'wbsItem.search.edit') {
    defaultFilter = {
      status: [WbsItemStatus.TODO, WbsItemStatus.DOING, WbsItemStatus.REVIEW],
      types: [WbsItemType.DELIVERABLE, WbsItemType.TASK],
    }
  }
  return {
    ...commonDefaultSearchFilter(),
    ...defaultFilter,
  }
}

const mapStateToProps = (state: AllState) => ({
  externalId: state.appFunction.currentExternalId,
  projectUuid: state.project.selected,
  wbsItemTypes: state.project.wbsItemTypes,
  ticketTypes: state.project.ticketTypes,
})

export default connect<StateProps, void, ComponentOwnProps, AllState>(
  mapStateToProps
)(injectIntl(WbsItemSearchCondition))
