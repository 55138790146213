import React from 'react'
import { RowNode } from 'ag-grid-community'
import { IconColor } from '../../../../../../../../styles/commonStyles'
import AttachmentIcon from '../../../../../../../components/icons/AttachmentIcon'

interface AttachmentSummary {
  hasAttachment: boolean
  totalCount: number
}

interface Props {
  node: RowNode
  openAttachmentList?: (row) => void
  closeAttachmentList?: () => void
  getAttachmentSummary?: (row) => AttachmentSummary | undefined
}

const OpenAttachmentCell = ({
  node,
  openAttachmentList,
  closeAttachmentList,
  getAttachmentSummary,
}: Props) => {
  if (
    node.data.isViewOnly ||
    node.data.isTotal ||
    !openAttachmentList ||
    !closeAttachmentList ||
    !getAttachmentSummary
  ) {
    return <></>
  }
  const attachmentSummary = getAttachmentSummary(node.data)
  if (!attachmentSummary || !attachmentSummary.hasAttachment) {
    return <></>
  }
  return (
    <div className="sevend-ag-cell-attachment">
      <AttachmentIcon
        className="sevend-ag-cell-attachment__attachment-icon"
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          openAttachmentList(node.data)
        }}
        style={{
          color: IconColor.GREY,
          fill: IconColor.GREY,
          fontsize: 18,
          width: 18,
        }}
      />
    </div>
  )
}

export default OpenAttachmentCell
