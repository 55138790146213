import {
  refinementService,
  UpdateRefinementDeltaRequest,
} from './../../domain/entity/ticket/RefinementEntity'
import { useCallback } from 'react'
import { RefinementRepository } from '../../applications/ports/refinementRepository'
import api from '../../lib/commons/api'

export const useRefinementRepository = (): RefinementRepository => {
  const fetch = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/refinements/detail',
      { wbsItemUuid: uuid },
      true
    )
    return refinementService.fromApiResponseToEntity(response.json)
  }, [])
  const updateDelta = useCallback(
    async (request: UpdateRefinementDeltaRequest) => {
      const response = await api.functional.request(
        'PUT',
        '/api/v1/projects/refinements/delta',
        request,
        true
      )
      return {}
    },
    []
  )
  return { fetch, updateDelta }
}
