import _ from 'lodash'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ValueGetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { intl } from '../../../../i18n'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  FinancialStatementAccountsType,
  LedgerAccountsType,
  getLedgerAccountsTypeLabel,
} from '../../LedgerAccounts/ledgerAccounts'
import { ProfitLossItemAmountRowBody } from '../../ProfitLossItems/profitLossItems'
import { DateTerm } from '../../../../utils/date'
import { generateYearMonthList } from '../../ProfitLossItems/gridOptions'
import DateVO from '../../../../vo/DateVO'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import '../../ProfitLossItems/gridOptions/styles.scss'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'

export const profitLossSummaryGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: false,
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    autoGroupColumnDef: {
      field: 'body.displayName',
      headerName: intl.formatMessage({ id: 'profitLossItems.name' }),
      hide: false,
      pinned: true,
      sortable: true,
      resizable: true,
      width: 300,
      filter: ClientSideTextFilter,
      floatingFilter: true,
      editable: false,
      suppressMovable: true,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: DefaultCellRenderer,
      },
    },
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        cellRendererParams: params => {
          return {
            value: getRowNumber(params.node),
          }
        },
        valueGetter: (params: ValueGetterParams) => {
          return getRowNumber(params.node)
        },
      },
      {
        groupId: 'information',
        headerName: intl.formatMessage({ id: 'profitLossItems.information' }),
        children: [
          {
            field: 'body.type',
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.ledgerAccountsType',
            }),
            pinned: true,
            lockPosition: 'left',
            sortable: true,
            valueGetter: (params: ValueGetterParams) => {
              const type = params.data.body.type
              return !type
                ? intl.formatMessage({ id: 'profitLossSummary.row.details' })
                : getLedgerAccountsTypeLabel(type)
            },
          },
          {
            field: 'body.financialState',
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.type.financialStatementAccounts',
            }),
            width: 100,
            hide: true,
            pinned: true,
            suppressColumnsToolPanel: true,
            lockPosition: 'left',
            valueGetter: params => {
              return params.data.body?.financialState?.displayName
            },
          },
          {
            field: 'body.generalLedger',
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.type.generalLedgerAccounts',
            }),
            width: 100,
            hide: true,
            pinned: true,
            suppressColumnsToolPanel: true,
            lockPosition: 'left',
            valueGetter: params => {
              return params.data?.body?.generalLedger?.displayName
            },
          },
          {
            field: 'body.subsidiary',
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.type.subsidiaryAccounts',
            }),
            width: 200,
            hide: true,
            pinned: true,
            suppressColumnsToolPanel: true,
            lockPosition: 'left',
            valueGetter: params => {
              if (
                !params.data.body.type ||
                params.data.body.type === LedgerAccountsType.SubsidiaryAccounts
              ) {
                return (
                  params.data?.body?.subsidiary?.displayName ??
                  intl.formatMessage({
                    id: 'profitLossSummary.no.subsidiary',
                  })
                )
              }
              return undefined
            },
          },
          {
            field: 'body.name',
            headerName: intl.formatMessage({ id: 'profitLossItems.name' }),
            width: 300,
            hide: true,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'total' }),
            hide: false,
            sortable: true,
            pinned: true,
            lockPosition: 'right',
            cellClass: 'amount',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
            },
            valueGetter: (params: ValueGetterParams) => {
              let sumTotal = summaryTotal(params.data)
              if (
                (params.data.body.financialState
                  .financialStatementAccountsType ===
                  FinancialStatementAccountsType.CostOfSales ||
                  params.data.body.financialState
                    .financialStatementAccountsType ===
                    FinancialStatementAccountsType.SGAExpense) &&
                sumTotal !== 0
              ) {
                sumTotal = -sumTotal
              }
              return sumTotal
            },
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
          {
            field: 'ratio',
            headerName: intl.formatMessage({
              id: 'profitLossSummary.profit.ratio',
            }),
            hide: false,
            sortable: true,
            pinned: true,
            lockPosition: 'right',
            cellClass: 'align-right',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
              decimalPoints: 1,
              unit: '%',
            },
            valueGetter: (params: ValueGetterParams) => {
              const value = calculateProfitRatio(params)
              return !!value ? value * 100 : undefined
            },
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'amount',
        dataType: 'Number',
        numberFormat: {
          format: '#,###',
        },
      },
      {
        id: 'align-right',
        alignment: {
          horizontal: 'Right',
        },
      },
    ],
  }
}

export type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
}

export const AMOUNT_FIELD_PREFIX = 'body.amounts.'

export const isAmountColumn = (id: string | undefined) => {
  return !!id && id.startsWith(AMOUNT_FIELD_PREFIX)
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const columnDefs = api?.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(AMOUNT_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(AMOUNT_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${AMOUNT_FIELD_PREFIX}${year}`,
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.year' },
          { year }
        ),
        children: [],
      }
    })
    const firstDayOfMonth = DateVO.now().getFirstDayOfMonth()
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const isCurrentMonth = date.isEqual(firstDayOfMonth)
      const col: ColDef = {
        field: AMOUNT_FIELD_PREFIX + date.format('YYYY/MM'),
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.month' },
          { month: date.getMonth() }
        ),
        hide: false,
        pinned: false,
        suppressMovable: true,
        cellRenderer: NumberCellRenderer,
        cellRendererParams: {
          numberWithCommas: true,
        },
        cellClassRules: {
          profit_loss_item_past_month: params => {
            return date.isBefore(DateVO.now().getFirstDayOfMonth())
          },
        },
        cellStyle: (params: CellClassParams) => {
          return isCurrentMonth ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE : {}
        },
        cellClass: 'amount',
        editable: false,
        valueGetter: (params: ValueGetterParams) => {
          const amounts = params.data.body.amounts
          const target = amounts.find(v => v.yearMonth === date.formatForApi())
          if (
            params.data.body.financialState.financialStatementAccountsType ===
              FinancialStatementAccountsType.CostOfSales ||
            params.data.body.financialState.financialStatementAccountsType ===
              FinancialStatementAccountsType.SGAExpense
          ) {
            if (!target?.amount) return ''
            return -target.amount
          }
          return target?.amount ?? ''
        },
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${AMOUNT_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => {
      newColDefs?.push(year)
    })
  }
  api.setColumnDefs(newColDefs!)
}

const summaryTotal = (data: any): number => {
  const amounts: ProfitLossItemAmountRowBody[] = data?.body?.amounts ?? []
  const sum = amounts.reduce((prev, current) => {
    return prev + (current.amount ?? 0)
  }, 0)
  return sum
}

const calculateProfitRatio = (params: ValueGetterParams) => {
  const type = params.data?.body?.financialState?.financialStatementAccountsType
  const salesTotal = calculateSalesTotal(params)
  if (
    type === FinancialStatementAccountsType.GrossProfit ||
    type === FinancialStatementAccountsType.OperatingIncome
  ) {
    const total = summaryTotal(params.data)
    if (total === 0 || salesTotal === 0) return 0
    return total / salesTotal
  }
  return undefined
}

const calculateSalesTotal = (params: ValueGetterParams): number => {
  const salesTree = params.context?.masters?.find(
    v =>
      v.financialStatementAccountsType === FinancialStatementAccountsType.Sales
  )
  if (!salesTree) return 0
  const salesRow = params.api.getRowNode(salesTree.uuid)?.data
  return summaryTotal(salesRow)
}
