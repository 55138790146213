import VOBase from '../base'
import { TagForWbsItem } from '../../lib/functions/tag'

export default class TagVO extends VOBase {
  private readonly value: TagForWbsItem[]

  constructor(_value: TagForWbsItem[]) {
    super()
    this.value = _value
  }

  getValue = () => {
    return this.value
  }
  format = () => {
    if (!this.value || !Array.isArray(this.value)) {
      return ''
    }
    return this.value.map(v => v.name).join(',')
  }
  serialize = () => this.value

  isEqual(comparedValue: VOBase): boolean {
    if (comparedValue instanceof TagVO) {
      return JSON.stringify(this.value) === JSON.stringify(comparedValue.value)
    }
    return false
  }
}
