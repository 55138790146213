import React, { useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import _ from 'lodash'
import './styles.scss'
import {
  escapeBreakText,
  listItemHandler,
  tableHandler,
} from '../../../../../utils/markdown'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { atelierDuneLight as CodeBlockStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import { MarkdownType } from '../../multiline-text/MultilineTextEditor'
import clsx from 'clsx'
import { HeadingProps } from 'react-markdown/lib/ast-to-react'

interface Props {
  text: string
  markdownType?: MarkdownType
}

export const MarkdownPreview = ({ text, markdownType }: Props) => {
  const [previewText, setPreviewText] = useState('')
  const setDelayPreviewText = useMemo(
    () => _.debounce(v => setPreviewText(escapeBreakText(v, '&nbsp;')), 200),
    []
  )

  useEffect(() => {
    setDelayPreviewText(text)
  }, [text])

  return (
    <ReactMarkdown
      className={clsx({
        'markdown-body': true,
        'markdown-side-by-side': markdownType === MarkdownType.SideBySide,
      })}
      children={previewText}
      linkTarget={'_blank'}
      rawSourcePos={true}
      remarkPlugins={[remarkGfm]}
      remarkRehypeOptions={{
        handlers: {
          listItem(h, node, parent) {
            return listItemHandler(h, node, parent)
          },
          table(h, node) {
            return tableHandler(h, node, true)
          },
        },
      }}
      components={{
        code: CodeBlockComponent,
        h1: props => HeaderComponent({ ...props, level: 1 }),
        h2: props => HeaderComponent({ ...props, level: 2 }),
        h3: props => HeaderComponent({ ...props, level: 3 }),
      }}
    />
  )
}

const CodeBlockComponent = props => {
  if (props.inline) {
    return <code className={props.className}>{props.children}</code>
  }
  const langMatch = /language-(\S+)/.exec(props.className || '')
  let lang = ''
  let title = ''
  if (langMatch) {
    let value = langMatch[1] || ''
    if (value.match(/:/)) {
      let strSplit = value.split(':')
      lang = strSplit.length > 0 ? strSplit[0] : ''
      title = strSplit.length > 1 ? strSplit[1] : ''
    } else if (value.match(/\./)) {
      let strSplit = value.split('.')
      title = value
      lang = strSplit.length > 1 ? strSplit[1] : ''
    } else {
      lang = value
      title = value
    }
  }
  if (!SyntaxHighlighter.supportedLanguages.includes(lang.toLowerCase())) {
    lang = 'text'
  }
  return (
    <>
      {title && <div style={{ paddingLeft: '5px' }}>{title}</div>}
      <SyntaxHighlighter
        style={CodeBlockStyle}
        language={lang}
        children={String(props.children).replace(/\n$/, '')}
      />
    </>
  )
}

const HeaderComponent = ({ level, sourcePosition, children }: HeadingProps) => {
  const lineId = `header-line${sourcePosition?.start.line}`
  switch (level) {
    case 2:
      return <h2 id={lineId}>{children}</h2>
    case 3:
      return <h3 id={lineId}>{children}</h3>
    default:
      return <h1 id={lineId}>{children}</h1>
  }
}
