import * as d3 from 'd3'
import { FontSize, FontWeight } from '../../../../../../styles/commonStyles'
import {
  addPortal,
  addTooltip,
  addTooltipByEvent,
  CHART_MAIN_TOP,
  TwoDimChartContext,
  TwoDimGraphPlotter,
} from '../index'

interface BorderProps {
  xValue: number
  label?: string
  style: BorderStyle
  tooltipButtonContents?: JSX.Element
  tooltip?: JSX.Element
  defaultShowTooltip?: boolean
}

interface BorderStyle {
  color: string
}

const TOOLTIP_BUTTON_MARGIN_Y: number = 2
const TOOLTIP_CLICK_MARGIN: number = 5

export class VerticalBorderPlotter extends TwoDimGraphPlotter {
  private props: BorderProps

  constructor(props: BorderProps) {
    super()
    this.props = props
  }

  plot(ctx: TwoDimChartContext) {
    const { chart, xScale, innerHeight } = ctx
    const {
      xValue,
      label,
      style: { color },
      tooltipButtonContents,
      tooltip,
      defaultShowTooltip,
    } = this.props
    const domain = xScale.domain()
    if (domain[0] <= xValue && domain[1] >= xValue) {
      const px = xScale(xValue)
      const height = innerHeight
      chart
        .append('line')
        .attr('x1', px)
        .attr('x2', px)
        .attr('y1', height)
        .attr('y2', CHART_MAIN_TOP)
        .attr('stroke', color)
        .attr('stroke-width', '2px')
      const labelElem = label
        ? chart
            .append('text')
            .attr('x', px)
            .attr('y', 10)
            .text(label)
            .style('fill', 'black')
            .style('font-size', FontSize.LARGE)
            .style('font-weight', FontWeight.BOLD)
            .style('text-anchor', 'middle')
            .style('width', '100%')
        : undefined
      if (tooltipButtonContents) {
        let dx = px
        if (labelElem) {
          dx = dx + (labelElem.node()?.getBBox()?.width || 0) / 2
        }

        const portal = addPortal(
          'verticalBorderTooltipButton',
          tooltipButtonContents,
          'left',
          'top',
          dx,
          TOOLTIP_BUTTON_MARGIN_Y,
          false,
          (
            root: HTMLElement | null,
            container: HTMLDivElement,
            content: Element | null
          ) => {
            if (defaultShowTooltip && tooltip && container && content) {
              const x =
                container.offsetLeft -
                (root?.scrollLeft || 0) +
                content.clientWidth / 2 +
                TOOLTIP_CLICK_MARGIN

              const y =
                container.offsetTop -
                (root?.scrollTop || 0) +
                content.clientHeight / 2 +
                TOOLTIP_CLICK_MARGIN

              addTooltip(tooltip, `#${portal.attr('id')}`, 'left', 'top', x, y)
            }
          }
        )
        portal
          .on('mouseover', (e, d) => {
            d3.select(e.currentTarget).style('cursor', 'pointer')
          })
          .on('mouseout', e => {
            d3.select(e.currentTarget).style('cursor', 'default')
          })
          .on('click', (e, d) => {
            if (tooltip) {
              addTooltipByEvent(
                tooltip,
                e,
                'left',
                'top',
                TOOLTIP_CLICK_MARGIN,
                TOOLTIP_CLICK_MARGIN
              )
            }
          })
      }
    }
  }
}
