import { IconButton } from '@mui/material'
import MuiNotificationsIcon from '@mui/icons-material/NotificationsRounded'
import { connect } from 'react-redux'
import store, { AllState } from '../../../../store'
import {
  openInformation,
  toggleInformation,
  ViewType,
} from '../../../../store/information'
import { Color } from '../../../../styles/commonStyles'
import AnnotationMark from './AnnotationMark'

type Props = {
  iconSize: number
}

type StateProps = {
  unreadNewsCount: number
  type: ViewType
}

const NotificationsIcon = ({
  iconSize,
  unreadNewsCount,
  type,
}: Props & StateProps) => {
  return (
    <IconButton
      sx={{ padding: '6px', marginRight: '10px' }}
      onClick={e => {
        // TODO: fix
        // Necessary not to close information because of ClickAwayListener.
        e.preventDefault()
        e.stopPropagation()
        if (type === ViewType.INFORMATION) {
          store.dispatch(toggleInformation())
        } else {
          store.dispatch(openInformation())
        }
      }}
    >
      {unreadNewsCount > 0 && <AnnotationMark />}
      <MuiNotificationsIcon
        sx={{ fontSize: iconSize, color: Color.MONOTONE }}
      />
    </IconButton>
  )
}

const mapStateToProps = (state: AllState) => ({
  unreadNewsCount: state.news.newsList.filter(v => !v.read).size,
  type: state.information.type,
})

export default connect(mapStateToProps)(NotificationsIcon)
