import { SubmitType } from '../../containers/meta/ViewMeta'
import wbsItem, {
  getAttachmentType,
  WbsItemBasic,
} from '../../../lib/functions/wbsItem'
import { WbsItemData } from '../WbsItem'
import {
  AttachmentHandlerParams,
  SingleSheetContext,
  SingleSheetKey,
  SingleSheetOptions,
} from '../../containers/SingleSheet'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import EntitySearchVO from '../../../vo/EntitySearchVO'
import TextVO from '../../../vo/TextVO'
import SelectVO from '../../../vo/SelectVO'
import { TableData } from '../../containers/SingleSheet/DataTable'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import CommentHeaderWbsItem, {
  mapTableDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import WbsItemTitle from '../../components/headers/HeaderBar/Title/WbsItemTitle'
import FavoriteIcon from '../../components/icons/FavoriteIcon'
import Auth from '../../../lib/commons/auth'
import ProjectPlanBreadcrumbs from '../../components/headers/HeaderBar/Breadcrumbs/ProjectPlanBreadcrumbs'
import WbsItemToolBar from '../WbsItem/WbsItemToolBar'
import MultiEntitySearchVO from '../../../vo/MultiEntitySearchVO'
import { toggleWatchers } from '../../../lib/functions/watchWbsItem'
import objects from '../../../utils/objects'
import { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { generateUuid } from '../../../utils/uuids'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'

export class TicketData extends TableData {
  ticketList?: EntitySearchVO
  ticketType?: SelectVO
  parentWbsItem?: EntitySearchVO
  wbsItem?: WbsItemData

  getCode() {
    return this.wbsItem?.code
  }

  getName() {
    return this.wbsItem?.displayName
  }
}

// Change not to export [V6BWULRZ]
export class TicketConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return {
      ...dest,
      prevSiblingUuid: src.prevSiblingUuid,
      parentUuid: src.parentUuid,
      wbsItem: {
        ...dest.wbsItem,
        uuid: src.wbsItem?.uuid,
        lockVersion: src.wbsItem?.lockVersion,
        wbsItemType: src.wbsItem?.typeDto
          ? new WbsItemTypeVO(src.wbsItem.typeDto)
          : undefined,
        watchers: new MultiEntitySearchVO(src.wbsItem?.watchers || []),
        projectUuid: src.wbsItem?.projectUuid,
      },
      projectPlanUuid: src.projectPlanUuid,
    }
  }

  convertSerialized({
    src,
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    delta?: any
  }) {
    return {
      before: initialData
        ? {
            input: {
              ...initialData,
              prevSiblingUuid: src.prevSiblingUuid,
              parentUuid: src.parentUuid,
              wbsItem: {
                ...initialData.wbsItem,
                uuid: src.wbsItem?.uuid,
                lockVersion: src.wbsItem?.lockVersion,
              },
            },
            watchers: initialData.wbsItem?.watchers,
            sprint: initialData.wbsItem?.sprintUuid,
          }
        : undefined,
      after: {
        input: {
          uuid: dest.uuid,
          projectUuid: src.wbsItem?.projectUuid,
          ticketListUuid: dest.ticketListUuid,
          ticketType: dest.ticketType,
          projectPlan: {
            projectUuid: src.wbsItem?.projectUuid,
            uuid: src.projectPlanUuid ?? generateUuid(),
            type: WbsItemType.TASK,
            wbsItem: {
              ...dest.wbsItem,
              uuid: src.wbsItem?.uuid,
              lockVersion: src.wbsItem?.lockVersion,
            },
            productBacklogItem: false,
          },
          parentWbsItemUuid: dest.parentWbsItemUuid,
          parentUuid: src.parentUuid,
          prevSiblingUuid: src.prevSiblingUuid,
          extensions: dest.extensions,
        },
        watchers: dest.wbsItem?.watchers,
        sprint: dest.wbsItem?.sprintUuid,
        tags: delta.wbsItem?.tags
          ? {
              uuid: dest.uuid,
              wbsItemUuid: src.wbsItem?.uuid,
              tagUuids: dest.wbsItem?.tags.map(v => v.uuid),
            }
          : undefined,
      },
      delta: delta
        ? {
            input: {
              ...delta,
              uuid: dest.uuid,
              wbsItem: {
                ...delta.wbsItem,
                uuid: src.wbsItem?.uuid,
                type: dest.wbsItem?.type,
              },
            },
            watchers: dest.wbsItem?.watchers,
            sprints: delta.wbsItem?.sprintUuid
              ? {
                  wbsItemUuid: src.wbsItem?.uuid,
                  wbsItemLockVersion: src.wbsItem?.lockVersion,
                  added: delta.wbsItem?.sprintUuid.newValue,
                  deleted: !delta.wbsItem?.sprintUuid.newValue
                    ? delta.wbsItem?.sprintUuid.oldValue
                    : undefined,
                }
              : undefined,
            tags: delta.wbsItem?.tags
              ? {
                  wbsItemUuid: src.wbsItem?.uuid,
                  tagUuids: dest.wbsItem?.tags.map(v => v.uuid),
                }
              : undefined,
          }
        : undefined,
    }
  }

  convertInitialData(dest: any, auxiliaries: any) {
    const parentWbsItem = auxiliaries?.parentWbsItem
    return {
      ...dest,
      parentWbsItem: parentWbsItem
        ? new EntitySearchVO(
            parentWbsItem.uuid,
            parentWbsItem.displayName,
            parentWbsItem.code
          )
        : undefined,
    }
  }
}

export default class Ticket extends SingleSheetOptions<TicketData> {
  getApplicationContext = (basic: WbsItemBasic) => {
    return {
      groupKeys: [basic.projectUuid, basic.ticketType, basic.ticketListUuid],
    }
  }
  selectKey = async (basic: WbsItemBasic) => {
    if (!basic.code) {
      // throw new Error('Could not get code')
      return
    }
    return { uuid: basic.ticketUuid } as SingleSheetKey
  }
  converterSpec = new TicketConverterSpec()
  getCodeByData = (data: any): TextVO => {
    return data.wbsItem.code
  }
  // Update entity by ticket uuid, but use task uuid for tabs
  uuidForOptionalFunction = (data: any) => [
    data.wbsItem?.uuid || '',
    data.uuid || '',
  ]
  getToolbarProps = (ctx: SingleSheetContext<TicketData>) => {
    return {
      wbsItemType: ctx.state.data.wbsItem?.wbsItemType,
    }
  }

  onUploadAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<TicketData>): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    if (!ctx.state.data.wbsItem?.uuid) {
      return
    }
    await this.onUploadWbsItemAttachments({
      externalId,
      uuid: ctx.state.data.wbsItem.uuid,
      lockVersion: ctx.state.data.wbsItem.lockVersion,
      attachments,
      ctx,
    })
    return {
      uuid,
      lockVersion: lockVersion as number,
    }
  }

  onUploadWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<TicketData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.wbsItem?.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.createAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachments,
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  onDeleteAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<TicketData>): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    if (!ctx.state.data.wbsItem?.uuid) {
      return
    }
    await this.onDeleteWbsItemAttachments({
      externalId,
      uuid: ctx.state.data.wbsItem.uuid,
      lockVersion: ctx.state.data.wbsItem.lockVersion || 0,
      attachments,
      ctx,
    })
    return {
      uuid,
      lockVersion: lockVersion as number,
    }
  }

  onDeleteWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<TicketData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.wbsItem?.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.deleteAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachmentItemUuids: attachments.map(v => v.uuid),
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  TitleComponent = props => {
    const type = props.data?.wbsItem?.typeDto
    return (
      <WbsItemTitle
        path={'wbsItem.displayName'}
        data={props.data}
        icon={type?.iconUrl ? <img src={type.iconUrl} /> : undefined}
      />
    )
  }

  HeaderComponent = props => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FavoriteIcon
          key="header-favorite-icon"
          checked={(props.data.wbsItem?.watchers || []).some(
            v => v.uuid === Auth.getCurrentTenant()?.user?.uuid
          )}
          onClick={(checked: boolean) => {
            const watchers = props.data.wbsItem?.watchers || []
            toggleWatchers(watchers, 'wbsItem.watchers', checked)
          }}
        />
        {!!props.data.wbsItem?.lockVersion && (
          <Box sx={{ marginLeft: '16px' }}>
            <ProjectPlanBreadcrumbs
              key="header-breadcrumb"
              rootUuid={props.data.wbsItem!.uuid!}
              itemName={props.data.wbsItem!.displayName}
            />
          </Box>
        )}
      </Box>
    )
  }

  toolBarItems = ctx => {
    const item = ctx.state.data.wbsItem
    const status = item.status ? item.status.getValue() : WbsItemStatus.TODO
    const cumulation = ctx.state.cumulation
    const cellDefs = ctx.state.cellDefs

    return [
      <WbsItemToolBar
        key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}
        status={status}
        item={item}
        ctx={ctx}
        cumulation={cumulation}
        cellDefs={cellDefs}
        prefix={'wbsItem.'}
        ticketItem={ctx.state.data}
      />,
    ]
  }
  defaultLeftTabExternalId = 'ticket.wbsItem.description'
  ignoreDataChangeCheckKeys = ['deliverableAttachments']

  commentHeaderComponents = ctx => {
    if (ctx.state.submitType === SubmitType.Create) {
      return []
    }
    return [
      <CommentHeaderWbsItem
        key={'1'}
        wbsItem={mapTableDataForCommentHeader(
          ctx.state.data.wbsItem,
          ctx.state.data.ticketType
        )}
        onAfterUpdate={() => {
          ctx.refreshAndMergeUncommitData({})
        }}
        readonly={true}
      />,
    ]
  }

  isDateDelayed = (
    data: TicketData,
    target:
      | 'scheduledStartDate'
      | 'scheduledEndDate'
      | 'actualStartDate'
      | 'actualEndDate'
  ): boolean => {
    const delayedJudgmentItem = data.wbsItem
    if (!delayedJudgmentItem) return false
    const today = new Date(Date.now())
    const scheduledDate = objects.getValue(delayedJudgmentItem, 'scheduledDate')
    const actualDate = objects.getValue(delayedJudgmentItem, 'actualDate')
    if (target === 'scheduledStartDate') {
      return (
        scheduledDate?.getValue().startDate! <= today &&
        actualDate?.getValue().startDate === undefined
      )
    } else if (target === 'scheduledEndDate') {
      return (
        scheduledDate?.getValue().endDate! <= today &&
        actualDate?.getValue().endDate === undefined
      )
    } else if (target === 'actualStartDate') {
      return (
        scheduledDate?.getValue().startDate! < actualDate?.getValue().startDate!
      )
    } else if (target === 'actualEndDate') {
      return (
        scheduledDate?.getValue().endDate! < actualDate?.getValue().endDate!
      )
    }
    return false
  }

  getDelayTarget = (externalId: string, target) => {
    if (externalId.includes('scheduledDate') && target === 'start') {
      return 'scheduledStartDate'
    } else if (externalId.includes('scheduledDate') && target === 'end') {
      return 'scheduledEndDate'
    } else if (externalId.includes('actualDate') && target === 'start') {
      return 'actualStartDate'
    } else if (externalId.includes('actualDate') && target === 'end') {
      return 'actualEndDate'
    }
    return undefined
  }

  getStyleRule = (
    externalId: string
  ):
    | ((data: TicketData, target?: string) => CSSProperties | undefined)
    | undefined => {
    if (
      externalId.includes('scheduledDate') ||
      externalId.includes('actualDate')
    ) {
      return (data: TicketData, target?: string) => {
        if (!target) return undefined
        const delayTarget = this.getDelayTarget(externalId, target)
        if (!delayTarget) return undefined
        const isDelayed = this.isDateDelayed(data, delayTarget)
        if (isDelayed) {
          return {
            color: '#ff6b50',
          }
        }
      }
    }
  }

  getBacisFromSingleSheetData = (data: any) => {
    if (!data) return undefined
    return {
      projectUuid: data.wbsItem?.projectUuid,
      projectCode: data.ticketList?.projectCode,
      type: data.wbsItem?.type,
      ticketType: data.ticketType,
      ticketUuid: data.ticketList?.uuid,
    }
  }
}
