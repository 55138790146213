import BaseError from '../BaseError'

export enum DomainErrorCode {
  REQUIRED_VALUE_ERROR = 'REQUIRED_VALUE_ERROR',
  VALUE_TYPE_ERROR = 'VALUE_TYPE_ERROR',
  VALUE_FORMAT_ERROR = 'VALUE_FORMAT_ERROR',
  INVALID_VALUE_ERROR = 'INVALID_VALUE_ERROR',
}

interface DomainErrorParams {
  errorCode: DomainErrorCode
  message: string
}

export default class DomainError extends BaseError {
  constructor(params: DomainErrorParams) {
    super(params)
  }
}
