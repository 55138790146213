import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import { WbsItemBasic, WbsItemDeltaInput, WbsItemDetail } from './wbsItem'
import { CommentSummary } from '../../store/comments'
import { ProjectPlanCumulation, WatchersInput } from './projectPlan'
import { AttachmentSummary } from '../../utils/attachment'
import { ProjectDetail } from './project'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

export interface ResponseOfGetTree {
  data: MyTaskDetail[]
}

export interface RequestOfUpdateBatch {
  myTasks: MyTaskBatchInput[]
  watchers: WatchersInput[]
}

export interface ResponseOfUpdateBatch {
  edited: {
    uuid: string
    lockVersion: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
}

export interface MyTaskDetail extends Tree<MyTaskDetail> {
  project: ProjectDetail
  parentDeliverable: WbsItemBasic
  wbsItem: WbsItemDetail
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  projectPlanPath?: string
  parentWbsItem?: WbsItemBasic
  typeIndex?: number
}

export interface MyTaskBatchInput {
  uuid: string
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: WbsItemDeltaInput
}

export function getAll(userUuid: string): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/my_tasks', {
    userUuid,
  })
}

export function getByUuid(uuid: string): Promise<APIResponse> {
  return API.functional.request('GET', `/api/v1/my_tasks/find`, {
    uuid: uuid,
  })
}
export function updateBatch(
  request: RequestOfUpdateBatch
): Promise<APIResponse> {
  return API.functional.request('POST', '/api/v1/my_tasks/delta/batch', request)
}
