import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSSProperties, PropsWithChildren, useEffect } from 'react'
import { IDndDraggable } from '../DndSortableMarker'
import styled from 'styled-components'

export type Props = PropsWithChildren<{
  id: string
  cards: IDndDraggable[]
  style?: CSSProperties
}>

const DroppableWrapper = ({ id, cards, style, children }: Props) => {
  const { setNodeRef, node } = useDroppable({ id })
  useEffect(() => {
    if (node.current) {
      node.current.scrollTop = 0
    }
  }, [])

  return (
    <DroppableArea ref={setNodeRef} style={style}>
      <SortableContext
        id={id}
        items={cards}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DroppableArea>
  )
}

export default DroppableWrapper

const DroppableArea = styled('div')({
  width: '100%',
  height: 'fit-content',
})
