import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Paper,
  Popper,
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { ICellEditorParams } from 'ag-grid-community'
import _ from 'lodash'
import { gridRootStyle } from '../../../commons/AgGrid'
import { SlackChannel } from '../../../../../domain/value-object/Slack/ChannelVO'

export const SlackChannelCellEditor = forwardRef(
  (props: ICellEditorParams, ref) => {
    const { context, api, rowIndex, column } = props
    const options = context.slackChannels || []

    const inputRef = React.createRef<HTMLInputElement>()

    const [value, setValue] = useState<SlackChannel | undefined>(props.value)
    const [open, setOpen] = useState<boolean>(false)

    // debounce with 100ms
    const search = useMemo(() => _.debounce(() => setOpen(true), 100), [])

    const valueChanged = useCallback(
      (event: React.ChangeEvent<{}>, option: SlackChannel | null) => {
        setValue(option || undefined)
        setOpen(false)
      },
      []
    )

    const initializedValue = useRef(false)
    useEffect(() => {
      if (!initializedValue.current) {
        initializedValue.current = true
        return
      }
      api.stopEditing()
      api.setFocusedCell(rowIndex, column)
    }, [api, rowIndex, column, value])

    const onClose = useCallback((e, reason) => {
      if (['blur', 'toggleInput'].includes(reason)) {
        return
      }
      let cancel = false
      if (reason === 'escape') {
        cancel = true
      }
      setOpen(false)
      props.api.stopEditing(cancel)
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
      }
    })

    return (
      <Box sx={gridRootStyle}>
        <Autocomplete
          options={options}
          getOptionLabel={option =>
            option.slackChannelName ? option.slackChannelName : ''
          }
          isOptionEqualToValue={(value, option) => {
            return value?.slackChannelId === option?.slackChannelId
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <InputBase
              inputRef={inputRef}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus={true}
            />
          )}
          PopperComponent={props => (
            <Popper {...props} placement="bottom-start" />
          )}
          autoSelect={false}
          blurOnSelect={true}
          open={open}
          noOptionsText={''}
          value={value}
          onFocus={search}
          onChange={valueChanged}
          onClose={onClose}
          disableCloseOnSelect={true}
        />
      </Box>
    )
  }
)
