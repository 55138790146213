import React from 'react'
import { styled } from '@mui/system'
import ColumnFilterButton from './ColumnFilterButton'

const RootContainer = styled('div')({
  minWidth: 'fit-content',
})

export const ColumnFilterButtonGroup = (props: {
  options: { label: string; value: any; icon?: JSX.Element }[]
  onChange: (value: any) => void
  disabled?: boolean
}) => {
  const { options, onChange } = props
  return (
    <RootContainer>
      {options.map((u, i) => (
        <ColumnFilterButton
          key={i}
          title={u.label}
          onClick={() => onChange(u.value)}
        />
      ))}
    </RootContainer>
  )
}
