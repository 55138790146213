import { ValueSetterParams } from 'ag-grid-community'
import { CustomEnumCombinationDirection } from '../../../../../../../../lib/commons/appFunction'
import { filterByCombination } from '../../../../../../../../lib/commons/customEnum'
import objects from '../../../../../../../../utils/objects'

export default (params: ValueSetterParams) => {
  if (!params.api || params.oldValue === params.newValue) {
    return false
  }

  let refreshColumns: string[] = []
  const valuesAllowed = params.colDef.cellEditorParams.uiMeta.valuesAllowed
  const getValueAtPath = (path: string) => objects.getValue(params.data, path)
  const selectedValue = filterByCombination(valuesAllowed, getValueAtPath).find(
    enumValue =>
      enumValue.value === params.newValue || enumValue.name === params.newValue
  )
  if (!selectedValue) {
    return false
  }
  objects.setValue(params.data, params.colDef.field || '', params.newValue)
  if (!selectedValue.combinations) {
    return true
  }
  selectedValue.combinations
    .filter(
      combination =>
        combination.direction === CustomEnumCombinationDirection.BIDIRECTION
    )
    .forEach(combination => {
      const combinedEnumPath = combination.combinedEnumPath
      if (!combinedEnumPath || !combination.combinedValues) {
        return
      }
      const combinedColumn = params.columnApi?.getColumn(combinedEnumPath)
      const filteredOptions = filterByCombination(
        combinedColumn?.getColDef().cellEditorParams.uiMeta.valuesAllowed,
        getValueAtPath
      )
      const options = combination.combinedValues.filter(val =>
        filteredOptions.some(option => option.value === val.value)
      )
      if (options.length === 0) {
        return
      }
      refreshColumns.push(combinedEnumPath)
      objects.setValue(params.data, combinedEnumPath, options[0].value)
    })
  params.node &&
    params.api.refreshCells({
      rowNodes: [params.node],
      columns: refreshColumns,
      force: true,
    })
  return true
}
