import React from 'react'
import {
  Autocomplete,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Chip,
  TextField,
} from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import TagVO from '../../../../../../vo/TagVO'
import {
  TagForWbsItem,
  getTextColor,
} from '../../../../../../lib/functions/tag'
import { TagCellEditorRenderOption } from '../../../../BulkSheetView/components/cellEditor/TagCellEditor'
import { connect } from 'react-redux'
import { AllState } from '../../../../../../store'
import { fetchTagsByProject } from '../../../../../../store/tag'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})

interface TagCellProps extends CellPropsBase<TagVO> {
  options: TagForWbsItem[]
  pageProjectUuid: string | undefined
  fetchTags: (uuid: string) => void
}

interface TagCellState extends CellStateBase {}

class TagCell extends DataCellBase<TagVO, TagCellProps, TagCellState> {
  constructor(props) {
    super(props)
    if (props.pageProjectUuid && props.options.length === 0) {
      props.fetchTags(props.pageProjectUuid)
    }
  }
  private ref = React.createRef<HTMLInputElement>()

  validate = (value: TagVO) => {
    return undefined
  }

  onChange = (event: any, value: any) => {
    this.props.onChange(new TagVO(value))
  }

  render() {
    const { value, data, cellDef, colSpan } = this.props
    return (
      <RootDiv>
        <Autocomplete
          multiple={true}
          size={'small'}
          options={this.props.options}
          getOptionLabel={option => (option.name ? option.name : '')}
          renderOption={TagCellEditorRenderOption}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant={'standard'}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              ref={this.ref}
            />
          )}
          isOptionEqualToValue={(value, option) => {
            return value.uuid === option.uuid
          }}
          forcePopupIcon={true}
          autoSelect={false}
          blurOnSelect={false}
          value={value ? value.getValue() : []}
          onChange={this.onChange}
          disableCloseOnSelect={true}
          renderTags={(
            value: TagForWbsItem[],
            getTagProps: AutocompleteRenderGetTagProps,
            ownerState: AutocompleteOwnerState<
              TagForWbsItem,
              true,
              undefined,
              undefined,
              'div'
            >
          ) =>
            value.map((v, index) => (
              <Chip
                size={'small'}
                label={v.name}
                sx={{
                  backgroundColor: v.backgroundColor,
                  color: getTextColor(v.backgroundColor),
                }}
                {...getTagProps({ index })}
                key={getTagProps({ index }).key}
              />
            ))
          }
        />
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState, ownProps) => ({
  pageProjectUuid: ownProps.data.projectUuid || state.project.selected,
  options:
    state.tag[ownProps.data.projectUuid ?? state.project.selected ?? ''] ?? [],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTags: (uuid: string) => dispatch(fetchTagsByProject(uuid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TagCell)
