import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  Box,
  Checkbox,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { ICellEditorParams } from 'ag-grid-community'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import _ from 'lodash'
import { gridRootStyle } from '../../../commons/AgGrid'
import SaveButton from '../../../../components/buttons/SaveButton'
import CancelButton from '../../../../components/buttons/CancelButton'

interface AutocompleteOption {
  uuid: string
  name: string
  iconUrl: string
}

interface Props extends ICellEditorParams {
  search: (text: string, projectUuid: string) => Promise<any>
  label: (option: AutocompleteOption) => string
}

export const MultiAutocompleteCellEditor = forwardRef((props: Props, ref) => {
  const inputRef = React.createRef<HTMLInputElement>()

  const [value, setValue] = useState<AutocompleteOption[]>(props.value)
  const [text, setText] = useState<string>(props.charPress ?? '')
  const [suggestions, setSuggestions] = useState<AutocompleteOption[]>([])
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const search = useMemo(
    () =>
      _.debounce(async (value: string) => {
        const options = await props.search(
          value,
          props.data?.body?.wbsItem?.projectUuid
        )
        setOpen(options.length > 0)
        options.sort((a, b) => {
          return (a?.name ?? '').localeCompare(b?.name ?? '')
        })
        setSuggestions(options)
      }, 300),
    []
  )

  const focused = () => {
    search(text ?? '')
  }

  const inputChanged = useCallback(
    (
      event: React.ChangeEvent<{}>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (reason === 'input') {
        setText(value)
        search(value)
      }
    },
    []
  )

  const valueChanged = useCallback(
    (
      event: React.ChangeEvent<{}>,
      option: AutocompleteOption[] | null,
      reason: AutocompleteChangeReason
    ) => {
      setValue(option || [])
      setText('')
    },
    []
  )

  const onSubmit = useCallback(() => {
    setOpen(false)
    props.api.stopEditing()
  }, [])

  const onCancel = useCallback(() => {
    setOpen(false)
    props.api.stopEditing(true)
  }, [])

  const onClose = useCallback((e, reason) => {
    if (['blur', 'toggleInput'].includes(reason)) {
      return
    }
    let cancel = false
    if (reason === 'escape') {
      cancel = true
    }
    setOpen(false)
    props.api.stopEditing(cancel)
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value
      },
    }
  })

  const getLabel = props.label

  return (
    <Box sx={gridRootStyle}>
      <Autocomplete
        multiple={true}
        options={suggestions}
        getOptionLabel={option => (option.name ? option.name : '')}
        renderOption={(props, option: AutocompleteOption, { selected }) => {
          return (
            <MenuItem
              {...props}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                '& .MuiCheckbox-root': { marginRight: '0px !important' },
                '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
              }}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
                color={'primary'}
              />
              <Avatar
                variant="circular"
                sx={{
                  marginRight: '5px',
                  height: '23px',
                  width: '23px',
                  borderRadius: '50%',
                }}
                src={option.iconUrl}
              />
              <Box sx={{ whiteSpace: 'nowrap' }}>{getLabel(option)}</Box>
            </MenuItem>
          )
        }}
        isOptionEqualToValue={(value, option) => {
          return value.uuid === option.uuid
        }}
        filterOptions={(options: AutocompleteOption[]) => {
          return options
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <InputBase
            inputRef={inputRef}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus={true}
          />
        )}
        PopperComponent={props => (
          <Popper {...props} placement="bottom-start" />
        )}
        PaperComponent={props => (
          <Paper
            {...props}
            sx={{
              '& .MuiAutocomplete-option': { paddingLeft: '1px' },
            }}
          >
            {props.children}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '36px',
              }}
            >
              <SaveButton onClick={onSubmit} />
              <CancelButton onClick={onCancel} />
            </Box>
          </Paper>
        )}
        autoSelect={false}
        blurOnSelect={false}
        open={open}
        noOptionsText={''}
        value={value}
        onFocus={focused}
        onInputChange={inputChanged}
        onChange={valueChanged}
        onClose={onClose}
        disableCloseOnSelect={true}
      />
    </Box>
  )
})
