import { Button, ButtonGroup, ButtonGroupProps, styled } from '@mui/material'
import {
  YearMonthVO,
  yearMonthService,
} from '../../../../domain/value-object/YearMonthVO'
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBeforeRounded'
import { colorPalette } from '../../../style/colorPallete'
import { useCallback } from 'react'

type YearMonthSelectProps = {
  value: YearMonthVO
  onChange: (_: YearMonthVO) => void
}

const YearMonthSelectButtonGroup = styled((props: ButtonGroupProps) => (
  <ButtonGroup variant="outlined" {...props} />
))({
  boxShadow: 'none',
  '& .MuiButtonGroup-grouped': {
    backgroundColor: colorPalette.monotone[0],
    color: colorPalette.monotone[5],
    borderColor: colorPalette.monotone[2],
    '&:not(:first-of-type)': {
      borderLeftColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: colorPalette.monotone[0],
      borderColor: colorPalette.monotone[2],
      '&:not(:first-of-type)': {
        borderLeftColor: 'transparent',
      },
    },
  },
})

const YearMonthSelectYearMonthButton = styled(Button)({
  width: '152px',
})

const YearMonthSelectMoveButton = styled(Button)({
  width: '28px',
})
const iconStyle = { height: '16px', width: '16px' }
const YearMonthSelectMoveNextIcon = styled(NavigateNextIcon)(iconStyle)
const YearMonthSelectMoveBeforeIcon = styled(NavigateBeforeIcon)(iconStyle)

export const YearMonthSelect = ({ value, onChange }: YearMonthSelectProps) => {
  const moveNext = useCallback(() => {
    const nextValue = yearMonthService.add(value, 1)
    onChange(nextValue)
  }, [value, onChange])
  const moveBefore = useCallback(() => {
    const nextValue = yearMonthService.add(value, -1)
    onChange(nextValue)
  }, [value, onChange])
  return (
    <YearMonthSelectButtonGroup>
      <YearMonthSelectMoveButton onClick={moveBefore}>
        <YearMonthSelectMoveBeforeIcon />
      </YearMonthSelectMoveButton>
      <YearMonthSelectYearMonthButton>{`${value.year} / ${value.month}`}</YearMonthSelectYearMonthButton>
      <YearMonthSelectMoveButton onClick={moveNext}>
        <YearMonthSelectMoveNextIcon />
      </YearMonthSelectMoveButton>
    </YearMonthSelectButtonGroup>
  )
}
function useCalllback(arg0: () => void, arg1: YearMonthVO[]) {
  throw new Error('Function not implemented.')
}
