import { useCallback, useEffect, useMemo, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import {
  getTicketTypeFromQueryParam,
  TicketBasic,
  TicketType,
} from '../../../../lib/functions/ticket'
import TicketListApi from '../../../../lib/functions/ticketList'

type StoredSearchConditionState = {
  ticketType?: TicketType
  lastTicketListUuidMap: { [key: string]: string | undefined }
}

export const useSearchConditionState = (
  functionUuid: string,
  projectUuid: string
) => {
  const [initialized, setInitialized] = useState(false)
  const [ticketType, setTicketType] = useState<TicketType>()
  const [ticketListUuid, setTicketListUuid] = useState<string>()
  const [lastTicketListUuidMap, setLastTicketListUuidMap] = useState<{
    [key: string]: string | undefined
  }>({})

  const uiStateKey = useMemo(
    () => `${UiStateKey.BulkSheetSearchCondisionState}-${projectUuid}`,
    [projectUuid]
  )

  const queryParams = getTicketTypeFromQueryParam()

  const getLastSelectedTicketListUuid = useCallback(
    (
      targetType: TicketType,
      targetMap: { [key: string]: string | undefined }
    ) => {
      return targetType && targetMap && targetType in targetMap
        ? targetMap[targetType]
        : undefined
    },
    []
  )

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: uiStateKey,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      const payload: StoredSearchConditionState | undefined = stored.value
        ? JSON.parse(stored.value)
        : undefined

      if (queryParams?.ticketListUuid) {
        const ticketList: TicketBasic = await TicketListApi.getByUuid(
          queryParams?.ticketListUuid
        )
        setTicketType(ticketList.ticketType as TicketType)
        setTicketListUuid(ticketList.uuid)

        const ticketListMap: { [key: string]: string | undefined } =
          payload?.lastTicketListUuidMap ?? {}

        ticketListMap[ticketList.ticketType] = ticketList.uuid
        setLastTicketListUuidMap(ticketListMap)
        return
      }
      const resolvedTicketType = queryParams?.ticketType || payload?.ticketType
      resolvedTicketType && setTicketType(resolvedTicketType)
      payload?.lastTicketListUuidMap &&
        setLastTicketListUuidMap(payload?.lastTicketListUuidMap)

      if (resolvedTicketType && payload?.lastTicketListUuidMap) {
        setTicketListUuid(
          getLastSelectedTicketListUuid(
            resolvedTicketType,
            payload.lastTicketListUuidMap
          )
        )
      }
    } finally {
      setInitialized(true)
    }
  }, [
    functionUuid,
    uiStateKey,
    getLastSelectedTicketListUuid,
    queryParams?.ticketType,
    queryParams?.ticketListUuid,
  ])

  const rememberPageState = useCallback(
    (value: StoredSearchConditionState) => {
      uiStates.update(
        {
          key: uiStateKey,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid, uiStateKey]
  )

  useEffect(() => {
    restorePageState()
  }, [restorePageState])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      ticketType,
      lastTicketListUuidMap,
    })
  }, [initialized, ticketType, lastTicketListUuidMap, rememberPageState])

  return {
    initialized,
    ticketType,
    setTicketType,
    lastTicketListUuidMap,
    setLastTicketListUuidMap,
    ticketListUuid,
    setTicketListUuid,
    getLastSelectedTicketListUuid,
  }
}
