import _ from 'lodash'
import { RouteComponentProps, withRouter } from 'react-router'
import { PageArea, PageProps } from '..'
import { BulkSheetView } from '../../containers/BulkSheetView'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowDragEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { ledgerAccountsGridOptions } from './gridOptions'
import LedgerAccountsHeader from './components/Header'
import Loading from '../../components/process-state-notifications/Loading'
import { useLedgerAccountsData } from './hooks/ledgerAccountsData'
import {
  LedgerAccountsRow,
  LedgerAccountsType,
  acceptChild,
} from './ledgerAccounts'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import {
  addGeneralLedgerAccountsRowMenuItem,
  addGeneralLedgerAccountsRowsMenuItem,
  addMultipleRows,
  addSubsidiaryAccountsRowMenuItem,
  addSubsidiaryAccountsRowsMenuItem,
  deleteRowMenuItems,
} from './gridOptions/contextMenu'
import AddRowCountInputDialog from '../../containers/BulkSheet/AddRowCountInputDialog'
import { useDialog } from './hooks/dialogState'
import { intl } from '../../../i18n'
import {
  collapseAllRows,
  expandAllRows,
  groupRowMenuItems,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import store from '../../../store'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import {
  getAllLeafChildren,
  getRowNumber,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import {
  InputError,
  addInputError,
} from '../../containers/BulkSheetView/lib/validation'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { removeRows } from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import validator from '../../containers/meta/validator'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { UiStateKey } from '../../../lib/commons/uiStates'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE, KEY_SPACE } from '../../model/keyBind'
import { useDragTreeStyle } from '../../containers/BulkSheetView/hooks/gridEvents/treeRowDrag'
import { sortByRowIndex } from '../../containers/BulkSheetView/lib/rowNode'
import {
  slideRowsAfter,
  slideRowsBefore,
} from '../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import {
  getParentUuid,
  getSiblingUuids,
  getSiblings,
} from '../../containers/BulkSheetView/lib/tree'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'

type PathProps = {
  code?: string
  term?: string
}

type Props = PageProps & RouteComponentProps<PathProps>

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

const LedgerAccounts = (props: Props) => {
  const functionUuid = props.uuid
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )
  const dialogState = useDialog()
  const columnSetting = useColumnSetting()
  const gridOptions = useMemo(() => ledgerAccountsGridOptions(), [])

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      errors: new InputError(),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, setData, refresh, save, deleteRows } = useLedgerAccountsData()
  const submit = useRef<Function>()
  submit.current = save
  const reload = useRef<Function>()
  reload.current = refresh
  const prevData = useRef<LedgerAccountsRow[]>([])
  prevData.current = data

  const bulkSheetState = useBulkSheetState(
    functionUuid,
    UiStateKey.LedgerAccounts
  )

  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(d => d.added || d.edited)
  }, [data])

  const restoreExpandedRows = useCallback(() => {
    if (_.isEmpty(bulkSheetState.expandedRowIds) || !gridOptions.api) return
    setTimeout(() => {
      bulkSheetState.expandedRowIds.forEach(id => {
        gridOptions.api?.getRowNode(id)?.setExpanded(true)
      })
    }, 200)
  }, [bulkSheetState.expandedRowIds, gridOptions.api])

  const refreshAll = useCallback(async () => {
    setLoading(true)
    try {
      await (reload.current ?? refresh)()
      restoreExpandedRows()
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        draggableNodeId: undefined,
        errors: new InputError(),
      }
      setLoading(false)
    }
  }, [refresh, restoreExpandedRows, gridOptions])

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        addGeneralLedgerAccountsRowMenuItem(params, prevData.current, setData),
        addGeneralLedgerAccountsRowsMenuItem(
          params,
          dialogState.openAddMultipleRows
        ),
        addSubsidiaryAccountsRowMenuItem(params, prevData.current, setData),
        addSubsidiaryAccountsRowsMenuItem(
          params,
          dialogState.openAddMultipleRows
        ),
        deleteRowMenuItems(params, dialogState.openDeleteConfirm),
        ...groupRowMenuItems(params),
        expandAllRows(params),
        collapseAllRows(params),
      ].filter(m => !!m) as (string | MenuItemDef)[]
    },
    [setData, dialogState.openAddMultipleRows, dialogState.openDeleteConfirm]
  )

  const validateBeforeSubmit = useCallback(() => {
    if (
      !gridOptions.api ||
      !gridOptions.columnApi ||
      !gridOptions.context.errors
    ) {
      return
    }

    const colIds = gridOptions.columnApi
      .getColumnState()
      .map(colState => colState.colId)

    data
      .filter(row => row.added || row.edited)
      .forEach(row => {
        const node = gridOptions.api!.getRowNode(row.uuid)
        if (!node) return

        colIds.forEach(colId => {
          const val = gridOptions.api!.getValue(colId, node)
          const colDef = gridOptions.columnApi!.getColumn(colId)?.getColDef()
          if (!colDef || !colDef.cellRendererParams) return
          const uiMeta = colDef.cellRendererParams.uiMeta
          const err = uiMeta
            ? validator
                .validate(val, row, uiMeta, () => undefined)
                ?.getMessage()
            : undefined
          if (err) {
            addInputError(gridOptions.context.errors, node.id, err, colDef)
          }
        })
      })
  }, [data, gridOptions.api, gridOptions.columnApi, gridOptions.context])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()
      validateBeforeSubmit()
      if (gridOptions.context?.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }

      const response = submit.current ? await submit.current() : await save()

      if (response.hasWarning) {
        const messages = extractValuesFromResponse(response.json, 'messages')
        handleWarning(messages, uuid => {
          const target = data.find(v => v.body?.uuid === uuid)
          return target?.body?.displayName
        })
      }

      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    gridOptions.api,
    gridOptions.context.errors,
    validateBeforeSubmit,
    save,
    data,
    functionUuid,
    refreshAll,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialogState.openCancelConfirm()
    } else {
      refreshAll()
    }
  }, [hasChanged, dialogState, refreshAll])

  const onCancelConfirm = useCallback(() => {
    dialogState.closeCancelConfirm()
    refreshAll()
  }, [dialogState, refreshAll])

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<LedgerAccountsRow>) =>
      e.api?.refreshCells({ columns: ['rowNumber'], force: true }),
    []
  )

  const onDeleteRows = useCallback(() => {
    const targetRows = getAllLeafChildren(
      getSelectedNode(gridOptions.api!)
    ).map(node => node.data) as LedgerAccountsRow[]
    const newData = removeRows(data, targetRows)
    setData(newData)
    deleteRows(targetRows)
    dialogState.closeDeleteConfirm()
  }, [gridOptions.api, data, setData, deleteRows, dialogState])

  // restore row state
  const restoreColumnState = useCallback(() => {
    if (!gridOptions.columnApi || _.isEmpty(bulkSheetState.columnState)) return
    gridOptions.columnApi.applyColumnState({
      state: bulkSheetState.columnState,
      applyOrder: true,
    })
  }, [gridOptions.columnApi, bulkSheetState.columnState])

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [gridOptions.columnApi, bulkSheetState])

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        bulkSheetState.expandRows([key])
      } else {
        bulkSheetState.collapseRows([key])
      }
    },
    [bulkSheetState]
  )

  const onGridReady = useCallback(() => {
    restoreColumnState()
  }, [restoreColumnState])

  const onFirstDataRendered = useCallback(
    e => {
      restoreExpandedRows()
      if (!_.isEmpty(bulkSheetState.filterState)) {
        gridOptions.api?.setFilterModel(bulkSheetState.filterState)
      }
      bulkSheetState.finishRestoring()
      setLoading(false)
    },
    [gridOptions.api, bulkSheetState, restoreExpandedRows]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)

      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedState[state.colId] = state
          }
        })
      const sortedList: SortedColumnState[] = sortedColumns.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedState[col.colId]?.sort : null,
        }
      })
      setSortColumnsState(sortedList)
    },
    [gridOptions.columnApi, rememberColumnState]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        dialogState.openUiState()
      }
    },
    [gridOptions.api, gridOptions.columnApi, dialogState]
  )

  // Filters
  const onDeleteFilteredColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const model = gridOptions.api.getFilterModel()
      delete model[column.colId || column.field || '']
      gridOptions.api.setFilterModel(model)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [gridOptions.api])

  // Sorts
  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions.columnApi]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions.columnApi])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions.columnApi]
  )

  // key bind
  const onSpaceKeyDown = useCallback(() => {
    const { api } = gridOptions
    const focusedCell = api?.getFocusedCell()
    const focusedNode = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex ?? -1)
    if (
      !focusedCell ||
      !focusedNode ||
      !document.activeElement!.classList.contains('ag-cell')
    ) {
      return
    }
    focusedNode.setExpanded(!focusedNode.expanded)
  }, [gridOptions])

  useKeyBind([
    { key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true },
    { key: KEY_SPACE, fn: onSpaceKeyDown },
  ])

  // drag row
  const dragTreeStyle = useDragTreeStyle(ref, acceptChild, gridOptions)

  const onRowDragEnd = useCallback(
    (event: RowDragEvent) => {
      const overNode = event.overNode
      if (!overNode || overNode.id !== gridOptions.context.draggableNodeId) {
        return
      }
      try {
        const ids = event.nodes.map(v => v.id)
        const topLevelNodes = event.nodes.filter(
          n => !ids.includes(n.parent?.id)
        )
        const movedData = sortByRowIndex(topLevelNodes).map(node => node.data)
        const parentUuid = getParentUuid(overNode.data)

        const slideRowAndUpdateDispOrder = (
          startIndex: number,
          endIndex: number,
          slideRow: (
            prevData: LedgerAccountsRow[],
            rows: LedgerAccountsRow[],
            prevSiblingUuid: string
          ) => LedgerAccountsRow[]
        ) => {
          const newRow = slideRow(data, movedData, overNode.id!)
          const newSiblings = getSiblings(newRow, parentUuid)
          const startDispOrder =
            startIndex === 0
              ? 0
              : newSiblings.at(startIndex - 1)?.body.displayOrder || 0
          newSiblings.slice(startIndex, endIndex + 1).forEach((row, index) => {
            row.edited = true
            row.body.displayOrder = startDispOrder + index + 1
          })
          setData(newRow)
        }

        const beforeSiblingUuids = getSiblingUuids(data, parentUuid)
        if (event.overIndex > (event.node.rowIndex || 0)) {
          slideRowAndUpdateDispOrder(
            beforeSiblingUuids.indexOf(movedData[0].uuid),
            beforeSiblingUuids.indexOf(overNode.data.uuid),
            slideRowsAfter
          )
        } else {
          slideRowAndUpdateDispOrder(
            beforeSiblingUuids.indexOf(overNode.data.uuid),
            beforeSiblingUuids.indexOf(movedData[movedData.length - 1].uuid),
            slideRowsBefore
          )
        }
      } finally {
        dragTreeStyle.refreshDragStyle()
      }
    },
    [gridOptions.context, data, setData, dragTreeStyle]
  )

  if (!bulkSheetState.initialized) {
    return <></>
  }

  return (
    <PageArea>
      <LedgerAccountsHeader
        loading={loading}
        onSubmit={onSubmit}
        onReload={onReload}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        sortColumnState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        columnSettingOpen={columnSetting.isOpen}
        onClickColumnSettingButton={columnSetting.toggle}
      />
      <BulkSheetView
        getContextMenuItems={contextMenu}
        gridOptions={gridOptions}
        onColumnMoved={rememberColumnState}
        onColumnResized={rememberColumnState}
        onColumnVisible={rememberColumnState}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onRowDataUpdated={onRowDataUpdated}
        onRowDragEnter={dragTreeStyle.onRowDragEnter}
        onRowDragMove={dragTreeStyle.onRowDragMove}
        onRowDragEnd={onRowDragEnd}
        onRowDragLeave={dragTreeStyle.refreshDragStyle}
        onRowGroupOpened={onRowGroupOpened}
        onSortChanged={onSortChanged}
        ref={ref}
        rowData={data}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <CancelConfirmDialog
        open={dialogState.cancelConfirm}
        onConfirm={onCancelConfirm}
        onClose={() => dialogState.closeCancelConfirm()}
      />
      <AddRowCountInputDialog
        open={dialogState.addMultipleRowsState.open}
        title={intl.formatMessage({
          id:
            LedgerAccountsType.GeneralLedgerAccounts ===
            dialogState.addMultipleRowsState.type
              ? 'generalLedgerAccounts.insert.multipleRow.title'
              : 'subsidiaryAccounts.insert.multipleRow.title',
        })}
        submitHandler={(addRowCount: number | undefined) => {
          addMultipleRows(
            addRowCount,
            dialogState.addMultipleRowsState,
            dialogState.closeAddMultipleRow,
            prevData.current,
            setData,
            gridOptions.api
          )
        }}
        closeHandler={dialogState.closeAddMultipleRow}
      />
      {dialogState.deleteConfirm && gridOptions.api && (
        <DeleteRowConfirmationDialog
          target={getAllLeafChildren(getSelectedNode(gridOptions.api)).map(
            v =>
              v.data.body?.displayName ??
              `(${intl.formatMessage({ id: 'displayName.undefined' })})`
          )}
          onConfirm={onDeleteRows}
          onClose={dialogState.closeDeleteConfirm}
        />
      )}
      <SavedUIStateDialog
        applicationFunctionUuid={functionUuid}
        open={dialogState.uiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.columnState,
          filterState: bulkSheetState.filterState,
        }}
        onSelect={(uiState: SavedUIState) => {
          const { columnState, filterState } = uiState.UIState
          if (columnState) {
            bulkSheetState.saveColumnState(columnState)
            gridOptions.columnApi?.applyColumnState({
              state: columnState,
              applyOrder: true,
            })
          }
          if (filterState) {
            bulkSheetState.saveFilterState(filterState)
            gridOptions.api?.setFilterModel(filterState)
          }
          dialogState.closeUiState()
        }}
        onClose={dialogState.closeUiState}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={dialogState.openUiState}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={functionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.columnState}
        offset={90}
      />
    </PageArea>
  )
}

export default withRouter(LedgerAccounts)
