import _ from 'lodash'
import { intl } from '../../../i18n'
import api, { APIResponse } from '../../../lib/commons/api'
import { ResourcePlanType } from '../../../lib/functions/resourcePlanNew'
import { TeamProps } from '../../../lib/functions/team'
import { UserBasic, UserDetail } from '../../../lib/functions/user'
import { generateUuid } from '../../../utils/uuids'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { removeRows } from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'

export enum RowGroupColumnType {
  MEMBER = ResourcePlanType.MEMBER,
  TEAM = ResourcePlanType.TEAM,
}

/**
 * API response
 */
export interface MemberWorkMonthSkeleton {
  month: Date
  manMonth: number
}

export class ResourcePlanSkeleton implements TreeSource<ResourcePlanSkeleton> {
  uuid: string
  type: ResourcePlanType
  user: UserDetail
  team: TeamProps
  projectReleasedDate: string
  memberWorkMonths: MemberWorkMonthSkeleton[]
  children: ResourcePlanSkeleton[]

  parentUuid: string
  lockVersion: number
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

/**
 * RowData definition
 */
export interface MemberWorkMonthCell {
  yearMonth?: string
  manMonth?: number
  userManMonth?: number
}

export interface ResourcePlanRowBody {
  user?: UserDetail
  team?: TeamProps
  projectReleasedDate?: string
  memberWorkMonths?: MemberWorkMonthCell[]
}

export interface ResourcePlanRow extends TreeRow {
  isTotal?: boolean
  type?: ResourcePlanType
  body: ResourcePlanRowBody
}

export interface GetResourcePlanProps {
  projectUuid: string
  startDate: string
  endDate: string
}

export const getResourcePlan = async (
  request: GetResourcePlanProps
): Promise<ResourcePlanSkeleton> => {
  const response: APIResponse = await api.functional.request(
    'GET',
    '/api/v1/projects/resource_plans',
    request
  )
  return response.json as Promise<ResourcePlanSkeleton>
}

/**
 * common function (crud rows)
 */
export const createNewResourcePlanNewRow = ({
  type,
  user,
  team,
  parentUuid,
}: {
  type: ResourcePlanType
  user?: UserDetail
  team?: TeamProps
  parentUuid?: string
}): ResourcePlanRow => {
  const uuid: string = generateUuid()
  return {
    uuid: uuid,
    type,
    body: {
      user,
      team,
    },
    treeValue: parentUuid ? [parentUuid, uuid] : [uuid],
  }
}

export const createLabelUser = (labelId: string): UserDetail => {
  const labelDisplayName = intl.formatMessage({
    id: labelId,
  })
  const labelUser = {
    uuid: generateUuid(),
    name: labelDisplayName,
  } as UserDetail
  return labelUser
}

export const createLabelTeam = (labelId: string): TeamProps => {
  const labelDisplayName = intl.formatMessage({
    id: labelId,
  })
  const labelTeam: TeamProps = {
    uuid: generateUuid(),
    iconUrl: '',
    projectUuid: '',
    displayName: labelDisplayName,
    officialName: labelDisplayName,
  }
  return labelTeam
}

const addRow = (
  sourceRows: ResourcePlanRow[],
  addRow: ResourcePlanRow,
  addToUuid: string | undefined,
  firstChild?: boolean
): ResourcePlanRow[] => {
  const addToIndex: number = sourceRows.findIndex(row => row.uuid === addToUuid)
  const numOfChildren: number = firstChild
    ? 0
    : 0 <= addToIndex
    ? sourceRows.filter(
        row =>
          row.uuid !== sourceRows[addToIndex].uuid &&
          row.treeValue[0] === sourceRows[addToIndex].uuid
      ).length
    : 0
  const insertIndex = addToIndex + numOfChildren + 1

  const newRows = sourceRows.concat()
  newRows.splice(insertIndex, 0, addRow)
  return newRows
}

const getAddToUuid = (
  addingUuid: string,
  addedRows: ResourcePlanRow[],
  addedUuids: string[] | undefined,
  order: string[],
  getUuidFromRow: (row: ResourcePlanRow) => string | undefined
): string | undefined => {
  const uuids: string[] =
    addedUuids ??
    (addedRows
      .map((row: ResourcePlanRow) => getUuidFromRow(row))
      .filter(uuid => !!uuid) as string[])
  const targetIndex = order.findIndex(uuid => uuid === addingUuid)
  if (!_.isEmpty(uuids) && targetIndex < 0) {
    const addToRow = addedRows.find(
      row => getUuidFromRow(row) === uuids[uuids.length - 1]
    )
    return addToRow?.uuid
  } else {
    for (let i = targetIndex - 1; i >= 0; i--) {
      if (uuids.includes(order[i])) {
        const addToRow = addedRows.find(row => getUuidFromRow(row) === order[i])
        if (addToRow) {
          return addToRow.uuid
        }
      }
    }
  }
}

export const addMemberRow = (
  addMember: UserDetail,
  memberOrder: string[],
  sourceRow: ResourcePlanRow[]
): ResourcePlanRow[] => {
  const addingUuid = addMember.uuid

  const memberList: ResourcePlanRow[] = sourceRow.filter(
    row => row.type === ResourcePlanType.MEMBER
  )
  const addToUuid = getAddToUuid(
    addingUuid,
    memberList,
    undefined,
    memberOrder,
    (row: ResourcePlanRow) => row.body.user?.uuid
  )

  let newRows = sourceRow.concat().filter(row => row.body.user !== undefined)
  const newMemberRow = createNewMemberRow(addMember)
  newMemberRow.added = true
  newRows = addRow(newRows, newMemberRow, addToUuid)

  const newUnsetRow = createNewUnsetRow(newMemberRow.uuid, addMember)
  newUnsetRow.added = true
  newRows = addRow(newRows, newUnsetRow, newMemberRow.uuid)

  return newRows
}

export const addTeamToRowsGroupByMember = (
  parentRow: ResourcePlanRow | undefined,
  addTeam: TeamProps,
  teamOrder: string[],
  sourceRow: ResourcePlanRow[]
): ResourcePlanRow[] => {
  if (!parentRow) return sourceRow

  const siblingTeamRow: ResourcePlanRow[] = sourceRow.filter(
    (row: ResourcePlanRow) =>
      row.treeValue[0] === parentRow.uuid &&
      row.type === ResourcePlanType.TEAM &&
      !!row.body.team
  )

  const addToUuid = getAddToUuid(
    addTeam.uuid,
    siblingTeamRow,
    undefined,
    teamOrder,
    (row: ResourcePlanRow) => row.body.team?.uuid
  )

  const newTeamRow = createNewResourcePlanNewRow({
    type: ResourcePlanType.TEAM,
    user: parentRow.body.user,
    team: addTeam,
    parentUuid: parentRow.uuid,
  })
  newTeamRow.added = true

  return addRow(sourceRow, newTeamRow, addToUuid ?? parentRow.uuid, !addToUuid)
}

export const addTeamToRowsGroupByTeam = (
  userRow: ResourcePlanRow | undefined,
  addTeam: TeamProps,
  teamOrder: string[],
  memberOrder: string[],
  sourceRows: ResourcePlanRow[]
): ResourcePlanRow[] => {
  const addingUuid = addTeam.uuid

  const teamRows: ResourcePlanRow[] = sourceRows.filter(
    row => row.type === ResourcePlanType.TEAM && !!row.body.team?.uuid
  )
  const addedUuids: string[] = teamRows.map(row => row.body.team!.uuid)

  let parentUuid = addedUuids.find(uuid => uuid === addingUuid)
  let rows = sourceRows
  if (!parentUuid) {
    // Add Team
    const addToUuid = getAddToUuid(
      addingUuid,
      teamRows,
      addedUuids,
      teamOrder,
      (row: ResourcePlanRow) => row.body.team?.uuid
    )
    const newTeamRow = createTeamGroupRow(addTeam)
    newTeamRow.added = true
    parentUuid = newTeamRow.uuid
    rows = addRow(rows, newTeamRow, addToUuid, !addToUuid)
  }

  // Add Member
  if (userRow) {
    const newMemberRow = createNewResourcePlanNewRow({
      type: ResourcePlanType.MEMBER,
      user: userRow.body.user,
      team: addTeam,
      parentUuid,
    })
    newMemberRow.added = true

    const siblingMemberRows = rows.filter(
      (row: ResourcePlanRow) =>
        row.treeValue[0] === parentUuid && row.type === ResourcePlanType.MEMBER
    )
    const addToUuid = userRow.body.user
      ? getAddToUuid(
          userRow.body.user.uuid,
          siblingMemberRows,
          undefined,
          memberOrder,
          (row: ResourcePlanRow) => row.body.user?.uuid
        )
      : undefined
    rows = addRow(rows, newMemberRow, addToUuid ?? parentUuid, !addToUuid)
  }
  return rows
}

export const addMemberRowForTeamView = (
  user: UserDetail,
  parentTeam: TeamProps,
  sourceRows: ResourcePlanRow[],
  memberOrder: string[]
) => {
  if (!user) return []
  const newMemberRow = createNewResourcePlanNewRow({
    type: ResourcePlanType.MEMBER,
    user: user,
    team: parentTeam,
    parentUuid: parentTeam.uuid,
  })
  newMemberRow.added = true

  const siblingMemberRows = sourceRows.filter(
    (row: ResourcePlanRow) =>
      row.type === ResourcePlanType.MEMBER &&
      row.treeValue[0] === parentTeam.uuid
  )
  const addToUuid = getAddToUuid(
    user.uuid,
    siblingMemberRows,
    undefined,
    memberOrder,
    (row: ResourcePlanRow) => row.body.user?.uuid
  )
  return addRow(
    sourceRows,
    newMemberRow,
    addToUuid ??
      sourceRows.find(
        (row: ResourcePlanRow) =>
          row.type === ResourcePlanType.TEAM &&
          row.body.team &&
          row.body.team.uuid === parentTeam.uuid
      )?.uuid,
    !addToUuid
  )
}

export const createNewMemberRow = (user?: UserDetail) => {
  return createNewResourcePlanNewRow({
    type: ResourcePlanType.MEMBER,
    user,
    team: createLabelTeam('project.resourcePlan.total'),
  })
}

export const createNewUnsetRow = (parentRowUuid: string, user?: UserDetail) => {
  return createNewResourcePlanNewRow({
    type: ResourcePlanType.UNSET,
    user,
    team: createLabelTeam('project.resourcePlan.none'),
    parentUuid: parentRowUuid,
  })
}

export const createDefaultRows = (): ResourcePlanRow[] => {
  const rows: ResourcePlanRow[] = []
  const memberRow = createNewMemberRow()
  memberRow.added = true
  rows.push(memberRow)
  const unsetRow = createNewUnsetRow(memberRow.uuid)
  unsetRow.added = true
  rows.push(unsetRow)
  return rows
}

export const createTeamGroupRow = (
  team: TeamProps,
  uuid?: string
): ResourcePlanRow => {
  const newUuid = uuid ?? team.uuid
  return {
    uuid: newUuid,
    type: ResourcePlanType.TEAM,
    body: {
      user: createLabelUser('project.resourcePlan.total'),
      team,
    },
    treeValue: [newUuid],
  }
}

export const UNSET_TEAM_GROUP_ROW_UUID: string =
  'fe5c8ef9-b59f-464f-a6cd-a1036f30956c'
export const createUnsetTeamGroupRow = (): ResourcePlanRow => {
  return {
    ...createTeamGroupRow(
      createLabelTeam('project.resourcePlan.none'),
      UNSET_TEAM_GROUP_ROW_UUID
    ),
    type: ResourcePlanType.UNSET,
  }
}

export const deleteRowsForMemberView = (
  allRows: ResourcePlanRow[],
  deleteRows: ResourcePlanRow[]
) => {
  let newData = removeRows(allRows, deleteRows)
  if (_.isEmpty(newData)) {
    newData = createDefaultRows()
  }
  return newData
}

export const deleteRowsForTeamView = (
  allRows: ResourcePlanRow[],
  deleteRows: ResourcePlanRow[]
) => {
  const deleteTeamRows = allRows.filter((teamRow: ResourcePlanRow) => {
    return (
      teamRow.type === ResourcePlanType.TEAM &&
      !allRows.some(
        (childRow: ResourcePlanRow) =>
          1 < childRow.treeValue.length &&
          childRow.treeValue[0] === teamRow.uuid &&
          !deleteRows.some(
            (rows: ResourcePlanRow) => rows.uuid === childRow.uuid
          )
      )
    )
  })

  return removeRows(allRows, [...deleteRows, ...deleteTeamRows])
}

/**
 * common function
 */
interface displayNameProps {
  displayName: string
}

export const displayNameComparator = (
  valueA: string | displayNameProps | undefined,
  valueB: string | displayNameProps | undefined
): number => {
  const strA = typeof valueA === 'string' ? valueA : valueA?.displayName || ''
  const strB = typeof valueB === 'string' ? valueB : valueB?.displayName || ''
  return strA.localeCompare(strB)
}

export const getRowDisplayName = (row: ResourcePlanRow) => {
  switch (row.type) {
    case ResourcePlanType.MEMBER:
      return row.body.user?.name
    case ResourcePlanType.TEAM:
    case ResourcePlanType.UNSET:
      return row.body.team?.displayName
    default:
      return ''
  }
}

export const setUserManMonth = (
  row: ResourcePlanRow,
  columnId: string | undefined,
  newValue: number
) => {
  const rowMemberWorkMont = row.body.memberWorkMonths?.find(
    (rowWorkMonth: MemberWorkMonthCell) => rowWorkMonth.yearMonth === columnId
  )
  if (rowMemberWorkMont) {
    rowMemberWorkMont.userManMonth = newValue
  } else {
    row.body.memberWorkMonths?.push({
      yearMonth: columnId,
      manMonth: 0,
      userManMonth: newValue,
    })
  }
}

export const sortByUuid = (
  a: { uuid: string } | undefined,
  b: { uuid: string } | undefined,
  orderUuids: string[]
) => {
  const aIndex = orderUuids.findIndex((uuid: string) => uuid === a?.uuid)
  const bIndex = orderUuids.findIndex((uuid: string) => uuid === b?.uuid)
  return aIndex - bIndex
}

export const getMemberUuids = (rows: ResourcePlanRow[]): string[] => {
  return rows
    .filter((row: ResourcePlanRow) => row.type === ResourcePlanType.MEMBER)
    .map((row: ResourcePlanRow) => row.body?.user?.uuid)
    .filter(uuid => !!uuid) as string[]
}

export const getTeamUuids = (rows: ResourcePlanRow[]): string[] => {
  return rows
    .filter((row: ResourcePlanRow) => row.type === ResourcePlanType.TEAM)
    .map((row: ResourcePlanRow) => row.body?.team?.uuid)
    .filter(uuid => !!uuid) as string[]
}
