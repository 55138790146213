import { useCallback, useEffect, useState } from 'react'
import { PageProps } from '..'
import { useProjectPrivateContext } from '../../context/projectContext'
import Auth from '../../../lib/commons/auth'
import { intl } from '../../../i18n'
import ProfitLossItemsViewBase from './view/'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { showAlert } from '../../../store/globalAlert'
import { useDispatch } from 'react-redux'

type Props = PageProps

const ProfitLossItems = (props: Props) => {
  const { project } = useProjectPrivateContext()
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  const [certificated, setCertificated] = useState(false)

  const checkAuth = useCallback(async () => {
    if (!project) return
    try {
      const result = await Auth.checkAccountingAuth(
        project.uuid,
        props.externalId
      )
      setCertificated(result)
      if (!result) {
        dispatch(
          showAlert({
            message: intl.formatMessage({
              id: 'global.warning.unauthorized.function',
            }),
          })
        )
      }
    } finally {
      setInitialized(true)
    }
  }, [project, props.externalId])

  useEffect(() => {
    if (!initialized) {
      checkAuth()
    }
  }, [checkAuth, initialized])

  if (!certificated) return <></>
  return <ProfitLossItemsViewBase {...props} currentProject={project} />
}

export default projectPrivate(ProfitLossItems)
