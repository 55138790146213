import { styled } from '@mui/material'
import queryString from 'query-string'
import { useCallback, useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { Button } from '../../../../components/buttons'
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded'

export const BurndownChartButton = () => {
  const { projectCode } = useProjectPrivateContext()
  const onClick = useCallback(() => {
    const queryObject = queryString.parse(window.location.search)
    const rootProjectPlanUuid = queryObject['treeRootUuid']
    if (!rootProjectPlanUuid) return

    window.open(
      `/burndownChart/${projectCode}?rootProjectPlanUuid=${rootProjectPlanUuid}`
    )
  }, [projectCode])
  const label = useMemo(
    () => intl.formatMessage({ id: 'open.burndownChart' }),
    []
  )
  return (
    <Button onClick={onClick} colorPattern="monotone" variant="outlined">
      <Icon />
      {label}
    </Button>
  )
}
const Icon = styled(SsidChartRoundedIcon)({
  color: colorPalette.monotone[4],
})
