import { useCallback, useState } from 'react'
import { LedgerAccountsType } from '../ledgerAccounts'

export interface AddMultipeLedgerAccountsRowState {
  open: boolean
  parentUuid?: string
  type?: LedgerAccountsType
}

export const useDialog = () => {
  const [cancelConfirm, setCancelConfirm] = useState<boolean>(false)
  const [addMultipleRowsState, setAddMultipleRowsState] =
    useState<AddMultipeLedgerAccountsRowState>({ open: false })
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [uiState, setUiState] = useState<boolean>(false)

  const openCancelConfirm = useCallback(() => setCancelConfirm(true), [])
  const closeCancelConfirm = useCallback(() => setCancelConfirm(false), [])

  const openAddMultipleRows = useCallback(
    (parentUuid: string, type: LedgerAccountsType) => {
      setAddMultipleRowsState({
        open: true,
        parentUuid,
        type,
      })
    },
    []
  )
  const closeAddMultipleRow = useCallback(
    () => setAddMultipleRowsState({ open: false }),
    []
  )

  const openDeleteConfirm = useCallback(() => setDeleteConfirm(true), [])
  const closeDeleteConfirm = useCallback(() => setDeleteConfirm(false), [])

  const openUiState = useCallback(() => setUiState(true), [])
  const closeUiState = useCallback(() => setUiState(false), [])

  return {
    cancelConfirm,
    openCancelConfirm,
    closeCancelConfirm,

    addMultipleRowsState,
    openAddMultipleRows,
    closeAddMultipleRow,

    deleteConfirm,
    openDeleteConfirm,
    closeDeleteConfirm,

    uiState,
    openUiState,
    closeUiState,
  }
}
