import { ICellRendererParams } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import { FloatingEditableIcon } from '../../common/text'
import { ProjectPlanCumulation } from '../../../../../../../../lib/functions/projectPlan'
import {
  format,
  formatAggregation,
  formatPercentage,
  FractionLabel,
  IconArea,
  PercentageLabel,
  StatusNameLabel,
} from '../wbsItemStatus/cellRenderer'
import './style.scss'
import { RootDiv } from '../../../../components/cell/CellRendererRoot'
import { DelayIcon } from '../wbsItemStatus/delayIcon'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

export default function CellRenderer(params: ICellRendererParams) {
  const cumulation: ProjectPlanCumulation = params.data
    ? params.data.cumulation
    : undefined
  if (!cumulation || !params.colDef) {
    return <></>
  }

  const wbsItem = objects.getValue(params.data, 'wbsItem')
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    params.data,
    'wbsItem.wbsItemType'
  )
  const thisValue = objects.getValue(params.data, params.colDef.field)

  let done
  let total
  if (wbsItemType?.isTask()) {
    if (cumulation.countTaskOfDirectChildren > 0) {
      total =
        cumulation.countTaskOfDirectChildren -
        cumulation.countTaskDiscardOfDirectChildren
      done = cumulation.countTaskDoneOfDirectChildren
    }
  } else {
    if (
      wbsItemType?.isWorkgroup() ||
      wbsItemType?.isProcess() ||
      wbsItemType?.isDeliverableList()
    ) {
      done = cumulation.countStatusDeliverableDone
      total =
        cumulation.countStatusDeliverableTodo +
        cumulation.countStatusDeliverableDoing +
        cumulation.countStatusDeliverableReview +
        cumulation.countStatusDeliverableDone
    } else {
      total =
        cumulation.countTaskOfDirectChildren -
        cumulation.countTaskDiscardOfDirectChildren
      done = cumulation.countTaskDoneOfDirectChildren
    }
  }

  return (
    <RootDiv>
      <IconArea>
        <DelayIcon wbsItem={wbsItem} />
      </IconArea>
      {wbsItemType?.isTask() && (
        <StatusNameLabel>{format(thisValue, params)}</StatusNameLabel>
      )}
      {!wbsItemType?.isTask() && (
        <PercentageLabel total={total}>
          {formatPercentage(done, total)}
        </PercentageLabel>
      )}
      <FractionLabel>
        {0 < total || !wbsItemType?.isTask()
          ? `(${formatAggregation(done, params)} / ${formatAggregation(
              total,
              params
            )})`
          : undefined}
      </FractionLabel>
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}
