// Ag grid options
import {
  CellKeyDownEvent,
  GridOptions,
  ICellRendererParams,
  ProcessCellForExportParams,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import {
  CheckBoxCellRenderer,
  DefaultCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import { SprintDetail } from '../../../../lib/functions/sprint'
import DateTimeVO from '../../../../vo/DateTimeVO'
import { normalize } from '../../../../utils/multilineText'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import {
  ProjectNotificationSettingRow,
  getContentDisplayName,
} from '../projectNotificationSettings'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { SlackChannelCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/SlackChannelCellEditor'
import { SlackChannel } from '../../../../domain/value-object/Slack/ChannelVO'

export const ProjectNotificationSettingGridOptions = (): GridOptions => {
  return {
    /**
     * contents below could be commonized.
     */
    // Styling
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 32,
    rowClassRules: {
      'ag-row-copied-or-selected-row': p =>
        p.context.copied?.some(v => v.id === p.node.id),
      'ag-row-copied-single-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'both'),
      'ag-row-copied-start-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'top'),
      'ag-row-copied-middle-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'middle'),
      'ag-row-copied-end-row': p =>
        p.context.copied?.some(v => v.id === p.node.id && v.place === 'bottom'),
    },
    // Row actions
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    onCellKeyDown: (params: CellKeyDownEvent) => {
      // @ts-ignore
      if (!['Delete', 'Backspace'].includes(params.event.key)) return
      const cellRanges = params.api.getCellRanges() || []
      cellRanges.forEach(range => {
        const start = Math.min(range.startRow!.rowIndex, range.endRow!.rowIndex)
        const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
        range.columns.forEach(column => {
          const colDef = column.getColDef()
          if (
            typeof colDef.editable === 'function'
              ? colDef.editable(params)
              : colDef.editable
          ) {
            for (let i = start; i <= end; i++) {
              const rowNode = params.api.getDisplayedRowAtIndex(i)
              rowNode?.setDataValue(column, undefined)
            }
          }
        })
      })
    },
    suppressContextMenu: true,
    // Column definition
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 150,
      editable: false,
      enableValue: false,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      suppressSizeToFit: true,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        // When editing in Japanese, any keyboard event should be suppressed.
        if (params.editing && params.event.isComposing) {
          return true
        }
        // Prevent editing cell for DELETE or BACKSPACE
        return (
          !params.editing && ['Delete', 'Backspace'].includes(params.event.key)
        )
      },
      valueSetter: (
        params: ValueSetterParams<ProjectNotificationSettingRow>
      ) => {
        const field = params.colDef.field || params.colDef.colId
        if (
          !field ||
          (!params.oldValue && !params.newValue) ||
          params.oldValue === params.newValue
        ) {
          return false
        }
        objects.setValue(params.data, field, params.newValue)
        params.data.edited = true
        if (!params.data.editedData) {
          params.data.editedData = { [field]: params.oldValue }
        } else if (!params.data.editedData.hasOwnProperty(field)) {
          params.data.editedData[field] = params.oldValue
        }

        store.dispatch(requireSave())
        return true
      },
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        valueGetter: params => (params.node?.rowIndex ?? 0) + 1,
      },
      {
        field: 'notificationCode',
        headerName: intl.formatMessage({
          id: 'project.notification.setting.column.content',
        }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 500,
        editable: false,
        valueGetter: (
          params: ValueGetterParams<ProjectNotificationSettingRow>
        ) => getContentDisplayName(params.data),
      },
      {
        headerName: intl.formatMessage({
          id: 'project.notification.setting.column.mail',
        }),
        children: [
          {
            field: 'mailNotification',
            headerName: intl.formatMessage({
              id: 'project.notification.setting.column.onoff',
            }),
            hide: false,
            pinned: false,
            lockPosition: 'right',
            width: 90,
            editable: false,
            cellRenderer: CheckBoxCellRenderer,
          },
        ],
      },
      {
        headerName: intl.formatMessage({
          id: 'project.notification.setting.column.slack',
        }),
        children: [
          {
            field: 'slackNotification',
            headerName: intl.formatMessage({
              id: 'project.notification.setting.column.onoff',
            }),
            hide: false,
            pinned: false,
            lockPosition: 'right',
            width: 90,
            editable: false,
            cellRenderer: CheckBoxCellRenderer,
            cellRendererParams: {
              enableDisabledByContext: true,
            },
          },
          {
            field: 'slackChannel',
            headerName: intl.formatMessage({
              id: 'project.notification.setting.column.slack.channel',
            }),
            hide: false,
            pinned: false,
            lockPosition: 'right',
            width: 250,
            editable: params => params.data.slackNotification,
            valueFormatter: params => params.value?.slackChannelName ?? '',
            cellRendererParams: {
              validate: (
                value: Partial<SlackChannel>,
                params: ICellRendererParams
              ): string | undefined => {
                const { data } = params
                if (
                  data.slackNotification &&
                  (!value || !value.slackChannelId)
                ) {
                  return intl.formatMessage({
                    id: 'project.notification.setting.error.message.notSetSlackChannel',
                  })
                }
                return undefined
              },
            },
            cellEditor: SlackChannelCellEditor,
          },
        ],
      },
    ],
    // Copy & paste
    processCellForClipboard,
    processCellFromClipboard,
    // Footer
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'count'],
          },
          align: 'left',
        },
      ],
    },
  }
}

export const processCellForClipboard = ({
  column,
  value,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field } = colDef
  if (field === 'slackChannel') {
    return value?.slackChannelId
  }
  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field } = colDef
  if (field === 'slackChannel') {
    return context.slackChannels?.find(v => v.slackChannelId === value)
  }
  return value
}
