import { useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import { APIResponse } from '../../../../lib/commons/api'
import {
  ProjectPlanPathProps,
  getProjectPlanPathByWbsItemUuid,
} from '../../../../lib/functions/projectPlan'
import { AllState } from '../../../../store'
import { BreadCrumb } from '../../../../store/breadcrumb'
import { getProgressReportLinkPath, ProgressReportScreenId } from '..'
import Breadcrumbs from '../../../components/headers/HeaderBar/Breadcrumbs'
import { ProgressReportSearchConditionVO } from '../../../../domain/value-object/ProgressReportSearchConditionVO'
import { Box } from '@mui/material'

interface OwnProps {
  rootUuid?: string
  transitionScreenId: ProgressReportScreenId
}

type Props = OwnProps

const createBreadcrumbs = (
  projectPlanPath: ProjectPlanPathProps,
  screenId: ProgressReportScreenId
): BreadCrumb | undefined => {
  if (!projectPlanPath) {
    return
  }
  const searchCondition: Partial<ProgressReportSearchConditionVO> = {
    rootWbsItemUuid: projectPlanPath.wbsItem?.uuid,
  }

  const link = getProgressReportLinkPath(
    window.location.pathname.split('/')[2],
    searchCondition,
    {},
    screenId
  )

  return {
    name: projectPlanPath.wbsItem.name,
    uuid: projectPlanPath.uuid,
    code: projectPlanPath.wbsItem.code,
    link,
    next: createBreadcrumbs(projectPlanPath.child, screenId),
    type: projectPlanPath.wbsItem.typeDto,
  }
}

const ProgressReportBreadcrumbs = ({ rootUuid, transitionScreenId }: Props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb | undefined>(
    undefined
  )
  useLayoutEffect(() => {
    if (!rootUuid) {
      return undefined
    }
    getProjectPlanPathByWbsItemUuid(rootUuid).then((response: APIResponse) => {
      const projectPlanPath: ProjectPlanPathProps = response.json
      const breadcrumbs = createBreadcrumbs(projectPlanPath, transitionScreenId)
      setBreadcrumbs(breadcrumbs)
    })
  }, [rootUuid])

  return !breadcrumbs ? (
    <></>
  ) : (
    <Box sx={{ marginLeft: '16px' }}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
})

export default connect(mapStateToProps)(ProgressReportBreadcrumbs)
