import {
  StyledDialog,
  StyledDialogTitle,
  Title,
  StyledDialogContent,
  StyledDialogActions,
} from '.'
import { intl } from '../../../../../i18n'
import { VersionReportVersionEntity } from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { Button } from '../../../../components/buttons'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { dateTimeV2Service } from '../../../../../domain/value-object/DateTimeV2'
import { useMemo } from 'react'

type VersionInfoDialogProps = {
  open: boolean
  version: VersionReportVersionEntity | undefined
  onClose: () => void
}
export const VersionInfoDialog = ({
  open,
  version,
  onClose,
}: VersionInfoDialogProps) => {
  const [createdAt, updatedAt] = useMemo(() => {
    if (!version) return ['', '']
    return [
      dateTimeV2Service.format(version.createdAt),
      dateTimeV2Service.format(version.updatedAt),
    ]
  }, [version])
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Title>詳細情報</Title>
      </StyledDialogTitle>
      <StyledDialogContent>
        <PropertyRow>
          <PropHeader>作成者</PropHeader>
          <PropValue>{version?.createdBy.name}</PropValue>
        </PropertyRow>
        <PropertyRow>
          <PropHeader>作成日時</PropHeader>
          <PropValue>{createdAt}</PropValue>
        </PropertyRow>
        <PropertyRow>
          <PropHeader>最終更新者</PropHeader>
          <PropValue>{version?.updatedBy.name}</PropValue>
        </PropertyRow>
        <PropertyRow>
          <PropHeader>最終更新日時</PropHeader>
          <PropValue>{updatedAt}</PropValue>
        </PropertyRow>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} colorPattern="monotone" variant="outlined">
          閉じる
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

const PropertyRow = styled('div')({
  display: 'flex',
  gap: '30px',
})
const PropHeader = styled('div')({
  width: '100px',
  color: colorPalette.monotone[5],
  textAlign: 'right',
})
const PropValue = styled('div')({
  width: '200px',
  color: colorPalette.monotone[5],
})
