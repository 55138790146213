import { useEffect, useMemo, useState } from 'react'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { BurndownChartBasicInformation } from '../../../../domain/value-object/BurndownChart'

export const useDomainDateRange = (
  basicInformation: BurndownChartBasicInformation | undefined
) => {
  const { project } = useProjectPrivateContext()
  const projectRange = useMemo(
    () => ({
      from: new Date(project.scheduledDate.startDate),
      to: new Date(project.scheduledDate.endDate),
    }),
    [project]
  )
  const range = useMemo(
    () => ({
      from: basicInformation?.maxDisplayDateTerm.from || projectRange.from,
      to: basicInformation?.maxDisplayDateTerm.to || projectRange.to,
    }),
    [basicInformation, projectRange]
  )
  return range
}
