import API from '../../lib/commons/api'
import { UiStateStorageService } from '../../services/ports/uiStateStorageService'
import { UiStateScope } from '../../services/model/uiState'
import { ApiResponseValue } from '../../services/model/api'

export const useUiStateStorageService = (): UiStateStorageService => {
  const save = async (
    functionUuid: string,
    key: string,
    scope: UiStateScope,
    value: ApiResponseValue
  ): Promise<void> => {
    const request = {
      key,
      scope,
      value: JSON.stringify(value),
    }
    API.presentational.request(
      'POST',
      `/api/v1/ui_states?applicationFunctionUuid=${functionUuid}`,
      request
    )
  }
  const fetch = async (
    functionUuid: string,
    key: string,
    scope: UiStateScope
  ): Promise<ApiResponseValue | undefined> => {
    const request = {
      applicationFunctionUuid: functionUuid,
      key,
      scope,
    }
    const response = await API.presentational.request(
      'GET',
      '/api/v1/ui_states',
      request
    )
    if (!response.json.value) return undefined
    return JSON.parse(response.json.value)
  }

  return {
    save,
    fetch,
  }
}
