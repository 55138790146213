import { useMemo } from 'react'
import {
  WidgetWithTitle,
  WidgetTitle,
  WidgetForExpanded,
  Kpi,
  KpiValuesColumn,
  WidgetContent,
} from '.'
import { ProjectProfitLoss } from '../../../../domain/value-object/ProjectReportVO'
import { useI18n } from '../../../hooks/useI18n'
import { intl } from '../../../../i18n'
import { useKpiAmountDigit } from '../hooks/kpiAmountDigit'

type ProjectProfitLossWidgetProps = ProjectProfitLoss

export const ProjectProfitLossWidget = ({
  totalCost,
  scheduledRecordedCost,
  actualRecordedCost,
  remainingCost,
  totalProfit,
  scheduledRecordedProfit,
  actualRecordedProfit,
  remainingProfit,
  expectedProfitRatio,
}: ProjectProfitLossWidgetProps) => {
  const maxValue = useMemo(
    () => Math.max(totalCost, totalProfit),
    [totalCost, totalProfit]
  )
  const { truncateDigit, unit } = useKpiAmountDigit(maxValue)
  const {
    formattedTotalCost,
    formattedScheduledRecordedCost,
    formattedActualRecordedCost,
    formattedRemainingCost,
    formattedTotalProfit,
    formattedScheduledRecordedProfit,
    formattedActualRecordedProfit,
    formattedRemainingProfit,
    formattedExpectedProfitRatio,
  } = useMemo(() => {
    const format = (src: number) => (src / 10 ** truncateDigit).toFixed(1)
    return {
      formattedTotalCost: format(totalCost),
      formattedScheduledRecordedCost: format(scheduledRecordedCost),
      formattedActualRecordedCost: format(actualRecordedCost),
      formattedRemainingCost: format(remainingCost),
      formattedTotalProfit: format(totalProfit),
      formattedScheduledRecordedProfit: format(scheduledRecordedProfit),
      formattedActualRecordedProfit: format(actualRecordedProfit),
      formattedRemainingProfit: format(remainingProfit),
      formattedExpectedProfitRatio: expectedProfitRatio
        ? (expectedProfitRatio * 100).toFixed(1)
        : '-',
    }
  }, [
    actualRecordedCost,
    actualRecordedProfit,
    expectedProfitRatio,
    remainingCost,
    remainingProfit,
    scheduledRecordedCost,
    scheduledRecordedProfit,
    totalCost,
    totalProfit,
    truncateDigit,
  ])

  const [
    title,
    totalCostLabel,
    scheduledRecordedCostLabel,
    actualRecordedCostLabel,
    remainingCostLabel,
    totalProfitLabel,
    scheduledRecordedProfitLabel,
    actualRecordedProfitLabel,
    remainingProfitLabel,
    expectedProfitRatioLabel,
  ] = useI18n([
    'enterpriseProjectReport.profitLoss',
    'enterpriseProjectReport.totalCost',
    'enterpriseProjectReport.scheduledRecordedCost',
    'enterpriseProjectReport.actualRecordedCost',
    'enterpriseProjectReport.remainingCost',
    'enterpriseProjectReport.totalProfit',
    'enterpriseProjectReport.scheduledRecordedProfit',
    'enterpriseProjectReport.actualRecordedProfit',
    'enterpriseProjectReport.remainingProfit',
    'enterpriseProjectReport.expectedProfitRatio',
  ])
  return (
    <WidgetWithTitle>
      <WidgetTitle>{title}</WidgetTitle>
      <WidgetForExpanded>
        <WidgetContent>
          <KpiValuesColumn>
            <Kpi
              title={totalCostLabel}
              value={formattedTotalCost}
              unit={unit}
              size="l"
            />
            <Kpi
              title={totalProfitLabel}
              value={formattedTotalProfit}
              unit={unit}
              size="l"
            />
          </KpiValuesColumn>
          <KpiValuesColumn>
            <Kpi
              title={scheduledRecordedCostLabel}
              value={formattedScheduledRecordedCost}
              unit={unit}
              size="l"
            />
            <Kpi
              title={scheduledRecordedProfitLabel}
              value={formattedScheduledRecordedProfit}
              unit={unit}
              size="l"
            />
          </KpiValuesColumn>
          <KpiValuesColumn>
            <Kpi
              title={actualRecordedCostLabel}
              value={formattedActualRecordedCost}
              unit={unit}
              size="l"
            />
            <Kpi
              title={actualRecordedProfitLabel}
              value={formattedActualRecordedProfit}
              unit={unit}
              size="l"
            />
          </KpiValuesColumn>
          <KpiValuesColumn>
            <Kpi
              title={remainingCostLabel}
              value={formattedRemainingCost}
              unit={unit}
              size="l"
            />
            <Kpi
              title={remainingProfitLabel}
              value={formattedRemainingProfit}
              unit={unit}
              size="l"
            />
          </KpiValuesColumn>
          <Kpi
            title={expectedProfitRatioLabel}
            value={formattedExpectedProfitRatio}
            unit={'%'}
          />
        </WidgetContent>
      </WidgetForExpanded>
    </WidgetWithTitle>
  )
}
