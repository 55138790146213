import { styled } from '@mui/system'
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
} from '@mui/material'
import { ColorPalette, initGraphConfig, ProgressReportGraphConfig } from '../..'
import { useCallback, useState } from 'react'
import { WbsItemTypeForWbsProgressLog } from '../../../../../lib/functions/wbsProgressLog'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { intl } from '../../../../../i18n'
import { AggregateField } from '../../../../../domain/entity/WbsItemEntity'

const RootDiv = styled('div')({
  padding: '0 0 0 5px',
})
const StyledIconButton = styled(IconButton)({
  padding: '3px',
})
const IconStyle = {
  width: '16px !important',
  height: '16px !important',
  color: '#888',
}
const StyledAddCircleOutlineRoundedIcon = styled(AddCircleOutlineRoundedIcon)(
  IconStyle
)
const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(IconStyle)
const StyledPaper = styled(Paper)({
  padding: '8px',
})
const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const HeaderLabel = styled('span')({
  fontSize: FontSize.MEDIUM,
  fontWeight: FontWeight.BOLD,
})
const StyledList = styled(List)({
  padding: '0',
})
const StyledListItem = styled(ListItem)({
  padding: '0',
})
const StyledListItemButton = styled(ListItemButton)({
  padding: '3px 5px !important',
})
const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '28px',
})
const StyledListItemText = styled(ListItemText)({
  fontSize: FontSize.MEDIUM,
})

type AddLegendButtonProps = {
  onAdd: (added: ProgressReportGraphConfig[]) => void
  types: WbsItemTypeForWbsProgressLog[]
  popColorPalette: () => ColorPalette
  aggregateType: AggregateField
}

const AddLegendButton = ({
  onAdd,
  types,
  popColorPalette,
  aggregateType,
}: AddLegendButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const onClick = useCallback(
    (type: WbsItemTypeForWbsProgressLog) => {
      const newGraphConfigs = initGraphConfig(
        type,
        popColorPalette(),
        aggregateType
      )
      onAdd(newGraphConfigs)
      setAnchorEl(null)
    },
    [onAdd]
  )
  return (
    <RootDiv>
      <IconButton
        size={'small'}
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        <StyledAddCircleOutlineRoundedIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPaper>
          <Header>
            <HeaderLabel>
              {intl.formatMessage({ id: 'progressReport.selectType' })}
            </HeaderLabel>
            <StyledIconButton onClick={_ => setAnchorEl(null)}>
              <StyledCloseRoundedIcon />
            </StyledIconButton>
          </Header>
          <StyledList>
            {types.map((type, index) => (
              <StyledListItem key={index} disablePadding={true}>
                <StyledListItemButton onClick={() => onClick(type)}>
                  <StyledListItemIcon>
                    <img src={type.iconUrl} />
                  </StyledListItemIcon>
                  <StyledListItemText
                    primary={type.name}
                    disableTypography={true}
                  />
                </StyledListItemButton>
              </StyledListItem>
            ))}
          </StyledList>
        </StyledPaper>
      </Popover>
    </RootDiv>
  )
}

export default AddLegendButton
