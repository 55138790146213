import {
  CellClassParams,
  CellClickedEvent,
  CellKeyDownEvent,
  CellStyle,
  ColDef,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  dateValueFormatter,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import {
  CustomEnumCellRenderer,
  DefaultCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { ServerSideUuidFilter } from '../../../containers/BulkSheetView/components/filter'
import { intl } from '../../../../i18n'
import { CustomEnumCellEditor } from '../../../containers/BulkSheetView/components/cellEditor'
import {
  ActualWorkingHoursRow,
  ActualWorkingHoursRowProjectBody,
  formatHour,
  formatTime,
  formatWorkTime,
} from '../ActualWorkingHours'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../../utils/date'
import './styles.scss'
import { ProjectDetail } from '../../../../lib/functions/project'
import { openTaskActualWorkListProps } from '../hooks/taskActualWorkTaskDialogState'
import { Color } from '../../../../styles/commonStyles'

export const actualWorkingHoursGridOptions = ({
  editable,
  openTaskActualWorkList,
  callbackCellEdit,
}: {
  editable: boolean
  openTaskActualWorkList?: (props: openTaskActualWorkListProps) => void
  callbackCellEdit: () => void
}): GridOptions => {
  return {
    groupHeaderHeight: 0,
    headerHeight: 45,
    rowHeight: 32,
    treeData: false,
    context: {},
    suppressLastEmptyLineOnPaste: true,
    // Row
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: false,
    onCellValueChanged: event => {
      callbackCellEdit()
    },
    onCellKeyDown: (params: CellKeyDownEvent) => {
      // @ts-ignore
      if (!['Delete', 'Backspace'].includes(params.event.key)) return
      const cellRanges = params.api.getCellRanges() || []
      cellRanges.forEach(range => {
        const start = Math.min(range.startRow!.rowIndex, range.endRow!.rowIndex)
        const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
        range.columns.forEach(column => {
          const colDef = column.getColDef()
          if (
            typeof colDef.editable === 'function'
              ? colDef.editable(params)
              : colDef.editable
          ) {
            for (let i = start; i <= end; i++) {
              const rowNode = params.api.getDisplayedRowAtIndex(i)
              rowNode?.setDataValue(column, undefined)
            }
          }
        })
      })
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 85,
      editable: (params: EditableCallbackParams) => {
        if (params.data.isTotal) return false
        return editable
      },
      enableValue: false,
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellStyle: { justifyContent: 'center' },
      cellClassRules: {
        'sevend-ag-grid-date-header-holiday': params => {
          const types = ['PAID_LEAVE', 'HOLIDAY', 'PAID_LEAVE_HALF']
          const data = params.data
          if (data.isTotal) {
            return false
          } else if (
            !!data.schedule?.legal ||
            data.schedule.workHour === 0 ||
            types.includes(data.workingHoursType)
          ) {
            return true
          }
          return false
        },
      },
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      suppressPaste: params => !_.isEmpty(params.context?.copied),
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        return (
          !params.editing && ['Delete', 'Backspace'].includes(params.event.key)
        )
      },
      valueSetter: (params: ValueSetterParams) => {
        const field = params.colDef.field || params.colDef.colId
        if (
          !field ||
          (!params.oldValue && !params.newValue) ||
          params.oldValue === params.newValue
        ) {
          return false
        }
        let newValue = params.newValue
        if (
          ['actual.startTime', 'actual.endTime', 'actual.breakTime'].includes(
            field
          )
        ) {
          if (!!params.newValue) {
            if (!validateTime(params.newValue)) {
              return false
            }
            newValue = parseTimeFormat(params.newValue)
          }
        }
        objects.setValue(params.data, field, newValue)
        params.data.edited = true
        store.dispatch(requireSave())
        return true
      },
    },
    columnDefs: [
      {
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: ServerSideUuidFilter,
      },
      {
        colId: 'blank',
        hide: true,
        width: 35,
        suppressColumnsToolPanel: true,
      },
      {
        field: 'date',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.date' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 90,
        editable: false,
        type: [ColumnType.date],
        valueFormatter: (params: ValueFormatterParams) =>
          dateValueFormatter(
            params.value,
            DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
          ) || '',
      },
      {
        field: 'workingHoursType',
        headerName: intl.formatMessage({
          id: 'actualWorkingHours.workingHoursType',
        }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 140,
        type: [ColumnType.customEnum],
        cellEditor: CustomEnumCellEditor,
        cellEditorParams: {
          customEnumCode: 'workingHoursType',
        },
        cellRenderer: CustomEnumCellRenderer,
        cellRendererParams: {
          customEnumCode: 'workingHoursType',
        },
        cellClass: 'workingHoursType',
      },
      {
        field: 'actual.startTime',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.startTime' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 100,
        cellClass: 'actualTime',
        cellClassRules: {
          'actual-hours-required-input': params => {
            const data = params.data
            if (data.isTotal || !data.actual) return false
            return isRequiredOtherSide(
              data.workingHoursType,
              data.actual.endTime,
              data.actual.startTime
            )
          },
        },
      },
      {
        field: 'actual.endTime',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.endTime' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 100,
        cellClass: 'actualTime',
        cellClassRules: {
          'actual-hours-required-input': params => {
            const data = params.data
            if (data.isTotal || !data.actual) return false
            return isRequiredOtherSide(
              data.workingHoursType,
              data.actual.startTime,
              data.actual.endTime
            )
          },
        },
      },
      {
        field: 'actual.breakTime',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.breakTime' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 100,
        valueGetter: (params: ValueGetterParams) => {
          if (params.data.isTotal) {
            return params.data.breakTime
              ? formatTime(params.data.breakTime)
              : ''
          }
          return params.data.actual?.breakTime ?? ''
        },
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value
        },
        cellClass: (params: CellClassParams) => {
          return params.data.isTotal ? 'timeFormula' : 'breakTime'
        },
      },
      {
        field: 'actual.workedTime',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.workedTime' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 90,
        editable: false,
        valueGetter: (params: ValueGetterParams) => {
          if (params.data.isTotal) {
            return formatTime(params.data.workHour)
          }
          return formatWorkTime(params.data)
        },
        cellClass: 'timeFormula',
      },
      {
        field: 'remarks',
        headerName: intl.formatMessage({ id: 'actualWorkingHours.remarks' }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 200,
        cellStyle: { justifyContent: 'start' },
      },
      {
        field: 'totalActualHour',
        headerName: intl.formatMessage({
          id: 'actualWorkingHours.actualHour',
        }),
        hide: false,
        pinned: true,
        lockPosition: 'left',
        width: 85,
        editable: false,
        valueGetter: (params: ValueGetterParams) => {
          const value = params.data?.totalActualHour || 0
          if (value === 0) return ''
          return formatHour(value)
        },
        cellClass: 'actualTime',
        cellStyle: (params: CellClassParams) => {
          return getActualWorkColumnCellStyle(() => {
            return toalActualHourColumnClickable(params.data)
          })
        },
        onCellClicked: (event: CellClickedEvent) => {
          if (!toalActualHourColumnClickable(event.data)) {
            return
          }
          onActualWOrkCellClicked(
            event,
            event.context.userUuid,
            openTaskActualWorkList,
            editable
          )
        },
      },
    ],
    excelStyles: [
      {
        id: 'dateType',
        dataType: 'DateTime',
        numberFormat: {
          format: intl.formatMessage({
            id: 'actualWorkingHours.excel.date.format',
          }),
        },
      },
      {
        id: 'actualTime',
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'breakTime',
        numberFormat: {
          format: intl.formatMessage({
            id: 'h:mm',
          }),
        },
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'workingHoursType',
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'timeFormula',
        dataType: 'Formula',
        numberFormat: {
          format: intl.formatMessage({
            id: '[h]:mm',
          }),
        },
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'centerCell',
        alignment: {
          horizontal: 'Center',
        },
      },
      {
        id: 'justifyRight',
        alignment: {
          horizontal: 'Right',
        },
      },
      {
        id: 'formulaTimeCell',
        numberFormat: {
          format: '[h]:mm',
        },
        alignment: {
          horizontal: 'Right',
        },
      },
    ],
  }
}

const validateTime = (value: string): boolean => {
  if (!value) return true
  if (
    /^([01０１]?[0-9０-９]|[2２][0-3０-３])([:：])([0-5０-５][0-9０-９])$/.test(
      value
    )
  ) {
    return true
  }
  return /^([01０１]?[0-9０-９]|[2２][0-3０-３])([0-5０-５][0-9０-９])$/.test(
    value
  )
}

const isRequiredOtherSide = (
  type: string,
  base: string,
  target: string
): boolean => {
  const targetTypes = ['ATTENDANCE', 'PAID_LEAVE_HALF']
  if (!targetTypes.includes(type)) return false
  return !!base && !target
}

const COLUMN_FIELD_PREFIX_PROJECT: string = 'project_'

export type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  projects: ProjectDetail[]
  userUuid: string | undefined
  openTaskActualWorkList?: (props: openTaskActualWorkListProps) => void
}

export const refreshDynamicColumnDef = ({
  api,
  projects,
  userUuid,
  openTaskActualWorkList,
}: RefreshDynamicColumnDefProps) => {
  if (!api || _.isEmpty(projects)) return
  const columnDefs = api.getColumnDefs()
  const newColumnDefs = columnDefs?.filter(
    def =>
      !('field' in def) || !def.field?.startsWith(COLUMN_FIELD_PREFIX_PROJECT)
  )
  projects.forEach(project => {
    newColumnDefs?.push({
      field: COLUMN_FIELD_PREFIX_PROJECT + project.uuid,
      headerName: project.displayName,
      hide: true,
      pinned: false,
      editable: false,
      refData: { uuid: project.uuid, code: project.code },
      valueGetter: (params: ValueGetterParams) => {
        const uuid = params.colDef.refData?.uuid
        const targetProjectHour: ActualWorkingHoursRowProjectBody =
          params.data.projectHours?.find(p => p.projectUuid === uuid)
        const hour = targetProjectHour?.actualHour || 0
        if (hour === 0) return ''
        return formatHour(hour)
      },
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value
      },
      cellStyle: (params: CellClassParams) => {
        return getActualWorkColumnCellStyle(() => {
          return projectColumnClickable(
            params.data,
            params.colDef.refData?.uuid
          )
        })
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (!projectColumnClickable(event.data, event.colDef.refData?.uuid)) {
          return
        }
        onActualWOrkCellClicked(event, userUuid, openTaskActualWorkList)
      },
      cellClass: 'actualTime',
      width: 125,
    })
  })
  api.setColumnDefs(newColumnDefs!)
}

export const updateDisplayProjectColumn = (
  columnApi: ColumnApi | null | undefined,
  gridApi: GridApi | null | undefined,
  data: ActualWorkingHoursRow[]
) => {
  if (!columnApi || !gridApi || _.isEmpty(data)) return

  const displayProjectUuids = new Set<string>(
    data
      .filter(d => !!d.projectHours)
      .flatMap(d => d.projectHours)
      .map(p => p?.projectUuid)
      .filter(p => !!p) as string[]
  )

  const columnState = columnApi.getColumnState()
  columnState.forEach(state => {
    if (!state.colId.startsWith(COLUMN_FIELD_PREFIX_PROJECT)) return

    const uuid = state.colId.slice(COLUMN_FIELD_PREFIX_PROJECT.length)
    state.hide = !displayProjectUuids.has(uuid)
  })
  columnApi.applyColumnState({
    state: columnState,
    applyOrder: true,
  })

  const columnDefs: ColDef[] = gridApi.getColumnDefs() || []
  const visibleColDefs = columnDefs.filter(def => {
    if (!def?.colId) return false
    if (!def.colId.startsWith(COLUMN_FIELD_PREFIX_PROJECT)) return true
    const uuid = def.colId.slice(COLUMN_FIELD_PREFIX_PROJECT.length)
    return displayProjectUuids.has(uuid)
  })
  gridApi.getToolPanelInstance('columns')?.setColumnLayout(visibleColDefs)
}

const projectColumnClickable = (
  row: ActualWorkingHoursRow,
  projectUuid: string | undefined
): boolean => {
  return (
    !row.isTotal &&
    !!row.projectHours &&
    row.projectHours.some(p => p.projectUuid === projectUuid)
  )
}

const toalActualHourColumnClickable = (row: ActualWorkingHoursRow) => {
  if (row.isTotal) return false
  return !!row.totalActualHour && row.totalActualHour >= 0.01
}

const getActualWorkColumnCellStyle = (clickable: () => boolean) => {
  const style: CellStyle = {
    justifyContent: 'center',
  }
  if (!clickable()) return style

  style.cursor = 'pointer'
  style.color = Color.MAIN
  return style
}

const onActualWOrkCellClicked = (
  event: CellClickedEvent,
  userUuid: string | undefined,
  openTaskActualWorkList?: (props: openTaskActualWorkListProps) => void,
  crossProject?: boolean
) => {
  if (!openTaskActualWorkList || !(event.event instanceof PointerEvent)) {
    return
  }
  const row: ActualWorkingHoursRow = event.data
  const projectUuid = event.context.projectUuid ?? event.colDef.refData?.uuid
  const projectCode = event.context.projectCode ?? event.colDef.refData?.code
  openTaskActualWorkList({
    userUuid,
    projectUuid: crossProject ? undefined : projectUuid,
    projectCode: crossProject ? undefined : projectCode,
    targetDate: row.date,
    event: event.event,
  })
}

const parseTimeFormat = (str: string) => {
  str = str.replace('：', ':')
  str = str.replace(/[０-９]/g, s => {
    return String.fromCharCode(s.charCodeAt(0) - 65248)
  })
  if (str.includes(':')) {
    return str
  }
  if (str.length === 4) {
    return `${str.substring(0, 2)}:${str.substring(2)}`
  }
  if (str.length === 3) {
    return `${str.substring(0, 1)}:${str.substring(1)}`
  }
  return undefined
}
