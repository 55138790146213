import { useCallback, useState } from 'react'
import { ClickAwayListener, Typography } from '@mui/material'
import { ColDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { FontSize } from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import FilterPopper from './FilterPopper'

type Props = {
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const FilterButton = ({
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  getDisplayColumnName,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element>()

  const handleClose = useCallback(() => setAnchorEl(undefined), [])
  const openPopper = useCallback(event => setAnchorEl(event.currentTarget), [])
  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <button
          style={{
            background: 'transparent',
            border: 0,
            fontSize: FontSize.MEDIUM,
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'row',
            padding: '5px 10px',
            cursor: filteredColumns.length >= 1 ? 'pointer' : '',
            minWidth: 'fit-content',
          }}
          onClick={openPopper}
        >
          <Typography sx={{ color: colorPalette.monotone[5] }}>
            {intl.formatMessage({ id: 'wbs.header.filter.title' })}
          </Typography>
          {filteredColumns.length >= 1 ? (
            <Typography
              sx={{
                cursor: 'pointer',
                color: colorPalette.skyBlue[7],
              }}
            >
              {filteredColumns.length}
            </Typography>
          ) : (
            <Typography sx={{ color: colorPalette.monotone[3] }}>
              {intl.formatMessage({ id: 'wbs.header.none.label' })}
            </Typography>
          )}
        </button>
      </ClickAwayListener>
      {filteredColumns.length >= 1 && (
        <FilterPopper
          anchorEl={anchorEl}
          filteredColumns={filteredColumns}
          placement={'bottom-start'}
          onDeleteFilteredColumn={onDeleteFilteredColumn}
          resetFilters={resetFilters}
          getDisplayColumnName={getDisplayColumnName}
        />
      )}
    </>
  )
}

export default FilterButton
