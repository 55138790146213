// TODO Remove it
import { SavedUIState } from './SavedUIStateList'
import { ColumnState } from 'ag-grid-community'

const colIdPatch = {
  ['wbsItem.code']: 'body.wbsItem.code',
  ['wbsItem.type']: 'body.wbsItem.type',
  ['wbsItem.ticketType']: 'body.wbsItem.ticketType',
  ['wbsItem.status']: 'body.wbsItem.status',
  ['wbsItem.substatus']: 'body.wbsItem.substatus',
  ['attachment']: 'body.deliverableAttachmentSummary',
  ['wbsItem.displayName']: 'body.wbsItem.displayName',
  ['wbsItem.priority']: 'body.wbsItem.priority',
  ['wbsItem.difficulty']: 'body.wbsItem.difficulty',
  ['wbsItem.description']: 'body.wbsItem.description',
  ['commentSummary.latestComment']: 'body.commentSummary.latestComment',
  ['wbsItem.team']: 'body.wbsItem.team',
  ['wbsItem.accountable']: 'body.wbsItem.accountable',
  ['wbsItem.responsible']: 'body.wbsItem.responsible',
  ['wbsItem.assignee']: 'body.wbsItem.assignee',
  ['wbsItem.watchers']: 'body.wbsItem.watchers',
  ['wbsItem.estimatedStoryPoint']: 'body.wbsItem.estimatedStoryPoint',
  ['storyPoint.earnedValue']: '',
  ['wbsItem.estimatedWorkload.deliverable']:
    'body.wbsItem.estimatedHour.deliverable',
  ['wbsItem.estimatedWorkload.task']: 'body.wbsItem.estimatedHour',
  ['estimatedProgressRate']: 'progress.scheduledProgressRate',
  ['progressRate']: 'progress.progressRate',
  ['total']: 'progress.total',
  ['plannedValue']: 'progress.scheduledToBe',
  ['earnedValue']: 'progress.completed',
  ['preceding']: 'progress.preceding',
  ['delayed']: 'progress.delayed',
  ['remaining']: 'progress.remaining',
  ['wbsItem.actualHour']: 'body.cumulation.actualHour',
  ['costVariance']: 'evm.costVariance',
  ['costPerformanceIndex']: 'evm.costPerformanceIndex',
  ['scheduleVariance']: 'evm.scheduleVariance',
  ['schedulePerformanceIndex']: 'evm.schedulePerformanceIndex',
  ['estimateToComplete']: 'evm.estimateToComplete',
  ['estimateAtCompletion']: 'evm.estimateAtCompletion',
  ['varianceAtCompletion']: 'evm.varianceAtCompletion',
  ['wbsItem.currentSprint']: 'body.sprint',
  ['wbsItem.scheduledDate.startDate']: 'body.wbsItem.scheduledDate.startDate',
  ['wbsItem.scheduledDate.endDate']: 'body.wbsItem.scheduledDate.endDate',
  ['wbsItem.actualDate.startDate']: 'body.wbsItem.actualDate.startDate',
  ['wbsItem.actualDate.endDate']: 'body.wbsItem.actualDate.endDate',
  ['wbsItem.estimatedAmount.deliverable']:
    'body.wbsItem.estimatedAmount.deliverable',
  ['wbsItem.estimatedAmount.task']: 'body.wbsItem.estimatedAmount.task',
  ['wbsItem.actualAmount']: 'body.wbsItem.actualAmount',
  ['wbsItem.revision']: 'body.wbsItem.revision',
  ['wbsItem.createdBy']: 'body.wbsItem.createdBy',
  ['wbsItem.createdAt']: 'body.wbsItem.createdAt',
  ['wbsItem.updatedBy']: 'body.wbsItem.updatedBy',
  ['wbsItem.updatedAt']: 'body.wbsItem.updatedAt',
}

export const patchColState = async (
  uiStates: SavedUIState[],
  getUIStates: () => Promise<SavedUIState[]>,
  updateUIStates: (uiState: SavedUIState[]) => Promise<void>
): Promise<SavedUIState[]> => {
  if (0 < uiStates.length) return uiStates
  const oldUiState: SavedUIState[] = await getUIStates()
  const patched = oldUiState.map(v => {
    return {
      ...v,
      UIState: {
        ...v.UIState,
        columnState:
          v.UIState.column?.map((colState: ColumnState) => ({
            ...colState,
            colId: colIdPatch[colState.colId] ?? colState.colId,
          })) ?? [],
      },
    } as SavedUIState
  })
  0 < oldUiState.length && updateUIStates(patched)
  return patched
}
