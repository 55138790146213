import _ from 'lodash'
import { Box, Link, Paper, styled, TableCell } from '@mui/material'
import React from 'react'
import {
  Color,
  FontSize,
  FontWeight,
  TextColor,
} from '../../../../../styles/commonStyles'
import { open } from '../../../../router'
import { ValueFormatter } from '../../../../components/charts/Chart/TwoDimChart'

export const ProgressReportTooltip = ({ children }) => {
  return (
    <Paper
      sx={{
        boxShadow: '0px 1px 2px #0000004D',
        border: '1px solid #DDDDDD',
        borderRadius: '4px',
      }}
    >
      {children}
    </Paper>
  )
}

export const ProgressReportTooltipHeader = styled(Box)({
  fontSize: '11px',
  fontWeight: FontWeight.BOLD,
  justifyContent: 'space-between',
  height: '24px',
  color: TextColor.WHITE,
  backgroundColor: Color.MAIN,
  borderRadius: '4px 4px 0 0',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
})

export const ProgressReportTooltipBody = styled(Box)({
  padding: '5px',
})

export const TooltipTableHeadCell = styled(TableCell)({
  height: '21px',
  padding: '0',
  fontSize: FontSize.SMALL,
  textAlign: 'center',
  border: 'none',
})

export const TooltipTableBodyCell = styled(TableCell)({
  padding: 0,
  textAlign: 'right',
  border: 'none',
})

export const TooltipTableLink = (props: {
  value: number
  wbsItemCodes?: string[] | undefined
  formatter: ValueFormatter
}) => {
  const { formatter, value, wbsItemCodes } = props
  return (
    <>
      {!_.isEmpty(wbsItemCodes) ? (
        <Link
          sx={{ cursor: 'pointer', textDecoration: 'none' }}
          onClick={() => {
            const workCode = wbsItemCodes?.join(',')
            const projectCode = window.location.pathname.split('/')[2]
            open(
              `/wbsItemSearch/${projectCode}?code=${workCode}`,
              undefined,
              undefined,
              true
            )
          }}
        >
          {formatter(value)}
        </Link>
      ) : (
        formatter(value)
      )}
    </>
  )
}

export type ProgressReportScheduleAndActualValues = {
  scheduled: number
  actual: number
}

export type ProgressReportScheduleAndActualValueMaps = {
  scheduled?: { [code: string]: number } | undefined
  actual?: { [code: string]: number } | undefined
}
