import API, { APIResponse } from '../commons/api'

export interface SprintProductItemProp {
  deliverableUuid: string
  deliverableLockVersion: number
  sprintUuid: string
}

export interface UpdateSprintProductItemProps {
  added: SprintProductItemProp[]
  deleted: SprintProductItemProp[]
}

export async function updateSprintProductItems(
  props: UpdateSprintProductItemProps
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/scrums/sprint_product_items/batch',
    props
  )
}
