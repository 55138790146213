import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'

export type Props = ButtonProps
const BaseButton = React.forwardRef<HTMLButtonElement, Props>(
  (props: Props, ref) => {
    return (
      <Button
        variant="contained"
        {...props}
        sx={{
          height: '24px',
          borderRadius: '17px',
          boxShadow: '0px 1px 1px #00000033',
          margin: '0 5px',
          ...props.sx,
        }}
        ref={ref}
      />
    )
  }
)

export default BaseButton
