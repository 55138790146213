import { ICellRendererParams } from 'ag-grid-community'
import React, { useEffect, useMemo, useState } from 'react'
import { ICON_SIZE } from '../../../BulkSheet/const'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { getLabel, I18n } from '../../../../../lib/commons/i18nLabel'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { setError } from '../../lib/validation'
import { Avatar } from '@mui/material'

type EntityType = {
  uuid: string
  code?: string
  name?: string
  nameI18n?: I18n
  displayName?: string
  iconUrl?: string
}

export const EntitySearchCellRenderer = (
  props: ICellRendererParams<EntityType | string | undefined> & {
    entity?: string
    uiMeta?: FunctionProperty
  }
) => {
  const { value, data, context, entity, uiMeta } = props
  const [message, setMessage] = useState<string | undefined>()

  useEffect(() => {
    if (uiMeta?.requiredIf) {
      const result = uiMeta.requiredIf.check(value, () => ({}))
      const err = result.ok ? undefined : result.message
      setError(data, err, props)
      setMessage(err)
    }
  }, [value])

  const val = useMemo(() => {
    if (entity && typeof value === 'string') {
      return (context[entity] ?? []).find(v => v.uuid === value)
    }
    return value
  }, [value])

  return (
    <Tooltip message={message}>
      <RootDiv style={{ backgroundColor: !!message ? '#FADBD8' : undefined }}>
        {val && val.iconUrl && (
          <Avatar
            src={val.iconUrl}
            variant="circular"
            sx={{
              height: `${ICON_SIZE}px`,
              width: `${ICON_SIZE}px`,
              margin: '5px',
            }}
          />
        )}
        {val && (getLabel(val.nameI18n) ?? val.displayName ?? val.name)}
        <FloatingEditableIcon {...props} />
      </RootDiv>
    </Tooltip>
  )
}
