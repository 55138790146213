import { useEffect, useState } from 'react'
import {
  DailyWorkloadData,
  BurndownChartResourceType,
} from '../../../../domain/value-object/BurndownChart'
import { BurndownChartCondition, BurndownChartResourceData } from '../model'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'

export const useResourceData = (
  condition: Pick<BurndownChartCondition, 'projectUuid' | 'domain'>,
  displayFrom: Date,
  displayTo: Date,
  simulationFrom: Date | undefined,
  resourceType: BurndownChartResourceType
): BurndownChartResourceData => {
  const [data, setData] = useState<DailyWorkloadData[]>([])
  const [sumBefore, setSumBefore] = useState<number>(0)
  const { getResourceData } = useBurndownChartRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await getResourceData({
        ...condition,
        from: displayFrom,
        to: displayTo,
        marginBefore: simulationFrom,
        resourceType,
      })
      setData(response.data)
      setSumBefore(response.sumBefore)
    }
    fn()
  }, [
    condition,
    getResourceData,
    resourceType,
    displayFrom,
    displayTo,
    simulationFrom,
  ])
  return { data, sumBefore }
}
