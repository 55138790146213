import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  ClickAwayListener,
  Popper,
  List,
  ListItem,
  ListItemButton,
  DialogActions,
  TextField,
} from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { StoredUiStateEditorDialog } from '.'
import { WbsItemSearchConditionVO } from '../../../../../domain/value-object/WbsItemSearchConditionVo'
import { intl } from '../../../../../i18n'
import { SearchConditionAndColumnState } from '../../../../../services/model/searchConditionAndColumnState'
import {
  PermanentUiState,
  UiStateScope,
} from '../../../../../services/model/uiState'
import { useColumnAndFilterStateStorageService } from '../../../../../services/storage-services/ui-states/columnAndFilterStateStorageService'
import { SavePermanentUiStateRequest } from '../../../../../services/storage-services/ui-states/permanentUiStateStorageService'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { BulkSheetColumnState } from '../../../../model/bulkSheetColumnAndFilterState'
import { colorPalette } from '../../../../style/colorPallete'
import { ScopeIcon } from '../../SavedUIStateDialog/contents/ScopeIcon'
import { UiStateScope as UiStateScopeIcon } from '../../../../../lib/commons/uiStates'
import { AllState } from '../../../../../store'
import { useSelector } from 'react-redux'
import { Button } from '../../../buttons'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { IconButton } from '../../../icons/IconButton'
import { FontSize } from '../../../../../styles/commonStyles'

type CreateSearchConditionAndColumnStateDraft = {
  type: 'Create'
  searchCondition: WbsItemSearchConditionVO
}

type UpdateSearchConditionAndColumnStateDraft = {
  type: 'Update'
  uuid: string
  name: string
  scope: UiStateScope
  searchCondition: WbsItemSearchConditionVO
}

export type SearchConditionAndColumnStateDraft =
  | CreateSearchConditionAndColumnStateDraft
  | UpdateSearchConditionAndColumnStateDraft

type StoredSearchConditionAndColumnStateEditorDialogProps = {
  functionUuid: string
  open: boolean
  draft: SearchConditionAndColumnStateDraft | undefined
  onSubmit: (
    target: SavePermanentUiStateRequest<SearchConditionAndColumnState>
  ) => void
  onClose: () => void
  currentColumnState: BulkSheetColumnState
  onDelete: (uuid: string) => void
}

type CurrentAndStoredColumnState =
  | {
      uuid: undefined
      value: BulkSheetColumnState
    }
  | PermanentUiState<BulkSheetColumnState>

interface StateProps {
  projectIconUrl: string | undefined
  tenantIconUrl: string | undefined
}

export const StoredSearchConditionAndColumnStateEditorDialog = ({
  functionUuid,
  open,
  draft,
  onClose,
  onSubmit,
  currentColumnState,
  onDelete,
}: StoredSearchConditionAndColumnStateEditorDialogProps) => {
  const [openScopeSelectPopper, setOpenScopeSelectPopper] =
    useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<Element>()

  const handleClose = () => {
    setAnchorEl(undefined)
    setOpenScopeSelectPopper(false)
  }

  const { projectUuid } = useProjectPrivateContext()

  const { projectIconUrl, tenantIconUrl } = useSelector<AllState, StateProps>(
    state => ({
      projectIconUrl: state.project.current?.iconUrl,
      tenantIconUrl: state.tenant.organization?.iconUrl,
    })
  )

  // Control form values.
  const dialogOpen = useMemo(() => open && !!draft, [open, draft])
  const [name, setName] = useState<string>('')
  const [scope, setScope] = useState<UiStateScope>('USER')
  const [uuid, setUuid] = useState<string>('')
  const [errorMessage, seterrorMessage] = useState<string>('')

  const [selectedColumnState, setSelectedColumnState] =
    useState<CurrentAndStoredColumnState>({
      uuid: undefined,
      value: currentColumnState,
    })
  useEffect(() => {
    if (!draft) return
    switch (draft.type) {
      case 'Create':
        setName('')
        setScope('USER')
        setUuid('')
        return
      case 'Update':
        setName(draft.name)
        setScope(draft.scope)
        setUuid(draft.uuid)
    }
  }, [draft])

  // Fetch stored column states.
  const columnAndFilterStateStorageService =
    useColumnAndFilterStateStorageService(functionUuid, projectUuid)

  useEffect(() => {
    if (!dialogOpen) return
    seterrorMessage('')
    columnAndFilterStateStorageService.fetch()
  }, [dialogOpen])

  // Callback functions.
  const submit = useCallback(() => {
    if (!draft) return
    switch (draft.type) {
      case 'Create':
        onSubmit({
          name,
          scope,
          value: {
            searchCondition: draft.searchCondition,
            columnState: selectedColumnState.value,
          },
        })
        return
      case 'Update':
        onSubmit({
          uuid: draft.uuid,
          name,
          scope,
          value: {
            searchCondition: draft.searchCondition,
            columnState: selectedColumnState.value,
          },
        })
        return
    }
  }, [draft, name, scope, selectedColumnState])

  const scopeIcon = useCallback(
    (scope: 'CROSS_PROJECT' | 'TENANT' | 'USER') => {
      return scope ? <ScopeIcon scope={scope as UiStateScopeIcon} /> : <></>
    },
    [projectUuid, projectIconUrl, tenantIconUrl]
  )

  const scopeName = useCallback(
    (scope: 'CROSS_PROJECT' | 'TENANT' | 'USER') => {
      let name: string | undefined = undefined
      switch (scope) {
        case 'CROSS_PROJECT':
          name = intl.formatMessage({ id: 'savedUIState.scope.tenant' })
          break
        case 'TENANT':
          name = intl.formatMessage({ id: 'savedUIState.scope.project' })
          break
        case 'USER':
          name = intl.formatMessage({ id: 'savedUIState.scope.private' })
          break
      }
      return scope ? name : undefined
    },
    [projectUuid, projectIconUrl, tenantIconUrl]
  )

  const onChangeTextValue = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setName(event.target.value)
      if (event.target.value.length > 56) {
        seterrorMessage(
          intl.formatMessage({
            id: 'savedUIState.edit.message.validate.length',
          })
        )
      } else {
        seterrorMessage('')
      }
    },
    []
  )

  const clear = () => {
    setName('')
    seterrorMessage('')
  }

  return (
    <StoredUiStateEditorDialog open={dialogOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          marginTop: '20px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            color: colorPalette.monotone[10],
            fontSize: '15px',
            fontWeight: 500,
          }}
        >
          {draft && draft.type === 'Create'
            ? intl.formatMessage({ id: 'savedUIState.save' })
            : intl.formatMessage({
                id: 'storedUiState.edit.saved.searchCondition',
              })}
        </Typography>
        {draft && draft.type === 'Create' && (
          <Typography
            sx={{
              fontSize: FontSize.MEDIUM,
              fontWeight: 400,
              color: colorPalette.monotone[5],
              marginLeft: '20px',
            }}
          >
            {intl.formatMessage({
              id: 'savedUIState.create.search.condition.subtitle',
            })}
          </Typography>
        )}
      </DialogTitle>
      <Divider sx={{ margin: '0 24px' }} />
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '10px 10px 20px 10px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: colorPalette.monotone[10],
              fontSize: '15px',
              fontWeight: 500,
              width: '100px',
            }}
          >
            {intl.formatMessage({ id: 'savedUIState.scope' })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '15px',
            }}
          >
            <Box sx={{ marginRight: '8px' }}>{scopeIcon(scope)}</Box>
            <Typography sx={{ color: colorPalette.monotone[4] }}>
              {scopeName(scope)}
            </Typography>
          </Box>
          <ClickAwayListener onClickAway={handleClose}>
            <Button
              colorPattern="skyBlue"
              sx={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                '&:hover': { background: 'transparent' },
              }}
              onClick={event => {
                if (openScopeSelectPopper) {
                  setAnchorEl(undefined)
                  setOpenScopeSelectPopper(false)
                } else {
                  setAnchorEl(event.currentTarget)
                  setOpenScopeSelectPopper(true)
                }
              }}
            >
              <Typography sx={{ color: colorPalette.skyBlue[8] }}>
                {intl.formatMessage({ id: 'savedUIState.scope.change' })}
              </Typography>
              <Popper
                open={openScopeSelectPopper}
                anchorEl={anchorEl}
                sx={{
                  zIndex: 1300,
                  boxShadow: '0px 1px 1px #7B8CAA80',
                  background: colorPalette.monotone[0],
                  borderRadius: '4px',
                  width: '250px',
                }}
              >
                <List sx={{ alignItems: 'start' }}>
                  <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                    <ListItemButton
                      sx={{
                        width: '100%',
                        padding: '3px 5px',
                        '&:hover': {
                          background: colorPalette.skyBlue[0],
                          borderRadius: '4px',
                        },
                      }}
                      onClick={() => {
                        setScope('USER')
                      }}
                    >
                      <Box sx={{ marginRight: '8px' }}>{scopeIcon('USER')}</Box>
                      <Typography sx={{ color: colorPalette.monotone[4] }}>
                        {scopeName('USER')}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        width: '100%',
                        padding: '3px 5px',
                        '&:hover': {
                          background: colorPalette.skyBlue[0],
                          borderRadius: '4px',
                        },
                      }}
                      onClick={() => setScope('TENANT')}
                    >
                      <Box sx={{ marginRight: '8px' }}>
                        {scopeIcon('TENANT')}
                      </Box>
                      <Typography sx={{ color: colorPalette.monotone[4] }}>
                        {scopeName('TENANT')}
                      </Typography>
                    </ListItemButton>
                    <ListItemButton
                      sx={{
                        width: '100%',
                        padding: '3px 5px',
                        '&:hover': {
                          background: colorPalette.skyBlue[0],
                          borderRadius: '4px',
                        },
                      }}
                      onClick={() => setScope('CROSS_PROJECT')}
                    >
                      <Box sx={{ marginRight: '8px' }}>
                        {scopeIcon('CROSS_PROJECT')}
                      </Box>
                      <Typography sx={{ color: colorPalette.monotone[4] }}>
                        {scopeName('CROSS_PROJECT')}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Popper>
            </Button>
          </ClickAwayListener>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '20px 10px',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: colorPalette.monotone[10],
              fontSize: '15px',
              fontWeight: 500,
              width: '100px',
            }}
          >
            {intl.formatMessage({
              id: 'savedUIState.saved.search.condition.name',
            })}
          </Typography>
          <TextField
            id="name"
            variant="outlined"
            required={true}
            value={name}
            onChange={onChangeTextValue}
            autoFocus={true}
            sx={{
              width: '524px',
              marginLeft: '20px',
            }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={clear}>
                  <ClearRoundedIcon
                    sx={{
                      color: colorPalette.monotone[4],
                      height: '13px',
                      width: '13px',
                    }}
                  />
                </IconButton>
              ),
            }}
            placeholder={intl.formatMessage({
              id: 'savedUIState.input.search.condition.name',
            })}
            helperText={errorMessage}
            error={!!errorMessage}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', marginBottom: '25px' }}>
        {draft && draft.type === 'Update' && (
          <Button
            colorPattern="pink"
            onClick={() => {
              onDelete(uuid)
              onClose()
            }}
            sx={{
              border: `1px solid ${colorPalette.pink[6]}`,
              color: colorPalette.pink[6],
              background: colorPalette.monotone[0],
              height: '44px',
              width: '140px',
              fontSize: '14px',
              fontWeight: 700,
              '&:hover': {
                color: colorPalette.pink[3],
                border: `1px solid ${colorPalette.pink[3]}`,
              },
            }}
          >
            <Typography>
              {intl.formatMessage({
                id: 'savedUIState.delete.search.condition',
              })}
            </Typography>
          </Button>
        )}
        <Button
          colorPattern="monotone"
          onClick={onClose}
          sx={{
            border: `1px solid ${colorPalette.monotone[2]}`,
            height: '44px',
            width: '140px',
            fontSize: '14px',
            fontWeight: 700,
            background: colorPalette.monotone[0],
            color: colorPalette.monotone[4],
            '&:hover': {
              border: `1px solid ${colorPalette.monotone[4]}`,
            },
          }}
        >
          <Typography>
            {intl.formatMessage({ id: 'savedUIState.edit.button.cancel' })}
          </Typography>
        </Button>
        <Button
          colorPattern="skyBlue"
          variant="filled"
          onClick={submit}
          disabled={!name || !!errorMessage}
          sx={{
            color: colorPalette.monotone[0],
            border: 'none',
            height: '44px',
            width: '140px',
            fontSize: '14px',
            fontWeight: 700,
          }}
        >
          <Typography>
            {draft && draft.type === 'Create'
              ? intl.formatMessage({ id: 'savedUIState.add.button.save' })
              : intl.formatMessage({
                  id: 'savedUIState.edit.button.overwrite',
                })}
          </Typography>
        </Button>
      </DialogActions>
    </StoredUiStateEditorDialog>
  )
}
