import objects from '../../utils/objects'

const DEFAULT_LOCALE = 'en'

export interface I18nLabel {
  languageCode: string
  name: string
}

export interface I18n {
  readonly __global: boolean
  readonly __uuid: string
  labels: I18nLabel[]
}

export const parse = (src: string[]): I18n => {
  let json = {} as I18n
  let labels: I18nLabel[] = []
  src.forEach(v => {
    const idxColon = v.indexOf(':')
    if (idxColon < 0) {
      return
    }
    const isLabel = !v.startsWith('__')
    const key = v.substring(isLabel ? 0 : 2, idxColon).trim()
    const value = v.substring(idxColon + 1, v.length).trim()
    if (isLabel) {
      labels.push({
        languageCode: key,
        name: value,
      })
    } else {
      objects.setValue(json, key, value)
    }
  })
  objects.setValue(json, 'labels', labels)
  return json
}

export const getLabel = (i18n?: I18n): string | undefined => {
  if (!i18n || !i18n.labels || i18n.labels.length === 0) {
    return undefined
  }
  const locale = navigator.language.split(/[-_]/)[0]
  const label = i18n.labels.find(label => label.languageCode === locale)
  if (label) {
    return label.name
  }
  const labelEn = i18n.labels.find(
    label => label.languageCode === DEFAULT_LOCALE
  )
  if (labelEn) {
    return labelEn.name
  }
  return undefined
}
