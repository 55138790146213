import React from 'react'
import { Button } from '@mui/material'
import { ColDef, RowNode } from 'ag-grid-community'
import RoundChatIcon from '../../../../../../../components/icons/RoundChatIcon'
import { Color, IconColor } from '../../../../../../../../styles/commonStyles'
import { CommentSummary } from '../../../../../../../../store/comments'
import './style.scss'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import ActionDial from './ActionDial'
import TimelineIcon from '@mui/icons-material/TimelineRounded'
import Tooltip from '../../../../../../../components/tooltips/Tooltip'
import { intl } from '../../../../../../../../i18n'

interface Props {
  node: RowNode
  rowIndex: number
  colDef: ColDef
  label?: string
  icon?: JSX.Element
  iconTooltip?: string
  disabled?: (row) => boolean
  onClicked: (node: RowNode, event: any) => void
  openComment?: (row) => void
  closeComment?: () => void
  commentOpened?: (row) => boolean
  getCommentSummary?: (row) => CommentSummary | undefined
  getSpeedDialActions?: (node: RowNode) => SpeedDialActionProps[]
  addRowActions?: (row) => void
  onClickProgressReportIcon?: (row) => void
}

const AnnotationHasData = () => {
  return <div className="sevend-ag-cell-detail__annotation-has-comment-mark" />
}

const IconButton = ({
  children,
  id,
  disabled,
  onClick,
}: {
  id?: string
  disabled?: boolean
  onClick: (event?: any) => void
} & React.PropsWithChildren) => (
  <button
    id={id}
    disabled={disabled}
    onClick={onClick}
    className="sevend-ag-cell-detail__icon-button"
  >
    {children}
  </button>
)

const OpenDetailCell = ({
  node,
  rowIndex,
  label,
  icon,
  iconTooltip,
  disabled,
  onClicked,
  commentOpened,
  openComment,
  closeComment,
  getCommentSummary,
  getSpeedDialActions,
  addRowActions,
  onClickProgressReportIcon,
}: Props) => {
  if (node.data.isViewOnly || node.data.isTotal) {
    return <></>
  }
  const commentSummary = getCommentSummary
    ? getCommentSummary(node.data)
    : undefined
  const showDetailButton = !disabled || !disabled(node.data)
  let detailButton: JSX.Element | undefined = undefined
  if (label) {
    detailButton = (
      <Button
        id={`column-detail-${rowIndex}`}
        className="sevend-ag-cell-detail__detail-label"
        onClick={(event: any) => onClicked(node, event)}
        disabled={node.data.isAdded}
      >
        {label}
      </Button>
    )
  } else if (icon) {
    detailButton = (
      <Tooltip message={iconTooltip}>
        <IconButton
          id={`column-detail-${rowIndex}`}
          onClick={(event: any) => onClicked(node, event)}
          disabled={node.data.isAdded}
        >
          {icon}
        </IconButton>
      </Tooltip>
    )
  }
  return (
    <div className="sevend-ag-cell-detail">
      {showDetailButton && <>{detailButton}</>}
      {openComment && closeComment && commentOpened && (
        <div>
          <RoundChatIcon
            className="sevend-ag-cell-detail__chat-icon"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              if (commentOpened && commentOpened(node.data)) {
                closeComment()
              } else {
                openComment(node.data)
              }
            }}
            style={{
              color:
                commentOpened && commentOpened(node.data)
                  ? Color.MAIN
                  : IconColor.GREY,
              fill:
                commentOpened && commentOpened(node.data) ? Color.MAIN : 'none',
            }}
          />
          {commentSummary?.hasComment && <AnnotationHasData />}
        </div>
      )}
      {addRowActions && (
        <AddRoundedIcon
          className="sevend-ag-cell-detail__chat-icon"
          onClick={event => {
            addRowActions(node.data)
            event.stopPropagation()
          }}
          style={{
            color: IconColor.GREY,
            fill: Color.GREY,
          }}
        />
      )}
      {getSpeedDialActions && (
        <ActionDial node={node} getSpeedDialActions={getSpeedDialActions} />
      )}
      {onClickProgressReportIcon && (
        <Tooltip
          message={intl.formatMessage({ id: 'report.showProgressReport' })}
        >
          <IconButton
            onClick={() => {
              onClickProgressReportIcon(node.data)
            }}
          >
            <TimelineIcon className="sevend-ag-cell-detail__progress-report-icon" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export type SpeedDialActionProps = {
  icon: JSX.Element
  tooltipTitle: string
  onClick: () => void
}

export default OpenDetailCell
