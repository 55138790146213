import React, { useEffect } from 'react'
import { Color, IconColor } from '../../../../../styles/commonStyles'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { CommentSummary } from '../../../../../store/comments'
import RoundChatIcon from '../../../../components/icons/RoundChatIcon'
import store, { AllState } from '../../../../../store'
import { closeComment, openComment } from '../../../../../store/information'
import { connect } from 'react-redux'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../../Comment/CommentHeaderWbsItem'
import { getWbsItemFunctionUuid } from '../../../../../lib/functions/wbsItem'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

type StateProps = {
  informationIsOpen: boolean
  currentDataUuid?: string
}

const mapStateToProps = (state: AllState) => ({
  informationIsOpen: state.information.open,
  currentDataUuid: state.information.commentProps?.dataUuid,
})

interface Props extends StateProps, ICellRendererParams {
  getCommentSummary: (row) => CommentSummary | undefined
  getWbsItem: (row) => any
  addRow: (node: RowNode) => void
}

export const TicketActionCellRenderer = connect(mapStateToProps)(
  ({
    node,
    data,
    informationIsOpen,
    currentDataUuid,
    getCommentSummary,
    getWbsItem,
    addRow,
  }: Props) => {
    const commentSummary = getCommentSummary(data)
    const wbsItem = getWbsItem(data)
    const commentOpened = !!currentDataUuid && currentDataUuid === wbsItem?.uuid

    useEffect(() => {
      if (!informationIsOpen) {
        store.dispatch(closeComment())
      }
    }, [informationIsOpen])

    return (
      <div className="sevend-ag-cell-detail">
        <div>
          <RoundChatIcon
            className="sevend-ag-cell-detail__chat-icon"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              if (commentOpened) {
                store.dispatch(closeComment())
              } else {
                store.dispatch(
                  openComment({
                    applicationFunctionUuid: getWbsItemFunctionUuid()!,
                    dataUuid: wbsItem?.uuid,
                    projectUuid: wbsItem?.projectUuid,
                    headerComponents: [
                      <CommentHeaderWbsItem
                        key={1}
                        wbsItem={mapRowDataForCommentHeader(wbsItem)}
                      />,
                    ],
                  })
                )
              }
            }}
            style={{
              color: commentOpened ? Color.MAIN : IconColor.GREY,
              fill: commentOpened ? Color.MAIN : 'none',
            }}
          />
          {commentSummary?.hasComment && (
            <div className="sevend-ag-cell-detail__annotation-has-comment-mark" />
          )}
        </div>
        <AddRoundedIcon
          className="sevend-ag-cell-detail__chat-icon"
          onClick={event => {
            addRow(node)
            event.stopPropagation()
          }}
          style={{
            color: IconColor.GREY,
            fill: Color.GREY,
          }}
        />
      </div>
    )
  }
)
