import { useCallback, useMemo } from 'react'
import {
  BurndownChartCalendar,
  BurndownChartData,
  BurndownChartResourceData,
  BurndownChartSimulationVariables,
  BurndownChartSummary,
} from '../model'
import { WbsPivotValue } from '../../../../domain/value-object/WbsPivot'
import {
  aggregateByDateBucket,
  calcBurnDownDateBucket,
} from '../../../components/charts/utils/chart'
import {
  DailyWorkloadData,
  BurndownChartResult,
} from '../../../../domain/value-object/BurndownChart'
import {
  DateBucket,
  DatewiseData,
} from '../../../components/charts/model/timeSeries'

export const useBurndownChartData = (
  summary: BurndownChartSummary,
  src: BurndownChartResult | undefined,
  {
    prospectVelocity,
    prospectResourceRate,
    simulatedVelocity,
    simulatedResourceRate,
    simulatedSimulationFromDate,
  }: BurndownChartSimulationVariables,
  dateBuckets: DateBucket[],
  getValue: (v: WbsPivotValue) => number,
  getWorkloadValue: (v: number) => number,
  resourceDataSource: BurndownChartResourceData,
  calendar: BurndownChartCalendar | undefined,
  isBucketPassed: (bucket: DateBucket) => boolean
): {
  data: BurndownChartData
} => {
  const aggregate = useCallback(
    (data: DatewiseData<number>[]) =>
      aggregateByDateBucket(data, dateBuckets, (arr: number[]) =>
        arr.reduce((sum, curr) => sum + curr, 0)
      ),
    [dateBuckets]
  )

  const { scheduleData, scheduleDataBurnDown } = useMemo(() => {
    const scheduleData = aggregate(
      (src?.scheduleData || [])
        .filter(d => !!d.date)
        .map(d => ({
          date: d.date as unknown as Date,
          value: getValue(d.value),
        }))
    )
    const sumBefore = (src?.scheduleData || [])
      .filter(d => d.date < dateBuckets[0].from)
      .map(d => getValue(d.value))
      .reduce((sum, curr) => sum + curr, 0)
    const scheduleDataBurnDown = calcBurnDownDateBucket(
      scheduleData,
      summary.total - sumBefore
    )
    return { scheduleData, scheduleDataBurnDown }
  }, [aggregate, dateBuckets, getValue, src?.scheduleData, summary.total])

  const resourceData = useMemo(
    () =>
      aggregate(
        (resourceDataSource.data || []).map(d => ({
          date: d.date,
          value: getWorkloadValue(d.value),
        }))
      ),
    [aggregate, getWorkloadValue, resourceDataSource]
  )
  const scheduleVelocityData = useMemo(
    () => scheduleData.map((d, i) => ({ x: d.x, y: d.y / resourceData[i].y })),
    [resourceData, scheduleData]
  )

  const { actualAndProspectData, actualAndProspectDataBurnDown } =
    useMemo(() => {
      const actualData = aggregate(
        (src?.actualData || [])
          .filter(d => !!d.date)
          .map(d => ({
            date: d.date as unknown as Date,
            value: getValue(d.value),
          }))
      ).filter(d => isBucketPassed(d.x))
      const prospectData = aggregate(
        (resourceDataSource.data || []).map(d => ({
          date: d.date,
          value:
            getWorkloadValue(d.value) * prospectResourceRate * prospectVelocity,
        }))
      ).filter(d => !isBucketPassed(d.x))
      const actualAndProspectData = [...actualData, ...prospectData]
      const sumBefore = (src?.actualData || [])
        .filter(d => d.date < dateBuckets[0].from)
        .map(d => getValue(d.value))
        .reduce((sum, curr) => sum + curr, 0)
      const actualAndProspectDataBurnDown = calcBurnDownDateBucket(
        actualAndProspectData,
        summary.total - sumBefore
      )
      return {
        actualAndProspectData,
        actualAndProspectDataBurnDown,
      }
    }, [
      aggregate,
      src?.actualData,
      resourceDataSource,
      summary.total,
      getValue,
      isBucketPassed,
      getWorkloadValue,
      prospectResourceRate,
      prospectVelocity,
      dateBuckets,
    ])
  const actualCostData = useMemo(() => {
    const actualCostBeforeToday = aggregate(
      (src?.actualCostData || []).map(d => ({
        date: d.date,
        value: getWorkloadValue(d.value),
      }))
    ).filter(d => isBucketPassed(d.x))
    const simulatedActualCost = aggregate(
      resourceDataSource.data.map(d => ({
        date: d.date,
        value: getWorkloadValue(d.value * prospectResourceRate),
      }))
    ).filter(d => !isBucketPassed(d.x))
    return [...actualCostBeforeToday, ...simulatedActualCost]
  }, [
    aggregate,
    src?.actualCostData,
    resourceDataSource,
    getWorkloadValue,
    isBucketPassed,
    prospectResourceRate,
  ])
  const actualAndProspectVelocityData = useMemo(
    () =>
      actualAndProspectData.map((d, i) => ({
        x: d.x,
        y: d.y / actualCostData[i].y,
      })),
    [actualAndProspectData, actualCostData]
  )
  const resourceDataForSimulation = useMemo(
    () =>
      simulatedSimulationFromDate
        ? resourceDataSource.data.filter(
            d => d.date >= simulatedSimulationFromDate
          )
        : [],
    [simulatedSimulationFromDate, resourceDataSource]
  )
  const { simulatedData, simulatedDataBurnDown } = useMemo(() => {
    const simulatedData = aggregate(
      (resourceDataForSimulation || []).map(d => ({
        date: d.date,
        value:
          getWorkloadValue(d.value) * simulatedResourceRate * simulatedVelocity,
      }))
    )
    const totalWithoutMargin =
      summary.total -
      getWorkloadValue(resourceDataSource.sumBefore) *
        simulatedResourceRate *
        simulatedVelocity
    const simulatedDataBurnDown = calcBurnDownDateBucket(
      simulatedData,
      totalWithoutMargin
    )
    return { simulatedData, simulatedDataBurnDown, simulatedVelocity }
  }, [
    aggregate,
    resourceDataForSimulation,
    summary.total,
    getWorkloadValue,
    resourceDataSource.sumBefore,
    simulatedResourceRate,
    simulatedVelocity,
  ])
  const simulatedResourceData = useMemo(
    () =>
      aggregate(
        (resourceDataForSimulation || []).map(d => ({
          date: d.date,
          value: getWorkloadValue(d.value) * simulatedResourceRate,
        }))
      ),
    [
      aggregate,
      getWorkloadValue,
      simulatedResourceRate,
      resourceDataForSimulation,
    ]
  )
  const simulatedVelocityData = useMemo(
    () =>
      simulatedData.map((d, i) => ({
        x: d.x,
        y: d.y / simulatedResourceData[i].y,
      })),
    [simulatedData, simulatedResourceData]
  )
  const sumCalendarWorkingHour = useMemo(() => {
    if (!calendar) return 0
    const sumData = calendar.data
      .map(d => d.value)
      .reduce((sum, curr) => sum + curr, 0)
    return sumData + calendar.sumBefore + calendar.sumAfter
  }, [calendar])
  const deltaY = useMemo(
    () => summary.total / sumCalendarWorkingHour,
    [sumCalendarWorkingHour, summary.total]
  )
  const { idealData, idealDataBurnDown } = useMemo(() => {
    const idealData = aggregate(
      (calendar?.data || []).map(d => ({
        date: d.date,
        value: deltaY * d.value,
      }))
    )
    const idealDataBurnDown = calcBurnDownDateBucket(
      idealData,
      summary.total - (calendar?.sumBefore || 0) * deltaY
    )
    return { idealData, idealDataBurnDown }
  }, [aggregate, calendar?.data, calendar?.sumBefore, deltaY, summary.total])

  return {
    data: {
      scheduleDataBurnDown,
      scheduleData,
      resourceData,
      scheduleVelocityData,
      actualAndProspectDataBurnDown,
      actualAndProspectData,
      actualCostData,
      actualAndProspectVelocityData,
      simulatedData,
      simulatedDataBurnDown,
      simulatedResourceData,
      simulatedVelocityData,
      idealData,
      idealDataBurnDown,
    },
  }
}
