import Deliverable from '../../../lib/functions/deliverable'
import WbsItem from '../../../lib/functions/wbsItem'
import Task from '../../../lib/functions/task'
import Division from '../../../lib/functions/division'
import User from '../../../lib/functions/user'
import BusinessClient from '../../../lib/functions/businessClient'
import Position from '../../../lib/functions/position'
import ProjectMember from '../../../lib/functions/projectMember'
import Team from '../../../lib/functions/team'
import Process from '../../../lib/functions/process'
import Workgroup from '../../../lib/functions/workgroup'
import Sprint from '../../../lib/functions/sprint'
import Role from '../../../lib/functions/role'
import Ticket from '../../../lib/functions/ticket'
import TicketList from '../../../lib/functions/ticketList'

export class EntitySearchValue extends Object {
  constructor(value: any) {
    super()
    Object.assign(this, value)
  }
  toString = () => {
    return this['displayName'] || this['officialName'] || this['name']
  }
}

export default {
  Division,
  User,
  BusinessClient,
  Position,
  ProjectMember,
  Team,
  Sprint,
  WbsItem,
  Process,
  Workgroup,
  Deliverable,
  Task,
  Role,
  Ticket,
  TicketList,
}
