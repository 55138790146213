import { TwoDimensionalData } from '../../../components/charts/model/data'
import { TwoDimensionalPoint } from '../model/chart'

type DatewiseData<T> = {
  date: Date | number
  value: T
}

export const aggregateByDateTicks = <T>(
  data: DatewiseData<T>[],
  xTicks: Date[],
  sum: (_: T[]) => number | undefined //
): TwoDimensionalPoint<Date, number>[] => {
  const points: TwoDimensionalPoint<Date, number>[] = []
  for (let i = 0; i < xTicks.length; i++) {
    const x = xTicks[i]
    const xFrom = i > 0 ? x : new Date(2000, 0, 1)
    const xTo = i + 1 < xTicks.length ? xTicks[i + 1] : new Date(9999, 11, 31)
    const value = sum(
      data.filter(d => d.date >= xFrom && d.date < xTo).map(v => v.value)
    )
    if (typeof value === 'undefined') continue
    points.push({ x, y: value })
  }
  return points
}

export const cumSum = (
  lines: TwoDimensionalData<Date, number>[]
): TwoDimensionalData<Date, number>[] => {
  return lines
    .filter(v => v.x)
    .map(line => {
      const cum = lines.filter(v => v.x <= line.x)
      return {
        x: line.x,
        y: cum.map(v => v.y).reduce((a, b) => a + b, 0),
      }
    })
}
