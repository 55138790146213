type Props = {
  height?: string
  width?: string
}

const FileImportIcon = ({ height, width }: Props) => {
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4375 12V5.04375L3.1875 7.29375L2.38125 6.4875L6 2.86875L9.61875 6.4875L8.8125 7.29375L6.5625 5.04375V12H5.4375ZM0 3.80625V1.125C0 0.825 0.1125 0.5625 0.3375 0.3375C0.5625 0.1125 0.825 0 1.125 0H10.875C11.175 0 11.4375 0.1125 11.6625 0.3375C11.8875 0.5625 12 0.825 12 1.125V3.80625H10.875V1.125H1.125V3.80625H0Z"
        fill="#7B8CAA"
      />
    </svg>
  )
}

export default FileImportIcon
