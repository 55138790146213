import { useEffect, useState } from 'react'
import { getProjectPlanUuidByWbsItemUuid } from '../../../../lib/functions/projectPlan'

export const useProjectPlanRootUuid = (
  rootWbsItemUuid: string | undefined
): {
  rootProjectPlanUuid: string | undefined
} => {
  const [rootProjectPlanUuid, setRootProjectPlanUuid] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (!rootWbsItemUuid) {
      setRootProjectPlanUuid(undefined)
      return
    }
    const fetch = async () => {
      const response = await getProjectPlanUuidByWbsItemUuid(rootWbsItemUuid)
      setRootProjectPlanUuid(response)
    }
    fetch()
  }, [rootWbsItemUuid])

  return { rootProjectPlanUuid }
}
