import { ICellRendererParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { setError } from '../../lib/validation'
import validator from '../../../meta/validator'

interface Props extends ICellRendererParams {
  tooltip?: (params: ICellRendererParams) => string | undefined
  uiMeta?: FunctionProperty
  validate?: (value: any, params: ICellRendererParams) => string | undefined
}

export const DefaultCellRenderer = (props: Props) => {
  const {
    value,
    valueFormatted,
    data,
    column,
    rowIndex,
    tooltip,
    uiMeta,
    validate,
  } = props
  const [message, setMessage] = useState<string | undefined>()
  const [tooltipText, setTooltipText] = useState<string | undefined>()

  useEffect(() => {
    const err = validate
      ? validate(value, props)
      : uiMeta
      ? validator.validate(value, data, uiMeta, () => undefined)?.getMessage()
      : undefined
    setError(data, err, props)
    setMessage(err)
  }, [value])

  useEffect(() => {
    tooltip && setTooltipText(tooltip?.(props))
  }, [value])

  return (
    <Tooltip message={message ?? tooltipText}>
      <div
        id={`column-${column?.getColId()}-${rowIndex}`}
        className={'sevend-ag-cell'}
        style={{ backgroundColor: !!message ? '#FADBD8' : undefined }}
      >
        {valueFormatted ?? value}
        <FloatingEditableIcon {...props} />
      </div>
    </Tooltip>
  )
}
