import { useRef, useState } from 'react'
import _ from 'lodash'
import TenantAvatar from '../../../components/icons/TenantAvatar'
import { OrganizationDetail } from '../../../../lib/functions/tenant'
import { UserDetail } from '../../../../lib/functions/user'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../pages'
import { Box, IconButton } from '@mui/material'
import CommonsTenantMenu from '../commons/CommonsTenantMenu'
import { Directory } from '../../../../lib/commons/appFunction'
import { colorPalette } from '../../../style/colorPallete'

type Props = {
  organization?: OrganizationDetail
  enableTenantEdit: boolean
  loginUser?: UserDetail
  menu: Directory[]
}

const mapStateToProps = (state: AllState) => ({
  organization: state.tenant.organization,
  enableTenantEdit: (state.appFunction.functions || []).some(
    f => f.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.TENANT_EDIT
  ),
  loginUser: state.user.user,
})

export const TenantIcon = connect(mapStateToProps)(
  ({ organization, menu }: Props) => {
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const delaySetOpenMenu = _.debounce(setOpenMenu, 100)
    const anchorRef = useRef<HTMLButtonElement>(null)
    return (
      <Box
        onMouseEnter={() => delaySetOpenMenu(true)}
        onMouseLeave={() => delaySetOpenMenu(false)}
        sx={{ padding: '5px' }}
      >
        <IconButton
          ref={anchorRef}
          sx={{
            height: '24px',
            width: '24px',
            padding: 0,
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          <TenantAvatar
            src={organization?.iconUrl}
            variant="rounded"
            sx={{
              height: '100%',
              width: '100%',
              border: `solid ${colorPalette.skyBlue[5]} 1px`,
            }}
          />
        </IconButton>
        <CommonsTenantMenu
          openMenu={openMenu}
          anchorEl={anchorRef.current}
          menu={menu}
        />
      </Box>
    )
  }
)
