export type Url = {
  url: string
  protocol: string
  host: string
  hostname: string
  port: string
  path: string
  isLocalHost: boolean
}

export const parseUrl = (url: string): Url => {
  const match = url.match(/^([^:]*:)\/\/(([^:/?#]*)(?::([0-9]+))?)(.*)$/)!
  return {
    url,
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    path: match[5],
    isLocalHost: isLocalhost(match[3]),
  }
}

export const isProduction = 'production' === process.env.REACT_APP_ENV

export const isLocalhost = (hostname: string) =>
  hostname === process.env.REACT_APP_LOCAL_HOST

export const extractTenantAlias = (url: URL) => {
  const alias = url.host.substr(0, url.host.indexOf('.'))
  return alias === 'lobby' ? undefined : alias
}

export const extractRootDomainName = () => {
  const domainName = extractDomainName()
  const startIndex = domainName.indexOf('.')
  return domainName.substr(startIndex + 1, domainName.length - startIndex)
}

export const extractDomainName = () => {
  const hostname = window.location.hostname
  return isLocalhost(hostname) ? 'proto.sevend.net' : hostname
}

export const isSameHost = (urlStr1?: string, urlStr2?: string) => {
  if (!urlStr1 || !urlStr2) {
    return false
  }
  const url1 = parseUrl(urlStr1)
  const url2 = parseUrl(urlStr2)
  return !url1.host || !url2.host || url1.host === url2.host
}

export const getUrlQueryStringParams = ():
  | {
      [key: string]: string
    }
  | undefined => {
  let urlParamStr = window.location.search
  if (urlParamStr) {
    // Remove ? mark.
    urlParamStr = urlParamStr.substring(1)
    let params = {}

    urlParamStr.split('&').forEach(paramStr => {
      const paramParts = paramStr.split('=')
      const key = decodeURIComponent(paramParts[0])
      const value = decodeURIComponent(paramParts[1])
      if (key.includes('[') && key.includes(']')) {
        const primaryKey = key.substring(0, key.indexOf('['))
        const secondaryKey = key.substring(key.indexOf(']') + 2)
        const index = key.substring(key.indexOf('[') + 1, key.indexOf(']'))
        if (!params[primaryKey]) {
          params[primaryKey] = []
        }
        if (!params[primaryKey][index]) {
          params[primaryKey].push({})
        }
        params[primaryKey][index][secondaryKey] = value
      } else {
        params[key] = value
      }
    })
    return params
  }
  return undefined
}

export const getUrlQueryObject = (): any => {
  const params = getUrlQueryStringParams() || {}

  const struct = (key: string, parent: any, value: any) => {
    const dotPos = key.indexOf('.')
    if (dotPos <= 0) {
      parent[key] = value
      return
    }
    const childKey = key.substr(0, dotPos)
    const child = parent[childKey]
    if (!child) {
      parent[childKey] = {}
    }
    struct(key.substring(dotPos + 1), parent[childKey], value)
  }

  const root = {}
  for (let key of Object.keys(params)) {
    struct(key, root, params[key])
  }
  return root
}

export const IS_LOCAL_HOST = (() => {
  const url = new URL(window.location.href)
  return isLocalhost(url.hostname)
})()

const MAX_URL_LENGTH: number = 6500
export const isValid = (url: string): boolean => {
  return (`${window.location.origin}` + url).length <= MAX_URL_LENGTH
}
