import {
  LoginInput,
  LoginButton,
  LoginTitle,
  LoginContainer,
} from './components'
import { intl } from '../../../i18n'
import { styled } from '@mui/material'
import { extractDomainName } from '../../../utils/urls'
import { useHistory } from 'react-router'
import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react'
import { ErrorMessage } from './components/AuthErrorMessage'
import { AuthError } from '../../../error/AuthError'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'

const DomainName = styled('div')({
  display: 'flex',
  fontSize: '18px',
  color: '#243050',
})

const EmailInput = styled(LoginInput)({
  width: '444px',
})

const Grid = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '72px',
  padding: '0 16px',
  gap: '12px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
  backgroundColor: '#F7F8FA',
  width: '444px',
})

const TenantDiv = styled('div')({
  display: 'flex',
  color: '#7B8CAA',
  fontSize: '14px',
  fontWeight: '400',
})

const TenantLeft = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '296px',
})

const LoginTextBody = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
const LoginForm = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
})

export const ForgotPassword = () => {
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)
  const onInputChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }, [])
  const { confirmResetPassword } = useAuthenticateUseCase()
  const onSubmit = useCallback(async () => {
    if (!email) {
      setAuthError(new AuthError('NO_EMAIL_INPUT'))
      return
    }
    await confirmResetPassword(email)
      .then(() => {
        history.push(`/forgotPasswordSubmit/${email}`)
      })
      .catch(() => {
        setAuthError(new AuthError('NEED_RESCUE'))
      })
  }, [confirmResetPassword, email, history])
  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )
  const backToLogin = useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <LoginContainer>
      <LoginTitle>
        {intl.formatMessage({
          id: 'forgotPassword.title',
        })}
      </LoginTitle>
      {authError && <ErrorMessage authError={authError} />}
      <LoginTextBody>
        {intl.formatMessage({ id: 'forgotPassword.sendPasswordAuthCode1' })}
      </LoginTextBody>
      <LoginTextBody>
        {intl.formatMessage({
          id: 'forgotPassword.sendPasswordAuthCode2',
        })}
      </LoginTextBody>

      <LoginForm>
        <Grid>
          <TenantLeft>
            <TenantDiv>
              {intl.formatMessage({ id: 'login.selectedTenant' })}
            </TenantDiv>
            <DomainName>{extractDomainName()}</DomainName>
          </TenantLeft>
        </Grid>
        <EmailInput
          label={intl.formatMessage({ id: 'login.email' })}
          value={email}
          onChange={onInputChanged}
          onKeyDown={onEnter}
        />
      </LoginForm>
      <LoginButton color={'skyBlue'} onClick={onSubmit}>
        {intl.formatMessage({ id: 'forgotPassword.sendAuthCode' })}
      </LoginButton>
      <LoginButton color={'monotone'} onClick={backToLogin}>
        {intl.formatMessage({ id: 'back' })}
      </LoginButton>
    </LoginContainer>
  )
}
