import React from 'react'

interface Props {
  filterChanged: Function
  enterPressed: Function
  field: string
  defaultValue: string | ''
}

interface Status {
  value: string
}

class TextSearchFilter extends React.Component<Props, Status> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.updateState(this.props.defaultValue)
    }
  }

  valueChanged = event => {
    this.updateState(event.target.value)
  }

  private updateState = (value: string) => {
    this.setState({ value }, () => {
      this.props.filterChanged(this.props.field, this.state.value)
    })
  }

  keyPressed = event => {
    if (event.key === 'Enter' && this.props.enterPressed) {
      this.props.enterPressed()
    }
  }

  render() {
    return (
      <div className="ag-input-wrapper">
        <input
          type="text"
          className="ag-floating-filter-input"
          value={this.state.value}
          onChange={this.valueChanged}
          onKeyPress={this.keyPressed}
        />
      </div>
    )
  }
}

export default TextSearchFilter
