import { styled } from '@mui/system'
import {
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseRounded'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { formatBytes } from '../../../utils/attachment'
import { formatDateTime } from '../../../utils/date'

enum ImageExtentions {
  apng,
  gif,
  jpg,
  jpeg,
  jfif,
  pjpeg,
  pjp,
  png,
  svg,
  webp,
}

export const canPreview = (fileName: string): boolean => {
  const splited = fileName.split('.')
  if (splited.length === 1) return false
  const extention = splited[splited.length - 1].toLowerCase()
  if (extention in ImageExtentions) {
    return true
  }

  return false
}

interface Props {
  open: boolean
  name: string
  source: string
  size?: number
  url: string
  updatedBy: string
  updatedAt?: number
  closeHandler: () => void
  downloadHandler?: (url: string, source: string) => void
}

const DialogTitle = styled(MuiDialogTitle)({
  padding: '2px 2px 2px 16px',
})
const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const FileInfo = styled('div')({
  marginRight: '16px',
  fontSize: '10px',
})
const FileUpdatedAt = styled('div')({
  fontSize: '10px',
})
const ButtonContainer = styled('div')({
  marginLeft: 'auto',
})
const CloseIconButton = styled(IconButton)({
  marginLeft: '8px',
})

const FileViewerDialog = ({
  open,
  name,
  source,
  size,
  url,
  updatedBy,
  updatedAt,
  closeHandler,
  downloadHandler,
}: Props) => {
  return (
    <Dialog open={open} onClose={closeHandler} maxWidth="xl">
      <DialogTitle>
        <Header>
          <FileInfo>{name}</FileInfo>
          <FileInfo>{size !== undefined ? formatBytes(size) : ''}</FileInfo>
          <FileInfo>{updatedBy}</FileInfo>
          <FileUpdatedAt>{formatDateTime(updatedAt) || ''}</FileUpdatedAt>
          <ButtonContainer>
            {downloadHandler && (
              <IconButton
                size="small"
                onClick={() => {
                  downloadHandler(url, source)
                }}
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            )}
            <CloseIconButton size={'small'} onClick={closeHandler}>
              <CloseIcon color="action" />
            </CloseIconButton>
          </ButtonContainer>
        </Header>
      </DialogTitle>
      <DialogContent dividers={true}>
        <img src={url} />
      </DialogContent>
    </Dialog>
  )
}

export default FileViewerDialog
