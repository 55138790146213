import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../store'
import {
  GroupHeaderLayout,
  WbsItemAdditionalPropertyLayoutEntity,
} from '../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { useMemo, useRef } from 'react'
import { fetchWbsItemAdditionalProperties } from '../../store/wbsItemAdditionalProperty'

// Filtered by wbs item types.
export const useWbsItemAdditionalProperties = (
  projectUuid: string,
  wbsItemTypeUuids?: string[]
): {
  fetched: boolean
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity | undefined
} => {
  const stateOfProject = useSelector<
    AllState,
    | {
        fetchedAt: number
        wbsItemAdditionalProperties:
          | WbsItemAdditionalPropertyLayoutEntity
          | undefined
      }
    | undefined
  >(state => state.wbsItemAdditionalProperty[projectUuid])
  const fetching = useRef(false)
  const dispatch = useDispatch()

  const filteredByWbsItemTypes = useMemo<
    WbsItemAdditionalPropertyLayoutEntity | undefined
  >(() => {
    if (!stateOfProject || !stateOfProject.wbsItemAdditionalProperties) {
      return
    }
    fetching.current = false
    if (!wbsItemTypeUuids || wbsItemTypeUuids.length === 0) {
      return stateOfProject.wbsItemAdditionalProperties
    }
    const original = stateOfProject.wbsItemAdditionalProperties
    const groupHeaderLayouts: GroupHeaderLayout[] = []
    // Filter properties by wbs item types.
    original.groupHeaderLayouts.forEach(groupHeaderLayout => {
      if (!groupHeaderLayout.propertyLayouts) return
      const propertyLayouts = groupHeaderLayout.propertyLayouts.filter(v => {
        const associatedWbsItemTypes = v.wbsItemAdditionalProperty.wbsItemTypes
        return (
          !associatedWbsItemTypes ||
          associatedWbsItemTypes.length === 0 ||
          associatedWbsItemTypes.some(associatedWbsItemType =>
            wbsItemTypeUuids.includes(associatedWbsItemType.uuid)
          )
        )
      })
      // Ignore group headers does not have child properties.
      if (propertyLayouts.length > 0) {
        groupHeaderLayouts.push({
          groupHeader: groupHeaderLayout.groupHeader,
          displayOrder: groupHeaderLayout.displayOrder,
          propertyLayouts,
        })
      }
    })
    return {
      projectUuid: original.projectUuid,
      groupHeaderLayouts,
    }
  }, [stateOfProject, wbsItemTypeUuids])

  if (!stateOfProject?.fetchedAt && !fetching.current) {
    fetching.current = true
    dispatch(fetchWbsItemAdditionalProperties(projectUuid))
    return {
      fetched: false,
      wbsItemAdditionalProperties: undefined,
    }
  }

  if (fetching.current) {
    return {
      fetched: false,
      wbsItemAdditionalProperties: undefined,
    }
  }

  return {
    fetched: true,
    wbsItemAdditionalProperties: filteredByWbsItemTypes,
  }
}
