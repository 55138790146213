import { useCallback } from 'react'
import { AuthService } from '../../applications/ports/authService'
import { AuthProviderConfig } from '../../domain/entity/TenantEntity'
import api from '../../lib/commons/api'
import Auth from '../../lib/commons/auth'
import { useAuthRepository } from './authRepositoryAdaptor'

export const useLegacyAuthService = (): AuthService => {
  const {
    getExternalIdByEmail,
    getUserExternalServices,
    updateUserExternalService,
  } = useAuthRepository()

  const configureAuthProvider = useCallback((config: AuthProviderConfig) => {
    // do nothing.
  }, [])
  const login = useCallback(
    async (email: string, password: string) => {
      const externalId = await getExternalIdByEmail(email)
      const { isTemporaryPassword } = await Auth.login(externalId, password)
      return {
        isSucceeded: true,
        isTemporaryPassword,
      }
    },
    [getExternalIdByEmail]
  )
  const federatedLogin = useCallback((providerCode: string) => {
    Auth.federatedLogin(providerCode)
  }, [])
  const completeNewPassword = useCallback(
    async (email: string, newPassword: string) => {
      const externalId = await getExternalIdByEmail(email)
      await Auth.completeNewPassword(externalId, newPassword)
    },
    [getExternalIdByEmail]
  )
  const ensureLoggedIn = useCallback(async () => {
    // do nothing
    return false
  }, [])
  const confirmResetPassword = useCallback(async (email: string) => {
    const response = await api.functional.request(
      'POST',
      '/api/v1/users/confirm_reset_password',
      { email },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
    return {
      isSucceeded: response.json.isSuccessful,
    }
  }, [])
  const resetPasswordSubmit = useCallback(
    async (email: string, resetCode: string, newPassword: string) => {
      const externalId = await getExternalIdByEmail(email)
      await Auth.forgotPasswordSubmit(externalId, resetCode, newPassword)
    },
    [getExternalIdByEmail]
  )
  const confirmFederatedLoginUser = useCallback(async () => {
    // Do nothing.
    /*
    const session = await getCurrentSession()
    const idTokenPayload = session.getIdToken().payload
    const username = idTokenPayload['cognito:username']
    const email = idTokenPayload.email
    const identities = idTokenPayload.identities
    // If not logged in with external id provider, do nothing.
    if (!identities || !identities[0]) {
      return
    }
    const identity = identities[0]
    if (identity.providerType !== 'OIDC') return
    if (idTokenPayload.email_verified) return
    const userExternalServices = await getUserExternalServices(email)
    // If the user logged in using external service for the first time,
    // restrict the user not to log in with the usual email-and-password way.
    if (
      userExternalServices.length === 0 ||
      userExternalServices.some(v => v.code === 'COGNITO')
    ) {
      updateUserExternalService(email, username, 'AAD')
    }
    */
  }, [])

  const confirmRegistration = useCallback(
    async (userPoolId: string, userUuid: string, oneTimeToken: string) => {
      const result = await Auth.confirmRegistration(
        userPoolId,
        userUuid,
        oneTimeToken
      )
      return {
        tenantAlias: result.tenantAlias,
        temporaryPassword: result.temporaryPassword,
        status: result.status,
        email: result.email,
      }
    },
    []
  )
  const logout = useCallback(async () => {
    // do nothing.
    /*
    logoutCognito()
    endReportTenantInfo()
    endReportUserInfo()
    */
  }, [])
  const switchTenant = useCallback(async (tenantAlias: string) => {
    const result = await Auth.switchTenant(tenantAlias)
    return {
      tenantUuid: result.tenantUuid,
      rootUrl: result.rootUrl,
      alias: result.tenantAlias,
      skipAgreement: result.skipAgreement,
    }
  }, [])

  const attemptChangeEmail = useCallback(
    async (uuid: string, emailCandidate: string) => {
      const response = await api.functional.request(
        'POST',
        '/api/v1/users/attempt_change_email',
        {
          uuid,
          emailCandidate,
        }
      )
      return response.json
    },
    []
  )
  const verifyEmail = useCallback(
    async (userPoolId: string, userUuid: string, oneTimeToken: string) => {
      const response = await api.functional.request(
        'POST',
        '/api/v1/users/verify_email',
        { userUuid, oneTimeToken },
        false,
        null,
        { 'x-7d-userpoolid': userPoolId }
      )
      return response.json
    },
    []
  )
  return {
    configureAuthProvider,
    login,
    federatedLogin,
    completeNewPassword,
    ensureLoggedIn,
    confirmFederatedLoginUser,
    confirmRegistration,
    confirmResetPassword,
    resetPasswordSubmit,
    logout,
    switchTenant,
    attemptChangeEmail,
    verifyEmail,
  }
}
