import {
  Paper,
  Popover,
  styled,
  Table,
  TableBody,
  TableContainer,
  ToggleButton,
} from '@mui/material'
import {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react'
import { FontSize } from '../../../../styles/commonStyles'
import { SearchConditionProps } from '../search-components'
import { FocusableCellProps } from '../../tables/table-cells'
import { colorPalette } from '../../../style/colorPallete'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

export type SearchItemLabelPropsBase<T extends SearchConditionProps<any>> =
  Omit<T, 'colSpan'> & {
    search: () => void
  }

export type FocusableSearchItemLabelProps<
  T extends SearchConditionProps<any> & FocusableCellProps
> = Omit<SearchItemLabelPropsBase<T>, 'onEnter'>

export const usePopup = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const openPopup = useCallback(() => setOpen(true), [])
  const closePopup = useCallback(() => setOpen(false), [])
  return {
    ref,
    open,
    openPopup,
    closePopup,
  }
}

type SearchItemLabelActiveStateProps = {
  active: boolean
}

const SearchItemLabelDiv = styled('div')(() => ({
  whiteSpace: 'nowrap',
  padding: '2px 4px',
  display: 'flex',
  borderRadius: '15px',
  background: colorPalette.monotone[1],
  color: colorPalette.monotone[5],
  margin: '5px 5px',
  fontSize: FontSize.MEDIUM,
  border: `1px dashed ${colorPalette.monotone[4]}`,
  height: 'fit-content',
}))

const SearchItemLabelSpan = styled('span')({
  width: '100%',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  textTransform: 'none',
})

const SearchItemLabelToggleButton = styled(ToggleButton)(() => ({
  height: '100%',
  margin: 'auto 0',
  whiteSpace: 'nowrap',
  border: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  color: colorPalette.monotone[5],
}))

type SearchItemLabelProps = PropsWithChildren<{
  active: boolean
  onClick: () => void
  onClear: () => void
}>

export const SearchItemLabel = forwardRef(
  (
    { children, active, onClick, onClear }: SearchItemLabelProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <SearchItemLabelDiv ref={ref}>
        <WatchLaterRoundedIcon
          sx={{ color: colorPalette.monotone[4], margin: 'auto 3px' }}
        />
        <SearchItemLabelToggleButton value="#" size="small" onClick={onClick}>
          <SearchItemLabelSpan>{children}</SearchItemLabelSpan>
        </SearchItemLabelToggleButton>
        <ClearRoundedIcon
          onClick={onClear}
          sx={{ margin: 'auto 3px', color: colorPalette.monotone[4] }}
        />
      </SearchItemLabelDiv>
    )
  }
)

const SearchItemLabelPopover = styled(Popover)({
  height: '100%',
  whiteSpace: 'nowrap',
  padding: '2px 4px',
  display: 'flex',
  borderRadius: '4px',
})

const SearchItemLabelTable = styled(Table)({
  margin: '10px',
  width: 600,
  height: '100%',
})

type SearchItemLabelPopupProps = PropsWithChildren<{
  open: boolean
  anchorEl: HTMLDivElement | null
  onClose: () => void
}>

export const SearchItemLabelPopup = ({
  open,
  anchorEl,
  onClose,
  children,
}: SearchItemLabelPopupProps) => {
  return (
    <SearchItemLabelPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <TableContainer component={Paper}>
        <SearchItemLabelTable size="small">
          <TableBody>{children}</TableBody>
        </SearchItemLabelTable>
      </TableContainer>
    </SearchItemLabelPopover>
  )
}
