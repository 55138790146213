import { useCallback, useState } from 'react'
import {
  ClickAwayListener,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material'
import { RowNode } from 'ag-grid-community'
import { BackgroundColor } from '../../../../../../../../styles/commonStyles'
import './style.scss'

export type SpeedDialActionProps = {
  icon: JSX.Element
  tooltipTitle: string
  onClick: () => void
}
interface Props {
  node: RowNode
  getSpeedDialActions: (node: RowNode) => SpeedDialActionProps[]
}

const ActionDial = (props: Props) => {
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const { node, getSpeedDialActions } = props

  const onMouseOver = useCallback(() => {
    setHover(true)
  }, [setHover])
  const onMouseLeave = useCallback(() => {
    setHover(false)
  }, [setHover])
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SpeedDial
        ariaLabel={''}
        open={open}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        className="sevend-ag-cell-detail__action-dial"
        classes={{
          actions: 'sevend-ag-cell-detail__action-dial-actions',
          directionUp: 'sevend-ag-cell-detail__action-dial-direction-up',
        }}
        icon={
          <SpeedDialIcon className="sevend-ag-cell-detail__action-dial-icon" />
        }
        onClick={() => setOpen(!open)}
        direction="right"
        FabProps={{
          color: 'default',
          style: {
            background: BackgroundColor.WHITE,
            boxShadow: '0px 0px 0px #000000',
          },
        }}
      >
        {(hover || open) &&
          getSpeedDialActions(node.data).map((action, index) => (
            <SpeedDialAction
              key={index}
              icon={action.icon}
              tooltipTitle={action.tooltipTitle}
              tooltipPlacement={'bottom-start'}
              onClick={e => {
                action.onClick()
                e.stopPropagation()
              }}
            />
          ))}
      </SpeedDial>
    </ClickAwayListener>
  )
}

export default ActionDial
