import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Paper, styled } from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { colorPalette } from '../../../style/colorPallete'
import { CSSProperties, PropsWithChildren, memo } from 'react'

export type Props = PropsWithChildren<{
  id: string
  isDragOverlay?: boolean
  cardStyle?: CSSProperties
}>

const Card = memo(({ id, isDragOverlay, cardStyle, children }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: isDragOverlay ? 'grabbing' : 'default',
    ...cardStyle,
  }

  return (
    <CardPaper
      elevation={3}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <CardContent isDragOverlay={!!isDragOverlay}>{children}</CardContent>
      <DragHandle isDragOverlay={!!isDragOverlay} ref={setActivatorNodeRef}>
        <DragIndicatorIcon sx={{ color: colorPalette.monotone[3] }} />
      </DragHandle>
    </CardPaper>
  )
})

export default Card

const CardPaper = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  width: 'calc(100% - 16px)',
})

const CardContent = styled('div')<{ isDragOverlay: boolean }>(props => ({
  width: 'calc(100% - 24px)',
  padding: '8px',
  pointerEvents: props.isDragOverlay ? 'none' : 'auto',
}))

const DragHandle = styled('div')<{ isDragOverlay: boolean }>(props => ({
  cursor: 'grab',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colorPalette.monotone[1],
  pointerEvents: props.isDragOverlay ? 'none' : 'auto',
}))
