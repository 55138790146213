import { generateUuid } from '../../../utils/uuids'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { WbsItemStatus } from '../../../domain/entity/WbsItemEntity'
import { DevelopmentEventVO } from '../../../domain/value-object/development/DevelopmentEventVO'

/**
 * RowData definition
 */
export interface DevelopmentEventWbsItemStatusMappingRow extends TreeRow {
  projectUuid: string
  wbsItemType?: WbsItemTypeVO
  ticketType?: string // TODO Remove. This is used for CustomEnumCellEditor component only.
  developmentEvent?: DevelopmentEventVO
  wbsItemStatus?: WbsItemStatus
  wbsItemSubstatus?: string
}

/**
 * Row data functionality
 */
export const createNewDevelopmentEventWbsItemStatusMappingRow = (
  projectUuid: string
): DevelopmentEventWbsItemStatusMappingRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [uuid],
    uuid,
    projectUuid,
  }
}
