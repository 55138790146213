import React from 'react'
import { Avatar, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import IconVO from '../../../../../../vo/IconVO'
import TenantStorage from '../../../../../../utils/storage'
import FileUploadDialog from '../../../../../components/dialogs/FileUploadDialog'
import ImageCropDialog from '../../../../../components/dialogs/ImageCropDialog'
import AlertDialog from '../../../../../components/dialogs/AlertDialog'
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
})

interface IconCellProps extends CellPropsBase<IconVO>, WrappedComponentProps {}

interface IconCellState extends CellStateBase {
  openSelectDialog: boolean
  openCropDialog: boolean
  openAlertDialog: boolean
  cropSrc: string
  openMessage: boolean
}

class IconCell extends DataCellBase<IconVO, IconCellProps, IconCellState> {
  constructor(props) {
    super(props)
    this.state = {
      openSelectDialog: false,
      openCropDialog: false,
      openAlertDialog: false,
      cropSrc: '',
      openMessage: false,
    }
  }
  validate = (value: IconVO) => {
    return undefined
  }

  private openSelectDialog = () => {
    if (!this.props.cellDef.editable) {
      return
    }
    this.setState({ openSelectDialog: true })
  }

  private closeSelectDialog = () => {
    this.setState({ openSelectDialog: false })
  }

  private closeCropDialog = () => {
    this.setState({ openCropDialog: false })
  }

  private openAlertDialog = () => {
    this.setState({ openAlertDialog: true })
  }

  private closeAlertDialog = () => {
    this.setState({ openAlertDialog: false })
  }

  private chooseFile = acceptedFiles => {
    this.setState({
      openCropDialog: true,
      cropSrc: URL.createObjectURL(acceptedFiles[0]),
    })
  }

  private uploadFile = async croppedImage => {
    const url = await TenantStorage.uploadFile(croppedImage).then(
      response => response.json.downloadUrl
    )
    this.props.onChange(new IconVO(url))
    this.setState({
      openSelectDialog: false,
      openCropDialog: false,
      cropSrc: '',
    })
  }

  private onClickDelete = () => {
    this.props.onChange(undefined)
    this.closeAlertDialog()
  }

  render() {
    const { value, cellDef, intl } = this.props
    return (
      <RootDiv>
        <Tooltip
          title={intl.formatMessage({ id: 'iconCell.iconChange' })}
          placement="right-start"
        >
          <Avatar
            alt=""
            src={value ? value.getValue() : undefined}
            onClick={this.openSelectDialog}
            variant="circular"
          />
        </Tooltip>
        <FileUploadDialog
          open={this.state.openSelectDialog}
          title={intl.formatMessage({ id: 'iconCell.title.selectIcon' })}
          message={intl.formatMessage({
            id: 'iconCell.message.dragDropIcon',
          })}
          dropAcceptedHandler={this.chooseFile}
          multiple={false}
          accept={cellDef.uiMeta.fileFormat}
          closeHandler={this.closeSelectDialog}
        />
        <ImageCropDialog
          open={this.state.openCropDialog}
          title={intl.formatMessage({ id: 'iconCell.title.setIconArea' })}
          url={this.state.cropSrc}
          closeHandler={this.closeCropDialog}
          submitHandler={this.uploadFile}
        />
        <AlertDialog
          isOpen={this.state.openAlertDialog}
          message={intl.formatMessage({ id: 'iconCell.message.deleteIcon' })}
          submitButtonTitle={intl.formatMessage({ id: 'dialog.delete' })}
          submitHandler={this.onClickDelete}
          closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
          closeHandler={this.closeAlertDialog}
        />
        {value && (
          <Tooltip
            title={intl.formatMessage({ id: 'iconCell.iconDelete' })}
            placement="right-start"
          >
            <IconButton onClick={this.openAlertDialog}>
              <HighlightOffIcon color="action" />
            </IconButton>
          </Tooltip>
        )}
      </RootDiv>
    )
  }
}

export default injectIntl(IconCell)
