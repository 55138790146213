import {
  Box,
  CircularProgress,
  InputBase,
  Link,
  NativeSelect,
  styled,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Color } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'

export const BulkSheetFilter = styled(Box)({
  width: '100%',
  padding: '4px 6px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const FilterSelect = styled(NativeSelect)({
  height: 24,
  margin: 2,
  border: '1px solid #95a5a6',
  borderRadius: 3,
  '&.Mui-focused': {
    border: '1px solid #719ece',
    boxShadow: '0 0 2px 1px #719ece',
  },
  '&.MuiInput-root': {
    paddingLeft: 4,
    '&:hover': {
      '&::before': {
        borderBottom: 'none',
      },
    },
    '&::before': {
      border: 'none',
    },
    '&::after': {
      border: 'none',
    },
  },
})

export const FilterInput = styled(InputBase)({
  width: '100%',
  margin: 2,
  padding: '1px 2px 1px 4px',
  border: '1px solid #95a5a6',
  '&.Mui-focused': {
    border: '1px solid #719ece',
    boxShadow: '0 0 2px 1px #719ece',
  },
})

export const FilterFooter = ({ onClick, loading }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        margin: '3px 3px 0',
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {loading && <CircularProgress size={20} />}
      </Box>
      <FilterResetButton onClick={onClick} />
    </Box>
  )
}

export const FilterResetButton = ({ onClick }) => {
  return (
    <Link
      component="button"
      onClick={onClick}
      sx={{
        fontSize: 11,
        color: Color.MAIN,
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        margin: '0 3px',
      }}
    >
      <CloseIcon sx={{ fontSize: 11, verticalAlign: 'middle' }} />
      {intl.formatMessage({ id: 'reset' })}
    </Link>
  )
}
