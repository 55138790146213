// Ag grid options
import {
  CellClassParams,
  CellKeyDownEvent,
  CellStyle,
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellRendererParams,
  ProcessCellForExportParams,
  RowNode,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import MultiAutocompleteCell from '../../../containers/commons/AgGrid/components/cell/common/multiAutocomplete'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import {
  CustomEnumCellEditor,
  DateCellEditor,
  EntitySearchCellEditor,
  MultiAutocompleteCellEditor,
  NumberCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import { getWbsItemStatusColorCode } from '../../../../lib/functions/wbsItem'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { ProjectPlanTreeCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemName/projectPlanTreeCellRenderer'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
  FunctionProperty,
} from '../../../../lib/commons/appFunction'
import { filterValuesByCombination } from '../../../../lib/commons/customEnum'
import ProjectMember, {
  ProjectMemberProps,
} from '../../../../lib/functions/projectMember'
import {
  AttachmentCellRenderer,
  CustomEnumCellRenderer,
  DefaultCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanStatusCellRenderer,
  TicketTypeCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import Sprint, {
  SprintDetail,
  SprintStatus,
} from '../../../../lib/functions/sprint'
import DateTimeVO from '../../../../vo/DateTimeVO'
import { generateExtensionColumnDef } from '../../../containers/BulkSheetView/gridOptions/extension'
import MultiLineTextCell from '../../../containers/commons/AgGrid/components/cell/custom/multiLineText'
import { normalize } from '../../../../utils/multilineText'
import BoolExpression from '../../../../utils/boolExpression'
import { ProgressDetail } from '../../ProjectPlanNew/gridOptions/cumulation'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import DateVO from '../../../../vo/DateVO'
import { toNumber } from '../../../../utils/number'
import { MutableRefObject, useMemo } from 'react'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import {
  Impact,
  ImpactLabel,
  ImpactType,
  Probability,
  ProbabilityLabel,
  ProbabilityType,
  RiskRow,
  RiskType,
  RiskTypeLabel,
  RiskTypeType,
  Strategy,
  StrategyLabel,
  StrategyType,
} from '../risks'
import { NewWbsItemRow } from '../../ProjectPlanNew/projectPlanNew'
import { TicketActionCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/TicketActionCellRenderer'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { DetailCellFilter } from '../../../containers/commons/AgGrid/components/cell/custom/detail'
import AttachmentCellFilter from '../../../containers/commons/AgGrid/components/cell/custom/attachment/attachmentCellFilter'
import { TeamProps } from '../../../../lib/functions/team'
import { multiAutocompleteCellFilterValueFormatter } from '../../../containers/commons/AgGrid/components/cell/common/multiAutocomplete/cellEditor'
import { EntitySearchValue } from '../../../containers/meta/repositories'
import { TagCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/TagCellEditor'
import { TagCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/TagCellRenderer'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { IDateCellEditorParams } from '../../../containers/BulkSheetView/components/cellEditor/DateCellEditor'
import { useLatestComment } from '../../../page-properties/bulksheet-properties/wbsItem'

export const useRiskGridOptions = ({
  projectUuid,
  refresh,
  submitSingleRow,
  addRow,
  extensionProperties,
}: {
  projectUuid: string
  refresh: Function
  submitSingleRow: MutableRefObject<
    ((uuid: string) => Promise<boolean | undefined>) | undefined
  >
  addRow: (row: RiskRow) => void
  extensionProperties: FunctionProperty[]
}): GridOptions => {
  const extensionColumnDefs = extensionProperties.map(v =>
    generateExtensionColumnDef(projectUuid, v, {
      disableFloatingFilter: true,
    })
  ) as (ColDef | ColGroupDef)[]
  const riskTypeValuesAllowed = [
    {
      name: RiskTypeLabel[RiskType.OMISSION],
      value: RiskType.OMISSION,
    },
  ] as CustomEnumValue[]
  const probabilityValuesAllowed = [
    {
      name: ProbabilityLabel[Probability.EXTREMELY_HIGH],
      value: Probability.EXTREMELY_HIGH,
    },
    {
      name: ProbabilityLabel[Probability.HIGH],
      value: Probability.HIGH,
    },
    {
      name: ProbabilityLabel[Probability.MIDDLE],
      value: Probability.MIDDLE,
    },
    {
      name: ProbabilityLabel[Probability.LOW],
      value: Probability.LOW,
    },
    {
      name: ProbabilityLabel[Probability.EXTREMELY_LOW],
      value: Probability.EXTREMELY_LOW,
    },
  ] as CustomEnumValue[]
  const impactValuesAllowed = [
    {
      name: ImpactLabel[Impact.EXTREMELY_HIGH],
      value: Impact.EXTREMELY_HIGH,
    },
    {
      name: ImpactLabel[Impact.HIGH],
      value: Impact.HIGH,
    },
    {
      name: ImpactLabel[Impact.MIDDLE],
      value: Impact.MIDDLE,
    },
    { name: ImpactLabel[Impact.LOW], value: Impact.LOW },
    {
      name: ImpactLabel[Impact.EXTREMELY_LOW],
      value: Probability.EXTREMELY_LOW,
    },
  ] as CustomEnumValue[]
  const strategyValuesAllowed = [
    {
      name: StrategyLabel[Strategy.AVOIDANCE],
      value: Strategy.AVOIDANCE,
    },
    {
      name: StrategyLabel[Strategy.TRANSFER],
      value: Strategy.TRANSFER,
    },
    {
      name: StrategyLabel[Strategy.REDUCTION],
      value: Strategy.REDUCTION,
    },
    {
      name: StrategyLabel[Strategy.RETENTION],
      value: Strategy.RETENTION,
    },
    {
      name: StrategyLabel[Strategy.SHARING],
      value: Strategy.SHARING,
    },
  ] as CustomEnumValue[]

  const commentColDef = useLatestComment('commentSummary.latestComment')
  const gridOptions = useMemo(
    () => ({
      /**
       * contents below could be commonized.
       */
      // Styling
      groupHeaderHeight: 25,
      headerHeight: 45,
      rowHeight: 32,
      rowClassRules: {
        'ag-row-copied-or-selected-row': p =>
          p.context.copied?.some(v => v.id === p.node.id),
        'ag-row-copied-single-row': p =>
          p.context.copied?.some(v => v.id === p.node.id && v.place === 'both'),
        'ag-row-copied-start-row': p =>
          p.context.copied?.some(v => v.id === p.node.id && v.place === 'top'),
        'ag-row-copied-middle-row': p =>
          p.context.copied?.some(
            v => v.id === p.node.id && v.place === 'middle'
          ),
        'ag-row-copied-end-row': p =>
          p.context.copied?.some(
            v => v.id === p.node.id && v.place === 'bottom'
          ),
      },
      // Row actions
      rowDragManaged: false,
      rowDragMultiRow: true,
      suppressMoveWhenRowDragging: true,
      enterMovesDownAfterEdit: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column definition
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: {
        width: 150,
        editable: false,
        enableValue: false,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        menuTabs: ['filterMenuTab', 'generalMenuTab'],
        suppressSizeToFit: true,
        singleClickEdit: false,
        cellEditor: 'textEditor',
        cellRenderer: DefaultCellRenderer,
        onCellClicked: defaultOnCellClicked,
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          // When editing in Japanese, any keyboard event should be suppressed.
          if (params.editing && params.event.isComposing) {
            return true
          }
          // Prevent editing cell for DELETE or BACKSPACE
          return (
            !params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)
          )
        },
        valueSetter: (params: ValueSetterParams<RiskRow>) => {
          const field = params.colDef.field || params.colDef.colId
          if (
            !field ||
            (!params.oldValue && !params.newValue) ||
            params.oldValue === params.newValue
          ) {
            return false
          }
          objects.setValue(params.data, field, params.newValue)
          params.data.edited = true
          if (!params.data.editedData) {
            params.data.editedData = { [field]: params.oldValue }
          } else if (!params.data.editedData.hasOwnProperty(field)) {
            params.data.editedData[field] = params.oldValue
          }

          store.dispatch(requireSave())
          return true
        },
        cellClassRules: {
          'grid-edited-cell': params => {
            const { colDef, data, value } = params
            const field = colDef.field ?? colDef.colId
            if (
              !data.editedData ||
              !field ||
              !colDef.editable ||
              (typeof colDef.editable === 'function' &&
                !colDef.editable(params))
            ) {
              return false
            }
            return (
              data.editedData.hasOwnProperty(field) &&
              value !== data.editedData[field]
            )
          },
        },
      },
      columnDefs: [
        {
          field: 'drag',
          headerName: '',
          type: [ColumnType.drag],
          rowDrag: _ => true,
          cellClassRules: {
            'hover-over-can-drop': params => {
              return params.node?.id === params.context?.draggableNodeId
            },
          },
        },
        {
          field: 'rowNumber',
          type: [ColumnType.sequenceNo],
          resizable: false,
          cellRenderer: SequenceNoCellRenderer,
          valueGetter: params => (params.node?.rowIndex ?? 0) + 1,
        },
        {
          headerName: intl.formatMessage({ id: 'projectPlan.information' }),
          children: [
            {
              field: 'wbsItem.code',
              headerName: intl.formatMessage({ id: 'projectPlan.code' }),
              hide: true,
              pinned: true,
              lockPosition: 'left',
              width: 90,
              editable: params => params.data.added,
              filter: ClientSideTextFilter,
              floatingFilter: true,
            },
            {
              field: 'wbsItem.ticketType',
              headerName: intl.formatMessage({ id: 'projectPlan.ticketType' }),
              hide: true,
              pinned: true,
              lockPosition: 'left',
              width: 90,
              cellRenderer: TicketTypeCellRenderer,
              valueGetter: (params: ValueGetterParams<RiskRow>) =>
                params.data?.wbsItem?.baseWbsItemType,
            },
            {
              field: 'wbsItem.status',
              headerName: intl.formatMessage({ id: 'projectPlan.status' }),
              pinned: true,
              lockPosition: 'left',
              width: 100,
              editable: true,
              type: [ColumnType.customEnum],
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: {
                customEnumCode: 'status',
                combinedValuePath: statusCombinedValuePath,
              },
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }
                params.data.wbsItem.status =
                  params.newValue ?? WbsItemStatus.TODO

                // Update substatus
                const { combinedValuePath } = params.colDef.cellEditorParams
                const substatusOptions = filterValuesByCombination(
                  params.context['substatus'],
                  code =>
                    objects.getValue(params.data, combinedValuePath?.(code)),
                  true
                )
                objects.setValue(
                  params.data,
                  'wbsItem.substatus',
                  substatusOptions?.[0]?.value
                )
                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              cellRenderer: ProjectPlanStatusCellRenderer,
              cellStyle: params => {
                if (!params.data?.wbsItem) {
                  return { backgroundColor: '#F7F7F7' }
                }
                return {
                  backgroundColor: `${getWbsItemStatusColorCode(
                    params.data?.wbsItem.status!
                  )}`,
                }
              },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: WbsItemStatus) => v,
                getLabel: (v?: WbsItemStatus) => v,
              },
            },
            {
              field: 'wbsItem.substatus',
              headerName: intl.formatMessage({ id: 'projectPlan.substatus' }),
              pinned: true,
              hide: true,
              lockPosition: 'left',
              width: 90,
              editable: true,
              type: [ColumnType.customEnum],
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: {
                customEnumCode: 'substatus',
                combinedValuePath: statusCombinedValuePath,
              },
              valueSetter: customEnumValueSetter,
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: { customEnumCode: 'substatus' },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: string) => v,
                getLabel: (v?: string) => v,
              },
            },
            {
              field: 'action',
              headerName: 'Action',
              pinned: true,
              lockPosition: 'left',
              width: 80,
              cellRenderer: TicketActionCellRenderer,
              cellRendererParams: {
                getCommentSummary: (data?: RiskRow) => data?.commentSummary,
                getWbsItem: (data?: RiskRow) => data?.wbsItem,
                addRow: (node: RowNode<RiskRow>) => addRow(node.data!),
                openComment: true,
              },
              valueGetter: (params: ValueGetterParams<RiskRow>) =>
                params.data?.wbsItem?.uuid,
              filter: DetailCellFilter,
              floatingFilter: true,
            },
            {
              field: 'deliverableAttachmentSummary',
              headerName: intl.formatMessage({ id: 'projectPlan.attachments' }),
              pinned: true,
              lockPosition: 'left',
              width: 50,
              hide: true,
              cellRenderer: AttachmentCellRenderer,
              filter: AttachmentCellFilter,
              floatingFilter: true,
              cellRendererParams: {
                getWbsItemUuid: (node: RowNode<RiskRow>) =>
                  node.data?.wbsItem.uuid,
                getAttachmentSummary: (data: RiskRow) =>
                  data?.deliverableAttachmentSummary,
              },
            },
            {
              field: 'parentPath',
              headerName: intl.formatMessage({ id: 'risks.path' }),
              width: 150,
              pinned: true,
              lockPosition: 'left',
              filter: ClientSideTextFilter,
              floatingFilter: true,
              cellStyle: {
                direction: 'rtl',
              },
            },
            {
              field: 'parentWbsItem',
              headerName: intl.formatMessage({ id: 'risks.parentWbsItem' }),
              width: 100,
              pinned: true,
              lockPosition: 'left',
              filter: ClientSideTextFilter,
              floatingFilter: true,
              valueGetter: (params: ValueGetterParams) => {
                return params.data?.parentWbsItem?.displayName
              },
            },
            {
              // Column for exporting excel
              field: 'wbsItem.displayName',
              headerName: intl.formatMessage({ id: 'projectPlan.displayName' }),
              width: 400,
              pinned: true,
              cellRenderer: ProjectPlanTreeCellRenderer,
              cellRendererParams: {
                suppressCount: true,
                suppressDoubleClickExpand: true,
                onCloseWbsItemDetail: () => refresh(),
                beforeOpenWbsItemDetail: async uuid => {
                  const success = await submitSingleRow.current?.(uuid)
                  return success
                },
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
              editable: true,
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                return params.data?.wbsItem?.displayName ?? ''
              },
              filter: ClientSideTextFilter,
              floatingFilter: true,
            },
            {
              field: 'wbsItem.tags',
              headerName: intl.formatMessage({ id: 'projectPlan.tags' }),
              width: 90,
              editable: true,
              type: [ColumnType.multiSelect],
              cellEditor: TagCellEditor,
              cellEditorParams: {
                projectUuidExtractor: data => data.wbsItem?.projectUuid,
              },
              cellRenderer: TagCellRenderer,
              cellRendererParams: {
                uiMeta: {},
              },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueFormatter: (params: ValueFormatterParams) => {
                  return params.value
                },
                getValue: option => option?.uuid,
                getLabel: option => option?.name,
              },
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'risk' }),
          children: [
            {
              field: 'riskType',
              headerName: intl.formatMessage({ id: 'risks.riskType' }),
              width: 90,
              editable: true,
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: { valuesAllowed: riskTypeValuesAllowed },
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: { valuesAllowed: riskTypeValuesAllowed },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueGetter: ({ node }: { node: RowNode<RiskRow> }) =>
                  node.data?.riskType,
                getValue: (v?: RiskTypeType) => v,
                getLabel: (v?: RiskTypeType) =>
                  riskTypeValuesAllowed.find(o => o.value === v)?.name,
              },
            },
            {
              field: 'score',
              headerName: intl.formatMessage({ id: 'risks.score' }),
              width: 90,
              editable: false,
              cellClass: 'ag-numeric-cell',
              cellStyle: { justifyContent: 'flex-end' },
              filter: ClientSideNumberFilter,
              floatingFilter: true,
            },
            {
              field: 'probability',
              headerName: intl.formatMessage({ id: 'risks.probability' }),
              width: 90,
              editable: true,
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: { valuesAllowed: probabilityValuesAllowed },
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: { valuesAllowed: probabilityValuesAllowed },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueGetter: ({ node }: { node: RowNode<RiskRow> }) =>
                  node.data?.probability,
                getValue: (v?: ProbabilityType) => v,
                getLabel: (v?: ProbabilityType) =>
                  probabilityValuesAllowed.find(o => o.value === v)?.name,
              },
            },
            {
              field: 'impact',
              headerName: intl.formatMessage({ id: 'risks.impact' }),
              width: 90,
              editable: true,
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: { valuesAllowed: impactValuesAllowed },
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: { valuesAllowed: impactValuesAllowed },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueGetter: ({ node }: { node: RowNode<RiskRow> }) =>
                  node.data?.impact,
                getValue: (v?: ImpactType) => v,
                getLabel: (v?: ImpactType) =>
                  impactValuesAllowed.find(o => o.value === v)?.name,
              },
            },
            {
              field: 'strategy',
              headerName: intl.formatMessage({ id: 'risks.strategy' }),
              width: 90,
              editable: true,
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: { valuesAllowed: strategyValuesAllowed },
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: { valuesAllowed: strategyValuesAllowed },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueGetter: ({ node }: { node: RowNode<RiskRow> }) =>
                  node.data?.strategy,
                getValue: (v?: StrategyType) => v,
                getLabel: (v?: StrategyType) =>
                  strategyValuesAllowed.find(o => o.value === v)?.name,
              },
            },
            {
              field: 'wbsItem.description',
              headerName: intl.formatMessage({ id: 'projectPlan.description' }),
              width: 300,
              editable: true,
              cellEditor: MultiLineTextCell.cellEditor,
              cellRenderer: MultiLineTextCell.cellRenderer,
              filter: ClientSideTextFilter,
              floatingFilter: true,
            },
          ],
        },
        {
          field: 'wbsItem.priority',
          headerName: intl.formatMessage({ id: 'projectPlan.priority' }),
          width: 90,
          hide: true,
          editable: true,
          type: [ColumnType.customEnum],
          cellEditor: CustomEnumCellEditor,
          cellEditorParams: { customEnumCode: 'priority' },
          cellRenderer: CustomEnumCellRenderer,
          cellRendererParams: { customEnumCode: 'priority' },
          filter: ClientSideSelectFilter,
          floatingFilter: true,
          filterParams: {
            getValue: (v?: string) => v,
            getLabel: (v?: string) => v,
          },
        },
        {
          field: 'wbsItem.difficulty',
          headerName: intl.formatMessage({ id: 'projectPlan.difficulty' }),
          hide: true,
          width: 90,
          editable: true,
          type: [ColumnType.customEnum],
          cellEditor: CustomEnumCellEditor,
          cellEditorParams: { customEnumCode: 'difficulty' },
          cellRenderer: CustomEnumCellRenderer,
          cellRendererParams: { customEnumCode: 'difficulty' },
          filter: ClientSideSelectFilter,
          floatingFilter: true,
          filterParams: {
            getValue: (v?: string) => v,
            getLabel: (v?: string) => v,
          },
        },
        {
          headerName: intl.formatMessage({
            id: 'projectPlan.description.comment',
          }),
          children: [commentColDef],
        },
        {
          headerName: intl.formatMessage({ id: 'projectPlan.assignment' }),
          children: [
            {
              field: 'wbsItem.team',
              headerName: intl.formatMessage({ id: 'projectPlan.team' }),
              hide: true,
              width: 65,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: { entity: 'team' },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: TeamProps) => v?.uuid,
                getLabel: (v?: TeamProps) => v?.displayName,
              },
            },
            {
              field: 'wbsItem.accountable',
              headerName: intl.formatMessage({ id: 'projectPlan.accountable' }),
              hide: true,
              width: 65,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: {
                entity: 'member',
                search: (text: string) =>
                  ProjectMember.search(text, { projectUuid }),
              },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: ProjectMemberProps) => v?.uuid,
                getLabel: (v?: ProjectMemberProps) => v?.name,
              },
            },
            {
              field: 'wbsItem.responsible',
              headerName: intl.formatMessage({ id: 'projectPlan.responsible' }),
              hide: true,
              width: 65,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: {
                entity: 'member',
                search: (text: string) =>
                  ProjectMember.search(text, { projectUuid }),
              },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: ProjectMemberProps) => v?.uuid,
                getLabel: (v?: ProjectMemberProps) => v?.name,
              },
            },
            {
              field: 'wbsItem.assignee',
              headerName: intl.formatMessage({ id: 'projectPlan.assignee' }),
              width: 65,
              hide: true,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: {
                entity: 'member',
                search: (text: string) =>
                  ProjectMember.search(text, { projectUuid }),
              },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: ProjectMemberProps) => v?.uuid,
                getLabel: (v?: ProjectMemberProps) => v?.name,
              },
            },
            {
              field: 'wbsItem.watchers',
              headerName: intl.formatMessage({ id: 'projectPlan.watcher' }),
              hide: true,
              width: 120,
              editable: true,
              cellEditor: MultiAutocompleteCellEditor,
              cellEditorParams: {
                search: async (text: string) =>
                  ProjectMember.search(text, { projectUuid }),
                label: option => option.name,
              },
              cellRenderer: MultiAutocompleteCell.cellRenderer,
              cellRendererParams: { showIcon: true },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                valueFormatter: multiAutocompleteCellFilterValueFormatter,
                getValue: option => new EntitySearchValue(option).toString(),
                getLabel: option => new EntitySearchValue(option).toString(),
              },
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectPlan.storyPoint' }),
          children: [
            {
              field: 'wbsItem.estimatedStoryPoint',
              headerName: intl.formatMessage({
                id: 'projectPlan.estimatedStoryPoint',
              }),
              hide: true,
              width: 90,
              editable: true,
              cellClass: 'ag-numeric-cell',
              cellStyle: { justifyContent: 'flex-end' },
              valueParser: (params: ValueParserParams) =>
                toNumber(params.newValue),
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                const field = params.colDef.field || params.colDef.colId
                const value = toNumber(params.newValue)
                if (
                  !field ||
                  (!params.oldValue && !value) ||
                  params.oldValue === value
                ) {
                  return false
                }
                objects.setValue(params.data, field, value)
                params.data.edited = true
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }

                store.dispatch(requireSave())
                return true
              },
              filter: ClientSideNumberFilter,
              floatingFilter: true,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectPlan.estimated' }),
          children: [
            {
              field: 'wbsItem.estimatedHour',
              headerName: intl.formatMessage({
                id: 'projectPlan.estimated.task',
              }),
              width: 80,
              hide: true,
              editable: true,
              cellEditor: NumberCellEditor,
              cellClass: 'ag-numeric-cell',
              cellStyle: { justifyContent: 'flex-end' },
              valueParser: (params: ValueParserParams) =>
                toNumber(params.newValue),
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }

                const value = toNumber(params.newValue)
                params.data.wbsItem.estimatedHour = value
                  ? value *
                    (params.context.workloadUnitState?.hoursPerSelectedUnit ||
                      1)
                  : 0

                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                const w = params.data?.wbsItem
                if (!w) return undefined
                const hour = w.estimatedHour ?? 0
                const rate =
                  params.context.workloadUnitState?.hoursPerSelectedUnit || 1
                return hour / rate
              },
              valueFormatter: (params: ValueFormatterParams<RiskRow>) =>
                Number.isFinite(params.value)
                  ? parseFloat(params.value.toString()).toFixed(2)
                  : '',
              filter: ClientSideNumberFilter,
              floatingFilter: true,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
          children: [
            {
              field: 'progress.scheduledToBe',
              headerName: intl.formatMessage({
                id: 'projectOverview.scheduledToBeCompleted',
              }),
              valueGetter: params => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return ProgressDetail.of(w, params.context)
                  .scheduledToBeCompleted
              },
              valueFormatter: progressValueFormatter,
              ...cumulationCellType,
            },
            {
              field: 'progress.completed',
              headerName: intl.formatMessage({
                id: 'projectOverview.completed',
              }),
              valueGetter: params => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return ProgressDetail.of(w, params.context).completed
              },
              valueFormatter: progressValueFormatter,
              ...cumulationCellType,
            },
            {
              field: 'progress.preceding',
              headerName: intl.formatMessage({
                id: 'projectOverview.preceding',
              }),
              valueGetter: params => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return ProgressDetail.of(w, params.context).endPreceding
              },
              valueFormatter: progressValueFormatter,
              ...cumulationCellType,
            },
            {
              field: 'progress.delayed',
              headerName: intl.formatMessage({ id: 'projectOverview.delayed' }),
              valueGetter: params => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return ProgressDetail.of(w, params.context).endDelayed
              },
              valueFormatter: progressValueFormatter,
              ...cumulationCellType,
            },
            {
              field: 'progress.remaining',
              headerName: intl.formatMessage({
                id: 'projectOverview.remaining',
              }),
              valueGetter: params => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return ProgressDetail.of(w, params.context).remaining
              },
              valueFormatter: progressValueFormatter,
              ...cumulationCellType,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
          children: [
            {
              field: 'cumulation.actualHour',
              headerName: intl.formatMessage({
                id: 'projectOverview.actualHour',
              }),
              width: 90,
              hide: true,
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return
                return c.actualHour
              },
              valueFormatter: (params: ValueFormatterParams) => {
                if (params.value === undefined) {
                  return ''
                }
                return (
                  Number(params.value) /
                  (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
                ).toFixed(2)
              },
              cellStyle: (params: CellClassParams<RiskRow>) => {
                const numberStyle = { justifyContent: 'flex-end' }
                const w = params.data?.wbsItem
                const c = params.data?.cumulation
                if (!w || !c) return numberStyle
                if (0 < c.actualHour) {
                  return {
                    ...numberStyle,
                    cursor: 'pointer',
                    color: 'blue',
                  }
                }
                return numberStyle
              },
              filter: ClientSideNumberFilter,
              floatingFilter: true,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectPlan.term' }),
          children: [
            {
              field: 'wbsItem.sprint',
              headerName: intl.formatMessage({ id: 'projectPlan.sprint' }),
              hide: true,
              width: 90,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: {
                fetch: async data => {
                  const teamUuid = data?.wbsItem?.team?.uuid
                  if (!teamUuid) return []
                  return Sprint.search('', {
                    teamUuid,
                    statusList: [SprintStatus.INPROGRESS, SprintStatus.STANDBY],
                  })
                },
              },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              floatingFilter: true,
            },
            {
              field: 'wbsItem.scheduledDate.startDate',
              headerName: intl.formatMessage({
                id: 'projectPlan.scheduledDate.start',
              }),
              hide: true,
              width: 90,
              editable: true,
              cellEditor: DateCellEditor,
              type: [ColumnType.date],
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }

                const end = params.data.wbsItem?.scheduledDate?.endDate
                if (params.newValue && end) {
                  const startDate = new DateVO(params.newValue)
                  const endDate = new DateVO(end)
                  params.data.wbsItem.scheduledDate = {
                    startDate: startDate.serialize(),
                    endDate: startDate.isAfter(endDate)
                      ? startDate.serialize()
                      : endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData['wbsItem.scheduledDate.endDate'] =
                      endDate.serialize()
                  }
                }
                objects.setValue(
                  params.data,
                  'wbsItem.scheduledDate.startDate',
                  params.newValue
                )

                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              floatingFilter: true,
            },
            {
              field: 'wbsItem.scheduledDate.endDate',
              headerName: intl.formatMessage({
                id: 'projectPlan.scheduledDate.end',
              }),
              hide: true,
              width: 90,
              editable: true,
              cellEditor: DateCellEditor,
              type: [ColumnType.date],
              cellEditorParams: {
                getInitialValueOnCalendar: (params: IDateCellEditorParams) => {
                  const startDate =
                    params.data.wbsItem?.scheduledDate?.startDate
                  return startDate
                    ? dateVoService.construct(startDate)
                    : undefined
                },
              },
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }

                const start = params.data.wbsItem?.scheduledDate?.startDate
                if (params.newValue && start) {
                  const startDate = new DateVO(start)
                  const endDate = new DateVO(params.newValue)
                  params.data.wbsItem.scheduledDate = {
                    startDate: startDate.isAfter(endDate)
                      ? endDate.serialize()
                      : startDate.serialize(),
                    endDate: endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData['wbsItem.scheduledDate.startDate'] =
                      startDate.serialize()
                  }
                }
                objects.setValue(
                  params.data,
                  'wbsItem.scheduledDate.endDate',
                  params.newValue
                )

                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              floatingFilter: true,
            },
            {
              field: 'wbsItem.actualDate.startDate',
              headerName: intl.formatMessage({
                id: 'projectPlan.actualDate.start',
              }),
              width: 90,
              hide: true,
              editable: true,
              cellEditor: DateCellEditor,
              type: [ColumnType.date],
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }

                const end = params.data.wbsItem?.actualDate?.endDate
                if (params.newValue && end) {
                  const startDate = new DateVO(params.newValue)
                  const endDate = new DateVO(end)
                  params.data.wbsItem.actualDate = {
                    startDate: startDate.serialize(),
                    endDate: startDate.isAfter(endDate)
                      ? startDate.serialize()
                      : endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData['wbsItem.actualDate.endDate'] =
                      endDate.serialize()
                  }
                }
                objects.setValue(
                  params.data,
                  'wbsItem.actualDate.startDate',
                  params.newValue
                )

                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                const wbsItem = params.data?.wbsItem
                if (!wbsItem) return ''
                return wbsItem.actualDate?.startDate
              },
              cellRendererParams: {
                tooltip: (
                  params: ICellRendererParams<RiskRow>
                ): string | undefined => {
                  const wbsItem = params.data?.wbsItem
                  return wbsItem && isStartDelayed(wbsItem)
                    ? intl.formatMessage({ id: 'wbs.start.delayed' })
                    : undefined
                },
              },
              cellStyle: (params: CellClassParams<RiskRow>): CellStyle => {
                const style = { justifyContent: 'flex-end' }
                const wbsItem = params.data?.wbsItem
                if (isStartDelayed(wbsItem)) {
                  return { ...style, backgroundColor: BackgroundColor.ALERT }
                }
                return style
              },
              floatingFilter: true,
            },
            {
              field: 'wbsItem.actualDate.endDate',
              headerName: intl.formatMessage({
                id: 'projectPlan.actualDate.end',
              }),
              width: 90,
              hide: true,
              editable: true,
              cellEditor: DateCellEditor,
              type: [ColumnType.date],
              cellEditorParams: {
                getInitialValueOnCalendar: (params: IDateCellEditorParams) => {
                  const startDate = params.data.wbsItem?.actualDate?.startDate
                  return startDate
                    ? dateVoService.construct(startDate)
                    : undefined
                },
              },
              valueSetter: (params: ValueSetterParams<RiskRow>) => {
                if (
                  params.oldValue === params.newValue ||
                  !params.data.wbsItem
                ) {
                  return false
                }

                const start = params.data.wbsItem?.actualDate?.startDate
                if (params.newValue && start) {
                  const startDate = new DateVO(start)
                  const endDate = new DateVO(params.newValue)
                  params.data.wbsItem.actualDate = {
                    startDate: startDate.isAfter(endDate)
                      ? endDate.serialize()
                      : startDate.serialize(),
                    endDate: endDate.serialize(),
                  }
                  if (startDate.isAfter(endDate)) {
                    if (!params.data.editedData) {
                      params.data.editedData = {}
                    }
                    params.data.editedData['wbsItem.actualDate.startDate'] =
                      startDate.serialize()
                  }
                }
                objects.setValue(
                  params.data,
                  'wbsItem.actualDate.endDate',
                  params.newValue
                )

                params.data.edited = true
                const field = params.colDef.field ?? params.colDef.colId ?? ''
                if (!params.data.editedData) {
                  params.data.editedData = { [field]: params.oldValue }
                } else if (!params.data.editedData.hasOwnProperty(field)) {
                  params.data.editedData[field] = params.oldValue
                }
                store.dispatch(requireSave())
                return true
              },
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                const wbsItem = params.data?.wbsItem
                if (!wbsItem) return ''
                return wbsItem.actualDate?.endDate
              },
              cellRendererParams: {
                tooltip: (
                  params: ICellRendererParams<RiskRow>
                ): string | undefined => {
                  const wbsItem = params.data?.wbsItem
                  return wbsItem && isEndDelayed(wbsItem)
                    ? intl.formatMessage({ id: 'wbs.end.delayed' })
                    : undefined
                },
              },
              cellStyle: (params: CellClassParams<RiskRow>): CellStyle => {
                const style = { justifyContent: 'flex-end' }
                const wbsItem = params.data?.wbsItem
                if (isEndDelayed(wbsItem)) {
                  return { ...style, backgroundColor: BackgroundColor.ALERT }
                }
                return style
              },
              floatingFilter: true,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'changeLog' }),
          children: [
            {
              field: 'wbsItem.createdBy',
              headerName: intl.formatMessage({ id: 'createdBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                return params.data?.wbsItem?.createdBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'wbsItem.createdBy.name',
                iconUrlField: 'wbsItem.createdBy.iconUrl',
              },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: string) => v,
                getLabel: (v?: string) => v,
              },
            },
            {
              field: 'wbsItem.createdAt',
              headerName: intl.formatMessage({ id: 'createdAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
              floatingFilter: true,
            },
            {
              field: 'wbsItem.updatedBy',
              headerName: intl.formatMessage({ id: 'updatedBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams<RiskRow>) => {
                return params.data?.wbsItem?.updatedBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'wbsItem.updatedBy.name',
                iconUrlField: 'wbsItem.updatedBy.iconUrl',
              },
              filter: ClientSideSelectFilter,
              floatingFilter: true,
              filterParams: {
                getValue: (v?: string) => v,
                getLabel: (v?: string) => v,
              },
            },
            {
              field: 'wbsItem.updatedAt',
              headerName: intl.formatMessage({ id: 'updatedAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
              floatingFilter: true,
            },
          ],
        },
        ...extensionColumnDefs,
      ],
      // Copy & paste
      processCellForClipboard,
      processCellFromClipboard,
      processDataFromClipboard,
      // Footer
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['sum', 'count'],
            },
            align: 'left',
          },
        ],
      },
      localeText: {
        sum: intl.formatMessage({
          id: 'bulksheet.statusPanel.sum',
        }),
        count: intl.formatMessage({
          id: 'bulksheet.statusPanel.count',
        }),
        totalAndFilteredRows: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
        }),
        of: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
        }),
      },
    }),
    []
  )
  return gridOptions as GridOptions
}

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'wbsItem.type'
    case 'TICKET_TYPE':
      return 'wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'wbsItem.substatus'
  }
  return undefined
}

export const processCellForClipboard = ({
  column,
  value,
  node,
  context,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return value
  if (['action', 'deliverableAttachmentSummary'].includes(field!)) {
    return ''
  }
  if (field === 'wbsItem.type') {
    return value?.name
  }
  if (field === 'wbsItem.ticketType') {
    const wbsItemType = node?.data?.wbsItem.wbsItemType
    return wbsItemType?.isTicket() ? wbsItemType?.name : ''
  }
  if (field === 'wbsItem.description') {
    return `"${normalize(value ?? '')}"`
  }
  if (field === 'commentSummary.latestComment') {
    return value?.text
  }
  if (field === 'wbsItem.watchers') {
    return value?.map(v => v.name).join(',')
  }
  if (field === 'wbsItem.tags') {
    return value?.map(v => v.name).join(TAG_DELIMITER)
  }
  if (!type) {
    if (typeof value === 'object') {
      return value.name ?? value.displayName
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    return (
      getLabel(value?.nameI18n) ||
      value?.name ||
      value?.displayName ||
      value?.officialName
    )
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const option = options.find(v => v.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }
  if (type.includes(ColumnType.dateTime)) {
    return new DateTimeVO(value).format()
  }
  if (type.includes(ColumnType.multiLineText)) {
    return `"${normalize(value ?? '')}"`
  }
  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  if (!value) return value
  if (field === 'wbsItem.watchers') {
    const names = value.split(',').filter(v => !!v)
    const options = context['member']
    return options.filter(o => names.includes(o.name))
  }
  if (field === 'wbsItem.sprint') {
    const team = node?.data?.wbsItem.team
    if (!team) return undefined
    return context.sprint?.find(
      (v: SprintDetail) => v.teamUuid === team.uuid && v.name === value
    )
  }
  if (field === 'wbsItem.tags') {
    const names = value.split(TAG_DELIMITER).filter(v => !!v)
    const options = context['tag'] ?? []
    return options.filter(v => names.includes(v.name))
  }
  if (!type) return value
  if (type.includes(ColumnType.autocomplete)) {
    if (cellEditorParams.entity) {
      const options = context[cellEditorParams.entity]
      return options.find(o =>
        [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
          .filter(v => !!v)
          .includes(value)
      )
    }
    return value
  }
  if (type.includes(ColumnType.customEnum)) {
    const options: CustomEnumValue[] =
      context[cellEditorParams.customEnumCode] ?? []
    const target = options.find(o =>
      [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
    )
    return target?.value
  }
  return value
}
const cumulationCellType = {
  width: 80,
  editable: false,
  hide: true,
  cellStyle: () => {
    return {
      color: TextColor.DARK_BLACK,
      justifyContent: 'flex-end',
    }
  },
  filter: ClientSideNumberFilter,
  floatingFilter: true,
}
const progressValueFormatter = (params: ValueFormatterParams<RiskRow>) => {
  const value: number | undefined = params.value
  if (value === undefined) return ''
  return (
    ' ' +
    (
      value / (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
    ).toFixed(1)
  )
}
const customEnumValueSetter = (params: ValueSetterParams<RiskRow>) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

const today = DateVO.now()
const isStartDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledStart = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  const actualStart = actualDate?.startDate
    ? new DateVO(actualDate.startDate)
    : undefined
  return !!(
    scheduledStart &&
    wbsItem.status === WbsItemStatus.TODO &&
    ((!actualStart && today.isAfter(scheduledStart)) ||
      (actualStart && actualStart.isAfter(scheduledStart)))
  )
}

const isEndDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledEnd = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  const actualEnd = actualDate?.endDate
    ? new DateVO(actualDate.endDate)
    : undefined
  return !!(
    scheduledEnd &&
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(wbsItem.status) &&
    ((!actualEnd && today.isAfter(scheduledEnd)) ||
      (actualEnd && actualEnd.isAfter(scheduledEnd)))
  )
}
