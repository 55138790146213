import api, { APIResponse } from '../../../lib/commons/api'
import { UserDetail } from '../../../lib/functions/user'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { UnitPriceBody } from '../UnitPricePerPosition/UnitPricePerPosition'

export class UnitPricePerUserSkeleton
  implements TreeSource<UnitPricePerUserSkeleton>
{
  uuid: string
  lockVersion: number
  children: UnitPricePerUserSkeleton[]
  rootUuid: string

  user: UserDetail
  unitPrices: UnitPriceBody[]
}

export class UnitPricePerUserRowBody {
  user: UserDetail | undefined
  unitPrices: UnitPriceBody[]

  constructor(src?: UnitPricePerUserSkeleton) {
    this.user = src?.user
    this.unitPrices = src?.unitPrices ?? []
  }
}

export interface UnitPricePerUserRow extends TreeRow {
  body: UnitPricePerUserRowBody
}

export interface UnitPricePerUserItemInput {
  validFrom: string
  unitPrice: number
}

export interface UnitPricePerUserCreateInput {
  uuid: string
  userUuid: string
  unitPrices: UnitPricePerUserItemInput[]
}

export interface UnitPricePerUserUpdateInput {
  uuid: string
  lockVersion: number
  unitPrices: UnitPricePerUserItemInput[]
}

export interface UnitPricePerUserDeleteInput {
  uuid: string
  lockVersion: number
}

export interface UnitPricePerUserCrudParams {
  added: UnitPricePerUserCreateInput[]
  edited: UnitPricePerUserUpdateInput[]
  deleted: UnitPricePerUserDeleteInput[]
}

export const fetchUnitPricePerUsers = async (): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/unit_prices/users')
}

export const crudUnitPricePerUsers = async (
  params: UnitPricePerUserCrudParams
): Promise<APIResponse> => {
  return api.functional.request(
    'POST',
    '/api/v1/unit_prices/users/batch',
    params
  )
}
