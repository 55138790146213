import React from 'react'
import { styled } from '@mui/system'
import Radio from '@mui/material/Radio'
import { ColDef, ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import { getParentNode, gridRootStyle, refreshAncestors } from '../../../..'

// Styles
const RootDiv = styled('div')<{ onlyText?: boolean }>(({ onlyText }) => ({
  ...gridRootStyle,
  position: 'relative',
  marginLeft: onlyText ? '6px' : undefined,
}))

interface Props {
  value: string | number // This is the selected value, which means this is modified by user action.
  colDef: ColDef
  node: RowNode
  api: GridApi
  columnApi: ColumnApi
}

class RadioGroupCellRenderer extends React.PureComponent<Props> {
  private readonly field: string
  private readonly value: string // This is the value which corresponds to this radio button, which means this is immutable.

  constructor(props: Props) {
    super(props)
    const cellRendererParams = this.props.colDef.cellRendererParams
    this.field = cellRendererParams.field
    this.value = cellRendererParams.radioValue
  }

  private valueChanged = (event: React.ChangeEvent<{}>, checked: boolean) => {
    this.props.node.setDataValue(this.field, this.value)
    const allColumns = this.props.columnApi.getColumns() || []
    const refreshColumns = allColumns
      .filter(column => {
        const columncellRendererParams = column.getColDef().cellRendererParams

        return (
          columncellRendererParams &&
          columncellRendererParams.field === this.field
        )
      })
      .map(column => column.getColDef().field || '')
    refreshAncestors(
      this.props.api,
      refreshColumns,
      getParentNode(this.props.node)
    )
  }

  private hide = () => {
    const hide = this.props.colDef.cellRendererParams.hide

    if (typeof hide === 'function') {
      return hide(this.props.node)
    }
    return !!hide
  }

  private disabled = () => {
    const disabled = this.props.colDef.cellRendererParams.disabled

    if (typeof disabled === 'function') {
      return disabled(this.props.node)
    }
    return !!disabled
  }

  private showAggregate = () => {
    const aggregate = this.props.colDef.cellRendererParams.aggregate

    if (typeof aggregate === 'function') {
      return aggregate(
        this.props.node,
        this.props.colDef.cellRendererParams.radioValue
      )
    }
    return ''
  }

  private getBackgroundColor = () => {
    const cellRendererParams = this.props.colDef.cellRendererParams
    const backgroundColor = cellRendererParams.backgroundColor
    const radioValue = cellRendererParams.radioValue
    if (!backgroundColor) return undefined
    const color =
      typeof backgroundColor === 'function'
        ? backgroundColor(this.props.value === radioValue)
        : backgroundColor
    return { backgroundColor: color }
  }

  render() {
    if (
      typeof this.props.colDef.cellRendererParams.showText === 'function' &&
      this.props.colDef.cellRendererParams.showText(this.props.node)
    ) {
      return <RootDiv onlyText={true}>{this.props.value}</RootDiv>
    }
    const aggregate = this.showAggregate()
    return this.hide() ? (
      <RootDiv />
    ) : (
      <RootDiv style={this.getBackgroundColor()}>
        <Radio
          value={this.value}
          disabled={this.disabled()}
          checked={this.props.value === this.value}
          onChange={this.valueChanged}
        />
        {aggregate && <div>{aggregate}</div>}
      </RootDiv>
    )
  }
}

export default RadioGroupCellRenderer
