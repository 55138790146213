import React from 'react'
import { Box } from '@mui/material'
import { StyleType } from '../../../../utils/markdown'
import StyleButtonBase, { StyleButtonBaseProps } from './StyleButtonBase'
import TenantStorage from '../../../../utils/storage'
import { isImageFile, UploadedFile } from '../../../../utils/file'

interface OwnProps {
  accept?: string
  multiple?: boolean
  onChange: (files: UploadedFile[]) => void
}

type Props = OwnProps & StyleButtonBaseProps

const FileSelectStyleButtonBase = (props: Props) => {
  const fileRef = React.useRef<HTMLInputElement>(null)
  const { onChange, accept, multiple } = props
  const onClickButton = React.useCallback(() => {
    if (!fileRef.current) return
    fileRef.current.click()
  }, [onChange, accept, multiple])
  const onChangeInput = React.useCallback(
    event => {
      onChangeInputBase(event.target.files, onChange)
      if (fileRef.current?.value) {
        fileRef.current.value = ''
      }
    },
    [onChange, accept, multiple]
  )
  return (
    <Box>
      <StyleButtonBase
        {...props}
        styleType={StyleType.FILE}
        onMouseDown={onClickButton}
      />
      <input
        hidden={true}
        ref={fileRef}
        multiple={multiple}
        type="file"
        accept={!accept ? 'image/*' : accept}
        onChange={onChangeInput}
      />
    </Box>
  )
}

const onChangeInputBase = async (files, onChange) => {
  if (files) {
    const uploadedFiles = await uploadImages(Array.from(files))
    onChange(uploadedFiles)
  }
}

const uploadImages = async (files): Promise<UploadedFile[]> => {
  const imgFiles = files.filter(file => isImageFile(file))
  const results: UploadedFile[] = await Promise.all(
    imgFiles.map(file => executeUpload(file))
  )
  return results.filter(v => v.url)
}

const executeUpload = async (file): Promise<UploadedFile> => {
  const response = await TenantStorage.uploadFile(file)
  return {
    url: response.json.downloadUrl,
    name: file.name,
    mimeType: file.type,
  }
}

export default FileSelectStyleButtonBase
