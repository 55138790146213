import { useRef } from 'react'
import { intl } from '../../../../../i18n'
import FileImportIcon from '../../../../components/icons/FileImportIcon'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'

interface FileImportProps {
  onClickImport?: (data: Uint8Array) => Promise<any> | void
}

const FileImportButton = ({ onClickImport }: FileImportProps) => {
  const importInput = useRef<HTMLInputElement>()

  return (
    <BootstrapTooltip
      arrow={true}
      title={intl.formatMessage({ id: 'wbs.header.file.import' })}
    >
      <button
        style={{
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (importInput && importInput.current) {
            importInput.current.click()
          }
        }}
      >
        <FileImportIcon height={'15px'} width={'15px'} />
        <input
          type="file"
          aria-hidden="true"
          style={{ display: 'none' }}
          ref={ref => {
            importInput.current = ref || undefined
          }}
          onChange={e => {
            const { files } = e.target
            if (!files || !files[0]) return
            const reader = new FileReader()
            reader.onload = e => {
              const data = new Uint8Array(e.target!['result'] as ArrayBuffer)
              onClickImport && onClickImport(data)
            }
            reader.readAsArrayBuffer(files[0])
            e.target.value = ''
          }}
        />
      </button>
    </BootstrapTooltip>
  )
}

export default FileImportButton
