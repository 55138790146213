import { useCallback, useMemo, useState } from 'react'
import { PageArea } from '..'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendarDetail,
} from '../../../lib/functions/organizationWorkingDayCalendar'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import OrganizationWorkingDayCalendarOptions, {
  ColumnQuickFilterKey,
  OrganizationWorkingDayCalendarRow,
  OrganizationWorkingDayCalendarState,
} from './OrganizationWorkingDayCalendarOptions'
import OrganizationWorkingDayCalendarHeader from './Header'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ColDef } from 'ag-grid-community'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Collapse } from '@mui/material'
import OrganizationWorkingDayCalendarToolbar from './Toolbar'
import { APIResponse } from '../../../lib/commons/api'
import moment from 'moment'
import _ from 'lodash'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'

type SearchCondition = {
  year?: number
  month?: number
}

type OrganizationWorkingDayCalendarPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const organizationWorkingDayCalendarDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const OrganizationWorkingDayCalendar = props => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        OrganizationWorkingDayCalendarDetail,
        OrganizationWorkingDayCalendarRow,
        OrganizationWorkingDayCalendarState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchCondition, setSearchCondition] = useState<SearchCondition>({
    year: undefined,
    month: undefined,
  })
  const { toolbar, updatePageState } =
    usePageState<OrganizationWorkingDayCalendarPageState>(
      props.uuid,
      organizationWorkingDayCalendarDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const onChangeToolbar = useCallback(
    (value: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar: value })
    },
    []
  )

  const organizationWorkingDayCalendarOptions = useMemo(() => {
    const options = new OrganizationWorkingDayCalendarOptions()

    options.getAll = async (state): Promise<APIResponse> => {
      const startDate =
        state.year && state.month
          ? moment(
              `${state.year}-${_.padStart(`${state.month}`, 2, '0')}-01`
            ).toDate()
          : moment().startOf('month').toDate()
      const endDate = moment(startDate).endOf('month').toDate()

      const response = await getOrganizationWorkingDayCalendar({
        startDate,
        endDate,
      })

      setSearchCondition({
        year: startDate.getFullYear(),
        month: startDate.getMonth() + 1,
      })
      return response
    }

    options.onSearch = async (ctx, searchConditionState) => {
      setSearchCondition({
        year: searchConditionState.year,
        month: searchConditionState.month,
      })
      ctx?.setState(
        {
          year: searchConditionState.year,
          month: searchConditionState.month,
        },
        ctx.refreshDataWithLoading
      )
    }

    options.restoreSearchCondition = async (searchConditionState, ctx) => {
      options.onSearch(ctx, searchConditionState)
    }

    options.refreshConditionAndColumn = async ctx => {
      options.onSearch(ctx, {
        year: moment().year(),
        month: moment().month() + 1,
      })
    }

    return options
  }, [])

  const onSearch = useCallback(
    searchConditionState => {
      if (!bulkSheet) return
      setSearchCondition({
        year: searchConditionState.year,
        month: searchConditionState.month,
      })
      organizationWorkingDayCalendarOptions.onSearch(
        bulkSheet,
        searchConditionState
      )
    },
    [bulkSheet]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  return (
    <PageArea>
      <OrganizationWorkingDayCalendarHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        filteredColumns={filteredColumns}
        sortedColumns={sortedColumns}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onRestoreSearchCondition={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateSearchCondition
          )
        }
        onSubmit={() => bulkSheet?.onSubmit()}
        onCancel={() => bulkSheet?.onCancel()}
        year={searchCondition.year!}
        month={searchCondition.month!}
        onSearch={onSearch}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <OrganizationWorkingDayCalendarToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={organizationWorkingDayCalendarOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

export default pageComponent(OrganizationWorkingDayCalendar)
