import { styled } from '@mui/material'
import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'

export type DataHandlerButtonsProps = {
  submit: () => Promise<void>
  reload: () => Promise<void>
}

export const DataHandlerButtons = ({
  submit,
  reload,
}: DataHandlerButtonsProps) => {
  const [submitLabel, reloadLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'submit' }),
      intl.formatMessage({ id: 'reload' }),
    ],
    []
  )
  return (
    <>
      <StyledButton
        variant="filled"
        colorPattern="skyBlue"
        size="s"
        onClick={submit}
      >
        {submitLabel}
      </StyledButton>
      <StyledButton
        variant="outlined"
        colorPattern="monotone"
        size="s"
        onClick={reload}
      >
        {reloadLabel}
      </StyledButton>
    </>
  )
}

const StyledButton = styled(Button)({
  width: '100px',
})
