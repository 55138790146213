import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import API, { APIResponse } from '../commons/api'
import { UpdateRiskDeltaInput } from '../../view/pages/Risks/hooks/riskData'

export class RiskApi implements SingleSheetRepository {
  create(): Promise<APIResponse> {
    throw new Error('Not implemented.')
  }

  update(): Promise<APIResponse> {
    throw new Error('Can not use risk.update method.')
  }

  updateDelta(props: UpdateRiskDeltaInput): Promise<APIResponse> {
    return API.functional.request('PUT', '/api/v1/projects/risks/delta', props)
  }

  getDetail(props: { uuid: string }): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/risks/detail',
      {
        uuid: props.uuid,
      },
      true
    )
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/wbs_items/basic', {
      code,
    })
  }
}
