import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { RiskBasic } from '../../hooks/riskData'
import {
  Impact,
  ImpactType,
  Probability,
  ProbabilityType,
} from '../../../../Risks/risks'

type HeatMapValue = {
  count: number
  color: string
}

type HeatMapDataType = {
  [probability: ProbabilityType]: { [impact: ImpactType]: HeatMapValue }
}

export const useRiskHeatMap = (
  riskList: RiskBasic[]
): {
  data: HeatMapDataType | undefined
  getValue: (params: {
    probability: ProbabilityType
    impact: ImpactType
  }) => HeatMapValue | undefined
} => {
  const [data, setData] = useState<HeatMapDataType>()

  useEffect(() => {
    const d = {}
    const grouped: { [key: string]: number } = _.countBy(
      riskList,
      (risk: RiskBasic) => `${risk.probability}${risk.impact}`
    )
    const maxCount = Math.max(...Object.values(grouped))
    const maxValue = maxCount === 0 ? 1 : maxCount
    const [percentileHighest, percentileHigh, percentileLow] = [
      maxValue * 0.75,
      maxValue * 0.5,
      maxValue * 0.25,
    ]
    Object.values(Probability).forEach(probability => {
      Object.values(Impact).forEach(impact => {
        const filtered = riskList.filter(
          v => v.probability === probability && v.impact === impact
        )
        const count = filtered.length
        d[probability] = d[probability] ?? []
        d[probability][impact] = {
          count,
          color:
            percentileHighest < count
              ? '#F88080'
              : percentileHigh < count
              ? '#F8A7A7'
              : percentileLow < count
              ? '#FBC5C5'
              : 0 < count
              ? '#FDE5E5'
              : 'white',
        }
      })
    })
    setData(d)
  }, [riskList])

  const getValue = useCallback(
    ({
      probability,
      impact,
    }: {
      probability: ProbabilityType
      impact: ImpactType
    }): HeatMapValue | undefined => {
      return data?.[probability][impact]
    },
    [data]
  )

  return { data, getValue }
}
