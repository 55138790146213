import MultilineTextEditor from '../../../components/editors/multiline-text/MultilineTextEditor'
import { Alert, Box } from '@mui/material'
import { muiTheme } from '../../../../styles/muiTheme'
import { useEffect, useState } from 'react'

type Props = {
  externalId: string
  value: string
  dataUuid?: string
  dataLockVersion?: number
  validate: (value) => string | undefined
  onChange: (value) => void
}

const theme = muiTheme
export const MultilineTextEditorTab = ({
  externalId,
  value,
  dataUuid,
  dataLockVersion,
  validate,
  onChange,
}: Props) => {
  const [message, setMessage] = useState<string | undefined>()
  useEffect(() => {
    setMessage(validate(value))
  })
  return (
    <>
      <Box sx={{ margin: '0 5px' }} />
      <Box
        key={externalId}
        sx={{
          height: '100%',
          width: 'auto',
          overflow: 'hidden',
          opacity: 1,
          borderRadius: 5,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          position: 'relative',
          zIndex: theme.zIndex.drawer - 1,
          background: theme.palette.background.default,
          margin: '0 5px',
        }}
      >
        <MultilineTextEditor
          dataUuid={dataUuid}
          dataLockVersion={dataLockVersion}
          externalId={externalId}
          value={value}
          setValue={value => {
            onChange(value)
            setMessage(validate(value))
          }}
        />
        {message && (
          <Alert
            severity="warning"
            icon={false}
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
          >
            {message}
          </Alert>
        )}
      </Box>
    </>
  )
}
