import { Epic, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { getMeasuringTimer } from '../services/adaptors/timerRepositoryAdapter'
interface State {
  taskName: ''
  parentWbsItemName: ''
  startTime: null
  wbsItemTypeUrl: ''
  taskUuid: ''
}

enum ActionType {
  STOP = 'STOP',
  FETCH_TIMER_ITEM = 'FETCH_TIMER_ITEM',
  RECEIVED_TIMER_ITEM = 'RECEIVED_TIMER_ITEM',
}

export function stopTimer() {
  return {
    type: ActionType.STOP,
    payload: {
      taskName: '',
      parentWbsItemName: '',
      startTime: null,
      wbsItemTypeUrl: '',
      taskUuid: '',
    },
  }
}
export const fetchTimerItem = () => {
  return {
    type: ActionType.FETCH_TIMER_ITEM,
  }
}
export const fetchTimerItemEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_TIMER_ITEM),
    mergeMap(async action => {
      const measuringTimer = await getMeasuringTimer()
      return measuringTimer
    }),
    map(result => {
      const {
        taskName,
        parentWbsItemName,
        startTime,
        wbsItemTypeUrl,
        taskUuid,
      } = result
      return receivedTimerItem(
        taskName,
        parentWbsItemName,
        startTime,
        wbsItemTypeUrl,
        taskUuid
      )
    })
  )
export const receivedTimerItem = (
  taskName: string,
  parentWbsItemName: string,
  startTime: number,
  wbsItemTypeUrl: string,
  taskUuid: string
) => ({
  type: ActionType.RECEIVED_TIMER_ITEM,
  taskName,
  parentWbsItemName,
  startTime,
  wbsItemTypeUrl,
  taskUuid,
})

export const reducer = (
  state: State = {
    taskName: '',
    parentWbsItemName: '',
    startTime: null,
    wbsItemTypeUrl: '',
    taskUuid: '',
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.STOP:
      return {
        taskName: '',
        parentWbsItemName: '',
        startTime: null,
        wbsItemTypeUrl: '',
        taskUuid: '',
      }
    case ActionType.RECEIVED_TIMER_ITEM:
      return {
        ...state,
        taskName: action.taskName,
        parentWbsItemName: action.parentWbsItemName,
        startTime: action.startTime,
        wbsItemTypeUrl: action.wbsItemTypeUrl,
        taskUuid: action.taskUuid,
      }
    default:
      return state
  }
}
