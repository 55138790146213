import IconButton from '@mui/material/IconButton'
import React from 'react'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
import ExpandLess from '@mui/icons-material/ExpandLessRounded'
import { styled } from '@mui/system'
import { FontSize } from '../../../../styles/commonStyles'
import { colorPalette } from '../../../style/colorPallete'

const MenuButton = styled(IconButton)({
  fontSize: FontSize.MEDIUM,
  backgroundColor: colorPalette.skyBlue[5],
  width: 12,
  height: 56,
  padding: '9px 2px',
  '&:hover': {
    backgroundColor: colorPalette.skyBlue[5],
  },
})

interface Props {
  toggle: (event) => void
  isOpen: boolean
  attach: 'left' | 'right'
}

const ToggleButton = (props: Props) => {
  const { toggle, isOpen, attach } = props
  const buttonStyle = {
    borderRadius: attach === 'left' ? '0px 3px 3px 0px' : '3px 0px 0px 3px',
  }
  const iconStyle = {
    transform: attach === 'left' ? 'rotate(90deg)' : 'rotate(270deg)',
  }
  return (
    <MenuButton
      aria-label="Open drawer"
      size="small"
      style={buttonStyle}
      onClick={toggle}
      disableFocusRipple={true}
      disableRipple={true}
    >
      {isOpen ? (
        <ExpandMore color="secondary" style={iconStyle} />
      ) : (
        <ExpandLess color="secondary" style={iconStyle} />
      )}
    </MenuButton>
  )
}

export default ToggleButton
