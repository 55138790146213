import { useEffect, useState } from 'react'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import { SelectOption } from '../../../model/selectOption'
import { intl } from '../../../../i18n'
import { WbsPivotAggregationDomain } from '../../../../domain/value-object/WbsPivot'
import { TeamSelectOption } from '../model'

export const useTeamSelectOptions = (
  projectUuid: string,
  domain: WbsPivotAggregationDomain | undefined
): {
  teamOptions: TeamSelectOption[]
  selected: string | undefined
  onSelect: (v: string | undefined) => void
} => {
  const [selected, setSelected] = useState<string | undefined>()
  const [options, setOptions] = useState<TeamSelectOption[]>([])
  const { fetchUnderProjectPlan } = useTeamRepository()
  useEffect(() => {
    const fn = async () => {
      // TODO: Fix to switch api by domain type.
      const fetched = await fetchUnderProjectPlan(projectUuid, domain?.value)
      setOptions(
        fetched.map(e => ({
          value: e.uuid,
          label: e.displayName,
          iconUrl: e.iconUrl,
        }))
      )
    }
    fn()
  }, [projectUuid, domain])
  return { teamOptions: options, selected, onSelect: setSelected }
}
