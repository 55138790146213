import { styled } from '@mui/system'

const ColorLegendDiv = styled('div')({
  height: '10px',
  width: '10px',
})

const ColorLegend = ({ color }: { color: string }) => {
  return <ColorLegendDiv style={{ backgroundColor: color }} />
}

export default ColorLegend
