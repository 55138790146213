import _ from 'lodash'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'
import {
  PROFIT_LOSS_COLGROUP_ID_INFOMATION,
  RefreshDynamicColumnDefProps,
  financialStatementAccountsColDef,
  generalLedgerAccountsColDef,
  generateYearMonthList,
  processCellForClipboard,
  processCellFromClipboard,
  subsidiaryAccountsColDef,
} from '../../ProfitLossItems/gridOptions'
import DateVO from '../../../../vo/DateVO'
import {
  ProfitLossMemberRow,
  ProfitLossMemberRowMonthlyValueBody,
  ProfitLossMemberRowType,
} from '../ProfitLossMembersNew'
import {
  isHalfOrFullWidthNumber,
  parseFullNumberToHalf,
  roundNumber,
} from '../../../../utils/number'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { AccountCategory } from '../../ProfitLossItems/profitLossItems'
import { AUTO_COLUMN_ID } from '../../../containers/BulkSheet/const'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import objects from '../../../../utils/objects'
import { WorkTimeCustomHeader } from '../components/CustomHeader/WorkTimeCustomHeader'

const WORK_MONTH_DECIMAP_POINTO: number = 2

export const profitLossMembersGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: false,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            (data.body?.type !== ProfitLossMemberRowType.AmountBudget &&
              (!colDef.editable ||
                (typeof colDef.editable === 'function' &&
                  !colDef.editable(params))))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        profit_loss_root_infomation_group_column: (
          params: CellClassParams<ProfitLossMemberRow>
        ) =>
          !params.data?.isTotal &&
          (params.data?.body?.type ===
            ProfitLossMemberRowType.WorkMonthForecast ||
            params.data?.body?.type ===
              ProfitLossMemberRowType.AmountForecast) &&
          (params.column.getParent()?.getGroupId() ===
            PROFIT_LOSS_COLGROUP_ID_INFOMATION ||
            params.colDef.colId === AUTO_COLUMN_ID),
        profit_loss_root_row: params =>
          !params.data.isTotal &&
          isBackgroundColorChangeRow(
            params.context.accountCategory,
            params.data
          ),
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        valueGetter: params => {
          if (params.data.isTotal) {
            return params.data.body?.type ===
              ProfitLossMemberRowType.AmountForecast
              ? intl.formatMessage({ id: 'total' })
              : ''
          }
          return (params.node?.rowIndex ?? 0) + 1
        },
      },
      {
        groupId: 'information',
        headerName: intl.formatMessage({ id: 'profitLossItems.information' }),
        children: [
          {
            ...financialStatementAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.financialState?.displayName ?? '',
              }
            },
            editable: params => {
              const category = params.context.accountCategory
              return (
                !params.data.isTotal &&
                isRootRow(params.data) &&
                category === AccountCategory.OperatingExpenses
              )
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.financialState?.uuid
            },
            valueSetter: (params: ValueSetterParams) => {
              const { colDef, data, oldValue, newValue } = params
              const field = colDef.field || colDef.colId
              if (!field || (!oldValue && !newValue) || oldValue === newValue) {
                return false
              }
              const masters = params.context.masters
              const masterItem = masters?.find(v => v.uuid === newValue)
              if (!masterItem) return false
              objects.setValue(data, field, masterItem)
              if (!data.editedData) {
                data.editedData = { [field]: oldValue }
              } else if (!data.editedData.hasOwnProperty(field)) {
                data.editedData[field] = oldValue
              }

              const oldGeneralLedgerValue = data.body.generalLedger
              data.body.generalLedger = undefined
              const generalLedgerField = 'body.generalLedger'
              if (!data.editedData) {
                data.editedData = {
                  [generalLedgerField]: oldGeneralLedgerValue,
                }
              } else if (!data.editedData.hasOwnProperty(generalLedgerField)) {
                data.editedData[generalLedgerField] = oldGeneralLedgerValue
              }

              const oldSubsidiaryValue = data.body.subsidiary
              data.body.subsidiary = undefined
              const subsidiaryField = 'body.subsidiary'
              if (!data.editedData) {
                data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
              } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
                data.editedData[subsidiaryField] = oldSubsidiaryValue
              }
              params.api.forEachNode(node => {
                if (
                  node.data.parentUuid === data.uuid &&
                  !isRootRow(node.data)
                ) {
                  objects.setValue(node.data, field, masterItem)
                  node.data.body.generalLedger = undefined
                  node.data.body.subsidiary = undefined
                }
              })
              store.dispatch(requireSave())
              params.api.redrawRows()
              return true
            },
          },
          {
            ...generalLedgerAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.generalLedger?.displayName ?? '',
              }
            },
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.generalLedger?.uuid
            },
            valueSetter: (params: ValueSetterParams) => {
              const { colDef, data, oldValue, newValue } = params
              const field = colDef.field || colDef.colId
              if (!field || (!oldValue && !newValue) || oldValue === newValue) {
                return false
              }
              const masters = params.context.masters
              const masterItem = masters
                ?.find(v => v.uuid === data.body.financialState.uuid)
                ?.children.find(v => v.uuid === newValue)
              if (!masterItem) return false
              objects.setValue(data, field, masterItem)
              if (!data.editedData) {
                data.editedData = { [field]: oldValue }
              } else if (!data.editedData.hasOwnProperty(field)) {
                data.editedData[field] = oldValue
              }

              const oldSubsidiaryValue = data.body.subsidiary
              data.body.subsidiary = undefined
              const subsidiaryField = 'body.subsidiary'
              if (!data.editedData) {
                data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
              } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
                data.editedData[subsidiaryField] = oldSubsidiaryValue
              }

              params.api.forEachNode(node => {
                if (
                  node.data.parentUuid === data.uuid &&
                  !isRootRow(node.data)
                ) {
                  objects.setValue(node.data, field, masterItem)
                  node.data.body.subsidiary = undefined
                }
              })

              store.dispatch(requireSave())
              return true
            },
          },
          {
            ...subsidiaryAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.subsidiary?.displayName ?? '',
              }
            },
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.subsidiary?.uuid
            },
          },
          {
            field: 'body.user',
            headerName: intl.formatMessage({ id: 'profitLossMembers.member' }),
            width: 150,
            pinned: true,
            suppressMovable: true,
            sortable: true,
            resizable: true,
            cellRenderer: IconCellRenderer,
            cellRendererParams: params => {
              return {
                labelField: 'body.user.name',
                iconUrlField: 'body.user.iconUrl',
                iconShow: !isBeforeSameData(params),
              }
            },
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body?.user?.name ?? ''
            },
            filter: ClientSideTextFilter,
            floatingFilter: true,
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
          },
          {
            field: 'body.position',
            headerName: intl.formatMessage({
              id: 'profitLossMembers.position',
            }),
            hide: false,
            sortable: true,
            pinned: true,
            floatingFilter: true,
            filter: 'clientSideTextFilter',
            resizable: true,
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow =
                params.data as ProfitLossMemberRow
              if (!row || !!row.isTotal) return ''
              return row.body?.position?.displayName ?? ''
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
          },
          {
            field: 'body.manmonthOrAmount',
            headerName: intl.formatMessage({
              id: 'profitLossMembers.workload.amount',
            }),
            width: 80,
            hide: false,
            sortable: false,
            pinned: true,
            resizable: true,
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow = params.data
              if (!!row.isTotal) return ''
              if (
                row.body.type === ProfitLossMemberRowType.AmountBudget ||
                row.body.type === ProfitLossMemberRowType.AmountResult ||
                row.body.type === ProfitLossMemberRowType.AmountForecast
              ) {
                return intl.formatMessage({ id: 'profitLossMembers.amount' })
              } else if (
                row.body.type === ProfitLossMemberRowType.WorkMonthActual ||
                row.body.type === ProfitLossMemberRowType.WorkMonthSchedule ||
                row.body.type === ProfitLossMemberRowType.WorkMonthForecast
              ) {
                return intl.formatMessage({ id: 'profitLossMembers.manmonth' })
              }
              return ''
            },
          },
          {
            field: 'body.budgetResult',
            headerName: intl.formatMessage({ id: 'profitLossItems.type' }),
            width: 60,
            hide: false,
            sortable: false,
            pinned: true,
            resizable: true,
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow = params.data
              if (
                row.body.type === ProfitLossMemberRowType.WorkMonthSchedule ||
                row.body.type === ProfitLossMemberRowType.AmountBudget
              ) {
                return intl.formatMessage({ id: 'profitLossItems.budget' })
              } else if (
                row.body.type === ProfitLossMemberRowType.WorkMonthActual ||
                row.body.type === ProfitLossMemberRowType.AmountResult
              ) {
                return intl.formatMessage({ id: 'profitLossItems.result' })
              } else if (
                row.body.type === ProfitLossMemberRowType.AmountForecast ||
                row.body.type === ProfitLossMemberRowType.WorkMonthForecast
              ) {
                return intl.formatMessage({ id: 'profitLossMembers.forecast' })
              }
              return ''
            },
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'total' }),
            hide: false,
            sortable: false,
            pinned: true,
            floatingFilter: true,
            filter: 'clientSideNumberFilter',
            resizable: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: params => {
              return {
                numberWithCommas: true,
                decimalPoints: isWorkMonthRow(params.data)
                  ? WORK_MONTH_DECIMAP_POINTO
                  : 0,
              }
            },
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow =
                params.data as ProfitLossMemberRow
              if (row.isTotal) {
                let sumValue = 0
                const summaryType = row.body.type
                params.api.forEachNode(node => {
                  const nodeData: ProfitLossMemberRow =
                    node.data as ProfitLossMemberRow
                  if (
                    !!nodeData &&
                    !nodeData.isTotal &&
                    nodeData.body?.type === summaryType
                  ) {
                    const monthlyValues = nodeData.body?.monthlyValues ?? []
                    const tempSum = monthlyValues.reduce((prev, current) => {
                      return prev + (current.value ?? 0)
                    }, 0)
                    sumValue += tempSum
                  }
                })
                return sumValue
              }
              const monthlyValues: ProfitLossMemberRowMonthlyValueBody[] =
                row?.body?.monthlyValues ?? []
              const sum = monthlyValues.reduce((prev, current) => {
                return prev + (current.value ?? 0)
              }, 0)
              return sum
            },
            cellClass: (params: CellClassParams) => {
              const row: ProfitLossMemberRow = params.data
              return isWorkMonthRow(row) ? 'workMonthStyle' : 'numberStyle'
            },
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0',
        },
      },
      {
        id: 'workMonthStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0.00',
        },
      },
    ],
    processCellForClipboard,
    processCellFromClipboard,
  }
}

export const MONTHLY_VALUE_FIELD_PREFIX = 'body.monthlyValues.'

export const isMonthlyValueColumn = (id: string | undefined) => {
  return !!id && id.startsWith(MONTHLY_VALUE_FIELD_PREFIX)
}

export const generateYearMonthField = (date: DateVO) => {
  return MONTHLY_VALUE_FIELD_PREFIX + date.format('YYYY/MM')
}

const getMonthlyValueByType = (
  uuid: string,
  rowNodes: RowNode[] | null,
  month: DateVO,
  rowType: string
): number | undefined => {
  const node = rowNodes?.find(r => {
    const data: ProfitLossMemberRow = r.data
    return data?.body.type === rowType && data.parentUuid === uuid
  })
  if (!node) return undefined
  return getMonthlyValueByMonth(node.data, month)?.value
}

const getMonthlyValueByMonth = (
  row: ProfitLossMemberRow,
  month: DateVO
): ProfitLossMemberRowMonthlyValueBody | undefined => {
  const values = row.body?.monthlyValues ?? []
  return values.find(v => v.yearMonth === month.formatForApi())
}

export const autoCalculateMonthlyValue = (
  editRowNode: RowNode | null,
  date: DateVO,
  calcRowType: string,
  field: string,
  editRowValue: number,
  calculate: (srcValue: number, unitPrice: number) => number
): RowNode[] | undefined => {
  const parentNode = editRowNode?.parent
  if (!parentNode) throw new Error('Illegal argument')

  // get budget amount
  const budgetAmount: number | undefined = getMonthlyValueByType(
    editRowNode?.data?.parentUuid,
    parentNode.childrenAfterGroup,
    date,
    ProfitLossMemberRowType.AmountBudget
  )
  if (!budgetAmount) return undefined

  // get scheduled work month
  const scheduledWorkMonth: number | undefined = getMonthlyValueByType(
    editRowNode?.data?.parentUuid,
    parentNode.childrenAfterGroup,
    date,
    ProfitLossMemberRowType.WorkMonthSchedule
  )
  if (!scheduledWorkMonth) return undefined

  const targetRowNode = parentNode.childrenAfterGroup?.find(n => {
    const data: ProfitLossMemberRow = n.data
    return (
      data.parentUuid === editRowNode?.data?.parentUuid &&
      data?.body?.type === calcRowType
    )
  })
  if (!targetRowNode) return
  const targetRowMonthlyValue = getMonthlyValueByMonth(targetRowNode.data, date)

  const targetRow: ProfitLossMemberRow = targetRowNode.data
  const oldValue = targetRowMonthlyValue
    ? {
        value: targetRowMonthlyValue?.value,
        manualEdited: targetRowMonthlyValue?.manualEdited,
      }
    : undefined
  const newValue = roundMonthlyValue(
    calculate(editRowValue, budgetAmount / scheduledWorkMonth),
    targetRow.body.type
  )
  if (oldValue?.value === newValue && !oldValue.manualEdited) return

  if (targetRowMonthlyValue) {
    targetRowMonthlyValue.value = newValue
    targetRowMonthlyValue.manualEdited = false
  } else {
    targetRow.body.monthlyValues.push({
      yearMonth: date.formatForApi(),
      value: newValue,
      manualEdited: false,
    })
  }
  targetRow.edited = !targetRow.added
  if (!targetRow.editedData) {
    targetRow.editedData = { [field]: oldValue }
  } else if (!targetRow.editedData.hasOwnProperty(field)) {
    targetRow.editedData[field] = oldValue
  }

  if (ProfitLossMemberRowType.AmountResult === calcRowType) {
    const forecastAmountRow = parentNode.allLeafChildren.find(
      child =>
        child.data.parentUuid === targetRow.parentUuid &&
        child.data.body?.type === ProfitLossMemberRowType.AmountForecast
    )?.data
    if (forecastAmountRow) {
      updateForecastValue(targetRow, forecastAmountRow, date, newValue)
    }
    const forecastWorkRow = parentNode.allLeafChildren.find(
      child =>
        child.data.parentUuid === targetRow.parentUuid &&
        child.data.body?.type === ProfitLossMemberRowType.WorkMonthForecast
    )?.data
    if (forecastWorkRow) {
      updateForecastValue(targetRow, forecastWorkRow, date, editRowValue)
    }
  } else {
    const forecastAmountRow = parentNode.allLeafChildren.find(
      child =>
        child.data.parentUuid === targetRow.parentUuid &&
        child.data.body?.type === ProfitLossMemberRowType.AmountForecast
    )?.data
    if (forecastAmountRow) {
      updateForecastValue(targetRow, forecastAmountRow, date, editRowValue)
    }
    const forecastWorkRow = parentNode.allLeafChildren.find(
      child =>
        child.data.parentUuid === targetRow.parentUuid &&
        child.data.body?.type === ProfitLossMemberRowType.WorkMonthForecast
    )?.data
    if (forecastWorkRow) {
      updateForecastValue(targetRow, forecastWorkRow, date, newValue)
    }
  }
  return [targetRowNode]
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const columnDefs = api?.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(MONTHLY_VALUE_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(MONTHLY_VALUE_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${MONTHLY_VALUE_FIELD_PREFIX}${year}`,
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.year' },
          { year }
        ),
        children: [],
      }
    })
    const firstDayOfMonth = DateVO.now().getFirstDayOfMonth()
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const nowMonth = DateVO.now().getFirstDayOfMonth()
      const isCurrentMonth = date.isEqual(firstDayOfMonth)
      const col: ColDef = {
        field: generateYearMonthField(date),
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.month' },
          { month: date.getMonth() }
        ),
        width: 80,
        hide: false,
        pinned: false,
        suppressMovable: true,
        resizable: true,
        headerComponent: WorkTimeCustomHeader,
        cellRenderer: NumberCellRenderer,
        cellClassRules: {
          profit_loss_item_past_month: params => {
            return isBoldMonth(params.api, params.data, date, nowMonth)
          },
          profit_loss_edited_cell: params => {
            const header = params.column
              .getColId()
              .replace(MONTHLY_VALUE_FIELD_PREFIX, '')
            if (!header) return false
            const currentMonth = new DateVO(`${header}/01`)
            return (
              AccountCategory.OperatingExpenses.toString() ===
                params.context.accountCategory &&
              !!getMonthlyValueByMonth(params.data, currentMonth)?.manualEdited
            )
          },
        },
        cellStyle: (params: CellClassParams) => {
          return isCurrentMonth ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE : {}
        },
        cellRendererParams: params => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          return {
            numberWithCommas: true,
            decimalPoints: isWorkMonthRow(row) ? WORK_MONTH_DECIMAP_POINTO : 0,
          }
        },
        editable: (params: EditableCallbackParams) => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          const category = params.context.accountCategory
          return (
            !row.isTotal &&
            (ProfitLossMemberRowType.AmountResult.toString() ===
              row.body.type ||
              ProfitLossMemberRowType.WorkMonthActual.toString() ===
                row.body.type ||
              (ProfitLossMemberRowType.AmountBudget.toString() ===
                row.body.type &&
                category === AccountCategory.OperatingRevenue))
          )
        },
        valueGetter: (params: ValueGetterParams) => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          if (row.isTotal) {
            let sumValue = 0
            const summaryType = row.body.type
            api?.forEachNode(node => {
              const row: ProfitLossMemberRow = node.data as ProfitLossMemberRow
              if (!!row && !row.isTotal && row.body.type === summaryType) {
                sumValue += getMonthlyValueByMonth(row, date)?.value ?? 0
              }
            })
            return sumValue
          }
          const value = getMonthlyValueByMonth(row, date)?.value
          return value ?? ''
        },
        valueSetter: (params: ValueSetterParams) => {
          const { colDef, context, data, oldValue, newValue } = params
          const field = colDef.field || colDef.colId
          const parseVal = parseFullNumberToHalf(newValue ?? '')
          if (
            (!!newValue && !isHalfOrFullWidthNumber(newValue)) ||
            parseVal.startsWith('.')
          ) {
            return false
          }
          const numVal = Number(parseVal ?? 0)
          if (!field || oldValue === newValue || Number.isNaN(numVal)) {
            return false
          }
          const formatDate = date.formatForApi()
          const row: ProfitLossMemberRow = data
          const monthlyValue = row.body.monthlyValues.find(
            cell => cell.yearMonth === formatDate
          )

          let autoCalculated: RowNode[] | undefined = undefined
          if (
            AccountCategory.OperatingExpenses.toString() ===
            context.accountCategory
          ) {
            if (ProfitLossMemberRowType.WorkMonthActual === row.body.type) {
              autoCalculated = autoCalculateMonthlyValue(
                params.node,
                date,
                ProfitLossMemberRowType.AmountResult,
                field,
                numVal,
                (srcValue: number, unitPrice: number) => srcValue * unitPrice
              )
            } else if (ProfitLossMemberRowType.AmountResult === row.body.type) {
              autoCalculated = autoCalculateMonthlyValue(
                params.node,
                date,
                ProfitLossMemberRowType.WorkMonthActual,
                field,
                numVal,
                (srcValue: number, unitPrice: number) => srcValue / unitPrice
              )
            }
          }
          if (autoCalculated) {
            params.api.refreshCells({
              rowNodes: autoCalculated,
              force: true,
            })
          }
          if (
            monthlyValue &&
            !!monthlyValue?.manualEdited &&
            monthlyValue.value ===
              (newValue === null || newValue === undefined ? undefined : numVal)
          ) {
            return !!autoCalculated
          }
          store.dispatch(requireSave())
          const oldMonthlyValue: ProfitLossMemberRowMonthlyValueBody = {
            value: oldValue,
            manualEdited: monthlyValue?.manualEdited,
          }
          if (newValue === null || newValue === undefined) {
            row.body.monthlyValues = row.body.monthlyValues.filter(
              v => v.yearMonth !== formatDate
            )
            row.edited = !row.added
          } else if (!monthlyValue) {
            row.body.monthlyValues.push({
              yearMonth: formatDate,
              value: numVal,
              manualEdited: true,
            })
            row.edited = !row.added
          } else {
            monthlyValue.value = numVal
            monthlyValue.manualEdited = true
            row.edited = !row.added
          }
          if (!row.editedData) {
            row.editedData = { [field]: oldMonthlyValue }
          } else if (!row.editedData.hasOwnProperty(field)) {
            row.editedData[field] = oldMonthlyValue
          }

          if (isWorkMonthRow(row)) {
            return true
          }
          const rootUuid = row.treeValue[0]
          api.forEachNode((node, index) => {
            const nodeData: ProfitLossMemberRow = node.data
            if (nodeData?.uuid === rootUuid) {
              updateRootRowMonthAmountValue(row, nodeData, date, numVal)
            }
          })
          return true
        },
        cellClass: (params: CellClassParams) => {
          const row: ProfitLossMemberRow = params.data
          return isWorkMonthRow(row) ? 'workMonthStyle' : 'numberStyle'
        },
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${MONTHLY_VALUE_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => {
      newColDefs?.push(year)
    })
  }
  if (newColDefs) {
    api.setColumnDefs(newColDefs)
  }
}

const isRootRow = (data: ProfitLossMemberRow | undefined) => {
  if (!data) return false
  return data.body?.type === ProfitLossMemberRowType.WorkMonthForecast
}

export const isBeforeSameData = params => {
  return isBeforeSameNode(params.api, params.node, params.data)
}

export const isBeforeSameNode = (api, node, data) => {
  let isSame = false
  let beforeRow: RowNode | undefined = undefined
  if (!data) return false
  api.forEachNodeAfterFilterAndSort((v, _) => {
    if (v.id === node.id) {
      isSame = !!(
        !!beforeRow && beforeRow.data.body?.user?.uuid === data.body?.user?.uuid
      )
      if (isSame) return
    }
    beforeRow = v
  })
  return isSame
}

const isWorkMonthRow = (data: ProfitLossMemberRow) => {
  return isWorkMonthRowType(data?.body?.type)
}

const isWorkMonthRowType = (type: string) => {
  return (
    ProfitLossMemberRowType.WorkMonthSchedule === type ||
    ProfitLossMemberRowType.WorkMonthActual === type ||
    ProfitLossMemberRowType.WorkMonthForecast === type
  )
}

const roundMonthlyValue = (value: number, type: string) => {
  const decimalPoint = isWorkMonthRowType(type) ? WORK_MONTH_DECIMAP_POINTO : 0
  return roundNumber(value, decimalPoint)
}

export const updateForecastValue = (
  editedRow: ProfitLossMemberRow,
  forecastRow: ProfitLossMemberRow,
  targetMonth: DateVO,
  newValue: number | undefined
) => {
  const diff = DateVO.now().getFirstDayOfMonth().diff(targetMonth)
  const editedRowType = editedRow.body.type
  if (
    ((editedRowType === ProfitLossMemberRowType.AmountBudget ||
      editedRowType === ProfitLossMemberRowType.WorkMonthSchedule) &&
      0 < diff) ||
    ((editedRowType === ProfitLossMemberRowType.AmountResult ||
      editedRowType === ProfitLossMemberRowType.WorkMonthActual) &&
      diff <= 0)
  ) {
    return
  }

  const formatMonth = targetMonth.formatForApi()
  const rootMonthlyValue = forecastRow?.body.monthlyValues.find(
    cell => cell.yearMonth === formatMonth
  )
  if (!rootMonthlyValue) {
    forecastRow.body.monthlyValues.push({
      yearMonth: formatMonth,
      value: newValue,
    })
  } else if (rootMonthlyValue && rootMonthlyValue.value !== newValue) {
    rootMonthlyValue.value = newValue
  }
}

export const updateRootRowMonthAmountValue = (
  editedRow: ProfitLossMemberRow,
  rootRow: ProfitLossMemberRow,
  targetMonth: DateVO,
  newValue: number | undefined
) => {
  const diff = DateVO.now().getFirstDayOfMonth().diff(targetMonth)
  const editedRowType = editedRow.body.type
  if (
    (editedRowType !== ProfitLossMemberRowType.AmountBudget.toString() &&
      editedRowType !== ProfitLossMemberRowType.AmountResult.toString()) ||
    (editedRowType === ProfitLossMemberRowType.AmountBudget && 0 < diff) ||
    (editedRowType === ProfitLossMemberRowType.AmountResult && diff <= 0)
  ) {
    return
  }
  const formatMonth = targetMonth.formatForApi()
  const rootMonthlyValue = rootRow?.body.monthlyValues.find(
    cell => cell.yearMonth === formatMonth
  )
  if (!rootMonthlyValue) {
    rootRow.body.monthlyValues.push({
      yearMonth: formatMonth,
      value: newValue,
    })
  } else if (rootMonthlyValue && rootMonthlyValue.value !== newValue) {
    rootMonthlyValue.value = newValue
  }
}

const isBackgroundColorChangeRow = (
  accountCategory: string,
  data: ProfitLossMemberRow | undefined
): boolean => {
  if (
    !data ||
    data.body?.type === ProfitLossMemberRowType.AmountResult ||
    data.body?.type === ProfitLossMemberRowType.WorkMonthActual ||
    (accountCategory === AccountCategory.OperatingRevenue &&
      data.body?.type === ProfitLossMemberRowType.AmountBudget)
  ) {
    return false
  }
  return true
}

const isBoldMonth = (
  api: GridApi,
  data: ProfitLossMemberRow | undefined,
  currentMonth: DateVO,
  nowMonth: DateVO
): boolean => {
  if (!data) {
    return false
  }
  if (
    data.body?.type === ProfitLossMemberRowType.WorkMonthSchedule ||
    data.body?.type === ProfitLossMemberRowType.AmountBudget
  ) {
    return currentMonth.isSameOrAfter(nowMonth)
  } else if (
    data.body?.type === ProfitLossMemberRowType.WorkMonthActual ||
    data.body?.type === ProfitLossMemberRowType.AmountResult
  ) {
    return currentMonth.isBefore(nowMonth)
  } else if (data.body?.type === ProfitLossMemberRowType.WorkMonthForecast) {
    let actual: ProfitLossMemberRowMonthlyValueBody[] = []
    api.forEachNode(node => {
      if (
        data.parentUuid === node.data?.parentUuid &&
        node.data?.body?.type === ProfitLossMemberRowType.WorkMonthActual
      ) {
        actual = node.data?.body?.monthlyValues
      }
    })
    if (!actual || actual.length === 0) {
      return false
    }
    return actual.some(v => v.yearMonth === currentMonth.formatForApi())
  } else if (data.body?.type === ProfitLossMemberRowType.AmountForecast) {
    let result: ProfitLossMemberRowMonthlyValueBody[] = []
    api.forEachNode(node => {
      if (
        data.parentUuid === node.data?.parentUuid &&
        node.data?.body?.type === ProfitLossMemberRowType.AmountResult
      ) {
        result = node.data?.body?.monthlyValues
      }
    })
    if (!result || result.length === 0) {
      return false
    }
    return result.some(v => v.yearMonth === currentMonth.formatForApi())
  }
  return true
}
