import React, { CSSProperties } from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'

interface Props {
  classes?: any
  className?: string
  name?: string
  label?: string | JSX.Element
  value?: any
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  readOnly?: boolean
  color?: 'primary' | 'secondary' | 'default'
  size?: 'small' | 'medium'
  inputRef?: React.Ref<HTMLInputElement>
  onChange?: (value: any, checked: boolean) => void
  labelClassName?: string
  indeterminate?: boolean
}

const Checkbox = (props: Props) => {
  const {
    classes,
    className,
    name,
    label,
    value,
    checked,
    defaultChecked,
    disabled,
    readOnly,
    color,
    size,
    inputRef,
    onChange,
    labelClassName,
    indeterminate,
  } = props
  const checkbox = (
    <MuiCheckbox
      className={className}
      classes={classes}
      color={color}
      size={size}
      disabled={disabled}
      value={value}
      name={name}
      checked={checked}
      defaultChecked={defaultChecked}
      readOnly={readOnly}
      inputRef={inputRef}
      indeterminate={indeterminate}
      onChange={e => {
        onChange && onChange(e.target.value, e.target.checked)
      }}
    />
  )

  if (label) {
    return (
      <FormControlLabel
        className={labelClassName}
        control={checkbox}
        label={label}
      />
    )
  }
  return checkbox
}

export default Checkbox
