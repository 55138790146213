import { Redirect, useRouteMatch } from 'react-router'
import { useMemo } from 'react'

export const RedirectProjectPlanLL = () => {
  const match = useRouteMatch<{ code: string | undefined }>()
  const to = useMemo(() => {
    const code = match.params.code
    if (code) return `/projectPlan/${code}`
    return 'projectPlan'
  }, [match])

  return <Redirect to={to} />
}
