import { useCallback } from 'react'
import { AppAuthInfo } from '../../../domain/entity/TenantEntity'
import { useLegacyAuthService } from '../../../services/adaptors/legacyAuthServiceAdaptor'

type LoginResult = {
  isSucceeded: boolean
  isTemporaryPassword: boolean
}

type ConfirmResetPasswordResult = {
  isSucceeded: boolean
}
type ConfirmRegistrationResult = {
  tenantAlias: string
  email: string
  temporaryPassword: string
  status: ConfirmRegistrationStatus
}
type ConfirmRegistrationStatus =
  | 'SUCCESS'
  | 'LINK_EXPIRED'
  | 'ALREADY_AUTH'
  | 'TOKEN_MISMATCH'
  | 'USER_NOT_EXISTS'
  | 'SYSTEM_ERROR'

type SelectTenant = (tenantAlias: string) => Promise<AppAuthInfo>
type Login = (username: string, password: string) => Promise<LoginResult>
type FederatedLogin = (providerCode: string) => void
type CompleteNewPassword = (email: string, newPassword: string) => Promise<void>
type EnsureLoggedIn = () => Promise<boolean>
type ConfirmFederatedLoginUser = () => Promise<void>
type ConfirmResetPassword = (
  email: string
) => Promise<ConfirmResetPasswordResult>
type ResetPasswordSubmit = (
  email: string,
  resetCode: string,
  newPassword: string
) => Promise<void>
type ConfirmRegistration = (
  userPoolId: string,
  userUuid: string,
  oneTimeToken: string
) => Promise<ConfirmRegistrationResult>
type LogOut = () => Promise<void>
type SignOut = () => Promise<void>

type AuthenticateUseCases = {
  selectTenant: SelectTenant
  login: Login
  federatedLogin: FederatedLogin
  completeNewPassword: CompleteNewPassword
  ensureLoggedIn: EnsureLoggedIn
  confirmFederatedLoginUser: ConfirmFederatedLoginUser
  confirmResetPassword: ConfirmResetPassword
  resetPasswordSubmit: ResetPasswordSubmit
  confirmRegistration: ConfirmRegistration
  logout: LogOut
  signout: SignOut
}

export const useAuthenticateUseCase = (): AuthenticateUseCases => {
  const {
    login: _login,
    federatedLogin: _federatedLogin,
    completeNewPassword: _completeNewPassword,
    ensureLoggedIn: _ensureLoggedIn,
    logout: _logout,
    confirmFederatedLoginUser: _confirmFederatedLoginUser,
    confirmResetPassword: _confirmResetPassword,
    resetPasswordSubmit: _resetPasswordSubmit,
    confirmRegistration: _confirmRegistration,
    switchTenant,
  } = useLegacyAuthService()

  const selectTenant = useCallback(
    async (tenantAlias: string) => {
      return switchTenant(tenantAlias)
    },
    [switchTenant]
  )

  const login = useCallback(
    async (email: string, password: string) => {
      const result = await _login(email, password)
      if (!result.isSucceeded || result.isTemporaryPassword) {
        return { ...result }
      }
      return { ...result }
    },
    [_login]
  )

  const federatedLogin = useCallback(
    (providerCode: string) => {
      _federatedLogin(providerCode)
    },
    [_federatedLogin]
  )
  const completeNewPassword = useCallback(
    async (email: string, newPassword: string) => {
      await _completeNewPassword(email, newPassword)
    },
    [_completeNewPassword]
  )

  const confirmFederatedLoginUser = useCallback(async () => {
    _confirmFederatedLoginUser()
  }, [_confirmFederatedLoginUser])

  const confirmResetPassword = useCallback(
    async (email: string) => {
      return _confirmResetPassword(email)
    },
    [_confirmResetPassword]
  )
  const resetPasswordSubmit = useCallback(
    async (email: string, resetCode: string, newPassword: string) => {
      return _resetPasswordSubmit(email, resetCode, newPassword)
    },
    [_resetPasswordSubmit]
  )
  const confirmRegistration = useCallback(
    async (userPoolId: string, userUuid: string, oneTimeToken: string) => {
      return _confirmRegistration(userPoolId, userUuid, oneTimeToken)
    },
    [_confirmRegistration]
  )
  const ensureLoggedIn = useCallback(async () => {
    return _ensureLoggedIn()
  }, [_ensureLoggedIn])
  const logout = useCallback(async () => {
    _logout()
  }, [_logout])
  const signout = useCallback(async () => {
    logout()
  }, [logout])

  return {
    selectTenant,
    login,
    federatedLogin,
    completeNewPassword,
    confirmFederatedLoginUser,
    confirmResetPassword,
    resetPasswordSubmit,
    ensureLoggedIn,
    confirmRegistration,
    logout,
    signout,
  }
}
