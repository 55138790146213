import API from '../../../lib/commons/api'
import IManagementNoticeRepository from '../../../domain/repository/ManagementNoticeRepository'
import ManagementNoticeEntity from '../../../domain/entity/ManagementNoticeEntity'
import ManagementNoticeFactory from '../../../domain/factory/ManagementNoticeFactory'

const entityFactory = new ManagementNoticeFactory()

export default class ManagementNoticeRepository
  implements IManagementNoticeRepository
{
  private DEFAULT_FETCH_LIMIT: number = 10
  async getNotices(
    offset: number,
    limit: number = this.DEFAULT_FETCH_LIMIT
  ): Promise<ManagementNoticeEntity[]> {
    const response = await API.functional.request(
      'GET',
      '/api/v1/management_notices',
      {
        offset,
        limit,
      }
    )
    const values = response.json.map(v => ({
      ...v,
    }))
    return entityFactory.reconstractList(values)
  }

  getDefaultFetchLimit = (): number => {
    return this.DEFAULT_FETCH_LIMIT
  }
}
