import { useCallback, useEffect, useMemo, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../../lib/commons/uiStates'
import _ from 'lodash'
import { ColumnState } from 'ag-grid-community'
import useWindowUnloadEffect from '../../../../hooks/useWindowUnloadEffect'

export type BulkSheetState = {
  expandedRowIds?: string[]
  columnState?: ColumnState[]
  filterState?: { [key: string]: any }
}

/**
 * Control
 *
 * @param functionUuid
 * @param key
 */
export const useBulkSheetState = (
  functionUuid: string,
  key: string,
  uiStateKey?: UiStateKey
): {
  initialized: boolean
  restoredAt: number | undefined
  finishRestoring: () => void
  expandedRowIds: string[]
  expandRows: (_: string[]) => void
  collapseRows: (_: string[]) => void
  columnState: ColumnState[]
  saveColumnState: (s: ColumnState[]) => void
  filterState?: { [key: string]: any }
  saveFilterState: (s: { [key: string]: any }) => void
  saveImmediately: () => void
} => {
  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([])
  const [columnState, setColumnState] = useState<ColumnState[]>([])
  const [filterState, setFilterState] = useState<{ [key: string]: any }>({})
  const [initialized, setInitialized] = useState(false)
  const [restored, setRestored] = useState(false)
  const [restoredAt, setRestoredAt] = useState<number | undefined>(undefined)

  const stateKey = useMemo(
    () => `${uiStateKey ?? UiStateKey.BulkSheetState}-${key}`,
    [uiStateKey, key]
  )

  // Function definitions.
  const restoreState = useCallback(async () => {
    try {
      const response = await uiStates.get({
        applicationFunctionUuid: functionUuid,
        key: stateKey,
        scope: UiStateScope.User,
      })
      const fetchedUiState = response.json
      if (!fetchedUiState.value) {
        return
      }
      const fetchedState: BulkSheetState = JSON.parse(fetchedUiState.value)
      setExpandedRowIds(fetchedState.expandedRowIds ?? [])
      setColumnState(fetchedState.columnState ?? [])
      setFilterState(fetchedState.filterState ?? {})
      setRestoredAt(new Date().getTime())
    } catch (e) {
      // ignore error not to stop service while presentational api is not working correctly.
    } finally {
      setInitialized(true)
    }
  }, [functionUuid, stateKey])

  // Fetch initial row state.
  useEffect(() => {
    restoreState()
  }, [restoreState])

  const save = useCallback(
    _.debounce(state => {
      uiStates.update(
        {
          key: stateKey,
          scope: UiStateScope.User,
          value: JSON.stringify(state),
        },
        functionUuid
      )
    }, 3000),
    [functionUuid, stateKey]
  )

  // Save on row state changed, with debounce.
  useEffect(() => {
    if (!initialized) return
    save({ expandedRowIds, columnState, filterState })
  }, [save, initialized, expandedRowIds, columnState, filterState])

  const saveImmediately = useCallback(() => {
    uiStates.update(
      {
        key: stateKey,
        scope: UiStateScope.User,
        value: JSON.stringify({ expandedRowIds, columnState, filterState }),
      },
      functionUuid
    )
  }, [stateKey, expandedRowIds, columnState, filterState, functionUuid])

  // Save before window unload.
  useWindowUnloadEffect(saveImmediately, false)

  // Exposed functions.
  const expandRows = useCallback(
    (rowIds: string[]) => {
      if (!restored) return
      setExpandedRowIds(current => {
        return _.uniq([...current, ...rowIds])
      })
    },
    [restored]
  )

  const collapseRows = useCallback(
    (rowIds: string[]) => {
      if (!restored) return
      setExpandedRowIds(current => current.filter(id => !rowIds.includes(id)))
    },
    [restored]
  )

  const saveColumnState = useCallback(
    (s: ColumnState[]) => {
      if (!restored) return
      setColumnState(s)
    },
    [restored]
  )

  const saveFilterState = useCallback(
    s => {
      if (!restored) return
      setFilterState(s)
    },
    [restored]
  )

  const finishRestoring = useCallback(() => setRestored(true), [])

  const result = useMemo(
    () => ({
      initialized,
      restoredAt,
      finishRestoring,
      expandedRowIds,
      expandRows,
      collapseRows,
      columnState,
      saveColumnState,
      filterState,
      saveFilterState,
      saveImmediately,
    }),
    [
      initialized,
      restoredAt,
      finishRestoring,
      expandedRowIds,
      expandRows,
      collapseRows,
      columnState,
      saveColumnState,
      filterState,
      saveFilterState,
      saveImmediately,
    ]
  )

  return result
}
