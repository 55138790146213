import { ColDef } from 'ag-grid-community'
import { Box } from '@mui/material'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import ReloadIcon from '../../../components/icons/ReloadIcon'
import {
  AggregateFieldToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../components/toggleGroups'
import DisplayFormatButton from '../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import ColumnSettingButton from '../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import FavoriteColumnFilterButton from '../../../containers/BulkSheetView/components/header/FavoriteColumnFilterButton'
import AddRowButton from '../../../containers/BulkSheetView/components/header/AddRowButton'
import FileExportButton from '../../../containers/BulkSheetView/components/header/FileExportButton'
import FileImportButton from '../../../containers/BulkSheetView/components/header/FileImportButton'
import FilterButton from '../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../containers/BulkSheetView/components/header/SortButton'
import { colorPalette } from '../../../style/colorPallete'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'
import ReportIcon from '../../../components/icons/ReportIcon'
import { BootstrapTooltip } from '../../../components/tooltips/Tooltip/BootStrapToopTip'
import { intl } from '../../../../i18n'
import { Button } from '../../../components/buttons'

type Props = {
  onChangeAggregateField: (value: AggregateField) => void
  aggregateField: AggregateField
  onChangeWorkloadUnit: (_: WorkloadUnit) => void
  workloadUnit: WorkloadUnit
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  onClickImportExcel?: ((data: Uint8Array) => void | Promise<any>) | undefined
  onClickExportExcel?: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  onReload: () => void
  onClickColumnSettingButton: (e) => void
  onClickFavoriteColumnFilterButton: () => void
  sortColumnsState: SortedColumnState[]
  currentFormat?: string | undefined
  onChangeDateFormat: (value: string) => void
  isReportOpen?: boolean
  onChangeReportOpen?: (value: boolean) => void
  columnSettingOpen: boolean
  onAddRow?: () => void
}
const TicketsWbsHeader = ({
  onChangeAggregateField,
  aggregateField,
  onChangeWorkloadUnit,
  workloadUnit,
  rowHeight,
  onChangeHeight,
  onClickImportExcel,
  onClickExportExcel,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  onReload,
  onClickColumnSettingButton,
  onClickFavoriteColumnFilterButton,
  sortColumnsState,
  currentFormat,
  onChangeDateFormat,
  isReportOpen,
  onChangeReportOpen,
  columnSettingOpen,
  onAddRow,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <Button
        onClick={onReload}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      {onChangeReportOpen && (
        <BootstrapTooltip
          arrow={true}
          title={
            isReportOpen
              ? intl.formatMessage({ id: 'wbs.header.close.report' })
              : intl.formatMessage({ id: 'wbs.header.open.report' })
          }
        >
          <Button
            onClick={() => onChangeReportOpen(!isReportOpen)}
            sx={{
              background: colorPalette.monotone[2],
              borderRadius: '50%',
              height: '28px',
              width: '28px',
              marginRight: '10px',
              padding: '5px 8px',
              cursor: 'pointer',
              '&:hover': { background: colorPalette.monotone[2] },
            }}
          >
            <ReportIcon />
          </Button>
        </BootstrapTooltip>
      )}
      {onAddRow && <AddRowButton onClick={onAddRow} />}
      <AggregateFieldToggleGroup
        value={aggregateField}
        onChange={onChangeAggregateField}
      />
      <WorkloadUnitToggleGroup
        value={workloadUnit}
        refresh={onChangeWorkloadUnit}
      />
      <FilterButton
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
      />
      <SortButton
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortColumnsState}
      />
      <ColumnSettingButton
        onClick={onClickColumnSettingButton}
        open={columnSettingOpen}
      />
      <FavoriteColumnFilterButton onClick={onClickFavoriteColumnFilterButton} />
      <DisplayFormatButton
        height={rowHeight}
        onChangeHeight={onChangeHeight}
        currentFormat={currentFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        {onClickImportExcel && (
          <FileImportButton onClickImport={onClickImportExcel} />
        )}
        {onClickExportExcel && (
          <FileExportButton onClickExport={onClickExportExcel} />
        )}
      </Box>
    </div>
  )
}

export default TicketsWbsHeader
