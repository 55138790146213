import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { ChangeLogRepository } from '../../applications/ports/changeLogRepository'

export const useChangeLogRepository = (): ChangeLogRepository => {
  const fetch = useCallback(
    async (uuids: string[], offset: number = 0, limit: number = 10) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/change_logs',
        {
          subjectUuid: uuids.join(','),
          offset,
          limit,
        }
      )
      return response.json.revisions
    },
    []
  )
  return {
    fetch,
  }
}
