import { useCallback, useMemo } from 'react'
import { CellProps, FocusableCellProps, InputCellProps } from '.'
import DateTimeTermVO from '../../../../domain/value-object/DateTimeTermVO'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import DateTimeInput from '../../editors/input/DateTimeInput'
import { Box, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'

type DateTimeTermCellProps = CellProps &
  InputCellProps<DateTimeTermVO | undefined> &
  FocusableCellProps

export const DateTimeTermCell = ({
  value,
  onChange,
  colSpan,
  ...other
}: DateTimeTermCellProps) => {
  const fromVo = useMemo(() => {
    const from = value && value.toPlainValue().startDateTime
    return from && new DateTimeVO(from)
  }, [value])

  const toVo = useMemo(() => {
    const from = value && value.toPlainValue().endDateTime
    return from && new DateTimeVO(from)
  }, [value])

  const onChangeFrom = useCallback(
    (v: DateTimeVO | undefined) => {
      if (!v && !toVo) {
        onChange(undefined)
        return
      }
      const newValue = new DateTimeTermVO(v, toVo)
      onChange(newValue)
    },
    [onChange, toVo]
  )
  const onChangeTo = useCallback(
    (v: DateTimeVO | undefined) => {
      if (!v && !fromVo) {
        onChange(undefined)
        return
      }
      const newValue = new DateTimeTermVO(fromVo, v)
      onChange(newValue)
    },
    [fromVo, onChange]
  )
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '400px',
        height: '60px',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <Box sx={{ padding: '0 10px 0 20px' }}>
        <DateTimeInput
          onChange={onChangeFrom}
          value={fromVo}
          placeholder={'YYYY/MM/DD HH:mm:ss'}
          {...other}
        />
      </Box>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <Box sx={{ padding: '0 20px 0 10px' }}>
        <DateTimeInput
          onChange={onChangeTo}
          value={toVo}
          placeholder={'YYYY/MM/DD HH:mm:ss'}
          {...other}
        />
      </Box>
    </Box>
  )
}
