import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import BaseButton from '../BaseButton'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'

const Button = styled(BaseButton)({
  background: BackgroundColor.WHITE,
  color: TextColor.BLUE,
  border: '1px solid #DDDDDD',
  whiteSpace: 'nowrap',
})

interface OwnProps {
  onClick?: () => void | Promise<void>
  disabled?: boolean
  hideIcon?: boolean
}
type Props = OwnProps & WrappedComponentProps

const ReloadButton = (props: Props) => {
  return (
    <Button
      {...props}
      startIcon={!props.hideIcon && <RefreshRoundedIcon color="primary" />}
      variant="outlined"
    >
      {props.intl.formatMessage({ id: 'toolbar.reload' })}
    </Button>
  )
}

export default injectIntl(ReloadButton)
