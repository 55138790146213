import {
  completeTree,
  filterTopLevelRows,
  getDescendants,
  getParentUuid,
  getSiblingUuids,
  isDescendantOf,
} from '../../lib/tree'
import { changeOrderByFollowing, changeOrderByPrev } from '../../lib/row'
import { TreeRow } from '../../model'

export const slideRowsAfter = <R extends TreeRow>(
  prevData: R[],
  rows: R[],
  prevSiblingUuid: string
) => {
  // Check slided rows and prevSiblingUuid has same parent.
  const slidedTopLevelRows = filterTopLevelRows(rows)
  const prevSibling = prevData.find(d => d.uuid === prevSiblingUuid)
  if (!prevSibling) {
    console.warn('PrevSibling is not found.')
    return prevData
  }
  if (!isSiblings([...slidedTopLevelRows, prevSibling])) {
    console.warn('Slided rows and prevSibling does not have the same parent.')
    return prevData
  }

  // Get all leaf children.
  const movedAllLeafChildren = completeTree(
    prevData,
    rows.map(row => row.uuid)
  )

  // Make top level rows edited
  movedAllLeafChildren.forEach(r => {
    if (slidedTopLevelRows.some(t => t.uuid === r.uuid)) {
      r.edited = true
    }
  })

  // Calculate prevUuid, which is the last of descendants of prevSibling.
  const descendantsOfPrevSibling = getDescendants(prevData, prevSiblingUuid)
  const prevUuid =
    descendantsOfPrevSibling.length > 0
      ? descendantsOfPrevSibling[descendantsOfPrevSibling.length - 1].uuid
      : prevSiblingUuid

  // Update row order.
  return changeOrderByPrev(prevData, movedAllLeafChildren, prevUuid)
}

export const slideRowsBefore = <R extends TreeRow>(
  prevData: R[],
  rows: R[],
  followingUuid: string
) => {
  // Check slided rows and prevSiblingUuid has same parent.
  const slidedTopLevelRows = filterTopLevelRows(rows)
  const followingSibling = prevData.find(d => d.uuid === followingUuid)
  if (!followingSibling) {
    console.warn('PrevSibling is not found.')
    return prevData
  }
  if (!isSiblings([...slidedTopLevelRows, followingSibling])) {
    console.warn('Slided rows and prevSibling does not have the same parent.')
    return prevData
  }

  // Get all leaf children.
  const movedAllLeafChildren = fillAllLeafChildren(prevData, rows)

  // Make top level rows edited
  movedAllLeafChildren.forEach(r => {
    if (slidedTopLevelRows.some(t => t.uuid === r.uuid)) {
      r.edited = true
    }
  })

  // Update row order.
  return changeOrderByFollowing(prevData, movedAllLeafChildren, followingUuid)
}

export const moveRowsToFirstChild = <R extends TreeRow>(
  prevData: R[],
  rows: R[],
  parentUuid: string | undefined
) => {
  // Skip if attempted to move rows to itself or its descendant.
  if (rows.some(row => row.uuid === parentUuid)) return prevData
  const parent = prevData.find(d => d.uuid === parentUuid)
  if (parent && rows.some(row => isDescendantOf(parent, row))) {
    return prevData
  }

  // Get all leaf children.
  const movedAllLeafChildren = fillAllLeafChildren(prevData, rows)

  // Update tree value for new parent.
  const treeValueUpdated = updateTreeValues(
    prevData.find(d => d.uuid === parentUuid),
    movedAllLeafChildren
  )

  // Make top level rows edited
  treeValueUpdated.forEach(r => {
    if (rows.some(t => t.uuid === r.uuid)) {
      r.edited = true
    }
  })

  // Update row order.
  return changeOrderByPrev(prevData, treeValueUpdated, parentUuid)
}

export const moveRowsToLastChild = <R extends TreeRow>(
  prevData: R[],
  rows: R[],
  parentUuid: string | undefined
): R[] => {
  // Skip if attempted to move rows to itself or its descendant.
  if (rows.some(row => row.uuid === parentUuid)) return prevData
  const parent = prevData.find(d => d.uuid === parentUuid)
  if (parent && rows.some(row => isDescendantOf(parent, row))) {
    return prevData
  }

  // Get all leaf children.
  const movedAllLeafChildren = fillAllLeafChildren(prevData, rows)

  // Update tree value for new parent.
  const treeValueUpdated = updateTreeValues(
    prevData.find(d => d.uuid === parentUuid),
    movedAllLeafChildren
  )

  // Make top level rows edited
  treeValueUpdated.forEach(r => {
    if (rows.some(t => t.uuid === r.uuid)) {
      r.edited = true
    }
  })

  // Update row order.
  // Calculate prevUuid.
  const futureSiblingUuids = getSiblingUuids(prevData, parentUuid)
  const prevUuid =
    futureSiblingUuids.length > 0
      ? futureSiblingUuids[futureSiblingUuids.length - 1]
      : parentUuid
  return changeOrderByPrev(prevData, treeValueUpdated, prevUuid)
}

/**
 * CAUTION: use this only when other functions are not sufficient.
 */
const moveTreeRows = <R extends TreeRow>(
  prevData: R[],
  rows: R[],
  parentUuid: string | undefined,
  prevUuid: string | undefined
) => {
  // Get all leaf children.
  const movedAllLeafChildren = prevData.filter(data =>
    rows.some(row => (data.treeValue || [data.uuid]).includes(row.uuid))
  )

  // Update tree value for new parent.
  const treeValueUpdated = updateTreeValues(
    prevData.find(d => d.uuid === parentUuid),
    movedAllLeafChildren
  )

  // Update row order.
  return changeOrderByPrev(prevData, treeValueUpdated, prevUuid)
}

// Private utility functions.
const isSiblings = <R extends TreeRow>(rows: R[]): boolean => {
  const parentUuids = new Set(rows.map(row => getParentUuid(row)))
  return parentUuids.size === 1
}

const fillAllLeafChildren = <R extends TreeRow>(
  allData: R[],
  rows: R[]
): R[] => {
  return allData.filter(data =>
    rows.some(row => (data.treeValue || [data.uuid]).includes(row.uuid))
  )
}

const updateTreeValues = <R extends TreeRow>(
  parent: R | undefined,
  rows: R[]
): R[] => {
  const grouped = groupRows(rows)
  const treeValueUpdated: R[] = []
  for (let group of grouped) {
    if (group.length === 0) {
      console.error('Something wrong with grouped rows.')
      continue
    }
    const topLevel = group[0]
    const parentTreeValue = parent?.treeValue || []
    const sliceIndex = (topLevel.treeValue || [topLevel.uuid]).length - 1
    for (let row of group) {
      const oldTreeValue = row.treeValue || [row.uuid]
      const newTreeValue = [
        ...parentTreeValue,
        ...oldTreeValue.slice(sliceIndex),
      ]
      treeValueUpdated.push({
        ...row,
        treeValue: newTreeValue,
      })
    }
  }
  return treeValueUpdated
}

const groupRows = <R extends TreeRow>(rows: R[]): R[][] => {
  const groupedRows: R[][] = []
  for (let row of rows) {
    if (groupedRows.length === 0) {
      groupedRows.push([row])
      continue
    }
    const lastGroup = groupedRows[groupedRows.length - 1]
    if (lastGroup.length === 0) {
      console.error('Something wrong with rows which is grouping target.')
      lastGroup.push(row)
      continue
    }
    if ((row.treeValue || []).includes(lastGroup[0].uuid)) {
      lastGroup.push(row)
      continue
    }
    groupedRows.push([row])
  }
  return groupedRows
}
