import React from 'react'
import { styled } from '@mui/system'
import { connect } from 'react-redux'
import {
  SearchConditionProps,
  SearchFilter,
} from '../WbsItemSearchConditions/WbsItemSearchCondition'
import FilteringItemLabel from './FilteringItemLabel'
import { AllState } from '../../../../../store'
import { intl } from '../../../../../i18n'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
})
const Label = styled('div')({
  verticalAlign: 'middle',
  margin: 'auto 0',
  whiteSpace: 'nowrap',
})

interface ComponentOwnProps {
  searchFilterSnapshotOnSearch: SearchFilter
  searchConditions: SearchConditionProps[]
  onChangeFilter: (filter?: SearchFilter) => void
}

interface StateProps {
  functionExternalId?: string
}

interface Props extends ComponentOwnProps, StateProps {}

class WbsItemSearchFilteringItems extends React.PureComponent<Props> {
  render() {
    const {
      functionExternalId,
      searchFilterSnapshotOnSearch,
      searchConditions,
      onChangeFilter,
    } = this.props
    if (!searchFilterSnapshotOnSearch) {
      return (
        <Label>
          {intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.filteringItems.label',
          })}
          ：
          {intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.filteringItems.nothing',
          })}
        </Label>
      )
    }
    return (
      <RootDiv key="wbs-item-search-filtering-items">
        <Label>
          {intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.filteringItems.label',
          })}
          ：
        </Label>
        {searchConditions.map(searchCondition => {
          return (
            <FilteringItemLabel
              key={`wbs-item-search-filtering-items-label-${searchCondition.key}`}
              functionExternalId={functionExternalId!}
              wbsItemSearchCondition={searchCondition}
              searchFilter={searchFilterSnapshotOnSearch}
              onChange={searchFilter => onChangeFilter(searchFilter)}
            />
          )
        })}
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  functionExternalId: state.appFunction.currentExternalId,
})

export default connect(mapStateToProps)(WbsItemSearchFilteringItems)
