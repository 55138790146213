import { useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../../store'
import {
  SearchFilter,
  getDefaultSearchFilter,
} from '../../../pages/WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import {
  openWbsItemSearch,
  wbsItemSearchCondition,
} from '../../../pages/WbsItemSearch/wbsItemSearchOptions'
import HeaderSearchBox from './HeaderSearchBox'

const HeaderSearchBoxArea = ({ projectUuid }: { projectUuid?: string }) => {
  const [searchText, setSearchText] = useState('')
  const [searchFilter, setSearchFilter] = useState(getDefaultSearchFilter())

  if (!projectUuid) return <></>
  return (
    <HeaderSearchBox
      key={'wbs-search-bar'}
      searchText={searchText}
      searchFilter={searchFilter}
      searchConditions={wbsItemSearchCondition}
      reloadHandler={() =>
        openWbsItemSearch(projectUuid, searchFilter, searchText)
      }
      onChangeSearchText={(text?: string) => setSearchText(text || '')}
      onChangeFilter={(filter?: SearchFilter) =>
        filter && setSearchFilter(filter)
      }
    />
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
})

export default connect(mapStateToProps)(HeaderSearchBoxArea)
