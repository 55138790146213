import WbsItemStatusCell, {
  WbsItemStatus,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { FontSize, FontWeight, TextColor } from '../../../styles/commonStyles'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
} from '../../containers/commons/AgGrid'
import { intl } from '../../../i18n'
import {
  CellClassParams,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { ProjectOverviewRow } from './projectOverview'
import { customHeaderTemplate } from '../../containers/commons/AgGrid/components/header/CustomHeader'
import {
  collapseAllMenu,
  expandAllMenu,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import { excelStyles } from '../../containers/BulkSheet/excel'
import { DateBound } from '../../components/toggleGroups/StartAndEndDateToggleGroup'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { ProjectOverviewSequenceNoCellRenderer } from '../../containers/commons/AgGrid/components/cell/custom/sequenceNo/ProjectOverviewSequenceNoCellRenderer'
import WbsItemTypeCell, {
  WbsItemTypeCellValue,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import { ProjectPlanTreeCellRenderer } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemName/projectPlanTreeCellRenderer'
import DateVO from '../../../vo/DateVO'
import { ProjectDetail } from '../../../lib/functions/project'

// Ag grid options
export const projectOverviewGridOptions = (
  project: ProjectDetail,
  ganttParameter: GanttParameterVO
): GridOptions => {
  const evmTooltipHeader = {
    template: customHeaderTemplate({ tooltip: 'true' }),
  }
  const today = DateVO.now()

  return {
    // Grid context. Define grid specific properties
    context: {
      dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
      ganttParameter,
    },
    // Styling
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 45,
    // Row grouping
    treeData: true,
    groupDefaultExpanded: 1,
    autoGroupColumnDef: {
      field: 'wbsItem.displayName',
      headerName: intl.formatMessage({
        id: 'projectOverview.displayName',
      }),
      width: 300,
      pinned: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: ProjectPlanTreeCellRenderer,
      },
    },
    // Column definition
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: defaultColDef(),
    tooltipShowDelay: 100,
    columnDefs: [
      {
        headerName: intl.formatMessage({ id: 'projectOverview.information' }),
        children: [
          {
            field: 'rowNumber',
            headerName: '#',
            pinned: true,
            // @ts-ignore
            lockPosition: 'left',
            suppressMovable: true,
            width: 50,
            cellClass: 'ag-numeric-cell',
            cellStyle: { justifyContent: 'center' },
            cellRenderer: ProjectOverviewSequenceNoCellRenderer,
            cellRendererParams: {
              onClick: (data: ProjectOverviewRow) => {
                window.open(
                  `${window.location.origin}/projectOverview/${project.code}?treeRootUuid=${data.uuid}`
                )
              },
            },
          },
          {
            field: 'wbsItem.code',
            headerName: intl.formatMessage({ id: 'projectOverview.code' }),
            pinned: true,
            // @ts-ignore
            lockPosition: 'left',
            width: 90,
            hide: true,
          },
          {
            field: 'wbsItem.type',
            headerName: intl.formatMessage({ id: 'projectOverview.type' }),
            type: [ColumnType.wbsItemType],
            cellRenderer: WbsItemTypeCell.cellRenderer,
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data.wbsItem.baseWbsItemType) return {}
              return {
                wbsItemType: params.data.wbsItem.baseWbsItemType,
              } as WbsItemTypeCellValue
            },
            valueFormatter: WbsItemTypeCell.valueFormatter,
            pinned: true,
            // @ts-ignore
            lockPosition: 'left',
            width: 90,
            hide: true,
          },
          {
            field: 'wbsItem.status',
            headerName: intl.formatMessage({ id: 'projectOverview.status' }),
            type: [ColumnType.wbsItemStatus],
            cellRenderer: WbsItemStatusCell.simpleCellRenderer,
            pinned: true,
            // @ts-ignore
            lockPosition: 'left',
            width: 90,
            hide: true,
          },
          {
            // Column for exporting excel
            field: 'wbsItem.displayName',
            headerName: intl.formatMessage({
              id: 'projectOverview.displayName',
            }),
            hide: true,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.term' }),
        children: [
          {
            field: 'wbsItem.scheduledDate.startDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledStartDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
            width: 90,
            hide: true,
          },
          {
            field: 'wbsItem.scheduledDate.endDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledEndDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
            width: 90,
            hide: true,
          },
          {
            field: 'wbsItem.actualDate.startDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualStartDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemActualDate],
            width: 90,
            hide: true,
          },
          {
            field: 'wbsItem.actualDate.endDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualEndDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemActualDate],
            width: 90,
            hide: true,
          },
          {
            field: 'startDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.startDelayDays',
            }),
            width: 100,
            hide: true,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectOverviewRow>) => {
              const w = params.data?.wbsItem
              const scheduled = w?.scheduledDate?.startDate
              const actual = w?.actualDate?.startDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
          {
            field: 'endDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.endDelayDays',
            }),
            width: 100,
            hide: true,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectOverviewRow>) => {
              const w = params.data?.wbsItem
              const scheduled = w?.scheduledDate?.endDate
              const actual = w?.actualDate?.endDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
        children: [
          {
            field: 'scheduledProgressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledProgressRate',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.scheduledProgressRate ??
              getProgressData(params.data, params.context)
                ?.scheduledProgressRate,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'progressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.progressRate',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.progressRate ??
              getProgressData(params.data, params.context)?.progressRate,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'projectOverview.total' }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.total ??
              getProgressData(params.data, params.context)?.total,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'scheduledToBe',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledToBeCompleted',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.scheduledToBe ??
              getProgressData(params.data, params.context)?.scheduledToBe,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'completed',
            headerName: intl.formatMessage({
              id: 'projectOverview.completed',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.completed ??
              getProgressData(params.data, params.context)?.completed,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'preceding',
            headerName: intl.formatMessage({
              id: 'projectOverview.preceding',
            }),
            hide: true,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.preceding ??
              getProgressData(params.data, params.context)?.preceding,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'delayed',
            headerName: intl.formatMessage({ id: 'projectOverview.delayed' }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.delayed ??
              getProgressData(params.data, params.context)?.delayed,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'remaining',
            headerName: intl.formatMessage({
              id: 'projectOverview.remaining',
            }),
            hide: true,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.remaining ??
              getProgressData(params.data, params.context)?.remaining,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'unplanned',
            headerName: intl.formatMessage({
              id: 'projectOverview.endUnplanned',
            }),
            hide: true,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.unplanned ??
              getProgressData(params.data, params.context)?.unplanned,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
        children: [
          {
            field: 'actualHour',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualHour',
            }),
            hide: true,
            valueGetter: params => {
              if (
                params.data?.wbsItem?.wbsItemType?.isTask() &&
                params.context.aggregateTarget === WbsItemType.TASK
              ) {
                return params.data?.cumulation?.actualHour
              }
              return getEvmData(params.data, params.context)?.actualHour
            },
            valueFormatter: (params: ValueFormatterParams) => {
              const type = params.data.wbsItem?.wbsItemType
              if (type) {
                if (
                  type.isDeliverable() &&
                  params.context.aggregateTarget === WbsItemType.DELIVERABLE
                ) {
                  return '-'
                }
                if (
                  type.isTask() &&
                  params.context.aggregateTarget !== WbsItemType.TASK
                ) {
                  return ''
                }
              }
              const value: number | undefined = params.value
              if (value === undefined) return '-'
              return (
                Number(value) /
                (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
              ).toFixed(1)
            },
            ...cumulationCellType,
          },
          {
            field: 'costVariance',
            headerName: 'CV',
            headerTooltip: intl.formatMessage({ id: 'evm.description.CV' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.costVariance,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'costPerformanceIndex',
            headerName: 'CPI',
            headerTooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.costPerformanceIndex,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'scheduleVariance',
            headerName: 'SV',
            headerTooltip: intl.formatMessage({ id: 'evm.description.SV' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.scheduleVariance,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'schedulePerformanceIndex',
            headerName: 'SPI',
            headerTooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.schedulePerformanceIndex,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'estimateToComplete',
            headerName: 'ETC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.estimateToComplete,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'estimateAtCompletion',
            headerName: 'EAC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.estimateAtCompletion,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'varianceAtCompletion',
            headerName: 'VAC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
            headerComponentParams: evmTooltipHeader,
            hide: true,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.varianceAtCompletion,
            ...cumulationCellType,
            valueFormatter: evmFormatter,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.ganttChart' }),
        headerGroupComponent: 'ganttHeaderGroupComponent',
        children: [
          {
            field: 'ganttChart',
            headerName: intl.formatMessage({
              id: 'projectOverview.ganttChart',
            }),
            type: [ColumnType.gantt],
            cellRendererParams: {
              editable: false,
            },
            width: 560,
            cellStyle: {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
            valueGetter: (params: ValueGetterParams) => {
              const w = params.data?.wbsItem
              if (!w) return undefined
              return JSON.stringify([w.scheduledDate, w.status, w.actualDate])
            },
          },
        ],
      },
    ],
    // Context menu
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [expandAllMenu(params), collapseAllMenu(params)]
    },
    // Excel
    excelStyles: excelStyles,
  }
}

export const changeProgressHeaderByBaseDate = (
  api: GridApi,
  base: DateBound
) => {
  const scheduledToBe = api.getColumnDef('scheduledToBe')!
  const completed = api.getColumnDef('completed')!
  const preceding = api.getColumnDef('preceding')!
  const delayed = api.getColumnDef('delayed')!
  const unplanned = api.getColumnDef('unplanned')!
  const remaining = api.getColumnDef('remaining')!
  const headerName = (field: string) =>
    intl.formatMessage({ id: `projectOverview.${field}` })
  if (base === DateBound.END) {
    scheduledToBe.headerName = headerName('scheduledToBeCompleted')
    completed.headerName = headerName('completed')
    preceding.headerName = headerName('preceding')
    delayed.headerName = headerName('delayed')
    remaining.headerName = headerName('remaining')
    unplanned.headerName = headerName('endUnplanned')
  } else {
    scheduledToBe.headerName = headerName('scheduledToBeStarted')
    completed.headerName = headerName('started')
    preceding.headerName = headerName('startPreceding')
    delayed.headerName = headerName('startDelayed')
    remaining.headerName = headerName('todo')
    unplanned.headerName = headerName('startUnplanned')
  }
  api.refreshHeader()
}

export const getProgressData = (
  data: ProjectOverviewRow,
  context: {
    aggregateTarget: AggregateTarget
    aggregateField: AggregateField
    aggregateBaseDate: DateBound
  }
) => {
  if (
    data.wbsItem?.wbsItemType?.isTask() ||
    (data.wbsItem?.wbsItemType?.isDeliverable() &&
      context.aggregateTarget === WbsItemType.DELIVERABLE)
  ) {
    return undefined
  }
  if (!data.progress) return undefined
  const field =
    (context.aggregateField === AggregateField.WBS_ITEM_WORKLOAD
      ? 'sum'
      : 'count') +
    (context.aggregateTarget === WbsItemType.DELIVERABLE
      ? 'Deliverable'
      : 'Task') +
    (context.aggregateBaseDate === DateBound.END ? 'End' : 'Start')
  return data.progress[field]
}
export const getProgressDetailData = (
  data: ProjectOverviewRow,
  context: {
    aggregateTarget: AggregateTarget
    aggregateField: AggregateField
    aggregateBaseDate: DateBound
  }
) => {
  const wbsItemType = data.wbsItem?.wbsItemType
  if (
    wbsItemType?.isProcess() ||
    wbsItemType?.isDeliverableList() ||
    (wbsItemType?.isDeliverable() &&
      context.aggregateTarget !== WbsItemType.DELIVERABLE) ||
    (wbsItemType?.isTask() && context.aggregateTarget !== WbsItemType.TASK) ||
    !data.progressDetail
  ) {
    return undefined
  }
  const field =
    (context.aggregateField === AggregateField.WBS_ITEM_WORKLOAD
      ? 'sum'
      : 'count') +
    (context.aggregateBaseDate === DateBound.END ? 'End' : 'Start')
  return data.progressDetail[field]
}
export const getEvmData = (
  data: ProjectOverviewRow,
  context: { aggregateTarget: AggregateTarget }
) => {
  const type = data.wbsItem?.wbsItemType
  if (
    type?.isTask() ||
    (type?.isDeliverable() &&
      context.aggregateTarget === WbsItemType.DELIVERABLE)
  ) {
    return undefined
  }
  return context.aggregateTarget === WbsItemType.DELIVERABLE
    ? data.evm?.deliverable
    : data.evm?.task
}
export const percentageValueFormatter = (params: ValueFormatterParams) => {
  const value: number | undefined = params.value
  const type: WbsItemTypeVO = params.data.wbsItem?.wbsItemType
  if (!type) return ''
  if (
    (type.isDeliverable() &&
      params.context.aggregateTarget === WbsItemType.DELIVERABLE) ||
    (type.isTask() && params.context.aggregateTarget === WbsItemType.TASK)
  ) {
    return ' -'
  }
  if (
    type.isWorkgroup() ||
    type.isProcess() ||
    type.isDeliverableList() ||
    (type.isDeliverable() &&
      params.context.aggregateTarget === WbsItemType.TASK)
  ) {
    return value === undefined ? '-' : `${Math.round(value * 100)}%`
  }
  return ''
}
export const progressValueFormatter = (params: ValueFormatterParams) => {
  const value: number | undefined = params.value
  const type: WbsItemTypeVO = params.data.wbsItem?.wbsItemType
  const isDetailRow =
    type &&
    ((type.isDeliverable() &&
      params.context.aggregateTarget === WbsItemType.DELIVERABLE) ||
      (type.isTask() && params.context.aggregateTarget === WbsItemType.TASK))
  if (params.context.aggregateField === AggregateField.WBS_ITEM_WORKLOAD) {
    if (value === undefined) return ''
    return (
      (isDetailRow ? ' ' : '') +
      (
        value / (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
      ).toFixed(1)
    )
  }
  return (isDetailRow ? ' ' : '') + (value?.toString() ?? '')
}
export const evmFormatter = (params: ValueFormatterParams) => {
  const type = params.data.wbsItem?.wbsItemType
  if (type) {
    if (
      (type.isDeliverable() &&
        params.context.aggregateTarget === WbsItemType.DELIVERABLE) ||
      (type.isTask() && params.context.aggregateTarget === WbsItemType.TASK)
    ) {
      return ' -'
    }
    if (type.isTask()) {
      return ''
    }
  }
  const value: number | undefined = params.value
  if (value === undefined) return '-'
  return (
    Number(value) /
    (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
  ).toFixed(1)
}
const numberCellStyle = (params: CellClassParams) => {
  const wbsItemType = params.data.wbsItem?.wbsItemType
  const aggregateTarget = params.context.aggregateTarget
  const isDetailRow =
    (wbsItemType?.isDeliverable() &&
      aggregateTarget === WbsItemType.DELIVERABLE) ||
    (wbsItemType?.isTask() && aggregateTarget === WbsItemType.TASK)
  let style = {
    fontSize: isDetailRow ? FontSize.MEDIUM : FontSize.XX_LARGE,
    fontWeight: isDetailRow ? FontWeight.NORMAL : FontWeight.BOLD,
    color: TextColor.DARK_BLACK,
    justifyContent: 'flex-end',
  }
  if (
    !isDetailRow &&
    ['scheduledProgressRate', 'progressRate', 'delayed'].includes(
      params.colDef.field ?? ''
    )
  ) {
    style = { ...style, fontSize: FontSize.XX_LARGE + 4 }
  }
  if (
    [WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(
      params.data.wbsItem.status
    )
  ) {
    return { ...style, color: TextColor.GREY }
  }
  const field = params.colDef.field ?? ''
  if (
    ['progressRate', 'delayed'].includes(field) &&
    0 < (getProgressData(params.data, params.context)?.delayed ?? 0)
  ) {
    return {
      ...style,
      color: '#ff0078',
      cursor: 'pointer',
    }
  }
  return style
}
export const cumulationCellType = {
  width: 90,
  cellStyle: numberCellStyle,
}
