import { useEffect } from 'react'
import Auth from '../../../lib/commons/auth'
import { useHistory } from 'react-router-dom'

const Loggedout = () => {
  const history = useHistory()
  useEffect(() => {
    const effect = async () => {
      const tenant = Auth.getCurrentTenant()
      tenant && (await tenant.logout())
      history.push('')
    }
    effect()
  }, [history])
  return null
}

export default Loggedout
