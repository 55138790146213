import { TableCell, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'

const StyledTableCell = styled(TableCell)({
  backgroundColor: '#F5F5F5',
  width: '130px',
  border: 'solid 1px #cccccc',
})
const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const RequiredMark = styled('span')({
  fontSize: FontSize.X_SMALL,
  fontWeight: FontWeight.BOLD,
  marginLeft: '10px',
  color: '#F03636',
})
const MessageIcon = styled(HelpOutlineRoundedIcon)({
  width: '14px',
  height: '14px',
  color: '#888888',
  marginLeft: 'auto',
})

interface Props {
  title: string
  required: boolean
  message?: string
}

const HeaderCell = (props: Props) => {
  const { title, required, message } = props
  return (
    <StyledTableCell>
      <Content>
        {title}
        {required && <RequiredMark>＊</RequiredMark>}
        {message && (
          <Tooltip title={message || 'test message.'}>
            <MessageIcon />
          </Tooltip>
        )}
      </Content>
    </StyledTableCell>
  )
}

export default HeaderCell
