import { changeOrderByPrev } from '../../lib/row'
import { completeTree, getDescendants } from '../../lib/tree'
import { TreeRow } from '../../model'
import _ from 'lodash'

export const addRowsBefore = <R extends TreeRow>(
  prevData: R[],
  added: R[],
  followingUuid: string
) => {
  const result: R[] = []
  for (let data of prevData) {
    if (data.uuid === followingUuid) {
      const treeValue = data.treeValue || [data.uuid]
      const slicedTreeValue = treeValue.slice(0, treeValue.length - 1)
      const treeValueUpdated = added.map(row => ({
        ...row,
        treeValue: [...slicedTreeValue, row.uuid],
        added: true,
      }))
      result.push(...treeValueUpdated)
    }
    result.push(data)
  }
  return result
}

export const addRowsToFirstChild = <R extends TreeRow>(
  prevData: R[],
  added: R[],
  parentUuid: string
) => {
  // Update treeValue.
  const parent = prevData.find(d => d.uuid === parentUuid)
  const treeValue = parent?.treeValue || []
  const treeValueUpdated = added.map(row => ({
    ...row,
    treeValue: [...treeValue, row.uuid],
    added: true,
  }))
  // Insert into array.
  return changeOrderByPrev(prevData, treeValueUpdated, parentUuid)
}

export const addRowsToLastChild = <R extends TreeRow>(
  prevData: R[],
  added: R[],
  parentUuid: string
): R[] => {
  // Update treeValue.
  const parent = prevData.find(d => d.uuid === parentUuid)
  const treeValue = parent?.treeValue || []
  const treeValueUpdated = added.map(row => ({
    ...row,
    treeValue: _.isEmpty(row.treeValue)
      ? [...treeValue, row.uuid]
      : row.treeValue,
    added: true,
  }))
  // Insert into array.
  const descendants = getDescendants(prevData, parentUuid)
  const prevUuid =
    descendants.length > 0
      ? descendants[descendants.length - 1].uuid
      : parentUuid

  return changeOrderByPrev(prevData, treeValueUpdated, prevUuid)
}

export const updateRows = <R extends TreeRow>(prevData: R[], rows: R[]) => {
  return prevData.map(d => {
    const corresponding = rows.find(row => row.uuid === d.uuid)
    if (corresponding) {
      if (!_.isEqual(corresponding.treeValue, d.treeValue)) {
        console.warn('Updating treeValue using updateRows is not recommended.')
        corresponding.treeValue = d.treeValue
      }
      return corresponding
    }
    return d
  })
}

export const removeRows = <R extends TreeRow>(prevData: R[], rows: R[]) => {
  const uuids = rows.map(v => v.uuid)
  const deleted = completeTree(prevData, uuids)
  const deletedUuids = new Set(deleted.map(d => d.uuid))
  return prevData.filter(d => !deletedUuids.has(d.uuid))
}
