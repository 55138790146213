import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import EventRoundedIcon from '@mui/icons-material/EventRounded'
import { IconButton, IconButtonProps, Tooltip } from '@mui/material'

type Props = WrappedComponentProps & IconButtonProps

const SelectStartDayOfWeekButton: React.FC<Props> = (props: Props) => {
  const { intl } = props
  return (
    <IconButton {...props} sx={{ padding: '3px' }}>
      <Tooltip
        title={intl.formatMessage({
          id: 'startDayOfWeek.select',
        })}
      >
        <EventRoundedIcon
          sx={{
            width: '14px !important',
            height: '14px !important',
            color: '#888',
          }}
        />
      </Tooltip>
    </IconButton>
  )
}

export default injectIntl(SelectStartDayOfWeekButton)
