import {
  CellKeyDownEvent,
  GridOptions,
  ICellEditorParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import {
  CustomEnumCellRenderer,
  DefaultCellRenderer,
  WbsItemStatusOnlyNameCellRenderer,
  WbsItemTypeCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { intl } from '../../../../i18n'
import BoolExpression from '../../../../utils/boolExpression'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
  FunctionProperty,
} from '../../../../lib/commons/appFunction'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import {
  CustomEnumCellEditor,
  SelectCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import { filterValuesByCombination } from '../../../../lib/commons/customEnum'
import { DevelopmentEventWbsItemStatusMappingRow } from '../developmentEventWbsItemStatusMapping'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { getWbsItemStatusColorCode } from '../../../../lib/functions/wbsItem'
import { DevelopmentEvent } from '../../../../domain/value-object/development/DevelopmentEventVO'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'wbsItemType.rootType'
    case 'TICKET_TYPE':
      return 'ticketType'
    case 'WBS_STATUS':
      return 'wbsItemStatus'
    case 'WBS_SUBSTATUS':
      return 'wbsItemSubstatus'
  }
  return undefined
}

const customEnumValueSetter = (
  params: ValueSetterParams<DevelopmentEventWbsItemStatusMappingRow>
) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

export const DevelopmentEventWbsItemStatusMappingGridOptions =
  (): GridOptions => {
    return {
      groupHeaderHeight: 25,
      headerHeight: 45,
      rowHeight: 32,
      treeData: false,
      context: {},
      suppressLastEmptyLineOnPaste: true,
      // Row
      rowDragManaged: false,
      rowDragMultiRow: false,
      suppressMoveWhenRowDragging: true,
      enterMovesDownAfterEdit: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: {
        width: 85,
        editable: false,
        enableValue: false,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        suppressSizeToFit: true,
        singleClickEdit: false,
        cellEditor: 'textEditor',
        cellStyle: { justifyContent: 'left' },
        cellRenderer: DefaultCellRenderer,
        onCellClicked: defaultOnCellClicked,
        suppressPaste: params => !_.isEmpty(params.context?.copied),
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          return (
            !params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)
          )
        },
        valueSetter: (params: ValueSetterParams) => {
          const field = params.colDef.field || params.colDef.colId
          if (
            !field ||
            (!params.oldValue && !params.newValue) ||
            params.oldValue === params.newValue
          ) {
            return false
          }
          objects.setValue(params.data, field, params.newValue)
          params.data.edited = true
          store.dispatch(requireSave())
          return true
        },
      },
      columnDefs: [
        {
          field: 'uuid',
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          colId: 'blank',
          hide: true,
          width: 35,
          suppressColumnsToolPanel: true,
        },
        {
          field: 'rowNumber',
          type: [ColumnType.sequenceNo],
          resizable: false,
          width: 35,
          cellRenderer: SequenceNoCellRenderer,
          cellRendererParams: params => {
            return {
              value: params.node.rowIndex + 1,
            }
          },
        },
        {
          headerName: intl.formatMessage({
            id: 'developmentEventWbsItemStatusMapping.column.groupHeader.updateSetting',
          }),
          children: [
            {
              field: 'developmentEvent',
              headerName: intl.formatMessage({
                id: 'developmentEventWbsItemStatusMapping.column.header.developmentEvent',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 200,
              editable: true,
              cellEditor: SelectCellEditor,
              cellEditorParams: {
                options: AllDevelopmentEvents.map(value => ({
                  value,
                  label: DevelopmentEventLabels[value],
                })),
              },
              valueFormatter: (
                params: ValueFormatterParams<DevelopmentEventWbsItemStatusMappingRow>
              ) => (params.value ? DevelopmentEventLabels[params.value] : ''),
              cellRendererParams: {
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
            },
            {
              field: 'wbsItemType',
              headerName: intl.formatMessage({
                id: 'developmentEventWbsItemStatusMapping.column.header.wbsItemType',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 200,
              editable: true,
              cellEditor: SelectCellEditor,
              cellEditorParams: {
                options: (params: ICellEditorParams) => {
                  const wbsItemTypes = params.context['wbsItemType']
                  if (!wbsItemTypes) {
                    return []
                  }
                  return wbsItemTypes.map(v => ({
                    value: v,
                    label: v.getNameWithSuffix(),
                    iconUrl: v.iconUrl,
                  }))
                },
              },
              valueFormatter: (
                params: ValueFormatterParams<DevelopmentEventWbsItemStatusMappingRow>
              ) => (params.value ? params.value.getNameWithSuffix() : ''),
              valueSetter: (
                params: ValueSetterParams<DevelopmentEventWbsItemStatusMappingRow>
              ) => {
                if (!params.newValue) {
                  return false
                }
                const wbsItemType = params.newValue as WbsItemTypeVO
                params.data.wbsItemType = wbsItemType
                params.data.ticketType = wbsItemType.isTicket()
                  ? wbsItemType.code.replace('_LIST', '')
                  : undefined
                params.data.wbsItemSubstatus = undefined
                params.data.edited = true
                return true
              },
              cellRenderer: WbsItemTypeCellRenderer,
              cellRendererParams: {
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
            },
            {
              field: 'wbsItemStatus',
              headerName: intl.formatMessage({
                id: 'developmentEventWbsItemStatusMapping.column.header.wbsItemStatus',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 150,
              editable: true,
              type: [ColumnType.customEnum],
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: {
                customEnumCode: 'wbsItemStatus',
                combinedValuePath: statusCombinedValuePath,
              },
              valueSetter: (
                params: ValueSetterParams<DevelopmentEventWbsItemStatusMappingRow>
              ): boolean => {
                params.data.wbsItemStatus =
                  params.newValue ?? WbsItemStatus.TODO

                // Update substatus
                const { combinedValuePath } = params.colDef.cellEditorParams
                const substatusOptions = filterValuesByCombination(
                  params.context['wbsItemSubstatus'],
                  code =>
                    objects.getValue(params.data, combinedValuePath?.(code)),
                  true
                )
                params.data.wbsItemSubstatus = substatusOptions?.[0]?.value
                params.data.edited = true
                return true
              },
              cellRenderer: WbsItemStatusOnlyNameCellRenderer,
              cellRendererParams: {
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
              cellStyle: params => {
                const rowData: DevelopmentEventWbsItemStatusMappingRow =
                  params.data
                if (!rowData || !rowData.wbsItemStatus) {
                  return { backgroundColor: '#F7F7F7' }
                }
                return {
                  backgroundColor: `${getWbsItemStatusColorCode(
                    rowData.wbsItemStatus
                  )}`,
                }
              },
            },
            {
              field: 'wbsItemSubstatus',
              headerName: intl.formatMessage({
                id: 'developmentEventWbsItemStatusMapping.column.header.wbsItemSubstatus',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 200,
              editable: true,
              type: [ColumnType.customEnum],
              cellEditor: CustomEnumCellEditor,
              cellEditorParams: {
                customEnumCode: 'wbsItemSubstatus',
                combinedValuePath: statusCombinedValuePath,
              },
              valueSetter: customEnumValueSetter,
              cellRenderer: CustomEnumCellRenderer,
              cellRendererParams: {
                customEnumCode: 'wbsItemSubstatus',
              },
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'changeLog' }),
          children: [
            {
              field: 'revision',
              headerName: intl.formatMessage({ id: 'revision' }),
              hide: true,
              width: 90,
            },
            {
              field: 'createdBy',
              headerName: intl.formatMessage({ id: 'createdBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams) => {
                return params.data.createdBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'createdBy.name',
                iconUrlField: 'createdBy.iconUrl',
              },
            },
            {
              field: 'createdAt',
              headerName: intl.formatMessage({ id: 'createdAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
            },
            {
              field: 'updatedBy',
              headerName: intl.formatMessage({ id: 'updatedBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams) => {
                return params.data.updatedBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'updatedBy.name',
                iconUrlField: 'updatedBy.iconUrl',
              },
            },
            {
              field: 'updatedAt',
              headerName: intl.formatMessage({ id: 'updatedAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
            },
          ],
        },
      ],
    }
  }

const AllDevelopmentEvents = [
  DevelopmentEvent.PUSH,
  DevelopmentEvent.CREATE_BRANCH,
  DevelopmentEvent.CREATE_PULL_REQUEST,
  DevelopmentEvent.MERGE_PULL_REQUEST,
]
export const DevelopmentEventLabels = {
  [DevelopmentEvent.PUSH]: intl.formatMessage({ id: 'development.event.push' }),
  [DevelopmentEvent.CREATE_BRANCH]: intl.formatMessage({
    id: 'development.event.createBranch',
  }),
  [DevelopmentEvent.CREATE_PULL_REQUEST]: intl.formatMessage({
    id: 'development.event.createPullRequest',
  }),
  [DevelopmentEvent.MERGE_PULL_REQUEST]: intl.formatMessage({
    id: 'development.event.mergePullRequest',
  }),
}
