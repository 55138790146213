import { ClickAwayListener, IconButton, Popper, styled } from '@mui/material'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { ValuePanelInputRoot } from './commons'
import {
  DateV2,
  dateV2Service,
} from '../../../../../../domain/value-object/DateV2'
import { useCallback, useMemo, useRef, useState } from 'react'
import { colorPalette } from '../../../../../style/colorPallete'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import { PopperRoot } from '../../../../../components/menu'
import { DatePicker } from '../../../../../components/inputs/DatePicker'
import { DateTermV2 } from '../../../../../../domain/value-object/DateTermV2'

type DateTermInputProps = {
  value: DateTermV2 | undefined
  onChange: (v: DateTermV2 | undefined) => void
}
export const DateTermInput = ({ value, onChange }: DateTermInputProps) => {
  const onChangeStartDate = useCallback(
    (v: DateV2 | undefined) => {
      onChange({
        startDate: v,
        endDate: value?.endDate,
      })
    },
    [onChange, value?.endDate]
  )
  const onChangeEndDate = useCallback(
    (v: DateV2 | undefined) => {
      onChange({
        startDate: value?.startDate,
        endDate: v,
      })
    },
    [onChange, value?.startDate]
  )
  return (
    <Root>
      <DateInput value={value?.startDate} onChange={onChangeStartDate} />
      <Tilde>〜</Tilde>
      <DateInput value={value?.endDate} onChange={onChangeEndDate} />
    </Root>
  )
}

const Root = styled(ValuePanelInputRoot)({
  width: '240px',
})
const Tilde = styled('span')({
  color: colorPalette.monotone[10],
  display: 'flex',
  justifyContent: 'center',
  fontSize: '11px',
})

type DateInputProps = {
  value: DateV2 | undefined
  onChange: (v: DateV2 | undefined) => void
}
const DateInput = ({ value, onChange: _onChange }: DateInputProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const close = useCallback(() => setOpen(false), [])
  const formattedValue = useMemo(() => {
    if (!value) return ''
    return dateV2Service.format(value)
  }, [value])
  const onChange = useCallback(
    (v: DateV2 | undefined) => {
      _onChange(v)
      close()
    },
    [_onChange, close]
  )
  return (
    <>
      <ValueWithIcon ref={ref}>
        <Value>{formattedValue}</Value>
        <StyledIconButton onClick={toggle}>
          <CalendarIcon />
        </StyledIconButton>
      </ValueWithIcon>
      <Popper open={open} anchorEl={ref.current}>
        <ClickAwayListener onClickAway={close}>
          <PopperRoot>
            <DatePicker value={value} onChange={onChange} />
          </PopperRoot>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const ValueWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})
const Value = styled('span')({
  color: colorPalette.monotone[10],
  display: 'flex',
  justifyContent: 'center',
  fontSize: '12px',
  width: '80px',
})
const StyledIconButton = styled(IconButton)({
  padding: '2px',
})
const CalendarIcon = styled(CalendarMonthRoundedIcon)({
  height: '14px',
  width: '14px',
  color: colorPalette.monotone[4],
})
