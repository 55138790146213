import { useCallback, useEffect, useState } from 'react'
import {
  BurndownChartResourceType,
  BurndownChartSimulationRequest,
  BurndownChartSimulationResult,
} from '../../../../domain/value-object/BurndownChart'
import { BurndownChartCondition } from '../model'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'

export const useServerSideSimulation = (
  condition: Pick<BurndownChartCondition, 'projectUuid' | 'domain'>,
  teamUuid: string | undefined,
  resourceType: BurndownChartResourceType,
  remaining: number
) => {
  const [request, setRequest] = useState<BurndownChartSimulationRequest>({
    ...condition,
    teamUuid,
    resourceType,
    remaining,
    dependentVariable: 'VELOCITY',
    endDate: new Date(),
  })

  useEffect(() => {
    setRequest(prev => ({
      ...prev,
      ...condition,
      teamUuid,
      resourceType,
      remaining,
    }))
  }, [condition, remaining, resourceType, teamUuid])

  const updateVelocity = useCallback((velocity: number) => {
    setRequest(prev => ({
      ...prev,
      dependentVariable: 'END_DATE',
      velocity,
      resourceRate: 1,
    }))
  }, [])
  const updateEndDate = useCallback((endDate: Date) => {
    setRequest(prev => ({
      ...prev,
      dependentVariable: 'VELOCITY',
      endDate,
    }))
  }, [])
  const [result, setResult] = useState<BurndownChartSimulationResult>({
    dependentVariable: 'VELOCITY',
    valid: true,
    value: 1,
  })
  const { simulateVelocity, simulateEndDate } = useBurndownChartRepository()
  useEffect(() => {
    const fn = async () => {
      switch (request.dependentVariable) {
        case 'END_DATE':
          const endDateResult = await simulateEndDate(request)
          setResult(endDateResult)
          break
        case 'VELOCITY':
          const velocityResult = await simulateVelocity(request)
          setResult(velocityResult)
      }
    }
    fn()
  }, [request, simulateEndDate, simulateVelocity])

  return {
    simulationRequest: request,
    simulationResult: result,
    updateVelocity,
    updateEndDate,
  }
}
