import VOBase from '../../base'
import { SlackUser } from '../../../domain/value-object/Slack/UserVO'

export default class SlackUserVO extends VOBase {
  private readonly value: SlackUser[]

  constructor(_value: SlackUser[]) {
    super()
    this.value = _value
  }

  getValue = () => {
    return this.value
  }
  format = () => {
    return this.value?.map(v => v.slackUserName) ?? ''
  }
  serialize = () => this.value.filter(v => v.slackUserId)

  isEqual(comparedValue: VOBase): boolean {
    if (comparedValue instanceof SlackUserVO) {
      return JSON.stringify(this.value) === JSON.stringify(comparedValue.value)
    }
    return false
  }

  createAddedOrReplacedClone = (newValue: SlackUser): SlackUserVO => {
    if (!newValue || !newValue.slackWorkspaceId) {
      return new SlackUserVO([...this.value])
    }
    const original = this.value.filter(
      v => v.slackWorkspaceId !== newValue.slackWorkspaceId
    )
    const replaced = [...original, newValue]
    return new SlackUserVO(replaced)
  }
}
