import React from 'react'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import SelectVO from '../../../../../../vo/SelectVO'
import { CellDef } from '../../../../meta/ViewMeta/SingleSheetViewMeta'
import { TableData } from '../..'
import objects from '../../../../../../utils/objects'
import { filterByCombination } from '../../../../../../lib/commons/customEnum'
import equal from 'fast-deep-equal'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import VOBase from '../../../../../../vo/base'
import { getWbsItemStatusDeepColorCode } from '../../../../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../../../commons/AgGrid/components/cell/custom/wbsItemStatus'
import { FontSize, FontWeight } from '../../../../../../styles/commonStyles'
import Select from '../../../../../components/editors/select/Select'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})
const OptionLabel = styled('span')({
  marginLeft: 10,
  padding: '2px 8px',
  width: 'auto',
  borderRadius: 4,
  fontWeight: FontWeight.BOLD,
  fontSize: FontSize.MEDIUM,
})

interface WbsStatusCellProps extends CellPropsBase<SelectVO> {}

interface WbsStatusCellState extends CellStateBase {
  options: SelectVO[]
}

class WbsStatusCell extends DataCellBase<
  SelectVO,
  WbsStatusCellProps,
  WbsStatusCellState
> {
  constructor(props) {
    super(props)
    this.state = { options: this.getOptions() }
  }
  private getOptions = (): SelectVO[] => {
    const getCombinedValue = (path: string) => {
      const value = objects.getValue(this.props.data, path)
      if (value instanceof VOBase) {
        return value.getValue()
      }
      return value
    }
    const filtered = filterByCombination(
      this.props.cellDef.uiMeta.valuesAllowed,
      getCombinedValue
    ).filter(option => option.value !== 'NONE')
    return filtered.map(option => SelectVO.fromCustomEnum(option))
  }
  private resetOptions = () => {
    this.setState({ options: this.getOptions() })
  }
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    if (!equal(this.props.data, prevProps.data)) {
      this.resetOptions()
    }
  }
  validate = (value: SelectVO) => {
    return undefined
  }

  onChange = (event: any) => {
    this.props.onChange(
      SelectVO.of(event.target.value, this.props.cellDef.uiMeta.valuesAllowed)
    )
  }

  isDisabled = (cellDef: CellDef, data: TableData): boolean => {
    const editable = cellDef.editable
    let disable = false
    if (typeof editable === 'boolean') {
      disable = !editable
    } else {
      disable = editable(data)
    }
    return disable
  }

  clear = () => {
    this.props.onChange(undefined)
  }

  render() {
    const { value, data, cellDef } = this.props
    return (
      <RootDiv>
        <Select
          SelectDisplayProps={{ style: { paddingTop: 7 } }}
          fullWidth={true}
          value={value ? value.getValue() : ''}
          disabled={this.isDisabled(cellDef, data)}
          onChange={this.onChange}
          IconComponent={ExpandMoreIcon}
          disableUnderline={true}
          options={this.state.options}
          getOptionLabel={o => (
            <OptionLabel
              style={{
                background: getWbsItemStatusDeepColorCode(
                  WbsItemStatus[o.getValue()]
                ),
              }}
            >
              {o.format()}
            </OptionLabel>
          )}
          getOptionValue={o => o?.getValue()}
          MenuProps={{ disablePortal: true }}
          variant="standard"
        />
      </RootDiv>
    )
  }
}

export default WbsStatusCell
