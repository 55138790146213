import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

const FORMAT = /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+/

export default class URLVO extends DomainValueObjectBase<string> {
  constructor(_value: string) {
    if (!_value) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'URL value is required.',
      })
    }
    if (typeof _value !== 'string') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: 'URL value must be string value.',
      })
    }
    if (!FORMAT.test(_value)) {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_FORMAT_ERROR,
        message: 'URL format must be URL.',
      })
    }
    super(_value)
  }
}
