import { Button, ButtonProps } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'

const ColumnFilterButton = (props: ButtonProps) => {
  const { classes, title, ...other } = props
  return (
    <Button
      {...other}
      variant="outlined"
      size="small"
      sx={{
        background: 'transparent',
        color: colorPalette.monotone[5],
        border: `1px solid ${colorPalette.monotone[2]}`,
        margin: '0 2px',
        minWidth: 'fit-content',
        height: '24px',
        borderRadius: '17px',
        '&:hover': {
          background: 'transparent',
          border: `1px solid ${colorPalette.monotone[2]}`,
        },
      }}
    >
      {title}
    </Button>
  )
}

export default ColumnFilterButton
