import _ from 'lodash'
import { List, Map } from 'immutable'
import React, { CSSProperties } from 'react'
import { styled } from '@mui/system'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { RouteComponentProps } from 'react-router'
import { AgGridReact } from 'ag-grid-react'
import BulkSheetToolBar from './BulkSheetToolBar'
import RowDataManager, {
  RowData,
  RowDataSpec,
} from './RowDataManager/rowDataManager'
import { Tree } from '../../../lib/commons/tree'
import ViewMeta, { SubmitType } from '../meta/ViewMeta'
import store, { AllState } from '../../../store'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnApi,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  MenuItemDef,
  NewValueParams,
  RowDragEvent,
  RowDragMoveEvent,
  RowNode,
  SuppressKeyboardEventParams,
  ValueGetterFunc,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import '../../../styles/agGrid.scss'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  defaultOnCellClicked,
  defaultValueSetter,
  frameworkComponents,
  getAncestors,
  getParentNode,
  isRootNode,
  onCellValueChanged,
  onPasteEnd,
  processCellForClipboard,
  processDataFromClipboard,
  rangeSelectionChanged,
  refreshAncestors,
  resetChildTreeValue,
  sideBar,
} from '../commons/AgGrid'
import objects from '../../../utils/objects'
import {
  ApplicationContext,
  Cockpit,
  Component,
  Function,
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import Excel, { excelStyles } from './excel'
import uiStates, {
  RequestOfGetStates,
  RequestOfUpdateStates,
  UiStateKey,
  UiStateProps,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import {
  APIResponse,
  ElasticsearchResponse,
  extractValuesFromResponse,
} from '../../../lib/commons/api'
import { AG_GRID_THEME } from './const'
import {
  withKeyBind,
  WithKeyBindProps,
} from '../../higher-order-components/keyBind'
import TaskActualWorkDialog, {
  TaskActualWorkDialogState,
} from '../../components/dialogs/TaskActualWorkDialog'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import CommonTextCell from '../commons/AgGrid/components/cell/common/text'
import ServerSideRowDataManager, {
  ServerSideDataSource,
} from './RowDataManager/serverSideRowDataManager'
import ClientSideRowDataManager from './RowDataManager/clientSideRowDataManager'
import {
  APPLICATION_FUNCTION_EXTERNAL_ID,
  getFunctionByPath,
  getPathByExternalId,
} from '../../pages'
import Auth from '../../../lib/commons/auth'
import Loading from '../../components/process-state-notifications/Loading'
import AlertDialog, {
  DialogProps as AlertDialogState,
} from '../../components/dialogs/AlertDialog'
import MarkupViewer from '../../components/viewers/MarkupViewer'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../commons/AgGrid/lib/contextMenu'
import repositories, { EntitySearchValue } from '../meta/repositories'
import { ErrorCode, handleWarning } from '../../../handlers/globalErrorHandler'
import AddRowCountInputDialog, {
  AddRowCountInputDialogState,
} from './AddRowCountInputDialog'
import { customHeaderTemplate } from '../commons/AgGrid/components/header/CustomHeader'
import {
  getGanttChartWidth,
  ProjectPlanRow,
} from '../../pages/ProjectPlan/projectPlanOptions'
import Project from '../../../lib/functions/project'
import { getUrlQueryObject } from '../../../utils/urls'
import { loginToProject } from '../../../store/project'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import { open } from '../../router'
import { intl } from '../../../i18n'
import { logDownloadExcel } from '../../../utils/file'
import { Comment, CommentSummary } from '../../../store/comments'
import { UserDetail } from '../../../lib/functions/user'
import SavedUIStateDialog, {
  SavedUIStateDialogProps,
} from '../../components/dialogs/SavedUIStateDialog'
import {
  generateURLForSharedSearchConditionState,
  isShareableSavedUIState,
  SavedUIState,
  UIState,
} from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { OptionUIStateListProps as SavedUIStateEditorDialogOptionListProps } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateEditorDialog'
import {
  CustomComponent,
  FunctionLayer,
  putHeaderComponent,
} from '../../../store/functionLayer'
import { CommentProps, closeComment } from '../../../store/information'
import { Attachment, AttachmentSummary } from '../../../utils/attachment'
import AttachmentListDialog, {
  AttachmentListDialogProps,
} from '../meta/AttachmentListDialog'
// TODO: exclude function specific logic.
import { SpeedDialActionProps } from '../commons/AgGrid/components/cell/custom/detail/cellRenderer'
import { validate } from '../commons/AgGrid/lib/validator'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import HeaderBar from '../../components/headers/HeaderBar'
import { ExcelExportDialog } from './ExcelExportDialog'
import {
  KEY_SAVE,
  KEY_SPACE,
  KEY_TAB,
  KeyBindListener,
} from '../../model/keyBind'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import {
  runAsyncWithPerfMonitoring,
  runUseCaseAsyncWithPerfMonitoring,
  runWithPerfMonitoring,
} from '../../../utils/monitoring'
import {
  getExternalIdByWbsItem,
  WbsItemDeltaInput,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'

const ERR_ROW_NUMBER_PREFIX: string = '#'
const ERR_DELIMITER: string = ' > '

export enum ROW_HEIGHT {
  MIN = 26,
  SMALL = 32,
  MEDIUM = 50,
  MAX = 200,
}

type BulkSheetColDef = ColDef & {
  headerName?: string
  children?: BulkSheetColDef[]
}

const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
})

interface SelectedUIState {
  searchConditionUuid?: string
  searchConditionIncludeColumn: boolean
  columnAndFilterUuid?: string
}

export interface BulkSheetState {
  uuid: string
  lockVersion?: number
  revision?: string
  submitType: SubmitType
  gridOptions: GridOptions
  rowData: RowData[]
  initialRowData: { [rowDataUuid: string]: RowData }
  inProgress: boolean
  editable: boolean
  hasUpdatePermission: boolean
  taskActualWorkDialog: TaskActualWorkDialogState
  attachmentListDialog: AttachmentListDialogProps
  total?: number
  hit?: number
  clipboard: RowData[]
  cutRows: RowNode[]
  alertDialogState: AlertDialogState
  savedBulkSheetUIStateDialogState: SavedUIStateDialogProps
  addRowCountInputDialogState: AddRowCountInputDialogState
  statusPopperAnchorEl?: any
  statusPopperProjectUuid?: string
  statusPopperUuid?: string
  statusPopperWbsItem?: WbsItemRow
  statusPopperWbsItemDelta?: Partial<WbsItemDeltaInput>
  isLoading: boolean
  filteredColumns: ColDef[]
  sortedColumns: string[]
  selectedCommentUuid?: string
  children: any
  rowHeight: number
  watchDataUuids: string[]
  copyHeadersToClipboard: boolean
  lastSelectedUIState: SelectedUIState | undefined
  submitDisabled: boolean
  rowBuffer?: number
  sortColumnsState?: SortedColumnState[] | undefined
}

const defaultValueGetter = (params: ValueGetterParams) => {
  const field = params.column.getColDef().field!
  if (!field) {
    return undefined
  }
  return objects.getValue(params.data, field)
}

export interface BulkSheetContext<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> {
  props: BulkSheetProps<P, T, R, S>
  rowDataManager: RowDataManager<T, R, S>
  gridApi?: GridApi
  columnApi?: ColumnApi
  state: S
  viewMeta: ViewMeta
  rowDataSpec: RowDataSpec<T, R>
  options: BulkSheetOptions<P, T, R, S>
  treeProperty?: FunctionProperty
  treeRoot: {
    uuid?: string
    lockVersion?: number
    revision?: string
  }
  addRow: (addToUuid?: string, parentUuid?: string, newRowData?: R) => void
  setState: <K extends keyof S>(
    state:
      | ((prevState: Readonly<S>, props: any) => Pick<S, K> | S | null)
      | (Pick<S, K> | S | null),
    callback?: () => void
  ) => void
  refreshDataWithLoading: (
    forceRefreshDynamicColumns?: boolean
  ) => Promise<void>
  refreshData: (forceRefreshDynamicColumns?: boolean) => void
  refreshGrid: () => void
  refreshDynamicColumns: (force?: boolean) => void
  refreshAfterUpdateSingleRow: (uuid: string) => Promise<void>
  rememberCurrentSearchCondition: () => void
  getColumnAndFilterUIState: () => UIState
  openSavedBulkSheetUIStateDialog: (
    uiStateKey: UiStateKey,
    showEditorDialogOptionList: boolean
  ) => void
  refreshFilteredRowDataUuid: () => void
  setContext: (newContext: any) => void
  onSubmitSingleRow: (uuid: string) => Promise<boolean | undefined>
  // Default context menu
  generateAddContextMenuGroup: (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ) => ContextMenuGroup | undefined
  generateEditContextMenu: (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ) => ContextMenuGroup | undefined
  generateUtilityContextMenu: (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ) => ContextMenuGroup | undefined
  generateURLCopyContextMenu: (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ) => ContextMenuGroup | undefined

  getAllRowNodes(): RowNode[]

  removeRowKeyEventListener: () => void
  copyRowKeyEventListener: () => void
  cutRowKeyEventListener: () => void
  pasteRowKeyEventListener: (
    pasteAsChildren: boolean,
    nameOnly: boolean
  ) => void
  insertCutRowKeyEventListener: () => void
  setHeaderComponents: () => void
}

interface CommonColumnCustomDef<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> {
  headerName?: string | ((ctx: BulkSheetContext<P, T, R, S>) => string)
  width?: number
}

export interface RestoreSearchConditionOptions {
  isRestoredSavedSearchConditionByUser?: boolean // Restored by user on select SavedBulkSheetUIState dialog.}
}

export type OpenDetailSpec = {
  openInDialog: boolean
  onOpen?: () => void
  layer: FunctionLayer
}

export abstract class BulkSheetOptions<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> {
  // Appearance
  getApplicationContext?: (
    ctx: BulkSheetContext<P, T, R, S>
  ) => ApplicationContext | undefined
  rowHeight: ROW_HEIGHT = ROW_HEIGHT.SMALL
  customColumnWidth?: (field: string) => number | undefined
  uniqueColumn: string = ''
  pinnedColumns: string[] = []
  /** lockedColumns: Columns cannot be moved by drag */
  lockedColumns: string[] = []
  focusColumn: (ctx: BulkSheetContext<P, T, R, S>) => string = ctx => ''
  copyRow?: (nodes: RowNode[], ctx: BulkSheetContext<P, T, R, S>) => any
  /** pasteColumns: required... must be pasted, optioanl... can be select to paste*/
  getPasteColumnsCandidate?: (ctx: BulkSheetContext<P, T, R, S>) => {
    path: string
    label: string
    defaultChecked?: boolean
    disabled?: boolean
    hidden?: boolean
  }[]

  /** showTreeRowNumber: The number of nested nodes are displayed as "1-1", "1-2-1" */
  showTreeRowNumber: boolean = false
  groupColumnWidth: number = 500
  /** fieldRefreshedAfterMove: Refresh fields of parent nodes, mainly to refresh cumulations */
  fieldRefreshedAfterMove?: string[]

  // Editing
  addable: boolean | ((bulkSheetState: S) => boolean) = false
  displayNameField?: string
  draggable: boolean = false
  rowDrag: (params: any, ctx: BulkSheetContext<P, T, R, S>) => boolean =
    params => true
  beforeParentChange?: (nodes: RowNode[], overNode?: RowNode) => void
  // Context menu options
  getRowsToRemove?: (
    nodes: RowNode[],
    ctx: BulkSheetContext<P, T, R, S>
  ) => { rows: RowNode[]; unremovableReasonMessageId?: string }
  checkRowCopiable: (data: R[]) => boolean = (data: R[]) => true
  checkRowBulkCopiable: (data: R) => boolean = (data: R) => true
  checkRowPastable: (parent: R[], selected: R[], data: R[]) => boolean = (
    parent: R[],
    selected: R[],
    data: R[]
  ) => true
  canAddChild: (
    row: R,
    parentRow: R | undefined,
    ctx: BulkSheetContext<P, T, R, S>
  ) => boolean = () => true
  abstract rowDataSpec: RowDataSpec<T, R>

  // HeaderBar and Toolbar options
  headerComponents?: (ctx: BulkSheetContext<P, T, R, S>) =>
    | {
        titleComponent?: JSX.Element
        customHeaderComponents?: CustomComponent[]
      }
    | undefined
  toolBarItems?: (ctx: BulkSheetContext<P, T, R, S>) => JSX.Element[]
  defaultToolBarItemKey?: React.Key
  hideToolbarDivider?: boolean
  subToolBarItems?: (ctx: BulkSheetContext<P, T, R, S>) => JSX.Element[]
  enableExcelExport: boolean = false
  enableExcelImport: boolean = false
  enableExpandAllRow: boolean = false
  onClickSetting?: (ctx: BulkSheetContext<P, T, R, S>) => void
  hiddenColumns?: string[] = []

  // Custom column definitions
  pinnedTopRowData?: R[]
  pinnedBottomRowData?: R[]
  customColumnTypes: { [key: string]: string[] } = {}
  getValueGetter?: (
    field: string,
    prop?: FunctionProperty
  ) => AgGridValueGetter | undefined
  getValueSetter?: (field: string) => AgGridValueSetter | undefined
  getCellEditorParams?: (
    field: string,
    prop: FunctionProperty,
    ctx: BulkSheetContext<P, T, R, S>
  ) => { [key: string]: any } | undefined
  customRenderers: { [key: string]: any } = {}
  getCellRendererParams?: (
    field: string,
    ctx?: BulkSheetContext<P, T, R, S>
  ) => { [key: string]: any } | undefined
  getDetailColumnCellRendererParams?: (ctx: BulkSheetContext<P, T, R, S>) =>
    | {
        path?: string
        label?: string
        icon?: JSX.Element
        iconTooltip?: string
        disabled?: (row: R) => boolean
        commentOpened?: (row: R) => boolean
        openComment?: (row: R, ctx: BulkSheetContext<P, T, R, S>) => void
        getCommentSummary?: (row: R) => CommentSummary | undefined
        getAttachmentList?: (
          row: R,
          ctx: BulkSheetContext<P, T, R, S>
        ) => Promise<Attachment[]>
        getAttachmentSummary?: (row: R) => AttachmentSummary | undefined
        getSpeedDialActions?: (node) => SpeedDialActionProps[]
        addRowActions?: (row: R) => void
        onClickProgressReportIcon?: (row) => void
      }
    | undefined
  getAttachmentCellRendererParams?: (ctx: BulkSheetContext<P, T, R, S>) =>
    | {
        getAttachmentList?: (
          row: R,
          ctx: BulkSheetContext<P, T, R, S>
        ) => Promise<Attachment[]>
        getAttachmentSummary?: (row: R) => AttachmentSummary | undefined
      }
    | undefined
  getOnCellValueChanged?: (
    field: string,
    ctx: BulkSheetContext<P, T, R, S>
  ) => ((params: NewValueParams) => void) | undefined
  getOnCellClicked?: (
    field: string,
    ctx: BulkSheetContext<P, T, R, S>
  ) => ((params: CellClickedEvent) => void) | undefined
  getRowStyle?: (params) => CSSProperties | undefined
  commonColumnCustomDefs: {
    rowNumber?: CommonColumnCustomDef<P, T, R, S>
  } = {}
  dynamicColumns: {
    [externalId: string]: {
      getColumn: (
        baseColumnDef: ColDef, // Base AgGrid ColDef generated from ui meta. If necessary extend this. FYI: colId and filed property must be unique.
        ctx: BulkSheetContext<P, T, R, S>,
        viewMeta: ViewMeta
      ) => Promise<ColDef[]> | ColDef[] // Allow async function. ex) get from server.
    }
  } = {}
  getCellStyle?: (field: string) => CellStyle | CellStyleFunc | undefined
  getDefaultContext: () => any = () => undefined
  // Event options
  onFilterChanged?: (ctx: BulkSheetContext<P, T, R, S>) => void
  onSortChanged?: (ctx: BulkSheetContext<P, T, R, S>) => void
  onColumnVisible?: (
    e: ColumnVisibleEvent,
    ctx: BulkSheetContext<P, T, R, S>
  ) => void

  // Quick filters. refer toolbar items.
  filteredRowDataUuids?: string[] | undefined = undefined
  getRowDataUuidForFilter?: (rowNode) => string
  refreshFilteredRowDataUuid?: (state: S) => void

  // CRUD options
  fetchDataOnInit: boolean = true
  onSubmit?: (
    ctx: BulkSheetContext<P, T, R, S>,
    data: {
      added: R[]
      edited: {
        before: R
        after: R
      }[]
      deleted: R[]
    },
    viewMeta: ViewMeta
  ) => Promise<APIResponse | APIResponse[]>
  // Status popper
  createWbsItemDeltaRequestByRow?: (
    editedRow: { before: R; after: R },
    viewMeta: ViewMeta
  ) => WbsItemDeltaInput

  abstract getAll(
    state: S,
    props?: BulkSheetProps<P, T, R, S>,
    ctx?: BulkSheetContext<P, T, R, S>
  ): Promise<APIResponse>

  beforeAdd?: (
    ctx: BulkSheetContext<P, T, R, S>,
    row: R[],
    parentUuid?: string
  ) => void
  beforeRemove?: (
    ctx: BulkSheetContext<P, T, R, S>,
    row: R[],
    parentUuid?: string
  ) => void

  // UI state options
  columnAndFilterStateKey?:
    | UiStateKey
    | ((ctx: BulkSheetContext<P, T, R, S>) => string)
  searchConditionStateKeySuffix?:
    | UiStateKey
    | ((ctx: BulkSheetContext<P, T, R, S>) => string)
  getSearchCondition?: (state: S) => any
  restoreSearchCondition?: (
    searchCondition: any,
    ctx: BulkSheetContext<P, T, R, S>,
    options?: RestoreSearchConditionOptions
  ) => Promise<void> | void
  refreshConditionAndColumn?: (ctx: BulkSheetContext<P, T, R, S>) => void
  hiddenSearchFilterIcon: boolean = false

  // Server side options
  isServerSideRowModel: boolean = false
  getChildren?: (
    state: S,
    treeRootUuid?: string,
    maxDepth?: number
  ) => Promise<APIResponse>

  // TODO Remove it after all and quick will be merged
  getCopiedRowDataWithChildren?: (
    ctx: BulkSheetContext<P, T, R, S>,
    treeRootUuid?: string
  ) => Promise<R[]>

  // Function specific
  getOpenDetailSpec?: (row: R) => Promise<OpenDetailSpec> | undefined
  updateState: (root: Tree<T>, state: S) => S = (root, state) => state
  updateDefaultState: (
    state: S,
    applicationFunctionUuid: string,
    ctx?: BulkSheetContext<P, T, R, S>
  ) => Promise<S> = state => Promise.resolve(state)
  getTaskActualResultKey: (params: CellClickedEvent) => string | undefined = (
    params: CellClickedEvent
  ) => params.data.uuid
  generateContextMenuItems?: (
    params: GetContextMenuItemsParams,
    ctx: BulkSheetContext<P, T, R, S>
  ) => ContextMenu | undefined
  getUpdatedRowAncestors?: (
    uuid: string,
    treeRootUuid?: string,
    state?: S
  ) => Promise<T>
  refreshAfterUpdateSingleRow?: (
    uuid: string,
    ctx: BulkSheetContext<P, T, R, S>
  ) => Promise<void>
  onRowDataChanged: (ctx: BulkSheetContext<P, T, R, S>) => void
  uniqueColumnIds?: string[]
  mergeRowCommentSummary?: (
    targetRow: R,
    comment: List<Comment> | undefined
  ) => R
  getKeyBindListeners?: (ctx: BulkSheetContext<P, T, R, S>) => KeyBindListener[]
  // TODO Remove it after shortcut keys implement.
  isSetKeyBind: boolean = false
  // TODO Remove it after inverting dependency of sprint report and BulkSheet.
  paddingHeightForDisplayFooter?: boolean = false
}

export type AgGridValueGetter = (params: ValueGetterParams) => any
export type AgGridValueSetter = (params: ValueSetterParams) => any
export type AgGridCellChanger = (event: CellValueChangedEvent) => any

interface PathProps {
  code: string
  uuid: string
}

type StateProps = {
  functions: Function[]
  projectUuid?: string
  user?: UserDetail
  functionLayers: Map<number, FunctionLayer>
  edited: boolean
  comments?: List<Comment>
  informationIsOpen: boolean
}

interface OwnProps<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> {
  uuid: string
  externalId: string
  options: BulkSheetOptions<P, T, R, S>
  viewMeta?: ViewMeta
  charts?: (ctx: BulkSheetContext<P, T, R, S>) => JSX.Element[]
  treeRootUuid?: string
  specificProps?: P
  useUiMetaOf?: (_?) => APPLICATION_FUNCTION_EXTERNAL_ID
  hideHeader?: boolean
  hideToolbar?: boolean

  setBulkSheet?: (ctx: BulkSheet<P, T, R, S>) => void
  filteredUuids?: string[]
  applyQuickFilters?: () => void
  gridOptions?: GridOptions
  setFilteredColumns?: (columns: ColDef[]) => void
  setSortedColumns?: (columns: string[]) => void
  setRowHeight?: (height: number) => void
  setSubmitDisabled?: (value: boolean) => void
  setIsLoading?: (value: boolean) => void
  // TODO Remove it
  ganttParameter?: GanttParameterVO
  setShowGantt?: (value: boolean) => void
  setSortColumnsState?: (value: SortedColumnState[]) => void
}

export interface BulkSheetSpecificProps {}

export interface BulkSheetProps<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> extends OwnProps<P, T, R, S>,
    StateProps,
    RouteComponentProps<PathProps>,
    WrappedComponentProps,
    WithKeyBindProps {}

export class BulkSheet<
  P extends BulkSheetSpecificProps,
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> extends React.Component<BulkSheetProps<P, T, R, S>, S> {
  agGridRef = React.createRef<AgGridReact>()
  gridApi?: GridApi
  columnApi?: ColumnApi
  treeProperty?: FunctionProperty = undefined
  treeElem?: Element
  agGridElem?: Element
  excel?: Excel<T, R>
  treeRoot: {
    uuid?: string
    lockVersion?: number
    revision?: string
  } = {}
  eventCache: CellValueChangedEvent[] = []

  // column state
  isAlreadyRestoredColumnState = false
  isAlreadyRestoredFilterState = false
  isAlreadyRestoredSearchCondition = false
  rememberedColumnAndFilterState?: {
    column: any
    filter: any
  }
  rememberedSearchCondition?: any
  rememberedRowState?: {
    rowHeight?: number
    expandedRow?: string[]
  }
  rememberedSelectedUIState: SelectedUIState = {
    searchConditionUuid: undefined,
    searchConditionIncludeColumn: false,
    columnAndFilterUuid: undefined,
  }
  dynamicColIds: string[] = []
  defaultColumnState: ColumnState[] = []

  focusedRowUuid?: string

  viewMeta: ViewMeta
  rowDataManager: RowDataManager<T, R, S>
  options: BulkSheetOptions<P, T, R, S>
  rowDataSpec: RowDataSpec<T, R>

  // D&D state
  dragNode?: RowNode
  overNode?: RowNode
  dragOnTreeColumn = false

  requireSave: boolean = false
  isServerSideRowModel: boolean = false
  rowHeight: number
  minRowHeight: number
  expandedRow: Set<string>

  detailColumns: (string | Column)[]
  fn: any

  updatingLastSelectedUIState: boolean = false

  skipRefreshDynamicColumns = false

  constructor(props: BulkSheetProps<P, T, R, S>) {
    super(props)
    this.rowDataSpec = this.props.options.rowDataSpec
    this.options = this.props.options
    this.isServerSideRowModel = this.options.isServerSideRowModel
    this.rowDataManager = this.isServerSideRowModel
      ? new ServerSideRowDataManager(this)
      : new ClientSideRowDataManager(this)
    this.rowHeight = this.options.rowHeight
    this.minRowHeight = this.rowHeight
    this.expandedRow = new Set<string>()
    this.detailColumns = []
    this.fn = getFunctionByPath(window.location.pathname)
  }

  private getKey = async () => {
    let projectUuid: string | undefined = this.props.projectUuid
    if (!projectUuid) {
      const response =
        this.props.match &&
        this.props.match.params.code &&
        this.fn &&
        this.fn.cockpit === Cockpit.Project
          ? await Project.getBasicByCode(this.props.match.params.code)
          : undefined
      projectUuid = response ? response.json.uuid : ''
      if (projectUuid && this.props.functionLayers.size === 1) {
        store.dispatch(loginToProject(projectUuid))
      }
    }
    const uuid =
      projectUuid ||
      // Use tenantUuid as the key since tenant(= non-project) features, like Divisions, may not have a uuid.
      Auth.getCurrentTenant()!.tenantUuid
    return {
      uuid,
    }
  }

  async componentDidMount() {
    const useUiMetaOf = this.props.useUiMetaOf
      ? this.props.useUiMetaOf()
      : undefined
    const func = this.props.functions.find(f => f.externalId === useUiMetaOf)
    this.viewMeta = this.props.viewMeta
      ? this.props.viewMeta
      : new ViewMeta(
          func ? func.uuid : this.props.uuid,
          useUiMetaOf ? useUiMetaOf : this.props.externalId
        )
    const { uuid } = await this.getKey()
    let projectUuid
    if (this.fn?.cockpit === Cockpit.Project) {
      projectUuid = uuid
    }
    this.viewMeta.setContext(
      this.getApplicationContext(projectUuid && [projectUuid])
    )
    await this.viewMeta.getOrFetchFunctionMeta()
    this.treeProperty = this.viewMeta.functionMeta.treeProperty
    this.setState(await this.getDefaultState(uuid))
    this.addKeyBindListener()
  }

  private getApplicationContext = (
    defaultKeys?: string[]
  ): ApplicationContext | undefined => {
    if (this.options.getApplicationContext) {
      return this.options.getApplicationContext(this)
    }
    return defaultKeys && { groupKeys: defaultKeys }
  }

  setHeaderComponents = () => {
    if (this.options.headerComponents) {
      const headerComponents = this.options.headerComponents(this)
      if (headerComponents) {
        store.dispatch(
          putHeaderComponent(
            headerComponents.titleComponent,
            headerComponents.customHeaderComponents
          )
        )
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<BulkSheetProps<P, T, R, S>>,
    prevState: Readonly<BulkSheetState>
  ) {
    if (!prevState || !prevProps) {
      return
    }

    if (!_.isEqual(prevProps.comments, this.props.comments)) {
      this.updateRowDataCommentSummary(this.props.comments)
    }

    if (this.state.selectedCommentUuid && !this.props.informationIsOpen) {
      this.setState({ selectedCommentUuid: undefined })
      store.dispatch(closeComment())
      this.gridApi!.refreshCells({
        columns: this.detailColumns,
        force: true,
      })
    }
  }

  setContext = (newContext: any) => {
    // Not use setState since ag-grid does not recognize that context has been changed.
    this.state.gridOptions.context = {
      ...this.state.gridOptions.context,
      ...newContext,
    }
  }

  refreshGrid = async () => {
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      this.viewMeta.setContext(this.getApplicationContext())
      await this.viewMeta.getOrFetchFunctionMeta()
      this.treeProperty = this.viewMeta.functionMeta.treeProperty
      const newColumnDefs = this.createColumnDefs()
      const newGridOptions = this.state.gridOptions
      this.mergeDefaultParams(newColumnDefs, newGridOptions.columnTypes!)
      newGridOptions.columnDefs = newColumnDefs
      const columnAndFilterStateProp = await this.getColumnAndFilterState()
      if (columnAndFilterStateProp && columnAndFilterStateProp.value) {
        const columnAndFilterState = JSON.parse(columnAndFilterStateProp.value)
        if (!columnAndFilterState) {
          return
        }
        this.rememberedColumnAndFilterState = { ...columnAndFilterState }
        this.isAlreadyRestoredColumnState = false
        this.isAlreadyRestoredFilterState = false
      }
      this.setState({ gridOptions: newGridOptions }, () => {
        this.state.gridOptions.api!.setColumnDefs(newGridOptions.columnDefs!)
        this.state.gridOptions.api!.setAutoGroupColumnDef(
          this.createAutoGroupColumnDef()!
        )
        this.refreshData()
      })
    } finally {
      this.setState({ isLoading: false })
      this.props.setIsLoading?.(false)
    }
  }

  removeRowKeyEventListener = () => {
    if (!this.checkRemovable().removable) return
    this.handleRemove()
  }
  copyRowKeyEventListener = () => {
    if (!this.checkCopiable()) return
    this.copyRowData()
  }
  cutRowKeyEventListener = () => {
    if (!this.checkCopiable()) return
    this.cutRowData()
  }
  pasteRowKeyEventListener = (
    pasteAsChildren: boolean = false,
    nameOnly: boolean = false
  ) => {
    if (!this.treeProperty || !this.checkPastable(true).pastable) return
    if (this.options.getPasteColumnsCandidate && !nameOnly) {
      this.displayPasteColumnsSelectionDialog(pasteAsChildren)
    } else {
      this.handlePaste(pasteAsChildren)
    }
  }
  insertCutRowKeyEventListener = () => {
    if (!this.canInsertCutRow().addable) return
    this.insertCutRowData(this.extractNodeInSelectedRange()[0])
  }

  private addKeyBindListener = () => {
    this.props.addKeyBindListeners([
      {
        key: KEY_SAVE,
        fn: this.onSubmit,
        stopDefaultBehavior: true,
      },
      {
        key: KEY_TAB,
        fn: this.moveOnTab,
      },
      {
        key: KEY_SPACE,
        fn: this.toggleTreeExpansion,
      },
      ...(this.options.getKeyBindListeners
        ? this.options.getKeyBindListeners(this)
        : []),
    ])
  }

  // This is necessary not to focus in after tabToNextCell
  private moveOnTab = () => {
    if (!this.gridApi || !this.columnApi) {
      return
    }
    // Don't stop editing when the column before move is autocomplete,
    // since stopEditing cancels to set value.
    if (document.activeElement!.classList.contains('ag-cell')) {
      this.gridApi.stopEditing()
    }
  }

  toggleTreeExpansion = () => {
    if (
      !this.treeProperty ||
      !document.activeElement!.classList.contains('ag-cell')
    ) {
      return
    }
    const focusPosition = this.gridApi!.getFocusedCell()
    if (!focusPosition) {
      return
    }
    const focusedNode = this.gridApi!.getDisplayedRowAtIndex(
      focusPosition.rowIndex
    )
    if (focusedNode && focusedNode.isExpandable()) {
      this.gridApi!.setRowNodeExpanded(focusedNode, !focusedNode.expanded)
    }
  }

  componentWillUnmount() {
    this.props.removeKeyBindListeners()
  }

  beforeRenderDynamicColumn() {
    if (
      !this.state.gridOptions ||
      !this.state.gridOptions.columnDefs ||
      !Object.keys(this.options.dynamicColumns).length
    ) {
      return false
    }
    return this.state.gridOptions.columnDefs.some(
      (colDef: ColDef) =>
        colDef.colId && this.options.dynamicColumns[colDef.colId]
    )
  }

  onRowGroupOpened = async (event: any) => {
    if (this.isServerSideRowModel) {
      this.rowDataManager.onRowGroupOpened!(event)
      return
    }
    const id = event.node.id
    const initSize = this.rowDataManager.expandedGroupIds.size
    if (!id) return
    if (event.node.expanded) {
      // Remember up to 200 rows.
      if (this.rowDataManager.expandedGroupIds.size < 200) {
        this.rowDataManager.expandedGroupIds.add(id)
      }
    } else {
      this.rowDataManager.expandedGroupIds.delete(id)
    }
    if (initSize !== this.rowDataManager.expandedGroupIds.size) {
      this.rememberRowState()
    }
  }

  async rememberRowState() {
    // TODO Need to remove deleted wbs item uuid.
    const expandedRows = Array.from(this.rowDataManager.expandedGroupIds.keys())
    this.rememberedRowState = {
      rowHeight: this.rowHeight,
      expandedRow: expandedRows,
    }
    await this.updateUiState(
      {
        key: this.getRowStateKey(),
        scope: UiStateScope.User,
        value: JSON.stringify(this.rememberedRowState),
      },
      this.viewMeta.uuid
    )
  }

  async rememberCurrentColumnState() {
    if (
      !this.getColumnAndFilterStateKey() ||
      this.beforeRenderDynamicColumn() ||
      !this.isAlreadyRestoredColumnState
    ) {
      return
    }
    const currentState: UIState = this.getColumnAndFilterUIState()
    this.rememberColumnAndFilterState({
      ...this.rememberedColumnAndFilterState,
      column: currentState.column,
    })
  }

  async rememberCurrentFilterState() {
    if (
      !this.getColumnAndFilterStateKey() ||
      this.beforeRenderDynamicColumn() ||
      !this.isAlreadyRestoredFilterState
    ) {
      return
    }
    const currentState: UIState = this.getColumnAndFilterUIState()
    this.rememberColumnAndFilterState({
      ...this.rememberedColumnAndFilterState,
      filter: currentState.filter,
    })
  }

  async rememberCurrentColumnAndFilterState() {
    if (
      !this.getColumnAndFilterStateKey() ||
      this.beforeRenderDynamicColumn() ||
      !(this.isAlreadyRestoredColumnState && this.isAlreadyRestoredFilterState)
    ) {
      return
    }
    this.rememberColumnAndFilterState(this.getColumnAndFilterUIState())
  }

  async rememberColumnAndFilterState(newColumnAndFilterState: UIState) {
    if (
      !_.isEqual(
        this.rememberedColumnAndFilterState,
        newColumnAndFilterState
      ) &&
      (newColumnAndFilterState.column || newColumnAndFilterState.filter)
    ) {
      this.rememberedColumnAndFilterState = newColumnAndFilterState
      await this.resetSelectedSavedColumnAndFilter()
      if (this.rememberedSelectedUIState.searchConditionIncludeColumn) {
        this.resetSelectedSavedSearchCondition()
      }
      await this.updateUiState(
        {
          key: this.getColumnAndFilterStateKey()!,
          scope: UiStateScope.User,
          value: JSON.stringify(this.rememberedColumnAndFilterState),
        },
        this.viewMeta.uuid
      )
    }
  }

  async rememberCurrentSearchCondition() {
    if (!this.options.getSearchCondition) {
      return
    }
    this.rememberSearchCondition(this.options.getSearchCondition(this.state))
  }

  async rememberSearchCondition(newSearchCondition: UIState) {
    if (
      newSearchCondition.searchFilter &&
      newSearchCondition.searchFilter.disableRestore
    ) {
      // do not store when searchfilter is disableRestore=true
      return
    }
    if (!_.isEqual(this.rememberedSearchCondition, newSearchCondition)) {
      this.rememberedSearchCondition = newSearchCondition
      await this.resetSelectedSavedSearchCondition()
      if (this.rememberedSelectedUIState.searchConditionIncludeColumn) {
        await this.resetSelectedSavedColumnAndFilter()
      }
      await this.updateUiState(
        {
          key: this.getSearchConditionStateKey(),
          scope: UiStateScope.User,
          value: JSON.stringify(this.rememberedSearchCondition || {}),
        },
        this.viewMeta.uuid
      )
    }
  }

  private resetSelectedSavedSearchCondition = async () => {
    if (this.rememberedSelectedUIState.searchConditionUuid) {
      await this.rememberSelectedSavedSearchCondition(undefined)
    }
  }

  private rememberSelectedSavedSearchCondition = async (
    savedUIStateUuid: string | undefined,
    includeColumn: boolean = false
  ) => {
    if (
      !_.isEqual(
        this.rememberedSelectedUIState.searchConditionUuid,
        savedUIStateUuid
      )
    ) {
      this.rememberedSelectedUIState = {
        ...this.rememberedSelectedUIState,
        searchConditionUuid: savedUIStateUuid,
        searchConditionIncludeColumn: includeColumn,
      }
      await this.updateUiState(
        {
          key: this.getSelectedSavedUIStateKey(),
          scope: UiStateScope.User,
          value: JSON.stringify(this.rememberedSelectedUIState || {}),
        },
        this.viewMeta.uuid
      )
    }
  }

  private resetSelectedSavedColumnAndFilter = async () => {
    if (
      this.rememberedSelectedUIState.columnAndFilterUuid &&
      !this.state.lastSelectedUIState?.columnAndFilterUuid
    ) {
      await this.rememberSelectedSavedColumnAndFilter(undefined)
    }
  }

  private rememberSelectedSavedColumnAndFilter = async (
    savedUIStateUuid: string | undefined
  ) => {
    if (
      !_.isEqual(
        this.rememberedSelectedUIState.columnAndFilterUuid,
        savedUIStateUuid
      )
    ) {
      this.rememberedSelectedUIState = {
        ...this.rememberedSelectedUIState,
        columnAndFilterUuid: savedUIStateUuid,
      }
      await this.updateUiState(
        {
          key: this.getSelectedSavedUIStateKey(),
          scope: UiStateScope.User,
          value: JSON.stringify(this.rememberedSelectedUIState || {}),
        },
        this.viewMeta.uuid
      )
    }
  }

  rememberSelectedColumnAndFilterByLastSelected = async () => {
    if (this.updatingLastSelectedUIState) {
      return
    }
    if (this.state.lastSelectedUIState?.columnAndFilterUuid) {
      try {
        this.updatingLastSelectedUIState = true
        await this.rememberSelectedSavedColumnAndFilter(
          this.state.lastSelectedUIState.columnAndFilterUuid
        )
        this.setState({
          lastSelectedUIState: {
            ...this.state.lastSelectedUIState,
            columnAndFilterUuid: undefined,
          },
        })
      } finally {
        this.updatingLastSelectedUIState = false
      }
    }
  }

  restoreColumnState(force = false) {
    if (!this.columnApi || (this.isAlreadyRestoredColumnState && !force)) {
      return
    }
    if (
      this.rememberedColumnAndFilterState &&
      this.rememberedColumnAndFilterState.column &&
      this.rememberedColumnAndFilterState.column.length
    ) {
      /*
        Check finished render dynamic column.
        Because move dynamic column to invalid position if restore dynamic column before render dynamic column.
       */
      const specialColIds = ['drag', 'rowNumber', 'treeRowNumber', 'detail']
      const allColumnState = this.columnApi.getColumnState() || []
      const specialColumnState = allColumnState.filter(v =>
        specialColIds.includes(v.colId || '')
      )
      const columnState = this.rememberedColumnAndFilterState.column.filter(
        v => !specialColIds.includes(v.colId || '')
      )
      // TODO Remove it
      const ganttColumnState = this.rememberedColumnAndFilterState.column
        .filter(v => v.colId === 'ganttChart')
        .map(v => {
          v['width'] = getGanttChartWidth(this.props.ganttParameter)
          return v
        })
      if (!this.beforeRenderDynamicColumn()) {
        this.columnApi.applyColumnState({
          state: [...specialColumnState, ...columnState, ...ganttColumnState],
          applyOrder: true,
        })
      }
    }
    this.isAlreadyRestoredColumnState = true
  }

  restoreFilterState() {
    const filterState = this.rememberedColumnAndFilterState?.filter
    if (filterState) {
      this.gridApi!.setFilterModel(filterState)
      // Remove filter of deleted columns
      for (let key in filterState) {
        const colDef = this.gridApi!.getColumnDef(key)
        if (!colDef) {
          delete this.rememberedColumnAndFilterState?.filter[key]
        }
      }
      this.isAlreadyRestoredFilterState = true
      this.gridApi?.onFilterChanged()
    }

    if (!this.isServerSideRowModel) {
      // TODO Fix server side row model expantion error
      this.gridApi?.forEachNode(node => {
        if (
          node &&
          node.id &&
          this.rowDataManager.expandedGroupIds.has(node.id)
        ) {
          node.setExpanded(true)
        }
      })
    }
    this.isAlreadyRestoredFilterState = true
  }

  async restoreSearchCondition(options?: RestoreSearchConditionOptions) {
    if (this.rememberedSearchCondition && this.options.restoreSearchCondition) {
      try {
        await this.options.restoreSearchCondition(
          this.rememberedSearchCondition,
          this,
          options
        )
      } catch (e) {
        // Ignore error getting search condition
      }
      this.isAlreadyRestoredSearchCondition = true
    }
  }

  resetSpecificColumnFilter = (column: string) => {
    const filterInstance = this.gridApi!.getFilterInstance(column)
    if (filterInstance) {
      filterInstance.setModel(null)
      // Wait for custom filter state is applied.
      // ex) DateCellFilter, CommentCellFilter.
      setTimeout(() => this.gridApi!.onFilterChanged(), 100)
    }
  }

  resetColumnAndFilterState = () => {
    this.rememberedColumnAndFilterState = undefined
    this.columnApi!.applyColumnState({
      state: this.defaultColumnState,
      applyOrder: true,
    })
    this.gridApi?.setFilterModel(null)
    this.gridApi?.onFilterChanged()
    if (this.options.refreshConditionAndColumn) {
      this.options.refreshConditionAndColumn(this)
    }
  }

  private getColumnAndFilterStateKey = (): string | undefined => {
    if (!this.options.columnAndFilterStateKey) {
      return undefined
    }

    if (typeof this.options.columnAndFilterStateKey === 'function') {
      return this.options.columnAndFilterStateKey(this)
    }
    return this.options.columnAndFilterStateKey
  }

  private getSearchConditionStateKey = (): string => {
    let suffix = ''
    if (this.options.searchConditionStateKeySuffix) {
      if (typeof this.options.searchConditionStateKeySuffix === 'function') {
        suffix = `-${this.options.searchConditionStateKeySuffix(this)}`
      } else {
        suffix = `-${this.options.searchConditionStateKeySuffix}`
      }
    }
    return `${UiStateKey.BulkSheetSearchCondisionState}${suffix}`
  }

  private getRowStateKey = (): string => {
    const keys: string[] = [UiStateKey.BulkSheetState]
    if (this.props.treeRootUuid) {
      keys.push(this.props.treeRootUuid)
    }
    return keys.join('-')
  }

  private getSelectedSavedUIStateKey = (): string => {
    return `${UiStateKey.BulkSheetSelectedUIState}${this.state.uuid}`
  }

  expandGroupRow = () => {
    this.gridApi!.expandAll()
  }
  expandChildRows = (node: RowNode) => {
    if (node.allLeafChildren) {
      node.allLeafChildren.forEach(node => {
        if (node.isExpandable() && !node.expanded) {
          node.setExpanded(true)
        }
      })
    }
  }

  collapseChildRows = (node: RowNode) => {
    const children = this.rowDataManager.getFlatDescendants(node.id!)
    children.forEach(child => {
      const node = this.gridApi!.getRowNode(child.uuid)
      if (node && node.expanded) {
        node.setExpanded(false)
      }
    })
  }

  setupAgGridElem = () => {
    const root = document.getElementsByClassName('ag-root')
    if (root) {
      this.agGridElem = Array.from(root)[0]
    }
  }

  async getDefaultState(uuid: string) {
    const state = {
      uuid,
      submitType: this.getSubmitType(uuid),
      gridOptions: await this.getGridOptions(),
      rowData: [] as RowData[],
      initialRowData: {},
      inProgress: false,
      editable: true,
      hasUpdatePermission: true,
      taskActualWorkDialog: {
        open: false,
        taskUuid: '',
      },
      attachmentListDialog: {
        open: false,
        onClose: () => {
          /** do nothing */
        },
        attachments: [],
      } as unknown,
      alertDialogState: {
        isOpen: false,
      },
      savedBulkSheetUIStateDialogState: {
        open: false,
      },
      addRowCountInputDialogState: {
        open: false,
      },
      clipboard: [] as RowData[],
      isLoading: false,
      filteredColumns: [] as ColDef[],
      sortedColumns: [] as string[],
      rowHeight: this.options.rowHeight.valueOf(),
      watchDataUuids: [] as string[],
      submitDisabled: true,
      sortColumnsState: [] as SortedColumnState[] | undefined,
    } as S
    return this.options.updateDefaultState(state, this.props.uuid)
  }

  getSubmitType = (uuid: string) => {
    return uuid ? SubmitType.Update : SubmitType.Create
  }

  onGridReady = async (event: GridReadyEvent) => {
    if (!this.agGridRef.current) {
      return
    }
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      this.gridApi = event.api
      this.columnApi = event.columnApi
      this.gridApi.addEventListener('rangeSelectionChanged', function (event) {
        rangeSelectionChanged(event.api, event)
      })
      const columnToolPanel = this.gridApi.getToolPanelInstance('columns')
      // Side bar is optional
      if (columnToolPanel) {
        // @ts-ignore
        columnToolPanel.collapseColumnGroups()
        // @ts-ignore
        columnToolPanel.setRowGroupsSectionVisible(false)
      }
      this.excel = new Excel<T, R>(
        this.gridApi,
        this.columnApi,
        this.viewMeta,
        this.treeProperty
      )
      this.setHeaderComponents()
      const rowStateProp = await this.getRowState()
      if (rowStateProp && rowStateProp.value) {
        const rowState = JSON.parse(rowStateProp.value)
        if (!rowState) {
          return
        }
        this.rememberedRowState = { ...rowState }
        if (this.rememberedRowState!) {
          this.rowHeight =
            this.rememberedRowState.rowHeight || this.minRowHeight
          this.setState(
            {
              rowHeight: this.rememberedRowState.rowHeight || this.minRowHeight,
            },
            () => {
              this.props.setRowHeight &&
                this.props.setRowHeight(this.state.rowHeight)
            }
          )
          if (!this.isServerSideRowModel) {
            this.rememberedRowState.expandedRow?.forEach(key =>
              this.rowDataManager.expandedGroupIds.add(key)
            )
          }
        }
      }
      let searchConditionStateHasColumnAndFilterState: boolean = false
      const searchConditionStateProp = await this.getSearchConditionState()
      if (searchConditionStateProp && searchConditionStateProp.value) {
        const urlQueryStringParams = getUrlQueryObject() as {
          arg?: string
        }
        const searchConditionStateCode = urlQueryStringParams?.arg
        let isRestoredSavedSearchConditionByUser = false
        if (searchConditionStateCode) {
          const searchConditionState =
            await this.getSearchConditionStateByStateCode(
              searchConditionStateCode
            )

          if (searchConditionState) {
            this.rememberedSearchCondition =
              searchConditionState.UIState.searchCondition
            isRestoredSavedSearchConditionByUser = true
            if (
              'column' in searchConditionState.UIState &&
              'filter' in searchConditionState.UIState
            ) {
              searchConditionStateHasColumnAndFilterState = true
              this.rememberedColumnAndFilterState = {
                column: searchConditionState.UIState.column,
                filter: searchConditionState.UIState.filter,
              } as any
            }
          } else if (this.options.restoreSearchCondition) {
            store.dispatch(
              addGlobalMessage({
                type: MessageLevel.WARN,
                title: this.props.intl.formatMessage({
                  id: 'bulksheet.warning.restore.searchCondition',
                }),
                text: this.props.intl.formatMessage({
                  id: 'bulksheet.warning.restore.searchCondition.detail',
                }),
              })
            )
          }
        } else {
          this.rememberedSearchCondition = JSON.parse(
            searchConditionStateProp.value
          )
        }
        await this.restoreSearchCondition({
          isRestoredSavedSearchConditionByUser,
        })
      }

      if (!searchConditionStateHasColumnAndFilterState) {
        const columnAndFilterStateProp = await this.getColumnAndFilterState()
        if (columnAndFilterStateProp && columnAndFilterStateProp.value) {
          const columnAndFilterState = JSON.parse(
            columnAndFilterStateProp.value
          )
          if (!columnAndFilterState) {
            return
          }
          this.rememberedColumnAndFilterState = { ...columnAndFilterState }
        } else {
          this.isAlreadyRestoredFilterState = true
        }
      }

      const selectedUIStateProps = await this.getSelectedSavedUISate()
      if (selectedUIStateProps && selectedUIStateProps.value) {
        const selectedUIState = JSON.parse(selectedUIStateProps.value)
        this.setState({ lastSelectedUIState: selectedUIState })
      }

      // For show loading.
      this.setupAgGridElem()

      if (this.isServerSideRowModel) {
        this.restoreColumnState()
      } else if (
        this.options.fetchDataOnInit &&
        !this.isAlreadyRestoredSearchCondition // Refresh after restoring search condition
      ) {
        await this.refreshData()
      }

      if (this.isServerSideRowModel) {
        this.gridApi.setServerSideDatasource(
          ServerSideDataSource(
            this,
            this.rowDataManager as ServerSideRowDataManager<T, R, S>
          )
        )
      }

      const urlQueryString = getUrlQueryObject()
      if (!isNaN(Number(urlQueryString?.rowBuffer))) {
        this.setState({ rowBuffer: urlQueryString.rowBuffer as number })
      }

      this.props.setBulkSheet && this.props.setBulkSheet(this)

      this.setState({ submitDisabled: false }, () => {
        this.props.setSubmitDisabled && this.props.setSubmitDisabled(false)
      })
    } finally {
      // Timeout to prevent scrolling on gantt chart
      setTimeout(() => {
        this.setState({ isLoading: false })
        this.props.setIsLoading?.(false)
      }, 300)
    }
  }

  onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    this.refreshPinnedData()
    if (!this.isServerSideRowModel) {
      this.restoreFilterState()
      // Need to fire FilterChange event if column filter is not present.
      // AgGrid fire FilterChange event in restoreFilterState if column filter is present.
      if (
        this.options.refreshFilteredRowDataUuid &&
        !this.gridApi!.isColumnFilterPresent()
      ) {
        this.gridApi!.onFilterChanged()
      }
    }
  }

  getGridOptions = async (): Promise<GridOptions> => {
    const colTypes = {
      ...columnTypes(),
      ...this.rowDataSpec.columnTypes(),
    }
    const gridOptions: GridOptions = {
      defaultColDef: defaultColDef({
        editable: true,
        sortable: !this.isServerSideRowModel,
      }),
      columnTypes: colTypes,
      sideBar: sideBar(),
      components: frameworkComponents,
      suppressScrollOnNewData: true,
      columnDefs: this.createColumnDefs(),
      autoGroupColumnDef: this.createAutoGroupColumnDef(),
      getRowStyle: params => {
        let style: { [cssProperty: string]: string } = {}
        const customStyle = this.options.getRowStyle
          ? this.options.getRowStyle(params)
          : undefined
        if (customStyle) {
          style = {
            ...style,
            ...(customStyle as { [cssProperty: string]: string }),
          }
        }
        return style
      },
      rowModelType: this.isServerSideRowModel ? 'serverSide' : undefined,
      isServerSideGroup: (dataItem: R): boolean =>
        dataItem.numberOfChildren ? dataItem.numberOfChildren > 0 : false,
      getServerSideGroupKey: (dataItem: ProjectPlanRow): string =>
        dataItem.uuid,
      enableGroupEdit: true,
      statusBar: {
        // @ts-ignore
        statusPanels: [
          !this.options.isServerSideRowModel && {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['sum', 'count'],
            },
            align: 'left',
          },
        ].filter(Boolean),
      },
      localeText: {
        sum: this.props.intl.formatMessage({
          id: 'bulksheet.statusPanel.sum',
        }),
        count: this.props.intl.formatMessage({
          id: 'bulksheet.statusPanel.count',
        }),
        totalAndFilteredRows: this.props.intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
        }),
        of: this.props.intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
        }),
      },
      context: {
        ...(await this.getExtensionPropertyOptions()),
        ...this.options.getDefaultContext(),
      },
    }
    const functionProperties = Array.from(
      this.viewMeta.functionMeta.properties.byId.values()
    )
    if (
      functionProperties.some(p => p.propertyType === PropertyType.GanttChart)
    ) {
      gridOptions.groupHeaderHeight = 25
      gridOptions.headerHeight = 45
    }
    this.mergeDefaultParams(gridOptions.columnDefs!, colTypes)
    return gridOptions
  }

  getExtensionPropertyOptions = async (): Promise<{
    [key: string]: object[]
  }> => {
    const props = this.viewMeta.removeParentProperty(
      this.viewMeta.functionMeta.detail.properties
    )
    const entitySearchExtensionProperties = props.filter(
      v => v.entityExtensionUuid && v.referenceEntity
    )
    const extensionOptions: { [key: string]: object[] } = {}
    for (let prop of entitySearchExtensionProperties) {
      // Fetch options
      if (_.isEmpty(extensionOptions[prop.referenceEntity!])) {
        const projectUuid = this.props.projectUuid
        // TODO Do not set complex extension search options
        // Search options for project uuid
        const searchOptions = prop.searchOptions.build({
          projectUuid,
          wbsItem: { projectUuid },
          task: { projectUuid },
        })
        const options = await repositories[prop.referenceEntity!].search(
          '',
          searchOptions,
          prop
        )
        if (!_.isEmpty(options)) {
          extensionOptions[prop.referenceEntity!] = options
        }
      }
    }
    return extensionOptions
  }

  createAutoGroupColumnDef = () => {
    if (!this.treeProperty) {
      return undefined
    }
    const renderer = this.options.customRenderers[this.treeProperty.externalId]
    const cellRendererParams = Object.assign(
      { ctx: this },
      this.options.getCellRendererParams
        ? this.options.getCellRendererParams(
            this.viewMeta.makeDataPropertyName(this.treeProperty),
            this
          )
        : {}
    )
    const types = this.options.customColumnTypes[this.treeProperty.externalId]
    const defaultValueGetter = (params: ValueGetterParams) => {
      const key = this.viewMeta.makeDataPropertyName(this.treeProperty!)
      return objects.getValue(params.data, key)
    }
    let colTypeValueGetter: String | ValueGetterFunc = ''
    let filterParams = { buttons: ['reset'] }
    let colTypeCellStyle: CellStyle | CellStyleFunc | undefined = undefined
    if (types) {
      for (const v of types) {
        const colType: ColDef | undefined = this.rowDataSpec.columnTypes()[v]
        if (colType) {
          if (colType.valueGetter) colTypeValueGetter = colType.valueGetter
          if (colType.filterParams) filterParams = colType.filterParams
          if (colType.cellStyle) colTypeCellStyle = colType.cellStyle
        }
      }
    }
    return {
      headerName: this.treeProperty.name,
      field: this.viewMeta.makeDataPropertyName(this.treeProperty),
      width: this.options.groupColumnWidth,
      pinned: this.options.pinnedColumns.includes(this.treeProperty.externalId),
      cellEditorParams: {
        uiMeta: this.treeProperty,
        viewMeta: this.viewMeta,
      },
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: renderer ? renderer : CommonTextCell.cellRenderer,
        uiMeta: this.treeProperty,
        viewMeta: this.viewMeta,
        ...cellRendererParams,
      },
      cellStyle: colTypeCellStyle,
      valueSetter: (params: ValueSetterParams) => {
        if (!params.node || params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const key = this.viewMeta.makeDataPropertyName(this.treeProperty!)
        // Do not set the same value not to merge rows
        const newValue = params.newValue || ''
        params.node.setGroupValue('ag-Grid-AutoColumn', newValue)
        objects.setValue(params.data, key, newValue)
        if (this.isServerSideRowModel) {
          const fieldName = params.colDef.field || params.colDef.colId
          if (!params.data.editedData) {
            params.data.editedData = {}
            params.data.editedData[fieldName!] = params.oldValue
          } else if (!params.data.editedData.hasOwnProperty(fieldName)) {
            params.data.editedData[fieldName!] = params.oldValue
          }
          this.rowDataManager.updateRow(params.data)
        }

        store.dispatch(requireSave())

        return true
      },
      valueGetter:
        typeof colTypeValueGetter === 'function'
          ? colTypeValueGetter
          : defaultValueGetter,
      cellClassRules: {
        'hover-over-can-add-child': params => {
          return !!(
            this.dragNode &&
            this.overNode === params.node &&
            this.dragOnTreeColumn &&
            this.canDrop({ node: this.dragNode, overNode: params.node }, true)
          )
        },
        'grid-edited-cell': params => {
          let paramsColId = params.colDef.field || params.colDef.colId
          if (!params.data || !params.data.editedData || !paramsColId) {
            return false
          }
          return (
            params.data.editedData.hasOwnProperty(paramsColId) &&
            params.value !== params.data.editedData[paramsColId]
          )
        },
      },
      filterParams: filterParams,
      filter: this.isServerSideRowModel ? false : 'clientSideTextFilter', // TODO Remove implement filter function for server side row model.
      editable: params => {
        const row: R = params.data
        const treeProperty = this.treeProperty
        let readOnly = false
        if (treeProperty) {
          readOnly = row.isAdded
            ? treeProperty.editableIfC.isFalse
            : treeProperty.editableIfU.isFalse
        }
        return !(
          readOnly ||
          !this.state.hasUpdatePermission ||
          !this.state.editable ||
          !params.data ||
          this.rootIsNotEditable(params.data.uuid) ||
          row.isViewOnly
        )
      },
      floatingFilter: !this.isServerSideRowModel,
      suppressKeyboardEvent: this.getSuppressKeyboardEventForProperty(
        this.treeProperty
      ),
    }
  }

  createDefaultColumnDefs = () => {
    const defaultColumns: (ColDef | ColGroupDef)[] = []
    if (this.options.draggable) {
      defaultColumns.push({
        field: 'drag',
        type: ColumnType.drag,
        pinned: true,
        rowDrag: params =>
          !this.state.inProgress && this.options.rowDrag(params, this),
        cellClassRules: {
          'hover-over-can-drop': params => {
            return !!(
              this.dragNode &&
              this.overNode === params.node &&
              !this.dragOnTreeColumn &&
              this.canDrop(
                { node: this.dragNode, overNode: params.node },
                false
              )
            )
          },
        },
      })
    }

    let rowNumberColumn: any = {}
    if (this.options.showTreeRowNumber) {
      rowNumberColumn = {
        field: 'treeRowNumber',
        pinned: true,
        sortable: false,
        type: ColumnType.sequenceNo,
        cellStyle: { justifyContent: 'left' },
        valueGetter: (params: ValueGetterParams) => {
          if (!params.node) return
          if (params.data.isTotal) return params.data.rowNumber
          let treeNumber: number[] = []
          const getTreeNumber = (rowNode: RowNode) => {
            const siblings = this.rowDataManager.getSiblings(rowNode.data.uuid)
            const indexInSiblings =
              siblings.findIndex(v => rowNode.id === v.uuid) -
              (siblings.some(v => v.isTotal) ? 1 : 0)
            treeNumber.push(indexInSiblings + 1)
            if (rowNode.parent && rowNode.parent.data) {
              getTreeNumber(rowNode.parent)
            }
          }
          getTreeNumber(params.node)

          return treeNumber.reverse().join('-')
        },
      }
    } else {
      rowNumberColumn = {
        field: 'rowNumber',
        sortable: false,
        type: ColumnType.sequenceNo,
      }
    }

    if (
      this.options.commonColumnCustomDefs &&
      this.options.commonColumnCustomDefs.rowNumber
    ) {
      if (this.options.commonColumnCustomDefs.rowNumber.headerName) {
        let headerName =
          this.options.commonColumnCustomDefs.rowNumber.headerName
        if (typeof headerName === 'string') {
          rowNumberColumn.headerName =
            this.options.commonColumnCustomDefs.rowNumber.headerName
        } else {
          rowNumberColumn.headerName = headerName(this)
        }
      }
      if (this.options.commonColumnCustomDefs.rowNumber.width) {
        rowNumberColumn.width =
          this.options.commonColumnCustomDefs.rowNumber.width
        rowNumberColumn.maxWidth =
          this.options.commonColumnCustomDefs.rowNumber.width
      }
    }

    if (
      this.options.customColumnTypes &&
      this.options.customColumnTypes['sequenceNo']
    ) {
      rowNumberColumn.type = this.options.customColumnTypes['sequenceNo']
    }

    defaultColumns.push(rowNumberColumn)

    return {
      headerName: ' ',
      children: defaultColumns,
    }
  }

  createColumnDefs = () => {
    const defaultColumns = this.createDefaultColumnDefs()
    const fields = this.generateFunctionColumns()
    const functionColumnFields: {
      prop: FunctionProperty
      column: ColDef
    }[] = []

    fields.forEach(f => {
      if (
        f.column.type &&
        f.column.type.includes(ColumnType.detailColumn) &&
        !!f.column.field
      ) {
        if (f.prop.parentProperty) {
          const parent = this.viewMeta.functionMeta.properties.byId.get(
            f.prop.parentProperty
          )
          if (
            parent &&
            parent.propertyType === PropertyType.Custom &&
            parent.component === Component.DefaultColumn
          ) {
            defaultColumns.children.push(f.column)
          } else {
            functionColumnFields.push(f)
          }
        }
        this.detailColumns.push(f.column.field)
      } else {
        functionColumnFields.push(f)
      }
    })
    const functionColumns = this.structFunctionColumns(functionColumnFields)
    const columnDefs = [defaultColumns, ...functionColumns]

    this.defaultColumnState = columnDefs
      .flatMap(p => p.children)
      .filter(c => !!c)
      .map(c => c as ColDef)
      .map(c => {
        return {
          colId: c.field,
          hide: !!c.hide,
          width: c.width,
          pinned: c.pinned,
          sort: null,
        } as ColumnState
      })
    return columnDefs
  }

  private structFunctionColumns = (
    fields: { prop: FunctionProperty; column: ColDef | ColGroupDef }[]
  ) => {
    const result: BulkSheetColDef[] = []
    const childrenList = {}
    this.viewMeta.functionMeta.parentPropertyIds.forEach(parentId => {
      if (!parentId) {
        return
      }
      const parent = this.viewMeta.functionMeta.properties.byId.get(parentId)!
      const group = {
        headerName: parent.name,
        headerGroupComponent:
          parent.propertyType === PropertyType.GanttChart
            ? 'ganttHeaderGroupComponent'
            : undefined,
        children: [],
      }
      result[parent.propertyOrder] = group
      childrenList[parent.externalId] = group.children
    })
    for (let i = 0; i < fields.length; i++) {
      const { prop, column } = fields[i]
      if (prop.parentProperty) {
        childrenList[prop.parentProperty][prop.propertyOrder] = column
      } else {
        result[prop.propertyOrder] = column
      }
    }
    return result
  }

  private generateFunctionColumns = () => {
    const props = this.viewMeta.removeParentProperty(
      this.viewMeta.functionMeta.detail.properties
    )
    return props.map(p => ({ prop: p, column: this.generateFunctionColumn(p) }))
  }

  private generateFunctionColumn = (prop: FunctionProperty): ColDef => {
    const pinned = this.options.pinnedColumns.includes(prop.externalId)
    const lockPosition = this.options.lockedColumns.includes(prop.externalId)
    const isDynamicColumn =
      this.options.dynamicColumns &&
      this.options.dynamicColumns[prop.externalId]
    const uiMeta = this.viewMeta.functionMeta.properties.byId.get(
      prop.externalId
    )
    const propName: string = this.viewMeta.makeDataPropertyName(prop)
    const types = this.options.customColumnTypes[prop.externalId]
    let colTypeValueGetter: String | ValueGetterFunc = ''
    let filterParams = {
      buttons: ['reset'],
      comparator: this.getFilterComparator(prop),
    }
    let colTypeCellStyle: CellStyle | CellStyleFunc | undefined = undefined
    let colTypeComparator:
      | ((
          valueA: any,
          valueB: any,
          nodeA: RowNode,
          nodeB: RowNode,
          isInverted: boolean
        ) => number)
      | undefined = undefined
    if (types) {
      for (const v of types) {
        const colType: ColDef | undefined = this.rowDataSpec.columnTypes()[v]
        if (colType) {
          if (colType.valueGetter) colTypeValueGetter = colType.valueGetter
          if (colType.filterParams) filterParams = colType.filterParams
          if (colType.cellStyle) colTypeCellStyle = colType.cellStyle
          if (colType.comparator) colTypeComparator = colType.comparator
        }
      }
    }
    const columnDef: ColDef = {
      colId: isDynamicColumn ? prop.externalId : undefined, // Specify column position by colId when generate dynamic column
      headerName: prop.name || '',
      field: propName,
      cellStyle: colTypeCellStyle,
      editable: params => {
        const row: R = params.data
        if (
          !this.state.hasUpdatePermission ||
          !this.state.editable ||
          !params.data ||
          this.rootIsNotEditable(params.data.uuid) ||
          row.isViewOnly
        ) {
          // Column group row of flat list has no data
          return false
        }
        const readOnly = this.viewMeta.isReadOnlyForProperty(
          row.isAdded ? SubmitType.Create : SubmitType.Update,
          prop
        )
        if (readOnly) {
          return false
        }
        // Custom type
        const customTypeColDef = this.getCustomTypeDef(prop)
        const editable = customTypeColDef
          ? customTypeColDef.editable
          : undefined
        if (typeof editable === 'boolean') {
          return editable
        } else if (typeof editable === 'function') {
          return editable(params)
        }
        return true
      },
      width: this.getWidthForProperty(prop),
      type: this.getTypeForProperty(prop),
      pinned,
      lockPosition,
      suppressMovable: lockPosition,
      // This hidden column is for import/export since "autoGroupColumnDef" can't be placed at expected column index.
      hide: prop.tree || this.isHiddenProperty(prop),
      suppressColumnsToolPanel: prop.tree,
      valueGetter:
        typeof colTypeValueGetter === 'function'
          ? colTypeValueGetter
          : this.getValueGetterForProperty(prop),
      valueSetter: this.options.getValueSetter
        ? this.options.getValueSetter(propName)
        : undefined,
      cellEditorParams: Object.assign(
        {},
        this.options.getCellEditorParams
          ? this.options.getCellEditorParams(propName, prop, this)
          : undefined,
        {
          uiMeta,
        }
      ),
      cellRendererParams: Object.assign(
        { ctx: this },
        this.getCellRendererParams(prop),
        {
          uiMeta,
        }
      ),
      onCellClicked: this.getOnCellClickedForProperty(prop, this.viewMeta),
      onCellDoubleClicked: () => {
        if (prop.component === Component.WbsItemStatus) {
          this.setState({
            statusPopperAnchorEl: undefined,
            statusPopperUuid: undefined,
            statusPopperWbsItem: undefined,
            statusPopperWbsItemDelta: undefined,
          })
        }
      },
      onCellValueChanged: this.options.getOnCellValueChanged
        ? this.options.getOnCellValueChanged(propName, this)
        : undefined,
      filterParams: filterParams,
      headerTooltip: prop.tooltipText,
      headerComponentParams: prop.tooltipText
        ? {
            template: customHeaderTemplate({ tooltip: 'test' }),
          }
        : undefined,
      cellClassRules: {
        'gantt-cell': () => prop.propertyType === PropertyType.GanttChart,
        'grid-edited-cell': params => {
          let paramsColId = params.colDef.field || params.colDef.colId
          if (!params.data || !params.data.editedData || !paramsColId) {
            return false
          }
          return (
            params.data.editedData.hasOwnProperty(paramsColId) &&
            params.value !== params.data.editedData[paramsColId]
          )
        },
      },
      floatingFilter: !this.isServerSideRowModel,
      suppressKeyboardEvent: this.getSuppressKeyboardEventForProperty(prop),
    }

    if (colTypeComparator) {
      columnDef.comparator = colTypeComparator
    } else {
      const sortComparator = this.getComparator(prop)
      if (sortComparator) {
        columnDef.comparator = sortComparator
      }
    }

    if (this.options.getCellStyle) {
      const style = this.options.getCellStyle(propName)
      if (style) {
        columnDef.cellStyle = style
      }
    }

    if (
      prop.propertyType === PropertyType.Custom &&
      prop.component === Component.Action
    ) {
      columnDef.sortable = false
    }

    if (
      this.isServerSideRowModel ||
      prop.propertyType === PropertyType.GanttChart
    ) {
      // TODO Remove implement filter function for server side row model.
      columnDef.filter = false
    }
    const renderer = this.options.customRenderers[prop.externalId]
    return renderer
      ? { ...columnDef, ...{ cellRenderer: renderer } }
      : columnDef
  }

  private getCellRendererParams = (
    prop: FunctionProperty
  ): { [key: string]: any } | undefined => {
    if (prop.component === Component.Action) {
      return this.createDetailColumnCellRendererParams()
    }
    if (prop.component === Component.Attachment) {
      return this.createAttachmentColumnCellRendererParams()
    }
    if (!this.options.getCellRendererParams) {
      return undefined
    } else {
      return this.options.getCellRendererParams(
        this.viewMeta.makeDataPropertyName(prop),
        this
      )
    }
  }

  private createAttachmentColumnCellRendererParams = ():
    | { [key: string]: any }
    | undefined => {
    const rendererParams = this.options.getAttachmentCellRendererParams
      ? this.options.getAttachmentCellRendererParams(this)
      : undefined
    return {
      openAttachmentList: rendererParams?.getAttachmentList
        ? async (row: R) => {
            const attachments = await rendererParams.getAttachmentList!(
              row,
              this
            )
            if (attachments.length > 0) {
              this.openAttachmentListDialog(attachments)
              this.gridApi!.refreshCells({
                columns: this.detailColumns,
                force: true,
              })
            }
          }
        : undefined,
      closeAttachmentList: rendererParams?.getAttachmentList
        ? (row: R) => {
            this.closeAttachmentListDialog()
            this.gridApi!.refreshCells({
              columns: this.detailColumns,
              force: true,
            })
          }
        : undefined,
      getAttachmentSummary: rendererParams?.getAttachmentSummary,
    }
  }

  private createDetailColumnCellRendererParams = ():
    | { [key: string]: any }
    | undefined => {
    const rendererParams = this.options.getDetailColumnCellRendererParams
      ? this.options.getDetailColumnCellRendererParams(this)
      : undefined

    return {
      onClicked: this.openDetail,
      label: rendererParams?.label,
      icon: rendererParams?.icon,
      iconTooltip: rendererParams?.iconTooltip,
      detailPath: rendererParams?.path,
      disabled: rendererParams?.disabled,
      commentOpened: (row: R): boolean => {
        return (
          !!this.state.selectedCommentUuid &&
          row.uuid === this.state.selectedCommentUuid
        )
      },
      openComment: rendererParams?.openComment
        ? (row: R) => {
            this.setState({ selectedCommentUuid: row.uuid })
            rendererParams.openComment!(row, this)
            this.gridApi!.refreshCells({
              columns: this.detailColumns,
              force: true,
            })
          }
        : undefined,
      closeComment: rendererParams?.openComment
        ? (row: R) => {
            this.setState({ selectedCommentUuid: undefined })
            store.dispatch(closeComment())
            this.gridApi!.refreshCells({
              columns: this.detailColumns,
              force: true,
            })
          }
        : undefined,
      getCommentSummary: rendererParams?.getCommentSummary,
      openAttachmentList: rendererParams?.getAttachmentList
        ? async (row: R) => {
            const attachments = await rendererParams.getAttachmentList!(
              row,
              this
            )
            if (attachments.length > 0) {
              this.openAttachmentListDialog(attachments)
              this.gridApi!.refreshCells({
                columns: this.detailColumns,
                force: true,
              })
            }
          }
        : undefined,
      closeAttachmentList: rendererParams?.getAttachmentList
        ? (row: R) => {
            this.closeAttachmentListDialog()
            this.gridApi!.refreshCells({
              columns: this.detailColumns,
              force: true,
            })
          }
        : undefined,
      getAttachmentSummary: rendererParams?.getAttachmentSummary,
      getSpeedDialActions: rendererParams?.getSpeedDialActions,
      addRowActions: rendererParams?.addRowActions,
      onClickProgressReportIcon: rendererParams?.onClickProgressReportIcon,
    }
  }

  private getCustomTypeDef = (prop: FunctionProperty) => {
    if (
      this.options.customColumnTypes &&
      this.options.customColumnTypes[prop.externalId]
    ) {
      const colDefs = this.options.customColumnTypes[prop.externalId].map(
        key => this.rowDataSpec.columnTypes()[key]
      )
      if (colDefs.length > 0) {
        return Object.assign({}, ...colDefs)
      }
    }
    return undefined
  }

  private getValueGetterForProperty(prop: FunctionProperty) {
    if (prop.entityExtensionUuid) {
      return (params: ValueGetterParams) => {
        if (!params || !params.data || !params.data.extensions) {
          return undefined
        }
        const entityExtension = params.data.extensions.find(
          v => v.uuid === prop.entityExtensionUuid
        )
        return entityExtension?.value
      }
    }
    return this.options.getValueGetter
      ? this.options.getValueGetter(
          this.viewMeta.makeDataPropertyName(prop),
          prop
        )
      : undefined
  }

  private getOnCellClickedForProperty(
    prop: FunctionProperty,
    viewMeta: ViewMeta
  ) {
    if (prop.component === Component.ActualResult) {
      return params => {
        const key = this.options.getTaskActualResultKey(params)
        if (key) {
          this.openTaskActualWorkDialog(key)
        }
      }
    }
    if (prop.component === Component.WbsItemStatus) {
      return this.getOpenStatusChangePopper(prop)
    }
    const onCellClicked = this.options.getOnCellClicked
      ? this.options.getOnCellClicked(viewMeta.makeDataPropertyName(prop), this)
      : undefined
    if (onCellClicked) return onCellClicked
    if (!_.isEmpty(this.options.customColumnTypes)) {
      const types = this.options.customColumnTypes[prop.externalId]
      if (types) {
        for (let type of types) {
          const colDef = this.rowDataSpec.columnTypes()[type]
          if (colDef && colDef.onCellClicked) {
            return colDef.onCellClicked
          }
        }
      }
    }
    return defaultOnCellClicked
  }

  private getSuppressKeyboardEventForProperty(prop: FunctionProperty) {
    return (params: SuppressKeyboardEventParams) => {
      // When editing in Japanese, any keyboard event should be suppressed.
      if (params.editing && params.event.isComposing) {
        return true
      }
      // By default, Delete / Backspace key starts cell editing,
      // but we want to just delete cell value.
      if (
        !params.editing &&
        ['Delete', 'Backspace'].includes(params.event.key)
      ) {
        this.onDelete()
        return true
      }
      // While editing with Autocomplete component,
      // avoid to move cell focus when pressing Enter / Tab key.
      if (
        params.editing &&
        (prop.propertyType === PropertyType.EntitySearch ||
          (prop.propertyType === PropertyType.Select &&
            prop.referenceEntity)) &&
        ['Enter', 'Tab'].includes(params.event.key)
      ) {
        return true
      }
      // For any key bind listener, avoid conflict with AgGrid default keyboard event.
      // TODO: Consider further for alt key, sequencial input, and so on.
      if (!params.editing && this.options.getKeyBindListeners) {
        const listeners = this.options.getKeyBindListeners(this)
        for (let listener of listeners) {
          // binded keys.
          const key = listener.key
          const eachKeys = Array.isArray(key) ? key : key.split('+')
          const modKeyIncluded = eachKeys.includes('mod')
          const shiftKeyIncluded = eachKeys.includes('shift')
          const altKeyIncluded = eachKeys.includes('alt')
          const pressedKey = eachKeys[eachKeys.length - 1]
          const pressedKeyArray = [
            pressedKey,
            pressedKey.toLowerCase(),
            pressedKey.toUpperCase(),
          ]
          // satisfactory condition check.
          const modKeyCondition =
            modKeyIncluded === (params.event.ctrlKey || params.event.metaKey)
          const shiftKeyCondition = shiftKeyIncluded === params.event.shiftKey
          const altKeyCondition = altKeyIncluded === params.event.altKey
          const pressedKeyCondition = pressedKeyArray.includes(params.event.key)
          if (
            modKeyCondition &&
            shiftKeyCondition &&
            altKeyCondition &&
            pressedKeyCondition
          ) {
            return true
          }
        }
      }
      return false
    }
  }

  private onDelete = () => {
    if (this.state.hasUpdatePermission && this.state.editable) {
      const cellRanges = this.gridApi!.getCellRanges() || []
      if (cellRanges.length === 0) {
        return
      }
      let changedRows: RowNode[] = []
      let changedColumns: Column[] = []
      cellRanges.forEach(cellRange => {
        const startRowIndex = Math.min(
          cellRange.startRow!.rowIndex,
          cellRange.endRow!.rowIndex
        )
        const endRowIndex = Math.max(
          cellRange.startRow!.rowIndex,
          cellRange.endRow!.rowIndex
        )
        for (let i = 0; i < cellRange.columns.length; i++) {
          const column = cellRange.columns[i]
          const editable = column.getColDef().editable!
          if (typeof editable === 'boolean' && !editable) {
            continue
          }
          for (let i = startRowIndex; i <= endRowIndex; i++) {
            let rowNode = this.gridApi!.getDisplayedRowAtIndex(i)
            if (
              !rowNode ||
              (editable &&
                typeof editable === 'function' &&
                !editable({
                  node: rowNode,
                  data: rowNode.data,
                  column: column,
                  colDef: column.getColDef(),
                  context: undefined,
                  api: this.gridApi!,
                  columnApi: this.columnApi!,
                }))
            ) {
              continue
            }
            rowNode.setDataValue(column, '')
            if (!changedRows.includes(rowNode)) {
              changedRows.push(rowNode)
            }
            if (!changedColumns.includes(column)) {
              changedColumns.push(column)
            }
          }
        }
      })
      this.gridApi!.clearFocusedCell()
      this.gridApi!.setFocusedCell(
        cellRanges[0].startRow!.rowIndex,
        cellRanges[0].columns[0]
      )
      this.gridApi!.refreshCells({
        rowNodes: changedRows,
        columns: changedColumns,
        force: true,
      })
      if (this.isServerSideRowModel) {
        changedRows.forEach(row => this.rowDataManager.updateRow(row.data))
      }
    }
  }

  private getComparator = (prop: FunctionProperty) => {
    if (prop.valuesAllowed) {
      return (a: string, b: string) => {
        const indexA = prop.valuesAllowed.findIndex(v => v.value === a)
        const indexB = prop.valuesAllowed.findIndex(v => v.value === b)
        return this.comparator(indexA, indexB)
      }
    }
    if (
      prop.propertyType === PropertyType.Select ||
      prop.propertyType === PropertyType.EntitySearch
    ) {
      const normalize = (value: any) => {
        if (typeof value === 'object') {
          return new EntitySearchValue(value).toString()
        }
        return value ? value : ''
      }
      return (a: any, b: any) => {
        let valueA = normalize(a)
        let valueB = normalize(b)
        return this.comparator(valueA, valueB)
      }
    }
    if (prop.propertyType === PropertyType.Number) {
      return (a: any, b: any) => {
        return this.numberComparator(a, b)
      }
    }
    return undefined
  }

  private getFilterComparator = (prop: FunctionProperty) => {
    if (prop.propertyType === PropertyType.EntitySearch) {
      return (a: any, b: any) => {
        return this.comparator(a ? a : '', b ? b : '')
      }
    }
    return undefined
  }

  private comparator = (a, b) => {
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    } else {
      return 0
    }
  }

  private numberComparator = (a, b) => {
    if (a === b) {
      return 0
    } else if (a === '-' || a === undefined) {
      return -1
    } else if (b === '-' || b === undefined) {
      return 1
    } else if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    } else {
      return 0
    }
  }

  refreshDynamicColumns = async (force?: boolean): Promise<void> => {
    if (_.isEmpty(this.options.dynamicColumns)) {
      return
    }

    if (this.skipRefreshDynamicColumns) {
      this.skipRefreshDynamicColumns = false
      return
    }

    const oldColumnDefs = this.state.gridOptions.columnDefs
    if (!oldColumnDefs) {
      return
    }
    let newColumnDefs = oldColumnDefs.concat()
    const props = this.viewMeta.removeParentProperty(
      this.viewMeta.functionMeta.detail.properties
    )

    // set dynamic columns
    let dynamicColIds: string[] = []
    for (let i = 0; i < props.length; i++) {
      const p = props[i]
      const dynamicColumn = this.options.dynamicColumns[p.externalId]
      if (!dynamicColumn) {
        continue
      }

      // Get dynamic column definitions
      const basedColumnDef = this.generateFunctionColumn(p)
      const dynamicColumnDefs = await dynamicColumn.getColumn(
        basedColumnDef,
        this,
        this.viewMeta
      )

      // Keep dynamicColIds
      dynamicColumnDefs.forEach(colGroup => {
        const dynamicColGroup = colGroup as ColGroupDef
        dynamicColGroup.children.forEach(colDef => {
          const dynamicColDef = colDef as ColDef
          dynamicColIds.push(dynamicColDef.colId || dynamicColDef.field || '')
        })
      })

      // Skip when dynamic columns already exists and not changed
      const oldDynamicColumnDefs: ColDef[] = newColumnDefs.filter(
        (colDef: ColDef) =>
          colDef.colId && colDef.colId.startsWith(`${p.externalId}`)
      )
      if (
        dynamicColumnDefs.length === 0 ||
        (!force &&
          dynamicColumnDefs.length === oldDynamicColumnDefs.length &&
          dynamicColumnDefs.every(
            (dynamicColumnDef, index) =>
              `${p.externalId}_${dynamicColumnDef.colId}` ===
              oldDynamicColumnDefs[index].colId
          ))
      ) {
        continue
      }

      // confirm dynamic column position
      const dynamicColumnIndex = newColumnDefs.findIndex(
        (colDef: ColDef) =>
          colDef.colId && colDef.colId.startsWith(p.externalId)
      )

      // remove old dynamic column
      newColumnDefs = newColumnDefs.filter(
        (colDef: ColDef) =>
          !colDef.colId || !colDef.colId.startsWith(`${p.externalId}`)
      )

      // set colId
      dynamicColumnDefs.forEach(columnDef => {
        columnDef.colId = `${p.externalId}_${columnDef.colId}`
      })

      // set columns as AgGrid setting
      if (dynamicColumnIndex > 0) {
        newColumnDefs.splice(dynamicColumnIndex, 0, ...dynamicColumnDefs)
      } else {
        newColumnDefs.push(...dynamicColumnDefs)
      }
    }
    this.dynamicColIds = dynamicColIds

    // Restore hide property
    const colState = this.columnApi!.getColumnState()
    const resetState = (colDef: ColDef) => {
      // Reset only for dynamic columns
      if (!dynamicColIds.includes(colDef.colId ?? colDef.field ?? '')) return
      const state = colState.find(v => v.colId === colDef.field)
      colDef['hide'] = state ? !!state.hide : !!colDef.hide
      colDef['pinned'] = state && state.pinned ? state.pinned : colDef['pinned']
      colDef['width'] = state ? state.width : colDef['width']
    }
    newColumnDefs.forEach(colDef => {
      'field' in colDef && resetState(colDef)
      'children' in colDef && colDef.children.forEach(resetState)
    })

    const newGridOptions = this.state.gridOptions
    newGridOptions.columnDefs = newColumnDefs
    this.setState({ gridOptions: newGridOptions })
    this.gridApi!.setColumnDefs(newGridOptions.columnDefs)

    const focusColumn = this.options.focusColumn(this)
    if (!!focusColumn) {
      setTimeout(() => this.gridApi?.ensureColumnVisible(focusColumn), 500)
    }

    if (force && this.columnApi) {
      // Reset dynamic column state
      const columnState = this.columnApi.getColumnState()
      const columns = columnState.filter(
        v => !this.dynamicColIds.includes(v.colId)
      )
      const dynamicColumns = columnState
        .filter(v => this.dynamicColIds.includes(v.colId))
        .sort(
          (a, b) =>
            this.dynamicColIds.indexOf(a.colId) -
            this.dynamicColIds.indexOf(b.colId)
        )
      if (
        !this.rememberedColumnAndFilterState ||
        !this.rememberedColumnAndFilterState.column
      ) {
        this.rememberedColumnAndFilterState = {
          filter: [],
          column: [...columns, ...dynamicColumns],
        }
      } else {
        let staticColDefs = this.rememberedColumnAndFilterState.column.filter(
          v => !this.dynamicColIds.includes(v.colId)
        )
        if (staticColDefs.length === 0) {
          staticColDefs = columns
        }

        const dynamicColDefs = dynamicColumns.map(v => {
          // Restore only a few properties because the order is important for dynamic columns
          const remembered = this.rememberedColumnAndFilterState?.column.find(
            r => r.colId === v.colId
          )
          if (remembered) {
            v.hide = remembered.hide
            v.pinned = remembered.pinned
            v.width = remembered.width
          }
          return v
        })
        this.rememberedColumnAndFilterState.column = [
          ...staticColDefs,
          ...dynamicColDefs,
        ]
      }
      this.columnApi.applyColumnState({
        state: this.rememberedColumnAndFilterState.column,
        applyOrder: true,
      })
    }
  }

  private getWidthForProperty = (prop: FunctionProperty) => {
    const customWidth = this.options.customColumnWidth
      ? this.options.customColumnWidth(this.viewMeta.makeDataPropertyName(prop))
      : undefined
    if (customWidth) {
      return customWidth
    }
    if (prop.propertyType === 'DATE') {
      return 125
    }
    if (prop.propertyType === 'DATE_TIME') {
      return 175
    }
    if (prop.propertyType === 'ENTITY_SEARCH') {
      return 120
    }
    const propName = this.viewMeta.makeDataPropertyName(prop)
    if (propName === 'code') {
      return 120
    }
    if (propName === 'revision') {
      return 90
    }
    if (propName === 'name') {
      return 120
    }
    const propNameSuffix = this.viewMeta.getPropertyNameSuffix(prop)
    if (propNameSuffix === 'revision') {
      return 100
    }
    if (prop.propertyType === 'TEXT') {
      return Math.min((prop.maxLength || 10) * 15, 200)
    }
    if (
      prop.propertyType === 'SELECT' ||
      prop.propertyType === 'MULTI_SELECT'
    ) {
      return Math.min((prop.maxLength || 8) * 15, 200)
    }
    if (prop.propertyType === 'RADIO') {
      return Math.min((prop.maxLength || 8) * 15, 200)
    }
    if (prop.propertyType === 'SLIDER') {
      return Math.min((prop.maxLength || 3) * 40, 200)
    }
    if (prop.propertyType === 'CHECKBOX') {
      return 75
    }
    if (prop.propertyType === PropertyType.Custom) {
      if (prop.component === Component.Action) {
        return 80
      }
    }
    return undefined
  }

  private getTypeForProperty = (prop: FunctionProperty) => {
    let types: string[] = []
    if (prop.propertyType === PropertyType.Select) {
      if (prop.referenceEntity) {
        types.push(ColumnType.autocomplete)
      } else {
        types.push(ColumnType.select)
      }
    } else if (prop.propertyType === PropertyType.MultiSelect) {
      if (prop.referenceEntity) {
        types.push(ColumnType.multiAutocomplete)
      } else if (prop.valuesAllowed) {
        types.push(ColumnType.multiSelect)
      } else {
        // TODO: implement multiSelectColumn which can choose multiple values.
        throw new Error(
          'MULTI_SELECT cell which has no reference entity is not defined yet.'
        )
      }
    } else if (prop.propertyType === PropertyType.Date) {
      types.push(ColumnType.date)
    } else if (prop.propertyType === PropertyType.DateTime) {
      types.push(ColumnType.dateTime)
    } else if (prop.propertyType === PropertyType.EntitySearch) {
      types.push(ColumnType.autocomplete)
    } else if (prop.propertyType === PropertyType.MultiLineText) {
      types.push(ColumnType.multiLineText)
    } else if (prop.propertyType === PropertyType.Radio) {
      types.push(ColumnType.radioGroup)
    } else if (prop.propertyType === PropertyType.Checkbox) {
      types.push(ColumnType.checkBox)
    } else if (prop.propertyType === PropertyType.Icon) {
      types.push(ColumnType.iconColumn)
    } else if (prop.propertyType === PropertyType.Number) {
      if (prop.component === Component.WbsAggregateValue) {
        types.push(ColumnType.wbsAggregateValue)
      } else {
        types.push(ColumnType.number)
      }
    } else if (prop.propertyType === PropertyType.I18nLabel) {
      types.push(ColumnType.i18nLabel)
    } else if (prop.propertyType === PropertyType.GanttChart) {
      types.push(ColumnType.gantt)
    } else if (prop.propertyType === PropertyType.Custom) {
      if (prop.component === Component.ActualResult) {
        if (this.isServerSideRowModel) {
          types.push(ColumnType.taskActualResultServerSide)
        } else {
          types.push(ColumnType.taskActualResult)
        }
      } else if (prop.component === Component.Workload) {
        types.push(ColumnType.workload)
      } else if (prop.component === Component.Action) {
        types.push(ColumnType.detailColumn)
      } else if (prop.component === Component.WbsItemBasic) {
        types.push(ColumnType.wbsItemBasic)
      } else if (prop.component === Component.Attachment) {
        types.push(ColumnType.attachmentColumn)
      } else if (prop.component === Component.TicketType) {
        types.push(ColumnType.ticketType)
      } else if (prop.component === Component.Tag) {
        types.push(ColumnType.tag)
      }
    }
    const customTypes = this.options.customColumnTypes[prop.externalId]
    if (customTypes) {
      types = types.concat(customTypes)
    }
    return types.length > 0 ? types : undefined
  }

  private isHiddenProperty = (prop: FunctionProperty) => {
    const hidden = prop.hiddenIfU.isTrue
    if (hidden) {
      return true
    }
    // Common hidden properties
    const propName = this.viewMeta.makeDataPropertyName(prop)
    const commonHiddenColumns = [
      'createdAt',
      'createdBy',
      'updatedAt',
      'updatedBy',
      'revision',
    ]
    if (commonHiddenColumns.includes(propName || '')) {
      return true
    }
    // ProjectPlanTab hidden properties
    if (
      this.options.hiddenColumns &&
      this.options.hiddenColumns.includes(propName || '')
    ) {
      return true
    }
  }

  mergeDefaultParams = (
    columnDefs: (ColDef | ColGroupDef)[],
    colTypes: { [key: string]: ColDef }
  ) => {
    for (let i = 0; i < columnDefs.length; i++) {
      const def = columnDefs[i]
      if (def['children']) {
        this.mergeDefaultParams(def['children'], colTypes)
        continue
      }
      const colDef = def as ColDef
      if (!colDef.field) {
        continue
      }
      const externalId = this.viewMeta.toExternalId(colDef.field)
      const uiMeta =
        this.viewMeta.functionMeta.properties.byId.get(externalId) ||
        this.viewMeta.functionMeta.entityExtensions.byId.get(colDef.field)
      if (!uiMeta) {
        continue
      }
      const field = this.viewMeta.makeDataPropertyName(uiMeta)
      colDef.cellEditorParams = {
        field,
        uiMeta,
        viewMeta: this.viewMeta,
        ...colDef.cellEditorParams,
      }
      colDef.cellRendererParams = {
        field,
        uiMeta,
        viewMeta: this.viewMeta,
        ...colDef.cellRendererParams,
      }
      let valueGetter
      let valueSetter = colDef.valueSetter
      const colDefValueSetter = colDef.valueSetter
      let filterParams = colDef.filterParams
      const cts =
        typeof colDef.type === 'string' ? [colDef.type] : colDef.type || []
      for (let t of cts) {
        const colType = colTypes[t]
        if (colType) {
          valueGetter = colType['valueGetter'] || valueGetter
          valueSetter = colType['valueSetter'] || valueSetter
          filterParams = {
            ...filterParams,
            ...colType['filterParams'],
          }
        }
      }
      // The priority for valueGetter is
      // 1. what is defined in each BulkSheetOptions
      // 2. what is defined by column colTypes
      // 3. defaultValueGetter
      if (!colDef.valueGetter) {
        if (valueGetter) {
          colDef.valueGetter = valueGetter
        } else {
          colDef.valueGetter = defaultValueGetter
        }
      }
      if (valueSetter) {
        colDef.valueSetter = param => {
          // Execute default valueSetter after custom valueSetter
          if (
            colDefValueSetter &&
            typeof colDefValueSetter === 'function' &&
            !colDefValueSetter(param)
          ) {
            return false
          }
          if (
            valueSetter &&
            typeof valueSetter === 'function' &&
            !valueSetter(param)
          ) {
            return false
          }
          const updated = defaultValueSetter(
            param,
            typeof valueSetter === 'string' ? valueSetter : undefined,
            true,
            uiMeta.entityExtensionUuid
          )
          // Data is managed in serverSideRowDataManager
          this.isServerSideRowModel && this.rowDataManager.updateRow(param.data)
          return updated
        }
      } else {
        colDef.valueSetter = param => {
          const updated = defaultValueSetter(
            param,
            undefined,
            false,
            uiMeta.entityExtensionUuid
          )
          this.isServerSideRowModel && this.rowDataManager.updateRow(param.data)
          return updated
        }
      }
      colDef.filterParams = filterParams
    }
  }

  openDetail = async (node: RowNode, event: any) => {
    if (node.data.isTotal) {
      return
    }
    let targetRowdata = node.data
    const openDetailSpec = await this.options.getOpenDetailSpec!(targetRowdata)
    if (!openDetailSpec) {
      return
    }
    const layer = openDetailSpec.layer
    if (
      !openDetailSpec.openInDialog ||
      (event && (event['ctrlKey'] || event['metaKey']))
    ) {
      open(`${getPathByExternalId(layer.externalId)}/${layer.code!}`, event)
    } else {
      openDetailSpec.onOpen && openDetailSpec.onOpen()
    }
  }

  refreshAfterUpdateSingleRow = async (uuid: string) => {
    if (!this.options.getUpdatedRowAncestors) {
      return
    }
    if (this.options.refreshAfterUpdateSingleRow) {
      this.options.refreshAfterUpdateSingleRow(uuid, this)
      return
    }
    let rows: R[] = []
    try {
      const ancestors = await this.options.getUpdatedRowAncestors(
        uuid,
        this.props.treeRootUuid,
        this.state
      )
      if (objects.isEmpty(ancestors)) {
        const node = this.gridApi!.getRowNode(uuid)
        if (!node) {
          return
        }
        this.rowDataManager.removeRows([node.data])
        return
      }
      let children = [ancestors]
      while (children && children.length > 0) {
        const child = children[0]
        rows.push({
          ...this.rowDataManager.createRowByResponse(
            children[0],
            this.viewMeta
          ),
          children: this.rowDataManager.getChildren(child.uuid),
          isAdded: false,
          isEdited: false,
          editedData: undefined,
        })
        children = child.children
      }
      let nodes: RowNode[] = []
      rows.forEach(row => {
        this.rowDataManager.initRow(row)
        const node = this.gridApi!.getRowNode(row.uuid)
        nodes.push(node!)
      })
      this.gridApi!.refreshCells({ rowNodes: nodes, force: true })
    } catch (e: any) {
      if (e.code !== 'NOT_FOUND') {
        throw e
      }
      const node = this.gridApi!.getRowNode(uuid)
      if (!node) {
        throw e
      }
      this.rowDataManager.removeRows([node.data])
    }
  }

  onSubmit = async () => {
    runUseCaseAsyncWithPerfMonitoring('Submit BulkSheet', async () => {
      this.setState({ isLoading: true })
      this.props.setIsLoading?.(true)
      try {
        if (
          !this.state.hasUpdatePermission ||
          !this.state.editable ||
          this.state.submitDisabled ||
          !this.props.edited
        ) {
          return
        }
        this.gridApi?.stopEditing()
        this.skipRefreshDynamicColumns = true

        this.setState({ inProgress: true })
        if (this.options.draggable) {
          this.gridApi!.setSuppressRowDrag(true)
        }
        const data = this.rowDataManager.getDataForBatchUpdate()
        const validationErrors = this.validateBeforeSubmit({
          all: this.rowDataManager.getAllRows(),
          ...data,
        })

        if (validationErrors.length > 0) {
          this.showWarning({ messages: validationErrors })
          return
        }
        if (this.options.onSubmit && data) {
          const response = await this.options
            .onSubmit(this, data, this.viewMeta)
            .catch(err => {
              if (this.handleSubmitError(err, data, this.viewMeta)) {
                return
              } else {
                throw err
              }
            })
          if (!response) {
            return
          }
          const hasWarning = Array.isArray(response)
            ? response.some(v => v.hasWarning)
            : response.hasWarning
          if (hasWarning) {
            if (Array.isArray(response)) {
              response.forEach(v => v.hasWarning && this.showWarning(v.json))
            } else {
              this.showWarning(response.json)
            }
          }
          const hasError = Array.isArray(response)
            ? response.some(v => v.hasError)
            : response.hasError
          if (!hasError && !hasWarning) {
            store.dispatch(
              addScreenMessage(this.viewMeta.externalId, {
                type: MessageLevel.SUCCESS,
                title: intl.formatMessage({ id: 'registration.complete' }),
              })
            )
            runAsyncWithPerfMonitoring('Refresh After Submit', async () =>
              this.refreshData()
            )
          }
        }
      } finally {
        this.setState({ inProgress: false, isLoading: false })
        this.props.setIsLoading?.(false)
        if (this.options.draggable) {
          this.gridApi!.setSuppressRowDrag(false)
        }
      }
    })
  }

  onSubmitSingleRow = async (uuid: string) => {
    let isSuccessSubmit = false
    if (
      !this.state.hasUpdatePermission ||
      !this.state.editable ||
      this.state.submitDisabled
    ) {
      return isSuccessSubmit
    }
    this.gridApi?.stopEditing()
    this.skipRefreshDynamicColumns = true

    try {
      this.setState({ inProgress: true })
      if (this.options.draggable) {
        this.gridApi!.setSuppressRowDrag(true)
      }
      const data = this.rowDataManager.getSingleRowDataForBatchUpdate(uuid)
      const validationErrors = this.validateBeforeSubmit({
        all: this.rowDataManager.getAllRows(),
        ...data,
      })
      if (validationErrors.length > 0) {
        this.showWarning({ messages: validationErrors })
        return
      }
      if (this.options.onSubmit && data) {
        const response = await this.options.onSubmit(this, data, this.viewMeta)
        const hasWarning = Array.isArray(response)
          ? response.some(v => v.hasWarning)
          : response.hasWarning
        if (hasWarning) {
          if (Array.isArray(response)) {
            response.forEach(v => v.hasWarning && this.showWarning(v.json))
          } else {
            this.showWarning(response.json)
          }
        }
        const hasError = Array.isArray(response)
          ? response.some(v => v.hasError)
          : response.hasError
        if (!hasWarning && !hasError) isSuccessSubmit = true
      }
    } finally {
      this.setState({ inProgress: false })
      if (this.options.draggable) {
        this.gridApi!.setSuppressRowDrag(false)
      }
      return isSuccessSubmit
    }
  }

  onCancel = async () => {
    runUseCaseAsyncWithPerfMonitoring('Reload BulkSheet', async () => {
      this.skipRefreshDynamicColumns = true
      await this.refreshDataWithLoading()
    })
  }

  private validateBeforeSubmit = (data: {
    all: R[]
    added: R[]
    edited: {
      before: R
      after: R
    }[]
    deleted: R[]
  }): { readableMessage: { message: string } }[] => {
    const editedAfter = data.edited.map(v => v.after)
    const { added } = data
    const targets = [...editedAfter, ...added]
    targets.sort((a, b) => Number(a.rowNumber) - Number(b.rowNumber))
    const functionProperties = this.viewMeta.removeParentProperty(
      this.viewMeta.functionMeta.detail.properties
    )

    const errorElements: string[][] = []
    targets.forEach(target => {
      functionProperties.forEach(functionProperty => {
        let val
        if (functionProperty.entityExtensionUuid) {
          const extension = target.extensions
            ? target.extensions.find(
                v => v.uuid === functionProperty.entityExtensionUuid
              )
            : undefined
          if (extension) {
            val = extension.value
          }
        } else {
          val = objects.getValue(
            target,
            this.viewMeta.makeDataPropertyName(functionProperty)
          )
        }
        if (functionProperty.valuesAllowed) {
          functionProperty.valuesAllowed.forEach(valueAllowed => {
            if (valueAllowed.value === val) {
              val = valueAllowed.name
            }
          })
        }
        const validationResult = validate(
          val,
          target,
          functionProperty,
          this.viewMeta,
          this.gridApi
        )
        if (validationResult) {
          errorElements.push([
            target.rowNumber || '',
            functionProperty.name,
            validationResult,
          ])
        }
      })
    })

    const rowDataErrorElements = data.all
      .filter(r => r.errorMessages)
      .flatMap(r => {
        const rowNumber = r.rowNumber
        return Object.entries(r.errorMessages!).map(([id, message]) => {
          return [
            rowNumber || '',
            this.viewMeta.getPropByExternalId(id)?.name || '',
            message,
          ]
        })
      })
    if (!_.isEmpty(rowDataErrorElements)) {
      errorElements.concat(rowDataErrorElements)
    }

    return errorElements
      .concat(rowDataErrorElements)
      .filter(e => !!e)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(e => {
        const elements = e.concat()
        elements[0] = ERR_ROW_NUMBER_PREFIX + e[0]
        return {
          readableMessage: {
            message: elements.join(ERR_DELIMITER),
          },
        }
      })
  }

  handleSubmitError = (
    error: any,
    data: {
      added: R[]
      edited: {
        before: R
        after: R
      }[]
    },
    viewMeta: ViewMeta
  ): boolean => {
    if (error.code === ErrorCode.CONSTRAINT_VIOLATION) {
      const details: { propertyPath: string; message: string }[] = error.detail
      const messages: (string | undefined)[] = details
        .map(detail => {
          const bulkPathPattern = /^(added|edited)\[([0-9]+)\]\.(.+)$/
          const bulkPath = detail.propertyPath.match(bulkPathPattern)
          if (!bulkPath) return
          const [_, action, index, field] = bulkPath

          let targetRow: R | undefined = undefined
          if (action === 'added') {
            targetRow = data['added'][index]
          } else if (action === 'edited') {
            targetRow = data['edited'][index]['after']
          } else {
            return
          }
          const propName = viewMeta.getPropByExternalId(
            viewMeta.toExternalId(field)
          )?.name
          return {
            rowNumber: targetRow?.rowNumber || 0,
            name: propName || '',
            message: detail.message,
          }
        })
        .filter(d => d)
        .sort((a, b) => {
          const bRowNumber = Number.isNaN(b?.rowNumber)
            ? 0
            : Number(b?.rowNumber)
          const aRowNumber = Number.isNaN(a?.rowNumber)
            ? 0
            : Number(a?.rowNumber)
          return aRowNumber - bRowNumber
        })
        .map(d => {
          return (
            ERR_ROW_NUMBER_PREFIX +
            d!.rowNumber +
            ERR_DELIMITER +
            d!.name +
            ERR_DELIMITER +
            d!.message
          )
        })

      const text = messages.join('\n')
      store.dispatch(
        addGlobalMessage({
          type: MessageLevel.WARN,
          code: error.errorCode,
          title: intl.formatMessage({
            id: 'global.warning.constraintViolation',
          }),
          text,
          detail: intl.formatMessage(
            { id: 'global.warning.errorCode' },
            { text, errorCode: error.errorCode }
          ),
        })
      )
      return true
    }
    return false
  }

  private showWarning = response => {
    const messages = extractValuesFromResponse(response, 'messages')
    handleWarning(messages, uuid => {
      const fields = Array.from(
        this.viewMeta.functionMeta.properties.byId.values()
      ).map(v => this.viewMeta.makeDataPropertyName(v))
      const codeField = fields.find(v => v && v.endsWith('.code'))
      const targetName = (codeField || '').replace(/\.?code$/, '')
      const key = `${targetName ? targetName + '.' : ''}uuid`
      const target = this.rowDataManager
        .getAllRows()
        .find(v => objects.getValue(v, key) === uuid)
      return targetName ? objects.getValue(target, targetName) : target
    })
  }

  getDataPath = this.treeProperty ? undefined : (data: any) => data.treeValue

  openTaskActualWorkDialog = (taskUuid: string) => {
    this.setState({
      taskActualWorkDialog: {
        open: true,
        taskUuid,
      },
    })
  }

  rootIsNotEditable = (uuid: string) => {
    const lowerLayer = this.props.functionLayers.get(
      this.props.functionLayers.size - 1
    )
    return (
      lowerLayer &&
      lowerLayer.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM &&
      this.treeRoot &&
      this.treeRoot.uuid === uuid
    )
  }

  getOpenStatusChangePopper = (prop: FunctionProperty) => (params: any) => {
    const data = params.data
    if (
      data.isAdded ||
      data.isViewOnly ||
      this.rootIsNotEditable(data.uuid) ||
      !!this.state.statusPopperAnchorEl
    ) {
      return
    }

    // Choose sevend-ag-cell as anchorEl in order to fix position of popper.
    let target = params.event?.target
    const getPositionElem = (elem): any => {
      if (elem.className.toString().indexOf('sevend-ag-cell') !== -1) {
        return elem
      }
      return getPositionElem(elem.parentElement)
    }
    // it means <p> element (letters on status cell) is clicked
    if (
      target &&
      (target.nodeName === 'P' ||
        target.nodeName === 'span' ||
        target.nodeName === 'svg' ||
        target.nodeName === 'path')
    ) {
      target = getPositionElem(target.parentElement)
    }

    if (!target || target.offsetHeight === 0) {
      this.setState({
        statusPopperAnchorEl: undefined,
        statusPopperUuid: undefined,
        statusPopperWbsItem: undefined,
        statusPopperWbsItemDelta: undefined,
      })
      return
    }
    const editedRow = this.rowDataManager.getSingleRowDataForBatchUpdate(
      data.uuid
    ).edited[0]
    this.setState({
      statusPopperAnchorEl: target,
      statusPopperUuid: data.uuid,
      statusPopperProjectUuid: data.projectUuid || this.state.uuid,
      statusPopperWbsItem: objects.getValue(
        data,
        this.viewMeta.makeDataPropertyName(prop).split('.')[0]
      ),
      statusPopperWbsItemDelta: editedRow
        ? this.options.createWbsItemDeltaRequestByRow?.(
            editedRow,
            this.viewMeta
          ) ?? {}
        : {},
    })
  }

  closeTaskActualWorkDialog = () => {
    this.setState({
      taskActualWorkDialog: {
        open: false,
        taskUuid: '',
      },
    })
  }

  closeAttachmentListDialog = () => {
    this.setState({
      attachmentListDialog: {
        open: false,
        onClose: () => {
          /** do nothing */
        },
        attachments: [],
      },
    })
  }

  openAttachmentListDialog = (attachments: Attachment[]) => {
    this.setState({
      attachmentListDialog: {
        open: true,
        onClose: this.closeAttachmentListDialog,
        attachments,
      },
    })
  }

  updateUiState = async (
    request: RequestOfUpdateStates,
    applicationFunctionUuid: string
  ) => uiStates.update(request, applicationFunctionUuid)

  refreshDataWithLoading = async (forceRefreshDynamicColumns?: boolean) => {
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      await runAsyncWithPerfMonitoring('refreshData', () =>
        this.refreshData(forceRefreshDynamicColumns)
      )
    } finally {
      this.setState({ isLoading: false })
      this.props.setIsLoading?.(false)
    }
  }
  refreshData = async (forceRefreshDynamicColumns?: boolean) => {
    await this.refreshDynamicColumns(forceRefreshDynamicColumns)
    const editedNodes: RowNode[] = []

    this.gridApi!.forEachNode((rowNode: RowNode) => {
      if (!!rowNode.data.isEdited) {
        editedNodes.push(rowNode)
        rowNode.data.editedData = undefined
      } else if (!!rowNode.data.errorMessages) {
        editedNodes.push(rowNode)
        rowNode.data.errorMessages = undefined
      }
    })
    if (this.isServerSideRowModel) {
      await this.refreshServerSideData()
    } else {
      const root = await runAsyncWithPerfMonitoring('getTree', () =>
        this.getTree()
      )
      this.treeRoot.uuid = root.uuid
      this.treeRoot.lockVersion = root.lockVersion
      this.treeRoot.revision = root.revision

      await runAsyncWithPerfMonitoring('initRowDataCreator', () =>
        this.initRowDataCreator(root)
      )
    }

    await this.rememberCurrentSearchCondition()

    store.dispatch(doNotRequireSave())

    const allRows = this.rowDataManager.getAllRows()
    editedNodes.forEach(
      (node: RowNode) =>
        (node.data = allRows.find(v => v.uuid === node.id) || node.data)
    )
    this.rowDataManager.refreshRowNumber(-1, -1)
    this.gridApi!.refreshCells()
    !_.isEmpty(editedNodes) &&
      this.gridApi!.redrawRows({ rowNodes: editedNodes })

    this.restoreColumnState()
    this.restoreFilterState()
    if (this.options.refreshFilteredRowDataUuid) {
      this.options.refreshFilteredRowDataUuid(this.state)
    }

    this.options.fieldRefreshedAfterMove &&
      this.gridApi!.refreshCells({
        columns: this.options.fieldRefreshedAfterMove,
        force: true,
      })

    if (this.state.lastSelectedUIState?.searchConditionUuid) {
      this.rememberSelectedSavedSearchCondition(
        this.state.lastSelectedUIState.searchConditionUuid,
        this.state.lastSelectedUIState.searchConditionIncludeColumn
      )
      this.setState({
        lastSelectedUIState: {
          ...this.state.lastSelectedUIState,
          searchConditionUuid: undefined,
          searchConditionIncludeColumn: false,
        },
      })
    }
    if (this.props.applyQuickFilters) {
      this.props.applyQuickFilters()
    }
    await this.rememberSelectedColumnAndFilterByLastSelected()
  }

  refreshPinnedData = () => {
    let pinnedRowNodes: RowNode[] = []
    if (this.options.pinnedTopRowData) {
      this.options.pinnedTopRowData.forEach((_, i) => {
        const node = this.gridApi!.getPinnedTopRow(i)
        node && pinnedRowNodes.push(node)
      })
    }
    if (this.options.pinnedBottomRowData) {
      this.options.pinnedBottomRowData.forEach((_, i) => {
        const node = this.gridApi!.getPinnedTopRow(i)
        node && pinnedRowNodes.push(node)
      })
    }
    if (pinnedRowNodes.length > 0) {
      this.gridApi!.refreshCells({ rowNodes: pinnedRowNodes, force: true })
    }
  }

  refreshServerSideData = async () => {
    // Fetch root nodes
    const response = await this.options.getChildren!(this.state, undefined, 2)
    const root = response.json
    this.treeRoot.uuid = root.uuid
    this.treeRoot.lockVersion = root.lockVersion
    this.treeRoot.revision = root.revision

    const route = this.rowDataManager.refresh!(root.children)
    if (route) {
      // Clear focus before purge rows
      this.gridApi!.clearFocusedCell()
      this.gridApi!.clearRangeSelection()
      // Refresh all rows
      this.gridApi!.refreshServerSideStore({ route, purge: true })
      return
    }

    const routes = await this.refreshServerSideRecursive(root.children)
    routes.forEach(route =>
      this.gridApi!.refreshServerSideStore({ route, purge: true })
    )
  }

  private refreshServerSideRecursive = async (
    trees: T[]
  ): Promise<string[][]> => {
    const refreshRoutes: string[][] = []
    for (const tree of trees) {
      if (
        this.rowDataManager.expandedGroupIds.has(tree.uuid) ||
        this.rowDataManager.collapsedGroupIds.has(tree.uuid)
      ) {
        const children = this.rowDataManager.getChildren(tree.uuid)
        let treeChildren: T[]
        if (children && children.length > 0) {
          treeChildren = tree.children
        } else {
          const response = await this.options.getChildren!(
            this.state,
            tree.uuid
          )
          treeChildren = response.json.children
        }
        const route = this.rowDataManager.refresh!(treeChildren, tree.uuid)
        if (route === undefined) {
          const childRoutes = await this.refreshServerSideRecursive(
            treeChildren
          )
          childRoutes.forEach(route => refreshRoutes.push(route))
        } else {
          refreshRoutes.push(route)
        }
      }
    }
    return refreshRoutes
  }

  async getTree(): Promise<Tree<T>> {
    const response = await this.options.getAll(this.state, this.props, this)
    const result = response.json
    if (Array.isArray(result)) {
      return {
        uuid: this.state.uuid,
        lockVersion: -1,
        revision: this.state.revision!,
        children: result,
      }
    } else if ('total' in result && 'data' in result) {
      const elasticsearchResult: ElasticsearchResponse = result
      this.setState({
        total: elasticsearchResult.total,
        hit: elasticsearchResult.hit,
      })

      if (elasticsearchResult.hit >= 10000) {
        // notify user that es can not return over 10,000 hits.
        store.dispatch(
          addScreenMessage(this.props.externalId, {
            type: MessageLevel.WARN,
            title: this.props.intl.formatMessage({
              id: 'bulksheet.warning.search.limit',
            }),
            text: this.props.intl.formatMessage({
              id: 'bulksheet.warning.search.limit.detail',
            }),
          })
        )
      }

      return {
        uuid: this.state.uuid,
        lockVersion: -1,
        revision: this.state.revision!,
        children: elasticsearchResult.data,
      }
    } else if (this.props.treeRootUuid) {
      // Show project plan
      return {
        uuid: result.uuid,
        lockVersion: result.lockVersion,
        revision: this.state.revision!,
        children: [result],
      }
    }
    return result
  }

  async getColumnAndFilterState(): Promise<UiStateProps | undefined> {
    const uiStateKey = this.getColumnAndFilterStateKey()
    if (uiStateKey) {
      return this.getUiState(this.viewMeta.uuid, uiStateKey, UiStateScope.User)
    }
  }

  async getSearchConditionState(): Promise<UiStateProps | undefined> {
    if (!this.options.getSearchCondition) {
      return
    }
    const uiStateKey = this.getSearchConditionStateKey()
    if (uiStateKey) {
      return this.getUiState(this.viewMeta.uuid, uiStateKey, UiStateScope.User)
    }
  }

  async getRowState(): Promise<UiStateProps | undefined> {
    return this.getUiState(
      this.viewMeta.uuid,
      this.getRowStateKey(),
      UiStateScope.User
    )
  }

  async getSearchConditionStateByStateCode(
    searchConditionStateCode: string
  ): Promise<SavedUIState | undefined> {
    const uiState = await this.getUiState(
      this.viewMeta.uuid,
      this.props.projectUuid
        ? `${UiStateKey.BulkSheetUIStateSearchCondition}-${this.props.projectUuid}`
        : UiStateKey.BulkSheetUIStateSearchCondition,
      UiStateScope.Tenant
    )
    const searchConditionStates = uiState.value
      ? (JSON.parse(uiState.value) as SavedUIState[])
      : []

    return searchConditionStates.find(v => v.code === searchConditionStateCode)
  }

  async getSelectedSavedUISate(): Promise<UiStateProps | undefined> {
    const uiStateKey = this.getSelectedSavedUIStateKey()
    if (uiStateKey) {
      return this.getUiState(this.viewMeta.uuid, uiStateKey, UiStateScope.User)
    }
  }

  async getUiState(
    applicationFunctionUuid: string,
    key: string,
    scope: UiStateScope
  ): Promise<UiStateProps> {
    const request: RequestOfGetStates = {
      applicationFunctionUuid,
      key,
      scope,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  addRow = (
    addToUuid?: string,
    parentUuid?: string,
    newRowData?: R,
    internalUse: boolean = false
  ) => {
    const newRow = newRowData || this.options.rowDataSpec.createNewRow(this)
    let newIndex: number
    if (addToUuid && this.rowDataManager.getIndexById) {
      newIndex =
        this.rowDataManager.getIndexById(addToUuid) +
        this.rowDataManager.countChildren(addToUuid) +
        1
    } else {
      newIndex = this.rowDataManager.getAllRows().length
    }
    this.rowDataManager.addRows(newRow, newIndex, parentUuid)
    if (!internalUse) {
      const node = this.gridApi!.getRowNode(newRow.uuid)
      node && this.refreshAncestors(node)
      this.rowDataManager.focusRow(newRow.uuid)
      store.dispatch(requireSave())
    }
  }

  generateAddContextMenuGroup = (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ): ContextMenuGroup | undefined => {
    if (
      !(this.state.hasUpdatePermission && this.state.editable) ||
      !params.node ||
      !params.node.data
    ) {
      return undefined
    }
    const { rowDataSpec } = this.options
    const rowNode: RowNode = params.node
    const targetRow = rowNode.data
    const displayName = this.options.displayNameField
      ? objects.getValue(rowNode.data, this.options.displayNameField)
      : rowNode.data.displayName
    const canAddChild = displayName && !!displayName.trim()
    const addRow = () =>
      this.addRow(
        rowNode.id,
        rowNode.parent && !rowNode.parent.groupData
          ? rowNode.parent.id
          : undefined
      )
    const addChild = () => {
      this.addRow(rowNode.id, rowNode.id)
      if (this.treeProperty) {
        rowNode.setExpanded(true)
      }
    }
    const isEnable = (id: ContextMenuItemId) => !menu || menu.includes(id)
    return {
      id: ContextMenuGroupId.ADD_ROW_GROUP,
      items: [
        // Add row
        isEnable(ContextMenuItemId.ADD_ROW)
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.row',
              }),
              disabled: !rowDataSpec.canAddRow(targetRow),
              action: addRow,
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage(
                    { id: 'bulksheet.contextMenu.shortcut.alt.shift' },
                    { shortcutKey: intl.formatMessage({ id: 'L' }) }
                  )
                : undefined,
            }
          : undefined,
        // Add multiple row
        isEnable(ContextMenuItemId.ADD_MULTIPLE_ROW)
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.multipleRow',
              }),
              disabled: !rowDataSpec.canAddRow(targetRow),
              action: () =>
                this.setState({
                  addRowCountInputDialogState: {
                    open: true,
                    title: intl.formatMessage({
                      id: 'bulksheet.contextMenu.insert.multipleRow.title',
                    }),
                    submitHandler: addRowCount => {
                      if (!addRowCount) {
                        throw new Error('addRowCount is 0 or undefined.')
                      }
                      Array.from({ length: addRowCount }).forEach(addRow)
                      this.setState({
                        addRowCountInputDialogState: { open: false },
                      })
                    },
                    closeHandler: () => {
                      this.setState({
                        addRowCountInputDialogState: { open: false },
                      })
                    },
                  },
                }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
            }
          : undefined,
        // Add child
        isEnable(ContextMenuItemId.ADD_CHILD_ROW) && this.treeProperty
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.child',
              }),
              disabled: !canAddChild,
              action: addChild,
              icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
              tooltip: !canAddChild
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.add.toChild.row',
                  })
                : undefined,
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage(
                    { id: 'bulksheet.contextMenu.shortcut.ctrl.shift' },
                    { shortcutKey: intl.formatMessage({ id: 'L' }) }
                  )
                : undefined,
            }
          : undefined,
        // Add children
        isEnable(ContextMenuItemId.ADD_MULTIPLE_CHILD_ROW) && this.treeProperty
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.insert.multipleChild',
              }),
              disabled: !canAddChild,
              action: () =>
                this.setState({
                  addRowCountInputDialogState: {
                    open: true,
                    title: intl.formatMessage({
                      id: 'bulksheet.contextMenu.insert.multipleChild.title',
                    }),
                    submitHandler: addRowCount => {
                      if (!addRowCount) {
                        throw new Error('addRowCount is 0 or undefined.')
                      }
                      Array.from({ length: addRowCount }).forEach(addChild)
                      this.setState({
                        addRowCountInputDialogState: { open: false },
                      })
                    },
                    closeHandler: () => {
                      this.setState({
                        addRowCountInputDialogState: { open: false },
                      })
                    },
                  },
                }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
              tooltip: !canAddChild
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.add.toChild.row',
                  })
                : undefined,
            }
          : undefined,
      ].filter(v => !!v) as MenuItemDef[],
    }
  }

  generateEditContextMenu = (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ): ContextMenuGroup | undefined => {
    if (
      !(this.state.hasUpdatePermission && this.state.editable) ||
      !params.node ||
      !params.node.data
    ) {
      return undefined
    }
    const isEnable = (id: ContextMenuItemId) => !menu || menu.includes(id)
    const checkRemovableResult = this.checkRemovable()

    return {
      id: ContextMenuGroupId.MANAGE_ROW_GROUP,
      items: [
        // Remove row
        isEnable(ContextMenuItemId.REMOVE_ROW)
          ? {
              name: this.props.intl.formatMessage(
                { id: 'bulksheet.contextMenu.delete' },
                { count: this.getSelectedRowRangeSize() }
              ),
              action: () => this.handleRemove(),
              disabled: !checkRemovableResult.removable,
              tooltip: checkRemovableResult.unremovableReason,
              icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.shortcut.row.delete',
                  })
                : undefined,
            }
          : undefined,
        // Copy row
        isEnable(ContextMenuItemId.COPY_ROW)
          ? {
              name: this.props.intl.formatMessage(
                { id: 'bulksheet.contextMenu.copy' },
                { count: this.getSelectedRowRangeSize() }
              ),
              disabled: !this.checkCopiable(),
              action: () => this.copyRowData(),
              icon: getMenuIconHtml(ContextMenuItemId.COPY_ROW),
              tooltip: !this.checkCopiable()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.copy',
                  })
                : undefined,
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.shortcut.row.copy',
                  })
                : undefined,
            }
          : undefined,
        // Bulk copy row
        isEnable(ContextMenuItemId.BULK_COPY_ROW)
          ? {
              name: this.props.intl.formatMessage({
                id: 'bulksheet.contextMenu.bulkCopy',
              }),
              disabled: !this.checkBulkCopiable(),
              action: () => this.copyRowDataWithChildren(),
              tooltip: !this.checkBulkCopiable()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.bulkCopy',
                  })
                : undefined,
            }
          : undefined,
        // Paste row
        isEnable(ContextMenuItemId.PASTE_ROW)
          ? this.options.getPasteColumnsCandidate
            ? {
                name: this.props.intl.formatMessage(
                  { id: 'bulksheet.contextMenu.paste.rows' },
                  {
                    clipboard: this.state.clipboard.length,
                    selected: this.getSelectedRowRangeSize(),
                  }
                ),
                subMenu: [
                  {
                    name: this.props.intl.formatMessage({
                      id: 'bulksheet.contextMenu.paste.rows.normal',
                    }),
                    action: () => this.handlePaste(false),
                  },
                  {
                    name: this.props.intl.formatMessage({
                      id: 'bulksheet.contextMenu.paste.rows.selectedCol',
                    }),
                    action: () =>
                      this.displayPasteColumnsSelectionDialog(false),
                  },
                ],
                disabled: !this.checkPastable(false).pastable,
              }
            : {
                name: this.props.intl.formatMessage(
                  { id: 'bulksheet.contextMenu.paste.rows' },
                  {
                    clipboard: this.state.clipboard.length,
                    selected: this.getSelectedRowRangeSize(),
                  }
                ),
                disabled: !this.checkPastable(false).pastable,
                action: () => this.handlePaste(false),
                tooltip: !this.checkPastable(false).pastable
                  ? this.checkPastable(false).message
                  : undefined,
                icon: getMenuIconHtml(ContextMenuItemId.PASTE_ROW_AS_CHILD),
              }
          : undefined,
        // Paste row as child
        isEnable(ContextMenuItemId.PASTE_ROW_AS_CHILD) && this.treeProperty
          ? this.options.getPasteColumnsCandidate
            ? {
                name: this.props.intl.formatMessage(
                  { id: 'bulksheet.contextMenu.paste.children' },
                  {
                    clipboard: this.state.clipboard.length,
                    selected: this.getSelectedRowRangeSize(),
                  }
                ),
                icon: getMenuIconHtml(ContextMenuItemId.PASTE_ROW_AS_CHILD),
                subMenu: [
                  {
                    name: this.props.intl.formatMessage({
                      id: 'bulksheet.contextMenu.paste.rows.normal',
                    }),
                    action: () => this.handlePaste(true),
                    icon: getMenuIconHtml(
                      ContextMenuItemId.PASTE_ROW_AS_CHILD_NAME
                    ),
                    shortcut: this.options.isSetKeyBind
                      ? intl.formatMessage({
                          id: 'bulksheet.contextMenu.shortcut.row.paste.name',
                        })
                      : undefined,
                  },
                  {
                    name: this.props.intl.formatMessage({
                      id: 'bulksheet.contextMenu.paste.rows.selectedCol',
                    }),
                    action: () => this.displayPasteColumnsSelectionDialog(true),
                    icon: getMenuIconHtml(
                      ContextMenuItemId.PASTE_ROW_AS_CHILD_SELECT
                    ),
                    shortcut: this.options.isSetKeyBind
                      ? intl.formatMessage({
                          id: 'bulksheet.contextMenu.shortcut.row.paste.data',
                        })
                      : undefined,
                  },
                ],
                disabled: !this.checkPastable(true).pastable,
                tooltip: !this.checkPastable(true).pastable
                  ? this.checkPastable(true).message
                  : undefined,
              }
            : {
                name: this.props.intl.formatMessage(
                  { id: 'bulksheet.contextMenu.paste.children' },
                  {
                    clipboard: this.state.clipboard.length,
                    selected: this.getSelectedRowRangeSize(),
                  }
                ),
                disabled: !this.checkPastable(true).pastable,
                action: () => this.handlePaste(true),
                tooltip: !this.checkPastable(true).pastable
                  ? this.checkPastable(true).message
                  : undefined,
                icon: getMenuIconHtml(ContextMenuItemId.PASTE_ROW_AS_CHILD),
              }
          : undefined,
        isEnable(ContextMenuItemId.CUT_ROW)
          ? {
              name: this.props.intl.formatMessage(
                { id: 'bulksheet.contextMenu.cut' },
                { count: this.getSelectedRowRangeSize() }
              ),
              disabled: !this.checkCopiable(),
              action: () => this.cutRowData(),
              icon: getMenuIconHtml(ContextMenuItemId.CUT_ROW),
              tooltip: !this.checkCopiable()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.cut',
                  })
                : undefined,
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.shortcut.cut.row',
                  })
                : undefined,
            }
          : undefined,
        isEnable(ContextMenuItemId.INSERT_CUT_ROW)
          ? {
              name: this.props.intl.formatMessage(
                { id: 'bulksheet.contextMenu.insert.cutrows' },
                { cutrows: this.state.cutRows?.length }
              ),
              icon: getMenuIconHtml(ContextMenuItemId.INSERT_CUT_ROW),
              disabled: !this.canInsertCutRow().addable,
              action: () =>
                this.insertCutRowData(this.extractNodeInSelectedRange()[0]),
              tooltip: !this.canInsertCutRow().addable
                ? this.canInsertCutRow().message
                : undefined,
              shortcut: this.options.isSetKeyBind
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.shortcut.cut.row.paste',
                  })
                : undefined,
            }
          : undefined,
      ].filter(v => !!v) as MenuItemDef[],
    }
  }

  generateUtilityContextMenu = (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ): ContextMenuGroup | undefined => {
    if (!params.node || !params.node.data) {
      return undefined
    }
    const rowNode: RowNode = params.node
    // Get exportable row id
    const selectedRange = this.gridApi!.getCellRanges() || []
    let targetIds: string[] = []
    // Avoid exporting multiple selected range not to mix data
    const range = selectedRange.filter(v => {
      if (rowNode.rowIndex === null || !v.startRow || !v.endRow) {
        return false
      }
      const index = rowNode.rowIndex
      const start = v.startRow.rowIndex
      const end = v.endRow.rowIndex
      if (start <= end) {
        return start <= index && index <= end
      } else {
        return end <= index && index <= start
      }
    })
    if (range.length > 0) {
      const start = range[0].startRow!.rowIndex
      const end = range[0].endRow!.rowIndex
      const [from, to] = start <= end ? [start, end] : [end, start]
      for (let i = from; i <= to; i++) {
        const node = this.gridApi!.getDisplayedRowAtIndex(i)
        if (!node) continue
        if (this.isServerSideRowModel) {
          const targetRows = this.rowDataManager.getFlatDescendants(
            node.id || ''
          )
          if (targetRows) {
            targetIds = [...targetIds, ...targetRows.map(v => v.uuid)]
          }
        } else if (
          node.allLeafChildren &&
          Array.isArray(node.allLeafChildren)
        ) {
          targetIds.push(...node.allLeafChildren.map(v => v.id || ''))
        }
      }
      targetIds = _.uniq(targetIds)
    }
    const isEnable = (id: ContextMenuItemId) => !menu || menu.includes(id)

    return {
      id: ContextMenuGroupId.EXPORT_ROW_GROUP,
      items: [
        // Expand all
        isEnable(ContextMenuItemId.EXPAND_ALL) &&
        this.treeProperty &&
        !this.isServerSideRowModel
          ? {
              name: this.props.intl.formatMessage({
                id: 'bulksheet.contextMenu.expand.allLeafChildren',
              }),
              disabled: !this.checkExpandable(),
              action: () =>
                this.extractNodeInSelectedRange().forEach(node =>
                  this.expandChildRows(node)
                ),
              icon: getMenuIconHtml(ContextMenuItemId.EXPAND_ALL),
              tooltip: !this.checkExpandable()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.expand.all',
                  })
                : undefined,
            }
          : undefined,
        // Collapse all
        isEnable(ContextMenuItemId.COLLAPSE_ALL) && this.treeProperty
          ? {
              name: this.props.intl.formatMessage({
                id: 'bulksheet.contextMenu.collapse.allLeafChildren',
              }),
              disabled: !this.checkCollapsible(),
              action: () =>
                this.extractNodeInSelectedRange().forEach(node =>
                  this.collapseChildRows(node)
                ),
              icon: getMenuIconHtml(ContextMenuItemId.COLLAPSE_ALL),
              tooltip: !this.checkCollapsible()
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.collapse.all',
                  })
                : undefined,
            }
          : undefined,
        // Export all
        isEnable(ContextMenuItemId.EXPORT_ALL) &&
        this.options.enableExcelExport &&
        this.treeProperty
          ? {
              name: this.props.intl.formatMessage(
                { id: 'bulksheet.contextMenu.export.excel' },
                { count: targetIds.length }
              ),
              action: () => this.openExcelOutputColumnSelectDialog(targetIds),
              icon: getMenuIconHtml(ContextMenuItemId.EXPORT_ALL),
            }
          : undefined,
      ].filter(v => !!v) as MenuItemDef[],
    }
  }

  generateURLCopyContextMenu = (
    params: GetContextMenuItemsParams,
    menu?: ContextMenuItemId[]
  ): ContextMenuGroup | undefined => {
    if (!params.node || !params.node.data || !params.node.data.wbsItem) {
      return undefined
    }

    const isEnable = (id: ContextMenuItemId) => !menu || menu.includes(id)
    return {
      id: ContextMenuGroupId.CUSTOM,
      items: [
        isEnable(ContextMenuItemId.COPY_URL)
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.url.copy',
              }),
              action: () => {
                const wbsItem = params.node?.data.wbsItem
                if (!wbsItem) return

                const externalId = getExternalIdByWbsItem(wbsItem)
                const path = getPathByExternalId(externalId)
                const url = `${window.location.origin}${path}/${wbsItem.code}`
                navigator.clipboard.writeText(url)
              },
              icon: getMenuIconHtml(ContextMenuItemId.COPY_URL),
            }
          : undefined,
        isEnable(ContextMenuItemId.COPY_URL_AND_NAME)
          ? {
              name: intl.formatMessage({
                id: 'bulksheet.contextMenu.urlAndName.copy',
              }),
              action: () => {
                const wbsItem = params.node?.data.wbsItem
                if (!wbsItem) return

                const externalId = getExternalIdByWbsItem(wbsItem)
                const path = getPathByExternalId(externalId)
                const urlAndName = `${wbsItem.displayName}\n${window.location.origin}${path}/${wbsItem.code}`
                navigator.clipboard.writeText(urlAndName)
              },
              icon: getMenuIconHtml(ContextMenuItemId.COPY_URL_AND_NAME),
            }
          : undefined,
      ].filter(v => !!v) as MenuItemDef[],
    }
  }

  getContextMenuItems = (params: GetContextMenuItemsParams): any[] => {
    if (
      !params.node ||
      !params.node.data ||
      params.node.data.isTotal ||
      !this.options.generateContextMenuItems
    ) {
      return []
    }
    return (
      this.options
        .generateContextMenuItems(params, this)
        ?.flattenContextMenuGroups() || []
    )
  }

  private refreshAncestors(node?: RowNode) {
    if (node && this.options.fieldRefreshedAfterMove) {
      // Refresh parent nodes to render calculations
      refreshAncestors(
        this.gridApi!,
        this.options.fieldRefreshedAfterMove,
        node
      )
    }
  }

  private refreshRowNodesAfterMove(nodes: RowNode[]) {
    if (this.options.fieldRefreshedAfterMove) {
      this.gridApi!.refreshCells({
        rowNodes: nodes,
        columns: this.options.fieldRefreshedAfterMove,
        force: true,
      })
    }
  }

  private closeDialog = () => {
    this.setState({ alertDialogState: { isOpen: false } })
  }

  private getSelectedRowRangeSize = (): number => {
    const selectedRange = this.gridApi!.getCellRanges() || []
    let result = 0
    selectedRange.forEach(range => {
      result +=
        range.startRow && range.endRow
          ? Math.abs(range.endRow.rowIndex - range.startRow.rowIndex) + 1
          : 0
    })
    return result
  }

  private saveFocusedRowUuid = () => {
    const focusedCell = this.gridApi!.getFocusedCell()
    if (focusedCell) {
      const focusedRow = this.gridApi!.getDisplayedRowAtIndex(
        focusedCell.rowIndex
      )
      if (focusedRow) {
        this.focusedRowUuid = focusedRow.id
      }
    }
  }

  private checkCopiable = (): boolean => {
    const data = this.extractNodeInSelectedRange().map(node => node.data)
    const uuids = data.map(row => row.uuid)
    const ancestorData = data.filter(
      row => !uuids.includes(row.parentUuid || '')
    )
    const parentUuidsOfAncestors = ancestorData
      .map(row => row.parentUuid || '')
      .filter((uuid, index, self) => self.indexOf(uuid) === index)
    if (parentUuidsOfAncestors.length === 1) {
      return this.options.checkRowCopiable(data as R[])
    }
    return false
  }

  private checkBulkCopiable = (): boolean => {
    const data = this.extractNodeInSelectedRange().map(node => node.data)
    return data.length === 1 && this.options.checkRowBulkCopiable(data[0] as R)
      ? true
      : false
  }

  private canInsertCutRow = (): {
    addable: boolean
    message: string | undefined
  } => {
    if (!this.state.cutRows || this.state.cutRows.length === 0) {
      return {
        addable: false,
        message: intl.formatMessage({
          id: 'bulksheet.contextMenu.disabled.insert.cutrows',
        }),
      }
    }
    if (this.extractNodeInSelectedRange().length !== 1) {
      return {
        addable: false,
        message: intl.formatMessage({
          id: 'bulksheet.contextMenu.disabled.multiple.row.selected',
        }),
      }
    }
    const selectedRow = this.extractNodeInSelectedRange()[0]
    const cutRows = this.state.cutRows
    if (
      cutRows.some(node => node === selectedRow) ||
      (!this.treeProperty &&
        cutRows.some(node => node.group || !isRootNode(node.parent!.id!)))
    ) {
      return {
        addable: false,
        message: intl.formatMessage({
          id: 'bulksheet.contextMenu.disabled.reason.same.row.selected',
        }),
      }
    }
    if (cutRows.every(node => this.isDescendant(selectedRow, node))) {
      return {
        addable: false,
        message: intl.formatMessage({
          id: 'bulksheet.contextMenu.disabled.reason.under.clipped.row',
        }),
      }
    }
    const addable = cutRows.every(node =>
      this.options.canAddChild(node.data, selectedRow.data, this)
    )
    return {
      addable,
      message: !addable
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.disabled.reason.add.impossible.cut.row',
          })
        : undefined,
    }
  }

  private copyRowData = () => {
    const nodes = this.extractNodeInSelectedRange()
    const clipboard = this.options.copyRow
      ? this.options.copyRow(nodes, this)
      : nodes.map(node => {
          return { ...node.data }
        })
    this.setState({ clipboard: clipboard })
  }

  private copyRowDataWithChildren = async () => {
    let clipboard: R[] = []
    if (this.isServerSideRowModel) {
      clipboard = await this.getSelectedRowDataWithChildren()
    } else {
      const nodes = this.extractNodeInSelectedRowsWithChildren()
      clipboard = this.options.copyRow
        ? this.options.copyRow(nodes, this)
        : nodes.map(node => {
            return { ...node.data }
          })
    }
    this.setState({ clipboard: clipboard })
  }

  private cutRowData = () => {
    this.setState({
      cutRows: this.extractNodeInSelectedRange().map(node => {
        return node
      }),
    })
  }

  private distinctRowNodes = (rowNodes: RowNode[]): RowNode[] => {
    const uuidList = rowNodes.map(n => n.data.uuid)
    return rowNodes.filter((n, i) => uuidList.lastIndexOf(n.data.uuid) === i)
  }

  private insertCutRowData = (newParentRow: RowNode) => {
    let nodes = this.state.cutRows
    const droppedOnTreeColumn = true
    try {
      if (droppedOnTreeColumn) {
        this.options.beforeParentChange &&
          this.options.beforeParentChange(nodes, newParentRow)
        // Move to child
        if (this.isServerSideRowModel) {
          this.rowDataManager.moveToChild(
            nodes.map(v => v.data),
            newParentRow.data.uuid
          )
          nodes.forEach(node => this.refreshAncestors(getParentNode(node)))
        } else {
          const refreshNodes: RowNode[] = []
          nodes.forEach(node => {
            refreshNodes.push(...getAncestors(getParentNode(node)))
            const data: R[] = [node.data]
            node.data.treeValue = [
              ...newParentRow.data.treeValue,
              node.data.treeValue[node.data.treeValue.length - 1],
            ]
            resetChildTreeValue(data, node)
            const expandedRowIds: string[] = []
            this.gridApi!.forEachNode(node => {
              node && node.id && node.expanded && expandedRowIds.push(node.id)
            })
            this.rowDataManager.moveToChild(
              data,
              newParentRow.data.uuid,
              node.data.uuid
            )
            expandedRowIds.forEach(nodeId => {
              const node = this.gridApi!.getRowNode(nodeId)
              node && node.setExpanded(true)
            })
            refreshNodes.push(...getAncestors(node))
          })
          this.refreshRowNodesAfterMove(this.distinctRowNodes(refreshNodes))
        }
        this.refreshAncestors(newParentRow)
        return
      }
      // Move to prev or next sibling
      if (this.isServerSideRowModel) {
        this.rowDataManager.moveRows!(
          nodes.map(v => v.data),
          newParentRow.data.uuid
        )
      } else {
        let overNode = newParentRow
        const nodesMoved = nodes
        nodesMoved.forEach(node => {
          this.rowDataManager.moveRow!(node.data, overNode.data.uuid)
          overNode = node
        })
      }
    } finally {
      this.gridApi!.clearRangeSelection()
      this.setState({ isLoading: false })
      this.setState({ cutRows: [] })
      store.dispatch(requireSave())
    }
  }

  private displayPasteColumnsSelectionDialog = (pasteAsChildren: boolean) => {
    if (!this.options.getPasteColumnsCandidate) return
    const candidate = this.options.getPasteColumnsCandidate(this)
    const pasteSelectedColumns = (
      allList: MultiSelectDialogSelection[],
      selectedItem: MultiSelectDialogSelection[]
    ) => {
      const selectedColumnPaths = selectedItem.map(col => col.value)
      const systemRequiredCols = candidate
        .filter(v => v.defaultChecked && v.hidden)
        .filter(v => !selectedColumnPaths.includes(v.path))
        .map(v => v.path)
      selectedColumnPaths.push(...systemRequiredCols)
      this.handlePaste(pasteAsChildren, selectedColumnPaths)
    }
    this.setState({
      children: (
        <MultiSelectDialog
          onClose={() => this.setState({ children: null })}
          onSubmit={pasteSelectedColumns}
          dialogTitle={intl.formatMessage({
            id: 'dialog.contextMenu.paste.selectedCol.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'dialog.contextMenu.paste.selectedCol.submit',
          })}
          allCheckBoxLabel={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
          })}
          getSelectList={this.getPasteColumnSelectionList}
          hideIcon={true}
          excludeValues={candidate.filter(v => v.disabled).map(v => v.path)}
        />
      ),
    })
  }

  private getPasteColumnSelectionList = () => {
    const list: MultiSelectDialogSelection[] = this.options
      .getPasteColumnsCandidate!(this)
      .filter(v => !v.hidden)
      .map(v => ({
        value: v.path,
        displayName: v.label,
        defaultChecked: v.defaultChecked,
      }))
    return list
  }

  private checkPastable = (
    pasteAsChildren: boolean
  ): { pastable: boolean; message: string | undefined } => {
    if (this.state.clipboard.length === 0) {
      return {
        pastable: false,
        message: intl.formatMessage({
          id: 'bulksheet.contextMenu.disabled.paste.rows',
        }),
      }
    }
    const selectedData = this.extractNodeInSelectedRange().map(
      node => node.data
    )
    let parent: R[] = []
    if (pasteAsChildren) {
      parent = selectedData as R[]
      const clipboard = this.state.clipboard as R[]
      if (this.isServerSideRowModel) {
        if (
          clipboard.some(row =>
            clipboard.map(v => v.uuid).includes(row.parentUuid!)
          ) &&
          this.extractNodeInSelectedRange().some(
            (node: RowNode) =>
              node.group &&
              node.data &&
              (!node.data.children || node.data.children.length === 0)
          )
        ) {
          // TODO Fix pasting trees to unfetched node
          return {
            pastable: false,
            message: intl.formatMessage({
              id: 'bulksheet.contextMenu.disabled.reason.unexpanded',
            }),
          }
        }
        if (
          selectedData.length > 1 &&
          selectedData.some(
            row =>
              row.parentUuid &&
              selectedData.map(v => v.uuid).includes(row.parentUuid)
          )
        ) {
          // Prevent pasting rows to parent and the child at the same time
          return {
            pastable: false,
            message: intl.formatMessage({
              id: 'bulksheet.contextMenu.disabled.reason.same.parent',
            }),
          }
        }
      }
    } else {
      selectedData.forEach(data => {
        const parentNode = this.gridApi!.getRowNode(data.parentUuid || '')
        if (parentNode) {
          parent.push(parentNode.data as R)
        }
      })
    }
    const pastable = this.options.checkRowPastable(
      parent,
      selectedData as R[],
      this.state.clipboard as R[]
    )
    return {
      pastable,
      message: !pastable
        ? intl.formatMessage({
            id: 'bulksheet.contextMenu.disabled.reason.add.impossible.copy.row',
          })
        : undefined,
    }
  }

  private handlePaste = (
    pasteAsChildren: boolean,
    selectedColIds?: string[]
  ) => {
    if (this.getSelectedRowRangeSize() > 1) {
      const alertDialogState: AlertDialogState = {
        isOpen: true,
        title: this.props.intl.formatMessage({
          id: 'dialog.title.pasteRows',
        }),
        message: this.props.intl.formatMessage({
          id: pasteAsChildren
            ? 'dialog.message.pasteRowsAsChildren'
            : 'dialog.message.pasteRows',
        }),
        submitButtonTitle: this.props.intl.formatMessage({
          id: 'dialog.submit.pasteRows',
        }),
        extraContent: this.getDisplayNameListAsMarkUp(
          this.extractNodeInSelectedRange()
        ),
        submitHandler: () => {
          this.pasteRowData(pasteAsChildren, selectedColIds)
          this.closeDialog()
        },
        closeHandler: this.closeDialog,
        closeButtonTitle: this.props.intl.formatMessage({
          id: 'dialog.cancel',
        }),
      }
      this.setState({
        alertDialogState,
      })
    } else {
      this.pasteRowData(pasteAsChildren, selectedColIds)
    }
  }

  private getDisplayNameListAsMarkUp = (nodes: RowNode[]) => {
    let displayNameList = ''
    nodes.forEach(node => {
      let title = ''
      if (this.options.displayNameField) {
        title = objects.getValue(node.data, this.options.displayNameField)
      } else {
        title = node.data.displayName || node.data.name
      }
      title =
        title ||
        this.props.intl.formatMessage({
          id: 'displayName.undefined',
        })
      displayNameList += `<p>${title}</p>`
    })
    return <MarkupViewer content={displayNameList} />
  }

  private pasteRowData = async (
    pasteAsChildren: boolean,
    selectedColIds?: string[]
  ) => {
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      await this.waitTime(200) // TODO remove wait time for show loading
      const selectedData = this.extractNodeInSelectedRange().map(
        node => node.data
      )
      const targetColIds = selectedColIds
        ? selectedColIds
        : this.options.getPasteColumnsCandidate
        ? this.options
            .getPasteColumnsCandidate(this)
            .filter(v => v.defaultChecked && (v.disabled || v.hidden))
            .map(v => v.path)
        : undefined

      if (this.isServerSideRowModel) {
        this.pasteRowsServerSide(selectedData, targetColIds)
        store.dispatch(requireSave())
        return
      }
      selectedData.forEach(data => {
        const copied = this.options.rowDataSpec.duplicateRows(
          this.state.clipboard as R[],
          targetColIds
        )
        const uuids = copied.map(row => row.uuid)
        const ancestors = copied.filter(
          row => !row.parentUuid || !uuids.includes(row.parentUuid)
        )
        let parentUuid
        if (pasteAsChildren) {
          parentUuid = data.uuid
        } else {
          const parentNode = this.gridApi!.getRowNode(data.parentUuid || '')
          parentUuid = parentNode ? parentNode.id : undefined
        }
        let addedNodes: RowNode[] = []
        ancestors.forEach((ancestor, index, self) => {
          ancestor.parentUuid = parentUuid
          this.recursivePasteRow(
            index === 0 ? data.uuid : self[index - 1].uuid,
            pasteAsChildren ? data.uuid : parentUuid || '',
            ancestor,
            data,
            copied,
            targetColIds
          )
          this.rowDataManager.focusRow(copied[0].uuid)
          const addedNode = this.gridApi!.getRowNode(ancestor.uuid)
          if (addedNode) {
            addedNodes.push(addedNode)
          }
        })
        addedNodes.forEach(node => {
          if (node.isExpandable() && !node.expanded) {
            node.setExpanded(true)
          }
        })
        if (pasteAsChildren) {
          copied.forEach(data => {
            const parentNode = this.gridApi!.getRowNode(data.uuid)
            if (
              parentNode &&
              parentNode.isExpandable() &&
              !parentNode.expanded
            ) {
              parentNode.setExpanded(true)
            }
          })
        }
        this.rowDataManager.focusRow(uuids[0])
      })
      store.dispatch(requireSave())
    } finally {
      this.setState({ isLoading: false })
      this.props.setIsLoading?.(false)
    }
  }

  private pasteRowsServerSide = async (data: R[], targetColIds?: string[]) => {
    for (const rowData of data) {
      const copied = this.options.rowDataSpec.duplicateRows(
        this.state.clipboard as R[],
        targetColIds
      )
      const uuids = copied.map(row => row.uuid)
      const ancestors = copied.filter(
        row => !row.parentUuid || !uuids.includes(row.parentUuid)
      )
      const sleep = () => new Promise(resolve => setTimeout(resolve, 500))
      const addRows = async (rows: R[], parentUuid) => {
        this.options.beforeAdd && this.options.beforeAdd(this, rows, parentUuid)
        this.rowDataManager.addRows(rows, 0, parentUuid)
        let i = 0
        let rowNode: RowNode | undefined = undefined
        while (i < 100 && !rowNode) {
          await sleep()
          rowNode = this.gridApi!.getRowNode(rows[0].uuid)
        }
        for (const row of rows) {
          const children = copied.filter(v => v.parentUuid === row.uuid)
          children.length > 0 && (await addRows(children, row.uuid))
        }
      }
      const overwrittenRows = ancestors.map(row => {
        const params = {
          child: row,
          parent: rowData,
        }
        return this.rowDataSpec.overwriteRowItemsWithParents(
          params,
          targetColIds
        )
      })
      await addRows(overwrittenRows, rowData.uuid)
      this.rowDataManager.focusRow(uuids[0])
    }
  }

  private checkRemovable = (): {
    removable: boolean
    unremovableReason?: string
  } => {
    const selectedNodes = this.extractNodeInSelectedRange()
    if (!!selectedNodes && selectedNodes.length === 0) {
      return { removable: false }
    }
    // Check if removable for each nodes.
    if (selectedNodes.some(n => this.treeRoot.uuid === n.data.uuid)) {
      return {
        removable: false,
        unremovableReason: this.props.intl.formatMessage({
          id: 'bulksheet.contextMenu.remove.disabled.reason.root',
        }),
      }
    }
    const result = this.options.getRowsToRemove
      ? this.options.getRowsToRemove(selectedNodes, this)
      : undefined

    const targetNodes = result ? result.rows : selectedNodes
    const unremovableReasonMessageId = result?.unremovableReasonMessageId
    if (selectedNodes.some(selected => !targetNodes.includes(selected))) {
      return {
        removable: false,
        unremovableReason: unremovableReasonMessageId
          ? this.props.intl.formatMessage({
              id: unremovableReasonMessageId,
            })
          : undefined,
      }
    }
    if (!this.treeProperty || this.isServerSideRowModel) {
      return { removable: true }
    }
    // Check if the delete target is complete tree, which means all child nodes will be deleted at the same time.
    const ancestorNodes = targetNodes.filter(
      node =>
        !targetNodes.some(target => target.data.uuid === node.data.parentUuid)
    )
    let removable = true
    ancestorNodes.forEach(ancestor => {
      let successors = targetNodes.filter(
        node => this.isDescendant(node, ancestor) || node === ancestor
      )
      if (ancestor.allLeafChildren.some(child => !successors.includes(child))) {
        removable = false
      }
    })
    return {
      removable,
      unremovableReason: removable
        ? undefined
        : this.props.intl.formatMessage({
            id: 'bulksheet.contextMenu.remove.disabled.reason.incomplete.tree',
          }),
    }
  }

  private handleRemove = () => {
    const targetNodes = this.options.getRowsToRemove
      ? this.options.getRowsToRemove(this.extractNodeInSelectedRange(), this)
          .rows
      : this.extractNodeInSelectedRange()
    const alertDialogState: AlertDialogState = {
      isOpen: true,
      title: this.props.intl.formatMessage({
        id: 'dialog.title.deleteRows',
      }),
      message: this.props.intl.formatMessage({
        id: 'dialog.message.deleteRows',
      }),
      submitButtonTitle: this.props.intl.formatMessage({
        id: 'dialog.submit.deleteRows',
      }),
      extraContent: this.getDisplayNameListAsMarkUp(targetNodes),
      submitHandler: () => {
        this.removeRows(targetNodes)
        this.closeDialog()
      },
      closeHandler: this.closeDialog,
      closeButtonTitle: this.props.intl.formatMessage({
        id: 'dialog.cancel',
      }),
    }
    this.setState({
      alertDialogState,
    })
  }

  private removeRows = (targetNodes: RowNode[]) => {
    const ancestorNodes = targetNodes.filter(
      node => !targetNodes.some(target => target === node.parent)
    )
    let targetRows: R[] = []
    ancestorNodes.forEach(node => {
      targetRows.push(node.data)
      this.options.beforeRemove &&
        this.options.beforeRemove(this, [node.data], node.data.parentUuid)
      if (node.hasChildren()) {
        if (this.isServerSideRowModel) {
          const getChildrenRecursive = (targetRows: any[], data: Tree<any>) => {
            if (data.children && data.children.length > 0) {
              data.children.forEach(child => {
                targetRows.push(child)
                getChildrenRecursive(targetRows, child)
              })
            }
          }
          getChildrenRecursive(targetRows, node.data)
        } else {
          node.allLeafChildren.forEach((node: RowNode) => {
            if (
              node.data &&
              targetRows.findIndex(v => v.uuid === node.data.uuid) < 0
            ) {
              targetRows.push(node.data)
            }
          })
        }
      }
    })
    this.rowDataManager.removeRows(targetRows.reverse())
    const addable =
      typeof this.options.addable === 'function'
        ? this.options.addable(this.state)
        : this.options.addable
    if (this.rowDataManager.getAllRows().length === 0 && addable) {
      this.addRow(ancestorNodes[0].id)
    }
    this.refreshAncestors(targetNodes[0].parent || undefined)

    store.dispatch(requireSave())
  }

  private recursivePasteRow = (
    addToUuid: string,
    parentUuid: string,
    row: R,
    parent: R,
    copiedRows: R[],
    selectedColIds?: string[]
  ) => {
    const params = { child: row, parent: parent }
    const pastingRow = this.options.rowDataSpec.overwriteRowItemsWithParents(
      params,
      selectedColIds
    )
    this.addRow(addToUuid, parentUuid, pastingRow, true)
    const children = copiedRows.filter(
      row => row.parentUuid === pastingRow.uuid
    )
    children.forEach((child, index, self) => {
      this.recursivePasteRow(
        index === 0 ? pastingRow.uuid : self[index - 1].uuid,
        pastingRow.uuid,
        child,
        pastingRow,
        copiedRows,
        selectedColIds
      )
    })
  }

  private extractNodeInSelectedRange = (): RowNode[] => {
    const selectedRange = this.gridApi!.getCellRanges() || []
    let nodes: RowNode[] = []
    selectedRange.forEach(range => {
      const startRowIndex =
        range.startRow && range.endRow
          ? Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
          : 0
      const endRowIndex =
        range.startRow && range.endRow
          ? Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
          : 0
      for (let i = startRowIndex; i <= endRowIndex; i++) {
        const displayedRow = this.gridApi!.getDisplayedRowAtIndex(i)
        displayedRow && nodes.push(displayedRow)
      }
    })
    return nodes
  }

  private extractNodeInSelectedRowsWithChildren = (): RowNode[] => {
    const selectedRange = this.gridApi!.getCellRanges() || []
    let nodes: RowNode[] = []
    if (
      selectedRange.length === 1 &&
      selectedRange[0].startRow?.rowIndex ===
        selectedRange[0].endRow?.rowIndex &&
      !!selectedRange[0].startRow?.rowIndex
    ) {
      const allRows = this.gridApi!.getDisplayedRowAtIndex(
        selectedRange[0].startRow?.rowIndex
      )?.allLeafChildren
      if (allRows) nodes = allRows
    }
    return nodes
  }

  private getSelectedRowDataWithChildren = async (): Promise<R[]> => {
    const selectedRange = this.gridApi!.getCellRanges() || []
    let rowData: R[] = []
    if (
      selectedRange.length === 1 &&
      selectedRange[0].startRow?.rowIndex ===
        selectedRange[0].endRow?.rowIndex &&
      !!selectedRange[0].startRow?.rowIndex
    ) {
      const selectedRow = this.gridApi!.getDisplayedRowAtIndex(
        selectedRange[0].startRow?.rowIndex
      )
      if (selectedRow) {
        const rows = await this.options?.getCopiedRowDataWithChildren!(
          this,
          selectedRow.data.uuid
        )
        rowData = rows
      }
    }
    return rowData
  }

  private checkExpandable = (): boolean => {
    const selectedNodes = this.extractNodeInSelectedRange()
    if (this.isServerSideRowModel && selectedNodes.length > 1) {
      // TODO Improve performance.
      return false
    }
    return selectedNodes.some(node => this.recursiveCheckExpandable(node))
  }

  private checkCollapsible = (): boolean => {
    const selectedNodes = this.extractNodeInSelectedRange()
    return selectedNodes.some(node => node.expanded)
  }

  private recursiveCheckExpandable = (node: RowNode): boolean => {
    if (this.isServerSideRowModel) {
      return node.isExpandable()
    }
    if (node.isExpandable() && !node.expanded) {
      return true
    }
    if (!this.gridApi) {
      return false
    }
    let result = false
    this.gridApi.forEachNode(n => {
      if (
        n.parent &&
        n.parent.id === node.id &&
        this.recursiveCheckExpandable(n)
      ) {
        result = true
      }
    })
    return result
  }

  private isDescendant = (a: RowNode, b: RowNode) => {
    let parent = a.parent
    while (parent) {
      if (parent === b) {
        return true
      }
      parent = parent.parent
    }
    return false
  }

  waitTime = async (milliseconds): Promise<void> => {
    await new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    })
    return
  }

  canDrop = (
    params: { node: RowNode; overNode?: RowNode },
    toChild: boolean
  ): boolean => {
    const nodes = this.getSelectedNodesForDrag(params.node)
    if (
      !this.state.hasUpdatePermission ||
      !this.state.editable ||
      !params.overNode ||
      nodes.length === 0 ||
      nodes.some(node => node === params.overNode) ||
      (!this.treeProperty &&
        nodes.some(node => node.group || !isRootNode(node.parent!.id!)))
    ) {
      return false
    }
    if (toChild) {
      if (nodes.every(node => this.isDescendant(params.overNode!, node))) {
        return false
      }
      return nodes.every(node =>
        this.options.canAddChild(node.data, params.overNode!.data, this)
      )
    }
    return (
      !this.treeProperty ||
      nodes.every(
        node => node.data.parentUuid === params.overNode!.data.parentUuid
      )
    )
  }

  getSelectedNodesForDrag = (dragNode: RowNode): RowNode[] => {
    const selectedNodes = this.gridApi!.getSelectedNodes()
    if (!selectedNodes.includes(dragNode)) {
      this.gridApi!.getSelectedNodes().forEach(node => node.setSelected(false))
      return [dragNode]
    }
    // Single row selected
    if (selectedNodes.length === 1) return selectedNodes
    const isDisplayedNode = (node: RowNode): boolean => {
      if (node.rowIndex === null || node.rowIndex < 0) return false
      if (node.parent && node.parent.level >= 0) {
        return node.parent.expanded
      }
      return true
    }
    const showNodes = selectedNodes
      .filter(isDisplayedNode)
      .sort((a, b) => (a.rowIndex! > b.rowIndex! ? 1 : -1))
    // Avoid moving node at parent level
    if (showNodes.some(node => node.level < showNodes[0].level)) return []
    // Move only selected top level nodes
    return showNodes.filter(node => node.level === showNodes[0].level)
  }

  getAllRowNodesWithChildren = (nodes: RowNode[]): RowNode[] => {
    const rows: R[] = _.compact(
      nodes.map(node => {
        const rowData: R = node.data
        if (!rowData || !rowData.uuid) return
        return this.rowDataManager.getFlatDescendants(rowData.uuid)
      })
    ).flat()
    return rows
      .map(row => this.gridApi!.getRowNode(row.uuid))
      .filter(v => !!v) as RowNode[]
  }

  onRowDragEnter = (params: RowDragMoveEvent) => {
    const treeElem = document.querySelector(
      '[col-id="ag-Grid-AutoColumn"][role="columnheader"]'
    )
    if (!treeElem) return
    this.treeElem = treeElem
  }

  onRowDragMove = (params: RowDragMoveEvent) => {
    if (!params.overNode) return
    const prevNode = this.overNode
    const prevDragOnTreeColumn = this.dragOnTreeColumn
    this.overNode = params.overNode
    this.dragNode = params.node
    this.dragOnTreeColumn = this.withinTreeColumn(params.event.x)
    if (
      prevNode !== this.overNode ||
      prevDragOnTreeColumn !== this.dragOnTreeColumn
    ) {
      this.redrawDragCells([prevNode!, params.overNode])
    }
  }

  private withinTreeColumn = (x: number) => {
    if (!this.treeElem) {
      return false
    }
    const pos = this.treeElem.getBoundingClientRect()
    const from = pos.left
    const to = pos.right
    return x >= from && x <= to
  }

  onRowDragEnd = async (params: RowDragEvent) => {
    this.resetDragState(params)
    const droppedOnTreeColumn = this.withinTreeColumn(params.event.x)
    if (!this.canDrop(params, droppedOnTreeColumn)) {
      return
    }
    runUseCaseAsyncWithPerfMonitoring('MoveRow', async () => {
      this.setState({ isLoading: true })
      this.props.setIsLoading?.(true)
      try {
        await this.waitTime(100) // TODO remove wait time for show loading

        const nodes = this.getSelectedNodesForDrag(params.node)
        if (droppedOnTreeColumn) {
          this.options.beforeParentChange &&
            this.options.beforeParentChange(nodes, params.overNode)
          // Move to child
          if (this.isServerSideRowModel) {
            this.rowDataManager.moveToChild(
              nodes.map(v => v.data),
              params.overNode!.data.uuid
            )
            nodes.forEach(node => this.refreshAncestors(getParentNode(node)))
          } else {
            const refreshNodes: RowNode[] = []
            nodes.forEach(node => {
              refreshNodes.push(...getAncestors(getParentNode(node)))
              const data: R[] = [node.data]
              node.data.treeValue = [
                ...params.overNode!.data.treeValue,
                node.data.treeValue[node.data.treeValue.length - 1],
              ]
              resetChildTreeValue(data, node)
              const expandedRowIds: string[] = []
              this.gridApi!.forEachNode(node => {
                node && node.id && node.expanded && expandedRowIds.push(node.id)
              })
              this.rowDataManager.moveToChild(
                data,
                params.overNode!.data.uuid,
                node.data.uuid
              )
              expandedRowIds.forEach(nodeId => {
                const node = this.gridApi!.getRowNode(nodeId)
                node && node.setExpanded(true)
              })
              refreshNodes.push(...getAncestors(node))
            })
            this.refreshRowNodesAfterMove(this.distinctRowNodes(refreshNodes))
          }
          this.refreshAncestors(params.overNode)
          return
        }
        // Move to prev or next sibling
        if (this.isServerSideRowModel) {
          this.rowDataManager.moveRows!(
            nodes.map(v => v.data),
            params.overNode!.data.uuid
          )
        } else {
          let overNode = params.overNode
          const nodesMoved =
            nodes[0].rowIndex !== null && nodes[0].rowIndex < params.overIndex
              ? nodes
              : nodes.reverse()
          nodesMoved.forEach(node => {
            this.rowDataManager.moveRow!(node.data, overNode!.data.uuid)
            overNode = node
          })
        }
      } finally {
        // Reset focus and row selection
        this.gridApi!.clearRangeSelection()
        this.rowDataManager.focusRow(params.node.data.uuid, 'drag')
        this.setState({ isLoading: false })
        this.props.setIsLoading?.(false)
        store.dispatch(requireSave())
      }
    })
  }

  onRowDragLeave = (params: RowDragEvent) => {
    this.resetDragState(params)
    delete this.treeElem
  }

  private redrawDragCells = (rowNodes: RowNode[]) => {
    this.gridApi!.refreshCells({
      rowNodes: rowNodes.filter(v => !!v),
      columns: ['drag', 'ag-Grid-AutoColumn'],
    })
  }

  private resetDragState = (params: RowDragEvent) => {
    delete this.dragNode
    delete this.overNode
    params.overNode && this.redrawDragCells([params.overNode, params.node])
  }

  exportExcel = async (targetIds?: string[], selectedColIds?: string[]) => {
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      await this.waitTime(500) // TODO remove wait time for show loading
      await logDownloadExcel()
      this.excel!.exportExcel(this.props.externalId, targetIds, selectedColIds)
    } finally {
      this.setState({ isLoading: false })
      this.props.setIsLoading?.(false)
    }
  }

  importExcel = async (rawData: Uint8Array) => {
    this.setState({ isLoading: true })
    this.props.setIsLoading?.(true)
    try {
      const root = {
        ...this.treeRoot,
        children: [],
      } as Tree<T>
      const result = await this.excel!.importExcel(
        rawData,
        root,
        this.options.rowDataSpec.importNewRow,
        this
      )
      if (result) {
        await this.initRowDataCreator(root, true)
      }
      store.dispatch(requireSave())
    } finally {
      this.setState({ isLoading: false })
      this.props.setIsLoading?.(false)
    }
  }

  private async initRowDataCreator(root: Tree<T>, replaceExisting = false) {
    if (!replaceExisting) {
      let expandedGroupIds = this.rowDataManager.expandedGroupIds
      this.rowDataManager = new ClientSideRowDataManager(this)
      this.rowDataManager.expandedGroupIds = expandedGroupIds
    }
    const children = root && root.children ? root.children : []
    runWithPerfMonitoring('rowDataManager#init', () =>
      this.rowDataManager.init(children, replaceExisting)
    )
    const allRows = this.rowDataManager.getAllRows()
    const initialRowData = {}
    this.rowDataManager.getAllRows().forEach(row => {
      initialRowData[row.uuid] = { ...row }
    })
    const state = {
      ...this.state,
      lockVersion: root.lockVersion,
      rowData: [...allRows],
      initialRowData,
    }
    this.setState(this.options.updateState(root, state))
    this.gridApi!.setRowData([...allRows])
  }

  onClickSetting = () => {
    this.options.onClickSetting!(this)
  }

  private onSelectSavedBulkSheetUIStateDialogList = (
    sharable: boolean,
    uiStateKey: UiStateKey,
    savedUIState: SavedUIState
  ) => {
    if (sharable && isShareableSavedUIState(savedUIState)) {
      const url = generateURLForSharedSearchConditionState(savedUIState)
      window.history.replaceState(null, '', url)
    }
    const { uuid, UIState } = savedUIState
    switch (uiStateKey) {
      case UiStateKey.BulkSheetUIStateSearchCondition: {
        this.rememberSearchCondition(UIState.searchCondition)
        const hasColumnAndFilterUIState: boolean =
          !!UIState.column || !!UIState.filter
        this.restoreSearchCondition({
          isRestoredSavedSearchConditionByUser: true,
        })
        if (hasColumnAndFilterUIState) {
          this.rememberColumnAndFilterState({
            ...this.rememberedColumnAndFilterState,
            column: UIState.column,
            filter: UIState.filter,
          } as any)
          this.restoreColumnState(true)
          const restoreFilterStateAfterRestoredSearchCondition = () => {
            if (this.state.isLoading) {
              // We need to wait for rendering row data when we restore search condition and refresh data.
              // because AgGrid filter requires row data for filtering especially "set filter".
              setTimeout(restoreFilterStateAfterRestoredSearchCondition, 300)
              return
            }
            this.restoreFilterState()
          }
          restoreFilterStateAfterRestoredSearchCondition()
        }
        this.setState({
          lastSelectedUIState: {
            ...this.state.lastSelectedUIState,
            searchConditionUuid: uuid,
            searchConditionIncludeColumn: hasColumnAndFilterUIState,
          },
        })
        break
      }
      case UiStateKey.BulkSheetUIStateColumnAndFilter: {
        this.rememberColumnAndFilterState({
          ...this.rememberedColumnAndFilterState,
          column: UIState.column,
          filter: UIState.filter,
        } as any)
        this.restoreColumnState(true)
        this.restoreFilterState()
        this.setState({
          lastSelectedUIState: {
            ...this.state.lastSelectedUIState,
            columnAndFilterUuid: uuid,
          },
        })
        break
      }
      default:
        throw new Error(
          `Can not restore bulksheet ui state because UiStateKey(${uiStateKey}) is unsupported.`
        )
    }
    this.closeSavedBulkSheetUIStateDialog()
  }

  openSavedBulkSheetUIStateDialog = (
    uiStateKey: UiStateKey,
    showEditorDialogOptionList: boolean = false
  ) => {
    let currentUIState = {}
    let sharable = false
    let editorDialogOptionListProps:
      | SavedUIStateEditorDialogOptionListProps
      | undefined = undefined
    let title = this.props.intl.formatMessage({
      id: `savedUIState.${uiStateKey}`,
    })
    let currentSavedUIStateUuid: string | undefined = undefined
    switch (uiStateKey) {
      case UiStateKey.BulkSheetUIStateSearchCondition: {
        currentUIState = {
          searchCondition: this.options.getSearchCondition!(this.state),
        }
        if (this.rememberedColumnAndFilterState?.column) {
          currentUIState['column'] = this.rememberedColumnAndFilterState?.column
        }
        if (this.rememberedColumnAndFilterState?.filter) {
          currentUIState['filter'] = this.rememberedColumnAndFilterState?.filter
        }
        currentSavedUIStateUuid =
          this.rememberedSelectedUIState.searchConditionUuid
        sharable = true
        if (showEditorDialogOptionList) {
          editorDialogOptionListProps = {
            title: intl.formatMessage({
              id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
            }),
            applicationFunctionUuid: this.props.uuid,
            uiStateKey: UiStateKey.BulkSheetUIStateColumnAndFilter,
            customItemTitle: this.props.intl.formatMessage({
              id: 'savedUIState.current.column.state',
            }),
            getCustomItemUIState: () => {
              return this.getColumnAndFilterUIState()
            },
          }
          title = this.props.intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_SEARCH_CONDITION_COLUMN_FILTER',
          })
        }
        break
      }
      case UiStateKey.BulkSheetUIStateColumnAndFilter: {
        if (this.rememberedColumnAndFilterState) {
          currentUIState = {
            column: this.rememberedColumnAndFilterState?.column,
            filter: this.rememberedColumnAndFilterState?.filter,
          }
          currentSavedUIStateUuid =
            this.rememberedSelectedUIState.columnAndFilterUuid
        } else {
          currentUIState = this.getColumnAndFilterUIState()
        }
        break
      }
      default:
        throw new Error(
          `Can not restore bulksheet ui state because UiStateKey(${uiStateKey}) is unsupported.`
        )
    }

    this.setState({
      savedBulkSheetUIStateDialogState: {
        applicationFunctionUuid: this.props.uuid,
        open: true,
        uiStateKey,
        currentUIState,
        currentSavedUIStateUuid,
        title,
        sharable,
        onSelect: (savedUIState: SavedUIState) => {
          return this.onSelectSavedBulkSheetUIStateDialogList(
            sharable,
            uiStateKey,
            savedUIState
          )
        },
        onClose: this.closeSavedBulkSheetUIStateDialog,
        editorOptionUIStateListProps: editorDialogOptionListProps,
      },
    })
  }

  closeSavedBulkSheetUIStateDialog = () => {
    this.setState({
      savedBulkSheetUIStateDialogState: {
        ...this.state.savedBulkSheetUIStateDialogState,
        open: false,
        onSelect: undefined,
        onClose: undefined,
      },
    })
  }

  getFilteredColumns = (): ColDef[] => {
    if (!this.gridApi) {
      return [] as ColDef[]
    }
    return Object.keys(this.gridApi.getFilterModel())
      .map(col => this.gridApi!.getColumnDef(col))
      .filter(v => !!v) as ColDef[]
  }

  getSortedColumns = (): string[] => {
    if (!this.gridApi || !this.columnApi) {
      return []
    }
    return this.columnApi
      .getColumnState()
      .filter(col => !!col.sort)
      .map(col => this.gridApi!.getColumnDef(col.colId)?.headerName)
      .filter(v => !!v) as string[]
  }

  private onKeyDown = e => {
    this.setState({
      copyHeadersToClipboard: e.shiftKey,
    })
  }

  getColumnAndFilterUIState = (): UIState => {
    return {
      column: this.columnApi?.getColumnState(),
      filter: this.gridApi?.getFilterModel(),
    } as UIState
  }

  onDeleteSortedColumn = (colId: string | ColDef<any>) => {
    this.columnApi?.applyColumnState({
      state: [{ colId: colId.toString(), sort: null }],
    })
  }

  onDeleteSortedAllColumns = () => {
    this.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }

  onChangeSortColumnState = (
    colId: string | ColDef<any>,
    sort: 'asc' | 'desc' | null
  ) => {
    this.columnApi?.applyColumnState({
      state: [{ colId: colId.toString(), sort }],
    })
  }

  refreshFilteredRowDataUuid = () => {
    if (!this.options.refreshFilteredRowDataUuid) {
      return
    }
    this.options.refreshFilteredRowDataUuid(this.state)
    this.gridApi!.onFilterChanged()
  }

  onFilterChanged = async (event: FilterChangedEvent) => {
    const filteredColumns = this.getFilteredColumns()
    this.setState({ filteredColumns }, () => {
      this.props.setFilteredColumns &&
        this.props.setFilteredColumns(filteredColumns)
    })
    if (this.options.onFilterChanged) {
      this.options.onFilterChanged(this)
    }
    if (this.focusedRowUuid) {
      const focusedNode = event.api.getRowNode(this.focusedRowUuid)
      if (focusedNode && focusedNode.rowIndex !== null) {
        const rowIndex = focusedNode.rowIndex
        event.api.clearFocusedCell()
        event.api.clearRangeSelection()
        event.api.ensureIndexVisible(rowIndex)
      }
    }
    await this.rememberCurrentFilterState()
    await this.rememberSelectedColumnAndFilterByLastSelected()
  }

  openExcelOutputColumnSelectDialog = (targetIds?: string[]) => {
    this.setState({
      children: (
        <ExcelExportDialog
          onClose={() => this.setState({ children: null })}
          onSubmit={colIds => this.exportExcel(targetIds, colIds)}
          columnApi={this.columnApi}
        />
      ),
    })
  }

  onChangeHeight = (value: number) => {
    this.rowHeight = value
    this.props.setRowHeight && this.props.setRowHeight(value)
    this.setState({ rowHeight: value })
    this.gridApi!.resetRowHeights()
    this.rememberRowState()
  }

  getAllRowNodes = (): RowNode[] => {
    const nodes: RowNode[] = []
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        nodes.push(node)
      })
    }
    return nodes
  }

  private validateOnCellValueChanged = (
    event: CellValueChangedEvent
  ): boolean => {
    const fieldName = event.colDef.field || event.colDef.colId
    if (!fieldName) return true
    if (event.oldValue === event.newValue) return true
    const valueChangedRowData: RowData = event.data as RowData
    if (
      !valueChangedRowData ||
      !objects.hasPath(valueChangedRowData, fieldName)
    ) {
      return false
    }

    // validate unique column
    let isValid: boolean = true
    if (this.options.uniqueColumnIds?.includes(fieldName)) {
      const externalId = this.viewMeta.toExternalId(fieldName)
      const rows: R[] = this.rowDataManager.getAllRows()
      const duplicateRowIndexes = this.rowDataManager.getDuplicateRowIndex(
        fieldName,
        rows
      )
      const props: FunctionProperty | undefined =
        this.viewMeta.getPropByExternalId(externalId)
      this.rowDataManager.updateErrorMessages(
        externalId,
        rows,
        duplicateRowIndexes,
        intl.formatMessage(
          { id: 'bulksheet.error.duplicate.unique.column.data' },
          { fieldName: props?.name }
        )
      )
      isValid = _.isEmpty(duplicateRowIndexes)
      this.gridApi!.refreshCells({
        columns: [fieldName],
        force: true,
      })
    }
    return isValid
  }

  private updateRowDataCommentSummary = (
    comments: List<Comment> | undefined
  ) => {
    if (!this.gridApi || !this.options.mergeRowCommentSummary) return
    if (!this.state.selectedCommentUuid) return
    const targetRow = this.gridApi.getRowNode(this.state.selectedCommentUuid)
    if (!targetRow?.data) return

    const newData = this.options.mergeRowCommentSummary(
      targetRow.data,
      comments
    )
    this.rowDataManager.updateRow(newData)
  }

  private getRowHeight = () => {
    return this.rowHeight
  }

  private onCellValueChanged = params => {
    if (!this.validateOnCellValueChanged(params)) return
    onCellValueChanged(params, this.eventCache, this.props.projectUuid || '')
  }

  private onPasteStart = () => {
    this.eventCache = []
  }

  private onPasteEnd = params => {
    return onPasteEnd(params, this.eventCache)
  }

  private getRowId = params => {
    return params.data.uuid
  }

  private onDisplayedColumnsChanged = async () => {
    await this.rememberCurrentColumnState()
    await this.rememberSelectedColumnAndFilterByLastSelected()
  }

  private onColumnResized = params => {
    if (params.finished) {
      this.rememberCurrentColumnState()
    }
  }

  private onSortChanged = () => {
    const sortedColumns = this.getSortedColumns()
    this.gridApi!.setSuppressRowDrag(0 < sortedColumns.length)

    const sortColumns = this.columnApi
      ?.getColumnState()
      .filter(col => !!col.sort)
      .map(col => {
        return { ...this.gridApi!.getColumnDef(col.colId), colId: col.colId }
      })
      .filter(v => !!v) as ColDef[]
    this.gridApi!.setSuppressRowDrag(0 < sortedColumns.length)
    const columnState = this.columnApi?.getColumnState()
    const sortedColumnState: { [colId: string]: ColumnState } = {}
    columnState &&
      columnState.forEach(state => {
        if (state.sort) {
          sortedColumnState[state.colId] = state
        }
      })
    const sortColumnsState: SortedColumnState[] = sortColumns.map(col => {
      return {
        colId: col.colId,
        field: col.field,
        headerName: col.headerName,
        sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
      }
    })

    this.setState(
      { sortedColumns },
      () =>
        this.props.setSortedColumns &&
        this.props.setSortedColumns(sortedColumns)
    )
    this.setState(
      { sortColumnsState },
      () =>
        this.props.setSortColumnsState &&
        this.props.setSortColumnsState(sortColumnsState)
    )

    this.rememberCurrentColumnAndFilterState()
    if (this.options.onSortChanged) {
      this.options.onSortChanged(this)
    }
  }

  private onRowDataUpdated = () => {
    if (this.options.onRowDataChanged) {
      this.options.onRowDataChanged(this)
    }
  }

  private onColumnVisible = (e: ColumnVisibleEvent) => {
    this.props?.gridOptions?.onColumnVisible &&
      this.props.gridOptions.onColumnVisible(e)
    this.options.onColumnVisible && this.options.onColumnVisible(e, this)
  }

  private isExternalFilterPresent = () => {
    return (
      Array.isArray(this.options.filteredRowDataUuids) ||
      (!!this.props.filteredUuids && this.props.filteredUuids.length > 0)
    )
  }

  private doesExternalFilterPass = rowNode => {
    const uuid = this.options.getRowDataUuidForFilter
      ? this.options.getRowDataUuidForFilter(rowNode)
      : rowNode.data.uuid
    if (!!this.props.filteredUuids && this.props.filteredUuids.length > 0) {
      return this.props.filteredUuids.includes(uuid)
    }
    return Array.isArray(this.options.filteredRowDataUuids)
      ? this.options.filteredRowDataUuids.includes(uuid)
      : true
  }

  render() {
    if (
      !this.state ||
      (this.fn?.cockpit === Cockpit.Project && !this.props.projectUuid)
    ) {
      return <></>
    }
    return (
      <RootContainer onKeyDown={this.onKeyDown}>
        <div
          className={AG_GRID_THEME}
          style={{
            flexGrow: 1,
            width: '100%',
            height: this.options.paddingHeightForDisplayFooter
              ? 'calc(100% - 56px)'
              : '100%',
            padding: '0 8px 8px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!this.props.hideHeader && <HeaderBar depth={0} />}
          {!this.props.hideToolbar && (
            <BulkSheetToolBar
              onSubmit={
                this.state.hasUpdatePermission &&
                this.state.editable &&
                this.options.onSubmit
                  ? this.onSubmit
                  : undefined
              }
              onCancel={
                this.state.hasUpdatePermission &&
                this.state.editable &&
                this.options.onSubmit
                  ? this.onCancel
                  : undefined
              }
              resetColumnStateHandler={this.resetColumnAndFilterState}
              resetSpecificColumnFilter={this.resetSpecificColumnFilter}
              exportHandler={
                this.options.enableExcelExport
                  ? this.openExcelOutputColumnSelectDialog
                  : undefined
              }
              importHandler={
                this.state.hasUpdatePermission &&
                this.state.editable &&
                this.options.enableExcelImport
                  ? this.importExcel
                  : undefined
              }
              expandGroupRowHandler={
                this.treeProperty && this.options.enableExpandAllRow
                  ? this.expandGroupRow
                  : undefined
              }
              inProgress={this.state.inProgress}
              submitButtonDisabled={
                this.state.submitType === SubmitType.Disable ||
                this.state.submitDisabled
              }
              cancelButtonDisabled={this.state.submitDisabled}
              settingHandler={
                this.options.onClickSetting ? this.onClickSetting : undefined
              }
              filterIconHandler={
                this.options.getSearchCondition &&
                this.options.restoreSearchCondition &&
                !this.options.hiddenSearchFilterIcon
                  ? () =>
                      this.openSavedBulkSheetUIStateDialog(
                        UiStateKey.BulkSheetUIStateSearchCondition
                      )
                  : undefined
              }
              sortIconHandler={() =>
                this.openSavedBulkSheetUIStateDialog(
                  UiStateKey.BulkSheetUIStateColumnAndFilter
                )
              }
              filteredColumns={
                !this.isServerSideRowModel
                  ? this.state.filteredColumns
                  : undefined
              }
              sortedColumns={
                !this.isServerSideRowModel
                  ? this.state.sortedColumns
                  : undefined
              }
              onChangeHeight={
                !this.isServerSideRowModel ? this.onChangeHeight : undefined
              }
              onChangeHeightCommitted={() =>
                this.gridApi!.refreshCells({ force: true })
              }
              defaultHeight={this.state.rowHeight}
              minHeight={this.minRowHeight}
              selectedFunction={this.props.externalId}
              defaultToolBarItemKey={this.options.defaultToolBarItemKey}
              hideDivider={this.options.hideToolbarDivider}
            >
              {this.options.toolBarItems && this.options.toolBarItems(this)}
            </BulkSheetToolBar>
          )}
          {this.options.subToolBarItems &&
            this.options.subToolBarItems.length > 0 && (
              <BulkSheetToolBar inProgress={this.state.inProgress}>
                {this.options.subToolBarItems(this)}
              </BulkSheetToolBar>
            )}
          <AgGridReact
            ref={this.agGridRef}
            rowBuffer={this.state.rowBuffer ? this.state.rowBuffer : 50}
            debounceVerticalScrollbar={true} // Improve vertical scroll performance.
            gridOptions={this.state.gridOptions}
            getRowHeight={this.getRowHeight}
            rowData={this.isServerSideRowModel ? undefined : this.state.rowData}
            animateRows={true}
            treeData={!!this.treeProperty}
            excludeChildrenWhenTreeDataFiltering={true}
            enterMovesDownAfterEdit={true}
            onGridReady={this.onGridReady}
            onFirstDataRendered={this.onFirstDataRendered}
            processCellForClipboard={processCellForClipboard}
            processDataFromClipboard={processDataFromClipboard}
            onCellValueChanged={this.onCellValueChanged}
            onPasteStart={this.onPasteStart}
            onPasteEnd={this.onPasteEnd}
            allowContextMenuWithControlKey={true}
            enableRangeSelection={true}
            undoRedoCellEditing={true}
            getContextMenuItems={this.getContextMenuItems}
            getRowId={this.getRowId}
            getDataPath={this.getDataPath}
            rowSelection="multiple"
            rowDragMultiRow={true}
            onRowDragEnter={this.onRowDragEnter}
            onRowDragMove={this.onRowDragMove}
            onRowDragEnd={this.onRowDragEnd}
            onRowGroupOpened={this.onRowGroupOpened}
            onRowDragLeave={this.onRowDragLeave}
            onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
            onColumnResized={this.onColumnResized}
            onCellFocused={this.saveFocusedRowUuid}
            onFilterChanged={this.onFilterChanged}
            onSortChanged={this.onSortChanged}
            onRowDataUpdated={this.onRowDataUpdated}
            onColumnVisible={this.onColumnVisible}
            suppressNoRowsOverlay={true}
            pinnedTopRowData={this.options.pinnedTopRowData}
            suppressCopyRowsToClipboard={true}
            copyHeadersToClipboard={this.state.copyHeadersToClipboard}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            excelStyles={excelStyles}
          />
          {this.state.children}
        </div>
        <TaskActualWorkDialog
          open={this.state.taskActualWorkDialog.open}
          taskUuid={this.state.taskActualWorkDialog.taskUuid}
          closeHandler={this.closeTaskActualWorkDialog}
        />
        <AttachmentListDialog {...this.state.attachmentListDialog} />
        <StatusChangePopper
          anchorEl={this.state.statusPopperAnchorEl}
          projectUuid={this.state.statusPopperProjectUuid}
          wbsItem={this.state.statusPopperWbsItem}
          wbsItemDelta={this.state.statusPopperWbsItemDelta}
          onAfterUpdate={() =>
            this.refreshAfterUpdateSingleRow(this.state.statusPopperUuid!)
          }
          onClose={() => {
            this.setState({
              statusPopperAnchorEl: undefined,
              statusPopperUuid: undefined,
              statusPopperWbsItem: undefined,
              statusPopperWbsItemDelta: undefined,
            })
          }}
        />
        <AlertDialog {...this.state.alertDialogState} />
        <Loading isLoading={this.state.isLoading} elem={this.agGridElem} />
        <AddRowCountInputDialog {...this.state.addRowCountInputDialogState} />
        <SavedUIStateDialog
          applicationFunctionUuid={this.props.uuid}
          open={this.state.savedBulkSheetUIStateDialogState.open}
          title={this.state.savedBulkSheetUIStateDialogState.title}
          uiStateKey={this.state.savedBulkSheetUIStateDialogState.uiStateKey}
          sharable={this.state.savedBulkSheetUIStateDialogState.sharable}
          currentUIState={
            this.state.savedBulkSheetUIStateDialogState.currentUIState
          }
          currentSavedUIStateUuid={
            this.state.savedBulkSheetUIStateDialogState.currentSavedUIStateUuid
          }
          onSelect={this.state.savedBulkSheetUIStateDialogState.onSelect}
          onClose={this.state.savedBulkSheetUIStateDialogState.onClose}
          editorOptionUIStateListProps={
            this.state.savedBulkSheetUIStateDialogState
              .editorOptionUIStateListProps
          }
        />
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState, bulkSheetProps): StateProps => {
  const commentProps: CommentProps | undefined = state.information?.commentProps
  return {
    functions: state.appFunction.functions,
    projectUuid: bulkSheetProps.projectUuid || state.project.selected,
    user: state.user.user,
    functionLayers: state.functionLayer.layers,
    edited: !!state.hasRequiredSaveData.hasRequiredSaveData,
    comments: commentProps
      ? state.comments.comments.get(
          `${commentProps.applicationFunctionUuid}-${commentProps.dataUuid}`,
          List<Comment>()
        )
      : undefined,
    informationIsOpen: state.information?.open || false,
  }
}

export default connect<StateProps, {}, OwnProps<any, any, any, any>, AllState>(
  mapStateToProps
)(injectIntl(withKeyBind(BulkSheet)))
