import { useEffect, useRef } from 'react'

const useWindowUnloadEffect = (handler: () => void, callOnCleanup: boolean) => {
  const cb = useRef<() => void>()

  cb.current = handler

  useEffect(() => {
    const handler = () => cb.current && cb.current()

    window.addEventListener('beforeunload', handler)

    return () => {
      if (callOnCleanup) handler()

      window.removeEventListener('beforeunload', handler)
    }
  }, [callOnCleanup])
}
export default useWindowUnloadEffect
