import React from 'react'
import Box from '@mui/material/Box'
import RegisterActualHourButton from '../../components/buttons/RegisterActualHour'
import TaskActualWorkRegistrationDialog from './TaskActualWorkRegistrationDialog'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { ToolbarDivider } from '../../components/toolbars/ContainerToolBar'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

interface Props extends WrappedComponentProps {
  taskUuid: string
  inProgress: boolean
  externalId: string
  onRefresh?: Function
  wbsItemType?: WbsItemTypeVO
}

export interface State {
  open: boolean
}

const TaskActualWorkRegistrationButton = (props: Props) => {
  const [openDialog, showDialog] = React.useState(false)
  if (!props.wbsItemType?.isTask() || !props.taskUuid) {
    return <></>
  }
  return (
    <>
      <div>
        <Box pr="8px">
          <RegisterActualHourButton onClick={() => showDialog(true)} />
        </Box>
        <TaskActualWorkRegistrationDialog
          open={openDialog}
          closeHandler={() => showDialog(false)}
          onSave={props.onRefresh}
          taskUuid={props.taskUuid}
          externalId={props.externalId}
        />
      </div>
      <ToolbarDivider key="task-actual-work-registration-button-divider" />
    </>
  )
}

export default injectIntl(TaskActualWorkRegistrationButton)
