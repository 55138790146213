import React from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Checkbox,
  InputBase,
  MenuItem,
} from '@mui/material'
import { styled } from '@mui/system'
import { ValueFormatterParams } from 'ag-grid-community'
import {
  CustomEnumValue,
  FunctionProperty,
} from '../../../../../../../../lib/commons/appFunction'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})
const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& .MuiCheckbox-root': { marginRight: '0px !important' },
  '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
})

interface Props {
  value: CustomEnumValue[]
  uiMeta: FunctionProperty
}

interface State {
  value?: CustomEnumValue[]
  open: boolean
}

export const multiSelectCellValueFormatter = (params: ValueFormatterParams) => {
  if (!params.value || !Array.isArray(params.value)) {
    return ''
  }
  return params.value.map(v => v.name).join(', ')
}

class cellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  constructor(props: Props) {
    super(props)
    this.state = {
      open: false,
      value: props.value,
    }
  }

  public getValue = () => {
    return this.state.value
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
    }
  }

  private valueChanged = (
    event: React.ChangeEvent<{}>,
    value: CustomEnumValue[] | null,
    reason: AutocompleteChangeReason
  ) => {
    this.setState({
      value: value || [],
      open: false,
    })
  }

  render() {
    const { uiMeta } = this.props
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    return (
      <RootDiv>
        <Autocomplete
          multiple={true}
          options={uiMeta.valuesAllowed}
          getOptionLabel={option => (option.name ? option.name : '')}
          renderOption={(props, option, { selected }) => {
            return (
              <StyledMenuItem {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color={'primary'}
                />
                <span>{option.name}</span>
              </StyledMenuItem>
            )
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <InputBase
              inputRef={this.ref}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus={true}
            />
          )}
          isOptionEqualToValue={(value, option) => {
            return value.value === option.value
          }}
          filterOptions={(options: CustomEnumValue[]) => {
            return options.filter(option => {
              return option.value !== 'NONE'
            })
          }}
          autoSelect={false}
          blurOnSelect={false}
          value={this.state.value}
          onChange={this.valueChanged}
          disableCloseOnSelect={true}
        />
      </RootDiv>
    )
  }
}

export default cellEditor
