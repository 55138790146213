import React from 'react'
import { ToolbarGroup } from '../index'
import ProjectPlanAggregateInformation, {
  Props as AggregateInformationProps,
} from '../../../../containers/AggregateInformation/ProjectPlanAggregateInformation'

export type ReportToolbarProps = AggregateInformationProps

export const ReportToolbar = (props: ReportToolbarProps) => {
  return (
    <ToolbarGroup>
      <ProjectPlanAggregateInformation
        aggregateType={props.aggregateType}
        onChangeAggregateType={props.onChangeAggregateType}
        wbsItemType={props.wbsItemType}
        onChangeWbsItemType={props.onChangeWbsItemType}
        workloadUnit={props.workloadUnit}
        onChangeWorkloadUnit={props.onChangeWorkloadUnit}
        rootNode={props.rootNode}
      />
    </ToolbarGroup>
  )
}
