import React, { useCallback, useState } from 'react'
import DatePicker from 'react-datepicker'
import { InputBase, Portal } from '@mui/material'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import CalendarIcon from '../../icons/CalendarIcon'

type DateTimeInputProps = {
  value: DateTimeVO | undefined
  onChange: (value: DateTimeVO | undefined) => void
  placeholder?: string
  onEnter?: () => void
  disabled?: boolean
  clearable?: boolean
}

const DateTimeInput = ({
  value,
  onChange,
  placeholder,
  onEnter,
  disabled,
  clearable,
}: DateTimeInputProps) => {
  const datePickerRef = React.createRef<any>()

  const [editing, setEditing] = useState<boolean>(false)

  const openCalendar = useCallback(() => {
    setEditing(true)
  }, [])

  const closeCalendar = useCallback(() => {
    setEditing(false)
  }, [])

  return editing ? (
    <DatePicker
      showTimeInput={true}
      ref={datePickerRef}
      autoFocus={true}
      selected={value && value.toPlainValue()}
      dateFormat={'yyyy/MM/dd HH:mm'}
      onChange={date => {
        onChange(date ? new DateTimeVO(date) : undefined)
      }}
      placeholderText={placeholder}
      customInput={
        <InputBase endAdornment={<CalendarIcon onClick={openCalendar} />} />
      }
      popperPlacement="bottom-end"
      onKeyPress={event => {
        if (onEnter && event.key === 'Enter') {
          onEnter()
        }
      }}
      isClearable={clearable}
      disabled={disabled}
      onClose={closeCalendar}
      popperContainer={props => {
        const { children } = props
        return <Portal container={null}>{children}</Portal>
      }}
    />
  ) : (
    <InputBase
      placeholder={placeholder}
      onFocus={openCalendar}
      value={value ? value.format() : ''}
      endAdornment={<CalendarIcon onClick={openCalendar} />}
    />
  )
}

export default DateTimeInput
