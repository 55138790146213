import API, { APIResponse } from '../commons/api'
import EntitySearch from '../commons/entitySearch'
import { FunctionProperty } from '../commons/appFunction'
import { UserBasic } from './user'
import { Tree } from '../commons/tree'

export enum ScopeType {
  FUNCTION = 'FUNCTION',
  PROPERTY = 'PROPERTY',
  DATA = 'DATA',
}

export enum Operation {
  ALL = 'ALL',
  GET = 'GET',
  LIST = 'LIST',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface ApplicationScope {
  scopeType: ScopeType
  operation: Operation
  function?: string // Not null when scopeType is 'FUNCTION'
  dataType?: string // Not null when scopeType is 'DATA'
  permissionOther: boolean // Enable when scopeType is 'DATA'
}

export interface RoleBasic {
  uuid: string
  code: string
  name: string
}

export interface RoleDetail extends Tree<RoleDetail> {
  code: string
  type: string
  name?: string
  description?: string
  scopeType?: string
  scopeTarget?: string
  operation?: string
  functionName?: string
  dataType?: string
  permissionOther?: boolean
}

export interface RoleGetRolesResponse {
  total: number
  hit: number
  data: RoleBasic[]
}

class Role extends EntitySearch {
  public me = (): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/roles/me')
  }

  entitySearchApi = (
    props: RoleGetRolesResponse,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/roles/list',
      props,
      true,
      signal
    )
  }

  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        all: query,
      }),
      (user: UserBasic) => ({
        ...user,
      })
    )
  }

  public searchAll = async () => {
    return this.searchInternal('', (query: string) => ({}), this.toResponse)
  }

  public getAll = async () => {
    return this.searchRequest.switch(signal =>
      API.functional.request('GET', '/api/v1/roles/list', {}, true, signal)
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const role = response.find(v => v.uuid === uuid)
    return { status: 200, json: this.toResponse(role) }
  }
}

export default new Role()
