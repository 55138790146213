import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { Box, ClickAwayListener, Fade, Paper, Popper } from '@mui/material'
import { ICellEditorParams } from 'ag-grid-community'
import _ from 'lodash'
import CancelButton from '../../../../components/buttons/CancelButton'
import { SketchPicker, CirclePicker } from 'react-color'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import { intl } from '../../../../../i18n'
import { CloseIcon } from '../../../../components/icons/CloseIcon'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '../../../../components/icons/IconButton'

export const ColorChartCellEditor = forwardRef(
  (props: ICellEditorParams, ref) => {
    const [value, setValue] = useState<string>(props.value)
    const [internalValue, setInternalValue] = useState<string>(props.value)
    const [open, setOpen] = useState<boolean>(true)
    const [otherColor, setOtherColor] = useState<boolean>(false)
    const defaultColors = [
      // layer 1
      // '#E4F5FD',
      // '#E3F3FF',
      // '#FCE2EC',
      // '#E1F3ED',
      // '#FFE5BB',
      // '#F7F8FA',
      // layer 2
      '#B3E6FC',
      '#BBE0FF',
      '#F8B6D0',
      '#B7E2D1',
      '#FDC7A0',
      '#D8DDE5',
      // layer 3
      '#82D7FC',
      '#8DCDFF',
      '#F486B0',
      '#8AD0B3',
      '#E2A77F',
      '#9FACC3',
      // layer 4
      '#50C6FA',
      '#5BB9FF',
      '#F05291',
      '#5EBC97',
      '#C4885B',
      '#7B8CAA',
      // layer 5
      '#29BAF9',
      '#2FA9FF',
      '#ED1E79',
      '#40AD82',
      '#AD6F40',
      '#5F7399',
      // layer 6
      '#01ADF8',
      '#009AFF',
      '#EA005F',
      '#2D9E70',
      '#965725',
      '#425B8A',
      // layer 7
      '#00A0E9',
      '#008BFD',
      '#DA005C',
      '#289064',
      '#8A4D1F',
      '#3C5482',
      // layer 8
      '#008CD5',
      '#0079E9',
      '#C40058',
      '#228056',
      '#7A4016',
      '#334A76',
      // later 9
      '#007BC1',
      '#0767D7',
      '#AF0054',
      '#197049',
      '#6C3211',
      '#2D4069',
      // layer 10
      '#005B9F',
      '#0D47B8',
      '#89004E',
      '#105430',
      '#5C2307',
      '#243050',
    ]

    const onChangeComplete = useCallback((color, event) => {
      setInternalValue(color.hex)
    }, [])

    const onSubmit = useCallback(() => {
      setOpen(false)
      setValue(internalValue)
    }, [internalValue])

    const onCancel = useCallback(() => {
      setOpen(false)
      props.api.stopEditing(true)
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
      }
    })

    const isEdited = useMemo(
      () => props.value !== internalValue,
      [props.value, internalValue]
    )

    useEffect(() => {
      if (props.value !== value) {
        props.api.stopEditing()
      }
    }, [value])

    return (
      <ClickAwayListener onClickAway={onCancel}>
        <Popper
          open={open}
          anchorEl={props.eGridCell}
          placement={'bottom-start'}
          disablePortal={true}
          style={{
            width: 280,
            padding: '5px 10px',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 0px 6px #0000004d',
            borderRadius: '5px',
            zIndex: 1300,
            margin: '5px 10px !important',
          }}
        >
          <Fade in={true} timeout={100}>
            <Paper style={{ boxShadow: 'none' }}>
              {otherColor && (
                <>
                  <Box
                    style={{
                      alignContent: 'left',
                    }}
                  >
                    <IconButton
                      onClick={() => setOtherColor(false)}
                      size={'small'}
                      title={intl.formatMessage({
                        id: 'tags.maintenance.return.colorPalette',
                      })}
                    >
                      <ArrowBackIcon
                        style={{ width: '12px', height: '12px' }}
                        color="action"
                      />
                    </IconButton>
                  </Box>
                  <SketchPicker
                    color={internalValue}
                    onChangeComplete={onChangeComplete}
                    disableAlpha={true}
                    width={240}
                  />
                </>
              )}
              {!otherColor && (
                <>
                  <CirclePicker
                    color={internalValue}
                    onChangeComplete={onChangeComplete}
                    width={280}
                    colors={defaultColors}
                    circleSize={32}
                    circleSpacing={14}
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      padding: '5px 0',
                      margin: '5px 0 0 0',
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        width: '100%',
                        height: '25px',
                        padding: '0px 10px',
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: '0px 0px 6px #0000004d',
                        borderRadius: '5px',
                      }}
                      onClick={() => setOtherColor(true)}
                    >
                      {intl.formatMessage({
                        id: 'tags.maintenance.otherColor',
                      })}
                    </div>
                  </Box>
                </>
              )}
              <Box style={{ textAlign: 'center', padding: '5px 0' }}>
                <SubmitButton
                  title={intl.formatMessage({ id: 'dialog.ok' })}
                  onClick={onSubmit}
                  hideIcon={true}
                  disabled={!isEdited}
                  notEdited={!isEdited}
                  hideTooltip={true}
                />
                <CancelButton onClick={onCancel} />
              </Box>
            </Paper>
          </Fade>
        </Popper>
      </ClickAwayListener>
    )
  }
)
