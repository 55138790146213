import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import idProviderApi, {
  ExternalServiceCode,
  IdProviderBatchInput,
  IdProviderDetail,
  IdProviderInput,
} from '../../../lib/functions/idProvider'
import { generateUuid } from '../../../utils/uuids'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import ViewMeta from '../../containers/meta/ViewMeta'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ContextMenu, {
  ContextMenuGroup,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { GetContextMenuItemsParams } from 'ag-grid-community'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class IdProviderRow extends RowData {
  code?: string
  name?: string
  externalServiceCode?: ExternalServiceCode
  clientId?: string
  clientSecret?: string
  issuer?: string
}

interface IdProviderBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    IdProviderDetail,
    IdProviderRow,
    BulkSheetState
  > {}

class IdProviderRowDataSpec extends RowDataSpec<
  IdProviderDetail,
  IdProviderRow
> {
  createNewRow(): IdProviderRow {
    return new IdProviderRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: IdProviderRow
    parent: IdProviderRow
  }): IdProviderRow {
    return params.child
  }
  createRowByResponse(response: IdProviderDetail): IdProviderRow {
    return {
      ...response,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
}

export default class IdProvidersOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  IdProviderDetail,
  IdProviderRow,
  BulkSheetState
> {
  addable = true
  columnAndFilterStateKey = UiStateKey.IdProvidersColumnAndFilterState
  rowDataSpec = new IdProviderRowDataSpec()
  lockedColumns = ['idProvider.code']
  pinnedColumns = ['idProvider.code', 'idProvider.name']
  enableExcelExport = true
  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return idProviderApi.getAll()
  }

  onSubmit = async (
    ctx: IdProviderBulkSheetContext,
    data: {
      added: IdProviderRow[]
      edited: {
        before: IdProviderRow
        after: IdProviderRow
      }[]
      deleted: IdProviderRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: IdProviderBatchInput = {
      added: data.added.map(this.createRequestByRow),
      edited: data.edited.map(v => this.createRequestByRow(v.after)),
      deleted: data.deleted.map(this.createDeleteRequestByRow),
    }
    return idProviderApi.updateBatch(input)
  }

  private createRequestByRow = (row: IdProviderRow): IdProviderInput => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      code: row.code,
      name: row.name!,
      externalServiceCode: row.externalServiceCode!,
      clientId: row.clientId!,
      clientSecret: row.clientSecret,
      issuer: row.issuer!,
      secretEdited: !!row.clientSecret && row.clientSecret.length > 0,
    }
  }

  private createDeleteRequestByRow = (row: IdProviderRow) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: IdProviderBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
}
