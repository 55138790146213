import { TicketEntity, ticketService, UpdateTicketDeltaInput } from '.'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import { UpdateTagInput } from '../WbsItemEntity'

export type RiskEntity = {
  uuid: string
  ticket: TicketEntity

  riskType?: string
  probability?: string
  impact?: string
  strategy?: string
  score?: number
}

type RiskType = 'OMISSION'
type Probability =
  | 'EXTREMELY_HIGH'
  | 'HIGH'
  | 'MIDDLE'
  | 'LOW'
  | 'EXTREMELY_LOW'

type Impact = 'EXTREMELY_HIGH' | 'HIGH' | 'MIDDLE' | 'LOW' | 'EXTREMELY_LOW'

type Strategy = 'AVOIDANCE' | 'TRANSFER' | 'REDUCTION' | 'RETENTION' | 'SHARING'

export type UpdateRiskDeltaRequest = {
  input: UpdateRiskDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}
export type UpdateRiskDeltaInput = {
  uuid: string
  ticket: UpdateTicketDeltaInput

  riskType?: IItemDelta<string>
  probability?: IItemDelta<string>
  impact?: IItemDelta<string>
  strategy?: IItemDelta<string>
}

export type UpdateRiskDeltaResult = {}

const fromApiResponseToEntity = (src: any): RiskEntity => {
  return {
    ...src,
    uuid: src.uuid,
    ticket: ticketService.fromApiResponseToEntity(src.ticket),
  }
}

export const riskService = {
  fromApiResponseToEntity,
}
