import { IconButton, styled } from '@mui/material'
import { Select } from '../../../../components/select'
import { useCallback, useEffect, useState } from 'react'
import { SelectOption } from '../../../../components/select/model'
import { useVersionReportVersionRepository } from '../../../../../services/adaptors/versionReportVersionRepositoryAdaptor'
import { colorPalette } from '../../../../style/colorPallete'
import { Button } from '../../../../components/buttons'
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import {
  VersionReportVersionEntity,
  VersionReportVersionPageEntity,
} from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { UseCurrentVersionReturnValue } from '../../hooks/currentReport'
import { NewVersionDialog } from '../dialog/NewVersionDialog'
import { useVersionReportTemplateRepository } from '../../../../../services/adaptors/versionReportTemplateRepositoryAdaptor'
import AlertDialog from '../../../../components/dialogs/AlertDialog'
import { VersionInfo } from './VersionInfo'
import { VersionReportMenu } from './ReportMenu'

type VersionReportHeaderProps = {
  projectUuid: string
  editing: boolean
  startEditing: () => void
  finishEditing: () => void
  evaluate?: () => void
} & Omit<UseCurrentVersionReturnValue, 'updatePageTitle' | 'updateLeadSentence'>
export const VersionReportHeader = ({
  projectUuid,
  versionUuid,
  updateVersionUuid,
  currentVersion,
  currentPage,
  toPrevPage,
  toNextPage,
  editing,
  startEditing,
  finishEditing,
  evaluate,
}: VersionReportHeaderProps) => {
  const [templateUuid, setTemplateUuid] = useState<string | undefined>()
  const { fetchOne } = useVersionReportTemplateRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await fetchOne(projectUuid)
      setTemplateUuid(response.uuid)
    }
    fn()
  }, [fetchOne, projectUuid])
  const [versions, setVersions] = useState<SelectOption<string>[]>([])
  const { fetchVersions, deleteVersion } = useVersionReportVersionRepository()
  useEffect(() => {
    const fn = async () => {
      if (!templateUuid) return
      const response = await fetchVersions(templateUuid)
      setVersions(
        response.map(v => ({
          value: v.uuid,
          name: v.name,
        }))
      )
    }
    fn()
  }, [fetchVersions, templateUuid, updateVersionUuid])
  const onAfterCreate = useCallback((uuid: string, name: string) => {
    setVersions(prev => [
      ...prev,
      {
        value: uuid,
        name,
      },
    ])
  }, [])
  const deleteCurrentVersion = useCallback(async () => {
    if (!currentVersion) return
    await deleteVersion(currentVersion.uuid)
    setVersions(prev => prev.filter(v => v.value !== currentVersion.uuid))
  }, [currentVersion, deleteVersion])
  useEffect(() => {
    if (versions.length === 0) {
      updateVersionUuid(undefined)
      return
    }
    const lastVersion = versions[versions.length - 1]
    updateVersionUuid(lastVersion.value)
  }, [updateVersionUuid, versions])
  const [open, setOpen] = useState<boolean>(false)
  const openDialog = useCallback(() => setOpen(true), [])
  const closeDialog = useCallback(() => setOpen(false), [])
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const openDeleteDialog = useCallback(() => setDeleteDialogOpen(true), [])
  const closeDeleteDialog = useCallback(() => setDeleteDialogOpen(false), [])
  const onDelete = useCallback(async () => {
    await deleteCurrentVersion()
    closeDeleteDialog()
  }, [closeDeleteDialog, deleteCurrentVersion])
  return (
    <>
      <HeaderRoot>
        <LeftSideArea>
          <SelectRoot>
            <Select
              value={versionUuid}
              onChange={updateVersionUuid}
              options={versions}
              disabled={editing}
            />
          </SelectRoot>
          <Button onClick={openDialog} disabled={editing}>
            新しく報告書を作成
          </Button>
          <PagingComponent
            current={currentPage?.pageNumber || 0}
            total={currentVersion?.pages.length || 0}
            toPrev={toPrevPage}
            toNext={toNextPage}
          />
        </LeftSideArea>
        {currentVersion && (
          <RightSideArea>
            <VersionInfo version={currentVersion} />
            <VersionReportMenu onDelete={openDeleteDialog} />
            {!editing && (
              <Button onClick={startEditing}>
                <EditIcon />
                編集する
              </Button>
            )}
            {editing && (
              <>
                {evaluate && (
                  <Button onClick={evaluate} colorPattern="skyBlue">
                    定性情報を自動生成する
                  </Button>
                )}
                <Button onClick={finishEditing} colorPattern="green">
                  <DoneIcon />
                  編集を完了する
                </Button>
              </>
            )}
          </RightSideArea>
        )}
      </HeaderRoot>
      <NewVersionDialog
        open={open}
        templateUuid={templateUuid}
        onAfterSubmit={onAfterCreate}
        onClose={closeDialog}
      />
      <AlertDialog
        isOpen={deleteDialogOpen}
        title="報告書を削除する"
        message="削除した報告書は復元できません。削除してよろしいですか？"
        submitHandler={onDelete}
        closeHandler={closeDeleteDialog}
      />
    </>
  )
}

const HeaderRoot = styled('div')({
  display: 'flex',
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  padding: '12px',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const SelectRoot = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  width: '172px',
  height: '26px',
  padding: '0 4px',
})
const LeftSideArea = styled('div')({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
})
const RightSideArea = styled('div')({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
})
const SquareIconButton = styled(IconButton)({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  padding: '4px',
})
const EditIcon = styled(EditRoundedIcon)({
  color: colorPalette.monotone[0],
  fontSize: '16px',
})
const DeleteIcon = styled(HighlightOffRoundedIcon)({
  color: colorPalette.monotone[0],
  fontSize: '16px',
})
const DoneIcon = styled(DoneRoundedIcon)({
  color: colorPalette.monotone[0],
  fontSize: '16px',
})

type PagingComponentProps = {
  current: number
  total: number
  toPrev: () => void
  toNext: () => void
}
const PagingComponent = ({
  current,
  total,
  toPrev,
  toNext,
}: PagingComponentProps) => {
  return (
    <PagingComponentRoot>
      <StyledIconButton onClick={toPrev}>
        <PrevIcon />
      </StyledIconButton>
      <PageText>
        {current}/{total}
      </PageText>
      <StyledIconButton onClick={toNext}>
        <NextIcon />
      </StyledIconButton>
    </PagingComponentRoot>
  )
}

const PagingComponentRoot = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
})

const PrevIcon = styled(NavigateBeforeRoundedIcon)({
  color: colorPalette.monotone[4],
  height: '16px',
})
const NextIcon = styled(NavigateNextRoundedIcon)({
  color: colorPalette.monotone[4],
  height: '16px',
})
const StyledIconButton = styled(IconButton)({
  padding: '2px',
})
const PageText = styled('span')({
  color: colorPalette.monotone[9],
  fontSize: '12px',
})
