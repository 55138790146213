import _ from 'lodash'
import { useCallback } from 'react'
import { Box, styled, TableRow } from '@mui/material'
import { SxProps } from '@mui/system'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import { WbsItemTypeForWbsProgressLog } from '../../../../../lib/functions/wbsProgressLog'
import { ValueFormatter } from '../../../../components/charts/Chart/TwoDimChart'
import { AccumulateDirection } from '../..'
import {
  AggregateColumnGroup,
  excludeWbsItemCodes,
  getAggregateColumnGroupShortLabel,
} from '../../utils'
import {
  ProgressReportScheduleAndActualValues,
  ProgressReportScheduleAndActualValueMaps,
  TooltipTableBodyCell,
  TooltipTableLink,
} from '.'

interface LabelProps {
  color: string
  type: WbsItemTypeForWbsProgressLog
  aggregateColumnGroup: AggregateColumnGroup
}

interface OwnProps {
  currValues: ProgressReportScheduleAndActualValues
  prevValues?: ProgressReportScheduleAndActualValues
  currValueMaps?: ProgressReportScheduleAndActualValueMaps
  prevValueMaps?: ProgressReportScheduleAndActualValueMaps
  formatter: ValueFormatter
  direction: AccumulateDirection
  label?: LabelProps
  showActualOnly?: boolean
  sx?: SxProps
}

type Props = OwnProps

const CellValue = styled((props: { children: React.ReactNode }) => (
  <Box {...props} component="span" />
))({
  paddingLeft: '5px',
  fontWeight: FontWeight.BOLD,
  fontSize: 24,
})

const PrevDiffCellValue = styled(CellValue)({
  fontSize: FontSize.MEDIUM,
})

const DiffCellValue = styled(CellValue)({
  fontSize: 20,
})

const TableBodyCell = styled(TooltipTableBodyCell)({
  padding: '0px 10px',
  verticalAlign: 'bottom',
})

const CurrTableBodyCell = styled(TableBodyCell)({
  paddingRight: '0px',
})

const PrevDiffTableBodyCell = styled(TableBodyCell)({
  paddingLeft: '0px',
  paddingBottom: '4px',
})

const NotApplicableCell = styled(CellValue)({
  fontSize: 20,
  fontWeight: FontWeight.NORMAL,
  textAlign: 'center',
})

const valueMapToCodes = (
  valueMap: { [code: string]: number } | undefined
): string[] => {
  return valueMap ? Object.keys(valueMap) : []
}

export const getSumValue = (
  targetCodes: string[],
  valueMap: { [code: string]: number } | undefined
): number => {
  if (_.isEmpty(targetCodes) || !valueMap) {
    return 0
  }
  return targetCodes.reduce((total: number, code: string) => {
    if (code in valueMap) {
      total += valueMap[code]
    }
    return total
  }, 0)
}

const ScheduledAndActualTooltipRow = ({
  currValues,
  prevValues,
  currValueMaps,
  prevValueMaps,
  formatter,
  direction,
  label,
  showActualOnly,
  sx,
}: Props) => {
  const currScheduledCodes = valueMapToCodes(currValueMaps?.scheduled)
  const currActualCodes = valueMapToCodes(currValueMaps?.actual)
  const prevScheduledCodes = valueMapToCodes(prevValueMaps?.scheduled)
  const prevActualCodes = valueMapToCodes(prevValueMaps?.actual)
  const diffScheduledCodes =
    currScheduledCodes.length < prevScheduledCodes.length
      ? excludeWbsItemCodes(prevScheduledCodes, currScheduledCodes)
      : excludeWbsItemCodes(currScheduledCodes, prevScheduledCodes)
  const diffActualCodes =
    currActualCodes.length < prevActualCodes.length
      ? excludeWbsItemCodes(prevActualCodes, currActualCodes)
      : excludeWbsItemCodes(currActualCodes, prevActualCodes)

  const getPrecedingCodesValue = useCallback((): [string[], number] => {
    const [minuend, subtrahend, valueMap] =
      direction === AccumulateDirection.BURN_UP
        ? [currActualCodes, currScheduledCodes, currValueMaps?.actual]
        : [currScheduledCodes, currActualCodes, currValueMaps?.scheduled]
    const codes = minuend.filter(c => !subtrahend.includes(c))
    return [codes, getSumValue(codes, valueMap)]
  }, [
    direction,
    currScheduledCodes,
    currActualCodes,
    currValueMaps?.scheduled,
    currValueMaps?.actual,
  ])

  const getDelayedCodesValue = useCallback((): [string[], number] => {
    const [minuend, subtrahend, valueMap] =
      direction === AccumulateDirection.BURN_UP
        ? [currScheduledCodes, currActualCodes, currValueMaps?.scheduled]
        : [currActualCodes, currScheduledCodes, currValueMaps?.actual]
    const codes = minuend.filter(c => !subtrahend.includes(c))
    return [codes, getSumValue(codes, valueMap)]
  }, [
    direction,
    currScheduledCodes,
    currActualCodes,
    currValueMaps?.scheduled,
    currValueMaps?.actual,
  ])
  const [precedingCodes, precedingValue] = getPrecedingCodesValue()
  const [delayedCodes, delayedValue] = getDelayedCodesValue()

  return (
    <TableRow sx={sx}>
      {label && (
        <TooltipTableBodyCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Box
              sx={{
                height: '10px',
                width: '10px',
                margin: '0 5px',
                backgroundColor: label.color,
              }}
            />
            <Box component="span" sx={{ fontSize: FontSize.SMALL }}>
              {`${label.type.name} - ${getAggregateColumnGroupShortLabel(
                label.aggregateColumnGroup
              )}`}
            </Box>
          </Box>
        </TooltipTableBodyCell>
      )}
      {!showActualOnly && (
        <>
          <CurrTableBodyCell>
            <CellValue>
              <TooltipTableLink
                value={currValues.scheduled}
                wbsItemCodes={currScheduledCodes}
                formatter={formatter}
              />
            </CellValue>
          </CurrTableBodyCell>
          <PrevDiffTableBodyCell>
            <PrevDiffCellValue>
              {`(`}
              <TooltipTableLink
                value={currValues.scheduled - (prevValues?.scheduled || 0)}
                wbsItemCodes={diffScheduledCodes}
                formatter={formatter}
              />
              {`)`}
            </PrevDiffCellValue>
          </PrevDiffTableBodyCell>
          <CurrTableBodyCell>
            <CellValue>
              <TooltipTableLink
                value={currValues.actual}
                wbsItemCodes={currActualCodes}
                formatter={formatter}
              />
            </CellValue>
          </CurrTableBodyCell>
          <PrevDiffTableBodyCell>
            <PrevDiffCellValue>
              {`(`}
              <TooltipTableLink
                value={currValues.actual - (prevValues?.actual || 0)}
                wbsItemCodes={diffActualCodes}
                formatter={formatter}
              />
              {`)`}
            </PrevDiffCellValue>
          </PrevDiffTableBodyCell>
          <TableBodyCell>
            <DiffCellValue>
              <TooltipTableLink
                value={precedingValue}
                wbsItemCodes={precedingCodes}
                formatter={formatter}
              />
            </DiffCellValue>
          </TableBodyCell>
          <TableBodyCell>
            <DiffCellValue>
              <TooltipTableLink
                value={delayedValue}
                wbsItemCodes={delayedCodes}
                formatter={formatter}
              />
            </DiffCellValue>
          </TableBodyCell>
        </>
      )}
      {showActualOnly && (
        <>
          <CurrTableBodyCell>
            <CellValue>
              <TooltipTableLink
                value={currValues.actual}
                wbsItemCodes={currActualCodes}
                formatter={formatter}
              />
            </CellValue>
          </CurrTableBodyCell>
          <PrevDiffTableBodyCell>
            <PrevDiffCellValue>
              {`(`}
              <TooltipTableLink
                value={currValues.actual - (prevValues?.actual || 0)}
                wbsItemCodes={diffActualCodes}
                formatter={formatter}
              />
              {`)`}
            </PrevDiffCellValue>
          </PrevDiffTableBodyCell>
        </>
      )}
    </TableRow>
  )
}

export default ScheduledAndActualTooltipRow
