import React from 'react'
import { styled } from '@mui/system'
import { RowNode } from 'ag-grid-community'
import { Comment } from '../../../../../../../../store/comments'
import CommentMessage from '../../../../../../Comment/CommentMessage'
import CommentHeader from '../../../../../../Comment/CommentHeader'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  alignItems: 'center',
  '& img': {
    maxWidth: '30%',
  },
  overflow: 'scroll',
  paddingLeft: 6,
})

interface Props {
  value: Comment
  node: RowNode
}

class CommentCell extends React.PureComponent<Props> {
  render() {
    const { value } = this.props

    if (!value) {
      return <></>
    }
    return (
      <RootDiv className="flagxs-markup-root">
        <CommentHeader comment={value} />
        <CommentMessage commentText={value.text} />
      </RootDiv>
    )
  }
}

export default CommentCell
