import { createContext } from 'react'
import { CardInputError, SelectOption } from './Kanban'

export type CardContextValue = {
  projectMembers: SelectOption[]
  projectMembersForFilter: SelectOption[]
  teams: SelectOption[]
  priorities: SelectOption[]
  prioritiesForFilter: SelectOption[]
  errors: CardInputError
}

const KanbanContext = createContext({
  projectMembers: [],
  projectMembersForFilter: [],
  teams: [],
  priorities: [],
  prioritiesForFilter: [],
  errors: new CardInputError(),
} as CardContextValue)

export default KanbanContext
