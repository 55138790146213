import { useCallback, useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popper,
  Typography,
} from '@mui/material'
import { Cockpit, Directory } from '../../../../lib/commons/appFunction'
import DateRangeIcon from '@mui/icons-material/DateRangeRounded'
import ScheduleIcon from '@mui/icons-material/ScheduleRounded'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import AccountCircleIcon from '@mui/icons-material/AccountCircleRounded'
import DescriptionIcon from '@mui/icons-material/DescriptionRounded'
import AssignmentIcon from '@mui/icons-material/AssignmentRounded'
import LanIcon from '@mui/icons-material/LanRounded'
import BusinessIcon from '@mui/icons-material/BusinessRounded'
import FolderCopyIcon from '@mui/icons-material/FolderCopyRounded'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import components, { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../pages'
import {
  BackgroundColor,
  Color,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import { open } from '../../../router'
import { ProjectDetail } from '../../../../lib/functions/project'
import { fromCamelToSnake } from '../../../../utils/string'
import { isProduction } from '../../../../utils/urls'
import {
  convertTicketExternalIdToTicketType,
  isUsingTicketComponent,
} from '../../../../lib/functions/ticket'

type IconProps = {
  directory: Directory
}

const DirectoryIcon = ({ directory }: IconProps) => {
  const iconStyles = { color: Color.MONOTONE, fontSize: 20 }
  switch (directory.externalId) {
    case 'project.planning':
      return <DateRangeIcon sx={iconStyles} />
    case 'project.workPlan':
      return <ScheduleIcon sx={iconStyles} />
    case 'project.management':
      return <SettingsIcon sx={iconStyles} />
    case 'project.members':
      return <AccountCircleIcon sx={iconStyles} />
    case 'project.tickets':
      return <DescriptionIcon sx={iconStyles} />
    case 'project.reports':
      return <AssignmentIcon sx={iconStyles} />
    case 'project.cost.management':
      return <MonetizationOnIcon sx={iconStyles} />
    case 'tenant.management':
      return <BusinessIcon sx={iconStyles} />
    case 'tenant.projects':
      return <FolderCopyIcon sx={iconStyles} />
    case 'tenant.organizations':
      return <LanIcon sx={iconStyles} />
    case 'tenant.cost.management':
      return <MonetizationOnIcon sx={iconStyles} />
    default:
      return <></>
  }
}

type MenuProps = {
  directory: Directory
  project?: ProjectDetail
}

const DirectoryIconMenu = ({ directory, project }: MenuProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const delaySetOpenMenu = _.debounce(setOpenMenu, 100)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const nestedFunctions = directory.directories.map(d => d.functions).flat()
  const displayFunctions = [
    ...(directory.functions || []),
    ...nestedFunctions,
  ].filter(v => !!v && !v.hidden)
  const onClick = useCallback(
    (event, externalId: string) => {
      const component = components[externalId]
      if (!component) return
      event.preventDefault()

      // If the component is a TICKETS, add a ticket type query parameter to the URL
      const ticketTypeQueryParam = isUsingTicketComponent(component)
        ? convertTicketExternalIdToTicketType(externalId)
        : ''

      const pathname =
        component.cockpit === Cockpit.Project && project
          ? `${component.defaultPath}/${project.code}${ticketTypeQueryParam}`
          : component.defaultPath
      open(pathname, event)
    },
    [project]
  )
  return (
    <Box
      onMouseEnter={() => delaySetOpenMenu(true)}
      onMouseLeave={() => delaySetOpenMenu(false)}
    >
      <IconButton
        sx={{
          padding: '8px, 0px, 8px, 0px',
          margin: '1px 0',
          width: '24px',
          height: '24px',
          justify: 'space-between',
        }}
        aria-haspopup="true"
        ref={anchorRef}
      >
        <DirectoryIcon directory={directory} />
      </IconButton>
      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        placement={'right-start'}
        modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]}
        sx={{
          padding: '5px',
          backgroundColor: BackgroundColor.WHITE,
          boxShadow:
            'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
          borderRadius: '4px',
          zIndex: 1300,
        }}
      >
        <List
          sx={{
            padding: '24px 16px 24px 16px',
            borderRadius: '4px',
          }}
        >
          <>
            <Box
              sx={{
                width: '250px',
                display: 'flex',
                marginBottom: '5px',
                padding: '0 3px 8px 3px',
              }}
            >
              <DirectoryIcon directory={directory} />
              <Typography
                variant="subtitle1"
                sx={{
                  color: TextColor.BLACK,
                  fontWeight: FontWeight.BOLD,
                  margin: '0 3px',
                }}
              >
                {directory.name}
              </Typography>
            </Box>
            {displayFunctions.map((p, i) => (
              <ListItem
                sx={{
                  color: Color.MONOTONE,
                  '&:hover': {
                    color: TextColor.FOCUS_BULE,
                    backgroundColor: BackgroundColor.FOCUS_BULE,
                  },
                  padding: '6px 8px 6px 8px',
                  borderRadius: '4px',
                  gap: '4px',
                }}
                key={`menu-${p.externalId.replaceAll('.', '-')}-${i}`}
              >
                <ListItemButton
                  sx={{
                    padding: 0,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={event => onClick(event, p.externalId)}
                  disabled={!components[p.externalId]}
                >
                  <ListItemText primary={p.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </>
        </List>
      </Popper>
    </Box>
  )
}

type Props = { currentProject?: ProjectDetail; directories: Directory[] }

const DirectoryMenu = ({ currentProject, directories }: Props) => {
  const [displayDirectories, setDisplayDirectories] = useState<Directory[]>([])
  useEffect(() => {
    const currentCockpit = currentProject ? Cockpit.Project : Cockpit.Tenant
    const directory = directories.find(v => v.name === currentCockpit)
    if (!directory) {
      return
    }
    setDisplayDirectories(directory.directories)
  }, [currentProject, directories])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {displayDirectories.map((p, i) => (
        <DirectoryIconMenu
          key={`minimized-menu-${i}`}
          directory={p}
          project={currentProject}
        />
      ))}
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  currentProject: state.project.current,
  directories: state.appFunction.directories,
})

export default connect(mapStateToProps)(DirectoryMenu)
