import moment from 'moment'
import Box from '@mui/material/Box'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  RestoreSearchConditionOptions,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import ViewMeta from '../../containers/meta/ViewMeta'
import {
  ColDef,
  GetContextMenuItemsParams,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { DateTerm } from '../../../utils/date'
import { roundNumber } from '../../../utils/number'
import { FunctionProperty } from '../../../lib/commons/appFunction'
import * as OrganizationWorkingDayCalendarAPI from '../../../lib/functions/organizationWorkingDayCalendar'
import {
  ALERT_COLOR,
  DayOfWeek,
  getTimeUnitByGrain,
  getTimeUnitSizeByGrain,
  mapDayOfWeekToNumber,
  TimeGrain,
} from '../../../lib/functions/report'
import ContextMenu, {
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { getCustomEnumName } from '../../../lib/functions/customEnumValue'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { getWbsItemStatusDeepColorCode } from '../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { CSSProperties, MouseEvent } from 'react'
import { needToOpenInNewTab, open } from '../../router'
import { intl } from '../../../i18n'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import {
  getSprintReport,
  getSprintReportKeyLabel,
  SprintReport,
  SprintReportGroupKey,
  SprintReportKey,
  SprintReportMemberAlias,
  SprintReportSummarizeValue,
  SprintReportValue,
  summarize,
} from '../../../lib/functions/sprintReport'
import store from '../../../store'
import SprintReportSearchConditionToolBar, {
  getDefaultDateTerm,
} from './SearchConditionToolBar'
import { ProjectMemberProps } from '../../../lib/functions/projectMember'
import {
  getUrlQueryStringParams,
  isValid as isValidUrl,
} from '../../../utils/urls'
import objects from '../../../utils/objects'
import { ViewConfigurationToolBar } from './ViewConfigurationToolBar'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import { fromSnakeToCamel } from '../../../utils/string'
import { openProgressChart } from '../ProgressChart'
import { SearchConditionProgress } from '../../containers/ReportContainer/ReportComponent/SearchCondition/WbsProgressLog'
import DateVO from '../../../vo/DateVO'
import {
  AggregateFieldToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../components/toggleGroups'
import uiStates, {
  RequestOfGetStates,
  RequestOfUpdateStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { AggregateField } from '../../../domain/entity/WbsItemEntity'
import formatter from '../../containers/meta/formatter'
import { getWorkloadUnitStateForSprintReport } from '../../hooks/useWorkloadUnit'
import { CustomComponent } from '../../../store/functionLayer'
import SprintReportBreadcrumb from './SprintReportBreadcrumb'
import { BreadCrumb } from '../../../store/breadcrumb'
import TotalCellRenderer from './Components/TotalCellRenderer'
import { DefaultCellRenderer } from '../../containers/BulkSheetView/components/cellRenderer'

const TOTAL_ESTIMATED_HOUR_CELL_COLOR = '#F2F2F2'
const TOTAL_COLUMN_KEY = 'TOTAL'
const NO_VALUE_STR = '-'
const DISPLAY_FORMAT_WORKLOAD = '0,0.00'
const DISPLAY_FORMAT_COUNT = '0,0'

interface SprintReportPageState {
  aggregateField: AggregateField
  workloadUnit: WorkloadUnit
}

export interface SprintReportState extends BulkSheetState {
  searchCondition: SprintReportSearchCondition
  pageState: SprintReportPageState
}

interface SprintReportSearchCondition {
  rootWbsItemUuid?: string
  teamUuid?: string
  groupKey: SprintReportGroupKey
  dateTerm: {
    startDate?: number
    endDate?: number
  }
  timeGrain: TimeGrain
  startDayOfWeek: DayOfWeek
}

const getDefaultCondition = () => ({
  rootWbsItemUuid: '',
  teamUuid: '',
  timeGrain: TimeGrain.WEEK,
  startDayOfWeek: DayOfWeek.MONDAY,
  groupKey: SprintReportGroupKey.SCHEDULED_END_DATE,
  dateTerm: getDefaultDateTerm(),
})

class SprintReportRow extends RowData {
  member: ProjectMemberProps
  key: string = ''
  values: { [key: string]: SprintReportValue } = {}
}

interface SprintReportBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    SprintReport,
    SprintReportRow,
    SprintReportState
  > {}

class SprintReportRowDataSpec extends RowDataSpec<
  SprintReport,
  SprintReportRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      nameColumn: {
        filter: 'agSetColumnFilter',
        cellStyle: params => {
          let isBeforeSameMember = false
          let beforeRow: RowNode | undefined = undefined
          params.api.forEachNodeAfterFilterAndSort((node, index) => {
            if (node.id === params.node.id) {
              isBeforeSameMember = !!(
                beforeRow &&
                beforeRow.data.member?.uuid === params.data.member?.uuid
              )
            }
            beforeRow = node
          })
          if (isBeforeSameMember) {
            return { color: 'transparent' }
          } else {
            return { color: 'inherit' }
          }
        },
      },
      keyColumn: {
        filter: 'agSetColumnFilter',
        cellRenderer: (params: ICellRendererParams) => {
          if (!Object.values(WbsItemStatus).includes(params.data.key)) {
            return <>{params.value}</>
          }
          return (
            <div style={{ padding: 0 }} className="sevend-ag-cell">
              <div
                style={{
                  backgroundColor: getWbsItemStatusDeepColorCode(
                    params.data.key
                  ),
                  height: '10px',
                  width: '10px',
                  margin: '0 5px 0 0',
                  border:
                    params.data.key === WbsItemStatus.TODO
                      ? '1px solid #DDDDDD'
                      : undefined,
                }}
              />
              <div>{params.value}</div>
            </div>
          )
        },
      },
    }
  }

  createNewRow(): SprintReportRow {
    return new SprintReportRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: SprintReportRow
    parent: SprintReportRow
  }): SprintReportRow {
    return params.child
  }

  createRowByResponse(response: SprintReport): SprintReportRow {
    return {
      uuid: generateUuid(),
      member: response.member,
      key: response.key,
      values: response.values,
    }
  }
}

export default class SprintReportOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  SprintReport,
  SprintReportRow,
  SprintReportState
> {
  fetchDataOnInit = false
  addable = false
  draggable = false
  enableExcelExport = true
  paddingHeightForDisplayFooter = true
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: SprintReportBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data || !params.column) return
    const field = params.column.getColDef().field!
    let dateTerm: DateTerm = {}
    if (field === TOTAL_COLUMN_KEY) {
      dateTerm = {
        startDate: moment(ctx.state.searchCondition.dateTerm.startDate).format(
          'YYYY-MM-DD'
        ),
        // 1 day is added because it is aggregate in the right half-open interval
        endDate: moment(ctx.state.searchCondition.dateTerm.endDate)
          .add(1, 'd')
          .format('YYYY-MM-DD'),
      }
    } else {
      dateTerm = this.toDateTerm(
        params.column.getColDef().field!,
        ctx.state.searchCondition.timeGrain
      )
    }
    const sum = this.summarizeValues(params.node.data.values, dateTerm)

    return new ContextMenu([
      {
        id: ContextMenuGroupId.CUSTOM,
        items: [
          {
            name: intl.formatMessage({ id: 'openProgressReportFlag' }),
            disabled: sum.wbsItemCodes.filter(c => !!c).length === 0,
            action: () => {
              const time = {
                from: moment(dateTerm.startDate).valueOf(),
                // 1 day is subtract because progress chart screen is a closed interval
                to: moment(dateTerm.endDate).subtract(1, 'd').valueOf(),
              }
              openProgressChart(
                SearchConditionProgress.with({
                  time: {
                    from: new DateVO(time.from),
                    to: new DateVO(time.to),
                  },
                  wbsItemCodes: (sum.wbsItemCodes.filter(c => !!c) || []).join(
                    ','
                  ),
                })
              )
            },
            icon: getMenuIconHtml(ContextMenuItemId.DISPLAY_GRAPH),
            tooltip:
              sum.wbsItemCodes.filter(c => !!c).length === 0
                ? intl.formatMessage({
                    id: 'bulksheet.contextMenu.disabled.display.graph.sprint.report',
                  })
                : undefined,
          },
        ],
      },
    ])
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.SprintReportColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx => `${ctx.state.uuid}`
  rowDataSpec = new SprintReportRowDataSpec()
  updateDefaultState = async (
    s: SprintReportState,
    applicationFunctionUuid: string
  ) => {
    let urlParams = getUrlQueryStringParams()
    let pageState: SprintReportPageState | undefined = undefined
    const pageStateProp = await this.getPageState(applicationFunctionUuid)
    if (pageStateProp && pageStateProp.value) {
      pageState = JSON.parse(pageStateProp.value)
    }

    const workloadUnit = pageState?.workloadUnit
      ? pageState.workloadUnit
      : WorkloadUnit.HOUR
    const newState: SprintReportState = {
      ...s,
      searchCondition: getDefaultCondition(),
      pageState: {
        aggregateField: pageState?.aggregateField
          ? pageState?.aggregateField
          : AggregateField.WBS_ITEM_WORKLOAD,
        workloadUnit,
      },
    }
    const workloadUnitState = getWorkloadUnitStateForSprintReport(
      workloadUnit,
      store.getState().tenant.organization!
    )
    s.gridOptions.context = {
      ...s.gridOptions.context,
      workloadUnit,
      workloadUnitState,
    }
    if (urlParams) {
      if (urlParams.rootWbsItem) {
        newState.searchCondition.rootWbsItemUuid = urlParams.rootWbsItem
      }
    }
    return newState
  }
  customColumnTypes = {
    'project.sprintReport.member.name': ['nameColumn'],
    'project.sprintReport.key': ['keyColumn'],
  }
  pinnedColumns = [
    'project.sprintReport.member.name',
    'project.sprintReport.key',
  ]
  dynamicColumns = {
    'project.sprintReport.date': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: SprintReportBulkSheetContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        let startDate = ctx.state.searchCondition.dateTerm.startDate
        let endDate = ctx.state.searchCondition.dateTerm.endDate
        if (!startDate || !endDate) {
          return []
        }
        const timeGrain = ctx.state.searchCondition.timeGrain || TimeGrain.DAY
        const startDayOfWeek =
          ctx.state.searchCondition.startDayOfWeek || DayOfWeek.MONDAY
        const dateTermList: DateTerm[] = []
        let from = startDate
        let to = endDate
        if (timeGrain === TimeGrain.WEEK || timeGrain === TimeGrain.TWO_WEEKS) {
          const dayOfWeek = moment(startDate).day()
          const startDayOfWeekNum = mapDayOfWeekToNumber(startDayOfWeek)
          const fromDiff = (dayOfWeek - startDayOfWeekNum + 7) % 7
          const toDiff = (startDayOfWeekNum - dayOfWeek + 7) % 7
          from = moment(startDate).subtract(fromDiff, 'd').valueOf()
          to = moment(endDate).add(toDiff, 'd').valueOf()
        }
        if (timeGrain === TimeGrain.MONTH) {
          from = moment(startDate).startOf('M').valueOf()
          to = moment(endDate).startOf('M').valueOf()
        }
        let momentFrom = moment(from)
        const momentTo = moment(to)
        const momentUnit = getTimeUnitByGrain(timeGrain)
        const unitSize = getTimeUnitSizeByGrain(timeGrain)
        const timeDiff = Math.ceil(
          momentTo.diff(momentFrom, momentUnit) / unitSize
        )
        for (let i = 0; i <= timeDiff; i++) {
          const sDate = moment(momentFrom).add(unitSize * i, momentUnit)
          dateTermList.push({
            // dateTerm range is [startDate, endDate), which means startDate is included, while endDate excluded.
            startDate: sDate.format('YYYY-MM-DD'),
            endDate: sDate.add(unitSize, momentUnit).format('YYYY-MM-DD'),
          })
        }

        let workingDayCalendars: OrganizationWorkingDayCalendarAPI.OrganizationWorkingDayCalendarDetail[] =
          []
        if (dateTermList.length > 0) {
          const getWorkingDayCalendarResponse =
            await OrganizationWorkingDayCalendarAPI.getOrganizationWorkingDayCalendar(
              {
                startDate: new Date(dateTermList[0].startDate!),
                endDate: new Date(
                  dateTermList[dateTermList.length - 1].endDate!
                ),
              }
            )
          workingDayCalendars =
            getWorkingDayCalendarResponse.json as OrganizationWorkingDayCalendarAPI.OrganizationWorkingDayCalendarDetail[]
        }

        // Because remove from organization working day API request.
        const createColDef = (dateTerm: DateTerm, isTotal: boolean = false) => {
          let businessDays = 0
          let workHour = 0
          // Calculate business days
          const from = moment(dateTerm.startDate).toDate()
          const to = moment(dateTerm.endDate).toDate()
          businessDays = this.getBusinessDays(from, to, workingDayCalendars)
          workHour = this.getWorkHour(from, to, workingDayCalendars)
          const isCurrentTerm =
            moment().isBetween(
              moment(dateTerm.startDate),
              moment(dateTerm.endDate)
            ) && !isTotal
          const headerClass = isCurrentTerm
            ? 'sevend-ag-grid-date-header-current'
            : ''
          return {
            ...baseColumnDef,
            colId: generateUuid(),
            headerName: isTotal
              ? ctx.props.intl.formatMessage({ id: 'total' })
              : this.getHeaderName(from, timeGrain),
            headerClass: headerClass,
            lockPosition: true,
            children: [
              {
                ...baseColumnDef,
                colId: isTotal ? TOTAL_COLUMN_KEY : dateTerm.startDate,
                headerName: this.getSubHeaderName(
                  businessDays,
                  workHour,
                  timeGrain
                ),
                headerClass: headerClass,
                field: isTotal ? TOTAL_COLUMN_KEY : dateTerm.startDate || '',
                valueGetter: (params: ValueGetterParams) => {
                  return this.summarizeValueGetter(
                    params,
                    dateTerm,
                    ctx.state.pageState.aggregateField
                  )
                },
                valueFormatter: (params: ValueFormatterParams) => {
                  return this.summarizeValueFormatter(
                    params,
                    ctx.state.pageState.aggregateField
                  )
                },
                onCellClicked: event =>
                  event.node.data.key !==
                    SprintReportKey.TOTAL_ESTIMATED_HOUR &&
                  this.openWorkResult(event.node.data.values, dateTerm),
                cellStyle: params => {
                  let cellStyle: any = {}

                  const sum = this.summarizeValues(
                    params.data.values,
                    dateTerm,
                    ctx.state.pageState.aggregateField
                  )
                  const isRatingCell = this.isRatingCell(params.data)

                  if (
                    !isRatingCell &&
                    sum.wbsItemCodes &&
                    sum.wbsItemCodes.length > 0 &&
                    params.data.key !== SprintReportKey.TOTAL_ESTIMATED_HOUR
                  ) {
                    cellStyle = {
                      justifyContent: 'flex-end',
                      cursor: 'pointer',
                      color: 'blue',
                    }
                  } else {
                    cellStyle = {
                      justifyContent: 'flex-end',
                    }
                  }
                  if (isRatingCell) {
                    const color = this.getCellColor(sum)
                    if (color) {
                      cellStyle.backgroundColor = color
                    }
                  }

                  return cellStyle
                },
                cellRenderer: params => {
                  const sum = this.summarizeValues(
                    params.data.values,
                    dateTerm,
                    ctx.state.pageState.aggregateField
                  )
                  const disabled =
                    !sum.wbsItemCodes || sum.wbsItemCodes.length === 0
                  if (
                    ctx.state.searchCondition.timeGrain &&
                    ctx.state.searchCondition.timeGrain !== TimeGrain.DAY &&
                    params.data.key === SprintReportKey.TOTAL_ESTIMATED_HOUR
                  ) {
                    return <TotalCellRenderer {...params} disabled={disabled} />
                  }
                  return <DefaultCellRenderer {...params} />
                },
                cellRendererParams: {
                  ...baseColumnDef.cellRendererParams,
                  dateTerm: dateTerm,
                  rootUuid: ctx.state.searchCondition.rootWbsItemUuid,
                  teamUuid: ctx.state.searchCondition.teamUuid,
                  searchConditionDateTerm: ctx.state.searchCondition.dateTerm,
                  onClickValue: this.openWorkResult,
                },
              },
            ],
          }
        }
        const columns = dateTermList.map((dateTerm, index) => {
          return createColDef(dateTerm)
        })
        const totalColDef = createColDef(
          {
            startDate: dateTermList[0].startDate,
            endDate: dateTermList[dateTermList.length - 1].endDate,
          },
          true
        )
        columns.unshift({
          ...totalColDef,
          headerName: 'Total',
          lockPosition: true,
          children: [
            {
              ...totalColDef.children[0],
              headerName: `${
                workingDayCalendars.length
              }d / ${workingDayCalendars
                .map(v => v.workHour)
                .reduce((sum, val) => sum + val, 0)}h`,
              pinned: true,
            },
          ],
        })
        return columns
      },
    },
  }

  getHeaderName = (date: Date, timeGrain: TimeGrain) => {
    switch (timeGrain) {
      case TimeGrain.DAY:
        return moment(date).format('M/D (ddd)')
      case TimeGrain.WEEK:
        return `${moment(date).format('M/D')}${intl.formatMessage({
          id: 'wbsProgressLog.week',
        })}`
      case TimeGrain.TWO_WEEKS:
        return `${moment(date).format('M/D')}-${moment(date)
          .add(2, 'w')
          .subtract(1, 'd')
          .format('M/D')}`
      case TimeGrain.MONTH:
        return `${moment(date).format('M')}${intl.formatMessage({
          id: 'wbsProgressLog.month',
        })}`
      default:
        return ''
    }
  }

  getSubHeaderName = (
    businessDays: number,
    workHour: number,
    timeGrain: TimeGrain
  ) => {
    switch (timeGrain) {
      case TimeGrain.WEEK:
      case TimeGrain.TWO_WEEKS:
      case TimeGrain.MONTH:
        return businessDays ? `${businessDays}d / ${workHour}h` : '-'
      case TimeGrain.DAY:
        return businessDays ? `${workHour}h` : '-'
    }
  }

  toDateTerm = (startDate: string, timeGrain: TimeGrain): DateTerm => {
    const from = moment(startDate)
    const to = moment(from).add(
      getTimeUnitSizeByGrain(timeGrain),
      getTimeUnitByGrain(timeGrain)
    )
    return {
      startDate: from.format('YYYY-MM-DD'),
      endDate: to.format('YYYY-MM-DD'),
    }
  }

  summarizeValues = (
    values: { [key: string]: SprintReportValue },
    dateTerm: DateTerm,
    aggregateField?: AggregateField
  ): SprintReportSummarizeValue => {
    return summarize(
      Object.entries(values)
        .filter(
          v =>
            moment(v[0]).isSameOrAfter(dateTerm.startDate, 'd') &&
            moment(v[0]).isBefore(dateTerm.endDate, 'd')
        )
        .map(v => v[1]),
      aggregateField ? aggregateField : AggregateField.WBS_ITEM_WORKLOAD
    )
  }

  isRatingCell = data => {
    return [
      SprintReportKey.RESOURCE_USED_RATE,
      SprintReportKey.VELOCITY,
      SprintReportKey.THROUGHPUT,
    ].includes(data.key)
  }

  isWorkloadOnlyCell = data => {
    return [
      SprintReportKey.TOTAL_ACTUAL_HOUR,
      SprintReportKey.RESOURCE_USED_RATE,
      SprintReportKey.VELOCITY,
    ].includes(data.key)
  }

  getRowStyle = (params): CSSProperties | undefined => {
    if (params.data.key === SprintReportKey.TOTAL_ESTIMATED_HOUR) {
      return {
        backgroundColor: TOTAL_ESTIMATED_HOUR_CELL_COLOR,
      }
    }
  }

  private openWorkResult = (
    values: { [key: string]: SprintReportValue },
    dateTerm: DateTerm
  ) => {
    const sum = this.summarizeValues(values, dateTerm)
    const wbsItemCodes = sum.wbsItemCodes || []
    if (wbsItemCodes.length > 0) {
      const wbsItemCodeStr = wbsItemCodes.filter(code => !!code).join(',')
      if (!wbsItemCodeStr) {
        return
      }
      const projectCode = window.location.pathname.split('/')[2]
      const url: string = `/actualWork/${projectCode}?status=all&code=${wbsItemCodeStr}`
      if (!isValidUrl(url)) {
        store.dispatch(
          addScreenMessage(store.getState().appFunction.currentExternalId!, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'sprintreport.warning.invalid.url.length',
            }),
          })
        )
        return
      }
      open(url, undefined, undefined, true)
    }
  }

  private getBusinessDays = (
    from: Date,
    to: Date,
    workingDayCalendars: OrganizationWorkingDayCalendarAPI.OrganizationWorkingDayCalendarDetail[]
  ): number => {
    let businessDays = 0
    const diff = moment(to).diff(from, 'd')
    for (let i = 0; i < diff; i++) {
      const workingDay = workingDayCalendars.find(cal =>
        moment(cal.date).isSame(moment(from).add(i, 'd'), 'd')
      )
      if (workingDay?.workHour) {
        businessDays++
      }
    }
    return businessDays
  }

  private getWorkHour = (
    from: Date,
    to: Date,
    workingDayCalendars: OrganizationWorkingDayCalendarAPI.OrganizationWorkingDayCalendarDetail[]
  ): number => {
    let workHour = 0
    const diff = moment(to).diff(from, 'd')
    for (let i = 0; i < diff; i++) {
      const workingDay = workingDayCalendars.find(cal =>
        moment(cal.date).isSame(moment(from).add(i, 'd'), 'd')
      )
      workHour = workHour + (workingDay?.workHour || 0)
    }
    return workHour
  }

  private getCellColor = (
    value?: SprintReportSummarizeValue
  ): string | undefined => {
    if (!value) {
      return undefined
    }
    if (!value.denominator) {
      return undefined
    }
    const rate = value.numerator / value.denominator
    if (rate >= 1.5) {
      return ALERT_COLOR.HIGH
    } else if (1.25 <= rate && rate < 1.5) {
      return ALERT_COLOR.MIDDLE_HIGH
    } else if (0 < rate && rate <= 0.5) {
      return ALERT_COLOR.LOW
    } else if (0.5 < rate && rate <= 0.75) {
      return ALERT_COLOR.MIDDLE_LOW
    }
  }

  getValueGetter = (
    field: string,
    prop?: FunctionProperty
  ): AgGridValueGetter | undefined => {
    if (field === 'member.name') {
      return (params: ValueGetterParams) => {
        const alias = SprintReportMemberAlias[params.data.member.name]
        if (alias) {
          return intl.formatMessage({
            id: `sprintReport.alias.${fromSnakeToCamel(alias)}`,
          })
        }
        if (params.data.member) {
          return params.data.member.name
        }
        return ''
      }
    }
    if (field === 'key') {
      return (params: ValueGetterParams) => {
        const customEnumName = getCustomEnumName(
          params.data.key,
          prop?.valuesAllowed || []
        )
        return customEnumName || getSprintReportKeyLabel(params.data.key)
      }
    }
    return undefined
  }

  private summarizeValueGetter = (
    params: ValueGetterParams,
    dateTerm: DateTerm,
    aggregateField: AggregateField
  ) => {
    const sum = this.summarizeValues(
      params.data.values,
      dateTerm,
      aggregateField
    )
    if (!this.isRatingCell(params.data)) {
      if (aggregateField === AggregateField.WBS_ITEM_WORKLOAD) {
        return roundNumber(
          sum.numerator /
            (params.context.workloadUnitState?.hoursPerSelectedUnit || 1),
          2
        )
      } else {
        if (this.isWorkloadOnlyCell(params.data)) {
          return NO_VALUE_STR
        }
        return sum.numerator
      }
    } else {
      if (!sum.denominator) {
        return NO_VALUE_STR
      }
      return roundNumber(sum.numerator / sum.denominator, 2)
    }
  }

  private summarizeValueFormatter = (
    params: ValueFormatterParams,
    aggregateField: AggregateField
  ) => {
    if (
      aggregateField === AggregateField.WBS_ITEM_WORKLOAD ||
      this.isRatingCell(params.data)
    ) {
      return formatter.format(params.value, {
        ...params.colDef.cellRendererParams.uiMeta,
        displayFormat: DISPLAY_FORMAT_WORKLOAD,
      })
    } else {
      return formatter.format(params.value, {
        ...params.colDef.cellRendererParams.uiMeta,
        displayFormat: DISPLAY_FORMAT_COUNT,
      })
    }
  }

  async getAll(state: SprintReportState): Promise<APIResponse> {
    if (
      !state.searchCondition.dateTerm.startDate ||
      !state.searchCondition.dateTerm.endDate
    ) {
      return Object.assign({}, successDummyResponse, { json: [] })
    }
    return getSprintReport({
      projectUuid: store.getState().project.selected!,
      rootWbsItemUuid: state.searchCondition.rootWbsItemUuid,
      teamUuid: state.searchCondition.teamUuid,
      groupKey: state.searchCondition.groupKey,
      startDate:
        state.searchCondition.dateTerm &&
        state.searchCondition.dateTerm.startDate
          ? moment(state.searchCondition.dateTerm.startDate).format(
              'YYYY-MM-DD'
            )
          : undefined,
      endDate:
        state.searchCondition.dateTerm && state.searchCondition.dateTerm.endDate
          ? moment(state.searchCondition.dateTerm.endDate).format('YYYY-MM-DD')
          : undefined,
    })
  }
  onSearch(
    ctx: SprintReportBulkSheetContext,
    searchCondition: SprintReportSearchCondition
  ) {
    ctx.setState(
      {
        searchCondition: { ...ctx.state.searchCondition, ...searchCondition },
      },
      () => {
        this.switchRoowWbsItem(ctx)
      }
    )
  }

  private onChangeAggregateField = (
    value: AggregateField,
    ctx: SprintReportBulkSheetContext
  ) => {
    const newPageState = {
      ...ctx.state.pageState,
      aggregateField: value,
    }
    ctx.setState({ pageState: newPageState }, () => {
      ctx.gridApi?.refreshCells()
    })
    this.updatePageUiState(newPageState, ctx.props.uuid)
  }

  private onChangeWorkloadUnit = (
    value: WorkloadUnit,
    ctx: SprintReportBulkSheetContext
  ) => {
    const newPageState = {
      ...ctx.state.pageState,
      workloadUnit: value,
    }
    ctx.setState({ pageState: newPageState })
    this.updatePageUiState(newPageState, ctx.props.uuid)
    this.refreshCellsChangeWorkloadUnit(value, ctx)
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  private updatePageUiState = async (value, uuid) => {
    const requests: RequestOfUpdateStates = {
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
      value: JSON.stringify(value),
    }
    uiStates.update(requests, uuid)
  }

  private refreshCellsChangeWorkloadUnit = (workloadUnit, ctx) => {
    const workloadUnitState = getWorkloadUnitStateForSprintReport(
      workloadUnit,
      store.getState().tenant.organization!
    )
    ctx.setContext({ workloadUnit, workloadUnitState })
    ctx.gridApi?.refreshCells()
  }

  getSearchCondition = (state: SprintReportState) => {
    return {
      projectUuid: state.uuid,
      ...state.searchCondition,
    }
  }

  restoreSearchCondition = (
    searchCondition: any,
    ctx: SprintReportBulkSheetContext,
    options?: RestoreSearchConditionOptions
  ) => {
    let restoredCondition = {
      ...getDefaultCondition(),
      ...searchCondition,
    }
    if (searchCondition.projectUuid === ctx.state.uuid) {
      const urlRootWbsItem = getUrlQueryStringParams()?.rootWbsItem
      if (urlRootWbsItem) {
        restoredCondition = {
          ...restoredCondition,
          rootWbsItemUuid: urlRootWbsItem,
          teamUuid: undefined,
        }
      }
      if (options?.isRestoredSavedSearchConditionByUser) {
        // Restored by user on select saved search condition.
        restoredCondition = { ...restoredCondition, ...searchCondition }
      }
    }
    this.onSearch(ctx, restoredCondition)
  }

  refreshConditionAndColumn = (ctx: SprintReportBulkSheetContext) => {
    this.onSearch(ctx, getDefaultCondition())
  }

  onFilterChanged = (ctx: SprintReportBulkSheetContext) => {
    this.refreshNameCell(ctx)
  }

  onSortChanged = (ctx: SprintReportBulkSheetContext) => {
    this.refreshNameCell(ctx)
  }

  refreshNameCell = (ctx: SprintReportBulkSheetContext) => {
    if (ctx.gridApi) {
      ctx.gridApi.refreshCells({
        columns: ['member.name'],
        force: true,
      })
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (field === 'date') {
      return 90
    } else if (['key', 'member.name'].includes(field)) {
      return 120
    }
    return undefined
  }

  toolBarItems = (ctx: SprintReportBulkSheetContext) => [
    <SprintReportSearchConditionToolBar
      key="1"
      projectUuid={ctx.state.uuid}
      rootWbsItemUuid={ctx.state.searchCondition.rootWbsItemUuid}
      teamUuid={ctx.state.searchCondition.teamUuid}
      groupKey={ctx.state.searchCondition.groupKey}
      dateTerm={ctx.state.searchCondition.dateTerm}
      search={searchCondition => this.onSearch(ctx, searchCondition as any)}
      isLoading={ctx.state.isLoading}
    />,
    <Box
      key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}
      sx={{ display: 'flex' }}
    >
      <AggregateFieldToggleGroup
        value={ctx.state.pageState.aggregateField}
        onChange={value => this.onChangeAggregateField(value, ctx)}
      />
      <WorkloadUnitToggleGroup
        refresh={value => this.onChangeWorkloadUnit(value, ctx)}
        value={ctx.state.pageState.workloadUnit}
        disabled={
          ctx.state.pageState.aggregateField === AggregateField.WBS_ITEM_COUNT
        }
      />
      <ViewConfigurationToolBar
        timeGrain={ctx.state.searchCondition.timeGrain}
        startDayOfWeek={ctx.state.searchCondition.startDayOfWeek}
        onChange={(key: string, value: string) => {
          const newCondition = {
            ...ctx.state.searchCondition,
          }
          objects.setValue(newCondition, key, value)
          ctx.setState(
            {
              searchCondition: newCondition,
            },
            () => {
              ctx.rememberCurrentSearchCondition()
              ctx.refreshDynamicColumns(true)
            }
          )
        }}
      />
    </Box>,
  ]

  private switchRoowWbsItem = (
    ctx: SprintReportBulkSheetContext,
    openInNewTab?: boolean
  ) => {
    const projectCode = store.getState().project.current?.code
    const wbsItemUuid = ctx.state.searchCondition.rootWbsItemUuid
    open(
      wbsItemUuid
        ? `/sprintReport/${projectCode}?rootWbsItem=${wbsItemUuid}`
        : `/sprintReport/${projectCode}`,
      undefined,
      undefined,
      openInNewTab
    )
    if (!openInNewTab) {
      ctx.setHeaderComponents()
      ctx.refreshDataWithLoading()
    }
  }

  headerComponents = (ctx: SprintReportBulkSheetContext) => {
    const rootWbsItemUuid = ctx.state.searchCondition.rootWbsItemUuid
    return {
      customHeaderComponents: [
        {
          key: '1',
          layout: {
            position: 'end',
            index: 0,
          },
          component: rootWbsItemUuid ? (
            <SprintReportBreadcrumb
              rootWbsItemUuid={rootWbsItemUuid}
              onSelectBreadcrumb={(
                event: MouseEvent | Event,
                breadcrumb: BreadCrumb
              ) => {
                ctx.setState(
                  {
                    ...ctx.state,
                    searchCondition: {
                      ...ctx.state.searchCondition,
                      rootWbsItemUuid: breadcrumb.wbsItemUuid,
                    },
                  },
                  () => {
                    ctx.rememberCurrentSearchCondition()
                    this.switchRoowWbsItem(ctx, needToOpenInNewTab(event))
                  }
                )
              }}
            />
          ) : (
            <></>
          ),
        } as CustomComponent,
      ],
    }
  }
}
