import { openWbsItemSearch } from '../../WbsItemSearch/wbsItemSearchOptions'
import React, { useCallback } from 'react'
import PageHeader, {
  Header,
  HeaderContent,
  HeaderDivider,
} from '../../../components/headers/HeaderBar/PageHeader'
import { getDefaultSearchFilter } from '../../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { styled } from '@mui/material'
import SubmitButton from '../../../components/buttons/SubmitButton'
import ReloadButton from '../../../components/buttons/ReloadButton'
import { isProduction } from '../../../../utils/urls'
import { ProjectPlanRow } from '../projectPlanOptions'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import QuickFilters from './QuickFilters'
import { GridApi } from 'ag-grid-community'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import ProgressReportIconButton from '../../../components/buttons/ProgressReportIconButton'
import { useDialogState } from '../../../components/dialogs/useDialogState'
import { SprintReportIconButton } from '../../../components/buttons/SprintReportIconButton'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import SearchWindow from '../../../components/toolbars/ContainerToolBar/SearchWindow'

interface OwnProps {
  treeRootUuid?: string
  projectUuid: string
  onSubmit: () => void
  onCancel: () => void
  submitDisabled: boolean
  isLoading: boolean
  rootProjectPlan?: ProjectPlanRow
  gridApi?: GridApi
  onChangeFilteredUuids: (values: string[]) => void
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
}

interface StateProps {
  notEdited: boolean
}

const TOGGLE_ITEMS = [ToolbarToggleValue.DISPLAY, ToolbarToggleValue.REPORT]
const RIGHT_HEADER_CONTENT_STYLE = {
  marginRight: isProduction ? undefined : 60,
}

const HeaderGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})

const HeaderIconGroup = styled('div')({
  display: 'flex',
  margin: '0 5px',
  width: 26,
  height: 26,
})

type Props = OwnProps &
  SettingSpeedDialProps &
  StateProps &
  WrappedComponentProps

const ProjectPlanHeader = (props: Props) => {
  const [searchFilter, setSearchFilter] = React.useState(
    getDefaultSearchFilter()
  )
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()
  const onConfirmInCancelDialog = useCallback(() => {
    closeCancelDialog()
    props.onCancel()
  }, [closeCancelDialog, props.onCancel])
  const onClickSearchInWbsItemSearchToolbar = useCallback(() => {
    openWbsItemSearch(props.projectUuid, searchFilter, '')
  }, [props.projectUuid, searchFilter])

  return (
    <PageHeader>
      <Header>
        <HeaderContent>
          <HeaderGroup>
            <SubmitButton
              onClick={props.onSubmit}
              disabled={
                props.submitDisabled || props.isLoading || props.notEdited
              }
              notEdited={props.notEdited}
            />
            <ReloadButton
              onClick={openCancelDialog}
              disabled={props.isLoading}
            />
            <CancelConfirmDialog
              open={showCancelDialog}
              onConfirm={onConfirmInCancelDialog}
              onClose={closeCancelDialog}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <HeaderIconGroup>
              <SettingSpeedDial
                onClickExport={props.onClickExport}
                onClickImport={props.onClickImport}
                rowHeight={props.rowHeight}
                onClickChangeRowHeight={props.onClickChangeRowHeight}
                currentFormat={props.currentFormat}
                onClickChangeDateFormat={props.onClickChangeDateFormat}
              />
            </HeaderIconGroup>
            <HeaderIconGroup>
              <ProgressReportIconButton
                projectUuid={props.projectUuid}
                rootWbsItemUuid={props.rootProjectPlan?.wbsItem.uuid}
                scheduledDate={props.rootProjectPlan?.wbsItem.scheduledDate}
              />
            </HeaderIconGroup>
            <HeaderIconGroup>
              <SprintReportIconButton
                projectUuid={props.projectUuid}
                rootWbsItemUuid={props.rootProjectPlan?.wbsItem.uuid}
              />
            </HeaderIconGroup>
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <QuickFilters
              gridApi={props.gridApi}
              onChangeFilteredUuids={props.onChangeFilteredUuids}
            />
          </HeaderGroup>
          <ToolbarToggleGroup
            toggleItems={TOGGLE_ITEMS}
            value={props.toolbar}
            onChange={props.onChangeToolbar}
            noticeItem={ToolbarToggleValue.DISPLAY}
            isNotice={props.isNotice}
          />
        </HeaderContent>
      </Header>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(injectIntl(ProjectPlanHeader))
