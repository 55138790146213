import { Fade, Slider, SliderThumb } from '@mui/material'
import { Color } from '../../../../../../../../../styles/commonStyles'
import { GanttCellRendererProps } from './index'
import { alpha, styled } from '@mui/system'
import objects from '../../../../../../../../../utils/objects'
import { WbsItemTypeVO } from '../../../../../../../../../domain/value-object/WbsItemTypeVO'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'
import { useCallback } from 'react'

export const GanttSlider = (props: GanttCellRendererProps) => {
  const { data, timeScale } = props
  const dataPath = props.dataPath ? props.dataPath + '.' : ''
  const wbsItem = objects.getValue(data, `${dataPath}wbsItem`)

  const findIndex = useCallback(
    (date: CalendarDateVO): number =>
      timeScale.findIndex(
        (v, i) =>
          (date.isEqual(v) || date.isAfter(v)) &&
          timeScale[i + 1] &&
          date.isBefore(timeScale[i + 1])
      ),
    [timeScale]
  )

  if (!wbsItem?.scheduledDate?.startDate || !wbsItem?.scheduledDate?.endDate) {
    return <></>
  }

  const startDate = CalendarDateVO.of(wbsItem.scheduledDate.startDate)
  const endDate = CalendarDateVO.of(wbsItem.scheduledDate.endDate)

  const isBeforeStart = startDate.isBefore(timeScale[0])
  const isAfterEnd = endDate.isAfter(timeScale[timeScale.length - 1])
  const startIndex = findIndex(startDate)
  const endIndex = findIndex(endDate) + 1

  return (
    <Fade in={true} timeout={300}>
      <StyledSlider
        key={`gantt-slider-${props.rowIndex}`}
        value={[
          isBeforeStart ? 0 : startIndex,
          isAfterEnd ? timeScale.length - 1 : endIndex,
        ]}
        size="small"
        components={{ Thumb: StyledSliderThumb }}
        valueLabelDisplay="auto"
        valueLabelFormat={(_, index) => {
          return index === 0 ? startDate.format('M/D') : endDate.format('M/D')
        }}
        max={timeScale.length - 1}
        wbsitemtype={wbsItem.wbsItemType}
        scheduled={
          +(
            !!wbsItem.scheduledDate?.startDate &&
            !!wbsItem.scheduledDate?.endDate
          )
        }
        disablethumbs={[isBeforeStart, isAfterEnd]}
        index={props.rowIndex}
      />
    </Fade>
  )
}

enum SliderColor {
  SLIDER_TASK = '#888888',
  SLIDER_NOT_SCHEDULED = '#cccccc',
  SLIDER_ANCESTORS = '#686868',
}

function getSliderColor(props: StyledSliderProps) {
  if (!props.scheduled) {
    return SliderColor.SLIDER_NOT_SCHEDULED
  } else if (!props.wbsitemtype?.isTask()) {
    return SliderColor.SLIDER_ANCESTORS
  }
  return SliderColor.SLIDER_TASK
}

type StyledSliderProps = {
  // Reduce console errors
  scheduled: number
  wbsitemtype?: WbsItemTypeVO
  disablethumbs: boolean[]
  index: number
}
const StyledSlider = styled(Slider)<StyledSliderProps>(props => {
  const sliderColor = getSliderColor(props)
  const isWorkGroupOrProcess =
    props.wbsitemtype?.isWorkgroup() || props.wbsitemtype?.isProcess()
  const isArrow =
    props.wbsitemtype?.isDeliverableList() || props.wbsitemtype?.isDeliverable()
  return {
    height: '4px',
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    touchAction: 'none',
    pointerEvents: 'none',
    margin: 0,
    zIndex: 3,
    '& .MuiSlider-root': {
      color: sliderColor,
    },
    '& .MuiSlider-rail': {
      height: 0,
    },
    '& .MuiSlider-track': {
      display: 'block',
      position: 'absolute',
      opacity: props.wbsitemtype?.isTask() ? 0.75 : 1,
      pointerEvents: props.disablethumbs.some(v => !!v) ? 'none' : 'all',
      cursor: 'pointer',
      transition: 'all 0.1s ease !important',
      height: `${isWorkGroupOrProcess || isArrow ? 10 : 3}px`,
      backgroundColor: !props.scheduled ? 'transparent' : sliderColor,
      backgroundImage: !props.scheduled
        ? `repeating-linear-gradient(-90deg, transparent, transparent 2px, ${sliderColor} 2px, ${sliderColor} 5px)`
        : undefined,
      transform: `translateY(${isWorkGroupOrProcess || isArrow ? -4 : -1}px)`,
      borderRadius: isWorkGroupOrProcess ? 1 : 'inherit',
      clipPath:
        isWorkGroupOrProcess && !props.disablethumbs[1]
          ? 'polygon(calc(100% - 4px) 100%, 100% calc(50% + 0.5px), 100% calc(50% - 0.5px), calc(100% - 4px) 0, 0 0, 0 100%)'
          : isArrow && !props.disablethumbs[1]
          ? 'polygon(0 3.5px, calc(100% - 5px) 3.5px, calc(100% - 5px) 0, 100% 50%, calc(100% - 5px) 100%, calc(100% - 5px) calc(100% - 3.5px), 0 calc(100% - 3.5px))'
          : isArrow && props.disablethumbs[1]
          ? 'polygon(0 3.5px, 100% 3.5px, 100% calc(100% - 3.5px), 0 calc(100% - 3.5px))'
          : undefined,
    },
    '& .MuiSlider-valueLabel:before': {
      bottom: props.index === 0 ? undefined : 0,
      top: props.index === 0 ? '-8px' : undefined,
    },
  }
})

type StyledSliderThumbProps = {
  disabled?: boolean
  labeldisabled?: number
  thumb?: number
}
const StyledSliderThumbBase = styled(SliderThumb)<StyledSliderThumbProps>(
  props => {
    const { wbsitemtype, index } = props['ownerState']
    const transparentThumb =
      wbsitemtype?.isWorkgroup() ||
      wbsitemtype?.isProcess() ||
      ((wbsitemtype?.isDeliverableList() || wbsitemtype?.isDeliverable()) &&
        props.thumb === 1)
    const hoverStyle = {
      boxShadow: `0 0 0 0.25rem ${alpha(Color.GREY, 0.15)}`,
      transform: 'scale(1.5)',
    }
    return {
      top: `${transparentThumb ? 54 : 50}%`,
      height: `${props.disabled ? 0 : transparentThumb ? 10 : 7}px`,
      width: `${props.disabled ? 0 : transparentThumb ? 10 : 7}px`,
      border: `${props.disabled ? 0 : transparentThumb ? 5 : 4}px solid`,
      borderRadius: '50%',
      borderColor: `${
        transparentThumb ? 'transparent' : getSliderColor(props['ownerState'])
      }`,
      color: `${
        transparentThumb ? 'transparent' : getSliderColor(props['ownerState'])
      }`,
      boxSizing: 'border-box',
      outline: 0,
      pointerEvents: 'all',
      cursor: 'pointer',
      transition: 'all 0.1s ease !important',
      transformOrigin: `${transparentThumb ? 15 : 12}px ${
        transparentThumb ? 15 : 12
      }px`,
      margin: 0,
      '&::after': {
        height: 0,
        width: 0,
      },
      ':hover': hoverStyle,
      '&.Mui-focusVisible': hoverStyle,
      '&.Mui-active': hoverStyle,
      '& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
        transform: `translateY(${index === 0 ? 40 : -100}%) scale(0.6)`,
      },
      '& .MuiSlider-valueLabel': {
        visibility:
          props.disabled || props.labeldisabled ? 'hidden' : undefined,
      },
    }
  }
)

function StyledSliderThumb(props) {
  const { children, ...other } = props
  const thumb = other['data-index']
  const { disablethumbs, value, min, max } = props['ownerState']
  return (
    <StyledSliderThumbBase
      {...other}
      thumb={thumb}
      disabled={
        (thumb === 0 && disablethumbs[0]) || (thumb === 1 && disablethumbs[1])
      }
    >
      {children}
    </StyledSliderThumbBase>
  )
}
