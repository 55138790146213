import { styled } from '@mui/system'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'
import { BreadCrumb } from '../../../../../store/breadcrumb'
import { Box, Link } from '@mui/material'
import { PinDropIcon } from '../../../icons/PinDropIcon'
import {
  FONT_FAMILY,
  FontWeight,
  FontSize,
  TextColor,
} from '../../../../../styles/commonStyles'

const RootContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)({
  height: 42,
  overflow: 'scroll',
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  fontSize: '10px',
  letterSpacing: 0,
  color: '#888888',
  opacity: 1,
  margin: '0px 4px',
  '& .MuiBreadcrumbs-li': {
    fontWeight: FontWeight.NORMAL,
  },
})

type Props = {
  breadcrumbs?: BreadCrumb
  itemName?: string
  onClick?: (bc: BreadCrumb) => void
  disableCopyPath?: boolean
  showRoot?: boolean
}

const Breadcrumbs = (props: Props) => {
  const { breadcrumbs, itemName } = props
  if (!breadcrumbs) {
    return <></>
  }

  const createBreadcrumbs = (
    elements: JSX.Element[],
    breadcrumbs: BreadCrumb
  ): JSX.Element[] => {
    if (breadcrumbs.next) {
      return createBreadcrumbs(
        [
          ...elements,
          props.onClick ? (
            <Link
              key={breadcrumbs.link}
              color="inherit"
              component="button"
              onClick={() => props.onClick!(breadcrumbs)}
              style={{
                fontFamily: FONT_FAMILY,
                fontSize: FontSize.SMALL,
                fontWeight: FontWeight.BOLD,
                color: TextColor.GREY,
              }}
            >
              {breadcrumbs.name}
            </Link>
          ) : (
            <Link
              key={breadcrumbs.link}
              color="inherit"
              href={breadcrumbs.link}
              target="_blank"
            >
              {breadcrumbs.name}
            </Link>
          ),
        ],
        breadcrumbs.next
      )
    }
    return [...elements]
  }

  return (
    <RootContainer>
      {props.showRoot && breadcrumbs.next && (
        <Box sx={{ color: TextColor.GREY, margin: '0 8px' }}>/</Box>
      )}
      <StyledMuiBreadcrumbs
        aria-label="breadcrumb"
        maxItems={4}
        itemsAfterCollapse={2}
      >
        {createBreadcrumbs([], breadcrumbs)}
      </StyledMuiBreadcrumbs>
      {!props.disableCopyPath && (
        <PinDropIcon breadcrumbs={breadcrumbs} itemName={itemName} />
      )}
    </RootContainer>
  )
}

export default Breadcrumbs
