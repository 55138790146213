import { IconButton } from '@mui/material'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import { useCallback } from 'react'
import { open } from '../../../../router'
import { MONTHLY_VALUE_FIELD_PREFIX } from '../../gridOptions'
import { styled } from '@mui/system'

const RootDiv = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '0',
  alignItems: 'center',
})

export const WorkTimeCustomHeader = props => {
  const onClickIcon = useCallback(
    event => {
      const date = props.column
        ?.getColId()
        ?.replace(MONTHLY_VALUE_FIELD_PREFIX, '')
      if (!date) return
      const projectCode = window.location.pathname.split('/')[2]
      open(
        `/actualWorkingHoursConfirm/${projectCode}?term=${date.replace(
          '/',
          '-'
        )}`,
        event,
        undefined,
        true
      )
    },
    [props.column]
  )
  return (
    <RootDiv>
      <span>{props.displayName}</span>
      <div style={{ width: '100%', textAlign: 'right' }}>
        <IconButton onClick={onClickIcon} sx={{ width: 18 }}>
          <PunchClockIcon style={{ fontSize: 16 }} color="primary" />
        </IconButton>
      </div>
    </RootDiv>
  )
}
