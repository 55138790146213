import { useMemo } from 'react'
import {
  CheckboxProps as MuiCheckboxProps,
  Checkbox as MuiCheckbox,
  styled,
} from '@mui/material'
import checkboxActiveMIcon from '../../../../assets/checkbox_rounded_active_m.svg'
import checkboxInactiveMIcon from '../../../../assets/checkbox_rounded_inactive_m.svg'

type RoundedCheckboxProps = Omit<
  MuiCheckboxProps,
  'size' | 'icon' | 'checkedIcon' | 'indeterminateIcon'
> & {
  size?: 's' | 'm'
}

// TODO: replace indeterminate icon.
export const RoundedCheckbox = styled(
  ({ size = 'm', ...other }: RoundedCheckboxProps) => {
    const { inactiveIcon, activeIcon } = useMemo(() => {
      switch (size) {
        case 's':
          return {
            inactiveIcon: checkboxInactiveMIcon,
            activeIcon: checkboxActiveMIcon,
          }
        case 'm':
          return {
            inactiveIcon: checkboxInactiveMIcon,
            activeIcon: checkboxActiveMIcon,
          }
      }
    }, [size])
    return (
      <MuiCheckbox
        {...other}
        icon={<img src={inactiveIcon} />}
        checkedIcon={<img src={activeIcon} />}
      />
    )
  }
)({
  '&.MuiCheckbox-root': {
    padding: '3px',
  },
})
