import { ChangeEvent, PropsWithChildren, useCallback, useMemo } from 'react'
import {
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  IconButton,
  List,
  ListItem,
  TextField,
} from '@mui/material'
import MuiFilterIcon from '@mui/icons-material/FilterAltOutlined'
import { intl } from '../../../../../i18n'
import { UiStateScope } from '../../../../../services/model/uiState'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded'
import LinkIcon from '@mui/icons-material/LinkRounded'
import MuiEditIcon from '@mui/icons-material/EditRounded'
import MuiDeleteIcon from '@mui/icons-material/DeleteRounded'
import { UiStateScopeIcon } from '../../../icons/UiStateScopeIcon'
import { FontSize, TextColor } from '../../../../../styles/commonStyles'
import SearchIcon from '../../../../../assets/search_icon.svg'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { colorPalette } from '../../../../style/colorPallete'

export const StoredUiStateList = styled(List)({
  minHeight: '100px',
})

export const StoredUiStateListItem = styled(ListItem)({
  padding: '2px 0 2px 12px',
})

export const StoredUiStateListItemButton = styled(ListItemButton)({
  padding: 0,
})

const StoredUiStateFilteringInputBase = styled(TextField)({
  width: '100%',
})

const FilterIcon = styled(MuiFilterIcon)({
  margin: '0 2px 0 0',
})

type StoredUiStateFilteringInputProps = {
  value: string | undefined
  onChange: (newValue: string) => void
  placeholder?: string
  isSearchIcon?: boolean
  width?: number
  onClear?: (value: string) => void
}

export const StoredUiStateFilteringInput = ({
  value,
  onChange,
  placeholder,
  isSearchIcon,
  width,
  onClear,
}: StoredUiStateFilteringInputProps) => {
  const onChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <StoredUiStateFilteringInputBase
      sx={{ width: width }}
      value={value}
      onChange={onChangeValue}
      size={'small'}
      variant={'outlined'}
      InputProps={{
        startAdornment:
          isSearchIcon && isSearchIcon ? (
            <img src={SearchIcon} style={{ marginRight: '8px' }}></img>
          ) : (
            <InputAdornment position={'start'}>
              <FilterIcon color="action" />
            </InputAdornment>
          ),
        endAdornment: onClear && (
          <IconButton onClick={() => onClear('')} sx={{ padding: '8px 0' }}>
            <ClearRoundedIcon sx={{ color: colorPalette.monotone[4] }} />
          </IconButton>
        ),
      }}
      placeholder={
        placeholder
          ? placeholder
          : intl.formatMessage({
              id: 'storedUiState.filter',
            })
      }
    />
  )
}

const ScopeListItemIcon = styled(ListItemIcon)({
  minWidth: '24px',
})

type ScopeIconProps = {
  scope: UiStateScope
}

export const ListItemScopeIcon = ({ scope }: ScopeIconProps) => {
  const tooltip = useMemo(() => {
    switch (scope) {
      case 'TENANT':
        return intl.formatMessage({ id: 'uiState.scope.project' })
      case 'USER':
        return intl.formatMessage({ id: 'uiState.scope.private' })
    }
  }, [scope])
  return (
    <ScopeListItemIcon>
      <Tooltip title={tooltip}>
        <UiStateScopeIcon scope={scope} />
      </Tooltip>
    </ScopeListItemIcon>
  )
}

export const StoredUiStateName = styled(ListItemText)({
  width: '200px', // ???
  '& span': {
    width: '260px',
    wordBreak: 'break-all',
  },
})

export const StoredUiStateUpdatedBy = styled(ListItemText)({
  width: '60px',
  marginRight: '5px',
  '& .MuiTypography-root': {
    fontSize: FontSize.SMALL,
    color: TextColor.GREY,
  },
  flex: 'initial',
})

export const StoredUiStateUpdatedAt = styled(ListItemText)({
  width: '100px',
  marginRight: '10px',
  '& .MuiTypography-root': {
    fontSize: FontSize.SMALL,
    color: TextColor.GREY,
  },
  flex: 'initial',
})

export const ListItemSecondaryActionArea = styled('div')({
  position: 'absolute',
  right: '180px',
})

type ListItemSecondaryActionIconButtonProps = {
  onClick: () => void
}

const StyledListItemSecondaryActionIconButton = styled(IconButton)({
  padding: '2px',
})

const ListItemSecondaryActionIconButton = ({
  onClick,
  children,
}: PropsWithChildren<ListItemSecondaryActionIconButtonProps>) => (
  <StyledListItemSecondaryActionIconButton size="small" onClick={onClick}>
    {children}
  </StyledListItemSecondaryActionIconButton>
)

// TODO: Consider putting these icons in view/components/icons
const CopyIcon = styled(LinkIcon)({
  width: '17px',
  transform: 'rotate(135deg)',
})

const EditIcon = styled(MuiEditIcon)({
  width: '17px',
})

const DeleteIcon = styled(MuiDeleteIcon)({
  width: '17px',
})

export const CopyIconButton = ({
  onClick,
}: ListItemSecondaryActionIconButtonProps) => (
  <ListItemSecondaryActionIconButton onClick={onClick}>
    <CopyIcon color="action" />
  </ListItemSecondaryActionIconButton>
)

export const EditIconButton = ({
  onClick,
}: ListItemSecondaryActionIconButtonProps) => (
  <ListItemSecondaryActionIconButton onClick={onClick}>
    <EditIcon color="action" />
  </ListItemSecondaryActionIconButton>
)

export const DeleteIconButton = ({
  onClick,
}: ListItemSecondaryActionIconButtonProps) => (
  <ListItemSecondaryActionIconButton onClick={onClick}>
    <DeleteIcon color="action" />
  </ListItemSecondaryActionIconButton>
)

const StyledAddUiStateIconButton = styled(IconButton)({
  margin: '0 0 0 6px',
})

type AddUiStateIconButtonProps = {
  onClick: () => void
}

export const AddUiStateIconButton = ({
  onClick,
}: AddUiStateIconButtonProps) => {
  return (
    <Tooltip title={intl.formatMessage({ id: 'savedUIState.add' })}>
      <StyledAddUiStateIconButton size="small" onClick={onClick}>
        <AddCircleOutlineIcon color="action" />
      </StyledAddUiStateIconButton>
    </Tooltip>
  )
}
