import { RowNode } from 'ag-grid-community'
import {
  AggregateTargetValue,
  deliverableAggregator,
  getDescendantDeliverableCodes,
  getDescendantTaskCodes,
  taskAggregator,
} from '.'
import WbsItemRowApi from '../wbsItem'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../../../domain/entity/WbsItemEntity'

export enum EvmIndicator {
  BUDGET_AT_COMPLETION = 'budgetAtCompletion',
  PLANNED_VALUE = 'plannedValue',
  EARNED_VALUE = 'earnedValue',
  ACTUAL_COST = 'actualCost',
  SCHEDULE_VARIANCE = 'scheduleVariance',
  COST_VARIANCE = 'costVariance',
  SCHEDULE_PERFORMANCE_INDEX = 'schedulePerformanceIndex',
  COST_PERFORMANCE_INDEX = 'costPerformanceIndex',
  ESTIMATE_TO_COMPLETE = 'estimateToComplete',
  ESTIMATE_AT_COMPLETION = 'estimateAtCompletion',
  VARIANCE_AT_COMPLETION = 'varianceAtCompletion',
  TOTAL = 'total',

  ESTIMATED_PROGRESS_RATE = 'estimatedProgressRate',
  PROGRESS_RATE = 'progressRate',
  DELAYED = 'delayed',
  REMAINING = 'remaining',
  PRECEDING = 'preceding',
  UNPLANNED = 'unplanned',
}

const projectPlanFacadeAggregator = (
  node: RowNode,
  evmIndicator: string,
  aggregateTarget: AggregateTarget,
  aggregateTargetType: AggregateField,
  rateToHour: number = 1
) => {
  // EVM values
  if (evmIndicator === EvmIndicator.BUDGET_AT_COMPLETION) {
    if (aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
      return calculateValue(countItemsNotDiscard, node, aggregateTarget)
    } else {
      return calculateValue(getEstimatedHour, node, aggregateTarget, rateToHour)
    }
  }
  if (evmIndicator === EvmIndicator.PLANNED_VALUE) {
    if (aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
      return calculateValue(countPlanedValue, node, aggregateTarget, rateToHour)
    } else {
      return calculateValue(
        getExpiredScheduledHour,
        node,
        aggregateTarget,
        rateToHour,
        node => !isScheduledToBeDone(node)
      )
    }
  }
  if (evmIndicator === EvmIndicator.ACTUAL_COST) {
    return calculateValue(getActualHour, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.EARNED_VALUE) {
    if (aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
      return calculateValue(countDoneItems, node, aggregateTarget, rateToHour)
    } else {
      return calculateValue(
        getEarnedValue,
        node,
        aggregateTarget,
        rateToHour,
        node => !isDone(node)
      )
    }
  }
  if (evmIndicator === EvmIndicator.SCHEDULE_VARIANCE) {
    return calculateValue(
      getScheduleVariance,
      node,
      aggregateTarget,
      rateToHour
    )
  }
  if (evmIndicator === EvmIndicator.COST_VARIANCE) {
    return calculateValue(getCostVariance, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.SCHEDULE_PERFORMANCE_INDEX) {
    return calculateRate(
      getSchedulePerformanceIndex,
      node,
      aggregateTarget,
      node => !isDone(node)
    )
  }
  if (evmIndicator === EvmIndicator.COST_PERFORMANCE_INDEX) {
    return calculateRate(
      getCostPerformanceIndex,
      node,
      aggregateTarget,
      node => !isDone(node)
    )
  }
  if (evmIndicator === EvmIndicator.ESTIMATE_TO_COMPLETE) {
    return calculateValue(
      getEstimateToComplete,
      node,
      aggregateTarget,
      rateToHour
    )
  }
  if (evmIndicator === EvmIndicator.ESTIMATE_AT_COMPLETION) {
    return calculateValue(
      getEstimateAtCompletion,
      node,
      aggregateTarget,
      rateToHour
    )
  }
  if (evmIndicator === EvmIndicator.VARIANCE_AT_COMPLETION) {
    return calculateValue(
      getVarianceAtCompletion,
      node,
      aggregateTarget,
      rateToHour
    )
  }

  if (evmIndicator === EvmIndicator.TOTAL) {
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? countTotalItems
        : getTotalWorkload
    return calculateValue(calculator, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.ESTIMATED_PROGRESS_RATE) {
    if (!node) return ''
    if (aggregateTarget === WbsItemType.DELIVERABLE && isDeliverable(node)) {
      return '-'
    }
    if (isTask(node)) return '-'
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? getEstimatedCompletionRate
        : getEstimatedProgressRate
    return calculateRate(
      calculator,
      node,
      aggregateTarget,
      node => !isDone(node)
    )
  }
  if (evmIndicator === EvmIndicator.PROGRESS_RATE) {
    if (!node) return ''
    if (aggregateTarget === WbsItemType.DELIVERABLE && isDeliverable(node)) {
      return '-'
    }
    if (isTask(node)) return '-'
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? getCompletionRate
        : getProgressRate
    return calculateRate(
      calculator,
      node,
      aggregateTarget,
      node => !isDone(node)
    )
  }
  if (evmIndicator === EvmIndicator.DELAYED) {
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? countDelayItems
        : getDelayedWorkload
    return calculateValue(calculator, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.REMAINING) {
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? countRemainingItems
        : getRemainingWorkload
    return calculateValue(calculator, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.PRECEDING) {
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? countPrecedingItems
        : getPrecedingWorkload
    return calculateValue(calculator, node, aggregateTarget, rateToHour)
  }
  if (evmIndicator === EvmIndicator.UNPLANNED) {
    const calculator =
      aggregateTargetType === AggregateField.WBS_ITEM_COUNT
        ? countUnplannedItems
        : getUnplannedWorkload
    return calculateValue(calculator, node, aggregateTarget, rateToHour)
  }
}

const calculateValue = (
  calculator: Function,
  node: RowNode | null,
  aggregateTarget: AggregateTarget,
  denominator: number = 1,
  blankCondition: (node: RowNode) => boolean = node => false
) => {
  if (!node) {
    return ''
  }
  if (aggregateTarget === WbsItemType.DELIVERABLE && isTask(node)) {
    return '-'
  }
  if (isAggregateTarget(node, aggregateTarget) && blankCondition(node)) {
    return ''
  }
  const value = calculator(node, aggregateTarget, denominator)
  if (Number.isNaN(value) || !Number.isFinite(value)) {
    return '-'
  }
  return value
}
const calculateRate = (
  calculator: Function,
  node: RowNode | null,
  aggregateTarget: AggregateTarget,
  blankCondition: (node: RowNode) => boolean = node => false
) => {
  if (!node) {
    return ''
  }
  if (
    (aggregateTarget === WbsItemType.DELIVERABLE && isTask(node)) ||
    (isAggregateTarget(node, aggregateTarget) && blankCondition(node))
  ) {
    return '-'
  }
  const rate = calculator(node, aggregateTarget)
  if (!Number.isFinite(rate) || Number.isNaN(rate)) {
    return '-'
  }
  return Number(rate)
}

const isDone = (node: RowNode) => WbsItemRowApi.isDone(node.data.wbsItem)
const isNotDiscard = (node: RowNode) =>
  WbsItemRowApi.isNotDiscard(node.data.wbsItem)
const isDelayed = (node: RowNode) => WbsItemRowApi.isDelayed(node.data.wbsItem)
const isPreceding = (node: RowNode) =>
  WbsItemRowApi.isPreceding(node.data.wbsItem)
const isDeliverable = (node: RowNode) =>
  WbsItemRowApi.isDeliverable(node.data.wbsItem)
const isNotDiscardDeliverable = (node: RowNode) =>
  WbsItemRowApi.isDeliverable(node.data.wbsItem) &&
  WbsItemRowApi.isNotDiscard(node.data.wbsItem)
const isTask = (node: RowNode) => WbsItemRowApi.isTask(node.data.wbsItem)
const isNotDiscardTask = (node: RowNode) =>
  WbsItemRowApi.isTask(node.data.wbsItem) &&
  WbsItemRowApi.isNotDiscard(node.data.wbsItem)
const isScheduledToBeDone = (node: RowNode) =>
  WbsItemRowApi.isScheduledToBeDone(node.data.wbsItem)
const isUnplanned = (node: RowNode) =>
  WbsItemRowApi.isUnplanned(node.data.wbsItem)

const isAggregateTarget = (node: RowNode, aggregateTarget: AggregateTarget) => {
  return (
    (aggregateTarget === WbsItemType.DELIVERABLE && isDeliverable(node)) ||
    (aggregateTarget === WbsItemType.TASK && isTask(node))
  )
}

export const getRate = (numerator: number, denominator: number): number => {
  if (denominator === 0) {
    return Number.POSITIVE_INFINITY
  }
  if (!Number.isFinite(denominator)) {
    return Number.NaN
  }
  return numerator / denominator
}

const getEstimatedHour = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isNotDiscard,
    denominator
  )
}

const getEarnedValue = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isDone,
    denominator
  )
}

const getTotalWorkload = (
  node: RowNode,
  aggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  const filterCondition =
    aggregateTarget === WbsItemType.DELIVERABLE ? isDeliverable : isTask
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    filterCondition,
    denominator
  )
}

const getDelayedWorkload = (
  node: RowNode,
  aggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isDelayed,
    denominator
  )
}

const getPrecedingWorkload = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isPreceding,
    denominator
  )
}

const getRemainingWorkload = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return (
    getEstimatedHour(node, aggregateTarget, denominator) -
    getEarnedValue(node, aggregateTarget, denominator)
  )
}

const getActualHour = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  return (
    taskAggregator(node, AggregateTargetValue.ACTUAL_HOUR, 'wbsItem') /
    denominator
  )
}

const getExpiredScheduledHour = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isScheduledToBeDone,
    denominator
  )
}

const getUnplannedWorkload = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.ESTIMATED_HOUR,
    'wbsItem',
    isUnplanned,
    denominator
  )
}

const countExpiredItem = (node: RowNode, aggregateTarget: AggregateTarget) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.COUNT,
    'wbsItem',
    isScheduledToBeDone
  )
}

const getScheduleVariance = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  return (
    getEarnedValue(node, aggregateTarget, denominator) -
    getExpiredScheduledHour(node, aggregateTarget, denominator)
  )
}

const getCostVariance = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  return (
    getEarnedValue(node, aggregateTarget, denominator) -
    getActualHour(node, aggregateTarget, denominator)
  )
}

const getSchedulePerformanceIndex = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  return getRate(
    getEarnedValue(node, aggregateTarget),
    getExpiredScheduledHour(node, aggregateTarget)
  )
}

const getCostPerformanceIndex = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  return getRate(
    getEarnedValue(node, aggregateTarget),
    getActualHour(node, aggregateTarget)
  )
}

const getEstimateToComplete = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const cpi = Number(getCostPerformanceIndex(node, aggregateTarget))
  return getRate(
    getEstimatedHour(node, aggregateTarget, denominator) -
      getEarnedValue(node, aggregateTarget, denominator),
    cpi
  )
}

const getEstimateAtCompletion = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  const cpi = Number(getCostPerformanceIndex(node, aggregateTarget))
  return getRate(getEstimatedHour(node, aggregateTarget, denominator), cpi)
}

const getVarianceAtCompletion = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  denominator: number = 1
) => {
  return (
    getEstimatedHour(node, aggregateTarget, denominator) -
    Number(getEstimateAtCompletion(node, aggregateTarget, denominator))
  )
}

const getEstimatedProgressRate = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  return getRate(
    getExpiredScheduledHour(node, aggregateTarget),
    getEstimatedHour(node, aggregateTarget)
  )
}

const getProgressRate = (node: RowNode, aggregateTarget: AggregateTarget) => {
  return getRate(
    getEarnedValue(node, aggregateTarget),
    getEstimatedHour(node, aggregateTarget)
  )
}

const getEstimatedCompletionRate = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  return getRate(
    countExpiredItem(node, aggregateTarget),
    countItemsNotDiscard(node, aggregateTarget)
  )
}

const getCompletionRate = (node: RowNode, aggregateTarget: AggregateTarget) => {
  return getRate(
    countDoneItems(node, aggregateTarget),
    countItemsNotDiscard(node, aggregateTarget)
  )
}

const countDoneItems = (node: RowNode, aggregateTarget: AggregateTarget) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(node, AggregateTargetValue.COUNT, 'wbsItem', isDone)
}

const countPlanedValue = (node: RowNode, aggregateTarget: AggregateTarget) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(
    node,
    AggregateTargetValue.COUNT,
    'wbsItem',
    isScheduledToBeDone
  )
}

const countPrecedingItems = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(node, AggregateTargetValue.COUNT, 'wbsItem', isPreceding)
}

const countItemsNotDiscard = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(node, AggregateTargetValue.COUNT, 'wbsItem', isNotDiscard)
}

const countDelayItems = (node: RowNode, aggregateTarget) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(node, AggregateTargetValue.COUNT, 'wbsItem', isDelayed)
}

const countRemainingItems = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  return (
    countItemsNotDiscard(node, aggregateTarget) -
    countDoneItems(node, aggregateTarget)
  )
}

const countTotalItems = (node: RowNode, aggregateTarget) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  const filterCondition =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? isNotDiscardDeliverable
      : isNotDiscardTask
  return aggregator(
    node,
    AggregateTargetValue.COUNT,
    'wbsItem',
    filterCondition
  )
}

const countUnplannedItems = (
  node: RowNode,
  aggregateTarget: AggregateTarget
) => {
  const aggregator =
    aggregateTarget === WbsItemType.DELIVERABLE
      ? deliverableAggregator
      : taskAggregator
  return aggregator(node, AggregateTargetValue.COUNT, 'wbsItem', isUnplanned)
}

const getAllTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isNotDiscard)
}

const getAllDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isNotDiscard)
}

const getPlannedTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isScheduledToBeDone)
}

const getPlannedDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isScheduledToBeDone)
}

const getEarnedTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isDone)
}

const getEarnedDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isDone)
}

const getPrecedingTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isPreceding)
}

const getPrecedingDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isPreceding)
}

const getDelayedTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isDelayed)
}

const getDelayedDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isDelayed)
}

const getRemainingTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isRemaining)
}

const getRemainingDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isRemaining)
}

const getUnplannedTaskCodes = (node: RowNode): string[] => {
  return getDescendantTaskCodes(node, WbsItemRowApi.isUnplanned)
}

const getUnplannedDeliverableCodes = (node: RowNode): string[] => {
  return getDescendantDeliverableCodes(node, WbsItemRowApi.isUnplanned)
}

export const getDescendantCodes = (
  node: RowNode,
  aggregateTarget: AggregateTarget,
  evmIndicator: EvmIndicator
): string[] | undefined => {
  switch (aggregateTarget) {
    case WbsItemType.DELIVERABLE:
      switch (evmIndicator) {
        case EvmIndicator.BUDGET_AT_COMPLETION:
          return getAllDeliverableCodes(node)
        case EvmIndicator.PLANNED_VALUE:
          return getPlannedDeliverableCodes(node)
        case EvmIndicator.EARNED_VALUE:
          return getEarnedDeliverableCodes(node)
        case EvmIndicator.PRECEDING:
          return getPrecedingDeliverableCodes(node)
        case EvmIndicator.DELAYED:
          return getDelayedDeliverableCodes(node)
        case EvmIndicator.REMAINING:
          return getRemainingDeliverableCodes(node)
        case EvmIndicator.UNPLANNED:
          return getUnplannedDeliverableCodes(node)
        default:
          return undefined
      }
    case WbsItemType.TASK:
      switch (evmIndicator) {
        case EvmIndicator.BUDGET_AT_COMPLETION:
          return getAllTaskCodes(node)
        case EvmIndicator.PLANNED_VALUE:
          return getPlannedTaskCodes(node)
        case EvmIndicator.EARNED_VALUE:
          return getEarnedTaskCodes(node)
        case EvmIndicator.PRECEDING:
          return getPrecedingTaskCodes(node)
        case EvmIndicator.DELAYED:
          return getDelayedTaskCodes(node)
        case EvmIndicator.REMAINING:
          return getRemainingTaskCodes(node)
        case EvmIndicator.UNPLANNED:
          return getUnplannedTaskCodes(node)
        default:
          return undefined
      }
  }
}

export default projectPlanFacadeAggregator
