import { styled } from '@mui/material'
import {
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
} from '.'
import { ReportStatusIcon } from '../status-icon'
import { TextInput } from '../inputs/TextInput'
import { ReportStatusSelect } from '../inputs/ReportStatusSelect'
import { useCallback } from 'react'
import {
  ProgressReportQualitativeContent,
  VersionReportStatus,
} from '../../../../../domain/entity/version-report/VersionReportVersionEntity'

export type ProgressReportTableContent = {
  uuid: string
  name: string
  status: VersionReportStatus
  comment: string
  scheduledProgressRate: string
  progressRate: string
  total: string
  planned: string
  completed: string
  delay: string
  remaining: string
}
export type Value = {
  workload: number
  count: number
}

type ProgressReportTableProps = {
  editing: boolean
  contents: ProgressReportTableContent[]
  updateTableContent: (
    v: ProgressReportQualitativeContent,
    index: number
  ) => void
}
export const ProgressReportTable = ({
  contents,
  editing,
  updateTableContent,
}: ProgressReportTableProps) => {
  const updateStatus = useCallback(
    (
      prev: ProgressReportQualitativeContent,
      status: VersionReportStatus,
      index: number
    ) => {
      updateTableContent(
        {
          ...prev,
          status,
        },
        index
      )
    },
    [updateTableContent]
  )
  const updateComment = useCallback(
    (prev: ProgressReportTableContent, comment: string, index: number) => {
      updateTableContent(
        {
          ...prev,
          comment,
        },
        index
      )
    },
    [updateTableContent]
  )
  return (
    <StyledTableContainer>
      <StyledTable>
        <colgroup>
          <NameCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <ValueCol />
          <CommentCol />
        </colgroup>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell>名称</StyledTableCell>
            <StyledTableCell>ステータス</StyledTableCell>
            <StyledTableCell>予定進捗率</StyledTableCell>
            <StyledTableCell>進捗率</StyledTableCell>
            <StyledTableCell>総数</StyledTableCell>
            <StyledTableCell>予定</StyledTableCell>
            <StyledTableCell>完了</StyledTableCell>
            <StyledTableCell>遅延</StyledTableCell>
            <StyledTableCell>残</StyledTableCell>
            <StyledTableCell>定性コメント</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {contents.map((content, i) => (
            <StyledTableRow key={content.uuid}>
              <StyledTableCell>{content.name}</StyledTableCell>
              <StyledTableCell position="center">
                {!editing && <ReportStatusIcon status={content.status} />}
                {editing && (
                  <ReportStatusSelect
                    status={content.status}
                    onChange={(v: VersionReportStatus) =>
                      updateStatus(content, v, i)
                    }
                  />
                )}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.scheduledProgressRate}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.progressRate}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.total}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.planned}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.completed}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.delay}
              </StyledTableCell>
              <StyledTableCell sizeVariant="l" position="right">
                {content.remaining}
              </StyledTableCell>
              <StyledTableCell>
                {!editing && content.comment}
                {editing && (
                  <TextInput
                    value={content.comment}
                    onChange={(v: string | undefined) =>
                      updateComment(content, v || '', i)
                    }
                    multiline={true}
                  />
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </StyledTableBody>
      </StyledTable>
    </StyledTableContainer>
  )
}

const NameCol = styled('col')({
  width: '180px',
})
const ValueCol = styled('col')({
  width: '110px',
})
const CommentCol = styled('col')({})
