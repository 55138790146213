import { SlackChannel } from '../../../domain/value-object/Slack/ChannelVO'
import { PreAuthorizationVO } from '../../../domain/value-object/Slack/PreAuthorizationVO'
import { SlackUser } from '../../../domain/value-object/Slack/UserVO'
import { SlackWorkspace } from '../../../domain/value-object/Slack/WorkspaceVO'
import API from '../../../lib/commons/api'

export const useSlackExternalServiceStorageService = () => {
  const getForPreparingSlackAuthorization =
    async (): Promise<PreAuthorizationVO> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/slack/authorize/prepare'
      )
      return response.json
    }

  const getAuthorizedSlackWorkspace = async (): Promise<SlackWorkspace[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/slack/workspaces/authorized'
    )
    return response.json
  }

  const getSlackWorkspace = async (): Promise<SlackWorkspace[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/slack/workspaces'
    )
    return response.json
  }

  const getChannelsFromSlackApi = async (
    slackWorkspaceId: string
  ): Promise<SlackChannel[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/slack/channels',
      {
        slackWorkspaceId,
      }
    )
    return response.json
  }

  const getUsersFromSlackApi = async (
    slackWorkspaceId: string
  ): Promise<SlackUser[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/slack/users',
      {
        slackWorkspaceId,
      }
    )
    return response.json
  }

  return {
    getForPreparingSlackAuthorization,
    getAuthorizedSlackWorkspace,
    getSlackWorkspace,
    getChannelsFromSlackApi,
    getUsersFromSlackApi,
  }
}
