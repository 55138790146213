import { styled, TextField, TextFieldProps } from '@mui/material'
import {
  HeaderText,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  Title,
} from '.'
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'
import { ProjectPlanSelect } from './ProjectPlanSelect'
import {
  ProgressReportByProcessPageConfig,
  ProgressReportByTeamPageConfig,
} from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'
import { TeamSelectGrid } from './TeamSelect'

type ProgressReportByTeamConfigDialogProps = {
  open: boolean
  onClose: () => void
  config: ProgressReportByTeamPageConfig
  updateConfig: (rootUuid: string | undefined, teamUuids: string[]) => void
  snapshotUuid: string
}

export const ProgressReportByTeamConfigDialog = ({
  open,
  onClose,
  config,
  updateConfig,
  snapshotUuid,
}: ProgressReportByTeamConfigDialogProps) => {
  const [rootUuid, setRootUuid] = useState<string | undefined>()
  useEffect(() => {
    setRootUuid(config.rootUuid)
  }, [config.rootUuid])
  const [teamUuids, setTeamUuids] = useState<string[]>(config.tableRows)

  const updateLeafUuid = useCallback((uuid: string, selected: boolean) => {
    setRootUuid(_ => {
      if (selected) return uuid
      return undefined
    })
  }, [])
  const updateTeamUuid = useCallback((uuid: string, selected: boolean) => {
    setTeamUuids(prev => {
      const withoutOne = prev.filter(v => v !== uuid)
      if (!selected) return withoutOne
      return [...withoutOne, uuid]
    })
  }, [])
  const onSubmit = useCallback(() => {
    updateConfig(rootUuid, teamUuids)
    onClose()
  }, [updateConfig, rootUuid, teamUuids, onClose])

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg">
      <StyledDialogTitle>
        <Title>チーム別進捗報告の設定変更</Title>
      </StyledDialogTitle>
      <StyledDialogContent>
        <GridWithHeader>
          <HeaderText>集計対象の工程</HeaderText>
          <GridWrapper>
            <ProjectPlanSelect
              snapshotUuid={snapshotUuid}
              selectedUuids={rootUuid ? [rootUuid] : []}
              updateSelectedUuid={updateLeafUuid}
            />
          </GridWrapper>
        </GridWithHeader>
        <GridWithHeader>
          <HeaderText>集計対象のチーム</HeaderText>
          <GridWrapper>
            <TeamSelectGrid
              snapshotUuid={snapshotUuid}
              selectedUuids={teamUuids}
              updateSelectedUuid={updateTeamUuid}
            />
          </GridWrapper>
        </GridWithHeader>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onSubmit} colorPattern="skyBlue">
          設定を変更する
        </Button>
        <Button onClick={onClose} colorPattern="monotone" variant="outlined">
          {intl.formatMessage({ id: 'dialog.cancel' })}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

const GridWrapper = styled('div')({
  width: '600px',
  height: '200px',
})

const GridWithHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
})
