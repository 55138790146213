import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import api from '../../lib/commons/api'
import { AuthError } from '../../error/AuthError'
import { createAuthInfo } from '../../domain/entity/TenantEntity'
import { getRootUrl } from '../../infrastructure/browser/url'
import {
  AuthRepository,
  PasswordPolicy,
} from '../../applications/ports/authRepository'
import {
  ExternalServiceCode,
  UserExternalService,
} from '../../domain/entity/IdProviderEntity'
import Auth from '../../lib/commons/auth'

export const useAuthRepository = (): AuthRepository => {
  const getTenantAuthInfo = useCallback(async (tenantAlias: string) => {
    const {
      json: {
        tenantUuid,
        domain,
        audience,
        clientId,
        // clientPhrase,
        skipAgreement,
        maintenanceMessage,
      },
    } = await api.functional
      .request('GET', '/api/v1/tenants/select', { alias: tenantAlias }, false)
      .catch(async err => {
        if (err.code === 'NOT_FOUND') {
          throw new AuthError('TENANT_NOT_EXIST')
        }
        throw new AuthError('NEED_RESCUE')
      })
    const rootUrl = getRootUrl(tenantAlias)
    const tenantAuthInfo = createAuthInfo(
      tenantUuid,
      rootUrl,
      tenantAlias,
      domain,
      audience,
      clientId,
      skipAgreement,
      maintenanceMessage
    )
    return tenantAuthInfo
  }, [])
  const getExternalIdByEmail = useCallback(async (email: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/users/external_id',
      { email },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
    if (!response.json.externalId) {
      throw new AuthError('INCORRECT_EMAIL_OR_PASSWORD')
    }
    return response.json.externalId
  }, [])
  const getUserExternalServices = useCallback(async (email: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/users/external_services',
      { email },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
    return response.json as UserExternalService[]
  }, [])
  const updateUserExternalService = useCallback(
    async (
      email: string,
      externalId: string,
      externalServiceCode: ExternalServiceCode
    ) => {
      api.functional.request(
        'PUT',
        '/api/v1/users/external_service',
        { email, externalId, externalServiceCode },
        false,
        null,
        { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
      )
    },
    []
  )
  const getPasswordPolicy = useCallback(async () => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/tenants/password_policy',
      { tenantUuid: Auth.getCurrentTenant()?.tenantUuid },
      false
    )
    return response.json as PasswordPolicy
  }, [])
  return {
    getTenantAuthInfo,
    getExternalIdByEmail,
    getUserExternalServices,
    updateUserExternalService,
    getPasswordPolicy,
  }
}
