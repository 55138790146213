import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Switch } from '../../../../components/inputs/Switch'

type ToggleCellProps = {
  value: boolean | undefined
  onChange?: (v: boolean | undefined) => void
} & CellPropsBase
export const ToggleCell = ({
  label,
  cellWidth,
  value,
  onChange,
  editable = true,
  required = false,
}: ToggleCellProps) => {
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <Switch />
      </CellValueArea>
    </FormCell>
  )
}
