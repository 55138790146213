import { styled } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import {
  EnterpriseProjectReportConfig,
  UpdateEnterpriseProjectReportConfig,
} from '../model/config'
import { AggregateTargetToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/AggregateTargetToggleButtonGroup'
import { AggregateFieldToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/AggregateFieldToggleButtonGroup'
import { WorkloadUnitToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/WorkloadUnitToggleButtonGroup'

type EnterpriseProjectReportHeadeProps = {
  config: EnterpriseProjectReportConfig
} & UpdateEnterpriseProjectReportConfig

const HeaderArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: colorPalette.monotone[1],
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  padding: '20px 16px',
  gap: '6px',
})

export const EnterpriseProjectReportHeader = ({
  config: { aggregateTarget, aggregateField, workloadUnit },
  updateAggregateTarget,
  updateAggregateField,
  updateWorkloadUnit,
}: EnterpriseProjectReportHeadeProps) => {
  return (
    <HeaderArea>
      <AggregateTargetToggleButtonGroup
        value={aggregateTarget}
        onChange={updateAggregateTarget}
      />
      <AggregateFieldToggleButtonGroup
        value={aggregateField}
        onChange={updateAggregateField}
      />
      <WorkloadUnitToggleButtonGroup
        value={workloadUnit}
        onChange={updateWorkloadUnit}
      />
    </HeaderArea>
  )
}
