import _ from 'lodash'
import { UiStateKey } from '../../../lib/commons/uiStates'
import store from '../../../store'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { PageArea, PageProps } from '..'
import { Collapse } from '@mui/material'
import { BulkSheetView } from '../../containers/BulkSheetView'
import Loading from '../../components/process-state-notifications/Loading'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { intl } from '../../../i18n'
import AddRowCountInputDialog from '../../containers/BulkSheet/AddRowCountInputDialog'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'
import { usePageState } from './hooks/pageState'
import { useTagsData } from './hooks/tagsData'
import { TagRow, createNewTagRow } from './tag'
import { TagsGridOptions } from './GridOptions'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import TagsToolbar from './Components/Toolbar'
import TagsHeader from './Components/Header'
import {
  ColDef,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  RowDataUpdatedEvent,
  RowDragEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { useDialog } from './hooks/dialog'
import {
  addRowMenuItems,
  addRowsMenuItems,
  deleteRowMenuItems,
} from './GridOptions/contextMenu'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import {
  focusRow,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import {
  addRowsToLastChild,
  removeRows,
} from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useDragStyle } from '../../containers/BulkSheetView/hooks/gridEvents/rowDrag'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

type WrapperProps = PageProps

export const Tag = projectPrivate(({ uuid: functionUuid }: WrapperProps) => {
  const { project } = useProjectPrivateContext()
  const [loading, setLoading] = useState<boolean>(true)
  const ref = useRef<HTMLDivElement>(null)

  const [toolbar, setToolbar] = useState<ToolbarToggleValue | undefined>(
    undefined
  )
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<ColDef[]>([])
  const pageState = usePageState({ functionUuid })
  const {
    data,
    setData,
    refresh,
    save,
    clearData,
    deleteRows,
    hasDuplicatedData,
  } = useTagsData(project.uuid)
  const gridOptions = useMemo(() => {
    return TagsGridOptions()
  }, [])
  const submit = useRef<Function>()
  submit.current = save
  const reload = useRef<Function>()
  reload.current = refresh

  const refreshAll = useCallback(async () => {
    if (!gridOptions.api) return
    setLoading(true)
    try {
      const refreshRows = reload.current ?? refresh
      await refreshRows(project.uuid)
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        errors: new InputError(),
      }
      setLoading(false)
    }
  }, [gridOptions, refresh])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()
      if (gridOptions.context.errors.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node
                ? (
                    data.findIndex(v => v.uuid === node.data.uuid) + 1
                  ).toString()
                : ''
            }),
          })
        )
        return
      }
      if (hasDuplicatedData()) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'tags.maintenance.message.warn.duplicatedRows',
            }),
          })
        )
        return
      }

      const response = submit.current ? await submit.current() : await save()
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    functionUuid,
    gridOptions.api,
    gridOptions.context.errors,
    refreshAll,
    save,
  ])

  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialog.openCancel()
    } else {
      refreshAll()
    }
  }, [hasChanged, refreshAll])

  const onDeleteRows = useCallback(() => {
    const rows = getSelectedNode(gridOptions.api!).map(v => v.data) as TagRow[]
    const target = _.uniqBy(rows, 'uuid')
    const newData = removeRows(data, target)
    setData(newData)
    deleteRows(target)
    dialog.closeDeleteConfirmation()
  }, [data, gridOptions.api])

  const bulkSheetState = useBulkSheetState(functionUuid, project.uuid)
  const dialog = useDialog()

  const prevData = useRef<TagRow[]>([])
  prevData.current = data

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        const filterModel = gridOptions.api?.getFilterModel()
        const quick = filterModel?.['uuid']
        gridOptions.api?.setFilterModel(quick ? { uuid: quick } : null)
      }
    },
    [gridOptions.columnApi]
  )

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [bulkSheetState])

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      rememberColumnState()
    },
    [bulkSheetState]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => e.api.getColumnDef(col.colId))
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)
      setSortedColumns(sortedColumns)
    },
    [bulkSheetState]
  )

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const selectedNodes = getSelectedNode(params.api)
      if (!params.node) return []
      const items = [
        ...addRowMenuItems(params, data, setData, project.uuid),
        ...addRowsMenuItems(params, data, {
          onAddRows: dialog.openMultiSelect,
        }),
        ...deleteRowMenuItems(selectedNodes, dialog.openDeleteConfirmation),
      ]
      return items
    },
    [data]
  )

  const dragStyle = useDragStyle(gridOptions)

  const onRowDragEnd = useCallback(
    (event: RowDragEvent<TagRow>) => {
      const movedIds = event.nodes.map(v => v.id)
      const overNode = event.overNode
      try {
        if (!overNode || movedIds.includes(overNode.id)) {
          return
        }
        const result = data.concat().filter(v => !movedIds.includes(v.uuid))
        const moved = data.concat().filter(v => movedIds.includes(v.uuid))
        // If moved toward down, move after overNode.
        const index =
          result.findIndex(v => v.uuid === overNode.id!) +
          (event.overIndex > (event.node.rowIndex || 0) ? 1 : 0)
        result.splice(index, 0, ...moved.map(v => ({ ...v, edited: true })))
        setData(result)
      } finally {
        // Clear drag cell style
        dragStyle.refreshDragStyle()
      }
    },
    [data, dragStyle, setData]
  )

  useEffect(() => {
    refreshAll()
  }, [setData])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
  }, [pageState.rowHeight])

  useKeyBind([{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }])

  return (
    <PageArea>
      <TagsHeader
        isLoading={loading}
        onSubmit={onSubmit}
        onCancel={onReload}
        rowHeight={pageState.rowHeight}
        onClickChangeRowHeight={(value: number) =>
          pageState.setRowHeight(value)
        }
        toolbar={toolbar}
        onChangeToolbar={value => setToolbar(value)}
        isNotice={!!filteredColumns.length || !!sortedColumns.length}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <TagsToolbar
          toolbar={toolbar}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column => {
            if (!column || !gridOptions.api) return
            const filterModel = gridOptions.api.getFilterModel()
            delete filterModel[column]
            gridOptions.api.setFilterModel(filterModel)
          }}
          onChangeColumnFilter={onChangeColumnFilter}
        />
      </Collapse>
      <BulkSheetView
        ref={ref}
        gridOptions={gridOptions}
        rowData={data}
        getContextMenuItems={contextMenu}
        rowHeight={pageState.rowHeight}
        // Events and actions
        onColumnVisible={onColumnVisible}
        onColumnResized={rememberColumnState}
        onColumnMoved={rememberColumnState}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onRowDataUpdated={(e: RowDataUpdatedEvent<TagRow>) =>
          e.api?.refreshCells({ columns: ['rowNumber'], force: true })
        }
        onRowDragEnter={dragStyle.onRowDragEnter}
        onRowDragMove={dragStyle.onRowDragMove}
        onRowDragEnd={onRowDragEnd}
        onRowDragLeave={dragStyle.refreshDragStyle}
      />
      <Loading isLoading={loading} elem={ref.current} />
      {dialog.deleteConfirmation && gridOptions.api && (
        <DeleteRowConfirmationDialog
          target={getSelectedNode(gridOptions.api).map(
            v =>
              v.data.name ??
              `(${intl.formatMessage({ id: 'displayName.undefined' })})`
          )}
          onConfirm={onDeleteRows}
          onClose={dialog.closeDeleteConfirmation}
          additionalContentTitle={intl.formatMessage({
            id: 'tags.deleteConfirmation.used.title',
          })}
          additionalContentTarget={getSelectedNode(gridOptions.api)
            .filter(v => v.data.used)
            .map(v => v.data.name)}
        />
      )}
      {dialog.multiSelect && (
        <AddRowCountInputDialog
          open={true}
          title={intl.formatMessage({
            id: 'bulksheet.contextMenu.insert.multipleRow.title',
          })}
          submitHandler={(addRowCount: number | undefined) => {
            const rows = Array.from({ length: addRowCount ?? 0 }).map(_ =>
              createNewTagRow(project.uuid)
            )
            const target = data[data.length - 1]
            const newData = addRowsToLastChild(data, rows, target.uuid)
            setData(newData)
            dialog.closeMultiSelect()
            focusRow(gridOptions.api!, rows[0].uuid)
          }}
          closeHandler={dialog.closeMultiSelect}
        />
      )}
      {dialog.cancel && (
        <CancelConfirmDialog
          open={true}
          onConfirm={() => {
            dialog.closeCancel()
            refreshAll()
          }}
          onClose={dialog.closeCancel}
        />
      )}
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={functionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          sharable={false}
          currentUIState={{
            columnState: bulkSheetState.columnState,
            filterState: bulkSheetState.filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, filterState } = uiState.UIState
            if (columnState) {
              bulkSheetState.saveColumnState(columnState)
              gridOptions.columnApi?.applyColumnState({
                state: columnState,
                applyOrder: true,
              })
            }
            if (filterState) {
              bulkSheetState.saveFilterState(filterState)
              gridOptions.api?.setFilterModel(filterState)
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
    </PageArea>
  )
})
