import {
  ComponentHeader,
  ComponentTitle,
  ComponentWrapper,
  DashboardContent,
  DashboardRoot,
  DashboardTitle,
  NoData,
  WidgetAlternative,
} from '../components'
import { ProjectDetail } from '../../../../lib/functions/project'
import {
  Header,
  HeaderContent,
} from '../../../components/headers/HeaderBar/PageHeader'
import { intl } from '../../../../i18n'
import { styled } from '@mui/material'
import { useRisk } from './hooks/riskData'
import { RiskList } from './RiskList'
import { RiskHeatMap } from './RiskHeatMap'
import React from 'react'
import ReloadButton from '../../../components/buttons/ReloadButton'

const DashboardBody = styled('div')({
  width: '100%',
  display: 'flex',
})

export const RiskDashboardContent = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px',
})

export const RiskDashboard = ({ project }: { project: ProjectDetail }) => {
  const { data, refresh, filteredRiskList, filter } = useRisk(project)

  return (
    <DashboardRoot>
      <Header sx={{ height: '36px', paddingLeft: '11px' }}>
        <HeaderContent>
          <DashboardTitle>
            {intl.formatMessage({ id: 'riskDashboard' })}
          </DashboardTitle>
          <ReloadButton onClick={refresh} />
        </HeaderContent>
      </Header>
      <DashboardContent>
        <ComponentWrapper>
          <ComponentHeader>
            <ComponentTitle>
              {intl.formatMessage({ id: 'riskDashboard' })}
            </ComponentTitle>
          </ComponentHeader>
          <DashboardBody sx={{ width: '100%' }}>
            {data.length === 0 ? (
              <WidgetAlternative>
                <NoData />
              </WidgetAlternative>
            ) : (
              <>
                <RiskHeatMap riskList={data} filterRiskList={filter} />
                <RiskList riskList={filteredRiskList} />
              </>
            )}
          </DashboardBody>
        </ComponentWrapper>
      </DashboardContent>
    </DashboardRoot>
  )
}
