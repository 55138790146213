import * as d3 from 'd3'
import { FontSize, FontWeight } from '../../../../../../styles/commonStyles'
import {
  TwoDimChartContext,
  TwoDimGraphPlotter,
  ValueFormatter,
  addTooltipByEvent,
  addSelectXLine,
} from '../index'

export interface LineGraphProps {
  data: LineGraphData[]
  style: LineGraphStyle
  eventHandler: EventHandler
  formatter: ValueFormatter
}

interface LineGraphStyle {
  color: string
  lineStyle: LineStyle
}

export interface LineGraphData {
  xValue: number
  yValue: number
}

interface EventHandler {
  nodeTooltip?: (x: number) => string | JSX.Element
  lineTooltip?: string | JSX.Element
  onClickNode?: (x: number) => void
  onClickLine?: () => void
}

export enum LineStyle {
  STRAIGHT,
  DASHED,
}

export class LineGraphPlotter extends TwoDimGraphPlotter {
  private props: LineGraphProps

  constructor(_props: LineGraphProps) {
    super()
    this.props = _props
  }
  plot(ctx: TwoDimChartContext) {
    const { data, style, eventHandler, formatter } = this.props
    const color = style.color
    const lineStyle = style.lineStyle
    const getX = (d: LineGraphData) => ctx.xScale(d.xValue) || 0
    const getY = (d: LineGraphData) => ctx.yScale(d.yValue) || 0
    this.applyLineStyle(
      ctx.chart
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 2)
        .attr(
          'd',
          d3
            .line<LineGraphData>()
            .x(d => getX(d))
            .y(d => getY(d))
        ),
      lineStyle
    )
    ctx.chart
      .append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'transparent')
      .attr('stroke-width', 8)
      .on('click', (e, d) => {
        eventHandler.onClickLine && eventHandler.onClickLine()
      })
      .attr(
        'd',
        d3
          .line<LineGraphData>()
          .x(d => getX(d))
          .y(d => getY(d))
      )
    ctx.chart
      .selectAll()
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 4)
      .attr('cx', d => getX(d))
      .attr('cy', d => getY(d))
      .attr('fill', color)
    ctx.chart
      .selectAll()
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 12)
      .attr('cx', d => getX(d))
      .attr('cy', d => getY(d))
      .attr('fill', 'transparent')
      .on('mouseover', (e, d) => {
        d3.select(e.currentTarget)
          .attr('fill', color)
          .style('opacity', 0.5)
          .style('cursor', 'pointer')
      })
      .on('mouseout', e => {
        d3.select(e.currentTarget)
          .attr('r', 12)
          .attr('fill', 'transparent')
          .style('cursor', 'default')
      })
      .on('click', (e, d) => {
        const nodeTooltip = eventHandler.nodeTooltip
        if (nodeTooltip) {
          addTooltipByEvent(nodeTooltip(d.xValue), e)
          addSelectXLine(getX(d), ctx.innerHeight)
        }
      })
    const lastData = data.slice(-1)
    ctx.chart
      .selectAll()
      .data(data)
      .enter()
      .append('text')
      .attr('x', d => getX(d) - 20)
      .attr('y', d => getY(d) - 10)
      .text(d => formatter(d.yValue))
      .style('fill', 'black')
      .style('font-size', FontSize.SMALL)
      .style('text-anchor', 'middle')
      .style('display', 'none')
    ctx.chart
      .selectAll()
      .data(lastData)
      .enter()
      .append('text')
      .attr('x', d => getX(d) + 15)
      .attr('y', d => getY(d) + 4)
      .text(d => formatter(d.yValue))
      .style('fill', 'black')
      .style('font-size', FontSize.LARGE)
      .style('font-weight', FontWeight.BOLD)
      .style('text-anchor', 'start')
  }

  private applyLineStyle = (
    line: d3.Selection<SVGPathElement, LineGraphData[], HTMLElement, any>,
    style: LineStyle
  ): void => {
    switch (style) {
      case LineStyle.STRAIGHT:
        return
      case LineStyle.DASHED:
        line.attr('stroke-dasharray', '4, 4')
        return
    }
  }
}
