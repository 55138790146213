import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { useMemo } from 'react'

export const StyledTableContainer = styled(TableContainer)({})
export const StyledTable = styled(Table)({})
export const StyledTableHead = styled(TableHead)({
  backgroundColor: colorPalette.skyBlue[7],
})
export const StyledTableBody = styled(TableBody)({})
export const StyledTableRow = styled(TableRow)({})
type StyledTableCellProps = {
  sizeVariant?: 'm' | 'l'
  position?: 'left' | 'center' | 'right'
}
export const StyledTableCell = styled(TableCell)(
  ({ sizeVariant = 'm', position = 'left' }: StyledTableCellProps) => {
    const sizeStyle = useMemo(() => {
      switch (sizeVariant) {
        case 'm':
          return {
            fontSize: '14px',
          }
        case 'l':
          return {
            fontSize: '20px',
            fontWeight: 600,
          }
      }
    }, [sizeVariant])
    const positionStyle = useMemo(() => {
      return {
        textAlign: position,
      }
    }, [position])
    return {
      ...sizeStyle,
      ...positionStyle,
      color: colorPalette.monotone[9],
      border: `1px solid ${colorPalette.monotone[2]}`,
      '&.MuiTableCell-head': {
        color: colorPalette.monotone[0],
        fontWeight: 600,
        border: `1px solid ${colorPalette.monotone[0]}`,
      },
    }
  }
)
