import _ from 'lodash'
import { useCallback, useState } from 'react'
import { generateUuid } from '../../../../utils/uuids'
import { requireSave } from '../../../../store/requiredSaveData'
import store from '../../../../store'
import DateVO from '../../../../vo/DateVO'
import { getPrevSiblings } from '../../../containers/BulkSheetView/lib/tree'
import {
  BudgetResultType,
  ProfitLossItemAmountBody,
} from '../../ProfitLossItems/profitLossItems'
import {
  OperationCalendar,
  ProfitLossItemAmountInput,
  ProfitLossMemberActualWorkMonthInput,
  ProfitLossMemberInput,
  ProfitLossMemberRow,
  ProfitLossMemberRowBody,
  ProfitLossMemberRowMonthlyValueBody,
  ProfitLossMemberRowType,
  ProfitLossMemberSkeleton,
  ProfitLossMemberWorkMonthBody,
  ProfitLossMembersUpdateParams,
  UnitPriceForMonth,
  fetchProfitLossMembers,
  fetchResourcePlanMemberWorkMonth,
  updateMembers,
} from '../ProfitLossMembersNew'
import { UnitPriceBody } from '../../UnitPricePerPosition/UnitPricePerPosition'

export const useProfitLossMembersData = () => {
  const [data, setDataInternal] = useState<ProfitLossMemberRow[]>([])
  const [rootUuid, setRootUuid] = useState<string | undefined>(undefined)
  const [lockVersion, setLockVersion] = useState<number>(0)
  const [summaryRows, setSummaryRows] = useState<ProfitLossMemberRow[]>([])
  const [operationCalendar, setOperationCalendar] = useState<
    OperationCalendar[]
  >([])

  const makeForecastAmountValues = useCallback(
    (member: ProfitLossMemberSkeleton) => {
      const now = DateVO.now().getFirstDayOfMonth()
      const amountBudgets = member.budgetAmounts ?? []
      const amountResults = member.resultAmounts ?? []
      const resList: ProfitLossMemberRowMonthlyValueBody[] = []
      amountBudgets.forEach(v => {
        if (new DateVO(v.month).isSameOrAfter(now)) {
          resList.push(
            new ProfitLossMemberRowMonthlyValueBody(v.month, v.amount)
          )
        }
      })
      amountResults.forEach(v => {
        if (new DateVO(v.month).isBefore(now)) {
          resList.push(
            new ProfitLossMemberRowMonthlyValueBody(v.month, v.amount)
          )
        }
      })
      return resList
    },
    []
  )

  const makeForecastWorkMonthValues = useCallback(
    (member: ProfitLossMemberSkeleton) => {
      const now = DateVO.now().getFirstDayOfMonth()
      const workMonthSchedule = member.scheduledWorkMonth ?? []
      const workMonthActual = member.actualWorkMonth ?? []
      const resList: ProfitLossMemberRowMonthlyValueBody[] = []
      workMonthSchedule.forEach(v => {
        if (new DateVO(v.month).isSameOrAfter(now)) {
          resList.push(
            new ProfitLossMemberRowMonthlyValueBody(v.month, v.manMonth)
          )
        }
      })
      workMonthActual.forEach(v => {
        if (new DateVO(v.month).isBefore(now)) {
          resList.push(
            new ProfitLossMemberRowMonthlyValueBody(v.month, v.manMonth)
          )
        }
      })
      return resList
    },
    []
  )

  const responseToChildRow = useCallback(
    (
      source: ProfitLossMemberSkeleton,
      rowType: string,
      rootUuid: string,
      itemAmounts?: ProfitLossItemAmountBody[],
      workMonths?: ProfitLossMemberWorkMonthBody[]
    ) => {
      const uuid = generateUuid()
      const row: ProfitLossMemberRow = {
        uuid: uuid,
        body: new ProfitLossMemberRowBody(source, rowType),
        treeValue: [uuid],
        parentUuid: rootUuid,
      }
      if (itemAmounts) {
        row.body.monthlyValues = itemAmounts?.map(v => {
          return new ProfitLossMemberRowMonthlyValueBody(
            v.month,
            v.amount,
            v.manualEdited
          )
        })
      } else if (workMonths) {
        row.body.monthlyValues = workMonths?.map(v => {
          return new ProfitLossMemberRowMonthlyValueBody(
            v.month,
            v.manMonth,
            v.manualEdited
          )
        })
      }
      return row
    },
    []
  )

  const LOCALDATE_MAX: string = '+999999999-12-31'
  const makeUnitPrices = useCallback(
    (
      baseUnitPrices: UnitPriceBody[],
      startDate: string,
      endDate: string
    ): UnitPriceForMonth[] => {
      const sortedMappings = baseUnitPrices.sort((a, b) => {
        const result = a.validFrom.localeCompare(b.validFrom)
        if (result === 0) return 0
        return 0 < result ? -1 : 1
      })

      const toDateVO = (strDate: string) => {
        return new DateVO(LOCALDATE_MAX === strDate ? '9999-12-31' : strDate)
      }
      const start = new DateVO(startDate).getFirstDayOfMonth()
      const end = new DateVO(endDate).getEndOfDay()
      const unitPrices: UnitPriceForMonth[] = []
      let price: UnitPriceBody | undefined = undefined
      let samePriceTo: DateVO | undefined = undefined

      for (let date = start; date.isBefore(end); date = date.addMonths(1)) {
        if (!price || !samePriceTo || date.isAfter(samePriceTo)) {
          price = baseUnitPrices.find(
            p =>
              date.isSameOrAfter(toDateVO(p.validFrom)) &&
              date.isSameOrBefore(toDateVO(p.validTo))
          )
          samePriceTo = price?.validTo ? toDateVO(price.validTo) : undefined
        }
        unitPrices.push({
          month: date.formatForApi(),
          unitPrice: price?.unitPrice || 0,
        } as UnitPriceForMonth)
      }
      return unitPrices
    },
    []
  )

  const fetchRecords = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      accountCategory: string
    ) => {
      const response = await fetchProfitLossMembers({
        projectUuid,
        startDate,
        endDate,
        accountCategory,
      })
      const sources: ProfitLossMemberSkeleton = response.json
      const rows: ProfitLossMemberRow[] = []
      sources.children?.forEach(v => {
        // Root Row
        const forecastWorkmonth = responseToChildRow(
          v,
          ProfitLossMemberRowType.WorkMonthForecast,
          v.uuid,
          undefined,
          undefined
        )
        forecastWorkmonth.unitPrices = makeUnitPrices(
          v.unitPrices,
          startDate,
          endDate
        )
        forecastWorkmonth.uuid = v.uuid
        forecastWorkmonth.body.monthlyValues = makeForecastWorkMonthValues(v)
        forecastWorkmonth.investedWorkMonth = v.investedWorkMonth
        rows.push(forecastWorkmonth)

        // Scheduled Work Month Row
        rows.push(
          responseToChildRow(
            v,
            ProfitLossMemberRowType.WorkMonthSchedule,
            v.uuid,
            undefined,
            v.scheduledWorkMonth
          )
        )
        // Actual Work Month Row
        rows.push(
          responseToChildRow(
            v,
            ProfitLossMemberRowType.WorkMonthActual,
            v.uuid,
            undefined,
            v.actualWorkMonth
          )
        )
        // Forecaset amount row
        const forecastAmount: ProfitLossMemberRow = responseToChildRow(
          v,
          ProfitLossMemberRowType.AmountForecast,
          v.uuid,
          undefined,
          undefined
        )
        forecastAmount.unitPrices = forecastWorkmonth.unitPrices
        forecastAmount.body.monthlyValues = makeForecastAmountValues(v)
        rows.push(forecastAmount)
        // Budger Amount Row
        rows.push(
          responseToChildRow(
            v,
            ProfitLossMemberRowType.AmountBudget,
            v.uuid,
            v.budgetAmounts
          )
        )
        // Result Amount Row
        rows.push(
          responseToChildRow(
            v,
            ProfitLossMemberRowType.AmountResult,
            v.uuid,
            v.resultAmounts
          )
        )
      })
      setRootUuid(sources.uuid)
      setLockVersion(sources.lockVersion)
      setDataInternal(rows)
      setOperationCalendar(sources.operationCalendar)
      const sumRows: ProfitLossMemberRow[] = [
        {
          uuid: '',
          body: new ProfitLossMemberRowBody(
            undefined,
            ProfitLossMemberRowType.AmountForecast
          ),
          isTotal: true,
          treeValue: [],
        },
        {
          uuid: '',
          body: new ProfitLossMemberRowBody(
            undefined,
            ProfitLossMemberRowType.AmountBudget
          ),
          isTotal: true,
          treeValue: [],
        },
        {
          uuid: '',
          body: new ProfitLossMemberRowBody(
            undefined,
            ProfitLossMemberRowType.AmountResult
          ),
          isTotal: true,
          treeValue: [],
        },
      ]
      setSummaryRows(sumRows)
    },
    [
      makeForecastAmountValues,
      makeForecastWorkMonthValues,
      makeUnitPrices,
      responseToChildRow,
    ]
  )

  const makeRegisterAmounts = useCallback(
    (uuid: string) => {
      const rows = data.filter(
        v =>
          v.parentUuid === uuid &&
          (ProfitLossMemberRowType.AmountBudget === v.body.type ||
            ProfitLossMemberRowType.AmountResult === v.body.type)
      )
      const result: ProfitLossItemAmountInput[] =
        rows
          .map(v => {
            const amountType =
              ProfitLossMemberRowType.AmountBudget === v.body.type
                ? BudgetResultType.Budget.toString()
                : BudgetResultType.Result.toString()
            return v.body.monthlyValues.map(itemAmount => {
              const date = new DateVO(itemAmount.yearMonth)
              return {
                budgetResultType: amountType,
                year: date.getYear(),
                month: date.getMonth(),
                amount: itemAmount.value,
                manualEdited: itemAmount.manualEdited,
              } as ProfitLossItemAmountInput
            })
          })
          .flat() ?? []
      return result
    },
    [data]
  )

  const makeRegisterManMonths = useCallback(
    (uuid: string) => {
      const rows = data.filter(
        v =>
          v.parentUuid === uuid &&
          ProfitLossMemberRowType.WorkMonthActual === v.body.type
      )
      const result: ProfitLossMemberActualWorkMonthInput[] =
        rows
          .map(v => {
            return v.body.monthlyValues.map(body => {
              const date = new DateVO(body.yearMonth)
              return {
                year: date.getYear(),
                month: date.getMonth(),
                manMonth: body.value,
                manualEdited: body.manualEdited,
              } as ProfitLossMemberActualWorkMonthInput
            })
          })
          .flat() ?? []
      return result
    },
    [data]
  )

  const save = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      accountCategory: string
    ) => {
      const edited = data
        .filter(v => v.body.type === ProfitLossMemberRowType.WorkMonthForecast)
        ?.map(v => {
          const siblings = getPrevSiblings(data, v)
          return {
            uuid: v.uuid,
            prevSiblingUuid: siblings[siblings.length - 1]?.uuid,
            financialStatementUuid: v.body.financialState?.uuid,
            generalLedgerUuid: v.body.generalLedger?.uuid,
            subsidiaryUuid: v.body.subsidiary?.uuid,
            userUuid: v.body.user?.uuid,
            profitLossItemAmounts: makeRegisterAmounts(v.uuid),
            actualWorkMonths: makeRegisterManMonths(v.uuid),
          } as ProfitLossMemberInput
        })
      const request: ProfitLossMembersUpdateParams = {
        uuid: rootUuid,
        lockVersion,
        projectUuid,
        startDate,
        endDate,
        accountCategory,
        profitLossMembers: edited,
      }
      const response = await updateMembers(request)
      return response
    },
    [data, lockVersion, makeRegisterAmounts, makeRegisterManMonths, rootUuid]
  )

  const setData = useCallback((data: ProfitLossMemberRow[]) => {
    setDataInternal(data)
    store.dispatch(requireSave())
  }, [])

  const fetchScheduledWorkMonth = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      row: ProfitLossMemberRow
    ) => {
      if (!row.body?.user) throw new Error('Illegal argument')

      const response = await fetchResourcePlanMemberWorkMonth({
        projectUuid,
        userUuid: row.body.user.uuid,
        startDate,
        endDate,
      })
      const workMonths: ProfitLossMemberWorkMonthBody[] = response.json

      const rows = _.cloneDeep(data)
      const targetScheduleWorkMonthRow = rows.find(
        d =>
          d.treeValue[0] === row.treeValue[0] &&
          ProfitLossMemberRowType.WorkMonthSchedule === d.body.type
      )
      if (!targetScheduleWorkMonthRow) return

      workMonths.forEach(workMonth => {
        const targetIndex =
          targetScheduleWorkMonthRow.body.monthlyValues.findIndex(
            m => m.yearMonth === workMonth.month
          )
        if (targetIndex < 0) {
          targetScheduleWorkMonthRow.body.monthlyValues.push(
            new ProfitLossMemberRowMonthlyValueBody(
              workMonth.month,
              workMonth.manMonth,
              false
            )
          )
        } else {
          targetScheduleWorkMonthRow.body.monthlyValues[targetIndex].value =
            workMonth.manMonth
        }
      })

      const monthList = workMonths.map(m => m.month)
      targetScheduleWorkMonthRow.body.monthlyValues =
        targetScheduleWorkMonthRow.body.monthlyValues.filter(m =>
          monthList.includes(m.yearMonth)
        )
      setDataInternal(rows)
    },
    [data]
  )

  return {
    data,
    setData,
    rootUuid,
    fetchRecords,
    save,
    summaryRows,
    fetchScheduledWorkMonth,
    operationCalendar,
  }
}
