import { openProgressChart } from '../ProgressChart'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import Sprint, {
  GetSprintsProps,
  SprintBatchDeltaInput,
  SprintDeltaInput,
  SprintDetail,
  SprintInput,
  SprintStatus,
} from '../../../lib/functions/sprint'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import {
  ColDef,
  GetContextMenuItemsParams,
  ValueGetterParams,
} from 'ag-grid-community'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { ALERT_COLOR } from '../../../lib/functions/report'
import DateVO from '../../../vo/DateVO'
import { intl } from '../../../i18n'
import { SearchConditionProgress } from '../../containers/ReportContainer/ReportComponent/SearchCondition/WbsProgressLog'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface SprintState extends BulkSheetState {
  teamUuid?: string
  quickFilters: QuickFilterKey[]
}

export class SprintRow extends RowData {
  projectUuid?: string
  revision?: string
  code?: string
  name = ''
  num?: number
  startDate?: string
  endDate?: string
  status?: SprintStatus
  actualStartDateTime?: string
  actualEndDateTime?: string
}

interface SprintBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    SprintDetail,
    SprintRow,
    SprintState
  > {}

class SprintRowDataSpec extends RowDataSpec<SprintDetail, SprintRow> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      sprintStatus: {
        filter: 'agSetColumnFilter',
      },
    }
  }
  createNewRow(): SprintRow {
    const sprintRow = new SprintRow(generateUuid())
    sprintRow.status = SprintStatus.STANDBY
    return sprintRow
  }
  overwriteRowItemsWithParents(params: {
    child: SprintRow
    parent: SprintRow
  }): SprintRow {
    return params.child
  }
  createRowByResponse(response: SprintDetail): SprintRow {
    return {
      ...response,
      startDate: new DateVO(response.startDate).format(),
      endDate: new DateVO(response.endDate).format(),
      actualStartDateTime: formatDateTime(response.actualStartDateTime) || '',
      actualEndDateTime: formatDateTime(response.actualEndDateTime) || '',
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(original: SprintRow): SprintRow {
    return {
      ...original,
      code: undefined,
      revision: undefined,
    }
  }
}

export enum QuickFilterKey {
  NOT_DONE = 'NOT_DONE',
}

export default class SprintOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  SprintDetail,
  SprintRow,
  SprintState
> {
  fetchDataOnInit = false
  addable = true
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.SprintColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new SprintRowDataSpec()
  pinnedColumns = [
    'project.sprint.code',
    'project.sprint.status',
    'project.sprint.name',
  ]
  lockedColumns = ['project.sprint.code']
  customColumnTypes = {
    'project.sprint.status': ['sprintStatus'],
  }
  getRowStyle = (params): any => {
    if (!params.data) return
    if (
      [SprintStatus.FINISHED, SprintStatus.CANCELED].includes(
        params.data.status
      )
    ) {
      return { backgroundColor: '#dcdcdc' }
    } else if (params.data.status === SprintStatus.INPROGRESS) {
      return { backgroundColor: ALERT_COLOR.MIDDLE_LOW }
    } else if (params.data.status === SprintStatus.STANDBY) {
      return { backgroundColor: null }
    }
  }
  updateDefaultState = async (s: SprintState) => {
    return {
      ...s,
      editable: false,
    }
  }
  onChangeTeam(teamUuid: string, ctx: SprintBulkSheetContext) {
    ctx.setState(
      { teamUuid: teamUuid, editable: !!teamUuid },
      ctx.refreshDataWithLoading
    )
  }
  async getAll(state: SprintState): Promise<APIResponse> {
    if (typeof state.teamUuid === 'undefined') {
      state.teamUuid = ''
    }
    const statuses: SprintStatus[] = Object.entries(SprintStatus).map(
      ([_, value]) => value
    )
    const getSprintsProps: GetSprintsProps = {
      teamUuid: state.teamUuid,
      statusList: statuses,
    }
    return Sprint.getSprints(getSprintsProps)
  }
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: SprintBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data) return
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
        {
          id: ContextMenuGroupId.CUSTOM,
          items: [
            {
              name: intl.formatMessage({ id: 'openProgressReportFlag' }),
              disabled: !params.node.data.revision,
              action: () => {
                openProgressChart(
                  SearchConditionProgress.with({
                    time: {
                      from: new DateVO(
                        params.node!.data.startDate
                      ).getStartOfDay(),
                      to: new DateVO(params.node!.data.endDate)
                        .getEndOfDay()
                        .addDays(2),
                    },
                    teamUuid: ctx.state.teamUuid,
                    sprintUuid: params.node!.data.uuid,
                  })
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.DISPLAY_GRAPH),
            },
          ],
        },
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  onSubmit = async (
    ctx: SprintBulkSheetContext,
    data: {
      added: SprintRow[]
      edited: {
        before: SprintRow
        after: SprintRow
      }[]
      deleted: SprintRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const state = ctx.state
    const input: SprintBatchDeltaInput = {
      added: data.added.map(row => {
        return this.createRequestByRow(row, viewMeta, state)
      }),
      edited: data.edited.map(row => {
        return this.createDeltaRequestByRow(row, viewMeta, state)
      }),
      deleted: data.deleted.map(row => {
        return this.createDeleteRequestByRow(row, state)
      }),
    }
    return Sprint.updateBatchDelta(input)
  }

  private createRequestByRow = (
    row: SprintRow,
    viewMeta: ViewMeta,
    state: SprintState
  ): SprintInput => {
    return {
      projectUuid: state.uuid,
      teamUuid: state.teamUuid!,
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      revision: row.revision,
      code: row.code,
      name: row.name,
      startDate: viewMeta.serializeInputForApi(
        row.startDate,
        viewMeta.functionMeta.properties.byId.get('project.sprint.startDate')!
      ),
      endDate: viewMeta.serializeInputForApi(
        row.endDate,
        viewMeta.functionMeta.properties.byId.get('project.sprint.endDate')!
      ),
      status: row.status,
      actualStartDateTime: viewMeta.serializeInputForApi(
        row.actualStartDateTime,
        viewMeta.functionMeta.properties.byId.get(
          'project.sprint.actualStartDateTime'
        )!
      ),
      actualEndDateTime: viewMeta.serializeInputForApi(
        row.actualEndDateTime,
        viewMeta.functionMeta.properties.byId.get(
          'project.sprint.actualEndDateTime'
        )!
      ),
    }
  }

  private createDeltaRequestByRow = (
    {
      before,
      after,
    }: {
      before: SprintRow
      after: SprintRow
    },
    viewMeta: ViewMeta,
    state: SprintState
  ): SprintDeltaInput => {
    return {
      uuid: after.uuid,
      name:
        before.name !== after.name
          ? {
              oldValue: before.name,
              newValue: after.name,
            }
          : undefined,
      startDate:
        before.startDate !== after.startDate
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.startDate,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.startDate'
                )!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.startDate,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.startDate'
                )!
              ),
            }
          : undefined,
      endDate:
        before.endDate !== after.endDate
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.endDate,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.endDate'
                )!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.endDate,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.endDate'
                )!
              ),
            }
          : undefined,
      status:
        before.status !== after.status
          ? {
              oldValue: before.status,
              newValue: after.status,
            }
          : undefined,
      actualStartDateTime:
        before.actualStartDateTime !== after.actualStartDateTime
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.actualStartDateTime,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.actualStartDateTime'
                )!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.actualStartDateTime,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.actualStartDateTime'
                )!
              ),
            }
          : undefined,
      actualEndDateTime:
        before.actualEndDateTime !== after.actualEndDateTime
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.actualEndDateTime,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.actualEndDateTime'
                )!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.actualEndDateTime,
                viewMeta.functionMeta.properties.byId.get(
                  'project.sprint.actualEndDateTime'
                )!
              ),
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (row: SprintRow, state: SprintState) => {
    return {
      teamUuid: state.teamUuid!,
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (field === 'businessDate') {
      return (params: ValueGetterParams) => {
        if (!params.data) return
        if (!params.data.businessDate && !params.data.businessHour) {
          return '-'
        }
        return intl.formatMessage(
          { id: 'sprint.businessDateHour' },
          {
            businessDate: params.data.businessDate,
            businessHour: params.data.businessHour,
          }
        )
      }
    }
    return
  }

  getSearchCondition = (state: SprintState) => {
    return { teamUuid: state.teamUuid, projectUuid: state.uuid }
  }

  restoreSearchCondition = (
    searchCondition: { teamUuid: string; projectUuid: string },
    ctx: SprintBulkSheetContext
  ) => {
    if (searchCondition.projectUuid === ctx.state.uuid) {
      this.onChangeTeam(searchCondition.teamUuid, ctx)
    }
  }

  refreshConditionAndColumn = (ctx: SprintBulkSheetContext) => {
    this.onChangeTeam('', ctx)
  }
}
