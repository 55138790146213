import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import { UserDetail } from './user'
import { TeamProps } from './team'
import { WbsItemTypeObject } from '../../domain/value-object/WbsItemTypeVO'

export interface WorkReport extends Tree<WorkReport> {
  user: UserDetail
  team: TeamProps
  totalActualHour: number
  totalScheduledHour: number
  work: {
    date: string
    actualHour: number
    scheduledHour: number
    taskCodeList: string
    actualWorks: {
      taskCode: string
      taskName: string
      taskStatus: string
      deliverableName: string
      hour: number
      ticketType: string
      wbsItemType: WbsItemTypeObject
      baseWbsItemType: WbsItemTypeObject
      path: string
    }[]
  }[]
}

export interface WorkReportRequest {
  projectUuid: string
  startDate: string
  endDate: string
}

export function getWorkReport(
  requestBody: WorkReportRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    `/api/v1/projects/work_report`,
    requestBody
  )
}
