import { TwoDimensionalPoint } from '../model/chart'
import { TwoDimensionalData } from '../model/data'
import { DateBucket, DatewiseData } from '../model/timeSeries'

export const aggregateByDateTicks = <T>(
  data: DatewiseData<T>[],
  xTicks: Date[],
  sum: (_: T[]) => number | undefined
): TwoDimensionalPoint<Date, number>[] => {
  const points: TwoDimensionalPoint<Date, number>[] = []
  for (let i = 0; i < xTicks.length; i++) {
    const x = xTicks[i]
    const xFrom = i > 0 ? xTicks[i - 1] : new Date(2000, 0, 1)
    const xTo = x // i + 1 < xTicks.length ? xTicks[i + 1] : new Date(9999, 11, 31)
    const value = sum(
      data.filter(d => d.date > xFrom && d.date <= xTo).map(v => v.value)
    )
    if (typeof value === 'undefined') continue
    points.push({ x, y: value })
  }
  return points
}

export const aggregateByDateBucket = <T>(
  data: DatewiseData<T>[],
  dateBuckets: DateBucket[],
  sum: (_: T[]) => number | undefined
): TwoDimensionalPoint<DateBucket, number>[] => {
  const points: TwoDimensionalPoint<DateBucket, number>[] = []
  for (let i = 0; i < dateBuckets.length; i++) {
    const bucket = dateBuckets[i]
    const { from, to } = bucket
    const value = sum(
      data.filter(d => d.date >= from && d.date < to).map(v => v.value)
    )
    if (typeof value === 'undefined') continue
    points.push({ x: bucket, y: value })
  }
  return points
}

export const calcBurnDown = (
  data: TwoDimensionalData<Date, number>[],
  total: number
): TwoDimensionalData<Date, number>[] => {
  const result: TwoDimensionalData<Date, number>[] = []
  let remaining = total
  for (let d of data) {
    remaining = Math.max(remaining - d.y, 0)
    result.push({
      x: d.x,
      y: remaining,
    })
  }
  return result
}

export const calcBurnDownDateBucket = (
  data: TwoDimensionalData<DateBucket, number>[],
  total: number
): TwoDimensionalData<DateBucket, number>[] => {
  const result: TwoDimensionalData<DateBucket, number>[] = []
  let remaining = total
  for (let d of data) {
    remaining = Math.max(remaining - d.y, 0)
    result.push({
      x: d.x,
      y: remaining,
    })
  }
  return result
}
