import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { TimerRepository } from '../../applications/ports/timerRepository'
import { MeasuringTimer } from '../../domain/entity/TimerEntity'

export const useTimerRepository = (): TimerRepository => {
  const start = useCallback(async (userUuid: string, taskUuid: string) => {
    const response = await api.functional.request(
      'POST',
      '/api/v1/projects/timer/start',
      {
        userUuid,
        taskUuid,
      }
    )
    return response.json.timers
  }, [])

  const stop = useCallback(async (userUuid: string, taskUuid: string) => {
    const response = await api.functional.request(
      'PUT',
      '/api/v1/projects/timer/stop',
      {
        userUuid,
        taskUuid,
      }
    )
    return response.json.timers
  }, [])

  return {
    start,
    stop,
  }
}

export const getMeasuringTimer = async (): Promise<MeasuringTimer> => {
  const response = await api.functional.request(
    'GET',
    '/api/v1/projects/timer/measuring/me'
  )
  return response.json
}
