import { useMemo } from 'react'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import {
  DEFAULT_COLUMN_GROUP_DEF,
  useEstimateColumnGroupDef,
  useEexplanationColumnGroupDef,
  useInformationColumnGroupDef,
  useNameColumnGroupDef,
  getProductivityColumnGroupDef,
  PATH_COLUMN_GROUP_DEF,
  PROGRESS_COLUMN_GROUP_DEF,
  PropsForGetColumnDefs,
  useStoryPointColumnGroupDef,
  CHANGE_LOG_COLUMN_GROUP_DEF,
  useAssignmentColumnGroupDef,
  useTermColumnGroupDef,
} from './common'

export const useGeneralColumnDefs = ({
  projectUuid,
  onClickedStatusCell,
  addRow,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  context,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const informationColumnGroupDef = useInformationColumnGroupDef({
    onClickedStatusCell,
    addRow,
  })
  const nameColumnGroupDef = useNameColumnGroupDef({
    reloadSingleRow,
    submitSingleRow,
    context,
  })
  const explanationColumnGroupDef = useEexplanationColumnGroupDef()
  const assignmentColumnGroupDef = useAssignmentColumnGroupDef(
    projectUuid,
    context
  )
  const storyPointColumnGroupDef = useStoryPointColumnGroupDef()
  const estimateColumnGroupDef = useEstimateColumnGroupDef()
  const termColumnGroupDef = useTermColumnGroupDef()
  const columnDefs = useMemo(
    () => [
      DEFAULT_COLUMN_GROUP_DEF,
      informationColumnGroupDef,
      PATH_COLUMN_GROUP_DEF,
      nameColumnGroupDef,
      explanationColumnGroupDef,
      assignmentColumnGroupDef,
      storyPointColumnGroupDef,
      estimateColumnGroupDef,
      PROGRESS_COLUMN_GROUP_DEF,
      getProductivityColumnGroupDef(onClickedActualHourCell),
      termColumnGroupDef,
      CHANGE_LOG_COLUMN_GROUP_DEF,
    ],
    [
      onClickedActualHourCell,
      informationColumnGroupDef,
      nameColumnGroupDef,
      explanationColumnGroupDef,
      assignmentColumnGroupDef,
      storyPointColumnGroupDef,
      estimateColumnGroupDef,
      termColumnGroupDef,
    ]
  )
  return columnDefs
}
