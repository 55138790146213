import React from 'react'
import _ from 'lodash'
import { ICellRendererParams } from 'ag-grid-community'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import { WbsItemRow } from '../../../../../../../../lib/functions/wbsItem'
import ProjectAPI, {
  ProjectDetail,
} from '../../../../../../../../lib/functions/project'
import { getProjectPlanUuidByWbsItemUuid } from '../../../../../../../../lib/functions/projectPlan'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { WbsItemIcon } from '../../../../../../../components/icons/WbsItemIcon'
import IconTextField from '../../common/iconText'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

interface Props extends ICellRendererParams {
  value: Partial<WbsItemRow>
  data: any
  uiMeta: FunctionProperty
  viewMeta: ViewMeta
}

class WbsItemBasicCellRenderer extends React.Component<Props> {
  canOpenProjectPlan = (): boolean => {
    const wbsItemType: WbsItemTypeVO | undefined = this.props.value?.wbsItemType
    return (
      !!wbsItemType &&
      (wbsItemType.isWorkgroup() ||
        wbsItemType.isProcess() ||
        wbsItemType.isDeliverableList() ||
        (wbsItemType.isTicket() && wbsItemType.isDeliverable()))
    )
  }

  openProjectPlan = async () => {
    if (!this.canOpenProjectPlan()) {
      return
    }

    let project: ProjectDetail | undefined = undefined
    if (this.props.value?.projectUuid) {
      const response = await ProjectAPI.getDetail({
        uuid: this.props.value.projectUuid,
      })
      project = response?.json as ProjectDetail
    }
    if (!project || !this.props.value?.uuid) {
      return
    }

    const projectPlanUuid = await getProjectPlanUuidByWbsItemUuid(
      this.props.value.uuid
    )
    window.open(
      `${window.location.origin}/projectPlan/${project.code}?treeRootUuid=${projectPlanUuid}`
    )
  }

  render() {
    const wbsItem = this.props.value
    return (
      <IconTextField
        icon={<WbsItemIcon type={wbsItem.wbsItemType} />}
        enableTooltip={false}
        onIconClicked={
          this.canOpenProjectPlan() ? this.openProjectPlan : undefined
        }
        value={wbsItem.displayName ?? ''}
        data={this.props.data}
        uiMeta={this.props.uiMeta}
        viewMeta={this.props.viewMeta}
      />
    )
  }
}

export default WbsItemBasicCellRenderer
