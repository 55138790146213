import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Box, IconButton, Paper, Popover } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { DayOfWeek, TimeGrain } from '../../../../lib/functions/report'
import { FontSize, FontWeight } from '../../../../styles/commonStyles'
import { DayOfWeekToggleGroup } from '../../editors/toggle/DayOfWeekToggleGroup'

type OwnProps = {
  popoverAnchorElement?: Element | null
  startDayOfWeek: DayOfWeek
  timeGrain: TimeGrain
  onClose: () => void
  onChange: (value: string) => void
}

type Props = WrappedComponentProps & OwnProps

const SelectStartDayOfWeekDialog: React.FC<Props> = (props: Props) => {
  const {
    popoverAnchorElement,
    intl,
    startDayOfWeek,
    timeGrain,
    onClose,
    onChange,
  } = props

  return (
    <Popover
      open={Boolean(popoverAnchorElement)}
      onClose={() => onClose()}
      anchorEl={popoverAnchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Paper sx={{ padding: '3px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            component={'span'}
            sx={{
              fontSize: FontSize.SMALL,
              fontWeight: FontWeight.BOLD,
            }}
          >
            {intl.formatMessage({ id: 'startDayOfWeek' })}
          </Box>
          <IconButton onClick={() => onClose()} sx={{ padding: '3px' }}>
            <CloseRoundedIcon
              sx={{
                width: '14px !important',
                height: '14px !important',
                color: '#888',
              }}
            />
          </IconButton>
        </Box>
        <DayOfWeekToggleGroup
          value={startDayOfWeek}
          onChange={newValue => onChange(newValue)}
          excludeHoliday={true}
          disabled={![TimeGrain.WEEK, TimeGrain.TWO_WEEKS].includes(timeGrain)}
        />
      </Paper>
    </Popover>
  )
}

export default injectIntl(SelectStartDayOfWeekDialog)
