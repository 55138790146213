import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import { Column, GridApi } from 'ag-grid-community'
import {
  addEventListenerForLastRow,
  dateTimeValueParser,
  dateValueParser,
} from '../../../..'
import DatePicker from 'react-datepicker'
import {
  FunctionProperty,
  PropertyType,
} from '../../../../../../../../lib/commons/appFunction'
import InputBase from '@mui/material/InputBase'
import moment from 'moment'
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATETIME_FORMAT,
} from '../../../../../../../../utils/date'
import {
  DateVO,
  dateVoService,
} from '../../../../../../../../domain/value-object/DateVO'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  backgroundColor: 'inherit',
})
const StyledDatePicker = styled(DatePicker)({
  width: '100%',
  height: '100%',
  border: 'none',
  padding: 0,
  display: 'flex',
  backgroundColor: 'inherit',
  margin: 0,
})

interface Props {
  value: number
  api: GridApi
  keyPress: number
  rowIndex: number
  column: Column
  charPress: any

  uiMeta: FunctionProperty

  getInitialValueOnCalendar?: (params: Props) => DateVO | undefined
}

interface State {
  value?: number
  initialInputValue?: string
  open: boolean
}

const INPUT_DATE_FORMAT = 'yyyy/MM/dd'

class DateCellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  // Check set parse input value or select on calendar.
  // Because internal date-picker process fire onSelect event while edit input value.
  private changedByInput = false
  private tmpValue: number | undefined = undefined
  private showTime: boolean = false
  private displayFormat: string = DISPLAY_DATE_FORMAT
  private valueParser: Function = dateValueParser

  constructor(props: Props) {
    super(props)
    let defaultVal = this.props.value ? moment(this.props.value) : undefined
    if (
      defaultVal &&
      !defaultVal.isValid() &&
      typeof this.props.value === 'string' &&
      !isNaN(this.props.value)
    ) {
      defaultVal = moment(parseInt(this.props.value, 10))
    }
    this.state = {
      value: defaultVal?.toDate().getTime(),
      initialInputValue: '',
      open: false,
    }
    this.setSpec(props.uiMeta.propertyType)
  }

  setSpec = (propertyType: PropertyType) => {
    if (propertyType === PropertyType.DateTime) {
      this.displayFormat = DISPLAY_DATETIME_FORMAT
      this.valueParser = dateTimeValueParser
      this.showTime = true
    }
  }

  getValue = () => {
    if (!this.state.value) {
      return undefined
    }
    return moment(this.state.value).format(this.displayFormat)
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
      addEventListenerForLastRow(this.ref.current, this.props)
    }
    if (
      this.props.keyPress === 46 ||
      this.props.keyPress === 8 /* DELETE or BACKSPACE*/
    ) {
      this.setState({ value: undefined })
      this.onFinishEdit()
    } else {
      if (this.props.charPress === null) {
        // ダブルクリックやF2でfocus Inした場合、元の値を保持したまま編集モードへ
        this.setState(
          {
            initialInputValue: this.state.value
              ? moment(this.state.value).format(this.displayFormat)
              : '',
          },
          () => {
            this.setState({ initialInputValue: undefined })
          }
        )
      } else {
        // focusされた状態から文字入力をしてfocus Inした場合、値をクリアして編集モードへ
        this.setState(
          {
            initialInputValue: this.props.charPress,
          },
          () => {
            this.setState({ initialInputValue: undefined })
          }
        )
      }
    }
    this.setState({ open: true })
  }

  private onChange = (value: Date) => {
    if (!this.changedByInput) {
      this.setState({
        value: value ? value.getTime() : undefined,
      })
    }
  }

  private onSelect = (value: Date) => {
    // Not set state.value if fired event while edit input value
    if (!this.changedByInput) {
      this.setState({
        value: value ? value.getTime() : undefined,
      })
    } else {
      this.changedByInput = false
    }
  }

  private onChangeCapture = event => {
    if (this.ref.current) {
      const value = this.valueParser(this.ref.current.value, this.props.uiMeta)
      if (value) {
        this.tmpValue = moment(value.replace(/\//g, '-')).toDate().getTime()
        this.changedByInput = true
        if (!isNaN(this.tmpValue) && this.tmpValue !== this.state.value) {
          this.setState(
            {
              value: this.tmpValue,
            },
            () => {
              this.changedByInput = false
            }
          )
        }
      }
    }
  }

  private onButtonPressed = event => {
    this.setState({ open: false }, this.onFinishEdit)
  }

  private getContainer = ({ className, children }) => {
    if (!this.showTime) {
      return <div className={className}>{children}</div>
    }
    return (
      <div className={className}>
        {children}
        <Button
          style={{
            display: 'block',
            width: '100%',
            borderTop: '1px solid #aeaeae',
            borderRadius: 0,
            backgroundColor: '#f0f0f0',
          }}
          onClick={this.onButtonPressed}
        >
          OK
        </Button>
      </div>
    )
  }

  private onFinishEdit = () => {
    let focusedCell = this.props.api.getFocusedCell()
    this.props.api.clearFocusedCell()
    this.props.api.setFocusedCell(focusedCell!.rowIndex, focusedCell!.column)
  }

  private handleKeyDown = event => {
    if (event.key === 'Enter') {
      let nextRowIndex =
        this.props.api.getLastDisplayedRow() !== this.props.rowIndex
          ? this.props.rowIndex + 1
          : this.props.rowIndex
      this.props.api.stopEditing()
      this.props.api.clearRangeSelection()
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(nextRowIndex, this.props.column)
    }
  }
  render() {
    const initialValueOnCalendar = this.props.getInitialValueOnCalendar
      ? this.props.getInitialValueOnCalendar(this.props)
      : undefined

    return (
      <RootDiv>
        <StyledDatePicker
          open={this.state.open}
          selected={
            this.state.value ||
            (initialValueOnCalendar
              ? dateVoService.toNumberValue(initialValueOnCalendar)
              : undefined)
          }
          dateFormat={INPUT_DATE_FORMAT}
          onChange={this.onChange}
          onSelect={this.onSelect}
          onCalendarClose={this.onFinishEdit}
          onKeyDown={this.handleKeyDown}
          showTimeInput={this.showTime}
          disabledKeyboardNavigation={true}
          shouldCloseOnSelect={!this.showTime}
          customInput={
            <InputBase
              inputRef={this.ref}
              autoFocus={true}
              inputProps={{
                value: this.state.initialInputValue,
              }}
              onChangeCapture={this.onChangeCapture}
            />
          }
          calendarContainer={this.getContainer}
          fixedHeight={true}
        />
      </RootDiv>
    )
  }
}

export default DateCellEditor
