import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { UserBasic } from '../../../../domain/value-object/UserBasicVO'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { APIResponse } from '../../../../lib/commons/api'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'
import { ProjectPlanCumulation } from '../../../../lib/functions/projectPlan'
import { isTagColumnEdited } from '../../../../lib/functions/tag'
import TicketApi, {
  CreateTicketInput,
  UpdateTicketDeltaInput,
} from '../../../../lib/functions/ticket'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import { WbsItemBasic, WbsItemDetail } from '../../../../lib/functions/wbsItem'
import Workload from '../../../../lib/functions/workload'
import store from '../../../../store'
import { CommentSummary } from '../../../../store/comments'
import { AttachmentSummary } from '../../../../utils/attachment'
import { generateUuid } from '../../../../utils/uuids'
import {
  deserializeExtensionValue,
  EntityExtensionValue,
} from '../../../containers/meta/entityExtension'
import { TicketRow, TicketWbsItemRow } from '../tickets'
import {
  GenerateCreateRequestParams,
  generateUpdateWathersRequest,
  gernerateTicketDeleteRequest,
  generateCreateRequest,
  TicketDataManager,
  generateUpdateDeltaBatchRequest,
  GenerateUpdateRequestParams,
} from '.'

type FetchTicketsResponse = {
  uuid: string
  lockVersion: number
  ticketType: string
  wbsItem: WbsItemDetail
  ticketList: TicketListBasic
  parentWbsItem: WbsItemBasic
  parentUuid?: string
  prevSiblingUuid?: string
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  extensions?: EntityExtensionValue[]
  parentPath?: string
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

const getUuid = (row: TicketRow): string => {
  return row.uuid
}

const getByTicketListUuid = async (params: {
  ticketListUuid: string
}): Promise<APIResponse> => TicketApi.getByTicketListUuid(params)

const getDetail = async (params: { uuid: string }): Promise<APIResponse> =>
  TicketApi.getDetail(params)

export const createRowByResponse = (
  response: any,
  dailyWorkHours: number,
  monthlyWorkDays: number,
  extensionProperties: FunctionProperty[] | undefined
): TicketRow => {
  const ticketResponse: FetchTicketsResponse = response
  const extensions =
    extensionProperties && ticketResponse.extensions
      ? ticketResponse.extensions?.map((ext: EntityExtensionValue) => {
          const extProp = extensionProperties.find(
            (prop: FunctionProperty) => prop.entityExtensionUuid === ext.uuid
          )
          return extProp
            ? {
                uuid: ext.uuid,
                value: deserializeExtensionValue(ext.value, extProp),
              }
            : ext
        })
      : ticketResponse.extensions
  return {
    ...ticketResponse,
    treeValue: [ticketResponse.uuid],
    wbsItem: {
      ...ticketResponse.wbsItem,
      wbsItemType: ticketResponse.wbsItem?.typeDto
        ? new WbsItemTypeVO(ticketResponse.wbsItem.typeDto)
        : store
            .getState()
            .project.wbsItemTypes.get(ticketResponse.wbsItem.type)!,
      baseWbsItemType: ticketResponse.wbsItem?.baseTypeDto
        ? new WbsItemTypeVO(ticketResponse.wbsItem.baseTypeDto)
        : store
            .getState()
            .project.wbsItemTypes.get(ticketResponse.wbsItem.type)!,
      ticketType:
        ticketResponse.wbsItem?.ticketType === CUSTOM_ENUM_NONE
          ? undefined
          : ticketResponse.wbsItem.ticketType,
      status: ticketResponse.wbsItem?.status
        ? (ticketResponse.wbsItem.status as WbsItemStatus)
        : undefined,
      substatus:
        ticketResponse.wbsItem?.substatus === CUSTOM_ENUM_NONE
          ? undefined
          : ticketResponse.wbsItem.substatus,
      priority:
        ticketResponse.wbsItem?.priority === CUSTOM_ENUM_NONE
          ? undefined
          : ticketResponse.wbsItem?.priority,
      difficulty:
        ticketResponse.wbsItem?.difficulty === CUSTOM_ENUM_NONE
          ? undefined
          : ticketResponse.wbsItem.difficulty,
      description: ticketResponse.wbsItem?.description ?? '',
      tags: ticketResponse.wbsItem?.tags,
      sprint: ticketResponse.wbsItem?.sprint,
      estimatedWorkload: Workload.from({
        hour: ticketResponse.wbsItem?.estimatedHour,
        standard: {
          dailyWorkHours,
          monthlyWorkDays,
        },
      }),
    },
    extensions,
  } as TicketRow
}

const updateBatchDelta = async (request: any): Promise<APIResponse> => {
  return TicketApi.updateBatchDelta(request)
}

const createUpdateDeltaBatchRequest = (
  projectUuid: string,
  originalData: TicketRow[],
  currentData: TicketRow[],
  deletedRows: TicketRow[]
) => {
  const changed = currentData.filter(row => row.added || row.edited)

  const added = generateCreateRequest<TicketRow, CreateTicketInput>(
    projectUuid,
    currentData,
    changed,
    ({
      ticketRequest,
    }: GenerateCreateRequestParams<TicketRow>):
      | CreateTicketInput
      | undefined => {
      return ticketRequest
    }
  )

  const edited = generateUpdateDeltaBatchRequest<
    TicketRow,
    UpdateTicketDeltaInput
  >(
    originalData,
    currentData,
    changed,
    ({
      ticketRequest,
    }: GenerateUpdateRequestParams<TicketRow>):
      | UpdateTicketDeltaInput
      | undefined => {
      return ticketRequest
    }
  )

  const deleted = gernerateTicketDeleteRequest(
    deletedRows,
    (row: TicketRow) => {
      return {
        uuid: row.uuid,
        lockVersion: row.lockVersion,
      }
    }
  )
  const watchers = generateUpdateWathersRequest(originalData, changed)

  const tags = changed
    .filter((row: TicketRow) => {
      const original =
        originalData.find(v => v.uuid === row.uuid)?.wbsItem?.tags ?? []
      const newData = row.wbsItem?.tags ?? []
      return (
        (row.added || row.edited) &&
        row.wbsItem &&
        isTagColumnEdited(original, newData)
      )
    })
    .map((row: TicketRow) => {
      const wbsItem: TicketWbsItemRow = row.wbsItem!
      return {
        uuid: row.uuid,
        wbsItemUuid: wbsItem.uuid,
        tagUuids:
          wbsItem.tags && Array.isArray(wbsItem.tags)
            ? wbsItem.tags.map(v => v.uuid)
            : [],
      }
    })

  return {
    added,
    edited,
    deleted,
    watchers,
    tags,
  }
}

export const createNewTicketRow = (ticketList: TicketListBasic): TicketRow => {
  const uuid = generateUuid()
  const project = store.getState().project
  const wbsItemType = project.ticketTypes.find(
    v => v.code === ticketList.ticketType
  )!
  return {
    treeValue: [uuid],
    uuid,
    lockVersion: 0,
    ticketList: {
      uuid: ticketList.uuid,
      code: ticketList.code,
      wbsItemUuid: ticketList.wbsItemUuid,
      ticketType: ticketList.ticketType,
      projectUuid: ticketList.projectUuid,
      displayName: ticketList.displayName,
    },
    wbsItem: {
      projectUuid: project.selected,
      uuid: generateUuid(),
      code: Math.random().toString(36).slice(-8).toUpperCase(),
      type: WbsItemType.TASK,
      wbsItemType: wbsItemType,
      baseWbsItemType: wbsItemType,
      ticketType: ticketList.ticketType,
      ticketListUuid: ticketList.uuid,
      status: WbsItemStatus.TODO,
      scheduledDate: { startDate: undefined, endDate: undefined },
      actualDate: { startDate: undefined, endDate: undefined },
    },
    cumulation: new ProjectPlanCumulation(),
    added: true,
  }
}

export const getGeneralTicketDataManager = (): TicketDataManager => {
  return {
    getUuid,
    getByTicketListUuid,
    getDetail,
    createRowByResponse,
    updateBatchDelta,
    createUpdateDeltaBatchRequest,
    createNewRow: createNewTicketRow,
  }
}
