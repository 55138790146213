import { TicketType } from '../../../../lib/functions/ticket'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import { TicketDataManager } from '../dataManager'
import { TicketRow } from '../tickets'
import { ExcelParser } from './general'
import { RefinementExcelParser } from './refinement'
import { RiskExcelParser } from './risk'

const getParser = (ticketType: TicketType) => {
  switch (ticketType) {
    case TicketType.RISK:
      return new RiskExcelParser()
    case TicketType.REFINEMENT_NEW:
      return new RefinementExcelParser()
    default:
      return new ExcelParser()
  }
}

export const getExcelParser = ({
  ticketType,
  excelSrouce,
  data,
  context,
  dataManager,
  defaultTicketList,
}: {
  ticketType: TicketType
  excelSrouce: object[]
  data: TicketRow[]
  context: object
  dataManager: TicketDataManager | undefined
  defaultTicketList: TicketListBasic | undefined
}) => {
  const parser = getParser(ticketType)
  parser.initialize(
    excelSrouce,
    { data, context },
    dataManager,
    defaultTicketList
  )
  return parser
}
