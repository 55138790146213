import React, { useCallback, useMemo } from 'react'
import { Box, Divider, Link, Typography } from '@mui/material'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../../../lib/functions/workload'
import { FontWeight } from '../../../../../../styles/commonStyles'
import { intl } from '../../../../../../i18n'
import { fromSnakeToCamel } from '../../../../../../utils/string'
import { AggregateValueType } from '../../../../../containers/AggregateInformation'
import { ProjectPlanCumulation } from '../../../../../../lib/functions/projectPlan'
import { useAggregateInformation } from './useAggregateInformation'
import { openWbsItemSearch } from '../../../../WbsItemSearch/wbsItemSearchOptions'
import { SearchFilter } from '../../../../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { AllState } from '../../../../../../store'
import { connect } from 'react-redux'
import { WbsItemStatus } from '../../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import DateVO from '../../../../../../vo/DateVO'
import { useWorkloadUnit } from '../../../../../hooks/useWorkloadUnit'
import { colorPalette } from '../../../../../style/colorPallete'

export const AggregateInformation = ({
  rootUuid,
  cumulation,
  aggregateField,
  aggregateTarget,
  workloadUnit,
}: {
  rootUuid?: string
  aggregateField: AggregateField
  aggregateTarget: AggregateTarget
  workloadUnit: WorkloadUnit
  cumulation: ProjectPlanCumulation
}) => {
  const data = useAggregateInformation(cumulation)
  const stats = useMemo(() => {
    return aggregateField === AggregateField.WBS_ITEM_WORKLOAD
      ? aggregateTarget === WbsItemType.DELIVERABLE
        ? data.deliverableWorkload
        : data.taskWorkload
      : aggregateTarget === WbsItemType.DELIVERABLE
      ? data.deliverableCount
      : data.taskCount
  }, [data, aggregateField, aggregateTarget])
  const { convertWorkloadFromHourToSelectedUnit } =
    useWorkloadUnit(workloadUnit)

  const formatValue = useCallback(
    (value?: number) => {
      if (value === undefined) return '-'
      if (aggregateField === AggregateField.WBS_ITEM_COUNT) return value
      return `${convertWorkloadFromHourToSelectedUnit(value).toFixed(1)}`
    },
    [aggregateField, convertWorkloadFromHourToSelectedUnit]
  )

  const formatWorkload = useCallback(
    (value?: number) => {
      if (value === undefined) return '-'
      return `${convertWorkloadFromHourToSelectedUnit(value).toFixed(1)}`
    },
    [convertWorkloadFromHourToSelectedUnit]
  )

  const formatRate = useCallback((value?: number) => {
    if (value === undefined || Number.isNaN(value)) return '-'
    return `${(value * 100).toFixed(1)}%`
  }, [])

  const wbsItemQuery = useCallback(
    (type: AggregateValueType): Partial<SearchFilter> | undefined => {
      const baseQuery = { rootUuid, types: [aggregateTarget] }
      switch (type) {
        case AggregateValueType.BUDGET_AT_COMPLETION:
          return {
            ...baseQuery,
            status: [
              WbsItemStatus.TODO,
              WbsItemStatus.DOING,
              WbsItemStatus.REVIEW,
              WbsItemStatus.DONE,
            ],
          }
        case AggregateValueType.PLANNED_VALUE:
          return {
            ...baseQuery,
            status: [
              WbsItemStatus.TODO,
              WbsItemStatus.DOING,
              WbsItemStatus.REVIEW,
              WbsItemStatus.DONE,
            ],
            scheduledEndDate: {
              to: DateVO.now(),
            },
          }
        case AggregateValueType.EARNED_VALUE:
          return {
            ...baseQuery,
            status: [WbsItemStatus.DONE],
          }
        case AggregateValueType.PRECEDING:
          return {
            ...baseQuery,
            status: [WbsItemStatus.DONE],
            scheduledEndDate: {
              from: DateVO.now().addDays(1),
            },
          }
        case AggregateValueType.DELAYED:
          return {
            ...baseQuery,
            status: [
              WbsItemStatus.TODO,
              WbsItemStatus.DOING,
              WbsItemStatus.REVIEW,
            ],
            scheduledEndDate: {
              to: DateVO.now().subtractDays(1),
            },
          }
        case AggregateValueType.REMAINING:
          return {
            ...baseQuery,
            status: [
              WbsItemStatus.TODO,
              WbsItemStatus.DOING,
              WbsItemStatus.REVIEW,
            ],
          }
        case AggregateValueType.UNPLANNED:
          return {
            ...baseQuery,
            status: [
              WbsItemStatus.TODO,
              WbsItemStatus.DOING,
              WbsItemStatus.REVIEW,
              WbsItemStatus.DONE,
            ],
            blank: { scheduledEndDate: true },
          }
      }
      return undefined
    },
    [rootUuid, aggregateTarget]
  )

  if (!stats) return <></>
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.estimatedProgressRate' })}
            </Typography>
            <StatData
              type={AggregateValueType.ESTIMATED_PROGRESS_RATE}
              fontWeight={700}
            >
              {formatRate(stats.estimatedProgressRate)}
            </StatData>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.progressRate' })}
            </Typography>
            <StatData type={AggregateValueType.PROGRESS_RATE} fontWeight={700}>
              {formatRate(stats.progressRate)}
            </StatData>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '60px',
            }}
          >
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.budgetAtCompletion' })}
            </Typography>
            <StatData
              type={AggregateValueType.BUDGET_AT_COMPLETION}
              key={`stat-data-${AggregateValueType.BUDGET_AT_COMPLETION}`}
              query={wbsItemQuery(AggregateValueType.BUDGET_AT_COMPLETION)}
              fontWeight={700}
            >
              {formatValue(stats.budgetAtCompletion)}
            </StatData>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.plannedValue' })}
            </Typography>
            <StatData
              type={AggregateValueType.PLANNED_VALUE}
              key={`stat-data-${AggregateValueType.PLANNED_VALUE}`}
              query={wbsItemQuery(AggregateValueType.PLANNED_VALUE)}
              fontWeight={700}
            >
              {formatValue(stats.plannedValue)}
            </StatData>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.earnedValue' })}
            </Typography>
            <StatData
              type={AggregateValueType.EARNED_VALUE}
              key={`stat-data-${AggregateValueType.EARNED_VALUE}`}
              query={wbsItemQuery(AggregateValueType.EARNED_VALUE)}
              fontWeight={700}
            >
              {formatValue(stats.earnedValue)}
            </StatData>
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              margin: '0 10px',
              color: colorPalette.monotone[2],
              width: '2px',
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.preceding' })}
            </Typography>
            <StatData
              type={AggregateValueType.PRECEDING}
              key={`stat-data-${AggregateValueType.PRECEDING}`}
              query={wbsItemQuery(AggregateValueType.PRECEDING)}
              fontWeight={700}
            >
              {formatValue(stats.preceding)}
            </StatData>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.delayed' })}
            </Typography>
            <StatData
              type={AggregateValueType.DELAYED}
              key={`stat-data-${AggregateValueType.DELAYED}`}
              query={wbsItemQuery(AggregateValueType.DELAYED)}
              fontWeight={700}
            >
              {formatValue(stats.delayed)}
            </StatData>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '60px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.remaining' })}
            </Typography>
            <StatData
              type={AggregateValueType.REMAINING}
              key={`stat-data-${AggregateValueType.REMAINING}`}
              query={wbsItemQuery(AggregateValueType.REMAINING)}
              fontWeight={700}
            >
              {formatValue(stats.remaining)}
            </StatData>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '60px',
              minWidth: 'fit-content',
            }}
          >
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.unplanned' })}
            </Typography>
            <StatData
              type={AggregateValueType.UNPLANNED}
              key={`stat-data-${AggregateValueType.UNPLANNED}`}
              query={wbsItemQuery(AggregateValueType.UNPLANNED)}
              fontWeight={700}
            >
              {formatValue(stats.unplanned)}
            </StatData>
          </Box>
          <Divider
            orientation="vertical"
            sx={{
              margin: '0 10px',
              color: colorPalette.monotone[2],
              width: '2px',
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '80px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.actualCost' })}
            </Typography>
            <StatData
              type={AggregateValueType.ACTUAL_COST}
              key={`stat-data-${AggregateValueType.ACTUAL_COST}`}
              query={wbsItemQuery(AggregateValueType.ACTUAL_COST)}
              fontWeight={700}
            >
              {formatWorkload(stats.actualCost)}
            </StatData>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '80px' }}>
            <Typography
              sx={{
                margin: '0 10px',
                color: colorPalette.monotone[4],
                fontSize: '12px',
                fontWeight: 400,
              }}
            >
              {intl.formatMessage({ id: 'wbs.header.costPerformanceIndex' })}
            </Typography>
            <StatData
              type={AggregateValueType.COST_PERFORMANCE_INDEX}
              key={`stat-data-${AggregateValueType.COST_PERFORMANCE_INDEX}`}
              query={wbsItemQuery(AggregateValueType.COST_PERFORMANCE_INDEX)}
              fontWeight={700}
            >
              {formatRate(stats.costPerformanceIndex)}
            </StatData>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
})

const StatData = connect(mapStateToProps)(
  ({
    type,
    query,
    projectUuid,
    children,
    fontWeight,
  }: {
    type: AggregateValueType
    query?: Partial<SearchFilter>
    projectUuid?: string
    children: React.ReactNode
    fontWeight?: number
  }) => {
    return (
      <div
        key={`stat-cell-${type}`}
        style={{
          maxWidth: 60,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          wordBreak: 'break-all',
          padding: 0,
          textAlign: 'center',
          overflow: 'hidden',
          fontSize: 14,
          borderBottom: 'none',
          fontWeight: fontWeight,
          color: colorPalette.monotone[10],
        }}
      >
        {projectUuid && query ? (
          <Link
            sx={{
              cursor: 'pointer',
              color: colorPalette.monotone[10],
              textDecorationColor: colorPalette.monotone[10],
            }}
            onClick={() => openWbsItemSearch(projectUuid, query)}
          >
            {children}
          </Link>
        ) : (
          children
        )}
      </div>
    )
  }
)
