import {
  ColumnState,
  GridOptions,
  RowDataUpdatedEvent,
  RowDragEvent,
  RowHeightParams,
} from 'ag-grid-community'
import { BulkSheetView } from '../../../containers/BulkSheetView'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { useCallback, useRef } from 'react'
import {
  ProfitLossItemRow,
  ProfitLossRowBase,
  ProfitLossRowBodyBase,
} from '../profitLossItems'
import Loading from '../../../components/process-state-notifications/Loading'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import { useDragTreeStyle } from '../../../containers/BulkSheetView/hooks/gridEvents/treeRowDrag'
import { sortByRowIndex } from '../../../containers/BulkSheetView/lib/rowNode'
import {
  moveRowsToLastChild,
  slideRowsAfter,
  slideRowsBefore,
} from '../../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import { LedgerAccountsTree } from '../../LedgerAccounts/ledgerAccounts'
import { AgGridReactProps } from 'ag-grid-react'
import ColumnSettingPopper from '../../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { CancelConfirmState, CancelConfirmStateType } from '.'

interface OwnProps<
  TRow extends ProfitLossRowBase<TRowBody>,
  TRowBody extends ProfitLossRowBodyBase
> {
  gridOptions: GridOptions
  loading: boolean
  data: TRow[]
  setData: (data: TRow[]) => void
  rowHeight: ROW_HEIGHT
  cancelConfirmState: CancelConfirmStateType
  setCancelConfirmState: (state: CancelConfirmStateType) => void
  onCancelConfirm: (state: CancelConfirmStateType) => void
  accountCategory: string | undefined
  masterTree: LedgerAccountsTree[]
  summaryRows: TRow[]
  createRow?: (
    financialState: LedgerAccountsTree,
    generalLedger: LedgerAccountsTree
  ) => TRow[]
  applicationFunctionUuid: string
  columnSetting: any
  columnState: ColumnState[]
  openSavedUiStateDialog: () => void
  initializeColumnState: () => void
}

type Props<
  TRow extends ProfitLossRowBase<TRowBody>,
  TRowBody extends ProfitLossRowBodyBase
> = OwnProps<TRow, TRowBody> & AgGridReactProps

const ProfitLossItemsTreeView = <
  TRow extends ProfitLossRowBase<TRowBody>,
  TRowBody extends ProfitLossRowBodyBase
>({
  gridOptions,
  loading,
  data,
  setData,
  rowHeight,
  cancelConfirmState,
  setCancelConfirmState,
  onCancelConfirm,
  summaryRows,
  onColumnMoved,
  onColumnResized,
  onColumnVisible,
  onFilterChanged,
  onFirstDataRendered,
  onGridReady,
  onRowGroupOpened,
  onSortChanged,
  onCellValueChanged,
  getContextMenuItems,
  isExternalFilterPresent,
  doesExternalFilterPass,
  applicationFunctionUuid,
  columnSetting,
  columnState,
  openSavedUiStateDialog,
  initializeColumnState,
}: Props<TRow, TRowBody>) => {
  const ref = useRef<HTMLDivElement>(null)

  const onCancelConfirmCallback = useCallback(() => {
    setCancelConfirmState(CancelConfirmState.Close)
    onCancelConfirm(cancelConfirmState)
  }, [cancelConfirmState, setCancelConfirmState, onCancelConfirm])

  const acceptChild = (
    parent: ProfitLossItemRow,
    child: ProfitLossItemRow
  ): boolean => {
    return false
  }
  const dragStyle = useDragTreeStyle(ref, acceptChild, gridOptions)

  const onRowDragEnd = useCallback(
    (event: RowDragEvent) => {
      const ids = event.nodes.map(v => v.id)
      const topLevelNodes = event.nodes.filter(n => !ids.includes(n.parent?.id))
      const movedData = sortByRowIndex(topLevelNodes).map(node => node.data)
      const overNode = event.overNode
      if (overNode && overNode.id === gridOptions.context.draggableNodeId) {
        try {
          if (gridOptions.context.onTree) {
            const result = moveRowsToLastChild(data, movedData, overNode.id)
            setData(result)
            overNode.setExpanded(true)
          } else {
            if (event.overIndex > (event.node.rowIndex || 0)) {
              setData(slideRowsAfter(data, movedData, overNode.id!))
            } else {
              setData(slideRowsBefore(data, movedData, overNode.id!))
            }
          }
        } finally {
          dragStyle.refreshDragStyle()
        }
      }
    },
    [data, dragStyle, gridOptions, setData]
  )

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<ProfitLossItemRow>) => {
      e.api?.refreshCells({ columns: ['rowNumber'], force: true })
    },
    []
  )

  const getRowHeight = useCallback(
    (params: RowHeightParams): number => {
      if (!!params.data.isTotal) return ROW_HEIGHT.SMALL
      return rowHeight
    },
    [rowHeight]
  )

  return (
    <>
      <BulkSheetView
        ref={ref}
        rowData={data}
        gridOptions={gridOptions}
        getRowHeight={getRowHeight}
        getContextMenuItems={getContextMenuItems}
        onRowDragEnter={dragStyle.onRowDragEnter}
        onRowDragMove={dragStyle.onRowDragMove}
        onRowDragLeave={dragStyle.refreshDragStyle}
        onRowDragEnd={onRowDragEnd}
        onRowDataUpdated={onRowDataUpdated}
        onColumnMoved={onColumnMoved}
        onColumnResized={onColumnResized}
        onColumnVisible={onColumnVisible}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onRowGroupOpened={onRowGroupOpened}
        onSortChanged={onSortChanged}
        pinnedTopRowData={summaryRows}
        onCellValueChanged={onCellValueChanged}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <CancelConfirmDialog
        open={CancelConfirmState.Close !== cancelConfirmState}
        onConfirm={onCancelConfirmCallback}
        onClose={() => {
          setCancelConfirmState(CancelConfirmState.Close)
        }}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={openSavedUiStateDialog}
        initializeColumnState={initializeColumnState}
        applicationFunctionUuid={applicationFunctionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={columnState}
        offset={90}
      />
    </>
  )
}

export default ProfitLossItemsTreeView
