import _ from 'lodash'

class Objects {
  getValue = (obj: any, path?: string) => {
    if (!obj || !path) {
      return undefined
    }
    const dot = path.indexOf('.')
    if (dot < 0) {
      return obj[path]
    }
    return this.getValue(obj[path.substr(0, dot)], path.substr(dot + 1))
  }

  setValue = (obj: any, path: string, value: any) => {
    if (typeof obj !== 'object') return
    const dot = path.indexOf('.')
    if (dot < 0) {
      obj[path] = value
      return
    }
    const parentPath = path.substr(0, dot)
    let parent = obj[parentPath]
    if (parent !== 0 && !parent) {
      parent = {}
      obj[parentPath] = parent
    }
    return this.setValue(parent, path.substr(dot + 1), value)
  }

  hasDiff = (a: any, b: any): boolean => {
    return JSON.stringify(a) !== JSON.stringify(b)
  }

  unflatten = (obj: any): any => {
    let result = {}
    for (let [key, value] of Object.entries(obj)) {
      this.setValue(result, key, value)
    }
    return result
  }

  isEmpty = (obj?: any): boolean => {
    if (obj === undefined || obj === null) {
      return true
    }
    if (typeof obj === 'object' || typeof obj === 'function') {
      return _.isEmpty(obj)
    }
    return false
  }

  removeEmpty = (src: any): any => {
    if (typeof src !== 'object') {
      return src
    }
    const dest = {}
    for (let entry of Object.entries(src)) {
      const key = entry[0]
      const value = entry[1]
      if (!value) continue
      this.setValue(dest, key, value)
    }
    return dest
  }

  applyChange = (target: any, diff: { [key: string]: any }): any => {
    for (const [key, value] of Object.entries(diff)) {
      this.setValue(target, key, value)
    }
    return target
  }

  /**
   *
   * @param obj
   * @param path
   */
  hasPath = (obj: any, path: string): boolean => {
    const splittedPath = path.split('.')
    let directObj = obj
    for (let i = 0; i < splittedPath.length; i++) {
      if (!(typeof directObj === 'object')) {
        return false
      }
      const directPath = splittedPath[i]
      if (!(directPath in directObj)) {
        return false
      }
      directObj = directObj[directPath]
    }
    return true
  }
}

export default new Objects()
