import React from 'react'
import HeaderCell from './HeaderCell'
import { CellDef } from '../../../meta/ViewMeta/SingleSheetViewMeta'
import DataCell from './DataCell'
import { ValidationError } from '../../../meta/validator'
import { TableData } from '..'
import { VO } from '../../../../../vo'

export interface FormCellProps<D extends TableData> {
  value: VO
  initialValue: VO
  data: D
  colSpan: number
  cellDef: CellDef
  onChange: (value: any) => void
  setError?: (error: ValidationError | undefined) => void
}

const FormCell = React.memo(<D extends TableData>(props: FormCellProps<D>) => {
  const { data, colSpan, cellDef, value, onChange, setError, initialValue } =
    props
  return (
    <>
      <HeaderCell
        title={cellDef.headerName}
        required={
          typeof cellDef.required === 'boolean'
            ? cellDef.required
            : cellDef.required(data)
        }
        message={cellDef.uiMeta.message}
      />
      <DataCell
        value={value}
        initialValue={initialValue}
        data={data}
        colSpan={colSpan}
        cellDef={cellDef}
        onChange={onChange}
        setError={setError}
      />
    </>
  )
})

export default FormCell
