import CustomEnumRepository from '../../infrastructure/repository/CustomEnumRepository'
import ICustomEnumRepository from './CustomEnumRepository'
import ManagementNoticeRepository from '../../infrastructure/repository/ManagementNoticeRepository'
import IManagementNoticeRepository from './ManagementNoticeRepository'
import IWbsItemTypeRepository from './WbsItemRepository'
import WbsItemTypeRepository from '../../infrastructure/repository/WbsItemTypeRepository'

const customEnumRepository: ICustomEnumRepository = new CustomEnumRepository()

const managementNoticeRepository: IManagementNoticeRepository =
  new ManagementNoticeRepository()

const wbsItemTypeRepository: IWbsItemTypeRepository =
  new WbsItemTypeRepository()

export {
  customEnumRepository,
  managementNoticeRepository,
  wbsItemTypeRepository,
}
