import { ValueSetterParams } from 'ag-grid-community'
import { getParentNode, refreshAncestors } from '../../../..'
import SelectCellValueSetter from '../../common/select/cellValueSetter'

export default (params: ValueSetterParams) => {
  if (!SelectCellValueSetter(params)) {
    return false
  }

  // Refresh aggregate at ancestor nodes
  const statusFieldName = params.colDef.field || ''
  const refreshFieldNames = [statusFieldName].concat(
    params.colDef.cellEditorParams.refreshFieldNames
  )
  params.node &&
    params.api.refreshCells({
      rowNodes: [params.node],
      columns: refreshFieldNames,
      force: true,
    })
  params.node &&
    refreshAncestors(params.api, refreshFieldNames, getParentNode(params.node))
  return true
}
