import { useMemo } from 'react'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ReferencedEntity } from '../../../../../domain/value-object/ReferencedEntity'
import { Autocomplete } from '../../../../components/inputs/Autocomplete'
import { renderInputWithIcon } from '../../../../components/inputs/Autocomplete/variants'

type AutocompleteCellProps<Entity extends ReferencedEntity> = {
  value: Entity | undefined
  onChange?: (v: Entity | undefined) => void
  search: (input: string) => Promise<Entity[]>
  displayIcon?: boolean
} & CellPropsBase

export const AutocompleteCell = <Entity extends ReferencedEntity>({
  cellWidth,
  label,
  value,
  onChange,
  search,
  editable = true,
  required = false,
  displayIcon = false,
}: AutocompleteCellProps<Entity>) => {
  const renderInput = useMemo(
    () => (displayIcon ? renderInputWithIcon : undefined),
    [displayIcon]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <Autocomplete
          value={value}
          onChange={onChange}
          search={search}
          renderInput={renderInput}
          disabled={!editable}
        />
      </CellValueArea>
    </FormCell>
  )
}
