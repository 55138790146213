import { useEffect, useMemo } from 'react'
import { useWbsItemLabel } from '../../label'
import { intl } from '../../../../../../i18n'

export const useRiskLabel = () => {
  const labelMap = useMemo(() => new Map<string, string>(), [])
  const wbsItemLabelMap = useWbsItemLabel()
  useEffect(() => {
    for (let [key, value] of wbsItemLabelMap.entries()) {
      labelMap.set(key, value)
    }
    labelMap.set('riskType', intl.formatMessage({ id: 'risks.riskType' }))
    labelMap.set('score', intl.formatMessage({ id: 'risks.score' }))
    labelMap.set('probability', intl.formatMessage({ id: 'risks.probability' }))
    labelMap.set('impact', intl.formatMessage({ id: 'risks.impact' }))
    labelMap.set('strategy', intl.formatMessage({ id: 'risks.strategy' }))
  }, [labelMap, wbsItemLabelMap])
  return labelMap
}
