import {
  CellKeyDownEvent,
  GridOptions,
  ICellEditorParams,
  ICellRendererParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { useColumnDefs } from './columnDefs'
import { WbsItemAdditionalPropertyNameCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/WbsItemAdditionalPropertyNameCellRenderer'
import {
  AdditionalPropertyRow,
  getName,
  GroupHeaderRow,
  isAdditionalPropertyRow,
  isGroupHeaderRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { propertyName } from '../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'
import { useMemo } from 'react'
import {
  I18nLabelVO,
  i18nLabelVoService,
} from '../../../../domain/value-object/I18nLabelVO'
import { I18nLabelCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/I18nLabelCellEditor'
import { intl } from '../../../../i18n'

export const useWbsItemAdditionalPropertyGridOptions = (): GridOptions => {
  const columnDefs = useColumnDefs()
  const gridOptions = useMemo(
    () => ({
      groupHeaderHeight: 25,
      headerHeight: 45,
      rowHeight: 32,
      treeData: false,
      context: {},
      suppressLastEmptyLineOnPaste: true,
      // Row
      rowDragManaged: true,
      rowDragMultiRow: true,
      suppressMoveWhenRowDragging: true,
      enterMovesDownAfterEdit: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: {
        width: 85,
        editable: false,
        enableValue: false,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        suppressSizeToFit: true,
        singleClickEdit: false,
        cellEditor: 'textEditor',
        cellStyle: { justifyContent: 'left' },
        cellRenderer: DefaultCellRenderer,
        onCellClicked: defaultOnCellClicked,
        suppressPaste: params => !_.isEmpty(params.context?.copied),
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          return (
            !params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)
          )
        },
        valueSetter: (params: ValueSetterParams) => {
          const field = params.colDef.field || params.colDef.colId
          if (
            !field ||
            (!params.oldValue && !params.newValue) ||
            params.oldValue === params.newValue
          ) {
            return false
          }
          objects.setValue(params.data, field, params.newValue)
          params.data.edited = true
          store.dispatch(requireSave())
          return true
        },
      },
      columnDefs,
      // Row grouping
      // @ts-ignore
      treeData: true,
      excludeChildrenWhenTreeDataFiltering: true,
      groupDefaultExpanded: 1,
      autoGroupColumnDef: {
        headerName: propertyName.headerName,
        width: propertyName.width,
        pinned: true,
        editable: true,
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          innerRenderer: WbsItemAdditionalPropertyNameCellRenderer,
          validate: (
            value: I18nLabelVO | undefined,
            params: ICellRendererParams
          ): string | undefined => {
            let nameI18n: I18nLabelVO
            if (isAdditionalPropertyRow(params.data)) {
              const additionalPropertyRow = params.node
                .data as AdditionalPropertyRow
              nameI18n = additionalPropertyRow.propertyNameI18n
            } else if (isGroupHeaderRow(params.data)) {
              const groupHeaderRow = params.node.data as GroupHeaderRow
              nameI18n = groupHeaderRow.headerNameI18n
            } else {
              return
            }
            if (nameI18n.labels.length === 0) {
              return intl.formatMessage({
                id: 'boolExpression.requiredField',
              })
            }
            const emptyLabels = nameI18n.labels.filter(v => !v.label)
            if (emptyLabels.length > 0) {
              return emptyLabels
                .map(v =>
                  intl.formatMessage(
                    {
                      id: 'wbsItemAdditionalProperties.validation.nameI18n.language.required',
                    },
                    { languageCode: v.languageCode }
                  )
                )
                .join('\n')
            }
            return undefined
          },
        },
        valueFormatter: (params: ValueFormatterParams) =>
          params.node ? getName(params.node.data) : '',
        valueGetter: (params: ValueGetterParams) =>
          params.node ? getName(params.node.data) : '',
        cellEditor: I18nLabelCellEditor,
        cellEditorParams: {
          valueGetter: (params: ICellEditorParams) => {
            if (!params.node) {
              return undefined
            }
            if (isGroupHeaderRow(params.node.data)) {
              const groupHeaderRow = params.node.data as GroupHeaderRow
              return groupHeaderRow.headerNameI18n
            } else {
              const additionalPropertyRow = params.node
                .data as AdditionalPropertyRow
              return additionalPropertyRow.propertyNameI18n
            }
          },
          valueSetter: (params: ICellEditorParams, newValue: I18nLabelVO) => {
            if (isGroupHeaderRow(params.node.data)) {
              const groupHeaderRow = params.node.data as GroupHeaderRow
              if (
                i18nLabelVoService.hasDiff(
                  groupHeaderRow.headerNameI18n,
                  newValue
                )
              ) {
                params.data.headerNameI18n = newValue
                params.data.edited = true
                store.dispatch(requireSave())
                return true
              }
            } else {
              const additionalPropertyRow = params.node
                .data as AdditionalPropertyRow
              if (
                i18nLabelVoService.hasDiff(
                  additionalPropertyRow.propertyNameI18n,
                  newValue
                )
              ) {
                params.data.propertyNameI18n = newValue
                params.data.edited = true
                store.dispatch(requireSave())
                return true
              }
            }
            return false
          },
        },
        floatingFilter: true,
        filter: ClientSideTextFilter,
        filterParams: {
          valueGetter: (params: { node; context }) => {
            if (!params.node) {
              return undefined
            }
            if (isGroupHeaderRow(params.node.data)) {
              const groupHeaderRow = params.node.data as GroupHeaderRow
              return i18nLabelVoService.getLabel(groupHeaderRow.headerNameI18n)
            } else {
              const additionalPropertyRow = params.node
                .data as AdditionalPropertyRow
              return i18nLabelVoService.getLabel(
                additionalPropertyRow.propertyNameI18n
              )
            }
          },
          filterChangedCallback: () => {
            return
          },
        },
        cellClassRules: {
          'hover-over-can-drop': params => {
            return (
              params.context?.onTree &&
              params.context?.draggableNodeId &&
              params.node?.id === params.context.draggableNodeId
            )
          },
        },
      },
    }),
    [columnDefs]
  )

  return gridOptions
}
