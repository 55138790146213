import _ from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  GeneralLedgerAccountsBatchInput,
  LedgerAccountsBatchDeleteInput,
  LedgerAccountsBatchInput,
  LedgerAccountsBatchRequest,
  LedgerAccountsRow,
  LedgerAccountsRowBody,
  LedgerAccountsTree,
  LedgerAccountsType,
  SubsidairyAccountsBatchInput,
  fetchLedgerAccounts,
  updateBatch,
} from '../ledgerAccounts'
import {
  AgGridTreeHelper,
  getParentUuid,
} from '../../../containers/BulkSheetView/lib/tree'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import { APIResponse } from '../../../../lib/commons/api'

export const useLedgerAccountsData = () => {
  const [data, setDataInternal] = useState<LedgerAccountsRow[]>([])
  const [deletedRows, setDeletedRows] = useState<LedgerAccountsRow[]>([])

  const setData = useCallback((data: LedgerAccountsRow[]) => {
    setDataInternal(data)
    store.dispatch(requireSave())
  }, [])

  const fetchRecords = useCallback(async () => {
    setDataInternal([])
    const response = await fetchLedgerAccounts({})
    const source: LedgerAccountsTree[] = response.json
    const rows = source
      .map(row =>
        AgGridTreeHelper.convert(
          row,
          (s: LedgerAccountsTree): LedgerAccountsRow => {
            return {
              uuid: s.uuid,
              body: new LedgerAccountsRowBody(s),
              lockVersion: s.lockVersion,
              revision: s.revision,
              createdAt: s.createdAt,
              createdBy: s.createdBy,
              updatedAt: s.updatedAt,
              updatedBy: s.updatedBy,
            } as LedgerAccountsRow
          }
        )
      )
      .flat()
    setDataInternal(rows)
    setDeletedRows([])
  }, [])

  const refresh = useCallback(async () => {
    await fetchRecords()
    store.dispatch(doNotRequireSave())
  }, [fetchRecords])

  const convertRow2RequestBatchInput = (
    rows: LedgerAccountsRow[]
  ): LedgerAccountsBatchInput[] => {
    return rows
      .filter(row => !row.added && !!row.edited)
      .map(row => {
        return {
          uuid: row.uuid,
          lockVersion: row.lockVersion!,
          displayName: row.body.displayName,
          officialName: row.body.officialName,
          displayOrder: row.body.displayOrder,
        }
      })
      .filter(row => !!row) as LedgerAccountsBatchInput[]
  }

  const convertRow2RequestAddedGeneralLedger = (
    generalLedgerAccountsRows: LedgerAccountsRow[]
  ): GeneralLedgerAccountsBatchInput[] => {
    return generalLedgerAccountsRows
      .filter(row => row.added)
      .map(row => {
        const parentUuid = getParentUuid(row)
        if (!parentUuid) return undefined
        return {
          uuid: row.uuid,
          code: row.body.code,
          financialStatementAccountUuid: parentUuid,
          displayName: row.body.displayName,
          officialName: row.body.officialName,
          displayOrder: row.body.displayOrder,
        }
      })
      .filter(row => !!row) as GeneralLedgerAccountsBatchInput[]
  }

  const convertRow2RequestAddedSubsidairy = (
    subsidairyAccountsRows: LedgerAccountsRow[]
  ): SubsidairyAccountsBatchInput[] => {
    return subsidairyAccountsRows
      .filter(row => row.added)
      .map(row => {
        const treeValue = row.treeValue || []
        if (treeValue.length < 3) return undefined
        return {
          uuid: row.uuid,
          code: row.body.code,
          financialStatementAccountUuid: treeValue[treeValue.length - 3],
          generalLedgerAccountUuid: treeValue[treeValue.length - 2],
          displayName: row.body.displayName,
          officialName: row.body.officialName,
          displayOrder: row.body.displayOrder,
        }
      })
      .filter(row => !!row) as SubsidairyAccountsBatchInput[]
  }

  const convertRow2DeleteRequest = (
    rows: LedgerAccountsRow[],
    type: LedgerAccountsType
  ): LedgerAccountsBatchDeleteInput[] => {
    return rows
      .filter(d => type === d.body.ledgerAccountsType)
      .map(row => {
        return {
          uuid: row.uuid,
          lockVersion: row.lockVersion!,
        }
      })
  }

  const updateLedgerAccounts = useCallback(
    async (allRows: LedgerAccountsRow[], modifiedRows: LedgerAccountsRow[]) => {
      const financialStatementAccountsRows = modifiedRows.filter(
        row =>
          LedgerAccountsType.FinancialStatementAccounts ===
          row.body.ledgerAccountsType
      )
      const generalLedgerAccountsRows = modifiedRows.filter(
        row =>
          LedgerAccountsType.GeneralLedgerAccounts ===
          row.body.ledgerAccountsType
      )
      const subsidiaryAccountsRows = modifiedRows.filter(
        row =>
          LedgerAccountsType.SubsidiaryAccounts === row.body.ledgerAccountsType
      )

      const request: LedgerAccountsBatchRequest = {
        editedFinancialStatementAccounts: convertRow2RequestBatchInput(
          financialStatementAccountsRows
        ),
        addedGeneralLedgerAccounts: convertRow2RequestAddedGeneralLedger(
          generalLedgerAccountsRows
        ),
        editedGeneralLedgerAccounts: convertRow2RequestBatchInput(
          generalLedgerAccountsRows
        ),
        deletedGeneralLedgerAccounts: convertRow2DeleteRequest(
          deletedRows,
          LedgerAccountsType.GeneralLedgerAccounts
        ),
        addedSubsidiaryAccounts: convertRow2RequestAddedSubsidairy(
          subsidiaryAccountsRows
        ),
        editedSubsidiaryAccounts: convertRow2RequestBatchInput(
          subsidiaryAccountsRows
        ),
        deletedSubsidiaryAccounts: convertRow2DeleteRequest(
          deletedRows,
          LedgerAccountsType.SubsidiaryAccounts
        ),
      }
      return updateBatch(request)
    },
    [deletedRows]
  )

  const save = useCallback(async (): Promise<APIResponse> => {
    const changed = data.filter(row => row.added || row.edited)
    const response = await updateLedgerAccounts(data, changed)
    return response
  }, [data, updateLedgerAccounts])

  const deleteRows = useCallback(
    (rows: LedgerAccountsRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(r => !r.added)])
    },
    [deletedRows]
  )

  useEffect(() => {
    fetchRecords()
  }, [fetchRecords])

  return { data, setData, refresh, save, deleteRows }
}
