import { Box } from '@mui/system'
import { ProgressReportViewConfig } from '../..'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  Toolbar,
  ToolbarDivider,
} from '../../../../components/toolbars/Toolbar'
import ProgressReportViewConfigurationToolbar from '../../Toolbar/ViewConfigurationToolbar'
import { OpenChartButton } from './OpenChartButton'
import ProgressReportSearchConditionButton, {
  SearchConditionKey,
} from '../../Toolbar/ProgressReportSearchConditionButton'
import { ProgressReportSearchConditionVO } from '../../../../../domain/value-object/ProgressReportSearchConditionVO'

interface OwnProps {
  projectUuid: string
  searchCondition: ProgressReportSearchConditionVO
  onChangeSearchCondition: (
    newCondition: ProgressReportSearchConditionVO
  ) => void
  onSearch: () => void
  viewConfig: ProgressReportViewConfig
  onChangeViewConfig: (newConfig: ProgressReportViewConfig) => void
}

type Props = OwnProps & SettingSpeedDialProps

const ProgressReportTableToolbar = ({
  projectUuid,
  searchCondition,
  onChangeSearchCondition,
  onSearch,
  viewConfig,
  onChangeViewConfig,
  onResetColumnState,
  onClickExport,
  onRestoreSearchCondition,
  rowHeight,
  onClickChangeRowHeight,
  filteredColumns,
  onDeletedFilterColumn,
  sortedColumns,
}: Props) => {
  return (
    <Toolbar>
      <ProgressReportSearchConditionButton
        condition={searchCondition}
        searchConditionKeys={[
          SearchConditionKey.TEAM,
          SearchConditionKey.ACCOUNTABLE,
          SearchConditionKey.RESPONSIBLE,
          SearchConditionKey.PRIORITY,
        ]}
        onChange={onChangeSearchCondition}
        onSearch={onSearch}
      />
      <OpenChartButton
        searchCondition={searchCondition}
        viewConfig={viewConfig}
        projectUuid={projectUuid}
        sx={{ marginRight: '10px' }}
      />
      <Box sx={{ display: 'flex', width: 24, height: 24 }}>
        <SettingSpeedDial
          onResetColumnState={onResetColumnState}
          onClickExport={onClickExport}
          onRestoreColumnState={onRestoreSearchCondition}
          rowHeight={rowHeight}
          onClickChangeRowHeight={onClickChangeRowHeight}
          filteredColumns={filteredColumns}
          onDeletedFilterColumn={onDeletedFilterColumn}
          sortedColumns={sortedColumns}
        />
      </Box>
      <Box sx={{ margin: '0 5px' }}></Box>
      <ToolbarDivider />
      <Box sx={{ margin: '0 5px' }}></Box>
      <ProgressReportViewConfigurationToolbar
        initialConfig={viewConfig}
        onChange={onChangeViewConfig}
        hideLabel={true}
      />
    </Toolbar>
  )
}

export default ProgressReportTableToolbar
