import { Tree } from '../commons/tree'
import EntitySearch from '../commons/entitySearch'
import API, { APIResponse } from '../commons/api'
import { GetDivisionsProps } from './division'
import { APPLICATION_UUID, FunctionProperty } from '../commons/appFunction'

export enum DataType {
  FUNCTION = 'FUNCTION',
  DATA = 'DATA',
}

export interface GetPermissionsResponse {
  total: number
  hit: number
  data: PermissionProps[]
}

export enum OperationType {
  NONE = 'NONE',
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface PermissionDetail extends Tree<PermissionDetail> {
  uuid: string
  name: string
  operationForRole: { [code: string]: OperationType }
}

export interface PermissionProps {
  uuid: string
  name: string
}

class Permission extends EntitySearch {
  public getAll = async () => {
    return this.searchRequest.switch(signal =>
      API.functional.request(
        'GET',
        '/api/v1/permissions/list',
        { applicationUuid: APPLICATION_UUID },
        true,
        signal
      )
    )
  }

  entitySearchApi = (
    props: GetDivisionsProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    throw new Error('Not implemented yet')
  }

  public search = async (
    rowQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    throw new Error('Not implemented yet')
  }

  public searchAll = () => {
    return this.getAll()
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const permission = response.json.find(v => v.uuid === uuid)
    return { status: 200, json: this.toResponse(permission) }
  }
}

export default new Permission()
