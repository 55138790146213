import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { Select } from '../../../../components/inputs/Select'
import {
  WeekDay,
  weekDayArray,
} from '../../../../../domain/value-object/DayOfWeek'
import { useMemo, useState } from 'react'
import { SelectOption } from '../../../../model/selectOption'
import { intl } from '../../../../../i18n'

type StartDayOfWeekSelectProps = {
  startDayOfWeek: WeekDay
  updateStartDayOfWeek: (v: WeekDay) => void
}

export const StartDayOfWeekSelect = ({
  startDayOfWeek,
  updateStartDayOfWeek,
}: StartDayOfWeekSelectProps) => {
  const options = useMemo(
    () =>
      weekDayArray.map(v => ({
        value: v,
        name: intl.formatMessage({
          id: `dayOfWeek.${v.toLowerCase()}`,
        }),
      })),
    []
  )
  const onSelect = (option: SelectOption) => {
    updateStartDayOfWeek(option.value as WeekDay)
  }

  return (
    <>
      <LabelDiv>{intl.formatMessage({ id: 'startDayOfWeek' })}</LabelDiv>
      <Select
        options={options}
        value={startDayOfWeek}
        onSelect={onSelect}
        height={26}
        width={90}
      />
    </>
  )
}

const LabelDiv = styled('div')({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: 1,
  color: colorPalette.monotone[5],
})
