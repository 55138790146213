import {
  ClickAwayListener,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react'
import { colorPalette } from '../../style/colorPallete'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'

const ExpandMoreIcon = styled(ExpandMoreRoundedIcon)(
  ({ open }: { open: boolean }) => {
    const transform = useMemo(() => {
      if (open) return 'rotate(180deg)'
      return 'none'
    }, [open])
    return {
      color: colorPalette.monotone[4],
      transform,
      transition: 'transform .2s',
    }
  }
)
type PopperRootProps = {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}
export const PopperRoot = styled('div')(
  ({ width, height }: PopperRootProps) => ({
    backgroundColor: colorPalette.monotone[0],
    borderRadius: '4px',
    boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
    padding: '8px 0',
    width,
    height,
  })
)
export const Menu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})
export const MenuItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  color: colorPalette.monotone[4],
})
export const MenuItemText = styled('span')({
  fontSize: '14px',
  lineHeight: 1,
  color: 'inherit',
  '&:hover': {
    // color: colorPalette.monotone[4],
    // backgroundColor: colorPalette.skyBlue[0],
  },
})
