import { intl } from '../../../i18n'
import { BulkSheetProperty } from './bulksheetProperty'

export const propertyName: BulkSheetProperty = {
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.propertyName',
  }),
  hide: false,
  width: 300,
}
export const propertyType: BulkSheetProperty = {
  field: 'propertyType',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.propertyType',
  }),
  hide: false,
  width: 150,
}
export const wbsItemTypes: BulkSheetProperty = {
  field: 'wbsItemTypes',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.wbsItemTypes',
  }),
  hide: false,
  width: 200,
}
export const required: BulkSheetProperty = {
  field: 'required',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.required',
  }),
  hide: false,
  width: 90,
  editable: false,
}
export const selectOptions: BulkSheetProperty = {
  field: 'selectOptions',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.selectOptions',
  }),
  hide: false,
  width: 200,
}
export const entitySearchReferenceEntity: BulkSheetProperty = {
  field: 'entitySearchReferenceEntity',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.entitySearchReferenceEntity',
  }),
  hide: false,
  width: 200,
}
export const using: BulkSheetProperty = {
  field: 'using',
  headerName: intl.formatMessage({
    id: 'wbsItemAdditionalProperties.column.header.using',
  }),
  hide: false,
  width: 90,
}
