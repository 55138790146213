import { LoginButton, LoginContainer, LoginTitle } from './components'
import { intl } from '../../../i18n'
import { styled } from '@mui/material'
import { useCallback } from 'react'
import { useHistory } from 'react-router'

const LoginText = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  padding: '0 0 16px 0',
})
export const ChangedPassword = () => {
  const history = useHistory()
  const backToLogin = useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <LoginContainer>
      <LoginTitle>
        {intl.formatMessage({ id: 'forgotPassword.resetPasswordCompleted' })}
      </LoginTitle>
      <LoginText>
        {intl.formatMessage({ id: 'forgotPassword.changedPassword.main' })}
      </LoginText>
      <LoginButton color={'skyBlue'} onClick={backToLogin}>
        {intl.formatMessage({ id: 'forgotPassword.returnLoginScreen' })}
      </LoginButton>
    </LoginContainer>
  )
}
