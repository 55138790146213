import React from 'react'
import { Switch } from '@mui/material'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import SwitchVO from '../../../../../../vo/SwitchVO'

interface SwitchCellProps extends CellPropsBase<SwitchVO> {}

interface SwitchCellState extends CellStateBase {}

class SwitchCell extends DataCellBase<
  SwitchVO,
  SwitchCellProps,
  SwitchCellState
> {
  validate = (value: SwitchVO) => {
    return undefined
  }

  onChange = (event: any) => {
    this.props.onChange(new SwitchVO(event.target.checked))
  }

  render() {
    const { value, cellDef } = this.props
    return (
      <Switch
        disabled={!cellDef.editable}
        size="small"
        checked={value ? value.getValue() : false}
        onChange={this.onChange}
        name="switch-cell"
        inputProps={{ 'aria-label': 'switch-cell' }}
      />
    )
  }
}

export default SwitchCell
