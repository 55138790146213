import { useCallback } from 'react'
import { WbsSubStatusRepository } from '../../applications/ports/wbsSubStatusRepository'
import { WbsItemStatus, WbsItemType } from '../../domain/entity/WbsItemEntity'
import api from '../../lib/commons/api'
import { getLabel, parse } from '../../lib/commons/i18nLabel'

export const useWbsSubStatusRepository = (): WbsSubStatusRepository => {
  const getByType = useCallback(
    async (projectUuid: string, type: WbsItemType, ticketType?: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/wbs_items/sub-status/type',
        { projectUuid, type, ticketType },
        true
      )
      return response.json.map(v => ({
        value: v.value,
        name: getLabel(parse(v.i18nLabel)),
        status: WbsItemStatus[v.status],
      }))
    },
    []
  )
  return { getByType }
}
