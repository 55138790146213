import Select, { Props as SelectOrgProps } from './Select'
import { styled } from '@mui/system'
import { FontWeight, TextColor } from '../../../../styles/commonStyles'
import * as React from 'react'
import { Avatar, Box, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

const SelectedMenuItem = styled(Box)({
  display: 'flex',
  margin: '8px',
  paddingLeft: 5,
  alignItems: 'center',
})
const EmptyTextOptionLabel = styled(Typography)({
  paddingLeft: 10,
  color: TextColor.GREY,
})
const OptionLabel = styled(Typography)({
  margin: 'auto 0px',
})
const OptionIcon = styled(Avatar)({
  margin: '0px 5px',
  width: 20,
  height: 20,
})
const StyledSelect = styled(Select)({
  width: '100%',
  height: '100%',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  boxShadow: '0px 2px 4px #0000001A',
  borderRadius: '19px',
  '& .MuiSelect-icon': {
    top: 'auto',
    right: '5px',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}) as typeof Select

interface OwnProps {
  emptyText?: string
  extraMenuItems?: React.ReactNode[]
}

type Props = OwnProps & SelectOrgProps

const ToolbarSelect = (props: Props) => {
  const { emptyText, extraMenuItems, ...other } = props
  const {
    options,
    getOptionValue,
    getOptionLabel,
    getOptionIconUrl,
    typographyVariant,
  } = other

  const renderSelectedValue: any = (val: any) => {
    if (!options) {
      return
    }
    const selectedIndex = options.findIndex(
      (o, i) => (getOptionValue ? getOptionValue(o, i) : o.value) === val
    )
    const selectedValue = options[selectedIndex]

    if (selectedIndex === -1) {
      if (!!emptyText) {
        return (
          <SelectedMenuItem>
            <EmptyTextOptionLabel variant="body1">
              {emptyText}
            </EmptyTextOptionLabel>
          </SelectedMenuItem>
        )
      } else {
        return
      }
    }
    return (
      <SelectedMenuItem>
        {getOptionIconUrl && (
          <OptionIcon
            variant="circular"
            src={getOptionIconUrl(selectedValue, selectedIndex)}
          />
        )}
        <OptionLabel
          variant={typographyVariant || 'subtitle2'}
          style={{ fontWeight: FontWeight.BOLD }}
        >
          {getOptionLabel
            ? getOptionLabel(selectedValue, selectedIndex)
            : val.label}
        </OptionLabel>
      </SelectedMenuItem>
    )
  }

  return (
    <StyledSelect
      {...other}
      IconComponent={ExpandMore}
      renderValue={renderSelectedValue}
      MenuProps={{
        disablePortal: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      extraMenuItem={extraMenuItems}
    />
  )
}

export default ToolbarSelect
