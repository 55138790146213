import Select from '../../../../../components/editors/select/Select'

type Props = {
  id: string
  value: string
  options: string[]
  onChange: (value) => void
  getOptionLabel: (option) => string
}

export const CommentOperatorSelect = ({
  id,
  value,
  options,
  onChange,
  getOptionLabel,
}: Props) => {
  return (
    <Select
      key={id}
      options={options}
      value={value}
      onChange={event => onChange(event.target.value)}
      getOptionValue={o => o}
      getOptionLabel={getOptionLabel}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
      }}
      sx={{ width: '70px', margin: 'auto 5px auto 0' }}
    />
  )
}
