import _ from 'lodash'
import { GetContextMenuItemsParams, GridApi, RowNode } from 'ag-grid-community'
import {
  AccountCategory,
  BudgetResultType,
  ProfitLossItemAmountRowBody,
  ProfitLossItemRow,
  ProfitLossRowBase,
  ProfitLossRowBodyBase,
} from '../profitLossItems'
import { intl } from '../../../../i18n'
import DateVO from '../../../../vo/DateVO'
import { distinctRowNodes, generateYearMonthField, getSummaryRowNode } from '.'
import {
  FinancialStatementAccountsType,
  LedgerAccountsTree,
} from '../../LedgerAccounts/ledgerAccounts'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import { AddSize } from './contextMenu'

export const addSimpleRow = <
  TRow extends ProfitLossRowBase<TBody>,
  TBody extends ProfitLossRowBodyBase
>(
  params: GetContextMenuItemsParams,
  data: TRow[],
  accountCategory: string | undefined,
  masterTree: LedgerAccountsTree[],
  createRow: (
    financialState: LedgerAccountsTree,
    generalLedger: LedgerAccountsTree
  ) => TRow[],
  action: { setData: (data: TRow[]) => void }
) => {
  const isDisabled = !accountCategory
  const financialState =
    AccountCategory.OperatingRevenue === accountCategory
      ? masterTree.find(
          v =>
            v.financialStatementAccountsType ===
            FinancialStatementAccountsType.Sales
        )
      : undefined
  const generalLedger =
    !!financialState?.children && financialState.children.length > 0
      ? financialState.children[0]
      : undefined
  return {
    name: intl.formatMessage({ id: 'bulksheet.contextMenu.insert.row' }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
    disabled: isDisabled,
    action: () => {
      const copyData = _.cloneDeep(data)
      const addItems = createRow(financialState!, generalLedger!)
      const index = data.findIndex(v => v.uuid === params.node?.data.uuid)
      const spliceIndex = Math.floor(index / AddSize)
      copyData.splice((spliceIndex + 1) * AddSize, 0, ...addItems)
      action.setData(copyData)
    },
  }
}

export const setSimpleSingleBudgetAmountToResult = (
  params: GetContextMenuItemsParams<ProfitLossItemRow>
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLoss.contextMenu.budget.to.result.single',
    }),
    disabled: params.api.getRenderedNodes().length <= 0 || !params.node?.data,
    action: () => {
      if (!params.node) return
      const currentRow = params.node.data
      if (!currentRow) return
      const items: RowNode[] = []
      params.api.forEachNode((node, _) => {
        if (
          node.data &&
          node.data.treeValue.includes(currentRow.treeValue[0])
        ) {
          items.push(node)
        }
      })
      const budgetNode = items.find(
        c => BudgetResultType.Budget === c.data.body.type
      )
      const resultNode = items.find(
        c => BudgetResultType.Result === c.data.body.type
      )
      const parentNode = items.find(c => !c.data.body.type)
      setBudgetAmountToResult(params.api, budgetNode, resultNode, parentNode)
    },
  }
}

export const setSimpleAllBudgetAmountToResult = (
  params: GetContextMenuItemsParams
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLoss.contextMenu.budget.to.result.all',
    }),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      const rootRows: RowNode[] = []
      params.api.forEachNode((node, _) => {
        if (!!node.data.treeValue && node.data.treeValue.length === 1) {
          rootRows.push(node)
        }
      })
      rootRows.forEach(root => {
        const items: RowNode[] = []
        params.api.forEachNode((node, _) => {
          if (
            node.data &&
            node.data.treeValue.includes(root.data.treeValue[0])
          ) {
            items.push(node)
          }
        })
        const budgetNode = items.find(
          c => BudgetResultType.Budget === c.data.body.type
        )
        const resultNode = items.find(
          c => BudgetResultType.Result === c.data.body.type
        )
        setBudgetAmountToResult(params.api, budgetNode, resultNode, root)
      })
    },
  }
}

const setBudgetAmountToResult = (
  api: GridApi,
  budgetNode: RowNode | undefined,
  resultNode: RowNode | undefined,
  parentNode: RowNode | undefined
) => {
  const budgetData = budgetNode?.data
  const resultData = resultNode?.data
  if (!budgetData || !resultData || !parentNode) return

  const thisMonth = DateVO.now().getFirstDayOfMonth()
  const editedRowNodes: RowNode[] = []

  budgetData.body.amounts.forEach(budgetAmount => {
    const targetMonth = new DateVO(budgetAmount.yearMonth)
    if (!budgetAmount?.amount || thisMonth.diffMonth(targetMonth) <= 0) {
      return
    }
    const resultAmount = resultData.body.amounts.find(
      resultAmount => resultAmount.yearMonth === budgetAmount.yearMonth
    )
    if (resultAmount?.amount) return

    const oldValue: number | undefined = resultAmount?.amount
    const newValue: number = budgetAmount.amount
    if (!resultAmount) {
      resultData.body.amounts.push({
        yearMonth: budgetAmount.yearMonth,
        amount: newValue,
      } as ProfitLossItemAmountRowBody)
    } else {
      resultAmount.amount = newValue
    }
    resultData.edited = !resultData.added
    const field = generateYearMonthField(targetMonth)
    if (!resultData.editedData) {
      resultData.editedData = { [field]: oldValue }
    } else if (!resultData.editedData.hasOwnProperty(field)) {
      resultData.editedData[field] = oldValue
    }
    editedRowNodes.push(resultNode)
    const formatDate = targetMonth.formatForApi()
    const rootAmount = parentNode.data.body.amounts.find(
      c => c.yearMonth === formatDate
    )
    if (rootAmount) {
      rootAmount.amount = newValue
    } else {
      parentNode.data.body.amounts.push({
        yearMonth: formatDate,
        amount: newValue,
      })
    }
    editedRowNodes.push(parentNode)
  })
  const refreshNodes = distinctRowNodes(editedRowNodes)
  if (!_.isEmpty(refreshNodes)) {
    refreshNodes.push(...getSummaryRowNode(api, ['', BudgetResultType.Result]))
    api.refreshCells({ rowNodes: refreshNodes, force: true })
  }
}
