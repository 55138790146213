import { intl } from '../../../../i18n'
import { ColumnFilterButtonGroup } from '../../../components/buttons/ColumnFilterButtonGroup'
import {
  HeaderDivider,
  HeaderGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ColumnQuickFilterKey } from '../WorkReportOptions'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../components/buttons/ToolbarFilterButtonGroup'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

type Props = OwnProps & ToolbarFilterGroupProps

const WorkReportToolbar = (props: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === props.toolbar && (
        <>
          <HeaderGroup>
            <ToolbarFilterButtonGroup
              filteredColumns={props.filteredColumns}
              sortedColumns={props.sortedColumns}
              onDeletedFilterColumn={props.onDeletedFilterColumn}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <ColumnFilterButtonGroup
              options={[
                {
                  label: intl.formatMessage({
                    id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
                  }),
                  value: ColumnQuickFilterKey.INITIAL,
                },
                {
                  label: intl.formatMessage({
                    id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
                  }),
                  value: ColumnQuickFilterKey.RESTORE,
                },
              ]}
              onChange={value =>
                props.onChangeColumnFilter(value as ColumnQuickFilterKey)
              }
            />
          </HeaderGroup>
        </>
      )}
    </Toolbar>
  )
}

export default WorkReportToolbar
