import React from 'react'
import MuiButton from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import MuiDialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/system'
import { alpha } from '@mui/material/styles'
import { intl } from '../../../i18n'
import { muiTheme } from '../../../styles/muiTheme'

const theme = muiTheme
const DialogContentText = styled(MuiDialogContentText)({
  minWidth: 300,
  whiteSpace: 'pre-line',
  paddingLeft: 5,
})
const Button = styled(MuiButton)({
  '&:hover, &:focus': {
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.hoverOpacity
    ),
  },
})

export type DialogProps = {
  isOpen: boolean
  title?: string
  message?: string
  extraContent?: any
  submitButtonTitle?: string
  submitHandler?: Function
  closeButtonTitle?: string
  closeHandler?: Function
}

class AlertDialog extends React.PureComponent<DialogProps> {
  private ref = React.createRef<HTMLButtonElement>()

  handleEnter = () => {
    this.ref.current && this.ref.current.focus()
  }

  handleFocusOnCancelButton = () => {
    this.ref.current && this.ref.current.blur()
  }

  onClose = () => {
    this.setState({ isOpen: false }, () => {
      this.props.closeHandler && this.props.closeHandler()
    })
  }

  onSubmit = () => {
    this.setState({ isOpen: false }, () => {
      this.props.submitHandler && this.props.submitHandler()
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        TransitionProps={{
          onEnter: this.handleEnter,
        }}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.props.title !== undefined && (
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        )}
        <DialogContent dividers={!!this.props.extraContent}>
          <DialogContentText id="alert-dialog-description">
            {this.props.message}
          </DialogContentText>
          {this.props.extraContent}
        </DialogContent>
        <DialogActions>
          {this.props.submitHandler && (
            <Button onClick={this.onSubmit} color="primary" ref={this.ref}>
              {this.props.submitButtonTitle ||
                intl.formatMessage({ id: 'dialog.ok' })}
            </Button>
          )}
          <Button
            onClick={this.onClose}
            color="primary"
            onMouseOver={this.handleFocusOnCancelButton}
          >
            {this.props.closeButtonTitle ||
              intl.formatMessage({ id: 'dialog.cancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AlertDialog
