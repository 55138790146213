import React, { memo, useCallback } from 'react'
import _ from 'lodash'
import {
  Box,
  Checkbox,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material'
import { ICON_SIZE } from '../../../../BulkSheet/const'
import { ReferencedEntityWithIcon } from '../../../../../../domain/value-object/ReferencedEntity'

export const SelectedValues = ({
  selectedValues,
  onChange,
}: {
  selectedValues: ReferencedEntityWithIcon[]
  onChange: (value: ReferencedEntityWithIcon[]) => void
}) => {
  const onClick = useCallback(
    (uuid: string) => {
      onChange(selectedValues.filter(v => v.uuid !== uuid))
    },
    [onChange, selectedValues]
  )
  return (
    <Container>
      {selectedValues.map(selectedValue => (
        <SelectedValue
          {...selectedValue}
          key={`select-filter-${selectedValue.uuid}`}
          onClick={onClick}
        />
      ))}
    </Container>
  )
}

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
})
const SelectedValue = memo(
  ({
    uuid,
    name,
    iconUrl,
    onClick,
  }: ReferencedEntityWithIcon & {
    onClick: (uuid: string) => void
  }) => {
    return (
      <StyledFormControl
        label={
          <FilterLabel>
            {iconUrl && (
              <img
                src={iconUrl}
                style={{
                  height: `${ICON_SIZE}px`,
                  width: `${ICON_SIZE}px`,
                  borderRadius: '50%',
                }}
              />
            )}
            <Typography sx={{ padding: '0 3px' }}>{name}</Typography>
          </FilterLabel>
        }
        control={
          <Checkbox
            checked={true}
            onChange={e => {
              onClick(uuid)
            }}
            disableRipple={true}
          />
        }
      />
    )
  }
)
export const StyledFormControl = styled(FormControlLabel)({
  margin: 0,
})
const FilterLabel = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '2px',
})
