import { useCallback, useEffect, useState } from 'react'
import { ResizableArea } from '../../../../components/draggers/ResizableArea'
import {
  MainArea,
  PanelRoot,
  SideBarArea,
  SingleSheetRoot,
} from '../../../../containers/SingleSheetV2'
import {
  Tab,
  Tabs,
  TabMainArea,
  TabPanelArea,
} from '../../../../containers/SingleSheetV2/Tab'
import { TextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { PageArea } from '../../..'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { SelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { AutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { WbsItemProps } from '../..'
import { MultiAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/MultiAutocompleteCell'
import { WorkloadCell } from '../../../../containers/SingleSheetV2/table/cells/WorkloadCell'
import { MultilineTextEditorTab } from '../tab-panel/multiline-text'
import { ProjectPlanTab } from '../tab-panel/project-plan'
import { AttachmentTab } from '../tab-panel/attachment'
import { DiscussionTab } from '../tab-panel/discussion'
import { TaskActualWorkTabPanel } from '../tab-panel/TaskActualWorkTabPanel'
import { WbsItemHeader } from '../header'
import { WbsItemSubHeader } from '../subheader'
import { DateTermCell } from '../../../../containers/SingleSheetV2/table/cells/DateTermCell'
import { DateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { DevelopmentTabPanel } from '../tab-panel/DevelopmentTabPanel'
import { ChangeLogTabPanel } from '../tab-panel/ChangeLogTabPanel'
import { WbsStatusCell } from '../../../../containers/SingleSheetV2/table/cells/WbsStatusCell'
import { useWbsItemSelectOptions } from '../../hooks/wbsItemSelectOptions'
import { useWbsItemSingleSheetData } from '../../hooks/wbsItemData'
import Loading from '../../../../components/process-state-notifications/Loading'
import { styled } from '@mui/material'
import { WbsTagsCell } from '../../../../containers/SingleSheetV2/table/cells/WbsTagsCell'
import { useAttachmentHandlers } from '../../hooks/attachments'
import { useWbsItemPropertySearch } from '../../hooks/wbsItemPropertySearch'
import { useWbsItemChangeLogParser } from '../../hooks/wbsItemChangeLogParser'
import { useWbsItemModelModifier } from '../../hooks/wbsItemModelModifier'
import { useTicketListRepository } from '../../../../../services/adaptors/ticketListRepositoryAdaptor'
import { ToggleCell } from '../../../../containers/SingleSheetV2/table/cells/ToggleCell'
import { useRefinementSingleSheetData } from '../../hooks/tickets/refinement/data'
import { useRefinementModelModifier } from '../../hooks/tickets/refinement/modelModifier'
import { useTicketListSearch } from '../../hooks/tickets/ticketListSearch'
import { useRefinementSelectOptions } from '../../hooks/tickets/refinement/selectOptions'
import { DateCell } from '../../../../containers/SingleSheetV2/table/cells/DateCell'
import { useRefinementChangeLogParser } from '../../hooks/tickets/refinement/changeLogParser'
import { useRefinementLabel } from '../../hooks/tickets/refinement/label'
import { SingleSheetWrapper } from '.'

export const Refinement = ({
  projectUuid,
  uuid,
  functionUuid,
}: WbsItemProps) => {
  const [leftTab, setLeftTab] = useState<number>(0)
  const onChangeLeftTab = useCallback(
    (_, newValue: number) => setLeftTab(newValue),
    []
  )
  const [rightTab, setRightTab] = useState<number>(0)
  const onChangeRightTab = useCallback(
    (_, newValue: number) => setRightTab(newValue),
    []
  )
  const {
    initialized,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    refinementFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    onChange,
    reload,
    submit,
  } = useRefinementSingleSheetData(uuid)
  const {
    subStatuses,
    difficulties,
    priorities,
    getSubStatusByStatus,
    getStatusBySubStatus,
  } = useWbsItemSelectOptions(projectUuid, WbsItemType.TASK)
  const {
    detectionPhases,
    environments,
    domains,
    featureTypes,
    impacts,
    importances,
    refinementCauses,
  } = useRefinementSelectOptions(projectUuid)
  const { searchTicketList } = useTicketListSearch(projectUuid)
  const { searchTeam, searchMember, searchSprint } = useWbsItemPropertySearch(
    projectUuid,
    wbsItemFormModel
  )

  const getChangeLogParser = useRefinementChangeLogParser(
    {
      subStatuses,
      difficulties,
      priorities,
    },
    {
      detectionPhases,
      environments,
      domains,
      featureTypes,
      impacts,
      importances,
      refinementCauses,
    }
  )

  const {
    onChangeDisplayName,
    onChangeStatus,
    onChangeSubStatus,
    onChangeTags,
    onChangeTeam,
    onChangeAccountable,
    onChangeResponsible,
    onChangeAssignee,
    onChangeWatchers,
    onChangeDifficulty,
    onChangePriority,
    onChangeSprint,
    onChangeScheduledDate,
    onChangeActualDate,
    onChangeDescription,
  } = useWbsItemModelModifier(onChangeWbsItem, {
    getStatusBySubStatus,
    getSubStatusByStatus,
  })
  const {
    onChangeKnownRefinement,
    onChangeOriginalRefinement,
    onChangeDetectionPhase,
    onChangeEnvironment,
    onChangeDomain,
    onChangeFeatureType,
    onChangeFeature,
    onChangeDefectEvent,
    onChangeOccurrenceProcedure,
    onChangeTestSpecification,
    onChangeTestCase,
    onChangeImportance,
    onChangeImpact,
    onChangeDesiredDeliveryDate,
    onChangeCause,
    onChangeDirectCause,
    onChangeRootCause,
    onChangeProperDetectionPhase,
    onChangeInterimAction,
    onChangePermanentAction,
    onChangeDocumentsToBeCorrected,
    onChangeHorizontalDeploymentTarget,
    onChangeHorizontalDeploymentContent,
  } = useRefinementModelModifier(onChange)

  const { addAttachments, deleteAttachments } = useAttachmentHandlers(uuid)
  const labelMap = useRefinementLabel()

  return (
    <PageArea>
      <Loading isLoading={isLoading} />
      {initialized && (
        <>
          <WbsItemHeader
            title={wbsItemFormModel.displayName || ''}
            onChangeTitle={onChangeDisplayName}
          />
          <WbsItemSubHeader
            projectUuid={projectUuid}
            submit={submit}
            reload={reload}
            model={wbsItemFormModel}
            onChangeStatus={onChangeStatus}
            onChangeSubStatus={onChangeSubStatus}
            onChangePriority={onChangePriority}
            onChangeTeam={onChangeTeam}
            onChangeAccountable={onChangeAccountable}
            onChangeResponsible={onChangeResponsible}
            onChangeAssignee={onChangeAssignee}
            onChangeScheduledDate={onChangeScheduledDate}
            onChangeActualDate={onChangeActualDate}
            subStatuses={subStatuses}
            priorities={priorities}
          />
          <SingleSheetWrapper>
            <SingleSheetRoot>
              <MainArea width="50%">
                <TabPanelArea>
                  <Tabs value={leftTab} onChange={onChangeLeftTab}>
                    <Tab label="基本情報" />
                    <Tab label="説明" />
                    <Tab label="サブタスク" />
                    <Tab label="添付" />
                  </Tabs>
                  <TabMainArea>
                    <PanelRoot>
                      {leftTab === 0 && (
                        <>
                          <TableHeader title="基本情報" />
                          <TableRow>
                            <TextCell
                              label="コード"
                              cellWidth="half"
                              value={wbsItemFormModel.code}
                              required={true}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="チケット管理表"
                              cellWidth="half"
                              value={ticketFormModel.ticketList}
                              onChange={onChangeTicketList}
                              search={searchTicketList}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="名称"
                              cellWidth="full"
                              value={wbsItemFormModel.displayName}
                              onChange={onChangeDisplayName}
                              required={true}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsStatusCell
                              label="ステータス"
                              cellWidth="half"
                              value={wbsItemFormModel.status}
                              onChange={onChangeStatus}
                              required={true}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="サブステータス"
                              cellWidth="half"
                              value={wbsItemFormModel.substatus}
                              options={subStatuses}
                              onChange={onChangeSubStatus}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsTagsCell
                              projectUuid={projectUuid}
                              label="タグ"
                              cellWidth="half"
                              value={wbsItemFormModel.tags || []}
                              onChange={onChangeTags}
                            />
                          </TableRow>
                          <TableHeader title="不具合事象" />
                          <TableRow>
                            <ToggleCell
                              label="既存"
                              cellWidth="half"
                              value={refinementFormModel.knownRefinement}
                              onChange={onChangeKnownRefinement}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="検知工程"
                              cellWidth="half"
                              value={refinementFormModel.detectionPhase}
                              onChange={onChangeDetectionPhase}
                              options={detectionPhases}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="環境"
                              cellWidth="half"
                              value={refinementFormModel.environment}
                              onChange={onChangeEnvironment}
                              options={environments}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="領域"
                              cellWidth="half"
                              value={refinementFormModel.domain}
                              onChange={onChangeDomain}
                              options={domains}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="機能種別"
                              cellWidth="half"
                              value={refinementFormModel.featureType}
                              onChange={onChangeFeatureType}
                              options={featureTypes}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="機能"
                              cellWidth="full"
                              value={refinementFormModel.feature}
                              onChange={onChangeFeature}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="不具合事象"
                              cellWidth="full"
                              value={refinementFormModel.defectEvent}
                              onChange={onChangeDefectEvent}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="発生手順"
                              cellWidth="full"
                              value={refinementFormModel.occurrenceProcedure}
                              onChange={onChangeOccurrenceProcedure}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="テスト仕様書"
                              cellWidth="full"
                              value={refinementFormModel.testSpecification}
                              onChange={onChangeTestSpecification}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="テストケース"
                              cellWidth="full"
                              value={refinementFormModel.testCase}
                              onChange={onChangeTestCase}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="影響度"
                              cellWidth="half"
                              value={refinementFormModel.impact}
                              onChange={onChangeImpact}
                              options={impacts}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="重要度"
                              cellWidth="half"
                              value={refinementFormModel.importance}
                              onChange={onChangeImportance}
                              options={importances}
                            />
                          </TableRow>
                          <TableRow>
                            <DateCell
                              label="希望納期"
                              cellWidth="half"
                              value={refinementFormModel.desiredDeliveryDate}
                              onChange={onChangeDesiredDeliveryDate}
                            />
                          </TableRow>
                          <TableHeader title="不具合分析" />
                          <TableRow>
                            <SelectCell
                              label="原因種別"
                              cellWidth="half"
                              value={refinementFormModel.cause}
                              onChange={onChangeCause}
                              options={refinementCauses}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="直接原因"
                              cellWidth="full"
                              value={refinementFormModel.directCause}
                              onChange={onChangeDirectCause}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="根本原因"
                              cellWidth="full"
                              value={refinementFormModel.rootCause}
                              onChange={onChangeRootCause}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="本来検知工程"
                              cellWidth="half"
                              value={refinementFormModel.properDetectionPhase}
                              onChange={onChangeProperDetectionPhase}
                              options={detectionPhases}
                            />
                          </TableRow>
                          <TableHeader title="対応内容" />
                          <TableRow>
                            <TextCell
                              label="暫定対応"
                              cellWidth="full"
                              value={refinementFormModel.interimAction}
                              onChange={onChangeInterimAction}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="恒久対応"
                              cellWidth="full"
                              value={refinementFormModel.permanentAction}
                              onChange={onChangePermanentAction}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="修正対象ドキュメント"
                              cellWidth="full"
                              value={refinementFormModel.documentsToBeCorrected}
                              onChange={onChangeDocumentsToBeCorrected}
                            />
                          </TableRow>
                          <TableRow>
                            <ToggleCell
                              label="横展開対象"
                              cellWidth="half"
                              value={
                                refinementFormModel.horizontalDeploymentTarget
                              }
                              onChange={onChangeHorizontalDeploymentTarget}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="横展開内容/横展開不要理由"
                              cellWidth="full"
                              value={
                                refinementFormModel.horizontalDeploymentContent
                              }
                              onChange={onChangeHorizontalDeploymentContent}
                            />
                          </TableRow>
                          <TableHeader title="担当" />
                          <TableRow>
                            <AutocompleteCell
                              label="チーム"
                              cellWidth="half"
                              value={wbsItemFormModel.team}
                              onChange={onChangeTeam}
                              search={searchTeam}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="責任者"
                              cellWidth="half"
                              value={wbsItemFormModel.accountable}
                              onChange={onChangeAccountable}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="担当者"
                              cellWidth="half"
                              value={wbsItemFormModel.responsible}
                              onChange={onChangeResponsible}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="アサイン先"
                              cellWidth="half"
                              value={wbsItemFormModel.assignee}
                              onChange={onChangeAssignee}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <MultiAutocompleteCell
                              label="ウォッチャー"
                              cellWidth="half"
                              value={wbsItemFormModel.watchers || []}
                              onChange={onChangeWatchers}
                              search={searchMember}
                            />
                          </TableRow>
                          <TableHeader title="期間・見積もり" />
                          <TableRow>
                            <SelectCell
                              label="難易度"
                              cellWidth="half"
                              value={wbsItemFormModel.difficulty}
                              onChange={onChangeDifficulty}
                              options={difficulties}
                            />
                            <SelectCell
                              label="優先度"
                              cellWidth="half"
                              value={wbsItemFormModel.priority}
                              onChange={onChangePriority}
                              options={priorities}
                            />
                          </TableRow>
                          <TableRow>
                            <WorkloadCell
                              label="見積り工数"
                              cellWidth="half"
                              value={wbsItemFormModel.estimatedHour || null}
                            />
                            <AutocompleteCell
                              label="スプリント"
                              cellWidth="half"
                              value={wbsItemFormModel.sprint}
                              onChange={onChangeSprint}
                              search={searchSprint}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="予定期間"
                              cellWidth="half"
                              value={wbsItemFormModel.scheduledDate}
                              onChange={onChangeScheduledDate}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="実績期間"
                              cellWidth="half"
                              value={wbsItemFormModel.actualDate}
                              onChange={onChangeActualDate}
                            />
                          </TableRow>
                          <TableHeader title="更新履歴" />
                          <TableRow>
                            <AutocompleteCell
                              label="登録者"
                              cellWidth="half"
                              value={wbsItemFormModel.createdBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="登録日時"
                              cellWidth="half"
                              value={wbsItemFormModel.createdAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="更新者"
                              cellWidth="half"
                              value={wbsItemFormModel.updatedBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="更新日時"
                              cellWidth="half"
                              value={wbsItemFormModel.updatedAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="更新回数"
                              cellWidth="half"
                              value={wbsItemFormModel.revision}
                              editable={false}
                            />
                          </TableRow>
                        </>
                      )}
                      {leftTab === 1 && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="task.description"
                          value={wbsItemFormModel.description}
                          onChange={onChangeDescription}
                        />
                      )}
                      {leftTab === 2 && <ProjectPlanTab uuid={uuid} />}
                      {leftTab === 3 && (
                        <AttachmentTab
                          attachments={wbsItemFormModel.deliverableAttachments}
                          acceptingFileTypes={[]}
                          onUpload={addAttachments}
                          onDelete={deleteAttachments}
                        />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </MainArea>
              <SideBarArea>
                <TabPanelArea>
                  <Tabs value={rightTab} onChange={onChangeRightTab}>
                    <Tab label="ディスカッション" />
                    <Tab label="実績" />
                    <Tab label="変更履歴" />
                    <Tab label="開発情報" />
                  </Tabs>
                  <TabMainArea>
                    <PanelRoot>
                      {rightTab === 0 && (
                        <DiscussionTab
                          functionUuid={functionUuid}
                          projectUuid={projectUuid}
                          uuid={uuid}
                        />
                      )}
                      {rightTab === 1 && <TaskActualWorkTabPanel uuid={uuid} />}
                      {rightTab === 2 && (
                        <ChangeLogTabPanel
                          uuids={[uuid, refinementFormModel.uuid]}
                          getParser={getChangeLogParser}
                          labelMap={labelMap}
                        />
                      )}
                      {rightTab === 3 && <DevelopmentTabPanel uuid={uuid} />}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </SideBarArea>
            </SingleSheetRoot>
          </SingleSheetWrapper>
        </>
      )}
    </PageArea>
  )
}
