import { useCallback, useState } from 'react'
import {
  UnitPricePerUserCreateInput,
  UnitPricePerUserItemInput,
  UnitPricePerUserRow,
  UnitPricePerUserRowBody,
  UnitPricePerUserSkeleton,
  UnitPricePerUserUpdateInput,
} from '../../UnitPricePerUsers/UnitPricePerUsers'
import {
  UnitPricePerProjectPartnerCrudParams,
  crudUnitPricePerProjectPartners,
  fetchUnitPricePerProjectPartners,
} from '../UnitPricePerProjectPartners'
import { AgGridTreeHelper } from '../../../containers/BulkSheetView/lib/tree'
import { generateUuid } from '../../../../utils/uuids'
import store from '../../../../store'
import { doNotRequireSave } from '../../../../store/requiredSaveData'

export const useUnitPricePerProjectPartnersData = (projectUuid: string) => {
  const [data, setDataInternal] = useState<UnitPricePerUserRow[]>([])

  const fetchRecords = useCallback(async () => {
    const response = await fetchUnitPricePerProjectPartners(projectUuid)
    const source: UnitPricePerUserSkeleton[] = response.json
    const rows = source
      .map(skltn =>
        AgGridTreeHelper.convert(
          skltn,
          (s: UnitPricePerUserSkeleton): UnitPricePerUserRow => {
            return {
              uuid: s.uuid ?? generateUuid(),
              lockVersion: s.lockVersion,
              added: !s.uuid,
              body: new UnitPricePerUserRowBody(s),
            } as UnitPricePerUserRow
          }
        )
      )
      .flat()
    setDataInternal(rows)
  }, [projectUuid])

  const refresh = useCallback(async () => {
    await fetchRecords()
    store.dispatch(doNotRequireSave())
  }, [fetchRecords])

  const createCrudParams = useCallback(
    (projectUuid: string): UnitPricePerProjectPartnerCrudParams => {
      const added: UnitPricePerUserCreateInput[] = data
        .filter(v => !!v.added && !!v.edited)
        .map(v => {
          return {
            uuid: v.uuid,
            userUuid: v.body.user!.uuid,
            unitPrices: (v.body.unitPrices ?? []).map(mp => {
              return {
                validFrom: mp.validFrom,
                unitPrice: mp.unitPrice,
              } as UnitPricePerUserItemInput
            }),
          }
        })
      const edited: UnitPricePerUserUpdateInput[] = data
        .filter(v => !v.added && !!v.edited)
        .map(v => {
          return {
            uuid: v.uuid,
            lockVersion: v.lockVersion ?? 0,
            unitPrices: (v.body.unitPrices ?? []).map(mp => {
              return {
                validFrom: mp.validFrom,
                unitPrice: mp.unitPrice,
              } as UnitPricePerUserItemInput
            }),
          }
        })
      return {
        projectUuid,
        added,
        edited,
        deleted: [],
      }
    },
    [data]
  )

  const save = useCallback(
    async (projectUuid: string) => {
      const params = createCrudParams(projectUuid)
      const response = await crudUnitPricePerProjectPartners(params)
      return response
    },
    [createCrudParams]
  )

  return {
    data,
    refresh,
    save,
  }
}
