import * as d3 from 'd3'
import { styled } from '@mui/material'
import {
  MonthlyResourceBalanceVO,
  ResourceBalanceVO,
  resourceBalanceService,
} from '../../../../../domain/value-object/ResourceBalanceVO'
import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react'
import {
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../../components'
import {
  YearMonthVO,
  yearMonthService,
} from '../../../../../domain/value-object/YearMonthVO'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { AVAILABLE_RESOURCE_COLOR, PLANNED_WORKLOAD_COLOR } from '.'
import { intl } from '../../../../../i18n'
import { useGetResourceBalance } from '../../../../../applications/usecases/resourceBalance/getResourceBalance'
import { TeamSelectOption } from '../../model/team'
import { TwoDimensionalPoint } from '../../model/chart'
import { useChartDimensions } from '../../hooks/chart/useChartDimensions'
import { ChartContainer, XAxis, YAxisWithLine } from '../../components/chart'
import { LineChart } from '../../components/chart/LineChart'
import { aggregatedWorkloadService } from '../../../../../domain/value-object/AggregatedValueVO'
import { ProjectReportConfig } from '../../model/config'

const ResourceBalanceTimeSeriesWithTitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '65%',
})

const ResourceBalanceTimeSeriesArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 12px',
})

type ResourceBalanceTimeSeriesProps = {
  project: ProjectDetail
  selectedTeam: TeamSelectOption[]
} & Pick<ProjectReportConfig, 'aggregateTarget'>

export const ResourceBalanceTimeSeries = ({
  project,
  selectedTeam,
  aggregateTarget,
}: ResourceBalanceTimeSeriesProps) => {
  const yearMonthRange = useMemo(() => {
    const projectStartYearMonth = yearMonthService.newFromString(
      project.scheduledDate.startDate
    )
    const projectEndYearMonth = yearMonthService.newFromString(
      project.scheduledDate.endDate
    )
    if (!projectStartYearMonth || !projectEndYearMonth) return undefined
    return {
      from: projectStartYearMonth,
      to: projectEndYearMonth,
    }
  }, [project])

  if (!yearMonthRange) return <></>
  return (
    <ResourceBalanceTimeSeriesWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'resourceDashboard.resourceBalanceTimeSeries',
        })}
      </WidgetTitle>
      <ResourceBalanceTimeSeriesArea>
        {selectedTeam.some(t => t.type === 'TEAM_TOTAL') && (
          <TeamTotalResourceBalanceChart
            projectUuid={project.uuid}
            yearMonthRange={yearMonthRange}
            aggregateTarget={aggregateTarget}
          />
        )}
        {selectedTeam
          .filter(t => t.type === 'SPECIFIC_TEAM')
          .map((team, i) => (
            <TeamResourceBalanceChart
              key={i}
              projectUuid={project.uuid}
              team={team}
              yearMonthRange={yearMonthRange}
              aggregateTarget={aggregateTarget}
            />
          ))}
        {selectedTeam.some(t => t.type === 'TEAM_UNSELECTED') && (
          <TeamUnselectedResourceBalanceChart
            projectUuid={project.uuid}
            yearMonthRange={yearMonthRange}
            aggregateTarget={aggregateTarget}
          />
        )}
      </ResourceBalanceTimeSeriesArea>
    </ResourceBalanceTimeSeriesWithTitleWrapper>
  )
}

type TeamTotalResourceBalanceChartProps = {
  projectUuid: string
  yearMonthRange: {
    from: YearMonthVO
    to: YearMonthVO
  }
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const TeamTotalResourceBalanceChart = ({
  projectUuid,
  yearMonthRange,
  aggregateTarget,
}: TeamTotalResourceBalanceChartProps) => {
  const [data, setData] = useState<MonthlyResourceBalanceVO[]>([])
  const { getMonthlyTotalResourceBalance } = useGetResourceBalance()
  useEffect(() => {
    const fn = async () => {
      getMonthlyTotalResourceBalance(projectUuid).then(res => setData(res))
    }
    fn()
  }, [projectUuid])
  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'teamTotal',
        })}
      </WidgetTitle>
      <WidgetArea>
        <ResourceBalanceGraph
          yearMonthRange={yearMonthRange}
          timeSeries={data}
          aggregateTarget={aggregateTarget}
        />
      </WidgetArea>
    </WidgetWithTitleWrapper>
  )
}

type TeamResourceBalanceChartProps = {
  projectUuid: string
  team: TeamSelectOption
  yearMonthRange: {
    from: YearMonthVO
    to: YearMonthVO
  }
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const TeamResourceBalanceChart = ({
  projectUuid,
  team,
  yearMonthRange,
  aggregateTarget,
}: TeamResourceBalanceChartProps) => {
  const [data, setData] = useState<MonthlyResourceBalanceVO[]>([])
  const { getMonthlyResourceBalance } = useGetResourceBalance()
  useEffect(() => {
    const fn = async () => {
      getMonthlyResourceBalance(projectUuid, team.value).then(res =>
        setData(res)
      )
    }
    fn()
  }, [projectUuid, team.value])
  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>{team.name}</WidgetTitle>
      <WidgetArea>
        <ResourceBalanceGraph
          yearMonthRange={yearMonthRange}
          timeSeries={data}
          aggregateTarget={aggregateTarget}
        />
      </WidgetArea>
    </WidgetWithTitleWrapper>
  )
}

type TeamUnselectedResourceBalanceChartProps = {
  projectUuid: string
  yearMonthRange: {
    from: YearMonthVO
    to: YearMonthVO
  }
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const TeamUnselectedResourceBalanceChart = ({
  projectUuid,
  yearMonthRange,
  aggregateTarget,
}: TeamUnselectedResourceBalanceChartProps) => {
  const [data, setData] = useState<MonthlyResourceBalanceVO[]>([])
  const { getMonthlyResourceBalance } = useGetResourceBalance()
  useEffect(() => {
    const fn = async () => {
      getMonthlyResourceBalance(projectUuid, undefined).then(res =>
        setData(res)
      )
    }
    fn()
  }, [projectUuid])
  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>{intl.formatMessage({ id: 'teamUnselected' })}</WidgetTitle>
      <WidgetArea>
        <ResourceBalanceGraph
          yearMonthRange={yearMonthRange}
          timeSeries={data}
          aggregateTarget={aggregateTarget}
        />
      </WidgetArea>
    </WidgetWithTitleWrapper>
  )
}

type ResourceBalanceGraphProps = {
  yearMonthRange: {
    from: YearMonthVO
    to: YearMonthVO
  }
  timeSeries: MonthlyResourceBalanceVO[]
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const TICK_SIZE = 6

const ResourceBalanceGraph = ({
  yearMonthRange,
  timeSeries,
  aggregateTarget,
}: ResourceBalanceGraphProps) => {
  const [
    ref,
    { width, height, marginTop, marginLeft, boundedWidth, boundedHeight },
  ] = useChartDimensions({
    height: 300,
    marginLeft: 30,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 30,
  })

  const { xScale, xTicks } = useMemo(() => {
    const minTickWidth = 30
    const maxTickIntervalCount = Math.max(
      Math.floor(boundedWidth / minTickWidth),
      2 // To avoid zero division, maxTickIntervalCount should be greater than 1.
    )
    const yearMonthDiff = yearMonthService.diff(
      yearMonthRange.to,
      yearMonthRange.from
    )
    const tickIntervalSize =
      [1, 3, 6, 12].find(
        v => v >= yearMonthDiff / (maxTickIntervalCount - 1)
      ) || 12
    const normalizedDiff =
      Math.ceil(yearMonthDiff / tickIntervalSize) * tickIntervalSize
    const normalizedTickCount = Math.ceil(
      (normalizedDiff + Math.floor(yearMonthDiff / normalizedDiff)) /
        tickIntervalSize
    )

    const fromValue = yearMonthService.getValue(yearMonthRange.from)
    const tickFrom =
      tickIntervalSize > 1
        ? fromValue - (fromValue % tickIntervalSize) + 1
        : fromValue
    const xTicks = Array.from(
      { length: normalizedTickCount + 1 },
      (_, i) => tickFrom + i * tickIntervalSize
    )

    const xScale = d3
      .scaleTime()
      .domain([
        xTicks[0] - tickIntervalSize,
        xTicks[xTicks.length - 1] + tickIntervalSize,
      ])
      .range([0, boundedWidth])

    return { xScale, xTicks }
  }, [yearMonthRange, boundedWidth])

  const {
    acculumativeAvailableResourceList,
    acculumativePlannedWorkloadList,
    availableResourceList,
    plannedWorkloadList,
  } = useMemo(() => {
    const resourceBalanceMap = new Map<number, ResourceBalanceVO>()
    for (let v of timeSeries) {
      resourceBalanceMap.set(yearMonthService.getValue(v.yearMonth), v.balance)
    }
    const sum = resourceBalanceService.sum(timeSeries.map(v => v.balance))
    let acculumativeAvailableResource = sum.availableResource
    let acculumativePlannedWorkload = aggregatedWorkloadService.getTargetValue(
      sum.plannedWorkload,
      aggregateTarget
    )
    const availableResourceList: TwoDimensionalPoint<number, number>[] = []
    const plannedWorkloadList: TwoDimensionalPoint<number, number>[] = []
    for (let i = 0; i < xTicks.length; i++) {
      const currX = xTicks[i]
      if (i === xTicks.length - 1) {
        availableResourceList.push({ x: currX, y: 0 })
        plannedWorkloadList.push({ x: currX, y: 0 })
        continue
      }
      const nextX = xTicks[i + 1]
      let availableResource = 0
      let plannedWorkload = 0
      for (let x = currX; x < nextX; x++) {
        const resourceBalance = resourceBalanceMap.get(x)
        if (!resourceBalance) continue
        availableResource += resourceBalance.availableResource
        plannedWorkload += aggregatedWorkloadService.getTargetValue(
          resourceBalance.plannedWorkload,
          aggregateTarget
        )
      }
      availableResourceList.push({ x: currX, y: availableResource })
      plannedWorkloadList.push({ x: currX, y: plannedWorkload })
    }
    const acculumativeAvailableResourceList: TwoDimensionalPoint<
      number,
      number
    >[] = []
    const acculumativePlannedWorkloadList: TwoDimensionalPoint<
      number,
      number
    >[] = []
    // ran for loop twice to improve readablity, and this won't affect performance much.
    for (let i = 0; i < xTicks.length; i++) {
      const currX = xTicks[i]
      if (i === 0) {
        acculumativeAvailableResourceList.push({
          x: currX,
          y: acculumativeAvailableResource,
        })
        acculumativePlannedWorkloadList.push({
          x: currX,
          y: acculumativePlannedWorkload,
        })
        continue
      }
      const availableResource = availableResourceList[i - 1].y
      const plannedWorkload = plannedWorkloadList[i - 1].y
      acculumativeAvailableResource -= availableResource
      acculumativePlannedWorkload -= plannedWorkload
      acculumativeAvailableResourceList.push({
        x: currX,
        y: acculumativeAvailableResource,
      })
      acculumativePlannedWorkloadList.push({
        x: currX,
        y: acculumativePlannedWorkload,
      })
    }
    return {
      acculumativeAvailableResourceList,
      acculumativePlannedWorkloadList,
      availableResourceList,
      plannedWorkloadList,
    }
  }, [aggregateTarget, timeSeries, xTicks])

  const { yScale } = useMemo(() => {
    const maxY = Math.max(
      ...acculumativeAvailableResourceList.map(v => v.y),
      ...acculumativePlannedWorkloadList.map(v => v.y)
    )
    const yScale = d3.scaleLinear().domain([0, maxY]).range([boundedHeight, 0])

    return { yScale }
  }, [
    acculumativeAvailableResourceList,
    acculumativePlannedWorkloadList,
    boundedHeight,
  ])

  const yTicks = useMemo(() => {
    return yScale.ticks(6)
  }, [yScale])

  const xAxisPoints = useMemo(
    () =>
      xTicks.map(x => ({
        scale: xScale(x),
        label: yearMonthService.toString(yearMonthService.newFromNumber(x)!),
      })),
    [xTicks, xScale]
  )

  const yAxisPoints = useMemo(
    () =>
      yTicks.map(y => ({
        scale: yScale(y),
        label: `${y.toFixed(1)}`,
      })),
    [yScale, yTicks]
  )

  return (
    <div ref={ref} style={{ height: '300px', width: '100%' }}>
      <ChartContainer
        height={height}
        width={width}
        marginLeft={marginLeft}
        marginTop={marginTop}
      >
        <XAxis
          points={xAxisPoints}
          boundedHeight={boundedHeight}
          boundedWidth={boundedWidth}
        />
        <YAxisWithLine points={yAxisPoints} boundedWidth={boundedWidth} />
        <g>
          <LineChart<number, number>
            xScale={xScale}
            yScale={yScale}
            points={acculumativeAvailableResourceList}
            color={AVAILABLE_RESOURCE_COLOR}
          />
          <LineChart<number, number>
            xScale={xScale}
            yScale={yScale}
            points={acculumativePlannedWorkloadList}
            color={PLANNED_WORKLOAD_COLOR}
          />
          {xTicks.map((_, i) => {
            if (i === xTicks.length - 1) return <></>
            const availableResource = availableResourceList[i]
            const plannedWorkload = plannedWorkloadList[i]
            return (
              <g key={i}>
                <rect
                  x={xScale(availableResource.x) - (10 + 1)}
                  y={yScale(availableResource.y)}
                  width={10}
                  height={boundedHeight - yScale(availableResource.y)}
                  fill={AVAILABLE_RESOURCE_COLOR}
                />
                <rect
                  x={xScale(plannedWorkload.x) + 1}
                  y={yScale(plannedWorkload.y)}
                  width={10}
                  height={boundedHeight - yScale(plannedWorkload.y)}
                  fill={PLANNED_WORKLOAD_COLOR}
                />
              </g>
            )
          })}
        </g>
      </ChartContainer>
    </div>
  )
}
