import { useMemo } from 'react'
import { CellDef, FormCellWidth } from '../model'
import objects from '../../../../../utils/objects'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

export type CellPropsBase = FormCellProps & {
  label: string
  editable?: boolean
  required?: boolean
}
type FormCellProps = {
  cellWidth: FormCellWidth
}
export const FormCell = styled('div')(({ cellWidth }: FormCellProps) => {
  const width = useMemo(() => {
    switch (cellWidth) {
      case 'full':
        return '100%'
      case 'half':
        return '50%'
    }
  }, [cellWidth])
  return {
    width,
    height: '34px',
    display: 'flex',
  }
})

type CellTitleProps = {
  title: string
  required?: boolean
}
export const CellTitle = ({ title, required = false }: CellTitleProps) => {
  return (
    <CellTitleArea>
      <CellTitleSpan>{title}</CellTitleSpan>
      {required && <RequiredIndicatorSpan>*</RequiredIndicatorSpan>}
    </CellTitleArea>
  )
}
const CellTitleArea = styled('div')({
  backgroundColor: colorPalette.monotone[1],
  height: '100%',
  width: '120px',
  border: `1px solid ${colorPalette.monotone[2]}`,
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  gap: '2px',
})
const CellTitleSpan = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '12px',
  lineHeight: 1,
})
const RequiredIndicatorSpan = styled('span')({
  color: colorPalette.pink[5],
})
export const CellValueArea = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  height: '100%',
  flex: '1 1 0%',
  display: 'flex',
  padding: '0 4px',
  alignItems: 'center',
})

export const TableRow = styled('div')({
  display: 'flex',
})

// BELOW ARE TEMPORARY CODE

type CellFacadeProps<D> = {
  cellDef: CellDef
  data: D
}
export const CellFacade = <D extends any>({
  cellDef,
  data,
}: CellFacadeProps<D>) => {
  const value = useMemo(
    // TODO: Consider not to rerender when an unrelevant field is changed.
    () => objects.getValue(data, cellDef.field),
    [cellDef.field, data]
  )
  switch (cellDef.type) {
    case 'TEXT':
      return <></>
  }
  return <></>
}
