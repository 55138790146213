import { useState } from 'react'
import { connect } from 'react-redux'
import { styled, Button, IconButton } from '@mui/material'
import { AllState } from '../../../../store'
import ReloadButton from '../../../components/buttons/ReloadButton'
import SubmitButton from '../../../components/buttons/SubmitButton'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import { useDialogState } from '../../../components/dialogs/useDialogState'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { intl } from '../../../../i18n'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ExclusiveToggleGroup from '../../../components/editors/toggle/ExclusiveToggleGroup'
import { DisplayTerm, ShiftType } from '../TaskActualWorkOptions'

const arrowIconStyle: React.CSSProperties = {
  color: 'gray',
  width: '14px',
  height: '14px',
  verticalAlign: 'middle',
}

const ShiftButton = styled(IconButton)({
  backgroundColor: '#FFFFFF',
  borderRadius: '0%',
  minWidth: '24px',
  margin: '1px',
  padding: '3px',
})

const TermButtonGroup = styled('div')({
  borderRadius: '5px',
  border: '1px solid lightgray',
  paddingRight: '4px',
  paddingleft: '4px',
})

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  onSubmit: () => void
  submitDisabled: boolean
  isLoading: boolean
  onCancel: () => void
  shiftDisplayTerm: (value: ShiftType) => void
  displayTerm: DisplayTerm
  onChangeDisplayTerm: (value: DisplayTerm) => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps & SettingSpeedDialProps

const TaskActualWorkHeader = ({
  toolbar,
  onChangeToolbar,
  isNotice,
  onSubmit,
  submitDisabled,
  isLoading,
  onCancel,
  notEdited,
  onClickExport,
  rowHeight,
  onClickChangeRowHeight,
  currentFormat,
  onClickChangeDateFormat,
  shiftDisplayTerm,
  displayTerm,
  onChangeDisplayTerm,
}: Props) => {
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()

  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <SubmitButton
            onClick={onSubmit}
            disabled={submitDisabled || isLoading || notEdited}
            notEdited={notEdited}
          />
          <ReloadButton onClick={openCancelDialog} disabled={isLoading} />
          <CancelConfirmDialog
            open={showCancelDialog}
            onConfirm={() => {
              closeCancelDialog()
              onCancel()
            }}
            onClose={closeCancelDialog}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={onClickExport}
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
              currentFormat={currentFormat}
              onClickChangeDateFormat={onClickChangeDateFormat}
            />
          </IconGroup>
        </HeaderGroup>
        <HeaderGroup>
          <ExclusiveToggleGroup
            value={displayTerm}
            options={[
              {
                label: intl.formatMessage({
                  id: 'week',
                }),
                value: DisplayTerm.WEEK,
              },
              {
                label: intl.formatMessage({
                  id: 'month',
                }),
                value: DisplayTerm.MONTH,
              },
            ]}
            onChange={value =>
              onChangeDisplayTerm(value as DisplayTerm.WEEK | DisplayTerm.MONTH)
            }
          />
          <TermButtonGroup>
            <Button
              sx={{
                background: 'white',
                borderRadius: '0%',
                padding: '1px',
                height: '24px',
                marginLeft: '4px',
                minWidth: '40px',
                width: 'fit-content',
                color: '#000000',
              }}
              onClick={() => shiftDisplayTerm(ShiftType.THIS_TERM)}
            >
              {intl.formatMessage({
                id:
                  displayTerm === DisplayTerm.WEEK ? 'this.week' : 'this.month',
              })}
            </Button>
            <ShiftButton onClick={() => shiftDisplayTerm(ShiftType.BACKWARD)}>
              <ArrowBackIosIcon style={arrowIconStyle} />
            </ShiftButton>
            <ShiftButton onClick={() => shiftDisplayTerm(ShiftType.FORWARD)}>
              <ArrowForwardIosIcon style={arrowIconStyle} />
            </ShiftButton>
          </TermButtonGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
      </HeaderContent>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(TaskActualWorkHeader)
