import {
  ICellEditorParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { styled } from '@mui/system'
import React from 'react'
import {
  CustomEnumValue,
  FunctionProperty,
} from '../../../../../../../../lib/commons/appFunction'
import { filterByCombination } from '../../../../../../../../lib/commons/customEnum'
import objects from '../../../../../../../../utils/objects'
import { Color } from '../../../../../../../../styles/commonStyles'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { FloatingEditableIcon } from '../text'
import Select from '../../../../../../../components/editors/select/Select'
import { RootDiv } from '../../CellRendererRoot'
import { MenuItem, SelectChangeEvent } from '@mui/material'

const getDefaultLabel = (uiMeta: FunctionProperty) => {
  const defaultValue = uiMeta.valuesAllowed.find(
    item => item.value === uiMeta.defaultValue
  )
  return defaultValue?.name || ''
}

const getLabel = (uiMeta: FunctionProperty, value?: string) => {
  const valuesAllowed = uiMeta.valuesAllowed
  if (!valuesAllowed) {
    return ''
  }
  const option = valuesAllowed.find(item => item.value === value)
  return option ? option.name : getDefaultLabel(uiMeta)
}

export const selectCellValueGetter = (params: ValueGetterParams): string => {
  if (!params.data) {
    return ''
  }
  const uiMeta: FunctionProperty = params.colDef.cellEditorParams.uiMeta
  const defaultValue = uiMeta.defaultValue
  let value
  if (uiMeta.entityExtensionUuid) {
    if (!params.data.extensions) {
      return ''
    }
    const entityExtension = params.data.extensions.find(
      v => v.uuid === uiMeta.entityExtensionUuid
    )
    value = entityExtension?.value
  } else {
    value = objects.getValue(params.data, params.colDef.field) || defaultValue
  }
  return value === 'NONE' ? '' : value
}

export const selectCellRenderer = (props: ICellRendererParams) => {
  if (!props?.colDef) return <></>
  const uiMeta = props.colDef.cellRendererParams.uiMeta
  const valuesAllowed = uiMeta.valuesAllowed
  if (!valuesAllowed) return <></>

  const option = valuesAllowed.find(item => item.value === props.value)

  return (
    <RootDiv>
      {option && option.iconUrl && (
        <img src={option.iconUrl} className="sevend-ag-cell-select-icon" />
      )}
      {props.valueFormatted}
      <FloatingEditableIcon {...props} />
    </RootDiv>
  )
}

export const selectCellValueFormatter = (params: ValueFormatterParams) => {
  return getLabel(params.colDef.cellEditorParams.uiMeta, params.value)
}

export const selectCellFilterValueFormatter = (
  params: ValueFormatterParams
) => {
  const value = getLabel(params.colDef.cellRendererParams.uiMeta, params.value)
  if (!value.trim()) {
    return '(Blanks)'
  }
  return value
}

// Styles
const StyledSelect = styled(Select)({
  width: '100%',
  height: '100%',
})
const StyledMenuItem = styled(MenuItem)<{ value?: string }>(({ value }) => {
  let style = {
    whiteSpace: 'nowrap',
    '&:hover': {
      color: Color.MAIN,
      backgroundColor: `transparent !important`,
    },
  } as any
  if (value === 'NONE') {
    style = {
      ...style,
      position: 'absolute',
      top: '-50px',
      height: '0',
    }
  }
  return style
}) as typeof MenuItem

interface Props extends ICellEditorParams {
  keyPress: number

  uiMeta: FunctionProperty
  viewMeta: ViewMeta
}

interface State {
  value: string
  open: boolean
}

class SelectCellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  private options: CustomEnumValue[] = []
  constructor(props: Props) {
    super(props)
    this.state = {
      value: this.props.value,
      open: true,
    }
    this.options = this.filterByCombination(this.props.uiMeta.valuesAllowed)
  }

  private filterByCombination = (
    options: CustomEnumValue[]
  ): CustomEnumValue[] => {
    const getCombinedValue = (path: string) => {
      return objects.getValue(this.props.node.data, path)
    }
    return filterByCombination(options, getCombinedValue)
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
    }
    if (
      this.props.keyPress === 46 ||
      this.props.keyPress === 8 /* DELETE or BACKSPACE */
    ) {
      // Delete key event is handled at BulkSheet.onDelete()
      this.setState({ value: '' })
      this.props.api.clearFocusedCell()
    } else {
      this.setState({ open: true })
    }
  }

  getValue = () => {
    return this.state.value
  }

  private valueChanged = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode
  ) => {
    this.setState(
      {
        value: event.target.value as string,
        open: false,
      },
      () => {
        this.props.api.stopEditing()
      }
    )
  }

  private closed = (keyCode: number) => {
    this.setState({ open: false })
    setTimeout(() => {
      if (keyCode === 9) {
        this.props.api.tabToNextCell()
        return
      }
      let nextRowIndex
      const current = this.props.api.getFocusedCell()
      if (!current) return
      if (keyCode === 13) {
        nextRowIndex =
          this.props.api.getLastDisplayedRow() !== current.rowIndex
            ? current.rowIndex + 1
            : current.rowIndex
      } else {
        nextRowIndex = current.rowIndex
      }
      this.props.api.stopEditing()
      this.props.api.clearRangeSelection()
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(nextRowIndex, current.column)
    }, 100)
  }

  private handleKeyDownOption = event => {
    // When Tab key is pressed, set the selected option as value
    if (event.keyCode === 9) {
      const selected = event.target.dataset.value
      this.valueChanged(
        {
          ...event,
          target: {
            ...event.target,
            value: selected,
          },
        },
        this.props.node.data
      )
    }
  }

  render() {
    if (this.options.length === 0) {
      return <></>
    }
    const hasIcon = this.options.filter(option => option.iconUrl).length
    return (
      <StyledSelect
        ref={this.ref}
        value={this.state.value}
        options={this.options}
        open={this.state.open}
        onChange={this.valueChanged}
        onClose={event => {
          // @ts-ignore
          this.closed(event.keyCode)
        }}
        defaultValue={this.props.uiMeta.defaultValue}
        getOptionIconUrl={hasIcon ? o => o.iconUrl : undefined}
        getOptionLabel={o => o.name}
        getOptionDisabled={o => o.value === 'NONE'}
        onOptionKeyDown={this.handleKeyDownOption}
        MenuItem={StyledMenuItem}
        MenuProps={{
          anchorReference: 'anchorEl',
          anchorEl: this.props.eGridCell,
        }}
      />
    )
  }
}

export default SelectCellEditor
