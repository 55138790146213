import _ from 'lodash'
import numeral from 'numeral'
import {
  CellClassParams,
  CellRendererSelectorFunc,
  CellRendererSelectorResult,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { CSSProperties } from 'styled-components'
import { intl } from '../../../../i18n'
import {
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import BoolExpression from '../../../../utils/boolExpression'
import { roundNumber, toNumber } from '../../../../utils/number'
import DateVO from '../../../../vo/DateVO'
import WorkTimeCellRenderer from '../components/CellRenderer/WorkTimeCellRenderer'
import {
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  ColumnType,
  CURRENT_MONTH_BACKGROUND_COLOR,
} from '../../../containers/commons/AgGrid'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import { EntitySearchValue } from '../../../containers/meta/repositories'
import {
  MemberWorkMonthCell,
  ResourcePlanRow,
  ResourcePlanRowBody,
  displayNameComparator,
  setUserManMonth,
} from '../resourcePlan'
import {
  INTERNAL_YEARMONTH_FORMAT,
  NUMBER_DECIMAL_POINT,
  TOTAL_WORK_MONTH_DISP_FORMAT,
  WorkingDay,
  WorkingDayCalendar,
  getMemberParamsCellStyles,
  isTeamRow,
} from './common'
import { ResourcePlanTeamCellRenderer } from '../components/CellRenderer/ResourcePlanTeamCellRenderer'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'

export const ALERT_UPPER_LIMIT_FOR_MAN_MONTH = 1.0

export const compareUnsetRow = (nodeA, nodeB, isInverted): number => {
  if (nodeA && nodeA.data && nodeA.data.type === ResourcePlanType.UNSET) {
    return isInverted ? -1 : 1
  }
  if (nodeB && nodeB.data && nodeB.data.type === ResourcePlanType.UNSET) {
    return isInverted ? 1 : -1
  }
  return 0
}

export const normalize = (value: any) => {
  if (typeof value === 'object') {
    return new EntitySearchValue(value).toString()
  }
  return value ? value : ''
}

const iconShowCheckUser = (type: string): boolean => {
  return type !== ResourcePlanType.MEMBER ? false : true
}

export const workMonthColumnComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isInverted
) => {
  const result = compareUnsetRow(nodeA, nodeB, isInverted)
  if (result !== 0) return result
  if (valueA === valueB) {
    return 0
  } else if (valueA === '-' || valueB === undefined) {
    return -1
  } else if (valueB === '-' || valueA === undefined) {
    return 1
  } else if (valueA < valueB) {
    return -1
  } else if (valueA > valueB) {
    return 1
  }
  return 0
}

export const COL_DEF_DRAG: ColDef = {
  field: 'drag',
  headerName: '',
  type: [ColumnType.drag],
  cellClassRules: {
    'hover-over-can-drop': params => {
      return (
        !params.context?.onTree &&
        params.context?.draggableNodeId &&
        params.node?.id === params.context?.draggableNodeId
      )
    },
  },
}

export const COL_DEF_ROW_NUMBER: ColDef = {
  field: 'rowNumber',
  type: [ColumnType.sequenceNo],
  resizable: false,
  cellRenderer: SequenceNoCellRenderer,
  valueGetter: params => {
    if (params.data.isTotal) {
      return 'Total'
    }
    return getRowNumber(params.node)
  },
}

export const COL_GROUP_DEF_INFORMATION_BASE: ColGroupDef = {
  groupId: 'information',
  headerName: intl.formatMessage({
    id: 'resourcePlan.crossProjects.information',
  }),
  children: [],
}

export const COL_DEF_USER_CODE: ColDef = {
  field: 'body.user.code',
  headerName: intl.formatMessage({
    id: 'project.resourcePlan.user.code',
  }),
  width: 120,
  hide: true,
  pinned: true,
  floatingFilter: true,
  filter: 'clientSideTextFilter',
  cellStyle: getMemberParamsCellStyles,
}

export const COL_DEF_TEAM: ColDef = {
  field: 'body.team',
  headerName: intl.formatMessage({
    id: 'project.resourcePlan.team',
  }),
  width: 120,
  hide: false,
  pinned: true,
  type: [ColumnType.autocomplete],
  cellRendererParams: {
    uiMeta: {},
    suppressCount: true,
    innerRenderer: ResourcePlanTeamCellRenderer,
    hideIcon: (params: ICellRendererParams<ResourcePlanRow>) =>
      !isTeamRow(params.data?.type),
  },
  editable: false,
  floatingFilter: true,
  filter: ClientSideSelectFilter,
  comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
    const result = compareUnsetRow(nodeA, nodeB, isInverted)
    if (result !== 0) return result
    const normalA = normalize(valueA)
    const normalB = normalize(valueB)
    if (normalA < normalB) {
      return -1
    } else if (normalA > normalB) {
      return 1
    }
    return 0
  },
}

export const COL_DEF_USER_NAME: ColDef = {
  field: 'body.user',
  headerName: intl.formatMessage({ id: 'project.resourcePlan.user' }),
  width: 150,
  hide: false,
  pinned: true,
  suppressMovable: true,
  cellRendererParams: {
    suppressCount: true,
    suppressDoubleClickExpand: true,
    innerRenderer: IconCellRenderer,
    labelField: 'body.user.name',
    iconUrlField: 'body.user.iconUrl',
    iconShowCheckField: 'type',
    iconShowCheck: iconShowCheckUser,
    uiMeta: {
      requiredIf: BoolExpression.of(true),
    } as Partial<FunctionProperty>,
  },
  editable: false,
  valueGetter: (params: ValueGetterParams<ResourcePlanRow>) =>
    params.data?.body?.user?.name || '',
  filter: ClientSideTextFilter,
  floatingFilter: true,
}

export const COL_DEF_USER_DIVISION: ColDef = {
  field: 'body.user.division',
  headerName: intl.formatMessage({
    id: 'project.resourcePlan.user.division.displayName',
  }),
  width: 120,
  hide: true,
  pinned: true,
  type: [ColumnType.autocomplete],
  cellRendererParams: {
    uiMeta: {},
  },
  floatingFilter: true,
  filter: ClientSideSelectFilter,
  comparator: displayNameComparator,
  cellStyle: getMemberParamsCellStyles,
}

export const COL_DEF_USER_POSITION: ColDef = {
  field: 'body.user.position',
  headerName: intl.formatMessage({
    id: 'project.resourcePlan.user.position.displayName',
  }),
  width: 120,
  hide: true,
  pinned: true,
  type: [ColumnType.autocomplete],
  cellRendererParams: {
    uiMeta: {},
  },
  floatingFilter: true,
  filter: ClientSideSelectFilter,
  comparator: displayNameComparator,
  cellStyle: getMemberParamsCellStyles,
}

export const COL_DEF_TOTAL_BASE: ColDef = {
  field: 'total',
  headerName: intl.formatMessage({
    id: 'project.resourcePlan.total',
  }),
  width: 78,
  hide: false,
  pinned: true,
  cellRenderer: NumberCellRenderer,
  cellRendererParams: {
    numberWithCommas: true,
    decimalPoints: NUMBER_DECIMAL_POINT,
  },
  floatingFilter: true,
  filter: 'clientSideNumberFilter',
  comparator: workMonthColumnComparator,
  cellClass: 'numberStyle',
}

const getWorkingTimeTotalLabel = (
  yearMonth: string,
  workingDayCalendars: WorkingDayCalendar
): string => {
  const target: WorkingDay = workingDayCalendars[yearMonth]
  return `${target ? target.businessDays : 0}d/${
    target ? target.workHours : 0
  }h`
}

export const setWorkMonthValueToRow = (
  targetRow: ResourcePlanRow,
  yearMonth: string,
  newValue: number | undefined
): boolean => {
  if (!targetRow || !yearMonth) return false
  const targetRowBody: ResourcePlanRowBody | undefined = targetRow.body
  const memberWorkMonth = targetRowBody.memberWorkMonths?.find(
    cell => cell.yearMonth === yearMonth
  )
  if (memberWorkMonth && memberWorkMonth.manMonth === newValue) {
    return false
  }
  const oldValue = memberWorkMonth?.manMonth
  if (!memberWorkMonth) {
    if (!targetRowBody.memberWorkMonths) {
      targetRowBody.memberWorkMonths = []
    }
    targetRowBody.memberWorkMonths.push({
      yearMonth,
      manMonth: newValue,
    })
  } else {
    memberWorkMonth.manMonth = newValue
  }

  targetRow.edited = true
  if (!targetRow.editedData) {
    targetRow.editedData = { [yearMonth]: oldValue }
  } else if (!targetRow.editedData.hasOwnProperty(yearMonth)) {
    targetRow.editedData[yearMonth] = oldValue
  }
  return true
}

export const findAndSetWorkMonthValue = (
  userUuid: string | undefined,
  teamUuid: string | undefined,
  rowType: ResourcePlanType | undefined,
  gridApi: GridApi | null | undefined,
  columnId: string,
  newValue: number | undefined
) => {
  if (!columnId || (!userUuid && !teamUuid) || !gridApi) return
  gridApi?.forEachNode((node: RowNode<ResourcePlanRow>) => {
    const nodeRow: ResourcePlanRow | undefined = node.data
    const targetNodeRowBody: ResourcePlanRowBody | undefined = nodeRow?.body
    if (
      nodeRow &&
      targetNodeRowBody &&
      (!teamUuid || targetNodeRowBody.team?.uuid === teamUuid) &&
      (!userUuid || targetNodeRowBody.user?.uuid === userUuid) &&
      (!rowType || nodeRow.type === rowType)
    ) {
      setWorkMonthValueToRow(nodeRow, columnId, newValue)
    }
  })
}

export const setUserManMonthValue = (
  memberViewGridApi: GridApi | null | undefined,
  teamViewGridApi: GridApi | null | undefined,
  memberUuid: string | undefined,
  columnId: string | undefined,
  newValue: number
) => {
  if (!memberUuid || !columnId) return
  ;[memberViewGridApi, teamViewGridApi]
    .filter(api => !!api)
    .forEach((api: GridApi | null | undefined) => {
      api?.forEachNode((node: RowNode<ResourcePlanRow>) => {
        const row: ResourcePlanRow | undefined = node.data
        if (!row?.body.user?.uuid || row.body.user.uuid !== memberUuid) return
        setUserManMonth(row, columnId, newValue)
      })
    })
}

export const workMonthColValueSetter = (
  params: ValueSetterParams,
  adjustWorkMonthValueFunc: (
    params: ValueSetterParams,
    memberUuid: string | undefined,
    columnId: string | undefined
  ) => void
) => {
  const colId: string | undefined = params.colDef.colId
  if (!colId) return false
  store.dispatch(requireSave())

  const newValue = params.newValue ? toNumber(params.newValue) : undefined
  if (!setWorkMonthValueToRow(params.data, colId, newValue)) {
    return false
  }

  findAndSetWorkMonthValue(
    params.data?.body?.user?.uuid,
    params.data?.body?.team?.uuid,
    undefined,
    params.context.syncGrid?.api,
    colId,
    newValue
  )

  adjustWorkMonthValueFunc(params, params.data?.body?.user?.uuid, colId)
  ;[params.api, params.context?.syncGrid?.api]
    .filter(api => !!api)
    .forEach((api: GridApi | null | undefined) => {
      api?.refreshCells({ force: true })
    })

  return true
}

export const getTotalWorkMonthColCellRendererSelectorResult = (
  params: ICellRendererParams
): CellRendererSelectorResult => {
  return {
    component: WorkTimeCellRenderer,
    params: {
      ...params,
      valueFormatted: numeral(
        roundNumber(params.value, NUMBER_DECIMAL_POINT)
      ).format(TOTAL_WORK_MONTH_DISP_FORMAT),
    },
  }
}

export const existsWorkMonthData = (
  params: ICellRendererParams,
  nodeIsTargetOfJudge: (
    params: ICellRendererParams,
    node: RowNode<ResourcePlanRow>
  ) => boolean,
  api?: GridApi | null | undefined
): boolean => {
  const gridApi = api ?? params.api
  if (!gridApi) return false

  let exists: boolean = false
  gridApi?.forEachNode((node: RowNode<ResourcePlanRow>) => {
    if (exists || !nodeIsTargetOfJudge(params, node)) return

    const memberWorkMonth = node.data?.body.memberWorkMonths?.find(
      cell => cell.yearMonth === params.colDef?.colId!
    )
    if (
      memberWorkMonth === undefined ||
      memberWorkMonth.manMonth === undefined
    ) {
      return
    }
    exists = true
  })
  return exists
}

export const getCalcWorkMonthColCellRendererSelectorResult = (
  params: ICellRendererParams,
  nodeIsTargetOfHideZeroJudge: (
    params: ICellRendererParams,
    node: RowNode<ResourcePlanRow>
  ) => boolean,
  api?: GridApi | null | undefined
): CellRendererSelectorResult => {
  return {
    component: NumberCellRenderer,
    params: {
      ...params,
      numberWithCommas: true,
      decimalPoints: NUMBER_DECIMAL_POINT,
      uiMeta: {} as Partial<FunctionProperty>,
      hideZero: (params: ICellRendererParams) => {
        return !existsWorkMonthData(params, nodeIsTargetOfHideZeroJudge, api)
      },
    },
  }
}

export const getDefaultWorkMonthColCellRendererSelectorResult = (
  params: ICellRendererParams
): CellRendererSelectorResult => {
  return {
    component: NumberCellRenderer,
    params: {
      ...params,
      numberWithCommas: true,
      decimalPoints: NUMBER_DECIMAL_POINT,
      uiMeta: {
        propertyType: PropertyType.Number,
        minNumber: 0.0,
      } as Partial<FunctionProperty>,
    },
  }
}

export const highliteWorkMonthCell = (
  params: CellClassParams,
  upperLimitIgnoreType?: ResourcePlanType
): boolean => {
  if (params.value < 0) return true

  const row: ResourcePlanRow = params.data
  if (row.isTotal) return false
  if (row.type === upperLimitIgnoreType) return false
  if (ALERT_UPPER_LIMIT_FOR_MAN_MONTH < params.value) return true

  const workMonth: MemberWorkMonthCell | undefined =
    row.body.memberWorkMonths?.find(
      (workMonth: MemberWorkMonthCell) =>
        workMonth.yearMonth === params.colDef?.colId
    )
  return (
    !!workMonth?.userManMonth &&
    ALERT_UPPER_LIMIT_FOR_MAN_MONTH < workMonth.userManMonth
  )
}

export const generateWorkMonthColDef = (
  yearMonth: string,
  workingDayCalendars: WorkingDayCalendar,
  editable: (params: EditableCallbackParams<ResourcePlanRow>) => boolean,
  valueGetter: (params: ValueGetterParams) => any,
  valueSetter: (params: ValueSetterParams) => boolean,
  cellRendererSelector: CellRendererSelectorFunc,
  cellStyle?: (params: CellClassParams) => CSSProperties
): ColDef => {
  return {
    colId: yearMonth,
    field: yearMonth,
    headerName: getWorkingTimeTotalLabel(yearMonth, workingDayCalendars),
    width: 78,
    editable,
    suppressMovable: true,
    valueGetter,
    valueSetter,
    cellEditor: 'textEditor',
    cellEditorParams: {
      uiMeta: {
        propertyType: PropertyType.Number,
        minNumber: 0.0,
      } as Partial<FunctionProperty>,
    },
    cellRendererSelector,
    cellStyle: (params: CellClassParams) => {
      let style: CSSProperties = {
        justifyContent: 'flex-end',
        color: 'black',
      }
      const isCurrentMonth =
        yearMonth === DateVO.now().format(INTERNAL_YEARMONTH_FORMAT)
      if (isCurrentMonth) {
        style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
      }
      const row: ResourcePlanRow = params.data
      if (row.type === ResourcePlanType.UNSET && params.value < 0) {
        style.color = 'red'
      }
      if (cellStyle) {
        return {
          ...style,
          ...cellStyle(params),
        }
      }
      return style
    },

    floatingFilter: true,
    filter: ClientSideNumberFilter,
    comparator: workMonthColumnComparator,
    cellClass: 'numberStyle',
  } as ColDef
}
