import {
  BulkSheetOptions,
  BulkSheetState,
  BulkSheetContext,
  BulkSheetSpecificProps,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import positionApi, {
  PositionDetail,
  PositionBatchDeltaInput,
  PositionInput,
  PositionDeltaInput,
} from '../../../lib/functions/position'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import ContextMenu, {
  ContextMenuGroup,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { GetContextMenuItemsParams } from 'ag-grid-community'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class PositionRow extends RowData {
  code?: string
  displayName = ''
  officialName?: string
  revision?: string
}

class PositionRowDataSpec extends RowDataSpec<PositionDetail, PositionRow> {
  createNewRow(): PositionRow {
    return new PositionRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: PositionRow
    parent: PositionRow
  }): PositionRow {
    return params.child
  }
  createRowByResponse(response: PositionDetail): PositionRow {
    return {
      ...response,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(original: PositionRow): PositionRow {
    return {
      ...original,
      code: undefined,
      revision: undefined,
    }
  }
}

interface PositionBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    PositionDetail,
    PositionRow,
    BulkSheetState
  > {}

export default class PositionsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  PositionDetail,
  PositionRow,
  BulkSheetState
> {
  addable = true
  draggable = true
  columnAndFilterStateKey = UiStateKey.PositionUuidsColumnAndFilterState
  enableExcelExport = true
  rowDataSpec = new PositionRowDataSpec()
  lockedColumns = ['position.code']
  pinnedColumns = ['position.code', 'position.displayName']
  groupColumnWidth = 200
  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return positionApi.getAll()
  }

  onSubmit = (
    ctx: PositionBulkSheetContext,
    data: {
      added: PositionRow[]
      edited: {
        before: PositionRow
        after: PositionRow
      }[]
      deleted: PositionRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: PositionBatchDeltaInput = {
      added: data.added.map(this.createRequestByRow),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v)),
      deleted: data.deleted.map(this.createDeleteRequestByRow),
    }
    return positionApi.updateBatchDelta(input)
  }

  private createRequestByRow = (row: PositionRow): PositionInput => {
    return row
  }

  private createDeltaRequestByRow = ({
    before,
    after,
  }: {
    before: PositionRow
    after: PositionRow
  }): PositionDeltaInput => {
    return {
      uuid: after.uuid,
      parentUuid: {
        oldValue: before.parentUuid,
        newValue: after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: before.prevSiblingUuid,
        newValue: after.prevSiblingUuid,
      },
      displayName:
        before.displayName !== after.displayName
          ? {
              oldValue: before.displayName,
              newValue: after.displayName,
            }
          : undefined,
      officialName:
        before.officialName !== after.officialName
          ? {
              oldValue: before.officialName,
              newValue: after.officialName,
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (row: PositionRow) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: PositionBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
        ctx.generateUtilityContextMenu(params),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
}
