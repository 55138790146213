import { Box, Typography, styled } from '@mui/material'
import { CardContent, EditableCardItem, SelectOption } from '../../Kanban'
import { getOpenWbsItemDetailSpec } from '../../../../../lib/functions/wbsItem'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getPathByExternalId } from '../../..'
import store, { AllState } from '../../../../../store'
import {
  FunctionLayer,
  pushFunctionLayer,
} from '../../../../../store/functionLayer'
import { AutocompleteItem } from './AutocompleteItem'
import KanbanContext from '../../KanbanContext'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import { DateRangeItem } from './DateRangeItem'
import Workload from '../../../../../lib/functions/workload'
import {
  DateTermVO,
  dateTermVoService,
} from '../../../../../domain/value-object/DateTermVO'
import { DateTerm } from '../../../../../utils/date'
import { useDispatch, useSelector } from 'react-redux'
import { OrganizationDetail } from '../../../../../lib/functions/tenant'
import TaskActualWorkDialog from '../../../../components/dialogs/TaskActualWorkDialog'
import { EstimatedWorkloadItem } from './EstimatedWorkloadItem'
import { DisplayNameItem } from './DisplayNameItem'
import { useDialogState } from '../../../../components/dialogs/useDialogState'
import AlertDialog from '../../../../components/dialogs/AlertDialog'

export type Props = {
  content: CardContent
  onChange?: (id: string, field: EditableCardItem, changed: any) => void
  onCloseWbsItemDetail?: (wbsItemCode: string) => void
  save?: () => Promise<void>
}

export const WbsStatusCardContent = memo(
  ({ content, onChange, onCloseWbsItemDetail, save }: Props) => {
    const dispatch = useDispatch()
    const [
      isOpenActulaWorkDialog,
      openActulaWorkDialog,
      closeActulaWorkDialog,
    ] = useDialogState()
    const [isOpenConfirmDialog, openConfirmDialog, closeConfirmDialog] =
      useDialogState()
    const [detailLayer, setDetailLayer] = useState<FunctionLayer | undefined>(
      undefined
    )

    const organization = useSelector<AllState, OrganizationDetail>(
      state => state.tenant.organization!
    )
    const hasRequiredSaveData = useSelector<AllState, boolean>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const { projectMembers, priorities, errors } = useContext(KanbanContext)

    const scheduledDateTerm = useMemo(
      () =>
        dateTermVoService.construct(
          content.scheduledDate?.startDate || undefined,
          content.scheduledDate?.endDate || undefined
        ),
      [content.scheduledDate]
    )

    const onChangeDisplayName = useCallback(
      (v: string) => {
        onChange?.(content.uuid!, 'displayName', v)
      },
      [content.uuid]
    )

    const onChangeMember = useCallback(
      (path: EditableCardItem, value?: SelectOption) => {
        onChange?.(content.uuid!, path, value)
      },
      [content.uuid]
    )

    const onChangePriority = useCallback(
      (value?: SelectOption) => {
        onChange?.(content.uuid!, 'priority', value?.value)
      },
      [content.uuid]
    )

    const onChangeScheduledDateTerm = useCallback(
      (value?: DateTermVO) => {
        const formatted = value ? dateTermVoService.format(value) : undefined
        const updated = formatted
          ? ({
              startDate: formatted.from,
              endDate: formatted.to,
            } as DateTerm)
          : undefined
        onChange?.(content.uuid!, 'scheduledDate', updated)
      },
      [content.uuid]
    )

    const onChangeEstimatedWorkload = useCallback(
      (hour: number) => {
        if (content.estimatedWorkload?.hour === hour) {
          return
        }
        const updated = Workload.from({
          hour: hour,
          standard: organization,
        })
        onChange?.(content.uuid!, 'estimatedWorkload', updated)
      },
      [content.estimatedWorkload?.hour, content.uuid, organization]
    )

    const openDetailInLayer = useCallback(async () => {
      if (isOpenConfirmDialog) {
        closeConfirmDialog()
        await save?.()
      }

      if (!detailLayer) {
        return
      }

      dispatch(
        pushFunctionLayer({
          ...detailLayer,
          onClose: async () => {
            onCloseWbsItemDetail?.(detailLayer.code!)
          },
        })
      )
    }, [dispatch, detailLayer, save, onCloseWbsItemDetail, isOpenConfirmDialog])

    const onClickWbsItemIcon = useCallback(
      async (event: any) => {
        if (!detailLayer) {
          return
        }
        if (event && (event['ctrlKey'] || event['metaKey'])) {
          window.open(
            `${getPathByExternalId(
              detailLayer.externalId
            )}/${detailLayer.code!}`
          )
          return
        }
        if (hasRequiredSaveData) {
          openConfirmDialog()
          return
        }
        openDetailInLayer()
      },
      [detailLayer, hasRequiredSaveData, openDetailInLayer]
    )

    const hasActualHour = useMemo(
      () => content.actualHour !== 0,
      [content.actualHour]
    )

    const accountable = useMemo(
      () =>
        content.accountable
          ? ({
              ...content.accountable,
              value: content.accountable?.uuid,
            } as SelectOption)
          : null,
      [content.accountable]
    )
    const responsible = useMemo(
      () =>
        content.responsible
          ? ({
              ...content.responsible,
              value: content.responsible?.uuid,
            } as SelectOption)
          : null,
      [content.responsible]
    )
    const assignee = useMemo(
      () =>
        content.assignee
          ? ({
              ...content.assignee,
              value: content.assignee?.uuid,
            } as SelectOption)
          : null,
      [content.assignee]
    )
    const priority = useMemo(
      () =>
        content.priority
          ? priorities.find(p => p.value === content.priority)
          : null,
      [priorities, content.priority]
    )

    useEffect(() => {
      // for opening wbs item detail single sheet in layer
      const fn = async () => {
        const openDetailSpec = await getOpenWbsItemDetailSpec(true, content)
        setDetailLayer(openDetailSpec.layer)
      }
      fn()
    }, [content.ticketType, content.type, content.code])

    return (
      <>
        <Typography
          component="div"
          sx={{
            fontWeight: '400',
            color: colorPalette.monotone[4],
            textAlign: 'right',
          }}
        >
          {content.path}
        </Typography>
        <DisplayNameItem
          value={content.displayName}
          uuid={content.uuid!}
          wbsItemType={content.wbsItemType!}
          onChange={onChangeDisplayName}
          onClickIcon={onClickWbsItemIcon}
          errors={errors}
        />
        <RowArea sx={{ flexWrap: 'wrap' }}>
          <RowArea sx={{ height: '35px' }}>
            <LabelTypography
              variant="h6"
              width={40}
              sx={{ marginRight: '2px' }}
            >
              {`${intl.formatMessage({ id: 'projectPlan.accountable' })}:`}
            </LabelTypography>
            <AutocompleteItem
              key={`${content.uuid}-accountable-${accountable?.value}`}
              options={projectMembers}
              value={accountable}
              id={`${content.uuid}-accountable`}
              onChange={(value?: SelectOption) =>
                onChangeMember('accountable', value)
              }
            />
          </RowArea>
          <RowArea sx={{ height: '35px' }}>
            <LabelTypography
              variant="h6"
              width={40}
              sx={{ marginRight: '2px' }}
            >
              {`${intl.formatMessage({ id: 'projectPlan.responsible' })}:`}
            </LabelTypography>
            <AutocompleteItem
              key={`${content.uuid}-responsible-${responsible?.value}`}
              options={projectMembers}
              value={responsible}
              id={`${content.uuid}-responsible`}
              onChange={(value?: SelectOption) =>
                onChangeMember('responsible', value)
              }
            />
          </RowArea>
        </RowArea>
        <RowArea sx={{ flexWrap: 'wrap' }}>
          <RowArea sx={{ height: '35px' }}>
            <LabelTypography
              variant="h6"
              width={64}
              sx={{ marginRight: '2px' }}
            >
              {`${intl.formatMessage({ id: 'projectPlan.assignee' })}:`}
            </LabelTypography>
            <AutocompleteItem
              key={`${content.uuid}-assignee-${assignee?.value}`}
              options={projectMembers}
              value={assignee}
              id={`${content.uuid}-assignee`}
              onChange={(value?: SelectOption) =>
                onChangeMember('assignee', value)
              }
            />
          </RowArea>
          <RowArea sx={{ height: '35px' }}>
            <LabelTypography
              variant="h6"
              width={40}
              sx={{ marginRight: '2px' }}
            >
              {`${intl.formatMessage({ id: 'projectPlan.priority' })}:`}
            </LabelTypography>
            <AutocompleteItem
              key={`${content.uuid}-priority-${priority?.value}`}
              options={priorities}
              value={priority}
              id={`${content.uuid}-priority`}
              onChange={(value?: SelectOption) => onChangePriority(value)}
            />
          </RowArea>
        </RowArea>
        <RowArea>
          <LabelTypography variant="h6" width={28} sx={{ marginRight: '8px' }}>
            {`${intl.formatMessage({ id: 'projectPlan.term' })}:`}
          </LabelTypography>
          <DateRangeItem
            key={`${content.uuid}-scheduledDate-${scheduledDateTerm.from}-${scheduledDateTerm.to}`}
            value={scheduledDateTerm}
            onChange={onChangeScheduledDateTerm}
          />
        </RowArea>
        <RowArea sx={{ flexWrap: 'wrap' }}>
          <RowArea sx={{ height: '35px', marginRight: '8px' }}>
            <LabelTypography variant="h6" width={52}>
              {`${intl.formatMessage({
                id: 'kanban.wbsItem.workload.scheduled',
              })}:`}
            </LabelTypography>
            <EstimatedWorkloadItem
              key={`${content.uuid}-estimatedWorkload-${content.estimatedWorkload?.hour}`}
              workload={content.estimatedWorkload}
              onChange={onChangeEstimatedWorkload}
            />
          </RowArea>
          <RowArea sx={{ height: '35px' }}>
            <LabelTypography variant="h6" width={52}>
              {`${intl.formatMessage({
                id: 'kanban.wbsItem.workload.actual',
              })}:`}
            </LabelTypography>
            <RowArea sx={{ padding: '3px 0 0 0' }}>
              <StyledTaskActualWork
                key={`${content.uuid}-actualHour-${content.actualHour}`}
                canClick={hasActualHour}
                onClick={() => hasActualHour && openActulaWorkDialog()}
              >
                <Typography
                  color={
                    hasActualHour
                      ? colorPalette.blue[8]
                      : colorPalette.monotone[10]
                  }
                >
                  {content.actualHour}
                </Typography>
              </StyledTaskActualWork>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: colorPalette.monotone[5],
                  minWidth: 'fit-content',
                }}
              >
                {intl.formatMessage({ id: 'workloadUnit.hour' })}
              </Typography>
            </RowArea>
          </RowArea>
        </RowArea>
        {isOpenActulaWorkDialog && (
          <TaskActualWorkDialog
            open={true}
            taskUuid={content.uuid ?? ''}
            closeHandler={() => closeActulaWorkDialog()}
          />
        )}
        {isOpenConfirmDialog && (
          <AlertDialog
            isOpen={true}
            title={intl.formatMessage({
              id: 'kanban.dialog.confirm.save.title',
            })}
            message={intl.formatMessage({
              id: 'kanban.dialog.confirm.save.message',
            })}
            submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
            submitHandler={openDetailInLayer}
            closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
            closeHandler={closeConfirmDialog}
          />
        )}
      </>
    )
  },
  (prev, next) => prev.content === next.content
)

const RowArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flex: 1,
  width: '100%',
})

const LabelTypography = styled(Typography)({
  fontSize: '12px',
  minWidth: 'fit-content',
  padding: '3px 0 0 0',
})

const StyledTaskActualWork = styled(Box)<{ canClick: boolean }>(props => ({
  width: '40px',
  borderRadius: '25%',
  padding: '4px',
  transition: 'background-color 0.5s ease',
  '&:hover': {
    cursor: props.canClick ? 'pointer' : 'default',
  },
  display: 'flex',
  justifyContent: 'right',
}))
