import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'

interface Props {
  onClick: () => void
}
const FavoriteColumnFilterButton = ({ onClick }: Props) => {
  return (
    <BootstrapTooltip
      arrow={true}
      title={intl.formatMessage({
        id: 'bulksheetview.columnsetting.storedColumnSetting',
      })}
    >
      <button
        style={{
          background: 'transparent',
          border: `1px dashed ${colorPalette.monotone[2]}`,
          borderRadius: '50%',
          padding: '4px 5px',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '5px',
          marginRight: '5px',
          display: 'flex',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <AddRoundedIcon
          sx={{
            height: '14px',
            width: '14px',
            color: colorPalette.monotone[3],
            flex: '1',
          }}
        />
      </button>
    </BootstrapTooltip>
  )
}

export default FavoriteColumnFilterButton
