import cellRenderer from './cellRenderer'
import valueGetter from './valueGetter'
import valueSetter from './valueSetter'
import cellStyle from './cellStyle'
import { WorkloadUnit } from '../../../../../../../../lib/functions/workload'
import { getSelectedWorkloadUnit } from '../../../../../../../components/toggleGroups'
export const getUnitByFieldName = (field: string) => {
  const splitted = field.split('.')
  if (['month', 'day', 'hour'].includes(splitted[splitted.length - 1])) {
    return WorkloadUnit[splitted[splitted.length - 1].toUpperCase()]
  } else {
    return getSelectedWorkloadUnit()
  }
}

export const getWorkloadFieldName = (field: string) => {
  const splitted = field.split('.')
  const lastField = splitted[splitted.length - 1]
  if (['month', 'day', 'hour'].includes(lastField)) {
    return field.substr(0, field.lastIndexOf('.'))
  }
  return field
}

export const getWbsItemFieldName = (field: string) => {
  return field.substr(0, field.indexOf('.'))
}

export default { cellRenderer, valueSetter, valueGetter, cellStyle }
