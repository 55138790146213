import React from 'react'
import { InputBase } from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import WorkloadVO from '../../../../../../vo/WorkloadVO'
import { intl } from '../../../../../../i18n'
import { AllState } from '../../../../../../store'
import { connect } from 'react-redux'

const HideWheelStyle = {
  margin: 0,
  '-webkit-appearance': 'none',
  appearance: 'none',
}
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  '& > input::-webkit-inner-spin-button': HideWheelStyle,
  '& > input::-webkit-outer-spin-button': HideWheelStyle,
})
const StyledInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  display: 'flex',
  '& > input::-webkit-inner-spin-button': HideWheelStyle,
  '& > input::-webkit-outer-spin-button': HideWheelStyle,
})
const Divider = styled('span')({
  backgroundColor: '#CCCCCC',
  width: '1px',
  margin: '0 3px 0 0',
})
const WorkloadUnitLabel = styled('span')({
  margin: 'auto',
  whiteSpace: 'nowrap',
})

interface ReduxStateProps {
  dailyWorkHours: number
  monthlyWorkDays: number
}

interface WorkloadCellProps
  extends CellPropsBase<WorkloadVO>,
    ReduxStateProps {}

interface WorkloadCellState extends CellStateBase {}

class WorkloadCell extends DataCellBase<
  WorkloadVO,
  WorkloadCellProps,
  WorkloadCellState
> {
  validate = (value?: WorkloadVO) => {
    return undefined
  }

  normalizeValue = value => {
    let newValue = value ? parseFloat(value) : undefined
    if (newValue !== undefined && newValue < 0) {
      newValue = 0
    }
    return newValue
  }

  onChangeHour = event => {
    this.props.onChange(
      WorkloadVO.fromHour(this.normalizeValue(event.target.value), {
        daylyWorkingHours: this.props.dailyWorkHours,
        monthlyWorkingDays: this.props.monthlyWorkDays,
      })
    )
  }
  onChangeDay = event => {
    this.props.onChange(
      WorkloadVO.fromDay(this.normalizeValue(event.target.value), {
        daylyWorkingHours: this.props.dailyWorkHours,
        monthlyWorkingDays: this.props.monthlyWorkDays,
      })
    )
  }
  onChangeMonth = event => {
    this.props.onChange(
      WorkloadVO.fromMonth(this.normalizeValue(event.target.value), {
        daylyWorkingHours: this.props.dailyWorkHours,
        monthlyWorkingDays: this.props.monthlyWorkDays,
      })
    )
  }

  render() {
    const { value, cellDef } = this.props
    return (
      <RootDiv>
        <StyledInput
          type="number"
          value={value ? value.getHour() : undefined}
          onChange={this.onChangeHour}
          disabled={!cellDef.editable}
          placeholder={cellDef.placeholder}
        />
        <WorkloadUnitLabel>
          /
          {intl.formatMessage({
            id: 'wbsItem.workload.unit.hour',
          })}
        </WorkloadUnitLabel>
        <Divider />
        <StyledInput
          type="number"
          value={value ? value.getDay() : undefined}
          onChange={this.onChangeDay}
          disabled={!cellDef.editable}
          placeholder={cellDef.placeholder}
        />
        <WorkloadUnitLabel>
          /
          {intl.formatMessage({
            id: 'wbsItem.workload.unit.day',
          })}
        </WorkloadUnitLabel>
        <Divider />
        <StyledInput
          type="number"
          value={value ? value.getMonth() : undefined}
          onChange={this.onChangeMonth}
          disabled={!cellDef.editable}
          placeholder={cellDef.placeholder}
        />
        <WorkloadUnitLabel>
          /
          {intl.formatMessage({
            id: 'wbsItem.workload.unit.month',
          })}
        </WorkloadUnitLabel>
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  dailyWorkHours: state.tenant.organization?.dailyWorkHours!,
  monthlyWorkDays: state.tenant.organization?.monthlyWorkDays!,
})

export default connect(mapStateToProps)(WorkloadCell)
