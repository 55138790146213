import store from '../../../store'
import {
  CellClassParams,
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  ColGroupDef,
  ICellEditorParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR,
  dateValueParser,
} from '../../containers/commons/AgGrid'
import TaskActualWorkApi, {
  TaskActualWorkDetail,
  TaskActualWorkUpdateBatchDeltaRequest,
} from '../../../lib/functions/taskActualWork'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRowByResponse,
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
  taskActualResultExists,
  WbsItemBatchDeltaRequest,
  WbsItemDeltaInput,
  WbsItemRow,
  WbsItemUpdateBatchDeltaRequest,
} from '../../../lib/functions/wbsItem'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_FORMAT_WITH_DAY,
  formatDateTime,
  isSaturday,
} from '../../../utils/date'
import { requireSave } from '../../../store/requiredSaveData'
import { UiStateKey } from '../../../lib/commons/uiStates'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
  RestoreSearchConditionOptions,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import ViewMeta from '../../containers/meta/ViewMeta'
import { generateUuid } from '../../../utils/uuids'
import moment from 'moment'
import { getUrlQueryStringParams } from '../../../utils/urls'
import _ from 'lodash'
import {
  getDefaultSearchFilter,
  SearchFilter,
  toPlainSearchFilter,
  toSearchFilter,
  WbsItemSearchConditionKey,
} from '../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import Workload, { round } from '../../../lib/functions/workload'
import {
  Comment,
  commentListToSummary,
  CommentSummary,
} from '../../../store/comments'
import DateVO from '../../../vo/DateVO'
import objects from '../../../utils/objects'
import { openComment } from '../../../store/information'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import {
  getTypeIndex,
  WbsItemTypeCellValue,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { List } from 'immutable'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendar,
} from '../../../lib/functions/organizationWorkingDayCalendar'
import { dateVoService } from '../../../domain/value-object/DateVO'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
  TERM = 'TERM',
}

export enum DisplayTerm {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum ShiftType {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
  THIS_TERM = 'THIS_TERM',
}

export interface ActualWorkRowData {
  [key: string]: number
}

export class TaskActualWorkRow extends RowData {
  wbsItemType?: WbsItemType
  deliverableName?: string
  projectPlanPath?: string
  task?: TaskData
  revision?: string
  commentSummary?: CommentSummary
  typeIndex?: number = -1
}

export interface ActualWork {
  uuid: string
  lockVersion: number
  hour: number
  actualDate: string
  isEdited: boolean
}

export class TaskData extends WbsItemRow {
  actualWorks?: ActualWork[]
}

export const createTaskActualWorkRowByResponse = (
  response: TaskActualWorkDetail,
  viewMeta: ViewMeta
): TaskActualWorkRow => {
  const taskActualWork = response
  const task = taskActualWork.task
  const cumulation = taskActualWork.cumulation
  const actualWorks: ActualWork[] = []
  const actualWorkData: ActualWorkRowData = {}
  taskActualWork.actualWorks.forEach(actualWork => {
    const key = moment(actualWork.actualDate).format(DISPLAY_DATE_FORMAT)
    actualWorkData[key] = actualWork.hour
    actualWorks.push({
      uuid: actualWork.uuid,
      lockVersion: actualWork.lockVersion,
      hour: actualWork.hour,
      actualDate: key,
      isEdited: false,
    })
  })
  const taskData: TaskData = createRowByResponse(task, cumulation)
  taskData.ticketType = task.ticketType
  taskData.actualWorks = actualWorks
  taskActualWork.actualWorks.forEach(actualWork => {
    const key = moment(actualWork.actualDate).format(DISPLAY_DATE_FORMAT)
    actualWorkData[key] = actualWork.hour
  })
  const typeIndex = getTypeIndex(taskData.baseWbsItemType!)

  return {
    uuid: task.uuid,
    lockVersion: task.lockVersion,
    isEdited: false,
    wbsItemType: WbsItemType.TASK,
    deliverableName: taskActualWork.deliverableName,
    projectPlanPath: taskActualWork.projectPlanPath,
    task: taskData,
    revision: task.revision,
    createdBy: task.createdBy,
    createdAt: formatDateTime(task.createdAt),
    updatedBy: task.updatedBy,
    updatedAt: formatDateTime(task.updatedAt),
    commentSummary: taskActualWork.commentSummary,
    ...actualWorkData,
    typeIndex,
  }
}

export interface TaskActualWorkState extends BulkSheetState {
  searchText?: string
  searchFilter?: SearchFilter
  searchFilterSnapshotOnSearch?: SearchFilter // Snapshot on click search button.
  dateTerm: string[]
  startDate: DateVO | undefined
  endDate: DateVO | undefined
  openSettingDialog: boolean
}

interface TaskActualWorkBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    TaskActualWorkDetail,
    TaskActualWorkRow,
    TaskActualWorkState
  > {}

class TaskActualWorkRowDataSpec extends RowDataSpec<
  TaskActualWorkDetail,
  TaskActualWorkRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      projectPlanPath: {
        cellStyle: { direction: 'rtl' },
      },
      displayName: {
        cellStyle: { minWidth: '100', width: '500' },
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      wbsItemTypeColumn: {
        ...columnTypes().wbsItemTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data.task?.baseWbsItemType?.getNameWithSuffix()
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data.task?.baseWbsItemType)?.name
        },
      },
    }
  }

  createNewRow(): TaskActualWorkRow {
    return new TaskActualWorkRow(generateUuid())
  }

  overwriteRowItemsWithParents(params: {
    child: TaskActualWorkRow
    parent: TaskActualWorkRow
  }): TaskActualWorkRow {
    return params.child
  }

  createRowByResponse(
    response: TaskActualWorkDetail,
    viewMeta: ViewMeta
  ): TaskActualWorkRow {
    return createTaskActualWorkRowByResponse(response, viewMeta)
  }
}

export const getDateTerm = (startDate?: DateVO, endDate?: DateVO) => {
  if (startDate && endDate) {
    const diff =
      moment(endDate.toDate()).diff(moment(startDate.toDate()), 'days') + 1
    return Array.from({ length: diff }).map((_, index) =>
      moment(startDate.toDate()).add(index, 'day').format(DISPLAY_DATE_FORMAT)
    )
  }

  const yesterday = moment().subtract(1, 'days')
  return Array.from({ length: 2 }).map((_, index) =>
    yesterday.add(index, 'day').format(DISPLAY_DATE_FORMAT)
  )
}

export default class TaskActualWorkOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  TaskActualWorkDetail,
  TaskActualWorkRow,
  TaskActualWorkState
> {
  fetchDataOnInit = true
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.TaskActualWorkColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx => `${ctx.state.uuid}`
  rowDataSpec = new TaskActualWorkRowDataSpec()
  pinnedColumns = [
    'taskActualWork.action',
    'taskActualWork.task.code',
    'taskActualWork.wbsItemType',
    'taskActualWork.task.status',
    'taskActualWork.task.substatus',
    'taskActualWork.projectPlanPath',
    'taskActualWork.deliverableName',
    'taskActualWork.task.displayName',
    'taskActualWork.task.responsible',
    'taskActualWork.task.assignee',
  ]
  lockedColumns = ['taskActualWork.action', 'taskActualWork.task.code']
  customRenderers = {
    'taskActualWork.task.displayName': 'wbsItemTreeCellRenderer',
  }
  customColumnTypes = {
    'taskActualWork.wbsItemType': [ColumnType.wbsItemType],
    'taskActualWork.task.status': [ColumnType.wbsItemStatus],
    'taskActualWork.task.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'taskActualWork.task.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'taskActualWork.task.actualDate.startDate': [ColumnType.wbsItemActualDate],
    'taskActualWork.task.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'taskActualWork.projectPlanPath': ['projectPlanPath'],
    'taskActualWork.commentSummary.latestComment': [ColumnType.comment],
    'taskActualWork.task.priority': ['priorityColumn'],
  }

  updateDefaultState = async (state: TaskActualWorkState) => {
    state.gridOptions.columnDefs = [...state.gridOptions.columnDefs!]
    let urlParams = getUrlQueryStringParams()

    if (urlParams) {
      let paramFilter = {}
      if (urlParams.code) {
        paramFilter['code'] = urlParams.code.split(',').join(' ')
      }

      if (urlParams.status === 'all') {
        paramFilter['status'] = [
          WbsItemStatus.TODO,
          WbsItemStatus.DOING,
          WbsItemStatus.REVIEW,
          WbsItemStatus.DONE,
          WbsItemStatus.DISCARD,
        ]
      }
      state.searchFilter = Object.assign(getDefaultSearchFilter(), paramFilter)
    }

    return {
      ...state,
      dateTerm: getDateTerm(),
      openSettingDialog: false,
    }
  }

  dynamicColumns = {
    'taskActualWork.actualWorks': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: TaskActualWorkBulkSheetContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        const actualWorkValueSetter = (params: ValueSetterParams) => {
          if (
            params.oldValue === params.newValue ||
            !params.node ||
            params.node.group
          ) {
            return false
          }
          store.dispatch(requireSave())
          const newValue = Number(Number(params.newValue || 0))
          const field = params.column.getColDef().field
          params.data[field!] = newValue
          const index = params.data.task.actualWorks.findIndex(
            (v: ActualWork) => v.actualDate === field
          )
          if (0 <= index) {
            params.data.task.actualWorks[index] = {
              ...params.data.task.actualWorks[index],
              hour: newValue,
              isEdited: true,
            }
          } else {
            params.data.task.actualWorks.push({
              actualDate: field,
              hour: newValue,
              isEdited: true,
            })
          }
          return true
        }
        const dateTerm = ctx.state.dateTerm
        const dateTermColumnGroupDef: ColGroupDef[] = []
        const dateTermColumnDefs: ColDef[] = []
        const response = await getOrganizationWorkingDayCalendar({
          startDate: new Date(dateTerm[0]),
          endDate: new Date(dateTerm[dateTerm.length - 1]),
        })
        const workingDayCalendar = new OrganizationWorkingDayCalendar(
          response.json
        )
        dateTerm.forEach(date => {
          let headerClass = ''
          if (workingDayCalendar.isHoliday(date)) {
            headerClass = 'sevend-ag-grid-date-header-holiday'
          }
          if (isSaturday(date)) {
            headerClass = 'sevend-ag-grid-date-header-saturday'
          }
          dateTermColumnDefs.push({
            ...baseColumnDef,
            headerName: moment(date).format(DISPLAY_DATE_FORMAT_WITH_DAY),
            colId: date,
            field: date,
            type: ColumnType.number,
            lockPosition: true,
            valueSetter: actualWorkValueSetter,
            hide: false,
            width: 80,
            cellStyle: (params: CellClassParams) => {
              let style: CellStyle | CellStyleFunc = {
                justifyContent: 'flex-end',
              }
              if (
                params.colDef.field === moment(new Date()).format('YYYY/MM/DD')
              ) {
                style.color = 'blue'
                style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
              }
              return style
            },
            valueGetter: (params: ValueGetterParams) => {
              const field = params.colDef?.field || ''
              const data: TaskActualWorkRow = params.data
              if (data.isTotal) {
                let totalHour = 0
                params.api.forEachNodeAfterFilter(node => {
                  const data: TaskActualWorkRow = node.data
                  const actualHour = data[field]
                  if (actualHour) {
                    totalHour += actualHour
                  }
                })
                return totalHour
              }
              if (!field) return undefined
              return (data.task?.actualWorks ?? []).find(
                v => v.actualDate === field
              )?.hour
            },
            headerClass: headerClass,
          })
        })
        dateTermColumnGroupDef.push({
          headerName: ctx.props.intl.formatMessage({
            id: 'workReport.column.actualHour',
          }),
          children: dateTermColumnDefs,
        })
        return dateTermColumnGroupDef
      },
    },
  }

  restoreSearchCondition = (
    searchCondition: any,
    ctx: TaskActualWorkBulkSheetContext,
    options?: RestoreSearchConditionOptions
  ) => {
    if (options?.isRestoredSavedSearchConditionByUser) {
      // Restored by user on select saved search condition.
      if (searchCondition.projectUuid === ctx.state.uuid) {
        const searchFilter = {
          ...getDefaultSearchFilter(),
          ...toSearchFilter(searchCondition.searchFilter),
        }
        ctx.setState(
          {
            searchText: searchCondition.searchText,
            searchFilter,
            searchFilterSnapshotOnSearch: _.cloneDeep(searchFilter),
          },
          ctx.refreshDataWithLoading
        )
      }
      return
    }
    if (ctx.state.searchFilter && ctx.state.searchFilter.code) {
      const searchFilter = {
        ...getDefaultSearchFilter(),
        code: ctx.state.searchFilter.code,
        status: ctx.state.searchFilter.status,
        disableRestore: true, // disable restore when the search condition from other screen
      }
      ctx.setState(
        {
          searchFilter,
          searchFilterSnapshotOnSearch: _.cloneDeep(searchFilter),
        },
        ctx.refreshDataWithLoading // kick only when open from other screens
      )
      return
    }
    if (searchCondition.projectUuid === ctx.state.uuid) {
      // restore search text
      ctx.setState({
        searchText: _.isEmpty(searchCondition.searchText)
          ? ''
          : searchCondition.searchText,
      })
      // restore filter panel condition
      if (
        searchCondition.searchFilter &&
        !searchCondition.searchFilter.disableRestore
      ) {
        const searchFilter = {
          ...getDefaultSearchFilter(),
          ...toSearchFilter(searchCondition.searchFilter),
        }
        ctx.setState(
          {
            searchFilter,
            searchFilterSnapshotOnSearch: _.cloneDeep(searchFilter),
          },
          ctx.refreshDataWithLoading
        )
      }
    }
  }

  getSearchCondition = (state: TaskActualWorkState) => {
    return {
      searchText: state.searchText,
      searchFilter: state.searchFilter
        ? toPlainSearchFilter(state.searchFilter)
        : undefined,
      projectUuid: state.uuid,
    }
  }
  getOpenDetailSpec = (row: TaskActualWorkRow): Promise<OpenDetailSpec> => {
    return getOpenWbsItemDetailSpec(true, row.task as WbsItemRow)
  }
  getUpdatedRowAncestors = async (
    uuid: string
  ): Promise<TaskActualWorkDetail> => {
    const actualWorkDetail = (
      await TaskActualWorkApi.getTaskActualWorkByTaskUuid(uuid)
    ).json as TaskActualWorkDetail
    const userUuid = store.getState().user.user?.uuid
    return {
      ...actualWorkDetail,
      actualWorks: actualWorkDetail.actualWorks.filter(
        w => w.projectMember.uuid === userUuid
      ),
    }
  }

  async getAll(state: TaskActualWorkState): Promise<APIResponse> {
    if (
      // when no search cond, do not send request to server
      !state.searchText &&
      !state.searchFilter &&
      !state.total
    ) {
      return successDummyResponse
    }
    const response = await TaskActualWorkApi.findTaskActualWork({
      projectUuid: state.uuid,
      all: state.searchText,
      searchFilter: state.searchFilter,
    })
    return response.json.total ? response : successDummyResponse
  }

  onSubmit = async (
    ctx: TaskActualWorkBulkSheetContext,
    data: {
      edited: {
        before: TaskActualWorkRow
        after: TaskActualWorkRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse[]> => {
    const state = ctx.state
    // Update tasks
    const taskResponse = await this.updateTasks(
      this.createTaskUpdateBatchRequest(state, data.edited, viewMeta)
    )

    // Update actual work hour
    const actualWorkResponse = await TaskActualWorkApi.updateBatchDelta(
      this.createActualWorkUpdateBatchRequest(
        state.dateTerm,
        ctx.rowDataManager.getAllRows()
      )
    )

    // Update sprint backlogs
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      data.edited.map(v => v.after?.task as WbsItemRow).filter(v => !!v),
      Object.fromEntries(
        taskResponse.json.edited.map(v => [v.uuid, v.lockVersion])
      ),
      data.edited.map(v => v.before?.task as WbsItemRow).filter(v => !!v)
    )
    const springBacklogResponse =
      await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    return [taskResponse, actualWorkResponse, springBacklogResponse]
  }

  async updateTasks(
    request: WbsItemUpdateBatchDeltaRequest
  ): Promise<APIResponse> {
    const data: WbsItemBatchDeltaRequest = { wbsItems: request, watchers: [] }
    return WbsItemApi.updateBatchDelta(data)
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: TaskActualWorkRow
      after: TaskActualWorkRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.task!,
        after: editedRowAfter.task!,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  private createTaskUpdateBatchRequest = (
    state: TaskActualWorkState,
    rows: {
      before: TaskActualWorkRow
      after: TaskActualWorkRow
    }[],
    viewMeta: ViewMeta
  ) => {
    const taskBatchRequest: WbsItemUpdateBatchDeltaRequest = {
      projectUuid: state.uuid,
      added: [],
      edited: [],
      deleted: [],
    }
    rows.forEach(row => {
      taskBatchRequest.edited.push(
        createDeltaRequestByRow(
          {
            before: row.before.task!,
            after: row.after.task!,
          },
          viewMeta,
          'taskActualWork.task',
          {
            before: row.before.extensions,
            after: row.after.extensions,
          }
        )
      )
    })
    return taskBatchRequest
  }

  private createActualWorkUpdateBatchRequest = (
    dateTerm: string[],
    data: TaskActualWorkRow[]
  ) => {
    const actualWorkBatchRequest: TaskActualWorkUpdateBatchDeltaRequest = {
      taskActualWorks: [],
    }
    data.forEach((row: TaskActualWorkRow) => {
      const task = row.task!
      dateTerm.forEach(date => {
        const value: number | null = row[date]
        const actualWork: ActualWork | undefined = task.actualWorks!.find(
          (v: ActualWork) => v.actualDate === date
        )
        const getActualDateTime = (date: string) => {
          return {
            startDateTime: Date.parse(`${date} 00:00:00`),
            endDateTime: Date.parse(`${date} 00:00:00`),
          }
        }
        if (
          (!actualWork && value && value >= 0.01) ||
          (actualWork && actualWork.isEdited)
        ) {
          actualWorkBatchRequest.taskActualWorks.push({
            taskUuid: row.uuid,
            hour: value && value >= 0.01 ? round(value) : 0,
            actualDate: date.replace(/\//g, '-'),
            actualDateTime: getActualDateTime(date),
          })
        }
      })
    })
    return actualWorkBatchRequest
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (['wbsItemType'].includes(field)) {
      return (params: ValueGetterParams) => {
        if (!params.data.task?.baseWbsItemType) return {}
        return {
          wbsItemType: params.data.task?.baseWbsItemType,
          typeIndex: params.data.typeIndex,
        } as WbsItemTypeCellValue
      }
    }
    if (
      [
        'task.estimatedStoryPoint',
        'task.estimatedWorkload.month',
        'task.estimatedWorkload.day',
        'task.estimatedWorkload.hour',
        'task.actualHour',
      ].includes(field)
    ) {
      return (params: ValueGetterParams) => {
        const data: TaskActualWorkRow = params.data
        if (data.isTotal) return this.calcTotal(params, field)
        return _.get(data, field)
      }
    }
    if (field === 'task.ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.task?.baseWbsItemType
      }
    }
    return undefined
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (
      'task.estimatedWorkload.hour' === field ||
      'task.estimatedWorkload.day' === field ||
      'task.estimatedWorkload.month' === field
    ) {
      return (params: ValueSetterParams) => {
        if (!params.data || !params.data.task || params.data.isTotal) {
          return false
        }
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        if ('task.estimatedWorkload.hour' === field) {
          params.data.task.estimatedWorkload = Workload.from({
            hour: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        } else if ('task.estimatedWorkload.day' === field) {
          params.data.task.estimatedWorkload = Workload.from({
            day: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        } else if ('task.estimatedWorkload.month' === field) {
          params.data.task.estimatedWorkload = Workload.from({
            month: params.newValue,
            standard: { dailyWorkHours, monthlyWorkDays },
          })
        }
        return true
      }
    }
    if (
      ['task.actualDate.startDate', 'task.actualDate.endDate'].includes(field)
    ) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) return false
        const value = !params.newValue
          ? params.newValue
          : dateValueParser(params.newValue)
        params.data.isEdited = true
        objects.setValue(params.data, field, value)
        if (!!value) {
          const actualDate = params.data.task.actualDate
          if (!!actualDate.startDate && !!actualDate.endDate) {
            // Keep start and end date order
            if (
              new DateVO(actualDate.endDate).isBefore(
                new DateVO(actualDate.startDate)
              )
            ) {
              params.data.task.actualDate = {
                startDate: field.includes('start')
                  ? actualDate.startDate
                  : actualDate.endDate,
                endDate: field.includes('end')
                  ? actualDate.endDate
                  : actualDate.startDate,
              }
            }
          }
        }
        return true
      }
    }
    return undefined
  }

  private calcTotal(params: ValueGetterParams, field: string) {
    let total = 0
    if (params.api) {
      params.api.forEachNodeAfterFilter(node => {
        const data: TaskActualWorkRow = node.data
        const amount = _.get(data, field)
        if (amount) {
          total += amount
        }
      })
    }
    return total
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'task.actualHour') {
      return {
        link: (row: TaskActualWorkRow) =>
          row && taskActualResultExists(row.task as WbsItemRow),
      }
    }
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'task.scheduledDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.task?.scheduledDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'task.actualDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.task?.actualDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
  }

  getDetailColumnCellRendererParams = () => {
    return {
      path: 'task.description',
      openComment: (
        row: TaskActualWorkRow,
        ctx: TaskActualWorkBulkSheetContext
      ) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const dataUuid = row.task?.uuid
        if (!applicationFunctionUuid || !dataUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: dataUuid,
            projectUuid: row.task?.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.task!)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      getCommentSummary: (row: TaskActualWorkRow) => row.commentSummary,
    }
  }

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: TaskActualWorkRow = params.data
    if (row && taskActualResultExists(row.task as WbsItemRow)) {
      return row.uuid
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (['task.priority'].includes(field)) {
      return 65
    }
    if (
      [
        'task.estimatedWorkload.month',
        'task.estimatedWorkload.day',
        'task.estimatedWorkload.hour',
        'task.actualHour',
      ].includes(field)
    ) {
      return 80
    }
    if (field === 'task.status') {
      return 90
    }
    if (['task.code', 'wbsItemType'].includes(field)) {
      return 100
    }
    if (['deliverableName'].includes(field)) {
      return 130
    }
    return undefined
  }
  refreshConditionAndColumn = (ctx: TaskActualWorkBulkSheetContext) => {
    ctx.refreshDataWithLoading()
    ctx.setState({ dateTerm: getDateTerm() })
    ctx.refreshDynamicColumns()
  }
  hiddenSearchFilterIcon = true

  onClickSetting = (ctx: TaskActualWorkBulkSheetContext) => {
    ctx.setState({
      openSettingDialog: true,
    })
  }

  pinnedTopRowData: TaskActualWorkRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      treeValue: [],
      projectPlanPath: '',
      isTotal: true,
      isViewOnly: true,
    },
  ]

  onFilterChanged = (ctx: TaskActualWorkBulkSheetContext): void => {
    if (ctx.gridApi) {
      const totalRow = ctx.gridApi.getPinnedTopRow(0)
      if (totalRow) {
        ctx.gridApi.refreshCells({
          rowNodes: [totalRow],
          force: true,
        })
      }
    }
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'task.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  mergeRowCommentSummary = (
    targetRow: TaskActualWorkRow,
    comments: List<Comment> | undefined
  ): TaskActualWorkRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }
}

export const taskActualWorkSearchCondition = [
  {
    key: WbsItemSearchConditionKey.CODE,
    position: { row: 1, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.TICKET_TYPES,
    position: { row: 2, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.STATUS,
    position: { row: 3, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.DELIVERABLE,
    position: { row: 4, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.DISPLAY_NAME,
    position: { row: 5, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.DESCRIPTION,
    position: { row: 6, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.TEAM,
    position: { row: 7, column: 1, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.ACCOUNTABLE,
    position: { row: 7, column: 2, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.RESPONSIBLE,
    position: { row: 8, column: 1, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.ASSIGNEE,
    position: { row: 8, column: 2, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.SCHEDULED_START_DATE,
    position: { row: 9, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.SCHEDULED_END_DATE,
    position: { row: 9, column: 2, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.ACTUAL_START_DATE,
    position: { row: 10, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.ACTUAL_END_DATE,
    position: { row: 10, column: 2, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.CREATED_AT,
    position: { row: 11, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.UPDATED_AT,
    position: { row: 11, column: 2, size: 2 },
  },
]
