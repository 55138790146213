import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import {
  Avatar,
  MenuProps,
  MenuItem,
  styled,
  Typography,
  Select,
} from '@mui/material'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { getLabel } from '../../../../../lib/commons/i18nLabel'
import { moveToNextRow } from './index'
import {
  CUSTOM_ENUM_NONE,
  filterValuesByCombination,
} from '../../../../../lib/commons/customEnum'
import objects from '../../../../../utils/objects'
import { Color } from '../../../../../styles/commonStyles'

interface Props extends ICellEditorParams {
  customEnumCode?: string
  getOptionKey?: (data) => string
  getOptions?: (data) => Promise<any[]>
  combinedValuePath?: (combinedEnumCode: string) => string
  valuesAllowed?: CustomEnumValue[]
}

export const MyWbsItemsCustomEnumCellEditor = forwardRef(
  (
    {
      customEnumCode,
      getOptionKey,
      getOptions,
      combinedValuePath,
      value,
      api,
      context,
      colDef,
      column,
      rowIndex,
      data,
      valuesAllowed,
    }: Props,
    ref
  ) => {
    const [val, setVal] = useState(
      value === CUSTOM_ENUM_NONE ? '' : value ?? ''
    )
    const [options, setOptions] = useState<CustomEnumValue[]>()

    const firstRendered = useRef(true)
    const refInput = useRef<HTMLSelectElement>(null)

    useEffect(() => {
      refInput.current?.focus()
    }, [])

    useEffect(() => {
      if (firstRendered.current) {
        firstRendered.current = false
        return
      }
      api.stopEditing()
    }, [val])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return val
        },
      }
    })

    useEffect(() => {
      const setOption = async () => {
        let optionList: CustomEnumValue[]
        if (customEnumCode) {
          if (getOptions) {
            optionList = await getOptions(data)
          } else if (getOptionKey) {
            optionList = context[customEnumCode].get(getOptionKey(data))
          } else {
            optionList = context[customEnumCode]
          }
          setOptions(
            filterValuesByCombination(optionList, code =>
              objects.getValue(data, combinedValuePath?.(code))
            )
          )
        } else if (valuesAllowed) {
          setOptions(valuesAllowed)
        }
      }
      setOption()
    }, [customEnumCode, data, valuesAllowed, getOptionKey, getOptions])

    if (!options) return <></>

    return (
      <EditorSelect
        ref={refInput}
        variant="standard"
        value={val}
        disableUnderline={true}
        open={true}
        onChange={e => setVal(e.target.value)}
        onClose={() => setTimeout(() => api.stopEditing(), 100)}
        MenuProps={selectCellMenuProps}
      >
        {options.map((option: CustomEnumValue, i: number) => {
          return (
            <EditorSelectMenuItem
              key={`${colDef.field}-${option.value}`}
              value={option.value}
              onKeyDown={e =>
                setTimeout(() => {
                  if (e.key === 'Enter') {
                    moveToNextRow(api, rowIndex, column)
                  } else if (e.key === 'Tab') {
                    api.tabToNextCell()
                  }
                }, 100)
              }
              backgroundColor={option.backgroundColor}
            >
              {option.iconUrl && (
                <Avatar
                  variant="circular"
                  src={option.iconUrl}
                  sx={{ margin: '0px 5px', width: 20, height: 20 }}
                />
              )}
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {getLabel(option.nameI18n) ?? option.name ?? option.value}
              </Typography>
            </EditorSelectMenuItem>
          )
        })}
      </EditorSelect>
    )
  }
)

const EditorSelect = styled(Select)({
  width: '100%',
  height: '100%',
  '& .MuiSelect-select': {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

const EditorSelectMenuItem = styled(MenuItem)(
  (props: { backgroundColor?: string }) => ({
    display: 'flex',
    margin: '2px',
    height: '26px',
    backgroundColor: props.backgroundColor
      ? `${props.backgroundColor}66`
      : 'inherit',
    transparent: '50%',
    borderRadius: '3px',
    '&:focus': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
    '&:hover': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
  })
)

const selectCellMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}
