import { useCallback, useEffect, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { styled } from '@mui/system'
import {
  Box,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { BackgroundColor } from '../../../../styles/commonStyles'
import SearchButton from '../../../components/buttons/SearchButton'
import { muiTheme } from '../../../../styles/muiTheme'
import objects from '../../../../utils/objects'
import * as SearchConditions from '../../../containers/SearchConditions'
import { customEnumRepository } from '../../../../domain/repository'
import CodeVO from '../../../../domain/value-object/CodeVO'
import { CustomEnumCode } from '../../../../lib/functions/customEnumValue'
import { ProgressReportSearchConditionVO } from '../../../../domain/value-object/ProgressReportSearchConditionVO'

// Styles
const theme = muiTheme
const RootDiv = styled('div')({
  display: 'flex',
})
const StyledIconButton = styled(IconButton)({
  background: BackgroundColor.BLUE_GRADATION,
  width: '24px',
  height: '24px',
  padding: 0,
  boxShadow: '0px 1px 1px #00000033',
})
const Icon = styled(SearchIcon)({
  color: '#ffffff',
  width: '14px',
  height: '14px',
})
const StyledPopover = styled(Popover)({
  overflow: 'visible',
  width: '400px',
  '& .MuiPopover-paper': {
    overflow: 'visible',
    width: '400px',
  },
})
const SearchConditionBox = styled('div')({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const StyledTable = styled(Table)({
  width: '100%',
  height: '100%',
})
const SearchButtonBox = styled('div')({
  margin: `${theme.spacing(1)} 0 0 0`,
})

export interface Props extends WrappedComponentProps {
  condition: ProgressReportSearchConditionVO
  searchConditionKeys: SearchConditionKey[]
  onChange: (newCondition: ProgressReportSearchConditionVO) => void
  onSearch: () => void
}

export enum SearchConditionKey {
  TEAM = 'team',
  ACCOUNTABLE = 'accountable',
  RESPONSIBLE = 'responsible',
  PRIORITY = 'priority',
}

const ProgressReportSearchConditionButton = ({
  intl,
  condition,
  searchConditionKeys,
  onChange,
  onSearch,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [priorityOption, setPriorityOption] = useState<
    { label: string; value: string }[]
  >([])
  const onButtonClick = useCallback(
    (event: any) => setAnchorEl(anchorEl ? null : event.currentTarget),
    []
  )
  const onClose = () => setAnchorEl(null)

  const getHeader = (key: SearchConditionKey) => {
    return intl.formatMessage({
      id: `progressReport.${key}`,
    })
  }

  const renderSelectCondition = (
    key: SearchConditionKey,
    options: any[],
    disabled?: boolean
  ) => {
    return (
      <SearchConditions.SelectCondition
        id={key}
        value={objects.getValue(condition, key)}
        options={options}
        onChange={onChange}
        header={getHeader(key)}
        disabled={disabled}
        clearable={true}
      />
    )
  }

  const renderEntitySearchCondition = (
    key: SearchConditionKey,
    referenceEntity: string
  ) => {
    return (
      <SearchConditions.EntitySearchCondition
        id={key}
        value={objects.getValue(condition, key)}
        onChange={onChange}
        referenceEntity={referenceEntity}
        header={getHeader(key)}
      />
    )
  }

  const renderCondition = (key: SearchConditionKey) => {
    switch (key) {
      case SearchConditionKey.TEAM:
        return renderEntitySearchCondition(key, 'Team')
      case SearchConditionKey.ACCOUNTABLE:
      case SearchConditionKey.RESPONSIBLE:
        return renderEntitySearchCondition(key, 'ProjectMember')
      case SearchConditionKey.PRIORITY:
        return renderSelectCondition(key, priorityOption)
    }
  }

  useEffect(() => {
    const getPriorityList = async () => {
      const customEnum = await customEnumRepository.getByCodeAndGroupKeys(
        new CodeVO(CustomEnumCode.WBS_PRIORITY),
        []
      )
      const priorityList = customEnum.values.map(v => {
        return { label: v.name, value: v.value }
      })
      setPriorityOption(priorityList)
    }
    getPriorityList()
  }, [])

  return (
    <RootDiv>
      <Box sx={{ margin: '0 10px' }}>
        <StyledIconButton
          onClick={onButtonClick}
          aria-describedby="progress-report-search-button"
        >
          <Icon color="action" />
        </StyledIconButton>
      </Box>
      <StyledPopover
        id="progress-report-search-conditions"
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <SearchConditionBox>
          <TableContainer component={Paper}>
            <StyledTable size="small" aria-label="customized table">
              <TableBody>
                {searchConditionKeys.map(key => renderCondition(key))}
              </TableBody>
            </StyledTable>
          </TableContainer>
          <SearchButtonBox>
            <SearchButton
              onClick={() => {
                onSearch()
                onClose()
              }}
            />
          </SearchButtonBox>
        </SearchConditionBox>
      </StyledPopover>
    </RootDiv>
  )
}

export default injectIntl(ProgressReportSearchConditionButton)
