import { useCallback, useState } from 'react'
import TaskActualWorkApi, {
  ActualWork,
  TaskActualWorkList,
} from '../../../../lib/functions/taskActualWork'
import { open } from '../../../router'

export type TaskActualWorkTaskDialogState = {
  total: number
  date: string
  taskActualWorks: ActualWork[]
  anchorElement: Element | null
  clickEvent: {
    x: number
    y: number
    view: {
      innerWidth: number
      innerHeight: number
    } | null
  }
  openDetail?: () => void
}

export type openTaskActualWorkListProps = {
  userUuid: string | undefined
  projectUuid?: string | undefined
  projectCode?: string | undefined
  targetDate: string | undefined
  event: PointerEvent | undefined | null
}

export const useTaskActualWorkTaskDialogState = () => {
  const [taskDialogState, setTaskDialogState] =
    useState<TaskActualWorkTaskDialogState | null>(null)

  const openTaskActualWorkList = useCallback(
    async ({
      userUuid,
      projectUuid,
      projectCode,
      targetDate,
      event,
    }: openTaskActualWorkListProps) => {
      if (!userUuid || !targetDate || !event) return

      const tasks: TaskActualWorkList = (
        await TaskActualWorkApi.getTaskActualWorkList({
          userUuid,
          targetDate,
          projectUuid,
        })
      ).json
      if (!tasks) return
      const taskActualWorks = !projectUuid
        ? tasks.taskActualWorks
        : tasks.taskActualWorks.map(actualWork => {
            actualWork.project = undefined
            return actualWork
          })

      const openDetail = projectCode
        ? () => {
            open(
              `/actualWork/${projectCode}?code=${tasks.taskActualWorks
                .map(actualWork => {
                  return actualWork.taskCode
                })
                .join(',')}`,
              event,
              undefined,
              true
            )
          }
        : undefined

      setTaskDialogState({
        total: tasks?.total || 0,
        date: targetDate,
        taskActualWorks,
        anchorElement: event.target as Element,
        clickEvent: event,
        openDetail,
      })
    },
    []
  )

  return {
    taskDialogState,
    setTaskDialogState,
    openTaskActualWorkList,
  }
}
