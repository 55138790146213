import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import { TeamProps } from './team'
import { UserDetail } from './user'

export enum ResourcePlanType {
  MEMBER = 'MEMBER',
  TEAM = 'TEAM',
  MEMBER_PER_TEAM = 'MEMBER_PER_TEAM',
  UNSET = 'UNSET',
}

export interface ResourcePlanNewDetail extends Tree<ResourcePlanNewDetail> {
  type: ResourcePlanType
  user: UserDetail
  team: TeamProps
  projectReleasedDate: string
  memberWorkMonths: MemberWorkMonthDetail[]
}

export interface MemberWorkMonthDetail {
  month: Date
  manMonth: number
}

export interface GetResourcePlanNewProps {
  projectUuid: string
  startDate: string
  endDate: string
}

export interface ResourcePlanNewInput {
  projectUuid: string
  uuid?: string
  lockVersion: number
  resourcePlanMembers: ResourcePlanNewMemberInput[]
  added: ResourcePlanNewMemberInput[]
  edited: ResourcePlanNewMemberInput[]
  deleted: ResourcePlanNewMemberInput[]
  memberSiblings: ResourcePlanMemberSiblingInput[]
  teamSiblings: ResourcePlanTeamSiblingInput[]
}

export interface ResourcePlanNewMemberInput {
  userUuid?: string
  teamUuid?: string
  deltaMemberWorkMonths: MemberWorkMonth[]
  // TODO Remove
  memberWorkMonths?: MemberWorkMonth[]
}

export interface ResourcePlanMemberSiblingInput {
  uuid: string
  userUuid: string
  prevSiblingUuid?: string
}

export interface ResourcePlanTeamSiblingInput {
  uuid: string
  teamUuid: string
  prevSiblingUuid?: string
}

interface MemberWorkMonth {
  year: number
  month: number
  manMonth: number
}

export async function getResourcePlan(
  request: GetResourcePlanNewProps
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/resource_plans/new',
    request
  )
}

export async function updateBatch(
  request: ResourcePlanNewInput
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/resource_plans/delta/batch',
    request
  )
}

export async function updateMemberWork(
  request: ResourcePlanNewInput
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/resource_plans/updateMemberWork',
    request
  )
}

export async function getResourceSummary({
  projectUuid,
}: {
  projectUuid: string
}): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/resource_plans/summary',
    { projectUuid }
  )
}
