export type SlackWorkspace = {
  tenantUuid?: string
  uuid?: string
  slackWorkspaceId?: string
  slackWorkspaceName?: string
  state?: string
}

export const hasConnectedData = (workspaces?: SlackWorkspace[]): boolean =>
  !!workspaces && workspaces.some(v => v.slackWorkspaceId != null)
export const hasFailedData = (workspaces?: SlackWorkspace[]): boolean =>
  !!workspaces && workspaces.some(v => v.state != null)
