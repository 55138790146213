import { useCallback } from 'react'
import API from '../../lib/commons/api'
import { ProductivityRepository } from '../../applications/ports/productivityRepository'
import { DailyCpiVO, EvmVO } from '../../domain/value-object/EvmVO'

export const useProductivityRepositoryAdaptor = (): ProductivityRepository => {
  const getEvmValue = useCallback(
    async (
      projectUuid: string,
      rootProjectPlanUuid?: string,
      teamUuid?: string
    ): Promise<EvmVO> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/productivity/evm',
        { projectUuid, rootProjectPlanUuid, teamUuid },
        true
      )
      return response.json as EvmVO
    },
    []
  )
  const getDailyCpi = useCallback(
    async (
      projectUuid: string,
      rootProjectPlanUuid?: string,
      teamUuid?: string
    ): Promise<DailyCpiVO[]> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/productivity/cpi/daily',
        { projectUuid, rootProjectPlanUuid, teamUuid },
        true
      )
      return response.json.map(res => ({
        date: new Date(res.date),
        ev: res.ev,
        ac: res.ac,
      }))
    },
    []
  )
  return { getEvmValue, getDailyCpi }
}
