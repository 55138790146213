import React, { PropsWithChildren } from 'react'
import FilterListIcon from '@mui/icons-material/FilterListRounded'
import GetAppIcon from '@mui/icons-material/GetAppRounded'
import ImportExportIcon from '@mui/icons-material/ImportExportRounded'
import PublishIcon from '@mui/icons-material/PublishRounded'
import ReorderIcon from '@mui/icons-material/ReorderRounded'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt'
import ViewColumnIcon from '@mui/icons-material/ViewColumnRounded'
import { styled } from '@mui/system'
import ToolBar, {
  generateToolBarItemKey,
  ToolBarItemPosition,
  ToolbarProps,
} from '../../components/toolbars/ContainerToolBar'
import {
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  Paper,
  Popper,
  Slider,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
} from '@mui/material'
import { injectIntl } from 'react-intl'
import {
  BackgroundColor,
  FontSize,
  TextColor,
} from '../../../styles/commonStyles'
import LineWeightRoundedIcon from '@mui/icons-material/LineWeightRounded'
import { ROW_HEIGHT } from './index'
import CloseIcon from '@mui/icons-material/Close'
import { ColDef } from 'ag-grid-community'

const SpeedDialWrapperMain = styled('div')({
  marginRight: 30,
})
const SpeedDialWrapper = styled('div')({
  transform: 'scale(0.6)',
  zIndex: 100,
  overflow: 'visible',
  position: 'fixed',
})
const StyledSpeedDial = styled(SpeedDial)({
  position: 'absolute',
  top: -28,
  '& .MuiSpeedDial-actions': {
    flexDirection: 'row',
    position: 'relative',
    top: -50,
    left: -65,
  },
  '& .MuiSpeedDial-directionUp': {
    opacity: 1,
    flexDirection: 'row',
  },
})
const StyledSettingIcon = styled(SettingsIcon)({
  width: 26,
})
const StyledPopper = styled(Popper)({
  zIndex: 600,
})
const StyledSlider = styled(Slider)({
  height: '90px !important',
  margin: '8px 0',
  padding: '0 5px',
  '& .MuiSlider-rail': {
    width: 3,
  },
})
const StyledList = styled(List)({
  position: 'relative',
  overflow: 'auto',
}) as typeof List
const StyledListItem = styled(ListItem)({
  fontSize: FontSize.SMALL,
  height: 25,
})
const FilteredColumn = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const CloseIconButton = styled(IconButton)({
  padding: '3px',
})
const StyledCloseIcon = styled(CloseIcon)({
  color: TextColor.GREY,
})

interface Props extends PropsWithChildren<ToolbarProps> {
  resetColumnStateHandler?: Function
  exportHandler?: Function
  importHandler?: (data: Uint8Array) => Promise<any> | void
  expandGroupRowHandler?: Function
  addToolBarItemsAtLast?: boolean
  settingHandler?: Function
  filterIconHandler?: Function
  sortIconHandler?: Function
  filteredColumns?: ColDef[]
  sortedColumns?: string[]
  onChangeHeight?: (value: number) => void
  onChangeHeightCommitted?: () => void
  minHeight?: number
  defaultHeight?: number
  resetSpecificColumnFilter?: Function
  defaultToolBarItemKey?: React.Key
}

interface State {
  checked?: boolean
  anchorElForFileteredColumns: any
  anchorElForSortedColumns: any
  anchorElForChangeRowHeight: any
  openHandler: boolean
}

class BulkSheetToolBar extends React.Component<Props, State> {
  state = {
    checked: false,
    anchorElForFileteredColumns: null,
    anchorElForSortedColumns: null,
    anchorElForChangeRowHeight: null,
    openHandler: false,
  }

  private handleClick = (e, operation) => {
    if (operation !== 'main') {
      this.setState({ openHandler: true })
    } else {
      this.setState({ openHandler: !this.state.openHandler })
    }
  }

  private onClickResetColumnState = () => {
    this.props.resetColumnStateHandler!()
  }

  private onClickResetSpecificColumnState = (column: string) => {
    this.props.resetSpecificColumnFilter!(column)
  }

  private onClickExportButton = () => {
    this.props.exportHandler!()
  }

  private onClickImportSpeeDial = () => {
    document.getElementById('download-page')?.click()
  }

  private onClickImportButton = e => {
    const reader = new FileReader()
    reader.onload = e => {
      const data = new Uint8Array(e.target!['result'] as ArrayBuffer)
      this.props.importHandler!(data)
    }
    reader.readAsArrayBuffer(e.target.files[0])
    e.target.value = '' // To be able to select the same file as last one.
  }

  private onClickSetting = () => {
    this.props.settingHandler!()
  }

  private onClickFilter = (event: any) => {
    if (!this.isFilteredColumns()) {
      return
    }
    this.setState({ anchorElForFileteredColumns: event.currentTarget })
  }

  private onClickChangeHeight = (event: any) => {
    this.setState({ anchorElForChangeRowHeight: event.currentTarget })
  }

  private onClickSort = (event: any) => {
    if (!this.isSortedColumns()) {
      return
    }
    this.setState({ anchorElForSortedColumns: event.currentTarget })
  }

  private isFilteredColumns(): boolean {
    return Boolean(
      this.props.filteredColumns && this.props.filteredColumns.length > 0
    )
  }

  private isSortedColumns(): boolean {
    return Boolean(
      this.props.sortedColumns && this.props.sortedColumns.length > 0
    )
  }

  private onClickAwayFilter = () => {
    this.setState({ anchorElForFileteredColumns: null })
  }

  private onClickAwaySort = () => {
    this.setState({ anchorElForSortedColumns: null })
  }

  private onClickAwayRowHeightChange = () => {
    this.setState({ anchorElForChangeRowHeight: null })
  }

  private getIconButtonGroup = () => {
    if (
      this.props.resetColumnStateHandler ||
      this.props.exportHandler ||
      this.props.importHandler ||
      this.props.expandGroupRowHandler ||
      this.props.filterIconHandler ||
      this.props.sortIconHandler ||
      this.props.settingHandler ||
      this.props.filteredColumns ||
      this.props.sortedColumns ||
      this.props.onChangeHeight
    ) {
      const minHeight = this.props.minHeight || ROW_HEIGHT.MIN
      const maxHeight = ROW_HEIGHT.MAX - minHeight

      return (
        <div
          key={
            this.props.defaultToolBarItemKey ||
            generateToolBarItemKey(0, ToolBarItemPosition.RIGHT)
          }
        >
          <SpeedDialWrapperMain>
            <SpeedDialWrapper>
              <input
                id="download-page"
                type="file"
                aria-hidden="true"
                style={{ display: 'none', width: 0 }}
                onChange={this.onClickImportButton}
              />
              <StyledSpeedDial
                ariaLabel="toolbar"
                icon={
                  <Tooltip
                    title={this.props.intl.formatMessage({
                      id: 'dialog.setting',
                    })}
                  >
                    <StyledSettingIcon />
                  </Tooltip>
                }
                onClick={e => {
                  this.handleClick(e, 'main')
                }}
                open={this.state.openHandler}
                direction="right"
                FabProps={{
                  size: 'small',
                  style: {
                    background: BackgroundColor.BLUE_GRADATION,
                    boxShadow: '0px 1px 1px #00000033',
                  },
                }}
              >
                {this.props.resetColumnStateHandler && (
                  <SpeedDialAction
                    key={'columnState'}
                    style={{ display: 'block', backgroundColor: 'blue' }}
                    icon={<ViewColumnIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.initialize.columnState',
                    })}
                    onClick={e => {
                      this.onClickResetColumnState()
                      this.handleClick(e, 'columnstate')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.exportHandler && (
                  <SpeedDialAction
                    key={'export'}
                    icon={<GetAppIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.excel.export',
                    })}
                    onClick={e => {
                      this.onClickExportButton()
                      this.handleClick(e, 'export')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.importHandler && (
                  <SpeedDialAction
                    key={'import'}
                    icon={<PublishIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.excel.import',
                    })}
                    onClick={e => {
                      this.onClickImportSpeeDial()
                      this.handleClick(e, 'import')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.expandGroupRowHandler && (
                  <SpeedDialAction
                    key={'expandAll'}
                    icon={<ReorderIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.expandAll',
                    })}
                    onClick={e => {
                      this.props.expandGroupRowHandler!()
                      this.handleClick(e, 'expandAll')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.filterIconHandler && (
                  <SpeedDialAction
                    key={'restore.searchCondition'}
                    icon={<FilterListIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.restore.searchCondition',
                    })}
                    onClick={e => {
                      this.props.filterIconHandler!()
                      this.handleClick(e, 'restore.searchCondition')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.sortIconHandler && (
                  <SpeedDialAction
                    key={'restore.columnState'}
                    icon={<ViewQuiltIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.restore.columnState',
                    })}
                    onClick={e => {
                      this.props.sortIconHandler!()
                      this.handleClick(e, 'restore.columnstate')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.settingHandler && (
                  <SpeedDialAction
                    key={'setting'}
                    icon={<SettingsIcon color="action" />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.setting',
                    })}
                    onClick={e => {
                      this.onClickSetting()
                      this.handleClick(e, 'setting')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.filteredColumns && (
                  <SpeedDialAction
                    key={'filter'}
                    icon={
                      <span
                        className={'ag-icon ag-icon-filter'}
                        style={{
                          fontSize: 20,
                          color: this.isFilteredColumns() ? 'red' : '',
                        }}
                      />
                    }
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.filter',
                    })}
                    onClick={e => {
                      this.onClickFilter(e)
                      this.handleClick(e, 'filter')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.sortedColumns && (
                  <SpeedDialAction
                    key={'sort'}
                    icon={
                      <ImportExportIcon
                        color="action"
                        style={{ color: this.isSortedColumns() ? 'red' : '' }}
                      />
                    }
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.sort',
                    })}
                    onClick={e => {
                      this.onClickSort(e)
                      this.handleClick(e, 'sort')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
                {this.props.onChangeHeight && (
                  <SpeedDialAction
                    key={'changeHeight'}
                    icon={<LineWeightRoundedIcon />}
                    tooltipTitle={this.props.intl.formatMessage({
                      id: 'toolbar.changeHeight',
                    })}
                    onClick={e => {
                      this.onClickChangeHeight(e)
                      this.handleClick(e, 'changeHeight')
                      e.stopPropagation()
                    }}
                    tooltipPlacement={'bottom-start'}
                  />
                )}
              </StyledSpeedDial>
            </SpeedDialWrapper>
          </SpeedDialWrapperMain>
          <StyledPopper
            open={Boolean(this.state.anchorElForFileteredColumns)}
            anchorEl={this.state.anchorElForFileteredColumns}
            placement={'right-start'}
            disablePortal={true}
          >
            <ClickAwayListener onClickAway={this.onClickAwayFilter}>
              <Paper>
                <StyledList component="nav" aria-label="template">
                  {this.props.filteredColumns?.map((column: ColDef, index) => {
                    return (
                      <StyledListItem key={`filteredColumn${index}`}>
                        <FilteredColumn>
                          {column.headerName}
                          <CloseIconButton
                            onClick={() =>
                              this.onClickResetSpecificColumnState(
                                column.colId || column.field || ''
                              )
                            }
                          >
                            <StyledCloseIcon fontSize="small" />
                          </CloseIconButton>
                        </FilteredColumn>
                      </StyledListItem>
                    )
                  })}
                </StyledList>
              </Paper>
            </ClickAwayListener>
          </StyledPopper>
          <StyledPopper
            open={Boolean(this.state.anchorElForSortedColumns)}
            anchorEl={this.state.anchorElForSortedColumns}
            placement={'right-start'}
            disablePortal={true}
          >
            <ClickAwayListener onClickAway={this.onClickAwaySort}>
              <Paper>
                <StyledList component="nav" aria-label="template">
                  {this.props.sortedColumns?.map((column, index) => {
                    return (
                      <StyledListItem key={`sortedColumn${index}`}>
                        {column}
                      </StyledListItem>
                    )
                  })}
                </StyledList>
              </Paper>
            </ClickAwayListener>
          </StyledPopper>
          <StyledPopper
            open={Boolean(this.state.anchorElForChangeRowHeight)}
            anchorEl={this.state.anchorElForChangeRowHeight}
            placement={'right-start'}
            disablePortal={true}
          >
            <ClickAwayListener onClickAway={this.onClickAwayRowHeightChange}>
              <Paper style={{ width: 30 }}>
                <StyledSlider
                  size="small"
                  onChange={(event: any, value: number | number[]) => {
                    if (Array.isArray(value)) {
                      throw new Error('Illegal argument for onChangeHeight')
                    }
                    // transform x value as ROW_HEIGHT.MAX - x
                    this.props.onChangeHeight!(ROW_HEIGHT.MAX - value)
                  }}
                  onChangeCommitted={this.props.onChangeHeightCommitted}
                  min={0}
                  max={maxHeight}
                  step={maxHeight / 10}
                  marks={true}
                  defaultValue={
                    ROW_HEIGHT.MAX - (this.props.defaultHeight || minHeight)
                  }
                  orientation={'vertical'}
                  track={false}
                />
              </Paper>
            </ClickAwayListener>
          </StyledPopper>
        </div>
      )
    }
    return undefined
  }

  render() {
    const { ...other } = this.props
    return (
      <ToolBar {...other}>
        {[
          ...((this.props.children || []) as JSX.Element[]),
          this.getIconButtonGroup(),
        ]}
      </ToolBar>
    )
  }
}

export default injectIntl(BulkSheetToolBar)
