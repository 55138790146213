import React from 'react'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import BaseButton from '../BaseButton'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'
import CircularProgress from '@mui/material/CircularProgress'
import { BackgroundColor } from '../../../../styles/commonStyles'

const Button = styled(BaseButton)({
  background: BackgroundColor.BLUE_GRADATION,
  color: '#FFFFFF',
  width: '100%',
})
const ButtonProgress = styled(CircularProgress)({
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -6,
  marginLeft: -6,
})

interface OwnProps {
  onClick?: () => void | Promise<void>
  hideIcon?: boolean
  isLoading: boolean
}
type Props = OwnProps & WrappedComponentProps

const RefreshButton = (props: Props) => {
  return (
    <>
      <Button
        {...props}
        startIcon={!props.hideIcon && <RefreshRoundedIcon color="secondary" />}
        color="primary"
      >
        {props.intl.formatMessage({ id: 'refresh' })}
      </Button>
      {props.isLoading && <ButtonProgress size={12} />}
    </>
  )
}

export default injectIntl(RefreshButton)
