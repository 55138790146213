import { useEffect, useState } from 'react'
import { useCustomEnumRepository } from '../../../../../../services/adaptors/customEnumRepositoryAdaptor'
import { SelectOption } from '../../../../../components/select/model'

export type RiskSelectOptions = {
  riskTypes: SelectOption<string>[]
  probabilities: SelectOption<string>[]
  impacts: SelectOption<string>[]
  strategies: SelectOption<string>[]
}
export const useRiskSelectOptions = (projectUuid: string) => {
  const [riskTypes, setRiskTypes] = useState<SelectOption<string>[]>([])
  const [probabilities, setProbabilities] = useState<SelectOption<string>[]>([])
  const [impacts, setImpacts] = useState<SelectOption<string>[]>([])
  const [strategies, setStrategies] = useState<SelectOption<string>[]>([])
  const { fetchAsVO } = useCustomEnumRepository()
  useEffect(() => {
    const fn = async () => {
      const [
        riskTypesResponse,
        probabilitiesResponse,
        impactsResponse,
        strategiesResponse,
      ] = await Promise.all([
        fetchAsVO('RISK_TYPE', projectUuid),
        fetchAsVO('RISK_PROBABILITY', projectUuid),
        fetchAsVO('RISK_IMPACT', projectUuid),
        fetchAsVO('RISK_STRATEGY', projectUuid),
      ])
      setRiskTypes(riskTypesResponse)
      setProbabilities(probabilitiesResponse)
      setImpacts(impactsResponse)
      setStrategies(strategiesResponse)
    }
    fn()
  }, [fetchAsVO, projectUuid])
  return {
    riskTypes,
    probabilities,
    impacts,
    strategies,
  }
}
