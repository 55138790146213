import { Box, Popper, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { useCallback } from 'react'
import { Checkbox } from '../../../components/inputs/Checkbox'
import { SelectOption } from '../../../model/selectOption'
import { Placement } from 'react-overlays/cjs/usePopper'

type Props = {
  open: boolean
  anchorEl: Element | undefined
  options: SelectOption[]
  value: string[] | undefined
  onChange: (newValue: string[] | undefined) => void
  offset?: number
  placement?: Placement | undefined
}

const WbsSearchConditionCheckBoxPopper = ({
  open,
  anchorEl,
  value,
  options,
  onChange,
  offset,
  placement,
}: Props) => {
  const onChangeValue = useCallback(
    (v: any) => {
      const newValue = [...(value || [])]
      if (newValue.includes(v.target.defaultValue)) {
        newValue.splice((value || []).indexOf(v.target.defaultValue), 1)
      } else {
        newValue.push(v.target.defaultValue)
      }
      onChange(newValue)
    },
    [value]
  )
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{
        zIndex: 1300,
        background: colorPalette.monotone[0],
        boxShadow:
          'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
        borderRadius: '4px',
        padding: '10px',
        minWidth: '445px',
      }}
      placement={placement}
      modifiers={[
        {
          name: 'offset',
          options: { offset: [offset ? offset : 0, 20] },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
          },
        },
      ]}
      disablePortal={true}
    >
      {options.map(option => (
        <Box
          key={option.value}
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Checkbox
              sx={{ marginRight: '8px' }}
              value={option.value}
              checked={(value || []).some(v => v === option.value)}
              onChange={onChangeValue}
            />
            <img src={option.iconUrl} />
            <Typography
              sx={{
                margin: 'auto 10px',
                color: colorPalette.monotone[4],
              }}
            >
              {option.name}
            </Typography>
          </Box>
        </Box>
      ))}
    </Popper>
  )
}

export default WbsSearchConditionCheckBoxPopper
