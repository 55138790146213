import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  RowDragCallbackParams,
  ICellRendererParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
  GridApi,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import { roundNumber } from '../../../../utils/number'
import { MemberWorkMonthCell, ResourcePlanRow } from '../resourcePlan'
import {
  NUMBER_DECIMAL_POINT,
  WORKMONTHS_FIELD_PREFIX,
  WorkingDayCalendar,
  getManMonth,
  getMemberParamsCellStyles,
  isMemberRow,
  getUnsetManMonth,
  getTotalManMonth,
} from './common'
import {
  ALERT_UPPER_LIMIT_FOR_MAN_MONTH,
  COL_DEF_DRAG,
  COL_DEF_ROW_NUMBER,
  COL_DEF_TEAM,
  COL_DEF_TOTAL_BASE,
  COL_DEF_USER_CODE,
  COL_DEF_USER_DIVISION,
  COL_DEF_USER_NAME,
  COL_DEF_USER_POSITION,
  COL_GROUP_DEF_INFORMATION_BASE,
  generateWorkMonthColDef,
  getCalcWorkMonthColCellRendererSelectorResult,
  getDefaultWorkMonthColCellRendererSelectorResult,
  getTotalWorkMonthColCellRendererSelectorResult,
  setWorkMonthValueToRow,
  findAndSetWorkMonthValue,
  workMonthColValueSetter,
  setUserManMonthValue,
  highliteWorkMonthCell,
} from './commonColumnDef'
import { CSSProperties } from 'styled-components'
import { inputErrorColor } from '../../../containers/commons/AgGrid/lib/validator'

export const AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER: ColDef = {
  ...COL_DEF_USER_NAME,
  cellClassRules: {
    'hover-over-can-drop': params => {
      return (
        params.context?.onTree &&
        params.context?.draggableNodeId &&
        params.node?.id === params.context.draggableNodeId
      )
    },
  },
  cellStyle: getMemberParamsCellStyles,
}

export const COLUMN_DEF_GROUP_ROW_BY_MEMBER:
  | (ColDef<ResourcePlanRow> | ColGroupDef<ResourcePlanRow>)[]
  | null = [
  {
    ...COL_DEF_DRAG,
    rowDrag: (params: RowDragCallbackParams) => {
      const row: ResourcePlanRow = params.data
      return isMemberRow(row?.type)
    },
  },
  COL_DEF_ROW_NUMBER,
  // infomation group
  {
    ...COL_GROUP_DEF_INFORMATION_BASE,
    children: [
      {
        ...COL_DEF_USER_CODE,
        lockPosition: true,
      },
      {
        field: 'body.user.name',
        headerName: intl.formatMessage({
          id: 'project.resourcePlan.user',
        }),
        width: 150,
        hide: true,
        pinned: true,
        sortable: false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        valueGetter: params => {
          const row: ResourcePlanRow | undefined = params.data
          if (row?.isTotal) return ''
          return row?.body?.user?.name || ''
        },
      },
      {
        ...COL_DEF_TEAM,
        headerName: intl.formatMessage({
          id: 'project.resourcePlan.team.total',
        }),
      },
      COL_DEF_USER_DIVISION,
      COL_DEF_USER_POSITION,
    ],
  },
  // total columns
  {
    ...COL_DEF_TOTAL_BASE,
    valueGetter: (params: ValueGetterParams) => {
      const row: ResourcePlanRow = params.data
      if (!params.api || !row) return 0
      if (row.isTotal) {
        let grandTotal = 0
        params.api.forEachNode(rowNode => {
          const nodeData: ResourcePlanRow = rowNode.data
          if (
            nodeData &&
            !nodeData.isTotal &&
            nodeData.type === ResourcePlanType.MEMBER
          ) {
            nodeData.body.memberWorkMonths?.forEach(memberWorkMonth => {
              grandTotal += getManMonth(memberWorkMonth)
            })
          }
        })
        return roundNumber(grandTotal, NUMBER_DECIMAL_POINT)
      }
      let total = 0
      if (params.data.type === ResourcePlanType.UNSET) {
        let memberTotal = 0
        let teamTotal = 0
        const parentRow: ResourcePlanRow = params.node?.parent?.data
        params.api.forEachNode((node: RowNode) => {
          const nodeData: ResourcePlanRow = node?.data
          const prentNodeData: ResourcePlanRow | undefined = node.parent?.data
          if (!nodeData) return
          if (
            nodeData.type === ResourcePlanType.MEMBER &&
            nodeData.uuid === parentRow?.uuid
          ) {
            nodeData.body.memberWorkMonths?.forEach(memberWorkMonth => {
              memberTotal += getManMonth(memberWorkMonth)
            })
          }
          if (
            nodeData.type === ResourcePlanType.TEAM &&
            prentNodeData &&
            prentNodeData?.uuid === parentRow?.uuid
          ) {
            nodeData.body.memberWorkMonths?.forEach(teamWorkMonth => {
              teamTotal += getManMonth(teamWorkMonth)
            })
          }
        })
        total = memberTotal - teamTotal
        total = roundNumber(total, NUMBER_DECIMAL_POINT)
      } else {
        row.body.memberWorkMonths?.forEach(memberWorkMonth => {
          total += getManMonth(memberWorkMonth)
        })
      }
      return roundNumber(total, NUMBER_DECIMAL_POINT)
    },
  },
  // year month columns
  {
    groupId: WORKMONTHS_FIELD_PREFIX,
    headerName: '',
  },
]

const adjustWorkMonthValue = (
  memberViewGridApi: GridApi | null | undefined,
  teamViewGridApi: GridApi | null | undefined,
  memberUuid: string | undefined,
  columnId: string | undefined,
  editedRowNode: RowNode<ResourcePlanRow> | null
) => {
  if (!memberViewGridApi || !columnId || !editedRowNode?.data?.uuid) return
  const { memberValue, teamValue, unsetValue } = getTotalManMonth(
    memberViewGridApi,
    memberUuid,
    columnId
  )
  if (0 <= unsetValue) {
    if (editedRowNode.data.type === ResourcePlanType.MEMBER) {
      setUserManMonthValue(
        memberViewGridApi,
        teamViewGridApi,
        memberUuid,
        columnId,
        memberValue
      )
    }
    return
  }
  if (editedRowNode.data.type === ResourcePlanType.MEMBER) {
    const childrenColumn = editedRowNode.allChildrenCount ?? 0
    let diff: number = unsetValue
    for (let i = childrenColumn - 1; 0 <= i && diff < 0; i--) {
      const child = editedRowNode.allLeafChildren[i]
      if (!child?.data || child.data?.type !== ResourcePlanType.TEAM) continue
      const memberWorkMonth = child.data?.body.memberWorkMonths?.find(
        cell => cell.yearMonth === columnId
      )
      const calcVal = roundNumber(
        getManMonth(memberWorkMonth) + diff,
        NUMBER_DECIMAL_POINT
      )
      diff = Math.min(calcVal, 0)
      const newValue = Math.max(0, calcVal)
      setWorkMonthValueToRow(child.data, columnId, newValue)
      findAndSetWorkMonthValue(
        child.data.body?.user?.uuid,
        child.data.body?.team?.uuid,
        undefined,
        teamViewGridApi,
        columnId,
        newValue
      )
    }
    setUserManMonthValue(
      memberViewGridApi,
      teamViewGridApi,
      memberUuid,
      columnId,
      memberValue
    )
  } else if (editedRowNode.data.type === ResourcePlanType.TEAM) {
    const parentRow = editedRowNode.parent?.data
    parentRow && setWorkMonthValueToRow(parentRow, columnId, teamValue)
    setUserManMonthValue(
      memberViewGridApi,
      teamViewGridApi,
      memberUuid,
      columnId,
      teamValue
    )
  }
}

const isUnsetValueNegative = (params: CellClassParams<ResourcePlanRow>) => {
  const row: ResourcePlanRow | undefined = params.data
  if (!row || row.isTotal || !params.colDef.colId) return false
  const memberRowNode: RowNode<ResourcePlanRow> | null =
    row.type === ResourcePlanType.MEMBER ? params.node : params.node.parent
  if (!memberRowNode) return false

  const unsetRowNode: RowNode<ResourcePlanRow> | undefined =
    memberRowNode?.allLeafChildren?.find(
      (node: RowNode<ResourcePlanRow>) =>
        node.data?.type === ResourcePlanType.UNSET
    )
  if (!unsetRowNode) return false

  return params.api?.getValue(params.colDef.colId, unsetRowNode) ?? 0 < 0
}

export const generateMemberViewWorkMonthColDef = (
  yearMonth: string,
  workingDayCalendars: WorkingDayCalendar
): ColDef => {
  const editable = (
    params: EditableCallbackParams<ResourcePlanRow>
  ): boolean => {
    const row: ResourcePlanRow | undefined = params.data
    return !!row && !row.isTotal && row.type !== ResourcePlanType.UNSET
  }
  const valueGetter = (params: ValueGetterParams) => {
    const row: ResourcePlanRow = params.data
    if (!params.api || !row) return
    if (row.isTotal) {
      let total = 0
      params.api.forEachNode((node: RowNode) => {
        const nodeData: ResourcePlanRow | undefined = node.data
        if (
          nodeData &&
          !nodeData.isTotal &&
          nodeData.body.user &&
          nodeData.type === ResourcePlanType.MEMBER
        ) {
          const memberWorkMonth = nodeData.body.memberWorkMonths?.find(
            cell => cell.yearMonth === params.colDef.colId!
          )
          total += getManMonth(memberWorkMonth)
        }
      })
      return roundNumber(total, NUMBER_DECIMAL_POINT)
    }
    if (row.type === ResourcePlanType.UNSET) {
      return getUnsetManMonth(
        params.api,
        params.node?.parent?.data?.body.user?.uuid,
        params.colDef.colId
      )
    }
    const memberWorkMonth =
      !params.node?.group && !!row.body.memberWorkMonths
        ? row.body.memberWorkMonths.find(
            cell =>
              cell.yearMonth === params.colDef.colId! &&
              cell.manMonth !== undefined
          )
        : undefined
    return memberWorkMonth ? memberWorkMonth.manMonth : undefined
  }
  const valueSetter = (params: ValueSetterParams) => {
    return workMonthColValueSetter(
      params,
      (
        params: ValueSetterParams,
        memberUuid: string | undefined,
        columnId: string | undefined
      ) => {
        adjustWorkMonthValue(
          params.api,
          params.context?.syncGrid?.api,
          memberUuid,
          columnId,
          params.node
        )
      }
    )
  }
  const cellRendererSelector = (params: ICellRendererParams) => {
    const row: ResourcePlanRow = params.data
    if (row.isTotal) {
      return getTotalWorkMonthColCellRendererSelectorResult(params)
    }
    if (row.type === ResourcePlanType.UNSET) {
      return getCalcWorkMonthColCellRendererSelectorResult(
        params,
        (params: ICellRendererParams, node: RowNode<ResourcePlanRow>) => {
          return (
            node.data &&
            !node.data.isTotal &&
            node.parent?.data &&
            params.node.parent?.data &&
            node.parent.data.uuid === params.node.parent.data.uuid
          )
        }
      )
    }
    return getDefaultWorkMonthColCellRendererSelectorResult(params)
  }
  const cellStyle = (params: CellClassParams): CSSProperties => {
    return isUnsetValueNegative(params) || highliteWorkMonthCell(params)
      ? {
          backgroundColor: inputErrorColor,
        }
      : {}
  }

  return generateWorkMonthColDef(
    yearMonth,
    workingDayCalendars,
    editable,
    valueGetter,
    valueSetter,
    cellRendererSelector,
    cellStyle
  )
}
