import React, { useRef } from 'react'
import {
  SearchConditionProps,
  SearchFilter,
} from '../../../pages/WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { SaveWbsItemSearchConditionDialogProps } from '../../../pages/WbsItemSearch/WbsItemSearchToolBar/SaveWbsItemSearchConditionDialog'
import HeaderSearchWindow from './HeaderSearchWindow'
import { intl } from '../../../../i18n'
import { useGlobalKeyBind } from '../../../hooks/useGlobalKeyBind'

interface Props {
  total?: number
  hit?: number
  searchText: string
  searchFilter: SearchFilter
  searchConditions: SearchConditionProps[]
  reloadHandler: () => void
  onChangeSearchText: (searchText?: string) => void
  onChangeFilter: (filter?: SearchFilter) => void
  saveDialogProps?: SaveWbsItemSearchConditionDialogProps
  openSavedUIStateDialog?: () => void
}

const HeaderSearchBox = ({
  reloadHandler,
  total,
  hit,
  searchFilter,
  onChangeFilter,
}: Props) => {
  const ref = useRef<HTMLInputElement>()

  useGlobalKeyBind(
    [
      {
        key: 'mod+/',
        fn: () => {
          ref?.current?.focus()
        },
        stopDefaultBehavior: true,
      },
    ],
    [ref]
  )

  return (
    <HeaderSearchWindow
      reloadHandler={reloadHandler}
      onChange={(searchName?: string) =>
        onChangeFilter({
          ...searchFilter,
          displayName: searchName,
        })
      }
      total={total}
      hit={hit}
      hideButton={true}
      searchText={searchFilter?.displayName}
      searchTextPlaceholder={intl.formatMessage({
        id: 'search.displayName.withShortCutKey',
      })}
      inputRef={ref}
    />
  )
}

export default HeaderSearchBox
