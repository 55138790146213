import queryString from 'query-string'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { WbsPivotAggregationDomain } from '../../../../domain/value-object/WbsPivot'

export const useAggregationDomain = (): {
  domain: WbsPivotAggregationDomain | undefined
} => {
  const location = useLocation()
  const domain: WbsPivotAggregationDomain | undefined = useMemo(() => {
    const queryObject = queryString.parse(location.search)
    const rootProjectPlanUuid = queryObject['rootProjectPlanUuid']
    if (typeof rootProjectPlanUuid === 'string') {
      return {
        type: 'WBS',
        value: rootProjectPlanUuid,
      }
    }
    const ticketListUuid = queryObject['ticketListUuid']
    if (typeof ticketListUuid === 'string') {
      return {
        type: 'TICKET_LIST',
        value: ticketListUuid,
      }
    }
    return undefined
  }, [location])
  return { domain }
}
