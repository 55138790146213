import { RefinementRow } from '../dataManager/refinement'
import { RefinementSelectOptionType } from '../hooks/selectOptions/refinement'
import { TicketRow } from '../tickets'
import { ExcelParserBase } from './base'

export class RefinementExcelParser extends ExcelParserBase {
  public getColumnMap = (columnKeyMap: {
    [key: string]: string
  }): {
    [key: string]: string
  } => {
    return {
      ...this.getCommonColumnKeyMap(columnKeyMap),
      knownRefinement:
        columnKeyMap[this.columnName('refinement.knownRefinement')],
      originalRefinement:
        columnKeyMap[this.columnName('refinement.originalRefinement')],
      detectionPhase:
        columnKeyMap[this.columnName('refinement.detectionPhase')],
      environment: columnKeyMap[this.columnName('refinement.environment')],
      domain: columnKeyMap[this.columnName('refinement.domain')],
      featureType: columnKeyMap[this.columnName('refinement.featureType')],
      feature: columnKeyMap[this.columnName('refinement.feature')],
      defectEvent: columnKeyMap[this.columnName('refinement.defectEvent')],
      occurrenceProcedure:
        columnKeyMap[this.columnName('refinement.occurrenceProcedure')],
      testSpecification:
        columnKeyMap[this.columnName('refinement.testSpecification')],
      testCase: columnKeyMap[this.columnName('refinement.testCase')],
      importance: columnKeyMap[this.columnName('refinement.importance')],
      impact: columnKeyMap[this.columnName('refinement.impact')],
      desiredDeliveryDate:
        columnKeyMap[this.columnName('refinement.desiredDeliveryDate')],
      cause: columnKeyMap[this.columnName('refinement.cause')],
      directCause: columnKeyMap[this.columnName('refinement.directCause')],
      rootCause: columnKeyMap[this.columnName('refinement.rootCause')],
      internalManagement:
        columnKeyMap[this.columnName('refinement.internalManagement')],
      properDetectionPhase:
        columnKeyMap[this.columnName('refinement.properDetectionPhase')],
      fixContents: columnKeyMap[this.columnName('refinement.fixContents')],
      interimAction: columnKeyMap[this.columnName('refinement.interimAction')],
      permanentAction:
        columnKeyMap[this.columnName('refinement.permanentAction')],
      documentsToBeCorrected:
        columnKeyMap[this.columnName('refinement.documentsToBeCorrected')],
      horizontalDeploymentTarget:
        columnKeyMap[this.columnName('refinement.horizontalDeploymentTarget')],
      horizontalDeploymentContent:
        columnKeyMap[this.columnName('refinement.horizontalDeploymentContent')],
    }
  }

  private mergeRefinementEnumValues = (
    columnKey: string,
    excelSrc: object,
    customEnumKey: string,
    embodiedValue: string
  ) => {
    return columnKey
      ? this.findFromContext(customEnumKey, excelSrc[columnKey])?.value ??
          embodiedValue
      : embodiedValue
  }

  public mergeUpdatedRow = (
    embodied: TicketRow,
    excelSrc: object,
    columnKeyMap: { [key: string]: string }
  ): RefinementRow => {
    const refinementEmbodied = embodied as RefinementRow
    return {
      ...embodied,
      knownRefinement: columnKeyMap.knownRefinement
        ? (excelSrc[columnKeyMap.knownRefinement] as boolean)
        : refinementEmbodied.knownRefinement,
      originalRefinement: columnKeyMap.originalRefinement
        ? this.findFromContext(
            RefinementSelectOptionType.REFINEMENT,
            excelSrc[columnKeyMap.originalRefinement]
          ) ?? refinementEmbodied.originalRefinement
        : refinementEmbodied.originalRefinement,
      detectionPhase: this.mergeRefinementEnumValues(
        columnKeyMap.detectionPhase,
        excelSrc,
        RefinementSelectOptionType.DETECTION_PHASE,
        refinementEmbodied.detectionPhase
      ),
      environment: this.mergeRefinementEnumValues(
        columnKeyMap.environment,
        excelSrc,
        RefinementSelectOptionType.ENVIRONMENT,
        refinementEmbodied.environment
      ),
      domain: this.mergeRefinementEnumValues(
        columnKeyMap.domain,
        excelSrc,
        RefinementSelectOptionType.DOMAIN,
        refinementEmbodied.domain
      ),
      featureType: this.mergeRefinementEnumValues(
        columnKeyMap.featureType,
        excelSrc,
        RefinementSelectOptionType.FEATURE_TYPE,
        refinementEmbodied.featureType
      ),
      feature: columnKeyMap.feature
        ? (excelSrc[columnKeyMap.feature] ?? '').toString()
        : refinementEmbodied.feature,
      defectEvent: columnKeyMap.defectEvent
        ? (excelSrc[columnKeyMap.defectEvent] ?? '').toString()
        : refinementEmbodied.defectEvent,
      occurrenceProcedure: columnKeyMap.occurrenceProcedure
        ? (excelSrc[columnKeyMap.occurrenceProcedure] ?? '').toString()
        : refinementEmbodied.occurrenceProcedure,
      testSpecification: columnKeyMap.testSpecification
        ? (excelSrc[columnKeyMap.testSpecification] ?? '').toString()
        : refinementEmbodied.testSpecification,
      testCase: columnKeyMap.testCase
        ? (excelSrc[columnKeyMap.testCase] ?? '').toString()
        : refinementEmbodied.testCase,
      importance: this.mergeRefinementEnumValues(
        columnKeyMap.importance,
        excelSrc,
        RefinementSelectOptionType.IMPORTANCE,
        refinementEmbodied.importance
      ),
      impact: this.mergeRefinementEnumValues(
        columnKeyMap.impact,
        excelSrc,
        RefinementSelectOptionType.IMPACT,
        refinementEmbodied.impact
      ),
      desiredDeliveryDate: columnKeyMap.desiredDeliveryDate
        ? this.parseDate(excelSrc[columnKeyMap.desiredDeliveryDate]) ||
          undefined
        : refinementEmbodied.desiredDeliveryDate,
      cause: this.mergeRefinementEnumValues(
        columnKeyMap.cause,
        excelSrc,
        RefinementSelectOptionType.REFINEMENT_CAUSE,
        refinementEmbodied.cause
      ),
      directCause: columnKeyMap.directCause
        ? (excelSrc[columnKeyMap.directCause] ?? '').toString()
        : refinementEmbodied.directCause,
      rootCause: columnKeyMap.rootCause
        ? (excelSrc[columnKeyMap.rootCause] ?? '').toString()
        : refinementEmbodied.rootCause,
      internalManagement: columnKeyMap.internalManagement
        ? (excelSrc[columnKeyMap.internalManagement] as boolean)
        : refinementEmbodied.internalManagement,
      properDetectionPhase: this.mergeRefinementEnumValues(
        columnKeyMap.properDetectionPhase,
        excelSrc,
        RefinementSelectOptionType.DETECTION_PHASE,
        refinementEmbodied.properDetectionPhase
      ),
      interimAction: columnKeyMap.interimAction
        ? (excelSrc[columnKeyMap.interimAction] ?? '').toString()
        : refinementEmbodied.interimAction,
      permanentAction: columnKeyMap.permanentAction
        ? (excelSrc[columnKeyMap.permanentAction] ?? '').toString()
        : refinementEmbodied.permanentAction,
      documentsToBeCorrected: columnKeyMap.documentsToBeCorrected
        ? (excelSrc[columnKeyMap.documentsToBeCorrected] ?? '').toString()
        : refinementEmbodied.documentsToBeCorrected,
      horizontalDeploymentTarget: columnKeyMap.horizontalDeploymentTarget
        ? (excelSrc[columnKeyMap.horizontalDeploymentTarget] as boolean)
        : refinementEmbodied.horizontalDeploymentTarget,
      horizontalDeploymentContent: columnKeyMap.horizontalDeploymentContent
        ? (excelSrc[columnKeyMap.horizontalDeploymentContent] ?? '').toString()
        : refinementEmbodied.horizontalDeploymentContent,
      wbsItem: this.mergeWbsItemColumnUpdatedRow(
        embodied.wbsItem!,
        excelSrc,
        columnKeyMap
      ),
    } as RefinementRow
  }
}
