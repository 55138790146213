import React from 'react'
import './styles.scss'

interface Props {
  isLoading: boolean
  elem?: Element | null
}

const Loading: React.FC<Props> = (props: Props) => {
  const pos = props.elem ? props.elem.getBoundingClientRect() : undefined

  return props.isLoading ? (
    <div
      style={{
        position: pos ? 'absolute' : undefined,
        zIndex: 9999,
        overflow: 'hidden',
        top: pos ? pos.top : undefined,
        left: pos ? pos.left : undefined,
        width: pos ? pos.width : undefined,
        height: pos ? pos.height : undefined,
      }}
    >
      <div className={'loader'} />
    </div>
  ) : (
    <></>
  )
}

export default Loading
