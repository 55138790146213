import { GridOptions, ValueFormatterParams } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { BurndownChartRowData } from './model'
import { useCallback, useMemo } from 'react'
import { DateBucket } from '../../../../components/charts/model/timeSeries'

type GridOptionsParams = {
  formatValue: (v: number) => string
  formatWorkload: (v: number) => string
  formatRate: (v: number) => string
  formatBucket: (v: DateBucket) => string
}
export const useGridOptions = ({
  formatValue,
  formatWorkload,
  formatRate,
  formatBucket,
}: GridOptionsParams): GridOptions<BurndownChartRowData> => {
  const [
    dateColumn,
    scheduleColumn,
    actualOrProspectColumn,
    simulatedColumn,
    burnDownColumn,
    diffColumn,
    resourceColumn,
    velocityColumn,
  ] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.table.column.date' }),
      intl.formatMessage({ id: 'progressReport.table.column.schedule' }),
      intl.formatMessage({
        id: 'progressReport.table.column.actualOrProspect',
      }),
      intl.formatMessage({ id: 'progressReport.table.column.simulated' }),
      intl.formatMessage({ id: 'progressReport.table.column.burnDown' }),
      intl.formatMessage({ id: 'progressReport.table.column.diff' }),
      intl.formatMessage({ id: 'progressReport.table.column.resource' }),
      intl.formatMessage({ id: 'progressReport.table.column.velocity' }),
    ],
    []
  )
  const dateFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, DateBucket>) =>
      formatBucket(params.value),
    [formatBucket]
  )
  const valueFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatValue(params.value),
    [formatValue]
  )
  const workloadFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatWorkload(params.value),
    [formatWorkload]
  )
  const rateFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatRate(params.value),
    [formatRate]
  )
  const options = useMemo(
    () => ({
      ...commonOptions,
      columnDefs: [
        {
          field: 'date',
          headerName: dateColumn,
          pinned: true,
          valueFormatter: dateFormatter,
          cellStyle: { justifyContent: 'center' },
        },
        {
          headerName: scheduleColumn,
          children: [
            {
              field: 'scheduleDataBurnDown',
              headerName: burnDownColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'scheduleData',
              headerName: diffColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'resourceData',
              headerName: resourceColumn,
              valueFormatter: workloadFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'scheduleVelocityData',
              headerName: velocityColumn,
              valueFormatter: rateFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
          ],
        },
        {
          headerName: actualOrProspectColumn,
          children: [
            {
              field: 'actualDataBurnDown',
              headerName: burnDownColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'actualData',
              headerName: diffColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'actualCostData',
              headerName: resourceColumn,
              valueFormatter: workloadFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'actualVelocityData',
              headerName: velocityColumn,
              valueFormatter: rateFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
          ],
        },
        {
          headerName: simulatedColumn,
          children: [
            {
              field: 'simulatedDataBurnDown',
              headerName: burnDownColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'simulatedData',
              headerName: diffColumn,
              valueFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'simulatedResourceData',
              headerName: resourceColumn,
              valueFormatter: workloadFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
            {
              field: 'simulatedVelocityData',
              headerName: velocityColumn,
              valueFormatter: rateFormatter,
              cellStyle: { justifyContent: 'flex-end' },
            },
          ],
        },
      ],
      // excelStyles: [],
    }),
    [
      actualOrProspectColumn,
      burnDownColumn,
      dateColumn,
      dateFormatter,
      diffColumn,
      simulatedColumn,
      rateFormatter,
      resourceColumn,
      scheduleColumn,
      valueFormatter,
      velocityColumn,
      workloadFormatter,
    ]
  )
  return options
}

type GridOptionsCrossTeamParams = {
  teams: {
    uuid: string
    name: string
  }[]
} & GridOptionsParams
export const useGridOptionsCrossTeam = ({
  teams,
  formatValue,
  formatWorkload,
  formatRate,
  formatBucket,
}: GridOptionsCrossTeamParams): GridOptions<BurndownChartRowData> => {
  const [
    dateColumn,
    scheduleColumn,
    actualOrProspectColumn,
    simulatedColumn,
    burnDownColumn,
    diffColumn,
    resourceColumn,
    velocityColumn,
  ] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.table.column.date' }),
      intl.formatMessage({ id: 'progressReport.table.column.schedule' }),
      intl.formatMessage({
        id: 'progressReport.table.column.actualOrProspect',
      }),
      intl.formatMessage({ id: 'progressReport.table.column.simulated' }),
      intl.formatMessage({ id: 'progressReport.table.column.burnDown' }),
      intl.formatMessage({ id: 'progressReport.table.column.diff' }),
      intl.formatMessage({ id: 'progressReport.table.column.resource' }),
      intl.formatMessage({ id: 'progressReport.table.column.velocity' }),
    ],
    []
  )
  const dateFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, DateBucket>) =>
      formatBucket(params.value),
    [formatBucket]
  )
  const valueFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatValue(params.value),
    [formatValue]
  )
  const workloadFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatWorkload(params.value),
    [formatWorkload]
  )
  const rateFormatter = useCallback(
    (params: ValueFormatterParams<BurndownChartRowData, number>) =>
      formatRate(params.value),
    [formatRate]
  )
  const options = useMemo(
    () => ({
      ...commonOptions,
      columnDefs: [
        {
          field: 'date',
          headerName: dateColumn,
          pinned: true,
          valueFormatter: dateFormatter,
          cellStyle: { justifyContent: 'center' },
        },
        {
          headerName: scheduleColumn,
          children: teams.map(team => ({
            headerName: team.name,
            children: [
              {
                field: `scheduleDataBurnDown-${team.uuid}`,
                headerName: burnDownColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `scheduleData-${team.uuid}`,
                headerName: diffColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `resourceData-${team.uuid}`,
                headerName: resourceColumn,
                valueFormatter: workloadFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `scheduleVelocityData-${team.uuid}`,
                headerName: velocityColumn,
                valueFormatter: rateFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
            ],
          })),
        },
        {
          headerName: actualOrProspectColumn,
          children: teams.map(team => ({
            headerName: team.name,
            children: [
              {
                field: `actualDataBurnDown-${team.uuid}`,
                headerName: burnDownColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `actualData-${team.uuid}`,
                headerName: diffColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `actualCostData-${team.uuid}`,
                headerName: resourceColumn,
                valueFormatter: workloadFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `actualVelocityData-${team.uuid}`,
                headerName: velocityColumn,
                valueFormatter: rateFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
            ],
          })),
        },
        {
          headerName: simulatedColumn,
          children: teams.map(team => ({
            headerName: team.name,
            children: [
              {
                field: `simulatedDataBurnDown-${team.uuid}`,
                headerName: burnDownColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `simulatedData-${team.uuid}`,
                headerName: diffColumn,
                valueFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `simulatedResourceData-${team.uuid}`,
                headerName: resourceColumn,
                valueFormatter: workloadFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
              {
                field: `simulatedVelocityData-${team.uuid}`,
                headerName: velocityColumn,
                valueFormatter: rateFormatter,
                cellStyle: { justifyContent: 'flex-end' },
              },
            ],
          })),
        },
      ],
      // excelStyles: [],
    }),
    [
      actualOrProspectColumn,
      burnDownColumn,
      dateColumn,
      dateFormatter,
      diffColumn,
      simulatedColumn,
      rateFormatter,
      resourceColumn,
      scheduleColumn,
      teams,
      valueFormatter,
      velocityColumn,
      workloadFormatter,
    ]
  )
  return options
}

const commonOptions = {
  groupHeaderHeight: 32,
  headerHeight: 32,
  rowHeight: 32,
  treeData: false,
  context: {},
  // Column
  defaultColDef: {
    width: 100,
    editable: false,
    sortable: false,
    resizable: true,
    suppressMenu: true,
    cellStyle: { display: 'flex', padding: '0 4px', overflow: 'hidden' },
  },
}
