import styled from 'styled-components'
import DateInput from '../../../../components/editors/input/DateInput'
import { memo, useCallback, useState } from 'react'
import { DateTermVO } from '../../../../../domain/value-object/DateTermVO'
import { Box, ClickAwayListener, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import {
  DateVO,
  dateVoService,
} from '../../../../../domain/value-object/DateVO'
import CalendarIcon from '../../../../components/icons/CalendarIcon'
import { useKeyBind } from '../../../../hooks/useKeyBind'
import { KEY_ESC } from '../../../../model/keyBind'

export type Props = {
  value?: DateTermVO
  onChange: (value?: DateTermVO) => void
}

export const DateRangeItem = memo(({ value, onChange }: Props) => {
  const [isEditingFrom, setIsEditingFrom] = useState(false)
  const [isEditingTo, setIsEditingTo] = useState(false)
  const onChangeFrom = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.to) {
        onChange(undefined)
        return
      }
      const shiftedTo = isStartDateBeyondEndDate(v, value?.to) ? v : value?.to
      onChange({ from: v, to: shiftedTo } as DateTermVO)
    },
    [onChange, value]
  )

  const onChangeTo = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.from) {
        onChange(undefined)
        return
      }
      const shiftedFrom = isStartDateBeyondEndDate(value?.from, v)
        ? v
        : value?.from
      onChange({ from: shiftedFrom, to: v } as DateTermVO)
    },
    [onChange, value]
  )

  const isStartDateBeyondEndDate = useCallback(
    (from?: DateVO, to?: DateVO) =>
      !!from && !!to && dateVoService.diff(from, to) > 0,
    []
  )

  const quitEditingBySpecialKey = useCallback(
    e => {
      if (e.key === 'Escape' || e.key === 'Enter') {
        isEditingFrom && setIsEditingFrom(false)
        isEditingTo && setIsEditingTo(false)
      }
    },
    [isEditingFrom, isEditingTo]
  )

  useKeyBind(
    [{ key: KEY_ESC, fn: quitEditingBySpecialKey }],
    [isEditingFrom, isEditingTo]
  )

  return (
    <RootDiv>
      <Box sx={{ padding: '0 2px 0 2px', width: '108px' }}>
        {isEditingFrom ? (
          <ClickAwayListener onClickAway={() => setIsEditingFrom(false)}>
            <StyledDateInput
              value={value?.from}
              onChange={onChangeFrom}
              placeholder={'YYYY/MM/DD'}
              enterHandler={() => setIsEditingFrom(false)}
              defaultEditing={true}
            />
          </ClickAwayListener>
        ) : (
          <StyledDateRenderer onClick={() => setIsEditingFrom(true)}>
            <StyledDateTypography hasValue={!!value?.from}>
              {value?.from
                ? dateVoService.format(value?.from, 'YYYY/MM/DD')
                : 'YYYY/MM/DD'}
            </StyledDateTypography>
            <StyledCalendarIcon>
              <CalendarIcon />
            </StyledCalendarIcon>
          </StyledDateRenderer>
        )}
      </Box>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <Box sx={{ padding: '0 2px 0 2px', width: '108px' }}>
        {isEditingTo ? (
          <ClickAwayListener onClickAway={() => setIsEditingTo(false)}>
            <StyledDateInput
              value={value?.to}
              onChange={onChangeTo}
              placeholder={'YYYY/MM/DD'}
              enterHandler={() => setIsEditingTo(false)}
              defaultEditing={true}
            />
          </ClickAwayListener>
        ) : (
          <StyledDateRenderer onClick={() => setIsEditingTo(true)}>
            <StyledDateTypography hasValue={!!value?.to}>
              {value?.to
                ? dateVoService.format(value?.to, 'YYYY/MM/DD')
                : 'YYYY/MM/DD'}
            </StyledDateTypography>
            <StyledCalendarIcon>
              <CalendarIcon />
            </StyledCalendarIcon>
          </StyledDateRenderer>
        )}
      </Box>
    </RootDiv>
  )
})

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
})

const StyledDateInput = styled(DateInput)({
  '& .MuiInputBase-input': {
    width: '80px',
    color: colorPalette.monotone[5],
  },
})

const StyledDateRenderer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const StyledDateTypography = styled(Typography)<{ hasValue: boolean }>(
  props => ({
    color: props.hasValue ? colorPalette.monotone[5] : colorPalette.monotone[2],
    width: '80px',
  })
)

const StyledCalendarIcon = styled('div')({
  padding: '5px 5px 0',
  cursor: 'pointer',
})
