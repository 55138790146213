/**
 * To make the independency chain not circular, cut out this class to the specific file.
 */
import { APIResponse } from './api'

export class SwitchableRequest {
  private abortController?: AbortController
  switch = async (request: (signal: AbortSignal) => Promise<APIResponse>) => {
    try {
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()
      return await request(this.abortController.signal)
    } catch (e: any) {
      if (e.name === 'AbortError') {
        // Expected error.
        throw e
      }
      return Promise.reject({
        error: e,
      })
    } finally {
      this.abortController = undefined
    }
  }
}
