import _ from 'lodash'
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  RowClassParams,
  RowNode,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { roundNumber } from '../../../../utils/number'
import DateVO from '../../../../vo/DateVO'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { ResourcePlanRow, RowGroupColumnType } from '../resourcePlan'
import {
  NUMBER_DECIMAL_POINT,
  WorkingDayCalendar,
  generateYearMonthColumnDefs,
  isMemberRow,
  isTeamRow,
  isWorkMonthColumn,
  isWorkMonthColumnDef,
} from './common'
import DateTimeVO from '../../../../vo/DateTimeVO'
import {
  AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER,
  COLUMN_DEF_GROUP_ROW_BY_MEMBER,
  generateMemberViewWorkMonthColDef,
} from './columnDefGroupRowsByMember'
import {
  AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_TEAM,
  COLUMN_DEF_GROUP_ROW_BY_TEAM,
  generateTeamViewWorkMonthColDef,
} from './columnDefGroupRowsByTeam'
import './styles.scss'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'

export const resourcePlanGridOptions = (
  rowGroupColumnType: RowGroupColumnType | undefined
): GridOptions => {
  let autoGroupColumnDef: ColDef = {}
  let columnDefs: (ColDef | ColGroupDef)[] | null = []
  switch (rowGroupColumnType) {
    case RowGroupColumnType.MEMBER:
      autoGroupColumnDef = AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER
      columnDefs = COLUMN_DEF_GROUP_ROW_BY_MEMBER
      break
    case RowGroupColumnType.TEAM:
      autoGroupColumnDef = AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_TEAM
      columnDefs = COLUMN_DEF_GROUP_ROW_BY_TEAM
      break
    default:
      break
  }

  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: true,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    autoGroupColumnDef,
    getRowStyle: (params: RowClassParams) => {
      const row: ResourcePlanRow = params.data
      if (
        row &&
        row.body.projectReleasedDate &&
        new DateVO(row.body.projectReleasedDate).isBefore(
          DateVO.now().getStartOfDay()
        )
      ) {
        return { backgroundColor: '#e0e0e0' }
      }
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      hide: false,
      sortable: true,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      resizable: true,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        resource_plan_root_row: params => {
          const row: ResourcePlanRow = params.node.data
          return (
            row?.type === rowGroupColumnType ||
            (rowGroupColumnType === RowGroupColumnType.TEAM &&
              row?.type === ResourcePlanType.UNSET)
          )
        },
        resource_plan_zero_value_cell: params => {
          return params.value === 0
        },
      },
    },
    columnDefs,
    // Footer
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'count'],
          },
          align: 'left',
        },
      ],
    },
    localeText: {
      sum: intl.formatMessage({
        id: 'bulksheet.statusPanel.sum',
      }),
      count: intl.formatMessage({
        id: 'bulksheet.statusPanel.count',
      }),
      totalAndFilteredRows: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
      }),
      of: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
      }),
    },
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0.00',
        },
      },
      {
        id: 'dateTimeStyle',
        dataType: 'DateTime',
        numberFormat: {
          format: 'yyyy/mm/dd hh:MM:ss',
        },
      },
    ],
    processCellForClipboard,
  }
}

export const processCellForClipboard = ({
  column,
  value,
}: ProcessCellForExportParams<ResourcePlanRow>) => {
  const colDef = column.getColDef()
  const { field, type } = colDef
  if (!value) return value

  if (type) {
    if (type.includes(ColumnType.autocomplete)) {
      return value?.name || value?.displayName || value?.officialName
    }
    if (type.includes(ColumnType.dateTime)) {
      return new DateTimeVO(value).format()
    }
  }
  if (field) {
    if (
      ['total'].includes(field) ||
      isWorkMonthColumn(column.getParent()?.getGroupId())
    ) {
      return roundNumber(value, NUMBER_DECIMAL_POINT)
    }
  }
  return value
}

export const refreshDynamicColumnDef = async (
  api: GridApi | null | undefined,
  yearMonthArray: string[],
  workingDayMap: WorkingDayCalendar,
  rowGroupColumn: RowGroupColumnType | undefined
) => {
  if (!api || !rowGroupColumn) return
  const columnDefs = api.getColumnDefs()
  const workMonthColumnIndex = getWorkMonthColumnIndex(columnDefs)
  const newColumnDefs = columnDefs?.filter(def => !isWorkMonthColumnDef(def))

  let generateWorkMonthColDefFunc:
    | ((yearMonth: string, workingDayCalendars: WorkingDayCalendar) => ColDef)
    | undefined = undefined
  switch (rowGroupColumn) {
    case RowGroupColumnType.MEMBER:
      generateWorkMonthColDefFunc = generateMemberViewWorkMonthColDef
      break
    case RowGroupColumnType.TEAM:
      generateWorkMonthColDefFunc = generateTeamViewWorkMonthColDef
      break
    default:
      return
  }

  const yearColGroupDefs: ColGroupDef[] | undefined =
    generateYearMonthColumnDefs(
      yearMonthArray,
      workingDayMap,
      generateWorkMonthColDefFunc
    )

  yearColGroupDefs?.forEach((year: ColGroupDef, index: number) => {
    if (workMonthColumnIndex) {
      newColumnDefs?.splice(workMonthColumnIndex + index, 0, year)
    } else {
      newColumnDefs?.push(year)
    }
  })

  api.setColumnDefs(newColumnDefs!)
}

export const getRootNodeForMemberView = (node: RowNode<ResourcePlanRow>) => {
  return isMemberRow(node.data?.type) ? node : node.parent
}

export const getRootNodeForTeamView = (node: RowNode<ResourcePlanRow>) => {
  return isTeamRow(node.data?.type) ? node : node.parent
}

export const getWorkMonthColumnIndex = (
  columnDefs: (ColDef | ColGroupDef)[] | undefined
): number => {
  return columnDefs?.findIndex(isWorkMonthColumnDef) || 0
}

export const moveWorkMonthColumns = (
  api: GridApi | null | undefined,
  sourceColumnDefs: (ColDef | ColGroupDef)[] | undefined,
  index: number
) => {
  if (!api) return
  const source = sourceColumnDefs ?? api.getColumnDefs()
  const workMonthColumns = source?.filter(def => isWorkMonthColumnDef(def))
  if (workMonthColumns) {
    const newColumnDefs = source?.filter(def => !isWorkMonthColumnDef(def))
    if (newColumnDefs) {
      newColumnDefs.splice(index, 0, ...workMonthColumns)
      api.setColumnDefs(newColumnDefs)
    }
  }
}
