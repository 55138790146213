import { IconButton, styled } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useCallback, useState } from 'react'
import { VersionReportVersionEntity } from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { VersionInfoDialog } from '../dialog/VersionInfoDialog'
import { HeaderIconButton } from './common'
import { colorPalette } from '../../../../style/colorPallete'

type VersionInfoProps = {
  version: VersionReportVersionEntity | undefined
}
export const VersionInfo = ({ version }: VersionInfoProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])
  return (
    <>
      <HeaderIconButton onClick={onOpen}>
        <InfoIcon />
      </HeaderIconButton>
      <VersionInfoDialog open={open} version={version} onClose={onClose} />
    </>
  )
}
const InfoIcon = styled(InfoOutlinedIcon)({
  color: colorPalette.monotone[4],
})
