import _ from 'lodash'
import API, { APIResponse, successDummyResponse } from '../commons/api'
import { DISPLAY_DATE_FORMAT_WITH_DAY } from '../../utils/date'
import { ColumnType } from '../../view/containers/commons/AgGrid'
import { RowNode, ColDef, ValueGetterParams } from 'ag-grid-community'
import { Tree } from '../commons/tree'
import { ProjectMemberProps } from './projectMember'
import EntitySearch from '../commons/entitySearch'
import { Component, FunctionProperty } from '../commons/appFunction'
import store from '../../store'
import { OrganizationWorkingDayCalendarDetail } from './organizationWorkingDayCalendar'
import DateVO from '../../vo/DateVO'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

export type GetSprintResponse = SprintDetail[]

export enum SprintStatus {
  STANDBY = 'STANDBY',
  INPROGRESS = 'INPROGRESS',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
}

export const FilterSortOrder = [
  SprintStatus.INPROGRESS,
  SprintStatus.FINISHED,
  SprintStatus.STANDBY,
  SprintStatus.CANCELED,
]

export interface SprintDetail extends Tree<SprintDetail> {
  uuid: string
  lockVersion: number
  revision: string
  teamUuid: string
  num: number
  name: string
  startDate: string
  endDate: string
  status: SprintStatus
  actualStartDateTime?: string
  actualEndDateTime?: string
  businessDate: number
  businessHour: number
  teamName?: string
  projectUuid?: string
}

export interface SprintInput {
  projectUuid: string
  teamUuid: string
  uuid?: string
  lockVersion?: number
  revision?: string
  code?: string
  name: string
  num?: number
  startDate?: string
  endDate?: string
  status?: SprintStatus
  actualStartDateTime?: string
  actualEndDateTime?: string
}

export interface SprintDeltaInput {
  uuid: string
  name?: IItemDelta<string>
  startDate?: IItemDelta<string>
  endDate?: IItemDelta<string>
  status?: IItemDelta<SprintStatus>
  actualStartDateTime?: IItemDelta<string>
  actualEndDateTime?: IItemDelta<string>
}

export interface SprintBatchDeltaInput {
  added: SprintInput[]
  edited: SprintDeltaInput[]
  deleted: {
    uuid: string
    lockVersion: number
  }[]
}

export interface SprintProps {
  uuid: string
  num: number
  name: string
  startDate: string
  endDate: string
  status: SprintStatus
}

export interface GetSprintBasedCurrentSprintProps {
  teamUuid: string
  previous?: number
  following?: number
}

export interface GetSprintMemberWorksProps {
  teamUuid: string
  sprintUuid: string[]
}

export interface SprintMemberWorksProps {
  sprint: SprintProps
  works: MemberWork[]
}

interface MemberWork {
  projectMember: ProjectMemberProps
  estimatedHour: number
  actualHour: number
}

export interface GetSprintsProps {
  projectUuid?: string
  teamUuid?: string
  statusList: SprintStatus[]
}

class Sprint extends EntitySearch {
  public search = async (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      searchOptions.teamUuid,
      (query: string) => ({
        teamUuid: query || '',
        statusList: uiMeta
          ? this.resolveStatusList(uiMeta)
          : searchOptions.statusList,
      }),
      uiMeta?.component === Component.Sprint
        ? this.toSingleSheetOptionResponse
        : this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  filter(entity: any, data: any): boolean {
    if (data.wbsItem && data.wbsItem.team) {
      return entity.teamUuid === data.wbsItem.team.uuid
    }
    // TODO: fixed filter method to use path of object.
    if (data.task && data.task.team) {
      return entity.teamUuid === data.task.team.uuid
    }
    return false
  }

  private resolveStatusList = (prop: FunctionProperty) => {
    if (
      prop.externalId.endsWith('sprint') ||
      prop.component === Component.Sprint
    ) {
      return [SprintStatus.INPROGRESS, SprintStatus.STANDBY]
    }
    return undefined
  }

  toResponse = (sprint: SprintDetail) => ({
    ...sprint,
    name: sprint.name,
  })

  toSingleSheetOptionResponse = (sprint: SprintDetail) => ({
    ...sprint,
    code: undefined,
  })

  getSprints(
    query: GetSprintsProps,
    signal?: AbortSignal | undefined
  ): Promise<APIResponse> {
    if (!query.teamUuid) {
      if (!query.projectUuid) {
        // @ts-ignore
        return successDummyResponse
      }
      return API.functional.request(
        'GET',
        '/api/v1/projects/scrums/sprints/all',
        {
          projectUuid: query.projectUuid || store.getState().project.selected,
        },
        true,
        signal
      )
    }
    return API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints',
      query,
      true,
      signal
    )
  }

  entitySearchApi = (
    query: GetSprintsProps,
    signal?: AbortSignal | undefined
  ): Promise<APIResponse> => {
    return this.getSprints(query, signal)
  }

  getSprintBasedCurrentSprint({
    teamUuid,
    previous,
    following,
  }: GetSprintBasedCurrentSprintProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/based_current_sprint',
      {
        teamUuid,
        previous,
        following,
      }
    )
  }

  updateBatchDelta(request: SprintBatchDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/scrums/sprints/delta/batch',
      request
    )
  }

  getMemberWorks({
    teamUuid,
    sprintUuid,
  }: GetSprintMemberWorksProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/member_works',
      {
        teamUuid,
        sprintUuid,
      }
    )
  }

  getSprintPlanColumns(params: {
    sprints: SprintDetail[]
    organizationWorkingDayCalendars: OrganizationWorkingDayCalendarDetail[]
    hideCheckBox: ((rowNode: RowNode) => void) | boolean
    valueGetter?: (params: ValueGetterParams) => any
  }): ColDef[] {
    const columns: any[] = []

    params.sprints.forEach((sprint, index) => {
      const cellEditorParams = {
        field: `sprint_${sprint.uuid}`,
        disabled: sprint.status === SprintStatus.FINISHED,
        hide: params.hideCheckBox,
      }
      columns.push({
        colId: `sprint_${sprint.uuid}`,
        headerName: new DateVO(sprint.startDate).format(
          DISPLAY_DATE_FORMAT_WITH_DAY
        ),
        children: [
          {
            headerName: `${sprint.businessDate}d / ${sprint.businessHour}h`,
            field: `sprint_${sprint.uuid}`,
            type: ColumnType.checkBox,
            hide: [SprintStatus.FINISHED, SprintStatus.CANCELED].includes(
              sprint.status
            ),
            width: 100,
            cellEditorParams: cellEditorParams,
            cellRendererParams: cellEditorParams,
            cellStyle:
              sprint.status === 'FINISHED'
                ? { backgroundColor: '#bdbdbd' }
                : undefined,
            filterParams: {
              buttons: ['reset'],
            },
            valueGetter: params.valueGetter,
          },
        ],
      })
    })
    return columns
  }

  orderDescWithInprogressFirst = (sprints: SprintDetail[]) => {
    if (_.isEmpty(sprints)) {
      return []
    }
    sprints.reverse()
    const currentIndex = sprints.findIndex(
      sprint => sprint.status === SprintStatus.INPROGRESS
    )
    if (currentIndex < 0) {
      return sprints
    }
    const currentSprint = sprints[currentIndex]
    sprints.splice(currentIndex, 1)
    return [currentSprint, ...sprints]
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const sprint = response.find(v => v.uuid === uuid)
    return {
      status: 200,
      json: sprint,
    }
  }

  getSingleByUuid = async (
    sprintUuid: string
  ): Promise<SprintDetail | undefined> => {
    if (!sprintUuid) {
      return undefined
    }
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/get_by_uuid',
      {
        sprintUuid,
      }
    )
    return response.status === 200 && response.json ? response.json : undefined
  }

  searchByText = async (
    teamUuid: string,
    searchText?: string
  ): Promise<SprintDetail[]> => {
    if (!teamUuid) {
      return []
    }
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/find',
      {
        teamUuid,
        searchText,
      }
    )
    return response.json || []
  }

  getAllCrossProjects = async (
    projectUuids: string[]
  ): Promise<SprintDetail[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/cross_projects',
      {
        projectUuids,
      }
    )
    return response.json || []
  }

  getFilterOptionUuids = ({
    projectUuid,
    rootProjectPlanUuid,
  }: {
    projectUuid: string
    rootProjectPlanUuid?: string
  }): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/filter_options',
      { projectUuid, rootProjectPlanUuid },
      true
    )
  }
}

export default new Sprint()
