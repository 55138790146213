import styled from 'styled-components'
import { IconButton, SelectChangeEvent } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import Select from '../../../../components/editors/select/Select'
import { ReactNode, memo, useCallback } from 'react'
import { SelectOption } from '../../Kanban'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

const SearchConditionSelect = memo(
  ({
    value,
    options,
    onChange,
  }: {
    value?: string
    options: SelectOption[]
    onChange: (value?: string) => void
  }) => {
    const onChangeValue = useCallback(
      (e: SelectChangeEvent<unknown>, child: ReactNode) => {
        onChange(
          typeof e.target.value === 'string'
            ? e.target.value
            : (e.target.value as SelectOption)?.value
        )
      },
      [onChange]
    )
    const clear = useCallback(() => {
      onChange('')
    }, [onChange])

    return (
      <StyledSelect
        isSelected={!!value}
        value={value || null}
        onChange={onChangeValue}
        variant="standard"
        options={options}
        getOptionValue={o => o.value}
        getOptionLabel={o => o.name}
        getOptionIconUrl={o => o.iconUrl}
        endAdornment={
          value ? (
            <IconButton
              onClick={clear}
              aria-label="clear"
              size="small"
              edge="end"
            >
              <ClearRoundedIcon />
            </IconButton>
          ) : (
            <></>
          )
        }
      />
    )
  },
  (prev, next) => prev.value === next.value && prev.options === next.options
)

export default SearchConditionSelect

const StyledSelect = styled(Select)<{ isSelected: boolean }>(props => ({
  display: 'flex',
  minWidth: '250px',
  minHeight: '32px',
  backgroundColor: colorPalette.monotone[0],
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  '& .MuiSelect-select': {
    display: 'inline-flex',
    paddingLeft: '8px',
    minHeight: '32px',
  },
  '& .MuiAvatar-root': {
    marginLeft: '0',
  },
  '& .MuiTypography-root': {
    lineHeight: '20px !important',
    color: colorPalette.monotone[5],
  },
  '& .MuiSelect-icon': {
    display: props.isSelected ? 'none' : 'inline-flex',
  },
}))
