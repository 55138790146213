import React from 'react'
import { styled } from '@mui/system'
import { Menu } from '@mui/material'
import Button from '@mui/material/Button'
import AlertDialog from '../components/dialogs/AlertDialog'
import { Attachment, FileType, isAttached } from '../../utils/attachment'
import TextInput from '../components/editors/input/TextInput'
import { generateUuid } from '../../utils/uuids'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { AllState } from '../../store'
import { connect } from 'react-redux'

// Styles
const RootContainer = styled('div')({
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})
const Title = styled('div')({
  width: '300px',
  padding: '0px 0px',
  margin: '10px',
  color: '#999999',
  fontWeight: 'bold',
  textAlign: 'center',
})
const HorizontalRule = styled('hr')({
  backgroundColor: '#c0c0c0',
  height: '1px',
  border: 0,
  margin: '8px',
})
const TextFieldLabel = styled('span')({
  color: '#999999',
  fontWeight: 'bold',
  padding: '0 10px',
})
const TextField = styled(TextInput)({
  width: '100%',
  padding: '5px 10px',
})

interface Props extends WrappedComponentProps {
  attachments: Attachment[]
  anchorEl: any
  closeHandler: Function
  accept: string[]
  maxSize?: number
  addAttachmentHandler: (attachments: Attachment[]) => void | Promise<void>
  currentExternalId?: string
}

interface Status {
  openMessage: boolean
  message: string
  url: string
  name: string
  disableAttach: boolean
}

class AttachmentMenu extends React.Component<Props, Status> {
  constructor(props: Props) {
    super(props)
    this.state = {
      openMessage: false,
      message: '',
      url: '',
      name: '',
      disableAttach: true,
    }
  }

  private close = () => {
    this.setState({ url: '', name: '' })
    this.props.closeHandler()
  }

  private attach = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      isAttached(
        this.props.currentExternalId!,
        { name: this.state.name, url: this.state.url, source: FileType.LINK },
        this.props.attachments
      )
    ) {
      return
    }
    this.props.addAttachmentHandler([
      {
        uuid: generateUuid(),
        source: FileType.LINK,
        name: this.state.name,
        url: this.state.url,
      },
    ])
    this.close()
  }

  private closeMessage = () => {
    this.setState({
      openMessage: false,
      message: '',
    })
  }

  private urlChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      url: e.target.value,
      disableAttach:
        e.target.value.length === 0 || this.state.name.length === 0,
    })
  }

  private nameChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: e.target.value,
      disableAttach: e.target.value.length === 0 || this.state.url.length === 0,
    })
  }

  render() {
    return (
      <RootContainer>
        <Menu
          id="attachment-menu"
          anchorEl={this.props.anchorEl}
          keepMounted={true}
          open={Boolean(this.props.anchorEl)}
          onClose={this.close}
          variant="menu"
        >
          <Title>Attach from...</Title>
          <HorizontalRule />
          <div>
            <TextFieldLabel>Attach a link</TextFieldLabel>
            <br />
            <TextField
              id="attachment-url"
              variant="outlined"
              value={this.state.url}
              onChange={this.urlChanged}
            />
            <br />
            <TextFieldLabel>Link name</TextFieldLabel>
            <br />
            <TextField
              id="attachment-name"
              variant="outlined"
              value={this.state.name}
              onChange={this.nameChanged}
            />
          </div>
          <div>
            <Button
              onClick={this.attach}
              disabled={this.state.disableAttach}
              color="primary"
            >
              Attach
            </Button>
            <Button onClick={this.close} color="primary">
              Close
            </Button>
          </div>
        </Menu>
        <AlertDialog
          isOpen={this.state.openMessage}
          message={this.state.message}
          closeButtonTitle="OK"
          closeHandler={this.closeMessage}
        />
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  currentExternalId: state.appFunction.currentExternalId,
})

export default connect(mapStateToProps)(injectIntl(AttachmentMenu))
