import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../entity/WbsItemEntity'

export type AggregatedValue = {
  count: AggregatedCount
  workload: AggregatedWorkload
}

export type AggregatedCount = {
  deliverable: number
  task: number
}

export type AggregatedWorkload = {
  deliverable: number
  task: number
}

const getValue = (
  v: AggregatedValue,
  target: AggregateTarget,
  field: AggregateField
) => {
  switch (field) {
    case AggregateField.WBS_ITEM_COUNT:
      return getTargetValue(v.count, target)
    case AggregateField.WBS_ITEM_WORKLOAD:
      return getTargetValue(v.workload, target)
  }
}

const getFormatted = (
  v: AggregatedValue,
  target: AggregateTarget,
  field: AggregateField
): string => {
  switch (field) {
    case AggregateField.WBS_ITEM_COUNT:
      return getTargetValue(v.count, target).toFixed(0)
    case AggregateField.WBS_ITEM_WORKLOAD:
      return getTargetValue(v.workload, target).toFixed(1)
  }
}

const getFormattedRatePercentage = (
  a: AggregatedValue,
  b: AggregatedValue,
  target: AggregateTarget,
  field: AggregateField
): string => {
  switch (field) {
    case AggregateField.WBS_ITEM_COUNT:
      return formatRatePercentage(
        getTargetValue(a.count, target) / getTargetValue(b.count, target)
      )
    case AggregateField.WBS_ITEM_WORKLOAD:
      return formatRatePercentage(
        getTargetValue(a.workload, target) / getTargetValue(b.workload, target)
      )
  }
}

const formatRatePercentage = (v: number): string => {
  if (Number.isNaN(v) || !Number.isFinite(v)) return '-'
  return `${(100 * v).toFixed(1)}%`
}

export const aggregatedValueService = {
  getValue,
  getFormatted,
  getFormattedRatePercentage,
}

const zero = (): AggregatedWorkload => {
  return {
    deliverable: 0,
    task: 0,
  }
}

const sum = (
  a: AggregatedWorkload,
  b: AggregatedWorkload
): AggregatedWorkload => {
  return {
    deliverable: a.deliverable + b.deliverable,
    task: a.task + b.task,
  }
}

const getTargetValue = (
  v: AggregatedWorkload,
  target: AggregateTarget
): number => {
  switch (target) {
    case WbsItemType.DELIVERABLE:
      return v.deliverable
    case WbsItemType.TASK:
      return v.task
  }
}

export const aggregatedWorkloadService = {
  zero,
  sum,
  getTargetValue,
}
