import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'
import CodeVO from '../CodeVO'
import URLVO from '../URLVO'
import UUIDVO from '../UUIDVO'

export interface UserBasic {
  uuid: string
  code?: string
  iconUrl?: string
  name?: string
}

export default class UserBasicVO extends DomainValueObjectBase<UserBasic> {
  uuid: string
  code?: string
  iconUrl?: string
  name?: string

  constructor(_uuid: UUIDVO, _code?: CodeVO, _iconUrl?: URLVO, _name?: string) {
    if (!_uuid) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'User basic value is required.',
      })
    }
    super({
      uuid: _uuid.toPlainValue(),
      code: _code?.toPlainValue(),
      iconUrl: _iconUrl?.toPlainValue(),
      name: _name,
    })
  }
}
