import React from 'react'
import { InputBase } from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import NumberVO from '../../../../../../vo/NumberVO'
import { ValidationError } from '../../../../meta/validator'

const HideWheelStyle = {
  margin: 0,
  '-webkit-appearance': 'none',
  appearance: 'none',
}
const StyledInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  '& > input::-webkit-inner-spin-button': HideWheelStyle,
  '& > input::-webkit-outer-spin-button': HideWheelStyle,
})

interface NumberCellProps extends CellPropsBase<NumberVO> {}

interface NumberCellState extends CellStateBase {}

class NumberCell extends DataCellBase<
  NumberVO,
  NumberCellProps,
  NumberCellState
> {
  validate = (value: NumberVO) => {
    if (value && value.getValue() && !Number.isFinite(value.getValue())) {
      return new ValidationError('Required to input number.')
    }
    return undefined
  }

  onChange = (event: any) => {
    this.props.onChange(
      event.target.value ? new NumberVO(event.target.value) : undefined
    )
  }

  render() {
    const { value, cellDef } = this.props
    return (
      <StyledInput
        type="number"
        value={value ? value.getValue() : ''}
        onChange={this.onChange}
        disabled={!cellDef.editable}
        placeholder={cellDef.placeholder}
      />
    )
  }
}

export default NumberCell
