import API from '../../../lib/commons/api'
import CustomEnumEntity from '../../../domain/entity/CustomEnumEntity'
import ICustomEnumRepository from '../../../domain/repository/CustomEnumRepository'
import CodeVO from '../../../domain/value-object/CodeVO'
import CustomEnumFactory from '../../../domain/factory/CustomEnumFactory'

const customEnumFactory = new CustomEnumFactory()

export default class CustomEnumRepository implements ICustomEnumRepository {
  private cachedCustomEnums: CustomEnumEntity[] = []

  async getByCodeAndGroupKeys(
    customEnumCode: CodeVO,
    groupKeys: string[]
  ): Promise<CustomEnumEntity> {
    const cachedCustomEnum = this.cachedCustomEnums.find(
      v =>
        v.customEnumCode.isEqual(customEnumCode) &&
        v.groupKeys.join(':') === groupKeys.join(':')
    )
    if (cachedCustomEnum) {
      return cachedCustomEnum
    }

    const response = await API.functional.request(
      'GET',
      '/api/v1/custom_enums/values',
      {
        customEnumCode: customEnumCode.toPlainValue(),
        groupKeys,
      }
    )
    const values = response.json.map(v => ({
      ...v,
    }))
    const customEnum = customEnumFactory.reconstract({
      customEnumCode: customEnumCode.toPlainValue(),
      groupKeys,
      values,
    })
    this.cachedCustomEnums.push(customEnum)

    return customEnum
  }
}
