import { Typography } from '@mui/material'
import { Button } from '../../../../components/buttons'
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded'
import { intl } from '../../../../../i18n'
import { AllState } from '../../../../../store'
import { open } from '../../../../router'
import { connect } from 'react-redux'

interface Props {
  projectCode: string | undefined
  rootWbsItemUuid: string | undefined
}

const SprintReportButton = ({ rootWbsItemUuid, projectCode }: Props) => {
  return (
    <Button
      sx={{
        margin: '0 5px 0 5px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'fit-content',
      }}
      onClick={() => {
        open(
          `/sprintReport/${projectCode}?rootWbsItem=${rootWbsItemUuid}`,
          undefined,
          undefined,
          true
        )
      }}
      variant="outlined"
      colorPattern="monotone"
    >
      <InsertChartRoundedIcon />
      <Typography>
        {intl.formatMessage({ id: 'wbs.header.sprintReport' })}
      </Typography>
    </Button>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectCode: state.project.current?.code,
})

export default connect(mapStateToProps)(SprintReportButton)
