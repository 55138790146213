import store from '../../store'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import API, { APIResponse } from '../commons/api'
import { CustomEnumValue, FunctionProperty } from '../commons/appFunction'
import EntitySearch from '../commons/entitySearch'
import {
  ProjectPlanBatchInput,
  ProjectPlanCumulation,
  WatchersInput,
} from './projectPlan'
import { WbsItemBasic, WbsItemDeltaInput, WbsItemDetail } from './wbsItem'
import _ from 'lodash'
import objects from '../../utils/objects'
import { SearchFilter } from '../../view/pages/WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import {
  EntityExtensionValue,
  EntityExtensionValueDelta,
} from '../../view/containers/meta/entityExtension'
import { intl } from '../../i18n'
import { Tree } from '../commons/tree'
import { CommentSummary } from '../../store/comments'
import { AttachmentSummary } from '../../utils/attachment'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import { TagsInput } from './tag'
import { getUrlQueryStringParams, isProduction } from '../../utils/urls'
import {
  APPLICATION_FUNCTION_EXTERNAL_ID,
  ComponentProps,
} from '../../view/pages'
import { fromCamelToSnake } from '../../utils/string'
import { WbsItemTypeVO } from '../../domain/value-object/WbsItemTypeVO'

// Delete after refactoring ProgressChart. task#0H6UGOOL
export enum TicketType {
  REFINEMENT = 'REFINEMENT',
  ISSUE = 'ISSUE',
  RISK = 'RISK',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  MEETING = 'MEETING',
  REFINEMENT_NEW = 'REFINEMENT_NEW',
  NONE = 'NONE',
}

export const getTicketTypeLabel = (type: string, types: CustomEnumValue[]) => {
  const value = types.find(t => t.value === type)
  return value?.name || intl.formatMessage({ id: 'ticket' })
}

export interface UpdateTicketDeltaBatchRequest {
  added: CreateTicketInput[]
  edited: UpdateTicketDeltaInput[]
  deleted: {
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
  watchers: WatchersInput[]
  tags?: TagsInput[]
}

export interface CreateTicketInput {
  projectUuid: string
  uuid?: string
  ticketType: string
  ticketListUuid: string
  parentWbsItemUuid: string
  projectPlan: ProjectPlanBatchInput
  extensions?: EntityExtensionValue[]
  parentUuid?: string
  prevSiblingUuid?: string
}

export interface UpdateTicketDeltaInput {
  uuid: string
  ticketListUuid?: IItemDelta<string>
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: WbsItemDeltaInput
  extensions: EntityExtensionValueDelta[]
}

export interface TicketCreateRequest {
  input: CreateTicketInput
  watchers?: string[]
  tags?: TagsInput[]
}

export interface TicketUpdateDeltaRequest {
  input: UpdateTicketDeltaInput
  watchers?: string[]
}

export interface UpdateTicketResponse {
  uuid: string
  lockVersion: number
}

export interface BatchUpdateTicketResponse {
  edited: UpdateTicketResponse[]
}

export interface GetTicketDetailProps {
  uuid: string
}

export interface GetTicketsProps {
  ticketListUuid: string
}

export interface GetTicketProps {
  projectUuid: string
}

export interface TicketDetailTree<T extends TicketDetailTree<T>>
  extends Tree<T>,
    TicketDetail {
  children: T[]
}

export interface TicketListDetailTree
  extends Tree<TicketListDetailTree>,
    TicketDetail {
  children: TicketListDetailTree[]
}

export interface TicketDetail extends Tree<TicketDetail> {
  uuid: string
  lockVersion: number
  wbsItem: WbsItemDetail
  revision: string
  ticketList: WbsItemDetail
  parentWbsItem: WbsItemBasic
  cumulation: ProjectPlanCumulation
  commentSummary: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  ticketType?: string
  parentPath?: string
}

export interface FindTicketProps {
  projectUuid: string
  all?: string
  team?: {
    uuid?: string
    code?: string
    officialName?: string
    shortName?: string
  }
  searchFilter?: SearchFilter
}

export interface TicketBasic {
  uuid: string
  code: string
  wbsItemUuid: string
  projectUuid: string
  projectCode: string
  displayName: string
  ticketType: string
  ticketListUuid: string
}

export class TicketApi extends EntitySearch implements SingleSheetRepository {
  // SingleSheetRepository part
  create(props: TicketCreateRequest): Promise<APIResponse> {
    return API.functional.request('POST', '/api/v1/tickets', props)
  }

  update(props: unknown): Promise<APIResponse> {
    throw new Error('Can not use ticket.update method.')
  }

  updateDelta(props: TicketUpdateDeltaRequest): Promise<APIResponse> {
    return API.functional.request('PUT', '/api/v1/tickets/delta', props)
  }

  getDetail(props: GetTicketDetailProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/tickets/detail',
      {
        uuid: props.uuid,
      },
      true
    )
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/wbs_items/basic', {
      code,
    })
  }

  // EntitySearch part
  public search = async (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: store.getState().project.selected,
        displayName: query,
        ticketType: searchOptions.ticketType,
        limit: query.length === 0 ? 10 : undefined,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  entitySearchApi = (
    props: GetTicketProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/tickets/find',
      props,
      true,
      signal
    )
  }

  toResponse = (response: any) => ({
    uuid: response.uuid,
    code: response.wbsItem?.code,
    name: '[' + response.wbsItem?.code + '] ' + response.wbsItem?.displayName,
    displayName: response.wbsItem?.displayName,
  })

  // Apis for ticket list
  updateBatchDelta(
    request: UpdateTicketDeltaBatchRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/tickets/delta/batch',
      request
    )
  }

  find(props: FindTicketProps): Promise<APIResponse> {
    let param = {
      projectUuid: props.projectUuid,
      all: props.all,
      team: props.team,
      ...props.searchFilter,
    }
    if (props.searchFilter && props.searchFilter.task) {
      objects.setValue(param, 'displayName', props.searchFilter.task)
    }
    return API.functional.request(
      'GET',
      '/api/v1/tickets/find',
      this.removeNameField(param)
    )
  }

  getByTicketListUuid = (props: GetTicketsProps): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/tickets/list', props)
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }

  protected removeNameField(param) {
    let result = _.cloneDeep(param)
    if (result.deliverable) {
      delete result.deliverable.name
    }
    if (result.accountable) {
      delete result.accountable.name
    }
    if (result.responsible) {
      delete result.responsible.name
    }
    if (result.assignee) {
      delete result.assignee.name
    }
    if (result.sprint) {
      delete result.sprint.name
    }
    if (result.team) {
      delete result.team.name
    }
    if (result.wbsItem) {
      delete result.wbsItem.name
      delete result.wbsItem.displayName
      delete result.wbsItem.uuid
    }
    return result
  }

  getTicketBasicByUuid = async (uuid: string): Promise<TicketBasic> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/tickets/basic',
      {
        uuid,
      }
    )
    return response.json
  }

  getTicketBasicList = async (
    projectUuid: string,
    ticketType: TicketType
  ): Promise<TicketBasic[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/tickets/basic/list',
      {
        projectUuid,
        ticketType,
      }
    )
    return response.json
  }
}

export default new TicketApi()

export interface TicketQueryParams {
  ticketType: TicketType | undefined
  ticketListUuid: string | undefined
}

export const getTicketTypeFromQueryParam = ():
  | TicketQueryParams
  | undefined => {
  const urlQueryObject = getUrlQueryStringParams()
  if (!urlQueryObject) return undefined
  const ticketTypeFromQuery = urlQueryObject['ticketType']
  const ticketListUuid = urlQueryObject['ticketList']

  if (!ticketTypeFromQuery && !ticketListUuid) return undefined
  return {
    ticketType: ticketTypeFromQuery as TicketType,
    ticketListUuid: ticketListUuid,
  }
}

export const isUsingTicketComponent = (component: ComponentProps): boolean => {
  return (
    !isProduction &&
    component.useUiMetaOf?.('') === APPLICATION_FUNCTION_EXTERNAL_ID.TICKETS
  )
}

export const convertTicketExternalIdToTicketType = (
  externalId: string
): string => {
  const ticketTypeFromExternalId = fromCamelToSnake(
    externalId.split('.')[0]
  ).toUpperCase()

  const ticketType =
    ticketTypeFromExternalId === TicketType.REFINEMENT_NEW.toString() &&
    store.getState().project.ticketTypes.some((type: WbsItemTypeVO) => {
      return type.code === TicketType.REFINEMENT
    })
      ? TicketType.REFINEMENT.toString()
      : ticketTypeFromExternalId

  return `?ticketType=${ticketType}`
}
