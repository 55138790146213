import { ICellEditorParams } from 'ag-grid-community'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { PositionBasic } from '../../UserPositions'
import { MenuItem, MenuProps, Select, Typography } from '@mui/material'
import styled from 'styled-components'
import { Color } from '../../../../../styles/commonStyles'
import { moveToNextRow } from '../../../../containers/BulkSheetView/components/cellEditor'

const selectCellMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}

const EditorSelect = styled(Select)({
  width: '100%',
  height: '100%',
  '& .MuiSelect-select': {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

const EditorSelectMenuItem = styled(MenuItem)(
  (props: { backgroundColor?: string }) => ({
    display: 'flex',
    margin: '2px',
    height: '26px',
    backgroundColor: props.backgroundColor
      ? `${props.backgroundColor}66`
      : 'inherit',
    transparent: '50%',
    borderRadius: '3px',
    '&:focus': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
    '&:hover': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
  })
)

interface PositionCellEditorProps extends ICellEditorParams {
  positions: PositionBasic[]
}

export const PositionCellEditor = forwardRef(
  (props: PositionCellEditorProps, ref) => {
    const { api, colDef, rowIndex, data, column, positions } = props
    const refInput = useRef<HTMLSelectElement>(null)
    const [val, setVal] = useState(props.value ?? '')
    const [options, setOptions] = useState<PositionBasic[]>([])
    const firstRendered = useRef(true)

    useEffect(() => {
      setOptions(positions)
    }, [positions])

    useEffect(() => {
      refInput.current?.focus()
    }, [])

    useEffect(() => {
      if (firstRendered.current) {
        firstRendered.current = false
        return
      }
      api.stopEditing()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return val
        },
      }
    })

    return (
      <EditorSelect
        ref={refInput}
        variant="standard"
        value={val}
        disableUnderline={true}
        open={true}
        onChange={e => setVal(e.target.value)}
        onClose={() => setTimeout(() => api.stopEditing(), 100)}
        MenuProps={selectCellMenuProps}
      >
        {options.map((option: PositionBasic, i: number) => {
          return (
            <EditorSelectMenuItem
              key={`${colDef.field}-${rowIndex}-${i}`}
              value={option.uuid}
              onKeyDown={e =>
                setTimeout(() => {
                  if (e.key === 'Enter') {
                    moveToNextRow(api, rowIndex, column)
                  } else if (e.key === 'Tab') {
                    api.tabToNextCell()
                  }
                }, 100)
              }
            >
              <Typography variant="subtitle2" sx={{ margin: 'auto 0px' }}>
                {option.displayName}
              </Typography>
            </EditorSelectMenuItem>
          )
        })}
      </EditorSelect>
    )
  }
)
