import { ICellRendererParams } from 'ag-grid-community'
import { FloatingEditableIcon } from '../../containers/commons/AgGrid/components/cell/common/text'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import formatter from '../../containers/meta/formatter'
import { RootDiv } from '../../containers/commons/AgGrid/components/cell/CellRendererRoot'

export default function CellRenderer(params: ICellRendererParams) {
  const { data, colDef, value, valueFormatted } = params
  if (!data || data.total || !colDef) {
    return <></>
  }

  let label = valueFormatted || value
  const cumulation: ProjectPlanCumulation = data.cumulation
  const formatWorkload = (value: number | undefined) =>
    formatter.format(
      Number(
        (value || 0) /
          (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
      ),
      params.colDef!.cellRendererParams.uiMeta
    )
  if (
    data.wbsItem.wbsItemType.isTask() &&
    cumulation &&
    (!!cumulation.countStatusTaskTodo ||
      !!cumulation.countStatusTaskDoing ||
      !!cumulation.countStatusTaskReview ||
      !!cumulation.countStatusTaskDone)
  ) {
    label = `${label} / ${formatWorkload(
      cumulation.actualHour +
        cumulation.sumActualHour -
        cumulation.sumActualHourDiscard
    )}`
  }

  return (
    <RootDiv>
      {label}
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}
