import { useCallback, useEffect, useState } from 'react'
import { AgreementRepository } from '../../applications/ports/agreementRepository'
import api from '../../lib/commons/api'
import { AgreementEntity } from '../../domain/entity/AgreementEntity'

export const useAgreementRepository = (): AgreementRepository => {
  const getAgreements = useCallback(async () => {
    const fetchedAgreements = await Promise.all([
      api.functional.request('GET', '/api/v1/agreements/list'),
      api.functional.request('GET', '/api/v1/agreements/logs'),
    ]).then(response => ({
      agreements: response[0].json as AgreementEntity[],
      userAgreementLogs: response[1].json as AgreementEntity[],
    }))
    return fetchedAgreements
  }, [])

  const saveUserAgreementLogs = useCallback(
    async (agreements: AgreementEntity[]) => {
      await api.functional.request('POST', '/api/v1/users/agree_terms', {
        agreements: agreements.map(v => ({ uuid: v.uuid, version: v.version })),
      })
      const response = await api.functional.request(
        'GET',
        '/api/v1/agreements/logs'
      )
    },
    []
  )
  return {
    getAgreements,
    saveUserAgreementLogs,
  }
}
