import { useCallback, useMemo, useState } from 'react'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import { WbsItemSearchConditionStorageService } from '../../../../services/storage-services/ui-states/wbsItemSearchConditionStorageService'

const wbsItemSearchSearchConditionFields = [
  'searchText',
  'rootUuid',
  'code',
  'types',
  'ticketTypes',
  'status',
  'priority',
  'displayName',
  'description',
  'teamUuid',
  'accountableUuid',
  'responsibleUuid',
  'assigneeUuid',
  'scheduledStartDate',
  'scheduledEndDate',
  'actualStartDate',
  'actualEndDate',
  'blank',
  'createdAt',
  'updatedAt',
  'createdByUuid',
  'updatedByUuid',
  'sprintUuid',
  'tagUuids',
] as const

export type WbsItemSearchSearchCondition = Pick<
  WbsItemSearchConditionVO,
  (typeof wbsItemSearchSearchConditionFields)[number]
>

const wbsItemSearchDefaultSearchCondition = {
  searchText: '',
  types: [WbsItemType.TASK],
  status: [
    WbsItemStatus.TODO,
    WbsItemStatus.DOING,
    WbsItemStatus.REVIEW,
    WbsItemStatus.DONE,
  ],
}

type UpdateWbsItemSearchSearchCondition = (
  updated: Partial<WbsItemSearchSearchCondition>
) => void
type SaveWbsItemSearchSearchCondition = () => Promise<void>
type WbsItemSearchSearchConditionUseCases = {
  updateSearchCondition: UpdateWbsItemSearchSearchCondition
  saveSearchCondition: SaveWbsItemSearchSearchCondition
  overrideSearchCondition: (
    newSearchCondition: WbsItemSearchConditionVO
  ) => void
  resetSearchCondition: () => void
}

export const useWbsItemSearchSearchCondition = (
  functionUuid: string,
  projectUuid: string,
  defaultSearchCondition: WbsItemSearchSearchCondition,
  searchConditionStorageService: WbsItemSearchConditionStorageService
): {
  searchCondition: WbsItemSearchConditionVO
} & WbsItemSearchSearchConditionUseCases => {
  const [searchCondition, setSearchCondition] =
    useState<WbsItemSearchSearchCondition>(defaultSearchCondition)

  const updateSearchCondition = useCallback(
    (updated: Partial<WbsItemSearchSearchCondition>) => {
      setSearchCondition(prev => ({
        ...prev,
        ...updated,
      }))
    },
    []
  )

  const overrideSearchCondition = useCallback(
    (newSearchCondition: WbsItemSearchConditionVO) => {
      setSearchCondition(newSearchCondition)
    },
    []
  )

  const saveSearchCondition = useCallback(async () => {
    searchConditionStorageService.save(
      functionUuid,
      projectUuid,
      searchCondition
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition])

  const resetSearchCondition = useCallback(() => {
    setSearchCondition(wbsItemSearchDefaultSearchCondition)
  }, [])

  const returnVal = useMemo(
    () => ({
      searchCondition,
      updateSearchCondition,
      saveSearchCondition,
      overrideSearchCondition,
      resetSearchCondition,
    }),
    [
      searchCondition,
      updateSearchCondition,
      saveSearchCondition,
      overrideSearchCondition,
      resetSearchCondition,
    ]
  )

  return returnVal
}
