import { DateV2 } from '../../value-object/DateV2'
import { ReferencedEntity } from '../../value-object/ReferencedEntity'
import { TicketEntity, ticketService, UpdateTicketDeltaInput } from '.'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import { UpdateTagInput } from '../WbsItemEntity'

export type RefinementEntity = {
  uuid: string
  ticket: TicketEntity

  knownRefinement?: boolean
  originalRefinement?: ReferencedEntity
  detectionPhase?: string
  environment?: string
  domain?: string
  featureType?: string
  feature?: string
  defectEvent?: string
  occurrenceProcedure?: string
  testSpecification?: string
  testCase?: string
  importance?: string
  impact?: string
  desiredDeliveryDate?: DateV2
  cause?: string
  directCause?: string
  rootCause?: string
  internalManagement?: boolean
  properDetectionPhase?: string
  interimAction?: string
  permanentAction?: string
  documentsToBeCorrected?: string
  horizontalDeploymentTarget?: boolean
  horizontalDeploymentContent?: string
}

export type UpdateRefinementDeltaRequest = {
  input: UpdateRefinementDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}
export type UpdateRefinementDeltaInput = {
  uuid: string
  ticket: UpdateTicketDeltaInput

  knownRefinement?: IItemDelta<boolean>
  originalRefinement?: IItemDelta<string>
  detectionPhase?: IItemDelta<string>
  environment?: IItemDelta<string>
  domain?: IItemDelta<string>
  featureType?: IItemDelta<string>
  feature?: IItemDelta<string>
  defectEvent?: IItemDelta<string>
  occurrenceProcedure?: IItemDelta<string>
  testSpecification?: IItemDelta<string>
  testCase?: IItemDelta<string>
  importance?: IItemDelta<string>
  impact?: IItemDelta<string>
  desiredDeliveryDate?: IItemDelta<DateV2>
  cause?: IItemDelta<string>
  directCause?: IItemDelta<string>
  rootCause?: IItemDelta<string>
  properDetectionPhase?: IItemDelta<string>
  interimAction?: IItemDelta<string>
  permanentAction?: IItemDelta<string>
  documentsToBeCorrected?: IItemDelta<string>
  horizontalDeploymentTarget?: IItemDelta<boolean>
  horizontalDeploymentContent?: IItemDelta<string>
}

export type UpdateRefinementDeltaResult = {}

const fromApiResponseToEntity = (src: any): RefinementEntity => {
  return {
    ...src,
    uuid: src.uuid,
    ticket: ticketService.fromApiResponseToEntity(src.ticket),
  }
}

export const refinementService = {
  fromApiResponseToEntity,
}
