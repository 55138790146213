import { useCallback, useMemo } from 'react'
import { BulkSheetColumnAndFilterState } from '../../../view/model/bulkSheetColumnAndFilterState'
import { PermanentUiState } from '../../model/uiState'
import { usePermanentUiStateStorageService } from './permanentUiStateStorageService'

type ColumnAndFilterStateStorageService = {
  stored: PermanentUiState<BulkSheetColumnAndFilterState>[]
  fetch: () => Promise<void>
  // TODO: add `save` and `delete` functions/
}

export const useColumnAndFilterStateStorageService = (
  functionUuid: string,
  projectUuid: string
): ColumnAndFilterStateStorageService => {
  const permanentUiStateStorageService = usePermanentUiStateStorageService(
    functionUuid,
    'BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
    projectUuid
  )

  const stored = useMemo(
    () => permanentUiStateStorageService.stored,
    [permanentUiStateStorageService.stored]
  )

  const fetch = useCallback(async () => {
    return permanentUiStateStorageService.fetch()
  }, [permanentUiStateStorageService.fetch])

  return {
    stored,
    fetch,
  }
}
