import moment from 'moment'
import { Box, Toolbar } from '@mui/material'
import {
  AggregateColumn,
  AggregateTargetUnit,
  getWbsProgressLog,
  WbsProgressLogChartType,
  WbsProgressLogData,
  WbsProgressLogDataManager,
  WbsProgressLogDataSeries,
  WbsProgressLogKey,
  WbsProgressLogRequest,
} from '../../../lib/functions/wbsProgressLog'
import objects from '../../../utils/objects'
import DateVO from '../../../vo/DateVO'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  ReportContext,
  ReportLayout,
  ReportOptions,
} from '../../containers/ReportContainer'
import ReportSearchCondition, {
  SearchConditionKey,
  WbsProgressLogSearchCondition,
} from '../../containers/ReportContainer/ReportComponent/SearchCondition/WbsProgressLog'
import ReportViewConfiguration, {
  WbsProgressLogViewConfiguration,
  WbsProgressLogViewConfigurationKey,
} from '../../containers/ReportContainer/ReportComponent/ViewConfiguration/WbsProgressLog'
import { toUrlQuery } from '../../../lib/commons/api'
import {
  DayOfWeek,
  getMaxDimTime,
  getMinDimTime,
  TimeGrain,
} from '../../../lib/functions/report'
import { ChartApi, ChartOptions } from '../../components/charts/Chart'
import StackedBarGraphSpec, {
  StackedBarChartConfigSpec,
  StackedBarChartConfiguration,
  StackedBarGraphConfiguration,
} from '../../components/charts/Chart/StackedBarGraph'
import {
  getWbsItemStatusDeepColorCode,
  getWbsItemStatusLabel,
} from '../../../lib/functions/wbsItem'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ReportSavedCondition } from '../../containers/ReportContainer/SavedCondition'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import store from '../../../store'

export const openProgressChart = (
  _searchCondition: Partial<WbsProgressLogSearchCondition>,
  _viewConfiguration?: Partial<WbsProgressLogViewConfiguration>
) => {
  const projectCode = window.location.pathname.split('/')[2]
  const condition: any = { ..._searchCondition }
  if (condition.time) {
    if (condition.time.from) {
      condition.time.from = condition.time.from.toNumberValue()
    }
    if (condition.time.to) {
      condition.time.to = condition.time.to.toNumberValue()
    }
  }
  const query = toUrlQuery({ ...condition })
  const url = `${window.location.origin}/progressChart/${projectCode}?${query}`
  window.open(url)
}

class ProgressReportChartConfigSpec extends StackedBarChartConfigSpec<WbsProgressLogKey> {
  getGraphConfig(
    chartConfig: StackedBarChartConfiguration,
    key: WbsProgressLogKey
  ): StackedBarGraphConfiguration | undefined {
    return chartConfig.graphConfig.find(
      c =>
        c.key.type === key.type && c.key.aggregateColumn === key.aggregateColumn
    )
  }
}

export default class ProgressChartOptions extends ReportOptions<
  WbsProgressLogKey,
  WbsProgressLogData,
  WbsProgressLogSearchCondition,
  WbsProgressLogViewConfiguration,
  StackedBarChartConfiguration,
  StackedBarGraphConfiguration
> {
  initSearchCondition = (): WbsProgressLogSearchCondition => {
    return {
      aggregateBy: [
        {
          type: WbsItemType.TASK,
          aggregateColumn: [
            AggregateColumn.SCHEDULED_END_DATE,
            AggregateColumn.ACTUAL_END_DATE,
          ],
        },
      ],
      time: {
        from: new DateVO(
          moment().startOf('M').startOf('day').subtract(2, 'M').toDate()
        ),
        to: new DateVO(moment().endOf('M').endOf('day').toDate()),
      },
      timeGrain: TimeGrain.DAY,
      wbsItemType: WbsItemType.TASK,
      wbsItemStatusList: Object.values(WbsItemStatus),
    }
  }
  initViewConfiguration = (): WbsProgressLogViewConfiguration => {
    return {
      timeGrain: TimeGrain.WEEK,
      startDayOfWeek: DayOfWeek.MONDAY,
      aggregateTargetType: AggregateField.WBS_ITEM_COUNT,
      aggregateTargetUnit: AggregateTargetUnit.HOUR,
    }
  }
  initChartConfiguration = (): StackedBarChartConfiguration => {
    return {
      viewTime: {
        from: new DateVO(
          moment().startOf('M').startOf('day').subtract(2, 'M').toDate()
        ),
        to: new DateVO(moment().endOf('M').endOf('day').toDate()),
      },
      displayKeys: Object.values(WbsItemStatus).map(v => ({
        key: v,
        label: getWbsItemStatusLabel(v),
      })),
      graphConfig: [],
    }
  }

  getSearchConditionFromUrlQuery = (urlQueryObject: any) => {
    const time = urlQueryObject.time
    const condition: WbsProgressLogSearchCondition = { ...urlQueryObject }
    if (time) {
      condition.time = {}
      if (time.from) {
        condition.time.from = new DateVO(parseInt(time.from, 10))
      }
      if (time.to) {
        condition.time.to = new DateVO(parseInt(time.to, 10))
      }
    }
    if (!condition || Object.keys(condition).length === 0) {
      return
    }
    if (condition.wbsItemCodes) {
      condition.wbsItemCodes = decodeURIComponent(
        condition.wbsItemCodes
      ).replaceAll(',', ' ')
    }
    return condition
  }

  getViewConfigFromUrlQuery = (urlQueryObject: any) => {
    const viewConfig: Partial<WbsProgressLogViewConfiguration> = {
      ...urlQueryObject,
    }
    if (!viewConfig || Object.keys(viewConfig).length === 0) {
      return
    }
    return viewConfig
  }

  getChartConfigFromUrlQuery = (urlQueryObject: any) => {
    const viewTime = urlQueryObject.viewTime
    const chartConfig: StackedBarChartConfiguration = {
      ...urlQueryObject,
    }
    if (viewTime) {
      chartConfig.viewTime = {}
      if (viewTime.from) {
        chartConfig.viewTime.from = new DateVO(parseInt(viewTime.from, 10))
      }
      if (viewTime.to) {
        chartConfig.viewTime.to = new DateVO(parseInt(viewTime.to, 10))
      }
    }
    if (!chartConfig || Object.keys(chartConfig).length === 0) {
      return
    }
    return chartConfig
  }
  fromSearchConditionToPlainObject = (
    searchCondition: WbsProgressLogSearchCondition
  ) => {
    const from = searchCondition.time?.from
      ? searchCondition.time?.from.toNumberValue()
      : undefined
    const to = searchCondition.time?.to
      ? searchCondition.time?.to.toNumberValue()
      : undefined
    return {
      ...searchCondition,
      time: {
        from,
        to,
      },
    }
  }
  fromViewConfigToPlainObject = (
    viewConfig: WbsProgressLogViewConfiguration
  ) => {
    return viewConfig
  }
  fromChartConfigToPlainObject = (
    chartConfig: StackedBarChartConfiguration
  ) => {
    const from = chartConfig.viewTime.from
      ? chartConfig.viewTime.from.toNumberValue()
      : undefined
    const to = chartConfig.viewTime.to
      ? chartConfig.viewTime.to.toNumberValue()
      : undefined
    return {
      ...chartConfig,
      viewTime: {
        from,
        to,
      },
    }
  }
  updateConfigBySearchCondition = (
    searchCondition: WbsProgressLogSearchCondition,
    viewConfig: WbsProgressLogViewConfiguration
  ): WbsProgressLogViewConfiguration => {
    return {
      ...viewConfig,
      timeGrain: searchCondition.timeGrain || TimeGrain.DAY,
      startDayOfWeek: searchCondition.startDayOfWeek || DayOfWeek.MONDAY,
    }
  }
  updateConfigByData = (
    dataSeries: WbsProgressLogDataSeries[],
    viewConfig: WbsProgressLogViewConfiguration
  ): StackedBarChartConfiguration => {
    const allDimTimes: number[] = []
    dataSeries.forEach(d =>
      d.value.forEach(v => {
        if (!!v.dimTime) allDimTimes.push(v.dimTime)
      })
    )
    let color: { [key: string]: string } = {}
    Object.values(WbsItemStatus).forEach(s => {
      objects.setValue(color, s, getWbsItemStatusDeepColorCode(s))
    })
    return {
      viewTime: {
        from: new DateVO(
          getMinDimTime(
            allDimTimes,
            viewConfig.timeGrain,
            viewConfig.startDayOfWeek
          )
        ),
        to: new DateVO(
          getMaxDimTime(
            allDimTimes,
            viewConfig.timeGrain,
            viewConfig.startDayOfWeek
          )
        ),
      },
      displayKeys: Object.values(WbsItemStatus).map(v => ({
        key: v,
        label: getWbsItemStatusLabel(v),
      })),
      graphConfig: dataSeries.map(data => ({
        key: data.key,
        color,
        borderColor: { ['TODO']: '#DDDDDD' },
      })),
    }
  }
  fetch = async (
    searchCondition: WbsProgressLogSearchCondition,
    projectUuid?: string
  ): Promise<WbsProgressLogDataSeries[]> => {
    if (!projectUuid) {
      throw new Error('projectUuid is not specified')
    }
    const requestBody = this.toRequestBody(searchCondition, projectUuid)
    const wbsProgressLog = (await getWbsProgressLog(requestBody)).json
    return wbsProgressLog.map(v => ({
      key: {
        type: v.key.type,
        aggregateColumn: v.key.aggregateColumn,
      },
      value: v.data.map(d => ({
        dimTime: d.dimTime,
        data: {
          workloadBy: d.estimatedBy,
          countBy: d.countBy,
          wbsItemCodes: d.wbsItemCodes,
        },
      })),
    }))
  }
  private toRequestBody = (
    searchCondition: WbsProgressLogSearchCondition,
    projectUuid: string
  ): WbsProgressLogRequest => {
    const wbsItemType = [WbsItemType.DELIVERABLE, WbsItemType.TASK].find(
      v => v === searchCondition.wbsItemType
    )
    return {
      projectUuid: projectUuid,
      useWriteModel: false,
      aggregateBy: [
        {
          wbsItemType: wbsItemType,
          ticketType: !wbsItemType ? searchCondition.wbsItemType : undefined,
          aggregateColumn: [AggregateColumn.ACTUAL_HOUR_RECORDED_AT],
        },
      ],
      rootWbsItemUuid: searchCondition.rootWbsItemUuid,
      wbsItemCodes: (searchCondition.wbsItemCodes || '').split(' '),
      wbsItemStatusList:
        searchCondition.wbsItemStatusList || Object.values(WbsItemStatus),
      time: {
        from: moment(searchCondition.time!.from!.toNumberValue())
          .startOf('date')
          .valueOf(),
        to: moment(searchCondition.time!.to!.toNumberValue())
          .endOf('date')
          .valueOf(),
      },
      timeGrain: searchCondition.timeGrain || TimeGrain.DAY,
      startDayOfWeek: searchCondition.startDayOfWeek || DayOfWeek.MONDAY,
      teamUuid: searchCondition.teamUuid,
      sprintUuid: searchCondition.sprintUuid,
      accountableUuid: searchCondition.accountableUuid,
      responsibleUuid: searchCondition.responsibleUuid,
    }
  }
  dataManager = new WbsProgressLogDataManager()
  reportLayout = ReportLayout.CHART_ONLY
  uiStateKey = UiStateKey.ProgressChartUIStateCondition
  chartProps = {
    chartSpec: new StackedBarGraphSpec(
      this.dataManager,
      new ProgressReportChartConfigSpec()
    ),
    chartOptions: new ChartOptions(),
    controlPanelItems:
      (
        ctx: ReportContext<
          WbsProgressLogKey,
          WbsProgressLogData,
          WbsProgressLogSearchCondition,
          WbsProgressLogViewConfiguration,
          StackedBarChartConfiguration,
          StackedBarGraphConfiguration
        >
      ) =>
      (
        api: ChartApi<
          WbsProgressLogKey,
          WbsProgressLogData,
          WbsProgressLogViewConfiguration,
          StackedBarChartConfiguration,
          StackedBarGraphConfiguration
        >
      ) =>
        [],
    toolbarItems: (
      ctx: ReportContext<
        WbsProgressLogKey,
        WbsProgressLogData,
        WbsProgressLogSearchCondition,
        WbsProgressLogViewConfiguration,
        StackedBarChartConfiguration,
        StackedBarGraphConfiguration
      >
    ) => [],
  }

  private rootBoxStyles = {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  }

  toolbarItems = (
    ctx: ReportContext<
      WbsProgressLogKey,
      WbsProgressLogData,
      WbsProgressLogSearchCondition,
      WbsProgressLogViewConfiguration,
      StackedBarChartConfiguration,
      StackedBarGraphConfiguration
    >
  ): JSX.Element[] => {
    return [
      <Toolbar
        key={'progress-chart-toolbar'}
        variant="dense"
        sx={{ minHeight: '100%' }}
      >
        <Box sx={this.rootBoxStyles}>
          <ReportSearchCondition
            chartType={WbsProgressLogChartType.PROGRESS}
            projectUuid={ctx.state.projectUuid!}
            condition={ctx.state.searchCondition}
            searchConditionKeys={[
              SearchConditionKey.ROOT_WBS_ITEM,
              SearchConditionKey.WBS_ITEM_TYPE,
              SearchConditionKey.WBS_ITEM_CODES,
              SearchConditionKey.STATUS,
              SearchConditionKey.TIME,
              SearchConditionKey.TIME_GRAIN,
              SearchConditionKey.TEAM,
              SearchConditionKey.SPRINT,
              SearchConditionKey.ACCOUNTABLE,
              SearchConditionKey.RESPONSIBLE,
            ]}
            onSearch={ctx.fetch}
          />
        </Box>
        <Box sx={{ ...this.rootBoxStyles, marginLeft: '5px' }}>
          <ReportViewConfiguration
            keys={[WbsProgressLogViewConfigurationKey.AGGREGATE_TARGET]}
            configuration={ctx.state.viewConfig}
            onChange={(newConfig: WbsProgressLogViewConfiguration) =>
              ctx.onChangeProps({ viewConfig: newConfig })
            }
          />
        </Box>
        <Box sx={this.rootBoxStyles}>
          <ReportSavedCondition
            applicationFunctionUuid={ctx.props.uuid}
            uiStateKey={UiStateKey.ProgressChartUIStateCondition}
            // @ts-ignore
            ctx={ctx}
          />
        </Box>
      </Toolbar>,
    ]
  }
}
