import { ValueFormatterParams } from 'ag-grid-community'
import {
  CellClassParams,
  GridOptions,
  ValueGetterParams,
} from 'ag-grid-enterprise'
import { intl } from '../../../../../i18n'
import { useMemo } from 'react'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
} from '../../../../containers/commons/AgGrid'
import {
  GanttHeader,
  GanttHeaderGroup,
  GanttReportCellRenderer,
} from '../../../../containers/commons/AgGrid/components/cell/custom/ganttReport'
import WbsItemStatusCell from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { MasterScheduleRowData } from './model'
import { ProjectPlanNameCellRenderer } from '../grid/renderers/ProjectPlanNameCellRenderer'
import { colorPalette } from '../../../../style/colorPallete'

// Ag grid options
export const useMasterScheduleGridOptions = (): GridOptions => {
  const gridOptions = useMemo(
    () => ({
      // Styling
      groupHeaderHeight: 25,
      headerHeight: 45,
      rowHeight: 45,
      // Row grouping
      treeData: true,
      // TODO: delete this option if the expanded condition is saved.
      groupDefaultExpanded: -1,
      autoGroupColumnDef: {
        field: 'wbsItem.displayName',
        headerName: intl.formatMessage({
          id: 'projectOverview.displayName',
        }),
        width: 300,
        hide: false,
        pinned: true,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: ProjectPlanNameCellRenderer,
        },
      },
      // Column definition
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: { ...defaultColDef(), hide: true },
      tooltipShowDelay: 100,
      suppressContextMenu: true,
      columnDefs: [
        {
          headerName: intl.formatMessage({ id: 'projectOverview.information' }),
          children: [
            {
              field: 'rowNumber',
              headerName: '#',
              pinned: true,
              lockPosition: 'left',
              suppressMovable: true,
              width: 50,
              hide: false,
              cellClass: 'ag-numeric-cell',
              cellStyle: { justifyContent: 'center' },
            },
            {
              field: 'wbsItem.status',
              headerName: intl.formatMessage({ id: 'projectOverview.status' }),
              type: [ColumnType.wbsItemStatus],
              cellRenderer: WbsItemStatusCell.simpleCellRenderer,
              pinned: true,
              lockPosition: 'left',
              width: 90,
              hide: false,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectOverview.term' }),
          children: [
            {
              field: 'wbsItem.scheduledDate.startDate',
              headerName: intl.formatMessage({
                id: 'projectOverview.scheduledStartDate',
              }),
              type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
              width: 90,
            },
            {
              field: 'wbsItem.scheduledDate.endDate',
              headerName: intl.formatMessage({
                id: 'projectOverview.scheduledEndDate',
              }),
              type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
              width: 90,
            },
            {
              field: 'wbsItem.actualDate.startDate',
              headerName: intl.formatMessage({
                id: 'projectOverview.actualStartDate',
              }),
              type: [ColumnType.date, ColumnType.wbsItemActualDate],
              width: 90,
            },
            {
              field: 'wbsItem.actualDate.endDate',
              headerName: intl.formatMessage({
                id: 'projectOverview.actualEndDate',
              }),
              type: [ColumnType.date, ColumnType.wbsItemActualDate],
              width: 90,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
          children: [
            {
              field: 'scheduledProgressRate',
              headerName: intl.formatMessage({
                id: 'projectOverview.scheduledProgressRate',
              }),
              hide: false,
              valueGetter: (
                params: ValueGetterParams<MasterScheduleRowData>
              ) => {
                if (!params.data) return NaN
                return params.data.progressValues.scheduledProgressRate
              },
              valueFormatter: percentageFormatter,
              cellStyle: progressValuesCellStyle,
            },
            {
              field: 'progressRate',
              headerName: intl.formatMessage({
                id: 'projectOverview.progressRate',
              }),
              hide: false,
              valueGetter: (
                params: ValueGetterParams<MasterScheduleRowData>
              ) => {
                if (!params.data) return NaN
                return params.data.progressValues.progressRate
              },
              valueFormatter: percentageFormatter,
              cellStyle: progressValuesCellStyle,
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'projectOverview.ganttChart' }),
          headerGroupComponent: GanttHeaderGroup,
          children: [
            {
              field: 'ganttChart',
              headerName: intl.formatMessage({
                id: 'projectOverview.ganttChart',
              }),
              cellRenderer: GanttReportCellRenderer,
              headerComponent: GanttHeader,
              cellRendererParams: {
                editable: false,
              },
              hide: false,
              cellStyle: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
              valueGetter: (params: ValueGetterParams) => {
                const w = params.data?.wbsItem
                if (!w) return undefined
                return JSON.stringify([w.scheduledDate, w.status, w.actualDate])
              },
            },
          ],
        },
      ],
    }),
    []
  )
  return gridOptions
}

const progressValuesCellStyle = (
  params: CellClassParams<MasterScheduleRowData>
) => {
  return {
    fontSize: '20px',
    fontWeight: 600,
    color: colorPalette.monotone[9],
    justifyContent: 'flex-end',
  }
}
const percentageFormatter = (
  params: ValueFormatterParams<MasterScheduleRowData, number>
) => {
  const { value } = params
  if (Number.isNaN(value)) return '-'
  return `${(value * 100).toFixed(1)}%`
}
