import React, { PropsWithChildren } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { IconButton, IconButtonProps, styled } from '@mui/material'
import { BackgroundColor } from '../../../../styles/commonStyles'
import Tooltip from '../../tooltips/Tooltip'

export const blueActionIconStyle: React.CSSProperties = {
  color: '#ffffff',
  width: '14px',
  height: '14px',
}

const ActionIconButton = styled(IconButton)({
  width: '24px',
  height: '24px',
  padding: 0,
  boxShadow: '0px 1px 1px #00000033',
})

export const BlueActionIconButton = injectIntl(
  (
    props: {
      onClick?: () => void
      title?: string
    } & PropsWithChildren<WrappedComponentProps & IconButtonProps>
  ) => {
    const { children, onClick, title, ...other } = props
    return (
      <Tooltip message={title || ''}>
        <ActionIconButton
          {...other}
          onClick={onClick}
          sx={{
            background: !props.disabled
              ? BackgroundColor.BLUE_GRADATION
              : BackgroundColor.GREY_GRADATION,
          }}
        >
          {children}
        </ActionIconButton>
      </Tooltip>
    )
  }
)
