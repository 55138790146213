import { useCallback, useState } from 'react'

type AddSelectOption = (...value: string[]) => void
type RemoveSelectOption = (...value: string[]) => void
type ToggleSelectOption = (value: string) => void

export const useSelectOptions = (
  defaultOptions: string[] = []
): [string[], AddSelectOption, RemoveSelectOption, ToggleSelectOption] => {
  const [selectOptions, setSelectOptions] = useState<string[]>(defaultOptions)
  const add = useCallback((...value: string[]) => {
    setSelectOptions(prev => {
      if (value.every(v => prev.includes(v))) {
        return prev
      }
      const newState = [...prev]
      for (let v of value) {
        if (newState.includes(v)) continue
        newState.push(v)
      }
      return newState
    })
  }, [])
  const remove = useCallback((...value: string[]) => {
    setSelectOptions(prev => {
      if (value.every(v => !prev.includes(v))) {
        return prev
      }
      return prev.filter(v => !value.includes(v))
    })
  }, [])
  const toggle = useCallback((value: string) => {
    setSelectOptions(prev => {
      if (prev.some(v => v === value)) {
        return prev.filter(v => v !== value)
      } else {
        return [...prev, value]
      }
    })
  }, [])

  return [selectOptions, add, remove, toggle]
}
