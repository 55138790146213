import Mousetrap from 'mousetrap'
import { KeyBindListener } from '../model/keyBind'
import { DependencyList, useCallback, useEffect } from 'react'

const mousetrap = new Mousetrap()

export const useGlobalKeyBind = (
  listeners: KeyBindListener[],
  deps?: DependencyList
) => {
  const addKeyBindListeners = useCallback((listeners: KeyBindListener[]) => {
    listeners.forEach(listener => {
      const { key, fn, stopDefaultBehavior } = listener
      if (!fn) return
      if (stopDefaultBehavior) {
        mousetrap.bind(key, (e: KeyboardEvent) => {
          e.stopImmediatePropagation && e.stopImmediatePropagation()
          e.preventDefault && e.preventDefault()
          e.stopPropagation && e.stopPropagation()
          fn()
        })
      } else {
        mousetrap.bind(key, fn)
      }
    })
  }, [])
  const removeKeyBindListeners = useCallback((listeners: KeyBindListener[]) => {
    listeners.forEach(listener => {
      mousetrap.unbind(listener.key)
    })
  }, [])

  useEffect(() => {
    addKeyBindListeners(listeners)
    return () => removeKeyBindListeners(listeners)
  }, deps)
}
