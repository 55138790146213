import _ from 'lodash'
import { GetContextMenuItemsParams, GridApi, RowNode } from 'ag-grid-community'
import { intl } from '../../../../i18n'
import DateVO from '../../../../vo/DateVO'
import {
  ProfitLossMemberRow,
  ProfitLossMemberRowMonthlyValueBody,
  ProfitLossMemberRowType,
  ProfitLossMemberRowTypeType,
} from '../ProfitLossMembers'
import {
  generateYearMonthField,
  isRootRow,
  updateRootRowMonthAmountValue,
} from '.'
import { DateTerm } from '../../../../utils/date'
import {
  distinctRowNodes,
  getSummaryRowNode,
} from '../../ProfitLossItems/gridOptions'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'

export const reloadScheduledWorkMonth = (
  params: GetContextMenuItemsParams<ProfitLossMemberRow>,
  action: (targetRow: ProfitLossMemberRow) => void
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLossMembers.contextMenu.fetch.scheduled.workMonth',
    }),
    disabled: !params.node?.data || params.node?.data?.isTotal,
    action: () => {
      action(params.node?.data!)
    },
  }
}

export const recalcBudgetAmount = (
  params: GetContextMenuItemsParams<ProfitLossMemberRow>,
  rows: ProfitLossMemberRow[],
  dateTerm: DateTerm,
  setData: (data: ProfitLossMemberRow[]) => void
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLossMembers.contextMenu.recalc.budget.amount',
    }),
    disabled: !params.node?.data || params.node?.data?.isTotal,
    action: () => {
      const row: ProfitLossMemberRow = params.node?.data!
      const rootNode = isRootRow(row) ? params.node : params.node?.parent
      const result = executeRecalculate(params.api, rows, rootNode, dateTerm)
      if (result.modified) {
        setData(result.rows)
        params.api.redrawRows()
      }
    },
  }
}

export const recalcAllBudgetAmounts = (
  params: GetContextMenuItemsParams,
  rows: ProfitLossMemberRow[],
  dateTerm: DateTerm,
  setData: (data: ProfitLossMemberRow[]) => void
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLossMembers.contextMenu.recalc.budget.amount.all',
    }),
    disabled: !rows || rows.length === 0,
    action: () => {
      let result: RecalculateResult = {
        modified: false,
        rows: _.cloneDeep(rows),
      }
      params.api.forEachNode(node => {
        if (node.level !== 0) return
        const rootNode = isRootRow(node?.data) ? node : node?.parent
        const tempResult = executeRecalculate(
          params.api,
          result.rows,
          rootNode,
          dateTerm
        )
        if (tempResult.modified) {
          result.modified = true
          result.rows = tempResult.rows
        }
      })
      if (result.modified) {
        setData(result.rows)
        params.api.redrawRows()
      }
    },
  }
}

interface RecalculateResult {
  modified: boolean
  rows: ProfitLossMemberRow[]
}

const executeRecalculate = (
  api: GridApi,
  rows: ProfitLossMemberRow[],
  rootNode: RowNode | null | undefined,
  dateTerm: DateTerm
): RecalculateResult => {
  const scheduledWorkMonthRow: ProfitLossMemberRow =
    rootNode?.childrenAfterGroup?.find(
      c =>
        (c.data as ProfitLossMemberRow)?.body.type ===
        ProfitLossMemberRowType.WorkMonthSchedule
    )?.data

  const rootRow: ProfitLossMemberRow = rootNode?.data

  const newRows: ProfitLossMemberRow[] = _.cloneDeep(rows)
  const budgetAmountRow: ProfitLossMemberRow | undefined = newRows.find(
    r =>
      r.body.type === ProfitLossMemberRowType.AmountBudget &&
      r.treeValue[0] === rootRow.uuid
  )
  if (!budgetAmountRow) {
    return {
      modified: false,
      rows: [],
    }
  }

  const newRootRow = newRows.find(r => r.uuid === rootRow.uuid)
  const firstDayOfMonth = new DateVO(dateTerm.startDate).getFirstDayOfMonth()
  let modified: boolean = false
  scheduledWorkMonthRow.body.monthlyValues.forEach(scheduledWorkMonth => {
    const currentYearMonth = new DateVO(scheduledWorkMonth.yearMonth)
    if (!currentYearMonth.isSameOrAfter(firstDayOfMonth)) {
      return
    }

    if (!scheduledWorkMonth.value) return

    const unitPrice = rootRow.unitPrices?.find(p =>
      new DateVO(p.month).isSame(currentYearMonth)
    )
    if (!unitPrice) return

    const budgetAmountMonthlyValue = budgetAmountRow.body.monthlyValues.find(
      m => scheduledWorkMonth.yearMonth === m.yearMonth
    )

    const oldValue = budgetAmountMonthlyValue || undefined
    const newValue = new ProfitLossMemberRowMonthlyValueBody(
      scheduledWorkMonth.yearMonth,
      unitPrice.unitPrice * scheduledWorkMonth.value,
      false
    )
    if (
      oldValue?.value === newValue.value &&
      oldValue?.manualEdited === newValue.manualEdited
    ) {
      return
    }
    if (budgetAmountMonthlyValue) {
      budgetAmountMonthlyValue.value = newValue.value
      budgetAmountMonthlyValue.manualEdited = false
    } else {
      budgetAmountRow.body.monthlyValues.push(newValue)
    }
    modified = true
    budgetAmountRow.edited = !budgetAmountRow.added

    const field = generateYearMonthField(currentYearMonth)
    if (!budgetAmountRow.editedData) {
      budgetAmountRow.editedData = { [field]: oldValue }
    } else if (!budgetAmountRow.editedData.hasOwnProperty(field)) {
      budgetAmountRow.editedData[field] = oldValue
    }

    if (newRootRow) {
      updateRootRowMonthAmountValue(
        api,
        budgetAmountRow,
        newRootRow,
        new DateVO(newValue.yearMonth),
        newValue.value
      )
    }
  })

  const newbudgetAmountRowMonthlyValues: ProfitLossMemberRowMonthlyValueBody[] =
    []
  budgetAmountRow.body.monthlyValues.forEach(budgerAmount => {
    if (
      !scheduledWorkMonthRow.body.monthlyValues.some(
        scheduledWorkMonth =>
          scheduledWorkMonth.yearMonth === budgerAmount.yearMonth
      )
    ) {
      const currentYearMonth = new DateVO(budgerAmount.yearMonth)
      const field = generateYearMonthField(currentYearMonth)
      if (!budgetAmountRow.editedData) {
        budgetAmountRow.editedData = { [field]: budgerAmount }
      } else if (!budgetAmountRow.editedData.hasOwnProperty(field)) {
        budgetAmountRow.editedData[field] = budgerAmount
      }
      budgetAmountRow.edited = !budgetAmountRow.added
      if (newRootRow) {
        updateRootRowMonthAmountValue(
          api,
          budgetAmountRow,
          newRootRow,
          currentYearMonth,
          undefined
        )
      }
    } else {
      newbudgetAmountRowMonthlyValues.push(budgerAmount)
    }
  })
  if (
    !_.isEqual(
      budgetAmountRow.body.monthlyValues,
      newbudgetAmountRowMonthlyValues
    )
  ) {
    budgetAmountRow.body.monthlyValues = newbudgetAmountRowMonthlyValues
    modified = true
  }

  return {
    modified,
    rows: newRows,
  }
}

const scheduledMonthlyValueToActual = (
  api: GridApi,
  rootRowNode: RowNode<ProfitLossMemberRow>,
  scheduledRowType: ProfitLossMemberRowTypeType,
  actualRowType: ProfitLossMemberRowTypeType,
  thisMonth: DateVO
): RowNode<ProfitLossMemberRow>[] | undefined => {
  if (!rootRowNode?.childrenMapped) return
  const children: RowNode<ProfitLossMemberRow>[] = Object.values(
    rootRowNode.childrenMapped
  ).filter(v => !!v)
  const scheduledNode = children.find(
    c => scheduledRowType === c.data?.body.type
  )
  const actualNode = children.find(c => actualRowType === c.data?.body.type)
  const actualRow = actualNode?.data
  if (!scheduledNode?.data || !actualRow) return

  let edited = false
  scheduledNode?.data.body.monthlyValues.forEach(scheduledMonthlyValue => {
    const targetMonth = new DateVO(scheduledMonthlyValue.yearMonth)
    if (
      !scheduledMonthlyValue?.value ||
      thisMonth.diffMonth(targetMonth) <= 0
    ) {
      return
    }
    const actualMonthlyValue = actualRow?.body.monthlyValues.find(
      v => v.yearMonth === scheduledMonthlyValue.yearMonth
    )
    if (actualMonthlyValue?.value) return

    const oldValue: ProfitLossMemberRowMonthlyValueBody | undefined =
      actualMonthlyValue
    const newValue: ProfitLossMemberRowMonthlyValueBody =
      new ProfitLossMemberRowMonthlyValueBody(
        scheduledMonthlyValue.yearMonth,
        scheduledMonthlyValue.value,
        false
      )
    if (!actualMonthlyValue) {
      actualRow?.body.monthlyValues.push(newValue)
    } else {
      actualMonthlyValue.value = newValue.value
      actualMonthlyValue.manualEdited = false
    }
    actualRow.edited = !actualRow.added
    const field = generateYearMonthField(targetMonth)
    if (!actualRow.editedData) {
      actualRow.editedData = { [field]: oldValue }
    } else if (!actualRow.editedData.hasOwnProperty(field)) {
      actualRow.editedData[field] = oldValue
    }
    updateRootRowMonthAmountValue(
      api,
      actualRow,
      rootRowNode.data!,
      targetMonth,
      newValue.value
    )
    edited = true
  })
  if (edited) {
    store.dispatch(requireSave())
  }
  return edited ? [rootRowNode, actualNode] : undefined
}

export const setScheduledMonthlyValueToActual = (
  params: GetContextMenuItemsParams<ProfitLossMemberRow>
) => {
  return {
    name: intl.formatMessage({ id: 'profitLoss.contextMenu.budget.to.result' }),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      const thisMonth = DateVO.now().getFirstDayOfMonth()
      const editedRowNodes: RowNode<ProfitLossMemberRow>[] = []
      params.api.forEachNode(node => {
        const row = node.data
        if (!row || row?.isTotal) return
        if (!isRootRow(row) || !node.childrenMapped) return
        const editWorkMonthRows = scheduledMonthlyValueToActual(
          params.api,
          node,
          ProfitLossMemberRowType.WorkMonthSchedule,
          ProfitLossMemberRowType.WorkMonthActual,
          thisMonth
        )
        if (editWorkMonthRows) {
          editedRowNodes.push(...editWorkMonthRows)
        }
        const editAmountRows = scheduledMonthlyValueToActual(
          params.api,
          node,
          ProfitLossMemberRowType.AmountBudget,
          ProfitLossMemberRowType.AmountResult,
          thisMonth
        )
        if (editAmountRows) {
          editedRowNodes.push(...editAmountRows)
        }
      })

      const refreshNodes = distinctRowNodes(editedRowNodes)
      if (!_.isEmpty(refreshNodes)) {
        refreshNodes.push(
          ...getSummaryRowNode(params.api, [
            '',
            ProfitLossMemberRowType.AmountResult,
          ])
        )
        params.api.refreshCells({
          rowNodes: refreshNodes,
          force: true,
        })
      }
    },
  }
}
