import { ICellRendererParams } from 'ag-grid-community'
import { FloatingEditableIcon } from '../../containers/commons/AgGrid/components/cell/common/text'
import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import formatter from '../../containers/meta/formatter'
import { RootDiv } from '../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { PropsWithChildren } from 'react'
import { FunctionProperty } from '../../../lib/commons/appFunction'

type ActualWorkCellRendererProps = {
  uiMeta: FunctionProperty
} & ICellRendererParams

/**
 * CAUTION: `value` in props is already divided by rateToHour, whereas actualHour in cumulation is not.
 * This fact means that `value` can be used directly when rendering its own actualHour,
 * whereas when calculating other rows actual hour, each values need to be divided.
 *
 * Usually renders its own actualHour,
 * but when the row is task and has children, renders like x.xx / y.yy,
 * whose denominator is sum of actual hours of children, including self.
 */
export default function CellRenderer({
  uiMeta,
  ...params
}: ActualWorkCellRendererProps) {
  const { data, value, node } = params
  if (!data || data.total) {
    return <></>
  }
  const formattedValue = formatter.format(value, uiMeta)
  const wbsItem: WbsItemRow = data.wbsItem
  const renderAsFraction =
    wbsItem.wbsItemType?.isTask() &&
    node.allLeafChildren?.some(v => {
      const wbsItem: WbsItemRow = v.data.wbsItem
      return (
        v.id !== node.id &&
        wbsItem.wbsItemType?.isTask() &&
        wbsItem.status !== WbsItemStatus.DISCARD
      )
    })
  if (renderAsFraction) {
    // Summarize actualHour of children, including self.
    // Since cumulation.actualHour is not rebated, they need to be divided by rateToHour.
    const denominator =
      node.allLeafChildren
        .map(v => {
          const wbsItem: WbsItemRow = v.data.wbsItem
          if (
            wbsItem.wbsItemType?.isTask() &&
            wbsItem.status !== WbsItemStatus.DISCARD
          ) {
            return v.data.cumulation?.actualHour || 0
          }
          return 0
        })
        .reduce((a, b) => a + b, 0) /
      (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
    const formattedDenominator = formatter.format(denominator, uiMeta)
    return (
      <EditableCell
        {...params}
      >{`${formattedValue} / ${formattedDenominator}`}</EditableCell>
    )
  }

  return <EditableCell {...params}>{formattedValue}</EditableCell>
}

type EditableCellProps = PropsWithChildren<ICellRendererParams>
// TODO: consider generalizing.
const EditableCell = ({ children, ...other }: EditableCellProps) => (
  <RootDiv>
    {children}
    <FloatingEditableIcon {...other} />
  </RootDiv>
)
