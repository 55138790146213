import * as d3 from 'd3'
import {
  DateBucket,
  DateBucketType,
  DateTickIntervalType,
} from '../../../components/charts/model/timeSeries'
import { useCallback, useMemo } from 'react'
import { formatDate, formatMonthDate, formatYearMonth } from '../utils/date'
import { getBucketRepresentativeGetter } from '../../../components/charts/utils/timeSeries'

export const useDateBucketFormatter = (dateBucketType: DateBucketType) => {
  const getBucketRepresentative = useMemo(
    () => getBucketRepresentativeGetter(dateBucketType),
    [dateBucketType]
  )

  const formatBucket = useCallback(
    (bucket: DateBucket) => {
      switch (dateBucketType) {
        case 'DAY':
          return formatDate(getBucketRepresentative(bucket))
        case 'WEEK':
          return `${formatDate(bucket.from)} - ${formatDate(
            d3.timeDay.offset(bucket.to, -1)
          )}`
        case 'MONTH':
          return `${formatYearMonth(d3.timeDay.ceil(bucket.to))}`
      }
    },
    [dateBucketType, getBucketRepresentative]
  )

  return { formatBucket }
}

export const useDateTickFormatter = (
  tickIntervalType: DateTickIntervalType
) => {
  const formatTick = useCallback(
    (tick: Date) => {
      switch (tickIntervalType) {
        case 'DAY':
          return formatMonthDate(tick)
        case 'WEEK':
          return formatMonthDate(tick)
        case 'MONTH':
          return `${formatYearMonth(d3.timeDay.ceil(tick))}`
        /*
        case 'THREE_MONTH':
          return `${formatYearMonth(tick.from)} - ${formatYearMonth(
            d3.timeDay.ceil(tick.to)
          )}`
        case 'HALF_YEAR':
          return `${formatYearMonth(tick.from)} - ${formatYearMonth(
            d3.timeDay.ceil(tick.to)
          )}`
          */
        case 'YEAR':
          return `${d3.timeDay.ceil(tick).getFullYear()}`
        default:
          return ''
      }
    },
    [tickIntervalType]
  )
  return {
    formatTick,
  }
}
