import _ from 'lodash'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Toolbar from '@mui/material/Toolbar'
import { Box, styled } from '@mui/material'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import {
  TwoDimChart,
  TwoDimChartScale,
  TwoDimGraphPlotter,
  ValueFormatter,
} from '../../../components/charts/Chart/TwoDimChart'
import objects from '../../../../utils/objects'
import {
  ChartBaseProps,
  ChartBaseState,
  ChartContext,
  ChartOptions,
} from '../../../containers/ChartContainer'
import {
  getTimeLabel,
  getTimeUnitByGrain,
  getTimeUnitSizeByGrain,
  TimeGrain,
  toMaxDimTime,
  toMinDimTime,
} from '../../../../lib/functions/report'
import {
  AggregateColumn,
  AggregateTargetUnit,
  getLineStyleForAggregateColumn,
  WbsItemTypeForWbsProgressLog,
} from '../../../../lib/functions/wbsProgressLog'
import { VerticalBorderPlotter } from '../../../components/charts/Chart/TwoDimChart/VerticalBorder'
import { BarGraphPlotter } from '../../../components/charts/Chart/TwoDimChart/BarGraph'
import {
  fetchChartCondition,
  fetchProjectUuid,
} from '../../../containers/ChartContainer/utils'
import Legend from './Legend'
import store, { AllState } from '../../../../store'
import {
  putHeaderComponent,
  replaceOrPushFunctionLayer,
  FunctionLayer,
} from '../../../../store/functionLayer'
import ProgressReportViewConfigurationToolbar from '../Toolbar/ViewConfigurationToolbar'
import moment from 'moment'
import ProgressReportSavedUiStateButton from './Toolbar/SavedUiStateButton'
import { connect } from 'react-redux'
import {
  AccumulateDirection,
  ColorPalette,
  fetch as fetchData,
  getChartConfigFromUiState,
  getChartUIState,
  getDataValue,
  getViewConfigFromUiState,
  initChartConfig,
  initGraphConfig,
  initViewConfig,
  isActualOnlyGroup,
  isGraphConfigDisabled,
  isScheduledColumn,
  mapGroupToColumn,
  mergeUIState,
  ProgressReportChartConfig,
  ProgressReportColorPalettes,
  ProgressReportDataSeries,
  ProgressReportGraphConfig,
  ProgressReportSavedUiState,
  ProgressReportViewConfig,
  saveChartUIState,
  summarize,
} from '..'
import {
  AggregateColumnGroup,
  getXValues,
  ProgressReportDataValue,
  SummarizeType,
} from '../utils'
import CurrAndPrevTooltip from './Tooltip/CurrAndPrevTooltip'
import { LineGraphPlotter } from '../../../components/charts/Chart/TwoDimChart/LineGraph'
import { SummaryTooltip } from './Tooltip/SummaryTooltip'
import {
  AggregateField,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { Map } from 'immutable'
import { Color, BorderColor } from '../../../../styles/commonStyles'
import {
  ProgressReportScheduleAndActualValues,
  ProgressReportScheduleAndActualValueMaps,
} from './Tooltip'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../../components/toolbars/ContainerToolBar'
import ProgressReportBreadcrumbs from '../Header/ProgressReportBreadcrumbs'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../..'
import {
  ProgressReportTicketListTitle,
  ProgressReportWbsItemTitle,
} from '../Header/ProgressReportTitle'
import ProgressReportSearchConditionButton, {
  SearchConditionKey,
} from '../Toolbar/ProgressReportSearchConditionButton'
import {
  getConditionFromUiState,
  initProgressReportSearchConditionVO,
  ProgressReportSearchConditionVO,
} from '../../../../domain/value-object/ProgressReportSearchConditionVO'
import { projectPrivate } from '../../../higher-order-components/projectPrivate'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'
import { WorkloadUnit } from '../../../../lib/functions/workload'

const DRAWER_WIDTH = 250

const RootDiv = styled('div')({
  display: `flex`,
  width: '0',
  height: '100%',
  flexGrow: 1,
  paddingTop: '10px',
})

type Props = ChartBaseProps & StateProps
type State = ChartBaseState & {
  condition: ProgressReportSearchConditionVO
  viewConfig: ProgressReportViewConfig
  chartConfig: ProgressReportChartConfig
  isRootLayer: boolean
  setSavedUiState: (_: ProgressReportSavedUiState) => void
  setViewConfig: (_: ProgressReportViewConfig) => void
  setCondition: (_: ProgressReportSearchConditionVO) => void
  currentLayerDepth: number
  currentLayer: FunctionLayer | undefined
  fetch: (
    condition: ProgressReportSearchConditionVO,
    projectUuid: string | undefined,
    ticketTypes?: WbsItemTypeVO[]
  ) => void
}

type StateProps = {
  sideMenuOpen: boolean
  layers: Map<number, FunctionLayer>
  currentLayerDepth: number
}

const mapStateToProps = (state: AllState) => ({
  sideMenuOpen: state.sideMenu.open,
  layers: state.functionLayer.layers,
  currentLayerDepth: state.functionLayer.currentDepth,
})

export default class ProgressReportOptions extends ChartOptions<Props, State> {
  chart = ProgressReport
  toolbarItems = (ctx: ChartContext<Props, State>): JSX.Element[] => {
    const { projectUuid, ticketTypes } = ctx.props
    const {
      condition,
      viewConfig,
      chartConfig,
      setSavedUiState,
      setViewConfig,
      setCondition,
      isRootLayer,
      currentLayerDepth,
      currentLayer,
      fetch,
    } = ctx.state

    return [
      <Toolbar
        key={'progress-report-toolbar'}
        variant="dense"
        sx={{ minHeight: '100%' }}
      >
        {isRootLayer ? (
          <ProgressReportSearchConditionButton
            key={generateToolBarItemKey(1, ToolBarItemPosition.START)}
            condition={condition}
            searchConditionKeys={[
              SearchConditionKey.TEAM,
              SearchConditionKey.ACCOUNTABLE,
              SearchConditionKey.RESPONSIBLE,
              SearchConditionKey.PRIORITY,
            ]}
            onChange={(newCondition: ProgressReportSearchConditionVO) => {
              setCondition({ ...condition, ...newCondition })
            }}
            onSearch={() => {
              updateChartUIState(
                isRootLayer,
                projectUuid,
                currentLayerDepth,
                currentLayer,
                { ...condition, ...viewConfig, ...chartConfig }
              )
              fetch(condition, projectUuid, ticketTypes)
            }}
          />
        ) : (
          <></>
        )}
        <ProgressReportViewConfigurationToolbar
          initialConfig={viewConfig}
          onChange={(newConfig: ProgressReportViewConfig) => {
            setViewConfig(newConfig)
            updateChartUIState(
              isRootLayer,
              projectUuid,
              currentLayerDepth,
              currentLayer,
              {
                ...condition,
                ...newConfig,
                ...chartConfig,
              }
            )
          }}
          hideLabel={true}
        />
        <ProgressReportSavedUiStateButton
          key={'progress-report-saveduistate-toolbar'}
          viewConfig={{
            ...viewConfig,
            viewTime: undefined,
          }}
          chartConfig={chartConfig}
          onChange={(newState: ProgressReportSavedUiState) => {
            const restoreState = {
              ...newState,
              viewTime: viewConfig.viewTime,
              ...condition,
            }
            setSavedUiState(restoreState)
            updateChartUIState(
              isRootLayer,
              projectUuid,
              currentLayerDepth,
              currentLayer,
              restoreState
            )
          }}
          uiStateKey={UiStateKey.ProgressReportUIStateCondition}
        />
      </Toolbar>,
    ]
  }
}

const useProjectUuid = (
  code: string,
  selectedProjectUuid?: string
): [string | undefined] => {
  const [projectUuid, setProjectUuid] = useState<string | undefined>()
  useEffect(() => {
    const init = async () => {
      if (selectedProjectUuid) {
        setProjectUuid(selectedProjectUuid)
        return
      }
      const fetchedProjectUuid = await fetchProjectUuid(code)
      setProjectUuid(fetchedProjectUuid)
    }
    init()
  }, [code, selectedProjectUuid])
  if (!projectUuid) {
    return [undefined]
  }
  return [projectUuid]
}

const useSavedUiState = (
  projectUuid: string | undefined,
  isRootLayer: boolean,
  params?: { [key: string]: any }
): [
  ProgressReportSavedUiState,
  Dispatch<SetStateAction<ProgressReportSavedUiState>>,
  boolean
] => {
  const [savedUiState, setSavedUiState] = useState<ProgressReportSavedUiState>(
    {} as ProgressReportSavedUiState
  )
  const [initialized, setInitialized] = useState<boolean>(false)
  useEffect(() => {
    const getConditionByUrlQuery = async () => {
      if (!projectUuid) {
        return
      }
      const chartUIState = await getChartUIState(projectUuid)
      const mergeChartCondition = isRootLayer
        ? (query: any, savedUIState?: any): ProgressReportSavedUiState => {
            let uiState = mergeUIState(query, savedUIState)
            uiState = mergeUIState(chartUIState, uiState)
            if (
              (query.rootWbsItemUuid || query.ticketListUuid) !==
              (chartUIState?.rootWbsItemUuid || chartUIState?.ticketListUuid)
            ) {
              uiState.viewTime = initViewConfig().viewTime
            }
            return uiState
          }
        : (query: any, savedUIState?: any): ProgressReportSavedUiState => {
            let uiState = mergeUIState(query, savedUIState)
            if (!uiState.viewTime) {
              uiState.viewTime = initViewConfig().viewTime
            }
            uiState = mergeUIState(chartUIState, uiState)
            return uiState
          }

      const chartCondition = await fetchChartCondition(
        mergeChartCondition,
        UiStateKey.ProgressReportUIStateCondition,
        projectUuid,
        params
      )
      if (chartCondition) {
        setSavedUiState(chartCondition)
      }
      setInitialized(true)
    }
    getConditionByUrlQuery()
  }, [projectUuid])
  return [savedUiState, setSavedUiState, initialized]
}

const useSearchCondition = (
  savedUiState: ProgressReportSavedUiState
): [
  ProgressReportSearchConditionVO,
  Dispatch<SetStateAction<ProgressReportSearchConditionVO>>
] => {
  const [condition, setCondition] = useState<ProgressReportSearchConditionVO>(
    initProgressReportSearchConditionVO()
  )
  useEffect(() => {
    setCondition(getConditionFromUiState(savedUiState))
  }, [savedUiState])
  return [condition, setCondition]
}

const useViewConfig = (
  data: ProgressReportDataSeries[],
  savedUiState: ProgressReportSavedUiState
): [
  ProgressReportViewConfig,
  Dispatch<SetStateAction<ProgressReportViewConfig>>
] => {
  const [viewConfig, setViewConfig] = useState<ProgressReportViewConfig>(
    initViewConfig()
  )
  useEffect(() => {
    const dimTimes = data
      .flatMap(dataSeries => dataSeries.value.map(v => v.dimTime))
      .filter(x => !!x)
    const xMin = dimTimes.length !== 0 ? Math.min(...dimTimes) : 0
    const xMax = dimTimes.length !== 0 ? Math.max(...dimTimes) : 0
    const newConfig = {
      ...viewConfig,
    }
    objects.setValue(newConfig, 'viewTime', {
      from: savedUiState.viewTime?.from || xMin,
      to: savedUiState.viewTime?.to || xMax,
    })
    setViewConfig(newConfig)
  }, [data])
  useEffect(() => {
    if (savedUiState) {
      setViewConfig({
        ...viewConfig,
        ...getViewConfigFromUiState(savedUiState),
      })
    }
  }, [savedUiState])
  return [viewConfig, setViewConfig]
}

const useChartConfig = (
  savedUiState: ProgressReportSavedUiState,
  isRootLayer: boolean,
  currentLayerDepth: number,
  currentLayer: FunctionLayer | undefined,
  projectUuid: string | undefined,
  condition: ProgressReportSearchConditionVO,
  viewConfig: ProgressReportViewConfig,
  colorPallet: ColorPalette[],
  data: ProgressReportDataSeries[]
): [
  ProgressReportChartConfig,
  (newGraphConfigs: ProgressReportGraphConfig[]) => void,
  (added: ProgressReportGraphConfig[]) => void,
  (removed: ProgressReportGraphConfig[]) => void
] => {
  const { wbsItemTypes } = useProjectPrivateContext()
  const [chartConfig, setChartConfig] = useState<ProgressReportChartConfig>(
    initChartConfig(viewConfig.aggregateTargetType, wbsItemTypes)
  )
  useEffect(() => {
    if (savedUiState) {
      let newConfig: ProgressReportChartConfig | undefined = undefined
      if (savedUiState.ticketListUuid) {
        const typesInData: WbsItemTypeForWbsProgressLog[] = _.uniqBy(
          data.map(d => d.type),
          type => type.value
        )
        const configFromState = getChartConfigFromUiState(
          savedUiState
        ).graphConfig?.filter(c =>
          typesInData.some(t => t.value === c.key.type.value)
        )
        newConfig = {
          graphConfig:
            configFromState ||
            typesInData.flatMap((type, index) =>
              initGraphConfig(
                type,
                colorPallet[index % colorPallet.length],
                viewConfig.aggregateTargetType,
                (
                  type: WbsItemTypeForWbsProgressLog,
                  grp: AggregateColumnGroup
                ) =>
                  type.value !== WbsItemType.TASK &&
                  grp === AggregateColumnGroup.END_DATE
              )
            ),
        }
      } else {
        newConfig = {
          ...initChartConfig(viewConfig.aggregateTargetType, wbsItemTypes),
          ...chartConfig,
          ...getChartConfigFromUiState(savedUiState),
        }
      }
      setChartConfig(newConfig)
    }
  }, [savedUiState, data])
  useEffect(() => {
    const newGraphConfig: ProgressReportGraphConfig[] =
      chartConfig.graphConfig.concat()
    newGraphConfig.forEach(c => {
      c.disabled = isGraphConfigDisabled(
        c.key.aggregateColumnGroup,
        viewConfig.aggregateTargetType
      )
      if (c.show && c.disabled) {
        c.show = false
      }
    })
    setChartConfig({
      graphConfig: newGraphConfig,
    })
  }, [viewConfig.aggregateTargetType])

  const updateChartConfig = useCallback(
    (newGraphConfigs: ProgressReportGraphConfig[]) => {
      const updatedGraphConfigs = [...chartConfig.graphConfig]
      newGraphConfigs.forEach(newGraphConfig => {
        const currIndex = updatedGraphConfigs.findIndex(
          config =>
            config.key.type.value === newGraphConfig.key.type.value &&
            config.key.aggregateColumnGroup ===
              newGraphConfig.key.aggregateColumnGroup
        )
        updatedGraphConfigs.splice(currIndex, 1, newGraphConfig)
      })
      setChartConfig({ graphConfig: updatedGraphConfigs })
      updateChartUIState(
        isRootLayer,
        projectUuid,
        currentLayerDepth,
        currentLayer,
        {
          ...condition,
          ...viewConfig,
          graphConfig: updatedGraphConfigs,
        }
      )
    },
    [chartConfig, viewConfig]
  )
  const addGraphConfig = useCallback(
    (added: ProgressReportGraphConfig[]) => {
      const newConfigs = [...chartConfig.graphConfig, ...added]
      setChartConfig({
        graphConfig: newConfigs,
      })
      updateChartUIState(
        isRootLayer,
        projectUuid,
        currentLayerDepth,
        currentLayer,
        {
          ...condition,
          ...viewConfig,
          graphConfig: newConfigs,
        }
      )
    },
    [chartConfig, viewConfig]
  )
  const removeGraphConfig = useCallback(
    (removed: ProgressReportGraphConfig[]) => {
      const newGraphConfigs = chartConfig.graphConfig.filter(
        config =>
          !removed.some(
            removedConfig =>
              removedConfig.key.type.value === config.key.type.value &&
              removedConfig.key.aggregateColumnGroup ===
                config.key.aggregateColumnGroup
          )
      )
      setChartConfig({
        graphConfig: newGraphConfigs,
      })
      updateChartUIState(
        isRootLayer,
        projectUuid,
        currentLayerDepth,
        currentLayer,
        {
          ...condition,
          ...viewConfig,
          graphConfig: newGraphConfigs,
        }
      )
    },
    [chartConfig, viewConfig]
  )
  return [chartConfig, updateChartConfig, addGraphConfig, removeGraphConfig]
}

const useScale = (
  viewConfig: ProgressReportViewConfig,
  graphConfig: ProgressReportGraphConfig[],
  data: ProgressReportDataSeries[],
  sideMenuOpen: boolean,
  xValues: number[]
): [TwoDimChartScale | undefined] => {
  const [scale, setScale] = useState<TwoDimChartScale>()
  const {
    timeGrain,
    startDayOfWeek,
    aggregateTargetType,
    aggregateTargetUnit,
    viewTime,
  } = viewConfig
  const workloadUnitState = useWorkloadUnit(WorkloadUnit[aggregateTargetUnit])
  useEffect(() => {
    const containerWidth = document.getElementById(
      'progress-report-root'
    )?.offsetWidth
    const width = containerWidth ? containerWidth - DRAWER_WIDTH : undefined
    const xMin = toMinDimTime(viewTime!.from, timeGrain, startDayOfWeek)
    const xMax = toMaxDimTime(viewTime!.to, timeGrain, startDayOfWeek)
    const yMin = 0
    const yMax = graphConfig
      .filter(config => config.show)
      .flatMap(config => {
        const type = config.key.type
        return mapGroupToColumn(config.key.aggregateColumnGroup).map(
          aggregateColumn => {
            const dataSeries = data.find(
              d =>
                d.type.value === type.value &&
                d.aggregateColumn === aggregateColumn
            )
            if (!dataSeries) return 0
            const xWithMaxY =
              config.direction === AccumulateDirection.BURN_UP ? xMax : xMin
            return summarize(
              dataSeries.value,
              xWithMaxY,
              timeGrain,
              aggregateTargetType,
              aggregateTargetUnit,
              SummarizeType[config.direction],
              workloadUnitState.hoursPerSelectedUnit
            )
          }
        )
      })
      .reduce((prev, curr) => Math.max(prev, curr), 0)
    const xWidth = Math.max(
      56,
      graphConfig.filter(g => g.show && g.showBar).length * 6
    )
    const newScale = {
      width,
      xValues,
      xWidth,
      yMin,
      yMax,
    }
    setScale(newScale)
  }, [viewConfig, graphConfig, data, sideMenuOpen, workloadUnitState])
  return [scale]
}

const useFormatter = (
  timeGrain: TimeGrain,
  aggregateTargetType: AggregateField,
  aggregateTargetUnit: AggregateTargetUnit
): [ValueFormatter, ValueFormatter] => {
  const [xFormatter, setXFormatter] = useState<ValueFormatter>(v => `${v}`)
  const [yFormatter, setYFormatter] = useState<ValueFormatter>(v => `${v}`)
  useEffect(() => {
    setXFormatter(_ => (v: number) => getTimeLabel(v, timeGrain))
  }, [timeGrain])
  useEffect(() => {
    let digit = 0
    if (aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
      digit = 0
    } else {
      if (aggregateTargetUnit === AggregateTargetUnit.HOUR) {
        digit = 2
      } else {
        digit = 1
      }
    }
    setYFormatter(_ => (v: number) => `${Number(v).toFixed(digit)}`)
  }, [aggregateTargetUnit, aggregateTargetType])
  return [xFormatter, yFormatter]
}

const getSummarizeValueByTargetDate = (
  targetDate: number,
  scheduledValues: SummarizedValue[] | undefined,
  actualValues: SummarizedValue[] | undefined
) => {
  const getValue = (
    index: number | undefined,
    values: SummarizedValue[] | undefined,
    indexOffset: number = 0
  ) => {
    if (!index || index < 0 || !values) return 0
    return values[index + indexOffset]?.yValue || 0
  }
  const getValueMap = (
    index: number | undefined,
    values: SummarizedValue[] | undefined,
    indexOffset: number = 0
  ) => {
    if (!index || !values) return undefined
    return values[index + indexOffset]?.wbsItemValueMap
  }

  const scheduledIndex = scheduledValues?.findIndex(
    v => v.xValue === targetDate
  )
  const actualIndex = actualValues?.findIndex(v => v.xValue === targetDate)

  const currValues: ProgressReportScheduleAndActualValues = {
    scheduled: getValue(scheduledIndex, scheduledValues),
    actual: getValue(actualIndex, actualValues),
  }
  const currWbsItemValueMaps: ProgressReportScheduleAndActualValueMaps = {
    scheduled: getValueMap(scheduledIndex, scheduledValues),
    actual: getValueMap(actualIndex, actualValues),
  }

  const prevValues: ProgressReportScheduleAndActualValues = {
    scheduled: getValue(scheduledIndex, scheduledValues, -1),
    actual: getValue(actualIndex, actualValues, -1),
  }
  const prevWbsItemValueMaps: ProgressReportScheduleAndActualValueMaps = {
    scheduled: getValueMap(scheduledIndex, scheduledValues, -1),
    actual: getValueMap(actualIndex, actualValues, -1),
  }
  return {
    currValues,
    prevValues,
    currWbsItemValueMaps,
    prevWbsItemValueMaps,
  }
}

const filterReportDataValueByDateTerm = (
  values: ProgressReportDataValue[],
  fromTime: number,
  toBaseTime: number,
  offsetToTimeGrain?: TimeGrain
): ProgressReportDataValue[] => {
  const toTime = offsetToTimeGrain
    ? moment(toBaseTime)
        .add(
          getTimeUnitSizeByGrain(offsetToTimeGrain),
          getTimeUnitByGrain(offsetToTimeGrain)
        )
        .valueOf()
    : toBaseTime
  return values
    .filter(v => !!v.dimTime)
    .filter(v => fromTime <= v.dimTime && v.dimTime < toTime)
}

const reportDateToValueMap = (
  values: ProgressReportDataValue[],
  aggregateTargetType: AggregateField,
  denominator: number,
  exclusionKeys?: string[]
) => {
  const valueMap: { [code: string]: number } = {}
  const isWorkload = aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD
  values.forEach(v =>
    Object.entries(v.data.workloadMap).forEach(([key, value]) => {
      if (!exclusionKeys || !exclusionKeys.includes(key)) {
        valueMap[key] = isWorkload ? value / denominator : 1
      }
    })
  )
  return valueMap
}

const reportDataToYValue = (
  values: ProgressReportDataValue[],
  aggregateTargetType: AggregateField,
  valueDenominator: number
) => {
  return values
    .map(v => getDataValue(v.data, aggregateTargetType, valueDenominator))
    .reduce((prev, curr) => prev + curr, 0)
}

const summarizeReportDataValueForTypeUnit = (
  xValues: number[],
  timeGrainValues: ProgressReportDataValue[][],
  aggregateField: AggregateField,
  valueDenominator: number
): SummarizedValue[] => {
  const result: SummarizedValue[] = []
  timeGrainValues.forEach((value, index) => {
    const wbsItemValues = reportDateToValueMap(
      value,
      aggregateField,
      valueDenominator
    )
    result.push({
      xValue: xValues[index],
      yValue: reportDataToYValue(value, aggregateField, valueDenominator),
      wbsItemValueMap: wbsItemValues,
    })
  })
  return result
}

const summarizeReportDataValueForTypeBurnUp = (
  xValues: number[],
  values: ProgressReportDataValue[],
  timeGrainValues: ProgressReportDataValue[][],
  aggregateField: AggregateField,
  valueDenominator: number
): SummarizedValue[] => {
  const beforeRangeValues = filterReportDataValueByDateTerm(
    values,
    0,
    xValues[0]
  )
  const result: SummarizedValue[] = []
  timeGrainValues.forEach((value, index) => {
    const wbsItemValues = reportDateToValueMap(
      value,
      aggregateField,
      valueDenominator
    )
    const resultNotEmpty = 0 < result.length
    const prevYValue = resultNotEmpty
      ? result[index - 1].yValue
      : reportDataToYValue(beforeRangeValues, aggregateField, valueDenominator)
    const prevWbsItemValues = resultNotEmpty
      ? result[index - 1].wbsItemValueMap
      : reportDateToValueMap(
          beforeRangeValues,
          aggregateField,
          valueDenominator
        )
    result.push({
      xValue: xValues[index],
      yValue:
        prevYValue +
        reportDataToYValue(value, aggregateField, valueDenominator),
      wbsItemValueMap: _.merge({}, prevWbsItemValues, wbsItemValues),
    })
  })
  return result
}

const summarizeReportDataValueForTypeBurnDown = (
  xValues: number[],
  values: ProgressReportDataValue[],
  timeGrainValues: ProgressReportDataValue[][],
  aggregateField: AggregateField,
  valueDenominator: number
): SummarizedValue[] => {
  const totalYValue = reportDataToYValue(
    values,
    aggregateField,
    valueDenominator
  )
  const beforeRangeValues = filterReportDataValueByDateTerm(
    values,
    0,
    xValues[0]
  )
  const defaultYValue =
    totalYValue -
    reportDataToYValue(beforeRangeValues, aggregateField, valueDenominator)
  const beforeRangeDataKeys = beforeRangeValues.flatMap(v =>
    Object.entries(v.data.workloadMap).map(([k, v]) => k)
  )
  const defaultWbsItemValues = reportDateToValueMap(
    values,
    aggregateField,
    valueDenominator,
    beforeRangeDataKeys
  )

  const result: SummarizedValue[] = []
  timeGrainValues.forEach((value, index) => {
    const keys = value.flatMap(v =>
      Object.entries(v.data.workloadMap).map(([k, v]) => k)
    )
    const prevYValue =
      0 < result.length ? result[index - 1].yValue : defaultYValue
    const prevWbsItemValues =
      0 < result.length
        ? result[index - 1].wbsItemValueMap
        : defaultWbsItemValues
    result.push({
      xValue: xValues[index],
      yValue:
        prevYValue -
        reportDataToYValue(value, aggregateField, valueDenominator),
      wbsItemValueMap: _.omit(prevWbsItemValues, keys),
    })
  })
  return result
}

export type SummarizedValue = {
  xValue: number
  yValue: number
  wbsItemValueMap: { [code: string]: number }
}

const summarizeReportDataValue = (
  xValues: number[],
  values: ProgressReportDataValue[],
  summarizeType: SummarizeType,
  aggregateField: AggregateField,
  aggregateUnit: AggregateTargetUnit,
  timeGrain: TimeGrain,
  hoursPerSelectedUnit: number
): SummarizedValue[] => {
  const timeGrainValues = xValues.map(xValue => {
    return filterReportDataValueByDateTerm(values, xValue, xValue, timeGrain)
  })
  const valueDenominator = aggregateUnit ? hoursPerSelectedUnit : 1
  if (summarizeType === SummarizeType.UNIT) {
    return summarizeReportDataValueForTypeUnit(
      xValues,
      timeGrainValues,
      aggregateField,
      valueDenominator
    )
  } else if (summarizeType === SummarizeType.BURN_UP) {
    return summarizeReportDataValueForTypeBurnUp(
      xValues,
      values,
      timeGrainValues,
      aggregateField,
      valueDenominator
    )
  } else if (summarizeType === SummarizeType.BURN_DOWN) {
    return summarizeReportDataValueForTypeBurnDown(
      xValues,
      values,
      timeGrainValues,
      aggregateField,
      valueDenominator
    )
  }
  return []
}

type GroupItem = {
  config: ProgressReportGraphConfig
  scheduledValues: SummarizedValue[]
  actualValues: SummarizedValue[]
}

const useGraph = (
  data: ProgressReportDataSeries[],
  viewConfig: ProgressReportViewConfig,
  graphConfig: ProgressReportGraphConfig[],
  formatter: ValueFormatter,
  xValues: number[],
  now: number
): [TwoDimGraphPlotter[]] => {
  const [graph, setGraph] = useState<TwoDimGraphPlotter[]>([])
  const [defaultShowSummaryTooltip, setDefaultShowSummaryTooltip] =
    useState<boolean>(true)

  const workloadUnitState = useWorkloadUnit(
    WorkloadUnit[viewConfig.aggregateTargetUnit]
  )
  useEffect(() => {
    const { timeGrain, aggregateTargetType, aggregateTargetUnit } = viewConfig
    const newGraph: TwoDimGraphPlotter[] = []
    const getValues = (
      dataSeries: ProgressReportDataSeries,
      direction?: AccumulateDirection
    ) => {
      const summarizeType = !direction
        ? SummarizeType.UNIT
        : SummarizeType[direction]
      return summarizeReportDataValue(
        xValues,
        dataSeries.value,
        summarizeType,
        aggregateTargetType,
        aggregateTargetUnit,
        timeGrain,
        workloadUnitState.hoursPerSelectedUnit
      )
    }

    const showGraphConfig = graphConfig.filter(config => config.show)

    const lineGraphValues: {
      config: ProgressReportGraphConfig
      column: AggregateColumn
      values: SummarizedValue[]
    }[] = []
    let hasScheduledGroupItems: GroupItem[] = []
    let actualOnlyGroupItems: GroupItem[] = []
    const getGroupItems = (group: AggregateColumnGroup) => {
      return isActualOnlyGroup(group)
        ? actualOnlyGroupItems
        : hasScheduledGroupItems
    }

    // summarize data
    showGraphConfig.forEach(config => {
      const type = config.key.type
      const group = config.key.aggregateColumnGroup
      const aggregateColumns = mapGroupToColumn(group)
      let groupItem = {
        config,
        scheduledValues: [] as SummarizedValue[],
        actualValues: [] as SummarizedValue[],
      }
      aggregateColumns.forEach(column => {
        const dataSeries = (data || []).find(
          d => d.type.value === type.value && d.aggregateColumn === column
        )
        if (!dataSeries) return
        const values = getValues(dataSeries, config.direction)
        lineGraphValues.push({ config, column, values })
        if (isScheduledColumn(column)) {
          groupItem.scheduledValues.push(...values)
        } else {
          groupItem.actualValues.push(...values)
        }
      })
      getGroupItems(group).push(groupItem)
    })

    // Line Graph
    lineGraphValues.forEach(lineGraphValue => {
      const { config, column, values } = lineGraphValue
      const nodeTooltip = (targetDate: number) => {
        const groupItems = getGroupItems(config.key.aggregateColumnGroup)
        const summaryItems = groupItems?.map(item => {
          const tooltipSummarizeValues = getSummarizeValueByTargetDate(
            targetDate,
            item.scheduledValues,
            item.actualValues
          )
          return {
            color: item.config.color,
            type: item.config.key.type,
            aggregateColumnGroup: item.config.key.aggregateColumnGroup,
            ...tooltipSummarizeValues,
            direction: item.config.direction,
          }
        })
        return (
          <SummaryTooltip
            // @ts-ignore
            summaries={summaryItems || []}
            formatter={formatter}
            timeGrain={timeGrain}
            aggregateTargetType={aggregateTargetType}
            dimTime={targetDate}
          />
        )
      }
      newGraph.push(
        new LineGraphPlotter({
          data: values.map(val => {
            return {
              xValue: val.xValue,
              yValue: val.yValue,
            }
          }),
          style: {
            color: config.color,
            lineStyle: getLineStyleForAggregateColumn(column),
          },
          eventHandler: {
            nodeTooltip: nodeTooltip,
          },
          formatter,
        })
      )
    })

    // Bar graph
    graphConfig
      .filter(config => config.show && config.showBar)
      .forEach((config, index) => {
        const type = config.key.type
        const aggregateColumnGroup = config.key.aggregateColumnGroup
        const aggregateColumns = mapGroupToColumn(aggregateColumnGroup)
        aggregateColumns.forEach((aggregateColumn, idx) => {
          const dataSeries = (data || []).find(
            d =>
              d.type.value === type.value &&
              d.aggregateColumn === aggregateColumn
          )
          if (!dataSeries) return
          const values = getValues(dataSeries)
          const tooltip = (x: number) => {
            const currIndex = values.findIndex(v => v.xValue === x)
            const hasPrev = currIndex > 0
            const currValue = values[currIndex].yValue
            const prevValue = hasPrev ? values[currIndex - 1].yValue : undefined
            const currWbsItemCodes = values[currIndex].wbsItemValueMap
              ? Object.keys(values[currIndex].wbsItemValueMap)
              : []

            return (
              <CurrAndPrevTooltip
                dimTime={x}
                currValue={currValue}
                prevValue={prevValue}
                currWbsItemCodes={currWbsItemCodes}
                formatter={formatter}
                timeGrain={viewConfig.timeGrain}
                aggregateColumn={aggregateColumn}
                type={type}
                compareToPrevValueLinkDisabled={true}
              />
            )
          }
          const color = config.color
          if (config.showBar) {
            newGraph.push(
              new BarGraphPlotter({
                data: values,
                style: { color, width: 6, marginLeft: 6 * (index + idx) },
                eventHandler: {
                  tooltip,
                },
              })
            )
          }
        })
      })

    // Vertical border
    if (
      newGraph.length > 0 &&
      Math.min(...xValues) <= now &&
      now <= Math.max(...xValues)
    ) {
      const groupItems =
        0 < hasScheduledGroupItems.length
          ? hasScheduledGroupItems
          : actualOnlyGroupItems
      const summaryItems = groupItems?.map(item => {
        const tooltipSummarizeValues = getSummarizeValueByTargetDate(
          now,
          item.scheduledValues,
          item.actualValues
        )
        return {
          color: item.config.color,
          type: item.config.key.type,
          aggregateColumnGroup: item.config.key.aggregateColumnGroup,
          ...tooltipSummarizeValues,
          direction: item.config.direction,
        }
      })

      newGraph.unshift(
        new VerticalBorderPlotter({
          xValue: now,
          label: moment(now).format('YYYY/MM/DD'),
          style: {
            color: '#E65757',
          },
          tooltipButtonContents: (
            <InfoOutlinedIcon
              sx={{ width: 14, height: 14, color: Color.MAIN }}
            />
          ),
          tooltip: (
            <SummaryTooltip
              // @ts-ignore
              summaries={summaryItems}
              formatter={formatter}
              timeGrain={timeGrain}
              aggregateTargetType={aggregateTargetType}
              dimTime={now}
            />
          ),
          defaultShowTooltip: defaultShowSummaryTooltip,
        })
      )
      setDefaultShowSummaryTooltip(false)
    }
    setGraph(newGraph)
  }, [
    data,
    viewConfig.timeGrain,
    viewConfig.aggregateTargetType,
    viewConfig.aggregateTargetUnit,
    viewConfig.startDayOfWeek,
    viewConfig.viewTime?.to,
    viewConfig.viewTime?.from,
    graphConfig,
    workloadUnitState.hoursPerSelectedUnit,
  ])
  return [graph]
}

const updateChartUIState = (
  isRootLayer: boolean,
  projectUuid: string | undefined,
  currentLayerDepth: number,
  currentLayer: FunctionLayer | undefined,
  newUIState: ProgressReportSavedUiState
) => {
  if (isRootLayer) {
    saveChartUIState(projectUuid, {
      ...newUIState,
    })
  } else {
    replaceLayerOpenInNewUIState(
      projectUuid,
      currentLayerDepth,
      currentLayer,
      newUIState
    )
  }
}

const replaceLayerOpenInNewUIState = (
  projectUuid: string | undefined,
  currentLayerDepth: number,
  currentLayer: FunctionLayer | undefined,
  newUIState: ProgressReportSavedUiState
) => {
  if (!currentLayer) {
    return
  }
  const newLayer = {
    ...currentLayer,
    onPreOpenInNew: async () => {
      await saveChartUIState(projectUuid, {
        ...newUIState,
      })
    },
  }
  store.dispatch(replaceOrPushFunctionLayer(newLayer, currentLayerDepth))
}

const TitleBox = styled('div')({
  paddingLeft: '8px',
})

const useHeaderComponent = (
  condition: ProgressReportSearchConditionVO,
  projectUuid: string | undefined
) => {
  useEffect(() => {
    if (!projectUuid) {
      return
    }
    const dispatchAction = condition.ticketListUuid
      ? putHeaderComponent(
          <TitleBox>
            <ProgressReportTicketListTitle
              projectUuid={projectUuid}
              ticketListUuid={condition.ticketListUuid}
            />
          </TitleBox>
        )
      : putHeaderComponent(
          <TitleBox>
            <ProgressReportWbsItemTitle
              rootWbsItemUuid={condition.rootWbsItemUuid || ''}
            />
          </TitleBox>,
          [
            {
              key: 'progressReportBreadcrumbs',
              layout: {
                position: 'right',
                index: 0,
              },
              component: (
                <ProgressReportBreadcrumbs
                  rootUuid={condition.rootWbsItemUuid}
                  transitionScreenId={
                    APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_CHART
                  }
                />
              ),
            },
          ]
        )
    store.dispatch(dispatchAction)
  }, [condition, projectUuid])
}

const useTypes = (
  data: ProgressReportDataSeries[],
  chartConfig: ProgressReportChartConfig
): [WbsItemTypeForWbsProgressLog[]] => {
  const [types, setTypes] = useState<WbsItemTypeForWbsProgressLog[]>([])
  useEffect(() => {
    const typeValuesInConfig = new Set(
      chartConfig.graphConfig.map(config => config.key.type.value)
    )
    const typesInData = data.map(d => d.type)
    const typeValues = new Set(typesInData.map(type => type.value))
    const newTypes = Array.from(typeValues)
      .map(v => typesInData.find(type => type.value === v))
      .filter(v => !!v && !typeValuesInConfig.has(v.value))
    // @ts-ignore
    setTypes(newTypes)
  }, [data, chartConfig])
  return [types]
}

const useColorPalettes = (): {
  colorPalettes: ColorPalette[]
  removeColorPalette: (palette: ColorPalette) => void
  changeColorPalette: (curr: ColorPalette, next: ColorPalette) => void
  popColorPalette: () => ColorPalette
} => {
  const [colorPalettes] = useState<ColorPalette[]>(ProgressReportColorPalettes)
  const [usedIndex, setUsedIndex] = useState<number[]>([0, 1])
  const findIndex = useCallback(
    (palette: ColorPalette): number => {
      return colorPalettes.findIndex(p =>
        palette
          .map(color => p.includes(color))
          .reduce((prev, curr) => prev && curr)
      )
    },
    [colorPalettes]
  )
  const addColorPalette = useCallback(
    (palette: ColorPalette) => {
      const index = findIndex(palette)
      if (!usedIndex.includes(index)) {
        setUsedIndex([...usedIndex, index])
      }
    },
    [findIndex, usedIndex]
  )
  const removeColorPalette = useCallback(
    (palette: ColorPalette) => {
      const index = findIndex(palette)
      setUsedIndex(usedIndex.filter(idx => idx !== index))
    },
    [findIndex, usedIndex]
  )
  const popColorPalette = useCallback(() => {
    const palette = colorPalettes.find((_, index) => !usedIndex.includes(index))
    if (!palette) {
      return colorPalettes[0]
    }
    addColorPalette(palette)
    return palette
  }, [usedIndex, addColorPalette])
  const changeColorPalette = useCallback(
    (prev: ColorPalette, next: ColorPalette) => {
      removeColorPalette(prev)
      addColorPalette(next)
    },
    [addColorPalette, removeColorPalette]
  )
  return {
    colorPalettes,
    removeColorPalette,
    changeColorPalette,
    popColorPalette,
  }
}

const useXValues = (
  viewConfig: ProgressReportViewConfig
): [number[], number] => {
  const { timeGrain, startDayOfWeek, viewTime } = viewConfig
  const xValues: number[] = getXValues(timeGrain, startDayOfWeek, viewTime!)
  const getNowXValue = useCallback(() => {
    let now: number = moment(
      toMinDimTime(moment().valueOf(), timeGrain, startDayOfWeek)
    )
      .startOf('d')
      .valueOf()
    if (timeGrain === TimeGrain.TWO_WEEKS) {
      now = moment(now)
        .subtract(moment(now).diff(moment(xValues[0]), 'w') % 2, 'w')
        .valueOf()
    }
    return now
  }, [timeGrain, startDayOfWeek])

  return [xValues, getNowXValue()]
}

const ProgressReport = connect(mapStateToProps)(
  projectPrivate((props: Props) => {
    const {
      code,
      projectUuid: selectedProjectUuid,
      setChartState,
      sideMenuOpen,
      params,
      layers,
      currentLayerDepth,
      ticketTypes,
    } = props
    const isRootLayer = layers?.size === 1
    const currentLayer = layers.has(currentLayerDepth)
      ? layers.get(currentLayerDepth)
      : undefined

    // initialize ProjectUuid.
    const [projectUuid] = useProjectUuid(code, selectedProjectUuid)
    const [savedUiState, setSavedUiState, initialized] = useSavedUiState(
      projectUuid,
      isRootLayer,
      params
    )

    // About search condition and view configuration
    const { removeColorPalette, colorPalettes, ...otherColorPalette } =
      useColorPalettes()
    const [condition, setCondition] = useSearchCondition(savedUiState)
    const [data, setData] = useState<ProgressReportDataSeries[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const fetch = useCallback(
      async (
        condition: ProgressReportSearchConditionVO,
        projectUuid: string | undefined,
        ticketTypes: WbsItemTypeVO[]
      ) => {
        if (!condition || !projectUuid) {
          return
        }
        setIsLoading(true)
        const fetched = await fetchData(condition, projectUuid, ticketTypes)
        setData(fetched)
        setIsLoading(false)
      },
      []
    )
    useEffect(() => {
      if (
        initialized &&
        ticketTypes &&
        (!!condition.rootWbsItemUuid || !!condition.ticketListUuid)
      ) {
        fetch(condition, projectUuid, ticketTypes)
      }
    }, [projectUuid, initialized, condition, ticketTypes])

    const [viewConfig, setViewConfig] = useViewConfig(data, savedUiState)
    const [xValues, nowXValue] = useXValues(viewConfig)
    const [chartConfig, updateGraphConfig, addGraphConfig, removeGraphConfig] =
      useChartConfig(
        savedUiState,
        isRootLayer,
        currentLayerDepth,
        currentLayer,
        projectUuid,
        condition,
        viewConfig,
        colorPalettes,
        data
      )
    const [types] = useTypes(data, chartConfig)

    useEffect(() => {
      if (setChartState) {
        setChartState({
          condition,
          viewConfig,
          chartConfig,
          isRootLayer,
          setSavedUiState,
          setViewConfig,
          setCondition,
          currentLayerDepth,
          currentLayer,
          fetch,
        })
      }
    }, [
      condition,
      viewConfig,
      chartConfig,
      isRootLayer,
      setSavedUiState,
      setViewConfig,
      currentLayerDepth,
      currentLayer,
    ])

    useHeaderComponent(condition, projectUuid)

    const [scale] = useScale(
      viewConfig,
      chartConfig.graphConfig,
      data,
      sideMenuOpen,
      xValues
    )
    const [xFormatter, yFormatter] = useFormatter(
      viewConfig.timeGrain,
      viewConfig.aggregateTargetType,
      viewConfig.aggregateTargetUnit
    )
    const [graph] = useGraph(
      data,
      viewConfig,
      chartConfig.graphConfig,
      yFormatter,
      xValues,
      nowXValue
    )
    const onRemoveGraphConfig = useCallback(
      (graphConfigs: ProgressReportGraphConfig[]) => {
        removeGraphConfig(graphConfigs)
        const removedColorPalette = graphConfigs.map(g => g.color)
        removeColorPalette(removedColorPalette)
      },
      [removeGraphConfig, removeColorPalette]
    )
    // consider
    if (!scale) {
      return <></>
    }

    return (
      <RootDiv id={'progress-report-root'}>
        <TwoDimChart
          graph={graph}
          scale={scale}
          xFormatter={xFormatter}
          yFormatter={yFormatter}
          isLoading={isLoading}
          baseXValue={nowXValue}
        />
        <Box
          sx={{
            width: `${DRAWER_WIDTH}px`,
            borderLeft: `1px solid ${BorderColor.GREY}`,
          }}
        >
          <Legend
            config={chartConfig.graphConfig}
            onChange={updateGraphConfig}
            onAdd={addGraphConfig}
            onRemove={onRemoveGraphConfig}
            types={types}
            colorPalettes={colorPalettes}
            aggregateType={viewConfig.aggregateTargetType}
            {...otherColorPalette}
          />
        </Box>
      </RootDiv>
    )
  })
)
