import React, { useCallback, useMemo, useState } from 'react'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import { AllState } from '../../../store'
import { PageArea, PageProps } from '..'
import { connect } from 'react-redux'
import ProjectMemberOptions, {
  ColumnQuickFilterKey,
  ProjectMemberRow,
  ProjectMemberState,
} from './ProjectMemberOptions'
import { ProjectMemberDetail } from '../../../lib/functions/projectMember'
import ProjectMemberHeader from './Header'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ColDef } from 'ag-grid-community'
import { Collapse } from '@mui/material'
import ProjectMemberToolbar from './Toolbar'
import { intl } from '../../../i18n'
import { usePageState } from '../../hooks/usePageState'
import { Function } from '../../../lib/commons/appFunction'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { pageComponent } from '../../higher-order-components/pageComponent'

type ProjectMemberPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const projectMemberDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

type StateProps = {
  functions: Function[]
}

const ProjectMember = (props: PageProps & StateProps) => {
  const projectMemberOptions = useMemo(() => new ProjectMemberOptions(), [])

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        ProjectMemberDetail,
        ProjectMemberRow,
        ProjectMemberState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { toolbar, updatePageState } = usePageState<ProjectMemberPageState>(
    props.uuid,
    projectMemberDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const onClickAddUser = useCallback(() => {
    bulkSheet?.setState({
      addRowCountInputDialogState: {
        open: true,
        title: intl.formatMessage({
          id: 'project.member.insert.multipleRow.title',
        }),
        submitHandler: addRowCount => {
          if (!addRowCount) {
            throw new Error('addRowCount is 0 or undefined.')
          }
          Array.from({ length: addRowCount }).forEach(value =>
            bulkSheet.addRow()
          )
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
        closeHandler: () => {
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
      },
    })
  }, [bulkSheet])

  return (
    <PageArea>
      <ProjectMemberHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onCancel={() => bulkSheet?.onCancel()}
        onClickAddUser={onClickAddUser}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <ProjectMemberToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={projectMemberOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

export default pageComponent(projectPrivate(ProjectMember))
