import { useCallback, useState } from 'react'

export const useSelectFilter = (): {
  init: (options: string[]) => void
  values: string[]
  setValues: (_: string[]) => void
  setOptions: (_: string[]) => void
  includeBlank: boolean
  setIncludeBlank: (_: boolean) => void
  isAllSelected: () => boolean
  isAllDeselected: () => boolean
  selectAll: () => void
  deselectAll: () => void
  isActive: () => boolean
  model: () => { values: string[]; includeBlank: boolean } | undefined
  reset: () => void
} => {
  const [options, setOptions] = useState<string[]>([])
  const [values, setValues] = useState<string[]>([])
  const [includeBlank, setIncludeBlank] = useState<boolean>(true)

  const isAllSelected = useCallback(() => {
    return values.length === options.length && includeBlank
  }, [values, options, includeBlank])

  const isAllDeselected = useCallback(() => {
    return values.length === 0 && !includeBlank
  }, [values, includeBlank])

  const selectAll = useCallback(() => {
    setValues(options)
    setIncludeBlank(true)
  }, [options])

  const deselectAll = useCallback(() => {
    setValues([])
    setIncludeBlank(false)
  }, [])

  const reset = useCallback(() => {
    setValues(options)
    setIncludeBlank(true)
  }, [options])

  const isActive = useCallback(
    () => !isAllSelected(),
    [values, options, includeBlank]
  )

  const model = useCallback(() => {
    if (isActive()) {
      return { values, includeBlank }
    }
    return undefined
  }, [isActive, values, options, includeBlank])

  const init = (o: string[]) => {
    setOptions(o)
    setValues(o)
  }

  return {
    init,
    values,
    setValues,
    setOptions,
    includeBlank,
    setIncludeBlank,
    isAllSelected,
    isAllDeselected,
    selectAll,
    deselectAll,
    isActive,
    model,
    reset,
  }
}
