import ExcelJS from 'exceljs'

export const getExcelBuffer = async <T>({
  sheetName,
  columns,
  source,
}: {
  sheetName: string
  columns: Partial<ExcelJS.Column>[]
  source: T[]
}) => {
  const workBook = new ExcelJS.Workbook()
  workBook.addWorksheet(sheetName)
  const workSheet: ExcelJS.Worksheet | undefined =
    workBook.getWorksheet(sheetName)
  if (!workSheet) return

  workSheet.columns = columns
  workSheet.columns.forEach((column: Partial<ExcelJS.Column>) => {
    if (column.font) return
    column.font = {
      name: 'Yu Gothic',
      size: 11,
    }
  })

  source.forEach((src: T) => {
    workSheet.addRow(src)
  })
  const buf: ExcelJS.Buffer = await workBook.xlsx.writeBuffer()
  return buf
}
