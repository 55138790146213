import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { WbsTagRepository } from '../../applications/ports/wbsTagRepository'

export const useWbsTagRepository = (): WbsTagRepository => {
  const fetchListAsReferencedEntity = useCallback(
    async (projectUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/tags',
        { projectUuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return (
        response.json.map(e => ({
          uuid: e.uuid,
          name: e.name,
          backgroundColor: e.backgroundColor,
        })) || []
      )
    },
    []
  )
  return { fetchListAsReferencedEntity }
}
