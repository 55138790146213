import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Attachment } from '../../../../utils/attachment'
import AttachmentList from '../AttachmentList'

// Styles
const RootDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const StyledDialog = styled(Dialog)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const StyledDialogContent = styled(DialogContent)({
  padding: 10,
  backgroundColor: 'lightgrey',
})

export interface AttachmentListDialogProps {
  open: boolean
  onClose: () => void
  attachments: Attachment[]
}

interface Props extends WrappedComponentProps, AttachmentListDialogProps {}

const AttachmentListDialog = ({ open, onClose, attachments }: Props) => {
  return (
    <RootDiv>
      <StyledDialog
        open={open}
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <StyledDialogContent>
          <AttachmentList attachments={attachments} />
        </StyledDialogContent>
      </StyledDialog>
    </RootDiv>
  )
}

export default injectIntl(AttachmentListDialog)
