import DomainEntityBase from '../entity/Base'

export interface DomainEntityCreateParams {}
export interface DomainEntityReconstractParams {}

export default abstract class DomainEntityFactoryBase<
  DomainEntity extends DomainEntityBase
> {
  abstract create(params: DomainEntityCreateParams): DomainEntity // Create new instance.
  abstract reconstract(params: DomainEntityReconstractParams): DomainEntity // Reconstract instance from saved data.
  abstract reconstractList(
    params: DomainEntityReconstractParams[]
  ): DomainEntity[]
}
