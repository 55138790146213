import React, { useCallback } from 'react'
import { toggleMarkdownStyle, StyleType } from '../../../../../utils/markdown'
import StyleButtonBase, {
  StyleButtonBaseProps,
} from '../../common/StyleButtonBase'

interface OwnProps {
  markdownText?: string
  setMarkdownText?: (text: string) => void
  selectionStart?: number
  selectionEnd?: number
  styleType: StyleType
  onClick?: () => void
}

type Props = OwnProps & StyleButtonBaseProps

const MarkdownStyleButton = (props: Props) => {
  const {
    disabled,
    markdownText,
    setMarkdownText,
    selectionStart,
    selectionEnd,
    styleType,
    onClick,
  } = props
  const onMouseDown = useCallback(
    event => {
      event.preventDefault()
      if (disabled) {
        return
      } else if (
        !setMarkdownText ||
        selectionStart == null ||
        selectionEnd == null
      ) {
        return
      }
      if (onClick) {
        onClick()
        return
      }
      const text = markdownText || ''
      setMarkdownText(
        toggleMarkdownStyle(text, selectionStart, selectionEnd, styleType)
      )
    },
    [disabled, markdownText, selectionStart, selectionEnd]
  )
  return <StyleButtonBase {...props} onMouseDown={onMouseDown} />
}

export default MarkdownStyleButton
