import { useCallback, useState } from 'react'
import {
  ProfitLossSummaryRow,
  ProfitLossSummaryRowBody,
  ProfitLossSummarySkeleton,
  fetchProfitLossSummary,
} from '../ProfitLossSummary'
import { AgGridTreeHelper } from '../../../containers/BulkSheetView/lib/tree'
import {
  LedgerAccountsTree,
  fetchLedgerAccounts,
} from '../../LedgerAccounts/ledgerAccounts'

export const useProfitLossSummaryData = () => {
  const [data, setDataInternal] = useState<ProfitLossSummaryRow[]>([])
  const [masterTree, setMasterTree] = useState<LedgerAccountsTree[]>([])

  const fetchRecords = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string,
      predictionType: string | undefined
    ) => {
      const response = await fetchProfitLossSummary({
        projectUuid,
        startDate,
        endDate,
        predictionType,
      })
      const src: ProfitLossSummarySkeleton = response.json
      if (!src?.children) return
      const rows = src.children
        .map(row =>
          AgGridTreeHelper.convert(
            row,
            (s: ProfitLossSummarySkeleton): ProfitLossSummaryRow => {
              return {
                uuid: s.uuid,
                body: new ProfitLossSummaryRowBody(s),
              } as ProfitLossSummaryRow
            }
          )
        )
        .flat()
      setDataInternal(rows)
    },
    []
  )

  const fetchMasterRecords = useCallback(async () => {
    const response = await fetchLedgerAccounts({})
    const source: LedgerAccountsTree[] = response.json
    setMasterTree(source)
  }, [])

  return {
    data,
    fetchRecords,
    masterTree,
    fetchMasterRecords,
  }
}
