import { useMemo } from 'react'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { intl } from '../../../../i18n'
import { SelectOption } from '../../../model/selectOption'

export const useWbsItemTypeSelectOptions = (): SelectOption[] => {
  const { ticketTypes } = useProjectPrivateContext()
  const options = useMemo(
    () => [
      {
        value: 'DELIVERABLE',
        name: intl.formatMessage({ id: 'wbsItemType.deliverable' }),
      },
      {
        value: 'TASK',
        name: intl.formatMessage({ id: 'wbsItemType.task' }),
      },
      ...ticketTypes.map(t => ({
        value: t.uuid,
        name: t.name,
      })),
    ],
    [ticketTypes]
  )
  return options
}
