import { styled } from '@mui/system'
import { Divider as MuiDivider } from '@mui/material'
import { Editor } from '@tiptap/react'
import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  FileButton,
  HeaderFiveButton,
  HeaderFourButton,
  HeaderOneButton,
  HeaderSixButton,
  HeaderThreeButton,
  HeaderTwoButton,
  HorizontalRuleButton,
  ItalicButton,
  OrderedListButton,
  StrikeButton,
  TableButton,
  TaskListButton,
  UnorderedListButton,
} from './StyleButton'

const RootContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const Divider = styled(MuiDivider)({
  margin: 3,
})
const StyledBlockquoteButton = styled(BlockquoteButton)({ margin: 5 })
const StyledBoldButton = styled(BoldButton)({ margin: 5 })
const StyledCodeBlockButton = styled(CodeBlockButton)({ margin: 5 })
const StyledCodeButton = styled(CodeButton)({ margin: 5 })
const StyledFileButton = styled(FileButton)({ margin: 5 })
const StyledHeaderFiveButton = styled(HeaderFiveButton)({ margin: 5 })
const StyledHeaderFourButton = styled(HeaderFourButton)({ margin: 5 })
const StyledHeaderOneButton = styled(HeaderOneButton)({ margin: 5 })
const StyledHeaderSixButton = styled(HeaderSixButton)({ margin: 5 })
const StyledHeaderThreeButton = styled(HeaderThreeButton)({ margin: 5 })
const StyledHeaderTwoButton = styled(HeaderTwoButton)({ margin: 5 })
const StyledHorizontalRuleButton = styled(HorizontalRuleButton)({ margin: 5 })
const StyledItalicButton = styled(ItalicButton)({ margin: 5 })
const StyledOrderedListButton = styled(OrderedListButton)({ margin: 5 })
const StyledStrikeButton = styled(StrikeButton)({ margin: 5 })
const StyledTableButton = styled(TableButton)({ margin: 5 })
const StyledTaskListButton = styled(TaskListButton)({ margin: 5 })
const StyledUnorderedListButton = styled(UnorderedListButton)({ margin: 5 })

const StyleButtonComponents = (props: {
  editor: Editor | undefined
  disabled?: boolean
  hideHeaderOne?: boolean
  hideHeaderTwo?: boolean
  hideHeaderThree?: boolean
  hideHeaderFour?: boolean
  hideHeaderFive?: boolean
  hideHeaderSix?: boolean
  hideExtras?: boolean
  highlightItems?: string[]
}) => {
  const {
    editor,
    disabled,
    hideHeaderOne,
    hideHeaderTwo,
    hideHeaderThree,
    hideHeaderFour,
    hideHeaderFive,
    hideHeaderSix,
    hideExtras,
    highlightItems,
  } = props
  return (
    <RootContainer>
      {!hideHeaderOne && (
        <StyledHeaderOneButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      {!hideHeaderTwo && (
        <StyledHeaderTwoButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      {!hideHeaderThree && (
        <StyledHeaderThreeButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      {!hideHeaderFour && (
        <StyledHeaderFourButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      {!hideHeaderFive && (
        <StyledHeaderFiveButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      {!hideHeaderSix && (
        <StyledHeaderSixButton
          editor={editor}
          disabled={disabled}
          highlightItems={highlightItems}
        />
      )}
      <Divider orientation="vertical" variant="middle" flexItem={true} />
      <StyledBoldButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <StyledItalicButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <StyledStrikeButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <Divider orientation="vertical" variant="middle" flexItem={true} />
      <StyledCodeButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <StyledCodeBlockButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <Divider orientation="vertical" variant="middle" flexItem={true} />
      <StyledBlockquoteButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <StyledUnorderedListButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      <StyledOrderedListButton
        editor={editor}
        disabled={disabled}
        highlightItems={highlightItems}
      />
      {!hideExtras && (
        <>
          <Divider orientation="vertical" variant="middle" flexItem={true} />
          <StyledHorizontalRuleButton
            editor={editor}
            disabled={disabled}
            highlightItems={highlightItems}
          />
          <StyledTaskListButton
            editor={editor}
            disabled={disabled}
            highlightItems={highlightItems}
          />
          <StyledTableButton
            editor={editor}
            disabled={disabled}
            highlightItems={highlightItems}
          />
          <StyledFileButton
            editor={editor}
            disabled={disabled}
            highlightItems={highlightItems}
          />
          <Divider orientation="vertical" variant="middle" flexItem={true} />
        </>
      )}
    </RootContainer>
  )
}

const RichTextStyleButtons = StyleButtonComponents

export { RichTextStyleButtons }
