import {
  fromApiResponseToEntity as fromApiResponseToWbsItemEntity,
  UpdateTagInput,
  UpdateWbsItemDeltaInput,
} from './../WbsItemEntity/index'
import { WbsItemEntity } from '../WbsItemEntity'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import { ReferencedTicketListEntity } from '../TicketListEntity'

export type TicketEntity = {
  uuid: string
  ticketType: PredeterminedTicketType | string
  ticketList: ReferencedTicketListEntity
  wbsItem: WbsItemEntity
}

export type PredeterminedTicketType =
  | 'REFINEMENT'
  | 'ISSUE'
  | 'RISK'
  | 'CHANGE_REQUEST'
  | 'MEETING'

export type UpdateTicketDeltaInput = {
  uuid: string
  ticketListUuid?: IItemDelta<string>
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: UpdateWbsItemDeltaInput
}

export type UpdateTicketDeltaResult = {}

export type UpdateTicketDeltaRequest = {
  input: UpdateTicketDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}

const fromApiResponseToEntity = (src: any): TicketEntity => {
  return {
    uuid: src.uuid,
    ticketType: src.ticketType,
    ticketList: {
      uuid: src.ticketList.uuid,
      name: src.ticketList.displayName,
      ticketType: src.ticketList.ticketType,
    },
    wbsItem: fromApiResponseToWbsItemEntity(src.wbsItem),
  }
}

export const ticketService = {
  fromApiResponseToEntity,
}
