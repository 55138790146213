import React, { ReactElement, PropsWithChildren } from 'react'
import { Tooltip as MuiTooltip } from '@mui/material'

interface Props extends PropsWithChildren {
  message?: string
  delay?: number
}

const Tooltip: React.FC<Props> = ({ message, delay, children }) => {
  if (!message) return children as ReactElement
  return (
    <MuiTooltip title={message} enterDelay={delay} enterNextDelay={delay}>
      {children as ReactElement}
    </MuiTooltip>
  )
}

export default Tooltip
