import _ from 'lodash'
import TextSearchFilter from './components/header/TextSearchFilter'
import IconCellRenderer from './components/cell/common/iconCell'
import DateCell from './components/cell/common/date'
import WbsItemDateCell from './components/cell/custom/wbsItemDate'
import {
  BaseExportParams,
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  Column,
  ColumnMenuTab,
  GridApi,
  ICellRendererParams,
  PasteEndEvent,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import TextCell from './components/cell/common/richText/TextCell'
import CommonTextCell from './components/cell/common/text'
import RadioGroupCellEditor from './components/cell/common/radioGroup/cellEditor'
import RadioGroupCellRenderer from './components/cell/common/radioGroup/cellRenderer'
import AutocompleteCellEditor from './components/cell/common/autocomplete/cellEditor'
import AutocompleteCellRenderer from './components/cell/common/autocomplete/cellRenderer'
import moment from 'moment'
import SelectCellEditor, {
  selectCellFilterValueFormatter,
  selectCellRenderer,
  selectCellValueFormatter,
  selectCellValueGetter,
} from './components/cell/common/select/cellEditor'
import SelectCellValueSetter from './components/cell/common/select/cellValueSetter'
import CheckBoxCell from './components/cell/common/checkBox'
import WbsItemTreeCellRenderer from './components/cell/custom/wbsItemName/treeCellRenderer'
import WbsItemBasicCellRenderer from './components/cell/custom/wbsItemName/basicCellRenderer'
import repositories, { EntitySearchValue } from '../../meta/repositories'
import WorkloadCell from './components/cell/custom/workload'
import TaskActualResultCell from './components/cell/custom/taskActualResult'
import WbsItemStatusCell, {
  WbsItemStatus,
} from './components/cell/custom/wbsItemStatus'
import WbsItemStatusServerSideCell from './components/cell/custom/wbsItemStatusServerSide'
import NumberCell from './components/cell/common/number'
import MultiAutocompleteCell from './components/cell/common/multiAutocomplete'
import IconEditCell from './components/cell/custom/iconEdit'
import objects from '../../../../utils/objects'
import formatter from './lib/formatter'
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  DISPLAY_TIME_SECOND_FORMAT,
  formatDateBy,
  formatDateTime,
} from '../../../../utils/date'
import store from '../../../../store'
import { Comment } from '../../../../store/comments'
import { requireSave } from '../../../../store/requiredSaveData'
import I18nLabelCellRenderer from './components/cell/common/i18nLabel'
import {
  getCustomEnumName,
  getCustomEnumValue,
} from '../../../../lib/functions/customEnumValue'
import OpenDetailCellRenderer from './components/cell/custom/detail/cellRenderer'
import { DetailCellFilter } from './components/cell/custom/detail'
import OpenAttachmentCellRenderer from './components/cell/custom/attachment/cellRenderer'
import OpenAttachmentCellFilter from './components/cell/custom/attachment/attachmentCellFilter'
import MultiLineTextCell from './components/cell/custom/multiLineText'
import CommentCell from './components/cell/custom/comment'
import { normalize } from '../../../../utils/multilineText'
import CountWbsStatus from './components/footer/CountWbsStatus'
import MultiSelect from './components/cell/custom/multiSelect'
import { multiSelectCellValueFormatter } from './components/cell/custom/multiSelect/cellEditor'
import {
  CustomEnumValue,
  FunctionProperty,
} from '../../../../lib/commons/appFunction'
import CommentCellFilter from './components/cell/custom/comment/CommentCellFilter'
import Gantt from './components/cell/custom/gantt'
import WbsAggregateValue from './components/cell/custom/wbsAggregateValue'
import ViewMeta from '../../meta/ViewMeta'
import WbsItemSequenceNoCellRenderer from './components/cell/custom/sequenceNo/WbsItemSequenceNoCellRenderer'
import { WbsItemRow } from '../../../../lib/functions/wbsItem'
import WbsItemRowApi from './lib/wbsItem'
import { intl } from '../../../../i18n'
import { SequenceNoCellRenderer } from './components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { format } from '../../../../utils/comment'
import { multiAutocompleteCellFilterValueFormatter } from './components/cell/common/multiAutocomplete/cellEditor'
import WbsItemTypeCell from './components/cell/custom/wbsItemType'
import TicketTypeCell from './components/cell/custom/ticketType'
import { completeDateString } from '../../../commons/inputSupporter'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import {
  ClientSideTextFilter,
  ClientSideNumberFilter,
  ClientSideSelectFilter,
} from '../../BulkSheetView/components/filter'
import { TagCellRenderer } from '../../BulkSheetView/components/cellRenderer/TagCellRenderer'
import { TagCellEditor } from '../../BulkSheetView/components/cellEditor/TagCellEditor'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { colorPalette } from '../../../style/colorPallete'
import { BackgroundColor } from '../../../../styles/commonStyles'

export const gridRootStyle = {
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
}

export const defaultValueSetter = (
  params: ValueSetterParams,
  customField?: string,
  skipFieldValue?: boolean,
  entityExtensionUuid?: string
) => {
  if (params.oldValue === params.newValue) {
    return false
  }
  if (entityExtensionUuid) {
    if (!params.data.extensions) {
      params.data.extensions = []
    }
    const entityExtension = params.data.extensions.find(
      v => v.uuid === entityExtensionUuid
    )
    if (entityExtension && entityExtension.value === params.newValue) {
      return false
    }
    if (!entityExtension) {
      params.data.extensions.push({
        uuid: entityExtensionUuid,
        value: params.newValue,
      })
    } else {
      entityExtension.value = params.newValue
    }
  }
  !skipFieldValue &&
    objects.setValue(
      params.data,
      customField ? customField : params.column.getColDef().field || '',
      params.newValue
    )
  params.data.isEdited = true
  const fieldName = params.colDef.field || params.colDef.colId
  if (!params.data.editedData) {
    params.data.editedData = {}
    params.data.editedData[fieldName!] = params.oldValue
  } else if (!params.data.editedData.hasOwnProperty(fieldName)) {
    params.data.editedData[fieldName!] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

export const defaultOnCellClicked = (event: CellClickedEvent) => {
  selectRows(event.api)
}

export const rangeSelectionChanged = (
  api: GridApi,
  event: { started: boolean; finished: boolean }
) => {
  const focusedCell = api.getFocusedCell()
  if (
    !focusedCell ||
    !focusedCell.column ||
    focusedCell.column.getColDef().field === 'drag'
  ) {
    return
  }
  if (event.started && !event.finished) {
    api.deselectAll()
  }
  if (event.finished) {
    selectRows(api)
  }
}

const selectRows = (api: GridApi) => {
  const ranges = api.getCellRanges() || []
  const rangeIndexes = ranges.map(range => {
    if (!range.startRow || !range.endRow) {
      return []
    }
    return range.startRow.rowIndex <= range.endRow.rowIndex
      ? _.range(range.startRow.rowIndex, range.endRow.rowIndex + 1)
      : _.range(range.endRow.rowIndex, range.startRow.rowIndex + 1)
  })
  const rowIndexes: number[] = rangeIndexes.flat().filter(v => v !== undefined)
  const selectedNodes = api.getSelectedNodes()
  selectedNodes.forEach(
    node =>
      node.rowIndex !== null &&
      !rowIndexes.includes(node.rowIndex) &&
      node.setSelected(false)
  )
  rowIndexes.forEach(i => {
    const node = api.getDisplayedRowAtIndex(i)
    node && node.setSelected(true)
  })
}

export const defaultColDef = (
  option: {
    editable: boolean
    sortable: boolean
  } = {
    editable: false,
    sortable: true,
  }
) => ({
  width: 150,
  editable: option.editable,
  enableValue: false,
  sortable: option.sortable,
  resizable: true,
  filter: 'clientSideTextFilter',
  suppressMenu: true,
  menuTabs: ['filterMenuTab', 'generalMenuTab'] as ColumnMenuTab[],
  suppressSizeToFit: true,
  singleClickEdit: false,
  cellEditor: 'textEditor',
  cellRenderer: CommonTextCell.cellRenderer,
  valueSetter: defaultValueSetter,
  onCellClicked: defaultOnCellClicked,
})

export enum ColumnType {
  drag = 'dragColumn',
  sequenceNo = 'sequenceNoColumn',
  wbsItemSequenceNo = 'wbsItemSequenceNoColumn',
  priority = 'priorityColumn',
  integer = 'integerColumn',
  number = 'numberColumn',
  date = 'dateColumn',
  wbsItemScheduledDate = 'wbsItemScheduledDateColumn',
  wbsItemActualDate = 'wbsItemActualDateColumn',
  dateTime = 'dateTimeColumn',
  hidden = 'hiddenColumn',
  iconColumn = 'iconColumn',
  iconWithNameColumn = 'iconWithNameColumn',
  radioGroup = 'radioGroupColumn',
  autocomplete = 'autocompleteColumn',
  multiAutocomplete = 'multiAutocompleteColumn',
  select = 'selectColumn',
  checkBox = 'checkBoxColumn',
  workload = 'workloadColumn',
  taskActualResult = 'taskActualResultColumn',
  taskActualResultServerSide = 'taskActualResultServerSideColumn',
  wbsItemStatus = 'wbsItemStatusColumn',
  wbsItemStatusServerSide = 'wbsItemStatusServerSideColumn',
  wbsItemType = 'wbsItemTypeColumn',
  i18nLabel = 'i18nLabelColumn',
  detailColumn = 'detailColumn',
  multiLineText = 'multiLineTextColumn',
  comment = 'commentColumn',
  // TODO Remove it
  markup = 'markupCell',
  multiSelect = 'multiSelectColumn',
  gantt = 'ganttColumn',
  wbsItemBasic = 'wbsItemBasicColumn',
  wbsAggregateValue = 'wbsAggregateValueColumn',
  attachmentColumn = 'attachmentColumn',
  ticketType = 'ticketTypeColumn',
  customEnum = 'customEnumColumn',
  colorSelect = 'colorSelect',
  tag = 'tagColumn',
  myWbsItemCustomEnum = 'myWbsItemCustomEnumColumn',
}

export const columnTypes = (): {
  [key: string]: ColDef | ColGroupDef
} => ({
  dragColumn: {
    headerName: '',
    rowDragText: params => '',
    field: 'drag',
    width: 30,
    maxWidth: 30,
    resizable: false,
    pinned: true,
    lockPosition: true,
    filter: false,
    editable: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressNavigable: true,
    onCellClicked: (e: CellClickedEvent) => {
      const sorted =
        0 < e.columnApi.getColumnState().filter(col => !!col.sort).length
      sorted &&
        store.dispatch(
          addScreenMessage(store.getState().appFunction.currentExternalId!, {
            type: MessageLevel.INFO,
            title: intl.formatMessage({
              id: 'bulksheet.warning.suppress.drag.when.sorted',
            }),
          })
        )
    },
  },
  sequenceNoColumn: {
    headerName: '#',
    width: 50,
    maxWidth: 80,
    pinned: true,
    lockPosition: true,
    suppressMovable: true,
    filter: false,
    cellClass: 'ag-numeric-cell',
    cellStyle: { justifyContent: 'center' },
    editable: false,
    cellRenderer: 'sequenceNoCellRenderer',
  },
  wbsItemSequenceNoColumn: {
    headerName: '#',
    width: 50,
    maxWidth: 80,
    pinned: true,
    lockPosition: true,
    suppressMovable: true,
    filter: false,
    cellClass: 'ag-numeric-cell',
    editable: false,
    cellRenderer: 'wbsItemSequenceNoCellRenderer',
  },
  detailColumn: {
    headerName: '',
    maxWidth: 150,
    pinned: true,
    lockPosition: true,
    suppressMovable: true,
    cellStyle: { justifyContent: 'center' },
    resizable: false,
    filter: 'openDetailCellFilter',
    editable: false,
    cellRenderer: 'openDetailCellRenderer',
  },
  attachmentColumn: {
    filter: 'openAttachmentCellFilter',
    editable: false,
    cellRenderer: 'openAttachmentCellRenderer',
  },
  iconColumn: {
    filter: false,
    cellRenderer: 'iconEditCell',
  },
  iconWithNameColumn: {
    cellRenderer: 'iconCellRenderer',
    filter: 'clientSideTextFilter',
    filterParams: {
      textFormatter: iconColumnTextFormatter,
    },
    keyCreator: (params): string => {
      if (!params.value) {
        return ''
      }
      return params.value.name
    },
  },
  priorityColumn: {
    width: 90,
    filter: false,
    cellClass: 'ag-numeric-cell',
    cellStyle: { justifyContent: 'center' },
  },
  integerColumn: {
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    comparator: numberComparator,
    valueParser: formatter.parse,
    valueFormatter: formatter.format,
    cellStyle: { justifyContent: 'flex-end' },
  },
  numberColumn: {
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    comparator: numberComparator,
    valueParser: formatter.parse,
    valueFormatter: formatter.format,
    cellStyle: NumberCell.cellStyle,
  },
  wbsAggregateValueColumn: {
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    comparator: numberComparator,
    valueParser: formatter.parse,
    valueFormatter: WbsAggregateValue.format,
    cellStyle: NumberCell.cellStyle,
  },
  dateColumn: {
    width: 120,
    cellEditor: 'dateCellEditor',
    valueParser: (params: ValueParserParams) =>
      dateValueParser(params.newValue),
    valueFormatter: (params: ValueFormatterParams) =>
      dateValueFormatter(params.value, params.context?.dateFormat) || '',
    filter: 'dateCellFilter',
    cellStyle: DateCell.cellStyle,
    filterParams: {},
    cellClass: 'dateType',
  },
  wbsItemScheduledDateColumn: {},
  wbsItemActualDateColumn: {
    cellStyle: WbsItemDateCell.wbsItemActualDateStyle,
    cellRendererParams: {
      tooltip: (params: ICellRendererParams): string | undefined => {
        const wbsItem = (params.data.wbsItem ||
          params.data.task ||
          params.data) as WbsItemRow
        if (!wbsItem || !wbsItem.status) return
        if (
          (params.colDef?.field || '').includes('end') &&
          WbsItemRowApi.isEndDelayed(wbsItem) &&
          ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(wbsItem.status)
        ) {
          return intl.formatMessage({ id: 'wbs.end.delayed' })
        }
        if (
          (params.colDef?.field || '').includes('start') &&
          WbsItemRowApi.isStartDelayed(wbsItem) &&
          wbsItem.status === WbsItemStatus.TODO
        ) {
          return intl.formatMessage({ id: 'wbs.start.delayed' })
        }
      },
    },
  },
  dateTimeColumn: {
    width: 175,
    cellEditor: 'dateCellEditor',
    valueFormatter: (params: ValueFormatterParams) =>
      dateTimeValueRenderer(params.value) || '',
    filter: 'dateCellFilter',
    filterParams: {},
    cellClass: 'dateTimeType',
  },
  hiddenColumn: { hide: true },
  radioGroupColumn: {
    width: 85,
    cellEditor: 'radioGroupCellEditor',
    cellRenderer: 'radioGroupCellRenderer',
  },
  autocompleteColumn: {
    width: 120,
    cellEditor: 'autocompleteCellEditor',
    cellRenderer: AutocompleteCellRenderer,
    valueFormatter: autocompleteColumnValueFormatter,
    filter: 'clientSideSelectFilter',
    keyCreator: (params): string => {
      if (!params.value) {
        return ''
      }
      if (typeof params.value !== 'object') {
        return params.value
      }
      return new EntitySearchValue(params.value).toString()
    },
    filterParams: {
      getValue: option => new EntitySearchValue(option).toString(),
      getLabel: option => new EntitySearchValue(option).toString(),
    },
  },
  multiAutocompleteColumn: {
    filter: 'clientSideSelectFilter',
    cellEditor: 'multiAutocompleteCellEditor',
    cellRenderer: 'multiAutocompleteCellRenderer',
    keyCreator: params => {
      if (!params.value || params.value.length === 0) {
        return ['(Blanks)']
      }
      return params.value.map(value => value.name || value.displayName)
    },
    filterParams: {
      valueFormatter: multiAutocompleteCellFilterValueFormatter,
      getValue: option => new EntitySearchValue(option).toString(),
      getLabel: option => new EntitySearchValue(option).toString(),
    },
  },
  selectColumn: {
    cellEditor: 'selectCellEditor',
    cellRenderer: selectCellRenderer,
    valueFormatter: selectCellValueFormatter,
    valueSetter: SelectCellValueSetter,
    valueGetter: selectCellValueGetter,
    filterValueGetter: selectCellValueGetter,
    filter: 'clientSideSelectFilter',
    filterParams: {
      valueFormatter: selectCellFilterValueFormatter,
      getValue: option => option,
      getLabel: option => option,
    },
  },
  checkBoxColumn: {
    width: 80,
    cellEditor: 'checkBoxCell',
    cellRenderer: 'checkBoxCell',
    filter: 'clientSideSelectFilter',
    keyCreator: (params): string => {
      return params.value ? 'checked' : ''
    },
    filterParams: {
      getValue: option => (option ? 'checked' : ''),
      getLabel: option => (option ? 'checked' : ''),
    },
  },
  workloadColumn: {
    width: 140,
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    cellRenderer: WorkloadCell.cellRenderer,
    comparator: numberComparator,
    valueParser: formatter.parse,
    valueSetter: WorkloadCell.valueSetter,
    valueGetter: WorkloadCell.valueGetter,
    cellStyle: WorkloadCell.cellStyle,
  },
  taskActualResultColumn: {
    width: 140,
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    valueFormatter: formatter.format,
    comparator: numberComparator,
    cellStyle: TaskActualResultCell.cellStyle,
  },
  taskActualResultServerSideColumn: {
    width: 140,
    cellClass: 'ag-numeric-cell',
    filter: 'clientSideNumberFilter',
    valueFormatter: formatter.format,
    comparator: numberComparator,
    cellStyle: TaskActualResultCell.cellStyle,
  },
  wbsItemStatusColumn: {
    cellStyle: WbsItemStatusCell.cellStyle,
    filter: 'clientSideSelectFilter',
    cellRenderer: WbsItemStatusCell.cellRenderer,
    valueSetter: WbsItemStatusCell.valueSetter,
    keyCreator: (params): string => {
      return params.value ? params.value : 'TODO'
    },
    onCellDoubleClicked: (e: CellClickedEvent) => {
      e.api.stopEditing()
    },
    filterParams: {
      sortValues: (uiMeta, options) => {
        if (!Array.isArray(uiMeta.valuesAllowed)) return options
        return uiMeta.valuesAllowed
          .filter(v => options.includes(v.value))
          .map(v => v.value)
      },
      getValue: option => option,
      getLabel: option => option,
    },
  },
  wbsItemStatusServerSideColumn: {
    cellStyle: WbsItemStatusServerSideCell.cellStyle,
    filter: 'clientSideSelectFilter',
    cellRenderer: WbsItemStatusServerSideCell.cellRenderer,
    valueSetter: WbsItemStatusServerSideCell.valueSetter,
    keyCreator: (params): string => {
      return params.value ? params.value : 'TODO'
    },
    onCellDoubleClicked: (e: CellClickedEvent) => {
      e.api.stopEditing()
    },
    filterParams: {
      sortValues: (uiMeta, options) => {
        if (!Array.isArray(uiMeta.valuesAllowed)) return options
        return uiMeta.valuesAllowed
          .filter(v => options.includes(v.value))
          .map(v => v.value)
      },
      getValue: option => option,
      getLabel: option => option,
    },
  },
  wbsItemTypeColumn: {
    filter: 'clientSideSelectFilter',
    comparator: WbsItemTypeCell.comparator,
    cellRenderer: WbsItemTypeCell.cellRenderer,
    valueFormatter: WbsItemTypeCell.valueFormatter,
    filterParams: {
      getValue: option => option,
      getLabel: option => option,
    },
  },
  i18nLabelColumn: {
    cellRenderer: 'i18nLabelCell',
  },
  multiLineTextColumn: {
    cellRenderer: 'multiLineTextRenderer',
    cellEditor: 'multiLineTextEditor',
  },
  // TODO Remove it
  markupCell: {
    valueGetter: (params: ValueGetterParams) => {
      const field = params.column.getColDef().field!
      if (!field) {
        return undefined
      }
      let value: string = objects.getValue(params.data, field)
      return value ? value.replace(/(<([^>]+)>)/gi, '') : ''
    },
  },
  commentColumn: {
    cellRenderer: 'commentRenderer',
    filter: 'commentCellFilter',
    filterParams: {
      textFormatter: commentColumnTextFormatter,
    },
    keyCreator: (params): string => {
      if (!params.value) {
        return ''
      }
      return params.value.name
    },
    comparator: (valueA: Comment | undefined, valueB: Comment | undefined) => {
      const valA: number = valueA?.createdAt ?? 0
      const valB: number = valueB?.createdAt ?? 0
      return valA - valB
    },
  },
  multiSelectColumn: {
    filter: 'clientSideSelectFilter',
    cellEditor: 'multiSelectCellEditor',
    valueFormatter: multiSelectCellValueFormatter,
    keyCreator: params => {
      if (!params.value) {
        return [] // The row is blank
      }
      if (params.value.length === 0) {
        return ['(Blanks)']
      }
      return params.value.map(value => value.name || value.displayName)
    },
    filterParams: {
      valueFormatter: selectCellFilterValueFormatter,
      getValue: option => option?.value,
      getLabel: option => option?.value,
    },
  },
  ganttColumn: {
    cellRenderer: 'ganttCellRenderer',
    headerComponent: 'ganttHeaderComponent',
  },
  wbsItemBasicColumn: {
    cellRenderer: 'wbsItemBasicCellRenderer',
    filter: 'clientSideTextFilter',
    filterParams: {
      textFormatter: (value: Partial<WbsItemRow>) => value?.displayName ?? '',
    },
    comparator: (value1: Partial<WbsItemRow>, value2: Partial<WbsItemRow>) => {
      return (value1?.displayName ?? '').localeCompare(
        value2?.displayName ?? ''
      )
    },
  },
  ticketTypeColumn: {
    filter: 'clientSideSelectFilter',
    cellRenderer: TicketTypeCell.cellRenderer,
    filterParams: {
      getValue: option => option,
      getLabel: option => option,
    },
  },
  tagColumn: {
    cellRenderer: 'tagCellRenderer',
    cellEditor: 'tagCellEditor',
    filter: 'clientSideSelectFilter',
    filterParams: {
      getValue: option => option?.uuid ?? '',
      getLabel: option => option?.name ?? '',
    },
  },
  customEnumColumn: {},
})

export const dateValueFormatter = (
  value: string | undefined,
  dateFormat?: string
) => {
  if (!value) return ''
  return formatDateBy(
    dateFormat || DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
    value.replaceAll('/', '-')
  )
}

const dateTimeValueRenderer = (value: string | undefined) => {
  return value ? formatDateTime(value) : ''
}

export const dateValueParser = (value: string | null | undefined): string => {
  if (!value) {
    return ''
  }
  if (value.toString().length === 5) {
    const num = Number(value)
    if (num && Number.isInteger(num)) {
      const date = new Date((num - (25567 + 2)) * (24 * 60 * 60 * 1000))
      return moment(date).format(DISPLAY_DATE_FORMAT)
    }
  }

  return completeDateString(value)
}

export const dateTimeValueParser = (
  value: string | null | undefined,
  uiMeta: FunctionProperty | undefined = undefined
): string => {
  if (!value) {
    return ''
  }

  const dateString = dateValueParser(value)

  const TIME_FORMATS = {
    HHmmss_COLON: /[0-9]{2}:[0-9]{1,2}:[0-9]{1,2}/, // HH:mm:ss
    HHmmss: /[0-9]{6}/, // HHmmss
    HHmm_COLON: /[0-9]{1,2}:[0-9]{1,2}/, // HH:mm
    HHmm: /[0-9]{4}/, // HHmm
  }

  const timeValue = value.includes(' ') ? value.split(' ')[1] : ''
  let dateTimeStr = ''
  if (
    TIME_FORMATS.HHmmss_COLON.test(timeValue) ||
    TIME_FORMATS.HHmmss.test(timeValue) ||
    TIME_FORMATS.HHmm_COLON.test(timeValue) ||
    TIME_FORMATS.HHmm.test(timeValue)
  ) {
    dateTimeStr = timeValue
  }

  const defaultTime =
    uiMeta?.externalId.includes('To') || uiMeta?.externalId.includes('End')
      ? '23:59:59'
      : '00:00:00'
  let momentObj = moment(dateTimeStr, 'HHmmss')
  const timeString = momentObj.isValid()
    ? momentObj.format(DISPLAY_TIME_SECOND_FORMAT)
    : defaultTime
  return `${dateString} ${timeString}`
}

const autocompleteColumnValueFormatter = (params: ValueFormatterParams) => {
  return params.value ? new EntitySearchValue(params.value).toString() : ''
}

const iconColumnTextFormatter = params => {
  if (typeof params === 'undefined') {
    return ''
  }
  if (typeof params !== 'string') {
    return params.name.toLowerCase()
  }
  return params.toLowerCase()
}

const commentColumnTextFormatter = params => {
  if (typeof params === 'undefined') {
    return ''
  }
  if (typeof params !== 'string') {
    return (
      params.text +
      params.createdBy.name +
      formatDateTime(params.createdAt)
    ).toLowerCase()
  }
  return params.toLowerCase()
}

const numberComparator = (value1, value2) => {
  return Number(value1) === Number(value2)
    ? 0
    : Number(value1) < Number(value2)
    ? -1
    : 1
}

export const sideBar = () => ({
  toolPanels: [
    {
      id: 'columns',
      labelDefault: '',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivotMode: true,
        suppressValues: true,
        suppressRowGroups: true,
      },
    },
  ],
  defaultToolPanel: '',
})

export const frameworkComponents = {
  textEditor: TextCell,
  textFloatingFilter: TextSearchFilter,
  iconCellRenderer: IconCellRenderer,
  radioGroupCellEditor: RadioGroupCellEditor,
  radioGroupCellRenderer: RadioGroupCellRenderer,
  autocompleteCellEditor: AutocompleteCellEditor,
  multiAutocompleteCellEditor: MultiAutocompleteCell.cellEditor,
  multiAutocompleteCellRenderer: MultiAutocompleteCell.cellRenderer,
  selectCellEditor: SelectCellEditor,
  checkBoxCell: CheckBoxCell,
  wbsItemTreeCellRenderer: WbsItemTreeCellRenderer,
  i18nLabelCell: I18nLabelCellRenderer,
  dateCellEditor: DateCell.cellEditor,
  openDetailCellRenderer: OpenDetailCellRenderer,
  multiLineTextRenderer: MultiLineTextCell.cellRenderer,
  multiLineTextEditor: MultiLineTextCell.cellEditor,
  iconEditCell: IconEditCell.cellRenderer,
  countWbsStatusComponent: CountWbsStatus,
  openDetailCellFilter: DetailCellFilter,
  openAttachmentCellRenderer: OpenAttachmentCellRenderer,
  openAttachmentCellFilter: OpenAttachmentCellFilter,
  commentRenderer: CommentCell.cellRenderer,
  dateCellFilter: DateCell.cellFilter,
  multiSelectCellEditor: MultiSelect.cellEditor,
  commentCellFilter: CommentCellFilter,
  wbsItemBasicCellRenderer: WbsItemBasicCellRenderer,
  ganttCellRenderer: Gantt.cellRenderer,
  ganttHeaderComponent: Gantt.headerComponent,
  ganttHeaderGroupComponent: Gantt.headerGroupComponent,
  sequenceNoCellRenderer: SequenceNoCellRenderer,
  wbsItemSequenceNoCellRenderer: WbsItemSequenceNoCellRenderer,
  ticketTypeCellRenderer: TicketTypeCell.cellRenderer,
  clientSideTextFilter: ClientSideTextFilter,
  clientSideNumberFilter: ClientSideNumberFilter,
  clientSideSelectFilter: ClientSideSelectFilter,
  tagCellRenderer: TagCellRenderer,
  tagCellEditor: TagCellEditor,
}

export const exportContextMenu = (
  gridApi: GridApi,
  exportParams: BaseExportParams
) => {
  return [
    {
      name: 'CSV Export',
      action: () => {
        gridApi.exportDataAsCsv(exportParams)
      },
    },
    {
      name: 'Excel Export (.xlsx)',
      action: () => {
        gridApi.exportDataAsExcel({ ...exportParams, exportMode: 'xlsx' })
      },
    },
    {
      name: 'Excel Export (.xml)',
      action: () => {
        gridApi.exportDataAsExcel({ ...exportParams, exportMode: 'xml' })
      },
    },
  ]
}

export const processCellForClipboard = (params: ProcessCellForExportParams) => {
  if (params.column.getColId() === 'ag-Grid-AutoColumn' && params.node) {
    const cellRendererParams = params.column.getColDef().cellRendererParams
    const uiMeta: FunctionProperty = cellRendererParams.uiMeta
    const viewMeta: ViewMeta = cellRendererParams.viewMeta
    const field = viewMeta.makeDataPropertyName(uiMeta)
    const value = objects.getValue(params.node.data, field)
    return value || params.value || ''
  }
  if (typeof params.value === 'number') {
    // To handle 0 and empty cell separately, return 0 when the value is 0.
    return params.value
  }
  const colType = params.column.getColDef().type
  if (!colType) {
    return params.value || ' '
  }
  if (colType === ColumnType.select || colType.includes(ColumnType.select)) {
    // Return enum value for select columns.
    const valuesAllowed =
      params.column.getColDef().cellRendererParams.uiMeta.valuesAllowed
    return getCustomEnumName(params.value, valuesAllowed)
  }
  if (
    colType === ColumnType.autocomplete ||
    colType.includes(ColumnType.autocomplete) ||
    colType === ColumnType.wbsItemBasic ||
    colType.includes(ColumnType.wbsItemBasic)
  ) {
    return autocompleteColumnValueFormatter(
      transformParamsFromExportToFormatter(params)
    )
  }
  if (
    colType === ColumnType.multiAutocomplete ||
    colType.includes(ColumnType.multiAutocomplete)
  ) {
    return MultiAutocompleteCell.valueFormatter(
      transformParamsFromExportToFormatter(params)
    )
  }
  if (
    typeof params.value === 'string' &&
    (colType === ColumnType.multiLineText ||
      colType.includes(ColumnType.multiLineText))
  ) {
    return `"${normalize(params.value || '')}"`
  }
  if (colType === ColumnType.comment || colType.includes(ColumnType.comment)) {
    return params.value ? format(params.value) : ' '
  }
  if (
    colType === ColumnType.multiSelect ||
    colType.includes(ColumnType.multiSelect)
  ) {
    if (!params.value) {
      return ''
    }
    return params.value.map(v => v.name).join(',')
  }
  if (
    colType === ColumnType.wbsItemType ||
    colType.includes(ColumnType.wbsItemType)
  ) {
    return WbsItemTypeCell.valueFormatter(
      transformParamsFromExportToFormatter(params)
    )
  }
  if (colType === ColumnType.tag || colType.includes(ColumnType.tag)) {
    if (!params.value) {
      return ''
    }
    return params.value.map(v => v.name).join(TAG_DELIMITER)
  }
  // 空の1つのセルをコピーして、1つのセルに対して、ペーストしようとすると右隣のセルにもペーストされてしまうため、
  // 半角スペースをセット。
  return params.value || ' '
}

const transformParamsFromExportToFormatter = (
  params: ProcessCellForExportParams
): ValueFormatterParams => {
  return {
    node: params.node!,
    data: params.node!.data,
    colDef: params.column.getColDef(),
    column: params.column,
    api: params.api,
    columnApi: params.columnApi,
    context: params.context,
    value: params.value,
  }
}

export const onCellValueChanged = (
  event: CellValueChangedEvent,
  eventCache: CellValueChangedEvent[],
  projectUuid: string
) => {
  const fieldName = event.colDef.field || event.colDef.colId
  if (!fieldName) return
  if (event.oldValue !== event.newValue) {
    // Save the original value to editedData
    if (!event.data.editedData) {
      event.data.editedData = {}
      event.data.editedData[fieldName] = event.oldValue
    } else if (!event.data.editedData.hasOwnProperty(fieldName)) {
      event.data.editedData[fieldName] = event.oldValue
    }
    event.api.refreshCells({
      rowNodes: [event.node],
      columns: [event.column],
      force: true,
    })
  }
  const CLIPBOARD_INPUT_SOURCE = 'paste'
  if (event.source !== CLIPBOARD_INPUT_SOURCE) {
    return
  }
  let needToRefresh = false
  const colType = event.colDef.type
  const columnTypes = Array.isArray(colType) ? colType : [colType]
  if (
    columnTypes.includes(ColumnType.autocomplete) ||
    columnTypes.includes(ColumnType.multiAutocomplete)
  ) {
    eventCache.push(event)
  } else if (columnTypes.includes(ColumnType.select)) {
    const value = getCustomEnumValue(
      event.newValue,
      event.colDef.cellRendererParams.uiMeta.valuesAllowed
    )
    needToRefresh = true
    event.node.setDataValue(fieldName, value)
  } else if (columnTypes.includes(ColumnType.checkBox)) {
    const value =
      event.newValue === 'true' ||
      event.newValue === 'TRUE' ||
      event.newValue > 0 ||
      event.newValue === true
    event.node.setDataValue(fieldName, value)
  } else if (event.colDef.cellClass === 'ag-numeric-cell') {
    needToRefresh = true
    if (isNaN(event.newValue)) {
      event.node.setDataValue(fieldName, event.oldValue)
    } else {
      event.node.setDataValue(
        fieldName,
        typeof event.newValue === 'string'
          ? parseFloat(event.newValue)
          : event.newValue
      )
    }
  } else if (columnTypes.includes(ColumnType.multiSelect)) {
    const values: CustomEnumValue[] = []
    const newValueArray = event.newValue ? event.newValue.split(',') : []
    const customEnums: CustomEnumValue[] =
      event.colDef.cellRendererParams.uiMeta.valuesAllowed
    newValueArray.forEach(value => {
      const normalized = value ? value.trim() : ''
      const customEnum = customEnums.find(
        customEnum => customEnum.name === normalized
      )
      if (customEnum) {
        values.push(customEnum)
      }
    })
    event.node.setDataValue(fieldName, values)
  } else if (
    event.newValue &&
    typeof event.newValue === 'string' &&
    !event.newValue.trim()
  ) {
    needToRefresh = true
    event.node.setDataValue(fieldName, '')
  } else if (columnTypes.includes(ColumnType.tag)) {
    const valueNameList = event.newValue?.split(TAG_DELIMITER) ?? []
    const tag = store.getState().tag[projectUuid] ?? []
    event.node.setDataValue(
      fieldName,
      tag.filter(v => valueNameList.includes(v.name))
    )
  }

  if (needToRefresh) {
    event.api.refreshCells({
      rowNodes: [event.node],
      columns: [fieldName],
      force: true,
    })
  }
}

export const onPasteEnd = async (
  params: PasteEndEvent,
  eventCache: CellValueChangedEvent[]
) => {
  const changedNodes = new Set<RowNode>()
  const changedColumns = new Set<string>()
  type ReferenceEntity = string
  type SearchOption = any
  const isSameSearchOption = (a: SearchOption, b: SearchOption) =>
    JSON.stringify(a) === JSON.stringify(b)
  const referenceEntities = new Map<ReferenceEntity, SearchOption[]>()
  eventCache.forEach(event => {
    const uiMeta = event.colDef.cellEditorParams.uiMeta
    const rowData = event.data
    const searchOption = uiMeta.searchOptions.build(rowData)
    const referenceEntity = uiMeta.referenceEntity
    let searchOptions = referenceEntities.get(referenceEntity)
    if (!searchOptions) {
      searchOptions = []
    }
    if (!searchOptions.some(v => isSameSearchOption(v, searchOption))) {
      searchOptions.push(searchOption)
    }
    referenceEntities.set(referenceEntity, searchOptions)
  })
  for (const referenceEntity of Array.from(referenceEntities.keys())) {
    const repository = repositories[referenceEntity]
    const searchOptions = referenceEntities.get(referenceEntity) || []
    const promises: Promise<{
      searchOption: SearchOption
      entities: any[]
    }>[] = []
    searchOptions.forEach(searchOption => {
      promises.push(
        repository.search('', searchOption).then(entities => {
          return {
            searchOption,
            entities,
          }
        })
      )
    })
    const entitiesWithSearchOptions = await Promise.all(promises)
    eventCache
      .filter(
        event =>
          event.colDef.cellEditorParams.uiMeta.referenceEntity ===
          referenceEntity
      )
      .forEach(event => {
        const uiMeta = event.colDef.cellEditorParams.uiMeta
        const fieldName = event.colDef.field!
        const validatePaste = event.colDef.cellEditorParams.validatePaste
        const rowData = event.data
        const searchOptionOfRowData = uiMeta.searchOptions.build(rowData)
        const entitiesWithSearchOption = entitiesWithSearchOptions.find(v =>
          isSameSearchOption(v.searchOption, searchOptionOfRowData)
        )
        const entities = entitiesWithSearchOption?.entities || []
        if (event.colDef.type?.includes('autocompleteColumn')) {
          const match = findMatchedEntity(
            event.value,
            entities,
            repository,
            event,
            validatePaste
          )
          event.node.setDataValue(fieldName, match)
        } else if (event.colDef.type?.includes('multiAutocompleteColumn')) {
          const values = event.value.split(', ')
          const matches = values
            .map(value => {
              return findMatchedEntity(
                value,
                entities,
                repository,
                event,
                validatePaste
              )
            })
            .filter(v => !!v)
          event.node.setDataValue(fieldName, matches)
        }
        changedNodes.add(event.node)
        changedColumns.add(fieldName)
      })
  }

  changedNodes.size > 0 &&
    params.api.refreshCells({
      rowNodes: Array.from(changedNodes),
      columns: Array.from(changedColumns),
      force: true,
    })
}

const findMatchedEntity = (
  value: string,
  entities: any[],
  repository: any,
  event: CellValueChangedEvent,
  validatePaste?: (value: any) => boolean
): any | undefined => {
  return entities.find(
    v =>
      repository.filter(v, event.node.data) &&
      (!validatePaste || validatePaste(v)) &&
      (v.name === value || v.code === value || v.displayName === value)
  )
}

export const processDataFromClipboard = (
  params: ProcessDataFromClipboardParams
) => {
  // Since empty row is included when copied from Excel in Windows, Need to delete that row.
  // TODO: This also delete the empty row intended.
  const lastRow = params.data[params.data.length - 1]
  if (params.data.length > 1 && lastRow.length === 1 && lastRow[0] === '') {
    params.data.splice(-1)
  }
  return params.data
}

export const resetChildTreeValue = (data: any[], rowNode: RowNode) => {
  if (!rowNode.childrenAfterGroup || rowNode.childrenAfterGroup.length === 0) {
    return
  }
  const children = rowNode.childrenAfterGroup.filter(
    node => node.parent === rowNode
  )
  for (let child of children) {
    const childTreeValue = child.data.treeValue
    child.data.treeValue = [
      ...rowNode.data.treeValue,
      childTreeValue[childTreeValue.length - 1],
    ]
    data.push(child.data)
    resetChildTreeValue(data, child)
  }
}

export const getParentNode = (node?: RowNode | null) => {
  return node?.parent || undefined
}

export const getAncestors = (node?: RowNode): RowNode[] => {
  if (!node) return []
  const rows: RowNode[] = []
  let parent: RowNode | undefined = node
  while (parent && parent.data) {
    rows.push(parent)
    parent = parent.parent || undefined
  }
  return rows
}

export const refreshAncestors = (
  api: GridApi,
  fields: string[],
  node?: RowNode,
  updateData?: (node: RowNode) => void
) => {
  if (!node) {
    return
  }
  const rows: RowNode[] = []
  let parent: RowNode | undefined = node
  while (parent && parent.data) {
    updateData && updateData(parent)
    rows.push(parent)
    parent = parent.parent || undefined
  }
  api.refreshCells({
    rowNodes: rows,
    columns: fields,
    force: true,
  })
}

export const isRootNode = (targetId: string) => {
  return targetId === 'ROOT_NODE_ID'
}

// TODO Remove it after resolving ag-Grid internal error when pressing Enter on the last line
export const addEventListenerForLastRow = (
  element: HTMLElement,
  props: {
    api: GridApi
    rowIndex: number
    column: Column
  }
) => {
  const lastRowIndex = props.api.getLastDisplayedRow()
  if (props.rowIndex === lastRowIndex) {
    element.addEventListener('keydown', e => {
      if (
        e.key === 'Enter' &&
        !(e as any).isComposing // Ignore key event fired by IME
      ) {
        props.api.stopEditing()
        props.api.setFocusedCell(props.rowIndex, props.column)
        e.stopPropagation()
      }
    })
  }
}

export const CURRENT_MONTH_BACKGROUND_COLOR = colorPalette.skyBlue[1]
export const CURRENT_MONTH_BACKGROUND_COLOR_STYLE: CellStyle = {
  backgroundColor: CURRENT_MONTH_BACKGROUND_COLOR,
}
