import './style.scss'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

/**
 * チケット管理表示用のアイコンを指定されたアイコンと組み合わせて表示する
 * @param type WbsItemTypeVO
 */
export const TicketListSubMenuItemsIcon = (type: WbsItemTypeVO) => {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <img src={type.iconUrl} />
        <img
          src={`${process.env.REACT_APP_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/ticket_list.svg`}
          style={{ height: '16px' }}
        />
      </div>
    </>
  )
}
