import styled from '@emotion/styled'
import { IconButton, Link, Tooltip } from '@mui/material'
import { useCallback } from 'react'
import {
  DevelopmentPanelCaption,
  DevelopmentPanelIconArea,
  DevelopmentPanelMainArea,
  DevelopmentPanelPaper,
  DevelopmentPanelRow,
  DevelopmentPanelTitle,
  DevelopmentPanelTopicIconArea,
} from '.'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import { CommitVO } from '../../../../domain/value-object/development/CommitVO'
import { intl } from '../../../../i18n'
import GitCommitIcon from '../../icons/GitCommitIcon'
import { LinkIcon } from '../../icons/PureLinkIcon'
import { RepositoryField } from './RepositoryField'

type CommitFieldProps = {
  commit: CommitVO
}

export const CommitField = ({ commit }: CommitFieldProps) => {
  return (
    <DevelopmentPanelPaper elevation={3}>
      <DevelopmentPanelTopicIconArea>
        <GitCommitIcon />
      </DevelopmentPanelTopicIconArea>
      <DevelopmentPanelMainArea>
        <DevelopmentPanelRow>
          <CommitMessageField message={commit.message} />
          <CommitShaField sha={commit.sha} url={commit.url} />
        </DevelopmentPanelRow>
        <DevelopmentPanelRow>
          <CommitUserAndTimestampField
            committer={commit.committer}
            timestamp={commit.timestamp}
          />
        </DevelopmentPanelRow>
        <DevelopmentPanelRow>
          <RepositoryField repository={commit.repository} />
        </DevelopmentPanelRow>
      </DevelopmentPanelMainArea>
    </DevelopmentPanelPaper>
  )
}

type CommitShaFieldProps = {
  sha: string
  url: string
}

const CopyShaIconButton = styled(IconButton)({
  padding: '2px',
})

const CommitSpan = styled('span')({
  margin: '0 0 0 10px',
  display: 'flex',
  alignItems: 'flex-end',
})

const CommitShaField = ({ sha, url }: CommitShaFieldProps) => {
  const onClickIcon = useCallback(() => {
    navigator.clipboard.writeText(sha)
  }, [sha])
  return (
    <CommitSpan>
      <Link href={url} target="_blank">
        {sha.substring(0, 7)}
      </Link>
      <Tooltip title={intl.formatMessage({ id: 'development.commit.copySha' })}>
        <CopyShaIconButton size="small" onClick={onClickIcon}>
          <LinkIcon />
        </CopyShaIconButton>
      </Tooltip>
    </CommitSpan>
  )
}

type CommitMessageFieldProps = {
  message: string
}

const CommitMessageField = ({ message }: CommitMessageFieldProps) => {
  return <DevelopmentPanelTitle>{message}</DevelopmentPanelTitle>
}

type CommitUserAndTimestampFieldProps = {
  committer: string
  timestamp: DateTimeVO
}

const CommitUserAndTimestampField = ({
  committer,
  timestamp,
}: CommitUserAndTimestampFieldProps) => {
  return (
    <DevelopmentPanelCaption>
      <b>{committer}</b> committed at {timestamp.toString()}
    </DevelopmentPanelCaption>
  )
}
