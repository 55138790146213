import { useCallback, useState } from 'react'
import { CommentSummary } from '../../../../../../../../../store/comments'

export enum DetailFilter {
  HAS_COMMENT = 'HAS_COMMENT',
  NO_COMMENT = 'NO_COMMENT',
}

export const useDetailFilter = () => {
  const [values, setValues] = useState<DetailFilter[]>([
    DetailFilter.HAS_COMMENT,
    DetailFilter.NO_COMMENT,
  ])

  const isFiltered = () => values.length < 2

  const isEmpty = () => values.length === 0

  const reset = () =>
    setValues([DetailFilter.HAS_COMMENT, DetailFilter.NO_COMMENT])

  const pass = useCallback(
    (v: CommentSummary) => {
      const hasComment = v?.hasComment ?? false
      return (
        (values.includes(DetailFilter.HAS_COMMENT) && hasComment) ||
        (values.includes(DetailFilter.NO_COMMENT) && !hasComment)
      )
    },
    [values]
  )

  return { values, setValues, isFiltered, isEmpty, reset, pass }
}
