import _ from 'lodash'
import React from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  FilterOptionsState,
  MenuItem,
  Popper,
} from '@mui/material'
import { styled } from '@mui/system'
import InputBase from '@mui/material/InputBase'
import { Column, ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import repositories from '../../../../../../meta/repositories'
import { RowData } from '../../../../../../BulkSheet/RowDataManager/rowDataManager'
import objects from '../../../../../../../../utils/objects'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { BackgroundColor } from '../../../../../../../../styles/commonStyles'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})
const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
})
const MenuItemLabel = styled('span')({
  whiteSpace: 'nowrap',
})
const StyledAvatar = styled(Avatar)({
  marginRight: '5px',
  height: '23px',
  width: '23px',
  borderRadius: '50%',
})
const NoImageIcon = styled(PersonRoundedIcon)({
  marginLeft: '5px', // added right margin to make it align to the avatar
  marginRight: '5px',
  borderRadius: '50%',
  height: '23px',
  minWidth: '23px',
  backgroundColor: BackgroundColor.GREY,
})

const iconDefined = ['ProjectMember', 'User']

interface AutocompleteOption {
  uuid: string
  name: string
  iconUrl?: string
}

interface Props {
  value: AutocompleteOption
  node: RowNode
  api: GridApi
  columnApi: ColumnApi
  keyPress: number
  rowIndex: number
  column: Column
  data: RowData

  field: string
  uiMeta: FunctionProperty
}

interface State {
  value?: AutocompleteOption
  text?: string
  suggestions: AutocompleteOption[]
  open: boolean
}

class AutocompleteCellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  private lastPressedKey: string
  private lastValue?: AutocompleteOption

  constructor(props: Props) {
    super(props)
    this.state = this.createInitialState(props)
    this.lastPressedKey = ''
  }

  private createInitialState(props) {
    let startText: string
    if (props.charPress) {
      startText = props.charPress
    } else {
      startText = ''
    }
    return {
      text: startText,
      suggestions: [],
      open: false,
    }
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
    }
    if (
      this.props.keyPress === 46 ||
      this.props.keyPress === 8 /* DELETE or BACKSPACE */
    ) {
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(this.props.rowIndex, this.props.column)
    }
  }

  getValue = () => {
    return this.state.value
  }

  private focused = event => {
    this.search(this.state.text ? this.state.text : '')
    // save previous value to recover when escape key is pressed.
    this.lastValue = objects.getValue(
      this.props.node.data,
      this.props.field || this.props.uiMeta.externalId
    )
  }

  private inputChanged = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      this.setState({ text: value })
      this.search(value)
    }
  }

  private valueChanged = (
    event: React.ChangeEvent<{}>,
    value: AutocompleteOption | null,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'blur') return
    this.props.node.setDataValue(
      this.props.field || this.props.uiMeta.externalId,
      value
    )
    // @ts-ignore
    const keyCode = event.keyCode
    this.setState(
      {
        value: value || undefined,
        text: value ? value.name : '',
      },
      () => {
        if (this.lastPressedKey !== 'Tab') {
          let nextRowIndex
          if (keyCode === 13) {
            nextRowIndex =
              this.props.api.getLastDisplayedRow() !== this.props.rowIndex
                ? this.props.rowIndex + 1
                : this.props.rowIndex
          } else {
            nextRowIndex = this.props.rowIndex
          }
          this.props.api.stopEditing()
          this.props.api.clearRangeSelection()
          this.props.api.clearFocusedCell()
          this.props.api.setFocusedCell(nextRowIndex, this.props.column)
        }
      }
    )
  }

  private blurred = event => {
    // WORKAROUND: call setState before stopEditing otherwise it cannot show the new value.
    this.setState({ open: false }, () => this.props.api.stopEditing())
  }

  private handleKeyPress = event => {
    if (event.key === 'Enter' && !this.state.value) {
      let nextRowIndex =
        this.props.api.getLastDisplayedRow() !== this.props.rowIndex
          ? this.props.rowIndex + 1
          : this.props.rowIndex
      this.props.api.stopEditing()
      this.props.api.clearRangeSelection()
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(nextRowIndex, this.props.column)
    }
  }

  private handleKeyDown = event => {
    this.lastPressedKey = event.key
    // When Tab key is pressed, set the selected option as value.
    if (event.key === 'Tab') {
      event.preventDefault()
      const value = this.props.value
      this.props.node.setDataValue(
        this.props.field || this.props.uiMeta.externalId,
        value
      )
      this.setState(
        {
          value: value || undefined,
          text: value ? value.name : '',
        },
        () => {
          this.props.api.tabToNextCell()
          this.setState({ open: false })
        }
      )
    }
    // When Escape key is pressed, reset the previous option as value.
    // This is necessary since autoSelect option set value even when the Escape is pressed.
    if (event.key === 'Escape') {
      this.props.node.setDataValue(
        this.props.field || this.props.uiMeta.externalId,
        this.lastValue
      )
      this.setState(
        {
          value: this.lastValue || undefined,
          text: this.lastValue ? this.lastValue.name : '',
        },
        () => {
          const current = this.props.api.getFocusedCell()
          current &&
            this.props.api.addCellRange({
              rowStartIndex: current.rowIndex,
              rowEndIndex: current.rowIndex,
              columns: [current.column],
            })
          this.setState({ open: false })
        }
      )
    }
  }

  private setOptions = async (value: string) => {
    const cellEditorParams = this.props.column.getColDef().cellEditorParams
    const searchOptions = this.props.uiMeta.searchOptions.build(this.props.data)
    let options: AutocompleteOption[] = await repositories[
      this.props.uiMeta.referenceEntity!
    ].search(value, searchOptions, this.props.uiMeta)
    const selectOptionsFilter = cellEditorParams.selectOptionsFilter
    options = selectOptionsFilter ? selectOptionsFilter(options) : options
    if (this.props.uiMeta.propertyType === 'SELECT') {
      options = options.filter(v => v.name.includes(value))
    }
    this.setState({ suggestions: options }, () => {
      this.setState({ open: this.state.suggestions.length > 0 })
    })
  }

  private search = _.debounce(this.setOptions, 300)

  private getIcon = (
    referenceEntity: string | undefined,
    iconUrl: string | undefined
  ) => {
    if (!referenceEntity || !iconDefined.includes(referenceEntity)) {
      return <></>
    }
    if (!iconUrl) {
      return <NoImageIcon color="action" />
    }
    return <StyledAvatar variant="circular" src={iconUrl} />
  }

  render() {
    const { uiMeta } = this.props
    return (
      <RootDiv>
        <Autocomplete
          inputValue={this.state.text}
          options={this.state.suggestions}
          getOptionLabel={option => (option.name ? option.name : '')}
          renderOption={(props, option: AutocompleteOption, state) => {
            return (
              <StyledMenuItem {...props}>
                {this.getIcon(uiMeta.referenceEntity, option.iconUrl)}
                <MenuItemLabel>
                  {repositories[uiMeta.referenceEntity!].getLabel(option)}
                </MenuItemLabel>
              </StyledMenuItem>
            )
          }}
          filterOptions={(
            options: AutocompleteOption[],
            state: FilterOptionsState<AutocompleteOption>
          ) => {
            return options
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <InputBase
              inputRef={this.ref}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus={true}
            />
          )}
          PopperComponent={props => (
            <Popper {...props} placement="bottom-start" />
          )}
          autoSelect={true}
          blurOnSelect={false}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          onFocus={this.focused}
          onInputChange={this.inputChanged}
          onChange={this.valueChanged}
          onBlur={this.blurred}
          open={this.state.open}
          noOptionsText={''}
        />
      </RootDiv>
    )
  }
}

export default AutocompleteCellEditor
