import { useCallback } from 'react'
import {
  useBooleanParser,
  useDateParser,
  useSelectParser,
  useTextParser,
} from '../../changeLogParser'
import { RiskSelectOptions } from './selectOptions'
import { WbsItemSelectOptions } from '../../wbsItemSelectOptions'
import { useWbsItemChangeLogParser } from '../../wbsItemChangeLogParser'

export const useRiskChangeLogParser = (
  wbsItemSelectOptions: WbsItemSelectOptions,
  { riskTypes, probabilities, impacts, strategies }: RiskSelectOptions
) => {
  const getWbsItemValueParser = useWbsItemChangeLogParser(wbsItemSelectOptions)
  const riskTypesParser = useSelectParser(riskTypes)
  const probabilitiesParser = useSelectParser(probabilities)
  const impactsParser = useSelectParser(impacts)
  const strategiesParser = useSelectParser(strategies)
  const getChangeLogParser = useCallback(
    (path: string) => {
      switch (path) {
        case 'riskType':
          return riskTypesParser
        case 'probability':
          return probabilitiesParser
        case 'impact':
          return impactsParser
        case 'strategy':
          return strategiesParser
      }
      return getWbsItemValueParser(path)
    },
    [
      getWbsItemValueParser,
      impactsParser,
      probabilitiesParser,
      riskTypesParser,
      strategiesParser,
    ]
  )
  return getChangeLogParser
}
