import { styled } from '@mui/system'
import { IconButton } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'

export interface UserShiftButtonGroupProps {
  userName: string | undefined
  currentIndex: number
  totalCount: number
  onPagingUser: (index: number) => void
}

const arrowIconStyle: React.CSSProperties = {
  color: 'gray',
  width: '14px',
  height: '14px',
  verticalAlign: 'middle',
}
const ShiftButtonGroup = styled('div')({
  borderRadius: '5px',
  border: '1px solid lightgray',
  paddingRight: '4px',
  paddingleft: '4px',
  display: 'flex',
})
const NameDiv = styled('div')({
  background: 'white',
  borderRadius: '0%',
  padding: '1px',
  height: '24px',
  marginLeft: '4px',
  marginRight: '8px',
  minWidth: '100px',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
})
const ShiftButton = styled(IconButton)({
  backgroundColor: '#FFFFFF',
  borderRadius: '0%',
  minWidth: '24px',
  margin: '1px',
  padding: '3px',
})

const UserShiftButtonGroup = (props: UserShiftButtonGroupProps) => {
  return (
    <ShiftButtonGroup>
      <NameDiv>{props.userName ?? ''}</NameDiv>
      <ShiftButton
        disabled={props.currentIndex <= 1}
        onClick={() => props.onPagingUser(props.currentIndex - 1)}
      >
        <ArrowBackIos style={arrowIconStyle} />
      </ShiftButton>
      <ShiftButton
        disabled={props.currentIndex >= props.totalCount}
        onClick={() => props.onPagingUser(props.currentIndex + 1)}
      >
        <ArrowForwardIos style={arrowIconStyle} />
      </ShiftButton>
    </ShiftButtonGroup>
  )
}

export default UserShiftButtonGroup
