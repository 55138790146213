import React from 'react'
import { InputAdornment, InputBase, SxProps } from '@mui/material'
import DatePicker, { registerLocale } from 'react-datepicker'
import { styled } from '@mui/system'
import el from 'date-fns/locale/el'
import ja from 'date-fns/locale/ja'
import { formatDateForApi } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import { EventNote } from '@mui/icons-material'
import { FontSize, TextColor } from '../../../../styles/commonStyles'
import { colorPalette } from '../../../style/colorPallete'
import './DateSelectStyle.scss'

registerLocale('en', el)
registerLocale('ja', ja)

export enum DateSelectType {
  YEAR_MONTH,
  DATE,
}

const getDateFormat = (type: DateSelectType) => {
  switch (type) {
    case DateSelectType.YEAR_MONTH:
      return 'yyyy/MM'
    case DateSelectType.DATE:
      return 'yyyy/MM/dd'
  }
}

const getShowMonthYearPicker = (type: DateSelectType) => {
  if (type === DateSelectType.YEAR_MONTH) {
    return true
  }
  return false
}

interface Props {
  type: DateSelectType
  value: DateVO
  placeholder?: string
  onChange: (value: string | undefined) => void
  startDate?: DateVO
  endDate?: DateVO
  disabled?: boolean
  sx?: SxProps
}

const RootContainer = styled('div')({
  width: '100%',
  border: `solid 1px ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  padding: '0 3px',
  zIndex: 3,
})
const CalendarIcon = styled(EventNote)({
  color: TextColor.GREY,
  fontSize: FontSize.MEDIUM,
})
const DateInputBase = styled(InputBase)({
  fontSize: '11px !important',
  color: colorPalette.monotone[4],
})

const DateSelect = (props: Props) => {
  const {
    type,
    value,
    placeholder,
    onChange,
    startDate,
    endDate,
    disabled,
    sx,
  } = props
  const dateFormat = getDateFormat(type)
  const showMonthYearPicker = getShowMonthYearPicker(type)
  const locale = navigator.language.split(/[-_]/)[0]

  return (
    <RootContainer sx={sx}>
      <DatePicker
        // TODO use locale of environment
        disabled={disabled}
        locale={locale}
        selected={value && value.toDate()}
        startDate={startDate ? startDate.toDate() : undefined}
        endDate={endDate ? endDate.toDate() : undefined}
        dateFormat={dateFormat}
        showMonthYearPicker={showMonthYearPicker}
        onChange={date => {
          onChange(formatDateForApi(date))
        }}
        placeholderText={placeholder || ''}
        customInput={
          <DateInputBase
            endAdornment={
              <InputAdornment
                position="end"
                style={{ marginRight: 0, marginLeft: 0 }}
              >
                <CalendarIcon />
              </InputAdornment>
            }
          />
        }
        popperPlacement="bottom-start"
        popperModifiers={{
          addZIndex: {
            enabled: true,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                zIndex: 10,
              },
            }),
          },
          offset: {
            enabled: true,
            offset: '20px, 0px',
          },
          preventOverflow: {
            enabled: true,
          },
        }}
        fixedHeight={true}
      />
    </RootContainer>
  )
}

export default DateSelect
