import { Divider, Fab, FabProps, InputBase, styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { TextInput } from '../inputs/TextInput'
import { useCallback, useMemo } from 'react'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

export const PageRoot = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 24px',
  height: '100%',
  gap: '8px',
  position: 'relative',
})

const PageHeaderArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

type EditableFieldProps = {
  editing: boolean
}
const PageTitleArea = styled('div')(({ editing }: EditableFieldProps) => {
  const border = useMemo(() => {
    if (!editing) return 'none'
    return `1px solid ${colorPalette.monotone[2]}`
  }, [editing])
  return {
    display: 'flex',
    height: '26px',
    padding: '4px',
    alignItems: 'center',
    borderRadius: '4px',
    border,
  }
})
const PageTitle = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: 1,
})

const PageHeaderDivider = styled(Divider)({
  borderColor: colorPalette.monotone[2],
})

const LeadSentenceArea = styled('div')(({ editing }: EditableFieldProps) => {
  const border = useMemo(() => {
    if (!editing) return 'none'
    return `1px solid ${colorPalette.monotone[2]}`
  }, [editing])
  return {
    height: '50px',
    padding: '4px',
    borderRadius: '4px',
    border,
  }
})
const LeadSentence = styled('div')({
  color: colorPalette.monotone[9],
  fontSize: '14px',
  lineHeight: 1.5,
  overflowWrap: 'break-word',
  height: '100%',
  overflowY: 'scroll',
})

type PageHeaderProps = {
  pageTitle: string
  leadSentence: string | undefined
  onChangePageTitle: (v: string) => void
  onChangeLeadSentence: (v: string) => void
  editing: boolean
}
export const PageHeader = ({
  pageTitle,
  leadSentence,
  onChangePageTitle: _onChangePageTitle,
  onChangeLeadSentence: _onChangeLeadSentence,
  editing,
}: PageHeaderProps) => {
  const onChangePageTitle = useCallback(
    (v: string | undefined) => {
      _onChangePageTitle(v || '')
    },
    [_onChangePageTitle]
  )
  const onChangeLeadSentence = useCallback(
    (v: string | undefined) => {
      _onChangeLeadSentence(v || '')
    },
    [_onChangeLeadSentence]
  )
  return (
    <PageHeaderArea>
      <PageTitleArea editing={editing}>
        {!editing && <PageTitle>{pageTitle}</PageTitle>}
        {editing && (
          <TextInput
            value={pageTitle}
            onChange={onChangePageTitle}
            variant="header"
          />
        )}
      </PageTitleArea>
      <PageHeaderDivider />
      <LeadSentenceArea editing={editing}>
        {!editing && <LeadSentence>{leadSentence}</LeadSentence>}
        {editing && (
          <TextInput
            value={leadSentence}
            onChange={onChangeLeadSentence}
            variant="leadSentence"
            multiline={true}
          />
        )}
      </LeadSentenceArea>
    </PageHeaderArea>
  )
}

type EditConfigButtonProps = {
  editing: boolean
  onClick: () => void
}
export const EditConfigButton = ({
  editing,
  onClick,
}: EditConfigButtonProps) => {
  if (!editing) return <></>
  return (
    <StyledFab onClick={onClick}>
      <ConfigIcon />
    </StyledFab>
  )
}

const StyledFab = styled((props: FabProps) => <Fab {...props} size="small" />)({
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  backgroundColor: colorPalette.skyBlue[7],
  '&:hover': {
    backgroundColor: colorPalette.skyBlue[5],
  },
})

const ConfigIcon = styled(SettingsRoundedIcon)({
  fontSize: '16px',
  color: colorPalette.monotone[0],
})
