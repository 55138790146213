import { PageArea, PageProps } from '../index'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ActualWorkingHoursConfirmHeader from './components/Header'
import { DateTerm } from '../../../utils/date'
import _ from 'lodash'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import { SearchUnit } from '../../components/headers/HeaderBar/DateRangeSelector'
import { getUrlQueryObject } from '../../../utils/urls'
import { UserBasic } from '../../../domain/value-object/UserBasicVO'
import { useActualWorkingHoursData } from '../ActualWorkingHours/hooks/actualWorkingHoursData'
import { useTaskActualWorkTaskDialogState } from '../ActualWorkingHours/hooks/taskActualWorkTaskDialogState'
import { actualWorkingHoursGridOptions } from '../ActualWorkingHours/gridOptions'
import { usePageState } from './hooks/pageState'
import ActualWorkingHoursView from '../ActualWorkingHours/components/view'
import { useExcelExport } from '../ActualWorkingHours/hooks/excelExport'
import { StandardWorkingInfo } from '../ActualWorkingHours/ActualWorkingHours'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'
import { Collapse } from '@mui/material'
import ActualWorkingHoursToolbar from '../ActualWorkingHours/components/Toolbar'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'

type Props = PageProps

const ActualWorkingHoursConfirm = (props: Props) => {
  const functionUuid = props.uuid
  const urlParams = getUrlQueryObject()
  const { project } = useProjectPrivateContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [userUuid, setUserUuid] = useState<string | undefined>()
  const [userName, setUserName] = useState<string | undefined>()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [users, setUsers] = useState<UserBasic[]>([])
  const [reloadHeader, setReloadHeader] = useState<boolean>(false)
  const [runInit, setRunInit] = useState<boolean>(false)
  const [toolbar, setToolbar] = useState<ToolbarToggleValue | undefined>(
    undefined
  )
  const [standardWorkInfo, setStandardWorkInfo] = useState<StandardWorkingInfo>(
    {
      standardWorkDays: 0,
      standardWorkHours: 0,
      totalWorkHours: 0,
      totalWorkDays: 0,
      diffDays: 0,
      diffHours: 0,
    }
  )
  const {
    data,
    summaryRow,
    refresh,
    fetchUsers,
    clearData,
    getStandardWorkingInfo,
  } = useActualWorkingHoursData()
  const pageState = usePageState({
    functionUuid,
    params: urlParams,
  })
  const { taskDialogState, setTaskDialogState, openTaskActualWorkList } =
    useTaskActualWorkTaskDialogState()

  const callbackCellEdit = useCallback(() => {
    setReloadHeader(true)
  }, [])

  const gridOptions = useMemo(() => {
    return actualWorkingHoursGridOptions({
      editable: false,
      openTaskActualWorkList,
      callbackCellEdit,
    })
  }, [callbackCellEdit, openTaskActualWorkList])

  const initializeManageMode = useCallback(async () => {
    if (!project.uuid) {
      setLoading(false)
      return
    }
    const responseUsers = await fetchUsers(
      project.uuid,
      pageState.dateTerm.startDate!,
      pageState.dateTerm.endDate!
    )
    setUsers(responseUsers)
    gridOptions.context = {
      ...gridOptions.context,
      projectUuid: project.uuid,
      projectCode: project.code,
    }
    if (responseUsers.length === 0) {
      setLoading(false)
    }
  }, [project, pageState.dateTerm, fetchUsers, gridOptions])

  const refreshAll = useCallback(async () => {
    if (
      !gridOptions.api ||
      !userUuid ||
      !pageState.dateTerm.startDate ||
      !pageState.dateTerm.endDate
    ) {
      return
    }
    setLoading(true)
    try {
      await refresh(
        userUuid,
        project.uuid,
        pageState.dateTerm.startDate,
        pageState.dateTerm.endDate
      )
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        errors: new InputError(),
      }
      setLoading(false)
      setReloadHeader(true)
    }
  }, [
    gridOptions,
    refresh,
    userUuid,
    project.uuid,
    pageState.dateTerm.startDate,
    pageState.dateTerm.endDate,
  ])

  const refreshUser = useCallback(() => {
    if (users && users.length > 0) {
      setTotalCount(users.length)
      if (!userUuid) {
        setUserUuid(users[0].uuid)
        setUserName(users[0].name)
        setCurrentIndex(1)
      } else {
        const index = users.findIndex(v => v.uuid === userUuid)
        if (index < 0) {
          setUserUuid(users[0].uuid)
          setUserName(users[0].name)
          setCurrentIndex(1)
        } else {
          setCurrentIndex(index + 1)
        }
      }
    } else {
      setTotalCount(0)
      setCurrentIndex(0)
      setUserUuid(undefined)
      setUserName(undefined)
      clearData()
    }
  }, [clearData, userUuid, users])

  const onSearch = useCallback(
    async (unit: SearchUnit, term: DateTerm) => {
      const resUsers = await fetchUsers(
        project.uuid,
        term.startDate!,
        term.endDate!
      )
      setUsers(resUsers)
      if (resUsers.length === 0) {
        setUserUuid(undefined)
        setUserName(undefined)
      }
      pageState.setSearchUnit(unit)
      pageState.setDateTerm(term)
    },
    [pageState, fetchUsers, project.uuid]
  )

  const onExportExcel = useExcelExport(gridOptions, {
    dateTerm: pageState.dateTerm,
    userName,
    standardInfo: standardWorkInfo,
  })

  const onPagingUser = useCallback(
    (index: number) => {
      const nextUser = users[index - 1]
      setCurrentIndex(index)
      setUserUuid(nextUser.uuid)
      setUserName(nextUser.name)
    },
    [users]
  )

  const onChangeToolbar = useCallback(
    (value: ToolbarToggleValue | undefined) => {
      setToolbar(value)
    },
    []
  )

  useEffect(() => {
    if (!pageState.initialized || runInit) return
    gridOptions.context = {
      ...gridOptions.context,
      errors: new InputError(),
    }
    initializeManageMode()
    setRunInit(true)
  }, [gridOptions, initializeManageMode, pageState.initialized, runInit])

  useEffect(() => {
    if (
      !pageState.initialized ||
      !pageState.dateTerm ||
      !pageState.dateTerm.startDate ||
      !pageState.dateTerm.endDate ||
      !userUuid
    ) {
      return
    }
    refreshAll()
  }, [pageState.initialized, pageState.dateTerm, userUuid, refreshAll])

  useEffect(() => {
    refreshUser()
  }, [refreshUser, users])

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      userUuid,
    }
  }, [gridOptions, userUuid])

  useEffect(() => {
    if (reloadHeader) {
      const stdInfo = getStandardWorkingInfo()
      setReloadHeader(false)
      setStandardWorkInfo(stdInfo)
    }
  }, [getStandardWorkingInfo, reloadHeader])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  return (
    <PageArea>
      <ActualWorkingHoursConfirmHeader
        searchUnit={pageState.searchUnit}
        dateTerm={pageState.dateTerm}
        onSearch={onSearch}
        onClickExport={onExportExcel}
        userName={userName}
        currentIndex={currentIndex}
        totalCount={totalCount}
        onPagingUser={onPagingUser}
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        rowHeight={pageState.rowHeight}
        onChangeRowHeight={(value: number) => pageState.setRowHeight(value)}
      />
      <Collapse in={!!toolbar} timeout={100} sx={{ padding: '5px 0' }}>
        <ActualWorkingHoursToolbar
          toolbar={ToolbarToggleValue.DISPLAY}
          data={standardWorkInfo}
        />
      </Collapse>
      <ActualWorkingHoursView
        gridOptions={gridOptions}
        loading={loading}
        userUuid={userUuid}
        projectUuid={project.uuid}
        data={data}
        summaryRow={summaryRow}
        rowHeight={pageState.rowHeight}
        taskDialogState={taskDialogState}
        setTaskDialogState={setTaskDialogState}
        openTaskActualWorkList={openTaskActualWorkList}
      />
    </PageArea>
  )
}

export default projectPrivate(ActualWorkingHoursConfirm)
