import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import InfiniteScroll from 'react-infinite-scroller'
import {
  BackgroundColor,
  Color,
  FontSize,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import ManagementNoticeEntity, {
  ManagementNoticeType,
} from '../../../../domain/entity/ManagementNoticeEntity'
import { managementNoticeRepository } from '../../../../domain/repository'
import { intl } from '../../../../i18n'
import ManagementNoticeTitle from './ManagementNoticeTitle'
import { Avatar, Box, Typography } from '@mui/material'
import ManagementNoticesIcon from '../../../../assets/management_notices_icon.png'
import { colorPalette } from '../../../style/colorPallete'

interface NoticeProps {
  notice: ManagementNoticeEntity
}

const Notice: React.FC<NoticeProps> = ({ notice }: NoticeProps) => {
  const getFormatedNewsCreatedAt = (newsCreatedAt: DateTimeVO) => {
    return newsCreatedAt.toString('HH:mm')
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: BackgroundColor.WHITE,
        borderRadius: '4px',
        margin: '10px 0px 20px 0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          borderBottom: `1px solid ${colorPalette.monotone[2]}`,
        }}
      >
        <ManagementNoticeTitle noticeType={notice.type.toString()} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '15px',
        }}
      >
        <Avatar
          sx={{
            width: 32,
            height: 32,
            margin: '0 15px 0 0',
          }}
          variant="circular"
          src={ManagementNoticesIcon}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto 0 0',
          }}
        >
          <Typography
            style={{
              fontSize: '11px',
              fontWeight: FontWeight.NORMAL,
              color: Color.MONOTONE,
              letterSpacing: '0.1em',
            }}
          >
            From
          </Typography>
          <div
            style={{
              maxWidth: '120px',
              maxHeight: '34px',
              margin: '2px 0',
              fontSize: FontSize.MEDIUM,
              fontWeight: 700,
              color: TextColor.MONOTONE,
              opacity: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {intl.formatMessage({ id: 'information.flagxs.official' })}
          </div>
        </div>
        <Typography
          style={{
            color: Color.MONOTONE,
            fontSize: FontSize.MEDIUM,
            fontWeight: FontWeight.NORMAL,
            letterSpacing: '0.2em',
            margin: '0 20px 0 0',
          }}
        >
          {getFormatedNewsCreatedAt(
            new DateTimeVO(notice.publishPeriod.toPlainValue().startDateTime)
          )}
        </Typography>
      </div>
      <div style={{ padding: '0px 16px 16px 16px', color: Color.MONOTONE }}>
        <ReactMarkdown
          className={'markdown-body'}
          children={notice.message.toPlainValue()}
          linkTarget={'_blank'}
          remarkPlugins={[remarkGfm]}
        />
      </div>
    </div>
  )
}

const InformationManagementNotices = () => {
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [noticeEntities, setNoticeEntities] = useState<
    ManagementNoticeEntity[]
  >([])
  const [filteredNoticeEntities, setFilteredNoticeEntities] = useState<
    ManagementNoticeEntity[]
  >([])
  const [selectedNoticeTypes, setSelectedNoticeTypes] = useState<
    ManagementNoticeType[]
  >([])

  useEffect(() => {
    const filteredEntities = _.isEmpty(selectedNoticeTypes)
      ? noticeEntities
      : noticeEntities.filter(e => {
          return selectedNoticeTypes.includes(e.type)
        })
    setFilteredNoticeEntities(filteredEntities)
  }, [noticeEntities, selectedNoticeTypes])

  const onChangeNoticeTypes = useCallback(
    (noticeTypes: ManagementNoticeType[]) => {
      setSelectedNoticeTypes(noticeTypes)
    },
    [selectedNoticeTypes]
  )

  const loadItems = async () => {
    const notices: ManagementNoticeEntity[] =
      await managementNoticeRepository.getNotices(noticeEntities.length)
    if (notices.length < managementNoticeRepository.getDefaultFetchLimit()) {
      setHasMore(false)
    }
    setNoticeEntities([...noticeEntities, ...notices])
  }
  const dateFormat = (date: DateTimeVO): string => {
    return date.toString(intl.formatMessage({ id: 'information.news.date' }))
  }
  const managementNoticesDateCheckFormat = (date: DateTimeVO): string => {
    return date.toString('yyyy/MM/dd')
  }
  let managementNoticesDate: DateTimeVO
  let managementNoticesDateFormat: string | undefined
  let check: boolean = false

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '5px 20px',
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        loadMore={loadItems}
        hasMore={hasMore}
        useWindow={false}
        threshold={10}
      >
        {filteredNoticeEntities.map((notice, index) => {
          if (index === 0) {
            managementNoticesDate = notice.createdAt
            managementNoticesDateFormat = dateFormat(notice.createdAt)
          }
          if (index >= 1) {
            if (notice.createdAt) {
              check =
                managementNoticesDateCheckFormat(managementNoticesDate) ===
                managementNoticesDateCheckFormat(notice.createdAt)
                  ? true
                  : false
            }
            if (!check) {
              managementNoticesDateFormat = dateFormat(notice.createdAt)
              managementNoticesDate = notice.createdAt
            }
          }
          return (
            <>
              {!check && (
                <Box
                  sx={{
                    color: Color.MONOTONE,
                    fontSize: FontSize.X_LARGE,
                    fontWeight: 700,
                    letterSpacing: '0.1em',
                    marginTop: '10px',
                  }}
                >
                  {managementNoticesDateFormat}
                </Box>
              )}
              <div key={`news-box${notice.uuid}`}>
                <Notice key={notice.uuid.toPlainValue()} notice={notice} />
              </div>
            </>
          )
        })}
      </InfiniteScroll>
    </div>
  )
}

export default InformationManagementNotices
