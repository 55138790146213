import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Avatar from '@mui/material/Avatar'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Checkbox from '../editors/checkbox/Checkbox'
import SubmitButton from '../buttons/SubmitButton'
import CancelButton from '../buttons/CancelButton'

const Dialog = styled(MuiDialog)({
  margin: 'auto 0px',
  maxHeight: '70vh',
})
const Content = styled('div')({
  margin: '8px 0 0 24px',
})
const SelectIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '0 8px',
})
const Icon = styled(Avatar)({
  width: 30,
  height: 30,
})

/**
 * チェックボックス1行を表す。
 */
export interface MultiSelectDialogSelection {
  value: string
  iconUrl?: string
  displayName: string
  defaultChecked?: boolean
}

interface SelectionInternal extends MultiSelectDialogSelection {
  value: string
  iconUrl: string
  displayName: string
  defaultChecked: boolean
  checked: boolean
  disabled: boolean
}

interface Props {
  onClose: () => void
  onSubmit: (
    allList: MultiSelectDialogSelection[],
    selectedItem: MultiSelectDialogSelection[]
  ) => void
  dialogTitle: string
  excludeValues?: string[]
  getSelectList: () =>
    | Promise<MultiSelectDialogSelection[]>
    | MultiSelectDialogSelection[]
  submitButtonTitle?: string
  hideIcon?: boolean
  allCheckBoxLabel?: string
}

const MultiSelectDialog = (props: Props) => {
  const [selected, setSelected] = useState<SelectionInternal[]>([])

  const init = useCallback(async () => {
    const data = await props.getSelectList()
    const selectionInternal = data.map(v => {
      return {
        value: v.value,
        iconUrl: v.iconUrl,
        displayName: v.displayName,
        defaultChecked: v.defaultChecked,
        checked: !!v.defaultChecked,
        disabled: props.excludeValues?.includes(v.value),
      } as SelectionInternal
    })
    setSelected(selectionInternal)
  }, [])

  useEffect(() => {
    init()
  }, [])

  const submit = useCallback(() => {
    props.onSubmit(
      selected,
      selected.filter(v => v.checked)
    )
    props.onClose()
  }, [selected])

  const onClick = useCallback(
    (checked, index) => {
      const changedSelected = [...selected]
      changedSelected[index].checked = checked
      setSelected(changedSelected)
    },
    [selected]
  )

  const onClickAllCheckBox = useCallback(
    checked => {
      const changedSelected = [...selected]
      changedSelected
        .filter(v => !v.disabled)
        .forEach(v => (v.checked = checked))
      setSelected(changedSelected)
    },
    [selected]
  )

  const isAllCleared = useCallback(
    (): boolean => selected.filter(v => v.checked).length === 0,
    [selected]
  )

  const isAllClearedWithoutDisabled = useCallback(
    (): boolean =>
      selected.filter(v => !v.disabled).filter(v => v.checked).length === 0,
    [selected]
  )

  const isAllCheckedWithoutDisabled = useCallback(
    (): boolean =>
      !isAllDisabled() &&
      selected.filter(v => !v.disabled).filter(v => !v.checked).length === 0,
    [selected]
  )

  const isAllDisabled = useCallback(
    (): boolean => selected.filter(v => !v.disabled).length === 0,
    [selected]
  )

  return (
    <Dialog open={true} maxWidth="lg" onClose={props.onClose}>
      <DialogTitle>{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <Checkbox
          label={props.allCheckBoxLabel}
          color={'primary'}
          onChange={(_, checked) => onClickAllCheckBox(checked)}
          checked={isAllCheckedWithoutDisabled()}
          disabled={isAllDisabled()}
          indeterminate={
            !isAllDisabled() &&
            !isAllClearedWithoutDisabled() &&
            !isAllCheckedWithoutDisabled()
          }
        />
        <Content>
          {selected.map((item, index) => {
            return (
              <div key={`multi-select-${index}`}>
                <Checkbox
                  label={
                    <SelectIcon>
                      {!props.hideIcon && <Icon src={item.iconUrl} />}
                      <div>{item.displayName}</div>
                    </SelectIcon>
                  }
                  color={'primary'}
                  onChange={(_, checked) => onClick(checked, index)}
                  checked={item.checked}
                  disabled={item.disabled}
                />
              </div>
            )
          })}
        </Content>
      </DialogContent>
      <DialogActions>
        <SubmitButton
          title={props.submitButtonTitle}
          disabled={isAllCleared()}
          onClick={submit}
        />
        <CancelButton onClick={props.onClose} />
      </DialogActions>
    </Dialog>
  )
}

export default MultiSelectDialog
