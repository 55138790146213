import API, { APIResponse } from '../commons/api'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import { UserBasic } from './user'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import Auth from '../commons/auth'

export interface OrganizationDeltaInput {
  uuid: string
  iconUrl?: IItemDelta<string>
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
}

export interface OrganizationDetail {
  uuid: string
  lockVersion: number
  revision: string
  alias: string
  displayName: string
  officialName: string
  iconUrl: string
  defaultTimezoneId: string
  defaultLanguage: string
  emailDomainWhitelist: string
  skipEmailVerification: boolean
  maintenanceMessage: string
  dailyWorkHours: number
  monthlyWorkDays: number
  financialYearEndMonth: string
  updatedBy: UserBasic
  updatedAt: string
}

export interface TenantAuth {
  expiresIn: number
}

class Tenant implements SingleSheetRepository {
  public create = (): Promise<APIResponse> => {
    throw new Error('Not implemented')
  }

  public update = (props: unknown): Promise<APIResponse> => {
    throw new Error('Can not use tenant.update method.')
  }

  public updateDelta = (
    props: OrganizationDeltaInput
  ): Promise<APIResponse> => {
    return API.functional.request('PUT', '/api/v1/tenants/delta', props)
  }

  public getDetail = async (): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/tenants/detail',
      {
        tenantUuid: Auth.getCurrentTenant()!.tenantUuid,
      },
      true
    )
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    throw new Error('It will not be used')
  }

  public loggedIn = async (): Promise<TenantAuth> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/tenants/loggedin'
    )
    return response.json as TenantAuth
  }

  public getAuth = async (): Promise<TenantAuth> => {
    const response = await API.functional.request('GET', '/api/v1/tenants/auth')
    return response.json as TenantAuth
  }
}

export default new Tenant()
