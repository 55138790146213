import numeral from 'numeral'
import {
  ColDef,
  ValueFormatterParams,
  ValueParserParams,
} from 'ag-grid-community'
import { WbsItemAdditionalPropertyEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import { ColumnType } from '../../../../commons/AgGrid'
import { toNumber } from '../../../../../../utils/number'
import {
  ClientSideNumberFilter,
  NumberFilter,
  ServerSideNumberFilter,
} from '../../../components/filter'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'

export const numberColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  column.type = [ColumnType.number]
  column.cellClass = 'ag-numeric-cell'
  column.cellStyle = { justifyContent: 'flex-end' }
  column.valueParser = (params: ValueParserParams) => toNumber(params.newValue)
  column.valueFormatter = (params: ValueFormatterParams) => {
    const num = numeral(params.value)
    if (
      params.value === null ||
      params.value === undefined ||
      num.value?.() === null ||
      num.value?.() === undefined
    ) {
      return ''
    }
    return num.format('0,0')
  }
  setFilterParams(column, wbsItemAdditionalProperty, option)
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = ClientSideNumberFilter
      column.floatingFilter = !option.disableFloatingFilter
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideNumberFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        fetch: (v: NumberFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                operator: v.operator,
                value: v.value?.toString(),
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
