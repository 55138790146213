import { Table, TableBody } from '@mui/material'
import { styled } from '@mui/system'
import equal from 'fast-deep-equal'
import React from 'react'
import objects from '../../../../utils/objects'
import EntitySearchVO from '../../../../vo/EntitySearchVO'
import TextVO from '../../../../vo/TextVO'
import { EntityExtensionValue } from '../../meta/entityExtension'
import { ValidationError } from '../../meta/validator'
import { CellDef, CellGroupDef } from '../../meta/ViewMeta/SingleSheetViewMeta'
import DataRow from './DataRow'
import HeaderRow from './HeaderRow'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const StyledTable = styled(Table)({
  width: '100%',
  tableLayout: 'fixed',
})
const HeaderColumn = styled('col')({
  width: '130px',
})
const ValueColumn = styled('col')({
  width: '50%',
})

export abstract class TableData {
  uuid?: string
  lockVersion?: number
  revision?: TextVO

  createdAt?: TextVO
  createdBy?: EntitySearchVO
  updatedAt?: TextVO
  updatedBy?: EntitySearchVO

  extensions?: EntityExtensionValue[]

  abstract getCode(): TextVO | undefined
  abstract getName(): TextVO | undefined
}

interface Props<D extends TableData> {
  data: D
  cellDef: CellGroupDef[]
  onChange: (data: D, value: { [key: string]: any }) => void
  setError?: (key: string, value: ValidationError | undefined) => void
  initialData: D
}

interface State<D extends TableData> {}

class DataTable<D extends TableData> extends React.Component<
  Props<D>,
  State<D>
> {
  private structure: { [key: string]: CellDef[][] } = {}

  constructor(props) {
    super(props)
    this.structure = this.structCellDefs(props.cellDef)
  }

  componentDidUpdate(prevProps: Props<D>) {
    if (!equal(prevProps.cellDef, this.props.cellDef)) {
      this.structure = this.structCellDefs(this.props.cellDef)
    }
  }

  private structCellDefs = (cellGroupDefs: CellGroupDef[]) => {
    let structure = {}
    cellGroupDefs.forEach(cellGroupDef => {
      const groupId = cellGroupDef.groupId
      let cellDefStructure: CellDef[][] = []
      // sort by cellPosition
      const children = cellGroupDef.children.sort(
        (a, b) =>
          (a.cellPosition.row - b.cellPosition.row) * 100 +
          (a.cellPosition.column - b.cellPosition.column)
      )
      children.forEach((cellDef, index) => {
        const row = cellDef.cellPosition.row
        if (!cellDefStructure[row - 1]) {
          cellDefStructure[row - 1] = []
        }
        cellDefStructure[row - 1].push(cellDef)
      })
      cellDefStructure = cellDefStructure.filter(row => row.length > 0)
      objects.setValue(structure, groupId, cellDefStructure)
    })
    return structure
  }

  private onCellChange = (value: any, cellDef: CellDef) => {
    let newData = {
      ...this.props.data,
    }

    let newValueMap = cellDef.getChangeValue(value, newData, cellDef)
    this.props.onChange(newData, newValueMap)
  }
  private setCellError = (
    error: ValidationError | undefined,
    cellDef: CellDef
  ) => {
    this.props.setError && this.props.setError(cellDef.cellId, error)
  }

  render() {
    const { data, cellDef, initialData } = this.props

    return (
      <RootDiv>
        {cellDef.map(group => (
          <StyledTable
            key={`table-${group.groupId}`}
            size="small"
            aria-label="customized table"
          >
            <colgroup>
              <HeaderColumn />
              <ValueColumn />
              <HeaderColumn />
              <ValueColumn />
            </colgroup>
            <HeaderRow title={group.headerName} />
            <TableBody>
              {objects
                .getValue(this.structure, group.groupId)
                .map((row, index) => (
                  <DataRow
                    key={`${group.groupId}-${index}`}
                    data={data}
                    cellDefs={row}
                    onCellChange={this.onCellChange}
                    setCellError={this.setCellError}
                    initialData={initialData}
                  />
                ))}
            </TableBody>
          </StyledTable>
        ))}
      </RootDiv>
    )
  }
}

export default DataTable
