import { createRef, useState } from 'react'
import Loading from '../../process-state-notifications/Loading'

export interface ChartProps {
  isLoading?: boolean
}

export const ID_CHART_DIV: string = 'chart'

export const Chart = (props: ChartProps) => {
  const { isLoading = false } = props
  const [chartRef] = useState(createRef<HTMLDivElement>())

  return (
    <>
      <div id={ID_CHART_DIV} ref={chartRef} />
      <Loading isLoading={!!isLoading} elem={chartRef.current} />
    </>
  )
}
