import api, { APIResponse } from '../../../lib/commons/api'
import { UserBasic } from '../../../lib/functions/user'
import { generateUuid } from '../../../utils/uuids'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { LedgerAccountsTree } from '../LedgerAccounts/ledgerAccounts'

export const BudgetResultType = {
  BudgetaryAmount: 'BUDGETARY_AMOUNT',
  Budget: 'BUDGET',
  Result: 'RESULT',
  Forecast: 'FORECAST',
} as const

export const AccountCategory = {
  OperatingRevenue: 'OPERATING_REVENUE',
  OperatingExpenses: 'OPERATING_EXPENSES',
} as const

export type BudgetResultTypeType =
  (typeof BudgetResultType)[keyof typeof BudgetResultType]

export const RowFilterKey = {
  Scheduled: 'SCHEDULED',
  Actual: 'ACTUAL',
  WorkMonth: 'WORK_MONTH',
  Amount: 'AMOUNT',
  Forecast: 'FORECAST',
  BudgetaryAmount: 'BUDGETARY_AMOUNT',
} as const

export type RowFilterKeyType = (typeof RowFilterKey)[keyof typeof RowFilterKey]

export const RowStyle = {
  Tree: 'TREE',
  Simple: 'SIMPLE',
} as const

export type RowStyleType = (typeof RowStyle)[keyof typeof RowStyle]

export class ProfitLossSkeletonBase {
  uuid: string
  lockVersion: number
  rootUuid: string

  financialState: LedgerAccountsTree | undefined
  generalLedger: LedgerAccountsTree | undefined
  subsidiary: LedgerAccountsTree | undefined

  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

export class ProfitLossItemSkeleton
  extends ProfitLossSkeletonBase
  implements TreeSource<ProfitLossItemSkeleton>
{
  children: ProfitLossItemSkeleton[]

  profitLossItemName: string
  remarks: string
  budgetaryAmounts: ProfitLossItemAmountBody[] // 予算
  budgetAmounts: ProfitLossItemAmountBody[] // 予定
  resultAmounts: ProfitLossItemAmountBody[] // 実績
}

export interface ProfitLossItemAmountBody {
  budgetResultType: string
  month?: string
  amount?: number
  manualEdited?: boolean
}

export interface ProfitLossRowBase<T extends ProfitLossRowBodyBase>
  extends TreeRow {
  body: T
  isTotal?: boolean
}

export class ProfitLossRowBodyBase {
  type: string
  financialState: LedgerAccountsTree | undefined
  generalLedger: LedgerAccountsTree | undefined
  subsidiary: LedgerAccountsTree | undefined

  constructor(src?: ProfitLossSkeletonBase, type?: string) {
    this.financialState = src?.financialState
    this.generalLedger = src?.generalLedger
    this.subsidiary = src?.subsidiary
    this.type = type ?? ''
  }
}

export interface ProfitLossItemRow
  extends ProfitLossRowBase<ProfitLossItemRowBody> {}

export class ProfitLossItemRowBody extends ProfitLossRowBodyBase {
  name: string
  remarks: string
  amounts: ProfitLossItemAmountRowBody[]

  constructor(src?: ProfitLossItemSkeleton, type?: string) {
    super(src, type)
    this.name = src?.profitLossItemName ?? ''
    this.remarks = src?.remarks ?? ''
    this.amounts = []
  }
}

export class ProfitLossItemAmountRowBody {
  yearMonth?: string
  amount?: number

  constructor(src: ProfitLossItemAmountBody) {
    this.yearMonth = src.month
    this.amount = src.amount
  }
}

export const fetchProfitLossItems = async (params: {
  projectUuid: string
  startDate: string
  endDate: string
  accountCategory: string
}): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/profit_loss_items', params)
}

export interface ProfitLossItemsUpdateParams {
  uuid: string | undefined
  lockVersion: number
  projectUuid: string
  startDate: string
  endDate: string
  accountCategory: string
  profitLossItems: ProfitLossItemInput[]
}

export interface ProfitLossItemInput {
  uuid: string
  profitLossItemName: string
  financialStatementUuid: string
  generalLedgerUuid: string
  subsidiaryUuid: string | undefined
  prevSiblingUuid: string | undefined
  profitLossItemAmounts: ProfitLossItemAmountInput[]
}

export interface ProfitLossItemAmountInput {
  budgetResultType: string
  year: number
  month: number
  amount: number
}

export const updateItems = async (
  params: ProfitLossItemsUpdateParams
): Promise<APIResponse> => {
  return api.functional.request(
    'POST',
    '/api/v1/profit_loss_items/batch',
    params
  )
}

export const createNewProfitLossItemRow = (
  financialState: LedgerAccountsTree | undefined,
  generalLedger: LedgerAccountsTree | undefined
) => {
  const rootUuid = generateUuid()
  const budgetaryAmountUuid = generateUuid()
  const budgetUuid = generateUuid()
  const resultUuid = generateUuid()
  const rootRow: ProfitLossItemRow = {
    uuid: rootUuid,
    added: true,
    body: new ProfitLossItemRowBody({
      financialState: financialState,
      generalLedger: generalLedger,
    } as unknown as ProfitLossItemSkeleton),
    treeValue: [rootUuid],
  }
  const budgetaryAmountRow: ProfitLossItemRow = {
    uuid: budgetaryAmountUuid,
    added: true,
    body: new ProfitLossItemRowBody(
      {
        financialState: financialState,
        generalLedger: generalLedger,
      } as unknown as ProfitLossItemSkeleton,
      BudgetResultType.BudgetaryAmount
    ),
    treeValue: [rootUuid, budgetaryAmountUuid],
  }
  const budgetRow: ProfitLossItemRow = {
    uuid: budgetUuid,
    added: true,
    body: new ProfitLossItemRowBody(
      {
        financialState: financialState,
        generalLedger: generalLedger,
      } as unknown as ProfitLossItemSkeleton,
      BudgetResultType.Budget
    ),
    treeValue: [rootUuid, budgetUuid],
  }
  const resultRow: ProfitLossItemRow = {
    uuid: resultUuid,
    added: true,
    body: new ProfitLossItemRowBody(
      {
        financialState: financialState,
        generalLedger: generalLedger,
      } as unknown as ProfitLossItemSkeleton,
      BudgetResultType.Result
    ),
    treeValue: [rootUuid, resultUuid],
  }
  return [rootRow, budgetaryAmountRow, budgetRow, resultRow]
}
