import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { Box, Button, InputBase } from '@mui/material'
import DatePicker from 'react-datepicker'
import DateTimeVO from '../../../../../vo/DateTimeVO'

export const DateTimeCellEditor = forwardRef(
  (props: ICellEditorParams, ref) => {
    const [value, setValue] = useState<Date | undefined>(
      props.value ? new DateTimeVO(props.value).getValue() : undefined
    )

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      inputRef.current?.focus()
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value ? new DateTimeVO(value).serialize() : undefined
        },
      }
    })

    const onChange = useCallback((date: Date) => {
      setValue(date)
    }, [])

    const onClose = useCallback(() => {
      const { api } = props
      let focusedCell = api.getFocusedCell()
      api.clearFocusedCell()
      api.setFocusedCell(focusedCell!.rowIndex, focusedCell!.column)
    }, [])

    return (
      <DatePicker
        open={true}
        selected={value}
        dateFormat={'yyyy/MM/dd HH:mm:ss'}
        onChange={onChange}
        onCalendarClose={() => onClose()}
        showTimeInput={true}
        disabledKeyboardNavigation={true}
        shouldCloseOnSelect={false}
        customInput={<InputBase inputRef={inputRef} />}
        calendarContainer={({ className, children }) => (
          <Box className={className}>
            {children}
            <Button
              sx={{
                display: 'block',
                width: '100%',
                borderTop: '1px solid #aeaeae',
                borderRadius: 0,
                backgroundColor: '#f0f0f0',
              }}
              onClick={() => onClose()}
            >
              OK
            </Button>
          </Box>
        )}
      />
    )
  }
)
