import { useCallback } from 'react'
import {
  MonthlyResourceBalanceVO,
  ResourceBalanceByTeamVO,
} from '../../../domain/value-object/ResourceBalanceVO'
import { useResourceBalanceRepository } from '../../../services/adaptors/resourceBalanceRepository'

type GetResourceBalance = {
  getResourceBalanceFromNowOn: (
    projectUuid: string
  ) => Promise<ResourceBalanceByTeamVO[]>
  getMonthlyResourceBalance: (
    projectUuid: string,
    teamUuid: string | undefined
  ) => Promise<MonthlyResourceBalanceVO[]>
  getMonthlyTotalResourceBalance: (
    projectUuid: string
  ) => Promise<MonthlyResourceBalanceVO[]>
}

export const useGetResourceBalance = (): GetResourceBalance => {
  const repository = useResourceBalanceRepository()

  const getResourceBalanceFromNowOn = useCallback(
    (projectUuid: string) =>
      repository.getResourceBalanceFromNowOn(projectUuid),
    [repository]
  )
  const getMonthlyResourceBalance = useCallback(
    (projectUuid: string, teamUuid: string | undefined) =>
      repository.getMonthlyResourceBalance(projectUuid, teamUuid),
    [repository]
  )
  const getMonthlyTotalResourceBalance = useCallback(
    (projectUuid: string) =>
      repository.getMonthlyTotalResourceBalance(projectUuid),
    [repository]
  )

  return {
    getResourceBalanceFromNowOn,
    getMonthlyResourceBalance,
    getMonthlyTotalResourceBalance,
  }
}
