import VOBase from '../base'

export default class MultiLineTextVO extends VOBase {
  private readonly value: string

  constructor(_value: string) {
    super()
    this.value = _value
  }
  getValue = () => {
    return this.value
  }
  format = () => {
    return this.value
  }
  serialize = () => this.value
}
