/* eslint-disable no-underscore-dangle */
import { Amplify, Auth as AwsAuth, Hub } from 'aws-amplify'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import lazyStoreLoader from '../../store/lazyStoreLoader'
import { logoutUser, receivedUser } from '../../store/user'
import API from './api'
import { isLocalhost, isSameHost } from '../../utils/urls'
import User, { UserProps } from '../functions/user'
import Role, { ApplicationScope, Operation, ScopeType } from '../functions/role'
import Tenant, { OrganizationDetail, TenantAuth } from '../functions/tenant'
import { receivedMaintenanceMessage, receivedTenant } from '../../store/tenant'
import { intl } from '../../i18n'
import { ExternalServiceCode } from '../functions/idProvider'
import { showAlert } from '../../store/globalAlert'
import {
  endReportTenantInfo,
  endReportUserInfo,
  startReportTenantInfo,
  startReportUserInfo,
} from '../../utils/monitoring'
import project from '../functions/project'
import { AuthError } from '../../error/AuthError'

// TODO start: Remove the following process and upgrade aws-amplify to the new version merged this PR(https://github.com/aws-amplify/amplify-js/pull/10755).
// Because aws-amplify(version is newer than 4.2.2) has the bug that aws-amplify makes users redirect to "/loggedin" URL if "code" exists in URL.
// @ts-ignore
const _handleAuthResponse = AwsAuth._handleAuthResponse.bind(AwsAuth)
// @ts-ignore
AwsAuth._handleAuthResponse = (url: string) => {
  const auth = AwsAuth.configure()
  const oauthOption = (auth as AuthOptions).oauth
  if (oauthOption && !url.startsWith(oauthOption.redirectSignIn)) {
    return
  }
  return _handleAuthResponse(url)
}
// TODO end

const KEY_CURR_TENANT = 'Auth.tenant'
const KEY_CURR_USER = 'Auth.user'
const KEY_CURR_ORGANIZATION = 'Auth.organization'
const KEY_LAST_ISSUED_AT = 'Auth.lastIssuedAt'
const KEY_TEMPORARY_PASSWORD = 'Auth.temporaryPassword'
const KEY_LOGIN_WITH_EXTERNAL_SERVICE = 'Auth.loginWithExternalService'

export type PasswordPolicy = {
  minLength: number
  requireUppercase: boolean
  requireLowercase: boolean
  requireNumber: boolean
  requireSymbol: boolean
  temporaryPasswordValidityDays: number
}
type TenantId = {
  uuid: string
  alias: string
  domain: string
  poolId: string
  clientId: string
  clientPhrase: string
  skipAgreement: boolean
}

type UserInfo = UserProps & {
  scopes: ApplicationScope[]
}

const AuthErrorMessage = {
  TENANT_NOT_FOUND: intl.formatMessage({ id: 'auth.error.notFoundTenant' }),
  NOT_AUTHORIZED: intl.formatMessage({
    id: 'auth.error.failedToAuthenticated',
  }),
  INCORRECT_NAME_OR_PASSWORD: intl.formatMessage({
    id: 'auth.error.incorrectEmailOrPassword',
  }),
  LIMIT_EXCEEDED: intl.formatMessage({ id: 'auth.error.limitExceeded' }),
  CODE_MISMATCH: intl.formatMessage({ id: 'auth.error.invalidAuthCode' }),
  EXPIRED_CODE: intl.formatMessage({ id: 'auth.error.codeExpired' }),
}

export enum AuthErrorCode {
  SESSION_EXPIRED = 'SESSION_EXPIRED',
}

export enum ConfirmRegistrationStatus {
  SUCCESS = 'SUCCESS',
  LINK_EXPIRED = 'LINK_EXPIRED',
  ALREADY_AUTH = 'ALREADY_AUTH',
  TOKEN_MISMATCH = 'TOKEN_MISMATCH',
  USER_NOT_EXISTS = 'USER_NOT_EXISTS',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
}

type AuthOptions = {
  tenantUuid: string
  alias: string
  skipAgreement: boolean
  region: string
  rootUrl: string
  userPoolWebClientId: string
  clientPhrase: string
  userPoolId: string
  oauth: {
    domain: string
    scope: string[]
    redirectSignIn: string
    redirectSignOut: string
    responseType: string
  }
}

let currentTenant: Auth | undefined
let redirectToAfterLogin: string | undefined

export const changedCurrentAuthorization = (event): boolean => {
  return (
    (!event.key && !event.oldValue && !event.newValue) ||
    ([KEY_CURR_TENANT, KEY_CURR_USER].includes(event.key) &&
      !!event.oldValue &&
      !event.newValue)
  )
}

const ensureLoggedin = async (session: CognitoUserSession) => {
  const lastIssuedAt = localStorage.getItem(KEY_LAST_ISSUED_AT)
  if (
    !lastIssuedAt ||
    Number(lastIssuedAt) !== Number(session.getIdToken().getIssuedAt())
  ) {
    localStorage.setItem(
      KEY_LAST_ISSUED_AT,
      String(session.getIdToken().getIssuedAt())
    )
    const tenant = Auth.getCurrentTenant()!
    await tenant.refreshUser()
    await tenant.refreshOrganization()
  }
}

export const clearExternalServiceCache = () => {
  localStorage.removeItem(KEY_LOGIN_WITH_EXTERNAL_SERVICE)
}

const validateExternalService = async payload => {
  localStorage.removeItem(KEY_LOGIN_WITH_EXTERNAL_SERVICE)
  const session: CognitoUserSession = payload.data['signInUserSession']
  const username: string = payload.data['username']
  const idTokenPayload = session.getIdToken().payload
  if (idTokenPayload['identities'] && idTokenPayload['identities'][0]) {
    const identity = idTokenPayload['identities'][0]
    if (identity.providerType === 'OIDC') {
      if (!idTokenPayload['email_verified']) {
        const store = await lazyStoreLoader()
        try {
          let externalServices: any[] = []
          try {
            const externalServiceResponse = await User.getUserExternalServices(
              idTokenPayload.email
            )
            externalServices = externalServiceResponse.json
          } catch (e: any) {
            if (e.code === 'NOT_FOUND') {
              store.dispatch(
                showAlert({
                  message: intl.formatMessage(
                    { id: 'login.loginWith.notFound' },
                    { email: idTokenPayload.email }
                  ),
                  action: () => {
                    window.location.href = '/loggedout'
                  },
                })
              )
              return false
            }
            throw e
          }
          if (
            externalServices.length === 0 ||
            externalServices.some(v => v.code === ExternalServiceCode.COGNITO)
          ) {
            await User.updateExternalService({
              email: idTokenPayload.email,
              externalId: username,
              externalServiceCode: ExternalServiceCode.AAD,
            })
            await login(payload)
            return false
          }
        } catch (e) {
          store.dispatch(
            showAlert({
              message: intl.formatMessage({ id: 'login.loginWith.error' }),
              action: () => {
                window.location.href = '/loggedout'
              },
            })
          )
          return false
        }
      }
    }
  }
  return true
}

const login = async payload => {
  const session: CognitoUserSession = payload.data['signInUserSession']
  await ensureLoggedin(session)
  if (redirectToAfterLogin !== window.location.href) {
    window.history.replaceState(null, '', redirectToAfterLogin)
  }
  window.location.reload()
}

Hub.listen('auth', async ({ payload }) => {
  if (payload.event === 'signIn') {
    if (!(await validateExternalService(payload))) {
      return
    }
    await login(payload)
  }
})

class Auth {
  public readonly rootUrl: string
  public readonly userPoolId: string
  public readonly tenantUuid: string
  public readonly tenantAlias: string
  public readonly skipAgreement: boolean
  private _user?: UserInfo
  private _organization?: OrganizationDetail

  private constructor(options: AuthOptions) {
    currentTenant = this
    try {
      if (!options.alias) {
        throw new Error('alias is not defined.')
      }
      Amplify.configure(options)
    } catch (e) {
      // Invalid options. Cleanup the auth state.
      this.logoutFromTenant()
      throw e
    }
    this.rootUrl = options.rootUrl
    this.userPoolId = options.userPoolId
    this.tenantUuid = options.tenantUuid
    this.tenantAlias = options.alias
    this.skipAgreement = options.skipAgreement
    localStorage.setItem(
      KEY_CURR_TENANT,
      JSON.stringify({
        uuid: options.tenantUuid,
        alias: options.alias,
        domain: options.oauth.domain,
        poolId: options.userPoolId,
        clientId: options.userPoolWebClientId,
        clientPhrase: options.clientPhrase,
        skipAgreement: options.skipAgreement,
      })
    )
  }

  static async switchTenant(alias: string): Promise<Auth> {
    const {
      json: {
        tenantUuid,
        domain,
        audience,
        clientId,
        clientPhrase,
        skipAgreement,
        maintenanceMessage,
      },
    } = await API.functional
      .request('GET', '/api/v1/tenants/select', { alias }, false)
      .catch(async err => {
        if (err.code === 'NOT_FOUND') {
          throw new AuthError('TENANT_NOT_EXIST')
        }
        throw err
      })

    const store = await lazyStoreLoader()
    if (maintenanceMessage) {
      store.dispatch(receivedMaintenanceMessage(maintenanceMessage))
    }

    return Auth.createAuth(
      tenantUuid,
      alias,
      domain,
      audience,
      clientId,
      clientPhrase,
      skipAgreement
    )
  }

  private static createAuth(
    tenantUuid: string,
    alias: string,
    domain: string,
    audience: string,
    clientId: string,
    clientPhrase: string,
    skipAgreement: boolean
  ): Auth {
    const currentUrl = new URL(window.location.href)
    let rootUrl: string
    if (isLocalhost(currentUrl.hostname)) {
      rootUrl = `${currentUrl.protocol}//${currentUrl.host}`
    } else {
      rootUrl = `${currentUrl.protocol}//${alias}${currentUrl.host.substr(
        currentUrl.host.indexOf('.')
      )}`
    }
    return new Auth({
      rootUrl,
      tenantUuid,
      alias,
      region: 'ap-northeast-1',
      userPoolWebClientId: clientId,
      clientPhrase,
      userPoolId: audience,
      skipAgreement,
      oauth: {
        domain,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: `${rootUrl}/loggedin`,
        redirectSignOut: `${rootUrl}/loggedout`,
        responseType: 'code',
      },
    })
  }

  static getCurrentTenant(): Auth | undefined {
    const tenant = JSON.parse(
      localStorage.getItem(KEY_CURR_TENANT)!
    ) as TenantId
    if (!tenant || !tenant.domain) {
      return undefined
    }
    if (currentTenant && currentTenant.userPoolId === tenant.poolId) {
      return currentTenant
    }
    return this.createAuth(
      tenant.uuid,
      tenant.alias,
      tenant.domain,
      tenant.poolId,
      tenant.clientId,
      tenant.clientPhrase,
      tenant.skipAgreement
    )
  }

  static async confirmRegistration(
    userPoolId: string,
    userUuid: string,
    oneTimeToken: string
  ) {
    const response = await API.functional.request(
      'POST',
      '/api/v1/users/confirm_registration',
      { userUuid, oneTimeToken },
      false,
      null,
      { 'x-7d-userpoolid': userPoolId }
    )
    return response.json
  }

  private async getOrRefreshCurrentSession(): Promise<CognitoUserSession> {
    try {
      const session = await AwsAuth.currentSession().catch(async err => {
        if (err.code === 'NotAuthorizedException') {
          err.code = AuthErrorCode.SESSION_EXPIRED
        }
        throw err
      })
      await ensureLoggedin(session)
      return session
    } catch (e) {
      await this.logoutFromTenant()
      throw e
    }
  }

  async getAccessToken(): Promise<string> {
    const session = await this.getOrRefreshCurrentSession()
    return session.getIdToken().getJwtToken()
  }

  async getGoogleAccessToken(): Promise<string> {
    const session = await this.getOrRefreshCurrentSession()
    return session.getIdToken().payload['custom:google_access_token']
  }

  static async login(
    username: string,
    password: string
  ): Promise<{ isTemporaryPassword: boolean }> {
    const congnitoUser = await AwsAuth.signIn(username, password).catch(
      async err => {
        switch (err.code) {
          case 'NotAuthorizedException':
            if (err.message.includes('attempts exceeded')) {
              throw new AuthError('ATTEMPT_LIMIT_EXCEEDED')
            }
            throw new AuthError('INCORRECT_EMAIL_OR_PASSWORD')
          default:
            throw err
        }
      }
    )
    if (congnitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      localStorage.setItem(KEY_TEMPORARY_PASSWORD, password) // Use for complete new password.
      return { isTemporaryPassword: true }
    }
    return { isTemporaryPassword: false }
  }

  static hasTemporaryPassword = () => {
    return !!localStorage.getItem(KEY_TEMPORARY_PASSWORD)
  }

  static async completeNewPassword(
    username: string,
    newPassword: string
  ): Promise<void> {
    const temporaryPassword = localStorage.getItem(
      KEY_TEMPORARY_PASSWORD
    ) as string
    const cognitoUser = await AwsAuth.signIn(username, temporaryPassword)
    const afterChangePassword = async () => {
      await this.registerFinal(
        cognitoUser.pool.userPoolId,
        cognitoUser.challengeParam.userAttributes['custom:user_uuid']
      )
      localStorage.removeItem(KEY_TEMPORARY_PASSWORD)
    }
    await AwsAuth.completeNewPassword(cognitoUser, newPassword, {}).catch(
      async err => {
        const session = await AwsAuth.currentSession().catch(_ => undefined)
        if (session && session.isValid()) {
          // Error but password changed successfully
          await afterChangePassword()
          Auth.login(username, newPassword)
          return
        }
        switch (err.code) {
          case 'NotAuthorizedException':
            if (err.message.includes('attempts exceeded')) {
              throw new AuthError('ATTEMPT_LIMIT_EXCEEDED')
            }
            throw new AuthError('INCORRECT_EMAIL_OR_PASSWORD')
          case 'InvalidPasswordException':
          case 'InvalidParameterException':
            throw new AuthError('NOT_SATISFY_PASSWORD_POLICY')
          default:
            throw err
        }
      }
    )
    afterChangePassword()
  }

  static async registerFinal(userPoolId: string, userUuid: string) {
    const response = await API.functional.request(
      'POST',
      '/api/v1/users/register_final',
      { uuid: userUuid },
      false,
      null,
      { 'x-7d-userpoolid': userPoolId }
    )
    return response.json
  }

  async isLoggedin(): Promise<boolean> {
    try {
      const user = this.user
      if (!user || !user.uuid) {
        return false
      }
      const session = await AwsAuth.currentSession()
      return session.isValid()
    } catch {
      return false
    }
  }

  static federatedLogin(provider: string) {
    localStorage.setItem(KEY_LOGIN_WITH_EXTERNAL_SERVICE, 'true')
    AwsAuth.federatedSignIn({ customProvider: provider })
  }

  async logout(redirectTo?: string) {
    const store = await lazyStoreLoader()
    endReportTenantInfo()
    endReportUserInfo()
    store.dispatch(logoutUser())
    redirectToAfterLogin = this.normalizeRedirectTo(redirectTo)
    this._user = undefined
    localStorage.removeItem(KEY_CURR_USER)
    localStorage.removeItem(KEY_LOGIN_WITH_EXTERNAL_SERVICE)
    await AwsAuth.signOut()
  }

  async logoutFromTenant(redirectTo?: string) {
    await this.logout(redirectTo)
    this._organization = undefined
    currentTenant = undefined
    localStorage.clear()
  }

  private normalizeRedirectTo = (redirectTo?: string) => {
    return isSameHost(window.location.href, redirectTo) ? redirectTo : undefined
  }

  static async changePassword(oldPassword: string, newPassword: string) {
    const user = await AwsAuth.currentAuthenticatedUser()
    return AwsAuth.changePassword(user, oldPassword, newPassword).catch(
      async err => {
        switch (err.code) {
          case 'NotAuthorizedException':
            throw new Error(AuthErrorMessage.NOT_AUTHORIZED)
          case 'LimitExceededException':
            throw new Error(AuthErrorMessage.LIMIT_EXCEEDED)
          case 'InvalidPasswordException':
          case 'InvalidParameterException':
            const tenant = Auth.getCurrentTenant()
            const passwordPolicy = await tenant!.getPasswordPolicy()
            throw new Error(Auth.generatePasswordPolicyMessage(passwordPolicy))
          default:
            throw err
        }
      }
    )
  }

  static forgotPassword(username: string) {
    return AwsAuth.forgotPassword(username)
  }

  static forgotPasswordSubmit(
    username: string,
    authCode: string,
    newPassword: string
  ) {
    return AwsAuth.forgotPasswordSubmit(username, authCode, newPassword).catch(
      async err => {
        switch (err.code) {
          case 'CodeMismatchException':
            throw new AuthError('RESET_CODE_MISMATCH')
          case 'LimitExceededException':
            throw new AuthError('ATTEMPT_LIMIT_EXCEEDED')
          case 'ExpiredCodeException':
            throw new AuthError('RESET_CODE_EXPIRED')
          case 'InvalidPasswordException':
          case 'InvalidParameterException':
            throw new AuthError('NOT_SATISFY_PASSWORD_POLICY')
          default:
            throw err
        }
      }
    )
  }

  get user(): UserInfo | undefined {
    if (!this._user) {
      this._user = JSON.parse(localStorage.getItem(KEY_CURR_USER)!) as UserInfo
    }
    return this._user
  }

  get organization(): OrganizationDetail | undefined {
    if (!this._organization) {
      this._organization = JSON.parse(
        localStorage.getItem(KEY_CURR_ORGANIZATION)!
      ) as OrganizationDetail
    }
    return this._organization
  }

  loggedIn = async () => {
    const tenantAuth = await Tenant.loggedIn()
    this.scheduleRefreshTenantCookie(tenantAuth)
    this.refreshUser()
    this.refreshOrganization()
  }

  refreshUser = async () => {
    const user = (await User.me()).json
    const role = (await Role.me()).json
    this._user = { ...user, scopes: role }
    startReportUserInfo(user)
    localStorage.setItem(KEY_CURR_USER, JSON.stringify(this._user))
    const store = await lazyStoreLoader()
    store.dispatch(receivedUser(user))
  }

  refreshOrganization = async () => {
    const organization = (await Tenant.getDetail()).json
    this._organization = organization
    startReportTenantInfo(organization)
    localStorage.setItem(
      KEY_CURR_ORGANIZATION,
      JSON.stringify(this._organization)
    )
    const store = await lazyStoreLoader()
    store.dispatch(receivedTenant(organization))
  }

  scheduleRefreshTenantCookie = async (tenantAuth: TenantAuth | undefined) => {
    if (!tenantAuth) return
    const _tenantAuth = await this.refreshTenantCookie()
    setTimeout(
      () => this.scheduleRefreshTenantCookie(_tenantAuth),
      tenantAuth.expiresIn * 1000
    )
  }

  refreshTenantCookie = async (): Promise<TenantAuth | undefined> => {
    const isLoggedIn = await this.isLoggedin()
    if (!isLoggedIn) {
      return undefined
    }

    return Tenant.getAuth()
  }

  getPasswordPolicy = async (): Promise<PasswordPolicy> => {
    const { json: passwordPolicy } = await API.functional.request(
      'GET',
      '/api/v1/tenants/password_policy',
      { tenantUuid: this.tenantUuid },
      false
    )

    return passwordPolicy
  }

  private static generatePasswordPolicyMessage = (
    passwordPolicy: PasswordPolicy
  ) => {
    const requiredCharactersLabel: string[] = []
    if (passwordPolicy.requireLowercase) {
      requiredCharactersLabel.push(
        intl.formatMessage({ id: 'password.policy.lowercase' })
      )
    }
    if (passwordPolicy.requireUppercase) {
      requiredCharactersLabel.push(
        intl.formatMessage({ id: 'password.policy.uppercase' })
      )
    }
    if (passwordPolicy.requireNumber) {
      requiredCharactersLabel.push(
        intl.formatMessage({ id: 'password.policy.number' })
      )
    }
    if (passwordPolicy.requireSymbol) {
      requiredCharactersLabel.push(
        intl.formatMessage({ id: 'password.policy.symbol' })
      )
    }
    if (requiredCharactersLabel.length === 0) {
      return intl.formatMessage(
        { id: 'auth.error.invalidPasswordLength' },
        { minLength: passwordPolicy.minLength }
      )
    }
    return intl.formatMessage(
      { id: 'auth.error.invalidPassword' },
      {
        minLength: passwordPolicy.minLength,
        requiredCharacters: requiredCharactersLabel.join(', '),
      }
    )
  }

  static loggedInWithExternalService() {
    return localStorage.getItem(KEY_LOGIN_WITH_EXTERNAL_SERVICE)
  }

  issueTemporaryPassword = async (minLength: number): Promise<string> => {
    const { json } = await API.functional.request(
      'GET',
      '/api/v1/tenants/issue_temporary_password',
      { minLength },
      false
    )
    return json.temporaryPassword
  }

  static checkAccountingAuth = async (
    projectUuid: string,
    externalId: string
  ): Promise<boolean> => {
    const user = this.getCurrentTenant()?.user
    if (!user) return false
    if (
      user.scopes.some(
        v =>
          (v.scopeType === ScopeType.FUNCTION &&
            v.function === Operation.ALL) ||
          v.dataType === 'ACCOUNT'
      )
    ) {
      return true
    }
    if (user.scopes.some(v => v.function === externalId)) {
      const response = await project.getAssignedProjectsByUser(user.uuid)
      const json = response.json
      return json.some(v => v.uuid === projectUuid)
    }
    return false
  }

  static isAccountingUser = (): boolean => {
    const user = this.getCurrentTenant()?.user
    return user?.scopes.some(v => v.dataType === 'ACCOUNT') ?? false
  }
}

export default Auth
