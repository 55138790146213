import { styled } from '@mui/system'
import Avatar from '@mui/material/Avatar'
import { Comment } from '../../../store/comments'
import Box from '@mui/material/Box'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  BackgroundColor,
  BorderColor,
  FontSize,
} from '../../../styles/commonStyles'
import './styles.scss'
import CommentItem from './CommentItem'
import Auth from '../../../lib/commons/auth'
import CommentHeader from './CommentHeader'

interface ComponentOwnProps {
  commentGroupId: string
  applicationFunctionUuid: string
  dataUuid?: string
  suffix?: string
  projectUuid?: string
  key: string
  comments: Comment[]
  index: any
  // Use to avoid edit more than two comments at the same time.
  checkIsMenuOpen: Function
  setIsMenuOpen: Function
  hasEditingComment: boolean
}

interface Props extends WrappedComponentProps, ComponentOwnProps {}

const RootContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  fontSize: FontSize.MEDIUM,
  padding: '0px 7px 0px 0px',
  lineHeight: 1.6,
})
const AvatarWrapper = styled(Box)({
  width: '32px',
  marginRight: '8px',
})
const StyledAvatar = styled(Avatar)({
  margin: '4px 0 0',
  width: '32px',
  height: '32px',
})
const CommentContainer = styled(Box)({
  flex: 1,
})
const Comments = styled(Box)({
  width: '100%',
  margin: '0 0 10px',
  position: 'relative',
  borderColor: BorderColor.GREY,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '10px',
  backgroundColor: BackgroundColor.WHITE,
  '&::after,&::before': {
    border: 'solid transparent',
    content: '""',
    width: '0',
    height: '0',
    position: 'absolute',
    pointerEvents: 'none',
    transform: 'skewY(20deg)',
  },
  '&::after': {
    top: '18px',
    left: '-18px',
    borderWidth: '0px 10px 9px 10px',
    borderRightColor: BorderColor.WHITE,
  },
  '&::before': {
    top: '16px',
    left: '-22px',
    borderWidth: '0px 11px 10px 11px',
    borderRightColor: BorderColor.GREY,
  },
})

class CommentGroup extends React.Component<Props> {
  render() {
    const { comments } = this.props
    if (comments.length <= 0) {
      return <></>
    }
    comments.sort((a: Comment, b: Comment) => a.createdAt - b.createdAt)
    const baseComment: Comment = comments[0]

    return (
      <RootContainer
        id={this.props.commentGroupId}
        display="flex"
        flexDirection="row"
        key={this.props.key}
      >
        <AvatarWrapper>
          <StyledAvatar
            variant="circular"
            src={baseComment.createdBy?.iconUrl}
          />
        </AvatarWrapper>
        <CommentContainer flex={1}>
          <CommentHeader comment={baseComment} />
          <Comments>
            {comments.map((v, i) => {
              return (
                <CommentItem
                  applicationFunctionUuid={this.props.applicationFunctionUuid}
                  dataUuid={this.props.dataUuid}
                  suffix={this.props.suffix}
                  projectUuid={this.props.projectUuid}
                  comment={v}
                  index={i}
                  key={`${this.props.key}-${i}`}
                  checkIsMenuOpen={this.props.checkIsMenuOpen}
                  setIsMenuOpen={this.props.setIsMenuOpen}
                  hasEditingComment={this.props.hasEditingComment}
                  isCreatedByLoginUser={
                    Auth.getCurrentTenant()?.user?.uuid === v.createdBy?.uuid
                  }
                />
              )
            })}
          </Comments>
        </CommentContainer>
      </RootContainer>
    )
  }
}
export default injectIntl(CommentGroup)
