import { useState } from 'react'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { DateBound } from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import { DateTerm } from '../../../../utils/date'
import {
  WorkLoadUnitState,
  useWorkloadUnit,
} from '../../../hooks/useWorkloadUnit'

export type AggregateRoot = {
  uuid: string
  wbsItemUuid: string
  wbsItemName: string
  scheduledDate: DateTerm
}

export type MasterScheduleParameter = {
  aggregateTarget: AggregateTarget
  setAggregateTarget: (_: AggregateTarget) => void
  aggregateField: AggregateField
  setAggregateField: (_: AggregateField) => void
  workloadUnit: WorkloadUnit
  setWorkloadUnit: (_: WorkloadUnit) => void
  aggregateBaseDate: DateBound
  setAggregateBaseDate: (_: DateBound) => void
  workloadUnitState: WorkLoadUnitState
}
export const useMasterScheduleParameter = (): MasterScheduleParameter => {
  const [aggregateTarget, setAggregateTarget] = useState<AggregateTarget>(
    WbsItemType.DELIVERABLE
  )
  const [aggregateField, setAggregateField] = useState<AggregateField>(
    AggregateField.WBS_ITEM_WORKLOAD
  )
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.HOUR
  )
  const [aggregateBaseDate, setAggregateBaseDate] = useState<DateBound>(
    DateBound.END
  )
  const workloadUnitState = useWorkloadUnit(workloadUnit)

  return {
    aggregateTarget,
    setAggregateTarget,

    aggregateField,
    setAggregateField,

    workloadUnit,
    setWorkloadUnit,

    aggregateBaseDate,
    setAggregateBaseDate,

    workloadUnitState,
  }
}
