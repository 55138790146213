import _ from 'lodash'
import { APIResponse } from '../../../../lib/commons/api'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import RefinementApi from '../../../../lib/functions/refinement'
import { TicketRow } from '../tickets'
import {
  createRowByTypeResponse,
  FetchResponseByTypeBase,
  GenerateCreateRequestParams,
  generateCreateRequest,
  TicketDataManager,
  generateUpdateDeltaBatchRequest,
  GenerateUpdateRequestParams,
  gernerateTicketDeleteRequest,
  generateUpdateWathersRequest,
  generateUpdateTagsRequest,
} from '.'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import {
  CreateTicketInput,
  TicketBasic,
  UpdateTicketDeltaInput,
} from '../../../../lib/functions/ticket'
import {
  EntityExtensionValue,
  EntityExtensionValueDelta,
} from '../../../containers/meta/entityExtension'
import {
  createDelta,
  IItemDelta,
} from '../../../../domain/value-object/ItemDeltaInputVO'
import { createNewTicketRow } from './general'
import { generateUuid } from '../../../../utils/uuids'

export interface RefinementRow extends TicketRow {
  refinementUuid: string
  refinementLockVersion: number
  taskUuid: string
  knownRefinement: boolean
  originalRefinement: TicketBasic
  detectionPhase: string
  environment: string
  domain: string
  featureType: string
  feature: string
  defectEvent: string
  occurrenceProcedure: string
  testSpecification: string
  testCase: string
  importance: string
  impact: string
  desiredDeliveryDate: string
  cause: string
  directCause: string
  rootCause: string
  internalManagement: boolean
  properDetectionPhase: string
  interimAction: string
  permanentAction: string
  documentsToBeCorrected: string
  horizontalDeploymentTarget: boolean
  horizontalDeploymentContent: string
}

export interface FetchRefinementResponse extends FetchResponseByTypeBase {
  taskUuid: string
  knownRefinement: boolean
  originalRefinement: TicketBasic
  detectionPhase: string
  environment: string
  domain: string
  featureType: string
  feature: string
  defectEvent: string
  occurrenceProcedure: string
  testSpecification: string
  testCase: string
  importance: string
  impact: string
  desiredDeliveryDate: string
  cause: string
  directCause: string
  rootCause: string
  internalManagement: boolean
  properDetectionPhase: string
  interimAction: string
  permanentAction: string
  documentsToBeCorrected: string
  horizontalDeploymentTarget: boolean
  horizontalDeploymentContent: string
}

type CreateRefinementInput = {
  knownRefinement: boolean
  originalRefinementTicketUuid: string
  detectionPhase: string
  environment: string
  domain: string
  featureType: string
  feature: string
  defectEvent: string
  occurrenceProcedure: string
  testSpecification: string
  testCase: string
  importance: string
  impact: string
  desiredDeliveryDate: string
  cause: string
  directCause: string
  rootCause: string
  internalManagement: boolean
  properDetectionPhase: string
  interimAction: string
  permanentAction: string
  documentsToBeCorrected: string
  horizontalDeploymentTarget: boolean
  horizontalDeploymentContent: string

  ticket: CreateTicketInput
  extensions?: EntityExtensionValue[]
}

export type UpdateRefinementDeltaInput = {
  uuid: string
  knownRefinement?: IItemDelta<boolean>
  originalRefinementTicketUuid?: IItemDelta<string>
  detectionPhase?: IItemDelta<string>
  environment?: IItemDelta<string>
  domain?: IItemDelta<string>
  featureType?: IItemDelta<string>
  feature?: IItemDelta<string>
  defectEvent?: IItemDelta<string>
  occurrenceProcedure?: IItemDelta<string>
  testSpecification?: IItemDelta<string>
  testCase?: IItemDelta<string>
  importance?: IItemDelta<string>
  impact?: IItemDelta<string>
  desiredDeliveryDate?: IItemDelta<string>
  cause?: IItemDelta<string>
  directCause?: IItemDelta<string>
  rootCause?: IItemDelta<string>
  internalManagement?: IItemDelta<boolean>
  properDetectionPhase?: IItemDelta<string>
  interimAction?: IItemDelta<string>
  permanentAction?: IItemDelta<string>
  documentsToBeCorrected?: IItemDelta<string>
  horizontalDeploymentTarget?: IItemDelta<boolean>
  horizontalDeploymentContent?: IItemDelta<string>

  ticket: UpdateTicketDeltaInput
  extensions: EntityExtensionValueDelta[]
}

const getUuid = (row: TicketRow): string => {
  const refinementRow: RefinementRow = row as RefinementRow
  return refinementRow.refinementUuid
}

const getDetail = async (params: { uuid: string }): Promise<APIResponse> =>
  RefinementApi.getDetail(params)

const createRowByResponse = (
  response: any,
  dailyWorkHours: number,
  monthlyWorkDays: number,
  extensionProperties: FunctionProperty[] | undefined
): RefinementRow => {
  return {
    ...createRowByTypeResponse<RefinementRow, FetchRefinementResponse>(
      response,
      dailyWorkHours,
      monthlyWorkDays,
      extensionProperties
    ),
    refinementUuid: response.uuid,
    refinementLockVersion: response.lockVersion,
  }
}

const createUpdateDeltaBatchRequest = (
  projectUuid: string,
  originalData: TicketRow[],
  currentData: TicketRow[],
  deletedRows: TicketRow[]
) => {
  const changed = currentData.filter(v => v.added || v.edited)

  const added = generateCreateRequest<RefinementRow, CreateRefinementInput>(
    projectUuid,
    currentData,
    changed,
    ({
      row,
      ticketRequest,
      extensionInput,
    }: GenerateCreateRequestParams<RefinementRow>):
      | CreateRefinementInput
      | undefined => {
      if (!row || !ticketRequest) return
      return {
        knownRefinement: row.knownRefinement,
        originalRefinementTicketUuid: row.originalRefinement?.uuid,
        detectionPhase: row.detectionPhase,
        environment: row.environment,
        domain: row.domain,
        featureType: row.featureType,
        feature: row.feature,
        defectEvent: row.defectEvent,
        occurrenceProcedure: row.occurrenceProcedure,
        testSpecification: row.testSpecification,
        testCase: row.testCase,
        importance: row.importance,
        impact: row.impact,
        desiredDeliveryDate: row.desiredDeliveryDate,
        cause: row.cause,
        directCause: row.directCause,
        rootCause: row.rootCause,
        internalManagement: row.internalManagement,
        properDetectionPhase: row.properDetectionPhase,
        interimAction: row.interimAction,
        permanentAction: row.permanentAction,
        documentsToBeCorrected: row.documentsToBeCorrected,
        horizontalDeploymentTarget: row.horizontalDeploymentTarget,
        horizontalDeploymentContent: row.horizontalDeploymentContent,
        ticket: ticketRequest,
        extensions: extensionInput,
      }
    }
  )

  const edited = generateUpdateDeltaBatchRequest<
    RefinementRow,
    UpdateRefinementDeltaInput
  >(
    originalData,
    currentData,
    changed,
    ({
      originalRow,
      row,
      ticketRequest,
      extensionInput,
    }: GenerateUpdateRequestParams<RefinementRow>):
      | UpdateRefinementDeltaInput
      | undefined => {
      if (!row || !ticketRequest) return
      return {
        uuid: row.refinementUuid,
        knownRefinement: createDelta(
          originalRow.knownRefinement,
          row.knownRefinement
        ),
        originalRefinementTicketUuid: createDelta(
          originalRow.originalRefinement?.uuid,
          row.originalRefinement?.uuid
        ),
        detectionPhase: createDelta(
          originalRow.detectionPhase,
          row.detectionPhase
        ),
        environment: createDelta(originalRow.environment, row.environment),
        domain: createDelta(originalRow.domain, row.domain),
        featureType: createDelta(originalRow.featureType, row.featureType),
        feature: createDelta(originalRow.feature, row.feature),
        defectEvent: createDelta(originalRow.defectEvent, row.defectEvent),
        occurrenceProcedure: createDelta(
          originalRow.occurrenceProcedure,
          row.occurrenceProcedure
        ),
        testSpecification: createDelta(
          originalRow.testSpecification,
          row.testSpecification
        ),
        testCase: createDelta(originalRow.testCase, row.testCase),
        importance: createDelta(originalRow.importance, row.importance),
        impact: createDelta(originalRow.impact, row.impact),
        desiredDeliveryDate: createDelta(
          originalRow.desiredDeliveryDate,
          row.desiredDeliveryDate
        ),
        cause: createDelta(originalRow.cause, row.cause),
        directCause: createDelta(originalRow.directCause, row.directCause),
        rootCause: createDelta(originalRow.rootCause, row.rootCause),
        internalManagement: createDelta(
          originalRow.internalManagement,
          row.internalManagement
        ),
        properDetectionPhase: createDelta(
          originalRow.properDetectionPhase,
          row.properDetectionPhase
        ),
        interimAction: createDelta(
          originalRow.interimAction,
          row.interimAction
        ),
        permanentAction: createDelta(
          originalRow.permanentAction,
          row.permanentAction
        ),
        documentsToBeCorrected: createDelta(
          originalRow.documentsToBeCorrected,
          row.documentsToBeCorrected
        ),
        horizontalDeploymentTarget: createDelta(
          originalRow.horizontalDeploymentTarget,
          row.horizontalDeploymentTarget
        ),
        horizontalDeploymentContent: createDelta(
          originalRow.horizontalDeploymentContent,
          row.horizontalDeploymentContent
        ),
        ticket: ticketRequest,
        extensions: extensionInput ?? [],
      }
    }
  )

  const deleted = gernerateTicketDeleteRequest(
    deletedRows,
    (row: TicketRow) => {
      const refinementRow = row as RefinementRow
      return {
        uuid: refinementRow.refinementUuid,
        lockVersion: refinementRow.refinementLockVersion,
      }
    }
  )
  const watchers = generateUpdateWathersRequest(originalData, changed)
  const tags = generateUpdateTagsRequest(originalData, changed)

  return {
    added,
    edited,
    deleted,
    watchers,
    tags,
  }
}

export const createNewRefinementRow = (
  ticketList: TicketListBasic
): RefinementRow => {
  const newTicketRow = createNewTicketRow(ticketList)
  return {
    refinementUuid: generateUuid(),
    refinementLockVersion: 0,
    taskUuid: newTicketRow.wbsItem!.uuid!,
    ...newTicketRow,
  } as RefinementRow
}

export const getRefinementTicketDataManager = (): TicketDataManager => {
  return {
    getUuid,
    getByTicketListUuid: RefinementApi.getByTicketListUuid,
    getDetail,
    createRowByResponse,
    updateBatchDelta: RefinementApi.updateBatchDelta,
    createUpdateDeltaBatchRequest,
    createNewRow: createNewRefinementRow,
  }
}
