import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterFooter, FilterSelect } from '../common'
import { useDateFilter } from './useDateFilter'
import DateInput from '../../../../../components/editors/input/DateInput'
import { Box } from '@mui/material'
import { dateVoService } from '../../../../../../domain/value-object/DateVO'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'

export type DateFilter = {
  operator: DateFilterOperator
  value: string
}

export enum DateFilterOperator {
  EQUALS = 'EQUALS',
  BLANK = 'BLANK',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
}

type Props = IFilterParams & {
  fetch: (v: DateFilter) => Promise<APIResponse>
}

export const ServerSideDateFilter = forwardRef(
  ({ fetch, filterChangedCallback }: Props, ref) => {
    const hasRequiredSaveData = useSelector<AllState>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

    // State
    const filter = useDateFilter()
    const [loading, isLoading] = useState(false)
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
      undefined
    )

    const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
    useEffect(() => {
      if (addedRowIds.current) return
      addedRowIds.current = []
    }, [])
    const fetchFilteredIds = async (v: DateFilter) => {
      const response = await fetch(v)
      setFilteredIds(response.json)
    }
    const search = useCallback(
      _.debounce(async (v: DateFilter) => {
        try {
          isLoading(true)
          await fetchFilteredIds(v)
        } finally {
          isLoading(false)
        }
      }, 300),
      []
    )

    // Ag-grid custom filter
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params: IDoesFilterPassParams) {
          // Don't filter new rows
          if (params.data.added) {
            if (addedRowIds.current && params.node.id) {
              addedRowIds.current.push(params.node.id)
            }
            return true
          }
          if (
            addedRowIds.current &&
            params.node.id &&
            addedRowIds.current.includes(params.node.id)
          ) {
            return true
          }
          if (!filteredIds) return true
          return params.node.id && filteredIds.includes(params.node.id)
        },

        isFilterActive() {
          return !!filteredIds
        },

        getModel() {
          return !!filteredIds ? filter.model() : undefined
        },

        setModel(model?: {
          operator: DateFilterOperator
          value: string | undefined
        }) {
          if (!model) {
            filter.reset()
            return
          }
          filter.setOperator(model.operator)
          filter.setValue(
            model.value ? dateVoService.construct(model.value) : undefined
          )
        },

        getModelAsString() {
          return filter.getModelAsString()
        },

        onNewRowsLoaded() {
          if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
          const filterModel = filter.model()
          if (filterModel) {
            fetchFilteredIds(filterModel)
          }
        },
      }
    })

    // Filter
    useEffect(() => {
      if (!filter.isActive()) {
        setFilteredIds(undefined)
        return
      }
      search(filter.model())
    }, [filter.operator, filter.value])

    useEffect(() => {
      const filterModel = filter.model()
      const isAfterSavedOrRefreshed =
        prevHasRequiredSaveData && !hasRequiredSaveData
      if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
        fetchFilteredIds(filterModel).then(() => {
          addedRowIds.current = []
        })
      }
    }, [hasRequiredSaveData])

    useEffect(() => {
      filterChangedCallback()
    }, [filteredIds])

    // Event
    const onOperatorChange = useCallback(v => filter.setOperator(v), [])
    const onDateChange = useCallback(v => filter.setValue(v), [])
    const clearFilter = useCallback(() => filter.reset(), [])

    return (
      <BulkSheetFilter>
        <Box sx={{ display: 'flex' }}>
          <FilterSelect
            variant="outlined"
            value={filter.operator}
            onChange={e =>
              onOperatorChange(e.target.value as DateFilterOperator)
            }
            sx={{ width: '100px' }}
          >
            {Object.values(DateFilterOperator).map(v => (
              <option key={`text-filter-option-${v}`} value={v}>
                {toLabel(v)}
              </option>
            ))}
          </FilterSelect>
          <Box sx={{ width: '110px', margin: '0 5px' }}>
            <DateInput value={filter.value} onChange={onDateChange} />
          </Box>
        </Box>
        <FilterFooter loading={loading} onClick={clearFilter} />
      </BulkSheetFilter>
    )
  }
)

const toLabel = (value: string) => {
  return `${value.charAt(0)}${_.lowerCase(value.slice(1))}`
}
