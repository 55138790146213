import { FunctionProperty } from '../../../../lib/commons/appFunction'
import validator, { ValidationError } from '../../meta/validator'
import objects from '../../../../utils/objects'
import ViewMeta from '../../meta/ViewMeta'
import { TableData } from '.'
import VOBase from '../../../../vo/base'

export const validate = (
  value: VOBase,
  data: TableData,
  prop: FunctionProperty,
  viewMeta: ViewMeta
): ValidationError | undefined => {
  const propertyAccessor = (externalId: string) => {
    const entityExtensionProperty = viewMeta.functionMeta.entityExtensions
      ? viewMeta.functionMeta.entityExtensions.byId.get(externalId)
      : undefined
    if (entityExtensionProperty) {
      return data.extensions
        ? data.extensions.find(
            v => v.uuid === entityExtensionProperty.entityExtensionUuid
          )
        : undefined
    }
    const functionProperty =
      viewMeta.functionMeta.properties.byId.get(externalId)
    if (!functionProperty) {
      return undefined
    }
    const field = viewMeta.makeDataPropertyName(functionProperty)
    return {
      name: functionProperty.name,
      value: objects.getValue(data, field),
    }
  }

  return validator.validate(value, data, prop, propertyAccessor)
}
