import { useCallback, useMemo } from 'react'
import {
  DailyWorkloadData,
  BurndownChartResult,
  BurndownChartResultSummary,
  ResourceSummary,
} from '../../../../domain/value-object/BurndownChart'
import {
  EMPTY_SUMMARY,
  BurndownChartResourceSummary,
  BurndownChartSummary,
} from '../model'
import { WbsPivotValue } from '../../../../domain/value-object/WbsPivot'

export const useBurndownChartSummary = (
  src: BurndownChartResult | undefined,
  getValue: (v: WbsPivotValue) => number,
  getWorkloadValue: (v: number) => number
): {
  summary: BurndownChartSummary
} => {
  const summary = useMemo(
    () => mapSummary(src?.summary, getValue, getWorkloadValue),
    [getValue, getWorkloadValue, src]
  )
  return { summary }
}

const mapSummary = (
  src: BurndownChartResultSummary | undefined,
  getValue: (v: WbsPivotValue) => number,
  getWorkloadValue: (v: number) => number
): BurndownChartSummary => {
  if (!src) return EMPTY_SUMMARY

  const total = getValue(src.total)
  const planned = getValue(src.planned)
  const completed = getValue(src.completed)
  const delayed = getValue(src.delayed)
  const preceding = getValue(src.preceding)
  const unplanned = getValue(src.unplanned)
  const remaining = total - completed
  const actualCost = getWorkloadValue(src.actualCost)
  const plannedProgressRate = planned / total
  const progressRate = completed / total
  return {
    total,
    planned,
    completed,
    delayed,
    preceding,
    unplanned,
    remaining,
    actualCost,
    plannedProgressRate,
    progressRate,
  }
}

export const useResourceSummary = (
  resourceData: DailyWorkloadData[],
  summary: BurndownChartSummary,
  getWorkloadValue: (v: number) => number
): BurndownChartResourceSummary => {
  const resourceSummary = aggregateResourceData(resourceData)
  const velocity = summary.completed / summary.actualCost
  const totalResource = getWorkloadValue(resourceSummary.total)
  const devotedResource = getWorkloadValue(resourceSummary.devoted)
  const remainingResource = getWorkloadValue(resourceSummary.remaining)
  const resourceDevotionRate = summary.actualCost / devotedResource
  return {
    velocity,
    totalResource,
    devotedResource,
    remainingResource,
    resourceDevotionRate,
  }
}

const aggregateResourceData = (
  resourceData: DailyWorkloadData[]
): ResourceSummary => {
  const total = resourceData.reduce((sum, curr) => sum + curr.value, 0)
  const devoted = resourceData
    .filter(d => d.date < new Date())
    .reduce((sum, curr) => sum + curr.value, 0)
  const remaining = resourceData
    .filter(d => d.date >= new Date())
    .reduce((sum, curr) => sum + curr.value, 0)
  return {
    total,
    devoted,
    remaining,
  }
}
