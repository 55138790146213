import { useCallback } from 'react'
import { BurndownChartRepository } from '../../applications/ports/burndownChartRepository'
import {
  BurndownChartDiffDetailRequest,
  BurndownChartDiffDetailResult,
  BurndownChartGetCalendarRequest,
  BurndownChartGetResourceRequest,
  BurndownChartRequest,
  BurndownChartResult,
  BurndownChartSimulateEndDateRequest,
  BurndownChartSimulateVelocityRequest,
  BurndownChartValueDetailRequest,
  BurndownChartValueDetailResult,
} from '../../domain/value-object/BurndownChart'
import { WbsPivotAggregationDomain } from '../../domain/value-object/WbsPivot'
import api from '../../lib/commons/api'
import * as d3 from 'd3'

export const useBurndownChartRepository = (): BurndownChartRepository => {
  const getBasicInformation = useCallback(
    async (
      projectUuid: string,
      domain: WbsPivotAggregationDomain | undefined
    ) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/basic',
        { projectUuid, domain },
        true
      )
      return {
        rootName: response.json.rootName,
        scheduledDate: {
          from: response.json.scheduledDate.startDate
            ? newDateFromApiResponse(response.json.scheduledDate.startDate)
            : undefined,
          to: response.json.scheduledDate.endDate
            ? newDateFromApiResponse(response.json.scheduledDate.endDate)
            : undefined,
        },
        maxDisplayDateTerm: {
          from: newDateFromApiResponse(
            response.json.maxDisplayDateTerm.startDate
          ),
          to: newDateFromApiResponse(response.json.maxDisplayDateTerm.endDate),
        },
      }
    },
    []
  )

  const get = useCallback(
    async (request: BurndownChartRequest): Promise<BurndownChartResult> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart',
        request,
        true
      )
      return {
        summary: response.json.summary,
        scheduleData: (response.json.scheduleData || []).map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
        actualData: (response.json.actualData || []).map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
        actualCostData: (response.json.actualCostData || []).map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
      }
    },
    []
  )
  const getDiffDetail = useCallback(
    async (
      request: BurndownChartDiffDetailRequest
    ): Promise<BurndownChartDiffDetailResult> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/diff',
        request,
        true
      )
      return {
        delayedData: response.json.delayedData.map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
        precedingData: response.json.precedingData.map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
      }
    },
    []
  )
  const getValueDetail = useCallback(
    async (
      request: BurndownChartValueDetailRequest
    ): Promise<BurndownChartValueDetailResult> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/detail',
        request,
        true
      )
      return response.json
    },
    []
  )
  const getResourceData = useCallback(
    async (request: BurndownChartGetResourceRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/resource',
        request,
        true
      )
      return {
        ...response.json,
        data: (response.json.data || []).map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
      }
    },
    []
  )
  const getCalendar = useCallback(
    async (request: BurndownChartGetCalendarRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/calendar',
        request,
        true
      )
      return {
        ...response.json,
        data: (response.json.data || []).map(d => ({
          date: d.date ? newDateFromApiResponse(d.date) : undefined,
          value: d.value,
        })),
      }
    },
    []
  )
  const simulateVelocity = useCallback(
    async (request: BurndownChartSimulateVelocityRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/simulate/velocity',
        request,
        true
      )
      return {
        ...response.json,
        dependentVariable: 'VELOCITY',
      }
    },
    []
  )

  const simulateEndDate = useCallback(
    async (request: BurndownChartSimulateEndDateRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/burndown-chart/simulate/end-date',
        request,
        true
      )
      return {
        ...response.json,
        value: response.json.value
          ? newDateFromApiResponse(response.json.value)
          : undefined,
        dependentVariable: 'END_DATE',
      }
    },
    []
  )

  return {
    getBasicInformation,
    get,
    getDiffDetail,
    getValueDetail,
    getResourceData,
    getCalendar,
    simulateVelocity,
    simulateEndDate,
  }
}

const newDateFromApiResponse = (src: any) => {
  return d3.timeDay.floor(new Date(src))
}
