import React, { useEffect, useState } from 'react'
import { TenantIcon } from './TenantIcon'
import { Box } from '@mui/material'
import { ProjectIcon } from './ProjectIcon'
import DirectoryMenu from './DirectoryMenu'
import { MINIMIZED_MENU_WIDTH } from '../'
import { AllState } from '../../../../store'
import { Color } from '../../../../styles/commonStyles'
import { Cockpit, Directory } from '../../../../lib/commons/appFunction'
import { connect } from 'react-redux'
import { isProduction } from '../../../../utils/urls'
import { colorPalette } from '../../../style/colorPallete'

type Props = {
  directories: Directory[]
}
const MinimizedMenu = ({ directories }: Props) => {
  const [tenantMenu, setTenantMenu] = useState<Directory[]>([])

  useEffect(() => {
    const directory = directories.find(item => {
      return item.name === Cockpit.Tenant
    })
    if (directory) {
      setTenantMenu(directory.directories)
    }
  }, [tenantMenu, directories])

  return (
    <Box
      sx={{
        width: `${MINIMIZED_MENU_WIDTH}px`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRight: `1px solid ${colorPalette.monotone[2]}`,
      }}
    >
      <Box
        sx={{
          height: '56px',
          width: `${MINIMIZED_MENU_WIDTH}px`,
          backgroundColor: Color.FLAGXS_BLUE,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TenantIcon menu={tenantMenu} />
      </Box>
      <Box
        sx={{
          width: '40px',
          height: '56px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: `1px solid ${colorPalette.monotone[2]}`,
        }}
      >
        <ProjectIcon />
      </Box>
      <Box
        sx={{
          width: '40px',
          padding: '16px 8px 16px 8px',
          gap: '16px',
        }}
      >
        <DirectoryMenu />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  directories: state.appFunction.directories,
})

export default connect(mapStateToProps)(MinimizedMenu)
