import { useEffect, useMemo, useState } from 'react'
import * as d3 from 'd3'
import { useGetWorkReport } from '../../../../../applications/usecases/workReport/getWorkReport'
import { YearMonthVO } from '../../../../../domain/value-object/YearMonthVO'
import { NoData, WidgetAlternative, WidgetArea } from '../../components'
import { ChartContainer, XAxisWithLine, YAxis } from '../../components/chart'
import { useChartDimensions } from '../../hooks/chart/useChartDimensions'
import { TeamSelectOption } from '../../model/team'
import { MonthlyWorkReportVO } from '../../../../../domain/value-object/WorkReportVO'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import { HorizontalBarChart } from '../../components/chart/BarChart'
import { colorPalette } from '../../../../style/colorPallete'
import { CircularProgress } from '@mui/material'

type MonthlyWorkReportProps = {
  projectUuid: string
  month: YearMonthVO
  team: TeamSelectOption
}

const BAR_INTERVAL = 20

export const MonthlyWorkReport = ({
  projectUuid,
  month,
  team,
}: MonthlyWorkReportProps) => {
  const { getMonthlyWorkReport } = useGetWorkReport()
  const [data, setData] = useState<MonthlyWorkReportVO[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [noData, setNoData] = useState<boolean>(false)
  useEffect(() => {
    const fn = async () => {
      setFetching(true)
      setNoData(false)
      const monthlyWorkReport = await getMonthlyWorkReport(
        projectUuid,
        month,
        team.type === 'TEAM_TOTAL' ? undefined : team.value
      )
      const sorted = monthlyWorkReport.sort(
        (a, b) => b.actualHour - a.actualHour
      )
      setFetching(false)
      setNoData(monthlyWorkReport.length === 0)
      setData(sorted)
    }
    fn()
  }, [projectUuid, month, team])

  const height = useMemo(() => Math.max(data.length * 30, 300), [data])
  const [ref, { width, marginTop, marginLeft, boundedWidth, boundedHeight }] =
    useChartDimensions({
      height,
      marginLeft: 100,
      marginRight: 20,
      marginTop: 10,
      marginBottom: 30,
    })

  const xMax = useMemo(() => {
    return Math.max(...data.flatMap(d => [d.plannedHour, d.actualHour]))
  }, [data])
  const monthlyWorkHours = useSelector((state: AllState) => {
    const org = state.tenant.organization
    if (!org) return 160
    return org.monthlyWorkDays * org.dailyWorkHours
  })
  const xPointValues = useMemo(() => {
    const xUpperBound = 2 * monthlyWorkHours
    const xTickDiff = monthlyWorkHours / 4
    const xPointCandidates = Array.from({ length: 8 }, (_, i) => xTickDiff * i)
    const xPointMax = xPointCandidates.reduce((nearest, curr) => {
      if (Math.abs(curr - xMax) <= Math.abs(nearest - xMax)) return curr
      return nearest
    }, xUpperBound)
    return xPointCandidates.filter(x => x <= xPointMax)
  }, [monthlyWorkHours, xMax])
  const xScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, xPointValues[xPointValues.length - 1] + 10])
      .range([0, boundedWidth])
  }, [boundedWidth, xPointValues])

  const yScale = useMemo(() => {
    const userUuids = data.map(d => d.user.uuid)
    const scale = d3
      .scaleBand()
      .domain(userUuids)
      .range([BAR_INTERVAL, boundedHeight - BAR_INTERVAL])
    return (y: string) => scale(y) || 0
  }, [data, boundedHeight])

  const xPoints = useMemo(
    () =>
      xPointValues.map(x => ({
        scale: xScale(x),
        label: `${x}`,
        emphasis: x === monthlyWorkHours,
      })),
    [xPointValues, xScale, monthlyWorkHours]
  )
  const yPoints = useMemo(
    () =>
      data.map(d => ({
        scale: yScale(d.user.uuid) || 0,
        label: d.user.name,
      })),
    [data, yScale]
  )
  const points = useMemo(
    () => data.map(d => ({ x: d.actualHour, y: d.user.uuid })),
    [data]
  )

  return (
    <WidgetArea>
      <div ref={ref} style={{ height, width: '100%' }}>
        {fetching && (
          <WidgetAlternative>
            <CircularProgress />
          </WidgetAlternative>
        )}
        {!fetching && (
          <>
            {noData && (
              <WidgetAlternative>
                <NoData />
              </WidgetAlternative>
            )}
            {!noData && (
              <ChartContainer
                width={width}
                height={height}
                marginLeft={marginLeft}
                marginTop={marginTop}
              >
                <XAxisWithLine
                  points={xPoints}
                  boundedHeight={boundedHeight}
                  boundedWidth={boundedWidth}
                />
                <YAxis points={yPoints} />
                <HorizontalBarChart<number, string>
                  data={points}
                  xScale={xScale}
                  yScale={yScale}
                  color={colorPalette.skyBlue[7]}
                  barWidth={20}
                />
              </ChartContainer>
            )}
          </>
        )}
      </div>
    </WidgetArea>
  )
}
