import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import DateVO from '../../../../../../../../vo/DateVO'

type GanttUnit = 'day' | 'week' | 'month' | 'year'

export type GanttReportParameter = {
  start: DateVO
  end: DateVO
  step: number
  unit: GanttUnit
}

export const generateGanttParameter = ({
  start,
  end,
}: {
  start: DateVO
  end: DateVO
}): GanttReportParameter => {
  const diffDay = end.diff(start)
  const diffMonth = end.diffMonth(start)
  const diffYear = end.diffYear(start)
  let step = 1
  let unit: GanttUnit
  if (diffDay <= 31) {
    step = 1
    unit = 'day'
  } else if (diffMonth <= 6) {
    unit = 'week'
  } else if (diffMonth <= 18) {
    unit = 'month'
  } else if (diffMonth <= 24) {
    step = 3
    unit = 'month'
  } else if (diffYear <= 12) {
    step = 6
    unit = 'month'
  } else {
    unit = 'year'
  }

  return { start, end, step, unit }
}

export const generateGanttScale = async ({
  start,
  end,
  step,
  unit,
}: GanttReportParameter): Promise<CalendarDateVO[]> => {
  const calendar: CalendarDateVO[] = []
  let date = startScale(start, step, unit)
  const endDate = endScale(end, step, unit)
  while (date.isSameOrBefore(endDate)) {
    calendar.push(CalendarDateVO.of(date.toDate()))
    date = addScale(date, step, unit)
  }

  return calendar
}

const startScale = (date: DateVO, step: number, unit: GanttUnit): DateVO => {
  if (unit === 'week') return date.getFirstDayOfWeek().addDays(1)
  if (unit === 'month') {
    if (step === 1) {
      return date.getFirstDayOfMonth()
    } else {
      return date
        .getFirstDayOfMonth()
        .subtractMonths((date.getMonth() - 1) % step)
    }
  }
  if (unit === 'year') return date.getFirstDayOfYear()
  return date
}

const endScale = (date: DateVO, step: number, unit: GanttUnit): DateVO => {
  let scale
  if (unit === 'week') {
    scale = date.getLastDayOfWeek()
  } else if (unit === 'month') {
    scale = date.getLastDayOfMonth()
  } else if (unit === 'year') {
    scale = date.getLastDayOfYear()
  } else {
    scale = date
  }
  return addScale(scale, step, unit)
}

const addScale = (date: DateVO, step: number, unit: GanttUnit): DateVO => {
  if (unit === 'week') return date.addWeeks(step)
  if (unit === 'month') return date.addMonths(step)
  if (unit === 'year') return date.addYears(step)
  return date.addDays(step)
}
