import './style.scss'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

export const WbsItemIcon = (props: {
  type?: WbsItemTypeVO
  className?: string
}) => {
  return (
    <>
      {props.type?.iconUrl && (
        <img src={props.type.iconUrl} className={props.className} />
      )}
      {props.type?.isTicket() && props.type?.isDeliverable() && (
        <img
          src={`${process.env.REACT_APP_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/ticket_list.svg`}
          className={props.className}
        />
      )}
    </>
  )
}
