import { DialogActions } from '@mui/material'
import { styled } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import React from 'react'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  FilterInputCell,
  RowHeaderCell,
  StyledTableRow,
} from '../../containers/DataCell'
import Auth, { PasswordPolicy } from '../../../lib/commons/auth'
import { FontSize } from '../../../styles/commonStyles'

// Styles
const StyledDialogContent = styled(DialogContent)({
  width: '410px',
})
const Title = styled('div')({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: FontSize.LARGE,
})
const StyledTable = styled(Table)({
  width: '100%',
  height: '100%',
})

interface Props extends WrappedComponentProps {
  open: boolean
  target: string
  onSubmit: Function
  onClose: Function
}

interface State {
  password: string
  passwordPolicy?: PasswordPolicy
}

const DEFAULT_PWD_MIN_LENGTH = 6

class ResetUserPasswordDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      password: '',
    }
  }

  async componentDidMount() {
    const tenant = Auth.getCurrentTenant()
    if (tenant) {
      const passwordPolicy = await tenant.getPasswordPolicy()
      const tempPwd = await tenant.issueTemporaryPassword(
        passwordPolicy?.minLength || DEFAULT_PWD_MIN_LENGTH
      )
      this.setState({
        passwordPolicy,
        password: tempPwd,
      })
    }
  }

  private submit = () => {
    this.props.onSubmit(this.state.password)
    this.close()
  }

  private close = () => {
    this.setState({ password: '' })
    this.props.onClose()
  }

  render() {
    const { open, target, intl } = this.props

    return (
      <Dialog
        open={open}
        onClose={this.close}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <StyledDialogContent>
          <Title>
            {intl.formatMessage(
              { id: 'user.resetPassword.confirmation' },
              { target }
            )}
          </Title>
          <div>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  <StyledTableRow key="temporaryPassword">
                    <RowHeaderCell align="left">
                      {intl.formatMessage({
                        id: 'user.resetPassword.temporaryPassword',
                      })}
                    </RowHeaderCell>
                    <FilterInputCell
                      colSpan={4}
                      value={this.state.password}
                      type={'text'}
                      onChange={value => this.setState({ password: value })}
                      placeholder={intl.formatMessage(
                        { id: 'user.resetPassword.placeholder' },
                        {
                          min:
                            this.state.passwordPolicy?.minLength ||
                            DEFAULT_PWD_MIN_LENGTH,
                        }
                      )}
                    />
                  </StyledTableRow>
                </TableBody>
              </StyledTable>
            </TableContainer>
          </div>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={this.close}>
            {intl.formatMessage({ id: 'dialog.close' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.submit}
            disabled={
              !this.state.password ||
              (this.state.passwordPolicy &&
                this.state.password.length <
                  this.state.passwordPolicy.minLength)
            }
          >
            {intl.formatMessage({ id: 'dialog.setting' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default injectIntl(ResetUserPasswordDialog)
