import roleApi from '../../../lib/functions/role'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { formatDateTime } from '../../../utils/date'
import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import { ColDef } from 'ag-grid-community'
import permissionApi, {
  PermissionDetail,
} from '../../../lib/functions/permission'
import ViewMeta from '../../containers/meta/ViewMeta'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class PermissionRow extends RowData {
  permissionOther?: boolean
}

class PermissionRowDataSpec extends RowDataSpec<
  PermissionDetail,
  PermissionRow
> {
  createNewRow(): PermissionRow {
    return new PermissionRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: PermissionRow
    parent: PermissionRow
  }): PermissionRow {
    return params.child
  }

  createRowByResponse(response: PermissionDetail): PermissionRow {
    return {
      ...response,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
}

interface PermissionContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    PermissionDetail,
    PermissionRow,
    BulkSheetState
  > {}

export default class PermissionsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  PermissionDetail,
  PermissionRow,
  BulkSheetState
> {
  addable = false
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = UiStateKey.PermissionColumnAndFilterState
  rowDataSpec = new PermissionRowDataSpec()
  lockedColumns = ['permission.externalId']
  pinnedColumns = ['permission.externalId', 'permission.name']
  groupColumnWidth = 200
  dynamicColumns = {
    'permission.roleOperations': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: PermissionContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        let columns: any[] = []
        let response = await roleApi.getAll()
        let roles = response.json
        let rolesCells = roles.map(v => {
          return {
            ...baseColumnDef,
            headerName: v.name,
            filter: 'agSetColumnFilter',
            cellRendererParams: {
              ...baseColumnDef.cellRendererParams,
              uiMeta: {
                ...baseColumnDef.cellRendererParams.uiMeta,
                minNumber: undefined,
                maxNumber: undefined,
              },
            },
            valueGetter: params => {
              return params.data?.operationForRole[v.code] ?? 'NONE'
            },
          }
        })

        columns.push({
          ...baseColumnDef,
          children: rolesCells,
        })
        return columns
      },
    },
  }

  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return permissionApi.getAll()
  }
}
