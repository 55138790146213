import { useCallback, useMemo, useRef, useState } from 'react'
import { intl } from '../../../../../i18n'
import { ActualGraphSimulationPanel } from './ActualGraphSimulationPanel'
import {
  BurndownChartResourceSummary,
  BurndownChartSimulationVariables,
} from '../../model'
import { SimulatedGraphSimulationPanel } from './SimulatedGraphSimulationPanel'
import {
  UpdateSimulatedSimulationVariables,
  UpdateProspectSimulationVariables,
  UpdateIdealSimulationVariables,
} from '../../hooks/simulationVariables'
import {
  SideBarRoot,
  SimulationHeader,
  SimulationDescription,
  SimulationHeaderArea,
  SimulationSettingArea,
} from '.'
import { ResourceTypeSelect } from './ResourceTypeSelect'
import { BurndownChartResourceType } from '../../../../../domain/value-object/BurndownChart'
import { ReportDivider } from '../..'
import { ReferenceDataPanel } from './ReferenceDataPanel'
import { IdealGraphSimulationPanel } from './IdealGraphSimulationPanel'
import { ValueFormatters } from '../../hooks/valueFormatters'

export type BurndownChartSideBarProps = BurndownChartSimulationVariables &
  UpdateProspectSimulationVariables &
  UpdateSimulatedSimulationVariables &
  UpdateIdealSimulationVariables & {
    resourceSummary: BurndownChartResourceSummary
    valueFormatters: ValueFormatters
    resourceType: BurndownChartResourceType
    updateResourceType: (_: BurndownChartResourceType) => void
    deadlineDate: Date | undefined
    updateVelocity: (v: number) => void
    updateEndDate: (v: Date) => void
  }
export const BurndownChartSideBar = ({
  updateProspectVelocity,
  updateProspectResourceRate,
  resetProspectVelocity,
  resetProspectResourceRate,
  updateSimulatedVelocity,
  updateSimulatedResourceRate,
  updateSimulatedSimulationFromDate,
  resetSimulatedVelocity,
  resetSimulatedResourceRate,
  resetSimulatedSimulationFromDate,
  prospectVelocity,
  prospectResourceRate,
  simulatedVelocity,
  simulatedResourceRate,
  simulatedSimulationFromDate,
  resourceSummary,
  valueFormatters,
  resourceType,
  updateResourceType,
  deadlineDate,
  updateVelocity,
  updateEndDate,
  idealSimulationFromDate,
  idealSimulationToDate,
  updateIdealSimulationFromDate,
  updateIdealSimulationToDate,
  resetIdealSimulationFromDate,
  resetIdealSimulationToDate,
}: BurndownChartSideBarProps) => {
  const [headerLabel, descriptionLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.simulation.header' }),
      intl.formatMessage({ id: 'progressReport.simulation.description' }),
    ],
    []
  )
  return (
    <SideBarRoot>
      <SimulationHeaderArea>
        <SimulationSettingArea>
          <SimulationHeader>{headerLabel}</SimulationHeader>
          <ResourceTypeSelect
            resourceType={resourceType}
            updateResourceType={updateResourceType}
          />
        </SimulationSettingArea>
        <SimulationDescription>{descriptionLabel}</SimulationDescription>
      </SimulationHeaderArea>
      <ReportDivider />
      <ReferenceDataPanel
        resourceSummary={resourceSummary}
        valueFormatters={valueFormatters}
      />
      <ReportDivider />
      <ActualGraphSimulationPanel
        prospectVelocity={prospectVelocity}
        prospectResourceRate={prospectResourceRate}
        resetProspectVelocity={resetProspectVelocity}
        resetProspectResourceRate={resetProspectResourceRate}
        updateProspectVelocity={updateProspectVelocity}
        updateProspectResourceRate={updateProspectResourceRate}
        deadlineDate={deadlineDate}
        updateVelocity={updateVelocity}
        updateEndDate={updateEndDate}
      />
      <ReportDivider />
      <SimulatedGraphSimulationPanel
        simulatedVelocity={simulatedVelocity}
        simulatedResourceRate={simulatedResourceRate}
        simulatedSimulationFromDate={simulatedSimulationFromDate}
        updateSimulatedVelocity={updateSimulatedVelocity}
        updateSimulatedResourceRate={updateSimulatedResourceRate}
        updateSimulatedSimulationFromDate={updateSimulatedSimulationFromDate}
        resetSimulatedVelocity={resetSimulatedVelocity}
        resetSimulatedResourceRate={resetSimulatedResourceRate}
        resetSimulatedSimulationFromDate={resetSimulatedSimulationFromDate}
      />
      <ReportDivider />
      <IdealGraphSimulationPanel
        idealSimulationFromDate={idealSimulationFromDate}
        idealSimulationToDate={idealSimulationToDate}
        updateIdealSimulationFromDate={updateIdealSimulationFromDate}
        updateIdealSimulationToDate={updateIdealSimulationToDate}
        resetIdealSimulationFromDate={resetIdealSimulationFromDate}
        resetIdealSimulationToDate={resetIdealSimulationToDate}
      />
    </SideBarRoot>
  )
}
