import { ICellRendererParams, RowNode } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import { FloatingEditableIcon } from '../../common/text'
import formatter from '../../../../../../meta/formatter'
import { WbsItemStatus } from '../wbsItemStatus'
import {
  AggregateTargetValue,
  deliverableAggregator,
  taskAggregator,
} from '../../../../lib/aggregator'
import { getUnitByFieldName, getWbsItemFieldName } from '.'
import { RootDiv } from '../../../../components/cell/CellRendererRoot'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'
import { getHoursPerWorkloadUnit } from '../../../../../../../hooks/useWorkloadUnit'

export default function CellRenderer(params: ICellRendererParams) {
  if (params.data.isTotal || !params.colDef) {
    return true
  }

  let renderValue
  const isTreeGrid = !!params.node.groupData
  if (!isTreeGrid) {
    renderValue = format(params.value, params)
  } else {
    const thisFieldName =
      params.colDef.cellEditorParams.thisFieldName || params.colDef.field || ''
    const workloadUnit = getUnitByFieldName(thisFieldName)
    const hoursPerSelectedUnit = getHoursPerWorkloadUnit(workloadUnit, {
      dailyWorkHours: params.context.workloadUnitState?.dailyWorkHours || 1,
      monthlyWorkDays: params.context.workloadUnitState?.monthlyWorkDays || 1,
    })

    const wbsItemFieldName = getWbsItemFieldName(thisFieldName)
    const wbsItemTypeFieldName = `${wbsItemFieldName}.wbsItemType`
    const wbsItemStatusFieldName = `${wbsItemFieldName}.status`

    const wbsItemType: WbsItemTypeVO = objects.getValue(
      params.data,
      wbsItemTypeFieldName
    )

    if (wbsItemType?.isTask()) {
      renderValue = format(params.value, params)
    } else {
      let sumOfTask = 0
      let sumOfDeliverable = 0
      const denominator = hoursPerSelectedUnit
      const isNotDiscard = (node: RowNode) =>
        objects.getValue(node.data, wbsItemStatusFieldName) !==
        WbsItemStatus.DISCARD
      sumOfTask = taskAggregator(
        params.node,
        AggregateTargetValue.ESTIMATED_HOUR,
        wbsItemFieldName,
        isNotDiscard,
        denominator
      )
      sumOfDeliverable = deliverableAggregator(
        params.node,
        AggregateTargetValue.ESTIMATED_HOUR,
        wbsItemFieldName,
        isNotDiscard,
        denominator
      )
      const numerator =
        wbsItemType?.isWorkgroup() ||
        wbsItemType?.isProcess() ||
        wbsItemType?.isDeliverableList()
          ? format(sumOfDeliverable, params)
          : format(params.value, params)
      renderValue = `${numerator} / ${format(sumOfTask, params)}`
    }
  }

  return (
    <RootDiv>
      {renderValue}
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}

const format = (value: string | number, props: ICellRendererParams) => {
  if (!props.colDef?.cellRendererParams) {
    return ''
  }
  return formatter.format(value, props.colDef.cellRendererParams.uiMeta)
}
