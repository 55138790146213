import GithubIcon from '../../../../components/icons/GithubIcon'
import {
  ConnectedInfo,
  ExternalServiceActions,
  ExternalServiceCard,
  ExternalServiceCardContent,
  ExternalServiceCardExplanation,
  ExternalServiceTitle,
  ExternalServiceTitleArea,
  ExternalServiceTitleWithIcon,
} from '../common/ExternalServiceCard'
import { intl } from '../../../../../i18n'
import { SetupGithub } from './SetupGitHub'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import { useDialogState } from '../../../../components/dialogs/useDialogState'
import { useDevelopmentExternalServiceStorageService } from '../../../../../services/storage-services/external-services/developmentExternalServiceStorageService'
import { useEffect, useState } from 'react'
import CancelButton from '../../../../components/buttons/CancelButton'

export const GitHubCard = ({ projectUuid }: { projectUuid: string }) => {
  const [dialogOpen, openDialog, closeDialog] = useDialogState()
  const { isGithubConnected } = useDevelopmentExternalServiceStorageService()
  const [connected, setConnected] = useState<boolean>(false)
  useEffect(() => {
    const fn = async () => {
      const isAlreadyConnected = await isGithubConnected(projectUuid)
      setConnected(isAlreadyConnected)
    }
    if (!dialogOpen) fn()
  }, [dialogOpen])
  return (
    <>
      <ExternalServiceCard sx={{ width: '360px' }}>
        <ConnectedInfo connected={connected} />
        <ExternalServiceCardContent>
          <ExternalServiceTitleArea>
            <GitHubTitle />
          </ExternalServiceTitleArea>
          <ExternalServiceCardExplanation>
            {intl.formatMessage({
              id: 'externalService.github.guide',
            })}
          </ExternalServiceCardExplanation>
        </ExternalServiceCardContent>
        <ExternalServiceActions>
          {!connected && (
            <SubmitButton
              title={intl.formatMessage({
                id: 'externalService.startConfiguration',
              })}
              onClick={openDialog}
              hideIcon={true}
              hideTooltip={true}
              sx={{ width: '120px' }}
            />
          )}
          {connected && (
            <CancelButton
              title={intl.formatMessage({
                id: 'externalService.resetConfiguration',
              })}
              onClick={openDialog}
              sx={{ width: '120px' }}
            />
          )}
        </ExternalServiceActions>
      </ExternalServiceCard>
      <SetupGithub
        projectUuid={projectUuid}
        open={dialogOpen}
        close={closeDialog}
      />
    </>
  )
}

const GitHubTitle = () => {
  return (
    <ExternalServiceTitleWithIcon>
      <GithubIcon />
      <ExternalServiceTitle>GitHub</ExternalServiceTitle>
    </ExternalServiceTitleWithIcon>
  )
}
