import { UserBasic } from '../../../lib/functions/user'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { WbsItemBasic, WbsItemDetail } from '../../../lib/functions/wbsItem'
import { CommentSummary } from '../../../store/comments'
import { AttachmentSummary } from '../../../utils/attachment'
import { NewWbsItemRow } from '../ProjectPlanNew/projectPlanNew'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { generateUuid } from '../../../utils/uuids'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import store from '../../../store'
import { SprintDetail } from '../../../lib/functions/sprint'
import { EntityExtensionValue } from '../../containers/meta/entityExtension'
import { intl } from '../../../i18n'

export interface RiskRow extends TreeRow {
  uuid: string
  lockVersion: number
  taskUuid: string
  riskType: RiskTypeType
  probability: ProbabilityType
  impact: ImpactType
  strategy: StrategyType
  score: number

  // Ticket
  ticketUuid: string
  ticketLockVersion: number
  parentUuid?: string
  prevSiblingUuid?: string

  ticketList?: WbsItemDetail

  parentWbsItem?: WbsItemBasic
  wbsItem: NewWbsItemRow
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary

  added?: boolean
  edited?: boolean
  editedData?: { [key: string]: any }

  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic

  extensions?: EntityExtensionValue[]
  parentPath?: string
}

export const RiskType = {
  OMISSION: 'OMISSION',
}
export type RiskTypeType = (typeof RiskType)[keyof typeof RiskType]
export const RiskTypeLabel: { [key: RiskTypeType]: string } = {
  [RiskType.OMISSION]: intl.formatMessage({ id: 'risk.type.omission' }),
}

export const Probability = {
  EXTREMELY_HIGH: 'EXTREMELY_HIGH',
  HIGH: 'HIGH',
  MIDDLE: 'MIDDLE',
  LOW: 'LOW',
  EXTREMELY_LOW: 'EXTREMELY_LOW',
}
export type ProbabilityType = (typeof Probability)[keyof typeof Probability]
export const ProbabilityLabel: { [key: ProbabilityType]: string } = {
  [Probability.EXTREMELY_HIGH]: intl.formatMessage({
    id: 'risk.probability.extremelyHigh',
  }),
  [Probability.HIGH]: intl.formatMessage({ id: 'risk.probability.high' }),
  [Probability.MIDDLE]: intl.formatMessage({ id: 'risk.probability.middle' }),
  [Probability.LOW]: intl.formatMessage({ id: 'risk.probability.low' }),
  [Probability.EXTREMELY_LOW]: intl.formatMessage({
    id: 'risk.probability.extremelyLow',
  }),
}

export const Impact = {
  EXTREMELY_HIGH: 'EXTREMELY_HIGH',
  HIGH: 'HIGH',
  MIDDLE: 'MIDDLE',
  LOW: 'LOW',
  EXTREMELY_LOW: 'EXTREMELY_LOW',
}
export type ImpactType = (typeof Impact)[keyof typeof Impact]
export const ImpactLabel: { [key: ImpactType]: string } = {
  [Impact.EXTREMELY_HIGH]: intl.formatMessage({
    id: 'risk.impact.extremelyHigh',
  }),
  [Impact.HIGH]: intl.formatMessage({ id: 'risk.impact.high' }),
  [Impact.MIDDLE]: intl.formatMessage({ id: 'risk.impact.middle' }),
  [Impact.LOW]: intl.formatMessage({ id: 'risk.impact.low' }),
  [Impact.EXTREMELY_LOW]: intl.formatMessage({
    id: 'risk.impact.extremelyLow',
  }),
}

export const Strategy = {
  AVOIDANCE: 'AVOIDANCE',
  TRANSFER: 'TRANSFER',
  REDUCTION: 'REDUCTION',
  RETENTION: 'RETENTION',
  SHARING: 'SHARING',
}
export type StrategyType = (typeof Strategy)[keyof typeof Strategy]
export const StrategyLabel: { [key: StrategyType]: string } = {
  [Strategy.AVOIDANCE]: intl.formatMessage({ id: 'risk.strategy.avoidance' }),
  [Strategy.TRANSFER]: intl.formatMessage({ id: 'risk.strategy.transfer' }),
  [Strategy.REDUCTION]: intl.formatMessage({ id: 'risk.strategy.reduction' }),
  [Strategy.RETENTION]: intl.formatMessage({ id: 'risk.strategy.retention' }),
  [Strategy.SHARING]: intl.formatMessage({ id: 'risk.strategy.sharing' }),
}

export const createRiskRow = (ticketListUuid?: string): RiskRow => {
  const wbsItemUuid = generateUuid()
  const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
  const wbsItemType = store
    .getState()
    .project.ticketTypes.find(v => v.code === 'RISK')!
  return {
    uuid: generateUuid(),
    lockVersion: 0,
    taskUuid: wbsItemUuid,
    treeValue: [],
    riskType: RiskType.OMISSION,
    probability: Probability.MIDDLE,
    impact: Impact.MIDDLE,
    strategy: Strategy.AVOIDANCE,
    score: 0,
    ticketUuid: generateUuid(),
    ticketLockVersion: 0,
    wbsItem: {
      uuid: wbsItemUuid,
      code: createRandomCode,
      type: WbsItemType.TASK,
      wbsItemType: wbsItemType,
      baseWbsItemType: wbsItemType,
      ticketType: 'RISK',
      ticketListUuid,
      status: WbsItemStatus.TODO,
      scheduledDate: { startDate: undefined, endDate: undefined },
      actualDate: { startDate: undefined, endDate: undefined },
    } as NewWbsItemRow,
    cumulation: new ProjectPlanCumulation(),

    added: true,
  } as RiskRow
}
