import { useCallback, useEffect, useMemo, useState } from 'react'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { DateTerm, getFinancialYear } from '../../../../utils/date'
import store from '../../../../store'
import { RowGroupColumnType } from '../resourcePlan'

export type StoredPageState = {
  startDate: string
  endDate: string
  rowHeight: ROW_HEIGHT
  rowGroupColumn: RowGroupColumnType | undefined
}

export const usePageState = (functionUuid: string) => {
  const defaultDateTerm: DateTerm = useMemo(() => {
    const startMonthOfFinancialYear = getFinancialYear(
      store.getState()?.tenant.organization
    )
    return {
      startDate: startMonthOfFinancialYear.formatForApi(),
      endDate: startMonthOfFinancialYear.addYears(1).addDays(-1).formatForApi(),
    }
  }, [])
  const [startDate, setStartDate] = useState<string>(defaultDateTerm.startDate!)
  const [endDate, setEndDate] = useState<string>(defaultDateTerm.endDate!)
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [rowGroupColumn, setRowGroupColumn] = useState<
    RowGroupColumnType | undefined
  >(RowGroupColumnType.MEMBER)
  const [initialized, setInitialized] = useState<boolean>(false)

  const dateTerm = useMemo(() => {
    return {
      startDate,
      endDate,
    } as DateTerm
  }, [startDate, endDate])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) return
      const payload: StoredPageState = JSON.parse(stored.value)
      payload.startDate && setStartDate(payload.startDate)
      payload.endDate && setEndDate(payload.endDate)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.rowGroupColumn && setRowGroupColumn(payload.rowGroupColumn)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  const rememberPageState = useCallback(
    (value: StoredPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    restorePageState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      startDate,
      endDate,
      rowHeight,
      rowGroupColumn,
    })
  }, [
    initialized,
    rememberPageState,
    startDate,
    endDate,
    rowHeight,
    rowGroupColumn,
  ])

  return {
    dateTerm,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rowHeight,
    setRowHeight,
    rowGroupColumn,
    setRowGroupColumn,
    initialized,
  }
}
