import EntitySearchVO from './EntitySearchVO'
import SelectVO from './SelectVO'
import TextVO from './TextVO'
import NumberVO from './NumberVO'
import DateVO from './DateVO'
import DateRangeVO from './DateRangeVO'
import DateTimeVO from './DateTimeVO'
import DateTimeRangeVO from './DateTimeRangeVO'
import SwitchVO from './SwitchVO'
import IconVO from './IconVO'
import WorkloadVO from './WorkloadVO'
import MultiSelectVO from './MultiSelectVO'
import MultiEntitySearchVO from './MultiEntitySearchVO'
import MultiLineTextVO from './MultiLineTextVO'
import { PropertyType } from '../lib/commons/appFunction'
import TagVO from './TagVO'
import SlackUserVO from './Slack/UserVO'

export type VO =
  | TextVO
  | NumberVO
  | DateVO
  | DateRangeVO
  | DateTimeRangeVO
  | DateTimeVO
  | SelectVO
  | EntitySearchVO
  | SwitchVO
  | IconVO
  | WorkloadVO
  | MultiSelectVO
  | MultiEntitySearchVO
  | MultiLineTextVO
  | TagVO
  | SlackUserVO

const supported = [
  PropertyType.Text,
  PropertyType.Number,
  PropertyType.Date,
  PropertyType.DateRange,
  PropertyType.DateTime,
  PropertyType.DateTimeRange,
  PropertyType.Select,
  PropertyType.EntitySearch,
  PropertyType.Switch,
  PropertyType.Checkbox,
  PropertyType.Icon,
  PropertyType.Custom,
  PropertyType.MultiSelect,
  PropertyType.MultiEntitySearch,
  PropertyType.MultiLineText,
]

export const isSupported = (propertyType: PropertyType): boolean => {
  return supported.includes(propertyType)
}
