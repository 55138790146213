import { useCallback, useState } from 'react'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'

export const useDialog = () => {
  const [wbsItem, setWbsItem] = useState(false)
  const [wbsItemType, setWbsItemType] = useState<WbsItemTypeVO>()
  const [ticket, setTicket] = useState(false)
  const [ticketType, setTicketType] = useState<WbsItemTypeVO>()
  const [parentUuid, setParentUuid] = useState('')

  const [columnSelect, setColumnSelect] = useState(false)

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [excel, setExcel] = useState<boolean>(false)

  const [actualResult, setActualResult] = useState<boolean>(false)
  const [taskUuid, setTaskUuid] = useState<string>()

  const [cancel, setCancel] = useState<boolean>(false)

  const [alert, setAlert] = useState<boolean>(false)

  const [uiState, setUiState] = useState<boolean>(false)

  const openWbsItem = useCallback(
    ({ type, parentUuid }: { type: WbsItemTypeVO; parentUuid: string }) => {
      setWbsItem(true)
      setWbsItemType(type)
      setParentUuid(parentUuid)
    },
    [wbsItem, wbsItemType]
  )

  const closeWbsItem = useCallback(() => {
    setWbsItem(false)
    setWbsItemType(undefined)
    setParentUuid('')
  }, [wbsItem, wbsItemType])

  const openTicket = useCallback(
    ({ parentUuid }: { parentUuid: string }) => {
      setTicket(true)
      setParentUuid(parentUuid)
    },
    [ticket, ticketType]
  )

  const closeTicket = useCallback(() => {
    setTicket(false)
    setParentUuid('')
  }, [ticket, ticketType])

  const openColumnSelect = useCallback(() => {
    setColumnSelect(true)
  }, [])

  const closeColumnSelect = useCallback(() => {
    setColumnSelect(false)
  }, [])

  const openDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true)
  }, [])

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false)
  }, [])

  const openExcel = useCallback(() => {
    setExcel(true)
  }, [])

  const closeExcel = useCallback(() => {
    setExcel(false)
  }, [])

  const openActualResult = useCallback(
    (uuid: string) => {
      setTaskUuid(uuid)
      setActualResult(true)
    },
    [actualResult, taskUuid]
  )

  const closeActualResult = useCallback(() => {
    setActualResult(false)
    setTaskUuid(undefined)
  }, [actualResult, taskUuid])

  const openCancel = useCallback(() => {
    setCancel(true)
  }, [])

  const closeCancel = useCallback(() => {
    setCancel(false)
  }, [])

  const openAlert = useCallback(() => {
    setAlert(true)
  }, [])

  const closeAlert = useCallback(() => {
    setAlert(false)
  }, [])

  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  return {
    wbsItem,
    wbsItemType,
    openWbsItem,
    closeWbsItem,

    ticket,
    ticketType,
    parentUuid,
    openTicket,
    closeTicket,

    columnSelect,
    openColumnSelect,
    closeColumnSelect,

    deleteConfirmation,
    openDeleteConfirmation,
    closeDeleteConfirmation,

    excel,
    openExcel,
    closeExcel,

    actualResult,
    taskUuid,
    openActualResult,
    closeActualResult,

    cancel,
    openCancel,
    closeCancel,

    alert,
    openAlert,
    closeAlert,

    uiState,
    openUiState,
    closeUiState,
  }
}
