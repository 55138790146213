import { useCallback, useEffect, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { OpenInNewRounded } from '@mui/icons-material'
import { open } from '../../../../router'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { RootDiv } from '../../../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import './styles.scss'

interface Props extends ICellRendererParams {
  tooltip?: (params: ICellRendererParams) => string | undefined
  hideIcon?: (params: ICellRendererParams) => boolean
  hideOpneInNewButton?: (params: ICellRendererParams) => boolean
}

export const ResourcePlanProjectCellRenderer = (props: Props) => {
  const { value, valueFormatted, tooltip, hideIcon, hideOpneInNewButton } =
    props
  const [tooltipText, setTooltipText] = useState<string | undefined>()

  useEffect(() => {
    tooltip && setTooltipText(tooltip?.(props))
  }, [props, tooltip])

  const onClickIcon = useCallback(
    event => {
      const projectCode = value?.code
      if (!projectCode) return
      open(`/resourcePlan/${projectCode}`, event, undefined, true)
    },
    [value]
  )

  const iconUrl = hideIcon && hideIcon(props) ? undefined : value?.iconUrl
  const code =
    hideOpneInNewButton && hideOpneInNewButton(props) ? undefined : value?.code
  return (
    <Tooltip message={tooltipText}>
      <RootDiv>
        <div className="resource_plan_cross_projects__project_cell_div resource_plan_cross_projects__project_value_cell">
          {iconUrl && (
            <img
              src={iconUrl}
              className="resource_plan_cross_projects__project_icon"
            />
          )}
          {valueFormatted ||
            (typeof value === 'string'
              ? value
              : value?.displayName || value?.name) ||
            ''}
        </div>

        {code && (
          <div className="resource_plan_cross_projects__project_cell_div resource_plan_cross_projects__project_open_in_new_tab_cell">
            <button
              className="resource_plan_cross_projects__project_open_in_new_tab_button"
              onClick={onClickIcon}
            >
              <OpenInNewRounded fontSize={'small'} />
            </button>
          </div>
        )}
      </RootDiv>
    </Tooltip>
  )
}
