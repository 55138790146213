import { useCallback, useMemo } from 'react'
import {
  DateComponent,
  DatePrefix,
  DateTermColumnComponent,
  DateWithPrefix,
  Kpi,
  KpiTitle,
  KpiValuesColumn,
  KpiWithTitle,
  WidgetContent,
  WidgetForExpanded,
  WidgetTitle,
  WidgetWithTitle,
} from '.'
import {
  ProjectBasic,
  ProjectProgress,
} from '../../../../domain/value-object/ProjectReportVO'
import { EnterpriseProjectReportConfig } from '../model/config'
import {
  AggregatedValue,
  aggregatedValueService,
} from '../../../../domain/value-object/AggregatedValueVO'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'
import { useKpiUnit } from '../hooks/kpiUnit'
import { useI18n } from '../../../hooks/useI18n'
import { useKpiFormatter } from '../hooks/kpiFormatter'

type ProjectProgressWidgetProps = Pick<
  ProjectBasic,
  'scheduledStartDate' | 'scheduledEndDate'
> &
  Omit<ProjectProgress, 'ac'> &
  EnterpriseProjectReportConfig

export const ProjectProgressWidget = ({
  scheduledStartDate,
  scheduledEndDate,
  bac,
  pv,
  ev,
  delayed,
  aggregateTarget,
  aggregateField,
  workloadUnit,
}: ProjectProgressWidgetProps) => {
  const workloadUnitState = useWorkloadUnit(workloadUnit)
  const { valueFormatter, rateFormatter } = useKpiFormatter(aggregateField)
  const getValue = useCallback(
    (src: AggregatedValue) => {
      switch (aggregateField) {
        case AggregateField.WBS_ITEM_COUNT:
          return aggregatedValueService.getValue(
            src,
            aggregateTarget,
            aggregateField
          )
        case AggregateField.WBS_ITEM_WORKLOAD:
          return workloadUnitState.convertWorkloadFromHourToSelectedUnit(
            aggregatedValueService.getValue(
              src,
              aggregateTarget,
              aggregateField
            )
          )
      }
    },
    [aggregateField, aggregateTarget, workloadUnitState]
  )
  const {
    elapsedTimeRate,
    scheduledProgressRate,
    progressRate,
    formattedBac,
    formattedPv,
    formattedEv,
    formattedDelayed,
    formattedRemaining,
  } = useMemo(() => {
    const projectTermDiff =
      scheduledEndDate.valueOf() - scheduledStartDate.valueOf()
    const elapsedTimeDiff = new Date().valueOf() - scheduledStartDate.valueOf()
    const elapsedTimeRate = rateFormatter(elapsedTimeDiff / projectTermDiff)
    const bacValue = getValue(bac)
    const pvValue = getValue(pv)
    const evValue = getValue(ev)
    const delayedValue = getValue(delayed)
    const scheduledProgressRate = bacValue
      ? rateFormatter(pvValue / bacValue)
      : '-'
    const progressRate = bacValue ? rateFormatter(evValue / bacValue) : '-'
    const formattedBac = valueFormatter(bacValue)
    const formattedPv = valueFormatter(pvValue)
    const formattedEv = valueFormatter(evValue)
    const formattedDelayed = valueFormatter(delayedValue)
    const formattedRemaining = valueFormatter(bacValue - evValue)
    return {
      elapsedTimeRate,
      scheduledProgressRate,
      progressRate,
      formattedBac,
      formattedPv,
      formattedEv,
      formattedDelayed,
      formattedRemaining,
    }
  }, [
    bac,
    delayed,
    ev,
    pv,
    scheduledEndDate,
    scheduledStartDate,
    getValue,
    rateFormatter,
    valueFormatter,
  ])
  const labelIds = useMemo(() => {
    const commonLabels = [
      'enterpriseProjectReport.progress',
      'enterpriseProjectReport.scheduledDate',
      'enterpriseProjectReport.elapsedTimeRate',
      'enterpriseProjectReport.scheduledProgressRate',
      'enterpriseProjectReport.progressRate',
    ]
    switch (aggregateField) {
      case AggregateField.WBS_ITEM_COUNT:
        return [
          ...commonLabels,
          'enterpriseProjectReport.total.count',
          'enterpriseProjectReport.planned',
          'enterpriseProjectReport.completed',
          'enterpriseProjectReport.delayed',
          'enterpriseProjectReport.remaining.count',
        ]
      case AggregateField.WBS_ITEM_WORKLOAD:
        return [
          ...commonLabels,
          'enterpriseProjectReport.total.workload',
          'enterpriseProjectReport.planned',
          'enterpriseProjectReport.completed',
          'enterpriseProjectReport.delayed',
          'enterpriseProjectReport.remaining.workload',
        ]
    }
  }, [aggregateField])
  const [
    title,
    scheduledDateLabel,
    elapsedTimeRateLabel,
    scheduledProgressRateLabel,
    progressRateLabel,
    bacLabel,
    pvLabel,
    evLabel,
    delayedLabel,
    remainingLabel,
  ] = useI18n(labelIds)

  const unit = useKpiUnit(aggregateField, workloadUnit)
  return (
    <WidgetWithTitle>
      <WidgetTitle>{title}</WidgetTitle>
      <WidgetForExpanded>
        <WidgetContent>
          <KpiWithTitle size="xl">
            <KpiTitle>{scheduledDateLabel}</KpiTitle>
            <DateTermColumnComponent>
              <DateWithPrefix>
                <DatePrefix>from</DatePrefix>
                <DateComponent date={scheduledStartDate} />
              </DateWithPrefix>
              <DateWithPrefix>
                <DatePrefix>to</DatePrefix>
                <DateComponent date={scheduledEndDate} />
              </DateWithPrefix>
            </DateTermColumnComponent>
          </KpiWithTitle>
          <Kpi
            title={elapsedTimeRateLabel}
            value={elapsedTimeRate}
            unit={'%'}
          />
          <KpiValuesColumn>
            <Kpi
              title={scheduledProgressRateLabel}
              value={scheduledProgressRate}
              unit={'%'}
            />
            <Kpi title={progressRateLabel} value={progressRate} unit={'%'} />
          </KpiValuesColumn>
          <Kpi title={bacLabel} value={formattedBac} unit={unit} />
          <KpiValuesColumn>
            <Kpi title={pvLabel} value={formattedPv} unit={unit} />
            <Kpi title={evLabel} value={formattedEv} unit={unit} />
          </KpiValuesColumn>
          <Kpi title={delayedLabel} value={formattedDelayed} unit={unit} />
          <Kpi title={remainingLabel} value={formattedRemaining} unit={unit} />
        </WidgetContent>
      </WidgetForExpanded>
    </WidgetWithTitle>
  )
}
