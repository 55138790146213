import DateTimeTermVO from '../../domain/value-object/DateTimeTermVO'
import { dateVoService } from '../../domain/value-object/DateVO'
import { WbsItemSearchConditionVO } from '../../domain/value-object/WbsItemSearchConditionVo'
import objects from '../../utils/objects'
import { StoredWbsItemSearchCondition } from '../model/storedWbsItemSearchCondition'

type WbsItemSearchConditionSerializeService = {
  fromVoToStoredObject: (
    src: WbsItemSearchConditionVO
  ) => StoredWbsItemSearchCondition
}

export const useWbsItemSearchConditionSerializeService =
  (): WbsItemSearchConditionSerializeService => {
    const fromVoToStoredObject = (
      src: WbsItemSearchConditionVO
    ): StoredWbsItemSearchCondition => {
      const dest: StoredWbsItemSearchCondition = {}
      for (let entry of Object.entries(src)) {
        const key = entry[0]
        const value = entry[1]
        // rootUuid does not stored.
        if (key === 'rootUuid') {
          continue
        }
        // DateVO has to be transformed.
        if (
          [
            'scheduledStartDate',
            'scheduledEndDate',
            'actualStartDate',
            'actualEndDate',
          ].includes(key)
        ) {
          if (typeof value !== 'object') continue
          const from = !!value['from']
            ? dateVoService.toString(value['from'])
            : undefined
          const to = !!value['to']
            ? dateVoService.toString(value['to'])
            : undefined
          objects.setValue(dest, key, { from, to })
          continue
        }
        // DateTimeVO has to be transformed.
        if (['createdAt', 'updatedAt'].includes(key)) {
          const v =
            value instanceof DateTimeTermVO ? value.serialize() : undefined
          objects.setValue(dest, key, v)
          continue
        }

        // if properties of blank is all false, it does not set.
        if (key === 'blank') {
          if (Object.values(value).every(v => !v)) {
            continue
          }
        }
        objects.setValue(dest, key, value)
      }
      return dest
    }

    return {
      fromVoToStoredObject,
    }
  }
