import { useCallback, useEffect, useState } from 'react'
import {
  getChartConfigFromUiState,
  getViewConfigFromUiState,
  initChartConfig,
  initViewConfig,
  mergeUIState,
  ProgressReportChartConfig,
  ProgressReportSavedUiState,
  ProgressReportViewConfig,
  translateUrlQueryToViewTime,
} from '..'
import {
  getConditionFromUiState,
  initProgressReportSearchConditionVO,
  ProgressReportSearchConditionVO,
} from '../../../../domain/value-object/ProgressReportSearchConditionVO'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { fetchChartCondition } from '../../../containers/ChartContainer/utils'
import { useProjectPrivateContext } from '../../../context/projectContext'

export const useProgressReportTableStates = (
  applicationFunctionUuid: string,
  projectUuid: string
): {
  searchCondition: ProgressReportSearchConditionVO
  viewConfig: ProgressReportViewConfig
  chartConfig: ProgressReportChartConfig
  updateSearchCondition: (updated: ProgressReportSearchConditionVO) => void
  updateViewConfig: (updated: ProgressReportViewConfig) => void
  updateCharconfig: (updated: ProgressReportChartConfig) => void
  savePageState: (updated: ProgressReportSavedUiState) => void
} => {
  const { wbsItemTypes } = useProjectPrivateContext()
  const [searchCondition, setSearchCondition] =
    useState<ProgressReportSearchConditionVO>(
      initProgressReportSearchConditionVO()
    )
  const [viewConfig, setViewConfig] = useState<ProgressReportViewConfig>(
    initViewConfig()
  )
  const [chartConfig, setChartConfig] = useState<ProgressReportChartConfig>(
    initChartConfig(viewConfig.aggregateTargetType, wbsItemTypes)
  )

  useEffect(() => {
    const f = async () => {
      const primary = await getPageState(applicationFunctionUuid)
      const savedUiState = await fetchChartCondition(
        (query: any, savedUIState?: any): ProgressReportSavedUiState => {
          let uiState = mergeUIState(query, savedUIState)
          uiState = mergeUIState(uiState, primary)
          uiState.viewTime =
            translateUrlQueryToViewTime(query) || viewConfig.viewTime
          return uiState
        },
        UiStateKey.ProgressReportUIStateCondition,
        projectUuid
      )
      if (savedUiState) {
        setSearchCondition({
          ...searchCondition,
          ...getConditionFromUiState(savedUiState),
        })
        setViewConfig({
          ...viewConfig,
          ...getViewConfigFromUiState(savedUiState),
        })
        setChartConfig({
          ...chartConfig,
          ...getChartConfigFromUiState(savedUiState),
        })
      }
    }
    f()
  }, [])

  const updateSearchCondition = useCallback(
    (updated: ProgressReportSearchConditionVO) => {
      setSearchCondition(prev => ({
        ...prev,
        ...updated,
      }))
    },
    []
  )
  const updateViewConfig = useCallback((updated: ProgressReportViewConfig) => {
    setViewConfig(prev => ({
      ...prev,
      ...updated,
    }))
  }, [])
  const updateCharconfig = useCallback((updated: ProgressReportChartConfig) => {
    setChartConfig(prev => ({
      ...prev,
      ...updated,
    }))
  }, [])

  const savePageState = useCallback((updated: ProgressReportSavedUiState) => {
    uiStates.update(
      {
        key: UiStateKey.PageState,
        scope: UiStateScope.User,
        value: JSON.stringify({
          ...searchCondition,
          ...viewConfig,
          ...updated,
        }),
      },
      applicationFunctionUuid
    )
  }, [])

  return {
    searchCondition,
    viewConfig,
    chartConfig,
    updateSearchCondition,
    updateViewConfig,
    updateCharconfig,
    savePageState,
  }
}

const getPageState = async (
  applicationFunctionUuid: string
): Promise<ProgressReportSavedUiState | undefined> => {
  const response = await uiStates.get({
    applicationFunctionUuid,
    key: UiStateKey.PageState,
    scope: UiStateScope.User,
  })
  return response.json.value
    ? (JSON.parse(response.json.value) as ProgressReportSavedUiState)
    : undefined
}
