import { CSSProperties, forwardRef, LegacyRef } from 'react'

const GitCommitIcon = forwardRef(
  (props: { style?: CSSProperties }, ref: LegacyRef<SVGSVGElement>) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={props.style?.fontSize || 16}
      height={props.style?.fontSize || 16}
      fill={'#888888'}
      ref={ref}
    >
      <path d="M11.93 8.5a4.002 4.002 0 0 1-7.86 0H.75a.75.75 0 0 1 0-1.5h3.32a4.002 4.002 0 0 1 7.86 0h3.32a.75.75 0 0 1 0 1.5Zm-1.43-.75a2.5 2.5 0 1 0-5 0 2.5 2.5 0 0 0 5 0Z"></path>
    </svg>
  )
)

export default GitCommitIcon
