import { useEffect, useState } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { colorPalette } from '../../style/colorPallete'

export interface Props {
  checked?: boolean
  onClick?: (checked: boolean) => void | Promise<void>
  tooltipTitle?: string
  style?: React.CSSProperties
}

const FavoriteIcon = ({ checked, onClick, tooltipTitle, style }: Props) => {
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const onClickIcon = () => {
    const toggled = !isChecked
    if (onClick) onClick(toggled)
    setIsChecked(toggled)
  }
  useEffect(() => {
    setIsChecked(!!checked)
  }, [checked])

  const favIcon = (
    <svg
      width={style?.width || 16}
      height={style?.height || 15}
      viewBox="0 0 16 15"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => onClickIcon()}
    >
      <path
        d="M3.36875 14.5L4.5875 9.23125L0.5 5.6875L5.9 5.21875L8 0.25L10.1 5.21875L15.5 5.6875L11.4125 9.23125L12.6313 14.5L8 11.7063L3.36875 14.5Z"
        fill={isChecked ? colorPalette.skyBlue[7] : colorPalette.monotone[4]}
        stroke={style?.stroke || 'none'}
      />
    </svg>
  )

  if (tooltipTitle) {
    return <Tooltip title={tooltipTitle}>{favIcon}</Tooltip>
  } else {
    return favIcon
  }
}

export default FavoriteIcon
