import { useCallback, useEffect, useState } from 'react'
import appFunctionAPI, {
  ApplicationFunctionPropertyConfiguration,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'
import { parse } from '../../../../lib/commons/i18nLabel'
import { getExtensions } from '../../../../lib/functions/entityExtension'
import BoolExpression from '../../../../utils/boolExpression'
import DateExpression from '../../../../utils/dateExpression'
import SearchOptions from '../../../../utils/searchOptions'
import repositories from '../../../containers/meta/repositories'

export interface ExtensionInfo {
  extensionProperties: FunctionProperty[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
  extensionOptions: { [key: string]: CustomEnumValue[] }
}

export const useExtension = (
  functionUuid: string,
  projectUuid: string,
  ticketType: string | undefined,
  ticketListUuid: string | undefined
) => {
  const [extensions, setExtensions] = useState<ExtensionInfo | undefined>()

  const fetchExtensionOptions = useCallback(
    async (projectUuid: string, properties: FunctionProperty[]) => {
      const options: { [key: string]: CustomEnumValue[] } = {}

      // Entity search
      const entitySearchExtensions = properties.filter(
        extension =>
          extension.propertyType === PropertyType.EntitySearch &&
          extension.referenceEntity
      )
      // use for to process in order
      for (let extension of entitySearchExtensions) {
        const repo = repositories[extension.referenceEntity!]
        const searchOptions = extension.searchOptions.build({
          wbsItem: { projectUuid },
        })
        const res = await repo.search('', { ...searchOptions, projectUuid })
        options[extension.referenceEntity!] = res.map(v => ({
          ...v,
          nameI18n: v.nameI18n ? parse(v.nameI18n) : undefined,
        }))
      }

      // Select
      properties
        .filter(
          extension =>
            [PropertyType.Select, PropertyType.MultiSelect].includes(
              extension.propertyType
            ) && extension.valuesAllowed
        )
        .forEach(extension => {
          options[extension.entityExtensionUuid] =
            extension.valuesAllowed.filter(v => v.value !== CUSTOM_ENUM_NONE)
        })
      return options
    },
    []
  )

  const fetchExtensions = useCallback(async () => {
    if (!ticketType || !ticketListUuid) {
      return []
    }
    const response = await getExtensions({
      applicationFunctionUuid: functionUuid,
      groupKeys: [projectUuid, ticketType, ticketListUuid],
    })

    const properties = response.json.map(v => ({
      ...v,
      requiredIf: BoolExpression.of(v.requiredIf),
      editableIfC: BoolExpression.of(v.editableIfC),
      editableIfU: BoolExpression.of(v.editableIfU),
      hiddenIfC: BoolExpression.of(v.hiddenIfC),
      hiddenIfU: BoolExpression.of(v.hiddenIfU),
      minDate: v.minDate ? new DateExpression(v.minDate, 'min') : undefined,
      maxDate: v.maxDate ? new DateExpression(v.maxDate, 'max') : undefined,
      searchOptions: new SearchOptions(v.searchOptions),
    })) as FunctionProperty[]

    await fetchExtensionOptions(projectUuid, properties)
    return properties
  }, [
    functionUuid,
    projectUuid,
    ticketType,
    ticketListUuid,
    fetchExtensionOptions,
  ])

  const fetchPropertyConfigurations = useCallback(async () => {
    if (!ticketType || !ticketListUuid) {
      return []
    }
    const response = await appFunctionAPI.getPropertyConfiguraitons(
      functionUuid,
      [projectUuid, ticketType, ticketListUuid]
    )
    const configs = response.json.map(v => ({
      ...v,
      requiredIf: v.requiredIf ? BoolExpression.of(v.requiredIf) : undefined,
      editableIfC: v.editableIfC ? BoolExpression.of(v.editableIfC) : undefined,
      editableIfU: v.editableIfU ? BoolExpression.of(v.editableIfU) : undefined,
      hiddenIfC: v.hiddenIfC ? BoolExpression.of(v.hiddenIfC) : undefined,
      hiddenIfU: v.hiddenIfU ? BoolExpression.of(v.hiddenIfU) : undefined,
    })) as ApplicationFunctionPropertyConfiguration[]

    return configs
  }, [functionUuid, projectUuid, ticketType, ticketListUuid])

  const clearExtensions = useCallback(() => setExtensions(undefined), [])

  useEffect(
    () => {
      const fetch = async () => {
        const properties = await fetchExtensions()
        const options = await fetchExtensionOptions(projectUuid, properties)
        const config = await fetchPropertyConfigurations()
        setExtensions({
          extensionProperties: properties,
          propertyConfigurations: config,
          extensionOptions: options,
        })
      }
      clearExtensions()
      fetch()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectUuid, ticketListUuid]
  )

  return {
    extensions,
    clearExtensions,
  }
}
