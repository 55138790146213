import { WidgetTitle } from '../components'
import { intl } from '../../../../i18n'
import { RiskBasic } from './hooks/riskData'
import { RiskDashboardContent } from './index'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useCallback } from 'react'
import {
  getWbsItemStatusColorCode,
  getWbsItemStatusLabel,
} from '../../../../lib/functions/wbsItem'
import {
  ImpactLabel,
  ProbabilityLabel,
  RiskTypeLabel,
  StrategyLabel,
} from '../../Risks/risks'
import { OpenInNewRounded } from '@mui/icons-material'

export const RiskList = ({ riskList }: { riskList: RiskBasic[] }) => {
  const i18n = useCallback(id => {
    return intl.formatMessage({ id })
  }, [])

  const openInNewTab = useCallback((code: string) => {
    window.open(`${window.location.origin}/risk/${code}`)
  }, [])

  return (
    <RiskDashboardContent style={{ width: '65%', minWidth: '400px' }}>
      <WidgetTitle>{intl.formatMessage({ id: 'riskList' })}</WidgetTitle>
      <TableContainer component={Paper} sx={{ maxHeight: '300px' }}>
        <Table stickyHeader={true} size="small">
          <TableHead sx={{ height: '40px' }}>
            <TableRow>
              <TableCell sx={{ width: '5%' }} align="center">
                #
              </TableCell>
              <TableCell sx={{ width: '5%' }} align="center" />
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('projectPlan.status')}
              </TableCell>
              <TableCell sx={{ width: '30%' }}>
                {i18n('projectPlan.displayName')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('risks.score')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('risks.riskType')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('risks.probability')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('risks.impact')}
              </TableCell>
              <TableCell sx={{ width: '10%' }} align="center">
                {i18n('risks.strategy')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {riskList
              .sort((a, b) =>
                a.score === b.score ? 0 : a.score > b.score ? -1 : 1
              )
              .map((row, index) => (
                <TableRow
                  key={row.code}
                  sx={{
                    height: '30px',
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => openInNewTab(row.code)}>
                      <OpenInNewRounded fontSize={'small'} />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: getWbsItemStatusColorCode(row.status),
                    }}
                  >
                    {getWbsItemStatusLabel(row.status)}
                  </TableCell>
                  <TableCell>{row.displayName}</TableCell>
                  <TableCell align="center">{row.score}</TableCell>
                  <TableCell align="center">
                    {RiskTypeLabel[row.riskType]}
                  </TableCell>
                  <TableCell align="center">
                    {ProbabilityLabel[row.probability]}
                  </TableCell>
                  <TableCell align="center">
                    {ImpactLabel[row.impact]}
                  </TableCell>
                  <TableCell align="center">
                    {StrategyLabel[row.strategy]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RiskDashboardContent>
  )
}
