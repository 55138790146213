import { MonthlyResourceBalanceVO } from './../../domain/value-object/ResourceBalanceVO/index'
import API from '../../lib/commons/api'
import { ResourceBalanceRepository } from '../../applications/ports/resourceBalanceRepository'
import { ResourceBalanceByTeamVO } from '../../domain/value-object/ResourceBalanceVO'
import { useCallback } from 'react'
import { yearMonthService } from '../../domain/value-object/YearMonthVO'

export const useResourceBalanceRepository = (): ResourceBalanceRepository => {
  const getResourceBalanceFromNowOn = useCallback(
    async (projectUuid: string): Promise<ResourceBalanceByTeamVO[]> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/resource_plans/balance/from-now-on',
        { projectUuid },
        true
      )
      return response.json.map(res => res as ResourceBalanceByTeamVO)
    },
    []
  )
  const getMonthlyResourceBalance = async (
    projectUuid: string,
    teamUuid: string | undefined
  ): Promise<MonthlyResourceBalanceVO[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/resource_plans/balance/monthly',
      { projectUuid, teamUuid },
      true
    )
    return response.json.map(
      res =>
        ({
          yearMonth: yearMonthService.newYearMonthVO(res.year, res.month),
          balance: res.balance,
        } as MonthlyResourceBalanceVO)
    )
  }
  const getMonthlyTotalResourceBalance = async (
    projectUuid: string
  ): Promise<MonthlyResourceBalanceVO[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/resource_plans/balance/monthly/total',
      { projectUuid },
      true
    )
    return response.json.map(
      res =>
        ({
          yearMonth: yearMonthService.newYearMonthVO(res.year, res.month),
          balance: res.balance,
        } as MonthlyResourceBalanceVO)
    )
  }
  return {
    getResourceBalanceFromNowOn,
    getMonthlyResourceBalance,
    getMonthlyTotalResourceBalance,
  }
}
