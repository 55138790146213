import moment from 'moment'
import DateVO from '../vo/DateVO'
import { OrganizationDetail } from '../lib/functions/tenant'

export const DISPLAY_DATE_FORMAT = 'YYYY/MM/DD'
export const DISPLAY_DATE_SHORT_FORMAT_WITH_DAY = 'M/D (ddd)'
export const DISPLAY_DATE_FORMAT_WITH_DAY = 'MM/DD (ddd)'
export const DISPLAY_DATE_FORMAT_WITH_YEAR_DAY = 'YYYY/MM/DD (ddd)'
export const DISPLAY_DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss'
export const DISPLAY_DATETIME_FORMAT_HYPHEN = 'YYYY-MM-DDTHH:mm'
export const DISPLAY_DATETIME_WITH_DAY_FORMAT = 'YYYY/MM/DD (ddd) HH:mm:ss'
export const DISPLAY_DATETIME_FORMAT_MINUTE = 'YYYY/MM/DD HH:mm'
export const DISPLAY_MONTH_FORMAT = 'YYYY/MM'
export const DISPLAY_DATE_WITH_DAY_FORMAT = 'DD (ddd)'
export const DISPLAY_TIME_SECOND_FORMAT = 'HH:mm:ss'
export const DISPLAY_TIME_FORMAT = 'H:mm'
const API_DATE_FORMAT = 'YYYY-MM-DD'

const DEFAULT_FINANCIAL_YEAR_START_MONTH: number = 4
export const Month = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
} as const

export interface DateTerm {
  startDate?: string
  endDate?: string
}

export const hasDiffDateTerm = (a?: DateTerm, b?: DateTerm): boolean => {
  if (!a && !b) {
    return false
  }
  if (a && b) {
    return a.startDate !== b.startDate || a.endDate !== b.endDate
  }
  return true
}

export interface DateTime {
  date?: string
  time?: string
}

export const formatDateBy = (format: string, value?: any) => {
  const dateFormat = [
    DISPLAY_DATE_FORMAT,
    DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
    DISPLAY_DATE_FORMAT_WITH_DAY,
    DISPLAY_DATE_FORMAT_WITH_YEAR_DAY,
    DISPLAY_MONTH_FORMAT,
    DISPLAY_DATE_WITH_DAY_FORMAT,
  ]
  if (!dateFormat.includes(format)) return undefined
  return !!value ? moment(value).format(format) : undefined
}

export const formatMonth = (value?: any) => {
  return !!value ? moment(value).format(DISPLAY_MONTH_FORMAT) : undefined
}

export const formatDate = (value?: any) => {
  return !!value
    ? moment(value).format(DISPLAY_DATE_FORMAT_WITH_YEAR_DAY)
    : undefined
}

export const formatDateShortWithDay = (value?: any) => {
  return !!value
    ? moment(value).format(DISPLAY_DATE_SHORT_FORMAT_WITH_DAY)
    : undefined
}

export const formatDateForApi = (value?: any) => {
  return !!value ? moment(value).format(API_DATE_FORMAT) : undefined
}

export const formatDateTimeForApi = (value?: any) => {
  return !!value ? moment(value).utc().toISOString() : undefined
}

export const formatDateTime = (value?: any) => {
  const numberVal = typeof value === 'string' ? Number(value) : NaN
  const val = !isNaN(numberVal) ? numberVal : value
  return !!val ? moment(val).format(DISPLAY_DATETIME_FORMAT) : undefined
}

export const formatDateTimeFromObj = (dateTime?: DateTime) => {
  if (!dateTime || (!dateTime.date && !dateTime.time)) {
    return undefined
  }
  if (!dateTime.date) {
    dateTime.date = moment().format(DISPLAY_DATE_FORMAT)
  }
  if (!dateTime.time) {
    dateTime.time = '0:00'
  }
  return moment(`${dateTime.date} ${dateTime.time}`).valueOf()
}

export const formatDateTimeWithDay = (value?: any) => {
  return !!value
    ? moment(value).format(DISPLAY_DATETIME_WITH_DAY_FORMAT)
    : undefined
}

export const formatDateTimeMinute = (value?: any) => {
  return !!value
    ? moment(value).format(DISPLAY_DATETIME_FORMAT_MINUTE)
    : undefined
}

export const formatTime = (value?: any) => {
  return !!value ? moment(value).format(DISPLAY_TIME_FORMAT) : undefined
}

export const formatDateTerm = (dateTerm?: DateTerm) => {
  if (!dateTerm) {
    return {
      startDate: '',
      endDate: '',
    }
  }
  return {
    startDate: dateTerm.startDate
      ? moment(dateTerm.startDate).format(DISPLAY_DATE_FORMAT)
      : '',
    endDate: dateTerm.endDate
      ? moment(dateTerm.endDate).format(DISPLAY_DATE_FORMAT)
      : '',
  }
}

export const formatDateWithDay = (value?: any) => {
  return !!value
    ? moment(value).format(DISPLAY_DATE_WITH_DAY_FORMAT)
    : undefined
}

export const isSaturday = (date: string) => {
  return moment(date).day() === 6
}

export const isSunday = (date: string) => {
  return moment(date).day() === 0
}

export const isCurrentDate = (date: string) => {
  return date === moment(new Date()).format('YYYY-MM-DD')
}

export const formatDateTimeRangeForApi = (dateRange?: {
  from: DateVO | undefined
  to: DateVO | undefined
}): {
  from: string | undefined
  to: string | undefined
} => {
  if (!dateRange) {
    return {
      from: undefined,
      to: undefined,
    }
  }
  return {
    from: dateRange.from
      ? formatDateTimeForApi(
          // @ts-ignore
          moment(new DateVO(dateRange.from.value).toDate())
            .startOf('minute')
            .toDate()
        )
      : undefined,
    to: dateRange.to
      ? formatDateTimeForApi(
          // @ts-ignore
          moment(new DateVO(dateRange.to.value).toDate())
            .endOf('minute')
            .toDate()
        )
      : undefined,
  }
}

export const removeDateWhenPast = (dateTerm: DateTerm): DateTerm => {
  const today = new Date()
  const startDate = dateTerm.startDate
  const endDate = dateTerm.endDate
  return {
    startDate:
      startDate && lowerThanDateOnly(new Date(startDate), today)
        ? undefined
        : startDate,
    endDate:
      endDate && lowerThanDateOnly(new Date(endDate), today)
        ? undefined
        : endDate,
  }
}

const lowerThanDateOnly = (date1: Date, date2: Date): boolean => {
  const year1 = date1.getFullYear()
  const month1 = date1.getMonth()
  const day1 = date1.getDate()

  const year2 = date2.getFullYear()
  const month2 = date2.getMonth()
  const day2 = date2.getDate()

  if (year1 === year2) {
    if (month1 === month2) {
      return day1 < day2
    } else {
      return month1 < month2
    }
  } else {
    return year1 < year2
  }
}

export const canParseDate = v => !isNaN(Date.parse(v))

const getFinancialStartMonth = (endMonth: string | undefined): number => {
  if (!endMonth || !(endMonth in Month)) {
    return DEFAULT_FINANCIAL_YEAR_START_MONTH
  }
  if (Month.December === Month[endMonth]) return Month.January
  return Month[endMonth] + 1
}

export const getFinancialYear = (
  // npm run test fails when calling store.getState() inside a function
  organization: OrganizationDetail | undefined
): DateVO => {
  const startMonth: number = getFinancialStartMonth(
    organization?.financialYearEndMonth
  )

  const now = DateVO.now()
  let year = now.getYear()
  if (now.getMonth() < startMonth) {
    year = year - 1
  }
  const formatedMonth = startMonth.toString().padStart(2, '0')
  return new DateVO(`${year}/${formatedMonth}/01`)
}

export const generateYearMonthArray = (term: DateTerm): Array<string> => {
  if (!term.startDate || !term.endDate) {
    return []
  }
  const startDate = new DateVO(term.startDate)
  const endDate = new DateVO(term.endDate).addMonths(1)
  const duration = endDate.getValue().diff(startDate.toDate(), 'months')
  return Array.from({ length: duration }).map((_, index) => {
    return startDate.addMonths(index).format('YYYY/MM')
  })
}
