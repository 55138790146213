import { ColGroupDef, EditableCallbackParams, RowNode } from 'ag-grid-community'
import { useMemo } from 'react'
import { i18nLabelVoService } from '../../../../../domain/value-object/I18nLabelVO'
import {
  WbsItemAdditionalPropertyValuesVO,
  WbsItemAdditionalPropertyValueType,
} from '../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { wbsItemAdditionalPropertyColumnDef } from './columnDef'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemAdditionalPropertyEntity } from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { useWbsItemRepository } from '../../../../../services/adaptors/wbsItemRepositoryAdaptor'
import { useTeamRepository } from '../../../../../services/adaptors/teamRepositoryAdaptor'
import { useProjectMemberRepository } from '../../../../../services/adaptors/projectMemberRepositoryAdaptor'

export interface Option<RowData> {
  getAdditionalPropertyValues: (
    rowData: RowData
  ) => WbsItemAdditionalPropertyValuesVO | undefined
  setAdditionalPropertyValues: (
    rowData: RowNode<RowData>,
    wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
    oldValue: WbsItemAdditionalPropertyValueType,
    newValue: WbsItemAdditionalPropertyValueType
  ) => void
  getWbsItemType: (rowData: RowData) => WbsItemTypeVO | undefined
  editable?: (params: EditableCallbackParams<RowData>) => boolean
  columnFilterType?: 'CLIENT_SIDE' | 'SERVER_SIDE'
  disableFloatingFilter?: boolean
}
export const useWbsItemAdditionalPropertyColumns = <RowData>(
  projectUuid,
  wbsItemAdditionalProperties:
    | WbsItemAdditionalPropertyLayoutEntity
    | undefined,
  option: Option<RowData>
): ColGroupDef<RowData>[] => {
  const wbsItemRepository = useWbsItemRepository()
  const projectMemberRepository = useProjectMemberRepository()
  const teamRepository = useTeamRepository()
  const wbsItemAdditionalPropertyColumns = useMemo(() => {
    if (!wbsItemAdditionalProperties) {
      return []
    }

    return wbsItemAdditionalProperties.groupHeaderLayouts.map(
      ({ groupHeader, propertyLayouts }) => {
        return {
          headerName: i18nLabelVoService.getLabel(groupHeader.headerNameI18n),
          children: propertyLayouts.map(({ wbsItemAdditionalProperty }) =>
            wbsItemAdditionalPropertyColumnDef(
              projectUuid,
              wbsItemAdditionalProperty,
              option,
              {
                wbsItemRepository,
                projectMemberRepository,
                teamRepository,
              }
            )
          ),
        }
      }
    )
  }, [wbsItemAdditionalProperties])

  return wbsItemAdditionalPropertyColumns
}
