export type DatewiseData<T> = {
  date: Date | number
  value: T
}

export type DateTickIntervalType =
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'THREE_MONTH'
  | 'HALF_YEAR'
  | 'YEAR'
const serializeIntervalType = (type: DateTickIntervalType): number => {
  switch (type) {
    case 'DAY':
      return 1
    case 'WEEK':
      return 2
    case 'MONTH':
      return 3
    case 'THREE_MONTH':
      return 4
    case 'HALF_YEAR':
      return 5
    case 'YEAR':
      return 6
  }
}
export const isGreater = (a: DateTickIntervalType, b: DateTickIntervalType) => {
  return serializeIntervalType(a) > serializeIntervalType(b)
}

export const DateBucketTypeArray = ['DAY', 'WEEK', 'MONTH'] as const
export type DateBucketType = (typeof DateBucketTypeArray)[number]
/**
 * DateBucket is closed-open term of Date,
 * which means it contains `from` but not `to`.
 */
export type DateBucket = {
  from: Date
  to: Date
}
