import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { WbsItemIcon } from '../../../../components/icons/WbsItemIcon'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { CardInputError } from '../../Kanban'
import { intl } from '../../../../../i18n'
import _ from 'lodash'

type Props = {
  value?: string
  uuid: string
  wbsItemType: WbsItemTypeVO
  onChange: (v: string) => void
  onClickIcon: (e: any) => void
  errors: CardInputError
}

export const DisplayNameItem = memo(
  ({ value, uuid, wbsItemType, onChange, onClickIcon, errors }: Props) => {
    const [displayName, setDisplayName] = useState<string>(value ?? '')
    const inputRef = useRef<HTMLInputElement>()
    const isTyping = useRef(false)
    const [isEditing, setIsEditing] = useState(false)

    const valueChanged = useMemo(
      () =>
        _.debounce(t => {
          if (isTyping.current) {
            valueChanged(t)
          } else if (inputRef.current?.value !== t) {
            setDisplayName(t)
          }
        }, 100),
      []
    )

    useEffect(() => {
      valueChanged(value ?? '')
      !!value && errors.remove({ id: uuid, field: 'displayName' })
    }, [value, uuid])

    const inputEnd = useMemo(
      () =>
        _.debounce(() => {
          isTyping.current = false
          onChange(inputRef.current?.value ?? '')
        }, 300),
      [onChange]
    )

    const onChangeDisplayName = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
          isTyping.current = true
          const newValue = e.currentTarget.value
          setDisplayName(newValue)
          if (!newValue) {
            errors.add({
              id: uuid,
              field: 'displayName',
              message: intl.formatMessage({
                id: 'kanban.error.card.displayName.required',
              }),
            })
          } else {
            errors.remove({ id: uuid, field: 'displayName' })
          }
        } finally {
          inputEnd()
        }
      },
      [uuid, errors, inputEnd]
    )

    const blurBySpecialKey = useCallback(e => {
      if ((e.key === 'Escape' || e.key === 'Enter') && inputRef.current) {
        inputRef.current.blur()
      }
    }, [])

    useEffect(() => {
      isEditing && inputRef.current?.focus()
    }, [isEditing])

    return (
      <FormControl
        error={errors.hasError(uuid)}
        variant="outlined"
        sx={{ width: '100%' }}
      >
        {isEditing && (
          <StyledDisplayNameEditing
            inputRef={inputRef}
            value={displayName}
            onChange={onChangeDisplayName}
            onBlur={() => setIsEditing(false)}
            onKeyDown={blurBySpecialKey}
            startAdornment={
              <InputAdornment position="start" sx={{ marginRight: '4px' }}>
                <IconButton onClick={onClickIcon} sx={{ padding: '4px' }}>
                  <WbsItemIcon type={wbsItemType} />
                </IconButton>
              </InputAdornment>
            }
          />
        )}
        {!isEditing && (
          <StyledDisplayWithIcon>
            <IconButton onClick={onClickIcon} sx={{ padding: '4px' }}>
              <WbsItemIcon type={wbsItemType} />
            </IconButton>
            <StyledDisplayNameRendering onClick={() => setIsEditing(true)}>
              {value}
            </StyledDisplayNameRendering>
          </StyledDisplayWithIcon>
        )}
        {errors.hasError(uuid) && (
          <FormHelperText id="component-error-text">
            {errors.getMessage(uuid, 'displayName')}
          </FormHelperText>
        )}
      </FormControl>
    )
  }
)

const StyledDisplayNameEditing = styled(Input)({
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '4px 0',
  },
})

const StyledDisplayNameRendering = styled('div')({
  width: '100%',
  fontSize: '12px',
  fontWeight: 600,
  padding: '4px 0',
  overflow: 'auto',
})

const StyledDisplayWithIcon = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
})
