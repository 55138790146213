import { Button } from '@mui/material'
import { PropsWithChildren, memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  colorPalette,
  isDesaturatedColor,
} from '../../../../style/colorPallete'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'

type Props = PropsWithChildren<{
  backgroundColor?: string
}>

const KanbanColumn = memo(({ backgroundColor, children }: Props) => {
  const [open, setOpen] = useState<boolean>(true)
  const autocompletedBackgroundColor = useMemo(
    () => backgroundColor ?? colorPalette.monotone[0],
    [backgroundColor]
  )
  return (
    <ColumnArea backgroundColor={autocompletedBackgroundColor} open={open}>
      <StyledButton
        variant="contained"
        backgroundColor={autocompletedBackgroundColor}
        open={open}
        onClick={() => setOpen(!open)}
      >
        <ExpandMore />
      </StyledButton>
      {open && children}
    </ColumnArea>
  )
})

const ColumnArea = styled('div')<{ backgroundColor: string; open: boolean }>(
  props => ({
    position: 'relative',
    height: '100%',
    minWidth: props.open ? '240px' : '40px',
    background: props.backgroundColor,
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px',
    flex: props.open ? 1 : 'none',
  })
)

const StyledButton = styled(Button)<{ backgroundColor: string; open: boolean }>(
  props => {
    const color =
      props.backgroundColor !== colorPalette.monotone[0] &&
      isDesaturatedColor(props.backgroundColor)
        ? colorPalette.monotone[0]
        : colorPalette.monotone[2]
    const defaultStyle = {
      minWidth: 'fit-content',
      width: '30px',
      height: '30px',
      color,
      background: 'transparent',
      borderRadius: '20%',
      cursor: 'pointer',
      transform: props.open ? 'rotate(90deg)' : 'rotate(270deg)',
      '&:hover': { background: 'transparent' },
    }
    return props.open
      ? { ...defaultStyle, position: 'absolute', top: 8, right: 8 }
      : defaultStyle
  }
)

export default KanbanColumn
