import { Autocomplete, MenuItem, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { intl } from '../../../i18n'
import { UiStateScope } from '../../../lib/commons/uiStates'
import { getTemplates } from '../../../lib/functions/template'
import TextInput from '../../components/editors/input/TextInput'
import { Template } from './TemplateList'
import LockIcon from '@mui/icons-material/LockRounded'
import GroupIcon from '@mui/icons-material/GroupRounded'
import { BackgroundColor, FontSize } from '../../../styles/commonStyles'
import ApartmentIcon from '@mui/icons-material/Apartment'

const StyledAutocomplete = styled(Autocomplete)({
  minWidth: '175px',
}) as typeof Autocomplete
const StyledMenuItem = styled(MenuItem)({
  fontSize: FontSize.MEDIUM,
  display: 'flex',
  padding: 5,
})
const StyledTextInput = styled(TextInput)({
  background: BackgroundColor.WHITE,
})
const UserScopeOptionIcon = styled(LockIcon)({
  height: '16px',
})
const ProjectScopeOptionIcon = styled(GroupIcon)({
  height: '16px',
})
const TenantScopeOptionIcon = styled(ApartmentIcon)({
  height: '16px',
})

interface Props {
  projectUuid?: string
  externalId: string
  selectedUuid?: string
  onChange: (template: Template) => void
  openTemplate?: boolean
}

const TemplateSelect = (props: Props) => {
  const {
    projectUuid,
    externalId,
    selectedUuid,
    onChange: onChangeInProps,
    openTemplate = false,
  } = props
  const [rendered, setRendered] = React.useState(false)
  const [options, setOptions] = React.useState<Template[]>([])
  const [selected, setSelected] = React.useState<Template | null>(null)
  const [prevOpen, setPrevOpen] = React.useState(false)
  const findAndSetSelected = (
    selectedUuid: string | undefined,
    options: Template[]
  ) => {
    const selected = options.find(template => template.uuid === selectedUuid)
    setSelected(selected || null)
  }
  const getOptions = async () => {
    const [templatesForTenant, templatesForProject, templatesForUser] =
      await Promise.all([
        getTemplates(UiStateScope.CrossProject, projectUuid),
        getTemplates(UiStateScope.Tenant, projectUuid),
        getTemplates(UiStateScope.User, projectUuid),
      ])
    const templates = [
      ...templatesForTenant,
      ...templatesForProject,
      ...templatesForUser,
    ]
    const defaultTemplateIdx = templates.findIndex(template =>
      (template.externalIdDefault || []).includes(externalId)
    )
    if (defaultTemplateIdx > -1) {
      const defaultTemplate = templates[defaultTemplateIdx]
      templates.splice(defaultTemplateIdx, 1)
      templates.unshift(defaultTemplate)
    }
    setOptions(templates)
    if (selectedUuid) {
      findAndSetSelected(selectedUuid, templates)
    }
  }
  React.useEffect(() => {
    findAndSetSelected(selectedUuid, options)
  }, [selectedUuid])
  React.useEffect(() => {
    if (!rendered) {
      getOptions()
      setRendered(true)
    }
  }, [rendered])
  React.useEffect(() => {
    if (!rendered) {
      return
    }
    if (prevOpen && prevOpen !== openTemplate) {
      getOptions()
    }
    setPrevOpen(openTemplate)
  }, [openTemplate])
  const onChange = (_, value: Template | null) => {
    setSelected(value)
    if (value) {
      onChangeInProps(value)
    }
  }
  const renderOption = (props, option: Template) => {
    let icon
    let tooltipTitle
    if (option.scope === UiStateScope.User) {
      icon = <UserScopeOptionIcon color="action" />
      tooltipTitle = intl.formatMessage({
        id: 'template.scope.private',
      })
    } else if (option.scope === UiStateScope.Tenant) {
      const isDefault = option.externalIdDefault.includes(externalId)
      icon = <ProjectScopeOptionIcon color={isDefault ? 'primary' : 'action'} />
      tooltipTitle = intl.formatMessage({
        id: isDefault
          ? 'template.scope.project.default'
          : 'template.scope.project',
      })
    } else {
      icon = <TenantScopeOptionIcon color="action" />
      tooltipTitle = intl.formatMessage({
        id: 'template.scope.tenant',
      })
    }
    return (
      <StyledMenuItem {...props}>
        <Tooltip title={tooltipTitle}>{icon}</Tooltip>
        {option.name}
      </StyledMenuItem>
    )
  }
  return (
    <StyledAutocomplete
      value={selected}
      options={options}
      getOptionLabel={(option: Template) => option.name}
      size="small"
      onChange={onChange}
      isOptionEqualToValue={(option: Template, value: Template) =>
        option.uuid === value.uuid
      }
      renderOption={renderOption}
      renderInput={params => (
        <StyledTextInput
          {...params}
          placeholder={intl.formatMessage({
            id: 'template.select',
          })}
          variant="outlined"
          fullWidth={true}
        />
      )}
    />
  )
}

export default TemplateSelect
