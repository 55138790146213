import {
  AggregateField,
  AggregateTarget,
} from '../../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import {
  DateBound,
  StartAndEndDateToggleGroup,
} from '../../../../components/toggleGroups/StartAndEndDateToggleGroup'
import { DefaultTitle } from '../../../../components/headers/HeaderBar/Title/DefaultTitle'
import {
  Header,
  HeaderContent,
} from '../../../../components/headers/HeaderBar/PageHeader'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../../components/toggleGroups'
import { intl } from '../../../../../i18n'
import { IconButton } from '@mui/material'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { UnfoldMoreRounded } from '@mui/icons-material'
import { DashboardTitle, ProjectReportBreadCrumbs } from '../../components'

type Props = {
  project: ProjectDetail
  rootUuid: string | undefined
  rootWbsItemName: string | undefined
  onClickBreadCrumb: (uuid: string | undefined) => void
  onOpenOverview: () => void
}

export const MasterScheduleHeader = ({
  project,
  rootUuid,
  rootWbsItemName,
  onClickBreadCrumb,
  onOpenOverview,
}: Props) => {
  return (
    <Header sx={{ paddingLeft: '11px' }}>
      <HeaderContent>
        <DashboardTitle>
          {intl.formatMessage({ id: 'masterSchedule' })}
        </DashboardTitle>
        <ProjectReportBreadCrumbs
          project={project}
          rootUuid={rootUuid}
          rootWbsItemName={rootWbsItemName}
          onClick={bc => onClickBreadCrumb(bc?.uuid)}
        />
      </HeaderContent>
      <HeaderContent>
        <IconButton onClick={onOpenOverview}>
          <UnfoldMoreRounded sx={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </HeaderContent>
    </Header>
  )
}
