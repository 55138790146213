import React from 'react'
import BaseButton from '../BaseButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { BackgroundColor } from '../../../../styles/commonStyles'

const Button = styled(BaseButton)({
  background: BackgroundColor.BLUE_GRADATION,
  color: '#FFFFFF',
})

interface OwnProps {
  onClick?: () => void | Promise<void>
  className?: string
  hideIcon?: boolean
}
type Props = OwnProps & WrappedComponentProps

const SearchButton = (props: Props) => {
  const { intl, hideIcon, ...others } = props
  return (
    <Button
      {...others}
      startIcon={
        props.hideIcon ? undefined : <SearchRoundedIcon color="secondary" />
      }
      color="primary"
    >
      {intl.formatMessage({ id: 'search' })}
    </Button>
  )
}

export default injectIntl(SearchButton)
