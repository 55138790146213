import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import divisionApi, {
  DivisionBatchDeltaInput,
  DivisionDetail,
  DivisionInput,
  DivisionDeltaInput,
} from '../../../lib/functions/division'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import ContextMenu, {
  ContextMenuGroup,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { GetContextMenuItemsParams } from 'ag-grid-community'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class DivisionRow extends RowData {
  code?: string
  displayName = ''
  officialName?: string
  revision?: string
}

class DivisionRowDataSpec extends RowDataSpec<DivisionDetail, DivisionRow> {
  createNewRow(): DivisionRow {
    return new DivisionRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: DivisionRow
    parent: DivisionRow
  }): DivisionRow {
    return params.child
  }
  createRowByResponse(response: DivisionDetail): DivisionRow {
    return {
      ...response,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(original: DivisionRow): DivisionRow {
    return {
      ...original,
      code: undefined,
      revision: undefined,
    }
  }
}

interface DivisionBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    DivisionDetail,
    DivisionRow,
    BulkSheetState
  > {}

export default class DivisionsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  DivisionDetail,
  DivisionRow,
  BulkSheetState
> {
  addable = true
  draggable = true
  columnAndFilterStateKey = UiStateKey.DivisionColumnAndFilterState
  rowDataSpec = new DivisionRowDataSpec()
  lockedColumns = ['division.code']
  pinnedColumns = ['division.code', 'division.displayName']
  enableExcelExport = true
  groupColumnWidth = 200
  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return divisionApi.getAll()
  }

  onSubmit = async (
    ctx: DivisionBulkSheetContext,
    data: {
      added: DivisionRow[]
      edited: {
        before: DivisionRow
        after: DivisionRow
      }[]
      deleted: DivisionRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: DivisionBatchDeltaInput = {
      added: data.added.map(this.createRequestByRow),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v)),
      deleted: data.deleted.map(this.createDeleteRequestByRow),
    }
    return divisionApi.updateBatchDelta(input)
  }

  private createRequestByRow = (row: DivisionRow): DivisionInput => {
    return row
  }

  private createDeltaRequestByRow = ({
    before,
    after,
  }: {
    before: DivisionRow
    after: DivisionRow
  }): DivisionDeltaInput => {
    return {
      uuid: after.uuid,
      parentUuid: {
        oldValue: before.parentUuid,
        newValue: after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: before.prevSiblingUuid,
        newValue: after.prevSiblingUuid,
      },
      displayName:
        before.displayName !== after.displayName
          ? {
              oldValue: before.displayName,
              newValue: after.displayName,
            }
          : undefined,
      officialName:
        before.officialName !== after.officialName
          ? {
              oldValue: before.officialName,
              newValue: after.officialName,
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (row: DivisionRow) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: DivisionBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
        ctx.generateUtilityContextMenu(params),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
}
