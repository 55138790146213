import React from 'react'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'

interface OwnProps extends WrappedComponentProps {}
type Props = OwnProps & BaseButtonProps

const RegisterActualHourButton = (props: Props) => {
  return (
    <BaseButton
      {...props}
      sx={{
        background: BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
        color: TextColor.BLUE,
        width: '100%',
        padding: '0 10px',
        border: '1px solid transparent',
      }}
      variant="outlined"
      startIcon={<ScheduleRoundedIcon color="primary" fontSize="small" />}
    >
      {props.intl.formatMessage({ id: 'wbsItem.toolbar.registerWorkHour' })}
    </BaseButton>
  )
}

export default injectIntl(RegisterActualHourButton)
