import { useCallback } from 'react'
import { CardContent, SelectOption } from '../Kanban'
import objects from '../../../../utils/objects'

export type SortItem =
  | 'responsible.name'
  | 'scheduledDate.startDate'
  | 'scheduledDate.endDate'
  | 'priority'

export type SortConfig = {
  key: SortItem
  isAsc: boolean
}

function useSort(priorityOptions: SelectOption[]): {
  sort: (sortConfig: SortConfig, cardContents: CardContent[]) => string[]
} {
  const sort = useCallback(
    (sortConfig: SortConfig, cardContents: CardContent[]): string[] => {
      const { key, isAsc } = sortConfig
      const sorted = cardContents.sort((a, b) => {
        const aValue = objects.getValue(a, key)
        const bValue = objects.getValue(b, key)
        if (!aValue && !bValue) {
          return 0
        }

        // nulls last
        if (!aValue) {
          return isAsc ? 1 : -1
        }
        if (!bValue) {
          return isAsc ? -1 : 1
        }

        if (key === 'priority') {
          // NONE: 100
          const aIdx = priorityOptions.some(v => v.value === aValue)
            ? priorityOptions.findIndex(v => v.value === aValue)
            : 100
          const bIdx = priorityOptions.some(v => v.value === bValue)
            ? priorityOptions.findIndex(v => v.value === bValue)
            : 100
          return isAsc ? aIdx - bIdx : bIdx - aIdx
        }

        if (aValue > bValue) {
          return isAsc ? 1 : -1
        } else if (aValue < bValue) {
          return isAsc ? -1 : 1
        } else {
          return 0
        }
      })
      return sorted.map(v => v.uuid!)
    },
    [priorityOptions]
  )

  return { sort }
}

export default useSort
