import { useCallback, useMemo } from 'react'
import { VersionReportStatus } from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { Select } from '../../../../components/select'
import { SelectOption } from '../../../../components/select/model'
import { ReportStatusIcon } from '../status-icon'

type ReportStatusSelectProps = {
  status: VersionReportStatus
  onChange: (v: VersionReportStatus) => void
}
export const ReportStatusSelect = ({
  status,
  onChange: _onChange,
}: ReportStatusSelectProps) => {
  const onChange = useCallback(
    (v: VersionReportStatus | undefined) => {
      if (!v) return
      _onChange(v)
    },
    [_onChange]
  )
  const options = useMemo(
    () => [
      {
        name: '',
        value: 'SUNNY' as VersionReportStatus,
      },
      {
        name: '',
        value: 'CLOUDY' as VersionReportStatus,
      },
      {
        name: '',
        value: 'RAINY' as VersionReportStatus,
      },
    ],
    []
  )
  const renderOption = useCallback((v: SelectOption<VersionReportStatus>) => {
    return <ReportStatusIcon status={v.value} />
  }, [])
  const renderValue = useCallback(
    (v: SelectOption<VersionReportStatus> | undefined) => {
      if (!v) return <></>
      return <ReportStatusIcon status={v.value} />
    },
    []
  )
  return (
    <Select
      value={status}
      options={options}
      onChange={onChange}
      renderOption={renderOption}
      renderValue={renderValue}
    />
  )
}
