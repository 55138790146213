import { useCallback, useEffect, useRef, useState } from 'react'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import { useWbsItemSearchConditionUrlTransformService } from '../../../../services/transform-service/wbsItemSearchConditionUrlTransformService'
import { useWbsItemSearchConditionStorageService } from '../../../../services/storage-services/ui-states/wbsItemSearchConditionStorageService'
import objects from '../../../../utils/objects'

const wbsItemSearchSearchConditionFields = [
  'rootUuid',
  'teamUuid',
  'scheduledEndDate',
  'sprintUuid',
] as const

export type StatusKanbanSearchCondition = Pick<
  WbsItemSearchConditionVO,
  (typeof wbsItemSearchSearchConditionFields)[number]
>

export const useStatusKanbanSearchCondition = (
  functionUuid: string,
  projectUuid: string
): {
  initialized: boolean
  searchCondition: StatusKanbanSearchCondition
  updateSearchCondition: (updated: Partial<StatusKanbanSearchCondition>) => void
  saveSearchCondition: () => Promise<void>
} => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [searchCondition, setSearchCondition] =
    useState<StatusKanbanSearchCondition>({})
  const latestSearchCondition = useRef<StatusKanbanSearchCondition>({})

  const wbsItemSearchConditionUrlTransformer =
    useWbsItemSearchConditionUrlTransformService()
  const wbsItemSearchConditionRepository =
    useWbsItemSearchConditionStorageService()

  const restoreFromURLQuery = useCallback(():
    | StatusKanbanSearchCondition
    | undefined => {
    const conditionFromUrl: StatusKanbanSearchCondition =
      wbsItemSearchConditionUrlTransformer.restoreFromUrl()
    if (!conditionFromUrl || Object.keys(conditionFromUrl).length === 0) {
      return undefined
    }
    const dest: StatusKanbanSearchCondition = {}
    for (let key of wbsItemSearchSearchConditionFields) {
      if (conditionFromUrl[key]) {
        objects.setValue(dest, key, conditionFromUrl[key])
      }
    }
    return dest
  }, [])

  const restoreFromUiState = useCallback(async () => {
    const storedSearchCondition = await wbsItemSearchConditionRepository.fetch(
      functionUuid,
      projectUuid
    )
    if (
      !storedSearchCondition ||
      Object.keys(storedSearchCondition).length === 0
    ) {
      return undefined
    }
    return storedSearchCondition
  }, [])

  useEffect(() => {
    const restore = async () => {
      const searchConditionInURLQuery = restoreFromURLQuery()
      if (searchConditionInURLQuery) {
        setSearchCondition(searchConditionInURLQuery)
        setInitialized(true)
        return
      }
      const storedSearchCondition = await restoreFromUiState()
      if (storedSearchCondition) {
        setSearchCondition(storedSearchCondition)
        setInitialized(true)
        return
      }
      setInitialized(true)
    }
    restore()
    latestSearchCondition.current = searchCondition
  }, [])

  const updateSearchCondition = useCallback(
    (updated: Partial<StatusKanbanSearchCondition>) => {
      setSearchCondition(prev => {
        const newCondition = {
          ...prev,
          ...updated,
        }
        latestSearchCondition.current = newCondition
        return newCondition
      })
    },
    []
  )
  const saveSearchCondition = useCallback(async () => {
    wbsItemSearchConditionRepository.save(
      functionUuid,
      projectUuid,
      latestSearchCondition.current
    )
  }, [])

  useEffect(() => {
    initialized && saveSearchCondition()
  }, [initialized, searchCondition])

  return {
    initialized,
    searchCondition,
    updateSearchCondition,
    saveSearchCondition,
  }
}
