import { useEffect, useMemo, useState } from 'react'
import {
  MainArea,
  ChartArea,
  SideBarArea,
  GraphWithSidebarArea,
  TableWithSidebarArea,
} from '../..'
import { calculateCurrentDateBucket } from '../../../../components/charts/utils/timeSeries'
import { useBurndownChartData } from '../../hooks/burndownChartData'
import { useBurndownChartDataSource } from '../../hooks/burndownChartDataSource'
import {
  useBurndownChartSummary,
  useResourceSummary,
} from '../../hooks/burndownChartSummary'
import { useSimulationVariables } from '../../hooks/simulationVariables'
import { BurndownChartGraph } from '../graph/BurndownChartGraph'
import { BurndownChartSideBar } from '../side-bar/BurndownChartSideBar'
import { BurndownChartTable } from '../table'
import { BurndownChartViewConfig } from '../../model/viewConfig'
import {
  ValueFormatters,
  ValueGetterInverse,
  ValueGetters,
} from '../../hooks/valueFormatters'
import { WbsPivotAggregationDomain } from '../../../../../domain/value-object/WbsPivot'
import { BurndownChartSearchCondition } from '../../hooks/searchCondition'
import { useBurndownChartCondition } from '../../hooks/burndownChartCondition'
import { useDiffDetail } from '../../hooks/diffDetail'
import { useDisplayDateTerm } from '../../hooks/displayDateTerm'
import { BurndownChartResourceType } from '../../../../../domain/value-object/BurndownChart'
import { useResourceData } from '../../hooks/resourceData'
import { useServerSideSimulation } from '../../hooks/serverSideSimulation'
import { DateTerm } from '../../model'
import { useDateBucketHandlers, useDateBuckets } from '../../hooks/dateBuckets'
import { useCalendar } from '../../hooks/calendar'
import { BurndownChartCardPanel } from '../card'
import { DateBucket } from '../../../../components/charts/model/timeSeries'

type BurndownChartTabPanelProps = {
  scheduledDate: {
    from: Date | undefined
    to: Date | undefined
  }
  domainDateTerm: DateTerm
  domain: WbsPivotAggregationDomain | undefined
  selectedTeamUuid: string | undefined
  searchConditions: BurndownChartSearchCondition
  viewConfigs: Pick<
    BurndownChartViewConfig,
    'dateBucketType' | 'startDayOfWeek' | 'viewType'
  >
  valueHandlers: ValueGetters & ValueFormatters & ValueGetterInverse
}
export const BurndownChartTabPanel = ({
  scheduledDate,
  domainDateTerm,
  domain,
  selectedTeamUuid,
  searchConditions: { type },
  viewConfigs: { dateBucketType, startDayOfWeek, viewType },
  valueHandlers: {
    getValue,
    getWorkloadValue,
    getValueInverse,
    ...valueFormatters
  },
}: BurndownChartTabPanelProps) => {
  // Fetch and calculate data.
  const condition = useBurndownChartCondition(domain, type, selectedTeamUuid)

  const { displayDateTerm, updateDisplayDateTerm } =
    useDisplayDateTerm(domainDateTerm)

  const [resourceType, setResourceType] =
    useState<BurndownChartResourceType>('RESOURCE_PLAN')
  const { src } = useBurndownChartDataSource(condition)
  const { summary } = useBurndownChartSummary(src, getValue, getWorkloadValue)

  const { simulationRequest, simulationResult, updateVelocity, updateEndDate } =
    useServerSideSimulation(
      condition,
      selectedTeamUuid,
      resourceType,
      getValueInverse(summary.remaining)
    )
  useEffect(() => {
    if (!!scheduledDate.to) {
      updateEndDate(scheduledDate.to)
    }
  }, [scheduledDate.to, updateEndDate])
  const { simulatedVelocity, deadlineDate } = useMemo(() => {
    const simulatedVelocity =
      simulationResult.dependentVariable === 'VELOCITY' &&
      simulationResult.valid
        ? simulationResult.value
        : undefined
    const deadlineDate =
      simulationResult.dependentVariable === 'END_DATE'
        ? simulationResult.value
        : simulationRequest.dependentVariable === 'VELOCITY'
        ? simulationRequest.endDate
        : scheduledDate.to
    return { simulatedVelocity, deadlineDate }
  }, [scheduledDate.to, simulationRequest, simulationResult])
  const { simulationVariables, ...updateSimulationVariables } =
    useSimulationVariables(
      scheduledDate.from,
      scheduledDate.to,
      simulatedVelocity
    )

  const resourceData = useResourceData(
    condition,
    displayDateTerm.from,
    displayDateTerm.to,
    simulationVariables.simulatedSimulationFromDate,
    resourceType
  )
  const calendar = useCalendar(
    displayDateTerm.from,
    displayDateTerm.to,
    simulationVariables.idealSimulationFromDate,
    simulationVariables.idealSimulationToDate
  )
  const resourceSummary = useResourceSummary(
    resourceData.data,
    summary,
    getWorkloadValue
  )

  const dateBuckets = useDateBuckets(
    displayDateTerm.from,
    displayDateTerm.to,
    dateBucketType,
    startDayOfWeek
  )
  const { isBucketPassed, formatBucket } = useDateBucketHandlers(
    dateBucketType,
    displayDateTerm
  )
  const { data } = useBurndownChartData(
    summary,
    src,
    simulationVariables,
    dateBuckets,
    getValue,
    getWorkloadValue,
    resourceData,
    calendar,
    isBucketPassed
  )

  const diffDetail = useDiffDetail(condition, getValue)

  const [selected, setSelected] = useState<DateBucket>(
    calculateCurrentDateBucket(dateBucketType, startDayOfWeek)
  )

  return (
    <>
      <MainArea>
        {viewType === 'GRAPH' && (
          <>
            <BurndownChartCardPanel
              xBucket={selected}
              formatBucket={formatBucket}
              summary={summary}
              data={data}
              diffDetail={diffDetail}
              formatters={valueFormatters}
              dateBucketType={dateBucketType}
              condition={condition}
              teamUuid={selectedTeamUuid}
            />
            <GraphWithSidebarArea>
              <ChartArea>
                <BurndownChartGraph
                  data={data}
                  diffDetail={diffDetail}
                  dateBuckets={dateBuckets}
                  dateBucketType={dateBucketType}
                  startDayOfWeek={startDayOfWeek}
                  valueFormatters={valueFormatters}
                  condition={condition}
                  displayDateTerm={displayDateTerm}
                  updateDisplayDateTerm={updateDisplayDateTerm}
                  scheduledEndDate={scheduledDate.to}
                  deadlineDate={deadlineDate}
                  onSelect={setSelected}
                />
              </ChartArea>
              <SideBarArea>
                <BurndownChartSideBar
                  resourceSummary={resourceSummary}
                  valueFormatters={valueFormatters}
                  resourceType={resourceType}
                  updateResourceType={setResourceType}
                  {...simulationVariables}
                  {...updateSimulationVariables}
                  deadlineDate={deadlineDate}
                  updateEndDate={updateEndDate}
                  updateVelocity={updateVelocity}
                />
              </SideBarArea>
            </GraphWithSidebarArea>
          </>
        )}
        {viewType === 'TABLE' && (
          <TableWithSidebarArea>
            <ChartArea>
              <BurndownChartTable
                data={data}
                dateBuckets={dateBuckets}
                {...valueFormatters}
                formatBucket={formatBucket}
              />
            </ChartArea>
            <SideBarArea>
              <BurndownChartSideBar
                resourceSummary={resourceSummary}
                valueFormatters={valueFormatters}
                resourceType={resourceType}
                updateResourceType={setResourceType}
                {...simulationVariables}
                {...updateSimulationVariables}
                deadlineDate={deadlineDate}
                updateEndDate={updateEndDate}
                updateVelocity={updateVelocity}
              />
            </SideBarArea>
          </TableWithSidebarArea>
        )}
      </MainArea>
    </>
  )
}
