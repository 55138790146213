import { useCallback, useEffect, useRef, useState } from 'react'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import ChangeLogApi, { Revision } from '../../../../lib/functions/changeLog'
import ViewMeta from '../../../containers/meta/ViewMeta'
import Component from './Component'
import Pluralize from 'pluralize'
import InfiniteScroll from 'react-infinite-scroller'
import styled from '@emotion/styled'

const FETCH_LIMIT = 50
const RootDiv = styled('div')({
  height: 'calc(100% - 7px)',
  overflow: 'auto',
})

interface Props {
  uuid: string | string[]
  lockVersion?: number
  code?: string
  viewMeta: ViewMeta
}

const RevisionList = (props: Props) => {
  const [revisions, setRevisions] = useState<Revision[] | undefined>(undefined)
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [lockVersion, setLockVersion] = useState<number | undefined>(undefined)
  const [offset, setOffset] = useState<number>(0)

  useEffect(() => {
    if (props.lockVersion !== lockVersion) {
      setLockVersion(props.lockVersion)
      fetchRevisions(undefined, 0)
    }
  }, [props.lockVersion, lockVersion])

  const convertValue = useCallback(
    async (
      value: string | null | undefined,
      prop: FunctionProperty | undefined,
      viewMeta: ViewMeta
    ) => {
      if (value === null || value === undefined) {
        return null
      }
      if (!prop) {
        return value
      }
      return viewMeta.convertChangeLog(parseValue(value), prop)
    },
    []
  )

  const parseValue = useCallback((value: string): any => {
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }, [])

  const getPropFromPath = useCallback(
    (path: string, referenceTable: string): FunctionProperty | undefined => {
      if (!path) {
        return undefined
      }
      let partial = path.replace(/^extension\./, '')
      const dotCount = path.split('.').length - 1
      for (let i = 0; i <= dotCount; i++) {
        const prop = getProp(partial, referenceTable)
        if (!!prop) return prop
        partial = partial.substring(0, partial.lastIndexOf('.'))
      }
      return undefined
    },
    []
  )

  const getProp = useCallback(
    (path: string, referenceTable: string): FunctionProperty | undefined => {
      const { viewMeta } = props
      const table = Pluralize.singular(referenceTable)
      return (
        viewMeta.getPropByExternalId(
          viewMeta.toExternalId(`${table}.${path}`)
        ) ||
        viewMeta.getPropByExternalId(viewMeta.toExternalId(path)) ||
        viewMeta.getPropByExternalId(path.replace(/^extension\./, '')) ||
        // wbsItem is used instead of process, deliverable, tasks
        viewMeta.getPropByExternalId(
          viewMeta.toExternalId(`wbsItem.${path}`)
        ) ||
        viewMeta.getPropByExternalId(
          viewMeta.toExternalId(`ticket.wbsItem.${path}`)
        ) ||
        // For unlabeled properties (scheduledDate.startDate/endDate, actualDate.startDate/endDate)
        viewMeta.getPropByExternalId(
          viewMeta.toExternalId(`wbsItem.${path.split('.')[0]}`)
        )
      )
    },
    []
  )

  const fetching = useRef(false)
  const fetchRevisions = useCallback(
    async (currentRevisions: Revision[] | undefined, _offset: number) => {
      if (fetching.current) return
      fetching.current = true

      let response
      const uuid = typeof props.uuid === 'object' ? props.uuid : [props.uuid]
      if (props.uuid) {
        response = await ChangeLogApi.getChangeLogsBySubjectUuid(
          uuid,
          _offset,
          FETCH_LIMIT
        )
      } else if (props.code) {
        response = await ChangeLogApi.getChangeLogsByCode(
          [props.code],
          _offset,
          FETCH_LIMIT
        )
      }

      const fetchedRevisions = response.json.revisions
      const fetchedRevisionCount = fetchedRevisions.length
      setHasMore(fetchedRevisionCount === FETCH_LIMIT)
      setOffset(fetchedRevisionCount + _offset)

      fetchedRevisions.reverse()
      let records: Revision[] = fetchedRevisions.filter(
        (revision: Revision) => {
          // Exclude revision history of deliverable attachments
          return !revision.delta.some(d => {
            const newValue = parseValue(d.newValue)
            const oldValue = parseValue(d.oldValue)
            return (
              (typeof newValue === 'object' &&
                'url' in newValue &&
                'size' in newValue) ||
              (typeof oldValue === 'object' &&
                'url' in oldValue &&
                'size' in oldValue)
            )
          })
        }
      )
      let converted: Revision[] = []
      let prevRevision: string | undefined
      let prevUpdatedAt: number | undefined
      const mainReferenceTable = records[0]
        ? records[0].referenceTable
        : undefined
      if (records) {
        for (let i = 0; i < records.length; i++) {
          let revision: Revision = records[i]
          let convertedDelta: any[] = []
          for (let j = 0; j < revision.delta.length; j++) {
            let d = revision.delta[j]
            const prop = getPropFromPath(d.path, revision.referenceTable)
            const oldValue = await convertValue(
              d.oldValue,
              prop,
              props.viewMeta
            )
            const newValue = await convertValue(
              d.newValue,
              prop,
              props.viewMeta
            )
            d = {
              path: d.path,
              operation: d.operation,
              oldValue: oldValue || d.oldValue,
              newValue: newValue || d.newValue,
              prop,
            }
            convertedDelta.push(d)
          }
          revision.delta = convertedDelta
          if (
            prevUpdatedAt &&
            revision.updatedAt === prevUpdatedAt &&
            revision.referenceTable !== mainReferenceTable
          ) {
            converted[converted.length - 1].delta = [
              ...converted[converted.length - 1].delta,
              ...revision.delta,
            ]
          } else {
            if (
              prevRevision &&
              (revision.revision === prevRevision ||
                revision.referenceTable !== mainReferenceTable)
            ) {
              revision.revision = ''
            }
            converted.push(revision)
          }
          prevUpdatedAt = records[i].updatedAt
          prevRevision =
            revision.referenceTable === mainReferenceTable
              ? records[i].revision
              : prevRevision
        }
      }

      if (currentRevisions !== undefined) {
        const tempRevs: Revision[] = currentRevisions
        tempRevs.forEach(rev => converted.push(rev))
      }
      setRevisions(converted)
      fetching.current = false
    },
    [props.uuid]
  )

  const loadItem = useCallback(() => {
    fetchRevisions(revisions, offset)
  }, [revisions, offset, fetchRevisions])

  return (
    <RootDiv>
      <InfiniteScroll
        loadMore={loadItem}
        hasMore={hasMore}
        useWindow={false}
        threshold={10}
      >
        <Component {...props} revisions={revisions} />
      </InfiniteScroll>
    </RootDiv>
  )
}

export default RevisionList
