import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import { intl } from '../../../../i18n'
import { DateTerm } from '../../../../utils/date'
import { generateYearMonthList } from '../../ProfitLossItems/gridOptions'
import DateVO from '../../../../vo/DateVO'
import _ from 'lodash'
import './styles.scss'
import { UnitPriceBody } from '../UnitPricePerPosition'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  ClientSideNumberFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  isHalfOrFullWidthNumber,
  parseFullNumberToHalf,
} from '../../../../utils/number'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'

export const unitPricePerPositionGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    autoGroupColumnDef: {
      field: 'position.name',
      headerName: intl.formatMessage({ id: 'unit.price.position' }),
      width: 250,
      pinned: true,
      suppressMovable: true,
      sortable: true,
      resizable: true,
      editable: false,
      cellRendererParams: params => {
        return {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          innerRenderer: DefaultCellRenderer,
        }
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.body?.position?.displayName
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 80,
      editable: false,
      enableValue: false,
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        valueGetter: params => {
          return getRowNumber(params.node)
        },
      },
      {
        groupId: 'information',
        headerName: intl.formatMessage({ id: 'unit.price.information' }),
        children: [
          {
            field: 'position',
            headerName: intl.formatMessage({
              id: 'unit.price.position',
            }),
            width: 200,
            hide: true,
            pinned: true,
            lockPosition: 'right',
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            valueGetter: params => {
              return params.data.body?.position?.displayName ?? ''
            },
          },
          {
            field: 'position.official',
            headerName: intl.formatMessage({
              id: 'unit.price.position.official',
            }),
            hide: false,
            pinned: true,
            lockPosition: 'right',
            suppressMovable: true,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body.position?.officialName ?? ''
            },
            sortable: true,
            filter: ClientSideTextFilter,
            floatingFilter: true,
          },
          {
            field: 'unitPrice',
            headerName: intl.formatMessage({ id: 'unit.price' }),
            hide: false,
            sortable: true,
            pinned: true,
            lockPosition: 'right',
            cellClass: 'amount',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
            },
            valueGetter: (params: ValueGetterParams) => {
              const units: UnitPriceBody[] = params.data.body?.unitPrices ?? []
              return getTargetUnitPrice(
                units,
                DateVO.now().getFirstDayOfMonth()
              )
            },
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'amount',
        dataType: 'Number',
        numberFormat: {
          format: '#,###',
        },
      },
    ],
  }
}

type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
}

const UNIT_PRICE_FIELD_PREFIX = 'body.unitPrices.'

export const isUnitPriceColumn = (id: string | undefined) => {
  return !!id && id.startsWith(UNIT_PRICE_FIELD_PREFIX)
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const columnDefs = api.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(UNIT_PRICE_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(UNIT_PRICE_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${UNIT_PRICE_FIELD_PREFIX}${year}`,
        headerName: `${year} ${intl.formatMessage({ id: 'year' })}`,
        children: [],
      }
    })
    const firstDayOfMonth = DateVO.now().getFirstDayOfMonth()
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const isCurrentMonth = date.isEqual(firstDayOfMonth)
      const value = date.format('YYYY/MM')
      const col: ColDef = {
        field: `${UNIT_PRICE_FIELD_PREFIX}${value}`,
        headerName: `${date.getMonth()} ${intl.formatMessage({ id: 'month' })}`,
        hide: false,
        pinned: false,
        suppressMovable: true,
        editable: true,
        cellClass: 'amount',
        cellRenderer: NumberCellRenderer,
        cellClassRules: {
          unit_price_input_month: params => {
            const units: UnitPriceBody[] = params.data.body?.unitPrices ?? []
            return units.some(v => v.validFrom === date.formatForApi())
          },
          unit_price_interval_month: params => {
            const units: UnitPriceBody[] = params.data.body?.unitPrices ?? []
            return !units.some(v => v.validFrom === date.formatForApi())
          },
        },
        cellStyle: (params: CellClassParams) => {
          return isCurrentMonth ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE : {}
        },
        cellRendererParams: {
          numberWithCommas: true,
        },
        valueGetter: (params: ValueGetterParams) => {
          const units: UnitPriceBody[] = params.data.body?.unitPrices ?? []
          return getTargetUnitPrice(units, date)
        },
        valueSetter: (params: ValueSetterParams) => {
          const { colDef, data, oldValue, newValue } = params
          const field = colDef.field || colDef.colId
          const parseVal = parseFullNumberToHalf(newValue ?? '')
          if (
            (!!newValue && !isHalfOrFullWidthNumber(newValue)) ||
            parseVal.startsWith('.')
          ) {
            return false
          }
          const numVal = Number(parseVal ?? 0)
          if (
            !field ||
            (!oldValue && !newValue) ||
            oldValue === newValue ||
            Number.isNaN(numVal)
          ) {
            return false
          }
          const unitPrice = data.body.unitPrices.find(
            v => v.validFrom === date.formatForApi()
          )
          if (unitPrice && unitPrice.unitPrice === numVal) {
            return false
          }
          store.dispatch(requireSave())
          if (!unitPrice) {
            data.body.unitPrices.push({
              validFrom: date.formatForApi(),
              unitPrice: numVal,
            })
            data.edited = true
          } else if (!numVal) {
            data.body.unitPrices = data.body.unitPrices.filter(
              v => v.validFrom !== date.formatForApi()
            )
            data.edited = true
          } else {
            unitPrice.unitPrice = numVal
            data.edited = true
          }
          if (!data.editedData) {
            data.editedData = { [field]: oldValue }
          } else if (!data.editedData.hasOwnProperty(field)) {
            data.editedData[field] = oldValue
          }
          return true
        },
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${UNIT_PRICE_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => newColDefs?.push(year))
  }
  api.setColumnDefs(newColDefs!)
}

const getTargetUnitPrice = (
  units: UnitPriceBody[],
  date: DateVO
): number | undefined => {
  const current = units.find(v => v.validFrom === date.formatForApi())
  if (current) {
    return current.unitPrice
  }
  const goneDays = units
    .map(v => new DateVO(v.validFrom))
    .filter(v => v.isBefore(date))
  if (goneDays && goneDays.length > 0) {
    const latest = DateVO.max(goneDays)
    return units.find(v => v.validFrom === latest.formatForApi())?.unitPrice
  }
  return undefined
}
