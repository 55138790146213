import { ICellRendererParams } from 'ag-grid-community'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FONT_FAMILY, FontSize } from '../../../../../styles/commonStyles'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import { inputErrorColor } from '../../../commons/AgGrid/lib/validator'
import LanguageIcon from '../../../../components/icons/LanguageIcon'
import { styled } from '@mui/material'
import { setError } from '../../lib/validation'

interface Props extends ICellRendererParams {
  validate?: (value: any, params: ICellRendererParams) => string | undefined
}

export const START_EDITING_I18N_LABELS = 'START_EDITING_I18N_LABELS'
export const WbsItemAdditionalPropertyNameCellRenderer = (props: Props) => {
  const { value, data, node, validate } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState('')
  const [openTooltip, setOpenTooltip] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  useEffect(() => {
    if (!validate) return
    const err = validate(value, props)
    setError(data, err, props)
    setErrorMessage(err)
  }, [value])

  const startEditingI18n = useCallback(() => {
    if (!props.column) return
    props.api.startEditingCell({
      rowIndex: props.rowIndex,
      colKey: props.column,
      charPress: START_EDITING_I18N_LABELS,
    })
  }, [props.api, props.rowIndex, props.column])

  useEffect(() => {
    const offsetWidth = ref.current?.offsetWidth
    const ctx = document.createElement('canvas').getContext('2d')
    if (value && offsetWidth && ctx) {
      ctx.font = `${FontSize.MEDIUM} "${FONT_FAMILY}"`
      const textWidth = ctx.measureText(value)?.width
      if (offsetWidth - textWidth < 0) {
        setTooltip(value)
      }
    }
  }, [value])

  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <Tooltip
        title={errorMessage || tooltip}
        placement={'bottom-start'}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: `${FontSize.MEDIUM}px`,
              marginTop: '5px !important',
            },
          },
        }}
        open={openTooltip}
      >
        <RootDiv
          id={`tree-column-${props['rowIndex']}`}
          ref={ref}
          onClick={e => setOpenTooltip(!openTooltip)}
          onMouseLeave={e => setOpenTooltip(false)}
        >
          <Name
            style={{
              backgroundColor: value ? 'none' : inputErrorColor,
            }}
          >
            {value}
          </Name>
          <StyledLanguageIcon color="action" onClick={startEditingI18n} />
        </RootDiv>
      </Tooltip>
    </ClickAwayListener>
  )
}

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: '0 5px 0 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginLeft: '3px',
  fontWeight: 'medium',
})
const Name = styled('div')({
  fontWeight: 'normal !important',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginLeft: '5px',
})
const StyledLanguageIcon = styled(LanguageIcon)({
  cursor: 'pointer',
})
