import { useMemo } from 'react'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { intl } from '../../../../i18n'

export const useKpiUnit = (
  aggregateField: AggregateField,
  workloadUnit: WorkloadUnit
): string => {
  const unit = useMemo(() => {
    if (aggregateField === AggregateField.WBS_ITEM_COUNT) {
      return intl.formatMessage({ id: 'unit.count' })
    }
    switch (workloadUnit) {
      case WorkloadUnit.MONTH:
        return intl.formatMessage({ id: 'unit.workload.month' })
      case WorkloadUnit.DAY:
        return intl.formatMessage({ id: 'unit.workload.day' })
      case WorkloadUnit.HOUR:
        return intl.formatMessage({ id: 'unit.workload.hour' })
    }
  }, [aggregateField, workloadUnit])
  return unit
}
