import React from 'react'
import _ from 'lodash'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  Checkbox,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/system'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import repositories from '../../../../meta/repositories'
import CancelButton from '../../../../../components/buttons/CancelButton'
import MultiEntitySearchVO, {
  MultiEntitySearchItem,
} from '../../../../../../vo/MultiEntitySearchVO'
import { TextColor } from '../../../../../../styles/commonStyles'
import SaveButton from '../../../../../components/buttons/SaveButton'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})
const Option = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& .MuiCheckbox-root': { marginRight: '0px !important' },
  '& .MuiAvatar-root': { margin: '0px 0px 0px 0px !important' },
})
const TooltipTitle = styled('div')({
  color: TextColor.WHITE,
  background: 'transparent',
  opacity: 0.9,
  borderRadius: '5px',
  maxHeight: 300,
  maxWidth: 500,
  overflowY: 'auto',
  padding: '9px 10px',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#bbb',
    borderRadius: '10px',
    border: '1px solid #fff',
  },
  '& .MuiList-root': {
    padding: 0,
  },
  '& .MuiListItem-root': {
    padding: 0,
    alignItems: 'center',
  },
  '& .MuiListItemAvatar-root': {
    height: '21px',
    minWidth: '21px !important',
  },
  '& .MuiAvatar-root': {
    margin: '0px 5px 0px 0px',
    padding: 0,
    height: '21px',
    width: '21px',
    border: 0,
    borderRadius: '50%',
  },
})
const StyledPaper = styled(Paper)({
  '& .MuiAutocomplete-option': { paddingLeft: '1px' },
})
const ButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '36px',
})
const Icon = styled(Avatar)({
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  margin: '0px, -10px !important',
})
const StyledTextInput = styled(TextField)({
  whiteSpace: 'nowrap',
})

interface MultiEntitySearchCellProps
  extends CellPropsBase<MultiEntitySearchVO> {}

interface MultiEntitySearchCellState extends CellStateBase {
  value: MultiEntitySearchItem[]
  initialValue: MultiEntitySearchItem[]
  options: MultiEntitySearchItem[]
  inputValue: string
  popperOpen: boolean
  tooltipOpen: boolean
}

class MultiEntitySearchCell extends DataCellBase<
  MultiEntitySearchVO,
  MultiEntitySearchCellProps,
  MultiEntitySearchCellState
> {
  private readonly repository: any
  constructor(props) {
    super(props)
    this.repository = repositories[props.cellDef.uiMeta.referenceEntity]
    this.state = {
      options: [],
      inputValue: '',
      value: props.value ? props.value.getValue() : [],
      initialValue: props.value ? props.value.getValue() : [],
      popperOpen: false,
      tooltipOpen: false,
    }
  }
  private ref = React.createRef<HTMLInputElement>()

  componentDidUpdate(
    prevProps: MultiEntitySearchCellProps,
    prevState: MultiEntitySearchCellState
  ): void {
    if (this.props.value && !prevProps.value?.isEqual(this.props.value)) {
      this.setState({ value: this.props.value.getValue() })
    }
  }

  private search = async (inputValue: string) => {
    const uiMeta = this.props.cellDef.uiMeta
    const searchOptions = uiMeta.searchOptions.build(this.props.data)
    const options = await this.repository.search(
      inputValue,
      searchOptions,
      uiMeta
    )
    this.setState({ options: options })
  }

  private searchOnInputChange = _.debounce(this.search, 300)

  private onFocus = event => {
    this.search(this.state.inputValue ? this.state.inputValue : '')
  }

  private onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      this.setState({ inputValue: value })
      this.searchOnInputChange(value)
    } else if (reason === 'reset') {
      this.setState({ inputValue: value })
    } else if (reason === 'clear') {
      this.searchOnInputChange('')
    }
  }

  validate = (value: MultiEntitySearchVO) => {
    return undefined
  }

  onChange = (event: any, value: any) => {
    this.setState({ value: value })
  }

  getIcon = (iconUrl: string | undefined) => {
    if (this.repository.getHasIcon()) {
      return <Icon variant="circular" src={iconUrl} />
    } else {
      return <></>
    }
  }

  getOmitIcons = () => {
    if (!this.state.value) {
      return <></>
    }
    let size = this.state.value.length
    let cutSize = 0
    let currentSize = 0
    const width = this.ref.current?.clientWidth || undefined
    const items: JSX.Element[] = []
    for (let index = 0; index < size; index++) {
      if (width && width < currentSize + 60 + 30) {
        cutSize = size - index
        break
      }
      items.push(this.getIcon(this.state.value[index].iconUrl))
      currentSize += 30
    }
    if (0 < cutSize) {
      const cutIcon = (
        <Icon variant="circular" style={{ fontSize: 'small' }}>
          +{cutSize}
        </Icon>
      )
      items.push(cutIcon)
    }
    return items
  }

  renderInput = (params: AutocompleteRenderInputParams) => {
    const startAdornment =
      this.repository.getHasIcon() &&
      !this.state.popperOpen &&
      this.state.value ? (
        <InputAdornment position="start" style={{ marginRight: -30 }}>
          {this.getOmitIcons()}
        </InputAdornment>
      ) : undefined
    const InputProps = {
      ...params.InputProps,
      disableUnderline: true,
      startAdornment: startAdornment,
    }
    return (
      <StyledTextInput
        {...params}
        ref={this.ref}
        variant="standard"
        fullWidth={true}
        InputProps={InputProps}
        inputProps={{
          ...params.inputProps,
          style: {
            textOverflow: 'inherit',
          },
        }}
        placeholder={this.props.cellDef.uiMeta.placeHolder}
      />
    )
  }

  onSubmit = event => {
    this.setState({
      popperOpen: false,
      tooltipOpen: false,
    })
    this.props.onChange(new MultiEntitySearchVO(this.state.value))
  }

  onCancel = event => {
    this.setState({
      value: this.props.value.getValue(),
      popperOpen: false,
      tooltipOpen: false,
    })
  }

  onPopperOpened = event => {
    this.setState({ popperOpen: true })
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    return (
      <Tooltip
        title={
          <TooltipTitle>
            <List>
              {this.state.value.map((v, i) => (
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar src={v.iconUrl} variant="rounded" />
                  </ListItemAvatar>
                  <ListItemText primary={v.name} />
                </ListItem>
              ))}
            </List>
          </TooltipTitle>
        }
        placement={'bottom-start'}
        enterDelay={300}
        leaveDelay={300}
        disableFocusListener={true}
        disableHoverListener={true}
        disableTouchListener={true}
        disableInteractive={true}
        open={this.state.tooltipOpen && !this.state.popperOpen}
      >
        <RootDiv
          onMouseEnter={e => {
            if (!this.state.popperOpen) {
              this.setState({ tooltipOpen: true })
            }
          }}
          onMouseLeave={e => this.setState({ tooltipOpen: false })}
        >
          <Autocomplete
            multiple={true}
            size={'small'}
            options={this.state.options}
            getOptionLabel={option => (option.name ? option.name : '')}
            renderOption={(props, option, { selected }) => {
              return (
                <Option {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    color={'primary'}
                  />
                  {this.getIcon(option.iconUrl)}
                  <span>{option.name}</span>
                </Option>
              )
            }}
            renderInput={this.renderInput}
            isOptionEqualToValue={(value, option) => {
              return value.uuid === option.uuid
            }}
            filterOptions={(options: MultiEntitySearchItem[]) => {
              return options
            }}
            PopperComponent={props => (
              <Popper {...props} placement="bottom-start" />
            )}
            PaperComponent={props => (
              <StyledPaper {...props}>
                {props.children}
                <ButtonGroup>
                  <SaveButton onMouseDown={this.onSubmit} />
                  <CancelButton onMouseDown={this.onCancel} />
                </ButtonGroup>
              </StyledPaper>
            )}
            forcePopupIcon={true}
            autoSelect={false}
            blurOnSelect={false}
            value={this.state.value}
            disableCloseOnSelect={true}
            disableClearable={true}
            noOptionsText={''}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onInputChange={this.onInputChange}
            onOpen={this.onPopperOpened}
          />
        </RootDiv>
      </Tooltip>
    )
  }
}

export default MultiEntitySearchCell
