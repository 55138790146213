import { styled } from '@mui/system'
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popover,
} from '@mui/material'
import { ColorPalette } from '../..'
import { useCallback, useState } from 'react'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { intl } from '../../../../../i18n'
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import ColorLegend from './ColorLegend'

const RootDiv = styled('div')({
  padding: '0 0 0 5px',
})
const IconStyle = {
  width: '16px !important',
  height: '16px !important',
  color: '#888',
}
const StyledColorLensRoundedIcon = styled(ColorLensRoundedIcon)(IconStyle)
const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(IconStyle)
const StyledIconButton = styled(IconButton)({
  padding: '3px',
})
const StyledPaper = styled(Paper)({
  padding: '8px',
})
const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})
const HeaderLabel = styled('span')({
  fontSize: FontSize.MEDIUM,
  fontWeight: FontWeight.BOLD,
})
const StyledList = styled(List)({
  padding: '0',
})
const StyledListItem = styled(ListItem)({
  padding: '0',
})
const StyledListItemButton = styled(ListItemButton)({
  padding: '3px 5px !important',
  justifyContent: 'center !important',
})

type SelectColorPaletteButtonProps = {
  colorPalettes: ColorPalette[]
  currentPalette: ColorPalette
  onChangeColorPalette: (prev: ColorPalette, curr: ColorPalette) => void
}

const SelectColorPalette = ({
  colorPalettes,
  currentPalette,
  onChangeColorPalette,
}: SelectColorPaletteButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const onClick = useCallback(
    (palette: ColorPalette) => {
      onChangeColorPalette(currentPalette, palette)
    },
    [currentPalette, onChangeColorPalette]
  )
  return (
    <RootDiv>
      <IconButton
        size={'small'}
        onClick={event => {
          setAnchorEl(event.currentTarget)
        }}
      >
        <StyledColorLensRoundedIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPaper>
          <Header>
            <HeaderLabel>
              {intl.formatMessage({ id: 'progressReport.selectColorPalette' })}
            </HeaderLabel>
            <StyledIconButton onClick={_ => setAnchorEl(null)}>
              <StyledCloseRoundedIcon />
            </StyledIconButton>
          </Header>
          <StyledList>
            {colorPalettes.map((palette, index) => (
              <StyledListItem key={index} disablePadding={true}>
                <StyledListItemButton
                  onClick={() => {
                    onClick(palette)
                    setAnchorEl(null)
                  }}
                >
                  {palette.map((color, i) => (
                    <ColorLegend key={i} color={color} />
                  ))}
                </StyledListItemButton>
              </StyledListItem>
            ))}
          </StyledList>
        </StyledPaper>
      </Popover>
    </RootDiv>
  )
}

export default SelectColorPalette
