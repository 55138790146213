import React from 'react'
import ToolBar, {
  generateToolBarItemKey,
  ToolBarItemPosition,
  ToolbarProps,
} from '../../components/toolbars/ContainerToolBar'
import { injectIntl } from 'react-intl'
import AlertDialog from '../../components/dialogs/AlertDialog'
import DeleteBasicDataButton from '../../components/buttons/DeleteBasicDataButton'

interface Props extends ToolbarProps {
  onDelete?: () => void
  deleteButtonDisabled?: boolean
}

interface State {
  openDeleteAlert: boolean
}

class SingleSheetToolBar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { openDeleteAlert: false }
  }

  private getMessage = (id: string) => {
    return this.props.intl.formatMessage({ id: id })
  }

  render() {
    return (
      <>
        <ToolBar {...this.props}>
          {[
            ...((this.props.children || []) as JSX.Element[]),
            this.props.onDelete && !this.props.deleteButtonDisabled && (
              <DeleteBasicDataButton
                key={generateToolBarItemKey(99, ToolBarItemPosition.END)}
                onClick={() => this.setState({ openDeleteAlert: true })}
                disabled={this.props.inProgress}
              >
                {this.getMessage('dialog.delete')}
              </DeleteBasicDataButton>
            ),
          ]}
        </ToolBar>
        <AlertDialog
          isOpen={this.state.openDeleteAlert}
          title={this.getMessage('dialog.title.deleteDisplayData')}
          message={this.getMessage('dialog.message.deleteDisplayData')}
          submitButtonTitle={this.getMessage('dialog.ok')}
          submitHandler={() => {
            this.setState({ openDeleteAlert: false })
            this.props.onDelete && this.props.onDelete()
          }}
          closeButtonTitle={this.getMessage('dialog.cancel')}
          closeHandler={() => this.setState({ openDeleteAlert: false })}
        />
      </>
    )
  }
}

export default injectIntl(SingleSheetToolBar)
