import React, { useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import { ProjectDetail } from '../../../../lib/functions/project'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { FontSize } from '../../../../styles/commonStyles'
import { intl } from '../../../../i18n'
import { useSprintReportData } from './hooks/sprintReportData'
import * as d3 from 'd3'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import {
  getWbsItemStatusDeepColorCode,
  getWbsItemStatusLabel,
} from '../../../../lib/functions/wbsItem'
import {
  WidgetAlternative,
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../components'
import { useChartDimensions } from '../hooks/chart/useChartDimensions'
import { useDateXTicks } from '../hooks/chart/useXTicks'
import { ChartContainer, XAxis, YAxisWithLine } from '../components/chart'
import { Button } from '../../../components/buttons'
import { StackedBarChart } from '../components/chart/BarChart'
import { useXDateScale } from '../hooks/chart/useXScale'
import { ProjectReportConfig } from '../model/config'

type Props = {
  project: ProjectDetail
  teamUuid?: string
} & Pick<
  ProjectReportConfig,
  'aggregateRoot' | 'aggregateTarget' | 'aggregateField' | 'workloadUnit'
>

export const SprintReport = ({
  project,
  aggregateRoot,
  aggregateTarget,
  aggregateField,
  workloadUnit,
  teamUuid,
}: Props) => {
  const [
    ref,
    { width, height, marginTop, marginLeft, boundedWidth, boundedHeight },
  ] = useChartDimensions({
    height: 300,
    marginLeft: 50,
    marginRight: 10,
    marginTop: 20,
    marginBottom: 20,
  })

  const { from, to } = useMemo(() => {
    const from =
      aggregateRoot?.scheduledDate.startDate || project.scheduledDate.startDate
    return {
      from: new Date(from),
      to: new Date(),
    }
  }, [aggregateRoot?.scheduledDate.startDate, project.scheduledDate.startDate])

  const { xTicks, intervalType } = useDateXTicks(from, to, boundedWidth, 20)

  const { data, fetchingStatus, confirmFetch } = useSprintReportData(
    project,
    xTicks,
    aggregateRoot,
    aggregateTarget,
    aggregateField,
    workloadUnit,
    teamUuid
  )

  const xScale = useXDateScale(xTicks, boundedWidth, {
    intervalType,
    before: 0.5,
    after: 0.5,
  })

  const { yScale, yTicks } = useMemo(() => {
    const yMax = Math.max(...(data || []).flatMap(v => v.total))
    const yScale = d3
      .scaleLinear()
      .domain([0, yMax * 1.1])
      .range([boundedHeight, 0])
    return {
      yScale,
      yTicks: yScale.ticks(10),
    }
  }, [boundedHeight, data])

  const xAxisPoints = useMemo(
    () =>
      xTicks.map(x => ({
        scale: xScale(x),
        label: `${x.getMonth() + 1}/${x.getDate()}`,
      })),
    [xScale, xTicks]
  )
  const yAxisPoints = useMemo(
    () =>
      yTicks.map(y => ({
        scale: yScale(y),
        label: `${y}`,
      })),
    [yScale, yTicks]
  )

  const stackedData = useMemo(() => {
    return (data || []).map(d => {
      return {
        x: d.date,
        y: [
          {
            k: WbsItemStatus.TODO,
            v: d.TODO || 0,
          },
          {
            k: WbsItemStatus.DOING,
            v: d.DOING || 0,
          },
          {
            k: WbsItemStatus.REVIEW,
            v: d.REVIEW || 0,
          },
          {
            k: WbsItemStatus.DONE,
            v: d.DONE || 0,
          },
        ],
      }
    })
  }, [data])

  return (
    <SprintReportWidgetAreaWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'sprintReport',
        })}
      </WidgetTitle>
      <SprintReportWidgetArea>
        <Box
          sx={{
            width: '100%',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '5px 0 3px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', margin: '0 5px' }}>
            <Legend status={WbsItemStatus.TODO} />
            <Legend status={WbsItemStatus.DOING} />
            <Legend status={WbsItemStatus.REVIEW} />
            <Legend status={WbsItemStatus.DONE} />
          </Box>
        </Box>

        <div ref={ref} style={{ width: '100%', height: '300px' }}>
          {fetchingStatus === 'BEFORE_FETCH' && <></>}
          {fetchingStatus === 'CONFIRM_FETCH' && (
            <WidgetAlternative>
              <Button
                onClick={confirmFetch}
                variant="outlined"
                colorPattern="monotone"
              >
                {intl.formatMessage({ id: 'projectReport.fetchData' })}
              </Button>
            </WidgetAlternative>
          )}
          {fetchingStatus === 'FETCHING' && (
            <WidgetAlternative>
              <CircularProgress />
            </WidgetAlternative>
          )}
          {fetchingStatus === 'FETCHED' && (
            <ChartContainer
              width={width}
              height={height}
              marginLeft={marginLeft}
              marginTop={marginTop}
            >
              <XAxis
                points={xAxisPoints}
                boundedHeight={boundedHeight}
                boundedWidth={boundedWidth}
              />
              <YAxisWithLine points={yAxisPoints} boundedWidth={boundedWidth} />
              <StackedBarChart
                data={stackedData}
                xScale={xScale}
                yScale={yScale}
                color={getWbsItemStatusDeepColorCode}
                barWidth={20}
              />
            </ChartContainer>
          )}
        </div>
      </SprintReportWidgetArea>
    </SprintReportWidgetAreaWrapper>
  )
}

const SprintReportWidgetAreaWrapper = styled(WidgetWithTitleWrapper)({
  width: '48%',
})

const SprintReportWidgetArea = styled(WidgetArea)({
  flexDirection: 'column',
})

const Legend = ({ status }: { status: WbsItemStatus }) => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        style={{
          width: '50px',
          height: '8px',
          backgroundColor: getWbsItemStatusDeepColorCode(status),
        }}
      />
      <Typography sx={{ fontSize: FontSize.SMALL }}>
        {getWbsItemStatusLabel(status)}
      </Typography>
    </Box>
  )
}
