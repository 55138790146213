import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { styled } from '@mui/system'
import { ICellEditorParams } from 'ag-grid-community'
import { I18nLabel } from '../../../../../lib/commons/i18nLabel'
import I18nLabelDialog from '../../../../components/dialogs/I18nLabelDialog'
import LanguageIcon from '../../../../components/icons/LanguageIcon'
import {
  I18nLabelVO,
  i18nLabelVoService,
} from '../../../../../domain/value-object/I18nLabelVO'
import { START_EDITING_I18N_LABELS } from '../cellRenderer/WbsItemAdditionalPropertyNameCellRenderer'
import { TextInput } from '../../../../components/inputs/TextInput'

type Props = {
  valueGetter: (params: ICellEditorParams) => I18nLabelVO
  valueSetter: (params: ICellEditorParams, newValue: I18nLabelVO) => I18nLabelVO
} & ICellEditorParams

export const I18nLabelCellEditor = forwardRef((props: Props, ref) => {
  const [value, setValue] = useState<I18nLabelVO>(props.valueGetter(props))
  const [open, setOpen] = useState(
    props.charPress === START_EDITING_I18N_LABELS
  )
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue() {
          return value
        },
      }
    },
    [value]
  )

  // TODO Remove
  const i18nLabelsForDialog = useMemo(() => {
    return value.labels.map(v => ({
      languageCode: v.languageCode,
      name: v.label,
    }))
  }, [value.labels])

  const openDialog = useCallback(() => {
    setOpen(true)
  }, [])

  const closeDialog = useCallback(
    (labels: I18nLabel[]) => {
      setOpen(false)
      const changed =
        labels.length !== i18nLabelsForDialog.length ||
        i18nLabelsForDialog.some(oldLabel =>
          labels.some(
            newLabel =>
              newLabel.languageCode === oldLabel.languageCode &&
              newLabel.name !== oldLabel.name
          )
        )
      if (changed) {
        const newValue = i18nLabelVoService.setLabels(
          value,
          labels.map(v => ({
            languageCode: v.languageCode,
            label: v.name,
          }))
        )
        setValue(newValue)
        props.valueSetter(props, newValue)
        props.api.stopEditing()
      } else {
        props.api.stopEditing(true)
      }
    },
    [value, i18nLabelsForDialog, props.api]
  )

  const onChangeInput = useCallback(
    e => {
      const currentLanguageCode = i18nLabelVoService.getCurrentLanguageCode()
      let changed = false
      const newLabels = value.labels.map(v => {
        if (v.languageCode === currentLanguageCode) {
          v.label = e.target.value
          changed = true
        }
        return v
      })
      if (!changed) {
        newLabels.push({
          languageCode: currentLanguageCode,
          label: e.target.value,
        })
      }
      const newValue = i18nLabelVoService.setLabels(value, newLabels)
      props.valueSetter(props, newValue)
      setValue(newValue)
    },
    [value.labels]
  )

  return (
    <RootDiv>
      <TextInput
        inputRef={inputRef}
        value={i18nLabelVoService.getLabel(value)}
        onChange={onChangeInput}
      />
      {open && (
        <I18nLabelDialog
          open={open}
          onClose={closeDialog}
          i18nLabels={i18nLabelsForDialog}
        />
      )}
      <LanguageIcon color="action" onClick={openDialog} />
    </RootDiv>
  )
})

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  justifyContent: 'space-between',
})
