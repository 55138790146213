import { useCallback, useEffect, useRef, useState } from 'react'
import { ClientSideTextFilter } from '../../../../containers/BulkSheetView/components/filter'
import Popper from '@mui/material/Popper/Popper'
import styled from 'styled-components'
import { colorPalette } from '../../../../style/colorPallete'
import Box from '@mui/material/Box/Box'
import { TextFilter as TextFilterModel } from '../../../../containers/BulkSheetView/components/filter/TextFilter'

type Props = {
  anchorEl: Element | undefined
  valueGetter: (params) => string
  filterChangedCallback: (changed: TextFilterModel | undefined) => void
  defaultValue?: TextFilterModel | undefined
}

export const TextFilter = ({
  anchorEl,
  valueGetter,
  filterChangedCallback,
  defaultValue,
}: Props) => {
  const [initialized, setInitialized] = useState(false)
  const ref = useRef<any>(null)

  const wrappedCallBack = useCallback(() => {
    if (!initialized) {
      defaultValue && ref.current.setModel(defaultValue)
      setInitialized(true)
      return
    }
    if (!ref.current.isFilterActive()) {
      filterChangedCallback(undefined)
      return
    }
    filterChangedCallback(ref.current.getModel())
  }, [initialized, filterChangedCallback])

  useEffect(() => {
    !anchorEl && setInitialized(false)
  }, [anchorEl])

  return (
    <StyledPopper open={true} anchorEl={anchorEl} placement={'bottom-start'}>
      <Box sx={{ display: 'flex', margin: 'auto 10px' }}>
        <ClientSideTextFilter
          ref={ref}
          filterChangedCallback={wrappedCallBack}
          valueGetter={valueGetter}
        />
      </Box>
    </StyledPopper>
  )
}

const StyledPopper = styled(Popper)({
  boxShadow: '0px 4px 16px 0px #7B8CAA80',
  background: colorPalette.monotone[0],
  display: 'flex',
  margin: '10px',
  borderRadius: '4px',
  zIndex: 1300,
})
