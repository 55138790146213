import { useCallback, useState } from 'react'
import _ from 'lodash'
import {
  FinancialStatementAccountsType,
  LedgerAccountsTree,
  fetchLedgerAccounts,
} from '../../LedgerAccounts/ledgerAccounts'

export const useMasterData = () => {
  const [masterTree, setMasterTree] = useState<LedgerAccountsTree[]>([])

  const fetchMasterRecords = useCallback(async () => {
    const response = await fetchLedgerAccounts({})
    const source: LedgerAccountsTree[] = response.json
    const targets = [
      FinancialStatementAccountsType.Sales,
      FinancialStatementAccountsType.CostOfSales,
      FinancialStatementAccountsType.SGAExpense,
    ]
    setMasterTree(
      source?.filter(
        v =>
          !!v.financialStatementAccountsType &&
          targets.includes(v.financialStatementAccountsType)
      )
    )
  }, [])

  return {
    masterTree,
    fetchMasterRecords,
  }
}
