import { useWbsItemSearchConditionSerializeService } from '../../transform-service/wbsItemSearchConditionSerializeService'
import { WbsItemSearchConditionVO } from '../../../domain/value-object/WbsItemSearchConditionVo'
import { useUiStateStorageService } from '../../../infrastructure/adaptors/uiStateStorageServiceAdaptor'
import { useWbsItemSearchConditionFactory } from '../../factories/wbsItemSearchConditionFactory'

export type WbsItemSearchConditionStorageService = {
  save: (
    functionUuid: string,
    projectUuid: string,
    condition: WbsItemSearchConditionVO
  ) => Promise<void>
  fetch: (
    functionUuid: string,
    projectUuid: string
  ) => Promise<WbsItemSearchConditionVO | undefined>
}

// This is used for temporary store.
const bulkSheetSearchCondisionState = 'BULK_SHEET_SEARCH_CONDITION_STATE'

export const useWbsItemSearchConditionStorageService =
  (): WbsItemSearchConditionStorageService => {
    const uiStateStorageService = useUiStateStorageService()
    const factory = useWbsItemSearchConditionFactory()
    const transformer = useWbsItemSearchConditionSerializeService()

    const toTemporaryKey = (projectUuid: string) =>
      `${bulkSheetSearchCondisionState}-${projectUuid}`

    const save = async (
      functionUuid: string,
      projectUuid: string,
      condition: WbsItemSearchConditionVO
    ): Promise<void> => {
      uiStateStorageService.save(
        functionUuid,
        toTemporaryKey(projectUuid),
        'USER',
        transformer.fromVoToStoredObject(condition)
      )
    }

    const fetch = async (
      functionUuid: string,
      projectUuid: string
    ): Promise<WbsItemSearchConditionVO | undefined> => {
      const storedCondition = await uiStateStorageService.fetch(
        functionUuid,
        toTemporaryKey(projectUuid),
        'USER'
      )
      if (!storedCondition) return undefined
      return factory.fromApiResponseToVo(storedCondition)
    }

    return {
      save,
      fetch,
    }
  }
