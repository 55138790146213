import { AutocompleteOption } from '../../../view/containers/DataCell'
import { ProgressReportSavedUiState } from '../../../view/pages/ProgressReport'

export type ProgressReportSearchConditionVO = {
  rootWbsItemUuid?: string
  ticketListUuid?: string
  team?: AutocompleteOption
  accountable?: AutocompleteOption
  responsible?: AutocompleteOption
  priority?: string
}

export const updateProgressReportSearchCondition = (
  original: ProgressReportSearchConditionVO,
  patch: Partial<ProgressReportSearchConditionVO>
): ProgressReportSearchConditionVO => ({
  ...original,
  ...patch,
})

export const initProgressReportSearchConditionVO = () => ({})

export const getConditionFromUiState = (
  uiState: ProgressReportSavedUiState
): Partial<ProgressReportSearchConditionVO> => {
  let condition = {}
  if (uiState.rootWbsItemUuid) {
    condition = { ...condition, rootWbsItemUuid: uiState.rootWbsItemUuid }
  }
  if (uiState.ticketListUuid) {
    condition = { ...condition, ticketListUuid: uiState.ticketListUuid }
  }
  if (uiState.team) {
    condition = { ...condition, team: uiState.team }
  }
  if (uiState.accountable) {
    condition = { ...condition, accountable: uiState.accountable }
  }
  if (uiState.responsible) {
    condition = { ...condition, responsible: uiState.responsible }
  }
  if (uiState.priority) {
    condition = { ...condition, priority: uiState.priority }
  }
  return condition
}
