import { useCallback, useState } from 'react'

export const useDialog = () => {
  const [cancelConfirmation, setCancelConfirmation] = useState(false)

  const [actualResult, setActualResult] = useState<boolean>(false)
  const [taskUuid, setTaskUuid] = useState<string>()

  const [addMultipleRow, setAddMultipleRow] = useState<boolean>(false)
  const [prevSiblingUuid, setPrevSiblingUuid] = useState<string>()

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [uiState, setUiState] = useState<boolean>(false)

  const [excel, setExcel] = useState<boolean>(false)

  // cancel confirmation
  const openCancelConfirmation = useCallback(() => {
    setCancelConfirmation(true)
  }, [])

  const closeCancelConfirmation = useCallback(() => {
    setCancelConfirmation(false)
  }, [])

  // actual result
  const openActualResult = useCallback((uuid: string) => {
    setTaskUuid(uuid)
    setActualResult(true)
  }, [])

  const closeActualResult = useCallback(() => {
    setActualResult(false)
    setTaskUuid(undefined)
  }, [])

  // add multiple row
  const openAddMultipleRow = useCallback((uuid: string | undefined) => {
    setAddMultipleRow(true)
    setPrevSiblingUuid(uuid)
  }, [])

  const closeAddMultipleRow = useCallback(() => {
    setAddMultipleRow(false)
    setPrevSiblingUuid(undefined)
  }, [])

  // delete confirmation
  const openDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true)
  }, [])

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false)
  }, [])

  // UI State
  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  const openExcel = useCallback(() => {
    setExcel(true)
  }, [])

  const closeExcel = useCallback(() => {
    setExcel(false)
  }, [])

  return {
    cancelConfirmation,
    openCancelConfirmation,
    closeCancelConfirmation,

    actualResult,
    taskUuid,
    openActualResult,
    closeActualResult,

    addMultipleRow,
    prevSiblingUuid,
    openAddMultipleRow,
    closeAddMultipleRow,

    deleteConfirmation,
    openDeleteConfirmation,
    closeDeleteConfirmation,

    uiState,
    openUiState,
    closeUiState,

    excel,
    openExcel,
    closeExcel,
  }
}
