import { intl } from '../../../../../../../../i18n'
import Select from '../../../../../../../components/editors/select/Select'

export enum DateCellFilterOperator {
  EQ = '=',
  NOT_SET = 'NOT_SET',
  GTE = '>=',
  LTE = '<=',
}

interface Props {
  className?: string
  id: string
  value?: DateCellFilterOperator
  onChange: (value: DateCellFilterOperator) => void
}

const options = Object.values(DateCellFilterOperator)

export const DateCellFilterOperatorLabel = {
  [DateCellFilterOperator.EQ]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.equal',
  }),
  [DateCellFilterOperator.NOT_SET]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.notSet',
  }),
  [DateCellFilterOperator.GTE]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.greaterThanEqual',
  }),
  [DateCellFilterOperator.LTE]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.lessThanEqual',
  }),
}

const DateCellOperatorSelect = ({ className, id, value, onChange }: Props) => {
  return (
    <Select
      className={className}
      key={id}
      options={options}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
      }}
      value={value}
      onChange={event => {
        onChange(event.target.value as DateCellFilterOperator)
      }}
      getOptionValue={o => o}
      getOptionLabel={o => DateCellFilterOperatorLabel[o]}
    />
  )
}

export default DateCellOperatorSelect
