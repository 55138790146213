import React from 'react'
import { styled } from '@mui/material'
import { DisplayToolbar, DisplayToolbarProps } from './DisplayToolbar'
import { ReportToolbar, ReportToolbarProps } from './ReportToolbar'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ToolbarFilterGroupProps } from '../../../components/buttons/ToolbarFilterButtonGroup'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
}

type Props = OwnProps &
  DisplayToolbarProps &
  ReportToolbarProps &
  ToolbarFilterGroupProps

export const ProjectPlanToolbar = (props: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === props.toolbar && (
        <DisplayToolbar
          wbsItemType={props.wbsItemType}
          onChangeWbsItemType={props.onChangeWbsItemType}
          aggregateType={props.aggregateType}
          onChangeAggregateType={props.onChangeAggregateType}
          workloadUnit={props.workloadUnit}
          onChangeWorkloadUnit={props.onChangeWorkloadUnit}
          onChangeColumnFilter={props.onChangeColumnFilter}
          showGanttParameter={props.showGanttParameter}
          filteredColumns={props.filteredColumns}
          sortedColumns={props.sortedColumns}
          onDeletedFilterColumn={props.onDeletedFilterColumn}
        />
      )}
      {ToolbarToggleValue.REPORT === props.toolbar && (
        <ReportToolbar
          aggregateType={props.aggregateType}
          onChangeAggregateType={props.onChangeAggregateType}
          wbsItemType={props.wbsItemType}
          onChangeWbsItemType={props.onChangeWbsItemType}
          workloadUnit={props.workloadUnit}
          onChangeWorkloadUnit={props.onChangeWorkloadUnit}
          rootNode={props.rootNode}
        />
      )}
    </Toolbar>
  )
}

export const ToolbarGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})
