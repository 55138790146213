import { useCallback, useEffect, useMemo, useRef } from 'react'
import { Fade, Slider, SliderThumb } from '@mui/material'
import _ from 'lodash'
import { Color } from '../../../../../../../../../styles/commonStyles'
import { GanttCellRendererProps } from './index'
import { alpha, styled } from '@mui/system'
import GanttDate from './ganttDate'
import objects from '../../../../../../../../../utils/objects'
import { WbsItemTypeVO } from '../../../../../../../../../domain/value-object/WbsItemTypeVO'
import { useGanttIndex } from './useGanttIndex'
import { RowNode } from 'ag-grid-enterprise'

const startEditing = (row: RowNode) => {
  if (!row.id) return
  const ganttCell = document.getElementById(
    `sevend-ag-cell-gantt-chart-${row.id}`
  )
  if (!ganttCell) return
  ganttCell.classList.add('sevend-ag-cell-gantt-chart-editing')
}
const finishEditing = (row: RowNode) => {
  if (!row.id) return
  const ganttCell = document.getElementById(
    `sevend-ag-cell-gantt-chart-${row.id}`
  )
  if (!ganttCell) return
  ganttCell.classList.remove('sevend-ag-cell-gantt-chart-editing')
}
export const GanttSlider = (props: GanttCellRendererProps) => {
  const dataPath = props.dataPath ? props.dataPath + '.' : ''
  const getWbsItem = (data?) => {
    return objects.getValue(data ?? props.data, `${dataPath}wbsItem`)
  }

  const ganttDate = useMemo(
    () =>
      new GanttDate({
        scale: props.timeScale,
        dateTerm: getWbsItem().scheduledDate,
      }),
    [props.value, props.parameter]
  )

  const ganttIndex = useGanttIndex(ganttDate)
  const sliderValue = useRef<number[]>([])
  sliderValue.current = ganttIndex.value

  useEffect(() => {
    if (getWbsItem().scheduledDate) {
      ganttIndex.initialize(ganttDate.startIndex, ganttDate.endIndex)
    }
  }, [props.value, props.parameter])

  const onEdit = useCallback(() => {
    if (!props.editable) return
    try {
      const movedRows = ganttIndex.getMovingRows()
      if (movedRows.length > 0) {
        movedRows.forEach(row => finishEditing(row))
      } else {
        finishEditing(props.node)
      }

      const updated = ganttIndex.edit(_.cloneDeep(props.data), dataPath)
      updated && props.updateRows(updated)
    } finally {
      props.api.clearRangeSelection()
    }
  }, [ganttIndex.value])

  if (
    (props.timeScale.length < ganttDate.startIndex &&
      props.timeScale.length < ganttDate.endIndex) ||
    (ganttDate.startIndex < 0 && ganttDate.endIndex < 0)
  ) {
    return <></>
  }
  const wbsItem = getWbsItem()
  return (
    <Fade in={true} timeout={300}>
      <StyledSlider
        key={`gantt-slider-${props.rowIndex}`}
        value={ganttIndex.value}
        size="small"
        components={{ Thumb: StyledSliderThumb }}
        valueLabelDisplay="auto"
        valueLabelFormat={(v, index) => {
          return index === 0
            ? ganttDate.getStartDateByIndex(v).format('M/D')
            : ganttDate.getEndDateByIndex(v).format('M/D')
        }}
        componentsProps={
          props.editable
            ? {
                track: {
                  onMouseDown: () => {
                    const selectedRows = props.api.getSelectedNodes()
                    if (selectedRows.length > 0) {
                      selectedRows.forEach(row => startEditing(row))
                    } else {
                      startEditing(props.node)
                    }
                    ganttIndex.moveWithTrack(selectedRows)
                  },
                },
                thumb: {
                  onMouseDown: () => {
                    const selectedRows = props.api.getSelectedNodes()
                    if (selectedRows.length > 0) {
                      selectedRows.forEach(row => startEditing(row))
                    } else {
                      startEditing(props.node)
                    }
                    ganttIndex.moveWithThumb(selectedRows)
                  },
                },
              }
            : undefined
        }
        onChange={(
          event: Event,
          val: number | number[],
          activeThumb: number
        ) => {
          if (!props.editable) return
          const indexes = typeof val === 'number' ? [val, val] : val
          if (event.type === 'mousedown') {
            ganttIndex.setMoveWithTrackOrigin(indexes, activeThumb)
          }
          if (event.type !== 'mousemove') return
          ganttIndex.move(indexes, activeThumb)
        }}
        onChangeCommitted={onEdit}
        max={props.timeScale.length}
        wbsitemtype={wbsItem.wbsItemType}
        scheduled={
          +(
            !!wbsItem.scheduledDate?.startDate &&
            !!wbsItem.scheduledDate?.endDate
          )
        }
        disablethumbs={[!!ganttIndex.startDisabled, !!ganttIndex.endDisabled]}
        index={props.rowIndex}
      />
    </Fade>
  )
}

enum SliderColor {
  SLIDER_TASK = '#888888',
  SLIDER_NOT_SCHEDULED = '#cccccc',
  SLIDER_ANCESTORS = '#686868',
}

function getSliderColor(props: StyledSliderProps) {
  if (!props.scheduled) {
    return SliderColor.SLIDER_NOT_SCHEDULED
  } else if (!props.wbsitemtype?.isTask()) {
    return SliderColor.SLIDER_ANCESTORS
  }
  return SliderColor.SLIDER_TASK
}

type StyledSliderProps = {
  // Reduce console errors
  scheduled: number
  wbsitemtype?: WbsItemTypeVO
  disablethumbs: boolean[]
  index: number
}
const StyledSlider = styled(Slider)<StyledSliderProps>(props => {
  const sliderColor = getSliderColor(props)
  const isWorkGroupOrProcess =
    props.wbsitemtype?.isWorkgroup() || props.wbsitemtype?.isProcess()
  const isArrow =
    props.wbsitemtype?.isDeliverableList() || props.wbsitemtype?.isDeliverable()
  return {
    height: '4px',
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    touchAction: 'none',
    pointerEvents: 'none',
    margin: 0,
    zIndex: 3,
    '& .MuiSlider-root': {
      color: sliderColor,
    },
    '& .MuiSlider-rail': {
      height: 0,
    },
    '& .MuiSlider-track': {
      display: 'block',
      position: 'absolute',
      pointerEvents: props.disablethumbs.some(v => !!v) ? 'none' : 'all',
      cursor: 'pointer',
      height: `10px`,
      backgroundColor: 'transparent',
    },
    '& .MuiSlider-track::after': {
      content: '""',
      position: 'absolute',
      opacity: props.wbsitemtype?.isTask() ? 0.75 : 1,
      transition: 'all 0.1s ease !important',
      width: '100%',
      height: `${isWorkGroupOrProcess || isArrow ? 10 : 3}px`,
      backgroundColor: !props.scheduled ? 'transparent' : sliderColor,
      backgroundImage: !props.scheduled
        ? `repeating-linear-gradient(-90deg, transparent, transparent 2px, ${sliderColor} 2px, ${sliderColor} 5px)`
        : undefined,
      transform: `translateY(${isWorkGroupOrProcess || isArrow ? 1 : 3.5}px)`,
      borderRadius: isWorkGroupOrProcess ? 1 : 'inherit',
      clipPath:
        isWorkGroupOrProcess && !props.disablethumbs[1]
          ? 'polygon(calc(100% - 4px) 100%, 100% calc(50% + 0.5px), 100% calc(50% - 0.5px), calc(100% - 4px) 0, 0 0, 0 100%)'
          : isArrow && !props.disablethumbs[1]
          ? 'polygon(0 3.5px, calc(100% - 5px) 3.5px, calc(100% - 5px) 0, 100% 50%, calc(100% - 5px) 100%, calc(100% - 5px) calc(100% - 3.5px), 0 calc(100% - 3.5px))'
          : isArrow && props.disablethumbs[1]
          ? 'polygon(0 3.5px, 100% 3.5px, 100% calc(100% - 3.5px), 0 calc(100% - 3.5px))'
          : undefined,
    },
    '& .MuiSlider-valueLabel:before': {
      bottom: props.index === 0 ? undefined : 0,
      top: props.index === 0 ? '-8px' : undefined,
    },
  }
})

type StyledSliderThumbProps = {
  disabled?: boolean
  labeldisabled?: number
  thumb?: number
}
const StyledSliderThumbBase = styled(SliderThumb)<StyledSliderThumbProps>(
  props => {
    const { wbsitemtype, index } = props['ownerState']
    const transparentThumb =
      wbsitemtype?.isWorkgroup() ||
      wbsitemtype?.isProcess() ||
      ((wbsitemtype?.isDeliverableList() || wbsitemtype?.isDeliverable()) &&
        props.thumb === 1)
    const hoverStyle = {
      boxShadow: `0 0 0 0.25rem ${alpha(Color.GREY, 0.15)}`,
      transform: `scale(1.5)`,
    }
    const invisibleDefaultHoverStyle = {
      boxShadow: `0 0 0 0 transparent`,
    }
    return {
      backgroundColor: 'transparent',
      top: `${transparentThumb ? 54 : 50}%`,
      height: `${props.disabled ? 0 : 20}px`,
      width: `${props.disabled ? 0 : 20}px`,
      borderRadius: '50%',
      color: `${
        transparentThumb ? 'transparent' : getSliderColor(props['ownerState'])
      }`,
      boxSizing: 'border-box',
      outline: 0,
      pointerEvents: 'all',
      cursor: 'pointer',
      margin: 0,
      '&::after': {
        height: `${props.disabled ? 0 : transparentThumb ? 10 : 7}px`,
        width: `${props.disabled ? 0 : transparentThumb ? 10 : 7}px`,
        borderRadius: '50%',
        transition: 'all 0.1s ease !important',
        transformOrigin: `${transparentThumb ? 15 : 11}px ${
          transparentThumb ? 15 : 11
        }px`,
        backgroundColor: `${
          transparentThumb ? 'transparent' : getSliderColor(props['ownerState'])
        }`,
      },
      ':hover': invisibleDefaultHoverStyle,
      '&.Mui-focusVisible': invisibleDefaultHoverStyle,
      '&.Mui-active': invisibleDefaultHoverStyle,
      ':hover::after': hoverStyle,
      '&.Mui-focusVisible::after': hoverStyle,
      '&.Mui-active::after': hoverStyle,
      '& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
        transform: `translateY(${index === 0 ? 40 : -120}%) scale(0.9)`,
      },
      '& .MuiSlider-valueLabel': {
        top: `${index === 0 ? 54 : 0}%`,
        transition: 'all 0.1s ease !important',
        visibility:
          props.disabled || props.labeldisabled ? 'hidden' : undefined,
      },
    }
  }
)

function StyledSliderThumb(props) {
  const { children, ...other } = props
  const thumb = other['data-index']
  const { disablethumbs, value, min, max } = props['ownerState']
  return (
    <StyledSliderThumbBase
      {...other}
      thumb={thumb}
      disabled={
        (thumb === 0 && disablethumbs[0]) || (thumb === 1 && disablethumbs[1])
      }
      labeldisabled={
        +(
          (thumb === 0 && value[thumb] === min) ||
          (thumb === 1 && value[thumb] === max)
        )
      }
    >
      {children}
    </StyledSliderThumbBase>
  )
}
