import store from '../store'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../store/messages'
import TenantStorage from './storage'
import { UserBasic } from '../lib/functions/user'
import { generateUuid } from './uuids'
import { intl } from '../i18n'

export const DEFAULT_MAX_FILE_SIZE_MB = 200

export interface Attachment {
  uuid: string
  source: string
  url: string
  name: string
  size?: number
  updatedBy?: UserBasic
  updatedAt?: number
}

export interface AttachmentSummary {
  hasAttachment: boolean
  totalAttachmentCount: number
}

export enum FileType {
  LOCAL = 'LOCAL',
  GOOGLE_DRIVE = 'GOOGLE_DRIVE',
  BOX = 'BOX',
  LINK = 'LINK',
}

type AttachingFile = {
  name: string
  url: string
  source: string
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const exceedMaxSize = (acceptedFiles: any[], maxSizeMB: number) => {
  const maxSize = maxSizeMB * 1024 ** 2
  let fileNames = ''
  for (let i = 0; i < acceptedFiles.length; i++) {
    if (acceptedFiles[i].size > maxSize) {
      fileNames = fileNames + '\n' + acceptedFiles[i].path
    }
  }
  if (fileNames.length > 0) {
    store.dispatch(
      addScreenMessage(store.getState().appFunction.currentExternalId!, {
        type: MessageLevel.WARN,
        title: intl.formatMessage(
          { id: 'attachment.title.maxSizeLimit' },
          {
            maxSizeMB: maxSizeMB,
            fileNames: fileNames,
          }
        ),
      })
    )
    return true
  }
  return false
}

export const uploadFiles = async (files: any[]) => {
  const promiseArray = [] as Promise<string>[]
  for (let i = 0; i < files.length; i++) {
    promiseArray.push(
      TenantStorage.uploadFile(files[i]).then(
        response => response.json.downloadUrl
      )
    )
  }
  const results = await Promise.all(promiseArray)
  const urls = results.filter(url => !!url)
  if (files.length > urls.length) {
    const title = intl.formatMessage({
      id: 'attachment.error.uploadFailed.fileOpened',
    })
    store.dispatch(
      addGlobalMessage({
        type: MessageLevel.WARN,
        title,
      })
    )
    throw new Error('File upload failed.')
  }
  const newAttachments = [] as Attachment[]
  for (let i = 0; i < urls.length; i++) {
    newAttachments.push({
      uuid: generateUuid(),
      source: FileType.LOCAL,
      name: files[i].path,
      url: urls[i],
      size: files[i].size,
    })
  }
  return newAttachments
}

export const deleteFile = async (url: string) => {
  try {
    TenantStorage.deleteFileByUrl(url)
  } catch (err) {
    console.debug(err)
  }
}

export const isAttached = (
  externalId: string,
  attachingFile: AttachingFile,
  attachedFiles: Attachment[]
): boolean => {
  let messageId: string | null = null
  for (let i = 0; i < attachedFiles.length; i++) {
    // check if the same source and same name
    if (
      FileType.LOCAL === attachingFile.source &&
      FileType.LOCAL === attachedFiles[i].source &&
      attachingFile.name === attachedFiles[i].name
    ) {
      messageId = 'attachment.file.exists'
    } else if (
      FileType.LINK === attachingFile.source &&
      FileType.LINK === attachedFiles[i].source
    ) {
      if (attachingFile.url === attachedFiles[i].url) {
        messageId = 'attachment.link.exists'
      } else if (attachingFile.name === attachedFiles[i].name) {
        messageId = 'attachment.name.exists'
      }
    }

    if (messageId) {
      store.dispatch(
        addScreenMessage(externalId, {
          type: MessageLevel.WARN,
          title: intl.formatMessage(
            { id: messageId },
            {
              name: attachingFile.name,
              url: attachingFile.url,
            }
          ),
        })
      )
      return true
    }
  }

  return false
}
