import { ICellEditorParams } from 'ag-grid-community'
import { InputBase } from '@mui/material'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  parseFullNumberToHalf,
  parseTimeToDecimal,
} from '../../../../../utils/number'
import { DecimalAndSexagesimalScale } from '../../../../../lib/functions/decimalAndSexagesimalScale'
import { convertDecimalToTime } from '../../../../../utils/time'

export const ActualWorkTimeCellEditor = forwardRef(
  (props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(props.charPress ?? props.value ?? '')

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      inputRef.current?.focus()
      const isCellEmpty = typeof value !== 'number'
      if (isCellEmpty) return
      if (
        props.context.decimalScaleUnit ===
        DecimalAndSexagesimalScale.SEXAGESIMAL
      ) {
        value && setValue(convertDecimalToTime(value))
      }
      if (
        props.context.decimalScaleUnit === DecimalAndSexagesimalScale.DECIMAL
      ) {
        value && setValue(value.toFixed(2))
      }
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          if (value === undefined || value === null) return undefined
          if (
            value.toString().includes(':') ||
            value.toString().includes('：') ||
            value.toString().includes(';') ||
            value.toString().includes('；')
          ) {
            const num = parseTimeToDecimal(value.toString())
            return Number.isFinite(num) ? Number(num.toFixed(2)) : undefined
          }
          const str = parseFullNumberToHalf(value.toString())
          const num = parseFloat(str)
          return Number.isFinite(num) ? Number(num.toFixed(2)) : undefined
        },
      }
    })

    return (
      <InputBase
        inputRef={inputRef}
        value={value}
        onChange={e => {
          setValue(e.target.value)
        }}
      />
    )
  }
)
