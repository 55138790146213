import { styled } from '@mui/system'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { intl } from '../../../../i18n'

const FooterRoot = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  height: '92px',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  fontSize: '12px',
  color: '#7B8CAA',
  width: '100vw',
  padding: '16px',
  flexDirection: 'column',
  position: 'absolute',
  bottom: '0',
  gap: '16px',
})

const FooterLinkArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

const FooterLinkDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  fontSize: '12px',
  color: '#7B8CAA',
  alignItems: 'center',
  height: '32px',
  padding: '8px 12px',
  gap: '4px',
})

const FooterLink = styled('a')({
  color: '#7B8CAA',
  textDecoration: 'none',
})

const StyledOpenInNewIcon = styled(OpenInNewRoundedIcon)({
  width: '16px',
  height: '16px',
})

const CorporateName = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

export const LoginFooter = () => {
  return (
    <FooterRoot>
      <FooterLinkArea>
        <FooterLinkDiv>
          <FooterLink
            href="https://www.flagxs.com/privacypolicy"
            target="_blank"
          >
            {intl.formatMessage({ id: 'login.privacyPolicy' })}
          </FooterLink>
          <StyledOpenInNewIcon />
        </FooterLinkDiv>
        <FooterLinkDiv>
          <FooterLink
            href="https://www.flagxs.com/privacypolicy#personal"
            target="_blank"
          >
            {intl.formatMessage({ id: 'login.privacyPolicy.personal' })}
          </FooterLink>
          <StyledOpenInNewIcon />
        </FooterLinkDiv>
        <FooterLinkDiv>
          <FooterLink href="https://www.flagxs.com/terms" target="_blank">
            {intl.formatMessage({ id: 'login.termsOfService' })}
          </FooterLink>
          <StyledOpenInNewIcon />
        </FooterLinkDiv>
        <FooterLinkDiv>
          <FooterLink href="https://www.flagxs.com/security" target="_blank">
            {intl.formatMessage({ id: 'login.basicPolicy' })}
          </FooterLink>
          <StyledOpenInNewIcon />
        </FooterLinkDiv>
      </FooterLinkArea>
      <CorporateName>
        <span>&copy; Flagxs Inc,</span>
      </CorporateName>
    </FooterRoot>
  )
}
