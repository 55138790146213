import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { styled } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { muiTheme } from '../../../../styles/muiTheme'
import Select from '../../../components/editors/select/Select'

export type AddRowCountInputDialogState = {
  open: boolean
  title?: string
  submitHandler?: (addRowCount: number) => void
  closeHandler?: Function
}

type Props = AddRowCountInputDialogState & {
  initialValue?: number
  disabled?: boolean
  children?: React.ReactNode
} & WrappedComponentProps

const MAX_ADD_ROW_COUNT = 20

const AddRowCountInputDialog = ({
  open,
  title,
  submitHandler,
  closeHandler,
  initialValue,
  disabled,
  children,
  intl,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>()
  const submitButtonRef = useRef<HTMLButtonElement>(null)

  const [count, setCount] = useState<number>(initialValue ?? 1)

  useEffect(() => {
    setTimeout(() => {
      open && inputRef.current?.focus()
    }, 100)
  }, [open])

  const handleEnter = useCallback(() => {
    submitButtonRef.current?.focus()
  }, [])

  const handleFocusOnCancelButton = useCallback(() => {
    submitButtonRef.current?.blur()
  }, [])

  const onClose = useCallback(() => {
    closeHandler && closeHandler()
  }, [closeHandler])

  const onSubmit = useCallback(() => {
    submitHandler?.(count)
  }, [count, submitHandler])

  const onChange = useCallback(event => setCount(event.target.value), [count])

  return (
    <Dialog
      open={open}
      TransitionProps={{ onEnter: handleEnter }}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {title !== undefined && (
        <DialogTitle id="dialog-title">{title}</DialogTitle>
      )}
      <DialogContent>
        {children}
        <DialogContentText id="dialog-description">
          <Select
            required={true}
            value={count}
            onChange={onChange}
            variant="outlined"
            options={[
              ...Array.from({ length: MAX_ADD_ROW_COUNT }, (e, i) => i + 1),
            ].map(i => ({
              label: i,
              value: i,
            }))}
            inputRef={inputRef}
            sx={{
              '& .MuiSelect-select': {
                padding: '3px 7px',
                display: 'inline-flex',
              },
            }}
          />
          &nbsp;
          {intl.formatMessage({
            id: 'rows',
          })}
          &nbsp;&nbsp;&nbsp;(
          {intl.formatMessage(
            {
              id: 'bulksheet.contextMenu.insert.multipleRow.info.maxRow',
            },
            {
              limit: MAX_ADD_ROW_COUNT,
            }
          )}
          )
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          onClick={onSubmit}
          color="primary"
          ref={submitButtonRef}
          disabled={disabled || !count}
        >
          {intl.formatMessage({
            id: 'add',
          })}
        </DialogActionButton>
        <DialogActionButton
          onClick={onClose}
          color="primary"
          onMouseOver={handleFocusOnCancelButton}
        >
          {intl.formatMessage({
            id: 'dialog.close',
          })}
        </DialogActionButton>
      </DialogActions>
    </Dialog>
  )
}

const theme = muiTheme
const DialogActionButton = styled(Button)({
  '&:hover, &:focus': {
    backgroundColor: alpha(
      theme.palette.text.primary,
      theme.palette.action.hoverOpacity
    ),
  },
})

export default injectIntl(AddRowCountInputDialog)
