import { useCallback, useState } from 'react'
import {
  EntitySearchFilter,
  EntitySearchFilterType,
} from './EntitySearchFilterModel'
import {
  ReferencedEntity,
  ReferencedEntityWithIcon,
} from '../../../../../../domain/value-object/ReferencedEntity'

export const useEntitySearchFilter = (): {
  init: (options: ReferencedEntityWithIcon[]) => void
  filterType: EntitySearchFilterType
  setFilterType: (_: EntitySearchFilterType) => void
  selectedValues: ReferencedEntityWithIcon[]
  setSelectedValues: (_: ReferencedEntityWithIcon[]) => void
  isActive: () => boolean
  model: () => EntitySearchFilter | undefined
  reset: () => void
} => {
  const [filterType, _setFilterType] = useState<EntitySearchFilterType>(
    EntitySearchFilterType.ALL
  )
  const [selectedValues, setSelectedValues] = useState<
    ReferencedEntityWithIcon[]
  >([])

  const setFilterType = useCallback(
    (newFilterType: EntitySearchFilterType) => {
      _setFilterType(newFilterType)
      if (
        newFilterType !== EntitySearchFilterType.SELECTED_VALUES &&
        selectedValues.length > 0
      ) {
        setSelectedValues([])
      }
    },
    [selectedValues]
  )

  const reset = useCallback(() => {
    setFilterType(EntitySearchFilterType.ALL)
    setSelectedValues([])
  }, [])

  const isActive = useCallback(() => {
    switch (filterType) {
      case EntitySearchFilterType.ALL:
        return false
      case EntitySearchFilterType.BLANK:
        return true
      case EntitySearchFilterType.SELECTED_VALUES:
        return selectedValues.length > 0
    }
  }, [filterType, selectedValues])

  const model = useCallback(() => {
    if (isActive()) {
      return { filterType, selectedValues }
    }
    return undefined
  }, [isActive, filterType, selectedValues])

  const init = (o: ReferencedEntity[]) => {
    setSelectedValues(o)
  }

  return {
    init,
    filterType,
    setFilterType,
    selectedValues,
    setSelectedValues,
    isActive,
    model,
    reset,
  }
}
