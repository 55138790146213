import { BulkSheetColumnState } from './../../model/bulkSheetColumnAndFilterState'
import { useSearchConditionAndColumnStateStorageService } from './../../../services/storage-services/ui-states/searchConditionAndColumnStateStorageService'
import { useWbsItemSearchConditionUrlTransformService } from '../../../services/transform-service/wbsItemSearchConditionUrlTransformService'
import { useWbsItemSearchConditionStorageService } from '../../../services/storage-services/ui-states/wbsItemSearchConditionStorageService'
import { useCallback, useEffect, useState } from 'react'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { WbsItemSearchConditionVO } from '../../../domain/value-object/WbsItemSearchConditionVo'
import { getUrlQueryObject } from '../../../utils/urls'

const wbsItemSearchSearchConditionFields = [
  'searchText',
  'rootUuid',
  'code',
  'types',
  'ticketTypes',
  'status',
  'priority',
  'displayName',
  'description',
  'teamUuid',
  'accountableUuid',
  'responsibleUuid',
  'assigneeUuid',
  'scheduledStartDate',
  'scheduledEndDate',
  'actualStartDate',
  'actualEndDate',
  'blank',
  'createdAt',
  'updatedAt',
  'createdByUuid',
  'updatedByUuid',
  'sprintUuid',
  'tagUuids',
] as const

export type WbsItemSearchSearchCondition = Pick<
  WbsItemSearchConditionVO,
  (typeof wbsItemSearchSearchConditionFields)[number]
>

const wbsItemSearchDefaultSearchCondition = {
  searchText: '',
  types: [WbsItemType.TASK],
  status: [
    WbsItemStatus.TODO,
    WbsItemStatus.DOING,
    WbsItemStatus.REVIEW,
    WbsItemStatus.DONE,
  ],
}

type UpdateWbsItemSearchSearchCondition = (
  updated: Partial<WbsItemSearchSearchCondition>
) => void
type SaveWbsItemSearchSearchCondition = () => Promise<void>
type WbsItemSearchSearchConditionUseCases = {
  updateSearchCondition: UpdateWbsItemSearchSearchCondition
  saveSearchCondition: SaveWbsItemSearchSearchCondition
  overrideSearchCondition: (
    newSearchCondition: WbsItemSearchConditionVO
  ) => void
  resetSearchCondition: () => void
}

export const useWbsItemSearchSearchCondition = (
  functionUuid: string,
  projectUuid: string
): {
  initialized: boolean
  searchCondition: WbsItemSearchConditionVO
  // CAUTION: Currently, this is only used for permanent column state.
  // TODO: Manage column state samely as search condition.
  columnState: BulkSheetColumnState | undefined
} & WbsItemSearchSearchConditionUseCases => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [searchCondition, setSearchCondition] =
    useState<WbsItemSearchSearchCondition>(wbsItemSearchDefaultSearchCondition)
  const [columnState, setColumnState] = useState<
    BulkSheetColumnState | undefined
  >()

  const wbsItemSearchConditionRepository =
    useWbsItemSearchConditionStorageService()
  const wbsItemSearchConditionUrlTransformer =
    useWbsItemSearchConditionUrlTransformService()
  const searchConditionAndColumnStateStorageService =
    useSearchConditionAndColumnStateStorageService(functionUuid, projectUuid)

  const restoreFromUiState = useCallback(async () => {
    const storedSearchCondition = await wbsItemSearchConditionRepository.fetch(
      functionUuid,
      projectUuid
    )
    if (
      !storedSearchCondition ||
      Object.keys(storedSearchCondition).length === 0
    ) {
      return undefined
    }
    return storedSearchCondition
  }, [])

  const restoreFromURLQuery = useCallback(() => {
    const conditionFromUrl =
      wbsItemSearchConditionUrlTransformer.restoreFromUrl()
    if (!conditionFromUrl || Object.keys(conditionFromUrl).length === 0) {
      return undefined
    }
    return conditionFromUrl
  }, [])

  const restorePermanentUiState = useCallback(async () => {
    const urlObject = getUrlQueryObject()
    if (!urlObject) return undefined
    const code = urlObject.arg
    if (!code) return undefined
    const permanentUiState =
      await searchConditionAndColumnStateStorageService.getByCode(code)
    if (!permanentUiState) return undefined
    return permanentUiState.value
  }, [])

  useEffect(() => {
    const restore = async () => {
      const permanentUiState = await restorePermanentUiState()
      if (permanentUiState) {
        setSearchCondition(permanentUiState.searchCondition)
        setColumnState(permanentUiState.columnState)
        setInitialized(true)
        return
      }
      const searchConditionInURLQuery = restoreFromURLQuery()
      if (searchConditionInURLQuery) {
        setSearchCondition(searchConditionInURLQuery)
        setInitialized(true)
        return
      }
      const storedSearchCondition = await restoreFromUiState()
      if (storedSearchCondition) {
        setSearchCondition(storedSearchCondition)
        setInitialized(true)
        return
      }
      setInitialized(true)
    }
    restore()
  }, [])

  const updateSearchCondition = useCallback(
    (updated: Partial<WbsItemSearchSearchCondition>) => {
      setSearchCondition(prev => ({
        ...prev,
        ...updated,
      }))
    },
    []
  )

  const overrideSearchCondition = useCallback(
    (newSearchCondition: WbsItemSearchConditionVO) => {
      setSearchCondition(newSearchCondition)
    },
    []
  )

  const saveSearchCondition = useCallback(async () => {
    wbsItemSearchConditionRepository.save(
      functionUuid,
      projectUuid,
      searchCondition
    )
  }, [searchCondition])

  const resetSearchCondition = useCallback(() => {
    setSearchCondition(wbsItemSearchDefaultSearchCondition)
  }, [])

  return {
    initialized,
    searchCondition,
    updateSearchCondition,
    saveSearchCondition,
    overrideSearchCondition,
    resetSearchCondition,
    columnState,
  }
}
