import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'
import { intl } from '../../../../i18n'
import { Color, FontSize, FONT_FAMILY } from '../../../../styles/commonStyles'
import { HeaderDivider } from '../../headers/HeaderBar/PageHeader'

export enum ToolbarToggleValue {
  DISPLAY = 'DISPLAY',
  REPORT = 'REPORT',
}

const NoticeIcon = styled(Typography)({
  width: '6px',
  height: '6px',
  borderRadius: '8px',
  position: 'absolute',
  fontWeight: 'bold',
  backgroundColor: Color.ALERT,
  textAlign: 'center',
  top: '6px',
  right: '6px',
})

export const ToolbarToggleGroup = (props: {
  toggleItems: ToolbarToggleValue[]
  value: ToolbarToggleValue | undefined
  noticeItem?: ToolbarToggleValue
  isNotice?: boolean
  onChange: (value: ToolbarToggleValue | undefined) => void
}) => {
  const labels = useMemo(() => {
    const maps = new Map()
    maps.set(ToolbarToggleValue.DISPLAY, 'projectPlan.toolbar.toggle.display')
    maps.set(ToolbarToggleValue.REPORT, 'projectPlan.toolbar.toggle.report')
    return maps
  }, [])
  return (
    <ToggleButtonGroup
      value={props.value}
      onChange={(e, v) => props.onChange(v || undefined)}
      exclusive={true}
      sx={{
        height: '100%',
      }}
    >
      {props.toggleItems.map((v, i) => {
        return (
          <ToolbarToggleButton
            key={`toggle-${i}`}
            value={v}
            sx={{
              fontFamily: FONT_FAMILY,
              width: 60,
              border: 'none',
              fontSize: FontSize.LARGE,
              margin: '3px 2px !important',
              padding: '0 0 2px',
              '&.MuiToggleButton-root': {
                borderRadius: 0,
              },
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                borderBottom: `2px solid ${Color.MAIN}`,
                padding: 0,
              },
            }}
            label={labels.get(v)}
            isNotice={props.noticeItem === v && props.isNotice}
          />
        )
      })}
      <HeaderDivider />
    </ToggleButtonGroup>
  )
}

interface ToolbarLabelProps {
  label: string
  isNotice?: boolean
}

type ToolbarToggleButtonProps = ToolbarLabelProps & ToggleButtonProps

const ToolbarToggleButton = (props: ToolbarToggleButtonProps) => {
  return (
    <>
      <HeaderDivider />
      <Box
        sx={{
          position: 'relative',
          margin: 0,
          padding: 0,
          width: '100%',
        }}
      >
        <ToggleButton
          key={props.key}
          className={props.className}
          color={props.color}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
          onChange={props.onChange}
          selected={props.selected}
          size={props.size}
          sx={props.sx}
          value={props.value}
        >
          {intl.formatMessage({ id: props.label })}
        </ToggleButton>
        {props.isNotice && <NoticeIcon />}
      </Box>
    </>
  )
}

type StoredUiStateWithToolbarToggle = {
  toolbar: ToolbarToggleValue | undefined
} & any

export const storedUiStateWithToolbarToggleAdaptor = (
  src: StoredUiStateWithToolbarToggle
) => ({
  ...src,
  toolbar: typeof src.toolbar === 'undefined' ? undefined : src.toolbar,
})
