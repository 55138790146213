import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Avatar from '@mui/material/Avatar'
import Auth from '../../../../lib/commons/auth'
import store, { AllState } from '../../../../store'
import { connect } from 'react-redux'
import parseHtmlString, { DOMNode } from 'html-react-parser'
import {
  BackgroundColor,
  Color,
  FontSize,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import {
  deleteNews,
  fetchNews,
  getNewsGroupForUI,
  NewsGroupForUI,
  NewsType,
  readNews,
} from '../../../../store/news'
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import InfiniteScroll from 'react-infinite-scroller'
import WbsItemAPI, {
  getOpenWbsItemDetailSpec,
} from '../../../../lib/functions/wbsItem'
import {
  APPLICATION_FUNCTION_EXTERNAL_ID,
  getFunctionByPath,
} from '../../../pages'
import { intl } from '../../../../i18n'
import { pushFunctionLayer } from '../../../../store/functionLayer'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import { setTabKey, TabKey } from '../../../../store/information'
import usePrevious from '../../../hooks/usePrevious'
import useWindowUnloadEffect from '../../../hooks/useWindowUnloadEffect'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import NewsFilter from './FilterNewsCondition'
import NewsTitle from './NewsTitle'
import { colorPalette } from '../../../style/colorPallete'

const RootDiv = styled('div')<{ newsType: NewsType }>(({ newsType }) => {
  return {
    display: 'flex',
    width: '100%',
    minHeight: '45px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    flexDirection: 'column',
    cursor: newsType === NewsType.NEW_MANAGEMENT_NOTICE ? 'pointer' : undefined,
  }
})
const NewsListContainer = styled('div')({
  height: 'calc(100% - 7px)',
  overflowY: 'scroll',
})
const Message = styled('div')({
  color: TextColor.BLACK,
  fontSize: FontSize.MEDIUM,
  lineHeight: '25px',
})

const getFormatedNewsCreatedAt = (newsCreatedAt: DateTimeVO) => {
  return newsCreatedAt.toString('HH:mm')
}

const News = ({ news, user, appFunction }) => {
  const openSingleSheetDialog = useCallback(
    _.throttle(
      async (code: string) => {
        let wbsItemBasic = {} as any
        try {
          wbsItemBasic = (await WbsItemAPI.getBasicByCode(code)).json
        } catch (err: any) {
          if (err.code === 'NOT_FOUND') {
            store.dispatch(
              addScreenMessage(appFunction.currentExternalId, {
                type: MessageLevel.WARN,
                title: intl.formatMessage({
                  id: 'wbsItem.deleted',
                }),
              })
            )
            return
          } else {
            throw err
          }
        }
        const { json: wbsItem } = await WbsItemAPI.getDetail({
          uuid: wbsItemBasic.uuid,
        })
        const wbsItemDetailSpec = await getOpenWbsItemDetailSpec(true, wbsItem)
        store.dispatch(pushFunctionLayer(wbsItemDetailSpec.layer))
      },
      1000,
      {
        trailing: false,
      }
    ),
    []
  )

  const replaceHtml = useCallback((domNode: DOMNode) => {
    const domNodeTmp = domNode as DOMNode & {
      attribs?: { href?: string }
      children?: {
        data?: string
      }[]
    }
    // Convert html for open single sheet dialog.
    if (domNodeTmp.type === 'tag') {
      if (domNodeTmp.attribs && domNodeTmp.attribs.href) {
        const url = domNodeTmp.attribs.href
        const urlMatchResults = url.match(/(https|http):\/\/[a-zA-Z0-9\.]*/)
        if (!urlMatchResults?.length) {
          return domNode
        }
        const domainName = urlMatchResults[0]
        const urlParts = url.replace(domainName, '').split('/')
        if (urlParts.length < 1) {
          return domNode
        }
        const func = getFunctionByPath(`/${urlParts[1]}`)
        const nothingWbsItemCode = urlParts.length < 3
        if (
          !func ||
          func.externalId !== APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM ||
          nothingWbsItemCode
        ) {
          return domNode
        }
        const wbsItemCode = urlParts[2]
        const text =
          domNodeTmp.children && domNodeTmp.children.length > 0
            ? domNodeTmp.children[0].data
            : ''
        const linkStyle = domNodeTmp.attribs.href
          ? { color: TextColor.MONOTONE }
          : undefined
        return (
          <a
            style={linkStyle}
            href={urlMatchResults.input}
            onClick={e => {
              openSingleSheetDialog(wbsItemCode)
              e.preventDefault()
            }}
          >
            {text}
          </a>
        )
      }
    }
    return domNode
  }, [])

  return (
    <RootDiv
      key={news.uuid}
      newsType={news.newsType}
      onClick={e => {
        if (news.newsType === NewsType.NEW_MANAGEMENT_NOTICE) {
          store.dispatch(setTabKey(TabKey.MANAGEMENT_NOTICE))
        }
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderBottom: `1px solid ${colorPalette.monotone[2]}`,
        }}
      >
        <NewsTitle newsType={news.newsType} />
        <Typography
          style={{
            color: Color.MONOTONE,
            fontSize: FontSize.MEDIUM,
            fontWeight: FontWeight.NORMAL,
            letterSpacing: '0.2em',
          }}
        >
          {getFormatedNewsCreatedAt(new DateTimeVO(news.createdAt))}
        </Typography>
        <Button
          sx={{
            margin: '0 20px 0 auto',
            border: `1px solid ${colorPalette.monotone[2]}`,
            color: colorPalette.monotone[4],
            minWidth: '68px',
            '&:hover': {
              color: Color.MONOTONE,
              border: `1px solid ${colorPalette.monotone[4]}`,
              background: BackgroundColor.WHITE,
            },
          }}
          onClick={() => store.dispatch(deleteNews(user.uuid, [news.uuid]))}
        >
          <ClearRoundedIcon sx={{ height: '15px', marginRight: '5px' }} />
          <Typography>
            {intl.formatMessage({ id: 'information.delete' })}
          </Typography>
        </Button>
      </div>
      {news.newsType !== NewsType.NEW_MANAGEMENT_NOTICE && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px',
            padding: '15px',
          }}
        >
          <>
            {news.project && (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto 0',
                  marginRight: '15px',
                  maxWidth: '270px',
                  maxHeight: '24px',
                  alignItems: 'center',
                  minWidth: '100px',
                }}
              >
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    margin: '0 5px 0 0',
                    marginRight: '10px',
                    border: `1px solid ${colorPalette.monotone[2]}`,
                  }}
                  variant="rounded"
                  src={news.project.iconUrl}
                />
                <div
                  style={{
                    fontWeight: FontWeight.BOLD,
                    opacity: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: colorPalette.monotone[5],
                  }}
                >
                  {news.project.name}
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                color: TextColor.GREY,
                fontSize: FontSize.SMALL,
                margin: '0 auto 0 0',
              }}
            >
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  margin: '0 15px 0 0',
                  border: `1px solid ${colorPalette.monotone[2]}`,
                }}
                variant="circular"
                src={news.createdBy?.iconUrl}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  style={{
                    fontSize: '11px',
                    fontWeight: FontWeight.NORMAL,
                    color: Color.MONOTONE,
                    letterSpacing: '0.1em',
                  }}
                >
                  From
                </Typography>
                <div
                  style={{
                    maxWidth: '120px',
                    maxHeight: '34px',
                    margin: '2px 0',
                    fontSize: FontSize.MEDIUM,
                    fontWeight: 700,
                    color: TextColor.MONOTONE,
                    opacity: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {news.createdBy?.name}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
      <Box
        sx={{
          background: colorPalette.monotone[1],
          borderRadius: '4px',
          padding: '15px',
          margin: '0 20px 10px 20px',
          marginTop: () =>
            news.newsType === NewsType.NEW_MANAGEMENT_NOTICE ? '10px' : 0,
        }}
      >
        <Message>
          {parseHtmlString(news.message, {
            replace: replaceHtml,
          })}
        </Message>
      </Box>
    </RootDiv>
  )
}

const NewsList = ({
  newsList,
  user,
  hasMoreNews,
  currentNewsCount,
  appFunction,
}) => {
  const [beforeNewsCount, setBeforeNewsCount] = useState(0)
  const loading = useCallback(
    () => beforeNewsCount && beforeNewsCount === currentNewsCount,
    [currentNewsCount, beforeNewsCount]
  )
  const loadItems = useCallback(() => {
    if (user) {
      store.dispatch(fetchNews(user.uuid, currentNewsCount))
      setBeforeNewsCount(currentNewsCount)
    }
  }, [user, currentNewsCount, beforeNewsCount])

  const displayDateFormat = (date: DateTimeVO): string => {
    return date.toString(intl.formatMessage({ id: 'information.news.date' }))
  }
  const newsDateCheckFormat = (date: DateTimeVO): string => {
    return date.toString('yyyy/MM/dd')
  }
  let newsDate: DateTimeVO
  let newsDateFormat: string | undefined
  let check: boolean = false

  return (
    <NewsListContainer>
      <InfiniteScroll
        loadMore={loadItems}
        hasMore={hasMoreNews && !loading()} // "loading" callback prevent InfiniteScroll from sending same request.
        useWindow={false}
        threshold={150}
      >
        {newsList.map((news, index) => {
          if (index === 0) {
            newsDate = new DateTimeVO(news.createdAt)
            newsDateFormat = displayDateFormat(new DateTimeVO(news.createdAt))
          }
          if (index >= 1) {
            if (news.createdAt) {
              check =
                newsDateCheckFormat(newsDate) ===
                newsDateCheckFormat(new DateTimeVO(news.createdAt))
                  ? true
                  : false
            }
            if (!check) {
              newsDateFormat = displayDateFormat(new DateTimeVO(news.createdAt))
              newsDate = new DateTimeVO(news.createdAt)
            }
          }
          return (
            <>
              {!check && (
                <Box
                  sx={{
                    color: Color.MONOTONE,
                    fontSize: FontSize.X_LARGE,
                    fontWeight: 700,
                    letterSpacing: '0.1em',
                    marginTop: () => (index === 0 ? 0 : '20px'),
                  }}
                >
                  {newsDateFormat}
                </Box>
              )}
              <div
                key={`news-box${news.uuid}`}
                style={{ padding: '5px 0', marginBottom: '10px' }}
              >
                {/* {requiredToBorder && <Border classes={classes} />} */}
                <News
                  key={news.uuid}
                  news={news}
                  user={user}
                  appFunction={appFunction}
                />
              </div>
            </>
          )
        })}
      </InfiniteScroll>
    </NewsListContainer>
  )
}

const InformationNews = ({
  newsList,
  user,
  hasMoreNews,
  appFunction,
  openInformation,
  currentInformationTab,
}) => {
  const [selectedProjectUuid, setSelectedProjectUuid] = useState<
    string | undefined
  >(undefined)
  const [selectedNewsGroups, setSelectedNewsGroups] = useState<
    NewsGroupForUI[]
  >([])
  const [filteredNewsList, setFilteredNewsList] = useState([
    ...newsList.filter(v => !!v.newsType),
  ])
  const prevOpenInformation = usePrevious(openInformation)
  const readNewsCallback = useCallback(() => {
    const tenant = Auth.getCurrentTenant()
    tenant &&
      user &&
      store.dispatch(
        readNews(
          user.uuid,
          newsList
            .filter(v => !v.read)
            .map(v => v.uuid)
            .toArray()
        )
      )
  }, [user, newsList])

  // Read news on change information tab.
  useEffect(() => {
    return () => {
      readNewsCallback()
    }
  }, [])
  // Read news on close information tab.
  useEffect(() => {
    if (
      !openInformation &&
      prevOpenInformation &&
      currentInformationTab === TabKey.NEWS
    ) {
      readNewsCallback()
    }
  }, [openInformation, newsList])

  // Read news on unload window.
  const readNewsOnUnloadWindow = () => {
    if (openInformation && currentInformationTab === TabKey.NEWS) {
      readNewsCallback()
    }
  }
  useWindowUnloadEffect(readNewsOnUnloadWindow, true)

  useEffect(() => {
    let newFilteredNewsList = [...newsList.filter(v => !!v.newsType)]
    if (selectedProjectUuid) {
      newFilteredNewsList = newFilteredNewsList.filter(
        news => !news.project || news.project.uuid === selectedProjectUuid
      )
    }
    if (selectedNewsGroups.length > 0) {
      newFilteredNewsList = newFilteredNewsList.filter(news => {
        const newsGroup = getNewsGroupForUI(news.newsType)
        return newsGroup && selectedNewsGroups.includes(newsGroup)
      })
    }
    setFilteredNewsList(newFilteredNewsList)
  }, [selectedProjectUuid, selectedNewsGroups, newsList])

  const onChangeProjectUuid = useCallback(
    (projectUuid?: string) => {
      setSelectedProjectUuid(projectUuid)
    },
    [selectedProjectUuid]
  )
  const onChangeNewsGroups = useCallback(
    (newsGroups: NewsGroupForUI[]) => {
      setSelectedNewsGroups(newsGroups)
    },
    [selectedNewsGroups]
  )

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: '5px 20px',
      }}
    >
      <NewsFilter
        selectedNewsGroups={selectedNewsGroups}
        onChangeNewsGroups={onChangeNewsGroups}
        onChangeProjectUuid={onChangeProjectUuid}
        selectedProjectUuid={selectedProjectUuid}
      />
      <NewsList
        newsList={filteredNewsList}
        user={user}
        hasMoreNews={openInformation && hasMoreNews && newsList.size < 100}
        currentNewsCount={newsList.size}
        appFunction={appFunction}
      />
    </div>
  )
}

const mapStateToProps = (state: AllState) => ({
  newsList: state.news.newsList,
  user: state.user.user,
  hasMoreNews: state.news.hasMoreNews,
  appFunction: state.appFunction.functions,
  openInformation: state.information.open,
  currentInformationTab: state.information.tabKey,
})

export default connect(mapStateToProps)(InformationNews)
