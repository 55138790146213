import _ from 'lodash'
import { useCallback, useState } from 'react'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GridOptions,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { useBulkSheetState as useBulkSheetStateBase } from '../../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { getWorkMonthColumnIndex, moveWorkMonthColumns } from '../gridOptions'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'

export interface ResourcePlanBulkSheetState {
  base: {
    columnState: ColumnState[]
    filterState?: { [key: string]: any }
    initialized: boolean
    saveColumnState: (s: ColumnState[]) => void
    saveFilterState: (s: { [key: string]: any }) => void
    saveImmediately: () => void
  }
  restoreColumnState: () => void
  rememberColumnState: () => void
  resetColumnState: () => void
  restoreExpandedRows: () => void
  filteredColDefs: ColDef[]
  onFilterChanged: (e: FilterChangedEvent) => void
  onFirstDataRendered: () => void
  onGridReady: () => void
  onRowGroupOpened: (event: RowGroupOpenedEvent) => void
  sortedColStates: SortedColumnState[]
  onSortChanged: (e: SortChangedEvent) => void
}

export const useBulkSheetState = (
  gridOptions: GridOptions,
  functionUuid: string,
  uiStateKey: string
): ResourcePlanBulkSheetState => {
  const [filteredColDefs, setFilteredColDefs] = useState<ColDef[]>([])
  const [sortedColStates, setSortedColStates] = useState<SortedColumnState[]>(
    []
  )
  const [initColumnState, setInitColumnState] = useState<ColumnState[]>([])
  const [initWorkMonthColIndex, setInitWorkMonthColIndex] = useState<number>(0)

  const base = useBulkSheetStateBase(functionUuid, uiStateKey)

  const restoreColumnState = useCallback(() => {
    if (!gridOptions.columnApi || _.isEmpty(base.columnState)) return
    gridOptions.columnApi.applyColumnState({
      state: base.columnState,
      applyOrder: true,
    })
  }, [base.columnState, gridOptions.columnApi])

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && base.saveColumnState(columnState)
  }, [gridOptions.columnApi, base])

  const resetColumnState = useCallback((): void => {
    if (!gridOptions.api || !gridOptions.columnApi) return
    gridOptions.columnApi.applyColumnState({
      state: initColumnState,
      applyOrder: true,
    })
    gridOptions.api?.setFilterModel(null)
    gridOptions.api?.onFilterChanged()

    moveWorkMonthColumns(
      gridOptions.api,
      gridOptions.api.getColumnDefs(),
      initWorkMonthColIndex
    )
  }, [
    gridOptions.api,
    gridOptions.columnApi,
    initColumnState,
    initWorkMonthColIndex,
  ])

  const restoreExpandedRows = useCallback(() => {
    if (_.isEmpty(base.expandedRowIds) || !gridOptions.api) return
    setTimeout(() => {
      base.expandedRowIds.forEach(id => {
        gridOptions.api?.getRowNode(id)?.setExpanded(true)
      })
    }, 200)
  }, [base.expandedRowIds, gridOptions.api])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      base.saveFilterState(filterModel)
      setFilteredColDefs(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [base]
  )

  const onFirstDataRendered = useCallback(() => {
    restoreExpandedRows()
    restoreColumnState()
    if (!_.isEmpty(base.filterState)) {
      gridOptions.api?.setFilterModel(base.filterState)
    }
    base.finishRestoring()
  }, [gridOptions.api, base, restoreExpandedRows, restoreColumnState])

  const onGridReady = useCallback(() => {
    if (
      gridOptions.api &&
      gridOptions.columnApi &&
      _.isEmpty(initColumnState)
    ) {
      // If you use columnApi.resetColumnState, you will be reset to the column state set in columnApi.setColumnDefs.
      // columnapi.setColumnDefs is called in RefreshdynamicColumn, so it is not reset to the correct initial state.
      // Therefore, keep the initial column status.
      setInitColumnState(gridOptions.columnApi.getColumnState())
      // At this time, the work month columns have not been added yet, so obtain the position.
      setInitWorkMonthColIndex(
        getWorkMonthColumnIndex(gridOptions.api.getColumnDefs())
      )
    }
    restoreColumnState()
  }, [
    gridOptions.api,
    gridOptions.columnApi,
    initColumnState,
    restoreColumnState,
  ])

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        base.expandRows([key])
      } else {
        base.collapseRows([key])
      }
    },
    [base]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      const sortedColumnStates = e.columnApi
        .getColumnState()
        .filter((colState: ColumnState) => !!colState.sort)
        .map((colState: ColumnState) => {
          const colDef: ColDef | null = e.api.getColumnDef(colState.colId)
          if (!colDef) return
          return {
            colId: colState.colId,
            field: colDef,
            headerName: colDef.headerName,
            sort: colState.sort,
          } as SortedColumnState
        })
        .filter(v => !!v) as SortedColumnState[]
      e.api.setSuppressRowDrag(0 < sortedColumnStates.length)
      setSortedColStates(sortedColumnStates)
    },
    [rememberColumnState]
  )

  return {
    base,
    restoreColumnState,
    rememberColumnState,
    resetColumnState,
    restoreExpandedRows,
    filteredColDefs,
    onFilterChanged,
    onFirstDataRendered,
    onGridReady,
    onRowGroupOpened,
    sortedColStates,
    onSortChanged,
  }
}
