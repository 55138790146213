import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import CssBaseline from '@mui/material/CssBaseline'
import App from './App'
import { IntlProvider } from 'react-intl'
import translations from './i18n/locales'
import store from './store'
import { muiTheme } from './styles/muiTheme'
import * as serviceWorker from './serviceWorker'
import './styles/reactDraft.scss'
import globalErrorHandler from './handlers/globalErrorHandler'
import cacheExpirationHandler from './handlers/cacheExpirationHandler'
import authorizationHandler from './handlers/authorizationHandler'
import { LicenseManager } from 'ag-grid-enterprise'
import { extractRootDomainName } from './utils/urls'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { Router, history } from './view/router'

if (
  process.env.REACT_APP_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'staging'
) {
  if (!process.env.REACT_APP_AG_GRID_LICENSE_KEY) {
    throw new Error(
      `AgGrid licence key is required in ${process.env.REACT_APP_ENV}.`
    )
  }
  LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY)
}

// Check host domain.
const HOST_DOMAINS = process.env
  .REACT_APP_HOST_DOMAINS!.split(',')
  .map(v => v.trim())
const domain = extractRootDomainName()
if (!HOST_DOMAINS.includes(domain)) {
  throw new Error(
    `${domain} is unknown host for the build profile: ${process.env.REACT_APP_ENV}`
  )
}

globalErrorHandler.register()
cacheExpirationHandler.register()
authorizationHandler.register()

// Setup NewRelic.
if (
  process.env.REACT_APP_NEWRELIC_RELEASE_NAME &&
  process.env.REACT_APP_NEWRELIC_RELEASE_ID
) {
  newrelic.addRelease(
    process.env.REACT_APP_NEWRELIC_RELEASE_NAME,
    process.env.REACT_APP_NEWRELIC_RELEASE_ID
  )
}

// language without region code
const language = navigator.language.split(/[-_]/)[0]
const messages = translations[language] || translations.en

const root = createRoot(document.getElementById('root')!)
root.render(
  <IntlProvider key={language} locale={language} messages={messages}>
    <ThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst={true}>
        <Provider store={store}>
          <Router history={history}>
            <CssBaseline />
            <App />
          </Router>
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </IntlProvider>
)

serviceWorker.unregister()
