import { UserDetail } from '../lib/functions/user'
import { AgreementDetail } from '../lib/functions/agreement'

enum ActionType {
  RECEIVED_USER = 'RECEIVED_USER',
  LOGOUT = 'LOGOUT',
  LOGOUT_USER = 'LOGOUT_USER',
  RECEIVED_AGREEMENTS = 'RECEIVED_AGREEMENTS',
}

type State = {
  user?: UserDetail
  authorized?: boolean
  agreements?: AgreementDetail[]
}

export const receivedUser = (user: UserDetail) => ({
  type: ActionType.RECEIVED_USER,
  user,
})

export const logout = () => ({
  type: ActionType.LOGOUT,
})

export const logoutUser = () => ({
  type: ActionType.LOGOUT_USER,
})

export const receivedAgreements = (agreements: AgreementDetail[]) => ({
  type: ActionType.RECEIVED_AGREEMENTS,
  agreements,
})

export const reducer = (state: State = {}, action: any): State => {
  switch (action.type) {
    case ActionType.RECEIVED_USER:
      return { ...state, user: action.user, authorized: true }
    case ActionType.LOGOUT:
      return { ...state, authorized: false }
    case ActionType.LOGOUT_USER:
      return { ...state, user: undefined, authorized: false }
    case ActionType.RECEIVED_AGREEMENTS:
      return { ...state, agreements: action.agreements }
    default:
      return state
  }
}
