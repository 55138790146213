import * as React from 'react'
import { IconColor } from '../../../styles/commonStyles'
// AG Grid filter icon
// See https://www.ag-grid.com/javascript-data-grid/custom-icons/

const SvgFilterIcon = React.forwardRef(
  (
    props: { style?: React.CSSProperties },
    ref: React.LegacyRef<SVGSVGElement>
  ) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.style?.fontSize || 25}
      height={props.style?.fontSize || 25}
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        fill={props.style?.color || IconColor.GREY}
        strokeWidth={1}
        d="M6.25037 12.3092C6.03787 12.3092 5.85975 12.2373 5.716 12.0936C5.57225 11.9498 5.50037 11.7717 5.50037 11.5592V7.0592L1.01912 1.34045C0.844122 1.12795 0.819122 0.902954 0.944122 0.665454C1.06912 0.427954 1.26287 0.309204 1.52537 0.309204H12.4754C12.7379 0.309204 12.9316 0.427954 13.0566 0.665454C13.1816 0.902954 13.1566 1.12795 12.9816 1.34045L8.50037 7.0592V11.5592C8.50037 11.7717 8.4285 11.9498 8.28475 12.0936C8.141 12.2373 7.96287 12.3092 7.75037 12.3092H6.25037ZM7.00037 7.1342L11.5004 1.4342H2.50037L7.00037 7.1342Z"
      />
    </svg>
  )
)

export default SvgFilterIcon
