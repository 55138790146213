import React, { CSSProperties } from 'react'
import { styled } from '@mui/system'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import objectUtil from '../../../../utils/objects'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import WbsItemSearchCondition, {
  getDefaultSearchFilter,
  SearchConditionProps,
  SearchConditionRowHeaderCell,
  SearchFilter,
  WbsItemSearchConditionKey,
} from './WbsItemSearchConditions/WbsItemSearchCondition'
import SearchButton from '../../../components/buttons/SearchButton'
import { FilterInputCell, StyledTableRow } from '../../../containers/DataCell'
import { FontSize, TextColor } from '../../../../styles/commonStyles'
import AddIcon from '@mui/icons-material/Add'
import ResetButton from '../../../components/buttons/ResetButton'
import SeveConditionButton from './SaveConditionButton'
import SaveWbsItemSearchConditionDialog, {
  SaveWbsItemSearchConditionDialogProps,
} from './SaveWbsItemSearchConditionDialog'
import { getWbsItemSearchConditionItemLabel } from './utils'

const OptionButtonArea = styled('div')({
  display: 'contents',
})
const optionButtonStyle: CSSProperties = {
  padding: 0,
  background: '#F5F5F5 0% 0% no-repeat padding-box',
  border: '1px solid #DDDDDD',
}
const DetailConditionButton = styled(Button)({
  ...optionButtonStyle,
  borderRadius: '0px',
})
const OptionButtonInner = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: FontSize.SMALL,
  color: TextColor.BLUE,
})
const RootDiv = styled('div')({
  width: 800,
  height: '100%',
  padding: '9px',
  background: '#F5F5F5 0% 0% no-repeat padding-box',
  borderRadius: 4,
  boxShadow: '0px 0px 6px #0000001A',
})
const TopTableContainer = styled(TableContainer)({
  width: '100%',
  overflow: 'hidden',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #FFFFFF',
  boxShadow: 'none',
  marginBottom: '20px',
})
const StyledTableContainer = styled(TableContainer)({
  width: '100%',
  overflow: 'hidden',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #FFFFFF',
  boxShadow: 'none',
}) as typeof TableContainer
const StyledTable = styled(Table)({
  width: '100%',
})
const BottomButtonArea = styled('div')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginTop: '10px',
})
const StyledResetButton = styled(ResetButton)({
  marginLeft: 10,
})
const StyledSearchButton = styled(SearchButton)({
  fontSize: FontSize.MEDIUM,
  height: 22,
})
const StyledSeveConditionButton = styled(SeveConditionButton)({
  fontSize: FontSize.MEDIUM,
  width: 110,
})
const OpenSavedConditionButton = styled(Button)({
  ...optionButtonStyle,
  borderRadius: '0px 4px 4px 0px',
})

export const removeEmptyPropertiesFromSearchFilter = (
  searchFilter: SearchFilter
) => {
  const filter = JSON.parse(JSON.stringify(searchFilter))
  const removeEmptyProperties = (obj: any): boolean => {
    const keys = Object.keys(obj)
    const isUuidFilter = !!obj['uuid']
    return (
      keys
        .filter(key => {
          return (
            (obj[key] !== false && !obj[key]) ||
            (typeof obj[key] === 'object' && removeEmptyProperties(obj[key])) ||
            (Array.isArray(obj[key]) && obj[key].length === 0) ||
            (isUuidFilter &&
              !(key === 'uuid' || key === 'value' || key === 'name'))
          )
        })
        .map(key => {
          delete obj[key]
        }).length === keys.length
    )
  }
  removeEmptyProperties(filter)
  return filter
}

const structSearchConditions = (searchConditions: SearchConditionProps[]) => {
  let structure: SearchConditionProps[][] = []
  const children = searchConditions.sort(
    (a, b) =>
      (a.position.row - b.position.row) * 100 +
      (a.position.column - b.position.column)
  )

  children.forEach(condition => {
    const row = condition.position.row
    if (!structure[row - 1]) {
      structure[row - 1] = []
    }
    structure[row - 1].push(condition)
  })
  structure = structure.filter(row => row.length > 0)
  return structure
}

interface Props extends WrappedComponentProps {
  reloadHandler: () => void
  onChangeSearchText?: (text?: string) => void
  searchText?: string
  onChangeFilter: (v?: SearchFilter) => void
  searchFilter?: SearchFilter
  searchConditions: SearchConditionProps[]
  anchorEl?: HTMLElement
  saveDialogProps?: SaveWbsItemSearchConditionDialogProps
  openSavedUIStateDialog?: () => void
}

export interface State {
  searchFilter: SearchFilter
  filtered: boolean
  open: boolean
  openSaveUIStateEditor: boolean
}

class SearchFilterPanel extends React.PureComponent<Props, State> {
  private structure: SearchConditionProps[][]

  constructor(props) {
    super(props)
    this.structure = structSearchConditions(props.searchConditions)
  }

  state: State = {
    searchFilter: Object.assign(
      getDefaultSearchFilter(),
      this.props.searchFilter || {}
    ),
    filtered: false,
    open: false,
    openSaveUIStateEditor: false,
  }

  componentDidUpdate(oldProps: Props): void {
    const newProps = this.props
    if (
      (!oldProps.searchFilter && newProps.searchFilter) ||
      (newProps.searchFilter &&
        objectUtil.hasDiff(oldProps.searchFilter, newProps.searchFilter))
    ) {
      this.setState({
        searchFilter: newProps.searchFilter,
      })
    } else if (oldProps.searchFilter && !newProps.searchFilter) {
      this.resetFilter()
    }
  }

  private changePanelDisplay = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      open: !this.state.open,
    })
  }

  private searchButtonClick = () => {
    this.props.onChangeFilter(this.state.searchFilter)
    this.search()
  }

  private search = () => {
    this.closePanel()
    this.props.reloadHandler()
  }

  private closePanel() {
    this.setState({
      open: false,
    })
  }

  private updateSearchFilter = (props: { [key: string]: any }) => {
    const newCondition = { ...this.state.searchFilter }
    for (const [key, value] of Object.entries(props)) {
      objectUtil.setValue(newCondition, key, value)
    }
    this.setState(
      {
        filtered: true,
        searchFilter: newCondition,
      },
      () => {
        this.props.onChangeFilter(this.state.searchFilter)
      }
    )
  }

  private resetFilter = () => {
    this.setState(
      {
        filtered: false,
        searchFilter: getDefaultSearchFilter(),
      },
      () => {
        this.props.onChangeFilter(undefined)
      }
    )
  }

  private resetCondition = () => {
    this.setState(
      {
        filtered: false,
        searchFilter: getDefaultSearchFilter(),
      },
      () => {
        if (this.props.onChangeSearchText) {
          this.props.onChangeSearchText(undefined)
        }
        this.props.onChangeFilter(undefined)
      }
    )
  }

  render() {
    const { saveDialogProps, openSavedUIStateDialog } = this.props

    return (
      <OptionButtonArea>
        <DetailConditionButton
          variant="outlined"
          onClick={this.changePanelDisplay}
          aria-describedby="wbs-item-work-search-filter"
        >
          <OptionButtonInner>
            <AddIcon style={{ color: TextColor.BLUE }} />
            {this.props.intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.condition',
            })}
          </OptionButtonInner>
        </DetailConditionButton>
        <Popover
          id="wbs-item-work-search-filter"
          open={this.state.open}
          onClose={this.changePanelDisplay}
          anchorEl={this.props.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <RootDiv>
            {this.props.onChangeSearchText && (
              <TopTableContainer>
                <StyledTable size="small" aria-label="customized table">
                  <TableBody>
                    <StyledTableRow key={'fulll-text-search'}>
                      <SearchConditionRowHeaderCell align="left">
                        {getWbsItemSearchConditionItemLabel({
                          wbsItemSearchConditionKey:
                            WbsItemSearchConditionKey.ALL,
                        })}
                      </SearchConditionRowHeaderCell>
                      <FilterInputCell
                        colSpan={10}
                        value={this.props.searchText || ''}
                        type={'text'}
                        onChange={value => {
                          this.props.onChangeSearchText?.(value?.toString())
                        }}
                        clearable={true}
                      />
                    </StyledTableRow>
                  </TableBody>
                </StyledTable>
              </TopTableContainer>
            )}
            <StyledTableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {this.structure.map((search, index) => {
                    return (
                      <StyledTableRow key={`search-filter-table-row-${index}`}>
                        {search.map(
                          (row: SearchConditionProps, rowIndex: number) => {
                            return (
                              <WbsItemSearchCondition
                                key={`search-condition-${index}-${rowIndex}`}
                                conditionKey={row}
                                updateSearchFilter={this.updateSearchFilter}
                                search={this.search}
                                searchFilter={this.state.searchFilter}
                              />
                            )
                          }
                        )}
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <BottomButtonArea>
              <StyledResetButton
                label={this.props.intl.formatMessage({
                  id: `wbsItemSearchFilterPanel.reset`,
                })}
                onClick={this.resetCondition}
              />
              <StyledSearchButton
                onClick={this.searchButtonClick}
                hideIcon={true}
              />
              <StyledSeveConditionButton
                onClick={() => {
                  this.setState({ openSaveUIStateEditor: true })
                }}
                title={this.props.intl.formatMessage({
                  id: 'wbsItemSearchFilterPanel.save',
                })}
              />
            </BottomButtonArea>
          </RootDiv>
        </Popover>
        {saveDialogProps && openSavedUIStateDialog && (
          <>
            <SaveWbsItemSearchConditionDialog
              open={this.state.openSaveUIStateEditor}
              onClose={() => {
                this.setState({ openSaveUIStateEditor: false })
              }}
              applicationFunctionUuid={saveDialogProps.applicationFunctionUuid}
              getCurrentSearchCondition={
                saveDialogProps.getCurrentSearchCondition
              }
              getColumnAndFilterState={saveDialogProps.getColumnAndFilterState}
            />
            <OpenSavedConditionButton
              variant="outlined"
              onClick={() => {
                openSavedUIStateDialog()
              }}
            >
              <OptionButtonInner>
                {this.props.intl.formatMessage({
                  id: 'wbsItemSearchFilterPanel.saved.condition',
                })}
              </OptionButtonInner>
            </OpenSavedConditionButton>
          </>
        )}
      </OptionButtonArea>
    )
  }
}

export default injectIntl(SearchFilterPanel)
