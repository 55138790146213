import { styled } from '@mui/system'
import { ResourceBalanceFromNowOn } from './ResourceBalanceFromNowOn'
import { ResourceBalanceTimeSeries } from './ResourceBalanceTimeSeries'
import { ProjectDetail } from '../../../../../lib/functions/project'
import {
  ComponentHeader,
  ComponentHeaderEndDiv,
  ComponentTitle,
  ComponentWrapper,
} from '../../components'
import { intl } from '../../../../../i18n'
import { TeamSelector } from '../../components/TeamSelector'
import { useState } from 'react'
import { TeamSelectOption } from '../../model/team'
import { ProjectReportConfig } from '../../model/config'
import { colorPalette } from '../../../../style/colorPallete'

export const AVAILABLE_RESOURCE_COLOR = '#009AFF'
export const PLANNED_WORKLOAD_COLOR = colorPalette.pink[6]

type ResourceBalanceProps = {
  project: ProjectDetail
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const ResourceBalanceRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  padding: '12px 24px',
  gap: '24px',
})

export const ResourceBalance = ({
  project,
  aggregateTarget,
}: ResourceBalanceProps) => {
  const [selectedTeam, setSelectedTeam] = useState<TeamSelectOption[]>([])
  return (
    <ComponentWrapper>
      <ComponentHeader>
        <ComponentTitle>
          {intl.formatMessage({ id: 'resourceBalance' })}
        </ComponentTitle>
        <ComponentHeaderEndDiv>
          <TeamSelector
            projectUuid={project.uuid}
            updateSelection={setSelectedTeam}
          />
        </ComponentHeaderEndDiv>
      </ComponentHeader>
      <ResourceBalanceRoot>
        <ResourceBalanceFromNowOn
          projectUuid={project.uuid}
          selectedTeam={selectedTeam}
          aggregateTarget={aggregateTarget}
        />
        <ResourceBalanceTimeSeries
          project={project}
          selectedTeam={selectedTeam}
          aggregateTarget={aggregateTarget}
        />
      </ResourceBalanceRoot>
    </ComponentWrapper>
  )
}
