import { useCallback, useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { styled } from '@mui/system'
import Popper from '@mui/material/Popper'
import store from '../../../../store'
import AlertDialog, { DialogProps } from '../../dialogs/AlertDialog'
import appFunctionApi, {
  Cockpit,
  Directory,
  Function as AppFunction,
} from '../../../../lib/commons/appFunction'
import { intl } from '../../../../i18n'

interface AppFunctionProps {
  value: string
  label: string
  externalId: string
}

interface Props {
  projectUuid?: string
  onChangeValue: Function
  applicationFunctionUuid?: string
}

const AutoCompleteFunctionName = styled(Autocomplete)({
  width: '225px',
  minWidth: '175px',
  height: '30px',
  marginRight: '6px',
  '& .MuiAutocomplete-inputRoot': {
    height: '100%',
    paddingTop: '3px !important',
  },
})

const AppFunctionSelect = (props: Props) => {
  const [functions, setFunctions] = useState<AppFunctionProps[]>([])
  const [options, setOptions] = useState<string[]>([])
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('')
  const [pendingItemLabel, setPendingItemLabel] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>('')
  const [dialogState, setDialogState] = useState<DialogProps>({ isOpen: false })
  const [prevApplicationFunctionUuid, setPrevApplicationFunctionUuid] =
    useState<string>()

  useEffect(() => {
    makeOptions()
  }, [])

  useEffect(() => {
    const isChanged =
      prevApplicationFunctionUuid &&
      props.applicationFunctionUuid &&
      prevApplicationFunctionUuid !== props.applicationFunctionUuid
    if (
      (!prevApplicationFunctionUuid &&
        props.applicationFunctionUuid &&
        !selectedItemLabel) ||
      isChanged
    ) {
      const selected = functions.find(
        v => v.value === props.applicationFunctionUuid
      )
      if (selected) {
        const label = selected.label
        setSelectedItemLabel(label)
        setInputValue(label)
      }
    }
    if (prevApplicationFunctionUuid && !props.applicationFunctionUuid) {
      setSelectedItemLabel('')
      setInputValue('')
    }
    setPrevApplicationFunctionUuid(props.applicationFunctionUuid)
  }, [props.applicationFunctionUuid, prevApplicationFunctionUuid])

  const makeOptions = useCallback(async () => {
    const response = (await appFunctionApi.getFunctions()).json
    const cockpit = props.projectUuid ? Cockpit.Project : Cockpit.Tenant
    const functions: AppFunction[] = []
    const recursivePickFunction = (directory: Directory) => {
      if (directory.functions) {
        directory.functions
          .filter(func => func.configurable)
          .forEach(func => functions.push(func))
      }
      if (directory.directories) {
        directory.directories.forEach(dir => recursivePickFunction(dir))
      }
    }
    recursivePickFunction(
      response.directories.find(res => res.name === cockpit)
    )
    const options = functions.map((func: any) => func.name)
    const functionProps = functions.map((func: any) => ({
      value: func.uuid,
      label: func.name,
      externalId: func.externalId,
    }))
    const selected = functions.find(
      func => func.uuid === props.applicationFunctionUuid
    )
    setFunctions(functionProps)
    setOptions(options)
    setSelectedItemLabel(selected?.name ?? '')
    setInputValue('')
  }, [])

  const onInputChange = useCallback((event, value) => {
    setInputValue(value)
  }, [])

  const onChange = useCallback(
    (event, value) => {
      if (store.getState().hasRequiredSaveData.hasRequiredSaveData && value) {
        setPendingItemLabel(value)
        openConfirmationDialog()
        return
      }
      changeFunction(value)
    },
    [functions]
  )

  const changeFunction = useCallback(
    value => {
      setSelectedItemLabel(value)
      const selectedItem = functions.find(v => v.label === value)
      props.onChangeValue(selectedItem?.value, selectedItem?.externalId)
    },
    [functions]
  )

  const openConfirmationDialog = useCallback(() => {
    setDialogState({
      isOpen: true,
      title: intl.formatMessage({
        id: 'appFunctionSelect.confirm.title',
      }),
      message: intl.formatMessage({
        id: 'appFunctionSelect.confirm.message',
      }),
      submitButtonTitle: intl.formatMessage({ id: 'yes' }),
      submitHandler: onConfirmSubmit,
      closeHandler: onConfirmClose,
      closeButtonTitle: intl.formatMessage({ id: 'no' }),
    })
  }, [])

  const onConfirmSubmit = useCallback(() => {
    changeFunction(pendingItemLabel)
    setDialogState({ isOpen: false })
  }, [pendingItemLabel])

  const onConfirmClose = useCallback(() => {
    setInputValue(selectedItemLabel ?? '')
  }, [selectedItemLabel])

  const closeDialog = useCallback(() => {
    setDialogState({ isOpen: false })
  }, [])

  return (
    <>
      <AlertDialog {...dialogState} closeHandler={closeDialog} />
      <AutoCompleteFunctionName
        value={selectedItemLabel}
        options={options}
        size={'small'}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onInputChange}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={intl.formatMessage({
              id: 'appFunctionSelect.placeHolder',
            })}
            variant="outlined"
            fullWidth={true}
          />
        )}
      />
    </>
  )
}

export default AppFunctionSelect
