import moment from 'moment'
import { intl } from '../../i18n'
import DateVO from '../../vo/DateVO'

// abstract
export interface ReportDataSeries<K, D> {
  key: K
  value: DataPerDim<D>[]
}
export interface DataPerDim<D> {
  dimTime: number
  data: D
}
export interface SearchConditionBase {
  projectUuid?: string
}
export interface ViewConfigurationBase {
  timeGrain: TimeGrain
  startDayOfWeek: DayOfWeek
}
export interface ChartConfigurationBase<G extends GraphConfigurationBase<any>> {
  viewTime: {
    from?: DateVO
    to?: DateVO
  }
  graphConfig: G[]
}
export interface GraphConfigurationBase<K> {
  key: K
}
export abstract class ReportDataManager<K, D, V extends ViewConfigurationBase> {
  abstract getValue(data: DataPerDim<D>, viewConfig: V, keys?: string[]): number
  abstract getFormattedValue(
    data: DataPerDim<D>,
    viewConfig: V,
    key?: string
  ): string
  abstract add(a: D, b: D): D
  // a - b
  abstract subtract(a: D, b: D): D
  abstract accumulate(
    data: DataPerDim<D>[],
    dimTimes: number[],
    option: 'none' | 'burnUp' | 'burnDown'
  ): DataPerDim<D>[]
}

export const MAX_DIM_TIME = 9999999999999

export enum DayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const WEEKDAYS = [
  DayOfWeek.MONDAY,
  DayOfWeek.TUESDAY,
  DayOfWeek.WEDNESDAY,
  DayOfWeek.THURSDAY,
  DayOfWeek.FRIDAY,
]

export const mapDayOfWeekToNumber = (dayOfWeek: DayOfWeek) => {
  switch (dayOfWeek) {
    case DayOfWeek.MONDAY:
      return 1
    case DayOfWeek.TUESDAY:
      return 2
    case DayOfWeek.WEDNESDAY:
      return 3
    case DayOfWeek.THURSDAY:
      return 4
    case DayOfWeek.FRIDAY:
      return 5
    case DayOfWeek.SATURDAY:
      return 6
    case DayOfWeek.SUNDAY:
      return 7
  }
}

export enum TimeGrain {
  DAY = 'DAY',
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  MONTH = 'MONTH',
}

const TimeGrainLabel = {
  DAY: intl.formatMessage({ id: 'wbsProgressLog.day' }),
  WEEK: intl.formatMessage({ id: 'wbsProgressLog.week' }),
  TWO_WEEKS: intl.formatMessage({ id: 'wbsProgressLog.twoWeeks' }),
  MONTH: intl.formatMessage({ id: 'wbsProgressLog.month' }),
}

export const getTimeUnitByGrain = (timeGrain: TimeGrain) => {
  switch (timeGrain) {
    case TimeGrain.DAY:
      return 'd'
    case TimeGrain.WEEK:
    case TimeGrain.TWO_WEEKS:
      return 'w'
    case TimeGrain.MONTH:
      return 'M'
  }
}

export const getTimeUnitSizeByGrain = (timeGrain: TimeGrain) => {
  switch (timeGrain) {
    case TimeGrain.DAY:
    case TimeGrain.WEEK:
    case TimeGrain.MONTH:
      return 1
    case TimeGrain.TWO_WEEKS:
      return 2
  }
}

export function getTimeGrainLabel(timeGrain: TimeGrain): string {
  switch (timeGrain) {
    case TimeGrain.DAY:
      return TimeGrainLabel.DAY
    case TimeGrain.WEEK:
      return TimeGrainLabel.WEEK
    case TimeGrain.TWO_WEEKS:
      return TimeGrainLabel.TWO_WEEKS
    case TimeGrain.MONTH:
      return TimeGrainLabel.MONTH
    default:
      return ''
  }
}

export const getTimeLabel = (
  date: Date | string | number,
  timeGrain: TimeGrain
): string => {
  switch (timeGrain) {
    case TimeGrain.DAY:
      return moment(date).format('M/D (ddd)')
    case TimeGrain.WEEK:
      return `${moment(date).format('M/D')}${intl.formatMessage({
        id: 'wbsProgressLog.week',
      })}`
    case TimeGrain.TWO_WEEKS:
      return `${moment(date).format('M/D')}-${moment(date)
        .add(2, 'w')
        .subtract(1, 'd')
        .format('M/D')}`
    case TimeGrain.MONTH:
      return `${moment(date).format(
        intl.formatMessage({
          id: 'wbsProgressLog.month.format',
        })
      )}`
    default:
      return ''
  }
}

export const getMinDimTime = (
  dimTimes: number[],
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek
): number => {
  const min = Math.min(...dimTimes)
  return toMinDimTime(min, timeGrain, startDayOfWeek)
}

export const getMaxDimTime = (
  dimTimes: number[],
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek
): number => {
  const max = Math.max(...dimTimes)
  return toMaxDimTime(max, timeGrain, startDayOfWeek)
}

export const toMinDimTime = (
  dimTime: number,
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek
): number => {
  if ([TimeGrain.WEEK, TimeGrain.TWO_WEEKS].includes(timeGrain)) {
    const dayOfWeek = moment(dimTime).day()
    const startDayOfWeekNum = mapDayOfWeekToNumber(startDayOfWeek)
    const diff = (dayOfWeek - startDayOfWeekNum + 7) % 7
    return moment(dimTime).subtract(diff, 'd').valueOf()
  }
  if (timeGrain === TimeGrain.MONTH) {
    return moment(dimTime).startOf('M').valueOf()
  }
  return dimTime
}

export const toMaxDimTime = (
  dimTime: number,
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek
): number => {
  if ([TimeGrain.WEEK, TimeGrain.TWO_WEEKS].includes(timeGrain)) {
    const dayOfWeek = moment(dimTime).day()
    const startDayOfWeekNum = mapDayOfWeekToNumber(startDayOfWeek)
    const diff = (startDayOfWeekNum - dayOfWeek + 7) % 7
    return moment(dimTime).add(diff, 'd').valueOf()
  }
  if (timeGrain === TimeGrain.MONTH) {
    return moment(dimTime).startOf('M').valueOf()
  }
  return moment(dimTime).valueOf()
}

export const getAllDimTimes = (
  dataSeries: ReportDataSeries<any, any>[],
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek,
  viewTime?: {
    from?: DateVO
    to?: DateVO
  }
): number[] => {
  const allDimTimes: number[] = []
  Object.values(dataSeries).forEach(v => {
    allDimTimes.push(...v.value.map(d => d.dimTime).filter(time => !!time))
  })
  const viewTimeFrom = viewTime?.from
    ? viewTime.from.toNumberValue()
    : MAX_DIM_TIME
  const viewTimeTo = viewTime?.to ? viewTime.to.toNumberValue() : 0
  const from = toMinDimTime(
    Math.min(...allDimTimes, viewTimeFrom),
    timeGrain,
    startDayOfWeek
  )
  const to = toMaxDimTime(
    Math.max(...allDimTimes, viewTimeTo),
    timeGrain,
    startDayOfWeek
  )
  let timeFrom = moment(from)
  const timeTo = moment(to)
  let dimTimes: number[] = []
  const momentUnit = getTimeUnitByGrain(timeGrain)
  const unitSize = getTimeUnitSizeByGrain(timeGrain)
  const timeDiff = Math.ceil(timeTo.diff(timeFrom, momentUnit) / unitSize)
  for (let i = 0; i <= timeDiff; i++) {
    dimTimes.push(
      moment(timeFrom)
        .add(unitSize * i, momentUnit)
        .valueOf()
    )
  }
  return dimTimes
}

export const ALERT_COLOR = {
  HIGH: '#F6DBE1',
  MIDDLE_HIGH: '#FFF5F7',
  MIDDLE_LOW: '#E7F2F6',
  LOW: '#C2E9F6',
}
