import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { useCallback } from 'react'
import { exportExcel } from '../../../containers/BulkSheet/excel'

export const useExportExcel = (gridOptions: GridOptions): (() => void) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined
      const colId: string = params.column.getColId()
      if (colId === 'ratio') {
        if (params.value && isFinite(params.value)) {
          return `${params.value.toFixed(1)}%`
        }
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'profit_loss_summary',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      getCustomExportValue: customExportValues,
    })
  }, [customExportValues, gridOptions])

  return onExportExcel
}
