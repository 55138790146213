import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  ROW_HEIGHT,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import Project, {
  ProjectDetail,
  ProjectStatus,
  isValidProjectEndDate,
  isValidProjectStartDate,
} from '../../../lib/functions/project'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import { ColumnType } from '../../containers/commons/AgGrid'
import {
  CellClassParams,
  CellStyle,
  CellStyleFunc,
  GetContextMenuItemsParams,
} from 'ag-grid-community'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { APPLICATION_FUNCTION_EXTERNAL_ID, getPathByExternalId } from '..'
import store from '../../../store'
import { loginToProjectByUserAction } from '../../../store/project'
import { intl } from '../../../i18n'
import DateVO from '../../../vo/DateVO'
import { Color } from '../../../styles/commonStyles'
import Auth from '../../../lib/commons/auth'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface ProjectState extends BulkSheetState {
  searchText?: string
}

export class ProjectRow extends RowData {
  code?: string
  displayName?: string
  officialName?: string
  businessClient?: string
  division?: string
  projectManager?: string
  scheduledFrom?: string
  scheduledTo?: string
  mainEmail?: string
  assigned?: boolean
  status?: ProjectStatus
}

interface ProjectBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    ProjectDetail,
    ProjectRow,
    ProjectState
  > {}

class ProjectRowDataSpec extends RowDataSpec<ProjectDetail, ProjectRow> {
  createNewRow(): ProjectRow {
    return new ProjectRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: ProjectRow
    parent: ProjectRow
  }): ProjectRow {
    return params.child
  }
  createRowByResponse(response: ProjectDetail): ProjectRow {
    return {
      ...response,
      businessClient: response.businessClient?.displayName,
      division: response.division?.displayName,
      projectManager: response.projectManager?.name,
      scheduledFrom: new DateVO(response.scheduledDate.startDate).format(),
      scheduledTo: new DateVO(response.scheduledDate.endDate).format(),
      status: response.status,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(original: ProjectRow): ProjectRow {
    return {
      ...original,
      code: undefined,
    }
  }
}

export default class ProjectsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  ProjectDetail,
  ProjectRow,
  ProjectState
> {
  rowHeight = ROW_HEIGHT.MEDIUM
  fetchDataOnInit = true
  addable = false
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = UiStateKey.ProjectColumnAndFilterState
  rowDataSpec = new ProjectRowDataSpec()
  pinnedColumns = [
    'projects.action',
    'projects.status',
    'projects.code',
    'projects.displayName',
  ]
  lockedColumns = ['projects.action', 'projects.status', 'projects.code']
  customColumnTypes = {
    'projects.displayName': [ColumnType.iconWithNameColumn],
  }
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: ProjectBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data) return
    return new ContextMenu(
      [
        {
          id: ContextMenuGroupId.CUSTOM,
          items: [
            // Edit project
            {
              name: intl.formatMessage({ id: 'edit' }),
              action: () => {
                const path = getPathByExternalId(
                  APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_REGISTRATION
                )
                window.open(
                  `${window.location.origin}${path}/${params.node!.data.code}`,
                  ''
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.EDIT),
            },
            // Login to project
            params.node.data.assigned && {
              name: intl.formatMessage({ id: 'project.loginToProject' }),
              action: () => {
                const projectRow = params.node!.data
                store.dispatch(loginToProjectByUserAction(projectRow.uuid))
              },
              icon: getMenuIconHtml(ContextMenuItemId.LOGIN_PROJECT),
            },
          ].filter(v => v !== undefined),
        },
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  getRowStyle = (params): any => {
    const row = params.data as ProjectRow
    if (
      !!row?.status &&
      (ProjectStatus.COMPLETED === row.status ||
        ProjectStatus.SUSPENDED === row.status)
    ) {
      return { backgroundColor: '#e0e0e0' }
    }
  }
  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (!['scheduledFrom', 'scheduledTo'].includes(field)) return
    return (params: CellClassParams): CellStyle | null | undefined => {
      if (
        'scheduledFrom' === params.colDef.field
          ? !isValidProjectStartDate(
              params.data.scheduledFrom,
              params.data.status
            )
          : !isValidProjectEndDate(params.data.scheduledTo, params.data.status)
      ) {
        return { color: Color.ALERT }
      }
    }
  }
  updateDefaultState = async (s: ProjectState) => {
    return {
      ...s,
      editable: false,
    }
  }
  onChangeSearchText(searchText: string, ctx: ProjectBulkSheetContext) {
    ctx.setState({ searchText })
  }
  onSearch(ctx: ProjectBulkSheetContext) {
    ctx.refreshDataWithLoading()
  }
  getOpenDetailSpec = (
    row: ProjectRow
  ): Promise<OpenDetailSpec> | undefined => {
    return Promise.resolve({
      openInDialog: false,
      onOpen: () => {
        store.dispatch(loginToProjectByUserAction(row.uuid))
      },
      layer: {
        externalId: Auth.isAccountingUser()
          ? APPLICATION_FUNCTION_EXTERNAL_ID.PROFIT_LOSS_SUMMARY
          : APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_NEW_EDIT,
        code: row.code,
      },
    })
  }
  // This is overridden by Projects component.
  async getAll(state: ProjectState): Promise<APIResponse> {
    return Project.getProjects({
      searchText: state.searchText,
      sortField: 'UPDATED_AT',
      sortOrder: 'desc',
    })
  }
  getCellRendererParams = (field: string) => {
    if (field === 'displayName') {
      return {
        labelField: 'displayName',
        iconUrlField: 'iconUrl',
      }
    }
  }
  getDetailColumnCellRendererParams = () => {
    return {
      label: intl.formatMessage({ id: 'project.loginToProject' }),
      disabled: (row: ProjectRow) => !row.assigned,
    }
  }
  getSearchCondition = (state: ProjectState) => {
    return { searchText: state.searchText }
  }
  restoreSearchCondition = (
    searchCondition: { searchText: string },
    ctx: ProjectBulkSheetContext
  ) => {
    ctx.setState({ searchText: searchCondition.searchText }, () =>
      this.onSearch(ctx)
    )
  }
  refreshConditionAndColumn = (ctx: ProjectBulkSheetContext) => {
    this.onChangeSearchText('', ctx)
  }
  customColumnWidth = (field: string): number | undefined => {
    if (['displayName'].includes(field)) {
      return 200
    }
    if (['scheduledFrom', 'scheduledTo'].includes(field)) {
      return 150
    }
    return undefined
  }
}
