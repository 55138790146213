import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Typography,
} from '@mui/material'
import { Color, FontWeight } from '../../../../styles/commonStyles'
import { ProjectDetail } from '../../../../lib/functions/project'
import { useEffect, useState } from 'react'
import ProjectMenu from '../commons/ProjectMenu'
import ProjectDummyIcon from '../../../../assets/project_dummy_icon.png'
import { intl } from '../../../../i18n'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { colorPalette } from '../../../style/colorPallete'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'

type Props = {
  projectUuid?: string | undefined
  getOptionValue?: (option: any, index: number) => any
  getOptionLabel?: (option: any, index: number) => string | JSX.Element
  getOptionIconUrl?: (option: any, index: number) => any
}

const AssignedProject = ({
  projectUuid,
  getOptionValue,
  getOptionLabel,
  getOptionIconUrl,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element>()
  const handleProjectMenuClose = () => {
    setAnchorEl(undefined)
  }

  const [assigned, setAssigned] = useState<ProjectDetail[]>([])
  const { assignedProjects, loggedInProject } = useSelector(
    (state: AllState) => ({
      assignedProjects: state.project.assigned,
      loggedInProject: state.project.current,
    })
  )
  useEffect(() => {
    if (!loggedInProject) {
      setAssigned(assignedProjects)
      return
    }
    if (assignedProjects.every(p => p.uuid !== loggedInProject.uuid)) {
      // Administrator logged in
      loggedInProject.assigned = false // TODO Implemented it on the server side
      setAssigned([loggedInProject, ...assignedProjects])
    }
  }, [assignedProjects, loggedInProject])

  const selectedIndex = assigned.findIndex(
    (o, i) => (getOptionValue ? getOptionValue(o, i) : o) === projectUuid
  )
  const selectedValue = assigned[selectedIndex]

  return (
    <>
      <ClickAwayListener onClickAway={handleProjectMenuClose}>
        <Button
          sx={{
            margin: '0px 5px',
            height: 'fit-content',
            '&:hover': { background: 'transparent' },
          }}
          onClick={event => setAnchorEl(event.currentTarget)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {getOptionIconUrl && (
              <Avatar
                sx={{
                  margin: '8px 8px 8px 16px',
                  border: () =>
                    selectedIndex === -1 || assigned.length === 0
                      ? 'none'
                      : `solid ${colorPalette.monotone[2]} 1px`,
                  height: '24px',
                  width: '24px',
                }}
                variant="rounded"
                src={
                  selectedIndex === -1 || assigned.length === 0
                    ? ProjectDummyIcon
                    : getOptionIconUrl(selectedValue, selectedIndex)
                }
              />
            )}
            <Typography
              style={{
                fontWeight: FontWeight.BOLD,
                color: colorPalette.monotone[5],
                maxHeight: '36px',
                alignItems: 'center',
                fontSize: '12px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                paddingRight: '33px',
                textTransform: 'capitalize',
              }}
            >
              {selectedIndex === -1 || assigned.length === 0
                ? intl.formatMessage({ id: 'menu.project.unselected' })
                : getOptionLabel
                ? getOptionLabel(selectedValue, selectedIndex)
                : undefined}
            </Typography>
            {(selectedIndex === -1 || assigned.length === 0) && (
              <NavigateNextRoundedIcon
                sx={{
                  color: Color.MONOTONE,
                  height: '15px',
                  width: '15px',
                  marginRight: '20px',
                }}
              />
            )}
          </Box>
        </Button>
      </ClickAwayListener>
      <ProjectMenu openMenu={!!anchorEl} anchorEl={anchorEl} />
    </>
  )
}

export default AssignedProject
