import { UiStateKey } from '../../../lib/commons/uiStates'
import TicketListOptions, {
  TicketListRowDataSpec,
} from '../TicketList/TicketListOptions'

// TODO: delete this file. [V6BWULRZ]

const MEETING = 'MEETING'

export default class MeetingListOptions extends TicketListOptions {
  constructor() {
    super(MEETING)
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.MeetingListColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx =>
    `${UiStateKey.MeetingListSearchConditionState}-${ctx.state.uuid}`
  rowDataSpec = new TicketListRowDataSpec(MEETING)
}
