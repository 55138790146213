import { ICellRendererParams } from 'ag-grid-community'
import { RootDiv } from '../../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import { IconButton } from '@mui/material'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import { useCallback } from 'react'
import { DateTerm } from '../../../../utils/date'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'
import {
  StatusKanbanSearchConditionWithResponsible,
  openKanban,
} from '../../Kanban/Kanban'
import { projectPrivate } from '../../../higher-order-components/projectPrivate'
import {
  SprintReportMemberAlias,
  SprintReportValue,
} from '../../../../lib/functions/sprintReport'
import { dateTermVoService } from '../../../../domain/value-object/DateTermVO'
import styled from 'styled-components'
import { dateVoService } from '../../../../domain/value-object/DateVO'

interface Props extends ICellRendererParams {
  dateTerm: DateTerm
  rootUuid?: string
  teamUuid?: string
  searchConditionDateTerm?: {
    startDate?: number
    endDate?: number
  }
  onClickValue?: (
    values: { [key: string]: SprintReportValue },
    dateTerm: DateTerm
  ) => void
  disabled: boolean
}

const TotalCellRenderer = ({
  value,
  valueFormatted,
  dateTerm,
  rootUuid,
  teamUuid,
  searchConditionDateTerm,
  onClickValue,
  node,
  disabled,
}: Props) => {
  const projectCode = useSelector(
    (state: AllState) => state.project.current?.code
  )

  const onClickIcon = useCallback(
    e => {
      if (!projectCode) return
      const searchConditionDateTermVo = dateTermVoService.construct(
        searchConditionDateTerm?.startDate,
        searchConditionDateTerm?.endDate
      )
      const columnDateTermVo = dateTermVoService.construct(
        dateTerm.startDate,
        dateTerm.endDate
      )
      const columnEndDate = dateVoService.toDate(columnDateTermVo.to!)
      const shiftedColumnEndDateVo = dateVoService.construct(
        columnEndDate.setDate(columnEndDate.getDate() - 1)
      )
      const startDateVo =
        dateVoService.diff(
          columnDateTermVo.from!,
          searchConditionDateTermVo.from!
        ) >= 0
          ? columnDateTermVo.from
          : searchConditionDateTermVo.from
      const endDateVo =
        dateVoService.diff(
          shiftedColumnEndDateVo,
          searchConditionDateTermVo.to!
        ) <= 0
          ? shiftedColumnEndDateVo
          : searchConditionDateTermVo.to
      const query: Partial<StatusKanbanSearchConditionWithResponsible> = {
        rootUuid: rootUuid,
        teamUuid: teamUuid,
        scheduledEndDate: {
          from: startDateVo,
          to: endDateVo,
        },
        responsibleUuid:
          node.data.member.name === SprintReportMemberAlias.TOTAL
            ? ''
            : node.data.member.name === SprintReportMemberAlias.UNASSIGNED
            ? 'BLANK'
            : node.data.member.uuid,
      }
      openKanban(projectCode, query)
    },
    [
      projectCode,
      rootUuid,
      teamUuid,
      dateTerm,
      node.data.member,
      searchConditionDateTerm,
    ]
  )

  const onClick = useCallback(
    e => {
      if (!onClickValue) return
      const values = node.data.values
      onClickValue(values, dateTerm)
    },
    [node.data.values, dateTerm, onClickValue]
  )

  return (
    <RootDiv style={{ display: 'flex', justifyContent: 'space-between' }}>
      <IconButton onClick={onClickIcon} disabled={disabled}>
        <ViewKanbanIcon
          color={disabled ? 'disabled' : 'primary'}
          style={{ fontSize: 16 }}
        />
      </IconButton>
      <StyledValue onClick={onClick} disabled={disabled}>
        {valueFormatted ?? value}
      </StyledValue>
    </RootDiv>
  )
}

export default projectPrivate(TotalCellRenderer)

const StyledValue = styled('div')<{ disabled: boolean }>(props => ({
  paddingLeft: '8px',
  color: props.disabled ? 'inherit' : 'blue',
  cursor: props.disabled ? 'inherit' : 'pointer',
}))
