import _ from 'lodash'
import { intl } from '../../../../i18n'
import { focusRow, getSelectedNode } from '../lib/gridApi'
import { TreeRow } from '../model'
import { copyRows } from '../hooks/actions/copyTreeRows'
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community'
import { moveRowsToLastChild } from '../hooks/actions/moveTreeRows'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../commons/AgGrid/lib/contextMenu'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'
import { getPathByExternalId } from '../../../pages'
import { getExternalIdByWbsItem } from '../../../../lib/functions/wbsItem'

export const addCopyURLMenuItems = (
  wbsItem: { code?: string; ticketType?: string; type: WbsItemType } | undefined
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.url.copy',
    }),
    action: () => copyURLCore(wbsItem, getURL),
    icon: getMenuIconHtml(ContextMenuItemId.COPY_URL),
  }
}

export const addCopyURLAndNameMenuItems = (
  wbsItem:
    | {
        code?: string
        ticketType?: string
        type: WbsItemType
        displayName?: string
      }
    | undefined
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.urlAndName.copy',
    }),
    action: () => copyURLCore(wbsItem, getURLAndName),
    icon: getMenuIconHtml(ContextMenuItemId.COPY_URL_AND_NAME),
  }
}

export const copyURLCore = (
  wbsItem:
    | {
        code?: string
        ticketType?: string
        type: WbsItemType
        displayName?: string
      }
    | undefined,
  getCopyText: (
    path: string,
    wbsItem: {
      code?: string
      ticketType?: string
      type: WbsItemType
      displayName?: string
    }
  ) => string
) => {
  if (!wbsItem) return

  const externalId = getExternalIdByWbsItem(wbsItem)
  const path = getPathByExternalId(externalId)
  const text = getCopyText(path, wbsItem)
  navigator.clipboard.writeText(text)
}

export const getURL = (
  path: string,
  wbsItem: { code?: string; ticketType?: string; type: WbsItemType }
) => {
  return `${window.location.origin}${path}/${wbsItem.code}`
}

export const getURLAndName = (
  path: string,
  wbsItem: {
    code?: string
    ticketType?: string
    type: WbsItemType
    displayName?: string
  }
) => {
  return `${wbsItem.displayName}\n${window.location.origin}${path}/${wbsItem.code}`
}

export const focusCellAfterCopy = (api: GridApi) => {
  setTimeout(() => {
    const focusedCell = api.getFocusedCell()
    focusedCell && api.setFocusedCell(focusedCell.rowIndex, focusedCell.column)
  }, 100)
}

export const copyTreeMenuItems = <R extends TreeRow>(
  params: GetContextMenuItemsParams,
  data: R[]
): MenuItemDef => {
  const currentCopiedNodes =
    params.context.copied?.map(v => params.api.getRowNode(v.id)) ?? []
  const selectedNodes = getSelectedNode(params.api)
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.copy' },
      { count: selectedNodes.length }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.COPY_ROW),
    disabled: false,
    action: () => {
      params.context.copied = copyRows(
        data,
        selectedNodes.map(node => node.data)
      )
      params.api.redrawRows({
        rowNodes: [...currentCopiedNodes, ...selectedNodes].filter(v => !!v),
      })
      focusCellAfterCopy(params.api)
    },
    shortcut: intl.formatMessage({
      id: 'bulksheet.contextMenu.shortcut.row.copy',
    }),
  }
}

export const copyAllTreeMenuItems = <R extends TreeRow>(
  params: GetContextMenuItemsParams,
  data: R[],
  action: {
    afterCopy: (uuids: string[]) => void
  }
): MenuItemDef => {
  return {
    name: intl.formatMessage({ id: 'bulksheet.contextMenu.bulkCopy' }),
    action: () => {
      const { node, context } = params
      if (!node) return
      const target = node.allLeafChildren.map(v => v.data).filter(v => v)
      context.copied = copyRows(data, target, false)
      action.afterCopy(target.map(v => v?.uuid).filter(v => v) as string[])
    },
  }
}

export const cutTreeMenuItems = <R extends TreeRow>(
  params: GetContextMenuItemsParams,
  data: R[]
): MenuItemDef => {
  const currentCopiedNodes =
    params.context.copied?.map(v => params.api.getRowNode(v.id)) ?? []
  const selectedNodes = getSelectedNode(params.api)
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.cut' },
      { count: selectedNodes.length }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.CUT_ROW),
    disabled: false,
    action: () => {
      params.context.copied = copyRows(
        data,
        selectedNodes.map(node => node.data),
        true
      )
      params.api.redrawRows({
        rowNodes: [...currentCopiedNodes, ...selectedNodes].filter(v => !!v),
      })
      focusCellAfterCopy(params.api)
    },
    shortcut: intl.formatMessage({
      id: 'bulksheet.contextMenu.shortcut.cut.row',
    }),
  }
}

export const pasteTreeMenuItems = <R extends TreeRow>(
  params: GetContextMenuItemsParams,
  action: {
    pasteNameOnly: () => void
    selectColumns: () => void
  }
): MenuItemDef => {
  const selectedNodes = getSelectedNode(params.api)
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.paste.children' },
      {
        clipboard: params.context.copied?.length ?? 0,
        selected: selectedNodes.length,
      }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.PASTE_ROW_AS_CHILD),
    subMenu: [
      {
        name: intl.formatMessage({
          id: 'bulksheet.contextMenu.paste.rows.normal',
        }),
        action: () => action.pasteNameOnly(),
        shortcut: intl.formatMessage({
          id: 'bulksheet.contextMenu.shortcut.row.paste.name',
        }),
      },
      {
        name: intl.formatMessage({
          id: 'bulksheet.contextMenu.paste.rows.selectedCol',
        }),
        action: () => {
          action.selectColumns()
        },
        shortcut: intl.formatMessage({
          id: 'bulksheet.contextMenu.shortcut.row.paste.data',
        }),
      },
    ],
  }
}

export const pasteCutTreeMenuItems = <R extends TreeRow>(
  params: GetContextMenuItemsParams,
  data: R[],
  action: {
    duplicateRow: (row: R) => R
    setData: (data: R[], moved: R[]) => void
  }
): MenuItemDef => {
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.insert.cutrows' },
      { cutrows: params.context.copied?.length ?? 0 }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.INSERT_CUT_ROW),
    disabled: (params.context.copied?.length ?? 0) === 0,
    action: () => {
      const overNode = params.node
      const movedData = params.context.copied
        .map(v => data.find(d => d.uuid === v.id))
        .filter(v => !!v)
      if (!overNode || _.isEmpty(movedData)) return
      action.setData(
        moveRowsToLastChild(data, movedData, overNode.id),
        movedData
      )
      overNode.setExpanded(true)
      focusRow(params.api, movedData[0].uuid)
    },
    shortcut: intl.formatMessage({
      id: 'bulksheet.contextMenu.shortcut.cut.row.paste',
    }),
  }
}

export const groupRowMenuItems = (
  params: GetContextMenuItemsParams
): (string | MenuItemDef)[] => {
  if (params.node?.hasChildren()) {
    return [
      'separator',
      expandAllMenu(params),
      collapseAllMenu(params),
      expandToLevelMenu(params),
    ]
  }
  return []
}

export const expandAllMenu = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.expand.allLeafChildren',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.EXPAND_ALL),
    action: () => {
      params.node?.allLeafChildren.forEach(node => {
        if (node.isExpandable() && !node.expanded) {
          node.setExpanded(true)
        }
      })
    },
  }
}

export const collapseAllMenu = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.collapse.allLeafChildren',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.COLLAPSE_ALL),
    action: () => {
      params.node?.allLeafChildren.forEach(node => {
        if (node.isExpandable() && node.expanded) {
          node.setExpanded(false)
        }
      })
    },
  }
}

const collapseNode = (node: RowNode) => {
  if (node.isExpandable() && node.expanded) {
    node.setExpanded(false)
  }
}

const expandNode = (node: RowNode) => {
  if (node.isExpandable() && !node.expanded) {
    node.setExpanded(true)
  }
}

const expandRecursive = (
  node: RowNode,
  currentLevel: number,
  inputLevel: number
) => {
  if (currentLevel < inputLevel) {
    expandNode(node)
    node.childrenAfterGroup?.forEach((childNode: RowNode) => {
      expandRecursive(childNode, currentLevel + 1, inputLevel)
    })
  } else {
    collapseNode(node)
  }
}

const ExpandToLevelSubMenuAction = (
  params: GetContextMenuItemsParams,
  inputLevel: number
) => {
  if (params.node) {
    const currentLevel: 0 = 0
    expandRecursive(params.node, currentLevel, inputLevel)
  }
}
export const expandToLevelMenu = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.expandToLevel.leafChildren',
    }),
    disabled: getSelectedNode(params.api).length > 1,
    icon: getMenuIconHtml(ContextMenuItemId.EXPAND_TO_LEVEL_MENU),
    subMenu: Array.from({ length: 5 }, (_, index) => ({
      name: intl.formatMessage(
        {
          id: 'bulksheet.contextMenu.expandToLevel.subMenu',
        },
        { level: (index + 1).toString() }
      ),
      action: () => {
        ExpandToLevelSubMenuAction(params, index + 1)
      },
    })),
  }
}

export const expandAllRows = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({ id: 'bulksheet.contextMenu.expand.all' }),
    icon: getMenuIconHtml(ContextMenuItemId.EXPAND_ALL),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      params.api.forEachNode(node => {
        node.allLeafChildren.forEach(child => {
          if (child.isExpandable() && !node.expanded) {
            child.setExpanded(true)
          }
        })
      })
    },
  }
}

export const collapseAllRows = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({ id: 'bulksheet.contextMenu.collapse.all' }),
    icon: getMenuIconHtml(ContextMenuItemId.COLLAPSE_ALL),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      params.api.forEachNode(node => {
        node.allLeafChildren.forEach(child => {
          if (child.isExpandable() && node.expanded) {
            child.setExpanded(false)
          }
        })
      })
    },
  }
}
