import { useEffect, useState } from 'react'
import { SelectOption } from '../../Kanban'
import { useSelectFilter } from '../../../../containers/BulkSheetView/components/filter/SelectFilter/useSelectFilter'
import {
  BulkSheetFilter,
  FilterFooter,
  FilterInput,
} from '../../../../containers/BulkSheetView/components/filter/common'
import { ICON_SIZE } from '../../../../containers/BulkSheet/const'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Popper,
  Typography,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { SelectFilter as SelectFilterModel } from '../../../../containers/BulkSheetView/components/filter/CustomEnumFilter'

type Props = {
  anchorEl: Element | undefined
  filterChangedCallback: (changed: SelectFilterModel | undefined) => void
  options?: SelectOption[]
  values?: SelectFilterModel
}

export const SelectFilter = ({
  anchorEl,
  filterChangedCallback,
  options: initialOptions = [],
  values,
}: Props) => {
  const filter = useSelectFilter()
  const [text, setText] = useState('')
  const [options, setOptions] = useState<SelectOption[]>(initialOptions)
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => setOptions(initialOptions), [initialOptions])

  useEffect(() => {
    if (filter.isActive()) {
      filter.setOptions(options.map(v => v.value))
    } else {
      if (initialized) {
        filter.setOptions(options.map(v => v.value))
      } else {
        filter.init(options.map(v => v.value))
      }
    }
  }, [initialized, options])

  useEffect(() => {
    if (values) {
      filter.setValues(values.values)
      filter.setIncludeBlank(values.includeBlank)
    } else {
      filter.setValues(options.map(v => v.value))
    }
    setInitialized(true)
  }, [values])

  useEffect(() => {
    initialized && filterChangedCallback(filter.model())
  }, [initialized, filter.values, filter.includeBlank])

  if (!initialized) return <></>

  return (
    <StyledPopper open={true} anchorEl={anchorEl} placement={'bottom-start'}>
      <BulkSheetFilter>
        <FilterInput
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder={'Search...'}
          sx={{ height: 22 }}
        />
        <SelectFilterArea>
          <SelectFilterFormControl
            label="(Select All)"
            control={
              <Checkbox
                checked={filter.isAllSelected()}
                indeterminate={
                  !filter.isAllSelected() && !filter.isAllDeselected()
                }
                onChange={e =>
                  e.target.checked ? filter.selectAll() : filter.deselectAll()
                }
                disableRipple={true}
              />
            }
          />
          <CheckboxArea>
            {!text && (
              <SelectFilterFormControl
                label="(Blanks)"
                control={
                  <Checkbox
                    checked={filter.includeBlank}
                    onChange={e => filter.setIncludeBlank(e.target.checked)}
                    disableRipple={true}
                  />
                }
              />
            )}
            {options
              .filter(option => !text || option.name.includes(text))
              .map(option => {
                const value = option.value
                return (
                  <SelectFilterFormControl
                    key={`select-filter-${value}`}
                    label={
                      <FilterLabel>
                        {option.iconUrl && (
                          <img
                            src={option.iconUrl}
                            style={{
                              height: `${ICON_SIZE}px`,
                              width: `${ICON_SIZE}px`,
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        <Typography sx={{ padding: '0 3px' }}>
                          {option.name}
                        </Typography>
                      </FilterLabel>
                    }
                    control={
                      <Checkbox
                        checked={filter.values.includes(value)}
                        onChange={e => {
                          if (e.target.checked) {
                            filter.setValues([...filter.values, value])
                          } else {
                            filter.setValues(
                              filter.values.filter(v => v !== value)
                            )
                          }
                        }}
                        disableRipple={true}
                      />
                    }
                  />
                )
              })}
          </CheckboxArea>
        </SelectFilterArea>
        <FilterFooter loading={false} onClick={() => filter.reset()} />
      </BulkSheetFilter>
    </StyledPopper>
  )
}

export const SelectFilterFormControl = styled(FormControlLabel)({
  margin: 0,
})

export const SelectFilterArea = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  margin: '0 3px',
})

export const CheckboxArea = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  marginLeft: '20px',
})

export const FilterLabel = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '2px',
})

const StyledPopper = styled(Popper)({
  boxShadow: '0px 4px 16px 0px #7B8CAA80',
  background: colorPalette.monotone[0],
  display: 'flex',
  margin: '10px',
  borderRadius: '4px',
  zIndex: 1300,
})
