import React from 'react'
import './style.scss'

export const RowResizeDragger = (props: {
  onStart?: () => void
  onResize: (value: number) => void // value is the height at which cursor moved
  onComplete?: () => void
  onDoubleClick?: () => void
}) => {
  const [y, setY] = React.useState<number>(0)
  const stateRef = React.useRef({})
  stateRef.current = y

  const onMouseDown = e => {
    props.onStart?.()
    setY(e.clientY)
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
    e.preventDefault()
  }

  const handleMouseMove = e => {
    props.onResize(e.clientY - (stateRef.current as number))
    setY(e.clientY)
    e.preventDefault()
  }

  const handleMouseUp = e => {
    props.onComplete?.()
    setY(0)
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
    e.preventDefault()
  }

  const onDoubleClick = e => {
    props.onDoubleClick?.()
    e.preventDefault()
  }

  return (
    <div
      className="row-resize-dragger"
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
    />
  )
}
