enum ActionType {
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
}

type AlertAction = {
  message: string
  init?: () => void
  action?: () => void
  cancel?: () => void
}

export type State = {
  alertAction?: AlertAction
}

export const showAlert = (alertAction: AlertAction) => ({
  type: ActionType.SHOW_MESSAGE,
  alertAction: alertAction,
})

export const clearAlert = () => ({
  type: ActionType.CLEAR_MESSAGE,
})

export const reducer = (state: State = {}, action: any): State => {
  switch (action.type) {
    case ActionType.SHOW_MESSAGE:
      return { ...state, alertAction: action.alertAction }
    case ActionType.CLEAR_MESSAGE:
      return { ...state, alertAction: undefined }
    default:
      return state
  }
}
