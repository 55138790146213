import * as d3 from 'd3'
import { FontSize } from '../../../../../../styles/commonStyles'
import {
  TwoDimChartContext,
  TwoDimGraphPlotter,
  addTooltipByEvent,
} from '../index'

interface BarGraphProps {
  data: BarGraphData[]
  style: BarGraphStyle
  eventHandler: EventHandler
}

interface BarGraphData {
  xValue: number
  yValue: number
}

interface BarGraphStyle {
  color: string
  width: number
  marginLeft?: number
}

interface EventHandler {
  tooltip?: (x: number) => string | JSX.Element
  onClick?: (x: number) => void
}

export class BarGraphPlotter extends TwoDimGraphPlotter {
  private props: BarGraphProps

  constructor(props) {
    super()
    this.props = props
  }
  plot(ctx: TwoDimChartContext) {
    const { chart, xScale, yScale, innerHeight } = ctx
    const {
      data,
      style: { color, width, marginLeft = 0 },
      eventHandler: { tooltip, onClick },
    } = this.props
    const xScaleData = (d: BarGraphData) => {
      return xScale(d.xValue) || 0
    }
    const yScaleData = (d: BarGraphData) => {
      return yScale(d.yValue) || 0
    }
    const getBarHeight = (d: BarGraphData) =>
      innerHeight - (yScaleData(d) || innerHeight)
    chart
      .selectAll()
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => xScaleData(d) + width + marginLeft)
      .attr('width', width)
      .style('fill', color)
      .attr('y', innerHeight)
      .attr('height', 0)
      .on('mouseover', (e, d) => {
        d3.select(e.currentTarget).style('cursor', 'pointer')
      })
      .on('mouseout', e => {
        d3.select(e.currentTarget).style('cursor', 'default')
      })
      .on('click', (e, d) => {
        onClick && onClick(d.xValue)
        const tooltipContent = tooltip && tooltip(d.xValue)
        if (tooltipContent) {
          addTooltipByEvent(tooltipContent, e)
        }
      })
      .transition()
      .attr('y', yScaleData)
      .attr('height', getBarHeight)
    chart
      .selectAll()
      .data(data)
      .enter()
      .append('text')
      .attr('x', d => xScaleData(d) + width + marginLeft)
      .attr('y', yScaleData)
      .text(d => `${d}`)
      .style('fill', 'black')
      .style('font-size', FontSize.SMALL)
      .style('text-anchor', 'middle')
      .style('display', 'none')
  }
}
