import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'

type Props = WrappedComponentProps & BaseButtonProps

const Button = styled(BaseButton)({
  background: '#F03636 0% 0% no-repeat padding-box',
  color: '#FFFFFF',
  '&:hover': {
    background: '#F03636',
    border: '1px solid #F03636',
  },
  minWidth: '80px',
}) as React.FC<BaseButtonProps>

const DeleteBasicDataButton = (props: Props) => {
  const { title } = props
  return (
    <Button {...props}>
      {title || props.intl.formatMessage({ id: 'dialog.delete' })}
    </Button>
  )
}

export default injectIntl(DeleteBasicDataButton)
