import { useCallback, useEffect, useMemo, useState } from 'react'
import { ProgressReportByTeamPageConfig } from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'
import {
  ProgressReportByTeamPageContent,
  ProgressReportQualitativeContent,
} from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { ProgressReportTable } from '../table/ProgressReportTable'
import { ProgressValue } from '../../../../../domain/value-object/ProjectPlanSnapshot'
import { useProjectPlanSnapshotRepository } from '../../../../../services/adaptors/projectPlanSnapshotRepositoryAdaptor'
import { aggregatedValueService } from '../../../../../domain/value-object/AggregatedValueVO'
import {
  AggregateField,
  WbsItemType,
} from '../../../../../domain/entity/WbsItemEntity'
import { EditConfigButton } from '.'
import { ProgressReportByTeamConfigDialog } from '../dialog/ProgressReportByTeamConfigDialog'

type ProgressReportByTeamProps = {
  snapshotUuid: string
  editing: boolean
  config: ProgressReportByTeamPageConfig
  content: ProgressReportByTeamPageContent
  updatePageConfig: <
    K extends keyof ProgressReportByTeamPageConfig,
    V extends ProgressReportByTeamPageConfig[K]
  >(
    path: K,
    value: V
  ) => void
  updatePageContent: <
    K extends keyof ProgressReportByTeamPageContent,
    V extends ProgressReportByTeamPageContent[K]
  >(
    path: K,
    value: V
  ) => void
}
export const ProgressReportByTeam = ({
  editing,
  snapshotUuid,
  config,
  content,
  updatePageConfig,
  updatePageContent,
}: ProgressReportByTeamProps) => {
  const [progressValues, setProgressValues] = useState<ProgressValue[]>([])
  const { pivotByTeamUuids } = useProjectPlanSnapshotRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await pivotByTeamUuids(
        snapshotUuid,
        config.rootUuid,
        config.tableRows
      )
      setProgressValues(response)
    }
    fn()
  }, [config.rootUuid, config.tableRows, pivotByTeamUuids, snapshotUuid])
  const tableContents = useMemo(
    () =>
      progressValues.map(v => {
        const qualitativeContent = content.tableContents.find(
          u => u.uuid === v.uuid
        )
        return {
          uuid: v.uuid,
          name: v.name,
          status: qualitativeContent?.status || 'SUNNY',
          comment: qualitativeContent?.comment || '',
          scheduledProgressRate:
            aggregatedValueService.getFormattedRatePercentage(
              v.planned,
              v.total,
              WbsItemType.TASK,
              AggregateField.WBS_ITEM_COUNT
            ),
          progressRate: aggregatedValueService.getFormattedRatePercentage(
            v.completed,
            v.total,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
          total: aggregatedValueService.getFormatted(
            v.total,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
          planned: aggregatedValueService.getFormatted(
            v.planned,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
          completed: aggregatedValueService.getFormatted(
            v.completed,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
          delay: aggregatedValueService.getFormatted(
            v.delay,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
          remaining: aggregatedValueService.getFormatted(
            v.remaining,
            WbsItemType.TASK,
            AggregateField.WBS_ITEM_COUNT
          ),
        }
      }),
    [content.tableContents, progressValues]
  )
  const updateTableContent = useCallback(
    (v: ProgressReportQualitativeContent, index: number) => {
      const newContent = tableContents.map((u, i) => {
        if (i !== index) return u
        return v
      })
      updatePageContent('tableContents', newContent)
    },
    [tableContents, updatePageContent]
  )
  const updateConfig = useCallback(
    (rootUuid: string | undefined, teamUuids: string[]) => {
      updatePageConfig('tableRows', teamUuids)
      updatePageConfig('rootUuid', rootUuid)
    },
    [updatePageConfig]
  )
  const [open, setOpen] = useState<boolean>(false)
  const openDialog = useCallback(() => setOpen(true), [])
  const closeDialog = useCallback(() => setOpen(false), [])
  return (
    <>
      <ProgressReportTable
        editing={editing}
        contents={tableContents}
        updateTableContent={updateTableContent}
      />
      <EditConfigButton editing={editing} onClick={openDialog} />
      <ProgressReportByTeamConfigDialog
        open={open}
        onClose={closeDialog}
        updateConfig={updateConfig}
        config={config}
        snapshotUuid={snapshotUuid}
      />
    </>
  )
}
