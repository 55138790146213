import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { TicketListRepository } from '../../applications/ports/ticketListRepository'

export const useTicketListRepository = (): TicketListRepository => {
  const fetchListAsReferencedEntity = useCallback(
    async (projectUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/ticket_lists/list',
        { projectUuid },
        true
      )
      return response.json.map(res => ({
        uuid: res.uuid,
        name: res.wbsItem.displayName,
        ticketType: res.ticketType,
      }))
    },
    []
  )
  return {
    fetchListAsReferencedEntity,
  }
}
