import { Comment } from '../store/comments'
import DateTimeVO from '../domain/value-object/DateTimeVO'
import { convertHtmlToMarkdown } from './richtext'
import { convertStringToHtmlNewLine } from './html'

export const format = (value: Comment): string => {
  const text = value.text || ''
  return `${value.createdBy?.name} ${new DateTimeVO(
    value.createdAt
  )}\n${convertHtmlToMarkdown(convertStringToHtmlNewLine(text))}`
}
