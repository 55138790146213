import BulkSheetComponent, {
  BulkSheet,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import { PageArea, PageProps } from '../index'
import {
  ColumnQuickFilterKey,
  getGanttChartWidth,
  ProjectPlanOptions,
  ProjectPlanProps,
  ProjectPlanRow,
  ProjectPlanState,
} from './projectPlanOptions'
import ProjectPlanHeader from './Header'
import { ProjectPlanToolbar } from './Toolbar'
import { Collapse } from '@mui/material'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { ProjectPlanDetail } from '../../../lib/functions/projectPlan'
import {
  ColDef,
  ColumnState,
  ColumnVisibleEvent,
  GridOptions,
  RowNode,
} from 'ag-grid-community'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ProjectPlanPageState, useProjectPlanPageState } from './usePageState'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import { Function } from '../../../lib/commons/appFunction'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { generateGanttScale } from '../../containers/commons/AgGrid/components/cell/custom/gantt/ganttUtil'
import { CalendarDateVO } from '../../../domain/value-object/CalendarDateVO'
import { getUrlQueryStringParams } from '../../../utils/urls'
import { WorkLoadUnitState, useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { useProjectPrivateContext } from '../../context/projectContext'

type StateProps = {
  functions: Function[]
  ganttParameter?: GanttParameterVO
}
const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
  ganttParameter: state.project.ganttParameter,
})

const ProjectPlan = (props: PageProps & StateProps) => {
  const { project } = useProjectPrivateContext()
  const fn = props.functions.find(v => v.externalId === props.externalId)
  if (!fn || !props.ganttParameter) {
    return <></>
  }
  return (
    <ProjectPlanContent
      {...props}
      fn={fn}
      projectUuid={project.uuid}
      ganttParameter={props.ganttParameter}
      treeRootUuid={getUrlQueryStringParams()?.treeRootUuid}
    />
  )
}

type Props = PageProps & {
  fn: Function
  projectUuid: string
  ganttParameter: GanttParameterVO
  treeRootUuid?: string
}

export type ProjectPlanAgGridContext = Pick<
  ProjectPlanPageState,
  'wbsItemType' | 'aggregateTargetType' | 'workloadUnit' | 'dateFormat'
> & {
  ganttParameter: GanttParameterVO
  ganttDisplayTimeScale: CalendarDateVO[]
  ganttTimeScale: CalendarDateVO[]
  workloadUnit: WorkloadUnit
  workloadUnitState: WorkLoadUnitState
}

const ProjectPlanContent = (props: Props) => {
  const projectPlanOptions = useMemo(() => new ProjectPlanOptions(), [])

  // State
  const [filteredUuids, setFilteredUuids] = useState<string[]>([])
  const [ganttChartVisible, setGanttChartVisible] = useState(false)
  const [rootNode, setRootNode] = useState<RowNode | undefined>(undefined)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [lastClickedColumnFilter, setLastClickedColumnFilter] = useState<
    ColumnQuickFilterKey | undefined
  >()

  // TODO Delete it in the future
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        ProjectPlanProps,
        ProjectPlanDetail,
        ProjectPlanRow,
        ProjectPlanState
      >
    >()

  const agGridContextHandler = useCallback(
    (context: Partial<ProjectPlanAgGridContext>) => {
      if (!bulkSheet) return
      bulkSheet.setContext(context)
      bulkSheet.gridApi?.refreshCells()
      bulkSheet.gridApi?.refreshCells({
        // FIXME Fix bug the cells of tasks having child tasks do not refresh.
        columns: ['wbsItem.estimatedWorkload.task', 'wbsItem.actualHour'],
        force: true,
      })
      const ganttColState = bulkSheet.columnApi
        ?.getColumnState()
        ?.find(v => v.colId === 'ganttChart')
      if (ganttColState && !ganttColState.hide) {
        setGanttChartVisible(true)
      }
    },
    [bulkSheet]
  )

  const {
    wbsItemType,
    aggregateTargetType,
    workloadUnit,
    dateFormat,
    toolbar,
    updatePageState,
  } = useProjectPlanPageState(props.uuid)

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    agGridContextHandler({
      wbsItemType,
      aggregateTargetType,
      workloadUnit,
      dateFormat,
      workloadUnitState,
    })
  }, [
    agGridContextHandler,
    wbsItemType,
    aggregateTargetType,
    dateFormat,
    workloadUnitState,
  ])
  const onChangeWbsItemType = useCallback(
    (wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK) => {
      updatePageState({ wbsItemType })
    },
    []
  )
  const onChangeAggregateType = useCallback((aggregateType: AggregateField) => {
    updatePageState({ aggregateTargetType: aggregateType })
  }, [])
  const onChangeWorkloadUnit = useCallback((workloadUnit: WorkloadUnit) => {
    updatePageState({ workloadUnit })
  }, [])
  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )
  const onSubmit = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.onSubmit()
    }
  }, [bulkSheet])
  const onCancel = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.onCancel()
    }
  }, [bulkSheet])
  const openExcelOutputColumnSelectDialog = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.openExcelOutputColumnSelectDialog()
    }
  }, [bulkSheet])
  const importExcel = useCallback(
    rowData => {
      if (bulkSheet) {
        bulkSheet.importExcel(rowData)
      }
    },
    [bulkSheet]
  )
  const onChangeHeight = useCallback(
    height => {
      if (bulkSheet) {
        bulkSheet.onChangeHeight(height)
      }
    },
    [bulkSheet]
  )
  const onDeletedFilterColumn = useCallback(
    column => {
      if (bulkSheet) {
        bulkSheet.resetSpecificColumnFilter(column)
      }
    },
    [bulkSheet]
  )

  useEffect(() => {
    if (props.treeRootUuid && bulkSheet?.gridApi) {
      setRootNode(bulkSheet.gridApi.getRowNode(props.treeRootUuid))
    }
  }, [props.treeRootUuid, bulkSheet])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      setLastClickedColumnFilter(value)
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
        return
      }
      if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
        return
      }

      let pinned = visibleColumnAfterColumnQuickFiltered.COMMON
      let notPinned: string[] = []
      switch (value) {
        case ColumnQuickFilterKey.PLANNING:
          pinned = [
            ...pinned,
            ...visibleColumnAfterColumnQuickFiltered.PLANNING.PINNED,
          ]
          notPinned = visibleColumnAfterColumnQuickFiltered.PLANNING.NOT_PINNED
          break
        case ColumnQuickFilterKey.WORK_RESULT:
          pinned = [
            ...pinned,
            ...visibleColumnAfterColumnQuickFiltered.WORK_RESULT.PINNED,
          ]
          notPinned =
            visibleColumnAfterColumnQuickFiltered.WORK_RESULT.NOT_PINNED
          break
        case ColumnQuickFilterKey.PROGRESS:
          pinned = [
            ...pinned,
            ...visibleColumnAfterColumnQuickFiltered.WORK_PROGRESS.PINNED,
          ]
          notPinned =
            visibleColumnAfterColumnQuickFiltered.WORK_PROGRESS.NOT_PINNED
          break
        case ColumnQuickFilterKey.PRODUCTIVITY:
          pinned = [
            ...pinned,
            ...visibleColumnAfterColumnQuickFiltered.PRODUCTIVITY.PINNED,
          ]
          notPinned =
            visibleColumnAfterColumnQuickFiltered.PRODUCTIVITY.NOT_PINNED
          break
        default:
          break
      }
      const visibleColumnIds = [...pinned, ...notPinned]
      const allColumnStates: ColumnState[] =
        bulkSheet.columnApi!.getColumnState()
      const visibleColumnStates = allColumnStates.filter(cs =>
        visibleColumnIds.includes(cs.colId)
      )
      const hiddlenColumnStates = allColumnStates.filter(
        cs => !visibleColumnIds.includes(cs.colId)
      )
      visibleColumnStates
        .sort(
          (a, b) =>
            visibleColumnIds.findIndex(id => id === a.colId) -
            visibleColumnIds.findIndex(id => id === b.colId)
        )
        .forEach(cs => {
          cs.pinned = pinned.includes(cs.colId)
          cs.hide = false
        })

      hiddlenColumnStates.forEach(cs => (cs.hide = true))

      bulkSheet.columnApi!.applyColumnState({
        state: [...visibleColumnStates, ...hiddlenColumnStates],
        applyOrder: true,
      })
    },
    [bulkSheet]
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      onColumnVisible: (e: ColumnVisibleEvent) => {
        const onDisplayGanttChart = (visible: boolean) => {
          setGanttChartVisible(visible)
          e.api.ensureColumnVisible(
            'ganttChart',
            props.ganttParameter.calcNearestPosition()
          )
        }
        if (ColumnQuickFilterKey.PROGRESS === lastClickedColumnFilter) return
        if (e.column && e.column.getColDef().field === 'ganttChart') {
          onDisplayGanttChart(!!e.visible)
          return
        }
        if (
          e.columns &&
          e.columns.some(v => v.getColDef().field === 'ganttChart')
        ) {
          const ganttChart = e.columns.find(
            v => v.getColDef().field === 'ganttChart'
          )
          onDisplayGanttChart(!!ganttChart?.isVisible())
        }
      },
    }),
    [props.ganttParameter]
  )

  const updateGanttChartByParams = useCallback(
    async (parameter: GanttParameterVO) => {
      if (
        !bulkSheet ||
        !bulkSheet.gridApi ||
        !bulkSheet.columnApi ||
        !parameter
      ) {
        return
      }
      const { gridApi, columnApi } = bulkSheet

      // Change context
      const [ganttDisplayTimeScale, ganttTimeScale] = await generateGanttScale(
        parameter
      )
      agGridContextHandler({
        ganttParameter: parameter,
        ganttDisplayTimeScale,
        ganttTimeScale,
      })

      // Restore column state
      const colState = columnApi.getColumnState()
      const resetState = (colDef: ColDef) => {
        const state = colState.find(v => v.colId === colDef.field)
        if (!state) return
        colDef['hide'] = state.hide || undefined
        colDef['pinned'] = state.pinned
        colDef['width'] = state.width
      }
      if (gridOptions.columnDefs) {
        gridOptions.columnDefs.forEach(colDef => {
          'field' in colDef && resetState(colDef)
          'children' in colDef && colDef.children.forEach(resetState)
        })

        // Refresh columns
        gridApi.setColumnDefs(gridOptions.columnDefs)
      }
      columnApi.applyColumnState({ state: colState, applyOrder: true })

      // Reset width
      columnApi.setColumnWidth('ganttChart', getGanttChartWidth(parameter))

      setTimeout(() => {
        gridApi.ensureColumnVisible(
          'ganttChart',
          parameter.calcNearestPosition()
        )
        gridApi.refreshCells({ columns: ['ganttChart'], force: true })
        gridApi.refreshHeader()
      }, 300)
    },
    [bulkSheet]
  )

  useEffect(() => {
    if (!ganttChartVisible) return
    updateGanttChartByParams(props.ganttParameter)
  }, [bulkSheet, ganttChartVisible, props.ganttParameter])
  const rootProjectPlan = useMemo(() => {
    if (!bulkSheet || !bulkSheet.rowDataManager) {
      return
    }
    const rootProjectPlan = bulkSheet.rowDataManager
      .getAllRows()
      .find(v => v.uuid === bulkSheet?.treeRoot?.uuid)

    if (rootProjectPlan?.wbsItem.displayName) {
      document.title = rootProjectPlan.wbsItem.displayName
    }
    return rootProjectPlan
  }, [bulkSheet?.rowDataManager])

  const bulkSheetSpecificProps = useMemo(
    () => ({
      updateReportToolbar: () => {
        if (bulkSheet?.gridApi && props.treeRootUuid) {
          const newRowNode = Object.assign(
            {},
            bulkSheet.gridApi.getRowNode(props.treeRootUuid)
          )
          setRootNode(newRowNode)
        }
      },
    }),
    [bulkSheet, props.treeRootUuid, setRootNode]
  )

  return (
    <PageArea>
      <ProjectPlanHeader
        treeRootUuid={props.treeRootUuid}
        projectUuid={props.projectUuid}
        // Submit props
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        // Speed dial tools
        onClickExport={openExcelOutputColumnSelectDialog}
        onClickImport={importExcel}
        rowHeight={rowHeight}
        onClickChangeRowHeight={onChangeHeight}
        currentFormat={dateFormat}
        onClickChangeDateFormat={onChangeDateFormat}
        // Auxiliaries
        rootProjectPlan={rootProjectPlan}
        gridApi={bulkSheet?.gridApi}
        onChangeFilteredUuids={setFilteredUuids}
        // Toolbar props
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <ProjectPlanToolbar
          toolbar={toolbar}
          wbsItemType={wbsItemType}
          onChangeWbsItemType={onChangeWbsItemType}
          aggregateType={aggregateTargetType}
          onChangeAggregateType={onChangeAggregateType}
          workloadUnit={workloadUnit}
          onChangeWorkloadUnit={onChangeWorkloadUnit}
          onChangeColumnFilter={onChangeColumnFilter}
          showGanttParameter={ganttChartVisible}
          rootNode={rootNode}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns || []}
          onDeletedFilterColumn={onDeletedFilterColumn}
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={projectPlanOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={setBulkSheet}
        filteredUuids={filteredUuids}
        gridOptions={gridOptions}
        setFilteredColumns={setFilteredColumns}
        setSortedColumns={setSortedColumns}
        setRowHeight={setRowHeight}
        setSubmitDisabled={setSubmitDisabled}
        setIsLoading={setIsLoading}
        specificProps={bulkSheetSpecificProps}
      />
    </PageArea>
  )
}

export default connect(mapStateToProps)(projectPrivate(ProjectPlan))

export const visibleColumnAfterColumnQuickFiltered = {
  // 必ず表示するカラム
  COMMON: ['drag', 'rowNumber'],
  // 名称は勝手に表示されるため省略
  PLANNING: {
    PINNED: [
      'wbsItem.status',
      'action',
      'ag-Grid-AutoColumn',
      'wbsItem.priority',
      'wbsItem.team',
      'wbsItem.accountable',
      'wbsItem.responsible',
      'wbsItem.scheduledDate.startDate',
      'wbsItem.scheduledDate.endDate',
    ],
    NOT_PINNED: [
      'wbsItem.estimatedWorkload.deliverable',
      'wbsItem.estimatedWorkload.task',
      'ganttChart',
    ],
  },
  WORK_RESULT: {
    PINNED: [
      'wbsItem.status',
      'action',
      'ag-Grid-AutoColumn',
      'wbsItem.team',
      'wbsItem.accountable',
      'wbsItem.responsible',
      'wbsItem.scheduledDate.startDate',
      'wbsItem.scheduledDate.endDate',
      'wbsItem.actualDate.startDate',
      'wbsItem.actualDate.endDate',
    ],
    NOT_PINNED: ['ganttChart'],
  },
  WORK_PROGRESS: {
    PINNED: [
      'wbsItem.status',
      'action',
      'ag-Grid-AutoColumn',
      'wbsItem.team',
      'wbsItem.accountable',
      'wbsItem.responsible',
      'estimatedProgressRate',
      'progressRate',
    ],
    NOT_PINNED: [
      'total',
      'plannedValue',
      'earnedValue',
      'preceding',
      'delayed',
      'remaining',
      'ganttChart',
    ],
  },
  PRODUCTIVITY: {
    PINNED: [
      'wbsItem.status',
      'action',
      'ag-Grid-AutoColumn',
      'wbsItem.team',
      'wbsItem.accountable',
      'wbsItem.responsible',
    ],
    NOT_PINNED: [
      'wbsItem.estimatedWorkload.deliverable',
      'wbsItem.estimatedWorkload.task',
      'wbsItem.actualHour',
      'costPerformanceIndex',
      'ganttChart',
    ],
  },
}
