import React from 'react'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  BackgroundColor,
  TextColor,
} from '../../../../../../../../../styles/commonStyles'
import BaseButton, {
  Props as BaseButtonProps,
} from '../../../../../../../../components/buttons/BaseButton'

const StyledBaseButton = styled(BaseButton)({
  background: BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
  color: TextColor.BLUE,
  height: '24px',
  border: '1px solid transparent',
}) as React.FC<BaseButtonProps>
interface OwnProps extends WrappedComponentProps {}
type Props = OwnProps & BaseButtonProps

function SwitchButton(props: Props) {
  return (
    <StyledBaseButton {...props} variant="outlined">
      {props.intl.formatMessage({ id: 'gantt.tool.button' })}
    </StyledBaseButton>
  )
}

export default injectIntl(SwitchButton)
