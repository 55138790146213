import _ from 'lodash'
import { AllState } from '../../../store'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { PageArea, PageProps } from '..'
import { BulkSheetView } from '../../containers/BulkSheetView'
import Loading from '../../components/process-state-notifications/Loading'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { intl } from '../../../i18n'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { GridApi, RowDataUpdatedEvent, RowNode } from 'ag-grid-community'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import {
  getRowNumber,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useDispatch } from 'react-redux'
import { useWbsItemAdditionalPropertyGridOptions } from './gridOptions'
import {
  getName,
  WbsItemAdditionalPropertyRow,
} from './rowModel/wbsItemAdditionalPropertyRow'
import { useDialog } from './hooks/dialog'
import { useWbsItemAdditionalPropertyContextMenu } from './hooks/contextMenu'
import { styled } from '@mui/material'
import ReloadButton from '../../containers/BulkSheetView/components/header/ReloadButton'
import FilterButton from '../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../containers/BulkSheetView/components/header/SortButton'
import { useColumnState } from './hooks/columnState'
import { useWbsItemAdditionalPropertyRowData } from './hooks/rowData'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import ColumnSettingButton from '../../containers/BulkSheetView/components/header/ColumnSettingButton'
import { useRowDragAndDrop } from './hooks/dragAndDrop'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { useWbsItemTypes } from './hooks/wbsItemType'
import { InputError } from '../../containers/BulkSheetView/lib/validation'

type WrapperProps = PageProps

export const WbsItemAdditionalProperties = projectPrivate(
  ({ uuid: functionUuid }: WrapperProps) => {
    const { projectUuid } = useProjectPrivateContext()
    const [loading, setLoading] = useState<boolean>(true)
    const dialog = useDialog()
    const ref = useRef<HTMLDivElement>(null)
    const { wbsItemTypes } = useWbsItemTypes()
    const gridOptions = useWbsItemAdditionalPropertyGridOptions()
    const {
      data,
      setData,
      fetchWbsItemAdditionalProperties,
      saveWbsItemAdditionalProperties,
      addGroupHeaderRow,
      addAdditionalPropertyRow,
      deleteRows,
      hasChanged,
    } = useWbsItemAdditionalPropertyRowData({
      projectUuid,
      wbsItemTypes,
      gridOptions,
    })
    const bulkSheetState = useBulkSheetState(functionUuid, projectUuid)
    const columnState = useColumnState({ gridOptions, bulkSheetState })
    const columnSetting = useColumnSetting()
    const dispatch = useDispatch()

    const { onRowDragEnter, onRowDragMove, onRowDragEnd, refreshDragStyle } =
      useRowDragAndDrop(ref, data, setData, gridOptions)

    useEffect(() => {
      gridOptions.context = {
        ...gridOptions.context,
        wbsItemType: wbsItemTypes,
      }
    }, [gridOptions, wbsItemTypes])

    const refreshAll = useCallback(async () => {
      setLoading(true)
      try {
        await fetchWbsItemAdditionalProperties()
        dispatch(doNotRequireSave())
      } finally {
        gridOptions.context = {
          ...gridOptions.context,
          onTree: false,
          draggableNodeId: undefined,
          errors: new InputError(),
        }
        setLoading(false)
      }
    }, [fetchWbsItemAdditionalProperties, dispatch, gridOptions])

    const onSubmit = useCallback(async () => {
      setLoading(true)
      try {
        gridOptions.api?.stopEditing()

        if (gridOptions.context.errors?.hasMessage()) {
          dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'global.warning.businessError' }),
              text: gridOptions.context.errors.toMessage(id => {
                const node = gridOptions.api?.getRowNode(id)
                return !!node ? getRowNumber(node).toString() : ''
              }),
            })
          )
          return
        }

        await saveWbsItemAdditionalProperties()
        dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        dispatch(doNotRequireSave())
        await refreshAll()
      } finally {
        setLoading(false)
      }
    }, [
      saveWbsItemAdditionalProperties,
      dispatch,
      gridOptions,
      functionUuid,
      refreshAll,
    ])
    const onReload = useCallback(() => {
      if (hasChanged()) {
        dialog.openCancel()
      } else {
        refreshAll()
      }
    }, [refreshAll, hasChanged])

    const { contextMenu } = useWbsItemAdditionalPropertyContextMenu({
      addGroupHeaderRowAction: addGroupHeaderRow,
      addAdditionalPropertyRowAction: addAdditionalPropertyRow,
      deleteRowAction: dialog.openDeleteConfirmation,
      allRows: data,
    })
    const onGridReady = useCallback(() => {
      if (!_.isEmpty(bulkSheetState.columnState) && gridOptions.columnApi) {
        gridOptions.columnApi.applyColumnState({
          state: bulkSheetState.columnState,
          applyOrder: true,
        })
      }
    }, [bulkSheetState.columnState, gridOptions.columnApi])

    // Restore row state.
    const onFirstDataRendered = useCallback(
      e => {
        if (!gridOptions.api) return

        if (!_.isEmpty(bulkSheetState.filterState)) {
          gridOptions.api.setFilterModel(bulkSheetState.filterState)
        }
        bulkSheetState.finishRestoring()
      },
      [bulkSheetState.filterState, gridOptions.api]
    )

    const onRowDataUpdated = useCallback(
      (e: RowDataUpdatedEvent<WbsItemAdditionalPropertyRow>) =>
        e.api?.refreshCells({ columns: ['rowNumber'], force: true }),
      []
    )

    const initialFetched = useRef(false)
    useEffect(() => {
      if (!bulkSheetState.initialized || initialFetched.current) {
        return
      }
      refreshAll()
      initialFetched.current = true
    }, [bulkSheetState.initialized, refreshAll])

    useKeyBind(
      [{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }],
      [onSubmit]
    )

    return (
      <PageArea>
        <SavePopper loading={loading} onSubmit={onSubmit} />
        <Header>
          <ReloadButton onReload={onReload} />
          <FilterButton
            filteredColumns={columnState.filteredColumns}
            onDeleteFilteredColumn={columnState.onDeleteFilteredColumn}
            resetFilters={columnState.resetFilters}
          />
          <SortButton
            onDeleteSortedColumn={columnState.onDeleteSortedColumn}
            onDeleteSortedAllColumns={columnState.onDeleteSortedAllColumns}
            onChangeSortColumnState={columnState.onChangeSortColumnState}
            sortColumnsState={columnState.sortColumnsState}
          />
          <ColumnSettingButton
            onClick={columnSetting.toggle}
            open={columnSetting.isOpen}
          />
        </Header>
        {bulkSheetState.initialized && (
          <BulkSheetView
            ref={ref}
            gridOptions={gridOptions}
            rowData={data}
            getContextMenuItems={contextMenu}
            // Events and actions
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            onColumnVisible={columnState.onColumnVisible}
            onColumnResized={columnState.rememberColumnState}
            onColumnMoved={columnState.rememberColumnState}
            onFilterChanged={columnState.onFilterChanged}
            onSortChanged={columnState.onSortChanged}
            onRowDataUpdated={onRowDataUpdated}
            onRowDragEnter={onRowDragEnter}
            onRowDragMove={onRowDragMove}
            onRowDragEnd={onRowDragEnd}
            onRowDragLeave={refreshDragStyle}
          />
        )}
        <Loading isLoading={loading} elem={ref.current} />
        {dialog.deleteConfirmation && gridOptions.api && (
          <DeleteRowConfirmationDialog
            target={getDeleteRowLabels(gridOptions.api)}
            onConfirm={() => {
              deleteRows()
              dialog.closeDeleteConfirmation()
              dispatch(requireSave())
            }}
            onClose={dialog.closeDeleteConfirmation}
          />
        )}
        {dialog.cancel && (
          <CancelConfirmDialog
            open={true}
            onConfirm={() => {
              dialog.closeCancel()
              refreshAll()
            }}
            onClose={dialog.closeCancel}
          />
        )}
        <ColumnSettingPopper
          anchorEl={columnSetting.anchorEl}
          open={columnSetting.isOpen}
          close={columnSetting.close}
          columnApi={gridOptions.columnApi || undefined}
          gridApi={gridOptions.api || undefined}
          height={ref.current?.offsetHeight}
          openSavedUiStateDialog={undefined}
          initializeColumnState={columnState.resetColumnState}
          applicationFunctionUuid={functionUuid}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          columnState={bulkSheetState.columnState}
          offset={120}
        />
      </PageArea>
    )
  }
)

const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '10px 10px 5px 10px',
  overflowX: 'auto',
  overflowY: 'hidden',
  minHeight: '47px',
})

const getDeleteRowLabels = (gridApi: GridApi) => {
  return getSelectedNode(gridApi).map(
    (v: RowNode<WbsItemAdditionalPropertyRow>) => {
      if (!v.data) return ''
      const name = getName(v.data)
      return (
        name ||
        intl.formatMessage({
          id: 'wbsItemAdditionalProperties.dialog.deleteRowsConfirmation.message.propertyName.undefined',
        })
      )
    }
  )
}
