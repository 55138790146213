import _ from 'lodash'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  RowNode,
} from 'ag-grid-community'
import { OrganizationWorkingDayCalendarDetail } from '../../../../lib/functions/organizationWorkingDayCalendar'
import DateVO from '../../../../vo/DateVO'
import { intl } from '../../../../i18n'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import { ResourcePlanRow } from '../resourcePlan'
import { roundNumber } from '../../../../utils/number'

export const NUMBER_DECIMAL_POINT: number = 2
export const TOTAL_WORK_MONTH_DISP_FORMAT: string = '0,0.00'
export const INTERNAL_YEARMONTH_FORMAT: string = 'YYYY/MM'
export const WORKMONTHS_FIELD_PREFIX: string = 'body.memberWorkMonths.'

export interface WorkingDay {
  businessDays: number
  workHours: number
}

export interface WorkingDayCalendar {
  [yearMonth: string]: WorkingDay
}

export const isWorkMonthColumnDef = (def: ColDef | ColGroupDef) => {
  const field = (def as ColDef)?.field
  const groupId = (def as ColGroupDef)?.groupId
  return isWorkMonthColumn(field) || isWorkMonthColumn(groupId)
}

export const isWorkMonthColumn = (id: string | undefined) => {
  return !!id && id.startsWith(WORKMONTHS_FIELD_PREFIX)
}

export const getWorkingDayCalendars = (
  workingDays: OrganizationWorkingDayCalendarDetail[],
  yearMonths: string[]
): WorkingDayCalendar => {
  const workingDayCalendars: WorkingDayCalendar = {}
  yearMonths.forEach(yearMonth => {
    const target = workingDays.filter(
      wd => new DateVO(wd.date).format(INTERNAL_YEARMONTH_FORMAT) === yearMonth
    )
    const workHour = target.reduce((pre, value) => {
      return pre + value.workHour
    }, 0)
    const businessDays = target.filter(ym => ym.workHour > 0).length
    workingDayCalendars[yearMonth] = {
      businessDays: businessDays,
      workHours: workHour,
    }
  })
  return workingDayCalendars
}

export const generateYearMonthColumnDefs = (
  yearMonthArray: string[],
  workingDayMap: WorkingDayCalendar,
  generateWorkMonthColumnDef: (
    yearMonth: string,
    workingDayCalendars: WorkingDayCalendar
  ) => ColDef
): ColGroupDef[] | undefined => {
  if (_.isEmpty(yearMonthArray)) return

  const yearList = Array.from(
    new Set(
      yearMonthArray.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const yearCols: ColGroupDef[] = yearList.map(year => {
    return {
      groupId: `${WORKMONTHS_FIELD_PREFIX}${year}`,
      headerName: intl.formatMessage(
        { id: 'project.resourcePlan.year' },
        { year }
      ),
      children: [],
    }
  })
  yearMonthArray.forEach(yearMonth => {
    const date = new DateVO(yearMonth)
    const monthHeader: ColGroupDef = {
      headerName: intl.formatMessage(
        { id: 'project.resourcePlan.month' },
        { month: date.getMonth() }
      ),
      groupId: WORKMONTHS_FIELD_PREFIX + date.format(INTERNAL_YEARMONTH_FORMAT),
      children: [generateWorkMonthColumnDef(yearMonth, workingDayMap)],
    }

    const yearHeader = yearCols.find(
      v => v.groupId === `${WORKMONTHS_FIELD_PREFIX}${date.getYear()}`
    )
    yearHeader?.children.push(monthHeader)
  })

  return yearCols
}

export const isMemberRow = (type: string | undefined): boolean =>
  ResourcePlanType.MEMBER === type

export const isTeamRow = (type: string | undefined): boolean =>
  ResourcePlanType.TEAM === type

export const getManMonth = (workMonth: any): number => {
  if (workMonth && workMonth.manMonth) {
    const manMonth = Number(workMonth.manMonth)
    return Number.isNaN(manMonth) ? 0 : manMonth
  }
  return 0
}

export const getTotalManMonth = (
  api: GridApi | null | undefined,
  memberUuid: string | undefined,
  columnId: string | undefined
): {
  memberValue
  teamValue
  unsetValue
} => {
  if (!api) {
    return {
      memberValue: 0,
      teamValue: 0,
      unsetValue: 0,
    }
  }

  let memberMonth: number = 0
  let teamTotal: number = 0
  api.forEachNode((node: RowNode<ResourcePlanRow>) => {
    const nodeData: ResourcePlanRow | undefined = node.data
    const prentNodeData: ResourcePlanRow | undefined = node.parent?.data
    if (!nodeData) return 0
    if (
      columnId &&
      nodeData.type === ResourcePlanType.MEMBER &&
      nodeData.body.user?.uuid === memberUuid
    ) {
      const memberWorkMonth = nodeData.body.memberWorkMonths?.find(
        cell => cell.yearMonth === columnId
      )
      memberMonth = getManMonth(memberWorkMonth)
    }
    if (
      columnId &&
      node.data?.type === ResourcePlanType.TEAM &&
      prentNodeData &&
      prentNodeData?.body?.user?.uuid === memberUuid
    ) {
      const teamWorkMonth = nodeData.body.memberWorkMonths?.find(
        cell => cell.yearMonth === columnId
      )
      teamTotal += getManMonth(teamWorkMonth)
    }
  })
  return {
    memberValue: memberMonth,
    teamValue: teamTotal,
    unsetValue: roundNumber(memberMonth - teamTotal, NUMBER_DECIMAL_POINT),
  }
}

export const getUnsetManMonth = (
  api: GridApi | null | undefined,
  memberUuid: string | undefined,
  columnId: string | undefined
): number => {
  return getTotalManMonth(api, memberUuid, columnId).unsetValue
}

export const getMemberParamsCellStyles = (
  params: CellClassParams<ResourcePlanRow>
) => {
  if (!isMemberRow((params.data as ResourcePlanRow)?.type)) {
    return { color: 'transparent' }
  }
  return { color: 'inherit' }
}

export const getTeamParamsCellStyles = (
  params: CellClassParams<ResourcePlanRow>
) => {
  if (isMemberRow((params.data as ResourcePlanRow)?.type)) {
    return { color: 'transparent' }
  }
  return { color: 'inherit' }
}
