// Styles
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { styled } from '@mui/system'
import store, { AllState } from '../../../store'
import { getUrlQueryObject } from '../../../utils/urls'
import Loading from '../../components/process-state-notifications/Loading'
import { RouteComponentProps } from 'react-router'
import { loginToProject } from '../../../store/project'
import Project from '../../../lib/functions/project'
import Chart, {
  ChartApi,
  ChartOptions,
  ChartSpec,
} from '../../components/charts/Chart'
import objects from '../../../utils/objects'
import { BulkSheetOptions } from '../BulkSheet'
import {
  ChartConfigurationBase,
  GraphConfigurationBase,
  ReportDataManager,
  ReportDataSeries,
  SearchConditionBase,
  ViewConfigurationBase,
} from '../../../lib/functions/report'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { muiTheme } from '../../../styles/muiTheme'
import HeaderBar from '../../components/headers/HeaderBar'
import {
  CustomComponent,
  putHeaderComponent,
} from '../../../store/functionLayer'
import { Box } from '@mui/material'

const theme = muiTheme
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const ChartContainer = styled('div')({
  width: '100%',
  height: '100%',
  flexGrow: 1,
  padding: theme.spacing(1),
  display: 'flex',
  overflow: 'auto',
})

export enum ReportLayout {
  TABLE_WITH_CHART_DIALOG,
  CHART_ONLY,
}
// Interface

interface PathProps {
  code: string
}
interface Props<
  K,
  D,
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> extends WrappedComponentProps,
    RouteComponentProps<PathProps> {
  uuid: string
  projectUuid?: string
  options: ReportOptions<K, D, S, V, C, G>
  hideHeader?: boolean
}

interface State<
  K,
  D,
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<any>
> {
  searchCondition: S
  viewConfig: V
  chartConfig: C
  dataSeries: ReportDataSeries<K, D>[]
  isLoading: boolean
  // TODO: delete after refactoring [QRXO3UJ4]
  projectUuid?: string
}

export interface ReportContext<
  K,
  D,
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  state: State<K, D, S, V, C>
  props: Props<K, D, S, V, C, G>
  fetch: () => Promise<void>
  setState: (
    state:
      | ((
          prevState: Readonly<State<K, D, S, V, C>>,
          props: any
        ) =>
          | Pick<State<K, D, S, V, C>, keyof State<K, D, S, V, C>>
          | State<K, D, S, V, C>
          | null)
      | (
          | Pick<State<K, D, S, V, C>, keyof State<K, D, S, V, C>>
          | State<K, D, S, V, C>
          | null
        ),
    callback?: () => void
  ) => void
  options: ReportOptions<K, D, S, V, C, G>
  onChangeProps: (params: { [key: string]: V | C }) => void
}

export abstract class ReportOptions<
  K,
  D,
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  // Search conditions, view configurations, chart configurations.
  abstract initSearchCondition: () => S
  abstract initViewConfiguration: () => V
  abstract initChartConfiguration: () => C
  abstract getSearchConditionFromUrlQuery: (
    urlQueryObject: any
  ) => Partial<S> | undefined
  abstract getViewConfigFromUrlQuery: (
    urlQueryObject: any
  ) => Partial<V> | undefined
  abstract getChartConfigFromUrlQuery: (
    urlQueryObject: any
  ) => Partial<C> | undefined
  abstract updateConfigBySearchCondition: (
    searchCondition: S,
    viewConfig: V
  ) => V
  abstract fromSearchConditionToPlainObject: (searchCondition: S) => any
  abstract fromViewConfigToPlainObject: (viewConfig: V) => any
  abstract fromChartConfigToPlainObject: (chartConfig: C) => any
  abstract updateConfigByData: (
    dataSeries: ReportDataSeries<K, D>[],
    viewConfig: V,
    chartConfig: C
  ) => C

  // data management.
  abstract fetch: (
    searchCondition: S,
    projectUuid?: string
  ) => Promise<ReportDataSeries<K, D>[]>
  abstract dataManager: ReportDataManager<K, D, V>

  // layout
  abstract reportLayout: ReportLayout
  tableProps?: {
    externalId: string
    options: BulkSheetOptions<any, any, any, any>
    toolbarItems: (ctx: ReportContext<K, D, S, V, C, G>) => JSX.Element[]
  }
  chartProps?: {
    chartSpec: ChartSpec<K, D, V, C, G>
    chartOptions: ChartOptions<V>
    controlPanelItems: (
      ctx: ReportContext<K, D, S, V, C, G>
    ) => (api: ChartApi<K, D, V, C, G>) => JSX.Element[]
    toolbarItems: (ctx: ReportContext<K, D, S, V, C, G>) => JSX.Element[]
  }
  abstract uiStateKey: UiStateKey
  getHeaderComponents?: (ctx: ReportContext<K, D, S, V, C, G>) => {
    titleComponent?: JSX.Element
    customComponents?: CustomComponent[]
  }
  toolbarItems?: (ctx: ReportContext<K, D, S, V, C, G>) => JSX.Element[]
}

class Report<
  K,
  D,
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> extends React.Component<Props<K, D, S, V, C, G>, State<K, D, S, V, C>> {
  private chartRef = React.createRef<HTMLDivElement>()
  private waitingForSearch = false
  readonly options: ReportOptions<K, D, S, V, C, G>
  private dataManager: ReportDataManager<K, D, V>

  constructor(props: Props<K, D, S, V, C, G>) {
    super(props)
    this.options = props.options
    this.dataManager = this.options.dataManager
    this.state = {
      searchCondition: this.options.initSearchCondition(),
      viewConfig: this.options.initViewConfiguration(),
      chartConfig: this.options.initChartConfiguration(),
      dataSeries: [] as ReportDataSeries<K, D>[],
      isLoading: false,
    }
    this.fetch = this.fetch.bind(this)
  }

  async componentDidMount() {
    // TODO: refactor [QRXO3UJ4]
    let projectUuid: string | undefined = this.props.projectUuid
    if (!projectUuid) {
      const response =
        this.props.match && this.props.match.params.code
          ? await Project.getBasicByCode(this.props.match.params.code)
          : undefined
      projectUuid = response ? response.json.uuid : ''
      if (projectUuid) {
        store.dispatch(loginToProject(projectUuid))
      }
    }
    this.setState({ projectUuid })
    const urlQueryStringParams = getUrlQueryObject() as {
      arg?: string
    }
    const savedUiStateCode = urlQueryStringParams?.arg
    if (savedUiStateCode) {
      const savedUiState = await this.getSearchConditionStateByStateCode(
        savedUiStateCode
      )
      if (savedUiState) {
        this.waitingForSearch = true
        this.setState({
          searchCondition: {
            ...this.state.searchCondition,
            ...this.options.getSearchConditionFromUrlQuery(
              savedUiState.UIState.searchCondition
            ),
          },
          viewConfig: {
            ...this.state.viewConfig,
            ...this.options.getViewConfigFromUrlQuery(
              savedUiState.UIState.viewConfiguration
            ),
          },
          chartConfig: {
            ...this.state.chartConfig,
            ...this.options.getChartConfigFromUrlQuery(
              savedUiState.UIState.chartConfiguration
            ),
          },
        })
      }
    } else {
      const conditionFromUrlParams = this.getConditionFromUrlQuery()
      const viewConfigFromUrlParams = this.getViewConfigurationFromUrlQuery()
      const chartConfigFromUrlParams = this.getChartConfigurationFromUrlQuery()
      if (conditionFromUrlParams) {
        this.waitingForSearch = true
        this.setState({
          searchCondition: {
            ...this.state.searchCondition,
            ...conditionFromUrlParams,
          },
        })
      }
      if (viewConfigFromUrlParams) {
        this.setState({
          viewConfig: {
            ...this.state.viewConfig,
            ...viewConfigFromUrlParams,
          },
        })
      }
      if (viewConfigFromUrlParams) {
        this.setState({
          chartConfig: {
            ...this.state.chartConfig,
            ...chartConfigFromUrlParams,
          },
        })
      }
    }
    this.setHeaderComponents()
  }

  componentDidUpdate(
    prevProps: Props<K, D, S, V, C, G>,
    prevState: State<K, D, S, V, C>
  ) {
    if (this.waitingForSearch && this.state.projectUuid) {
      this.waitingForSearch = false
      return this.fetch()
    }
    if (!_.isEqual(prevState.viewConfig, this.state.viewConfig)) {
      this.setHeaderComponents()
    }
  }

  private async getSearchConditionStateByStateCode(
    searchConditionStateCode: string
  ): Promise<SavedUIState | undefined> {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: this.props.uuid,
      key: `${this.options.uiStateKey}${
        this.state.projectUuid ? `-${this.state.projectUuid}` : ''
      }`,
      scope: UiStateScope.Tenant,
    }
    const response = await uiStates.get(request)
    const uiState = response.json
    const searchConditionStates = uiState.value
      ? (JSON.parse(uiState.value) as SavedUIState[])
      : []
    return searchConditionStates.find(v => v.code === searchConditionStateCode)
  }

  private getConditionFromUrlQuery = () => {
    const urlQueryObject = getUrlQueryObject()
    return this.options.getSearchConditionFromUrlQuery(urlQueryObject)
  }

  private getViewConfigurationFromUrlQuery = () => {
    const urlQueryObject = getUrlQueryObject()
    return this.options.getViewConfigFromUrlQuery(urlQueryObject)
  }

  private getChartConfigurationFromUrlQuery = () => {
    const urlQueryObject = getUrlQueryObject()
    return this.options.getChartConfigFromUrlQuery(urlQueryObject)
  }

  private setHeaderComponents = () => {
    if (!this.options.getHeaderComponents) {
      return
    }
    const headerComponent = this.options.getHeaderComponents(this)
    if (!headerComponent) {
      return
    }
    store.dispatch(
      putHeaderComponent(
        headerComponent.titleComponent,
        headerComponent.customComponents
      )
    )
  }

  async fetch(): Promise<void> {
    this.setState({
      // To reset chart
      dataSeries: [],
      isLoading: true,
    })
    const searchCondition = this.state.searchCondition
    const newViewConfig = this.options.updateConfigBySearchCondition(
      searchCondition,
      this.state.viewConfig
    )
    try {
      const dataSeries = await this.options.fetch(
        searchCondition,
        this.state.projectUuid
      )
      const newChartConfig = this.options.updateConfigByData(
        dataSeries,
        newViewConfig,
        this.state.chartConfig
      )
      this.setState({
        dataSeries,
        viewConfig: newViewConfig,
        chartConfig: newChartConfig,
      })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  onChangeProps = <
    V extends ViewConfigurationBase,
    C extends ChartConfigurationBase<any>
  >(params: {
    [key: string]: V | C
  }) => {
    let newState = { ...this.state }
    for (const [key, value] of Object.entries(params)) {
      if (!['viewConfig', 'chartConfig'].includes(key)) {
        continue
      }
      objects.setValue(newState, key, value)
    }
    this.setState(newState)
  }

  render() {
    const { hideHeader, projectUuid } = this.props
    if (!projectUuid) {
      return <></>
    }
    const layout = this.options.reportLayout
    const chart = this.options.chartProps ? (
      <Chart
        viewConfig={this.state.viewConfig}
        chartConfig={this.state.chartConfig}
        dataSeries={this.state.dataSeries}
        chartSpec={this.options.chartProps.chartSpec}
        chartOptions={this.options.chartProps.chartOptions}
        dataManager={this.dataManager}
        onChangeProps={this.onChangeProps}
        controlPanels={this.options.chartProps.controlPanelItems(this)}
        toolbarItems={this.options.chartProps.toolbarItems(this)}
      />
    ) : (
      <></>
    )

    return (
      <RootDiv>
        {!hideHeader && <HeaderBar depth={0} />}
        {this.options.toolbarItems && (
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {this.options.toolbarItems(this)}
          </Box>
        )}
        {layout === ReportLayout.CHART_ONLY && (
          <ChartContainer ref={this.chartRef}>{chart}</ChartContainer>
        )}
        <Loading
          isLoading={this.state.isLoading}
          elem={this.chartRef.current}
        />
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState) => {
  const func = state.appFunction.functions.find(
    f => f.externalId === state.appFunction.currentExternalId
  )
  return {
    projectUuid: state.project.selected,
    uuid: func!.uuid,
  }
}

export default connect(mapStateToProps)(injectIntl(Report))
