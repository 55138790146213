import { RowNode } from 'ag-grid-community'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import ColumnFilterButton from '../../../components/buttons/ColumnFilterButtonGroup/ColumnFilterButton'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../components/buttons/ToolbarFilterButtonGroup'
import {
  HeaderDivider,
  HeaderGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import { WorkloadUnitToggleGroup } from '../../../components/toggleGroups'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import MyTasksAggregateInformation from '../../../containers/AggregateInformation/NewMyTasksAggregateInformation'
import { ColumnQuickFilterKey } from '../MyTasksOptions'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
  onChangeWorkloadUnit: (value: WorkloadUnit) => void
  aggregateType: AggregateField
  onChangeAggregateType: (value: AggregateField) => void
  workloadUnit: WorkloadUnit
  rowNodes?: RowNode[]
}

type Props = OwnProps & ToolbarFilterGroupProps

const MyTasksToolbar = ({
  toolbar,
  filteredColumns,
  sortedColumns,
  onDeletedFilterColumn,
  onChangeColumnFilter,
  onChangeWorkloadUnit,
  workloadUnit,
  aggregateType,
  onChangeAggregateType,
  rowNodes,
}: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === toolbar && (
        <>
          <HeaderGroup>
            <ToolbarFilterButtonGroup
              filteredColumns={filteredColumns}
              sortedColumns={sortedColumns}
              onDeletedFilterColumn={onDeletedFilterColumn}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <WorkloadUnitToggleGroup
              refresh={value => {
                onChangeWorkloadUnit(value)
              }}
              value={workloadUnit}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <ColumnFilterButton
              title={intl.formatMessage({
                id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
              })}
              onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)}
            />
            <ColumnFilterButton
              title={intl.formatMessage({
                id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
              })}
              onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.RESTORE)}
            />
          </HeaderGroup>
        </>
      )}
      {ToolbarToggleValue.REPORT === toolbar && (
        <>
          <HeaderGroup>
            <MyTasksAggregateInformation
              rowNodes={rowNodes}
              workloadUnit={workloadUnit}
              aggregateTargetType={aggregateType}
              onChangeAggregateTargetType={onChangeAggregateType}
            />
          </HeaderGroup>
          <HeaderGroup>
            <WorkloadUnitToggleGroup
              refresh={value => {
                onChangeWorkloadUnit(value)
              }}
              value={workloadUnit}
            />
          </HeaderGroup>
        </>
      )}
    </Toolbar>
  )
}

export default MyTasksToolbar
