import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SubmitButton from '../../buttons/SubmitButton'
import ReloadButton from '../../buttons/ReloadButton'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import CancelConfirmDialog from '../../dialogs/CancelConfirmDialog'
import ProjectLoginButton from '../../buttons/ProjectLoginButton'

export const generateToolBarItemKey = (
  key: number,
  position: ToolBarItemPosition = ToolBarItemPosition.START
) => {
  return `${position.toLowerCase()}-${key}`
}

export enum ToolBarItemPosition {
  START = 'START',
  RIGHT = 'RIGHT', // Right of the basic items
  END = 'END',
}

const getPositionByKey = (key: string) => {
  const splitted = key.split('-')
  if (splitted.length === 1) {
    return ToolBarItemPosition.START
  }
  return ToolBarItemPosition[splitted[0].toUpperCase()]
}

export const ToolbarDivider = (props: { key: string }) => (
  <Divider
    orientation="vertical"
    style={{ height: '17px', margin: '0px 10px' }}
  />
)

const ChildrenToolBox = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
})
const RootDiv = styled('div')({
  padding: '2px 0',
  width: '100%',
  height: '34px',
  display: 'flex',
  marginRight: '16px',
  marginBottom: '3px',
})
const ChildBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
})

interface StateProps {
  notEdited?: boolean
}

export interface ToolbarProps
  extends PropsWithChildren<WrappedComponentProps & StateProps> {
  inProgress: boolean
  onSubmit?: Function
  onCancel?: Function
  onLogin?: Function
  submitButtonTitle?: string
  submitButtonDisabled?: boolean
  cancelButtonDisabled?: boolean
  loginButtonDisabled?: boolean
  selectedFunction?: string
  hideDivider?: boolean
}

const ToolBar = (props: ToolbarProps) => {
  const [openCancelAlert, setOpenCancelAlert] = useState<boolean>(false)

  const getToolBarItemOfPosition = useCallback(
    (toolBarItems: JSX.Element[], position: ToolBarItemPosition) => {
      if (!toolBarItems) {
        return undefined
      }
      const toNumber = (val: string) => Number(val.replaceAll(/[^0-9]/g, ''))
      return toolBarItems
        .filter(
          item =>
            item &&
            item.key &&
            getPositionByKey(item.key.toString()) === position
        )
        .sort(
          (a, b) => toNumber(a.key!.toString()) - toNumber(b.key!.toString())
        )
    },
    []
  )

  const getItemGroup = useCallback(
    (position: ToolBarItemPosition, isLast: boolean = false) => {
      const toolBarItems = getToolBarItemOfPosition(
        props.children as JSX.Element[],
        position
      )
      if (toolBarItems && toolBarItems.length > 0) {
        // TODO Remove it
        if (
          position === ToolBarItemPosition.START &&
          props.selectedFunction?.startsWith('project.progressReport')
        ) {
          return (
            <ChildrenToolBox
              key="toolbar-start"
              sx={{
                marginLeft: isLast ? 'auto' : undefined,
              }}
            >
              {toolBarItems}
            </ChildrenToolBox>
          )
        }
        if (!props.hideDivider) {
          const length = toolBarItems.length
          for (let i = length - 1; i > 0; i--) {
            toolBarItems.splice(
              i,
              0,
              <ToolbarDivider key={`divider-${position}-${i}`} />
            )
          }
        }
        return (
          <ChildrenToolBox
            sx={{
              marginLeft: isLast ? 'auto' : undefined,
            }}
          >
            {toolBarItems}
          </ChildrenToolBox>
        )
      }
      return undefined
    },
    [props.children, props.selectedFunction, props.hideDivider]
  )

  const hasBaseButton = useMemo(() => {
    return !!props.onSubmit || !!props.onCancel
  }, [props.onSubmit, props.onCancel])

  const startItems = useMemo(() => {
    return getItemGroup(ToolBarItemPosition.START)
  }, [props.children, props.selectedFunction, props.hideDivider])
  const rightItems = useMemo(() => {
    return getItemGroup(ToolBarItemPosition.RIGHT)
  }, [props.children, props.selectedFunction, props.hideDivider])
  const endItems = useMemo(() => {
    return getItemGroup(ToolBarItemPosition.END, true)
  }, [props.children, props.selectedFunction, props.hideDivider])

  const onConfirm = useCallback(() => {
    setOpenCancelAlert(false)
    props.onCancel && props.onCancel()
  }, [props.onCancel])

  const {
    inProgress,
    notEdited,
    onSubmit,
    onCancel,
    onLogin,
    submitButtonDisabled,
    cancelButtonDisabled,
    loginButtonDisabled,
    hideDivider,
  } = props

  return (
    <RootDiv>
      <ChildBox>
        {startItems}
        {startItems && !hideDivider && (
          <ToolbarDivider key="toolbar-devider-start" />
        )}
        {onSubmit && (
          <SubmitButton
            onClick={() => onSubmit()}
            disabled={notEdited || inProgress || submitButtonDisabled}
            notEdited={notEdited}
          />
        )}
        {onCancel && (
          <ReloadButton
            onClick={() => setOpenCancelAlert(true)}
            disabled={inProgress || cancelButtonDisabled}
          />
        )}
        {onLogin && !loginButtonDisabled && (
          <ProjectLoginButton onClick={() => onLogin()} disabled={!notEdited} />
        )}
        {hasBaseButton && rightItems && !hideDivider && (
          <ToolbarDivider key="toolbar-divider-right" />
        )}
        {rightItems}
        {endItems}
      </ChildBox>
      <CancelConfirmDialog
        open={openCancelAlert}
        onConfirm={onConfirm}
        onClose={() => setOpenCancelAlert(false)}
      />
    </RootDiv>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(injectIntl(ToolBar))
