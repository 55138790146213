import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Markdown from '../../markdown/Markdown'
import '../../markdown/Markdown/styles.scss'
import { decodeHtmlCharCode } from '../../../../../utils/html'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import AlertDialog from '../../../dialogs/AlertDialog'
import TemplateDialog from '../../../dialogs/TemplateDialog'
import WebIcon from '@mui/icons-material/WebRounded'
import IconButton from '@mui/material/IconButton'
import Tooltip from '../../../tooltips/Tooltip'
import { Box, styled, Toolbar } from '@mui/material'
import { MarkdownStyleButtons } from '../../../toolbars/MarkdownToolbar'
import clsx from 'clsx'
import { ButtonGroup } from '../../../buttons/ButtonGroup'
import EditIcon from '@mui/icons-material/EditRounded'
import VisibilityIcon from '@mui/icons-material/VisibilityRounded'
import store, { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import TemplateSelect from '../../../../containers/DescriptionTemplate/TemplateSelect'
import { Template } from '../../../../containers/DescriptionTemplate/TemplateList'
import { normalize } from '../../../../../utils/multilineText'
import { FontSize } from '../../../../../styles/commonStyles'
import './styles.scss'
import TenantStorage from '../../../../../utils/storage'
import { isImageFile, UploadedFile } from '../../../../../utils/file'
import { showAlert } from '../../../../../store/globalAlert'
import { Editor, EditorContent } from '@tiptap/react'
import {
  convertHtmlToMarkdown,
  convertMarkdownToHtml,
} from '../../../../../utils/richtext'
import { RichTextStyleButtons } from '../../../toolbars/RichTextToolbar'
import {
  findHighLightItemsInRichText,
  getEditorExtensions,
  insertLinkFromClipboard,
} from '../../richtext'
import TextRotationIcon from '@mui/icons-material/TextRotationNoneRounded'
import Dropzone from 'react-dropzone'
import Loading from '../../../process-state-notifications/Loading'
import { useEditorState } from './hooks/editor'

export enum EditorType {
  Markdown = 'markdown',
  Draft = 'draft',
}

export enum MarkdownType {
  Edit = 'edit',
  Preview = 'preview',
  SideBySide = 'sidebyside',
}

const TextEditorOptions = [
  {
    labelId: 'multiline.switch.draft',
    value: EditorType.Draft,
  },
  {
    labelId: 'multiline.switch.markdown',
    value: EditorType.Markdown,
  },
]

// Interface
interface Props extends WrappedComponentProps, StateProps {
  dataUuid?: string
  dataLockVersion?: number
  externalId?: string
  value: string
  setValue?: Function
  disableSbsMode?: boolean
  fontSize?: FontSize
}

interface StateProps {
  projectUuid?: string
}

interface AlertOption {
  message: string
  submitHandler?: Function
}

const MultilineTextEditor = (props: Props) => {
  const {
    initialized,
    editor,
    changeEditor,
    markdownType,
    changeMarkdownType,
    templateUuid,
    changeTemplateUuid,
  } = useEditorState(
    props.dataUuid,
    !!normalize(props.value || '') ? MarkdownType.Preview : MarkdownType.Edit,
    props.disableSbsMode
  )
  const [text, setText] = useState<string>(normalize(props.value || ''))
  const [openTemplate, setOpenTemplate] = useState<boolean>(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [alertOption, setAlertOption] = useState<AlertOption>()
  const [highlightItem, setHighlightItem] = useState<string[]>([])
  const [mdSelectionStart, setMdSelectionStart] = useState<number>(-1)
  const [mdSelectionEnd, setMdSelectionEnd] = useState<number>(-1)
  const [mdOffset, setMdOffset] = useState<number>(-1)
  const [mdLineNo, setMdLineNo] = useState<number>(-1)
  const richtext = useMemo(
    () =>
      new Editor({
        extensions: getEditorExtensions(),
        content: '',
        parseOptions: {
          preserveWhitespace: 'full',
        },
        editorProps: {
          attributes: {
            class: 'richtext-editor',
          },
        },
        onUpdate: ({ editor }) => {
          const html = editor.getHTML()
          const t = convertHtmlToMarkdown(html)
          textChanged(t)
          findHighLightItemsImpl(editor)
        },
        onSelectionUpdate: ({ editor }) => {
          findHighLightItemsImpl(editor)
        },
      }),
    []
  )
  const [loading, setLoading] = useState<boolean>(false)

  const init = useCallback(async () => {
    const html = convertMarkdownToHtml(normalize(text))
    richtext?.commands.setContent(html)
  }, [])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const newText = normalize(props.value || '')
    if (text !== newText) {
      setText(newText)
      richtext?.commands.setContent(convertMarkdownToHtml(newText))
    }
  }, [props.value, props.dataLockVersion])

  const setTextState = value => {
    setText(value)
    props.setValue?.(value)
  }

  const textChanged = _.debounce<(value: string) => void>(setTextState, 100)

  const switchEditor = (newEditor: EditorType) => {
    changeEditor(newEditor)
    setOpenAlert(false)
    setHighlightItem([])
    richtext?.commands.setContent(convertMarkdownToHtml(text))
  }

  const setTemplate = (template: Template) => {
    const onSubmit = () => {
      textChanged(template.text)
      changeTemplateUuid(template.uuid)
      if (editor === EditorType.Draft) {
        richtext?.commands.setContent(convertMarkdownToHtml(template.text))
      }
    }
    if (text) {
      setOpenAlert(true)
      setAlertOption({
        message: props.intl.formatMessage({
          id: 'template.paste.alert',
        }),
        submitHandler: _ => {
          onSubmit()
          closeAlert()
        },
      })
      setOpenTemplate(false)
    } else {
      onSubmit()
    }
    setOpenTemplate(false)
  }

  const openSwitchAlert = (newEditor: EditorType) => {
    setOpenAlert(true)
    setAlertOption({
      message: props.intl.formatMessage({
        id: 'multiline.switch.alert',
      }),
      submitHandler: () => switchEditor(newEditor),
    })
  }

  const closeAlert = () => {
    setOpenAlert(false)
    setAlertOption(undefined)
  }

  const findHighLightItemsImpl = _.debounce(editor => {
    setHighlightItem(findHighLightItemsInRichText(editor))
  }, 100)

  const onPaste = async event => {
    if (event.clipboardData.files.length) {
      const files = await uploadImages(Array.from(event.clipboardData.files))
      insertImages(files)
    }
  }

  const onPasteCaptureIntoRichText = useCallback(
    event => {
      if (!richtext || !event.clipboardData) return
      if (insertLinkFromClipboard(event.clipboardData, richtext)) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    [richtext]
  )

  const uploadImages = async (files): Promise<UploadedFile[]> => {
    const imgFiles = files.filter(file => isImageFile(file))
    if (files.length !== imgFiles.length) {
      store.dispatch(
        showAlert({
          message: props.intl.formatMessage({
            id: 'editor.canNotUploadFile',
          }),
        })
      )
    }
    const uploadedFiles: UploadedFile[] = await Promise.all(
      imgFiles.map(file => executeUpload(file))
    )
    return uploadedFiles.filter(v => v.url)
  }

  const executeUpload = async (file): Promise<UploadedFile> => {
    const response = await TenantStorage.uploadFile(file)
    return {
      url: response.json.downloadUrl,
      name: file.name,
      mimeType: file.type,
    }
  }

  const insertImages = (files: UploadedFile[]) => {
    files.forEach(file => {
      richtext?.chain().focus().setImage({ src: file.url }).run()
    })
    debounceImages()
  }

  const debounceImages = _.debounce(() => {
    const html = richtext?.getHTML()
    const t = convertHtmlToMarkdown(html ? html : '')
    setText(t)
    setLoading(false)
    textChanged(t)
  }, 100)

  const onDropAccepted = async acceptedFiles => {
    const files = await uploadImages(acceptedFiles)
    insertImages(files)
  }

  const { intl, disableSbsMode } = props
  let markdownTypeOptions = [
    {
      label: intl.formatMessage({ id: 'preview' }),
      value: MarkdownType.Preview,
      icon: <VisibilityIcon fontSize="small" />,
    },
    {
      label: intl.formatMessage({ id: 'edit' }),
      value: MarkdownType.Edit,
      icon: <EditIcon fontSize="small" />,
    },
  ]
  if (!disableSbsMode) {
    markdownTypeOptions.splice(-1, 0, {
      label: intl.formatMessage({ id: 'sidebyside' }),
      value: MarkdownType.SideBySide,
      icon: <TextRotationIcon fontSize="small" />,
    })
  }
  useEffect(() => {
    if (initialized && richtext) {
      richtext.view.dom.focus()
    }
  }, [richtext, initialized])

  if (!initialized) return <></>

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <MultilineToolbar>
        <MultilineToolbarGroup sx={{ display: 'flex' }}>
          <ButtonGroup
            value={editor}
            options={TextEditorOptions.map(option => ({
              ...option,
              label: intl.formatMessage({ id: option.labelId }),
            }))}
            onChange={openSwitchAlert}
          />
          {editor === EditorType.Markdown && (
            <ButtonGroup
              value={markdownType}
              options={markdownTypeOptions}
              onChange={(value: MarkdownType) => changeMarkdownType(value)}
            />
          )}
          {props.externalId && (
            <>
              <TemplateSelect
                projectUuid={props.projectUuid}
                externalId={props.externalId}
                selectedUuid={templateUuid}
                onChange={(template: Template) => setTemplate(template)}
                openTemplate={openTemplate}
              />
              <IconButton
                size="small"
                onClick={() => setOpenTemplate(true)}
                sx={{ padding: '3px 5px' }}
              >
                <Tooltip message={intl.formatMessage({ id: 'template.edit' })}>
                  <WebIcon color="action" />
                </Tooltip>
              </IconButton>
              <TemplateDialog
                open={openTemplate}
                externalId={props.externalId}
                onClose={() => setOpenTemplate(false)}
                editor={editor}
              />
            </>
          )}
        </MultilineToolbarGroup>
        <MultilineToolbarGroup>
          {editor === EditorType.Draft && (
            <RichTextStyleButtons
              editor={richtext}
              hideHeaderFour={true}
              hideHeaderFive={true}
              hideHeaderSix={true}
              highlightItems={highlightItem}
            />
          )}
          {editor === EditorType.Markdown && (
            <MarkdownStyleButtons
              disabled={markdownType === MarkdownType.Preview}
              hideHeaderFour={true}
              hideHeaderFive={true}
              hideHeaderSix={true}
              highlightItems={highlightItem}
              markdownText={decodeHtmlCharCode(text)}
              setMarkdownText={textChanged}
              selectionStart={mdSelectionStart}
              selectionEnd={mdSelectionEnd}
            />
          )}
        </MultilineToolbarGroup>
      </MultilineToolbar>
      <MultilineEditorBox
        className={clsx({
          'markdown-body': editor === EditorType.Markdown,
          'description-tab-markup-root': editor === EditorType.Draft,
        })}
      >
        <Box sx={{ paddingLeft: '20px', width: '100%', height: '100%' }}>
          {editor === EditorType.Markdown && (
            <Markdown
              value={decodeHtmlCharCode(text)}
              onChange={textChanged}
              style={'markdown-body'}
              markdownType={markdownType}
              setHighlightItems={values => setHighlightItem(values)}
              onSelectionChange={(start, end) => {
                setMdSelectionStart(start)
                setMdSelectionEnd(end)
              }}
              offset={mdOffset}
              lineNo={mdLineNo}
            />
          )}
          {editor === EditorType.Draft && (
            <>
              <Loading isLoading={loading} />
              <Dropzone onDropAccepted={onDropAccepted} multiple={true}>
                {({ getRootProps, getInputProps }) => (
                  <Box
                    {...getRootProps({
                      onClick: e => {
                        e.stopPropagation()
                      },
                    })}
                    sx={{
                      width: '100%',
                      height: '100%',
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <input {...getInputProps()} />
                    <EditorContent
                      editor={richtext || null}
                      onPaste={onPaste}
                      onPasteCapture={onPasteCaptureIntoRichText}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </Box>
                )}
              </Dropzone>
            </>
          )}
        </Box>
      </MultilineEditorBox>
      <AlertDialog
        isOpen={openAlert}
        message={alertOption?.message}
        submitButtonTitle={props.intl.formatMessage({ id: 'yes' })}
        submitHandler={alertOption?.submitHandler}
        closeButtonTitle={props.intl.formatMessage({ id: 'no' })}
        closeHandler={closeAlert}
      />
    </Box>
  )
}

const MultilineToolbar = styled(Toolbar)({
  '&.MuiToolbar-root': {
    padding: '5px 0 0 0',
    border: 0,
    borderRadius: '0 0 5px 5px',
    backgroundColor: '#f1f1f1',
    marginBottom: 0,
    minHeight: 41,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    '&:after': {
      borderColor: 'rgba(255, 255, 255, 0)',
      borderTopColor: '#333',
      borderWidth: '4px',
      marginLeft: '-4px',
    },
    '&:before': {
      borderColor: 'rgba(221, 221, 221, 0)',
      borderTopColor: '#111',
      borderWidth: '6px',
      marginLeft: '-6px',
    },
  },
})
const MultilineToolbarGroup = styled(Box)({ padding: '0 8px' })

const MultilineEditorBox = styled(Box)({
  height: 'calc(100% - 50px)',
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  overflowX: 'scroll',
  padding: '5px 0px',
  fontSize: FontSize.LARGE,
  flexGrow: 1,
})

const mapStateToProps = (state: AllState) => {
  return { projectUuid: state.project.selected }
}

export default connect(mapStateToProps)(injectIntl(MultilineTextEditor))
