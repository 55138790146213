import { intl } from '../../../i18n'
import {
  FunctionProperty,
  isNumberProperty,
  isDateRangeProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { parseFullNumberToHalf } from '../../../utils/number'
import VOBase from '../../../vo/base'
import DateVO from '../../../vo/DateVO'

const validate = (
  val: any,
  data: any,
  prop: FunctionProperty,
  propertyAccessor: (externalId: string) => any
): ValidationError | undefined => {
  if (data && data.isViewOnly) {
    return // validation is not necessary when the data is just for view only.
  }
  let isVO = false
  if (val instanceof VOBase) {
    val = val.getValue()
    isVO = true
  }

  if (prop.requiredIf) {
    if (isVO && isDateRangeProperty(prop) && (!val.startDate || !val.endDate)) {
      const startDateResult = prop.requiredIf.check(
        val.startDate,
        propertyAccessor
      )
      const endDateResult = prop.requiredIf.check(val.endDate, propertyAccessor)
      if (!startDateResult.ok) {
        return new ValidationError(startDateResult.message!)
      }
      if (!endDateResult.ok) {
        return new ValidationError(endDateResult.message!)
      }
    } else {
      const result = prop.requiredIf.check(val, propertyAccessor)
      if (!result.ok) {
        return new ValidationError(result.message!)
      }
    }
  }
  if (val === null || val === undefined) {
    return
  }

  if (isNumberProperty(prop)) {
    try {
      val = parseFullNumberToHalf(val)
    } catch {
      // ignore
    }
  }

  if (
    [PropertyType.Text, PropertyType.MultiLineText].includes(prop.propertyType)
  ) {
    if (prop.minLength && prop.minLength > 0) {
      if (prop.minLength > val.toString().length) {
        return new ValidationError(
          intl.formatMessage(
            { id: 'validator.character.minLength' },
            { minLength: prop.minLength }
          )
        )
      }
    }
    if (prop.maxLength && prop.maxLength > 0) {
      if (prop.maxLength < val.toString().length) {
        return new ValidationError(
          intl.formatMessage(
            { id: 'validator.character.maxLength' },
            { maxLength: prop.maxLength }
          )
        )
      }
    }
  }
  if (isNumberProperty(prop)) {
    if (prop.minLength && val < 10 ** prop.minLength) {
      return new ValidationError(
        intl.formatMessage(
          { id: 'validator.number.minLength' },
          { minLength: 10 ** prop.minLength - 1 }
        )
      )
    }
    if (prop.maxLength && 10 ** prop.maxLength < val) {
      return new ValidationError(
        intl.formatMessage(
          { id: 'validator.number.maxLength' },
          { maxLength: 10 ** prop.maxLength - 1 }
        )
      )
    }
    if (Number.isFinite(prop.minNumber) && val < prop.minNumber!) {
      return new ValidationError(
        intl.formatMessage(
          { id: 'validator.number.minNumber' },
          { minNumber: prop.minNumber }
        )
      )
    }
    if (Number.isFinite(prop.maxNumber) && prop.maxNumber! < val) {
      return new ValidationError(
        intl.formatMessage(
          { id: 'validator.number.maxNumber' },
          { maxNumber: prop.maxNumber }
        )
      )
    }
  }
  if (prop.minDate) {
    const result = prop.minDate.check(val, propertyAccessor)
    if (!result.ok) {
      return new ValidationError(result.message!)
    }
  }
  if (prop.maxDate) {
    const result = prop.maxDate.check(val, propertyAccessor)
    if (!result.ok) {
      return new ValidationError(result.message!)
    }
  }
  // TODO Add max and min value property for number validation
  const strVal = typeof val === 'number' ? val.toString() : val
  if (strVal.length > 0) {
    if (!!prop.charactersAllowed) {
      const match = strVal.match(`[${prop.charactersAllowed}]+`)
      if (!match || match[0] !== strVal) {
        return new ValidationError(
          intl.formatMessage({
            id: 'validator.invalidCharacter.allowed',
          }) + prop.charactersAllowed
        )
      }
    }
    if (!!prop.charactersProhibited) {
      const match = strVal.match(`[${prop.charactersProhibited}]`)
      if (match && match.length > 0) {
        return new ValidationError(
          intl.formatMessage({
            id: 'validator.invalidCharacter.notAllowed',
          }) + prop.charactersProhibited
        )
      }
    }
    if (['TEXT', 'CODE', 'EMAIL'].includes(prop.propertyType)) {
      const match = strVal.match('[\r\n]')
      if (match && match.length > 0) {
        return new ValidationError(
          intl.formatMessage({ id: 'validator.containsLineBreak' })
        )
      }
    }
    if (!!prop.emailDomainWhitelist && prop.emailDomainWhitelist.length > 0) {
      const domain = strVal.substr(
        strVal.indexOf('@') + 1,
        strVal.toString().length
      )
      if (!prop.emailDomainWhitelist.includes(domain)) {
        return new ValidationError(
          intl.formatMessage({
            id: 'validator.invalidEmailDomain',
          }) + prop.emailDomainWhitelist.toString()
        )
      }
    }
  }
  if (prop.propertyType === PropertyType.DateRange) {
    if (val) {
      const startDate = val.startDate
      const endDate = val.endDate
      if (startDate && endDate) {
        if (new DateVO(startDate).isAfter(new DateVO(endDate))) {
          return new ValidationError(
            intl.formatMessage({ id: 'validator.dateRange.illegalOrder' })
          )
        }
      }
    }
  }
  if (prop.referenceEntity) {
    // ENTITY_SEARCH or SELECT
    if (!prop.allowFreeText && val) {
      const property = propertyAccessor(prop.externalId)
      let masterDataNotAvailable = false
      if (!property || !property.value) {
        // value not exists.
        masterDataNotAvailable = true
      } else {
        const getValue = (v: any) => {
          if (isVO || (prop.entityExtensionUuid && typeof v === 'string')) {
            return v
          }
          return v['uuid']
        }
        if (!Array.isArray(property.value)) {
          masterDataNotAvailable = !getValue(property.value)
        } else {
          masterDataNotAvailable = property.value.some(v => !getValue(v))
        }
      }
      if (masterDataNotAvailable) {
        return new ValidationError(
          intl.formatMessage({ id: 'validator.masterDataNotAvailable' })
        )
      }
    }
  } else if (prop.propertyType === PropertyType.Select) {
    if (!prop.allowFreeText) {
      let v = val
      let list: string[] = []
      if (isVO) {
        list = prop.valuesAllowed.map(item => item.value)
      } else {
        list = prop.valuesAllowed.map(item => item.name)
      }
      if (v === '') {
        return
      }
      if (!list.includes(v)) {
        return new ValidationError(
          intl.formatMessage({ id: 'validator.masterDataNotAvailable' })
        )
      }
    }
  }
}

export class ValidationError {
  private readonly message: string

  constructor(message) {
    this.message = message
  }

  getMessage = () => this.message
}

export default {
  validate,
}
