import { I18n } from './i18nLabel'
import API, { APIResponse } from './api'
import BoolExpression from '../../utils/boolExpression'
import DateExpression from '../../utils/dateExpression'
import SearchOptions from '../../utils/searchOptions'
import { Tree } from './tree'

export interface AppFunctionsGetFunctionsResponse {
  directories: Directory[]
}

export interface Directory {
  uuid: string
  externalId: string
  name: string
  displayOrder: number
  defaultOpen: boolean
  directories: Directory[]
  functions: Function[]
}

export interface Function {
  uuid: string
  externalId: string
  name: string
  hidden: boolean
  uiType: UiType
  displayOrder: number
  configurable: boolean
}

export interface ApplicationContext {
  groupKeys: string[]
}

export interface CustomEnumValue {
  customEnumCode?: string
  name: string
  nameI18n?: I18n
  value: string
  backgroundColor?: string
  foregroundColor?: string
  iconUrl?: string
  combinations?: CustomEnumValueCombination[]
}

export interface CustomEnumValueCombination {
  direction: CustomEnumCombinationDirection
  combinedEnumCode: string
  combinedEnumPath?: string
  combinedValues: CustomEnumValue[]
}

export enum CustomEnumCombinationDirection {
  BIDIRECTION = 'BIDIRECTION',
  ROW_TO_COLUMN = 'ROW_TO_COLUMN',
}

export enum Position {
  Default = 'default',
  Left = 'left',
  Right = 'right',
}

export enum PropertyType {
  Text = 'TEXT',
  Number = 'NUMBER',
  Select = 'SELECT',
  MultiSelect = 'MULTI_SELECT',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
  DateRange = 'DATE_RANGE',
  DateTimeRange = 'DATE_TIME_RANGE',
  EntitySearch = 'ENTITY_SEARCH',
  MultiLineText = 'MULTI_LINE_TEXT',
  Icon = 'ICON',
  File = 'FILE',
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
  Switch = 'SWITCH',
  Slider = 'SLIDER',
  RangeSlider = 'RANGE_SLIDER',
  Stepper = 'STEPPER',
  Link = 'LINK',
  Email = 'EMAIL',
  Revision = 'REVISION',
  I18nLabel = 'I18N_LABEL',
  Custom = 'CUSTOM',
  MultiEntitySearch = 'MULTI_ENTITY_SEARCH',
  GanttChart = 'GANTT_CHART',
}

export const APPLICATION_UUID = process.env.REACT_APP_APPLICATION_UUID!

export enum Component {
  Table = 'TABLE',
  ActualResult = 'ACTUAL_RESULT',
  ProjectPlan = 'PROJECT_PLAN',
  Workload = 'WORKLOAD',
  Discussion = 'DISCUSSION',
  WbsItemStatus = 'WBS_STATUS',
  Action = 'ACTION',
  DefaultColumn = 'DEFAULT_COLUMN',
  WbsItemBasic = 'WBS_ITEM_BASIC',
  WbsAggregateValue = 'WBS_AGGREGATE_VALUE',
  Attachment = 'ATTACHMENT',
  TicketType = 'TICKET_TYPE',
  Development = 'DEVELOPMENT',
  Sprint = 'SPRINT',
  Tag = 'TAG',
  SlackUser = 'SLACK_USER',
}

export const isNumberProperty = (prop: FunctionProperty) => {
  return (
    prop.propertyType === PropertyType.Number ||
    (prop.propertyType === PropertyType.Custom &&
      prop.component === Component.Workload)
  )
}

export const isDateRangeProperty = (prop: FunctionProperty) => {
  return (
    prop.propertyType === PropertyType.DateRange ||
    prop.propertyType === PropertyType.DateTimeRange
  )
}

export const fillDefaultValue = (props: Partial<FunctionProperty>) => {
  return {
    position: Position.Default,
    valuesAllowed: [],
    emailDomainWhitelist: [],
    skipEmailVerification: false,
    ...props,
  } as FunctionProperty
}

export interface FunctionProperty {
  entityExtensionUuid: string
  externalId: string
  name: string
  propertyType: PropertyType
  propertyLayout: string
  tree: boolean
  propertyOrder: number
  position: Position
  parentProperty?: string
  requiredIf: BoolExpression
  editableIfC: BoolExpression
  editableIfU: BoolExpression
  hiddenIfC: BoolExpression
  hiddenIfU: BoolExpression
  defaultValue?: string
  placeHolder?: string
  tooltipText?: string
  minLength?: number
  maxLength?: number
  minNumber?: number
  maxNumber?: number
  minDate?: DateExpression
  maxDate?: DateExpression
  charactersAllowed?: string
  charactersProhibited?: string
  allowFreeText: boolean
  valuesAllowed: CustomEnumValue[]
  referenceEntity?: string
  searchOptions: SearchOptions
  fileFormat: string[]
  displayFormat: string
  step: number
  message: string
  component?: Component
  emailDomainWhitelist: string[]
  skipEmailVerification: boolean
}

export interface FunctionPropertyConfigurationDetail
  extends Tree<FunctionPropertyConfigurationDetail> {
  entityExtensionUuid: string
  externalId: string
  name: string
  propertyType: PropertyType
  propertyLayout: string
  parentProperty?: string
  requiredIf: string
  editableIfC: string
  editableIfU: string
  hiddenIfC: string
  hiddenIfU: string
  valuesAllowed: CustomEnumValue[]
  configurable: boolean
  configuration: PropertyConfigurationDetail
}

export interface PropertyConfigurationDetail {
  externalId: string
  name: string
  requiredIf: string
  editableIfU: string
  editableIfC: string
  hiddenIfC: string
  hiddenIfU: string
}

export interface EntityMeta {
  entityType: string
  domainContextName: string
  entityName: string
}
export interface AppFunctionsGetDetailsProps {
  applicationFunctionUuid: string
  groupKeys?: string[]
}

export interface AppFunctionsGetDetailResponse {
  uuid: string
  externalId: string
  properties: FunctionProperty[]
}

export interface UpdateBatchPropertyConfigurationProps {
  applicationFunctionUuid: string
  projectUuid: string
  configurations: UpdatePropertyConfigurationProps[]
}

export interface UpdatePropertyConfigurationProps {
  externalId: string
  name?: string
  parentProperty?: string
  requiredIf?: string
  editableIfC?: string
  editableIfU?: string
  hiddenIfC?: string
  hiddenIfU?: string
}

export interface ApplicationFunctionPropertyConfiguration {
  externalId: string
  name?: string
  propertyLayout?: string
  parentProperty?: string
  requiredIf?: BoolExpression
  editableIfC?: BoolExpression
  editableIfU?: BoolExpression
  hiddenIfC?: BoolExpression
  hiddenIfU?: BoolExpression
}

export enum Cockpit {
  Tenant = 'TENANT',
  Project = 'PROJECT',
  Global = 'GLOBAL',
}

export enum UiType {
  Single = 'SINGLE',
  SingleBulk = 'SINGLE_BULK',
  SingleSearch = 'SINGLE_SEARCH',
  MultiSingleBulk = 'MULTI_SINGLE_BULK',
  Custom = 'CUSTOM',
}

class AppFunction {
  async getFunctions(): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/functions', {
      applicationUuid: APPLICATION_UUID,
    })
  }

  async getDetail(props: AppFunctionsGetDetailsProps): Promise<APIResponse> {
    const response = await API.functional.request(
      'GET',
      '/api/v1/functions/detail',
      props
    )
    for (let i = 0; i < response.json.properties.length; i++) {
      const p = response.json.properties[i]
      p.requiredIf = BoolExpression.of(p.requiredIf)
      p.editableIfC = BoolExpression.of(p.editableIfC)
      p.editableIfU = BoolExpression.of(p.editableIfU)
      p.hiddenIfC = BoolExpression.of(p.hiddenIfC || 'FALSE')
      p.hiddenIfU = BoolExpression.of(p.hiddenIfU || 'FALSE')
      p.searchOptions = new SearchOptions(p.searchOptions)
      if (p.minDate) {
        p.minDate = new DateExpression(p.minDate, 'min')
      }
      if (p.maxDate) {
        p.maxDate = new DateExpression(p.maxDate, 'max')
      }
    }
    return response
  }

  async getConfig(props: AppFunctionsGetDetailsProps): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/functions/config', props)
  }

  async updateConfig(
    request: UpdateBatchPropertyConfigurationProps
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/functions/config/batch',
      request
    )
  }

  async findEntityExtensions(): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/functions/properties/find')
  }

  async findCustomizableEntities(): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/functions/properties/customizable_entities/find',
      {
        applicationUuid: APPLICATION_UUID,
      }
    )
  }

  async getPropertyConfiguraitons(
    applicationFunctionUuid: string,
    groupKeys?: string[]
  ): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/functions/properties/configurations',
      {
        applicationFunctionUuid,
        groupKeys,
      }
    )
  }
}

export default new AppFunction()
