import formatter from '../../../../meta/formatter'
import { ValueFormatterParams, ValueParserParams } from 'ag-grid-community'

const format = (params: ValueFormatterParams): string => {
  if (
    !params.data ||
    !params.colDef.cellRendererParams ||
    typeof params.value === 'undefined'
  ) {
    return ''
  }
  const formatted = formatter.format(
    params.value,
    params.colDef.cellRendererParams.uiMeta
  )
  return !formatted && formatted !== 0 ? '' : formatted
}

const parse = (params: ValueParserParams): any => {
  if (!params.colDef.cellEditorParams) {
    return ''
  }
  const value = formatter.parse(
    params.newValue,
    params.colDef.cellEditorParams.uiMeta
  )
  if (params.newValue === '' && params.colDef.cellRendererParams) {
    return undefined
  }
  return value
}

export default {
  format,
  parse,
}
