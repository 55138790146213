import { useCallback, useEffect, useState } from 'react'
import {
  OrganizationWorkingDayCalendarDetail,
  getOrganizationWorkingDayCalendar,
} from '../../../../lib/functions/organizationWorkingDayCalendar'
import projectMember from '../../../../lib/functions/projectMember'
import { DateTerm } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import { UserDetail } from '../../../../lib/functions/user'
import team, { TeamProps } from '../../../../lib/functions/team'

export const useMasterData = (selectedProjectUuid: string | undefined) => {
  const [allCanAddMembers, setAllCanAddMembers] = useState<UserDetail[]>([])
  const [teams, setTeams] = useState<TeamProps[]>([])

  const fetchWorkingDayCalendars = useCallback(
    async (
      dateTerm: DateTerm
    ): Promise<OrganizationWorkingDayCalendarDetail[]> => {
      const workingDayCalendarResponse =
        await getOrganizationWorkingDayCalendar({
          startDate: new DateVO(dateTerm.startDate).toDate(),
          endDate: new DateVO(dateTerm.endDate).toDate(),
        })
      return workingDayCalendarResponse.json as OrganizationWorkingDayCalendarDetail[]
    },
    []
  )

  const fetchAllCanAddMembers = useCallback(async () => {
    if (!selectedProjectUuid) return
    const today = DateVO.now().getStartOfDay()
    setAllCanAddMembers(
      await projectMember
        .getProjectMembers({
          projectUuid: selectedProjectUuid,
        })
        .then(res => res.json)
        .then((json: any[]) =>
          json
            .filter(
              m =>
                (!m.user.validTo ||
                  new DateVO(m.user.validTo).isAfter(today)) &&
                (!m.projectReleasedDate ||
                  new DateVO(m.projectReleasedDate).isAfter(today))
            )
            .map(m => m.user)
            .sort(function (a, b) {
              if (a.nameKana > b.nameKana) {
                return 1
              } else {
                return -1
              }
            })
        )
    )
  }, [selectedProjectUuid])

  useEffect(() => {
    fetchAllCanAddMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjectUuid])

  const fetchTeams = useCallback(async () => {
    setTeams(await team.searchAll())
  }, [])

  useEffect(() => {
    fetchTeams()
  }, [fetchTeams])

  return {
    fetchWorkingDayCalendars,
    allCanAddMembers,
    teams,
  }
}
