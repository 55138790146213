import { TreeRow } from '../../model'

export type ClipboardPlace = 'top' | 'middle' | 'bottom' | 'both'
type CopiedRow = {
  id: string
  place: ClipboardPlace
  cut?: boolean
}

export const copyRows = <R extends TreeRow>(
  allRows: R[],
  copiedRows: R[],
  cut?: boolean
): CopiedRow[] => {
  const copied: CopiedRow[] = []
  if (copiedRows.length === 1) {
    return [{ id: copiedRows[0].uuid, place: 'both', cut }]
  }

  const copiedRowsUuids = new Set(copiedRows.map(row => row.uuid))
  copiedRowsUuids.forEach(uuid => {
    const i = allRows.findIndex(v => v.uuid === uuid)
    if (i < 0) return
    const data = allRows[i]
    const previous = i > 0 ? allRows[i - 1] : undefined
    const previousCopied = previous && copiedRowsUuids.has(previous.uuid)
    const following = i < allRows.length - 1 ? allRows[i + 1] : undefined
    const followingCopied = following && copiedRowsUuids.has(following.uuid)
    if (previousCopied && followingCopied) {
      copied.push({ id: data.uuid, place: 'middle', cut })
      return
    }
    if (previousCopied) {
      copied.push({ id: data.uuid, place: 'bottom', cut })
      return
    }
    if (followingCopied) {
      copied.push({ id: data.uuid, place: 'top', cut })
      return
    }
    copied.push({ id: data.uuid, place: 'both', cut })
  })
  return copied
}
