const DescIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.41667 0.916341V7.43218L1.57 4.58551C1.3425 4.35801 0.96917 4.35801 0.74167 4.58551C0.51417 4.81301 0.51417 5.18051 0.74167 5.40801L4.58584 9.25218C4.81334 9.47968 5.18084 9.47968 5.40834 9.25218L9.2525 5.40801C9.48 5.18051 9.48 4.81301 9.2525 4.58551C9.025 4.35801 8.6575 4.35801 8.43 4.58551L5.58334 7.43218V0.916341C5.58334 0.595508 5.32084 0.333008 5 0.333008C4.67917 0.333008 4.41667 0.595508 4.41667 0.916341Z"
        fill="#008CD5"
      />
    </svg>
  )
}

export default DescIcon
