import { useMemo } from 'react'
import { ValueGetterParams } from 'ag-grid-community'
import { intl } from '../../../i18n'
import IconCellRenderer from '../../containers/commons/AgGrid/components/cell/common/iconCell'
import { ColumnType } from '../../containers/commons/AgGrid'
import { BulkSheetProperty } from './bulksheetProperty'
import objects from '../../../utils/objects'
import {
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../containers/BulkSheetView/components/filter'
import { UserBasic } from '../../../domain/value-object/UserBasicVO'

export const useRevision = (field: string): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: 'revision' }),
      hide: true,
      width: 90,
      filter: ClientSideTextFilter,
      floatingFilter: true,
    }),
    [field]
  )
  return property
}

export const useCreatedBy = (field: string): BulkSheetProperty => {
  return useUserColumnDef(field, 'createdBy')
}

export const useCreatedAt = (field: string): BulkSheetProperty => {
  return useDateTimeColumnDef(field, 'createdAt')
}

export const useUpdatedBy = (field: string): BulkSheetProperty => {
  return useUserColumnDef(field, 'updatedBy')
}

export const useUpdatedAt = (field: string): BulkSheetProperty => {
  return useDateTimeColumnDef(field, 'updatedAt')
}

const useUserColumnDef = (
  field: string,
  headerNameId: string
): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: headerNameId }),
      hide: true,
      width: 110,
      valueGetter: (params: ValueGetterParams) => {
        return objects.getValue(params.data, field)
      },
      cellRenderer: IconCellRenderer,
      cellRendererParams: {
        labelField: `${field}.name`,
        iconUrlField: `${field}.iconUrl`,
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        getValue: (v?: UserBasic) => v?.name,
        getLabel: (v?: UserBasic) => v?.name,
      },
      comparator: (a: UserBasic, b: UserBasic) =>
        (a?.name ?? '').localeCompare(b?.name ?? ''),
    }),
    [field, headerNameId]
  )
  return property
}

const useDateTimeColumnDef = (
  field: string,
  headerNameId: string
): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      headerName: intl.formatMessage({ id: headerNameId }),
      hide: true,
      width: 120,
      type: [ColumnType.dateTime],
      floatingFilter: true,
    }),
    [field, headerNameId]
  )
  return property
}
