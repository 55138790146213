import React from 'react'
import { WidgetArea, WidgetTitle } from '../../components'
import { intl } from '../../../../../i18n'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import {
  Impact,
  ImpactLabel,
  ImpactType,
  Probability,
  ProbabilityLabel,
  ProbabilityType,
} from '../../../Risks/risks'
import { BorderColor, FontSize } from '../../../../../styles/commonStyles'
import { RiskBasic } from '../hooks/riskData'
import { RiskDashboardContent } from '..'
import { useRiskHeatMap } from './hooks/riskHeatMap'

const HEAT_MAP_ITEM_HEIGHT = 42

export const RiskHeatMap = ({
  riskList,
  filterRiskList,
}: {
  riskList: RiskBasic[]
  filterRiskList: (probability: ProbabilityType, impact: ImpactType) => void
}) => {
  const { data, getValue } = useRiskHeatMap(riskList)
  const probabilities = [
    Probability.EXTREMELY_HIGH,
    Probability.HIGH,
    Probability.MIDDLE,
    Probability.LOW,
    Probability.EXTREMELY_LOW,
  ]
  const impacts = [
    Impact.EXTREMELY_LOW,
    Impact.LOW,
    Impact.MIDDLE,
    Impact.HIGH,
    Impact.EXTREMELY_HIGH,
  ]

  if (!data) return <></>

  return (
    <RiskDashboardContent style={{ width: '35%' }}>
      <WidgetTitle>{intl.formatMessage({ id: 'riskHeatMap' })}</WidgetTitle>
      <WidgetArea sx={{ padding: '20px 20px 10px 10px' }}>
        <HeatMapYTickTitle>
          <ChartLabelBox sx={{ height: `${HEAT_MAP_ITEM_HEIGHT * 5}px` }}>
            <ChartLabel>
              {intl.formatMessage({ id: 'risks.probability' })}
            </ChartLabel>
          </ChartLabelBox>
        </HeatMapYTickTitle>
        <HeatMapYTick>
          {probabilities.map(probability => (
            <ChartLabel
              key={`heat-map-y-tick-label-${probability}`}
              sx={{ height: `${HEAT_MAP_ITEM_HEIGHT}px` }}
            >
              {ProbabilityLabel[probability]}
            </ChartLabel>
          ))}
        </HeatMapYTick>
        <HeatMapArea>
          <HeatMap>
            {probabilities.map((probability, yIndex) => {
              return (
                <HeatMapRow key={`heat-map-${probability}`}>
                  {impacts.map((impact, xIndex) => {
                    const value = getValue({ probability, impact })
                    const border = `1px solid ${BorderColor.GREY}`
                    return (
                      <HeatMapItem
                        key={`heat-map-${impact}`}
                        sx={{
                          border,
                          borderLeft: xIndex === 0 ? border : 'transparent',
                          borderTop: yIndex === 0 ? border : 'transparent',
                          backgroundColor: value?.color,
                          cursor: value?.count ? 'pointer' : undefined,
                        }}
                        onClick={() =>
                          value?.count && filterRiskList(probability, impact)
                        }
                      >
                        <Typography sx={{ fontSize: FontSize.SMALL }}>
                          {value?.count ? value.count : undefined}
                        </Typography>
                      </HeatMapItem>
                    )
                  })}
                </HeatMapRow>
              )
            })}
            <HeatMapXTick>
              {impacts.map(impact => (
                <ChartLabel
                  key={`heat-map-x-tick-label-${impact}`}
                  sx={{ width: '20%', margin: '5px 0' }}
                >
                  {ImpactLabel[impact]}
                </ChartLabel>
              ))}
            </HeatMapXTick>
          </HeatMap>
          <ChartLabelBox sx={{ height: '20px', margin: '5px' }}>
            <ChartLabel>
              {intl.formatMessage({ id: 'risks.impact' })}
            </ChartLabel>
          </ChartLabelBox>
        </HeatMapArea>
      </WidgetArea>
    </RiskDashboardContent>
  )
}

const ChartLabelBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ChartLabel = styled(WidgetTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const HeatMapYTickTitle = styled(Box)({
  width: '10%',
  display: 'flex',
  flexDirection: 'column',
})

const HeatMapYTick = styled(Box)({
  width: '8%',
  display: 'flex',
  flexDirection: 'column',
})

const HeatMapXTick = styled(Box)({
  width: '100%',
  display: 'flex',
})

const HeatMapArea = styled(Box)({
  width: '82%',
  display: 'flex',
  flexDirection: 'column',
})

const HeatMap = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const HeatMapRow = styled(Box)({
  width: '100%',
  display: 'flex',
})

const HeatMapItem = styled(Box)({
  width: '20%',
  height: `${HEAT_MAP_ITEM_HEIGHT}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
