import { Box, Divider, Link, styled, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import {
  FONT_FAMILY,
  FontSize,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import ProjectPlanBreadcrumbs from '../../../components/headers/HeaderBar/Breadcrumbs/ProjectPlanBreadcrumbs'
import { BreadCrumb } from '../../../../store/breadcrumb'
import { ProjectDetail } from '../../../../lib/functions/project'
import { intl } from '../../../../i18n'

export const DashboardRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export const DashboardTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
})

export const DashboardContent = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '8px 24px',
})

export const ComponentWrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colorPalette.monotone[1],
  borderRadius: '4px',
  padding: '12px 24px',
})

export const ComponentHeader = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
})

export const ComponentHeaderEndDiv = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '12px',
})

export const ComponentTitle = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[10],
  fontWeight: 600,
})

export const TeamwiseComponent = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '24px',
})

export const TeamwiseInnerComponent = styled('div')({
  width: 'calc(100% - 124px)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
})

export const TeamName = styled('div')({
  fontSize: '11px',
  fontWeight: 600,
  width: '100px',
  color: colorPalette.monotone[10],
  textAlign: 'center',
})

export const ComponentDivider = styled(Divider)({
  borderColor: colorPalette.monotone[3],
})

export const WidgetWithTitleWrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 0',
})

export const WidgetArea = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  padding: '4px',
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  backgroundColor: colorPalette.monotone[0],
})

export const WidgetTitle = styled('span')({
  fontSize: '11px',
  color: colorPalette.monotone[9],
  fontWeight: 600,
  padding: '0 4px',
})

export const WidgetAlternative = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const KpiContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '80px',
})

export const KpiTitle = styled('div')({
  fontSize: '11px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
  padding: '0 4px',
})

export const KpiValue = styled('div')({
  fontSize: '21px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
})

export const KpiWithUnit = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
})

export const KpiUnit = styled('div')({
  fontSize: '10px',
  color: colorPalette.monotone[4],
  margin: '5px 0 0 2px',
  whiteSpace: 'nowrap',
})

const NoDataBox = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  height: '30px',
  width: '160px',
  fontSize: '11px',
  color: colorPalette.monotone[4],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const NoData = () => (
  <NoDataBox>{intl.formatMessage({ id: 'data.not.found' })}</NoDataBox>
)

export const ProjectReportBreadCrumbs = ({
  project,
  rootUuid,
  rootWbsItemName,
  onClick,
}: {
  project: ProjectDetail
  rootUuid?: string
  rootWbsItemName?: string
  onClick: (bc?: BreadCrumb) => void
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 16px' }}>
      <Link
        key="root-breadcrumb-project"
        color="inherit"
        component="button"
        onClick={() => onClick(undefined)}
        style={{
          fontFamily: FONT_FAMILY,
          fontSize: FontSize.SMALL,
          fontWeight: FontWeight.BOLD,
          color: TextColor.GREY,
        }}
      >
        {project.displayName}
      </Link>
      <ProjectPlanBreadcrumbs
        rootUuid={rootUuid}
        rootUuidType={'projectPlan'}
        itemName={''}
        onClick={onClick}
        disableCopyPath={true}
        showRoot={true}
      />
      {rootWbsItemName && (
        <>
          <Box sx={{ color: TextColor.GREY, margin: '0 8px' }}>/</Box>
          <Typography
            style={{
              fontFamily: FONT_FAMILY,
              fontSize: FontSize.SMALL,
              fontWeight: FontWeight.BOLD,
              color: TextColor.GREY,
            }}
          >
            {rootWbsItemName}
          </Typography>
        </>
      )}
    </Box>
  )
}
