import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  Column,
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
  RowDragCallbackParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  MemberWorkMonthCell,
  ResourcePlanRow,
  setUserManMonth,
} from '../resourcePlan'
import {
  COL_DEF_DRAG,
  ALERT_UPPER_LIMIT_FOR_MAN_MONTH,
  COL_DEF_ROW_NUMBER,
  COL_DEF_TEAM,
  COL_DEF_TOTAL_BASE,
  COL_DEF_USER_CODE,
  COL_DEF_USER_DIVISION,
  COL_DEF_USER_NAME,
  COL_DEF_USER_POSITION,
  COL_GROUP_DEF_INFORMATION_BASE,
  existsWorkMonthData,
  generateWorkMonthColDef,
  getCalcWorkMonthColCellRendererSelectorResult,
  getDefaultWorkMonthColCellRendererSelectorResult,
  getTotalWorkMonthColCellRendererSelectorResult,
  findAndSetWorkMonthValue,
  workMonthColValueSetter,
  setUserManMonthValue,
  highliteWorkMonthCell,
} from './commonColumnDef'
import {
  NUMBER_DECIMAL_POINT,
  WORKMONTHS_FIELD_PREFIX,
  WorkingDayCalendar,
  getManMonth,
  getTeamParamsCellStyles,
  isTeamRow,
  getUnsetManMonth,
  isWorkMonthColumn,
  getTotalManMonth,
} from './common'
import { intl } from '../../../../i18n'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import { CSSProperties } from 'styled-components'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import BoolExpression from '../../../../utils/boolExpression'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import { roundNumber } from '../../../../utils/number'
import { inputErrorColor } from '../../../containers/commons/AgGrid/lib/validator'

export const AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_TEAM: ColDef = {
  ...COL_DEF_TEAM,
  cellStyle: getTeamParamsCellStyles,
}

const totalRowValueGetter = (params: ValueGetterParams<ResourcePlanRow>) => {
  let grandTotal = 0
  params.api.forEachNode(rowNode => {
    const nodeData: ResourcePlanRow | undefined = rowNode.data
    if (
      nodeData &&
      !nodeData.isTotal &&
      (nodeData.type === ResourcePlanType.TEAM ||
        nodeData.type === ResourcePlanType.UNSET) &&
      params.colDef?.colId
    ) {
      grandTotal += params.api.getValue(params.colDef?.colId, rowNode)
    }
  })
  return roundNumber(grandTotal, NUMBER_DECIMAL_POINT)
}

export const COLUMN_DEF_GROUP_ROW_BY_TEAM:
  | (ColDef<ResourcePlanRow> | ColGroupDef<ResourcePlanRow>)[]
  | null = [
  {
    ...COL_DEF_DRAG,
    rowDrag: (params: RowDragCallbackParams) => {
      const row: ResourcePlanRow = params.data
      return isTeamRow(row?.type)
    },
  },
  COL_DEF_ROW_NUMBER,
  // infomation group
  {
    ...COL_GROUP_DEF_INFORMATION_BASE,
    children: [
      {
        field: 'body.team.name',
        headerName: intl.formatMessage({
          id: 'project.resourcePlan.team',
        }),
        width: 120,
        hide: true,
        pinned: true,
        sortable: false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        valueGetter: params => {
          const row: ResourcePlanRow | undefined = params.data
          if (row?.isTotal) return ''
          return row?.body?.team?.displayName || ''
        },
      },
      {
        ...COL_DEF_USER_NAME,
        headerName: intl.formatMessage({
          id: 'project.resourcePlan.user.total',
        }),
        cellRendererSelector: (params: ICellRendererParams) => {
          const row: ResourcePlanRow = params.data
          if (
            row.isTotal ||
            row.type === ResourcePlanType.TEAM ||
            row.type === ResourcePlanType.UNSET
          ) {
            return {
              component: DefaultCellRenderer,
              params: {
                uiMeta: {
                  requiredIf: BoolExpression.of('FALSE'),
                },
              },
            }
          }
          return {
            component: IconCellRenderer,
            params: {
              ...COL_DEF_USER_NAME.cellRendererParams,
              uiMeta: {},
            },
          }
        },
      },
      COL_DEF_USER_CODE,
      COL_DEF_USER_DIVISION,
      COL_DEF_USER_POSITION,
    ],
  },
  // total columns
  {
    ...COL_DEF_TOTAL_BASE,
    valueGetter: (params: ValueGetterParams<ResourcePlanRow>) => {
      const row: ResourcePlanRow | undefined = params.data
      if (!params.api || !row) return 0
      if (row.isTotal) {
        return totalRowValueGetter(params)
      }
      if (row.type === ResourcePlanType.TEAM) {
        let total = 0
        params.api.forEachNode(rowNode => {
          const nodeData: ResourcePlanRow | undefined = rowNode.data
          if (
            nodeData &&
            !nodeData.isTotal &&
            nodeData.body.team &&
            nodeData.body.team?.uuid === row.body.team?.uuid &&
            nodeData.type === ResourcePlanType.MEMBER
          ) {
            nodeData.body.memberWorkMonths?.forEach(memberWorkMonth => {
              total += getManMonth(memberWorkMonth)
            })
          }
        })
        return roundNumber(total, NUMBER_DECIMAL_POINT)
      }
      if (
        row.type === ResourcePlanType.UNSET ||
        (params.node?.parent?.data?.type === ResourcePlanType.UNSET &&
          row.type === ResourcePlanType.MEMBER)
      ) {
        let total = 0
        if (params.node) {
          params.columnApi
            .getColumns()
            ?.filter((col: Column) =>
              isWorkMonthColumn(col.getParent()?.getGroupId())
            )
            .forEach((col: Column) => {
              total += params.api.getValue(col, params.node!)
            })
        }
        return roundNumber(total, NUMBER_DECIMAL_POINT)
      }
      let total = 0
      row.body.memberWorkMonths?.forEach(memberWorkMonth => {
        total += getManMonth(memberWorkMonth)
      })
      return roundNumber(total, NUMBER_DECIMAL_POINT)
    },
  },
  // year month columns
  {
    groupId: WORKMONTHS_FIELD_PREFIX,
    headerName: '',
  },
]

const adjustWorkMonthValue = (
  memberViewGridApi: GridApi | null | undefined,
  teamViewGridApi: GridApi | null | undefined,
  memberUuid: string | undefined,
  columnId: string | undefined,
  editedRowNode: RowNode<ResourcePlanRow> | null
) => {
  if (!memberViewGridApi || !columnId || !editedRowNode?.data?.uuid) return
  if (editedRowNode.data.type !== ResourcePlanType.MEMBER) return

  const { teamValue, unsetValue } = getTotalManMonth(
    memberViewGridApi,
    memberUuid,
    columnId
  )
  if (0 <= unsetValue) {
    return
  }
  findAndSetWorkMonthValue(
    memberUuid,
    undefined,
    ResourcePlanType.MEMBER,
    memberViewGridApi,
    columnId,
    teamValue
  )
  setUserManMonthValue(
    memberViewGridApi,
    teamViewGridApi,
    memberUuid,
    columnId,
    teamValue
  )
}

const getRootNode = (node: RowNode): RowNode => {
  if (!node.parent) return node
  return getRootNode(node.parent)
}

const isUnsetValueNegative = (params: CellClassParams<ResourcePlanRow>) => {
  const row: ResourcePlanRow | undefined = params.data
  if (!row || row.isTotal || !params.colDef.colId) return false

  const rootNode = getRootNode(params.node)
  const unsetRowNode = rootNode.allLeafChildren?.find(
    (node: RowNode<ResourcePlanRow>) =>
      node.parent?.data &&
      node.parent.data.type === ResourcePlanType.UNSET &&
      node.data &&
      node.data.type === ResourcePlanType.MEMBER &&
      node.data.body.user?.uuid === row.body.user?.uuid
  )
  if (!unsetRowNode) return false

  return params.api?.getValue(params.colDef.colId, unsetRowNode) ?? 0 < 0
}

export const generateTeamViewWorkMonthColDef = (
  yearMonth: string,
  workingDayCalendars: WorkingDayCalendar
): ColDef => {
  const editable = (
    params: EditableCallbackParams<ResourcePlanRow>
  ): boolean => {
    const row: ResourcePlanRow | undefined = params.data
    return (
      !!row &&
      !row.isTotal &&
      row.type === ResourcePlanType.MEMBER &&
      (params.node.parent?.data as ResourcePlanRow).type !==
        ResourcePlanType.UNSET
    )
  }
  const valueGetter = (params: ValueGetterParams) => {
    const row: ResourcePlanRow = params.data
    if (!params.api || !row) return
    if (row.isTotal) {
      return totalRowValueGetter(params)
    }
    if (row.type === ResourcePlanType.TEAM) {
      let teamTotal = 0
      params.api.forEachNode((node: RowNode) => {
        const nodeData: ResourcePlanRow | undefined = node.data
        if (!nodeData) return 0
        if (
          nodeData.type === ResourcePlanType.MEMBER &&
          nodeData.body.team?.uuid === row.body.team?.uuid
        ) {
          const memberWorkMonth = nodeData.body.memberWorkMonths?.find(
            cell => cell.yearMonth === params.colDef.colId!
          )
          teamTotal += getManMonth(memberWorkMonth)
        }
      })
      return roundNumber(teamTotal, NUMBER_DECIMAL_POINT)
    }
    if (row.type === ResourcePlanType.UNSET) {
      let teamTotal = 0
      params.api.forEachNode((node: RowNode) => {
        const nodeData: ResourcePlanRow | undefined = node.data
        if (!nodeData) return 0
        if (
          nodeData.type === ResourcePlanType.MEMBER &&
          nodeData.body.team?.uuid === row.body.team?.uuid
        ) {
          teamTotal += params.api.getValue(params.column, node)
        }
      })
      return roundNumber(teamTotal, NUMBER_DECIMAL_POINT)
    }
    if (row.type === ResourcePlanType.MEMBER) {
      if (
        (params.node?.parent?.data as ResourcePlanRow)?.type ===
        ResourcePlanType.UNSET
      ) {
        return getUnsetManMonth(
          params.context.syncGrid?.api,
          row.body?.user?.uuid,
          params.colDef.colId
        )
      }
    }
    const memberWorkMonth =
      !params.node?.group && !!row.body.memberWorkMonths
        ? row.body.memberWorkMonths.find(
            cell =>
              cell.yearMonth === params.colDef.colId! &&
              cell.manMonth !== undefined
          )
        : undefined
    return memberWorkMonth ? memberWorkMonth.manMonth : undefined
  }
  const valueSetter = (params: ValueSetterParams) => {
    return workMonthColValueSetter(
      params,
      (
        params: ValueSetterParams,
        memberUuid: string | undefined,
        columnId: string | undefined
      ) => {
        adjustWorkMonthValue(
          params.context?.syncGrid?.api,
          params.api,
          memberUuid,
          columnId,
          params.node
        )
      }
    )
  }
  const cellRendererSelector = (params: ICellRendererParams) => {
    const row: ResourcePlanRow = params.data
    if (row.isTotal) {
      return getTotalWorkMonthColCellRendererSelectorResult(params)
    }
    if (row.type === ResourcePlanType.TEAM) {
      return getCalcWorkMonthColCellRendererSelectorResult(
        params,
        (params: ICellRendererParams, node: RowNode<ResourcePlanRow>) => {
          return (
            node.data &&
            !node.data.isTotal &&
            node.parent?.data &&
            params.node.data &&
            node.parent.data.uuid === params.node.data.uuid
          )
        }
      )
    }
    if (row.type === ResourcePlanType.UNSET) {
      return {
        component: NumberCellRenderer,
        params: {
          ...params,
          numberWithCommas: true,
          decimalPoints: NUMBER_DECIMAL_POINT,
          uiMeta: {} as Partial<FunctionProperty>,
          hideZero: (params: ICellRendererParams) => {
            let hideZero: boolean = true
            params.api?.forEachNode(
              (teamViewNode: RowNode<ResourcePlanRow>) => {
                if (
                  !teamViewNode.data ||
                  teamViewNode.data.isTotal ||
                  teamViewNode.data.type !== ResourcePlanType.MEMBER ||
                  teamViewNode.data.body.team?.uuid !==
                    params.node.data?.body.team.uuid
                ) {
                  return
                }
                const targetMemberUuid = teamViewNode.data.body.user?.uuid
                if (
                  !existsWorkMonthData(
                    params,
                    (
                      params: ICellRendererParams,
                      memberViewNode: RowNode<ResourcePlanRow>
                    ) => {
                      return (
                        !!memberViewNode.data?.body.user &&
                        memberViewNode.data.type !== ResourcePlanType.UNSET &&
                        memberViewNode.data.body.user.uuid === targetMemberUuid
                      )
                    },
                    params.context.syncGrid?.api
                  )
                ) {
                  return
                }
                hideZero = false
              }
            )
            return hideZero
          },
        },
      }
    }
    if (
      params.node.parent?.data?.type === ResourcePlanType.UNSET &&
      row.type === ResourcePlanType.MEMBER
    ) {
      return getCalcWorkMonthColCellRendererSelectorResult(
        params,
        (params: ICellRendererParams, node: RowNode<ResourcePlanRow>) => {
          return (
            node.data &&
            !node.data.isTotal &&
            node.data?.body?.user &&
            params.node.data?.body.user &&
            node.data.body.user.uuid === params.node.data.body.user.uuid
          )
        },
        params.context.syncGrid?.api
      )
    }
    return getDefaultWorkMonthColCellRendererSelectorResult(params)
  }
  const cellStyle = (
    params: CellClassParams<ResourcePlanRow>
  ): CSSProperties => {
    return isUnsetValueNegative(params) ||
      highliteWorkMonthCell(params, ResourcePlanType.TEAM)
      ? {
          backgroundColor: inputErrorColor,
        }
      : {}
  }

  return generateWorkMonthColDef(
    yearMonth,
    workingDayCalendars,
    editable,
    valueGetter,
    valueSetter,
    cellRendererSelector,
    cellStyle
  )
}
