import { dateTermVoService } from '../../domain/value-object/DateTermVO'
import DateTimeTermVO from '../../domain/value-object/DateTimeTermVO'
import { WbsItemSearchConditionVO } from '../../domain/value-object/WbsItemSearchConditionVo'
import objects from '../../utils/objects'
import DateTimeVO from '../../domain/value-object/DateTimeVO'
import { ApiResponseValue } from '../model/api'
import { adaptSearchConditionBefore20230125 } from '../compatible-adaptors/wbsItemSearchConditionCompatibleAdaptor'

type WbsItemSearchConditionFactory = {
  fromApiResponseToVo: (src: ApiResponseValue) => WbsItemSearchConditionVO
}

export const useWbsItemSearchConditionFactory =
  (): WbsItemSearchConditionFactory => {
    const fromApiResponseToVo = (
      src: ApiResponseValue
    ): WbsItemSearchConditionVO => {
      if (!src) return {}
      const adapted = adaptSearchConditionBefore20230125(src)
      const dest: WbsItemSearchConditionVO = {}
      for (let entry of Object.entries(adapted)) {
        const key = entry[0]
        const value = entry[1]
        if (!value) continue
        if (
          [
            'scheduledStartDate',
            'scheduledEndDate',
            'actualStartDate',
            'actualEndDate',
          ].includes(key)
        ) {
          if (typeof value !== 'object') continue
          const v = dateTermVoService.construct(value['from'], value['to'])
          objects.setValue(dest, key, v)
          continue
        }
        if (['createdAt', 'updatedAt'].includes(key)) {
          const v = new DateTimeTermVO(
            !!value['from'] ? new DateTimeVO(value['from']) : undefined,
            !!value['to'] ? new DateTimeVO(value['to']) : undefined
          )
          objects.setValue(dest, key, v)
          continue
        }
        objects.setValue(dest, key, value)
      }
      return dest
    }
    return {
      fromApiResponseToVo,
    }
  }
