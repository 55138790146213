import { useCallback, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import DateVO from '../../../../../../../../../vo/DateVO'
import GanttDate from './ganttDate'
import objects from '../../../../../../../../../utils/objects'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'
import { RowNode } from 'ag-grid-enterprise'

export const useGanttIndex = (ganttDate: GanttDate) => {
  const [value, setValue] = useState<number[]>([])
  const [startDisabled, setStartDisabled] = useState(false)
  const [endDisabled, setEndDisabled] = useState(false)

  const moveWith = useRef<'track' | 'thumb' | undefined>()
  const previousValue = useRef<number[]>([])
  const startValue = useRef<number[]>([])
  const grabbedPosValue = useRef<number>(0)
  const selectedRows = useRef<RowNode[]>([])
  const workDays = useRef<number>(0)

  const initialize = useCallback(
    (start, end) => {
      setValue([start, end])
      setStartDisabled(!ganttDate.indexIsActive(start))
      setEndDisabled(!ganttDate.indexIsActive(end))
    },
    [ganttDate]
  )

  const moveWithTrack = useCallback(
    rows => {
      moveWith.current = 'track'
      startValue.current = value.concat()
      previousValue.current = value.concat()
      selectedRows.current = rows
      workDays.current = ganttDate.getWorkDaysByIndex(value)
    },
    [value, ganttDate]
  )

  const setMoveWithTrackOrigin = useCallback(
    (indexes: number[], activeThumnbIdenx: number) => {
      if (moveWith.current === 'track') {
        grabbedPosValue.current = indexes[activeThumnbIdenx]
      }
    },
    []
  )

  const moveWithThumb = useCallback(
    rows => {
      moveWith.current = 'thumb'
      startValue.current = value.concat()
      previousValue.current = value.concat()
      selectedRows.current = rows
    },
    [value]
  )
  const getMovingRows = useCallback(() => selectedRows.current, [selectedRows])

  const moveThrottleWithTrack = useCallback(
    (indexes: number[], activeIndex: number) => {
      const diff = indexes[activeIndex] - grabbedPosValue.current
      grabbedPosValue.current = indexes[activeIndex]
      if (diff === 0) {
        return
      }

      let val = [
        startValue.current[0] + (startDisabled ? 0 : diff),
        startValue.current[1] + (endDisabled ? 0 : diff),
      ]

      if (val[0] < 0) {
        const adjust = val[0] * -1
        val = [
          val[0] + (startDisabled ? 0 : adjust),
          val[1] + (endDisabled ? 0 : adjust),
        ]
        grabbedPosValue.current += adjust
      } else if (ganttDate.getMaxIndex() < val[1]) {
        const adjust = ganttDate.getMaxIndex() - val[1]
        val = [
          val[0] + (startDisabled ? 0 : adjust),
          val[1] + (endDisabled ? 0 : adjust),
        ]
        grabbedPosValue.current += adjust
      }

      // Fix min/max scale
      if (
        !ganttDate.indexIsActive(val[0]) ||
        !ganttDate.indexIsActive(val[1])
      ) {
        setValue(startValue.current)
        return
      }

      // Skip holiday
      const startDate = ganttDate.getStartDateByIndex(val[0])
      const endDate = ganttDate.getEndDateByIndex(val[1])
      const newWorkDays = ganttDate.getWorkDaysByIndex(val)
      const workDayDiff = workDays.current - newWorkDays
      if (workDayDiff) {
        if (0 < diff) {
          // Move forward
          const newDate = ganttDate.getWorkDay(endDate, workDayDiff) ?? endDate
          const newIndex = ganttDate.findIndex(newDate)
          val[1] = newIndex + 1
        } else {
          // Move back
          const newDate =
            ganttDate.getWorkDay(startDate, -workDayDiff) ?? startDate
          val[0] = ganttDate.findIndex(newDate)
        }
      }
      setValue(val)
      startValue.current = val
    },
    [ganttDate, startDisabled, endDisabled]
  )

  const moveThrottle = useCallback(
    (indexes: number[], activeIndex: number) => {
      if (moveWith.current === 'track') {
        moveThrottleWithTrack(indexes, activeIndex)
        return
      }
      setValue(indexes)
    },
    [ganttDate, moveThrottleWithTrack]
  )

  const move = useMemo(
    () =>
      _.throttle(
        (indexes: number[], activeIndex: number) =>
          moveThrottle(indexes, activeIndex),
        100
      ),
    [moveThrottle]
  )

  const changeSelectedRows = useCallback(
    (dataPath: string, skipUuid: string) => {
      // Update scheduled date term of selected rows
      if (selectedRows.current.length === 1) return []
      const rows: any[] = []
      let startDiff = ganttDate.getStartDateDiff(
        previousValue.current[0],
        value[0]
      )
      startDiff =
        0 < value[0] - previousValue.current[0] ? startDiff : -startDiff
      let endDiff = ganttDate.getEndDateDiff(previousValue.current[1], value[1])
      endDiff = 0 < value[1] - previousValue.current[1] ? endDiff : -endDiff
      const today = DateVO.now().toDate()
      selectedRows.current.forEach(projectPlanRowNode => {
        const projectPlanRow = projectPlanRowNode.data
        if (skipUuid === projectPlanRow.uuid) return
        const row = _.cloneDeep(projectPlanRow)
        row.isEdited = true
        const dateTerm = objects.getValue(
          row,
          `${dataPath}wbsItem`
        ).scheduledDate
        let startDate = CalendarDateVO.of(
          new Date(dateTerm?.startDate || dateTerm?.endDate || today)
        )
        startDate = ganttDate.getWorkDay(startDate, startDiff) ?? startDate
        let endDate = CalendarDateVO.of(
          new Date(dateTerm?.endDate || dateTerm?.startDate || today)
        )
        endDate = ganttDate.getWorkDay(endDate, endDiff) ?? endDate
        // Keep date term order
        if (startDiff !== 0 && startDate.isAfter(endDate)) {
          endDate = startDate
        } else if (endDiff !== 0 && endDate.isBefore(startDate)) {
          startDate = endDate
        }
        objects.setValue(row, `${dataPath}wbsItem.scheduledDate`, {
          startDate: new DateVO(startDate.date).serialize(),
          endDate: new DateVO(endDate.date).serialize(),
        })
        rows.push(row)
      })
      selectedRows.current = []
      return rows
    },
    [value, ganttDate]
  )

  const edit = useCallback(
    (data, dataPath) => {
      // Change date
      !startDisabled && ganttDate.setStartDateByIndex(value[0])
      !endDisabled && ganttDate.setEndDateByIndex(value[1])
      // Fix index
      const fixedValue = [ganttDate.startIndex, ganttDate.endIndex]
      if (fixedValue[0] !== value[0] || fixedValue[1] !== value[1]) {
        setValue(fixedValue)
      }
      // Update data
      objects.setValue(data, `${dataPath}wbsItem.scheduledDate`, {
        startDate: new DateVO(ganttDate.startDate.date).serialize(),
        endDate: new DateVO(ganttDate.endDate.date).serialize(),
      })
      data.isEdited = true
      return [data, ...changeSelectedRows(dataPath, data.uuid)]
    },
    [value, ganttDate]
  )

  return {
    value,
    setValue,
    startDisabled,
    endDisabled,

    initialize,
    moveWithTrack,
    setMoveWithTrackOrigin,
    moveWithThumb,
    getMovingRows,
    move,
    edit,
  }
}
