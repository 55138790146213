import { styled } from '@mui/system'
import { DateTerm } from '../../../../../utils/date'
import Select from '../../../editors/select/Select'
import { useCallback, useEffect, useState } from 'react'
import { intl } from '../../../../../i18n'
import { DateSelect } from '../../../toolbars/common'
import { DateSelectType } from '../../../toolbars/common/DateSelect'
import DateVO from '../../../../../vo/DateVO'

export enum SearchUnit {
  MONTH,
  DATE_RANGE,
}

type Props = {
  searchUnit: SearchUnit
  dateTerm: DateTerm
  onSearch: (unit: SearchUnit, term: DateTerm) => void
}

export const DateRangeSelector = (props: Props) => {
  const now = DateVO.now()
  const [year, setYear] = useState<number>(now.getYear())
  const [month, setMonth] = useState<number>(now.getMonth())
  const [searchUnit, setSearchUnit] = useState<SearchUnit>(props.searchUnit)
  const [startDate, setStartDate] = useState<DateVO>(now.getFirstDayOfMonth())
  const [endDate, setEndDate] = useState<DateVO>(now.getLastDayOfMonth())
  const [execSearch, setExecSearch] = useState(false)

  useEffect(() => {
    const propStartDate = getFirstDayOfMonth()
    const propEndDate = getLastDayOfMonth()
    const unit = getSearchUnit()
    setSearchUnit(unit)
    setYear(propStartDate.getYear())
    setMonth(propStartDate.getMonth())
    setStartDate(propStartDate)
    setEndDate(propEndDate)
  }, [props.searchUnit, props.dateTerm.startDate, props.dateTerm.endDate])

  useEffect(() => {
    if (!execSearch) return
    search()
    setExecSearch(false)
  }, [execSearch])

  const getFirstDayOfMonth = useCallback(() => {
    return props.dateTerm && props.dateTerm.startDate
      ? new DateVO(props.dateTerm.startDate)
      : DateVO.now().getFirstDayOfMonth()
  }, [props.dateTerm.startDate])

  const getLastDayOfMonth = useCallback(() => {
    return props.dateTerm && props.dateTerm.endDate
      ? new DateVO(props.dateTerm.endDate)
      : DateVO.now().getLastDayOfMonth()
  }, [props.dateTerm.endDate])

  const getSearchUnit = useCallback(() => {
    return props.searchUnit ?? SearchUnit.MONTH
  }, [props.searchUnit])

  const search = useCallback(() => {
    props.onSearch(searchUnit, {
      startDate: startDate.formatForApi(),
      endDate: endDate.formatForApi(),
    })
  }, [searchUnit, startDate, endDate, execSearch])

  const onChangeUnit = useCallback(
    e => {
      if (e.target.value === SearchUnit.MONTH) {
        setSearchUnit(SearchUnit.MONTH)
        onChangeYearMonth(`${year}-${month}-1`)
      } else if (e.target.value === SearchUnit.DATE_RANGE) {
        setSearchUnit(SearchUnit.DATE_RANGE)
        onChangeStartDate(startDate.toLabel())
      }
    },
    [year, month, startDate]
  )

  const onChangeYearMonth = useCallback(value => {
    if (!value) return
    const date = new DateVO(value)
    setYear(date.getYear())
    setMonth(date.getMonth())
    setStartDate(date.getFirstDayOfMonth())
    setEndDate(date.getLastDayOfMonth())
    setExecSearch(true)
  }, [])

  const onChangeStartDate = useCallback(value => {
    setStartDate(value ? new DateVO(value) : getFirstDayOfMonth())
    setExecSearch(true)
  }, [])

  const onChangeEndDate = useCallback(value => {
    setEndDate(value ? new DateVO(value) : getLastDayOfMonth())
    setExecSearch(true)
  }, [])

  return (
    <DateRangeRoot>
      <SearchUnitDiv>
        <Select
          fullWidth={true}
          value={searchUnit}
          onChange={onChangeUnit}
          disableUnderline={true}
          options={[
            {
              value: SearchUnit.MONTH,
              label: intl.formatMessage({
                id: 'workReport.searchUnit.month',
              }),
            },
            {
              value: SearchUnit.DATE_RANGE,
              label: intl.formatMessage({
                id: 'workReport.searchUnit.dateRange',
              }),
            },
          ]}
          sx={{
            height: '28px',
            fontSize: '11px',
          }}
        />
      </SearchUnitDiv>
      {searchUnit === SearchUnit.MONTH && (
        <YearMonthSelect>
          <DateSelect
            type={DateSelectType.YEAR_MONTH}
            value={new DateVO(`${year}-${month}-1`)}
            onChange={onChangeYearMonth}
          />
        </YearMonthSelect>
      )}
      {searchUnit === SearchUnit.DATE_RANGE && (
        <>
          <StartDateDiv>
            <DateSelect
              type={DateSelectType.DATE}
              value={startDate}
              onChange={onChangeStartDate}
              placeholder="YYYY/MM/DD"
            />
          </StartDateDiv>
          <TermDiv>〜</TermDiv>
          <EndDateDiv>
            <DateSelect
              type={DateSelectType.DATE}
              value={endDate}
              onChange={onChangeEndDate}
              placeholder="YYYY/MM/DD"
            />
          </EndDateDiv>
        </>
      )}
    </DateRangeRoot>
  )
}

const DateRangeRoot = styled('div')({
  width: '100%',
  padding: '10px 0',
  marginRight: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const SearchUnitDiv = styled('div')({
  width: '100px',
  border: 'solid 1px #C4C4C4',
  borderRadius: '4px',
  padding: '0 5px',
  marginRight: '6px',
})

const YearMonthSelect = styled('div')({
  width: '100px',
  zIndex: 3,
})

const StartDateDiv = styled('div')({
  width: '120px',
  zIndex: 3,
})

const EndDateDiv = styled('div')({
  width: '120px',
  zIndex: 3,
  marginRight: '6px',
})

const TermDiv = styled('div')({
  padding: '5px',
})
