import { useEffect, useState } from 'react'
import { Avatar, Typography } from '@mui/material'
import { ICellRendererParams } from 'ag-grid-community'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import { setError } from '../../lib/validation'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'

interface Props extends ICellRendererParams {
  fieldInContext: string
  uiMeta?: FunctionProperty
}

export const WbsItemTypeCellRenderer = (props: Props) => {
  const { value, data, uiMeta } = props
  const [message, setMessage] = useState<string | undefined>()

  useEffect(() => {
    if (uiMeta?.requiredIf) {
      const result = uiMeta.requiredIf.check(value, () => ({}))
      const err = result.ok ? undefined : result.message
      setError(data, err, props)
      setMessage(err)
    }
  }, [value, uiMeta?.requiredIf, data, props])

  const wbsItemType = value as WbsItemTypeVO

  return (
    <Tooltip message={message}>
      <RootDiv style={{ backgroundColor: !!message ? '#FADBD8' : undefined }}>
        {wbsItemType?.iconUrl && (
          <Avatar
            variant="circular"
            src={wbsItemType.iconUrl}
            sx={{ margin: '0px 5px', width: 20, height: 20 }}
          />
        )}
        <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
          {wbsItemType?.getNameWithSuffix()}
        </Typography>
      </RootDiv>
    </Tooltip>
  )
}
