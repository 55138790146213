import { Typography, styled } from '@mui/material'
import { colorPalette } from '../../../../../style/colorPallete'
import { FontSize, FontWeight } from '../../../../../../styles/commonStyles'
import { intl } from '../../../../../../i18n'

export const DatePickerAreaDiv = styled('div')({
  height: '48px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: colorPalette.monotone[2],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  padding: '8px 16px 8px 16px',
  minWidth: 'fit-content',
})

export const DateTermAreaDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
})

export const DateAreaDiv = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
})

export const DateArea = ({
  year,
  month,
  day,
  dayOfWeek,
}: {
  year?: string
  month: string
  day?: string
  dayOfWeek?: string
}) => {
  return (
    <DateAreaDiv>
      {year && (
        <Typography
          fontSize={FontSize.MEDIUM}
          sx={{ color: colorPalette.monotone[4] }}
        >
          {intl.formatMessage(
            { id: 'header.termSelector.year' },
            { year: year }
          )}
        </Typography>
      )}
      <Typography
        fontSize={'15px'}
        fontWeight={FontWeight.BOLD}
        sx={{
          color: colorPalette.monotone[5],
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {day
          ? intl.formatMessage(
              { id: 'header.termSelector.monthDay' },
              { month: month, day: day }
            )
          : intl.formatMessage(
              { id: 'header.termSelector.month' },
              { month: month }
            )}
      </Typography>
      {dayOfWeek && (
        <Typography
          fontSize={FontSize.MEDIUM}
          sx={{
            color: colorPalette.monotone[4],
            backgroundColor: colorPalette.monotone[2],
            width: '20px',
            height: '20px',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {dayOfWeek}
        </Typography>
      )}
    </DateAreaDiv>
  )
}

export const DateTermDivider = () => {
  return (
    <Typography fontSize={'12px'} sx={{ color: colorPalette.monotone[4] }}>
      ～
    </Typography>
  )
}
