import { openWbsItemSearch } from '../../WbsItemSearch/wbsItemSearchOptions'
import React from 'react'
import PageHeader, {
  Header,
  HeaderContent,
} from '../../../components/headers/HeaderBar/PageHeader'
import { getDefaultSearchFilter } from '../../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { isProduction } from '../../../../utils/urls'
import SubmitButton from '../../../components/buttons/SubmitButton'
import ReloadButton from '../../../components/buttons/ReloadButton'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import { ToolbarDivider } from '../../../components/toolbars/Toolbar'
import SettingSpeedDialActions, {
  Props as SettingSpeedDialActionsProps,
} from '../Header/SettingSpeedDialActions'
import { styled } from '@mui/material'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { useDialogState } from '../../../components/dialogs/useDialogState'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import SearchWindow from '../../../components/toolbars/ContainerToolBar/SearchWindow'

interface OwnProps {
  projectUuid: string
  title: string
  onSubmit: () => void
  onCancel: () => void
  submitDisabled: boolean
  isLoading: boolean
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
}

interface StateProps {
  notEdited: boolean
}

const TOGGLE_ITEMS = [ToolbarToggleValue.DISPLAY]
const RIGHT_HEADER_CONTENT_STYLE = {
  marginRight: isProduction ? undefined : 60,
}

const HeaderGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})

const HeaderIconGroup = styled('div')({
  display: 'flex',
  margin: '0 5px',
  width: 26,
  height: 26,
})

type Props = OwnProps &
  StateProps &
  SettingSpeedDialActionsProps &
  WrappedComponentProps

const ProjectPlanHeader = (props: Props) => {
  const [searchFilter, setSearchFilter] = React.useState(
    getDefaultSearchFilter()
  )
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()

  const onConfirmInCancelDialog = React.useCallback(() => {
    closeCancelDialog()
    props.onCancel()
  }, [closeCancelDialog, props.onCancel])
  const onClickSearchInWbsItemSearchToolbar = React.useCallback(() => {
    openWbsItemSearch(props.projectUuid, searchFilter, '')
  }, [props.projectUuid, searchFilter])

  return (
    <PageHeader>
      <Header>
        <HeaderContent>
          <HeaderGroup>
            <SubmitButton
              onClick={props.onSubmit}
              disabled={
                props.submitDisabled || props.isLoading || props.notEdited
              }
              notEdited={props.notEdited}
            />
            <ReloadButton
              onClick={openCancelDialog}
              disabled={props.isLoading}
            />
            <CancelConfirmDialog
              open={showCancelDialog}
              onConfirm={onConfirmInCancelDialog}
              onClose={closeCancelDialog}
            />
          </HeaderGroup>
          <ToolbarDivider />
          <HeaderGroup>
            <HeaderIconGroup>
              <SettingSpeedDialActions
                onResetColumnState={props.onResetColumnState}
                onClickExport={props.onClickExport}
                onRestoreColumnState={props.onRestoreColumnState}
                currentFormat={props.currentFormat}
                onClickChangeDateFormat={props.onClickChangeDateFormat}
              />
            </HeaderIconGroup>
          </HeaderGroup>
          <ToolbarToggleGroup
            toggleItems={TOGGLE_ITEMS}
            value={props.toolbar}
            onChange={props.onChangeToolbar}
          />
        </HeaderContent>
        <HeaderContent style={RIGHT_HEADER_CONTENT_STYLE}>
          <SearchWindow
            reloadHandler={onClickSearchInWbsItemSearchToolbar}
            onChange={(searchName?: string) =>
              setSearchFilter({
                ...searchFilter,
                displayName: searchName,
              })
            }
            hideButton={true}
            searchText={searchFilter.displayName}
            searchTextPlaceholder={props.intl.formatMessage({
              id: 'search.displayName',
            })}
          />
        </HeaderContent>
      </Header>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(injectIntl(ProjectPlanHeader))
