import { intl } from '../i18n'
import lazyStoreLoader from '../store/lazyStoreLoader'
import { changedCurrentAuthorization } from '../lib/commons/auth'
import { showAlert } from '../store/globalAlert'

class AuthorizationHandler {
  private execute = async event => {
    if (changedCurrentAuthorization(event)) {
      const store = await lazyStoreLoader()
      if (store.getState().user.authorized) {
        store.dispatch(
          showAlert({
            message: intl.formatMessage({ id: 'auth.error.loggedOut' }),
            action: () => window.location.reload(),
          })
        )
      }
    }
  }

  register = async () => {
    window.addEventListener('storage', this.execute)
  }
}

export default new AuthorizationHandler()
