import {
  PredeterminedTicketType,
  TicketEntity,
} from '../../../../domain/entity/ticket'
import { ChangeRequestEntity } from '../../../../domain/entity/ticket/ChangeRequestEntity'
import { IssueEntity } from '../../../../domain/entity/ticket/IssueEntity'
import { MeetingEntity } from '../../../../domain/entity/ticket/MeetingEntity'
import { RiskEntity } from '../../../../domain/entity/ticket/RiskEntity'
import { generateUuid } from '../../../../utils/uuids'

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
type ReplaceBy<T, K extends keyof T, V> = Omit<T, K> & { [P in K]: V }

export type TicketFormModel = Omit<
  PartialBy<TicketEntity, 'ticketList'>,
  'wbsItem'
>

export const createTicketInitialValue = (
  ticketType: PredeterminedTicketType | string
): TicketFormModel => {
  const uuid = generateUuid()
  return {
    uuid,
    ticketType,
  }
}
