import _ from 'lodash'
import store, { AllState } from '../../../store'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { PageArea, PageProps } from '..'
import { BulkSheetView } from '../../containers/BulkSheetView'
import Loading from '../../components/process-state-notifications/Loading'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { intl } from '../../../i18n'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ChatChannelMessageTicketCreationMappingRow } from './chatChannelMessageTicketCreationMapping'
import { ChatChannelMessageTicketCreationMappingGridOptions } from './gridOptions'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import {
  GetContextMenuItemsParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { useDialog } from './hooks/dialog'
import { addRowMenuItems, deleteRowMenuItems } from './gridOptions/contextMenu'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { getSelectedNode } from '../../containers/BulkSheetView/lib/gridApi'
import { removeRows } from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useChatChannelMessageTicketCreationMappingsData } from './hooks/data'
import { useSelector } from 'react-redux'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import Header from './components/Header'
import { useSlackExternalServiceStorageService } from '../../../services/storage-services/external-services/slackExternalServiceStorageService'
import { SlackWorkspace } from '../../../domain/value-object/Slack/WorkspaceVO'
import { SlackChannel } from '../../../domain/value-object/Slack/ChannelVO'

type WrapperProps = PageProps

export const ChatChannelMessageTicketCreationMapping = projectPrivate(
  ({ uuid: functionUuid }: WrapperProps) => {
    const { project } = useProjectPrivateContext()
    const [loading, setLoading] = useState<boolean>(true)
    const [initializedSelectOptions, setInitializedSelectOptions] =
      useState<boolean>(false)
    const [authorizedSlackWorkspace, setAuthorizedSlackWorkspace] =
      useState<SlackWorkspace>()
    const ref = useRef<HTMLDivElement>(null)

    // Initialize the authorized workspace.
    const { getAuthorizedSlackWorkspace, getChannelsFromSlackApi } =
      useSlackExternalServiceStorageService()
    useEffect(() => {
      getAuthorizedSlackWorkspace().then(slackWorkspaces => {
        if (slackWorkspaces?.length > 0) {
          const authorizedSlackWorkspace = slackWorkspaces[0]
          setAuthorizedSlackWorkspace(authorizedSlackWorkspace)
        }
      })
    }, [])

    const { data, setData, refresh, save, deleteRows, hasDuplicatedData } =
      useChatChannelMessageTicketCreationMappingsData(
        project.uuid,
        authorizedSlackWorkspace?.slackWorkspaceId
      )

    const gridOptions = useMemo(() => {
      return ChatChannelMessageTicketCreationMappingGridOptions()
    }, [])
    const ticketTypes = useSelector<AllState, WbsItemTypeVO[]>(
      state => state.project.ticketTypes
    )
    const fetchSelectColumnOptions = useCallback(
      async (projectUuid: string, chatWorkspaceId: string) => {
        const slackChannels = await getChannelsFromSlackApi(chatWorkspaceId)
        gridOptions.context = {
          ...gridOptions.context,
          ticketType: ticketTypes,
          slackChannels,
        }
        setInitializedSelectOptions(true)
      },
      [gridOptions, ticketTypes]
    )

    useEffect(() => {
      const authorizedChatWorkspaceId =
        authorizedSlackWorkspace?.slackWorkspaceId
      if (authorizedChatWorkspaceId && !initializedSelectOptions) {
        fetchSelectColumnOptions(project.uuid, authorizedChatWorkspaceId)
      }
    }, [
      project.uuid,
      authorizedSlackWorkspace,
      fetchSelectColumnOptions,
      initializedSelectOptions,
    ])

    const submit = useRef<Function>()
    submit.current = save
    const reload = useRef<Function>()
    reload.current = refresh

    const refreshAll = useCallback(async () => {
      if (!gridOptions.api) return
      setLoading(true)
      try {
        const refreshRows = reload.current ?? refresh
        await refreshRows(project.uuid)
      } finally {
        gridOptions.context = {
          ...gridOptions.context,
          errors: new InputError(),
        }
        setLoading(false)
      }
    }, [gridOptions, project.uuid, refresh])

    const onSubmit = useCallback(async () => {
      setLoading(true)
      try {
        gridOptions.api?.stopEditing()
        if (gridOptions.context.errors.hasMessage()) {
          store.dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'global.warning.businessError' }),
              text: gridOptions.context.errors.toMessage(id => {
                const node = gridOptions.api?.getRowNode(id)
                return !!node
                  ? (
                      data.findIndex(v => v.uuid === node.data.uuid) + 1
                    ).toString()
                  : ''
              }),
            })
          )
          return
        }
        if (hasDuplicatedData()) {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.WARN,
              title: intl.formatMessage({
                id: 'chatChannelMessageTicketCreationMapping.validation.message.duplicatedRows',
              }),
            })
          )
          return
        }

        const response = submit.current ? await submit.current() : await save()
        if (!response.hasError && !response.hasWarning) {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.SUCCESS,
              title: intl.formatMessage({ id: 'registration.complete' }),
            })
          )
          await refreshAll()
        }
      } finally {
        setLoading(false)
      }
    }, [
      data,
      functionUuid,
      gridOptions.api,
      gridOptions.context.errors,
      hasDuplicatedData,
      refreshAll,
      save,
    ])

    const hasChanged = useCallback((): boolean => {
      return (prevData.current || data).some(v => v.added || v.edited)
    }, [data])

    const dialog = useDialog()

    const onReload = useCallback(() => {
      if (hasChanged()) {
        dialog.openCancel()
      } else {
        refreshAll()
      }
    }, [dialog, hasChanged, refreshAll])

    const onDeleteRows = useCallback(() => {
      const rows = getSelectedNode(gridOptions.api!).map(
        v => v.data
      ) as ChatChannelMessageTicketCreationMappingRow[]
      const target = _.uniqBy(rows, 'uuid')
      const newData = removeRows(data, target)
      if (gridOptions.context.errors) {
        target.forEach(v => gridOptions.context.errors.removeErrorsById(v.uuid))
      }
      setData(newData)
      deleteRows(target)
      dialog.closeDeleteConfirmation()
    }, [data, deleteRows, dialog, gridOptions.api, setData])

    const bulkSheetState = useBulkSheetState(functionUuid, project.uuid)

    const prevData = useRef<ChatChannelMessageTicketCreationMappingRow[]>([])
    prevData.current = data

    const contextMenu = useCallback(
      (params: GetContextMenuItemsParams) => {
        const selectedNodes = getSelectedNode(params.api)
        if (!params.node) return []
        const items = [
          ...addRowMenuItems(params, data, setData, project.uuid),
          ...deleteRowMenuItems(selectedNodes, dialog.openDeleteConfirmation),
        ]
        return items
      },
      [data, dialog.openDeleteConfirmation, project.uuid, setData]
    )

    useEffect(() => {
      if (!initializedSelectOptions || !bulkSheetState.initialized) {
        return
      }
      refreshAll()
    }, [refreshAll, initializedSelectOptions, bulkSheetState.initialized])

    useKeyBind(
      [{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }],
      [onSubmit]
    )

    return (
      <PageArea>
        <Header loading={loading} onSubmit={onSubmit} onReload={onReload} />
        <BulkSheetView
          ref={ref}
          gridOptions={gridOptions}
          rowData={data}
          getContextMenuItems={contextMenu}
          onRowDataUpdated={(
            e: RowDataUpdatedEvent<ChatChannelMessageTicketCreationMappingRow>
          ) => e.api?.refreshCells({ columns: ['rowNumber'], force: true })}
        />
        <Loading isLoading={loading} elem={ref.current} />
        {dialog.deleteConfirmation && gridOptions.api && (
          <DeleteRowConfirmationDialog
            target={getSelectedNode(gridOptions.api).map(v => {
              const row = v.data as ChatChannelMessageTicketCreationMappingRow
              const chatChannelLabel = row.chatChannel
                ? row.chatChannel.slackChannelName
                : intl.formatMessage({
                    id: 'chatChannelMessageTicketCreationMapping.dialog.deleteRowsConfirmation.message.chatChannel.undefined',
                  })
              const ticketTypeLabel = row.ticketType
                ? row.ticketType.getNameWithSuffix()
                : intl.formatMessage({
                    id: 'chatChannelMessageTicketCreationMapping.dialog.deleteRowsConfirmation.message.ticketType.undefined',
                  })
              const ticketListLabel = row.ticketList
                ? row.ticketList.displayName
                : intl.formatMessage({
                    id: 'chatChannelMessageTicketCreationMapping.dialog.deleteRowsConfirmation.message.ticketList.undefined',
                  })
              return `[${chatChannelLabel}]-[${ticketTypeLabel}]-[${ticketListLabel}]`
            })}
            onConfirm={onDeleteRows}
            onClose={dialog.closeDeleteConfirmation}
          />
        )}
        {dialog.cancel && (
          <CancelConfirmDialog
            open={true}
            onConfirm={() => {
              dialog.closeCancel()
              refreshAll()
            }}
            onClose={dialog.closeCancel}
          />
        )}
      </PageArea>
    )
  }
)
