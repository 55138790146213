import VOBase from '../base'
import { CustomEnumValue } from '../../lib/commons/appFunction'

export default class MultiSelectVO extends VOBase {
  private readonly value: CustomEnumValue[]

  constructor(_value: CustomEnumValue[]) {
    super()
    this.value = _value
  }

  getValue = () => {
    return this.value
  }
  format = () => {
    if (!this.value || !Array.isArray(this.value)) {
      return ''
    }
    return this.value.map(v => v.name).join(',')
  }
  serialize = () => this.value

  isEqual(comparedValue: VOBase): boolean {
    if (comparedValue instanceof MultiSelectVO) {
      return JSON.stringify(this.value) === JSON.stringify(comparedValue.value)
    }
    return false
  }
}
