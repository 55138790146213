import { styled } from '@mui/material'
import { VersionReportStatus } from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded'
import WbCloudyRoundedIcon from '@mui/icons-material/WbCloudyRounded'
import ThunderstormRoundedIcon from '@mui/icons-material/ThunderstormRounded'
import { colorPalette } from '../../../../style/colorPallete'

type ReportStatusIconProps = {
  status: VersionReportStatus
}
export const ReportStatusIcon = ({ status }: ReportStatusIconProps) => {
  switch (status) {
    case 'SUNNY':
      return <SunnyIcon />
    case 'CLOUDY':
      return <CloudyIcon />
    case 'RAINY':
      return <RainyIcon />
  }
}

const SunnyIcon = styled(WbSunnyRoundedIcon)({
  color: colorPalette.yellow[3],
  fontSize: '36px',
})
const CloudyIcon = styled(WbCloudyRoundedIcon)({
  color: colorPalette.monotone[3],
  fontSize: '36px',
})
const RainyIcon = styled(ThunderstormRoundedIcon)({
  color: colorPalette.blue[8],
  fontSize: '36px',
})
