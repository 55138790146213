import React, { CSSProperties } from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessIcon from '@mui/icons-material/ExpandLessRounded'
import ClearIcon from '@mui/icons-material/CancelRounded'
import { ToggleButton } from '@mui/material'
import objects from '../../../../../../utils/objects'
import DateVO from '../../../../../../vo/DateVO'
import { AutocompleteOption } from '../../../../../containers/DataCell'
import { getWbsItemSearchConditionItemLabel } from '../../utils'
import {
  SearchConditionProps,
  SearchFilter,
  WbsItemSearchConditionKey,
} from '../../WbsItemSearchConditions/WbsItemSearchCondition'
import { BackgroundColor } from '../../../../../../styles/commonStyles'
import SearchConditionEditorPopup from '../SearchConditionEditorPopup'
import { intl } from '../../../../../../i18n'
import store from '../../../../../../store'

const RootDiv = styled('div')<{ active: boolean }>(({ active }) => {
  const defaultStyle: CSSProperties = {
    height: '100%',
    whiteSpace: 'nowrap',
    padding: '2px 4px',
    display: 'flex',
    borderRadius: '4px',
  }
  if (active) {
    return {
      ...defaultStyle,
      background: BackgroundColor.BLUE_GRADATION,
      color: '#FFFFFF !important',
    }
  } else {
    return {
      ...defaultStyle,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      color: '#868686',
    }
  }
})
const StyledToggleButton = styled(ToggleButton)<{ active: boolean }>(
  ({ active }) => {
    const defaultStyle: CSSProperties = {
      maxWidth: '200px',
      height: '100%',
      margin: 'auto 0',
      whiteSpace: 'nowrap',
      border: 'none',
      padding: 0,
    }
    if (active) {
      return {
        ...defaultStyle,
        background: BackgroundColor.BLUE_GRADATION,
        color: '#FFFFFF !important',
      }
    } else {
      return {
        ...defaultStyle,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        color: '#868686',
      }
    }
  }
)
const ItemLabel = styled('span')({
  width: '100%',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  textTransform: 'none',
})
const ToggleSearchConditionEditorButton = styled(IconButton)({
  marginLeft: '-5px',
})
const ClearButton = styled(IconButton)({
  marginLeft: '-5px',
})

interface ComponentOwnProps {
  functionExternalId: string
  wbsItemSearchCondition: SearchConditionProps
  searchFilter: SearchFilter
  onChange: (searchFilter: SearchFilter) => Promise<void> | void
}

interface Props extends ComponentOwnProps {}

const getFilteringItemValueLabel = ({
  wbsItemSearchConditionKey,
  searchFilter,
}: {
  wbsItemSearchConditionKey: WbsItemSearchConditionKey
  searchFilter: SearchFilter
}): string => {
  const value = objects.getValue(searchFilter, wbsItemSearchConditionKey)
  const isBlank = objects.getValue(
    searchFilter,
    `blank.${wbsItemSearchConditionKey}`
  )
  if (isBlank) {
    return intl.formatMessage({ id: 'wbsItemSearchFilterPanel.blank' })
  }
  if (objects.isEmpty(value)) {
    return ''
  }
  switch (wbsItemSearchConditionKey) {
    case WbsItemSearchConditionKey.CODE:
    case WbsItemSearchConditionKey.DISPLAY_NAME:
    case WbsItemSearchConditionKey.DESCRIPTION:
      return value as string
    case WbsItemSearchConditionKey.TYPES: {
      const wbsItemTypes = store.getState().project.wbsItemTypes
      return wbsItemTypes.isEmpty()
        ? ''
        : value.map(v => wbsItemTypes.get(v)?.name ?? '').join(',')
    }
    case WbsItemSearchConditionKey.STATUS:
      return Array.isArray(value) ? value.join(',') : ''
    case WbsItemSearchConditionKey.PRIORITY: {
      return value.map(v => v.name).join(',')
    }
    case WbsItemSearchConditionKey.TICKET_TYPES: {
      const ticketTypes = store.getState().project.ticketTypes
      return ticketTypes.length === 0
        ? ''
        : value
            .map(
              v =>
                ticketTypes.find(t => t.code === v)?.name ||
                intl.formatMessage({ id: 'none' })
            )
            .join(',')
    }
    case WbsItemSearchConditionKey.DELIVERABLE:
    case WbsItemSearchConditionKey.TEAM:
    case WbsItemSearchConditionKey.ACCOUNTABLE:
    case WbsItemSearchConditionKey.RESPONSIBLE:
    case WbsItemSearchConditionKey.ASSIGNEE:
    case WbsItemSearchConditionKey.SPRINT:
    case WbsItemSearchConditionKey.CREATED_BY:
    case WbsItemSearchConditionKey.UPDATED_BY:
      return (value as AutocompleteOption).name!
    case WbsItemSearchConditionKey.SCHEDULED_START_DATE:
    case WbsItemSearchConditionKey.SCHEDULED_END_DATE:
    case WbsItemSearchConditionKey.ACTUAL_START_DATE:
    case WbsItemSearchConditionKey.ACTUAL_END_DATE: {
      let { from, to } = value as {
        from?: DateVO
        to?: DateVO
      }
      if (from) {
        from =
          typeof from.toDate === 'function' ? from : new DateVO(from as any)
      }
      if (to) {
        to = typeof to.toDate === 'function' ? to : new DateVO(to as any)
      }
      if (from && to) return from.toLabel() + '〜' + to.toLabel()
      if (from) return from.toLabel()
      if (to) return to.toLabel()
      return ''
    }
    case WbsItemSearchConditionKey.CREATED_AT:
    case WbsItemSearchConditionKey.UPDATED_AT: {
      let { from, to } = value as {
        from?: DateVO
        to?: DateVO
      }
      if (from) {
        from =
          typeof from.toDate === 'function' ? from : new DateVO(from as any)
      }
      if (to) {
        to = typeof to.toDate === 'function' ? to : new DateVO(to as any)
      }
      if (from && to) {
        return from.formatYYYYMMDDHHmm() + '〜' + to.formatYYYYMMDDHHmm()
      }
      if (from) return from.formatYYYYMMDDHHmm()
      if (to) return to.formatYYYYMMDDHHmm()
      return ''
    }
    case WbsItemSearchConditionKey.CRITICAL:
      return (value as boolean) ? '○' : ''
    default:
      throw new Error('Unsupported WbsItemSearchConditionKey.')
  }
}

const ToggleSearchConditionEditor = ({ isOpen, onClick }) => {
  return (
    <ToggleSearchConditionEditorButton
      aria-label="Open wbs item search condition editor"
      size="small"
      onClick={onClick}
    >
      {isOpen ? (
        <ExpandLessIcon fontSize="small" />
      ) : (
        <ExpandMoreIcon fontSize="small" />
      )}
    </ToggleSearchConditionEditorButton>
  )
}

const ClearSearchCondition = ({ onClick }) => {
  return (
    <ClearButton
      aria-label="Clear wbs item search condition"
      size="small"
      onClick={onClick}
    >
      <ClearIcon fontSize="small" />
    </ClearButton>
  )
}

interface State {
  isOpenSearchConditionEditor: boolean
  editedSearchFilter: SearchFilter
  edited: boolean
}

class FilteringItemLabel extends React.PureComponent<Props, State> {
  private rootRef = React.createRef<HTMLDivElement>()

  state = {
    isOpenSearchConditionEditor: false,
    editedSearchFilter: this.props.searchFilter,
    edited: false,
  }

  componentDidUpdate() {
    if (!this.state.edited) {
      this.setState({
        editedSearchFilter: this.props.searchFilter,
      })
    }
  }

  toggleSearchConditionEditor = () => {
    this.setState({
      isOpenSearchConditionEditor: !this.state.isOpenSearchConditionEditor,
    })
  }

  onChangeSearchConditionValue = (editedSearchFilter: SearchFilter) => {
    this.setState({
      editedSearchFilter: _.cloneDeep(editedSearchFilter),
      edited: true,
    })
  }

  clearSearchCondition = () => {
    const { wbsItemSearchCondition, onChange, searchFilter } = this.props
    const newSearchFilter = _.cloneDeep(searchFilter)
    switch (wbsItemSearchCondition.key) {
      case WbsItemSearchConditionKey.CODE:
      case WbsItemSearchConditionKey.DISPLAY_NAME:
      case WbsItemSearchConditionKey.DESCRIPTION:
      case WbsItemSearchConditionKey.TYPES:
      case WbsItemSearchConditionKey.STATUS:
      case WbsItemSearchConditionKey.PRIORITY:
      case WbsItemSearchConditionKey.TICKET_TYPES:
      case WbsItemSearchConditionKey.DELIVERABLE:
        newSearchFilter[wbsItemSearchCondition.key] = undefined
        return onChange(newSearchFilter)
      case WbsItemSearchConditionKey.TEAM:
        newSearchFilter[wbsItemSearchCondition.key] = {}
        return onChange(newSearchFilter)
      case WbsItemSearchConditionKey.ACCOUNTABLE:
      case WbsItemSearchConditionKey.RESPONSIBLE:
      case WbsItemSearchConditionKey.ASSIGNEE:
      case WbsItemSearchConditionKey.CREATED_BY:
      case WbsItemSearchConditionKey.UPDATED_BY:
        newSearchFilter[wbsItemSearchCondition.key] = undefined
        return onChange(newSearchFilter)
      case WbsItemSearchConditionKey.SPRINT:
        newSearchFilter[wbsItemSearchCondition.key] = {}
        newSearchFilter.blank[wbsItemSearchCondition.key] = undefined
        return onChange(newSearchFilter)
      case WbsItemSearchConditionKey.SCHEDULED_START_DATE:
      case WbsItemSearchConditionKey.SCHEDULED_END_DATE:
      case WbsItemSearchConditionKey.ACTUAL_START_DATE:
      case WbsItemSearchConditionKey.ACTUAL_END_DATE:
      case WbsItemSearchConditionKey.CREATED_AT:
      case WbsItemSearchConditionKey.UPDATED_AT:
        newSearchFilter[wbsItemSearchCondition.key] = {}
        newSearchFilter.blank[wbsItemSearchCondition.key] = undefined
        return onChange(newSearchFilter)
      case WbsItemSearchConditionKey.CRITICAL:
        newSearchFilter[wbsItemSearchCondition.key] = undefined
        return onChange(newSearchFilter)
      default:
        throw new Error('Unsupported WbsItemSearchConditionKey.')
    }
  }

  onFinishedEditSearchCondition = () => {
    const { onChange } = this.props
    const edited = this.state.edited
    this.setState({ isOpenSearchConditionEditor: false, edited: false })
    if (edited) {
      onChange(this.state.editedSearchFilter)
    }
  }

  render() {
    const { wbsItemSearchCondition, searchFilter } = this.props
    const label = getWbsItemSearchConditionItemLabel({
      wbsItemSearchConditionKey: wbsItemSearchCondition.key,
    })
    const value = getFilteringItemValueLabel({
      wbsItemSearchConditionKey: wbsItemSearchCondition.key,
      searchFilter,
    })
    if (!this.state.edited && (!value || !label)) return <></>

    return (
      <RootDiv
        ref={this.rootRef}
        active={this.state.isOpenSearchConditionEditor}
      >
        <StyledToggleButton
          // TODO Use MultipleToggleGroup
          value="#"
          size="small"
          onClick={this.toggleSearchConditionEditor}
          selected={this.state.isOpenSearchConditionEditor}
          active={this.state.isOpenSearchConditionEditor}
        >
          <ItemLabel>
            {label}:{value}
          </ItemLabel>
        </StyledToggleButton>
        <ToggleSearchConditionEditor
          onClick={this.toggleSearchConditionEditor}
          isOpen={this.state.isOpenSearchConditionEditor}
        />
        <ClearSearchCondition onClick={this.clearSearchCondition} />
        <SearchConditionEditorPopup
          open={this.state.isOpenSearchConditionEditor}
          anchorEl={this.rootRef?.current}
          onClose={this.onFinishedEditSearchCondition}
          wbsItemSearchCondition={wbsItemSearchCondition}
          searchFilter={this.state.editedSearchFilter}
          onChange={this.onChangeSearchConditionValue}
        />
      </RootDiv>
    )
  }
}

export default FilteringItemLabel
