import _ from 'lodash'
import { GridApi, RangeSelectionChangedEvent } from 'ag-grid-community'

export const onRangeSelectionChanged = ({
  api,
  started,
  finished,
}: RangeSelectionChangedEvent) => {
  const focusedCell = api.getFocusedCell()
  if (
    !focusedCell ||
    !focusedCell.column ||
    focusedCell.column.getColDef().field === 'drag'
  ) {
    return
  }
  if (started && !finished) {
    api.deselectAll()
  }
  if (finished) {
    selectRows(api)
  }
}

const selectRows = (api: GridApi) => {
  const ranges = api.getCellRanges() || []
  const rangeIndexes = ranges.map(range => {
    if (!range.startRow || !range.endRow) {
      return []
    }
    return range.startRow.rowIndex <= range.endRow.rowIndex
      ? _.range(range.startRow.rowIndex, range.endRow.rowIndex + 1)
      : _.range(range.endRow.rowIndex, range.startRow.rowIndex + 1)
  })
  const rowIndexes: number[] = rangeIndexes.flat().filter(v => v !== undefined)
  const selectedNodes = api.getSelectedNodes()
  selectedNodes.forEach(
    node =>
      node.rowIndex !== null &&
      !rowIndexes.includes(node.rowIndex) &&
      node.setSelected(false)
  )
  rowIndexes.forEach(i => {
    const node = api.getDisplayedRowAtIndex(i)
    node && node.setSelected(true)
  })
}
