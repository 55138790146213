import {
  riskService,
  UpdateRiskDeltaRequest,
} from './../../domain/entity/ticket/RiskEntity'
import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { RiskRepository } from '../../applications/ports/riskRepository'

export const useRiskRepository = (): RiskRepository => {
  const fetch = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/risks/detail',
      { wbsItemUuid: uuid },
      true
    )
    return riskService.fromApiResponseToEntity(response.json)
  }, [])
  const updateDelta = useCallback(async (request: UpdateRiskDeltaRequest) => {
    const response = await api.functional.request(
      'PUT',
      '/api/v1/projects/risks/delta',
      request,
      true
    )
    return {}
  }, [])
  return { fetch, updateDelta }
}
