import { useCallback, useState } from 'react'

export const useDialog = () => {
  const [uiState, setUiState] = useState<boolean>(false)

  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  return {
    uiState,
    openUiState,
    closeUiState,
  }
}
