import React from 'react'
import { styled } from '@mui/system'
import ExclusiveToggleGroup from './ExclusiveToggleGroup'
import { getTimeGrainLabel, TimeGrain } from '../../../../lib/functions/report'

const RootContainer = styled('div')({
  display: 'flex',
})

interface OwnProps {
  value: TimeGrain
  onChange: (newValue: string) => void
  label?: string
}

export const TimeGrainToggleGroup = (props: OwnProps) => {
  const { value, label, onChange } = props

  const timeGrainOptions = Object.values(TimeGrain).map(v => ({
    label: getTimeGrainLabel(v),
    value: v,
  }))
  return (
    <>
      {label && <span>{label}</span>}
      <RootContainer>
        <ExclusiveToggleGroup
          value={value}
          options={timeGrainOptions}
          onChange={onChange}
        />
      </RootContainer>
    </>
  )
}
