import { useState, useCallback, useMemo } from 'react'
import {
  SidebarPanelRoot,
  SidebarPanelHeader,
  SidebarPanelTitle,
  SidebarPanelHeaderIconButton,
  SidebarHeaderArrowIcon,
  SidebarPanelMainArea,
  SimulationConfigPanel,
  SimulatedSimulationFromDateConfigPanel,
  SimulationDateConfigPanel,
  formatDate,
} from '.'
import { BurndownChartSimulationVariables } from '../../model'
import { UpdateIdealSimulationVariables } from '../../hooks/simulationVariables'
import { intl } from '../../../../../i18n'

type IdealGraphSimulationPanelProps = Pick<
  BurndownChartSimulationVariables,
  'idealSimulationFromDate' | 'idealSimulationToDate'
> &
  UpdateIdealSimulationVariables
export const IdealGraphSimulationPanel = ({
  idealSimulationFromDate,
  idealSimulationToDate,
  updateIdealSimulationFromDate,
  updateIdealSimulationToDate,
  resetIdealSimulationFromDate,
  resetIdealSimulationToDate,
}: IdealGraphSimulationPanelProps) => {
  const [fromDateLabel, toDateLabel] = useMemo(
    () => [
      intl.formatMessage({
        id: 'progressReport.simulation.simulationFromDate',
      }),
      intl.formatMessage({
        id: 'progressReport.simulation.simulationToDate',
      }),
    ],
    []
  )
  const format = useCallback((v: Date | undefined) => {
    if (!v) return ''
    return formatDate(v)
  }, [])
  const [open, setOpen] = useState<boolean>(true)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  return (
    <SidebarPanelRoot>
      <SidebarPanelHeader>
        <SidebarPanelTitle>理想線シミュレーション</SidebarPanelTitle>
        <SidebarPanelHeaderIconButton onClick={toggle}>
          <SidebarHeaderArrowIcon open={open} />
        </SidebarPanelHeaderIconButton>
      </SidebarPanelHeader>
      <SidebarPanelMainArea in={open}>
        <SimulationDateConfigPanel
          value={idealSimulationFromDate}
          update={updateIdealSimulationFromDate}
          onReset={resetIdealSimulationFromDate}
          label={fromDateLabel}
          format={format}
        />
        <SimulationDateConfigPanel
          value={idealSimulationToDate}
          update={updateIdealSimulationToDate}
          onReset={resetIdealSimulationToDate}
          label={toDateLabel}
          format={format}
        />
      </SidebarPanelMainArea>
    </SidebarPanelRoot>
  )
}
