import { DateV2, dateV2Service } from '../DateV2'

export type DateTermV2 = {
  startDate: DateV2 | undefined
  endDate: DateV2 | undefined
}

const isEqual = (
  a: DateTermV2 | undefined,
  b: DateTermV2 | undefined
): boolean => {
  if (!a && !b) return true
  if (!a || !b) return false
  return (
    dateV2Service.isEqual(a.startDate, b.startDate) &&
    dateV2Service.isEqual(a.endDate, b.endDate)
  )
}

export const dateTermV2Service = {
  isEqual,
}
