import { Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { TextColor } from '../../../../../styles/commonStyles'

type Props = {
  noticeType: string
}

const ManagementNoticeTitle = ({ noticeType }: Props) => {
  let title: string | undefined = undefined
  switch (noticeType) {
    case 'NEW_FEATURE':
      title = intl.formatMessage({ id: 'information.new.feature' })
      break
    case 'MAINTENANCE':
      title = intl.formatMessage({ id: 'information.maintenance' })
      break
    case 'BUG':
      title = intl.formatMessage({ id: 'information.bug' })
      break
  }
  return (
    <Typography
      sx={{
        color: TextColor.MONOTONE,
        fontSize: '14px',
        fontWeight: 700,
        padding: '15px',
        margin: '0',
      }}
    >
      {title}
    </Typography>
  )
}

export default ManagementNoticeTitle
