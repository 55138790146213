import DateVO from '../../../vo/DateVO'
import { DayOfWeek } from '../../../lib/functions/report'

export enum GanttDisplayUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export type DisplayRange = {
  start: DateVO
  end: DateVO
}

export type SerializedGanttParameter = {
  unit: GanttDisplayUnit
  startDay: DayOfWeek
  range: { start: number; end: number }
}

export class GanttParameterVO {
  public readonly unit: GanttDisplayUnit
  public readonly startDay: DayOfWeek
  public readonly range: DisplayRange

  constructor(unit, startDay, range) {
    this.unit = unit
    this.startDay = startDay
    this.range = range
  }

  public static defaultValue = (): GanttParameterVO => {
    return new GanttParameterVO(GanttDisplayUnit.MONTH, DayOfWeek.MONDAY, {
      start: new DateVO().subtractWeeks(10),
      end: new DateVO().addWeeks(10),
    })
  }

  public serialize = (): SerializedGanttParameter => {
    return {
      unit: this.unit,
      startDay: this.startDay,
      range: {
        start: this.range.start.toNumberValue(),
        end: this.range.end.toNumberValue(),
      },
    }
  }

  public static deserialize = (
    source: SerializedGanttParameter
  ): GanttParameterVO => {
    return new GanttParameterVO(source.unit, source.startDay, {
      start: new DateVO(source.range.start),
      end: new DateVO(source.range.end),
    } as DisplayRange)
  }

  public calcNearestPosition = (): 'start' | 'middle' | 'end' => {
    // Get position for ag-grid ensureColumnVisible api
    const today = DateVO.now()
    const todayFromStart = today.diff(this.range.start)
    if (todayFromStart < 0) return 'start' // Today is before start
    if (this.range.end.diff(today) < 0) return 'end' // Today is after end

    const oneThirds = Math.floor(this.range.end.diff(this.range.start) / 3)
    if (todayFromStart < oneThirds) return 'start'
    if (todayFromStart < oneThirds * 2) return 'middle'
    return 'end'
  }
}
