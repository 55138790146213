import {
  Popper,
  ClickAwayListener,
  styled,
  ListItemButton,
  IconButton,
  Divider,
} from '@mui/material'
import { useState, useCallback, useRef, useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { BurndownChartResourceType } from '../../../../../domain/value-object/BurndownChart'

type ResourceTypeSelectProps = {
  resourceType: BurndownChartResourceType
  updateResourceType: (_: BurndownChartResourceType) => void
}
export const ResourceTypeSelect = ({
  resourceType,
  updateResourceType,
}: ResourceTypeSelectProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLButtonElement | null>(null)
  return (
    <>
      <PopperIconButton onClick={toggle} ref={ref}>
        <SettingsIcon />
      </PopperIconButton>
      <Popper open={open} anchorEl={ref.current} placement="bottom-start">
        <ClickAwayListener onClickAway={onClose}>
          <PopperRoot>
            <PopperHeader>
              {intl.formatMessage({ id: 'progressReport.resourceType' })}
              <PopperIconButton onClick={onClose}>
                <CloseIcon />
              </PopperIconButton>
            </PopperHeader>
            <PopperDivider />
            <PopperMain>
              <PopperSelect
                onClick={() => {
                  updateResourceType('WORKING_DAY_CALENDAR')
                }}
                selected={resourceType === 'WORKING_DAY_CALENDAR'}
              >
                {intl.formatMessage({
                  id: 'progressReport.resourceType.workingDayCalendar',
                })}
              </PopperSelect>
              <PopperSelect
                onClick={() => {
                  updateResourceType('RESOURCE_PLAN')
                }}
                selected={resourceType === 'RESOURCE_PLAN'}
              >
                {intl.formatMessage({
                  id: 'progressReport.resourceType.resourcePlan',
                })}
              </PopperSelect>
              <PopperSelect
                onClick={() => {
                  updateResourceType('WORK_SCHEDULE')
                }}
                selected={resourceType === 'WORK_SCHEDULE'}
              >
                {intl.formatMessage({
                  id: 'progressReport.resourceType.workSchedule',
                })}
              </PopperSelect>
            </PopperMain>
          </PopperRoot>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const SettingsIcon = styled(SettingsRoundedIcon)({
  fontSize: '16px',
  color: colorPalette.monotone[4],
})

const CloseIcon = styled(CloseRoundedIcon)({
  fontSize: '16px',
  color: colorPalette.monotone[4],
})

const PopperRoot = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '4px',
  padding: '6px 8px',
})

const PopperHeader = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[4],
  fontWeight: 400,
  display: 'flex',
  justifyContent: 'space-between',
})

const PopperMain = styled('div')({
  width: '220px',
  gap: '4px',
})

const PopperSelect = styled(ListItemButton)({
  '&.Mui-selected': { backgroundColor: colorPalette.skyBlue[1] },
  fontSize: '14px',
  fontWeight: 400,
  color: colorPalette.monotone[4],
  lineHeight: '14px',
  textAlign: 'left',
  padding: '8px',
})

const PopperIconButton = styled(IconButton)({
  padding: '0',
})

const PopperDivider = styled(Divider)({
  margin: '4px 2px',
  color: colorPalette.monotone[2],
  height: '2px',
})
