import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Directory } from '../../../../../lib/commons/appFunction'

export interface Props extends RouteComponentProps {
  directory: Directory
}

export interface State {
  isOpened: boolean
}

abstract class SideMenuCategoryBase<
  P extends Props,
  S extends State
> extends React.Component<P, S> {
  linkActiveStyle = {
    backgroundColor: '#ddd',
  }

  constructor(props: P) {
    super(props)
    this.state = {
      isOpened: props.directory.defaultOpen,
    } as S

    this.handleToggleExpand = this.handleToggleExpand.bind(this)
  }

  handleToggleExpand() {
    this.setState({ isOpened: !this.state.isOpened })
  }
}

export default SideMenuCategoryBase
