import {
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { formatDate, formatDateTime } from '../../../utils/date'
import projectMemberApi, {
  GetProjectMembersProps,
  ProjectMemberBatchDeltaInput,
  ProjectMemberDeltaInput,
  ProjectMemberDetail,
  ProjectMemberInput,
  ProjectMemberRole,
  ProjectMemberStatus,
} from '../../../lib/functions/projectMember'
import {
  ColDef,
  GetContextMenuItemsParams,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { UserDetail } from '../../../lib/functions/user'
import { TeamProps } from '../../../lib/functions/team'
import { ColumnType } from '../../containers/commons/AgGrid'
import { FunctionProperty } from '../../../lib/commons/appFunction'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuItemId,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import DateVO from '../../../vo/DateVO'
import { KeyBindListener } from '../../model/keyBind'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface ProjectMemberState extends BulkSheetState {}

export class ProjectMemberRow extends RowData {
  user?: UserDetail
  team?: TeamProps
  projectMemberRole?: ProjectMemberRole
  projectAssignedDate?: string
  projectReleasedDate?: string
  revision?: string
  status?: ProjectMemberStatus
}

interface ProjectMemberBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    ProjectMemberDetail,
    ProjectMemberRow,
    ProjectMemberState
  > {}

const dateValueRenderer = (value: string | undefined) => {
  return value ? formatDate(value) : ''
}

class ProjectMemberRowDataSpec extends RowDataSpec<
  ProjectMemberDetail,
  ProjectMemberRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      projectMemberDate: {
        valueFormatter: (params: ValueFormatterParams) => {
          return dateValueRenderer(params.value) || ''
        },
      },
    }
  }
  createNewRow(): ProjectMemberRow {
    const projectMemberRow = new ProjectMemberRow(generateUuid())
    projectMemberRow.status = ProjectMemberStatus.ASSIGNED
    projectMemberRow.projectAssignedDate = new DateVO().format()
    return projectMemberRow
  }
  overwriteRowItemsWithParents(params: {
    child: ProjectMemberRow
    parent: ProjectMemberRow
  }): ProjectMemberRow {
    return params.child
  }
  createRowByResponse(response: ProjectMemberDetail): ProjectMemberRow {
    const projectAssignedDate = new DateVO(
      response.projectAssignedDate
    ).format()
    const projectReleasedDate = response.projectReleasedDate
      ? new DateVO(response.projectReleasedDate).format()
      : undefined
    return {
      ...response,
      projectAssignedDate: projectAssignedDate,
      projectReleasedDate: projectReleasedDate,
      status: calcStatusByDates(projectAssignedDate, projectReleasedDate),
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
}

const calcStatusByDates = (
  projectAssignedDate: string,
  projectReleasedDate: string | undefined
) => {
  const today = DateVO.now()
  const assignDate = new DateVO(projectAssignedDate)
  let releaseDate = new DateVO(projectReleasedDate)
  if (projectReleasedDate === undefined || projectReleasedDate === '') {
    releaseDate = new DateVO('9999/12/31')
  }
  if (today.isBefore(assignDate)) {
    return ProjectMemberStatus.TBA
  }
  if (
    (today.isSame(assignDate) || today.isAfter(assignDate)) &&
    (today.isBefore(releaseDate) || today.isSame(releaseDate))
  ) {
    return ProjectMemberStatus.ASSIGNED
  }
  return ProjectMemberStatus.RELEASED
}

export default class ProjectMemberOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  ProjectMemberDetail,
  ProjectMemberRow,
  ProjectMemberState
> {
  addable = true
  draggable = true
  enableExcelExport = true
  displayNameField = 'user.name'
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ProjectMemberColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new ProjectMemberRowDataSpec()
  lockedColumns = ['project.member.user.code']
  pinnedColumns = [
    'project.member.user.code',
    'project.member.status',
    'project.member.user',
    'project.member.user.role',
  ]
  customColumnTypes = {
    'project.member.status': [ColumnType.wbsItemType],
    'project.member.projectAssignedDate': ['projectMemberDate'],
    'project.member.projectReleasedDate': ['projectMemberDate'],
  }

  getCellEditorParams = (
    field: string,
    prop: FunctionProperty,
    ctx: ProjectMemberBulkSheetContext
  ) => {
    if (field === 'user') {
      return {
        // Filter users who are assined.
        selectOptionsFilter: (options: any[]) => {
          ctx.gridApi!.forEachNode(node => {
            if (node.data && node.data.user) {
              const i = options.findIndex(v => v.uuid === node.data.user.uuid)
              i >= 0 && options.splice(i, 1)
            }
          })
          return options
        },
      }
    }
    return undefined
  }

  async getAll(state: ProjectMemberState): Promise<APIResponse> {
    const projectUuid: GetProjectMembersProps = { projectUuid: state.uuid }
    return projectMemberApi.getProjectMembers(projectUuid)
  }
  getRowStyle = params => {
    if (
      params.data &&
      params.data.projectReleasedDate &&
      new DateVO(params.data.projectReleasedDate).isBefore(
        DateVO.now().getStartOfDay()
      )
    ) {
      return { backgroundColor: '#e0e0e0' }
    }
  }
  onSubmit = async (
    ctx: ProjectMemberBulkSheetContext,
    data: {
      added: ProjectMemberRow[]
      edited: {
        before: ProjectMemberRow
        after: ProjectMemberRow
      }[]
      deleted: ProjectMemberRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const state = ctx.state
    this.cancelDeleteAndAddRequest(data)
    const input: ProjectMemberBatchDeltaInput = {
      added: data.added.map(row =>
        this.createRequestByRow(row, viewMeta, state)
      ),
      edited: data.edited.map(row =>
        this.createDeltaRequestByRow(row, viewMeta, state)
      ),
      deleted: data.deleted.map(row =>
        this.createDeleteRequestByRow(row, state)
      ),
    }
    return projectMemberApi.updateBatchDelta(input)
  }

  private cancelDeleteAndAddRequest = (data: {
    added: ProjectMemberRow[]
    edited: {
      before: ProjectMemberRow
      after: ProjectMemberRow
    }[]
    deleted: ProjectMemberRow[]
  }) => {
    const deletedAndAddedUser = data.added
      .map(row => row.user && row.user.uuid)
      .filter(
        userUuid =>
          userUuid &&
          data.deleted.some(row => (row.user && row.user.uuid) === userUuid)
      )
    deletedAndAddedUser.forEach(userUuid => {
      const added = data.added.find(
        row => row.user && row.user.uuid === userUuid
      )
      const deleted = data.deleted.find(
        row => row.user && row.user.uuid === userUuid
      )
      data.edited.push({
        before: {
          ...deleted!,
        },
        after: {
          ...added,
          uuid: deleted!.uuid,
          lockVersion: deleted!.lockVersion,
          revision: deleted!.revision,
          projectMemberRole: deleted!.projectMemberRole,
        },
      })
    })
    data.added = data.added.filter(
      row => !deletedAndAddedUser.includes(row.user && row.user.uuid)
    )
    data.deleted = data.deleted.filter(
      row => !deletedAndAddedUser.includes(row.user && row.user.uuid)
    )
  }

  private createRequestByRow = (
    row: ProjectMemberRow,
    viewMeta: ViewMeta,
    state: ProjectMemberState
  ): ProjectMemberInput => {
    return {
      projectUuid: state.uuid,
      userUuid: (row.user && row.user.uuid) || '',
      uuid: row.uuid,
      teamUuid: row.team && row.team.uuid,
      prevSiblingUuid: row.prevSiblingUuid!,
      projectMemberRoleUuid:
        row.projectMemberRole && row.projectMemberRole.uuid,
      projectAssignedDate: !!row.projectAssignedDate
        ? new DateVO(row.projectAssignedDate).formatForApi() || ''
        : '',
      projectReleasedDate: !!row.projectReleasedDate
        ? new DateVO(row.projectReleasedDate).formatForApi() || ''
        : '',
      lockVersion: row.lockVersion,
      revision: row.revision,
    }
  }

  private createDeltaRequestByRow = (
    {
      before,
      after,
    }: {
      before: ProjectMemberRow
      after: ProjectMemberRow
    },
    viewMeta: ViewMeta,
    state: ProjectMemberState
  ): ProjectMemberDeltaInput => {
    return {
      projectUuid: state.uuid,
      uuid: after.uuid,
      prevSiblingUuid:
        before.prevSiblingUuid !== after.prevSiblingUuid
          ? {
              oldValue: before.prevSiblingUuid,
              newValue: after.prevSiblingUuid,
            }
          : undefined,
      teamUuid:
        before.team?.uuid !== after.team?.uuid
          ? {
              oldValue: before.team?.uuid,
              newValue: after.team?.uuid,
            }
          : undefined,
      projectMemberRoleUuid:
        before.projectMemberRole?.uuid !== after.projectMemberRole?.uuid
          ? {
              oldValue: before.projectMemberRole?.uuid,
              newValue: after.projectMemberRole?.uuid,
            }
          : undefined,
      projectAssignedDate:
        before.projectAssignedDate !== after.projectAssignedDate
          ? {
              oldValue: before.projectAssignedDate
                ? new DateVO(before.projectAssignedDate).formatForApi() || ''
                : '',
              newValue: after.projectAssignedDate
                ? new DateVO(after.projectAssignedDate).formatForApi() || ''
                : '',
            }
          : undefined,
      projectReleasedDate:
        before.projectReleasedDate !== after.projectReleasedDate
          ? {
              oldValue: before.projectReleasedDate
                ? new DateVO(before.projectReleasedDate).formatForApi() || ''
                : '',
              newValue: after.projectReleasedDate
                ? new DateVO(after.projectReleasedDate).formatForApi() || ''
                : '',
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (
    row: ProjectMemberRow,
    state: ProjectMemberState
  ) => {
    return {
      projectUuid: state.uuid,
      userUuid: row.user! && row.user.uuid,
      lockVersion: row.lockVersion!,
    }
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (['projectAssignedDate', 'projectReleasedDate'].includes(field)) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        let projectAssignedDate = params.data.projectAssignedDate
        let projectReleasedDate = params.data.projectReleasedDate
        if (field === 'projectAssignedDate') {
          projectAssignedDate = params.newValue
          params.data.projectAssignedDate = params.newValue
        } else {
          projectReleasedDate = params.newValue
          params.data.projectReleasedDate = params.newValue
        }
        params.data.status = calcStatusByDates(
          projectAssignedDate,
          projectReleasedDate
        )
        return true
      }
    }
    if (field === 'status') {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        params.data.status = params.newValue
        this.setDatesByStatus(params.data)
        return true
      }
    }
    return undefined
  }

  setDatesByStatus = (row: ProjectMemberRow) => {
    const status = row.status
    if (status === ProjectMemberStatus.TBA) {
      row.projectAssignedDate = undefined
      row.projectReleasedDate = undefined
    }
    if (status === ProjectMemberStatus.ASSIGNED) {
      row.projectAssignedDate = new DateVO().format()
      row.projectReleasedDate = undefined
    }
    if (status === ProjectMemberStatus.RELEASED) {
      const releaseDate = new DateVO().addDays(-1).format()
      row.projectReleasedDate = releaseDate
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'user.code',
        'user.division.displayName',
        'user.position.displayName',
      ].includes(field)
    ) {
      return 120
    }
    return undefined
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: ProjectMemberBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params, [ContextMenuItemId.REMOVE_ROW]),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  isSetKeyBind = true
  getKeyBindListeners = (
    ctx: ProjectMemberBulkSheetContext
  ): KeyBindListener[] => {
    return [
      {
        key: 'alt+shift+l',
        fn: () => {
          if (
            !!ctx.gridApi?.getSelectedNodes() &&
            ctx.gridApi?.getSelectedNodes().length > 0
          ) {
            ctx.addRow(
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].data.uuid,
              ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent &&
                !ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.groupData
                ? ctx.gridApi?.getSelectedNodes().slice(-1)[0].parent?.id
                : undefined
            )
          }
        },
      },
      {
        key: 'alt+shift+d',
        fn: ctx.removeRowKeyEventListener,
      },
    ]
  }
}
