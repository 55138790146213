const AscIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58334 9.08361V2.56777L8.43 5.41444C8.6575 5.64194 9.03084 5.64194 9.25834 5.41444C9.48584 5.18694 9.48584 4.81944 9.25834 4.59194L5.41417 0.747773C5.18667 0.520273 4.81917 0.520273 4.59167 0.747773L0.74167 4.58611C0.51417 4.81361 0.51417 5.18111 0.74167 5.40861C0.96917 5.63611 1.33667 5.63611 1.56417 5.40861L4.41667 2.56777V9.08361C4.41667 9.40444 4.67917 9.66694 5 9.66694C5.32084 9.66694 5.58334 9.40444 5.58334 9.08361Z"
        fill="#EA005F"
      />
    </svg>
  )
}

export default AscIcon
