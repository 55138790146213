import { FC, useCallback, useState } from 'react'
import { ProjectDetail } from '../../../../lib/functions/project'
import { Box, styled } from '@mui/material'
import { DefaultTitle } from '../../../components/headers/HeaderBar/Title/DefaultTitle'
import { intl } from '../../../../i18n'
import {
  Header,
  HeaderContent,
} from '../../../components/headers/HeaderBar/PageHeader'
import { MasterScheduleParameter } from '../hooks/useMasterScheduleParameter'
import {
  ComponentDivider,
  ComponentHeader,
  ComponentHeaderEndDiv,
  ComponentTitle,
  ComponentWrapper,
  DashboardContent,
  DashboardRoot,
  DashboardTitle,
  ProjectReportBreadCrumbs,
} from '../components'
import { BreadCrumb } from '../../../../store/breadcrumb'
import {
  fetchProjectPlanBody,
  ProjectPlanBody,
} from '../../ProjectPlanNew/projectPlanNew'
import { ProgressDashboardParameter } from '../hooks/useProgressDashboardParameter'
import { TeamSelector } from '../components/TeamSelector'
import { TeamSelectOption, teamTotal } from '../model/team'
import { ProgressGroup } from './ProgressGroup'
import { ProjectReportConfig, UpdateProjectReportConfig } from '../model/config'

type Props = {
  project: ProjectDetail
} & ProjectReportConfig &
  Pick<UpdateProjectReportConfig, 'updateAggregateRoot'>

export const ProgressDashboard: FC<Props> = ({
  project,
  aggregateRoot,
  updateAggregateRoot,
  ...other
}: Props) => {
  const [teams, setTeams] = useState<TeamSelectOption[]>([teamTotal])

  const onClickBreadCrumb = useCallback(async (projectPlanUuid?: string) => {
    if (!projectPlanUuid) {
      updateAggregateRoot(undefined)
      return
    }
    const response = await fetchProjectPlanBody([projectPlanUuid])
    const body: ProjectPlanBody = response.json[0]
    if (body) {
      updateAggregateRoot({
        uuid: projectPlanUuid,
        wbsItemUuid: body.wbsItem.uuid,
        wbsItemName: body.wbsItem.displayName,
        scheduledDate: body.wbsItem.scheduledDate,
      })
    }
  }, [])
  return (
    <DashboardRoot>
      <Header sx={{ height: '36px', paddingLeft: '11px' }}>
        <HeaderContent>
          <DashboardTitle>
            {intl.formatMessage({ id: 'progressDashboard' })}
          </DashboardTitle>
          <ProjectReportBreadCrumbs
            project={project}
            rootUuid={aggregateRoot?.uuid}
            rootWbsItemName={aggregateRoot?.wbsItemName}
            onClick={(bc?: BreadCrumb) => {
              onClickBreadCrumb(bc?.uuid)
            }}
          />
        </HeaderContent>
      </Header>
      <DashboardContent>
        <ComponentWrapper>
          <ComponentHeader>
            <ComponentTitle>
              {intl.formatMessage({ id: 'progressDashboard' })}
            </ComponentTitle>
            <ComponentHeaderEndDiv>
              <TeamSelector
                projectUuid={project.uuid}
                updateSelection={setTeams}
                disableTeamUnselected={true}
              />
            </ComponentHeaderEndDiv>
          </ComponentHeader>
          <ProgressGroupRoot>
            {teams.map((team, i) => (
              <>
                {i > 0 && <ComponentDivider />}
                <ProgressGroup
                  project={project}
                  aggregateRoot={aggregateRoot}
                  {...other}
                  key={`progress-group-${i}`}
                  team={team}
                />
              </>
            ))}
          </ProgressGroupRoot>
        </ComponentWrapper>
      </DashboardContent>
    </DashboardRoot>
  )
}

const ProgressGroupRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})
