import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import { parse } from '../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../lib/functions/customEnumValue'
import projectMemberApi from '../../../../lib/functions/projectMember'
import teamApi from '../../../../lib/functions/team'
import sprintApi from '../../../../lib/functions/sprint'

export const useSelectColumnOptions = (projectUuid: string) => {
  const [selectColumnOptions, setSelectColumnOptions] = useState<
    | {
        [key: string]: CustomEnumValue[]
      }
    | undefined
  >(undefined)

  const fetchStatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchSubstatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchPriorityOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchDifficultyOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_DIFFICULTY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchTeamOptions = useCallback(async projectUuid => {
    const response = await teamApi.getTeams(projectUuid)
    return response.json
  }, [])

  const fetchMemberOptions = useCallback(async projectUuid => {
    const response = await projectMemberApi.getProjectMembers({ projectUuid })
    return response.json.map(v => v.user)
  }, [])

  const fetchSprintOptions = useCallback(async () => sprintApi.searchAll(), [])

  const fetchSelectColumnOptions = useCallback(
    async (projectUuid: string) => {
      const [status, substatus, priority, difficulty, team, member, sprint] =
        await Promise.all([
          fetchStatusOptions(projectUuid),
          fetchSubstatusOptions(projectUuid),
          fetchPriorityOptions(projectUuid),
          fetchDifficultyOptions(projectUuid),
          fetchTeamOptions(projectUuid),
          fetchMemberOptions(projectUuid),
          fetchSprintOptions(),
        ])
      setSelectColumnOptions({
        status,
        substatus,
        priority,
        difficulty,
        team,
        member,
        sprint,
      })
    },
    [
      fetchStatusOptions,
      fetchSubstatusOptions,
      fetchPriorityOptions,
      fetchDifficultyOptions,
      fetchTeamOptions,
      fetchMemberOptions,
      fetchSprintOptions,
    ]
  )

  useEffect(() => {
    if (_.isEmpty(projectUuid)) return
    fetchSelectColumnOptions(projectUuid)
  }, [projectUuid, fetchSelectColumnOptions])

  return selectColumnOptions
}
