import { CustomEnumValue } from '../../lib/commons/appFunction'
import VOBase from '../base'

export default class SelectVO extends VOBase {
  private readonly value: string
  private readonly name: string
  private readonly backgroundColor?: string
  private readonly foregroundColor?: string
  private readonly iconUrl?: string

  public getIconUrl(): string | undefined {
    return this.iconUrl!
  }

  constructor(
    _value: string,
    _name: string,
    _backgroundColor?: string,
    _foregroundColor?: string,
    _iconUrl?: string
  ) {
    super()
    this.value = _value
    this.name = _name
    this.backgroundColor = _backgroundColor
    this.foregroundColor = _foregroundColor
    this.iconUrl = _iconUrl
  }
  public static of = (
    _value: string,
    options: CustomEnumValue[]
  ): SelectVO | undefined => {
    if (!_value) {
      return undefined
    }
    const val = options.find(v => v.value === _value)
    if (!val) {
      return undefined
    }
    return new SelectVO(
      _value,
      val.name,
      val.foregroundColor,
      val.backgroundColor,
      val.iconUrl
    )
  }
  public static fromCustomEnum = (customEnum: CustomEnumValue): SelectVO => {
    return new SelectVO(
      customEnum.value,
      customEnum.name,
      customEnum.foregroundColor,
      customEnum.backgroundColor,
      customEnum.iconUrl
    )
  }
  public toCustomEnumValue = () => {
    return {
      value: this.value,
      name: this.name,
      foregroundColor: this.foregroundColor,
      backgroundColor: this.backgroundColor,
      iconUrl: this.iconUrl,
    }
  }
  getValue = () => {
    return this.value
  }
  format = () => {
    return this.name
  }
  serialize = () => this.value
}
