import _ from 'lodash'
import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community'
import { createNewTagRow, TagRow } from '../tag'
import { intl } from '../../../../i18n'
import { addRowsToLastChild } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import { focusRow } from '../../../containers/BulkSheetView/lib/gridApi'

export const addRowMenuItems = (
  params: GetContextMenuItemsParams,
  data: TagRow[],
  setData: (d: TagRow[], r: TagRow[]) => void,
  projectUuid: string
): MenuItemDef[] => {
  const row = params.node?.data
  if (!projectUuid) return []

  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.insert.row',
      }),
      icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
      action: () => {
        const target = Array.isArray(data) ? data[data.length - 1] : row
        const newRow = createNewTagRow(projectUuid)
        const newData = addRowsToLastChild(data, [newRow], target.uuid)
        const newRows = newData.filter(v => v.uuid === newRow.uuid)
        setData(newData, newRows)
        focusRow(params.api, newRow.uuid)
      },
    },
  ]
}

export const addRowsMenuItems = (
  params: GetContextMenuItemsParams,
  data: TagRow[],
  action: {
    onAddRows: () => void
  }
): MenuItemDef[] => {
  return [
    {
      name: intl.formatMessage({
        id: 'bulksheet.contextMenu.insert.multipleRow',
      }),
      icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
      action: action.onAddRows,
    },
  ]
}

export const deleteRowMenuItems = (
  selectedNodes: RowNode<TagRow>[],
  action: () => void
): MenuItemDef[] => {
  return [
    {
      name: intl.formatMessage(
        { id: 'bulksheet.contextMenu.delete' },
        { count: selectedNodes.length }
      ),
      icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
      action,
      shortcut: intl.formatMessage({
        id: 'bulksheet.contextMenu.shortcut.row.delete',
      }),
    },
  ]
}
