import { GridApi, RowNode } from 'ag-grid-community'
import { AUTO_COLUMN_ID } from '../../BulkSheet/const'

export const getSelectedNode = (gridApi: GridApi): RowNode[] => {
  const selectedRange = gridApi.getCellRanges() || []
  let nodes: RowNode[] = []
  selectedRange.forEach(range => {
    const startRowIndex =
      range.startRow && range.endRow
        ? Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
        : 0
    const endRowIndex =
      range.startRow && range.endRow
        ? Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
        : 0
    for (let i = startRowIndex; i <= endRowIndex; i++) {
      const displayedRow = gridApi.getDisplayedRowAtIndex(i)
      displayedRow && nodes.push(displayedRow)
    }
  })
  return nodes
}

export const getDisplayedRow = (api: GridApi) => {
  const firstRowIndex = api.getFirstDisplayedRow()
  const lastRowIndex = api.getLastDisplayedRow()
  return api
    .getRenderedNodes()
    .filter(
      node =>
        typeof node.rowIndex === 'number' &&
        node.rowIndex >= firstRowIndex &&
        node.rowIndex <= lastRowIndex
    )
}

export const getSelectedRowRangeSize = (api: GridApi): number => {
  const selectedRange = api.getCellRanges() || []
  return selectedRange
    .map(range =>
      range.startRow && range.endRow
        ? Math.abs(range.endRow.rowIndex - range.startRow.rowIndex) + 1
        : 0
    )
    .reduce((prev, curr) => prev + curr, 0)
}

export const focusRow = (api: GridApi, id: string) => {
  setTimeout(() => {
    const node = api.getRowNode(id)
    if (node?.rowIndex) {
      api.ensureNodeVisible(node)
      api.clearRangeSelection()
      api.setFocusedCell(node.rowIndex, AUTO_COLUMN_ID)
    }
  }, 300)
}

export const getRowNumber = (node?: RowNode | null): number => {
  let count = 0
  let target: RowNode | null | undefined = node
  while (target && 0 <= target.level) {
    const parent = target?.parent
    const siblings = parent?.childrenAfterGroup
    if (!target || !parent || !siblings || siblings.length === 0) return 0
    const i = siblings.findIndex(v => v.id === target?.id)
    const prevSiblings = siblings.slice(0, i)
    count += prevSiblings.reduce(
      (v, c) => v + (c.allLeafChildren?.length ?? 0),
      0
    )
    count++
    target = target.parent
  }
  return count
}

export const getAllLeafChildren = (nodes: RowNode[]): RowNode[] => {
  return nodes
    .map(n => {
      const treeValueStr = treeJoin(n)
      return n.allLeafChildren.filter(c => treeJoin(c).includes(treeValueStr))
    })
    .flat()
}
const treeJoin = (node: RowNode): string => node.data?.treeValue?.join('') ?? ''
