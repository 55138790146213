import BaseError from '../BaseError'

type SystemErrorCode = 'NOT_IMPLEMENTED'

interface SystemErrorParams {
  errorCode: SystemErrorCode
  message: string
}

export default class SystemError extends BaseError {
  constructor(params: SystemErrorParams) {
    super(params)
  }
}
