import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import {
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import store from '../../../../store'

type AggregateTargetToggleButtonGroupProps = {
  value: AggregateTarget
  onChange: (_: AggregateTarget) => void
}

export const AggregateTargetToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: AggregateTargetToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={WbsItemType.DELIVERABLE}>
        {
          <img
            src={store.getState().project.wbsItemTypes.deliverable.iconUrl}
            style={{ verticalAlign: 'middle', marginLeft: '-3px' }}
          />
        }
        {intl.formatMessage({
          id: 'wbsItemType.deliverable',
        })}
      </ToggleButton>
      <ToggleButton value={WbsItemType.TASK}>
        {
          <img
            src={store.getState().project.wbsItemTypes.task.iconUrl}
            style={{ verticalAlign: 'middle', marginLeft: '-3px' }}
          />
        }
        {intl.formatMessage({
          id: 'wbsItemType.task',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
