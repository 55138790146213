import { useEffect, useState } from 'react'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'
import { BurndownChartCalendar } from '../model'

export const useCalendar = (
  displayFrom: Date,
  displayTo: Date,
  simulationFrom: Date | undefined,
  simulationTo: Date | undefined
) => {
  const { getCalendar } = useBurndownChartRepository()
  const [calendar, setCalendar] = useState<BurndownChartCalendar>()
  useEffect(() => {
    const fn = async () => {
      const response = await getCalendar({
        from: displayFrom,
        to: displayTo,
        marginBefore: simulationFrom,
        marginAfter: simulationTo,
      })
      setCalendar({ ...response })
    }
    fn()
  }, [displayFrom, displayTo, getCalendar, simulationFrom, simulationTo])
  return calendar
}
