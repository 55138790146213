import DataConverter from '.'
import ViewMeta, { SubmitType } from '../ViewMeta'

export default class SingleSheetDataConverter {
  private converter = new DataConverter()
  private spec: SingleSheetConverterSpec

  constructor(_spec: SingleSheetConverterSpec) {
    this.spec = _spec
  }

  deserialize(src: any, viewMeta: ViewMeta): any {
    return this.spec.convertDeserialized(src, {
      uuid: src.uuid,
      lockVersion: src.lockVersion,
      ...this.converter.deserialize(src, viewMeta),
    })
  }
  serialize({
    src,
    viewMeta,
    initialData,
    submitType,
  }: {
    src: any
    viewMeta: ViewMeta
    initialData?: any
    submitType: SubmitType
    auxiliaries?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return this.spec.convertSerialized({
      src,
      dest: {
        uuid: src.uuid,
        lockVersion: src.lockVersion,
        ...this.converter.serialize(src, viewMeta),
      },
      initialData: initialData
        ? {
            uuid: initialData.uuid,
            lockVersion: initialData.lockVersion,
            ...this.converter.serialize(initialData, viewMeta),
          }
        : undefined,
      delta: initialData
        ? this.converter.getDelta({ src, viewMeta, initialData })
        : undefined,
      submitType,
    })
  }
  structInitialData(viewMeta: ViewMeta, auxiliaries?: any) {
    return this.spec.convertInitialData(
      this.converter.structInitialData(viewMeta),
      auxiliaries
    )
  }
}

export class SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any): any {
    return dest
  }
  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    submitType: SubmitType
    delta?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return {
      before: initialData,
      after: dest,
      delta,
    }
  }
  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}
