import { useCallback, useState } from 'react'
import { AttemptChangeUserEmailDialog } from '../../components/dialogs/AttemptChangeUserEmailDialog'
import BaseButton from '../../components/buttons/BaseButton'
import { intl } from '../../../i18n'
import { styled } from '@mui/material'
import { BackgroundColor, TextColor } from '../../../styles/commonStyles'

type AttemptChangeUserEmailButtonProps = {
  uuid: string
}
export const AttemptChangeUserEmailButton = ({
  uuid,
}: AttemptChangeUserEmailButtonProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const onOpen = useCallback(() => setOpen(true), [])
  const onClose = useCallback(() => setOpen(false), [])
  return (
    <>
      <StyledButton variant="outlined" onClick={onOpen}>
        {intl.formatMessage({ id: 'user.attemptChangeEmail.button' })}
      </StyledButton>
      <AttemptChangeUserEmailDialog open={open} uuid={uuid} onClose={onClose} />
    </>
  )
}

const StyledButton = styled(BaseButton)({
  background: BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
  color: TextColor.BLUE,
  width: '100%',
  padding: '0 10px',
  border: '1px solid transparent',
})
