import { styled } from '@mui/material'
import { PageArea } from '..'
import { useProjectPrivateContext } from '../../context/projectContext'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { VersionReportHeader } from './components/header'
import { VersionReportPage } from './components/pages/facade'
import { useCurrentVersion } from './hooks/currentReport'
import { useEditingState } from './hooks/editingState'
import { useEvaluate } from './hooks/evaluate'

type ProjectVersionReportProps = {}
export const ProjectVersionReport = projectPrivate(
  ({}: ProjectVersionReportProps) => {
    const { projectUuid } = useProjectPrivateContext()
    const { currentPage, currentVersion, updatePageContent, ...others } =
      useCurrentVersion()
    const { editing, ...editingHandlers } = useEditingState(
      currentVersion,
      currentPage
    )
    const { evaluate } = useEvaluate(
      currentVersion,
      currentPage,
      updatePageContent
    )
    return (
      <PageArea>
        <Root>
          <VersionReportHeader
            projectUuid={projectUuid}
            currentPage={currentPage}
            currentVersion={currentVersion}
            {...others}
            editing={editing}
            {...editingHandlers}
            evaluate={evaluate}
            updatePageContent={updatePageContent}
          />
          <VersionReportPage
            snapshotUuid={currentVersion?.snapshotUuid}
            page={currentPage}
            editing={editing}
            updatePageContent={updatePageContent}
            {...others}
          />
        </Root>
      </PageArea>
    )
  }
)
const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '16px',
  height: '100%',
})
