import { useCallback, useMemo } from 'react'
import { RefinementFormModel } from '../../../model/refinement'

export const useRefinementModelModifier = (
  onChange: <
    K extends keyof RefinementFormModel,
    T extends RefinementFormModel[K]
  >(
    path: K,
    value: T
  ) => void
) => {
  const onChangeKnownRefinement = useCallback(
    (value: RefinementFormModel['knownRefinement']) => {
      onChange('knownRefinement', value)
    },
    [onChange]
  )
  const onChangeOriginalRefinement = useCallback(
    (value: RefinementFormModel['originalRefinement']) => {
      onChange('originalRefinement', value)
    },
    [onChange]
  )
  const onChangeDetectionPhase = useCallback(
    (value: RefinementFormModel['detectionPhase']) => {
      onChange('detectionPhase', value)
    },
    [onChange]
  )
  const onChangeEnvironment = useCallback(
    (value: RefinementFormModel['environment']) => {
      onChange('environment', value)
    },
    [onChange]
  )
  const onChangeDomain = useCallback(
    (value: RefinementFormModel['domain']) => {
      onChange('domain', value)
    },
    [onChange]
  )
  const onChangeFeatureType = useCallback(
    (value: RefinementFormModel['featureType']) => {
      onChange('featureType', value)
    },
    [onChange]
  )
  const onChangeFeature = useCallback(
    (value: RefinementFormModel['feature']) => {
      onChange('feature', value)
    },
    [onChange]
  )
  const onChangeDefectEvent = useCallback(
    (value: RefinementFormModel['defectEvent']) => {
      onChange('defectEvent', value)
    },
    [onChange]
  )
  const onChangeOccurrenceProcedure = useCallback(
    (value: RefinementFormModel['occurrenceProcedure']) => {
      onChange('occurrenceProcedure', value)
    },
    [onChange]
  )
  const onChangeTestSpecification = useCallback(
    (value: RefinementFormModel['testSpecification']) => {
      onChange('testSpecification', value)
    },
    [onChange]
  )
  const onChangeTestCase = useCallback(
    (value: RefinementFormModel['testCase']) => {
      onChange('testCase', value)
    },
    [onChange]
  )
  const onChangeImportance = useCallback(
    (value: RefinementFormModel['importance']) => {
      onChange('importance', value)
    },
    [onChange]
  )
  const onChangeImpact = useCallback(
    (value: RefinementFormModel['impact']) => {
      onChange('impact', value)
    },
    [onChange]
  )
  const onChangeDesiredDeliveryDate = useCallback(
    (value: RefinementFormModel['desiredDeliveryDate']) => {
      onChange('desiredDeliveryDate', value)
    },
    [onChange]
  )
  const onChangeCause = useCallback(
    (value: RefinementFormModel['cause']) => {
      onChange('cause', value)
    },
    [onChange]
  )
  const onChangeDirectCause = useCallback(
    (value: RefinementFormModel['directCause']) => {
      onChange('directCause', value)
    },
    [onChange]
  )
  const onChangeRootCause = useCallback(
    (value: RefinementFormModel['rootCause']) => {
      onChange('rootCause', value)
    },
    [onChange]
  )
  const onChangeProperDetectionPhase = useCallback(
    (value: RefinementFormModel['properDetectionPhase']) => {
      onChange('properDetectionPhase', value)
    },
    [onChange]
  )
  const onChangeInterimAction = useCallback(
    (value: RefinementFormModel['interimAction']) => {
      onChange('interimAction', value)
    },
    [onChange]
  )
  const onChangePermanentAction = useCallback(
    (value: RefinementFormModel['permanentAction']) => {
      onChange('permanentAction', value)
    },
    [onChange]
  )
  const onChangeDocumentsToBeCorrected = useCallback(
    (value: RefinementFormModel['documentsToBeCorrected']) => {
      onChange('documentsToBeCorrected', value)
    },
    [onChange]
  )
  const onChangeHorizontalDeploymentTarget = useCallback(
    (value: RefinementFormModel['horizontalDeploymentTarget']) => {
      onChange('horizontalDeploymentTarget', value)
    },
    [onChange]
  )
  const onChangeHorizontalDeploymentContent = useCallback(
    (value: RefinementFormModel['horizontalDeploymentContent']) => {
      onChange('horizontalDeploymentContent', value)
    },
    [onChange]
  )
  const modifiers = useMemo(
    () => ({
      onChangeKnownRefinement,
      onChangeOriginalRefinement,
      onChangeDetectionPhase,
      onChangeEnvironment,
      onChangeDomain,
      onChangeFeatureType,
      onChangeFeature,
      onChangeDefectEvent,
      onChangeOccurrenceProcedure,
      onChangeTestSpecification,
      onChangeTestCase,
      onChangeImportance,
      onChangeImpact,
      onChangeDesiredDeliveryDate,
      onChangeCause,
      onChangeDirectCause,
      onChangeRootCause,
      onChangeProperDetectionPhase,
      onChangeInterimAction,
      onChangePermanentAction,
      onChangeDocumentsToBeCorrected,
      onChangeHorizontalDeploymentTarget,
      onChangeHorizontalDeploymentContent,
    }),
    [
      onChangeKnownRefinement,
      onChangeOriginalRefinement,
      onChangeDetectionPhase,
      onChangeEnvironment,
      onChangeDomain,
      onChangeFeatureType,
      onChangeFeature,
      onChangeDefectEvent,
      onChangeOccurrenceProcedure,
      onChangeTestSpecification,
      onChangeTestCase,
      onChangeImportance,
      onChangeImpact,
      onChangeDesiredDeliveryDate,
      onChangeCause,
      onChangeDirectCause,
      onChangeRootCause,
      onChangeProperDetectionPhase,
      onChangeInterimAction,
      onChangePermanentAction,
      onChangeDocumentsToBeCorrected,
      onChangeHorizontalDeploymentTarget,
      onChangeHorizontalDeploymentContent,
    ]
  )
  return modifiers
}
