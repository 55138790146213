import { ReferencedTicketListEntity } from './../../../../../domain/entity/TicketListEntity/index'
import { useCallback, useState } from 'react'
import { useTicketListRepository } from '../../../../../services/adaptors/ticketListRepositoryAdaptor'

export const useTicketListSearch = (projectUuid: string) => {
  const { fetchListAsReferencedEntity } = useTicketListRepository()
  const [candidates, setCandidates] = useState<ReferencedTicketListEntity[]>([])
  const searchTicketList = useCallback(
    async (searchText: string) => {
      if (candidates.length === 0) {
        const response = await fetchListAsReferencedEntity(projectUuid)
        setCandidates(response)
        return response.filter(res => res.name.includes(searchText))
      }
      return candidates.filter(entity => entity.name.includes(searchText))
    },
    [candidates, fetchListAsReferencedEntity, projectUuid]
  )
  return {
    searchTicketList,
  }
}
