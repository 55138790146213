import { styled } from '@mui/system'
import {
  BlockquoteButton,
  BoldButton,
  CodeBlockButton,
  CodeButton,
  FileButton,
  HeaderFiveButton,
  HeaderFourButton,
  HeaderOneButton,
  HeaderSixButton,
  HeaderThreeButton,
  HeaderTwoButton,
  HorizontalRuleButton,
  ItalicButton,
  OrderedListItemButton,
  StrikethroughButton,
  TableButton,
  TaskListButton,
  UnorderedListItemButton,
} from './StyleButton'
import { Divider } from '@mui/material'

const RootContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const StyledDivider = styled(Divider)({
  margin: 3,
})
const StyledBlockquoteButton = styled(BlockquoteButton)({ margin: 5 })
const StyledBoldButton = styled(BoldButton)({ margin: 5 })
const StyledCodeBlockButton = styled(CodeBlockButton)({ margin: 5 })
const StyledCodeButton = styled(CodeButton)({ margin: 5 })
const StyledFileButton = styled(FileButton)({ margin: 5 })
const StyledHeaderFiveButton = styled(HeaderFiveButton)({ margin: 5 })
const StyledHeaderFourButton = styled(HeaderFourButton)({ margin: 5 })
const StyledHeaderOneButton = styled(HeaderOneButton)({ margin: 5 })
const StyledHeaderSixButton = styled(HeaderSixButton)({ margin: 5 })
const StyledHeaderThreeButton = styled(HeaderThreeButton)({ margin: 5 })
const StyledHeaderTwoButton = styled(HeaderTwoButton)({ margin: 5 })
const StyledHorizontalRuleButton = styled(HorizontalRuleButton)({ margin: 5 })
const StyledItalicButton = styled(ItalicButton)({ margin: 5 })
const StyledOrderedListItemButton = styled(OrderedListItemButton)({ margin: 5 })
const StyledStrikethroughButton = styled(StrikethroughButton)({ margin: 5 })
const StyledTableButton = styled(TableButton)({ margin: 5 })
const StyledTaskListButton = styled(TaskListButton)({ margin: 5 })
const StyledUnorderedListItemButton = styled(UnorderedListItemButton)({
  margin: 5,
})

const StyleButtonComponents = (props: {
  disabled?: boolean
  hideHeaderOne?: boolean
  hideHeaderTwo?: boolean
  hideHeaderThree?: boolean
  hideHeaderFour?: boolean
  hideHeaderFive?: boolean
  hideHeaderSix?: boolean
  highlightItems?: string[]
  markdownText?: string
  setMarkdownText?: (text: string) => void
  selectionStart?: number
  selectionEnd?: number
}) => {
  const {
    disabled,
    hideHeaderOne,
    hideHeaderTwo,
    hideHeaderThree,
    hideHeaderFour,
    hideHeaderFive,
    hideHeaderSix,
    highlightItems,
    markdownText,
    setMarkdownText,
    selectionStart = -1,
    selectionEnd = -1,
  } = props
  return (
    <RootContainer>
      {!hideHeaderOne && (
        <StyledHeaderOneButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      {!hideHeaderTwo && (
        <StyledHeaderTwoButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      {!hideHeaderThree && (
        <StyledHeaderThreeButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      {!hideHeaderFour && (
        <StyledHeaderFourButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      {!hideHeaderFive && (
        <StyledHeaderFiveButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      {!hideHeaderSix && (
        <StyledHeaderSixButton
          disabled={disabled}
          highlightItems={highlightItems}
          markdownText={markdownText}
          setMarkdownText={setMarkdownText}
          selectionStart={selectionStart}
          selectionEnd={selectionEnd}
        />
      )}
      <StyledDivider orientation="vertical" variant="middle" flexItem={true} />
      <StyledBoldButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledItalicButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledStrikethroughButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledDivider orientation="vertical" variant="middle" flexItem={true} />
      <StyledCodeButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledCodeBlockButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledDivider orientation="vertical" variant="middle" flexItem={true} />
      <StyledBlockquoteButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledUnorderedListItemButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledOrderedListItemButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledDivider orientation="vertical" variant="middle" flexItem={true} />
      <StyledHorizontalRuleButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledTaskListButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledTableButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledFileButton
        disabled={disabled}
        highlightItems={highlightItems}
        markdownText={markdownText}
        setMarkdownText={setMarkdownText}
        selectionStart={selectionStart}
        selectionEnd={selectionEnd}
      />
      <StyledDivider orientation="vertical" variant="middle" flexItem={true} />
    </RootContainer>
  )
}

const MarkdownStyleButtons = StyleButtonComponents

export { MarkdownStyleButtons }
