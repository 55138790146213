import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import Auth from '../commons/auth'

export enum ExternalServiceCode {
  COGNITO = 'COGNITO',
  GOOGLE = 'GOOGLE',
  AAD = 'AAD',
}
export interface GetIdProvidersProps {}

export interface IdProviderDetail extends Tree<IdProviderDetail> {
  code?: string
  name: string
  externalServiceCode: ExternalServiceCode
  clientId: string
  clientSecret?: string
  issuer: string
}

export interface IdProviderInput {
  uuid: string
  lockVersion?: number
  code?: string
  name: string
  externalServiceCode: ExternalServiceCode
  clientId: string
  clientSecret?: string
  issuer: string
  secretEdited?: boolean
}

export interface IdProviderBatchInput {
  added: IdProviderInput[]
  edited: IdProviderInput[]
  deleted: { uuid: string; lockVersion: number }[]
}

class IdProvider {
  public getAll = async () => {
    return API.functional.request(
      'GET',
      '/api/v1/id_providers',
      undefined,
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }

  updateBatch(request: IdProviderBatchInput): Promise<APIResponse> {
    return API.functional.request('POST', '/api/v1/id_providers/batch', request)
  }
}

export default new IdProvider()
