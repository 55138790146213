export type UiState<T> = {
  key: string
  scope: UiStateScope
  value: T
}

const uiStateScopeArray = ['CROSS_PROJECT', 'TENANT', 'USER'] as const
export type UiStateScope = (typeof uiStateScopeArray)[number]

export const toUiStateScope = (src: string): UiStateScope | undefined => {
  return uiStateScopeArray.find(v => v === src)
}

export type PermanentUiState<T> = {
  uuid: string
  code: string
  name: string
  scope: UiStateScope
  value: T
  updatedAt: string
  updatedBy: string
}

export const getKey = (
  key: string,
  scope: UiStateScope,
  projectUuid: string
) => {
  return scope === 'CROSS_PROJECT' ? key : `${key}-${projectUuid}`
}
