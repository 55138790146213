import { FontSize } from '../../../styles/commonStyles'
import {
  IconButton,
  List,
  ListItem,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material'
import { ColDef } from 'ag-grid-community'
import CloseIcon from '@mui/icons-material/Close'

export interface Props {
  anchorEl: any
  columns?: (ColDef | string)[]
  placement?: PopperPlacementType
  onDelete?: (column: string) => void
}

const ColumnListPopper = (props: Props) => {
  return (
    <Popper
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      placement={props.placement || 'right-start'}
      disablePortal={true}
      style={{ zIndex: 2000 }}
    >
      <Paper>
        <List
          component="nav"
          style={{
            position: 'relative',
            overflow: 'auto',
            padding: 0,
          }}
        >
          {props.columns?.map((column: ColDef | string, index) => {
            return (
              <ListItem
                key={`filteredColumn${index}`}
                style={{
                  fontSize: FontSize.SMALL,
                  height: 25,
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                onClick={e => e.stopPropagation()}
              >
                {typeof column === 'string' ? column : column.headerName}
                {props.onDelete && (
                  <IconButton
                    style={{ padding: '3px' }}
                    onClick={() =>
                      props.onDelete &&
                      props.onDelete(
                        typeof column === 'string'
                          ? column
                          : column.colId || column.field || ''
                      )
                    }
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </ListItem>
            )
          })}
        </List>
      </Paper>
    </Popper>
  )
}

export default ColumnListPopper
