import { useMemo } from 'react'
import * as d3 from 'd3'
import {
  DateBucket,
  DateBucketType,
  DateTickIntervalType,
  isGreater,
} from '../../../components/charts/model/timeSeries'
import { calculateIntervalType } from '../../../components/charts/hooks/useXTicks'
import { DateTerm } from '../model'
import { WeekDay } from '../../../../domain/value-object/DayOfWeek'

export const useBurndownChartXTicks = (
  xMin: Date,
  xMax: Date,
  dateBucketType: DateBucketType,
  startDayOfWeek: WeekDay,
  width: number,
  minTickSize: number
): {
  xTicks: Date[]
  tickIntervalType: DateTickIntervalType
} => {
  const maxTickIntervalCount = useMemo(
    () => Math.floor(width / minTickSize),
    [minTickSize, width]
  )
  const { type, interval, offset } = useMemo(() => {
    const intervalType = calculateIntervalType(xMin, xMax, maxTickIntervalCount)
    const type = isGreater(dateBucketType, intervalType)
      ? dateBucketType
      : intervalType
    const interval = getInterval(type, startDayOfWeek)
    const offset = getOffset(type)
    return { type, interval, offset }
  }, [dateBucketType, xMin, maxTickIntervalCount, startDayOfWeek, xMax])
  const xTicks = useMemo(
    () => interval.range(xMin, interval.offset(xMax, offset)),
    [xMin, interval, offset, xMax]
  )
  return {
    xTicks,
    tickIntervalType: type,
  }
}

const getInterval = (type: DateTickIntervalType, startDayOfWeek: WeekDay) => {
  switch (type) {
    case 'DAY':
      return d3.timeDay
    case 'WEEK':
      switch (startDayOfWeek) {
        case 'MONDAY':
          return d3.timeMonday
        case 'TUESDAY':
          return d3.timeTuesday
        case 'WEDNESDAY':
          return d3.timeWednesday
        case 'THURSDAY':
          return d3.timeThursday
        case 'FRIDAY':
          return d3.timeFriday
      }
      return d3.timeMonday
    case 'MONTH':
    case 'THREE_MONTH':
    case 'HALF_YEAR':
      return d3.timeMonth
    case 'YEAR':
      return d3.timeYear
  }
}

const getOffset = (type: DateTickIntervalType) => {
  switch (type) {
    case 'DAY':
    case 'WEEK':
    case 'MONTH':
      return 1
    case 'THREE_MONTH':
      return 3
    case 'HALF_YEAR':
      return 6
    case 'YEAR':
      return 1
  }
}
