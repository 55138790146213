import { CircularProgress, styled } from '@mui/material'
import _ from 'lodash'
import { DateBound } from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { intl } from '../../../../i18n'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { ProjectDetail } from '../../../../lib/functions/project'
import { MasterScheduleParameter } from '../hooks/useMasterScheduleParameter'
import {
  getCumulation,
  ProjectPlanCumulation,
} from '../../../../lib/functions/projectPlan'
import { Progress } from '../../ProjectPlanNew/gridOptions/cumulation'
import { ProgressDashboardParameter } from '../hooks/useProgressDashboardParameter'
import {
  KpiContainer,
  KpiTitle,
  KpiUnit,
  KpiValue,
  KpiWithUnit,
  WidgetAlternative,
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../components'
import { ProjectReportConfig } from '../model/config'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'

type Props = {
  project: ProjectDetail
  teamUuid?: string
} & ProjectReportConfig

export const ProgressInformation = ({
  project,
  aggregateRoot,
  aggregateTarget,
  aggregateField,
  workloadUnit,
  aggregateBaseDate,
  teamUuid,
}: Props) => {
  const [cumulation, setCumulation] = useState<ProjectPlanCumulation>()
  const [progress, setProgress] = useState<Progress>()
  const [fetching, setFetching] = useState(true)
  const workloadUnitState = useWorkloadUnit(workloadUnit)

  const endFetching = _.debounce(() => {
    setFetching(false)
  }, 300)

  const fetch = useCallback(async () => {
    setFetching(true)
    const response = await getCumulation(
      project.uuid,
      aggregateRoot?.uuid,
      teamUuid
    )
    if (response.json.projectPlanUuid) {
      setCumulation(response.json)
    }
    endFetching()
  }, [project.uuid, aggregateRoot?.uuid, teamUuid])

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    if (!cumulation) return
    setProgress(
      Progress.of(cumulation, {
        aggregateTarget,
        aggregateField,
      })
    )
  }, [cumulation, aggregateTarget, aggregateField])

  const progressLabel = useCallback(
    (id: string) => intl.formatMessage({ id: `projectOverview.${id}` }),
    []
  )

  const formatWorkload = useCallback(
    (value: number): string => {
      if (aggregateField === AggregateField.WBS_ITEM_COUNT) {
        return `${value.toFixed(0)}`
      }
      return `${(value / workloadUnitState.hoursPerSelectedUnit).toFixed(0)}`
    },
    [aggregateField, workloadUnitState.hoursPerSelectedUnit]
  )

  const formatPercentage = useCallback((value: number | undefined): string => {
    if (!value) return '-'
    return `${(value * 100).toFixed(0)}%`
  }, [])

  const workloadUnitLabel = useMemo(() => {
    if (aggregateField === AggregateField.WBS_ITEM_COUNT) {
      return ''
    }
    switch (workloadUnit) {
      case WorkloadUnit.HOUR:
        return intl.formatMessage({ id: 'workloadUnit.hour' })
      case WorkloadUnit.DAY:
        return intl.formatMessage({ id: 'workloadUnit.day' })
      case WorkloadUnit.MONTH:
        return intl.formatMessage({ id: 'workloadUnit.month' })
    }
  }, [aggregateField, workloadUnit])

  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'progressDashboard.progressInformation',
        })}
      </WidgetTitle>
      <ProgressInformationWidgetArea>
        {fetching && (
          <WidgetAlternative>
            <CircularProgress />
          </WidgetAlternative>
        )}
        {!fetching && progress && (
          <>
            <ProgressValue
              label={progressLabel('scheduledProgressRate')}
              value={formatPercentage(
                aggregateBaseDate === DateBound.END
                  ? progress.scheduledProgressRate
                  : progress.scheduledStartRate
              )}
            />
            <ProgressValue
              label={progressLabel('progressRate')}
              value={formatPercentage(
                aggregateBaseDate === DateBound.END
                  ? progress.progressRate
                  : progress.startRate
              )}
            />
            <ProgressValue
              label={progressLabel('total')}
              value={formatWorkload(progress.total)}
              unit={workloadUnitLabel}
            />
            <ProgressValue
              label={progressLabel(
                aggregateBaseDate === DateBound.END
                  ? 'scheduledToBeCompleted'
                  : 'scheduledToBeCompleted'
              )}
              value={formatWorkload(
                aggregateBaseDate === DateBound.END
                  ? progress.scheduledToBeCompleted
                  : progress.scheduledToBeStarted
              )}
              unit={workloadUnitLabel}
            />
            <ProgressValue
              label={progressLabel(
                aggregateBaseDate === DateBound.END ? 'completed' : 'started'
              )}
              value={formatWorkload(
                aggregateBaseDate === DateBound.END
                  ? progress.completed
                  : progress.started
              )}
              unit={workloadUnitLabel}
            />
            <ProgressValue
              label={progressLabel(
                aggregateBaseDate === DateBound.END ? 'delayed' : 'startDelayed'
              )}
              value={formatWorkload(
                aggregateBaseDate === DateBound.END
                  ? progress.delayed
                  : progress.startDelayed
              )}
              unit={workloadUnitLabel}
            />
          </>
        )}
      </ProgressInformationWidgetArea>
    </WidgetWithTitleWrapper>
  )
}

const ProgressInformationWidgetArea = styled(WidgetArea)({
  width: '490px',
  height: '58px',
})

const ProgressValue = ({
  label,
  value,
  unit,
}: {
  label: string
  value: string
  unit?: string
}) => {
  return (
    <KpiContainer>
      <KpiTitle>{label}</KpiTitle>
      <KpiWithUnit>
        <KpiValue>{value}</KpiValue>
        <KpiUnit>{unit}</KpiUnit>
      </KpiWithUnit>
    </KpiContainer>
  )
}
