export default abstract class DomainValueObjectBase<T> {
  private value: T

  constructor(value: T) {
    this.value = value
  }

  toPlainValue(): T {
    return this.value
  }

  isEqual(other: DomainValueObjectBase<T>): boolean {
    if (other === undefined || other === null) {
      return false
    }
    return other.toPlainValue() === this.value
  }
}
