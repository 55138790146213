import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../../../store'
import { FunctionLayer } from '../../../../../store/functionLayer'
import { DefaultTitle } from './DefaultTitle'
import { Function } from '../../../../../lib/commons/appFunction'

interface OwnProps {
  depth: number
}

interface StateProps {
  functions: Function[]
  layer?: FunctionLayer
}

const Title = (props: OwnProps & StateProps) => {
  const { depth, layer, functions } = props
  const [title, setTitle] = useState<string | undefined>(undefined)
  useEffect(() => {
    const externalId = layer?.externalId
    if (!externalId) {
      setTitle(undefined)
      return
    }
    const selectedFunction = functions.find(f => f.externalId === externalId)
    if (!selectedFunction) {
      setTitle(undefined)
      return
    }
    setTitle(selectedFunction.name)
  }, [layer])
  useEffect(() => {
    if (depth === 0) {
      document.title = title || 'Flagxs'
    }
  }, [title, depth])
  if (!layer) {
    return <></>
  }
  return (
    <div id="header-bar-title">
      {layer.titleComponent || (title && <DefaultTitle title={title} />)}
    </div>
  )
}

const mapStateToProps = (state: AllState, props: OwnProps) => ({
  functions: state.appFunction.functions,
  layer: state.functionLayer.layers.get(props.depth),
})

export default connect(mapStateToProps)(Title)
