import { useCallback, useEffect, useMemo, useState } from 'react'
import { PageArea } from '..'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { colorPalette } from '../../style/colorPallete'
import { useAggregationDomain } from './hooks/aggregationDomain'
import { useDomainDateRange } from './hooks/domainDateRange'
import { Divider, DividerProps, styled } from '@mui/material'
import { useViewConfig } from './hooks/viewConfig'
import { BurndownChartHeader } from './components/header'
import { useValueFormatters } from './hooks/valueFormatters'
import { useSearchCondition } from './hooks/searchCondition'
import { BurndownChartTabPanel } from './components/tab-panel'
import { useProjectPrivateContext } from '../../context/projectContext'
import { useBasicInformation } from './hooks/basicInformation'
import { useHistory } from 'react-router'
import { BurndownChartViewConfigPanel } from './components/view-config'
import { useTeamSelectOptions } from './hooks/teamSelectOptions'

export const BurndownChart = projectPrivate(() => {
  // Initialize config.
  const { aggregateField, workloadUnit, ...others } = useViewConfig()
  const valueHandlers = useValueFormatters(aggregateField, workloadUnit)

  const { projectUuid, projectCode } = useProjectPrivateContext()
  const { domain } = useAggregationDomain()
  const { selected: selectedTeamUuid, ...teamOthers } = useTeamSelectOptions(
    projectUuid,
    domain
  )
  const history = useHistory()
  const onChangeDomain = useCallback(
    (rootProjectPlanUuid: string | undefined) => {
      const path = `/burndownChart/${projectCode}`
      if (!rootProjectPlanUuid) {
        history.push(path)
        return
      }
      history.push(`${path}?rootProjectPlanUuid=${rootProjectPlanUuid}`)
    },
    [history, projectCode]
  )
  const { basicInformation } = useBasicInformation(projectUuid, domain)

  const domainDateTerm = useDomainDateRange(basicInformation)

  const searchConditions = useSearchCondition()

  const scheduledDateTerm = useMemo(() => {
    if (!basicInformation) {
      return {
        from: undefined,
        to: undefined,
      }
    }
    return basicInformation.scheduledDate
  }, [basicInformation])
  const [isViewConfigOpen, setIsViewConfigOpen] = useState<boolean>(true)
  const toggle = useCallback(() => setIsViewConfigOpen(prev => !prev), [])

  return (
    <PageArea>
      <BurndownChartViewConfigPanel
        aggregateField={aggregateField}
        workloadUnit={workloadUnit}
        open={isViewConfigOpen}
        toggle={toggle}
        {...others}
        {...searchConditions}
      />
      <ReportWrapper isViewConfigOpen={isViewConfigOpen}>
        <HeaderArea>
          <BurndownChartHeader
            domain={domain}
            onChangeDomain={onChangeDomain}
            rootName={basicInformation?.rootName || ''}
            scheduledDate={scheduledDateTerm}
            selected={selectedTeamUuid}
            {...teamOthers}
          />
        </HeaderArea>
        {basicInformation && (
          <BurndownChartTabPanel
            scheduledDate={scheduledDateTerm}
            domainDateTerm={domainDateTerm}
            domain={domain}
            selectedTeamUuid={selectedTeamUuid}
            viewConfigs={others}
            searchConditions={searchConditions}
            valueHandlers={valueHandlers}
          />
        )}
      </ReportWrapper>
    </PageArea>
  )
})

const HEADER_HEIGHT = 150
const VIEW_CONFIG_HEIGHT = 78
const SUBHEADER_HEIGHT = 105
const SUMMARY_HEIGHT = 60
const SIDEBAR_WIDTH = 280

type ReportWrapperProps = {
  isViewConfigOpen: boolean
}
const ReportWrapper = styled('div')(
  ({ isViewConfigOpen }: ReportWrapperProps) => {
    const height = useMemo(() => {
      if (isViewConfigOpen) {
        return `calc(100% - ${32 + 16 + VIEW_CONFIG_HEIGHT}px)`
      }
      return 'calc(100% - 48px)'
    }, [isViewConfigOpen])
    return {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      margin: '16px',
      height,
      width: 'calc(100% - 32px)',
    }
  }
)
const HeaderArea = styled('div')({
  width: '100%',
  height: `${HEADER_HEIGHT}px`,
})
export const MainArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: `calc(100% - ${HEADER_HEIGHT}px)`,
  padding: '0 20px',
  gap: '20px',
})
export const SummaryArea = styled('div')({
  width: '100%',
  height: `${SUMMARY_HEIGHT}px`,
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  border: `1px solid ${colorPalette.monotone[2]}`,
})
export const ChartArea = styled('div')({
  width: `calc(100% - ${SIDEBAR_WIDTH + 20}px)`,
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  border: `1px solid ${colorPalette.monotone[2]}`,
  height: '100%',
})
export const GraphWithSidebarArea = styled('div')({
  display: 'flex',
  gap: '20px',
  height: `calc(100% - ${SUBHEADER_HEIGHT + 20}px)`,
})
export const TableWithSidebarArea = styled('div')({
  display: 'flex',
  gap: '20px',
  height: '100%',
})
export const SideBarArea = styled('div')({
  height: '100%',
  width: `${SIDEBAR_WIDTH}px`,
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  border: `1px solid ${colorPalette.monotone[2]}`,
})
export const ReportDivider = styled(Divider)(
  ({ orientation = 'horizontal' }: DividerProps) => ({
    margin: orientation === 'horizontal' ? '0 10px' : '10px 0',
    height: orientation === 'vertical' ? 'calc(100% - 20px)' : undefined,
    width: orientation === 'horizontal' ? 'calc(100% - 20px)' : undefined,
    color: colorPalette.monotone[2],
  })
)
