import { DateTimeV2 } from '../../../value-object/DateTimeV2'
import { VersionReportPageConfig } from '../VersionReportTemplateEntity'

export type VersionReportVersionEntity = {
  uuid: string
  snapshotUuid: string
  templateName: string
  name: string
  pages: VersionReportVersionPageEntity[]
  createdAt: DateTimeV2
  createdBy: {
    uuid: string
    name: string
  }
  updatedAt: DateTimeV2
  updatedBy: {
    uuid: string
    name: string
  }
}
export type VersionReportVersionPageEntity = {
  uuid: string
  pageTitle: string
  leadSentence: string | undefined
  pageNumber: number
  pageType: VersionReportPageType
  config: VersionReportPageConfig
  content: VersionReportVersionPageContent
}

export type VersionReportVersionPageContent =
  | ProgressOverviewPageContent
  | ProgressReportByProcessPageContent
  | ProgressReportByTeamPageContent
export type ProgressOverviewPageContent = {
  type: 'PROGRESS_OVERVIEW'
  status: VersionReportStatus
  comment: string
}
export type ProgressReportByProcessPageContent = {
  type: 'PROGRESS_REPORT_BY_PROCESS'
  tableContents: ProgressReportQualitativeContent[]
}
export type ProgressReportByTeamPageContent = {
  type: 'PROGRESS_REPORT_BY_TEAM'
  tableContents: ProgressReportQualitativeContent[]
}
export const initContent = (type: VersionReportPageType) => {
  switch (type) {
    case 'PROGRESS_OVERVIEW':
      return {
        status: 'SUNNY',
      }
    case 'PROGRESS_REPORT_BY_PROCESS':
      return {
        tableContents: [],
      }
    case 'PROGRESS_REPORT_BY_TEAM':
      return {
        tableContents: [],
      }
  }
}

export type ProgressReportQualitativeContent = {
  uuid: string
  status: VersionReportStatus
  comment: string
}

export type VersionReportPageType =
  | 'PROGRESS_OVERVIEW'
  | 'PROGRESS_REPORT_BY_PROCESS'
  | 'PROGRESS_REPORT_BY_TEAM'

export type VersionReportStatus = 'SUNNY' | 'CLOUDY' | 'RAINY'
