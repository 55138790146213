import { FC, useCallback, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { ProjectOverviewContent } from '../../ProjectOverview'
import { ProjectDetail } from '../../../../lib/functions/project'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../index'
import { Function } from '../../../../lib/commons/appFunction'
import UiFunctions from '../../../pages'
import { GanttReportParameter } from '../../../containers/commons/AgGrid/components/cell/custom/ganttReport/ganttUtil'
import {
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../domain/value-object/GanttParameterVO'
import { DayOfWeek } from '../../../../lib/functions/report'

type Props = {
  project: ProjectDetail
  treeRootUuid?: string
  ganttParameter: GanttReportParameter
  onClose: () => void
}

type StateProps = {
  functions: Function[]
}
const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export const ProjectOverviewDialog: FC<Props> = connect(mapStateToProps)(
  ({
    project,
    ganttParameter,
    treeRootUuid,
    onClose,
    functions,
  }: Props & StateProps) => {
    const [dialogHeight, setDialogHeight] = useState<number>(window.innerHeight)
    const handleWindowResize = useCallback(() => {
      setDialogHeight(window.innerHeight)
    }, [])
    useEffect(() => {
      window.addEventListener('resize', handleWindowResize)
      return () => {
        window.removeEventListener('resize', handleWindowResize)
      }
    }, [handleWindowResize])

    const appFunction = functions.find(
      v => v.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_OVERVIEW
    )
    const uiFunction =
      UiFunctions[APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_OVERVIEW]
    if (!appFunction || !uiFunction) return <></>

    return (
      <Dialog open={true} fullWidth={true} maxWidth={false} onClose={onClose}>
        <DialogContent sx={{ height: dialogHeight, padding: 0 }}>
          <ProjectOverviewContent
            {...appFunction}
            {...uiFunction}
            key={appFunction.externalId}
            project={project}
            ganttParameter={
              new GanttParameterVO(GanttDisplayUnit.MONTH, DayOfWeek.MONDAY, {
                start: ganttParameter.start,
                end: ganttParameter.end,
              })
            }
            treeRootUuid={treeRootUuid}
          />
        </DialogContent>
      </Dialog>
    )
  }
)
