import { IconButton, InputBase } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback } from 'react'
import { CellProps, FocusableCellProps, InputCell, InputCellProps } from '.'
import ClearIcon from '@mui/icons-material/ClearRounded'

type TextInputCellProps = CellProps &
  InputCellProps<string> &
  FocusableCellProps

export const TextInputCell = ({
  colSpan,
  value,
  onChange,
  onEnter,
  disabled,
  placeholder,
  onBlur,
}: TextInputCellProps) => {
  const clear = useCallback(() => {
    onChange('')
  }, [])
  return (
    <InputCell align="left" colSpan={colSpan}>
      <InputBase
        style={{
          width: 'calc(100% - 26px)',
          paddingRight: 0,
        }}
        value={value}
        onChange={event => {
          onChange(event.target.value ? event.target.value : '')
        }}
        onKeyPress={event => {
          if (onEnter && event.key === 'Enter') {
            onEnter()
          }
        }}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {!!value && (
        <StyledClearIconButton onClick={clear}>
          <ClearIcon fontSize="small" />
        </StyledClearIconButton>
      )}
    </InputCell>
  )
}

const StyledClearIconButton = styled(IconButton)({
  padding: '4px',
  color: 'rgba(0, 0, 0, 0.54)',
  left: '4px',
})
