import { useMemo } from 'react'
import { intl } from '../../../../i18n'

export const useKpiAmountDigit = (amount: number) => {
  const { truncateDigit, unit } = useMemo(() => {
    const digit = Math.floor(Math.log10(amount)) + 1
    if (digit > 9) {
      return {
        truncateDigit: 9,
        unit: intl.formatMessage({ id: 'unit.amount.billion' }),
      }
    }
    if (digit > 6) {
      return {
        truncateDigit: 6,
        unit: intl.formatMessage({ id: 'unit.amount.million' }),
      }
    }
    if (digit > 3) {
      return {
        truncateDigit: 3,
        unit: intl.formatMessage({ id: 'unit.amount.thousand' }),
      }
    }
    return {
      truncateDigit: 3,
      unit: intl.formatMessage({ id: 'unit.amount' }),
    }
  }, [amount])
  return {
    truncateDigit,
    unit,
  }
}
