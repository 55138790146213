import { useCallback, useState } from 'react'
import { WbsItemTypeVO } from '../../../../../../domain/value-object/WbsItemTypeVO'

export const useWbsItemTypeFilter = (
  wbsItemTypes: WbsItemTypeVO[]
): {
  options: WbsItemTypeVO[]
  setOptions: (_: WbsItemTypeVO[]) => void
  values: string[]
  setValues: (_: string[]) => void
  isAllSelected: () => boolean
  isAllDeselected: () => boolean
  selectAll: () => void
  deselectAll: () => void
  isActive: () => boolean
  model: () => { values: string[] } | undefined
  reset: () => void
  getModelAsString: () => string
} => {
  const [options, setOptions] = useState<WbsItemTypeVO[]>(wbsItemTypes)
  const [values, setValues] = useState<string[]>(
    wbsItemTypes.map(v => v.baseTicketUuid ?? v.uuid)
  )

  const isAllSelected = useCallback(() => {
    return values.length === options.length
  }, [values])

  const isAllDeselected = useCallback(() => {
    return values.length === 0
  }, [values])

  const selectAll = useCallback(() => {
    setValues(options.map(v => v.baseTicketUuid ?? v.uuid))
  }, [options])

  const deselectAll = useCallback(() => {
    setValues([])
  }, [])

  const reset = useCallback(() => {
    setValues(options.map(v => v.baseTicketUuid ?? v.uuid))
  }, [options])

  const isActive = useCallback(() => !isAllSelected(), [values, options])

  const model = useCallback(() => {
    if (isActive()) {
      return { values }
    }
    return undefined
  }, [values, options])

  const getModelAsString = useCallback(() => {
    if (!isActive()) return ''
    return `${options
      .filter(o => values.includes(o.baseTicketUuid ?? o.uuid))
      .map(o => o.getNameWithSuffix())
      .join(',')}`
  }, [values, options])

  return {
    options,
    setOptions,
    values,
    setValues,
    isAllSelected,
    isAllDeselected,
    selectAll,
    deselectAll,
    isActive,
    model,
    reset,
    getModelAsString,
  }
}
