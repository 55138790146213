import React from 'react'
import _ from 'lodash'
import { InputBase } from '@mui/material'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import TextVO from '../../../../../../vo/TextVO'

interface TextCellProps extends CellPropsBase<TextVO> {}
interface TextCellState extends CellStateBase {
  value: string
}

class TextCell extends DataCellBase<TextVO, TextCellProps, TextCellState> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value?.getValue() ?? '',
    }
  }

  componentDidUpdate(prevProps: TextCellProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    const value = this.props.value?.getValue() ?? ''
    if (
      prevProps.value?.getValue() !== this.props.value?.getValue() &&
      this.state.value !== value
    ) {
      this.setState({ value })
    }
  }

  validate = (value: TextVO) => {
    return undefined
  }

  onChangeText = _.debounce((value: string) => {
    this.props.onChange(new TextVO(value))
  }, 300)

  onChange = (event: any) => {
    const value = event.target.value ?? ''
    this.setState({ value })
    this.onChangeText(value)
  }

  render() {
    const { cellDef } = this.props
    return (
      <InputBase
        value={this.state.value}
        onChange={this.onChange}
        disabled={!cellDef.editable}
        placeholder={cellDef.placeholder}
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    )
  }
}

export default TextCell
