import { intl } from '../../../../i18n'
import { SelectOption } from '../../../model/selectOption'

export type TeamSelectOption = SelectOption & {
  type: TeamType
}

export type TeamType = 'TEAM_TOTAL' | 'SPECIFIC_TEAM' | 'TEAM_UNSELECTED'

export const teamTotal = {
  value: 'TEAM_TOTAL',
  name: intl.formatMessage({ id: 'teamTotal' }),
  type: 'TEAM_TOTAL' as TeamType,
}

export const teamUnselected = {
  value: 'TEAM_UNSELECTED',
  name: intl.formatMessage({ id: 'teamUnselected' }),
  type: 'TEAM_UNSELECTED' as TeamType,
}
