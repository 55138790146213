import {
  Breadcrumbs,
  ClickAwayListener,
  IconButton,
  Link,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { colorPalette } from '../../../style/colorPallete'
import {
  ProjectPlanBreadcrumb as ProjectPlanBreadcrumbModel,
  useProjectPlanBreadcrumbs,
  useProjectPlanDirectChildren,
  useProjectPlanSiblings,
} from './projectPlanBreadcrumbs'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useCallback, useMemo, useRef, useState } from 'react'
import defaultProjectIcon from '../../../../assets/project_icon_default.svg'

type ProjectPlanBreadcrumbsProps = {
  rootProjectPlanUuid: string | undefined
  onSelectBreadcrumb: (rootProjectPlanUuid: string | undefined) => void
}
export const ProjectPlanBreadcrumbs = ({
  rootProjectPlanUuid,
  onSelectBreadcrumb,
}: ProjectPlanBreadcrumbsProps) => {
  const breadcrumbs = useProjectPlanBreadcrumbs(rootProjectPlanUuid)
  const [breadcrumbsWithoutLeaf, leafBreadcrumb] = useMemo(() => {
    const breadcrumbWithoutLeaf = breadcrumbs.filter(
      (_, i) => i < breadcrumbs.length - 1
    )
    const leafBreadcrumb =
      breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : undefined
    return [breadcrumbWithoutLeaf, leafBreadcrumb]
  }, [breadcrumbs])
  const onSelectProject = useCallback(
    () => onSelectBreadcrumb(undefined),
    [onSelectBreadcrumb]
  )
  const isProjectRoot = useMemo(
    () => !rootProjectPlanUuid,
    [rootProjectPlanUuid]
  )
  return (
    <Breadcrumbs>
      <ProjectBreadcrumb
        key="project-breadcrumb"
        onClick={onSelectProject}
        isProjectRoot={isProjectRoot}
      />
      {breadcrumbsWithoutLeaf.map(breadcrumb => (
        <ProjectPlanBreadcrumb
          key={breadcrumb.uuid}
          breadcrumb={breadcrumb}
          onSelectBreadcrumb={onSelectBreadcrumb}
        />
      ))}
      {leafBreadcrumb && (
        <LeafProjectPlanBreadcrumb
          breadcrumb={leafBreadcrumb}
          onSelectBreadcrumb={onSelectBreadcrumb}
        />
      )}
      <BreakdownIconButton
        leafProjectPlanUuid={leafBreadcrumb?.uuid}
        onSelectBreadcrumb={onSelectBreadcrumb}
      />
    </Breadcrumbs>
  )
}

type ProjectBreadcrumbProps = {
  onClick: () => void
  isProjectRoot: boolean
}
const ProjectBreadcrumb = ({
  onClick,
  isProjectRoot,
}: ProjectBreadcrumbProps) => {
  const { project } = useProjectPrivateContext()
  const projectIconUrl = useMemo(
    () => project.iconUrl || defaultProjectIcon,
    [project.iconUrl]
  )
  return (
    <BreadcrumbWrapper>
      <ProjectIcon src={projectIconUrl} />
      {!isProjectRoot && (
        <BreadcrumbLink onClick={onClick}>{project.displayName}</BreadcrumbLink>
      )}
      {isProjectRoot && <BreadcrumbName>{project.displayName}</BreadcrumbName>}
    </BreadcrumbWrapper>
  )
}

type ProjectPlanBreadcrumbProps = {
  breadcrumb: ProjectPlanBreadcrumbModel
  onSelectBreadcrumb: (rootProjectPlanUuid: string | undefined) => void
}
const ProjectPlanBreadcrumb = ({
  breadcrumb,
  onSelectBreadcrumb,
}: ProjectPlanBreadcrumbProps) => {
  const onClick = useCallback(() => {
    onSelectBreadcrumb(breadcrumb.uuid)
  }, [breadcrumb.uuid, onSelectBreadcrumb])
  return (
    <BreadcrumbWrapper key={breadcrumb.uuid}>
      <WbsItemTypeIcon src={breadcrumb.type.iconUrl} />
      <BreadcrumbLink onClick={onClick}>{breadcrumb.name}</BreadcrumbLink>
    </BreadcrumbWrapper>
  )
}

const LeafProjectPlanBreadcrumb = ({
  breadcrumb,
  onSelectBreadcrumb,
}: ProjectPlanBreadcrumbProps) => {
  return (
    <BreadcrumbWrapper key={breadcrumb.uuid}>
      <WbsItemTypeIcon src={breadcrumb.type.iconUrl} />
      <BreadcrumbName>{breadcrumb.name}</BreadcrumbName>
      <ExpandSiblingsIconButton
        projectPlanUuid={breadcrumb.uuid}
        onSelectBreadcrumb={onSelectBreadcrumb}
      />
    </BreadcrumbWrapper>
  )
}

type ExpandSiblingsIconButtonProps = {
  projectPlanUuid: string
  onSelectBreadcrumb: (rootProjectPlanUuid: string | undefined) => void
}
const ExpandSiblingsIconButton = ({
  projectPlanUuid,
  onSelectBreadcrumb,
}: ExpandSiblingsIconButtonProps) => {
  const siblings = useProjectPlanSiblings(projectPlanUuid)
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLButtonElement>(null)
  return (
    <>
      <StyledIconButton ref={ref} onClick={toggle}>
        <StyledExpandMoreIcon />
      </StyledIconButton>
      <BreadcrumbListPopper
        open={open}
        onClose={onClose}
        anchorEl={ref?.current}
        breadcrumbs={siblings}
        onSelectBreadcrumb={onSelectBreadcrumb}
      />
    </>
  )
}

type BreakdownIconButtonProps = {
  leafProjectPlanUuid: string | undefined
  onSelectBreadcrumb: (rootProjectPlanUuid: string | undefined) => void
}
const BreakdownIconButton = ({
  leafProjectPlanUuid,
  onSelectBreadcrumb,
}: BreakdownIconButtonProps) => {
  const children = useProjectPlanDirectChildren(leafProjectPlanUuid)
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLButtonElement>(null)
  return (
    <>
      <StyledIconButton ref={ref} onClick={toggle}>
        <StyledBreakdownIcon />
      </StyledIconButton>
      <BreadcrumbListPopper
        open={open}
        onClose={onClose}
        anchorEl={ref?.current}
        breadcrumbs={children}
        onSelectBreadcrumb={onSelectBreadcrumb}
      />
    </>
  )
}

type BreadcrumbListPopperProps = {
  open: boolean
  onClose: () => void
  anchorEl: HTMLButtonElement | null
  breadcrumbs: ProjectPlanBreadcrumbModel[]
  onSelectBreadcrumb: (rootProjectPlanUuid: string | undefined) => void
}
const BreadcrumbListPopper = ({
  open,
  onClose,
  anchorEl,
  breadcrumbs,
  onSelectBreadcrumb,
}: BreadcrumbListPopperProps) => {
  return (
    <Popper open={open} anchorEl={anchorEl}>
      <ClickAwayListener onClickAway={onClose}>
        <BreadcrumbListDiv>
          {breadcrumbs.map(breadcrumb => (
            <SelectListItemButton
              key={breadcrumb.uuid}
              onClick={() => onSelectBreadcrumb(breadcrumb.uuid)}
            >
              <SelectListItem>{breadcrumb.name}</SelectListItem>
            </SelectListItemButton>
          ))}
        </BreadcrumbListDiv>
      </ClickAwayListener>
    </Popper>
  )
}

const BreadcrumbListDiv = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  padding: '4px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  width: '150px',
})
const SelectListItemButton = styled(ListItemButton)({})
const SelectListItem = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[4],
})

const StyledIconButton = styled(IconButton)({
  padding: '0',
})
const StyledBreakdownIcon = styled(AddCircleOutlineRoundedIcon)({
  color: colorPalette.monotone[5],
  height: '16px',
  width: '16px',
})
const StyledExpandMoreIcon = styled(ExpandMoreRoundedIcon)({
  color: colorPalette.monotone[5],
  height: '16px',
  width: '16px',
})

const ProjectIcon = styled('img')({
  height: '20px',
  width: '20px',
})
const WbsItemTypeIcon = styled('img')({
  height: '14px',
  width: '14px',
})
const BreadcrumbWrapper = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
})
const BreadcrumbLink = styled(Link)({
  fontSize: '12px',
  lineHeight: 1.5,
  color: colorPalette.monotone[10],
  cursor: 'pointer',
})
const BreadcrumbName = styled('div')({
  fontSize: '12px',
  lineHeight: 1.5,
  color: colorPalette.monotone[10],
})
