import { useCallback, useState } from 'react'
import { NewWbsItemRow } from '../projectPlanNew'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'

export const usePopper = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget>()
  const [wbsItem, setWbsItem] = useState<NewWbsItemRow>()
  const [wbsItemDelta, setWbsItemDelta] = useState<Partial<WbsItemDeltaInput>>(
    {}
  )

  const open = useCallback(
    (
      el: EventTarget,
      wbsItem: NewWbsItemRow,
      delta: Partial<WbsItemDeltaInput>
    ) => {
      setAnchorEl(el)
      setWbsItem(wbsItem)
      setWbsItemDelta(delta)
    },
    []
  )

  const close = useCallback(() => {
    setAnchorEl(undefined)
    setWbsItem(undefined)
    setWbsItemDelta({})
  }, [])

  return {
    anchorEl,
    wbsItem,
    wbsItemDelta,

    open,
    close,
  }
}
