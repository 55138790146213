import { FunctionProperty } from '../../../../../../lib/commons/appFunction'
import validator from '../../../../meta/validator'
import objects from '../../../../../../utils/objects'
import ViewMeta from '../../../../meta/ViewMeta'
import { GridApi } from 'ag-grid-community'

export const inputErrorColor = '#FADBD8'

export const validate = (
  val: any,
  rowData: any,
  prop: FunctionProperty,
  viewMeta: ViewMeta,
  api?: GridApi
): string | undefined => {
  const propertyAccessor = (externalId: string) => {
    const entityExtensionProperty = viewMeta.functionMeta.entityExtensions
      ? viewMeta.functionMeta.entityExtensions.byId.get(externalId)
      : undefined
    if (entityExtensionProperty) {
      return rowData.extensions
        ? rowData.extensions.find(
            v => v.uuid === entityExtensionProperty.entityExtensionUuid
          )
        : undefined
    }
    const functionProperty =
      viewMeta.functionMeta.properties.byId.get(externalId)
    if (!functionProperty) {
      return undefined
    }
    const field = viewMeta.makeDataPropertyName(functionProperty)
    return {
      name: functionProperty.name,
      value: objects.getValue(rowData, field),
    }
  }

  const error = validator.validate(val, rowData, prop, propertyAccessor)

  // Refresh related cells to validate
  if (viewMeta && viewMeta.functionMeta && api) {
    const relatedProperty = viewMeta.functionMeta.relatedProperties.get(
      prop.externalId
    )
    if (relatedProperty && relatedProperty.length > 0) {
      const columns = relatedProperty.map(v => viewMeta.makeDataPropertyName(v))
      const node = api.getRowNode(rowData.uuid)
      node &&
        api.refreshCells({
          rowNodes: [node],
          columns: columns,
          force: true,
        })
    }
  }
  return error ? error.getMessage() : undefined
}
