import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'

type AggregateFieldToggleButtonGroupProps = {
  value: AggregateField
  onChange: (_: AggregateField) => void
}

export const AggregateFieldToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: AggregateFieldToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={AggregateField.WBS_ITEM_COUNT}>
        {intl.formatMessage({
          id: 'aggregateField.count',
        })}
      </ToggleButton>
      <ToggleButton value={AggregateField.WBS_ITEM_WORKLOAD}>
        {intl.formatMessage({
          id: 'aggregateField.workload',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
