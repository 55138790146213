import { useCallback, useEffect, useState } from 'react'
import ProjectAPI, { ProjectDetail } from '../../../../lib/functions/project'

export const useAssigedProjectsData = ({
  userUuid,
  projectUuid,
}: {
  userUuid: string | undefined
  projectUuid: string | undefined
}) => {
  const [projects, setProjects] = useState<ProjectDetail[]>([])

  const fetchAssigedProjects = useCallback(async () => {
    const response = await ProjectAPI.getAssignedProjectsByUser(userUuid)
    if (projectUuid) {
      const jsonProj = response.json as ProjectDetail[]
      const findProj = jsonProj.find(v => v.uuid === projectUuid)
      setProjects(findProj ? [findProj] : [])
    } else {
      setProjects(response.json as ProjectDetail[])
    }
  }, [projectUuid, userUuid])

  useEffect(() => {
    fetchAssigedProjects()
  }, [fetchAssigedProjects])

  return { projects }
}
