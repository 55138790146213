import { useCallback, useState } from 'react'
import { SelectOption } from '../../../model/selectOption'

export const useSingleSelectOption = (
  defaultValue?: string
): [string | undefined, (option: SelectOption | undefined) => void] => {
  const [selected, setSelected] = useState<string | undefined>(defaultValue)
  const onSelect = useCallback((option: SelectOption | undefined) => {
    setSelected(option?.value)
  }, [])
  return [selected, onSelect]
}
