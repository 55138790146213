import styled from 'styled-components'
import { intl } from '../../../../i18n'
import { UIState } from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import {
  Header,
  HeaderContent,
  HeaderDivider,
} from '../../../components/headers/HeaderBar/PageHeader'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../../components/toolbars/ContainerToolBar'
import WbsItemSearchToolBar from '../../WbsItemSearch/WbsItemSearchToolBar'
import {
  getDefaultSearchFilter,
  SearchFilter,
} from '../../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import WbsItemSearchFilteringItems from '../../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchFilteringItems'
import { taskActualWorkSearchCondition } from '../TaskActualWorkOptions'

interface Props {
  applicationFunctionUuid: string
  getColumnAndFilterState: () => UIState
  openSavedUIStateDialog: () => void
  onSearch: () => void
  onChangeFilter: (value: SearchFilter) => void
  searchText: string
  setSearchText: (value: string) => void
  searchFilter: SearchFilter
  setSearchFilter: (value: SearchFilter) => void
  getSearchCondition: () => any
  total: number
  hit: number
}

const LabelSpan = styled('span')({
  whiteSpace: 'nowrap',
  margin: '0 5px',
})

const HeaderGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})

const PageSubHeader = styled('span')({
  background: '#fff',
  color: '#000',
  height: '36px',
  marginBottom: '3px',
})

const TaskActualWorkSearchHeader = ({
  searchText,
  searchFilter,
  onSearch,
  setSearchText,
  setSearchFilter,
  applicationFunctionUuid,
  getSearchCondition,
  getColumnAndFilterState,
  openSavedUIStateDialog,
  hit,
  total,
  onChangeFilter,
}: Props) => {
  return (
    <PageSubHeader>
      <Header sx={{ paddingLeft: '8px' }}>
        <HeaderContent>
          <WbsItemSearchToolBar
            key={'wbs-search-bar'}
            searchConditions={taskActualWorkSearchCondition}
            searchText={searchText}
            searchFilter={searchFilter}
            reloadHandler={() => onSearch()}
            onChangeSearchText={(text?: string) => setSearchText(text || '')}
            onChangeFilter={(filter?: SearchFilter) =>
              setSearchFilter(filter || getDefaultSearchFilter())
            }
            saveDialogProps={{
              applicationFunctionUuid: applicationFunctionUuid,
              getCurrentSearchCondition: () => {
                return {
                  searchCondition: getSearchCondition(),
                } as UIState
              },
              getColumnAndFilterState: () => {
                return getColumnAndFilterState()
              },
            }}
            openSavedUIStateDialog={() => {
              openSavedUIStateDialog()
            }}
          />
          <HeaderGroup>
            <LabelSpan>
              {intl.formatMessage(
                { id: 'search.hitsTotal' },
                { hit: hit, total: total }
              )}
            </LabelSpan>
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <WbsItemSearchFilteringItems
              key={generateToolBarItemKey(2, ToolBarItemPosition.RIGHT)}
              searchConditions={taskActualWorkSearchCondition}
              searchFilterSnapshotOnSearch={
                searchFilter || getDefaultSearchFilter()
              }
              onChangeFilter={searchFilter => {
                searchFilter && onChangeFilter(searchFilter)
              }}
            />
          </HeaderGroup>
        </HeaderContent>
      </Header>
    </PageSubHeader>
  )
}

export default TaskActualWorkSearchHeader
