import { ICellRendererParams } from 'ag-grid-community'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { ICON_SIZE } from '../../../BulkSheet/const'
import { styled } from '@mui/material'

type Props = ICellRendererParams & {
  getLabelWithIconUrl: (rowData) =>
    | {
        label: string
        iconUrl: string
      }
    | undefined
}

export const IconCellRenderer = (props: Props) => {
  const { data, colDef, getLabelWithIconUrl } = props

  if (!colDef) {
    return <></>
  }
  const labelWithIconUrl = getLabelWithIconUrl(data)
  if (
    !labelWithIconUrl ||
    (!labelWithIconUrl.iconUrl && !labelWithIconUrl.label)
  ) {
    return <></>
  }

  return (
    <RootDiv className={`column-${colDef?.colId || ''}`}>
      <Icon src={labelWithIconUrl?.iconUrl} variant="rounded" />
      <Label component="span">{labelWithIconUrl?.label}</Label>
    </RootDiv>
  )
}

const Icon = styled(Avatar)({
  backgroundColor: '#DCDCDC',
  height: ICON_SIZE,
  width: ICON_SIZE,
  mr: '10px',
})

const Label = styled(Box)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})
