import VOBase from '../base'
import moment from 'moment'

interface IDateTimeRange {
  startDateTime?: Date
  endDateTime?: Date
}

interface IFormatedDateTimeRange {
  startDateTime?: string
  endDateTime?: string
}

export default class DateTimeRangeVO extends VOBase {
  private readonly value: IDateTimeRange
  private readonly FORMAT = 'YYYY/MM/DD HH:mm:ss'

  constructor(_value: {
    startDateTime?: number | string | Date
    endDateTime?: number | string | Date
  }) {
    super()
    if (!_value) {
      throw new Error(
        'Can not create DateTimeRangeVO instance because params is empty.'
      )
    }
    if (
      typeof _value === 'object' &&
      Object.keys(_value).some(
        key => !['startDateTime', 'endDateTime'].includes(key)
      )
    ) {
      throw new Error(
        `Can not create DateTimeRangeVO instance because params is invalid. property${JSON.stringify(
          _value
        )} is invalid.`
      )
    }
    const { startDateTime, endDateTime } = _value
    if (startDateTime && !moment(startDateTime).isValid()) {
      throw new Error(
        `Can not create DateTimeRangeVO instance because not supported params(params: ${_value}).`
      )
    }
    if (endDateTime && !moment(endDateTime).isValid()) {
      throw new Error(
        `Can not create DateTimeRangeVO instance because not supported params(params: ${_value}).`
      )
    }
    this.value = {
      startDateTime: startDateTime ? moment(startDateTime).toDate() : undefined,
      endDateTime: endDateTime ? moment(endDateTime).toDate() : undefined,
    }
  }

  getValue = (): IDateTimeRange => {
    return this.value
  }

  format = (): IFormatedDateTimeRange => {
    const { startDateTime, endDateTime } = this.value
    return {
      startDateTime: startDateTime
        ? moment(startDateTime).format(this.FORMAT)
        : undefined,
      endDateTime: endDateTime
        ? moment(endDateTime).format(this.FORMAT)
        : undefined,
    }
  }

  isEmpty = () => {
    return !this.value.startDateTime && !this.value.endDateTime
  }
  serialize = () => {
    const { startDateTime, endDateTime } = this.value
    return {
      startDateTime: startDateTime,
      endDateTime: endDateTime,
    }
  }
  isEqual(target: VOBase): boolean {
    const datetime = this.format()
    const targetDatetime = target.format()
    return (
      datetime.startDateTime === targetDatetime.startDateTime &&
      datetime.endDateTime === targetDatetime.endDateTime
    )
  }
}
