import { GetContextMenuItemsParams, GridOptions } from 'ag-grid-community'
import { BulkSheetView } from '../../../../containers/BulkSheetView'
import Loading from '../../../../components/process-state-notifications/Loading'
import { useCallback, useEffect, useRef } from 'react'
import CancelConfirmDialog from '../../../../components/dialogs/CancelConfirmDialog'
import {
  TaskActualWorkTaskDialogState,
  openTaskActualWorkListProps,
} from '../../hooks/taskActualWorkTaskDialogState'
import TaskActualWorkTaskDialog from '../../../../components/dialogs/TaskActualWorkTaskDialog'
import DateVO from '../../../../../vo/DateVO'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../../lib/functions/customEnumValue'
import { parse } from '../../../../../lib/commons/i18nLabel'
import {
  ActualWorkingHoursRow,
  ActualWorkingHoursSummaryRow,
} from '../../ActualWorkingHours'
import { useAssigedProjectsData } from '../../hooks/assignedProjectsData'
import {
  refreshDynamicColumnDef,
  updateDisplayProjectColumn,
} from '../../gridOptions'

interface Props {
  gridOptions: GridOptions
  loading: boolean
  userUuid: string | undefined
  projectUuid?: string | undefined
  data: ActualWorkingHoursRow[]
  summaryRow: ActualWorkingHoursSummaryRow
  onCallbackReload?: () => void
  taskDialogState: TaskActualWorkTaskDialogState | null
  setTaskDialogState: (state: TaskActualWorkTaskDialogState | null) => void
  openTaskActualWorkList: (props: openTaskActualWorkListProps) => Promise<void>
  openCancel?: boolean
  setOpenCancel?: (value: boolean) => void
  rowHeight: number
}

const ActualWorkingHoursView = (props: Props) => {
  const {
    gridOptions,
    loading,
    userUuid,
    projectUuid,
    data,
    summaryRow,
    onCallbackReload,
    taskDialogState,
    setTaskDialogState,
    openTaskActualWorkList,
    openCancel,
    setOpenCancel,
    rowHeight,
  } = props
  const { projects } = useAssigedProjectsData({ userUuid, projectUuid })
  const ref = useRef<HTMLDivElement>(null)
  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    return []
  }, [])

  const fetchSelectColumnOptions = useCallback(async () => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WORKING_HOURS_TYPE,
    })
    const workingHoursType = response.json.map(v => ({
      ...v,
      nameI18n: parse(v.nameI18n),
    }))
    gridOptions.context = {
      ...gridOptions.context,
      workingHoursType,
    }
  }, [gridOptions])

  const onCancelConfirm = useCallback(() => {
    setOpenCancel && setOpenCancel(false)
    onCallbackReload && onCallbackReload()
  }, [onCallbackReload, setOpenCancel])

  useEffect(() => {
    fetchSelectColumnOptions()
  }, [fetchSelectColumnOptions])

  useEffect(() => {
    refreshDynamicColumnDef({
      api: gridOptions.api,
      projects,
      userUuid,
      openTaskActualWorkList,
    })
  }, [gridOptions.api, projects, userUuid, openTaskActualWorkList])

  useEffect(() => {
    updateDisplayProjectColumn(gridOptions.columnApi, gridOptions.api, data)
  }, [gridOptions.columnApi, gridOptions.api, data])

  return (
    <>
      <BulkSheetView
        ref={ref}
        gridOptions={gridOptions}
        rowHeight={rowHeight}
        rowData={data}
        pinnedTopRowData={[summaryRow]}
        getContextMenuItems={contextMenu}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <CancelConfirmDialog
        open={openCancel ?? false}
        onConfirm={onCancelConfirm}
        onClose={() => setOpenCancel && setOpenCancel(false)}
      />
      {taskDialogState && (
        <TaskActualWorkTaskDialog
          anchorEl={taskDialogState.anchorElement}
          date={new DateVO(taskDialogState.date)}
          actualWorks={taskDialogState.taskActualWorks}
          actualHour={taskDialogState.total}
          event={taskDialogState.clickEvent as PointerEvent}
          onClose={() => setTaskDialogState(null)}
          openDetail={taskDialogState.openDetail}
        />
      )}
    </>
  )
}

export default ActualWorkingHoursView
