import {
  ImpactType,
  ProbabilityType,
  RiskTypeType,
  StrategyType,
} from '../../../Risks/risks'
import { TicketListDetail } from '../../../../../lib/functions/ticketList'
import { WbsItemStatus } from '../../../../../domain/entity/WbsItemEntity'
import API, { APIResponse } from '../../../../../lib/commons/api'
import { useCallback, useEffect, useState } from 'react'
import { ProjectDetail } from '../../../../../lib/functions/project'

export type RiskBasic = {
  uuid: string
  riskType: RiskTypeType
  probability: ProbabilityType
  impact: ImpactType
  strategy: StrategyType
  score: number

  ticketList?: TicketListDetail

  taskUuid: string
  code: string
  status: WbsItemStatus
  displayName: string
}

export const useRisk = (
  project: ProjectDetail
): {
  data: RiskBasic[]
  refresh: () => void
  filteredRiskList: RiskBasic[]
  filter: (probability: ProbabilityType, impact: ImpactType) => void
} => {
  const [data, setData] = useState<RiskBasic[]>([])
  const [filteredRiskList, setFilteredRiskList] = useState<RiskBasic[]>([])

  useEffect(() => {
    refresh()
  }, [project.uuid])

  const refresh = useCallback(async () => {
    if (!project.uuid) return []
    const response = await fetchRisks({ projectUuid: project.uuid })
    setData(response.json)
    setFilteredRiskList(response.json)
  }, [project.uuid])

  const filter = useCallback(
    (probability?: ProbabilityType, impact?: ImpactType) => {
      if (probability && impact) {
        setFilteredRiskList(
          data.filter(v => v.probability === probability && v.impact === impact)
        )
      } else {
        setFilteredRiskList(data)
      }
    },
    [data]
  )

  return { data, refresh, filteredRiskList, filter }
}

// TODO: Move it
// Fetch
const fetchRisks = async (params: {
  projectUuid: string
}): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/risks/list/all',
    params
  )
}
