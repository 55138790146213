import React, { useCallback, useState } from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { IconColor } from '../../../../../styles/commonStyles'
import AttachmentIcon from '../../../../components/icons/AttachmentIcon'
import WbsItem from '../../../../../lib/functions/wbsItem'
import AttachmentListDialog from '../../../meta/AttachmentListDialog'
import { AttachmentSummary } from '../../../../../utils/attachment'

interface Props extends ICellRendererParams {
  value: AttachmentSummary | undefined
  getWbsItemUuid: (node: RowNode) => string
}

export const AttachmentCellRenderer = ({
  value,
  node,
  getWbsItemUuid,
}: Props) => {
  const [openDetail, setOpenDetail] = useState(false)
  const [attachments, setAttachments] = useState([])

  const openAttachmentList = useCallback(async (node: RowNode) => {
    const wbsItemUuid = getWbsItemUuid
      ? getWbsItemUuid(node)
      : node.data.body?.wbsItem?.uuid
    const response = await WbsItem.getDetail({ uuid: wbsItemUuid })
    const wbsItemDetail = response.json
    setAttachments(wbsItemDetail.deliverableAttachments || [])
    setOpenDetail(true)
  }, [])

  if (!value || !value.hasAttachment) return <></>
  return (
    <div className="sevend-ag-cell-attachment">
      <AttachmentIcon
        className="sevend-ag-cell-attachment__attachment-icon"
        onClick={event => {
          event.preventDefault()
          event.stopPropagation()
          openAttachmentList(node)
        }}
        style={{
          color: IconColor.GREY,
          fill: IconColor.GREY,
          fontsize: 18,
          width: 18,
        }}
      />
      <AttachmentListDialog
        open={openDetail}
        attachments={attachments}
        onClose={() => setOpenDetail(false)}
      />
    </div>
  )
}
