import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { AgGridRowResizeDragger } from '../../../dragger/rowResizeDragger'

const SequenceNoLabel = ({ rowNumber }) => (
  <p className="sevend-ag-cell-sequence-no__label">{rowNumber}</p>
)

export const SequenceNoCellRenderer = (props: ICellRendererParams) => {
  return (
    <div className="sevend-ag-cell-sequence-no">
      <SequenceNoLabel rowNumber={props.value} />
      {!props.data?.isTotal && (
        <AgGridRowResizeDragger node={props.node} api={props.api} />
      )}
    </div>
  )
}
