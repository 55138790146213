import * as d3 from 'd3'
import { DateBucket, DateBucketType } from '../model/timeSeries'
import { WeekDay } from '../../../../domain/value-object/DayOfWeek'

export const calculateDateBucket = (
  from: Date,
  to: Date,
  type: DateBucketType,
  startDayOfWeek: WeekDay,
  offset: {
    from: number
    to: number
  } = {
    from: 0,
    to: 0,
  }
): DateBucket[] => {
  const interval = getInterval(type, startDayOfWeek)
  const startBucketFrom = interval.offset(interval.floor(from), -offset.from)
  const endBucketFrom = interval.offset(interval.floor(to), offset.to)
  const bucketCount = interval.count(startBucketFrom, endBucketFrom) + 1
  return Array.from({ length: bucketCount }).map((_, i) => {
    const bucketFrom = interval.offset(startBucketFrom, i)
    const bucketTo = interval.offset(bucketFrom, 1)
    return {
      from: bucketFrom,
      to: bucketTo,
    }
  })
}

export const getIsBucketPassed = (
  type: DateBucketType
): ((bucket: DateBucket) => boolean) => {
  const now = new Date()
  switch (type) {
    case 'DAY':
      return (bucket: DateBucket) => {
        return bucket.from < now
      }
    case 'WEEK':
    case 'MONTH':
      return (bucket: DateBucket) => {
        return bucket.to < now
      }
  }
}

export const getBucketRepresentativeGetter = (
  type: DateBucketType
): ((bucket: DateBucket) => Date) => {
  switch (type) {
    case 'DAY':
      return (bucket: DateBucket) => {
        return bucket.from
      }
    case 'WEEK':
    case 'MONTH':
      return (bucket: DateBucket) => {
        return bucket.to
      }
  }
}

export const calculateCurrentDateBucket = (
  type: DateBucketType,
  startDayOfWeek: WeekDay
): DateBucket => {
  const interval = getInterval(type, startDayOfWeek)
  const today = new Date()
  const representative = interval.floor(today)
  switch (type) {
    case 'DAY':
      return {
        from: representative,
        to: interval.offset(representative, 1),
      }
    case 'WEEK':
    case 'MONTH':
      return {
        from: interval.offset(representative, -1),
        to: representative,
      }
  }
}

const getInterval = (type: DateBucketType, startDayOfWeek: WeekDay) => {
  switch (type) {
    case 'DAY':
      return d3.timeDay
    case 'WEEK':
      switch (startDayOfWeek) {
        case 'MONDAY':
          return d3.timeMonday
        case 'TUESDAY':
          return d3.timeTuesday
        case 'WEDNESDAY':
          return d3.timeWednesday
        case 'THURSDAY':
          return d3.timeThursday
        case 'FRIDAY':
          return d3.timeFriday
      }
      return d3.timeMonday
    case 'MONTH':
      return d3.timeMonth
  }
}

const calculateFromDate = (
  base: Date,
  type: DateBucketType,
  offset: number
): Date => {
  switch (type) {
    case 'DAY':
      return d3.timeDay.offset(base, -offset)
    case 'WEEK':
      return d3.timeMonday.offset(d3.timeMonday.floor(base), -offset)
    case 'MONTH':
      return d3.timeMonth.offset(d3.timeMonth.floor(base), -offset)
  }
}

const calculateToDate = (
  base: Date,
  type: DateBucketType,
  offset: number
): Date => {
  switch (type) {
    case 'DAY':
      return d3.timeDay.offset(base, offset)
    case 'WEEK':
      return d3.timeMonday.offset(d3.timeMonday.floor(base), offset)
    case 'MONTH':
      return d3.timeMonth.offset(d3.timeMonth.floor(base), offset)
  }
}
