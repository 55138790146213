import objects from './objects'

export default class SearchOptions {
  readonly expression: string
  readonly keys: string[]
  readonly values: string[]

  constructor(expression: string) {
    this.expression = expression || ''
    let keys: string[] = []
    let values: string[] = []
    if (this.expression !== '') {
      const options = expression.split(/\s+/)
      if (options.length > 0) {
        for (let i = 0; i < options.length; i++) {
          const keyValue = options[i].split(':')
          keys[i] = keyValue[0]
          values[i] = keyValue[1]
        }
      }
    }
    this.keys = keys
    this.values = values
  }

  public build = (data: any) => {
    if (this.keys.length !== this.values.length) {
      return {}
    }
    let result = {}
    for (let i = 0; i < this.keys.length; i++) {
      objects.setValue(
        result,
        this.keys[i],
        this.getValue(data, this.values[i])
      )
    }
    return result
  }

  // When value is splitted by comma, treat as array.
  // When value starts and ends with double quatation, set as value directly.
  private getValue = (data: any, val: string) => {
    const commaSplitted = val.split(',')
    if (commaSplitted.length > 1) {
      return commaSplitted.map(v => this.getValue(data, v))
    }
    const splitted = val.split('"')
    if (splitted.length > 1) {
      return splitted[1]
    }
    return objects.getValue(data, val)
  }
}
