import api, { APIResponse } from '../../../lib/commons/api'
import { PositionProps } from '../../../lib/functions/position'
import { UserDetail } from '../../../lib/functions/user'
import { generateUuid } from '../../../utils/uuids'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { LedgerAccountsTree } from '../LedgerAccounts/ledgerAccounts'
import {
  ProfitLossItemAmountBody,
  ProfitLossRowBase,
  ProfitLossRowBodyBase,
  ProfitLossSkeletonBase,
} from '../ProfitLossItems/profitLossItems'
import { UnitPriceBody } from '../UnitPricePerPosition/UnitPricePerPosition'

export const ProfitLossMemberRowType = {
  AmountBudget: 'AMOUNT_BUDGET',
  AmountResult: 'AMOUNT_RESULT',
  WorkMonthSchedule: 'WORK_MONTH_SCHEDULE',
  WorkMonthActual: 'WORK_MONTH_ACTUAL',
} as const

export type ProfitLossMemberRowTypeType =
  (typeof ProfitLossMemberRowType)[keyof typeof ProfitLossMemberRowType]

export class ProfitLossMemberSkeleton
  extends ProfitLossSkeletonBase
  implements TreeSource<ProfitLossMemberSkeleton>
{
  children: ProfitLossMemberSkeleton[]

  user: UserDetail
  position: PositionProps
  unitPrices: UnitPriceBody[]
  budgetAmounts: ProfitLossItemAmountBody[]
  resultAmounts: ProfitLossItemAmountBody[]
  scheduledWorkMonth: ProfitLossMemberWorkMonthBody[]
  actualWorkMonth: ProfitLossMemberWorkMonthBody[]
}

export interface ProfitLossMemberWorkMonthBody {
  month?: string
  manMonth?: number
  manualEdited?: boolean
}

export interface UnitPriceForMonth {
  month: string
  unitPrice: number
}

export interface ProfitLossMemberRow
  extends ProfitLossRowBase<ProfitLossMemberRowBody> {
  unitPrices?: UnitPriceForMonth[]
}

export class ProfitLossMemberRowBody extends ProfitLossRowBodyBase {
  user: UserDetail | undefined
  position: PositionProps | undefined
  monthlyValues: ProfitLossMemberRowMonthlyValueBody[]

  constructor(src?: ProfitLossMemberSkeleton, type?: string) {
    super(src, type)
    this.user = src?.user
    this.position = src?.position
    this.monthlyValues = []
  }
}

export class ProfitLossMemberRowMonthlyValueBody {
  yearMonth?: string
  value?: number
  manualEdited?: boolean

  constructor(yearMonth?: string, value?: number, manualEdited?: boolean) {
    this.yearMonth = yearMonth
    this.value = value
    this.manualEdited = manualEdited ?? false
  }
}

export const fetchProfitLossMembers = async (params: {
  projectUuid: string
  startDate: string
  endDate: string
  accountCategory: string
}): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/profit_loss/members', params)
}

export interface ProfitLossMembersUpdateParams {
  uuid: string | undefined
  lockVersion: number
  projectUuid: string
  startDate: string
  endDate: string
  accountCategory: string
  profitLossMembers: ProfitLossMemberInput[]
}

export interface ProfitLossMemberInput {
  uuid: string
  financialStatementUuid: string
  generalLedgerUuid: string
  subsidiaryUuid: string | undefined
  userUuid: string
  prevSiblingUuid: string | undefined
  profitLossItemAmounts: ProfitLossItemAmountInput[]
  actualWorkMonths: ProfitLossMemberActualWorkMonthInput[]
}

export interface ProfitLossMemberActualWorkMonthInput {
  year: number
  month: number
  manMonth: number
  manualEdited: boolean
}

export interface ProfitLossItemAmountInput {
  budgetResultType: string
  year: number
  month: number
  amount: number
  manualEdited: boolean
}

export const updateMembers = async (
  params: ProfitLossMembersUpdateParams
): Promise<APIResponse> => {
  return api.functional.request(
    'POST',
    '/api/v1/profit_loss/members/batch',
    params
  )
}

export const createNewProfitLossMemberRow = (
  financialState: LedgerAccountsTree | undefined
) => {
  const rootUuid = generateUuid()
  const amountBudgetUuid = generateUuid()
  const amountResultUuid = generateUuid()
  const workMonthScheduleUuid = generateUuid()
  const workMonthActualUuid = generateUuid()

  const rootRow: ProfitLossMemberRow = {
    uuid: rootUuid,
    added: true,
    body: new ProfitLossMemberRowBody({
      financialState: financialState,
    } as unknown as ProfitLossMemberSkeleton),
    treeValue: [rootUuid],
  }

  const workMonthScheduleRow: ProfitLossMemberRow =
    createNewProfitLossMemberChildRow(
      rootUuid,
      workMonthScheduleUuid,
      financialState,
      ProfitLossMemberRowType.WorkMonthSchedule
    )

  const workMonthActualRow: ProfitLossMemberRow =
    createNewProfitLossMemberChildRow(
      rootUuid,
      workMonthActualUuid,
      financialState,
      ProfitLossMemberRowType.WorkMonthActual
    )

  const amountBudgetRow: ProfitLossMemberRow =
    createNewProfitLossMemberChildRow(
      rootUuid,
      amountBudgetUuid,
      financialState,
      ProfitLossMemberRowType.AmountBudget
    )

  const amountResultRow: ProfitLossMemberRow =
    createNewProfitLossMemberChildRow(
      rootUuid,
      amountResultUuid,
      financialState,
      ProfitLossMemberRowType.AmountResult
    )

  return [
    rootRow,
    workMonthScheduleRow,
    workMonthActualRow,
    amountBudgetRow,
    amountResultRow,
  ]
}

const createNewProfitLossMemberChildRow = (
  rootUuid: string,
  childUuid: string,
  financialState: LedgerAccountsTree | undefined,
  type: string
): ProfitLossMemberRow => {
  return {
    uuid: childUuid,
    added: true,
    body: new ProfitLossMemberRowBody(
      {
        financialState: financialState,
      } as unknown as ProfitLossMemberSkeleton,
      type
    ),
    treeValue: [rootUuid, childUuid],
  }
}

export const fetchResourcePlanMemberWorkMonth = async (params: {
  projectUuid: string
  userUuid: string
  startDate: string
  endDate: string
}): Promise<APIResponse> => {
  return api.functional.request(
    'GET',
    '/api/v1/projects/resource_plans/memberWorkMonths',
    params
  )
}
