import React from 'react'
import { styled } from '@mui/system'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import * as validator from '../../../../lib/validator'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { FontSize } from '../../../../../../../../styles/commonStyles'
import { muiTheme } from '../../../../../../../../styles/muiTheme'
import { Tooltip } from '@mui/material'

// Styles
const theme = muiTheme
const RootDiv = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
})
const StyledTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    fontSize: FontSize.MEDIUM,
    maxWidth: 465,
    padding: 10,
  },
})
const StyledTextField = styled(TextField)({
  display: 'flex',
  height: '100%',
  width: '100%',
  '& .MuiInputBase-root': {
    height: '100%',
    width: '100%',
    padding: '6px',
  },
  '& .MuiInputBase-input': {
    maxHeight: '100% !important',
    fontSize: '12px !important',
    display: 'block !important',
    margin: 'auto',
    overflow: 'hidden',
    padding: '0 !important',
  },
  '& .Mui-error': {
    backgroundColor: validator.inputErrorColor,
  },
  '& .Mui-disabled': {
    color: theme.palette.common.black,
  },
  '& .MuiFormHelperText-error': {
    fontSize: FontSize.MEDIUM,
    textAlign: 'left',
    color: '#ffffff !important',
    background: '#696969',
    position: 'absolute',
    lineHeight: '24px',
    padding: '3px',
    borderRadius: '4px',
    border: '1px solid #696969',
    top: '35px',
  },
})

interface OwnProps {
  value: string
  data: any
  uiMeta: FunctionProperty
  viewMeta: ViewMeta
  inputRef?: React.RefObject<HTMLInputElement>
  setError?: Function
  enableTooltip?: boolean
}

export type Props = OwnProps & Omit<TextFieldProps, 'sx'>

interface State {
  hasError: boolean
  message?: string
  openTooltip: boolean
}

const Underlying = React.memo(StyledTextField)

class RichTextField extends React.PureComponent<Props, State> {
  ref: HTMLDivElement | null = null
  editorRef: HTMLDivElement | null = null
  inputRef = this.props.inputRef
  state: State = {
    hasError: false,
    openTooltip: false,
  }

  componentDidMount() {
    this.validate(this.props.value)
    if (this.inputRef && !this.props.disabled) {
      this.inputRef.current.addEventListener('change', this.changed)
    }
    setTimeout(() => {
      if (
        this.inputRef &&
        this.inputRef.current &&
        this.inputRef.current.style
      ) {
        this.inputRef.current.style.height =
          this.inputRef?.current.scrollHeight + 'px'
      }
    }, 0)
  }

  componentDidUpdate() {
    this.validate(this.props.value)
  }

  private validate = (value: string) => {
    const { data, uiMeta } = this.props
    const message = uiMeta
      ? validator.validate(value, data, uiMeta, this.props.viewMeta)
      : undefined
    this.setState(
      {
        hasError: !!message,
        message,
      },
      () => {
        this.props.setError && this.props.setError(this.state.hasError)
      }
    )
  }

  private changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.validate(e.target.value)
  }

  private setOpenTooltip = (open: boolean) => {
    this.props.enableTooltip &&
      this.state.hasError &&
      this.setState({ openTooltip: open })
  }

  render() {
    const { uiMeta, viewMeta, setError, ...underlyingProps } = this.props
    return (
      <StyledTooltip
        title={this.state.message || ''}
        placement="top"
        open={this.state.openTooltip}
      >
        <RootDiv
          className={`column-${this.props.uiMeta?.name}`}
          ref={ref => (this.ref = ref)}
          onMouseOver={() => this.setOpenTooltip(true)}
          onMouseLeave={() => this.setOpenTooltip(false)}
        >
          <Underlying
            {...underlyingProps}
            variant="standard"
            error={this.state.hasError}
            helperText={this.state.message}
            fullWidth={true}
            multiline={true}
            ref={ref => (this.editorRef = ref)}
            inputRef={this.inputRef}
            InputProps={
              {
                disableUnderline: true,
              } as Partial<OutlinedInputProps>
            }
            autoFocus={true}
          />
        </RootDiv>
      </StyledTooltip>
    )
  }
}

export default RichTextField
