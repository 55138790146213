import { useCallback } from 'react'

export type ValueDiffType = 'NEUTRAL' | 'POSITIVE' | 'NEGATIVE'
export type FormattedValueDiff = {
  type: ValueDiffType
  formatted: string
}
type DiffFormatter = (v: number) => FormattedValueDiff
export const useDiffFormatter = (
  formatValue: (v: number) => string
): [DiffFormatter, DiffFormatter] => {
  const formatDiff = useCallback(
    (v: number): FormattedValueDiff => {
      if (v > 0) {
        return { type: 'POSITIVE', formatted: `+${formatValue(Math.abs(v))}` } // v = 0.01 -> 0.0
      }
      if (v < 0) {
        return { type: 'NEGATIVE', formatted: `-${formatValue(Math.abs(v))}` }
      }
      return { type: 'NEUTRAL', formatted: `±${formatValue(Math.abs(v))}` }
    },
    [formatValue]
  )
  const formatDiffInverted = useCallback(
    (v: number): FormattedValueDiff => {
      if (v > 0) {
        return { type: 'NEGATIVE', formatted: `+${formatValue(Math.abs(v))}` }
      }
      if (v < 0) {
        return { type: 'POSITIVE', formatted: `-${formatValue(Math.abs(v))}` }
      }
      return { type: 'NEUTRAL', formatted: `±${formatValue(Math.abs(v))}` }
    },
    [formatValue]
  )
  return [formatDiff, formatDiffInverted]
}
