import { useCallback, useEffect, useState } from 'react'
import {
  getCumulation,
  ProjectPlanCumulation,
} from '../../../../../lib/functions/projectPlan'
import { Progress } from '../../../ProjectPlanNew/gridOptions/cumulation'
import _ from 'lodash'
import { ProjectDetail } from '../../../../../lib/functions/project'
import {
  AggregateField,
  AggregateTarget,
} from '../../../../../domain/entity/WbsItemEntity'
import { AggregateRoot } from '../../hooks/useMasterScheduleParameter'
import API from '../../../../../lib/commons/api'
import DateVO from '../../../../../vo/DateVO'
import { getResourceSummary } from '../../../../../lib/functions/resourcePlanNew'

export const useSummaryData = ({
  project,
  aggregateTarget,
  aggregateField,
  aggregateRoot,
}: {
  project: ProjectDetail
  aggregateTarget: AggregateTarget
  aggregateField: AggregateField
  aggregateRoot: AggregateRoot | undefined
}): {
  progress: Progress | undefined
  periodicProgressRate: number
  resourceTotal: number
  resourceConsumed: number
  totalCost: number
  actualRecordedCost: number
  criticalRiskCount: number
  fetching: boolean
} => {
  const [progress, setProgress] = useState<Progress>()
  const [periodicProgressRate, setPeriodicProgressRate] = useState<number>(0)
  const [resourceTotal, setResourceTotal] = useState<number>(0)
  const [resourceConsumed, setResourceConsumed] = useState<number>(0)
  const [totalCost, setTotalCost] = useState<number>(0)
  const [actualRecordedCost, setActualRecordedCost] = useState<number>(0)
  const [criticalRiskCount, setCriticalRiskCount] = useState<number>(0)

  const [cumulation, setCumulation] = useState<ProjectPlanCumulation>()
  const [fetching, setFetching] = useState(true)

  const endFetching = _.debounce(() => {
    setFetching(false)
  }, 300)

  const fetch = useCallback(async () => {
    setFetching(true)
    // Cumulation
    const response = await getCumulation(project.uuid, aggregateRoot?.uuid)
    if (response.json.projectPlanUuid) {
      setCumulation(response.json)
    }
    // Periodic progress rate
    const today = DateVO.now()
    const start = new DateVO(project.scheduledDate.startDate)
    const end = new DateVO(project.scheduledDate.endDate)
    setPeriodicProgressRate(start.diff(today) / start.diff(end))

    // Resource
    const resourceResponse = await getResourceSummary({
      projectUuid: project.uuid,
    })
    const resourceSummary = resourceResponse.json
    setResourceTotal(resourceSummary?.total ?? 0)
    setResourceConsumed(resourceSummary?.consumed ?? 0)

    // Cost
    const costResponse = await API.functional.request(
      'GET',
      '/api/v1/profit_loss_items/report/summary',
      { projectUuid: project.uuid }
    )
    setTotalCost(costResponse.json.totalCost)
    setActualRecordedCost(costResponse.json.actualRecordedCost)

    // Risk
    const riskResponse = await API.functional.request(
      'GET',
      '/api/v1/projects/risks/list/all',
      { projectUuid: project.uuid }
    )
    setCriticalRiskCount(
      riskResponse.json.filter(v => 0.8 <= (v.score ?? 0)).length
    )

    endFetching()
  }, [project.uuid, aggregateRoot?.uuid])

  useEffect(() => {
    fetch()
  }, [fetch])

  useEffect(() => {
    if (!cumulation) return
    setProgress(
      Progress.of(cumulation, {
        aggregateTarget,
        aggregateField,
      })
    )
  }, [cumulation, aggregateTarget, aggregateField])

  return {
    progress,
    periodicProgressRate,
    resourceTotal,
    resourceConsumed,
    totalCost,
    actualRecordedCost,
    criticalRiskCount,
    fetching,
  }
}
