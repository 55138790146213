import BoolExpression from '../../../utils/boolExpression'
import store from '../../../store'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import { SubmitType } from '../../containers/meta/ViewMeta'
import { SingleSheetOptions } from '../../containers/SingleSheet'
import { TableData } from '../../containers/SingleSheet/DataTable'

class EntityExtensionData extends TableData {
  getCode() {
    return undefined
  }
  getName() {
    return undefined
  }
}

class EntityExtensionConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return dest
  }
  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    submitType: SubmitType
    delta?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return {
      before: initialData,
      after: dest,
      delta,
    }
  }
  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}

export default class EntityExtension extends SingleSheetOptions<EntityExtensionData> {
  converterSpec = new EntityExtensionConverterSpec()
  private entityType?: string
  private propertyLayout?: string

  setAuxiliaries = (auxiliaries: any) => {
    if (!auxiliaries) {
      return
    }
    this.entityType = auxiliaries.entityType
    this.propertyLayout = auxiliaries.propertyLayout
  }

  beforeUpdate = (data: any) => {
    return {
      ...data,
      entityType: this.entityType,
      groupUuid: store.getState().project.selected,
      propertyLayout: this.propertyLayout,
      requiredIf: BoolExpression.of(data.requiredIf).expression,
      editableIfC: BoolExpression.of(data.editableIfC).expression,
      editableIfU: BoolExpression.of(data.editableIfU).expression,
      hiddenIfC: BoolExpression.of(data.hiddenIfC).expression,
      hiddenIfU: BoolExpression.of(data.hiddenIfU).expression,
    }
  }
}
