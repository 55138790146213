import { styled } from '@mui/material'

export const SearchItemLabelHorizontalContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  margin: '15px 0 8px 0',
  flexWrap: 'wrap',
  alignContent: 'center',
})

export const SearchItemLabelContainerLabel = styled('div')({
  verticalAlign: 'middle',
  margin: 'auto 0',
  whiteSpace: 'nowrap',
})
