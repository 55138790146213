import {
  PropsWithChildren,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useRef,
  DragEvent,
} from 'react'
import {
  BoundDimensions,
  MarginDimensions,
  TwoDimensionalPoint,
  WrapperDimensions,
} from './model/chart'
import * as d3 from 'd3'
import { colorPalette } from '../../style/colorPallete'
import { intl } from '../../../i18n'

type ChartContainerProps = PropsWithChildren<
  WrapperDimensions &
    Pick<MarginDimensions, 'marginLeft' | 'marginTop'> &
    EventListeners
>
export type ChartEventListener = (
  point: { x: number; y: number } | undefined,
  boundingClientRect: DOMRect | undefined
) => void
type EventListeners = {
  onHover?: ChartEventListener
  onClick?: ChartEventListener
}

export const ChartContainer = memo(
  ({
    width,
    height,
    marginLeft,
    marginTop,
    children,
    onHover,
    onClick: _onClick,
  }: ChartContainerProps) => {
    const [boundingClientRect, setBoundingClientRect] = useState<
      DOMRect | undefined
    >()
    const [point, setPoint] = useState<{ x: number; y: number } | undefined>()
    const ref = useRef<SVGSVGElement | null>(null)
    const onMouseMove = useCallback(
      (e: MouseEvent<SVGSVGElement>) => {
        if (!onHover) return
        const rect = {
          width: 0,
          height: 0,
          top: e.clientY,
          right: e.clientX,
          bottom: e.clientY,
          left: e.clientX,
          x: e.clientX,
          y: e.clientY,
        }
        const toJSON = () => JSON.stringify(rect)
        setBoundingClientRect({
          ...rect,
          toJSON,
        })
        setPoint({
          x:
            e.clientX -
            (ref.current?.getBoundingClientRect().left || 0) -
            marginLeft,
          y:
            e.clientY -
            (ref.current?.getBoundingClientRect().top || 0) -
            marginTop,
        })
      },
      [marginLeft, marginTop, onHover]
    )
    const onMouseLeave = useCallback(() => {
      if (!onHover) return
      setPoint(undefined)
    }, [onHover])
    useEffect(() => {
      onHover && onHover(point, boundingClientRect)
    }, [boundingClientRect, onHover, point])
    const onClick = useCallback(
      (e: MouseEvent<SVGSVGElement>) => {
        _onClick && _onClick(point, boundingClientRect)
      },
      [_onClick, boundingClientRect, point]
    )
    return (
      <svg
        ref={ref}
        width={width}
        height={height}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      >
        <g transform={`translate(${[marginLeft, marginTop].join(',')})`}>
          {children}
        </g>
      </svg>
    )
  }
)

type AxisPoint = {
  scale: number
  label: string
  emphasis?: boolean
}

type XAxisProps = {
  points: AxisPoint[]
} & BoundDimensions

export const XAxis = ({ points, boundedHeight, boundedWidth }: XAxisProps) => {
  return (
    <g
      transform={`translate(${[0, boundedHeight].join(',')})`}
      color={colorPalette.monotone[3]}
    >
      <path
        d={['M', 0, 6, 'v', -6, 'H', boundedWidth, 'v', 6].join(' ')}
        fill="none"
        stroke={'currentColor'}
      />
      {points.map((p, i) => (
        <g key={i} transform={`translate(${[p.scale, 0].join(',')})`}>
          <line y2={6} stroke="currentColor" />
          <text
            style={{
              fontSize: '13px',
              textAnchor: 'middle',
              transform: 'translateY(20px)',
              fill: colorPalette.monotone[5],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type XAxisWithLineProps = XAxisProps & {}

export const XAxisWithLine = ({
  points,
  boundedHeight,
  boundedWidth,
}: XAxisWithLineProps) => {
  return (
    <g
      transform={`translate(${[0, boundedHeight].join(',')})`}
      color={colorPalette.monotone[3]}
    >
      <path
        d={['M', 0, 6, 'v', -6, 'H', boundedWidth, 'v', 6].join(' ')}
        fill="none"
        stroke={'currentColor'}
      />
      {points.map((p, i) => (
        <g key={i} transform={`translate(${[p.scale, 0].join(',')})`}>
          <line y2={6} stroke="currentColor" />
          <line
            y2={-boundedHeight}
            stroke={
              p.emphasis ? colorPalette.pink[5] : colorPalette.monotone[2]
            }
          />
          <text
            style={{
              fontSize: '13px',
              textAnchor: 'middle',
              transform: 'translateY(20px)',
              fill: colorPalette.monotone[5],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type YAxisProps = {
  points: AxisPoint[]
}

export const YAxis = ({ points }: YAxisProps) => {
  return (
    <g>
      {points.map((p, i) => (
        <g
          key={i}
          transform={`translate(0,${p.scale})`}
          color={colorPalette.monotone[3]}
        >
          <line x2="-6" stroke="currentColor" />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'end',
              transform: 'translate(-8px, 3px)',
              fill: colorPalette.monotone[5],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type YAxisWithLineProps = YAxisProps & Pick<BoundDimensions, 'boundedWidth'>

export const YAxisWithLine = ({ points, boundedWidth }: YAxisWithLineProps) => {
  return (
    <g>
      {points.map((p, i) => (
        <g
          key={i}
          transform={`translate(0,${p.scale})`}
          color={colorPalette.monotone[3]}
        >
          <line x2="-6" stroke="currentColor" />
          <line
            x2={boundedWidth}
            stroke={
              p.emphasis ? colorPalette.pink[5] : colorPalette.monotone[2]
            }
            strokeDasharray={'1, 1'}
          />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'end',
              transform: 'translate(-8px, 3px)',
              fill: colorPalette.monotone[5],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type VerticalLineProps = {
  x: number
  boundedHeight: number
  color?: string
}
export const VerticalLine = ({
  x,
  boundedHeight,
  color = colorPalette.pink[5],
}: VerticalLineProps) => {
  return (
    <g style={{ transform: `translate(${x}px,0px)` }}>
      <line
        x1={0}
        x2={0}
        y1={boundedHeight}
        y2={10}
        stroke={color}
        strokeWidth="2px"
      />
    </g>
  )
}

type VerticalLineWithLabelProps = VerticalLineProps & {
  label: string
  y: number
  boundedWidth: number
}
export const VerticalLineWithLabel = ({
  label,
  x,
  y,
  boundedHeight,
  boundedWidth,
  ...others
}: VerticalLineWithLabelProps) => {
  const styles = useMemo(
    () => ({
      boxStyle: {
        borderRadius: 8,
        backgroundColor: colorPalette.monotone[1],
        dropShadow: `0px 4px 16px ${colorPalette.monotone[4]}80`,
      },
      textStyle: {
        color: colorPalette.monotone[5],
        fontSize: 13,
      },
    }),
    []
  )
  return (
    <g>
      <VerticalLine x={x} boundedHeight={boundedHeight} {...others} />
      <ChartPopper
        x={x}
        y={y}
        boundedHeight={boundedHeight}
        boundedWidth={boundedWidth}
        offsetX={15}
        offsetY={15}
      >
        <ChartTextBox
          label={label}
          position="right"
          width={56}
          height={21}
          {...styles}
        />
      </ChartPopper>
    </g>
  )
}

type ChartPopperProps = PropsWithChildren<{
  x: number
  y: number
  boundedWidth: number
  boundedHeight: number
  offsetX?: number
  offsetY?: number
}>
const ChartPopper = ({
  x,
  y,
  boundedWidth,
  boundedHeight,
  offsetX = 0,
  offsetY = 0,
  children,
}: ChartPopperProps) => {
  const ref = useRef<SVGGElement>(null)
  const translateX = useMemo(() => {
    const elemWidth = ref.current?.getBoundingClientRect()?.width || 0
    if (x + offsetX > boundedWidth) return boundedWidth - elemWidth
    if (x + elemWidth + offsetX > boundedWidth) {
      return x - offsetX - elemWidth
    }
    return x + offsetX
  }, [boundedWidth, offsetX, x])
  const translateY = useMemo(() => {
    const elemHeight = ref.current?.getBoundingClientRect()?.height || 0
    if (y + offsetY > boundedHeight) return boundedHeight - elemHeight
    if (y + elemHeight + offsetY > boundedHeight) {
      return y - offsetY - elemHeight
    }
    return y + offsetY
  }, [boundedHeight, offsetY, y])
  const positioningStyle = useMemo(() => {
    return {
      transform: `translate(${translateX}px,${translateY}px)`,
    }
  }, [translateX, translateY])
  return (
    <g ref={ref} style={positioningStyle}>
      {children}
    </g>
  )
}

const X_AXIS_CHIP_HEIGHT = 28
const X_AXIS_CHIP_WIDTH = 48
type XAxisChipProps = {
  x: number
  boundedHeight: number
  label: string
  color?: string
  offset?: number
}
const XAxisChip = ({
  x,
  boundedHeight,
  label,
  color = colorPalette.monotone[2],
  offset = 0,
}: XAxisChipProps) => {
  return (
    <g
      style={{
        transform: `translate(${x + offset}px,${boundedHeight + 30}px)`,
      }}
    >
      <ChartChip
        label={label}
        position={'bottom'}
        width={X_AXIS_CHIP_WIDTH}
        height={X_AXIS_CHIP_HEIGHT}
        color={color}
      />
    </g>
  )
}

export const VerticalLineWithXAxisChip = ({
  x,
  boundedHeight,
  label,
  color = colorPalette.monotone[10],
  offset,
}: VerticalLineProps & XAxisChipProps) => {
  const [hovered, setHovered] = useState<boolean>(false)
  const onMouseEnter = useCallback(() => setHovered(true), [])
  const onMouseLeave = useCallback(() => setHovered(false), [])
  const styles = useMemo(
    () => ({
      boxStyle: {
        borderRadius: 2,
        backgroundColor: colorPalette.monotone[1],
        dropShadow: `0px 2px 6px ${colorPalette.monotone[4]}60`,
      },
      textStyle: {
        color: colorPalette.monotone[5],
        fontSize: 13,
      },
    }),
    []
  )
  return (
    <>
      <VerticalLine x={x} boundedHeight={boundedHeight} color={color} />
      <g onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <XAxisChip
          x={x}
          boundedHeight={boundedHeight}
          label={label}
          color={color}
          offset={offset}
        />
      </g>
      {hovered && (
        <g
          style={{
            transform: `translate(${x}px,${boundedHeight + 18}px)`,
          }}
        >
          <ChartTextBox
            label={label}
            position="top"
            width={56}
            height={21}
            {...styles}
          />
          <path d="M-6 0 l6 8 l6 -8 z" fill={colorPalette.monotone[1]} />
        </g>
      )}
    </>
  )
}

const ARROW_EDGE_LENGTH = 16
type OutOfBoundIndicatorChipProps = {
  boundedWidth: number
  boundedHeight: number
  position: 'right' | 'left'
  label: string
  color?: string
  offset?: number
}
export const OutOfBoundIndicatorChip = ({
  boundedWidth,
  boundedHeight,
  position,
  label,
  color = colorPalette.monotone[2],
  offset = 0,
}: OutOfBoundIndicatorChipProps) => {
  const [x, y] = useMemo(
    () => [position === 'right' ? boundedWidth : 0, boundedHeight / 2 + offset],
    [boundedHeight, boundedWidth, offset, position]
  )
  const arrowD = useMemo(() => {
    const initialX = position === 'right' ? -1 : 1
    const deltaY = ARROW_EDGE_LENGTH / 2
    const deltaXSign = position === 'right' ? 1 : -1
    const deltaX = deltaXSign * deltaY * Math.sqrt(3)
    return `M${initialX},${-deltaY}l${deltaX},${deltaY}l${-deltaX},${deltaY}z`
  }, [position])
  const chipPosition = useMemo(() => {
    return position === 'left' ? 'right' : 'left'
  }, [position])
  return (
    <g
      style={{
        transform: `translate(${x}px,${y}px)`,
      }}
    >
      <ChartChip
        label={label}
        position={chipPosition}
        width={X_AXIS_CHIP_WIDTH}
        height={X_AXIS_CHIP_HEIGHT}
        color={color}
      />
      <path d={arrowD} fill={color} />
    </g>
  )
}

type ChartChipProps = {
  label: string
  position: 'top' | 'right' | 'bottom' | 'left'
  width: number
  height: number
  color: string
}
const ChartChip = ({ width, height, color, ...others }: ChartChipProps) => {
  const r = useMemo(() => {
    const minEdgeLength = Math.min(width, height)
    return minEdgeLength / 2
  }, [height, width])
  const styles = useMemo(
    () => ({
      boxStyle: {
        borderRadius: r,
        borderColor: color,
        borderWidth: 2,
        backgroundColor: colorPalette.monotone[0],
      },
      textStyle: {
        fontSize: 12,
        fontWeight: 600,
        color,
      },
    }),
    [color, r]
  )
  return <ChartTextBox {...others} width={width} height={height} {...styles} />
}

type ChartTextBoxProps = {
  label: string
  position: 'top' | 'right' | 'bottom' | 'left'
  width: number
  height: number
  boxStyle?: {
    borderRadius?: number
    borderColor?: string
    borderWidth?: number
    backgroundColor?: string
    dropShadow?: string
  }
  textStyle?: {
    fontSize?: number
    fontWeight?: number
    color?: string
  }
}
const ChartTextBox = ({
  label,
  position,
  width,
  height,
  boxStyle,
  textStyle,
}: ChartTextBoxProps) => {
  const [rectX, rectY, textX, textY] = useMemo(() => {
    switch (position) {
      case 'top':
        return [-width / 2, -height, 0, -height / 2]
      case 'right':
        return [0, -height / 2, width / 2, 0]
      case 'bottom':
        return [-width / 2, 0, 0, height / 2]
      case 'left':
        return [-width, -height / 2, -width / 2, 0]
    }
  }, [height, position, width])
  const rectStyleProps = useMemo(() => {
    const {
      borderRadius: r = 0,
      borderColor: stroke = undefined,
      borderWidth: strokeWidth = undefined,
      backgroundColor: fill = colorPalette.monotone[0],
      dropShadow = undefined,
    } = boxStyle || {}
    const style = dropShadow
      ? {
          filter: `drop-shadow(${dropShadow})`,
        }
      : {}
    return {
      rx: r,
      ry: r,
      stroke,
      strokeWidth,
      fill,
      style,
    }
  }, [boxStyle])
  const textStyleProps = useMemo(() => {
    const {
      fontSize = 12,
      fontWeight = 400,
      color: fill = colorPalette.monotone[5],
    } = textStyle || {}
    return {
      fontSize: `${fontSize}px`,
      fontWeight,
      fill,
    }
  }, [textStyle])
  return (
    <g>
      <rect
        x={rectX}
        y={rectY}
        width={`${width}px`}
        height={`${height}px`}
        {...rectStyleProps}
      />
      <text
        x={textX}
        y={textY}
        style={{
          ...textStyleProps,
          textAnchor: 'middle',
          dominantBaseline: 'central',
        }}
      >
        {label}
      </text>
    </g>
  )
}

export type VerticalLineBaseProps = {
  x: number
  color: string
  label: string
}
type VerticalLineGroupProps = {
  lines: VerticalLineBaseProps[]
  boundedWidth: number
  boundedHeight: number
}
export const VerticalLineGroup = ({
  lines,
  boundedHeight,
  boundedWidth,
}: VerticalLineGroupProps) => {
  const { displayableLines, outOfBoundIndicators } = useMemo(() => {
    const displayableLines = lines
      .filter(l => l.x >= 0 && l.x <= boundedWidth)
      .map((l, _, arr) => {
        const sameXArray = arr.filter(a => a.x === l.x)
        const baselineIndex = Math.floor((sameXArray.length - 1) / 2)
        const ownIndex = sameXArray.findIndex(a => a.label === l.label)
        return {
          ...l,
          offset: (ownIndex - baselineIndex) * 12,
        }
      })
    const outOfBoundIndicators = lines
      .filter(l => l.x < 0 || l.x > boundedWidth)
      .map(l => {
        const position = l.x < 0 ? 'left' : 'right'
        return {
          ...l,
          position,
        }
      })
      .map((l, _, arr) => {
        const samePositionArray = arr.filter(a => a.position === l.position)
        const baselineIndex = Math.floor((samePositionArray.length - 1) / 2)
        const ownIndex = samePositionArray.findIndex(a => a.label === l.label)
        return {
          ...l,
          offset: (ownIndex - baselineIndex) * 36,
        } as Omit<
          OutOfBoundIndicatorChipProps,
          'boundedWidth' | 'boundedHeight'
        >
      })
    return {
      displayableLines,
      outOfBoundIndicators,
    }
  }, [boundedWidth, lines])
  return (
    <g>
      {displayableLines.map((line, i) => (
        <VerticalLineWithXAxisChip
          key={`vertical-line-${i}`}
          {...line}
          boundedHeight={boundedHeight}
        />
      ))}
      {outOfBoundIndicators.map((line, i) => (
        <OutOfBoundIndicatorChip
          key={`out-of-bound-indicator-${i}`}
          {...line}
          boundedHeight={boundedHeight}
          boundedWidth={boundedWidth}
        />
      ))}
    </g>
  )
}

type GraphEventListenerAreaProps = {
  onClick?: ChartEventListener
  onHover?: ChartEventListener
} & BoundDimensions
export const GraphEventListenerLayer = ({
  boundedHeight,
  boundedWidth,
  onClick: _onClick,
  onHover,
}: GraphEventListenerAreaProps) => {
  const [boundingClientRect, setBoundingClientRect] = useState<
    DOMRect | undefined
  >()
  const [point, setPoint] = useState<{ x: number; y: number } | undefined>()
  const ref = useRef<SVGRectElement | null>(null)
  const onMouseMove = useCallback(
    (e: MouseEvent<SVGRectElement>) => {
      if (!onHover) return
      const rect = {
        width: 0,
        height: 0,
        top: e.clientY,
        right: e.clientX,
        bottom: e.clientY,
        left: e.clientX,
        x: e.clientX,
        y: e.clientY,
      }
      const toJSON = () => JSON.stringify(rect)
      setBoundingClientRect({
        ...rect,
        toJSON,
      })
      setPoint({
        x: e.clientX - (ref.current?.getBoundingClientRect().left || 0),
        y: e.clientY - (ref.current?.getBoundingClientRect().top || 0),
      })
    },
    [onHover]
  )
  const onMouseLeave = useCallback(() => {
    if (!onHover) return
    setPoint(undefined)
  }, [onHover])
  useEffect(() => {
    onHover && onHover(point, boundingClientRect)
  }, [boundingClientRect, onHover, point])
  const onClick = useCallback(
    (e: MouseEvent<SVGRectElement>) => {
      _onClick && _onClick(point, boundingClientRect)
    },
    [_onClick, boundingClientRect, point]
  )
  return (
    <rect
      width={boundedWidth}
      height={boundedHeight}
      fill="transparent"
      ref={ref}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    />
  )
}
