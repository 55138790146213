import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { Box, styled } from '@mui/material'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterFooter } from '../common'
import { useEntitySearchFilter } from './useEntitySearchFilter'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'
import { ReferencedEntityWithIcon } from '../../../../../../domain/value-object/ReferencedEntity'
import {
  EntitySearchFilter,
  EntitySearchFilterType,
  getEntitySearchFilterTypeLabel,
} from './EntitySearchFilterModel'
import { FilterAutocomplete } from './FilterAutocomplete'
import { SelectedValues } from './SelectedValues'
import { FilterTypeSelect } from './FilterTypeSelect'

type Props = IFilterParams & {
  searchOptions: (
    params: IFilterParams,
    text?: string
  ) => Promise<ReferencedEntityWithIcon[]>
  fetch: (v: EntitySearchFilter) => Promise<APIResponse>
}

export * from './EntitySearchFilterModel'
export const ServerSideEntitySearchFilter = forwardRef((params: Props, ref) => {
  const {
    searchOptions: _searchOptions,
    fetch,
    filterChangedCallback,
    context,
  } = params
  const hasRequiredSaveData = useSelector<AllState>(
    state => state.hasRequiredSaveData.hasRequiredSaveData
  )
  const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

  // State
  const filter = useEntitySearchFilter()
  const [loading, isLoading] = useState(false)
  const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
    undefined
  )

  const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
  useEffect(() => {
    if (addedRowIds.current) return
    addedRowIds.current = []
  }, [])
  const fetchFilteredIds = async (v: EntitySearchFilter) => {
    const response = await fetch(v)
    setFilteredIds(response.json)
  }
  const search = useCallback(
    _.debounce(async (v: EntitySearchFilter) => {
      try {
        isLoading(true)
        await fetchFilteredIds(v)
      } finally {
        isLoading(false)
      }
    }, 300),
    []
  )
  const searchOptions = useCallback(
    async (text?: string) => {
      return _searchOptions(params, text)
    },
    [params, _searchOptions]
  )
  const onChange = useCallback((value: ReferencedEntityWithIcon[] | null) => {
    filter.setSelectedValues(value || [])
  }, [])

  // Ag-grid custom filter
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.added) {
          if (addedRowIds.current && params.node.id) {
            addedRowIds.current.push(params.node.id)
          }
          return true
        }
        if (
          addedRowIds.current &&
          params.node.id &&
          addedRowIds.current.includes(params.node.id)
        ) {
          return true
        }
        if (!filteredIds) return true
        return params.node.id && filteredIds.includes(params.node.id)
      },

      isFilterActive() {
        return !!filteredIds
      },

      getModel() {
        return filter.model()
      },

      setModel(model?: EntitySearchFilter) {
        if (!model) {
          filter.reset()
          return
        }
        filter.setFilterType(model.filterType)
        filter.setSelectedValues(model.selectedValues)
      },

      getModelAsString() {
        if (!filter.isActive()) return ''
        return getEntitySearchFilterTypeLabel(filter.filterType)
      },

      onNewRowsLoaded() {
        if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
        // refreshOptions()
        const filterModel = filter.model()
        if (filterModel) {
          fetchFilteredIds(filterModel)
        }
      },
    }
  })

  // Filter
  useEffect(() => {
    if (!filter.isActive()) {
      setFilteredIds(undefined)
      return
    }
    search(filter.model())
  }, [filter.filterType, filter.selectedValues])

  useEffect(() => {
    const filterModel = filter.model()
    const isAfterSavedOrRefreshed =
      prevHasRequiredSaveData && !hasRequiredSaveData
    if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
      fetchFilteredIds(filterModel).then(() => {
        addedRowIds.current = []
      })
    }
  }, [hasRequiredSaveData])

  useEffect(() => {
    filterChangedCallback()
  }, [filteredIds])

  return (
    <BulkSheetFilter>
      <FilterBox>
        <FilterTypeSelect
          filterType={filter.filterType}
          onChange={filter.setFilterType}
        />
        <SelectedValues
          selectedValues={filter.selectedValues}
          onChange={onChange}
        />
        <FilterAutocomplete
          searchOptions={searchOptions}
          value={filter.selectedValues}
          onChange={onChange}
          disabled={
            filter.filterType !== EntitySearchFilterType.SELECTED_VALUES
          }
        />
      </FilterBox>
      <FilterFooter loading={loading} onClick={() => filter.reset()} />
    </BulkSheetFilter>
  )
})
const FilterBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  margin: '0 3px',
})
