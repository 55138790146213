export const KEY_DEL: string = 'del'
export const KEY_ESC: string = 'esc'
export const KEY_SAVE: string = 'mod+s'
export const KEY_SPACE: string = 'space'
export const KEY_TAB: string = 'tab'

export type KeyBindListener = {
  key: string | string[]
  fn?: Function
  stopDefaultBehavior?: boolean
}

export type KeyBindListeners = KeyBindListener[]

export type AddKeyBindListeners = (listeners: KeyBindListeners) => void
export type RemoveKeyBindListeners = () => void
export type KeyBindHandlers = {
  addKeyBindListeners: AddKeyBindListeners
  removeKeyBindListeners: RemoveKeyBindListeners
}
