import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const customHeaderTemplate = (param: { tooltip: string }): string => {
  // https://www.ag-grid.com/javascript-grid-column-header/#header-templates

  return textHead + (param.tooltip ? helpIcon() : '') + textFoot
}

const helpIcon = () => {
  return ReactDOMServer.renderToString(
    <span
      className="ag-header-cell-label material-icons"
      style={{
        fontSize: '1rem',
        color: '#888',
        marginLeft: 3,
        maxWidth: '1rem',
      }}
    >
      help_outline
    </span>
  )
}

const textHead =
  '<div class="ag-cell-label-container" role="presentation">' +
  '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>'

const textFoot =
  '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
  '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
  '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
  '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
  '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
  '  </div>' +
  '</div>'
