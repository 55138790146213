import React from 'react'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import SelectVO from '../../../../../../vo/SelectVO'
import { CellDef } from '../../../../meta/ViewMeta/SingleSheetViewMeta'
import { TableData } from '../..'
import objects from '../../../../../../utils/objects'
import { filterByCombination } from '../../../../../../lib/commons/customEnum'
import equal from 'fast-deep-equal'
import ClearIcon from '@mui/icons-material/ClearRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import VOBase from '../../../../../../vo/base'
import Select from '../../../../../components/editors/select/Select'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})
const ClearIconButton = styled(IconButton)({
  padding: '4px',
  color: 'rgba(0, 0, 0, 0.54)',
  right: '20px',
  position: 'absolute',
})
const StyledClearIcon = styled(ClearIcon)({
  fontSize: '17px',
})

interface SelectCellProps extends CellPropsBase<SelectVO> {}

interface SelectCellState extends CellStateBase {
  options: SelectVO[]
  hovered: boolean
}

class SelectCell extends DataCellBase<
  SelectVO,
  SelectCellProps,
  SelectCellState
> {
  constructor(props) {
    super(props)
    this.state = { options: this.getOptions(), hovered: false }
  }
  private getOptions = (): SelectVO[] => {
    const getCombinedValue = (path: string) => {
      const value = objects.getValue(this.props.data, path)
      if (value instanceof VOBase) {
        return value.getValue()
      }
      return value
    }
    const filtered = filterByCombination(
      this.props.cellDef.uiMeta.valuesAllowed,
      getCombinedValue
    ).filter(option => option.value !== 'NONE')
    return filtered.map(option => SelectVO.fromCustomEnum(option))
  }
  private resetOptions = () => {
    this.setState({ options: this.getOptions() })
  }
  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    if (!equal(this.props.data, prevProps.data)) {
      this.resetOptions()
    }
  }
  validate = (value: SelectVO) => {
    return undefined
  }

  onChange = e => {
    this.props.onChange(
      SelectVO.of(e.target.value, this.props.cellDef.uiMeta.valuesAllowed)
    )
  }

  isDisabled = (cellDef: CellDef, data: TableData): boolean => {
    const editable = cellDef.editable
    let disable = false
    if (typeof editable === 'boolean') {
      disable = !editable
    } else {
      disable = editable(data)
    }
    return disable
  }

  clear = () => {
    this.props.onChange(undefined)
  }

  render() {
    const { value, data, cellDef } = this.props
    const disabled: boolean = this.isDisabled(cellDef, data)
    return (
      <RootDiv
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        <Select
          fullWidth={true}
          value={value ? value.getValue() : ''}
          disabled={disabled}
          onChange={this.onChange}
          IconComponent={ExpandMoreIcon}
          disableUnderline={true}
          options={this.state.options}
          getOptionIconUrl={o => o.iconUrl}
          getOptionLabel={o => o?.format()}
          getOptionValue={o => o?.getValue()}
          MenuProps={{ disablePortal: true }}
          variant="standard"
        />
        {this.state.hovered && this.props.value && !disabled && (
          <ClearIconButton onClick={this.clear}>
            <StyledClearIcon color="action" />
          </ClearIconButton>
        )}
      </RootDiv>
    )
  }
}

export default SelectCell
