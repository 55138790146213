import { Attachment, AttachmentCandidate } from '../../value-object/Attachment'
import { DateTermV2 } from '../../value-object/DateTermV2'
import { DateTimeV2 } from '../../value-object/DateTimeV2'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import {
  ReferencedEntity,
  ReferencedEntityWithBackgroundColor,
  ReferencedEntityWithIcon,
} from '../../value-object/ReferencedEntity'

/**
 * TODO: implement.
 * DO NOT USE this entity before implementation.
 */
export type WbsItemEntity = {
  projectUuid: string
  uuid: string
  code: string
  type: WbsItemType
  status: WbsItemStatus
  substatus?: string
  displayName: string
  description?: string
  tags?: ReferencedEntityWithBackgroundColor[]
  team?: ReferencedEntityWithIcon
  accountable?: ReferencedEntityWithIcon
  responsible?: ReferencedEntityWithIcon
  assignee?: ReferencedEntityWithIcon
  watchers?: ReferencedEntityWithIcon[]
  difficulty?: string
  // TODO: delete this.
  estimatedStoryPoint?: number
  estimatedHour?: number
  actualHour?: number
  priority?: string
  sprint?: ReferencedEntity
  scheduledDate?: DateTermV2
  actualDate?: DateTermV2
  deliverableAttachments?: Attachment[]
  createdBy: ReferencedEntityWithIcon
  createdAt: DateTimeV2
  updatedBy: ReferencedEntityWithIcon
  updatedAt: DateTimeV2
  revision: string
}

export const fromApiResponseToEntity = (src: any): WbsItemEntity => {
  return {
    projectUuid: src.projectUuid,
    uuid: src.uuid,
    code: src.code,
    type: WbsItemType[src.type],
    status: WbsItemStatus[src.status],
    substatus: src.substatus,
    displayName: src.displayName,
    description: src.description,
    tags: src.tags?.map(tag => ({
      uuid: tag.uuid,
      name: tag.name,
      backgroundColor: tag.backgroundColor,
    })),
    team: src.team && {
      uuid: src.team.uuid,
      name: src.team.displayName,
      iconUrl: src.team.iconUrl,
    },
    accountable: src.accountable && {
      uuid: src.accountable.uuid,
      name: src.accountable.name,
      iconUrl: src.accountable.iconUrl,
    },
    responsible: src.responsible && {
      uuid: src.responsible.uuid,
      name: src.responsible.name,
      iconUrl: src.responsible.iconUrl,
    },
    assignee: src.assignee && {
      uuid: src.assignee.uuid,
      name: src.assignee.name,
      iconUrl: src.assignee.iconUrl,
    },
    watchers:
      src.watchers &&
      src.watchers.map(watcher => ({
        uuid: watcher.uuid,
        name: watcher.name,
        iconUrl: watcher.iconUrl,
      })),
    difficulty: src.difficulty,
    estimatedStoryPoint: src.estimatedStoryPoint,
    estimatedHour: src.estimatedHour,
    actualHour: src.actualHour,
    priority: src.priority,
    sprint: src.sprint && {
      uuid: src.sprint.uuid,
      name: src.sprint.name,
    },
    scheduledDate: src.scheduledDate && {
      startDate:
        src.scheduledDate.startDate && new Date(src.scheduledDate.startDate),
      endDate: src.scheduledDate.endDate && new Date(src.scheduledDate.endDate),
    },
    actualDate: src.actualDate && {
      startDate: src.actualDate.startDate && new Date(src.actualDate.startDate),
      endDate: src.actualDate.endDate && new Date(src.actualDate.endDate),
    },
    deliverableAttachments: src.deliverableAttachments,
    createdBy: src.createdBy,
    createdAt: new Date(src.createdAt),
    updatedBy: src.updatedBy,
    updatedAt: new Date(src.updatedAt),
    revision: src.revision,
  }
}

export type WbsItemBasic = {
  projectUuid: string
  uuid: string
  type: WbsItemType
  ticketType?: string
}

export enum WbsItemType {
  WORKGROUP = 'WORKGROUP',
  PROCESS = 'PROCESS',
  DELIVERABLE_LIST = 'DELIVERABLE_LIST',
  DELIVERABLE = 'DELIVERABLE',
  TASK = 'TASK',
}

export enum WbsItemStatus {
  TODO = 'TODO',
  DOING = 'DOING',
  REVIEW = 'REVIEW',
  DONE = 'DONE',
  DISCARD = 'DISCARD',
}
export const toLabel = (src: WbsItemStatus) => {
  switch (src) {
    case WbsItemStatus.TODO:
      return 'ToDo'
    case WbsItemStatus.DOING:
      return 'Doing'
    case WbsItemStatus.REVIEW:
      return 'Review'
    case WbsItemStatus.DONE:
      return 'Done'
    case WbsItemStatus.DISCARD:
      return 'Discard'
  }
}

// TODO Move it
// Report
export type AggregateTarget = WbsItemType.DELIVERABLE | WbsItemType.TASK
export enum AggregateField {
  WBS_ITEM_COUNT = 'WBS_ITEM_COUNT',
  WBS_ITEM_WORKLOAD = 'WBS_ITEM_WORKLOAD',
}

export type UpdateWbsItemDeltaRequest = {
  input: UpdateWbsItemDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}

export type UpdateWbsItemDeltaInput = {
  uuid: string
  type: WbsItemType
  status?: IItemDelta<string>
  substatus?: IItemDelta<string>
  displayName?: IItemDelta<string>
  description?: IItemDelta<string>
  teamUuid?: IItemDelta<string>
  accountableUuid?: IItemDelta<string>
  responsibleUuid?: IItemDelta<string>
  assigneeUuid?: IItemDelta<string>
  difficulty?: IItemDelta<string>
  estimatedStoryPoint?: IItemDelta<number>
  estimatedHour?: IItemDelta<number>
  estimatedAmount?: IItemDelta<number>
  actualAmount?: IItemDelta<number>
  priority?: IItemDelta<string>
  sprintUuid?: IItemDelta<string>
  scheduledDate?: IItemDelta<DateTermV2>
  actualDate?: IItemDelta<DateTermV2>
}

export type UpdateWbsItemDeltaResult = {
  // TODO: implement
}

export type UpdateTagInput = {
  wbsItemUuid: string
  tagUuids: string[]
}

export type AddWbsItemAttachmentRequest = {
  wbsItemUuid: string
  attachmentType: 'DELIVERABLE'
  attachments: AttachmentCandidate[]
}
export type AddWbsItemAttachmentResult = {
  wbsItemUuid: string
}
export type DeleteWbsItemAttachmentRequest = {
  wbsItemUuid: string
  attachmentType: 'DELIVERABLE'
  attachmentItemUuids: string[]
}
export type DeleteWbsItemAttachmentResult = {
  wbsItemUuid: string
}
