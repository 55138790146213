import * as d3 from 'd3'
import { useMemo, useCallback, useState, useEffect } from 'react'
import {
  ChartContainer,
  GraphEventListenerLayer,
  VerticalLineBaseProps,
  VerticalLineGroup,
  VerticalLineWithLabel,
  XAxis,
  YAxisWithLine,
} from '../../../../components/charts'
import { LineGraph, LineNode } from '../../../../components/charts/LineChart'
import {
  DateBucket,
  DateBucketType,
} from '../../../../components/charts/model/timeSeries'
import { colorPalette } from '../../../../style/colorPallete'
import { useChartDimensions } from '../../../ProjectReport/hooks/chart/useChartDimensions'
import {
  useDateBucketFormatter,
  useDateTickFormatter,
} from '../../hooks/dateFormatter'
import { useBurndownChartXTicks } from '../../hooks/xTicks'
import {
  DateTerm,
  BurndownChartData,
  BurndownChartCondition,
  BurndownChartSummary,
} from '../../model'
import { ValueFormatters } from '../../hooks/valueFormatters'
import { intl } from '../../../../../i18n'
import { Checkbox } from '../../../../components/inputs/Checkbox'
import { useTooltip } from '../../hooks/tooltip'
import {
  GraphRoot,
  GraphManipulationArea,
  GraphLegendGroup,
  GraphLegend,
  GraphLegendColorDiv,
  GraphLegendText,
  GraphArea,
  BurndownChartSimulatedGraph,
  BurndownChartActualGraph,
  BurndownChartScheduleGraph,
  GraphLegendArea,
  BurndownChartProspectGraph,
  BurndownChartIdealGraph,
} from '.'
import { BurndownChartDiffDetail, useDiffDetail } from '../../hooks/diffDetail'
import { useDisplayGraph } from '../../hooks/displayGraph'
import { DateTermSelect } from './DateTermSelect'
import { TwoDimensionalPoint } from '../../../../components/charts/model/chart'
import { WeekDay } from '../../../../../domain/value-object/DayOfWeek'
import { useDateBucketHandlers } from '../../hooks/dateBuckets'

type BurndownChartGraphProps = {
  data: BurndownChartData
  diffDetail: BurndownChartDiffDetail
  dateBuckets: DateBucket[]
  dateBucketType: DateBucketType
  startDayOfWeek: WeekDay
  valueFormatters: ValueFormatters
  condition: BurndownChartCondition
  displayDateTerm: DateTerm
  updateDisplayDateTerm: (_: DateTerm) => void
  scheduledEndDate: Date | undefined
  deadlineDate: Date | undefined
  onSelect: (v: DateBucket) => void
}
export const BurndownChartGraph = ({
  data,
  diffDetail,
  dateBuckets,
  dateBucketType,
  startDayOfWeek,
  valueFormatters,
  condition,
  displayDateTerm,
  updateDisplayDateTerm,
  scheduledEndDate,
  deadlineDate,
  onSelect,
}: BurndownChartGraphProps) => {
  const {
    formatBucket,
    getBucketRepresentative,
    isBucketPassed,
    isBucketIncluded,
  } = useDateBucketHandlers(dateBucketType, displayDateTerm)
  // Draw graph
  const [
    ref,
    { width, height, marginTop, marginLeft, boundedWidth, boundedHeight },
  ] = useChartDimensions({
    marginLeft: 50,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 80,
  })
  const displayDateBuckets = useMemo(
    () => dateBuckets.filter(bucket => isBucketIncluded(bucket)),
    [dateBuckets, isBucketIncluded]
  )
  const displayData = useMemo(() => {
    const filterData = (data: TwoDimensionalPoint<DateBucket, number>[]) =>
      data.filter(d => isBucketIncluded(d.x))
    const scheduleData = filterData(data.scheduleDataBurnDown)
    const actualAndProspectData = filterData(data.actualAndProspectDataBurnDown)
    const actualData = actualAndProspectData.filter(d => isBucketPassed(d.x))
    const prospectData = actualAndProspectData.filter(
      (_, i) => i >= actualData.length - 1
    )
    const simulatedData = filterData(data.simulatedDataBurnDown)
    const idealData = filterData(data.idealDataBurnDown)
    return {
      scheduleData,
      actualData,
      prospectData,
      simulatedData,
      idealData,
    }
  }, [
    data.scheduleDataBurnDown,
    data.actualAndProspectDataBurnDown,
    data.simulatedDataBurnDown,
    data.idealDataBurnDown,
    isBucketIncluded,
    isBucketPassed,
  ])

  const { xMin, xMax } = useMemo(() => {
    const xMin = getBucketRepresentative(displayDateBuckets[0])
    const xMax = getBucketRepresentative(
      displayDateBuckets[displayDateBuckets.length - 1]
    )
    return { xMin, xMax }
  }, [displayDateBuckets, getBucketRepresentative])
  const { xTicks, tickIntervalType } = useBurndownChartXTicks(
    xMin,
    xMax,
    dateBucketType,
    startDayOfWeek,
    boundedWidth,
    30
  )
  const xScale = useMemo(
    () => d3.scaleTime().domain([xMin, xMax]).range([0, boundedWidth]),
    [boundedWidth, xMax, xMin]
  )
  const xScaleInverse = useMemo(() => {
    return d3
      .scaleTime()
      .domain([0, boundedWidth])
      .range([xMin.valueOf(), xMax.valueOf()])
  }, [boundedWidth, xMax, xMin])

  const dateBucketScale = useMemo(() => {
    return (bucket: DateBucket) => {
      return xScale(getBucketRepresentative(bucket))
    }
  }, [getBucketRepresentative, xScale])

  const { yScale, yTicks } = useMemo(() => {
    const yMax = Math.max(
      ...[
        ...displayData.scheduleData.map(d => d.y),
        ...displayData.actualData.map(d => d.y),
        ...displayData.prospectData.map(d => d.y),
        ...displayData.simulatedData.map(d => d.y),
        ...displayData.idealData.map(d => d.y),
      ]
    )
    const yScale = d3
      .scaleLinear()
      .domain([0, yMax * 1.1])
      .range([boundedHeight, 0])
    return {
      yScale,
      yTicks: yScale.ticks(10),
    }
  }, [boundedHeight, displayData])

  const { formatTick } = useDateTickFormatter(tickIntervalType)
  const xAxisPoints = useMemo(
    () =>
      xTicks
        .map(x => ({
          scale: xScale(x),
          label: formatTick(x),
        }))
        .filter(x => x.scale >= 0 && x.scale <= boundedWidth),
    [boundedWidth, formatTick, xScale, xTicks]
  )
  const yAxisPoints = useMemo(
    () =>
      yTicks.map(y => ({
        scale: yScale(y),
        label: `${y}`,
      })),
    [yScale, yTicks]
  )

  const { selected, hoveredAnchor, onHover, onClick } = useTooltip(
    displayDateBuckets,
    xScaleInverse,
    getBucketRepresentative,
    dateBucketType,
    startDayOfWeek
  )
  const hoveredVerticalLine = useMemo(
    () =>
      hoveredAnchor && {
        x: xScale(hoveredAnchor.x),
        y: hoveredAnchor.y,
        label: formatTick(hoveredAnchor.x),
        anchorEl: hoveredAnchor.anchorEl,
      },
    [formatTick, hoveredAnchor, xScale]
  )

  const verticalLines = useMemo(() => {
    const verticalLines: VerticalLineBaseProps[] = []
    const now = d3.timeDay.floor(new Date())
    const todayX = xScale(now)
    verticalLines.push({
      x: todayX,
      label: intl.formatMessage({ id: 'today' }),
      color: colorPalette.monotone[3],
    })
    if (scheduledEndDate) {
      const scheduledEndDateX = xScale(scheduledEndDate)
      verticalLines.push({
        x: scheduledEndDateX,
        label: intl.formatMessage({ id: 'progressReport.scheduledEndDate' }),
        color: colorPalette.monotone[10],
      })
    }
    if (deadlineDate) {
      const deadlineDateX = xScale(deadlineDate)
      verticalLines.push({
        x: deadlineDateX,
        label: intl.formatMessage({ id: 'progressReport.prospectEndDate' }),
        color: colorPalette.pink[6],
      })
    }
    return verticalLines
  }, [deadlineDate, scheduledEndDate, xScale])
  useEffect(() => {
    onSelect(selected)
  }, [selected, onSelect])

  const [
    scheduleLegendLabel,
    actualLegendLabel,
    prospectLegendLabel,
    simulatedLegendLabel,
    idealLegendLabel,
  ] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.graph.legend.schedule' }),
      intl.formatMessage({ id: 'progressReport.graph.legend.actual' }),
      intl.formatMessage({ id: 'progressReport.graph.legend.prospect' }),
      intl.formatMessage({ id: 'progressReport.graph.legend.simulated' }),
      intl.formatMessage({ id: 'progressReport.graph.legend.ideal' }),
      intl.formatMessage({ id: 'today' }),
      intl.formatMessage({ id: 'progressReport.scheduledEndDate' }),
      intl.formatMessage({ id: 'progressReport.prospectEndDate' }),
    ],
    []
  )

  const {
    displayScheduleGraph,
    toggleDisplayScheduleGraph,
    displayActualAndProspectGraph,
    toggleDisplayActualAndProspectGraph,
    displaySimulatedGraph,
    toggleDisplaySimulatedGraph,
    displayIdealGraph,
    toggleDisplayIdealGraph,
  } = useDisplayGraph()

  const {
    selectedSchedulePoint,
    selectedActualAndProspectPoint,
    selectedSimulatedPoint,
    selectedIdealPoint,
  } = useMemo(() => {
    const findSelectedPoint = (
      data: TwoDimensionalPoint<DateBucket, number>[]
    ) => {
      const anchorXRepresentative = getBucketRepresentative(selected)
      return data.find(
        d =>
          getBucketRepresentative(d.x).valueOf() ===
          anchorXRepresentative.valueOf()
      )
    }
    const schedulePoint = findSelectedPoint(displayData.scheduleData)
    const selectedSchedulePoint = displayScheduleGraph &&
      schedulePoint && {
        x: selected,
        y: schedulePoint.y || 0,
      }
    const actualPoint = findSelectedPoint([
      ...displayData.actualData,
      ...displayData.prospectData,
    ])
    const selectedActualAndProspectPoint = displayActualAndProspectGraph &&
      actualPoint && {
        x: selected,
        y: actualPoint.y || 0,
      }
    const simulatedPoint = findSelectedPoint(displayData.simulatedData)
    const selectedSimulatedPoint = displaySimulatedGraph &&
      simulatedPoint && {
        x: selected,
        y: simulatedPoint.y || 0,
      }
    const idealPoint = findSelectedPoint(displayData.idealData)
    const selectedIdealPoint = displayIdealGraph &&
      idealPoint && {
        x: selected,
        y: idealPoint.y || 0,
      }
    return {
      selectedSchedulePoint,
      selectedActualAndProspectPoint,
      selectedSimulatedPoint,
      selectedIdealPoint,
    }
  }, [
    selected,
    displayActualAndProspectGraph,
    displayData.actualData,
    displayData.idealData,
    displayData.prospectData,
    displayData.scheduleData,
    displayData.simulatedData,
    displayIdealGraph,
    displayScheduleGraph,
    displaySimulatedGraph,
    getBucketRepresentative,
  ])

  return (
    <GraphRoot>
      <GraphManipulationArea>
        <DateTermSelect
          value={displayDateTerm}
          onChange={updateDisplayDateTerm}
        />
        <GraphLegendArea>
          <GraphLegendGroup>
            <Checkbox
              size="s"
              checked={displayIdealGraph}
              onChange={toggleDisplayIdealGraph}
            />
            <GraphLegend>
              <GraphLegendColorDiv color={colorPalette.monotone[4]} />
              <GraphLegendText>{idealLegendLabel}</GraphLegendText>
            </GraphLegend>
          </GraphLegendGroup>
          <GraphLegendGroup>
            <Checkbox
              size="s"
              checked={displaySimulatedGraph}
              onChange={toggleDisplaySimulatedGraph}
            />
            <GraphLegend>
              <GraphLegendColorDiv color={colorPalette.monotone[2]} />
              <GraphLegendText>{simulatedLegendLabel}</GraphLegendText>
            </GraphLegend>
          </GraphLegendGroup>
          <GraphLegendGroup>
            <Checkbox
              size="s"
              checked={displayScheduleGraph}
              onChange={toggleDisplayScheduleGraph}
            />
            <GraphLegend>
              <GraphLegendColorDiv color={colorPalette.blue[2]} />
              <GraphLegendText>{scheduleLegendLabel}</GraphLegendText>
            </GraphLegend>
          </GraphLegendGroup>
          <GraphLegendGroup>
            <Checkbox
              size="s"
              checked={displayActualAndProspectGraph}
              onChange={toggleDisplayActualAndProspectGraph}
            />
            <GraphLegend>
              <GraphLegendColorDiv color={colorPalette.blue[7]} />
              <GraphLegendText>{`${actualLegendLabel} / ${prospectLegendLabel}`}</GraphLegendText>
            </GraphLegend>
          </GraphLegendGroup>
        </GraphLegendArea>
      </GraphManipulationArea>
      <GraphArea ref={ref}>
        <ChartContainer
          width={width}
          height={height}
          marginLeft={marginLeft}
          marginTop={marginTop}
        >
          <XAxis
            points={xAxisPoints}
            boundedHeight={boundedHeight}
            boundedWidth={boundedWidth}
          />
          <YAxisWithLine points={yAxisPoints} boundedWidth={boundedWidth} />
          <VerticalLineGroup
            lines={verticalLines}
            boundedHeight={boundedHeight}
            boundedWidth={boundedWidth}
          />
          {displayIdealGraph && (
            <>
              <BurndownChartIdealGraph
                xScale={dateBucketScale}
                yScale={yScale}
                points={displayData.idealData}
              />
              {selectedIdealPoint && (
                <LineNode
                  xScale={dateBucketScale}
                  yScale={yScale}
                  point={selectedIdealPoint}
                  color={colorPalette.monotone[4]}
                />
              )}
            </>
          )}
          {displaySimulatedGraph && (
            <>
              <BurndownChartSimulatedGraph
                xScale={dateBucketScale}
                yScale={yScale}
                points={displayData.simulatedData}
              />
              {selectedSimulatedPoint && (
                <LineNode
                  xScale={dateBucketScale}
                  yScale={yScale}
                  point={selectedSimulatedPoint}
                  color={colorPalette.monotone[2]}
                />
              )}
            </>
          )}
          {displayScheduleGraph && (
            <>
              <BurndownChartScheduleGraph
                xScale={dateBucketScale}
                yScale={yScale}
                points={displayData.scheduleData}
              />
              {selectedSchedulePoint && (
                <LineNode
                  xScale={dateBucketScale}
                  yScale={yScale}
                  point={selectedSchedulePoint}
                  color={colorPalette.blue[2]}
                />
              )}
            </>
          )}
          {displayActualAndProspectGraph && (
            <>
              <BurndownChartActualGraph
                xScale={dateBucketScale}
                yScale={yScale}
                points={displayData.actualData}
              />
              <BurndownChartProspectGraph
                xScale={dateBucketScale}
                yScale={yScale}
                points={displayData.prospectData}
              />
              {selectedActualAndProspectPoint && (
                <LineNode
                  xScale={dateBucketScale}
                  yScale={yScale}
                  point={selectedActualAndProspectPoint}
                  color={colorPalette.blue[7]}
                />
              )}
            </>
          )}
          {hoveredVerticalLine && (
            <VerticalLineWithLabel
              {...hoveredVerticalLine}
              color={colorPalette.monotone[2]}
              boundedHeight={boundedHeight}
              boundedWidth={boundedWidth}
            />
          )}
          <GraphEventListenerLayer
            boundedHeight={boundedHeight}
            boundedWidth={boundedWidth}
            onHover={onHover}
            onClick={onClick}
          />
        </ChartContainer>
      </GraphArea>
    </GraphRoot>
  )
}
