import { useCallback, useEffect, useMemo, useState } from 'react'
import { BurndownChartSimulationVariables } from '../model'

export type UpdateProspectSimulationVariables = {
  updateProspectVelocity: (v: number) => void
  updateProspectResourceRate: (v: number) => void
  resetProspectVelocity: () => void
  resetProspectResourceRate: () => void
}
export type UpdateSimulatedSimulationVariables = {
  updateSimulatedVelocity: (v: number) => void
  updateSimulatedResourceRate: (v: number) => void
  updateSimulatedSimulationFromDate: (v: Date) => void
  resetSimulatedVelocity: () => void
  resetSimulatedResourceRate: () => void
  resetSimulatedSimulationFromDate: () => void
}
export type UpdateIdealSimulationVariables = {
  updateIdealSimulationFromDate: (v: Date) => void
  updateIdealSimulationToDate: (v: Date) => void
  resetIdealSimulationFromDate: () => void
  resetIdealSimulationToDate: () => void
}

export const useSimulationVariables = (
  scheduledStartDate: Date | undefined,
  scheduledEndDate: Date | undefined,
  calculatedVelocity: number | undefined
): {
  simulationVariables: BurndownChartSimulationVariables
} & UpdateProspectSimulationVariables &
  UpdateSimulatedSimulationVariables &
  UpdateIdealSimulationVariables => {
  const [prospectVelocity, setProspectVelocity] = useState<number>(1)
  const [prospectResourceRate, setProspectResourceRate] = useState<number>(1)
  const [simulatedVelocity, setSimulatedVelocity] = useState<number>(1)
  const [simulatedResourceRate, setSimulatedResourceRate] = useState<number>(1)
  const [simulatedSimulationFromDate, setSimulatedSimulationFromDate] =
    useState<Date | undefined>(scheduledStartDate)
  const [idealSimulationFromDate, setIdealSimulationFromDate] = useState<
    Date | undefined
  >(scheduledStartDate)
  const [idealSimulationToDate, setIdealSimulationToDate] = useState<
    Date | undefined
  >(scheduledEndDate)
  useEffect(() => {
    setSimulatedSimulationFromDate(scheduledStartDate)
    setIdealSimulationFromDate(scheduledStartDate)
    setIdealSimulationToDate(scheduledEndDate)
  }, [scheduledEndDate, scheduledStartDate])
  useEffect(() => {
    if (typeof calculatedVelocity === 'undefined') return
    setProspectVelocity(calculatedVelocity)
  }, [calculatedVelocity])
  const simulationVariables = useMemo(
    () => ({
      prospectVelocity,
      prospectResourceRate,
      simulatedVelocity,
      simulatedResourceRate,
      simulatedSimulationFromDate,
      idealSimulationFromDate,
      idealSimulationToDate,
    }),
    [
      simulatedResourceRate,
      simulatedVelocity,
      prospectResourceRate,
      prospectVelocity,
      simulatedSimulationFromDate,
      idealSimulationFromDate,
      idealSimulationToDate,
    ]
  )
  const resetProspectVelocity = useCallback(() => {
    setProspectVelocity(1)
  }, [])
  const resetProspectResourceRate = useCallback(() => {
    setProspectResourceRate(1)
  }, [])
  const resetSimulatedVelocity = useCallback(() => {
    setSimulatedVelocity(1)
  }, [])
  const resetSimulatedResourceRate = useCallback(() => {
    setSimulatedResourceRate(1)
  }, [])
  const resetSimulatedSimulationFromDate = useCallback(() => {
    setSimulatedSimulationFromDate(scheduledStartDate)
  }, [scheduledStartDate])
  const resetIdealSimulationFromDate = useCallback(() => {
    setIdealSimulationFromDate(scheduledStartDate)
  }, [scheduledStartDate])
  const resetIdealSimulationToDate = useCallback(() => {
    setIdealSimulationToDate(scheduledEndDate)
  }, [scheduledEndDate])

  return {
    simulationVariables,
    updateProspectVelocity: setProspectVelocity,
    updateProspectResourceRate: setProspectResourceRate,
    resetProspectVelocity,
    resetProspectResourceRate,
    updateSimulatedVelocity: setSimulatedVelocity,
    updateSimulatedResourceRate: setSimulatedResourceRate,
    updateSimulatedSimulationFromDate: setSimulatedSimulationFromDate,
    resetSimulatedVelocity,
    resetSimulatedResourceRate,
    resetSimulatedSimulationFromDate,
    updateIdealSimulationFromDate: setIdealSimulationFromDate,
    updateIdealSimulationToDate: setIdealSimulationToDate,
    resetIdealSimulationFromDate,
    resetIdealSimulationToDate,
  }
}
