import React, { useCallback } from 'react'
import StyleButtonBase, { StyleButtonBaseProps } from './StyleButtonBase'
import TableCreateDialog from '../../dialogs/TableCreateDialog'
import { StyleType } from '../../../../utils/markdown'

interface OwnProps {
  setTableValue: (rows: number, columns: number) => void
  deleteTable: () => void
}

type Props = OwnProps & StyleButtonBaseProps

const TableStyleButtonBase = (props: Props) => {
  const { disabled, highlightItems, setTableValue, deleteTable } = props
  const [open, setOpen] = React.useState(false)
  const onMouseDown = useCallback(
    event => {
      event.preventDefault()
      if (disabled || !setTableValue) {
        return
      }
      if (highlightItems?.includes(StyleType.TABLE)) {
        deleteTable()
        return
      }
      setOpen(true)
    },
    [disabled, highlightItems, setTableValue, deleteTable]
  )

  const close = React.useCallback(
    (rows?: number, columns?: number) => {
      try {
        if (!rows || !columns) {
          return
        }
        setTableValue(rows, columns)
      } finally {
        setOpen(false)
      }
    },
    [disabled, setTableValue]
  )
  return (
    <>
      <StyleButtonBase
        {...props}
        styleType={StyleType.TABLE}
        onMouseDown={onMouseDown}
      />
      <TableCreateDialog open={open} closeHandler={close} />
    </>
  )
}

export default TableStyleButtonBase
