import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { Button } from '../../../../components/buttons'
import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { ValuePanel, ValuePanelProps } from './value-panel'
import { UpdateFormModelValue, WbsItemFormModel } from '../../model'
import {
  DataHandlerButtons,
  DataHandlerButtonsProps,
} from './DataHandlerButtons'
import { SubHeaderDivider } from './commons'

type WbsItemSubHeaderProps = DataHandlerButtonsProps & ValuePanelProps
export const WbsItemSubHeader = ({
  submit,
  reload,
  ...valuePanelProps
}: WbsItemSubHeaderProps) => {
  return (
    <SubHeaderRoot>
      <DataHandlerButtons submit={submit} reload={reload} />
      <SubHeaderDivider />
      <ValuePanel {...valuePanelProps} />
    </SubHeaderRoot>
  )
}

const SubHeaderRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0 12px',
  backgroundColor: colorPalette.monotone[0],
  gap: '8px',
})
