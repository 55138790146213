import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SearchConditionButton from '../../../../components/buttons/SearchConditionButton'
import SavedUIStateDialog from '../../../../components/dialogs/SavedUIStateDialog'
import { UiStateKey } from '../../../../../lib/commons/uiStates'
import { SavedUIState } from '../../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import {
  ProgressReportChartConfig,
  ProgressReportSavedUiState,
  ProgressReportViewConfig,
} from '../..'
import { FontSize } from '../../../../../styles/commonStyles'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
})
const InnerDiv = styled('div')({
  padding: '0 5px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export interface Props extends WrappedComponentProps {
  viewConfig: ProgressReportViewConfig
  chartConfig?: ProgressReportChartConfig
  onChange: (newUiState: ProgressReportSavedUiState) => void
  uiStateKey: UiStateKey
}

const ProgressReportSavedUiStateButton = injectIntl(
  ({ intl, uiStateKey, viewConfig, chartConfig, onChange }: Props) => {
    const [open, setOpen] = useState(false)
    const [currentUiState, setCurrentUiState] = useState<any>(undefined)
    useEffect(() => {
      const newUiState = {
        ...viewConfig,
        ...chartConfig,
      }
      setCurrentUiState(newUiState)
    }, [viewConfig, chartConfig])

    const onSelect = useCallback(
      (savedUiState: SavedUIState) => {
        onChange(savedUiState.UIState)
        setOpen(false)
      },
      [onChange]
    )

    return (
      <RootDiv>
        <InnerDiv>
          <SearchConditionButton onClick={() => setOpen(true)} />
          <SavedUIStateDialog
            applicationFunctionUuid={''}
            open={open}
            onClose={() => setOpen(false)}
            title={intl.formatMessage({ id: 'report.specification' })}
            sharable={true}
            uiStateKey={uiStateKey}
            onSelect={onSelect}
            currentUIState={currentUiState}
          />
        </InnerDiv>
      </RootDiv>
    )
  }
)

export default ProgressReportSavedUiStateButton
