import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

const LINE_BREAK_MATCHER: string = '[\r\n]'

export default class SingleLineTextVO extends DomainValueObjectBase<string> {
  constructor(_value: string) {
    if (!_value) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'Code value is required.',
      })
    }
    if (typeof _value !== 'string') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: `Text value(${_value}) must be string value.`,
      })
    }
    const match = _value.match(LINE_BREAK_MATCHER)
    if (match && 0 < match.length) {
      throw new DomainError({
        errorCode: DomainErrorCode.INVALID_VALUE_ERROR,
        message: `Text (${_value}) contains line break.`,
      })
    }
    super(_value)
  }
}
