import React from 'react'
import MuiDialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { styled } from '@mui/system'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import Button from '@mui/material/Button'

// Styles
const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const Dialog = styled(MuiDialog)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const DialogTitle = styled(MuiDialogTitle)({
  padding: '0px 0px',
  margin: '10px',
})
const DialogContent = styled(MuiDialogContent)({
  backgroundColor: 'lightgrey',
  padding: '0px 0px',
})
const ImageCrop = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

interface Props {
  open: boolean
  title?: string
  url: string
  closeHandler: Function
  submitHandler: Function
}

interface Status {
  crop?: any
  croppedImage?: any
}

class FileUploadDialog extends React.Component<Props, Status> {
  imageRef: any

  constructor(props: Props) {
    super(props)
    this.state = {
      crop: {
        unit: 'px',
        width: 256,
        aspect: 1,
      },
    }
  }

  private closeDialog = () => {
    this.props.closeHandler()
  }

  private imageLoaded = image => {
    this.imageRef = image
  }

  private change = crop => {
    this.setState({ crop: crop })
  }

  private complete = (crop, pixelCrop) => {
    this.makeClientCrop(crop)
  }

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImage(this.imageRef, crop).then(croppedImage => {
        // @ts-ignore
        this.setState({ croppedImage })
      })
    }
  }

  getCroppedImage(image, crop) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width < 256 ? crop.width : 256
    canvas.height = crop.height < 256 ? crop.height : 256
    const ctx = canvas.getContext('2d')

    // @ts-ignore
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        resolve(blob)
      }, 'image/jpg')
    })
  }

  private submit = () => {
    this.props.submitHandler(this.state.croppedImage)
  }

  render() {
    return (
      <RootContainer>
        <Dialog
          open={this.props.open}
          onClose={this.closeDialog}
          aria-labelledby="simple-dialog-title"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <ImageCrop>
              <ReactCrop
                src={this.props.url}
                crop={this.state.crop}
                onImageLoaded={this.imageLoaded}
                onChange={this.change}
                onComplete={this.complete}
                minWidth={32}
              />
            </ImageCrop>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.submit} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </RootContainer>
    )
  }
}
export default FileUploadDialog
