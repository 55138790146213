import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GridOptions,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { useBulkSheetState as useBulkSheetStateBase } from '../../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'

export const useBulkSheetState = (
  gridOptions: GridOptions,
  functionUuid: string,
  uiStateKey: UiStateKey
) => {
  const base = useBulkSheetStateBase(functionUuid, uiStateKey)

  const restoreColumnState = useCallback(() => {
    if (!gridOptions.columnApi || _.isEmpty(base.columnState)) return
    gridOptions.columnApi.applyColumnState({
      state: base.columnState,
      applyOrder: true,
    })
  }, [gridOptions.columnApi, base.columnState])

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && base.saveColumnState(columnState)
  }, [gridOptions.columnApi, base])

  const restoreExpandedRows = useCallback(() => {
    if (_.isEmpty(base.expandedRowIds) || !gridOptions.api) return
    setTimeout(() => {
      base.expandedRowIds.forEach(id => {
        gridOptions.api?.getRowNode(id)?.setExpanded(true)
      })
    }, 200)
  }, [base.expandedRowIds, gridOptions.api])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      base.saveFilterState(filterModel)
      return Object.keys(filterModel)
        .map(col => e.api.getColumnDef(col))
        .filter(v => !!v && v.field !== 'uuid') as ColDef[]
    },
    [base]
  )

  const onFirstDataRendered = useCallback(
    e => {
      restoreExpandedRows()
      restoreColumnState()
      if (!_.isEmpty(base.filterState)) {
        gridOptions.api?.setFilterModel(base.filterState)
      }
      base.finishRestoring()
    },
    [gridOptions.api, base, restoreExpandedRows, restoreColumnState]
  )

  const onGridReady = useCallback(() => {
    restoreColumnState()
  }, [restoreColumnState])

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        base.expandRows([key])
      } else {
        base.collapseRows([key])
      }
    },
    [base]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      const sortedColumnStates = e.columnApi
        .getColumnState()
        .filter((colState: ColumnState) => !!colState.sort)
        .map((colState: ColumnState) => {
          const colDef: ColDef | null = e.api.getColumnDef(colState.colId)
          if (!colDef) return
          return {
            colId: colState.colId,
            field: colDef,
            headerName: colDef.headerName,
            sort: colState.sort,
          } as SortedColumnState
        })
        .filter(v => !!v) as SortedColumnState[]
      e.api.setSuppressRowDrag(0 < sortedColumnStates.length)
      return sortedColumnStates
    },
    [rememberColumnState]
  )

  const columnSettingUiStateKey = useMemo(() => {
    switch (uiStateKey) {
      case UiStateKey.ResourcePlanCrossProjectsMemberView:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanCPMemberView
      case UiStateKey.ResourcePlanCrossProjectsProjectView:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanCPProjectView
      default:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanCPMemberView
    }
  }, [uiStateKey])

  return {
    base,
    restoreColumnState,
    rememberColumnState,
    restoreExpandedRows,
    onFilterChanged,
    onFirstDataRendered,
    onGridReady,
    onRowGroupOpened,
    onSortChanged,
    columnSettingUiStateKey,
  }
}
