import _ from 'lodash'
import moment from 'moment'
import { APIResponse, toUrlQuery } from '../../../lib/commons/api'
import {
  DayOfWeek,
  getTimeLabel,
  getTimeUnitByGrain,
  getTimeUnitSizeByGrain,
  TimeGrain,
} from '../../../lib/functions/report'
import {
  AggregateColumn,
  AggregateTargetUnit,
  getWbsItemProgressLog,
  getTicketWbsItemProgressLog,
  WbsItemTypeForWbsProgressLog,
  WbsProgressLogRequest,
  TicketWbsItemProgressLogRequest,
} from '../../../lib/functions/wbsProgressLog'
import objects from '../../../utils/objects'
import { Color } from '../../../styles/commonStyles'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import ProjectAPI from '../../../lib/functions/project'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import DateVO from '../../../vo/DateVO'
import {
  AggregateColumnGroup,
  ProgressReportData,
  ProgressReportDataValue,
  SummarizeType,
} from './utils'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { APPLICATION_FUNCTION_EXTERNAL_ID, getPathByExternalId } from '..'
import { DateTerm } from '../../../utils/date'
import { ProgressReportSearchConditionVO } from '../../../domain/value-object/ProgressReportSearchConditionVO'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { BaseWbsItemType } from '../../../store/project'

export type ProgressReportScreenId =
  | APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_CHART
  | APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_TABLE

export const getProgressReportLinkPath = (
  projectCode: string,
  searchCondition: Partial<ProgressReportSearchConditionVO>,
  viewConfig: Partial<ProgressReportViewConfig> = {},
  screenId: ProgressReportScreenId
) => {
  const functionDefaultPath: string = getPathByExternalId(screenId)
  const condition: any = {
    ...searchCondition,
    ...viewConfig,
  }
  const query = toUrlQuery({ ...condition })
  return `${functionDefaultPath}/${projectCode}?${query}`
}

export const dateTermToViewConfig = (dateTerm?: DateTerm) => {
  // Plan to return viewConfig according to plans and actuals
  return {}
}

export const openProgressReport = async (
  projectUuid: string,
  _searchCondition: Partial<ProgressReportSearchConditionVO>,
  _viewConfig: Partial<ProgressReportViewConfig> = {}
) => {
  const projectResponse = await ProjectAPI.getDetail({ uuid: projectUuid })
  const link = getProgressReportLinkPath(
    projectResponse.json.code,
    _searchCondition,
    _viewConfig,
    APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_TABLE
  )
  const url = `${window.location.origin}${link}`
  window.open(url)
}

const WBS_ITEM_STATUS_LIST = [
  WbsItemStatus.TODO,
  WbsItemStatus.DOING,
  WbsItemStatus.REVIEW,
  WbsItemStatus.DONE,
  WbsItemStatus.DISCARD,
]

export type ProgressReportViewConfig = {
  timeGrain: TimeGrain
  startDayOfWeek: DayOfWeek
  aggregateTargetType: AggregateField
  aggregateTargetUnit: AggregateTargetUnit
  viewTime?: { from: number; to: number }
}

export type ProgressReportChartConfig = {
  graphConfig: ProgressReportGraphConfig[]
}

export type ProgressReportGraphConfigKey = {
  type: WbsItemTypeForWbsProgressLog
  aggregateColumnGroup: AggregateColumnGroup
}

export enum AccumulateDirection {
  BURN_UP = 'BURN_UP',
  BURN_DOWN = 'BURN_DOWN',
}

export type GraphColor = string
export type ColorPalette = GraphColor[]
export const ProgressReportColorPalettes = [
  ['#91E1F4', '#00BBE6', '#01A4C9', '#1375A2'],
  ['#BBF2A4', '#7CDF53', '#4ABE1A', '#379311'],
  ['#f1f490', '#e2e600', '#c8cb01', '#a09d13'],
  ['#FFC684', '#FD9C2A', '#F27C17', '#E86200'],
  ['#FFB0B0', '#FC8383', '#E65757', '#D62929'],
  ['#e790f4', '#db0fff', '#ad01cb', '#a614a9'],
]

export type ProgressReportGraphConfig = {
  key: ProgressReportGraphConfigKey
  show: boolean
  showBar: boolean
  direction: AccumulateDirection
  color: GraphColor
  disabled?: boolean
}

export type ProgressReportDataSeries = {
  type: WbsItemTypeForWbsProgressLog
  aggregateColumn: AggregateColumn
  value: ProgressReportDataValue[]
}

// Web API
const toWbsItemProgressLogRequestBody = async (
  condition: ProgressReportSearchConditionVO,
  projectUuid: string,
  ticketTypes: WbsItemTypeVO[]
): Promise<WbsProgressLogRequest> => {
  const aggregateBy = [
    ...[WbsItemType.DELIVERABLE, WbsItemType.TASK].map(type => ({
      wbsItemType: type,
      aggregateColumn: Object.values(AggregateColumn),
    })),
    ...ticketTypes.map(type => ({
      ticketType: type.code,
      aggregateColumn: Object.values(AggregateColumn),
    })),
  ]
  return {
    projectUuid: projectUuid,
    useWriteModel: true,
    aggregateBy,
    rootWbsItemUuid: condition.rootWbsItemUuid,
    wbsItemCodes: [],
    wbsItemStatusList: WBS_ITEM_STATUS_LIST,
    aggregate: false,
    timeGrain: TimeGrain.DAY,
    startDayOfWeek: DayOfWeek.MONDAY,
    teamUuid: condition.team?.uuid,
    accountableUuid: condition.accountable?.uuid,
    responsibleUuid: condition.responsible?.uuid,
    priority: condition.priority,
  }
}

const fetchByRootWbsItem = async (
  condition: ProgressReportSearchConditionVO,
  projectUuid: string,
  ticketTypes: WbsItemTypeVO[]
): Promise<APIResponse> => {
  const requestBody = await toWbsItemProgressLogRequestBody(
    condition,
    projectUuid,
    ticketTypes
  )
  return getWbsItemProgressLog(requestBody)
}

const toTicketProgressLogRequestBody = (
  condition: ProgressReportSearchConditionVO,
  projectUuid: string
): TicketWbsItemProgressLogRequest => {
  return {
    ticketListUuid: condition.ticketListUuid!,
    projectUuid: projectUuid,
    teamUuid: condition.team?.uuid,
    accountableUuid: condition.accountable?.uuid,
    responsibleUuid: condition.responsible?.uuid,
    priority: condition.priority,
  }
}

const fetchByTicketList = async (
  condition: ProgressReportSearchConditionVO,
  projectUuid: string
): Promise<APIResponse> => {
  const requestBody = toTicketProgressLogRequestBody(condition, projectUuid)
  return getTicketWbsItemProgressLog(requestBody)
}

const responseToReportData = (
  isActualHour: boolean,
  wbsItemDatas
): ProgressReportData | undefined => {
  if (!wbsItemDatas) {
    return undefined
  }
  const includeStatus = !!isActualHour
    ? WBS_ITEM_STATUS_LIST
    : WBS_ITEM_STATUS_LIST.filter(v => v !== WbsItemStatus.DISCARD)
  return wbsItemDatas.reduce(
    (reportData: ProgressReportData, data) => {
      if (!includeStatus.includes(data.status)) {
        return reportData
      }
      const workload: number = !isActualHour ? data.estimated : data.actual
      return {
        workload: reportData.workload + workload,
        count: !isActualHour ? reportData.count + 1 : 0,
        workloadMap: {
          ...reportData.workloadMap,
          [data.code]: workload,
        },
      }
    },
    {
      workload: 0,
      count: 0,
      workloadMap: {},
    }
  )
}

const sortProgressReportDataSeries = (
  dataSeries: ProgressReportDataSeries[],
  ticketTypes: WbsItemTypeVO[],
  priorityToTicketType: boolean
): ProgressReportDataSeries[] => {
  const types: string[] = priorityToTicketType
    ? [...ticketTypes.map(type => type.code), WbsItemType.TASK]
    : [
        WbsItemType.DELIVERABLE,
        WbsItemType.TASK,
        ...ticketTypes.map(type => type.code),
      ]

  const columns: string[] = Object.keys(AggregateColumn)
  dataSeries.sort((data1, data2) => {
    const typeComparisonResult =
      types.indexOf(data1.type.value) - types.indexOf(data2.type.value)
    return typeComparisonResult !== 0
      ? typeComparisonResult
      : columns.indexOf(data1.aggregateColumn) -
          columns.indexOf(data2.aggregateColumn)
  })
  return dataSeries
}

export const fetch = async (
  condition: ProgressReportSearchConditionVO,
  projectUuid?: string,
  ticketTypes: WbsItemTypeVO[] = []
): Promise<ProgressReportDataSeries[]> => {
  if (!projectUuid) {
    throw new Error('projectUuid is not specified')
  }
  if (!condition) return []

  let responseJson
  if (condition.ticketListUuid) {
    responseJson = (await fetchByTicketList(condition, projectUuid)).json
  } else {
    responseJson = (
      await fetchByRootWbsItem(condition, projectUuid, ticketTypes)
    ).json
  }
  if (!Array.isArray(responseJson)) {
    return []
  }

  const dataSeries = responseJson.map(response => {
    const isActualHour =
      response.key.aggregateColumn === AggregateColumn.ACTUAL_HOUR_RECORDED_AT
    const values: ProgressReportDataValue[] = response.data.map(dataPerDim => {
      return {
        dimTime: dataPerDim.dimTime,
        data: responseToReportData(isActualHour, dataPerDim.wbsItemDatas),
      }
    })
    return {
      type: response.key.type,
      aggregateColumn: response.key.aggregateColumn,
      value: values,
    }
  })
  return sortProgressReportDataSeries(
    dataSeries,
    ticketTypes,
    !!condition.ticketListUuid
  )
}

// Condition and configurations
export type ProgressReportSavedUiState =
  Partial<ProgressReportSearchConditionVO> &
    Partial<ProgressReportViewConfig> &
    Partial<ProgressReportChartConfig>

export const initViewConfig = () => ({
  timeGrain: TimeGrain.DAY,
  startDayOfWeek: DayOfWeek.MONDAY,
  aggregateTargetType: AggregateField.WBS_ITEM_COUNT,
  aggregateTargetUnit: AggregateTargetUnit.HOUR,
  viewTime: {
    from: moment().subtract(2, 'M').startOf('M').valueOf(),
    to: moment().add(2, 'M').endOf('M').valueOf(),
  },
})

export const initChartConfig = (
  aggregateType: AggregateField,
  baseWbsItemType: BaseWbsItemType
): ProgressReportChartConfig => {
  return {
    graphConfig: [baseWbsItemType.deliverable, baseWbsItemType.task].flatMap(
      (type, index) =>
        initGraphConfig(
          {
            value: type.code,
            name: type.name,
            iconUrl: type.iconUrl,
          },
          ProgressReportColorPalettes[index],
          aggregateType
        )
    ),
  }
}

export const initGraphConfig = (
  type: WbsItemTypeForWbsProgressLog,
  colorPalette: ColorPalette,
  aggregateType: AggregateField,
  isShow?: (
    type: WbsItemTypeForWbsProgressLog,
    grp: AggregateColumnGroup
  ) => boolean
): ProgressReportGraphConfig[] => {
  return Object.values(AggregateColumnGroup).map((grp, index) => {
    const show = isShow
      ? isShow(type, grp)
      : grp === AggregateColumnGroup.END_DATE
    return {
      key: {
        type,
        aggregateColumnGroup: grp,
      },
      show,
      showBar: false,
      direction: AccumulateDirection.BURN_UP,
      color: colorPalette[index] || Color.MAIN,
      disabled: isGraphConfigDisabled(grp, aggregateType),
    }
  })
}

export const isGraphConfigDisabled = (
  grp: AggregateColumnGroup,
  aggregateType: AggregateField
) => {
  return (
    grp === AggregateColumnGroup.ACTUAL_HOUR_RECORDED_AT &&
    aggregateType === AggregateField.WBS_ITEM_COUNT
  )
}

export const getViewConfigFromUiState = (
  uiState: ProgressReportSavedUiState
): Partial<ProgressReportViewConfig> => {
  const viewConfig = {}
  if (uiState.timeGrain) {
    objects.setValue(viewConfig, 'timeGrain', uiState.timeGrain)
  }
  if (uiState.startDayOfWeek) {
    objects.setValue(viewConfig, 'startDayOfWeek', uiState.startDayOfWeek)
  }
  if (uiState.aggregateTargetType) {
    objects.setValue(
      viewConfig,
      'aggregateTargetType',
      uiState.aggregateTargetType
    )
  }
  if (uiState.aggregateTargetUnit) {
    objects.setValue(
      viewConfig,
      'aggregateTargetUnit',
      uiState.aggregateTargetUnit
    )
  }
  if (uiState.viewTime) {
    objects.setValue(viewConfig, 'viewTime', uiState.viewTime)
  }
  return viewConfig
}

export const getChartConfigFromUiState = (
  uiState: ProgressReportSavedUiState
): Partial<ProgressReportChartConfig> => {
  if (uiState.graphConfig) {
    return { graphConfig: uiState.graphConfig }
  }
  return {}
}

export const mergeUIState = (
  primary: ProgressReportSavedUiState | undefined,
  secondary: ProgressReportSavedUiState | undefined
) => {
  const margedState = {
    ...secondary,
    ...primary,
  }
  if (primary?.rootWbsItemUuid && margedState.ticketListUuid) {
    delete margedState.ticketListUuid
  } else if (primary?.ticketListUuid && margedState.rootWbsItemUuid) {
    delete margedState.rootWbsItemUuid
  }
  return margedState
}

export const translateUrlQueryToViewTime = (
  query: any
): { from: number; to: number } | undefined => {
  if (query.viewTime && query.viewTime.from && query.viewTime.to) {
    return {
      from: toViewTime(query.viewTime.from) || 0,
      to: toViewTime(query.viewTime.to) || 0,
    }
  }
  return undefined
}
export const toViewTime = (value: number | string) => {
  if (Number.isNaN(Number(value))) {
    return DateVO.isSupportedValue(value)
      ? new DateVO(value).toNumberValue()
      : undefined
  }
  return Number(value)
}

// TODO: Move to Utils file (because an error occurs in jest, it cannot be moved)
export const getDataValue = (
  data: {
    workload: number
    count: number
  },
  aggregateTargetType: AggregateField,
  hoursPerSelectedWorkloadUnit: number
) => {
  const isWorkload = aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD
  const v = isWorkload ? data.workload : data.count
  const denominator = isWorkload ? hoursPerSelectedWorkloadUnit : 1
  return v / denominator
}

// TODO: Move to Utils file (because an error occurs in jest, it cannot be moved)
export const summarize = (
  values: ProgressReportDataValue[],
  dimTime: number,
  timeGrain: TimeGrain,
  aggregateTargetType: AggregateField,
  aggregateTargetUnit: AggregateTargetUnit,
  type: SummarizeType,
  hoursPerSelectedWorkloadUnit: number
) => {
  switch (type) {
    case SummarizeType.UNIT:
      return values
        .filter(v => !!v.dimTime)
        .filter(v =>
          moment(v.dimTime).isBetween(
            moment(dimTime),
            moment(dimTime).add(
              getTimeUnitSizeByGrain(timeGrain),
              getTimeUnitByGrain(timeGrain)
            ),
            'd',
            '[)'
          )
        )
        .map(v =>
          getDataValue(
            v.data,
            aggregateTargetType,
            hoursPerSelectedWorkloadUnit
          )
        )
        .reduce((prev, curr) => prev + curr, 0)
    case SummarizeType.BURN_UP:
      return values
        .filter(v => !!v.dimTime)
        .filter(v =>
          moment(v.dimTime).isBetween(
            moment(0),
            moment(dimTime).add(
              getTimeUnitSizeByGrain(timeGrain),
              getTimeUnitByGrain(timeGrain)
            ),
            'd',
            '[)'
          )
        )
        .map(v =>
          getDataValue(
            v.data,
            aggregateTargetType,
            hoursPerSelectedWorkloadUnit
          )
        )
        .reduce((prev, curr) => prev + curr, 0)
    case SummarizeType.BURN_DOWN:
      const total = values
        .map(v =>
          getDataValue(
            v.data,
            aggregateTargetType,
            hoursPerSelectedWorkloadUnit
          )
        )
        .reduce((prev, curr) => prev + curr, 0)
      return (
        total -
        values
          .filter(v => !!v.dimTime)
          .filter(v =>
            moment(v.dimTime).isBetween(
              moment(0),
              moment(dimTime).add(
                getTimeUnitSizeByGrain(timeGrain),
                getTimeUnitByGrain(timeGrain)
              ),
              'd',
              '[)'
            )
          )
          .map(v =>
            getDataValue(
              v.data,
              aggregateTargetType,
              hoursPerSelectedWorkloadUnit
            )
          )
          .reduce((prev, curr) => prev + curr, 0)
      )
  }
}

// Value formatter
// TODO: Move to Utils file (because an error occurs in jest, it cannot be moved)
export const getValueFormatter = (
  aggregateTargetType: AggregateField,
  aggregateTargetUnit: AggregateTargetUnit
) => {
  let digit = 0
  if (aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
    digit = 0
  } else {
    if (aggregateTargetUnit === AggregateTargetUnit.HOUR) {
      digit = 2
    } else {
      digit = 1
    }
  }
  return (v: number) => `${Number(v).toFixed(digit)}`
}

export const getDateFormatter = (timeGrain: TimeGrain) => {
  return (v: number) => getTimeLabel(v, timeGrain)
}

// other utils
// TODO: Move to Utils file (because an error occurs in jest, it cannot be moved)
export const mapGroupToColumn = (
  group: AggregateColumnGroup
): AggregateColumn[] => {
  switch (group) {
    case AggregateColumnGroup.START_DATE:
      return [
        AggregateColumn.SCHEDULED_START_DATE,
        AggregateColumn.ACTUAL_START_DATE,
      ]
    case AggregateColumnGroup.END_DATE:
      return [
        AggregateColumn.SCHEDULED_END_DATE,
        AggregateColumn.ACTUAL_END_DATE,
      ]
    default:
      return [AggregateColumn[group]]
  }
}

export const isScheduledColumn = (column: AggregateColumn) => {
  return [
    AggregateColumn.SCHEDULED_START_DATE,
    AggregateColumn.SCHEDULED_END_DATE,
  ].includes(column)
}

export const isActualOnlyGroup = (group: AggregateColumnGroup) => {
  return [
    AggregateColumnGroup.CREATED_AT,
    AggregateColumnGroup.ACTUAL_HOUR_RECORDED_AT,
  ].includes(group)
}

// chart uistate
const getChartUIStateKey = (projectUuid: string | undefined) => {
  return projectUuid
    ? `${UiStateKey.ProgressReportChartUIState}-${projectUuid}`
    : UiStateKey.ProgressReportChartUIState
}

export const getChartUIState = async (
  projectUuid: string | undefined
): Promise<ProgressReportSavedUiState | undefined> => {
  const response = await uiStates.get({
    applicationFunctionUuid: '',
    key: getChartUIStateKey(projectUuid),
    scope: UiStateScope.User,
  })
  return response.json.value
    ? (JSON.parse(response.json.value) as ProgressReportSavedUiState)
    : undefined
}

export const saveChartUIState = async (
  projectUuid: string | undefined,
  pageTransitionState: ProgressReportSavedUiState
) => {
  await uiStates.update(
    {
      key: getChartUIStateKey(projectUuid),
      scope: UiStateScope.User,
      value: JSON.stringify(pageTransitionState),
    },
    ''
  )
}
