import { Box, Divider, Popper, Typography } from '@mui/material'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import {
  BackgroundColor,
  BorderColor,
  Color,
} from '../../../../styles/commonStyles'
import { intl } from '../../../../i18n'
import { Directory } from '../../../../lib/commons/appFunction'
import TenantDirectoryMenu from './TenantDirectoryMenu'
import TenantAvatar from '../../../components/icons/TenantAvatar'
import { OrganizationDetail } from '../../../../lib/functions/tenant'
import { colorPalette } from '../../../style/colorPallete'

type Props = {
  openMenu: boolean
  anchorEl: Element | null
  menu: Directory[]
  organization?: OrganizationDetail
}

const CommonsTenantMenu = ({
  openMenu,
  anchorEl,
  menu,
  organization,
}: Props) => {
  return (
    <Popper
      open={openMenu}
      anchorEl={anchorEl}
      placement={'right-start'}
      modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]}
      sx={{
        backgroundColor: BackgroundColor.WHITE,
        boxShadow:
          'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
        borderRadius: '4px',
        zIndex: 1300,
        padding: '2px 10px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          margin: '10px 0',
          padding: '0px 8px',
          border: '0px 0px 1px 0px',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TenantAvatar
          src={organization?.iconUrl}
          variant="rounded"
          sx={{
            width: '24px',
            height: '24px',
            border: `solid ${colorPalette.monotone[2]} 1px`,
          }}
        />
        <Typography
          sx={{
            color: Color.MONOTONE,
            fontWeight: 700,
            fontSize: '14px',
            marginLeft: '10px',
            maxWidth: '240px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
            overflow: 'hidden',
          }}
        >
          {organization?.displayName}
        </Typography>
        <Typography
          sx={{
            color: colorPalette.monotone[10],
            fontWeight: 500,
            fontSize: '15px',
            marginRight: 'auto',
            marginLeft: '20px',
          }}
        >
          {intl.formatMessage({ id: 'tenant.menu.title' })}
        </Typography>
      </Box>
      <Divider
        sx={{
          border: `1px solid ${BorderColor.GREY}`,
          background: 'transparent',
          margin: '5px 0',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {menu[0] && (
          <div>
            <TenantDirectoryMenu
              openMenu={openMenu}
              anchorEl={anchorEl}
              directory={menu[0]}
            />
          </div>
        )}
        {menu[1] && (
          <div>
            <TenantDirectoryMenu
              openMenu={openMenu}
              anchorEl={anchorEl}
              directory={menu[1]}
            />
          </div>
        )}
        {menu[2] && (
          <div>
            <TenantDirectoryMenu
              openMenu={openMenu}
              anchorEl={anchorEl}
              directory={menu[2]}
            />
          </div>
        )}
        {menu[3] && (
          <div>
            <TenantDirectoryMenu
              openMenu={openMenu}
              anchorEl={anchorEl}
              directory={menu[3]}
            />
          </div>
        )}
      </Box>
    </Popper>
  )
}

const mapStateToProject = (state: AllState) => ({
  currentProject: state.project.current,
  assigned: state.project.assigned,
  hasRequiredSaveData: state.hasRequiredSaveData.hasRequiredSaveData,
  directories: state.appFunction.directories,
  organization: state.tenant.organization,
})

export default connect(mapStateToProject)(CommonsTenantMenu)
