import { PageArea, PageProps } from '../index'
import '../../../styles/agGrid.scss'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { MasterSchedule } from './MasterSchedule'
import { CSSProperties, FC, useCallback } from 'react'
import { Box, styled } from '@mui/material'
import { BorderColor } from '../../../styles/commonStyles'
import { ProgressDashboard } from './ProgressDashboard'
import {
  AggregateRoot,
  useMasterScheduleParameter,
} from './hooks/useMasterScheduleParameter'
import { ResourceDashboard } from './ResourceDashboard'
import { useProgressDashboardParameter } from './hooks/useProgressDashboardParameter'
import { useResourceDashboardParameter } from './hooks/useResourceDashboardParameter'
import { useProjectReportConfig } from './hooks/projectReportConfig'
import { ProjectReportConfigButton } from './components/ProjectReportConfigButton'
import { RiskDashboard } from './RiskDashboard'
import { Summary } from './Summary'
import { colorPalette } from '../../style/colorPallete'

export const ProjectReport = projectPrivate((_: PageProps) => {
  const { project } = useProjectPrivateContext()
  const {
    config: {
      aggregateRoot,
      aggregateTarget,
      aggregateField,
      workloadUnit,
      aggregateBaseDate,
    },
    updateAggregateRoot,
    updateAggregateTarget,
    updateAggregateField,
    updateWorkloadUnit,
    updateAggregateBaseDate,
  } = useProjectReportConfig()
  const masterScheduleParameter = useMasterScheduleParameter()
  const progressDashboardParameter = useProgressDashboardParameter()
  const resourceDashboardParameter = useResourceDashboardParameter()
  const switchRoot = useCallback(
    (root: AggregateRoot | undefined) => {
      progressDashboardParameter.setRoot(root)
      resourceDashboardParameter.setRoot(root)
    },
    [progressDashboardParameter, resourceDashboardParameter]
  )
  return (
    <PageArea>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '3px 10px',
          overflowX: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <DashboardArea>
          <DashboardPanel
            style={{
              minHeight: '180px',
              backgroundColor: colorPalette.monotone[1],
            }}
          >
            <Summary
              project={project}
              aggregateRoot={aggregateRoot}
              aggregateTarget={aggregateTarget}
              aggregateField={aggregateField}
              workloadUnit={workloadUnit}
              aggregateBaseDate={aggregateBaseDate}
            />
          </DashboardPanel>
          <DashboardPanel style={{ minHeight: '400px' }}>
            <MasterSchedule
              project={project}
              aggregateRoot={aggregateRoot}
              aggregateTarget={aggregateTarget}
              aggregateField={aggregateField}
              workloadUnit={workloadUnit}
              aggregateBaseDate={aggregateBaseDate}
              updateAggregateRoot={updateAggregateRoot}
            />
          </DashboardPanel>
          <DashboardPanel style={{ height: 'auto' }}>
            <ProgressDashboard
              project={project}
              aggregateRoot={aggregateRoot}
              aggregateTarget={aggregateTarget}
              aggregateField={aggregateField}
              workloadUnit={workloadUnit}
              aggregateBaseDate={aggregateBaseDate}
              updateAggregateRoot={updateAggregateRoot}
            />
          </DashboardPanel>
          <DashboardPanel style={{ height: 'auto' }}>
            <ResourceDashboard
              project={project}
              aggregateRoot={aggregateRoot}
              aggregateTarget={aggregateTarget}
              workloadUnit={workloadUnit}
            />
          </DashboardPanel>
          <DashboardPanel style={{ height: 'auto' }}>
            <RiskDashboard project={project} />
          </DashboardPanel>
        </DashboardArea>
      </Box>
      <ProjectReportConfigArea>
        <ProjectReportConfigButton
          aggregateTarget={aggregateTarget}
          aggregateField={aggregateField}
          workloadUnit={workloadUnit}
          aggregateBaseDate={aggregateBaseDate}
          updateAggregateTarget={updateAggregateTarget}
          updateAggregateField={updateAggregateField}
          updateWorkloadUnit={updateWorkloadUnit}
          updateAggregateBaseDate={updateAggregateBaseDate}
        />
      </ProjectReportConfigArea>
    </PageArea>
  )
})

type PanelProps = {
  children: React.ReactNode
  style?: CSSProperties
}
const DashboardPanel: FC<PanelProps> = ({ children, style, ...props }) => {
  return (
    <Box
      style={{
        width: '100%',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px 0',
        border: `1px solid ${BorderColor.LIGHT_BLACK}80`,
        borderRadius: '5px',
        boxShadow: `1px 2px 4px ${BorderColor.LIGHT_BLACK}80`,
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

const DashboardArea = styled('div')({
  height: '100%',
  width: '100%',
  overflowY: 'scroll',
})

const ProjectReportConfigArea = styled('div')({
  position: 'absolute',
  right: '30px',
  bottom: '20px',
  zIndex: 10,
})
