import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
  ValueGetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { intl } from '../../../../i18n'
import {
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import DateVO from '../../../../vo/DateVO'
import { DateTerm } from '../../../../utils/date'
import { generateYearMonthList } from '../../ProfitLossItems/gridOptions'
import _ from 'lodash'
import { PositionBasic, UserPositionMappingBody } from '../UserPositions'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { PositionCellEditor } from '../components/CellEditor'

export const userPositionsGridOptions = (): GridOptions => {
  return {
    context: {},
    suppressLastEmptyLineOnPaste: true,
    // Row
    treeData: false,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: false,
      suppressMovable: true,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        valueGetter: params => {
          return (params.node?.rowIndex ?? 0) + 1
        },
      },
      {
        groupId: 'information',
        headerName: intl.formatMessage({ id: 'unit.price.information' }),
        children: [
          {
            field: 'body.userCode',
            headerName: intl.formatMessage({ id: 'unit.price.userCode' }),
            width: 90,
            hide: false,
            pinned: true,
            lockPosition: 'left',
            sortable: true,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body.user?.code ?? ''
            },
            filter: ClientSideTextFilter,
            floatingFilter: true,
          },
          {
            field: 'body.user',
            headerName: intl.formatMessage({ id: 'unit.price.user' }),
            width: 120,
            hide: false,
            pinned: true,
            lockPosition: 'left',
            sortable: true,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.body.user?.name ?? ''
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => option,
              getLabel: option => option,
            },
          },
          {
            field: 'body.division',
            headerName: intl.formatMessage({ id: 'unit.price.division' }),
            hide: false,
            pinned: true,
            lockPosition: 'left',
            sortable: true,
            valueGetter: params => {
              return params.data.body.user?.division?.displayName ?? ''
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => option,
              getLabel: option => option,
            },
          },
          {
            field: 'body.position',
            headerName: intl.formatMessage({ id: 'unit.price.position' }),
            hide: false,
            pinned: true,
            lockPosition: 'left',
            sortable: true,
            valueGetter: params => {
              const mappings = params.data.body.mappings ?? []
              const position = getTargetPosition(
                mappings,
                DateVO.now().getFirstDayOfMonth()
              )
              return position?.displayName ?? ''
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: option => option,
              getLabel: option => option,
            },
          },
        ],
      },
    ],
    processCellForClipboard,
    processCellFromClipboard,
  }
}

type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
}

export const POSITION_FIELD_PREFIX = 'body.mappings.position.'

export const isPositionMapColumn = (id: string | undefined) => {
  return !!id && id.startsWith(POSITION_FIELD_PREFIX)
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const colDefs = api.getColumnDefs()
  const newColDefs = colDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(POSITION_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(POSITION_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const now = DateVO.now()
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${POSITION_FIELD_PREFIX}${year}`,
        headerName: `${year} ${intl.formatMessage({ id: 'year' })}`,
        children: [],
      }
    })
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const value = date.format('YYYY/MM')
      const isCurrentMonth = date.isEqual(now.getFirstDayOfMonth())
      const formattedDate = date.formatForApi()
      const col: ColDef = {
        field: `${POSITION_FIELD_PREFIX}${value}`,
        headerName: `${date.getMonth()} ${intl.formatMessage({ id: 'month' })}`,
        width: 80,
        hide: false,
        pinned: false,
        suppressMovable: true,
        editable: true,
        cellRenderer: DefaultCellRenderer,
        cellClassRules: {
          unit_price_input_month: params => {
            const maps = params.data.body?.mappings ?? []
            return maps.some(v => v.validFrom === formattedDate)
          },
          unit_price_interval_month: params => {
            const maps = params.data.body?.mappings ?? []
            return !maps.some(v => v.validFrom === formattedDate)
          },
        },
        cellStyle: (params: CellClassParams) => {
          return isCurrentMonth ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE : {}
        },
        cellRendererParams: params => {
          const mappings: UserPositionMappingBody[] =
            params.data.body?.mappings ?? []
          const value = getTargetPosition(mappings, date)
          return {
            value: value?.displayName ?? '',
          }
        },
        cellEditor: PositionCellEditor,
        cellEditorParams: params => {
          const positions = params.context?.positions ?? []
          return {
            positions,
          }
        },
        valueGetter: params => {
          const mappings: UserPositionMappingBody[] =
            params.data.body?.mappings ?? []
          const value = getTargetPosition(mappings, date)
          return value?.uuid ?? ''
        },
        valueSetter: params => {
          const { colDef, data, oldValue, newValue } = params
          const field = colDef.field || colDef.colId
          if (
            !data.body ||
            !field ||
            (!oldValue && !newValue) ||
            oldValue === newValue
          ) {
            return false
          }
          const positions: PositionBasic[] = params.context.positions ?? []
          const item: PositionBasic | undefined = positions.find(
            v => v.uuid === newValue
          )
          const mappings: UserPositionMappingBody[] = data.body.mappings ?? []
          const basePos: UserPositionMappingBody | undefined = mappings.find(
            v => v.validFrom === formattedDate
          )
          if (
            (basePos && basePos.position.uuid === newValue) ||
            (!basePos && !item)
          ) {
            return false
          }
          store.dispatch(requireSave())
          if (!item) {
            data.body.mappings = data.body.mappings.filter(
              v => v.validFrom !== formattedDate
            )
            data.edited = true
          } else {
            const newPosition: UserPositionMappingBody = {
              position: item,
              validFrom: formattedDate!,
            }
            if (!basePos) {
              data.body.mappings?.push(newPosition)
              data.edited = true
            } else {
              data.body.mappings = data.body.mappings.filter(
                v => v.validFrom !== formattedDate
              )
              data.body.mappings.push(newPosition)
              data.edited = true
            }
          }
          if (!data.editedData) {
            data.editedData = { [field]: oldValue }
          } else if (!data.editedData.hasOwnProperty(field)) {
            data.editedData[field] = oldValue
          }
          return true
        },
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${POSITION_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => newColDefs?.push(year))
  }
  api.setColumnDefs(newColDefs!)
}

const getTargetPosition = (
  mappings: UserPositionMappingBody[],
  date: DateVO
): PositionBasic | undefined => {
  const current = mappings.find(v => v.validFrom === date.formatForApi())
  if (current) {
    return current.position
  }
  const goneDays = mappings
    .map(v => new DateVO(v.validFrom))
    .filter(v => v.isBefore(date))
  if (goneDays && goneDays.length > 0) {
    const latest = DateVO.max(goneDays)
    return mappings.find(v => v.validFrom === latest.formatForApi())?.position
  }
  return undefined
}

const getCandidateUsers = (api: GridApi, members: any, context: any) => {
  const ignores = context.ignoreMembers
  const existsUser: string[] = []
  api.forEachNode(node => {
    existsUser.push(node.data.body.user?.uuid)
  })
  return members
    .filter(v => !existsUser.some(ex => ex === v.uuid))
    .filter(v => !ignores.some(ig => ig.uuid === v.uuid))
}

export const processCellForClipboard = ({
  column,
  context,
  value,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field } = colDef
  if (!value) return value

  if (field?.startsWith(POSITION_FIELD_PREFIX)) {
    const positions: PositionBasic[] = context?.positions ?? []
    return positions.find(p => p.uuid === value)?.displayName ?? ''
  }
  return value
}

export const processCellFromClipboard = ({
  column,
  context,
  value,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field } = colDef

  if (field?.startsWith(POSITION_FIELD_PREFIX)) {
    const positions: PositionBasic[] = context?.positions ?? []
    return positions.find(p => p.displayName === value)?.uuid ?? value
  }
  return value
}
