import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { intl } from '../../../../../../../../i18n'
import { AttachmentSummary } from '../../../../../../../../utils/attachment'

const hasAttachmentLabel = intl.formatMessage({
  id: 'openDetail.hasAttachment',
})
const noAttachmentLabel = intl.formatMessage({
  id: 'openDetail.noAttachment',
})

interface Props {
  filterChangedCallback: () => void
  colDef?: {
    cellRendererParams: {
      getAttachmentSummary?: (row: any) => AttachmentSummary | undefined
    }
  }
}

export default forwardRef((props: Props, ref) => {
  const [options, setOptions] = useState<string[]>([])
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  useEffect(() => {
    if (props.colDef?.cellRendererParams) {
      const { getAttachmentSummary } = props.colDef.cellRendererParams
      const newOptions = [...options]
      if (
        getAttachmentSummary &&
        !options.includes(hasAttachmentLabel) &&
        !options.includes(noAttachmentLabel)
      ) {
        newOptions.push(hasAttachmentLabel, noAttachmentLabel)
      }
      setOptions(newOptions)
    }
  }, [props.colDef?.cellRendererParams?.getAttachmentSummary])

  useEffect(() => {
    setSelectedValues([...options])
  }, [options])

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        if (!props.colDef?.cellRendererParams) {
          return true
        }
        // Filter by comment summary.
        if (props.colDef.cellRendererParams.getAttachmentSummary) {
          const attachmentSummary =
            props.colDef.cellRendererParams.getAttachmentSummary(params.data)
          if (
            selectedValues.includes(hasAttachmentLabel) &&
            attachmentSummary?.hasAttachment
          ) {
            return true
          }
          if (
            selectedValues.includes(noAttachmentLabel) &&
            !attachmentSummary?.hasAttachment
          ) {
            return true
          }
        }
        return false
      },

      isFilterActive() {
        return options.length !== selectedValues.length
      },

      getModel() {
        if (this.isFilterActive()) {
          return { value: selectedValues }
        } else {
          return null
        }
      },

      setModel() {
        setSelectedValues(options)
      },
    }
  })

  const onChange = event => {
    const checked = event.target.checked
    const value = event.target.name
    if (checked) {
      setSelectedValues([...selectedValues, value])
    } else {
      setSelectedValues(selectedValues.filter(v => v !== value))
    }
  }

  useEffect(() => {
    props.filterChangedCallback()
  }, [props.filterChangedCallback, selectedValues])

  return (
    <div
      style={{
        minHeight: 100,
        padding: 2,
      }}
    >
      {options.map(option => (
        <div
          key={option}
          style={{
            height: 23,
          }}
        >
          <input
            type="checkbox"
            key={option}
            id={option}
            name={option}
            style={{
              width: 15,
              height: 15,
              color: '#0091ea',
              backgroundColor: '#ffffff',
              verticalAlign: 'middle',
            }}
            checked={selectedValues.includes(option)}
            onChange={onChange}
          />
          <label
            htmlFor={option}
            style={{
              verticalAlign: 'middle',
            }}
          >
            {option}
          </label>
        </div>
      ))}
    </div>
  )
})
