import TextVO from '../../../vo/TextVO'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import { SubmitType } from '../../containers/meta/ViewMeta'
import { SingleSheetOptions } from '../../containers/SingleSheet'
import { TableData } from '../../containers/SingleSheet/DataTable'
import store from '../../../store'
import ProjectApi, {
  ProjectStatus,
  isValidProjectEndDate,
  isValidProjectStartDate,
} from '../../../lib/functions/project'
import { setAssignedProject } from '../../../store/project'
import SelectVO from '../../../vo/SelectVO'
import { CSSProperties } from 'react'
import DateRangeVO from '../../../vo/DateRangeVO'
import { Color } from '../../../styles/commonStyles'

export class ProjectConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return dest
  }
  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    submitType: SubmitType
    delta?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return {
      before: initialData,
      after: dest,
      delta: delta
        ? {
            uuid: dest.uuid,
            ...delta,
          }
        : undefined,
    }
  }
  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}

export class ProjectData extends TableData {
  code?: TextVO
  displayName?: TextVO
  status?: SelectVO
  scheduledDate?: DateRangeVO
  getCode() {
    return this.code
  }
  getName() {
    return this.displayName
  }
}

export const getProjectStyleRule = (
  externalId: string
):
  | ((data: ProjectData, target?: string) => CSSProperties | undefined)
  | undefined => {
  if (externalId.includes('scheduledDate')) {
    return (data: ProjectData, target?: string) => {
      if (
        'start' === target
          ? !isValidProjectStartDate(
              data.scheduledDate?.getStartDate()?.getValue().valueOf(),
              data.status?.getValue() as ProjectStatus | undefined
            )
          : !isValidProjectEndDate(
              data.scheduledDate?.getEndDate()?.getValue().valueOf(),
              data.status?.getValue() as ProjectStatus | undefined
            )
      ) {
        return {
          color: Color.ALERT,
        }
      }
    }
  }
}

export default class Project extends SingleSheetOptions<ProjectData> {
  converterSpec = new ProjectConverterSpec()
  afterCreate = async () => {
    const response = await ProjectApi.getAssignedProjects()
    store.dispatch(setAssignedProject(response.json))
  }
  canDelete = (data: ProjectData) => {
    const status = data?.status?.getValue()
    return !!status && ProjectStatus.STANDBY === status
  }
  canLogin = data => store.getState().singleSheet.data[0].assigned
  afterDelete = async () => {
    const response = await ProjectApi.getAssignedProjects()
    store.dispatch(setAssignedProject(response.json))
  }
  getStyleRule = getProjectStyleRule
}
