import React from 'react'
import {
  getDefaultSearchFilter,
  SearchConditionProps,
  SearchFilter,
} from './WbsItemSearchConditions/WbsItemSearchCondition'
import SearchWindow from '../../../components/toolbars/ContainerToolBar/SearchWindow'
import WbsItemSearchFilterPanel from './WbsItemSearchFilterPanel'
import { SaveWbsItemSearchConditionDialogProps } from './SaveWbsItemSearchConditionDialog'
import { intl } from '../../../../i18n'
import { injectIntl, WrappedComponentProps } from 'react-intl'

interface Props {
  total?: number
  hit?: number
  searchText: string
  searchFilter?: SearchFilter
  searchConditions: SearchConditionProps[]
  reloadHandler: () => void
  onChangeSearchText: (searchText?: string) => void
  onChangeFilter: (filter?: SearchFilter) => void
  saveDialogProps?: SaveWbsItemSearchConditionDialogProps
  openSavedUIStateDialog?: () => void
}

interface State {
  defaultSearchFilter: SearchFilter
  searchWindowRef?: HTMLElement
}

class WbsItemSearchToolBar extends React.PureComponent<
  Props & WrappedComponentProps,
  State
> {
  state: State = {
    defaultSearchFilter: getDefaultSearchFilter(),
    searchWindowRef: undefined,
  }

  onChangeSearchName = (searchName?: string) => {
    this.props.onChangeFilter({
      ...this.props.searchFilter!,
      displayName: searchName,
    })
  }

  setSearchWindowRef = ref => {
    this.setState({ searchWindowRef: ref })
  }

  render() {
    return (
      <SearchWindow
        reloadHandler={this.props.reloadHandler}
        onChange={this.onChangeSearchName}
        total={this.props.total}
        hit={this.props.hit}
        hideButton={true}
        searchText={this.props.searchFilter?.displayName}
        searchTextPlaceholder={this.props.intl.formatMessage({
          id: 'search.displayName',
        })}
        setSearchWindowRef={this.setSearchWindowRef}
      >
        <WbsItemSearchFilterPanel
          anchorEl={this.state.searchWindowRef}
          searchConditions={this.props.searchConditions}
          searchText={this.props.searchText}
          searchFilter={this.props.searchFilter}
          reloadHandler={this.props.reloadHandler}
          onChangeSearchText={this.props.onChangeSearchText}
          onChangeFilter={this.props.onChangeFilter}
          saveDialogProps={this.props.saveDialogProps}
          openSavedUIStateDialog={this.props.openSavedUIStateDialog}
        />
      </SearchWindow>
    )
  }
}

export default injectIntl(WbsItemSearchToolBar)
