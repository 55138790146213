import _ from 'lodash'
import {
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  ProcessCellForExportParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { OrganizationWorkingDayCalendarDetail } from '../../../../lib/functions/organizationWorkingDayCalendar'
import { ProjectBasic, ProjectDetail } from '../../../../lib/functions/project'
import { UserDetail, UserProps } from '../../../../lib/functions/user'
import { DateTerm, generateYearMonthArray } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import DateTimeVO from '../../../../vo/DateTimeVO'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import {
  ResourcePlanCPGroupRowsByMemberColRowBody,
  ResourcePlanCrossProjectType,
  ResourcePlanCrossProjectsRow,
  RowGroupColumnType,
} from '../ResourcePlanCrossProjects'
import './styles.scss'
import {
  AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER,
  COLUMN_DEF_GROUP_ROW_BY_MEMBER,
  generateWorkMontColDefGroupRowByMember,
} from './columnDefGroupRowsByMemberCol'
import {
  AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_PROJECT,
  COLUMN_DEF_GROUP_ROW_BY_PROJECT,
  generateWorkMontColDefGroupRowByProject,
} from './columnDefGroupRowsByProjectCol'

const WORKMONTHS_FIELD_PREFIX: string = 'body.memberWorkMonths.'
const INTERNAL_YEARMONTH_FORMAT: string = 'YYYY/MM'

export const resourcePlanCrossProjectsGridOptions = (
  rowGroupColumnType: RowGroupColumnType | undefined
): GridOptions => {
  let autoGroupColumnDef: ColDef = {}
  let columnDefs: (ColDef | ColGroupDef)[] | null = []
  switch (rowGroupColumnType) {
    case RowGroupColumnType.MEMBER:
      autoGroupColumnDef = AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER
      columnDefs = COLUMN_DEF_GROUP_ROW_BY_MEMBER
      break
    case RowGroupColumnType.PROJECT:
      autoGroupColumnDef = AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_PROJECT
      columnDefs = COLUMN_DEF_GROUP_ROW_BY_PROJECT
      break
    default:
      break
  }
  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    autoGroupColumnDef,
    getRowStyle: params => {
      const row: ResourcePlanCrossProjectsRow = params.data
      if (row.rowGroupColumnType === RowGroupColumnType.MEMBER) {
        const rowBody = row?.body as ResourcePlanCPGroupRowsByMemberColRowBody
        if (
          row &&
          row.type !== ResourcePlanCrossProjectType.SUMMARY &&
          rowBody &&
          !rowBody.validUser
        ) {
          return { backgroundColor: '#e0e0e0' }
        }
      }
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      hide: false,
      sortable: true,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      resizable: true,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        resource_plan_cross_projects_root_row: params => {
          const row: ResourcePlanCrossProjectsRow = params.data
          return row.type === row.rowGroupColumnType?.toString()
        },
      },
    },
    columnDefs,
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0.00',
        },
      },
      {
        id: 'dateTimeStyle',
        dataType: 'DateTime',
        numberFormat: {
          format: 'yyyy/mm/dd hh:MM:ss',
        },
      },
    ],
    processCellForClipboard,
  }
}

interface WorkingDay {
  businessDays: number
  workHours: number
}

interface WorkingDayCalendar {
  [yearMonth: string]: WorkingDay
}

export const isWorkMonthColumn = (id: string | undefined) => {
  return !!id && id.startsWith(WORKMONTHS_FIELD_PREFIX)
}

export const isWorkMonthColumnDef = (def: ColDef | ColGroupDef) => {
  const field = (def as ColDef)?.field
  const groupId = (def as ColGroupDef)?.groupId
  return isWorkMonthColumn(field) || isWorkMonthColumn(groupId)
}

const getWorkingDayCalendars = (
  workingDays: OrganizationWorkingDayCalendarDetail[],
  yearMonths: string[]
): WorkingDayCalendar => {
  const workingDayCalendars: WorkingDayCalendar = {}
  yearMonths.forEach(yearMonth => {
    const target = workingDays.filter(
      wd => new DateVO(wd.date).format(INTERNAL_YEARMONTH_FORMAT) === yearMonth
    )
    const workHour = target.reduce((pre, value) => {
      return pre + value.workHour
    }, 0)
    const businessDays = target.filter(ym => ym.workHour > 0).length
    workingDayCalendars[yearMonth] = {
      businessDays: businessDays,
      workHours: workHour,
    }
  })
  return workingDayCalendars
}

export type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
  workingDays: OrganizationWorkingDayCalendarDetail[]
  rowGroupColumn: RowGroupColumnType | undefined
}

export const refreshDynamicColumnDef = async ({
  api,
  dateTerm,
  workingDays,
  rowGroupColumn,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return

  let generateWorkMonthColDef:
    | ((
        yearMonth: string,
        getWorkingTimeTotal: (yearMonth: string) => string
      ) => ColDef)
    | undefined = undefined
  switch (rowGroupColumn) {
    case RowGroupColumnType.MEMBER:
      generateWorkMonthColDef = generateWorkMontColDefGroupRowByMember
      break
    case RowGroupColumnType.PROJECT:
      generateWorkMonthColDef = generateWorkMontColDefGroupRowByProject
      break
    default:
      return
  }
  const yearMonthArray = generateYearMonthArray(dateTerm)
  const columnDefs = api.getColumnDefs()
  const workMonthColumnIndex: number | undefined =
    columnDefs?.findIndex(isWorkMonthColumnDef)
  const newColDefs = columnDefs?.filter(def => {
    return !isWorkMonthColumnDef(def)
  })
  if (!_.isEmpty(yearMonthArray)) {
    const workingDayCalendars: WorkingDayCalendar = getWorkingDayCalendars(
      workingDays,
      yearMonthArray
    )
    const getWorkingTimeTotal = (yearMonth: string): string => {
      const target: WorkingDay = workingDayCalendars[yearMonth]
      return `${target ? target.businessDays : 0}d/${
        target ? target.workHours : 0
      }h`
    }

    const yearList = Array.from(
      new Set(
        yearMonthArray.map(value => {
          return new DateVO(value).getYear()
        })
      )
    )
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${WORKMONTHS_FIELD_PREFIX}${year}`,
        headerName: intl.formatMessage(
          { id: 'project.resourcePlan.year' },
          { year }
        ),
        children: [],
      }
    })
    yearMonthArray.forEach(yearMonth => {
      const date = new DateVO(yearMonth)
      const monthHeader: ColGroupDef = {
        headerName: intl.formatMessage(
          { id: 'project.resourcePlan.month' },
          { month: date.getMonth() }
        ),
        groupId:
          WORKMONTHS_FIELD_PREFIX + date.format(INTERNAL_YEARMONTH_FORMAT),
        children: [generateWorkMonthColDef!(yearMonth, getWorkingTimeTotal)],
      }

      const yearHeader = yearCols.find(
        v => v.groupId === `${WORKMONTHS_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(monthHeader)
    })

    yearCols.forEach((year: ColGroupDef, index: number) => {
      if (workMonthColumnIndex) {
        newColDefs?.splice(workMonthColumnIndex + index, 0, year)
      } else {
        newColDefs?.push(year)
      }
    })
  }
  api.setColumnDefs(newColDefs!)
}

export const processCellForClipboard = ({
  column,
  value,
}: ProcessCellForExportParams<ResourcePlanCrossProjectsRow>) => {
  const colDef = column.getColDef()
  const { field, type } = colDef
  if (!value) return value

  if (type) {
    if (type.includes(ColumnType.autocomplete)) {
      return value?.name || value?.displayName || value?.officialName
    }
    if (type.includes(ColumnType.dateTime)) {
      return new DateTimeVO(value).format()
    }
  }
  if (field) {
    if (field === 'body.project') {
      return projectCellValueFormatter(value)
    }
    if (['body.user', 'body.project.projectManager'].includes(field)) {
      return getUserName(value)
    }
  }
  return value
}

export const projectCellValueFormatter = (
  value: string | ProjectBasic | ProjectDetail | undefined
) => {
  if (!value) return ''
  return typeof value === 'string'
    ? value
    : (value as ProjectDetail)?.displayName ||
        (value as ProjectBasic)?.name ||
        ''
}

export const getUserName = (
  value: string | UserDetail | UserProps | undefined
): string => {
  if (!value) return ''
  return typeof value === 'string' ? value : value.name || ''
}
