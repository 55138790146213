import { Dialog, TextField, TextFieldProps, styled } from '@mui/material'
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react'
import { intl } from '../../../../i18n'
import { colorPalette } from '../../../style/colorPallete'
import { useLegacyAuthService } from '../../../../services/adaptors/legacyAuthServiceAdaptor'
import { useDispatch } from 'react-redux'
import { MessageLevel, addScreenMessage } from '../../../../store/messages'

type AttemptChangeUserEmailDialogProps = {
  open?: boolean
  uuid: string
  onClose: () => void
}

export const AttemptChangeUserEmailDialog = ({
  open = true,
  uuid,
  onClose,
}: AttemptChangeUserEmailDialogProps) => {
  const { attemptChangeEmail } = useLegacyAuthService()
  const [value, setValue] = useState<string>('')
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])
  const [error, setError] = useState<SubmitError | undefined>(undefined)
  useEffect(() => {
    if (!open) setError(undefined)
  }, [open])
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const onSubmit = useCallback(async () => {
    setLoading(true)
    const response = await attemptChangeEmail(uuid, value)
    setLoading(false)
    switch (response.status) {
      case 'SUCCEEDED':
        dispatch(
          addScreenMessage('AttemptChangeUserEmailDialog', {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({
              id: 'user.attemptChangeEmail.succeeded',
            }),
          })
        )
        onClose()
        return
      case 'EMAIL_ALREADY_EXISTS':
        setError({
          message: intl.formatMessage({
            id: 'user.attemptChangeEmail.error.emailAlreadyExists',
          }),
        })
        return
      case 'SYSTEM_ERROR':
        setError({
          message: intl.formatMessage({
            id: 'user.attemptChangeEmail.error.systemError',
          }),
        })
        return
    }
  }, [attemptChangeEmail, uuid, value])

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const disabled = useMemo(() => !value || loading, [value, loading])
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Title>
          {intl.formatMessage({ id: 'user.attemptChangeEmail.title' })}
        </Title>
        <HeaderText>
          {intl.formatMessage({ id: 'user.attemptChangeEmail.detail1' })}
        </HeaderText>
        <HeaderText>
          {intl.formatMessage({ id: 'user.attemptChangeEmail.detail2' })}
        </HeaderText>
      </StyledDialogTitle>
      <StyledDialogContent>
        {error && <ErrorMessage error={error} />}
        <Input
          label={intl.formatMessage({ id: 'user.emailCandidate' })}
          onChange={onChange}
          onKeyDown={onEnter}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={onSubmit} color="skyBlue" disabled={disabled}>
          {intl.formatMessage({ id: 'user.attemptChangeEmail' })}
        </StyledButton>
        <StyledButton onClick={onClose} color="monotone">
          {intl.formatMessage({ id: 'dialog.cancel' })}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: '48px 32px',
  },
})

const StyledDialogTitle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  gap: '16px',
})

const StyledDialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '32px',
})

const StyledDialogActions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px 0 16px',
  gap: '16px',
})

const Input = styled((props: TextFieldProps) => (
  <TextField variant="outlined" {...props} />
))({
  width: '360px',
  '& .MuiOutlinedInput-input': {
    height: '14px',
    padding: '20px 16px',
    color: '#2D4069',
    caretColor: '#00A0E9',
    '&:focus': {
      backgroundColor: '#F7FCFF',
    },
    fontSize: '16px !important',
  },
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      borderColor: '#D8DDE5',
    },
    '&:hover fieldset': {
      borderColor: '#00A0E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00A0E9',
      borderWidth: '1px',
    },
  },
})

type StyledButtonProps = {
  color: 'skyBlue' | 'monotone'
  disabled?: boolean
}
const StyledButton = styled('button')(
  ({ color, disabled }: StyledButtonProps) => {
    const customizedStyle = useMemo(() => {
      if (disabled) {
        return {
          border: 'none',
          backgroundColor: '#D8DDE5',
          color: '#7B8CAA',
        }
      }
      switch (color) {
        case 'skyBlue':
          return {
            border: 'none',
            backgroundColor: '#00A0E9',
            color: '#FFFFFF',
            cursor: 'pointer',
          }
        case 'monotone':
          return {
            border: '1px solid #D8DDE5',
            backgroundColor: '#FFFFFF',
            color: '#7B8CAA',
            cursor: 'pointer',
          }
        default:
          return {}
      }
    }, [color, disabled])
    return {
      width: '300px',
      height: '30px',
      fontWeight: '600',
      borderRadius: '4px',
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...customizedStyle,
    }
  }
)

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: colorPalette.monotone[10],
  fontSize: '20px',
  fontWeight: '600',
})

const HeaderText = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: colorPalette.monotone[4],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})

type SubmitError = {
  message: string
}
type ErrorMessageProps = {
  error: SubmitError
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  if (!error) return <></>
  return (
    <ErrorMessageContainer>
      <ErrorMessageTitle>{error.message}</ErrorMessageTitle>
    </ErrorMessageContainer>
  )
}
const ErrorMessageContainer = styled('div')({
  display: 'flex',
  backgroundColor: '#FCF4F7',
  border: '1px solid #ED1E79',
  width: '476px',
  padding: '12px 24px',
  borderRadius: '4px',
  justifyContent: 'center',
  gap: '12px',
  color: '#ED1E79',
  alignItems: 'center',
})

const ErrorMessageTitle = styled('div')({
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'center',
})
