import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

const FORMAT = /#[a-zA-Z0-9]{6}/

export default class ColorVO extends DomainValueObjectBase<string> {
  constructor(_value: string) {
    if (!_value) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'Color value is required.',
      })
    }
    if (typeof _value !== 'string') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: 'Color value must be string value.',
      })
    }
    if (!FORMAT.test(_value)) {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_FORMAT_ERROR,
        message: 'Color format must be #XXXXXX.',
      })
    }
    super(_value)
  }
}
