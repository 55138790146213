import React, { FocusEventHandler, useState } from 'react'
import _ from 'lodash'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  Box,
  Checkbox,
  InputBase,
  MenuItem,
  Popper,
  styled,
} from '@mui/material'
import { ReferencedEntityWithIcon } from '../../../../../../domain/value-object/ReferencedEntity'

type Props = {
  searchOptions: (text?: string) => Promise<ReferencedEntityWithIcon[]>
  value: ReferencedEntityWithIcon[]
  onChange: (value: ReferencedEntityWithIcon[] | null) => void
  disabled: boolean
}
export const FilterAutocomplete = ({
  searchOptions,
  value,
  onChange,
  disabled,
}: Props) => {
  const [options, setOptions] = useState<ReferencedEntityWithIcon[]>([])

  const search = async (searchText?: string) => {
    const newOptions = await searchOptions(searchText)
    setOptions(newOptions)
  }
  const debouncedSearch = _.debounce(
    (searchText?: string) => search(searchText),
    300
  )

  const onFocus: FocusEventHandler<HTMLDivElement> = event => {
    search()
  }

  const onChangeValue = (
    event: React.ChangeEvent<{}>,
    option: ReferencedEntityWithIcon[] | null,
    reason: AutocompleteChangeReason
  ) => {
    onChange(option)
  }

  return (
    <StyledAutocomplete
      disabled={disabled}
      multiple={true}
      options={options}
      autoSelect={false}
      blurOnSelect={false}
      disableCloseOnSelect={true}
      value={value}
      onFocus={onFocus}
      onInputChange={(e, searchText) => {
        debouncedSearch(searchText)
      }}
      filterOptions={o => o}
      onChange={onChangeValue}
      renderOption={(props, option: ReferencedEntityWithIcon, { selected }) => {
        return (
          <MenuItem
            {...props}
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              '& .MuiCheckbox-root': { marginRight: '0px !important' },
              '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
            }}
          >
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
              color={'primary'}
            />
            <Avatar
              variant="circular"
              sx={{
                marginRight: '5px',
                height: '23px',
                width: '23px',
                borderRadius: '50%',
              }}
              src={option.iconUrl}
            />
            <Box sx={{ whiteSpace: 'nowrap' }}>{option.name}</Box>
          </MenuItem>
        )
      }}
      isOptionEqualToValue={(value, option) => value.uuid === option.uuid}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus={true}
          placeholder="Search keyword"
        />
      )}
      PopperComponent={props => (
        <Popper
          {...props}
          placement="bottom-start"
          className="ag-custom-component-popup"
          style={{
            zIndex: 1000,
          }}
        />
      )}
    />
  )
}
const StyledAutocomplete = styled(Autocomplete)({
  width: '100%',
  margin: 2,
  padding: '1px 2px 1px 4px',
  border: '1px solid #95a5a6',
  '&.Mui-focused': {
    border: '1px solid #719ece',
    boxShadow: '0 0 2px 1px #719ece',
  },
}) as typeof Autocomplete
