import { ColDef, ColGroupDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import {
  useRevision,
  useCreatedBy,
  useCreatedAt,
  useUpdatedBy,
  useUpdatedAt,
} from '../../../../page-properties/bulksheet-properties/changeLog'
import { dragAndDrop } from '../../../../page-properties/bulksheet-properties/dragAndDrop'
import { useRowNumber } from '../../../../page-properties/bulksheet-properties/rowNumber'
import { propertyTypeColumnDef } from './propertyType'
import { wbsItemTypesColumnDef } from './wbsItemTypes'
import { entitySearchReferenceEntityColumnDef } from './entitySearchReferenceEntity'
import { requiredColumnDef } from './required'
import { selectOptionsColumnDef } from './selectOptions'
import { usingColumnDef } from './using'
import { useMemo } from 'react'

export const useColumnDefs = (): (ColDef | ColGroupDef)[] => {
  const rowNumberCol = useRowNumber()
  const revisionCol = useRevision('revision')
  const createdByCol = useCreatedBy('createdBy')
  const createdAtCol = useCreatedAt('createdAt')
  const updatedByCol = useUpdatedBy('updatedBy')
  const updatedAtCol = useUpdatedAt('updatedAt')

  const columnDef = useMemo(
    () => [
      {
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        colId: 'blank',
        hide: true,
        width: 35,
        suppressColumnsToolPanel: true,
      },
      rowNumberCol,
      dragAndDrop,
      {
        headerName: intl.formatMessage({
          id: 'wbsItemAdditionalProperties.column.group.header.wbsAdditionalProperty',
        }),
        children: [
          propertyTypeColumnDef,
          wbsItemTypesColumnDef,
          requiredColumnDef,
          selectOptionsColumnDef,
          entitySearchReferenceEntityColumnDef,
          usingColumnDef,
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          revisionCol,
          createdByCol,
          createdAtCol,
          updatedByCol,
          updatedAtCol,
        ],
      },
    ],
    [
      rowNumberCol,
      revisionCol,
      createdByCol,
      createdAtCol,
      updatedByCol,
      updatedAtCol,
    ]
  )
  return columnDef
}
