import { useEffect, useState } from 'react'
import { BurndownChartResult } from '../../../../domain/value-object/BurndownChart'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'
import { BurndownChartCondition } from '../model'

export const useBurndownChartDataSource = (
  condition: BurndownChartCondition
): {
  src: BurndownChartResult | undefined
} => {
  const [src, setSrc] = useState<BurndownChartResult | undefined>()
  const { get } = useBurndownChartRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await get({
        ...condition,
      })
      setSrc(response)
    }
    fn()
  }, [condition, get])
  return {
    src,
  }
}
