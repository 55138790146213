import { styled } from '@mui/system'
import {
  Button,
  DialogContentText as MuiDialogContentText,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { muiTheme } from '../../../styles/muiTheme'
import {
  ComponentProps,
  forwardRef,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react'

type DialogButtonProps = ComponentProps<typeof Button>

export const useDialogButtonRef = (): [
  MutableRefObject<HTMLButtonElement | null>,
  () => void,
  () => void
] => {
  const ref = useRef<HTMLButtonElement>(null)
  const focusButton = useCallback(() => {
    if (ref.current) ref.current.focus()
  }, [ref])
  const blurButton = useCallback(() => {
    if (ref.current) ref.current.blur()
  }, [ref])
  return [ref, focusButton, blurButton]
}

export const DialogButton = styled(
  forwardRef<HTMLButtonElement, DialogButtonProps>((props, ref) => (
    <Button color={'primary'} {...props} ref={ref} />
  ))
)({
  '&:hover, &:focus': {
    backgroundColor: alpha(
      muiTheme.palette.text.primary,
      muiTheme.palette.action.hoverOpacity
    ),
  },
})

export const DialogContentText = styled(MuiDialogContentText)({
  minWidth: 300,
  whiteSpace: 'pre-line',
  paddingLeft: 5,
})
