import { useCallback, useMemo, useState, useRef } from 'react'
import { intl } from '../../../../i18n'
import { Button } from '../../../components/buttons'
import {
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  styled,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import { ProjectReportConfig, UpdateProjectReportConfig } from '../model/config'
import { colorPalette } from '../../../style/colorPallete'
import { AggregateTargetToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/AggregateTargetToggleButtonGroup'
import { AggregateFieldToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/AggregateFieldToggleButtonGroup'
import { WorkloadUnitToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/WorkloadUnitToggleButtonGroup'
import { AggregateBaseDateToggleButtonGroup } from '../../../components/inputs/ToggleButtonGroup/AggregateBaseDateToggleButtonGroup'

type ProjectReportConfigButtonProps = Omit<
  ProjectReportConfig,
  'aggregateRoot'
> &
  Omit<UpdateProjectReportConfig, 'updateAggregateRoot'>

export const ProjectReportConfigButton = ({
  aggregateTarget,
  aggregateField,
  workloadUnit,
  aggregateBaseDate,
  updateAggregateTarget,
  updateAggregateField,
  updateWorkloadUnit,
  updateAggregateBaseDate,
}: ProjectReportConfigButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const active = useMemo(() => {
    return Boolean(anchorEl)
  }, [anchorEl])
  const onClickButton = useCallback(() => {
    if (!active) {
      setAnchorEl(buttonRef.current)
    } else {
      setAnchorEl(null)
    }
  }, [active])
  return (
    <>
      <Button ref={buttonRef} onClick={onClickButton}>
        <ConfigIcon />
        {intl.formatMessage({ id: 'projectReport.config' })}
      </Button>
      <Popper open={active} anchorEl={anchorEl} placement="top-end">
        <ClickAwayListener onClickAway={onClickButton}>
          <Paper>
            <ConfigAreaWrapper>
              <CongigAreaHeader>
                {intl.formatMessage({ id: 'projectReport.modifyConfig' })}
              </CongigAreaHeader>
              <ConfigAreaDivider />
              <ConfigList>
                <ConfigModifierWithTitle>
                  <ConfigModifierTitle>
                    {intl.formatMessage({
                      id: 'projectReport.aggregateTarget',
                    })}
                  </ConfigModifierTitle>
                  <AggregateTargetToggleButtonGroup
                    value={aggregateTarget}
                    onChange={updateAggregateTarget}
                  />
                </ConfigModifierWithTitle>
                <ConfigModifierWithTitle>
                  <ConfigModifierTitle>
                    {intl.formatMessage({ id: 'projectReport.aggregateField' })}
                  </ConfigModifierTitle>
                  <AggregateFieldToggleButtonGroup
                    value={aggregateField}
                    onChange={updateAggregateField}
                  />
                </ConfigModifierWithTitle>
                <ConfigModifierWithTitle>
                  <ConfigModifierTitle>
                    {intl.formatMessage({ id: 'projectReport.workloadUnit' })}
                  </ConfigModifierTitle>
                  <WorkloadUnitToggleButtonGroup
                    value={workloadUnit}
                    onChange={updateWorkloadUnit}
                  />
                </ConfigModifierWithTitle>
                <ConfigModifierWithTitle>
                  <ConfigModifierTitle>
                    {intl.formatMessage({
                      id: 'projectReport.aggregateBaseDate',
                    })}
                  </ConfigModifierTitle>
                  <AggregateBaseDateToggleButtonGroup
                    value={aggregateBaseDate}
                    onChange={updateAggregateBaseDate}
                  />
                </ConfigModifierWithTitle>
              </ConfigList>
            </ConfigAreaWrapper>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

const ConfigIcon = styled(SettingsIcon)({
  height: '14px',
  width: '14px',
})

const ConfigArea = styled(Paper)({
  padding: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
})

const ConfigAreaWrapper = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  borderRadius: '4px',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  width: '200px',
})

const CongigAreaHeader = styled('div')({
  color: colorPalette.monotone[5],
  fontSize: '12px',
  fontWeight: 600,
  padding: '8px',
})

const ConfigAreaDivider = styled(Divider)({
  borderColor: colorPalette.monotone[2],
})

const ConfigList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '4px',
})

const ConfigModifierWithTitle = styled('div')({})

const ConfigModifierTitle = styled('div')({
  fontSize: '11px',
  color: colorPalette.monotone[4],
})
