import { useCallback, useMemo } from 'react'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'
import { BurndownChartCondition } from '../model'
import { ValueDetailType } from '../../../../domain/value-object/BurndownChart'
import { DateBucket } from '../../../components/charts/model/timeSeries'
import { useProjectPrivateContext } from '../../../context/projectContext'
import * as d3 from 'd3'

type ValueDetailResult = {
  openScheduledDetail: () => Promise<void>
  openScheduledDiffDetail: () => Promise<void>
  openScheduledRemainingDetail: () => Promise<void>
  openActualDetail: () => Promise<void>
  openActualDiffDetail: () => Promise<void>
  openActualRemainingDetail: () => Promise<void>
  openDiffScheduledAndActualDetail: () => Promise<void>
  openDelayedDetail: () => Promise<void>
  openDelayedDiffDetail: () => Promise<void>
  openPrecedingDetail: () => Promise<void>
}
export const useValueDetail = (
  condition: BurndownChartCondition,
  teamUuid: string | undefined,
  dateBucket: DateBucket | undefined
): ValueDetailResult => {
  const { projectCode } = useProjectPrivateContext()
  const { getValueDetail } = useBurndownChartRepository()
  const openDetailByType = useCallback(
    async (type: ValueDetailType) => {
      if (!dateBucket) return
      const detail = await getValueDetail({
        ...condition,
        teamUuid,
        from: dateBucket.from,
        to: d3.timeDay.offset(dateBucket.to, 1),
        type,
      })
      // TODO: Consider user feedback.
      if (detail.wbsItemCodes.length === 0) return
      const url = `${
        window.location.origin
      }/wbsItemSearch/${projectCode}?code=${detail.wbsItemCodes.join(',')}`
      window.open(url)
    },
    [condition, dateBucket, getValueDetail, projectCode, teamUuid]
  )
  const openDetails = useMemo(
    () => ({
      openScheduledDetail: () => openDetailByType('SCHEDULED'),
      openScheduledDiffDetail: () => openDetailByType('SCHEDULED_DIFF'),
      openScheduledRemainingDetail: () =>
        openDetailByType('SCHEDULED_REMAINING'),
      openActualDetail: () => openDetailByType('ACTUAL'),
      openActualDiffDetail: () => openDetailByType('ACTUAL_DIFF'),
      openActualRemainingDetail: () => openDetailByType('ACTUAL_REMAINING'),
      openDiffScheduledAndActualDetail: () =>
        openDetailByType('DIFF_SCHEDULED_AND_ACTUAL'),
      openDelayedDetail: () => openDetailByType('DELAYED'),
      openDelayedDiffDetail: () => openDetailByType('DELAYED_DIFF'),
      openPrecedingDetail: () => openDetailByType('PRECEDING'),
    }),
    [openDetailByType]
  )
  return openDetails
}
