import moment from 'moment'
import {
  FunctionProperty,
  PropertyType,
  CustomEnumValue,
} from '../../../lib/commons/appFunction'
import { DISPLAY_DATETIME_FORMAT } from '../../../utils/date'
export interface EntityExtensionValue {
  uuid: string
  value?: any
}
export interface EntityExtensionValueDelta {
  uuid: string
  oldValue: any
  newValue: any
}

export const deserializeExtensionValue = (
  value: any,
  prop: FunctionProperty
) => {
  switch (prop.propertyType) {
    case PropertyType.Checkbox:
    case PropertyType.Switch:
      if (typeof value === 'string') {
        const valueStr = value.toLowerCase()
        if (!['true', 'false'].includes(valueStr)) {
          throw new Error(
            `Can not create SwitchVO instance because source value(${valueStr}) is invalid.`
          )
        }
        return valueStr === 'true'
      }
      break
    case PropertyType.MultiSelect:
      if (prop.valuesAllowed) {
        const valueEnums: CustomEnumValue[] = []
        const valuesAllowed = prop.valuesAllowed
        if (!value) {
          return undefined
        }
        if (Array.isArray(value)) {
          return value
        }
        const valueArray: string[] = value.split(',')
        valueArray.forEach(value => {
          const enumValue = valuesAllowed.find(item => item.value === value)
          if (enumValue) {
            valueEnums.push(enumValue)
          }
        })
        return valueEnums
      }
      break
    case PropertyType.DateTime:
      const numberVal = typeof value === 'string' ? Number(value) : NaN
      const val = !isNaN(numberVal) ? numberVal : value
      return !!val && moment(val).isValid() ? moment(val).toDate() : value
  }
  return value
}

export const serializeExtensionValue = (value: any, prop: FunctionProperty) => {
  switch (prop.propertyType) {
    case PropertyType.Checkbox:
    case PropertyType.Switch:
      return value ? 'TRUE' : 'FALSE'
    case PropertyType.Date:
      return value && value.replace(/\//g, '-')
    case PropertyType.DateTime:
      return !!value
        ? moment(value).isValid()
          ? moment(value).toISOString()
          : moment(value, DISPLAY_DATETIME_FORMAT).toISOString()
        : value
    case PropertyType.MultiSelect:
      return value && value.map(v => v.value).join(',')
    case PropertyType.Number:
      if (value) return value.toString()
  }
  return value
}
