import _ from 'lodash'
import objects from '../utils/objects'

enum ActionType {
  CLEAR_SINGLE_SHEET_DATA = 'CLEAR_SINGLE_SHEET_DATA',
  SET_SINGLE_SHEET_DATA = 'SET_SINGLE_SHEET_DATA',
  APPLY_DIFF = 'APPLY_DIFF',
}

type State = {
  data: any[]
}

export const setData = (data: any) => ({
  type: ActionType.SET_SINGLE_SHEET_DATA,
  data,
})

export const applyDiff = (diff: { [key: string]: any }) => ({
  type: ActionType.APPLY_DIFF,
  diff,
})

export const clearData = () => ({
  type: ActionType.CLEAR_SINGLE_SHEET_DATA,
})

export const reducer = (
  state: State = {
    data: [],
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.SET_SINGLE_SHEET_DATA:
      return {
        data: [
          ...state.data.filter(v => v.uuid !== action.data.uuid),
          action.data,
        ],
      }
    case ActionType.APPLY_DIFF:
      const data = _.cloneDeep(state.data)
      Object.keys(action.diff).forEach(key => {
        objects.setValue(data[data.length - 1], key, action.diff[key])
      })
      return { data: data }
    case ActionType.CLEAR_SINGLE_SHEET_DATA:
      return { data: state.data.slice(0, state.data.length - 1) }
    default:
      return state
  }
}
