import { useCallback, useRef, useState } from 'react'
import _ from 'lodash'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import { APIResponse } from '../../../../lib/commons/api'
import { generateUuid } from '../../../../utils/uuids'
import {
  TagDeleteInput,
  TagInput,
  TagRow,
  TagsBatchInput,
  createNewTagRow,
  updateBatch,
} from '../tag'
import { useDispatch } from 'react-redux'
import { fetchTags } from '../../../../lib/functions/tag'

export const useTagsData = projectUuid => {
  const [data, setDataInternal] = useState<TagRow[]>([])
  const [deletedRows, setDeletedRows] = useState<TagRow[]>([])
  const dispatch = useDispatch()

  const originalData = useRef<
    Map<string, Pick<TagRow, 'projectUuid' | 'name' | 'backgroundColor'>>
  >(new Map())

  const clearData = useCallback(() => {
    setDataInternal([])
    originalData.current.clear()
  }, [])

  const fetchRecords = useCallback(async () => {
    setDataInternal([])
    const response = await fetchTags({
      projectUuid,
    })
    const rows: TagRow[] = response.json

    setDataInternal(rows)
    originalData.current.clear()
    rows.forEach(row => {
      originalData.current.set(
        row.uuid,
        _.cloneDeep({
          projectUuid: row.projectUuid,
          name: row.name,
          backgroundColor: row.backgroundColor,
        })
      )
    })
    setDeletedRows([])
    if (rows.length === 0) {
      const firstRow = createNewTagRow(projectUuid)
      setDataInternal([
        { ...firstRow, added: true, treeValue: [firstRow.uuid] },
      ])
    }
  }, [])

  const refresh = useCallback(async () => {
    await fetchRecords()
    dispatch(doNotRequireSave())
  }, [fetchRecords])

  const updateTags = useCallback(
    async (rows: TagRow[]): Promise<APIResponse> => {
      const edited = rows.filter(row => row.edited)
      const batchInput: TagsBatchInput = {
        added: convertRow2Request(edited.filter(row => row.added)),
        edited: convertRow2Request(
          edited.filter(row => !row.added && row.edited)
        ),
        deleted: deletedRows.map(
          v =>
            ({
              uuid: v.uuid,
              lockVersion: v.lockVersion,
            } as TagDeleteInput)
        ),
        order: {
          projectUuid,
          tagOrder: rows.map((row: TagRow, index: number) => {
            return {
              tagUuid: row.uuid,
              displayOrder: index + 1,
            }
          }),
        },
      }
      return updateBatch(batchInput)
    },
    [deletedRows, projectUuid]
  )

  const save = useCallback(async (): Promise<APIResponse> => {
    const response = await updateTags(data)
    return response
  }, [data, updateTags])

  const convertRow2Request = (items: TagRow[]): TagInput[] => {
    return items.map(row => {
      return {
        uuid: row.uuid ?? generateUuid(),
        projectUuid: row.projectUuid,
        name: row.name ?? '',
        backgroundColor: row.backgroundColor,
      }
    })
  }

  const setData = useCallback((d: TagRow[]) => {
    setDataInternal(d)
    dispatch(requireSave())
  }, [])

  const deleteRows = useCallback(
    (rows: TagRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
    },
    [deletedRows]
  )

  const hasDuplicatedData = useCallback(() => {
    const allName = data.map(v => v.name).filter(v => !!v)
    const distincted = new Set(allName)
    return allName.length !== distincted.size
  }, [data])

  return {
    data,
    setData,
    refresh,
    save,
    clearData,
    deleteRows,
    hasDuplicatedData,
  }
}
