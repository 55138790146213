import { useCallback, useEffect, useMemo, useState } from 'react'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { ProjectDetail } from '../../../../lib/functions/project'
import { getFinancialYear } from '../../../../utils/date'
import store from '../../../../store'
import { PredictionType } from '../ProfitLossSummary'

export type StoredPageState = {
  startDate: string
  endDate: string
  rowHeight: ROW_HEIGHT
  predictionType: string | undefined
}

export const usePageState = (
  functionUuid
): {
  startDate: string
  setStartDate: (_: string) => void
  endDate: string
  setEndDate: (_: string) => void
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
  predictionType: string | undefined
  setPredictionType: (_: string | undefined) => void
  initialized: boolean
} => {
  const organization = useMemo(() => store.getState()?.tenant.organization, [])
  const [startDate, setStartDate] = useState<string>(
    getFinancialYear(organization).formatForApi()!
  )
  const [endDate, setEndDate] = useState<string>(
    getFinancialYear(organization).addMonths(11).formatForApi()!
  )
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [predictionType, setPredictionType] = useState<string | undefined>(
    PredictionType.Forecast
  )
  const [initialized, setInitialized] = useState(false)

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) return
      const payload: StoredPageState = JSON.parse(stored.value)
      payload.startDate && setStartDate(payload.startDate)
      payload.endDate && setEndDate(payload.endDate)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.predictionType && setPredictionType(payload.predictionType)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  const rememberPageState = useCallback(
    (value: StoredPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    restorePageState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      startDate,
      endDate,
      rowHeight,
      predictionType,
    })
  }, [
    endDate,
    initialized,
    predictionType,
    rememberPageState,
    rowHeight,
    startDate,
  ])

  return {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    rowHeight,
    setRowHeight,
    predictionType,
    setPredictionType,
    initialized,
  }
}
