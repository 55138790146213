import {
  GridOptions,
  RowDragCallbackParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { intl } from '../../../../i18n'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'
import {
  LedgerAccountsRow,
  LedgerAccountsType,
  getLedgerAccountsTypeLabel,
} from '../ledgerAccounts'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import objects from '../../../../utils/objects'
import BoolExpression from '../../../../utils/boolExpression'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import { LedgerAccountsSequenceNoCellRenderer } from '../components/Cell/LedgerAccountsSequenceNoCellRenderer'

export const ledgerAccountsGridOptions = (): GridOptions => {
  return {
    // Grid context
    context: {},
    // Styling
    groupHeaderHeight: 25,
    headerHeight: 25,
    rowHeight: ROW_HEIGHT.SMALL,
    // Row Grouping
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    autoGroupColumnDef: {
      field: 'body.displayName',
      headerName: intl.formatMessage({ id: 'ledgerAccounts.displayName' }),
      width: 200,
      pinned: true,
      cellRendererParams: {
        innerRenderer: DefaultCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        uiMeta: {
          requiredIf: BoolExpression.of(true),
        } as Partial<FunctionProperty>,
      },
      editable: true,
      filter: ClientSideTextFilter,
      floatingFilter: true,
      cellClassRules: {
        'hover-over-can-drop': params => {
          return (
            params.context?.onTree &&
            params.context?.draggableNodeId &&
            params.node?.id === params.context.draggableNodeId
          )
        },
      },
    },
    // Row actions
    rowDragManaged: false,
    rowDragMultiRow: true,
    suppressMoveWhenRowDragging: true,
    // Column definition
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      ...defaultColDef(),
      cellRenderer: DefaultCellRenderer,
      floatingFilter: true,
      hide: false,
      valueSetter: (params: ValueSetterParams) => {
        const { colDef, data, oldValue, newValue } = params
        const field = colDef.field || colDef.colId
        if (!field || (!oldValue && !newValue) || oldValue === newValue) {
          return false
        }
        objects.setValue(data, field, newValue)
        data.edited = true
        if (!data.editedData) {
          data.editedData = { [field]: oldValue }
        } else if (!data.editedData.hasOwnProperty(field)) {
          data.editedData[field] = oldValue
        }
        store.dispatch(requireSave())
        return true
      },
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
      },
    },
    columnDefs: [
      {
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
      },
      {
        field: 'drag',
        headerName: '',
        type: [ColumnType.drag],
        rowDrag: (params: RowDragCallbackParams<LedgerAccountsRow>): boolean =>
          LedgerAccountsType.FinancialStatementAccounts !==
          params.data?.body.ledgerAccountsType,
        cellClassRules: {
          'hover-over-can-drop': params => {
            return (
              !params.context?.onTree &&
              params.context?.draggableNodeId &&
              params.node?.id === params.context?.draggableNodeId
            )
          },
        },
      },
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: LedgerAccountsSequenceNoCellRenderer,
      },
      {
        headerName: intl.formatMessage({ id: 'ledgerAccounts.information' }),
        children: [
          {
            field: 'body.code',
            headerName: intl.formatMessage({ id: 'ledgerAccounts.code' }),
            pinned: true,
            lockPosition: true,
            width: 90,
            editable: params => params.data.added,
          },
          {
            field: 'body.ledgerAccountsType',
            filter: null,
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.ledgerAccountsType',
            }),
            pinned: true,
            lockPosition: true,
            width: 100,
            valueGetter: (params: ValueGetterParams) => {
              const row: LedgerAccountsRow = params.data
              return getLedgerAccountsTypeLabel(row.body.ledgerAccountsType)
            },
          },
          {
            field: 'body.officialName',
            headerName: intl.formatMessage({
              id: 'ledgerAccounts.officialName',
            }),
            editable: true,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          {
            field: 'revision',
            headerName: intl.formatMessage({ id: 'revision' }),
            hide: true,
            width: 90,
          },
          {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: 'createdBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.createdBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'createdBy.name',
              iconUrlField: 'createdBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            field: 'createdAt',
            headerName: intl.formatMessage({ id: 'createdAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
          {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: 'updatedBy' }),
            hide: true,
            width: 150,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.updatedBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'updatedBy.name',
              iconUrlField: 'updatedBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            field: 'updatedAt',
            headerName: intl.formatMessage({ id: 'updatedAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
        ],
      },
    ],
    // Footer
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum', 'count'],
          },
          align: 'left',
        },
      ],
    },
    localeText: {
      sum: intl.formatMessage({
        id: 'bulksheet.statusPanel.sum',
      }),
      count: intl.formatMessage({
        id: 'bulksheet.statusPanel.count',
      }),
      totalAndFilteredRows: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
      }),
      of: intl.formatMessage({
        id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
      }),
    },
  }
}
