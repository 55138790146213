import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

export default class LockVersionVO extends DomainValueObjectBase<number> {
  constructor(_value: number) {
    if (_value === null || _value === undefined) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'Lock version value is required.',
      })
    }
    if (typeof _value !== 'number') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: `Lock version value(${_value}) must be number value.`,
      })
    }
    if (_value < 0) {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: `Lock version value(${_value}) must be >= 0.`,
      })
    }
    super(_value)
  }
}
