import React, { useCallback } from 'react'
import DateVO from '../../../../../../../../../vo/DateVO'
import Tooltip from '../../../../../../../../components/tooltips/Tooltip'
import { GanttCellRendererProps } from './index'
import { GanttColor } from '../../../../../../../../../styles/commonStyles'
import { WbsItemStatus } from '../../wbsItemStatus'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import objects from '../../../../../../../../../utils/objects'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'
import { intl } from '../../../../../../../../../i18n'

const now = new Date()
const today = CalendarDateVO.of(now)
const endOfToday = CalendarDateVO.of(new DateVO(now).addDays(1).toDate())

type Props = WrappedComponentProps & GanttCellRendererProps

export const GanttBackground = injectIntl((props: Props) => {
  const dataPath = props.dataPath ? props.dataPath + '.' : ''
  const { status, scheduledDate, actualDate } = objects.getValue(
    props.data,
    `${dataPath}wbsItem`
  )
  const cumulation = objects.getValue(props.data, `${dataPath}cumulation`)
  if (!status || !scheduledDate || !actualDate) return <></>

  const wbsIsDoneOrDiscard = [
    WbsItemStatus.DONE,
    WbsItemStatus.DISCARD,
  ].includes(status)
  const wbsIsReview = WbsItemStatus.REVIEW === status

  const toCalendarDate = useCallback(date => {
    return date ? CalendarDateVO.of(date) : undefined
  }, [])

  const scheduledStart = toCalendarDate(scheduledDate.startDate)
  const scheduledEnd = toCalendarDate(scheduledDate.endDate)
  const actualStart = toCalendarDate(actualDate.startDate)
  const actualEnd = toCalendarDate(actualDate.endDate)

  const maxScheduledDate = cumulation?.maxScheduledEndDate
    ? CalendarDateVO.of(cumulation.maxScheduledEndDate)
    : undefined

  const message = useCallback(id => intl.formatMessage({ id }), [])

  return (
    <>
      {props.timeScale.slice(0, props.timeScale.length - 1).map((date, i) => {
        const isToday = props.parameter.unit === 'day' && date.isEqual(today)
        const isHoliday = props.parameter.unit === 'day' && date.isHoliday()
        const childScheduleIsOutOfRange =
          scheduledEnd &&
          date.isAfter(scheduledEnd) &&
          maxScheduledDate &&
          (date.isEqual(maxScheduledDate) || date.isBefore(maxScheduledDate))
        const startDelayed =
          !actualStart &&
          !actualEnd &&
          !!scheduledStart &&
          scheduledStart.isBefore(endOfToday) &&
          (date.isEqual(scheduledStart) ||
            (date.isBetween(scheduledStart, endOfToday) &&
              !date.isEqual(endOfToday)))
        const isActualTerm =
          actualStart &&
          (date.isEqual(actualStart) ||
            (!!actualEnd && date.isEqual(actualEnd ?? endOfToday)) ||
            (date.isBetween(actualStart, actualEnd ?? endOfToday) &&
              !date.isEqual(actualEnd ?? endOfToday)))
        const endDelayed =
          !!scheduledEnd && date.isAfter(scheduledEnd) && !wbsIsDoneOrDiscard
        return (
          <div
            key={`gantt-${props.node.rowIndex}-${i}`}
            style={{
              height: '100%',
              width: '100%',
              borderLeft: `1px solid ${GanttColor.BORDER_GRID}`,
              marginTop: '-1px',
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            {isToday ? (
              <Background className="sevend-ag-cell-gantt-background--today" />
            ) : isHoliday ? (
              <Background className="sevend-ag-cell-gantt-background--holiday" />
            ) : undefined}
            {childScheduleIsOutOfRange && (
              <Tooltip message={message('wbs.scheduledToBeExceeded')}>
                <Background className="sevend-ag-cell-gantt-background--warning" />
              </Tooltip>
            )}
            {startDelayed && (
              <Tooltip message={message('wbs.start.delayed')} delay={600}>
                <BehindScheduledStartBar />
              </Tooltip>
            )}
            {isActualTerm && (
              <Tooltip
                message={endDelayed ? message('wbs.end.delayed') : undefined}
                delay={600}
              >
                <ActualResultBar
                  delayed={+endDelayed}
                  review={+(wbsIsReview && !!actualEnd)}
                  done={+wbsIsDoneOrDiscard}
                />
              </Tooltip>
            )}
          </div>
        )
      })}
    </>
  )
})

const Background = React.forwardRef<HTMLDivElement, { className: string }>(
  ({ className, ...tooltipProps }, ref) => {
    return (
      <div
        {...tooltipProps}
        className={'sevend-ag-cell-gantt-background ' + className}
        ref={ref}
      />
    )
  }
)

const BehindScheduledStartBar = React.forwardRef<HTMLSpanElement>(
  ({ ...tooltipProps }, ref) => (
    <span
      {...tooltipProps}
      className="sevend-ag-cell-gantt-background__behind-scheduled-start-bar"
      ref={ref}
    />
  )
)

const ActualResultBar = React.forwardRef<
  HTMLSpanElement,
  {
    delayed: number
    review: number
    done: number
  }
>(({ delayed, review, done, ...tooltipProps }, ref) => {
  let customClassName =
    'sevend-ag-cell-gantt-background__actual-result-bar--scheduled'
  if (review) {
    customClassName =
      'sevend-ag-cell-gantt-background__actual-result-bar--review'
  } else if (done) {
    customClassName = 'sevend-ag-cell-gantt-background__actual-result-bar--done'
  } else if (delayed) {
    customClassName = 'sevend-ag-cell-gantt-background__actual-result-bar--late'
  }
  return (
    <span
      {...tooltipProps}
      className={
        'sevend-ag-cell-gantt-background__actual-result-bar ' + customClassName
      }
      ref={ref}
    />
  )
})
