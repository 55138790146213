import { useState } from 'react'
import { intl } from '../../../../i18n'
import MultipleToggleButton from '../../../components/editors/toggle/MultipleToggleGroup'
import { QuickFilterKey } from '../SprintOptions'

type SprintQuickFilterToggleButtonsProps = {
  quickFilters: QuickFilterKey[]
  onChangeQuickFilters: (values: QuickFilterKey[]) => void
}

const QuickFilterToggleButtons = ({
  quickFilters,
  onChangeQuickFilters,
}: SprintQuickFilterToggleButtonsProps) => {
  return (
    <MultipleToggleButton
      values={quickFilters}
      options={[
        {
          value: QuickFilterKey.NOT_DONE,
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.quickFilter.label.notDone',
          }),
        },
      ]}
      onChange={(values: string[]) => {
        onChangeQuickFilters(values as QuickFilterKey[])
      }}
    />
  )
}

export default QuickFilterToggleButtons
