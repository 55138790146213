import React from 'react'
import { TableData } from '../..'
import VOBase from '../../../../../../vo/base'
import { ValidationError } from '../../../../meta/validator'
import { CellDef } from '../../../../meta/ViewMeta/SingleSheetViewMeta'
import * as validator from '../../validator'

export interface CellPropsBase<V extends VOBase> {
  value: V
  data: TableData
  colSpan: number
  cellDef: CellDef
  onChange: (value: V | undefined) => void
  setError?: (error: ValidationError | undefined) => void
}

export interface CellStateBase {
  error?: ValidationError
  cellUniqueError?: ValidationError
}

export default abstract class DataCellBase<
  V extends VOBase,
  P extends CellPropsBase<V>,
  S extends CellStateBase
> extends React.Component<P, S> {
  abstract validate: (value: V) => ValidationError | undefined

  private validateValue() {
    const { value, data, cellDef } = this.props
    const error = validator.validate(
      value,
      data,
      cellDef.uiMeta,
      cellDef.viewMeta
    )
    this.setState({ error: error })
    this.props.setError &&
      this.props.setError(error ?? this.state?.cellUniqueError)
  }

  componentDidMount() {
    this.setState({ error: undefined })
    this.validateValue()
  }

  componentDidUpdate(prevProps: P, prevState: S) {
    if (prevProps.value !== this.props.value) {
      this.validateValue()
    }
  }
}
