import { Paper } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { CommitVO } from '../../../../domain/value-object/development/CommitVO'
import { CommitField } from './CommitField'
import { useDevelopmentByWbsItem } from '../../../../applications/usecases/development/getDevelopmentByWbsItem'

type DevelopmentPanelProps = {
  uuid: string
}

const DevelopmentPanelRoot = styled('div')({
  height: '100%',
  overflowY: 'scroll',
})

export const DevelopmentPanelArea = ({ uuid }: DevelopmentPanelProps) => {
  const [commits, setCommits] = useState<CommitVO[]>([])
  const { getDevelopmentByWbsItem } = useDevelopmentByWbsItem()
  useEffect(() => {
    const fn = async () => {
      const resp = await getDevelopmentByWbsItem(uuid)
      setCommits(
        // order by timestamp desc.
        resp.commits.sort((a, b) =>
          a.timestamp.isBefore(b.timestamp) ? 1 : -1
        )
      )
    }
    fn()
  }, [uuid])
  return (
    <DevelopmentPanelRoot>
      {commits.map((commit, i) => (
        <CommitField key={i} commit={commit} />
      ))}
    </DevelopmentPanelRoot>
  )
}

export const DevelopmentPanelPaper = styled(Paper)({
  margin: '12px 6px',
  padding: '10px',
  display: 'flex',
})

export const DevelopmentPanelTopicIconArea = styled('div')({
  width: '22px',
  height: '100%',
  padding: '7px 0',
})

export const DevelopmentPanelMainArea = styled('div')({
  width: 'calc(100% - 22px)',
})

export const DevelopmentPanelRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '2px 0',
  width: '100%',
})

export const DevelopmentPanelTitle = styled('span')({
  fontSize: '16px',
  fontWeight: 600,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export const DevelopmentPanelCaption = styled('span')({
  color: '#888888',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  margin: '0 4px',
  fontSize: '12px',
})

export const DevelopmentPanelIconArea = styled('span')({
  display: 'flex',
  margin: '0 3px',
})
