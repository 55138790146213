import { useCallback, useState } from 'react'
import {
  WbsItemDeltaInput,
  WbsItemRow,
} from '../../../../lib/functions/wbsItem'

export interface StatusChangePopperParams {
  anchorEl: EventTarget
  wbsItem: WbsItemRow
  wbsItemDelta: Partial<WbsItemDeltaInput>
}

export const useDialog = () => {
  const [cancelConfirmation, setCancelConfirmation] = useState(false)

  const [actualResult, setActualResult] = useState<boolean>(false)
  const [taskUuid, setTaskUuid] = useState<string>()

  const [statusPopperParams, setStatusPopperParams] = useState<
    Partial<StatusChangePopperParams>
  >({})

  const [uiState, setUiState] = useState<boolean>(false)

  const [excelExport, setExcelExport] = useState<boolean>(false)

  // cancel confirmation
  const openCancelConfirmation = useCallback(() => {
    setCancelConfirmation(true)
  }, [])

  const closeCancelConfirmation = useCallback(() => {
    setCancelConfirmation(false)
  }, [])

  // actual result
  const openActualResult = useCallback((uuid: string) => {
    setTaskUuid(uuid)
    setActualResult(true)
  }, [])

  const closeActualResult = useCallback(() => {
    setActualResult(false)
    setTaskUuid(undefined)
  }, [])

  // status
  const openStatusPopper = useCallback(
    (
      el: EventTarget,
      wbsItem: WbsItemRow,
      delta: Partial<WbsItemDeltaInput>
    ) => {
      setStatusPopperParams({
        anchorEl: el,
        wbsItem,
        wbsItemDelta: delta,
      })
    },
    []
  )

  // UI State
  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  const closeStatusPopper = useCallback(() => {
    setStatusPopperParams({})
  }, [])

  // Excel Ouput
  const openExcelExport = useCallback(() => {
    setExcelExport(true)
  }, [])

  const closeExcelExport = useCallback(() => {
    setExcelExport(false)
  }, [])

  return {
    cancelConfirmation,
    openCancelConfirmation,
    closeCancelConfirmation,

    actualResult,
    taskUuid,
    openActualResult,
    closeActualResult,

    statusPopperParams,
    openStatusPopper,
    closeStatusPopper,

    uiState,
    openUiState,
    closeUiState,

    excelExport,
    openExcelExport,
    closeExcelExport,
  }
}
