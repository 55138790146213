import { intl } from '../i18n'

export type CheckResult = {
  ok: boolean
  message?: string
}

enum Operator {
  isTrue = 'isTrue',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}

const OK = { ok: true }

export default class BoolExpression {
  readonly expression: string
  readonly isTrue: boolean
  readonly isFalse: boolean
  readonly relatedField: string
  readonly operator: Operator | undefined
  readonly comparedValue: string | undefined

  private constructor(expression: string) {
    this.expression = expression
    this.isTrue = this.expression === 'TRUE'
    this.isFalse = this.expression === 'FALSE'
    if (this.expression !== 'FALSE') {
      const elements = expression.split(/\s+/)
      if (elements.length > 0) {
        this.relatedField = elements[0]
        this.operator = Operator[elements[1]]
        if (elements.length === 3) {
          this.comparedValue = elements[2]
        }
        return
      }
    }
    this.relatedField = ''
    this.operator = undefined
  }

  public static of(expression: string | boolean): BoolExpression {
    if (typeof expression === 'undefined' || expression == null) {
      return new BoolExpression('FALSE')
    }
    if (typeof expression === 'boolean') {
      return new BoolExpression(expression ? 'TRUE' : 'FALSE')
    }
    if (expression === '') {
      return new BoolExpression('FALSE')
    }
    return new BoolExpression(expression)
  }

  public check = (
    value: any,
    propertyAccessor: (externalId: string) => any
  ): CheckResult => {
    const isEmpty = (value: any) => {
      return value === undefined || value === null || value === ''
    }
    if (this.isTrue) {
      if (!isEmpty(value)) {
        return OK
      }
      return {
        ok: false,
        message: intl.formatMessage({ id: 'boolExpression.requiredField' }),
      }
    } else if (this.relatedField) {
      const relatedProperty = propertyAccessor(this.relatedField)
      const relatedValue = relatedProperty.value
      switch (this.operator) {
        case Operator.isTrue:
          if (!relatedValue || !isEmpty(value)) {
            return OK
          }
          return {
            ok: false,
            message: intl.formatMessage(
              { id: 'boolExpression.requiredFieldIfOn' },
              { relatedProperty: relatedProperty.name }
            ),
          }
        case Operator.isEmpty:
          if (!isEmpty(relatedValue) || !isEmpty(value)) {
            return OK
          }
          return {
            ok: false,
            message: intl.formatMessage(
              { id: 'boolExpression.requiredFieldIf' },
              { relatedProperty: relatedProperty.name }
            ),
          }
        case Operator.isNotEmpty:
          if (isEmpty(relatedValue) || !isEmpty(value)) {
            return OK
          }
          return {
            ok: false,
            message: intl.formatMessage(
              { id: 'boolExpression.requiredFieldIfNot' },
              { relatedProperty: relatedProperty.name }
            ),
          }
      }
    }
    return OK
  }
}

export const TRUE = BoolExpression.of('TRUE')
export const FALSE = BoolExpression.of('FALSE')
