import { useCallback } from 'react'
import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { ProfitLossMemberRow } from '../ProfitLossMembersNew'
import { isBeforeSameNode } from '../gridOptions'

export const useExportExcel = (gridOptions: GridOptions): (() => void) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined

      const row: ProfitLossMemberRow = params?.node?.data
      const colId: string = params.column.getColId()
      if (colId === 'body.generalLedger') {
        return !isBeforeSameNode(params.api, params.node, row)
          ? row?.body?.generalLedger?.displayName
          : ' '
      } else if (colId === 'body.subsidiary') {
        return !isBeforeSameNode(params.api, params.node, row)
          ? row?.body?.subsidiary?.displayName
          : ' '
      } else if (colId === 'body.financialState') {
        return !isBeforeSameNode(params.api, params.node, row)
          ? row?.body?.financialState?.displayName
          : ' '
      } else if (colId === 'body.user') {
        return !isBeforeSameNode(params.api, params.node, row)
          ? row?.body?.user?.name
          : ' '
      } else if (colId === 'body.position') {
        return !isBeforeSameNode(params.api, params.node, row)
          ? row?.body?.position?.displayName
          : ' '
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'profit_loss_members',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      getCustomExportValue: customExportValues,
    })
  }, [gridOptions, customExportValues])

  return onExportExcel
}
