import React from 'react'
import { styled } from '@mui/system'
import { IStatusPanelParams } from 'ag-grid-community'
import { WbsItemStatus } from '../cell/custom/wbsItemStatus'
import {
  getWbsItemStatusDeepColorCode,
  getWbsItemStatusLabel,
} from '../../../../../../lib/functions/wbsItem'
import { TextColor } from '../../../../../../styles/commonStyles'

const RootDiv = styled('div')({
  display: 'flex',
})
const StatusLabel = styled('span')({
  margin: '0 3px',
})
const StatusCount = styled('span')({
  color: TextColor.BLACK,
})

interface Props extends IStatusPanelParams {}

interface State {
  count: { [key: string]: number }
}

class CountWbsStatus extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { count: {} }
    // Calculate count for the first time when row nodes are ready.
    // Since somehow gridReady event cannot be listened, use firstDataRendered instead.
    props.api.addEventListener('firstDataRendered', this.updateCount)
    // Recalculate count each time the status is changed.
    props.api.addEventListener('rowDataUpdated', this.updateCount)
    // Recalculate count each time the filter is modified and applied.
    props.api.addEventListener('filterChanged', this.updateCount)
  }

  private updateCount = () => {
    const api = this.props.api
    const count = {}
    api.forEachNodeAfterFilter(node => {
      if (!node.data) {
        return
      }
      const status = node.data.wbsItem.status
      if (!status) {
        return
      }
      if (typeof count[status] === 'undefined') {
        count[status] = 0
      }
      count[status] = count[status] + 1
    })
    this.setState({ count })
  }
  render() {
    const { count } = this.state
    return (
      <RootDiv>
        {Object.values(WbsItemStatus).map(
          (status, index) =>
            typeof count[status] !== 'undefined' && (
              <StatusLabel key={index}>
                <span
                  style={{
                    background: getWbsItemStatusDeepColorCode(status),
                  }}
                >
                  {getWbsItemStatusLabel(status)}:
                </span>
                <StatusCount> {count[status]}</StatusCount>
              </StatusLabel>
            )
        )}
      </RootDiv>
    )
  }
}

export default CountWbsStatus
