import {
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useSyncExternalStore,
} from 'react'
import {
  ChartDimensions,
  MarginDimensions,
  WrapperDimensions,
} from '../../model/chart'

export const useChartDimensions = (
  fixedDimension: Partial<WrapperDimensions> & MarginDimensions
): [RefObject<HTMLDivElement>, ChartDimensions] => {
  const ref = useRef<HTMLDivElement>(null)
  const subscribe = useCallback((onStoreChange: () => void) => {
    const observer = new ResizeObserver(entries => {
      entries.forEach(el => {
        onStoreChange()
      })
    })
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  const width = useSyncExternalStore(subscribe, () => {
    return ref.current?.offsetWidth || 0
  })
  const height = useSyncExternalStore(subscribe, () => {
    return ref.current?.offsetHeight || 0
  })
  const dimensions = useMemo(() => {
    const wrapperWidth = fixedDimension.width || width
    const wrapperHeight = fixedDimension.height || height
    return {
      ...fixedDimension,
      width: fixedDimension.width || width,
      height: fixedDimension.height || height,
      boundedWidth: Math.max(
        wrapperWidth - (fixedDimension.marginLeft + fixedDimension.marginRight),
        0
      ),
      boundedHeight: Math.max(
        wrapperHeight -
          (fixedDimension.marginTop + fixedDimension.marginBottom),
        0
      ),
    }
  }, [fixedDimension, width, height])
  return [ref, dimensions]
}
