import * as React from 'react'

function SvgRoundChatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={26.667}
      height={26.667}
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth={1}
        d="M4.19 18.28C-.56 12.91.59 8.44 4.12 4.91c6.22-5.66 19.47-3.07 19.72 6.75-.34 7.31-7.65 10.62-15.31 9.12l-5.81 2.66 1.47-5.16z"
      />
    </svg>
  )
}

export default SvgRoundChatIcon
