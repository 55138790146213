import { Epic, ofType } from 'redux-observable'
import { mergeMap, map } from 'rxjs/operators'
import AppFunction, { Directory, Function } from '../lib/commons/appFunction'
import { getFunctionByPath } from '../view/pages'

enum ActionType {
  FETCH_FUNCTIONS = 'FETCH_FUNCTIONS',
  RECEIVED_FUNCTIONS = 'RECEIVED_FUNCTIONS',
  SELECT_FUNCTION = 'SELECT_FUNCTION',
}

type State = {
  directories: Directory[]
  functions: Function[]
  currentExternalId?: string
}

export const fetchFunctions = () => ({
  type: ActionType.FETCH_FUNCTIONS,
})

export const selectFunction = (externalId: string) => ({
  type: ActionType.SELECT_FUNCTION,
  externalId,
})

export const receivedFunctions = (directories: Directory[]) => ({
  type: ActionType.RECEIVED_FUNCTIONS,
  directories,
})

export const fetchFunctionsEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_FUNCTIONS),
    mergeMap(async action => {
      const response = await AppFunction.getFunctions()
      return { directories: response.json.directories }
    }),
    map(result => receivedFunctions(result.directories))
  )

const filterFunction = (directory: Directory) => {
  const functions =
    directory.functions && directory.functions.length > 0
      ? directory.functions
      : []
  if (!directory.directories || directory.directories.length === 0) {
    return functions
  }
  return [...functions, ...directory.directories.flatMap(filterFunction)]
}

export const reducer = (
  state: State = { directories: [], functions: [] },
  action: any
): State => {
  switch (action.type) {
    case ActionType.RECEIVED_FUNCTIONS: {
      const currentFunction = getFunctionByPath(document.location.pathname)
      return {
        directories: action.directories,
        functions: action.directories.flatMap(filterFunction),
        currentExternalId:
          (currentFunction && currentFunction.externalId) || undefined,
      }
    }
    case ActionType.SELECT_FUNCTION: {
      const currentFunction = state.functions.filter(
        v => v.externalId === action.externalId
      )
      return {
        ...state,
        currentExternalId:
          (currentFunction &&
            currentFunction.length > 0 &&
            currentFunction[0].externalId) ||
          undefined,
      }
    }
    default:
      return state
  }
}
