import { ValueFormatterParams } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'

const DEFAULT_DISPLAY_NAME_PROP = 'name'
// This is adjusted to sprint, since currently this column type is used only in sprint.
// TODO: implement the logic to get order key by the type of reference entity.
const DEFAULT_SORT_KEY_PROP = 'num'

export default (params: ValueFormatterParams) => {
  const displayNameProperty =
    params.colDef.cellEditorParams.displayNameProperty ||
    DEFAULT_DISPLAY_NAME_PROP
  const sortKey =
    params.colDef.cellEditorParams.sortKey || DEFAULT_SORT_KEY_PROP
  if (!Array.isArray(params.value)) {
    return params.value
  }
  return params.value
    .sort((a, b) => objects.getValue(b, sortKey) - objects.getValue(a, sortKey))
    .map(value => objects.getValue(value, displayNameProperty))
    .join(', ')
}
