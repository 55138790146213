import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from './../../../../domain/entity/WbsItemEntity/index'
import { useCallback, useState } from 'react'
import { ProjectReportConfig } from '../model/config'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { DateBound } from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import { AggregateRoot } from './useMasterScheduleParameter'

export const useProjectReportConfig = () => {
  const [config, setConfig] = useState<ProjectReportConfig>({
    aggregateRoot: undefined,
    aggregateTarget: WbsItemType.TASK,
    aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.DAY,
    aggregateBaseDate: DateBound.END,
  })
  const updateAggregateTarget = useCallback((newValue: AggregateTarget) => {
    setConfig(prev => ({
      ...prev,
      aggregateTarget: newValue,
    }))
  }, [])
  const updateAggregateField = useCallback((newValue: AggregateField) => {
    setConfig(prev => ({
      ...prev,
      aggregateField: newValue,
    }))
  }, [])
  const updateWorkloadUnit = useCallback((newValue: WorkloadUnit) => {
    setConfig(prev => ({
      ...prev,
      workloadUnit: newValue,
    }))
  }, [])
  const updateAggregateBaseDate = useCallback((newValue: DateBound) => {
    setConfig(prev => ({
      ...prev,
      aggregateBaseDate: newValue,
    }))
  }, [])
  const updateAggregateRoot = useCallback(
    (newValue: AggregateRoot | undefined) => {
      setConfig(prev => ({
        ...prev,
        aggregateRoot: newValue,
      }))
    },
    []
  )
  return {
    config,
    updateAggregateTarget,
    updateAggregateField,
    updateWorkloadUnit,
    updateAggregateBaseDate,
    updateAggregateRoot,
  }
}
