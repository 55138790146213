import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColDef,
  Column,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowDragEvent,
  RowHeightParams,
  RowNode,
} from 'ag-grid-community'
import { intl } from '../../../i18n'
import { dateVoService } from '../../../domain/value-object/DateVO'
import { DateTerm, generateYearMonthArray } from '../../../utils/date'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import Loading from '../../components/process-state-notifications/Loading'
import { BulkSheetView } from '../../containers/BulkSheetView'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import {
  collapseAllRows,
  expandAllRows,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import {
  getRowNumber,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import {
  InputError,
  addInputError,
} from '../../containers/BulkSheetView/lib/validation'
import { useProjectPrivateContext } from '../../context/projectContext'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { PageArea, PageProps } from '..'
import {
  getRootNodeForMemberView,
  getRootNodeForTeamView,
  refreshDynamicColumnDef,
  resourcePlanGridOptions,
} from './gridOptions'
import {
  addMemberMenuItemForMemberView,
  addMemberMenuItemForTeamView,
  addTeamMenuItemForMemberView,
  addTeamMenuItemForTeamView,
  deleteRowMenuItemForMemberView,
  deleteRowMenuItemForTeamView,
  getAddableMembersForMemberView,
  getAddableMembersForTeamView,
  getExcludeTeamUuidsForMemberView,
  getExcludeTeamUuidsForTeamView,
  getRowsToRemoveForMemberView,
  getRowsToRemoveForTeamView,
} from './gridOptions/contextMenu'
import {
  ResourcePlanBulkSheetState,
  useBulkSheetState,
} from './hooks/bulkSheetState'
import { useMasterData } from './hooks/masterData'
import { usePageState } from './hooks/pageState'
import { useResourcePlantData } from './hooks/resourcePlanData'
import {
  ResourcePlanRow,
  RowGroupColumnType,
  UNSET_TEAM_GROUP_ROW_UUID,
  addMemberRow,
  addMemberRowForTeamView,
  addTeamToRowsGroupByMember,
  addTeamToRowsGroupByTeam,
  deleteRowsForMemberView,
  deleteRowsForTeamView,
  createNewResourcePlanNewRow,
  createUnsetTeamGroupRow,
  getRowDisplayName,
  sortByUuid,
} from './resourcePlan'
import { useExcel } from './hooks/excel'
import { useDialog } from './hooks/dialog'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import ResourcePlanHeader from './components/Header'
import { ExcelExportDialog } from './components/ExcelExportDialog'
import {
  WorkingDayCalendar,
  getWorkingDayCalendars,
  isWorkMonthColumn,
} from './gridOptions/common'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ROW_HEIGHT } from '../../containers/BulkSheet'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE, KEY_SPACE } from '../../model/keyBind'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import validator from '../../containers/meta/validator'
import store, { AllState } from '../../../store'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { useDragTreeStyle } from '../../containers/BulkSheetView/hooks/gridEvents/treeRowDrag'
import { sortByRowIndex } from '../../containers/BulkSheetView/lib/rowNode'
import {
  slideRowsAfter,
  slideRowsBefore,
} from '../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import { connect } from 'react-redux'
import { FunctionLayer } from '../../../store/functionLayer'
import { Box } from '@mui/material'
import { ResourcePlanType } from '../../../lib/functions/resourcePlanNew'
import { TeamProps } from '../../../lib/functions/team'
import { UserDetail } from '../../../lib/functions/user'
import DateVO from '../../../vo/DateVO'

type StateProps = {
  functionLayers: Immutable.Map<number, FunctionLayer>
}
type Props = PageProps & StateProps

const BulkSheetDiv = ({
  visible,
  children,
}: {
  visible: boolean
  children: JSX.Element
}) => {
  return (
    <Box
      sx={visible ? { height: '100%', display: 'block' } : { display: 'none' }}
    >
      {children}
    </Box>
  )
}

const ResourcePlan = ({ uuid, functionLayers }: Props) => {
  const { project } = useProjectPrivateContext()
  const functionUuid: string = uuid
  const memberViewRef = useRef<HTMLDivElement>(null)
  const teamViewRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState<boolean>(false)

  // girid definition
  const memberViewGridOptions: GridOptions = useMemo(
    () => resourcePlanGridOptions(RowGroupColumnType.MEMBER),
    []
  )
  const teamViewGridOptions: GridOptions = useMemo(
    () => resourcePlanGridOptions(RowGroupColumnType.TEAM),
    []
  )
  const gridOptionsArray: GridOptions[] = useMemo(
    () => [memberViewGridOptions, teamViewGridOptions],
    [memberViewGridOptions, teamViewGridOptions]
  )
  useEffect(() => {
    memberViewGridOptions.context = {
      ...memberViewGridOptions.context,
      syncGrid: teamViewGridOptions,
    }
    teamViewGridOptions.context = {
      ...teamViewGridOptions.context,
      syncGrid: memberViewGridOptions,
    }
  }, [memberViewGridOptions, teamViewGridOptions])

  // page state
  const pageState = usePageState(functionUuid)
  const dialogState = useDialog()
  const columnSetting = useColumnSetting()

  // bulkSheetState
  const memberViewBulkSheetState = useBulkSheetState(
    memberViewGridOptions,
    functionUuid,
    `${UiStateKey.ResourcePlanMemberView}-${project.uuid}`
  )
  const teamViewBulkSheetState = useBulkSheetState(
    teamViewGridOptions,
    functionUuid,
    `${UiStateKey.ResourcePlanTeamView}-${project.uuid}`
  )

  const getActiveGridRef = useCallback((): HTMLDivElement | null => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        return memberViewRef.current
      case RowGroupColumnType.TEAM:
        return teamViewRef.current
      default:
        return memberViewRef.current
    }
  }, [pageState.rowGroupColumn, memberViewRef, teamViewRef])

  const getActiveGridOptions = useCallback(
    (rowGroupColumn: RowGroupColumnType | undefined) => {
      switch (rowGroupColumn) {
        case RowGroupColumnType.MEMBER:
          return memberViewGridOptions
        case RowGroupColumnType.TEAM:
          return teamViewGridOptions
        default:
          return memberViewGridOptions
      }
    },
    [memberViewGridOptions, teamViewGridOptions]
  )

  const activeGridOptions = useMemo((): GridOptions => {
    return getActiveGridOptions(pageState.rowGroupColumn)
  }, [pageState.rowGroupColumn, getActiveGridOptions])

  const activeBulkSheetState = useMemo((): ResourcePlanBulkSheetState => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        return memberViewBulkSheetState
      case RowGroupColumnType.TEAM:
        return teamViewBulkSheetState
      default:
        return memberViewBulkSheetState
    }
  }, [
    pageState.rowGroupColumn,
    memberViewBulkSheetState,
    teamViewBulkSheetState,
  ])

  const activeBulkSheetUiStateKey: UiStateKey = useMemo(() => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanMemberView
      case RowGroupColumnType.TEAM:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanTeamView
      default:
        return UiStateKey.BulkSheetUIStateColumnAndFilterResourcePlanMemberView
    }
  }, [pageState.rowGroupColumn])

  // excel
  const excel = useExcel(activeGridOptions)

  // master data
  const { fetchWorkingDayCalendars, allCanAddMembers, teams } = useMasterData(
    project.uuid
  )

  // grid data
  const {
    rowsGroupByMember,
    rowsGroupByTeam,
    summaryRows,
    memberOrder,
    teamOrder,
    fetchRecords,
    setRowsGroupByMember,
    setRowsGroupByTeam,
    rememberDeletedRows,
    save,
  } = useResourcePlantData(project.uuid)

  const prevRowsGroupByMember = useRef<ResourcePlanRow[]>([])
  prevRowsGroupByMember.current = rowsGroupByMember
  const hasChanged = useCallback((): boolean => {
    return (prevRowsGroupByMember.current || rowsGroupByMember).some(
      v => v.added || v.edited
    )
  }, [rowsGroupByMember])

  const refreshAll = useCallback(async () => {
    try {
      setLoading(true)
      await fetchRecords(pageState.dateTerm)
      activeBulkSheetState.restoreExpandedRows()
    } finally {
      gridOptionsArray.forEach(options => {
        options.context = {
          ...options.context,
          draggableNodeId: undefined,
          errors: new InputError(),
        }
      })
      setLoading(false)
    }
  }, [gridOptionsArray, fetchRecords, pageState.dateTerm, activeBulkSheetState])

  // refreshDynamicColumns
  const yearMonthArray = useMemo(
    () => generateYearMonthArray(pageState.dateTerm),
    [pageState.dateTerm]
  )
  const workingDays = useRef<WorkingDayCalendar>({})

  const refreshDynamicColumns = useCallback(
    (rowGroupColumn?: RowGroupColumnType) => {
      const rowGroup = rowGroupColumn ?? pageState.rowGroupColumn
      const gridOption = getActiveGridOptions(rowGroup)

      const asyncRefreshDynamicColumns = async () => {
        const calendarKeys: string[] = Object.keys(workingDays.current)
        if (
          !_.isEmpty(yearMonthArray) &&
          yearMonthArray.some(yearMonth => !calendarKeys.includes(yearMonth))
        ) {
          const calendar = await fetchWorkingDayCalendars(pageState.dateTerm)
          workingDays.current = getWorkingDayCalendars(calendar, yearMonthArray)
        }
        const columnApi = gridOption.columnApi
        if (columnApi) {
          const states = columnApi
            ?.getColumns()
            ?.filter((c: Column) => {
              if (c.isVisible()) return false

              const parent = c.getParent()
              if (!parent || !isWorkMonthColumn(parent.getGroupId())) {
                return false
              }
              return dateVoService.isBetween(
                dateVoService.construct(c.getColId()),
                dateVoService.construct(pageState.dateTerm.startDate),
                dateVoService.construct(pageState.dateTerm.endDate),
                '[]'
              )
            })
            .map(c => ({
              colId: c.getColId(),
              hide: false,
            }))
          columnApi.applyColumnState({
            state: states,
          })
        }
        const gridApi = gridOption.api
        if (gridApi) {
          refreshDynamicColumnDef(
            gridApi,
            yearMonthArray,
            workingDays.current,
            rowGroup
          )
        }
      }
      asyncRefreshDynamicColumns()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeGridOptions, pageState.dateTerm, yearMonthArray]
  )

  useEffect(
    () => {
      if (memberViewGridOptions.api && pageState.initialized) {
        refreshDynamicColumns(RowGroupColumnType.MEMBER)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memberViewGridOptions, pageState.initialized, pageState.dateTerm]
  )
  useEffect(
    () => {
      if (teamViewGridOptions.api && pageState.initialized) {
        refreshDynamicColumns(RowGroupColumnType.TEAM)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamViewGridOptions, pageState.initialized, pageState.dateTerm]
  )
  useEffect(
    () => {
      if (pageState.initialized) {
        refreshAll()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageState.initialized, pageState.dateTerm, teams]
  )

  // drag row
  const acceptChild = (
    parent: ResourcePlanRow,
    child: ResourcePlanRow
  ): boolean => {
    return false
  }

  const onRowDragEnd = useCallback(
    (
      event: RowDragEvent,
      draggableNodeId: string,
      rows: ResourcePlanRow[],
      setRows: (row: ResourcePlanRow[]) => void,
      refreshDragStyle: () => void
    ): ResourcePlanRow[] | undefined => {
      const overNodeId = event.overNode?.id
      if (!overNodeId || overNodeId !== draggableNodeId) {
        return
      }
      try {
        const ids = event.nodes.map(v => v.id)
        const topLevelNodes = event.nodes.filter(
          n => !ids.includes(n.parent?.id)
        )
        const movedData = sortByRowIndex(topLevelNodes).map(node => node.data)
        const movedRows =
          event.overIndex > (event.node.rowIndex || 0)
            ? slideRowsAfter(rows, movedData, overNodeId)
            : slideRowsBefore(rows, movedData, overNodeId)
        setRows(movedRows)
        return movedRows
      } finally {
        refreshDragStyle()
      }
    },
    []
  )

  const dragTreeStyleForMemberView = useDragTreeStyle(
    memberViewRef,
    acceptChild,
    memberViewGridOptions
  )

  const sortChildrenRows = useCallback(
    (
      rows: ResourcePlanRow[],
      rootRowTypes: ResourcePlanType[],
      sortRowTypes: ResourcePlanType[],
      setRows: (row: ResourcePlanRow[]) => void,
      sort: (a: ResourcePlanRow, b: ResourcePlanRow) => number
    ) => {
      const workRows = rows.concat()
      rows.forEach((row: ResourcePlanRow, index: number) => {
        if (!row.type || !rootRowTypes.includes(row.type)) return
        const targetChildren: ResourcePlanRow[] = rows
          .filter(
            (child: ResourcePlanRow) =>
              child.type &&
              sortRowTypes.includes(child.type) &&
              child.treeValue[0] === row.uuid
          )
          .concat()
        const sortedChildren = targetChildren.sort(sort)
        workRows.splice(index + 1, sortedChildren.length, ...sortedChildren)
      })
      setRows(workRows)
    },
    []
  )

  const onRowDragEndForMemberView = useCallback(
    (event: RowDragEvent) => {
      const movedRows = onRowDragEnd(
        event,
        memberViewGridOptions.context.draggableNodeId,
        rowsGroupByMember,
        setRowsGroupByMember,
        dragTreeStyleForMemberView.refreshDragStyle
      )
      if (!movedRows) return

      const memberUuids: string[] = movedRows
        .filter((row: ResourcePlanRow) => row.type === ResourcePlanType.MEMBER)
        .map((row: ResourcePlanRow) => row.body?.user?.uuid)
        .filter(uuid => !!uuid) as string[]

      sortChildrenRows(
        rowsGroupByTeam,
        [ResourcePlanType.TEAM, ResourcePlanType.UNSET],
        [ResourcePlanType.MEMBER],
        setRowsGroupByTeam,
        (a: ResourcePlanRow, b: ResourcePlanRow) =>
          sortByUuid(a.body.user, b.body.user, memberUuids)
      )
    },
    [
      onRowDragEnd,
      memberViewGridOptions.context,
      rowsGroupByMember,
      rowsGroupByTeam,
      setRowsGroupByMember,
      setRowsGroupByTeam,
      dragTreeStyleForMemberView,
      sortChildrenRows,
    ]
  )

  const dragTreeStyleForTeamView = useDragTreeStyle(
    teamViewRef,
    acceptChild,
    teamViewGridOptions
  )

  const onRowDragEndForTeamView = useCallback(
    (event: RowDragEvent) => {
      const movedRows = onRowDragEnd(
        event,
        teamViewGridOptions.context.draggableNodeId,
        rowsGroupByTeam,
        setRowsGroupByTeam,
        dragTreeStyleForTeamView.refreshDragStyle
      )
      if (!movedRows) return

      const teamUuids: string[] = movedRows
        .filter((row: ResourcePlanRow) => row.type === ResourcePlanType.TEAM)
        .map((row: ResourcePlanRow) => row.body?.team?.uuid)
        .filter(uuid => !!uuid) as string[]

      sortChildrenRows(
        rowsGroupByMember,
        [ResourcePlanType.MEMBER],
        [ResourcePlanType.TEAM],
        setRowsGroupByMember,
        (a: ResourcePlanRow, b: ResourcePlanRow) =>
          sortByUuid(a.body.team, b.body.team, teamUuids)
      )
    },
    [
      onRowDragEnd,
      teamViewGridOptions.context,
      rowsGroupByMember,
      setRowsGroupByMember,
      rowsGroupByTeam,
      setRowsGroupByTeam,
      dragTreeStyleForTeamView,
      sortChildrenRows,
    ]
  )

  // Header event
  const onChangeDateTerm = useCallback(
    (dateTerm: DateTerm) => {
      if (!!dateTerm.startDate) {
        pageState.setStartDate(dateTerm.startDate)
      }
      if (!!dateTerm.endDate) {
        pageState.setEndDate(dateTerm.endDate)
      }
    },
    [pageState]
  )

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialogState.openCancelConfirm()
    } else {
      refreshAll()
    }
  }, [dialogState, hasChanged, refreshAll])

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      const gridApi = activeGridOptions.api
      if (!column || !gridApi) return
      const key = column.colId || column.field
      if (!key) return
      const filterModel = gridApi.getFilterModel()
      delete filterModel[key]
      gridApi.setFilterModel(filterModel)
    },
    [activeGridOptions]
  )

  const resetFilters = useCallback(() => {
    const gridApi = activeGridOptions.api
    if (!gridApi) return
    gridApi.setFilterModel([])
  }, [activeGridOptions])

  const getDisplayColumnName = useCallback(
    (colDef: ColDef) => {
      if (!activeGridOptions.columnApi) return
      const column = activeGridOptions.columnApi?.getColumn(colDef.colId)
      const parentColumnDef = column?.getParent()?.getColGroupDef()
      if (parentColumnDef && isWorkMonthColumn(parentColumnDef.groupId)) {
        return new DateVO(column!.getColId()).format(
          intl.formatMessage({ id: 'dateFormat.yyyy.mm' })
        )
      }
      return colDef.headerName
    },
    [activeGridOptions.columnApi]
  )

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      const columnApi = activeGridOptions.columnApi
      if (!columnApi) return
      columnApi.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [activeGridOptions]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    const columnApi = activeGridOptions.columnApi
    if (!columnApi) return
    columnApi.applyColumnState({
      defaultState: { sort: null },
    })
  }, [activeGridOptions])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      const columnApi = activeGridOptions.columnApi
      if (!columnApi) return
      columnApi.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [activeGridOptions]
  )

  const onChangeRowGroupColumn = useCallback(
    (type: RowGroupColumnType | undefined) => {
      pageState.setRowGroupColumn(type)
      columnSetting.close()
      getActiveGridOptions(type).api?.refreshCells({ force: true })
    },
    [pageState, columnSetting, getActiveGridOptions]
  )

  const onConfirmedCancel = useCallback(() => {
    refreshAll()
    dialogState.closeCancelConfirm()
  }, [dialogState, refreshAll])

  interface ErrorUnsetInfo {
    userUuid: string
    colDef: ColDef
    memberManMonth: number
    teamManMonth: number
  }

  const validateBeforeSubmit = useCallback(() => {
    const gridOptions = memberViewGridOptions
    if (
      !gridOptions.api ||
      !gridOptions.columnApi ||
      !gridOptions.context.errors
    ) {
      return
    }

    const colIds = gridOptions.columnApi
      .getColumnState()
      .map(colState => colState.colId)

    rowsGroupByMember
      .filter(row => row.body.user && (row.added || row.edited))
      .forEach(row => {
        const node = gridOptions.api!.getRowNode(row.uuid)
        if (!node) return

        colIds.forEach(colId => {
          const val = gridOptions.api!.getValue(colId, node)
          const colDef = gridOptions.columnApi!.getColumn(colId)?.getColDef()
          if (!colDef || !colDef.cellRendererParams) return
          const uiMeta = colDef.cellRendererParams.uiMeta
          const err = uiMeta
            ? validator
                .validate(val, row, uiMeta, () => undefined)
                ?.getMessage()
            : undefined
          if (err) {
            addInputError(gridOptions.context.errors, node.id, err, colDef)
          }
        })
      })
  }, [memberViewGridOptions, rowsGroupByMember])

  const onSubmit = useCallback(async () => {
    const gridOptions = getActiveGridOptions(pageState.rowGroupColumn)
    if (!gridOptions.api) return
    try {
      setLoading(true)
      gridOptions.api?.stopEditing()
      validateBeforeSubmit()
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }
      memberViewBulkSheetState.base.saveImmediately()
      const response = await save()
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(uuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    uuid,
    save,
    validateBeforeSubmit,
    memberViewBulkSheetState,
    refreshAll,
    getActiveGridOptions,
    pageState.rowGroupColumn,
  ])

  useEffect(() => {
    gridOptionsArray.forEach((gridOptions: GridOptions) =>
      gridOptions.api?.resetRowHeights()
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  // BulkSheet Event
  // context menu
  const getExcludeTeamUuids = useCallback((): string[] => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        if (!memberViewGridOptions.api) return []
        return getExcludeTeamUuidsForMemberView(
          getSelectedNode(memberViewGridOptions.api)
        )
      case RowGroupColumnType.TEAM:
        return getExcludeTeamUuidsForTeamView(rowsGroupByTeam)
      default:
        return []
    }
  }, [pageState.rowGroupColumn, memberViewGridOptions.api, rowsGroupByTeam])

  const memberViewContextMenu = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const selectedNodes = getSelectedNode(params.api)
      return [
        addMemberMenuItemForMemberView(
          params,
          allCanAddMembers,
          dialogState.openAddMember
        ),
        addTeamMenuItemForMemberView(
          selectedNodes,
          teams,
          getExcludeTeamUuids(),
          dialogState.openAddTeam
        ),
        'separator',
        deleteRowMenuItemForMemberView(
          selectedNodes,
          dialogState.openDeleteConfirm
        ),
        'separator',
        expandAllRows(params),
        collapseAllRows(params),
      ].filter(m => !!m)
    },
    [
      allCanAddMembers,
      dialogState.openAddMember,
      teams,
      dialogState.openAddTeam,
      dialogState.openDeleteConfirm,
      getExcludeTeamUuids,
    ]
  )

  const getAddableMembers = useCallback((): UserDetail[] => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        return getAddableMembersForMemberView(
          memberViewGridOptions.api,
          allCanAddMembers
        )
      case RowGroupColumnType.TEAM:
        if (!teamViewGridOptions.api) return []
        return getAddableMembersForTeamView(
          getSelectedNode(teamViewGridOptions.api),
          allCanAddMembers
        )
      default:
        return []
    }
  }, [
    pageState.rowGroupColumn,
    memberViewGridOptions.api,
    teamViewGridOptions.api,
    allCanAddMembers,
  ])

  const getAddMemberDialogSelectList =
    useCallback((): MultiSelectDialogSelection[] => {
      return (
        getAddableMembers()?.map(m => ({
          value: m.uuid,
          iconUrl: m.iconUrl,
          displayName: m.name,
        })) || []
      )
    }, [getAddableMembers])

  const addTeamViewUnsetMemberRow = useCallback(
    (
      teamViewUnsetRow: ResourcePlanRow,
      addUnsetMemberRows: ResourcePlanRow[],
      sourceRows: ResourcePlanRow[],
      memberOrder: string[]
    ): ResourcePlanRow[] => {
      const unsetRow = teamViewUnsetRow ?? createUnsetTeamGroupRow()
      const childrenOfUnsetRow: ResourcePlanRow[] = sourceRows
        .filter((row: ResourcePlanRow) => row.treeValue[0] === unsetRow?.uuid)
        .concat()
      childrenOfUnsetRow.push(...addUnsetMemberRows)
      childrenOfUnsetRow.sort((a: ResourcePlanRow, b: ResourcePlanRow) =>
        sortByUuid(a.body?.user, b.body?.user, memberOrder)
      )
      const newTeamViewRows = sourceRows.filter(
        (row: ResourcePlanRow) => row.treeValue[0] !== unsetRow?.uuid
      )
      newTeamViewRows.push(unsetRow)
      newTeamViewRows.push(...childrenOfUnsetRow)
      return newTeamViewRows
    },
    []
  )

  const addMemberForMemberView = useCallback(
    (
      allList: MultiSelectDialogSelection[],
      selectedItem: MultiSelectDialogSelection[]
    ) => {
      let newRow = rowsGroupByMember

      const teamViewRows = rowsGroupByTeam.concat()
      const teamViewUnsetRow =
        teamViewRows.find(
          (row: ResourcePlanRow) => row.type === ResourcePlanType.UNSET
        ) ?? createUnsetTeamGroupRow()
      const addUnsetMemberRows: ResourcePlanRow[] = []
      selectedItem.forEach((item: MultiSelectDialogSelection) => {
        const addMember = allCanAddMembers.find(
          member => member.uuid === item.value
        )
        if (addMember) {
          newRow = addMemberRow(addMember, memberOrder, newRow)
          addUnsetMemberRows.push(
            createNewResourcePlanNewRow({
              type: ResourcePlanType.MEMBER,
              user: addMember,
              team: teamViewUnsetRow?.body.team,
              parentUuid: teamViewUnsetRow?.uuid,
            })
          )
        }
      })
      setRowsGroupByMember(newRow)

      const newTeamViewRows = addTeamViewUnsetMemberRow(
        teamViewUnsetRow,
        addUnsetMemberRows,
        teamViewRows,
        newRow
          .map((row: ResourcePlanRow) => row.body.user?.uuid)
          .filter(uuid => !!uuid) as string[]
      )
      setRowsGroupByTeam(newTeamViewRows)
    },
    [
      memberOrder,
      allCanAddMembers,
      rowsGroupByMember,
      rowsGroupByTeam,
      setRowsGroupByMember,
      setRowsGroupByTeam,
      addTeamViewUnsetMemberRow,
    ]
  )

  const addMemberForTeamView = useCallback(
    (
      allList: MultiSelectDialogSelection[],
      selectedItem: MultiSelectDialogSelection[]
    ) => {
      if (!teamViewGridOptions.api) return
      const selectedNodes = getSelectedNode(teamViewGridOptions.api)
      const selectedTeam = new Set<TeamProps>(
        selectedNodes
          .map((node: RowNode<ResourcePlanRow>) => {
            return getRootNodeForTeamView(node)?.data?.body.team
          })
          .filter(team => !!team) as TeamProps[]
      )
      let newRowsGroupByMember = rowsGroupByMember
      let newRowsGroupByTeam = rowsGroupByTeam
      const teamViewUnsetRow =
        newRowsGroupByTeam.find(
          (row: ResourcePlanRow) => row.type === ResourcePlanType.UNSET
        ) ?? createUnsetTeamGroupRow()
      const addUnsetMemberRows: ResourcePlanRow[] = []
      selectedTeam.forEach((team: TeamProps) => {
        selectedItem.forEach((item: MultiSelectDialogSelection) => {
          const addMember = allCanAddMembers.find(
            member => member.uuid === item.value
          )
          if (!addMember) return
          newRowsGroupByTeam = addMemberRowForTeamView(
            addMember,
            team,
            newRowsGroupByTeam,
            memberOrder
          )

          const targetMemberRow = newRowsGroupByMember.find(
            (row: ResourcePlanRow) =>
              row.type === ResourcePlanType.MEMBER &&
              row.body.user?.uuid === addMember.uuid
          )
          if (!targetMemberRow) {
            newRowsGroupByMember = addMemberRow(
              addMember,
              memberOrder,
              newRowsGroupByMember
            )
            addUnsetMemberRows.push(
              createNewResourcePlanNewRow({
                type: ResourcePlanType.MEMBER,
                user: addMember,
                team: teamViewUnsetRow?.body.team,
                parentUuid: teamViewUnsetRow?.uuid,
              })
            )
          }
          newRowsGroupByMember = addTeamToRowsGroupByMember(
            targetMemberRow ??
              newRowsGroupByMember.find(
                (row: ResourcePlanRow) =>
                  row.type === ResourcePlanType.MEMBER &&
                  row.body.user?.uuid === addMember.uuid
              ),
            team,
            teamOrder,
            newRowsGroupByMember
          )
        })
      })
      setRowsGroupByMember(newRowsGroupByMember)
      if (!_.isEmpty(addUnsetMemberRows)) {
        newRowsGroupByTeam = addTeamViewUnsetMemberRow(
          teamViewUnsetRow,
          addUnsetMemberRows,
          newRowsGroupByTeam,
          newRowsGroupByMember
            .map((row: ResourcePlanRow) => row.body.user?.uuid)
            .filter(uuid => !!uuid) as string[]
        )
      }
      setRowsGroupByTeam(newRowsGroupByTeam)
    },
    [
      memberOrder,
      teamOrder,
      teamViewGridOptions.api,
      rowsGroupByMember,
      rowsGroupByTeam,
      allCanAddMembers,
      setRowsGroupByMember,
      setRowsGroupByTeam,
      addTeamViewUnsetMemberRow,
    ]
  )

  const onSubmitAddMemberDialog = useCallback(
    (
      allList: MultiSelectDialogSelection[],
      selectedItem: MultiSelectDialogSelection[]
    ) => {
      switch (pageState.rowGroupColumn) {
        case RowGroupColumnType.MEMBER:
          addMemberForMemberView(allList, selectedItem)
          break
        case RowGroupColumnType.TEAM:
          addMemberForTeamView(allList, selectedItem)
          break
        default:
          break
      }
    },
    [pageState.rowGroupColumn, addMemberForMemberView, addMemberForTeamView]
  )

  const getAddTeamDialogSelectList =
    useCallback((): MultiSelectDialogSelection[] => {
      return (
        teams?.map(team => ({
          value: team.uuid,
          iconUrl: team.iconUrl,
          displayName: team.displayName,
        })) || []
      )
    }, [teams])

  const addTeamsForMemberView = useCallback(
    (addTeams: TeamProps[]) => {
      if (!memberViewGridOptions.api) return
      const selectedNodes: RowNode<ResourcePlanRow>[] = getSelectedNode(
        memberViewGridOptions.api
      )
      const rootNodeMap = selectedNodes.reduce(
        (
          map: Map<string, RowNode<ResourcePlanRow>>,
          selectedNode: RowNode<ResourcePlanRow>
        ) => {
          const parentNode = getRootNodeForMemberView(selectedNode)
          const uuid = parentNode?.data?.uuid
          if (uuid && !map.has(uuid)) {
            map.set(uuid, parentNode)
          }
          return map
        },
        new Map<string, RowNode<ResourcePlanRow>>()
      )
      if (rootNodeMap.size <= 0) return

      let newRowsGroupByMember = rowsGroupByMember
      let newRowsGroupByTeam = rowsGroupByTeam
      Array.from(rootNodeMap.values()).forEach(
        (selectedNode: RowNode<ResourcePlanRow>) => {
          const parentNode: RowNode<ResourcePlanRow> | null =
            getRootNodeForMemberView(selectedNode)

          addTeams.forEach((addTeam: TeamProps | undefined) => {
            if (!addTeam) return
            newRowsGroupByMember = addTeamToRowsGroupByMember(
              parentNode?.data,
              addTeam,
              teamOrder,
              newRowsGroupByMember
            )
            newRowsGroupByTeam = addTeamToRowsGroupByTeam(
              parentNode?.data,
              addTeam,
              teamOrder,
              memberOrder,
              newRowsGroupByTeam
            )
          })
        }
      )
      setRowsGroupByMember(newRowsGroupByMember)
      setRowsGroupByTeam(newRowsGroupByTeam)
    },
    [
      memberViewGridOptions.api,
      rowsGroupByMember,
      rowsGroupByTeam,
      teamOrder,
      memberOrder,
      setRowsGroupByMember,
      setRowsGroupByTeam,
    ]
  )

  const addTeamsForTeamView = useCallback(
    (addTeams: TeamProps[]) => {
      let newRowsGroupByTeam = rowsGroupByTeam
      addTeams.forEach((addTeam: TeamProps) => {
        newRowsGroupByTeam = addTeamToRowsGroupByTeam(
          undefined,
          addTeam,
          teamOrder,
          memberOrder,
          newRowsGroupByTeam
        )
      })
      setRowsGroupByTeam(newRowsGroupByTeam)
    },
    [teamOrder, memberOrder, rowsGroupByTeam, setRowsGroupByTeam]
  )

  const onSubmitAddTeamDialog = useCallback(
    (
      allList: MultiSelectDialogSelection[],
      selectedItem: MultiSelectDialogSelection[]
    ) => {
      const addTeams = selectedItem
        .map((item: MultiSelectDialogSelection) =>
          teams.find(team => team.uuid === item.value)
        )
        .filter(team => !!team) as TeamProps[]

      switch (pageState.rowGroupColumn) {
        case RowGroupColumnType.MEMBER:
          addTeamsForMemberView(addTeams)
          break
        case RowGroupColumnType.TEAM:
          addTeamsForTeamView(addTeams)
          break
        default:
          break
      }
    },
    [
      teams,
      pageState.rowGroupColumn,
      addTeamsForMemberView,
      addTeamsForTeamView,
    ]
  )

  const getDeleteConfirmTarget = useCallback(() => {
    let deleteNodes: RowNode<ResourcePlanRow>[] = []
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        if (!memberViewGridOptions.api) return []
        deleteNodes = getRowsToRemoveForMemberView(
          getSelectedNode(memberViewGridOptions.api)
        ).rows
        break
      case RowGroupColumnType.TEAM:
        if (!teamViewGridOptions.api) return []
        deleteNodes = getRowsToRemoveForTeamView(
          getSelectedNode(teamViewGridOptions.api)
        ).rows
        break
      default:
        return []
    }
    return deleteNodes.map(
      v =>
        getRowDisplayName(v.data as ResourcePlanRow) ??
        `(${intl.formatMessage({ id: 'displayName.undefined' })})`
    )
  }, [
    pageState.rowGroupColumn,
    memberViewGridOptions.api,
    teamViewGridOptions.api,
  ])

  const onDeleteRowsConfirmedForMemberView = useCallback(() => {
    if (!memberViewGridOptions.api) return
    const deleteRows: ResourcePlanRow[] = getRowsToRemoveForMemberView(
      getSelectedNode(memberViewGridOptions.api)
    )
      .rows.map(row => row.data)
      .filter(row => row) as ResourcePlanRow[]

    setRowsGroupByMember(deleteRowsForMemberView(rowsGroupByMember, deleteRows))
    rememberDeletedRows(deleteRows)

    const deleteMemberRows = deleteRows.filter(
      (row: ResourcePlanRow) => row.type === ResourcePlanType.MEMBER
    )

    const teamViewDeleteRows = rowsGroupByTeam.filter(
      (sourceRow: ResourcePlanRow) =>
        deleteRows.some(
          (deleteRow: ResourcePlanRow) =>
            sourceRow.body.user?.uuid === deleteRow.body.user?.uuid &&
            sourceRow.body.team?.uuid === deleteRow.body.team?.uuid
        ) ||
        deleteMemberRows.some(
          (deleteRow: ResourcePlanRow) =>
            sourceRow.treeValue[0] === UNSET_TEAM_GROUP_ROW_UUID &&
            sourceRow.body.user?.uuid === deleteRow.body.user?.uuid
        )
    )

    if (
      !rowsGroupByTeam.some(
        (row: ResourcePlanRow) =>
          !teamViewDeleteRows.some(
            (deleteRow: ResourcePlanRow) => deleteRow.uuid === row.uuid
          ) &&
          row.type === ResourcePlanType.MEMBER &&
          row.treeValue[0] === UNSET_TEAM_GROUP_ROW_UUID
      )
    ) {
      const unsetRow = rowsGroupByTeam.find(
        (row: ResourcePlanRow) => row.type === ResourcePlanType.UNSET
      )
      if (unsetRow) {
        teamViewDeleteRows.push(unsetRow)
      }
    }
    setRowsGroupByTeam(
      deleteRowsForTeamView(rowsGroupByTeam, teamViewDeleteRows)
    )
  }, [
    memberViewGridOptions.api,
    rowsGroupByMember,
    rowsGroupByTeam,
    setRowsGroupByMember,
    setRowsGroupByTeam,
    rememberDeletedRows,
  ])

  const onDeleteRowsConfirmedForTeamView = useCallback(() => {
    if (!teamViewGridOptions.api) return
    const deleteRows: ResourcePlanRow[] = getRowsToRemoveForTeamView(
      getSelectedNode(teamViewGridOptions.api)
    )
      .rows.map(row => row.data)
      .filter(row => row) as ResourcePlanRow[]

    setRowsGroupByTeam(deleteRowsForTeamView(rowsGroupByTeam, deleteRows))
    const memberViewDeleteRows = rowsGroupByMember.filter(
      (sourceRow: ResourcePlanRow) =>
        deleteRows.some(
          (deleteRow: ResourcePlanRow) =>
            sourceRow.body.user?.uuid === deleteRow.body.user?.uuid &&
            sourceRow.body.team?.uuid === deleteRow.body.team?.uuid
        )
    )
    setRowsGroupByMember(
      deleteRowsForMemberView(rowsGroupByMember, memberViewDeleteRows)
    )
    rememberDeletedRows(memberViewDeleteRows)
  }, [
    teamViewGridOptions.api,
    rowsGroupByMember,
    rowsGroupByTeam,
    setRowsGroupByMember,
    setRowsGroupByTeam,
    rememberDeletedRows,
  ])

  const onDeleteRowsConfirmed = useCallback(() => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        onDeleteRowsConfirmedForMemberView()
        break
      case RowGroupColumnType.TEAM:
        onDeleteRowsConfirmedForTeamView()
        break
      default:
        return []
    }
    dialogState.closeDeleteConfirm()
  }, [
    pageState.rowGroupColumn,
    dialogState,
    onDeleteRowsConfirmedForMemberView,
    onDeleteRowsConfirmedForTeamView,
  ])

  const getRowHeight = useCallback(
    (params: RowHeightParams<ResourcePlanRow>): number => {
      const row: ResourcePlanRow | undefined = params.data
      if (!!row?.isTotal) return ROW_HEIGHT.SMALL
      return pageState.rowHeight
    },
    [pageState.rowHeight]
  )

  const onFirstDataRendered = useCallback(
    (bulkSheetState: ResourcePlanBulkSheetState) => {
      bulkSheetState.onFirstDataRendered()
      setLoading(false)
    },
    []
  )

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<ResourcePlanRow>) => {
      activeGridOptions.api?.refreshCells({ force: true })
    },
    [activeGridOptions]
  )

  const onSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      const baseBulkSheetState = activeBulkSheetState.base
      if (columnState) {
        baseBulkSheetState.saveColumnState(columnState)
        activeGridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        baseBulkSheetState.saveFilterState(filterState)
        activeGridOptions.api?.setFilterModel(filterState)
      }
      dialogState.closeUiState()
    },
    [activeBulkSheetState, activeGridOptions, dialogState]
  )

  const teamViewContextMenu = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const selectedNodes = getSelectedNode(params.api)
      return [
        addTeamMenuItemForTeamView(
          teams,
          getExcludeTeamUuids(),
          dialogState.openAddTeam
        ),
        addMemberMenuItemForTeamView(
          selectedNodes,
          allCanAddMembers,
          dialogState.openAddMember
        ),
        'separator',
        deleteRowMenuItemForTeamView(
          selectedNodes,
          dialogState.openDeleteConfirm
        ),
        'separator',
        expandAllRows(params),
        collapseAllRows(params),
      ].filter(m => !!m)
    },
    [
      teams,
      allCanAddMembers,
      dialogState.openAddTeam,
      dialogState.openAddMember,
      dialogState.openDeleteConfirm,
      getExcludeTeamUuids,
    ]
  )

  // Key bind
  const onSpaceKeyDown = useCallback(() => {
    const api = activeGridOptions.api
    const focusedCell = api?.getFocusedCell()
    const focusedNode = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex ?? -1)
    if (
      !focusedCell ||
      !focusedNode ||
      !document.activeElement!.classList.contains('ag-cell')
    ) {
      return
    }
    focusedNode.setExpanded(!focusedNode.expanded)
  }, [activeGridOptions])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
      { key: KEY_SPACE, fn: onSpaceKeyDown },
    ],
    [save, onSpaceKeyDown]
  )

  return (
    <PageArea>
      {functionLayers.size === 1 && (
        <SavePopper loading={loading} onSubmit={onSubmit} />
      )}
      <ResourcePlanHeader
        fromDate={pageState.startDate}
        toDate={pageState.endDate}
        onChangeDateTerm={onChangeDateTerm}
        onReload={onReload}
        filteredColumns={activeBulkSheetState.filteredColDefs}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={activeBulkSheetState.sortedColStates}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        rowHeight={pageState.rowHeight}
        onChangeHeight={pageState.setRowHeight}
        onClickExportExcel={dialogState.openExcelExport}
        rowGroupColumn={pageState.rowGroupColumn}
        onChangeRowGroupColumn={onChangeRowGroupColumn}
        getDisplayColumnName={getDisplayColumnName}
      />
      {/* Removing the AgGrid node from the DOM resets the column definitions, so hide them. */}
      <BulkSheetDiv
        visible={pageState.rowGroupColumn === RowGroupColumnType.MEMBER}
        children={
          <BulkSheetView
            gridOptions={memberViewGridOptions}
            getContextMenuItems={memberViewContextMenu}
            getRowHeight={getRowHeight}
            onColumnMoved={memberViewBulkSheetState.rememberColumnState}
            onColumnResized={memberViewBulkSheetState.rememberColumnState}
            onColumnVisible={memberViewBulkSheetState.rememberColumnState}
            onRowDragEnter={dragTreeStyleForMemberView.onRowDragEnter}
            onRowDragMove={dragTreeStyleForMemberView.onRowDragMove}
            onRowDragEnd={onRowDragEndForMemberView}
            onRowDragLeave={dragTreeStyleForMemberView.refreshDragStyle}
            onFilterChanged={memberViewBulkSheetState.onFilterChanged}
            onFirstDataRendered={() => {
              onFirstDataRendered(memberViewBulkSheetState)
            }}
            onGridReady={memberViewBulkSheetState.onGridReady}
            onRowDataUpdated={onRowDataUpdated}
            onRowGroupOpened={memberViewBulkSheetState.onRowGroupOpened}
            onSortChanged={memberViewBulkSheetState.onSortChanged}
            pinnedTopRowData={summaryRows}
            ref={memberViewRef}
            rowData={rowsGroupByMember}
          />
        }
      />
      <BulkSheetDiv
        visible={pageState.rowGroupColumn === RowGroupColumnType.TEAM}
        children={
          <BulkSheetView
            gridOptions={teamViewGridOptions}
            getContextMenuItems={teamViewContextMenu}
            getRowHeight={getRowHeight}
            onColumnMoved={teamViewBulkSheetState.rememberColumnState}
            onColumnResized={teamViewBulkSheetState.rememberColumnState}
            onColumnVisible={teamViewBulkSheetState.rememberColumnState}
            onRowDragEnter={dragTreeStyleForTeamView.onRowDragEnter}
            onRowDragMove={dragTreeStyleForTeamView.onRowDragMove}
            onRowDragEnd={onRowDragEndForTeamView}
            onRowDragLeave={dragTreeStyleForTeamView.refreshDragStyle}
            onFilterChanged={teamViewBulkSheetState.onFilterChanged}
            onFirstDataRendered={() => {
              onFirstDataRendered(teamViewBulkSheetState)
            }}
            onGridReady={teamViewBulkSheetState.onGridReady}
            onRowDataUpdated={onRowDataUpdated}
            onRowGroupOpened={teamViewBulkSheetState.onRowGroupOpened}
            onSortChanged={teamViewBulkSheetState.onSortChanged}
            pinnedTopRowData={summaryRows}
            ref={teamViewRef}
            rowData={rowsGroupByTeam}
          />
        }
      />
      <Loading isLoading={loading} elem={getActiveGridRef()} />
      <CancelConfirmDialog
        open={dialogState.cancelConfirm}
        onConfirm={onConfirmedCancel}
        onClose={dialogState.closeCancelConfirm}
      />
      {dialogState.addMember && (
        <MultiSelectDialog
          onClose={dialogState.closeAddMember}
          onSubmit={onSubmitAddMemberDialog}
          dialogTitle={intl.formatMessage({
            id: 'project.resourcePlan.addMember',
          })}
          getSelectList={getAddMemberDialogSelectList}
          allCheckBoxLabel={intl.formatMessage({
            id: 'multiSelectDialog.allCkeck.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'add',
          })}
        />
      )}
      {dialogState.addTeam && memberViewGridOptions.api && (
        <MultiSelectDialog
          onClose={dialogState.closeAddTeam}
          onSubmit={onSubmitAddTeamDialog}
          dialogTitle={intl.formatMessage({
            id: 'project.resourcePlan.addTeam',
          })}
          excludeValues={getExcludeTeamUuids()}
          getSelectList={getAddTeamDialogSelectList}
          allCheckBoxLabel={intl.formatMessage({
            id: 'multiSelectDialog.allCkeck.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'add',
          })}
        />
      )}
      {dialogState.deleteConfirm && memberViewGridOptions.api && (
        <DeleteRowConfirmationDialog
          target={getDeleteConfirmTarget()}
          onConfirm={onDeleteRowsConfirmed}
          onClose={dialogState.closeDeleteConfirm}
        />
      )}
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={activeGridOptions.columnApi ?? undefined}
        gridApi={activeGridOptions.api ?? undefined}
        height={getActiveGridRef()?.offsetHeight}
        openSavedUiStateDialog={dialogState.openUiState}
        initializeColumnState={activeBulkSheetState.resetColumnState}
        applicationFunctionUuid={uuid}
        uiStateKey={activeBulkSheetUiStateKey}
        columnState={activeBulkSheetState.base.columnState}
        offset={90}
      />
      <SavedUIStateDialog
        applicationFunctionUuid={functionUuid}
        open={dialogState.uiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={activeBulkSheetUiStateKey}
        sharable={false}
        currentUIState={{
          columnState: activeBulkSheetState.base.columnState,
          filterState: activeBulkSheetState.base.filterState,
        }}
        onSelect={onSelectUiState}
        onClose={dialogState.closeUiState}
      />
      {dialogState.excelExport && (
        <ExcelExportDialog
          onClose={dialogState.closeExcelExport}
          onSubmit={excel.onExportExcel}
          columnApi={activeGridOptions.columnApi}
        />
      )}{' '}
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functionLayers: state.functionLayer.layers,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(ResourcePlan))
)
