import { useCallback } from 'react'
import { DailyCpiVO, EvmVO } from '../../../domain/value-object/EvmVO'
import { useProductivityRepositoryAdaptor } from '../../../services/adaptors/productivityRepositoryAdaptor'

type GetProductivity = {
  getEvmValue: (
    projectUuid: string,
    rootProjectPlanUuid?: string,
    teamUuid?: string
  ) => Promise<EvmVO>
  getDailyCpi: (
    projectUuid: string,
    rootProjectPlanUuid?: string,
    teamUuid?: string
  ) => Promise<DailyCpiVO[]>
}

export const useGetProductivity = (): GetProductivity => {
  const repository = useProductivityRepositoryAdaptor()
  const getEvmValue = useCallback(
    (projectUuid: string, rootProjectPlanUuid?: string, teamUuid?: string) => {
      return repository.getEvmValue(projectUuid, rootProjectPlanUuid, teamUuid)
    },
    [repository]
  )
  const getDailyCpi = useCallback(
    (projectUuid: string, rootProjectPlanUuid?: string, teamUuid?: string) => {
      return repository.getDailyCpi(projectUuid, rootProjectPlanUuid, teamUuid)
    },
    [repository]
  )
  return { getEvmValue, getDailyCpi }
}
