// TODO share this class with project plan
import { getOpenWbsItemDetailSpec } from '../../../../../../../../lib/functions/wbsItem'
import {
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
  RowNode,
} from 'ag-grid-community'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { WbsItemIcon } from '../../../../../../../components/icons/WbsItemIcon'
import store from '../../../../../../../../store'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import { getPathByExternalId } from '../../../../../../../pages'
import IconTextField from '../../common/iconText'
import { BulkSheetContext } from '../../../../../../BulkSheet'
import { pushFunctionLayer } from '../../../../../../../../store/functionLayer'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

interface Props extends ICellRendererParams {
  // TODO Use wbsItem.type
  value: string
  data: {
    uuid: string
    wbsItemType: WbsItemTypeVO
    ticketType: string
    wbsItem?: {
      uuid: string
      wbsItemType: WbsItemTypeVO
      ticketType: string
      description: string
    }
    task: {
      wbsItemType: WbsItemTypeVO
      ticketType: string
      description: string
    }
    isAdded: boolean
  }
  node: RowNode
  viewMeta: ViewMeta
  uiMeta: FunctionProperty
  // TODO Remove it
  ctx: BulkSheetContext<any, any, any, any>
  api: GridApi
  isRootUuid?: (uuid: string) => boolean
}

const WbsItemTreeCellRenderer = (props: Props) => {
  if (!props.data || !props.node) return <></>

  const openDetail = async (node: RowNode, event: any) => {
    if (node.data.isTotal) {
      return
    }
    let targetRowData = node.data
    if (node.data.isAdded || node.data.isEdited) {
      const isSuccessSubmit = await props.ctx.onSubmitSingleRow(node.id!)
      if (!isSuccessSubmit) return
      await props.ctx.refreshAfterUpdateSingleRow(node.id!)
      targetRowData = props.api.getRowNode(node.id!)?.data
    }
    const openDetailSpec = await getOpenWbsItemDetailSpec(
      true,
      targetRowData.wbsItem ?? targetRowData.task
    )
    if (!openDetailSpec) {
      return
    }
    const layer = openDetailSpec.layer
    openDetailSpec.onOpen && openDetailSpec.onOpen()
    if (
      !openDetailSpec.openInDialog ||
      (event && (event['ctrlKey'] || event['metaKey']))
    ) {
      window.open(`${getPathByExternalId(layer.externalId)}/${layer.code!}`)
    } else {
      store.dispatch(
        pushFunctionLayer({
          ...layer,
          onClose: async () => {
            props.ctx?.refreshAfterUpdateSingleRow(node.data.uuid)
          },
        })
      )
    }
  }

  const data = props.data
  const wbsItemType =
    (data.wbsItemType && typeof data.wbsItemType == 'object'
      ? data.wbsItemType
      : undefined) ??
    data.wbsItem?.wbsItemType ??
    data.task?.wbsItemType

  let editable = props.colDef?.editable
  const isEditable =
    typeof editable === 'function'
      ? editable(props as EditableCallbackParams)
      : editable
  const hasDetail =
    !!props.data.wbsItem?.description || !!props.data.task?.description

  return (
    <IconTextField
      id={`wbs-tree-column-${props['rowIndex']}`}
      icon={<WbsItemIcon type={wbsItemType} />}
      onIconClicked={
        props.isRootUuid && props.isRootUuid(props.node.data.uuid)
          ? undefined
          : e => openDetail(props.node, e)
      }
      enableTooltip={true}
      editable={isEditable}
      value={props.value}
      data={props.data}
      uiMeta={props.uiMeta}
      viewMeta={props.viewMeta}
      api={props.api}
      hasDetail={hasDetail}
      iconDisabled={props.ctx?.state?.inProgress}
    />
  )
}

export default WbsItemTreeCellRenderer
