import { Collapse, Typography } from '@mui/material'
import {
  AggregateField,
  AggregateTarget,
} from '../../../../../domain/entity/WbsItemEntity'
import { ProjectPlanCumulation } from '../../../../../lib/functions/projectPlan'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import { AggregateInformation } from '../Toolbar/ReportToolbar/AggregateInformation'
import ProgressReportButton from './ProgressReportButton'
import SprintReportButton from './SprintReportButton'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { intl } from '../../../../../i18n'
import OpenKanbanButton from '../../../Kanban/components/Button/OpenKanbanButton'
import { BurndownChartButton } from './BurndownChartButton'
import { dateTermVoService } from '../../../../../domain/value-object/DateTermVO'

type Props = {
  aggregateField: AggregateField
  aggregateTarget: AggregateTarget
  workloadUnit: WorkloadUnit
  cumulation: ProjectPlanCumulation
  data: any
  projectUuid: string
  rootWbsItem?: string
  onChangeOpenReport: (value: boolean) => void
  openReport: boolean
}

const ProjectPlanNewReport = ({
  aggregateField,
  aggregateTarget,
  workloadUnit,
  cumulation,
  data,
  projectUuid,
  rootWbsItem,
  onChangeOpenReport,
  openReport,
}: Props) => {
  const scheduledEndDateForKanban = data?.[0]?.body?.wbsItem?.scheduledDate
    ? dateTermVoService.construct(
        data?.[0]?.body?.wbsItem?.scheduledDate.startDate,
        data?.[0]?.body?.wbsItem?.scheduledDate.endDate
      )
    : undefined
  return (
    <Collapse
      in={openReport}
      sx={{
        minHeight: 'auto !important',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '4px',
          padding: '10px 20px',
          background: colorPalette.monotone[0],
          margin: !rootWbsItem ? '10px 0px 0px 0px' : 0,
          overflowX: 'auto',
          overflowY: 'hidden',
          minHeight: '47px',
        }}
      >
        <AggregateInformation
          rootUuid={rootWbsItem}
          aggregateField={aggregateField}
          aggregateTarget={aggregateTarget}
          workloadUnit={workloadUnit}
          cumulation={cumulation}
        />
        {rootWbsItem && (
          <>
            <ProgressReportButton
              projectUuid={projectUuid}
              rootWbsItemUuid={rootWbsItem}
              scheduledDate={data.scheduledDate}
            />
            <BurndownChartButton />
            <SprintReportButton rootWbsItemUuid={rootWbsItem} />
            <OpenKanbanButton
              query={{
                rootUuid: rootWbsItem,
              }}
            />
          </>
        )}
        <Button
          onClick={() => onChangeOpenReport(false)}
          sx={{
            margin: '0 0 0 auto',
            color: colorPalette.monotone[5],
            background: 'transparent',
            cursor: 'pointer',
            '&:hover': { background: 'transparent' },
            minWidth: 'fit-content',
          }}
        >
          <Typography>
            {intl.formatMessage({ id: 'wbs.header.close.report' })}
          </Typography>
          <ClearRoundedIcon />
        </Button>
      </div>
    </Collapse>
  )
}

export default ProjectPlanNewReport
