import API, { APIResponse } from '../commons/api'
import EntitySearch from '../commons/entitySearch'
import store from '../../store'
import { Tree } from '../commons/tree'
import { FunctionProperty } from '../commons/appFunction'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import project from './project'

export interface GetTeamsProps {
  projectUuid: string
  all?: string
  name?: string
  offset?: number
  limit?: number
}

export interface GetTeamsResponse {
  total: number
  hit: number
  data: TeamProps[]
}

export interface TeamDetail extends Tree<TeamDetail> {
  code: string
  iconUrl?: string
  displayName: string
  officialName?: string
}

export interface TeamProps {
  uuid: string
  iconUrl: string
  projectUuid: string
  displayName: string
  officialName: string
}

export interface TeamInput {
  projectUuid?: string
  uuid: string
  lockVersion?: number
  revision?: string
  parentUuid?: string
  prevSiblingUuid?: string
  code?: string
  displayName: string
  officialName?: string
  iconUrl?: string
}

export interface TeamDeltaInput {
  uuid: string
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
  iconUrl?: IItemDelta<string>
}

export interface TeamBatchDeltaInput {
  added: TeamInput[]
  edited: TeamDeltaInput[]
  deleted: {
    uuid: string
    lockVersion: number
  }[]
}

class Team extends EntitySearch {
  hasIcon = true
  public search = async (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      // Do not use the query to get all teams in the project.
      (query: string) => ({
        projectUuid:
          searchOptions?.projectUuid || store.getState().project.selected,
      }),
      this.toResponse,
      true
    )
  }

  public searchAll = (projectUuid?: string) => this.search('', { projectUuid })

  toResponse = (team: TeamProps) => ({
    ...team,
    name: team.displayName,
  })

  public getAll = async (projectUuid: string) => {
    return this.searchRequest.switch(signal =>
      API.functional.request(
        'GET',
        '/api/v1/projects/teams/descendant',
        {
          projectUuid,
        },
        true,
        signal
      )
    )
  }

  entitySearchApi = (props: {}, signal?: AbortSignal): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/teams/list',
      props,
      true,
      signal
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const project = store.getState().project
    if (!project || !project.selected) {
      const response = await this.getTeam(uuid)
      return {
        status: 200,
        json: this.toResponse(response.json),
      }
    }
    const response = await this.searchAll()
    const team = response.find(team => team.uuid === uuid)
    return {
      status: 200,
      json: team,
    }
  }

  getTeam = async (uuid: string) => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/teams',
      { uuid },
      true
    )
  }

  getTeams = async (projectUuid: string) => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/teams/list',
      { projectUuid },
      true
    )
  }

  updateBatchDelta(request: TeamBatchDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/teams/delta/batch',
      request
    )
  }

  getLabel(option: TeamProps): string {
    return `${option.displayName}`
  }

  getAllCrossProjects = async (
    projectUuids: string[]
  ): Promise<TeamProps[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/teams/cross_projects',
      { projectUuids },
      true
    )
    return response.json || []
  }

  getFilterOptionUuids = ({
    projectUuid,
    rootProjectPlanUuid,
  }: {
    projectUuid: string
    rootProjectPlanUuid?: string
  }): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/teams/filter_options',
      { projectUuid, rootProjectPlanUuid },
      true
    )
  }
}

export default new Team()
