import { intl } from '../../../../../../../../i18n'
import Select from '../../../../../../../components/editors/select/Select'

export enum CommentCellDateFilterOperator {
  EQ = '=',
  GTE = '>=',
  LTE = '<=',
}

interface Props {
  className?: string
  key: string
  value?: CommentCellDateFilterOperator
  onChange: (value: CommentCellDateFilterOperator) => void
}

const options = Object.values(CommentCellDateFilterOperator)

export const CommentCellDateFilterOperatorLabel = {
  [CommentCellDateFilterOperator.EQ]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.equal',
  }),
  [CommentCellDateFilterOperator.GTE]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.greaterThanEqual',
  }),
  [CommentCellDateFilterOperator.LTE]: intl.formatMessage({
    id: 'bulksheet.filter.dateFilter.operator.lessThanEqual',
  }),
}

const CommentCellDateOperatorSelect = ({
  className,
  key,
  value,
  onChange,
}: Props) => {
  return (
    <Select
      className={className}
      key={key}
      options={options}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
      }}
      value={value}
      onChange={event => {
        onChange(event.target.value as CommentCellDateFilterOperator)
      }}
      getOptionValue={o => o}
      getOptionLabel={o => CommentCellDateFilterOperatorLabel[o]}
    />
  )
}

export default CommentCellDateOperatorSelect
