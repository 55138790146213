import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DisplayNameFilterModel,
  FilterModel,
  PriorityFilterModel,
  ResponsibleFilterModel,
  isSameSelectFilter,
  isSameTextFilter,
} from '../components/Filter/filterModel'
import { CardData } from '../Kanban'
import { TextFilterOperator } from '../../../containers/BulkSheetView/components/filter/TextFilter'
import { getUrlQueryObject } from '../../../../utils/urls'
import _ from 'lodash'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'

const responsibleFilterFieldFromUrl = 'responsibleUuid'

export const useFilter = (): {
  filterModels: FilterModel[]
  updateFilterModel: (_: FilterModel) => void
  filter: (card: CardData) => boolean
  activeFilterModels: FilterModel[]
  isFilterActive: boolean
} => {
  const [displayFilterModel, setDisplayFilterModel] =
    useState<DisplayNameFilterModel>({
      key: 'DISPLAY_NAME',
      value: undefined,
    })
  const [responsibleFilterModel, setResponsibleFilterModel] =
    useState<ResponsibleFilterModel>({
      key: 'RESPONSIBLE',
      value: undefined,
    })
  const [priorityFilterModel, setPriorityFilterModel] =
    useState<PriorityFilterModel>({
      key: 'PRIORITY',
      value: undefined,
    })

  const filterModels = useMemo(() => {
    return [
      displayFilterModel,
      responsibleFilterModel,
      priorityFilterModel,
    ] as FilterModel[]
  }, [displayFilterModel, responsibleFilterModel, priorityFilterModel])

  const updateFilterModel = useCallback(
    (updated: FilterModel) => {
      if (updated.key === 'DISPLAY_NAME') {
        !isSameTextFilter(displayFilterModel.value, updated.value) &&
          setDisplayFilterModel(updated)
      } else if (updated.key === 'RESPONSIBLE') {
        !isSameSelectFilter(responsibleFilterModel.value, updated.value) &&
          setResponsibleFilterModel(updated)
      } else if (updated.key === 'PRIORITY') {
        !isSameSelectFilter(priorityFilterModel.value, updated.value) &&
          setPriorityFilterModel(updated)
      }
    },
    [displayFilterModel, responsibleFilterModel, priorityFilterModel]
  )

  const displayNameFilter = useCallback(
    (card: CardData) => {
      const filter = displayFilterModel.value
      if (!filter) return true
      const textValue = card.content.displayName || ''
      if (!textValue && filter.value) return false
      switch (filter.operator) {
        case TextFilterOperator.CONTAINS:
          return textValue.indexOf(filter.value) >= 0
        case TextFilterOperator.NOT_CONTAINS:
          return textValue.indexOf(filter.value) < 0
        case TextFilterOperator.EQUALS:
          return textValue === filter.value
        case TextFilterOperator.NOT_EQUALS:
          return textValue !== filter.value
        case TextFilterOperator.STARTS_WITH:
          return textValue.indexOf(filter.value) === 0
        case TextFilterOperator.ENDS_WITH:
          const index = textValue.lastIndexOf(filter.value)
          return index >= 0 && index === textValue.length - filter.value.length
        case TextFilterOperator.BLANK:
          return !textValue
        case TextFilterOperator.NOT_BLANK:
          return !!textValue
        default:
          // should never happen
          console.warn('invalid filter type ' + filter.operator)
          return false
      }
    },
    [displayFilterModel]
  )

  const responsibleFilter = useCallback(
    (card: CardData) => {
      const filter = responsibleFilterModel.value
      if (!filter) return true
      if (!filter.values) return true
      const responsible = card.content.responsible
      if (filter.includeBlank && !responsible) return true
      if (filter.values.includes(responsible?.uuid ?? '')) return true
      return false
    },
    [responsibleFilterModel]
  )

  const priorityFilter = useCallback(
    (card: CardData) => {
      const filter = priorityFilterModel.value
      if (!filter) return true
      if (!filter.values) return true
      const priority = card.content.priority
      if (filter.includeBlank && (!priority || priority === CUSTOM_ENUM_NONE)) {
        return true
      }
      if (filter.values.includes(priority ?? '')) return true
      return false
    },
    [priorityFilterModel]
  )

  const filter = useCallback(
    (card: CardData) => {
      if (card.edited) return true
      return (
        displayNameFilter(card) &&
        responsibleFilter(card) &&
        priorityFilter(card)
      )
    },
    [displayNameFilter, responsibleFilter, priorityFilter]
  )

  const isFilterActive = useMemo(
    () => filterModels.some(model => !!model.value),
    [filterModels]
  )

  const activeFilterModels = useMemo(
    () => filterModels.filter(model => !!model.value),
    [filterModels]
  )

  useEffect(() => {
    const urlQueryObject = getUrlQueryObject()
    if (!urlQueryObject || !urlQueryObject[responsibleFilterFieldFromUrl]) {
      return
    }
    const selected: string[] =
      urlQueryObject[responsibleFilterFieldFromUrl].split(',')
    setResponsibleFilterModel({
      key: 'RESPONSIBLE',
      value: {
        values: selected.filter(v => v !== 'BLANK'),
        includeBlank: selected.some(v => v === 'BLANK'),
      },
    })
  }, [setResponsibleFilterModel])

  return {
    filterModels,
    updateFilterModel,
    filter,
    activeFilterModels,
    isFilterActive,
  }
}
