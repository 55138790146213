import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { colorPalette } from '../../../../style/colorPallete'

type TextCellProps = {
  value: string | undefined
  onChange?: (v: string | undefined) => void
} & CellPropsBase
export const TextCell = ({
  label,
  cellWidth,
  value,
  onChange,
  editable = true,
  required = false,
}: TextCellProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value)
  const [changedByInput, setChangedByInput] = useState<boolean>(false)
  useEffect(() => {
    setInputValue(value)
    setChangedByInput(false)
  }, [value])
  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (changedByInput) {
      onChange && onChange(debouncedValue)
    }
  }, [changedByInput, debouncedValue, onChange])
  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setChangedByInput(true)
  }, [])
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <TextInput
          value={inputValue}
          onChange={onChangeInput}
          disabled={!editable}
        />
      </CellValueArea>
    </FormCell>
  )
}

const TextInput = styled(InputBase)({
  width: '100%',
  '& .MuiInputBase-input.Mui-disabled': {
    color: colorPalette.monotone[3],
    '-webkit-text-fill-color': colorPalette.monotone[3],
  },
})
