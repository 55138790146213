import { useCallback, useMemo, useState } from 'react'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import * as WorkReportAPI from '../../../lib/functions/workReport'
import { PageArea, PageProps } from '..'
import WorkReportOptions, {
  ColumnQuickFilterKey,
  WorkReportDetailForExport,
  WorkReportRow,
  WorkReportState,
} from './WorkReportOptions'
import WorkReportHeader from './Header'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Collapse } from '@mui/material'
import WorkReportToolbar from './Toolbar'
import { ColDef } from 'ag-grid-community'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { usePageState } from '../../hooks/usePageState'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { SearchUnit } from '../../components/headers/HeaderBar/DateRangeSelector'
import DateVO from '../../../vo/DateVO'
import { DISPLAY_DATE_FORMAT, DateTerm } from '../../../utils/date'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { getExcelBuffer } from '../../../utils/excel'
import { intl } from '../../../i18n'
import DateTimeVO from '../../../domain/value-object/DateTimeVO'
import { dateVoService } from '../../../domain/value-object/DateVO'
import { BLOB_MIME_TYPE_EXCEL } from './components/FileDownloadButton'
import ExcelJS from 'exceljs'

type WorkReportPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const workReportDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const DETAIL_EXCEL_FILE_NAME: string = 'project_workReport_detail'
const DETAIL_EXCEL_COLUMN_DEF: Partial<ExcelJS.Column>[] = [
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.userName',
    }),
    key: 'userName',
    width: 15,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.date',
    }),
    key: 'date',
    width: 12,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.ticketType',
    }),
    key: 'ticketType',
    width: 15,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.taskCode',
    }),
    key: 'taskCode',
    width: 12,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.taskName',
    }),
    key: 'taskName',
    width: 45,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.path',
    }),
    key: 'path',
    width: 45,
  },
  {
    header: intl.formatMessage({
      id: 'workReport.detail.csv.header.hour',
    }),
    key: 'hour',
    style: { numFmt: '0.00' },
  },
]

const WorkReport = (props: PageProps) => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        WorkReportAPI.WorkReport,
        WorkReportRow,
        WorkReportState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const workReportOptions = useMemo(() => new WorkReportOptions(), [])
  const [dateTerm, setDateTerm] = useState<DateTerm>({
    startDate: DateVO.now().getFirstDayOfMonth().formatForApi(),
    endDate: DateVO.now().getLastDayOfMonth().formatForApi(),
  })
  const [searchUnit, setSearchUnit] = useState<SearchUnit>(SearchUnit.MONTH)

  const { toolbar, updatePageState } = usePageState<WorkReportPageState>(
    props.uuid,
    workReportDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )

  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const onSearch = useCallback(
    (unit: SearchUnit, term: DateTerm) => {
      setSearchUnit(unit)
      setDateTerm(term)
      workReportOptions.onSearch(bulkSheet!, term)
    },
    [bulkSheet]
  )

  const getDetailExcelFileName = useCallback(
    (): string =>
      `${DETAIL_EXCEL_FILE_NAME}_${new DateTimeVO().format(
        'YYYYMMDDHHmmss'
      )}.xlsx`,
    []
  )

  const getDetailExcelBuffer = useCallback(async (): Promise<
    BlobPart | undefined
  > => {
    const rows: WorkReportRow[] = bulkSheet?.rowDataManager.getAllRows() ?? []
    if (!rows) return

    const exportData = rows
      .flatMap((row: WorkReportRow) => {
        if (!row || !row.user || !row.work) return
        return row.work
          .flatMap(work => {
            if (!work.date || !work.actualWorks) return
            return work.actualWorks.map(actualWorks => {
              return {
                userName: row.user!.name,
                date: dateVoService.format(
                  dateVoService.construct(work.date),
                  DISPLAY_DATE_FORMAT
                ),
                ticketType:
                  actualWorks.baseWbsItemType?.name ??
                  actualWorks.wbsItemType?.name,
                taskCode: actualWorks.taskCode,
                taskName: actualWorks.taskName,
                path: actualWorks.path,
                hour: actualWorks.hour,
              } as WorkReportDetailForExport
            })
          })
          .filter(c => !!c)
      })
      .filter(c => !!c) as WorkReportDetailForExport[]

    const buf = await getExcelBuffer({
      sheetName: DETAIL_EXCEL_FILE_NAME,
      columns: DETAIL_EXCEL_COLUMN_DEF,
      source: exportData,
    })
    return buf
  }, [bulkSheet])

  return (
    <PageArea>
      <WorkReportHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onResetColumnState={() => bulkSheet?.resetColumnAndFilterState()}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        onClickImport={data => bulkSheet?.importExcel(data)}
        onRestoreSearchCondition={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateSearchCondition
          )
        }
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        searchUnit={searchUnit}
        dateTerm={dateTerm}
        onSearch={onSearch}
        refreshDataWithLoading={() => bulkSheet?.refreshDataWithLoading()}
        isLoading={bulkSheet?.state.isLoading!}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        detailDownloadProps={{
          blobMimeType: BLOB_MIME_TYPE_EXCEL,
          getFileName: getDetailExcelFileName,
          getBlobData: getDetailExcelBuffer,
        }}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <WorkReportToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={workReportOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
      />
    </PageArea>
  )
}

export default pageComponent(projectPrivate(WorkReport))
