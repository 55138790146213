import styled from 'styled-components'
import DndComponent from '../../components/kanban/DndComponent'
import { useCallback, useState } from 'react'
import {
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core'
import Card from '../../components/kanban/items/Card'
import { CardContent, CardData, Column, EditableCardItem } from './Kanban'
import { WbsStatusCardContent } from './components/Card/WbsStatusCardContent'
import { StatusColumnHeader } from './components/Column/StatusColumnHeader'
import DroppableWrapper from '../../components/kanban/items/DroppableWrapper'
import { arrayMove } from '@dnd-kit/sortable'
import KanbanColumn from './components/Column'

type Props = {
  columns: Column[]
  cards: { [id: string]: CardData }
  filter: (card: CardData) => boolean
  handleChangeCardContent: (
    id: string,
    field: EditableCardItem,
    changed: any
  ) => void
  setColumns: React.Dispatch<React.SetStateAction<Column[]>>
  refreshSingle: (wbsItemCode: string) => void
  save: () => Promise<void>
}

export const ColumnContainer = ({
  columns,
  cards,
  filter,
  handleChangeCardContent,
  setColumns,
  refreshSingle,
  save,
}: Props) => {
  const [overlayActiveContent, setActiveContent] = useState<
    CardContent | undefined
  >(undefined)

  const findColumn = useCallback(
    (id: string | null) => {
      if (!id) {
        return null
      }
      // over column
      if (columns.some(c => c.id === id)) {
        return columns.find(c => c.id === id) ?? null
      }
      // over card
      return columns.find(c => c.cardIds.some(i => i === id))
    },
    [columns]
  )

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event
      const target: CardData | undefined = cards[active.id]
      setActiveContent(target?.content)
    },
    [cards]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      const activeId = String(active.id)
      const overId = over ? String(over.id) : null
      const activeColumn = findColumn(activeId)
      const overColumn = findColumn(overId)
      setActiveContent(undefined)
      if (!activeColumn || !overColumn || activeColumn !== overColumn) {
        return null
      }
      if (overlayActiveContent?.status !== activeColumn.id) {
        handleChangeCardContent(activeId, 'status', activeColumn.id)
      }

      const activeIndex = activeColumn.cardIds.findIndex(i => i === activeId)
      const overIndex = overColumn.cardIds.findIndex(i => i === overId)
      if (activeIndex !== overIndex) {
        // Swap the order of cards within the same column.
        setColumns(prev => {
          return prev.map(column => {
            if (column.id === activeColumn.id) {
              column.cardIds = arrayMove(
                overColumn.cardIds,
                activeIndex,
                overIndex
              )
              return column
            } else {
              return column
            }
          })
        })
      }
    },
    [findColumn, overlayActiveContent]
  )

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      const { active, over, delta } = event
      const activeId = String(active.id)
      const overId = over ? String(over.id) : null
      const activeColumn = findColumn(activeId)
      const overColumn = findColumn(overId)
      if (!activeColumn || !overColumn || activeColumn === overColumn) {
        return null
      }
      // Temporally swapping and moving card.
      setColumns(prevState => {
        const activeItems = activeColumn.cardIds
        const overItems = overColumn.cardIds
        const activeIndex = activeItems.findIndex(i => i === activeId)
        const overIndex = overItems
          .filter(i => !!i)
          .findIndex(i => i === overId)
        const newIndex = () => {
          const putOnBelowLastItem =
            overIndex === overItems.length - 1 && delta.y > 0
          const modifier = putOnBelowLastItem ? 1 : 0
          return overIndex >= 0 ? overIndex + modifier : overItems.length + 1
        }
        return prevState.map(c => {
          if (c.id === activeColumn.id) {
            c.cardIds = activeItems.filter(i => i !== activeId)
            return c
          } else if (c.id === overColumn.id) {
            c.cardIds = [
              ...overItems.slice(0, newIndex()),
              activeItems[activeIndex],
              ...overItems.slice(newIndex(), overItems.length),
            ]
            return c
          } else {
            return c
          }
        })
      })
    },
    [findColumn]
  )

  return (
    <DndComponent
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <DndArea>
        {columns.map(column => (
          <KanbanColumn
            key={`column-${column.id}`}
            backgroundColor={column.backgroundColor}
          >
            <StatusColumnHeader
              title={column.title}
              cumulationData={column.cumulationData}
            />
            <DroppableWrapper
              id={column.id}
              cards={column.cardIds.map(id => cards[id])}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                overflowY: 'auto',
                padding: '8px 0',
              }}
            >
              {column.cardIds
                .map(id => cards[id])
                .filter(filter)
                .map(card => (
                  <Card key={`card-${card.id}`} id={card.id}>
                    <WbsStatusCardContent
                      content={card.content}
                      onChange={handleChangeCardContent}
                      onCloseWbsItemDetail={refreshSingle}
                      save={save}
                    />
                  </Card>
                ))}
            </DroppableWrapper>
          </KanbanColumn>
        ))}
      </DndArea>
      <DragOverlay>
        {overlayActiveContent ? (
          <Card
            id={`${overlayActiveContent.uuid}`}
            cardStyle={{ transform: 'scale(1.05)' }}
            isDragOverlay={true}
          >
            <WbsStatusCardContent content={overlayActiveContent} />
          </Card>
        ) : null}
      </DragOverlay>
    </DndComponent>
  )
}

const DndArea = styled('div')({
  height: '100%',
  display: 'flex',
  flexWrap: 'nowrap',
  padding: '8px',
  gap: '8px',
  overflowX: 'auto',
  flex: 1,
})
