import { ICellRendererParams } from 'ag-grid-community'
import { FloatingEditableIcon } from '../../containers/commons/AgGrid/components/cell/common/text'
import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import formatter from '../../containers/meta/formatter'
import { RootDiv } from '../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'

export default function CellRenderer(params: ICellRendererParams) {
  const { data, colDef, value, valueFormatted, node } = params
  if (!data || data.total || !colDef) {
    return true
  }
  let renderValue = valueFormatted || value
  const wbsItem: WbsItemRow = data.wbsItem
  if (
    wbsItem.wbsItemType?.isTask() &&
    node.allLeafChildren?.some(v => {
      const wbsItem: WbsItemRow = v.data.wbsItem
      return (
        v.id !== node.id &&
        wbsItem.wbsItemType?.isTask() &&
        wbsItem.status !== WbsItemStatus.DISCARD
      )
    })
  ) {
    // Show aggregate on parent task row
    const aggregate = node.allLeafChildren
      .map(v => {
        if (!node || node.id === v.id) return 0
        const wbsItem: WbsItemRow = v.data.wbsItem
        if (
          wbsItem.wbsItemType?.isTask() &&
          wbsItem.status !== WbsItemStatus.DISCARD
        ) {
          return wbsItem.estimatedWorkload?.hour || 0
        }
        return 0
      })
      .reduce((a, b) => a + b, 0)
    renderValue = `${renderValue} / ${formatter.format(
      ((data.wbsItem.estimatedWorkload?.hour || 0) + aggregate) /
        (params.context.workloadUnitState?.hoursPerSelectedUnit || 1),
      params.colDef!.cellRendererParams.uiMeta
    )}`
  }

  return (
    <RootDiv>
      {renderValue}
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}
