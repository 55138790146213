import { DayOfWeek, TimeGrain } from '../../../lib/functions/report'
import { TimeGrainToggleGroup } from '../../components/editors/toggle/TimeGrainToggleGroup'
import { useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SelectStartDayOfWeekButton from '../../components/buttons/SelectStartDayOfWeekButton'
import SelectStartDayOfWeekDialog from '../../components/dialogs/SelectStartDayOfWeekDialog'

interface OwnProps {
  timeGrain: TimeGrain
  startDayOfWeek: DayOfWeek
  onChange: (key: string, value: string) => void
}

type Props = OwnProps & WrappedComponentProps

export const ViewConfigurationToolBar = injectIntl((props: Props) => {
  const { timeGrain, startDayOfWeek, onChange } = props
  const [anchorEl, setAnchorEl] = useState<any>(null)

  return (
    <>
      <TimeGrainToggleGroup
        value={timeGrain}
        onChange={newValue => onChange('timeGrain', newValue)}
      />
      <SelectStartDayOfWeekButton
        onClick={event => setAnchorEl(event.currentTarget)}
      />
      <SelectStartDayOfWeekDialog
        popoverAnchorElement={anchorEl}
        startDayOfWeek={startDayOfWeek}
        timeGrain={timeGrain}
        onClose={() => setAnchorEl(null)}
        onChange={newValue => onChange('startDayOfWeek', newValue)}
      />
    </>
  )
})
