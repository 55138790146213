import {
  ColDef,
  EditableCallbackParams,
  ValueGetterParams,
  ValueFormatterParams,
  CellClassParams,
  ICellEditorParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { intl } from '../../../../../i18n'
import { ClientSideSelectFilter } from '../../../../containers/BulkSheetView/components/filter'
import { wbsItemTypes } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { MultiAutocompleteCellEditor } from '../../../../containers/BulkSheetView/components/cellEditor'
import store from '../../../../../store'

export const wbsItemTypesColumnDef: ColDef = {
  ...wbsItemTypes,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data),
  valueFormatter: (params: ValueFormatterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    if (
      !additionalPropertyRow.wbsItemTypes ||
      additionalPropertyRow.wbsItemTypes.length === 0
    ) {
      return intl.formatMessage({
        id: 'wbsItemAdditionalProperties.wbsItemTypes.allWbsItemTypes',
      })
    }
    return additionalPropertyRow.wbsItemTypes.map(v => v.name).join(',')
  },
  cellEditor: MultiAutocompleteCellEditor,
  cellEditorParams: {
    search: (text: string, projectUuid: string) => {
      const { wbsItemTypes, ticketTypes } = store.getState().project
      return Promise.resolve(
        [...wbsItemTypes.getAll(), ...ticketTypes].filter(v =>
          v.name.includes(text)
        )
      )
    },
    label: option => option.name,
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: {
    valueGetter: (params: { node; context }) => {
      if (!params.node || isGroupHeaderRow(params.node.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      const allWbsItemTypes = params.context.wbsItemType
      const wbsItemTypes = additionalPropertyRow.wbsItemTypes
      return wbsItemTypes && wbsItemTypes.length > 0
        ? wbsItemTypes
        : allWbsItemTypes
    },
    getValue: (option: WbsItemTypeVO | undefined) => option?.uuid,
    getLabel: (option: WbsItemTypeVO | undefined) =>
      option?.getNameWithSuffix(),
    sortValues: (_, options: WbsItemTypeVO[], context) => {
      const allWbsItemTypes = context.wbsItemType
      return options.sort((a, b) => {
        return (
          allWbsItemTypes.findIndex(o => o.uuid === a.uuid) -
          allWbsItemTypes.findIndex(o => o.uuid === b.uuid)
        )
      })
    },
    filterChangedCallback: () => {
      return
    },
  },
}
