import _ from 'lodash'
import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import {
  PROFIT_LOSS_COLGROUP_ID_INFOMATION,
  RefreshDynamicColumnDefProps,
  financialStatementAccountsColDef,
  generalLedgerAccountsColDef,
  generateYearMonthList,
  processCellForClipboard,
  processCellFromClipboard,
  subsidiaryAccountsColDef,
} from '../../ProfitLossItems/gridOptions'
import DateVO from '../../../../vo/DateVO'
import {
  ProfitLossMemberRow,
  ProfitLossMemberRowMonthlyValueBody,
  ProfitLossMemberRowType,
} from '../ProfitLossMembers'
import {
  isHalfOrFullWidthNumber,
  parseFullNumberToHalf,
  roundNumber,
} from '../../../../utils/number'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { AccountCategory } from '../../ProfitLossItems/profitLossItems'
import { AUTO_COLUMN_ID } from '../../../containers/BulkSheet/const'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'

const WORK_MONTH_DECIMAP_POINTO: number = 2
const WORK_MONTH_VALUE_FORMAT: string = '0,0.00'

export const profitLossMembersGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    autoGroupColumnDef: {
      field: 'body.user',
      headerName: intl.formatMessage({ id: 'profitLossMembers.member' }),
      width: 250,
      pinned: true,
      suppressMovable: true,
      sortable: true,
      resizable: true,
      cellRendererParams: params => {
        const row: ProfitLossMemberRow = params.data
        return {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          innerRenderer: isRootRow(row)
            ? IconCellRenderer
            : DefaultCellRenderer,
          labelField: 'body.user.name',
          iconUrlField: 'body.user.iconUrl',
        }
      },
      valueGetter: getUserColumnValueGetter(),
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (!isRootRow(nodeA.data) || !isRootRow(nodeB.data)) return 0
        return (valueA ?? '').localeCompare(valueB ?? '')
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
      filterValueGetter: (params: ValueGetterParams) => {
        const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
        if (isRootRow(row)) {
          return row?.body?.user?.name || ''
        }
        if (row.body.type === ProfitLossMemberRowType.WorkMonthSchedule) {
          return intl.formatMessage({ id: 'profitLossMembers.manmonth' })
        } else if (row.body.type === ProfitLossMemberRowType.AmountBudget) {
          return intl.formatMessage({ id: 'profitLossMembers.amount' })
        }
        return ''
      },
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            ((!colDef.editable ||
              (typeof colDef.editable === 'function' &&
                !colDef.editable(params))) &&
              ProfitLossMemberRowType.AmountBudget.toString() !==
                data.body.type)
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        profit_loss_root_infomation_group_column: (
          params: CellClassParams<ProfitLossMemberRow>
        ) =>
          isRootRow(params.data) &&
          (params.column.getParent()?.getGroupId() ===
            PROFIT_LOSS_COLGROUP_ID_INFOMATION ||
            params.colDef.colId === AUTO_COLUMN_ID),
        profit_loss_root_row: params => isRootRow(params.data),
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        valueGetter: params => {
          if (params.data.isTotal) {
            return isRootRow(params.data)
              ? intl.formatMessage({ id: 'total' })
              : ''
          }
          return getRowNumber(params.node)
        },
      },
      {
        groupId: 'information',
        headerName: intl.formatMessage({ id: 'profitLossItems.information' }),
        children: [
          {
            ...financialStatementAccountsColDef,
            cellRendererParams: params => {
              return {
                value: !params.data?.body?.type
                  ? params.data?.body?.financialState?.displayName
                  : undefined,
              }
            },
            editable: params => {
              const category = params.context.accountCategory
              return (
                !params.data.isTotal &&
                isRootRow(params.data) &&
                category === AccountCategory.OperatingExpenses
              )
            },
          },
          {
            ...generalLedgerAccountsColDef,
            cellRendererParams: params => {
              return {
                value: !params.data?.body?.type
                  ? params.data?.body?.generalLedger?.displayName
                  : undefined,
              }
            },
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
          },
          subsidiaryAccountsColDef,
          {
            field: 'body.user',
            headerName: intl.formatMessage({ id: 'profitLossMembers.member' }),
            width: 300,
            hide: true,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            valueGetter: getUserColumnValueGetter(),
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
          },
          {
            field: 'body.position',
            headerName: intl.formatMessage({
              id: 'profitLossMembers.position',
            }),
            hide: false,
            sortable: true,
            pinned: true,
            floatingFilter: true,
            filter: 'clientSideTextFilter',
            resizable: true,
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow =
                params.data as ProfitLossMemberRow
              if (isRootRow(row)) {
                if (!!row.isTotal) {
                  return intl.formatMessage({ id: 'profitLossItems.forecast' })
                }
                return row?.body?.user?.partnerMember
                  ? intl.formatMessage({
                      id: 'profitLossMembers.business.partner',
                    })
                  : row?.body?.position?.displayName
              }
              if (
                row.body.type === ProfitLossMemberRowType.WorkMonthSchedule ||
                row.body.type === ProfitLossMemberRowType.AmountBudget
              ) {
                return intl.formatMessage({ id: 'profitLossItems.budget' })
              } else if (
                row.body.type === ProfitLossMemberRowType.WorkMonthActual ||
                row.body.type === ProfitLossMemberRowType.AmountResult
              ) {
                return intl.formatMessage({ id: 'profitLossItems.result' })
              }
              return ''
            },
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'total' }),
            hide: false,
            sortable: true,
            pinned: true,
            floatingFilter: true,
            filter: 'clientSideNumberFilter',
            resizable: true,
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
            },
            valueGetter: (params: ValueGetterParams) => {
              const row: ProfitLossMemberRow =
                params.data as ProfitLossMemberRow
              if (isWorkMonthRow(row)) {
                return ''
              }
              if (row.isTotal) {
                let sumValue = 0
                const summaryType = row.body.type
                params.api.forEachNode(node => {
                  const nodeData: ProfitLossMemberRow =
                    node.data as ProfitLossMemberRow
                  if (
                    !!nodeData &&
                    !nodeData.isTotal &&
                    nodeData.body?.type === summaryType
                  ) {
                    const monthlyValues = nodeData.body?.monthlyValues ?? []
                    const tempSum = monthlyValues.reduce((prev, current) => {
                      return prev + (current.value ?? 0)
                    }, 0)
                    sumValue += tempSum
                  }
                })
                return sumValue
              }
              const monthlyValues: ProfitLossMemberRowMonthlyValueBody[] =
                row?.body?.monthlyValues ?? []
              const sum = monthlyValues.reduce((prev, current) => {
                return prev + (current.value ?? 0)
              }, 0)
              return sum
            },
            cellClass: (params: CellClassParams) => {
              const row: ProfitLossMemberRow = params.data
              return isWorkMonthRow(row) ? '' : 'numberStyle'
            },
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0',
        },
      },
      {
        id: 'workMonthStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0.00',
        },
      },
    ],
    processCellForClipboard,
    processCellFromClipboard,
  }
}

const MONTHLY_VALUE_FIELD_PREFIX = 'body.monthlyValues.'

export const isMonthlyValueColumn = (id: string | undefined) => {
  return !!id && id.startsWith(MONTHLY_VALUE_FIELD_PREFIX)
}

export const generateYearMonthField = (date: DateVO) => {
  return MONTHLY_VALUE_FIELD_PREFIX + date.format('YYYY/MM')
}

const getMonthlyValueByType = (
  rowNodes: RowNode[] | null,
  month: DateVO,
  rowType: string
): number | undefined => {
  const node = rowNodes?.find(
    r => (r.data as ProfitLossMemberRow)?.body.type === rowType
  )
  if (!node) return undefined
  return getMonthlyValueByMonth(node.data, month)?.value
}

const getMonthlyValueByMonth = (
  row: ProfitLossMemberRow,
  month: DateVO
): ProfitLossMemberRowMonthlyValueBody | undefined => {
  const values = row.body?.monthlyValues ?? []
  return values.find(v => v.yearMonth === month.formatForApi())
}

const autoCalculateMonthlyValue = (
  api: GridApi,
  editRowNode: RowNode | null,
  date: DateVO,
  calcRowType: string,
  field: string,
  editRowValue: number,
  calculate: (srcValue: number, unitPrice: number) => number
): RowNode[] | undefined => {
  const parentNode = editRowNode?.parent
  if (!parentNode) throw new Error('Illegal argument')

  // get budget amount
  const budgetAmount: number | undefined = getMonthlyValueByType(
    parentNode.childrenAfterGroup,
    date,
    ProfitLossMemberRowType.AmountBudget
  )
  if (!budgetAmount) return undefined

  // get scheduled work month
  const scheduledWorkMonth: number | undefined = getMonthlyValueByType(
    parentNode.childrenAfterGroup,
    date,
    ProfitLossMemberRowType.WorkMonthSchedule
  )
  if (!scheduledWorkMonth) return undefined

  const targetRowNode = parentNode.childrenAfterGroup?.find(
    n => (n.data as ProfitLossMemberRow)?.body.type === calcRowType
  )
  if (!targetRowNode) return
  const targetRowMonthlyValue = getMonthlyValueByMonth(targetRowNode.data, date)

  const targetRow: ProfitLossMemberRow = targetRowNode.data
  const oldValue = targetRowMonthlyValue
    ? {
        value: targetRowMonthlyValue?.value,
        manualEdited: targetRowMonthlyValue?.manualEdited,
      }
    : undefined
  const newValue = roundMonthlyValue(
    calculate(editRowValue, budgetAmount / scheduledWorkMonth),
    targetRow.body.type
  )
  if (oldValue?.value === newValue && !oldValue.manualEdited) return

  if (targetRowMonthlyValue) {
    targetRowMonthlyValue.value = newValue
    targetRowMonthlyValue.manualEdited = false
  } else {
    targetRow.body.monthlyValues.push({
      yearMonth: date.formatForApi(),
      value: newValue,
      manualEdited: false,
    })
  }
  targetRow.edited = !targetRow.added
  if (!targetRow.editedData) {
    targetRow.editedData = { [field]: oldValue }
  } else if (!targetRow.editedData.hasOwnProperty(field)) {
    targetRow.editedData[field] = oldValue
  }

  if (ProfitLossMemberRowType.AmountResult === calcRowType) {
    updateRootRowMonthAmountValue(
      api,
      targetRow,
      parentNode.data,
      date,
      newValue
    )
  }
  return [targetRowNode]
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const columnDefs = api?.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(MONTHLY_VALUE_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(MONTHLY_VALUE_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${MONTHLY_VALUE_FIELD_PREFIX}${year}`,
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.year' },
          { year }
        ),
        children: [],
      }
    })
    const firstDayOfMonth = DateVO.now().getFirstDayOfMonth()
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const isCurrentMonth = date.isEqual(firstDayOfMonth)
      const col: ColDef = {
        field: generateYearMonthField(date),
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.month' },
          { month: date.getMonth() }
        ),
        width: 80,
        hide: false,
        pinned: false,
        suppressMovable: true,
        resizable: true,
        cellRenderer: NumberCellRenderer,
        cellClassRules: {
          profit_loss_item_past_month: params => {
            return (
              isRootRow(params.data) &&
              date.isBefore(DateVO.now().getFirstDayOfMonth())
            )
          },
        },
        cellRendererParams: params => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          return {
            numberWithCommas: true,
            decimalPoints: isWorkMonthRow(row) ? WORK_MONTH_DECIMAP_POINTO : 0,
          }
        },
        editable: (params: EditableCallbackParams) => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          const category = params.context.accountCategory
          return (
            !row.isTotal &&
            !isRootRow(row) &&
            (ProfitLossMemberRowType.AmountResult.toString() ===
              row.body.type ||
              ProfitLossMemberRowType.WorkMonthActual.toString() ===
                row.body.type ||
              (ProfitLossMemberRowType.AmountBudget.toString() ===
                row.body.type &&
                category === AccountCategory.OperatingRevenue))
          )
        },
        valueGetter: (params: ValueGetterParams) => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          if (row.isTotal) {
            let sumValue = 0
            const summaryType = row.body.type
            api?.forEachNode(node => {
              const row: ProfitLossMemberRow = node.data as ProfitLossMemberRow
              if (!!row && !row.isTotal && row.body.type === summaryType) {
                sumValue += getMonthlyValueByMonth(row, date)?.value ?? 0
              }
            })
            return sumValue
          }
          const value = getMonthlyValueByMonth(row, date)?.value
          return value ?? ''
        },
        valueSetter: (params: ValueSetterParams) => {
          const { colDef, context, data, oldValue, newValue } = params
          const field = colDef.field || colDef.colId
          const parseVal = parseFullNumberToHalf(newValue ?? '')
          if (
            (!!newValue && !isHalfOrFullWidthNumber(newValue)) ||
            parseVal.startsWith('.')
          ) {
            return false
          }
          const numVal = Number(parseVal ?? 0)
          if (!field || oldValue === newValue || Number.isNaN(numVal)) {
            return false
          }
          const formatDate = date.formatForApi()
          const row: ProfitLossMemberRow = data
          const monthlyValue = row.body.monthlyValues.find(
            cell => cell.yearMonth === formatDate
          )

          let autoCalculated: RowNode[] | undefined = undefined
          if (
            AccountCategory.OperatingExpenses.toString() ===
            context.accountCategory
          ) {
            if (ProfitLossMemberRowType.WorkMonthActual === row.body.type) {
              autoCalculated = autoCalculateMonthlyValue(
                params.api,
                params.node,
                date,
                ProfitLossMemberRowType.AmountResult,
                field,
                numVal,
                (srcValue: number, unitPrice: number) => srcValue * unitPrice
              )
            } else if (ProfitLossMemberRowType.AmountResult === row.body.type) {
              autoCalculated = autoCalculateMonthlyValue(
                params.api,
                params.node,
                date,
                ProfitLossMemberRowType.WorkMonthActual,
                field,
                numVal,
                (srcValue: number, unitPrice: number) => srcValue / unitPrice
              )
            }
          }
          if (autoCalculated) {
            params.api.refreshCells({
              rowNodes: autoCalculated,
              force: true,
            })
          }
          if (
            monthlyValue &&
            !!monthlyValue?.manualEdited &&
            monthlyValue.value === (_.isEmpty(newValue) ? undefined : numVal)
          ) {
            return !!autoCalculated
          }
          store.dispatch(requireSave())
          const oldMonthlyValue: ProfitLossMemberRowMonthlyValueBody = {
            value: oldValue,
            manualEdited: monthlyValue?.manualEdited,
          }
          if (_.isEmpty(newValue)) {
            row.body.monthlyValues = row.body.monthlyValues.filter(
              v => v.yearMonth !== formatDate
            )
            row.edited = !row.added
          } else if (!monthlyValue) {
            row.body.monthlyValues.push({
              yearMonth: formatDate,
              value: numVal,
              manualEdited: true,
            })
            row.edited = !row.added
          } else {
            monthlyValue.value = numVal
            monthlyValue.manualEdited = true
            row.edited = !row.added
          }
          if (!row.editedData) {
            row.editedData = { [field]: oldMonthlyValue }
          } else if (!row.editedData.hasOwnProperty(field)) {
            row.editedData[field] = oldMonthlyValue
          }

          if (isWorkMonthRow(row)) {
            return true
          }
          const rootUuid = row.treeValue[0]
          api.forEachNode((node, index) => {
            const nodeData: ProfitLossMemberRow = node.data
            if (nodeData?.uuid === rootUuid) {
              updateRootRowMonthAmountValue(api, row, nodeData, date, numVal)
            }
          })
          return true
        },
        cellStyle: (params: CellClassParams) => {
          const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
          return AccountCategory.OperatingExpenses.toString() ===
            params.context.accountCategory &&
            getMonthlyValueByMonth(row, date)?.manualEdited
            ? { backgroundColor: '#92D050' }
            : isCurrentMonth
            ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE
            : undefined
        },
        cellClass: (params: CellClassParams) => {
          const row: ProfitLossMemberRow = params.data
          return isWorkMonthRow(row) ? 'workMonthStyle' : 'numberStyle'
        },
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${MONTHLY_VALUE_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => {
      newColDefs?.push(year)
    })
  }
  if (newColDefs) {
    api.setColumnDefs(newColDefs)
  }
}

export const isRootRow = (data: ProfitLossMemberRow | undefined) => {
  if (!data) return false
  return !Object.values(ProfitLossMemberRowType)
    .map(t => t.toString())
    .includes(data.body?.type)
}

const isWorkMonthRow = (data: ProfitLossMemberRow) => {
  return isWorkMonthRowType(data?.body?.type)
}

const isWorkMonthRowType = (type: string) => {
  return (
    ProfitLossMemberRowType.WorkMonthSchedule === type ||
    ProfitLossMemberRowType.WorkMonthActual === type
  )
}

const roundMonthlyValue = (value: number, type: string) => {
  const decimalPoint = isWorkMonthRowType(type) ? WORK_MONTH_DECIMAP_POINTO : 0
  return roundNumber(value, decimalPoint)
}

const getUserColumnValueGetter = () => {
  return (params: ValueGetterParams) => {
    const row: ProfitLossMemberRow = params.data as ProfitLossMemberRow
    if (row.isTotal) {
      switch (row.body.type) {
        case ProfitLossMemberRowType.AmountBudget:
          return intl.formatMessage({ id: 'profitLossMembers.amount' })
        case ProfitLossMemberRowType.AmountResult:
          return intl.formatMessage({ id: 'profitLossMembers.amount' })
        default:
          return ''
      }
    }
    if (isRootRow(row)) {
      return row?.body?.user?.name || ''
    }
    const visibleTypes = params.context?.rowFilterTypes || []
    if (!visibleTypes.includes(row.body.type)) return ''

    switch (row.body.type) {
      case ProfitLossMemberRowType.WorkMonthSchedule:
        return intl.formatMessage({ id: 'profitLossMembers.manmonth' })
      case ProfitLossMemberRowType.WorkMonthActual:
        return !visibleTypes.includes(ProfitLossMemberRowType.WorkMonthSchedule)
          ? intl.formatMessage({ id: 'profitLossMembers.manmonth' })
          : ''
      case ProfitLossMemberRowType.AmountBudget:
        return intl.formatMessage({ id: 'profitLossMembers.amount' })
      case ProfitLossMemberRowType.AmountResult:
        return !visibleTypes.includes(ProfitLossMemberRowType.AmountBudget)
          ? intl.formatMessage({ id: 'profitLossMembers.amount' })
          : ''
      default:
        return ''
    }
  }
}

export const updateRootRowMonthAmountValue = (
  api: GridApi,
  editedRow: ProfitLossMemberRow,
  rootRow: ProfitLossMemberRow,
  targetMonth: DateVO,
  newValue: number | undefined
) => {
  const editedRowType = editedRow.body.type
  const formatMonth = targetMonth.formatForApi()
  const rootMonthlyValue = rootRow?.body.monthlyValues.find(
    cell => cell.yearMonth === formatMonth
  )
  let budgetAmounts: ProfitLossMemberRowMonthlyValueBody[] = []
  let resultAmounts: ProfitLossMemberRowMonthlyValueBody[] = []
  api.forEachNode((node, index) => {
    if (node.data.treeValue.includes(rootRow.uuid)) {
      if (node.data.body.type === ProfitLossMemberRowType.AmountBudget) {
        budgetAmounts = node.data.body.monthlyValues
      } else if (node.data.body.type === ProfitLossMemberRowType.AmountResult) {
        resultAmounts = node.data.body.monthlyValues
      }
    }
  })
  const resultAmount = resultAmounts.find(
    cell => cell.yearMonth === formatMonth
  )
  if (resultAmount) {
    const addValue =
      editedRowType !== ProfitLossMemberRowType.AmountResult.toString()
        ? resultAmount.value
        : newValue
    if (!rootMonthlyValue) {
      rootRow.body.monthlyValues.push({
        yearMonth: formatMonth,
        value: addValue,
      })
    } else if (rootMonthlyValue && rootMonthlyValue.value !== addValue) {
      rootMonthlyValue.value = addValue
    }
    return
  }
  const budgetAmount = budgetAmounts.find(
    cell => cell.yearMonth === formatMonth
  )
  if (budgetAmount) {
    const addValue =
      editedRowType !== ProfitLossMemberRowType.AmountBudget.toString()
        ? budgetAmount.value
        : newValue
    if (!rootMonthlyValue) {
      rootRow.body.monthlyValues.push({
        yearMonth: formatMonth,
        value: addValue,
      })
    } else if (rootMonthlyValue && rootMonthlyValue.value !== addValue) {
      rootMonthlyValue.value = addValue
    }
    return
  }
  if (!resultAmount && !budgetAmount && rootMonthlyValue) {
    rootRow.body.monthlyValues = rootRow.body.monthlyValues.filter(
      monthlyValue => monthlyValue.yearMonth !== rootMonthlyValue.yearMonth
    )
    return
  }
}
