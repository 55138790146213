import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import { IconButton } from '@mui/material'
import { IconColor } from '../../../styles/commonStyles'

const CalendarIcon = ({ onClick }: { onClick?: () => void }) => {
  const calendar = (
    <CalendarTodayRoundedIcon
      style={{
        color: IconColor.GREY,
        fill: IconColor.GREY,
        fontSize: 18,
      }}
    />
  )
  if (onClick) {
    return (
      <IconButton size={'small'} onClick={onClick}>
        {calendar}
      </IconButton>
    )
  }
  return calendar
}

export default CalendarIcon
