import Auth from '../../../lib/commons/auth'
import { OrganizationDetail } from '../../../lib/functions/tenant'
import TextVO from '../../../vo/TextVO'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import { SubmitType } from '../../containers/meta/ViewMeta'
import { SingleSheetOptions } from '../../containers/SingleSheet'
import { TableData } from '../../containers/SingleSheet/DataTable'

class TenantConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return dest
  }
  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    submitType: SubmitType
    delta?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return {
      before: initialData,
      after: dest,
      delta: delta
        ? {
            uuid: dest.uuid,
            ...delta,
          }
        : undefined,
    }
  }
  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}

class TenantData extends TableData {
  code?: TextVO
  displayName?: TextVO
  getCode() {
    return this.code
  }
  getName() {
    return this.displayName
  }
}

export default class Tenant extends SingleSheetOptions<TenantData> {
  converterSpec = new TenantConverterSpec()
  selectKey = () => {
    return {
      uuid: Auth.getCurrentTenant()!.tenantUuid,
    }
  }
  afterGet = (data: OrganizationDetail) => {
    const tenant = Auth.getCurrentTenant()!
    tenant.refreshOrganization()
    return data
  }
}
