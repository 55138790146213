import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterFooter, FilterInput } from '../common'
import { useSelectFilter } from '../SelectFilter/useSelectFilter'
import {
  SelectFilter,
  SelectFilterFormControl,
  SelectFilterArea,
  CheckboxArea,
  FilterLabel,
} from '../SelectFilter'
import { Checkbox, Typography } from '@mui/material'
import { ICON_SIZE } from '../../../../BulkSheet/const'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'

type Props = IFilterParams & {
  optionKey: string
  fetchOptions?: (params: IFilterParams) => Promise<object[]>
  getValue: (option) => string
  getLabel: (option) => string
  fetch: (v: SelectFilter) => Promise<APIResponse>
  /**
   * if defined, filter values are limited to the values returned by this function.
   */
  getValuesForOptionFilter?: (context) => Promise<string>
  sortValues?: (context) => any
}

export const ServerSideSelectFilter = forwardRef((params: Props, ref) => {
  const {
    optionKey,
    fetchOptions,
    getValue,
    getLabel,
    fetch,
    getValuesForOptionFilter,
    filterChangedCallback,
    context,
    sortValues,
  } = params
  const [options, setOptions] = useState<any[]>([])
  const refreshOptions = useCallback(async () => {
    let allOptions = context[optionKey] ?? []
    if (!optionKey && fetchOptions) {
      allOptions = await fetchOptions(params)
    }
    const optionFilterValues =
      getValuesForOptionFilter && (await getValuesForOptionFilter(context))
    const optionsInternal = optionFilterValues
      ? allOptions.filter(option =>
          optionFilterValues.includes(getValue(option))
        )
      : allOptions
    if (sortValues) sortValues(optionsInternal)
    setOptions(optionsInternal)
  }, [])
  useEffect(() => {
    refreshOptions()
  }, [])
  const hasRequiredSaveData = useSelector<AllState>(
    state => state.hasRequiredSaveData.hasRequiredSaveData
  )
  const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

  // State
  const filter = useSelectFilter()
  const [text, setText] = useState('')
  const [loading, isLoading] = useState(false)
  const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
    undefined
  )
  const restoredFilterState = useRef<boolean>()

  const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
  useEffect(() => {
    if (addedRowIds.current) return
    addedRowIds.current = []
  }, [])
  const fetchFilteredIds = async (v: SelectFilter) => {
    const response = await fetch(v)
    setFilteredIds(response.json)
  }
  const search = useCallback(
    _.debounce(async (v: SelectFilter) => {
      try {
        isLoading(true)
        await fetchFilteredIds(v)
      } finally {
        isLoading(false)
      }
    }, 300),
    []
  )

  // Ag-grid custom filter
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.added) {
          if (addedRowIds.current && params.node.id) {
            addedRowIds.current.push(params.node.id)
          }
          return true
        }
        if (
          addedRowIds.current &&
          params.node.id &&
          addedRowIds.current.includes(params.node.id)
        ) {
          return true
        }
        if (!filteredIds) return true
        return params.node.id && filteredIds.includes(params.node.id)
      },

      isFilterActive() {
        return !!filteredIds
      },

      getModel() {
        return filter.model()
      },

      setModel(model?: { values: string[]; includeBlank: boolean }) {
        if (!model) {
          filter.reset()
          return
        }
        restoredFilterState.current = true
        filter.setValues(model.values)
        filter.setIncludeBlank(model.includeBlank)
      },

      getModelAsString() {
        if (!filter.isActive()) return ''
        return `${filter.includeBlank ? '(Blank),' : ''}${options
          .filter(option => filter.values.includes(getValue(option)))
          .map(option => getLabel(option))
          .join(',')}`
      },

      onNewRowsLoaded() {
        if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
        refreshOptions()
        const filterModel = filter.model()
        if (filterModel) {
          fetchFilteredIds(filterModel)
        }
      },
    }
  })

  // Filter
  useEffect(() => {
    if (restoredFilterState.current) {
      filter.setOptions(options.map(getValue) ?? [])
    } else {
      filter.init(options.map(getValue) ?? [])
      options.length > 0 && (restoredFilterState.current = true)
    }
  }, [options])

  useEffect(() => {
    if (!filter.isActive()) {
      setFilteredIds(undefined)
      return
    }
    if (filter.isAllDeselected()) {
      setFilteredIds([])
      return
    }
    search(filter.model())
  }, [filter.values, filter.includeBlank])

  useEffect(() => {
    const filterModel = filter.model()
    const isAfterSavedOrRefreshed =
      prevHasRequiredSaveData && !hasRequiredSaveData
    isAfterSavedOrRefreshed && refreshOptions()
    if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
      fetchFilteredIds(filterModel).then(() => {
        addedRowIds.current = []
      })
    }
  }, [hasRequiredSaveData])

  useEffect(() => {
    filterChangedCallback()
  }, [filteredIds])

  return (
    <BulkSheetFilter>
      <FilterInput
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder={'Search...'}
        sx={{ height: 22 }}
      />
      <SelectFilterArea>
        <SelectFilterFormControl
          label="(Select All)"
          control={
            <Checkbox
              checked={filter.isAllSelected()}
              indeterminate={
                !filter.isAllSelected() && !filter.isAllDeselected()
              }
              onChange={e =>
                e.target.checked ? filter.selectAll() : filter.deselectAll()
              }
              disableRipple={true}
            />
          }
        />
        <CheckboxArea>
          {!text && (
            <SelectFilterFormControl
              label="(Blanks)"
              control={
                <Checkbox
                  checked={filter.includeBlank}
                  onChange={e => filter.setIncludeBlank(e.target.checked)}
                  disableRipple={true}
                />
              }
            />
          )}
          {options
            .filter(option => !text || getLabel(option).includes(text))
            .map(option => {
              const value = getValue(option)
              return (
                <SelectFilterFormControl
                  key={`select-filter-${value}`}
                  label={
                    <FilterLabel
                      sx={{
                        backgroundColor: option.backgroundColor ?? 'inherit',
                      }}
                    >
                      {option.iconUrl && (
                        <img
                          src={option.iconUrl}
                          style={{
                            height: `${ICON_SIZE}px`,
                            width: `${ICON_SIZE}px`,
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <Typography sx={{ padding: '0 3px' }}>
                        {getLabel(option)}
                      </Typography>
                    </FilterLabel>
                  }
                  control={
                    <Checkbox
                      checked={filter.values.includes(value)}
                      onChange={e => {
                        if (e.target.checked) {
                          filter.setValues([...filter.values, value])
                        } else {
                          filter.setValues(
                            filter.values.filter(v => v !== value)
                          )
                        }
                      }}
                      disableRipple={true}
                    />
                  }
                />
              )
            })}
        </CheckboxArea>
      </SelectFilterArea>
      <FilterFooter loading={loading} onClick={() => filter.reset()} />
    </BulkSheetFilter>
  )
})
