import { useCallback, useState } from 'react'

export const useColumnSetting = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = useCallback(e => {
    setAnchorEl(e.currentTarget ?? undefined)
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setAnchorEl(undefined)
    setIsOpen(false)
  }, [])

  const toggle = useCallback(
    e => {
      setAnchorEl(isOpen ? undefined : e.currentTarget ?? undefined)
      setIsOpen(!isOpen)
    },
    [isOpen]
  )

  return {
    anchorEl,
    isOpen,

    open,
    close,
    toggle,
  }
}
