import { useCallback } from 'react'
import { intl } from '../../../../../i18n'
import ExclusiveToggleGroup from '../../../../components/editors/toggle/ExclusiveToggleGroup'
import { RowGroupColumnType } from '../../ResourcePlanCrossProjects'

export const RowGroupColumnToggleGroup = ({
  value,
  onChange,
}: {
  value: RowGroupColumnType | undefined
  onChange: (value: RowGroupColumnType | undefined) => void
}) => {
  const stringToRowGroupColumnType = useCallback(
    (strValue: string): RowGroupColumnType | undefined => {
      switch (strValue) {
        case RowGroupColumnType.MEMBER.toString():
          return RowGroupColumnType.MEMBER
        case RowGroupColumnType.PROJECT.toString():
          return RowGroupColumnType.PROJECT
        default:
          break
      }
    },
    []
  )

  return (
    <ExclusiveToggleGroup
      value={value?.toString()}
      onChange={value => onChange(stringToRowGroupColumnType(value))}
      options={[
        {
          label: intl.formatMessage({
            id: 'resourcePlan.crossProjects.group.column.member',
          }),
          value: RowGroupColumnType.MEMBER.toString(),
        },
        {
          label: intl.formatMessage({
            id: 'resourcePlan.crossProjects.group.column.project',
          }),
          value: RowGroupColumnType.PROJECT.toString(),
        },
      ]}
    />
  )
}
