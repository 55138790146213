import _ from 'lodash'
import moment from 'moment'
import { intl } from '../../../i18n'
import {
  DayOfWeek,
  getTimeUnitSizeByGrain,
  getTimeUnitByGrain,
  TimeGrain,
  toMaxDimTime,
  toMinDimTime,
} from '../../../lib/functions/report'
import { fromSnakeToCamel } from '../../../utils/string'

export enum AggregateColumnGroup {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  CREATED_AT = 'CREATED_AT',
  ACTUAL_HOUR_RECORDED_AT = 'ACTUAL_HOUR_RECORDED_AT',
}

export type ProgressReportDataValue = {
  dimTime: number
  data: ProgressReportData
}

export type ProgressReportData = {
  workload: number
  count: number
  workloadMap: { [code: string]: number }
}

export enum SummarizeType {
  UNIT,
  BURN_UP,
  BURN_DOWN,
}

// xValues calculators
export const getXValues = (
  timeGrain: TimeGrain,
  startDayOfWeek: DayOfWeek,
  viewTime: { from: number; to: number }
) => {
  const xValues: number[] = []
  const from = toMinDimTime(viewTime.from, timeGrain, startDayOfWeek)
  const to = toMaxDimTime(viewTime.to, timeGrain, startDayOfWeek)
  const timeFrom = moment(from)
  const timeTo = moment(to)
  const momentUnit = getTimeUnitByGrain(timeGrain)
  const unitSize = getTimeUnitSizeByGrain(timeGrain)
  const timeDiff = Math.ceil(timeTo.diff(timeFrom, momentUnit) / unitSize)
  for (let i = 0; i <= timeDiff; i++) {
    xValues.push(
      moment(timeFrom)
        .add(unitSize * i, momentUnit)
        .valueOf()
    )
  }
  return xValues
}

// other utils

export const getAggregateColumnGroupLabel = (
  aggregateColumnGroup: AggregateColumnGroup
) => {
  return intl.formatMessage({
    id: `progressReport.aggregateColumn.${fromSnakeToCamel(
      aggregateColumnGroup
    )}`,
  })
}

export const getAggregateColumnGroupShortLabel = (
  aggregateColumnGroup: AggregateColumnGroup
) => {
  return intl.formatMessage({
    id: `progressReport.${fromSnakeToCamel(aggregateColumnGroup)}`,
  })
}

export const excludeWbsItemCodes = (
  targetCodes?: string[],
  excludeCodes?: string[]
) => {
  if (_.isEmpty(targetCodes)) {
    return undefined
  }
  return excludeCodes
    ? targetCodes?.filter(c => !excludeCodes.includes(c))
    : targetCodes
}
