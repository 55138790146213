import { useDialogState } from '../../../components/dialogs/useDialogState'

export const useDialog = () => {
  const [addMember, openAddMember, closeAddMember] = useDialogState()
  const [addTeam, openAddTeam, closeAddTeam] = useDialogState()
  const [deleteConfirm, openDeleteConfirm, closeDeleteConfirm] =
    useDialogState()
  const [cancelConfirm, openCancelConfirm, closeCancelConfirm] =
    useDialogState()
  const [uiState, openUiState, closeUiState] = useDialogState()
  const [excelExport, openExcelExport, closeExcelExport] = useDialogState()

  return {
    addMember,
    openAddMember,
    closeAddMember,

    addTeam,
    openAddTeam,
    closeAddTeam,

    deleteConfirm,
    openDeleteConfirm,
    closeDeleteConfirm,

    cancelConfirm,
    openCancelConfirm,
    closeCancelConfirm,

    uiState,
    openUiState,
    closeUiState,

    excelExport,
    openExcelExport,
    closeExcelExport,
  }
}
