import LockIcon from '@mui/icons-material/LockRounded'
import GroupIcon from '@mui/icons-material/GroupRounded'
import { styled } from '@mui/material'
import { UiStateScope } from '../../../services/model/uiState'
import { useMemo } from 'react'
import ApartmentIcon from '@mui/icons-material/Apartment'

export const CrossProjectScopeIcon = styled(ApartmentIcon)({
  width: '17px',
})

export const TenantScopeIcon = styled(GroupIcon)({
  width: '17px',
})

export const UserScopeIcon = styled(LockIcon)({
  width: '17px',
})

type UiStateScopeIconProps = {
  scope: UiStateScope
}

export const UiStateScopeIcon = ({ scope }: UiStateScopeIconProps) => {
  const icon = useMemo(() => {
    switch (scope) {
      case 'CROSS_PROJECT':
        return <CrossProjectScopeIcon />
      case 'TENANT':
        return <TenantScopeIcon />
      case 'USER':
        return <UserScopeIcon />
    }
  }, [scope])
  return icon
}
