import {
  getWbsItemStatusColorCode,
  getWbsItemTreeStatusColorCode,
} from '../../../../../../../../lib/functions/wbsItem'
import objects from '../../../../../../../../utils/objects'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

const STYLE_BASE = {
  backgroundSize: 'calc(100% + 1px)',
}

export default function (params) {
  if (!params.node.data || params.node.data.isViewOnly) {
    return { ...STYLE_BASE, backgroundColor: '#F7F7F7' }
  }
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    params.data,
    'wbsItem.wbsItemType'
  )
  if (wbsItemType.isTask()) {
    return {
      ...STYLE_BASE,
      backgroundColor: getWbsItemStatusColorCode(params.value),
    }
  }
  return {
    background: getWbsItemTreeStatusColorCode(params.value),
    ...STYLE_BASE,
  }
}
