import { Epic, ofType } from 'redux-observable'
import { Observable, Subscriber } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import API from '../lib/commons/api'
import Auth from '../lib/commons/auth'

export interface ApplicationProp {
  maintenanceMessage: string
  maintenanceStartAt: number
  maintenanceEndAt: number
  status: ApplicationStatus
}

export enum ApplicationStatus {
  NORMAL = 'NORMAL',
  UNDER_MAINTENANCE = 'UNDER_MAINTENANCE',
  BEFORE_MAINTENANCE = 'BEFORE_MAINTENANCE',
}

type State = {
  maintenanceMessage?: string
  maintenanceStartAt?: number
  maintenanceEndAt?: number
  status: ApplicationStatus
}

// Actions
enum ActionType {
  SUBSCRIBE_APPLICATION = 'SUBSCRIBE_APPLICATION',
  RECEIVED_APPLICATION = 'RECEIVED_APPLICATION',
}

export const subscribeApplication = (applicationUuid: string) => ({
  type: ActionType.SUBSCRIBE_APPLICATION,
  applicationUuid,
})

// Epics
export const subscribeApplicationEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_APPLICATION),
    mergeMap(action => {
      let subscriber: Subscriber<ApplicationProp>
      const observable = new Observable<ApplicationProp>(sub => {
        subscriber = sub
      })
      const tenant = Auth.getCurrentTenant()!
      if (tenant) {
        API.notification.subscribe<ApplicationProp>(
          `${tenant.tenantUuid}/ApplicationNotification/${action.applicationUuid}`,
          application => {
            subscriber.next(application)
          }
        )
      }
      return observable
    }),
    map(application => {
      return {
        type: ActionType.RECEIVED_APPLICATION,
        application,
      }
    })
  )

// Reducers
export const reducer = (
  state: State = { status: ApplicationStatus.NORMAL },
  action: any
): State => {
  switch (action.type) {
    case ActionType.RECEIVED_APPLICATION:
      return {
        ...action.application,
      }
    default:
      return state
  }
}
