import { styled } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { useMemo } from 'react'

export const WidgetTitle = styled('div')({
  fontSize: '12px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
})

export const Widget = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  backgroundColor: colorPalette.monotone[0],
  padding: '8px 28px',
  display: 'flex',
  alignItems: 'center',
})

export const WidgetForExpanded = styled(Widget)({
  height: '140px',
})

export const WidgetForCollapsed = styled(Widget)({
  height: '80px',
})

export const WidgetContent = styled('div')({
  display: 'flex',
  gap: '16px',
})

export const WidgetWithTitle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
})

type KpiProps = {
  title: string
  value: string
  unit: string
} & KpiStyleProps
type KpiStyleProps = {
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl'
}
export const Kpi = ({ title, value, unit, ...styleProps }: KpiProps) => (
  <KpiWithTitle {...styleProps}>
    <KpiTitle>{title}</KpiTitle>
    <KpiValueWithUnit>
      <KpiValue>{value}</KpiValue>
      <KpiUnit>{unit}</KpiUnit>
    </KpiValueWithUnit>
  </KpiWithTitle>
)

export const KpiWithTitle = styled('div')(({ size = 'm' }: KpiStyleProps) => {
  const width = useMemo(() => {
    switch (size) {
      case 's':
        return '70px'
      case 'm':
        return '80px'
      case 'l':
        return '100px'
      case 'xl':
        return '120px'
      case 'xxl':
        return '200px'
    }
  }, [size])
  return {
    width,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  }
})

export const KpiTitle = styled('div')({
  fontSize: '11px',
  fontWeight: 600,
  color: colorPalette.monotone[9],
})

export const KpiValue = styled('div')({
  fontSize: '21px',
  fontWeight: 600,
  textAlign: 'end',
  color: colorPalette.monotone[9],
})

export const KpiUnit = styled('div')({
  fontSize: '10px',
  color: colorPalette.monotone[4],
  paddingBottom: '4px',
})

export const KpiValueWithUnit = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'flex-end',
})

export const KpiValuesColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const DatePrefix = styled('div')({
  fontSize: '10px',
  color: colorPalette.monotone[4],
  width: '20px',
  paddingBottom: '4px',
})

type DateComponentProps = { date: Date }
export const DateComponent = ({ date }: DateComponentProps) => {
  const formatted = useMemo(() => {
    const formattedYear = date.getFullYear().toString().substring(2, 4)
    const monthValue = date.getMonth() + 1
    const formattedMonth =
      monthValue >= 10 ? monthValue.toString() : `0${monthValue}`
    const dateValue = date.getDate()
    const formattedDate =
      dateValue >= 10 ? dateValue.toString() : `0${dateValue}`
    return `${formattedYear}/${formattedMonth}/${formattedDate}`
  }, [date])
  return <StyledDateComponent>{formatted}</StyledDateComponent>
}

export const StyledDateComponent = styled('div')({
  fontSize: '16px',
  fontWeight: 600,
  color: colorPalette.monotone[9],
})

export const DateWithPrefix = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '6px',
})

export const DateTermColumnComponent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

export const DateTermRowComponent = styled('div')({
  display: 'flex',
  gap: '6px',
})
