import { IconButton, InputBase } from '@mui/material'
import { styled } from '@mui/system'
import { EventNote } from '@mui/icons-material'
import React from 'react'
import { CellDef } from '../../../containers/meta/ViewMeta/SingleSheetViewMeta'
import DatePicker from 'react-datepicker'
import store from '../../../../store'
import { applyDiff } from '../../../../store/singleSheet'
import Tooltip from '../../../components/tooltips/Tooltip'
import { intl } from '../../../../i18n'
import DateVO from '../../../../vo/DateVO'
import './styles.scss'
import { requireSave } from '../../../../store/requiredSaveData'

const RootDiv = styled('div')({
  display: 'flex',
  margin: '0 8px',
  textAlign: 'center',
  cursor: 'pointer',
  lineHeight: '20px',
  height: '20px',
})
const DatePickerInput = styled(InputBase)({
  width: '80px',
  height: '10px',
  '& > div': {
    width: '100%',
  },
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
  '& > input': {
    textAlign: 'center',
  },
})
const TooltipTitle = styled('span')({
  width: '80px',
})
const DatePickerIcon = styled(EventNote)({
  width: '15px',
  height: '15px',
})

type Props = {
  tooltipTitle: string
  storeKey: string
  data: any
  defs: CellDef
  value: DateVO
}
type State = { isEditing: boolean }
const unset = intl.formatMessage({ id: 'singleSheet.header.unset' })

class DateInput extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
    }
  }
  onChange = newValue => {
    const diff = {}
    diff[this.props.storeKey] = newValue
    store.dispatch(applyDiff({ ...diff }))
    store.dispatch(requireSave())
  }
  setEditing = (value: boolean) => {
    if (!this.props.defs) {
      return
    }
    this.setState({ isEditing: value })
  }
  isEditable = (): boolean => {
    if (!this.props.defs) {
      return false
    }
    return this.state.isEditing
  }

  getDateComponent = () => {
    if (this.state.isEditing) {
      const selected = this.props.value ? this.props.value.toDate() : undefined
      return (
        <DatePicker
          selected={selected}
          dateFormat={'yyyy/MM/dd'}
          onChange={selectedDate => {
            this.onChange(selectedDate)
            this.setEditing(false)
          }}
          open={this.state.isEditing}
          onClickOutside={() => this.setEditing(false)}
          placeholderText={this.props.defs.placeholder || 'YYYY/MM/DD'}
          customInput={<DatePickerInput />}
          popperPlacement="bottom-end"
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.stopPropagation()
              e.currentTarget.blur()
            }
          }}
          fixedHeight={true}
        />
      )
    } else {
      const formatted = this.props.value ? this.props.value.format() : unset
      return (
        <Tooltip message={this.props.tooltipTitle}>
          <TooltipTitle onClick={e => this.setEditing(true)}>
            {formatted}
          </TooltipTitle>
        </Tooltip>
      )
    }
  }
  render() {
    return (
      <RootDiv>
        {this.getDateComponent()}
        <IconButton size={'small'} onClick={e => this.setEditing(true)}>
          <DatePickerIcon />
        </IconButton>
      </RootDiv>
    )
  }
}

export default DateInput
