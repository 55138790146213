import _ from 'lodash'
import numeral from 'numeral'
import api, { APIResponse } from '../../../lib/commons/api'
import DateTimeVO from '../../../vo/DateTimeVO'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'

export enum WorkingHoursType {
  Attendance = 'ATTENDANCE',
  PaidLeave = 'PAID_LEAVE',
  PaidLeaveHalf = 'PAID_LEAVE_HALF',
  Absence = 'ABSENCE',
  Holiday = 'HOLIDAY',
}

export class ActualWorkingHoursSkelton
  implements TreeSource<ActualWorkingHoursSkelton>
{
  uuid: string
  children: ActualWorkingHoursSkelton[]
  workDate: string
  schedule?: ActualWorkingHoursScheduleBody
  actual?: ActualWorkingHoursActualBody
}

export interface ActualWorkingHoursBody {
  workDate: string
  schedule?: ActualWorkingHoursScheduleBody
  actual?: ActualWorkingHoursActualBody
}

export interface ActualWorkingHoursScheduleBody {
  workingHoursType: string
  workHour: number
  legal: boolean
  dateName: string
}

export interface ActualWorkingHoursActualBody {
  uuid: string
  workingHoursType: string
  workStartAt: string
  workEndAt: string
  breakTime: number
  remarks: string
}

export interface ActualWorkingHoursRow extends TreeRow {
  isTotal: boolean
  date?: string
  workingHoursType?: string
  remarks?: string
  schedule?: ActualWorkingHoursRowScheduleBody
  actual?: ActualWorkingHoursRowActualBody
  totalActualHour?: number
  projectHours?: ActualWorkingHoursRowProjectBody[]
}

export interface StandardWorkingInfo {
  standardWorkDays: number
  standardWorkHours: number
  totalWorkDays: number
  totalWorkHours: number
  diffDays: number
  diffHours: number
}

export class ActualWorkingHoursRowScheduleBody {
  workingHoursType?: string
  workHour?: number
  dateName?: string
  legal?: boolean
  constructor(src: ActualWorkingHoursScheduleBody) {
    this.workingHoursType = src.workingHoursType
    this.workHour = src.workHour ?? 0
    this.dateName = src.dateName
    this.legal = src.legal
  }
}

export class ActualWorkingHoursRowActualBody {
  workingHoursType?: string
  actualUuid?: string
  startTime?: string
  endTime?: string
  breakTime?: string
  workedTime?: string
  remarks?: string

  constructor(src: ActualWorkingHoursActualBody) {
    this.workingHoursType = src.workingHoursType
    this.actualUuid = src.uuid
    this.startTime = _.isEmpty(src.workStartAt)
      ? ''
      : formatTimeString(src.workStartAt)
    this.endTime = _.isEmpty(src.workEndAt)
      ? ''
      : formatTimeString(src.workEndAt)
    this.breakTime = restoreBreakTime(src.breakTime)
    this.workedTime = DefaultTime
    this.remarks = src.remarks
  }
}

export class ActualWorkingHoursRowProjectBody {
  projectUuid: string
  actualHour: number = 0
}

export class ActualWorkingHoursSummaryRowProjectBody {
  projectUuid: string
  actualHour: number = 0

  constructor(projectUuid: string) {
    this.projectUuid = projectUuid
  }
}

export class ActualWorkingHoursSummaryRow {
  isTotal: boolean
  breakTime: number
  workHour: number
  totalActualHour: number
  projectHours: ActualWorkingHoursSummaryRowProjectBody[]
  constructor() {
    this.isTotal = true
    this.workHour = 0
    this.totalActualHour = 0
  }
}

export const fetchActualWorkingHours = async (params: {
  userUuid: string
  startDate: string
  endDate: string
}): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/actual_working_hours', params)
}

export interface ActualWorkingHoursInput {
  uuid: string
  userUuid: string
  workDate: string
  workingHoursType: string
  workStartAt: string
  workEndAt: string
  breakTime: number
  remarks: string
}

export interface ActualWorkingHoursBatchInput {
  added: ActualWorkingHoursInput[]
  edited: ActualWorkingHoursInput[]
  deleted: string[]
}

export async function updateBatch(
  request: ActualWorkingHoursBatchInput
): Promise<APIResponse> {
  return api.functional.request(
    'POST',
    '/api/v1/actual_working_hours/batch',
    request
  )
}

export const fetchTargetUsers = async (params: {
  projectUuid: string
  startDate: string
  endDate: string
}): Promise<APIResponse> => {
  return api.functional.request(
    'GET',
    '/api/v1/projects/resource_plans/users',
    params
  )
}

const DefaultTime = '0:00'

const restoreBreakTime = (time: number | undefined): string => {
  if (!time || time < 0) return ''
  return formatTime(time)
}

const formatHourMinute = (time: HourMinutes): string => {
  return `${numeral(time.hour).format('0')}:${numeral(time.minute).format(
    '00'
  )}`
}

export const formatTime = (time: number): string => {
  if (time <= 0) return DefaultTime
  const hour = Math.floor(time / 3600)
  const minute = (time % 3600) / 60
  return formatHourMinute({ hour, minute })
}

export const formatHour = (hour: number): string => {
  if (hour <= 0) return DefaultTime
  const minute = (hour * 60) % 60
  return formatHourMinute({ hour: Math.floor(hour), minute })
}

export const formatTimeString = (time: string): string => {
  const hourMinutes: HourMinutes | undefined = splitHourMinute(time)
  if (!hourMinutes) return DefaultTime
  return formatHourMinute(hourMinutes)
}

export const convertInput2Date = (
  date: string | undefined,
  time: string | undefined
): string => {
  if (!date || !time) return ''
  const baseTime = new DateTimeVO(date).toNumberValue()
  const timespan = splitHourMinute(time)
  if (!timespan) return ''
  const calcTime =
    baseTime + timespan.hour * 3600 * 1000 + timespan.minute * 60 * 1000
  return String(new DateTimeVO(calcTime).serialize())
}

export const convertBreakTime = (data: ActualWorkingHoursRow): number => {
  const breakTime = calculateBreakTime(data)
  return breakTime
}

export const calculateBreakTime = (data: ActualWorkingHoursRow): number => {
  if (!data.actual || !data.actual.breakTime) return 0
  const breakTimeArray = data.actual.breakTime.split(':')
  let breakTimeSpan = 0
  if (breakTimeArray.length > 0) {
    breakTimeSpan = Number(breakTimeArray[0]) * 60 * 60
  }
  if (breakTimeArray.length > 1) {
    breakTimeSpan += Number(breakTimeArray[1]) * 60
  }
  return breakTimeSpan
}

interface HourMinutes {
  hour: number
  minute: number
}

const splitHourMinute = (time: string): HourMinutes | undefined => {
  if (!time) return undefined
  const splits = time.split(':')
  return {
    hour: Number(splits[0]) ?? 0,
    minute: Number(splits[1]) ?? 0,
  }
}

export const formatWorkTime = (data: ActualWorkingHoursRow): string => {
  const time = calculateWorkTime(data)
  if (time === 0) return ''
  return formatTime(time)
}

export const calculateWorkTime = (data: ActualWorkingHoursRow): number => {
  if (
    !data.actual?.startTime ||
    !data.actual?.endTime ||
    isHoliday(data.workingHoursType as WorkingHoursType)
  ) {
    return 0
  }
  const totalWorks = calculateTotalWorkTime(data)
  const breakTime = calculateBreakTime(data)
  return Math.max(0, totalWorks - breakTime)
}

const calculateTotalWorkTime = (data: ActualWorkingHoursRow): number => {
  const baseDate = new DateTimeVO(data.date!).toNumberValue() / 1000
  const startTerm = splitHourMinute(data.actual!.startTime!)
  const endTerm = splitHourMinute(data.actual!.endTime!)
  if (!startTerm || !endTerm) return 0
  const startTime = baseDate + startTerm.hour * 3600 + startTerm.minute * 60
  let endHour = endTerm.hour
  if (endHour < startTerm.hour) {
    endHour += 24
  }
  const endTime = baseDate + endHour * 3600 + endTerm.minute * 60
  return endTime - startTime
}

export const isHoliday = (workingHoursType: string | undefined): boolean => {
  if (!workingHoursType) return false
  return ![
    WorkingHoursType.Attendance,
    WorkingHoursType.PaidLeaveHalf,
    WorkingHoursType.Holiday,
  ].includes(workingHoursType as WorkingHoursType)
}

export const formatDiffTime = (diff: number) => {
  if (diff < 0) {
    const value = formatTime(diff * -1)
    return `-${value}`
  }
  return formatTime(diff)
}
