import { ICellRendererParams } from 'ag-grid-community'
import { FloatingEditableIcon } from '../text'
import * as validator from '../../../../lib/validator'
import Tooltip from '../../../../../../../components/tooltips/Tooltip'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import { getLabel, I18n } from '../../../../../../../../lib/commons/i18nLabel'

interface AutocompleteOption {
  uuid: string
  code?: string
  name?: string
  nameI18n?: I18n
  displayName?: string
  iconUrl?: string
}

export default function AutocompleteCellRenderer(
  params: ICellRendererParams & {
    uiMeta: FunctionProperty
  }
) {
  const { valueFormatted, colDef, context, uiMeta } = params
  const options: AutocompleteOption[] = uiMeta.referenceEntity
    ? context[uiMeta.referenceEntity] ?? []
    : []
  const value: AutocompleteOption | undefined =
    typeof params.value === 'object'
      ? params.value
      : typeof params.value === 'string' && options
      ? options.find(v => v.uuid === params.value)
      : undefined

  if (!colDef || !value) return <></>
  if (colDef.colId === 'ag-Grid-AutoColumn') {
    return (
      <div>
        <p>{getLabel(value.nameI18n) ?? value.displayName ?? value.name}</p>
        <FloatingEditableIcon {...params} />
      </div>
    )
  }

  const name = valueFormatted || value.name || ''
  const message = validate(name, params)
  const iconUrl = value.iconUrl

  return (
    <div
      className="sevend-ag-cell"
      style={{
        fontWeight: 'normal',
        color: !!message ? 'red' : undefined,
        backgroundColor: !!message ? validator.inputErrorColor : undefined,
      }}
    >
      <Tooltip message={message}>
        {iconUrl && (
          <img src={iconUrl} className="sevend-ag-cell-autocomplete-icon" />
        )}
        {name}
        <FloatingEditableIcon {...params} />
      </Tooltip>
    </div>
  )
}

const validate = (value: string, props: ICellRendererParams) => {
  // Flat list rows can be grouped and are read only
  const { cellRendererParams } = props.colDef!
  if (cellRendererParams && cellRendererParams.uiMeta) {
    return validator.validate(
      value,
      props.data,
      cellRendererParams.uiMeta,
      cellRendererParams.viewMeta,
      props.api
    )
  }
  return undefined
}
