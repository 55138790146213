import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  MenuItemDef,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { DateTerm } from '../../../../utils/date'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  InputError,
  addInputError,
} from '../../../containers/BulkSheetView/lib/validation'
import { useKeyBind } from '../../../hooks/useKeyBind'
import { KEY_SAVE } from '../../../model/keyBind'
import { PageArea, PageProps } from '../..'
import ProfitLossItemHeader, {
  ColumnQuickFilterKey,
  RowFilterProps,
} from '../../ProfitLossItems/components/Header'
import { useMasterData } from '../../ProfitLossItems/hooks/masterData'
import {
  StoredPageState,
  usePageState,
} from '../../ProfitLossItems/hooks/pageState'
import {
  isMonthlyValueColumn,
  isRootRow,
  profitLossMembersGridOptions,
  refreshDynamicColumnDef,
} from '../gridOptions'
import { useProfitLossMembersData } from '../hooks/profitLossMembersData'
import {
  ProfitLossMemberRow,
  ProfitLossMemberRowType,
  ProfitLossMemberRowTypeType,
  createNewProfitLossMemberRow,
} from '../ProfitLossMembers'
import { intl } from '../../../../i18n'
import BoolExpression from '../../../../utils/boolExpression'
import validator from '../../../containers/meta/validator'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import store from '../../../../store'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../../store/messages'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import { useExportExcel } from '../hooks/excel'
import { useBulkSheetState } from '../../ProfitLossItems/hooks/bulkSheetState'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import SavedUIStateDialog from '../../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import AddRowCountInputDialog from '../../../containers/BulkSheet/AddRowCountInputDialog'
import {
  recalcAllBudgetAmounts,
  recalcBudgetAmount,
  reloadScheduledWorkMonth,
  setScheduledMonthlyValueToActual,
} from '../gridOptions/contextMenu'
import {
  AccountCategory,
  RowFilterKey,
  RowFilterKeyType,
  RowStyle,
} from '../../ProfitLossItems/profitLossItems'
import { FinancialStatementAccountsType } from '../../LedgerAccounts/ledgerAccounts'
import { AUTO_COLUMN_ID } from '../../../containers/BulkSheet/const'
import {
  collapseAllRows,
  expandAllRows,
} from '../../../containers/BulkSheetView/gridOptions/contextMenu'
import { ProjectDetail } from '../../../../lib/functions/project'
import { refreshPinnedRow } from '../../ProfitLossItems/gridOptions'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'
import { useColumnSetting } from '../../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ProfitLossItemsTreeView from '../../ProfitLossItems/view/ProfitLossItemsTreeView'
import {
  CancelConfirmState,
  CancelConfirmStateType,
} from '../../ProfitLossItems/view'

const ROWFILTER_KEYS_WORKMONTH_AMOUNT: RowFilterKeyType[] = [
  RowFilterKey.WorkMonth,
  RowFilterKey.Amount,
]
const ROWFILTER_KEYS_SCHEDULED_ACTUAL: RowFilterKeyType[] = [
  RowFilterKey.Scheduled,
  RowFilterKey.Actual,
]

interface OwnProps {
  currentProject: ProjectDetail
}

type Props = OwnProps & PageProps

let localRowFilterTypes: ProfitLossMemberRowTypeType[] = []

const ProfitLossMembersView = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [cancelConfirmState, setCancelConfirmState] =
    useState<CancelConfirmStateType>(CancelConfirmState.Close)
  const [openUiState, setOpenUiState] = useState(false)
  const [openAddMultipleRows, setOpenAddMultipleRows] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const {
    data,
    fetchRecords,
    setData,
    save,
    summaryRows,
    fetchScheduledWorkMonth,
  } = useProfitLossMembersData()
  const { fetchMasterRecords, masterTree } = useMasterData()

  const columnSetting = useColumnSetting()
  const pageState = usePageState(props.uuid)
  const prevPageState = useRef<StoredPageState>({
    startDate: '',
    endDate: '',
    accountCategory: undefined,
    rowHeight: ROW_HEIGHT.SMALL,
    rowStyle: RowStyle.Tree,
  })

  const gridOptions = useMemo(() => {
    return profitLossMembersGridOptions()
  }, [])

  const onExportExcel = useExportExcel(gridOptions)

  const bulkSheetState = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.ProfitLossMembers
  )

  const refreshAll = useCallback(
    async (
      startDate: string,
      endDate: string,
      accountCategory: string | undefined
    ) => {
      if (!accountCategory) return
      try {
        setLoading(true)
        await fetchRecords(
          props.currentProject.uuid,
          startDate,
          endDate,
          accountCategory
        )
        refreshDynamicColumnDef({
          api: gridOptions.api,
          dateTerm: {
            startDate,
            endDate,
          },
        })
        bulkSheetState.restoreExpandedRows()
        store.dispatch(doNotRequireSave())
      } finally {
        gridOptions.context = {
          ...gridOptions.context,
          draggableNodeId: undefined,
          errors: new InputError(),
          accountCategory,
        }
        setLoading(false)
      }
    },
    [fetchRecords, gridOptions, props.currentProject.uuid, bulkSheetState]
  )

  const onChangeDateTerm = useCallback(
    (term: DateTerm) => {
      if (!!term.startDate) {
        pageState.setStartDate(term.startDate)
      }
      if (!!term.endDate) {
        pageState.setEndDate(term.endDate)
      }
      const states = gridOptions.columnApi
        ?.getColumns()
        ?.filter(v => {
          if (v.isVisible()) return false
          const parent = v.getParent()
          if (!parent || !isMonthlyValueColumn(parent.getGroupId())) {
            return false
          }
          return dateVoService.isBetween(
            dateVoService.construct(v.getColId()),
            dateVoService.construct(term.startDate),
            dateVoService.construct(term.endDate),
            '[]'
          )
        })
        .map(v => ({
          colId: v.getColId(),
          hide: false,
        }))
      gridOptions.columnApi?.applyColumnState({ state: states })
    },
    [gridOptions.columnApi, pageState]
  )

  const prevData = useRef<ProfitLossMemberRow[]>([])
  prevData.current = data
  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const execReload = useCallback(async () => {
    refreshAll(
      pageState.startDate,
      pageState.endDate,
      pageState.accountCategory
    )
  }, [
    pageState.accountCategory,
    pageState.endDate,
    pageState.startDate,
    refreshAll,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      setCancelConfirmState(CancelConfirmState.OpenByReload)
    } else {
      execReload()
    }
  }, [execReload, hasChanged])

  const validateBeforeSubmit = useCallback(() => {
    if (
      !gridOptions.api ||
      !gridOptions.columnApi ||
      !gridOptions.context?.errors
    ) {
      return
    }
    const errors = new InputError()
    const dupeMsg = intl.formatMessage(
      { id: 'bulksheet.error.duplicate.unique.column.data' },
      {
        fieldName: intl.formatMessage({
          id: 'profitLossItems.duplicate.pattern',
        }),
      }
    )
    const colIds = gridOptions.columnApi.getColumnState().map(col => col.colId)
    const items = data.filter(v => !v.body.type)
    items.forEach(row => {
      const node = gridOptions.api!.getRowNode(row.uuid)
      if (!node) return
      colIds.forEach(colId => {
        const value = gridOptions.api?.getValue(colId, node)
        const colDef = gridOptions.columnApi?.getColumn(colId)?.getColDef()
        if (
          !colDef ||
          !colDef.cellRendererParams ||
          (!!colDef.field && 'body.user' !== colDef.field)
        ) {
          return
        }
        const uiMeta = {
          requiredIf: BoolExpression.of(true),
        }

        const error = validator
          .validate(value, row, uiMeta as FunctionProperty, () => undefined)
          ?.getMessage()
        if (error) {
          addInputError(errors, node.id, error, colDef)
        }
        if (colDef.field === 'body.user') {
          const isDupe = items.some(
            v =>
              (!!row.editedData || row.added) &&
              v.uuid !== row.uuid &&
              v.body?.financialState?.uuid === row.body?.financialState?.uuid &&
              v.body?.generalLedger?.uuid === row.body?.generalLedger?.uuid &&
              v.body?.subsidiary?.uuid === row.body?.subsidiary?.uuid &&
              v.body?.user?.uuid === row.body?.user?.uuid
          )
          if (isDupe) {
            addInputError(errors, node.id, dupeMsg, colDef)
          }
        }
      })
    })
    gridOptions.context = {
      ...gridOptions.context,
      errors,
    }
  }, [data, gridOptions])

  const onSubmit = useCallback(async () => {
    if (!pageState.accountCategory) return
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()
      validateBeforeSubmit()
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }
      bulkSheetState.base.saveImmediately()
      const response = await save(
        props.currentProject.uuid,
        pageState.startDate,
        pageState.endDate,
        pageState.accountCategory
      )
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(props.uuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await execReload()
      }
    } finally {
      setLoading(false)
    }
  }, [
    pageState.accountCategory,
    pageState.startDate,
    pageState.endDate,
    gridOptions.api,
    gridOptions.context.errors,
    validateBeforeSubmit,
    bulkSheetState.base,
    save,
    props.currentProject.uuid,
    props.uuid,
    execReload,
  ])

  const isConditionChanged = useCallback(() => {
    if (
      !prevPageState.current ||
      prevPageState.current.accountCategory !== pageState.accountCategory ||
      prevPageState.current.startDate !== pageState.startDate ||
      prevPageState.current.endDate !== pageState.endDate
    ) {
      prevPageState.current = {
        accountCategory: pageState.accountCategory,
        startDate: pageState.startDate,
        endDate: pageState.endDate,
        rowHeight: pageState.rowHeight,
        rowStyle: pageState.rowStyle,
      }
      return true
    }
    return false
  }, [
    pageState.accountCategory,
    pageState.endDate,
    pageState.rowHeight,
    pageState.rowStyle,
    pageState.startDate,
  ])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = bulkSheetState.onFilterChanged(e)
      setFilteredColumns(filter)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      const sorted = bulkSheetState.onSortChanged(e)
      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedState[state.colId] = state
          }
        })
      const sortedList: SortedColumnState[] = sorted.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedState[col.colId]?.sort : null,
        }
      })
      setSortColumnsState(sortedList)
    },
    [bulkSheetState, gridOptions.columnApi]
  )

  const onFirstDataRendered = useCallback(
    e => {
      bulkSheetState.onFirstDataRendered(e)
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else {
        setOpenUiState(true)
      }
    },
    [gridOptions.api, gridOptions.columnApi]
  )

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    gridOptions.api?.setFilterModel([])
  }, [gridOptions.api])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions.columnApi]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions.columnApi])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions.columnApi]
  )

  const closeUiStateDialog = useCallback(() => {
    setOpenUiState(false)
  }, [])

  const callbackSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      if (columnState) {
        bulkSheetState.base.saveColumnState(columnState)
        gridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        bulkSheetState.base.saveFilterState(filterState)
        gridOptions.api?.setFilterModel(filterState)
      }
      closeUiStateDialog()
    },
    [
      bulkSheetState.base,
      closeUiStateDialog,
      gridOptions.api,
      gridOptions.columnApi,
    ]
  )

  const pendingAccountCategory = useRef<string | undefined>()
  const setAccountCategory = useCallback(
    (value: string | undefined) => {
      pageState.setAccountCategory(value)
      gridOptions.context = {
        ...gridOptions.context,
        accountCategory: value,
      }
    },
    [pageState, gridOptions]
  )

  const onChangeAccountCategory = useCallback(
    (value: string | undefined) => {
      if (hasChanged()) {
        pendingAccountCategory.current = value
        setCancelConfirmState(CancelConfirmState.OpenByAccountCategory)
      } else {
        pendingAccountCategory.current = undefined
        setAccountCategory(value)
      }
    },
    [hasChanged, setAccountCategory]
  )

  const onCancelConfirm = useCallback(
    (state: CancelConfirmStateType) => {
      if (CancelConfirmState.OpenByReload === state) {
        execReload()
      } else if (CancelConfirmState.OpenByAccountCategory === state) {
        setAccountCategory(pendingAccountCategory.current)
        pendingAccountCategory.current = undefined
      }
    },
    [execReload, setAccountCategory]
  )

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const commonMenuItems: (string | MenuItemDef)[] = [
        setScheduledMonthlyValueToActual(params),
        'separator',
        expandAllRows(params),
        collapseAllRows(params),
      ]

      const menuItems: (string | MenuItemDef)[] =
        pageState.accountCategory !== AccountCategory.OperatingRevenue
          ? [
              reloadScheduledWorkMonth(
                params,
                (targetRow: ProfitLossMemberRow) => {
                  fetchScheduledWorkMonth(
                    props.currentProject.uuid,
                    pageState.startDate,
                    pageState.endDate,
                    targetRow
                  )
                }
              ),
              recalcBudgetAmount(
                params,
                data,
                { startDate: pageState.startDate, endDate: pageState.endDate },
                setData
              ),
              recalcAllBudgetAmounts(
                params,
                data,
                { startDate: pageState.startDate, endDate: pageState.endDate },
                setData
              ),
              ...commonMenuItems,
            ]
          : commonMenuItems

      return menuItems
    },
    [
      pageState.accountCategory,
      pageState.startDate,
      pageState.endDate,
      data,
      setData,
      fetchScheduledWorkMonth,
      props.currentProject.uuid,
    ]
  )

  const closeAddMultipleRows = useCallback(() => {
    setOpenAddMultipleRows(false)
  }, [])

  const submitAddMultipleRows = useCallback(
    (count: number | undefined) => {
      try {
        if (!count) return
        const financialState =
          AccountCategory.OperatingRevenue === pageState.accountCategory
            ? masterTree.find(
                v =>
                  v.financialStatementAccountsType ===
                  FinancialStatementAccountsType.Sales
              )
            : undefined
        const result: ProfitLossMemberRow[] = []
        for (let i = 0; i < count; i++) {
          const addItems = createNewProfitLossMemberRow(financialState)
          result.push(...addItems)
        }
        setData([...data, ...result])
      } finally {
        closeAddMultipleRows()
      }
    },
    [pageState.accountCategory, closeAddMultipleRows, data, masterTree, setData]
  )

  const updateLocalRowFilterTypes = useCallback(
    (types: RowFilterKeyType[] | undefined) => {
      localRowFilterTypes.splice(0)
      if (!types) return

      if (
        types.includes(RowFilterKey.Scheduled) &&
        types.includes(RowFilterKey.Amount)
      ) {
        localRowFilterTypes.push(ProfitLossMemberRowType.AmountBudget)
      }
      if (
        types.includes(RowFilterKey.Scheduled) &&
        types.includes(RowFilterKey.WorkMonth)
      ) {
        localRowFilterTypes.push(ProfitLossMemberRowType.WorkMonthSchedule)
      }
      if (
        types.includes(RowFilterKey.Actual) &&
        types.includes(RowFilterKey.Amount)
      ) {
        localRowFilterTypes.push(ProfitLossMemberRowType.AmountResult)
      }
      if (
        types.includes(RowFilterKey.Actual) &&
        types.includes(RowFilterKey.WorkMonth)
      ) {
        localRowFilterTypes.push(ProfitLossMemberRowType.WorkMonthActual)
      }
      gridOptions.context = {
        ...gridOptions.context,
        rowFilterTypes: localRowFilterTypes,
      }
    },
    [gridOptions]
  )

  const onChangeRowFilter = useCallback(
    (values: RowFilterKeyType[], options: RowFilterKeyType[]) => {
      const filters: RowFilterKeyType[] = pageState.rowFilterTypes
        ? _.cloneDeep(pageState.rowFilterTypes)
        : []

      const newFilters = filters.filter(t => !options.includes(t))
      newFilters.push(...values)

      if (pageState.setRowFilterTypes) {
        pageState.setRowFilterTypes(newFilters)
      }
      updateLocalRowFilterTypes(newFilters)
      gridOptions.api?.onFilterChanged()
      gridOptions.api?.refreshCells({
        columns: [gridOptions.columnApi?.getColumn(AUTO_COLUMN_ID)!],
        force: true,
      })
    },
    [gridOptions, pageState, updateLocalRowFilterTypes]
  )

  const onChangeRowFilterWorkMonthAmount = useCallback(
    (values: RowFilterKeyType[]) => {
      onChangeRowFilter(values, ROWFILTER_KEYS_WORKMONTH_AMOUNT)
    },
    [onChangeRowFilter]
  )

  const onChangeRowFilterScheduledActual = useCallback(
    (values: RowFilterKeyType[]) => {
      onChangeRowFilter(values, ROWFILTER_KEYS_SCHEDULED_ACTUAL)
    },
    [onChangeRowFilter]
  )

  const rowFilterProps: RowFilterProps[] = useMemo(() => {
    return [
      {
        enableKeys: ROWFILTER_KEYS_WORKMONTH_AMOUNT,
        values:
          pageState.rowFilterTypes?.filter(t =>
            ROWFILTER_KEYS_WORKMONTH_AMOUNT.includes(t)
          ) || [],
        onChange: onChangeRowFilterWorkMonthAmount,
      },
      {
        enableKeys: ROWFILTER_KEYS_SCHEDULED_ACTUAL,
        values:
          pageState.rowFilterTypes?.filter(t =>
            ROWFILTER_KEYS_SCHEDULED_ACTUAL.includes(t)
          ) || [],
        onChange: onChangeRowFilterScheduledActual,
      },
    ]
  }, [
    pageState.rowFilterTypes,
    onChangeRowFilterWorkMonthAmount,
    onChangeRowFilterScheduledActual,
  ])

  const isExternalFilterPresent = useCallback(params => true, [])

  const rowFilterPass = useCallback((node: RowNode<ProfitLossMemberRow>) => {
    if (isRootRow(node.data)) return true
    return (
      !!node.data?.body.type &&
      localRowFilterTypes.includes(
        node.data.body.type as ProfitLossMemberRowTypeType
      )
    )
  }, [])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  useEffect(() => {
    if (pageState.initialized && isConditionChanged()) {
      refreshAll(
        pageState.startDate,
        pageState.endDate,
        pageState.accountCategory
      )
    }
  }, [
    isConditionChanged,
    pageState.accountCategory,
    pageState.initialized,
    pageState.startDate,
    pageState.endDate,
    refreshAll,
  ])

  useEffect(() => {
    fetchMasterRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterTree && masterTree.length > 0) {
      gridOptions.context = {
        ...gridOptions.context,
        masters: masterTree,
      }
    }
  }, [gridOptions, masterTree])

  useEffect(() => {
    updateLocalRowFilterTypes(pageState.rowFilterTypes)
  }, [updateLocalRowFilterTypes, pageState])

  useEffect(() => {
    refreshPinnedRow(gridOptions.api)
  }, [gridOptions, data])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    [data, save, pageState]
  )

  return (
    <PageArea>
      <ProfitLossItemHeader
        loading={loading}
        dateTerm={{
          startDate: pageState.startDate,
          endDate: pageState.endDate,
        }}
        onSearch={onChangeDateTerm}
        onSubmit={onSubmit}
        onReload={onReload}
        rowHeight={pageState.rowHeight}
        onChangeRowHeight={pageState.setRowHeight}
        onClickExport={onExportExcel}
        accountCategory={pageState.accountCategory}
        onChangeAccountCategory={onChangeAccountCategory}
        rowFilters={rowFilterProps}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        sortColumnState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        columnSettingOpen={columnSetting.isOpen}
        onClickColumnSettingButton={columnSetting.toggle}
      />
      <ProfitLossItemsTreeView
        loading={loading}
        gridOptions={gridOptions}
        data={data}
        setData={setData}
        accountCategory={pageState.accountCategory}
        masterTree={masterTree}
        rowHeight={pageState.rowHeight}
        cancelConfirmState={cancelConfirmState}
        setCancelConfirmState={setCancelConfirmState}
        onCancelConfirm={onCancelConfirm}
        onColumnMoved={bulkSheetState.rememberColumnState}
        onColumnResized={bulkSheetState.rememberColumnState}
        onColumnVisible={bulkSheetState.rememberColumnState}
        onGridReady={bulkSheetState.onGridReady}
        onRowGroupOpened={bulkSheetState.onRowGroupOpened}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        summaryRows={summaryRows}
        createRow={createNewProfitLossMemberRow}
        getContextMenuItems={contextMenu}
        isExternalFilterPresent={isExternalFilterPresent}
        doesExternalFilterPass={rowFilterPass}
        applicationFunctionUuid={props.uuid}
        columnSetting={columnSetting}
        columnState={bulkSheetState.base.columnState}
        openSavedUiStateDialog={() => setOpenUiState(true)}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
      />
      <SavedUIStateDialog
        applicationFunctionUuid={props.uuid}
        open={openUiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.base.columnState,
          filterState: bulkSheetState.base.filterState,
        }}
        onSelect={callbackSelectUiState}
        onClose={closeUiStateDialog}
      />
      <AddRowCountInputDialog
        open={openAddMultipleRows}
        submitHandler={submitAddMultipleRows}
        closeHandler={closeAddMultipleRows}
      />
    </PageArea>
  )
}

export default ProfitLossMembersView
