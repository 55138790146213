import { styled } from '@mui/system'
import { useCallback } from 'react'
import MuiLinkIcon from '@mui/icons-material/LinkRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { IconButton } from './IconButton'
import {
  CheckResult,
  FunctionLayer,
  getUrlParamsFromLayer,
} from '../../../store/functionLayer'
import { getPathByExternalId } from '../../pages'
import store from '../../../store'
import { addScreenMessage, MessageLevel } from '../../../store/messages'

type IconSize = 'xs' | 's' | 'm'
const Icon = styled(MuiLinkIcon)<{ iconSize?: IconSize }>(({ iconSize }) => {
  switch (iconSize) {
    case 'xs':
      return {
        transform: 'rotate(135deg)',
        width: '12px',
        height: '12px',
      }
    case 's':
      return {
        transform: 'rotate(135deg)',
        width: '16px',
        height: '16px',
      }
    case 'm':
      return {
        transform: 'rotate(135deg)',
        width: '20px',
        height: '20px',
      }
  }
})
export const LinkIcon = injectIntl(
  (
    props: {
      layer: FunctionLayer
      size?: 'xs' | 's' | 'm'
    } & WrappedComponentProps
  ) => {
    const { intl, size, layer } = props
    const onClick = useCallback(async () => {
      const result: CheckResult | undefined = layer.canCopyUrlLink
        ? await layer.canCopyUrlLink()
        : undefined
      if (result && !result.ok) {
        if (result.message) {
          store.dispatch(
            addScreenMessage(layer.externalId, {
              type: MessageLevel.WARN,
              title: result.message,
            })
          )
        }
        return
      }
      const path = getPathByExternalId(layer.externalId)
      const urlParams = getUrlParamsFromLayer(layer)
      const url = urlParams
        ? `${window.location.origin}${path}/${layer.code}?${urlParams}`
        : `${window.location.origin}${path}/${layer.code}`
      navigator.clipboard.writeText(url)
    }, [])
    return (
      <IconButton
        onClick={onClick}
        size={'small'}
        title={intl.formatMessage({
          id: 'copyUrl',
        })}
      >
        <Icon iconSize={size || 's'} color="action" />
      </IconButton>
    )
  }
)
