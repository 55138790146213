import { intl } from '../../../../i18n'
import ColumnFilterButton from '../../../components/buttons/ColumnFilterButtonGroup/ColumnFilterButton'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../components/buttons/ToolbarFilterButtonGroup'
import { HeaderGroup } from '../../../components/headers/HeaderBar/PageHeader'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ColumnQuickFilterKey } from '../TaskActualWorkOptions'

interface OwnProps {
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

type Props = OwnProps & ToolbarFilterGroupProps

const TaskActualWorkToolbar = ({
  onChangeColumnFilter,
  filteredColumns,
  sortedColumns,
  onDeletedFilterColumn,
}: Props) => {
  return (
    <Toolbar>
      <HeaderGroup>
        <ToolbarFilterButtonGroup
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={onDeletedFilterColumn}
        />
      </HeaderGroup>
      <HeaderGroup>
        <ColumnFilterButton
          title={intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
          })}
          onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)}
        />
        <ColumnFilterButton
          title={intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
          })}
          onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.RESTORE)}
        />
      </HeaderGroup>
    </Toolbar>
  )
}

export default TaskActualWorkToolbar
