import _ from 'lodash'
import {
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import objects from '../../../../utils/objects'
import VOBase from '../../../../vo/base'
import factory from '../../../../vo/factory'
import {
  deserializeExtensionValue,
  serializeExtensionValue,
} from '../entityExtension'
import ViewMeta from '../ViewMeta'
import { isSupported } from '../../../../vo'
import EntitySearchVO from '../../../../vo/EntitySearchVO'

export default class DataConverter {
  deserialize = (src: any, viewMeta: ViewMeta): any => {
    let data: any = {}
    viewMeta
      .removeParentProperty(viewMeta.functionMeta.detail.properties)
      .forEach(prop => {
        const field = viewMeta.makeDataPropertyName(prop)
        let value
        let setValue: (key, value) => void
        if (prop.entityExtensionUuid) {
          const extensions = src.extensions
          if (extensions) {
            const extension = extensions.find(
              e => e.uuid === prop.entityExtensionUuid
            )
            if (extension) {
              let extensionValue = extension.value
              if (extensionValue instanceof EntitySearchVO) {
                extensionValue = extensionValue.getJson()
              } else if (extensionValue instanceof VOBase) {
                extensionValue = extensionValue.getValue()
              }
              value = deserializeExtensionValue(extensionValue, prop)
            }
          }
          setValue = (key, value) => {
            if (!data.extensions) {
              data.extensions = []
            }
            const extension = {
              uuid: prop.entityExtensionUuid,
              value: value,
            }
            data.extensions.push(extension)
          }
        } else {
          value = objects.getValue(src, field)
          setValue = (key, value) => objects.setValue(data, key, value)
        }
        const toVoCondition = (value: any, prop: FunctionProperty): boolean => {
          if (!isSupported(prop.propertyType)) {
            return false
          }
          if (prop.propertyType === PropertyType.Number) {
            return !!value || value === 0
          }
          if (
            prop.propertyType === PropertyType.Checkbox ||
            prop.propertyType === PropertyType.Switch
          ) {
            return !!value || value === false
          }
          return !_.isNil(value)
        }
        const toVo = toVoCondition(value, prop)
        if (toVo) {
          setValue(field, factory.create(value, prop))
        } else {
          // Delete after implementing all vo
          setValue(field, value)
        }
      })
    return data
  }

  serialize = (src: any, viewMeta: ViewMeta): any => {
    let data: any = {}
    viewMeta
      .removeParentProperty(viewMeta.functionMeta.detail.properties)
      .forEach(prop => {
        const field = viewMeta.makeDataPropertyName(prop)
        let value
        let setValue: (key, value) => void
        if (prop.entityExtensionUuid) {
          const extensions = src.extensions
          if (extensions) {
            const extension = extensions.find(
              e => e.uuid === prop.entityExtensionUuid
            )
            if (extension) {
              value = extension.value
            }
          }
          setValue = (key, value) => {
            if (!data.extensions) {
              data.extensions = []
            }
            const extension = {
              uuid: prop.entityExtensionUuid,
              value: serializeExtensionValue(value, prop),
            }
            data.extensions.push(extension)
          }
        } else {
          value = objects.getValue(src, field)
          setValue = (key, value) => {
            let k = key
            if (prop.propertyType === PropertyType.EntitySearch) {
              k = `${key}Uuid`
            }
            objects.setValue(data, k, value)
          }
        }
        if (value instanceof VOBase) {
          setValue(field, value.serialize())
        } else {
          // TODO: refactor after adding VO for type in each tabs.
          if (prop.propertyType === PropertyType.File) {
            value =
              value && Array.isArray(value)
                ? value.map(v => ({
                    ...v,
                    updatedBy: v.updatedBy?.uuid,
                  }))
                : undefined
            setValue(field, value)
            return
          }
          setValue(field, value)
        }
      })
    return data
  }

  getDelta = ({
    src,
    viewMeta,
    initialData,
  }: {
    src: any
    viewMeta: ViewMeta
    initialData: any
  }): any => {
    let data: any = {}
    const setValue = ({
      prop,
      field,
      oldValue,
      newValue,
    }: {
      prop: FunctionProperty
      field: string
      oldValue?: any
      newValue?: any
    }) => {
      if (prop.entityExtensionUuid) {
        if (!data.extensions) {
          data.extensions = []
        }
        const extension = {
          uuid: prop.entityExtensionUuid,
          oldValue: serializeExtensionValue(oldValue, prop),
          newValue: serializeExtensionValue(newValue, prop),
        }
        data.extensions.push(extension)
        return
      }

      const key =
        prop.propertyType === PropertyType.EntitySearch ? `${field}Uuid` : field
      objects.setValue(data, key, { oldValue, newValue })
    }
    const getValue = ({
      prop,
      field,
    }: {
      prop: FunctionProperty
      field: string
    }): {
      oldValue: any
      newValue: any
    } => {
      if (prop.entityExtensionUuid) {
        const oldExtensions = initialData.extensions
        const newExtensions = src.extensions
        return {
          oldValue: oldExtensions?.find(
            e => e.uuid === prop.entityExtensionUuid
          )?.value,
          newValue: newExtensions?.find(
            e => e.uuid === prop.entityExtensionUuid
          )?.value,
        }
      }
      return {
        oldValue: objects.getValue(initialData, field),
        newValue: objects.getValue(src, field),
      }
    }
    viewMeta
      .removeParentProperty(viewMeta.functionMeta.detail.properties)
      .forEach(prop => {
        const field = viewMeta.makeDataPropertyName(prop)
        const { oldValue, newValue } = getValue({ prop, field })
        if (
          (oldValue instanceof VOBase &&
            newValue instanceof VOBase &&
            oldValue.isEqual(newValue)) ||
          oldValue === newValue
        ) {
          // Do nothing if value is not changed.
          return
        }
        setValue({
          prop,
          field,
          oldValue:
            oldValue instanceof VOBase ? oldValue.serialize() : oldValue,
          newValue:
            newValue instanceof VOBase ? newValue.serialize() : newValue,
        })
      })
    return data
  }

  structInitialData = (viewMeta: ViewMeta): any => {
    let data: any = {}
    viewMeta
      .removeParentProperty(viewMeta.functionMeta.detail.properties)
      .forEach(prop => {
        const value = prop.defaultValue
        if (!value) {
          return
        }
        const field = viewMeta.makeDataPropertyName(prop)
        if (isSupported(prop.propertyType)) {
          objects.setValue(data, field, factory.create(value, prop))
        } else {
          objects.setValue(data, field, value)
        }
      })
    return data
  }
}
