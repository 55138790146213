import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DateBucket,
  DateBucketType,
} from '../../../components/charts/model/timeSeries'
import { ChartEventListener } from '../../../components/charts'
import { WeekDay } from '../../../../domain/value-object/DayOfWeek'
import { calculateCurrentDateBucket } from '../../../components/charts/utils/timeSeries'

export const useTooltip = (
  dateBuckets: DateBucket[],
  xScaleInverse: (v: number) => number,
  getBucketRepresentative: (v: DateBucket) => Date,
  dateBucketType: DateBucketType,
  startDayOfWeek: WeekDay
): {
  hoveredAnchor: { x: Date; y: number; anchorEl: DOMRect } | undefined
  selected: DateBucket
  onHover: ChartEventListener
  onClick: ChartEventListener
  onClose: () => void
} => {
  const defaultDateBucket = useMemo(
    () => calculateCurrentDateBucket(dateBucketType, startDayOfWeek),
    [dateBucketType, startDayOfWeek]
  )
  // Hovered Point
  const [hovered, setHovered] = useState<
    { x: number; y: number; boundingClientRect: DOMRect } | undefined
  >(undefined)
  const calcNearestBucket = useCallback(
    (x: number) => {
      const xValue = xScaleInverse(x)
      let nearestIndex = 0
      let minDist = Math.abs(
        getBucketRepresentative(dateBuckets[0]).valueOf() - xValue
      )
      for (let i = 1; i < dateBuckets.length; i++) {
        const dist = Math.abs(
          getBucketRepresentative(dateBuckets[i]).valueOf() - xValue
        )
        if (dist < minDist) {
          minDist = dist
          nearestIndex = i
        }
      }
      return dateBuckets[nearestIndex]
    },
    [dateBuckets, getBucketRepresentative, xScaleInverse]
  )
  const [hoveredAnchor, setHoveredAnchor] = useState<
    { x: Date; y: number; anchorEl: DOMRect } | undefined
  >()
  useEffect(() => {
    if (!hovered) {
      setHoveredAnchor(undefined)
      return
    }
    const nearestBucket = calcNearestBucket(hovered.x)
    setHoveredAnchor({
      x: getBucketRepresentative(nearestBucket),
      y: hovered.y,
      anchorEl: hovered.boundingClientRect,
    })
  }, [calcNearestBucket, getBucketRepresentative, hovered])
  const onHover = useCallback(
    (
      point: { x: number; y: number } | undefined,
      boundingClientRect: DOMRect | undefined
    ) => {
      if (!point || !boundingClientRect) {
        setHovered(undefined)
        return
      }
      setHovered({ ...point, boundingClientRect })
    },
    []
  )

  // Handle Click
  const [clickedPoint, setClickedPoint] = useState<
    { x: number; boundingClientRect: DOMRect } | undefined
  >(undefined)
  const [selected, setSelected] = useState<DateBucket>(defaultDateBucket)
  useEffect(() => {
    if (!clickedPoint) {
      setSelected(defaultDateBucket)
      return
    }
    const nearestBucket = calcNearestBucket(clickedPoint.x)
    setSelected(nearestBucket)
  }, [calcNearestBucket, clickedPoint, defaultDateBucket])

  const onClick = useCallback(
    (
      point: { x: number; y: number } | undefined,
      boundingClientRect: DOMRect | undefined
    ) => {
      if (!point || !boundingClientRect) {
        setClickedPoint(undefined)
        return
      }
      setClickedPoint({ x: point.x, boundingClientRect })
    },
    []
  )
  const onClose = useCallback(() => {
    setClickedPoint(undefined)
  }, [])
  return {
    selected,
    hoveredAnchor,
    onHover,
    onClick,
    onClose,
  }
}
