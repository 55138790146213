import {
  CellClassParams,
  CellClickedEvent,
  EditableCallbackParams,
  ICellRendererParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  AggregateField,
  WbsItemStatus,
} from '../../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../../i18n'
import {
  CustomEnumCombinationDirection,
  CustomEnumValue,
} from '../../../../../lib/commons/appFunction'
import { filterValuesByCombination } from '../../../../../lib/commons/customEnum'
import { getLabel } from '../../../../../lib/commons/i18nLabel'
import { ProjectMemberProps } from '../../../../../lib/functions/projectMember'
import { TicketListBasic } from '../../../../../lib/functions/ticketList'
import { UserBasic } from '../../../../../lib/functions/user'
import {
  getWbsItemStatusColorCode,
  getWbsItemStatusLabel,
} from '../../../../../lib/functions/wbsItem'
import store from '../../../../../store'
import { requireSave } from '../../../../../store/requiredSaveData'
import { TextColor } from '../../../../../styles/commonStyles'
import { AttachmentSummary } from '../../../../../utils/attachment'
import { toNumber } from '../../../../../utils/number'
import objects from '../../../../../utils/objects'
import DateVO from '../../../../../vo/DateVO'
import {
  CheckBoxCellEditor,
  CustomEnumCellEditor,
  EntitySearchCellEditor,
} from '../../../../containers/BulkSheetView/components/cellEditor'
import {
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  ClientSideTextFilter,
  CustomEnumFilter,
  ServerSideUuidFilter,
} from '../../../../containers/BulkSheetView/components/filter'
import {
  AttachmentCellRenderer,
  CheckBoxCellRenderer,
  CustomEnumCellRenderer,
  DefaultCellRenderer,
  EntitySearchCellRenderer,
  ProjectPlanStatusCellRenderer,
  TicketTypeCellRenderer,
} from '../../../../containers/BulkSheetView/components/cellRenderer'
import { TicketActionCellRenderer } from '../../../../containers/BulkSheetView/components/cellRenderer/TicketActionCellRenderer'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import { ColumnType } from '../../../../containers/commons/AgGrid'
import IconCellRenderer from '../../../../containers/commons/AgGrid/components/cell/common/iconCell'
import attachmentCellFilter from '../../../../containers/commons/AgGrid/components/cell/custom/attachment/attachmentCellFilter'
import { DetailCellFilter } from '../../../../containers/commons/AgGrid/components/cell/custom/detail'
import MultiLineTextCell from '../../../../containers/commons/AgGrid/components/cell/custom/multiLineText'
import { SequenceNoCellRenderer } from '../../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import StatusCellRenderer from '../../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { WorkLoadUnitState } from '../../../../hooks/useWorkloadUnit'
import { ProgressDetail } from '../../../ProjectPlanNew/gridOptions/cumulation'
import { NewWbsItemRow } from '../../../ProjectPlanNew/projectPlanNew'
import { TicketRow } from '../../tickets'
import {
  useAccountable,
  useActualEndDate,
  useActualStartDate,
  useAssignee,
  useCode,
  useDescription,
  useDisplayName,
  useEstimatedStoryPoint,
  useEstimatedWorkLoadTask,
  useLatestComment,
  useResponsible,
  useScheduledEndDate,
  useScheduledStartDate,
  useSprint,
  useTags,
  useTeam,
  useWatchers,
} from '../../../../page-properties/bulksheet-properties/wbsItem'
import { useCallback, useMemo } from 'react'

export interface PropsForGetColumnDefs {
  projectUuid: string
  onClickedStatusCell?: (
    target: EventTarget | undefined,
    row: TicketRow | undefined
  ) => void
  onClickedActualHourCell?: (row: TicketRow | undefined) => void
  addRow: (row: TicketRow) => void
  reloadSingleRow: ((uuid: string) => void) | undefined
  submitSingleRow: ((uuid: string) => Promise<boolean | undefined>) | undefined
  context:
    | {
        [key: string]: CustomEnumValue[]
      }
    | undefined
}

export const TAG_EXTERNAL_ID: string = 'ticket.list.wbsItem.tags'
const FIELD_WBSITEM_SCHEDULED_START = 'wbsItem.scheduledDate.startDate'
const FIELD_WBSITEM_SCHEDULED_END = 'wbsItem.scheduledDate.endDate'
export const FIELD_WBSITEM_ACTUALDATE_START = 'wbsItem.actualDate.startDate'
export const FIELD_WBSITEM_ACTUALDATE_END = 'wbsItem.actualDate.endDate'

const WORKLOAD_VALUE_DIGITS: number = 2
const PROGRESS_WORKLOAD_VALUE_DIGITS: number = 1
const PROGRESS_COUNT_VALUE_DIGITS: number = 0

const WBS_ITEM_STATUS_ORDER: WbsItemStatus[] = [
  WbsItemStatus.TODO,
  WbsItemStatus.DOING,
  WbsItemStatus.REVIEW,
  WbsItemStatus.DONE,
  WbsItemStatus.DISCARD,
]

/**
 * util functions
 */

const statusCombinedValuePath = (combinedEnumCode: string) => {
  switch (combinedEnumCode) {
    case 'WBS_TYPE':
      return 'wbsItem.type'
    case 'TICKET_TYPE':
      return 'wbsItem.ticketType'
    case 'WBS_STATUS':
      return 'wbsItem.status'
    case 'WBS_SUBSTATUS':
      return 'wbsItem.substatus'
  }
  return undefined
}

const customEnumValueSetter = (params: ValueSetterParams) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field || params.oldValue === params.newValue) return false
  const { customEnumCode, combinedValuePath } = params.colDef.cellEditorParams
  const options: CustomEnumValue[] = params.context[customEnumCode]

  const customEnumValue = options.find(o => o.value === params.newValue)
  const bidirection = customEnumValue?.combinations?.find(
    v => v.direction === CustomEnumCombinationDirection.BIDIRECTION
  )
  objects.setValue(params.data, field, params.newValue)
  if (bidirection && combinedValuePath) {
    objects.setValue(
      params.data,
      combinedValuePath?.(bidirection.combinedEnumCode),
      bidirection.combinedValues?.[0]?.value
    )
  }
  params.data.edited = true
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
  store.dispatch(requireSave())
  return true
}

// TODO: isStartDelayed and isEndDelayed should be common with ProjectPlanNew
const today = DateVO.now()
const isStartDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledStart = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  const actualStart = actualDate?.startDate
    ? new DateVO(actualDate.startDate)
    : undefined
  return !!(
    scheduledStart &&
    wbsItem.status === WbsItemStatus.TODO &&
    ((!actualStart && today.isAfter(scheduledStart)) ||
      (actualStart && actualStart.isAfter(scheduledStart)))
  )
}

const isEndDelayed = (wbsItem?: NewWbsItemRow): boolean => {
  if (!wbsItem?.status) return false
  const { scheduledDate, actualDate } = wbsItem
  const scheduledEnd = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  const actualEnd = actualDate?.endDate
    ? new DateVO(actualDate.endDate)
    : undefined
  return !!(
    scheduledEnd &&
    ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(wbsItem.status) &&
    ((!actualEnd && today.isAfter(scheduledEnd)) ||
      (actualEnd && actualEnd.isAfter(scheduledEnd)))
  )
}

export const editableExceptSummaryRow = (
  params: EditableCallbackParams<TicketRow>
) => !params.data?.isTotal

const getValueInWorklodUnit = (
  hour?: number,
  unitState?: WorkLoadUnitState,
  digits?: number
) => {
  const rate = unitState?.hoursPerSelectedUnit || 1
  return Number(((hour ?? 0) / rate).toFixed(digits ?? 2))
}

const getProgressValueGetter = (
  getSummaryValue: (detail: ProgressDetail) => number
) => {
  return (params: ValueGetterParams<TicketRow>) => {
    const isCount =
      params.context?.aggregateField === AggregateField.WBS_ITEM_COUNT
    const digits = isCount
      ? PROGRESS_COUNT_VALUE_DIGITS
      : PROGRESS_WORKLOAD_VALUE_DIGITS

    if (!!params.data?.isTotal) {
      if (!params.api) return
      let sum: number = 0
      params.api.forEachNode(node => {
        const row: TicketRow | undefined = node.data
        if (!row?.wbsItem) return
        const value: number = getSummaryValue(
          ProgressDetail.of(row.wbsItem, params.context)
        )
        sum += getValueInWorklodUnit(
          value,
          isCount ? undefined : params.context.workloadUnitState,
          digits
        )
      })
      return sum
    }
    const w = params.data?.wbsItem
    const c = params.data?.cumulation
    if (!w || !c) return
    return getValueInWorklodUnit(
      getSummaryValue(ProgressDetail.of(w, params.context)),
      isCount ? undefined : params.context.workloadUnitState,
      digits
    )
  }
}

const progressValueFormatter = (params: ValueFormatterParams<TicketRow>) => {
  const value: number | undefined = params.value
  if (value === undefined) return ''
  return (
    ' ' +
    value.toFixed(
      params.context?.aggregateField === AggregateField.WBS_ITEM_WORKLOAD
        ? PROGRESS_WORKLOAD_VALUE_DIGITS
        : PROGRESS_COUNT_VALUE_DIGITS
    )
  )
}

const wbsItemStatusComparator = (a: WbsItemStatus, b: WbsItemStatus) =>
  WBS_ITEM_STATUS_ORDER.findIndex(s => s === a) -
  WBS_ITEM_STATUS_ORDER.findIndex(s => s === b)

export const userNameComparator = (
  a: UserBasic | ProjectMemberProps,
  b: UserBasic | ProjectMemberProps
) => (a?.name ?? '').localeCompare(b?.name ?? '')

/**
 * generate columnDefs functions
 */

export const generateCheckboxColumnDef = (
  externalId: string,
  field: string,
  headerNameId: string
) => {
  return {
    externalId,
    field,
    headerName: intl.formatMessage({ id: headerNameId }),
    type: [ColumnType.checkBox],
    editable: editableExceptSummaryRow,
    cellEditor: CheckBoxCellEditor,
    cellRendererSelector: (params: ICellRendererParams) => {
      return !!params.data?.isTotal
        ? { component: DefaultCellRenderer }
        : { component: CheckBoxCellRenderer }
    },
    valueFormatter: (params: ValueFormatterParams) =>
      params.value === undefined ? undefined : params.value.toString(),
    filter: 'clientSideSelectFilter',
    floatingFilter: true,
    keyCreator: (params): string => {
      return params.value ? 'checked' : ''
    },
    filterParams: {
      getValue: option => (option ? 'checked' : ''),
      getLabel: option => (option ? 'checked' : ''),
    },
  }
}

export const generateCustomEnumColumnDef = (
  externalId: string,
  field: string,
  headerNameId: string,
  customEnumCode: string,
  context?: {
    [key: string]: CustomEnumValue[]
  }
): FlagxsColumnDef => {
  return {
    externalId,
    field,
    headerName: intl.formatMessage({ id: headerNameId }),
    width: 90,
    editable: editableExceptSummaryRow,
    type: [ColumnType.customEnum],
    cellEditor: CustomEnumCellEditor,
    cellEditorParams: { customEnumCode },
    cellRenderer: CustomEnumCellRenderer,
    cellRendererParams: { customEnumCode },
    filter: CustomEnumFilter,
    floatingFilter: true,
    filterParams: {
      customEnumCode,
      getValue: option => option.value,
      getLabel: option => getLabel(option.nameI18n),
      sortValues: (options, context) => {
        const customEnums = context[customEnumCode]
        if (!customEnums) return options
        return options.sort((a, b) => {
          return (
            customEnums.findIndex(e => e.value === a.value) -
            customEnums.findIndex(e => e.value === b.value)
          )
        })
      },
    },
    comparator: (a: string, b: string) => {
      if (!context) return 0
      const options = context[customEnumCode]
      if (!options) return 0
      return (
        options.findIndex(o => o.value === a) -
        options.findIndex(o => o.value === b)
      )
    },
  } as FlagxsColumnDef
}

export const generateMultiLineTextColumnDef = (
  externalId: string,
  field: string,
  headerNameId: string
) => {
  return {
    externalId,
    field,
    headerName: intl.formatMessage({ id: headerNameId }),
    editable: editableExceptSummaryRow,
    type: [ColumnType.multiLineText],
    cellEditor: MultiLineTextCell.cellEditor,
    cellRenderer: MultiLineTextCell.cellRenderer,
    floatingFilter: true,
    filter: ClientSideTextFilter,
  }
}

/**
 * Common column defs
 */

export const DEFAULT_COLUMN_GROUP_DEF: FlagxsColumnDef = {
  externalId: ' ',
  headerName: intl.formatMessage({ id: ' ' }),
  children: [
    {
      externalId: 'uuid',
      field: 'uuid',
      hide: true,
      pinned: true,
      lockPosition: 'left',
      suppressColumnsToolPanel: true,
      filter: ServerSideUuidFilter,
    },
    {
      externalId: 'drag',
      field: 'drag',
      headerName: '',
      type: [ColumnType.drag],
      rowDrag: _ => true,
      cellClassRules: {
        'hover-over-can-drop': params => {
          return (
            params.context?.draggableNodeId &&
            params.node?.id === params.context?.draggableNodeId
          )
        },
      },
    },
    {
      externalId: 'rowNumber',
      field: 'rowNumber',
      type: [ColumnType.sequenceNo],
      resizable: false,
      width: 35,
      cellRenderer: SequenceNoCellRenderer,
      cellRendererParams: params => {
        const node: RowNode<TicketRow> = params.node
        return {
          value: !!node.data?.isTotal ? 'Total' : params.node.rowIndex + 1,
        }
      },
    },
  ],
} as FlagxsColumnDef

export const useInformationColumnGroupDef = ({
  onClickedStatusCell,
  addRow,
}: {
  onClickedStatusCell?: (
    target: EventTarget | undefined,
    row: TicketRow | undefined
  ) => void
  addRow: (row: TicketRow) => void
}): FlagxsColumnDef => {
  const getWbsItemProjectUuid = useCallback(
    (row: TicketRow | undefined) => row?.wbsItem?.projectUuid,
    []
  )

  const codeCol = useCode('wbsItem.code')

  const tagsCol = useTags({
    field: 'wbsItem.tags',
    getWbsItemProjectUuid,
  })

  const columnGroupDef = useMemo(
    () =>
      ({
        externalId: 'ticket.list.basic',
        headerName: intl.formatMessage({ id: 'projectPlan.information' }),
        children: [
          {
            externalId: 'ticket.list.wbsItem.code',
            ...codeCol,
            lockPosition: 'left',
          },
          {
            externalId: 'ticket.list.ticketType',
            field: 'wbsItem.ticketType',
            headerName: intl.formatMessage({ id: 'projectPlan.ticketType' }),
            hide: true,
            pinned: true,
            lockPosition: 'left',
            cellRenderer: TicketTypeCellRenderer,
            valueGetter: (params: ValueGetterParams<TicketRow>) =>
              params.data?.wbsItem?.baseWbsItemType,
          },
          {
            externalId: 'ticket.list.wbsItem.status',
            field: 'wbsItem.status',
            headerName: intl.formatMessage({ id: 'projectPlan.status' }),
            pinned: true,
            lockPosition: 'left',
            width: 150,
            editable: editableExceptSummaryRow,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'status',
              combinedValuePath: statusCombinedValuePath,
            },
            valueSetter: (params: ValueSetterParams<TicketRow>) => {
              if (params.oldValue === params.newValue || !params.data.wbsItem) {
                return false
              }
              params.data.wbsItem.status = params.newValue ?? WbsItemStatus.TODO

              // Update substatus
              const { combinedValuePath } = params.colDef.cellEditorParams
              const substatusOptions = filterValuesByCombination(
                params.context['substatus'],
                code =>
                  objects.getValue(params.data, combinedValuePath?.(code)),
                true
              )
              objects.setValue(
                params.data,
                'wbsItem.substatus',
                substatusOptions?.[0]?.value
              )
              params.data.edited = true
              const field = params.colDef.field ?? params.colDef.colId ?? ''
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              store.dispatch(requireSave())
              return true
            },
            cellRendererSelector: (params: ICellRendererParams<TicketRow>) => {
              // ProjectPlanStatusCellRenderer does not support Total, so use StatusCellRenderer.cellRenderer.
              // TODO: Should be commonized
              return !params.data?.isTotal
                ? { component: ProjectPlanStatusCellRenderer }
                : { component: StatusCellRenderer.cellRenderer }
            },
            cellRendererParams: { isAggregate: true, hideChildrenCount: true },
            cellStyle: params => {
              if (!params.data?.wbsItem) {
                return { backgroundColor: '#F7F7F7' }
              }
              return {
                backgroundColor: `${getWbsItemStatusColorCode(
                  params.data?.wbsItem.status!
                )}`,
              }
            },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              getValue: (v?: WbsItemStatus) => v,
              getLabel: (v?: WbsItemStatus) =>
                v ? getWbsItemStatusLabel(v) : v,
              sortValues: (uiMeta, options) => {
                if (!Array.isArray(options)) return options
                return options.sort(wbsItemStatusComparator)
              },
            },
            comparator: wbsItemStatusComparator,
            onCellClicked: (e: CellClickedEvent<TicketRow>) => {
              if (!onClickedStatusCell) return
              onClickedStatusCell(e.event?.target ?? undefined, e.data)
            },
          },
          {
            externalId: 'ticket.list.wbsItem.substatus',
            field: 'wbsItem.substatus',
            headerName: intl.formatMessage({ id: 'projectPlan.substatus' }),
            pinned: true,
            hide: true,
            lockPosition: 'left',
            width: 90,
            editable: editableExceptSummaryRow,
            type: [ColumnType.customEnum],
            cellEditor: CustomEnumCellEditor,
            cellEditorParams: {
              customEnumCode: 'substatus',
              combinedValuePath: statusCombinedValuePath,
            },
            valueSetter: customEnumValueSetter,
            cellRenderer: CustomEnumCellRenderer,
            cellRendererParams: { customEnumCode: 'substatus' },
            filter: ClientSideSelectFilter,
            floatingFilter: true,
            filterParams: {
              valueGetter: ({
                node,
                context,
              }: {
                node: RowNode<TicketRow>
                context: any
              }) => {
                const options = context ? context['substatus'] : []
                const value = node.data?.wbsItem?.substatus
                return options?.find(o => o.value === value)
              },
              getValue: (v?: CustomEnumValue) => v?.value,
              getLabel: (v?: CustomEnumValue) =>
                v?.nameI18n ? getLabel(v.nameI18n) : v?.name,
            },
          },
          {
            externalId: 'ticket.list.action',
            field: 'action',
            headerName: 'Action',
            pinned: true,
            lockPosition: 'left',
            width: 135,
            sortable: false,
            cellRendererSelector: (params: ICellRendererParams<TicketRow>) => {
              return !!params.data?.isTotal
                ? { component: DefaultCellRenderer }
                : {
                    component: TicketActionCellRenderer,
                    params: {
                      getCommentSummary: (data?: TicketRow) =>
                        data?.commentSummary,
                      getWbsItem: (data?: TicketRow) => data?.wbsItem,
                      addRow: (node: RowNode<TicketRow>) => addRow(node.data!),
                      openComment: true,
                    },
                  }
            },
            // DetailCellFilter not support CellRendererSelectorResult.params
            // TODO: Should be support CellRendererSelectorResult.params on DetailCellFilter
            cellRendererParams: {
              openComment: true,
            },
            valueGetter: (params: ValueGetterParams<TicketRow>) =>
              params.data?.wbsItem?.uuid,
            filter: DetailCellFilter,
            floatingFilter: true,
          },
          {
            externalId: 'ticket.list.attachment',
            field: 'deliverableAttachmentSummary',
            headerName: intl.formatMessage({ id: 'projectPlan.attachments' }),
            pinned: true,
            lockPosition: 'left',
            width: 55,
            cellRenderer: AttachmentCellRenderer,
            filter: attachmentCellFilter,
            floatingFilter: true,
            cellRendererParams: {
              getWbsItemUuid: (node: RowNode<TicketRow>) =>
                node?.data?.wbsItem?.uuid,
              getAttachmentSummary: (data: TicketRow) =>
                data?.deliverableAttachmentSummary,
            },
            comparator: (
              a: AttachmentSummary | undefined,
              b: AttachmentSummary | undefined
            ) => {
              if (!a && !b) return 0
              if (!a) return -1
              if (!b) return 1
              if (0 < a.totalAttachmentCount) return 1
              if (0 < b.totalAttachmentCount) return -1
              return 0
            },
          },
          {
            externalId: TAG_EXTERNAL_ID,
            ...tagsCol,
            width: 100,
            editable: editableExceptSummaryRow,
          },
        ],
      } as FlagxsColumnDef),
    [onClickedStatusCell, addRow, codeCol, tagsCol]
  )
  return columnGroupDef
}

export const PATH_COLUMN_GROUP_DEF: FlagxsColumnDef = {
  externalId: 'ticket.list.path',
  headerName: intl.formatMessage({ id: 'tickets.path' }),
  children: [
    {
      externalId: 'ticket.list.ticketList',
      field: 'ticketList',
      headerName: intl.formatMessage({ id: 'tickets.ticket.list' }),
      hide: true,
      pinned: true,
      lockPosition: 'left',
      width: 120,
      editable: editableExceptSummaryRow,
      type: [ColumnType.autocomplete],
      cellRenderer: EntitySearchCellRenderer,
      cellEditor: EntitySearchCellEditor,
      cellEditorParams: {
        entity: 'ticketList',
      },
      suppressKeyboardEvent: params =>
        (!params.editing &&
          ['Delete', 'Backspace'].includes(params.event.key)) ||
        (params.editing && params.event.key === 'Enter'),
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        valueGetter: ({ node, context }) => {
          const val = objects.getValue(node.data, 'ticketList')
          return typeof val === 'string' ? undefined : val // Exclude unselected data
        },
        getValue: (v?: TicketListBasic) => v?.uuid,
        getLabel: (v?: TicketListBasic) => v?.displayName,
      },
      comparator: (a: TicketListBasic, b: TicketListBasic) => {
        return (a?.displayName ?? '').localeCompare(b?.displayName ?? '')
      },
    },
    {
      externalId: 'ticket.list.parentPath',
      field: 'parentPath',
      headerName: intl.formatMessage({ id: 'tickets.path' }),
      width: 120,
      pinned: true,
      lockPosition: 'left',
      filter: ClientSideTextFilter,
      floatingFilter: true,
      cellStyle: {
        direction: 'rtl',
      },
    },
    {
      externalId: 'ticket.list.parentWbsItem',
      field: 'parentWbsItem',
      headerName: intl.formatMessage({ id: 'tickets.parentWbsItem' }),
      width: 100,
      pinned: true,
      lockPosition: 'left',
      filter: ClientSideTextFilter,
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.parentWbsItem?.displayName
      },
    },
  ],
} as FlagxsColumnDef

export const useDisplayNameColumnDef = ({
  reloadSingleRow,
  submitSingleRow,
}: {
  reloadSingleRow: ((uuid: string) => void) | undefined
  submitSingleRow: ((uuid: string) => Promise<boolean | undefined>) | undefined
}): FlagxsColumnDef => {
  const displayNameColDef = useDisplayName({
    field: 'wbsItem.displayName',
    wbsItemCellRendererParams: {
      onCloseWbsItemDetail: (wbsItem: NewWbsItemRow) =>
        wbsItem?.uuid && reloadSingleRow?.(wbsItem.uuid),
      beforeOpenWbsItemDetail: async uuid => {
        await submitSingleRow?.(uuid)
      },
    },
  })
  const columnDef = useMemo(
    () => ({
      externalId: 'ticket.list.wbsItem.displayName',
      ...displayNameColDef,
      field: 'wbsItem.displayName',
      width: 500,
      pinned: true,
      editable: editableExceptSummaryRow,
    }),
    [displayNameColDef]
  )
  return columnDef
}

export const useNameColumnGroupDef = ({
  reloadSingleRow,
  submitSingleRow,
  context,
}: {
  reloadSingleRow: ((uuid: string) => void) | undefined
  submitSingleRow: ((uuid: string) => Promise<boolean | undefined>) | undefined
  context:
    | {
        [key: string]: CustomEnumValue[]
      }
    | undefined
}): FlagxsColumnDef => {
  const displayNameColDef = useDisplayNameColumnDef({
    reloadSingleRow,
    submitSingleRow,
  })

  const columnGroupDef = useMemo(
    () => ({
      externalId: 'ticket.list.name',
      headerName: intl.formatMessage({ id: 'tickets.name' }),
      children: [
        displayNameColDef,
        {
          ...generateCustomEnumColumnDef(
            'ticket.list.wbsItem.priority',
            'wbsItem.priority',
            'projectPlan.priority',
            'priority',
            context
          ),
          width: 65,
        },
        {
          ...generateCustomEnumColumnDef(
            'ticket.list.wbsItem.difficulty',
            'wbsItem.difficulty',
            'projectPlan.difficulty',
            'difficulty',
            context
          ),
          width: 65,
        },
      ],
    }),
    [context, displayNameColDef]
  )
  return columnGroupDef
}

export const useDescriptionColumnDef = (): FlagxsColumnDef => {
  const descriptionCol = useDescription('wbsItem.description')

  const columnDef = useMemo(
    () => ({
      externalId: 'ticket.list.wbsItem.description',
      ...descriptionCol,
      editable: editableExceptSummaryRow,
    }),
    [descriptionCol]
  )
  return columnDef
}

export const useEexplanationColumnGroupDef = (): FlagxsColumnDef => {
  const descriptionColDef = useDescriptionColumnDef()
  const commentColDef = useLatestComment('commentSummary.latestComment')
  const groupDef = useMemo(
    () =>
      ({
        externalId: 'ticket.list.explanation',
        headerName: intl.formatMessage({
          id: 'projectPlan.description.comment',
        }),
        children: [
          descriptionColDef,
          {
            externalId: 'ticket.list.commentSummary.latestComment',
            ...commentColDef,
          },
        ],
      } as FlagxsColumnDef),
    [descriptionColDef, commentColDef]
  )
  return groupDef
}

export const useAssignmentColumnGroupDef = (
  projectUuid: string,
  context: any
): FlagxsColumnDef => {
  const teamCol = useTeam({
    field: 'wbsItem.team',
    gridOptionContext: context,
  })
  const accountableCol = useAccountable({
    field: 'wbsItem.accountable',
  })
  const responsibleCol = useResponsible({
    field: 'wbsItem.responsible',
  })
  const assigneeCol = useAssignee({
    field: 'wbsItem.assignee',
  })
  const watchersCol = useWatchers({
    field: 'wbsItem.watchers',
  })

  const columnGroupDef = useMemo(
    () =>
      ({
        externalId: 'ticket.list.assignment',
        headerName: intl.formatMessage({ id: 'projectPlan.assignment' }),
        children: [
          {
            externalId: 'ticket.list.wbsItem.team',
            ...teamCol,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'ticket.list.wbsItem.accountable',
            ...accountableCol,
            width: 65,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'ticket.list.wbsItem.responsible',
            ...responsibleCol,
            width: 65,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'ticket.list.wbsItem.assignee',
            ...assigneeCol,
            width: 65,
            editable: editableExceptSummaryRow,
          },
          {
            externalId: 'ticket.list.wbsItem.watchers',
            ...watchersCol,
            editable: editableExceptSummaryRow,
          },
        ],
      } as FlagxsColumnDef),
    [
      projectUuid,
      teamCol,
      accountableCol,
      responsibleCol,
      assigneeCol,
      watchersCol,
    ]
  )
  return columnGroupDef
}

export const useStoryPointColumnGroupDef = (): FlagxsColumnDef => {
  const estimatedStoryPointCol = useEstimatedStoryPoint(
    'wbsItem.estimatedStoryPoint'
  )
  const columnGroupDef = useMemo(
    () =>
      ({
        externalId: 'ticket.list.storyPoint',
        headerName: intl.formatMessage({ id: 'projectPlan.storyPoint' }),
        children: [
          {
            externalId: 'ticket.list.wbsItem.estimatedStoryPoint',
            ...estimatedStoryPointCol,
            headerName: intl.formatMessage({
              id: 'projectPlan.estimatedStoryPoint',
            }),
            width: 80,
            editable: editableExceptSummaryRow,
            valueParser: (params: ValueParserParams) =>
              toNumber(params.newValue),
            valueSetter: (params: ValueSetterParams<TicketRow>) => {
              const field = params.colDef.field || params.colDef.colId
              const value = toNumber(params.newValue)
              if (
                !field ||
                (!params.oldValue && !value) ||
                params.oldValue === value
              ) {
                return false
              }
              objects.setValue(params.data, field, value)
              params.data.edited = true
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }

              store.dispatch(requireSave())
              return true
            },
          },
          {
            externalId: 'ticket.list.storyPoint.earnedValue',
            field: 'storyPoint.earnedValue',
            headerName: intl.formatMessage({
              id: 'tickets.storypoint.earnedvalue',
            }),
            hide: true,
            width: 80,
            editable: false,
            cellClass: 'ag-numeric-cell',
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<TicketRow>) =>
              params.data?.wbsItem?.status === WbsItemStatus.DONE
                ? params.data?.wbsItem.estimatedStoryPoint
                : undefined,
            filter: ClientSideNumberFilter,
            floatingFilter: true,
          },
        ],
      } as FlagxsColumnDef),
    [estimatedStoryPointCol]
  )
  return columnGroupDef
}

export const useEstimateColumnGroupDef = (): FlagxsColumnDef => {
  const getWbsItemType = useCallback(
    (row: TicketRow | undefined) => row?.wbsItem?.wbsItemType,
    []
  )
  const estimatedWorkLoadTaskCol = useEstimatedWorkLoadTask({
    field: 'wbsItem.estimatedHour',
    getWbsItemType,
  })
  const columnGroupDef = useMemo(
    () =>
      ({
        externalId: 'ticket.list.estimate',
        headerName: intl.formatMessage({ id: 'projectPlan.estimated' }),
        children: [
          {
            externalId: 'ticket.list.wbsItem.estimatedWorkload',
            ...estimatedWorkLoadTaskCol,
            editable: editableExceptSummaryRow,
            valueSetter: (params: ValueSetterParams<TicketRow>) => {
              if (params.oldValue === params.newValue || !params.data.wbsItem) {
                return false
              }

              const value = toNumber(params.newValue)
              params.data.wbsItem.estimatedHour = value
                ? value *
                  (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
                : 0

              params.data.edited = true
              const field = params.colDef.field ?? params.colDef.colId ?? ''
              if (!params.data.editedData) {
                params.data.editedData = { [field]: params.oldValue }
              } else if (!params.data.editedData.hasOwnProperty(field)) {
                params.data.editedData[field] = params.oldValue
              }
              store.dispatch(requireSave())
              return true
            },
            valueGetter: (params: ValueGetterParams<TicketRow>) => {
              if (params.data?.isTotal) {
                if (!params.api) return
                let sum: number = 0
                params.api.forEachNode(node => {
                  const row: TicketRow | undefined = node.data
                  if (
                    !row?.wbsItem ||
                    row.isTotal ||
                    row.wbsItem.status === WbsItemStatus.DISCARD
                  ) {
                    return
                  }
                  sum += getValueInWorklodUnit(
                    row.wbsItem.estimatedHour,
                    params.context.workloadUnitState,
                    WORKLOAD_VALUE_DIGITS
                  )
                })
                return sum
              }
              const w = params.data?.wbsItem
              if (!w) return undefined
              return getValueInWorklodUnit(
                w.estimatedHour,
                params.context.workloadUnitState,
                WORKLOAD_VALUE_DIGITS
              )
            },
            valueFormatter: (params: ValueFormatterParams<TicketRow>) =>
              Number.isFinite(params.value)
                ? parseFloat(params.value.toString()).toFixed(
                    WORKLOAD_VALUE_DIGITS
                  )
                : '',
          },
        ],
      } as FlagxsColumnDef),
    [estimatedWorkLoadTaskCol]
  )
  return columnGroupDef
}

const cumulationCellColumnDef = {
  width: 80,
  editable: false,
  hide: true,
  cellStyle: () => {
    return {
      color: TextColor.DARK_BLACK,
      justifyContent: 'flex-end',
    }
  },
  filter: ClientSideNumberFilter,
  floatingFilter: true,
}

export const PROGRESS_COLUMN_GROUP_DEF: FlagxsColumnDef = {
  externalId: 'ticket.list.progress',
  headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
  children: [
    {
      externalId: 'ticket.list.plannedValue',
      field: 'progress.scheduledToBe',
      headerName: intl.formatMessage({
        id: 'projectOverview.scheduledToBeCompleted',
      }),
      valueGetter: getProgressValueGetter(
        (detail: ProgressDetail) => detail.scheduledToBeCompleted
      ),
      valueFormatter: progressValueFormatter,
      ...cumulationCellColumnDef,
    },
    {
      externalId: 'ticket.list.wbsItem.earnedValue',
      field: 'progress.completed',
      headerName: intl.formatMessage({
        id: 'projectOverview.completed',
      }),
      valueGetter: getProgressValueGetter(
        (detail: ProgressDetail) => detail.completed
      ),
      valueFormatter: progressValueFormatter,
      ...cumulationCellColumnDef,
    },
    {
      externalId: 'ticket.list.preceding',
      field: 'progress.preceding',
      headerName: intl.formatMessage({
        id: 'projectOverview.preceding',
      }),
      valueGetter: getProgressValueGetter(
        (detail: ProgressDetail) => detail.endPreceding
      ),
      valueFormatter: progressValueFormatter,
      ...cumulationCellColumnDef,
    },
    {
      externalId: 'ticket.list.delayed',
      field: 'progress.delayed',
      headerName: intl.formatMessage({ id: 'projectOverview.delayed' }),
      valueGetter: getProgressValueGetter(
        (detail: ProgressDetail) => detail.endDelayed
      ),
      valueFormatter: progressValueFormatter,
      ...cumulationCellColumnDef,
    },
    {
      externalId: 'ticket.list.remaining',
      field: 'progress.remaining',
      headerName: intl.formatMessage({
        id: 'projectOverview.remaining',
      }),
      valueGetter: getProgressValueGetter(
        (detail: ProgressDetail) => detail.remaining
      ),
      valueFormatter: progressValueFormatter,
      ...cumulationCellColumnDef,
    },
  ],
} as FlagxsColumnDef

export const getProductivityColumnGroupDef = (
  onClickedActualHourCell?: (row: TicketRow | undefined) => void
): FlagxsColumnDef => {
  return {
    externalId: 'ticket.list.productivity',
    headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
    children: [
      {
        externalId: 'ticket.list.wbsItem.actualHour',
        field: 'cumulation.actualHour',
        headerName: intl.formatMessage({
          id: 'projectOverview.actualHour',
        }),
        width: 80,
        valueGetter: (params: ValueGetterParams<TicketRow>) => {
          if (params.data?.isTotal) {
            if (!params.api) return
            let sum: number = 0
            params.api.forEachNode(node => {
              const row: TicketRow | undefined = node.data
              if (
                !row?.cumulation ||
                row.isTotal ||
                row.wbsItem?.status === WbsItemStatus.DISCARD
              ) {
                return
              }
              sum += getValueInWorklodUnit(
                row.cumulation?.actualHour,
                params.context.workloadUnitState,
                WORKLOAD_VALUE_DIGITS
              )
            })
            return sum
          }
          const w = params.data?.wbsItem
          const c = params.data?.cumulation
          if (!w || !c) return
          return getValueInWorklodUnit(
            c.actualHour,
            params.context.workloadUnitState,
            WORKLOAD_VALUE_DIGITS
          )
        },
        valueFormatter: (params: ValueFormatterParams<TicketRow>) =>
          Number.isFinite(params.value)
            ? parseFloat(params.value.toString()).toFixed(WORKLOAD_VALUE_DIGITS)
            : '',
        cellStyle: (params: CellClassParams<TicketRow>) => {
          const numberStyle = { justifyContent: 'flex-end' }
          const w = params.data?.wbsItem
          const c = params.data?.cumulation
          if (!w || !c) return numberStyle
          if (0 < c.actualHour) {
            return {
              ...numberStyle,
              cursor: 'pointer',
              color: 'blue',
            }
          }
          return numberStyle
        },
        filter: ClientSideNumberFilter,
        floatingFilter: true,
        onCellClicked: (e: CellClickedEvent<TicketRow>) => {
          if (!onClickedActualHourCell) return
          onClickedActualHourCell(e.data)
        },
      },
    ],
  } as FlagxsColumnDef
}

export const useTermColumnGroupDef = (): FlagxsColumnDef => {
  const getWbsItemStatus = useCallback(
    (row: TicketRow | undefined) => row?.wbsItem?.status,
    []
  )
  const getScheduledDate = useCallback(
    (row: TicketRow | undefined) => row?.wbsItem?.scheduledDate,
    []
  )
  const getActualDate = useCallback(
    (row: TicketRow | undefined) => row?.wbsItem?.actualDate,
    []
  )

  const sprintColumnDef = useSprint({
    field: 'wbsItem.sprint',
    getWbsItemProjectUuid: (row: TicketRow | undefined) =>
      row?.wbsItem?.projectUuid,
    getTeamUuid: (row: TicketRow | undefined) => row?.wbsItem?.team?.uuid,
  })

  const setScheduledEndDate = useCallback(
    (
      params: ValueSetterParams<TicketRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem) return
      if (!params.data?.wbsItem?.scheduledDate) {
        params.data.wbsItem.scheduledDate = {}
      }
      params.data.wbsItem.scheduledDate.endDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_SCHEDULED_END] = oldValue
    },
    []
  )
  const scheduledStartDateColumnDef = useScheduledStartDate({
    field: FIELD_WBSITEM_SCHEDULED_START,
    getScheduledDate,
    setScheduledEndDate,
  })

  const setScheduledStartDate = useCallback(
    (
      params: ValueSetterParams<TicketRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem) return
      if (!params.data?.wbsItem?.scheduledDate) {
        params.data.wbsItem.scheduledDate = {}
      }
      params.data.wbsItem.scheduledDate.startDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_SCHEDULED_START] = oldValue
    },
    []
  )
  const scheduledEndDateColumnDef = useScheduledEndDate({
    field: FIELD_WBSITEM_SCHEDULED_END,
    getScheduledDate,
    setScheduledStartDate,
  })

  const setActualEndDate = useCallback(
    (
      params: ValueSetterParams<TicketRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem) return
      if (!params.data?.wbsItem?.actualDate) {
        params.data.wbsItem.actualDate = {}
      }
      params.data.wbsItem.actualDate.endDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_ACTUALDATE_END] = oldValue
    },
    []
  )
  const actualStartDateColumnDef = useActualStartDate({
    field: FIELD_WBSITEM_ACTUALDATE_START,
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualEndDate,
  })

  const setActualStartDate = useCallback(
    (
      params: ValueSetterParams<TicketRow>,
      newValue: string,
      oldValue: string
    ) => {
      if (!params.data?.wbsItem) return
      if (!params.data?.wbsItem?.actualDate) {
        params.data.wbsItem.actualDate = {}
      }
      params.data.wbsItem.actualDate.startDate = newValue
      if (!params.data.editedData) {
        params.data.editedData = {}
      }
      params.data.editedData[FIELD_WBSITEM_ACTUALDATE_START] = oldValue
    },
    []
  )
  const actualEndDateColumnDef = useActualEndDate({
    field: FIELD_WBSITEM_ACTUALDATE_END,
    getWbsItemStatus,
    getScheduledDate,
    getActualDate,
    setActualStartDate,
  })

  const groupDef = useMemo(
    () => ({
      externalId: 'ticket.list.term',
      headerName: intl.formatMessage({ id: 'projectPlan.term' }),
      children: [
        {
          externalId: 'ticket.list.wbsItem.sprint',
          ...sprintColumnDef,
          editable: editableExceptSummaryRow,
        },
        {
          externalId: 'ticket.list.wbsItem.scheduledDate.startDate',
          ...scheduledStartDateColumnDef,
          editable: editableExceptSummaryRow,
        },
        {
          externalId: 'ticket.list.wbsItem.scheduledDate.endDate',
          ...scheduledEndDateColumnDef,
          editable: editableExceptSummaryRow,
        },
        {
          externalId: 'ticket.list.wbsItem.actualDate.startDate',
          ...actualStartDateColumnDef,
          editable: editableExceptSummaryRow,
        },
        {
          externalId: 'ticket.list.wbsItem.actualDate.endDate',
          ...actualEndDateColumnDef,
          editable: editableExceptSummaryRow,
        },
      ],
    }),
    [
      sprintColumnDef,
      scheduledStartDateColumnDef,
      scheduledEndDateColumnDef,
      actualStartDateColumnDef,
      actualEndDateColumnDef,
    ]
  )

  return groupDef
}

export const CHANGE_LOG_COLUMN_GROUP_DEF: FlagxsColumnDef = {
  externalId: 'ticket.list.updateInfo',
  headerName: intl.formatMessage({ id: 'changeLog' }),
  children: [
    {
      externalId: 'ticket.list.wbsItem.revision',
      field: 'wbsItem.revision',
      headerName: intl.formatMessage({ id: 'revision' }),
      hide: true,
      width: 90,
      floatingFilter: true,
      filter: 'clientSideTextFilter',
    },
    {
      externalId: 'ticket.list.wbsItem.createdBy',
      field: 'wbsItem.createdBy',
      headerName: intl.formatMessage({ id: 'createdBy' }),
      hide: true,
      width: 120,
      cellRenderer: IconCellRenderer,
      cellRendererParams: {
        labelField: 'wbsItem.createdBy.name',
        iconUrlField: 'wbsItem.createdBy.iconUrl',
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        getValue: (v?: UserBasic) => v?.name,
        getLabel: (v?: UserBasic) => v?.name,
      },
      comparator: userNameComparator,
    },
    {
      externalId: 'ticket.list.wbsItem.createdAt',
      field: 'wbsItem.createdAt',
      headerName: intl.formatMessage({ id: 'createdAt' }),
      hide: true,
      width: 175,
      type: [ColumnType.dateTime],
      floatingFilter: true,
    },
    {
      externalId: 'ticket.list.wbsItem.updatedBy',
      field: 'wbsItem.updatedBy',
      headerName: intl.formatMessage({ id: 'updatedBy' }),
      hide: true,
      width: 120,
      cellRenderer: IconCellRenderer,
      cellRendererParams: {
        labelField: 'wbsItem.updatedBy.name',
        iconUrlField: 'wbsItem.updatedBy.iconUrl',
      },
      filter: ClientSideSelectFilter,
      floatingFilter: true,
      filterParams: {
        getValue: (v?: UserBasic) => v?.name,
        getLabel: (v?: UserBasic) => v?.name,
      },
      comparator: userNameComparator,
    },
    {
      externalId: 'ticket.list.wbsItem.updatedAt',
      field: 'wbsItem.updatedAt',
      headerName: intl.formatMessage({ id: 'updatedAt' }),
      hide: true,
      width: 175,
      type: [ColumnType.dateTime],
      floatingFilter: true,
    },
  ],
} as FlagxsColumnDef
