import { useCallback } from 'react'
import { AgreementEntity } from '../../../domain/entity/AgreementEntity'
import { useAgreementRepository } from '../../../services/adaptors/agreementRepositoryAdaptor'

type AgreeTerms = (agreements: AgreementEntity[]) => Promise<void>

type AgreementsUseCases = {
  agreeTerms: AgreeTerms
}

export const useAgreementsUseCase = (): AgreementsUseCases => {
  const { saveUserAgreementLogs } = useAgreementRepository()
  const agreeTerms = useCallback(
    async (agreements: AgreementEntity[]) => {
      await saveUserAgreementLogs(agreements)
    },
    [saveUserAgreementLogs]
  )

  return {
    agreeTerms,
  }
}
