import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { Box } from '@mui/material'
import { AG_GRID_THEME } from '../BulkSheet/const'
import React, { Ref, useCallback, useMemo, useState } from 'react'
import { onRangeSelectionChanged } from './gridEventCallback'
import { getUrlQueryObject } from '../../../utils/urls'

type Props = AgGridReactProps & {
  gridRef?: Ref<AgGridReact>
}
export const BulkSheetView = React.forwardRef<HTMLDivElement, Props>(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const rowBuffer = useMemo(() => {
      const urlQueryString = getUrlQueryObject()
      return !isNaN(Number(urlQueryString?.rowBuffer))
        ? (urlQueryString.rowBuffer as number)
        : 50
    }, [])

    const [copyHeadersToClipboard, setCopyHeadersToClipboard] = useState(false)
    const onKeyDown = useCallback(
      e => {
        setCopyHeadersToClipboard(e.shiftKey)
      },
      [setCopyHeadersToClipboard]
    )

    return (
      <Box
        ref={ref}
        className={AG_GRID_THEME}
        sx={{
          width: '100%',
          height: '100%',
          padding: '0 8px 8px',
        }}
        onKeyDown={onKeyDown}
      >
        <AgGridReact
          ref={props.gridRef}
          getRowId={params => params.data.uuid}
          getDataPath={data => data.treeValue}
          rowBuffer={rowBuffer}
          animateRows={true}
          rowSelection="multiple"
          enableRangeSelection={true}
          undoRedoCellEditing={true}
          suppressNoRowsOverlay={true}
          onRangeSelectionChanged={onRangeSelectionChanged}
          copyHeadersToClipboard={copyHeadersToClipboard}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: '',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                  suppressPivotMode: true,
                  suppressValues: true,
                  suppressRowGroups: true,
                },
              },
            ],
            defaultToolPanel: '',
          }}
          {...props}
        />
      </Box>
    )
  }
)
