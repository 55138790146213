import {
  EntitySearchReferenceEntity,
  WbsItemAdditionalPropertySelectOption,
  WbsItemAdditionalPropertyType,
} from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import {
  I18nLabelVO,
  i18nLabelVoService,
} from '../../../../domain/value-object/I18nLabelVO'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { generateUuid } from '../../../../utils/uuids'
import { TreeRow } from '../../../containers/BulkSheetView/model'

/**
 * RowData definition
 */
export interface GroupHeaderRow extends TreeRow {
  projectUuid: string
  headerNameI18n: I18nLabelVO
}
export interface AdditionalPropertyRow extends TreeRow {
  projectUuid: string
  wbsItemTypes: WbsItemTypeVO[]
  propertyNameI18n: I18nLabelVO
  propertyType: WbsItemAdditionalPropertyType
  required: boolean
  selectOptions?: WbsItemAdditionalPropertySelectOption[]
  entitySearchReferenceEntity?: EntitySearchReferenceEntity
  using: boolean
}
export type WbsItemAdditionalPropertyRow =
  | GroupHeaderRow
  | AdditionalPropertyRow

/**
 * Row data functionality
 */
export const isGroupHeaderRow = (
  rowData: WbsItemAdditionalPropertyRow
): boolean => rowData.treeValue.length === 1
export const isAdditionalPropertyRow = (
  rowData: WbsItemAdditionalPropertyRow
): boolean => rowData.treeValue.length === 2
export const getName = (rowData: WbsItemAdditionalPropertyRow): string => {
  if (isGroupHeaderRow(rowData)) {
    return i18nLabelVoService.getLabel(
      (rowData as GroupHeaderRow).headerNameI18n
    )
  }
  return i18nLabelVoService.getLabel(
    (rowData as AdditionalPropertyRow).propertyNameI18n
  )
}
export const hasChildren = (
  targetRow: WbsItemAdditionalPropertyRow,
  allRows: WbsItemAdditionalPropertyRow[]
): boolean => {
  if (isAdditionalPropertyRow(targetRow)) {
    return false
  }
  return allRows.some(otherRow => targetRow.uuid === otherRow.treeValue[0])
}
export const getGroupHeaderUuidOfAdditionalProperty = (
  rowData: AdditionalPropertyRow
): string => {
  return rowData.treeValue[0]
}
export const createNewGroupHeaderRow = (
  projectUuid: string
): GroupHeaderRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [uuid],
    uuid,
    projectUuid,
    headerNameI18n: {
      labelId: generateUuid(),
      labels: [],
    },
  }
}
export const createNewAdditionalPropertyRow = (
  projectUuid: string,
  groupHeaderUuid: string
): WbsItemAdditionalPropertyRow => {
  const uuid = generateUuid()
  return {
    added: true,
    edited: true,
    treeValue: [groupHeaderUuid, uuid],
    uuid,
    projectUuid,
    wbsItemTypes: [],
    propertyNameI18n: {
      labelId: generateUuid(),
      labels: [],
    },
    propertyType: WbsItemAdditionalPropertyType.TEXT,
    required: false,
    selectOptions: undefined,
    entitySearchReferenceEntity: undefined,
    using: false,
  }
}
export const entityToRow = (
  projectUuid: string,
  entity: WbsItemAdditionalPropertyLayoutEntity
): WbsItemAdditionalPropertyRow[] => {
  const rows: WbsItemAdditionalPropertyRow[] = []
  entity.groupHeaderLayouts.map(({ groupHeader, propertyLayouts }) => {
    // Add a group header row.
    rows.push({
      treeValue: [groupHeader.uuid],
      uuid: groupHeader.uuid,
      projectUuid,
      headerNameI18n: groupHeader.headerNameI18n,
      revision: groupHeader.revision,
      createdAt: groupHeader.createdAt,
      createdBy: groupHeader.createdBy,
      updatedAt: groupHeader.updatedAt,
      updatedBy: groupHeader.updatedBy,
    })

    propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
      // Add a additional property row.
      rows.push({
        treeValue: [groupHeader.uuid, wbsItemAdditionalProperty.uuid],
        uuid: wbsItemAdditionalProperty.uuid,
        projectUuid,
        wbsItemTypes: wbsItemAdditionalProperty.wbsItemTypes || [],
        propertyNameI18n: wbsItemAdditionalProperty.propertyNameI18n,
        propertyType: wbsItemAdditionalProperty.propertyType,
        required: wbsItemAdditionalProperty.required,
        selectOptions: wbsItemAdditionalProperty.selectOptions,
        entitySearchReferenceEntity:
          wbsItemAdditionalProperty.entitySearchReferenceEntity,
        using: wbsItemAdditionalProperty.using,
        revision: wbsItemAdditionalProperty.revision,
        createdAt: wbsItemAdditionalProperty.createdAt,
        createdBy: wbsItemAdditionalProperty.createdBy,
        updatedAt: wbsItemAdditionalProperty.updatedAt,
        updatedBy: wbsItemAdditionalProperty.updatedBy,
      })
    })
  })
  return rows
}
