import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { styled } from '@mui/system'
import './style.scss'
import store, { AllState } from '../../../../store'
import {
  Comment,
  deleteDraft,
  editComment,
  finishEditComment,
  generateCommentDraftKey,
  MentionType,
  postComment,
  saveDraft,
} from '../../../../store/comments'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import UserApi from '../../../../lib/functions/user'
import ProjectMemberApi from '../../../../lib/functions/projectMember'
import TenantStorage from '../../../../utils/storage'
import { SendButton } from './Button'
import SubmitButton from '../../../components/buttons/SubmitButton'
import CancelButton from '../../../components/buttons/CancelButton'
import { BorderColor } from '../../../../styles/commonStyles'
import { isImageFile, UploadedFile } from '../../../../utils/file'
import { showAlert } from '../../../../store/globalAlert'
import { intl } from '../../../../i18n'
import {
  EditorContent,
  Extension,
  JSONContent,
  mergeAttributes,
  ReactRenderer,
  useEditor,
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import { Mention } from '@tiptap/extension-mention'
import { RichTextStyleButtons } from '../../../components/toolbars/RichTextToolbar'
import {
  findHighLightItemsInRichText,
  insertLinkFromClipboard,
} from '../../../components/editors/richtext'
import Dropzone from 'react-dropzone'
import Mentions from './Mentions'
import tippy, { GetReferenceClientRect } from 'tippy.js'
import Auth from '../../../../lib/commons/auth'
import {
  FileEntityAttributeKey,
  MentionEntityAttributeKey,
  MentionSuggestionData,
} from './lib/Converter'
import { muiTheme } from '../../../../styles/muiTheme'

interface ComponentOwnProps {
  applicationFunctionUuid: string
  dataUuid?: string
  suffix?: string
  projectUuid?: string
  comment?: Comment
  isEditing: boolean
  className?: string
  hideToolbar?: boolean
  setComment?: (
    html: string,
    text: string,
    mentions: MentionSuggestionData[]
  ) => void
}

interface StateProps {
  draft?: string
}

interface Props extends WrappedComponentProps, ComponentOwnProps, StateProps {}

const theme = muiTheme
const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})
const Editor = styled('div')<{ isEditing: boolean }>(({ isEditing }) => {
  let style = {
    boxSizing: 'border-box',
    border: `1px solid ${BorderColor.DARK_BLACK}`,
    cursor: 'text',
    borderRadius: '5px',
    backgroundColor: '#fff',
    fontSize: '14px',
    lineHeight: 1.6,
  } as any
  if (isEditing) {
    style = {
      ...style,
      width: 'calc(100% - 20px)',
      margin: '0 10px',
    }
  }

  return style
})
const MarkupToolbarItems = styled('div')({
  display: 'flex',
  overflow: 'hidden',
})
const SendButtonWrapper = styled('div')({
  margin: 'auto 0 auto auto',
  paddingRight: theme.spacing(1),
})
const EditingButtons = styled('div')({
  margin: '5px 5px 0',
})

const insertImages = (files: UploadedFile[], editor) => {
  files.forEach(file => {
    editor
      ?.chain()
      .focus()
      .setImage({
        src: file.url,
        [FileEntityAttributeKey.fileName]: file.name,
        [FileEntityAttributeKey.fileType]: file.mimeType,
        [FileEntityAttributeKey.fileUrl]: file.url,
      })
      .run()
  })
}

const uploadImages = async (files): Promise<UploadedFile[]> => {
  const uploadImage = async (imageFile): Promise<UploadedFile> => {
    const formData = new FormData()
    formData.append('file', imageFile)
    const response = await TenantStorage.uploadFile(imageFile)
    return {
      url: response.json.downloadUrl,
      name: imageFile.name,
      mimeType: imageFile.type,
    }
  }
  const imageFiles = files.filter(file => isImageFile(file))
  const notImageFiles = files.filter(file => !isImageFile(file))
  if (notImageFiles.length > 0) {
    store.dispatch(
      showAlert({
        message: intl.formatMessage({ id: 'editor.canNotUploadFile' }),
      })
    )
  }
  const uploadedFiles: UploadedFile[] = await Promise.all(
    imageFiles.map(imageFile => uploadImage(imageFile))
  )

  return uploadedFiles.filter(v => v.url)
}

const CommentEditor = ({
  intl,
  applicationFunctionUuid,
  comment,
  dataUuid,
  suffix,
  isEditing,
  draft,
  projectUuid,
  hideToolbar = false,
  setComment,
}: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const [highlightItem, setHighlightItem] = useState<string[]>([])

  // functions to get mention data.
  const getMentionData = useCallback(
    async (
      projectUuid: string | undefined,
      query: string
    ): Promise<MentionSuggestionData[]> => {
      let users: {
        uuid: string
        iconUrl: string
        name: string
      }[] = []
      if (projectUuid) {
        const projectMembers = await ProjectMemberApi.search(query, {
          projectUuid,
        })
        users = projectMembers || []
      } else {
        users = await UserApi.search(query)
      }
      return users.map(user => {
        return {
          avatar: user.iconUrl,
          name: user.name,
          description: '',
          userUuid: user.uuid,
          mentionType: MentionType.USER,
        } as MentionSuggestionData
      })
    },
    []
  )
  const getProjectMentionData = useCallback(
    (
      projectUuid: string | undefined,
      query: string
    ): MentionSuggestionData[] => {
      const targetStr = 'project'
      if (projectUuid && targetStr.includes(query)) {
        return [
          {
            name: targetStr,
            description: '',
            userUuid: projectUuid,
            mentionType: MentionType.PROJECT_MEMBER,
          } as MentionSuggestionData,
        ]
      }
      return []
    },
    []
  )

  // Setup tiptap
  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          heading: false,
          horizontalRule: false,
        }),
        Placeholder.configure({
          placeholder: intl.formatMessage({ id: 'commentEditor.writeComment' }),
        }),
        Link,
        Image.extend({
          addAttributes() {
            return {
              src: {
                default: null,
              },
              [FileEntityAttributeKey.fileUrl]: {
                default: null,
              },
              [FileEntityAttributeKey.fileName]: {
                default: null,
              },
              [FileEntityAttributeKey.fileType]: {
                default: null,
              },
            }
          },
        }).configure({
          inline: true,
          HTMLAttributes: {
            class: 'uploaded-image',
          },
        }),
        Mention.extend({
          addAttributes() {
            return {
              ...this.parent?.(),
              [MentionEntityAttributeKey.mentionUuid]: {
                default: null,
              },
              [MentionEntityAttributeKey.mentionType]: {
                default: null,
              },
            }
          },
          renderHTML({ node, HTMLAttributes }) {
            const mentionUuid =
              node.attrs[MentionEntityAttributeKey.mentionUuid]
            const mentionType =
              node.attrs[MentionEntityAttributeKey.mentionType]
            const loginUser = Auth.getCurrentTenant()!.user!.uuid
            const loginMention =
              mentionUuid === loginUser ||
              mentionType === MentionType.PROJECT_MEMBER
            const newAttrs = {
              class: clsx(
                'mention-base',
                !loginMention && 'mention',
                loginMention && 'mention-login-user'
              ),
            }
            return [
              'span',
              mergeAttributes(
                { 'data-type': this.name },
                this.options.HTMLAttributes,
                HTMLAttributes,
                newAttrs
              ),
              this.options.renderText({
                options: this.options,
                node,
              }),
            ]
          },
        }).configure({
          suggestion: {
            items: async ({ query }) => {
              const userMentions = await getMentionData(projectUuid, query)
              const projectMention = getProjectMentionData(projectUuid, query)
              return [...projectMention, ...userMentions]
            },
            render: () => {
              let reactRenderer
              let popup
              return {
                onStart: props => {
                  reactRenderer = new ReactRenderer(Mentions, {
                    props,
                    editor: props.editor,
                  })

                  popup = tippy('body', {
                    getReferenceClientRect:
                      props.clientRect as GetReferenceClientRect,
                    appendTo: () => document.body,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'top-start',
                    theme: 'mentionList',
                  })
                },
                onUpdate(props) {
                  reactRenderer?.updateProps(props)

                  if (popup?.length) {
                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    })
                  }
                },
                onKeyDown(props) {
                  return reactRenderer?.ref?.onKeyDown(props)
                },
                onExit() {
                  if (popup?.length) {
                    popup[0].destroy()
                  }
                  if (!!reactRenderer) {
                    reactRenderer.destroy()
                  }
                },
              }
            },
          },
        }),
        Extension.create({
          addKeyboardShortcuts(this) {
            return {
              'Cmd-Enter': () => {
                return true
              },
              'Ctrl-Enter': () => {
                return true
              },
            }
          },
        }),
      ],
      content: draft || comment?.text || '',
      parseOptions: {
        preserveWhitespace: 'full',
      },
      onSelectionUpdate: ({ editor }) => {
        findHighLightItemsImpl(editor)
      },
      onBlur: ({ editor }) => {
        if (editor.getText() === '') {
          store.dispatch(
            deleteDraft(
              applicationFunctionUuid,
              dataUuid!,
              suffix,
              comment ? comment.uuid : undefined
            )
          )
        } else {
          store.dispatch(
            saveDraft(
              applicationFunctionUuid,
              dataUuid!,
              suffix,
              comment ? comment.uuid : undefined,
              editor.getHTML()
            )
          )
        }
      },
      onUpdate: ({ editor }) => {
        if (setComment) {
          const html = editor.getHTML()
          const text = editor.getText()
          const mentions = getMentions(editor)
          setComment(html, text, mentions)
        }
      },
    },
    [applicationFunctionUuid, dataUuid, projectUuid]
  )

  // Setup callback.
  const finishEdit = useCallback(() => {
    setUploadedFiles([])
    store.dispatch(
      finishEditComment(
        applicationFunctionUuid,
        dataUuid!,
        suffix,
        comment ? comment.uuid : undefined
      )
    )
  }, [])
  const getMentions = useCallback(
    editor => {
      let result: MentionSuggestionData[] = []
      const textJson = editor?.getJSON()
      if (textJson) {
        result = getMentionsFromJson(textJson)
      }
      return result
    },
    [editor]
  )
  const getMentionsFromJson = (json: JSONContent) => {
    let result: MentionSuggestionData[] = []
    const type = json['type']
    const content = json['content']
    if (type === 'mention') {
      const attrsJson = json['attrs']
      if (attrsJson) {
        const mentionUuid = attrsJson[MentionEntityAttributeKey.mentionUuid]
        const mentionType = attrsJson[MentionEntityAttributeKey.mentionType]
        const item: MentionSuggestionData = {
          userUuid: mentionUuid,
          mentionType: mentionType,
        }
        result.push(item)
      }
    } else if (type !== 'mention' && content) {
      for (let i = 0; i < content.length; i++) {
        const subItem = getMentionsFromJson(content[i])
        if (subItem && subItem.length > 0) {
          result = [...result, ...subItem]
        }
      }
    }
    return result
  }
  const cleanNotUsedFiles = useCallback(
    (html: string) => {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i]
        if (!html.includes(file.url)) {
          TenantStorage.deleteFileByUrl(file.url)
        }
      }
    },
    [uploadedFiles]
  )
  const onSubmit = useCallback(() => {
    const html = editor?.getHTML() || ''
    const mentions = getMentions(editor).map(v => ({
      mentionType: v.mentionType || '',
      uuid: v.userUuid || '',
    }))
    cleanNotUsedFiles(html)
    if (isEditing) {
      store.dispatch(
        editComment(
          applicationFunctionUuid,
          dataUuid!,
          {
            uuid: comment!.uuid,
            text: html,
            createdAt: comment!.createdAt,
            createdBy: comment!.createdBy,
          },
          mentions
        )
      )
    } else {
      store.dispatch(
        postComment(applicationFunctionUuid, dataUuid!, html, mentions)
      )
    }
    editor?.commands.clearContent()
    finishEdit()
  }, [editor, dataUuid])
  const onKeyDown = useCallback(
    event => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
        // Ctrl + Enter
        const editedSize = editor?.state.doc.textContent.length
        if (editedSize && editedSize > 0) {
          onSubmit()
        }
      }
      if (event.keyCode === 27 && isEditing) {
        // finish editing mode when esc key is pressed
        finishEdit()
      }
    },
    [onSubmit]
  )
  const onAddFiles = useCallback(
    (files: UploadedFile[]) => {
      setUploadedFiles([...uploadedFiles, ...files])
    },
    [uploadedFiles]
  )

  const findHighLightItemsImpl = _.debounce(editor => {
    setHighlightItem(findHighLightItemsInRichText(editor))
  }, 100)

  const onPaste = async event => {
    if (event.clipboardData.files.length) {
      uploadImages(Array.from(event.clipboardData.files)).then(files => {
        insertImages(files, editor)
        onAddFiles(files)
      })
    }
  }

  const onPasteCapture = useCallback(
    event => {
      if (!editor || !event.clipboardData) return
      if (insertLinkFromClipboard(event.clipboardData, editor)) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    [editor]
  )

  const onDropAccepted = async acceptFiles => {
    uploadImages(acceptFiles).then(files => {
      insertImages(files, editor)
      onAddFiles(files)
    })
  }

  const isEmptyEditor = () => {
    const value = editor?.getHTML()
    return !value || value === '<p></p>'
  }

  return (
    <RootContainer>
      <Editor
        isEditing={isEditing}
        className="flagxs-markup-root"
        onKeyDown={onKeyDown}
      >
        <Dropzone multiple={true} onDropAccepted={onDropAccepted}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                onClick: e => {
                  e.stopPropagation()
                },
              })}
            >
              <input {...getInputProps()} />
              <EditorContent
                editor={editor || null}
                onPaste={onPaste}
                onPasteCapture={onPasteCapture}
              />
            </div>
          )}
        </Dropzone>
        {hideToolbar === false && (
          <MarkupToolbarItems>
            <RichTextStyleButtons
              editor={editor || undefined}
              hideHeaderOne={true}
              hideHeaderTwo={true}
              hideHeaderThree={true}
              hideHeaderFour={true}
              hideHeaderFive={true}
              hideHeaderSix={true}
              hideExtras={true}
              highlightItems={highlightItem}
            />
            <SendButtonWrapper key="submit-button">
              {!isEditing && (
                <SendButton
                  onClick={onSubmit}
                  disabled={!dataUuid || isEmptyEditor()}
                />
              )}
            </SendButtonWrapper>
          </MarkupToolbarItems>
        )}
      </Editor>
      {isEditing && (
        <EditingButtons>
          <SubmitButton
            onClick={onSubmit}
            disabled={!dataUuid || isEmptyEditor()}
          />
          <CancelButton onClick={finishEdit} />
        </EditingButtons>
      )}
    </RootContainer>
  )
}

const mapStateToProps = (state: AllState, ownProps: ComponentOwnProps) => ({
  draft: state.comments.drafts.get(
    generateCommentDraftKey(
      ownProps.applicationFunctionUuid,
      ownProps.dataUuid!,
      ownProps.suffix,
      ownProps.comment ? ownProps.comment.uuid : undefined
    )
  ),
})

export default connect<StateProps, void, ComponentOwnProps, AllState>(
  mapStateToProps
)(injectIntl(CommentEditor))
