import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { GanttBackground } from './GanttBackground'
import { GanttSlider } from './GanttSlider'
import { ProjectPlanRow } from '../../../../../../../../pages/ProjectPlan/projectPlanOptions'
import objects from '../../../../../../../../../utils/objects'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'
import { GanttReportParameter } from '../ganttUtil'

const ChartContainer = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>((props, ref) => (
  <div className="sevend-ag-cell-gantt-chart" {...props} ref={ref} />
))

const SliderContainer = ({ children, rowHeight }) => {
  return (
    <div
      style={{
        left: 0,
        width: '100%',
        height: '3px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: rowHeight / 2 - 4,
        margin: '-1px',
      }}
    >
      {children}
    </div>
  )
}

export interface GanttCellRendererProps extends ICellRendererParams {
  timeScale: CalendarDateVO[]
  parameter: GanttReportParameter
  updateRows: (row: ProjectPlanRow[]) => void
  editable?: boolean
  dataPath?: string
}

interface Props extends GanttCellRendererProps {}

export const GanttReportCellRenderer = (props: Props) => {
  const { context } = props
  const ganttParams = {
    ...props,
    parameter: context.ganttParameter,
    timeScale: context.ganttTimeScale,
  }
  if (
    !objects.getValue(props.data, props.dataPath ?? 'wbsItem') ||
    !ganttParams.parameter ||
    !ganttParams.timeScale
  ) {
    return <></>
  }
  return (
    <>
      <ChartContainer>
        <GanttBackground {...ganttParams} />
      </ChartContainer>
      <SliderContainer rowHeight={props.node.rowHeight || 32}>
        <GanttSlider {...ganttParams} editable={props.editable !== false} />
      </SliderContainer>
    </>
  )
}
