import { SelectFilter } from '../../../../containers/BulkSheetView/components/filter/SelectFilter'
import { TextFilter } from '../../../../containers/BulkSheetView/components/filter/TextFilter'

export type filterKey = 'DISPLAY_NAME' | 'RESPONSIBLE' | 'PRIORITY'

export interface DisplayNameFilterModel {
  key: 'DISPLAY_NAME'
  value: TextFilter | undefined
}

export interface ResponsibleFilterModel {
  key: 'RESPONSIBLE'
  value: SelectFilter | undefined
}

export interface PriorityFilterModel {
  key: 'PRIORITY'
  value: SelectFilter | undefined
}

export type FilterModel =
  | DisplayNameFilterModel
  | ResponsibleFilterModel
  | PriorityFilterModel

export const isSameTextFilter = (x?: TextFilter, y?: TextFilter) =>
  x === y || (x?.operator === y?.operator && x?.value === y?.value)
export const isSameSelectFilter = (x?: SelectFilter, y?: SelectFilter) =>
  x === y ||
  (x?.values.length === y?.values.length &&
    x?.values.every(v => y?.values.includes(v)) &&
    x?.includeBlank === y?.includeBlank)
