import { u } from 'unist-builder'
import { all as remarkAll } from 'remark-rehype'
import { ElementContent } from 'hast'
import { table } from 'mdast-util-to-hast/lib/handlers/table'
import { UploadedFile } from './file'

export interface MdAnalyzedItem {
  startPos: number
  endPos: number
  styleType: string
}

interface MarkdownItem {
  name: string
  regExp: RegExp
  mdValue: string
}

export enum StyleType {
  HEADER_ONE = 'HEADER_ONE',
  HEADER_TWO = 'HEADER_TWO',
  HEADER_THREE = 'HEADER_THREE',
  HEADER_FOUR = 'HEADER_FOUR',
  HEADER_FIVE = 'HEADER_FIVE',
  HEADER_SIX = 'HEADER_SIX',
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  STRIKETHROUGH = 'STRIKETHROUGH',
  CODE = 'CODE',
  UNORDERED_LIST_ITEM = 'UNORDERED_LIST_ITEM',
  ORDERED_LIST_ITEM = 'ORDERED_LIST_ITEM',
  CODE_BLOCK = 'CODE_BLOCK',
  BLOCKQUOTE = 'BLOCKQUOTE',
  HORIZONTAL_RULE = 'HORIZONTAL_RULE',
  TASK_LIST = 'TASK_LIST',
  TABLE = 'TABLE',
  FILE = 'FILE',
}

export const InlineStyles = [
  StyleType.BOLD,
  StyleType.ITALIC,
  StyleType.STRIKETHROUGH,
  StyleType.CODE,
]

export const BlockStyles = [
  StyleType.HEADER_ONE,
  StyleType.HEADER_TWO,
  StyleType.HEADER_THREE,
  StyleType.HEADER_FOUR,
  StyleType.HEADER_FIVE,
  StyleType.HEADER_SIX,
  StyleType.UNORDERED_LIST_ITEM,
  StyleType.ORDERED_LIST_ITEM,
  StyleType.CODE_BLOCK,
  StyleType.BLOCKQUOTE,
  StyleType.HORIZONTAL_RULE,
  StyleType.TASK_LIST,
]

export const IndependenceStyles = [StyleType.TABLE, StyleType.FILE]

const HorizontalRuleStyleItem: MarkdownItem = {
  name: StyleType.HORIZONTAL_RULE,
  regExp: /(\*)\1{2,}/,
  mdValue: '***',
}
const TableStyleItem: MarkdownItem = {
  name: StyleType.TABLE,
  regExp: /\|(.*)\|/,
  mdValue: '|',
}
const BlockquoteStyleItem: MarkdownItem = {
  name: StyleType.BLOCKQUOTE,
  regExp: /> /,
  mdValue: '> ',
}
const OrderedListStyleItem: MarkdownItem = {
  name: StyleType.ORDERED_LIST_ITEM,
  regExp: /[0-9]+\. /,
  mdValue: '0. ',
}
const HeaderOneStyleItem: MarkdownItem = {
  name: StyleType.HEADER_ONE,
  regExp: /# /,
  mdValue: '# ',
}
const HeaderTwoStyleItem: MarkdownItem = {
  name: StyleType.HEADER_TWO,
  regExp: /## /,
  mdValue: '## ',
}
const HeaderThreeStyleItem: MarkdownItem = {
  name: StyleType.HEADER_THREE,
  regExp: /### /,
  mdValue: '### ',
}
const TaskListStyleItem: MarkdownItem = {
  name: StyleType.TASK_LIST,
  regExp: /- \[.\] /,
  mdValue: '- [ ] ',
}
const UnorderedListStyleItem: MarkdownItem = {
  name: StyleType.UNORDERED_LIST_ITEM,
  regExp: /- /,
  mdValue: '- ',
}
const BoldStyleItem: MarkdownItem = {
  name: StyleType.BOLD,
  regExp: /__/,
  mdValue: '__',
}
const ItalicStyleItem: MarkdownItem = {
  name: StyleType.ITALIC,
  regExp: /\*/,
  mdValue: '*',
}
const StrikeThroughStyleItem: MarkdownItem = {
  name: StyleType.STRIKETHROUGH,
  regExp: /\~\~/,
  mdValue: '~~',
}
const CodeStyleItem: MarkdownItem = {
  name: StyleType.CODE,
  regExp: /`/,
  mdValue: '`',
}
const CodeBlockStyleItem: MarkdownItem = {
  name: StyleType.CODE_BLOCK,
  regExp: /```/,
  mdValue: '```',
}

const FileStyleItem: MarkdownItem = {
  name: StyleType.FILE,
  regExp: /\[(.*)\]\((.*)\)/,
  mdValue: '[]()',
}

const IndependenceItems: MarkdownItem[] = [
  HorizontalRuleStyleItem,
  TableStyleItem,
  FileStyleItem,
]

const LineItems: MarkdownItem[] = [BlockquoteStyleItem, OrderedListStyleItem]

const DuplicateCheckLineItems: MarkdownItem[] = [
  HeaderThreeStyleItem,
  HeaderTwoStyleItem,
  HeaderOneStyleItem,
  TaskListStyleItem,
  UnorderedListStyleItem,
]

const BothItems: MarkdownItem[] = [
  BoldStyleItem,
  ItalicStyleItem,
  StrikeThroughStyleItem,
]

const DuplicateCheckBothItems: MarkdownItem[] = [
  CodeBlockStyleItem,
  CodeStyleItem,
]

export const findMarkdownItemNames = (
  value: string,
  startPos: number,
  endPos: number
): string[] => {
  const analyzeList = analyzeMarkdownItems(value)
  let results: string[] = []
  let calcStartPos = startPos
  if (calcStartPos === 0 || value.substr(calcStartPos - 1, 1) === '\n') {
    calcStartPos += 1
  }
  if (startPos === endPos) {
    analyzeList.map(item => {
      if (item.startPos < calcStartPos && calcStartPos <= item.endPos) {
        results = [...results, item.styleType]
      }
    })
  } else {
    let startList: string[] = []
    let endList: string[] = []
    analyzeList.map(item => {
      if (item.startPos <= calcStartPos && calcStartPos <= item.endPos) {
        startList = [...startList, item.styleType]
      }
      if (item.startPos <= endPos && endPos <= item.endPos) {
        endList = [...endList, item.styleType]
      }
    })
    results = deduplicateList(startList, endList)
  }
  return results
}

export const toggleMarkdownStyle = (
  value: string,
  startPos: number,
  endPos: number,
  styleType: string
): string => {
  const markdownMap = createMarkdownItemMap()
  const mdItem = markdownMap.get(styleType)
  if (!mdItem) return value
  let analyzeList = analyzeMarkdownItems(value)
  const orderedCount = analyzeMarkdownItems(StyleType.ORDERED_LIST_ITEM).length
  let result: string = value
  let calcStartPos = startPos
  let calcEndPos = endPos
  const lineList = [...LineItems, ...DuplicateCheckLineItems]
  const excludeItems = analyzeExcludesItems(analyzeList, styleType)
  const isLine = lineList.filter(item => item.name === styleType).length > 0
  const isIndependent =
    IndependenceItems.filter(item => item.name === styleType).length > 0
  if (startPos === endPos) {
    if (!isLine && !isIndependent) {
      return value
    }
    let filterList = analyzeList.filter(
      item =>
        item.startPos <= calcStartPos &&
        calcStartPos <= item.endPos &&
        item.styleType === styleType
    )
    const filterExcludeList = excludeItems.filter(
      item => item.startPos < calcStartPos && calcStartPos <= item.endPos
    )
    let diffPos = 0
    if (filterExcludeList.length > 0) {
      filterExcludeList.map(item => {
        const tmpMd = markdownMap.get(item.styleType)
        diffPos = diffPos + (tmpMd ? tmpMd.mdValue.length : 0)
      })
      result = removeMarkdownItems(filterExcludeList, value)
      analyzeList = analyzeMarkdownItems(result)
      calcStartPos = calcStartPos - diffPos
      if (calcStartPos < 0) calcStartPos = 0
      analyzeList = analyzeMarkdownItems(result)
      filterList = analyzeList.filter(
        item =>
          item.startPos <= calcStartPos &&
          calcStartPos < item.endPos &&
          item.styleType === styleType
      )
    }
    if (!filterList || filterList.length === 0) {
      if (isIndependent) {
        return createIndependenceStyle(value, startPos, styleType)
      }
      let position = result.lastIndexOf('\n', calcStartPos)
      if (position === calcStartPos) {
        position = result.lastIndexOf('\n', calcStartPos - 1)
      }
      let newValue = mdItem.mdValue
      if (mdItem.name === StyleType.ORDERED_LIST_ITEM) {
        let calcOrderCount = orderedCount + 1
        const orderedValue = mdItem.mdValue.replace(
          '0',
          calcOrderCount.toString()
        )
        newValue = orderedValue
      }
      if (position <= 0) {
        result = newValue + result
      } else {
        result =
          result.slice(0, position + 1) + newValue + result.slice(position + 1)
      }
    } else {
      result = removeMarkdownItems(filterList, result)
    }
  } else {
    let itemList: MdAnalyzedItem[] = []
    let calcExcludes: MdAnalyzedItem[] = []
    for (let index = calcStartPos; index <= endPos; index++) {
      const tmpList = analyzeList.filter(
        item =>
          item.startPos <= index &&
          index <= item.endPos &&
          item.styleType === styleType
      )
      itemList = [...itemList, ...tmpList]
      const tmpExcludes = excludeItems.filter(
        item => item.startPos <= index && index <= item.endPos
      )
      calcExcludes = [...calcExcludes, ...tmpExcludes]
    }
    let filterList = Array.from(new Set(itemList))
    const filterExcludeList = Array.from(new Set(calcExcludes))
    let diffStartPos = 0
    let diffEndPos = 0
    if (filterExcludeList.length > 0) {
      filterExcludeList.map(item => {
        const tmpMd = markdownMap.get(item.styleType)
        if (item.startPos <= calcStartPos && calcStartPos < item.endPos) {
          diffStartPos = diffStartPos + (tmpMd ? tmpMd.mdValue.length : 0)
        }
        diffEndPos = diffEndPos + (tmpMd ? tmpMd.mdValue.length : 0)
      })
      result = removeMarkdownItems(filterExcludeList, value)
      analyzeList = analyzeMarkdownItems(result)
      calcStartPos = calcStartPos - diffStartPos
      if (calcStartPos < 0) calcStartPos = 0
      calcEndPos = endPos - diffEndPos
      itemList = []
      for (let index = calcStartPos; index <= endPos; index++) {
        const tmpList = analyzeList.filter(
          item =>
            item.startPos <= index &&
            index <= item.endPos &&
            item.styleType === styleType
        )
        itemList = [...itemList, ...tmpList]
      }
      filterList = Array.from(new Set(itemList))
    }
    if (!filterList || filterList.length === 0) {
      if (isLine) {
        result = createLineRangeText(
          mdItem,
          result,
          diffStartPos ? calcStartPos : startPos,
          diffEndPos ? calcEndPos : endPos,
          orderedCount
        )
      } else if (styleType === StyleType.CODE_BLOCK) {
        result = createCodeBlockText(
          mdItem,
          result,
          diffStartPos ? calcStartPos : startPos,
          diffEndPos ? calcEndPos : endPos
        )
      } else {
        result = createBothRangeText(
          mdItem,
          result,
          diffStartPos ? calcStartPos : startPos,
          diffEndPos ? calcEndPos : endPos
        )
      }
    } else {
      result = removeMarkdownItems(filterList, result)
    }
  }
  return result
}

const removeMarkdownItems = (
  removeItems: MdAnalyzedItem[],
  value: string
): string => {
  let result = value
  removeItems.sort((a, b) => {
    if (a.startPos > b.startPos) return -1
    if (a.startPos < b.startPos) return 1
    return 0
  })
  const markdownMap = createMarkdownItemMap()
  removeItems.map(item => {
    const mdItem = markdownMap.get(item.styleType)
    if (!mdItem) return
    let sliceValue = result.slice(item.startPos, item.endPos)
    sliceValue = sliceValue.replace(new RegExp(mdItem.regExp, 'g'), '')
    if (item.styleType === StyleType.CODE_BLOCK) {
      sliceValue = sliceValue.slice(1, sliceValue.length - 1)
    }
    result =
      result.slice(0, item.startPos) + sliceValue + result.slice(item.endPos)
  })
  return result
}

const createLineRangeText = (
  mdItem: MarkdownItem,
  value: string,
  startPos: number,
  endPos: number,
  orderedCount: number
): string => {
  let result
  let position = value.lastIndexOf('\n', startPos)
  if (position < 0) {
    position = 0
  } else {
    position += 1
  }
  let tempResult = value.slice(position, endPos)
  result = value.slice(0, position)
  let newLineValue
  let lastStartPos = 0
  let lastEndPos = 0
  let calcOrderCount = orderedCount
  while ((newLineValue = /\n/.exec(tempResult.slice(lastStartPos)))) {
    if (tempResult.length < newLineValue.index + lastStartPos) {
      break
    }
    lastEndPos =
      newLineValue.index + lastStartPos < tempResult.length
        ? newLineValue.index + lastStartPos
        : tempResult.length
    let sliceValue = tempResult.slice(lastStartPos, lastEndPos)
    let newValue = mdItem.mdValue + sliceValue
    if (mdItem.name === StyleType.ORDERED_LIST_ITEM) {
      calcOrderCount += 1
      const orderedValue = mdItem.mdValue.replace(
        '0',
        calcOrderCount.toString()
      )
      newValue = orderedValue + sliceValue
    }
    result = result + newValue + '\n'
    lastStartPos = lastEndPos + 1
  }
  if (lastEndPos === 0) {
    const lastIndex = value.lastIndexOf('\n', position)
    if (lastIndex === position) {
      position = position + 1
      result = value.slice(0, position)
    }
    const sliceValue = value.slice(position)
    let newValue = mdItem.mdValue + sliceValue
    if (mdItem.name === StyleType.ORDERED_LIST_ITEM) {
      calcOrderCount += 1
      const orderedValue = mdItem.mdValue.replace(
        '0',
        calcOrderCount.toString()
      )
      newValue = orderedValue + sliceValue
    }
    result = result + newValue
  } else if (lastStartPos + position <= endPos) {
    position = value.lastIndexOf('\n', lastStartPos + position) + 1
    const sliceValue = value.slice(position)
    let newValue = mdItem.mdValue + sliceValue
    if (mdItem.name === StyleType.ORDERED_LIST_ITEM) {
      calcOrderCount += 1
      const orderedValue = mdItem.mdValue.replace(
        '0',
        calcOrderCount.toString()
      )
      newValue = orderedValue + sliceValue
    }
    result = result + newValue
  } else {
    result = result + value.slice(endPos)
  }
  return result
}

const createBothRangeText = (
  mdItem: MarkdownItem,
  value: string,
  startPos: number,
  endPos: number
): string => {
  let calcStartPos = startPos
  let newLineValue
  let lastEndPos = 0
  let result = value
  let tempResult = result.slice(0, calcStartPos)
  while ((newLineValue = /\n/.exec(result.slice(calcStartPos)))) {
    if (endPos < newLineValue.index + calcStartPos) {
      break
    }
    lastEndPos =
      newLineValue.index + calcStartPos < endPos
        ? newLineValue.index + calcStartPos
        : endPos
    let sliceValue = result.slice(calcStartPos, lastEndPos)
    let newValue = mdItem.mdValue + sliceValue + mdItem.mdValue
    tempResult = tempResult + newValue + '\n'
    calcStartPos = lastEndPos + 1
  }
  if (lastEndPos === 0) {
    const sliceValue = result.slice(startPos, endPos)
    let newValue = mdItem.mdValue + sliceValue + mdItem.mdValue
    result = result.slice(0, startPos) + newValue + result.slice(endPos)
  } else if (calcStartPos <= endPos) {
    const sliceValue = result.slice(calcStartPos, endPos)
    let newValue = mdItem.mdValue + sliceValue + mdItem.mdValue
    result = tempResult + newValue + result.slice(endPos)
  } else {
    let newValue = result.slice(endPos)
    if (newValue.startsWith('\n')) {
      newValue = result.slice(endPos + 1)
    }
    result = tempResult + newValue
  }
  return result
}

const createCodeBlockText = (
  mdItem: MarkdownItem,
  value: string,
  startPos: number,
  endPos: number
): string => {
  const startText = value.slice(0, startPos)
  const endText = value.slice(endPos)
  let contents: string = ''
  if (startText.length > 0 && !startText.endsWith('\n')) {
    contents = contents + '\n'
  }
  contents = contents + mdItem.mdValue + '\n' + value.slice(startPos, endPos)
  if (!contents.endsWith('\n')) {
    contents = contents + '\n'
  }
  contents = contents + mdItem.mdValue
  if (!endText.startsWith('\n') && value.length !== endPos) {
    contents = contents + '\n'
  }
  const result = startText + contents + endText
  return result
}

const createMarkdownItemMap = (): Map<string, MarkdownItem> => {
  const items = [
    ...LineItems,
    ...BothItems,
    ...DuplicateCheckBothItems,
    ...DuplicateCheckLineItems,
    ...IndependenceItems,
  ]
  let resultMap = new Map<string, MarkdownItem>()
  items.map(item => {
    resultMap.set(item.name, item)
  })
  return resultMap
}

const analyzeMarkdownItems = (value: string): MdAnalyzedItem[] => {
  let analyzeList: MdAnalyzedItem[] = []

  DuplicateCheckLineItems.map(item => {
    const resultList = createLineAnalyzedItems(value, item.regExp, item.name)
    resultList.map(analizedItem => {
      if (!isDuplicated(analyzeList, analizedItem)) {
        analyzeList = [...analyzeList, analizedItem]
      }
    })
  })
  DuplicateCheckBothItems.map(item => {
    const resultList = createBothAnalyzedItem(
      value,
      item.regExp,
      item.name,
      item.mdValue.length
    )
    resultList.map(analizedItem => {
      if (!isDuplicated(analyzeList, analizedItem)) {
        analyzeList = [...analyzeList, analizedItem]
      }
    })
  })
  LineItems.map(item => {
    const resultList = createLineAnalyzedItems(value, item.regExp, item.name)
    analyzeList = [...analyzeList, ...resultList]
  })
  BothItems.map(item => {
    const resultList = createBothAnalyzedItem(
      value,
      item.regExp,
      item.name,
      item.mdValue.length
    )
    analyzeList = [...analyzeList, ...resultList]
  })
  IndependenceItems.map(item => {
    const resultList = createLineAnalyzedItems(value, item.regExp, item.name)
    analyzeList = [...analyzeList, ...resultList]
  })
  return analyzeList
}

const createLineAnalyzedItems = (
  value: string,
  item: RegExp,
  styleType: string
): MdAnalyzedItem[] => {
  let result
  let splitVal = value
  let prevPos = 0
  let resultList: MdAnalyzedItem[] = []
  while ((result = item.exec(splitVal))) {
    const startPos = result.index
    splitVal = splitVal.substr(startPos)
    const endExec = /\n/.exec(splitVal)
    const endPos = endExec ? endExec.index : splitVal.length
    const mdi: MdAnalyzedItem = {
      startPos: startPos + prevPos,
      endPos: startPos + endPos + prevPos,
      styleType: styleType,
    }
    resultList = [...resultList, mdi]
    prevPos = mdi.endPos
    splitVal = value.substr(prevPos)
  }
  return resultList
}

const createBothAnalyzedItem = (
  value: string,
  regExp: RegExp,
  styleType: string,
  trimLength: number
): MdAnalyzedItem[] => {
  let result
  let splitValue = value
  if (styleType === StyleType.ITALIC) {
    let checkRes
    let checkSplitValue = value
    let replaced = ''
    let lastEnd = 0
    const re = /(\*)\1{2,}/g
    while ((checkRes = re.exec(checkSplitValue))) {
      const startPos = checkRes.index
      const endPos = re.lastIndex
      replaced = replaced + checkSplitValue.slice(lastEnd, startPos)
      replaced = replaced + '?'.repeat(endPos - startPos)
      lastEnd = endPos
    }
    splitValue = replaced + checkSplitValue.slice(lastEnd)
  }
  let prevPos = 0
  let resultList: MdAnalyzedItem[] = []
  while ((result = regExp.exec(splitValue))) {
    const startPos = result.index
    splitValue = splitValue.slice(startPos + trimLength)
    const endExec = regExp.exec(splitValue)
    if (!endExec) {
      break
    }
    const endPos = endExec.index + trimLength * 2
    const mdi: MdAnalyzedItem = {
      startPos: startPos + prevPos,
      endPos: startPos + endPos + prevPos,
      styleType: styleType,
    }
    resultList = [...resultList, mdi]
    prevPos = mdi.endPos
    splitValue = value.slice(prevPos)
  }
  return resultList
}

const isDuplicated = (
  baseItems: MdAnalyzedItem[],
  targetItems: MdAnalyzedItem
): boolean => {
  let isAdded = false
  baseItems.map(baseItem => {
    if (
      baseItem.startPos <= targetItems.startPos &&
      targetItems.startPos <= baseItem.endPos
    ) {
      isAdded = true
      return
    }
  })
  return isAdded
}

const analyzeExcludesItems = (
  analyzedItems: MdAnalyzedItem[],
  styleType: string
): MdAnalyzedItem[] => {
  const excludes = createExcludesList(styleType)
  let results: MdAnalyzedItem[] = []
  excludes.map(item => {
    let tmpExcludes = analyzedItems.filter(
      analyze => analyze.styleType === item.name
    )
    results = [...results, ...tmpExcludes]
  })
  return results
}

const createExcludesList = (styleType: string): MarkdownItem[] => {
  let results: MarkdownItem[] = []
  switch (styleType) {
    case StyleType.HEADER_ONE:
    case StyleType.HEADER_TWO:
    case StyleType.HEADER_THREE:
      results = DuplicateCheckLineItems.filter(item => item.name !== styleType)
      results = [...results, ...LineItems]
      break
    case StyleType.BLOCKQUOTE:
    case StyleType.UNORDERED_LIST_ITEM:
    case StyleType.ORDERED_LIST_ITEM:
      results = LineItems.filter(item => item.name !== styleType)
      results = [...results, ...DuplicateCheckLineItems]
      break
    case StyleType.CODE_BLOCK:
      results = DuplicateCheckBothItems.filter(item => item.name !== styleType)
      results = [
        ...results,
        ...LineItems,
        ...DuplicateCheckLineItems,
        ...BothItems,
      ]
      break
    default:
      break
  }
  return results
}

const deduplicateList = (startList, endList) => {
  const mergeList = [...startList, ...endList]
  const duplicateList = mergeList.filter(
    value => startList.includes(value) && endList.includes(value)
  )
  const dedupSet = new Set(duplicateList)
  return Array.from(dedupSet)
}

const createIndependenceStyle = (
  value: string,
  position: number,
  styleType: string
): string => {
  let result
  const mdItem = IndependenceItems.find(item => item.name === styleType)
  if (styleType === StyleType.HORIZONTAL_RULE) {
    result = value.slice(0, position)
    if (result && result.slice(-1) !== '\n') {
      result = result + '\n'
    }
    result = result + mdItem?.mdValue
    const endValue = value.slice(position)
    if (!endValue.startsWith('\n')) {
      result = result + '\n'
    }
    result = result + endValue
  }
  return result
}

export const createTableStyle = (
  value: string,
  position: number,
  rows: number,
  columns: number
): string => {
  const mdv = TableStyleItem.mdValue
  let beforeSlice = value.slice(0, position)
  if (beforeSlice && beforeSlice.slice(-1) !== '\n') {
    beforeSlice = beforeSlice + '\n'
  }
  let afterSlice = value.slice(position)
  let index = -1
  const alignItem = ':--'
  let items = mdv
  let aligns = ''
  while (++index < columns) {
    items = items + mdv
    aligns = aligns + mdv + alignItem
  }
  items = items + '\n'
  aligns = aligns + mdv + '\n'
  const result = items + aligns + items.repeat(rows)
  return beforeSlice + result + afterSlice
}

export const deleteTableStyle = (value: string, position: number): string => {
  let beforeValue = ''
  if (position > 0) {
    const sliceValue = value.slice(0, position)
    const splitValues = sliceValue.split('\n')
    let index = splitValues.length
    while (--index >= 0) {
      if (!splitValues[index].startsWith(TableStyleItem.mdValue)) break
    }
    beforeValue = splitValues.slice(0, index + 1).join('\n')
  }
  let afterValue = ''
  const sliceValue = value.slice(position)
  const splitValues = sliceValue.split('\n')
  let index = -1
  while (++index < splitValues.length) {
    if (!splitValues[index]) continue
    if (!splitValues[index].endsWith(TableStyleItem.mdValue)) break
  }
  if (index >= splitValues.length) index = splitValues.length - 1
  afterValue = splitValues.slice(index).join('\n')
  return beforeValue + '\n' + afterValue
}

export const listItemHandler = (h, node, parent) => {
  const props: {
    [PropertyName: string]:
      | boolean
      | number
      | string
      | null
      | undefined
      | Array<string | number>
  } = {}
  const result = remarkAll(h, node)
  if (typeof node.checked === 'boolean') {
    let p
    if (
      result[0] &&
      result[0].type === 'element' &&
      result[0].tagName === 'p'
    ) {
      p = result[0]
    } else {
      p = h(null, 'p', [])
      result.unshift(p)
    }
    if (p.children.length > 0) {
      p.children.unshift(u('text', ' '))
    }
    p.children.unshift(
      h(null, 'input', {
        type: 'checkbox',
        checked: node.checked,
        disabled: true,
      })
    )
    props.className = ['task-list-item']
  }
  const wrapped: Array<ElementContent> = []
  let index = -1
  while (++index < result.length) {
    const child = result[index]
    if (index !== 0 || child.type !== 'element' || child.tagName !== 'p') {
      wrapped.push(u('text', '\n'))
    }
    if (child.type === 'element' && child.tagName === 'p') {
      let isOutput = true
      if (child.children[0]) {
        const childValue: any = child.children[0]
        const checkValue = childValue.value?.match(/\[(.)\]/)
        if (checkValue) {
          props.className = ['task-list-item']
          wrapped.push(
            h(null, 'input', {
              type: 'checkbox',
              checked: checkValue[1] === 'x',
              disabled: true,
            })
          )
          isOutput = false
        }
      }
      if (isOutput) {
        wrapped.push(...child.children)
      }
    } else {
      wrapped.push(child)
    }
  }
  const tail = result[result.length - 1]
  if (tail && (!('tagName' in tail) || tail.tagName !== 'p')) {
    wrapped.push(u('text', '\n'))
  }
  return h(node, 'li', props, wrapped)
}

export const tableHandler = (h, node, isPreview: boolean) => {
  const tbl: any = table(h, node)
  tbl?.children.map(tableChild => {
    if (tableChild.type !== 'element' || !tableChild.children) return
    tableChild.children.map(rowChild => {
      if (rowChild.type !== 'element' || !rowChild.children) return
      rowChild.children.map(cellChild => {
        if (cellChild.type !== 'element') return
        if (cellChild.children.length === 0) {
          if (isPreview) cellChild.children.push(h(node, 'br'))
        } else {
          replaceBreak(h, node, cellChild)
        }
      })
    })
  })
  return tbl
}

const replaceBreak = (h, node, child) => {
  let index = -1
  const wrapped: Array<ElementContent> = []
  while (++index < child.children.length) {
    const element = child.children[index]
    if (element.type !== 'text') {
      wrapped.push(element)
    } else {
      const replaceValues = element.value.split('  ')
      let subIndex = -1
      while (++subIndex < replaceValues.length) {
        wrapped.push(u('text', replaceValues[subIndex]))
        if (subIndex !== replaceValues.length - 1) {
          wrapped.push(h(node, 'br'))
        }
      }
    }
  }
  child.children = wrapped
}

export const escapeBreakText = (value: string, escapeValue: string): string => {
  let text = ''
  let index = -1
  let isCodeStart = false
  let isJustBeforeStyle = false
  const splitValue = value.split('\n')
  const checkList = [
    ...LineItems,
    TaskListStyleItem,
    UnorderedListStyleItem,
    TableStyleItem,
  ]
  while (++index < splitValue.length) {
    let newValue = splitValue[index]
    if (newValue.match(CodeBlockStyleItem.regExp)) isCodeStart = !isCodeStart
    if (0 < index && !isCodeStart) {
      isJustBeforeStyle = false
      let subIndex = -1
      const checkValue = splitValue[index - 1]
      while (++subIndex < checkList.length) {
        const item = checkList[subIndex]
        if (checkValue.match(item.regExp)) {
          isJustBeforeStyle = true
          break
        }
      }
    }
    if (!isCodeStart && !isJustBeforeStyle && newValue.trim().length === 0) {
      newValue = escapeValue
    }
    text = text + newValue + '\n'
  }
  return text
}

export const addImageText = (
  files: UploadedFile[],
  position: number,
  text: string,
  setText: (text: string) => void
) => {
  let strText = files.reduce((acc, value) => {
    return acc + `![](${value.url})\n`
  }, '')
  if (position > 0) {
    const beforeCursor = text.substring(0, position)
    const afterCursor = text.substring(position)
    strText = beforeCursor + strText + afterCursor
  } else {
    strText = strText + text
  }
  setText(strText)
}

export const shortcutKeyBinding = (e): string | undefined => {
  const hasCmd =
    e.metaKey || e.nativeEvent.metaKey || e.ctrlKey || e.nativeEvent.ctrlKey
  if (hasCmd) {
    if (e.shiftKey || e.nativeEvent.shiftKey) {
      switch (e.keyCode) {
        case 49:
          return StyleType.HEADER_ONE
        case 50:
          return StyleType.HEADER_TWO
        case 51:
          return StyleType.HEADER_THREE
        case 69:
          return StyleType.CODE_BLOCK
        case 190:
          return StyleType.BLOCKQUOTE
        case 56:
          return StyleType.UNORDERED_LIST_ITEM
        case 55:
          return StyleType.ORDERED_LIST_ITEM
        case 72:
          return StyleType.HORIZONTAL_RULE
        case 88:
          return StyleType.STRIKETHROUGH
        case 57:
          return StyleType.TASK_LIST
      }
    } else {
      switch (e.keyCode) {
        case 66:
          return StyleType.BOLD
        case 73:
          return StyleType.ITALIC
        case 69:
          return StyleType.CODE
      }
    }
  }
  return undefined
}
