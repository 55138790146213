import { Column, ColumnApi } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import DateVO from '../../../../../vo/DateVO'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../../../components/dialogs/MultiSelectDialog'
import { isWorkMonthColumnDef } from '../../gridOptions'
import { RowGroupColumnType } from '../../ResourcePlanCrossProjects'
import { useCallback } from 'react'

type Props = {
  onClose: () => void
  onSubmit: (colIds: string[]) => void
  rowGroupColumn: RowGroupColumnType | undefined
  columnApi?: ColumnApi | null
}

export const ResourcePlanExcelExportDialog = ({
  onClose,
  onSubmit,
  rowGroupColumn,
  columnApi,
}: Props) => {
  const getSelectionItemDisplayName = useCallback(
    (
      rowGroupColumn: RowGroupColumnType | undefined,
      columnApi: ColumnApi | undefined,
      column: Column
    ): string => {
      if (
        column.getParent()?.getColGroupDef() &&
        isWorkMonthColumnDef(column.getParent().getColGroupDef()!)
      ) {
        return new DateVO(column.getColId()).format(
          intl.formatMessage({ id: 'dateFormat.yyyy.mm' })
        )
      }
      if (!columnApi) return ''
      const displayName = columnApi?.getDisplayNameForColumn(column, null)
      const field = column.getColDef().field
      if (rowGroupColumn === RowGroupColumnType.PROJECT && field) {
        if (['body.user.code', 'body.user.division'].includes(field)) {
          return (
            displayName +
            intl.formatMessage(
              {
                id: 'resourcePlan.crossProjects.excel.export.item.suffix.format',
              },
              {
                colGroup: intl.formatMessage({
                  id: 'resourcePlan.crossProjects.member',
                }),
              }
            )
          )
        }
      }
      return displayName
    },
    []
  )

  const getSelectionList = useCallback(
    (
      rowGroupColumn: RowGroupColumnType | undefined,
      columnApi?: ColumnApi
    ): MultiSelectDialogSelection[] => {
      return (columnApi?.getColumns() || [])
        .filter((column: Column) => !['drag'].includes(column.getColId()))
        .map((column: Column) => {
          return {
            value: column.getColId(),
            displayName: getSelectionItemDisplayName(
              rowGroupColumn,
              columnApi,
              column
            ),
            defaultChecked:
              column.isVisible() ||
              column.getColDef().cellRendererParams?.uiMeta?.tree ||
              ['wbsItem.code', 'wbsItem.type', 'wbsItem.displayName'].includes(
                column.getColId()
              ),
          }
        }) as MultiSelectDialogSelection[]
    },
    [getSelectionItemDisplayName]
  )

  return (
    <MultiSelectDialog
      onClose={onClose}
      onSubmit={(allList, selectedItem) => {
        const selectedColIds = selectedItem.map(col => col.value)
        onSubmit(selectedColIds)
      }}
      dialogTitle={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.title',
      })}
      submitButtonTitle={intl.formatMessage({
        id: 'dialog.exceloutput.submit',
      })}
      allCheckBoxLabel={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
      })}
      getSelectList={() =>
        getSelectionList(rowGroupColumn, columnApi ?? undefined)
      }
      hideIcon={true}
    />
  )
}
