import 'reflect-metadata'
import { Expose, Type, Transform } from 'class-transformer'
import CodeVO from '../../value-object/CodeVO'
import ColorVO from '../../value-object/ColorVO'
import DateTimeVO from '../../value-object/DateTimeVO'
import LockVersionVO from '../../value-object/LockVersionVO'
import RevisionVO from '../../value-object/RevisionVO'
import URLVO from '../../value-object/URLVO'
import UUIDVO from '../../value-object/UUIDVO'
import DomainEntityBase from '../Base'

export class CustomEnumValue {
  @Expose()
  @Type(() => UUIDVO)
  @Transform(({ value }) => new UUIDVO(value))
  uuid: UUIDVO
  @Expose()
  @Type(() => LockVersionVO)
  @Transform(({ value }) => new LockVersionVO(value))
  lockVersion: LockVersionVO
  @Expose()
  @Type(() => RevisionVO)
  @Transform(({ value }) => new RevisionVO(value))
  revision: RevisionVO
  @Expose()
  @Type(() => UUIDVO)
  @Transform(({ value }) => (value ? new UUIDVO(value) : undefined))
  prevSiblingUuid: UUIDVO | undefined
  @Expose()
  @Type(() => UUIDVO)
  @Transform(({ value }) => (value ? new UUIDVO(value) : undefined))
  groupUuid: UUIDVO | undefined
  @Expose()
  name: string
  @Expose()
  value: string
  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => new DateTimeVO(value))
  validFrom: DateTimeVO
  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => (value ? new DateTimeVO(value) : undefined))
  validTo: DateTimeVO | undefined
  @Expose()
  @Type(() => ColorVO)
  @Transform(({ value }) => (value ? new ColorVO(value) : undefined))
  backgroundColor: ColorVO | undefined
  @Expose()
  @Type(() => ColorVO)
  @Transform(({ value }) => (value ? new ColorVO(value) : undefined))
  foregroundColor: ColorVO | undefined
  @Expose()
  @Type(() => URLVO)
  @Transform(({ value }) => (value ? new URLVO(value) : undefined))
  iconUrl: URLVO | undefined
  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => (value ? new DateTimeVO(value) : undefined))
  createdAt: DateTimeVO
  @Expose()
  @Type(() => DateTimeVO)
  @Transform(({ value }) => (value ? new DateTimeVO(value) : undefined))
  updatedAt: DateTimeVO

  toPlainValue(): {
    name: string
    value: string
    backgroundColor: string | undefined
    foregroundColor: string | undefined
    iconUrl: string | undefined
  } {
    return {
      name: this.name,
      value: this.value,
      backgroundColor: this.backgroundColor
        ? this.backgroundColor.toPlainValue()
        : undefined,
      foregroundColor: this.foregroundColor
        ? this.foregroundColor.toPlainValue()
        : undefined,
      iconUrl: this.iconUrl ? this.iconUrl.toPlainValue() : undefined,
    }
  }
}

export default class CustomEnumEntity extends DomainEntityBase {
  @Expose()
  @Type(() => CodeVO)
  @Transform(({ value }) => new CodeVO(value))
  customEnumCode: CodeVO
  @Expose()
  groupKeys: string[]
  @Expose()
  @Type(() => CustomEnumValue)
  values: CustomEnumValue[]
}
