import React, { useCallback } from 'react'
import {
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import { intl } from '../../../i18n'
import { fromSnakeToCamel } from '../../../utils/string'
import { open } from '../../router'
import { ProjectDetail } from '../../../lib/functions/project'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { colorPalette } from '../../style/colorPallete'

const RootContainer = styled('div')({
  display: 'flex',
})
const StyledTableContainer = styled(TableContainer)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  '&.MuiTableContainer-root': {
    overflowX: 'hidden',
  },
})
const StyledTable = styled(Table)({
  width: 'initial',
})
const StyledTableHeaderCell = styled(TableCell)({
  width: 60,
  maxWidth: 60,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  overflow: 'hidden',
  fontSize: 12,
  fontWeight: 400,
  color: colorPalette.monotone[4],
  borderBottom: '0px',
  padding: '0 8px',
})
const StyledTableCell = styled(TableCell)({
  width: 60,
  maxWidth: 60,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
  textAlign: 'center',
  overflow: 'hidden',
  fontSize: 14,
  fontWeight: 700,
  color: colorPalette.monotone[10],
  borderBottom: '0px',
  padding: '0',
})
const StyledLink = styled(Link)({
  cursor: 'pointer',
  color: 'inherit',
  textDecorationColor: colorPalette.monotone[10],
})

type StateProps = {
  currentProject?: ProjectDetail
}

interface Props extends StateProps {
  aggregatedValueGroups: AggregatedValueGroup
  aggregateType: AggregateField
  wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK
}

export enum AggregateValueType {
  ESTIMATED_PROGRESS_RATE = 'ESTIMATED_PROGRESS_RATE',
  PROGRESS_RATE = 'PROGRESS_RATE',
  BUDGET_AT_COMPLETION = 'BUDGET_AT_COMPLETION',
  PLANNED_VALUE = 'PLANNED_VALUE',
  EARNED_VALUE = 'EARNED_VALUE',
  DELAYED = 'DELAYED',
  PRECEDING = 'PRECEDING',
  REMAINING = 'REMAINING',
  UNPLANNED = 'UNPLANNED',
  ACTUAL_COST = 'ACTUAL_COST',
  COST_PERFORMANCE_INDEX = 'COST_PERFORMANCE_INDEX',
}

const getLabelForType = (type: AggregateValueType): string => {
  return intl.formatMessage({
    id: `aggregateInformation.${fromSnakeToCamel(type)}`,
  })
}

export const isTypeRate = (type: AggregateValueType): boolean => {
  return [
    AggregateValueType.ESTIMATED_PROGRESS_RATE,
    AggregateValueType.PROGRESS_RATE,
    AggregateValueType.COST_PERFORMANCE_INDEX,
  ].includes(type)
}

export type AggregatedValue = {
  type: AggregateValueType
  value: number
  codes?: string[]
}

export type AggregatedValueGroup = AggregatedValue[][]

const AggregateInformation = ({
  aggregateType,
  aggregatedValueGroups,
  currentProject,
}: Props) => {
  const format = useCallback(
    (
      aggregatedValue: AggregatedValue,
      aggregateTargetType: AggregateField
    ): string => {
      const value = aggregatedValue.value
      if (isTypeRate(aggregatedValue.type)) {
        if (!Number.isFinite(value) || Number.isNaN(value)) {
          return '-'
        }
        return `${Number(value * 100).toFixed(1)}%`
      }
      // @ts-ignore
      if (value === '-') return value
      return Number(value || 0).toFixed(
        aggregatedValue.type === AggregateValueType.ACTUAL_COST ||
          aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD
          ? 1
          : 0
      )
    },
    []
  )

  return (
    <RootContainer>
      {aggregatedValueGroups &&
        aggregatedValueGroups.map((grp, i) => (
          <>
            {i !== 0 && (
              <Divider
                orientation="vertical"
                sx={{
                  margin: '0 10px',
                  color: colorPalette.monotone[2],
                  width: '2px',
                  height: 'inherit',
                }}
              />
            )}
            <StyledTableContainer>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    {grp.map((v, j) => (
                      <StyledTableHeaderCell key={j}>
                        {getLabelForType(v.type)}
                      </StyledTableHeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {grp.map((v, j) => (
                      <StyledTableCell key={j}>
                        {v.codes && v.codes.length > 0 && currentProject ? (
                          <StyledLink
                            onClick={event => {
                              open(
                                `/wbsItemSearch/${
                                  currentProject.code
                                }?code=${v.codes!.join(',')}`,
                                event,
                                undefined,
                                true
                              )
                            }}
                          >
                            {format(v, aggregateType)}
                          </StyledLink>
                        ) : (
                          format(v, aggregateType)
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </>
        ))}
    </RootContainer>
  )
}

const mapStateToProps = (state: AllState) => ({
  currentProject: state.project.assigned.find(
    p => p.uuid === state.project.selected
  ),
})

export default connect(mapStateToProps)(AggregateInformation)
