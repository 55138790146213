import { styled } from '@mui/system'
import defaultTenantImage from '../../../../assets/flagxs_default_tenant_logo.png'
import { Avatar, AvatarProps } from '@mui/material'

const Icon = styled('img')({
  width: '100%',
  height: '100%',
})

const TenantAvatar = ({ src, variant, className, ...other }: AvatarProps) => {
  return (
    <Avatar
      {...other}
      src={src}
      variant={variant || 'rounded'}
      className={className}
    >
      <Icon src={defaultTenantImage} alt="" />
    </Avatar>
  )
}

export default TenantAvatar
