import { useCallback, useRef, useState } from 'react'
import { DateTerm, formatDateTime, formatMonth } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import {
  RowGroupColumnType,
  ResourcePlanCPGroupRowsByMemberColRowBody,
  ResourcePlanCPGroupRowsByProjectColRowBody,
  ResourcePlanCrossProjectType,
  ResourcePlanCrossProjectsRow,
  ResourcePlanCrossProjectsSkeleton,
  displayNameComparator,
  fetchResourcePlanCrossProjectsWorkMonths,
} from '../ResourcePlanCrossProjects'
import { intl } from '../../../../i18n'
import { generateUuid } from '../../../../utils/uuids'
import { UserDetail } from '../../../../lib/functions/user'
import { ProjectDetail } from '../../../../lib/functions/project'

const DEFAULT_SUMMARY_ROW: ResourcePlanCrossProjectsRow = {
  uuid: '',
  isTotal: true,
  type: ResourcePlanCrossProjectType.SUMMARY,
  body: {},
  treeValue: [],
}

export const useResourcePlanCrossProjectData = (
  groupColumn: RowGroupColumnType | undefined
) => {
  const rowsGroupingByMember = useRef<ResourcePlanCrossProjectsRow[]>([])
  const rowsGroupingByProject = useRef<ResourcePlanCrossProjectsRow[]>([])
  const [rowData, setRowData] = useState<ResourcePlanCrossProjectsRow[]>([])
  const [summaryRows, setSummaryRows] = useState<
    ResourcePlanCrossProjectsRow[]
  >([])

  const createRowGroupingByMember = useCallback(
    (
      source: ResourcePlanCrossProjectsSkeleton[]
    ): ResourcePlanCrossProjectsRow[] => {
      const rows: ResourcePlanCrossProjectsRow[] = []

      const sortedMember = source
        .map(s => s.user)
        .filter(
          (user: UserDetail, index: number, self: UserDetail[]) =>
            self.findIndex(u => u.uuid === user.uuid) === index
        )
        .sort((user1: UserDetail, user2: UserDetail) => {
          const compDivision = displayNameComparator(
            user1?.division,
            user2?.division
          )
          if (compDivision !== 0) return compDivision

          const compPosition = displayNameComparator(
            user1?.position,
            user2?.position
          )
          if (compPosition !== 0) return compPosition
          return user1.code.localeCompare(user2.code)
        })

      sortedMember.forEach((member: UserDetail) => {
        const targetSource = source.filter(s => s.user.uuid === member.uuid)

        // Member row
        const memberRow: ResourcePlanCrossProjectsRow = {
          uuid: member.uuid,
          type: ResourcePlanCrossProjectType.MEMBER,
          body: {
            user: member,
            project: {
              code: '',
              iconUrl: '',
              name: intl.formatMessage({ id: 'project.resourcePlan.total' }),
              uuid: generateUuid(),
            },
            role: member.role,
            validFrom: formatDateTime(member.validFrom),
            validTo: formatDateTime(member.validTo),
            validUser: targetSource[0].validUser,
            memberWorkMonths: [],
          } as ResourcePlanCPGroupRowsByMemberColRowBody,
          rowGroupColumnType: RowGroupColumnType.MEMBER,
          treeValue: [member.uuid],
        }
        rows.push(memberRow)

        // Project row
        targetSource.forEach(
          (projectSource: ResourcePlanCrossProjectsSkeleton) => {
            const projectRow: ResourcePlanCrossProjectsRow = {
              uuid: projectSource.uuid,
              type: ResourcePlanCrossProjectType.PROJECT,
              body: {
                ...memberRow.body,
                user: member,
                project: {
                  code: projectSource.project.code,
                  iconUrl: projectSource.project.iconUrl,
                  name: projectSource.project.displayName,
                  uuid: projectSource.project.uuid,
                },
                memberWorkMonths: projectSource.memberWorkMonths.map(mwm => ({
                  yearMonth: formatMonth(mwm.month),
                  manMonth: mwm.manMonth,
                })),
                resourcePlanUuid: projectSource.resourcePlanUuid,
              },
              rowGroupColumnType: RowGroupColumnType.MEMBER,
              revision: projectSource.revision,
              lockVersion: projectSource.lockVersion,
              createdAt: projectSource.createdAt,
              createdBy: projectSource.createdBy,
              updatedAt: projectSource.updatedAt,
              updatedBy: projectSource.updatedBy,
              treeValue: [memberRow.uuid, projectSource.uuid],
            }
            rows.push(projectRow)
          }
        )
      })

      return rows
    },
    []
  )

  const createRowGroupingByProject = useCallback(
    (
      source: ResourcePlanCrossProjectsSkeleton[]
    ): ResourcePlanCrossProjectsRow[] => {
      const rows: ResourcePlanCrossProjectsRow[] = []

      const sortedProject = source
        .map(s => s.project)
        .filter(
          (project: ProjectDetail, index: number, self: ProjectDetail[]) =>
            self.findIndex(p => p.uuid === project.uuid) === index
        )
        .sort((project1: ProjectDetail, project2: ProjectDetail) => {
          const compDivision = displayNameComparator(
            project1?.division,
            project2?.division
          )
          if (compDivision !== 0) return compDivision
          return project1.code.localeCompare(project2.code)
        })

      sortedProject.forEach((project: ProjectDetail) => {
        // Project row
        const projectRow: ResourcePlanCrossProjectsRow = {
          uuid: project.uuid,
          type: ResourcePlanCrossProjectType.PROJECT,
          body: {
            user: {
              code: '',
              iconUrl: '',
              name: intl.formatMessage({ id: 'project.resourcePlan.total' }),
              uuid: generateUuid(),
            } as UserDetail,
            project: project,
            memberWorkMonths: [],
          } as ResourcePlanCPGroupRowsByProjectColRowBody,
          rowGroupColumnType: RowGroupColumnType.PROJECT,
          treeValue: [project.uuid],
        }
        rows.push(projectRow)

        // Member row
        const targetSource = source.filter(s => s.project.uuid === project.uuid)
        targetSource.forEach(
          (userSource: ResourcePlanCrossProjectsSkeleton) => {
            const memberRow: ResourcePlanCrossProjectsRow = {
              uuid: userSource.uuid,
              type: ResourcePlanCrossProjectType.MEMBER,
              body: {
                ...projectRow.body,
                user: userSource.user,
                project: project,
                memberWorkMonths: userSource.memberWorkMonths.map(mwm => ({
                  yearMonth: formatMonth(mwm.month),
                  manMonth: mwm.manMonth,
                })),
                resourcePlanUuid: userSource.resourcePlanUuid,
              },
              rowGroupColumnType: RowGroupColumnType.PROJECT,
              revision: userSource.revision,
              lockVersion: userSource.lockVersion,
              createdAt: userSource.createdAt,
              createdBy: userSource.createdBy,
              updatedAt: userSource.updatedAt,
              updatedBy: userSource.updatedBy,
              treeValue: [projectRow.uuid, userSource.uuid],
            }
            rows.push(memberRow)
          }
        )
      })

      return rows
    },
    []
  )

  const switchRowDataByGroupColumn = useCallback(
    (type: RowGroupColumnType | undefined) => {
      switch (type) {
        case RowGroupColumnType.MEMBER:
          setRowData(rowsGroupingByMember.current)
          break
        case RowGroupColumnType.PROJECT:
          setRowData(rowsGroupingByProject.current)
          break
        default:
          setRowData([])
          break
      }
      setSummaryRows([DEFAULT_SUMMARY_ROW])
    },
    []
  )

  const fetchRecords = useCallback(
    async (dateTerm: DateTerm) => {
      if (!dateTerm || !dateTerm.startDate || !dateTerm.endDate) {
        return
      }
      const startDate = new DateVO(dateTerm.startDate).formatForApi()
      const endDate = new DateVO(dateTerm.endDate).formatForApi()
      if (!startDate || !endDate) {
        return
      }
      const responce: ResourcePlanCrossProjectsSkeleton[] =
        await fetchResourcePlanCrossProjectsWorkMonths({
          startDate,
          endDate,
        })
      rowsGroupingByMember.current = createRowGroupingByMember(responce)
      rowsGroupingByProject.current = createRowGroupingByProject(responce)
      switchRowDataByGroupColumn(groupColumn)
    },
    [
      groupColumn,
      createRowGroupingByMember,
      createRowGroupingByProject,
      switchRowDataByGroupColumn,
    ]
  )

  return {
    rowData,
    summaryRows,
    fetchRecords,
    switchRowDataByGroupColumn,
  }
}
