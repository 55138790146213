import { LoginContainer } from '../components'
import { styled } from '@mui/material'
import flagxs_logo_tagline_monotone from '../../../../assets/flagxs_logo_tagline_monotone.svg'
import people_at_the_table from '../../../../assets/people_at_the_table.svg'
import { intl } from '../../../../i18n'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../store'

/*
const Hogediv = styled(LoginMainDiv)({
  gap: '30px',
})
*/
const MaintenanceDetail = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
const MaintenanceTitle = styled('div')({
  fontSize: '14px',
  fontWeight: '600',

  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
const MaintenanceContent = styled('div')({
  display: 'flex',
  width: '476px',
  height: '70px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
  gap: '10px',
  backgroundColor: '#F7F8FA',
  flexDirection: 'column',
  padding: '16px',
  justifyContent: 'center',
  alignItems: 'center',
})
export const Maintenance = () => {
  const { maintenanceMessage } = useSelector((state: AllState) => ({
    maintenanceMessage: state.tenant.maintenanceMessage,
  }))
  return (
    <LoginContainer>
      <img src={people_at_the_table} width={300} height={233.82} />
      <img src={flagxs_logo_tagline_monotone} width={220} height={61.45} />
      <MaintenanceContent>
        <MaintenanceTitle>
          {intl.formatMessage({ id: 'maintenance.inProgress' })}
        </MaintenanceTitle>
        <MaintenanceDetail>{maintenanceMessage}</MaintenanceDetail>
      </MaintenanceContent>
    </LoginContainer>
  )
}
