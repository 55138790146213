import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import SearchCondition, { SearchConditionProps } from '.'
import { intl } from '../../../../../i18n'
import { CUSTOM_ENUM_NONE } from '../../../../../lib/commons/customEnum'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { AllState } from '../../../../../store'
import { CheckBoxCell } from '../../../../containers/DataCell'
import { customEnumRepository } from '../../../../../domain/repository'
import CodeVO from '../../../../../domain/value-object/CodeVO'
import { projectPrivate } from '../../../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../../../context/projectContext'

interface ComponentOwnProps extends SearchConditionProps {
  customEnumCode: string
  renderOption?: (customEnumValue: CustomEnumValue) => JSX.Element
}

const CustomEnumValueCondition = (props: ComponentOwnProps) => {
  const {
    conditionKey,
    value,
    onChange,
    customEnumCode,
    colSpan,
    renderOption,
  } = props
  const [options, setOptions] = useState<CustomEnumValue[]>([])
  const [selected, setSelected] = useState<string[]>([])
  const [rendered, setRendered] = useState<boolean>(false)
  const { projectUuid } = useProjectPrivateContext()
  const getAndSetOptions = async () => {
    const customEnum = await customEnumRepository.getByCodeAndGroupKeys(
      new CodeVO(customEnumCode),
      [projectUuid]
    )
    const values = customEnum.values.map(v => v.toPlainValue())
    if (!values.some(v => v.value === CUSTOM_ENUM_NONE)) {
      values.push({
        name: intl.formatMessage({
          id: 'none',
        }),
        value: CUSTOM_ENUM_NONE,
        foregroundColor: undefined,
        backgroundColor: undefined,
        iconUrl: undefined,
      })
    }
    setOptions(values)
  }
  const onChangeValues = useCallback(
    (values: string[]) => {
      onChange(
        values
          .map(v => options.find(option => option.value === v))
          .filter(v => !!v)
      )
    },
    [options]
  )
  useEffect(() => {
    setSelected((value || []).map(v => v.value))
  }, [value])
  useEffect(() => {
    if (!rendered) {
      getAndSetOptions()
      setRendered(true)
    }
  }, [rendered])
  return (
    <SearchCondition conditionKey={conditionKey}>
      <CheckBoxCell
        colSpan={colSpan}
        value={selected || []}
        options={options}
        onChange={onChangeValues}
        readOnly={false}
        renderOption={renderOption}
      />
    </SearchCondition>
  )
}

export default projectPrivate(CustomEnumValueCondition)
