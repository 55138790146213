import numeral from 'numeral'
import VOBase from '../base'

export default class NumberVO extends VOBase {
  private readonly value: number

  constructor(_value: number | string) {
    super()
    let tmpValue = typeof _value === 'string' ? parseFloat(_value) : _value
    if (!Number.isFinite(tmpValue)) {
      throw new Error(
        `Can not create NumberVO instance. because source value(${_value}) is invalid.`
      )
    }
    this.value = tmpValue
  }
  getValue = () => {
    return this.value
  }
  format = (formatter?: string) => {
    if (!formatter) {
      return '' + this.value
    }
    let numberValue = this.value
    if (numberValue > 0) {
      numberValue += 0.000001
    } else if (numberValue < 0) {
      numberValue -= 0.000001
    }
    try {
      const num = numeral(numberValue)
      if (num.value() === null || num.value === undefined) {
        return '' + this.value
      }
      return num.format(formatter)
    } catch {
      return '' + this.value
    }
  }
  serialize = () => this.value
}
