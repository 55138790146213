import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import { intl } from '../../../i18n'
import { getSelectionList } from './excel'
import { ColumnApi } from 'ag-grid-community'

type Props = {
  onClose: () => void
  onSubmit: (colIds: string[]) => void
  columnApi?: ColumnApi | null
  excludeColIds?: string[]
  defaultCheckColIds?: string[]
}

export const ExcelExportDialog = ({
  onClose,
  onSubmit,
  columnApi,
  excludeColIds,
  defaultCheckColIds,
}: Props) => {
  return (
    <MultiSelectDialog
      onClose={onClose}
      onSubmit={(allList, selectedItem) => {
        const selectedColIds = selectedItem.map(col => col.value)
        onSubmit(selectedColIds)
      }}
      dialogTitle={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.title',
      })}
      submitButtonTitle={intl.formatMessage({
        id: 'dialog.exceloutput.submit',
      })}
      allCheckBoxLabel={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
      })}
      getSelectList={() =>
        getSelectionList(
          columnApi ?? undefined,
          excludeColIds,
          defaultCheckColIds
        ) as MultiSelectDialogSelection[]
      }
      hideIcon={true}
    />
  )
}
