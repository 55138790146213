import { useMemo } from 'react'
import {
  ProgressOverviewPageContent,
  ProgressReportByProcessPageContent,
  ProgressReportByTeamPageContent,
  VersionReportVersionPageContent,
  VersionReportVersionPageEntity,
} from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { ProgressOverview } from './ProgressOverview'
import { PageHeader, PageRoot } from '.'
import { ProgressReportByProcess } from './ProgressReportByProcess'
import { ProgressReportByTeam } from './ProgressReportByTeam'
import {
  ProgressOverviewPageConfig,
  ProgressReportByProcessPageConfig,
  ProgressReportByTeamPageConfig,
  VersionReportPageConfig,
} from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'

type VersionReportPageProps = {
  snapshotUuid: string | undefined
  page: VersionReportVersionPageEntity | undefined
  updatePageTitle: (v: string) => void
  updateLeadSentence: (v: string) => void
  updatePageConfig: <
    K extends keyof VersionReportPageConfig,
    T extends VersionReportPageConfig[K]
  >(
    path: K,
    value: T
  ) => void
  updatePageContent: <
    K extends keyof VersionReportVersionPageContent,
    T extends VersionReportVersionPageContent[K]
  >(
    path: K,
    value: T
  ) => void
  editing: boolean
}
export const VersionReportPage = ({
  snapshotUuid,
  page,
  editing,
  updatePageTitle,
  updatePageConfig,
  updateLeadSentence,
  updatePageContent,
}: VersionReportPageProps) => {
  const component = useMemo(() => {
    if (!snapshotUuid || !page) return <></>
    if (
      page.config.type === 'PROGRESS_OVERVIEW' &&
      page.content.type === 'PROGRESS_OVERVIEW'
    ) {
      return (
        <ProgressOverview
          editing={editing}
          snapshotUuid={snapshotUuid}
          config={page.config}
          content={page.content}
          updatePageConfig={
            updatePageConfig as <
              K extends keyof ProgressOverviewPageConfig,
              V extends ProgressOverviewPageConfig[K]
            >(
              path: K,
              value: V
            ) => void
          }
          updatePageContent={
            updatePageContent as <
              K extends keyof ProgressOverviewPageContent,
              V extends ProgressOverviewPageContent[K]
            >(
              path: K,
              value: V
            ) => void
          }
        />
      )
    }
    if (
      page.config.type === 'PROGRESS_REPORT_BY_PROCESS' &&
      page.content.type === 'PROGRESS_REPORT_BY_PROCESS'
    ) {
      return (
        <ProgressReportByProcess
          editing={editing}
          snapshotUuid={snapshotUuid}
          config={page.config}
          content={page.content}
          updatePageConfig={
            updatePageConfig as <
              K extends keyof ProgressReportByProcessPageConfig,
              V extends ProgressReportByProcessPageConfig[K]
            >(
              path: K,
              value: V
            ) => void
          }
          updatePageContent={
            updatePageContent as <
              K extends keyof ProgressReportByProcessPageContent,
              V extends ProgressReportByProcessPageContent[K]
            >(
              path: K,
              value: V
            ) => void
          }
        />
      )
    }
    if (
      page.config.type === 'PROGRESS_REPORT_BY_TEAM' &&
      page.content.type === 'PROGRESS_REPORT_BY_TEAM'
    ) {
      return (
        <ProgressReportByTeam
          editing={editing}
          snapshotUuid={snapshotUuid}
          config={page.config}
          content={page.content}
          updatePageConfig={
            updatePageConfig as <
              K extends keyof ProgressReportByTeamPageConfig,
              V extends ProgressReportByTeamPageConfig[K]
            >(
              path: K,
              value: V
            ) => void
          }
          updatePageContent={
            updatePageContent as <
              K extends keyof ProgressReportByTeamPageContent,
              V extends ProgressReportByTeamPageContent[K]
            >(
              path: K,
              value: V
            ) => void
          }
        />
      )
    }
    return <></>
  }, [editing, page, snapshotUuid, updatePageConfig, updatePageContent])
  return (
    <PageRoot>
      {page && (
        <PageHeader
          pageTitle={page.pageTitle}
          leadSentence={page.leadSentence}
          onChangePageTitle={updatePageTitle}
          onChangeLeadSentence={updateLeadSentence}
          editing={editing}
        />
      )}
      {component}
    </PageRoot>
  )
}
