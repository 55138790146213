import React, { useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../store'
import { FunctionLayer } from '../../../store/functionLayer'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import HeaderBar from '../../components/headers/HeaderBar'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const Chart = styled('div')({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  overflow: 'hidden',
})

interface OwnProps {
  uuid: string
  options: ChartOptions<any, any>
  hideHeader?: boolean
}

interface StateProps {
  layer: FunctionLayer
  uuid: string
  code: string
  projectUuid?: string
}

type Props = OwnProps & StateProps

export interface ChartBaseProps {
  uuid: string
  code: string
  projectUuid?: string
  setChartState?: (state: ChartBaseState) => void
  params?: { [key: string]: any }
  ticketTypes?: WbsItemTypeVO[]
}

export interface ChartBaseState {}

export interface ChartContext<
  P extends ChartBaseProps,
  S extends ChartBaseState
> {
  props: P
  state: S
}

export abstract class ChartOptions<
  P extends ChartBaseProps,
  S extends ChartBaseState
> {
  abstract chart: React.ComponentType<P>
  toolbarItems?: (ctx: ChartContext<P, S>) => JSX.Element[]
}

const ChartContainer = (props: Props) => {
  const { options, layer, uuid, projectUuid, code, hideHeader } = props
  const chartRef = React.createRef<HTMLDivElement>()
  const [chartState, setChartState] = useState<ChartBaseState>({})

  const { ticketTypes } = useProjectPrivateContext()

  const chartElement = React.createElement(options.chart, {
    uuid,
    projectUuid,
    code,
    setChartState: (state: ChartBaseState) => {
      setChartState(state)
    },
    params: layer.params,
    ticketTypes,
  })
  return (
    <RootContainer>
      {!hideHeader && <HeaderBar depth={0} />}
      {options.toolbarItems && (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {options.toolbarItems({
            props: chartElement.props,
            state: chartState,
          })}
        </Box>
      )}
      <Chart ref={chartRef}>{chartElement}</Chart>
    </RootContainer>
  )
}

const mapStateToProps = (state: AllState) => {
  const layer = state.functionLayer.layers.get(state.functionLayer.currentDepth)
  const func = state.appFunction.functions.find(
    f => f.externalId === layer?.externalId
  )
  return {
    projectUuid: state.project.selected,
    uuid: func!.uuid,
    code: layer!.code!,
    layer: layer!,
  }
}

export default connect(mapStateToProps)(projectPrivate(ChartContainer))
