import {
  Avatar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import React, { CSSProperties } from 'react'
import { getWbsItemStatusLabel } from '../../../lib/functions/wbsItem'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import {
  generateToolBarItemKey,
  ToolbarDivider,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import { intl } from '../../../i18n'
import WorkloadVO from '../../../vo/WorkloadVO'
import ToolBarItem from './ToolBarItem'
import EntitySearchVO from '../../../vo/EntitySearchVO'
import NumberInput from './Input/NumberInput'
import { CellGroupDef } from '../../containers/meta/ViewMeta/SingleSheetViewMeta'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { TicketData } from '../Ticket'
import TaskActualWorkRegistrationButton from './TaskActualWorkRegistrationButton'
import DateInput from './Input/DateInput'
import { DelayIcon } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/delayIcon'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

const StyledAvatar = styled(Avatar)({
  margin: 0,
  width: 20,
  height: 20,
})
const StyledTable = styled(Table)({
  width: 'auto',
})
const toolbarDateTableCellStyle: CSSProperties = {
  width: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  border: 'none',
  wordBreak: 'break-all',
  padding: 0,
  fontSize: 12,
}
const ToolbarDateTableCell = styled(TableCell)({
  ...toolbarDateTableCellStyle,
})
const ToolbarTimeTableCell = styled(TableCell)({
  ...toolbarDateTableCellStyle,
  textAlign: 'right',
})

interface Props {
  item: any
  status: WbsItemStatus
  ctx: any
  cumulation: ProjectPlanCumulation
  cellDefs: CellGroupDef[]
  prefix?: string
  ticketItem?: TicketData
}

const unset = intl.formatMessage({ id: 'singleSheet.header.unset' })

const DelayIconDiv = styled('div')({
  display: 'flex',
  marginLeft: '-4px',
})

class WbsItemToolBar extends React.Component<Props> {
  getSubstatus = () => {
    const substatus = this.props.item.substatus
    return (
      <Typography variant={'subtitle2'}>
        {!substatus || substatus.getValue() === 'NONE'
          ? unset
          : substatus.format()}
      </Typography>
    )
  }

  getPriority = () => {
    let priority: any = this.props.item.priority
    if (!priority || priority.getValue() === 'NONE') {
      return <Typography variant={'subtitle2'}>{unset}</Typography>
    }
    return <StyledAvatar src={priority.getIconUrl()} />
  }

  getTeam = (team: EntitySearchVO) => {
    if (!team) {
      return <Typography variant={'subtitle2'}>{unset}</Typography>
    }
    return (
      <>
        <StyledAvatar src={team.getIconUrl()} />
        <Typography
          variant={'subtitle2'}
          style={{
            marginLeft: '5px',
          }}
        >
          {team.format()}
        </Typography>
      </>
    )
  }

  getUserTooltip = (user: EntitySearchVO, tooltipTitle: string) => {
    return user ? tooltipTitle + ' : ' + user.format() : tooltipTitle
  }

  getUserLabel = (user: EntitySearchVO) => {
    let element: JSX.Element = (
      <Typography variant={'subtitle2'}>{unset}</Typography>
    )
    if (user) {
      element = <StyledAvatar src={user.getIconUrl()} />
    }
    return element
  }

  getActualHours = () => {
    if (!this.props.item.wbsItemType) return 0

    const wbsItemType: WbsItemTypeVO = this.props.item.wbsItemType
    return wbsItemType.isTask()
      ? this.props.cumulation.actualHour
      : this.props.cumulation.sumActualHour
  }

  getEstimatedHours = () => {
    let hours = '0'
    if (this.props.item.estimatedHour instanceof WorkloadVO) {
      hours = this.props.item.estimatedHour.getValue()
    }
    return hours
  }

  getDefs = (key: string): any => {
    let retDefs
    if (!this.props.cellDefs) {
      return undefined
    }
    const cellKey = this.props.prefix ? this.props.prefix + key : key
    for (let i = 0; i < this.props.cellDefs.length; i++) {
      const parent = this.props.cellDefs[i]
      retDefs = parent.children.find(child => child?.cellId === cellKey)
      if (retDefs) break
    }
    return retDefs
  }

  render() {
    const { item, prefix, ticketItem, ctx } = this.props
    const optionalUuid = ctx.options.uuidForOptionalFunction(ctx.state.data)
    const taskUuid =
      typeof optionalUuid === 'object' ? optionalUuid[0] : optionalUuid
    const toolbarProps = ctx.options.getToolbarProps(ctx)
    return (
      <>
        <TaskActualWorkRegistrationButton
          {...toolbarProps}
          key={generateToolBarItemKey(1, ToolBarItemPosition.START)}
          inProgress={ctx.state.inProgress}
          taskUuid={taskUuid}
          externalId={ctx.viewMeta.externalId}
        />
        <ToolBarItem
          tooltipTitle={intl.formatMessage({
            id: 'singleSheet.header.status',
          })}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'status' : 'status'}
          defs={this.getDefs('status')}
          value={item.status}
          displayIcon={false}
        >
          {getWbsItemStatusLabel(WbsItemStatus[item.status.getValue()])}
        </ToolBarItem>
        <ToolBarItem
          tooltipTitle={intl.formatMessage({
            id: 'singleSheet.header.substatus',
          })}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'substatus' : 'substatus'}
          defs={this.getDefs('substatus')}
          value={item.substatus}
          displayIcon={false}
        >
          {this.getSubstatus()}
        </ToolBarItem>
        <ToolbarDivider key="wbs-item-toolbar-divider-1" />
        <ToolBarItem
          tooltipTitle={intl.formatMessage({
            id: 'singleSheet.header.priority',
          })}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'priority' : 'priority'}
          defs={this.getDefs('priority')}
          value={item.priority}
          displayIcon={true}
        >
          {this.getPriority()}
        </ToolBarItem>
        <ToolbarDivider key="wbs-item-toolbar-divider-2" />
        <ToolBarItem
          tooltipTitle={intl.formatMessage({
            id: 'singleSheet.header.team',
          })}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'team' : 'team'}
          defs={this.getDefs('team')}
          value={item.team}
        >
          {this.getTeam(item.team)}
        </ToolBarItem>
        <ToolBarItem
          tooltipTitle={this.getUserTooltip(
            item.accountable,
            intl.formatMessage({
              id: 'singleSheet.header.accountable',
            })
          )}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'accountable' : 'accountable'}
          defs={this.getDefs('accountable')}
          value={item.accountable}
        >
          {this.getUserLabel(item.accountable)}
        </ToolBarItem>
        <ToolBarItem
          tooltipTitle={this.getUserTooltip(
            item.responsible,
            intl.formatMessage({
              id: 'singleSheet.header.responsible',
            })
          )}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'responsible' : 'responsible'}
          defs={this.getDefs('responsible')}
          value={item.responsible}
        >
          {this.getUserLabel(item.responsible)}
        </ToolBarItem>
        <ToolBarItem
          tooltipTitle={this.getUserTooltip(
            item.assignee,
            intl.formatMessage({
              id: 'singleSheet.header.assignee',
            })
          )}
          data={ticketItem ? ticketItem : item}
          storeKey={prefix ? prefix + 'assignee' : 'assignee'}
          defs={this.getDefs('assignee')}
          value={item.assignee}
        >
          {this.getUserLabel(item.assignee)}
        </ToolBarItem>
        <ToolbarDivider key="wbs-item-toolbar-divider-3" />
        <StyledTable>
          <TableBody>
            <TableRow>
              <ToolbarDateTableCell>
                {intl.formatMessage({
                  id: 'singleSheet.header.scheduled',
                })}
              </ToolbarDateTableCell>
              <ToolbarDateTableCell>
                <DateInput
                  tooltipTitle={intl.formatMessage({
                    id: 'singleSheet.header.scheduled',
                  })}
                  data={ticketItem ? ticketItem : item}
                  storeKey={
                    prefix
                      ? prefix + 'scheduledDate.startDate'
                      : 'scheduledDate.startDate'
                  }
                  defs={this.getDefs('scheduledDate')}
                  value={
                    item.scheduledDate
                      ? item.scheduledDate.getStartDate()
                      : undefined
                  }
                />
              </ToolbarDateTableCell>
            </TableRow>
            <TableRow>
              <ToolbarDateTableCell>
                {intl.formatMessage({
                  id: 'singleSheet.header.actual',
                })}
              </ToolbarDateTableCell>
              <ToolbarDateTableCell>
                <DateInput
                  tooltipTitle={intl.formatMessage({
                    id: 'singleSheet.header.actual',
                  })}
                  data={ticketItem ? ticketItem : item}
                  storeKey={
                    prefix
                      ? prefix + 'actualDate.startDate'
                      : 'actualDate.startDate'
                  }
                  defs={this.getDefs('actualDate')}
                  value={
                    item.actualDate ? item.actualDate.getStartDate() : undefined
                  }
                />
              </ToolbarDateTableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
        <StyledTable>
          <TableBody>
            <TableRow>
              <ToolbarDateTableCell>～</ToolbarDateTableCell>
              <ToolbarDateTableCell>
                <DateInput
                  tooltipTitle={intl.formatMessage({
                    id: 'singleSheet.header.scheduled',
                  })}
                  data={ticketItem ? ticketItem : item}
                  storeKey={
                    prefix
                      ? prefix + 'scheduledDate.endDate'
                      : 'scheduledDate.endDate'
                  }
                  defs={this.getDefs('scheduledDate')}
                  value={
                    item.scheduledDate
                      ? item.scheduledDate.getEndDate()
                      : undefined
                  }
                />
              </ToolbarDateTableCell>
            </TableRow>
            <TableRow>
              <ToolbarDateTableCell>～</ToolbarDateTableCell>
              <ToolbarDateTableCell>
                <DateInput
                  tooltipTitle={intl.formatMessage({
                    id: 'singleSheet.header.actual',
                  })}
                  data={ticketItem ? ticketItem : item}
                  storeKey={
                    prefix
                      ? prefix + 'actualDate.endDate'
                      : 'actualDate.endDate'
                  }
                  defs={this.getDefs('actualDate')}
                  value={
                    item.actualDate ? item.actualDate.getEndDate() : undefined
                  }
                />
              </ToolbarDateTableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
        <DelayIconDiv>
          <DelayIcon
            fontSize={20}
            wbsItem={{
              ...item,
              scheduledDate: {
                startDate: item.scheduledDate
                  ? item.scheduledDate.getStartDate()
                  : undefined,
                endDate: item.scheduledDate
                  ? item.scheduledDate.getEndDate()
                  : undefined,
              },
              actualDate: {
                startDate: item.actualDate
                  ? item.actualDate.getStartDate()
                  : undefined,
                endDate: item.actualDate
                  ? item.actualDate.getEndDate()
                  : undefined,
              },
            }}
          />
        </DelayIconDiv>
        <StyledTable>
          <TableBody>
            <TableRow>
              <ToolbarTimeTableCell>
                <NumberInput
                  data={ticketItem ? ticketItem : item}
                  storeKey={prefix ? prefix + 'estimatedHour' : 'estimatedHour'}
                  defs={this.getDefs('estimatedHour')}
                  value={this.getEstimatedHours()}
                />
              </ToolbarTimeTableCell>
              <ToolbarDateTableCell>
                {intl.formatMessage({
                  id: 'singleSheet.header.hours',
                })}
              </ToolbarDateTableCell>
            </TableRow>
            <TableRow>
              <ToolbarTimeTableCell>
                {this.getActualHours()}
              </ToolbarTimeTableCell>
              <ToolbarDateTableCell>
                {intl.formatMessage({
                  id: 'singleSheet.header.hours',
                })}
              </ToolbarDateTableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </>
    )
  }
}

export default WbsItemToolBar
