import { useCallback, useMemo } from 'react'
import { useProjectMemberRepository } from '../../../../services/adaptors/projectMemberRepositoryAdaptor'
import { useSprintRepository } from '../../../../services/adaptors/sprintRepositoryAdaptor'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import { WbsItemFormModel } from '../model'

export const useWbsItemPropertySearch = (
  projectUuid: string,
  formModel: WbsItemFormModel
) => {
  const { fetchListAsReferencedEntity: fetchTeams } = useTeamRepository()
  const searchTeam = useCallback(async () => {
    const candidates = await fetchTeams(projectUuid)
    return candidates
  }, [fetchTeams, projectUuid])
  const { searchAsReferencedEntity: searchProjectMembers } =
    useProjectMemberRepository()
  const searchMember = useCallback(
    async (searchText: string) => {
      return searchProjectMembers(projectUuid, searchText)
    },
    [projectUuid, searchProjectMembers]
  )
  const { fetchActiveAsReferencedEntity: fetchSprints } = useSprintRepository()
  const searchSprint = useCallback(
    async (searchText: string) => {
      if (!formModel.team) return []
      const candidates = await fetchSprints(formModel.team.uuid)
      return candidates.filter(sprint => sprint.name.includes(searchText))
    },
    [fetchSprints, formModel.team]
  )
  const functions = useMemo(
    () => ({
      searchTeam,
      searchMember,
      searchSprint,
    }),
    [searchMember, searchSprint, searchTeam]
  )
  return functions
}
