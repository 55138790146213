import {
  ColDef,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { WbsItemAdditionalPropertyEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import { ColumnType } from '../../../../commons/AgGrid'
import { DateTimeCellEditor } from '../../../components/cellEditor'
import { formatDateTime } from '../../../../../../utils/date'
import { wbsItemAdditionalPropertyValuesVoService } from '../../../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { DateFilter, ServerSideDateFilter } from '../../../components/filter'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'

export const datetimeColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  column.type = [ColumnType.dateTime]
  column.width = 175
  column.cellEditor = DateTimeCellEditor
  column.valueGetter = (params: ValueGetterParams) => {
    const wbsItemAdditionalPropertyValues = option.getAdditionalPropertyValues(
      params.data
    )
    if (!wbsItemAdditionalPropertyValues) return undefined
    const value = wbsItemAdditionalPropertyValuesVoService.getValue(
      wbsItemAdditionalPropertyValues,
      wbsItemAdditionalProperty.uuid
    )?.value
    return typeof value === 'string' ? Number(value) : value
  }
  column.valueFormatter = (params: ValueFormatterParams) =>
    formatDateTime(params.value) ?? ''
  setFilterParams(column, wbsItemAdditionalProperty, option)
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = 'dateCellFilter'
      column.floatingFilter = !option?.disableFloatingFilter
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideDateFilter
      column.floatingFilter = !option?.disableFloatingFilter
      column.filterParams = {
        fetch: (v: DateFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                operator: v.operator,
                value: v.value,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
