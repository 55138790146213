import { Tree } from '../commons/tree'
import API, { APIResponse } from '../commons/api'
import { UserDetail } from './user'
import { TeamProps } from './team'

export interface ScheduledOperationTimeDetail
  extends Tree<ScheduledOperationTimeDetail> {
  user: UserDetail
  team: TeamProps
  projectReleasedDate: string
  memberWorkDates: MemberWorkDateDetail[]
}

export interface MemberWorkDateDetail {
  uuid: string
  lockVersion: number
  date: Date
  hour: number
  // Show whether the cell is completed in server side.
  edited: boolean
}

export interface GetScheduledOperationTimeProps {
  projectUuid: string
  year: number
  month: number
}

export interface GetScheduledOperationTimeProjectToalRequest {
  userUuid: string
  startDate: string
  endDate: string
}

export interface ScheduledOperationTimeProjectTotal {
  projectUuid: string
  scheduledHour: number
}

export interface ScheduledOperationTimeUpdateBatchRequest {
  setMemberWorkDates: SetMemberWorkDateInput[]
  deleteMemberWorkDates: DeleteMemberWorkDateInput[]
}

export interface SetMemberWorkDateInput {
  lockVersion?: number
  projectUuid: string
  userUuid: string
  year: number
  month: number
  date: number
  hour: number
}

export interface DeleteMemberWorkDateInput {
  lockVersion?: number
  projectUuid: string
  userUuid: string
  year: number
  month: number
  date: number
}

export async function getScheduledOperationTime(
  request: GetScheduledOperationTimeProps
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/scheduled_operation_time',
    request
  )
}

export async function getScheduledOperationTimeProjectTotal(
  request: GetScheduledOperationTimeProjectToalRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/scheduled_operation_time/project_total',
    request
  )
}

export async function updateBatch(
  request: ScheduledOperationTimeUpdateBatchRequest
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/scheduled_operation_time/batch',
    request
  )
}
