import ViewMeta, { SubmitType } from '.'
import {
  Component,
  CustomEnumCombinationDirection,
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { filterByCombination } from '../../../../lib/commons/customEnum'
import objects from '../../../../utils/objects'
import SelectVO from '../../../../vo/SelectVO'
import { SingleSheetOptions } from '../../SingleSheet'
import EntitySearchVO from '../../../../vo/EntitySearchVO'

export interface CellGroupDef {
  groupId: string
  headerName: string
  children: CellDef[]
}

export interface CellPosition {
  row: number
  column: number
  size: number
}

export interface CellDef {
  cellId: string
  type: PropertyType
  component: Component
  headerName: string
  cellPosition: CellPosition
  required: boolean | ((data: any) => boolean)
  editable: boolean | ((data: any) => boolean)
  placeholder?: string
  tooltip?: string
  valueGetter: (data: any) => any
  getChangeValue: (
    value: any,
    data: any,
    cellDef: CellDef
  ) => { [key: string]: any }
  uiMeta: FunctionProperty
  viewMeta: SingleSheetViewMeta
  error?: string[]
  getStyle?: (data: any, target: string) => any
}

export default class SingleSheetViewMeta extends ViewMeta {
  getDefaultState = async (
    submitType: SubmitType = SubmitType.Create,
    options: SingleSheetOptions<any>
  ) => {
    // TODO: fix
    await this.getOrFetchFunctionMeta(submitType)
    const cellDefs = this.generateCellDefs(submitType, options)
    const cellGroupDefs = this.structCellGroupDefs(cellDefs)
    return {
      cellDefs: cellGroupDefs,
    }
  }

  private structCellGroupDefs = (cellDefs: CellDef[]): CellGroupDef[] => {
    const result: CellGroupDef[] = []
    const childrenList = {}
    this.functionMeta.parentPropertyIds.forEach(parentId => {
      if (!parentId) {
        return
      }
      const parent = this.functionMeta.properties.byId.get(parentId)!
      const group = {
        groupId: this.makeDataPropertyName(parent),
        headerName: parent.name,
        children: [],
      }
      result[parent.propertyOrder] = group
      childrenList[parent.externalId] = group.children
    })
    for (let i = 0; i < cellDefs.length; i++) {
      const cellDef = cellDefs[i]
      const uiMeta = cellDef.uiMeta
      if (!uiMeta.parentProperty) {
        continue
      }
      childrenList[uiMeta.parentProperty][uiMeta.propertyOrder] = cellDef
    }
    return result
  }

  private generateCellDefs = (
    submitType: SubmitType,
    options: SingleSheetOptions<any>
  ): CellDef[] => {
    const props = this.functionMeta.detail.properties
      .filter(prop => this.isGridProperty(prop))
      .filter(prop => this.predicateHiddenProperties(submitType)(prop))
      .sort((a, b) => {
        if (a.propertyLayout > b.propertyLayout) return 1
        if (a.propertyLayout < b.propertyLayout) return -1
        return 0
      })
    let cellDefs: CellDef[] = []
    props.forEach(prop =>
      cellDefs.push(this.generateCellDef(prop, submitType, options))
    )
    return cellDefs
  }

  private predicateHiddenProperties =
    (submitType?: SubmitType) =>
    (prop: FunctionProperty): boolean => {
      if (!submitType) {
        return true
      }
      if (submitType === SubmitType.Create) {
        return !prop.hiddenIfC || !prop.hiddenIfC.isTrue
      }
      if (submitType === SubmitType.Update) {
        return !prop.hiddenIfC || !prop.hiddenIfU.isTrue
      }
      return true
    }

  private getValueGetter = (
    prop: FunctionProperty,
    options?: SingleSheetOptions<any>
  ) => {
    if (prop.entityExtensionUuid) {
      return data => {
        if (!data) {
          return undefined
        }
        const extensions = data.extensions
        if (!extensions) {
          return undefined
        }
        const extension = extensions.find(
          e => e.uuid === prop.entityExtensionUuid
        )
        if (!extension) {
          return undefined
        }
        return extension.value
      }
    }
    return data => objects.getValue(data, this.makeDataPropertyName(prop))
  }

  private getChangeValue = (
    prop: FunctionProperty,
    options?: SingleSheetOptions<any>
  ) => {
    let newValueMap: { [key: string]: any } = {}
    return (value, data, cellDef) => {
      if (prop.entityExtensionUuid) {
        return this.setEntityExtension(value, data, prop)
      }
      if (prop.propertyType === PropertyType.Select) {
        newValueMap = this.getCombinationChangeValue(value, data, prop)
      }
      if (this.makeDataPropertyName(prop)) {
        objects.setValue(data, this.makeDataPropertyName(prop), value)
        newValueMap[this.makeDataPropertyName(prop)] = value
      }
      return this.setCellChangeValue(value, cellDef, newValueMap)
    }
  }

  private setEntityExtension = (
    value: any,
    data: any,
    prop: FunctionProperty
  ): { [key: string]: any } => {
    let newValueMap: { [key: string]: any } = {}
    const entityExtensionUuid = prop.entityExtensionUuid
    if (!data.extensions) {
      data.extensions = []
    }
    const entityExtension = data.extensions.find(
      v => v.uuid === entityExtensionUuid
    )
    if (!entityExtension) {
      data.extensions.push({
        uuid: entityExtensionUuid,
        value: value,
      })
    } else {
      entityExtension.value = value
    }
    newValueMap['extensions'] = data.extensions
    return newValueMap
  }

  private getCombinationChangeValue = (
    value: any,
    data: any,
    prop: FunctionProperty
  ): { [key: string]: any } => {
    let newValueMap: { [key: string]: any } = {}
    if (!value) {
      objects.setValue(data, this.makeDataPropertyName(prop), value)
      return newValueMap
    }
    const valuesAllowed = prop.valuesAllowed
    const getValueAtPath = (path: string) => objects.getValue(data, path)
    const selectedValue = filterByCombination(
      valuesAllowed,
      getValueAtPath
    ).find(enumValue => enumValue.value === value.getValue())
    if (!selectedValue) {
      return newValueMap
    }
    objects.setValue(data, this.makeDataPropertyName(prop), value)
    if (!selectedValue.combinations) {
      return newValueMap
    }
    selectedValue.combinations
      .filter(
        combination =>
          combination.direction === CustomEnumCombinationDirection.BIDIRECTION
      )
      .forEach(combination => {
        const combinedEnumPath = combination.combinedEnumPath
        if (!combinedEnumPath || !combination.combinedValues) {
          return
        }
        const combinedProp = this.functionMeta.detail.properties.find(
          p => p.externalId === this.toExternalId(combinedEnumPath)
        )
        if (!combinedProp) {
          return
        }
        const filteredOptions = filterByCombination(
          combinedProp.valuesAllowed,
          getValueAtPath
        )
        const options = combination.combinedValues.filter(val =>
          filteredOptions.some(option => option.value === val.value)
        )
        if (options.length === 0) {
          return
        }
        objects.setValue(
          data,
          combinedEnumPath,
          SelectVO.fromCustomEnum(options[0])
        )
        const newValue = SelectVO.fromCustomEnum(options[0]).getValue()
        newValueMap[combinedEnumPath] = newValue
      })
    return newValueMap
  }

  private setCellChangeValue = (
    value: any,
    cellDef: CellDef,
    newValueMap: { [key: string]: any }
  ) => {
    let newValue: any = undefined
    if (value) {
      if (value instanceof EntitySearchVO) {
        newValue = value.getJson()
      } else {
        newValue = value.getValue()
      }
    }
    newValueMap[cellDef.cellId] = newValue
    return newValueMap
  }

  private generateCellDef = (
    prop: FunctionProperty,
    submitType: SubmitType,
    options: SingleSheetOptions<any>
  ): CellDef => {
    const singleSheetPropertyRegExp = new RegExp(
      /^(\d+)(?:-(\d+))?(?:-(\d+))?(?:\:(\d+))?$/
    )
    const position = singleSheetPropertyRegExp.exec(prop.propertyLayout)
    if (!position) {
      throw new Error('Illegal property layout.')
    }
    const row = Number(position[2] || position[1])
    const column = Number(position[3] || 1)
    const maxColCount = Number(position[4] || 2)
    const styleRule =
      options.getStyleRule && options.getStyleRule(prop.externalId)

    return {
      cellId: this.makeDataPropertyName(prop),
      type: prop.propertyType,
      component: prop.component,
      headerName: prop.name,
      cellPosition: {
        row,
        column,
        size: 2 / maxColCount,
      },
      valueGetter: this.getValueGetter(prop),
      getChangeValue: this.getChangeValue(prop),
      // TODO: use operator.
      required: prop.requiredIf.isTrue,
      editable:
        submitType === SubmitType.Create
          ? prop.editableIfC.isTrue
          : prop.editableIfU.isTrue,
      placeholder: prop.placeHolder,
      tooltip: prop.tooltipText,
      uiMeta: prop,
      viewMeta: this,
      getStyle: styleRule,
    } as CellDef
  }
}
