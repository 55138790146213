export const weekDayArray = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
] as const
export const weekendArray = ['SATURDAY', 'SUNDAY'] as const
export const dayOfWeekArray = [...weekDayArray, ...weekendArray] as const

export type WeekDay = (typeof weekDayArray)[number]
export type Weekend = (typeof weekendArray)[number]
export type DayOfWeek = (typeof dayOfWeekArray)[number]
