import uiStates, {
  UiStateKey,
  UiStateScope,
} from './../../../lib/commons/uiStates'
import { useEffect, useState } from 'react'
import { RequestOfGetStates } from '../../../lib/commons/uiStates'
import { getUrlQueryObject } from '../../../utils/urls'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import Project from '../../../lib/functions/project'
import { loginToProject } from '../../../store/project'
import store from '../../../store'
import objects from '../../../utils/objects'

export const fetchProjectUuid = async (
  code: string | undefined
): Promise<string> => {
  const response = code ? await Project.getBasicByCode(code) : undefined
  const projectUuid = response ? response.json.uuid : ''
  if (projectUuid) {
    store.dispatch(loginToProject(projectUuid))
  }
  return projectUuid
}

export const fetchChartCondition = async <C>(
  translate: (query: any, savedUIState?: any) => C,
  uiStateKey: UiStateKey,
  projectUuid: string = '',
  params?: { [key: string]: any }
): Promise<C | undefined> => {
  const { arg, ...other } = params
    ? objects.unflatten(params)
    : getUrlQueryObject()
  const savedUiStateCode = arg
  if (savedUiStateCode) {
    const savedUiState = await getConditionStateByStateCode(
      savedUiStateCode,
      uiStateKey,
      projectUuid
    )
    if (savedUiState) {
      return translate({ ...other }, savedUiState.UIState)
    }
  } else {
    return translate({ ...other })
  }
  return undefined
}

const getConditionStateByStateCode = async (
  conditionStateCode: string,
  uiStateKey: UiStateKey,
  projectUuid: string = ''
): Promise<SavedUIState | undefined> => {
  const request: RequestOfGetStates = {
    applicationFunctionUuid: '',
    key: `${uiStateKey}-${projectUuid}`,
    scope: UiStateScope.Tenant,
  }
  const response = await uiStates.get(request)
  const uiState = response.json
  const searchConditionStates = uiState.value
    ? (JSON.parse(uiState.value) as SavedUIState[])
    : []
  return searchConditionStates.find(v => v.code === conditionStateCode)
}
