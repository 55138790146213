import { ColDef } from 'ag-grid-community'
import { DateTerm } from '../../../../../utils/date'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { MonthRangeSelector } from '../../../../components/headers/HeaderBar/MonthRangeSelector'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import { Box } from '@mui/material'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import ExclusiveToggleGroup from '../../../../components/editors/toggle/ExclusiveToggleGroup'
import objects from '../../../../../utils/objects'
import { PredictionType } from '../../ProfitLossSummary'
import { intl } from '../../../../../i18n'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

interface OwnProps {
  loading: boolean
  dateTerm: DateTerm
  rowHeight: number
  predictionType: string | undefined
  onSearch: (dateTerm: DateTerm) => void
  onReload: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn: (column: ColDef) => void
  resetFilters: () => void
  sortColumnState: SortedColumnState[]
  onDeleteSortedColumn: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  columnSettingOpen: boolean
  onClickColumnSettingButton: (e) => void
  onChangeRowHeight: (value: number) => void
  onClickExport: () => void
  onChangePredictionType: (value: string | undefined) => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps

const ProfitLossSummaryHeader = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 10px 5px 10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <Button
        onClick={props.onReload}
        disabled={props.loading}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      <div>
        <MonthRangeSelector
          dateTerm={props.dateTerm}
          onSearch={props.onSearch}
        />
      </div>
      <div style={{ paddingRight: '5px' }}>
        <ExclusiveToggleGroup
          value={props.predictionType}
          options={Object.values(PredictionType).map(v => ({
            label: intl.formatMessage({
              id: `profitLoss.prediction.type.${v}`,
            }),
            value: v,
          }))}
          onChange={props.onChangePredictionType}
        />
      </div>
      <FilterButton
        filteredColumns={props.filteredColumns}
        onDeleteFilteredColumn={props.onDeleteFilteredColumn}
        resetFilters={props.resetFilters}
      />
      <SortButton
        sortColumnsState={props.sortColumnState}
        onDeleteSortedColumn={props.onDeleteSortedColumn}
        onDeleteSortedAllColumns={props.onDeleteSortedAllColumns}
        onChangeSortColumnState={props.onChangeSortColumnState}
      />
      <ColumnSettingButton
        open={props.columnSettingOpen}
        onClick={props.onClickColumnSettingButton}
      />
      <DisplayFormatButton
        height={props.rowHeight}
        onChangeHeight={props.onChangeRowHeight}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <FileExportButton onClickExport={props.onClickExport} />
      </Box>
    </div>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(ProfitLossSummaryHeader)
