import { PermanentUiState } from '../model/uiState'

type PermanentUiStateBefore20220208<T> = Omit<PermanentUiState<T>, 'value'> & {
  UIState: T
}

export const adaptPermanentUiStateBefore20220208 = <T>(
  src: PermanentUiStateBefore20220208<T> | PermanentUiState<T>
): PermanentUiState<T> => {
  if ('value' in src) {
    return src
  }
  return {
    uuid: src.uuid,
    code: src.code,
    name: src.name,
    scope: src.scope,
    value: src.UIState,
    updatedBy: src.updatedBy,
    updatedAt: src.updatedAt,
  }
}
