import { useCallback } from 'react'
import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { ProfitLossMemberRow } from '../ProfitLossMembers'

export const useExportExcel = (gridOptions: GridOptions): (() => void) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined

      const row: ProfitLossMemberRow = params?.node?.data
      const colId: string = params.column.getColId()
      if (colId === 'body.generalLedger') {
        if (!row?.body?.type) {
          return row?.body?.generalLedger?.displayName
        }
      } else if (colId === 'body.subsidiary') {
        if (!row?.body?.type) {
          return row?.body?.subsidiary?.displayName
        }
      } else if (colId === 'body.financialState') {
        if (!row?.body?.type) {
          return row?.body?.financialState?.displayName
        }
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'profit_loss_members',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      getCustomExportValue: customExportValues,
    })
  }, [gridOptions, customExportValues])

  return onExportExcel
}
