import _ from 'lodash'
import { intl } from '../../i18n'
import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import { ProjectMemberProps } from './projectMember'
import { AggregateField } from '../../domain/entity/WbsItemEntity'

export interface SprintReport extends Tree<SprintReport> {
  member: ProjectMemberProps
  key: SprintReportKey
  values: { [key: string]: SprintReportValue }
}

export interface FractionValue {
  numerator: number
  denominator: number
}

export interface SprintReportValue {
  workloadBy: FractionValue
  countBy: FractionValue
  wbsItemCodes: string[]
}

export interface SprintReportSummarizeValue {
  numerator: number
  denominator: number
  wbsItemCodes: string[]
}

export const summarize = (
  values: SprintReportValue[],
  aggregateField: AggregateField
): SprintReportSummarizeValue => {
  const sum: SprintReportSummarizeValue = {
    numerator: 0,
    denominator: 0,
    wbsItemCodes: [] as string[],
  }
  values.forEach(v => {
    const value: FractionValue =
      aggregateField === AggregateField.WBS_ITEM_COUNT
        ? v.countBy
        : v.workloadBy
    sum.numerator = sum.numerator + value.numerator
    sum.denominator = sum.denominator + value.denominator
    sum.wbsItemCodes = _.union(sum.wbsItemCodes, v.wbsItemCodes || [])
  })
  return sum
}

export enum SprintReportGroupKey {
  SCHEDULED_START_DATE = 'SCHEDULED_START_DATE',
  SCHEDULED_END_DATE = 'SCHEDULED_END_DATE',
}

export function getSprintReportGroupKeyLabel(
  key: SprintReportGroupKey
): string {
  switch (key) {
    case SprintReportGroupKey.SCHEDULED_START_DATE:
      return intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.scheduledStartDate',
      })
    case SprintReportGroupKey.SCHEDULED_END_DATE:
      return intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.scheduledEndDate',
      })
    default:
      return key
  }
}

export enum SprintReportKey {
  TODO = 'TODO',
  DOING = 'DOING',
  REVIEW = 'REVIEW',
  DONE = 'DONE',
  DISCARD = 'DISCARD',
  TOTAL_ESTIMATED_HOUR = 'TOTAL_ESTIMATED_HOUR',
  TOTAL_ESTIMATED_HOUR_EXCLUDE_DISCARD = 'TOTAL_ESTIMATED_HOUR_EXCLUDE_DISCARD',
  TOTAL_ACTUAL_HOUR = 'TOTAL_ACTUAL_HOUR',
  RESOURCE_USED_RATE = 'RESOURCE_USED_RATE',
  VELOCITY = 'VELOCITY',
  THROUGHPUT = 'THROUGHPUT',
}

export enum SprintReportMemberAlias {
  TOTAL = 'TOTAL',
  UNASSIGNED = 'UNASSIGNED',
}

export function getSprintReportKeyLabel(key: SprintReportKey): string {
  switch (key) {
    case SprintReportKey.TOTAL_ESTIMATED_HOUR:
      return intl.formatMessage({ id: 'total' })
    case SprintReportKey.TOTAL_ESTIMATED_HOUR_EXCLUDE_DISCARD:
      return intl.formatMessage({
        id: 'sprintReport.totalEstimatedHourExcludeDiscard',
      })
    case SprintReportKey.TOTAL_ACTUAL_HOUR:
      return intl.formatMessage({ id: 'sprintReport.totalActualHour' })
    case SprintReportKey.RESOURCE_USED_RATE:
      return intl.formatMessage({ id: 'sprintReport.resourceUsedRate' })
    case SprintReportKey.VELOCITY:
      return 'Velocity'
    case SprintReportKey.THROUGHPUT:
      return intl.formatMessage({ id: 'sprintReport.throughput' })
    default:
      return key
  }
}

export interface SprintReportRequest {
  projectUuid: string
  rootWbsItemUuid?: string
  teamUuid?: string
  groupKey: SprintReportGroupKey
  startDate?: string
  endDate?: string
}

export function getSprintReport(
  requestBody: SprintReportRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    `/api/v1/projects/status_report`,
    requestBody
  )
}
