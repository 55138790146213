import { styled } from '@mui/material'
import { intl } from '../../../../../i18n'
import {
  ComponentDivider,
  ComponentHeader,
  ComponentHeaderEndDiv,
  ComponentTitle,
  ComponentWrapper,
  TeamName,
  TeamwiseComponent,
  TeamwiseInnerComponent,
} from '../../components'
import { TeamSelector } from '../../components/TeamSelector'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { useState } from 'react'
import { TeamSelectOption } from '../../model/team'
import {
  YearMonthVO,
  yearMonthService,
} from '../../../../../domain/value-object/YearMonthVO'
import { MonthlyWorkReport } from './MonthlyWorkReport'
import { YearMonthSelect } from '../../../../components/inputs/YearMonthSelect'

const WorkReportRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px',
  gap: '24px',
})

type WorkReportProps = {
  project: ProjectDetail
}

export const WorkReport = ({ project }: WorkReportProps) => {
  const [selectedTeam, setSelectedTeam] = useState<TeamSelectOption[]>([])
  const [yearMonth, setYearMonth] = useState<YearMonthVO>(
    yearMonthService.now()
  )
  return (
    <ComponentWrapper>
      <ComponentHeader>
        <ComponentTitle>
          {intl.formatMessage({ id: 'workReport' })}
        </ComponentTitle>
        <ComponentHeaderEndDiv>
          <YearMonthSelect value={yearMonth} onChange={setYearMonth} />
          <TeamSelector
            projectUuid={project.uuid}
            updateSelection={setSelectedTeam}
            disableTeamUnselected={true}
          />
        </ComponentHeaderEndDiv>
      </ComponentHeader>
      <WorkReportRoot>
        {selectedTeam.map((team, i) => (
          <>
            {i > 0 && <ComponentDivider />}
            <TeamwiseComponent key={i}>
              <TeamName>{team.name}</TeamName>
              <TeamwiseInnerComponent>
                <MonthlyWorkReport
                  projectUuid={project.uuid}
                  team={team}
                  month={yearMonth}
                />
              </TeamwiseInnerComponent>
            </TeamwiseComponent>
          </>
        ))}
      </WorkReportRoot>
    </ComponentWrapper>
  )
}
