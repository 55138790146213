import { TwoDimensionalPoints } from '../../model/chart'

type StackedData<X, Key> = {
  x: X
  y: {
    k: Key
    v: number
  }[]
}

type StackedBarChartProps<X, Key> = {
  data: StackedData<X, Key>[]
  xScale: (x: X) => number
  yScale: (y: number) => number
  color: (k: Key) => string
  barWidth: number
}

export const StackedBarChart = <X, Key>({
  data,
  xScale,
  yScale,
  color,
  barWidth,
}: StackedBarChartProps<X, Key>) => {
  return (
    <g>
      {data.map((d, i) => (
        <g key={i} transform={`translate(${xScale(d.x) - barWidth / 2},0)`}>
          {d.y.map((v, j) => (
            <rect
              key={`${i}-${j}`}
              fill={color(v.k)}
              x={0}
              y={yScale(
                d.y.slice(0, j + 1).reduce((sum, curr) => sum + curr.v, 0)
              )}
              width={barWidth}
              height={yScale(0) - yScale(v.v)}
            />
          ))}
        </g>
      ))}
    </g>
  )
}

type HorizontalBarChartProps<X, Y> = {
  data: TwoDimensionalPoints<X, Y>
  xScale: (x: X) => number
  yScale: (y: Y) => number

  color: string
  barWidth: number
}

export const HorizontalBarChart = <X, Y>({
  data,
  xScale,
  yScale,
  color,
  barWidth,
}: HorizontalBarChartProps<X, Y>) => {
  return (
    <g>
      {data.map((d, i) => (
        <g key={i} transform={`translate(0,${yScale(d.y) - barWidth / 2})`}>
          <rect
            fill={color}
            x={0}
            y={0}
            width={xScale(d.x)}
            height={barWidth}
          />
        </g>
      ))}
    </g>
  )
}
