const RESERVED_SUBDOMAIN = ['lobby']

export const getTenantAlias = (): string | undefined => {
  if (isLocalhost()) return undefined
  const url = getUrl()
  const alias = url.host.substring(0, url.host.indexOf('.'))
  if (RESERVED_SUBDOMAIN.includes(alias)) return undefined
  return alias
}

export const isLocalhost = (): boolean => {
  return getUrl().hostname === process.env.REACT_APP_LOCAL_HOST
}

export const getRootUrl = (tenantAlias: string): string => {
  const url = getUrl()
  if (isLocalhost()) {
    return `${url.protocol}//${url.host}`
  }
  return `${url.protocol}//${tenantAlias}${url.host.substring(
    url.host.indexOf('.')
  )}`
}

export const getDomain = (): string => {
  const currentEnv = process.env.REACT_APP_ENV
  if (currentEnv === 'production') {
    return 'flagxs.com'
  }
  return 'sevend.net'
}

const getUrl = (): URL => new URL(window.location.href)
