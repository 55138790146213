import { DateVO, dateVoService, RelativeDate } from '../DateVO'

export type DateTermVO = {
  readonly from?: DateVO
  readonly to?: DateVO
}

type DateTermVOConstructorArgs =
  | string
  | number
  | Date
  | RelativeDate
  | undefined

const construct = (
  from: DateTermVOConstructorArgs,
  to: DateTermVOConstructorArgs
): DateTermVO => {
  const toDateVO = (v: DateTermVOConstructorArgs): DateVO | undefined => {
    if (typeof v === 'undefined') {
      return undefined
    }
    return dateVoService.construct(v)
  }
  return {
    from: toDateVO(from),
    to: toDateVO(to),
  }
}

const format = (
  src: DateTermVO,
  formatter?: string
): { from?: string; to?: string } => {
  return {
    from: src.from ? dateVoService.format(src.from, formatter) : undefined,
    to: src.to ? dateVoService.format(src.to, formatter) : undefined,
  }
}

export const dateTermVoService = {
  construct,
  format,
}
