import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../utils/uuids'
import businessClientApi, {
  BusinessClientBatchDeltaInput,
  BusinessClientDeltaInput,
  BusinessClientDetail,
  BusinessClientInput,
} from '../../../lib/functions/businessClient'
import { formatDateTime } from '../../../utils/date'
import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import ViewMeta from '../../containers/meta/ViewMeta'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import ContextMenu, {
  ContextMenuGroup,
} from '../../containers/commons/AgGrid/lib/contextMenu'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class BusinessClientRow extends RowData {
  code?: string
  displayName: string = ''
  officialName?: string
  revision?: string
}

class BusinessClientRowDataSpec extends RowDataSpec<
  BusinessClientDetail,
  BusinessClientRow
> {
  createNewRow(): BusinessClientRow {
    return new BusinessClientRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: BusinessClientRow
    parent: BusinessClientRow
  }): BusinessClientRow {
    return params.child
  }

  createRowByResponse(response: BusinessClientDetail): BusinessClientRow {
    return {
      ...response,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(original: BusinessClientRow): BusinessClientRow {
    return {
      ...original,
      code: undefined,
      revision: undefined,
    }
  }
}

interface BusinessClientBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    BusinessClientDetail,
    BusinessClientRow,
    BulkSheetState
  > {}

export default class BusinessClientsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  BusinessClientDetail,
  BusinessClientRow,
  BulkSheetState
> {
  addable = true
  draggable = true
  columnAndFilterStateKey = UiStateKey.BusinessClientColumnAndFilterState
  rowDataSpec = new BusinessClientRowDataSpec()
  lockedColumns = ['businessClients.code']
  pinnedColumns = ['businessClients.code', 'businessClients.displayName']
  enableExcelExport = true
  groupColumnWidth = 200
  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return businessClientApi.getAll()
  }

  openDetail = (
    row: BusinessClientRow,
    ctx: BusinessClientBulkSheetContext
  ) => {
    if (row && row.code) {
      ctx.props.history.push(`/businessClientEdit/${row.code}`)
    }
  }

  onSubmit = (
    ctx: BusinessClientBulkSheetContext,
    data: {
      added: BusinessClientRow[]
      edited: {
        before: BusinessClientRow
        after: BusinessClientRow
      }[]
      deleted: BusinessClientRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: BusinessClientBatchDeltaInput = {
      added: data.added.map(this.createRequestByRow),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v)),
      deleted: data.deleted.map(this.createDeleteRequestByRow),
    }
    return businessClientApi.updateBatchDelta(input)
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: BusinessClientBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
        ctx.generateUtilityContextMenu(params),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  private createRequestByRow = (
    row: BusinessClientRow
  ): BusinessClientInput => {
    return row
  }

  private createDeltaRequestByRow = ({
    before,
    after,
  }: {
    before: BusinessClientRow
    after: BusinessClientRow
  }): BusinessClientDeltaInput => {
    return {
      uuid: after.uuid,
      parentUuid: {
        oldValue: before.parentUuid,
        newValue: after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: before.prevSiblingUuid,
        newValue: after.prevSiblingUuid,
      },
      displayName:
        before.displayName !== after.displayName
          ? {
              oldValue: before.displayName,
              newValue: after.displayName,
            }
          : undefined,
      officialName:
        before.officialName !== after.officialName
          ? {
              oldValue: before.officialName,
              newValue: after.officialName,
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (row: BusinessClientRow) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }
}
