import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { useProjectNotificationSettingData } from './hooks/projectNotificationSettingData'
import { BulkSheetView } from '../../containers/BulkSheetView'
import { usePageState } from './hooks/pageState'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import {
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { ProjectNotificationSettingGridOptions } from './gridOptions'
import store from '../../../store'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import { getRowNumber } from '../../containers/BulkSheetView/lib/gridApi'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import {
  ProjectNotificationSettingRow,
  ProjectNotificationWorkspace,
  getContentDisplayName,
} from './projectNotificationSettings'
import { useDialog } from './hooks/dialog'
import Loading from '../../components/process-state-notifications/Loading'
import _ from 'lodash'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import ProjectNotificationSettingsHeader from './header'
import { SlackChannel } from '../../../domain/value-object/Slack/ChannelVO'
import { useSlackExternalServiceStorageService } from '../../../services/storage-services/external-services/slackExternalServiceStorageService'
import { InputError } from '../../containers/BulkSheetView/lib/validation'

type WrapperProps = PageProps

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export const ProjectNotificationSettings = projectPrivate(
  ({ uuid: functionUuid }: WrapperProps) => {
    const { projectUuid } = useProjectPrivateContext()

    const pageState = usePageState(functionUuid)
    const bulkSheetState = useBulkSheetState(functionUuid, projectUuid)
    const {
      rowData,
      setRowData,
      workspace,
      setWorkspace,
      refresh,
      save,
      validate,
    } = useProjectNotificationSettingData(projectUuid)
    const rowDataRef = useRef<ProjectNotificationSettingRow[]>([])
    const workspaceRef = useRef<ProjectNotificationWorkspace>()
    rowDataRef.current = rowData
    workspaceRef.current = workspace
    const reload = useRef<Function>()
    useEffect(() => {
      reload.current = refresh
    }, [refresh])

    const [loading, setLoading] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)
    const [gridApi, setGridApi] = useState<GridApi>()
    const [columnApi, setColumnApi] = useState<ColumnApi>()
    const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
    const [sortColumnsState, setSortColumnsState] = useState<
      SortedColumnState[]
    >([])

    const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([])
    const { getChannelsFromSlackApi } = useSlackExternalServiceStorageService()

    const dialog = useDialog()
    const columnSetting = useColumnSetting()

    useEffect(() => {
      gridApi?.resetRowHeights()
    }, [gridApi, pageState.rowHeight])

    const gridOptions = useMemo(
      () => ProjectNotificationSettingGridOptions(),
      []
    )

    const initializeInputError = useCallback(() => {
      gridOptions.context = {
        ...gridOptions.context,
        errors: new InputError(),
      }
    }, [gridOptions.context])

    useEffect(() => {
      // Initialize InputError object.
      initializeInputError()
    }, [])

    useEffect(() => {
      const fn = async () => {
        if (!workspace) {
          setSlackChannels([])
          gridOptions.context = {
            ...gridOptions.context,
            slackChannels: [],
          }
          return
        }
        const channels = await getChannelsFromSlackApi(
          workspace.slackWorkspaceId
        )
        setSlackChannels(channels)
        gridOptions.context = {
          ...gridOptions.context,
          slackChannels: channels,
        }
      }
      fn()
    }, [workspace])

    useEffect(() => {
      gridOptions.context = {
        ...gridOptions.context,
        workspace,
        checkboxDisabled: !workspace,
      }
      gridApi?.refreshCells({ force: true })
    }, [gridApi, workspace])

    useEffect(() => {
      gridApi?.refreshCells({ force: true })
    }, [gridApi, slackChannels])

    const onSubmit = useCallback(async () => {
      setLoading(true)
      try {
        gridApi?.stopEditing()

        if (gridOptions.context.errors?.hasMessage()) {
          store.dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'global.warning.businessError' }),
              text: gridOptions.context.errors.toMessage(id => {
                const node = gridApi?.getRowNode(id)
                return !!node ? getRowNumber(node).toString() : ''
              }),
            })
          )
          return
        }

        const pageErrors = validate()
        if (pageErrors.length > 0) {
          store.dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'global.warning.businessError' }),
              text: pageErrors.join('\n'),
            })
          )
          return
        }

        bulkSheetState.saveImmediately()

        // Save
        const response = await save()

        if (response.hasWarning) {
          const messages = extractValuesFromResponse(response.json, 'messages')
          handleWarning(messages, uuid => {
            const target = rowData.find(v => v.uuid === uuid)
            return getContentDisplayName(target)
          })
        }

        if (!response.hasError && !response.hasWarning) {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.SUCCESS,
              title: intl.formatMessage({ id: 'registration.complete' }),
            })
          )
          await refresh()
        }
      } finally {
        setLoading(false)
      }
    }, [rowData, save, bulkSheetState])

    const onReload = useCallback(async () => {
      await refresh()
      initializeInputError()
    }, [refresh])

    const rememberColumnState = useCallback(() => {
      const columnState = gridOptions.columnApi?.getColumnState()
      columnState && bulkSheetState.saveColumnState(columnState)
    }, [bulkSheetState, gridOptions.columnApi])

    const onGridReady = useCallback(
      (event: GridReadyEvent<ProjectNotificationSettingRow>) => {
        setGridApi(event.api)
        setColumnApi(event.columnApi)
        if (!_.isEmpty(bulkSheetState.columnState)) {
          event.columnApi.applyColumnState({
            state: bulkSheetState.columnState,
            applyOrder: true,
          })
        }
        setTimeout(() => setLoading(false), 2000)
      },
      [bulkSheetState.columnState]
    )

    // Restore row state.
    const onFirstDataRendered = useCallback(
      e => {
        if (!_.isEmpty(bulkSheetState.filterState)) {
          gridApi?.setFilterModel(bulkSheetState.filterState)
        }
        bulkSheetState.finishRestoring()
        setLoading(false)
      },
      [bulkSheetState]
    )

    const onColumnVisible = useCallback(
      (e: ColumnVisibleEvent) => {
        rememberColumnState()
        onSortedColumnsStateChanged(e)
      },
      [bulkSheetState]
    )

    const onFilterChanged = useCallback(
      (e: FilterChangedEvent) => {
        const filterModel = e.api.getFilterModel()
        delete filterModel['uuid']
        bulkSheetState.saveFilterState(filterModel)
        setFilteredColumns(
          Object.keys(filterModel)
            .map(col => e.api.getColumnDef(col))
            .filter(v => !!v && v.field !== 'uuid') as ColDef[]
        )
      },
      [bulkSheetState]
    )

    const onSortedColumnsStateChanged = useCallback(
      (e: SortChangedEvent) => {
        const sortedColumns = e.columnApi
          .getColumnState()
          .filter(col => !!col.sort)
          .map(col => {
            return {
              ...e.api.getColumnDef(col.colId),
              colId: col.colId,
            }
          })
          .filter(v => !!v) as ColDef[]
        e.api.setSuppressRowDrag(0 < sortedColumns.length)

        const columnState = gridOptions.columnApi?.getColumnState()
        const sortedColumnState: { [colId: string]: ColumnState } = {}
        columnState &&
          columnState.forEach(state => {
            if (state.sort) {
              sortedColumnState[state.colId] = state
            }
          })
        const sortedColumnsState: SortedColumnState[] = sortedColumns.map(
          col => {
            return {
              colId: col.colId,
              field: col.field,
              headerName: col.headerName,
              sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
            }
          }
        )

        setSortColumnsState(sortedColumnsState)
      },
      [bulkSheetState]
    )

    const onSortChanged = useCallback(
      (e: SortChangedEvent) => {
        rememberColumnState()
        onSortedColumnsStateChanged(e)
      },
      [bulkSheetState]
    )

    const onChangeColumnFilter = useCallback(
      (value: ColumnQuickFilterKey) => {
        if (value === ColumnQuickFilterKey.INITIAL) {
          columnApi?.resetColumnState()
          const filterModel = gridApi?.getFilterModel()
          const quick = filterModel?.['uuid']
          gridApi?.setFilterModel(quick ? { uuid: quick } : null)
        } else if (value === ColumnQuickFilterKey.RESTORE) {
          dialog.openUiState()
        }
      },
      [gridApi, columnApi]
    )

    const onDeleteFilteredColumn = useCallback((column: ColDef) => {
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    }, [])

    const resetFilters = useCallback(() => {
      if (!gridOptions.api) return
      gridOptions.api.setFilterModel([])
    }, [])

    const onDeleteSortedColumn = useCallback((colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    }, [])

    const onDeleteSortedAllColumns = useCallback(() => {
      gridOptions.columnApi?.applyColumnState({
        defaultState: { sort: null },
      })
    }, [])

    const onChangeSortColumnState = useCallback(
      (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
        gridOptions.columnApi?.applyColumnState({
          state: [{ colId: colId.toString(), sort }],
        })
      },
      []
    )

    const onChangeWorkspace = useCallback(
      (value?: ProjectNotificationWorkspace) => {
        setWorkspace(value)
        gridOptions.context = {
          ...gridOptions.context,
          workspace: value,
          checkboxDisabled: !value,
        }
      },
      [setWorkspace]
    )

    const onCellValueChanged = useCallback(
      (e: CellValueChangedEvent) => {
        if (e.colDef.field === 'slackNotification') {
          gridApi?.refreshCells({ force: true, columns: ['slackChannel'] })
        }
      },
      [gridApi]
    )

    useKeyBind(
      [
        {
          key: KEY_SAVE,
          fn: onSubmit,
          stopDefaultBehavior: true,
        },
      ],
      [rowData, save]
    )

    if (!pageState.initialized || !bulkSheetState.initialized) {
      return <></>
    }

    return (
      <PageArea>
        <SavePopper loading={loading} onSubmit={onSubmit} />
        <ProjectNotificationSettingsHeader
          rowHeight={pageState.rowHeight}
          onChangeHeight={pageState.setRowHeight}
          filteredColumns={filteredColumns}
          onDeleteFilteredColumn={onDeleteFilteredColumn}
          resetFilters={resetFilters}
          onDeleteSortedColumn={onDeleteSortedColumn}
          onDeleteSortedAllColumns={onDeleteSortedAllColumns}
          onChangeSortColumnState={onChangeSortColumnState}
          onReload={onReload}
          onClickColumnSettingButton={columnSetting.toggle}
          columnSettingOpen={columnSetting.isOpen}
          onClickFavoriteColumnFilterButton={dialog.openUiState}
          sortColumnsState={sortColumnsState}
          workspace={workspace}
          onChangeWorkspace={onChangeWorkspace}
        />
        <BulkSheetView
          ref={ref}
          gridOptions={gridOptions}
          rowData={rowData}
          rowHeight={pageState.rowHeight}
          // Events and actions
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onColumnVisible={onColumnVisible}
          onColumnResized={rememberColumnState}
          onColumnMoved={rememberColumnState}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          onCellValueChanged={onCellValueChanged}
        />
        <Loading isLoading={loading} elem={ref.current} />
        <ColumnSettingPopper
          anchorEl={columnSetting.anchorEl}
          open={columnSetting.isOpen}
          columnApi={gridOptions?.columnApi ?? undefined}
          gridApi={gridOptions?.api ?? undefined}
          close={columnSetting.close}
          height={ref.current?.offsetHeight}
          openSavedUiStateDialog={dialog.openUiState}
          initializeColumnState={() =>
            onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
          }
          applicationFunctionUuid={functionUuid}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          columnState={bulkSheetState.columnState}
          offset={120}
        />
        {dialog.uiState && (
          <SavedUIStateDialog
            applicationFunctionUuid={functionUuid}
            open={true}
            title={intl.formatMessage({
              id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
            })}
            uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
            sharable={false}
            currentUIState={{
              columnState: bulkSheetState.columnState,
              filterState: bulkSheetState.filterState,
            }}
            onSelect={(uiState: SavedUIState) => {
              const { columnState, filterState } = uiState.UIState
              if (columnState) {
                bulkSheetState.saveColumnState(columnState)
                columnApi?.applyColumnState({
                  state: columnState,
                  applyOrder: true,
                })
              }
              if (filterState) {
                bulkSheetState.saveFilterState(filterState)
                gridApi?.setFilterModel(filterState)
              }
              dialog.closeUiState()
            }}
            onClose={dialog.closeUiState}
          />
        )}
      </PageArea>
    )
  }
)
