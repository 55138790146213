import {
  UpdateWbsItemDeltaInput,
  WbsItemEntity,
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import {
  DateTermV2,
  dateTermV2Service,
} from '../../../../domain/value-object/DateTermV2'
import { IItemDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'
import { generateUuid } from '../../../../utils/uuids'
import {
  isTextValueDiffered,
  isSelectValueDiffered,
  isReferencedEntityValueDiffered,
  isNumberValueDiffered,
  isDateTermValueDiffered,
  toTextDeltaValue,
  toSelectDeltaValue,
  toReferencedEntityDeltaValue,
  toNumberDeltaValue,
  toDateTermDeltaValue,
} from './properties'

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type WbsItemFormModel = PartialBy<
  Omit<WbsItemEntity, 'projectUuid'>,
  | 'displayName'
  | 'code'
  | 'status'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>
export type WbsItemEditableField = keyof Omit<
  WbsItemFormModel,
  | 'uuid'
  | 'type'
  | 'code'
  | 'actualHour'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>

export const createInitialValue = (type: WbsItemType): WbsItemFormModel => {
  const uuid = generateUuid()
  return {
    uuid,
    type,
    status: WbsItemStatus.TODO,
  }
}

export type UpdateFormModel = <
  K extends keyof WbsItemFormModel,
  V extends WbsItemFormModel[K]
>(
  path: K,
  value: V
) => void
export type UpdateFormModelValue<K extends keyof WbsItemFormModel> = (
  value: WbsItemFormModel[K]
) => void

export const toDeltaInput = (
  entity: WbsItemEntity,
  model: WbsItemFormModel
): UpdateWbsItemDeltaInput => {
  const input: UpdateWbsItemDeltaInput = {
    uuid: entity.uuid,
    type: entity.type,
  }
  // TODO: Consider to simplify, maybe by defining field properties.
  const updatableFields: WbsItemEditableField[] = [
    'displayName',
    'description',
    'status',
    'substatus',
    'difficulty',
    'priority',
    'team',
    'accountable',
    'responsible',
    'assignee',
    'estimatedStoryPoint',
    'estimatedHour',
    'scheduledDate',
    'actualDate',
  ]
  for (let field of updatableFields) {
    if (isValueDiffered(entity, model, field)) {
      input[field] = toDeltaValue(entity, model, field)
    }
  }
  return input
}

const isValueDiffered = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
      return isTextValueDiffered(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return isSelectValueDiffered(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return isReferencedEntityValueDiffered(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return isNumberValueDiffered(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return isDateTermValueDiffered(entity[path], model[path])
  }
  return false
}

const toDeltaValue = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
      return toTextDeltaValue(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return toSelectDeltaValue(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return toReferencedEntityDeltaValue(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return toNumberDeltaValue(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return toDateTermDeltaValue(entity[path], model[path])
  }
  return undefined
}
