import { Action } from 'redux'
import { WbsItemTypeVO } from '../domain/value-object/WbsItemTypeVO'

export interface BreadCrumb {
  name: string
  uuid: string
  code: string
  link: string
  next?: BreadCrumb
  type: WbsItemTypeVO
  wbsItemUuid?: string
}

// Actions
enum ActionType {
  SetBreadcrumbs = 'SetBreadcrumbs',
  ClearBreadcrumbs = 'ClearBreadcrumbs',
}

interface State {
  breadcrumbs?: BreadCrumb
}

interface HeaderBarAction extends State, Action {}

export const setBreadcrumbs = (breadcrumbs: BreadCrumb) => ({
  type: ActionType.SetBreadcrumbs,
  breadcrumbs,
})
export const clearBreadcrumbs = () => ({ type: ActionType.ClearBreadcrumbs })

// reducers
export default (state: State = {}, action: HeaderBarAction) => {
  switch (action.type) {
    case ActionType.SetBreadcrumbs:
      return { ...state, breadcrumbs: action.breadcrumbs }
    case ActionType.ClearBreadcrumbs:
      return { ...state, breadcrumbs: undefined }
    default:
      return state
  }
}
