enum ActionType {
  TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU',
  OPEN_SIDE_MENU = 'OPEN_SIDE_MENU',
  CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU',
  TOGGLE_ELEVATED = 'TOGGLE_ELEVATED',
}

type State = {
  open: boolean
}

export const toggleSideMenu = () => ({
  type: ActionType.TOGGLE_SIDE_MENU,
})

export const openSideMenu = () => ({
  type: ActionType.OPEN_SIDE_MENU,
})

export const closeSideMenu = () => ({
  type: ActionType.CLOSE_SIDE_MENU,
})

export const toggleElevated = () => ({
  type: ActionType.TOGGLE_ELEVATED,
})

export const reducer = (
  state: State = {
    open: false,
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.TOGGLE_SIDE_MENU: {
      return {
        ...state,
        open: !state.open,
      }
    }
    case ActionType.OPEN_SIDE_MENU: {
      return {
        ...state,
        open: true,
      }
    }
    case ActionType.CLOSE_SIDE_MENU: {
      return {
        ...state,
        open: false,
      }
    }
    case ActionType.TOGGLE_ELEVATED: {
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
