import api, { APIResponse } from '../../../lib/commons/api'
import { ProjectBasic, ProjectDetail } from '../../../lib/functions/project'
import { RoleBasic } from '../../../lib/functions/role'
import { UserBasic, UserDetail } from '../../../lib/functions/user'
import { DateTerm } from '../../../utils/date'
import DateVO from '../../../vo/DateVO'
import { TreeRow } from '../../containers/BulkSheetView/model'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export enum ResourcePlanCrossProjectType {
  MEMBER = 'MEMBER',
  PROJECT = 'PROJECT',
  SUMMARY = 'SUMMARY',
}

export enum RowGroupColumnType {
  MEMBER = ResourcePlanCrossProjectType.MEMBER,
  PROJECT = ResourcePlanCrossProjectType.PROJECT,
}

/**
 * RowData definition
 */
export interface MemberWorkMonthBody {
  yearMonth?: string
  manMonth?: number
}

export interface ResourcePlanCrossProjectRowBodyBase {
  resourcePlanUuid?: string
  revision?: string
  lockVersion?: number
  memberWorkMonths?: MemberWorkMonthBody[]
}

export interface ResourcePlanCPGroupRowsByMemberColRowBody
  extends ResourcePlanCrossProjectRowBodyBase {
  user?: UserDetail
  project?: ProjectBasic
  role?: RoleBasic
  validFrom?: string
  validTo?: string
  validUser?: boolean
}

export interface ResourcePlanCPGroupRowsByProjectColRowBody
  extends ResourcePlanCrossProjectRowBodyBase {
  user?: UserDetail
  project?: ProjectDetail
}

export interface ResourcePlanCrossProjectsRow extends TreeRow {
  body:
    | ResourcePlanCPGroupRowsByMemberColRowBody
    | ResourcePlanCPGroupRowsByProjectColRowBody
  isTotal?: boolean
  type: ResourcePlanCrossProjectType
  rowGroupColumnType?: RowGroupColumnType
}

/**
 * API response
 */
export interface MemberWorkMonthSkelton {
  month: Date
  manMonth: number
}

export interface ResourcePlanCrossProjectsSkeleton {
  uuid: string
  user: UserDetail
  project: ProjectDetail
  memberWorkMonths: MemberWorkMonthSkelton[]
  resourcePlanUuid: string
  lockVersion: number
  validUser: boolean

  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

export const fetchResourcePlanCrossProjectsWorkMonths = async (params: {
  startDate: string
  endDate: string
}): Promise<ResourcePlanCrossProjectsSkeleton[]> => {
  const response: APIResponse = await api.functional.request(
    'GET',
    '/api/v1/projects/resource_plans/cross_projects',
    params
  )
  return response.json as Promise<ResourcePlanCrossProjectsSkeleton[]>
}

/**
 * functions
 */
export const createDefaultDateTerm = (): DateTerm => {
  const now = DateVO.now()
  const startYear = now.getMonth() > 3 ? now.getYear() : now.getYear() - 1
  return {
    startDate: `${startYear}/04/01`,
    endDate: `${startYear + 1}/03/31`,
  }
}

interface displayNameProps {
  displayName?: string
  name?: string
}

export const displayNameComparator = (
  valueA: string | displayNameProps,
  valueB: string | displayNameProps
): number => {
  const strA =
    typeof valueA === 'string'
      ? valueA
      : valueA?.displayName || valueA?.name || ''
  const strB =
    typeof valueB === 'string'
      ? valueB
      : valueB?.displayName || valueB?.name || ''
  return strA.localeCompare(strB)
}
