import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterFooter } from '../common'
import { useBoolFilter } from './useBoolFilter'
import { Box, Checkbox, FormControlLabel, styled } from '@mui/material'
import { intl } from '../../../../../../i18n'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'

export type BoolFilter = {
  operator: BoolFilterOperator
}

export enum BoolFilterOperator {
  EXIST = 'EXIST',
  NOT_EXIST = 'NOT_EXIST',
  ALL_DESELECTED = 'ALL_DESELECTED',
}

type Props = IFilterParams & {
  labelExist?: string
  labelNotExist?: string
  fetch: (v: BoolFilter) => Promise<APIResponse>
}

export const ServerSideBoolFilter = forwardRef(
  (
    { labelExist, labelNotExist, fetch, filterChangedCallback, context }: Props,
    ref
  ) => {
    const hasRequiredSaveData = useSelector<AllState>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

    // State
    const filter = useBoolFilter()
    const [loading, isLoading] = useState(false)
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
      undefined
    )

    const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
    useEffect(() => {
      if (addedRowIds.current) return
      addedRowIds.current = []
    }, [])
    const fetchFilteredIds = async (v: BoolFilter) => {
      const response = await fetch(v)
      setFilteredIds(response.json)
    }
    const search = useCallback(
      _.debounce(async (v: BoolFilter) => {
        try {
          isLoading(true)
          await fetchFilteredIds(v)
        } finally {
          isLoading(false)
        }
      }, 300),
      []
    )

    // Ag-grid custom filter
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params: IDoesFilterPassParams) {
          // Don't filter new rows
          if (params.data.added) {
            if (addedRowIds.current && params.node.id) {
              addedRowIds.current.push(params.node.id)
            }
            return true
          }
          if (
            addedRowIds.current &&
            params.node.id &&
            addedRowIds.current.includes(params.node.id)
          ) {
            return true
          }
          if (!filteredIds) return true
          return params.node.id && filteredIds.includes(params.node.id)
        },

        isFilterActive() {
          return filter.isActive()
        },

        getModel() {
          return filter.model()
        },

        setModel(model?: { operator?: BoolFilterOperator }) {
          if (!model) {
            filter.reset()
            return
          }
          filter.setExists(model.operator === BoolFilterOperator.EXIST)
          filter.setNotExists(model.operator === BoolFilterOperator.NOT_EXIST)
        },

        getModelAsString() {
          if (!filter.isActive()) return ''
          if (labelExist || labelNotExist) {
            if (filter.exists) return labelExist
            if (filter.notExists) return labelNotExist
          }
          return filter.getModelAsString()
        },

        onNewRowsLoaded() {
          if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
          const filterModel = filter.model()
          if (filterModel) {
            fetchFilteredIds(filterModel)
          }
        },
      }
    })

    // Filter
    useEffect(() => {
      if (!filter.isActive()) {
        setFilteredIds(undefined)
        return
      }
      if (filter.isAllDeselected()) {
        setFilteredIds([])
        return
      }
      search(filter.model())
    }, [filter.exists, filter.notExists])

    useEffect(() => {
      const filterModel = filter.model()
      const isAfterSavedOrRefreshed =
        prevHasRequiredSaveData && !hasRequiredSaveData
      if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
        fetchFilteredIds(filterModel).then(() => {
          addedRowIds.current = []
        })
      }
    }, [hasRequiredSaveData])

    useEffect(() => {
      filterChangedCallback()
    }, [filteredIds])

    return (
      <BulkSheetFilter>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column',
            margin: '0 3px',
          }}
        >
          <CheckboxFormControl
            label={labelExist}
            control={
              <Checkbox
                checked={filter.exists}
                onChange={e => filter.setExists(e.target.checked)}
                disableRipple={true}
              />
            }
          />
          <CheckboxFormControl
            label={labelNotExist}
            control={
              <Checkbox
                checked={filter.notExists}
                onChange={e => filter.setNotExists(e.target.checked)}
                disableRipple={true}
              />
            }
          />
        </Box>
        <FilterFooter loading={loading} onClick={() => filter.reset()} />
      </BulkSheetFilter>
    )
  }
)

const CheckboxFormControl = styled(FormControlLabel)({
  margin: 0,
})
