import { useCallback, useRef, useState } from 'react'
import {
  ActualWorkingHoursRow,
  ActualWorkingHoursSkelton,
  fetchActualWorkingHours,
  ActualWorkingHoursBatchInput,
  ActualWorkingHoursInput,
  convertInput2Date,
  convertBreakTime,
  updateBatch,
  ActualWorkingHoursRowScheduleBody,
  ActualWorkingHoursRowActualBody,
  calculateWorkTime,
  isHoliday,
  ActualWorkingHoursRowProjectBody,
  fetchTargetUsers,
  StandardWorkingInfo,
  ActualWorkingHoursSummaryRow,
  ActualWorkingHoursSummaryRowProjectBody,
  calculateBreakTime,
} from '../ActualWorkingHours'
import { AgGridTreeHelper } from '../../../containers/BulkSheetView/lib/tree'
import _ from 'lodash'
import store from '../../../../store'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import { APIResponse } from '../../../../lib/commons/api'
import { generateUuid } from '../../../../utils/uuids'
import Auth from '../../../../lib/commons/auth'
import DateVO from '../../../../vo/DateVO'
import TaskActualWorkAPI, {
  TaskActualWorkHour,
} from '../../../../lib/functions/taskActualWork'
import { UserBasic } from '../../../../domain/value-object/UserBasicVO'

export const useActualWorkingHoursData = () => {
  const [summaryRow, setSummaryRow] = useState<ActualWorkingHoursSummaryRow>(
    new ActualWorkingHoursSummaryRow()
  )
  const [data, setData] = useState<ActualWorkingHoursRow[]>([])
  const originalData = useRef<
    Map<
      string,
      Pick<
        ActualWorkingHoursRowActualBody,
        'workingHoursType' | 'startTime' | 'endTime' | 'breakTime' | 'remarks'
      >
    >
  >(new Map())

  const clearData = useCallback(() => {
    setData([])
    originalData.current.clear()
    setSummaryRow(new ActualWorkingHoursSummaryRow())
  }, [])

  const fetchRecords = useCallback(
    async (
      userUuid: string,
      projectUuid: string,
      startDate: string,
      endDate: string
    ) => {
      setData([])
      const response = await fetchActualWorkingHours({
        userUuid,
        startDate,
        endDate,
      })
      const sources: ActualWorkingHoursSkelton[] = response.json

      const responseTaskActualHour =
        await TaskActualWorkAPI.getTaskActualWorkHours({
          userUuid,
          startDate,
          endDate,
        })
      const taskActualWorks: TaskActualWorkHour[] = responseTaskActualHour.json

      const rows = sources
        .map(v =>
          AgGridTreeHelper.convert(
            v,
            (s: ActualWorkingHoursSkelton): ActualWorkingHoursRow => {
              const targetTaskActualWorks = taskActualWorks.filter(
                actualWork => actualWork.actualDate === s.workDate
              )
              let totalHour = 0
              if (projectUuid) {
                const target = targetTaskActualWorks.find(
                  v => v.projectUuid === projectUuid
                )
                totalHour = target?.hour ?? 0
              } else {
                totalHour = targetTaskActualWorks.reduce(
                  (acc, cur) => acc + cur.hour,
                  0
                )
              }
              const row: ActualWorkingHoursRow = {
                isTotal: false,
                uuid: s.uuid,
                date: s.workDate,
                schedule: s.schedule
                  ? new ActualWorkingHoursRowScheduleBody(s.schedule)
                  : undefined,
                actual: s.actual
                  ? new ActualWorkingHoursRowActualBody(s.actual)
                  : undefined,
                totalActualHour: totalHour,
                projectHours: targetTaskActualWorks.map(actualWork => {
                  const body = new ActualWorkingHoursRowProjectBody()
                  body.projectUuid = actualWork.projectUuid
                  body.actualHour = actualWork.hour
                  return body
                }),
              } as ActualWorkingHoursRow
              row.workingHoursType =
                row.actual?.workingHoursType ??
                row.schedule?.workingHoursType ??
                ''
              row.remarks = row.actual?.remarks ?? row.schedule?.dateName ?? ''
              return row
            }
          )
        )
        .flat()
      setData(rows)
      originalData.current.clear()

      let sumBreak = 0
      let sumWorked = 0
      rows
        .filter(row => !!row.actual)
        .forEach(row => {
          originalData.current.set(
            row.uuid,
            _.cloneDeep({
              workingHoursType: row.actual!.workingHoursType,
              startTime: row.actual!.startTime,
              endTime: row.actual!.endTime,
              breakTime: row.actual!.breakTime,
              remarks: row.actual!.remarks,
            })
          )
          sumBreak += calculateBreakTime(row)
          sumWorked += calculateWorkTime(row)
        })

      const projectHourMap = new Map<
        string,
        ActualWorkingHoursSummaryRowProjectBody
      >()
      rows.forEach(row => {
        row.projectHours?.forEach(projectHour => {
          const body = projectHourMap.has(projectHour.projectUuid)
            ? (projectHourMap.get(
                projectHour.projectUuid
              ) as ActualWorkingHoursSummaryRowProjectBody)
            : new ActualWorkingHoursSummaryRowProjectBody(
                projectHour.projectUuid
              )
          body.actualHour += projectHour.actualHour
          projectHourMap.set(projectHour.projectUuid, body)
        })
        return projectHourMap
      })

      const sumRow = new ActualWorkingHoursSummaryRow()
      sumRow.breakTime = sumBreak
      sumRow.workHour = sumWorked
      sumRow.totalActualHour = rows.reduce((sum, row) => {
        return sum + (row.totalActualHour || 0)
      }, 0)
      sumRow.projectHours = Array.from(projectHourMap.values())
      setSummaryRow(sumRow)
    },
    []
  )

  const refresh = useCallback(
    async (
      userUuid: string,
      projectUuid: string,
      startDate: string,
      endDate: string
    ) => {
      const formatStart = new DateVO(startDate).formatForApi()!
      const formatEnd = new DateVO(endDate).formatForApi()!
      await fetchRecords(userUuid, projectUuid, formatStart, formatEnd)
      store.dispatch(doNotRequireSave())
    },
    [fetchRecords]
  )

  const updateWorkingHours = useCallback(
    async (rows: ActualWorkingHoursRow[]): Promise<APIResponse> => {
      const tenant = Auth.getCurrentTenant()
      const userUuid = tenant!.user!.uuid
      const batchInput: ActualWorkingHoursBatchInput = {
        added: convertRow2Request(
          rows.filter(row => !row.actual?.actualUuid),
          userUuid
        ),
        edited: convertRow2Request(
          rows.filter(row => !!row.actual?.actualUuid),
          userUuid
        ),
        deleted: [],
      }
      return updateBatch(batchInput)
    },
    []
  )

  const save = useCallback(async (): Promise<APIResponse> => {
    const edited = data.filter(row => row.edited)
    const response = await updateWorkingHours(edited)
    return response
  }, [data, updateWorkingHours])

  const convertRow2Request = (
    items: ActualWorkingHoursRow[],
    userUuid: string
  ): ActualWorkingHoursInput[] => {
    return items.map(row => {
      const holiday = isHoliday(row.workingHoursType)
      return {
        uuid: row.actual?.actualUuid ?? generateUuid(),
        userUuid,
        workingHoursType: row.workingHoursType ?? '',
        workDate: row.date ?? '',
        workStartAt: holiday
          ? ''
          : convertInput2Date(row.date, row.actual?.startTime),
        workEndAt: holiday
          ? ''
          : convertInput2Date(row.date, row.actual?.endTime),
        breakTime: holiday ? 0 : convertBreakTime(row),
        remarks: row.remarks ?? '',
      }
    })
  }

  const fetchUsers = useCallback(
    async (
      projectUuid: string,
      startDate: string,
      endDate: string
    ): Promise<UserBasic[]> => {
      const formatStart = new DateVO(startDate).formatForApi()!
      const formatEnd = new DateVO(endDate).formatForApi()!
      const response = await fetchTargetUsers({
        projectUuid,
        startDate: formatStart,
        endDate: formatEnd,
      })
      const src = response.json
      const users: UserBasic[] = src.children.map(v => {
        return {
          uuid: v.user.uuid,
          name: v.user.name,
        } as UserBasic
      })
      return users
    },
    []
  )

  const getStandardWorkingInfo = useCallback(() => {
    const scheduleHour = data.map(value => {
      return value.schedule?.workHour ?? 0
    })
    const workDays = scheduleHour.reduce((prev, cur) => {
      return prev + (cur > 0 ? 1 : 0)
    }, 0)
    const rawStdHour = scheduleHour.reduce((prev, cur) => {
      return prev + cur
    }, 0)
    let sumTime = 0
    let sumDays = 0
    data.forEach(row => {
      sumTime += calculateWorkTime(row)
      if (!!row.actual && !!row.actual.startTime && !!row.actual.endTime) {
        sumDays++
      }
    })
    return {
      standardWorkDays: workDays,
      standardWorkHours: rawStdHour,
      totalWorkHours: sumTime,
      totalWorkDays: sumDays,
      diffHours: sumTime - rawStdHour * 3600,
      diffDays: sumDays - workDays,
    } as StandardWorkingInfo
  }, [data])

  return {
    data,
    summaryRow,
    refresh,
    save,
    fetchUsers,
    clearData,
    getStandardWorkingInfo,
  }
}
