import {
  BlankableSearchConditionProps,
  FocusableSearchConditionProps,
  SearchConditionProps,
} from '.'
import { DateTermVO } from '../../../../domain/value-object/DateTermVO'
import { DateTermCell } from '../../tables/table-cells/DateTermCell'

export type BlankableDateTermConditionProps = SearchConditionProps<
  DateTermVO | undefined
> &
  FocusableSearchConditionProps &
  BlankableSearchConditionProps

export const BlankableDateTermCondition = ({
  value,
  blank,
  onChangeBlank,
  onChange,
  colSpan,
  onEnter,
}: BlankableDateTermConditionProps) => {
  return (
    <>
      <DateTermCell
        colSpan={colSpan}
        value={value}
        onChange={onChange}
        onEnter={onEnter}
        disabled={blank}
        blank={blank}
        onChangeBlank={onChangeBlank}
      />
    </>
  )
}
