import { connect } from 'react-redux'
import { AllState } from '../../../../../store'
import ReloadButton from '../../../../components/buttons/ReloadButton'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  onSubmit: () => void
  isLoading: boolean
  onCancel: () => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps & SettingSpeedDialProps

const TagsHeader = ({
  onSubmit,
  isLoading,
  notEdited,
  onCancel,
  rowHeight,
  onClickChangeRowHeight,
  onChangeToolbar,
  toolbar,
  isNotice,
}: Props) => {
  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <SubmitButton
            onClick={onSubmit}
            disabled={isLoading || notEdited}
            notEdited={notEdited}
          />
          <ReloadButton onClick={onCancel} disabled={isLoading} />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
      </HeaderContent>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(TagsHeader)
