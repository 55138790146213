import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import ProjectAPI, { ProjectDetail } from './project'
import {
  WbsItemDeltaInput,
  WbsItemDetail,
  WbsItemInput,
  WbsItemRow,
} from './wbsItem'
import { BreadCrumb } from '../../store/breadcrumb'
import { WbsItemStatus } from '../../view/containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { TicketCumulation } from './ticketList'
import { CommentSummary } from '../../store/comments'
import { AttachmentSummary } from '../../utils/attachment'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'
import { DateTerm } from '../../utils/date'
import { TagsInput } from './tag'
import { WbsItemTypeVO } from '../../domain/value-object/WbsItemTypeVO'

export interface ResponseOfGetTree {
  data: ProjectPlanDetail[]
}

export interface RequestOfUpdateBatchDelta {
  projectPlans: ProjectPlanUpdateBatchDeltaRequest
  productBacklogItems?: ProductBacklogItemInput[]
  sprintProductItems?: SprintItemInput[]
  sprintBacklogItems?: SprintItemInput[]
  ticketLists?: TicketListInput[]
  tickets?: TicketInput[]
  watchers?: WatchersInput[]
  tags?: TagsInput[]
}

export interface ProductBacklogItemInput {
  uuid: string
  wbsItemUuid?: string
  teamUuid?: string
  productBacklogItem: boolean
}

export interface SprintItemInput {
  uuid: string
  wbsItemUuid?: string
  wbsItemLockVersion?: number
  sprintUuid?: string
}

export interface TicketListInput {
  uuid: string
  ticketType: string
}

export interface TicketInput {
  uuid: string
  ticketType: string
  ticketListUuid: string
}

export interface WatchersInput {
  uuid: string
  wbsItemUuid: string | undefined
  userUuids: string[]
}

export interface ProjectPlanUpdateBatchRequest {
  projectUuid: string
  rootProjectPlanUuid: string
  rootProjectPlanLockVersion: number
  added: ProjectPlanBatchInput[]
  edited: ProjectPlanBatchInput[]
  deleted: {
    uuid: string
    lockVersion: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
}

export interface ProjectPlanUpdateBatchDeltaRequest {
  projectUuid: string
  rootProjectPlanUuid?: string
  rootProjectPlanLockVersion?: number
  added: ProjectPlanBatchInput[]
  edited: ProjectPlanBatchDeltaInput[]
  deleted: {
    uuid: string
    lockVersion: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
}

export interface ResponseOfUpdateBatch {
  rootProjectPlan: {
    uuid: string
    lockVersion: number
  }
  added: {
    uuid: string
    lockVersion: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
  edited: {
    uuid: string
    lockVersion: number
    wbsItemUuid: string
    wbsItemLockVersion: number
  }[]
  deleted: {
    uuid: string
    wbsItemUuid: string
  }[]
}

export class ProjectPlanCumulation {
  projectPlanUuid?: string
  wbsItemUuid?: string
  sumDeliverableEstimatedStoryPoint: number = 0
  sumDeliverableEstimatedHour: number = 0
  sumDeliverableEstimatedHourTodo: number = 0
  sumDeliverableEstimatedHourDoing: number = 0
  sumDeliverableEstimatedHourReview: number = 0
  sumDeliverableEstimatedHourDone: number = 0
  sumDeliverableEstimatedHourDiscard: number = 0
  sumTaskEstimatedStoryPoint: number = 0
  sumTaskEstimatedHour: number = 0
  sumTaskEstimatedHourTodo: number = 0
  sumTaskEstimatedHourDoing: number = 0
  sumTaskEstimatedHourReview: number = 0
  sumTaskEstimatedHourDone: number = 0
  sumTaskEstimatedHourDiscard: number = 0
  actualHour: number = 0
  sumActualHour: number = 0
  sumActualHourTodo: number = 0
  sumActualHourDoing: number = 0
  sumActualHourReview: number = 0
  sumActualHourDone: number = 0
  sumActualHourDiscard: number = 0
  countDeliverable: number = 0
  countStatusDeliverableDiscard: number = 0
  countStatusDeliverableTodo: number = 0
  countStatusDeliverableDoing: number = 0
  countStatusDeliverableReview: number = 0
  countStatusDeliverableDone: number = 0
  countTask: number = 0
  countStatusTaskDiscard: number = 0
  countStatusTaskTodo: number = 0
  countStatusTaskDoing: number = 0
  countStatusTaskReview: number = 0
  countStatusTaskDone: number = 0
  sumTaskEstimatedHourOfDirectChildren: number = 0
  sumTaskEstimatedHourDoneOfDirectChildren: number = 0
  sumTaskEstimatedHourDiscardOfDirectChildren: number = 0
  sumActualHourOfDirectChildren: number = 0
  countTaskOfDirectChildren: number = 0
  countTaskTodoOfDirectChildren: number = 0
  countTaskDoingOfDirectChildren: number = 0
  countTaskReviewOfDirectChildren: number = 0
  countTaskDoneOfDirectChildren: number = 0
  countTaskDiscardOfDirectChildren: number = 0
  sumTaskPlannedHourOfDirectChildren: number = 0
  sumTaskUnplannedHourOfDirectChildren: number = 0
  sumDelayedTaskEstimatedHourOfDirectChildren: number = 0
  sumPrecedingTaskEstimatedHourOfDirectChildren: number = 0
  countDelayedTaskOfDirectChildren: number = 0
  countTaskPlannedItemOfDirectChildren: number = 0
  countTaskPrecedingOfDirectChildren: number = 0
  minScheduledStartDate?: string
  maxScheduledEndDate?: string
  minActualStartDate?: string
  maxActualEndDate?: string

  sumDeliverableToBeStarted: number = 0
  sumDeliverableStartPreceding: number = 0
  sumDeliverableStartDelayed: number = 0
  sumDeliverableStartUnplanned: number = 0
  sumDeliverableToBeCompleted: number = 0
  sumDeliverableEndPreceding: number = 0
  sumDeliverableEndDelayed: number = 0
  sumDeliverableEndUnplanned: number = 0

  sumTaskToBeStarted: number = 0
  sumTaskStartPreceding: number = 0
  sumTaskStartDelayed: number = 0
  sumTaskStartUnplanned: number = 0
  sumTaskToBeCompleted: number = 0
  sumTaskEndPreceding: number = 0
  sumTaskEndDelayed: number = 0
  sumTaskEndUnplanned: number = 0

  countDeliverableToBeStarted: number = 0
  countDeliverableStartPreceding: number = 0
  countDeliverableStartDelayed: number = 0
  countDeliverableStartUnplanned: number = 0
  countDeliverableToBeCompleted: number = 0
  countDeliverableEndPreceding: number = 0
  countDeliverableEndDelayed: number = 0
  countDeliverableEndUnplanned: number = 0

  countTaskToBeStarted: number = 0
  countTaskStartPreceding: number = 0
  countTaskStartDelayed: number = 0
  countTaskStartUnplanned: number = 0
  countTaskToBeCompleted: number = 0
  countTaskEndPreceding: number = 0
  countTaskEndDelayed: number = 0
  countTaskEndUnplanned: number = 0

  sumTaskEstimatedAmount: number = 0
  sumDeliverableEstimatedAmount: number = 0
  sumTaskActualAmount: number = 0
  sumTaskEstimatedAmountOfDirectChildren: number = 0

  static sum = (
    cumulations: ProjectPlanCumulation[]
  ): ProjectPlanCumulation => {
    const c = new ProjectPlanCumulation()
    for (let s of cumulations) {
      c.sumDeliverableEstimatedStoryPoint += s.sumDeliverableEstimatedStoryPoint
      c.sumDeliverableEstimatedHour += s.sumDeliverableEstimatedHour
      c.sumDeliverableEstimatedHourTodo += s.sumDeliverableEstimatedHourTodo
      c.sumDeliverableEstimatedHourDoing += s.sumDeliverableEstimatedHourDoing
      c.sumDeliverableEstimatedHourReview += s.sumDeliverableEstimatedHourReview
      c.sumDeliverableEstimatedHourDone += s.sumDeliverableEstimatedHourDone
      c.sumDeliverableEstimatedHourDiscard +=
        s.sumDeliverableEstimatedHourDiscard
      c.sumTaskEstimatedStoryPoint += s.sumTaskEstimatedStoryPoint
      c.sumTaskEstimatedHour += s.sumTaskEstimatedHour
      c.sumTaskEstimatedHourTodo += s.sumTaskEstimatedHourTodo
      c.sumTaskEstimatedHourDoing += s.sumTaskEstimatedHourDoing
      c.sumTaskEstimatedHourReview += s.sumTaskEstimatedHourReview
      c.sumTaskEstimatedHourDone += s.sumTaskEstimatedHourDone
      c.sumTaskEstimatedHourDiscard += s.sumTaskEstimatedHourDiscard
      c.actualHour += s.actualHour
      c.sumActualHour += s.sumActualHour
      c.sumActualHourTodo += s.sumActualHourTodo
      c.sumActualHourDoing += s.sumActualHourDoing
      c.sumActualHourReview += s.sumActualHourReview
      c.sumActualHourDone += s.sumActualHourDone
      c.sumActualHourDiscard += s.sumActualHourDiscard
      c.countDeliverable += s.countDeliverable
      c.countStatusDeliverableDiscard += s.countStatusDeliverableDiscard
      c.countStatusDeliverableTodo += s.countStatusDeliverableTodo
      c.countStatusDeliverableDoing += s.countStatusDeliverableDoing
      c.countStatusDeliverableReview += s.countStatusDeliverableReview
      c.countStatusDeliverableDone += s.countStatusDeliverableDone
      c.countTask += s.countTask
      c.countStatusTaskDiscard += s.countStatusTaskDiscard
      c.countStatusTaskTodo += s.countStatusTaskTodo
      c.countStatusTaskDoing += s.countStatusTaskDoing
      c.countStatusTaskReview += s.countStatusTaskReview
      c.countStatusTaskDone += s.countStatusTaskDone
      c.sumTaskEstimatedHourOfDirectChildren +=
        s.sumTaskEstimatedHourOfDirectChildren
      c.sumTaskEstimatedHourDoneOfDirectChildren +=
        s.sumTaskEstimatedHourDoneOfDirectChildren
      c.sumTaskEstimatedHourDiscardOfDirectChildren +=
        s.sumTaskEstimatedHourDiscardOfDirectChildren
      c.sumActualHourOfDirectChildren += s.sumActualHourOfDirectChildren
      c.countTaskOfDirectChildren += s.countTaskOfDirectChildren
      c.countTaskTodoOfDirectChildren += s.countTaskTodoOfDirectChildren
      c.countTaskDoingOfDirectChildren += s.countTaskDoingOfDirectChildren
      c.countTaskReviewOfDirectChildren += s.countTaskReviewOfDirectChildren
      c.countTaskDoneOfDirectChildren += s.countTaskDoneOfDirectChildren
      c.countTaskDiscardOfDirectChildren += s.countTaskDiscardOfDirectChildren
      c.sumTaskPlannedHourOfDirectChildren +=
        s.sumTaskPlannedHourOfDirectChildren
      c.sumTaskUnplannedHourOfDirectChildren +=
        s.sumTaskUnplannedHourOfDirectChildren
      c.sumDelayedTaskEstimatedHourOfDirectChildren +=
        s.sumDelayedTaskEstimatedHourOfDirectChildren
      c.sumPrecedingTaskEstimatedHourOfDirectChildren +=
        s.sumPrecedingTaskEstimatedHourOfDirectChildren
      c.countDelayedTaskOfDirectChildren += s.countDelayedTaskOfDirectChildren
      c.countTaskPlannedItemOfDirectChildren +=
        s.countTaskPlannedItemOfDirectChildren
      c.countTaskPrecedingOfDirectChildren +=
        s.countTaskPrecedingOfDirectChildren
      c.sumDeliverableToBeStarted += s.sumDeliverableToBeStarted
      c.sumDeliverableStartPreceding += s.sumDeliverableStartPreceding
      c.sumDeliverableStartDelayed += s.sumDeliverableStartDelayed
      c.sumDeliverableStartUnplanned += s.sumDeliverableStartUnplanned
      c.sumDeliverableToBeCompleted += s.sumDeliverableToBeCompleted
      c.sumDeliverableEndPreceding += s.sumDeliverableEndPreceding
      c.sumDeliverableEndDelayed += s.sumDeliverableEndDelayed
      c.sumDeliverableEndUnplanned += s.sumDeliverableEndUnplanned
      c.sumTaskToBeStarted += s.sumTaskToBeStarted
      c.sumTaskStartPreceding += s.sumTaskStartPreceding
      c.sumTaskStartDelayed += s.sumTaskStartDelayed
      c.sumTaskStartUnplanned += s.sumTaskStartUnplanned
      c.sumTaskToBeCompleted += s.sumTaskToBeCompleted
      c.sumTaskEndPreceding += s.sumTaskEndPreceding
      c.sumTaskEndDelayed += s.sumTaskEndDelayed
      c.sumTaskEndUnplanned += s.sumTaskEndUnplanned
      c.countDeliverableToBeStarted += s.countDeliverableToBeStarted
      c.countDeliverableStartPreceding += s.countDeliverableStartPreceding
      c.countDeliverableStartDelayed += s.countDeliverableStartDelayed
      c.countDeliverableStartUnplanned += s.countDeliverableStartUnplanned
      c.countDeliverableToBeCompleted += s.countDeliverableToBeCompleted
      c.countDeliverableEndPreceding += s.countDeliverableEndPreceding
      c.countDeliverableEndDelayed += s.countDeliverableEndDelayed
      c.countDeliverableEndUnplanned += s.countDeliverableEndUnplanned
      c.countTaskToBeStarted += s.countTaskToBeStarted
      c.countTaskStartPreceding += s.countTaskStartPreceding
      c.countTaskStartDelayed += s.countTaskStartDelayed
      c.countTaskStartUnplanned += s.countTaskStartUnplanned
      c.countTaskToBeCompleted += s.countTaskToBeCompleted
      c.countTaskEndPreceding += s.countTaskEndPreceding
      c.countTaskEndDelayed += s.countTaskEndDelayed
      c.countTaskEndUnplanned += s.countTaskEndUnplanned
      c.sumTaskEstimatedAmount += s.sumTaskEstimatedAmount
      c.sumDeliverableEstimatedAmount += s.sumDeliverableEstimatedAmount
      c.sumTaskActualAmount += s.sumTaskActualAmount
      c.sumTaskEstimatedAmountOfDirectChildren +=
        s.sumTaskEstimatedAmountOfDirectChildren
    }
    return c
  }

  static minus = (
    c: ProjectPlanCumulation,
    s: ProjectPlanCumulation
  ): ProjectPlanCumulation => {
    c.sumDeliverableEstimatedStoryPoint -= s.sumDeliverableEstimatedStoryPoint
    c.sumDeliverableEstimatedHour -= s.sumDeliverableEstimatedHour
    c.sumDeliverableEstimatedHourTodo -= s.sumDeliverableEstimatedHourTodo
    c.sumDeliverableEstimatedHourDoing -= s.sumDeliverableEstimatedHourDoing
    c.sumDeliverableEstimatedHourReview -= s.sumDeliverableEstimatedHourReview
    c.sumDeliverableEstimatedHourDone -= s.sumDeliverableEstimatedHourDone
    c.sumDeliverableEstimatedHourDiscard -= s.sumDeliverableEstimatedHourDiscard
    c.sumTaskEstimatedStoryPoint -= s.sumTaskEstimatedStoryPoint
    c.sumTaskEstimatedHour -= s.sumTaskEstimatedHour
    c.sumTaskEstimatedHourTodo -= s.sumTaskEstimatedHourTodo
    c.sumTaskEstimatedHourDoing -= s.sumTaskEstimatedHourDoing
    c.sumTaskEstimatedHourReview -= s.sumTaskEstimatedHourReview
    c.sumTaskEstimatedHourDone -= s.sumTaskEstimatedHourDone
    c.sumTaskEstimatedHourDiscard -= s.sumTaskEstimatedHourDiscard
    c.actualHour -= s.actualHour
    c.sumActualHour -= s.sumActualHour
    c.sumActualHourTodo -= s.sumActualHourTodo
    c.sumActualHourDoing -= s.sumActualHourDoing
    c.sumActualHourReview -= s.sumActualHourReview
    c.sumActualHourDone -= s.sumActualHourDone
    c.sumActualHourDiscard -= s.sumActualHourDiscard
    c.countDeliverable -= s.countDeliverable
    c.countStatusDeliverableDiscard -= s.countStatusDeliverableDiscard
    c.countStatusDeliverableTodo -= s.countStatusDeliverableTodo
    c.countStatusDeliverableDoing -= s.countStatusDeliverableDoing
    c.countStatusDeliverableReview -= s.countStatusDeliverableReview
    c.countStatusDeliverableDone -= s.countStatusDeliverableDone
    c.countTask -= s.countTask
    c.countStatusTaskDiscard -= s.countStatusTaskDiscard
    c.countStatusTaskTodo -= s.countStatusTaskTodo
    c.countStatusTaskDoing -= s.countStatusTaskDoing
    c.countStatusTaskReview -= s.countStatusTaskReview
    c.countStatusTaskDone -= s.countStatusTaskDone
    c.sumTaskEstimatedHourOfDirectChildren -=
      s.sumTaskEstimatedHourOfDirectChildren
    c.sumTaskEstimatedHourDoneOfDirectChildren -=
      s.sumTaskEstimatedHourDoneOfDirectChildren
    c.sumTaskEstimatedHourDiscardOfDirectChildren -=
      s.sumTaskEstimatedHourDiscardOfDirectChildren
    c.sumActualHourOfDirectChildren -= s.sumActualHourOfDirectChildren
    c.countTaskOfDirectChildren -= s.countTaskOfDirectChildren
    c.countTaskTodoOfDirectChildren -= s.countTaskTodoOfDirectChildren
    c.countTaskDoingOfDirectChildren -= s.countTaskDoingOfDirectChildren
    c.countTaskReviewOfDirectChildren -= s.countTaskReviewOfDirectChildren
    c.countTaskDoneOfDirectChildren -= s.countTaskDoneOfDirectChildren
    c.countTaskDiscardOfDirectChildren -= s.countTaskDiscardOfDirectChildren
    c.sumTaskPlannedHourOfDirectChildren -= s.sumTaskPlannedHourOfDirectChildren
    c.sumTaskUnplannedHourOfDirectChildren -=
      s.sumTaskUnplannedHourOfDirectChildren
    c.sumDelayedTaskEstimatedHourOfDirectChildren -=
      s.sumDelayedTaskEstimatedHourOfDirectChildren
    c.sumPrecedingTaskEstimatedHourOfDirectChildren -=
      s.sumPrecedingTaskEstimatedHourOfDirectChildren
    c.countDelayedTaskOfDirectChildren -= s.countDelayedTaskOfDirectChildren
    c.countTaskPlannedItemOfDirectChildren -=
      s.countTaskPlannedItemOfDirectChildren
    c.countTaskPrecedingOfDirectChildren -= s.countTaskPrecedingOfDirectChildren
    c.sumDeliverableToBeStarted -= s.sumDeliverableToBeStarted
    c.sumDeliverableStartPreceding -= s.sumDeliverableStartPreceding
    c.sumDeliverableStartDelayed -= s.sumDeliverableStartDelayed
    c.sumDeliverableStartUnplanned -= s.sumDeliverableStartUnplanned
    c.sumDeliverableToBeCompleted -= s.sumDeliverableToBeCompleted
    c.sumDeliverableEndPreceding -= s.sumDeliverableEndPreceding
    c.sumDeliverableEndDelayed -= s.sumDeliverableEndDelayed
    c.sumDeliverableEndUnplanned -= s.sumDeliverableEndUnplanned
    c.sumTaskToBeStarted -= s.sumTaskToBeStarted
    c.sumTaskStartPreceding -= s.sumTaskStartPreceding
    c.sumTaskStartDelayed -= s.sumTaskStartDelayed
    c.sumTaskStartUnplanned -= s.sumTaskStartUnplanned
    c.sumTaskToBeCompleted -= s.sumTaskToBeCompleted
    c.sumTaskEndPreceding -= s.sumTaskEndPreceding
    c.sumTaskEndDelayed -= s.sumTaskEndDelayed
    c.sumTaskEndUnplanned -= s.sumTaskEndUnplanned
    c.countDeliverableToBeStarted -= s.countDeliverableToBeStarted
    c.countDeliverableStartPreceding -= s.countDeliverableStartPreceding
    c.countDeliverableStartDelayed -= s.countDeliverableStartDelayed
    c.countDeliverableStartUnplanned -= s.countDeliverableStartUnplanned
    c.countDeliverableToBeCompleted -= s.countDeliverableToBeCompleted
    c.countDeliverableEndPreceding -= s.countDeliverableEndPreceding
    c.countDeliverableEndDelayed -= s.countDeliverableEndDelayed
    c.countDeliverableEndUnplanned -= s.countDeliverableEndUnplanned
    c.countTaskToBeStarted -= s.countTaskToBeStarted
    c.countTaskStartPreceding -= s.countTaskStartPreceding
    c.countTaskStartDelayed -= s.countTaskStartDelayed
    c.countTaskStartUnplanned -= s.countTaskStartUnplanned
    c.countTaskToBeCompleted -= s.countTaskToBeCompleted
    c.countTaskEndPreceding -= s.countTaskEndPreceding
    c.countTaskEndDelayed -= s.countTaskEndDelayed
    c.countTaskEndUnplanned -= s.countTaskEndUnplanned
    c.sumTaskEstimatedAmount -= s.sumTaskEstimatedAmount
    c.sumDeliverableEstimatedAmount -= s.sumDeliverableEstimatedAmount
    c.sumTaskActualAmount -= s.sumTaskActualAmount
    c.sumTaskEstimatedAmountOfDirectChildren -=
      s.sumTaskEstimatedAmountOfDirectChildren
    return c
  }
}

export const addCumulation = (
  target: ProjectPlanCumulation,
  cumulation: ProjectPlanCumulation,
  wbsItem: WbsItemRow
) => {
  const status = wbsItem.status!
  target.sumDeliverableEstimatedStoryPoint +=
    cumulation.sumDeliverableEstimatedStoryPoint
  target.sumDeliverableEstimatedHour += cumulation.sumDeliverableEstimatedHour
  target.sumDeliverableEstimatedHourTodo +=
    cumulation.sumDeliverableEstimatedHourTodo
  target.sumDeliverableEstimatedHourDoing +=
    cumulation.sumDeliverableEstimatedHourDoing
  target.sumDeliverableEstimatedHourReview +=
    cumulation.sumDeliverableEstimatedHourReview
  target.sumDeliverableEstimatedHourDone +=
    cumulation.sumDeliverableEstimatedHourDone
  target.sumDeliverableEstimatedHourDiscard +=
    cumulation.sumDeliverableEstimatedHourDiscard
  target.sumTaskEstimatedStoryPoint += cumulation.sumTaskEstimatedStoryPoint
  target.sumTaskEstimatedHour += cumulation.sumTaskEstimatedHour
  target.sumTaskEstimatedHourTodo += cumulation.sumTaskEstimatedHourTodo
  target.sumTaskEstimatedHourDoing += cumulation.sumTaskEstimatedHourDoing
  target.sumTaskEstimatedHourReview += cumulation.sumTaskEstimatedHourReview
  target.sumTaskEstimatedHourDone += cumulation.sumTaskEstimatedHourDone
  target.sumTaskEstimatedHourDiscard += cumulation.sumTaskEstimatedHourDiscard
  target.sumActualHour += cumulation.sumActualHour + cumulation.actualHour
  target.sumActualHourTodo +=
    cumulation.sumActualHourTodo + status === WbsItemStatus.TODO ? 1 : 0
  target.sumActualHourDoing +=
    cumulation.sumActualHourDoing + status === WbsItemStatus.DOING ? 1 : 0
  target.sumActualHourReview +=
    cumulation.sumActualHourReview + status === WbsItemStatus.REVIEW ? 1 : 0
  target.sumActualHourDone +=
    cumulation.sumActualHourDone + status === WbsItemStatus.DONE ? 1 : 0
  target.sumActualHourDiscard +=
    cumulation.sumActualHourDiscard + status === WbsItemStatus.DISCARD ? 1 : 0
  target.countStatusDeliverableDiscard +=
    cumulation.countStatusDeliverableDiscard
  target.countStatusDeliverableTodo += cumulation.countStatusDeliverableTodo
  target.countStatusDeliverableDoing += cumulation.countStatusDeliverableDoing
  target.countStatusDeliverableReview += cumulation.countStatusDeliverableReview
  target.countStatusDeliverableDone += cumulation.countStatusDeliverableDone
  target.countStatusTaskDiscard += cumulation.countStatusTaskDiscard
  target.countStatusTaskTodo += cumulation.countStatusTaskTodo
  target.countStatusTaskDoing += cumulation.countStatusTaskDoing
  target.countStatusTaskReview += cumulation.countStatusTaskReview
  target.countStatusTaskDone += cumulation.countStatusTaskDone
  target.sumTaskEstimatedAmount += cumulation.sumTaskEstimatedAmount
  target.sumDeliverableEstimatedAmount +=
    cumulation.sumDeliverableEstimatedAmount
  target.sumTaskActualAmount += cumulation.sumTaskActualAmount
  const estimatedStoryPoint = wbsItem.estimatedStoryPoint || 0
  const estimatedHour = wbsItem.estimatedWorkload?.hour || 0
  const estimatedAmount = wbsItem.estimatedAmount || 0
  const actualAmount = wbsItem.actualAmount || 0
  if (wbsItem.wbsItemType?.isDeliverable()) {
    target.sumDeliverableEstimatedStoryPoint += estimatedStoryPoint
    target.sumDeliverableEstimatedHour += estimatedHour
    target.sumDeliverableEstimatedHourTodo +=
      status === WbsItemStatus.TODO ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDoing +=
      status === WbsItemStatus.DOING ? estimatedHour : 0
    target.sumDeliverableEstimatedHourReview +=
      status === WbsItemStatus.REVIEW ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDone +=
      status === WbsItemStatus.DONE ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDiscard +=
      status === WbsItemStatus.DISCARD ? estimatedHour : 0
    target.countStatusDeliverableTodo += status === WbsItemStatus.TODO ? 1 : 0
    target.countStatusDeliverableDoing += status === WbsItemStatus.DOING ? 1 : 0
    target.countStatusDeliverableReview +=
      status === WbsItemStatus.REVIEW ? 1 : 0
    target.countStatusDeliverableDone += status === WbsItemStatus.DONE ? 1 : 0
    target.countStatusDeliverableDiscard +=
      status === WbsItemStatus.DISCARD ? 1 : 0
    target.sumDeliverableEstimatedAmount +=
      status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
  } else if (wbsItem.wbsItemType?.isTask()) {
    target.sumTaskEstimatedStoryPoint += estimatedStoryPoint
    target.sumTaskEstimatedHour += estimatedHour
    target.sumTaskEstimatedHourTodo +=
      status === WbsItemStatus.TODO ? estimatedHour : 0
    target.sumTaskEstimatedHourDoing +=
      status === WbsItemStatus.DOING ? estimatedHour : 0
    target.sumTaskEstimatedHourReview +=
      status === WbsItemStatus.REVIEW ? estimatedHour : 0
    target.sumTaskEstimatedHourDone +=
      status === WbsItemStatus.DONE ? estimatedHour : 0
    target.sumTaskEstimatedHourDiscard +=
      status === WbsItemStatus.DISCARD ? estimatedHour : 0
    target.countStatusTaskTodo += status === WbsItemStatus.TODO ? 1 : 0
    target.countStatusTaskDoing += status === WbsItemStatus.DOING ? 1 : 0
    target.countStatusTaskReview += status === WbsItemStatus.REVIEW ? 1 : 0
    target.countStatusTaskDone += status === WbsItemStatus.DONE ? 1 : 0
    target.countStatusTaskDiscard += status === WbsItemStatus.DISCARD ? 1 : 0
    target.sumTaskEstimatedAmount +=
      status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
    target.sumTaskActualAmount +=
      status !== WbsItemStatus.DISCARD ? actualAmount : 0
  }
  return target
}

export const subtractCumulation = (
  target: ProjectPlanCumulation,
  cumulation: ProjectPlanCumulation,
  wbsItem: WbsItemRow
) => {
  target.sumDeliverableEstimatedStoryPoint -=
    cumulation.sumDeliverableEstimatedStoryPoint
  target.sumDeliverableEstimatedHour -= cumulation.sumDeliverableEstimatedHour
  target.sumDeliverableEstimatedHourTodo -=
    cumulation.sumDeliverableEstimatedHourTodo
  target.sumDeliverableEstimatedHourDoing -=
    cumulation.sumDeliverableEstimatedHourDoing
  target.sumDeliverableEstimatedHourReview -=
    cumulation.sumDeliverableEstimatedHourReview
  target.sumDeliverableEstimatedHourDone -=
    cumulation.sumDeliverableEstimatedHourDone
  target.sumDeliverableEstimatedHourDiscard -=
    cumulation.sumDeliverableEstimatedHourDiscard
  target.sumTaskEstimatedStoryPoint -= cumulation.sumTaskEstimatedStoryPoint
  target.sumTaskEstimatedHour -= cumulation.sumTaskEstimatedHour
  target.sumTaskEstimatedHourTodo -= cumulation.sumTaskEstimatedHourTodo
  target.sumTaskEstimatedHourDoing -= cumulation.sumTaskEstimatedHourDoing
  target.sumTaskEstimatedHourReview -= cumulation.sumTaskEstimatedHourReview
  target.sumTaskEstimatedHourDone -= cumulation.sumTaskEstimatedHourDone
  target.sumTaskEstimatedHourDiscard -= cumulation.sumTaskEstimatedHourDiscard
  target.sumActualHour -= cumulation.sumActualHour
  target.sumActualHour -= cumulation.actualHour
  target.sumActualHourTodo -= cumulation.sumActualHourTodo
  target.sumActualHourDoing -= cumulation.sumActualHourDoing
  target.sumActualHourReview -= cumulation.sumActualHourReview
  target.sumActualHourDone -= cumulation.sumActualHourDone
  target.sumActualHourDiscard -= cumulation.sumActualHourDiscard
  target.sumActualHourTodo -= wbsItem.status === WbsItemStatus.TODO ? 1 : 0
  target.sumActualHourDoing -= wbsItem.status === WbsItemStatus.DOING ? 1 : 0
  target.sumActualHourReview -= wbsItem.status === WbsItemStatus.REVIEW ? 1 : 0
  target.sumActualHourDone -= wbsItem.status === WbsItemStatus.DONE ? 1 : 0
  target.sumActualHourDiscard -=
    wbsItem.status === WbsItemStatus.DISCARD ? 1 : 0
  target.countStatusDeliverableDiscard -=
    cumulation.countStatusDeliverableDiscard
  target.countStatusDeliverableTodo -= cumulation.countStatusDeliverableTodo
  target.countStatusDeliverableDoing -= cumulation.countStatusDeliverableDoing
  target.countStatusDeliverableReview -= cumulation.countStatusDeliverableReview
  target.countStatusDeliverableDone -= cumulation.countStatusDeliverableDone
  target.countStatusTaskDiscard -= cumulation.countStatusTaskDiscard
  target.countStatusTaskTodo -= cumulation.countStatusTaskTodo
  target.countStatusTaskDoing -= cumulation.countStatusTaskDoing
  target.countStatusTaskReview -= cumulation.countStatusTaskReview
  target.countStatusTaskDone -= cumulation.countStatusTaskDone
  target.sumTaskEstimatedAmount -= cumulation.sumTaskEstimatedAmount
  target.sumDeliverableEstimatedAmount -=
    cumulation.sumDeliverableEstimatedAmount
  target.sumTaskActualAmount -= cumulation.sumTaskActualAmount
  const status = wbsItem.status
  const estimatedStoryPoint = wbsItem.estimatedStoryPoint || 0
  const estimatedHour = wbsItem.estimatedWorkload?.hour || 0
  const estimatedAmount = wbsItem.estimatedAmount || 0
  const actualAmount = wbsItem.actualAmount || 0
  if (wbsItem.wbsItemType?.isDeliverable()) {
    target.sumDeliverableEstimatedStoryPoint -= estimatedStoryPoint
    target.sumDeliverableEstimatedHour -= estimatedHour
    target.sumDeliverableEstimatedHourTodo -=
      status === WbsItemStatus.TODO ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDoing -=
      status === WbsItemStatus.DOING ? estimatedHour : 0
    target.sumDeliverableEstimatedHourReview -=
      status === WbsItemStatus.REVIEW ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDone -=
      status === WbsItemStatus.DONE ? estimatedHour : 0
    target.sumDeliverableEstimatedHourDiscard -=
      status === WbsItemStatus.DISCARD ? estimatedHour : 0
    target.countStatusDeliverableTodo -= status === WbsItemStatus.TODO ? 1 : 0
    target.countStatusDeliverableDoing -= status === WbsItemStatus.DOING ? 1 : 0
    target.countStatusDeliverableReview -=
      status === WbsItemStatus.REVIEW ? 1 : 0
    target.countStatusDeliverableDone -= status === WbsItemStatus.DONE ? 1 : 0
    target.countStatusDeliverableDiscard -=
      status !== WbsItemStatus.DISCARD ? 1 : 0
    target.sumDeliverableEstimatedAmount -=
      status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
  } else if (wbsItem.wbsItemType?.isTask()) {
    target.sumTaskEstimatedStoryPoint -= estimatedStoryPoint
    target.sumTaskEstimatedHour -= estimatedHour
    target.sumTaskEstimatedHourTodo -=
      status === WbsItemStatus.TODO ? estimatedHour : 0
    target.sumTaskEstimatedHourDoing -=
      status === WbsItemStatus.DOING ? estimatedHour : 0
    target.sumTaskEstimatedHourReview -=
      status === WbsItemStatus.REVIEW ? estimatedHour : 0
    target.sumTaskEstimatedHourDone -=
      status === WbsItemStatus.DONE ? estimatedHour : 0
    target.sumTaskEstimatedHourDiscard -=
      status === WbsItemStatus.DISCARD ? estimatedHour : 0
    target.countStatusTaskTodo -= status === WbsItemStatus.TODO ? 1 : 0
    target.countStatusTaskDoing -= status === WbsItemStatus.DOING ? 1 : 0
    target.countStatusTaskReview -= status === WbsItemStatus.REVIEW ? 1 : 0
    target.countStatusTaskDone -= status === WbsItemStatus.DONE ? 1 : 0
    target.countStatusTaskDiscard -= status === WbsItemStatus.DISCARD ? 1 : 0
    target.sumTaskEstimatedAmount -=
      status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
    target.sumTaskActualAmount -=
      status !== WbsItemStatus.DISCARD ? actualAmount : 0
  }
  return target
}

export const addDirectCumulation = (
  target: ProjectPlanCumulation,
  cumulation: ProjectPlanCumulation,
  wbsItem: WbsItemRow
) => {
  const estimatedHour = wbsItem.estimatedWorkload?.hour || 0
  const estimatedAmount = wbsItem.estimatedAmount || 0
  target.sumTaskEstimatedHourOfDirectChildren += estimatedHour
  target.sumTaskEstimatedAmountOfDirectChildren +=
    wbsItem.status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
  target.sumTaskEstimatedHourDoneOfDirectChildren +=
    wbsItem.status === WbsItemStatus.DONE ? estimatedHour : 0
  target.sumTaskEstimatedHourDiscardOfDirectChildren +=
    wbsItem.status === WbsItemStatus.DISCARD ? estimatedHour : 0
  target.sumActualHourOfDirectChildren += cumulation.actualHour
  target.countTaskOfDirectChildren += 1
  if (wbsItem.status === WbsItemStatus.TODO) {
    target.countTaskTodoOfDirectChildren += 1
  } else if (wbsItem.status === WbsItemStatus.DOING) {
    target.countTaskDoingOfDirectChildren += 1
  } else if (wbsItem.status === WbsItemStatus.REVIEW) {
    target.countTaskReviewOfDirectChildren += 1
  } else if (wbsItem.status === WbsItemStatus.DONE) {
    target.countTaskDoneOfDirectChildren += 1
  } else if (wbsItem.status === WbsItemStatus.DISCARD) {
    target.countTaskDiscardOfDirectChildren += 1
  }
  return target
}

export const subtractDirectCumulation = (
  target: ProjectPlanCumulation,
  cumulation: ProjectPlanCumulation,
  wbsItem: WbsItemRow
) => {
  const estimatedHour = wbsItem.estimatedWorkload?.hour || 0
  const estimatedAmount = wbsItem.estimatedAmount || 0
  target.sumTaskEstimatedHourOfDirectChildren -= estimatedHour
  target.sumTaskEstimatedAmountOfDirectChildren -=
    wbsItem.status !== WbsItemStatus.DISCARD ? estimatedAmount : 0
  target.sumTaskEstimatedHourDoneOfDirectChildren -=
    wbsItem.status === WbsItemStatus.DONE ? estimatedHour : 0
  target.sumTaskEstimatedHourDiscardOfDirectChildren -=
    wbsItem.status === WbsItemStatus.DISCARD ? estimatedHour : 0
  target.sumActualHourOfDirectChildren -= cumulation.actualHour
  target.countTaskOfDirectChildren -= 1
  if (wbsItem.status === WbsItemStatus.TODO) {
    target.countTaskTodoOfDirectChildren -= 1
  } else if (wbsItem.status === WbsItemStatus.DOING) {
    target.countTaskDoingOfDirectChildren -= 1
  } else if (wbsItem.status === WbsItemStatus.REVIEW) {
    target.countTaskReviewOfDirectChildren -= 1
  } else if (wbsItem.status === WbsItemStatus.DONE) {
    target.countTaskDoneOfDirectChildren -= 1
  } else if (wbsItem.status === WbsItemStatus.DISCARD) {
    target.countTaskDiscardOfDirectChildren -= 1
  }
  return target
}

export interface ProjectPlanBasic {
  uuid: string
  wbsItemUuid: string
  code: string
  name: string
  type: WbsItemTypeVO
}

export interface ProjectPlanDetail extends Tree<ProjectPlanDetail> {
  type: WbsItemType
  wbsItem: WbsItemDetail
  cumulation: ProjectPlanCumulation
  productBacklogItem: boolean
  numberOfChildren: number
  ticketType: string
  ticketCumulation: TicketCumulation
  ticketListUuid: string
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
}

export interface ProjectPlanBatchInput {
  uuid: string // クライアントサイドでUUIDを発行
  lockVersion?: number // 更新時のみ
  projectUuid?: string
  parentUuid?: string
  prevSiblingUuid?: string
  type: WbsItemType
  wbsItem: WbsItemInput
  productBacklogItem?: boolean
  currentSprintUuid?: string
  ticketType?: string
}

export interface ProjectPlanBatchDeltaInput {
  uuid: string
  type: WbsItemType
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  wbsItem: WbsItemDeltaInput
}

export interface ProjectPlanPathProps {
  uuid: string
  wbsItem: {
    uuid: string
    code: string
    name: string
    scheduledDate: DateTerm
    typeDto: WbsItemTypeVO
  }
  child: ProjectPlanPathProps
}

export async function getProjectPlanBreadcrumbs(
  projectUuid: string,
  projectPlanUuid: string,
  projectCode?: string
) {
  const [
    projectPlanPathResponse,
    projectPlanSiblingsResponse,
    projectResponse,
  ] = await Promise.all([
    getProjectPlanPath(projectPlanUuid),
    getProjectPlanSiblings(projectPlanUuid),
    ProjectAPI.getDetail({ uuid: projectUuid }),
  ])
  const projectPlanPath: ProjectPlanPathProps = projectPlanPathResponse.json
  const projectPlanSiblings: ProjectPlanBasic[] =
    projectPlanSiblingsResponse.json
  const project = projectResponse.json
  if (!projectPlanPath || !projectResponse) {
    return
  }
  const breadcrumbs: BreadCrumb | undefined = createBreadcrumbs(
    project,
    projectPlanPath
  )
  const bottomBreadcrumbOptions: BreadCrumb[] | undefined =
    projectPlanSiblings.map((siblings: ProjectPlanBasic) => ({
      name: siblings.name,
      uuid: siblings.uuid,
      code: siblings.code,
      link: createBraedcrumbLink(project.code, siblings.uuid),
      type: siblings.type,
      wbsItemUuid: siblings.wbsItemUuid,
    }))

  return { breadcrumbs, bottomBreadcrumbOptions }
}

export async function getProjectPlanBreadcrumbsByWbsItemUuid(
  projectUuid: string,
  wbsItemUuid: string
) {
  const [projectPlanPathResponse, projectResponse] = await Promise.all([
    getProjectPlanPathByWbsItemUuid(wbsItemUuid),
    ProjectAPI.getDetail({ uuid: projectUuid }),
  ])
  const projectPlanPath: ProjectPlanPathProps = projectPlanPathResponse.json
  const project = projectResponse.json
  if (!projectPlanPath || !projectResponse) {
    return
  }
  return createBreadcrumbs(project, projectPlanPath)
}

function createBraedcrumbLink(
  projectCode: string,
  projectPlanUuid: string
): string {
  return `${window.location.origin}/projectPlan/${projectCode}?treeRootUuid=${projectPlanUuid}`
}

function createBreadcrumbs(
  project: ProjectDetail,
  projectPlanPath: ProjectPlanPathProps
): BreadCrumb | undefined {
  if (!projectPlanPath) {
    return
  }
  return {
    name: projectPlanPath.wbsItem.name,
    uuid: projectPlanPath.uuid,
    code: projectPlanPath.wbsItem.code,
    link: createBraedcrumbLink(project.code, projectPlanPath.uuid),
    next: createBreadcrumbs(project, projectPlanPath.child),
    type: projectPlanPath.wbsItem.typeDto,
    wbsItemUuid: projectPlanPath.wbsItem.uuid,
  }
}

export const getProjectPlanUuidByWbsItemUuid = async (
  wbsItemUuid: string
): Promise<string> => {
  const projectPlanPathResponse = await getProjectPlanPathByWbsItemUuid(
    wbsItemUuid
  )
  let projectPlanPath = projectPlanPathResponse.json as ProjectPlanPathProps
  while (projectPlanPath.child) {
    projectPlanPath = projectPlanPath.child
  }
  return projectPlanPath.uuid
}

export const getWbsItemUuidByProjectPlanUuid = async (
  projectPlanUuid: string
): Promise<string> => {
  const projectPlanPathResponse = await getProjectPlanPath(projectPlanUuid)
  let projectPlanPath = projectPlanPathResponse.json as ProjectPlanPathProps
  while (projectPlanPath.child) {
    projectPlanPath = projectPlanPath.child
  }
  return projectPlanPath.wbsItem.uuid
}

export const getUpdatedRowAncestors = async (
  uuid: string,
  treeRootUuid?: string
): Promise<ProjectPlanDetail> => {
  const ancestors = (await getProjectPlanAncestors(uuid, treeRootUuid))
    .json as ProjectPlanDetail
  return treeRootUuid ? ancestors : ancestors.children[0]
}

export const getAll = async (projectUuid: string) => {
  return getTree(projectUuid, Object.values(WbsItemType))
}

export function getTree(
  projectUuid: string,
  wbsItemTypes: WbsItemType[],
  rootProjectPlanUuid?: string,
  maxDepth?: number
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/descendant', {
    projectUuid,
    wbsItemTypes,
    rootProjectPlanUuid,
    maxDepth,
  })
}

export function updateBatchDelta(
  request: RequestOfUpdateBatchDelta
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/projects/plans/delta/batch',
    request
  )
}

export function getProjectPlanPath(
  projectPlanUuid: string
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/path', {
    projectPlanUuid,
  })
}

export function getProjectPlanPathByWbsItemUuid(
  wbsItemUuid: string
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/path_wbs', {
    wbsItemUuid,
  })
}

export function getProjectPlanAncestors(
  leafProjectPlanUuid: string,
  rootProjectPlanUuid?: string
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/ancestors', {
    leafProjectPlanUuid,
    rootProjectPlanUuid,
  })
}

export function getEarliestScheduledDate(
  projectUuid: string,
  uuid: string
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/plans/find/earliest_scheduled_date',
    {
      projectUuid,
      uuid,
    }
  )
}

export function getLatestScheduledDate(
  projectUuid: string,
  uuid: string
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/projects/plans/find/latest_scheduled_date',
    {
      projectUuid,
      uuid,
    }
  )
}

export function getCumulation(
  projectUuid: string,
  projectPlanUuid?: string,
  teamUuid?: string
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/cumulation', {
    projectUuid,
    projectPlanUuid,
    teamUuid,
  })
}

export function getProjectPlanSiblings(
  projectPlanUuid: string
): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/projects/plans/siblings', {
    projectPlanUuid,
  })
}
