import { Card, CardActions, CardContent, styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded'
import { intl } from '../../../../../i18n'

export const ExternalServiceCard = styled(Card)({
  width: '360px',
  position: 'relative',
})

export const ExternalServiceTitleArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

export const ExternalServiceTitleWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const ExternalServiceTitle = styled('span')({
  fontSize: '14px',
  fontWeight: 600,
  margin: '3px 6px 0',
})

export const ExternalServiceCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

export const ExternalServiceCardExplanation = styled('div')({
  fontSize: '11px',
  padding: '28px 14px 0',
  whiteSpace: 'break-spaces',
})

export const ExternalServiceActions = styled(CardActions)({
  justifyContent: 'center',
  padding: '4px 4px 16px',
})

const ConnectedIcon = styled(CheckCircleIcon)({
  width: '18px',
  height: '18px',
})

const AlreadyConnectedIcon = styled(ConnectedIcon)({
  fill: '#1D7CA8',
})

const NotConnectedIcon = styled(ConnectedIcon)({
  fill: '#888888',
})

const ConnectedInfoArea = styled('div')({
  position: 'absolute',
  top: '12px',
  right: '4px',
  display: 'flex',
  alignItems: 'center',
})

const ConnectedInfoText = styled('span')({
  fontSize: '11px',
  padding: '0 2px',
})

type ConnectedInfoProps = {
  connected: boolean
}

export const ConnectedInfo = ({ connected }: ConnectedInfoProps) => {
  return (
    <ConnectedInfoArea>
      {connected && <AlreadyConnectedIcon />}
      {!connected && <NotConnectedIcon />}
      <ConnectedInfoText>
        {connected && intl.formatMessage({ id: 'externalService.connected' })}
        {!connected &&
          intl.formatMessage({ id: 'externalService.notConnected' })}
      </ConnectedInfoText>
    </ConnectedInfoArea>
  )
}
