import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea, PageProps } from '..'
import { TaskActualWorkDetail } from '../../../lib/functions/taskActualWork'
import { AllState } from '../../../store'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import TaskActualWorkOptions, {
  ColumnQuickFilterKey,
  getDateTerm,
  TaskActualWorkRow,
  TaskActualWorkState,
  DisplayTerm,
  ShiftType,
} from './TaskActualWorkOptions'
import TaskActualWorkHeader from './Header'
import { ColDef } from 'ag-grid-community'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Box, Collapse } from '@mui/material'
import TaskActualWorkToolbar from './Toolbar'
import {
  getDefaultSearchFilter,
  SearchFilter,
  toPlainSearchFilter,
} from '../WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import TaskActualWorkSearchHeader from './Header/TaskActualWorkSearchHeader'
import _ from 'lodash'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { usePageState } from '../../hooks/usePageState'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import DateVO from '../../../vo/DateVO'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { Function } from '../../../lib/commons/appFunction'
import { pageComponent } from '../../higher-order-components/pageComponent'

type TaskActualWorkPageState = {
  toolbar: ToolbarToggleValue | undefined
  dateFormat: string
  displayTerm: DisplayTerm
}

const taskActualWorkDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  displayTerm: DisplayTerm.WEEK,
}

type StateProps = {
  functions: Function[]
}

const TaskActualWork = (props: PageProps & StateProps) => {
  const { project } = useProjectPrivateContext()
  const fn = useMemo(
    () => props.functions.find(v => v.externalId === props.externalId)!,
    [props.functions, props.externalId]
  )

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        TaskActualWorkDetail,
        TaskActualWorkRow,
        TaskActualWorkState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>(
    bulkSheet?.state.searchText || ''
  )
  const [searchFilter, setSearchFilter] = useState<SearchFilter>(
    bulkSheet?.state.searchFilter || getDefaultSearchFilter()
  )

  const { toolbar, dateFormat, displayTerm, updatePageState } =
    usePageState<TaskActualWorkPageState>(
      fn.uuid,
      taskActualWorkDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )
  const changeToolbarToggle = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )
  const changeDateFormat = useCallback((dateFormat: string) => {
    updatePageState({ dateFormat })
  }, [])

  const changeDisplayTerm = useCallback(
    (displayTerm: DisplayTerm) => {
      shiftDisplayTerm(ShiftType.THIS_TERM)
      updatePageState({ displayTerm })
    },
    [bulkSheet]
  )

  const taskActualWorkOptions = useMemo(() => new TaskActualWorkOptions(), [])

  const getSearchCondition = useCallback(() => {
    const searchFilter = bulkSheet?.state.searchFilter
    const searchText = bulkSheet?.state.searchText
    return {
      searchText: searchText,
      searchFilter: searchFilter
        ? toPlainSearchFilter(searchFilter)
        : undefined,
      projectUuid: project.uuid,
    }
  }, [bulkSheet])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      } else if (value === ColumnQuickFilterKey.TERM) {
        setDialogOpen(true)
      }
    },
    [bulkSheet]
  )

  const onSearch = () => {
    const searchFilter = bulkSheet?.state.searchFilter
    bulkSheet?.setState(
      {
        searchText: bulkSheet.state.searchText,
        searchFilterSnapshotOnSearch: searchFilter
          ? _.cloneDeep(searchFilter)
          : getDefaultSearchFilter(),
      },
      () => bulkSheet?.refreshDataWithLoading()
    )
  }

  const onChangeToolbarFilter = (value: SearchFilter) => {
    bulkSheet?.setState(
      {
        searchFilter: value,
        searchFilterSnapshotOnSearch: _.cloneDeep(value),
      },
      () => {
        bulkSheet.refreshDataWithLoading()
      }
    )
  }

  const shiftDisplayTerm = (shiftType: ShiftType) => {
    if (!bulkSheet) return
    const dateList = bulkSheet.state.dateTerm
    let startDate = new DateVO()
    let endDate = new DateVO()
    const oldStartDate = new DateVO(dateList[0])
    switch (shiftType) {
      case ShiftType.THIS_TERM:
        const now = DateVO.now()
        if (displayTerm === DisplayTerm.WEEK) {
          startDate = now.getFirstDayOfWeek().addDays(1)
          endDate = startDate.getLastDayOfWeek().addDays(1)
        } else if (displayTerm === DisplayTerm.MONTH) {
          startDate = now.getFirstDayOfMonth()
          endDate = startDate.getLastDayOfMonth()
        }
        break
      case ShiftType.FORWARD:
        if (displayTerm === DisplayTerm.WEEK) {
          startDate = oldStartDate.addWeeks(1)
          endDate = startDate.getLastDayOfWeek().addDays(1)
        } else if (displayTerm === DisplayTerm.MONTH) {
          startDate = oldStartDate.addMonths(1)
          endDate = startDate.getLastDayOfMonth()
        }
        break
      case ShiftType.BACKWARD:
        if (displayTerm === DisplayTerm.WEEK) {
          startDate = oldStartDate.subtractWeeks(1)
          endDate = startDate.getLastDayOfWeek().addDays(1)
        } else if (displayTerm === DisplayTerm.MONTH) {
          startDate = oldStartDate.subtractMonths(1)
          endDate = startDate.getLastDayOfMonth()
        }
        break
    }
    bulkSheet?.setState({ dateTerm: getDateTerm(startDate, endDate) }, () =>
      bulkSheet?.refreshDynamicColumns(true)
    )
  }

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet?.setContext({ dateFormat })
    bulkSheet?.gridApi?.refreshCells({
      columns: [
        'task.scheduledDate.startDate',
        'task.scheduledDate.endDate',
        'task.actualDate.startDate',
        'task.actualDate.endDate',
        'task.deadline',
      ],
      force: true,
    })
    shiftDisplayTerm(ShiftType.THIS_TERM)
  }, [dateFormat, displayTerm, bulkSheet])

  useEffect(() => {
    setSearchText(bulkSheet?.state.searchText || '')
  }, [bulkSheet?.state.searchText])
  useEffect(() => {
    if (bulkSheet?.state.searchFilter) {
      setSearchFilter(bulkSheet?.state.searchFilter)
    }
  }, [bulkSheet?.state.searchFilter])

  return (
    <PageArea>
      <TaskActualWorkHeader
        toolbar={toolbar}
        onChangeToolbar={changeToolbarToggle}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        filteredColumns={filteredColumns}
        sortedColumns={sortedColumns}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        onCancel={() => bulkSheet?.onCancel()}
        currentFormat={dateFormat}
        onClickChangeDateFormat={changeDateFormat}
        shiftDisplayTerm={shiftDisplayTerm}
        displayTerm={displayTerm}
        onChangeDisplayTerm={changeDisplayTerm}
      />
      <>
        <Collapse in={!!toolbar} timeout={100}>
          <TaskActualWorkToolbar
            onChangeColumnFilter={onChangeColumnFilter}
            filteredColumns={filteredColumns}
            sortedColumns={sortedColumns}
            onDeletedFilterColumn={column =>
              bulkSheet?.resetSpecificColumnFilter(column || '')
            }
          />
        </Collapse>
        <TaskActualWorkSearchHeader
          applicationFunctionUuid={fn.uuid}
          getColumnAndFilterState={() => {
            return bulkSheet?.getColumnAndFilterUIState()
          }}
          openSavedUIStateDialog={() => {
            bulkSheet?.openSavedBulkSheetUIStateDialog(
              UiStateKey.BulkSheetUIStateSearchCondition,
              true
            )
          }}
          onSearch={onSearch}
          onChangeFilter={onChangeToolbarFilter}
          searchText={searchText}
          setSearchText={(value: string) =>
            bulkSheet?.setState({ searchText: value }, () => {
              setSearchText(value)
            })
          }
          searchFilter={searchFilter}
          setSearchFilter={(value: SearchFilter) => {
            bulkSheet?.setState({ searchFilter: value }, () => {
              setSearchFilter(value)
            })
          }}
          getSearchCondition={getSearchCondition}
          hit={bulkSheet?.state.hit || 0}
          total={bulkSheet?.state.total || 0}
        />
      </>
      <Box sx={{ height: '100%' }}>
        <BulkSheetComponent
          {...props}
          options={taskActualWorkOptions}
          hideHeader={true}
          hideToolbar={true}
          setBulkSheet={ctx => setBulkSheet(ctx)}
          setFilteredColumns={columns => setFilteredColumns(columns)}
          setSortedColumns={columns => setSortedColumns(columns)}
          setRowHeight={height => setRowHeight(height)}
          setSubmitDisabled={value => setSubmitDisabled(value)}
          setIsLoading={value => setIsLoading(value)}
        />
      </Box>
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(TaskActualWork))
)
