import cellRenderer from './cellRenderer'
import cellStyle from './cellStyle'
import { ICellRendererParams, EditableCallbackParams } from 'ag-grid-community'
import CreateIcon from '@mui/icons-material/CreateRounded'

export const EditableIcon = () => <CreateIcon className="editable-icon" />

export function FloatingEditableIcon(params: ICellRendererParams) {
  const editable = params.colDef?.editable
  const isEditable =
    typeof editable === 'function'
      ? editable(params as EditableCallbackParams)
      : !!editable
  if (!isEditable) return <></>
  return <EditableIcon />
}

export default {
  cellRenderer,
  cellStyle,
}
