import { intl } from '../../../../i18n'
import { ColumnFilterButtonGroup } from '../../../components/buttons/ColumnFilterButtonGroup'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../components/buttons/ToolbarFilterButtonGroup'
import { HeaderGroup } from '../../../components/headers/HeaderBar/PageHeader'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ColumnQuickFilterKey } from '../ProjectMemberOptions'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

type Props = OwnProps & ToolbarFilterGroupProps

const ProjectMemberToolbar = ({
  toolbar,
  onChangeColumnFilter,
  filteredColumns,
  sortedColumns,
  onDeletedFilterColumn,
}: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === toolbar && (
        <>
          <HeaderGroup>
            <ToolbarFilterButtonGroup
              filteredColumns={filteredColumns}
              sortedColumns={sortedColumns}
              onDeletedFilterColumn={onDeletedFilterColumn}
            />
          </HeaderGroup>
          <HeaderGroup>
            <ColumnFilterButtonGroup
              options={[
                {
                  label: intl.formatMessage({
                    id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
                  }),
                  value: ColumnQuickFilterKey.INITIAL,
                },
                {
                  label: intl.formatMessage({
                    id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
                  }),
                  value: ColumnQuickFilterKey.RESTORE,
                },
              ]}
              onChange={value =>
                onChangeColumnFilter(value as ColumnQuickFilterKey)
              }
            />
          </HeaderGroup>
        </>
      )}
    </Toolbar>
  )
}

export default ProjectMemberToolbar
