import api, { APIResponse } from '../../../lib/commons/api'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'

export class PositionBody {
  uuid: string
  code: string
  displayName: string
  officialName: string
}

export class UnitPriceBody {
  validFrom: string
  validTo: string
  unitPrice: number
}

export class UnitPricePerPositionSkeleton
  implements TreeSource<UnitPricePerPositionSkeleton>
{
  uuid: string
  lockVersion: number
  children: UnitPricePerPositionSkeleton[]
  rootUuid: string

  position: PositionBody
  unitPrices: UnitPriceBody[]
}

export interface UnitPricePerPositionRow extends TreeRow {
  body: UnitPricePerPositionRowBody
}

export class UnitPricePerPositionRowBody {
  position: PositionBody | undefined
  unitPrices: UnitPriceBody[]

  constructor(src?: UnitPricePerPositionSkeleton) {
    this.position = src?.position
    this.unitPrices = src?.unitPrices ?? []
  }
}

export const fetchUnitPricePerPositions = async (): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/unit_prices/positions')
}

export interface UnitPricePerPositionUpdateParams {
  records: UnitPricePerPositionInput[]
}

export interface UnitPricePerPositionInput {
  uuid?: string
  lockVersion?: number
  positionUuid: string
  hidden: boolean
  unitPrices: UnitPriceInput[]
}

export interface UnitPriceInput {
  validFrom: string
  unitPrice: number
}

export const updateUnitPricePerPosition = async (
  params: UnitPricePerPositionUpdateParams
): Promise<APIResponse> => {
  return api.functional.request(
    'POST',
    '/api/v1/unit_prices/positions/batch',
    params
  )
}
