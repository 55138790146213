import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { MasterScheduleRowData } from '../../master-schedule/model'
import { useProjectPrivateContext } from '../../../../../context/projectContext'
import { WbsItemIcon } from '../../../../../components/icons/WbsItemIcon'
import { CellRendererRoot } from '.'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../../style/colorPallete'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import { Checkbox } from '../../../../../components/inputs/Checkbox'
import { Row, TreeRow } from '../../../../../containers/BulkSheetView/model'

type CheckboxCellRendererProps = ICellRendererParams<Row, boolean> & {
  updateSelectedUuid: (uuid: string, selected: boolean) => void
}

export const CheckboxCellRenderer = ({
  value,
  data,
  updateSelectedUuid,
}: CheckboxCellRendererProps) => {
  const onChange = useCallback(
    (_, value: boolean) => {
      if (!data) return
      updateSelectedUuid(data.uuid, value)
    },
    [data, updateSelectedUuid]
  )
  return (
    <CellRendererRoot>
      <Checkbox checked={value} onChange={onChange} />
    </CellRendererRoot>
  )
}
