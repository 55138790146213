import store from '../../store'

interface StateProps {
  externalId: string
}

export const pageComponent = <OriginalProps extends StateProps>(
  WrappedComponent: React.ComponentType<OriginalProps>
) => {
  return (props: OriginalProps) => {
    const functions = store.getState().appFunction.functions
    const fn = functions?.find(v => v.externalId === props.externalId)
    if (!fn) return <></>
    return <WrappedComponent {...props} />
  }
}
