import API from '../lib/commons/api'

export const logDownloadExcel = async () => {
  return API.functional.request('GET', '/api/v1/files/log/excel', {
    url: window.location.href,
  })
}

export const logDownloadAttachment = async (url: string) => {
  return API.functional.request('GET', '/api/v1/files/log/attachment', {
    url,
  })
}

export const isImageFile = (file: File) => file.type.startsWith('image/')

export interface UploadedFile {
  url: string
  name: string
  mimeType: string
}
