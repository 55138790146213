/* eslint-disable no-underscore-dangle */
import { DateTerm } from '../../../../../../../../../utils/date'
import DateVO from '../../../../../../../../../vo/DateVO'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'

export default class GanttDate {
  private _startDate: CalendarDateVO
  private _endDate: CalendarDateVO
  private readonly _scale: CalendarDateVO[]

  constructor({
    scale,
    dateTerm,
  }: {
    scale: CalendarDateVO[]
    dateTerm?: DateTerm
  }) {
    this._scale = scale
    const start = dateTerm?.startDate || dateTerm?.endDate
    const end = dateTerm?.endDate || dateTerm?.startDate
    if (!start) {
      this._startDate = CalendarDateVO.of(DateVO.now().toDate())
    } else {
      const date = CalendarDateVO.of(start)
      this._startDate = scale.find(v => v.date === date.date) ?? date
    }
    if (!end) {
      this._endDate = CalendarDateVO.of(new Date(DateVO.now().toDate()))
    } else {
      const date = CalendarDateVO.of(end)
      this._endDate = scale.find(v => v.date === date.date) ?? date
    }
  }

  public setStartDateByIndex(index: number) {
    if (this.startIndex < 0) {
      this._startDate = this.startDate
    } else if (this._scale.length <= index) {
      const endDate = new Date(this._scale[this._scale.length - 1].date)
      endDate.setDate(endDate.getDate() + 1)
      this._startDate = CalendarDateVO.of(endDate)
    } else {
      this._startDate = this._scale[index]
    }
    const calendar = this._scale.find(v => v.isEqual(this._startDate))
    if (calendar && calendar.isHoliday()) {
      const nextWorkDay = this.getWorkDay(this._startDate, 1)
      if (nextWorkDay) {
        this._startDate = nextWorkDay
      }
    }
  }

  public setEndDateByIndex(index: number) {
    if (0 === index) {
      const startDate = new Date(this._scale[0].date)
      startDate.setDate(startDate.getDate() - 1)
      this._endDate = CalendarDateVO.of(startDate)
    } else if (this._scale.length < index) {
      this._endDate = this.endDate
    } else {
      this._endDate = this._scale[index - 1]
    }
    const calendar = this._scale.find(v => v.isEqual(this._endDate))
    if (calendar && calendar.isHoliday()) {
      const previousWorkDay = this.getWorkDay(this._endDate, -1)
      if (previousWorkDay) {
        this._endDate = previousWorkDay
      }
    }
  }

  get startDate(): CalendarDateVO {
    return this._startDate
  }

  get endDate(): CalendarDateVO {
    return this._endDate
  }

  get startIndex(): number {
    const firstDate = this._scale[0]
    const end = new Date(this._scale[this._scale.length - 1].date)
    end.setDate(end.getDate() + 1)
    const lastDate = CalendarDateVO.of(end)
    if (this.startDate.isBefore(firstDate)) return -1
    if (this.startDate.isEqual(lastDate)) return this._scale.length
    if (this.startDate.isAfter(lastDate)) return this._scale.length + 1
    return this._scale.findIndex(v => this.startDate.isEqual(v))
  }

  get endIndex(): number {
    const start = new Date(this._scale[0].date)
    start.setDate(start.getDate() - 1)
    const firstDate = CalendarDateVO.of(start)
    const lastDate = this._scale[this._scale.length - 1]
    if (this.endDate.isEqual(firstDate)) return 0
    if (this.endDate.isBefore(firstDate)) return -1
    if (this.endDate.isAfter(lastDate)) return this._scale.length + 1
    const index = this._scale.findIndex(v => this.endDate.isEqual(v))
    return index + 1
  }

  public indexIsActive(index: number): boolean {
    return 0 <= index && index <= this._scale.length
  }

  // TODO Remove it
  public findIndex(date: CalendarDateVO): number {
    return this._scale.findIndex(v => v.isEqual(date))
  }

  public getStartDateByIndex(index: number): CalendarDateVO {
    if (index < 0) {
      // return this.startDate
      return this.dateBeforeStart()
    } else if (this._scale.length <= index) {
      return this.dateAfterEnd()
    } else {
      return this._scale[index]
    }
  }

  public getEndDateByIndex(index: number): CalendarDateVO {
    if (index <= 0) {
      return this.dateBeforeStart()
    } else if (this._scale.length < index) {
      // return this.endDate
      return this.dateAfterEnd()
    } else {
      return this._scale[index - 1]
    }
  }

  private dateBeforeStart() {
    const start = new Date(this._scale[0].date)
    start.setDate(start.getDate() - 1)
    return CalendarDateVO.of(start)
  }

  private dateAfterEnd() {
    const end = new Date(this._scale[this._scale.length - 1].date)
    end.setDate(end.getDate() + 1)
    return CalendarDateVO.of(end)
  }

  // Work day
  public getWorkDaysByIndex(indexes: number[]): number {
    const start = this.getStartDateByIndex(indexes[0])
    const end = this.getEndDateByIndex(indexes[1])
    return this._scale.filter(v => v.isBetween(start, end) && !v.isHoliday())
      .length
  }

  public getStartDateDiff(from: number, to: number): number {
    if (from === to) return 0
    const fromDate = this.getStartDateByIndex(from)
    const toDate = this.getStartDateByIndex(to)
    if (toDate.isAfter(fromDate)) {
      return this._scale.filter(
        v =>
          ((v.isAfter(fromDate) && v.isBefore(toDate)) || v.isEqual(toDate)) &&
          !v.isHoliday()
      ).length
    }
    return this._scale.filter(
      v =>
        ((v.isAfter(toDate) && v.isBefore(fromDate)) || v.isEqual(toDate)) &&
        !v.isHoliday()
    ).length
  }

  public getEndDateDiff(from: number, to: number): number {
    if (from === to) return 0
    const fromDate = this.getEndDateByIndex(from)
    const toDate = this.getEndDateByIndex(to)
    if (toDate.isAfter(fromDate)) {
      return this._scale.filter(
        v =>
          ((v.isAfter(fromDate) && v.isBefore(toDate)) || v.isEqual(toDate)) &&
          !v.isHoliday()
      ).length
    }
    return this._scale.filter(
      v =>
        ((v.isAfter(toDate) && v.isBefore(fromDate)) || v.isEqual(toDate)) &&
        !v.isHoliday()
    ).length
  }

  public getWorkDay(date, step): CalendarDateVO | undefined {
    if (step < 0) {
      return CalendarDateVO.previousWorkDay(this._scale, date, -step)
    }
    return CalendarDateVO.nextWorkDay(this._scale, date, step)
  }

  public getMaxIndex(): number {
    return this._scale.length
  }
}
