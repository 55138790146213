import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import SavedUIStateList, { SavedUIState, UIState } from './SavedUIStateList'
import { OptionUIStateListProps as EditorDialogOptionUIStateListProps } from './SavedUIStateEditorDialog'
import { colorPalette } from '../../../style/colorPallete'
import CancelIcon from '../../../../assets/cancel_icon.svg'
import { FontSize } from '../../../../styles/commonStyles'
import { intl } from '../../../../i18n'
import { Button } from '../../buttons'

export interface SavedUIStateDialogProps {
  applicationFunctionUuid: string
  open: boolean
  title: string
  sharable: boolean
  uiStateKey: UiStateKey
  currentUIState: UIState
  currentSavedUIStateUuid?: string
  onSelect: (uiState: SavedUIState) => void
  onClose: () => void
  editorOptionUIStateListProps?: EditorDialogOptionUIStateListProps
}

type Props = WrappedComponentProps & SavedUIStateDialogProps

const SavedUIStateDialog = (props: Props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiPaper-root': { maxWidth: '800px' },
      }}
    >
      <DialogTitle id="dialog-title">
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: colorPalette.monotone[10],
            borderBottom: `1px solid ${colorPalette.monotone[2]}`,
            paddingBottom: '16px',
          }}
        >
          {props.title}
          <CloseButton onClick={props.onClose} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ minHeight: '375px' }}>
        <SavedUIStateList
          uiStateKey={props.uiStateKey}
          applicationFunctionUuid={props.applicationFunctionUuid}
          currentUIState={props.currentUIState}
          currentSavedUIStateUuid={props.currentSavedUIStateUuid}
          title={props.title}
          sharable={props.sharable}
          onSelect={props.onSelect}
          editorOptionUIStateListProps={props.editorOptionUIStateListProps}
        />
      </DialogContent>
    </Dialog>
  )
}

export default injectIntl(SavedUIStateDialog)

const CloseButton = ({ onClick }) => {
  return (
    <Button
      colorPattern="monotone"
      variant="outlined"
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: FontSize.SMALL,
        cursor: 'pointer',
      }}
    >
      <img src={CancelIcon} style={{ paddingRight: '4px' }} />
      <Typography sx={{ fontSize: FontSize.MEDIUM }}>
        {intl.formatMessage({ id: 'dialog.close' })}
      </Typography>
    </Button>
  )
}
