import _ from 'lodash'
import { EntityExtensionValue } from '../../meta/entityExtension'
import { UserBasic } from '../../../../lib/functions/user'
import { Tree } from '../../../../lib/commons/tree'
import { generateUuid } from '../../../../utils/uuids'
import { BulkSheetState, BulkSheetContext } from '../index'
import ViewMeta from '../../meta/ViewMeta'
import { ColDef, RowGroupOpenedEvent } from 'ag-grid-community'

export abstract class RowDataSpec<T extends Tree<T>, R extends RowData> {
  columnTypes(): { [key: string]: ColDef } {
    return {}
  }
  abstract createNewRow(ctx: BulkSheetContext<any, T, R, any>, params?: any): R
  abstract overwriteRowItemsWithParents(
    params: { child: R; parent: R },
    selectedColIds?: string[]
  ): R
  abstract createRowByResponse(response: T, viewMeta: ViewMeta): R

  duplicateRows(original: R[], selectedColIds?: string[]): R[] {
    const clone = original.concat()
    const uuids = clone.map(row => row.uuid)
    const ancestors = clone.filter(row => !uuids.includes(row.parentUuid || ''))
    ancestors.forEach(ancestor => this.recursiveResetUuid(ancestor, clone))
    return clone.map(data => {
      const duplicated = selectedColIds
        ? this.duplicateRowWithSelectedCols(data, selectedColIds)
        : this.duplicateRow(data)
      return {
        ...duplicated,
        lockVersion: undefined,
        createdAt: undefined,
        createdBy: undefined,
        updatedAt: undefined,
        updatedBy: undefined,
        isEdited: true,
      }
    })
  }

  recursiveResetUuid(row: R, allData: R[]) {
    const children = allData.filter(data => data.parentUuid === row.uuid)
    const newUuid = generateUuid()
    row.uuid = newUuid
    children.forEach(child => {
      child.parentUuid = newUuid
      this.recursiveResetUuid(child, allData)
    })
  }

  duplicateRow(original: R): R {
    return original
  }

  duplicateRowWithSelectedCols(original: R, selectedColIds: string[]): R {
    throw new Error(
      'duplicateRowWithSelectedCols should be implemented in each pages.'
    )
  }

  replaceRow(original: R, row: R): boolean {
    return true
  }

  importNewRow(value: T, ctx?: BulkSheetContext<any, T, R, any>): boolean {
    return true
  }
  canAddRow(row: R): boolean {
    return true
  }
  canAddChild(row: R): boolean {
    return true
  }
  addChild(parent: R, child: T): boolean {
    return true
  }
  removeRow(row: R): boolean {
    return true
  }
  hasDiff(row: R, newRow: R): boolean {
    return true
  }
  topCustomRowData?: () => R
}

export abstract class RowData {
  uuid: string
  lockVersion?: number
  createdAt?: string
  createdBy?: UserBasic
  updatedAt?: string
  updatedBy?: UserBasic

  rowNumber?: string
  treeValue?: string[]
  parentUuid?: string
  prevSiblingUuid?: string
  projectUuid?: string
  isAdded?: boolean
  isEdited?: boolean
  editedData?: { [key: string]: any }
  openContextMenu?: boolean // Use highlight row on open context menu

  extensions?: EntityExtensionValue[]

  numberOfChildren?: number
  children?: any[]

  isTotal?: boolean = false
  isViewOnly?: boolean

  errorMessages?: { [key: string]: string }

  constructor(uuid: string, lockVersion?: number, treeValue?: string[]) {
    this.uuid = uuid
    this.lockVersion = lockVersion
    this.treeValue = treeValue || []
  }
}

export default abstract class RowDataManager<
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> {
  public expandedGroupIds = new Set<string>()
  public collapsedGroupIds = new Set<string>()
  protected initialData: { [uuid: string]: R } = {}
  constructor(readonly ctx: BulkSheetContext<any, T, R, S>) {}

  // Initialize
  abstract init: (trees: T[], replaceExisting?: boolean) => void
  initRow = (row: R): void => {
    this.initialData[row.uuid] = _.cloneDeep(row)
    this.updateRow(row)
  }

  // Get
  abstract getAllRows: () => R[]
  abstract getPreviousSibling: (uuid: string) => R | undefined
  abstract getSiblings: (uuid: string) => R[]
  abstract getChildren: (uuid: string) => R[]
  abstract countChildren: (uuid: string) => number
  abstract getAncestors: (uuid: string) => R[]
  abstract getParent: (uuid: string) => R | undefined
  abstract getGrandParent: (uuid: string) => R | undefined
  abstract getFlatDescendants: (uuid: string) => R[]
  abstract getDataForBatchUpdate: () => {
    added: R[]
    edited: {
      before: R
      after: R
    }[]
    deleted: R[]
  }
  abstract getSingleRowDataForBatchUpdate: (uuid: string) => {
    added: R[]
    edited: {
      before: R
      after: R
    }[]
    deleted: R[]
  }
  abstract getRemovedRows: () => any[]

  // Update
  abstract addRowsTo: (
    row: R[],
    params: {
      parentUuid?: string
      prevSiblingUuid?: string
    }
  ) => void
  abstract addRows: (
    row: R | R[],
    addIndex: number,
    parentUuid?: string,
    skipRefreshing?: boolean
  ) => void
  abstract updateRow: (row: R) => void
  abstract removeRows: (rows: R[]) => void
  abstract moveToChild: (
    data: R[],
    parentUuid: string,
    fromUuid?: string,
    addIndex?: number
  ) => void

  abstract refreshRowNumber: (indexFrom: number, indexTo: number) => void

  // Only for client side row model
  getIndexById?: (id: string) => number

  // Only for server side row model
  onRowGroupOpened?: (event: RowGroupOpenedEvent) => void
  restoreRowExpandedState?: (data: R[]) => void
  refresh?: (trees: T[], parentId?: string) => string[] | undefined
  serverSideDataSource?: () => any

  moveRow?: (row: R, toUuid?: string) => void
  moveRows?: (rows: R[], toUuid?: string) => void

  // Main = group column, or editable, first visible, and also required if it's possible.
  private findMainPropertyKey = (newRow: boolean) => {
    const autoGolumn = this.ctx.gridApi!.getColumnDef('ag-Grid-AutoColumn')
    if (autoGolumn) {
      return 'ag-Grid-AutoColumn'
    }
    let candidate
    for (let row of this.ctx.viewMeta.functionMeta.properties.byIndex) {
      for (let c of row) {
        const key = this.ctx.viewMeta.makeDataPropertyName(c, false)
        const colDef = this.ctx.gridApi!.getColumnDef(key)
        if (
          colDef &&
          !colDef.hide &&
          ((newRow && c.editableIfC.isTrue) ||
            (!newRow && c.editableIfU.isTrue))
        ) {
          if (c.requiredIf.isTrue) {
            return key
          }
          candidate = key
        }
      }
    }
    return candidate
  }

  focusRow = (uuid: string, column?: string) => {
    const fn = (retry: number) => {
      if (retry < 0) {
        return
      }
      const node = this.ctx.gridApi!.getRowNode(uuid)
      if (!node) {
        // Retry until node is prepared when it's server side row model.
        setTimeout(() => fn(retry - 1), 200)
        return
      }
      if (node.parent && !node.parent.expanded) {
        // Expand parent node to focus
        node.parent.setExpanded(true)
      }
      this.ctx.gridApi!.ensureNodeVisible(node)
      const mainPropertyKey = this.findMainPropertyKey(node.data.isAdded)
      node.rowIndex !== null &&
        this.ctx.gridApi!.setFocusedCell(
          node.rowIndex,
          column || mainPropertyKey || 'rowNumber'
        )
    }
    // Use setTimeout to focus row after expand parent row.
    setTimeout(() => fn(5), 0)
  }

  public createRowByResponse(response: T, viewMeta: ViewMeta): R {
    const row = this.ctx.rowDataSpec.createRowByResponse(response, viewMeta)
    return {
      ...row,
      // TODO: Fix entire logic of entity extensions.
      extensions:
        row.extensions ||
        viewMeta.deserializeEntityExtensions(response.extensions || []),
      isAdded: false,
      isEdited: false,
    }
  }

  public getDuplicateRowIndex = (
    targetFieldName: string,
    rowDataArray: R[]
  ): number[] => {
    return rowDataArray
      .map(row => (targetFieldName in row ? row[targetFieldName] : undefined))
      .map((field, index, fields) => {
        if (!field) return -1
        return fields.indexOf(field) !== fields.lastIndexOf(field) ? index : -1
      })
      .filter(i => 0 <= i)
  }

  public updateErrorMessages = (
    errColExternalId: string,
    updateTargetRows: R[],
    errorRowIndexs: number[],
    errorMessage: string
  ) => {
    updateTargetRows.forEach((row, index) => {
      if (errorRowIndexs.includes(index)) {
        if (!row.errorMessages) {
          row.errorMessages = {}
        }
        row.errorMessages[errColExternalId] = errorMessage
      } else if (row.errorMessages) {
        delete row.errorMessages[errColExternalId]
      }
    })
  }
}
