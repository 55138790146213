import {
  Box,
  ClickAwayListener,
  Collapse,
  InputBase,
  List,
  ListItem,
  Paper,
  Popper,
} from '@mui/material'
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnState,
  GridApi,
} from 'ag-grid-community'
import { FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import CancelIcon from '../../../../../assets/cancel_icon.svg'
import ArrowTopIcon from '../../../../../assets/arrow_top_icon.svg'
import ArrowBottomIcon from '../../../../../assets/arrow_bottom_icon.svg'
import SearchIcon from '../../../../../assets/search_icon.svg'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Checkbox } from '../../../../components/inputs/Checkbox'
import SavedUIStateEditorDialog from '../../../../components/dialogs/SavedUIStateDialog/SavedUIStateEditorDialog'
import {
  UiStateKey,
  UiStateScope,
  generateCode,
} from '../../../../../lib/commons/uiStates'
import { generateUuid } from '../../../../../utils/uuids'
import {
  SavedUIState,
  UIState,
  getAllScopeUiState,
  updateUIStates,
} from '../../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import Auth from '../../../../../lib/commons/auth'
import { formatDateTime } from '../../../../../utils/date'
import moment from 'moment'
import { Function } from '../../../../../lib/commons/appFunction'
import { Button } from '../../../../components/buttons'

export interface Props {
  open: boolean
  anchorEl: any
  columnApi?: ColumnApi
  gridApi?: GridApi
  height?: number
  openSavedUiStateDialog?: () => void
  initializeColumnState: () => void
  applicationFunctionUuid: string
  uiStateKey: UiStateKey
  columnState: ColumnState[] // For re-rendering
  offset: number
  close: () => void
}

const ColumnSettingPopper = ({
  open,
  anchorEl,
  columnApi,
  gridApi,
  height,
  openSavedUiStateDialog,
  initializeColumnState,
  applicationFunctionUuid,
  uiStateKey,
  columnState,
  offset,
  close,
}: Props) => {
  if (!open || !anchorEl || !columnApi || !gridApi || !height) {
    return <></>
  }
  return (
    <ColumnSettingPopperInner
      anchorEl={anchorEl}
      columnApi={columnApi}
      gridApi={gridApi}
      height={height}
      openSavedUiStateDialog={openSavedUiStateDialog}
      initializeColumnState={initializeColumnState}
      applicationFunctionUuid={applicationFunctionUuid}
      uiStateKey={uiStateKey}
      columnState={columnState}
      offset={offset}
      close={close}
    />
  )
}

const ColumnSettingPopperInner = ({
  anchorEl,
  columnApi,
  gridApi,
  height,
  openSavedUiStateDialog,
  initializeColumnState,
  applicationFunctionUuid,
  uiStateKey,
  columnState,
  offset,
  close,
}: {
  anchorEl: any
  columnApi: ColumnApi
  gridApi: GridApi
  height: number
  openSavedUiStateDialog?: () => void
  initializeColumnState: () => void
  applicationFunctionUuid: string
  uiStateKey: UiStateKey
  columnState: ColumnState[]
  offset: number
  close: () => void
}) => {
  const agGridAutoColumnFieldId = useMemo(
    () =>
      columnApi
        .getAllGridColumns()
        ?.find(v => v.getColId() === 'ag-Grid-AutoColumn')
        ?.getColDef().field,
    []
  )
  const [searchText, setSearchText] = useState<string>('')
  const [openEditor, setOpenEditor] = useState<boolean>(false)
  const debouncedText = useDebounce(searchText, 300)

  const filteredColumnDefs = useMemo(
    () =>
      gridApi
        .getColumnDefs()
        ?.filter(v => v.headerName)
        ?.filter(
          v =>
            v.headerName?.includes(debouncedText) ||
            !!(v as ColGroupDef).children
              ?.filter(c => (c as ColDef).field !== agGridAutoColumnFieldId)
              ?.find(c => c.headerName?.includes(debouncedText))
        )
        ?.map(v => {
          const colGroupDef: ColGroupDef = v as ColGroupDef
          if (!colGroupDef.children) {
            return v
          }
          colGroupDef.children = colGroupDef.children
            .filter(c => (c as ColDef).field !== agGridAutoColumnFieldId)
            .filter(c => !(c as ColDef).suppressColumnsToolPanel)
            .filter(
              c =>
                v.headerName?.includes(debouncedText) ||
                c.headerName?.includes(debouncedText)
            )
          return colGroupDef
        }) ?? [],
    [columnState, debouncedText]
  )

  const { projectUuid, functions } = useSelector<
    AllState,
    { projectUuid?: string; functions?: Function[] }
  >(state => ({
    projectUuid: state.project.selected,
    functions: state.appFunction.functions,
  }))

  const saveNewUIStates = async (uiState: SavedUIState) => {
    const columnState = columnApi.getColumnState()
    const filterState = gridApi.getFilterModel()
    const currentUIState = {
      columnState: columnState,
      filterState: filterState,
      // for legacy BulkSheet
      column: columnState,
      filter: filterState,
    } as UIState
    const allScopeSavedUIStates: SavedUIState[] = await getAllScopeUiState(
      applicationFunctionUuid,
      uiStateKey,
      projectUuid,
      functions
    )
    const targetScopeSavedUIStates = allScopeSavedUIStates.filter(
      v => v.scope === uiState.scope
    )

    const newSavedUIState = {
      ...uiState,
      code: generateCode(allScopeSavedUIStates.map(v => v.code)),
      UIState: currentUIState,
      updatedBy: Auth.getCurrentTenant()!.user!.name,
      updatedAt: formatDateTime(moment()),
    }
    const newSavedUIStates = [...targetScopeSavedUIStates, newSavedUIState]
    updateUIStates(
      uiState.scope,
      newSavedUIStates,
      applicationFunctionUuid,
      uiStateKey,
      projectUuid
    )
    setOpenEditor(false)
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => close()}>
        <Box>
          <Popper
            open={true}
            anchorEl={anchorEl}
            placement={'bottom'}
            disablePortal={true}
            style={{
              borderRadius: '5px',
              zIndex: 1300,
            }}
            modifiers={[{ name: 'offset', options: { offset: [0, offset] } }]}
          >
            <Paper
              style={{
                width: '320px',
                height: height ? height - offset : 0,
                color: colorPalette.monotone[0],
                boxShadow: '0px 4px 16px 0px #7B8CAA80',
                boxSizing: 'border-box',
                padding: '16px',
              }}
            >
              {openSavedUiStateDialog && (
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                  }}
                >
                  <SavedColumnSettingButton onClick={openSavedUiStateDialog} />
                  <SaveNewColumnSettingButton
                    onClick={() => setOpenEditor(true)}
                  />
                </Box>
              )}
              <SearchBox
                searchText={searchText}
                onInputChange={setSearchText}
              />
              <ColumnList
                gridApi={gridApi}
                columnApi={columnApi}
                filteredColumnDefs={filteredColumnDefs}
              />
              <InitializeColumnSettingButton onClick={initializeColumnState} />
            </Paper>
          </Popper>
          {openEditor && (
            <SavedUIStateEditorDialog
              savedUIState={{
                uuid: generateUuid(),
                code: 'generate_later',
                name: '',
                UIState: {},
                scope: UiStateScope.User,
              }}
              open={openEditor}
              dialogTitle={intl.formatMessage({ id: 'savedUIState.save' })}
              dialogSubTitle={intl.formatMessage({
                id: 'savedUIState.add.dialog.subtitle',
              })}
              onSubmit={saveNewUIStates}
              onClose={() => setOpenEditor(false)}
              submitButtonLabel={intl.formatMessage({
                id: 'savedUIState.add.button.save',
              })}
              cancelButtonLabel={intl.formatMessage({
                id: 'savedUIState.edit.button.cancel',
              })}
            />
          )}
        </Box>
      </ClickAwayListener>
    </>
  )
}

export default ColumnSettingPopper

const SavedColumnSettingButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      colorPattern="monotone"
      variant="outlined"
      style={{
        textAlign: 'center',
        width: '100%',
        height: '32px',
        padding: '6px 0',
        marginRight: '4px',
        cursor: 'pointer',
        fontSize: FontSize.MEDIUM,
      }}
      onClick={onClick}
    >
      <span>
        {intl.formatMessage({
          id: 'bulksheetview.columnsetting.storedColumnSetting',
        })}
      </span>
    </Button>
  )
}

const SaveNewColumnSettingButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      colorPattern="skyBlue"
      variant="filled"
      style={{
        textAlign: 'center',
        width: '100%',
        height: '32px',
        padding: '6px 0',
        marginLeft: '4px',
        cursor: 'pointer',
        fontSize: FontSize.MEDIUM,
      }}
      onClick={onClick}
    >
      <span>
        {intl.formatMessage({
          id: 'bulksheetview.columnsetting.saveNewColumnSetting',
        })}
      </span>
    </Button>
  )
}

const SearchBox = ({
  searchText,
  onInputChange,
}: {
  searchText: string
  onInputChange: (text: string) => void
}) => {
  return (
    <Box
      style={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '32px',
        marginBottom: '16px',
      }}
    >
      <img
        src={SearchIcon}
        style={{ color: colorPalette.monotone[4], margin: '10px' }}
      />
      <InputBase
        sx={{ width: '100%' }}
        placeholder={intl.formatMessage({ id: 'search' })}
        value={searchText}
        onChange={event => onInputChange(event.target.value)}
      />
      <img
        src={CancelIcon}
        style={{
          color: colorPalette.monotone[4],
          cursor: 'pointer',
          padding: '8px',
        }}
        onClick={e => {
          onInputChange('')
        }}
      />
    </Box>
  )
}

const ColumnList = ({
  gridApi,
  columnApi,
  filteredColumnDefs,
}: {
  gridApi: GridApi
  columnApi: ColumnApi
  filteredColumnDefs: (ColDef | ColGroupDef)[]
}) => {
  const [parentExpanded, setParentExpanded] = useState<{
    [key: string]: boolean
  }>({})

  const existHideDescendants = useCallback(
    (
      column: ColDef | ColGroupDef,
      existHideColumn: boolean = false
    ): boolean => {
      if (existHideColumn) return true
      const groupColumn = column as ColGroupDef
      if (!groupColumn.children) {
        return (column as ColDef).hide ? true : false
      }
      for (let i = 0; i < groupColumn.children.length; i++) {
        const existHideDescendant = existHideDescendants(
          groupColumn.children[i],
          existHideColumn
        )
        if (existHideDescendant) {
          return true
        }
      }
      return false
    },
    []
  )

  const getDescendantColumnIds = useCallback(
    (column: ColDef | ColGroupDef): string[] => {
      let ids: string[] = []
      const groupColumn = column as ColGroupDef
      if (!!groupColumn.children) {
        groupColumn.children.forEach(child => {
          ids = ids.concat(getDescendantColumnIds(child))
        })
      } else {
        ids.push((column as ColDef).colId!)
      }
      return ids
    },
    []
  )

  useEffect(() => {
    if (gridApi && !Object.keys(parentExpanded).length) {
      let initial = {}
      gridApi
        .getColumnDefs()
        ?.filter(v => v.headerName)
        ?.filter(v => !!(v as ColGroupDef).children)
        .forEach(v => (initial[v.headerName!] = true))
      setParentExpanded(initial)
    }
  }, [gridApi])

  return (
    <Box
      style={{
        width: '100%',
        height: 'calc(100% - 147px)',
        overflowY: 'auto',
      }}
    >
      <List component="nav">
        {filteredColumnDefs.map((parentCol: ColDef<any> | ColGroupDef<any>) => {
          const colGroupDef = parentCol as ColGroupDef
          const hasChildren = !!colGroupDef.children
          if (hasChildren) {
            const checked = !existHideDescendants(colGroupDef)
            return (
              <>
                <ColumnGroupItem
                  key={colGroupDef.groupId}
                  colGroupId={colGroupDef.groupId!}
                  checked={checked}
                  onChecked={() => {
                    const addState = getDescendantColumnIds(colGroupDef).map(
                      id => ({
                        colId: id,
                        hide: checked,
                      })
                    )
                    columnApi.applyColumnState({
                      state: addState,
                    })
                  }}
                  headerName={colGroupDef.headerName!}
                  expanded={parentExpanded[colGroupDef.headerName!]}
                  toggleExpanded={() =>
                    setParentExpanded(prev => ({
                      ...prev,
                      [colGroupDef.headerName!]: !prev[colGroupDef.headerName!],
                    }))
                  }
                />
                <Collapse
                  in={parentExpanded[colGroupDef.headerName!]}
                  timeout={100}
                >
                  <List component={'div'}>
                    {colGroupDef.children
                      .filter(c => {
                        const groupDef = c as ColGroupDef
                        return (
                          !groupDef?.children || 0 < groupDef?.children.length
                        )
                      })
                      .map((childDef: ColDef | ColGroupDef) => {
                        const ids = !(childDef as ColGroupDef).children
                          ? [(childDef as ColDef).colId!]
                          : getDescendantColumnIds(childDef)
                        const hide = !(childDef as ColGroupDef).children
                          ? (childDef as ColDef).hide
                            ? true
                            : false
                          : existHideDescendants(childDef)

                        return (
                          <ColumnItem
                            key={ids[0]}
                            columnApi={columnApi}
                            colIds={ids}
                            hide={hide}
                            headerName={childDef.headerName!}
                            hasNoParent={false}
                          />
                        )
                      })}
                  </List>
                </Collapse>
              </>
            )
          } else {
            const noParentColDef = parentCol as ColDef
            return (
              <ColumnItem
                key={noParentColDef.colId}
                columnApi={columnApi}
                colIds={[noParentColDef.colId!]}
                hide={!!noParentColDef.hide}
                headerName={noParentColDef.headerName!}
                hasNoParent={true}
              />
            )
          }
        })}
      </List>
    </Box>
  )
}

const ColumnGroupItem = ({
  colGroupId,
  checked,
  onChecked,
  headerName,
  expanded,
  toggleExpanded,
}: {
  colGroupId: string
  checked: boolean
  onChecked: () => void
  headerName: string
  expanded: boolean
  toggleExpanded: () => void
}) => {
  return (
    <ListItem
      key={colGroupId}
      style={{
        fontSize: FontSize.MEDIUM,
        height: 25,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: colorPalette.monotone[10],
        paddingLeft: '0',
      }}
      onClick={e => e.stopPropagation()}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          size={'m'}
          style={{ padding: '0 8px', cursor: 'pointer' }}
          checked={checked}
          onClick={onChecked}
        />
        {headerName}
      </Box>
      <img
        src={expanded ? ArrowBottomIcon : ArrowTopIcon}
        onClick={toggleExpanded}
        style={{ cursor: 'pointer' }}
      />
    </ListItem>
  )
}

const ColumnItem = ({
  columnApi,
  colIds,
  hide,
  headerName,
  hasNoParent,
}: {
  columnApi: ColumnApi
  colIds: string[]
  hide: boolean
  headerName: string
  hasNoParent: boolean
}) => {
  return (
    <ListItem
      key={colIds[0]}
      style={{
        fontSize: FontSize.MEDIUM,
        height: 25,
        width: '100%',
        alignItems: 'center',
        color: colorPalette.monotone[10],
        paddingLeft: hasNoParent ? '0' : undefined,
      }}
      onClick={e => e.stopPropagation()}
    >
      <Checkbox
        size={'m'}
        style={{ padding: '0 8px', cursor: 'pointer' }}
        checked={!hide}
        onClick={() => {
          const currentColumnState = columnApi.getColumnState()
          currentColumnState.forEach(state => {
            if (colIds.includes(state.colId)) {
              state.hide = !hide
            }
          })
          columnApi.applyColumnState({
            state: currentColumnState,
          })
        }}
      />
      {headerName}
    </ListItem>
  )
}

const InitializeColumnSettingButton = ({
  onClick,
}: {
  onClick?: () => void
}) => {
  return (
    <Button
      colorPattern="monotone"
      variant="outlined"
      style={{
        textAlign: 'center',
        width: '100%',
        height: '32px',
        padding: '6px 0',
        marginTop: '16px',
        cursor: 'pointer',
        fontSize: FontSize.MEDIUM,
      }}
      onClick={onClick}
    >
      <span>
        {intl.formatMessage({
          id: 'bulksheetview.columnsetting.initializeColumnSetting',
        })}
      </span>
    </Button>
  )
}
