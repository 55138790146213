import API, { APIResponse } from '../commons/api'
import store from '../../store'
import EntitySearch from '../commons/entitySearch'
import { FunctionProperty } from '../commons/appFunction'
import WbsItemApi from '../functions/wbsItem'

export interface GetProcessesProps {
  projectUuid: string
  all?: string
  code?: string
  displayName?: string
  offset?: number
  limit?: number
}

export interface ProcessProps {
  uuid: string
  code: string
  projectUuid: string
  displayName: string
}

class Process extends EntitySearch {
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: store.getState().project.selected,
        all: query,
        limit: query.length === 0 ? 10 : undefined,
      }),
      this.toResponse
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  toResponse = (process: ProcessProps) => {
    return {
      uuid: process.uuid,
      code: process.code,
      name: `[${process.code}] ${process.displayName}`,
      displayName: process.displayName,
    }
  }

  entitySearchApi = (
    props: GetProcessesProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/wbs_items/processes/find',
      props,
      true,
      signal
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await WbsItemApi.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }
}

export default new Process()
