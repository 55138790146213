import React, { useCallback, useRef } from 'react'
import { styled } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'
import Button, { ButtonProps } from '@mui/material/Button'

const RootDiv = styled('div')({
  position: 'relative',
})
const ProgressCircle = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-12px',
  marginLeft: '-12px',
})

type OwnProps = { isLoading?: boolean }
type CircularButtonProps = ButtonProps & OwnProps

const CircularButton = (props: CircularButtonProps) => {
  const { isLoading, disabled, title, ...others } = props
  return (
    <RootDiv>
      <Button {...others} disabled={isLoading || disabled}>
        {title}
      </Button>
      {isLoading && <ProgressCircle size={24} />}
    </RootDiv>
  )
}

export default CircularButton
