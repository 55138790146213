import { Typography } from '@mui/material'
import { Color, FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { colorPalette } from '../../../../style/colorPallete'

interface Props {
  onClick: (event) => void
  open: boolean
}
const ColumnSettingButton = ({ onClick, open }: Props) => {
  return (
    <button
      style={{
        background: 'transparent',
        border: 0,
        fontSize: FontSize.MEDIUM,
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'row',
        padding: '5px 10px',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 'fit-content',
      }}
      onClick={onClick}
    >
      <Typography sx={{ color: colorPalette.monotone[5] }}>
        {intl.formatMessage({ id: 'wbs.header.column.setting.title' })}
      </Typography>
      <NavigateNextRoundedIcon
        sx={{
          marginLeft: '5px',
          color: colorPalette.monotone[4],
          transform: () => (open ? 'rotate(90deg)' : 'rotate(0deg)'),
        }}
      />
    </button>
  )
}

export default ColumnSettingButton
