import { GridOptions, RowDragEvent, RowNode } from 'ag-grid-community'
import { useCallback, useState } from 'react'
import { Row } from '../../model'

// Set hovered information to context. Change styles using cellClassRules of colDefs
export const useDragStyle = <R extends Row>(
  gridOptions: GridOptions | undefined
) => {
  const [movedNodes, setMovedNodes] = useState<RowNode[]>([])

  const refreshRows = useCallback(
    (params: { draggableNodeId?: string }) => {
      if (!gridOptions) return
      gridOptions.context = { ...gridOptions.context, ...params }
      gridOptions.api?.refreshCells()
    },
    [gridOptions]
  )

  const onRowDragEnter = useCallback((event: RowDragEvent<R>) => {
    setMovedNodes(event.nodes)
  }, [])

  const onRowDragMove = useCallback(
    (event: RowDragEvent) => {
      const overNode = event.overNode
      if (
        !overNode ||
        !overNode.id ||
        movedNodes.some(v => v.id === overNode.id)
      ) {
        // Clear
        refreshRows({ draggableNodeId: undefined })
      } else {
        // Can move to same level
        refreshRows({ draggableNodeId: overNode.id })
      }
    },
    [movedNodes, refreshRows]
  )

  const refreshDragStyle = useCallback(() => {
    refreshRows({ draggableNodeId: undefined })
  }, [refreshRows])

  return { onRowDragEnter, onRowDragMove, refreshDragStyle }
}
