import { WbsItemStatus } from '../../../domain/entity/WbsItemEntity'
import { dateTermVoService } from '../../../domain/value-object/DateTermVO'
import { WbsItemSearchConditionVO } from '../../../domain/value-object/WbsItemSearchConditionVo'
import { toUrlQuery } from '../../../lib/commons/api'
import { WbsItemRow, WbsItemSearchDetail } from '../../../lib/functions/wbsItem'
import { IDndDraggable } from '../../components/kanban/DndSortableMarker'
import { InputError } from '../../containers/BulkSheetView/lib/validation'

export enum ColumnType {
  STATUS = 'STATUS',
  RESPONSIBLE = 'RESPONSIBLE',
}

export class CardContent extends WbsItemRow {
  path?: string
}

export interface CardData extends IDndDraggable {
  id: string
  content: CardContent
  edited?: boolean
}

export interface ColumnDef extends IDndDraggable {
  title: string
  backgroundColor: string
}

export interface Column extends ColumnDef {
  cardIds: string[]
  cumulationData: StatusColumnCumulationData
}

export type StatusColumnCumulationData = {
  count: number
  estimatedHour: number
  actualHour: number
}

export interface StatusColumnAPIResponse {
  id: WbsItemStatus
  count: number
  estimatedHour: number
  actualHour: number
  cards: WbsItemSearchDetail[]
}

export interface ItemCountAPIResponse {
  hit: number
}

export type SelectOption = {
  value: string
  code?: string
  name: string
  iconUrl?: string
}

export class CardInputError extends InputError {
  public toMessage(idConverter?: (id: any) => string): string {
    throw new Error('This method does not work in Kanban page.')
  }

  public getMessage(id: string, field: string): string | undefined {
    if (!this.errors[id]) {
      return undefined
    }
    return this.errors[id][field]
  }

  public hasError(id: string): boolean {
    return !!this.errors[id]
  }
}

const wbsItemSearchSearchConditionFields = [
  'rootUuid',
  'teamUuid',
  'scheduledEndDate',
  'sprintUuid',
  'responsibleUuid',
] as const

export type StatusKanbanSearchConditionWithResponsible = Pick<
  WbsItemSearchConditionVO,
  (typeof wbsItemSearchSearchConditionFields)[number]
>

export const openKanban = (
  projectCode: string,
  condition: Partial<StatusKanbanSearchConditionWithResponsible>
) => {
  const body = {}
  condition.rootUuid && (body['rootUuid'] = condition.rootUuid)
  condition.teamUuid && (body['teamUuid'] = condition.teamUuid)
  condition.scheduledEndDate &&
    (body['scheduledEndDate'] = dateTermVoService.format(
      condition.scheduledEndDate
    ))
  condition.responsibleUuid &&
    (body['responsibleUuid'] = condition.responsibleUuid)
  const query = toUrlQuery(body, '', true)
  const url = `${window.location.origin}/project/kanban/${projectCode}?${query}`
  window.open(url)
}

export type EditableCardItem =
  | 'status'
  | 'displayName'
  | 'accountable'
  | 'responsible'
  | 'assignee'
  | 'priority'
  | 'scheduledDate'
  | 'estimatedWorkload'
