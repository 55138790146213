import { MouseEvent, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { getProjectPlanUuidByWbsItemUuid } from '../../../lib/functions/projectPlan'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { BreadCrumb } from '../../../store/breadcrumb'
import { colorPalette } from '../../style/colorPallete'
import ProjectPlanNewBreadcrumb from '../ProjectPlanNew/components/Header/ProjectPlanNewBreadcrumb'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'

type OwnProps = {
  rootWbsItemUuid: string
  onSelectBreadcrumb: (
    event: MouseEvent | Event,
    breadcrumb: BreadCrumb
  ) => void | Promise<void>
}

const SprintReportBreadcrumb = ({
  rootWbsItemUuid,
  onSelectBreadcrumb,
}: OwnProps) => {
  const [projectPlanUuid, setProjectPlanUuid] = useState<string | undefined>()

  useEffect(() => {
    const getProjectPlanUuid = async () => {
      const uuid = await getProjectPlanUuidByWbsItemUuid(rootWbsItemUuid)
      setProjectPlanUuid(uuid)
    }
    getProjectPlanUuid()
  }, [rootWbsItemUuid])

  if (!projectPlanUuid) return <></>

  return (
    // TODO Edit root box style it after inverting dependency of sprint report and BulkSheet.
    <Box
      sx={{
        width: '100vw',
        background: colorPalette.monotone[1],
        marginTop: '-4px',
        marginLeft: '-24px',
        overflowX: 'auto',
        '@media (min-width: 600px)': {
          marginLeft: '-19px',
        },
        ' ol': {
          flexWrap: 'nowrap',
        },
      }}
    >
      <ProjectPlanNewBreadcrumb
        rootProjectPlanUuid={projectPlanUuid}
        onSelectBreadcrumb={onSelectBreadcrumb}
        optionsTypes={[
          WbsItemType.WORKGROUP,
          WbsItemType.PROCESS,
          WbsItemType.DELIVERABLE_LIST,
          WbsItemType.DELIVERABLE,
        ]}
      />
    </Box>
  )
}

export default projectPrivate(SprintReportBreadcrumb)
