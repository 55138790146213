import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Breadcrumbs from './index'
import {
  getProjectPlanBreadcrumbs,
  getProjectPlanBreadcrumbsByWbsItemUuid,
} from '../../../../../lib/functions/projectPlan'
import { AllState } from '../../../../../store'
import { BreadCrumb } from '../../../../../store/breadcrumb'

interface OwnProps {
  rootUuid?: string
  rootUuidType?: 'wbsItem' | 'projectPlan'
  itemName: string
  onClick?: (bc: BreadCrumb) => void
  disableCopyPath?: boolean
  showRoot?: boolean
}

interface StateProps {
  projectUuid?: string
}

const ProjectPlanBreadcrumbs = (props: OwnProps & StateProps) => {
  const { rootUuid, rootUuidType, projectUuid, itemName } = props
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb | undefined>(
    undefined
  )
  const getAndSetBreadcrumbs = useCallback(() => {
    if (!projectUuid || !rootUuid) {
      setBreadcrumbs(undefined)
      return undefined
    }
    if (!rootUuidType || rootUuidType === 'wbsItem') {
      getProjectPlanBreadcrumbsByWbsItemUuid(projectUuid, rootUuid).then(
        breadcrumb => setBreadcrumbs(breadcrumb)
      )
    } else {
      getProjectPlanBreadcrumbs(projectUuid, rootUuid).then(breadcrumbInfo =>
        setBreadcrumbs(breadcrumbInfo?.breadcrumbs)
      )
    }
  }, [rootUuid, projectUuid])
  useEffect(() => {
    getAndSetBreadcrumbs()
  }, [projectUuid, rootUuid])
  if (!breadcrumbs) return <></>
  return (
    <Breadcrumbs
      breadcrumbs={breadcrumbs}
      itemName={itemName}
      onClick={props.onClick}
      disableCopyPath={props.disableCopyPath}
      showRoot={props.showRoot}
    />
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
})

export default connect(mapStateToProps)(ProjectPlanBreadcrumbs)
