import _ from 'lodash'
import { dateVoService } from '../../domain/value-object/DateVO'
import { DISPLAY_DATE_FORMAT } from '../../utils/date'

const AVAILABLE_DATE_FORMAT = {
  YYYYMMDD: /^[0-9]{8}$/, // YYYYMMDD
  YYYYMMDD_SLASH: /^[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}$/, // YYYY/mm/dd
  YYYYMMDD_HAIFUN: /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/, // YYYY-mm-dd
  MMDD: /^[0-9]{4}$/, // MMDD
  MMDD_SLASH: /^[0-9]{1,2}\/[0-9]{1,2}$/, // mm/dd
  MMDD_HAIFUN: /^[0-9]{1,2}-[0-9]{1,2}$/, // mm-dd
} as const

// Private functions.
const complementWithCurrentYear = (
  mm: string | number,
  dd: string | number
): string => {
  const YYYY = new Date().getFullYear()
  const MM = _.padStart(`${mm}`, 2, '0')
  const DD = _.padStart(`${dd}`, 2, '0')
  return `${YYYY}/${MM}/${DD}`
}

// Public functions.
export const completeDateString = (str: string | null | undefined): string => {
  if (!str) {
    return ''
  }
  const trimmedStr = str.includes(' ') ? str.split(' ')[0] : str

  let dateStr = ''
  if (AVAILABLE_DATE_FORMAT.YYYYMMDD.test(trimmedStr)) {
    dateStr = `${trimmedStr.slice(0, 4)}-${trimmedStr.slice(
      4,
      6
    )}-${trimmedStr.slice(6)}`
  } else if (
    AVAILABLE_DATE_FORMAT.YYYYMMDD_SLASH.test(trimmedStr) ||
    AVAILABLE_DATE_FORMAT.YYYYMMDD_HAIFUN.test(trimmedStr)
  ) {
    dateStr = trimmedStr
  } else if (AVAILABLE_DATE_FORMAT.MMDD.test(trimmedStr)) {
    dateStr = new Date().getFullYear() + trimmedStr
  } else if (AVAILABLE_DATE_FORMAT.MMDD_SLASH.test(trimmedStr)) {
    const dateParts = trimmedStr.split('/')
    dateStr = complementWithCurrentYear(dateParts[0], dateParts[1])
  } else if (AVAILABLE_DATE_FORMAT.MMDD_HAIFUN.test(trimmedStr)) {
    const dateParts = trimmedStr.split('-')
    dateStr = complementWithCurrentYear(dateParts[0], dateParts[1])
  }
  if (!dateStr) {
    return ''
  }
  const dateVO = dateVoService.construct(dateStr)

  return dateVoService.isValid(dateVO)
    ? dateVoService.format(dateVO, DISPLAY_DATE_FORMAT)
    : ''
}
