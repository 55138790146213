import React, { useEffect, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SwitchButton from './SwitchButton'
import { Button, ClickAwayListener, Fade, Paper, Popper } from '@mui/material'
import { FontSize } from '../../../../../../../../../styles/commonStyles'
import ExclusiveToggleGroup from '../../../../../../../../components/editors/toggle/ExclusiveToggleGroup'
import { DayOfWeekToggleGroup } from '../../../../../../../../components/editors/toggle/DayOfWeekToggleGroup'
import DateSelect, {
  DateSelectType,
} from '../../../../../../../../components/toolbars/common/DateSelect'
import DateVO from '../../../../../../../../../vo/DateVO'
import { DayOfWeek } from '../../../../../../../../../lib/functions/report'
import {
  DisplayRange,
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../../../../../../domain/value-object/GanttParameterVO'
import store, { AllState } from '../../../../../../../../../store'
import { connect } from 'react-redux'
import { changeGanttParameter } from '../../../../../../../../../store/project'

const DisplayParameter = ({ children }) => (
  <div className="sevend-ag-cell-gantt-display-parameter__parameter">
    {children}
  </div>
)
const Caption = ({ children }) => (
  <span style={{ fontSize: FontSize.SMALL }}>{children}</span>
)
const RangeSelect = ({ children }) => (
  <div className="sevend-ag-cell-gantt-display-parameter__range-select">
    {children}
  </div>
)

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
  parameter: state.project.ganttParameter,
})

type StateProps = {
  projectUuid?: string
  parameter?: GanttParameterVO
}

interface Props extends WrappedComponentProps, StateProps {
  visible?: boolean
}

export const GanttDisplayParameter = connect(mapStateToProps)(
  injectIntl((props: Props) => {
    const parameter = props.parameter ?? GanttParameterVO.defaultValue()
    const [unit, setUnit] = useState<GanttDisplayUnit>(parameter.unit)
    const [startDay, setStartDay] = useState<DayOfWeek>(parameter.startDay)
    const [range, setRange] = useState<DisplayRange>(parameter.range)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(
      undefined
    )
    const [open, setOpen] = useState(false)

    useEffect(() => {
      setUnit(parameter.unit)
      setStartDay(parameter.startDay)
      setRange(parameter.range)
    }, [props.parameter])

    if (!props.visible) {
      return <></>
    }
    return (
      <>
        <DisplayParameter>
          <SwitchButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget)
              setOpen(!open)
            }}
          />
        </DisplayParameter>
        <TransitionPopper
          open={open}
          anchorEl={anchorEl}
          onClickAway={() => {
            setOpen(false)
            setAnchorEl(undefined)
          }}
        >
          <Caption>
            {props.intl.formatMessage({ id: 'gantt.tool.unit' })}
          </Caption>
          <ExclusiveToggleGroup
            value={unit}
            options={Object.values(GanttDisplayUnit).map(v => {
              return {
                value: v,
                label: props.intl.formatMessage({
                  id: `gantt.displayUnit.${v.toLowerCase()}`,
                }),
              }
            })}
            onChange={value => setUnit(value as GanttDisplayUnit)}
          />
          <Caption>
            {props.intl.formatMessage({ id: 'gantt.tool.startDay' })}
          </Caption>
          <DayOfWeekToggleGroup
            value={startDay}
            onChange={value => setStartDay(value as DayOfWeek)}
            excludeHoliday={false}
            disabled={unit !== GanttDisplayUnit.WEEK}
          />
          <Caption>
            {props.intl.formatMessage({ id: 'gantt.tool.displayRange' })}
          </Caption>
          <RangeSelect>
            <DateSelect
              type={DateSelectType.DATE}
              value={range.start}
              onChange={(value?: string) => {
                if (!value) return
                const date = new DateVO(value)
                setRange({
                  start: date,
                  end: range.end.isBefore(date) ? date : range.end,
                })
              }}
            />
            <DateSelect
              type={DateSelectType.DATE}
              value={range.end}
              onChange={(value?: string) => {
                if (!value) return
                const date = new DateVO(value)
                setRange({
                  start: range.start.isAfter(date) ? date : range.start,
                  end: date,
                })
              }}
            />
          </RangeSelect>
          <Button
            color="primary"
            onClick={() => {
              store.dispatch(
                changeGanttParameter(
                  props.projectUuid!,
                  new GanttParameterVO(unit, startDay, range)
                )
              )
              setOpen(false)
              setAnchorEl(undefined)
            }}
          >
            {props.intl.formatMessage({ id: 'dialog.ok' })}
          </Button>
        </TransitionPopper>
      </>
    )
  })
)

function TransitionPopper(props: {
  open: boolean
  anchorEl: HTMLButtonElement | undefined
  onClickAway: () => void
  children: JSX.Element | JSX.Element[]
}) {
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      placement={'bottom-start'}
      transition={true}
      style={{ zIndex: 2 }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.onClickAway}>
          <Fade {...TransitionProps} timeout={200}>
            <Paper
              style={{
                margin: 5,
                padding: '5px 5px 0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {props.children}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
