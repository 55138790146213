import { useEffect, useState } from 'react'
import { useBurndownChartRepository } from '../../../../services/adaptors/burndownChartRepositoryAdaptor'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { useAggregationDomain } from './aggregationDomain'
import { BurndownChartBasicInformation } from '../../../../domain/value-object/BurndownChart'
import { WbsPivotAggregationDomain } from '../../../../domain/value-object/WbsPivot'

export const useBasicInformation = (
  projectUuid: string,
  domain: WbsPivotAggregationDomain | undefined
) => {
  const { getBasicInformation } = useBurndownChartRepository()
  const [basicInformation, setBasicInformation] = useState<
    BurndownChartBasicInformation | undefined
  >()
  useEffect(() => {
    const fn = async () => {
      const response = await getBasicInformation(projectUuid, domain)
      setBasicInformation(response)
    }
    fn()
  }, [projectUuid, domain])
  return {
    basicInformation,
  }
}
