import { APPLICATION_FUNCTION_EXTERNAL_ID } from '..'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { intl } from '../../../i18n'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import TicketApi from '../../../lib/functions/ticket'
import { TicketListBasic } from '../../../lib/functions/ticketList'
import store from '../../../store'
import { CommentSummary } from '../../../store/comments'
import { FunctionLayer, pushFunctionLayer } from '../../../store/functionLayer'
import { setData as setSingleSheetData } from '../../../store/singleSheet'

export interface UnsavedTicketWbsItem {
  uuid: string
  code: string
  projectUuid: string
  status: WbsItemStatus
  type: WbsItemType
  ticketType: string
}

export interface UnsavedTicketParentWbsItem {
  uuid?: string
  code?: string
  projectUuid?: string
  type?: WbsItemType
  displayName?: string
  ticketType?: string
  ticketListUuid?: string
}

export interface UnsavedTicketDetail {
  uuid: string
  wbsItem: UnsavedTicketWbsItem
  ticketList?: TicketListBasic
  parentWbsItem: UnsavedTicketParentWbsItem
  ticketType?: string
  projectPlanUuid?: string
}

export const openUnsavedTicketSingleSheetDialog = (
  ticket: UnsavedTicketDetail,
  onClose: () => void
) => {
  const detail = {
    ...ticket,
    revision: '0.0.0',
    cumulation: new ProjectPlanCumulation(),
    commentSummary: new CommentSummary(),
  }

  const isSavedTicket = async (ticketUuid: string): Promise<boolean> => {
    try {
      await TicketApi.getTicketBasicByUuid(ticketUuid)
      return true
    } catch (err: any) {
      return false
    }
  }

  store.dispatch(setSingleSheetData(detail))
  store.dispatch(
    pushFunctionLayer({
      externalId: APPLICATION_FUNCTION_EXTERNAL_ID.TICKET,
      code: ticket.wbsItem.code,
      onClose,
      canOpenInNew: async () => {
        if (await isSavedTicket(detail.uuid)) {
          return {
            ok: true,
          }
        } else {
          return {
            ok: false,
            message: intl.formatMessage({
              id: 'open.in.new.error.not.saved',
            }),
          }
        }
      },
      canCopyUrlLink: async () => {
        if (await isSavedTicket(detail.uuid)) {
          return {
            ok: true,
          }
        } else {
          return {
            ok: false,
            message: intl.formatMessage({
              id: 'copy.url.error.not.saved',
            }),
          }
        }
      },
      getHeaderComponentData: (
        singleSheetData: any[],
        layer: FunctionLayer
      ) => {
        const targetData = singleSheetData?.find(
          data =>
            (data.uuid && data.uuid === detail.uuid) ||
            data.wbsItem.code === layer.code
        )
        return targetData
      },
    })
  )
}
