import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ValueGetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { ProjectBasic } from '../../../../lib/functions/project'
import { ALERT_COLOR } from '../../../../lib/functions/report'
import { RoleBasic } from '../../../../lib/functions/role'
import {
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { ColumnType } from '../../../containers/commons/AgGrid'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { ResourcePlanProjectCellRenderer } from '../components/CellRenderer/ResourcePlanProjectCellRenderer'
import {
  ResourcePlanCPGroupRowsByMemberColRowBody,
  ResourcePlanCrossProjectType,
  ResourcePlanCrossProjectsRow,
  displayNameComparator,
} from '../ResourcePlanCrossProjects'
import {
  ROW_NUMBER_COL_DEF,
  INFORMATION_COLUMN_GROUP_DEF_BASE,
  generateTotalColumnDef,
  WORKMONTHS_FIELD_PREFIX,
  CHANGE_LOG_COLUMN_GROUP,
  iconShowCheckUser,
  generateWorkMonthColDef,
  ALERT_UPPER_LIMIT_FOR_MAN_MONTH,
} from './commonColumnDef'
import { projectCellValueFormatter } from '.'

const getMemberParamsCellStyles = (
  params: CellClassParams<ResourcePlanCrossProjectsRow>
) => {
  const row: ResourcePlanCrossProjectsRow | undefined = params.data
  if (row?.type !== ResourcePlanCrossProjectType.MEMBER) {
    return { color: 'transparent' }
  }
  return { color: 'inherit' }
}

export const AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_MEMBER = {
  field: 'body.user',
  headerName: intl.formatMessage({ id: 'resourcePlan.crossProjects.user' }),
  width: 150,
  hide: false,
  pinned: true,
  suppressMovable: true,
  cellRendererParams: {
    suppressCount: true,
    suppressDoubleClickExpand: true,
    innerRenderer: IconCellRenderer,
    labelField: 'body.user.name',
    iconUrlField: 'body.user.iconUrl',
    iconShowCheckField: 'type',
    iconShowCheck: iconShowCheckUser,
  },
  editable: false,
  valueGetter: (params: ValueGetterParams) => {
    const row: ResourcePlanCrossProjectsRow = params.data
    return row.body.user?.name || ''
  },
  filter: ClientSideTextFilter,
  floatingFilter: true,
  cellStyle: getMemberParamsCellStyles,
}

const getMemberUuid = (row: ResourcePlanCrossProjectsRow) => {
  const rowBody = row?.body as ResourcePlanCPGroupRowsByMemberColRowBody
  return rowBody?.user?.uuid
}

export const COLUMN_DEF_GROUP_ROW_BY_MEMBER:
  | (
      | ColDef<ResourcePlanCrossProjectsRow>
      | ColGroupDef<ResourcePlanCrossProjectsRow>
    )[]
  | null = [
  ROW_NUMBER_COL_DEF,
  {
    ...INFORMATION_COLUMN_GROUP_DEF_BASE,
    children: [
      {
        field: 'body.user.code',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.code',
        }),
        width: 120,
        hide: true,
        lockPosition: true,
        pinned: true,
        floatingFilter: true,
        filter: 'clientSideTextFilter',
        cellStyle: getMemberParamsCellStyles,
      },
      {
        field: 'body.user.division',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.division',
        }),
        width: 120,
        lockPosition: true,
        pinned: true,
        type: [ColumnType.autocomplete],
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
        cellStyle: getMemberParamsCellStyles,
      },
      {
        field: 'body.user.position',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.position',
        }),
        width: 120,
        lockPosition: true,
        pinned: true,
        type: [ColumnType.autocomplete],
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
        cellStyle: getMemberParamsCellStyles,
      },
      {
        field: 'body.user.name',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user',
        }),
        width: 120,
        hide: true,
        pinned: true,
        sortable: false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        valueGetter: params => {
          const row: ResourcePlanCrossProjectsRow | undefined = params.data
          if (row?.isTotal) return ''
          return row?.body?.user?.name || ''
        },
      },
      {
        field: 'body.project',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project',
        }),
        width: 150,
        pinned: true,
        cellRenderer: ResourcePlanProjectCellRenderer,
        cellRendererParams: {
          uiMeta: {},
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: (
          valueA: string | ProjectBasic,
          valueB: string | ProjectBasic
        ): number => {
          const strA: string = projectCellValueFormatter(valueA)
          const strB: string = projectCellValueFormatter(valueB)
          return strA.localeCompare(strB)
        },
        filterParams: {
          getValue: value => projectCellValueFormatter(value),
          getLabel: value => projectCellValueFormatter(value),
        },
      },
      {
        field: 'body.role',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.role',
        }),
        hide: true,
        type: [ColumnType.autocomplete],
        pinned: true,
        cellRendererParams: {
          uiMeta: {},
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: (
          valueA: string | RoleBasic,
          valueB: string | RoleBasic
        ): number => {
          const strA = typeof valueA === 'string' ? valueA : valueA.name
          const strB = typeof valueB === 'string' ? valueB : valueB.name
          return strA.localeCompare(strB)
        },
        cellStyle: getMemberParamsCellStyles,
      },
      {
        field: 'body.user.validFrom',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.validFrom',
        }),
        hide: true,
        pinned: true,
        type: [ColumnType.dateTime],
        floatingFilter: true,
        cellClass: 'dateTimeStyle',
        cellStyle: getMemberParamsCellStyles,
      },
      {
        field: 'body.user.validTo',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.validTo',
        }),
        hide: true,
        pinned: true,
        type: [ColumnType.dateTime],
        floatingFilter: true,
        cellClass: 'dateTimeStyle',
        cellStyle: getMemberParamsCellStyles,
      },
    ],
  },
  generateTotalColumnDef(ResourcePlanCrossProjectType.PROJECT, getMemberUuid),
  {
    groupId: WORKMONTHS_FIELD_PREFIX,
    headerName: '',
  },
  CHANGE_LOG_COLUMN_GROUP,
]

export const generateWorkMontColDefGroupRowByMember = (
  yearMonth: string,
  getWorkingTimeTotal: (yearMonth: string) => string
) => {
  const columnDef: ColDef = generateWorkMonthColDef(
    yearMonth,
    getWorkingTimeTotal,
    ResourcePlanCrossProjectType.PROJECT,
    getMemberUuid
  )
  return {
    ...columnDef,
    cellStyle: (params: CellClassParams) => {
      let style =
        typeof columnDef.cellStyle === 'function'
          ? columnDef.cellStyle(params)
          : columnDef.cellStyle || {}

      const row: ResourcePlanCrossProjectsRow = params.data
      if (!row.isTotal && ALERT_UPPER_LIMIT_FOR_MAN_MONTH < params.value) {
        style!.backgroundColor = ALERT_COLOR.HIGH
      }
      return style
    },
  }
}
