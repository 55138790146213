import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Table,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import {
  BackgroundColor,
  Color,
  FontSize,
} from '../../../../../styles/commonStyles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { QuickFilterKeys } from '../../wbsItemSearch'
import { Checkbox } from '../../../../components/inputs/Checkbox'

type Props = {
  onChangeQuickFilters: (keys: QuickFilterKeys[]) => void | Promise<void>
  selectedFilterGroups: QuickFilterKeys[]
}

const QUICKFILTER_LABEL_NOT_SELECT: string = intl.formatMessage({
  id: 'wbs.header.select.filter',
})

const QUICKFILTER_LABEL_ACCOUNTABLEISME: string = intl.formatMessage({
  id: 'bulksheet.toolbar.quickFilter.label.accountableIsMe',
})
const QUICKFILTER_LABEL_MYRESPONSIBILITY: string = intl.formatMessage({
  id: 'bulksheet.toolbar.quickFilter.label.myResponsibility',
})

const AssignmentFilter = ({
  onChangeQuickFilters,
  selectedFilterGroups,
}: Props) => {
  const [openAssignmentFilter, setOpenAssignmentFilter] =
    useState<boolean>(false)
  const [filterAnchorEl, setFilterAnchorEl] = useState<Element>()
  const [accountableChecked, setAccountableChecked] = useState<boolean>(false)
  const [responsibleChecked, setResponsibleChecked] = useState<boolean>(false)

  const handleFilterClose = () => {
    setFilterAnchorEl(undefined)
    setOpenAssignmentFilter(false)
  }

  const onClickCheckbox = useCallback(
    (filterGroups: QuickFilterKeys[]) => {
      filterGroups.forEach(filterGroup => {
        if (selectedFilterGroups.includes(filterGroup)) {
          onChangeQuickFilters(
            selectedFilterGroups.filter(filter => filter !== filterGroup)
          )
        } else {
          onChangeQuickFilters([...selectedFilterGroups, filterGroup])
        }
      })
    },
    [selectedFilterGroups]
  )

  const selectedFilterLabel = useMemo(() => {
    return accountableChecked && responsibleChecked
      ? QUICKFILTER_LABEL_ACCOUNTABLEISME +
          ', ' +
          QUICKFILTER_LABEL_MYRESPONSIBILITY
      : accountableChecked
      ? QUICKFILTER_LABEL_ACCOUNTABLEISME
      : responsibleChecked
      ? QUICKFILTER_LABEL_MYRESPONSIBILITY
      : QUICKFILTER_LABEL_NOT_SELECT
  }, [accountableChecked, responsibleChecked])

  return (
    <Box
      sx={{
        margin: ' 0 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: 'fit-content',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[5],
          fontSize: FontSize.MEDIUM,
          marginRight: '10px',
        }}
      >
        {intl.formatMessage({ id: 'wbs.search.header.assignment' })}
      </Typography>
      <ClickAwayListener onClickAway={handleFilterClose}>
        <div>
          <Button
            sx={{
              border: `1px solid ${colorPalette.monotone[2]}`,
              background: BackgroundColor.WHITE,
              '&:hover': { background: BackgroundColor.WHITE },
              color: Color.MONOTONE,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onClick={event => {
              if (openAssignmentFilter) {
                setFilterAnchorEl(undefined)
                setOpenAssignmentFilter(false)
              } else {
                setFilterAnchorEl(event.currentTarget)
                setOpenAssignmentFilter(true)
              }
            }}
          >
            <Typography
              sx={{ margin: '0 auto 0 10px', fontSize: FontSize.SMALL }}
            >
              {selectedFilterLabel}
            </Typography>
            {openAssignmentFilter ? (
              <ExpandLess
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            ) : (
              <ExpandMore
                sx={{ margin: '0 5px 0 auto', height: '13px', width: '13px' }}
              />
            )}
          </Button>
          {openAssignmentFilter ? (
            <Popper
              open={!!filterAnchorEl}
              anchorEl={filterAnchorEl}
              sx={{
                zIndex: '1300',
                background: BackgroundColor.WHITE,
                height: 'fit-content',
                width: '170px',
                borderRadius: '4px',
                boxShadow: '0px 1px 2px #0000004D',
              }}
              placement={'bottom-start'}
              modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            >
              <Table>
                <TableRow>
                  <CheckBoxRowCell
                    onClick={() => (
                      setAccountableChecked(!accountableChecked),
                      onClickCheckbox([QuickFilterKeys.ACCOUNTABLE])
                    )}
                    title={QUICKFILTER_LABEL_ACCOUNTABLEISME}
                    checked={accountableChecked}
                    style={{ padding: '20px 20px 10px 20px' }}
                  />
                  <CheckBoxRowCell
                    onClick={() => (
                      setResponsibleChecked(!responsibleChecked),
                      onClickCheckbox([QuickFilterKeys.RESPONSIBLE_OR_ASSIGNEE])
                    )}
                    title={QUICKFILTER_LABEL_MYRESPONSIBILITY}
                    checked={responsibleChecked}
                    style={{ padding: '10px 20px 20px 20px' }}
                  />
                </TableRow>
              </Table>
            </Popper>
          ) : null}
        </div>
      </ClickAwayListener>
    </Box>
  )
}

type CheckBoxProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  title: string
  checked: boolean
  style?: CSSProperties
}

const CheckBoxRowCell = ({ onClick, title, checked, style }: CheckBoxProps) => {
  return (
    <TableCell
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: 'none',
        padding: style?.padding,
        alignItems: 'center',
      }}
    >
      <Checkbox
        size="m"
        onClick={onClick}
        checked={checked}
        sx={{ marginRight: '10px' }}
      />
      <Typography>{title}</Typography>
    </TableCell>
  )
}

export default AssignmentFilter
