import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IFloatingFilterParams } from 'ag-grid-community'
import { FilterInput } from '../../filter/common'
import { TextFilterOperator } from '../../filter/TextFilter'

export type Props = IFloatingFilterParams

export const ServerSideTextFloatingFilter = forwardRef(
  ({ parentFilterInstance }: Props, ref) => {
    const INITIAL_VALUE = undefined
    const inputRef = useRef<HTMLInputElement>(null)
    const [value, setValue] = useState<string | undefined>(INITIAL_VALUE)
    const [isComposing, setIsComposing] = useState<boolean>(false)

    useImperativeHandle(ref, () => {
      return {
        onParentModelChanged(parentModel) {
          const currentValue = inputRef.current?.value || ''
          const parentValue = parentModel?.value || ''
          if (currentValue !== parentValue) {
            setValue(parentValue)
            if (inputRef.current) {
              inputRef.current.value = parentValue
            }
          }
        },
      }
    })

    const changeModel = useCallback(
      _.debounce(v => {
        parentFilterInstance(instance => {
          if (!instance) return
          const model = instance.getModel()
          instance.onFloatingFilterChanged(null, {
            operator: TextFilterOperator.CONTAINS, // Default operator
            ...model,
            value: v,
          })
        })
      }, 300),
      []
    )

    useEffect(() => {
      if (value === INITIAL_VALUE) {
        return
      }
      !isComposing && changeModel(value)
    }, [value])

    return (
      <FilterInput
        ref={inputRef}
        value={value}
        onChange={e => setValue(e.target.value ?? '')}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={e => {
          setIsComposing(false)
          const v = (e.target as HTMLInputElement).value ?? ''
          setValue(v)
          changeModel(v)
        }}
        sx={{ backgroundColor: '#ffffff' }}
      />
    )
  }
)
