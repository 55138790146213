import { useEffect, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { StatusNameLabel } from '../../../commons/AgGrid/components/cell/custom/wbsItemStatus/cellRenderer'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { getWbsItemStatusLabel } from '../../../../../lib/functions/wbsItem'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import { setError } from '../../lib/validation'

interface Props extends ICellRendererParams {
  uiMeta?: FunctionProperty
}

export const WbsItemStatusOnlyNameCellRenderer = (props: Props) => {
  const { value, data, uiMeta } = props
  const [message, setMessage] = useState<string | undefined>()

  useEffect(() => {
    if (uiMeta?.requiredIf) {
      const result = uiMeta.requiredIf.check(value, () => ({}))
      const err = result.ok ? undefined : result.message
      setError(data, err, props)
      setMessage(err)
    }
  }, [value, uiMeta?.requiredIf, data, props])

  return (
    <Tooltip message={message}>
      <RootDiv style={{ backgroundColor: !!message ? '#FADBD8' : undefined }}>
        <StatusNameLabel>{getWbsItemStatusLabel(value)}</StatusNameLabel>
        <FloatingEditableIcon {...props} />
      </RootDiv>
    </Tooltip>
  )
}
