import store from '../../../store'
import { PageArea, PageProps } from '../index'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ActualWorkingHoursHeader from './components/Header'
import { useActualWorkingHoursData } from './hooks/actualWorkingHoursData'
import { usePageState } from './hooks/pageState'
import { DateTerm } from '../../../utils/date'
import {
  ActualWorkingHoursRow,
  StandardWorkingInfo,
} from './ActualWorkingHours'
import { actualWorkingHoursGridOptions } from './gridOptions'
import _ from 'lodash'
import Auth from '../../../lib/commons/auth'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import { getRowNumber } from '../../containers/BulkSheetView/lib/gridApi'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { SearchUnit } from '../../components/headers/HeaderBar/DateRangeSelector'
import { useTaskActualWorkTaskDialogState } from './hooks/taskActualWorkTaskDialogState'
import { useExcelExport } from './hooks/excelExport'
import { RouteComponentProps, withRouter } from 'react-router'
import ActualWorkingHoursView from './components/view'
import ActualWorkingHoursToolbar from './components/Toolbar'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'
import { Collapse } from '@mui/material'

type PathProps = {
  code?: string
  term?: string
}

type Props = PageProps & RouteComponentProps<PathProps>

const ActualWorkingHours = (props: Props) => {
  const functionUuid = props.uuid
  const [loading, setLoading] = useState<boolean>(true)
  const [userUuid, setUserUuid] = useState<string | undefined>()
  const [userName, setUserName] = useState<string>('')
  const [openCancel, setOpenCancel] = useState<boolean>(false)
  const [toolbar, setToolbar] = useState<ToolbarToggleValue | undefined>(
    undefined
  )
  const [reloadHeader, setReloadHeader] = useState<boolean>(false)
  const [standardWorkInfo, setStandardWorkInfo] = useState<StandardWorkingInfo>(
    {
      standardWorkDays: 0,
      standardWorkHours: 0,
      totalWorkDays: 0,
      totalWorkHours: 0,
      diffDays: 0,
      diffHours: 0,
    }
  )
  const { data, summaryRow, refresh, save, getStandardWorkingInfo } =
    useActualWorkingHoursData()
  const pageState = usePageState({ functionUuid })
  const { taskDialogState, setTaskDialogState, openTaskActualWorkList } =
    useTaskActualWorkTaskDialogState()

  const callbackCellEdit = useCallback(() => {
    setReloadHeader(true)
  }, [])

  const gridOptions = useMemo(() => {
    return actualWorkingHoursGridOptions({
      editable: true,
      openTaskActualWorkList,
      callbackCellEdit,
    })
  }, [callbackCellEdit, openTaskActualWorkList])

  const submit = useRef<Function>()
  submit.current = save
  const reload = useRef<Function>()
  reload.current = refresh

  const initPage = useCallback(() => {
    const tenant = Auth.getCurrentTenant()
    const user = tenant!.user!
    setUserUuid(user.uuid)
    setUserName(user.name)
  }, [])

  const prevData = useRef<ActualWorkingHoursRow[]>([])
  prevData.current = data
  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const refreshAll = useCallback(async () => {
    if (!gridOptions.api) return
    setLoading(true)
    try {
      const refreshRows = reload.current ?? refresh
      await refreshRows(
        userUuid,
        undefined,
        pageState.displayDateTerm.startDate,
        pageState.displayDateTerm.endDate
      )
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        errors: new InputError(),
      }
      setReloadHeader(true)
      setLoading(false)
    }
  }, [
    gridOptions,
    pageState.displayDateTerm.endDate,
    pageState.displayDateTerm.startDate,
    refresh,
    userUuid,
  ])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()
      if (gridOptions.context.errors.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }
      const response = submit.current ? await submit.current() : await save()
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    functionUuid,
    gridOptions.api,
    gridOptions.context.errors,
    refreshAll,
    save,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      setOpenCancel(true)
    } else {
      refreshAll()
    }
  }, [hasChanged, refreshAll])

  const callbackReload = useCallback(async () => {
    await refreshAll()
  }, [refreshAll])

  const onSearch = useCallback(
    async (unit: SearchUnit, term: DateTerm) => {
      if (unit !== pageState.searchUnit) {
        pageState.setSearchUnit(unit)
      } else if (term !== pageState.displayDateTerm) {
        pageState.setDisplayDateTerm(term)
      }
    },
    [pageState]
  )

  const onExportExcel = useExcelExport(gridOptions, {
    dateTerm: pageState.displayDateTerm,
    userName,
    standardInfo: standardWorkInfo,
  })

  const onChangeToolbar = useCallback(
    (value: ToolbarToggleValue | undefined) => {
      setToolbar(value)
    },
    []
  )

  useKeyBind(
    [{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }],
    [data, save, pageState.displayDateTerm]
  )

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      errors: new InputError(),
    }
    initPage()
  }, [gridOptions, initPage])

  useEffect(() => {
    if (
      !pageState.initialized ||
      !pageState.displayDateTerm ||
      !pageState.displayDateTerm.startDate ||
      !pageState.displayDateTerm.endDate ||
      !userUuid
    ) {
      return
    }
    refreshAll()
  }, [pageState.initialized, pageState.displayDateTerm, userUuid, refreshAll])

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      userUuid,
    }
  }, [gridOptions, userUuid])

  useEffect(() => {
    if (reloadHeader) {
      const stdInfo = getStandardWorkingInfo()
      setReloadHeader(false)
      setStandardWorkInfo(stdInfo)
    }
  }, [getStandardWorkingInfo, reloadHeader])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  return (
    <PageArea>
      <ActualWorkingHoursHeader
        loading={loading}
        onSubmit={onSubmit}
        onCancel={onReload}
        searchUnit={pageState.searchUnit}
        dateTerm={pageState.displayDateTerm}
        onSearch={onSearch}
        onClickExport={onExportExcel}
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        rowHeight={pageState.rowHeight}
        onChangeRowHeight={(value: number) => pageState.setRowHeight(value)}
      />
      <Collapse in={!!toolbar} timeout={100} sx={{ padding: '5px 0' }}>
        <ActualWorkingHoursToolbar
          toolbar={ToolbarToggleValue.DISPLAY}
          data={standardWorkInfo}
        />
      </Collapse>
      <ActualWorkingHoursView
        gridOptions={gridOptions}
        loading={loading}
        userUuid={userUuid}
        data={data}
        summaryRow={summaryRow}
        rowHeight={pageState.rowHeight}
        onCallbackReload={callbackReload}
        taskDialogState={taskDialogState}
        setTaskDialogState={setTaskDialogState}
        openTaskActualWorkList={openTaskActualWorkList}
        openCancel={openCancel}
        setOpenCancel={setOpenCancel}
      />
    </PageArea>
  )
}

export default withRouter(ActualWorkingHours)
