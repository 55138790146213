import { connect, useDispatch } from 'react-redux'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import { TicketListDetailTree, TicketType } from '../../../lib/functions/ticket'
import { AllState } from '../../../store'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import {
  ColumnQuickFilterKey,
  TicketListRow,
  TicketListState,
} from './TicketListOptions'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ColDef, ColumnState, RowNode } from 'ag-grid-community'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { TicketListDetail } from '../../../lib/functions/ticketList'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Box } from '@mui/material'
import { AggregateField } from '../../../domain/entity/WbsItemEntity'
import { usePageState } from '../../hooks/usePageState'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { Function } from '../../../lib/commons/appFunction'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { fetchTags } from '../../../lib/functions/tag'
import { receivedTags } from '../../../store/tag'
import { TicketListSelector } from '../Tickets/Header/TicketListSelector'
import TicketsReport from '../Tickets/Header/TicketsReport'
import TicketsWbsHeader from '../Tickets/Header/TicketsWbsHeader'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { isProduction } from '../../../utils/urls'
import {
  UnsavedTicketDetail,
  openUnsavedTicketSingleSheetDialog,
} from '../Ticket/ticket'

type TicketListPageState = {
  toolbar: ToolbarToggleValue | undefined
  aggregateType: AggregateField
  workloadUnit: WorkloadUnit
  dateFormat: string
  isReportOpen: boolean
}

const ticketListDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  aggregateType: AggregateField.WBS_ITEM_WORKLOAD,
  workloadUnit: WorkloadUnit.DAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  isReportOpen: false,
}

type OwnProps = {
  code?: string
}

type StateProps = {
  functions: Function[]
}

const TicketList = (props: OwnProps & PageProps & StateProps) => {
  const fn = useMemo(
    () => props.functions.find(v => v.externalId === props.externalId)!,
    [props.functions, props.externalId]
  )
  const { project } = useProjectPrivateContext()
  const ref = useRef<HTMLDivElement>(null)
  const ticketType = props.options!['ticketType']
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        TicketListDetailTree,
        TicketListRow,
        TicketListState
      >
    >()
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [ticketList, setTicketList] = useState<TicketListDetail>()
  const [rowNodes, setRowNodes] = useState<RowNode[]>([])
  const {
    workloadUnit,
    aggregateType,
    dateFormat,
    isReportOpen,
    updatePageState,
  } = usePageState<TicketListPageState>(
    props.uuid,
    ticketListDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const columnSetting = useColumnSetting()
  const [currentColumnState, setCurrentColumnState] = useState<ColumnState[]>(
    []
  )
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  props.options!['onColumnVisible'] = useCallback(
    () => setCurrentColumnState(bulkSheet?.columnApi?.getColumnState() ?? []),
    [bulkSheet]
  )

  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])
  const onChangeAggregateType = useCallback((value: AggregateField) => {
    updatePageState({ aggregateType: value })
  }, [])
  const onChangeWorkloadUnit = useCallback((value: WorkloadUnit) => {
    updatePageState({ workloadUnit: value })
  }, [])
  const onChangeReportOpen = useCallback((value: boolean) => {
    updatePageState({ isReportOpen: value })
  }, [])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const onChangeTicketList = useCallback(
    (value: TicketListDetail | undefined) => {
      if (!bulkSheet) return
      setTicketList(value)
      bulkSheet?.setState(
        {
          ticketList: value,
          editable: !!value,
        },
        () => {
          bulkSheet.refreshGrid()
        }
      )
    },
    [bulkSheet]
  )

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ workloadUnit, workloadUnitState })
    bulkSheet.gridApi!.refreshCells({
      columns: [
        'wbsItem.estimatedWorkload',
        'wbsItem.actualHour',
        'plannedValue',
        'wbsItem.earnedValue',
        'preceding',
        'delayed',
        'remaining',
        'productivity',
      ],
      force: true,
    })
  }, [bulkSheet, workloadUnit, workloadUnitState])

  const resetFilters = useCallback(() => {
    if (!bulkSheet?.gridApi) return
    bulkSheet?.gridApi.setFilterModel([])
  }, [bulkSheet?.gridApi])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      bulkSheet?.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [bulkSheet?.columnApi]
  )

  const onReload = useCallback(() => bulkSheet?.onCancel(), [bulkSheet])

  const onAddRow = useCallback(() => {
    if (!bulkSheet || !bulkSheet.state.ticketList?.wbsItem) return
    const newRow: TicketListRow =
      bulkSheet.options.rowDataSpec.createNewRow(bulkSheet)
    bulkSheet.addRow(undefined, undefined, newRow)

    const detail: UnsavedTicketDetail = {
      uuid: newRow.uuid,
      wbsItem: {
        uuid: newRow.wbsItem.uuid!,
        code: newRow.wbsItem.code!,
        projectUuid: project.uuid,
        status: newRow.wbsItem.status!,
        type: newRow.wbsItem.type!,
        ticketType: newRow.wbsItem.ticketType!,
      },
      ticketList: {
        uuid: bulkSheet.state.ticketList.uuid,
        code: bulkSheet.state.ticketList.wbsItem.code,
        wbsItemUuid: bulkSheet.state.ticketList.wbsItem.uuid,
        ticketType: bulkSheet.state.ticketList.ticketType,
        projectUuid: project.uuid,
        displayName: bulkSheet.state.ticketList.wbsItem.displayName,
      },
      parentWbsItem: {
        uuid: newRow.parentWbsItem!.uuid,
        code: newRow.parentWbsItem!.code,
        projectUuid: project.uuid,
        type: newRow.parentWbsItem!.type,
        displayName: newRow.parentWbsItem!.displayName,
        ticketType: newRow.parentWbsItem!.ticketType!,
        ticketListUuid: bulkSheet.state.ticketList?.uuid,
      },
      ticketType: newRow.ticketType,
    }
    openUnsavedTicketSingleSheetDialog(detail, () => {
      bulkSheet.refreshAfterUpdateSingleRow(detail.uuid)
    })
  }, [bulkSheet, project.uuid])

  useEffect(() => {
    const bulkTicketList = bulkSheet?.state.ticketList
    if (bulkTicketList !== ticketList) {
      setTicketList(bulkTicketList)
    }
  }, [bulkSheet?.state.ticketList])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ dateFormat })
    bulkSheet.gridApi?.refreshCells({
      columns: [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
      ],
      force: true,
    })
  }, [bulkSheet, dateFormat])

  const dispatch = useDispatch()
  useEffect(() => {
    const initTags = async () => {
      const response = await fetchTags({ projectUuid: project.uuid })
      const tags = response.json
      bulkSheet?.setState({ tags }, () =>
        dispatch(receivedTags(project.uuid, tags))
      )
    }
    initTags()
  }, [project.uuid, bulkSheet])

  return (
    <PageArea>
      <SavePopper loading={isLoading} onSubmit={() => bulkSheet?.onSubmit()} />
      <TicketListSelector
        ticketType={ticketType}
        ticketListUuid={ticketList?.uuid}
        onChangeTicketList={onChangeTicketList}
      />
      <TicketsReport
        aggregateField={aggregateType}
        workloadUnit={workloadUnit}
        rowNodes={rowNodes}
        projectUuid={project.uuid}
        ticketList={ticketList}
        open={isReportOpen}
        closeReport={() => onChangeReportOpen(false)}
      />
      <TicketsWbsHeader
        onChangeAggregateField={onChangeAggregateType}
        aggregateField={aggregateType}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        workloadUnit={workloadUnit}
        rowHeight={rowHeight}
        onChangeHeight={value => bulkSheet?.onChangeHeight(value)}
        onClickImportExcel={bulkSheet?.importExcel}
        onClickExportExcel={() =>
          bulkSheet?.openExcelOutputColumnSelectDialog()
        }
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={column =>
          bulkSheet?.resetSpecificColumnFilter(
            column.colId || column.field || ''
          )
        }
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={bulkSheet?.onDeleteSortedAllColumns}
        onChangeSortColumnState={bulkSheet?.onChangeSortColumnState}
        onReload={onReload}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        onClickFavoriteColumnFilterButton={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        sortColumnsState={sortColumnsState}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
        isReportOpen={isReportOpen}
        onChangeReportOpen={onChangeReportOpen}
        // WORKAROUND: Support for all ticket types in the future
        onAddRow={
          !isProduction && ticketType === TicketType.REFINEMENT
            ? onAddRow
            : undefined
        }
      />
      <Box ref={ref} sx={{ height: '100%' }}>
        {/* @ts-ignore */}
        <BulkSheetComponent
          {...props}
          hideHeader={true}
          hideToolbar={true}
          setBulkSheet={ctx => setBulkSheet(ctx)}
          setFilteredColumns={columns => setFilteredColumns(columns)}
          setRowHeight={height => setRowHeight(height)}
          setSubmitDisabled={value => setSubmitDisabled(value)}
          setIsLoading={value => setIsLoading(value)}
          specificProps={{
            updateAggregationRowNodes: (nodes: RowNode[]) => {
              setRowNodes(nodes)
            },
          }}
          setSortColumnsState={value => setSortColumnsState(value)}
        />
      </Box>
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        columnApi={bulkSheet?.columnApi}
        gridApi={bulkSheet?.gridApi}
        close={columnSetting.close}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={fn.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={currentColumnState}
        offset={90}
      />
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(TicketList))
)
