import moment from 'moment'

enum RELATIVE_DATE {
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
}

function toPascalCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1, str.length)
}

export default class RelativeDate {
  static YESTERDAY = () => new RelativeDate(RELATIVE_DATE.YESTERDAY)
  static TODAY = () => new RelativeDate(RELATIVE_DATE.TODAY)
  static TOMORROW = () => new RelativeDate(RELATIVE_DATE.TOMORROW)
  private static allowValues = [
    RELATIVE_DATE.YESTERDAY,
    RELATIVE_DATE.TODAY,
    RELATIVE_DATE.TOMORROW,
  ]
  value: RELATIVE_DATE
  private static allowTexts = [
    ...RelativeDate.allowValues,
    ...RelativeDate.allowValues.map(v => v.toLowerCase()),
    ...RelativeDate.allowValues.map(v => toPascalCase(v)),
  ]

  constructor(value: string) {
    if (!value || !RelativeDate.of(value.toUpperCase())) {
      throw new Error(
        `Can not create RelativeDate instance only supported ${RelativeDate.allowValues.join(
          ','
        )}.(value: ${value})`
      )
    }
    this.value = value.toUpperCase() as RELATIVE_DATE
  }

  static of(value?: string): boolean {
    return RelativeDate.allowValues.some(v => v === value)
  }

  static allowText(text?: string): boolean {
    return !!text && RelativeDate.allowTexts.includes(text)
  }

  toLabel(): string {
    return this.value
  }

  toDate(): Date {
    switch (this.value) {
      case RELATIVE_DATE.YESTERDAY:
        return moment()
          .subtract(1, 'day')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate()
      case RELATIVE_DATE.TODAY:
        return moment().hour(0).minute(0).second(0).millisecond(0).toDate()
      case RELATIVE_DATE.TOMORROW:
        return moment()
          .add(1, 'day')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate()
      default:
        throw new Error(`Not support value.(value = ${this.value})`)
    }
  }
}
