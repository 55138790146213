import { useCallback, useEffect, useState } from 'react'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { DateBound } from '../../components/toggleGroups/StartAndEndDateToggleGroup'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { WorkloadUnit } from '../../../lib/functions/workload'

type StoredProjectOverviewPageState = {
  aggregateTarget: AggregateTarget
  aggregateField: AggregateField
  workloadUnit: WorkloadUnit
  aggregateBaseDate: DateBound
  dateFormat: string
}

export const usePageState = functionUuid => {
  const [initialized, setInitialized] = useState(false)
  const [aggregateTarget, setAggregateTarget] = useState<AggregateTarget>(
    WbsItemType.DELIVERABLE
  )
  const [aggregateField, setAggregateField] = useState<AggregateField>(
    AggregateField.WBS_ITEM_WORKLOAD
  )
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.HOUR
  )
  const [aggregateBaseDate, setAggregateBaseDate] = useState<DateBound>(
    DateBound.END
  )
  const [dateFormat, setDateFormat] = useState(
    DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
  )

  useEffect(() => {
    restorePageState()
  }, [])

  useEffect(() => {
    if (!initialized) return
    uiStates.update(
      {
        key: UiStateKey.PageState,
        scope: UiStateScope.User,
        value: JSON.stringify({
          aggregateTarget,
          aggregateField,
          workloadUnit,
          aggregateBaseDate,
          dateFormat,
        }),
      },
      functionUuid
    )
  }, [
    aggregateTarget,
    aggregateField,
    workloadUnit,
    aggregateBaseDate,
    dateFormat,
  ])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    if (!stored.value) {
      setInitialized(true)
      return
    }
    const payload: StoredProjectOverviewPageState = JSON.parse(stored.value)

    setAggregateTarget(payload.aggregateTarget ?? WbsItemType.DELIVERABLE)
    setAggregateField(
      payload.aggregateField ?? AggregateField.WBS_ITEM_WORKLOAD
    )
    setWorkloadUnit(payload.workloadUnit ?? WorkloadUnit.HOUR)
    setAggregateBaseDate(payload.aggregateBaseDate ?? DateBound.END)
    setDateFormat(payload.dateFormat ?? DISPLAY_DATE_SHORT_FORMAT_WITH_DAY)
    setInitialized(true)
  }, [])

  return {
    initialized,

    aggregateTarget,
    setAggregateTarget,

    aggregateField,
    setAggregateField,

    workloadUnit,
    setWorkloadUnit,

    aggregateBaseDate,
    setAggregateBaseDate,

    dateFormat,
    setDateFormat,
  }
}
