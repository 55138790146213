import { useCallback, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Auth from '../../../lib/commons/auth'
import AlertDialog from '../../components/dialogs/AlertDialog'
import TextInput from '../../components/editors/input/TextInput'
import store from '../../../store'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import { DialogState } from './commons/LoginContainer'
import { intl } from '../../../i18n'

const ChangePassword = (props: RouteComponentProps) => {
  const [oldPassword, setOldPassword] = useState<string>()
  const [newPassword, setNewPassword] = useState<string>()
  const [newPasswordForConfirm, setNewPasswordForConfirm] = useState<string>()
  const [dialog, setDialog] = useState<DialogState>({
    open: false,
    message: '',
  })

  const onChange = useCallback(
    (
      key: 'oldPassword' | 'newPassword' | 'newPasswordForConfirm',
      value: string
    ) => {
      if (key === 'oldPassword') {
        setOldPassword(value)
      } else if (key === 'newPassword') {
        setNewPassword(value)
      } else if (key === 'newPasswordForConfirm') {
        setNewPasswordForConfirm(value)
      }
    },
    []
  )

  const close = useCallback(() => {
    props.history.goBack()
  }, [props.history])

  const openAlertDialog = useCallback((message: string) => {
    setDialog({ open: true, message })
  }, [])

  const closeAlertDialog = useCallback(() => {
    setDialog({ open: false, message: '' })
  }, [])

  const onSubmit = useCallback(async () => {
    // Check required item.
    const errorMessages: string[] = []
    if (!oldPassword) {
      errorMessages.push(
        intl.formatMessage({
          id: 'changePassword.enterOldPassword',
        })
      )
    }
    if (!newPassword) {
      errorMessages.push(
        intl.formatMessage({
          id: 'changePassword.enterNewPassword',
        })
      )
    }
    if (!newPasswordForConfirm) {
      errorMessages.push(
        intl.formatMessage({
          id: 'changePassword.enterNewPasswordCheck',
        })
      )
    }
    if (errorMessages.length > 0) {
      openAlertDialog(errorMessages.join('\n'))
      return
    }

    // Check if password for confirm is equal to password.
    if (newPassword !== newPasswordForConfirm) {
      openAlertDialog(
        intl.formatMessage({
          id: 'changePassword.passwordNotMatch',
        })
      )
      return
    }

    await Auth.changePassword(oldPassword!, newPassword!)
      .then(() => {
        store.dispatch(
          addScreenMessage(store.getState().appFunction.currentExternalId!, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({
              id: 'changePassword.succeeded',
            }),
          })
        )
        close()
      })
      .catch(err => {
        openAlertDialog(err.message)
      })
  }, [oldPassword, newPassword, newPasswordForConfirm, openAlertDialog, close])

  return (
    <Dialog
      open={true}
      aria-labelledby="changePasswordDialogTitle"
      aria-describedby="changePasswordDialogDescription"
    >
      <AlertDialog
        isOpen={dialog.open}
        message={dialog.message}
        closeButtonTitle="OK"
        closeHandler={closeAlertDialog}
      />
      <DialogTitle id="changePasswordDialogTitle">
        {intl.formatMessage({ id: 'changePassword' })}
      </DialogTitle>
      <DialogContent>
        <TextInput
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          id="oldPassword"
          label={intl.formatMessage({
            id: 'changePassword.oldPassword',
          })}
          name="oldPassword"
          type="password"
          autoFocus={true}
          onChange={event => onChange('oldPassword', event.target.value)}
        />
        <TextInput
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          id="newPassword"
          label={intl.formatMessage({
            id: 'changePassword.newPassword',
          })}
          name="newPassword"
          type="password"
          onChange={event => onChange('newPassword', event.target.value)}
        />
        <TextInput
          variant="outlined"
          margin="normal"
          required={true}
          fullWidth={true}
          id="newPasswordForConfirm"
          label={intl.formatMessage({
            id: 'changePassword.newPasswordForConfirm',
          })}
          name="newPasswordForConfirm"
          type="password"
          onChange={event =>
            onChange('newPasswordForConfirm', event.target.value)
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>
          {intl.formatMessage({ id: 'changePassword.close' })}
        </Button>
        <Button onClick={onSubmit} color="secondary">
          {intl.formatMessage({ id: 'changePassword.edit' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withRouter(ChangePassword)
