import { CircularProgress, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import registrationFailureIcon from '../../../assets/registration_failure_icon.svg'
import { LoginButton, LoginTitle, LoginContainer } from './components'
import { intl } from '../../../i18n'
import { Progress } from '../../components/feedback/progress'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { getDomain } from '../../../infrastructure/browser/url'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'

const DetailItem = styled('a')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  flexDirection: 'row',
  textDecoration: 'none',
})

const GridRowTitle = styled('a')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  flexDirection: 'row',
  textDecoration: 'none',
  width: '128px',
})
const Grid = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '159px',
  padding: '16px',
  gap: '10px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
  backgroundColor: '#F7F8FA',
  width: '476px',
})
const GridRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '21px',
  padding: '0 16px',
  gap: '8px',
  width: '412px',
})

const GridRowValue = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '21px',
  padding: '0 16px',
  gap: '8px',
  width: '103px',
  fontWeight: '400',
  color: '#243050',
})

const Icon = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '80px',
  padding: '12px',
  borderRadius: '64px',
  backgroundColor: '#FCF4F7',
  width: '80px',
})

const ButtonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '476px',
  height: '70px',
  padding: '16px',
  gap: '32px',
})

type RegistratedInfo = {
  tenantAlias: string
  email: string
  temporaryPassword: string
}
type RegistrationError = {
  title: string
  detail: string
}

export const WelcomeNewUser = () => {
  const { confirmRegistration } = useAuthenticateUseCase()
  const [registratedInfo, setRegistratedInfo] = useState<
    RegistratedInfo | undefined
  >(undefined)
  const [error, setError] = useState<RegistrationError | undefined>(undefined)
  const location = useLocation()
  useEffect(() => {
    const fn = async () => {
      const queryObject = queryString.parse(location.search)
      const userPoolId = queryObject['userPoolId'] as string
      const userUuid = queryObject['userUuid'] as string
      const token = queryObject['token'] as string
      if (!userPoolId || !userUuid || !token) {
        setError({
          title: intl.formatMessage({
            id: 'welcomeNewUser.invalidLink',
          }),
          detail: intl.formatMessage({
            id: 'welcomeNewUser.invalidLink.detail',
          }),
        })
        return
      }
      const result = await confirmRegistration(userPoolId, userUuid, token)
      switch (result.status) {
        case 'SUCCESS':
          setRegistratedInfo({
            ...result,
          })
          return
        case 'LINK_EXPIRED':
          setError({
            title: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.linkExpired',
            }),
            detail: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.linkExpired.detail',
            }),
          })
          return
        case 'ALREADY_AUTH':
          setError({
            title: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.alreadyAuth',
            }),
            detail: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.alreadyAuth.detail',
            }),
          })
          return
        case 'USER_NOT_EXISTS':
          setError({
            title: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.userNotExists',
            }),
            detail: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.userNotExists.detail',
            }),
          })
          return
        case 'TOKEN_MISMATCH':
        case 'SYSTEM_ERROR':
          setError({
            title: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.tokenMismatch',
            }),
            detail: intl.formatMessage({
              id: 'welcomeNewUser.confirmRegistration.tokenMismatch.detail',
            }),
          })
          return
      }
    }
    fn()
  }, [confirmRegistration, location.search])
  const history = useHistory()
  const toLogin = useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <>
      <LoginContainer>
        {!error && (
          <>
            {!registratedInfo && (
              <>
                <LoginTitle>
                  {intl.formatMessage({
                    id: 'welcomeNewUser.identityVerification',
                  })}
                </LoginTitle>
                <CircularProgress />
                <GridRowTitle>
                  {intl.formatMessage({
                    id: 'welcomeNewUser.userRegistrationCompletedSoon',
                  })}
                </GridRowTitle>
              </>
            )}
            {registratedInfo && (
              <>
                <LoginTitle>
                  {intl.formatMessage({
                    id: 'welcomeNewUser.userRegistrationCompleted',
                  })}
                </LoginTitle>
                <DetailItem>
                  {intl.formatMessage({
                    id: 'welcomeNewUser.userRegistrationCompleted.detail',
                  })}
                </DetailItem>
                <Grid>
                  <GridRow>
                    <GridRowTitle>
                      {intl.formatMessage({ id: 'welcomeNewUser.Tenant' })}
                    </GridRowTitle>
                    <GridRowValue>{`${
                      registratedInfo.tenantAlias
                    }.${getDomain()}`}</GridRowValue>
                  </GridRow>
                  <GridRow>
                    <GridRowTitle>
                      {intl.formatMessage({ id: 'login.email' })}
                    </GridRowTitle>
                    <GridRowValue>{registratedInfo.email}</GridRowValue>
                  </GridRow>
                  <GridRow>
                    <GridRowTitle>
                      {intl.formatMessage({ id: 'login.temporaryPassword' })}
                    </GridRowTitle>
                    <GridRowValue>
                      {registratedInfo.temporaryPassword}
                    </GridRowValue>
                  </GridRow>
                </Grid>
                <ButtonDiv>
                  <LoginButton color={'skyBlue'} onClick={toLogin}>
                    {intl.formatMessage({ id: 'login.toLogin' })}
                  </LoginButton>
                </ButtonDiv>
              </>
            )}
          </>
        )}
        {error && (
          <>
            <LoginTitle>{error.title}</LoginTitle>
            <Icon>
              <img src={registrationFailureIcon} />
            </Icon>
            <DetailItem>{error.detail}</DetailItem>
          </>
        )}
      </LoginContainer>
    </>
  )
}
