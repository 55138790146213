import { Box } from '@mui/material'
import { WorkloadUnitToggleGroup } from '../../../../components/toggleGroups'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import TermSelector, {
  DisplayUnit,
  ShiftType,
} from '../../../../containers/BulkSheetView/components/header/datePicker/TermSelector'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { useEffect } from 'react'
import { ColDef } from 'ag-grid-community'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { DateTerm } from '../../../../../utils/date'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import { MyWbsItemTabType } from '../../hooks/myWbsItemsData'
import { DecimalAndSexagesimalScale } from '../../../../../lib/functions/decimalAndSexagesimalScale'
import { useTmpFeatureFlag } from '../../hooks/featureFlag'
import { DecimalAndSexagesimalScaleToggleButtonGroup } from '../../../../components/inputs/ToggleButtonGroup/DecimalAndSexagesimalScaleToggleGroup'

type Props = {
  onChangeWorkloadUnit: (_: WorkloadUnit) => void
  workloadUnit: WorkloadUnit
  onChangeDecimalScaleUnit: (_: DecimalAndSexagesimalScale) => void
  decimalScaleUnit: DecimalAndSexagesimalScale
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  onClickExportExcel: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  onReload: () => void
  displayUnit: DisplayUnit
  dateTerm: DateTerm | undefined
  onChangeTerm: (displayUnit: DisplayUnit) => void
  shiftDisplayTerm: (value: ShiftType) => void
  onClickColumnSettingButton: (e) => void
  sortColumnsState: SortedColumnState[]
  currentFormat?: string | undefined
  onChangeDateFormat: (value: string) => void
  tab: MyWbsItemTabType
  columnSettingOpen: boolean
}

const MyWbsItemsWbsHeader = ({
  onChangeWorkloadUnit,
  workloadUnit,
  onChangeDecimalScaleUnit,
  decimalScaleUnit,
  rowHeight,
  onChangeHeight,
  onClickExportExcel,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  onReload,
  displayUnit,
  dateTerm,
  onChangeTerm,
  shiftDisplayTerm,
  onClickColumnSettingButton,
  sortColumnsState,
  currentFormat,
  onChangeDateFormat,
  tab,
  columnSettingOpen,
}: Props) => {
  const featureFlag = useTmpFeatureFlag()
  useEffect(() => {
    onChangeWorkloadUnit(WorkloadUnit.HOUR)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '5px 20px 10px 20px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '60px',
      }}
    >
      <Button
        onClick={onReload}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      <WorkloadUnitToggleGroup
        value={workloadUnit}
        refresh={onChangeWorkloadUnit}
      />
      {tab === MyWbsItemTabType.TASK && featureFlag && (
        <DecimalAndSexagesimalScaleToggleButtonGroup
          value={decimalScaleUnit}
          onChange={onChangeDecimalScaleUnit}
        />
      )}
      <FilterButton
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
      />
      <SortButton
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortColumnsState}
      />
      <ColumnSettingButton
        onClick={onClickColumnSettingButton}
        open={columnSettingOpen}
      />
      <DisplayFormatButton
        height={rowHeight}
        onChangeHeight={onChangeHeight}
        currentFormat={currentFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          marginRight: '15px',
        }}
      >
        <FileExportButton onClickExport={onClickExportExcel} />
      </Box>
      {tab === MyWbsItemTabType.TASK && (
        <TermSelector
          displayUnit={displayUnit}
          dateTerm={dateTerm}
          onChangeTerm={onChangeTerm}
          shiftDisplayTerm={shiftDisplayTerm}
        />
      )}
    </div>
  )
}

export default MyWbsItemsWbsHeader
