import {
  Input,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { intl } from '../../../../i18n'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { projectPrivate } from '../../../higher-order-components/projectPrivate'
import { useCallback, useEffect, useState } from 'react'
import { ProjectNotificationWorkspace } from '../projectNotificationSettings'
import { useSlackExternalServiceStorageService } from '../../../../services/storage-services/external-services/slackExternalServiceStorageService'
import { SlackWorkspace } from '../../../../domain/value-object/Slack/WorkspaceVO'
import CancelIcon from '../../../../assets/cancel_icon.svg'

type Props = {
  workspace?: ProjectNotificationWorkspace
  onChangeWorkspace: (value?: ProjectNotificationWorkspace) => void
}

export const SlackWorkspaceSelect = projectPrivate(
  ({ workspace, onChangeWorkspace }: Props) => {
    const { projectUuid } = useProjectPrivateContext()
    const [workspaces, setWorkspaces] = useState<
      ProjectNotificationWorkspace[]
    >([])

    const { getAuthorizedSlackWorkspace } =
      useSlackExternalServiceStorageService()

    const fetchOptions = useCallback(async () => {
      const options = await getAuthorizedSlackWorkspace()
      setWorkspaces(options.map(convertResponseRowToOption))
    }, [projectUuid])

    const convertResponseRowToOption = useCallback(
      (row: SlackWorkspace): ProjectNotificationWorkspace => ({
        projectUuid,
        slackCollaborationWorkspaceUuid: row.uuid!,
        slackWorkspaceId: row.slackWorkspaceId!,
        slackWorkspaceName: row.slackWorkspaceName!,
      }),
      [projectUuid]
    )

    const onChange = useCallback(
      (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        const targetId = event.target.value
        const targetWorkspace = workspaces.find(
          v => v.slackWorkspaceId === targetId
        )
        onChangeWorkspace(targetWorkspace)
      },
      [workspace, workspaces, onChangeWorkspace]
    )

    const onClear = useCallback(() => {
      onChangeWorkspace(undefined)
    }, [onChangeWorkspace])

    useEffect(() => {
      fetchOptions()
    }, [projectUuid])

    const customInput = (
      <Input
        startAdornment={
          !workspace && (
            <InputAdornment
              position="start"
              sx={{
                width: '100px',
                margin: '0 -104px 0 8px',
                color: colorPalette.monotone[2],
              }}
            >
              {intl.formatMessage({
                id: 'project.notification.setting.header.select.workspace',
              })}
            </InputAdornment>
          )
        }
        endAdornment={
          workspace && (
            <InputAdornment
              position="end"
              sx={{
                margin: '0 0 0 -27px',
              }}
            >
              <img
                src={CancelIcon}
                style={{
                  color: colorPalette.monotone[4],
                  cursor: 'pointer',
                  padding: '8px',
                }}
                onClick={onClear}
              />
            </InputAdornment>
          )
        }
        sx={{ padding: '0 8px 0 0' }}
      />
    )

    return (
      <Select
        value={workspace?.slackWorkspaceId || ''}
        labelId="project-notification-setting-header-label"
        label={intl.formatMessage({
          id: 'project.notification.setting.header.select.workspace',
        })}
        sx={{
          border: `1px solid ${colorPalette.monotone[2]}`,
          borderRadius: '4px',
          width: '300px',
          height: '30px',
          color: colorPalette.monotone[4],
          backgroundColor: colorPalette.monotone[0],
          '.MuiSelect-icon': workspace && { display: 'none' },
          '.MuiSelect-select': { padding: '8px' },
        }}
        MenuProps={{
          sx: {
            height: '540px',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '21px',
          },
        }}
        input={customInput}
        onChange={onChange}
      >
        {workspaces.map(v => {
          return (
            <MenuItem
              key={v.slackWorkspaceId}
              value={v.slackWorkspaceId}
              sx={{
                color: colorPalette.monotone[4],
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
              }}
            >
              {v.slackWorkspaceName}
            </MenuItem>
          )
        })}
      </Select>
    )
  }
)
