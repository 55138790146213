import { CellClassParams, CellStyle, CellStyleFunc } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import DateCell from '../../common/date'
import WbsItemRowUtil from '../../../../lib/wbsItem'
import { BackgroundColor } from '../../../../../../../../styles/commonStyles'
import { WbsItemStatus } from '../wbsItemStatus'

const wbsItemDateStyle = (
  target: 'scheduledDate' | 'actualDate'
): CellStyleFunc => {
  return (params: CellClassParams): CellStyle => {
    const style = { ...DateCell.cellStyle }
    const dateFieldName = params.colDef.field || ''
    const splitted = dateFieldName.split('.')
    const wbsItemFieldName = splitted[0]
    if (
      splitted.length < 3 ||
      !['startDate', 'endDate'].includes(splitted[2]) ||
      params.data.isTotal
    ) {
      return style
    }
    const isStartDate = splitted[2] === 'startDate'
    const wbsItem = objects.getValue(params.data, wbsItemFieldName)
    if (
      target === 'actualDate' &&
      ((isStartDate &&
        WbsItemRowUtil.isStartDelayed(wbsItem) &&
        wbsItem.status === WbsItemStatus.TODO) ||
        (!isStartDate &&
          WbsItemRowUtil.isEndDelayed(wbsItem) &&
          ![WbsItemStatus.DONE, WbsItemStatus.DISCARD].includes(
            wbsItem.status
          )))
    ) {
      objects.setValue(style, 'backgroundColor', BackgroundColor.ALERT)
    }
    return style
  }
}

const wbsItemScheduledDateStyle: CellStyleFunc =
  wbsItemDateStyle('scheduledDate')

const wbsItemActualDateStyle: CellStyleFunc = wbsItemDateStyle('actualDate')

export default { wbsItemScheduledDateStyle, wbsItemActualDateStyle }
