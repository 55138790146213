import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import WbsItemApi from '../../../../../lib/functions/wbsItem'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Autocomplete, Popper } from '@mui/material'
import TextInput from '../../input/TextInput'
import _ from 'lodash'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'

const StyledAutocomplete = styled(Autocomplete)({
  width: '175px',
  minWidth: '175px',
  height: '30px',
  marginRight: '6px',
  '& .MuiAutocomplete-inputRoot': {
    height: '100%',
    paddingTop: '3px !important',
  },
}) as typeof Autocomplete

interface OwnProps {
  value?: string
  onChange: (newValue: string | undefined) => void
  types?: WbsItemType[]
}

type Props = OwnProps & WrappedComponentProps

interface WbsItemOption {
  uuid: string
  code: string
  displayName: string
}

export const WbsItemSelect = injectIntl(
  ({ intl, value, onChange, types }: Props) => {
    const [rendered, setRendered] = React.useState(false)
    const [selected, setSelected] = useState<WbsItemOption | undefined>()
    const [options, setOptions] = useState<WbsItemOption[]>([])
    const [inputValue, setInputValue] = useState<string>('')

    const onValueChanged = useCallback(
      (_, value: WbsItemOption | null) => {
        onChange(value?.uuid)
      },
      [onChange]
    )
    const search = useCallback(
      async (v: string) => {
        const entities = await WbsItemApi.search(v, { types })
        setOptions(entities)
      },
      [types]
    )
    const fetch = useCallback(async (value: string) => {
      try {
        const entity = (await WbsItemApi.getDetail({ uuid: value })).json
        setSelected(entity)
        setInputValue(entity.displayName)
        setOptions([entity])
      } catch (err) {
        onValueChanged(undefined, null)
        search('')
      }
    }, [])
    const searchOnInputChanged = useCallback(_.debounce(search, 300), [types])
    const onInputChanged = useCallback((event: SyntheticEvent, val: string) => {
      // If an event is issued from outside the UI (event = null), the previous value may be returned.
      if (!!event) {
        onValueChanged(undefined, null)
        setInputValue(val)
        searchOnInputChanged(val)
      }
    }, [])

    // Search or fetch when the component is rendered.
    useEffect(() => {
      if (!rendered) {
        if (value) {
          fetch(value)
        } else {
          search(inputValue)
        }
        setRendered(true)
      }
    }, [rendered])

    // Fetch WbsItem when value has been changed.
    useEffect(() => {
      if (value) {
        fetch(value)
      }
    }, [value])

    return (
      <StyledAutocomplete
        value={selected}
        options={options}
        size={'small'}
        onChange={onValueChanged}
        inputValue={inputValue}
        onInputChange={onInputChanged}
        getOptionLabel={option => option.displayName || ''}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        isOptionEqualToValue={(option: WbsItemOption, value: WbsItemOption) => {
          return value && option.uuid === value.uuid
        }}
        renderInput={params => (
          <TextInput
            {...params}
            placeholder={intl.formatMessage({
              id: 'wbsItemSelect',
            })}
            variant="outlined"
            fullWidth={true}
          />
        )}
        clearOnBlur={false}
      />
    )
  }
)
