import React from 'react'
import { useIntl } from 'react-intl'
import {
  getMeasuringTimer,
  useTimerRepository,
} from '../../../../../../../../services/adaptors/timerRepositoryAdapter'
import { timerStatus } from '../../../../../../../../domain/entity/TimerEntity'
import { useDispatch, useSelector } from 'react-redux'
import {
  receivedTimerItem,
  stopTimer,
} from '../../../../../../../../store/measuringTimer'
interface ButtonCellRendererProps {
  data: {
    body: {
      timerStatus: string
    }
    uuid: string
  }
  context: any
}

const ButtonCellRenderer: React.FC<ButtonCellRendererProps> = ({
  data,
  context,
}) => {
  let buttonText: string
  let buttonColor: string
  const intl = useIntl()

  const status = data.body.timerStatus
  const userUuid = useSelector((state: any) => state.user.user?.uuid)
  const taskUuid = data.uuid
  const dispatch = useDispatch()

  if (status === timerStatus.MEASURING) {
    buttonText = intl.formatMessage({ id: 'myWbsItems.timer.stopButton' })
    buttonColor = 'red'
  } else {
    buttonText = intl.formatMessage({ id: 'myWbsItems.timer.startButton' })
    buttonColor = '#00CC33'
  }

  const { start, stop } = useTimerRepository()
  const buttonStyle = React.useMemo(
    () => ({
      backgroundColor: buttonColor,
      color: 'white',
      padding: '5px 10px',
      width: '100%',
      border: 'none',
      cursor: 'pointer',
      minWidth: '100px',
    }),
    [buttonColor]
  )

  const handleClick = async () => {
    const input = {
      userUuid,
      taskUuid,
    }
    if (status === timerStatus.MEASURING) {
      await stop(input.userUuid, input.taskUuid)
      dispatch(stopTimer())
    } else {
      await start(input.userUuid, input.taskUuid)
      const measuringTimer = await getMeasuringTimer()
      dispatch(
        receivedTimerItem(
          measuringTimer.taskName,
          measuringTimer.parentWbsItemName,
          measuringTimer.startTime,
          measuringTimer.wbsItemTypeUrl,
          measuringTimer.taskUuid
        )
      )
    }
    await context.refreshAll?.()
  }

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {buttonText}
    </button>
  )
}
export default ButtonCellRenderer
