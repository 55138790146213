import { getTextColor } from '../../../lib/functions/tag'

interface Props {
  value?: string
  backgroundColor?: string
}

const Tag = ({ value, backgroundColor }: Props) => {
  if (!value) return <></>
  const textColor = getTextColor(backgroundColor)
  return (
    <div
      style={{
        backgroundColor: backgroundColor ?? '#FFFFFF',
        borderRadius: '16px',
        padding: '0px 8px',
        height: '24px',
        boxShadow: '0px 1px 1px #00000033',
        color: textColor,
        marginRight: '3px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {value}
    </div>
  )
}

export default Tag
