import React from 'react'
import { WorkloadUnit } from '../../../../../lib/functions/workload'
import { GanttDisplayParameter } from '../../../../containers/commons/AgGrid/components/cell/custom/gantt/GanttDisplayParameter'
import ProjectPlanColumnFilterButtonGroup from './ProjectPlanColumnFilterButtonGroup'
import { ToolbarGroup } from '../index'
import { ColumnQuickFilterKey } from '../../projectPlanOptions'
import { ToolbarDivider } from '../../../../components/toolbars/Toolbar'
import {
  AggregateField,
  WbsItemType,
} from '../../../../../domain/entity/WbsItemEntity'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../../components/toggleGroups'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../../components/buttons/ToolbarFilterButtonGroup'

export interface DisplayToolbarProps {
  wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK
  onChangeWbsItemType: (value) => void
  aggregateType: AggregateField
  onChangeAggregateType: (value: AggregateField) => void
  workloadUnit: WorkloadUnit
  onChangeWorkloadUnit: (value: WorkloadUnit) => void
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
  showGanttParameter: boolean
}

type Props = DisplayToolbarProps & ToolbarFilterGroupProps

export const DisplayToolbar = (props: Props) => {
  return (
    <>
      <ToolbarGroup>
        <ToolbarFilterButtonGroup
          filteredColumns={props.filteredColumns}
          sortedColumns={props.sortedColumns}
          onDeletedFilterColumn={props.onDeletedFilterColumn}
        />
      </ToolbarGroup>
      <ToolbarDivider />
      <ToolbarGroup>
        <AggregateTargetToggleGroup
          value={props.wbsItemType}
          onChange={props.onChangeWbsItemType}
        />
        <AggregateFieldToggleGroup
          value={props.aggregateType}
          onChange={props.onChangeAggregateType}
        />
        <WorkloadUnitToggleGroup
          value={props.workloadUnit}
          refresh={props.onChangeWorkloadUnit}
        />
      </ToolbarGroup>
      <ToolbarDivider />
      <ToolbarGroup>
        <ProjectPlanColumnFilterButtonGroup
          onClick={props.onChangeColumnFilter}
        />
      </ToolbarGroup>
      <ToolbarDivider />
      <ToolbarGroup>
        <GanttDisplayParameter visible={props.showGanttParameter} />
      </ToolbarGroup>
    </>
  )
}
