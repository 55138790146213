import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { getLabel, parse } from '../../lib/commons/i18nLabel'

export const useCustomEnumRepository = () => {
  const fetchAsVO = useCallback(
    async (customEnumCode: string, projectUuid?: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/custom_enums/values',
        {
          customEnumCode,
          groupKeys: [projectUuid],
        }
      )
      return response.json.map(option => ({
        value: option.value,
        name: getLabel(parse(option.nameI18n)),
        iconUrl: option.iconUrl,
      }))
    },
    []
  )
  return { fetchAsVO }
}
