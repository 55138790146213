import { AllState } from '../../../../store'
import components, { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../pages'
import { Function } from '../../../../lib/commons/appFunction'
import React, { useEffect, useState } from 'react'
import ProjectPlanTab from '../../../pages/ProjectPlanTab'
import { connect } from 'react-redux'
import { getProjectPlanUuidByWbsItemUuid } from '../../../../lib/functions/projectPlan'
import WbsItemApi from '../../../../lib/functions/wbsItem'
import BulkSheet from '../../BulkSheet'

interface OwnProps {
  wbsItemUuid: string
}

interface StateProps {
  functions: Function[]
}

const ProjectPlanTabContainer = (props: OwnProps & StateProps) => {
  const { wbsItemUuid, functions } = props
  const [projectPlanUuid, setProjectPlanUuid] = useState<string | undefined>(
    undefined
  )
  const [projectUuid, setProjectUuid] = useState<string | undefined>(undefined)
  useEffect(() => {
    getProjectPlanUuidByWbsItemUuid(wbsItemUuid).then(fetchedUuid => {
      setProjectPlanUuid(fetchedUuid)
    })
    WbsItemApi.getByUuid(wbsItemUuid).then(wbsItem => {
      setProjectUuid(wbsItem.projectUuid)
    })
  }, [wbsItemUuid])
  const component =
    components[APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_EDIT]
  const appFunction = functions.find(
    v => v.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_EDIT
  )
  if (!projectUuid) {
    return <></>
  }
  return (
    <>
      {/* @ts-ignore */}
      <BulkSheet
        {...component}
        options={new ProjectPlanTab()}
        externalId={appFunction!.externalId}
        uuid={appFunction!.uuid}
        treeRootUuid={projectPlanUuid}
        projectUuid={projectUuid}
        hideHeader={true}
      />
    </>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(ProjectPlanTabContainer)
