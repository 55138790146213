import { useState, useEffect, useCallback } from 'react'
import { WbsItemType } from '../../../../../../domain/entity/WbsItemEntity'
import {
  WbsItemFormModel,
  createInitialValue,
  toDeltaInput,
} from '../../../model'
import { IItemDelta } from '../../../../../../domain/value-object/ItemDeltaInputVO'
import { useDispatch } from 'react-redux'
import {
  addScreenMessage,
  MessageLevel,
} from '../../../../../../store/messages'
import { intl } from '../../../../../../i18n'
import {
  createTicketInitialValue,
  TicketFormModel,
} from '../../../model/ticket'
import { ReferencedTicketListEntity } from '../../../../../../domain/entity/TicketListEntity'
import {
  isReferencedEntityValueDiffered,
  toReferencedEntityDeltaValue,
} from '../../../model/properties'
import { RiskEntity } from '../../../../../../domain/entity/ticket/RiskEntity'
import {
  createRiskInitialValue,
  RiskFormModel,
  toRiskDeltaInput,
} from '../../../model/risk'
import { useRiskRepository } from '../../../../../../services/adaptors/riskRepositoryAdaptor'

export const useRiskSingleSheetData = (uuid: string) => {
  const { fetch, updateDelta } = useRiskRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<RiskEntity | undefined>()
  const [wbsItemFormModel, setWbsItemFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK)
  )
  const [ticketFormModel, setTicketFormModel] = useState<TicketFormModel>(
    createTicketInitialValue('RISK')
  )
  const [riskFormModel, setRiskFormModel] = useState<RiskFormModel>(
    createRiskInitialValue()
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await fetch(uuid)
    setEntity(response)
    setWbsItemFormModel(response.ticket.wbsItem)
    setTicketFormModel(response.ticket)
    setRiskFormModel(response)
    setIsLoading(false)
  }, [fetch, uuid])
  useEffect(() => {
    fetchData()
    setInitialized(true)
  }, [fetchData, uuid])
  const onChangeWbsItem = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setWbsItemFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
    },
    []
  )
  const onChangeTicketList = useCallback(
    (ticketList: ReferencedTicketListEntity | undefined) => {
      if (!ticketList) return
      setTicketFormModel(prev => ({
        ...prev,
        ticketList,
        ticketType: ticketList.ticketType,
      }))
    },
    []
  )
  const onChange = useCallback(
    <K extends keyof RiskFormModel, T extends RiskFormModel[K]>(
      path: K,
      value: T
    ) => {
      setRiskFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
    },
    []
  )
  const dispatch = useDispatch()
  const submit = useCallback(async () => {
    if (!entity) return
    setIsLoading(true)
    const wbsItem = toDeltaInput(entity.ticket.wbsItem, wbsItemFormModel)
    const ticket = {
      uuid: entity.ticket.uuid,
      ticketListUuid: isReferencedEntityValueDiffered(
        entity.ticket.ticketList,
        ticketFormModel.ticketList
      )
        ? toReferencedEntityDeltaValue(
            entity.ticket.ticketList,
            ticketFormModel.ticketList
          )
        : undefined,
      wbsItem,
    }
    const input = {
      ...toRiskDeltaInput(entity, riskFormModel),
      ticket,
    }
    const request = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
    await fetchData()
    setIsLoading(false)
  }, [
    dispatch,
    entity,
    fetchData,
    riskFormModel,
    ticketFormModel.ticketList,
    updateDelta,
    uuid,
    wbsItemFormModel,
  ])
  return {
    initialized,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    riskFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    onChange,
    reload: fetchData,
    submit,
  }
}
