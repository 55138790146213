import { useCallback } from 'react'
import { GridOptions } from 'ag-grid-community'
import { exportExcel } from '../../../containers/BulkSheet/excel'

export const useExcel = (gridOptions: GridOptions) => {
  const onExportExcel = useCallback(
    (colIds: string[]) => {
      if (!colIds || colIds.length === 0) return
      exportExcel({
        fileNamePrefix: 'flagxs_project_resourcePlanNew_',
        gridOptions,
        exportColIds: colIds,
      })
    },
    [gridOptions]
  )

  return {
    onExportExcel,
  }
}
