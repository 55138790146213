import { CSSProperties, forwardRef, LegacyRef } from 'react'

const GithubIcon = forwardRef(
  (props: { style?: CSSProperties }, ref: LegacyRef<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.542"
      height="32"
      viewBox="0 0 32.542 32"
      ref={ref}
    >
      <path
        id="github-mark"
        d="M16.285,0a16.422,16.422,0,0,0-5.15,31.969c.809.163,1.105-.353,1.105-.787,0-.38-.027-1.684-.027-3.042-4.53.978-5.473-1.956-5.473-1.956a4.191,4.191,0,0,0-1.807-2.39c-1.483-1,.108-1,.108-1a3.415,3.415,0,0,1,2.508,1.684,3.449,3.449,0,0,0,4.745,1.358,3.5,3.5,0,0,1,1.025-2.2C9.706,23.25,5.9,21.838,5.9,15.536a6.423,6.423,0,0,1,1.671-4.4A5.967,5.967,0,0,1,7.738,6.79s1.375-.435,4.475,1.684a15.524,15.524,0,0,1,8.142,0c3.1-2.119,4.476-1.684,4.476-1.684a5.966,5.966,0,0,1,.162,4.346,6.3,6.3,0,0,1,1.672,4.4c0,6.3-3.8,7.687-7.441,8.094a3.9,3.9,0,0,1,1.105,3.042c0,2.2-.027,3.966-.027,4.509,0,.435.3.951,1.105.788A16.422,16.422,0,0,0,16.285,0Z"
        fill="#24292f"
        fill-rule="evenodd"
      />
    </svg>
  )
)

export default GithubIcon
