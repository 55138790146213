import * as d3 from 'd3'
import {
  Scale,
  TwoDimensionalPoint,
  TwoDimensionalPoints,
  TwoDimensionalScales,
} from './model/chart'
import { useMemo } from 'react'
import { useLine } from './hooks/lineChart'
import { colorPalette } from '../../style/colorPallete'

type LineStyle = 'STRAIGHT' | 'DASHED'

type LineGraphStyle = {
  color: string
  width?: number
  lineStyle?: LineStyle
  opacity?: number
}

type NodeFeedbacks<X, Y> = {
  onPointClicked?: (
    point: TwoDimensionalPoint<X, Y>,
    elem: SVGCircleElement
  ) => void
}

type LineWithNodeProps<X, Y> = {
  points: TwoDimensionalPoints<X, Y>
} & LineGraphStyle &
  NodeStyleProps &
  TwoDimensionalScales<X, Y> &
  NodeFeedbacks<X, Y>

export const LineGraphWithNode = <X, Y>({
  xScale,
  yScale,
  points,
  color,
  lineStyle,
  width,
  nodeType,
  onPointClicked,
}: LineWithNodeProps<X, Y>) => {
  return (
    <g>
      <LineGraph
        color={color}
        lineStyle={lineStyle}
        width={width}
        xScale={xScale}
        yScale={yScale}
        points={points}
      />
      <Nodes
        color={color}
        nodeType={nodeType}
        xScale={xScale}
        yScale={yScale}
        points={points}
        onPointClicked={onPointClicked}
      />
    </g>
  )
}

type LineProps<X, Y> = LineGraphStyle &
  TwoDimensionalScales<X, Y> & {
    points: TwoDimensionalPoint<X, Y>[]
  }

export const LineGraph = <X, Y>({
  color,
  lineStyle = 'STRAIGHT',
  width = 1.5,
  xScale,
  yScale,
  points,
  opacity = 1,
}: LineProps<X, Y>) => {
  const d = useLine(xScale, yScale, points)
  const strokeDashArray = useMemo(() => {
    switch (lineStyle) {
      case 'STRAIGHT':
        return ''
      case 'DASHED':
        return '8, 8'
    }
  }, [lineStyle])
  return (
    <path
      fill="none"
      stroke={color}
      strokeWidth={width}
      d={d}
      strokeDasharray={strokeDashArray}
      strokeLinecap="round"
      style={{ opacity }}
    />
  )
}

type NodeType = 'FILLED' | 'DONUT'
type NodeStyleProps = {
  color: string
  nodeType?: NodeType
}
type NodesProps<X, Y> = NodeStyleProps & {
  xScale: Scale<X>
  yScale: Scale<Y>
  points: TwoDimensionalPoint<X, Y>[]
  onPointClicked?: (
    p: TwoDimensionalPoint<X, Y>,
    elem: SVGCircleElement
  ) => void
}

const Nodes = <X, Y>({
  color,
  nodeType = 'FILLED',
  xScale,
  yScale,
  points,
  onPointClicked,
}: NodesProps<X, Y>) => {
  const nodes = useMemo(() => {
    return points.map(p => ({
      cx: xScale(p.x),
      cy: yScale(p.y),
      onClick: (elem: SVGCircleElement) => onPointClicked?.(p, elem),
    }))
  }, [points, xScale, yScale, onPointClicked])
  const style = useMemo(() => {
    switch (nodeType) {
      case 'FILLED':
        return {
          fill: color,
        }
      case 'DONUT':
        return {
          fill: colorPalette.monotone[0],
          stroke: color,
          strokeWidth: 1,
        }
      default:
        return {}
    }
  }, [color, nodeType])
  return (
    <>
      {nodes.map((v, i) => (
        <circle
          {...style}
          key={i}
          r={4}
          cx={v.cx}
          cy={v.cy}
          onClick={e => v.onClick(e.currentTarget)}
        />
      ))}
    </>
  )
}

type NodeProps<X, Y> = {
  xScale: Scale<X>
  yScale: Scale<Y>
  point: TwoDimensionalPoint<X, Y>
  color?: string
}
export const LineNode = <X, Y>({
  xScale,
  yScale,
  point,
  color = colorPalette.monotone[4],
}: NodeProps<X, Y>) => {
  const [cx, cy] = useMemo(
    () => [xScale(point.x), yScale(point.y)],
    [point.x, point.y, xScale, yScale]
  )
  return (
    <g>
      <circle r="6" cx={cx} cy={cy} fill={color} />
      <circle r="3" cx={cx} cy={cy} fill={colorPalette.monotone[0]} />
    </g>
  )
}
