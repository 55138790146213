import { useState, useCallback } from 'react'

export type DisplayGraph = {
  displayScheduleGraph: boolean
  displayActualAndProspectGraph: boolean
  displaySimulatedGraph: boolean
  displayIdealGraph: boolean
}
export type ToggleDisplayGraph = {
  toggleDisplayScheduleGraph: () => void
  toggleDisplayActualAndProspectGraph: () => void
  toggleDisplaySimulatedGraph: () => void
  toggleDisplayIdealGraph: () => void
}
export const useDisplayGraph = (): DisplayGraph & ToggleDisplayGraph => {
  const [displayScheduleGraph, setDisplayScheduleGraph] =
    useState<boolean>(true)
  const toggleDisplayScheduleGraph = useCallback(
    () => setDisplayScheduleGraph(prev => !prev),
    []
  )
  const [displayActualAndProspectGraph, setDisplayActualAndProspectGraph] =
    useState<boolean>(true)
  const toggleDisplayActualAndProspectGraph = useCallback(
    () => setDisplayActualAndProspectGraph(prev => !prev),
    []
  )
  const [displaySimulatedGraph, setDisplaySimulatedGraph] =
    useState<boolean>(true)
  const toggleDisplaySimulatedGraph = useCallback(
    () => setDisplaySimulatedGraph(prev => !prev),
    []
  )
  const [displayIdealGraph, setDisplayIdealGraph] = useState<boolean>(true)
  const toggleDisplayIdealGraph = useCallback(
    () => setDisplayIdealGraph(prev => !prev),
    []
  )
  return {
    displayScheduleGraph,
    toggleDisplayScheduleGraph,
    displayActualAndProspectGraph,
    toggleDisplayActualAndProspectGraph,
    displaySimulatedGraph,
    toggleDisplaySimulatedGraph,
    displayIdealGraph,
    toggleDisplayIdealGraph,
  }
}
