import API from '../../lib/commons/api'
import { CommitVO } from './../../domain/value-object/development/CommitVO/index'
import { DevelopmentRepository } from '../../applications/ports/developmentRepository'
import DateTimeVO from '../../domain/value-object/DateTimeVO'

export const useDevelopmentRepository = (): DevelopmentRepository => {
  const getCommitsByWbsItemUuid = async (
    wbsItemUuid: string
  ): Promise<CommitVO[]> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/development/commits/wbs_item',
      { wbsItemUuid },
      true
    )
    return response.json.map(res => {
      return {
        ...res,
        timestamp: new DateTimeVO(res.timestamp),
      }
    })
  }
  return {
    getCommitsByWbsItemUuid,
  }
}
