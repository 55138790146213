import { SingleSheetOptions } from '../../containers/SingleSheet'
import {
  ProjectConverterSpec,
  ProjectData,
  getProjectStyleRule,
} from '../Project'

export default class ProjectInformationReference extends SingleSheetOptions<ProjectData> {
  converterSpec = new ProjectConverterSpec()
  referece = true
  getStyleRule = getProjectStyleRule
}
