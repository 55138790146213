import { ReferencedEntityWithIcon } from '../../../../../../domain/value-object/ReferencedEntity'
import { intl } from '../../../../../../i18n'

export enum EntitySearchFilterType {
  ALL = 'ALL',
  BLANK = 'BLANK',
  SELECTED_VALUES = 'SELECTED_VALUES',
}
export type EntitySearchFilter = {
  filterType: EntitySearchFilterType
  selectedValues: ReferencedEntityWithIcon[]
}
export const getEntitySearchFilterTypeLabel = (
  filterType: EntitySearchFilterType
): string => {
  switch (filterType) {
    case EntitySearchFilterType.ALL:
      return intl.formatMessage({
        id: 'bulksheet.filter.entitySearchFilter.type.all',
      })
    case EntitySearchFilterType.BLANK:
      return intl.formatMessage({
        id: 'bulksheet.filter.entitySearchFilter.type.blank',
      })
    case EntitySearchFilterType.SELECTED_VALUES:
      return intl.formatMessage({
        id: 'bulksheet.filter.entitySearchFilter.type.selectedValues',
      })
  }
}
