import {
  AutocompleteRenderInputParams,
  Avatar,
  InputAdornment,
  InputBase,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { ReferencedEntityWithIcon } from '../../../../domain/value-object/ReferencedEntity'

export const renderInputWithIcon = <Entity extends ReferencedEntityWithIcon>(
  params: AutocompleteRenderInputParams,
  value: Entity | undefined
) => {
  const startAdornment = value ? (
    <InputAdornment position="start">
      <StyledAvatar variant="circular" src={value?.iconUrl} />
    </InputAdornment>
  ) : undefined
  const InputProps = {
    ...params.InputProps,
    startAdornment,
  }
  return <TextInput {...InputProps} inputProps={params.inputProps} />
}

const TextInput = styled(InputBase)({
  width: '100%',
  color: colorPalette.monotone[10],
})
const StyledAvatar = styled(Avatar)({
  width: '20px',
  height: '20px',
})
