import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import Auth from '../../../../../lib/commons/auth'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { OrganizationDetail } from '../../../../../lib/functions/tenant'
import { Box, Button, ClickAwayListener, Typography } from '@mui/material'
import { UserDetail } from '../../../../../lib/functions/user'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  BackgroundColor,
  Color,
  FontSize,
  TextColor,
} from '../../../../../styles/commonStyles'
import { Directory, Function } from '../../../../../lib/commons/appFunction'
import TenantAvatar from '../../../../components/icons/TenantAvatar'
// TODO: Removed when ready for production release
import CommonsTenantMenu from '../../commons/CommonsTenantMenu'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'

interface Props extends RouteComponentProps, WrappedComponentProps {
  user?: UserDetail
  organization?: OrganizationDetail
  functions?: Function[]
  onClick: () => void
  menu: Directory[]
}

interface State {
  anchorEl: Element | null
}

const MenuTop = ({ user, organization, menu, onClick }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const tenant = Auth.getCurrentTenant()
  if (!tenant || !user) {
    return <></>
  }

  return (
    <Box
      sx={{
        height: '56px',
        width: '240px',
        background:
          process.env.REACT_APP_ENV === 'staging'
            ? BackgroundColor.GREY_GRADATION
            : Color.FLAGXS_BLUE,
        opacity: 1,
      }}
    >
      <Box
        display="flex"
        sx={{
          height: '56px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            width: 'calc(100% - 45px)',
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
          }}
        >
          <TenantAvatar
            src={organization?.iconUrl}
            style={{
              margin: '0 15px 0 20px',
              height: '24px',
              width: '24px',
              border: `solid ${colorPalette.skyBlue[5]} 1px`,
            }}
          />
          <Typography
            sx={{
              color: TextColor.WHITE,
              opacity: 1,
              fontSize: FontSize.LARGE,
              padding: '0 10px 0 0',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 700,
            }}
          >
            {tenant.organization?.displayName}
          </Typography>
        </div>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Button
            onClick={event => {
              setAnchorEl(event.currentTarget)
              onClick()
            }}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
              color: '#E4F5FD',
              fontWeight: 500,
              fontSize: '12px',
              fontFamily: 'Noto Sans JP',
              minWidth: '30px',
              marginRight: '16px',
            }}
          >
            {intl.formatMessage({
              id: 'menu.setting',
            })}
          </Button>
        </ClickAwayListener>
      </Box>
      <CommonsTenantMenu
        openMenu={!!anchorEl}
        anchorEl={anchorEl}
        menu={menu}
      />
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  user: state.user.user,
  organization: state.tenant.organization,
  functions: state.appFunction.functions,
})

export default withRouter(connect(mapStateToProps)(injectIntl(MenuTop)))
