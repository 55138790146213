import { useCallback, useState } from 'react'
import { MyWbsItemTabType } from './myWbsItemsData'

export const useDialog = () => {
  const [cancelConfirm, setCancelConfirm] = useState<boolean>(false)
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
  const [tabTransitionConfirm, setTabTransitionConfirm] =
    useState<boolean>(false)
  const [tab, setTab] = useState<MyWbsItemTabType>()
  const [uiState, setUiState] = useState<boolean>(false)

  const [actualResult, setActualResult] = useState<boolean>(false)
  const [taskUuid, setTaskUuid] = useState<string>()

  const [excel, setExcel] = useState<boolean>(false)

  const openCancelConfirm = useCallback(() => setCancelConfirm(true), [])
  const closeCancelConfirm = useCallback(() => setCancelConfirm(false), [])

  const openDeleteConfirm = useCallback(() => setDeleteConfirm(true), [])
  const closeDeleteConfirm = useCallback(() => setDeleteConfirm(false), [])

  const openTabTransitionConfirm = useCallback(
    (tabType: MyWbsItemTabType) => {
      setTab(tabType)
      setTabTransitionConfirm(true)
    },
    [tab]
  )
  const closeTabTransitionConfirm = useCallback(() => {
    setTab(undefined)
    setTabTransitionConfirm(false)
  }, [])

  const openActualResult = useCallback(
    (uuid: string) => {
      setTaskUuid(uuid)
      setActualResult(true)
    },
    [actualResult, taskUuid]
  )

  const closeActualResult = useCallback(() => {
    setActualResult(false)
    setTaskUuid(undefined)
  }, [actualResult, taskUuid])

  const openExcel = useCallback(() => {
    setExcel(true)
  }, [])

  const closeExcel = useCallback(() => {
    setExcel(false)
  }, [])

  const openUiState = useCallback(() => setUiState(true), [])
  const closeUiState = useCallback(() => setUiState(false), [])

  return {
    cancelConfirm,
    openCancelConfirm,
    closeCancelConfirm,

    deleteConfirm,
    openDeleteConfirm,
    closeDeleteConfirm,

    tabTransitionConfirm,
    tab,
    openTabTransitionConfirm,
    closeTabTransitionConfirm,

    actualResult,
    taskUuid,
    openActualResult,
    closeActualResult,

    excel,
    openExcel,
    closeExcel,

    uiState,
    openUiState,
    closeUiState,
  }
}
