import { generateUuid } from '../../../utils/uuids'
import API, { APIResponse } from '../../../lib/commons/api'
import { TreeRow } from '../../containers/BulkSheetView/model'

/**
 * RowData definition
 */
export interface TagRow extends TreeRow {
  projectUuid: string
  name?: string
  backgroundColor: string
  used?: boolean
}

/**
 * Row data functionality
 */
export const createNewTagRow = (projectUuid: string): TagRow => {
  return {
    added: true,
    treeValue: [],
    uuid: generateUuid(),
    projectUuid: projectUuid,
    backgroundColor: '#ffffff',
  }
}

export interface TagInput {
  uuid: string
  projectUuid: string
  name: string
  backgroundColor: string
}
export interface TagDeleteInput {
  uuid: string
  lockVersion: number
}
export interface TagOrder {
  tagUuid: string
  displayOrder: number
}
export interface TagOrderInput {
  projectUuid: string
  tagOrder: TagOrder[]
}
export interface TagsBatchInput {
  added: TagInput[]
  edited: TagInput[]
  deleted: TagDeleteInput[]
  order: TagOrderInput
}

export const updateBatch = async (
  request: TagsBatchInput
): Promise<APIResponse> => {
  return API.functional.request('POST', '/api/v1/projects/tags/batch', request)
}
