import { ColDef } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea } from '../..'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { AllState } from '../../../../store'
import BulkSheetComponent, {
  BulkSheet,
  ROW_HEIGHT,
} from '../../../containers/BulkSheet'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { projectPrivate } from '../../../higher-order-components/projectPrivate'
import ProgressReportTableOptions, {
  ProgressReportDataAsTree,
  ProgressReportTableBulkSheetProps,
  ProgressReportTableRow,
  ProgressReportTableState,
} from './progressReportTableOptions'
import ProgressReportTableToolbar from './Toolbar'
import { useProgressReportTableStates } from './useProgressReportTableStates'
import { pageComponent } from '../../../higher-order-components/pageComponent'

const ProgressReportTable = props => {
  const fn = props.functions.find(v => v.externalId === props.externalId)

  const progressReportTableOptions = useMemo(
    () => new ProgressReportTableOptions(),
    []
  )

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        ProgressReportTableBulkSheetProps,
        ProgressReportDataAsTree,
        ProgressReportTableRow,
        ProgressReportTableState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [initialized, setInitialized] = useState<boolean>(false)

  const { ticketTypes } = useProjectPrivateContext()

  const {
    searchCondition,
    viewConfig,
    updateSearchCondition,
    updateViewConfig,
    savePageState,
  } = useProgressReportTableStates(fn.uuid, props.projectUuid)

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setState({
      condition: { ...bulkSheet?.state.condition, ...searchCondition },
    })
    setInitialized(true)
  }, [bulkSheet, searchCondition])

  useEffect(() => {
    if (!bulkSheet || !initialized) return
    bulkSheet.refreshDataWithLoading()
  }, [bulkSheet, initialized])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setState(
      {
        viewConfig: { ...bulkSheet?.state.viewConfig, ...viewConfig },
      },
      () => {
        bulkSheet?.refreshDynamicColumns(true)
      }
    )
  }, [bulkSheet, viewConfig])

  const onSearch = useCallback(() => {
    if (!bulkSheet) return
    bulkSheet.refreshDataWithLoading()
    savePageState(searchCondition)
  }, [bulkSheet])

  return (
    <PageArea>
      <ProgressReportTableToolbar
        projectUuid={props.projectUuid}
        searchCondition={searchCondition}
        onChangeSearchCondition={newCondition => {
          updateSearchCondition(newCondition)
        }}
        onSearch={onSearch}
        viewConfig={viewConfig}
        onChangeViewConfig={newConfig => {
          updateViewConfig(newConfig)
          savePageState(newConfig)
        }}
        onResetColumnState={() => bulkSheet?.resetColumnAndFilterState()}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        onRestoreColumnState={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        filteredColumns={filteredColumns}
        onDeletedFilterColumn={column =>
          bulkSheet?.resetSpecificColumnFilter(column || '')
        }
        sortedColumns={sortedColumns}
      />
      <BulkSheetComponent
        {...props}
        options={progressReportTableOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        specificProps={{
          ...props.specificProps,
          ticketTypes: ticketTypes,
        }}
      />
    </PageArea>
  )
}

const mapStateToProps = (state: AllState, props) => ({
  functions: state.appFunction.functions,
  projectUuid: props.projectUuid || state.project.selected,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(ProgressReportTable))
)
