import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { AllState } from '../../../../store'
import { BaseWbsItemType } from '../../../../store/project'

export const useWbsItemTypes = () => {
  const baseWbsItemType = useSelector<AllState, BaseWbsItemType>(
    state => state.project.wbsItemTypes
  )
  const ticketTypes = useSelector<AllState, WbsItemTypeVO[]>(
    state => state.project.ticketTypes
  )
  const wbsItemTypes = useMemo(
    () => [...baseWbsItemType.getAll(), ...ticketTypes],
    [baseWbsItemType, ticketTypes]
  )

  return {
    wbsItemTypes,
  }
}
