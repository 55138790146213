import { Typography } from '@mui/material'
import { NewsType } from '../../../../../store/news'
import { intl } from '../../../../../i18n'
import { TextColor } from '../../../../../styles/commonStyles'

type Props = {
  newsType: NewsType
}

const NewsTitle = ({ newsType }: Props) => {
  let title: string | undefined = undefined
  switch (newsType) {
    case NewsType.ASSIGNED_TO_WBS_ITEM:
      title = intl.formatMessage({ id: 'information.assigned.wbs.item' })
      break
    case NewsType.CHANGED_WBS_ITEM_STATUS:
      title = intl.formatMessage({ id: 'information.changed.wbs.item.status' })
      break
    case NewsType.COMMENTED:
      title = intl.formatMessage({ id: 'information.commented' })
      break
    case NewsType.CHANGED_WBS_ITEM_PRIORITY:
    case NewsType.CHANGED_WBS_ITEM_PRIORITY_V2:
    case NewsType.CHANGED_WBS_ITEM_CRITICAL:
      title = intl.formatMessage({ id: 'information.changed.priority' })
      break
    case NewsType.ASSIGNED_TO_WBS_ITEM_WATCHER:
      title = intl.formatMessage({ id: 'information.assigned.watcher' })
      break
    case NewsType.CHANGED_WBS_ITEM_ASSIGNMENT:
    case NewsType.UNASSIGNED_WBS_ITEM:
      title = intl.formatMessage({ id: 'information.unassigned' })
      break
    case NewsType.ASSIGNED_TO_PROJECT:
      title = intl.formatMessage({ id: 'information.assigned.project' })
      break
    case NewsType.NEW_MANAGEMENT_NOTICE:
      title = intl.formatMessage({
        id: 'information.administrative.management',
      })
  }
  return (
    <Typography
      sx={{
        color: TextColor.MONOTONE,
        fontSize: '14px',
        fontWeight: 700,
        padding: '15px',
      }}
    >
      {title}
    </Typography>
  )
}

export default NewsTitle
