/**
 * returns YY/MM/DD
 */
export const formatDate = (date: Date): string => {
  const year = `${date.getFullYear() % 100}`
  const monthNum = date.getMonth() + 1
  const month = monthNum < 10 ? `0${monthNum}` : `${monthNum}`
  const dayNum = date.getDate()
  const day = dayNum < 10 ? `0${dayNum}` : `${dayNum}`
  return `${year}/${month}/${day}`
}

/**
 * returns MM/DD
 */
export const formatMonthDate = (date: Date): string => {
  const monthNum = date.getMonth() + 1
  const month = monthNum < 10 ? `0${monthNum}` : `${monthNum}`
  const dayNum = date.getDate()
  const day = dayNum < 10 ? `0${dayNum}` : `${dayNum}`
  return `${month}/${day}`
}

/**
 * returns YY/MM
 */
export const formatYearMonth = (date: Date): string => {
  const year = `${date.getFullYear() % 100}`
  const monthNum = date.getMonth() + 1
  const month = monthNum < 10 ? `0${monthNum}` : `${monthNum}`
  return `${year}/${month}`
}

export const formatFullDate = (date: Date): string => {
  const year = `${date.getFullYear()}`
  const monthNum = date.getMonth() + 1
  const month = monthNum < 10 ? `0${monthNum}` : `${monthNum}`
  const dayNum = date.getDate()
  const day = dayNum < 10 ? `0${dayNum}` : `${dayNum}`
  return `${year}/${month}/${day}`
}
