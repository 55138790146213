import { useCallback, useMemo, useState } from 'react'
import {
  SidebarPanelRoot,
  SimulationConfigPanel,
  SimulatedSimulationFromDateConfigPanel,
  SidebarHeaderArrowIcon,
  SidebarPanelHeader,
  SidebarPanelHeaderIconButton,
  SidebarPanelMainArea,
  SidebarPanelTitle,
} from '.'
import { intl } from '../../../../../i18n'
import { BurndownChartSimulationVariables } from '../../model'
import { UpdateSimulatedSimulationVariables } from '../../hooks/simulationVariables'

type SimulatedGraphConfigPanelProps = Pick<
  BurndownChartSimulationVariables,
  'simulatedVelocity' | 'simulatedResourceRate' | 'simulatedSimulationFromDate'
> &
  UpdateSimulatedSimulationVariables

export const SimulatedGraphSimulationPanel = ({
  simulatedVelocity,
  simulatedResourceRate,
  simulatedSimulationFromDate,
  updateSimulatedVelocity,
  updateSimulatedResourceRate,
  updateSimulatedSimulationFromDate,
  resetSimulatedVelocity,
  resetSimulatedResourceRate,
  resetSimulatedSimulationFromDate,
}: SimulatedGraphConfigPanelProps) => {
  const [velocityLabel, resourceRateLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.simulation.velocity' }),
      intl.formatMessage({
        id: 'progressReport.simulation.resourceDevotionRate',
      }),
    ],
    []
  )
  const [open, setOpen] = useState<boolean>(true)
  const toggle = useCallback(() => setOpen(prev => !prev), [])

  return (
    <SidebarPanelRoot>
      <SidebarPanelHeader>
        <SidebarPanelTitle>リソースシミュレーション</SidebarPanelTitle>
        <SidebarPanelHeaderIconButton onClick={toggle}>
          <SidebarHeaderArrowIcon open={open} />
        </SidebarPanelHeaderIconButton>
      </SidebarPanelHeader>
      <SidebarPanelMainArea in={open}>
        <SimulationConfigPanel
          label={velocityLabel}
          value={simulatedVelocity}
          update={updateSimulatedVelocity}
          max={2}
          onReset={resetSimulatedVelocity}
        />
        <SimulationConfigPanel
          label={resourceRateLabel}
          value={simulatedResourceRate}
          update={updateSimulatedResourceRate}
          max={2}
          onReset={resetSimulatedResourceRate}
        />
        <SimulatedSimulationFromDateConfigPanel
          value={simulatedSimulationFromDate}
          update={updateSimulatedSimulationFromDate}
          onReset={resetSimulatedSimulationFromDate}
        />
      </SidebarPanelMainArea>
    </SidebarPanelRoot>
  )
}
