import VOBase from '../base'
import moment from 'moment'
import DateVO from '../DateVO'

interface IDateRange {
  startDate?: Date
  endDate?: Date
}

interface IFormatedDateRange {
  startDate?: string
  endDate?: string
}

export default class DateRangeVO extends VOBase {
  private readonly value: IDateRange
  private readonly FORMAT = 'YYYY/MM/DD'
  private readonly API_FORMAT = 'YYYY-MM-DD'

  constructor(_value: {
    startDate?: number | string | Date
    endDate?: number | string | Date
  }) {
    super()
    if (!_value) {
      throw new Error(
        'Can not create DateRangeVO instance because params is empty.'
      )
    }
    if (
      typeof _value === 'object' &&
      Object.keys(_value).some(key => !['startDate', 'endDate'].includes(key))
    ) {
      throw new Error(
        `Can not create DateRangeVO instance because params is invalid. property${JSON.stringify(
          _value
        )} is invalid.`
      )
    }

    this.value = {}
    const { startDate, endDate } = _value
    if (startDate) {
      if (moment(startDate).isValid()) {
        this.value.startDate = moment(startDate).toDate()
      } else {
        throw new Error(
          `Can not create DateRangeVO instance because not supported params(params: ${_value}).`
        )
      }
    }
    if (endDate) {
      if (moment(endDate).isValid()) {
        this.value.endDate = moment(endDate).toDate()
      } else {
        throw new Error(
          `Can not create DateRangeVO instance because not supported params(params: ${_value}).`
        )
      }
    }
  }

  getValue = (): IDateRange => {
    return this.value
  }

  format = (): IFormatedDateRange => {
    const { startDate, endDate } = this.value
    return {
      startDate: startDate ? moment(startDate).format(this.FORMAT) : undefined,
      endDate: endDate ? moment(endDate).format(this.FORMAT) : undefined,
    }
  }

  getStartDate = (): DateVO | undefined => {
    return this.value.startDate ? new DateVO(this.value.startDate) : undefined
  }

  getEndDate = (): DateVO | undefined => {
    return this.value.endDate ? new DateVO(this.value.endDate) : undefined
  }

  isEmpty = () => {
    return !this.value.startDate && !this.value.endDate
  }
  serialize = () => {
    const { startDate, endDate } = this.value
    return {
      startDate: startDate
        ? moment(startDate).format(this.API_FORMAT)
        : undefined,
      endDate: endDate ? moment(endDate).format(this.API_FORMAT) : undefined,
    }
  }

  isEqual(target: VOBase): boolean {
    const dates = this.format()
    const targetDates = target.format()
    if (
      dates.startDate === targetDates.startDate &&
      dates.endDate === targetDates.endDate
    ) {
      return true
    }

    return false
  }
}
