import _ from 'lodash'
import { GetContextMenuItemsParams, GridApi, RowNode } from 'ag-grid-community'
import { intl } from '../../../../i18n'
import DateVO from '../../../../vo/DateVO'
import {
  ProfitLossMemberRow,
  ProfitLossMemberRowMonthlyValueBody,
  ProfitLossMemberRowType,
  ProfitLossMemberRowTypeType,
} from '../ProfitLossMembersNew'
import {
  generateYearMonthField,
  updateForecastValue,
  updateRootRowMonthAmountValue,
} from '.'
import { DateTerm } from '../../../../utils/date'
import {
  distinctRowNodes,
  getSummaryRowNode,
} from '../../ProfitLossItems/gridOptions'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'

// 以下ContextMenuからは外すが、処理自体は残しておくよう要望があった為、
// 未使用のものも物理的に消さずにコメントアウトして残しております。

// export const reloadScheduledWorkMonth = (
//   params: GetContextMenuItemsParams,
//   accountCategory: string | undefined,
//   action: (targetRow: ProfitLossMemberRow) => void
// ) => {
//   return {
//     name: intl.formatMessage({
//       id: 'profitLossMembers.contextMenu.fetch.scheduled.workMonth',
//     }),
//     disabled:
//       !params.node?.data ||
//       accountCategory === AccountCategory.OperatingRevenue ||
//       !!params.node?.data?.isTotal,
//     action: () => {
//       action(params.node?.data)
//     },
//   }
// }

// export const recalcBudgetAmount = (
//   params: GetContextMenuItemsParams,
//   rows: ProfitLossMemberRow[],
//   dateTerm: DateTerm,
//   accountCategory: string | undefined,
//   setData: (data: ProfitLossMemberRow[]) => void
// ) => {
//   return {
//     name: intl.formatMessage({
//       id: 'profitLossMembers.contextMenu.recalc.budget.amount',
//     }),
//     disabled:
//       !params.node?.data ||
//       accountCategory === AccountCategory.OperatingRevenue ||
//       !!params.node?.data?.isTotal,
//     action: () => {
//       const row: ProfitLossMemberRow = params.node?.data
//       if (row.body?.type === ProfitLossMemberRowType.WorkMonthForecast) {
//         const rootNode = params.node
//         const result = recalculateRow(rows, rootNode, dateTerm)
//         if (result.modified) {
//           setData(result.rows)
//           params.api.redrawRows()
//         }
//       } else {
//         params.api.forEachNode(node => {
//           if (
//             node.data.uuid === row.parentUuid &&
//             node.data.body?.type === ProfitLossMemberRowType.WorkMonthForecast
//           ) {
//             const result = recalculateRow(rows, node, dateTerm)
//             if (result.modified) {
//               setData(result.rows)
//               params.api.redrawRows()
//             }
//           }
//         })
//       }
//     },
//   }
// }

// export const recalcAllBudgetAmounts = (
//   params: GetContextMenuItemsParams,
//   rows: ProfitLossMemberRow[],
//   dateTerm: DateTerm,
//   accountCategory: string | undefined,
//   setData: (data: ProfitLossMemberRow[]) => void
// ) => {
//   return {
//     name: intl.formatMessage({
//       id: 'profitLossMembers.contextMenu.recalc.budget.amount.all',
//     }),
//     disabled:
//       !rows ||
//       rows.length === 0 ||
//       accountCategory === AccountCategory.OperatingRevenue,
//     action: () => {
//       let result: RecalculateResult = {
//         modified: false,
//         rows: _.cloneDeep(rows),
//       }
//       params.api.forEachNode(node => {
//         if (node.level !== 0) return
//         if (
//           node.data.body?.type !== ProfitLossMemberRowType.WorkMonthForecast
//         ) {
//           return
//         }
//         const tempResult = recalculateRow(result.rows, node, dateTerm)
//         if (tempResult.modified) {
//           result.modified = true
//           result.rows = tempResult.rows
//         }
//       })
//       if (result.modified) {
//         setData(result.rows)
//         params.api.redrawRows()
//       }
//     },
//   }
// }

export const recalculateAllBudgetAmounts = (
  api: GridApi | undefined | null,
  rows: ProfitLossMemberRow[],
  dateTerm: DateTerm
): ProfitLossMemberRow[] => {
  let result: RecalculateResult = {
    modified: false,
    rows: _.cloneDeep(rows),
  }
  api?.forEachNode(node => {
    if (node.level !== 0) return
    if (node.data.body?.type !== ProfitLossMemberRowType.WorkMonthForecast) {
      return
    }
    const temp = recalculateRow(result.rows, node, dateTerm)
    if (temp.modified) {
      result.modified = true
      temp.rows.forEach(row => {
        const index = result.rows.findIndex(v => v.uuid === row.uuid)
        result.rows[index] = row
      })
    }
  })
  return result.modified ? result.rows : []
}

export interface RecalculateResult {
  modified: boolean
  rows: ProfitLossMemberRow[]
}

const recalculateRow = (
  rows: ProfitLossMemberRow[],
  rootNode: RowNode | null | undefined,
  dateTerm: DateTerm
): RecalculateResult => {
  const scheduledWorkMonthRow: ProfitLossMemberRow | undefined = rows.find(
    c =>
      c.body.type === ProfitLossMemberRowType.WorkMonthSchedule &&
      c.parentUuid === rootNode?.data.uuid
  )

  const rootRow: ProfitLossMemberRow = rootNode?.data

  const newRows: ProfitLossMemberRow[] = _.cloneDeep(rows)
  const budgetAmountRow: ProfitLossMemberRow | undefined = newRows.find(
    r =>
      r.body.type === ProfitLossMemberRowType.AmountBudget &&
      r.parentUuid === rootRow.uuid
  )
  if (!budgetAmountRow) {
    return {
      modified: false,
      rows: [],
    }
  }
  const newRootRow = newRows.find(
    r =>
      r.parentUuid === rootRow.parentUuid &&
      r.body?.type === ProfitLossMemberRowType.AmountForecast
  )

  const firstDayOfMonth = new DateVO(dateTerm.startDate).getFirstDayOfMonth()
  let modified: boolean = false
  scheduledWorkMonthRow?.body.monthlyValues.forEach(scheduledWorkMonth => {
    const currentYearMonth = new DateVO(scheduledWorkMonth.yearMonth)
    if (!currentYearMonth.isSameOrAfter(firstDayOfMonth)) {
      return
    }

    if (!scheduledWorkMonth.value) return

    const unitPrice = rootRow.unitPrices?.find(p =>
      new DateVO(p.month).isSame(currentYearMonth)
    )
    if (!unitPrice) return

    const budgetAmountMonthlyValue = budgetAmountRow.body.monthlyValues.find(
      m => scheduledWorkMonth.yearMonth === m.yearMonth
    )

    const oldValue = budgetAmountMonthlyValue || undefined
    const newValue = new ProfitLossMemberRowMonthlyValueBody(
      scheduledWorkMonth.yearMonth,
      unitPrice.unitPrice * scheduledWorkMonth.value,
      false
    )
    if (
      oldValue?.value === newValue.value &&
      oldValue?.manualEdited === newValue.manualEdited
    ) {
      return
    }
    if (budgetAmountMonthlyValue) {
      budgetAmountMonthlyValue.value = newValue.value
      budgetAmountMonthlyValue.manualEdited = false
    } else {
      budgetAmountRow.body.monthlyValues.push(newValue)
    }
    modified = true
    budgetAmountRow.edited = !budgetAmountRow.added

    const field = generateYearMonthField(currentYearMonth)
    if (!budgetAmountRow.editedData) {
      budgetAmountRow.editedData = { [field]: oldValue }
    } else if (!budgetAmountRow.editedData.hasOwnProperty(field)) {
      budgetAmountRow.editedData[field] = oldValue
    }

    if (newRootRow) {
      updateRootRowMonthAmountValue(
        budgetAmountRow,
        newRootRow,
        new DateVO(newValue.yearMonth),
        newValue.value
      )
    }
  })
  return {
    modified,
    rows: !!newRootRow ? [budgetAmountRow, newRootRow] : [budgetAmountRow],
  }
}

const setScheduleToActual = (
  api: GridApi,
  rootNode: RowNode<ProfitLossMemberRow>,
  scheduleRowType: ProfitLossMemberRowTypeType,
  actualRowType: ProfitLossMemberRowTypeType,
  thisMonth: DateVO
): RowNode<ProfitLossMemberRow>[] | undefined => {
  let scheduledNode: RowNode<ProfitLossMemberRow> | undefined
  let actualNode: RowNode<ProfitLossMemberRow> | undefined
  api.forEachNode(node => {
    if (node.data.parentUuid === rootNode.data?.parentUuid) {
      if (node.data.body?.type === scheduleRowType) {
        scheduledNode = node
      } else if (node.data.body?.type === actualRowType) {
        actualNode = node
      }
    }
  })
  const actualRow = actualNode?.data
  if (!scheduledNode?.data || !actualNode || !actualRow) return

  let edited = false
  scheduledNode?.data.body.monthlyValues.forEach(scheduledMonthlyValue => {
    const targetMonth = new DateVO(scheduledMonthlyValue.yearMonth)
    if (
      !scheduledMonthlyValue?.value ||
      thisMonth.diffMonth(targetMonth) <= 0
    ) {
      return
    }
    const actualMonthlyValue = actualRow?.body.monthlyValues.find(
      v => v.yearMonth === scheduledMonthlyValue.yearMonth
    )
    if (actualMonthlyValue?.value) return

    const oldValue: ProfitLossMemberRowMonthlyValueBody | undefined =
      actualMonthlyValue
    const newValue: ProfitLossMemberRowMonthlyValueBody =
      new ProfitLossMemberRowMonthlyValueBody(
        scheduledMonthlyValue.yearMonth,
        scheduledMonthlyValue.value,
        false
      )
    if (!actualMonthlyValue) {
      actualRow?.body.monthlyValues.push(newValue)
    } else {
      actualMonthlyValue.value = newValue.value
      actualMonthlyValue.manualEdited = false
    }
    actualRow.edited = !actualRow.added
    const field = generateYearMonthField(targetMonth)
    if (!actualRow.editedData) {
      actualRow.editedData = { [field]: oldValue }
    } else if (!actualRow.editedData.hasOwnProperty(field)) {
      actualRow.editedData[field] = oldValue
    }
    updateForecastValue(actualRow, rootNode.data!, targetMonth, newValue.value)
    edited = true
  })
  if (edited) {
    store.dispatch(requireSave())
  }
  return edited ? [rootNode, actualNode] : undefined
}

export const setAllScheduledMonthlyValueToActual = (
  params: GetContextMenuItemsParams<ProfitLossMemberRow>
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLossMembers.contextMenu.budget.to.result.all',
    }),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      const thisMonth = DateVO.now().getFirstDayOfMonth()
      const editedRowNodes: RowNode<ProfitLossMemberRow>[] = []
      params.api.forEachNode(node => {
        const row = node.data
        if (!row || row?.isTotal) return
        if (row.body.type === ProfitLossMemberRowType.WorkMonthForecast) {
          const editedRows = setScheduleToActual(
            params.api,
            node,
            ProfitLossMemberRowType.WorkMonthSchedule,
            ProfitLossMemberRowType.WorkMonthActual,
            thisMonth
          )
          if (editedRows) {
            editedRowNodes.push(...editedRows)
          }
        } else if (row.body.type === ProfitLossMemberRowType.AmountForecast) {
          const editedRows = setScheduleToActual(
            params.api,
            node,
            ProfitLossMemberRowType.AmountBudget,
            ProfitLossMemberRowType.AmountResult,
            thisMonth
          )
          if (editedRows) {
            editedRowNodes.push(...editedRows)
          }
        }
      })

      const refreshNodes = distinctRowNodes(editedRowNodes)
      if (!_.isEmpty(refreshNodes)) {
        refreshNodes.push(
          ...getSummaryRowNode(params.api, [
            ProfitLossMemberRowType.AmountForecast,
            ProfitLossMemberRowType.AmountResult,
          ])
        )
        params.api.refreshCells({
          rowNodes: refreshNodes,
          force: true,
        })
      }
    },
  }
}

export const setSingleScheduledMonthlyValueToActual = (
  params: GetContextMenuItemsParams<ProfitLossMemberRow>
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLossMembers.contextMenu.budget.to.result.single',
    }),
    disabled: !params.node?.data || !!params.node?.data?.isTotal,
    action: () => {
      const thisMonth = DateVO.now().getFirstDayOfMonth()
      const editedNodes: RowNode<ProfitLossMemberRow>[] = []
      const baseRow = params.node?.data
      if (!baseRow || baseRow.isTotal) return
      params.api.forEachNode(node => {
        const row = node.data
        if (!row || row.isTotal || row.parentUuid !== baseRow.parentUuid) return
        if (row.body.type === ProfitLossMemberRowType.WorkMonthForecast) {
          const editedRows = setScheduleToActual(
            params.api,
            node,
            ProfitLossMemberRowType.WorkMonthSchedule,
            ProfitLossMemberRowType.WorkMonthActual,
            thisMonth
          )
          if (editedRows) {
            editedNodes.push(...editedRows)
          }
        } else if (row.body.type === ProfitLossMemberRowType.AmountForecast) {
          const editedRows = setScheduleToActual(
            params.api,
            node,
            ProfitLossMemberRowType.AmountBudget,
            ProfitLossMemberRowType.AmountResult,
            thisMonth
          )
          if (editedRows) {
            editedNodes.push(...editedRows)
          }
        }
      })
      const refreshNodes = distinctRowNodes(editedNodes)
      if (!_.isEmpty(refreshNodes)) {
        refreshNodes.push(
          ...getSummaryRowNode(params.api, [
            ProfitLossMemberRowType.AmountForecast,
            ProfitLossMemberRowType.AmountResult,
          ])
        )
        params.api.refreshCells({
          rowNodes: refreshNodes,
          force: true,
        })
      }
    },
  }
}

// export const importSingleInvestedWorkMonth = (
//   params: GetContextMenuItemsParams<ProfitLossMemberRow>,
//   accountCategory: string | undefined,
//   rows: ProfitLossMemberRow[],
//   calendar: OperationCalendar[]
// ) => {
//   return {
//     name: intl.formatMessage({
//       id: 'profitLossMembers.contextMenu.aggregate.workMonth',
//     }),
//     disabled:
//       !params.node?.data ||
//       accountCategory === AccountCategory.OperatingRevenue ||
//       !!params.node?.data?.isTotal,
//     action: () => {
//       const uuid = params.node?.data?.parentUuid
//       if (!uuid) return
//       if (importInvestedWorkMonth(params, uuid, rows, calendar)) {
//         params.api.redrawRows()
//       }
//     },
//   }
// }

// export const importAllInvestedWorkMonth = (
//   params: GetContextMenuItemsParams,
//   accountCategory: string | undefined,
//   rows: ProfitLossMemberRow[],
//   calendar: OperationCalendar[]
// ) => {
//   return {
//     name: intl.formatMessage({
//       id: 'profitLossMembers.contextMenu.aggregate.workMonth.all',
//     }),
//     disabled:
//       accountCategory === AccountCategory.OperatingRevenue ||
//       params.api.getRenderedNodes().length <= 0,
//     action: () => {
//       let uuid: string
//       let result = false
//       params.api.forEachNode(node => {
//         if (uuid === node.data.parentUuid) return
//         uuid = node.data.parentUuid
//         const importResult = importInvestedWorkMonth(
//           params,
//           uuid,
//           rows,
//           calendar
//         )
//         if (importResult) result = true
//       })
//       if (result) {
//         params.api.redrawRows()
//       }
//     },
//   }
// }

// const importInvestedWorkMonth = (
//   params: GetContextMenuItemsParams<ProfitLossMemberRow>,
//   uuid: string,
//   rows: ProfitLossMemberRow[],
//   calendar: OperationCalendar[]
// ): boolean => {
//   const nowMonth = DateVO.now().getFirstDayOfMonth()
//   const baseRow = rows.find(
//     v =>
//       v.parentUuid === uuid &&
//       v.body.type === ProfitLossMemberRowType.WorkMonthForecast
//   )
//   let actualWorkNode: RowNode<ProfitLossMemberRow> | undefined
//   let resultAmountNode: RowNode<ProfitLossMemberRow> | undefined
//   params.api.forEachNode(node => {
//     if (
//       node.data?.parentUuid === uuid &&
//       node.data.body?.type === ProfitLossMemberRowType.WorkMonthActual
//     ) {
//       actualWorkNode = node
//     } else if (
//       node.data?.parentUuid === uuid &&
//       node.data.body?.type === ProfitLossMemberRowType.AmountResult
//     ) {
//       resultAmountNode = node
//     }
//   })
//   const actualWorkRow = actualWorkNode?.data
//   const resultAmountRow = resultAmountNode?.data
//   const standardHour = Auth.getCurrentTenant()?.organization?.dailyWorkHours
//   if (
//     !baseRow ||
//     !baseRow.investedWorkMonth ||
//     !standardHour ||
//     !actualWorkRow ||
//     !resultAmountRow
//   ) {
//     return false
//   }
//   const editedNodes: RowNode<ProfitLossMemberRow>[] = []
//   baseRow.investedWorkMonth.forEach(invest => {
//     const targetMonth = new DateVO(invest.month)
//     const targetCalendar = calendar.find(v => v.date === invest.month)
//     if (
//       !targetCalendar ||
//       !invest.manHour ||
//       nowMonth.isSameOrBefore(targetMonth)
//     ) {
//       return
//     }
//     const actualValue = actualWorkRow.body.monthlyValues.find(
//       v => v.yearMonth === invest.month
//     )
//     if (actualValue?.value) return

//     const oldValue: ProfitLossMemberRowMonthlyValueBody | undefined =
//       actualValue
//     const newValue: ProfitLossMemberRowMonthlyValueBody =
//       new ProfitLossMemberRowMonthlyValueBody(
//         invest.month,
//         invest.manHour / (targetCalendar.days * standardHour),
//         false
//       )
//     if (!actualValue) {
//       actualWorkRow?.body.monthlyValues.push(newValue)
//     } else {
//       actualValue.value = newValue.value
//       actualValue.manualEdited = false
//     }
//     actualWorkRow.edited = !actualWorkRow.added
//     const field = generateYearMonthField(targetMonth)
//     if (!actualWorkRow.editedData) {
//       actualWorkRow.editedData = { [field]: oldValue }
//     } else if (!actualWorkRow.editedData.hasOwnProperty(field)) {
//       actualWorkRow.editedData[field] = oldValue
//     }
//     editedNodes.push(actualWorkNode!)

//     const calcAmount = autoCalculateMonthlyValue(
//       actualWorkNode!,
//       targetMonth,
//       ProfitLossMemberRowType.AmountResult,
//       field,
//       newValue.value ?? 0,
//       (srcValue: number, unitPrice: number) => srcValue * unitPrice
//     )
//     if (calcAmount) {
//       editedNodes.push(...calcAmount)
//     }
//     store.dispatch(requireSave())
//   })
//   return editedNodes.length !== 0
// }
