import { Dialog, DialogTitle, styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    padding: '48px 32px',
  },
})
export const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  gap: '16px',
})

export const StyledDialogContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  gap: '32px',
})

export const StyledDialogActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px 0 16px',
  gap: '16px',
})

export const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: colorPalette.monotone[10],
  fontSize: '16px',
  fontWeight: '600',
})

export const HeaderText = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: 1.5,
  letterSpacing: '0em',
  textAlign: 'left',
  color: colorPalette.monotone[9],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
