import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WbsItemSearchConditionVO } from '../../../../domain/value-object/WbsItemSearchConditionVo'
import { useSearchConditionAndColumnStateStorageService } from '../../../../services/storage-services/ui-states/searchConditionAndColumnStateStorageService'
import { useWbsItemSearchConditionUrlTransformService } from '../../../../services/transform-service/wbsItemSearchConditionUrlTransformService'
import { getUrlQueryObject } from '../../../../utils/urls'
import { WbsItemSearchSearchCondition } from './useSearchCondition'

const wbsItemSearchDefaultSearchCondition = {
  searchText: '',
  types: [WbsItemType.TASK],
  status: [
    WbsItemStatus.TODO,
    WbsItemStatus.DOING,
    WbsItemStatus.REVIEW,
    WbsItemStatus.DONE,
  ],
}

export const useRestoredSearchCondition = (
  functionUuid: string,
  projectUuid: string,
  fetchWbsItemSearchConditionVO: (
    functionUuid: string,
    projectUuid: string
  ) => Promise<WbsItemSearchConditionVO | undefined>
) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [restoredSearchCondition, setRestoredSearchCondition] =
    useState<WbsItemSearchSearchCondition>(wbsItemSearchDefaultSearchCondition)

  const searchConditionStorageService =
    useSearchConditionAndColumnStateStorageService(functionUuid, projectUuid)
  const wbsItemSearchConditionUrlTransformer =
    useWbsItemSearchConditionUrlTransformService()

  const restorePermanentUiState = useCallback(async () => {
    const urlObject = getUrlQueryObject()
    if (!urlObject) return undefined
    const code = urlObject.arg
    if (!code) return undefined
    const permanentUiState = await searchConditionStorageService.getByCode(code)
    if (!permanentUiState) return undefined
    return permanentUiState.value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchConditionStorageService.getByCode])

  const restoreFromURLQuery = useCallback(() => {
    const conditionFromUrl =
      wbsItemSearchConditionUrlTransformer.restoreFromUrl()
    if (!conditionFromUrl || Object.keys(conditionFromUrl).length === 0) {
      return undefined
    }
    return conditionFromUrl
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const restoreFromUiState = useCallback(async () => {
    const storedSearchCondition = await fetchWbsItemSearchConditionVO(
      functionUuid,
      projectUuid
    )
    if (
      !storedSearchCondition ||
      Object.keys(storedSearchCondition).length === 0
    ) {
      return undefined
    }
    return storedSearchCondition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionUuid, projectUuid])

  useEffect(() => {
    const restore = async () => {
      const permanentUiState = await restorePermanentUiState()
      if (permanentUiState) {
        setRestoredSearchCondition(permanentUiState.searchCondition)
        setInitialized(true)
        return
      }
      const searchConditionInURLQuery = restoreFromURLQuery()
      if (searchConditionInURLQuery) {
        setRestoredSearchCondition(searchConditionInURLQuery)
        setInitialized(true)
        return
      }
      const storedSearchCondition = await restoreFromUiState()
      if (storedSearchCondition) {
        setRestoredSearchCondition(storedSearchCondition)
        setInitialized(true)
        return
      }
      setInitialized(true)
    }
    restore()
  }, [restorePermanentUiState, restoreFromURLQuery, restoreFromUiState])

  const result = useMemo(
    () => ({
      initialized,
      restoredSearchCondition,
    }),
    [initialized, restoredSearchCondition]
  )
  return result
}
