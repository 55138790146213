import API, { APIResponse } from '../lib/commons/api'

class TenantStorage {
  uploadFile(file): Promise<APIResponse> {
    let formData = new FormData()
    formData.append('file', file)
    return API.functional.request(
      'POST',
      '/api/v1/storages/tenants',
      formData,
      true,
      null,
      {},
      () => {
        // Do nothing.
        // This is necessary not to handle error in globalErrorHandler
      }
    )
  }

  deleteFileByUrl(url: string): Promise<APIResponse> {
    // Extract key from url
    const parts = url.split('/')
    // Development env = 'tenants', other env = 'tenant'
    const i = parts.findIndex(v => v === 'tenants' || v === 'tenant')
    if (i < 0 || parts.length < i + 2) {
      throw new Error('Can not delete file not managed flagxs.')
    }
    const tenantUuid = parts[i + 1]
    const fileUuid = parts[i + 2]
    return this.deleteFileByUuid(tenantUuid, fileUuid)
  }

  deleteFileByUuid(tenantUuid: string, fileUuid: string): Promise<APIResponse> {
    return API.functional.request('DELETE', '/api/v1/storages/tenants', {
      tenantUuid,
      fileUuid,
    })
  }
}

export default new TenantStorage()
