import Checkbox from '../../components/editors/checkbox/Checkbox'
import {
  AutoCompleteCell,
  AutocompleteOption,
  CheckBoxCell,
  DateCell,
  EntitySearchCell,
  FilterInputCell,
  RowHeaderCell,
  SelectCell,
  SingleCheckBoxCell,
  StyledTableRow,
} from '../DataCell'
import DateVO from '../../../vo/DateVO'
import React from 'react'

export const TextCondition = (props: {
  id: string
  value: any
  header: string
  onChange: (entry: any) => void
  onEnter?: () => void
}) => {
  const { id, value, header, onChange, onEnter } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <FilterInputCell
        colSpan={4}
        value={value}
        type={'text'}
        onChange={value => onChange({ [id]: value })}
        enterHandler={onEnter}
        clearable={true}
      />
    </StyledTableRow>
  )
}

export const SelectCondition = (props: {
  id: string
  value: any
  options: any
  onChange: (entry: any) => void
  header: string
  disabled?: boolean
  MenuProps?: any
  clearable?: boolean
}) => {
  const {
    id,
    value,
    options,
    onChange,
    header,
    disabled,
    MenuProps,
    clearable,
  } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <SelectCell
        colSpan={2}
        value={value}
        options={options}
        onChange={value => onChange({ [id]: value })}
        disabled={disabled}
        MenuProps={MenuProps}
        clearable={clearable}
      />
    </StyledTableRow>
  )
}

export const DoubleSelectCondition = (props: {
  header: string
  idLeft: string
  valueLeft: any
  optionsLeft: any
  disabledLeft?: boolean
  idRight: string
  valueRight: any
  optionsRight: any
  disabledRight?: boolean
  onChange: (entry: any) => void
}) => {
  const {
    header,
    idLeft,
    valueLeft,
    optionsLeft,
    disabledLeft,
    idRight,
    valueRight,
    optionsRight,
    disabledRight,
    onChange,
  } = props
  return (
    <StyledTableRow key={`${idLeft}-${idRight}`}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <SelectCell
        colSpan={1}
        value={valueLeft}
        options={optionsLeft}
        onChange={value => onChange({ [idLeft]: value })}
        disabled={disabledLeft}
      />
      <SelectCell
        colSpan={1}
        value={valueRight}
        options={optionsRight}
        onChange={value => onChange({ [idRight]: value })}
        disabled={disabledRight}
      />
    </StyledTableRow>
  )
}

export const MultipleSelectCondition = (props: {
  id: string
  value: any
  options: any
  onChange: (entry: any) => void
  header: string
  getValue?: (option: any) => any
  getLabel?: (option: any) => string
  disabled?: boolean
}) => {
  const { id, value, options, onChange, header, getLabel, disabled } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <CheckBoxCell
        colSpan={2}
        value={value || []}
        options={options}
        onChange={value => onChange({ [id]: value })}
        readOnly={disabled}
      />
    </StyledTableRow>
  )
}

export const SingleCheckCondition = (props: {
  id: string
  value: boolean
  onChange: (entry: any) => void
  header: string
}) => {
  const { id, value, onChange, header } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <SingleCheckBoxCell
        colSpan={2}
        value={value}
        onChange={value => onChange({ [id]: value })}
        readOnly={false}
      />
    </StyledTableRow>
  )
}

export const AutocompleteCondition = (props: {
  id: string
  value: any
  options: any[]
  referenceEntity: string
  onChange: (entry: any) => void
  header: string
  onEnter?: () => void
}) => {
  const { id, value, options, referenceEntity, onChange, onEnter, header } =
    props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <AutoCompleteCell
        colSpan={2}
        value={value}
        options={options}
        onChange={value => onChange({ [id]: value })}
        enterHandler={onEnter}
        referenceEntity={referenceEntity}
      />
    </StyledTableRow>
  )
}

export const getAutocompleteConditionWithCheckbox = (props: {
  id: string
  value: any
  options: any[]
  referenceEntity: string
  onChange: (entry: any) => void
  onEnter: () => void
  disabled: Function
  header: string
  blankId: string
  blankValue: boolean
  fixedSearchCondition?: any
  orderFunction?: (options: any[]) => any[]
  placeHolder?: Function
}) => {
  const {
    id,
    value,
    options,
    referenceEntity,
    onChange,
    onEnter,
    disabled,
    header,
    blankId,
    blankValue,
    fixedSearchCondition,
    orderFunction,
    placeHolder,
  } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">
        {header}
        <Checkbox
          checked={blankValue}
          onChange={(_, checked) =>
            onChange({
              [id]: undefined,
              [blankId]: checked,
            })
          }
        />
      </RowHeaderCell>
      <AutoCompleteCell
        colSpan={2}
        value={value}
        options={options}
        referenceEntity={referenceEntity}
        disabled={disabled()}
        fixedSearchCondition={fixedSearchCondition}
        orderFunction={orderFunction}
        onChange={value => onChange({ [id]: value })}
        enterHandler={onEnter}
        placeholder={placeHolder ? placeHolder() : ''}
      />
    </StyledTableRow>
  )
}

export const EntitySearchCondition = (props: {
  id: string
  value: any
  referenceEntity: string
  onChange: (entry: any) => void
  onEnter?: () => void
  header: string
  autoHighlight?: boolean
  onInputChangeHandler?: (
    value: string | AutocompleteOption | undefined
  ) => void
  fixedSearchCondition?: any
}) => {
  const {
    id,
    value,
    referenceEntity,
    onChange,
    onEnter,
    header,
    autoHighlight,
    onInputChangeHandler,
    fixedSearchCondition,
  } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <EntitySearchCell
        colSpan={2}
        value={value}
        onChange={value => onChange({ [id]: value })}
        onInputChange={onInputChangeHandler}
        enterHandler={onEnter}
        referenceEntity={referenceEntity}
        fixedSearchCondition={fixedSearchCondition}
        autoHighlight={autoHighlight}
      />
    </StyledTableRow>
  )
}

export const DateRangeCondition = (props: {
  id: string
  onChange: (entry: any) => void
  header: string
  placeholder?: string
  rangeValueFrom?: DateVO
  rangeValueTo?: DateVO
  onEnter?: () => void
}) => {
  const {
    id,
    onChange,
    rangeValueFrom,
    rangeValueTo,
    header,
    placeholder,
    onEnter,
  } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">{header}</RowHeaderCell>
      <DateCell
        value={rangeValueFrom}
        onChange={value =>
          onChange({
            [getRangeKeyFrom(id)]: value,
          })
        }
        enterHandler={onEnter}
        isClearable={true}
        placeholderText={placeholder}
      />
      <DateCell
        value={rangeValueTo}
        onChange={value => onChange({ [getRangeKeyTo(id)]: value })}
        enterHandler={onEnter}
        isClearable={true}
        placeholderText={placeholder}
      />
    </StyledTableRow>
  )
}

export const DateRangeConditionWithCheckbox = (props: {
  id: string
  onChange: (entry: any) => void
  onEnter: () => void
  blankValue: boolean
  rangeValueFrom: DateVO
  rangeValueTo: DateVO
  header: string
  placeholder: string
}) => {
  const {
    id,
    onChange,
    onEnter,
    blankValue,
    rangeValueFrom,
    rangeValueTo,
    header,
    placeholder,
  } = props
  return (
    <StyledTableRow key={id}>
      <RowHeaderCell align="left">
        {header}
        <Checkbox
          checked={blankValue}
          onChange={(_, checked) =>
            onChange({
              [getBlankKey(id)]: checked,
              // when user click blank, date value is cleared
              [getRangeKeyFrom(id)]: undefined,
              [getRangeKeyTo(id)]: undefined,
            })
          }
        />
      </RowHeaderCell>
      <DateCell
        value={rangeValueFrom}
        onChange={value =>
          onChange({
            [getRangeKeyFrom(id)]: value,
          })
        }
        enterHandler={onEnter}
        isClearable={true}
        disabled={blankValue}
        placeholderText={blankValue ? placeholder : undefined}
      />
      <DateCell
        value={rangeValueTo}
        onChange={value => onChange({ [getRangeKeyTo(id)]: value })}
        enterHandler={onEnter}
        isClearable={true}
        disabled={blankValue}
        placeholderText={blankValue ? placeholder : undefined}
      />
    </StyledTableRow>
  )
}

const getRangeKeyFrom = (id: string) => `${id}.from`
const getRangeKeyTo = (id: string) => `${id}.to`
const getBlankKey = (id: string) => `blank.${id}`
