import _ from 'lodash'
import {
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../../../../../domain/value-object/GanttParameterVO'
import { mapDayOfWeekToNumber } from '../../../../../../../../lib/functions/report'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendarProps,
} from '../../../../../../../../lib/functions/organizationWorkingDayCalendar'
import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import DateVO from '../../../../../../../../vo/DateVO'

export const generateGanttScale = async (ganttParameter: GanttParameterVO) => {
  const [startDate, endDate] = fixRange(ganttParameter)
  const timeScale = await getCalendar(startDate, endDate)
  const displayTimeScale = generateDisplayScale(ganttParameter, timeScale)
  return [displayTimeScale, timeScale]
}

const fixRange = (ganttParameter: GanttParameterVO): [Date, Date] => {
  const { unit, startDay, range } = ganttParameter
  const start = range.start.toDate()
  const end = range.end.toDate()
  if (unit === GanttDisplayUnit.DAY) return [start, end]
  if (unit === GanttDisplayUnit.WEEK) {
    // Fix to week date range
    // Previous date of start day of week
    const fixedStartDiff =
      (start.getDay() - mapDayOfWeekToNumber(startDay) + 7) % 7
    const fixedStart = new Date(start)
    fixedStart.setDate(fixedStart.getDate() - fixedStartDiff)
    // Next date of the day before start day of week
    const fixedEndDiff = (mapDayOfWeekToNumber(startDay) - end.getDay() + 6) % 7
    const fixedEnd = new Date(end)
    fixedEnd.setDate(fixedEnd.getDate() + fixedEndDiff)
    return [fixedStart, fixedEnd]
  }
  if (unit === GanttDisplayUnit.MONTH) {
    return [
      new DateVO(start).getFirstDayOfMonth().toDate(),
      new DateVO(end).getLastDayOfMonth().toDate(),
    ]
  }
  return [start, end]
}

const getCalendar = async (
  start: Date,
  end: Date
): Promise<CalendarDateVO[]> => {
  const calendarResponse = await getOrganizationWorkingDayCalendar({
    startDate: start,
    endDate: end,
  })
  const result = calendarResponse.json as OrganizationWorkingDayCalendarProps[]
  const map = {}
  result.forEach(v => {
    const c = new CalendarDateVO({
      date: v.date,
      legal: v.legal,
      name: v.name,
      workHour: v.workHour,
    })
    map[c.date.getTime()] = c
  })
  const calendar: CalendarDateVO[] = []
  let date = CalendarDateVO.of(start)
  const endDate = CalendarDateVO.of(end)
  while (date.isBefore(endDate) || date.isEqual(endDate)) {
    const cal = map[date.date.getTime()]
    calendar.push(cal ?? date)
    const next = new Date(date.date)
    next.setDate(next.getDate() + 1)
    date = CalendarDateVO.of(next)
  }
  return calendar
}

const generateDisplayScale = (
  ganttParameter: GanttParameterVO,
  timeScale: CalendarDateVO[]
): CalendarDateVO[] => {
  if (ganttParameter.unit === GanttDisplayUnit.DAY) {
    return _.cloneDeep(timeScale)
  }
  if (ganttParameter.unit === GanttDisplayUnit.WEEK) {
    const scale: CalendarDateVO[] = []
    let date = timeScale[0]
    let i = 0
    while (!!date) {
      scale.push(timeScale[i])
      i += 7
      date = timeScale[i]
    }
    return scale
  }
  if (ganttParameter.unit === GanttDisplayUnit.MONTH) {
    const scale: CalendarDateVO[] = []
    const end = timeScale[timeScale.length - 1]
    let date = timeScale[0]
    while (date.isBefore(end)) {
      scale.push(date)
      date = CalendarDateVO.of(new DateVO(date.date).addMonths(1).toDate())
    }
    return scale
  }
  return []
}
