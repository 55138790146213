import { useCallback } from 'react'
import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { useDialogState } from '../../../components/dialogs/useDialogState'
import { getUserName, projectCellValueFormatter } from '../gridOptions'

export const useExcel = (gridOptions: GridOptions) => {
  const [dialogOpen, openDialog, closeDialog] = useDialogState()

  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined
      const colId: string = params.column.getColId()
      if (colId === 'body.project') {
        return projectCellValueFormatter(params.value)
      }
      if (['body.user', 'body.project.projectManager'].includes(colId)) {
        return getUserName(params.value)
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(
    (colIds: string[]) => {
      if (!colIds || colIds.length === 0) return
      exportExcel({
        fileNamePrefix: 'resource_plan_cross_projects',
        gridOptions,
        exportColIds: colIds,
        getCustomExportValue: customExportValues,
        shouldRowBeSkipped: params =>
          !params.node.data.body || !params.node.displayed,
      })
    },
    [gridOptions, customExportValues]
  )

  return {
    dialogOpen,
    openDialog,
    closeDialog,
    onExportExcel,
  }
}
