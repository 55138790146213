import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { FontSize, FontWeight } from '../../../styles/commonStyles'
import { Box, Typography } from '@mui/material'
import {
  getWbsItemStatusDeepColorCode,
  getWbsItemStatusLabel,
} from '../../../lib/functions/wbsItem'
import {
  StatusChangePopper,
  WbsItemForStatusChange,
} from '../poppers/StatusChangePopper'
import { intl } from '../../../i18n'
import Tooltip from '../tooltips/Tooltip'

interface PopperProps {
  projectUuid?: string
  wbsItem: WbsItemForStatusChange
  onAfterUpdate?: () => void
}

interface Props {
  status: WbsItemStatus
  statusChangePopperProps?: PopperProps
  readonly?: boolean
}

const RootBox = styled(Box)({
  borderRadius: '2px',
  display: 'inline-block',
  boxShadow: '0 1px 3px 0.5px #00000033',
})

const LabelText = styled(Typography)({
  fontSize: `${FontSize.SMALL}px`,
  fontWeight: FontWeight.BOLD,
  margin: '1px 8px',
})

const WbsItemStatusLabel = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)
  const [status, setStatus] = useState<WbsItemStatus>(props.status)
  const [statusChangePopperProps, setStatusChangePopperProps] =
    useState<PopperProps>()
  const [hovered, setHovered] = useState<boolean>(false)

  useEffect(() => {
    setStatus(props.status)
    setStatusChangePopperProps(props.statusChangePopperProps)
  }, [props.status])

  useEffect(() => {
    if (statusChangePopperProps !== props.statusChangePopperProps) {
      setStatus(props.status)
      setStatusChangePopperProps(props.statusChangePopperProps)
    }
  }, [props.status, props.statusChangePopperProps])

  const onClick = useCallback(
    e => {
      if (!!props.readonly || !statusChangePopperProps) return
      setAnchorEl(e.target)
    },
    [statusChangePopperProps, props.readonly]
  )

  const onClose = useCallback(() => {
    setAnchorEl(undefined)
  }, [])

  const onAfterUpdate = useCallback(
    wbsItem => {
      if (props.statusChangePopperProps) return
      setStatus(wbsItem.status)
      setStatusChangePopperProps({
        ...statusChangePopperProps,
        wbsItem,
      })
      if (statusChangePopperProps?.onAfterUpdate) {
        statusChangePopperProps.onAfterUpdate()
      }
    },
    [props]
  )
  return (
    <>
      <Tooltip
        message={intl.formatMessage({ id: 'singleSheet.header.status' })}
      >
        <RootBox
          style={{
            backgroundColor: getWbsItemStatusDeepColorCode(status),
            cursor:
              !props.readonly && statusChangePopperProps
                ? 'pointer'
                : 'default',
            filter: !props.readonly && hovered ? 'brightness(95%)' : 'none',
          }}
          onClick={onClick}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <LabelText>{getWbsItemStatusLabel(status)}</LabelText>
        </RootBox>
      </Tooltip>
      {statusChangePopperProps && (
        <StatusChangePopper
          anchorEl={anchorEl}
          projectUuid={statusChangePopperProps.projectUuid}
          wbsItem={statusChangePopperProps.wbsItem}
          onAfterUpdate={onAfterUpdate}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default WbsItemStatusLabel
