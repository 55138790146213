import { Box, Table, TableBody, TableHead, TableRow } from '@mui/material'
import {
  ProgressReportTooltip,
  ProgressReportTooltipBody,
  ProgressReportTooltipHeader,
  TooltipTableBodyCell,
  TooltipTableHeadCell,
  TooltipTableLink,
} from '.'
import { intl } from '../../../../../i18n'
import {
  getTimeGrainLabel,
  getTimeLabel,
  TimeGrain,
} from '../../../../../lib/functions/report'
import {
  AggregateColumn,
  getAggregateColumnLabel,
  WbsItemTypeForWbsProgressLog,
} from '../../../../../lib/functions/wbsProgressLog'
import { BackgroundColor, FontWeight } from '../../../../../styles/commonStyles'
import { ValueFormatter } from '../../../../components/charts/Chart/TwoDimChart'
import { excludeWbsItemCodes } from '../../utils'
import { AccumulateDirection } from '../../'

interface OwnProps {
  dimTime: number
  currValue: number
  prevValue?: number
  currWbsItemCodes?: string[]
  prevWbsItemCodes?: string[]
  currCycleWbsItemCodes?: string[]
  formatter: ValueFormatter
  type: WbsItemTypeForWbsProgressLog
  aggregateColumn: AggregateColumn
  timeGrain: TimeGrain
  currValueLinkDisabled?: boolean
  compareToPrevValueLinkDisabled?: boolean
  direction?: AccumulateDirection
}

type Props = OwnProps

const CurrAndPrevTooltip = ({
  dimTime,
  timeGrain,
  currValue,
  prevValue,
  currWbsItemCodes,
  prevWbsItemCodes,
  currCycleWbsItemCodes,
  formatter,
  type,
  aggregateColumn,
  currValueLinkDisabled,
  compareToPrevValueLinkDisabled,
  direction,
}: Props) => {
  let compareToPrevValue = excludeWbsItemCodes(
    !direction || direction === AccumulateDirection.BURN_UP
      ? currWbsItemCodes
      : prevWbsItemCodes,
    !direction || direction === AccumulateDirection.BURN_UP
      ? prevWbsItemCodes
      : currWbsItemCodes
  )
  if (currCycleWbsItemCodes) {
    compareToPrevValue = compareToPrevValue
      ? Array.from(new Set([...compareToPrevValue, ...currCycleWbsItemCodes]))
      : currCycleWbsItemCodes
  }

  return (
    <ProgressReportTooltip>
      <ProgressReportTooltipHeader>
        <span>{`■ ${type.name}-${getAggregateColumnLabel(
          aggregateColumn
        )}`}</span>
        <span>{getTimeLabel(dimTime, timeGrain)}</span>
      </ProgressReportTooltipHeader>
      <ProgressReportTooltipBody>
        <Table sx={{ width: 150 }}>
          <TableHead sx={{ backgroundColor: BackgroundColor.GREY }}>
            <TableRow>
              <TooltipTableHeadCell sx={{ width: 150 }}>
                {`${getAggregateColumnLabel(
                  aggregateColumn
                )} (${intl.formatMessage(
                  { id: 'progressReport.compareToPrev' },
                  { timeGrain: getTimeGrainLabel(timeGrain) }
                )})`}
              </TooltipTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TooltipTableBodyCell>
                <Box
                  component="span"
                  sx={{ fontWeight: FontWeight.BOLD, fontSize: 24 }}
                >
                  <TooltipTableLink
                    value={currValue}
                    wbsItemCodes={
                      !currValueLinkDisabled ? currWbsItemCodes : undefined
                    }
                    formatter={formatter}
                  />
                </Box>
                <Box component="span" sx={{ fontWeight: FontWeight.BOLD }}>
                  {`(`}
                  <TooltipTableLink
                    value={currValue - (prevValue || 0)}
                    wbsItemCodes={
                      !compareToPrevValueLinkDisabled
                        ? compareToPrevValue
                        : undefined
                    }
                    formatter={formatter}
                  />
                  {`)`}
                </Box>
              </TooltipTableBodyCell>
            </TableRow>
          </TableBody>
        </Table>
      </ProgressReportTooltipBody>
    </ProgressReportTooltip>
  )
}

export default CurrAndPrevTooltip
