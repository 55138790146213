import React from 'react'
import { Dialog } from '@mui/material'
import { styled } from '@mui/system'
import { Column, GridApi } from 'ag-grid-community'
import './style.scss'
import { Props as RichTextFieldProps } from '../../common/richText'
import { WrappedComponentProps } from 'react-intl'
import MultilineTextEditor from '../../../../../../../components/editors/multiline-text/MultilineTextEditor'
import CancelButton from '../../../../../../../components/buttons/CancelButton'
import { normalize } from '../../../../../../../../utils/multilineText'
import SaveButton from '../../../../../../../components/buttons/SaveButton'

// Styles
const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    maxWidth: '100% !important',
    height: '100%',
  },
})
const RichTextFieldWrapper = styled('div')({
  height: 'calc(100% - 54px)',
  width: 640,
  margin: 10,
  border: '2px solid #dddddd',
  borderRadius: 4,
})
const ButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 10,
})

interface OwnProps {
  parseValue?: (value: any) => any
  api: GridApi
  keyPress: number
  rowIndex: number
  column: Column
  field: string
}

interface State {
  value: string
  selection?: number
  isOpen: boolean
}

type Props = WrappedComponentProps & OwnProps & RichTextFieldProps

class cellEditor extends React.PureComponent<Props, State> {
  ref = React.createRef<HTMLInputElement>()
  state: State = this.createInitialState(this.props)

  private createInitialState(props) {
    return {
      value: normalize(props.value || ''),
      isOpen: true,
    }
  }

  componentDidMount() {
    if (this.ref.current && this.isEditable()) {
      this.setState({
        selection: undefined,
      })
      this.ref.current.focus()
      this.ref.current.selectionStart = this.ref.current.selectionEnd =
        this.ref.current.value.length
      this.ref.current.scrollTop = this.ref.current.scrollHeight // scroll to the bottom in case the cell has scrollbars
    }
    if (
      this.props.keyPress === 46 ||
      this.props.keyPress === 8 /* DELETE or BACKSPACE */
    ) {
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(this.props.rowIndex, this.props.column)
    }
  }

  isEditable(props = this.props) {
    if (props.disabled) {
      return !props.disabled
    }
    const editable = props['colDef'].editable
    return typeof editable === 'function' ? editable(props) : editable
  }

  getValue = () => {
    return this.props.parseValue
      ? this.props.parseValue(this.state.value)
      : this.state.value
  }

  onClose = (
    event: any,
    reason: 'escapeKeyDown' | 'backdropClick' | 'submit' | 'cancel'
  ) => {
    this.setState({ isOpen: false })
    this.props.api.stopEditing(
      ['escapeKeyDown', 'backdropClick', 'cancel'].includes(reason)
    )
  }

  private onChangeText = value => {
    this.setState({ value: value })
  }

  render() {
    const { classes } = this.props
    return (
      <StyledDialog
        open={this.state.isOpen}
        onClose={(event, reason) => {
          this.onClose(event, reason)
        }}
      >
        <RichTextFieldWrapper>
          <MultilineTextEditor
            dataUuid={this.props.data.uuid}
            externalId={this.props.field}
            value={this.state.value}
            setValue={this.onChangeText}
            disableSbsMode={true}
          />
        </RichTextFieldWrapper>
        <ButtonGroup>
          <SaveButton onClick={_ => this.onClose(_, 'submit')} />
          <CancelButton onClick={_ => this.onClose(_, 'cancel')} />
        </ButtonGroup>
      </StyledDialog>
    )
  }
}

export default cellEditor
