import {
  DialogActions,
  DialogTitle,
  MenuItem,
  Paper,
  PaperProps,
  Switch,
} from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { UiStateScope } from '../../../lib/commons/uiStates'
import { isDefault, Template } from './TemplateList'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import MultilineTextEditor, {
  EditorType,
} from '../../components/editors/multiline-text/MultilineTextEditor'
import LockIcon from '@mui/icons-material/LockRounded'
import GroupIcon from '@mui/icons-material/GroupRounded'
import Draggable from 'react-draggable'
import TextInput from '../../components/editors/input/TextInput'
import Select from '../../components/editors/select/Select'
import { generateUuid } from '../../../utils/uuids'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { AllState } from '../../../store'
import { connect } from 'react-redux'

// Styles
const StyledDialogTitle = styled(DialogTitle)({
  cursor: 'move',
  padding: '8px',
})
const StyledDialogContent = styled(DialogContent)({
  width: '700px',
})
const Title = styled('p')({
  margin: '0 0 3px 0',
})
const TemplateTextEditorWrapper = styled('div')({
  height: '400px',
  position: 'relative',
  border: '1px solid lightgrey',
  borderRadius: '4px',
})
const StyledSelect = styled(Select)({
  height: '30px',
})
const StyledMenuItem = styled(MenuItem)({
  height: 'inherit',
}) as typeof MenuItem
const OptionLabel = styled('p')({
  display: 'flex',
  alignItems: 'center',
})

interface Props extends WrappedComponentProps {
  template: Template
  externalId: string
  open: boolean
  onSubmit: (template: Template) => void
  onClose: () => void
  editor?: EditorType
  hasAllPrivileges?: boolean
}

interface State {
  name: string
  text: string
  scope: UiStateScope
  default: boolean
}

const DraggablePaper = (props: PaperProps) => {
  return (
    <Draggable
      handle="#templateEdit-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

class TemplateEditDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      name: props.template.name,
      text: props.template.text,
      scope: props.template.scope,
      default: isDefault(props.template, this.props.externalId),
    }
  }

  private onChangeName = event => {
    this.setState({ name: event.target.value })
  }

  private onChangeText = value => {
    this.setState({ text: value })
  }

  private onChangeScope = e => {
    this.setState({ scope: e.target.value, default: false })
  }

  private submit = event => {
    let externalIdDefault = this.props.template.externalIdDefault || []
    if (
      this.state.default &&
      !isDefault(this.props.template, this.props.externalId)
    ) {
      externalIdDefault = [...externalIdDefault, this.props.externalId]
    } else if (
      !this.state.default &&
      isDefault(this.props.template, this.props.externalId)
    ) {
      externalIdDefault = externalIdDefault.filter(
        id => id !== this.props.externalId
      )
    }
    this.props.onSubmit({
      uuid: this.props.template.uuid || generateUuid(),
      name: this.state.name,
      text: this.state.text,
      scope: this.state.scope,
      externalIdDefault,
    })
    this.setState({
      name: '',
      text: '',
      scope: UiStateScope.User,
      default: false,
    })
  }

  private close = event => {
    this.props.onClose()
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth={false}
        PaperComponent={DraggablePaper}
        aria-labelledby="templateEdit-dialog-title"
      >
        <StyledDialogTitle id="templateEdit-dialog-title" />
        <StyledDialogContent>
          <Title>
            {this.props.intl.formatMessage({ id: 'template.name' })}
          </Title>
          <TextInput
            id="name"
            variant="outlined"
            required={true}
            fullWidth={true}
            value={this.state.name}
            onChange={this.onChangeName}
            size="small"
            autoFocus={true}
          />
          <Title>
            {this.props.intl.formatMessage({ id: 'template.scope' })}
          </Title>
          <FormControl variant="outlined" size="small" fullWidth={true}>
            <StyledSelect
              required={true}
              value={this.state.scope}
              onChange={this.onChangeScope}
              MenuItem={StyledMenuItem}
              options={
                this.props.hasAllPrivileges
                  ? [
                      {
                        label: (
                          <OptionLabel>
                            <ApartmentIcon color="action" />
                            {this.props.intl.formatMessage({
                              id: 'template.scope.tenant',
                            })}
                          </OptionLabel>
                        ),
                        value: UiStateScope.CrossProject,
                      },
                      {
                        label: (
                          <OptionLabel>
                            <GroupIcon color="action" />
                            {this.props.intl.formatMessage({
                              id: 'template.scope.project',
                            })}
                          </OptionLabel>
                        ),
                        value: UiStateScope.Tenant,
                      },
                      {
                        label: (
                          <OptionLabel>
                            <LockIcon color="action" />
                            {this.props.intl.formatMessage({
                              id: 'template.scope.private',
                            })}
                          </OptionLabel>
                        ),
                        value: UiStateScope.User,
                      },
                    ]
                  : [
                      {
                        label: (
                          <OptionLabel>
                            <GroupIcon color="action" />
                            {this.props.intl.formatMessage({
                              id: 'template.scope.project',
                            })}
                          </OptionLabel>
                        ),
                        value: UiStateScope.Tenant,
                      },
                      {
                        label: (
                          <OptionLabel>
                            <LockIcon color="action" />
                            {this.props.intl.formatMessage({
                              id: 'template.scope.private',
                            })}
                          </OptionLabel>
                        ),
                        value: UiStateScope.User,
                      },
                    ]
              }
              MenuProps={{
                style: {
                  height: 'inherit',
                },
              }}
            />
          </FormControl>
          <Title>
            {this.props.intl.formatMessage({ id: 'template.default' })}
          </Title>
          <FormControl variant="outlined" size="small" fullWidth={true}>
            <Switch
              size={'small'}
              color={'primary'}
              checked={this.state.default}
              disabled={this.state.scope !== UiStateScope.Tenant}
              onChange={() => this.setState({ default: !this.state.default })}
            />
          </FormControl>
          <Title>
            {this.props.intl.formatMessage({ id: 'template.text' })}
          </Title>
          <TemplateTextEditorWrapper>
            <MultilineTextEditor
              value={this.state.text}
              setValue={this.onChangeText}
              disableSbsMode={true}
            />
          </TemplateTextEditorWrapper>
        </StyledDialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.submit}>
            {this.props.intl.formatMessage({ id: 'dialog.ok' })}
          </Button>
          <Button onClick={this.close}>
            {this.props.intl.formatMessage({ id: 'dialog.cancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  hasAllPrivileges: state.user.user?.hasAllPrivileges,
})

export default connect(mapStateToProps)(injectIntl(TemplateEditDialog))
