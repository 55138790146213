import React from 'react'
import { styled } from '@mui/system'
import SendIcon from '@mui/icons-material/SendRounded'
import IconButton from '@mui/material/IconButton'

const StyledSendIcon = styled(SendIcon)<{ disabled: boolean }>(
  ({ disabled }) => {
    if (disabled) {
      return {
        color: '#888888',
      }
    } else {
      return {
        color: '#1D85B4',
        '&:hover': {
          cursor: 'pointer',
        },
      }
    }
  }
)

const SendButton = ({ onClick, disabled }) => {
  return (
    <IconButton onClick={disabled ? undefined : onClick} size="small">
      <StyledSendIcon disabled={disabled} />
    </IconButton>
  )
}

export default SendButton
