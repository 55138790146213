import { connect } from 'react-redux'
import { WbsItemIcon } from '../../components/icons/WbsItemIcon'
import WbsItemStatusLabel from '../../components/labels/WbsItemStatusLabel'
import { Component } from 'react'
import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { styled } from '@mui/system'
import { WbsItemStatus } from '../commons/AgGrid/components/cell/custom/wbsItemStatus'
import { ProjectMemberProps } from '../../../lib/functions/projectMember'
import { DateTerm } from '../../../utils/date'
import { WbsItemForStatusChange } from '../../components/poppers/StatusChangePopper'
import { generateUuid } from '../../../utils/uuids'
import { WbsItemData } from '../../pages/WbsItem'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'

const RootContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 0',
  backgroundColor: '#f5f5f5',
})
const WbsItemIconWrapper = styled('div')({
  display: 'flex',
  marginLeft: '5px',
  '& img': {
    width: '19px',
  },
})
const DisplayName = styled('div')({
  fontWeight: 'bold',
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '0 3px',
  color: '#262626',
})

export interface WbsItemForCommentHeader extends WbsItemForStatusChange {
  displayName: string
  ticketType?: string
}

export const mapRowDataForCommentHeader = (
  wbsItem: WbsItemRow
): WbsItemForCommentHeader => {
  return {
    uuid: wbsItem.uuid || generateUuid(),
    lockVersion: wbsItem.lockVersion || 0,
    type: wbsItem.type || WbsItemType.TASK,
    projectUuid: wbsItem.projectUuid || '',
    displayName: wbsItem.displayName || '',
    status: wbsItem.status || WbsItemStatus.TODO,
    assignee: wbsItem.assignee,
    priority: wbsItem.priority,
    scheduledDate: wbsItem.scheduledDate,
    actualDate: wbsItem.actualDate,
    ticketType: wbsItem.ticketType,
    wbsItemType: wbsItem.wbsItemType,
  }
}

export const mapTableDataForCommentHeader = (
  wbsItem: WbsItemData,
  tableDataTicketType?: string
): WbsItemForCommentHeader => {
  return {
    uuid: wbsItem.uuid || generateUuid(),
    lockVersion: wbsItem.lockVersion || 0,
    type: wbsItem.type
      ? (wbsItem.type.getValue() as WbsItemType)
      : WbsItemType.TASK,
    projectUuid: wbsItem.projectUuid || '',
    displayName: wbsItem.displayName ? wbsItem.displayName.getValue() : '',
    status: wbsItem.status
      ? (wbsItem.status.getValue() as WbsItemStatus)
      : WbsItemStatus.TODO,
    assignee:
      wbsItem.assignee &&
      ({
        uuid: wbsItem.assignee.getValue(),
        name: wbsItem.assignee.format(),
        iconUrl: wbsItem.assignee.getIconUrl(),
      } as ProjectMemberProps),
    priority: wbsItem.priority && wbsItem.priority.getValue(),
    scheduledDate:
      wbsItem.scheduledDate &&
      ({
        startDate: wbsItem.scheduledDate.getValue().startDate,
        endDate: wbsItem.scheduledDate.getValue().endDate,
      } as DateTerm),
    actualDate:
      wbsItem.actualDate &&
      ({
        startDate: wbsItem.actualDate.getValue().startDate,
        endDate: wbsItem.actualDate.getValue().endDate,
      } as DateTerm),
    ticketType: wbsItem.ticketType || tableDataTicketType,
    wbsItemType: wbsItem.wbsItemType,
  }
}

export interface Props {
  wbsItem: WbsItemForCommentHeader
  onAfterUpdate?: () => void
  readonly?: boolean
}

interface State {
  wbsItemDisplayName?: string
}

class CommentHeaderWbsItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      wbsItemDisplayName: undefined,
    }
  }
  private getAndSetWbsItemIcon = async () => {
    const wbsItem = this.props.wbsItem
    if (!wbsItem?.type) {
      return
    }
    this.setState({
      wbsItemDisplayName: wbsItem.displayName,
    })
  }

  async componentDidMount() {
    this.getAndSetWbsItemIcon()
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.wbsItem !== prevProps.wbsItem) {
      this.getAndSetWbsItemIcon()
    }
  }

  render() {
    const { wbsItem, onAfterUpdate, readonly } = this.props
    return (
      <RootContainer>
        <WbsItemStatusLabel
          status={wbsItem.status}
          statusChangePopperProps={{
            projectUuid: wbsItem.projectUuid,
            wbsItem: wbsItem,
            onAfterUpdate: onAfterUpdate,
          }}
          readonly={readonly}
        />
        {wbsItem.wbsItemType && (
          <WbsItemIconWrapper>
            <WbsItemIcon type={wbsItem.wbsItemType} />
          </WbsItemIconWrapper>
        )}
        <DisplayName>{this.state.wbsItemDisplayName}</DisplayName>
      </RootContainer>
    )
  }
}

export default CommentHeaderWbsItem
