import {
  ButtonProps,
  ClickAwayListener,
  Collapse,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  Popper,
  Slider,
  Switch,
  Tooltip,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { useCallback, useMemo, useRef, useState } from 'react'
import { intl } from '../../../../../i18n'
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Button } from '../../../../components/buttons'
import {
  ToggleButton,
  ToggleButtonGroup,
  useOnChangeToggleButtonGroup,
} from '../../../../components/inputs/ToggleButtonGroup'
import { DatePicker } from '../../../../components/inputs/DatePicker'

export const SideBarRoot = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  gap: '20px',
})

export const SidebarPanelRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

export const SidebarPanelHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
export const SidebarPanelTitle = styled('div')({
  fontSize: '13px',
  lineHeight: 1.5,
  fontWeight: 600,
})

export const SidebarPanelHeaderIconButton = styled(IconButton)({
  padding: '2px',
})
export const SidebarHeaderArrowIcon = ({ open }: { open: boolean }) =>
  open ? <OpenPanelIcon /> : <ClosedPanelIcon />
const ClosedPanelIcon = styled(KeyboardArrowDownRoundedIcon)({
  color: colorPalette.monotone[4],
})
const OpenPanelIcon = styled(KeyboardArrowUpRoundedIcon)({
  color: colorPalette.monotone[4],
})

export const SidebarPanelMainArea = styled(Collapse)({
  '& .MuiCollapse-wrapperInner': {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px 0 0 0',
  },
})

export const SimulationHeaderArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
})

export const SimulationSettingArea = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const SimulationHeader = styled('div')({
  color: colorPalette.monotone[10],
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: 'normal',
})

export const SimulationDescription = styled('div')({
  color: colorPalette.monotone[4],
  fontSize: '13px',
})

export const SimulationSubHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export const SimulationConfigHeaderArea = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

export const SimulationConfigArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})

export const SimulationConfigHeader = styled('div')({
  color: colorPalette.monotone[4],
  fontSize: '12px',
})

export const SimulationConfigResetButton = styled(Link)({
  color: colorPalette.skyBlue[8],
  fontSize: '12px',
  cursor: 'pointer',
})

const SimulationValueBox = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 6px 0 10px',
  gap: '4px',
  justifyContent: 'space-between',
})

const SimulationValue = styled('div')({
  color: colorPalette.monotone[10],
  fontSize: '13px',
  fontWeight: 600,
})

const StyledSlider = styled(Slider)({
  width: '4px',
  margin: '10px 0',
  height: 'calc(100% - 20px)',
  color: colorPalette.skyBlue[7],
})

const StyledIconButton = styled(IconButton)({
  padding: '2px',
})
const ArrowIcon = ({ open }: { open: boolean }) =>
  open ? <OpenIcon /> : <ClosedIcon />
const ClosedIcon = styled(KeyboardArrowDownRoundedIcon)({
  color: colorPalette.monotone[4],
})
const OpenIcon = styled(KeyboardArrowUpRoundedIcon)({
  color: colorPalette.monotone[4],
})

const PopperBase = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  display: 'flex',
  borderRadius: '4px',
  justifyContent: 'center',
})
const SliderPopperWrapper = styled(PopperBase)({
  height: '100px',
})
const DatePickerPopperWrapper = styled(PopperBase)({})

type SimulationConfigPanelProps = {
  label: string
  value: number
  update: (v: number) => void
  onChangeCommited?: (v: number) => void
  max: number
  onReset?: () => void
}
export const SimulationConfigPanel = ({
  label,
  value,
  update,
  onChangeCommited: _onChangeCommited,
  max,
  onReset,
}: SimulationConfigPanelProps) => {
  const onChange = useCallback(
    (_, value: number | number[]) => {
      if (Array.isArray(value)) return
      update(value)
    },
    [update]
  )
  const onChangeCommited = useCallback(
    (_, value: number | number[]) => {
      if (!_onChangeCommited) return
      if (Array.isArray(value)) return
      _onChangeCommited(value)
    },
    [_onChangeCommited]
  )
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLButtonElement | null>(null)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const initialValueLabel = useMemo(
    () => intl.formatMessage({ id: 'progressReport.simulation.initialValue' }),
    []
  )
  return (
    <>
      <SimulationConfigArea>
        <SimulationConfigHeaderArea>
          <SimulationConfigHeader>{label}</SimulationConfigHeader>
          <SimulationConfigResetButton onClick={onReset}>
            {initialValueLabel}
          </SimulationConfigResetButton>
        </SimulationConfigHeaderArea>
        <SimulationValueBox>
          <SimulationValue>{value.toFixed(2)}</SimulationValue>
          <StyledIconButton onClick={toggle} ref={ref}>
            <ArrowIcon open={open} />
          </StyledIconButton>
        </SimulationValueBox>
      </SimulationConfigArea>
      <Popper open={open} anchorEl={ref.current} placement="bottom">
        <ClickAwayListener onClickAway={onClose}>
          <SliderPopperWrapper>
            <StyledSlider
              size="small"
              orientation="vertical"
              value={value}
              min={0.1}
              max={max}
              step={0.1}
              onChange={onChange}
              onChangeCommitted={onChangeCommited}
            />
          </SliderPopperWrapper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
type SimulationDateConfigPanelProps = {
  label: string
  value: Date | undefined
  format: (v: Date | undefined) => string
  update: (v: Date) => void
  onReset?: () => void
}
export const SimulationDateConfigPanel = ({
  label,
  value,
  format,
  update,
  onReset,
}: SimulationDateConfigPanelProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLButtonElement | null>(null)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const formattedValue = useMemo(() => format(value), [format, value])
  const initialValueLabel = useMemo(
    () => intl.formatMessage({ id: 'progressReport.simulation.initialValue' }),
    []
  )
  return (
    <>
      <SimulationConfigArea>
        <SimulationConfigHeaderArea>
          <SimulationConfigHeader>{label}</SimulationConfigHeader>
          <SimulationConfigResetButton onClick={onReset}>
            {initialValueLabel}
          </SimulationConfigResetButton>
        </SimulationConfigHeaderArea>
        <SimulationValueBox>
          <SimulationValue>{formattedValue}</SimulationValue>
          <StyledIconButton onClick={toggle} ref={ref}>
            <ArrowIcon open={open} />
          </StyledIconButton>
        </SimulationValueBox>
      </SimulationConfigArea>
      <Popper open={open} anchorEl={ref.current} placement="bottom-end">
        <ClickAwayListener onClickAway={onClose}>
          <DatePickerPopperWrapper>
            <DatePicker value={value} onChange={update} />
          </DatePickerPopperWrapper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

export const formatDate = (v: Date): string => {
  const year = v.getFullYear()
  const month = v.getMonth() + 1
  const monthStr = month < 10 ? `0${month}` : month.toString()
  const date = v.getDate()
  const dateStr = date < 10 ? `0${date}` : date.toString()
  return `${year}/${monthStr}/${dateStr}`
}
type SimulatedSimulationFromDateConfigPanelProps = {
  value: Date | undefined
  update: (v: Date) => void
  onReset?: () => void
}
export const SimulatedSimulationFromDateConfigPanel = ({
  ...props
}: SimulatedSimulationFromDateConfigPanelProps) => {
  const label = useMemo(
    () =>
      intl.formatMessage({
        id: 'progressReport.simulation.simulationFromDate',
      }),
    []
  )
  const format = useCallback((v: Date | undefined) => {
    if (!v) return ''
    return formatDate(v)
  }, [])
  return <SimulationDateConfigPanel {...props} label={label} format={format} />
}
type ProspectSimulationEndDateConfigPanelProps = {
  value: Date | undefined
  update: (v: Date) => void
  onReset?: () => void
}
export const ProspectSimulationEndDateConfigPanel = ({
  ...props
}: ProspectSimulationEndDateConfigPanelProps) => {
  const label = useMemo(
    () => intl.formatMessage({ id: 'progressReport.simulation.endDate' }),
    []
  )
  const format = useCallback((v: Date | undefined) => {
    if (!v) return ''
    return formatDate(v)
  }, [])
  return <SimulationDateConfigPanel {...props} format={format} label={label} />
}

export type SimulatableGraphType = 'PROSPECT' | 'SIMULATED'
type GraphToggleButtonGroupProps = {
  value: SimulatableGraphType
  onChange: (v: SimulatableGraphType) => void
}
export const GraphToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: GraphToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  const [prospectGraphLabel, simulatedGraphLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.graph.prediction' }),
      intl.formatMessage({ id: 'progressReport.graph.simulated' }),
    ],
    []
  )
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={'PROSPECT'}>{prospectGraphLabel}</ToggleButton>
      <ToggleButton value={'SIMULATED'}>{simulatedGraphLabel}</ToggleButton>
    </ToggleButtonGroup>
  )
}

export const SimulationResultPanel = styled('div')({
  backgroundColor: colorPalette.monotone[1],
  padding: '12px 8px',
  borderRadius: '4px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})
export const SimulationResultMainRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})
export const SimulationResultMainTitle = styled('div')({
  color: colorPalette.monotone[4],
  fontSize: '13px',
  fontWeight: 600,
  lineHeight: 'normal',
})
export const SimulationResultMainValue = styled('div')({
  color: colorPalette.pink[7],
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 'normal',
})
export const SimulationResultSubRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
})
export const SimulationResultSubTitle = styled('div')({
  color: colorPalette.monotone[4],
  fontSize: '12px',
  lineHeight: 'normal',
  margin: '0 6px 0 0',
})
export const SimulationResultSubValue = styled('div')({
  color: colorPalette.pink[7],
  fontSize: '14px',
  lineHeight: 'normal',
})
export const SimulationResultSubValueUnit = styled('div')({
  color: colorPalette.pink[7],
  fontSize: '10px',
  lineHeight: 'normal',
})
