import * as React from 'react'
import { colorPalette } from '../../style/colorPallete'

const SvgPlusIcon = React.forwardRef(
  (props: { style?: React.CSSProperties }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.style?.width || 12}
      height={props.style?.height || 12}
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        fill={props.style?.color || colorPalette.monotone[3]}
        strokeWidth={1}
        d="M11.25 6.22717C11.25 6.64139 10.9142 6.97717 10.5 6.97717H6.75V10.7272C6.75 11.1414 6.41421 11.4772 6 11.4772C5.58579 11.4772 5.25 11.1414 5.25 10.7272V6.97717H1.5C1.08579 6.97717 0.75 6.64139 0.75 6.22717C0.75 5.81296 1.08579 5.47717 1.5 5.47717H5.25V1.72717C5.25 1.31296 5.58579 0.977173 6 0.977173C6.41421 0.977173 6.75 1.31296 6.75 1.72717V5.47717H10.5C10.9142 5.47717 11.25 5.81296 11.25 6.22717Z"
      />
    </svg>
  )
)

export default SvgPlusIcon
