import _ from 'lodash'
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowNode,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { ResourcePlanType } from '../../../../lib/functions/resourcePlanNew'
import { UserDetail } from '../../../../lib/functions/user'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import { ResourcePlanRow } from '../resourcePlan'
import { TeamProps } from '../../../../lib/functions/team'
import { getRootNodeForMemberView, getRootNodeForTeamView } from '.'

/**
 * functions for member view
 */
export const addMemberMenuItemForMemberView = (
  params: GetContextMenuItemsParams,
  allCanAddMemberList: UserDetail[],
  action: () => void
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'project.resourcePlan.addMember',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MEMBER),
    disabled:
      (params.node?.data as ResourcePlanRow)?.type !==
        ResourcePlanType.MEMBER ||
      getAddableMembersForMemberView(params.api, allCanAddMemberList).length <=
        0,
    action,
  }
}

export const getAddableMembersForMemberView = (
  api: GridApi | null | undefined,
  allCanAddMemberList: UserDetail[]
): UserDetail[] => {
  if (!api) return []
  const exclude: string[] = []
  api.forEachNode((n: RowNode) => {
    const row: ResourcePlanRow = n.data
    if (row.type === ResourcePlanType.MEMBER && row.body?.user) {
      exclude.push(row.body.user.uuid)
    }
  })
  return allCanAddMemberList.filter(d => !exclude.includes(d.uuid))
}

export const addTeamMenuItemForMemberView = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  teams: TeamProps[],
  excludeTeamUuids: string[],
  action: () => void
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'project.resourcePlan.addTeam',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_TEAM),
    disabled:
      selectedNodes.some(node => {
        const row: ResourcePlanRow | undefined = node?.data
        return !row || !row.body.user
      }) || teams.length <= excludeTeamUuids.length,
    action,
  }
}

export const getExcludeTeamUuidsForMemberView = (
  selectedNodes: (RowNode<ResourcePlanRow> | null | undefined)[]
): string[] => {
  if (!selectedNodes || _.isEmpty(selectedNodes)) return []

  const excludeUuids: string[] = []
  selectedNodes.forEach((node: RowNode<ResourcePlanRow> | null | undefined) => {
    if (!node) return
    const rootRowNode: RowNode | null = getRootNodeForMemberView(node)
    const exclude = rootRowNode?.allLeafChildren
      .map((node: RowNode) => {
        const row: ResourcePlanRow = node.data
        return row.type === ResourcePlanType.TEAM
          ? row.body.team?.uuid
          : undefined
      })
      .filter(uuid => uuid) as string[]
    excludeUuids.push(...exclude)
  })
  return Array.from(new Set<string>(excludeUuids))
}

export const deleteRowMenuItemForMemberView = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  action: () => void
) => {
  const checkRemovableResult = checkRemovable(
    selectedNodes,
    getRowsToRemoveForMemberView
  )
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.delete' },
      { count: selectedNodes.length }
    ),
    action,
    disabled: !checkRemovableResult.removable,
    tooltip: checkRemovableResult.unremovableReason,
    icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
  }
}

const canRemoveRowForMemberView = (node: RowNode<ResourcePlanRow>) => {
  if (!node.data) return false
  if (node.data.type === ResourcePlanType.UNSET) {
    return false
  }
  if (
    node.data.type === ResourcePlanType.MEMBER &&
    node.allChildrenCount &&
    node.allChildrenCount > 1
  ) {
    return false
  }
  if (!node.data.body.user) return false
  return true
}

export const getRowsToRemoveForMemberView = (
  selectedNodes: RowNode<ResourcePlanRow>[]
): {
  rows: RowNode<ResourcePlanRow>[]
  unremovableReasonMessageId?: string
} => {
  return getRowsToRemove(selectedNodes, canRemoveRowForMemberView)
}

/**
 * functions for team view
 */

export const addTeamMenuItemForTeamView = (
  teams: TeamProps[],
  excludeTeamUuids: string[],
  action: () => void
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'project.resourcePlan.addTeam',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_TEAM),
    disabled: teams.length <= excludeTeamUuids.length,
    action,
  }
}

export const addMemberMenuItemForTeamView = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  allCanAddMemberList: UserDetail[],
  action: () => void
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'project.resourcePlan.addMember',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MEMBER),
    disabled:
      _.isEmpty(selectedNodes) ||
      selectedNodes.some((node: RowNode<ResourcePlanRow>) => {
        return (
          (getRootNodeForTeamView(node)?.data as ResourcePlanRow)?.type ===
          ResourcePlanType.UNSET
        )
      }) ||
      getAddableMembersForTeamView(selectedNodes, allCanAddMemberList).length <=
        0,
    action,
  }
}

export const deleteRowMenuItemForTeamView = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  action: () => void
) => {
  const checkRemovableResult = checkRemovable(
    selectedNodes,
    getRowsToRemoveForTeamView
  )
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.delete' },
      { count: selectedNodes.length }
    ),
    action,
    disabled: !checkRemovableResult.removable,
    tooltip: checkRemovableResult.unremovableReason,
    icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
  }
}

export const getExcludeTeamUuidsForTeamView = (
  rows: ResourcePlanRow[]
): string[] => {
  return rows
    .filter((row: ResourcePlanRow) => row.type === ResourcePlanType.TEAM)
    .map((row: ResourcePlanRow) => row.body.team?.uuid)
    .filter(uuid => !!uuid) as string[]
}

export const getAddableMembersForTeamView = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  allCanAddMemberList: UserDetail[]
): UserDetail[] => {
  const excludeUuids = new Set<string>()
  selectedNodes.forEach((node: RowNode<ResourcePlanRow> | null | undefined) => {
    if (!node) return
    const rootRowNode: RowNode | null = getRootNodeForTeamView(node)
    rootRowNode?.allLeafChildren.forEach((node: RowNode) => {
      const row: ResourcePlanRow = node.data
      if (row.type === ResourcePlanType.MEMBER && row.body.user?.uuid) {
        excludeUuids.add(row.body.user?.uuid)
      }
    })
  })
  return allCanAddMemberList.filter(d => !excludeUuids.has(d.uuid))
}

const canRemoveRowForTeamView = (node: RowNode<ResourcePlanRow>) => {
  if (!node.data) return false
  if (
    node.data.type === ResourcePlanType.UNSET ||
    node.parent?.data?.type === ResourcePlanType.UNSET
  ) {
    return false
  }
  if (
    node.data.type === ResourcePlanType.TEAM &&
    node.allChildrenCount &&
    node.allChildrenCount > 0
  ) {
    return false
  }
  if (!node.data.body.user) return false
  return true
}

export const getRowsToRemoveForTeamView = (
  selectedNodes: RowNode<ResourcePlanRow>[]
): {
  rows: RowNode<ResourcePlanRow>[]
  unremovableReasonMessageId?: string
} => {
  return getRowsToRemove(selectedNodes, canRemoveRowForTeamView)
}

/**
 * common
 */

const checkRemovable = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  getRowsToRemove: (selectedNodes: RowNode<ResourcePlanRow>[]) => {
    rows: RowNode<ResourcePlanRow>[]
    unremovableReasonMessageId?: string
  }
): {
  removable: boolean
  unremovableReason?: string
} => {
  if (!!selectedNodes && selectedNodes.length === 0) {
    return { removable: false }
  }
  const result = getRowsToRemove(selectedNodes)

  const targetNodes = result ? result.rows : selectedNodes
  const unremovableReasonMessageId = result?.unremovableReasonMessageId
  if (selectedNodes.some(selected => !targetNodes.includes(selected))) {
    return {
      removable: false,
      unremovableReason: unremovableReasonMessageId
        ? intl.formatMessage({
            id: unremovableReasonMessageId,
          })
        : undefined,
    }
  }
  return {
    removable: true,
  }
}

export const getRowsToRemove = (
  selectedNodes: RowNode<ResourcePlanRow>[],
  canRemoveRow: (node: RowNode<ResourcePlanRow>) => boolean
): {
  rows: RowNode<ResourcePlanRow>[]
  unremovableReasonMessageId?: string
} => {
  let result: RowNode<ResourcePlanRow>[] = []
  selectedNodes.forEach(node => {
    if (canRemoveRow(node)) {
      result.push(...node.allLeafChildren)
    }
  })
  result = result.filter((node, index, self) => self.indexOf(node) === index)
  return { rows: result }
}
