import { Grid, styled } from '@mui/material'
import { GitHubCard } from './external-services/github/GitHubCard'
import { intl } from '../../../i18n'
import { SlackCard } from './external-services/slack/SlackCard'
import { PageArea } from '..'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'

const ExternalServices = ({ children }) => {
  return (
    <PageArea>
      <ExternalServicesContentArea>
        <ExternalServiceListTitle>
          {intl.formatMessage({ id: 'externalService.connectableServices' })}
        </ExternalServiceListTitle>
        <ExternalServiceListArea>
          <Grid container={true} columns={{ xs: 4, md: 12 }}>
            {children}
          </Grid>
        </ExternalServiceListArea>
      </ExternalServicesContentArea>
    </PageArea>
  )
}

const TenantExternalServices = () => {
  return (
    <ExternalServices>
      <GridItem xs={2} md={4}>
        <SlackCard />
      </GridItem>
    </ExternalServices>
  )
}

const ProjectExternalServices = projectPrivate(() => {
  const { project } = useProjectPrivateContext()
  return (
    <ExternalServices>
      <GridItem xs={2} md={4}>
        <GitHubCard projectUuid={project.uuid} />
      </GridItem>
    </ExternalServices>
  )
})

const ExternalServicesContentArea = styled('div')({
  width: 'calc(100% - 8px)',
  height: '100%',
  margin: '4px',
  border: '1px solid #888888',
})

const ExternalServiceListArea = styled('div')({
  margin: '24px 128px',
  flexGrow: 1,
})

const ExternalServiceListTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  padding: '16px 8px 16px 24px',
})

const GridItem = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  margin: '12px 0',
})

export { TenantExternalServices, ProjectExternalServices }
