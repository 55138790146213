import { useCallback, useEffect, useState } from 'react'
import api from '../../lib/commons/api'
import { ChatChannelMessageTicketCreationMappingRepository } from '../../applications/ports/chatChannelMessageTicketCreationMappingRepository'
import { ChatChannelMessageTicketCreationMappingEntity } from '../../domain/entity/ChatChannelMessageTicketCreationMappingEntity'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../domain/value-object/WbsItemTypeVO'
import { UserBasic } from '../../lib/functions/user'
import { TicketListBasic } from '../../lib/functions/ticketList'
import { SlackChannel } from '../../domain/value-object/Slack/ChannelVO'

export const useChatChannelMessageTicketCreationMappingRepository =
  (): ChatChannelMessageTicketCreationMappingRepository => {
    const getByProjectUuid = useCallback(
      async (
        projectUuid: string,
        chatWorkspaceId: string
      ): Promise<ChatChannelMessageTicketCreationMappingEntity[]> => {
        const response = await api.functional.request(
          'GET',
          '/api/v1/projects/chat_channel_message_ticket_creation_mappings',
          { projectUuid, chatWorkspaceId }
        )
        const mappings: ChatChannelMessageTicketCreationMappingsFetchResponse =
          response.json
        return mappings.map(v => {
          return {
            uuid: v.uuid,
            lockVersion: v.lockVersion,
            revision: v.revision,
            projectUuid: v.projectUuid,
            ticketType: new WbsItemTypeVO(v.baseTicketType),
            ticketList: v.ticketList,
            chatChannel: v.chatChannel,
            createdAt: v.createdAt,
            createdBy: v.createdBy,
            updatedAt: v.updatedAt,
            updatedBy: v.updatedBy,
          }
        })
      },
      []
    )

    const save = useCallback(
      async ({
        added,
        edited,
        deleted,
      }: {
        added: ChatChannelMessageTicketCreationMappingEntity[]
        edited: ChatChannelMessageTicketCreationMappingEntity[]
        deleted: ChatChannelMessageTicketCreationMappingEntity[]
      }): Promise<{ hasError: boolean; hasWarning: boolean }> => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/chat_channel_message_ticket_creation_mappings/batch',
          {
            added: added.map(v => ({
              uuid: v.uuid,
              projectUuid: v.projectUuid,
              ticketListUuid: v.ticketList.uuid,
              chatChannelId: v.chatChannel?.slackChannelId,
            })),
            edited: edited.map(v => ({
              uuid: v.uuid,
              projectUuid: v.projectUuid,
              ticketListUuid: v.ticketList.uuid,
              chatChannelId: v.chatChannel?.slackChannelId,
            })),
            deleted: deleted.map(v => ({ uuid: v.uuid })),
          }
        )
        return {
          hasError: !!response.hasError,
          hasWarning: !!response.hasWarning,
        }
      },
      []
    )
    return {
      getByProjectUuid,
      save,
    }
  }

interface ChatChannelMessageTicketCreationMappingObject {
  uuid: string
  lockVersion: number
  revision: string
  projectUuid: string
  baseTicketType: WbsItemTypeObject
  ticketList: TicketListBasic
  chatChannel: SlackChannel
  createdAt: number
  createdBy?: UserBasic
  updatedAt: number
  updatedBy?: UserBasic
}
type ChatChannelMessageTicketCreationMappingsFetchResponse =
  ChatChannelMessageTicketCreationMappingObject[]
