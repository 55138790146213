import { UiStateKey } from '../../../lib/commons/uiStates'
import TicketListOptions, {
  TicketListRowDataSpec,
} from '../TicketList/TicketListOptions'

// TODO: delete this file. [V6BWULRZ]

const CHANGE_REQUEST = 'CHANGE_REQUEST'

export default class ChangeRequestListOptions extends TicketListOptions {
  constructor() {
    super(CHANGE_REQUEST)
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ChangeRequestListColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx =>
    `${UiStateKey.ChangeRequestListSearchConditionState}-${ctx.state.uuid}`
  rowDataSpec = new TicketListRowDataSpec(CHANGE_REQUEST)
}
