import { useCallback, useState } from 'react'
import {
  UnitPriceInput,
  UnitPricePerPositionInput,
  UnitPricePerPositionRow,
  UnitPricePerPositionRowBody,
  UnitPricePerPositionSkeleton,
  fetchUnitPricePerPositions,
  updateUnitPricePerPosition,
} from '../UnitPricePerPosition'
import { AgGridTreeHelper } from '../../../containers/BulkSheetView/lib/tree'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import { generateUuid } from '../../../../utils/uuids'

export const useUnitPricePerPositionData = () => {
  const [data, setDataInternal] = useState<UnitPricePerPositionRow[]>([])

  const fetchRecords = useCallback(async () => {
    setDataInternal([])

    const response = await fetchUnitPricePerPositions()
    const source: UnitPricePerPositionSkeleton[] = response.json
    const rows = source
      .map(Skeleton =>
        AgGridTreeHelper.convert(
          Skeleton,
          (s: UnitPricePerPositionSkeleton): UnitPricePerPositionRow => {
            return {
              uuid: s.uuid ?? generateUuid(),
              lockVersion: s.lockVersion,
              added: !s.uuid,
              body: new UnitPricePerPositionRowBody(s),
            } as UnitPricePerPositionRow
          }
        )
      )
      .flat()
    setDataInternal(rows)
  }, [])

  const refresh = useCallback(async () => {
    await fetchRecords()
    store.dispatch(doNotRequireSave())
  }, [fetchRecords])

  const setData = useCallback((data: UnitPricePerPositionRow[]) => {
    setDataInternal(data)
    store.dispatch(requireSave())
  }, [])

  const save = useCallback(async () => {
    const records = data
      .filter(v => v.edited)
      .map(v => {
        return {
          uuid: !v.added ? v.uuid : undefined,
          lockVersion: !v.added ? v.lockVersion : undefined,
          hidden: false,
          positionUuid: v.body.position?.uuid,
          unitPrices: v.body.unitPrices.map(up => {
            return {
              validFrom: up.validFrom,
              unitPrice: up.unitPrice,
            } as UnitPriceInput
          }),
        } as UnitPricePerPositionInput
      })
    const response = await updateUnitPricePerPosition({ records })
    return response
  }, [data])

  return {
    data,
    setData,
    refresh,
    save,
  }
}
