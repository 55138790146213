import { useCallback, useMemo } from 'react'
import { CellTitle, CellValueArea, FormCell, CellPropsBase } from '.'
import { DateTermV2 } from '../../../../../domain/value-object/DateTermV2'
import { DateInput } from '../../../../components/inputs/DatePicker'
import { Divider, styled } from '@mui/material'

type DateTermCellProps = {
  value: DateTermV2 | undefined
  onChange?: (v: DateTermV2 | undefined) => void
} & CellPropsBase

export const DateTermCell = ({
  value,
  onChange,
  cellWidth,
  label,
  required,
}: DateTermCellProps) => {
  const { startDate, endDate } = useMemo(() => {
    if (!value) {
      return {
        startDate: undefined,
        endDate: undefined,
      }
    }
    return value
  }, [value])
  const onChangeStartDate = useCallback(
    (v: Date | undefined) => {
      onChange &&
        onChange({
          startDate: v,
          endDate,
        })
    },
    [endDate, onChange]
  )
  const onChangeEndDate = useCallback(
    (v: Date | undefined) => {
      onChange &&
        onChange({
          startDate,
          endDate: v,
        })
    },
    [startDate, onChange]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <InputWrapper>
          <DateInput
            value={startDate}
            onChange={onChangeStartDate}
            onSelect={onChangeStartDate}
          />
        </InputWrapper>
        <StyledDivider />
        <InputWrapper>
          <DateInput
            value={endDate}
            onChange={onChangeEndDate}
            onSelect={onChangeEndDate}
          />
        </InputWrapper>
      </CellValueArea>
    </FormCell>
  )
}

const InputWrapper = styled('div')({
  width: '50%',
})
const StyledDivider = styled(props => (
  <Divider {...props} orientation="vertical" />
))({
  margin: '0 4px',
})
