import _ from 'lodash'
import {
  ExcelRow,
  GridOptions,
  ProcessCellForExportParams,
} from 'ag-grid-community'
import { DateTerm } from '../../../../utils/date'
import { useCallback, useMemo } from 'react'
import { intl } from '../../../../i18n'
import { CustomEnumValueDetail } from '../../../../lib/functions/customEnumValue'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import {
  ActualWorkingHoursRow,
  StandardWorkingInfo,
  convertBreakTime,
  formatHour,
} from '../ActualWorkingHours'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import DateVO from '../../../../vo/DateVO'

const EXCEL_COLUMN_START_TIME: string = 'D'
const EXCEL_COLUMN_END_TIME: string = 'E'
const EXCEL_COLUMN_BREAK_TIME: string = 'F'
const EXCEL_COLUMN_WORKED_TIME: string = 'G'
const EXCEL_START_INDEX_OFFSET: number = 3 // header row + next

const EXCEL_CELL_SUM_ACTUAL_DAYS: string = 'E4'
const EXCEL_CELL_SUM_SCHEDULE_DAYS: string = 'D4'
const EXCEL_CELL_SUM_ACTUAL_TIME: string = 'E5'
const EXCEL_CELL_SUM_SCHEDULE_TIME: string = 'D5'
const SUMMARY_START_INDEX: number = 9

const SECOND_TO_EXCEL_SERIAL_VALUE: number = 3600 * 24

type ExcelHeaderContents = {
  dateTerm: DateTerm
  userName: string | undefined
  standardInfo: StandardWorkingInfo
}

export const useExcelExport = (
  gridOptions: GridOptions,
  headerContents: ExcelHeaderContents
): (() => void) => {
  const getTotalValueFormula = useCallback(
    (column: string, startRow: number, endRow: number): string => {
      return `=SUM(${column}${startRow}:${column}${endRow})`
    },
    []
  )

  const convertSlashFormat = useCallback((value: string | undefined) => {
    if (!value) return ''
    const date = new DateVO(value)
    return date.format('YYYY/M/D')
  }, [])

  const { fileNamePrefix, prependContent, outputNumOfDays } = useMemo(() => {
    const fileNamePrefix = headerContents.userName
      ? intl.formatMessage(
          {
            id: 'actualWorkingHours.excel.name.prefix.format',
          },
          {
            name: headerContents.userName,
          }
        )
      : intl.formatMessage({
          id: 'actualWorkingHours.excel.title',
        })

    let outputNumOfDays = 0
    if (
      !_.isEmpty(headerContents.dateTerm.startDate) &&
      !_.isEmpty(headerContents.dateTerm.endDate)
    ) {
      const start = dateVoService.construct(headerContents.dateTerm.startDate)
      const end = dateVoService.construct(headerContents.dateTerm.endDate)
      outputNumOfDays = Math.abs(dateVoService.diff(start, end)) + 1
    }
    const prependContent: ExcelRow[] = [
      {
        cells: [],
      },
      {
        cells: [
          {
            data: {
              value: '',
              type: 'String',
            },
          },
          {
            data: {
              value: intl.formatMessage({
                id: 'actualWorkingHours.excel.user',
              }),
              type: 'String',
            },
            mergeAcross: 1,
          },
          {
            data: {
              value: headerContents.userName || '',
              type: 'String',
            },
          },
        ],
      },
      {
        cells: [
          {
            data: {
              value: '',
              type: 'String',
            },
          },
          {
            data: {
              value: intl.formatMessage({
                id: 'actualWorkingHours.header.term',
              }),
              type: 'String',
            },
            mergeAcross: 1,
          },
          {
            styleId: 'justifyRight',
            data: {
              value: convertSlashFormat(headerContents.dateTerm.startDate),
              type: 'String',
            },
          },
          {
            styleId: 'centerCell',
            data: {
              value: '～',
              type: 'String',
            },
          },
          {
            styleId: 'justifyRight',
            data: {
              value: convertSlashFormat(headerContents.dateTerm.endDate),
              type: 'String',
            },
          },
        ],
      },
      {
        cells: [
          {
            data: {
              value: '',
              type: 'String',
            },
          },
          {
            mergeAcross: 1,
            data: {
              value: intl.formatMessage({
                id: 'actualWorkingHours.header.days',
              }),
              type: 'String',
            },
          },
          {
            data: {
              value: String(headerContents.standardInfo.standardWorkDays),
              type: 'Number',
            },
          },
          {
            data: {
              value: `=COUNTIFS(${EXCEL_COLUMN_START_TIME}${SUMMARY_START_INDEX}:${EXCEL_COLUMN_START_TIME}${
                outputNumOfDays + SUMMARY_START_INDEX - 1
              },"<>",${EXCEL_COLUMN_END_TIME}${SUMMARY_START_INDEX}:${EXCEL_COLUMN_END_TIME}${
                outputNumOfDays + SUMMARY_START_INDEX - 1
              },"<>")`,
              type: 'f',
            },
          },
          {
            data: {
              value: `=${EXCEL_CELL_SUM_ACTUAL_DAYS}-${EXCEL_CELL_SUM_SCHEDULE_DAYS}`,
              type: 'f',
            },
          },
        ],
      },
      {
        cells: [
          {
            data: {
              value: '',
              type: 'String',
            },
          },
          {
            mergeAcross: 1,
            data: {
              value: intl.formatMessage({
                id: 'actualWorkingHours.header.hours',
              }),
              type: 'String',
            },
          },
          {
            styleId: 'justifyRight',
            data: {
              value: formatHour(headerContents.standardInfo.standardWorkHours),
              type: 'String',
            },
          },
          {
            styleId: 'formulaTimeCell',
            data: {
              value: getTotalValueFormula(
                EXCEL_COLUMN_WORKED_TIME,
                SUMMARY_START_INDEX,
                outputNumOfDays + SUMMARY_START_INDEX - 1
              ),
              type: 'f',
            },
          },
          {
            styleId: 'formulaTimeCell',
            data: {
              value: `=TEXT(ABS(TEXT(${EXCEL_CELL_SUM_ACTUAL_TIME}, "[h]:mm")-TEXT(${EXCEL_CELL_SUM_SCHEDULE_TIME}, "[h]:mm")),IF(VALUE(TEXT(${EXCEL_CELL_SUM_ACTUAL_TIME},"[h]:mm")-TEXT(${EXCEL_CELL_SUM_SCHEDULE_TIME},"[h]:mm"))<0,"-[h]:mm","[h]:mm"))`,
              type: 'f',
            },
          },
        ],
      },
      {
        cells: [],
      },
    ]
    return { fileNamePrefix, prependContent, outputNumOfDays }
  }, [convertSlashFormat, getTotalValueFormula, headerContents])

  const workDayTypeNames = useMemo(() => {
    if (!gridOptions.context) return []

    const types: CustomEnumValueDetail[] =
      gridOptions.context['workingHoursType']
    if (!types) return []
    const names = types
      .filter(
        t => t.value && ['ATTENDANCE', 'PAID_LEAVE_HALF'].includes(t.value)
      )
      .map(t => getLabel(t.nameI18n) ?? t.name)
    return names
  }, [gridOptions.context])

  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (
        !params.node ||
        params.node.rowIndex === null ||
        _.isEmpty(workDayTypeNames)
      ) {
        return undefined
      }
      const rowIndex =
        prependContent.length + EXCEL_START_INDEX_OFFSET + params.node.rowIndex
      const maxRowIndex =
        prependContent.length + EXCEL_START_INDEX_OFFSET + outputNumOfDays - 1

      const colId: string = params.column.getColId()
      if (colId === 'actual.breakTime') {
        if (params.node.data.isTotal) {
          return getTotalValueFormula(
            EXCEL_COLUMN_BREAK_TIME,
            rowIndex,
            maxRowIndex
          )
        } else if (params.node?.data) {
          // TODO: If you put a VALUE expression in the total column, the range will be #SPILL and an error will occur
          const row = params.node.data as ActualWorkingHoursRow
          if (!row.actual?.breakTime) return ''
          const breakTime = convertBreakTime(row)
          return (breakTime / SECOND_TO_EXCEL_SERIAL_VALUE).toString()
        }
      } else if (colId === 'actual.workedTime') {
        if (params?.node?.data.isTotal) {
          return getTotalValueFormula(
            EXCEL_COLUMN_WORKED_TIME,
            rowIndex,
            maxRowIndex
          )
        }
        const startTimeCell = EXCEL_COLUMN_START_TIME + rowIndex
        const endTimeCell = EXCEL_COLUMN_END_TIME + rowIndex
        const breakTimeCell = EXCEL_COLUMN_BREAK_TIME + rowIndex
        const endTime = `IF(VALUE(${endTimeCell}) < VALUE(${startTimeCell}), ${endTimeCell} + "24:00", ${endTimeCell})`
        // If you use the LET function, you will get an error with a spill avoidance "@"
        const formula = `=IF(OR(ISBLANK(${startTimeCell}), ISBLANK(${endTimeCell})), "", 
          IF((${endTime} - ${startTimeCell} - ${breakTimeCell}) > 0, ${endTime} - ${startTimeCell} - ${breakTimeCell}, ""))`
        return formula
      }
      return undefined
    },
    [
      prependContent.length,
      workDayTypeNames,
      outputNumOfDays,
      getTotalValueFormula,
    ]
  )

  const onExportExcel = useCallback(() => {
    const visibleColIds =
      gridOptions.columnApi
        ?.getColumnState()
        .filter(state => !state.hide)
        .map(state => state.colId) || []
    const colIds = (gridOptions.columnApi?.getColumns() ?? [])
      .filter(
        column =>
          column.getColId() === 'blank' ||
          (!['uuid'].includes(column.getColId()) &&
            visibleColIds.includes(column.getColId()))
      )
      .sort((colA, colB) => colA.getInstanceId() - colB.getInstanceId())
    if (!colIds || colIds.length === 0) return

    exportExcel({
      fileNamePrefix,
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      prependContent,
      getCustomExportValue: customExportValues,
    })
  }, [gridOptions, fileNamePrefix, prependContent, customExportValues])

  return onExportExcel
}
