import DateInput from '.'
import { DateVO } from '../../../../../domain/value-object/DateVO'
import dateVoAdaptor from '../../../../../services/compatible-adaptors/dateVoAdaptor'
import ClassDateVO from '../../../../../vo/DateVO'

interface DateInputProps {
  classes?: any
  className?: string
  placeholder?: string
  value: ClassDateVO | undefined
  onChange: (value: ClassDateVO | undefined) => void
  isClearable?: boolean
  disabled?: boolean
  enterHandler?: () => void
}

const DateInputWithClassVO = ({
  value,
  onChange,
  ...other
}: DateInputProps) => {
  const adaptedValue = value && dateVoAdaptor.fromClassDateVOtoType(value)
  const adaptedOnChange = (v: DateVO | undefined) => {
    const classValue = v && dateVoAdaptor.fromTypeDateVOtoClass(v)
    onChange(classValue)
  }
  return (
    <DateInput value={adaptedValue} onChange={adaptedOnChange} {...other} />
  )
}

export default DateInputWithClassVO
