import { styled } from '@mui/material'
import { ColumnQuickFilterKey } from '../..'
import { intl } from '../../../../../i18n'
import ColumnFilterButton from '../../../../components/buttons/ColumnFilterButtonGroup/ColumnFilterButton'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../../components/buttons/ToolbarFilterButtonGroup'
import { ToolbarToggleValue } from '../../../../components/toolbars/Toolbar/ToolbarToggle'
import {
  Toolbar,
  ToolbarDivider,
} from '../../../../components/toolbars/Toolbar'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

type Props = OwnProps & ToolbarFilterGroupProps

const TagsToolbar = ({
  toolbar,
  filteredColumns,
  sortedColumns,
  onDeletedFilterColumn,
  onChangeColumnFilter,
}: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === toolbar && (
        <>
          <ToolbarGroup>
            <ToolbarFilterButtonGroup
              filteredColumns={filteredColumns}
              sortedColumns={sortedColumns}
              onDeletedFilterColumn={onDeletedFilterColumn}
            />
          </ToolbarGroup>
          <ToolbarDivider />
          <ToolbarGroup>
            <ColumnFilterButton
              title={intl.formatMessage({
                id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
              })}
              onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)}
            />
          </ToolbarGroup>
        </>
      )}
    </Toolbar>
  )
}

export default TagsToolbar

export const ToolbarGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})
