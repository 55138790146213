import _ from 'lodash'
import React from 'react'
import { styled } from '@mui/system'
import Dropzone from 'react-dropzone'
import {
  Attachment,
  DEFAULT_MAX_FILE_SIZE_MB,
  deleteFile,
  exceedMaxSize,
  FileType,
  isAttached,
  uploadFiles,
} from '../../../../utils/attachment'
import store, { AllState } from '../../../../store'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import AttachmentMenu from '../../AttachmentMenu'
import AlertDialog from '../../../components/dialogs/AlertDialog'
import MarkupViewer from '../../../components/viewers/MarkupViewer'
import Loading from '../../../components/process-state-notifications/Loading'
import { intl } from '../../../../i18n'
import AddAttachmentButton from '../../../components/buttons/AddAttachmentButton'
import { connect } from 'react-redux'
import AttachmentList from '../AttachmentList'
import mime from 'mime'

// Styles
const RootContainer = styled('div')({
  display: 'flex',
  height: '100%',
  alignItems: 'stretch',
  flexDirection: 'column',
  borderRadius: 5,
})
const DropzoneContent = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  position: 'relative',
})
const TableContent = styled('div')({
  width: '100%',
  overflowX: 'scroll',
})
const FileDropArea = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '52px',
})
const FileDrop = styled('div')({
  width: '60%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '52px',
  border: '1px dashed #DDDDDD',
})

interface Props extends WrappedComponentProps {
  attachments: Attachment[]
  setValue: Function
  accept: string[]
  maxSize?: number
  onUploadAttachments?: (attachments: Attachment[]) => Promise<void> | void
  onDeleteAttachments?: (attachments: Attachment[]) => Promise<void> | void
  currentExternalId?: string
}

interface State {
  attachments: Attachment[]
  anchorEl?: any
  openAlert: boolean
  selectedIndex?: number
  isLoading: boolean
}

class AttachmentEditor extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      attachments: props.attachments,
      openAlert: false,
      isLoading: false,
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (!_.isEqual(prevProps.attachments, this.props.attachments)) {
      this.setState({ attachments: this.props.attachments })
    }
  }

  private dropAccepted = async acceptedFiles => {
    if (
      exceedMaxSize(
        acceptedFiles,
        this.props.maxSize || DEFAULT_MAX_FILE_SIZE_MB
      )
    ) {
      return
    }
    this.showLoading()
    const targetFiles: any[] = []
    let canUpload = true
    acceptedFiles.forEach(f => {
      if (
        !isAttached(
          this.props.currentExternalId!,
          { name: f.path, url: '', source: FileType.LOCAL },
          this.props.attachments
        )
      ) {
        targetFiles.push(f)
      } else {
        canUpload = false
      }
    })

    if (canUpload) {
      let newAttachments = [] as Attachment[]
      try {
        newAttachments = await uploadFiles(targetFiles)
      } catch (err) {
        this.hideLoading()
        throw err
      }
      if (this.props.onUploadAttachments) {
        await this.props.onUploadAttachments(newAttachments)
      }
      const attachments = [
        ...newAttachments,
        ...this.state.attachments.concat(),
      ]
      this.setState({ attachments }, () => {
        this.props.setValue(attachments)
        store.dispatch(
          addScreenMessage(store.getState().appFunction.currentExternalId!, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({
              id: 'attachment.title.uploadComplete',
            }),
            text: intl.formatMessage(
              { id: 'attachment.message.uploadComplete' },
              { attach: attachments.map(v => v.name).join('\n') }
            ),
          })
        )
      })
    }
    this.hideLoading()
  }

  private dropRejected = rejectedFiles => {
    let message = intl.formatMessage({
      id: 'attachment.message.fileCannotUpload',
    })
    for (let i = 0; i < rejectedFiles.length; i++) {
      message = message + '\n' + rejectedFiles[i].path
    }
    store.dispatch(
      addScreenMessage(store.getState().appFunction.currentExternalId!, {
        type: MessageLevel.WARN,
        title: intl.formatMessage({ id: 'attachment.title.fileCannotUpload' }),
        text: message,
      })
    )
  }

  private openAlert = index => {
    this.setState({
      openAlert: true,
      selectedIndex: index,
    })
  }

  private closeAlert = () => {
    this.setState({
      openAlert: false,
      selectedIndex: undefined,
    })
  }

  private removeItem = async () => {
    if (this.state.selectedIndex === undefined) return
    this.showLoading()
    const attachments = this.state.attachments.concat()
    const removedItem = attachments.splice(this.state.selectedIndex, 1)[0]
    await deleteFile(removedItem.url)
    if (this.props.onDeleteAttachments) {
      await this.props.onDeleteAttachments([removedItem])
    }
    this.hideLoading()
    store.dispatch(
      addScreenMessage(store.getState().appFunction.currentExternalId!, {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'attachment.title.deleteComplete' }),
        text: intl.formatMessage(
          { id: 'attachment.message.deleteComplete' },
          { removedItem: removedItem.name }
        ),
      })
    )
    this.setState({ attachments }, () => {
      this.props.setValue(attachments)
    })
    this.closeAlert()
  }

  private closeMenu = () => {
    this.setState({ anchorEl: null })
  }

  private addAttachment = async (attachments: Attachment[]): Promise<void> => {
    this.showLoading()
    const added = [...attachments, ...this.state.attachments.concat()]
    if (this.props.onUploadAttachments) {
      await this.props.onUploadAttachments(attachments)
    }
    this.setState({ attachments: added }, () => {
      this.props.setValue(added)
      this.hideLoading()
      store.dispatch(
        addScreenMessage(store.getState().appFunction.currentExternalId!, {
          type: MessageLevel.SUCCESS,
          title: intl.formatMessage({ id: 'attachment.title.uploadComplete' }),
          text: intl.formatMessage(
            { id: 'attachment.message.uploadComplete' },
            { attachments: attachments.map(v => v.name).join('\n') }
          ),
        })
      )
    })
  }

  private showLoading = () => {
    this.setState({ isLoading: true })
  }

  private hideLoading = () => {
    this.setState({ isLoading: false })
  }

  render = () => {
    return (
      <RootContainer>
        <Loading isLoading={this.state.isLoading} />
        <Dropzone
          onDropAccepted={this.dropAccepted}
          onDropRejected={this.dropRejected}
          multiple={true}
          accept={this.props.accept?.map(v => mime.getType(v)).join(',')}
        >
          {({ getRootProps, getInputProps }) => (
            <DropzoneContent
              {...getRootProps({
                onClick: e => {
                  e.stopPropagation()
                },
              })}
            >
              <input {...getInputProps()} />
              <TableContent>
                <AttachmentList
                  attachments={this.state.attachments}
                  onDelete={(attachments, index) => this.openAlert(index)}
                />
              </TableContent>
              <FileDropArea>
                <FileDrop>
                  <span>
                    {this.props.intl.formatMessage({
                      id: 'attachment.fileDropAttach',
                    })}
                  </span>
                </FileDrop>
              </FileDropArea>
              <AddAttachmentButton
                onClick={e => this.setState({ anchorEl: e.currentTarget })}
              />
            </DropzoneContent>
          )}
        </Dropzone>
        <AttachmentMenu
          attachments={this.state.attachments}
          anchorEl={this.state.anchorEl}
          closeHandler={this.closeMenu}
          accept={this.props.accept}
          maxSize={this.props.maxSize}
          addAttachmentHandler={this.addAttachment}
        />
        <AlertDialog
          isOpen={this.state.openAlert}
          message={this.props.intl.formatMessage({
            id: 'attachment.delete.message',
          })}
          extraContent={
            this.state.selectedIndex !== undefined &&
            this.state.attachments[this.state.selectedIndex] ? (
              <MarkupViewer
                content={this.state.attachments[this.state.selectedIndex].name}
              />
            ) : (
              ''
            )
          }
          submitButtonTitle={this.props.intl.formatMessage({ id: 'dialog.ok' })}
          submitHandler={this.removeItem}
          closeButtonTitle={this.props.intl.formatMessage({
            id: 'dialog.cancel',
          })}
          closeHandler={this.closeAlert}
        />
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  currentExternalId: state.appFunction.currentExternalId,
})

export default connect(mapStateToProps)(injectIntl(AttachmentEditor))
