import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '../..'
import {
  isAmountColumn,
  profitLossItemsTreeGridOptions,
  refreshDynamicColumnDef,
  refreshPinnedRow,
} from '../gridOptions'
import { useProfitLossItemData } from '../hooks/profitLossItemsData'
import { StoredPageState, usePageState } from '../hooks/pageState'
import {
  AccountCategory,
  BudgetResultType,
  BudgetResultTypeType,
  ProfitLossItemRow,
  RowFilterKey,
  RowFilterKeyType,
  RowStyle,
  RowStyleType,
  createNewProfitLossItemRow,
} from '../profitLossItems'
import store from '../../../../store'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../../store/messages'
import { intl } from '../../../../i18n'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import { useExportExcel, useExportExcelSimple } from '../hooks/excel'
import '../../../../styles/agGrid.scss'
import { useKeyBind } from '../../../hooks/useKeyBind'
import { KEY_SAVE } from '../../../model/keyBind'
import _ from 'lodash'
import { useBulkSheetState } from '../hooks/bulkSheetState'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import SavedUIStateDialog from '../../../components/dialogs/SavedUIStateDialog'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { SavedUIState } from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import {
  InputError,
  addInputError,
} from '../../../containers/BulkSheetView/lib/validation'
import validator from '../../../containers/meta/validator'
import BoolExpression from '../../../../utils/boolExpression'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import { DateTerm } from '../../../../utils/date'
import { useMasterData } from '../hooks/masterData'
import {
  AddSize,
  addMultipleRows,
  addRow,
  deleteRow,
  setAllBudgetAmountToResult,
  setSingleBudgetAmountToResult,
} from '../gridOptions/contextMenu'
import { FinancialStatementAccountsType } from '../../LedgerAccounts/ledgerAccounts'
import AddRowCountInputDialog from '../../../containers/BulkSheet/AddRowCountInputDialog'
import {
  collapseAllRows,
  expandAllRows,
} from '../../../containers/BulkSheetView/gridOptions/contextMenu'
import { ProjectDetail } from '../../../../lib/functions/project'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'
import { useColumnSetting } from '../../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ProfitLossItemHeader, {
  ColumnQuickFilterKey,
  RowFilterProps,
} from '../components/Header'
import ProfitLossItemsSimpleView from './ProfitLossItemsSimpleView'
import { profitLossItemsSimpleGridOptions } from '../gridOptions/profitLossItemsSimpleGridOptions'
import {
  addSimpleRow,
  setSimpleAllBudgetAmountToResult,
  setSimpleSingleBudgetAmountToResult,
} from '../gridOptions/simpleContextMenu'
import ProfitLossItemsTreeView from './ProfitLossItemsTreeView'

export const CancelConfirmState = {
  OpenByReload: 'OPEN_BY_RELOAD',
  OpenByAccountCategory: 'OPEN_BY_ACCOUNT_CATEGORY',
  OpenByRowStyle: 'OPEN_BY_ROW_STYLE',
  Close: 'CLOSE',
} as const

export type CancelConfirmStateType =
  (typeof CancelConfirmState)[keyof typeof CancelConfirmState]

interface OwnProps {
  currentProject: ProjectDetail
}

type Props = OwnProps & PageProps

let localRowFilterTypes: BudgetResultTypeType[] = []

const ProfitLossItemsViewBase = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [cancelConfirmState, setCancelConfirmState] =
    useState<CancelConfirmStateType>(CancelConfirmState.Close)
  const [openUiState, setOpenUiState] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )
  const [openAddMultipleRows, setOpenAddMultipleRows] = useState(false)
  const [paramsForAddMultiple, setParamsForAddMultiple] = useState<any>()

  const { data, fetchRecords, setData, save, summaryRows } =
    useProfitLossItemData()
  const { fetchMasterRecords, masterTree } = useMasterData()

  const columnSetting = useColumnSetting()
  const pageState = usePageState(props.uuid)
  const prevPageState = useRef<StoredPageState>({
    startDate: '',
    endDate: '',
    accountCategory: undefined,
    rowHeight: ROW_HEIGHT.SMALL,
    rowStyle: RowStyle.Tree,
  })

  const gridOptions = useMemo(() => {
    return profitLossItemsTreeGridOptions()
  }, [])

  const simpleGridOptions = useMemo(() => {
    return profitLossItemsSimpleGridOptions()
  }, [])

  const onExportExcel = useExportExcel(gridOptions)
  const onExportSimpleExcel = useExportExcelSimple(simpleGridOptions)
  const bulkSheetState = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.ProfitLossItems
  )

  const refreshAll = useCallback(
    async (
      startDate: string,
      endDate: string,
      accountCategory: string | undefined
    ) => {
      if (!accountCategory) return
      try {
        setLoading(true)
        await fetchRecords(
          props.currentProject.uuid,
          startDate,
          endDate,
          accountCategory
        )
        bulkSheetState.restoreExpandedRows()
        store.dispatch(doNotRequireSave())
      } finally {
        gridOptions.context = {
          ...gridOptions.context,
          draggableNodeId: undefined,
          errors: new InputError(),
          accountCategory,
        }
        simpleGridOptions.context = {
          ...simpleGridOptions.context,
          errors: new InputError(),
          accountCategory,
        }
        setLoading(false)
      }
    },
    [
      bulkSheetState,
      fetchRecords,
      gridOptions,
      simpleGridOptions,
      props.currentProject,
    ]
  )

  const onChangeDateTerm = useCallback(
    (term: DateTerm) => {
      if (!!term.startDate) {
        pageState.setStartDate(term.startDate)
      }
      if (!!term.endDate) {
        pageState.setEndDate(term.endDate)
      }
      const states = gridOptions.columnApi
        ?.getColumns()
        ?.filter(v => {
          if (v.isVisible()) return false
          const parent = v.getParent()
          if (!parent || !isAmountColumn(parent.getGroupId())) return false
          return dateVoService.isBetween(
            dateVoService.construct(v.getColId()),
            dateVoService.construct(term.startDate),
            dateVoService.construct(term.endDate),
            '[]'
          )
        })
        .map(v => ({
          colId: v.getColId(),
          hide: false,
        }))
      gridOptions.columnApi?.applyColumnState({ state: states })
    },
    [gridOptions.columnApi, pageState]
  )

  const prevData = useRef<ProfitLossItemRow[]>([])
  prevData.current = data
  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const execReload = useCallback(async () => {
    refreshAll(
      pageState.startDate,
      pageState.endDate,
      pageState.accountCategory
    )
  }, [
    pageState.accountCategory,
    pageState.endDate,
    pageState.startDate,
    refreshAll,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      setCancelConfirmState(CancelConfirmState.OpenByReload)
    } else {
      execReload()
    }
  }, [execReload, hasChanged])

  const validateBeforeSubmit = useCallback(() => {
    if (
      !gridOptions.api ||
      !gridOptions.columnApi ||
      !gridOptions.context?.errors
    ) {
      return
    }
    const errors = new InputError()
    const dupeMsg = intl.formatMessage(
      { id: 'bulksheet.error.duplicate.unique.column.data' },
      {
        fieldName: intl.formatMessage({
          id: 'profitLossItems.duplicate.pattern',
        }),
      }
    )
    const colIds = gridOptions.columnApi.getColumnState().map(col => col.colId)
    const items = data.filter(v => !v.body.type)
    items.forEach(row => {
      const node = gridOptions.api!.getRowNode(row.uuid)
      if (!node) return
      colIds.forEach(colId => {
        const value = gridOptions.api?.getValue(colId, node)
        const colDef = gridOptions.columnApi?.getColumn(colId)?.getColDef()
        if (!colDef || !colDef.cellRendererParams || !!colDef.field) {
          return
        }
        const uiMeta = {
          requiredIf: BoolExpression.of(true),
        }

        const error = validator
          .validate(value, row, uiMeta as FunctionProperty, () => undefined)
          ?.getMessage()
        if (error) {
          addInputError(errors, node.id, error, colDef)
        }
        if (colDef.field === 'body.name') {
          const isDupe = items.some(
            v =>
              (!!row.editedData || row.added) &&
              v.uuid !== row.uuid &&
              v.body?.financialState?.uuid === row.body?.financialState?.uuid &&
              v.body?.generalLedger?.uuid === row.body?.generalLedger?.uuid &&
              v.body?.subsidiary?.uuid === row.body?.subsidiary?.uuid &&
              !!v.body?.name &&
              !!row.body?.name &&
              v.body?.name === row.body?.name
          )
          if (isDupe) {
            addInputError(errors, node.id, dupeMsg, colDef)
          }
        }
      })
    })
    gridOptions.context = {
      ...gridOptions.context,
      errors,
    }
  }, [data, gridOptions])

  const onSubmit = useCallback(async () => {
    if (!pageState.accountCategory) return
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()
      validateBeforeSubmit()
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }
      bulkSheetState.base.saveImmediately()
      const response = await save(
        props.currentProject.uuid,
        pageState.startDate,
        pageState.endDate,
        pageState.accountCategory
      )
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(props.uuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await execReload()
      }
    } finally {
      setLoading(false)
    }
  }, [
    bulkSheetState.base,
    execReload,
    gridOptions.api,
    gridOptions.context.errors,
    pageState.accountCategory,
    pageState.endDate,
    pageState.startDate,
    props.currentProject,
    props.uuid,
    save,
    validateBeforeSubmit,
  ])

  const isConditionChanged = useCallback(() => {
    if (
      !prevPageState.current ||
      prevPageState.current.accountCategory !== pageState.accountCategory ||
      prevPageState.current.startDate !== pageState.startDate ||
      prevPageState.current.endDate !== pageState.endDate ||
      prevPageState.current.rowStyle !== pageState.rowStyle
    ) {
      prevPageState.current = {
        accountCategory: pageState.accountCategory,
        startDate: pageState.startDate,
        endDate: pageState.endDate,
        rowHeight: pageState.rowHeight,
        rowStyle: pageState.rowStyle,
      }
      return true
    }
    return false
  }, [
    pageState.accountCategory,
    pageState.endDate,
    pageState.rowHeight,
    pageState.startDate,
    pageState.rowStyle,
  ])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = bulkSheetState.onFilterChanged(e)
      setFilteredColumns(filter)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      const sorted = bulkSheetState.onSortChanged(e)
      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedState[state.colId] = state
          }
        })
      const sortedList: SortedColumnState[] = sorted.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedState[col.colId]?.sort : null,
        }
      })
      setSortColumnsState(sortedList)
    },
    [bulkSheetState, gridOptions.columnApi]
  )

  const onFirstDataRendered = useCallback(
    e => {
      bulkSheetState.onFirstDataRendered(e)
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else {
        setOpenUiState(true)
      }
    },
    [gridOptions.api, gridOptions.columnApi]
  )

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [gridOptions.api])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions.columnApi]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions.columnApi])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions.columnApi]
  )

  const closeUiStateDialog = useCallback(() => {
    setOpenUiState(false)
  }, [])

  const callbackSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      if (columnState) {
        bulkSheetState.base.saveColumnState(columnState)
        gridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        bulkSheetState.base.saveFilterState(filterState)
        gridOptions.api?.setFilterModel(filterState)
      }
      closeUiStateDialog()
    },
    [
      bulkSheetState.base,
      closeUiStateDialog,
      gridOptions.api,
      gridOptions.columnApi,
    ]
  )

  const pendingAccountCategory = useRef<string | undefined>()
  const pendingRowStyle = useRef<RowStyleType | undefined>()
  const setAccountCategory = useCallback(
    (value: string | undefined) => {
      setData([])
      pageState.setAccountCategory(value)
      gridOptions.context = {
        ...gridOptions.context,
        accountCategory: value,
      }
    },
    [setData, pageState, gridOptions]
  )

  const onChangeAccountCategory = useCallback(
    (value: string | undefined) => {
      if (hasChanged()) {
        pendingAccountCategory.current = value
        setCancelConfirmState(CancelConfirmState.OpenByAccountCategory)
      } else {
        pendingAccountCategory.current = undefined
        setAccountCategory(value)
      }
    },
    [hasChanged, setCancelConfirmState, setAccountCategory]
  )

  const onCancelConfirm = useCallback(
    (state: CancelConfirmStateType) => {
      if (CancelConfirmState.OpenByReload === state) {
        execReload()
      } else if (CancelConfirmState.OpenByAccountCategory === state) {
        setAccountCategory(pendingAccountCategory.current)
        pendingAccountCategory.current = undefined
      } else if (CancelConfirmState.OpenByRowStyle === state) {
        pageState.setRowStyle(pendingRowStyle.current)
        pendingRowStyle.current = undefined
      }
    },
    [execReload, pageState, setAccountCategory]
  )

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const menuItems = [
        addRow(
          params,
          data,
          pageState.accountCategory,
          masterTree,
          createNewProfitLossItemRow,
          {
            setData,
          }
        ),
        addMultipleRows(params, pageState.accountCategory, () => {
          setOpenAddMultipleRows(true)
          setParamsForAddMultiple(params)
        }),
        deleteRow(params, data, !pageState.accountCategory, { setData }),
        'separator',
        setSingleBudgetAmountToResult(params),
        setAllBudgetAmountToResult(params),
        'separator',
        expandAllRows(params),
        collapseAllRows(params),
      ]
      return menuItems
    },
    [pageState.accountCategory, data, masterTree, setData]
  )

  const simpleContextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const menuItems = [
        addSimpleRow(
          params,
          data,
          pageState.accountCategory,
          masterTree,
          createNewProfitLossItemRow,
          {
            setData,
          }
        ),
        addMultipleRows(params, pageState.accountCategory, () => {
          setOpenAddMultipleRows(true)
          setParamsForAddMultiple(params)
        }),
        deleteRow(params, data, !pageState.accountCategory, { setData }),
        'separator',
        setSimpleSingleBudgetAmountToResult(params),
        setSimpleAllBudgetAmountToResult(params),
      ]
      return menuItems
    },
    [data, masterTree, pageState.accountCategory, setData]
  )

  const closeAddMultipleRows = useCallback(() => {
    setOpenAddMultipleRows(false)
  }, [])

  const submitAddMultipleRows = useCallback(
    (count: number | undefined) => {
      try {
        if (!count) return
        const financialState =
          AccountCategory.OperatingRevenue === pageState.accountCategory
            ? masterTree.find(
                v =>
                  v.financialStatementAccountsType ===
                  FinancialStatementAccountsType.Sales
              )
            : undefined
        const generalLedger =
          !!financialState?.children && financialState?.children.length > 0
            ? financialState?.children[0]
            : undefined
        const result: ProfitLossItemRow[] = []
        for (let i = 0; i < count; i++) {
          const addItems = createNewProfitLossItemRow(
            financialState,
            generalLedger
          )
          result.push(...addItems)
        }
        const copyData = _.cloneDeep(data)
        const index = data.findIndex(
          v => v.uuid === paramsForAddMultiple.node?.data.uuid
        )
        if (pageState.rowStyle === RowStyle.Tree) {
          copyData.splice(index + AddSize, 0, ...result)
        } else if (pageState.rowStyle === RowStyle.Simple) {
          const spliceIndex = Math.floor(index / AddSize)
          copyData.splice((spliceIndex + 1) * AddSize, 0, ...result)
        }
        setData(copyData)
      } finally {
        closeAddMultipleRows()
      }
    },
    [
      pageState.accountCategory,
      pageState.rowStyle,
      masterTree,
      data,
      setData,
      paramsForAddMultiple,
      closeAddMultipleRows,
    ]
  )

  const updateLocalRowFilterTypes = useCallback(
    (types: RowFilterKeyType[] | undefined) => {
      localRowFilterTypes.splice(0)
      if (!types) return

      if (types.includes(RowFilterKey.Scheduled)) {
        localRowFilterTypes.push(BudgetResultType.Budget)
      }
      if (types.includes(RowFilterKey.Actual)) {
        localRowFilterTypes.push(BudgetResultType.Result)
      }
      if (types.includes(RowFilterKey.BudgetaryAmount)) {
        localRowFilterTypes.push(BudgetResultType.BudgetaryAmount)
      }
      if (types.includes(RowFilterKey.Forecast)) {
        localRowFilterTypes.push(BudgetResultType.Forecast)
      }
    },
    []
  )

  const onChangeRowFilter = useCallback(
    (values: RowFilterKeyType[]) => {
      if (pageState.setRowFilterTypes) {
        pageState.setRowFilterTypes(values)
      }
      updateLocalRowFilterTypes(values)
      if (pageState.rowStyle === RowStyle.Tree) {
        gridOptions.api?.onFilterChanged()
      } else {
        simpleGridOptions.api?.onFilterChanged()
        simpleGridOptions.api?.redrawRows()
      }
    },
    [gridOptions, pageState, simpleGridOptions, updateLocalRowFilterTypes]
  )

  const rowFilterProps: RowFilterProps[] = useMemo(() => {
    return [
      {
        enableKeys: [
          RowFilterKey.Forecast,
          RowFilterKey.BudgetaryAmount,
          RowFilterKey.Scheduled,
          RowFilterKey.Actual,
        ],
        values: pageState.rowFilterTypes || [],
        onChange: onChangeRowFilter,
      },
    ]
  }, [pageState.rowFilterTypes, onChangeRowFilter])

  const isExternalFilterPresent = useCallback(params => true, [])

  const rowFilterPass = useCallback((node: RowNode<ProfitLossItemRow>) => {
    if (
      localRowFilterTypes.includes(BudgetResultType.Forecast) &&
      !node.data?.body.type
    ) {
      return true
    }
    return (
      !!node.data?.body.type &&
      localRowFilterTypes.includes(node.data.body.type as BudgetResultTypeType)
    )
  }, [])

  const onChangeRowStyle = useCallback(
    (value: string | undefined) => {
      if (hasChanged()) {
        pendingRowStyle.current = value as RowStyleType
        setCancelConfirmState(CancelConfirmState.OpenByRowStyle)
      } else {
        pendingRowStyle.current = undefined
        pageState.setRowStyle(value as RowStyleType)
      }
    },
    [hasChanged, pageState, setCancelConfirmState]
  )

  const onClickExport = useCallback(() => {
    if (pageState.rowStyle === RowStyle.Tree) {
      onExportExcel()
    } else if (pageState.rowStyle === RowStyle.Simple) {
      onExportSimpleExcel()
    }
  }, [onExportExcel, onExportSimpleExcel, pageState.rowStyle])

  useEffect(() => {
    const api =
      pageState.rowStyle === RowStyle.Tree
        ? gridOptions.api
        : simpleGridOptions.api

    if (!api || !pageState?.initialized) return

    refreshDynamicColumnDef({
      api,
      dateTerm: {
        startDate: pageState.startDate,
        endDate: pageState.endDate,
      },
    })
  }, [
    pageState.initialized,
    pageState.startDate,
    pageState.endDate,
    pageState.rowStyle,
    gridOptions.api,
    simpleGridOptions.api,
  ])

  useEffect(() => {
    if (pageState.rowStyle === RowStyle.Tree) {
      gridOptions.api?.resetRowHeights()
    } else if (pageState.rowStyle === RowStyle.Simple) {
      simpleGridOptions.api?.resetRowHeights()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight, pageState.rowStyle])

  useEffect(() => {
    if (pageState.initialized && isConditionChanged()) {
      refreshAll(
        pageState.startDate,
        pageState.endDate,
        pageState.accountCategory
      )
    }
  }, [
    isConditionChanged,
    pageState.accountCategory,
    pageState.initialized,
    pageState.startDate,
    pageState.endDate,
    pageState.rowStyle,
    refreshAll,
  ])

  useEffect(() => {
    fetchMasterRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterTree && masterTree.length > 0) {
      gridOptions.context = {
        masters: masterTree,
      }
      simpleGridOptions.context = {
        masters: masterTree,
      }
    }
  }, [gridOptions, masterTree, simpleGridOptions])

  useEffect(() => {
    updateLocalRowFilterTypes(pageState.rowFilterTypes)
  }, [updateLocalRowFilterTypes, pageState])

  useEffect(() => {
    refreshPinnedRow(gridOptions.api)
  }, [gridOptions, data])

  useEffect(() => {
    refreshPinnedRow(simpleGridOptions.api)
  }, [simpleGridOptions, data])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    [data, save, pageState]
  )

  return (
    <PageArea>
      <ProfitLossItemHeader
        loading={loading}
        dateTerm={{
          startDate: pageState.startDate,
          endDate: pageState.endDate,
        }}
        onSearch={onChangeDateTerm}
        onSubmit={onSubmit}
        onReload={onReload}
        rowHeight={pageState.rowHeight}
        onChangeRowHeight={pageState.setRowHeight}
        onClickExport={onClickExport}
        accountCategory={pageState.accountCategory}
        onChangeAccountCategory={onChangeAccountCategory}
        rowFilters={rowFilterProps}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        sortColumnState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        columnSettingOpen={columnSetting.isOpen}
        onClickColumnSettingButton={columnSetting.toggle}
        rowStyle={pageState.rowStyle}
        onChangeRowStyle={onChangeRowStyle}
      />
      {pageState.rowStyle === RowStyle.Tree && (
        <ProfitLossItemsTreeView
          loading={loading}
          gridOptions={gridOptions}
          data={data}
          setData={setData}
          accountCategory={pageState.accountCategory}
          masterTree={masterTree}
          rowHeight={pageState.rowHeight}
          cancelConfirmState={cancelConfirmState}
          setCancelConfirmState={setCancelConfirmState}
          onCancelConfirm={onCancelConfirm}
          onColumnMoved={bulkSheetState.rememberColumnState}
          onColumnResized={bulkSheetState.rememberColumnState}
          onColumnVisible={bulkSheetState.rememberColumnState}
          onGridReady={bulkSheetState.onGridReady}
          onRowGroupOpened={bulkSheetState.onRowGroupOpened}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          summaryRows={summaryRows}
          createRow={createNewProfitLossItemRow}
          getContextMenuItems={contextMenu}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={rowFilterPass}
          applicationFunctionUuid={props.uuid}
          columnSetting={columnSetting}
          columnState={bulkSheetState.base.columnState}
          openSavedUiStateDialog={() => setOpenUiState(true)}
          initializeColumnState={() =>
            onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
          }
        />
      )}
      {pageState.rowStyle === RowStyle.Simple && (
        <ProfitLossItemsSimpleView
          loading={loading}
          gridOptions={simpleGridOptions}
          data={data}
          accountCategory={pageState.accountCategory}
          masterTree={masterTree}
          rowHeight={pageState.rowHeight}
          cancelConfirmState={cancelConfirmState}
          setCancelConfirmState={setCancelConfirmState}
          onCancelConfirm={onCancelConfirm}
          onColumnMoved={bulkSheetState.rememberColumnState}
          onColumnResized={bulkSheetState.rememberColumnState}
          onColumnVisible={bulkSheetState.rememberColumnState}
          onGridReady={bulkSheetState.onGridReady}
          onRowGroupOpened={bulkSheetState.onRowGroupOpened}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          summaryRows={summaryRows}
          createRow={createNewProfitLossItemRow}
          getContextMenuItems={simpleContextMenu}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={rowFilterPass}
          applicationFunctionUuid={props.uuid}
          columnSetting={columnSetting}
          columnState={bulkSheetState.base.columnState}
          openSavedUiStateDialog={() => setOpenUiState(true)}
          initializeColumnState={() =>
            onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
          }
        />
      )}
      <SavedUIStateDialog
        applicationFunctionUuid={props.uuid}
        open={openUiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.base.columnState,
          filterState: bulkSheetState.base.filterState,
        }}
        onSelect={callbackSelectUiState}
        onClose={closeUiStateDialog}
      />
      <AddRowCountInputDialog
        open={openAddMultipleRows}
        submitHandler={submitAddMultipleRows}
        closeHandler={closeAddMultipleRows}
      />
    </PageArea>
  )
}

export default ProfitLossItemsViewBase
