import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import { UpdateRefinementDeltaInput } from '../../view/pages/TicketsNew/dataManager/refinement'
import API, { APIResponse } from '../commons/api'

export class RefinementApi implements SingleSheetRepository {
  create(): Promise<APIResponse> {
    throw new Error('Not implemented.')
  }

  update(): Promise<APIResponse> {
    throw new Error('Can not use refinement.update method.')
  }

  updateDelta(props: UpdateRefinementDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'PUT',
      '/api/v1/projects/refinements/delta',
      props
    )
  }

  getDetail(props: { uuid: string }): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/refinements/detail',
      {
        uuid: props.uuid,
      },
      true
    )
  }

  getDetailByWbsItemUuid(props: { wbsItemUuid: string }): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/refinements/detail',
      {
        wbsItemUuid: props.wbsItemUuid,
      },
      true
    )
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/wbs_items/basic', {
      code,
    })
  }

  getByTicketListUuid = async (params: {
    ticketListUuid: string
  }): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/refinements/list',
      params
    )
  }

  updateBatchDelta = async (request: any): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/projects/refinements/delta/batch',
      request
    )
  }
}

export default new RefinementApi()
