import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  InputBase,
  Autocomplete as MuiAutocomplete,
  Popper,
  PopperProps,
  styled,
} from '@mui/material'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'
import { memo, ReactNode, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../hooks/useDebounce'
import { MenuItem, MenuItemText } from '../../menu'
import { ExpandMoreRounded, CloseRounded } from '@mui/icons-material'
import { colorPalette } from '../../../style/colorPallete'

type MultiAutocompleteProps<Entity extends ReferencedEntity> = {
  value: Entity[]
  onChange: (v: Entity[]) => void
  search: (searchText: string) => Promise<Entity[]>

  renderTags?: (
    value: Entity[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => ReactNode
}

export const MultiAutocomplete = <Entity extends ReferencedEntity>({
  value,
  onChange,
  search,
  renderTags,
}: MultiAutocompleteProps<Entity>) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [options, setOptions] = useState<Entity[]>(value || [])
  const [inputChangedBy, setInputChangedBy] =
    useState<AutocompleteInputChangeReason>('reset')
  const onChangeValue = useCallback(
    (_, v: Entity[], reason: AutocompleteChangeReason) => {
      onChange(v)
    },
    [onChange]
  )
  const onChangeInput = useCallback(
    (_, value: string, reason: AutocompleteInputChangeReason) => {
      setInputValue(value)
      setInputChangedBy(reason)
    },
    []
  )

  const onFocus = useCallback(async () => {
    const response = await search('')
    setOptions(response)
  }, [search])
  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    const fn = async () => {
      const response = await search(debouncedValue)
      setOptions(response)
    }
    if (['input', 'clear'].includes(inputChangedBy)) {
      fn()
    }
  }, [debouncedValue, inputChangedBy, search])
  const renderInput = useCallback((params: AutocompleteRenderInputParams) => {
    return <TextInput {...params.InputProps} inputProps={params.inputProps} />
  }, [])
  const renderOption = useCallback((props, value: Entity) => {
    return (
      <MenuItem {...props}>
        <MenuItemText>{value.name}</MenuItemText>
      </MenuItem>
    )
  }, [])
  const isOptionEqualToValue = useCallback((option: Entity, value: Entity) => {
    return option.uuid === value.uuid
  }, [])
  const getOptionLabel = useCallback((option: Entity) => {
    return option.name
  }, [])
  const PopperComponent = memo((props: PopperProps) => (
    <Popper {...props} placement="bottom-start" />
  ))
  return (
    <MuiAutocomplete
      // Use sx props since making Autocomplete styled component takes a bit effort.
      sx={{ width: '100%' }}
      multiple={true}
      value={value}
      onChange={onChangeValue}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      renderInput={renderInput}
      inputValue={inputValue}
      onInputChange={onChangeInput}
      onFocus={onFocus}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      disableCloseOnSelect={true}
      PopperComponent={PopperComponent}
      popupIcon={<PopupIcon />}
      clearIcon={<CloseIcon />}
      renderTags={renderTags}
    />
  )
}

const TextInput = styled(InputBase)({
  width: '100%',
  color: colorPalette.monotone[10],
})
const PopupIcon = styled(ExpandMoreRounded)({
  color: colorPalette.monotone[4],
})
const CloseIcon = styled(CloseRounded)({
  color: colorPalette.monotone[4],
  fontSize: '14px',
})
