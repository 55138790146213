import { FC, useMemo } from 'react'
import { ProjectDetail } from '../../../../lib/functions/project'
import { Box, styled, Typography } from '@mui/material'
import { intl } from '../../../../i18n'
import { MasterScheduleParameter } from '../hooks/useMasterScheduleParameter'
import { ProgressInformation } from './ProgressInformation'
import { ProgressReport } from './ProgressReport'
import { SprintReport } from './SprintReport'
import { ProgressDashboardParameter } from '../hooks/useProgressDashboardParameter'
import { FontWeight } from '../../../../styles/commonStyles'
import { TeamSelectOption } from '../model/team'
import {
  TeamName,
  TeamwiseComponent,
  TeamwiseInnerComponent,
} from '../components'
import { ProjectReportConfig } from '../model/config'

type Props = {
  project: ProjectDetail
  team: TeamSelectOption
} & ProjectReportConfig

export const ProgressGroup: FC<Props> = ({ team, ...other }: Props) => {
  const teamUuid = useMemo(
    () => (team.type === 'SPECIFIC_TEAM' ? team.value : undefined),
    [team]
  )
  return (
    <TeamwiseComponent>
      <TeamName>{team.name}</TeamName>
      <TeamwiseInnerComponent>
        <ProgressInformation {...other} teamUuid={teamUuid} />
        <ChartArea>
          <ProgressReport {...other} teamUuid={teamUuid} />
          <SprintReport {...other} teamUuid={teamUuid} />
        </ChartArea>
      </TeamwiseInnerComponent>
    </TeamwiseComponent>
  )
}

const ChartArea = styled('div')({
  height: '100%',
  display: 'flex',
  gap: '24px',
})
