import _ from 'lodash'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { TicketListBasic } from '../../../../lib/functions/ticketList'
import API, { APIResponse } from '../../../../lib/commons/api'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import { RiskApi } from '../../../../lib/functions/risk'
import { CreateTicketInput } from '../../../../lib/functions/ticket'
import { generateUuid } from '../../../../utils/uuids'
import { EntityExtensionValue } from '../../../containers/meta/entityExtension'
import { UpdateRiskDeltaInput } from '../../Risks/hooks/riskData'
import {
  Impact,
  ImpactType,
  Probability,
  ProbabilityType,
  RiskType,
  RiskTypeType,
  Strategy,
  StrategyType,
} from '../../Risks/risks'
import { TicketRow } from '../tickets'
import {
  createRowByTypeResponse,
  FetchResponseByTypeBase,
  GenerateCreateRequestParams,
  generateUpdateTagsRequest,
  generateUpdateWathersRequest,
  gernerateTicketDeleteRequest,
  generateCreateRequest,
  TicketDataManager,
  generateUpdateDeltaBatchRequest,
  GenerateUpdateRequestParams,
} from '.'
import { createNewTicketRow } from './general'

export interface RiskRow extends TicketRow {
  riskUuid: string
  riskLockVersion: number
  taskUuid: string
  riskType: RiskTypeType
  probability: ProbabilityType
  impact: ImpactType
  strategy: StrategyType
  score: number
}

export interface FetchRiskResponse extends FetchResponseByTypeBase {
  taskUuid: string
  riskType: string
  probability: string
  impact: string
  strategy: string
  score: number
}

type CreateRiskInput = {
  riskType: string
  probability: ProbabilityType
  impact: ImpactType
  strategy: StrategyType

  ticket: CreateTicketInput
  extensions?: EntityExtensionValue[]
}

const getUuid = (row: TicketRow): string => {
  const riskRow: RiskRow = row as RiskRow
  return riskRow.riskUuid
}

const getByTicketListUuid = async (params: {
  ticketListUuid: string
}): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/projects/risks/list', params)
}

const getDetail = async (params: { uuid: string }): Promise<APIResponse> =>
  new RiskApi().getDetail(params)

const createRowByResponse = (
  response: any,
  dailyWorkHours: number,
  monthlyWorkDays: number,
  extensionProperties: FunctionProperty[] | undefined
): RiskRow => {
  return {
    ...createRowByTypeResponse<RiskRow, FetchRiskResponse>(
      response,
      dailyWorkHours,
      monthlyWorkDays,
      extensionProperties
    ),
    riskUuid: response.uuid,
    riskLockVersion: response.lockVersion,
  }
}

const updateBatchDelta = async (request: any): Promise<APIResponse> => {
  return API.functional.request(
    'POST',
    '/api/v1/projects/risks/delta/batch',
    request
  )
}

const createUpdateDeltaBatchRequest = (
  projectUuid: string,
  originalData: TicketRow[],
  currentData: TicketRow[],
  deletedRows: TicketRow[]
) => {
  const changed = currentData.filter(v => v.added || v.edited)

  const added = generateCreateRequest<RiskRow, CreateRiskInput>(
    projectUuid,
    currentData,
    changed,
    ({
      row,
      ticketRequest,
      extensionInput,
    }: GenerateCreateRequestParams<RiskRow>): CreateRiskInput | undefined => {
      if (!row || !ticketRequest) return
      return {
        riskType: row.riskType,
        probability: row.probability,
        impact: row.impact,
        strategy: row.strategy,
        ticket: ticketRequest,
        extensions: extensionInput,
      }
    }
  )

  const edited = generateUpdateDeltaBatchRequest<RiskRow, UpdateRiskDeltaInput>(
    originalData,
    currentData,
    changed,
    ({
      originalRow,
      row,
      ticketRequest,
      extensionInput,
    }: GenerateUpdateRequestParams<RiskRow>):
      | UpdateRiskDeltaInput
      | undefined => {
      if (!row || !ticketRequest) return
      return {
        uuid: row.riskUuid,
        riskType: createDelta(originalRow.riskType, row.riskType),
        probability: createDelta(originalRow.probability, row.probability),
        impact: createDelta(originalRow.impact, row.impact),
        strategy: createDelta(originalRow.strategy, row.strategy),
        ticket: ticketRequest,
        extensions: extensionInput ?? [],
      }
    }
  )

  const deleted = gernerateTicketDeleteRequest(
    deletedRows,
    (row: TicketRow) => {
      const riskRow = row as RiskRow
      return {
        uuid: riskRow.riskUuid,
        lockVersion: riskRow.riskLockVersion,
      }
    }
  )
  const watchers = generateUpdateWathersRequest(originalData, changed)
  const tags = generateUpdateTagsRequest(originalData, changed)

  return {
    added,
    edited,
    deleted,
    watchers,
    tags,
  }
}

export const createNewRiskRow = (ticketList: TicketListBasic): RiskRow => {
  const newTicketRow = createNewTicketRow(ticketList)
  return {
    riskUuid: generateUuid(),
    riskLockVersion: 0,
    taskUuid: newTicketRow.wbsItem!.uuid!,
    riskType: RiskType.OMISSION,
    probability: Probability.MIDDLE,
    impact: Impact.MIDDLE,
    strategy: Strategy.AVOIDANCE,
    score: 0,
    ...newTicketRow,
  }
}

export const getRiskTicketDataManager = (): TicketDataManager => {
  return {
    getUuid,
    getByTicketListUuid,
    getDetail,
    createRowByResponse,
    updateBatchDelta,
    createUpdateDeltaBatchRequest,
    createNewRow: createNewRiskRow,
  }
}
