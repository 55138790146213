import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import { getUserName, projectCellValueFormatter } from '.'
import { intl } from '../../../../i18n'
import { ProjectDetail } from '../../../../lib/functions/project'
import { ALERT_COLOR } from '../../../../lib/functions/report'
import { ColumnType } from '../../../containers/commons/AgGrid'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { ResourcePlanProjectCellRenderer } from '../components/CellRenderer/ResourcePlanProjectCellRenderer'
import {
  ResourcePlanCPGroupRowsByProjectColRowBody,
  ResourcePlanCrossProjectType,
  ResourcePlanCrossProjectsRow,
  displayNameComparator,
} from '../ResourcePlanCrossProjects'
import {
  ROW_NUMBER_COL_DEF,
  INFORMATION_COLUMN_GROUP_DEF_BASE,
  MEMBER_COLUMN_GROUP_DEF_BASE,
  CHANGE_LOG_COLUMN_GROUP,
  WORKMONTHS_FIELD_PREFIX,
  iconShowCheckUser,
  generateTotalColumnDef,
  generateWorkMonthColDef,
  ALERT_UPPER_LIMIT_FOR_MAN_MONTH,
} from './commonColumnDef'
import { muiTheme } from '../../../../styles/muiTheme'

const isProjectRow = (params: ICellRendererParams | CellClassParams) => {
  const row: ResourcePlanCrossProjectsRow = params.data
  return row.type === ResourcePlanCrossProjectType.PROJECT
}

const getProjectParamsCellStyles = (
  params: CellClassParams<ResourcePlanCrossProjectsRow>
) => {
  if (!isProjectRow(params)) {
    return { color: 'transparent' }
  }
  return { color: 'inherit' }
}

export const AUTO_GROUP_COLUMN_DEF_GROUP_ROW_BY_PROJECT: ColDef = {
  field: 'body.project',
  headerName: intl.formatMessage({
    id: 'resourcePlan.crossProjects.project.name',
  }),
  width: 218,
  hide: false,
  pinned: true,
  suppressMovable: true,
  cellRendererParams: {
    suppressCount: true,
    suppressDoubleClickExpand: true,
    innerRenderer: ResourcePlanProjectCellRenderer,
    hideIcon: (params: ICellRendererParams) => !isProjectRow(params),
    hideOpneInNewButton: (params: ICellRendererParams) => !isProjectRow(params),
  },
  editable: false,
  comparator: (
    valueA: string | ProjectDetail,
    valueB: string | ProjectDetail
  ): number => {
    const strA: string = projectCellValueFormatter(valueA)
    const strB: string = projectCellValueFormatter(valueB)
    return strA.localeCompare(strB)
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: {
    getValue: value => projectCellValueFormatter(value),
    getLabel: value => projectCellValueFormatter(value),
  },
  cellStyle: getProjectParamsCellStyles,
}

const getProjectUuid = (row: ResourcePlanCrossProjectsRow) => {
  const rowBody = row?.body as ResourcePlanCPGroupRowsByProjectColRowBody
  return rowBody?.project?.uuid
}

export const COLUMN_DEF_GROUP_ROW_BY_PROJECT:
  | (
      | ColDef<ResourcePlanCrossProjectsRow>
      | ColGroupDef<ResourcePlanCrossProjectsRow>
    )[]
  | null = [
  ROW_NUMBER_COL_DEF,
  {
    ...INFORMATION_COLUMN_GROUP_DEF_BASE,
    children: [
      {
        field: 'body.project.code',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project.code',
        }),
        width: 120,
        hide: true,
        lockPosition: true,
        pinned: true,
        floatingFilter: true,
        filter: 'clientSideTextFilter',
        cellStyle: getProjectParamsCellStyles,
      },
      {
        field: 'body.project.division',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project.division',
        }),
        width: 110,
        lockPosition: true,
        pinned: true,
        type: [ColumnType.autocomplete],
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
        cellStyle: getProjectParamsCellStyles,
      },
      {
        field: 'body.project.projectManager',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project.pm',
        }),
        hide: true,
        lockPosition: true,
        pinned: true,
        cellRenderer: IconCellRenderer,
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          labelField: 'body.project.projectManager.name',
          iconUrlField: 'body.project.projectManager.iconUrl',
          iconShowCheckField: 'type',
          iconShowCheck: (type: string): boolean => {
            return type !== ResourcePlanCrossProjectType.PROJECT ? false : true
          },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
        filterParams: {
          getValue: value => getUserName(value),
          getLabel: value => getUserName(value),
        },
        cellStyle: getProjectParamsCellStyles,
      },
      {
        field: 'body.project.status',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project.status',
        }),
        hide: true,
        lockPosition: true,
        pinned: true,
        cellRenderer: DefaultCellRenderer,
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
        filterParams: {
          getValue: value => value,
          getLabel: value => value,
        },
        cellStyle: getProjectParamsCellStyles,
      },
      {
        field: 'body.project.displayName',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.project.name',
        }),
        width: 120,
        hide: true,
        pinned: true,
        sortable: false,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        valueGetter: params => {
          const row: ResourcePlanCrossProjectsRow | undefined = params.data
          const rowBody = params.data
            ?.body as ResourcePlanCPGroupRowsByProjectColRowBody
          if (row?.isTotal) return ''
          return rowBody?.project?.displayName || ''
        },
      },
    ],
  },
  {
    ...MEMBER_COLUMN_GROUP_DEF_BASE,
    children: [
      {
        field: 'body.user',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.name',
        }),
        width: 124,
        pinned: true,
        cellRendererSelector: (params: ICellRendererParams) => {
          const row: ResourcePlanCrossProjectsRow = params.data
          if (row.type === ResourcePlanCrossProjectType.MEMBER) {
            return {
              component: IconCellRenderer,
            }
          } else {
            return {
              component: DefaultCellRenderer,
            }
          }
        },
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          labelField: 'body.user.name',
          iconUrlField: 'body.user.iconUrl',
          iconShowCheckField: 'type',
          iconShowCheck: iconShowCheckUser,
        },
        valueGetter: (params: ValueGetterParams) => {
          const rowBody = params?.data
            ?.body as ResourcePlanCPGroupRowsByProjectColRowBody
          return rowBody?.user?.name || ''
        },
        floatingFilter: true,
        filter: ClientSideTextFilter,
        filterParams: {
          getValue: value => getUserName(value),
          getLabel: value => getUserName(value),
        },
      },
      {
        field: 'body.user.code',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.code',
        }),
        width: 100,
        hide: true,
        pinned: true,
        floatingFilter: true,
        filter: 'clientSideTextFilter',
      },
      {
        field: 'body.user.division',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.division',
        }),
        width: 85,
        pinned: true,
        type: [ColumnType.autocomplete],
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
      },
      {
        field: 'body.user.position',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.position',
        }),
        width: 85,
        pinned: true,
        type: [ColumnType.autocomplete],
        cellRendererParams: {
          uiMeta: { tree: true },
        },
        floatingFilter: true,
        filter: ClientSideSelectFilter,
        comparator: displayNameComparator,
      },
      {
        field: 'body.user.partnerMember',
        headerName: intl.formatMessage({
          id: 'resourcePlan.crossProjects.user.business.partner',
        }),
        width: 85,
        hide: true,
        pinned: true,
        type: [ColumnType.checkBox],
        cellEditor: undefined,
        cellRendererParams: {},
        cellEditorParams: {
          disabled: true,
          hide: (node: RowNode<ResourcePlanCrossProjectsRow>) => {
            return ResourcePlanCrossProjectType.MEMBER !== node.data?.type
          },
        },
        floatingFilter: true,
        cellStyle: { color: muiTheme.palette.primary.main },
      },
    ],
  },
  generateTotalColumnDef(ResourcePlanCrossProjectType.MEMBER, getProjectUuid),
  {
    groupId: WORKMONTHS_FIELD_PREFIX,
    headerName: '',
  },
  CHANGE_LOG_COLUMN_GROUP,
]

export const generateWorkMontColDefGroupRowByProject = (
  yearMonth: string,
  getWorkingTimeTotal: (yearMonth: string) => string
) => {
  const columnDef: ColDef = generateWorkMonthColDef(
    yearMonth,
    getWorkingTimeTotal,
    ResourcePlanCrossProjectType.MEMBER,
    getProjectUuid
  )

  return {
    ...columnDef,
    cellStyle: (params: CellClassParams) => {
      let style =
        typeof columnDef.cellStyle === 'function'
          ? columnDef.cellStyle(params)
          : columnDef.cellStyle || {}

      const row: ResourcePlanCrossProjectsRow = params.data
      if (
        row.type === ResourcePlanCrossProjectType.MEMBER &&
        !row.isTotal &&
        ALERT_UPPER_LIMIT_FOR_MAN_MONTH < params.value
      ) {
        style!.backgroundColor = ALERT_COLOR.HIGH
      }
      return style
    },
  }
}
