import { Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { SearchItemLabelHorizontalContainer } from '../../../../components/search-forms/search-item-label-container'
import { BlankableDateTermConditionItemLabel } from '../../../../components/search-forms/search-item-labels/BlankableDateTermConditionItemLabel'
import { DateTimeTermConditionItemLabel } from '../../../../components/search-forms/search-item-labels/DateTimeTermConditionItemLabel'
import { TextConditionItemLabel } from '../../../../components/search-forms/search-item-labels/TextConditionItemLabel'
import { ProjectMemberAutocompleteConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/ProjectMemberAutocompleteConditionItemLabel'
import { SprintAutocompleteConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/SprintAutocompleteConditionItemLabel'
import { TeamAutocompleteConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/TeamAutocompleteConditionItemLabel'
import { TicketTypeCheckboxSelectConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/TicketTypeCheckboxSelectConditionItemLabel'
import { WbsItemPriorityCheckboxSelectConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/WbsItemPriorityCheckboxSelectConditionItemLabel'
import { WbsItemStatusCheckboxSelectConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/WbsItemStatusCheckboxSelectConditionItemLabel'
import { WbsItemTypeCheckboxSelectConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/WbsItemTypeCheckboxSelectConditionItemLabel'
import { WbsItemSearchSearchCondition } from '../../useSearchCondition'
import { useOnSingleValueChangedGenerator } from './useOnSingleValueChangedGenerator'
import { useMemo } from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { TagMultiAutocompleteConditionItemLabel } from '../../../../components/search-forms/search-item-labels/wbs-item-search-item-labels/TagMultiAutocompleteConditionItemLabel'

type WbsItemSearchSearchItemLabelsProps = {
  searchCondition: WbsItemSearchSearchCondition
  onChange: (updated: Partial<WbsItemSearchSearchCondition>) => void
  search: () => void
}

export const WbsItemSearchSearchItemLabels = ({
  searchCondition,
  onChange,
  search,
}: WbsItemSearchSearchItemLabelsProps) => {
  const { onChangeValueGenerator, onChangeBlankGenerator } =
    useOnSingleValueChangedGenerator(searchCondition, onChange)

  const noSearchCondition = useMemo(() => {
    return Object.values(searchCondition)
      .map(v => {
        if (typeof v === 'undefined') {
          return true
        }
        if (Array.isArray(v)) {
          return v.length === 0
        }
        return !v
      })
      .every(v => v)
  }, [searchCondition])

  return (
    <SearchItemLabelHorizontalContainer>
      {noSearchCondition ? (
        <Typography
          sx={{
            fontSize: '14px',
            color: colorPalette.monotone[5],
            fontWeight: 500,
            marginLeft: '10px',
          }}
        >
          {intl.formatMessage({ id: 'wbs.search.no.search.condition' })}
        </Typography>
      ) : (
        <>
          <TextConditionItemLabel
            header={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.code' })}
            value={searchCondition.code}
            onChange={onChangeValueGenerator('code')}
            search={search}
          />
          <WbsItemTypeCheckboxSelectConditionItemLabel
            value={searchCondition.types}
            onChange={onChangeValueGenerator('types')}
            search={search}
          />
          <TicketTypeCheckboxSelectConditionItemLabel
            value={searchCondition.ticketTypes}
            onChange={onChangeValueGenerator('ticketTypes')}
            search={search}
          />
          <WbsItemStatusCheckboxSelectConditionItemLabel
            value={searchCondition.status}
            onChange={onChangeValueGenerator('status')}
            search={search}
          />
          <WbsItemPriorityCheckboxSelectConditionItemLabel
            value={searchCondition.priority}
            onChange={onChangeValueGenerator('priority')}
            search={search}
          />
          <TextConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.displayName',
            })}
            value={searchCondition.displayName}
            onChange={onChangeValueGenerator('displayName')}
            search={search}
          />
          <TextConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.keyword',
            })}
            value={searchCondition.searchText}
            onChange={onChangeValueGenerator('searchText')}
            search={search}
          />
          <TextConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.description',
            })}
            value={searchCondition.description}
            onChange={onChangeValueGenerator('description')}
            search={search}
          />
          <TeamAutocompleteConditionItemLabel
            value={searchCondition.teamUuid}
            onChange={onChangeValueGenerator('teamUuid')}
            search={search}
          />
          <SprintAutocompleteConditionItemLabel
            value={searchCondition.sprintUuid}
            teamUuid={searchCondition.teamUuid}
            onChange={onChangeValueGenerator('sprintUuid')}
            search={search}
          />
          <ProjectMemberAutocompleteConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.accountable',
            })}
            value={searchCondition.accountableUuid}
            onChange={onChangeValueGenerator('accountableUuid')}
            search={search}
          />
          <ProjectMemberAutocompleteConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.responsible',
            })}
            value={searchCondition.responsibleUuid}
            onChange={onChangeValueGenerator('responsibleUuid')}
            search={search}
          />
          <ProjectMemberAutocompleteConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.assignee',
            })}
            value={searchCondition.assigneeUuid}
            onChange={onChangeValueGenerator('assigneeUuid')}
            search={search}
          />
          <TagMultiAutocompleteConditionItemLabel
            value={searchCondition.tagUuids}
            onChange={onChangeValueGenerator('tagUuids')}
            search={search}
          />
          <BlankableDateTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledStartDate',
            })}
            value={searchCondition.scheduledStartDate}
            onChange={onChangeValueGenerator('scheduledStartDate')}
            search={search}
            blank={!!searchCondition.blank?.scheduledStartDate}
            onChangeBlank={onChangeBlankGenerator('scheduledStartDate')}
          />
          <BlankableDateTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledEndDate',
            })}
            value={searchCondition.scheduledEndDate}
            onChange={onChangeValueGenerator('scheduledEndDate')}
            search={search}
            blank={!!searchCondition.blank?.scheduledEndDate}
            onChangeBlank={onChangeBlankGenerator('scheduledEndDate')}
          />
          <BlankableDateTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualStartDate',
            })}
            value={searchCondition.actualStartDate}
            onChange={onChangeValueGenerator('actualStartDate')}
            search={search}
            blank={!!searchCondition.blank?.actualStartDate}
            onChangeBlank={onChangeBlankGenerator('actualStartDate')}
          />
          <BlankableDateTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualEndDate',
            })}
            value={searchCondition.actualEndDate}
            onChange={onChangeValueGenerator('actualEndDate')}
            search={search}
            blank={!!searchCondition.blank?.actualEndDate}
            onChangeBlank={onChangeBlankGenerator('actualEndDate')}
          />
          <DateTimeTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.createdAt',
            })}
            value={searchCondition.createdAt}
            onChange={onChangeValueGenerator('createdAt')}
            search={search}
          />
          <DateTimeTermConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.updatedAt',
            })}
            value={searchCondition.updatedAt}
            onChange={onChangeValueGenerator('updatedAt')}
            search={search}
          />
          <ProjectMemberAutocompleteConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.createdBy',
            })}
            value={searchCondition.createdByUuid}
            onChange={onChangeValueGenerator('createdByUuid')}
            search={search}
          />
          <ProjectMemberAutocompleteConditionItemLabel
            header={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.updatedBy',
            })}
            value={searchCondition.updatedByUuid}
            onChange={onChangeValueGenerator('updatedByUuid')}
            search={search}
          />
        </>
      )}
    </SearchItemLabelHorizontalContainer>
  )
}
