import { ICellRendererParams } from 'ag-grid-community'
import { useState, useEffect } from 'react'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import validator from '../../../meta/validator'
import { setError } from '../../lib/validation'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { getTextColor } from '../../../../../lib/functions/tag'

interface Props extends ICellRendererParams {
  tooltip?: (params: ICellRendererParams) => string | undefined
  uiMeta?: FunctionProperty
}

export const ColorChartCellRenderer = (props: Props) => {
  const { value, data, column, rowIndex, uiMeta } = props
  const [message, setMessage] = useState<string | undefined>()
  const [textColor, setTextColor] = useState('#000000')

  useEffect(() => {
    const err = uiMeta
      ? validator.validate(value, data, uiMeta, () => undefined)?.getMessage()
      : undefined
    setError(data, err, props)
    setMessage(err)
  }, [value])

  useEffect(() => {
    if (!value) setTextColor('#000000')
    setTextColor(getTextColor(value))
  }, [value])

  return (
    <Tooltip message={message}>
      <div
        id={`column-${column?.getColId()}-${rowIndex}`}
        className={'sevend-ag-cell'}
        style={{
          backgroundColor: value ?? (!!message ? '#FADBD8' : undefined),
          color: textColor,
        }}
      >
        {value}
        <FloatingEditableIcon {...props} />
      </div>
    </Tooltip>
  )
}
