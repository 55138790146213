import React from 'react'
import Typography from '@mui/material/Typography'
import { FontSize, TextColor } from '../../../../../styles/commonStyles'

export const DefaultTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      sx={{
        fontSize: FontSize.LARGE,
        fontWeight: 'bold',
        color: TextColor.DARK_BLACK,
        margin: '0 7px',
        whiteSpace: 'nowrap',
      }}
    >
      {title}
    </Typography>
  )
}
