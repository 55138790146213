import { useCallback } from 'react'
import { CellProps, FocusableCellProps, InputCell, InputCellProps } from '.'
import { DateTermVO } from '../../../../domain/value-object/DateTermVO'
import { DateVO } from '../../../../domain/value-object/DateVO'
import { BlankableSearchConditionProps } from '../../search-forms/search-components'
import DateInput from '../../editors/input/DateInput'
import { Box, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'

type DateTermCellProps = CellProps &
  InputCellProps<DateTermVO | undefined> &
  FocusableCellProps &
  BlankableSearchConditionProps

export const DateTermCell = ({
  value,
  onChange,
  onEnter,
  placeholder,
  onChangeBlank,
  blank,
}: DateTermCellProps) => {
  const onChangeFrom = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.to) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        from: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )
  const onChangeTo = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.from) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        to: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )
  const clear = useCallback(() => {
    onChange(undefined)
  }, [onChange])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '300px',
        height: '60px',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <Box sx={{ padding: '0 10px 0 20px' }}>
        <DateInput
          value={value && value.from}
          onChange={onChangeFrom}
          placeholder={placeholder || 'YYYY/MM/DD'}
          enterHandler={onEnter}
          isWbsSearchCondition={true}
          onChangeBlank={onChangeBlank}
          checkedBlank={blank}
          onChangedSwitchButton={clear}
        />
      </Box>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <Box sx={{ padding: '0 20px 0 10px' }}>
        <DateInput
          value={value && value.to}
          onChange={onChangeTo}
          placeholder={placeholder || 'YYYY/MM/DD'}
          enterHandler={onEnter}
          isWbsSearchCondition={true}
          onChangeBlank={onChangeBlank}
          checkedBlank={blank}
          onChangedSwitchButton={clear}
        />
      </Box>
    </Box>
  )
}
