import './styles.scss'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Avatar, Box } from '@mui/material'
import { MentionEntityAttributeKey } from '../lib/Converter'

export default forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectItem = index => {
    const item = props.items[index]
    if (item) {
      props.command({
        id: item.userUuid,
        label: item.name,
        [MentionEntityAttributeKey.mentionUuid]: item.userUuid,
        [MentionEntityAttributeKey.mentionType]: item.mentionType,
      })
    }
  }
  const selectedItemRef = useRef<HTMLDivElement>(null)

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    )
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useEffect(() => {
    if (selectedItemRef?.current) {
      selectedItemRef.current.scrollIntoView({
        block: 'nearest',
      })
    }
  }, [selectedIndex])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }
      if (event.key === 'Enter') {
        enterHandler()
        return true
      }
      return false
    },
  }))
  return (
    <div className="items">
      {props.items.length ? (
        props.items.map((item, index) => (
          <Box
            key={'mention' + index}
            ref={selectedIndex === index ? selectedItemRef : null}
            sx={{
              backgroundColor:
                selectedIndex === index ? '#E0E0E0' : 'transparent',
            }}
          >
            <button
              className={'item'}
              key={index}
              onClick={() => selectItem(index)}
            >
              <div className={'container'}>
                <div className={'iconBox'}>
                  <Avatar
                    variant="circular"
                    className={'icon'}
                    src={item.avatar}
                  />
                </div>
                <div className={'textBox'}>
                  <div className={'textName'}>{item.name}</div>
                  <div className={'textDescription'}>{item.description}</div>
                </div>
              </div>
            </button>
          </Box>
        ))
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  )
})
