import { Divider, styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { useMemo } from 'react'
import { intl } from '../../../../../i18n'
import { WbsPivotAggregationDomain } from '../../../../../domain/value-object/WbsPivot'
import { ProjectPlanBreadcrumbs } from '../../../../components/navigation/project-plan-breadcrumbs'
import { ReportDivider } from '../..'
import {
  BurndownChartTeamSelectHeader,
  BurndownChartTeamSelectHeaderProps,
} from './TeamSelectHeader'

type BurndownChartHeaderProps = {
  rootName: string
  domain: WbsPivotAggregationDomain | undefined
  onChangeDomain: (rootProjectPlanUuid: string | undefined) => void
  scheduledDate: {
    from: Date | undefined
    to: Date | undefined
  }
} & BurndownChartTeamSelectHeaderProps
export const BurndownChartHeader = ({
  rootName,
  domain,
  onChangeDomain,
  scheduledDate,
  ...others
}: BurndownChartHeaderProps) => {
  const [scheduledDateLabel, elapsedProgressRateLabel] = useMemo(
    () => [
      intl.formatMessage({ id: 'progressReport.header.scheduledDate' }),
      intl.formatMessage({ id: 'progressReport.header.elapsedProgressRate' }),
    ],
    []
  )
  const { startDate, endDate } = useMemo(() => {
    const format = (d: Date | undefined) => {
      if (!d) {
        return intl.formatMessage({
          id: 'progressReport.scheduledDateTerm.undefined',
        })
      }
      const monthNum = d.getMonth() + 1
      const month = monthNum < 10 ? `0${monthNum}` : monthNum.toString()
      const dateNum = d.getDate()
      const date = dateNum < 10 ? `0${dateNum}` : dateNum
      return `${d.getFullYear()}/${month}/${date}`
    }
    return {
      startDate: format(scheduledDate.from),
      endDate: format(scheduledDate.to),
    }
  }, [scheduledDate])
  const elapsedProgressRatePercentageRounded = useMemo(() => {
    const scheduledStartDate = scheduledDate.from
    const scheduledEndDate = scheduledDate.to
    if (!scheduledStartDate || !scheduledEndDate) return '-'
    const now = new Date()
    const denominator =
      scheduledEndDate.valueOf() - scheduledStartDate.valueOf()
    const numerator = now.valueOf() - scheduledStartDate.valueOf()
    const elapsedProgressRate = Math.min(
      1,
      Math.max(0, numerator / denominator)
    )
    const elapsedProgressRatePercentage = elapsedProgressRate * 100
    return elapsedProgressRatePercentage.toFixed(1)
  }, [scheduledDate])

  return (
    <HeaderArea>
      <BreadcrumbArea>
        <ProjectPlanBreadcrumbs
          rootProjectPlanUuid={domain?.value}
          onSelectBreadcrumb={onChangeDomain}
        />
      </BreadcrumbArea>
      <HeaderMainArea>
        <TitleArea>
          <Title>{rootName}</Title>
          <StyledDivider />
          <PropertyArea>
            <PropertyLabel>{scheduledDateLabel}:</PropertyLabel>
            <StyledDate>{startDate}</StyledDate>
            <StyledDateRangeTilde>~</StyledDateRangeTilde>
            <StyledDate>{endDate}</StyledDate>
            <PropertyLabel>{elapsedProgressRateLabel}:</PropertyLabel>
            <StyledPeriodicProgressRate>
              {elapsedProgressRatePercentageRounded}%
            </StyledPeriodicProgressRate>
          </PropertyArea>
        </TitleArea>
        <ReportDivider />
        <BurndownChartTeamSelectHeader {...others} />
      </HeaderMainArea>
    </HeaderArea>
  )
}

const HeaderArea = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
})
const BreadcrumbArea = styled('div')({})
const HeaderMainArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '4px',
  padding: '16px 20px',
})
const TitleArea = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '12px',
})

const Title = styled('div')({
  fontSize: '20px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
  display: 'flex',
})

const PropertyArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const PropertyLabel = styled('div')({
  fontSize: '12px',
  lineHeight: 1.5,
  color: colorPalette.monotone[4],
})

const StyledDate = styled('div')({
  fontSize: '13px',
  lineHeight: 1.5,
  color: colorPalette.monotone[5],
  fontWeight: 600,
})

const StyledDateRangeTilde = styled('div')({
  fontSize: '12px',
  lineHeight: 1,
  color: colorPalette.monotone[4],
})

const StyledPeriodicProgressRate = styled('div')({
  fontSize: '13px',
  color: colorPalette.monotone[5],
  fontWeight: 600,
})
const StyledDivider = styled(() => <Divider orientation="vertical" />)({
  borderColor: colorPalette.monotone[2],
})
