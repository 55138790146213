import { ColumnType } from '../../containers/commons/AgGrid'
import { BulkSheetProperty } from './bulksheetProperty'

export const dragAndDrop: BulkSheetProperty = {
  field: 'drag',
  headerName: '',
  type: [ColumnType.drag],
  rowDrag: _ => true,
  cellClassRules: {
    'hover-over-can-drop': params => {
      return (
        params.context?.draggableNodeId &&
        params.node?.id === params.context?.draggableNodeId
      )
    },
  },
}
