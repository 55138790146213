import _ from 'lodash'
import { MouseEvent, useCallback, useRef } from 'react'
import { WrappedComponentProps, injectIntl } from 'react-intl'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import { Link, Typography } from '@mui/material'
import { Button } from '../../../../components/buttons'

export const BLOB_MIME_TYPE_EXCEL: string = 'application/octet-stream'

interface OwnProps extends WrappedComponentProps {
  mimeType: string
  label: string
  fileName: () => string | string
  getData: () => BlobPart | undefined | Promise<BlobPart | undefined>
}
type Props = OwnProps

const FileDownloadButton = ({ mimeType, label, fileName, getData }: Props) => {
  const linkRef = useRef<HTMLAnchorElement>(null)

  const onClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      const downloadFileName =
        typeof fileName === 'function' ? fileName() : fileName
      if (_.isEmpty(downloadFileName)) return
      const data = await getData()
      const blob = new Blob(data ? [data] : [], { type: mimeType })
      const url = window.URL.createObjectURL(blob)

      const link = linkRef.current!
      linkRef.current!.setAttribute('href', url)
      linkRef.current!.setAttribute('download', downloadFileName)
      link.click()
    },
    [mimeType, fileName, getData]
  )

  return (
    <>
      <Button
        colorPattern="monotone"
        disabled={!linkRef.current}
        onClick={onClick}
        sx={{
          margin: '0 5px 0 15px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          minWidth: 'fit-content',
        }}
        variant="outlined"
      >
        <GetAppRoundedIcon sx={{ height: '16px', width: '16px' }} />
        <Typography>{label}</Typography>
      </Button>
      <Link ref={linkRef} sx={{ display: 'none' }} />
    </>
  )
}

export default injectIntl(FileDownloadButton)
