import { WbsItemRow } from '../../../lib/functions/wbsItem'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { TreeRow } from '../../containers/BulkSheetView/model'
import DateVO from '../../../vo/DateVO'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'

// Project overview row data
interface Progress {
  scheduledProgressRate?: number
  progressRate?: number
  total: number
  scheduledToBe: number
  completed: number
  preceding: number
  delayed: number
  remaining: number
  unplanned: number
}

interface Evm {
  actualHour: number
  costVariance: number
  costPerformanceIndex?: number
  scheduleVariance: number
  schedulePerformanceIndex?: number
  estimateToComplete?: number
  estimateAtCompletion?: number
  varianceAtCompletion?: number
}

export interface ProjectOverviewRow extends TreeRow {
  uuid: string
  wbsItem: WbsItemRow
  progress?: {
    sumDeliverableStart: Progress
    sumDeliverableEnd: Progress
    sumTaskStart: Progress
    sumTaskEnd: Progress
    countDeliverableStart: Progress
    countDeliverableEnd: Progress
    countTaskStart: Progress
    countTaskEnd: Progress
  }
  progressDetail?: {
    sumStart?: Progress
    sumEnd?: Progress
    countStart?: Progress
    countEnd?: Progress
  }
  evm?: {
    task: Evm
    deliverable: Evm
  }
  cumulation?: Partial<ProjectPlanCumulation>

  wbsItemUuid: string
  children: any[]
}

// Cumulation conversion
export class Cumulation {
  constructor(private projectPlanCumulation: ProjectPlanCumulation) {}

  progressBySumDeliverableStart = (): Progress => {
    const c = this.projectPlanCumulation
    const total =
      c.sumDeliverableEstimatedHour - c.sumDeliverableEstimatedHourDiscard
    const countStarted = total - c.sumDeliverableEstimatedHourTodo
    return {
      scheduledProgressRate: this.divide(c.sumDeliverableToBeStarted, total),
      progressRate: this.divide(countStarted, total),
      total,
      scheduledToBe: c.sumDeliverableToBeStarted,
      completed: countStarted,
      preceding: c.sumDeliverableStartPreceding,
      delayed: c.sumDeliverableStartDelayed,
      remaining: total - countStarted,
      unplanned: c.sumDeliverableStartUnplanned,
    }
  }

  progressBySumDeliverableEnd = (): Progress => {
    const c = this.projectPlanCumulation
    const total =
      c.sumDeliverableEstimatedHour - c.sumDeliverableEstimatedHourDiscard
    return {
      scheduledProgressRate: this.divide(c.sumDeliverableToBeCompleted, total),
      progressRate: this.divide(c.sumDeliverableEstimatedHourDone, total),
      total,
      scheduledToBe: c.sumDeliverableToBeCompleted,
      completed: c.sumDeliverableEstimatedHourDone,
      preceding: c.sumDeliverableEndPreceding,
      delayed: c.sumDeliverableEndDelayed,
      remaining: total - c.sumDeliverableEstimatedHourDone,
      unplanned: c.sumDeliverableEndUnplanned,
    }
  }

  progressByCountDeliverableStart = (): Progress => {
    const c = this.projectPlanCumulation
    const total = c.countDeliverable - c.countStatusDeliverableDiscard
    const countStarted = total - c.countStatusDeliverableTodo
    return {
      scheduledProgressRate: this.divide(c.countDeliverableToBeStarted, total),
      progressRate: this.divide(countStarted, total),
      total,
      scheduledToBe: c.countDeliverableToBeStarted,
      completed: countStarted,
      preceding: c.countDeliverableStartPreceding,
      delayed: c.countDeliverableStartDelayed,
      remaining: total - countStarted,
      unplanned: c.countDeliverableStartUnplanned,
    }
  }

  progressByCountDeliverableEnd = (): Progress => {
    const c = this.projectPlanCumulation
    const total = c.countDeliverable - c.countStatusDeliverableDiscard
    return {
      scheduledProgressRate: this.divide(
        c.countDeliverableToBeCompleted,
        total
      ),
      progressRate: this.divide(c.countStatusDeliverableDone, total),
      total,
      scheduledToBe: c.countDeliverableToBeCompleted,
      completed: c.countStatusDeliverableDone,
      preceding: c.countDeliverableEndPreceding,
      delayed: c.countDeliverableEndDelayed,
      remaining: total - c.countStatusDeliverableDone,
      unplanned: c.countDeliverableEndUnplanned,
    }
  }

  progressBySumTaskStart = (): Progress => {
    const c = this.projectPlanCumulation
    const total = c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard
    const countStarted = total - c.sumTaskEstimatedHourTodo
    return {
      scheduledProgressRate: this.divide(c.sumTaskToBeStarted, total),
      progressRate: this.divide(countStarted, total),
      total,
      scheduledToBe: c.sumTaskToBeStarted,
      completed: countStarted,
      preceding: c.sumTaskStartPreceding,
      delayed: c.sumTaskStartDelayed,
      remaining: total - countStarted,
      unplanned: c.sumTaskStartUnplanned,
    }
  }

  progressBySumTaskEnd = (): Progress => {
    const c = this.projectPlanCumulation
    const total = c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard
    return {
      scheduledProgressRate: this.divide(c.sumTaskToBeCompleted, total),
      progressRate: this.divide(c.sumTaskEstimatedHourDone, total),
      total,
      scheduledToBe: c.sumTaskToBeCompleted,
      completed: c.sumTaskEstimatedHourDone,
      preceding: c.sumTaskEndPreceding,
      delayed: c.sumTaskEndDelayed,
      remaining: total - c.sumTaskEstimatedHourDone,
      unplanned: c.sumTaskEndUnplanned,
    }
  }

  progressByCountTaskStart = (): Progress => {
    const c = this.projectPlanCumulation
    const taskCount = c.countTask - c.countStatusTaskDiscard
    const countStarted = taskCount - c.countStatusTaskTodo
    return {
      scheduledProgressRate: this.divide(c.countTaskToBeStarted, taskCount),
      progressRate: this.divide(countStarted, taskCount),
      total: taskCount,
      scheduledToBe: c.countTaskToBeStarted,
      completed: countStarted,
      preceding: c.countTaskStartPreceding,
      delayed: c.countTaskStartDelayed,
      remaining: taskCount - countStarted,
      unplanned: c.countTaskStartUnplanned,
    }
  }
  progressByCountTaskEnd = (): Progress => {
    const c = this.projectPlanCumulation
    const taskCount = c.countTask - c.countStatusTaskDiscard
    return {
      scheduledProgressRate: this.divide(c.countTaskToBeCompleted, taskCount),
      progressRate: this.divide(c.countStatusTaskDone, taskCount),
      total: taskCount,
      scheduledToBe: c.countTaskToBeCompleted,
      completed: c.countStatusTaskDone,
      preceding: c.countTaskEndPreceding,
      delayed: c.countTaskEndDelayed,
      remaining: taskCount - c.countStatusTaskDone,
      unplanned: c.countTaskEndUnplanned,
    }
  }

  evmByTask = (): Evm => {
    const c = this.projectPlanCumulation
    return this.evm({
      estimatedHour: c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard,
      earnedValue: c.sumTaskEstimatedHourDone,
      plannedValue: c.sumTaskToBeCompleted,
      actualHour: c.sumActualHour,
    })
  }

  evmByDeliverable = (): Evm => {
    const c = this.projectPlanCumulation
    return this.evm({
      estimatedHour:
        c.sumDeliverableEstimatedHour - c.sumDeliverableEstimatedHourDiscard,
      earnedValue: c.sumDeliverableEstimatedHourDone,
      plannedValue: c.sumDeliverableToBeCompleted,
      actualHour: c.sumActualHour,
    })
  }

  divide = (numerator: number, denominator: number | undefined) =>
    !denominator ? undefined : numerator / denominator

  evm = (props: {
    estimatedHour: number
    earnedValue: number
    plannedValue: number
    actualHour: number
  }): Evm => {
    const { estimatedHour, earnedValue, plannedValue, actualHour } = props
    const cpi = this.divide(earnedValue, actualHour)
    const spi = this.divide(earnedValue, plannedValue)
    const etc = this.divide(estimatedHour - earnedValue, cpi)
    const eac = this.divide(estimatedHour, cpi)
    const vac = eac ? estimatedHour - eac : undefined
    return {
      actualHour: actualHour,
      costVariance: earnedValue - actualHour,
      costPerformanceIndex: cpi,
      scheduleVariance: earnedValue - plannedValue,
      schedulePerformanceIndex: spi,
      estimateToComplete: etc,
      estimateAtCompletion: eac,
      varianceAtCompletion: vac,
    }
  }
}

export const progressDetailByEnd = (
  wbsItem: WbsItemRow,
  target: 'sum' | 'count',
  today: DateVO
): Progress | undefined => {
  const { wbsItemType, status, scheduledDate, estimatedWorkload } = wbsItem
  if (
    status === WbsItemStatus.DISCARD ||
    !(wbsItemType?.isTask() || wbsItemType?.isDeliverable())
  ) {
    return undefined
  }
  const field = target === 'sum' ? estimatedWorkload?.hour ?? 0 : 1
  const scheduledEndDate = scheduledDate?.endDate
    ? new DateVO(scheduledDate.endDate)
    : undefined
  return {
    scheduledProgressRate: 0,
    progressRate: 0,
    total: field,
    scheduledToBe:
      scheduledEndDate && scheduledEndDate.isSameOrBefore(today) ? field : 0,
    completed: status === WbsItemStatus.DONE ? field : 0,
    preceding:
      status === WbsItemStatus.DONE &&
      scheduledEndDate &&
      today.isBefore(scheduledEndDate)
        ? field
        : 0,
    delayed:
      status !== WbsItemStatus.DONE &&
      scheduledEndDate &&
      scheduledEndDate.isBefore(today)
        ? field
        : 0,
    remaining: status !== WbsItemStatus.DONE ? field : 0,
    unplanned: !scheduledEndDate ? field : 0,
  }
}

export const progressDetailByStart = (
  wbsItem: WbsItemRow,
  target: 'sum' | 'count',
  today: DateVO
): Progress | undefined => {
  const { wbsItemType, status, scheduledDate, estimatedWorkload } = wbsItem
  if (
    status === WbsItemStatus.DISCARD ||
    !(wbsItemType?.isTask() || wbsItemType?.isDeliverable())
  ) {
    return undefined
  }
  const field = target === 'sum' ? estimatedWorkload?.hour ?? 0 : 1
  const scheduledStartDate = scheduledDate?.startDate
    ? new DateVO(scheduledDate.startDate)
    : undefined
  return {
    scheduledProgressRate: 0,
    progressRate: 0,
    total: field,
    scheduledToBe:
      scheduledStartDate && scheduledStartDate.isSameOrBefore(today)
        ? field
        : 0,
    completed: status !== WbsItemStatus.TODO ? field : 0,
    preceding:
      [WbsItemStatus.DOING, WbsItemStatus.REVIEW].includes(status!) &&
      scheduledStartDate &&
      today.isBefore(scheduledStartDate)
        ? field
        : 0,
    delayed:
      status === WbsItemStatus.TODO &&
      scheduledStartDate &&
      scheduledStartDate.isBefore(today)
        ? field
        : 0,
    remaining: status === WbsItemStatus.TODO ? field : 0,
    unplanned: !scheduledStartDate ? field : 0,
  }
}
