import { useMemo } from 'react'
import { DateTerm } from '../../../../utils/date'
import RefreshButton from '../../../components/buttons/RefreshButton'
import {
  DateRangeSelector,
  SearchUnit,
} from '../../../components/headers/HeaderBar/DateRangeSelector'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { intl } from '../../../../i18n'
import FileDownloadButton from '../components/FileDownloadButton'

interface DetailDownloadProps {
  blobMimeType: string
  getFileName: () => string
  getBlobData: () => BlobPart | Promise<BlobPart | undefined> | undefined
}

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  searchUnit: SearchUnit
  dateTerm: DateTerm
  onSearch: (searchUnit: SearchUnit, dateTerm: DateTerm) => void
  refreshDataWithLoading: (forceRefreshDynamicColumns?: boolean) => void
  isLoading: boolean
  isNotice: boolean
  detailDownloadProps: DetailDownloadProps
}

type Props = OwnProps & SettingSpeedDialProps

const WorkReportHeader = ({
  toolbar,
  onChangeToolbar,
  searchUnit,
  dateTerm,
  onSearch,
  refreshDataWithLoading,
  isLoading,
  isNotice,
  onClickExport,
  onClickImport,
  onRestoreSearchCondition,
  rowHeight,
  onClickChangeRowHeight,
  detailDownloadProps,
}: Props) => {
  const fileDownloadButtonLbal = useMemo(
    () => intl.formatMessage({ id: 'workReport.csv.download.button' }),
    []
  )

  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <DateRangeSelector
            searchUnit={searchUnit}
            dateTerm={dateTerm}
            onSearch={onSearch}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <RefreshButton
            onClick={() => refreshDataWithLoading()}
            hideIcon={true}
            isLoading={isLoading}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={onClickExport}
              onClickImport={onClickImport}
              onRestoreSearchCondition={onRestoreSearchCondition}
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
        <FileDownloadButton
          mimeType={detailDownloadProps.blobMimeType}
          label={fileDownloadButtonLbal}
          fileName={detailDownloadProps.getFileName}
          getData={detailDownloadProps.getBlobData}
        />
      </HeaderContent>
    </PageHeader>
  )
}

export default WorkReportHeader
