import React from 'react'
import { styled } from '@mui/system'
import { intl } from '../../../../i18n'
import TicketListAPI, {
  TicketListDetail,
} from '../../../../lib/functions/ticketList'
import AddRowCountInputDialog from '../../../containers/BulkSheet/AddRowCountInputDialog'
import TicketListSelect from '../../../pages/TicketList/TicketListSelect'
import { TicketTypeSelect } from '../../../pages/Tickets/TicketTypeSelect'
import uiState, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { _ } from 'ag-grid-community'

const SelectBox = styled('div')({
  margin: '0 0 10px 0',
})
const SelectBoxLabel = styled('span')({
  fontSize: '11px',
})

export const AddMultipleTicketDialog = (props: {
  open: boolean
  projectUuid: string
  title?: string
  submitHandler: (
    addRowCount: number | undefined,
    ticketType: string | undefined,
    ticketList: TicketListDetail | undefined
  ) => void
  closeHandler?: Function
  initialValue?: number
}) => {
  const {
    open,
    projectUuid,
    title,
    submitHandler,
    closeHandler,
    initialValue,
  } = props
  const [ticketType, setTicketType] = React.useState<string | undefined>(
    undefined
  )
  const [ticketList, setTicketList] = React.useState<
    TicketListDetail | undefined
  >(undefined)
  React.useEffect(() => {
    if (!ticketType) return
    const init = async () => {
      const getUiStateResponse = await uiState.get({
        key: `${UiStateKey.LastSelectedTicketListToAddTicket}-${projectUuid}-${ticketType}`,
        scope: UiStateScope.User,
      })
      const lastSelectedTicketListUuid: string | undefined =
        getUiStateResponse.json.value

      if (!lastSelectedTicketListUuid) return
      const findTicketListResponse = await TicketListAPI.findByUuid(
        projectUuid,
        lastSelectedTicketListUuid
      ).catch(_ => {
        // Ignore error because its possible for the init function to throw NotFound error on finding deleted ticketlist.
        return
      })
      const ticketList: TicketListDetail | undefined =
        findTicketListResponse?.json
      if (
        !ticketList ||
        WbsItemStatus.DONE === ticketList.wbsItem.status ||
        WbsItemStatus.DISCARD === ticketList.wbsItem.status // Ignore DONE or DISCARD beucase of setting true as showOnlyActiveTicketList to TicketListSelect component.
      ) {
        return
      }
      setTicketList(ticketList)
    }
    init()
  }, [projectUuid, ticketType])

  React.useEffect(() => {
    const fetchLastSelectTicketType = async () => {
      const response = await uiState.get({
        key: `${UiStateKey.LastSelectedTicketTypeToAddTicket}-${projectUuid}`,
        scope: UiStateScope.User,
      })
      const lastSelectedTicketType: string = response.json.value
      if (lastSelectedTicketType && 0 < lastSelectedTicketType.length) {
        setTicketType(lastSelectedTicketType)
      }
    }
    fetchLastSelectTicketType()
  }, [projectUuid])

  const submitHandlerForTicketList = (addRowCount: number | undefined) => {
    uiState.update({
      key: `${UiStateKey.LastSelectedTicketTypeToAddTicket}-${projectUuid}`,
      scope: UiStateScope.User,
      value: ticketType ?? '',
    })
    uiState.update({
      key: `${UiStateKey.LastSelectedTicketListToAddTicket}-${projectUuid}-${ticketType}`,
      scope: UiStateScope.User,
      value: ticketList!.uuid,
    })
    submitHandler(addRowCount, ticketType, ticketList)
  }
  return (
    <AddRowCountInputDialog
      open={open}
      title={title}
      submitHandler={submitHandlerForTicketList}
      closeHandler={closeHandler}
      disabled={!ticketType || !ticketList}
      initialValue={initialValue}
    >
      <SelectBox>
        <SelectBoxLabel>
          {intl.formatMessage({ id: 'ticketType' })}
        </SelectBoxLabel>
        <TicketTypeSelect
          value={ticketType}
          onChange={value => {
            setTicketType(value)
            setTicketList(undefined)
          }}
        />
      </SelectBox>
      <SelectBox>
        <SelectBoxLabel>
          {intl.formatMessage({ id: 'ticketList' })}
        </SelectBoxLabel>
        <TicketListSelect
          ticketType={ticketType}
          value={ticketList}
          onChange={setTicketList}
          showOnlyActiveTicketList={true}
        />
      </SelectBox>
    </AddRowCountInputDialog>
  )
}
