import { TreeRow } from '../../containers/BulkSheetView/model'
import { intl } from '../../../i18n'
import { IItemDelta } from '../../../domain/value-object/ItemDeltaInputVO'
import { SlackChannel } from '../../../domain/value-object/Slack/ChannelVO'

export interface ProjectNotificationSettingRow extends TreeRow {
  projectUuid: string
  notificationCode: string
  mailNotification: boolean
  slackNotification: boolean
  slackChannel: Partial<SlackChannel>
  displayOrder: number
}

export interface ProjectNotificationWorkspace {
  uuid?: string
  projectUuid: string
  slackCollaborationWorkspaceUuid: string
  slackWorkspaceId: string
  slackWorkspaceName: string
}

export const isWorkspaceChanged = (
  a?: ProjectNotificationWorkspace,
  b?: ProjectNotificationWorkspace
): boolean => {
  return (
    a?.slackCollaborationWorkspaceUuid !== b?.slackCollaborationWorkspaceUuid
  )
}

export interface ProjectNotificationSettingDeltaBatchRequest {
  projectUuid: string
  workspaceDelta?: IItemDelta<ProjectNotificationWorkspace>
  contentsDelta: ProjectNotificationSettingContentDeltaInput[]
}

export interface ProjectNotificationSettingContentDeltaInput {
  uuid: string
  lockVersion?: number
  notificationCode: string
  projectUuid: string
  mailNotification?: IItemDelta<boolean>
  slackNotification?: IItemDelta<boolean>
  slackChannelId?: IItemDelta<string>
  slackChannelName?: IItemDelta<string>
}

export const getContentDisplayName = (row?: ProjectNotificationSettingRow) =>
  row
    ? intl.formatMessage({
        id: `project.notification.setting.content.${row.notificationCode}`,
      })
    : ''
