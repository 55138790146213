import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import TaskActualWorkAPI, {
  TaskActualWorkDetail,
} from '../../../../lib/functions/taskActualWork'
import { formatDate } from '../../../../utils/date'
import { ProjectMemberProps } from '../../../../lib/functions/projectMember'
import { FontSize } from '../../../../styles/commonStyles'
import DateVO from '../../../../vo/DateVO'
import { intl } from '../../../../i18n'

interface ActualWork {
  uuid: string
  projectMember: ProjectMemberProps
  actualDate: string
  hour: number
  isTotal: boolean
}

interface Props {
  taskUuid?: string
  showOnlyList?: boolean
}

const RootDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 7px)',
  overflow: 'auto',
})
const TitleDiv = styled('div')({
  fontWeight: 'bold',
  marginBottom: '10px',
  fontSize: `${FontSize.LARGE}px`,
})
const HeaderCell = styled(TableCell)({
  padding: '8px',
  borderBottom: 'none',
})
const TotalSpan = styled('span')({
  fontWeight: 'bold',
})

const TaskActualWorkList = (props: Props) => {
  const [total, setTotal] = useState<number>(0)
  const [taskName, setTaskName] = useState<string>('')
  const [taskActualWorks, setTaskActualWorks] = useState<ActualWork[]>([])

  useEffect(() => {
    makeTaskActualWork()
  }, [])

  const makeTaskActualWork = useCallback(async () => {
    if (props.taskUuid) {
      const response = await TaskActualWorkAPI.getTaskActualWorkByTaskUuid(
        props.taskUuid
      )
      const taskActualWork: TaskActualWorkDetail = response.json
      if (!taskActualWork.actualWorks) {
        taskActualWork.actualWorks = []
        return
      }
      const memberTotalActualHour: { [uuid: string]: number } = {}
      const taskActualWorks: ActualWork[] = []
      let total = 0
      taskActualWork.actualWorks.forEach(actualWork => {
        total += actualWork.hour
        const memberTotal = memberTotalActualHour[actualWork.projectMember.uuid]
        memberTotalActualHour[actualWork.projectMember.uuid] = memberTotal
          ? memberTotalActualHour[actualWork.projectMember.uuid] +
            actualWork.hour
          : actualWork.hour
        taskActualWorks.push({
          uuid: actualWork.uuid,
          projectMember: actualWork.projectMember,
          actualDate: actualWork.actualDate,
          hour: actualWork.hour,
          isTotal: false,
        })
      })
      taskActualWorks.sort((a, b) => {
        if (a.projectMember.uuid !== b.projectMember.uuid) {
          return a.projectMember.uuid > b.projectMember.uuid ? 1 : -1
        }
        return new DateVO(a.actualDate).isAfter(new DateVO(b.actualDate))
          ? 1
          : -1
      })
      // Set member total
      Object.entries(memberTotalActualHour).forEach(
        ([memberUuid, memberTotal]) => {
          const index = taskActualWorks.findIndex(
            v => v.projectMember.uuid === memberUuid
          )
          taskActualWorks.splice(index, 0, {
            uuid: taskActualWorks[index].projectMember.uuid + 'total',
            projectMember: taskActualWorks[index].projectMember,
            actualDate: 'Total',
            hour: memberTotal,
            isTotal: true,
          })
        }
      )
      setTotal(total)
      setTaskName(taskActualWork.task.displayName)
      setTaskActualWorks(taskActualWorks)
    }
  }, [props.taskUuid])

  const formatHour = useCallback((value: number): string => {
    return Number(value).toFixed(2)
  }, [])

  return (
    <RootDiv>
      {!props.showOnlyList && <TitleDiv>{taskName}</TitleDiv>}
      {props.taskUuid && taskName && (
        <div>
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table aria-label="simple table" sx={{ boxShadow: 'none' }}>
              <TableHead sx={{ backgroundColor: '#F5F5F5' }}>
                <TableRow>
                  <HeaderCell>
                    {intl.formatMessage({
                      id: 'taskActualWorkList.header.user',
                    })}
                  </HeaderCell>
                  <HeaderCell>
                    {intl.formatMessage({
                      id: 'taskActualWorkList.header.date',
                    })}
                  </HeaderCell>
                  <HeaderCell align="right">
                    {intl.formatMessage({
                      id: 'taskActualWorkList.header.hour',
                    })}
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taskActualWorks.map((row: ActualWork, index: number) => {
                  const isSameMemberData = (a: ActualWork, b: ActualWork) =>
                    a.projectMember.uuid === b.projectMember.uuid
                  const isFirst = index === 0
                  const isLast = index === taskActualWorks.length - 1
                  const current = row
                  const previous = isFirst
                    ? undefined
                    : taskActualWorks[index - 1]
                  const next = isLast ? undefined : taskActualWorks[index + 1]
                  return (
                    <TableRow key={row.uuid}>
                      <TableCell
                        sx={{
                          padding: '8px',
                          borderBottom:
                            next && isSameMemberData(current, next)
                              ? 'none'
                              : undefined,
                        }}
                      >
                        {!previous || !isSameMemberData(previous, current)
                          ? row.projectMember.name
                          : ''}
                      </TableCell>
                      <TableCell sx={{ padding: '8px' }}>
                        {row.isTotal ? (
                          <TotalSpan>Total:</TotalSpan>
                        ) : (
                          formatDate(row.actualDate)
                        )}
                      </TableCell>
                      <TableCell sx={{ padding: '8px' }} align="right">
                        {row.isTotal ? (
                          <TotalSpan>{formatHour(row.hour)}</TotalSpan>
                        ) : (
                          formatHour(row.hour)
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {taskName && (
                  <TableRow>
                    <HeaderCell />
                    <HeaderCell>
                      <TotalSpan>Total:</TotalSpan>
                    </HeaderCell>
                    <HeaderCell align="right">
                      <TotalSpan>{formatHour(total)}</TotalSpan>
                    </HeaderCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </RootDiv>
  )
}

export default TaskActualWorkList
