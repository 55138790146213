import {
  ProgressReportScheduleAndActualValues,
  ProgressReportScheduleAndActualValueMaps,
  ProgressReportTooltip,
  ProgressReportTooltipBody,
  ProgressReportTooltipHeader,
  TooltipTableHeadCell,
} from '.'
import { AccumulateDirection } from '../..'
import { AggregateColumnGroup } from '../../utils'
import { intl } from '../../../../../i18n'
import { WbsItemTypeForWbsProgressLog } from '../../../../../lib/functions/wbsProgressLog'
import {
  getTimeGrainLabel,
  getTimeLabel,
  TimeGrain,
} from '../../../../../lib/functions/report'
import { BackgroundColor } from '../../../../../styles/commonStyles'
import { ValueFormatter } from '../../../../components/charts/Chart/TwoDimChart'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { AggregateField } from '../../../../../domain/entity/WbsItemEntity'
import ScheduledAndActualTooltipRow from './ScheduledAndActualTooltipRow'

export type ProgressReportSummaryItem = {
  color: string
  type: WbsItemTypeForWbsProgressLog
  aggregateColumnGroup: AggregateColumnGroup
  currValues: ProgressReportScheduleAndActualValues
  prevValues?: ProgressReportScheduleAndActualValues
  currWbsItemValueMaps?: ProgressReportScheduleAndActualValueMaps
  prevWbsItemValueMaps?: ProgressReportScheduleAndActualValueMaps
  direction: AccumulateDirection
}

type Props = {
  summaries: ProgressReportSummaryItem[]
  formatter: ValueFormatter
  timeGrain: TimeGrain
  aggregateTargetType: AggregateField
  dimTime: number
}

export const SummaryTooltip = ({
  summaries,
  formatter,
  timeGrain,
  aggregateTargetType,
  dimTime,
}: Props) => {
  const showActualOnly: boolean = !summaries.some((summary, i) => {
    return ![
      AggregateColumnGroup.CREATED_AT,
      AggregateColumnGroup.ACTUAL_HOUR_RECORDED_AT,
    ].includes(summary.aggregateColumnGroup)
  })

  return (
    <ProgressReportTooltip>
      <ProgressReportTooltipHeader>
        <span>{`■ ${getTimeLabel(dimTime, timeGrain)}`}</span>
        <span>{`${intl.formatMessage({
          id: 'progressReport.aggregateCycle',
        })} : ${getTimeGrainLabel(timeGrain)}/${intl.formatMessage({
          id: 'progressReport.unit',
        })} : ${intl.formatMessage({
          id: `progressReport.${aggregateTargetType}`,
        })}`}</span>
      </ProgressReportTooltipHeader>
      <ProgressReportTooltipBody>
        <Table sx={{ minWidth: !showActualOnly ? 388 : 230 }}>
          <TableHead sx={{ backgroundColor: BackgroundColor.GREY }}>
            {!showActualOnly && (
              <TableRow>
                <TooltipTableHeadCell sx={{ width: 120 }} />
                <TooltipTableHeadCell colSpan={2} sx={{ width: 84 }}>
                  {`${intl.formatMessage({
                    id: 'progressReport.scheduled',
                  })} (${intl.formatMessage(
                    { id: 'progressReport.compareToPrev' },
                    { timeGrain: getTimeGrainLabel(timeGrain) }
                  )})`}
                </TooltipTableHeadCell>
                <TooltipTableHeadCell colSpan={2} sx={{ width: 84 }}>
                  {`${intl.formatMessage({
                    id: 'progressReport.actual',
                  })} (${intl.formatMessage(
                    { id: 'progressReport.compareToPrev' },
                    { timeGrain: getTimeGrainLabel(timeGrain) }
                  )})`}
                </TooltipTableHeadCell>
                <TooltipTableHeadCell sx={{ width: 50 }}>
                  {intl.formatMessage({
                    id: 'progressReport.preceding',
                  })}
                </TooltipTableHeadCell>
                <TooltipTableHeadCell sx={{ width: 50 }}>
                  {intl.formatMessage({
                    id: 'progressReport.delayed',
                  })}
                </TooltipTableHeadCell>
              </TableRow>
            )}
            {showActualOnly && (
              <TableRow>
                <TooltipTableHeadCell sx={{ width: 120 }} />
                <TooltipTableHeadCell colSpan={2} sx={{ width: 84 }}>
                  {`${intl.formatMessage({
                    id: 'progressReport.actual',
                  })} (${intl.formatMessage(
                    { id: 'progressReport.compareToPrev' },
                    { timeGrain: getTimeGrainLabel(timeGrain) }
                  )})`}
                </TooltipTableHeadCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {summaries.map((summary, i) => {
              const {
                color,
                type,
                aggregateColumnGroup,
                currValues,
                prevValues,
                currWbsItemValueMaps,
                prevWbsItemValueMaps,
                direction,
              } = summary
              return (
                <ScheduledAndActualTooltipRow
                  key={`summary-tooltip-row-${i}`}
                  sx={{
                    borderBottom: '2px dotted #f5f5f5',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                  currValues={currValues}
                  prevValues={prevValues}
                  currValueMaps={currWbsItemValueMaps}
                  prevValueMaps={prevWbsItemValueMaps}
                  formatter={formatter}
                  direction={direction}
                  label={{ color, type, aggregateColumnGroup }}
                  showActualOnly={showActualOnly}
                />
              )
            })}
          </TableBody>
        </Table>
      </ProgressReportTooltipBody>
    </ProgressReportTooltip>
  )
}
