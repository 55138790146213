import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import TeamAPI from '../../../../lib/functions/team'
import { Autocomplete, Popper } from '@mui/material'
import store from '../../../../store'
import AlertDialog, { DialogProps } from '../../dialogs/AlertDialog'
import TextInput from '../input/TextInput'
import { intl } from '../../../../i18n'

const AutoCompleteTeamName = styled(Autocomplete)({
  width: '175px',
  minWidth: '175px',
  height: '30px',
  marginRight: '6px',
  '& .MuiAutocomplete-inputRoot': {
    height: '100%',
    paddingTop: '3px !important',
  },
})

interface TeamProps {
  value: string | number
  label: string
}

interface Props {
  projectUuid: string
  onTeamChanged: Function
  teamUuid?: string
}

const TeamSelect = (props: Props) => {
  const [teams, setTeams] = useState<TeamProps[]>([])
  const [options, setOptions] = useState<string[]>([])
  const [selectedItemLabel, setSelectedItemLabel] = useState<string>('')
  const [pendingItemLabel, setPendingItemLabel] = useState<string>()
  const [inputValue, setInputValue] = useState<string>('')
  const [dialogState, setDialogState] = useState<DialogProps>({ isOpen: false })
  const [prevTeamUuid, setPrevTeamUuid] = useState<string>()
  const [prevProjectUuid, setPrevProjectUuid] = useState<string>()

  useEffect(() => {
    makeTeams()
  }, [])

  useEffect(() => {
    const isChangedTeam =
      prevTeamUuid && props.teamUuid && prevTeamUuid !== props.teamUuid
    if (
      (!prevTeamUuid && props.teamUuid && !selectedItemLabel) ||
      isChangedTeam ||
      (!prevProjectUuid && props.projectUuid)
    ) {
      makeTeams()
    }
    if (prevTeamUuid && !props.teamUuid) {
      setSelectedItemLabel('')
      setInputValue('')
    }
    setPrevTeamUuid(props.teamUuid)
    setPrevProjectUuid(props.projectUuid)
  }, [props.teamUuid, props.projectUuid, prevTeamUuid, prevProjectUuid])

  useEffect(() => {
    if (!props.teamUuid) return
    const selectedItem = teams.find(v => v.value === props.teamUuid)
    if (selectedItem) {
      const label = selectedItem.label
      setSelectedItemLabel(label)
      setInputValue(label)
    }
  }, [teams, props.teamUuid])

  const makeTeams = useCallback(async () => {
    if (!props.projectUuid) {
      return
    }
    const response = await TeamAPI.searchAll()
    const options = response.map((team: any) => team.displayName)
    const teams = response.map((team: any) => ({
      label: team.displayName,
      value: team.uuid,
    }))
    const selectedTeam = teams.find(team => team.value === props.teamUuid)
    setTeams(teams)
    setOptions(options)
    setSelectedItemLabel(selectedTeam?.label ?? '')
  }, [])

  const onChange = useCallback(
    (event, value) => {
      if (store.getState().hasRequiredSaveData.hasRequiredSaveData && value) {
        setPendingItemLabel(value)
        openConfirmationDialog()
        return
      }
      changeTeam(value)
    },
    [teams]
  )

  const changeTeam = useCallback(
    value => {
      setSelectedItemLabel(value)
      const selectedItem = teams.find(v => v.label === value)
      props.onTeamChanged(selectedItem?.value ?? '')
    },
    [teams]
  )

  const openConfirmationDialog = useCallback(() => {
    setDialogState({
      isOpen: true,
      title: intl.formatMessage({
        id: 'teamChange.confirm.title',
      }),
      message: intl.formatMessage({
        id: 'teamChange.confirm.message',
      }),
      submitButtonTitle: intl.formatMessage({
        id: 'yes',
      }),
      submitHandler: onConfirmSubmit,
      closeHandler: onConfirmClose,
      closeButtonTitle: intl.formatMessage({ id: 'no' }),
    })
  }, [])

  const onConfirmSubmit = useCallback(() => {
    changeTeam(pendingItemLabel)
    setDialogState({ isOpen: false })
  }, [pendingItemLabel])

  const onConfirmClose = useCallback(() => {
    setInputValue(selectedItemLabel)
  }, [selectedItemLabel])

  const closeDialog = useCallback(() => {
    setDialogState({ isOpen: false })
  }, [])

  const onChangeInput = useCallback((event, value) => {
    setInputValue(value)
  }, [])

  return (
    <>
      <AlertDialog {...dialogState} closeHandler={closeDialog} />
      <AutoCompleteTeamName
        value={selectedItemLabel}
        options={options}
        size={'small'}
        onChange={onChange}
        inputValue={inputValue}
        onInputChange={onChangeInput}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        renderInput={params => (
          <TextInput
            {...params}
            placeholder={intl.formatMessage({
              id: 'teamChange.teamSelect',
            })}
            variant="outlined"
            fullWidth={true}
            sx={{
              height: '100%',
            }}
          />
        )}
      />
    </>
  )
}

export default TeamSelect
