import { Box, DialogActions, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { UiStateKey, UiStateScope } from '../../../../lib/commons/uiStates'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import SavedUIStateList, {
  CustomListItemProps as OptionUiStateListCustomItemProps,
  SavedUIState,
  UIState,
} from './SavedUIStateList'
import BaseSelect from '../../editors/select/Select'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import { colorPalette } from '../../../style/colorPallete'
import { FontSize } from '../../../../styles/commonStyles'
import { ScopeIcon } from './contents/ScopeIcon'
import CancelIcon from '../../../../assets/cancel_icon.svg'
import { Button } from '../../buttons'

// Styles
const DialogContent = styled(MuiDialogContent)({
  width: '700px',
  paddingBottom: '0',
})
const SavedUIStateListTitle = styled('p')({
  margin: '5px 0 3px 0',
})
const ScopeOption = styled('p')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  color: colorPalette.monotone[4],
})
const Select = styled(BaseSelect)({
  height: '30px',
  pointerEvents: 'none',
  '& > svg': {
    display: 'none',
  },
})
const SelectMenuItem = styled(MenuItem)({
  height: 'fit-content',
  width: '220px',
  margin: '4px',
  borderRadius: '4px',
  color: colorPalette.monotone[4],
  gap: '4px',
  padding: '0 10px',
  '&:hover': { background: colorPalette.skyBlue[0] },
  '&.Mui-selected': {
    backgroundColor: 'none',
    background: 'none',
    '&:hover': {
      background: colorPalette.skyBlue[0],
    },
  },
  '.MuiMenuItem-root': {
    backgroundColor: 'none',
    background: 'none',
    '&:hover': {
      backgroundColor: 'none',
      background: colorPalette.skyBlue[0],
    },
  },
})

export interface OptionUIStateListProps {
  title?: string
  applicationFunctionUuid: string
  uiStateKey: UiStateKey
  customItemTitle?: string
  getCustomItemUIState?: () => UIState
}

interface Props extends WrappedComponentProps {
  savedUIState: SavedUIState
  open: boolean
  dialogTitle: string
  dialogSubTitle?: string
  submitButtonLabel?: string
  cancelButtonLabel?: string
  onSubmit: (
    savedUIState: SavedUIState,
    optionUIState: UIState | undefined
  ) => void
  onClose: () => void
  optionUIStateListProps?: OptionUIStateListProps
  projectUuid?: string
  hasAllPrivileges?: boolean
  onDelete?: () => void
}

interface State {
  name: string
  UIState: UIState
  optionUIState?: UIState
  scope: UiStateScope
  scopeSelectOpen: boolean
  errorMessage?: string
}

class SavedUIStateEditorDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = this.propsToState(props)
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open && !!this.props.open) {
      this.setState(this.propsToState(this.props))
    }
  }

  private propsToState = (props: Props) => {
    let optonUIState: UIState = undefined
    if (!!props.optionUIStateListProps?.getCustomItemUIState) {
      optonUIState = props.optionUIStateListProps.getCustomItemUIState()
    }
    return {
      name: props.savedUIState.name,
      UIState: props.savedUIState.UIState,
      optionUIState: optonUIState,
      scope: props.savedUIState.scope,
      scopeSelectOpen: false,
    }
  }

  private onChangeName = event => {
    const name: string = event.target.value
    if (name?.length > 56) {
      this.setState({
        name: name,
        errorMessage: this.props.intl.formatMessage({
          id: 'savedUIState.edit.message.validate.length',
        }),
      })
    } else {
      this.setState({ name: event.target.value, errorMessage: undefined })
    }
  }

  private onChangeScope = event => {
    this.setState({ scope: event.target.value })
  }

  private onChangeScopeSelectOpen = event => {
    this.setState({ scopeSelectOpen: !this.state.scopeSelectOpen })
  }

  private submit = event => {
    if (this.shouldDisableSubmit()) return
    this.props.onSubmit(
      {
        uuid: this.props.savedUIState.uuid,
        code: this.props.savedUIState.code,
        name: this.state.name,
        UIState: this.state.UIState,
        scope: this.state.scope,
      },
      this.state.optionUIState
    )
  }

  private close = event => {
    this.props.onClose()
  }

  private onClearName = event => {
    this.setState({ name: '', errorMessage: undefined })
  }

  private getUiStateListCustomItemProps = ():
    | OptionUiStateListCustomItemProps
    | undefined => {
    if (!this.props.optionUIStateListProps) {
      return undefined
    }
    const { customItemTitle, getCustomItemUIState } =
      this.props.optionUIStateListProps
    if (!customItemTitle || !getCustomItemUIState) {
      return undefined
    }
    return {
      title: customItemTitle,
      onSelect: () => {
        this.setState({ optionUIState: getCustomItemUIState() })
      },
    } as OptionUiStateListCustomItemProps
  }

  private shouldDisableSubmit = () =>
    !this.state.name || !!this.state.errorMessage

  render() {
    const options = getOptions(this.props)

    return (
      <Dialog maxWidth="lg" open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle id="dialog-title">
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderBottom: `1px solid ${colorPalette.monotone[2]}`,
              padding: '16px 0',
            }}
          >
            <Box style={{ color: colorPalette.monotone[10] }}>
              {this.props.dialogTitle}
            </Box>
            <Box
              style={{
                color: colorPalette.monotone[5],
                marginLeft: '8px',
                fontSize: FontSize.X_SMALL,
                fontWeight: 300,
              }}
            >
              {this.props.dialogSubTitle}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              margin: '16px 0',
            }}
          >
            <Box
              style={{
                color: colorPalette.monotone[10],
                width: '128px',
              }}
            >
              {this.props.intl.formatMessage({
                id: 'savedUIState.scope',
              })}
            </Box>
            <FormControl
              variant="standard"
              size="small"
              sx={{
                display: 'contents',
              }}
            >
              <Select
                required={true}
                value={this.state.scope}
                onChange={this.onChangeScope}
                MenuItem={props => <SelectMenuItem {...props} />}
                variant="standard"
                options={options}
                open={this.state.scopeSelectOpen}
                onClose={this.onChangeScopeSelectOpen}
              />
              <Box
                sx={{
                  color: colorPalette.skyBlue[8],
                  padding: '8px',
                  fontSize: FontSize.SMALL,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: colorPalette.skyBlue[0],
                  },
                }}
                onClick={this.onChangeScopeSelectOpen}
              >
                {this.props.intl.formatMessage({
                  id: 'savedUIState.scope.change',
                })}
              </Box>
            </FormControl>
          </Box>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Box
              style={{
                color: colorPalette.monotone[10],
                width: '128px',
              }}
            >
              {this.props.intl.formatMessage({
                id: 'savedUIState.name',
              })}
            </Box>
            <TextField
              id="name"
              variant="outlined"
              required={true}
              value={this.state.name}
              onChange={this.onChangeName}
              autoFocus={true}
              sx={{
                width: '524px',
              }}
              InputProps={{
                endAdornment: (
                  <img
                    src={CancelIcon}
                    style={{
                      cursor: 'pointer',
                      padding: '8px',
                    }}
                    onClick={this.onClearName}
                  />
                ),
              }}
              placeholder={this.props.intl.formatMessage({
                id: 'savedUIState.edit.placeholder',
              })}
              error={!!this.state.errorMessage}
              helperText={this.state.errorMessage}
            />
          </Box>
          {this.props.optionUIStateListProps && (
            <>
              <SavedUIStateListTitle>
                {this.props.optionUIStateListProps.title}
              </SavedUIStateListTitle>
              <SavedUIStateList
                uiStateKey={this.props.optionUIStateListProps.uiStateKey}
                applicationFunctionUuid={
                  this.props.optionUIStateListProps.applicationFunctionUuid
                }
                currentUIState={{}}
                title={''}
                sharable={false}
                onSelect={(selectedSavedState: SavedUIState) => {
                  this.setState({
                    optionUIState: selectedSavedState.UIState,
                  })
                }}
                hiddenAddButton={true}
                hiddenSecondaryAction={true}
                displayBorder={true}
                customListItemProps={this.getUiStateListCustomItemProps()}
              />
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            padding: '16px',
          }}
        >
          {this.props.onDelete && (
            <Button
              colorPattern="pink"
              variant="outlined"
              onClick={this.props.onDelete}
              sx={{
                textAlign: 'center',
                minHeight: '44px',
                fontWeight: 600,
              }}
            >
              {this.props.intl.formatMessage({
                id: 'savedUIState.edit.button.delete',
              })}
            </Button>
          )}
          <Button
            colorPattern="monotone"
            variant="outlined"
            onClick={this.close}
            sx={{
              textAlign: 'center',
              minHeight: '44px',
              fontWeight: 600,
            }}
          >
            {!!this.props.cancelButtonLabel
              ? this.props.cancelButtonLabel
              : this.props.intl.formatMessage({ id: 'dialog.cancel' })}
          </Button>
          <Button
            colorPattern="skyBlue"
            variant="filled"
            onClick={this.submit}
            disabled={this.shouldDisableSubmit()}
            sx={{
              padding: '0 16px',
              textAlign: 'center',
              minHeight: '44px',
              fontWeight: 600,
            }}
          >
            {!!this.props.submitButtonLabel
              ? this.props.submitButtonLabel
              : this.props.intl.formatMessage({ id: 'dialog.ok' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const getOptions = (props: Props) => {
  const { projectUuid, hasAllPrivileges, intl } = props
  if (projectUuid && hasAllPrivileges) {
    return [
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.User} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.private',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.User,
      },
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.Tenant} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.project',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.Tenant,
      },
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.CrossProject} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.tenant',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.CrossProject,
      },
    ]
  } else if (projectUuid) {
    return [
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.User} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.private',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.User,
      },
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.Tenant} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.project',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.Tenant,
      },
    ]
  } else if (hasAllPrivileges) {
    return [
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.User} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.private',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.User,
      },
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.Tenant} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.tenant',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.Tenant,
      },
    ]
  } else {
    return [
      {
        label: (
          <ScopeOption>
            <ScopeIcon scope={UiStateScope.User} />
            <Typography sx={{ marginLeft: '8px' }}>
              {intl.formatMessage({
                id: 'savedUIState.scope.private',
              })}
            </Typography>
          </ScopeOption>
        ),
        value: UiStateScope.User,
      },
    ]
  }
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
  hasAllPrivileges: state.user.user?.hasAllPrivileges,
})

export default connect(mapStateToProps)(injectIntl(SavedUIStateEditorDialog))
