import {
  LoginButton,
  LoginCheckbox,
  LoginContainer,
  LoginTitle,
} from './components'
import { intl } from '../../../i18n'
import { styled } from '@mui/material'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { useCallback, useEffect, useState } from 'react'
import { useAgreementRepository } from '../../../services/adaptors/agreementRepositoryAdaptor'
import { useHistory } from 'react-router'
import { AgreementEntity } from '../../../domain/entity/AgreementEntity'
import { useAgreementsUseCase } from '../../../applications/usecases/agreements/agreementsUseCases'

const LoginText = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
type AgreementsBoxProps = {
  active: boolean
}
const AgreementsBox = styled('div')(({ active }: AgreementsBoxProps) => ({
  display: 'flex',
  width: '476px',
  height: '64px',
  padding: '16px',
  borderRadius: '4px',
  border: `1px solid ${active ? '#00A0E9' : '#D8DDE5'}`,
  backgroundColor: active ? '#F6FCFE' : '#F7F8FA',
}))
const StyledOpenInNewIcon = styled(OpenInNewRoundedIcon)({
  width: '16px',
  height: '16px',
})

const LinkItem = styled('a')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  flexDirection: 'row',
  textDecoration: 'none',
})

export const Agreements = () => {
  const history = useHistory()
  const { getAgreements } = useAgreementRepository()
  const { agreeTerms } = useAgreementsUseCase()
  const [agreements, setAgreements] = useState<AgreementEntity[]>([])
  useEffect(() => {
    const fn = async () => {
      const { agreements: fetchedAgreements, userAgreementLogs } =
        await getAgreements()
      const remainingAgreements = fetchedAgreements.filter(
        agreement =>
          !userAgreementLogs.find(log => log.uuid === agreement.uuid)
            ?.latestVersion
      )
      if (remainingAgreements.length === 0) {
        window.location.reload()
      }
      setAgreements(remainingAgreements)
      setChecked(remainingAgreements.map(_ => false))
    }
    fn()
  }, [getAgreements, history])
  const [checked, setChecked] = useState<boolean[]>([])
  const onChecked = useCallback((index: number) => {
    setChecked(prev => prev.map((bool, i) => (i === index ? !bool : bool)))
  }, [])
  const onSubmit = useCallback(async () => {
    if (checked.some(bool => !bool)) return
    await agreeTerms(agreements)
    window.location.reload()
  }, [agreeTerms, agreements, checked])
  const back = useCallback(() => {
    history.push('/loggedOut')
  }, [history])
  return (
    <LoginContainer>
      <LoginTitle>{intl.formatMessage({ id: 'agreement' })}</LoginTitle>
      <LoginText>
        {intl.formatMessage({ id: 'agreement.termsOfService' })}
      </LoginText>
      {agreements.map((agreement, i) => (
        <AgreementsBox active={checked[i]} key={i}>
          <LoginCheckbox checked={checked[i]} onChange={() => onChecked(i)} />
          <LinkItem href={agreement.termUrl} target="_blank">
            {agreement.name}
            <StyledOpenInNewIcon />
          </LinkItem>
        </AgreementsBox>
      ))}
      <LoginButton
        color={'skyBlue'}
        disabled={checked.some(bool => !bool)}
        onClick={onSubmit}
      >
        {intl.formatMessage({ id: 'agreement.agreeNext' })}
      </LoginButton>
      <LoginButton color={'monotone'} onClick={back}>
        {intl.formatMessage({ id: 'back' })}
      </LoginButton>
    </LoginContainer>
  )
}
