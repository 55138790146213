import { ICellEditorParams } from 'ag-grid-community'
import { InputBase } from '@mui/material'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export const CheckBoxCellEditor = forwardRef(
  (props: ICellEditorParams, ref) => {
    const strToBool = useCallback((val?: string): boolean | undefined => {
      if (val === undefined) return undefined
      if (val.match(/^([tT]|check|1)/)) return true
      if (val.match(/^[fF]|0/)) return false
      return undefined
    }, [])

    const [value, setValue] = useState<boolean | undefined>(
      props.value === undefined
        ? undefined
        : !!props.value?.toString().match(/^([tT]|check|1)/)
    )
    const [text, setText] = useState(props.charPress ?? props.value ?? '')

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      inputRef.current?.focus()
      if (props.charPress) {
        setValue(strToBool(props.charPress))
      }
    }, [])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value
        },
      }
    })

    return (
      <InputBase
        inputRef={inputRef}
        value={text}
        onChange={e => {
          setValue(strToBool(e.target.value))
          setText(e.target.value)
        }}
      />
    )
  }
)
