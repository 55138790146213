import ReactDatePicker from 'react-datepicker'
import './style.scss'
import { InputBase, Popper, PopperProps, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import * as d3 from 'd3'
import { completeDateString } from '../../../commons/inputSupporter'
import _ from 'lodash'
import { colorPalette } from '../../../style/colorPallete'

type DatePickerProps = {
  value: Date | undefined
  onChange: (v: Date) => void
  // This interval is open-closed interval, which means the start is not included, but the end included.
  includeDateIntervals?: [{ start: Date; end: Date }]
}
type DatePickerPopupProps = DatePickerProps
export const DatePicker = ({
  value,
  onChange: _onChange,
  ...others
}: DatePickerProps) => {
  const onChange = useCallback(
    (v: Date) => {
      const value = d3.timeDay.floor(v)
      _onChange(value)
    },
    [_onChange]
  )
  return (
    <ReactDatePicker
      selected={value}
      onChange={onChange}
      inline={true}
      {...others}
    />
  )
}

type DateInputProps = {
  value?: Date
  initialValueOnCalendar?: Date
  onChange?: (value?: Date) => void
  onSelect: (value?: Date) => void
  placeholder?: string
  editable?: boolean
}
export const DateInput = ({
  value,
  initialValueOnCalendar,
  onChange,
  onSelect,
  placeholder,
  editable = true,
}: DateInputProps) => {
  const [changedByInput, setChangedByInput] = useState(false)
  const [calendarOpened, setCalendarOpened] = useState(false)

  useEffect(() => {
    setChangedByInput(false)
  }, [value])

  const onSelectCallback = useCallback(
    (value?: Date) => {
      if (!changedByInput) {
        onSelect(value)
      } else {
        setChangedByInput(false)
      }
    },
    [onSelect, changedByInput]
  )
  const onChangeCaptureCallback = useCallback(
    _.debounce(event => {
      const inputValue = event.target.value
      setChangedByInput(true)

      if (!inputValue) {
        // 値をクリアした時
        onSelect(undefined)
      }
      const value = completeDateString(inputValue)
      if (!value) {
        return
      }
      const newValue = new Date(value.replace(/\//g, '-'))
      onSelect(newValue)
    }, 200),
    [changedByInput, onSelect]
  )

  return (
    <ReactDatePicker
      selected={value || (calendarOpened ? initialValueOnCalendar : undefined)}
      dateFormat={'yyyy/MM/dd'}
      onChange={onChange}
      onSelect={onSelectCallback}
      placeholderText={placeholder || 'YYYY/MM/DD'}
      customInput={
        <TextInput
          onChangeCapture={onChangeCaptureCallback}
          onFocusCapture={() => setCalendarOpened(true)}
          onBlurCapture={() => setCalendarOpened(false)}
        />
      }
      popperPlacement="bottom-end"
      disabled={!editable}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.stopPropagation()
          e.currentTarget.blur()
        }
      }}
      fixedHeight={true}
    />
  )
}

const TextInput = styled(InputBase)({
  width: '100%',
  color: colorPalette.monotone[10],
})
