import React, { useCallback, useEffect, useState } from 'react'
import { ProjectPlanCumulation } from '../../../../../../lib/functions/projectPlan'

export type ProjectPlanStats = {
  estimatedProgressRate: number
  progressRate: number
  budgetAtCompletion: number
  plannedValue: number
  earnedValue: number
  delayed: number
  preceding: number
  remaining: number
  unplanned: number
  actualCost: number
  costPerformanceIndex: number
}

export const useAggregateInformation = (cumulation: ProjectPlanCumulation) => {
  const [deliverableWorkload, setDeliverableWorkload] =
    useState<ProjectPlanStats>()
  const [deliverableCount, setDeliverableCount] = useState<ProjectPlanStats>()
  const [taskWorkload, setTaskWorkload] = useState<ProjectPlanStats>()
  const [taskCount, setTaskCount] = useState<ProjectPlanStats>()

  const generateDeliverableWorkload = useCallback((): ProjectPlanStats => {
    const c = cumulation
    const budgetAtCompletion =
      c.sumDeliverableEstimatedHour - c.sumDeliverableEstimatedHourDiscard
    const earnedValue = c.sumDeliverableEstimatedHourDone
    const plannedValue = c.sumDeliverableToBeCompleted
    return {
      estimatedProgressRate: plannedValue / budgetAtCompletion,
      progressRate: earnedValue / budgetAtCompletion,
      budgetAtCompletion,
      plannedValue,
      earnedValue,
      delayed: c.sumDeliverableEndDelayed,
      preceding: c.sumDeliverableEndPreceding,
      remaining: budgetAtCompletion - earnedValue,
      unplanned: c.sumDeliverableEndUnplanned,

      actualCost: c.sumActualHour,
      costPerformanceIndex: earnedValue / c.sumActualHour,
    }
  }, [cumulation])

  const generateDeliverableCount = useCallback((): ProjectPlanStats => {
    const c = cumulation
    const budgetAtCompletion =
      c.countDeliverable - c.countStatusDeliverableDiscard
    const earnedValue = c.countStatusDeliverableDone
    const plannedValue = c.countDeliverableToBeCompleted
    return {
      estimatedProgressRate: plannedValue / budgetAtCompletion,
      progressRate: earnedValue / budgetAtCompletion,
      budgetAtCompletion,
      plannedValue,
      earnedValue,
      delayed: c.countDeliverableEndDelayed,
      preceding: c.countDeliverableEndPreceding,
      remaining: budgetAtCompletion - earnedValue,
      unplanned: c.countDeliverableEndUnplanned,

      actualCost: c.sumActualHour,
      costPerformanceIndex: c.sumDeliverableEstimatedHourDone / c.sumActualHour,
    }
  }, [cumulation])

  const generateTaskWorkload = useCallback((): ProjectPlanStats => {
    const c = cumulation
    const budgetAtCompletion =
      c.sumTaskEstimatedHour - c.sumTaskEstimatedHourDiscard
    const earnedValue = c.sumTaskEstimatedHourDone
    const plannedValue = c.sumTaskToBeCompleted
    return {
      estimatedProgressRate: plannedValue / budgetAtCompletion,
      progressRate: earnedValue / budgetAtCompletion,
      budgetAtCompletion,
      plannedValue,
      earnedValue,
      delayed: c.sumTaskEndDelayed,
      preceding: c.sumTaskEndPreceding,
      remaining: budgetAtCompletion - earnedValue,
      unplanned: c.sumTaskEndUnplanned,

      actualCost: c.sumActualHour,
      costPerformanceIndex: earnedValue / c.sumActualHour,
    }
  }, [cumulation])

  const generateTaskCount = useCallback((): ProjectPlanStats => {
    const c = cumulation
    const budgetAtCompletion = c.countTask - c.countStatusTaskDiscard
    const earnedValue = c.countStatusTaskDone
    const plannedValue = c.countTaskToBeCompleted
    return {
      estimatedProgressRate: plannedValue / budgetAtCompletion,
      progressRate: earnedValue / budgetAtCompletion,
      budgetAtCompletion,
      plannedValue,
      earnedValue,
      delayed: c.countTaskEndDelayed,
      preceding: c.countTaskEndPreceding,
      remaining: budgetAtCompletion - earnedValue,
      unplanned: c.countTaskEndUnplanned,

      actualCost: c.sumActualHour,
      costPerformanceIndex: c.sumTaskEstimatedHourDone / c.sumActualHour,
    }
  }, [cumulation])

  useEffect(() => {
    setDeliverableWorkload(generateDeliverableWorkload)
    setDeliverableCount(generateDeliverableCount)
    setTaskWorkload(generateTaskWorkload)
    setTaskCount(generateTaskCount)
  }, [cumulation])

  return {
    deliverableWorkload,
    deliverableCount,
    taskWorkload,
    taskCount,
  }
}
