import { TextField } from '@mui/material'
import { styled } from '@mui/system'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { intl } from '../../../../../../../../i18n'
import objects from '../../../../../../../../utils/objects'
import DateVO from '../../../../../../../../vo/DateVO'
import ResetButton from '../../../../../../../components/buttons/ResetButton'
import DateInputWithClassVO from '../../../../../../../components/editors/input/DateInput/DateInputWithClassVO'
import CommentCellDateOperatorSelect, {
  CommentCellDateFilterOperator,
  CommentCellDateFilterOperatorLabel,
} from './CommentCellDateOperatorSelect'
import CommentCellTextOperatorSelect, {
  CommentCellTextFilterOperator,
  CommentCellTextFilterOperatorLabel,
} from './CommentCellTextOperatorSelect'

interface CommentFilterState {
  dateValue?: DateVO
  textValue?: string
  textFilterOperator?: CommentCellTextFilterOperator
  dateFilterOperator?: CommentCellDateFilterOperator
  restored?: boolean
}

const RootDiv = styled('div')({
  padding: '3px 8px',
  display: 'flex',
})
const StyledCommentCellDateOperatorSelect = styled(
  CommentCellDateOperatorSelect
)({
  minWidth: 35,
  margin: 'auto 5px auto 0',
})
const StyledCommentCellTextOperatorSelect = styled(
  CommentCellTextOperatorSelect
)({
  minWidth: 35,
  margin: 'auto 5px auto 0',
})
const StyledDateInputWithClassVO = styled(DateInputWithClassVO)({
  width: 110,
  margin: 'auto 5px auto 0',
  alignItems: 'center',
})
const StyledTextField = styled(TextField)({
  width: 105,
  marginRight: '10px !important',
  '& .MuiOutlinedInput-root': {
    '& .MuiInputBase-input': {
      padding: '0px !important',
    },
  },
})
const StyledResetButton = styled(ResetButton)({
  whiteSpace: 'nowrap',
  margin: 'auto 0',
})

const CommentCellFilter = ({
  key,
  commentFilterState,
  onChangeTextValue,
  onChangeDateValue,
  onChangeTextFilterOperator,
  onChangeDateFilterOperator,
  clearTextFilter,
  clearDateFilter,
}: {
  key: string
  commentFilterState: CommentFilterState
  onChangeTextValue: (event: React.ChangeEvent) => void
  onChangeDateValue: (dateValue?: DateVO) => void
  onChangeTextFilterOperator: (operator?: CommentCellTextFilterOperator) => void
  onChangeDateFilterOperator: (operator?: CommentCellDateFilterOperator) => void
  clearTextFilter: () => void
  clearDateFilter: () => void
}) => (
  <>
    <RootDiv>
      <StyledCommentCellDateOperatorSelect
        key={`${key}-${commentFilterState.dateFilterOperator}`}
        value={commentFilterState.dateFilterOperator}
        onChange={onChangeDateFilterOperator}
      />
      <StyledDateInputWithClassVO
        value={commentFilterState.dateValue}
        onChange={onChangeDateValue}
      />
      <StyledResetButton
        label={intl.formatMessage({ id: 'clear' })}
        onClick={clearDateFilter}
      />
    </RootDiv>
    <RootDiv>
      <StyledCommentCellTextOperatorSelect
        key={`${key}-${commentFilterState.textFilterOperator}`}
        value={commentFilterState.textFilterOperator}
        onChange={onChangeTextFilterOperator}
      />
      <StyledTextField
        variant="outlined"
        size="small"
        onChange={onChangeTextValue}
        value={commentFilterState.textValue}
      />
      <StyledResetButton
        label={intl.formatMessage({ id: 'clear' })}
        onClick={clearTextFilter}
      />
    </RootDiv>
  </>
)

interface Props {
  filterChangedCallback: () => void
  colDef: {
    field: string
  }
}

const MILLSECOND_PER_DAY = 24 * 60 * 60 * 1000

export default forwardRef((props: Props, ref) => {
  const [commentFilterState, setCommentFilterState] =
    useState<CommentFilterState>({
      textFilterOperator: CommentCellTextFilterOperator.CONTAINS,
      dateFilterOperator: CommentCellDateFilterOperator.GTE,
    })

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        // Don't filter new rows
        if (params.data.isAdded) return true
        if (
          !commentFilterState.dateFilterOperator &&
          !commentFilterState.textFilterOperator
        ) {
          return true
        }
        const latestComment = objects.getValue(params.data, props.colDef.field)
        const createdAt = latestComment ? latestComment.createdAt : undefined
        const textValue = latestComment ? latestComment.text : undefined
        const dateValue = createdAt ? new Date(createdAt).getTime() : undefined
        const filterTextValue = commentFilterState.textValue
          ? commentFilterState.textValue
          : undefined
        const filterDateValue = commentFilterState.dateValue
          ? commentFilterState.dateValue.toDate().getTime()
          : undefined

        let isTextFilterPass
        switch (filterTextValue && commentFilterState.textFilterOperator) {
          case CommentCellTextFilterOperator.CONTAINS:
            isTextFilterPass = textValue && textValue.includes(filterTextValue)
            break
          case CommentCellTextFilterOperator.NOT_CONTAINS:
            isTextFilterPass = textValue && !textValue.includes(filterTextValue)
            break
          default:
            isTextFilterPass = true
        }

        let isDateFilterPass
        if (filterDateValue) {
          switch (commentFilterState.dateFilterOperator) {
            case CommentCellDateFilterOperator.EQ:
              isDateFilterPass =
                dateValue &&
                filterDateValue &&
                filterDateValue <= dateValue &&
                dateValue < filterDateValue + MILLSECOND_PER_DAY
              break
            case CommentCellDateFilterOperator.GTE:
              isDateFilterPass =
                dateValue && filterDateValue && dateValue >= filterDateValue
              break
            case CommentCellDateFilterOperator.LTE:
              isDateFilterPass =
                dateValue && filterDateValue && dateValue <= filterDateValue
              break
            default:
              isDateFilterPass = true
          }
        } else {
          isDateFilterPass = true
        }
        return isTextFilterPass && isDateFilterPass
      },

      isFilterActive() {
        const isTextFilterActive =
          commentFilterState.textValue && commentFilterState.textFilterOperator

        const isDateFilterActive =
          commentFilterState.dateValue && commentFilterState.dateFilterOperator

        return isTextFilterActive || isDateFilterActive
      },

      getModel() {
        if (!commentFilterState || !this.isFilterActive()) return undefined
        return {
          value: {
            ...commentFilterState,
            textValue: commentFilterState.textValue
              ? commentFilterState.textValue
              : undefined,
            dateValue: commentFilterState.dateValue
              ? commentFilterState.dateValue.toLabel()
              : undefined,
          },
        }
      },

      setModel(model) {
        if (model?.value) {
          setCommentFilterState({
            textValue: model.value.textValue,
            dateValue: model.value.dateValue
              ? new DateVO(model.value.dateValue)
              : undefined,
            textFilterOperator: model.value.textFilterOperator,
            dateFilterOperator: model.value.dateFilterOperator,
            restored: true,
          })
        } else {
          setCommentFilterState({
            textValue: undefined,
            dateValue: undefined,
            textFilterOperator: undefined,
            dateFilterOperator: undefined,
            restored: true,
          })
        }
      },

      getModelAsString() {
        const textFilterOperatorLabel =
          commentFilterState.textValue && commentFilterState.textFilterOperator
            ? CommentCellTextFilterOperatorLabel[
                commentFilterState.textFilterOperator
              ]
            : undefined
        const textFilterLabel =
          commentFilterState.textValue && commentFilterState.textFilterOperator
            ? ` : ${commentFilterState.textValue}`
            : ''
        const dateFilterOperatorLabel =
          commentFilterState.dateValue && commentFilterState.dateFilterOperator
            ? CommentCellDateFilterOperatorLabel[
                commentFilterState.dateFilterOperator
              ]
            : undefined
        const dateFilterLabel =
          commentFilterState.dateValue && commentFilterState.dateFilterOperator
            ? ` : ${commentFilterState.dateValue.toLabel()}`
            : ''
        if (!this.isFilterActive()) {
          return ''
        }
        const filterLabels: string[] = []
        if (dateFilterOperatorLabel) {
          filterLabels.push(dateFilterOperatorLabel + dateFilterLabel)
        }
        if (textFilterOperatorLabel) {
          filterLabels.push(textFilterOperatorLabel + textFilterLabel)
        }
        return filterLabels.join(', ')
      },
    }
  })
  useEffect(() => {
    if (!commentFilterState.restored) {
      props.filterChangedCallback()
    }
  }, [commentFilterState])

  const onChangeTextValue = useCallback(
    event => {
      const textValue = event.target.value
      setCommentFilterState({
        ...commentFilterState,
        textValue,
        restored: false,
      })
    },
    [commentFilterState]
  )

  const onChangeDateValue = useCallback(
    dateValue => {
      setCommentFilterState({
        ...commentFilterState,
        dateValue,
        restored: false,
      })
    },
    [commentFilterState]
  )

  const onChangeTextFilterOperator = useCallback(
    textFilterOperator => {
      setCommentFilterState({
        ...commentFilterState,
        textFilterOperator,
        restored: false,
      })
    },
    [commentFilterState]
  )

  const onChangeDateFilterOperator = useCallback(
    dateFilterOperator => {
      setCommentFilterState({
        ...commentFilterState,
        dateFilterOperator,
        restored: false,
      })
    },
    [commentFilterState]
  )

  const clearTextFilter = useCallback(() => {
    setCommentFilterState({
      ...commentFilterState,
      textValue: '',
      textFilterOperator: CommentCellTextFilterOperator.CONTAINS,
      restored: false,
    })
  }, [commentFilterState])

  const clearDateFilter = useCallback(() => {
    setCommentFilterState({
      ...commentFilterState,
      dateValue: undefined,
      dateFilterOperator: CommentCellDateFilterOperator.GTE,
      restored: false,
    })
  }, [commentFilterState])

  return (
    <CommentCellFilter
      key={`comment-cell-filter-${props.colDef.field}`}
      commentFilterState={commentFilterState}
      onChangeTextFilterOperator={onChangeTextFilterOperator}
      onChangeDateFilterOperator={onChangeDateFilterOperator}
      onChangeTextValue={onChangeTextValue}
      onChangeDateValue={onChangeDateValue}
      clearTextFilter={clearTextFilter}
      clearDateFilter={clearDateFilter}
    />
  )
})
