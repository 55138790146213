import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BulkSheetView } from '../../../../containers/BulkSheetView'
import { useMasterScheduleGridOptions } from './gridOptions'
import { MasterScheduleRowData } from './model'
import {
  generateGanttParameter,
  generateGanttScale,
} from '../../../../containers/commons/AgGrid/components/cell/custom/ganttReport/ganttUtil'
import DateVO from '../../../../../vo/DateVO'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../../domain/entity/WbsItemEntity'
import { useMasterScheduleData } from './hooks/data'

type MasterScheduleProps = {
  projectUuid: string
  rootUuid: string | undefined
  targetUuids: string[]
  snapshotUuid: string
}
export const MasterSchedule = ({
  projectUuid,
  rootUuid,
  snapshotUuid,
  targetUuids,
}: MasterScheduleProps) => {
  const { project, wbsItemTypes } = useProjectPrivateContext()
  const gridOptions = useMasterScheduleGridOptions()
  const ref = useRef<HTMLDivElement>(null)
  const { rows } = useMasterScheduleData(projectUuid, snapshotUuid, targetUuids)
  const [ganttDisplayTerm, setGanttDisplayTerm] = useState<{
    start: DateVO
    end: DateVO
  }>({
    start: new DateVO(project.scheduledDate.startDate),
    end: new DateVO(project.scheduledDate.endDate),
  })
  const ganttParameter = useMemo(
    () => generateGanttParameter(ganttDisplayTerm),
    [ganttDisplayTerm]
  )

  const initGanttParam = useCallback(async () => {
    const ganttTimeScale = await generateGanttScale(ganttParameter)
    gridOptions.context = {
      ...gridOptions.context,
      ganttParameter,
      ganttTimeScale,
    }
    gridOptions.api?.refreshHeader()
    gridOptions.api?.refreshCells()
    gridOptions.columnApi?.setColumnWidth(
      'ganttChart',
      ganttTimeScale.length * 40
    )
  }, [ganttParameter])

  useEffect(() => {
    initGanttParam()
  }, [initGanttParam])
  return (
    <>
      {rows.length > 0 && (
        <BulkSheetView
          ref={ref}
          gridOptions={gridOptions}
          rowData={rows}
          // Event
          onGridReady={() => {
            gridOptions.columnApi?.setColumnWidth(
              'ganttChart',
              (gridOptions?.context?.ganttTimeScale?.length ?? 0) * 40
            )
          }}
        />
      )}
    </>
  )
}
