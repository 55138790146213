import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import teamApi, {
  TeamBatchDeltaInput,
  TeamDeltaInput,
  TeamDetail,
  TeamInput,
} from '../../../lib/functions/team'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import ContextMenu, {
  ContextMenuGroup,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { GetContextMenuItemsParams } from 'ag-grid-community'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class TeamRow extends RowData {
  code?: string
  iconUrl?: string
  displayName = ''
  officialName?: string
  revision?: string
}

class TeamRowDataSpec extends RowDataSpec<TeamDetail, TeamRow> {
  createNewRow(): TeamRow {
    return new TeamRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: TeamRow
    parent: TeamRow
  }): TeamRow {
    return params.child
  }
  createRowByResponse(response: TeamDetail): TeamRow {
    return {
      ...response,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }

  duplicateRow(original: TeamRow): TeamRow {
    return {
      ...original,
      code: undefined,
      revision: undefined,
    }
  }
}

interface TeamBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    TeamDetail,
    TeamRow,
    BulkSheetState
  > {}

export default class TeamsOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  TeamDetail,
  TeamRow,
  BulkSheetState
> {
  addable = true
  draggable = true
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.TeamColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new TeamRowDataSpec()
  lockedColumns = ['project.team.code']
  pinnedColumns = [
    'project.team.code',
    'project.team.iconUrl',
    'project.team.displayName',
  ]

  groupColumnWidth = 200
  async getAll(state: BulkSheetState): Promise<APIResponse> {
    return teamApi.getAll(state.uuid)
  }
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: TeamBulkSheetContext
  ): ContextMenu | undefined => {
    return new ContextMenu(
      [
        ctx.generateAddContextMenuGroup(params),
        ctx.generateEditContextMenu(params),
        ctx.generateUtilityContextMenu(params),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  onSubmit = async (
    ctx: TeamBulkSheetContext,
    data: {
      added: TeamRow[]
      edited: {
        before: TeamRow
        after: TeamRow
      }[]
      deleted: TeamRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const state = ctx.state
    const input: TeamBatchDeltaInput = {
      added: data.added.map(this.createRequestByRow).map(v => {
        v.projectUuid = state.uuid
        return v
      }),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v)),
      deleted: data.deleted.map(this.createDeleteRequestByRow),
    }
    return teamApi.updateBatchDelta(input)
  }

  private createRequestByRow = (row: TeamRow): TeamInput => {
    return row
  }

  private createDeltaRequestByRow = ({
    before,
    after,
  }: {
    before: TeamRow
    after: TeamRow
  }): TeamDeltaInput => {
    return {
      uuid: after.uuid,
      parentUuid: {
        oldValue: before.parentUuid,
        newValue: after.parentUuid,
      },
      prevSiblingUuid: {
        oldValue: before.prevSiblingUuid,
        newValue: after.prevSiblingUuid,
      },
      displayName:
        before.displayName !== after.displayName
          ? {
              oldValue: before.displayName,
              newValue: after.displayName,
            }
          : undefined,
      officialName:
        before.officialName !== after.officialName
          ? {
              oldValue: before.officialName,
              newValue: after.officialName,
            }
          : undefined,
      iconUrl:
        before.iconUrl !== after.iconUrl
          ? {
              oldValue: before.iconUrl,
              newValue: after.iconUrl,
            }
          : undefined,
    }
  }

  private createDeleteRequestByRow = (row: TeamRow) => {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion!,
    }
  }
}
