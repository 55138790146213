import {
  Checkbox,
  CheckboxProps,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import checkboxActive from '../../../../assets/checkbox_active_m.svg'
import checkboxInactive from '../../../../assets/checkbox_inactive_m.svg'
import { Support } from './Support'
import { LoginFooter } from './LoginFooter'
import bannerImage from '../../../../assets/flagxs_logo_horizontal_color.svg'

export const LoginContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <LoginRoot>
      <Logo src={bannerImage} height={31} />
      <LoginMain>
        <Support />
        <LoginMainDiv>{children}</LoginMainDiv>
      </LoginMain>
      <LoginFooter />
    </LoginRoot>
  )
}

const LoginRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F7F8FA',
  width: '100vw',
  justifyContent: 'flex-start',
})

const Logo = styled('img')({
  display: 'flex',
  flexDirection: 'row',
  top: '20px',
  alignItems: 'center',
  position: 'absolute',
  left: '20px',
})

const LoginMain = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100vw',
  justifyContent: 'space-around',
  height: 'calc(100% - 92px)',
})

const LoginMainDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '48px 32px',
  gap: '16px',
  width: '540px',
  borderRadius: '12px',
  backgroundColor: '#FFFFFF',
})

export const LoginTitle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: '#243050',
  fontSize: '20px',
  fontWeight: '600',
  paddingBottom: '20px',
})

export const ErrorMessage = styled('div')({
  display: 'flex',
  backgroundColor: '#FCF4F7',
  linearGradient: '#ED1E79',
  border: '1px solid #ED1E79',
  fontSize: '15px',
  fontWeight: '700',
  color: '#ED1E79',
  width: '476px',
  padding: '12px 24px 12px 24px',
  borderRadius: '4px',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
})

export const LoginInput = styled((props: TextFieldProps) => (
  <TextField variant="outlined" {...props} />
))({
  width: '360px',
  '& .MuiOutlinedInput-input': {
    height: '14px',
    padding: '20px 16px',
    color: '#2D4069',
    caretColor: '#00A0E9',
    '&:focus': {
      backgroundColor: '#F7FCFF',
    },
    fontSize: '16px !important',
  },
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      borderColor: '#D8DDE5',
    },
    '&:hover fieldset': {
      borderColor: '#00A0E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00A0E9',
      borderWidth: '1px',
    },
  },
})

type LoginButtonProps = {
  color: 'skyBlue' | 'monotone'
  disabled?: boolean
}

export const LoginButton = styled('button')(
  ({ color, disabled }: LoginButtonProps) => {
    const customizedStyle = useMemo(() => {
      if (disabled) {
        return {
          border: 'none',
          backgroundColor: '#D8DDE5',
          color: '#7B8CAA',
        }
      }
      switch (color) {
        case 'skyBlue':
          return {
            border: 'none',
            backgroundColor: '#00A0E9',
            color: '#FFFFFF',
            cursor: 'pointer',
          }
        case 'monotone':
          return {
            border: '1px solid #D8DDE5',
            backgroundColor: '#FFFFFF',
            color: '#7B8CAA',
            cursor: 'pointer',
          }
        default:
          return {}
      }
    }, [color, disabled])
    return {
      width: '300px',
      height: '30px',
      fontWeight: '600',
      borderRadius: '4px',
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...customizedStyle,
    }
  }
)

export const LoginCheckbox = (props: CheckboxProps) => (
  <Checkbox
    {...props}
    icon={<img src={checkboxInactive} />}
    checkedIcon={<img src={checkboxActive} />}
  />
)

export const LoginLink = styled('a')({
  color: '#7B8CAA',
  textDecoration: 'none',
  cursor: 'pointer',
})
