import { ColDef } from 'ag-grid-community'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import ImportExportIcon from '@mui/icons-material/ImportExportRounded'
import { Button, ClickAwayListener, styled } from '@mui/material'
import { useState } from 'react'
import { intl } from '../../../../i18n'
import {
  Color,
  FontSize,
  FONT_FAMILY,
  IconColor,
  TextColor,
} from '../../../../styles/commonStyles'
import FilterIcon from '../../icons/FilterIcon'
import ColumnListPopper from '../../poppers/ColumnListPopper'
import { ToolbarDivider } from '../../toolbars/Toolbar'

export interface ToolbarFilterGroupProps {
  filteredColumns?: (ColDef | string)[]
  sortedColumns?: (ColDef | string)[]
  onDeletedFilterColumn?: (column: string) => void
}

const GroupRoot = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 3px',
})

const StyledButton = styled(Button)({
  alignItems: 'center',
  justifyContent: 'center',
  outline: 0,
  border: 0,
  height: '24px',
  fontSize: FontSize.MEDIUM,
  fontFamily: FONT_FAMILY,
  color: TextColor.GREY,
})

const IconStyle = {
  width: '14px',
  height: '14px',
  color: IconColor.GREY,
}

const NoticeColor = Color.ALERT

const ToolbarFilterButtonGroup = (props: ToolbarFilterGroupProps) => {
  const [anchorElForFilter, setAnchorElForFilter] =
    useState<HTMLButtonElement>()
  const [anchorElForSort, setAnchorElForSort] = useState<HTMLButtonElement>()
  const close = () => {
    setAnchorElForFilter(undefined)
    setAnchorElForSort(undefined)
  }

  return (
    <ClickAwayListener onClickAway={() => close()}>
      <GroupRoot>
        <StyledButton
          sx={{
            padding: 0,
            minWidth: '100px',
          }}
          onClick={e => {
            setAnchorElForFilter(
              !!anchorElForFilter ? undefined : e.currentTarget
            )
            e.stopPropagation()
          }}
        >
          <FilterIcon
            style={{
              width: '13px',
              height: '13px',
              marginRight: '3px',
              color:
                props.filteredColumns && props.filteredColumns.length > 0
                  ? NoticeColor
                  : IconColor.GREY,
            }}
          />
          {props.filteredColumns &&
            props.filteredColumns.length > 0 &&
            props.filteredColumns.length}
          {intl.formatMessage({
            id: 'toolbar.label.filter',
          })}
          <KeyboardArrowDownRoundedIcon
            sx={{
              ...IconStyle,
            }}
          />
        </StyledButton>
        <ColumnListPopper
          anchorEl={anchorElForFilter}
          columns={props.filteredColumns}
          placement={'bottom-start'}
          onDelete={props.onDeletedFilterColumn}
        />
        <ToolbarDivider />
        <StyledButton
          sx={{ minWidth: '70px', whiteSpace: 'nowrap' }}
          onClick={e => {
            setAnchorElForSort(!!anchorElForSort ? undefined : e.currentTarget)
            e.stopPropagation()
          }}
        >
          <ImportExportIcon
            style={{
              width: '16px',
              height: '16px',
              marginRight: '1px',
              color:
                props.sortedColumns && props.sortedColumns.length > 0
                  ? NoticeColor
                  : IconColor.GREY,
            }}
          />
          {intl.formatMessage({
            id: 'toolbar.label.sort',
          })}
          <KeyboardArrowDownRoundedIcon
            sx={{
              ...IconStyle,
            }}
          />
        </StyledButton>
        <ColumnListPopper
          anchorEl={anchorElForSort}
          columns={props.sortedColumns}
          placement={'bottom-start'}
        />
      </GroupRoot>
    </ClickAwayListener>
  )
}

export default ToolbarFilterButtonGroup
