import React from 'react'
import { styled } from '@mui/system'
import { ColDef, Column, ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import Checkbox from '../../../../../../../components/editors/checkbox/Checkbox'
import { addEventListenerForLastRow } from '../../../../index'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import ViewMeta from '../../../../../../meta/ViewMeta'
import * as validator from '../../../../lib/validator'
import { FontSize } from '../../../../../../../../styles/commonStyles'
import { Tooltip } from '@mui/material'
import { AG_GRID_THEME } from '../../../../../../BulkSheet/const'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const TextLabel = styled('div')({
  justifyContent: 'flex-end',
})
const StyledCheckbox = styled(Checkbox)<{ hidden: boolean }>(({ hidden }) => {
  return {
    margin: '0 auto',
    justifyContent: 'center',
    display: hidden ? 'none' : undefined,
  }
})
const StyledTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    fontSize: FontSize.MEDIUM,
    maxWidth: 465,
    padding: 10,
  },
})

interface Props {
  value: boolean | number | string
  node: RowNode
  colDef: ColDef
  api: GridApi
  column: Column
  columnApi: ColumnApi
  rowIndex: number

  field: string
  uiMeta: FunctionProperty
  viewMeta: ViewMeta
}

interface State {
  value: boolean | number | string
  message?: string
  openTooltip: boolean
}

class CheckBoxCell extends React.PureComponent<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>()
  private ref = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)
    this.state = {
      value: this.props.value,
      openTooltip: false,
    }
  }

  componentDidMount() {
    const focusedCell = this.props.api.getFocusedCell()
    if (
      this.inputRef.current &&
      focusedCell &&
      focusedCell.rowIndex === this.props.rowIndex &&
      focusedCell.column.getColId() === this.props.column.getColId()
    ) {
      this.inputRef.current.focus()
    }
    if (this.ref.current) {
      this.validate(this.state.value)
      addEventListenerForLastRow(this.ref.current, this.props)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value })
    }
  }

  private hide = () => {
    const data = this.props.node.data
    if (data && data.isTotal) {
      return true
    }
    const hide = this.props.colDef.cellEditorParams.hide
    if (typeof hide === 'function') {
      return hide(this.props.node)
    }
    return !!hide
  }

  private disabled = () => {
    const disabled = this.props.colDef.cellEditorParams.disabled

    if (typeof disabled === 'function') {
      return disabled(this.props.node)
    }
    return !!disabled
  }

  private onChange = checked => {
    this.setState({ value: checked })
    this.props.node.setDataValue(this.props.field, checked)
    this.validate(checked)
  }

  private validate = value => {
    if (!this.isCheckboxValue(value)) {
      return
    }
    if (!this.props.uiMeta) {
      return
    }
    const message = validator.validate(
      value,
      this.props.node.data,
      this.props.uiMeta,
      this.props.viewMeta,
      this.props.api
    )
    if (this.ref.current) {
      if (message) {
        this.ref.current.style.color = 'red'
        this.ref.current.style.backgroundColor = validator.inputErrorColor
        this.setState({ message: message })
      } else {
        this.ref.current.style.color = ''
        this.ref.current.style.backgroundColor = ''
        this.setState({ message: undefined })
      }
    }
  }

  getValue = () => {
    return this.state.value
  }

  private isCheckboxValue = value => {
    return typeof value !== 'number' && typeof value !== 'string'
  }

  getLabel = () => {
    if (this.state.value) {
      return this.props.colDef.cellRendererParams.labelTrue || ''
    }
    return this.props.colDef.cellRendererParams.labelFalse || ''
  }

  private setOpenTooltip = (open: boolean) => {
    !!this.state.message && this.setState({ openTooltip: open })
  }

  render() {
    if (this.props.node.data.isViewOnly) {
      return <></>
    }

    return (
      <StyledTooltip
        title={this.state.message || ''}
        placement="top"
        open={this.state.openTooltip}
      >
        <RootDiv
          ref={this.ref}
          onMouseOver={() => this.setOpenTooltip(true)}
          onMouseLeave={() => this.setOpenTooltip(false)}
        >
          {!this.isCheckboxValue(this.state.value) && (
            <TextLabel className="sevend-ag-cell">{this.props.value}</TextLabel>
          )}
          {this.isCheckboxValue(this.state.value) && (
            <StyledCheckbox
              hidden={this.hide()}
              label={
                this.getLabel() && (
                  <span className={AG_GRID_THEME}>{this.getLabel()}</span>
                )
              }
              value={this.state.value}
              checked={!!this.state.value}
              color="primary"
              disabled={this.disabled()}
              onChange={(_, checked) => this.onChange(checked)}
              inputRef={this.inputRef}
            />
          )}
        </RootDiv>
      </StyledTooltip>
    )
  }
}

export default CheckBoxCell
