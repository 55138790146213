import _ from 'lodash'
import { useMemo } from 'react'
import {
  CellKeyDownEvent,
  GridOptions,
  ProcessCellForExportParams,
} from 'ag-grid-community'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { intl } from '../../../../i18n'
import { CustomEnumValue } from '../../../../lib/commons/appFunction'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { format as commentFormat } from '../../../../utils/comment'
import { normalize } from '../../../../utils/multilineText'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  ColumnType,
  columnTypes,
  frameworkComponents,
  processDataFromClipboard,
} from '../../../containers/commons/AgGrid'
import { MULTI_PROPERTY_DELIMITER, WbsItemSearchRow } from '../wbsItemSearch'
import {
  DEFAULT_COLUMN_DEF,
  useColumnDefs,
  PropsForGetColumnDefs,
} from './columnDefs'
import store from '../../../../store'
import { SprintDetail } from '../../../../lib/functions/sprint'
import { ExtensionInfo } from '../../../hooks/useEntityExtension'
import { mergeExtensionColumns } from '../../../containers/BulkSheetView/gridOptions/extension'
import { overwriteColumnByPropertyConfiguration } from '../../../containers/BulkSheetView/gridOptions/propertyConfiguration'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { formatDateTime } from '../../../../utils/date'
import DomainError from '../../../../error/DomainError'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import { InputError } from '../../../containers/BulkSheetView/lib/validation'
import { WorkloadUnit } from '../../../../lib/functions/workload'

type Props = PropsForGetColumnDefs & {
  extensions?: ExtensionInfo
}

export const useWbsItemSearchGridOptions = ({
  projectUuid,
  selectColumnOptionMap: selectColumnOptions,
  onClickedStatusCell,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  extensions,
}: Props): GridOptions => {
  const columnDefs = useColumnDefs({
    projectUuid,
    selectColumnOptionMap: selectColumnOptions,
    onClickedStatusCell,
    onClickedActualHourCell,
    reloadSingleRow,
    submitSingleRow,
  })

  const gridOptions: GridOptions = useMemo(() => {
    if (extensions && !_.isEmpty(extensions?.extensionProperties)) {
      mergeExtensionColumns({
        projectUuid,
        defaultColumns: columnDefs,
        extensionProperties: extensions.extensionProperties,
        propertyConfigurations: extensions.propertyConfigurations,
        generateColDefOptions: {
          filterType: 'CLIENT_SIDE',
        },
      })
      overwriteColumnByPropertyConfiguration({
        columns: columnDefs,
        propertyConfigurations: extensions.propertyConfigurations,
      })
    }

    return {
      context: {
        errors: new InputError(),
        aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
        workloadUnit: WorkloadUnit.HOUR,
        ...selectColumnOptions,
        ...extensions?.extensionOptions,
      },
      treeData: false,
      rowHeight: ROW_HEIGHT.SMALL,
      // Row actions
      enterMovesDownAfterEdit: true,
      rowDragManaged: false,
      rowDragMultiRow: false,
      suppressLastEmptyLineOnPaste: true,
      suppressMoveWhenRowDragging: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: DEFAULT_COLUMN_DEF,
      columnDefs,
      excelStyles: [
        {
          id: 'dateType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd',
          },
        },
        {
          id: 'dateTimeType',
          dataType: 'DateTime',
          numberFormat: {
            format: 'yyyy/mm/dd hh:MM:ss',
          },
        },
      ],
      // Copy & paste
      processCellForClipboard,
      processCellFromClipboard,
      processDataFromClipboard,
      // Footer
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['sum', 'count'],
            },
            align: 'left',
          },
        ],
      },
      localeText: {
        sum: intl.formatMessage({
          id: 'bulksheet.statusPanel.sum',
        }),
        count: intl.formatMessage({
          id: 'bulksheet.statusPanel.count',
        }),
        totalAndFilteredRows: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.title',
        }),
        of: intl.formatMessage({
          id: 'bulksheet.statusPanel.totalAndFilteredRows.division',
        }),
      },
    }
  }, [columnDefs, selectColumnOptions, projectUuid, extensions])
  return gridOptions
}

export const processCellForClipboard = (
  params: ProcessCellForExportParams<WbsItemSearchRow>
) => {
  const { column, value, node, context } = params
  const colDef = column.getColDef()
  const { field, type, cellEditorParams } = colDef
  const row: WbsItemSearchRow | undefined = node?.data

  if (field) {
    if (['action', 'deliverableAttachmentSummary'].includes(field)) {
      return ''
    }
    if (field === 'wbsItem.type') {
      return row?.wbsItem?.baseWbsItemType?.getNameWithSuffix() ?? ''
    }
    if (field === 'wbsItem.ticketType') {
      const wbsItemType = row?.wbsItem?.wbsItemType
      return wbsItemType?.isTicket() ? wbsItemType?.name : ''
    }
    if (field === 'wbsItem.tags') {
      return value?.map(v => v.name).join(TAG_DELIMITER)
    }
    if (field === 'wbsItem.description') {
      return `"${normalize(params.value || '')}"`
    }
    if (field === 'commentSummary.latestComment') {
      return value ? `"${commentFormat(params.value)}"` : ' '
    }
    if (field === 'wbsItem.watchers') {
      return value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER)
    }
  }
  if (!type) {
    if (cellEditorParams) {
      const { valuesAllowed, entity } = cellEditorParams
      if (valuesAllowed) {
        // For extension select column
        const options: CustomEnumValue[] = valuesAllowed ?? []
        const option = options.find(v => v.value === value)
        return option?.name || ''
      }
      if (entity) {
        // For extension EntitySearch column
        const options = context[entity] ?? []
        const option = options.find(v =>
          [value, value?.uuid].filter(v => !!v).includes(v.uuid)
        )
        return (
          getLabel(option?.nameI18n) ||
          option?.displayName ||
          option?.name ||
          ''
        )
      }
    }
    if (typeof value === 'object') {
      return value.name ?? value.displayName
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    return (
      getLabel(value?.nameI18n) ||
      value?.name ||
      value?.displayName ||
      value?.officialName
    )
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const option = options.find(v => v.value === value)
    return getLabel(option?.nameI18n) || option?.name || ''
  }
  if (type.includes(ColumnType.date)) {
    return value ? dateVoService.toString(dateVoService.construct(value)) : ''
  }
  if (type.includes(ColumnType.dateTime)) {
    return value ? formatDateTime(value) : ''
  }
  if (type.includes(ColumnType.multiLineText)) {
    return `"${normalize(params.value || '')}"`
  }
  if (type.includes(ColumnType.multiSelect)) {
    return params?.value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER) ?? ''
  }

  return value
}

export const processCellFromClipboard = ({
  column,
  value,
  context,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field, type, cellEditorParams, valueFormatter } = colDef

  if (field) {
    if (field === 'wbsItem.tags') {
      const projectUuid = store.getState().project.selected
      if (!projectUuid) return
      const names = value.split(TAG_DELIMITER).filter(v => !!v)
      const options = store.getState().tag[projectUuid]
      return options.filter(v => names.includes(v.name))
    }
    if (field === 'wbsItem.watchers') {
      const names = value.split(MULTI_PROPERTY_DELIMITER).filter(v => !!v)
      const options = context['member']
      return options.filter(o => names.includes(o.name))
    }
    if (field === 'wbsItem.sprint') {
      const team = node?.data?.wbsItem.team
      if (!team) return undefined
      return context.sprint?.find(
        (v: SprintDetail) => v.teamUuid === team.uuid && v.name === value
      )
    }
  }
  if (!type) {
    if (cellEditorParams) {
      const { valuesAllowed, entity } = cellEditorParams
      if (valuesAllowed) {
        // For extension select column
        const options: CustomEnumValue[] = valuesAllowed ?? []
        return options.find(o => o.name === value)?.value
      }
      if (entity) {
        // For extension EntitySearch column
        const options = context[entity] ?? []
        return options.find(o =>
          [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
            .filter(v => !!v)
            .includes(value)
        )
      }
    }
    return value
  }
  if (type.includes(ColumnType.autocomplete)) {
    if (cellEditorParams.entity) {
      const options = context[cellEditorParams.entity]
      return options.find(o =>
        [getLabel(o.nameI18n), o.name, o.displayName, o.officialName]
          .filter(v => !!v)
          .includes(value)
      )
    }
    return value
  }
  if (type.includes(ColumnType.customEnum)) {
    const { customEnumCode } = cellEditorParams
    const options: CustomEnumValue[] = context[customEnumCode] ?? []
    const target = options.find(o =>
      [getLabel(o.nameI18n), o.name].filter(v => !!v).includes(value)
    )
    return target?.value
  }
  if (type.includes(ColumnType.date)) {
    const date = dateVoService.construct(value)
    return dateVoService.isValid(date)
      ? dateVoService.toString(date)
      : undefined
  }
  if (type.includes(ColumnType.dateTime)) {
    try {
      return new DateTimeVO(value).toPlainValue()
    } catch (e) {
      if (e instanceof DomainError) return undefined
      throw e
    }
  }
  if (type.includes(ColumnType.multiSelect)) {
    const { valuesAllowed, entity } = cellEditorParams?.uiMeta
    if (!valuesAllowed) return
    const names = value.split(MULTI_PROPERTY_DELIMITER).filter(v => !!v)
    const options: CustomEnumValue[] = valuesAllowed ?? []
    return options.filter(v => names?.includes(v.name)).filter(v => !!v)
  }
  return value
}
