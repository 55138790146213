import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button } from '../../../../components/buttons'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'

interface Props {
  onClick: () => void
}
const FavoriteColumnFilterButton = ({ onClick }: Props) => {
  return (
    <BootstrapTooltip
      arrow={true}
      title={intl.formatMessage({
        id: 'bulksheet.contextMenu.insert.row',
      })}
    >
      <Button
        onClick={onClick}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 5px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <AddRoundedIcon
          sx={{
            height: '100% !important',
            width: '100% !important',
            color: colorPalette.monotone[3],
            flex: '1',
          }}
        />
      </Button>
    </BootstrapTooltip>
  )
}

export default FavoriteColumnFilterButton
