import * as d3 from 'd3'
import { useState, useCallback, useEffect } from 'react'
import { DateTerm } from '../model'
import { DateBucket } from '../../../components/charts/model/timeSeries'

export type DisplayDateTermConfigs = {
  displayDateTerm: DateTerm
  updateDisplayDateTerm: (_: DateTerm) => void
  isBucketIncluded: (_: DateBucket) => boolean
}
export const useDisplayDateTerm = (
  defaultDateTerm: DateTerm
): DisplayDateTermConfigs => {
  const expandDefaultDateTerm = useCallback((dateTerm: DateTerm) => {
    return {
      from: d3.timeDay.offset(dateTerm.from, -1),
      to: d3.timeWeek.offset(dateTerm.to, 2),
    }
  }, [])
  const [displayDateTerm, setDisplayDateTerm] = useState<DateTerm>(
    expandDefaultDateTerm(defaultDateTerm)
  )
  const updateDisplayDateTerm = useCallback(
    (v: DateTerm) =>
      setDisplayDateTerm({
        from: d3.timeDay.floor(v.from),
        to: d3.timeDay.floor(v.to),
      }),
    []
  )
  useEffect(() => {
    const defaultDateTermDaysCount = d3.timeYear.count(
      defaultDateTerm.from,
      defaultDateTerm.to
    )
    if (defaultDateTermDaysCount > 1) {
      const today = new Date()
      const fromValue = Math.max(
        d3.timeMonth.offset(today, -8).valueOf(),
        defaultDateTerm.from.valueOf()
      )
      const from = new Date(fromValue)
      const to = d3.timeMonth.offset(from, 4)
      updateDisplayDateTerm({ from, to })
      return
    }
    updateDisplayDateTerm(expandDefaultDateTerm(defaultDateTerm))
  }, [defaultDateTerm, expandDefaultDateTerm, updateDisplayDateTerm])
  const isBucketIncluded = useCallback(
    (bucket: DateBucket) =>
      displayDateTerm.from <= bucket.from && bucket.from <= displayDateTerm.to,
    [displayDateTerm.from, displayDateTerm.to]
  )
  return {
    displayDateTerm,
    updateDisplayDateTerm,
    isBucketIncluded,
  }
}
