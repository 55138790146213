import { useCallback, useMemo } from 'react'
import {
  DateComponent,
  DatePrefix,
  DateTermRowComponent,
  DateWithPrefix,
  Kpi,
  KpiTitle,
  KpiWithTitle,
  WidgetContent,
  WidgetForCollapsed,
} from '.'
import {
  ProjectBasic,
  ProjectProfitLoss,
  ProjectProgress,
  ProjectResource,
  ProjectRisk,
} from '../../../../domain/value-object/ProjectReportVO'
import { EnterpriseProjectReportConfig } from '../model/config'
import {
  AggregatedValue,
  aggregatedValueService,
} from '../../../../domain/value-object/AggregatedValueVO'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'
import { useKpiFormatter } from '../hooks/kpiFormatter'
import { useI18n } from '../../../hooks/useI18n'
import { useKpiUnit } from '../hooks/kpiUnit'
import { useKpiAmountDigit } from '../hooks/kpiAmountDigit'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'

type ProjectSummaryWidgetProps = Pick<
  ProjectBasic,
  'scheduledStartDate' | 'scheduledEndDate'
> &
  Pick<ProjectProgress, 'bac' | 'pv' | 'ev' | 'delayed'> &
  Pick<ProjectResource, 'total' | 'devoted'> &
  Pick<ProjectProfitLoss, 'totalCost' | 'actualRecordedCost'> &
  Pick<ProjectRisk, 'veryHigh'> &
  EnterpriseProjectReportConfig

export const ProjectSummaryWidget = ({
  scheduledStartDate,
  scheduledEndDate,
  bac,
  pv,
  ev,
  total,
  devoted,
  delayed,
  totalCost,
  actualRecordedCost,
  veryHigh,
  aggregateTarget,
  aggregateField,
  workloadUnit,
}: ProjectSummaryWidgetProps) => {
  const workloadUnitState = useWorkloadUnit(workloadUnit)
  const { valueFormatter, rateFormatter } = useKpiFormatter(aggregateField)
  const getValue = useCallback(
    (src: AggregatedValue) => {
      switch (aggregateField) {
        case AggregateField.WBS_ITEM_COUNT:
          return aggregatedValueService.getValue(
            src,
            aggregateTarget,
            aggregateField
          )
        case AggregateField.WBS_ITEM_WORKLOAD:
          return workloadUnitState.convertWorkloadFromHourToSelectedUnit(
            aggregatedValueService.getValue(
              src,
              aggregateTarget,
              aggregateField
            )
          )
      }
    },
    [aggregateField, aggregateTarget, workloadUnitState]
  )
  const {
    elapsedTimeRate,
    scheduledProgressRate,
    progressRate,
    formattedDelayed,
  } = useMemo(() => {
    const projectTermDiff =
      scheduledEndDate.valueOf() - scheduledStartDate.valueOf()
    const elapsedTimeDiff = new Date().valueOf() - scheduledStartDate.valueOf()
    const elapsedTimeRate = rateFormatter(elapsedTimeDiff / projectTermDiff)
    const bacValue = getValue(bac)
    const pvValue = getValue(pv)
    const evValue = getValue(ev)
    const delayedValue = getValue(delayed)
    const scheduledProgressRate = bacValue
      ? rateFormatter(pvValue / bacValue)
      : '-'
    const progressRate = bacValue ? rateFormatter(evValue / bacValue) : '-'
    const formattedDelayed = valueFormatter(delayedValue)
    return {
      elapsedTimeRate,
      scheduledProgressRate,
      progressRate,
      formattedDelayed,
    }
  }, [
    bac,
    delayed,
    ev,
    pv,
    scheduledEndDate,
    scheduledStartDate,
    getValue,
    rateFormatter,
    valueFormatter,
  ])
  const progressUnit = useKpiUnit(aggregateField, workloadUnit)

  const { totalResource, devotedResource } = useMemo(() => {
    return {
      totalResource: total.toFixed(1),
      devotedResource: devoted.toFixed(1),
    }
  }, [devoted, total])
  const resourceUnit = useKpiUnit(
    AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit
  )

  const { truncateDigit, unit: ProfitLossUnit } = useKpiAmountDigit(totalCost)
  const { formattedTotalCost, formattedActualRecordedCost } = useMemo(() => {
    const format = (src: number) => (src / 10 ** truncateDigit).toFixed(1)
    return {
      formattedTotalCost: format(totalCost),
      formattedActualRecordedCost: format(actualRecordedCost),
    }
  }, [actualRecordedCost, totalCost, truncateDigit])
  const [
    title,
    elapsedTimeRateLabel,
    scheduledProgressRateLabel,
    progressRateLabel,
    totalResourceLabel,
    devotedResourceLabel,
    totalCostLabel,
    recordedCostLabel,
    delayedLabel,
    riskLabel,
    riskUnit,
  ] = useI18n([
    'enterpriseProjectReport.scheduledDate',
    'enterpriseProjectReport.elapsedTimeRate',
    'enterpriseProjectReport.scheduledProgressRate',
    'enterpriseProjectReport.progressRate',
    'enterpriseProjectReport.totalResource',
    'enterpriseProjectReport.devotedResource',
    'enterpriseProjectReport.totalCost',
    'enterpriseProjectReport.recordedCost',
    'enterpriseProjectReport.delayed',
    'enterpriseProjectReport.summary.risk.veryHigh',
    'unit.count',
  ])
  return (
    <WidgetForCollapsed>
      <WidgetContent>
        <KpiWithTitle size="xxl">
          <KpiTitle>{title}</KpiTitle>
          <DateTermRowComponent>
            <DateWithPrefix>
              <DatePrefix>from</DatePrefix>
              <DateComponent date={scheduledStartDate} />
            </DateWithPrefix>
            <DateWithPrefix>
              <DatePrefix>to</DatePrefix>
              <DateComponent date={scheduledEndDate} />
            </DateWithPrefix>
          </DateTermRowComponent>
        </KpiWithTitle>
        <Kpi title={elapsedTimeRateLabel} value={elapsedTimeRate} unit={'%'} />
        <Kpi
          title={scheduledProgressRateLabel}
          value={scheduledProgressRate}
          unit={'%'}
        />
        <Kpi title={progressRateLabel} value={progressRate} unit={'%'} />
        <Kpi
          title={totalResourceLabel}
          value={totalResource}
          unit={resourceUnit}
        />
        <Kpi
          title={devotedResourceLabel}
          value={devotedResource}
          unit={resourceUnit}
        />
        <Kpi
          title={totalCostLabel}
          value={formattedTotalCost}
          unit={ProfitLossUnit}
        />
        <Kpi
          title={recordedCostLabel}
          value={formattedActualRecordedCost}
          unit={ProfitLossUnit}
        />
        <Kpi
          title={delayedLabel}
          value={formattedDelayed}
          unit={progressUnit}
        />
        <Kpi
          title={riskLabel}
          value={`${veryHigh}`}
          unit={riskUnit}
          size="xl"
        />
      </WidgetContent>
    </WidgetForCollapsed>
  )
}
