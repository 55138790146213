import { Paper, Popper, Slider, styled } from '@mui/material'
import { ROW_HEIGHT } from '../../containers/BulkSheet'

export interface Props {
  anchorEl: any
  height?: number
  onChangeHeight: (value: number) => void
}

const RowHeightChangePopper = (props: Props) => {
  const minHeight = ROW_HEIGHT.SMALL // 32
  const maxHeight = 202
  return (
    <Popper
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      placement={'right-start'}
      disablePortal={true}
    >
      <Paper style={{ height: 110, width: 30 }}>
        <RowHeightSlider
          onChange={(event: any, value: number | number[]) => {
            if (Array.isArray(value)) return
            props.onChangeHeight(maxHeight - value)
            event.stopPropagation()
          }}
          onChangeCommitted={e => e.stopPropagation()}
          defaultValue={maxHeight - (props.height || ROW_HEIGHT.SMALL)}
          min={0}
          max={maxHeight - minHeight}
          step={17}
          marks={true}
          orientation={'vertical'}
          track={false}
        />
      </Paper>
    </Popper>
  )
}

export default RowHeightChangePopper

const RowHeightSlider = styled(Slider)({
  height: 90,
  margin: '8px 0',
  padding: '0 5px',
  '& .MuiSlider-rail': {
    width: 3,
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
  },
})
