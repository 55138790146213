import {
  Popper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { NewsGroupForUI } from '../../../../../store/news'
import { BackgroundColor } from '../../../../../styles/commonStyles'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import CheckMark from '../../../../../assets/filter_check_mark.png'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'

type Props = {
  openMenu: boolean
  filterAnchorEl: Element | undefined
  selectedNewsGroups: NewsGroupForUI[]
  onChangeNewsGroups: (newsGroups: NewsGroupForUI[]) => void | Promise<void>
  handleFilterChange: any
}

const FilterNewsTypeCheckBox = ({
  openMenu,
  filterAnchorEl,
  selectedNewsGroups,
  onChangeNewsGroups,
  handleFilterChange,
}: Props) => {
  const [commentChecked, setCommentChecked] = useState<boolean>(false)
  const [statusChecked, setStatusChecked] = useState<boolean>(false)
  const [priorityChecked, setPriorityChecked] = useState<boolean>(false)
  const [assignedChecked, setAssignedChecked] = useState<boolean>(false)

  useEffect(() => {
    setCommentChecked(
      selectedNewsGroups.includes(NewsGroupForUI.COMMENTED) ? true : false
    )
    setStatusChecked(
      selectedNewsGroups.includes(NewsGroupForUI.CHANGED_WBS_ITEM_STATUS)
        ? true
        : false
    )
    setPriorityChecked(
      selectedNewsGroups.includes(NewsGroupForUI.CHANGED_WBS_ITEM_PRIORITY) ||
        selectedNewsGroups.includes(NewsGroupForUI.CHANGED_WBS_ITEM_PRIORITY_V2)
        ? true
        : false
    )
    setAssignedChecked(
      selectedNewsGroups.includes(NewsGroupForUI.ASSIGNED) ? true : false
    )
  }, [])

  useEffect(() => {
    if (selectedNewsGroups.length === 0) {
      handleFilterChange(false)
    } else {
      handleFilterChange(true)
    }
  }, [selectedNewsGroups])

  const onClickCheckbox = useCallback(
    (newsGroups: NewsGroupForUI[]) => {
      newsGroups.forEach(newsGroup => {
        if (selectedNewsGroups.includes(newsGroup)) {
          onChangeNewsGroups(
            selectedNewsGroups.filter(filter => filter !== newsGroup)
          )
        } else {
          onChangeNewsGroups([...selectedNewsGroups, newsGroup])
        }
      })
    },
    [selectedNewsGroups]
  )

  return (
    <Popper
      open={openMenu}
      sx={{
        zIndex: '1300',
        background: BackgroundColor.WHITE,
        boxShadow: '0px 1px 2px #0000004D',
        width: '200px',
        borderRadius: '4px',
      }}
      placement={'bottom-end'}
      modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]}
      anchorEl={filterAnchorEl}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ display: 'initial' }}>
            <CheckBoxRowCell
              onClick={() => (
                commentChecked
                  ? setCommentChecked(false)
                  : setCommentChecked(true),
                onClickCheckbox([NewsGroupForUI.COMMENTED])
              )}
              title={intl.formatMessage({ id: 'news.type.commented.label' })}
              checked={commentChecked}
              style={{ padding: '20px 20px 10px 20px' }}
            />
            <CheckBoxRowCell
              onClick={() => (
                statusChecked
                  ? setStatusChecked(false)
                  : setStatusChecked(true),
                onClickCheckbox([NewsGroupForUI.CHANGED_WBS_ITEM_STATUS])
              )}
              title={intl.formatMessage({
                id: 'news.type.changedStatus.label',
              })}
              checked={statusChecked}
              style={{ padding: '10px 20px 10px 20px' }}
            />
            <CheckBoxRowCell
              onClick={() => (
                priorityChecked
                  ? setPriorityChecked(false)
                  : setPriorityChecked(true),
                onClickCheckbox([
                  NewsGroupForUI.CHANGED_WBS_ITEM_PRIORITY,
                  NewsGroupForUI.CHANGED_WBS_ITEM_PRIORITY_V2,
                ])
              )}
              title={intl.formatMessage({
                id: 'news.type.changedPriority.label',
              })}
              checked={priorityChecked}
              style={{ padding: '10px 20px 10px 20px' }}
            />
            <CheckBoxRowCell
              onClick={() => (
                assignedChecked
                  ? setAssignedChecked(false)
                  : setAssignedChecked(true),
                onClickCheckbox([NewsGroupForUI.ASSIGNED])
              )}
              title={intl.formatMessage({ id: 'news.type.assigned.label' })}
              checked={assignedChecked}
              style={{ padding: '10px 20px 20px 20px' }}
            />
          </TableRow>
        </TableHead>
      </Table>
    </Popper>
  )
}

type CheckBoxProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  title: string
  checked: boolean
  style?: CSSProperties
}

const CheckBoxRowCell = ({ onClick, title, checked, style }: CheckBoxProps) => {
  return (
    <TableCell
      sx={{
        display: 'flex',
        flexDirection: 'row',
        borderBottom: 'none',
        padding: style?.padding,
      }}
    >
      <button
        style={{
          border: `1px solid ${colorPalette.monotone[2]}`,
          height: '20px',
          width: '20px',
          background: BackgroundColor.WHITE,
          borderRadius: '4px',
          padding: '0px',
          marginRight: '10px',
        }}
        onClick={onClick}
      >
        {checked && (
          <img src={CheckMark} style={{ height: '10px', width: '13px' }} />
        )}
      </button>
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        {title}
      </Typography>
    </TableCell>
  )
}

export default FilterNewsTypeCheckBox
