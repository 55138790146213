import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'
import moment from 'moment'

export default class DateTimeVO extends DomainValueObjectBase<Date> {
  private readonly FORMATTER = 'YYYY/MM/DD HH:mm:ss'
  private readonly API_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

  constructor(_value?: Date | number | string) {
    const m = moment(_value)
    if (!m.isValid()) {
      throw new DomainError({
        errorCode: DomainErrorCode.INVALID_VALUE_ERROR,
        message: 'Date value is invalid date.',
      })
    }
    super(m.toDate())
  }

  public toString(formatter?: string): string {
    return moment(super.toPlainValue()).format(formatter || this.FORMATTER)
  }

  public isBefore(target: DateTimeVO): boolean {
    return moment(super.toPlainValue()).isBefore(moment(target.toPlainValue()))
  }

  public isAfter(target: DateTimeVO): boolean {
    return moment(super.toPlainValue()).isAfter(moment(target.toPlainValue()))
  }

  public isToday(): boolean {
    return moment(super.toPlainValue()).isSame(moment(), 'd')
  }

  public isThisYear(): boolean {
    return moment(super.toPlainValue()).isSame(moment(), 'y')
  }

  public format(formatter = 'YYYY/MM/DD HH:mm'): string {
    return moment(super.toPlainValue()).format(formatter)
  }

  public serialize(): number {
    return Date.parse(moment(super.toPlainValue()).format(this.API_FORMAT))
  }
}
