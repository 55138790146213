import { intl } from '../../../../../i18n'
import FileExportIcon from '../../../../components/icons/FileExportIcon'
import { BootstrapTooltip } from '../../../../components/tooltips/Tooltip/BootStrapToopTip'

interface FileExportProps {
  onClickExport: () => void
}

const FileExportButton = ({ onClickExport }: FileExportProps) => {
  return (
    <BootstrapTooltip
      arrow={true}
      title={intl.formatMessage({ id: 'wbs.header.file.export' })}
    >
      <button
        style={{
          background: 'transparent',
          border: 'none',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={onClickExport}
      >
        <FileExportIcon height={'15px'} width={'15px'} />
      </button>
    </BootstrapTooltip>
  )
}

export default FileExportButton
