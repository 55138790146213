import { Map, List } from 'immutable'
import { Epic, ofType } from 'redux-observable'
import { Observable, Subscriber } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import API from '../lib/commons/api'
import Auth from '../lib/commons/auth'

export enum MessageLevel {
  SUCCESS,
  INFO,
  WARN,
  ERROR,
  DEBUG,
}

export interface Message {
  id: string
  type: MessageLevel
  code?: string
  title?: string
  text: string
  detail?: string
  at: number
}

export interface AddMessageProps {
  type: MessageLevel
  code?: string
  title?: string
  text?: string
  detail?: string
  at?: number
}

interface State {
  global: List<Message>
  screens: Map<string, List<Message>>
  latest?: Message
}

// Actions
enum ActionType {
  ADD_GLOBAL_MESSAGE = 'ADD_GLOBAL_MESSAGE',
  DELETE_GLOBAL_MESSAGE = 'DELETE_GLOBAL_MESSAGE',
  ADD_SCREEN_MESSAGE = 'ADD_SCREEN_MESSAGE',
  DELETE_SCREEN_MESSAGES = 'DELETE_SCREEN_MESSAGES',
  DELETE_SCREEN_MESSAGE = 'DELETE_SCREEN_MESSAGE',
  SUBSCRIBE_API_DEBUG_MESSAGE = 'SUBSCRIBE_API_DEBUG_MESSAGE',
  RECEIVED_API_DEBUG_MESSAGE = 'RECEIVED_API_DEBUG_MESSAGE',
}

const normalizeMessage = (message: AddMessageProps) => {
  if (!message.text) {
    message.text = ''
  }
  if (!message.at) {
    message.at = new Date().getTime()
  }
  return message
}

export const addGlobalMessage = (message: AddMessageProps) => {
  return {
    type: ActionType.ADD_GLOBAL_MESSAGE,
    message: normalizeMessage(message),
  }
}

export const deleteGlobalMessage = (id: string) => {
  return {
    type: ActionType.DELETE_GLOBAL_MESSAGE,
    id,
  }
}

export const addScreenMessage = (
  screenName: string,
  message: AddMessageProps
) => {
  return {
    type: ActionType.ADD_SCREEN_MESSAGE,
    screenName,
    message: normalizeMessage(message),
  }
}

export const deleteScreenMessages = (screenName: string) => {
  return {
    type: ActionType.DELETE_SCREEN_MESSAGES,
    screenName,
  }
}

export const deleteScreenMessage = (screenName: string, id: string) => {
  return {
    type: ActionType.DELETE_SCREEN_MESSAGE,
    screenName,
    id,
  }
}

function generateId(message: AddMessageProps): string {
  return `${message.at}-${Math.random()}`
}

export const subscribeDebugMessage = () => ({
  type: ActionType.SUBSCRIBE_API_DEBUG_MESSAGE,
})

// Epics
export const subscribeApiDebugMessageEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_API_DEBUG_MESSAGE),
    mergeMap(_ => {
      let subscriber: Subscriber<AddMessageProps>
      const observable = new Observable<AddMessageProps>(sub => {
        subscriber = sub
      })
      const tenant = Auth.getCurrentTenant()!
      API.notification.subscribe<string>(
        `${tenant.tenantUuid}/DebugMessage`,
        message => {
          subscriber.next(
            normalizeMessage({
              type: MessageLevel.ERROR,
              title: '[DEBUG] Fact-API error',
              text: message,
            })
          )
        }
      )
      return observable
    }),
    map(message => ({
      type: ActionType.RECEIVED_API_DEBUG_MESSAGE,
      message,
    }))
  )

// Reducers
export const reducer = (
  state: State = {
    global: List(),
    screens: Map(),
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.ADD_GLOBAL_MESSAGE:
    case ActionType.RECEIVED_API_DEBUG_MESSAGE:
      return {
        ...state,
        global: state.global.insert(0, {
          ...action.message,
          id: generateId(action.message),
        }),
        latest: action.message,
      }
    case ActionType.DELETE_GLOBAL_MESSAGE:
      return {
        ...state,
        global: state.global.delete(
          state.global.findIndex(v => v.id === action.id)
        ),
      }
    case ActionType.ADD_SCREEN_MESSAGE:
      const message = {
        ...action.message,
        id: generateId(action.message),
      }

      return {
        ...state,
        screens: state.screens.update(action.screenName, v =>
          !v ? List([message]) : v.insert(0, message)
        ),
        latest: action.message,
      }
    case ActionType.DELETE_SCREEN_MESSAGES:
      return {
        ...state,
        screens: state.screens.delete(action.screenName),
      }
    case ActionType.DELETE_SCREEN_MESSAGE:
      const screenMessages = state.screens.get(action.screenName)
      if (!screenMessages || !screenMessages.size) {
        return { ...state }
      }

      const index = screenMessages.findIndex(v => v.id === action.id)
      if (index < 0) {
        return { ...state }
      }

      const newMessages = screenMessages.delete(index)
      return {
        ...state,
        screens: state.screens.set(action.screenName, newMessages),
      }
    default:
      return state
  }
}
