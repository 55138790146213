import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import { ToolbarToggleValue } from '../../../../components/toolbars/Toolbar/ToolbarToggle'
import {
  FontSize,
  FontWeight,
  Color,
  TextColor,
} from '../../../../../styles/commonStyles'
import {
  StandardWorkingInfo,
  formatDiffTime,
  formatHour,
  formatTime,
} from '../../ActualWorkingHours'
import { intl } from '../../../../../i18n'

interface Props {
  toolbar: ToolbarToggleValue | undefined
  data: StandardWorkingInfo
}

const HeaderCell = styled(TableCell)({
  width: '180px',
  maxWidth: '180px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  wordBreak: 'break-all',
  padding: 0,
  textAlign: 'center',
  overflow: 'hidden',
  fontSize: FontSize.MEDIUM,
  backgroundColor: '#F5F5F5',
  fontWeight: FontWeight.BOLD,
  lineHeight: '20px',
})
const BodyCell = styled(TableCell)({
  width: '120px',
  maxWidth: '120px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  wordBreak: 'break-all',
  padding: 0,
  textAlign: 'center',
  overflow: 'hidden',
  fontSize: FontSize.MEDIUM,
})

const ActualWorkingHoursToolbar = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '5px',
      }}
    >
      <TableContainer
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'auto',
        }}
      >
        <Table
          sx={{
            width: 'initial',
            marginLeft: '5px',
          }}
        >
          <TableBody>
            <TableRow>
              <HeaderCell>
                {intl.formatMessage({ id: 'actualWorkingHours.header.days' })}
              </HeaderCell>
              <BodyCell>{props.data.standardWorkDays}</BodyCell>
              <BodyCell>{props.data.totalWorkDays}</BodyCell>
              <BodyCell>
                <Typography
                  style={{
                    color:
                      props.data.diffDays < 0 ? Color.ALERT : TextColor.BLACK,
                  }}
                >
                  {props.data.diffDays}
                </Typography>
              </BodyCell>
            </TableRow>
            <TableRow>
              <HeaderCell>
                {intl.formatMessage({ id: 'actualWorkingHours.header.hours' })}
              </HeaderCell>
              <BodyCell>{formatHour(props.data.standardWorkHours)}</BodyCell>
              <BodyCell>{formatTime(props.data.totalWorkHours)}</BodyCell>
              <BodyCell>
                <Typography
                  style={{
                    color:
                      props.data.diffHours < 0 ? Color.ALERT : TextColor.BLACK,
                  }}
                >
                  {formatDiffTime(props.data.diffHours)}
                </Typography>
              </BodyCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ActualWorkingHoursToolbar
