import _ from 'lodash'
import { GetContextMenuItemsParams, RowNode } from 'ag-grid-community'
import {
  AccountCategory,
  BudgetResultType,
  ProfitLossItemAmountRowBody,
  ProfitLossItemRow,
  ProfitLossRowBase,
  ProfitLossRowBodyBase,
} from '../profitLossItems'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import { intl } from '../../../../i18n'
import {
  FinancialStatementAccountsType,
  LedgerAccountsTree,
} from '../../LedgerAccounts/ledgerAccounts'
import {
  distinctRowNodes,
  generateYearMonthField,
  getSummaryRowNode,
  isRootRow,
} from '.'
import DateVO from '../../../../vo/DateVO'

export const AddSize = 4

export const addRow = <
  TRow extends ProfitLossRowBase<TBody>,
  TBody extends ProfitLossRowBodyBase
>(
  params: GetContextMenuItemsParams,
  data: TRow[],
  accountCategory: string | undefined,
  masterTree: LedgerAccountsTree[],
  createRow: (
    financialState: LedgerAccountsTree,
    generalLedger: LedgerAccountsTree
  ) => TRow[],
  action: { setData: (data: TRow[]) => void }
) => {
  const isDisabled = !accountCategory
  const financialState =
    AccountCategory.OperatingRevenue === accountCategory
      ? masterTree.find(
          v =>
            v.financialStatementAccountsType ===
            FinancialStatementAccountsType.Sales
        )
      : undefined
  const generalLedger =
    !!financialState?.children && financialState?.children.length > 0
      ? financialState?.children[0]
      : undefined
  return {
    name: intl.formatMessage({ id: 'bulksheet.contextMenu.insert.row' }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
    disabled: isDisabled,
    action: () => {
      const copyData = _.cloneDeep(data)
      const addItems = createRow(financialState!, generalLedger!)
      const index = data.findIndex(v => v.uuid === params.node?.data.uuid)
      copyData.splice(index + AddSize, 0, ...addItems)
      action.setData(copyData)
    },
  }
}

export const deleteRow = <
  TRow extends ProfitLossRowBase<TBody>,
  TBody extends ProfitLossRowBodyBase
>(
  params: GetContextMenuItemsParams,
  data: TRow[],
  disabled: boolean,
  action: { setData: (data: TRow[]) => void }
) => {
  const isDisable =
    Object.values(BudgetResultType).includes(params.node?.data?.body?.type) ||
    disabled ||
    !params.node
  return {
    name: intl.formatMessage(
      {
        id: 'bulksheet.contextMenu.delete.rows',
      },
      {
        count: 1,
      }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
    disabled: isDisable,
    action: () => {
      const newRows = data.filter(
        v =>
          v.uuid !== params.node?.data.uuid &&
          !v.treeValue.includes(params.node?.data.uuid)
      )
      action.setData(newRows)
    },
  }
}

export const addMultipleRows = (
  params: GetContextMenuItemsParams,
  accountCategory: string | undefined,
  openAddRowsDialog: () => void
) => {
  const isDisabled = !accountCategory
  return {
    name: intl.formatMessage({ id: 'addMultipleRows' }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
    disabled: isDisabled,
    action: () => {
      openAddRowsDialog()
    },
  }
}

export const setAllBudgetAmountToResult = (
  params: GetContextMenuItemsParams<ProfitLossItemRow>
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLoss.contextMenu.budget.to.result.all',
    }),
    disabled: params.api.getRenderedNodes().length <= 0,
    action: () => {
      const thisMonth = DateVO.now().getFirstDayOfMonth()
      const editedRowNodes: RowNode[] = []
      params.api.forEachNode(node => {
        const row = node.data
        if (!isRootRow(row) || !node.childrenMapped) return
        const children: RowNode<ProfitLossItemRow>[] = Object.values(
          node.childrenMapped
        ).filter(v => !!v)
        const budgetNode = children.find(
          c => BudgetResultType.Budget === c.data?.body.type
        )
        const resultNode = children.find(
          c => BudgetResultType.Result === c.data?.body.type
        )
        const resultRow = resultNode?.data
        if (!budgetNode?.data || !resultRow) return

        budgetNode?.data.body.amounts.forEach(budgetAmount => {
          const targetMonth = new DateVO(budgetAmount.yearMonth)
          if (!budgetAmount?.amount || thisMonth.diffMonth(targetMonth) <= 0) {
            return
          }
          const resultAmount = resultRow?.body.amounts.find(
            resultAmount => resultAmount.yearMonth === budgetAmount.yearMonth
          )
          if (resultAmount?.amount) return

          const oldValue: number | undefined = resultAmount?.amount
          const newValue: number = budgetAmount.amount
          if (!resultAmount) {
            resultRow?.body.amounts.push({
              yearMonth: budgetAmount.yearMonth,
              amount: newValue,
            } as ProfitLossItemAmountRowBody)
          } else {
            resultAmount.amount = newValue
          }
          resultRow.edited = !resultRow.added
          const field = generateYearMonthField(targetMonth)
          if (!resultRow.editedData) {
            resultRow.editedData = { [field]: oldValue }
          } else if (!resultRow.editedData.hasOwnProperty(field)) {
            resultRow.editedData[field] = oldValue
          }
          editedRowNodes.push(resultNode)
          const formatDate = targetMonth.formatForApi()
          const rootAmount = node.data?.body.amounts.find(
            a => a.yearMonth === formatDate
          )
          if (rootAmount) {
            rootAmount.amount = newValue
          } else {
            node.data?.body.amounts.push({
              amount: newValue,
              yearMonth: formatDate,
            })
          }
          editedRowNodes.push(node)
        })
      })
      const refreshNodes = distinctRowNodes(editedRowNodes)
      if (!_.isEmpty(refreshNodes)) {
        refreshNodes.push(
          ...getSummaryRowNode(params.api, ['', BudgetResultType.Result])
        )
        params.api.refreshCells({ rowNodes: refreshNodes, force: true })
      }
    },
  }
}

export const setSingleBudgetAmountToResult = (
  params: GetContextMenuItemsParams<ProfitLossItemRow>
) => {
  return {
    name: intl.formatMessage({
      id: 'profitLoss.contextMenu.budget.to.result.single',
    }),
    disabled: params.api.getRenderedNodes().length <= 0 || !params.node?.data,
    action: () => {
      const thisMonth = DateVO.now().getFirstDayOfMonth()
      const editedRowNodes: RowNode[] = []
      if (!params.node) return
      const node = isRootRow(params.node.data)
        ? params.node
        : params.node.parent
      const row = node?.data
      if (!node || !row || !node.childrenMapped) return

      const children: RowNode<ProfitLossItemRow>[] = Object.values(
        node.childrenMapped
      ).filter(v => !!v)
      const budgetNode = children.find(
        c => BudgetResultType.Budget === c.data?.body.type
      )
      const resultNode = children.find(
        c => BudgetResultType.Result === c.data?.body.type
      )
      const resultRow = resultNode?.data
      if (!budgetNode?.data || !resultRow) return

      budgetNode?.data.body.amounts.forEach(budgetAmount => {
        const targetMonth = new DateVO(budgetAmount.yearMonth)
        if (!budgetAmount?.amount || thisMonth.diffMonth(targetMonth) <= 0) {
          return
        }
        const resultAmount = resultRow?.body.amounts.find(
          resultAmount => resultAmount.yearMonth === budgetAmount.yearMonth
        )
        if (resultAmount?.amount) return

        const oldValue: number | undefined = resultAmount?.amount
        const newValue: number = budgetAmount.amount
        if (!resultAmount) {
          resultRow?.body.amounts.push({
            yearMonth: budgetAmount.yearMonth,
            amount: newValue,
          } as ProfitLossItemAmountRowBody)
        } else {
          resultAmount.amount = newValue
        }
        resultRow.edited = !resultRow.added
        const field = generateYearMonthField(targetMonth)
        if (!resultRow.editedData) {
          resultRow.editedData = { [field]: oldValue }
        } else if (!resultRow.editedData.hasOwnProperty(field)) {
          resultRow.editedData[field] = oldValue
        }
        editedRowNodes.push(resultNode)
        const formatDate = targetMonth.formatForApi()
        const rootAmount = node.data?.body.amounts.find(
          a => a.yearMonth === formatDate
        )
        if (rootAmount) {
          rootAmount.amount = newValue
        } else {
          node.data?.body.amounts.push({
            amount: newValue,
            yearMonth: formatDate,
          })
        }
        editedRowNodes.push(node)
      })
      const refreshNodes = distinctRowNodes(editedRowNodes)
      if (!_.isEmpty(refreshNodes)) {
        refreshNodes.push(
          ...getSummaryRowNode(params.api, ['', BudgetResultType.Result])
        )
        params.api.refreshCells({ rowNodes: refreshNodes, force: true })
      }
    },
  }
}
