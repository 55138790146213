import { useCallback, useMemo, useState } from 'react'
import { PermissionDetail } from '../../../lib/functions/permission'
import PermissionsOptions, {
  ColumnQuickFilterKey,
  PermissionRow,
} from './PermissionsOptions'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  BulkSheetState,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import { PageArea } from '..'
import PermissionsHeader from './Header'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ColDef } from 'ag-grid-community'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Collapse } from '@mui/material'
import IdProvidersToolbar from '../IdProviders/Toolbar'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'

type PermissionsPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const permissionsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const Permissions = props => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        PermissionDetail,
        PermissionRow,
        BulkSheetState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)

  const permissionsOptions = useMemo(() => new PermissionsOptions(), [])

  const { toolbar, updatePageState } = usePageState<PermissionsPageState>(
    props.uuid,
    permissionsDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )

  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.columnApi?.resetColumnState()
        bulkSheet.gridApi?.setFilterModel(null)
        bulkSheet.gridApi?.onFilterChanged()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  return (
    <PageArea>
      <PermissionsHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        filteredColumns={filteredColumns}
        sortedColumns={sortedColumns}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <IdProvidersToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={permissionsOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
      />
    </PageArea>
  )
}

export default pageComponent(Permissions)
