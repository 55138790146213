import { useMemo } from 'react'
import * as d3 from 'd3'
import { TwoDimensionalPoint } from '../../model/chart'

export const useLine = <X, Y>(
  xScale: (x: X) => number,
  yScale: (y: Y) => number,
  points: TwoDimensionalPoint<X, Y>[]
) => {
  const line = useMemo(
    () =>
      d3
        .line<TwoDimensionalPoint<X, Y>>()
        .x(d => xScale(d.x))
        .y(d => yScale(d.y)),
    [xScale, yScale]
  )
  const d = useMemo(() => line(points) || '', [line, points])
  return d
}
