/* eslint-disable no-underscore-dangle */
import { createStore, compose, combineReducers, applyMiddleware } from 'redux'
import { createEpicMiddleware, combineEpics } from 'redux-observable'
import Auth from '../lib/commons/auth'
import { reducer as appFunction, fetchFunctionsEpic } from './appFunction'
import {
  reducer as messages,
  subscribeApiDebugMessageEpic,
  subscribeDebugMessage,
} from './messages'
import { reducer as application, subscribeApplicationEpic } from './application'
import breadcrumb from './breadcrumb'
import {
  reducer as comments,
  subscribeAddedCommentEpic,
  subscribeUpdatedCommentEpic,
  subscribeDeletedCommentEpic,
  fetchCommentEpic,
  postCommentEpic,
  deleteCommentEpic,
  editCommentEpic,
} from './comments'
import {
  reducer as news,
  subscribeAddedNewsEpic,
  subscribeUpdatedNewsEpic,
  subscribeDeletedNewsEpic,
  fetchNewsEpic,
  readNewsEpic,
  deleteNewsEpic,
} from './news'
import { reducer as hasRequiredSaveData } from './requiredSaveData'
import { reducer as user } from './user'
import {
  reducer as project,
  fetchProjectEpic,
  receiveProjectEpic,
  changeProjectEpic,
  changeGanttParameterEpic,
} from './project'
import { reducer as tenant } from './tenant'
import { reducer as globalAlert } from './globalAlert'
import { reducer as functionLayer } from './functionLayer'
import { reducer as sideMenu } from './sideMenu'
import { reducer as information } from './information'
import { reducer as singleSheet } from './singleSheet'
import { reducer as keyBind } from './keyBind'
import { fetchTagsByProjectEpic, reducer as tag } from './tag'
import {
  reducer as wbsItemCommentUserSummary,
  initializeWbsItemCommentUserSummariesEpic,
  openedWbsItemCommentUserSummariesEpic,
  confirmWbsItemCommentUserSummaryEpic,
  subscribeWbsItemCommentUserSummaryNewCommentAppearedEpic,
  subscribeWbsItemCommentUserSummaryNewCommentDisappearedEpic,
} from './wbsItemCommentUserSummary'
import {
  fetchWbsItemAdditionalPropertiesEpic,
  reducer as wbsItemAdditionalProperty,
} from './wbsItemAdditionalProperty'
import { reducer as measuringTimer, fetchTimerItemEpic } from './measuringTimer'

const composeEnhancers =
  (process.env.REACT_APP_ENV !== 'production' &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const apiDebugEpics = createEpicMiddleware()
const applicationEpics = createEpicMiddleware()
const appFunctionsEpics = createEpicMiddleware()
const commentEpics = createEpicMiddleware()
const projectEpics = createEpicMiddleware()
const newsEpics = createEpicMiddleware()
const singleSheetEpics = createEpicMiddleware()
const tagEpics = createEpicMiddleware()
const wbsItemCommentUserSummaryEpics = createEpicMiddleware()
const wbsItemAdditionalPropertyEpics = createEpicMiddleware()
const timerEpics = createEpicMiddleware()

const store = createStore(
  combineReducers({
    application,
    appFunction,
    messages,
    comments,
    hasRequiredSaveData,
    breadcrumb,
    user,
    project,
    tenant,
    news,
    globalAlert,
    functionLayer,
    sideMenu,
    information,
    singleSheet,
    keyBind,
    tag,
    wbsItemCommentUserSummary,
    wbsItemAdditionalProperty,
    measuringTimer,
  }),
  composeEnhancers(
    applyMiddleware(
      apiDebugEpics,
      applicationEpics,
      appFunctionsEpics,
      commentEpics,
      projectEpics,
      newsEpics,
      singleSheetEpics,
      tagEpics,
      wbsItemCommentUserSummaryEpics,
      wbsItemAdditionalPropertyEpics,
      timerEpics
    )
  )
)

apiDebugEpics.run(subscribeApiDebugMessageEpic)
applicationEpics.run(subscribeApplicationEpic)
appFunctionsEpics.run(fetchFunctionsEpic)
commentEpics.run(
  combineEpics(
    subscribeAddedCommentEpic,
    subscribeUpdatedCommentEpic,
    subscribeDeletedCommentEpic,
    fetchCommentEpic,
    postCommentEpic,
    editCommentEpic,
    deleteCommentEpic
  )
)
projectEpics.run(
  combineEpics(
    fetchProjectEpic,
    receiveProjectEpic,
    changeProjectEpic,
    changeGanttParameterEpic,
    fetchTagsByProjectEpic
  )
)
newsEpics.run(
  combineEpics(
    subscribeAddedNewsEpic,
    subscribeUpdatedNewsEpic,
    subscribeDeletedNewsEpic,
    fetchNewsEpic,
    readNewsEpic,
    deleteNewsEpic
  )
)
wbsItemCommentUserSummaryEpics.run(
  combineEpics(
    initializeWbsItemCommentUserSummariesEpic,
    openedWbsItemCommentUserSummariesEpic,
    confirmWbsItemCommentUserSummaryEpic,
    subscribeWbsItemCommentUserSummaryNewCommentAppearedEpic,
    subscribeWbsItemCommentUserSummaryNewCommentDisappearedEpic
  )
)
wbsItemAdditionalPropertyEpics.run(
  combineEpics(fetchWbsItemAdditionalPropertiesEpic)
)
timerEpics.run(combineEpics(fetchTimerItemEpic))

if (process.env.REACT_APP_ENV === 'development') {
  const tenant = Auth.getCurrentTenant()
  if (tenant) {
    store.dispatch(subscribeDebugMessage())
  }
}

export type AllState = ReturnType<typeof store.getState>
export default store
