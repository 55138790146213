import { ICellRendererParams } from 'ag-grid-community'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import Tag from '../../../../components/labels/Tag'
import { TagForWbsItem } from '../../../../../lib/functions/tag'

export const TagCellRenderer = (
  props: ICellRendererParams<TagForWbsItem | string | undefined>
) => {
  const { value } = props

  return (
    <RootDiv>
      {Array.isArray(value) &&
        value.map((v, i) => (
          <>
            <Tag key={i} value={v.name} backgroundColor={v.backgroundColor} />
          </>
        ))}
      <FloatingEditableIcon {...props} />
    </RootDiv>
  )
}
