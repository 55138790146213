/* eslint-disable no-underscore-dangle */
import { Epic, ofType } from 'redux-observable'
import { mergeMap, map } from 'rxjs/operators'
import { WbsItemAdditionalPropertyLayoutEntity } from '../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { getWbsItemAdditionalProperties } from '../services/adaptors/wbsItemAdditionalPropertyRepositoryAdaptor'

enum ActionType {
  FETCH_WBS_ITEM_ADDITIONAL_PROPERTIES = 'FETCH_WBS_ITEM_ADDITIONAL_PROPERTIES',
  RECEIVED_WBS_ITEM_ADDITIONAL_PROPERTIES = 'RECEIVED_WBS_ITEM_ADDITIONAL_PROPERTIES',
}

type State = {
  [projectUuid: string]: {
    fetchedAt: number
    wbsItemAdditionalProperties:
      | WbsItemAdditionalPropertyLayoutEntity
      | undefined
  }
}

export const fetchWbsItemAdditionalProperties = (projectUuid: string) => ({
  type: ActionType.FETCH_WBS_ITEM_ADDITIONAL_PROPERTIES,
  projectUuid,
})

export const fetchWbsItemAdditionalPropertiesEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_WBS_ITEM_ADDITIONAL_PROPERTIES),
    mergeMap(async action => {
      const projectUuid = action.projectUuid
      const wbsItemAdditionalProperties = await getWbsItemAdditionalProperties(
        projectUuid,
        true
      )
      return {
        projectUuid,
        wbsItemAdditionalProperties,
      }
    }),
    map(result => {
      const { projectUuid, wbsItemAdditionalProperties } = result
      return receivedWbsItemAdditionalProperties(
        projectUuid,
        new Date().getTime(),
        wbsItemAdditionalProperties
      )
    })
  )

export const receivedWbsItemAdditionalProperties = (
  projectUuid: string,
  fetchedAt: number,
  wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity
) => ({
  type: ActionType.RECEIVED_WBS_ITEM_ADDITIONAL_PROPERTIES,
  projectUuid,
  fetchedAt,
  wbsItemAdditionalProperties,
})

export const reducer = (
  state: State = {},
  action: {
    type: ActionType
  } & {
    projectUuid: string
    fetchedAt: number
    wbsItemAdditionalProperties: WbsItemAdditionalPropertyLayoutEntity
  }
): State => {
  switch (action.type) {
    case ActionType.RECEIVED_WBS_ITEM_ADDITIONAL_PROPERTIES:
      return {
        ...state,
        [action.projectUuid]: {
          fetchedAt: action.fetchedAt,
          wbsItemAdditionalProperties: action.wbsItemAdditionalProperties,
        },
      }
    default:
      return state
  }
}
