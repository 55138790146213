import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import SaveButtonArea from '../../../../containers/BulkSheetView/components/header/SaveButtonArea'

type Props = {
  loading: boolean
  onSubmit: () => void
  onReload: () => void
}

const Header = ({ loading, onSubmit, onReload }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 10px 5px 10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <SaveButtonArea loading={loading} onSubmit={onSubmit} />
      <Button
        onClick={onReload}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
    </div>
  )
}

export default Header
