import { CSSProperties } from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import DateRangeVO from '../../../../../../vo/DateRangeVO'
import { DateInput } from './DateCell'
import { InputBase } from '@mui/material'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
  display: 'flex',
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
})
const CustomInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
  display: 'flex',
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
})
const Divider = styled('span')({
  backgroundColor: '#CCCCCC',
  width: '1px',
  margin: '0 3px',
})

interface DateRangeCellProps extends CellPropsBase<DateRangeVO> {
  editEnd: () => void
}

interface DateRangeCellState extends CellStateBase {}

type DateRangeItemKey = 'startDate' | 'endDate'

class DateRangeCell extends DataCellBase<
  DateRangeVO,
  DateRangeCellProps,
  DateRangeCellState
> {
  validate = (value?: DateRangeVO) => {
    return undefined
  }

  onChange = (key: DateRangeItemKey, value?: Date) => {
    const { value: oldRangeValue } = this.props
    const dateRange = oldRangeValue ? { ...oldRangeValue.getValue() } : {}
    dateRange[key] = value
    this.props.onChange(new DateRangeVO(dateRange))
    this.props.editEnd()
  }
  onSelectStartDate = (selectedValue?: Date) => {
    this.onChange('startDate', selectedValue)
  }
  onSelectEndDate = (selectedValue?: Date) => {
    this.onChange('endDate', selectedValue)
  }

  getStyle = (target: 'start' | 'end'): CSSProperties | undefined => {
    return (
      this.props.cellDef.getStyle &&
      this.props.cellDef.getStyle(this.props.data, target)
    )
  }

  render() {
    const { value, cellDef } = this.props
    const dateRange = value ? value.getValue() : {}
    const editable =
      typeof cellDef.editable === 'function'
        ? cellDef.editable(this.props.data)
        : cellDef.editable
    return (
      <RootDiv>
        <DateInput
          value={dateRange.startDate}
          onSelect={this.onSelectStartDate}
          placeholder={cellDef.placeholder || 'YYYY/MM/DD'}
          editable={editable}
          style={this.getStyle('start')}
          CustomInput={CustomInput}
        />
        <Divider />
        <DateInput
          value={dateRange.endDate}
          initialValueOnCalendar={dateRange.startDate}
          onSelect={this.onSelectEndDate}
          placeholder={cellDef.placeholder || 'YYYY/MM/DD'}
          editable={editable}
          style={this.getStyle('end')}
          CustomInput={CustomInput}
        />
      </RootDiv>
    )
  }
}

export default DateRangeCell
