import { dateVoService } from './../../../../../../domain/value-object/DateVO/index'
import { useCallback, useState } from 'react'
import { DateVO } from '../../../../../../domain/value-object/DateVO'
import { CommentCreatedAtFilterOperator } from './index'

export const useCommentCreatedAtFilter = (): {
  operator: CommentCreatedAtFilterOperator
  setOperator: (_: CommentCreatedAtFilterOperator) => void
  value: DateVO | undefined
  setValue: (_?: DateVO) => void
  reset: () => void
  isActive: () => boolean
  model: () =>
    | { operator: CommentCreatedAtFilterOperator; value: string }
    | undefined
  getModelAsString: () => string
} => {
  const [operator, setOperator] = useState<CommentCreatedAtFilterOperator>(
    CommentCreatedAtFilterOperator.AFTER
  )
  const [value, setValue] = useState<DateVO>()

  const reset = useCallback(() => {
    setOperator(CommentCreatedAtFilterOperator.AFTER)
    setValue(undefined)
  }, [])

  const isActive = useCallback(() => !!value, [value])

  const model = useCallback(() => {
    if (isActive()) {
      return { operator, value: value ? dateVoService.format(value) : '' }
    }
    return undefined
  }, [value, operator])

  const getModelAsString = useCallback(() => {
    if (!isActive()) return ''
    return `${
      operator === CommentCreatedAtFilterOperator.EQUALS
        ? '='
        : operator === CommentCreatedAtFilterOperator.AFTER
        ? '≥'
        : operator === CommentCreatedAtFilterOperator.BEFORE
        ? '≤'
        : ''
    } ${value && dateVoService.format(value, 'YYYY/MM/DD')}`
  }, [operator, value])

  return {
    operator,
    setOperator,
    value,
    setValue,
    reset,
    isActive,
    model,
    getModelAsString,
  }
}
