import _ from 'lodash'
import * as XLSX from 'xlsx'
import { useCallback } from 'react'
import {
  GridOptions,
  ProcessCellForExportParams,
  ShouldRowBeSkippedParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { getLabel } from '../../../../lib/commons/i18nLabel'
import { TAG_DELIMITER } from '../../../../lib/functions/tag'
import { TicketType } from '../../../../lib/functions/ticket'
import store from '../../../../store'
import { addGlobalMessage, MessageLevel } from '../../../../store/messages'
import { format as commentFormat } from '../../../../utils/comment'
import { exportExcel } from '../../../containers/BulkSheet/excel'
import { ColumnType } from '../../../containers/commons/AgGrid'
import {
  formatPercentage,
  getStatusSummary,
} from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/cellRenderer'
import formatter from '../../../containers/meta/formatter'
import { TicketDataManager } from '../dataManager'
import { MULTI_PROPERTY_DELIMITER, TicketRow } from '../tickets'
import { getExcelParser } from '../excel'
import { TicketListBasic } from '../../../../lib/functions/ticketList'

export const useExcel = (
  gridOptions: GridOptions | undefined,
  data: TicketRow[],
  dataManager: TicketDataManager | undefined,
  setLoading: (val: boolean) => void,
  setData: (data: TicketRow[]) => void
) => {
  // Export -------------
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams<TicketRow>) => {
      if (!params.node) return undefined
      const columnId: string = params.column.getColId()
      const colDef = params.column.getColDef()
      const { cellRendererParams, cellEditorParams, type } = colDef
      const row: TicketRow | undefined = params.node.data

      if (columnId === 'rowNumber') {
        const rendererParams = cellRendererParams({ node: params.node })
        return rendererParams.value
      }
      if (columnId === 'wbsItem.ticketType') {
        const wbsItemType = row?.wbsItem?.wbsItemType
        return wbsItemType?.isTicket() ? wbsItemType?.name : ''
      }
      if (columnId === 'wbsItem.status') {
        if (row?.isTotal) {
          const { countTotal, countDone } = getStatusSummary(
            params.api,
            columnId
          )
          const percentage = formatPercentage(countDone, countTotal)
          const done = formatter.format(
            countDone,
            colDef.cellRendererParams.uiMeta
          )
          const total = formatter.format(
            countTotal,
            colDef.cellRendererParams.uiMeta
          )
          return `${percentage} (${done} / ${total})`
        }
        return undefined
      }
      if (columnId === 'wbsItem.tags') {
        return params.value?.map(v => v.name)?.join(TAG_DELIMITER)
      }
      if (columnId === 'commentSummary.latestComment') {
        return params.value ? commentFormat(params.value) : ''
      }
      if (columnId === 'wbsItem.watchers') {
        return (
          params.value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER) ?? ''
        )
      }
      // Risk parameteres ----
      if (
        row?.wbsItem?.ticketType === TicketType.RISK &&
        ['riskType', 'probability', 'impact', 'strategy'].includes(columnId)
      ) {
        if (!cellRendererParams) return undefined
        const { valuesAllowed } = cellRendererParams
        if (!valuesAllowed) return undefined
        const option = valuesAllowed.find(v => v.value === params.value)
        return option?.name ?? ''
      }
      if (!type) {
        if (cellEditorParams) {
          const { entity } = cellEditorParams
          if (entity) {
            // For extension EntitySearch column
            const options = params.context[entity] ?? []
            const option = options.find(v =>
              [params.value, params.value?.uuid].includes(v.uuid)
            )
            return (
              getLabel(option?.nameI18n) ||
              option?.displayName ||
              option?.name ||
              ''
            )
          }
        }
        if (typeof params.value === 'object') {
          return params.value.name ?? params.value.displayName ?? undefined
        }
      } else {
        if (type.includes(ColumnType.multiSelect)) {
          return (
            params?.value?.map(v => v.name).join(MULTI_PROPERTY_DELIMITER) ?? ''
          )
        }
      }
      return undefined
    },
    []
  )

  const onExcelExport = useCallback(
    (colIds: string[]) => {
      if (!gridOptions) return

      exportExcel({
        fileNamePrefix: 'ticket_list_edit',
        gridOptions,
        exportColIds: colIds,
        shouldRowBeSkipped: (params: ShouldRowBeSkippedParams) =>
          !params.node.displayed,
        getCustomExportValue: customExportValues,
      })
    },
    [gridOptions, customExportValues]
  )

  // Import -------------
  const onExcelImport = useCallback(
    (
      ticketType: TicketType,
      defaultTicketList: TicketListBasic | undefined,
      raw: Uint8Array
    ) => {
      if (!gridOptions?.context) return
      try {
        setLoading(true)
        // Convert excel file
        const workbook = XLSX.read(raw, { type: 'array', dense: true })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const source: object[] = XLSX.utils.sheet_to_json(worksheet)

        const parser = getExcelParser({
          ticketType,
          excelSrouce: source,
          data,
          context: gridOptions?.context,
          dataManager,
          defaultTicketList,
        })

        const { rows: newData, errorMessages } = parser.parse()
        if (errorMessages.length > 0) {
          store.dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'projectPlan.import.warning' }),
              text: errorMessages.join('\n'),
            })
          )
          return
        }
        setData(newData)
      } finally {
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, gridOptions, setLoading, setData]
  )

  return {
    onExcelExport,
    onExcelImport,
  }
}
