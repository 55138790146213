import { Button } from '../../../../components/buttons'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { colorPalette } from '../../../../style/colorPallete'

const ReloadButton = ({ onReload }: { onReload: () => void }) => {
  return (
    <Button
      onClick={onReload}
      sx={{
        background: colorPalette.monotone[1],
        border: `1px solid ${colorPalette.monotone[3]}`,
        borderRadius: '50%',
        height: '28px',
        width: '28px',
        marginRight: '10px',
        padding: '5px 8px',
        cursor: 'pointer',
        '&:hover': { background: colorPalette.monotone[1] },
      }}
    >
      <ReloadIcon />
    </Button>
  )
}

export default ReloadButton
