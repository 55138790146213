import moment from 'moment'

const relativeDateTuple = ['YESTERDAY', 'TODAY', 'TOMORROW'] as const
export type RelativeDate = (typeof relativeDateTuple)[number]

export type RelativeDateVO = {
  readonly type: 'Relative'
  readonly value: RelativeDate
}

export type AbsoluteDateVO = {
  readonly type: 'Absolute'
  readonly value: moment.Moment
}

export type DateVO = RelativeDateVO | AbsoluteDateVO

const defaultFormatter = 'YYYY-MM-DD'

// Public functions.
const construct = (
  value: string | number | Date | RelativeDate | undefined
): DateVO => {
  if (typeof value === 'string') {
    const relativeDate = toRelativeDate(value)
    if (relativeDate) {
      return {
        type: 'Relative',
        value: relativeDate,
      }
    }
  }
  const m = moment(value)
  return {
    type: 'Absolute',
    value: m,
  }
}

const isValid = (src: DateVO) => {
  switch (src.type) {
    case 'Relative':
      return true
    case 'Absolute':
      return src.value.isValid()
  }
}

const toDate = (src: DateVO): Date => {
  switch (src.type) {
    case 'Relative':
      return relativeDateToMoment(src.value).toDate()
    case 'Absolute':
      return src.value.toDate()
  }
}

const toNumberValue = (src: DateVO): number => {
  switch (src.type) {
    case 'Relative':
      return relativeDateToMoment(src.value).valueOf()
    case 'Absolute':
      return src.value.valueOf()
  }
}

const toString = (src: DateVO): string => {
  switch (src.type) {
    case 'Relative':
      return src.value
    case 'Absolute':
      return src.value.format('YYYY-MM-DD')
  }
}

const format = (src: DateVO, formatter: string = defaultFormatter): string => {
  switch (src.type) {
    case 'Relative':
      return relativeDateToMoment(src.value).format(formatter)
    case 'Absolute':
      return src.value.format(formatter)
  }
}

const isRelativeDate = (src: DateVO): boolean => {
  return src.type === 'Relative'
}

const isEqual = (a: DateVO, b: DateVO): boolean => {
  switch (a.type) {
    case 'Relative':
      if (b.type === 'Absolute') return false
      return a.value === b.value
    case 'Absolute':
      if (b.type === 'Relative') return false
      return a.value.isSame(b.value, 'd')
  }
}

const constructRelativeDate = (value: string): RelativeDateVO | undefined => {
  const relativeDate = toRelativeDate(value)
  if (!relativeDate) return undefined
  return {
    type: 'Relative',
    value: relativeDate,
  }
}

const castToRelativeDate = (src: DateVO): RelativeDateVO | undefined => {
  if (src.type === 'Absolute') return undefined
  return src
}

// Private functions.
const relativeDateToMoment = (src: RelativeDate): moment.Moment => {
  switch (src) {
    case 'YESTERDAY':
      return moment()
        .subtract(1, 'day')
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
    case 'TODAY':
      return moment().hour(0).minute(0).second(0).millisecond(0)
    case 'TOMORROW':
      return moment().add(1, 'day').hour(0).minute(0).second(0).millisecond(0)
  }
}

const toRelativeDate = (str: string): RelativeDate | undefined => {
  return relativeDateTuple.find(s => s === str)
}

const toMoment = (date: DateVO): moment.Moment => {
  return isRelativeDate(date)
    ? relativeDateToMoment(date.value as RelativeDate)
    : (date as AbsoluteDateVO).value
}

const diff = (srcStart: DateVO, srcEnd: DateVO): number => {
  const start: moment.Moment = toMoment(srcStart)
  const end: moment.Moment = toMoment(srcEnd)
  return start.diff(end, 'days')
}

const isBetween = (
  date: DateVO,
  startDate: DateVO,
  endDate: DateVO,
  inclusivity: '()' | '[)' | '(]' | '[]' = '()'
): boolean => {
  const start: moment.Moment = toMoment(startDate)
  const end: moment.Moment = toMoment(endDate)
  const target: moment.Moment = toMoment(date)
  return target.isBetween(start, end, null, inclusivity)
}

export const dateVoService = {
  construct,
  isValid,
  toDate,
  toNumberValue,
  toString,
  format,
  isRelativeDate,
  isEqual,
  constructRelativeDate,
  castToRelativeDate,
  diff,
  isBetween,
}
