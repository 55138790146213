import { ColDef } from 'ag-grid-community'
import { Button } from '../../../../components/buttons'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import { colorPalette } from '../../../../style/colorPallete'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import { Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { AllState } from '../../../../../store'
import { connect } from 'react-redux'

interface OwnProps {
  loading: boolean
  onSubmit: () => void
  onReload: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn: (column: ColDef) => void
  resetFilters: () => void
  sortColumnState: SortedColumnState[]
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  columnSettingOpen: boolean
  onClickColumnSettingButton: (e) => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps

const LedgerAccountsHeader = (props: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 10px 5px 10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <Button
        disabled={props.loading || props.notEdited}
        onClick={props.onSubmit}
        colorPattern="green"
        sx={{
          border: 'none',
          cursor: 'pointer',
          padding: '8px 0px',
          height: '28px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <Typography
          sx={{ color: colorPalette.monotone[0], margin: '0 2px 0 15px' }}
        >
          {intl.formatMessage({ id: 'wbs.header.save.button' })}
        </Typography>
        <Typography
          sx={{
            color:
              props.loading || props.notEdited
                ? colorPalette.monotone[0]
                : colorPalette.green[2],
            margin: '0 10px 0 2px',
            fontSize: '11px',
          }}
        >
          {intl.formatMessage({ id: 'wbs.header.save.button.shortcutKey' })}
        </Typography>
      </Button>
      <Button
        onClick={props.onReload}
        disabled={props.loading}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      <FilterButton
        filteredColumns={props.filteredColumns}
        onDeleteFilteredColumn={props.onDeleteFilteredColumn}
        resetFilters={props.resetFilters}
      />
      <SortButton
        sortColumnsState={props.sortColumnState}
        onDeleteSortedColumn={props.onDeleteSortedColumn}
        onDeleteSortedAllColumns={props.onDeleteSortedAllColumns}
        onChangeSortColumnState={props.onChangeSortColumnState}
      />
      <ColumnSettingButton
        open={props.columnSettingOpen}
        onClick={props.onClickColumnSettingButton}
      />
    </div>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(LedgerAccountsHeader)
