import store from '../../store'
import EntitySearch from '../commons/entitySearch'
import * as ProjectPlanAPI from './projectPlan'
import { ProcessProps } from './process'
import { FunctionProperty } from '../commons/appFunction'
import { APIResponse } from '../commons/api'
import { WbsItemType } from '../../domain/entity/WbsItemEntity'

class Workgroup extends EntitySearch {
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      // Do not use the query to get all workgroups in the project.
      (query: string) => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse,
      true
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      () => ({
        projectUuid: store.getState().project.selected,
      }),
      this.toResponse
    )
  }

  toResponse = (process: ProcessProps) => {
    return {
      uuid: process.uuid,
      name: process.displayName,
    }
  }

  entitySearchApi = async (props, signal?: AbortSignal) => {
    const response = await ProjectPlanAPI.getTree(
      props.projectUuid,
      [WbsItemType.PROCESS],
      undefined,
      1
    )
    return {
      ...response,
      json: (response.json && response.json.children.map(v => v.wbsItem)) || [],
    }
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const workgroup = response.json.find(v => v.uuid === uuid)
    return { status: 200, json: this.toResponse(workgroup) }
  }
}

export default new Workgroup()
