import React, { useEffect } from 'react'
import Mousetrap from 'mousetrap'
import store from '../../store'
import {
  addKeyBindListeners as addKeyBindListenersRedux,
  removeKeyBindListeners as removeKeyBindListenersRedux,
} from '../../store/keyBind'
import { KeyBindListeners, KeyBindHandlers } from '../model/keyBind'

// Higher Order Components should not be used.
// This file will be removed after replacing each components by functional components.

export interface WithKeyBindProps extends KeyBindHandlers {}

export const withKeyBind = WrappedComponent => {
  return props => {
    const addKeyBindListeners = (listeners: KeyBindListeners) => {
      store.dispatch(addKeyBindListenersRedux(listeners))
    }
    const removeKeyBindListeners = () => {
      store.dispatch(removeKeyBindListenersRedux())
    }
    return (
      <WrappedComponent
        {...props}
        addKeyBindListeners={addKeyBindListeners}
        removeKeyBindListeners={removeKeyBindListeners}
      />
    )
  }
}
