import React from 'react'
import RichTextField, { Props as RichTextFieldProps } from '.'
import { GridApi, Column } from 'ag-grid-community'
import { addEventListenerForLastRow } from '../../../..'

interface OwnProps {
  parseValue?: (value: any) => any
  formatValue?: (value: any) => any
  api: GridApi
  keyPress: number
  rowIndex: number
  column: Column
}

interface State {
  value: string
  hasError: boolean
}

class TextCell extends React.PureComponent<
  OwnProps & RichTextFieldProps,
  State
> {
  ref = React.createRef<HTMLInputElement>()
  state: State = this.createInitialState(this.props)

  private createInitialState(props) {
    let startValue: string
    if (!this.isEditable(props)) {
      startValue = props.value
    } else if (
      props.keyPress === 8 /* BACKSPACE */ ||
      props.keyPress === 46 /* DELETE */
    ) {
      startValue = ''
    } else if (props.charPress) {
      startValue = props.charPress
    } else {
      startValue = props.value
    }
    return {
      value: startValue,
      hasError: false,
    }
  }

  isEditable(props = this.props) {
    if (props.disabled) {
      return !props.disabled
    }
    const editable = props['colDef'].editable
    return typeof editable === 'function' ? editable(props) : editable
  }

  componentDidMount() {
    if (this.ref.current && this.isEditable()) {
      this.ref.current.focus()
      this.ref.current.selectionStart = this.ref.current.selectionEnd =
        this.ref.current.value.length
      this.ref.current.scrollTop = this.ref.current.scrollHeight // scroll to the bottom in case the cell has scrollbars
      addEventListenerForLastRow(this.ref.current, this.props)
    }
    if (
      this.props.keyPress === 46 ||
      this.props.keyPress === 8 /* DELETE or BACKSPACE */
    ) {
      this.props.api.clearFocusedCell()
      this.props.api.setFocusedCell(this.props.rowIndex, this.props.column)
    }
  }

  getValue = () => {
    // Call parseValue method to apply valueParser defined at ag-Grid column definition
    if (this.state.hasError) {
      return ''
    }
    return this.props.parseValue
      ? this.props.parseValue(this.state.value)
      : this.state.value
  }

  getFormattedValue = () => {
    return this.state.value
  }

  private changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: e.target.value })
  }

  private blurred = e => {
    this.props.api.stopEditing()
  }

  private setError = (hasError: boolean) => {
    this.setState({ hasError: hasError })
  }

  render() {
    return (
      <RichTextField
        value={this.getFormattedValue()}
        disabled={!this.isEditable()}
        inputRef={this.ref}
        data={this.props.data}
        uiMeta={this.props.uiMeta}
        viewMeta={this.props.viewMeta}
        onChange={this.changed}
        onBlur={this.blurred}
        setError={this.setError}
      />
    )
  }
}

export class TextCellViewer extends TextCell {
  isEditable = () => false

  getFormattedValue = () => {
    // Apply valueFormatter
    return this.props.formatValue
      ? this.props.formatValue(this.state.value)
      : this.state.value
  }
}

export default TextCell
