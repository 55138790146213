import { List } from 'immutable'
import React, { useCallback, useState } from 'react'
import { styled } from '@mui/system'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import MailIcon from '@mui/icons-material/MailRounded'
import FileCopyIcon from '@mui/icons-material/FileCopyRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import AnnouncementIcon from '@mui/icons-material/AnnouncementRounded'
import WarningIcon from '@mui/icons-material/WarningRounded'
import ExpandLess from '@mui/icons-material/ExpandLessRounded'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
import Collapse from '@mui/material/Collapse'
import { formatDateTime } from '../../../utils/date'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Message as IMessage, MessageLevel } from '../../../store/messages'
import { IconButton, Tooltip } from '@mui/material'
import { intl } from '../../../i18n'
import { BackgroundColor, TextColor } from '../../../styles/commonStyles'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../pages'

interface Props extends WrappedComponentProps {
  screenName: string
  globalMessages: List<IMessage>
  screenMessages: List<IMessage>
}

const RootDiv = styled('div')({
  width: '100%',
  padding: '10px',
})
const Border = styled('div')({
  backgroundColor: BackgroundColor.GREY,
  height: '2px',
  margin: '8px 0',
})
const MessageHeader = styled('div')({
  width: '100%',
  display: 'flex',
})
const MessageTitleBox = styled('div')({
  width: '100%',
  textAlign: 'left',
  marginBottom: '5px',
  display: 'flex',
  alignItems: 'center',
})
const MessageTitle = styled('span')({
  marginLeft: '5px',
  letterSpacing: '0px',
  color: TextColor.BLACK,
  opacity: 1,
  verticalAlign: 'middle',
  fontWeight: 'bold',
})
const StyledExpandLess = styled(ExpandLess)({
  verticalAlign: 'middle',
  cursor: 'pointer',
})
const StyledExpandMore = styled(ExpandMore)({
  verticalAlign: 'middle',
  cursor: 'pointer',
})
const MessageText = styled('div')({
  width: '100%',
  margin: 0,
  maxHeight: '200px',
  overflow: 'scroll',
  letterSpacing: '0',
  color: TextColor.BLACK,
  opacity: 1,
  whiteSpace: 'pre-line',
})
const MessageTextFooter = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const MessageAt = styled('div')({
  textAlign: 'right',
  verticalAlign: 'middle',
  letterSpacing: '0px',
  color: TextColor.GREY,
  opacity: 1,
})
const SmallIconButton = styled(IconButton)({
  padding: '5px',
}) as typeof IconButton

const MailToIcon = ({ message }) => {
  let mailBodyMessage = (message.detail || message.text)
    .replace(/[\n]/g, '%0D%0A')
    .replace(/[\t]/g, '%20')
    .replace(/;/g, '%3B')
  mailBodyMessage =
    mailBodyMessage.length <= 1000
      ? mailBodyMessage
      : `${mailBodyMessage.substr(0, 1000)}%0D%0A... and more`
  const mailtoLink = intl.formatMessage(
    { id: 'messages.mailToLink' },
    {
      mailAddress: process.env.REACT_APP_SUPPORT_MAIL_ADDRESS,
      mailBody: mailBodyMessage,
    }
  )

  return (
    <Tooltip title="Mail to support team">
      <SmallIconButton href={mailtoLink}>
        <MailIcon color="action" fontSize="small" />
      </SmallIconButton>
    </Tooltip>
  )
}

const MessageCopyIcon = ({ message }) => {
  const copyMessage = useCallback(() => {
    const { title, at, text, detail } = message
    const el = document.createElement('textarea')
    el.value = intl.formatMessage(
      { id: 'message.messageCopy' },
      {
        title: title,
        dateTime: formatDateTime(at),
        text: detail || text,
      }
    )
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }, [])
  return (
    <Tooltip title="Copy message">
      <SmallIconButton onClick={_ => copyMessage()}>
        <FileCopyIcon color="action" fontSize="small" />
      </SmallIconButton>
    </Tooltip>
  )
}

const defaultOpen = (message): boolean =>
  [MessageLevel.ERROR, MessageLevel.WARN].includes(message.type)

const MessageIcon = ({ level }: { level: MessageLevel }) => {
  switch (level) {
    case MessageLevel.ERROR:
      return <AnnouncementIcon color="error" />
    case MessageLevel.WARN:
      return <WarningIcon color="warning" />
    default:
      return <InfoRoundedIcon color="primary" />
  }
}

const Message = ({ message, index }) => {
  const [opened, setOpened] = useState(defaultOpen(message))

  return (
    <div key={`message-${index}`}>
      <MessageHeader>
        <MessageTitleBox>
          <MessageIcon level={message.type} />
          <MessageTitle>{message.title}</MessageTitle>
        </MessageTitleBox>
        <div>
          {opened ? (
            <StyledExpandLess color="action" onClick={() => setOpened(false)} />
          ) : (
            <StyledExpandMore color="action" onClick={() => setOpened(true)} />
          )}
        </div>
      </MessageHeader>
      <Collapse in={opened} timeout="auto" unmountOnExit={true}>
        <div>
          <MessageText>{message.detail || message.text}</MessageText>
          <MessageTextFooter>
            <div>
              <MailToIcon message={message} />
              <MessageCopyIcon message={message} />
            </div>
            <MessageAt>{formatDateTime(message.at)}</MessageAt>
          </MessageTextFooter>
        </div>
      </Collapse>
    </div>
  )
}

const InformationMessage = ({ globalMessages, screenMessages }: Props) => {
  const messages = globalMessages
    .map(v => ({ global: true, ...v }))
    .concat(screenMessages.map(v => ({ global: false, ...v })))
    .filter((v: any) => v.type !== MessageLevel.SUCCESS)
    .sortBy((v: any) => v.at)

  if (messages.isEmpty()) {
    return (
      <RootDiv>
        <div>{intl.formatMessage({ id: 'messages.none' })}</div>
      </RootDiv>
    )
  }
  return (
    <RootDiv>
      {messages.map((message, index) => {
        const requiredToBorder = index > 0
        return (
          <div key={`message-box${index}`}>
            {requiredToBorder && <Border />}
            <Message index={index} message={message} />
          </div>
        )
      })}
    </RootDiv>
  )
}

const mapStateToProps = (state: AllState) => {
  const screenName = state.appFunction
    .currentExternalId as APPLICATION_FUNCTION_EXTERNAL_ID
  const screenMessages = screenName
    ? state.messages.screens.get(screenName)
    : undefined
  return {
    screenName,
    globalMessages: state.messages.global || List(),
    screenMessages: screenMessages || List(),
  }
}

export default connect(mapStateToProps)(injectIntl(InformationMessage))
