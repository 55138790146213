import _ from 'lodash'
import React, { useCallback } from 'react'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { RowNode } from 'ag-grid-community'
import AggregateInformation, {
  AggregateValueType,
  AggregatedValue,
  AggregatedValueGroup,
} from './index'
import projectPlanFacadeAggregator, {
  EvmIndicator,
  getDescendantCodes,
  getRate,
} from '../commons/AgGrid/lib/aggregator/projectPlanAggregator'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

export interface Props {
  rowNodes?: RowNode[]
  aggregateType: AggregateField
  workloadUnit: WorkloadUnit
}

const aggregateTypeGroups = [
  [
    AggregateValueType.ESTIMATED_PROGRESS_RATE,
    AggregateValueType.PROGRESS_RATE,
    AggregateValueType.BUDGET_AT_COMPLETION,
    AggregateValueType.PLANNED_VALUE,
    AggregateValueType.EARNED_VALUE,
  ],
  [
    AggregateValueType.PRECEDING,
    AggregateValueType.DELAYED,
    AggregateValueType.REMAINING,
    AggregateValueType.UNPLANNED,
  ],
  [AggregateValueType.ACTUAL_COST, AggregateValueType.COST_PERFORMANCE_INDEX],
]

const aggregateValue = (
  node: RowNode,
  type: AggregateValueType,
  aggregateType: AggregateField,
  rateToHour: number
): number => {
  const v = projectPlanFacadeAggregator(
    node,
    EvmIndicator[type],
    WbsItemType.TASK,
    type === AggregateValueType.ACTUAL_COST
      ? AggregateField.WBS_ITEM_WORKLOAD
      : aggregateType,
    aggregateType === AggregateField.WBS_ITEM_WORKLOAD ||
      type === AggregateValueType.ACTUAL_COST
      ? rateToHour
      : 1
  )
  return !Number.isFinite(v) || Number.isNaN(v) ? 0 : Number(v)
}

const calculateRate = (
  rowNodes: RowNode[],
  rateToHour: number,
  aggregateType: AggregateField,
  numeratorType: AggregateValueType,
  denominatorType: AggregateValueType
): number => {
  let totalNumerator: number = 0
  let totalDenominator: number = 0
  rowNodes.forEach((node: RowNode) => {
    const numerator = aggregateValue(
      node,
      numeratorType,
      aggregateType,
      rateToHour
    )
    if (!Number.isNaN(numerator)) {
      totalNumerator += numerator
    }
    const denominator = aggregateValue(
      node,
      denominatorType,
      aggregateType,
      rateToHour
    )
    if (!Number.isNaN(denominator)) {
      totalDenominator += denominator
    }
  })
  return getRate(totalNumerator, totalDenominator)
}

const aggregateRowsValue = (
  type: AggregateValueType,
  aggregateType: AggregateField,
  rowNodes: RowNode[],
  rateToHour: number
): number => {
  switch (type) {
    case AggregateValueType.ESTIMATED_PROGRESS_RATE:
      return calculateRate(
        rowNodes,
        rateToHour,
        aggregateType,
        AggregateValueType.PLANNED_VALUE,
        AggregateValueType.BUDGET_AT_COMPLETION
      )
    case AggregateValueType.PROGRESS_RATE:
      return calculateRate(
        rowNodes,
        rateToHour,
        aggregateType,
        AggregateValueType.EARNED_VALUE,
        AggregateValueType.BUDGET_AT_COMPLETION
      )
    case AggregateValueType.COST_PERFORMANCE_INDEX:
      return calculateRate(
        rowNodes,
        rateToHour,
        AggregateField.WBS_ITEM_WORKLOAD,
        AggregateValueType.EARNED_VALUE,
        AggregateValueType.ACTUAL_COST
      )
    default:
      return rowNodes.reduce((total: number, node: RowNode) => {
        return total + aggregateValue(node, type, aggregateType, rateToHour)
      }, 0)
  }
}

const TicketAggregateInformation = (props: Props) => {
  const [aggregatedValueGroups, setAggregatedValueGroups] = React.useState<any>(
    []
  )

  const { hoursPerSelectedUnit } = useWorkloadUnit(props.workloadUnit)
  const aggregate = useCallback((): AggregatedValueGroup => {
    const aggregateType = props.aggregateType
    const rowNodes = props.rowNodes
    if (!rowNodes || _.isEmpty(rowNodes)) return []
    const type2Value = (type: AggregateValueType): AggregatedValue => {
      const codes: string[] | undefined = []
      rowNodes.forEach(node => {
        getDescendantCodes(node, WbsItemType.TASK, EvmIndicator[type])
          ?.filter(c => c)
          .forEach(c => codes.push(c))
      })
      return {
        type,
        value: aggregateRowsValue(
          type,
          aggregateType,
          rowNodes,
          hoursPerSelectedUnit
        ),
        codes: codes.length !== 0 ? codes : undefined,
      }
    }

    return aggregateTypeGroups.map(v => v.map(type2Value))
  }, [props.aggregateType, props.rowNodes, hoursPerSelectedUnit])

  React.useEffect(() => {
    props.rowNodes && setAggregatedValueGroups(aggregate())
  }, [aggregate, props.rowNodes])

  return (
    <AggregateInformation
      aggregatedValueGroups={aggregatedValueGroups}
      aggregateType={props.aggregateType}
      wbsItemType={WbsItemType.TASK}
    />
  )
}

export default TicketAggregateInformation
