import API, { APIResponse } from '../commons/api'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'

export interface CreateEntityExtensionProps {}

export interface UpdateEntityExtensionProps {}
export interface DeleteEntityExtensionProps {}
export interface GetEntityExtensionDetailProps {
  uuid: string
}

class EntityExtension implements SingleSheetRepository {
  public create = (props: CreateEntityExtensionProps): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/functions/properties/extension',
      props
    )
  }

  public update = (props: UpdateEntityExtensionProps): Promise<APIResponse> => {
    return API.functional.request(
      'PUT',
      '/api/v1/functions/properties/extension',
      props
    )
  }

  public delete = (props: DeleteEntityExtensionProps): Promise<APIResponse> => {
    return API.functional.request(
      'DELETE',
      '/api/v1/functions/properties/extension',
      props
    )
  }

  public getDetail = (props: GetEntityExtensionDetailProps): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/functions/properties/extension',
      props
    )
  }
  getBasicByCode(code: string): Promise<APIResponse> {
    throw new Error('Not implemented.')
  }
}

export interface GetEntityExtensionsProps {
  applicationFunctionUuid: string
  groupKeys?: string[]
}

export const getExtensions = (
  props: GetEntityExtensionsProps
): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/functions/properties/extensions',
    props
  )
}

export default new EntityExtension()
