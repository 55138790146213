// TODO: Consider expressing these functions in domain layer.

import * as d3 from 'd3'
import { DateTickIntervalType } from '../hooks/chart/useXTicks'
import moment from 'moment'

export const dateOffset = (
  target: Date,
  diff: number,
  intervalType: DateTickIntervalType
): Date => {
  switch (intervalType) {
    case 'DAY':
      return moment(target).add(diff, 'd').toDate()
    case 'WEEK':
      return moment(target).add(diff, 'w').toDate()
    case 'MONTH':
      return moment(target).add(diff, 'M').toDate()
    case 'THREE_MONTH':
      return moment(target)
        .add(3 * diff, 'M')
        .toDate()
    case 'HALF_YEAR':
      return moment(target)
        .add(6 * diff, 'M')
        .toDate()
    case 'YEAR':
      return moment(target).add(diff, 'y').toDate()
  }
}
