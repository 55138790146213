/* eslint-disable no-underscore-dangle */
import Moment from 'moment'
import repositories, { EntitySearchValue } from '../repositories'
import Objects from '../../../../utils/objects'

import AppFunction, {
  AppFunctionsGetDetailResponse,
  ApplicationContext,
  FunctionProperty,
  Position,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { Attachment } from '../../../../utils/attachment'
import { I18n } from '../../../../lib/commons/i18nLabel'
import { DISPLAY_DATETIME_FORMAT } from '../../../../utils/date'
import {
  deserializeExtensionValue,
  EntityExtensionValue,
  serializeExtensionValue,
} from '../entityExtension'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'

export enum SubmitType {
  Disable = 'DISABLE',
  Create = 'CREATE',
  Update = 'UPDATE',
}

export type FunctionMeta = {
  detail: AppFunctionsGetDetailResponse
  treeProperty?: FunctionProperty
  properties: {
    byId: Map<string, FunctionProperty>
    byRowIndex: Map<number, FunctionProperty>
    byIndex: FunctionProperty[][]
  }
  parentPropertyIds: Set<string | undefined>
  entityExtensions: {
    byId: Map<String, FunctionProperty>
    byUuid: Map<String, FunctionProperty>
  }
  tabViewProperties: {
    left: {
      byId: Map<string, FunctionProperty>
      byIndex: FunctionProperty[]
    }
    right: {
      byId: Map<string, FunctionProperty>
      byIndex: FunctionProperty[]
    }
  }
  relatedProperties: Map<string, FunctionProperty[]>
}

export default class ViewMeta {
  readonly uuid: string
  readonly externalId: string
  readonly externalIdPrefix: string
  readonly uniqueKey?: string
  private context?: ApplicationContext
  private entityCache = {}

  private _functionMeta?: FunctionMeta
  public get functionMeta(): FunctionMeta {
    return this._functionMeta!
  }

  public setContext(_context: ApplicationContext | undefined) {
    this.context = _context
  }

  constructor(
    functionUuid: string,
    functionExternalId: string,
    uniqueKey?: string
  ) {
    this.uuid = functionUuid
    this.externalId = functionExternalId
    this.externalIdPrefix =
      functionExternalId.substr(0, functionExternalId.lastIndexOf('.')) ||
      this.externalId
    this.uniqueKey = uniqueKey
  }

  protected async getFunctionDetail(): Promise<AppFunctionsGetDetailResponse> {
    const response = await AppFunction.getDetail({
      applicationFunctionUuid: this.uuid,
      groupKeys: this.context?.groupKeys,
    })
    return response.json
  }

  isReadOnlyForProperty = (submitType: SubmitType, prop: FunctionProperty) => {
    return submitType === SubmitType.Create
      ? prop.editableIfC && !prop.editableIfC.isTrue
      : prop.editableIfU && !prop.editableIfU.isTrue
  }

  isEntityExtension = (prop: FunctionProperty) => {
    return !!this.functionMeta.entityExtensions.byUuid.get(
      prop.entityExtensionUuid
    )
  }

  serializeEntityExtensionsForApi = (extensions?: EntityExtensionValue[]) => {
    if (!extensions) return
    return extensions.map(extension => {
      const functionProperty = this.functionMeta.entityExtensions.byUuid.get(
        extension.uuid
      )
      return this.serializeExtensionForApi(extension, functionProperty)
    })
  }

  serializeEntityExtensionsDeltaForApi = (delta: {
    oldExtensions?: EntityExtensionValue[]
    newExtensions?: EntityExtensionValue[]
  }): {
    uuid: string
    oldValue?: any
    newValue?: any
  }[] => {
    const { oldExtensions, newExtensions } = delta
    const extensionUuids: string[] = []
    const oldExtensionValueMapByUuid: {
      [entityUuid: string]: EntityExtensionValue
    } = {}
    oldExtensions?.forEach(e => {
      oldExtensionValueMapByUuid[e.uuid] = e
      if (!extensionUuids.includes(e.uuid)) {
        extensionUuids.push(e.uuid)
      }
    })
    const newExtensionValueMapByUuid: {
      [entityUuid: string]: EntityExtensionValue
    } = {}
    newExtensions?.forEach(e => {
      newExtensionValueMapByUuid[e.uuid] = e
      if (!extensionUuids.includes(e.uuid)) {
        extensionUuids.push(e.uuid)
      }
    })
    const extensionDelta: {
      uuid: string
      oldValue?: any
      newValue?: any
    }[] = []
    extensionUuids.forEach(extensionUuid => {
      const functionProperty =
        this.functionMeta.entityExtensions.byUuid.get(extensionUuid)
      const oldValue = oldExtensionValueMapByUuid[extensionUuid]
        ? this.serializeExtensionForApi(
            oldExtensionValueMapByUuid[extensionUuid],
            functionProperty
          )
        : undefined
      const newValue = newExtensionValueMapByUuid[extensionUuid]
        ? this.serializeExtensionForApi(
            newExtensionValueMapByUuid[extensionUuid],
            functionProperty
          )
        : undefined

      if (oldValue?.value !== newValue?.value) {
        extensionDelta.push({
          uuid: extensionUuid,
          oldValue: oldValue?.value,
          newValue: newValue?.value,
        })
      }
    })
    return extensionDelta
  }

  private serializeExtensionForApi = (
    extension: EntityExtensionValue,
    functionProperty?: FunctionProperty
  ): EntityExtensionValue => {
    if (functionProperty) {
      let value = extension.value
      if (functionProperty.referenceEntity && extension.value) {
        value = extension.value.uuid
      }
      return {
        uuid: extension.uuid,
        value: serializeExtensionValue(value, functionProperty),
      }
    }
    return extension
  }

  deserializeEntityExtensions = (
    extensions: EntityExtensionValue[]
  ): EntityExtensionValue[] => {
    return extensions
      .map(ext => {
        const extension = this.functionMeta.entityExtensions.byUuid.get(
          ext.uuid
        )
        if (!extension) {
          return
        }
        return {
          uuid: ext.uuid,
          value: deserializeExtensionValue(ext.value, extension),
        }
      })
      .filter(v => !!v) as EntityExtensionValue[]
  }

  public async getOrFetchFunctionMeta(
    submitType?: SubmitType
  ): Promise<FunctionMeta> {
    const detail = await this.getFunctionDetail()
    const treeProperty = detail.properties.find(v => v.tree)
    let gridProperties = this.extractPropertiesByLayout(
      detail.properties,
      Position.Default
    )
    gridProperties = this.orderProperties(gridProperties)
    this.fillCustomEnumCombinations(gridProperties)
    const parentPropertyIds = new Set(gridProperties.map(p => p.parentProperty))
    const propertiesById = new Map()
    const propertiesByRowIndex = new Map()
    const propertiesByIndex: FunctionProperty[][] = []
    const entityExtensionsById = new Map()
    const entityExtensionsByUuid = new Map()
    for (let i = 0; i < gridProperties.length; i++) {
      const prop = gridProperties[i]
      propertiesById.set(prop.externalId, prop)
      if (!prop.parentProperty) {
        propertiesByRowIndex.set(prop.propertyOrder, prop)
        propertiesByIndex[prop.propertyOrder] = [prop]
      } else {
        const parentProp = propertiesById.get(prop.parentProperty)
        let row = propertiesByIndex[parentProp.propertyOrder]
        if (!row) {
          row = []
          propertiesByIndex[parentProp.propertyOrder] = row
        }
        row[prop.propertyOrder] = prop
      }
      if (prop.entityExtensionUuid) {
        entityExtensionsById.set(prop.externalId, prop)
        entityExtensionsByUuid.set(prop.entityExtensionUuid, prop)
      }
    }
    const leftProperties = this.extractPropertiesByLayout(
      detail.properties,
      Position.Left,
      submitType
    )
    const rightProperties = this.extractPropertiesByLayout(
      detail.properties,
      Position.Right,
      submitType
    )
    const relatedProperties = new Map()
    gridProperties.forEach(prop => {
      const properties = gridProperties.filter(
        v => v.requiredIf.relatedField === prop.externalId
      )
      if (properties.length > 0) {
        relatedProperties.set(prop.externalId, properties)
      }
    })
    this._functionMeta = {
      detail,
      treeProperty,
      properties: {
        byId: propertiesById,
        byRowIndex: propertiesByRowIndex,
        byIndex: propertiesByIndex,
      },
      parentPropertyIds,
      entityExtensions: {
        byId: entityExtensionsById,
        byUuid: entityExtensionsByUuid,
      },
      tabViewProperties: {
        left: this.createTabViewProperties(leftProperties),
        right: this.createTabViewProperties(rightProperties),
      },
      relatedProperties: relatedProperties,
    }
    return this._functionMeta
  }

  getTabViewProperties = (position: Position): FunctionProperty[] => {
    return this.functionMeta && this.functionMeta.tabViewProperties
      ? this.functionMeta.tabViewProperties[position].byIndex
      : []
  }

  private extractPropertiesByLayout = (
    props: FunctionProperty[],
    position: Position,
    submitType?: SubmitType
  ) => {
    // TODO: fix layout
    const defaultPropertyRegExp = new RegExp(/^(\d+)(?:-(\d+))?/)
    const leftPropertyRegExp = new RegExp(/^(?:p:left-)(\d+)(?:-(\d+))?$/)
    const rightPropertyRegExp = new RegExp(/^(?:p:right-)(\d+)(?:-(\d+))?$/)
    const leftPropertyCreateRegExp = new RegExp(/^(?:c:left-)(\d+)(?:-(\d+))?$/)
    const rightPropertyCreateRegExp = new RegExp(
      /^(?:c:right-)(\d+)(?:-(\d+))?$/
    )
    const functionProperties: FunctionProperty[] = []
    for (let i = 0; i < props.length; i++) {
      const prop = props[i]
      const layoutProps = prop.propertyLayout.split(',')
      let layoutMatch: RegExpExecArray | null = null

      if (position === Position.Default) {
        if (defaultPropertyRegExp.test(prop.propertyLayout)) {
          layoutMatch = defaultPropertyRegExp.exec(prop.propertyLayout)
          prop.position = Position.Default
        }
      } else if (position === Position.Left) {
        if (
          submitType === SubmitType.Create &&
          layoutProps.some(prop => prop.startsWith('c'))
        ) {
          // When submitType is create and there is some rule about create mode,
          // that rule is prior to the general rule.
          const match = layoutProps.find(prop =>
            leftPropertyCreateRegExp.test(prop)
          )
          if (match) {
            layoutMatch = leftPropertyCreateRegExp.exec(match)
            prop.position = Position.Left
          }
        } else {
          const match = layoutProps.find(prop => leftPropertyRegExp.test(prop))
          if (match) {
            layoutMatch = leftPropertyRegExp.exec(match)
            prop.position = Position.Left
          }
        }
      } else if (position === Position.Right) {
        if (
          submitType === SubmitType.Create &&
          layoutProps.some(prop => prop.startsWith('c'))
        ) {
          // When submitType is create and there is some rule about create mode,
          // that rule is prior to the general rule.
          const match = layoutProps.find(prop =>
            rightPropertyCreateRegExp.test(prop)
          )
          if (match) {
            layoutMatch = rightPropertyCreateRegExp.exec(match)
            prop.position = Position.Right
          }
        } else {
          const match = layoutProps.find(prop => rightPropertyRegExp.test(prop))
          if (match) {
            layoutMatch = rightPropertyRegExp.exec(match)
            prop.position = Position.Right
          }
        }
      }

      if (!layoutMatch) {
        continue
      }
      if (
        (submitType === SubmitType.Create && prop.hiddenIfC.isTrue) ||
        (submitType === SubmitType.Update && prop.hiddenIfU.isTrue)
      ) {
        continue
      }
      const orderArray = layoutMatch.filter(Boolean)
      prop.propertyOrder = Number(orderArray[orderArray.length - 1])
      functionProperties.push(prop)
    }
    functionProperties.sort((a: FunctionProperty, b: FunctionProperty) => {
      return a.parentProperty && !b.parentProperty ? 1 : -1
    })
    functionProperties.sort((a: FunctionProperty, b: FunctionProperty) => {
      return a.propertyOrder > b.propertyOrder ? 1 : -1
    })
    return functionProperties
  }

  private createTabViewProperties = (properties: FunctionProperty[]) => {
    Array.from({ length: properties.length }).map(
      (_, index) => (properties[index].propertyOrder = index)
    )
    const tabViewPropertiesById = new Map()
    const tabViewPropertiesByIndex: FunctionProperty[] = []
    for (let i = 0; i < properties.length; i++) {
      const prop = properties[i]
      tabViewPropertiesById.set(prop.externalId, prop)
      tabViewPropertiesByIndex[i] = prop
    }
    return {
      byId: tabViewPropertiesById,
      byIndex: tabViewPropertiesByIndex,
    }
  }

  public deserializeApiValue = (value: any, prop: FunctionProperty) => {
    if (value == null || value === undefined) {
      return null
    }
    if (
      prop.propertyType === PropertyType.Date ||
      prop.propertyType === PropertyType.DateTime
    ) {
      return Moment(value).format('YYYYMMDD')
    }
    if (prop.referenceEntity) {
      // ENTITY_SEARCH or SELECT
      const name = new EntitySearchValue(value).toString()
      const cache = this.entityCache[prop.referenceEntity] || {}
      cache[name] = value
      cache[value.uuid] = value
      this.entityCache[prop.referenceEntity] = cache
      return name
    }
    if (prop.propertyType === PropertyType.Select) {
      const allowed = prop.valuesAllowed.find(v => v.value === value)
      if (allowed) {
        return allowed.name
      }
    }
    return value
  }

  public extractValue = (obj: any, prop: FunctionProperty) => {
    if (prop.parentProperty) {
      const parentProp = this.functionMeta.properties.byId.get(
        prop.parentProperty
      )!
      const parentPath = this.makeDataPropertyName(parentProp)
      const parentObj = Objects.getValue(obj, parentPath)
      const childPath = prop.externalId.startsWith(parentProp.externalId)
        ? prop.externalId.substr(parentProp.externalId.length + 1)
        : this.makeDataPropertyName(prop)
      if (parentObj) {
        return this.deserializeApiValue(
          Objects.getValue(parentObj, childPath),
          prop
        )
      } else {
        return this.deserializeApiValue(Objects.getValue(obj, childPath), prop)
      }
    } else {
      const path = this.makeDataPropertyName(prop)
      return this.deserializeApiValue(Objects.getValue(obj, path), prop)
    }
  }

  private orderProperties = (props: FunctionProperty[]) => {
    const orderedProps: FunctionProperty[] = []
    const parents = new Set()
    let curr = 0
    props.forEach(prop => {
      if (prop.parentProperty) {
        if (!parents.has(prop.parentProperty)) {
          parents.add(prop.parentProperty)
          const children = props.filter(
            child => child.parentProperty === prop.parentProperty
          )
          for (let j = 0; j < children.length; j++) {
            children[j].propertyOrder = j
          }
        }
      } else if (prop.propertyOrder) {
        prop.propertyOrder = curr++
        orderedProps.push(prop)
      }
    })
    let diff = 0
    props.forEach(prop => {
      if (parents.has(prop.externalId)) {
        const children = props.filter(
          child => child.parentProperty === prop.externalId
        )
        for (let j = 0; j < children.length; j++) {
          orderedProps.splice(prop.propertyOrder + diff + 1, 0, children[j])
        }
        diff += children.length
      }
    })
    return orderedProps
  }

  private fillCustomEnumCombinations = (props: FunctionProperty[]): void => {
    for (let i = 0; i < props.length; i++) {
      let prop = props[i]
      if (!prop.valuesAllowed) {
        continue
      }
      for (let j = 0; j < prop.valuesAllowed.length; j++) {
        let value = prop.valuesAllowed[j]
        if (!value.combinations) {
          continue
        }
        for (let k = 0; k < value.combinations.length; k++) {
          let combination = value.combinations[k]
          const combinedEnumProp = props.find(p =>
            p.valuesAllowed?.some(
              customEnum =>
                customEnum.customEnumCode === combination.combinedEnumCode
            )
          )
          if (!combinedEnumProp) {
            continue
          }
          combination.combinedEnumPath =
            this.makeDataPropertyName(combinedEnumProp)
        }
      }
    }
  }

  removeParentProperty = (props: FunctionProperty[]) => {
    return props.filter(
      p => !this.functionMeta.parentPropertyIds.has(p.externalId)
    )
  }

  makeDataPropertyName = (
    prop: FunctionProperty,
    postData: boolean = false
  ) => {
    if (!this.externalIdPrefix) {
      return prop.externalId
    }
    const suffix = postData && prop.referenceEntity ? 'Uuid' : ''
    if (prop.externalId.startsWith(this.externalIdPrefix)) {
      return prop.externalId.substr(this.externalIdPrefix.length + 1) + suffix
    }
    return prop.externalId + suffix
  }

  getPropertyNameSuffix = (prop: FunctionProperty) => {
    return prop.externalId.substr(prop.externalId.lastIndexOf('.') + 1)
  }

  toExternalId = (name: string) => {
    if (
      !this.externalIdPrefix ||
      name.startsWith(this.externalIdPrefix + '.')
    ) {
      return name
    }
    let id = `${this.externalIdPrefix}.${name}`
    if (this.functionMeta.properties.byId.get(id)) {
      return id
    }
    if (id.endsWith('Uuid')) {
      return id.substr(0, id.length - 4)
    }
    return id
  }

  getPropByExternalId = (externalId: string): FunctionProperty | undefined => {
    return (
      this.functionMeta.properties.byId.get(externalId) ||
      this.functionMeta.tabViewProperties.left.byId.get(externalId) ||
      this.functionMeta.tabViewProperties.right.byId.get(externalId)
    )
  }

  isGridProperty = (prop: FunctionProperty): boolean => {
    return (
      !this.functionMeta.parentPropertyIds.has(prop.externalId) &&
      !this.isTabViewProperty(prop)
    )
  }

  isTabViewProperty = (prop: FunctionProperty): boolean => {
    return (
      Array.from(this.functionMeta.tabViewProperties.left.byId.keys()).includes(
        prop.externalId
      ) ||
      Array.from(
        this.functionMeta.tabViewProperties.right.byId.keys()
      ).includes(prop.externalId)
    )
  }

  getPropByCustomEnumCode = (code: string) => {
    return Array.from(this.functionMeta.properties.byId.values()).find(prop =>
      prop.valuesAllowed?.some(value => value.customEnumCode === code)
    )
  }

  serializeInputForApi = (value: any, prop: FunctionProperty) => {
    value = this.convertToActualValue(value, prop)
    if (value === null || value === undefined) {
      return null
    }
    if (prop.referenceEntity) {
      // ENTITY_SEARCH or SELECT
      return value ? value.uuid : value.toString()
    }
    if (prop.propertyType === PropertyType.Select) {
      return value.value
    }
    if (prop.propertyType === PropertyType.Date) {
      return value && value.replace(/\//g, '-')
    }
    if (prop.propertyType === PropertyType.File) {
      return this.serializeFileForApi(value)
    }
    if (prop.propertyType === PropertyType.I18nLabel) {
      const i18n: I18n = value
      const labels: string[] = []
      labels.push(`__global:${i18n.__global}`)
      labels.push(`__uuid:${i18n.__uuid}`)
      i18n.labels.forEach(v => labels.push(`${v.languageCode}:${v.name}`))
      return labels
    }
    return value
  }

  serializeFileForApi = (value?: Attachment[]) => {
    if (!value) {
      return undefined
    }
    let attachments: Attachment[] = value.concat()
    attachments.forEach((value: Attachment, index: number) => {
      attachments[index].updatedBy = undefined
      attachments[index].updatedAt = undefined
    })
    return attachments
  }

  convertToActualValue = (
    value: string | null | undefined,
    prop: FunctionProperty
  ) => {
    if (value === null || value === undefined || !prop) {
      return null
    }
    if (prop.propertyType === PropertyType.Date) {
      const formatted = Moment(value, 'YYYYMMDD').format('YYYY-MM-DD')
      return formatted !== 'Invalid date' ? formatted : null
    }
    if (prop.propertyType === PropertyType.DateTime) {
      const dateTime = Moment(value, DISPLAY_DATETIME_FORMAT)
      const parsedDateTime = Date.parse(dateTime.format('YYYY-MM-DDTHH:mm:ss'))
      return !!parsedDateTime ? parsedDateTime : null
    }
    if (prop.referenceEntity) {
      // ENTITY_SEARCH or SELECT
      const repository = repositories[prop.referenceEntity]
      const cache = this.entityCache[prop.referenceEntity]
      return repository.getCacheByName(value) || (cache && cache[value])
    }
    if (prop.propertyType === PropertyType.Select) {
      return prop.valuesAllowed.find(v => v.name === value)
    }
    return value
  }

  public convertChangeLog = async (
    value: string | null | undefined,
    prop: FunctionProperty
  ) => {
    if (value === null || value === undefined) {
      return null
    }
    if (prop.referenceEntity) {
      const repository = repositories[prop.referenceEntity]
      if (!repository) {
        return value
      }
      const cache = this.entityCache[prop.referenceEntity] || {}
      if (cache[value]) {
        return cache[value]
      }
      const response = await repository.getByUuid(value)
      if (response) {
        cache[response.name] = response
        cache[value] = response
        return response
      }
      return value
    }
    if (prop.propertyType === PropertyType.Select) {
      const val = prop.valuesAllowed.find(v => v.value === value.toString())
      return val || value
    }
    if (prop.propertyType === PropertyType.DateTime) {
      return new DateTimeVO(value).format()
    }
    return value
  }
}
