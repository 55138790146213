import API, { APIResponse } from '../commons/api'
import { UserBasic } from './user'
import { FunctionProperty } from '../commons/appFunction'

export interface Revision {
  revision: string
  referenceTable: string
  delta: Delta[]
  updatedAt: number
  updatedBy?: UserBasic
  deletedAt: number
  deletedBy?: UserBasic
}

export interface Delta {
  path: string
  operation: 'ADD' | 'REPLACE' | 'REMOVE'
  newValue: string
  oldValue: string
  prop?: FunctionProperty
}

class ChangeLogApi {
  getChangeLogsBySubjectUuid(
    subjectUuid: string[],
    offset: number,
    limit: number
  ): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/change_logs', {
      subjectUuid,
      offset,
      limit,
    })
  }
  getChangeLogsByCode(
    code: string[],
    offset: number,
    limit: number
  ): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/change_logs', {
      code,
      offset,
      limit,
    })
  }
}

export default new ChangeLogApi()
