import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../../components/headers/HeaderBar/PageHeader'
import { DateTerm } from '../../../../../utils/date'
import SettingSpeedDial from '../../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  DateRangeSelector,
  SearchUnit,
} from '../../../../components/headers/HeaderBar/DateRangeSelector'
import UserShiftButtonGroup, {
  UserShiftButtonGroupProps,
} from './UserShiftButtonGroup'
import styled from '@emotion/styled'
import { intl } from '../../../../../i18n'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  searchUnit: SearchUnit
  dateTerm: DateTerm
  onSearch: (unit: SearchUnit, dateTerm: DateTerm) => void
  onClickExport: () => void
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  rowHeight: number
  onChangeRowHeight: (value: number) => void
}

type Props = OwnProps & UserShiftButtonGroupProps

const TotalSpan = styled('span')({
  whiteSpace: 'nowrap',
})

const ActualWorkingHoursConfirmHeader = (props: Props) => {
  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <DateRangeSelector
            searchUnit={props.searchUnit}
            dateTerm={props.dateTerm}
            onSearch={props.onSearch}
          />
        </HeaderGroup>
        <HeaderDivider />
        <IconGroup>
          <SettingSpeedDial
            onClickExport={props.onClickExport}
            rowHeight={props.rowHeight}
            onClickChangeRowHeight={props.onChangeRowHeight}
          />
        </IconGroup>
        <HeaderDivider />
        <HeaderGroup>
          <UserShiftButtonGroup
            userName={props.userName}
            currentIndex={props.currentIndex}
            totalCount={props.totalCount}
            onPagingUser={props.onPagingUser}
          />
        </HeaderGroup>
        <HeaderGroup>
          <TotalSpan>
            {intl.formatMessage(
              { id: 'search.hitsTotal' },
              { hit: props.currentIndex, total: props.totalCount }
            )}
          </TotalSpan>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.REPORT]}
          value={props.toolbar}
          onChange={props.onChangeToolbar}
          isNotice={false}
        />
      </HeaderContent>
    </PageHeader>
  )
}

export default ActualWorkingHoursConfirmHeader
