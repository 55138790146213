import { ICellRendererParams } from 'ag-grid-community'
import * as validator from '../../../../lib/validator'
import Tooltip from '../../../../../../../components/tooltips/Tooltip'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import { FloatingEditableIcon } from './index'

interface Props extends ICellRendererParams {
  tooltip: (params: ICellRendererParams) => string | undefined
}

export default function CellRenderer(params: Props) {
  const value =
    !params.valueFormatted && params.valueFormatted !== ''
      ? !params.value && params.value !== 0
        ? ''
        : params.value
      : params.valueFormatted
  const message = validate(value, params)
  const tooltip = params.tooltip ? params.tooltip(params) : undefined

  return (
    <Tooltip message={message || tooltip}>
      <div
        id={`column-${params.column?.getColId()}-${params.rowIndex}`}
        className="sevend-ag-cell"
        style={{
          backgroundColor: !!message ? validator.inputErrorColor : undefined,
        }}
      >
        <p>{value}</p>
        <FloatingEditableIcon {...params} />
      </div>
    </Tooltip>
  )
}

const validate = (value: string, params: ICellRendererParams) => {
  // Flat list rows can be grouped and are read only
  if (!params.colDef) return ''
  const { cellRendererParams } = params.colDef
  if (cellRendererParams && cellRendererParams.uiMeta) {
    const errorMessage = validator.validate(
      value,
      params.data,
      cellRendererParams.uiMeta,
      cellRendererParams.viewMeta,
      params.api
    )
    if (errorMessage) return errorMessage

    const functionProps: FunctionProperty = cellRendererParams.uiMeta
    return params.data.errorMessages &&
      functionProps.externalId in params.data.errorMessages
      ? params.data.errorMessages[functionProps.externalId] || ''
      : ''
  }
  return ''
}
