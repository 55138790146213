const ReloadIcon = () => {
  return (
    <svg
      width="13"
      height="17"
      viewBox="0 0 13 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.00898 11.103C0.758984 10.653 0.583984 10.1999 0.483984 9.7436C0.383984 9.28735 0.333984 8.82173 0.333984 8.34673C0.333984 6.70923 0.924609 5.29985 2.10586 4.1186C3.28711 2.93735 4.69648 2.34673 6.33398 2.34673H7.14023L5.64023 0.846728L6.37148 0.115479L9.16523 2.90923L6.37148 5.70298L5.62148 4.95298L7.10273 3.47173H6.33398C4.99648 3.47173 3.84961 3.94985 2.89336 4.9061C1.93711 5.86235 1.45898 7.00923 1.45898 8.34673C1.45898 8.70923 1.49336 9.05298 1.56211 9.37798C1.63086 9.70298 1.71523 10.0092 1.81523 10.2967L1.00898 11.103ZM6.25898 16.5592L3.46523 13.7655L6.25898 10.9717L6.99023 11.703L5.49023 13.203H6.33398C7.67148 13.203 8.81836 12.7249 9.77461 11.7686C10.7309 10.8124 11.209 9.66548 11.209 8.32798C11.209 7.96548 11.1777 7.62173 11.1152 7.29673C11.0527 6.97173 10.959 6.66548 10.834 6.37798L11.6402 5.57173C11.8902 6.02173 12.0684 6.47485 12.1746 6.9311C12.2809 7.38735 12.334 7.85298 12.334 8.32798C12.334 9.96548 11.7434 11.3749 10.5621 12.5561C9.38086 13.7374 7.97148 14.328 6.33398 14.328H5.49023L6.99023 15.828L6.25898 16.5592Z"
        fill="#7B8CAA"
      />
    </svg>
  )
}

export default ReloadIcon
