import { styled } from '@mui/material'
import { useEffect, useMemo, useRef } from 'react'
import { useGridOptions } from './gridOptions'
import { BulkSheetView } from '../../../../containers/BulkSheetView'
import { BurndownChartData } from '../../model'
import { DateBucket } from '../../../../components/charts/model/timeSeries'
import { ValueFormatters } from '../../hooks/valueFormatters'
import { BurndownChartRowData } from './model'
import { AgGridReact } from 'ag-grid-react'
import './style.scss'

type BurndownChartTableProps = {
  dateBuckets: DateBucket[]
  data: BurndownChartData
} & Pick<ValueFormatters, 'formatValue' | 'formatWorkload' | 'formatRate'> & {
    formatBucket: (b: DateBucket) => string
  }
export const BurndownChartTable = ({
  dateBuckets,
  data,
  formatValue,
  formatWorkload,
  formatRate,
  formatBucket,
}: BurndownChartTableProps) => {
  const ref = useRef<AgGridReact<BurndownChartRowData>>(null)
  const rowData: BurndownChartRowData[] = useMemo(() => {
    return dateBuckets.map((d, i) => ({
      id: d.to.toDateString(),
      date: d,
      scheduleDataBurnDown: data.scheduleDataBurnDown[i].y || 0,
      scheduleData: data.scheduleData[i].y || 0,
      resourceData: data.resourceData[i].y || 0,
      scheduleVelocityData: data.scheduleVelocityData[i].y || 0,
      actualDataBurnDown: data.actualAndProspectDataBurnDown[i].y || 0,
      actualData: data.actualAndProspectData[i].y || 0,
      actualCostData: data.actualCostData[i].y || 0,
      actualVelocityData: data.actualAndProspectVelocityData[i].y || 0,
      simulatedDataBurnDown: data.simulatedDataBurnDown[i].y || 0,
      simulatedData: data.simulatedData[i].y || 0,
      simulatedResourceData: data.simulatedResourceData[i].y || 0,
      simulatedVelocityData: data.simulatedVelocityData[i].y || 0,
    }))
  }, [
    dateBuckets,
    data.scheduleDataBurnDown,
    data.scheduleData,
    data.resourceData,
    data.scheduleVelocityData,
    data.actualAndProspectDataBurnDown,
    data.actualAndProspectData,
    data.actualCostData,
    data.actualAndProspectVelocityData,
    data.simulatedDataBurnDown,
    data.simulatedData,
    data.simulatedResourceData,
    data.simulatedVelocityData,
  ])

  const gridOptionsParams = useMemo(
    () => ({
      formatValue,
      formatWorkload,
      formatRate,
      formatBucket,
    }),
    [formatValue, formatWorkload, formatRate, formatBucket]
  )
  const gridOptions = useGridOptions(gridOptionsParams)

  useEffect(() => {
    if (!ref.current || !ref.current.api) return
    ref.current.api.setColumnDefs([])
    ref.current.api.setColumnDefs(gridOptions.columnDefs || [])
  }, [gridOptions])

  return (
    <TableArea>
      <BulkSheetView
        gridRef={ref}
        gridOptions={gridOptions}
        rowData={rowData}
        getRowId={params => params.data.id}
      />
    </TableArea>
  )
}

const TableArea = styled('div')({
  width: '100%',
  height: '100%',
  padding: '16px',
})
