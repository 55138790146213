import { UiStateKey } from '../../../lib/commons/uiStates'
import TicketListOptions, {
  TicketListRowDataSpec,
} from '../TicketList/TicketListOptions'

const ISSUE = 'ISSUE'

export default class IssueListOptions extends TicketListOptions {
  constructor() {
    super(ISSUE)
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.IssueListColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx =>
    `${UiStateKey.IssueListSearchConditionState}-${ctx.state.uuid}`
  rowDataSpec = new TicketListRowDataSpec(ISSUE)
}
