import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { VersionReportVersionRepository } from '../../applications/ports/versionReportVersionRepository'
import {
  initContent,
  ProgressOverviewPageContent,
  ProgressReportByProcessPageContent,
  VersionReportPageType,
  VersionReportVersionEntity,
} from '../../domain/entity/version-report/VersionReportVersionEntity'
import {
  ProgressOverviewPageConfig,
  ProgressReportByProcessPageConfig,
} from '../../domain/entity/version-report/VersionReportTemplateEntity'
import {
  EvaluateOverviewRequest,
  EvaluateProgressReportByProcessRequest,
  EvaluateProgressReportByTeamRequest,
} from '../../domain/entity/version-report/evaluation'
import { dateTimeV2Service } from '../../domain/value-object/DateTimeV2'

export const useVersionReportVersionRepository =
  (): VersionReportVersionRepository => {
    const fetchVersions = useCallback(async (templateUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/version-report/version/list',
        { templateUuid }
      )
      return response.json
    }, [])
    const fetch = useCallback(async (uuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/version-report/version',
        { uuid }
      )
      return {
        uuid: response.json.uuid,
        snapshotUuid: response.json.snapshotUuid,
        templateName: response.json.templateName,
        name: response.json.name,
        pages: response.json.pages.map(page => ({
          uuid: page.uuid,
          pageTitle: page.pageTitle,
          leadSentence: page.leadSentence,
          pageNumber: page.pageNumber,
          pageType: page.pageType,
          config: { ...JSON.parse(page.configJsonString), type: page.pageType },
          content: {
            ...initContent(page.pageType),
            ...JSON.parse(page.contentJsonString || '{}'),
            type: page.pageType,
          },
        })),
        createdAt: dateTimeV2Service.newFromString(response.json.createdAt),
        createdBy: response.json.createdBy || {
          uuid: '',
          name: '',
        },
        updatedAt: dateTimeV2Service.newFromString(response.json.updatedAt),
        updatedBy: response.json.updatedBy || {
          uuid: '',
          name: '',
        },
      }
    }, [])
    const createVersion = useCallback(
      async (templateUuid: string, name: string) => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/version-report/version',
          { templateUuid, name }
        )
        return response.json
      },
      []
    )
    const updatePages = useCallback(
      async (entity: VersionReportVersionEntity) => {
        const request = {
          uuid: entity.uuid,
          pages: entity.pages.map(page => ({
            uuid: page.uuid,
            pageTitle: page.pageTitle,
            leadSentence: page.leadSentence,
            configJsonString: JSON.stringify(page.config),
            contentJsonString: JSON.stringify(page.content),
          })),
        }
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/version-report/version/pages',
          request
        )
      },
      []
    )
    const deleteVersion = useCallback(async (uuid: string) => {
      const response = await api.functional.request(
        'DELETE',
        '/api/v1/projects/version-report/version',
        { uuid }
      )
    }, [])
    const evaluateOverview = useCallback(
      async (request: EvaluateOverviewRequest) => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/version-report/evaluate/overview',
          request
        )
        return response.json
      },
      []
    )
    const evaluateProgressByProcess = useCallback(
      async (request: EvaluateProgressReportByProcessRequest) => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/version-report/evaluate/progress-by-process',
          request
        )
        return response.json
      },
      []
    )
    const evaluateProgressByTeam = useCallback(
      async (request: EvaluateProgressReportByTeamRequest) => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/version-report/evaluate/progress-by-team',
          request
        )
        return response.json
      },
      []
    )
    return {
      fetchVersions,
      fetch,
      evaluateOverview,
      evaluateProgressByProcess,
      evaluateProgressByTeam,
      createVersion,
      updatePages,
      deleteVersion,
    }
  }
