import { useEffect, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { RootDiv } from '../../../../containers/commons/AgGrid/components/cell/CellRendererRoot'
import './styles.scss'

interface Props extends ICellRendererParams {
  tooltip?: (params: ICellRendererParams) => string | undefined
  hideIcon?: (params: ICellRendererParams) => boolean
}

export const ResourcePlanTeamCellRenderer = (props: Props) => {
  const { value, valueFormatted, tooltip, hideIcon } = props
  const [tooltipText, setTooltipText] = useState<string | undefined>()

  useEffect(() => {
    tooltip && setTooltipText(tooltip?.(props))
  }, [props, tooltip])

  const iconUrl = hideIcon && hideIcon(props) ? undefined : value?.iconUrl
  return (
    <Tooltip message={tooltipText}>
      <RootDiv>
        <div className="resource_plan_project_cell_div resource_plan_project_value_cell">
          {iconUrl && (
            <img src={iconUrl} className="resource_plan_project_icon" />
          )}
          {valueFormatted ||
            (typeof value === 'string'
              ? value
              : value?.displayName || value?.name) ||
            ''}
        </div>
      </RootDiv>
    </Tooltip>
  )
}
