import { intl } from '../../../../../i18n'
import {
  ToggleButton,
  ToggleButtonGroup,
  useOnChangeToggleButtonGroup,
} from '../../../../components/inputs/ToggleButtonGroup'
import { ViewType } from '../../model/viewConfig'

type ViewTypeToggleButtonGroupProps = {
  value: ViewType
  onChange: (_: ViewType) => void
}

export const ViewTypeToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: ViewTypeToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={'GRAPH'}>
        {intl.formatMessage({ id: 'progressReport.viewConfig.viewType.graph' })}
      </ToggleButton>
      <ToggleButton value={'TABLE'}>
        {intl.formatMessage({ id: 'progressReport.viewConfig.viewType.table' })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
