import { intl } from '../../../i18n'
import { WbsItemType } from '../../entity/WbsItemEntity'

export class WbsItemTypeObject {
  uuid: string
  code: string
  name: string
  rootType: WbsItemType
  iconUrl: string
  child?: WbsItemTypeObject
  baseUuid?: string
  baseTicketUuid?: string
}

export class WbsItemTypeVO {
  uuid: string
  code: string
  name: string
  rootType: WbsItemType
  iconUrl: string
  child?: WbsItemTypeVO
  baseUuid?: string
  baseTicketUuid?: string

  constructor(obj: WbsItemTypeObject) {
    this.uuid = obj.uuid
    this.code = obj.code
    this.name = obj.name
    this.rootType = obj.rootType
    this.iconUrl = obj.iconUrl
    this.child = obj.child ? new WbsItemTypeVO(obj.child) : undefined
    this.baseUuid = obj.baseUuid
    this.baseTicketUuid = obj.baseTicketUuid
  }

  isWorkgroup(): boolean {
    return this.rootType === WbsItemType.WORKGROUP
  }
  isProcess(): boolean {
    return this.rootType === WbsItemType.PROCESS
  }
  isDeliverableList(): boolean {
    return this.rootType === WbsItemType.DELIVERABLE_LIST
  }
  isDeliverable(): boolean {
    return this.rootType === WbsItemType.DELIVERABLE
  }
  isTask(): boolean {
    return this.rootType === WbsItemType.TASK
  }
  isTicket(): boolean {
    return !!this.baseUuid
  }

  canBeChildOf(parent: WbsItemTypeVO): boolean {
    if (this.isWorkgroup()) {
      return false
    }
    if (this.isProcess()) {
      return parent.isWorkgroup() || parent.isProcess()
    }
    if (this.isDeliverableList()) {
      return (
        parent.isWorkgroup() || parent.isProcess() || parent.isDeliverableList()
      )
    }
    if (this.isDeliverable()) {
      return (
        parent.isWorkgroup() || parent.isProcess() || parent.isDeliverableList()
      )
    }
    if (this.isTask()) {
      if (this.isTicket()) {
        return parent.isDeliverable()
      }
      return (parent.isDeliverable() && !parent.isTicket()) || parent.isTask()
    }
    return false
  }

  toWbsItemTypeObject(): WbsItemTypeObject {
    return {
      uuid: this.uuid,
      code: this.code,
      name: this.name,
      rootType: this.rootType,
      iconUrl: this.iconUrl,
      child: this.child ? this.child.toWbsItemTypeObject() : undefined,
      baseUuid: this.baseUuid,
      baseTicketUuid: this.baseTicketUuid,
    } as WbsItemTypeObject
  }

  /**
   * No mean to be used by transaction ticket type
   *
   * @returns name with suffix 'List' if type is ticket list, or simply name
   */
  getNameWithSuffix(): string {
    if (this.isTicket() && this.isDeliverable()) {
      return `${this.name}${intl.formatMessage({
        id: 'ticketList.name.suffix',
      })}`
    }
    return this.name
  }

  /**
   * @returns TRUE if this is base wbs type or base ticket type
   */
  isBaseType(): boolean {
    return !this.baseTicketUuid
  }
}
