import { useCallback, useEffect, useMemo, useState } from 'react'
import { PageArea } from '..'
import { MyTaskDetail } from '../../../lib/functions/myTasks'
import BulkSheetComponent, {
  BulkSheet,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import MyTasksOptions, {
  ColumnQuickFilterKey,
  MyTaskRow,
  MyTasksProps,
  MyTasksState,
} from './MyTasksOptions'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ColDef, RowNode } from 'ag-grid-community'
import { UiStateKey } from '../../../lib/commons/uiStates'
import Auth from '../../../lib/commons/auth'
import DateVO from '../../../vo/DateVO'
import { AggregateField } from '../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { Collapse } from '@mui/material'
import MyTasksToolbar from './Toolbar'
import MyTasksHeader from './Header'
import { QuickFilterKeys } from '../ProjectPlan/projectPlanOptions'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

const MyTasks = props => {
  const [bulkSheet, setBulkSheet] =
    useState<BulkSheet<MyTasksProps, MyTaskDetail, MyTaskRow, MyTasksState>>()

  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [rowNodes, setRowNodes] = useState<RowNode[]>([])
  const [quickFilters, setQuickFilters] = useState<QuickFilterKeys[]>([])

  const myTasksOptions = useMemo(() => new MyTasksOptions(), [])

  type MyTasksPageState = {
    toolbar: ToolbarToggleValue | undefined
    aggregateType: AggregateField
    workloadUnit: WorkloadUnit
  }

  const myTasksDefaultPageState = {
    toolbar: ToolbarToggleValue.DISPLAY,
    aggregateType: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.DAY,
  }

  const { toolbar, workloadUnit, aggregateType, updatePageState } =
    usePageState<MyTasksPageState>(
      props.uuid,
      myTasksDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onChangeAggregateType = useCallback((aggregateType: AggregateField) => {
    updatePageState({ aggregateType })
  }, [])

  const onChangeWorkloadUnit = useCallback((workloadUnit: WorkloadUnit) => {
    updatePageState({ workloadUnit })
  }, [])

  const workloadUnitState = useWorkloadUnit(workloadUnit)

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ workloadUnit, workloadUnitState })
    bulkSheet.gridApi?.refreshCells({
      columns: ['wbsItem.estimatedWorkload.task', 'productivity.actualHour'],
    })
  }, [bulkSheet, workloadUnit, workloadUnitState])

  const quickFilterChanged = useCallback(
    (values: string[]) => {
      setQuickFilters(values as QuickFilterKeys[])
      bulkSheet?.setState({ quickFilters: values }, () => {
        if (!values || values.length === 0) {
          bulkSheet.options.filteredRowDataUuids = undefined
          bulkSheet.gridApi!.onFilterChanged()
          return
        }

        const tenant = Auth.getCurrentTenant()
        if (!tenant || !tenant.user) return
        const result: string[] = []
        bulkSheet.gridApi!.forEachNode(node => {
          const isTarget = values
            .map(quickFilterKey => isFiltered(quickFilterKey, node))
            .reduce((prev, curr) => prev && curr)
          if (isTarget) {
            result.push(node.data.wbsItem.uuid)
          }
        })
        bulkSheet.options.filteredRowDataUuids = result
        bulkSheet.gridApi!.onFilterChanged()
      })
    },
    [bulkSheet]
  )

  const isFiltered = useCallback(
    (quickFilterKey: string, node: RowNode): boolean => {
      const tenant = Auth.getCurrentTenant()!
      switch (quickFilterKey) {
        case QuickFilterKeys.ACCOUNTABLE:
          return (
            node.data.wbsItem.accountable &&
            node.data.wbsItem.accountable.name === tenant.user!.name
          )
        case QuickFilterKeys.RESPONSIBLE_OR_ASSIGNEE:
          const isResponsible =
            node.data.wbsItem.responsible &&
            node.data.wbsItem.responsible.name === tenant.user!.name
          const isAssignee =
            node.data.wbsItem.assignee &&
            node.data.wbsItem.assignee.name === tenant.user!.name
          return isResponsible || isAssignee
        case QuickFilterKeys.DELAY:
          return (
            node.data.wbsItem.scheduledDate.endDate &&
            new DateVO(node.data.wbsItem.scheduledDate.endDate).isBefore(
              DateVO.now()
            )
          )
        case QuickFilterKeys.COMMENT:
          return node.data.commentSummary && node.data.commentSummary.hasComment
        default:
          return false
      }
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.columnApi?.resetColumnState()
        bulkSheet.gridApi?.setFilterModel(null)
        bulkSheet.gridApi?.onFilterChanged()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  return (
    <PageArea>
      <MyTasksHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onCancel={() => bulkSheet?.onCancel()}
        onChange={quickFilter => quickFilterChanged(quickFilter)}
        quickFilter={quickFilters}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <MyTasksToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
          onChangeWorkloadUnit={onChangeWorkloadUnit}
          workloadUnit={workloadUnit}
          aggregateType={aggregateType}
          onChangeAggregateType={onChangeAggregateType}
          rowNodes={rowNodes}
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={myTasksOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
        specificProps={{
          ...props.specificProps,
          updateAggregationRowNodes: (nodes: RowNode[]) => {
            setRowNodes(nodes)
          },
        }}
      />
    </PageArea>
  )
}

export default pageComponent(MyTasks)
