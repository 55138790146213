import { useEffect, useMemo, useState } from 'react'
import { ProjectPlanSelectRow } from '../model'
import { useProjectPlanSnapshotRepository } from '../../../../../../../services/adaptors/projectPlanSnapshotRepositoryAdaptor'

export const useProjectPlanSelectData = (
  snapshotUuid: string,
  selectedUuids: string[]
) => {
  const [partialRows, setPartialRows] = useState<
    Omit<ProjectPlanSelectRow, 'selected'>[]
  >([])
  const { fetchMasterSchedule } = useProjectPlanSnapshotRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await fetchMasterSchedule(snapshotUuid)
      setPartialRows(
        response.map(resp => ({
          uuid: resp.uuid,
          treeValue: resp.path,
          displayName: resp.wbsItem.displayName,
          type: resp.type,
        }))
      )
    }
    fn()
  }, [fetchMasterSchedule, snapshotUuid])
  const rows = useMemo(
    () =>
      partialRows.map(r => {
        const selected = selectedUuids.includes(r.uuid)
        return {
          ...r,
          selected,
        } as ProjectPlanSelectRow
      }),
    [partialRows, selectedUuids]
  )
  return { rows }
}
