import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import User, {
  ConnectedService,
  RegistrationStatus,
  UserBatchDeltaInput,
  UserDetail,
  UserInput,
  UserUpdateDeltaProps,
  isEmailChangable,
} from '../../../lib/functions/user'
import { generateUuid } from '../../../utils/uuids'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APIResponse } from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import { ColumnType } from '../../containers/commons/AgGrid'
import { GetContextMenuItemsParams } from 'ag-grid-community'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import { APPLICATION_FUNCTION_EXTERNAL_ID, getPathByExternalId } from '..'
import ViewMeta from '../../containers/meta/ViewMeta'
import { DivisionProps } from '../../../lib/functions/division'
import { PositionProps } from '../../../lib/functions/position'
import { RoleBasic } from '../../../lib/functions/role'
import { intl } from '../../../i18n'
import store from '../../../store'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import { ExternalServiceCode } from '../../../lib/functions/idProvider'
import SearchWindow from '../../components/toolbars/ContainerToolBar/SearchWindow'
import ResetUserPasswordDialog from './ResetUserPasswordDialog'
import AlertDialog from '../../components/dialogs/AlertDialog'
import DateVO from '../../../vo/DateVO'
import { AttemptChangeUserEmailDialog } from '../../components/dialogs/AttemptChangeUserEmailDialog'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface UserState extends BulkSheetState {
  searchText?: string
  openDelete: boolean
  openResetPassword: boolean
  openResendRegistrationMail: boolean
  target?: UserRow // Target for context menu action
}

export class UserRow extends RowData {
  code?: string
  name?: string
  nameKana?: string
  nameAlphabet?: string
  iconUrl?: string
  email?: string
  phoneNumber?: string
  birthday?: string
  timezoneId?: string
  division?: DivisionProps
  position?: PositionProps
  role?: RoleBasic
  hireDate?: string
  leaveDate?: string
  validFrom?: string
  validTo?: string
  emailNotification?: boolean
  registrationStatus?: RegistrationStatus
  lastLoginAt?: string
  partnerMember?: boolean
  connectedWithCognito?: boolean
}

interface UserBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    UserDetail,
    UserRow,
    UserState
  > {}

class UserRowDataSpec extends RowDataSpec<UserDetail, UserRow> {
  createNewRow(): UserRow {
    const row = new UserRow(generateUuid())
    row.timezoneId = 'Asia/Tokyo'
    row.validFrom = DateVO.now().getStartOfDay().format()
    return row
  }
  overwriteRowItemsWithParents(params: {
    child: UserRow
    parent: UserRow
  }): UserRow {
    return params.child
  }

  createRowByResponse(response: UserDetail): UserRow {
    return {
      ...response,
      division: response.division,
      position: response.position,
      role: response.role,
      timezoneId: response.timezoneId,
      hireDate: response.hireDate ? new DateVO(response.hireDate).format() : '',
      leaveDate: response.leaveDate
        ? new DateVO(response.leaveDate).format()
        : '',
      validFrom: formatDateTime(response.validFrom) || '',
      validTo: formatDateTime(response.validTo),
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }

  duplicateRow(original: UserRow): UserRow {
    return {
      ...original,
      code: undefined,
    }
  }
}

export default class UserOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  UserDetail,
  UserRow,
  UserState
> {
  fetchDataOnInit = true
  addable = true
  draggable = false
  enableExcelExport = true
  enableExcelImport = true
  columnAndFilterStateKey = UiStateKey.UserColumnAndFilterState
  rowDataSpec = new UserRowDataSpec()
  uniqueColumn = 'users.code'
  pinnedColumns = ['users.code', 'users.name', 'users.registrationStatus']
  lockedColumns = ['users.code']
  customColumnTypes = {
    'users.name': [ColumnType.iconWithNameColumn],
  }

  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: UserBulkSheetContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data) return
    ctx.setState({ target: undefined })
    const target = params.node.data

    const closeSubDialog = () => ctx.setState({ children: undefined })
    const getResetUserPasswordDialogState = (target: UserRow) => ({
      children: (
        <ResetUserPasswordDialog
          open={true}
          target={target ? `${target.name}(${target.email})` : ''}
          onSubmit={value => this.resetUserPassword(ctx, value)}
          onClose={closeSubDialog}
        />
      ),
      target,
    })
    const getResendRegistrationEmailDialog = (target: UserRow) => ({
      children: (
        <AlertDialog
          isOpen={true}
          message={intl.formatMessage(
            { id: 'user.resendRegistrationEmail.confirmation' },
            { target: target ? `${target.name}(${target.email})` : undefined }
          )}
          submitHandler={() => this.resendRegistrationEmail(ctx)}
          closeHandler={closeSubDialog}
        />
      ),
      target,
    })
    const getDeleteUserDialog = (target: UserRow) => ({
      children: (
        <AlertDialog
          isOpen={true}
          message={intl.formatMessage(
            { id: 'user.delete.confirmation' },
            { target: target ? `${target.name}(${target.email})` : undefined }
          )}
          submitHandler={() => this.deleteUser(ctx)}
          closeHandler={closeSubDialog}
        />
      ),
      target,
    })
    const getAttemptChangeUserEmailDialog = (target: UserRow) => ({
      children: (
        <AttemptChangeUserEmailDialog
          uuid={target.uuid}
          onClose={closeSubDialog}
        />
      ),
      target,
    })

    return new ContextMenu(
      [
        // Add user
        ctx.generateAddContextMenuGroup(params),
        {
          id: ContextMenuGroupId.CUSTOM,
          items: [
            // Edit
            {
              name: intl.formatMessage({ id: 'edit' }),
              action: () => {
                const path = getPathByExternalId(
                  APPLICATION_FUNCTION_EXTERNAL_ID.USER_REGISTRATION
                )
                window.open(
                  `${window.location.origin}${path}/${params.node!.data.code}`,
                  ''
                )
              },
              icon: getMenuIconHtml(ContextMenuItemId.EDIT),
            },
            // Change email
            isEmailChangable(params.node.data.registrationStatus) && {
              name: intl.formatMessage({ id: 'user.attemptChangeEmail' }),
              action: () => {
                ctx.setState(getAttemptChangeUserEmailDialog(target))
              },
              // TODO: Consider icon.
              // icon: getMenuIconHtml(ContextMenuItemId.EDIT),
            },
            // Resend registration email
            [
              RegistrationStatus.PROVISIONAL,
              RegistrationStatus.PROVISIONAL_EXPIRED,
            ].includes(params.node.data.registrationStatus)
              ? {
                  name: intl.formatMessage({
                    id: 'user.resendRegistrationEmail',
                  }),
                  action: () =>
                    ctx.setState(getResendRegistrationEmailDialog(target)),
                  icon: getMenuIconHtml(
                    ContextMenuItemId.RESEND_REGISTRATION_EMAIL
                  ),
                }
              : undefined,
            // Reset password
            ![
              RegistrationStatus.PROVISIONAL,
              RegistrationStatus.PROVISIONAL_EXPIRED,
            ].includes(params.node.data.registrationStatus) &&
            params.node.data.connectedWithCognito
              ? {
                  name: intl.formatMessage({
                    id:
                      params.node.data.registrationStatus ===
                      RegistrationStatus.TEMPORARY_PASSWORD_EXPIRED
                        ? 'user.reset.temporaryPassword'
                        : 'user.resetPassword',
                  }),
                  action: () =>
                    ctx.setState(getResetUserPasswordDialogState(target)),
                  icon: getMenuIconHtml(
                    params.node.data.registrationStatus ===
                      RegistrationStatus.TEMPORARY_PASSWORD_EXPIRED
                      ? ContextMenuItemId.TEMPORARY_PASSWORD
                      : ContextMenuItemId.RESET_PASSWORD
                  ),
                }
              : undefined,
            // Delete user
            !target.lastLoginAt
              ? {
                  name: intl.formatMessage({ id: 'user.delete' }),
                  action: () => ctx.setState(getDeleteUserDialog(target)),
                  icon: getMenuIconHtml(ContextMenuItemId.REMOVE_USER),
                }
              : undefined,
          ].filter(v => !!v),
        },
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }

  resetUserPassword = async (ctx, temporaryPassword: string) => {
    await User.reset({
      uuid: ctx.state.target.uuid,
      temporaryPassword,
    })
    store.dispatch(
      addScreenMessage(APPLICATION_FUNCTION_EXTERNAL_ID.USERS_SEARCH, {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'user.resetPassword.completed' }),
      })
    )
    ctx.setState({
      target: undefined,
      children: undefined,
    })
  }

  resendRegistrationEmail = async ctx => {
    await User.resendRegistrationUrl({
      uuid: ctx.state.target.uuid,
      lockVersion: ctx.state.target.lockVersion,
    })
    store.dispatch(
      addScreenMessage(APPLICATION_FUNCTION_EXTERNAL_ID.USERS_SEARCH, {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({
          id: 'user.resendRegistrationEmail.completed',
        }),
      })
    )
    ctx.setState({
      target: undefined,
      children: undefined,
    })
  }

  deleteUser = async ctx => {
    ctx.setState({ openDelete: false })
    await User.delete({
      uuid: ctx.state.target.uuid,
      lockVersion: ctx.state.target.lockVersion,
    })
    store.dispatch(
      addScreenMessage(APPLICATION_FUNCTION_EXTERNAL_ID.USERS_SEARCH, {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({
          id: 'user.delete.completed',
        }),
      })
    )
    ctx.setState({
      target: undefined,
      children: undefined,
    })
    ctx.refreshDataWithLoading()
  }

  getRowStyle = (params): any => {
    if (
      params.data &&
      params.data.validTo &&
      new DateVO(params.data.validTo).isBefore(DateVO.now().getStartOfDay())
    ) {
      return { backgroundColor: '#e0e0e0' }
    }
  }
  updateDefaultState = async (s: UserState) => {
    return {
      ...s,
      openDelete: false,
      openReset: false,
      openResendRegistrationMail: false,
    }
  }

  onSubmit = async (
    ctx: UserBulkSheetContext,
    data: {
      added: UserRow[]
      edited: {
        before: UserRow
        after: UserRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: UserBatchDeltaInput = {
      added: data.added.map(v => this.createRequestByRow(v, viewMeta)),
      edited: data.edited.map(v => this.createDeltaRequestByRow(v, viewMeta)),
    }
    return User.updateBatchDelta(input)
  }
  async getAll(state: UserState): Promise<APIResponse> {
    return User.getUsers({
      all: state.searchText,
      mustMatch: true,
      validOnly: false,
      sortField: 'updatedAt',
      sortOrder: 'desc',
    })
  }

  getCellRendererParams = (field: string) => {
    if (field === 'name') {
      return {
        labelField: 'name',
        iconUrlField: 'iconUrl',
      }
    }
  }

  getSearchCondition = (state: UserState) => {
    return { searchText: state.searchText }
  }
  restoreSearchCondition = (
    searchCondition: { searchText: string },
    ctx: UserBulkSheetContext
  ) => {
    ctx.setState({ searchText: searchCondition.searchText }, () =>
      ctx.refreshDataWithLoading()
    )
  }
  getOpenDetailSpec = async (row: UserRow): Promise<OpenDetailSpec> => {
    return {
      openInDialog: false,
      layer: {
        externalId: APPLICATION_FUNCTION_EXTERNAL_ID.USER_REGISTRATION,
        code: row.code!,
      },
    }
  }
  customColumnWidth = (field: string): number | undefined => {
    if (['name', 'email'].includes(field)) {
      return 200
    }
    if (
      [
        'nameKana',
        'nameAlphabet',
        'phoneNumber',
        'birthday',
        'hireDate',
        'leaveDate',
        'validFrom',
        'validTo',
      ].includes(field)
    ) {
      return 150
    }
    return undefined
  }

  private createRequestByRow = (
    row: UserRow,
    viewMeta: ViewMeta
  ): UserInput => {
    return {
      ...row,
      validFrom: viewMeta.serializeInputForApi(
        row.validFrom,
        viewMeta.functionMeta.properties.byId.get('users.validFrom')!
      ),
      validTo: viewMeta.serializeInputForApi(
        row.validTo,
        viewMeta.functionMeta.properties.byId.get('users.validTo')!
      ),
      hireDate: viewMeta.serializeInputForApi(
        row.hireDate,
        viewMeta.functionMeta.properties.byId.get('users.hireDate')!
      ),
      leaveDate: viewMeta.serializeInputForApi(
        row.leaveDate,
        viewMeta.functionMeta.properties.byId.get('users.leaveDate')!
      ),
      birthday: viewMeta.serializeInputForApi(
        row.birthday,
        viewMeta.functionMeta.properties.byId.get('users.birthday')!
      ),
      divisionUuid: row.division?.uuid,
      positionUuid: row.position?.uuid,
      roleUuid: row.role?.uuid,
      emailNotification: row.emailNotification ?? false,
      partnerMember: row.partnerMember ?? false,
    }
  }

  private createDeltaRequestByRow = (
    {
      before,
      after,
    }: {
      before: UserRow
      after: UserRow
    },
    viewMeta: ViewMeta
  ): UserUpdateDeltaProps => {
    return {
      uuid: after.uuid,
      code:
        before.code !== after.code
          ? {
              oldValue: before.code,
              newValue: after.code,
            }
          : undefined,
      email:
        before.email !== after.email
          ? {
              oldValue: before.email,
              newValue: after.email,
            }
          : undefined,
      name:
        before.name !== after.name
          ? {
              oldValue: before.name,
              newValue: after.name,
            }
          : undefined,
      nameAlphabet:
        before.nameAlphabet !== after.nameAlphabet
          ? {
              oldValue: before.nameAlphabet,
              newValue: after.nameAlphabet,
            }
          : undefined,
      nameKana:
        before.nameKana !== after.nameKana
          ? {
              oldValue: before.nameKana,
              newValue: after.nameKana,
            }
          : undefined,
      iconUrl:
        before.iconUrl !== after.iconUrl
          ? {
              oldValue: before.iconUrl,
              newValue: after.iconUrl,
            }
          : undefined,
      phoneNumber:
        before.phoneNumber !== after.phoneNumber
          ? {
              oldValue: before.phoneNumber,
              newValue: after.phoneNumber,
            }
          : undefined,
      birthday:
        before.birthday !== after.birthday
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.birthday,
                viewMeta.functionMeta.properties.byId.get('users.birthday')!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.birthday,
                viewMeta.functionMeta.properties.byId.get('users.birthday')!
              ),
            }
          : undefined,
      hireDate:
        before.hireDate !== after.hireDate
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.hireDate,
                viewMeta.functionMeta.properties.byId.get('users.hireDate')!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.hireDate,
                viewMeta.functionMeta.properties.byId.get('users.hireDate')!
              ),
            }
          : undefined,
      leaveDate:
        before.leaveDate !== after.leaveDate
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.leaveDate,
                viewMeta.functionMeta.properties.byId.get('users.leaveDate')!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.leaveDate,
                viewMeta.functionMeta.properties.byId.get('users.leaveDate')!
              ),
            }
          : undefined,
      validFrom:
        before.validFrom !== after.validFrom
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.validFrom,
                viewMeta.functionMeta.properties.byId.get('users.validFrom')!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.validFrom,
                viewMeta.functionMeta.properties.byId.get('users.validFrom')!
              ),
            }
          : undefined,
      validTo:
        before.validTo !== after.validTo
          ? {
              oldValue: viewMeta.serializeInputForApi(
                before.validTo,
                viewMeta.functionMeta.properties.byId.get('users.validTo')!
              ),
              newValue: viewMeta.serializeInputForApi(
                after.validTo,
                viewMeta.functionMeta.properties.byId.get('users.validTo')!
              ),
            }
          : undefined,
      emailNotification:
        before.emailNotification !== after.emailNotification
          ? {
              oldValue: !!before.emailNotification,
              newValue: !!after.emailNotification,
            }
          : undefined,
      divisionUuid:
        before.division?.uuid !== after.division?.uuid
          ? {
              oldValue: before.division?.uuid,
              newValue: after.division?.uuid,
            }
          : undefined,
      positionUuid:
        before.position?.uuid !== after.position?.uuid
          ? {
              oldValue: before.position?.uuid,
              newValue: after.position?.uuid,
            }
          : undefined,
      roleUuid:
        before.role?.uuid !== after.role?.uuid
          ? {
              oldValue: before.role?.uuid,
              newValue: after.role?.uuid,
            }
          : undefined,
      timezoneId:
        before.timezoneId !== after.timezoneId
          ? {
              oldValue: before.timezoneId,
              newValue: after.timezoneId,
            }
          : undefined,
      partnerMember:
        before.partnerMember !== after.partnerMember
          ? {
              oldValue: !!before.partnerMember,
              newValue: !!after.partnerMember,
            }
          : undefined,
    }
  }

  uniqueColumnIds = ['code', 'email']
}
