import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterResetButton } from '../common'
import { useCommentTextFilter } from './useCommentTextFilter'
import { Box, styled, TextField } from '@mui/material'
import { useCommentCreatedAtFilter } from './useCommentCreatedAtFilter'
import DateInput from '../../../../../components/editors/input/DateInput'
import { CommentOperatorSelect } from './CommentOperatorSelect'
import { intl } from '../../../../../../i18n'
import { dateVoService } from '../../../../../../domain/value-object/DateVO'
import { AllState } from '../../../../../../store'
import { useSelector } from 'react-redux'
import usePrevious from '../../../../../hooks/usePrevious'

export type CommentTextFilter = {
  operator: CommentTextFilterOperator
  value: string
}

export type CommentCreatedAtFilter = {
  operator: CommentCreatedAtFilterOperator
  value: string
}

export enum CommentTextFilterOperator {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
}

export enum CommentCreatedAtFilterOperator {
  EQUALS = 'EQUALS',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
}

type Props = IFilterParams & {
  fetch: (
    t?: CommentTextFilter,
    d?: CommentCreatedAtFilter
  ) => Promise<APIResponse>
}

export const ServerSideCommentFilter = forwardRef(
  ({ fetch, filterChangedCallback, context }: Props, ref) => {
    const hasRequiredSaveData = useSelector<AllState>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

    // State
    const textFilter = useCommentTextFilter()
    const dateFilter = useCommentCreatedAtFilter()
    const [loading, isLoading] = useState(false)
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
      undefined
    )

    const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
    useEffect(() => {
      if (addedRowIds.current) return
      addedRowIds.current = []
    }, [])
    const fetchFilteredIds = async (t, d) => {
      const response = await fetch(t, d)
      setFilteredIds(response.json)
    }
    const search = useCallback(
      _.debounce(async (t, d) => {
        try {
          isLoading(true)
          await fetchFilteredIds(t, d)
        } finally {
          isLoading(false)
        }
      }, 300),
      []
    )

    // Ag-grid custom filter
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params: IDoesFilterPassParams) {
          // Don't filter new rows
          if (params.data.added) {
            if (addedRowIds.current && params.node.id) {
              addedRowIds.current.push(params.node.id)
            }
            return true
          }
          if (
            addedRowIds.current &&
            params.node.id &&
            addedRowIds.current.includes(params.node.id)
          ) {
            return true
          }
          if (!filteredIds) return true
          return params.node.id && filteredIds.includes(params.node.id)
        },

        isFilterActive() {
          return textFilter.isActive() || dateFilter.isActive
        },

        getModel() {
          if (!textFilter.model() && !dateFilter.model()) return undefined
          return {
            commentText: textFilter.model(),
            commentCreatedAt: dateFilter.model(),
          }
        },

        setModel(model?: {
          commentText: CommentTextFilter
          commentCreatedAt: CommentCreatedAtFilter
        }) {
          if (!model) {
            textFilter.reset()
            dateFilter.reset()
            return
          }
          if (model.commentText) {
            textFilter.setOperator(model.commentText.operator)
            textFilter.setValue(model.commentText.value)
          }
          if (model.commentCreatedAt) {
            dateFilter.setOperator(model.commentCreatedAt.operator)
            dateFilter.setValue(
              dateVoService.construct(model.commentCreatedAt.value)
            )
          }
        },

        getModelAsString() {
          return [dateFilter.getModelAsString(), textFilter.value]
            .filter(v => !!v)
            .join(',')
        },

        onNewRowsLoaded() {
          if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
          const textFilterModel = textFilter.model()
          const dateFilterModel = dateFilter.model()
          if (textFilterModel || dateFilterModel) {
            fetchFilteredIds(textFilterModel, dateFilterModel)
          }
        },
      }
    })

    // Filter
    useEffect(() => {
      if (!textFilter.isActive() && !dateFilter.isActive()) {
        setFilteredIds(undefined)
        return
      }
      search(textFilter.model(), dateFilter.model())
    }, [
      textFilter.operator,
      textFilter.value,
      dateFilter.operator,
      dateFilter.value,
    ])

    useEffect(() => {
      const isAfterSavedOrRefreshed =
        prevHasRequiredSaveData && !hasRequiredSaveData
      if (
        isAfterSavedOrRefreshed &&
        (textFilter.isActive() || dateFilter.isActive())
      ) {
        fetchFilteredIds(textFilter.model(), dateFilter.model()).then(() => {
          addedRowIds.current = []
        })
      }
    }, [hasRequiredSaveData])

    useEffect(() => {
      filterChangedCallback()
    }, [filteredIds])

    return (
      <BulkSheetFilter>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <FilterRow>
            <CommentOperatorSelect
              id={'comment-date-filter-operator'}
              value={dateFilter.operator}
              options={Object.values(CommentCreatedAtFilterOperator)}
              onChange={v => dateFilter.setOperator(v)}
              getOptionLabel={v => {
                const msg = postfix =>
                  intl.formatMessage({
                    id: `bulksheet.filter.dateFilter.operator.${postfix}`,
                  })
                switch (v) {
                  case CommentCreatedAtFilterOperator.EQUALS:
                    return msg('equal')
                  case CommentCreatedAtFilterOperator.AFTER:
                    return msg('greaterThanEqual')
                  case CommentCreatedAtFilterOperator.BEFORE:
                    return msg('lessThanEqual')
                  default:
                    return ''
                }
              }}
            />
            <Box sx={{ width: '120px', alignItems: 'center' }}>
              <DateInput
                value={dateFilter.value}
                onChange={v => dateFilter.setValue(v)}
              />
            </Box>
            <FilterResetButton onClick={() => dateFilter.reset()} />
          </FilterRow>
          <FilterRow>
            <CommentOperatorSelect
              id={'comment-text-filter-operator'}
              value={textFilter.operator}
              options={Object.values(CommentTextFilterOperator)}
              onChange={v => textFilter.setOperator(v)}
              getOptionLabel={v => {
                const msg = postfix =>
                  intl.formatMessage({
                    id: `bulksheet.filter.textFilter.operator.${postfix}`,
                  })
                switch (v) {
                  case CommentTextFilterOperator.CONTAINS:
                    return msg('contain')
                  case CommentTextFilterOperator.NOT_CONTAINS:
                    return msg('notContain')
                  default:
                    return ''
                }
              }}
            />
            <TextField
              variant="outlined"
              size="small"
              value={textFilter.value}
              onChange={e => textFilter.setValue(e.target.value)}
              sx={{
                width: '120px',
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    padding: '3px',
                  },
                },
              }}
            />
            <FilterResetButton onClick={() => textFilter.reset()} />
          </FilterRow>
        </Box>
      </BulkSheetFilter>
    )
  }
)

const FilterRow = styled(Box)({
  width: '100%',
  padding: '3px 0',
  display: 'flex',
})
