import React from 'react'
import { Editor } from '@tiptap/react'
import { StyleType } from '../../../../../utils/markdown'
import TableStyleButtonBase from '../../common/TableStyleButtonBase'
import RichTextStyleButton from './RichTextStyleButton'
import { StyleButtonBaseProps } from '../../common/StyleButtonBase'
import { UploadedFile } from '../../../../../utils/file'
import FileSelectStyleButtonBase from '../../common/FileSelectStyleButtonBase'

interface OwnProps {
  editor: Editor | undefined
}

type Props = OwnProps & StyleButtonBaseProps

export const HeaderOneButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_ONE} />
)

export const HeaderTwoButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_TWO} />
)

export const HeaderThreeButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_THREE} />
)

export const HeaderFourButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_FOUR} />
)

export const HeaderFiveButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_FIVE} />
)

export const HeaderSixButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HEADER_SIX} />
)

export const BoldButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.BOLD} />
)

export const ItalicButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.ITALIC} />
)

export const StrikeButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.STRIKETHROUGH} />
)

export const CodeButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.CODE} />
)

export const CodeBlockButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.CODE_BLOCK} />
)

export const BlockquoteButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.BLOCKQUOTE} />
)

export const UnorderedListButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.UNORDERED_LIST_ITEM} />
)

export const OrderedListButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.ORDERED_LIST_ITEM} />
)

export const HorizontalRuleButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.HORIZONTAL_RULE} />
)

export const TaskListButton = (props: Props) => (
  <RichTextStyleButton {...props} styleType={StyleType.TASK_LIST} />
)

export const TableButton = (props: Props) => {
  const { editor } = props
  const setTable = React.useCallback(
    (rows: number, columns: number) => {
      if (!editor) return
      editor
        .chain()
        .focus()
        .insertTable({ rows: rows + 1, cols: columns, withHeaderRow: true })
        .run()
    },
    [editor]
  )
  const deleteTable = React.useCallback(() => {
    if (!editor) return
    editor.chain().focus().deleteTable().run()
  }, [editor])
  return (
    <TableStyleButtonBase
      {...props}
      setTableValue={setTable}
      deleteTable={deleteTable}
    />
  )
}

export const FileButton = (props: Props) => {
  const { editor } = props
  const onChangeInput = React.useCallback(
    (files: UploadedFile[]) => {
      if (!editor) return
      files.forEach(file => {
        editor.chain().focus().setImage({ src: file.url }).setHardBreak().run()
      })
    },
    [editor]
  )
  return (
    <FileSelectStyleButtonBase
      {...props}
      multiple={true}
      onChange={onChangeInput}
    />
  )
}
