import React from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Popper,
  TextField,
  styled,
} from '@mui/material'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import { connect } from 'react-redux'
import { AllState } from '../../../../../../store'
import { SlackWorkspace } from '../../../../../../domain/value-object/Slack/WorkspaceVO'
import { useSlackExternalServiceStorageService } from '../../../../../../services/storage-services/external-services/slackExternalServiceStorageService'
import { ValidationError } from '../../../../meta/validator'
import { intl } from '../../../../../../i18n'
import SlackUserVO from '../../../../../../vo/Slack/UserVO'
import {
  SlackUser,
  extractSlackWorkspace,
  fromSlackWorkspace,
} from '../../../../../../domain/value-object/Slack/UserVO'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
})

const Devider = styled('span')({
  backgroundColor: '#CCCCCC',
  width: '1px',
  margin: '0 3px',
})

interface SlackMentionUserCellState extends CellStateBase {
  workspaceOptions: SlackWorkspace[]
  userOptions: SlackUser[]
  selected: SlackUser | undefined
}

class SlackMentionUserCell extends DataCellBase<
  SlackUserVO,
  CellPropsBase<SlackUserVO>,
  SlackMentionUserCellState
> {
  private ref = React.createRef<HTMLInputElement>()
  constructor(props) {
    super(props)
    const values = props.value.getValue()
    this.state = {
      workspaceOptions: [],
      userOptions: [],
      cellUniqueError: undefined,
      selected: values.length > 0 ? values[0] : undefined,
    }
    this.fetchWorkspaceOptions()
    if (values.length > 0) {
      this.fetchUserOptions(values[0].slackWorkspaceId)
    }
  }

  fetchWorkspaceOptions = async () => {
    const { getAuthorizedSlackWorkspace } =
      useSlackExternalServiceStorageService()
    const workspaces = await getAuthorizedSlackWorkspace()
    this.setState({ workspaceOptions: workspaces ?? [] })
  }

  fetchUserOptions = async (workspaceId: string) => {
    if (!workspaceId) return
    const { getUsersFromSlackApi } = useSlackExternalServiceStorageService()
    const users = await getUsersFromSlackApi(workspaceId)
    this.setState({ userOptions: users ?? [] })
  }

  validate = (value: SlackUserVO) => {
    return undefined
  }

  onChangeWorkspace = (event: any, value: any) => {
    if (!this.state.selected && !value) return
    if (!value) {
      this.setState({ selected: undefined })
      return
    }
    const selectedPropsValue = this.props.value
      .getValue()
      .find(v => v.slackWorkspaceId === value.slackWorkspaceId)
    const slackUser = fromSlackWorkspace(value)
    slackUser.userUuid = this.props.data.uuid
    this.setState({ selected: selectedPropsValue ?? slackUser })
    value && this.fetchUserOptions(value.slackWorkspaceId)
  }

  onChangeUser = (event: any, value: any) => {
    if (this.state.selected?.slackUserId === value?.slackUserId) return
    const slackUser: SlackUser = value ?? {}
    slackUser.userUuid = this.props.data.uuid
    slackUser.slackCollaborationWorkspaceUuid =
      this.state.selected?.slackCollaborationWorkspaceUuid
    slackUser.slackWorkspaceId = this.state.selected?.slackWorkspaceId
    slackUser.slackWorkspaceName = this.state.selected?.slackWorkspaceName
    slackUser.uuid = this.state.selected?.uuid

    this.setState({ selected: slackUser })
    const changed = this.props.value.createAddedOrReplacedClone(slackUser)
    this.props.onChange(changed)
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    if (prevProps.value !== this.props.value) {
      this.setState({
        selected: this.props.value.getValue()[0],
      })
    }
  }

  render() {
    return (
      <RootDiv>
        <Autocomplete
          size={'small'}
          options={this.state.workspaceOptions}
          getOptionLabel={option => option?.slackWorkspaceName ?? ''}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant={'standard'}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              ref={this.ref}
            />
          )}
          PopperComponent={props => {
            return <Popper {...props} placement="bottom-start" />
          }}
          isOptionEqualToValue={(value, option) => {
            return (
              value &&
              option &&
              value.slackWorkspaceId === option.slackWorkspaceId
            )
          }}
          forcePopupIcon={true}
          autoSelect={false}
          blurOnSelect={false}
          value={
            this.state.selected
              ? extractSlackWorkspace(this.state.selected)
              : null
          }
          onChange={this.onChangeWorkspace}
          disableCloseOnSelect={true}
          sx={{ width: '100%' }}
          clearIcon={null}
        />
        <Devider />
        <Autocomplete
          size={'small'}
          options={this.state.userOptions}
          getOptionLabel={option => option?.slackUserName ?? ''}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant={'standard'}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              ref={this.ref}
            />
          )}
          PopperComponent={props => {
            return <Popper {...props} placement="bottom-start" />
          }}
          isOptionEqualToValue={(value, option) => {
            return value && option && value.slackUserId === option.slackUserId
          }}
          forcePopupIcon={true}
          autoSelect={false}
          blurOnSelect={false}
          value={this.state.selected ? this.state.selected : null}
          onChange={this.onChangeUser}
          disableCloseOnSelect={true}
          sx={{ width: '100%' }}
          disabled={!this.state.selected?.slackWorkspaceId}
        />
      </RootDiv>
    )
  }
}

export default SlackMentionUserCell
