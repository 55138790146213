import { useCallback, useEffect, useState } from 'react'
import { SelectOption } from '../Kanban'
import ProjectMemberAPI, {
  ProjectMemberDetail,
} from '../../../../lib/functions/projectMember'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../lib/functions/customEnumValue'
import { getLabel, parse } from '../../../../lib/commons/i18nLabel'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import { TeamEntity } from '../../../../domain/entity/TeamEntity'

export const useSelectOptions = (
  projectUuid: string,
  rootProjectPlanUuid?: string
): {
  projectMembers: SelectOption[]
  teams: SelectOption[]
  priorities: SelectOption[]
} => {
  const [projectMembers, setProjectMembers] = useState<SelectOption[]>([])
  const [teams, setTeams] = useState<SelectOption[]>([])
  const [priorities, setPriorities] = useState<SelectOption[]>([])

  const { fetchUnderProjectPlan } = useTeamRepository()

  const fetchMemberOptions = useCallback(async projectUuid => {
    const response = await ProjectMemberAPI.getProjectMembers({ projectUuid })
    const projectMemberDetails: ProjectMemberDetail[] = response.json
    if (!projectMemberDetails) return
    setProjectMembers(
      projectMemberDetails
        .map(v => v.user)
        .map(v => ({ ...v, value: v.uuid } as SelectOption))
    )
  }, [])
  const fetchTeamOptions = useCallback(
    async (projectUuid, rootProjectPlanUuid) => {
      const response = await fetchUnderProjectPlan(
        projectUuid,
        rootProjectPlanUuid
      )
      const teamDetails: TeamEntity[] = response
      if (!teamDetails) return
      const teamOptions: SelectOption[] = teamDetails.map(
        team =>
          ({
            value: team.uuid,
            name: team.displayName,
            iconUrl: team.iconUrl,
          } as SelectOption)
      )
      setTeams(teamOptions)
    },
    [rootProjectPlanUuid]
  )
  const fetchPriorityOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuid: projectUuid,
    })
    if (!response.json) return
    const priorityOptions = response.json.map(
      v => ({ ...v, name: getLabel(parse(v.nameI18n)) } as SelectOption)
    )
    setPriorities(priorityOptions)
  }, [])

  const fetchSelectOptions = useCallback(
    async (projectUuid, rootProjectPlanUuid) => {
      await Promise.all([
        fetchMemberOptions(projectUuid),
        fetchTeamOptions(projectUuid, rootProjectPlanUuid),
        fetchPriorityOptions(projectUuid),
      ])
    },
    [fetchMemberOptions, fetchTeamOptions, fetchPriorityOptions]
  )
  useEffect(() => {
    fetchSelectOptions(projectUuid, rootProjectPlanUuid)
  }, [projectUuid, rootProjectPlanUuid])

  return { projectMembers, teams, priorities }
}
