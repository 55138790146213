import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import bannerImage from '../../../assets/flagxs_logo_tugline_color.svg'
import { styled } from '@mui/material'
import {
  ChangeEvent,
  useCallback,
  useState,
  KeyboardEvent,
  useMemo,
} from 'react'
import { LoginInput, LoginButton, LoginContainer } from './components'
import { intl } from '../../../i18n'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'
import { getDomain } from '../../../infrastructure/browser/url'
import { AuthError } from '../../../error/AuthError'
import { ErrorMessage } from './components/AuthErrorMessage'

const LogoDiv = styled('div')({
  width: '300px',
  height: '109.45px',
  padding: '24px 40px',
})

const TenantInputArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
})

const TenantInputText = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  fontSize: '14px',
  fontWeight: '700',
  gap: '4px',
  color: '#243050',
})

const TenantInput = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
  margin: '8px 0 0 0',
  gap: '8px',
})

const TenantUrlSuffix = styled('div')({
  fontsize: '14px',
  textAlign: 'left',
  color: '#7B8CAA',
})

const SubmitButtonArea = styled('div')({
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

export const TenantLogin = () => {
  const { selectTenant } = useAuthenticateUseCase()
  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)

  const [alias, setAlias] = useState<string>('')

  const onChangeInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAlias(event.target.value)
  }, [])

  const onSubmit = useCallback(async () => {
    setAuthError(undefined)
    if (alias === '') {
      setAuthError(new AuthError('NO_TENANT_ALIAS'))
      return
    }

    try {
      const { rootUrl } = await selectTenant(alias)
      window.location.href = `${rootUrl}`
    } catch (e) {
      if (!(e instanceof AuthError)) {
        throw e
      }
      setAuthError(e)
    }
  }, [alias, selectTenant])

  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const domain = useMemo(() => `.${getDomain()}`, [])

  return (
    <LoginContainer>
      <LogoDiv>
        <img src={bannerImage} width={220} height={61.45} />
      </LogoDiv>
      {authError && <ErrorMessage authError={authError} />}
      <TenantInputArea>
        <TenantInputText>
          {intl.formatMessage({ id: 'login.inputTenantUrl' })}
          <HelpOutlineRoundedIcon sx={{ color: '#7B8CAA' }} />
        </TenantInputText>
        <TenantInput>
          <LoginInput onChange={onChangeInput} onKeyDown={onEnter} />
          <TenantUrlSuffix>{domain}</TenantUrlSuffix>
        </TenantInput>
      </TenantInputArea>
      <SubmitButtonArea>
        <LoginButton color={'skyBlue'} onClick={onSubmit}>
          {intl.formatMessage({ id: 'login.next' })}
        </LoginButton>
      </SubmitButtonArea>
    </LoginContainer>
  )
}
