import { styled } from '@mui/material'
import { WbsItemTitle } from './WbsItemTitle'
import { colorPalette } from '../../../../style/colorPallete'

type WbsItemHeaderProps = {
  title: string
  onChangeTitle: (v: string) => void
}
export const WbsItemHeader = ({ title, onChangeTitle }: WbsItemHeaderProps) => {
  return (
    <HeaderRoot>
      <WbsItemTitle value={title} onChange={onChangeTitle} />
    </HeaderRoot>
  )
}
const HeaderRoot = styled('div')({
  display: 'flex',
  width: '100%',
  padding: '4px 12px',
  backgroundColor: colorPalette.monotone[0],
})
