import VOBase from '../base'

export interface MultiEntitySearchItem {
  uuid: string
  code: string
  iconUrl: string
  name: string
}

export default class MultiEntitySearchVO extends VOBase {
  private readonly value: MultiEntitySearchItem[]

  constructor(_value: MultiEntitySearchItem[]) {
    super()
    this.value = _value
  }

  getValue = () => {
    return this.value
  }
  format = () => {
    if (!this.value || !Array.isArray(this.value)) {
      return ''
    }
    return this.value.map(v => v.name).join(',')
  }
  serialize = () => {
    return this.value.map(v => v.uuid)
  }

  isEqual(compareValue: VOBase): boolean {
    if (compareValue instanceof MultiEntitySearchVO) {
      return JSON.stringify(this.value) === JSON.stringify(compareValue.value)
    }
    return false
  }
}
