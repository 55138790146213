import React from 'react'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'
import CheckIcon from '@mui/icons-material/CheckRounded'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import Tooltip from '../../tooltips/Tooltip'
import { SxProps } from '@mui/material'

const Button = styled(BaseButton)<{ notEdited?: boolean }>(({ notEdited }) => ({
  background: notEdited
    ? BackgroundColor.WHITE
    : BackgroundColor.BLUE_GRADATION,
  color: notEdited ? TextColor.BLUE : TextColor.WHITE,
}))

interface OwnProps {
  title?: string
  hideIcon?: boolean
  notEdited?: boolean
  hideTooltip?: boolean
  disabled?: boolean
  onClick?: () => void | Promise<void>
  sx?: SxProps
}
type Props = OwnProps & WrappedComponentProps

const SubmitButton = (props: Props) => {
  const { intl, title, hideIcon, notEdited, hideTooltip, ...other } = props

  const button = (
    <span>
      <Button
        {...other}
        startIcon={
          !hideIcon && <CheckIcon color={notEdited ? 'primary' : 'inherit'} />
        }
        notEdited={notEdited}
      >
        {title || intl.formatMessage({ id: 'dialog.register' })}
      </Button>
    </span>
  )

  return hideTooltip ? (
    button
  ) : (
    <Tooltip
      message={intl.formatMessage({
        id: 'toolTip.submitButton',
      })}
    >
      {button}
    </Tooltip>
  )
}

export default injectIntl(SubmitButton)
