import { useCallback } from 'react'
import { Column, ColumnApi } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import DateVO from '../../../../../vo/DateVO'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../../../components/dialogs/MultiSelectDialog'
import { isWorkMonthColumnDef } from '../../gridOptions/common'

type Props = {
  onClose: () => void
  onSubmit: (colIds: string[]) => void
  columnApi?: ColumnApi | null
}

export const ExcelExportDialog = ({ onClose, onSubmit, columnApi }: Props) => {
  const getSelectionItemDisplayName = useCallback(
    (columnApi: ColumnApi | undefined, column: Column): string => {
      if (
        column.getParent()?.getColGroupDef() &&
        isWorkMonthColumnDef(column.getParent().getColGroupDef()!)
      ) {
        return new DateVO(column.getColId()).format(
          intl.formatMessage({ id: 'dateFormat.yyyy.mm' })
        )
      }
      if (!columnApi) return ''
      return columnApi?.getDisplayNameForColumn(column, null)
    },
    []
  )

  const getSelectionList = useCallback(
    (columnApi?: ColumnApi): MultiSelectDialogSelection[] => {
      return (columnApi?.getColumns() || [])
        .filter((column: Column) => !['drag'].includes(column.getColId()))
        .map((column: Column) => {
          return {
            value: column.getColId(),
            displayName: getSelectionItemDisplayName(columnApi, column),
            defaultChecked:
              column.isVisible() ||
              column.getColDef().cellRendererParams?.uiMeta?.tree,
          }
        }) as MultiSelectDialogSelection[]
    },
    [getSelectionItemDisplayName]
  )

  return (
    <MultiSelectDialog
      onClose={onClose}
      onSubmit={(allList, selectedItem) => {
        const selectedColIds = selectedItem.map(col => col.value)
        onSubmit(selectedColIds)
      }}
      dialogTitle={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.title',
      })}
      submitButtonTitle={intl.formatMessage({
        id: 'dialog.exceloutput.submit',
      })}
      allCheckBoxLabel={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
      })}
      getSelectList={() => getSelectionList(columnApi ?? undefined)}
      hideIcon={true}
    />
  )
}
