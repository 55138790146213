import React from 'react'
import {
  BlueActionIconButton,
  blueActionIconStyle,
} from '../../../../components/icons/IconButton/BlueActionIconButton'
import { intl } from '../../../../../i18n'
import { BackgroundColor, IconColor } from '../../../../../styles/commonStyles'
import {
  ClickAwayListener,
  SpeedDial as MuiSpeedDial,
  SpeedDialAction as MuiSpeedDialAction,
  SpeedDialActionProps,
  styled,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import ViewColumnIcon from '@mui/icons-material/ViewColumnRounded'
import GetAppIcon from '@mui/icons-material/GetAppRounded'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt'
import DateFormatChangePopper, {
  Props as DateFormatChangePopperProps,
} from '../../../../components/poppers/DateFormatChangePopper'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

export interface Props {
  onResetColumnState: () => void
  onClickExport: () => void
  onRestoreColumnState: () => void
  currentFormat?: string
  onClickChangeDateFormat?: (value: string) => void
}

const SettingSpeedDialActions = (props: Props) => {
  const [openSpeedDialog, setOpenSpeedDialog] = React.useState(false)
  const [anchorElForChangeDateFormat, setAnchorElForChangeDateFormat] =
    React.useState<HTMLDivElement>()

  const close = () => {
    setOpenSpeedDialog(false)
    setAnchorElForChangeDateFormat(undefined)
  }

  return (
    <ClickAwayListener onClickAway={() => close()}>
      <SpeedDial
        ariaLabel="toolbar"
        icon={
          <BlueActionIconButton
            title={intl.formatMessage({
              id: 'dialog.setting',
            })}
          >
            <SettingsIcon style={blueActionIconStyle} />
          </BlueActionIconButton>
        }
        onClick={() => {
          if (openSpeedDialog) {
            close()
          } else {
            setOpenSpeedDialog(true)
          }
        }}
        open={openSpeedDialog}
        direction="right"
        FabProps={{
          size: 'small',
          style: {
            background: BackgroundColor.BLUE_GRADATION,
            boxShadow: '0px 1px 1px #00000033',
          },
        }}
      >
        <SpeedDialAction
          key={'action-export'}
          icon={<GetAppIcon color="action" style={actionIconStyle} />}
          tooltipTitle={intl.formatMessage({ id: 'toolbar.excel.export' })}
          onClick={props.onClickExport}
          tooltipPlacement={'bottom-start'}
        />
        {props.onClickChangeDateFormat && (
          <DateFormatSpeedDialAction
            key={'changeDateFormat'}
            icon={<EventAvailableIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({
              id: 'toolbar.changeDateFormat',
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              setAnchorElForChangeDateFormat(
                !!anchorElForChangeDateFormat ? undefined : e.currentTarget
              )
              e.stopPropagation()
            }}
            tooltipPlacement={'bottom-start'}
            anchorEl={anchorElForChangeDateFormat}
            currentFormat={props.currentFormat}
            onChangeFormat={props.onClickChangeDateFormat}
            intl={intl}
          />
        )}
      </SpeedDial>
    </ClickAwayListener>
  )
}

export default SettingSpeedDialActions

const actionIconStyle: React.CSSProperties = {
  width: '14px',
  height: '14px',
  color: IconColor.GREY,
}

const SpeedDial = styled(MuiSpeedDial)(props => ({
  '& .MuiSpeedDial-fab': {
    height: '24px',
    width: '24px',
    minHeight: '24px',
  },
  '& .MuiSpeedDial-actions': {
    padding: 0,
    marginLeft: 5,
    ...(props.open
      ? {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '10px',
        }
      : undefined),
  },
}))
const SpeedDialAction = styled(MuiSpeedDialAction)({
  height: '24px',
  width: '24px',
  minHeight: '24px',
  margin: '2px 4px',
})

type DateFormatFragmentProps = SpeedDialActionProps &
  DateFormatChangePopperProps

const DateFormatSpeedDialAction = (props: DateFormatFragmentProps) => {
  return (
    <>
      <SpeedDialAction
        icon={props.icon}
        tooltipTitle={props.tooltipTitle}
        tooltipPlacement={props.tooltipPlacement}
        onClick={props.onClick}
        open={props.open}
      />
      <DateFormatChangePopper
        anchorEl={props.anchorEl}
        currentFormat={props.currentFormat}
        onChangeFormat={props.onChangeFormat}
        intl={props.intl}
      />
    </>
  )
}
