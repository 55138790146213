export const FONT_FAMILY: string = [
  'Arial',
  'Hiragino Kaku Gothic ProN',
  'Noto Sans JP',
  'sans-serif',
].join(',')
export enum FontSize {
  X_SMALL = 8,
  SMALL = 10,
  MEDIUM = 12,
  LARGE = 14,
  X_LARGE = 16,
  XX_LARGE = 18,
}
export enum FontWeight {
  NORMAL = 400,
  BOLD = 600,
}
export enum Color {
  FLAGXS_BLUE = '#00a0e9',
  FLAGXS_RED = '#ed1e79',
  MAIN = '#1d85b4',
  DISABLED = '#dddddd',
  ALERT = '#ff6b50',
  GREY = '#888888',
  MONOTONE = '#7B8CAA',
}
export enum TextColor {
  BLACK = '#333333',
  GREY = '#888888',
  BLUE = '#015176',
  WHITE = '#ffffff',
  DARK_BLACK = '#262626',
  FOCUS_BULE = '#008cd5',
  MONOTONE = '#243050',
}
export enum BackgroundColor {
  GREY = '#F5F5F5',
  DARK_GREY = '#333333',
  BLUE = '#f2f7f8',
  ALERT = '#ffeaea',
  WARNING = '#fffacd',
  BLUE_GRADATION = 'transparent linear-gradient(90deg, #3D97C1 0%, #0778AB 85%, #1D7CA8 100%) 0% 0% no-repeat padding-box',
  WHITE = '#ffffff',
  ALERT_LOW = '#D9F2F8',
  ALERT_TOO_LOW = '#9ADCEB',
  ALERT_HIGH = '#FDE5E5',
  ALERT_TOO_HIGH = '#F8A7A7',
  GREY_GRADATION = 'transparent linear-gradient(90deg, #B6BABF 0%, #AEB6B9 85%, #8C979C 100%) 0% 0% no-repeat padding-box',
  HIGHLIGHT_BLUE = '#a1caf1',
  FOCUS_BULE = '#f7fcff',
}
export enum BorderColor {
  GREY = '#dddddd',
  WHITE = '#ffffff',
  DARK_BLACK = '#262626',
  LIGHT_BLACK = '#7F7F7F',
}
export enum IconColor {
  BLUE = '#1d85b4',
  WHITE = '#ffffff',
  GREY = '#888888',
}

export enum DayColor {
  HOLIDAY = '#ff6b50',
  SUNDAY = '#ff6b50',
  SATURDAY = '#0076FF',
}

export enum GanttColor {
  BORDER_GRID = '#bdc3c7',
  BORDER_TODAY = '#e65757',
  BACKGROUND_COLOR_TODAY = '#f9e8d2',
  BACKGROUND_COLOR_HOLIDAY = '#f0f0f0',
}
