import { Tree } from '../commons/tree'
import API, { APIResponse } from '../commons/api'
import { isSunday } from '../../utils/date'
import DateVO from '../../vo/DateVO'

export interface OrganizationWorkingDayCalendarDetail
  extends Tree<OrganizationWorkingDayCalendarDetail> {
  code: string
  date: string
  workHour: number
  name?: string
  legal: boolean
}

export interface OrganizationWorkingDayCalendarInput {
  uuid?: string
  lockVersion?: number
  revision?: string
  code: string
  date: string
  workHour: number
  name?: string
  legal: boolean
}

export interface OrganizationWorkingDayCalendarBatchInput {
  edited: OrganizationWorkingDayCalendarInput[]
}

export interface GetOrganizationWorkingDayCalendarProps {
  startDate?: Date
  endDate?: Date
}

export interface OrganizationWorkingDayCalendarProps {
  uuid: string
  lockVersion: number
  revision: string
  code: string
  date: string
  workHour: number
  name: string
  legal: boolean
}

export interface OrganizationWorkingDayCalendarObject {
  uuid: string
  lockVersion: number
  revision: string
  code: string
  date: DateVO
  workHour: number
  name: string
  legal: boolean
}

export class OrganizationWorkingDayCalendar {
  private calendar: { [key: string]: OrganizationWorkingDayCalendarObject }

  constructor(c: OrganizationWorkingDayCalendarProps[]) {
    const calendarList = c.map(v => ({ ...v, date: new DateVO(v.date) }))
    this.calendar = Object.fromEntries(
      calendarList.map(v => [v.date.format(), v])
    )
  }

  private getCalendar(date: string | DateVO) {
    const target = typeof date === 'string' ? new DateVO(date) : date
    return this.calendar[target.format()]
  }

  isHoliday(date: string | DateVO) {
    // TODO Use CalendarDateVO for organization working calendar date list
    const target = typeof date === 'string' ? new DateVO(date) : date
    const calendar = this.getCalendar(target)
    return (calendar && calendar.workHour === 0) || isSunday(target.serialize())
  }
}

export async function getOrganizationWorkingDayCalendar({
  startDate,
  endDate,
}: GetOrganizationWorkingDayCalendarProps): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    '/api/v1/organization_working_day_calendar',
    {
      startDate: startDate ? new DateVO(startDate).serialize() : undefined,
      endDate: endDate ? new DateVO(endDate).serialize() : undefined,
    }
  )
}

export async function updateBatch(
  request: OrganizationWorkingDayCalendarBatchInput
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/organization_working_day_calendar/batch',
    request
  )
}
