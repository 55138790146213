import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { TicketRepository } from '../../applications/ports/ticketRepository'
import {
  ticketService,
  UpdateTicketDeltaRequest,
} from '../../domain/entity/ticket'

export const useTicketRepository = (): TicketRepository => {
  const fetchByWbsItemUuid = useCallback(async (wbsItemUuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/tickets/detail/wbs-item-uuid',
      { wbsItemUuid },
      true
    )
    return ticketService.fromApiResponseToEntity(response.json)
  }, [])
  const updateDelta = useCallback(async (request: UpdateTicketDeltaRequest) => {
    const response = await api.functional.request(
      'PUT',
      '/api/v1/tickets/delta',
      request,
      true
    )
    return {}
  }, [])
  return { fetchByWbsItemUuid, updateDelta }
}
