import { useEffect, useState } from 'react'
import { useGetEnterpriseReport } from '../../../applications/usecases/enterpriseReport/getEnterpriseReport'
import { ProjectReportVO } from '../../../domain/value-object/ProjectReportVO'
import { PageArea } from '..'
import { EnterpriseProjectReportRow } from './components/EnterpriseProjectReportRow'
import { styled } from '@mui/material'
import { EnterpriseProjectReportHeader } from './components/EnterpriseProjectReportHeader'
import { useEnterpriseProjectReportConfig } from './hooks/enterpriseProjectReportConfig'

type EnterpriseProjectReportProps = {}

export const EnterpriseProjectReport = ({}: EnterpriseProjectReportProps) => {
  const { getEnterpriseReport } = useGetEnterpriseReport()
  const [data, setData] = useState<ProjectReportVO[]>([])
  useEffect(() => {
    const f = async () => {
      const report = await getEnterpriseReport()
      setData(report)
    }
    f()
  }, [])

  const { config, ...updateConfigs } = useEnterpriseProjectReportConfig()
  return (
    <PageArea>
      <PageRoot>
        <EnterpriseProjectReportHeader config={config} {...updateConfigs} />
        <ScrollablePageContent>
          {data.map((d, i) => (
            <EnterpriseProjectReportRow key={i} report={d} config={config} />
          ))}
        </ScrollablePageContent>
      </PageRoot>
    </PageArea>
  )
}

const PageRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '24px',
  height: '100%',
})

const ScrollablePageContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  overflowY: 'scroll',
  height: '100%',
})
