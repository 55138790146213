import React from 'react'
import ExclusiveToggleGroup from '../../components/editors/toggle/ExclusiveToggleGroup'
import { intl } from '../../../i18n'

export enum DateBound {
  START = 'START',
  END = 'END',
}

export const StartAndEndDateToggleGroup = (props: {
  value: DateBound
  onChange: (value: DateBound) => void
  height?: number
}) => {
  return (
    <ExclusiveToggleGroup
      value={props.value}
      options={[
        {
          label: intl.formatMessage({
            id: 'toggle.startDate',
          }),
          value: DateBound.START,
        },
        {
          label: intl.formatMessage({
            id: 'toggle.endDate',
          }),
          value: DateBound.END,
        },
      ]}
      onChange={value => props.onChange(value as DateBound)}
      height={props.height}
    />
  )
}
