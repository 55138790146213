import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  FilterInputCell,
  RowHeaderCell,
  StyledTableRow,
} from '../../containers/DataCell'
import CircularButton from '../buttons/CircularButton'

const DialogContent = styled(MuiDialogContent)({
  width: '410px',
  height: '100%',
})
const Content = styled('div')({
  width: '100%',
})
const Table = styled(MuiTable)({
  width: '100%',
  height: '100%',
})

interface Props extends WrappedComponentProps {
  open: boolean
  closeHandler: (rows?: number, columns?: number) => void
}

interface State {
  columns: string
  rows: string
}

class TableCreateDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      columns: '3',
      rows: '3',
    }
  }

  private close = () => {
    this.props.closeHandler()
  }

  private submit = () => {
    const rows = this.state.rows ? Number(this.state.rows) : 0
    const columns = this.state.columns ? Number(this.state.columns) : 0
    this.props.closeHandler(rows, columns)
  }

  private rowCountChanged = value => {
    this.setState({
      rows: value && Number.isFinite(Number(value)) ? value : '',
    })
  }

  private columnCountChanged = value => {
    this.setState({
      columns: value && Number.isFinite(Number(value)) ? value : '',
    })
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.close}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <DialogContent>
          <Content>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <StyledTableRow key="rows">
                    <RowHeaderCell align="left">
                      {this.props.intl.formatMessage({ id: 'table.row.count' })}
                    </RowHeaderCell>
                    <FilterInputCell
                      colSpan={4}
                      value={this.state.rows}
                      type={'text'}
                      onChange={this.rowCountChanged}
                      placeholder="0"
                    />
                  </StyledTableRow>
                  <StyledTableRow key="columns">
                    <RowHeaderCell align="left">
                      {this.props.intl.formatMessage({
                        id: 'table.column.count',
                      })}
                    </RowHeaderCell>
                    <FilterInputCell
                      colSpan={4}
                      value={this.state.columns}
                      type={'text'}
                      onChange={this.columnCountChanged}
                      placeholder="0"
                    />
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Content>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close}>
            {this.props.intl.formatMessage({ id: 'dialog.close' })}
          </Button>
          <CircularButton
            variant="contained"
            color="primary"
            title={this.props.intl.formatMessage({ id: 'dialog.create' })}
            onClick={this.submit}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default injectIntl(TableCreateDialog)
