import _ from 'lodash'
import React, { CSSProperties } from 'react'
import { styled } from '@mui/system'
import {
  withKeyBind,
  WithKeyBindProps,
} from '../../higher-order-components/keyBind'
import store, { AllState } from '../../../store'
import { connect } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../store/messages'
import { BreadCrumb } from '../../../store/breadcrumb'
import { SubmitType } from '../meta/ViewMeta'
import { RouteComponentProps } from 'react-router-dom'
import {
  APIResponse,
  extractValuesFromResponse,
} from '../../../lib/commons/api'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SingleSheetToolBar from './SingleSheetToolBar'
import TabView from './TabView'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import { ResizableArea } from '../../components/draggers/ResizableArea'
import functions, { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../pages/index'
import {
  ApplicationContext,
  Cockpit,
  Function as AppFunction,
  Position,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import objects from '../../../utils/objects'
import {
  loginToProject,
  loginToProjectByUserAction,
} from '../../../store/project'
import Project, { ProjectDetail } from '../../../lib/functions/project'
import { Attachment } from '../../../utils/attachment'
import SingleSheetViewMeta, {
  CellGroupDef,
} from '../meta/ViewMeta/SingleSheetViewMeta'
import DataTable, { TableData } from './DataTable'
import equal from 'fast-deep-equal'
import { ValidationError } from '../meta/validator'
import SingleSheetDataConverter, {
  SingleSheetConverterSpec,
} from '../meta/DataConverter/SingleSheetDataConverter'
import TextVO from '../../../vo/TextVO'
import { BorderColor } from '../../../styles/commonStyles'
import {
  CustomComponent,
  putHeaderComponent,
} from '../../../store/functionLayer'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { applyDiff, clearData, setData } from '../../../store/singleSheet'
import { normalize } from '../../../utils/multilineText'
import HeaderBar from '../../components/headers/HeaderBar'
import Loading from '../../components/process-state-notifications/Loading'
import { KEY_SAVE } from '../../model/keyBind'
import { intl } from '../../../i18n'
import MultiLineTextVO from '../../../vo/MultiLineTextVO'

export interface SingleSheetRepository {
  create(props: any, options?: any): Promise<APIResponse>

  update(props: any, options?: any): Promise<APIResponse>

  updateDelta?(props: any, options?: any): Promise<APIResponse>

  delete?(props: any, options?: any): Promise<APIResponse>

  getDetail(props: { uuid: string }, options?: any): Promise<APIResponse>

  getBasicByCode(code: string): Promise<APIResponse>
}

// Styles
const RootDiv = styled('div')({
  display: 'flex',
  width: '100%',
  height: 'calc(100% - 56px)',
  padding: '0 8px 2px',
  flexGrow: 1,
})
const Content = styled('div')({
  width: '100%',
  height: '100%',
  flexGrow: 1,
  padding: '0px 2px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
})
const MainDiv = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  border: `1px solid ${BorderColor.LIGHT_BLACK}`,
})
const LeftTabPanel = styled('div')({
  width: '100%',
  height: '100%',
  flexGrow: 1,
  overflow: 'hidden',
})

// Interface

export interface PathProps {
  code: string
}

interface StateProps {
  projectUuid?: string
  functions: AppFunction[]
  data: any
  menuOpened: boolean
  functionLayer: Map<number, any>
  hideHeader?: boolean
}

interface Props<D extends TableData>
  extends WrappedComponentProps,
    WithKeyBindProps,
    RouteComponentProps<PathProps>,
    StateProps {
  uuid: string
  externalId: string
  code?: string
  repository: SingleSheetRepository
  inProgress: boolean
  breadcrumbs?: (key: SingleSheetKey) => any
  options: SingleSheetOptions<D>
  useUiMetaOf?: (basic: any) => APPLICATION_FUNCTION_EXTERNAL_ID
  useFunctionUuidOf?: APPLICATION_FUNCTION_EXTERNAL_ID
  auxiliaries?: any
}

interface State<D extends TableData> {
  initialized: boolean
  projectUuid?: string
  uuid?: string
  lockVersion?: number
  latestRevision?: string
  submitType: SubmitType
  inProgress: boolean
  readOnly?: boolean
  breadcrumbs?: BreadCrumb
  data: D
  cellDefs: CellGroupDef[]
  errors: { [key: string]: ValidationError }
  applicationContext?: ApplicationContext
  cumulation?: ProjectPlanCumulation
  tabWidth: number
  tabHeight: number
}

export type SingleSheetKey = {
  uuid: string
  projectUuid?: string
}

export interface AttachmentHandlerParams<D extends TableData> {
  externalId: string
  uuid: string
  lockVersion?: number
  attachments: Attachment[]
  ctx: SingleSheetContext<D>
}

export abstract class SingleSheetOptions<D extends TableData> {
  getApplicationContext?: (basic: any) => ApplicationContext | undefined
  redirect?: (props: RouteComponentProps<PathProps>, basic: any) => void
  selectKey: (
    basic?: any
  ) => SingleSheetKey | Promise<SingleSheetKey | undefined> | undefined = key =>
    key
  uuidForOptionalFunction: (data: any) => string | string[] | undefined =
    data => data.uuid
  abstract converterSpec: SingleSheetConverterSpec
  afterCreate: () => void = () => undefined
  beforeUpdate: (
    data: any,
    initialData: any,
    submitType: SubmitType,
    uuid?: string,
    lockVersion?: number
  ) => any = data => data
  setAuxiliaries: (auxiliaries: any) => void = (auxiliaries: any) => {
    return
  }
  onUploadAttachments?: (params: AttachmentHandlerParams<D>) => Promise<{
    uuid: string
    lockVersion: number
  } | void>
  onDeleteAttachments?: (params: AttachmentHandlerParams<D>) => Promise<{
    uuid: string
    lockVersion: number
  } | void>
  defaultLeftTabExternalId?: string
  defaultRightTabExternalId?: string
  refreshAfterCreate?: boolean
  referece?: boolean
  getCodeByData: (data: any) => TextVO = (data: any) => data.code
  TitleComponent?: React.FC<any>
  HeaderComponent?: React.FC<any>
  // TODO Remove it
  getHeaderComponents?: (data: D) => {
    titleComponent?: JSX.Element
    customComponents?: CustomComponent[]
  }
  getToolbarProps: (ctx: SingleSheetContext<D>) => any = ctx => ({})
  toolBarItems?: (props: any) => JSX.Element[]
  ignoreDataChangeCheckKeys?: string[]
  afterDelete: () => void = () => undefined
  commentHeaderComponents?: (props: any) => JSX.Element[]
  canDelete?: (data) => boolean
  canLogin?: (data) => boolean
  getStyleRule?: (
    externalId: string
  ) => ((data: D, target?: string) => CSSProperties | undefined) | undefined
  getBacisFromSingleSheetData?: (singleSheetStateData: any) => any
}

export interface SingleSheetContext<D extends TableData> {
  props: Props<D>
  state: State<D>
}

class SingleSheet<D extends TableData> extends React.Component<
  Props<D>,
  State<D>
> {
  private dataConverter: SingleSheetDataConverter
  private viewMeta: SingleSheetViewMeta
  private options: SingleSheetOptions<D>
  private repository: SingleSheetRepository
  private initialData?: D

  private defaultLeftTabIndex = 0
  private defaultRightTabIndex = 0
  private tabDiv: any

  constructor(props: Props<D>) {
    super(props)
    this.state = {
      initialized: false,
      submitType: SubmitType.Create,
      inProgress: false,
      projectUuid: props.projectUuid,
      data: {} as D,
      cellDefs: [],
      errors: {},
      tabWidth: 0,
      tabHeight: 0,
    }
    this.viewMeta = new SingleSheetViewMeta(props.uuid, props.externalId)
    this.options = props.options
    this.repository = props.repository
    this.dataConverter = new SingleSheetDataConverter(
      this.options?.converterSpec || new SingleSheetConverterSpec()
    )
    this.tabDiv = React.createRef<HTMLDivElement>()
  }

  handleWindowResize = _.debounce(() => {
    if (this.tabDiv.current) {
      this.setState({
        tabWidth: this.tabDiv.current.offsetWidth,
        tabHeight: this.tabDiv.current.offsetHeight,
      })
    }
  })

  async componentDidMount() {
    const uuid = await this.resolveApplicationContext()
    if (uuid === undefined) return
    await this.setDefaultForm(uuid)
    await this.setForm()
    await this.loadData()
    this.props.addKeyBindListeners([
      {
        key: KEY_SAVE,
        fn: this.submit,
        stopDefaultBehavior: true,
      },
    ])
    if (!this.state.initialized) {
      this.setState({
        initialized: true,
      })
    }
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  }

  private setForm = async () => {
    if (this.options.defaultLeftTabExternalId) {
      const properties =
        this.viewMeta.functionMeta.tabViewProperties.left.byIndex
      this.defaultLeftTabIndex = Math.max(
        properties.findIndex(
          v => v.externalId === this.options.defaultLeftTabExternalId
        ),
        0
      )
    }
    if (this.options.defaultRightTabExternalId) {
      const properties =
        this.viewMeta.functionMeta.tabViewProperties.right.byIndex
      this.defaultRightTabIndex = Math.max(
        properties.findIndex(
          v => v.externalId === this.options.defaultRightTabExternalId
        ),
        0
      )
    }
  }

  private loadData = async () => {
    if (this.state.submitType === SubmitType.Create) {
      const initData = this.dataConverter.structInitialData(
        this.viewMeta,
        this.props.auxiliaries
      )
      const data = this.props.data
        ? {
            ...initData,
            ...this.dataConverter.deserialize(this.props.data, this.viewMeta),
          }
        : initData
      this.initialData = _.cloneDeep(data)
      this.setState({
        data,
        uuid: data.uuid,
        lockVersion: data.lockVersion,
        cumulation: this.props.data?.cumulation,
      })
    } else if (this.state.submitType === SubmitType.Update) {
      await this.fetch(this.state.uuid!)
    }
  }

  private getFunctionUuid = () => {
    const useFunctionUuidOf = this.props.useFunctionUuidOf
      ? store
          .getState()
          .appFunction.functions.find(
            v => v.externalId === this.props.useFunctionUuidOf
          )
      : undefined
    return useFunctionUuidOf?.uuid || this.props.uuid
  }

  // TODO: refactor
  private resolveApplicationContext = async (): Promise<string | undefined> => {
    let key: SingleSheetKey | undefined
    let response: any
    let basic: any
    const code = this.props.code
    const component = functions[this.props.externalId]

    if (this.options.getBacisFromSingleSheetData && this.props.data) {
      basic = this.options.getBacisFromSingleSheetData(this.props.data)
    } else {
      try {
        response = code ? await this.repository.getBasicByCode(code) : undefined
      } catch (err: any) {
        if (err.code === 'NOT_FOUND') {
          store.dispatch(
            addScreenMessage(this.viewMeta.externalId, {
              type: MessageLevel.WARN,
              title: this.props.intl.formatMessage({
                id: 'item.deleted',
              }),
            })
          )
        } else {
          throw err
        }
        return undefined
      }
      if (response) {
        basic = response.json
      }
    }

    this.options.redirect?.(this.props, basic)

    const useUiMetaOfId = this.props.useUiMetaOf
      ? this.props.useUiMetaOf(basic)
      : undefined
    let useUiMetaOf
    if (useUiMetaOfId) {
      const component = functions[useUiMetaOfId]
      useUiMetaOf = this.props.functions.find(
        v => v.externalId === useUiMetaOfId
      )
      this.options =
        (component.options as SingleSheetOptions<D>) || this.options
      this.repository =
        (component.repository as SingleSheetRepository) || this.repository
      this.dataConverter = new SingleSheetDataConverter(
        this.options.converterSpec
      )
    }
    this.viewMeta = new SingleSheetViewMeta(
      useUiMetaOf?.uuid || this.props.uuid,
      useUiMetaOf?.externalId || this.props.externalId
    )
    const context = this.getApplicationContext(basic)
    this.viewMeta.setContext(context)

    key = await this.options.selectKey(basic)
    let submitType
    if (component?.cockpit === Cockpit.Tenant || Cockpit.Global) {
      submitType = !key?.uuid ? SubmitType.Create : SubmitType.Update
    } else {
      if (basic.projectCode === code) {
        submitType = SubmitType.Create
      } else {
        submitType = key?.uuid ? SubmitType.Update : SubmitType.Disable
      }
    }
    this.setState(
      {
        uuid: key?.uuid,
        submitType: submitType,
        projectUuid: response?.json.projectUuid ?? basic?.projectUuid,
      },
      () => {
        if (component.cockpit === Cockpit.Project) {
          if (this.props.functionLayer.size === 1) {
            if (this.state.projectUuid) {
              store.dispatch(loginToProject(this.state.projectUuid))
            } else if (this.props.repository === Project) {
              store.dispatch(loginToProject(this.state.uuid!))
            }
          }
        }
      }
    )
    return key?.uuid ?? ''
  }

  getApplicationContext = (basic: any): ApplicationContext | undefined => {
    if (this.options.getApplicationContext) {
      return this.options.getApplicationContext(basic)
    }
    return basic?.projectUuid && { groupKeys: [basic.projectUuid] }
  }

  componentDidUpdate(prevProps: Props<D>, prevState: State<D>) {
    if (prevProps.data !== this.props.data && !!this.props.data) {
      if (!prevProps.data) {
        setTimeout(() => this.refreshData(), 300)
      } else {
        this.refreshData()
      }
    }
    if (prevState.lockVersion !== this.state.lockVersion) {
      this.setState({
        submitType: this.state.lockVersion
          ? SubmitType.Update
          : SubmitType.Create,
      })
      return
    }

    if (this.state.submitType !== prevState.submitType) {
      if (this.state.submitType === SubmitType.Create) {
        this.setState({ lockVersion: 0 })
        this.setDefaultForm()
      }
      if (
        prevState.submitType === SubmitType.Create &&
        this.state.submitType === SubmitType.Update
      ) {
        this.setDefaultForm()
      }
    }
  }

  private refreshData = () => this.setState({ data: this.getTableData() })

  componentWillUnmount() {
    this.props.removeKeyBindListeners()
    store.dispatch(clearData())
    window.removeEventListener('resize', this.handleWindowResize)
  }

  isDataChanged = () => !equal(this.initialData, this.state.data)

  dataChangeCheckerReplacer = (key: string, value) => {
    if (this.options?.ignoreDataChangeCheckKeys?.includes(key)) {
      return undefined
    }
    return value
  }

  private dataChangeChecker = async (data: {}) => {
    if (
      JSON.stringify(this.initialData, this.dataChangeCheckerReplacer) !==
      JSON.stringify(data, this.dataChangeCheckerReplacer)
    ) {
      store.dispatch(requireSave())
    } else {
      store.dispatch(doNotRequireSave())
    }
  }

  private submit = async () => {
    // Do nothing if data is not updated.
    if (!this.isDataChanged()) {
      return
    }
    // For pressing Ctrl + S
    if (!objects.isEmpty(this.state.errors)) {
      return
    }
    this.setState({ inProgress: true })
    const activeEle = document.activeElement as HTMLElement
    activeEle.blur()
    const { after, delta } = this.dataConverter.serialize({
      src: this.state.data,
      viewMeta: this.viewMeta,
      initialData: this.initialData,
      submitType: this.state.submitType,
      auxiliaries: this.props.auxiliaries,
    })
    try {
      const submitType = this.state.submitType
      let response
      if (submitType === SubmitType.Create) {
        response = await this.repository.create(after)
        this.options.afterCreate()
      } else {
        if (this.repository.updateDelta) {
          response = await this.repository.updateDelta(delta)
        } else {
          response = await this.repository.update(after)
        }
      }
      if (response.hasWarning) {
        this.handleWarning(response, after)
        return
      }
      store.dispatch(doNotRequireSave())
      const uuid = response.json.uuid || this.state.uuid
      const data = await this.fetch(uuid)
      const code = this.options.getCodeByData(data) || new TextVO('')
      store.dispatch(
        addScreenMessage(this.viewMeta.externalId, {
          type: MessageLevel.SUCCESS,
          title: this.getMessage(
            `${this.state.submitType.toLowerCase()}.completed.title`
          ),
          text: this.getMessage(
            `${this.state.submitType.toLowerCase()}.completed.message`,
            {
              code: code.getValue(),
            }
          ),
        })
      )
      if (this.state.uuid !== uuid) {
        this.setState({ uuid: uuid })
      }
    } catch (err) {
      this.handleError(err)
    } finally {
      this.setState({ inProgress: false })
    }
  }

  private delete = async () => {
    await this.repository.delete!({
      uuid: this.state.uuid,
      lockVersion: this.state.lockVersion,
    })
    if (this.options.afterDelete) {
      this.options.afterDelete()
    }
    store.dispatch(
      addScreenMessage(this.viewMeta.externalId, {
        type: MessageLevel.SUCCESS,
        title: this.props.intl.formatMessage({
          id: 'singleSheet.delete.completed',
        }),
      })
    )
    this.setState({ readOnly: true })
    store.dispatch(doNotRequireSave())
  }

  private login = async () => {
    store.dispatch(loginToProjectByUserAction(this.state.uuid!))
  }

  private handleWarning = (response: any, postData: any): void => {
    const messages = extractValuesFromResponse(response.json, 'messages')
    handleWarning(messages, uuid => {
      if (postData['code']) {
        return postData
      }
      const getMainEntityName = (data: any, entry?: string) => {
        const obj = entry ? data[entry] : data
        if (typeof obj !== 'object') return undefined
        const keys = Object.keys(obj)
        if (keys.includes('code')) return entry
        for (let i in keys) {
          const entity = getMainEntityName(obj, keys[i])
          if (entity) {
            return entry ? `${entry}.${entity}` : entity
          }
        }
        return undefined
      }
      const mainEntityName = getMainEntityName(postData)
      if (mainEntityName) {
        return objects.getValue(postData, mainEntityName)
      }
    })
  }

  private handleError = (error: any) => {
    if (error.errorCode === 'NOT_IMPLEMENTED') {
      return store.dispatch(
        addScreenMessage(this.viewMeta.externalId, {
          type: MessageLevel.INFO,
          title: intl.formatMessage({ id: 'snackBar.not.implemented' }),
        })
      )
    }
    if (error.status !== 400) {
      throw error
    }
    const messagePrefix = this.state.submitType.toLowerCase()
    let errorTitle = this.getMessage(`${messagePrefix}.error.title`)
    let errorMessage: string
    if (error.code === 'CONSTRAINT_VIOLATION') {
      // add error message
      const globalMessage = ''
      errorMessage = this.getMessage(`${messagePrefix}.validateError.title`)
      errorMessage = `${errorMessage}\n${globalMessage}`
    } else {
      errorMessage = error.detail
    }
    store.dispatch(
      addScreenMessage(this.viewMeta.externalId, {
        type: MessageLevel.WARN,
        title: errorTitle,
        text: errorMessage,
      })
    )
  }

  private decodePropertyCharCode = (src: any, type: PropertyType): any => {
    let srcObject = _.cloneDeep(src)
    const multiLineTextExternalIds: string[] =
      this.viewMeta.functionMeta.detail.properties
        .filter(property => {
          return property.propertyType === type
        })
        .map(property => {
          return this.viewMeta.makeDataPropertyName(property)
        })
    multiLineTextExternalIds.forEach(externalId => {
      const value = objects.getValue(srcObject, externalId)
      if (value) {
        const decoded = normalize(value.getValue() || '')
        objects.setValue(srcObject, externalId, new MultiLineTextVO(decoded))
      }
    })
    return srcObject
  }

  private fetch = async (uuid: string): Promise<TableData> => {
    const response = await this.repository.getDetail({ uuid })
    const cumulation =
      response.json.ticket?.cumulation ?? response.json.cumulation
    store.dispatch(setData(response.json))
    const data = this.dataConverter.deserialize(response.json, this.viewMeta)
    this.initialData = _.cloneDeep(data)
    if (this.options.getHeaderComponents) {
      const headerComponent = this.options.getHeaderComponents(response.json)
      store.dispatch(
        putHeaderComponent(
          headerComponent.titleComponent,
          headerComponent.customComponents
        )
      )
    }
    this.setState({
      data,
      uuid: data.uuid,
      lockVersion: data.lockVersion,
    })
    if (cumulation) {
      this.setState({ cumulation: cumulation })
    }
    return data
  }

  refreshForm = async () => {
    await this.setDefaultForm()
    if (this.state.submitType === SubmitType.Update) {
      // Clear lock version to fetch with initial condition
      this.setState({ lockVersion: undefined }, () => {
        this.fetch(this.state.uuid!)
      })
    }
    store.dispatch(doNotRequireSave())
  }

  refreshAndMergeUncommitData = async (result: any) => {
    if (!this.state.uuid) {
      return
    }
    const response = await this.fetch(this.state.uuid)
    // TODO: merge uncommitted data.
  }

  private setDefaultForm = async (uuid?: string) => {
    this.setState(
      await this.viewMeta.getDefaultState(
        uuid || this.state.uuid ? SubmitType.Update : SubmitType.Create,
        this.options
      )
    )
  }

  private getMessage = (id: string, values: { [key: string]: string } = {}) => {
    return this.props.intl.formatMessage(
      {
        id: `${this.viewMeta.externalId}.${id}`,
      },
      values
    )
  }

  private isReadOnly = () => {
    return this.state.readOnly // TODO 権限により変わる
  }

  private onUploadAttachments = async (
    externalId: string,
    attachments: Attachment[]
  ) => {
    if (this.options.onUploadAttachments) {
      await this.options.onUploadAttachments({
        externalId,
        uuid: this.state.uuid!,
        lockVersion: this.state.lockVersion!,
        attachments,
        ctx: this,
      })
    }
  }

  private onDeleteAttachments = async (
    externalId: string,
    attachments: Attachment[]
  ) => {
    if (this.options.onDeleteAttachments) {
      await this.options.onDeleteAttachments({
        externalId,
        uuid: this.state.uuid!,
        lockVersion: this.state.lockVersion!,
        attachments,
        ctx: this,
      })
    }
  }

  private getTableData = () => {
    return this.dataConverter.deserialize(this.props.data, this.viewMeta)
  }

  private setError = (key: string, value: ValidationError | undefined) => {
    const errors = _.cloneDeep(this.state.errors)
    if (value) {
      errors[key] = value
      this.setState({ errors })
    } else if (errors[key]) {
      delete errors[key]
      this.setState({ errors })
    }
  }

  render = () => {
    if (!this.state.initialized) {
      return <RootDiv ref={this.tabDiv} />
    }

    const uuid = this.options.uuidForOptionalFunction(this.state.data)
    const disabled =
      this.state.readOnly || this.state.submitType === SubmitType.Disable
    const getTabProperties = (position: Position) => {
      const properties = this.viewMeta.getTabViewProperties(position)
      return disabled
        ? properties.filter(v => v.propertyType === PropertyType.Revision)
        : properties
    }
    const hasRightTab =
      this.viewMeta.functionMeta.tabViewProperties.right.byId.size > 0
    const inputTable = (
      <DataTable
        data={this.state.data}
        cellDef={this.state.cellDefs}
        onChange={(data: TableData, newValue: { [key: string]: any }) => {
          if (this.state.submitType === SubmitType.Create) {
            this.setState({ data: data as D })
            store.dispatch(applyDiff(newValue))
          } else {
            store.dispatch(applyDiff(newValue))
          }
          this.dataChangeChecker(data)
        }}
        setError={this.setError}
        initialData={
          // TODO: Reconsider the timing of decode
          this.initialData
            ? this.decodePropertyCharCode(
                this.initialData,
                PropertyType.MultiLineText
              )
            : undefined
        }
      />
    )
    // Do not rename <title> for poppers
    if (
      this.props.data &&
      this.props.functionLayer.get(0).externalId === 'wbsItem' &&
      this.props.functionLayer.size === 1
    ) {
      if (typeof this.props.data.displayName === 'undefined') {
        // type: "TASK"
        document.title = this.props.data.wbsItem.displayName
      } else {
        document.title = this.props.data.displayName
      }
    }

    let leftTabView
    if (hasRightTab) {
      leftTabView = (
        <ResizableArea
          width={
            this.state.tabWidth *
            (SubmitType.Create === this.state.submitType ? 0.5 : 0.65)
          }
          maxWidth={this.state.tabWidth}
          height={this.state.tabHeight}
        >
          <LeftTabPanel>
            <TabView
              projectUuid={this.state.projectUuid}
              properties={getTabProperties(Position.Left)}
              data={this.state.data}
              functionUuid={this.getFunctionUuid()}
              viewMeta={this.viewMeta}
              dataUuid={uuid}
              dataLockVersion={this.state.lockVersion}
              position={Position.Left}
              defaultIndex={this.defaultLeftTabIndex}
              onUploadAttachments={this.onUploadAttachments}
              onDeleteAttachments={this.onDeleteAttachments}
              breadcrumbs={this.state.breadcrumbs}
              table={inputTable}
              onChange={(data: any, changeValue: { [key: string]: any }) => {
                store.dispatch(applyDiff(changeValue))
                this.dataChangeChecker(data)
              }}
              setError={this.setError}
            />
          </LeftTabPanel>
        </ResizableArea>
      )
    } else {
      leftTabView = inputTable
    }
    const readOnly = this.isReadOnly()
    const hasError =
      Object.values(this.state.errors).filter(v => !!v).length > 0

    return (
      <RootDiv>
        <Loading isLoading={this.state.inProgress} />
        <Content>
          {!this.props.hideHeader && (
            <HeaderBar
              depth={0}
              TitleComponent={
                this.options?.TitleComponent
                  ? React.createElement(this.options.TitleComponent, {
                      data: this.props.data,
                    })
                  : undefined
              }
              HeaderComponent={
                this.options?.HeaderComponent
                  ? React.createElement(this.options.HeaderComponent, {
                      data: this.props.data,
                    })
                  : undefined
              }
            />
          )}
          <SingleSheetToolBar
            submitButtonTitle={this.getMessage(
              this.state.submitType === SubmitType.Create
                ? 'create.submitButton'
                : 'update.submitButton'
            )}
            onSubmit={this.submit}
            onCancel={
              this.state.submitType === SubmitType.Create
                ? undefined
                : this.refreshForm
            }
            onDelete={this.delete}
            inProgress={this.state.inProgress}
            submitButtonDisabled={readOnly || disabled || hasError}
            cancelButtonDisabled={readOnly || disabled}
            deleteButtonDisabled={
              readOnly ||
              disabled ||
              this.state.submitType === SubmitType.Create ||
              !this.repository.delete ||
              this.options.referece ||
              !(
                this.options.canDelete &&
                this.options.canDelete(this.initialData)
              )
            }
            onLogin={this.login}
            loginButtonDisabled={
              readOnly ||
              disabled ||
              this.state.submitType === SubmitType.Create ||
              this.options.referece ||
              !(this.options.canLogin && this.options.canLogin(this.state.data))
            }
          >
            {this.options.toolBarItems && this.options.toolBarItems(this)}
          </SingleSheetToolBar>
          <MainDiv ref={this.tabDiv}>
            {leftTabView}
            {hasRightTab && (
              <TabView
                projectUuid={this.state.projectUuid}
                properties={getTabProperties(Position.Right)}
                data={this.state.data}
                functionUuid={this.getFunctionUuid()}
                viewMeta={this.viewMeta}
                dataUuid={uuid}
                dataLockVersion={this.state.lockVersion}
                position={Position.Right}
                defaultIndex={this.defaultRightTabIndex}
                onUploadAttachments={this.onUploadAttachments}
                onDeleteAttachments={this.onDeleteAttachments}
                breadcrumbs={this.state.breadcrumbs}
                onChange={(data: any, changeValue: { [key: string]: any }) => {
                  store.dispatch(applyDiff(changeValue))
                  this.dataChangeChecker(data)
                }}
                commentHeaderCompornent={
                  this.options?.commentHeaderComponents &&
                  this.options.commentHeaderComponents(this)
                }
                setError={this.setError}
              />
            )}
          </MainDiv>
        </Content>
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState, ownProps: Props<TableData>) => {
  const projectUuid = ownProps.projectUuid || state.project.selected
  return {
    projectUuid,
    functions: state.appFunction.functions,
    data: state.singleSheet.data.find(
      v => (v.ticket?.wbsItem || v.wbsItem || v).code === ownProps.code
    ), // TODO Replace code with uuid.
    menuOpened: state.sideMenu.open,
    functionLayer: state.functionLayer.layers,
  }
}

export default connect(mapStateToProps)(injectIntl(withKeyBind(SingleSheet)))
