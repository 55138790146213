import VOBase from '../base'

export default class SwitchVO extends VOBase {
  private readonly value: boolean

  constructor(_value: boolean | string) {
    super()
    let switchValue = _value
    if (typeof switchValue === 'string') {
      switchValue = switchValue.toLowerCase()
      if (!['true', 'false'].includes(switchValue)) {
        throw new Error(
          `Can not create SwitchVO instance because source value(${_value}) is invalid.`
        )
      }
      this.value = switchValue === 'true'
    } else {
      this.value = switchValue
    }
  }
  getValue = () => {
    return this.value
  }
  format = () => {
    return this.value ? '✔︎' : ''
  }
  serialize = () => this.value
}
