import {
  ConnectedInfo,
  ExternalServiceActions,
  ExternalServiceCard,
  ExternalServiceCardContent,
  ExternalServiceCardExplanation,
  ExternalServiceTitle,
  ExternalServiceTitleArea,
  ExternalServiceTitleWithIcon,
} from '../common/ExternalServiceCard'
import { intl } from '../../../../../i18n'
import { SetupSlack } from './SetupSlack'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import { useDialogState } from '../../../../components/dialogs/useDialogState'
import { useEffect, useState } from 'react'
import CancelButton from '../../../../components/buttons/CancelButton'
import SlackIcon from '../../../../components/icons/SlackIcon'
import { useSlackExternalServiceStorageService } from '../../../../../services/storage-services/external-services/slackExternalServiceStorageService'

export const SlackCard = () => {
  const [dialogOpen, openDialog, closeDialog] = useDialogState()
  const { getAuthorizedSlackWorkspace } =
    useSlackExternalServiceStorageService()
  const [connected, setConnected] = useState<boolean>(false)
  useEffect(() => {
    if (dialogOpen) return
    const fn = async () => {
      const workspaces = await getAuthorizedSlackWorkspace()
      const isAlreadyConnected = !!workspaces && workspaces.length > 0
      setConnected(isAlreadyConnected)
    }
    fn()
  }, [dialogOpen])
  return (
    <>
      <ExternalServiceCard sx={{ width: '360px' }}>
        <ConnectedInfo connected={connected} />
        <ExternalServiceCardContent>
          <ExternalServiceTitleArea>
            <SlackTitle />
          </ExternalServiceTitleArea>
          <ExternalServiceCardExplanation>
            {intl.formatMessage({
              id: 'externalService.slack.card.description',
            })}
          </ExternalServiceCardExplanation>
        </ExternalServiceCardContent>
        <ExternalServiceActions>
          {!connected && (
            <SubmitButton
              title={intl.formatMessage({
                id: 'externalService.startConfiguration',
              })}
              onClick={openDialog}
              hideIcon={true}
              hideTooltip={true}
              sx={{ width: '120px' }}
            />
          )}
          {connected && (
            <CancelButton
              title={intl.formatMessage({
                id: 'externalService.changeConfiguration',
              })}
              onClick={openDialog}
              sx={{ width: '120px' }}
            />
          )}
        </ExternalServiceActions>
      </ExternalServiceCard>
      <SetupSlack open={dialogOpen} close={closeDialog} />
    </>
  )
}

const SlackTitle = () => {
  return (
    <ExternalServiceTitleWithIcon>
      <SlackIcon />
      <ExternalServiceTitle>Slack</ExternalServiceTitle>
    </ExternalServiceTitleWithIcon>
  )
}
