import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import { ICellEditorParams } from 'ag-grid-community'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import _ from 'lodash'
import { gridRootStyle } from '../../../commons/AgGrid'
import SaveButton from '../../../../components/buttons/SaveButton'
import CancelButton from '../../../../components/buttons/CancelButton'
import Tag from '../../../../components/labels/Tag'
import { TagForWbsItem } from '../../../../../lib/functions/tag'
import { useDispatch, useSelector } from 'react-redux'
import { fetchTagsByProject } from '../../../../../store/tag'
import { intl } from '../../../../../i18n'
import { AllState } from '../../../../../store'
import MuiOpenInNewIcon from '@mui/icons-material/OpenInNewRounded'
import { IconButton } from '../../../../components/icons/IconButton'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

interface StateProps {
  options: TagForWbsItem[]
  pageProjectUuid: string | undefined
  projectCode: string | undefined
}

type Props = StateProps &
  ICellEditorParams & {
    projectUuidExtractor?: (data) => string
  }

export const TagCellEditor = forwardRef((props: Props, ref) => {
  const { data, projectUuidExtractor } = props
  const rowProjectUuid = projectUuidExtractor && projectUuidExtractor(data)
  const { options, projectCode, pageProjectUuid } = useSelector<
    AllState,
    StateProps
  >(state => ({
    pageProjectUuid: state.project.selected,
    projectCode: rowProjectUuid
      ? state.project.assigned.find(v => v.uuid === rowProjectUuid)?.code
      : state.project.current?.code,
    options: state.tag[rowProjectUuid ?? state.project.selected ?? ''] ?? [],
  }))
  const dispatch = useDispatch()
  const inputRef = React.createRef<HTMLInputElement>()

  const [value, setValue] = useState<TagForWbsItem[]>(props.value)
  const [open, setOpen] = useState<boolean>(false)

  const projectUuid = rowProjectUuid ?? pageProjectUuid
  const dispatchFetchTags = useCallback(() => {
    if (projectUuid) {
      dispatch(fetchTagsByProject(projectUuid))
      inputRef.current?.focus()
    }
  }, [projectUuid])

  useEffect(() => {
    if (options.length === 0) {
      dispatchFetchTags()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchFetchTags])

  const search = useMemo(() => _.debounce(() => setOpen(true), 100), [options])

  const valueChanged = useCallback(
    (event: React.ChangeEvent<{}>, option: TagForWbsItem[] | null) => {
      setValue(option || [])
    },
    []
  )

  const onSubmit = useCallback(() => {
    setOpen(false)
    props.api.stopEditing()
  }, [])

  const onCancel = useCallback(() => {
    setOpen(false)
    props.api.stopEditing(true)
  }, [])

  const onClose = useCallback((e, reason) => {
    if (['blur', 'toggleInput'].includes(reason)) {
      return
    }
    let cancel = false
    if (reason === 'escape') {
      cancel = true
    }
    setOpen(false)
    props.api.stopEditing(cancel)
  }, [])

  const openMaintenancePage = useCallback(
    () => window.open(`${window.location.origin}/tags/${projectCode}`),
    [projectCode]
  )

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value
      },
    }
  })

  return (
    <Box sx={gridRootStyle}>
      <Autocomplete
        multiple={true}
        options={options}
        getOptionLabel={option => (option.name ? option.name : '')}
        renderOption={TagCellEditorRenderOption}
        isOptionEqualToValue={(value, option) => {
          return value.uuid === option.uuid
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <InputBase
            inputRef={inputRef}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus={true}
          />
        )}
        PopperComponent={props => (
          <Popper {...props} placement="bottom-start" />
        )}
        PaperComponent={props => (
          <Paper
            {...props}
            sx={{
              '& .MuiAutocomplete-option': { paddingLeft: '1px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '24px',
              }}
            >
              <IconButton
                onClick={dispatchFetchTags}
                size={'small'}
                title={intl.formatMessage({
                  id: 'tags.cellEditor.reload',
                })}
              >
                <RefreshRoundedIcon
                  color="action"
                  style={{ width: '14px', height: '14px' }}
                />
              </IconButton>
              <IconButton
                onClick={openMaintenancePage}
                size={'small'}
                title={intl.formatMessage({
                  id: 'tags.cellEditor.transition.to.maintenance',
                })}
              >
                <MuiOpenInNewIcon
                  style={{ width: '12px', height: '12px' }}
                  color="action"
                />
              </IconButton>
            </Box>
            {props.children}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '36px',
              }}
            >
              <SaveButton onClick={onSubmit} />
              <CancelButton onClick={onCancel} />
            </Box>
          </Paper>
        )}
        autoSelect={false}
        blurOnSelect={false}
        open={open}
        noOptionsText={''}
        value={value}
        onFocus={search}
        onChange={valueChanged}
        onClose={onClose}
        disableCloseOnSelect={true}
      />
    </Box>
  )
})

export const TagCellEditorRenderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: TagForWbsItem,
  state: AutocompleteRenderOptionState
) => {
  return (
    <MenuItem
      {...props}
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        '& .MuiCheckbox-root': { marginRight: '0px !important' },
        '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
      }}
    >
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        style={{ marginRight: 8 }}
        checked={state.selected}
        color={'primary'}
      />
      <Tag value={option.name} backgroundColor={option.backgroundColor} />
    </MenuItem>
  )
}
