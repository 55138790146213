import { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import { Delta, Revision } from '../../../../lib/functions/changeLog'
import { formatDateTimeWithDay } from '../../../../utils/date'
import ViewMeta from '../../../containers/meta/ViewMeta'
import { formatBytes } from '../../../../utils/attachment'
import {
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import MultilineTextDialog from './MultilineTextDialog'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import { intl } from '../../../../i18n'

interface FormattedDelta {
  name: string
  newValue: string | JSX.Element
  oldValue: string | JSX.Element
}

interface FormattedRevision {
  datetime: string | undefined
  name: string
  delta?: FormattedDelta[]
}

interface Props {
  viewMeta: ViewMeta
  revisions?: Revision[]
}

const RevisionTable = styled(Table)({
  tableLayout: 'fixed',
  width: '100%',
  minWidth: '390px',
  maxWidth: '630px',
  border: 'solid 1px #cccccc',
  '& .MuiTableCell-root': {
    border: 'solid 1px #cccccc',
    '&:last-child': {
      paddingRight: '5px',
    },
  },
  '& .MuiTableCell-head': {
    backgroundColor: BackgroundColor.GREY,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    '&:first-child': {
      width: '130px',
    },
  },
  '& .MuiTableCell-body': {
    width: 'auto',
    wordBreak: 'break-all',
  },
})
const TimeSpan = styled('span')({
  color: TextColor.GREY,
  fontSize: '10px',
  verticalAlign: 'bottom',
})

const RevisionListComponent = (props: Props) => {
  const NOT_SET_STR = intl.formatMessage({ id: 'revisionList.delta.notSet' })
  const [formattedRevisions, setFormattedRevisions] = useState<
    FormattedRevision[]
  >([])

  useEffect(() => {
    setFormattedRevisions(formatRevisions(props.revisions))
  }, [props.revisions])

  const formatValue = useCallback(
    (
      revision: Revision,
      src: any,
      meta?: FunctionProperty
    ): string | JSX.Element | undefined => {
      if (meta && meta.propertyType === PropertyType.MultiLineText) {
        return <MultilineTextDialog text={`${src || ''}`} revision={revision} />
      }
      let result
      if (!src) {
        result = undefined
      } else if (
        meta &&
        (meta.propertyType === PropertyType.EntitySearch ||
          meta.propertyType === PropertyType.Select)
      ) {
        result = src.name || src.displayName || undefined
      } else if (meta && meta.propertyType === PropertyType.Switch) {
        result = src === true || src === 'true' ? 'ON' : 'OFF'
      } else if (src.name) {
        result = src.size
          ? src.name + '(' + formatBytes(src.size) + ')'
          : src.name
      } else if (typeof src === 'object') {
        result = src.toString()
      } else {
        result = src
      }
      return result
    },
    []
  )

  const formatDelta = useCallback(
    (revision: Revision): FormattedDelta[] | undefined => {
      const rows = revision.delta ? revision.delta.filter(d => !!d.prop) : []
      if (rows.length === 0) {
        return undefined
      }
      const delta: FormattedDelta[] = []
      rows.forEach((d: Delta) => {
        const field = d.prop!
        let name: string
        if (field) {
          name = field.name
        } else {
          name = d.path
        }
        if (field.propertyType === PropertyType.DateRange) {
          if (d.path.endsWith('startDate')) {
            name = `${name} ${intl.formatMessage({
              id: 'revisionList.dateRange.startDate',
            })}`
          }
          if (d.path.endsWith('endDate')) {
            name = `${name} ${intl.formatMessage({
              id: 'revisionList.dateRange.endDate',
            })}`
          }
        }
        let oldValue: string | JSX.Element | undefined = undefined
        let newValue: string | JSX.Element | undefined = undefined
        if (d.operation === 'REPLACE' || d.operation === 'REMOVE') {
          oldValue = formatValue(revision, d.oldValue, field)
        }
        newValue = formatValue(revision, d.newValue, field)

        if (!oldValue && !newValue) {
          return
        }
        delta.push({
          name,
          newValue: newValue ?? NOT_SET_STR,
          oldValue: oldValue ?? NOT_SET_STR,
        })
      })
      return delta
    },
    []
  )

  const formatRevisions = useCallback(
    (revisions: Revision[] | undefined): FormattedRevision[] => {
      let formattedRevisions: FormattedRevision[] = []
      if (!revisions) {
        return formattedRevisions
      }

      const latestRevision = revisions[revisions.length - 1]
      if (latestRevision && latestRevision.deletedAt) {
        const name: string = latestRevision.deletedBy?.name
          ? intl.formatMessage(
              {
                id: 'revisionList.updatedBy',
              },
              {
                updatedBy: latestRevision.deletedBy?.name,
              }
            )
          : ''
        formattedRevisions.push({
          datetime: formatDateTimeWithDay(latestRevision.deletedAt),
          name,
        })
      }
      revisions
        .concat()
        .reverse()
        .forEach((row: Revision) => {
          const delta: FormattedDelta[] | undefined = formatDelta(row)
          if (!delta || delta.length === 0) {
            return
          }
          const name: string = row.updatedBy?.name
            ? intl.formatMessage(
                {
                  id: 'revisionList.updatedBy',
                },
                {
                  updatedBy: row.updatedBy.name,
                }
              )
            : ''
          formattedRevisions.push({
            datetime: formatDateTimeWithDay(row.updatedAt),
            name,
            delta,
          })
        })
      return formattedRevisions
    },
    []
  )

  const deltaTable = useCallback((delta: FormattedDelta[]): JSX.Element => {
    return (
      <RevisionTable size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {intl.formatMessage({
                id: 'revisionList.delta.header.propertyName',
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: 'revisionList.delta.header.before',
              })}
            </TableCell>
            <TableCell>
              {intl.formatMessage({
                id: 'revisionList.delta.header.after',
              })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {delta.map((d: FormattedDelta, index: number) => {
            return (
              <TableRow key={`delta${index}`}>
                <TableCell>{d.name}</TableCell>
                <TableCell>{d.oldValue}</TableCell>
                <TableCell>{d.newValue}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </RevisionTable>
    )
  }, [])

  return (
    <>
      {!!formattedRevisions && formattedRevisions.length > 0 && (
        <TableContainer>
          <Table sx={{ width: '100%' }}>
            <TableBody>
              {formattedRevisions.map(
                (revision: FormattedRevision, index: number) => {
                  return (
                    <>
                      <TableRow key={`header${index}`}>
                        <TableCell
                          sx={{
                            padding: !revision.delta
                              ? '10px 2px 15px'
                              : '10px 2px 11px',
                            borderBottom: revision.delta ? 'none' : undefined,
                          }}
                        >
                          <TimeSpan>{revision.datetime}</TimeSpan>
                          <br />
                          <span>{revision.name}</span>
                        </TableCell>
                      </TableRow>
                      {revision.delta && (
                        <TableRow key={`deltas${index}`}>
                          <TableCell sx={{ padding: '0 2px 15px ' }}>
                            <span>{deltaTable(revision.delta)}</span>
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default RevisionListComponent
