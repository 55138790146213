import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { styled } from '@mui/system'
import { Divider as MuiDivider } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

const PageHeaderBar = styled(AppBar)({
  background: colorPalette.monotone[1],
  color: '#000',
  height: '36px',
  borderRadius: '5px',
})

const HeaderBar = (props: React.PropsWithChildren<WrappedComponentProps>) => {
  return (
    <PageHeaderBar position="static" id="headerBar" elevation={0}>
      <Toolbar variant="dense">{props.children}</Toolbar>
    </PageHeaderBar>
  )
}

export default injectIntl(HeaderBar)

export const Header = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

export const HeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
})

export const HeaderGroup = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
})

export const IconGroup = styled('div')({
  display: 'flex',
  margin: '0 5px',
  width: 26,
  height: 26,
})

const Divider = styled(MuiDivider)({
  marginLeft: 2,
  marginRight: 2,
  height: '16px',
  alignSelf: 'center',
})
export const HeaderDivider = () => (
  <Divider orientation="vertical" variant="middle" flexItem={true} />
)
