import { List } from 'immutable'
import { Epic, ofType } from 'redux-observable'
import { Observable, Subscriber } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import API from '../lib/commons/api'
import Auth from '../lib/commons/auth'
import { ProjectBasic } from '../lib/functions/project'
import { UserBasic } from '../lib/functions/user'

export enum NewsType {
  ASSIGNED_TO_PROJECT = 'ASSIGNED_TO_PROJECT',
  ASSIGNED_TO_WBS_ITEM_WATCHER = 'ASSIGNED_TO_WBS_ITEM_WATCHER',
  ASSIGNED_TO_WBS_ITEM = 'ASSIGNED_TO_WBS_ITEM',
  UNASSIGNED_WBS_ITEM = 'UNASSIGNED_WBS_ITEM',
  CHANGED_WBS_ITEM_STATUS = 'CHANGED_WBS_ITEM_STATUS',
  CHANGED_WBS_ITEM_PRIORITY = 'CHANGED_WBS_ITEM_PRIORITY',
  CHANGED_WBS_ITEM_PRIORITY_V2 = 'CHANGED_WBS_ITEM_PRIORITY_V2',
  CHANGED_WBS_ITEM_CRITICAL = 'CHANGED_WBS_ITEM_CRITICAL',
  CHANGED_WBS_ITEM_ASSIGNMENT = 'CHANGED_WBS_ITEM_ASSIGNMENT',
  COMMENTED = 'COMMENTED',
  NEW_MANAGEMENT_NOTICE = 'NEW_MANAGEMENT_NOTICE',
}

export enum NewsGroupForUI {
  COMMENTED = 'COMMENTED',
  CHANGED_WBS_ITEM_STATUS = 'CHANGED_WBS_ITEM_STATUS',
  CHANGED_WBS_ITEM_PRIORITY = 'CHANGED_WBS_ITEM_PRIORITY',
  CHANGED_WBS_ITEM_PRIORITY_V2 = 'CHANGED_WBS_ITEM_PRIORITY_V2',
  ASSIGNED = 'ASSIGNED',
  MANAGEMENT_NOTICE = 'MANAGEMENT_NOTICE',
}

export const getNewsGroupForUI = (
  newsType: NewsType
): NewsGroupForUI | undefined => {
  if (!newsType) {
    return undefined
  }
  switch (newsType) {
    case NewsType.ASSIGNED_TO_PROJECT:
    case NewsType.ASSIGNED_TO_WBS_ITEM_WATCHER:
    case NewsType.ASSIGNED_TO_WBS_ITEM:
    case NewsType.UNASSIGNED_WBS_ITEM:
    case NewsType.CHANGED_WBS_ITEM_ASSIGNMENT:
      return NewsGroupForUI.ASSIGNED
    case NewsType.CHANGED_WBS_ITEM_STATUS:
      return NewsGroupForUI.CHANGED_WBS_ITEM_STATUS
    case NewsType.CHANGED_WBS_ITEM_PRIORITY:
    case NewsType.CHANGED_WBS_ITEM_PRIORITY_V2:
    case NewsType.CHANGED_WBS_ITEM_CRITICAL:
      return NewsGroupForUI.CHANGED_WBS_ITEM_PRIORITY_V2
    case NewsType.COMMENTED:
      return NewsGroupForUI.COMMENTED
    case NewsType.NEW_MANAGEMENT_NOTICE:
      return NewsGroupForUI.MANAGEMENT_NOTICE
    default:
      throw new Error(`Unsupported newsType(${newsType}).`)
  }
}

export interface News {
  uuid: string
  newsType: NewsType
  toUser: UserBasic
  project: ProjectBasic
  message: string
  read: boolean
  readAt: number
  createdAt: number
  notifiedAt: number
  createdBy: UserBasic
}

type State = {
  newsList: List<News>
  hasMoreNews: boolean
}

// Actions
enum ActionType {
  FETCH_NEWS = 'FETCH_NEWS',
  SUBSCRIBE_NEWS = 'SUBSCRIBE_NEWS',
  UNSHIFT_NEWS_LIST = 'UNSHIFT_NEWS_LIST',
  REMOVE_NEWS_LIST = 'REMOVE_NEWS_LIST',
  PUSH_NEWS_LIST = 'PUSH_NEWS_LIST',
  READ_NEWS = 'READ_NEWS',
  ALREADY_READ_NEWS = 'ALREADY_READ_NEWS',
  UPDATE_NEWS = 'UPDATE_NEWS',
  DELETE_NEWS = 'DELETE_NEWS',
  DELETED_NEWS = 'DELETED_NEWS',
}

const FETCH_LIMIT = 10

export const fetchNews = (userUuid: string, offset: number) => ({
  type: ActionType.FETCH_NEWS,
  userUuid,
  offset,
})

export const subscribeNews = (userUuid: string) => ({
  type: ActionType.SUBSCRIBE_NEWS,
  userUuid,
})

export const unshiftNewsList = (userUuid: string, newsList: List<News>) => ({
  type: ActionType.UNSHIFT_NEWS_LIST,
  userUuid,
  newsList,
})

export const updateNews = (userUuid: string, newsList: List<News>) => ({
  type: ActionType.UPDATE_NEWS,
  userUuid,
  newsList,
})

export const removeNewsList = (userUuid: string, newsList: List<News>) => ({
  type: ActionType.REMOVE_NEWS_LIST,
  userUuid,
  newsList,
})

export const pushNewsList = (
  userUuid: string,
  offset: number,
  newsList: List<News>
) => ({
  type: ActionType.PUSH_NEWS_LIST,
  userUuid,
  offset,
  newsList,
})

export const readNews = (userUuid: string, newsUuids: string[]) => ({
  type: ActionType.READ_NEWS,
  userUuid,
  newsUuids,
})

export const readAlreadyNews = (userUuid: string, newsUuids: string[]) => ({
  type: ActionType.ALREADY_READ_NEWS,
  userUuid,
  newsUuids,
})

export const deleteNews = (userUuid: string, newsUuids: string[]) => ({
  type: ActionType.DELETE_NEWS,
  userUuid,
  newsUuids,
})

export const deletedNews = (userUuid: string, newsUuids: string[]) => ({
  type: ActionType.DELETED_NEWS,
  userUuid,
  newsUuids,
})

// Epics
type NewsNotification = {
  userUuid: string
  news: News
}
const subscribeNotification =
  (eventName: string) =>
  (action): Observable<NewsNotification> => {
    let subscriber: Subscriber<NewsNotification>
    const observable = new Observable<NewsNotification>(sub => {
      subscriber = sub
    })
    const tenant = Auth.getCurrentTenant()!
    API.notification.subscribe<News>(
      `${tenant.tenantUuid}/${eventName}/${action.userUuid}`,
      news => {
        subscriber.next({
          userUuid: action.userUuid,
          news: {
            ...news,
          },
        })
      }
    )
    return observable
  }

export const subscribeAddedNewsEpic: Epic<
  ReturnType<typeof subscribeNews>,
  ReturnType<typeof unshiftNewsList>
> = action$ =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_NEWS),
    mergeMap(subscribeNotification('NewsAdded')),
    mergeMap(async notification => {
      try {
        const response = await API.presentational.request(
          'GET',
          '/api/v1/ui_news',
          {
            userUuid: notification.userUuid,
            offset: 0,
            limit: FETCH_LIMIT,
          }
        )
        const newsList = response.json as News[]
        const news = newsList.find(v => v.uuid === notification.news.uuid)
        return {
          userUuid: notification.userUuid,
          news: news || notification.news,
        }
      } catch (err) {
        return {
          userUuid: notification.userUuid,
          news: notification.news,
        }
      }
    }),
    map(({ userUuid, news }) =>
      unshiftNewsList(userUuid, List(news ? [news] : []))
    )
  )

export const subscribeUpdatedNewsEpic: Epic<
  ReturnType<typeof subscribeNews>,
  ReturnType<typeof updateNews>
> = action$ =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_NEWS),
    mergeMap(subscribeNotification('NewsUpdated')),
    map(notification =>
      updateNews(notification.userUuid, List([notification.news]))
    )
  )

export const subscribeDeletedNewsEpic: Epic<
  ReturnType<typeof subscribeNews>,
  ReturnType<typeof removeNewsList>
> = action$ =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_NEWS),
    mergeMap(subscribeNotification('NewsDeleted')),
    map(notification =>
      removeNewsList(notification.userUuid, List([notification.news]))
    )
  )

export const fetchNewsEpic: Epic<
  ReturnType<typeof fetchNews>,
  ReturnType<typeof pushNewsList>
> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_NEWS),
    mergeMap(async action => {
      const query = {
        userUuid: action.userUuid,
        offset: action.offset,
        limit: FETCH_LIMIT,
      }
      const response = await API.presentational.request(
        'GET',
        '/api/v1/ui_news',
        query
      )
      return { query, response }
    }),
    map(result => {
      let newsList
      try {
        newsList = List(result.response.json)
      } catch (e) {
        newsList = []
      }
      return pushNewsList(result.query.userUuid, result.query.offset, newsList)
    })
  )

export const readNewsEpic: Epic<
  ReturnType<typeof readNews>,
  ReturnType<typeof readAlreadyNews>
> = action$ =>
  action$.pipe(
    ofType(ActionType.READ_NEWS),
    mergeMap(async action => {
      const response = await API.presentational.request(
        'POST',
        '/api/v1/ui_news/read',
        {
          newsUuids: action.newsUuids,
        }
      )
      return { action, response }
    }),
    map(result =>
      readAlreadyNews(result.action.userUuid, result.action.newsUuids)
    )
  )

export const deleteNewsEpic: Epic<
  ReturnType<typeof deleteNews>,
  ReturnType<typeof deletedNews>
> = action$ =>
  action$.pipe(
    ofType(ActionType.DELETE_NEWS),
    mergeMap(async action => {
      const response = await API.presentational.request(
        'POST',
        '/api/v1/ui_news/delete',
        {
          newsUuids: action.newsUuids,
        }
      )
      return { action, response }
    }),
    map(result => deletedNews(result.action.userUuid, result.action.newsUuids))
  )

// Reducers
const updateState = (
  state: State,
  userUuid: string,
  newsList: List<News>,
  insertFirst: boolean
) =>
  state.newsList.update(v => {
    newsList.forEach(news => {
      const index = v.findIndex(v => v.uuid === news.uuid)
      if (index === -1) {
        v = v.insert(insertFirst ? 0 : v.size, news)
      } else {
        v = v.set(index, news)
      }
    })
    return v
  })

const deleteNewsState = (state: State, userUuid: string, uuids: List<string>) =>
  state.newsList.update(v => {
    uuids.forEach(uuid => {
      const index = v.findIndex(v => v.uuid === uuid)
      if (index !== -1) {
        v = v.remove(index)
      }
    })
    return v
  })

export const reducer = (
  state: State = { newsList: List(), hasMoreNews: true },
  action: any
): State => {
  switch (action.type) {
    case ActionType.UNSHIFT_NEWS_LIST:
      return {
        ...state,
        newsList: updateState(
          state,
          action.userUuid,
          action.newsList.reverse(),
          true
        ),
      }
    case ActionType.PUSH_NEWS_LIST:
      return {
        ...state,
        newsList: updateState(state, action.userUuid, action.newsList, false),
        hasMoreNews: action.newsList.size === FETCH_LIMIT,
      }
    case ActionType.UPDATE_NEWS:
      return {
        ...state,
        newsList: updateState(state, action.userUuid, action.newsList, false),
      }
    case ActionType.REMOVE_NEWS_LIST:
      return {
        ...state,
        newsList: deleteNewsState(
          state,
          action.userUuid,
          action.newsList.map(news => news.uuid)
        ),
      }
    case ActionType.DELETED_NEWS:
      return {
        ...state,
        newsList: deleteNewsState(state, action.userUuid, List([action.uuid])),
      }
    default:
      return state
  }
}
