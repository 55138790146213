import { styled } from '@mui/system'
import {
  Avatar,
  Divider,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps,
  Typography,
} from '@mui/material'
import { BorderColor } from '../../../../../styles/commonStyles'
import { Variant } from '@mui/material/styles/createTypography'
import { colorPalette } from '../../../../style/colorPallete'

const MenuItemIcon = styled(Avatar)({
  margin: '0px 5px',
  width: 20,
  height: 20,
})
const MenuItemLabel = styled(Typography)({
  margin: 'auto 0px',
})
const DefaultStyledMenuItem = styled(MuiMenuItem)({
  display: 'flex',
  margin: '0px',
  height: '30px',
  '&:hover': {
    color: colorPalette.monotone[4],
    backgroundColor: `${colorPalette.skyBlue[0]} !important`,
  },
})
const StyledDivider = styled(Divider)({
  borderTop: `1px solid ${BorderColor.GREY}`,
  background: 'transparent',
  margin: '5px 0',
})

interface OwnProps {
  options?: any[]
  getOptionValue?: (option: any, index: number) => any
  getOptionLabel?: (option: any, index: number) => string | JSX.Element
  getOptionIconUrl?: (option: any, index: number) => any
  getOptionClassName?: (option: any, index: number) => any
  getOptionDisabled?: (option: any, index: number) => boolean
  onOptionKeyDown?: (event: any) => void
  MenuItem?: typeof MuiMenuItem
  typographyVariant?: Variant
  extraMenuItem?: React.ReactNode[]
}

export type Props = OwnProps & SelectProps

const Select = (props: Props) => {
  const {
    options,
    getOptionValue,
    getOptionLabel,
    getOptionIconUrl,
    getOptionClassName,
    getOptionDisabled,
    onOptionKeyDown,
    typographyVariant,
    extraMenuItem,
    MenuItem,
    ...other
  } = props

  const MenuItemComponent = MenuItem || DefaultStyledMenuItem
  return (
    <MuiSelect
      sx={{
        '& .MuiSelect-select': {
          padding: '2px',
          display: 'inline-flex',
        },
      }}
      disableUnderline={
        props.disableUnderline === undefined ? true : props.disableUnderline
      }
      variant="standard"
      {...other}
    >
      {options?.map((o, i) => {
        const val = getOptionValue ? getOptionValue(o, i) : o.value
        const label = getOptionLabel ? getOptionLabel(o, i) : o.label
        return (
          <MenuItemComponent
            key={val}
            value={val}
            className={
              getOptionClassName ? getOptionClassName(o, i) : undefined
            }
            onKeyDown={onOptionKeyDown}
            disabled={getOptionDisabled && getOptionDisabled(o, i)}
          >
            {getOptionIconUrl?.(o, i) && (
              <MenuItemIcon variant="circular" src={getOptionIconUrl(o, i)} />
            )}
            <MenuItemLabel variant={typographyVariant || 'subtitle2'}>
              {label}
            </MenuItemLabel>
          </MenuItemComponent>
        )
      })}
      {extraMenuItem && <StyledDivider />}
      {extraMenuItem}
    </MuiSelect>
  )
}

export default Select
