import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import { intl } from '../../../../i18n'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { DateBucketType } from '../../charts/model/timeSeries'

type DateBucketToggleButtonGroupProps = {
  value: DateBucketType
  onChange: (_: DateBucketType) => void
}

export const DateBucketToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: DateBucketToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={'DAY'}>
        {intl.formatMessage({
          id: 'day',
        })}
      </ToggleButton>
      <ToggleButton value={'WEEK'}>
        {intl.formatMessage({
          id: 'week',
        })}
      </ToggleButton>
      <ToggleButton value={'MONTH'}>
        {intl.formatMessage({
          id: 'month',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
