import { useCallback } from 'react'
import { styled } from '@mui/system'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepConnector from '@mui/material/StepConnector'
import Typography from '@mui/material/Typography'
import { Radio, StepButton } from '@mui/material'
import { Color, FontSize, TextColor } from '../../../../styles/commonStyles'

const CAPTION_HEIGHT = 30

interface RadioStep {
  label: string
  value: any
  activeColor?: string
  captionFt?: JSX.Element
}

interface Props {
  value: any
  steps: RadioStep[]
  onChange: (value: any) => void
  icon?: JSX.Element
}

const RootDiv = styled('div')({
  width: '100%',
  display: 'flex',
  '& .MuiStepper-root': {
    justifyContent: 'space-between',
  },
  '& .MuiStep-root': {
    flex: 'auto',
    width: '100%',
  },
  overflow: 'visible',
})
const Connector = styled(StepConnector)({
  top: 10,
  left: 'calc(-50% + 7px)',
  right: 'calc(50% + 7px)',
  '& .MuiStepConnector-line': {
    height: 2,
    border: 0,
    backgroundColor: '#E8E8E8',
    borderRadius: 1,
  },
})
const CaptionDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: `${CAPTION_HEIGHT}px`,
})

const RadioStepper = (props: Props) => {
  const { value, steps, icon, onChange } = props

  const getRadioIcon = useCallback(
    (step: RadioStep) => {
      const getOnClick = (step: RadioStep) => () => {
        onChange(step.value)
      }
      return (
        <Radio
          checked={step.value === value}
          style={{
            padding: 0,
            zIndex: 1,
            width: 20,
            height: 20,
            color:
              step.value === value
                ? step.activeColor ?? Color.MAIN
                : Color.DISABLED,
          }}
          onClick={getOnClick(step)}
        />
      )
    },
    [props.value, props.onChange]
  )

  return (
    <RootDiv>
      <Stepper
        alternativeLabel={true}
        nonLinear={true}
        connector={<Connector style={{ top: 10 + CAPTION_HEIGHT }} />}
      >
        {steps.map((step, index) => {
          return (
            <Step key={`${step.value}-${index}`}>
              <CaptionDiv>{step.captionFt}</CaptionDiv>
              <StepButton icon={getRadioIcon(step)} />
              <Typography
                align="center"
                style={{
                  fontWeight: 600,
                  fontSize: `${FontSize.SMALL}px`,
                  color: value === step.value ? Color.MAIN : TextColor.GREY,
                }}
              >
                {step.label}
              </Typography>
            </Step>
          )
        })}
      </Stepper>
      {icon && (
        <Stepper style={{ width: 20, overflow: 'hidden' }}>
          <Step key="delay-icon">
            <div style={{ height: CAPTION_HEIGHT }} />
            {icon}
            <Typography>&nbsp;</Typography>
          </Step>
        </Stepper>
      )}
    </RootDiv>
  )
}

export default RadioStepper
