import _ from 'lodash'
import { ValueSetterParams } from 'ag-grid-community'

import store from '../../../store'
import { requireSave } from '../../../store/requiredSaveData'
import objects from '../../../utils/objects'
import { ListRow } from '../../containers/BulkSheetView/model'

const setEditedValue = <TRow extends ListRow>(
  params: ValueSetterParams<TRow>
) => {
  const field = params.colDef.field || params.colDef.colId
  if (!field) return
  if (!params.data.editedData) {
    params.data.editedData = { [field]: params.oldValue }
  } else if (!params.data.editedData.hasOwnProperty(field)) {
    params.data.editedData[field] = params.oldValue
  }
}

export const commonValueSetter = <TRow extends ListRow>(
  params: ValueSetterParams<TRow>,
  individualSetValue?: (params: ValueSetterParams<TRow>) => void
) => {
  const field = params.colDef.field || params.colDef.colId
  if (
    !field ||
    (!params.oldValue && !params.newValue) ||
    params.oldValue === params.newValue
  ) {
    return false
  }
  individualSetValue && individualSetValue(params)
  objects.setValue(params.data, field, params.newValue)
  params.data.edited = true
  setEditedValue(params)
  store.dispatch(requireSave())
  return true
}

export const defaultValueSetter = <TRow extends ListRow>(
  params: ValueSetterParams<TRow>
) => {
  return commonValueSetter(params)
}
