import React from 'react'
import AlertDialog from './AlertDialog'
import { intl } from '../../../i18n'

const CancelConfirmDialog = (props: {
  open: boolean
  onConfirm: () => void
  onClose: () => void
}) => {
  return (
    <AlertDialog
      isOpen={props.open}
      title={intl.formatMessage({ id: 'dialog.title.cancelInput' })}
      message={intl.formatMessage({
        id: 'dialog.message.cancelInitializeScreen',
      })}
      submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
      submitHandler={props.onConfirm}
      closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
      closeHandler={props.onClose}
    />
  )
}

export default CancelConfirmDialog
