import { Box, BoxProps, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import TimelineIcon from '@mui/icons-material/Timeline'
import { useCallback } from 'react'
import {
  ProgressReportChartConfig,
  ProgressReportViewConfig,
  saveChartUIState,
} from '../..'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../..'
import store from '../../../../../store'
import {
  FunctionLayer,
  pushFunctionLayer,
} from '../../../../../store/functionLayer'
import { BackgroundColor } from '../../../../../styles/commonStyles'
import { ProgressReportSearchConditionVO } from '../../../../../domain/value-object/ProgressReportSearchConditionVO'

const StyledIconButton = styled(IconButton)({
  background: BackgroundColor.BLUE_GRADATION,
  width: '24px',
  height: '24px',
  padding: 0,
  boxShadow: '0px 1px 1px #00000033',
})
const Icon = styled(TimelineIcon)({
  color: '#ffffff',
  width: '14px',
  height: '14px',
})

type OwnProps = {
  searchCondition: ProgressReportSearchConditionVO
  viewConfig: ProgressReportViewConfig
  chartConfig?: ProgressReportChartConfig
  projectUuid?: string | undefined
}

export const OpenChartButton = ({
  searchCondition,
  viewConfig,
  chartConfig = {} as ProgressReportChartConfig,
  projectUuid,
  classes,
  sx,
}: OwnProps & BoxProps) => {
  const onClick = useCallback(async () => {
    const projectCode = window.location.pathname.split('/')[2]
    await saveChartUIState(projectUuid, {
      ...searchCondition,
      ...viewConfig,
      ...chartConfig,
    })
    const newLayer: FunctionLayer = {
      externalId:
        APPLICATION_FUNCTION_EXTERNAL_ID.PROJECT_PROGRESS_REPORT_CHART,
      code: projectCode,
      onPreOpenInNew: async () => {
        await saveChartUIState(projectUuid, {
          ...searchCondition,
          ...viewConfig,
          ...chartConfig,
        })
      },
    }
    if (searchCondition.ticketListUuid) {
      newLayer['params'] = { ticketListUuid: searchCondition.ticketListUuid }
    } else if (searchCondition.rootWbsItemUuid) {
      newLayer['params'] = {
        rootWbsItemUuid: searchCondition.rootWbsItemUuid,
      }
    }
    store.dispatch(pushFunctionLayer(newLayer))
  }, [searchCondition, viewConfig])
  return (
    <Box sx={sx}>
      <StyledIconButton
        onClick={onClick}
        aria-describedby="progress-report-show-dialog"
      >
        <Icon color="action" />
      </StyledIconButton>
    </Box>
  )
}
