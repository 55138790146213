import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import * as d3 from 'd3'
import { ProjectDetail } from '../../../../lib/functions/project'
import { useProgressReportData } from './hooks/progressReportData'
import { intl } from '../../../../i18n'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import { FontSize } from '../../../../styles/commonStyles'
import { ProgressDashboardParameter } from '../hooks/useProgressDashboardParameter'
import {
  NoData,
  WidgetAlternative,
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../components'
import { useChartDimensions } from '../hooks/chart/useChartDimensions'
import {
  ChartContainer,
  TodayVerticalLine,
  XAxis,
  YAxisWithLine,
} from '../components/chart'
import { LineChart } from '../components/chart/LineChart'
import { useDateXTicks } from '../hooks/chart/useXTicks'
import { colorPalette } from '../../../style/colorPallete'
import { ProjectReportConfig } from '../model/config'

type Props = {
  project: ProjectDetail
  teamUuid?: string
} & Pick<
  ProjectReportConfig,
  'aggregateRoot' | 'aggregateField' | 'workloadUnit'
>

export const ProgressReport = ({
  project,
  aggregateRoot,
  aggregateField,
  workloadUnit,
  teamUuid,
}: Props) => {
  const [
    ref,
    { width, height, marginTop, marginLeft, boundedWidth, boundedHeight },
  ] = useChartDimensions({
    height: 300,
    marginLeft: 50,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 20,
  })

  const { from, to } = useMemo(() => {
    const from =
      aggregateRoot?.scheduledDate.startDate || project.scheduledDate.startDate
    const to =
      aggregateRoot?.scheduledDate.endDate || project.scheduledDate.endDate
    return {
      from: new Date(from),
      to: new Date(to),
    }
  }, [
    aggregateRoot?.scheduledDate.endDate,
    aggregateRoot?.scheduledDate.startDate,
    project.scheduledDate.endDate,
    project.scheduledDate.startDate,
  ])

  const { xTicks, intervalType } = useDateXTicks(from, to, boundedWidth, 30)

  const { data, fetching } = useProgressReportData(
    project,
    xTicks,
    aggregateRoot,
    aggregateField,
    workloadUnit,
    teamUuid
  )

  const xScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([xTicks[0], xTicks[xTicks.length - 1]])
        .range([0, boundedWidth]),
    [boundedWidth, xTicks]
  )

  const { yScale, yTicks } = useMemo(() => {
    const yMax = Math.max(
      ...[
        data?.deliverableActualLog || [],
        data?.deliverableScheduledLog || [],
        data?.taskActualLog || [],
        data?.taskScheduledLog || [],
      ].flatMap(arr => arr.map(v => v.y))
    )
    const yScale = d3
      .scaleLinear()
      .domain([0, yMax * 1.1])
      .range([boundedHeight, 0])
    return {
      yScale,
      yTicks: yScale.ticks(10),
    }
  }, [boundedHeight, data])

  const xAxisPoints = useMemo(() => {
    const getLabel = (x: Date): string => {
      switch (intervalType) {
        case 'DAY':
        case 'WEEK':
          return `${x.getMonth() + 1}/${x.getDate()}`
        case 'MONTH':
        case 'THREE_MONTH':
        case 'HALF_YEAR':
          return `${x.getFullYear() % 100}/${x.getMonth() + 1}`
        case 'YEAR':
          return `${x.getFullYear()}`
      }
    }
    return xTicks.map(x => {
      return {
        scale: xScale(x),
        label: getLabel(x),
      }
    })
  }, [intervalType, xScale, xTicks])
  const yAxisPoints = useMemo(
    () =>
      yTicks.map(y => ({
        scale: yScale(y),
        label: `${y}`,
      })),
    [yScale, yTicks]
  )

  const message = useCallback((id: string) => {
    return intl.formatMessage({ id })
  }, [])

  return (
    <ProgressReportWidgetAreaWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'progressReport',
        })}
      </WidgetTitle>
      <ProgressReportWidgetArea>
        <Box
          sx={{
            width: '100%',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '5px 0 3px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Legend
              borderStyle="dashed"
              color={colorPalette.blue[5]}
              label={
                message('wbsItemType.deliverable') +
                message('progressReport.endDate') +
                message('progressReport.scheduled')
              }
            />
            <Legend
              borderStyle="solid"
              color={colorPalette.blue[5]}
              label={
                message('wbsItemType.deliverable') +
                message('progressReport.actual')
              }
            />
            <Legend
              borderStyle="dashed"
              color={colorPalette.green[5]}
              label={
                message('wbsItemType.task') +
                message('progressReport.endDate') +
                message('progressReport.scheduled')
              }
            />
            <Legend
              borderStyle="solid"
              color={colorPalette.green[5]}
              label={
                message('wbsItemType.task') + message('progressReport.actual')
              }
            />
          </Box>
        </Box>
        <div ref={ref} style={{ width: '100%', height: '300px' }}>
          {fetching ? (
            <WidgetAlternative>
              <CircularProgress />
            </WidgetAlternative>
          ) : !data ? (
            <WidgetAlternative>
              <NoData />
            </WidgetAlternative>
          ) : (
            <ChartContainer
              width={width}
              height={height}
              marginLeft={marginLeft}
              marginTop={marginTop}
            >
              <XAxis
                points={xAxisPoints}
                boundedHeight={boundedHeight}
                boundedWidth={boundedWidth}
              />
              <YAxisWithLine points={yAxisPoints} boundedWidth={boundedWidth} />
              <TodayVerticalLine
                xScale={xScale}
                boundedHeight={boundedHeight}
              />
              <LineChart<Date, number>
                xScale={xScale}
                yScale={yScale}
                points={data.deliverableActualLog}
                color={colorPalette.blue[5]}
              />
              <LineChart<Date, number>
                xScale={xScale}
                yScale={yScale}
                points={data.deliverableScheduledLog}
                color={colorPalette.blue[5]}
                lineStyle="DASHED"
              />
              <LineChart<Date, number>
                xScale={xScale}
                yScale={yScale}
                points={data.taskActualLog}
                color={colorPalette.green[5]}
              />
              <LineChart<Date, number>
                xScale={xScale}
                yScale={yScale}
                points={data.taskScheduledLog}
                color={colorPalette.green[5]}
                lineStyle="DASHED"
              />
            </ChartContainer>
          )}
        </div>
      </ProgressReportWidgetArea>
    </ProgressReportWidgetAreaWrapper>
  )
}

const ProgressReportWidgetAreaWrapper = styled(WidgetWithTitleWrapper)({
  width: '48%',
})

const ProgressReportWidgetArea = styled(WidgetArea)({
  flexDirection: 'column',
})

const Legend = ({
  borderStyle,
  color,
  label,
}: {
  borderStyle: string
  color: string
  label: string
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 3px' }}>
      <Box
        style={{
          width: '20px',
          height: 0,
          borderTop: `2px ${borderStyle} ${color}`,
          margin: '0 2px',
        }}
      />
      <Typography sx={{ fontSize: FontSize.SMALL }}>{label}</Typography>
    </Box>
  )
}
