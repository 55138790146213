import VOBase from '../base'
import numeral from 'numeral'

export interface IWorkload {
  hour?: number
  day?: number
  month?: number
}

interface IFormatedWorkload {
  hour: string
  day: string
  month: string
}

interface Standard {
  monthlyWorkingDays: number
  daylyWorkingHours: number
}

const DEFAULT_DISPLAY_FORMAT = '0,0.00'

const formatNumber = (val: number, displayFormat?: string) => {
  // Adjust floating number error E.g. Convert 123.449999998 into 123.45
  if (val > 0) {
    val += 0.000001
  } else if (val < 0) {
    val -= 0.000001
  }
  const num = numeral(val)
  if (num.value() === null || num.value === undefined) {
    return val
  }
  return num.format(displayFormat || DEFAULT_DISPLAY_FORMAT)
}

export default class WorkloadVO extends VOBase {
  private readonly value: IWorkload

  constructor(hour: number | undefined, standard: Standard) {
    super()
    if (Number.isFinite(hour) && hour! < 0) {
      throw new Error(
        'Can not create WorkloadVO instance because hour is minus.'
      )
    }
    if (hour === undefined) {
      this.value = {
        hour: undefined,
        day: undefined,
        month: undefined,
      }
      return
    }

    const { monthlyWorkingDays, daylyWorkingHours } = standard
    this.value = {
      hour: WorkloadVO.floorNumber(hour),
      day: WorkloadVO.floorNumber(Number(hour / daylyWorkingHours)),
      month: WorkloadVO.floorNumber(
        Number(hour / (monthlyWorkingDays * daylyWorkingHours))
      ),
    }
  }

  static isNumber(value?: number): boolean {
    return value !== undefined && value !== null && Number.isFinite(value)
  }

  static fromHour(hour: number | undefined, standard: Standard): WorkloadVO {
    let flooredHour = WorkloadVO.isNumber(hour)
      ? WorkloadVO.floorNumber(hour!)
      : undefined
    return new WorkloadVO(flooredHour, standard)
  }
  static fromDay(day: number | undefined, standard: Standard): WorkloadVO {
    let flooredDay = WorkloadVO.isNumber(day)
      ? WorkloadVO.floorNumber(day!)
      : undefined
    return new WorkloadVO(
      flooredDay && Number.isFinite(flooredDay)
        ? flooredDay * standard.daylyWorkingHours
        : flooredDay,
      standard
    )
  }
  static fromMonth(month: number | undefined, standard: Standard): WorkloadVO {
    let flooredMonth = WorkloadVO.isNumber(month)
      ? WorkloadVO.floorNumber(month!)
      : undefined
    return new WorkloadVO(
      flooredMonth && Number.isFinite(flooredMonth)
        ? flooredMonth *
          standard.monthlyWorkingDays *
          standard.daylyWorkingHours
        : flooredMonth,
      standard
    )
  }
  static floorNumber(value: number) {
    return Math.floor(value * 100) / 100
  }

  getValue = () => {
    return this.getHour()
  }
  getHour = () => {
    return this.value.hour
  }
  getDay = () => {
    return this.value.day
  }
  getMonth = () => {
    return this.value.month
  }
  format = (): IFormatedWorkload => {
    const { hour, day, month } = this.value
    return {
      hour: Number.isFinite(hour)
        ? formatNumber(hour!, DEFAULT_DISPLAY_FORMAT)
        : '',
      day: Number.isFinite(day)
        ? formatNumber(day!, DEFAULT_DISPLAY_FORMAT)
        : '',
      month: Number.isFinite(month)
        ? formatNumber(month!, DEFAULT_DISPLAY_FORMAT)
        : '',
    }
  }
  serialize = () => this.getHour()
}
