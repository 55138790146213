import TaskActualWorkList from '../../../../components/tables/TaskActualWorkList'

type TaskActualWorkTabPanelProps = {
  uuid: string
}
export const TaskActualWorkTabPanel = ({
  uuid,
}: TaskActualWorkTabPanelProps) => {
  return <TaskActualWorkList taskUuid={uuid} showOnlyList={true} />
}
