import { ClickAwayListener, Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { FontSize } from '../../../../../styles/commonStyles'
import { useState } from 'react'
import { ColDef } from 'ag-grid-community'
import SortColumnListPopper from './SortColumnListPopper'
import { colorPalette } from '../../../../style/colorPallete'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'

type Props = {
  onDeleteSortedColumn?: (column: string | ColDef<any>) => void
  onDeleteSortedAllColumns: (() => void) | undefined
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  sortColumnsState: SortedColumnState[]
  getDisplayColumnName?: (colDef: ColDef) => string | undefined
}

const SortButton = ({
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  sortColumnsState,
  getDisplayColumnName,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element>()

  const handleClose = () => {
    setAnchorEl(undefined)
  }
  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div style={{ minWidth: 'fit-content' }}>
          <button
            style={{
              background: 'transparent',
              border: 0,
              fontSize: FontSize.MEDIUM,
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'row',
              padding: '5px 10px',
              cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
            }}
            onClick={event => setAnchorEl(event.currentTarget)}
          >
            <Typography sx={{ color: colorPalette.monotone[5] }}>
              {intl.formatMessage({ id: 'wbs.header.sort.title' })}
            </Typography>
            <Typography
              sx={{
                color:
                  sortColumnsState.length >= 1
                    ? colorPalette.skyBlue[7]
                    : colorPalette.monotone[3],
                cursor: sortColumnsState.length >= 1 ? 'pointer' : '',
              }}
            >
              {sortColumnsState.length >= 1
                ? sortColumnsState.length.toString()
                : intl.formatMessage({ id: 'wbs.header.none.label' })}
            </Typography>
          </button>
        </div>
      </ClickAwayListener>
      {sortColumnsState.length >= 1 && (
        <SortColumnListPopper
          anchorEl={anchorEl}
          placement={'bottom-start'}
          onDeleteSortedColumn={onDeleteSortedColumn}
          onDeleteSortedAllColumns={onDeleteSortedAllColumns}
          onChangeSortColumnState={onChangeSortColumnState}
          sortColumnsState={sortColumnsState}
          getDisplayColumnName={getDisplayColumnName}
        />
      )}
    </>
  )
}

export default SortButton
