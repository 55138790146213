import ProjectApi from '../../../lib/functions/project'
import React from 'react'
import { intl } from '../../../i18n'
import AssessmentIcon from '@mui/icons-material/AssessmentRounded'
import { open } from '../../router'
import {
  BlueActionIconButton,
  blueActionIconStyle,
} from '../icons/IconButton/BlueActionIconButton'

export const SprintReportIconButton = ({
  projectUuid,
  rootWbsItemUuid,
}: {
  projectUuid: string
  rootWbsItemUuid?: string
}) => {
  return (
    <BlueActionIconButton
      title={intl.formatMessage({
        id: 'report.showSprintReport',
      })}
      onClick={async () => {
        const projectResponse = await ProjectApi.getDetail({
          uuid: projectUuid,
        })
        open(
          `/sprintReport/${projectResponse.json.code}?rootWbsItem=${rootWbsItemUuid}`,
          undefined,
          undefined,
          true
        )
      }}
    >
      <AssessmentIcon style={blueActionIconStyle} />
    </BlueActionIconButton>
  )
}
