import { useCallback } from 'react'
import { StatusKanbanRepository } from '../../applications/ports/KanbanRepositroy'
import api from '../../lib/commons/api'
import { WbsItemSearchConditionApiRequest } from '../model/wbsItemSearchConditionApiRequest'

export const useStatusKanbanRepository = (): StatusKanbanRepository => {
  const get = useCallback(
    async (searchCondition: WbsItemSearchConditionApiRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/kanban/status',
        searchCondition
      )
      return response.json
    },
    []
  )

  const getCount = useCallback(
    async (searchCondition: WbsItemSearchConditionApiRequest) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/kanban/status/count',
        searchCondition
      )
      return response.json
    },
    []
  )

  return {
    get,
    getCount,
  }
}
