import React, { useCallback, useEffect, useState } from 'react'
import MenuTop from './MenuTop'
import { Box, ClickAwayListener, Divider, List } from '@mui/material'
import DirectoryMenu from './MenuList/DirectoryMenu'
import { Cockpit, Directory } from '../../../../lib/commons/appFunction'
import store, { AllState } from '../../../../store'
import { getFunctionByPath } from '../../../pages'
import { addScreenMessage, MessageLevel } from '../../../../store/messages'
import { intl } from '../../../../i18n'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { EXPANDED_MENU_WIDTH } from '../index'
import { ProjectDetail } from '../../../../lib/functions/project'
import AssignedProject from './AssignedProject'
import { _ } from 'ag-grid-community'
import { BackgroundColor } from '../../../../styles/commonStyles'
import { closeSideMenu } from '../../../../store/sideMenu'
import { isProduction } from '../../../../utils/urls'
import { colorPalette } from '../../../style/colorPallete'

type Props = RouteComponentProps & StateProps

type StateProps = {
  projectUuid?: string
  directories: Directory[]
  hasRequiredSaveData: boolean
}

const ExpandedMenu = ({ projectUuid, directories }: Props) => {
  const [menu, setMenu] = useState<Directory[]>([])
  const [tenantMenu, setTenantMenu] = useState<Directory[]>([])

  const onClose = useCallback(() => {
    store.dispatch(closeSideMenu())
  }, [])

  useEffect(() => {
    const component = getFunctionByPath(window.location.pathname)
    const code = window.location.pathname.split('/').slice(-1)[0]
    if (component && component.cockpit === Cockpit.Project && code) {
      try {
        return switchMenu(Cockpit.Project)
      } catch {
        store.dispatch(
          addScreenMessage(component.externalId, {
            type: MessageLevel.WARN,
            title: intl.formatMessage({
              id: 'sideMenu.codeNotFound.title',
            }),
          })
        )
      }
    }
    if (component && component.cockpit === Cockpit.Global) {
      menu.length === 0 &&
        switchMenu(projectUuid ? Cockpit.Project : Cockpit.Tenant)
      return
    }
  }, [directories, window.location.pathname])

  const switchMenu = (cockpit: Cockpit) => {
    const directory = directories.filter(item => {
      return item.name === cockpit
    })
    if (directory.length > 0) {
      setMenu(directory[0].directories)
    }
  }

  const getTenantMenu = useCallback(() => {
    const directory = directories.filter(item => {
      return item.name === Cockpit.Tenant
    })
    if (directory.length > 0) {
      setTenantMenu(directory[0].directories)
    }
  }, [tenantMenu])

  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <div
          style={{
            background: BackgroundColor.WHITE,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            width: `${EXPANDED_MENU_WIDTH}px`,
          }}
        >
          <MenuTop menu={tenantMenu} onClick={getTenantMenu} />
          <Box
            sx={{
              width: '240px',
              gap: '16px',
              padding: '16px 0',
              borderBottom: `1px soild ${colorPalette.monotone[2]}`,
            }}
          >
            <AssignedProject
              getOptionValue={o => o.uuid}
              getOptionLabel={o => o.displayName}
              getOptionIconUrl={o => o.iconUrl}
              projectUuid={projectUuid}
            />
            <Divider
              sx={{ margin: '0 10px', paddingTop: '16px', opacity: 0.5 }}
            />
          </Box>
          <List
            component="nav"
            sx={{
              width: '100%',
              overflow: 'scroll',
              flex: 1,
              padding: '8px 16px !important',
            }}
          >
            {menu.map(dir => (
              <DirectoryMenu key={dir.uuid} directory={dir} />
            ))}
          </List>
        </div>
      </ClickAwayListener>
    </>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
  directories: state.appFunction.directories,
  hasRequiredSaveData: state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(withRouter(ExpandedMenu))
