import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import store, { AllState } from '../../../store'
import { connect } from 'react-redux'
import AlertDialog from './AlertDialog'
import { clearAlert } from '../../../store/globalAlert'

interface Props extends RouteComponentProps, WrappedComponentProps {
  message?: string
  init?: () => void
  action?: () => void
  cancel?: () => void
}

class GlobalAlert extends React.Component<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    const { message, init } = this.props
    if (!prevProps.message && !!message) {
      init && init()
    }
  }

  private submit = () => {
    this.props.action && this.props.action()
    store.dispatch(clearAlert())
  }

  private close = () => {
    this.props.cancel && this.props.cancel()
    store.dispatch(clearAlert())
  }

  render() {
    const { message, cancel, intl } = this.props
    return (
      <AlertDialog
        isOpen={!!message}
        message={message}
        submitButtonTitle={
          !!cancel ? intl.formatMessage({ id: 'dialog.ok' }) : undefined
        }
        submitHandler={!!cancel ? this.submit : undefined}
        closeButtonTitle={
          !!cancel
            ? intl.formatMessage({ id: 'dialog.cancel' })
            : intl.formatMessage({ id: 'dialog.ok' })
        }
        closeHandler={!!cancel ? this.close : this.submit}
      />
    )
  }
}

const mapStateToProps = (state: AllState) => {
  return state.globalAlert.alertAction || {}
}

export default connect(mapStateToProps)(injectIntl(withRouter(GlobalAlert)))
