import { forwardRef, useImperativeHandle, useState } from 'react'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'

export const ServerSideUuidFilter = forwardRef((props: IFilterParams, ref) => {
  const [filteredIds, setFilteredIds] = useState<string[] | undefined>()

  // Filter component for quick filter
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.added) return true
        if (!filteredIds) return true
        return params.node.id && filteredIds.includes(params.node.id)
      },

      isFilterActive() {
        return !!filteredIds
      },

      getModel() {
        return filteredIds
      },

      setModel(model?: string[]) {
        setFilteredIds(model)
      },
    }
  })
  return <></>
})
