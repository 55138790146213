import { useEffect, useState } from 'react'
import { useCustomEnumRepository } from '../../../../../../services/adaptors/customEnumRepositoryAdaptor'
import { SelectOption } from '../../../../../components/select/model'

export type RefinementSelectOptions = {
  detectionPhases: SelectOption<string>[]
  environments: SelectOption<string>[]
  domains: SelectOption<string>[]
  featureTypes: SelectOption<string>[]
  impacts: SelectOption<string>[]
  importances: SelectOption<string>[]
  refinementCauses: SelectOption<string>[]
}
export const useRefinementSelectOptions = (projectUuid: string) => {
  const [detectionPhases, setDetectionPhases] = useState<
    SelectOption<string>[]
  >([])
  const [environments, setEnvironments] = useState<SelectOption<string>[]>([])
  const [domains, setDomains] = useState<SelectOption<string>[]>([])
  const [featureTypes, setFeatureTypes] = useState<SelectOption<string>[]>([])
  const [impacts, setImpacts] = useState<SelectOption<string>[]>([])
  const [importances, setImportances] = useState<SelectOption<string>[]>([])
  const [refinementCauses, setRefinementCauses] = useState<
    SelectOption<string>[]
  >([])
  const { fetchAsVO } = useCustomEnumRepository()
  useEffect(() => {
    const fn = async () => {
      const [
        detectionPhaseResponse,
        environmentResponse,
        domainResponse,
        featureTypeResponse,
        impactResponse,
        importanceResponse,
        refinementCauseResponse,
      ] = await Promise.all([
        fetchAsVO('DETECTION_PHASE', projectUuid),
        fetchAsVO('ENVIRONMENT', projectUuid),
        fetchAsVO('DOMAIN', projectUuid),
        fetchAsVO('FEATURE_TYPE', projectUuid),
        fetchAsVO('IMPACT', projectUuid),
        fetchAsVO('IMPORTANCE', projectUuid),
        fetchAsVO('REFINEMENT_CAUSE', projectUuid),
      ])
      setDetectionPhases(detectionPhaseResponse)
      setEnvironments(environmentResponse)
      setDomains(domainResponse)
      setFeatureTypes(featureTypeResponse)
      setImpacts(impactResponse)
      setImportances(importanceResponse)
      setRefinementCauses(refinementCauseResponse)
    }
    fn()
  }, [fetchAsVO, projectUuid])
  return {
    detectionPhases,
    environments,
    domains,
    featureTypes,
    impacts,
    importances,
    refinementCauses,
  }
}
