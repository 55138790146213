import { useState } from 'react'
import { ReportContext } from '..'
import { intl } from '../../../../i18n'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import {
  ChartConfigurationBase,
  SearchConditionBase,
  ViewConfigurationBase,
} from '../../../../lib/functions/report'
import SearchConditionButton from '../../../components/buttons/SearchConditionButton'
import SavedUIStateDialog from '../../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'

export interface ReportUIState<
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<any>
> {
  searchCondition: S
  viewConfiguration: V
  chartConfiguration: C
}

export const ReportSavedCondition = <
  S extends SearchConditionBase,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<any>
>(props: {
  applicationFunctionUuid: string
  uiStateKey: UiStateKey
  ctx: ReportContext<any, any, S, V, C, any>
}) => {
  const { applicationFunctionUuid, uiStateKey, ctx } = props
  const [open, setOpen] = useState(false)
  const onSelect = (savedUiState: SavedUIState) => {
    ctx.setState(
      {
        ...ctx.state,
        searchCondition: {
          ...ctx.options.initSearchCondition(),
          ...ctx.options.getSearchConditionFromUrlQuery(
            savedUiState.UIState.searchCondition
          ),
        },
        viewConfig: {
          ...ctx.options.initViewConfiguration(),
          ...ctx.options.getViewConfigFromUrlQuery(
            savedUiState.UIState.viewConfiguration
          ),
        },
        chartConfig: {
          ...ctx.options.initChartConfiguration(),
          ...ctx.options.getChartConfigFromUrlQuery(
            savedUiState.UIState.chartConfiguration
          ),
        },
      },
      () => {
        setOpen(false)
        ctx.fetch()
      }
    )
  }
  const currentUIState = {
    searchCondition: ctx.options.fromSearchConditionToPlainObject(
      ctx.state.searchCondition
    ),
    viewConfiguration: ctx.options.fromViewConfigToPlainObject(
      ctx.state.viewConfig
    ),
    chartConfiguration: ctx.options.fromChartConfigToPlainObject(
      ctx.state.chartConfig
    ),
  }

  return (
    <div>
      <SearchConditionButton onClick={() => setOpen(true)} />
      <SavedUIStateDialog
        applicationFunctionUuid={applicationFunctionUuid}
        open={open}
        onClose={() => setOpen(false)}
        title={intl.formatMessage({ id: 'report.specification' })}
        sharable={true}
        uiStateKey={uiStateKey}
        onSelect={onSelect}
        currentUIState={currentUIState}
      />
    </div>
  )
}
