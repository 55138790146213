import { styled } from '@mui/system'
import MuiCloseIcon from '@mui/icons-material/CloseRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { IconButton } from './IconButton'

type IconSize = 'xs' | 's' | 'm'
const Icon = styled(MuiCloseIcon)<{ iconSize?: IconSize }>(({ iconSize }) => {
  switch (iconSize) {
    case 'xs':
      return {
        width: '12px',
        height: '12px',
      }
    case 's':
      return {
        width: '16px',
        height: '16px',
      }
    case 'm':
      return {
        width: '20px',
        height: '20px',
      }
  }
})
export const CloseIcon = injectIntl(
  (
    props: {
      onClose: () => void
      size?: IconSize
    } & WrappedComponentProps
  ) => {
    const { intl, onClose, size } = props
    return (
      <IconButton
        onClick={onClose}
        size={'small'}
        title={intl.formatMessage({
          id: 'close',
        })}
      >
        <Icon iconSize={size || 's'} color="action" />
      </IconButton>
    )
  }
)
