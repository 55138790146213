import { useCallback, useState } from 'react'

export const useDialog = () => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [cancel, setCancel] = useState<boolean>(false)

  const [uiState, setUiState] = useState<boolean>(false)

  const [multiSelect, setMultiSelect] = useState<boolean>(false)

  const openDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true)
  }, [])

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false)
  }, [])

  const openCancel = useCallback(() => {
    setCancel(true)
  }, [])

  const closeCancel = useCallback(() => {
    setCancel(false)
  }, [])

  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  const openMultiSelect = useCallback(() => {
    setMultiSelect(true)
  }, [])

  const closeMultiSelect = useCallback(() => {
    setMultiSelect(false)
  }, [])

  return {
    deleteConfirmation,
    openDeleteConfirmation,
    closeDeleteConfirmation,

    cancel,
    openCancel,
    closeCancel,

    uiState,
    openUiState,
    closeUiState,

    multiSelect,
    openMultiSelect,
    closeMultiSelect,
  }
}
