import { useMemo } from 'react'
import * as d3 from 'd3'
import { DateTickIntervalType } from './useXTicks'
import { dateOffset } from '../../utils/date'

export const useXDateScale = (
  xTicks: Date[],
  boundedWidth: number,
  offset?: { intervalType: DateTickIntervalType; before: number; after: number }
) => {
  const domain = useMemo(() => {
    const offsetBefore = offset?.before || 0
    const offsetAfter = offset?.after || 0
    const intervalType = offset?.intervalType || 'DAY'
    const from = dateOffset(xTicks[0], -1 * offsetBefore, intervalType)
    const to = dateOffset(xTicks[xTicks.length - 1], offsetAfter, intervalType)
    return [from, to]
  }, [offset?.after, offset?.before, offset?.intervalType, xTicks])

  const xScale = useMemo(
    () => d3.scaleTime().domain(domain).range([0, boundedWidth]),
    [boundedWidth, domain]
  )
  return xScale
}
