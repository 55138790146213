import { styled } from '@mui/material'
import { toLabel, WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import { useMemo } from 'react'
import { getWbsItemStatusDeepColorCode } from '../../../../lib/functions/wbsItem'

type WbsStatusLabelProps = {
  status: WbsItemStatus
}

export const WbsStatusLabel = ({ status }: WbsStatusLabelProps) => {
  const label = useMemo(() => toLabel(status), [status])
  return <LabelWrapper status={status}>{label}</LabelWrapper>
}

const LabelWrapper = styled('div')(({ status }: WbsStatusLabelProps) => {
  const backgroundColor = useMemo(
    () => getWbsItemStatusDeepColorCode(status),
    [status]
  )
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    fontSize: '11px',
    fontWeight: 600,
    width: '50px',
    borderRadius: '4px',
    backgroundColor,
  }
})
