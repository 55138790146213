import _ from 'lodash'
import Heading from '@tiptap/extension-heading'
import Blockquote from '@tiptap/extension-blockquote'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import StarterKit from '@tiptap/starter-kit'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import { Editor } from '@tiptap/react'
import lowlight from 'lowlight'
import { StyleType } from '../../../../utils/markdown'

export const getEditorExtensions = () => {
  return [
    StarterKit.configure({
      codeBlock: false,
      heading: false,
      blockquote: false,
      horizontalRule: false,
    }),
    CustomHeading,
    CustomBlockquote,
    Link,
    Image.configure({
      inline: true,
    }),
    CustomCodeBlock.configure({
      lowlight,
    }),
    CustomHorizontalRule,
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
    Table,
    TableCell,
    TableHeader,
    TableRow,
  ]
}

const CustomCodeBlock = CodeBlockLowlight.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-e': () => this.editor.commands.toggleCodeBlock(),
    }
  },
  renderHTML({ HTMLAttributes }) {
    return ['pre', ['pre', ['code', HTMLAttributes, 0]]]
  },
})

const CustomBlockquote = Blockquote.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-.': () => this.editor.commands.toggleBlockquote(),
    }
  },
})

const CustomHeading = Heading.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-1': () => this.editor.commands.toggleHeading({ level: 1 }),
      'Mod-Shift-2': () => this.editor.commands.toggleHeading({ level: 2 }),
      'Mod-Shift-3': () => this.editor.commands.toggleHeading({ level: 3 }),
      'Mod-Shift-4': () => this.editor.commands.toggleHeading({ level: 4 }),
      'Mod-Shift-5': () => this.editor.commands.toggleHeading({ level: 5 }),
      'Mod-Shift-6': () => this.editor.commands.toggleHeading({ level: 6 }),
    }
  },
})

const CustomHorizontalRule = HorizontalRule.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-Shift-h': () => this.editor.commands.setHorizontalRule(),
    }
  },
})

export const findHighLightItemsInRichText = (editor: Editor): string[] => {
  let items: string[] = []
  if (editor.isActive('heading', { level: 1 })) {
    items = [...items, StyleType.HEADER_ONE]
  }
  if (editor.isActive('heading', { level: 2 })) {
    items = [...items, StyleType.HEADER_TWO]
  }
  if (editor.isActive('heading', { level: 3 })) {
    items = [...items, StyleType.HEADER_THREE]
  }
  if (editor.isActive('heading', { level: 4 })) {
    items = [...items, StyleType.HEADER_FOUR]
  }
  if (editor.isActive('heading', { level: 5 })) {
    items = [...items, StyleType.HEADER_FIVE]
  }
  if (editor.isActive('heading', { level: 6 })) {
    items = [...items, StyleType.HEADER_SIX]
  }
  if (editor.isActive('bold')) items = [...items, StyleType.BOLD]
  if (editor.isActive('italic')) items = [...items, StyleType.ITALIC]
  if (editor.isActive('strike')) items = [...items, StyleType.STRIKETHROUGH]
  if (editor.isActive('code')) items = [...items, StyleType.CODE]
  if (editor.isActive('codeBlock')) items = [...items, StyleType.CODE_BLOCK]
  if (editor.isActive('blockquote')) items = [...items, StyleType.BLOCKQUOTE]
  if (editor.isActive('bulletList')) {
    items = [...items, StyleType.UNORDERED_LIST_ITEM]
  }
  if (editor.isActive('orderedList')) {
    items = [...items, StyleType.ORDERED_LIST_ITEM]
  }
  if (editor.isActive('horizontalRule')) {
    items = [...items, StyleType.HORIZONTAL_RULE]
  }
  if (editor.isActive('taskList')) items = [...items, StyleType.TASK_LIST]
  if (editor.isActive('table')) items = [...items, StyleType.TABLE]
  if (editor.isActive('image')) items = [...items, StyleType.FILE]
  return items
}

const CLIPBOARD_DATA_TYPE_HTML: string = 'text/html'
const HTML_ANCHOR_TAG_REGEX: string = '<a '

export const insertLinkFromClipboard = (
  clipboardData: DataTransfer,
  editor: Editor
): boolean => {
  if (
    !clipboardData ||
    clipboardData.types.indexOf(CLIPBOARD_DATA_TYPE_HTML) < 0
  ) {
    return false
  }

  const html: string = clipboardData.getData(CLIPBOARD_DATA_TYPE_HTML)
  if (
    _.isEmpty(html) ||
    !new RegExp(HTML_ANCHOR_TAG_REGEX).test(html.replaceAll('\r\n', ''))
  ) {
    return false
  }
  editor.commands.insertContent(html, {
    parseOptions: {
      preserveWhitespace: false,
    },
  })
  return true
}
