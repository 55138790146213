interface BaseErrorParams {
  errorCode: string
  message: string
}

export default class BaseError extends Error {
  errorCode: string

  constructor({ errorCode, message }: BaseErrorParams) {
    super(message)
    this.errorCode = errorCode
  }
}
