import { useCallback } from 'react'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'

// TODO: Consider moving these functions into domain layer.

type Formatter = (src: number) => string
type KpiFormatters = {
  valueFormatter: Formatter
  rateFormatter: Formatter
}

export const useKpiFormatter = (
  aggregateField: AggregateField
): KpiFormatters => {
  const workloadFormatter = useCallback((src: number) => src.toFixed(1), [])
  const countFormatter = useCallback((src: number) => src.toFixed(0), [])
  const rateFormatter = useCallback((src: number) => (src * 100).toFixed(1), [])
  switch (aggregateField) {
    case AggregateField.WBS_ITEM_WORKLOAD:
      return { valueFormatter: workloadFormatter, rateFormatter }
    case AggregateField.WBS_ITEM_COUNT:
      return { valueFormatter: countFormatter, rateFormatter }
  }
}
