import { useCallback, useState } from 'react'

export const useDialog = () => {
  const [addMultiple, setAddMultiple] = useState<boolean>(false)
  const [prevSiblingUuid, setPrevSiblingUuid] = useState<string>()

  const [actualResult, setActualResult] = useState<boolean>(false)
  const [taskUuid, setTaskUuid] = useState<string>()

  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [uiState, setUiState] = useState<boolean>(false)

  const openAddMultiple = useCallback((uuid: string | undefined) => {
    setAddMultiple(true)
    setPrevSiblingUuid(uuid)
  }, [])

  const closeAddMultiple = useCallback(() => {
    setAddMultiple(false)
    setPrevSiblingUuid(undefined)
  }, [])

  const openActualResult = useCallback(
    (uuid: string) => {
      setTaskUuid(uuid)
      setActualResult(true)
    },
    [actualResult, taskUuid]
  )

  const closeActualResult = useCallback(() => {
    setActualResult(false)
    setTaskUuid(undefined)
  }, [actualResult, taskUuid])

  const openDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true)
  }, [])

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false)
  }, [])

  const openUiState = useCallback(() => {
    setUiState(true)
  }, [])

  const closeUiState = useCallback(() => {
    setUiState(false)
  }, [])

  return {
    addMultiple,
    prevSiblingUuid,
    openAddMultiple,
    closeAddMultiple,

    actualResult,
    taskUuid,
    openActualResult,
    closeActualResult,

    deleteConfirmation,
    openDeleteConfirmation,
    closeDeleteConfirmation,

    uiState,
    openUiState,
    closeUiState,
  }
}
