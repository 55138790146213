import React from 'react'
import { Divider as MuiDivider, styled } from '@mui/material'

export const Toolbar = styled('div')({
  width: '100%',
  height: '38px',
  padding: '2px 5px',
  margin: '1px 0',
  display: 'flex',
  alignItems: 'center',
})

const Divider = styled(MuiDivider)({
  marginLeft: 2,
  marginRight: 2,
})
export const ToolbarDivider = () => (
  <Divider orientation="vertical" variant="middle" flexItem={true} />
)
