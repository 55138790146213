import React from 'react'
import { styled } from '@mui/system'
import Radio from '@mui/material/Radio'
import { ColDef, Column, ColumnApi, GridApi, RowNode } from 'ag-grid-community'
import { addEventListenerForLastRow } from '../../../../index'
import { moveFocusByArrowKey } from '../../../../lib/navigator'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})

interface Props {
  value: string
  node: RowNode
  colDef: ColDef
  api: GridApi
  column: Column
  columnApi: ColumnApi
  rowIndex: number

  field: string
  radioValue: string
}

interface State {
  value: string
}

class RadioGroupCellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)
    this.state = {
      value: this.props.value,
    }
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
      addEventListenerForLastRow(this.ref.current, this.props)
    }
  }

  getValue = () => {
    return this.state.value
  }

  private valueChanged = (event: React.ChangeEvent<{}>, checked: boolean) => {
    this.setValue()
  }

  private keyPressed = event => {
    if (event && event.keyCode === 32) {
      // space
      this.setValue()
    }
    moveFocusByArrowKey(
      event,
      this.props.node,
      this.props.column,
      this.props.api,
      this.props.columnApi
    )
  }

  private blurred = e => {
    this.props.api.stopEditing()
  }

  private setValue = () => {
    this.setState({ value: this.props.radioValue })
    this.props.node.setDataValue(this.props.field, this.props.radioValue)
  }

  render() {
    return (
      <RootDiv>
        <Radio
          name={this.props.field}
          value={this.props.radioValue}
          checked={this.state.value === this.props.radioValue}
          onChange={this.valueChanged}
          onKeyDown={this.keyPressed}
          onBlur={this.blurred}
          inputRef={this.ref}
          tabIndex={1}
        />
      </RootDiv>
    )
  }
}

export default RadioGroupCellEditor
