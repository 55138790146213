import { useEffect, useMemo, useState } from 'react'
import { useProjectPlanSnapshotRepository } from '../../../../../../../services/adaptors/projectPlanSnapshotRepositoryAdaptor'
import { useProjectPrivateContext } from '../../../../../../context/projectContext'
import { TeamSelectRow } from '../model'

export const useTeamSelectData = (
  snapshotUuid: string,
  selectedUuids: string[]
) => {
  const { projectUuid } = useProjectPrivateContext()
  const { fetchReferencedValues } = useProjectPlanSnapshotRepository()
  const [partialRows, setPartialRows] = useState<
    Omit<TeamSelectRow, 'selected'>[]
  >([])
  useEffect(() => {
    const fn = async () => {
      const response = await fetchReferencedValues(snapshotUuid, 'TEAM_ENTITY')
      setPartialRows(
        response.map(resp => ({
          uuid: resp.value,
          displayName: resp.name,
        }))
      )
    }
    fn()
  }, [fetchReferencedValues, snapshotUuid])
  const rows = useMemo(
    () =>
      partialRows.map(r => {
        const selected = selectedUuids.includes(r.uuid)
        return {
          ...r,
          selected,
        } as TeamSelectRow
      }),
    [partialRows, selectedUuids]
  )
  return {
    rows,
  }
}
