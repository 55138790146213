import moment from 'moment'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetProps,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../../containers/BulkSheet/RowDataManager/rowDataManager'
import { generateUuid } from '../../../../utils/uuids'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { APIResponse } from '../../../../lib/commons/api'
import ViewMeta from '../../../containers/meta/ViewMeta'
import { ColDef, GridApi, RowNode, ValueGetterParams } from 'ag-grid-community'
import { DISPLAY_DATE_FORMAT_WITH_DAY } from '../../../../utils/date'
import { intl } from '../../../../i18n'
import {
  AggregateColumn,
  AggregateKey,
  getAggregateColumnLabel,
} from '../../../../lib/functions/wbsProgressLog'
import { getTimeLabel, TimeGrain } from '../../../../lib/functions/report'
import {
  fetch,
  getDataValue,
  getValueFormatter,
  ProgressReportChartConfig,
  ProgressReportViewConfig,
  summarize,
} from '..'
import { getXValues, ProgressReportDataValue, SummarizeType } from '../utils'
import { Tree } from '../../../../lib/commons/tree'
import { ProgressReportSearchConditionVO } from '../../../../domain/value-object/ProgressReportSearchConditionVO'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import store from '../../../../store'
import { getHoursPerWorkloadUnit } from '../../../hooks/useWorkloadUnit'
import { WorkloadUnit } from '../../../../lib/functions/workload'

export interface ProgressReportTableState extends BulkSheetState {
  condition: ProgressReportSearchConditionVO
  viewConfig: ProgressReportViewConfig
  chartConfig: ProgressReportChartConfig
}

export interface ProgressReportTableProps extends BulkSheetSpecificProps {
  ticketTypes: WbsItemTypeVO[]
}

export interface ProgressReportDataAsTree
  extends Tree<ProgressReportDataAsTree> {
  key: AggregateKey
  values: ProgressReportDataValue[]
  type: SummarizeType
}

export class ProgressReportTableRow extends RowData {
  key?: AggregateKey
  values: ProgressReportDataValue[] = []
  type: SummarizeType = SummarizeType.UNIT
}

interface ProgressReportTableBulkSheetContext
  extends BulkSheetContext<
    ProgressReportTableProps,
    ProgressReportDataAsTree,
    ProgressReportTableRow,
    ProgressReportTableState
  > {}

export interface ProgressReportTableBulkSheetProps
  extends BulkSheetProps<
    ProgressReportTableProps,
    ProgressReportDataAsTree,
    ProgressReportTableRow,
    ProgressReportTableState
  > {}

class ProgressReportTableRowDataSpec extends RowDataSpec<
  ProgressReportDataAsTree,
  ProgressReportTableRow
> {
  createNewRow(): ProgressReportTableRow {
    throw new Error('Creating new row is not allowed.')
  }

  overwriteRowItemsWithParents(params: {
    child: ProgressReportTableRow
    parent: ProgressReportTableRow
  }): ProgressReportTableRow {
    throw new Error('overwriteRowItemsWithParents is not implemented.')
  }

  createRowByResponse(
    response: ProgressReportDataAsTree
  ): ProgressReportTableRow {
    return {
      uuid: generateUuid(),
      key: response.key,
      values: response.values,
      type: response.type,
    }
  }
}

export default class ProgressReportTableOptions extends BulkSheetOptions<
  ProgressReportTableProps,
  ProgressReportDataAsTree,
  ProgressReportTableRow,
  ProgressReportTableState
> {
  addable = false
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ProgressReportTableColumnAndFilterState}-${ctx.state.uuid}`
  rowDataSpec = new ProgressReportTableRowDataSpec()
  pinnedColumns = ['project.progressReport.table.key']

  async getAll(
    state: ProgressReportTableState,
    props: ProgressReportTableBulkSheetProps
  ): Promise<APIResponse> {
    const dataSeries = await fetch(
      state.condition,
      state.uuid,
      props.specificProps?.ticketTypes
    )
    const rows: ProgressReportDataAsTree[] = []
    dataSeries.forEach(series => {
      rows.push({
        uuid: generateUuid(),
        lockVersion: -1,
        revision: '',
        key: { type: series.type, aggregateColumn: series.aggregateColumn },
        type: SummarizeType.UNIT,
        values: series.value,
        children: [],
      })
      rows.push({
        uuid: generateUuid(),
        lockVersion: -1,
        revision: '',
        key: { type: series.type, aggregateColumn: series.aggregateColumn },
        type: SummarizeType.BURN_UP,
        values: series.value,
        children: [],
      })
      rows.push({
        uuid: generateUuid(),
        lockVersion: -1,
        revision: '',
        key: { type: series.type, aggregateColumn: series.aggregateColumn },
        type: SummarizeType.BURN_DOWN,
        values: series.value,
        children: [],
      })
    })
    return {
      status: 200,
      json: rows || [],
    }
  }

  dynamicColumns = {
    'project.progressReport.table.key': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: ProgressReportTableBulkSheetContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        let columns: ColDef[] = []
        let children: ColDef[] = []
        const getCellStyle = (
          getTargetValue: (data: ProgressReportTableRow) => any
        ): ((params: any) => any) => {
          return (params: any) => {
            let isBeforeSameMember = false
            let beforeRow: RowNode | undefined = undefined
            params.api.forEachNodeAfterFilterAndSort((node, index) => {
              if (node.id === params.node.id) {
                isBeforeSameMember = !!(
                  beforeRow &&
                  getTargetValue(beforeRow.data) === getTargetValue(params.data)
                )
              }
              beforeRow = node
            })
            if (isBeforeSameMember) {
              return { color: 'transparent' }
            } else {
              return { color: 'inherit' }
            }
          }
        }
        children.push({
          ...baseColumnDef,
          headerName: intl.formatMessage({
            id: 'progressReport.table.key.type',
          }),
          colId: 'type',
          field: 'type',
          valueGetter: (params: ValueGetterParams) => {
            return params.data.key.type.name
          },
          cellStyle: getCellStyle(data => data.key?.type.value),
          filter: 'agSetColumnFilter',
        })
        children.push({
          ...baseColumnDef,
          headerName: intl.formatMessage({
            id: 'progressReport.table.key.primary',
          }),
          colId: 'primaryKey',
          field: 'primaryKey',
          valueGetter: (params: ValueGetterParams) => {
            return this.getAggregateColumnPrimaryLabel(
              params.data.key.aggregateColumn
            )
          },
          cellStyle: getCellStyle(data => data.key?.aggregateColumn),
          filter: 'agSetColumnFilter',
        })
        children.push({
          ...baseColumnDef,
          headerName: intl.formatMessage({
            id: 'progressReport.table.key.secondary',
          }),
          colId: 'secondaryKey',
          field: 'secondaryKey',
          valueGetter: (params: ValueGetterParams) => {
            return this.getAggregateColumnSecondaryLabel(
              params.data.type,
              ctx.state.viewConfig.timeGrain || TimeGrain.WEEK
            )
          },
          cellStyle: getCellStyle(data => data.type),
          filter: 'agSetColumnFilter',
        })
        columns.push({
          ...baseColumnDef,
          children: children,
        } as ColDef)
        return columns
      },
    },
    'project.progressReport.table.value': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: ProgressReportTableBulkSheetContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        if (!ctx.state.viewConfig) return []
        const {
          timeGrain,
          startDayOfWeek,
          viewTime,
          aggregateTargetType,
          aggregateTargetUnit,
        } = ctx.state.viewConfig

        const organization = store.getState().tenant.organization!
        const hoursPerWorkloadUnit = getHoursPerWorkloadUnit(
          WorkloadUnit[aggregateTargetUnit],
          organization
        )
        const dimTimes = getXValues(timeGrain, startDayOfWeek, viewTime!)
        const getHeaderName = (
          dimTime: number,
          timeGrain: TimeGrain
        ): string => {
          return getTimeLabel(dimTime, timeGrain)
        }
        const getValueGetter = (
          dimTime: number | undefined
        ): AgGridValueGetter => {
          return (params: ValueGetterParams) => {
            if (
              [
                AggregateColumn.ACTUAL_HOUR_RECORDED_AT,
                AggregateColumn.CREATED_AT,
              ].includes(params.data.key.aggregateColumn) &&
              params.data.type === SummarizeType.BURN_DOWN
            ) {
              return '-'
            }
            const formatter = getValueFormatter(
              aggregateTargetType,
              aggregateTargetUnit
            )
            if (typeof dimTime === 'undefined') {
              const value = params.data.values.find(
                v => typeof v.dimTime === 'undefined'
              )
              if (value) {
                return formatter(
                  getDataValue(
                    value.data,
                    aggregateTargetType,
                    hoursPerWorkloadUnit
                  )
                )
              }
              return formatter(0)
            }
            const value = summarize(
              params.data.values,
              dimTime,
              timeGrain,
              aggregateTargetType,
              aggregateTargetUnit,
              params.data.type,
              hoursPerWorkloadUnit
            )
            return formatter(value)
          }
        }
        const columns = dimTimes.map((dimTime, index) => {
          return {
            ...baseColumnDef,
            headerName: getHeaderName(dimTime, timeGrain),
            colId: moment(dimTime).format(DISPLAY_DATE_FORMAT_WITH_DAY),
            field: moment(dimTime).format(DISPLAY_DATE_FORMAT_WITH_DAY),
            cellStyle: {
              justifyContent: 'flex-end',
            },
            valueGetter: getValueGetter(dimTime),
          }
        })
        columns.unshift({
          ...baseColumnDef,
          headerName: intl.formatMessage({
            id: 'progressReport.table.unplanned',
          }),
          colId: 'unplanned',
          field: 'unplanned',
          cellStyle: {
            justifyContent: 'flex-end',
          },
          valueGetter: (params: ValueGetterParams) => {
            const aggregateColumn = params.data.key.aggregateColumn
            if (
              [
                AggregateColumn.ACTUAL_START_DATE,
                AggregateColumn.ACTUAL_END_DATE,
                AggregateColumn.CREATED_AT,
                AggregateColumn.ACTUAL_HOUR_RECORDED_AT,
              ].includes(aggregateColumn)
            ) {
              return '-'
            }
            return getValueGetter(undefined)(params)
          },
        })
        return [
          {
            ...baseColumnDef,
            children: columns,
          } as ColDef,
        ]
      },
    },
  }

  private getAggregateColumnPrimaryLabel = (column: AggregateColumn) => {
    return getAggregateColumnLabel(column)
  }

  private getAggregateColumnSecondaryLabel = (
    type: SummarizeType,
    timeGrain: TimeGrain
  ) => {
    switch (type) {
      case SummarizeType.UNIT:
        switch (timeGrain) {
          case TimeGrain.DAY:
            return intl.formatMessage({
              id: 'progressReport.table.key.bar.day',
            })
          case TimeGrain.WEEK:
            return intl.formatMessage({
              id: 'progressReport.table.key.bar.week',
            })
          case TimeGrain.MONTH:
            return intl.formatMessage({
              id: 'progressReport.table.key.bar.month',
            })
          default:
            return '-'
        }
      case SummarizeType.BURN_UP:
        return intl.formatMessage({
          id: 'progressReport.table.key.burnUp',
        })
      case SummarizeType.BURN_DOWN:
        return intl.formatMessage({
          id: 'progressReport.table.key.burnDown',
        })
      default:
        return '-'
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (field === 'key') {
      return 120
    }
    if (field === 'value') {
      return 70
    }
    return undefined
  }

  private refreshKeyColumns = (gridApi: GridApi | undefined) => {
    gridApi!.refreshCells({
      force: true,
      columns: ['type', 'primaryKey', 'secondaryKey'],
    })
  }

  onFilterChanged = (ctx: ProgressReportTableBulkSheetContext) => {
    this.refreshKeyColumns(ctx.gridApi)
  }
  onSortChanged = (ctx: ProgressReportTableBulkSheetContext) => {
    this.refreshKeyColumns(ctx.gridApi)
  }
}
