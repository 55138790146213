import { Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import { EditorType } from '../../components/editors/multiline-text/MultilineTextEditor'
import TemplateEditDialog from './TemplateEditDialog'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import AlertDialog from '../../components/dialogs/AlertDialog'
import MarkupViewer from '../../components/viewers/MarkupViewer'
import EditIcon from '@mui/icons-material/EditRounded'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import SearchIcon from '@mui/icons-material/SearchRounded'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded'
import LockIcon from '@mui/icons-material/LockRounded'
import GroupIcon from '@mui/icons-material/GroupRounded'
import Auth from '../../../lib/commons/auth'
import moment from 'moment'
import { formatDateTime } from '../../../utils/date'
import { getTemplates } from '../../../lib/functions/template'
import { generateUuid } from '../../../utils/uuids'
import { muiTheme } from '../../../styles/muiTheme'
import ApartmentIcon from '@mui/icons-material/Apartment'

// Styles
const theme = muiTheme
const RootDiv = styled('div')({
  width: '100%',
})
const SearchInputArea = styled('span')({
  border: '1px solid grey',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginRight: '5px',
})
const SearchInputIcon = styled(SearchIcon)({
  margin: '10px',
})
const SearchInput = styled(InputBase)({
  color: 'inherit',
  width: '100%',
})
const TemplateListArea = styled('div')({
  maxHeight: '300px',
  overflowY: 'scroll',
})
const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '30px',
})
const TemplateName = styled(ListItemText)({
  width: '200px',
})
const TemplateUpdatedInfo = styled(ListItemText)({
  width: '150px',
  marginRight: '10px',
})
const AddButton = styled(IconButton)({
  marginLeft: 10,
})

interface Props extends WrappedComponentProps {
  projectUuid: string
  externalId: string
  editor?: EditorType
  hasAllPrivileges?: boolean
}

export interface Template {
  uuid: string
  name: string
  text: string
  scope: UiStateScope
  externalIdDefault: string[]
  updatedBy?: string
  updatedAt?: string
}

export const isDefault = (template: Template, externalId: string) => {
  return template.externalIdDefault?.includes(externalId)
}

interface State {
  templates: Template[]
  selectedIndex?: number
  searchText: string
  openEditor: boolean
  openAlert: boolean
}

class TemplateList extends React.Component<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  state: State = {
    templates: [],
    searchText: '',
    openEditor: false,
    openAlert: false,
  }

  async componentDidMount() {
    const templatesForCrossProject = await this.getTemplates(
      UiStateScope.CrossProject
    )
    const templatesForProject = await this.getTemplates(UiStateScope.Tenant)
    const templatesForUser = await this.getTemplates(UiStateScope.User)
    this.setState({
      templates: [
        ...templatesForCrossProject,
        ...templatesForProject,
        ...templatesForUser,
      ],
    })
    if (this.ref.current) {
      this.ref.current.focus()
    }
  }

  private getTemplates = async (scope: UiStateScope) => {
    return getTemplates(scope, this.props.projectUuid)
  }

  private updateTemplates = async (scope: UiStateScope) => {
    const key =
      scope === UiStateScope.CrossProject
        ? UiStateKey.Template
        : `${UiStateKey.Template}-${this.props.projectUuid || ''}`
    uiStates.update(
      {
        key: key,
        scope: scope,
        value: JSON.stringify(
          this.state.templates.filter(v => v.scope === scope)
        ),
      },
      ''
    )
  }

  private isDefault = (template: Template) => {
    return isDefault(template, this.props.externalId)
  }

  private openEditor = index => {
    this.setState({
      openEditor: true,
      selectedIndex: index,
    })
  }

  private closeEditor = () => {
    this.setState({
      openEditor: false,
      selectedIndex: undefined,
    })
  }

  private saveTemplate = (index: number, template: Template) => {
    const templates = this.state.templates
    if (this.isDefault(template)) {
      templates.forEach(temp => {
        temp.externalIdDefault = (temp.externalIdDefault || []).filter(
          id => id !== this.props.externalId
        )
      })
    }
    templates[index] = {
      ...template,
      updatedBy: Auth.getCurrentTenant()!.user!.name,
      updatedAt: formatDateTime(moment()),
    }
    this.setState({ templates, selectedIndex: undefined }, () => {
      this.updateTemplates(UiStateScope.CrossProject)
      this.updateTemplates(UiStateScope.Tenant)
      this.updateTemplates(UiStateScope.User)
    })
  }

  private openAlert = (index: number) => {
    this.setState({
      openAlert: true,
      selectedIndex: index,
    })
  }

  private closeAlert = () => {
    this.setState({
      openAlert: false,
      selectedIndex: undefined,
    })
  }

  private deleteTemplate = () => {
    if (!this.state.selectedIndex && this.state.selectedIndex !== 0) return
    const templates = this.state.templates
    const scope = templates[this.state.selectedIndex].scope
    templates.splice(this.state.selectedIndex, 1)
    this.setState(
      { templates, openAlert: false, selectedIndex: undefined },
      () => {
        this.updateTemplates(scope)
      }
    )
  }

  private searchTextChanged = event => {
    this.setState({ searchText: event.target.value })
  }

  render() {
    return (
      <RootDiv>
        <SearchInputArea>
          <SearchInputIcon color="action" />
          <SearchInput
            inputRef={this.ref}
            placeholder={this.props.intl.formatMessage({
              id: 'template.search',
            })}
            value={this.state.searchText}
            onChange={this.searchTextChanged}
          />
        </SearchInputArea>
        <TemplateListArea>
          <List component="nav" aria-label="template">
            {this.state.templates.map((template, index) => {
              if (
                this.state.searchText &&
                !template.name.includes(this.state.searchText)
              ) {
                return <></>
              }
              return (
                <>
                  <ListItem button={true}>
                    <StyledListItemIcon>
                      {template.scope === UiStateScope.CrossProject && (
                        <Tooltip
                          title={this.props.intl.formatMessage({
                            id: 'template.scope.tenant',
                          })}
                        >
                          <ApartmentIcon color="action" />
                        </Tooltip>
                      )}
                      {template.scope === UiStateScope.Tenant && (
                        <Tooltip
                          title={this.props.intl.formatMessage({
                            id: this.isDefault(template)
                              ? 'template.scope.project.default'
                              : 'template.scope.project',
                          })}
                        >
                          <GroupIcon
                            color={
                              this.isDefault(template) ? 'primary' : 'action'
                            }
                          />
                        </Tooltip>
                      )}
                      {template.scope === UiStateScope.User && (
                        <Tooltip
                          title={this.props.intl.formatMessage({
                            id: 'template.scope.private',
                          })}
                        >
                          <LockIcon color="action" />
                        </Tooltip>
                      )}
                    </StyledListItemIcon>
                    <TemplateName primary={template.name} />
                    <TemplateUpdatedInfo
                      secondary={`${template.updatedBy || ''} ${
                        template.updatedAt || ''
                      }`}
                    />
                    {(template.scope !== UiStateScope.CrossProject ||
                      this.props.hasAllPrivileges) && (
                      <ListItemSecondaryAction>
                        <>
                          <Tooltip
                            title={this.props.intl.formatMessage({
                              id: 'template.tooltip.edit',
                            })}
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              aria-label="edit"
                              onClick={e => this.openEditor(index)}
                            >
                              <EditIcon color="action" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={this.props.intl.formatMessage({
                              id: 'template.tooltip.delete',
                            })}
                          >
                            <IconButton
                              size="small"
                              edge="end"
                              aria-label="edit"
                              onClick={e => this.openAlert(index)}
                            >
                              <DeleteIcon color="action" />
                            </IconButton>
                          </Tooltip>
                        </>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                  {this.state.openEditor && (
                    <TemplateEditDialog
                      open={this.state.selectedIndex === index}
                      externalId={this.props.externalId}
                      template={template}
                      onSubmit={template => this.saveTemplate(index, template)}
                      onClose={this.closeEditor}
                      editor={this.props.editor}
                    />
                  )}
                </>
              )
            })}
          </List>
        </TemplateListArea>
        <Tooltip
          title={this.props.intl.formatMessage({
            id: 'template.tooltip.add',
          })}
        >
          <AddButton
            size="small"
            aria-label="add"
            onClick={e => this.openEditor(this.state.templates.length)}
          >
            <AddCircleOutlineIcon color="action" />
          </AddButton>
        </Tooltip>
        {this.state.openEditor && (
          <TemplateEditDialog
            open={this.state.selectedIndex === this.state.templates.length}
            externalId={this.props.externalId}
            template={{
              uuid: generateUuid(),
              name: '',
              text: '',
              externalIdDefault: [],
              scope: UiStateScope.User,
            }}
            onSubmit={template =>
              this.saveTemplate(this.state.templates.length, template)
            }
            onClose={this.closeEditor}
            editor={this.props.editor}
          />
        )}
        <AlertDialog
          isOpen={this.state.openAlert}
          message={this.props.intl.formatMessage({
            id: 'template.delete.message',
          })}
          extraContent={
            this.state.selectedIndex !== undefined &&
            this.state.templates[this.state.selectedIndex] ? (
              <MarkupViewer
                content={this.state.templates[this.state.selectedIndex].name}
              />
            ) : (
              ''
            )
          }
          submitButtonTitle={this.props.intl.formatMessage({ id: 'dialog.ok' })}
          submitHandler={this.deleteTemplate}
          closeButtonTitle={this.props.intl.formatMessage({
            id: 'dialog.cancel',
          })}
          closeHandler={this.closeAlert}
        />
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected!,
  hasAllPrivileges: state.user.user?.hasAllPrivileges,
})

export default connect(mapStateToProps)(injectIntl(TemplateList))
