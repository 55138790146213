import { useMemo } from 'react'
import {
  WidgetWithTitle,
  WidgetTitle,
  WidgetForExpanded,
  Kpi,
  WidgetContent,
} from '.'
import { ProjectProgress } from '../../../../domain/value-object/ProjectReportVO'
import { intl } from '../../../../i18n'
import { EnterpriseProjectReportConfig } from '../model/config'
import { aggregatedWorkloadService } from '../../../../domain/value-object/AggregatedValueVO'
import { useI18n } from '../../../hooks/useI18n'
import { useKpiUnit } from '../hooks/kpiUnit'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'

type ProjectProductivityWidgetProps = Pick<
  ProjectProgress,
  'bac' | 'ev' | 'ac'
> &
  Omit<EnterpriseProjectReportConfig, 'aggregateField'>

export const ProjectProductivityWidget = ({
  bac,
  ev,
  ac,
  aggregateTarget,
  workloadUnit,
}: ProjectProductivityWidgetProps) => {
  const workloadUnitState = useWorkloadUnit(workloadUnit)
  const { bacValue, evValue, cpiValue, acValue } = useMemo(() => {
    const bacValue = workloadUnitState.convertWorkloadFromHourToSelectedUnit(
      aggregatedWorkloadService.getTargetValue(bac.workload, aggregateTarget)
    )
    const evValue = workloadUnitState.convertWorkloadFromHourToSelectedUnit(
      aggregatedWorkloadService.getTargetValue(ev.workload, aggregateTarget)
    )
    const acValue = workloadUnitState.convertWorkloadFromHourToSelectedUnit(ac)
    const cpiValue = acValue > 0 ? evValue / acValue : Number.POSITIVE_INFINITY
    return {
      bacValue,
      evValue,
      cpiValue,
      acValue,
    }
  }, [ac, aggregateTarget, bac.workload, ev.workload, workloadUnitState])
  const { cpi, etc, eac } = useMemo(() => {
    if (!Number.isFinite(cpiValue)) {
      const etcValue = bacValue - evValue
      return {
        cpi: '-',
        etc: etcValue.toFixed(1),
        eac: (acValue + etcValue).toFixed(1),
      }
    }
    if (cpiValue === 0) {
      return { cpi: '0', etc: '-', eac: '-' }
    }
    const etcValue = (bacValue - evValue) / cpiValue
    return {
      cpi: (cpiValue * 100).toFixed(1),
      etc: etcValue.toFixed(1),
      eac: (acValue + etcValue).toFixed(1),
    }
  }, [acValue, bacValue, cpiValue, evValue])
  const [title] = useI18n(['enterpriseProjectReport.productivity'])
  const unit = useKpiUnit(AggregateField.WBS_ITEM_WORKLOAD, workloadUnit)
  return (
    <WidgetWithTitle>
      <WidgetTitle>{title}</WidgetTitle>
      <WidgetForExpanded>
        <WidgetContent>
          <Kpi title={'CPI'} value={cpi} unit={'%'} />
          <Kpi title={'ETC'} value={etc} unit={unit} />
          <Kpi title={'EAC'} value={eac} unit={unit} />
        </WidgetContent>
      </WidgetForExpanded>
    </WidgetWithTitle>
  )
}
