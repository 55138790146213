import { DateVO, dateVoService } from '../../domain/value-object/DateVO'
import ClassDateVO from '../../vo/DateVO'

const fromClassDateVOtoType = (src: ClassDateVO): DateVO => {
  if (src.relativeDate) {
    return dateVoService.construct(src.relativeDate.toLabel())
  }
  return dateVoService.construct(src.toDate())
}

const fromTypeDateVOtoClass = (src: DateVO): ClassDateVO => {
  if (src.type === 'Relative') {
    return new ClassDateVO(src.value)
  }
  return new ClassDateVO(src.value.toDate())
}

export default {
  fromClassDateVOtoType,
  fromTypeDateVOtoClass,
}
