import * as d3 from 'd3'
import {
  Scale,
  TwoDimensionalPoint,
  TwoDimensionalPoints,
  TwoDimensionalScales,
} from '../../model/chart'
import { useMemo } from 'react'
import { useLine } from '../../hooks/chart/lineChart'

type LineStyle = 'STRAIGHT' | 'DASHED'

type LineChartStyle = {
  color: string
  lineStyle?: LineStyle
}

type LineChartFeedbacks<X, Y> = {
  onPointClicked?: (point: TwoDimensionalPoint<X, Y>) => void
}

type LineChartProps<X, Y> = {
  points: TwoDimensionalPoints<X, Y>
} & LineChartStyle &
  TwoDimensionalScales<X, Y> &
  LineChartFeedbacks<X, Y>

export const LineChart = <X, Y>({
  xScale,
  yScale,
  points,
  color,
  lineStyle,
  onPointClicked,
}: LineChartProps<X, Y>) => {
  return (
    <g>
      <Line
        color={color}
        lineStyle={lineStyle}
        xScale={xScale}
        yScale={yScale}
        points={points}
      />
      <Nodes
        color={color}
        xScale={xScale}
        yScale={yScale}
        points={points}
        onPointClicked={onPointClicked}
      />
    </g>
  )
}

type LineProps<X, Y> = LineChartStyle &
  TwoDimensionalScales<X, Y> & {
    points: TwoDimensionalPoint<X, Y>[]
  }

const Line = <X, Y>({
  color,
  lineStyle,
  xScale,
  yScale,
  points,
}: LineProps<X, Y>) => {
  const d = useLine(xScale, yScale, points)
  const strokeDashArray = useMemo(() => {
    switch (lineStyle) {
      case 'STRAIGHT':
        return ''
      case 'DASHED':
        return '4, 4'
      default:
        return ''
    }
  }, [lineStyle])
  return (
    <path
      fill="none"
      stroke={color}
      strokeWidth={1.5}
      d={d}
      strokeDasharray={strokeDashArray}
    />
  )
}

type NodesProps<X, Y> = {
  color: string
  xScale: Scale<X>
  yScale: Scale<Y>
  points: TwoDimensionalPoint<X, Y>[]
  onPointClicked?: (p: TwoDimensionalPoint<X, Y>) => void
}

type Node = {
  cx: number
  cy: number
  onClick?: () => void
}

const Nodes = <X, Y>({
  color,
  xScale,
  yScale,
  points,
  onPointClicked,
}: NodesProps<X, Y>) => {
  const nodes = useMemo(() => {
    return points.map(p => ({
      cx: xScale(p.x),
      cy: yScale(p.y),
      onClick: () => onPointClicked?.(p),
    }))
  }, [points, xScale, yScale, onPointClicked])
  return (
    <>
      {nodes.map((v, i) => (
        <circle
          key={i}
          r={4}
          cx={v.cx}
          cy={v.cy}
          fill={color}
          onClick={v.onClick}
        />
      ))}
    </>
  )
}
