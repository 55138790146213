import { useCallback, useState } from 'react'

export const useDialog = () => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false)

  const [cancel, setCancel] = useState<boolean>(false)

  const openDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true)
  }, [])

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false)
  }, [])

  const openCancel = useCallback(() => {
    setCancel(true)
  }, [])

  const closeCancel = useCallback(() => {
    setCancel(false)
  }, [])

  return {
    deleteConfirmation,
    openDeleteConfirmation,
    closeDeleteConfirmation,

    cancel,
    openCancel,
    closeCancel,
  }
}
