import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea } from '..'
import Project, { ProjectDetail } from '../../../lib/functions/project'
import { AllState } from '../../../store'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import ProjectsOptions, {
  ColumnQuickFilterKey,
  ProjectRow,
  ProjectState,
} from './ProjectsOptions'
import ProjectsHeader from './Header'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { ColDef } from 'ag-grid-community'
import {
  getUiState,
  UiStateKey,
  UiStateScope,
  updateUiState,
} from '../../../lib/commons/uiStates'
import { Collapse } from '@mui/material'
import ProjectsToolbar from './Toolbar'
import { APIResponse } from '../../../lib/commons/api'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'

type SearchResult = {
  hit: number
  total: number
}

type ProjectsPageState = {
  toolbar: ToolbarToggleValue | undefined
  dateFormat: string
}

const projectsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
}

const Projects = props => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<BulkSheetSpecificProps, ProjectDetail, ProjectRow, ProjectState>
    >()

  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<SearchResult>({
    hit: 0,
    total: 0,
  })

  const { toolbar, dateFormat, updatePageState } =
    usePageState<ProjectsPageState>(
      props.uuid,
      projectsDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )
  const onChangeDateFormat = useCallback((dateFormat: string) => {
    updatePageState({ dateFormat })
  }, [])

  const projectsOptions = useMemo(() => {
    const options = new ProjectsOptions()
    // Override getAll method in order to use data fetch result in this component.
    // In the future, each methods in BulkSheetOptions will be decomposed into distinct props of BulkSheet,
    // then defining this method here is not so bad.
    options.getAll = async (state: ProjectState): Promise<APIResponse> => {
      const response = await Project.getProjects({
        searchText: state.searchText,
        sortField: 'UPDATED_AT',
        sortOrder: 'desc',
      })
      const result = response.json
      setSearchResult({
        hit: result.hit,
        total: result.total,
      })
      return response
    }
    return options
  }, [])

  const onSearch = useCallback(() => {
    bulkSheet?.setState(
      {
        searchText: searchText,
      },
      () => bulkSheet?.refreshDataWithLoading()
    )
  }, [searchText])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ dateFormat })
    bulkSheet.gridApi?.refreshCells({
      columns: ['scheduledFrom', 'scheduledTo'],
      force: true,
    })
  }, [bulkSheet, dateFormat])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
        setSearchText('')
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  useEffect(() => {
    const bulkSearchText = bulkSheet?.state.searchText || ''
    if (bulkSearchText !== searchText) {
      setSearchText(bulkSearchText)
    }
  }, [bulkSheet?.state.searchText])

  return (
    <PageArea>
      <ProjectsHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onRestoreSearchCondition={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateSearchCondition
          )
        }
        onSearch={onSearch}
        onChangeSearchText={seachText => setSearchText(seachText)}
        searchText={searchText}
        total={searchResult.total}
        hit={searchResult.hit}
        currentFormat={dateFormat}
        onClickChangeDateFormat={onChangeDateFormat}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <ProjectsToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={projectsOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
      />
    </PageArea>
  )
}

export default pageComponent(Projects)
