import { GridOptions } from 'ag-grid-community'
import { useCallback } from 'react'
import { exportExcel } from '../../../containers/BulkSheet/excel'

export const useExportExcel = (gridOptions: GridOptions): (() => void) => {
  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'unit_price_per_positions',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
    })
  }, [gridOptions])

  return onExportExcel
}
