import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { APIResponse } from '../../../../lib/commons/api'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import {
  ProjectNotificationSettingDeltaBatchRequest,
  ProjectNotificationSettingRow,
  ProjectNotificationWorkspace,
  isWorkspaceChanged,
} from '../projectNotificationSettings'
import {
  IItemDelta,
  createDelta,
} from '../../../../domain/value-object/ItemDeltaInputVO'
import { useProjectNotificationSettingRepository } from '../../../../services/adaptors/ProjectNotificationSettingRepositoryAdaptor'
import {
  ProjectNotificationSettingEntity,
  ProjectNotificationWorkspaceEntity,
} from '../../../../domain/entity/ProjectNotificationSettingEntity'
import { SlackChannel } from '../../../../domain/value-object/Slack/ChannelVO'
import { intl } from '../../../../i18n'

export const useProjectNotificationSettingData = (
  projectUuid: string
): {
  rowData: ProjectNotificationSettingRow[]
  setRowData: (rows: ProjectNotificationSettingRow[]) => void
  workspace: ProjectNotificationWorkspace | undefined
  setWorkspace: (workspace?: ProjectNotificationWorkspace) => void
  refresh: Function
  save: () => Promise<APIResponse>
  validate: () => string[]
} => {
  const [rowData, setRowDataInternal] = useState<
    ProjectNotificationSettingRow[]
  >([])
  const [workspace, setWorkspaceInternal] = useState<
    ProjectNotificationWorkspace | undefined
  >()

  const originalRowData = useRef<ProjectNotificationSettingRow[]>([])
  const originalWorkspace = useRef<ProjectNotificationWorkspace>()

  useEffect(() => {
    refresh()
  }, [projectUuid])

  const setRowData = useCallback((d: ProjectNotificationSettingRow[]) => {
    setRowDataInternal(d)
    store.dispatch(requireSave())
  }, [])

  const setWorkspace = useCallback(
    (d?: ProjectNotificationWorkspace) => {
      setWorkspaceInternal(d)
      const slackSettingDeleted = rowData.map(v => {
        if (!v.slackNotification) return v
        !d && (v.slackNotification = false)
        v.slackChannel = {}
        v.edited = true
        return v
      })
      setRowDataInternal(slackSettingDeleted)
      store.dispatch(requireSave())
    },
    [rowData]
  )

  const refresh = useCallback(async () => {
    if (!projectUuid) return []
    await fetch()
    store.dispatch(doNotRequireSave())
  }, [projectUuid])

  const { get, updateDeltaBatch } = useProjectNotificationSettingRepository()
  const fetch = useCallback(async () => {
    if (!projectUuid) return []
    const { slackWorkspace, projectNotificationSettings } = await get(
      projectUuid
    )
    const convertedWorkspace = responseToWorkspace(slackWorkspace)
    const convertedContents =
      projectNotificationSettings.map(responseToContentRow)
    setWorkspaceInternal(convertedWorkspace)
    setRowDataInternal(convertedContents)
    originalWorkspace.current = _.cloneDeep(convertedWorkspace)
    originalRowData.current = _.cloneDeep(convertedContents)
  }, [projectUuid])

  const validate = useCallback(() => {
    const errors: string[] = []
    const notSetSlackChannel = rowData.some(
      row => row.slackNotification && !row.slackChannel?.slackChannelId
    )
    notSetSlackChannel &&
      errors.push(
        intl.formatMessage({
          id: 'project.notification.setting.error.message.notSetSlackChannel',
        })
      )
    return errors
  }, [rowData])

  const save = useCallback(async (): Promise<APIResponse> => {
    const request = createDeltaBatchRequest(workspace, rowData, {
      projectUuid,
      originalWorkspace: originalWorkspace.current,
      originalRowData: originalRowData.current,
    })
    const response = await updateDeltaBatch(request)
    return response
  }, [workspace, rowData, projectUuid])

  return {
    rowData,
    setRowData,
    workspace,
    setWorkspace,
    refresh,
    save,
    validate,
  }
}

const responseToWorkspace = (
  response: ProjectNotificationWorkspaceEntity
): ProjectNotificationWorkspace | undefined => {
  if (!response) return undefined
  return { ...response }
}

const responseToContentRow = (
  response: ProjectNotificationSettingEntity
): ProjectNotificationSettingRow => {
  return {
    ...response,
    treeValue: [],
    slackChannel: {
      slackChannelId: response.slackChannelId ?? undefined,
      slackChannelName: response.slackChannelName ?? undefined,
    },
  }
}

const createDeltaBatchRequest = (
  workspace: ProjectNotificationWorkspace | undefined,
  data: ProjectNotificationSettingRow[],
  {
    projectUuid,
    originalWorkspace,
    originalRowData,
  }: {
    projectUuid: string
    originalWorkspace: ProjectNotificationWorkspace | undefined
    originalRowData: ProjectNotificationSettingRow[]
  }
) => {
  const changedRows = data.filter(v => v.edited)
  const request: ProjectNotificationSettingDeltaBatchRequest = {
    projectUuid,
    workspaceDelta: isWorkspaceChanged(workspace, originalWorkspace)
      ? createDelta(originalWorkspace, workspace)
      : undefined,
    contentsDelta: changedRows.map(row => {
      const original = originalRowData.find(v => v.uuid === row.uuid)!
      const { idDelta, nameDelta } = createSlackChannelDelta(
        original.slackChannel,
        row.slackChannel,
        row.slackNotification ?? false
      )
      return {
        uuid: row.uuid,
        lockVersion: row.lockVersion,
        notificationCode: row.notificationCode,
        projectUuid: row.projectUuid,
        mailNotification: createDelta(
          original.mailNotification,
          row.mailNotification
        ),
        slackNotification: createDelta(
          original.slackNotification,
          row.slackNotification
        ),
        slackChannelId: idDelta,
        slackChannelName: nameDelta,
      }
    }),
  }
  return request
}

const createSlackChannelDelta = (
  original: Partial<SlackChannel>,
  current: Partial<SlackChannel>,
  currentSlackNotification: boolean
): {
  idDelta: IItemDelta<string> | undefined
  nameDelta: IItemDelta<string> | undefined
} => ({
  idDelta: createDelta(
    original.slackChannelId ?? undefined,
    currentSlackNotification ? current.slackChannelId : undefined
  ),
  nameDelta: createDelta(
    original.slackChannelName,
    currentSlackNotification ? current.slackChannelName : undefined
  ),
})
