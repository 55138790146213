import { useCallback } from 'react'
import { useProjectMemberRepository } from '../../../../services/adaptors/projectMemberRepositoryAdaptor'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import {
  useReferencedEntityParser,
  useSelectParser,
  useTextParser,
} from './changeLogParser'
import { WbsItemSelectOptions } from './wbsItemSelectOptions'

export const useWbsItemChangeLogParser = ({
  subStatuses,
  difficulties,
  priorities,
}: WbsItemSelectOptions) => {
  const textParser = useTextParser()
  const subStatusParser = useSelectParser(subStatuses)
  const difficultyParser = useSelectParser(difficulties)
  const priorityParser = useSelectParser(priorities)
  const { fetchAsReferencedEntity: fetchTeam } = useTeamRepository()
  const teamParser = useReferencedEntityParser(fetchTeam)
  const { fetchAsReferencedEntity: fetchProjectMember } =
    useProjectMemberRepository()
  const projectMemberParser = useReferencedEntityParser(fetchProjectMember)
  const getChangeLogParser = useCallback(
    (path: string) => {
      switch (path) {
        case 'code':
        case 'displayName':
          return textParser
        case 'team':
          return teamParser
        case 'accountable':
        case 'responsible':
        case 'assignee':
          return projectMemberParser
        case 'subStatus':
          return subStatusParser
        case 'difficulty':
          return difficultyParser
        case 'priority':
          return priorityParser
      }
    },
    [
      difficultyParser,
      priorityParser,
      projectMemberParser,
      subStatusParser,
      teamParser,
      textParser,
    ]
  )
  return getChangeLogParser
}
