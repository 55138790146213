import { WbsItemSearchConditionVO } from '../../domain/value-object/WbsItemSearchConditionVo/index'
import { WbsItemSearchConditionApiRequest } from '../model/wbsItemSearchConditionApiRequest'
import { useCallback } from 'react'
import { dateTermVoService } from '../../domain/value-object/DateTermVO'

type WbsItemSearchConditionApiRequestTransformService = {
  toApiRequest: (
    src: WbsItemSearchConditionVO
  ) => WbsItemSearchConditionApiRequest
}

export const useWbsItemSearchConditionApiRequestTransformService = (
  projectUuid: string
): WbsItemSearchConditionApiRequestTransformService => {
  const toApiRequest = useCallback(
    (src: WbsItemSearchConditionVO): WbsItemSearchConditionApiRequest => {
      return {
        projectUuid,
        all: src.searchText,
        rootUuid: src.rootUuid,
        code: src.code,
        status: src.status,
        types: src.types,
        ticketTypes: src.ticketTypes,
        priority: src.priority,
        displayName: src.displayName,
        description: src.description,
        team: src.teamUuid ? { uuid: src.teamUuid } : undefined,
        sprint: src.sprintUuid ? { uuid: src.sprintUuid } : undefined,
        accountable: src.accountableUuid
          ? { uuid: src.accountableUuid }
          : undefined,
        responsible: src.responsibleUuid
          ? { uuid: src.responsibleUuid }
          : undefined,
        assignee: src.assigneeUuid ? { uuid: src.assigneeUuid } : undefined,
        scheduledStartDate: src.scheduledStartDate
          ? dateTermVoService.format(src.scheduledStartDate)
          : undefined,
        scheduledEndDate: src.scheduledEndDate
          ? dateTermVoService.format(src.scheduledEndDate)
          : undefined,
        actualStartDate: src.actualStartDate
          ? dateTermVoService.format(src.actualStartDate)
          : undefined,
        actualEndDate: src.actualEndDate
          ? dateTermVoService.format(src.actualEndDate)
          : undefined,
        tags: src.tagUuids || [],
        blank: src.blank,
        createdAt: src.createdAt?.serializeForApi(),
        updatedAt: src.updatedAt?.serializeForApi(),
        createdBy: src.createdByUuid ? { uuid: src.createdByUuid } : undefined,
        updatedBy: src.updatedByUuid ? { uuid: src.updatedByUuid } : undefined,
      }
    },
    [projectUuid]
  )
  return { toApiRequest }
}
