// TODO: Consider putting this in model/value-object/CodeVO
export const generateCode = (existingCode: string[]): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const length = 8
  const generateCode = () => {
    return Array.from(crypto.getRandomValues(new Uint8Array(length)))
      .map(n => characters[n % characters.length])
      .join('')
  }
  let code = generateCode()
  while (existingCode.includes(code)) {
    code = generateCode()
  }
  return code
}
