import { useState } from 'react'
import { AggregateRoot } from './useMasterScheduleParameter'

export type ProgressDashboardParameter = {
  root: AggregateRoot | undefined
  setRoot: (_: AggregateRoot | undefined) => void
}
export const useProgressDashboardParameter = (): ProgressDashboardParameter => {
  const [root, setRoot] = useState<AggregateRoot>()

  return {
    root,
    setRoot,
  }
}
