import { OrganizationDetail } from '../lib/functions/tenant'

enum ActionType {
  LOGOUT_FROM_TENANT = 'LOGOUT_FROM_TENANT',
  RECEIVED_TENANT = 'RECEIVED_TENANT',
  RECEIVED_MAINTENANCE_MESSAGE = 'RECEIVED_MAINTENANCE_MESSAGE',
}

type State = { organization?: OrganizationDetail; maintenanceMessage?: string }

export const logoutFromTenant = () => ({
  type: ActionType.LOGOUT_FROM_TENANT,
})

export const receivedTenant = (organization: OrganizationDetail) => ({
  type: ActionType.RECEIVED_TENANT,
  organization,
})

export const receivedMaintenanceMessage = (message: string) => ({
  type: ActionType.RECEIVED_MAINTENANCE_MESSAGE,
  message,
})

export const reducer = (state: State = {}, action: any): State => {
  switch (action.type) {
    case ActionType.LOGOUT_FROM_TENANT:
      return { organization: undefined }
    case ActionType.RECEIVED_TENANT:
      return {
        organization: action.organization,
        maintenanceMessage: action.organization.maintenanceMessage,
      }
    case ActionType.RECEIVED_MAINTENANCE_MESSAGE:
      return { maintenanceMessage: action.message }
    default:
      return state
  }
}
