import React, { useEffect, useState } from 'react'
import { EditableCallbackParams, ICellRendererParams } from 'ag-grid-community'
import { gridRootStyle } from '../../../..'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import './style.scss'
import { Create as CreateIcon } from '@mui/icons-material'
import { normalize } from '../../../../../../../../utils/multilineText'
import { listItemHandler } from '../../../../../../../../utils/markdown'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import Tooltip from '../../../../../../../components/tooltips/Tooltip'
import { Box } from '@mui/material'
import { setError } from '../../../../../../BulkSheetView/lib/validation'
import validator from '../../../../../../meta/validator'
import { FloatingEditableIcon } from '../../common/text'

interface Props extends ICellRendererParams<string> {
  value: string
  uiMeta: FunctionProperty
}

const MultiLineTextCell = (props: Props) => {
  const { value, data, node, uiMeta, colDef } = props
  const [message, setMessage] = useState<string | undefined>()

  const isEditable: boolean = !colDef
    ? false
    : typeof colDef.editable === 'function'
    ? colDef.editable({ ...props, ...node } as EditableCallbackParams)
    : colDef.editable ?? false

  useEffect(() => {
    const err = uiMeta
      ? validator.validate(value, data, uiMeta, () => undefined)?.getMessage()
      : undefined
    setError(data, err, props)
    setMessage(err)
  }, [value])

  const text = normalize(value || '')
  return (
    <Tooltip message={message}>
      <Box
        sx={{
          ...gridRootStyle,
          backgroundColor: !!message ? '#FADBD8' : undefined,
          alignItems: 'start',
          overflow: 'scroll',
          padding: '0 0 0 6px',
          display: 'flex',
          '&:hover $editableHoverIcon': {
            opacity: '1',
          },
        }}
      >
        <Box className={`markdown-body ag-grid-markdown-body`}>
          <ReactMarkdown
            children={text}
            className={'ag-grid-cell-markdown'}
            linkTarget={'_blank'}
            remarkPlugins={[remarkGfm]}
            remarkRehypeOptions={{
              handlers: {
                listItem(h, node, parent) {
                  return listItemHandler(h, node, parent)
                },
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            transform: 'translate(0, -50%)',
          }}
          className="editable-icon"
        >
          {isEditable && <CreateIcon sx={{ fontSize: '12pt' }} />}
        </Box>
      </Box>
    </Tooltip>
  )
}

export default MultiLineTextCell
