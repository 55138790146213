import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { Comment } from '../../../../store/comments'
import { intl } from '../../../../i18n'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'

const UserNameLabel = styled('span')({
  fontWeight: 'bold',
})
const DateTimeLabel = styled('span')({
  color: '#616061',
})

const CommentHeader = ({
  className,
  comment,
}: {
  className?: string
  comment: Comment
}) => {
  const formatDate = (createdAt: number): string | undefined => {
    const target: DateTimeVO = new DateTimeVO(createdAt)
    if (target.isToday()) {
      return (
        intl.formatMessage({ id: 'comment.header.today' }) +
        ' ' +
        target.toString('HH:mm')
      )
    } else {
      return target.toString('YYYY/MM/DD (ddd) HH:mm')
    }
  }
  return (
    <Box className={className}>
      <UserNameLabel>{comment.createdBy?.name}</UserNameLabel>
      &nbsp;&nbsp;
      <DateTimeLabel>{formatDate(comment.createdAt)}</DateTimeLabel>
    </Box>
  )
}

export default CommentHeader
