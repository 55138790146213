import { Typography } from '@mui/material'

const AnnotationMark = () => {
  return (
    <Typography
      sx={{
        width: '6px',
        height: '6px',
        borderRadius: '8px',
        position: 'absolute',
        fontWeight: 'bold',
        backgroundColor: '#DA005C',
        textAlign: 'center',
        padding: '0 2px',
        margin: '0 0 16px 14px',
      }}
    />
  )
}

export default AnnotationMark
