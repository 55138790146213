import { useWbsItemSearchConditionSerializeService } from './../../transform-service/wbsItemSearchConditionSerializeService'
import { useCallback, useMemo } from 'react'
import { SearchConditionAndColumnState } from '../../model/searchConditionAndColumnState'
import { PermanentUiState } from '../../model/uiState'
import {
  SavePermanentUiStateRequest,
  usePermanentUiStateStorageService,
} from './permanentUiStateStorageService'
import { useWbsItemSearchConditionFactory } from '../../factories/wbsItemSearchConditionFactory'

export type SearchConditionAndColumnStateStorageService = {
  stored: PermanentUiState<SearchConditionAndColumnState>[]
  fetch: () => Promise<void>
  save: (
    request: SavePermanentUiStateRequest<SearchConditionAndColumnState>
  ) => Promise<void>
  delete: (uuid: string) => Promise<void>
  getByCode: (
    code: string
  ) => Promise<PermanentUiState<SearchConditionAndColumnState> | undefined>
}

export const useSearchConditionAndColumnStateStorageService = (
  functionUuid: string,
  projectUuid: string
): SearchConditionAndColumnStateStorageService => {
  const permanentUiStateStorageService = usePermanentUiStateStorageService(
    functionUuid,
    'BULK_SHEET_UI_STATE_SEARCH_CONDITION',
    projectUuid
  )

  const searchConditionSerializeService =
    useWbsItemSearchConditionSerializeService()
  const searchConditionFactory = useWbsItemSearchConditionFactory()

  const stored = useMemo(() => {
    return permanentUiStateStorageService.stored.map(s => {
      const storedState = s.value
      return {
        ...s,
        value: {
          searchCondition: searchConditionFactory.fromApiResponseToVo(
            s.value.searchCondition
          ),
          columnState: storedState.columnState,
        },
      }
    })
  }, [permanentUiStateStorageService.stored])

  const fetch = useMemo(
    () => permanentUiStateStorageService.fetch,
    [permanentUiStateStorageService.fetch]
  )

  const save = useCallback(
    async (
      request: SavePermanentUiStateRequest<SearchConditionAndColumnState>
    ) => {
      const value = request.value
      const plainValue = {
        searchCondition: searchConditionSerializeService.fromVoToStoredObject(
          value.searchCondition
        ),
        columnState: value.columnState,
      }
      const deserializedRequest = {
        ...request,
        value: plainValue,
      }
      permanentUiStateStorageService.save(deserializedRequest)
    },
    [permanentUiStateStorageService.save]
  )

  const deleteState = useMemo(
    () => permanentUiStateStorageService.delete,
    [permanentUiStateStorageService.delete]
  )

  // TODO: Consider further.
  const getByCode = useCallback(
    async (code: string) => {
      const response = await permanentUiStateStorageService.getByCode(code)
      if (!response) return undefined
      const storedState = response.value
      return {
        ...response,
        value: {
          searchCondition: searchConditionFactory.fromApiResponseToVo(
            storedState.searchCondition
          ),
          columnState: storedState.columnState,
        },
      }
    },
    [permanentUiStateStorageService.getByCode]
  )

  return {
    stored,
    fetch,
    save,
    delete: deleteState,
    getByCode,
  }
}
