import { decode } from 'html-entities'

const LINK_REGEX = /(https?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!\{\}]+)/g

// // Conversion for html
export const convertStringToHtml = (src: string): string => {
  let html = src.replace(/ /g, '&nbsp;')
  html = convertStringToHtmlNewLine(html)
  return convertStringToHtmlLink(html)
}

export const convertStringToHtmlNewLine = (src: string): string => {
  return src.replace(/<\/p>\n<p>/g, '<br>').replace(/<\/p><p>/g, '<br>')
}

export const convertStringToHtmlLink = (src: string): string => {
  return src.replace(
    LINK_REGEX,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
  )
}

export const splitStringByHtmlLink = (src: string): string[] => {
  return src.split(LINK_REGEX)
}

export const isLink = (src: string): boolean => {
  return new RegExp(LINK_REGEX).test(src)
}

// HTML utilities
export const isHtml = (src: string) => {
  if (!src) {
    return false
  }
  const tagReplaced = src.replace(/<.+?>.*<\/.+>|\s/g, '')
  return tagReplaced.length === 0
}

export const decodeHtmlCharCode = (str: string) => {
  return str.replace(/&#?([\da-zA-Z]+;)/g, match => decode(match))
}
