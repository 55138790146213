import { useCallback, useMemo, useState } from 'react'
import { useSingleSelectOption } from './singleSelectOption'
import { SelectOption } from '../../../model/selectOption'

export type BurndownChartSearchCondition = {
  type: string | undefined
}
export type UpdateBurndownChartSearchCondition = {
  onChangeType: (v: SelectOption | undefined) => void
}
export const useSearchCondition = (): BurndownChartSearchCondition &
  UpdateBurndownChartSearchCondition => {
  const [type, onChangeType] = useSingleSelectOption('TASK')
  return {
    type,
    onChangeType,
  }
}
