import AppBar from '@mui/material/AppBar'
import { styled } from '@mui/system'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import {
  Component,
  Function as ApplicationFunction,
  FunctionProperty,
  Position,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import { Attachment } from '../../../../utils/attachment'
import { AllState } from '../../../../store'
import Comments from '../../Comment/index'
import RevisionList from '../../../components/tables/RevisionList'
import TaskActualWorkList from '../../../components/tables/TaskActualWorkList'
import AttachmentEditor from '../../meta/AttachmentEditor'
import ViewMeta from '../../meta/ViewMeta'
import { BreadCrumb } from '../../../../store/breadcrumb'
import objects from '../../../../utils/objects'
import ProjectPlanTabContainer from '.././ProjectPlanTabContainer'
import { muiTheme } from '../../../../styles/muiTheme'
import validator, { ValidationError } from '../../meta/validator'
import { MultilineTextEditorTab } from './MultilineTextEditorTab'
import { DevelopmentPanelArea } from '../../../components/tables/DevelopmentPanel'
import MultiLineTextVO from '../../../../vo/MultiLineTextVO'
import { BorderColor } from '../../../../styles/commonStyles'

// Styles
const theme = muiTheme
const RootDiv = styled('div')<{ position: Position }>(({ position }) => {
  const defaultStyle: any = {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }
  if (position === Position.Right) {
    return {
      ...defaultStyle,
      height: '100%',
      flex: '1 1 0%',
      borderLeft: `1px solid ${BorderColor.LIGHT_BLACK}`,
    }
  }
  return defaultStyle
})
const StyledAppBar = styled(AppBar)({
  backgroundColor: 'transparent',
  padding: '0 4px',
})
const StyledTab = styled(Tab)({
  minWidth: '100px',
  minHeight: '32px',
  padding: '0 5px',
  color: '#262626',
  fontWeight: 'bold',
})
const TabPanelBorder = styled('div')({
  margin: '0 5px',
})
const TabPanelContent = styled('div')({
  height: '100%',
  width: 'auto',
  overflow: 'hidden',
  opacity: 1,
  borderRadius: 5,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  position: 'relative',
  zIndex: theme.zIndex.drawer - 1,
  background: theme.palette.background.default,
  margin: '0 5px',
})
const TablePanel = styled('div')({
  height: '100%',
  width: 'auto',
  overflow: 'auto',
  position: 'relative',
  zIndex: theme.zIndex.drawer - 1,
  background: theme.palette.background.default,
  margin: '0 9px',
})
const DiscussionPanel = styled('div')({
  height: '100%',
  width: '100%',
  overflow: 'auto',
  opacity: 1,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  '& .rdw-editor-wrapper': {
    marginLeft: 8,
  },
})

export const TabPanel = styled('div')({
  height: '100%',
  overflow: 'hidden',
})

// Interface
interface Props extends WrappedComponentProps {
  properties: FunctionProperty[]
  data: any
  functionUuid: string
  viewMeta: ViewMeta
  dataUuid?: string | string[]
  dataLockVersion?: number
  projectUuid?: string
  functions: ApplicationFunction[]
  position: Position
  table?: JSX.Element
  defaultIndex?: number
  onUploadAttachments?: (
    externalId: string,
    attachments: Attachment[]
  ) => void | Promise<void>
  onDeleteAttachments?: (
    externalId: string,
    attachments: Attachment[]
  ) => void | Promise<void>
  breadcrumbs?: BreadCrumb
  onChange: (data: any, changeValue: { [key: string]: any }) => void
  commentHeaderCompornent?: JSX.Element[]
  setError?: (key: string, value: ValidationError | undefined) => void
}

interface State {
  tabIndex: number
  code?: string
}

class TabView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tabIndex: props.defaultIndex || 0,
    }
  }

  async componentDidMount() {
    const { dataUuid } = this.props
    let uuid: string | undefined = undefined
    if (dataUuid) {
      uuid = typeof dataUuid === 'object' ? dataUuid[0] : dataUuid
    }
    if (!uuid) {
      // Show revision tab because entity may be deleted.
      const path = window.location.pathname.split('/').filter(v => !!v)
      const code = path[path.length - 1]
      if (code) {
        this.setState({ code })
      }
    }
  }

  onTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    })
  }

  allyProps = (index: any) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  createTabs = () => {
    const tabs: JSX.Element[] = []
    const { properties } = this.props
    const headers = properties.map(v => v.name)
    for (let i = 0; i < headers.length; i++) {
      tabs.push(
        <StyledTab key={headers[i]} label={headers[i]} {...this.allyProps(i)} />
      )
    }
    return tabs
  }

  setData = (path: string, value: any) => {
    if (!value || objects.isEmpty(value)) {
      if (path in this.props.data) {
        delete this.props.data[path]
      }
    } else {
      objects.setValue(this.props.data, path, value)
    }
    this.props.onChange(this.props.data, { [path]: value })
  }

  createTabPanels = () => {
    const tabPanels: JSX.Element[] = []
    const { properties } = this.props
    properties.forEach((property, index) => {
      if (index === this.state.tabIndex) {
        tabPanels.push(this.createPanelFromPropertyType(property, index))
      }
    })
    return tabPanels
  }

  private createPanelFromPropertyType = (prop: FunctionProperty, i: number) => {
    const propName = this.props.viewMeta.makeDataPropertyName(prop)
    if (prop.propertyType === PropertyType.MultiLineText) {
      const dataUuid = Array.isArray(this.props.dataUuid)
        ? this.props.dataUuid[0]
        : this.props.dataUuid
      const value = objects.getValue(this.props.data, propName)
      return (
        <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
          <MultilineTextEditorTab
            externalId={prop.externalId}
            value={value instanceof MultiLineTextVO ? value.getValue() : value}
            dataUuid={dataUuid}
            dataLockVersion={this.props.dataLockVersion}
            validate={value => {
              const result = validator.validate(value, {}, prop, () => ({}))
              const message = result?.getMessage()
              if (this.props.setError) {
                const messageKey =
                  this.props.viewMeta.makeDataPropertyName(prop)
                if (message) {
                  this.props.setError(messageKey, result)
                } else {
                  this.props.setError(messageKey, undefined)
                }
              }
              return message
            }}
            onChange={value => {
              this.setData(propName, value)
            }}
          />
        </TabPanel>
      )
    }
    if (prop.propertyType === PropertyType.File) {
      return (
        <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
          <TabPanelBorder />
          <TabPanelContent key={prop.externalId}>
            <AttachmentEditor
              attachments={objects.getValue(this.props.data, propName) || []}
              setValue={value => {
                this.setData(propName, value)
              }}
              accept={prop.fileFormat}
              onUploadAttachments={async attachments => {
                if (this.props.onUploadAttachments) {
                  await this.props.onUploadAttachments(
                    prop.externalId,
                    attachments
                  )
                }
              }}
              onDeleteAttachments={async attachments => {
                if (this.props.onDeleteAttachments) {
                  await this.props.onDeleteAttachments(
                    prop.externalId,
                    attachments
                  )
                }
              }}
            />
          </TabPanelContent>
        </TabPanel>
      )
    }
    if (
      prop.propertyType === PropertyType.Revision &&
      (this.props.dataUuid || this.state.code)
    ) {
      return (
        <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
          <TabPanelBorder />
          <TabPanelContent key={prop.externalId}>
            <RevisionList
              uuid={this.props.dataUuid!}
              lockVersion={this.props.dataLockVersion}
              viewMeta={this.props.viewMeta}
              code={this.state.code}
            />
          </TabPanelContent>
        </TabPanel>
      )
    }
    const dataUuid =
      typeof this.props.dataUuid === 'object'
        ? this.props.dataUuid[0]
        : this.props.dataUuid
    if (prop.propertyType === PropertyType.Custom) {
      if (prop.component === Component.Table) {
        return (
          (
            <TablePanel key={`tab-panel-${i}-${prop.externalId}`}>
              {this.props.table}
            </TablePanel>
          ) || <></>
        )
      } else if (prop.component === Component.ActualResult) {
        return (
          <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
            <TabPanelBorder />
            <TabPanelContent key={prop.externalId}>
              <TaskActualWorkList taskUuid={dataUuid} showOnlyList={true} />
            </TabPanelContent>
          </TabPanel>
        )
      } else if (
        prop.component === Component.ProjectPlan &&
        this.props.dataUuid
      ) {
        return (
          <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
            <TabPanelBorder />
            <TabPanelContent key={prop.externalId}>
              <ProjectPlanTabContainer
                wbsItemUuid={
                  Array.isArray(this.props.dataUuid)
                    ? this.props.dataUuid[0]
                    : this.props.dataUuid
                }
              />
            </TabPanelContent>
          </TabPanel>
        )
      } else if (prop.component === Component.Discussion) {
        return (
          <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
            <TabPanelBorder />
            <DiscussionPanel key={prop.externalId}>
              <Comments
                applicationFunctionUuid={this.props.functionUuid}
                dataUuid={dataUuid}
                headerComponents={this.props.commentHeaderCompornent}
                projectUuid={this.props.projectUuid}
              />
            </DiscussionPanel>
          </TabPanel>
        )
      } else if (prop.component === Component.Development) {
        const dataUuid = this.props.dataUuid!
        const uuid = Array.isArray(dataUuid) ? dataUuid[0] : dataUuid
        return (
          <TabPanel key={`tab-panel-${i}-${prop.externalId}`}>
            <TabPanelBorder />
            <TabPanelContent key={prop.externalId}>
              <DevelopmentPanelArea uuid={uuid} />
            </TabPanelContent>
          </TabPanel>
        )
      } else {
        throw new Error(`${prop.component} is not implemented.`)
      }
    }
    return <></>
  }

  render = () => {
    return (
      <RootDiv position={this.props.position}>
        <StyledAppBar key="tabview" position="static">
          <Tabs
            value={this.state.tabIndex}
            onChange={this.onTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {this.createTabs()}
          </Tabs>
        </StyledAppBar>
        {this.createTabPanels()}
      </RootDiv>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(injectIntl(TabView))
