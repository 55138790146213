import { styled } from '@mui/system'
import { FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import {
  ReportDataManager,
  ReportDataSeries,
  ViewConfigurationBase,
} from '../../../../../lib/functions/report'
import {
  StackedBarChartConfigSpec,
  StackedBarChartConfiguration,
  StackedBarChartKey,
} from './index'

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const Label = styled('span')({
  fontSize: FontSize.SMALL,
})

export const StackedBarLegendWrapper = <
  K,
  D,
  V extends ViewConfigurationBase
>(props: {
  data: ReportDataSeries<K, D>[]
  dataManager: ReportDataManager<K, D, V>
  chartConfig: StackedBarChartConfiguration
  chartConfigSpec: StackedBarChartConfigSpec<K>
  onChangeConfig: (newConfig: StackedBarChartConfiguration) => void
}) => {
  const { data, dataManager, chartConfigSpec, chartConfig, onChangeConfig } =
    props
  return (
    <RootContainer>
      <Label>{intl.formatMessage({ id: 'progressReport.legend' })}</Label>
      {data.map((d, i) => {
        return chartConfig.displayKeys.map((k, j) => (
          <StackedBarLegend
            key={j}
            chartKey={k}
            data={d}
            chartConfigSpec={chartConfigSpec}
            chartConfig={chartConfig}
            dataManager={dataManager}
            onChangeConfig={onChangeConfig}
          />
        ))
      })}
    </RootContainer>
  )
}

const LegentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '17px',
  padding: '2px 0 2px 5px',
})
const ColorLegend = styled('div')({
  height: '10px',
  width: '10px',
})
const LegendLabel = styled('span')({
  fontSize: FontSize.SMALL,
  margin: '0 6px',
})

const StackedBarLegend = <K, D, V extends ViewConfigurationBase>(props: {
  chartKey: StackedBarChartKey
  data: ReportDataSeries<K, D>
  dataManager: ReportDataManager<K, D, V>
  chartConfigSpec: StackedBarChartConfigSpec<K>
  chartConfig: StackedBarChartConfiguration
  onChangeConfig: (newConfig: StackedBarChartConfiguration) => void
}) => {
  const {
    chartKey,
    data,
    chartConfigSpec,
    dataManager,
    chartConfig,
    onChangeConfig,
  } = props
  const graphConfig = chartConfigSpec.getGraphConfig(chartConfig, data.key)
  if (!graphConfig) {
    return <></>
  }
  const color = graphConfig.color[chartKey.key]
  const borderColor = graphConfig.borderColor[chartKey.key] || 'transparent'
  return (
    <LegentContainer>
      <ColorLegend
        style={{ backgroundColor: color, border: `1px solid ${borderColor}` }}
      />
      <LegendLabel>{chartKey.label}</LegendLabel>
    </LegentContainer>
  )
}
