import {
  DateTermV2,
  dateTermV2Service,
} from '../../../../domain/value-object/DateTermV2'
import { DateV2, dateV2Service } from '../../../../domain/value-object/DateV2'
import { IItemDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'

export const isTextValueDiffered = (
  oldValue: string | undefined,
  newValue: string | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

export const isNumberValueDiffered = (
  oldValue: number | undefined,
  newValue: number | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

export const isBooleanValueDiffered = (
  oldValue: boolean | undefined,
  newValue: boolean | undefined
): boolean => {
  return (!!oldValue && !newValue) || (!oldValue && !!newValue)
}

export const isSelectValueDiffered = (
  oldValue: string | undefined,
  newValue: string | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue !== newValue
}

export const isReferencedEntityValueDiffered = (
  oldValue: ReferencedEntity | undefined,
  newValue: ReferencedEntity | undefined
): boolean => {
  if (!oldValue && !newValue) return false
  if (!oldValue || !newValue) return true
  return oldValue.uuid !== newValue.uuid
}

export const isDateValueDiffered = (
  oldValue: DateV2 | undefined,
  newValue: DateV2 | undefined
): boolean => {
  return !dateV2Service.isEqual(oldValue, newValue)
}

export const isDateTermValueDiffered = (
  oldValue: DateTermV2 | undefined,
  newValue: DateTermV2 | undefined
): boolean => {
  return !dateTermV2Service.isEqual(oldValue, newValue)
}

export const toTextDeltaValue = (
  oldValue: string | undefined,
  newValue: string | undefined
): IItemDelta<string> => {
  return {
    oldValue,
    newValue,
  }
}

export const toNumberDeltaValue = (
  oldValue: number | undefined,
  newValue: number | undefined
): IItemDelta<number> => {
  return {
    oldValue,
    newValue,
  }
}

export const toBooleanDeltaValue = (
  oldValue: boolean | undefined,
  newValue: boolean | undefined
): IItemDelta<boolean> => {
  return {
    oldValue,
    newValue,
  }
}

export const toSelectDeltaValue = (
  oldValue: string | undefined,
  newValue: string | undefined
): IItemDelta<string> => {
  return {
    oldValue,
    newValue,
  }
}

export const toReferencedEntityDeltaValue = (
  oldValue: ReferencedEntity | undefined,
  newValue: ReferencedEntity | undefined
): IItemDelta<string> => {
  return { oldValue: oldValue?.uuid, newValue: newValue?.uuid }
}

export const toDateDeltaValue = (
  oldValue: DateV2 | undefined,
  newValue: DateV2 | undefined
): IItemDelta<DateV2> => {
  return {
    oldValue,
    newValue,
  }
}

export const toDateTermDeltaValue = (
  oldValue: DateTermV2 | undefined,
  newValue: DateTermV2 | undefined
): IItemDelta<DateTermV2> => {
  return {
    oldValue,
    newValue,
  }
}
