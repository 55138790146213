import { useCallback, useState } from 'react'
import { BoolFilterOperator } from './index'
import { intl } from '../../../../../../i18n'

export const useBoolFilter = (): {
  exists: boolean
  setExists: (_: boolean) => void
  notExists: boolean
  setNotExists: (_: boolean) => void
  isActive: () => boolean
  isAllDeselected: () => boolean
  model: () => { operator: BoolFilterOperator } | undefined
  reset: () => void
  getModelAsString: () => string
} => {
  const [exists, setExists] = useState(true)
  const [notExists, setNotExists] = useState(true)

  const reset = useCallback(() => {
    setExists(true)
    setNotExists(true)
  }, [])

  const isActive = useCallback(
    () => !(exists && notExists),
    [exists, notExists]
  )

  const isAllDeselected = useCallback(
    () => !exists && !notExists,
    [exists, notExists]
  )

  const model = useCallback(() => {
    if (isActive()) {
      return {
        operator: isAllDeselected()
          ? BoolFilterOperator.ALL_DESELECTED
          : exists
          ? BoolFilterOperator.EXIST
          : BoolFilterOperator.NOT_EXIST,
      }
    }
    return undefined
  }, [exists, notExists])

  const getModelAsString = useCallback(() => {
    if (!isActive()) return ''
    if (isAllDeselected()) return '(Not Selected)'
    if (exists) return intl.formatMessage({ id: 'exist' })
    if (notExists) return intl.formatMessage({ id: 'not.exist' })
    return ''
  }, [exists, notExists])

  return {
    exists,
    setExists,
    notExists,
    setNotExists,
    isActive,
    isAllDeselected,
    model,
    reset,
    getModelAsString,
  }
}
