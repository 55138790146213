import { ColDef } from 'ag-grid-community'
import { Collapse } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { PageArea } from '..'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { DivisionDetail } from '../../../lib/functions/division'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  BulkSheetState,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import DivisionsOptions, {
  ColumnQuickFilterKey,
  DivisionRow,
} from './DivisionsOptions'
import DivisionsHeader from './Header'
import DivisionsToolbar from './Toolbar'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'

type DivisionsPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const divisionsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const Divisions = props => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        DivisionDetail,
        DivisionRow,
        BulkSheetState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const divisionsOptions = useMemo(() => new DivisionsOptions(), [])

  const { toolbar, updatePageState } = usePageState<DivisionsPageState>(
    props.uuid,
    divisionsDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  return (
    <PageArea>
      <DivisionsHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        filteredColumns={filteredColumns}
        sortedColumns={sortedColumns}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        onCancel={() => bulkSheet?.onCancel()}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <DivisionsToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={divisionsOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

export default pageComponent(Divisions)
