import {
  ClickAwayListener,
  IconButton,
  ListItemButton,
  Popper,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import { useCallback, useMemo, useRef, useState } from 'react'
import { SelectOption } from '../../../model/selectOption'

type SelectProps = {
  options: SelectOption[]
  value: string | undefined
  onSelect: (option: SelectOption) => void
  placeholder?: string
} & SelectStyleProps
export const Select = ({
  options,
  value,
  onSelect,
  placeholder,
  width,
  height,
}: SelectProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const openList = useCallback(() => {
    setOpen(true)
  }, [])
  const ref = useRef<HTMLDivElement>(null)
  const selected = useMemo(
    () => options.find(option => option.value === value),
    [options, value]
  )
  const label = useMemo(
    () => selected?.name || placeholder || '',
    [placeholder, selected]
  )
  const onClose = useCallback(() => {
    setOpen(false)
  }, [])
  const onSelectOption = useCallback(
    (option: SelectOption) => {
      onSelect(option)
      onClose()
    },
    [onClose, onSelect]
  )
  return (
    <>
      <SelectBoxDiv ref={ref} width={width} height={height}>
        <SelectBoxValue>{label}</SelectBoxValue>
        <SelectBoxIconButton onClick={openList}>
          <SelectBoxIcon />
        </SelectBoxIconButton>
      </SelectBoxDiv>
      <Popper open={open} anchorEl={ref.current} placement="bottom-start">
        <ClickAwayListener onClickAway={onClose}>
          <SelectListDiv width={width}>
            {options.map((option, i) => (
              <SelectListItemButton
                key={i}
                onClick={() => onSelectOption(option)}
              >
                <SelectListItem>{option.name}</SelectListItem>
              </SelectListItemButton>
            ))}
          </SelectListDiv>
        </ClickAwayListener>
      </Popper>
    </>
  )
}

type SelectStyleProps = {
  width?: number
  height?: number
}
const DEFAULT_WIDTH = 150
const DEFAULT_HEIGHT = 32
const SelectBoxDiv = styled('div')(
  ({ width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }: SelectStyleProps) => ({
    display: 'flex',
    height: `${height}px`,
    minWidth: `${width}px`,
    padding: '0 8px',
    alignItems: 'center',
    backgroundColor: colorPalette.monotone[0],
    border: `1px solid ${colorPalette.monotone[2]}`,
    borderRadius: '4px',
    justifyContent: 'space-between',
  })
)

const SelectBoxValue = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[4],
})

const SelectBoxIconButton = styled(IconButton)({
  padding: '0',
})
const SelectBoxIcon = styled(ExpandMoreIcon)({
  color: colorPalette.monotone[4],
})

const SelectListDiv = styled('div')(
  ({ width = DEFAULT_WIDTH }: SelectStyleProps) => ({
    backgroundColor: colorPalette.monotone[0],
    padding: '4px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
    width: `${width}px`,
  })
)

const SelectListItemButton = styled(ListItemButton)({})
const SelectListItem = styled('div')({
  fontSize: '12px',
  color: colorPalette.monotone[4],
})
