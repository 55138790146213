import React, { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/SearchRounded'
import ClearIcon from '@mui/icons-material/ClearRounded'
import { IconButton } from '@mui/material'
import { muiTheme } from '../../../../styles/muiTheme'
import { intl } from '../../../../i18n'
import SearchButton from '../../../components/buttons/SearchButton'

const theme = muiTheme

interface Props {
  reloadHandler: () => void
  onChange: (v: string) => void
  total?: number
  hit?: number
  hideButton?: boolean
  searchText?: string
  searchTextPlaceholder?: string
  inputRef?: any
  children?: React.ReactNode
}

const HeaderSearchWindow = (props: Props) => {
  const {
    inputRef,
    children,
    hideButton,
    hit,
    total,
    searchText,
    searchTextPlaceholder,
    onChange,
    reloadHandler,
  } = props

  const valueChange = useCallback(
    event => {
      const text = event.target.value
      onChange(text)
    },
    [onChange]
  )

  const keyPressed = useCallback(
    event => {
      if (event.key === 'Enter') {
        reloadHandler()
      }
    },
    [reloadHandler]
  )

  const searchButtonClick = useCallback(() => {
    reloadHandler()
  }, [reloadHandler])

  const clearSearchText = useCallback(() => {
    onChange('')
  }, [onChange])

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          border: '1px solid #DDDDDD',
          borderRadius: theme.shape.borderRadius,
          display: 'flex',
          alignItems: 'center',
          height: '30px',
          marginRight: '5px',
        }}
      >
        <SearchIcon color="action" sx={{ margin: '10px' }} />
        <InputBase
          sx={{ color: 'inherit', width: '180px' }}
          placeholder={
            searchTextPlaceholder ||
            intl.formatMessage({ id: 'search.full.text' })
          }
          value={searchText}
          onChange={valueChange}
          onKeyPress={keyPressed}
          inputRef={inputRef}
        />
        <IconButton onClick={clearSearchText} sx={{ padding: '4px' }}>
          <ClearIcon color="action" />
        </IconButton>
        {children && <div style={{ display: 'inline-flex' }}>{children}</div>}
      </span>
      {!hideButton && (
        <Box pr="8px">
          <SearchButton onClick={searchButtonClick} />
        </Box>
      )}
      {hit !== undefined && total !== undefined && (
        <span style={{ whiteSpace: 'nowrap', marginRight: '5px' }}>
          {intl.formatMessage({ id: 'search.hitsTotal' }, { hit, total })}
        </span>
      )}
    </div>
  )
}

export default HeaderSearchWindow
