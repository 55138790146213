import { styled } from '@mui/material'
import { CSSProperties } from 'react'
import { colorPalette } from '../../style/colorPallete'

export const SingleSheetRoot = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%',
  border: `1px solid ${colorPalette.monotone[2]}`,
  backgroundColor: colorPalette.monotone[0],
})

type MainAreaProps = Pick<CSSProperties, 'width'>
export const MainArea = styled('div')(({ width }: MainAreaProps) => ({
  width,
  height: '100%',
}))
export const SideBarArea = styled('div')({
  height: '100%',
  flex: '1 1 0%',
})

export const PanelRoot = styled('div')({
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
})
