import { Box } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import uiStates, {
  generateCode as generateUIStateCode,
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import InputBase from '@mui/material/InputBase'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import AlertDialog from '../AlertDialog'
import SearchIcon from '../../../../assets/search_icon.svg'
import Auth from '../../../../lib/commons/auth'
import moment from 'moment'
import { formatDateTime } from '../../../../utils/date'
import SavedUIStateEditorDialog, {
  OptionUIStateListProps as EditorDialogOptionUIStateListProps,
} from './SavedUIStateEditorDialog'
import { generateUuid } from '../../../../utils/uuids'
import { FontSize } from '../../../../styles/commonStyles'
import { muiTheme } from '../../../../styles/muiTheme'
import _ from 'lodash'
import { Function } from '../../../../lib/commons/appFunction'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../pages'
import { patchColState } from './patchForMigrationFromProjectPlanQuickToBeta'
import { colorPalette } from '../../../style/colorPallete'
import CancelIcon from '../../../../assets/cancel_icon.svg'
import SavedUIStateListHeader from './contents/SavedUIStateListHeader'
import SavedUIStateListItem from './contents/SavedUIStateListItem'
import SvgPlusIcon from '../../icons/PlusIcon'
import { Button } from '../../buttons'

export type UIState = any

export interface CustomListItemProps {
  title: string
  onSelect: () => void
}

interface Props extends WrappedComponentProps {
  applicationFunctionUuid: string
  projectUuid: string
  functions: Function[]
  title: string
  sharable: boolean
  uiStateKey: UiStateKey
  currentUIState: UIState
  currentSavedUIStateUuid?: string
  onSelect: (UIState: SavedUIState) => void
  hiddenAddButton?: boolean
  hiddenSecondaryAction?: boolean
  displayBorder?: boolean
  notSetFocus?: boolean
  customListItemProps?: CustomListItemProps
  editorOptionUIStateListProps?: EditorDialogOptionUIStateListProps
}

export interface SavedUIState {
  uuid: string
  code: string
  name: string
  UIState: UIState
  scope: UiStateScope
  updatedBy?: string
  updatedAt?: string
  updateUserIconUrl?: string
}

const getUIStates = async (
  functionUuid: string,
  scope: UiStateScope,
  key: UiStateKey,
  projectUuid?: string
): Promise<SavedUIState[]> => {
  const response = await uiStates.get({
    applicationFunctionUuid: functionUuid,
    key: isProjectScope(scope, projectUuid) ? `${key}-${projectUuid}` : key,
    scope: scope,
  })
  return response.json.value
    ? (JSON.parse(response.json.value) as SavedUIState[])
    : []
}

export const updateUIStates = async (
  scope: UiStateScope,
  uiState: SavedUIState[],
  functionUuid: string,
  key: UiStateKey,
  projectUuid?: string
) => {
  uiStates.update(
    {
      key: isProjectScope(scope, projectUuid) ? `${key}-${projectUuid}` : key,
      scope: scope,
      value: JSON.stringify(uiState.filter(v => v.scope === scope)),
    },
    functionUuid
  )
}

const isProjectScope = (scope: UiStateScope, projectUuid?: string) => {
  return scope !== UiStateScope.CrossProject && projectUuid
}

export const getAllScopeUiState = async (
  applicationFunctionUuid: string,
  key: UiStateKey,
  projectUuid?: string,
  functions?: Function[]
) => {
  let uiStateForCrossProject = projectUuid
    ? await getUIStates(
        applicationFunctionUuid,
        UiStateScope.CrossProject,
        key,
        projectUuid
      )
    : []
  let uiStatesForProject = await getUIStates(
    applicationFunctionUuid,
    UiStateScope.Tenant,
    key,
    projectUuid
  )
  let uiStatesForUser = await getUIStates(
    applicationFunctionUuid,
    UiStateScope.User,
    key,
    projectUuid
  )

  // >>> Patch for migration from project plan quick to beta
  const betaUuid = APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_NEW_EDIT
  const oldUuid = APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_LAZYLOAD_EDIT
  const projectPlanBeta = functions?.find(v => v.externalId === betaUuid)
  const projectPlanQuick = functions?.find(v => v.externalId === oldUuid)
  if (
    projectPlanBeta &&
    projectPlanQuick &&
    applicationFunctionUuid === projectPlanBeta.uuid
  ) {
    uiStateForCrossProject = await patchColState(
      uiStateForCrossProject,
      () =>
        getUIStates(
          projectPlanQuick.uuid,
          UiStateScope.CrossProject,
          key,
          projectUuid
        ),
      uiState =>
        updateUIStates(
          UiStateScope.CrossProject,
          uiState,
          applicationFunctionUuid,
          key,
          projectUuid
        )
    )
    uiStatesForProject = await patchColState(
      uiStatesForProject,
      () =>
        getUIStates(
          projectPlanQuick.uuid,
          UiStateScope.Tenant,
          key,
          projectUuid
        ),
      uiState =>
        updateUIStates(
          UiStateScope.Tenant,
          uiState,
          applicationFunctionUuid,
          key,
          projectUuid
        )
    )
    uiStatesForUser = await patchColState(
      uiStatesForUser,
      () =>
        getUIStates(projectPlanQuick.uuid, UiStateScope.User, key, projectUuid),
      uiState =>
        updateUIStates(
          UiStateScope.User,
          uiState,
          applicationFunctionUuid,
          key,
          projectUuid
        )
    )
  }
  // <<< Patch for migration from project plan quick to beta

  const newUIStates: SavedUIState[] = [
    ...uiStateForCrossProject,
    ...uiStatesForProject,
    ...uiStatesForUser,
  ]
  return newUIStates
}

export const isShareableSavedUIState = (
  savedUIState: SavedUIState
): boolean => {
  return (
    savedUIState.scope === UiStateScope.Tenant ||
    savedUIState.scope === UiStateScope.CrossProject
  )
}

export const generateURLForSharedSearchConditionState = (
  savedUIState: SavedUIState
): string => {
  const currentUrl = window.location.href
  const urlWithoutQueryString = currentUrl.split('?')[0]
  return `${urlWithoutQueryString}?arg=${savedUIState.code}`
}

const useEditor = () => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)
  return { isOpen, open, close }
}

const useAlert = () => {
  const [message, setMessage] = useState('')
  const open = (message: string) => setMessage(message)
  const close = () => setMessage('')
  return { message, open, close }
}

const SavedUIStateList = (props: Props) => {
  const ref = useRef<HTMLInputElement>()

  const editor = useEditor()
  const alert = useAlert()

  const [savedUIStates, setSavedUIStates] = useState<SavedUIState[]>([])
  const [searchText, setSearchText] = useState('')
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const [selectedUIStateIndex, setSelectedUIStateIndex] = useState(
    props.customListItemProps ? 0 : -1
  )
  const [scopeFilter, setScopeFilter] = useState<UiStateScope[]>([
    UiStateScope.CrossProject,
    UiStateScope.Tenant,
    UiStateScope.User,
  ])
  const [userFilter, setUserFilter] = useState<string[]>()

  const initialize = useCallback(async () => {
    const uuid = props.applicationFunctionUuid
    const newUIStates = await getAllScopeUiState(
      uuid,
      props.uiStateKey,
      props.projectUuid,
      props.functions
    )

    let selectedIndex: number = -1
    if (!props.customListItemProps && props.currentSavedUIStateUuid) {
      selectedIndex = newUIStates.findIndex(
        s => s.uuid === props.currentSavedUIStateUuid
      )
    }
    setSavedUIStates(newUIStates)
    if (selectedIndex !== -1) {
      setSelectedUIStateIndex(selectedIndex)
    }
    if (ref.current && !!props.notSetFocus) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    initialize()
  }, [])

  const openEditor = useCallback(index => {
    editor.open()
    setSelectedIndex(index)
  }, [])

  const closeEditor = useCallback(() => {
    editor.close()
    setSelectedIndex(undefined)
  }, [])

  const saveUIStates = useCallback(
    (index: number, savedUIState: SavedUIState, optionUIState: UIState) => {
      const currentUIStates = savedUIStates
      let newUIState = optionUIState
        ? { ...props.currentUIState, ...optionUIState }
        : props.currentUIState

      currentUIStates[index] = {
        ...savedUIState,
        UIState: newUIState,
        updatedBy: Auth.getCurrentTenant()!.user!.name,
        updatedAt: formatDateTime(moment()),
      }
      setSavedUIStates(currentUIStates)
      setSelectedIndex(undefined)
      props.projectUuid &&
        updateUIStates(
          UiStateScope.CrossProject,
          currentUIStates,
          props.applicationFunctionUuid,
          props.uiStateKey,
          props.projectUuid
        )
      updateUIStates(
        UiStateScope.Tenant,
        currentUIStates,
        props.applicationFunctionUuid,
        props.uiStateKey,
        props.projectUuid
      )
      updateUIStates(
        UiStateScope.User,
        currentUIStates,
        props.applicationFunctionUuid,
        props.uiStateKey,
        props.projectUuid
      )
    },
    [savedUIStates]
  )

  const openAlert = useCallback((index: number, title: string) => {
    alert.open(title)
    setSelectedIndex(index)
  }, [])

  const closeAlert = useCallback(() => {
    alert.close()
    setSelectedIndex(undefined)
  }, [])

  const deleteUIState = useCallback(() => {
    if (!selectedIndex && selectedIndex !== 0) return
    const currentUIStates = _.cloneDeep(savedUIStates)
    const scope = currentUIStates[selectedIndex].scope
    currentUIStates.splice(selectedIndex, 1)
    alert.close()
    setSavedUIStates(currentUIStates)
    setSelectedIndex(undefined)
    updateUIStates(
      scope,
      currentUIStates,
      props.applicationFunctionUuid,
      props.uiStateKey,
      props.projectUuid
    )
  }, [selectedIndex, savedUIStates])

  const searchTextChanged = useCallback(event => {
    setSearchText(event.target.value)
  }, [])

  const copyUrl = useCallback((savedUIState: SavedUIState) => {
    if (!isSupportedURLCopy()) {
      return
    }

    const url = generateURLForSharedSearchConditionState(savedUIState)
    navigator.clipboard.writeText(url)
  }, [])

  const isSupportedURLCopy = useCallback(() => {
    return Boolean(navigator.clipboard)
  }, [])

  const generateUniqueCode = useCallback((): string => {
    return generateUIStateCode(savedUIStates.map(v => v.code))
  }, [savedUIStates])

  return (
    <Box
      sx={{
        width: '100%',
        ...(props.displayBorder
          ? {
              padding: '5px 0px 5px 3px',
              border: '1px solid #DDDDDD',
              borderRadius: muiTheme.shape.borderRadius,
            }
          : {}),
      }}
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            style={{
              color: colorPalette.monotone[10],
              fontSize: FontSize.LARGE,
              fontWeight: 600,
            }}
          >
            {props.intl.formatMessage({
              id: 'savedUIState.content.title.list',
            })}
          </span>
          {!props.hiddenAddButton && (
            <Button
              colorPattern="skyBlue"
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: FontSize.SMALL,
                cursor: 'pointer',
                marginLeft: '20px',
                color: colorPalette.skyBlue[7],
              }}
              onClick={_ => openEditor(savedUIStates.length)}
            >
              <SvgPlusIcon
                style={{
                  width: '16px',
                  height: '16px',
                  paddingRight: '4px',
                  color: colorPalette.skyBlue[7],
                }}
              />
              <span style={{ textAlign: 'center', fontSize: FontSize.MEDIUM }}>
                {props.intl.formatMessage({
                  id: 'savedUIState.content.title.createNew',
                })}
              </span>
            </Button>
          )}
        </Box>
        <Box
          sx={{
            border: `1px solid ${colorPalette.monotone[2]}`,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            height: '30px',
            marginRight: '5px',
          }}
        >
          <img src={SearchIcon} style={{ margin: '10px' }} />
          <InputBase
            inputRef={ref}
            placeholder={props.intl.formatMessage({
              id: 'savedUIState.content.search.placeholder',
            })}
            sx={{ color: 'inherit', width: '100%' }}
            value={searchText}
            onChange={searchTextChanged}
          />
          <img
            src={CancelIcon}
            style={{
              color: colorPalette.monotone[4],
              cursor: 'pointer',
              padding: '8px',
            }}
            onClick={() => setSearchText('')}
          />
        </Box>
      </Box>

      <Box
        sx={{
          maxHeight: '300px',
          overflowY: 'auto',
          marginRight: '5px',
          '& .MuiListItem-secondaryAction': {
            paddingRight: 0,
          },
        }}
      >
        <List
          component="nav"
          aria-label="saved-ui-state"
          sx={{ paddingTop: '20px' }}
        >
          {props.customListItemProps && (
            <ListItem
              button={true}
              onClick={_ => {
                setSelectedUIStateIndex(0)
                props.customListItemProps?.onSelect()
              }}
              selected={0 === selectedUIStateIndex}
            >
              <ListItemText>{props.customListItemProps.title}</ListItemText>
            </ListItem>
          )}
          {!props.customListItemProps && (
            <SavedUIStateListHeader
              checkedScope={scopeFilter}
              onChangeScopeFilter={scope => setScopeFilter(scope)}
              checkedUser={userFilter}
              onChangeUserFilter={user => setUserFilter(user)}
            />
          )}
          {savedUIStates.map((savedUIState, i) => {
            const index = props.customListItemProps ? i + 1 : i
            if (searchText && !savedUIState.name.includes(searchText)) {
              return <></>
            }
            return (
              <Box key={`ui-state${index}`}>
                <SavedUIStateListItem
                  name={savedUIState.name}
                  scope={savedUIState.scope}
                  updateUserIconUrl={savedUIState.updateUserIconUrl}
                  updatedBy={savedUIState.updatedBy}
                  updatedAt={savedUIState.updatedAt}
                  hiddenSecondaryAction={props.hiddenSecondaryAction}
                  onClickEditButton={() => openEditor(index)}
                  onClickApplyButton={() => {
                    setSelectedUIStateIndex(index)
                    if (props.onSelect) {
                      props.onSelect(savedUIState)
                    }
                  }}
                />
                {editor.isOpen && (
                  <SavedUIStateEditorDialog
                    savedUIState={savedUIState}
                    open={selectedIndex === index && editor.isOpen}
                    dialogTitle={props.intl.formatMessage({
                      id: 'savedUIState.update',
                    })}
                    submitButtonLabel={props.intl.formatMessage({
                      id: 'savedUIState.edit.button.overwrite',
                    })}
                    cancelButtonLabel={props.intl.formatMessage({
                      id: 'savedUIState.edit.button.cancel',
                    })}
                    onSubmit={(
                      UIState: SavedUIState,
                      optionUIState: UIState
                    ) => {
                      saveUIStates(index, UIState, optionUIState)
                    }}
                    onClose={closeEditor}
                    optionUIStateListProps={props.editorOptionUIStateListProps}
                    onDelete={() => {
                      openAlert(
                        index,
                        props.intl.formatMessage({
                          id: 'savedUIState.confirmDelete',
                        })
                      )
                    }}
                  />
                )}
              </Box>
            )
          })}
        </List>
      </Box>
      {editor.isOpen && (
        <SavedUIStateEditorDialog
          savedUIState={{
            uuid: generateUuid(),
            code: generateUniqueCode(),
            name: '',
            UIState: {},
            scope: UiStateScope.User,
          }}
          open={selectedIndex === savedUIStates.length && editor.isOpen}
          dialogTitle={props.intl.formatMessage({ id: 'savedUIState.save' })}
          dialogSubTitle={props.intl.formatMessage({
            id: 'savedUIState.add.dialog.subtitle',
          })}
          onSubmit={(uiState: SavedUIState, optionUIState: UIState) => {
            saveUIStates(savedUIStates.length, uiState, optionUIState)
          }}
          onClose={closeEditor}
          optionUIStateListProps={
            props.editorOptionUIStateListProps
              ? props.editorOptionUIStateListProps
              : undefined
          }
          submitButtonLabel={props.intl.formatMessage({
            id: 'savedUIState.add.button.save',
          })}
          cancelButtonLabel={props.intl.formatMessage({
            id: 'savedUIState.edit.button.cancel',
          })}
        />
      )}
      <AlertDialog
        isOpen={!!alert.message}
        message={alert.message}
        submitButtonTitle={props.intl.formatMessage({ id: 'dialog.ok' })}
        submitHandler={deleteUIState}
        closeButtonTitle={props.intl.formatMessage({
          id: 'dialog.cancel',
        })}
        closeHandler={closeAlert}
      />
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected || '',
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(injectIntl(SavedUIStateList))
