import { GridOptions, ProcessCellForExportParams } from 'ag-grid-community'
import { useCallback } from 'react'
import { intl } from '../../../../i18n'
import { BudgetResultType } from '../profitLossItems'
import { exportExcel } from '../../../containers/BulkSheet/excel'

export const useExportExcel = (gridOptions: GridOptions): (() => void) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined
      const colId: string = params.column.getColId()
      if (colId === 'body.type') {
        if (params.node?.data?.body?.type === BudgetResultType.Budget) {
          return intl.formatMessage({ id: 'profitLossItems.budget' })
        } else if (params.node?.data?.body?.type === BudgetResultType.Result) {
          return intl.formatMessage({ id: 'profitLossItems.result' })
        }
      } else if (colId === 'body.generalLedger') {
        if (!params.node?.data?.body?.type) {
          return params.node?.data?.body?.generalLedger?.displayName
        }
      } else if (colId === 'body.subsidiary') {
        if (!params.node?.data?.body?.type) {
          return params.node?.data?.body?.subsidiary?.displayName
        }
      } else if (colId === 'body.financialState') {
        if (!params.node?.data?.body?.type) {
          return params.node?.data?.body?.financialState?.displayName
        }
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'profit_loss_items',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      getCustomExportValue: customExportValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customExportValues, gridOptions.columnApi])

  return onExportExcel
}

export const useExportExcelSimple = (
  gridOptions: GridOptions
): (() => void) => {
  const customExportValues = useCallback(
    (params: ProcessCellForExportParams): string | undefined => {
      if (!params.node || params.node.rowIndex === null) return undefined
      const colId: string = params.column.getColId()
      if (colId === 'body.generalLedger') {
        return params.node?.data?.body?.generalLedger?.displayName
      } else if (colId === 'body.subsidiary') {
        return params.node?.data?.body?.subsidiary?.displayName
      } else if (colId === 'body.financialState') {
        return params.node?.data?.body?.financialState?.displayName
      } else if (colId === 'rowNumber') {
        if (!!params.node.data.isTotal) {
          return ' '
        }
        return String(params.node.rowIndex + 1)
      }
      return undefined
    },
    []
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'profit_loss_items',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
      getCustomExportValue: customExportValues,
    })
  }, [customExportValues, gridOptions])

  return onExportExcel
}
