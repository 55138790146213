import { AbsoluteDateVO } from '../DateVO'

export type YearMonthVO = {
  year: number
  month: number
}

// Constructors.
const newYearMonthVO = (
  year: number,
  month: number
): YearMonthVO | undefined => {
  if (month < 1 || month > 12) return undefined
  return {
    year,
    month,
  }
}

const newFromString = (dateString: string): YearMonthVO | undefined => {
  const dateWithSlashRegExp = /^(\d{4})\/(\d{1,2})\/(\d{1,2})/
  const withSlashMatch = dateString.match(dateWithSlashRegExp)
  if (!!withSlashMatch) {
    return newYearMonthVO(Number(withSlashMatch[1]), Number(withSlashMatch[2]))
  }
  const dateWithHyphenRegExp = /^(\d{4})-(\d{1,2})-(\d{1,2})/
  const withHyphenMatch = dateString.match(dateWithHyphenRegExp)
  if (!!withHyphenMatch) {
    return newYearMonthVO(
      Number(withHyphenMatch[1]),
      Number(withHyphenMatch[2])
    )
  }
}

const newFromNumber = (v: number): YearMonthVO | undefined => {
  if (v < 13) return undefined // minumum value is {year: 1, month: 1}.
  const year = Math.floor((v - 1) / 12)
  const month = v - year * 12
  return newYearMonthVO(year, month)
}

const now = (): YearMonthVO => {
  const nowDate = new Date()
  return {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
  }
}

// Comparator functions.
const isEqual = (a: YearMonthVO, b: YearMonthVO): boolean => {
  return a.year === b.year && a.month === b.month
}

const isGreaterThan = (a: YearMonthVO, b: YearMonthVO): boolean => {
  return a.year > b.year || (a.year === b.year && a.month > b.month)
}

const isGreatherThanOrEqual = (a: YearMonthVO, b: YearMonthVO): boolean => {
  return isEqual(a, b) || isGreaterThan(a, b)
}

const isLessThan = (a: YearMonthVO, b: YearMonthVO): boolean => {
  return a.year < b.year || (a.year === b.year && a.month < b.month)
}

const isLessThanOrEqual = (a: YearMonthVO, b: YearMonthVO): boolean => {
  return isEqual(a, b) || isLessThan(a, b)
}

// Calculate functions.
/**
 *
 * @param a
 * @param b
 * @returns a - b by month count.
 */
const diff = (a: YearMonthVO, b: YearMonthVO): number => {
  return (a.year - b.year) * 12 + (a.month - b.month)
}

const add = (base: YearMonthVO, diff: number): YearMonthVO => {
  const yearDiff = Math.floor((base.month + diff - 1) / 12)
  const month = (base.month + diff) % 12 || 12
  return newYearMonthVO(base.year + yearDiff, month)!
}

// Utility functions.
const fillRange = (from: YearMonthVO, to: YearMonthVO): YearMonthVO[] => {
  if (isGreaterThan(from, to)) {
    return []
  }
  const filled: YearMonthVO[] = []
  for (let y = from.year; y <= to.year; y++) {
    for (let m = 1; m <= 12; m++) {
      const yearMonth = newYearMonthVO(y, m)!
      // Ignore year-month before from.
      if (isLessThan(yearMonth, from)) {
        continue
      }
      // Ignore year-month after to.
      if (isGreaterThan(yearMonth, to)) {
        break
      }
      filled.push(yearMonth)
    }
  }
  return filled
}

const toString = (src: YearMonthVO): string => `${src.year}-${src.month}`

const getValue = (src: YearMonthVO): number => {
  return src.year * 12 + src.month
}

export const yearMonthService = {
  newYearMonthVO,
  newFromString,
  newFromNumber,
  now,
  isEqual,
  isGreaterThan,
  isGreatherThanOrEqual,
  isLessThan,
  isLessThanOrEqual,
  diff,
  add,
  fillRange,
  toString,
  getValue,
}
