import { styled } from '@mui/system'
import store, { AllState } from '../../../store'
import {
  Comment,
  deleteComment,
  generateEditingCommentUuidKey,
  startEditComment,
} from '../../../store/comments'
import Box from '@mui/material/Box'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVertRounded'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import AlertDialog from '../../components/dialogs/AlertDialog'
import CommentEditor from './CommentEditor'
import MarkupViewer from '../../components/viewers/MarkupViewer'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { FontSize } from '../../../styles/commonStyles'
import './styles.scss'
import CommentMessage from './CommentMessage'
import FileViewerDialog from '../../components/dialogs/FileViewerDialog'
import { FileType } from '../../../utils/attachment'

interface ComponentOwnProps {
  applicationFunctionUuid: string
  dataUuid?: string
  suffix?: string
  projectUuid?: string
  key: string
  comment: Comment
  index: any
  isCreatedByLoginUser: boolean
  // Use to avoid edit more than two comments at the same time.
  checkIsMenuOpen: Function
  setIsMenuOpen: Function
  hasEditingComment: boolean
}

interface StateProps {
  isEditing: boolean
}

interface Props extends WrappedComponentProps, ComponentOwnProps, StateProps {}

const RootContainer = styled('div')<{
  isEditing: boolean
  isHovered: boolean
}>(({ isEditing, isHovered }) => {
  if (isEditing) {
    return {
      margin: '0 0 8px',
      padding: '8px 0',
      backgroundColor: '#f2c7441a',
      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      },
      '&:last-child': {
        marginBottom: 0,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      },
    }
  }
  return {
    backgroundColor: isHovered ? '#f7f7f7' : undefined,
    position: 'relative',
    width: 'auto',
    fontSize: FontSize.MEDIUM,
    padding: '4px 4px',
    margin: '0px 4px',
    '&:first-child': {
      marginTop: '4px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    '&:last-child': {
      marginBottom: '4px',
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
  }
})
const MenuButton = styled(Box)({
  position: 'absolute',
  top: '1px',
  right: '1px',
  border: '1px solid #aaa',
  borderRadius: 5,
  backgroundColor: '#fff',
  lineHeight: '1em',
})
const MenuListPopper = styled(Popper)({
  zIndex: 1,
})
const StyledMenuItem = styled(MenuItem)({
  '&hover': {
    color: '#fff',
    backgroundColor: '#1264a1',
  },
})
const DeleteMenuItem = styled(MenuItem)({
  color: '#e01e5a',
  '&hover': {
    color: '#fff',
    backgroundColor: '#e01e5a',
  },
})

interface State {
  isHovered: boolean
  anchorEl: any
  isAlertDialogOpen: boolean
  fileViewerDialogState: {
    open: boolean
    name: string
    source: string
    size?: number
    url: string
    updatedBy: string
    updatedAt?: number
  }
}

class CommentItem extends React.Component<Props, State> {
  state = {
    isHovered: false,
    anchorEl: null,
    isAlertDialogOpen: false,
    fileViewerDialogState: {
      open: false,
      name: '',
      source: '',
      url: '',
      updatedBy: '',
    },
  }

  handleMouseEnter = () => {
    if (!this.props.checkIsMenuOpen()) {
      this.setState({ isHovered: true })
    }
  }
  handleMouseLeave = () => {
    if (!this.props.checkIsMenuOpen()) {
      this.setState({ isHovered: false, anchorEl: null })
    }
  }
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
    this.props.setIsMenuOpen(true)
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null, isHovered: false })
    this.props.setIsMenuOpen(false)
  }
  editMessage = () => {
    if (!this.props.hasEditingComment) {
      store.dispatch(
        startEditComment(
          this.props.applicationFunctionUuid,
          this.props.dataUuid!,
          this.props.suffix,
          this.props.comment.uuid
        )
      )
    }
    this.handleMenuClose()
  }
  handleAlertOpen = () => {
    this.setState({ isAlertDialogOpen: true })
    this.handleMenuClose()
  }
  handleAlertClose = () => {
    this.setState({ isAlertDialogOpen: false })
  }
  deleteMessage = () => {
    store.dispatch(
      deleteComment(
        this.props.applicationFunctionUuid,
        this.props.dataUuid!,
        this.props.comment.uuid
      )
    )
    this.handleAlertClose()
  }
  closeFileViewerDialog = () => {
    this.setState({
      fileViewerDialogState: {
        open: false,
        name: '',
        source: '',
        size: undefined,
        url: '',
        updatedBy: '',
        updatedAt: undefined,
      },
    })
  }
  openFileViewerDialog = ({ url, name }: { url: string; name: string }) => {
    this.setState({
      fileViewerDialogState: {
        open: true,
        name,
        source: FileType.LOCAL,
        size: undefined,
        url,
        updatedBy: this.props.comment.createdBy?.name || '',
        updatedAt: this.props.comment.createdAt,
      },
    })
  }

  render() {
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      <RootContainer
        isEditing={this.props.isEditing}
        isHovered={this.state.isHovered}
      >
        <Box
          display="flex"
          key={this.props.key}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {this.props.isEditing && (
            <CommentEditor
              applicationFunctionUuid={this.props.applicationFunctionUuid}
              dataUuid={this.props.dataUuid}
              suffix={this.props.suffix}
              projectUuid={this.props.projectUuid}
              comment={this.props.comment}
              isEditing={true}
            />
          )}
          {!this.props.isEditing && (
            <CommentMessage
              commentText={this.props.comment.text}
              onClickImage={this.openFileViewerDialog}
            />
          )}
          {!this.props.isEditing &&
            this.state.isHovered &&
            this.props.isCreatedByLoginUser && (
              <MenuButton>
                <IconButton size={'small'} onClick={this.handleMenuOpen}>
                  <MoreVertIcon color="action" fontSize="small" />
                </IconButton>
              </MenuButton>
            )}
          <MenuListPopper
            open={open}
            anchorEl={anchorEl}
            placement={'left'}
            disablePortal={true}
          >
            <ClickAwayListener onClickAway={this.handleMenuClose}>
              <Paper>
                <MenuList id="menu-list" style={{ paddingInlineStart: 0 }}>
                  <StyledMenuItem
                    onClick={this.editMessage}
                    disabled={this.props.hasEditingComment}
                  >
                    {this.props.intl.formatMessage({
                      id: 'commentItem.editMessage',
                    })}
                  </StyledMenuItem>
                  <DeleteMenuItem onClick={this.handleAlertOpen}>
                    {this.props.intl.formatMessage({
                      id: 'commentItem.deleteMessage',
                    })}
                  </DeleteMenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </MenuListPopper>
        </Box>
        <AlertDialog
          isOpen={this.state.isAlertDialogOpen}
          title={this.props.intl.formatMessage({
            id: 'commentItem.deleteMessage',
          })}
          message={this.props.intl.formatMessage({
            id: 'commentItem.deleteMessage.message',
          })}
          extraContent={<MarkupViewer content={this.props.comment.text} />}
          closeButtonTitle={this.props.intl.formatMessage({
            id: 'dialog.cancel',
          })}
          closeHandler={this.handleAlertClose}
          submitButtonTitle={this.props.intl.formatMessage({
            id: 'dialog.delete',
          })}
          submitHandler={this.deleteMessage}
        />
        <FileViewerDialog
          {...this.state.fileViewerDialogState}
          closeHandler={this.closeFileViewerDialog}
        />
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState, ownProps: ComponentOwnProps) => ({
  isEditing:
    ownProps.comment.uuid ===
    state.comments.editingCommentUuids.get(
      generateEditingCommentUuidKey(
        ownProps.applicationFunctionUuid,
        ownProps.dataUuid!,
        ownProps.suffix
      )
    ),
})
export default connect<StateProps, void, ComponentOwnProps, AllState>(
  mapStateToProps
)(injectIntl(CommentItem))
