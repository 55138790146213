import { intl } from '../../../../../../../../i18n'
import Select from '../../../../../../../components/editors/select/Select'

export enum CommentCellTextFilterOperator {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
}

interface Props {
  className?: string
  key: string
  value?: CommentCellTextFilterOperator
  onChange: (value: CommentCellTextFilterOperator) => void
}

const options = Object.values(CommentCellTextFilterOperator)

export const CommentCellTextFilterOperatorLabel = {
  [CommentCellTextFilterOperator.CONTAINS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.contain',
  }),
  [CommentCellTextFilterOperator.NOT_CONTAINS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.notContain',
  }),
}

const CommentCellTextOperatorSelect = ({
  className,
  key,
  value,
  onChange,
}: Props) => {
  return (
    <Select
      className={className}
      key={key}
      options={options}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
      }}
      value={value}
      onChange={event => {
        onChange(event.target.value as CommentCellTextFilterOperator)
      }}
      getOptionValue={o => o}
      getOptionLabel={o => CommentCellTextFilterOperatorLabel[o]}
    />
  )
}

export default CommentCellTextOperatorSelect
