import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { ICellEditorParams } from 'ag-grid-community'
import { InputBase } from '@mui/material'
import DatePicker from 'react-datepicker'
import DateVO from '../../../../../vo/DateVO'
import { completeDateString } from '../../../../commons/inputSupporter'
import {
  DateVO as DomainDateVO,
  dateVoService,
} from '../../../../../domain/value-object/DateVO'

export interface IDateCellEditorParams extends ICellEditorParams {
  getInitialValueOnCalendar?: (
    params: IDateCellEditorParams
  ) => DomainDateVO | undefined
}
export const DateCellEditor = forwardRef(
  (props: IDateCellEditorParams, ref) => {
    const [value, setValue] = useState<DateVO | undefined>(
      props.value ? new DateVO(props.value) : undefined
    )
    const initialValueOnCalendar = props.getInitialValueOnCalendar
      ? props.getInitialValueOnCalendar(props)
      : undefined

    const calendarSelected = useRef(false)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      inputRef.current?.focus()
    }, [])

    useEffect(() => {
      if (calendarSelected.current) {
        props.api.stopEditing()
        props.api.setFocusedCell(props.rowIndex, props.column)
      }
    }, [value])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value?.serialize()
        },
      }
    })

    const onChange = useCallback((date: Date | undefined) => {
      setValue(date ? new DateVO(date) : undefined)
    }, [])

    const onSelect = useCallback((date: Date | undefined) => {
      setValue(date ? new DateVO(date) : undefined)
      calendarSelected.current = true
    }, [])

    const onChangeCapture = useCallback(
      _.debounce(event => {
        const inputValue = event.target.value

        if (!inputValue) {
          // 値をクリアした時
          onChange(undefined)
        }
        const value = completeDateString(inputValue)
        if (!value) {
          return
        }
        const newValue = new Date(value.replace(/\//g, '-'))
        onChange(newValue)
      }, 200),
      [onChange]
    )

    return (
      <DatePicker
        open={true}
        selected={
          value?.toNumberValue() ||
          (initialValueOnCalendar
            ? dateVoService.toNumberValue(initialValueOnCalendar)
            : undefined)
        }
        dateFormat={'MM/dd'}
        onSelect={onSelect}
        customInput={
          <InputBase
            inputRef={inputRef}
            autoFocus={true}
            value={value?.format()}
            onChangeCapture={onChangeCapture}
          />
        }
        fixedHeight={true}
      />
    )
  }
)
