import { GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community'
import { intl } from '../../../../i18n'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'

export const expandAllMenu = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.expand.allLeafChildren',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.EXPAND_ALL),
    action: () => {
      params.node?.allLeafChildren.forEach(node => {
        if (node.isExpandable() && !node.expanded) {
          node.setExpanded(true)
        }
      })
    },
  }
}

export const collapseAllMenu = (
  params: GetContextMenuItemsParams
): MenuItemDef => {
  return {
    name: intl.formatMessage({
      id: 'bulksheet.contextMenu.collapse.allLeafChildren',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.COLLAPSE_ALL),
    action: () => {
      params.node?.allLeafChildren.forEach(node => {
        if (node.isExpandable() && node.expanded) {
          node.setExpanded(false)
        }
      })
    },
  }
}
