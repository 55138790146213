import API from '../../lib/commons/api'
import { TeamEntity } from '../../domain/entity/TeamEntity'
import { TeamRepository } from './../../applications/ports/teamRepository'
import { useCallback } from 'react'

export const useTeamRepository = (): TeamRepository => {
  const fetch = useCallback(
    async (uuid: string): Promise<TeamEntity | undefined> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/teams',
        { uuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return response.json
    },
    []
  )
  const fetchList = useCallback(
    async (projectUuid: string): Promise<TeamEntity[]> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/teams/list',
        { projectUuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return response.json || []
    },
    []
  )
  const fetchUnderProjectPlan = useCallback(
    async (projectUuid: string, rootProjectPlanUuid: string | undefined) => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/teams/under-project-plan',
        { projectUuid, rootProjectPlanUuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return response.json || []
    },
    []
  )
  const fetchListAsReferencedEntity = useCallback(
    async (projectUuid: string) => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/teams/list',
        { projectUuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return (
        response.json.map(e => ({
          uuid: e.uuid,
          name: e.displayName,
          iconUrl: e.iconUrl,
        })) || []
      )
    },
    []
  )
  const fetchAsReferencedEntity = useCallback(async (uuid: string) => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/teams',
      { uuid },
      true
    )
    // TODO: Consider the logic to assure type in runtime.
    return (
      response.json && {
        uuid: response.json.uuid,
        name: response.json.displayName,
        iconUrl: response.json.iconUrl,
      }
    )
  }, [])
  return {
    fetch,
    fetchList,
    fetchUnderProjectPlan,
    fetchListAsReferencedEntity,
    fetchAsReferencedEntity,
  }
}
