import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  ICellEditorParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { ColumnType, columnTypes } from '../../containers/commons/AgGrid'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRowByResponse,
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
  taskActualResultExists,
  WbsItemBatchDeltaRequest,
  WbsItemDeltaInput,
  WbsItemRow,
  WbsItemSearchDetail,
  WbsItemUpdateBatchDeltaRequest,
} from '../../../lib/functions/wbsItem'
import {
  APIResponse,
  successDummyResponse,
  toUrlQuery,
} from '../../../lib/commons/api'
import { formatDateTime } from '../../../utils/date'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import ViewMeta from '../../containers/meta/ViewMeta'
import { generateUuid } from '../../../utils/uuids'
import {
  SearchFilter,
  WbsItemSearchConditionKey,
} from './WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import DateVO from '../../../vo/DateVO'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import store from '../../../store'
import Workload, { WorkloadUnit } from '../../../lib/functions/workload'
import {
  Comment,
  commentListToSummary,
  CommentSummary,
} from '../../../store/comments'
import { openComment } from '../../../store/information'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import { AttachmentSummary } from '../../../utils/attachment'
import ProjectAPI from '../../../lib/functions/project'
import Auth from '../../../lib/commons/auth'
import { UserProps } from '../../../lib/functions/user'
import {
  SprintProductItemProp,
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../lib/functions/sprintProductItem'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { List } from 'immutable'
import {
  getTypeIndex,
  WbsItemTypeCellValue,
} from '../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import { WbsItemSearchConditionApiRequest } from '../../../services/model/wbsItemSearchConditionApiRequest'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { getTicketType } from '../../containers/commons/AgGrid/components/cell/custom/ticketType'
import { isTagColumnEdited } from '../../../lib/functions/tag'
import { dateVoService } from '../../../domain/value-object/DateVO'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class WbsItemSearchRow extends RowData {
  deliverableName?: string
  projectPlanPath?: string
  wbsItem?: WbsItemData
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  revision?: string
  type?: WbsItemType
  typeIndex?: number = -1
  parentWbsItem?: Partial<WbsItemRow>
}

export class WbsItemData extends WbsItemRow {
  path?: string
}

export const createWbsItemSearchRowByResponse = (
  wbsItem: WbsItemSearchDetail,
  viewMeta: ViewMeta
): WbsItemSearchRow => {
  const wbsItemData: WbsItemData = createRowByResponse(
    wbsItem,
    wbsItem.cumulation
  )
  wbsItemData.path = wbsItem.path
  const typeIndex = getTypeIndex(new WbsItemTypeVO(wbsItem.baseTypeDto))

  return {
    uuid: wbsItem.uuid,
    lockVersion: wbsItem.lockVersion,
    isEdited: false,
    type: wbsItem.type,
    wbsItem: wbsItemData,
    cumulation: wbsItem.cumulation,
    commentSummary: wbsItem.commentSummary,
    deliverableAttachmentSummary: wbsItem.deliverableAttachmentSummary,
    parentWbsItem: {
      ...wbsItem.parentWbsItem,
      wbsItemType: wbsItem.parentWbsItem?.typeDto
        ? new WbsItemTypeVO(wbsItem.parentWbsItem.typeDto)
        : undefined,
    },
    revision: wbsItem.revision,
    createdBy: wbsItem.createdBy,
    createdAt: formatDateTime(wbsItem.createdAt),
    updatedBy: wbsItem.updatedBy,
    updatedAt: formatDateTime(wbsItem.updatedAt),
    extensions: viewMeta.deserializeEntityExtensions(wbsItem.extensions || []),
    typeIndex,
  }
}

export enum QuickFilterKeys {
  ACCOUNTABLE = 'ACCOUNTABLE',
  RESPONSIBLE_OR_ASSIGNEE = 'RESPONSIBLE_OR_ASSIGNEE',
}

export interface WbsItemSearchState extends BulkSheetState {
  searchText?: string
  searchFilter?: SearchFilter
  searchFilterSnapshotOnSearch?: SearchFilter // Snapshot on click search button.
  quickFilters: QuickFilterKeys[]
  workloadUnit: WorkloadUnit

  searchCondition: WbsItemSearchConditionApiRequest
  searchConditionInitialized: boolean
}

interface WbsItemSearchBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    WbsItemSearchDetail,
    WbsItemSearchRow,
    WbsItemSearchState
  > {}

class WbsItemSearchRowDataSpec extends RowDataSpec<
  WbsItemSearchDetail,
  WbsItemSearchRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      path: {
        cellStyle: { direction: 'rtl' },
      },
      displayName: {
        cellStyle: { minWidth: '100', width: '500' },
      },
      deliverableWorkload: {
        editable: params => params.data.wbsItem.wbsItemType?.isDeliverable(),
      },
      taskWorkload: {
        editable: params => params.data.wbsItem.wbsItemType?.isTask(),
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
      wbsItemTypeColumn: {
        ...columnTypes().wbsItemTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.wbsItem?.baseWbsItemType?.getNameWithSuffix()
        },
      },
      ticketTypeColumn: {
        ...columnTypes().ticketTypeColumn,
        filterValueGetter: (params: ValueGetterParams) => {
          return getTicketType(params.data?.wbsItem?.baseWbsItemType)?.name
        },
      },
      parentWbsItemColumn: {
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.parentWbsItem?.displayName
        },
      },
    }
  }

  createNewRow(): WbsItemSearchRow {
    return new WbsItemSearchRow(generateUuid())
  }

  overwriteRowItemsWithParents(params: {
    child: WbsItemSearchRow
    parent: WbsItemSearchRow
  }): WbsItemSearchRow {
    return params.child
  }

  createRowByResponse(
    response: WbsItemSearchDetail,
    viewMeta: ViewMeta
  ): WbsItemSearchRow {
    return createWbsItemSearchRowByResponse(response, viewMeta)
  }
}

export const SearchConditionEndDelayed = {
  with: (args: Partial<SearchFilter>) => ({
    status: [WbsItemStatus.TODO, WbsItemStatus.DOING, WbsItemStatus.REVIEW],
    scheduledEndDate: { to: new DateVO().subtractDays(1) },
    ...args,
  }),
}

export const SearchConditionStartDelayed = {
  with: (args: Partial<SearchFilter>) => ({
    status: [WbsItemStatus.TODO],
    scheduledStartDate: { to: new DateVO().subtractDays(1) },
    ...args,
  }),
}

export const openWbsItemSearch = async (
  projectUuid: string,
  searchFilter: Partial<SearchFilter>,
  searchText?: string
) => {
  const projectResponse = await ProjectAPI.getDetail({ uuid: projectUuid })
  const projectCode = projectResponse.json.code
  const query =
    (searchText ? `searchText=${searchText}&` : '') +
    toUrlQuery(searchFilter, '', true)
  const url = `${window.location.origin}/wbsItemSearch/${projectCode}?${query}`
  window.open(url)
}

export class WbsItemSearchOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  WbsItemSearchDetail,
  WbsItemSearchRow,
  WbsItemSearchState
> {
  displayNameField = 'wbsItem.displayName'
  fetchDataOnInit = true
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.WbsItemSearchColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx => `${ctx.state.uuid}`
  rowDataSpec = new WbsItemSearchRowDataSpec()
  pinnedColumns = [
    'wbsItem.search.action',
    'wbsItem.search.wbsItem.code',
    'wbsItem.search.wbsItem.type',
    'wbsItem.search.wbsItem.ticketType',
    'wbsItem.search.wbsItem.status',
    'wbsItem.search.wbsItem.substatus',
    'wbsItem.search.wbsItem.path',
    'wbsItem.search.parentWbsItem.displayName',
    'wbsItem.search.wbsItem.displayName',
    'wbsItem.search.attachment',
  ]
  lockedColumns = ['wbsItem.search.wbsItem.code']
  customRenderers = {
    'wbsItem.search.wbsItem.displayName': 'wbsItemTreeCellRenderer',
  }
  customColumnTypes = {
    'wbsItem.search.wbsItem.type': [ColumnType.wbsItemType],
    'wbsItem.search.wbsItem.status': [ColumnType.wbsItemStatusServerSide],
    'wbsItem.search.wbsItem.estimatedWorkload.deliverable': [
      'deliverableWorkload',
    ],
    'wbsItem.search.wbsItem.estimatedWorkload.task': ['taskWorkload'],
    'wbsItem.search.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'wbsItem.search.wbsItem.scheduledDate.endDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'wbsItem.search.wbsItem.actualDate.startDate': [
      ColumnType.wbsItemActualDate,
    ],
    'wbsItem.search.wbsItem.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'wbsItem.search.wbsItem.path': ['path'],
    'wbsItem.search.commentSummary.latestComment': [ColumnType.comment],
    'wbsItem.search.wbsItem.priority': ['priorityColumn'],
    sequenceNo: [ColumnType.wbsItemSequenceNo],
    'wbsItem.search.wbsItem.tags': [ColumnType.tag],
    'wbsItem.search.parentWbsItem.displayName': ['parentWbsItemColumn'],
  }

  getOpenDetailSpec = (row: WbsItemSearchRow): Promise<OpenDetailSpec> => {
    return getOpenWbsItemDetailSpec(true, row.wbsItem as WbsItemRow)
  }
  getUpdatedRowAncestors = async (
    uuid: string
  ): Promise<WbsItemSearchDetail> => {
    return (
      await WbsItemApi.findByUuid(store.getState().project.selected!, uuid)
    ).json as WbsItemSearchDetail
  }

  async getAll(state: WbsItemSearchState): Promise<APIResponse> {
    if (
      // when no search cond, do not send request to server
      !state.searchCondition ||
      !state.searchConditionInitialized
    ) {
      return successDummyResponse
    }
    const response = await WbsItemApi.searchWbsItem(state.searchCondition)

    return response.json.total ? response : successDummyResponse
  }

  onSubmit = async (
    ctx: WbsItemSearchBulkSheetContext,
    data: {
      edited: {
        before: WbsItemSearchRow
        after: WbsItemSearchRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse[]> => {
    const state = ctx.state
    const response = await WbsItemApi.updateBatchDelta(
      this.createUpdateWbsItemRequest(state, data.edited, viewMeta)
    )
    const uuidToLockVersion = this.createUuidToLockVersion(response.json)
    await this.createSprintProductItems(
      this.createSprintProductItemRequest(data.edited, uuidToLockVersion)
    )

    // Update sprint backlogs
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      data.edited.map(v => v.after?.wbsItem as WbsItemRow).filter(v => !!v),
      Object.fromEntries(
        response.json.edited.map(v => [v.uuid, v.lockVersion])
      ),
      data.edited.map(v => v.before?.wbsItem as WbsItemRow).filter(v => !!v)
    )
    await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    const sprintProductItemsRequest: UpdateSprintProductItemProps = {
      added: [],
      deleted: [
        ...data.edited
          .filter(
            v =>
              v.after.wbsItem?.wbsItemType?.isDeliverable() &&
              !v.after.wbsItem.sprint &&
              v.before.wbsItem?.sprint
          )
          .map(
            v =>
              ({
                deliverableUuid: v.after.wbsItem?.uuid,
                deliverableLockVersion: v.after.wbsItem?.lockVersion,
                sprintUuid: v.before.wbsItem?.sprint?.uuid,
              } as SprintProductItemProp)
          ),
      ],
    }
    await updateSprintProductItems(sprintProductItemsRequest)

    return [response]
  }

  createUuidToLockVersion(output: {
    edited: { uuid: string; lockVersion: number }[]
  }): Map<string, number> {
    let lockVersion = new Map<string, number>()
    output.edited.map(row => {
      lockVersion.set(row.uuid, row.lockVersion)
    })
    return lockVersion
  }

  private createWbsItemUpdateBatchRequest = (
    state: WbsItemSearchState,
    rows: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    }[],
    viewMeta: ViewMeta
  ): WbsItemUpdateBatchDeltaRequest => {
    const wbsItemBatchRequest: WbsItemUpdateBatchDeltaRequest = {
      projectUuid: state.uuid,
      added: [],
      edited: [],
      deleted: [],
    }
    rows.forEach(row => {
      wbsItemBatchRequest.edited.push(
        createDeltaRequestByRow(
          {
            before: row.before.wbsItem!,
            after: row.after.wbsItem!,
          },
          viewMeta,
          'wbsItem.search.wbsItem',
          {
            before: row.before.extensions,
            after: row.after.extensions,
          }
        )
      )
    })
    return wbsItemBatchRequest
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem!,
        after: editedRowAfter.wbsItem!,
      },
      viewMeta,
      'wbsItem.search.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  private createUpdateWbsItemRequest = (
    state: WbsItemSearchState,
    edited: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    }[],
    viewMeta: ViewMeta
  ): WbsItemBatchDeltaRequest => {
    return {
      wbsItems: this.createWbsItemUpdateBatchRequest(state, edited, viewMeta),
      watchers: this.createUpdateWatcherRequest(edited),
      tags: this.createUpdateTagRequest(edited),
    }
  }

  private createUpdateWatcherRequest = (
    edited: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    }[]
  ): { wbsItemUuid: string; userUuids: string[] }[] => {
    return edited.map(row => {
      return {
        wbsItemUuid: row.after.wbsItem!.uuid!,
        userUuids: row.after.wbsItem!.watchers
          ? row.after.wbsItem!.watchers.map(row => row.uuid)
          : [],
      }
    })
  }

  private createUpdateTagRequest = (
    edited: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    }[]
  ): { wbsItemUuid: string; tagUuids: string[] }[] => {
    return edited
      .filter(row => {
        const original = row.before.wbsItem?.tags || []
        const newData = row.after.wbsItem?.tags || []
        return isTagColumnEdited(original, newData)
      })
      .map(row => {
        const w = row.after.wbsItem
        return {
          wbsItemUuid: w!.uuid!,
          tagUuids:
            w?.tags && Array.isArray(w?.tags) ? w?.tags.map(v => v.uuid) : [],
        }
      })
  }

  async createSprintProductItems(
    request: UpdateSprintProductItemProps
  ): Promise<APIResponse> {
    return updateSprintProductItems(request)
  }

  private createSprintProductItemRequest = (
    edited: {
      before: WbsItemSearchRow
      after: WbsItemSearchRow
    }[],
    uuidToLockVersion: Map<string, number>
  ): UpdateSprintProductItemProps => {
    const added = edited
      .filter(
        row =>
          row.after.wbsItem?.wbsItemType?.isDeliverable() &&
          !!row.after.wbsItem?.sprint
      )
      .map(row => {
        return {
          deliverableUuid: row.after.wbsItem!.uuid!,
          deliverableLockVersion:
            uuidToLockVersion.get(row.after.wbsItem!.uuid!) ||
            row.after.wbsItem!.lockVersion!,
          sprintUuid: row.after.wbsItem!.sprint!.uuid,
        }
      })
    return {
      added,
      deleted: [],
    }
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (field === 'wbsItem.type') {
      return (params: ValueGetterParams) => {
        if (!params.data?.wbsItem?.baseWbsItemType) return {}
        return {
          wbsItemType: params.data.wbsItem.baseWbsItemType,
          typeIndex: params.data.typeIndex,
        } as WbsItemTypeCellValue
      }
    }
    if (field === 'wbsItem.ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    if (field === 'wbsItem.actualHour') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || !data.wbsItem || !data.wbsItem.wbsItemType?.isTask()) {
          return ''
        }
        return Number(
          (Number(data.wbsItem.actualHour) || 0) /
            (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
        )
      }
    }
    if (field === 'parentWbsItem.displayName') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        return data?.parentWbsItem
      }
    }
    if (field === 'wbsItem.estimatedWorkload.deliverable') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || data.wbsItem?.wbsItemType?.isTask()) return undefined
        const deliverableEstimatedHour = getDeliverableEstimatedHour(data)
        return formatWorkload(
          deliverableEstimatedHour,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.estimatedWorkload.task') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        const taskEstimatedHour = getTaskEstimatedHour(data)
        return formatWorkload(
          taskEstimatedHour,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'costVariance') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const earnedValue = getTaskEarnedValue(data)
        const actualCost = getActualHour(data)
        return formatEvm(
          earnedValue - actualCost,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'costPerformanceIndex') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const earnedValue = getTaskEarnedValue(data)
        const actualCost = getActualHour(data)
        return getRate(earnedValue, actualCost)
      }
    }
    if (field === 'scheduleVariance') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const earnedValue = getTaskEarnedValue(data)
        const plannedValue = getTaskPlannedValue(data)
        return formatEvm(
          earnedValue - plannedValue,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'schedulePerformanceIndex') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const earnedValue = getTaskEarnedValue(data)
        const plannedValue = getTaskPlannedValue(data)
        return getRate(earnedValue, plannedValue)
      }
    }

    if (field === 'estimateToComplete') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        return formatEvm(
          getTaskEstimateToComplete(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'estimateAtCompletion') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        return formatEvm(
          getTaskEstimateAtCompletion(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'varianceAtCompletion') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const bac = getTaskEstimatedHour(data)
        const eac = getTaskEstimateAtCompletion(data)
        return formatEvm(
          bac - eac,
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'estimatedProgressRate') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (data.wbsItem?.wbsItemType?.isTask()) return '-'
        const plannedValue = getTaskPlannedValue(data)
        const estimatedHour = getTaskEstimatedHour(data)
        return getRate(plannedValue, estimatedHour)
      }
    }
    if (field === 'progressRate') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (!data || (data.wbsItem?.wbsItemType?.isTask() && !isDone(data))) {
          return ''
        }
        const earnedValue = getTaskEarnedValue(data)
        const estimatedHour = getTaskEstimatedHour(data)
        return getRate(earnedValue, estimatedHour)
      }
    }
    if (field === 'plannedValue') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (
          data.wbsItem?.wbsItemType?.isTask() &&
          (!isScheduledToBeDone(data) || isDiscard(data))
        ) {
          return ''
        }
        return formatEvm(
          getTaskPlannedValue(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'earnedValue') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (data.wbsItem?.wbsItemType?.isTask() && !isDone(data)) return ''
        return (
          getTaskEarnedValue(data) /
            params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'delayedWorkload') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data

        return formatEvm(
          getDelayedTaskWorkload(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'remainingWorkload') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        if (data.wbsItem?.wbsItemType?.isTask()) {
          return isDone(data) || isDiscard(data)
            ? 0
            : formatEvm(
                getTaskEstimatedHour(data),
                params.context.workloadUnitState?.hoursPerSelectedUnit || 1
              )
        }
        return formatEvm(
          getTaskEstimatedHour(data) - getTaskEarnedValue(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'preceding') {
      return (params: ValueGetterParams) => {
        const data: WbsItemSearchRow = params.data
        return formatEvm(
          getPrecedingTaskWorkload(data),
          params.context.workloadUnitState?.hoursPerSelectedUnit || 1
        )
      }
    }
    if (field === 'wbsItem.tags') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.tags
      }
    }
    return undefined
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (
      [
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
      ].includes(field)
    ) {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const unit = params.context.workloadUnit
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        let workload: Workload
        switch (unit) {
          case WorkloadUnit.MONTH:
            workload = Workload.from({
              month: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.DAY:
            workload = Workload.from({
              day: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.HOUR:
            workload = Workload.from({
              hour: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          default:
            workload = new Workload(0, 0, 0, {
              dailyWorkHours,
              monthlyWorkDays,
            })
        }
        params.data.wbsItem.estimatedWorkload = workload
        return true
      }
    }
    return undefined
  }

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: WbsItemSearchRow = params.data
    if (row && row.wbsItem && taskActualResultExists(row.wbsItem)) {
      return row.uuid
    }
    return undefined
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: WbsItemSearchRow) =>
          row && row.wbsItem && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.scheduledDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.scheduledDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
    if (field === 'wbsItem.actualDate.endDate') {
      return {
        getInitialValueOnCalendar: (params: ICellEditorParams) => {
          const startDate = params.data?.wbsItem?.actualDate?.startDate
          return startDate ? dateVoService.construct(startDate) : undefined
        },
      }
    }
  }

  getAttachmentCellRendererParams = () => {
    return {
      getAttachmentList: async (row: WbsItemSearchRow) => {
        if (row.wbsItem?.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: WbsItemSearchRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = () => {
    return {
      path: 'wbsItem.description',
      openComment: (
        row: WbsItemSearchRow,
        ctx: WbsItemSearchBulkSheetContext
      ) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        if (!row.wbsItem) {
          return
        }
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      getCommentSummary: (row: WbsItemSearchRow) => row.commentSummary,
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (['wbsItem.priority'].includes(field)) {
      return 65
    }
    if (['wbsItem.estimatedWorkload', 'wbsItem.actualHour'].includes(field)) {
      return 120
    }
    if (['action', 'wbsItem.code', 'wbsItem.type'].includes(field)) {
      return 100
    }
    if (field === 'wbsItem.status') {
      return 150
    }
    if (
      [
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
        'wbsItem.difficulty',
        'progressRate',
        'plannedValue',
        'earnedValue',
        'preceding',
        'delayedWorkload',
        'remainingWorkload',
        'costPerformanceIndex',
        'schedulePerformanceIndex',
        'task.remainingWorkload',
        'costVariance',
        'scheduleVariance',
        'estimateToComplete',
        'estimateAtCompletion',
        'varianceAtCompletion',
        'task.delayedWorkload',
        'task.plannedValue',
        'wbsItem.actualHour',
      ].includes(field)
    ) {
      return 80
    }
    if (['estimatedProgressRate'].includes(field)) {
      return 90
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    if (['wbsItem.tags'].includes(field)) {
      return 100
    }
    return undefined
  }

  refreshConditionAndColumn = (ctx: WbsItemSearchBulkSheetContext) => {
    ctx.refreshDataWithLoading()
  }
  hiddenSearchFilterIcon = true

  filteredRowDataUuids: string[] | undefined = undefined
  getRowDataUuidForFilter = (rowNode: RowNode) => {
    const row: WbsItemSearchRow = rowNode.data
    return row.wbsItem?.uuid || row.uuid
  }

  private isFilteredByQuickFilter = (
    quickFilterKey: QuickFilterKeys,
    userUuid: string,
    wbsItem?: WbsItemData
  ): boolean => {
    if (!wbsItem) {
      return false
    }
    switch (quickFilterKey) {
      case QuickFilterKeys.ACCOUNTABLE:
        return wbsItem.accountable?.uuid === userUuid
      case QuickFilterKeys.RESPONSIBLE_OR_ASSIGNEE:
        return (
          wbsItem.responsible?.uuid === userUuid ||
          wbsItem.assignee?.uuid === userUuid
        )
      default:
        return false
    }
  }

  refreshFilteredRowDataUuid = (state: WbsItemSearchState) => {
    const filters: QuickFilterKeys[] = state.quickFilters
    if (!filters || filters.length === 0) {
      this.filteredRowDataUuids = undefined
      return
    }
    const user: UserProps | undefined = Auth.getCurrentTenant()?.user
    if (!user) {
      return
    }
    this.filteredRowDataUuids = state.rowData.reduce(
      (uuids: string[], row: WbsItemSearchRow) => {
        if (!row.wbsItem?.uuid) {
          return uuids
        }
        if (
          filters.every(f =>
            this.isFilteredByQuickFilter(f, user?.uuid, row.wbsItem)
          )
        ) {
          uuids.push(row.wbsItem.uuid)
        }
        return uuids
      },
      []
    )
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getDefaultContext = (): any => ({
    workloadUnit: WorkloadUnit.HOUR,
  })

  mergeRowCommentSummary = (
    targetRow: WbsItemSearchRow,
    comments: List<Comment> | undefined
  ): WbsItemSearchRow => {
    return {
      ...targetRow,
      commentSummary: commentListToSummary(comments),
    }
  }
}

export const isDone = (data: WbsItemSearchRow) =>
  data.wbsItem?.status === WbsItemStatus.DONE
export const isDiscard = (data: WbsItemSearchRow) =>
  data.wbsItem?.status === WbsItemStatus.DISCARD
export const isScheduledToBeDone = (data: WbsItemSearchRow) =>
  data.wbsItem?.scheduledDate &&
  new DateVO(data.wbsItem?.scheduledDate.endDate).isSameOrBefore(DateVO.now())
export const isPreceding = (data: WbsItemSearchRow) =>
  data.wbsItem?.scheduledDate &&
  new DateVO(data.wbsItem?.scheduledDate.endDate).isAfter(DateVO.now()) &&
  data.wbsItem?.status === WbsItemStatus.DONE
export const formatWorkload = (
  value: number | undefined,
  denominator: number = 1
) => Number((value || 0) / denominator)
export const getDeliverableEstimatedHour = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    return 0
  }
  if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.wbsItem?.estimatedWorkload?.hour || 0
  }
  return (
    data.cumulation!.sumDeliverableEstimatedHour -
    data.cumulation!.sumDeliverableEstimatedHourDiscard
  )
}
export const getTaskEstimatedHour = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    return data.wbsItem?.estimatedWorkload?.hour || 0
  } else if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return (
      data.cumulation!.sumTaskEstimatedHourOfDirectChildren -
      data.cumulation!.sumTaskEstimatedHourDiscardOfDirectChildren
    )
  }
  return (
    data.cumulation!.sumTaskEstimatedHour -
    data.cumulation!.sumTaskEstimatedHourDiscard
  )
}
export const getTaskEarnedValue = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    return isDone(data) ? data.wbsItem?.estimatedWorkload?.hour || 0 : 0
  } else if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumTaskEstimatedHourDoneOfDirectChildren
  }
  return data.cumulation!.sumTaskEstimatedHourDone
}
export const getRate = (numerator: number, denominator?: number) => {
  if (!denominator) return '-'
  return numerator / denominator
}
export const getActualHour = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    return data.cumulation!.actualHour
  } else if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumActualHourOfDirectChildren
  }
  return data.cumulation!.sumActualHour
}
export const getTaskPlannedValue = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    if (isScheduledToBeDone(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumTaskPlannedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskToBeCompleted
}
export const formatEvm = (value: number | undefined, hoursPerUnit: number) =>
  (value || 0) / hoursPerUnit

export const getTaskCostPerformanceIndex = (data: WbsItemSearchRow): number => {
  const ev = getTaskEarnedValue(data)
  const ac = getActualHour(data)
  return ev / ac
}

export const getTaskEstimateToComplete = (data: WbsItemSearchRow): number => {
  const bac = getTaskEstimatedHour(data)
  const ev = getTaskEarnedValue(data)

  const cpi = getTaskCostPerformanceIndex(data)
  return (bac - ev) / cpi
}
export const getTaskEstimateAtCompletion = (data: WbsItemSearchRow): number => {
  const ac = getActualHour(data)
  const etc = getTaskEstimateToComplete(data)
  return ac + etc
}

export const getDelayedTaskWorkload = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    if (
      isScheduledToBeDone(data) &&
      data.wbsItem?.status !== WbsItemStatus.DONE
    ) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumDelayedTaskEstimatedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskEndDelayed
}
export const getPrecedingTaskWorkload = (data: WbsItemSearchRow): number => {
  if (data.wbsItem?.wbsItemType?.isTask()) {
    if (isPreceding(data)) {
      return getTaskEstimatedHour(data)
    }
    return 0
  }
  if (data.wbsItem?.wbsItemType?.isDeliverable()) {
    return data.cumulation!.sumPrecedingTaskEstimatedHourOfDirectChildren
  }
  return data.cumulation!.sumTaskEndPreceding
}
export const wbsItemSearchCondition = [
  {
    key: WbsItemSearchConditionKey.CODE,
    position: { row: 1, column: 2, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.TYPES,
    position: { row: 2, column: 2, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.TICKET_TYPES,
    position: { row: 3, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.STATUS,
    position: { row: 4, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.PRIORITY,
    position: { row: 5, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.DISPLAY_NAME,
    position: { row: 6, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.DESCRIPTION,
    position: { row: 7, column: 1, size: 10 },
  },
  {
    key: WbsItemSearchConditionKey.TEAM,
    position: { row: 8, column: 1, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.ACCOUNTABLE,
    position: { row: 8, column: 2, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.RESPONSIBLE,
    position: { row: 9, column: 1, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.ASSIGNEE,
    position: { row: 9, column: 2, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.SCHEDULED_START_DATE,
    position: { row: 10, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.SCHEDULED_END_DATE,
    position: { row: 10, column: 2, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.ACTUAL_START_DATE,
    position: { row: 11, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.ACTUAL_END_DATE,
    position: { row: 11, column: 2, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.CREATED_AT,
    position: { row: 12, column: 1, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.UPDATED_AT,
    position: { row: 12, column: 2, size: 2 },
  },
  {
    key: WbsItemSearchConditionKey.CREATED_BY,
    position: { row: 13, column: 1, size: 4 },
  },
  {
    key: WbsItemSearchConditionKey.UPDATED_BY,
    position: { row: 13, column: 2, size: 4 },
  },
]
