import React from 'react'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { styled } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import MultiLineTextVO from '../../../../../../vo/MultiLineTextVO'
import { normalize } from '../../../../../../utils/multilineText'
import { listItemHandler } from '../../../../../../utils/markdown'
import MultilineTextEditor from '../../../../../components/editors/multiline-text/MultilineTextEditor'
import CancelButton from '../../../../../components/buttons/CancelButton'
import SaveButton from '../../../../../components/buttons/SaveButton'

const RichTextFieldDiv = styled('div')({
  height: 'calc(100% - 54px)',
  width: 640,
  margin: 10,
  border: '2px solid #dddddd',
  borderRadius: 4,
})

const ButtonDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: 10,
})

const CellDiv = styled('div')({
  maxHeight: '400px',
  overflow: 'auto',
  width: '100%',
})

interface MultiLineTextCellProps extends CellPropsBase<MultiLineTextVO> {
  editEnd: () => void
}
interface MultiLineTextCellState extends CellStateBase {
  value: string
  initialValue: string
  isOpen: boolean
}

class MultiLineTextCell extends DataCellBase<
  MultiLineTextVO,
  MultiLineTextCellProps,
  MultiLineTextCellState
> {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value?.getValue() ?? '',
      initialValue: props.value?.getValue() ?? '',
      isOpen: false,
    }
  }

  componentDidUpdate(prevProps: MultiLineTextCellProps, prevState) {
    super.componentDidUpdate(prevProps, prevState)
    const value = this.props.value?.getValue() ?? ''
    if (
      prevProps.value?.getValue() !== this.props.value?.getValue() &&
      this.state.value !== value
    ) {
      this.setState({ value })
    }
  }

  validate = (value: MultiLineTextVO) => {
    return undefined
  }

  onClose = () => {
    this.setState({ isOpen: false })
    this.onChangeText(this.state.value)
    this.props.editEnd()
  }

  onCancel = () => {
    this.setState({
      isOpen: false,
      value: this.state.initialValue,
    })
    this.props.editEnd()
  }

  onChange = (value: string) => {
    this.setState({ value })
  }

  onChangeText = _.debounce((value: string) => {
    this.props.onChange(new MultiLineTextVO(value))
  }, 300)

  render() {
    const { value, cellDef } = this.props
    const text = normalize(!!value ? value.getValue() : '')
    return (
      <CellDiv>
        <div
          className={`markdown-body ag-grid-markdown-body`}
          style={{ minHeight: '34px' }}
          onClick={() => {
            cellDef.editable &&
              this.setState({ isOpen: true, initialValue: text })
          }}
        >
          <ReactMarkdown
            children={text}
            linkTarget={'_blank'}
            remarkPlugins={[remarkGfm]}
            remarkRehypeOptions={{
              handlers: {
                listItem(h, node, parent) {
                  return listItemHandler(h, node, parent)
                },
              },
            }}
          />
        </div>
        <Dialog
          PaperProps={{ sx: { maxWidth: '100% !important', height: '100%' } }}
          open={this.state.isOpen}
          onClose={this.onClose}
        >
          <RichTextFieldDiv>
            <MultilineTextEditor
              dataUuid={this.props.data.uuid}
              externalId={this.props.cellDef.cellId}
              value={this.state.value}
              setValue={this.onChange}
              disableSbsMode={true}
            />
          </RichTextFieldDiv>
          <ButtonDiv>
            <SaveButton onClick={this.onClose} />
            <CancelButton onClick={this.onCancel} />
          </ButtonDiv>
        </Dialog>
      </CellDiv>
    )
  }
}

export default MultiLineTextCell
