import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../store'
import TicketListApi, {
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import ToolbarSelect from '../../components/editors/select/ToolbarSelect'
import { Box } from '@mui/material'
import { WbsItemStatus } from '../../../domain/entity/WbsItemEntity'

interface StateProps {
  projectUuid?: string
}

interface Props extends StateProps {
  ticketType?: string
  value?: TicketListDetail | string
  onChange: (value: TicketListDetail | undefined) => void
  showOnlyActiveTicketList?: boolean
}

const TicketListSelect = ({
  projectUuid,
  ticketType,
  value,
  onChange,
  showOnlyActiveTicketList,
}: Props) => {
  const [ticketLists, setTicketLists] = useState<TicketListDetail[]>([])

  useEffect(() => {
    fetchTicketLists()
  }, [projectUuid, ticketType])

  const ticketListUuid =
    value && typeof value === 'string'
      ? value
      : (value as TicketListDetail)?.uuid || ''

  const fetchTicketLists = useCallback(async () => {
    let lists: TicketListDetail[] = []
    if (projectUuid && ticketType) {
      const response = await TicketListApi.getAll({
        projectUuid: projectUuid,
        ticketType: ticketType,
      })
      lists = response.json
      if (showOnlyActiveTicketList) {
        const activeStatuses = [
          WbsItemStatus.TODO,
          WbsItemStatus.DOING,
          WbsItemStatus.REVIEW,
        ]
        lists = lists.filter(v =>
          activeStatuses.includes(v.wbsItem.status as WbsItemStatus)
        )
      }
    }
    setTicketLists(lists)
    const list = lists.find(v => v.uuid === ticketListUuid)
    onChange(list)
  }, [projectUuid, ticketType, value])

  const onChanged = useCallback(
    e => {
      const ticketList = (ticketLists || []).find(
        t => t.uuid === e.target.value
      )
      onChange(ticketList)
    },
    [ticketLists, onChange]
  )

  return (
    <Box sx={{ width: '240px', height: '30px' }}>
      <ToolbarSelect
        options={ticketLists}
        value={ticketListUuid}
        getOptionValue={(o: TicketListDetail) => o.uuid}
        getOptionLabel={(o: TicketListDetail) => o.wbsItem.displayName}
        onChange={onChanged}
        sx={{
          border: '1px solid #DDDDDD',
          boxShadow: '0px 1px 1px #00000033',
        }}
      />
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  projectUuid: state.project.selected,
})

export default connect(mapStateToProps)(TicketListSelect)
