import moment from 'moment'
import { intl } from '../i18n'
import { DISPLAY_DATE_FORMAT } from './date'
import DateVO from '../vo/DateVO'

export type CheckResult = {
  ok: boolean
  message?: string
}

const OK = { ok: true }

export default class DateExpression {
  readonly expression: string
  readonly baseDate: string
  readonly dateDiff: number
  readonly type: 'min' | 'max'

  constructor(expression: string, type: 'min' | 'max' = 'min') {
    this.expression = expression
    this.type = type
    if (expression.includes('DATE_NOW')) {
      // Relative date expression
      let lhs: string = ''
      let op: string | undefined
      let rhs: string | undefined
      for (let i = 0; i < expression.length; i++) {
        const c = expression[i]
        if (c === '+' || c === '-') {
          lhs = expression.substring(0, i).trim()
          op = c
          rhs = expression.substring(i + 1).trim()
          break
        }
      }
      this.baseDate = lhs || expression
      this.dateDiff = !rhs ? 0 : parseInt(`${op!}${rhs}`)
    } else {
      // Absolute date value
      this.baseDate = ''
      this.dateDiff = 0
    }
  }

  private checkAbsolute = (value: string): string | undefined => {
    const date = new DateVO(value)
    const targetDate = new DateVO(this.expression)
    if (this.type === 'min') {
      if (date.isSameOrAfter(targetDate)) {
        return
      }
      return intl.formatMessage(
        { id: 'dateExpression.message.afterDateInput' },
        { date: targetDate.format(DISPLAY_DATE_FORMAT) }
      )
    } else {
      if (date.isSameOrBefore(targetDate)) {
        return
      }
      return intl.formatMessage(
        { id: 'dateExpression.message.withinDateInput' },
        { date: targetDate.format(DISPLAY_DATE_FORMAT) }
      )
    }
  }

  private checkRelative = (
    value: any,
    propertyAccessor: (externalId: string) => any
  ): string | undefined => {
    if (!value) {
      return
    }
    let targetDate: moment.Moment | undefined
    if (this.baseDate === 'DATE_NOW') {
      targetDate = moment().startOf('day')
    } else {
      const targetValue = propertyAccessor(this.baseDate)
      if (targetValue) {
        targetDate = moment(targetValue)
      }
    }
    if (!targetDate) {
      return
    }
    targetDate = targetDate.add(this.dateDiff, 'day')
    try {
      const date = moment(value)
      if (this.type === 'min') {
        if (date.isSameOrAfter(targetDate)) {
          return
        }
        return intl.formatMessage(
          { id: 'dateExpression.message.afterDateInput' },
          { date: targetDate.format(DISPLAY_DATE_FORMAT) }
        )
      } else {
        if (date.isSameOrBefore(targetDate)) {
          return
        }
        return intl.formatMessage(
          { id: 'dateExpression.message.withinDateInput' },
          { date: targetDate.format(DISPLAY_DATE_FORMAT) }
        )
      }
    } catch (e) {
      return intl.formatMessage({
        id: 'dateExpression.message.inputCorrectDate',
      })
    }
  }

  public check = (
    value: any,
    propertyAccessor: (externalId: string) => any
  ): CheckResult => {
    if (!value) {
      return OK
    }
    const message = !!this.baseDate
      ? this.checkRelative(value, propertyAccessor)
      : this.checkAbsolute(value)
    if (message) {
      return { ok: false, message }
    }
    return OK
  }

  public toDate = () => {
    let targetDate: moment.Moment | undefined
    if (this.baseDate === 'DATE_NOW') {
      targetDate = moment().startOf('day')
    } else {
      targetDate = moment(this.baseDate)
    }
    return targetDate.add(this.dateDiff, 'day')
  }
}
