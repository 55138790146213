import { CircularProgress, styled } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import registrationFailureIcon from '../../../assets/registration_failure_icon.svg'
import { LoginButton, LoginTitle, LoginContainer } from './components'
import { intl } from '../../../i18n'
import { Progress } from '../../components/feedback/progress'
import { useHistory, useLocation } from 'react-router'
import queryString from 'query-string'
import { getDomain } from '../../../infrastructure/browser/url'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'
import { useLegacyAuthService } from '../../../services/adaptors/legacyAuthServiceAdaptor'

const DetailItem = styled('a')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  flexDirection: 'row',
  textDecoration: 'none',
})

const GridRowTitle = styled('a')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-line',
  flexDirection: 'row',
  textDecoration: 'none',
  width: '128px',
})

const Icon = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '80px',
  padding: '12px',
  borderRadius: '64px',
  backgroundColor: '#FCF4F7',
  width: '80px',
})

const ButtonDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '476px',
  height: '70px',
  padding: '16px',
  gap: '32px',
})

type EmailVerificationError = {
  title: string
  detail: string
}

export const VerifyEmail = () => {
  const { verifyEmail } = useLegacyAuthService()
  const [verified, setVerified] = useState<boolean>(false)
  const [error, setError] = useState<EmailVerificationError | undefined>(
    undefined
  )
  const location = useLocation()
  useEffect(() => {
    const fn = async () => {
      const queryObject = queryString.parse(location.search)
      const userPoolId = queryObject['userPoolId'] as string
      const userUuid = queryObject['userUuid'] as string
      const token = queryObject['token'] as string
      if (!userPoolId || !userUuid || !token) {
        setError({
          title: intl.formatMessage({
            id: 'verifyEmail.invalidLink',
          }),
          detail: intl.formatMessage({
            id: 'verifyEmail.invalidLink.detail',
          }),
        })
        return
      }
      const result = await verifyEmail(userPoolId, userUuid, token)
      switch (result.status) {
        case 'SUCCESS':
          setVerified(true)
          return
        case 'LINK_EXPIRED':
          setError({
            title: intl.formatMessage({
              id: 'verifyEmail.linkExpired',
            }),
            detail: intl.formatMessage({
              id: 'verifyEmail.linkExpired.detail',
            }),
          })
          return
        case 'ALREADY_VERIFIED':
          setError({
            title: intl.formatMessage({
              id: 'verifyEmail.alreadyVerified',
            }),
            detail: intl.formatMessage({
              id: 'verifyEmail.alreadyVerified.detail',
            }),
          })
          return
        case 'USER_NOT_EXISTS':
          setError({
            title: intl.formatMessage({
              id: 'verifyEmail.userNotExists',
            }),
            detail: intl.formatMessage({
              id: 'verifyEmail.userNotExists.detail',
            }),
          })
          return
        case 'TOKEN_MISMATCH':
        case 'SYSTEM_ERROR':
          setError({
            title: intl.formatMessage({
              id: 'verifyEmail.tokenMismatch',
            }),
            detail: intl.formatMessage({
              id: 'verifyEmail.tokenMismatch.detail',
            }),
          })
          return
      }
    }
    fn()
  }, [verifyEmail, location.search])
  const history = useHistory()
  const toLogin = useCallback(() => {
    history.push('/')
  }, [history])
  return (
    <>
      <LoginContainer>
        {!error && (
          <>
            {!verified && (
              <>
                <LoginTitle>
                  {intl.formatMessage({
                    id: 'verifyEmail.identityVerification',
                  })}
                </LoginTitle>
                <CircularProgress />
                <GridRowTitle>
                  {intl.formatMessage({
                    id: 'verifyEmail.verificationWillBeCompletedSoon',
                  })}
                </GridRowTitle>
              </>
            )}
            {verified && (
              <>
                <LoginTitle>
                  {intl.formatMessage({
                    id: 'verifyEmail.verificationCompleted',
                  })}
                </LoginTitle>
                <DetailItem>
                  {intl.formatMessage({
                    id: 'verifyEmail.verificationCompleted.detail',
                  })}
                </DetailItem>
                <ButtonDiv>
                  <LoginButton color={'skyBlue'} onClick={toLogin}>
                    {intl.formatMessage({ id: 'verifyEmail.toFlagxs' })}
                  </LoginButton>
                </ButtonDiv>
              </>
            )}
          </>
        )}
        {error && (
          <>
            <LoginTitle>{error.title}</LoginTitle>
            <Icon>
              <img src={registrationFailureIcon} />
            </Icon>
            <DetailItem>{error.detail}</DetailItem>
          </>
        )}
      </LoginContainer>
    </>
  )
}
