import React from 'react'
import { styled } from '@mui/system'
import ExclusiveToggleGroup from './ExclusiveToggleGroup'
import { DayOfWeek, WEEKDAYS } from '../../../../lib/functions/report'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const RootContainer = styled('div')({
  display: 'flex',
})

interface OwnProps {
  value: DayOfWeek
  onChange: (newValue: string) => void
  label?: string
  disabled?: boolean
  excludeHoliday?: boolean
}

export const DayOfWeekToggleGroup = injectIntl(
  (props: OwnProps & WrappedComponentProps) => {
    const { intl, value, label, disabled, onChange, excludeHoliday } = props

    const timeGrainOptions = Object.values(DayOfWeek)
      .filter(v => (excludeHoliday ? WEEKDAYS.includes(v) : true))
      .map(v => ({
        label: intl.formatMessage({ id: `toggle.dayOfWeek.${v}` }),
        value: v,
      }))
    return (
      <>
        {label && <span>{label}</span>}
        <RootContainer>
          <ExclusiveToggleGroup
            value={value}
            options={timeGrainOptions}
            disabled={disabled}
            onChange={onChange}
          />
        </RootContainer>
      </>
    )
  }
)
