import React from 'react'
import { intl } from '../../../../../i18n'
import { ColumnFilterButtonGroup } from '../../../../components/buttons/ColumnFilterButtonGroup'
import { ColumnQuickFilterKey } from '../../projectPlanOptions'

const ProjectPlanColumnFilterButtonGroup = (props: {
  onClick: (value: ColumnQuickFilterKey) => void
}) => {
  return (
    <ColumnFilterButtonGroup
      options={[
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
          }),
          value: ColumnQuickFilterKey.INITIAL,
        },
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.planning',
          }),
          value: ColumnQuickFilterKey.PLANNING,
        },
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.workResult',
          }),
          value: ColumnQuickFilterKey.WORK_RESULT,
        },
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.progress',
          }),
          value: ColumnQuickFilterKey.PROGRESS,
        },
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.productivity',
          }),
          value: ColumnQuickFilterKey.PRODUCTIVITY,
        },
        {
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
          }),
          value: ColumnQuickFilterKey.RESTORE,
        },
      ]}
      onChange={value => props.onClick(value as ColumnQuickFilterKey)}
    />
  )
}

export default ProjectPlanColumnFilterButtonGroup
