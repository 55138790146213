import { GridApi, ICellRendererParams } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import { FloatingEditableIcon } from '../../common/text'
import { WbsItemStatus } from '.'
import formatter from '../../../../../../meta/formatter'
import {
  AggregateTargetValue,
  deliverableAggregator,
  taskAggregator,
} from '../../../../lib/aggregator'
import { RootDiv } from '../../CellRendererRoot'
import { DelayIcon } from './delayIcon'
import { getWbsItemStatusLabel } from '../../../../../../../../lib/functions/wbsItem'
import { Box } from '@mui/material'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

export const StatusNameLabel = ({ children }) => (
  <p className="sevend-ag-cell-wbs-item-status__status-label">{children}</p>
)
export const PercentageLabel = ({
  children,
  total,
}: {
  children: string
  total: number
}) => {
  let className = 'sevend-ag-cell-wbs-item-status__progress-percentage-label'
  if (total > 0) {
    className +=
      ' sevend-ag-cell-wbs-item-status__progress-percentage-label--gt0'
  } else {
    className +=
      ' sevend-ag-cell-wbs-item-status__progress-percentage-label--eq0'
  }
  return <p className={className}>{children}</p>
}
export const FractionLabel = ({ children }) => (
  <p className="sevend-ag-cell-wbs-item-status__progress-fraction-label">
    {children}
  </p>
)
export const IconArea = ({ children }) => (
  <span className="sevend-ag-cell-wbs-item-status__delay-icon">{children}</span>
)

export default function CellRenderer(params: ICellRendererParams) {
  if (!params.colDef) return <></>
  const isAggregate = params.colDef.cellRendererParams.isAggregate
  if (!params.node.data || (params.node.data.isViewOnly && !isAggregate)) {
    // If the function does not show data in tree grid, there is no target to count
    return <></>
  }

  const statusFieldName = params.colDef.field || ''
  const wbsItemFieldName = statusFieldName.substring(
    0,
    statusFieldName.lastIndexOf('.')
  )

  // When the row is total and is aggregate, treat as aggregate row,
  // which shows 'countDone / countTotal'
  if (params.node.data.isTotal && isAggregate && params.api) {
    const { countTotal, countDone } = getStatusSummary(
      params.api,
      statusFieldName
    )
    return (
      <RootDiv>
        <PercentageLabel total={countTotal}>
          {formatPercentage(countDone, countTotal)}
        </PercentageLabel>
        <FractionLabel>
          {`(${formatAggregation(countDone, params)} / ${formatAggregation(
            countTotal,
            params
          )})`}
        </FractionLabel>
        <FloatingEditableIcon {...params} />
      </RootDiv>
    )
  }

  const wbsItem = objects.getValue(params.data, wbsItemFieldName)
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    params.data,
    `${wbsItemFieldName}.wbsItemType`
  )
  const thisValue = objects.getValue(params.data, statusFieldName)

  // Count deliverable when target node is process or deliverable list, while task when deliverable.
  const aggregator =
    wbsItemType?.isDeliverable() || wbsItemType?.isTask()
      ? taskAggregator
      : deliverableAggregator
  const countAllFt =
    params.colDef.cellRendererParams?.countAll ||
    ((node: any) =>
      aggregator(
        node,
        AggregateTargetValue.STATUS_COUNT,
        wbsItemFieldName,
        node =>
          objects.getValue(node.data, statusFieldName) !== WbsItemStatus.DISCARD
      ))
  const countDoneFt =
    params.colDef.cellRendererParams?.countDone ||
    ((node: any) =>
      aggregator(
        node,
        AggregateTargetValue.STATUS_COUNT,
        wbsItemFieldName,
        node =>
          objects.getValue(node.data, statusFieldName) === WbsItemStatus.DONE
      ))

  const countAll = countAllFt(params.node)
  const countDone = countDoneFt(params.node)

  return (
    <RootDiv>
      <IconArea>
        <DelayIcon wbsItem={wbsItem} />
      </IconArea>
      {wbsItemType?.isTask() && (
        <StatusNameLabel>{format(thisValue, params)}</StatusNameLabel>
      )}
      {!wbsItemType?.isTask() && (
        <PercentageLabel total={countAll}>
          {formatPercentage(countDone, countAll)}
        </PercentageLabel>
      )}
      <FractionLabel>
        {0 < countAll || !wbsItemType?.isTask()
          ? `(${formatAggregation(countDone, params)} / ${formatAggregation(
              countAll,
              params
            )})`
          : undefined}
      </FractionLabel>
      <FloatingEditableIcon {...params} />
    </RootDiv>
  )
}

export const formatAggregation = (
  value: number,
  params: ICellRendererParams
) => {
  if (!params.colDef?.cellRendererParams) {
    return ''
  }
  return formatter.format(value, params.colDef.cellRendererParams.uiMeta)
}

export const formatPercentage = (numerator: number, denominator: number) => {
  return denominator !== 0
    ? Number((numerator * 100) / denominator).toFixed(1) + '%'
    : '-'
}

export const format = (value: string, params: ICellRendererParams) => {
  if (!params.colDef!.cellRendererParams || !value) {
    return ''
  }
  const valuesAllowed = params.colDef!.cellRendererParams.uiMeta.valuesAllowed
  const target = valuesAllowed.find(v => v.value === value)
  return target ? target.name : ''
}

export function SimpleCellRenderer(params: ICellRendererParams) {
  if (!params.colDef) return <></>
  const statusFieldName = params.colDef.field || ''
  const value = objects.getValue(params.data, statusFieldName)
  return (
    <RootDiv>
      <Box
        sx={{
          minWidth: '44px',
          textAlign: 'center',
          margin: '0 1px',
        }}
      >
        {getWbsItemStatusLabel(value)}
      </Box>
    </RootDiv>
  )
}

export const getStatusSummary = (api: GridApi, statusFieldName: string) => {
  let countTotal = 0
  let countDone = 0
  if (0 < statusFieldName.length) {
    api?.forEachNode(node => {
      const nodeData = node.data
      if (nodeData.isTotal) return
      const status = objects.getValue(nodeData, statusFieldName)
      status !== WbsItemStatus.DISCARD && countTotal++
      status === WbsItemStatus.DONE && countDone++
    })
  }
  return { countTotal, countDone }
}
