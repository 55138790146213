import _ from 'lodash'
import {
  ColDef,
  Column,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowHeightParams,
  SortChangedEvent,
} from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea } from '..'
import { AllState } from '../../../store'
import { ROW_HEIGHT } from '../../containers/BulkSheet'
import ResourcePlanCrossProjectsHeader from './components/Header'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { DateTerm } from '../../../utils/date'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { BulkSheetView } from '../../containers/BulkSheetView'
import {
  isWorkMonthColumn,
  refreshDynamicColumnDef,
  resourcePlanCrossProjectsGridOptions,
} from './gridOptions'
import {
  collapseAllRows,
  expandAllRows,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import { useResourcePlanCrossProjectData } from './hooks/resourcePlanCrossProjectsData'
import { useMasterData } from './hooks/masterData'
import { StoredPageState, usePageState } from './hooks/pageState'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { intl } from '../../../i18n'
import {
  ColumnQuickFilterKey,
  RowGroupColumnType,
  ResourcePlanCrossProjectsRow,
} from './ResourcePlanCrossProjects'
import Loading from '../../components/process-state-notifications/Loading'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useBulkSheetState } from './hooks/bulkSheetState'
import { useExcel } from './hooks/excel'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SPACE } from '../../model/keyBind'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import { dateVoService } from '../../../domain/value-object/DateVO'
import { OrganizationWorkingDayCalendarDetail } from '../../../lib/functions/organizationWorkingDayCalendar'
import { ResourcePlanExcelExportDialog } from './components/ResourcePlanExcelExportDialog'
import DateVO from '../../../vo/DateVO'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'

const ResourcePlanCrossProjects = props => {
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumnStates, setSortedColumnStates] = useState<
    SortedColumnState[]
  >([])
  const [openUiState, setOpenUiState] = useState<boolean>(false)
  const [initializedGridOptions, setInitializedGridOptions] =
    useState<boolean>(false)

  const pageState = usePageState(props.uuid)
  const prevPageState = useRef<StoredPageState>({
    fromDate: '',
    toDate: '',
    rowHeight: ROW_HEIGHT.SMALL,
    rowGroupColumn: RowGroupColumnType.MEMBER,
  })
  const { workingDays } = useMasterData({
    startDate: pageState.fromDate,
    endDate: pageState.toDate,
  })
  const prevWorkingDays =
    useRef<OrganizationWorkingDayCalendarDetail[]>(workingDays)

  const { rowData, summaryRows, fetchRecords, switchRowDataByGroupColumn } =
    useResourcePlanCrossProjectData(pageState.rowGroupColumn)

  const recreateGrid = useCallback(() => {
    setInitializedGridOptions(true)
  }, [])

  const destoryGrid = useCallback(() => {
    setInitializedGridOptions(false)
    // Recreate after DOM rewriting is complete (setTimeout 0sec)
    setTimeout(() => recreateGrid(), 0)
  }, [recreateGrid])

  const gridOptions: GridOptions = useMemo(() => {
    // Re-create the Grid to resolve the issue of GridOptions not switching.
    destoryGrid()
    return resourcePlanCrossProjectsGridOptions(pageState.rowGroupColumn)
  }, [pageState.rowGroupColumn, destoryGrid])

  useEffect(() => {
    gridOptions.api?.destroy()
  }, [gridOptions])

  const excel = useExcel(gridOptions)
  const bulkSheetStateMemberView = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.ResourcePlanCrossProjectsMemberView
  )
  const bulkSheetStateProjectView = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.ResourcePlanCrossProjectsProjectView
  )

  const bulkSheetState = useMemo(() => {
    switch (pageState.rowGroupColumn) {
      case RowGroupColumnType.MEMBER:
        return bulkSheetStateMemberView
      case RowGroupColumnType.PROJECT:
        return bulkSheetStateProjectView
    }
    return bulkSheetStateMemberView
  }, [
    pageState.rowGroupColumn,
    bulkSheetStateMemberView,
    bulkSheetStateProjectView,
  ])

  const columnSetting = useColumnSetting()

  const refreshAll = useCallback(async () => {
    try {
      setLoading(true)
      await fetchRecords({
        startDate: pageState.fromDate,
        endDate: pageState.toDate,
      })
      bulkSheetState.restoreExpandedRows()
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        draggableNodeId: undefined,
        errors: new InputError(),
      }
      setLoading(false)
    }
  }, [fetchRecords, gridOptions, bulkSheetState, pageState])

  const onReload = useCallback(() => {
    refreshAll()
  }, [refreshAll])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        setOpenUiState(true)
      }
    },
    [gridOptions.api, gridOptions.columnApi]
  )

  const onChangeDateTerm = useCallback(
    (term: DateTerm) => {
      if (!!term.startDate) {
        pageState.setFromDate(term.startDate)
      }
      if (!!term.endDate) {
        pageState.setToDate(term.endDate)
      }
      const states = gridOptions?.columnApi
        ?.getColumns()
        ?.filter((c: Column) => {
          if (c.isVisible()) return false

          const parent = c.getParent()
          if (!parent || !isWorkMonthColumn(parent.getGroupId())) return false

          return dateVoService.isBetween(
            dateVoService.construct(c.getColId()),
            dateVoService.construct(term.startDate),
            dateVoService.construct(term.endDate),
            '[]'
          )
        })
        .map(c => ({
          colId: c.getColId(),
          hide: false,
        }))
      gridOptions?.columnApi?.applyColumnState({ state: states })
    },
    [pageState, gridOptions.columnApi]
  )

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const key = column.colId || column.field
      if (!key) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[key]
      gridOptions.api.setFilterModel(filterModel)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [gridOptions.api])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      if (!gridOptions.columnApi) return
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    if (!gridOptions.columnApi) return
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      if (!gridOptions.columnApi) return
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions]
  )

  const onChangeRowGroupColumn = useCallback(
    (type: RowGroupColumnType | undefined) => {
      pageState.setRowGroupColumn(type)
      columnSetting.close()
    },
    [pageState, columnSetting]
  )

  const getDisplayColumnName = useCallback(
    (colDef: ColDef) => {
      if (!gridOptions.columnApi) return
      const column = gridOptions.columnApi?.getColumn(colDef.colId)
      const parentColumnDef = column?.getParent()?.getColGroupDef()
      if (parentColumnDef && isWorkMonthColumn(parentColumnDef.groupId)) {
        return new DateVO(column!.getColId()).format(
          intl.formatMessage({ id: 'dateFormat.yyyy.mm' })
        )
      }
      return colDef.headerName
    },
    [gridOptions.columnApi]
  )

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [expandAllRows(params), collapseAllRows(params)].filter(
        m => !!m
      ) as (string | MenuItemDef)[]
    },
    []
  )

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<ResourcePlanCrossProjectsRow>) => {
      e.api?.refreshCells({ force: true })
    },
    []
  )

  const onFirstDataRendered = useCallback(
    e => {
      bulkSheetState.onFirstDataRendered(e)
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onGridReady = useCallback(() => {
    refreshDynamicColumnDef({
      api: gridOptions.api,
      dateTerm: {
        startDate: pageState.fromDate,
        endDate: pageState.toDate,
      },
      workingDays,
      rowGroupColumn: pageState.rowGroupColumn,
    })
    bulkSheetState.onGridReady()
    switchRowDataByGroupColumn(pageState.rowGroupColumn)
  }, [
    gridOptions,
    pageState,
    workingDays,
    bulkSheetState,
    switchRowDataByGroupColumn,
  ])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = bulkSheetState.onFilterChanged(e)
      setFilteredColumns(filter)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      const sorted = bulkSheetState.onSortChanged(e)
      setSortedColumnStates(sorted)
    },
    [bulkSheetState]
  )

  const getRowHeight = useCallback(
    (params: RowHeightParams): number => {
      const row: ResourcePlanCrossProjectsRow = params.data
      if (!!row?.isTotal) return ROW_HEIGHT.SMALL
      return pageState.rowHeight
    },
    [pageState.rowHeight]
  )
  const closeUiStateDialog = useCallback(() => {
    setOpenUiState(false)
  }, [])

  const onSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      if (columnState) {
        bulkSheetState.base.saveColumnState(columnState)
        gridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        bulkSheetState.base.saveFilterState(filterState)
        gridOptions.api?.setFilterModel(filterState)
      }
      closeUiStateDialog()
    },
    [bulkSheetState, closeUiStateDialog, gridOptions.api, gridOptions.columnApi]
  )

  const isConditionChanged = useCallback(() => {
    if (
      !prevPageState.current ||
      prevPageState.current.fromDate !== pageState.fromDate ||
      prevPageState.current.toDate !== pageState.toDate ||
      !_.isEqual(prevWorkingDays.current, workingDays)
    ) {
      prevPageState.current = {
        fromDate: pageState.fromDate,
        toDate: pageState.toDate,
        rowHeight: pageState.rowHeight,
      }
      prevWorkingDays.current = workingDays
      return true
    }
    return false
  }, [pageState.fromDate, pageState.toDate, pageState.rowHeight, workingDays])

  useEffect(() => {
    if (pageState.initialized && isConditionChanged()) {
      refreshDynamicColumnDef({
        api: gridOptions.api,
        dateTerm: {
          startDate: pageState.fromDate,
          endDate: pageState.toDate,
        },
        workingDays,
        rowGroupColumn: pageState.rowGroupColumn,
      })
      refreshAll()
    }
  }, [
    isConditionChanged,
    refreshAll,
    pageState.initialized,
    gridOptions.api,
    pageState.fromDate,
    pageState.toDate,
    workingDays,
    pageState.rowGroupColumn,
  ])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  const onSpaceKeyDown = useCallback(() => {
    const { api } = gridOptions
    const focusedCell = api?.getFocusedCell()
    const focusedNode = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex ?? -1)
    if (
      !focusedCell ||
      !focusedNode ||
      !document.activeElement!.classList.contains('ag-cell')
    ) {
      return
    }
    focusedNode.setExpanded(!focusedNode.expanded)
  }, [gridOptions])

  useKeyBind([
    {
      key: KEY_SPACE,
      fn: onSpaceKeyDown,
      stopDefaultBehavior: true,
    },
  ])

  if (!pageState.initialized) {
    return <></>
  }

  // This process is to solve the problem that GridOptions does not switch.
  if (!initializedGridOptions) {
    return <></>
  }

  return (
    <PageArea>
      <ResourcePlanCrossProjectsHeader
        fromDate={pageState.fromDate}
        toDate={pageState.toDate}
        onChangeDateTerm={onChangeDateTerm}
        onReload={onReload}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        sortColumnsState={sortedColumnStates}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        rowHeight={pageState.rowHeight}
        onChangeHeight={pageState.setRowHeight}
        onClickExportExcel={excel.openDialog}
        rowGroupColumn={pageState.rowGroupColumn}
        onChangeRowGroupColumn={onChangeRowGroupColumn}
        getDisplayColumnName={getDisplayColumnName}
      />
      <BulkSheetView
        gridOptions={gridOptions}
        getContextMenuItems={contextMenu}
        getRowHeight={getRowHeight}
        onColumnMoved={bulkSheetState.rememberColumnState}
        onColumnResized={bulkSheetState.rememberColumnState}
        onColumnVisible={bulkSheetState.rememberColumnState}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onGridReady}
        onRowDataUpdated={onRowDataUpdated}
        onRowGroupOpened={bulkSheetState.onRowGroupOpened}
        onSortChanged={onSortChanged}
        pinnedTopRowData={summaryRows}
        ref={ref}
        rowData={rowData}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <SavedUIStateDialog
        applicationFunctionUuid={props.uuid}
        open={openUiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={bulkSheetState.columnSettingUiStateKey}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.base.columnState,
          filterState: bulkSheetState.base.filterState,
        }}
        onSelect={onSelectUiState}
        onClose={closeUiStateDialog}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions.columnApi ?? undefined}
        gridApi={gridOptions.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() => setOpenUiState(true)}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={props.uuid}
        uiStateKey={bulkSheetState.columnSettingUiStateKey}
        columnState={bulkSheetState.base.columnState}
        offset={90}
      />
      {excel.dialogOpen && (
        <ResourcePlanExcelExportDialog
          onClose={excel.closeDialog}
          onSubmit={excel.onExportExcel}
          rowGroupColumn={pageState.rowGroupColumn}
          columnApi={gridOptions.columnApi}
        />
      )}
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(
  pageComponent(ResourcePlanCrossProjects)
)
