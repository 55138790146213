import React from 'react'
import BaseButton from '../BaseButton'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { BackgroundColor, FontWeight } from '../../../../styles/commonStyles'
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded'

const Button = styled(BaseButton)({
  background: BackgroundColor.BLUE_GRADATION,
  color: '#FFFFFF',
  width: '101px',
  height: '24px',
  borderRadius: '13px',
  fontWeight: FontWeight.BOLD,
})
const StartIcon = styled(PlaylistAddCheckRoundedIcon)({
  fontSize: '16px !important',
})

interface OwnProps {
  onClick?: () => void | Promise<void>
}
type Props = OwnProps & WrappedComponentProps

const SearchConditionButton = ({ intl, ...props }: Props) => {
  return (
    <Button {...props} startIcon={<StartIcon />} color="primary">
      {intl.formatMessage({ id: 'report.saveSearchCondition' })}
    </Button>
  )
}

export default injectIntl(SearchConditionButton)
