import numeral from 'numeral'
import { ICellRendererParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { setError } from '../../lib/validation'
import validator from '../../../meta/validator'
import { Color } from '../../../../../styles/commonStyles'

interface Props<TData = any>
  extends ICellRendererParams<TData, number | string> {
  tooltip?: (
    params: ICellRendererParams<TData, number | string>
  ) => string | undefined
  uiMeta?: FunctionProperty
  numberWithCommas?: boolean
  decimalPoints?: number
  denominator?: number
  unit?: string
  hideZero?: ((params: ICellRendererParams) => boolean) | boolean
}

export const NumberCellRenderer = (props: Props) => {
  const {
    value,
    valueFormatted,
    data,
    column,
    rowIndex,
    tooltip,
    uiMeta,
    numberWithCommas,
    decimalPoints,
    denominator,
    unit: unitLabel,
    hideZero,
  } = props
  const [message, setMessage] = useState<string | undefined>()
  const [tooltipText, setTooltipText] = useState<string | undefined>()
  const [numberFormat, setNumberFormat] = useState<string>('0')
  const [displayValue, setDisplayValue] = useState<string | number>()

  useEffect(() => {
    const err = uiMeta
      ? validator.validate(value, data, uiMeta, () => undefined)?.getMessage()
      : undefined
    setError(data, err, props)
    setMessage(err)
  }, [data, props, uiMeta, value])

  useEffect(() => {
    tooltip && setTooltipText(tooltip?.(props))
  }, [tooltip, props, value])

  useEffect(() => {
    const integerFormat = numberWithCommas ? '0,0' : '0'
    const decimalFormat = decimalPoints
      ? '.'.padEnd(1 + decimalPoints, '0')
      : ''
    setNumberFormat(integerFormat.concat(decimalFormat))
  }, [numberWithCommas, decimalPoints])

  useEffect(() => {
    if (valueFormatted) {
      setDisplayValue(valueFormatted)
      return
    }
    if (typeof value !== 'number') {
      setDisplayValue(value)
      return
    }
    if (!isFinite(value)) {
      setDisplayValue('')
      return
    }
    const calcValue = denominator ? value / denominator : value
    if (calcValue === 0 && hideZero !== undefined) {
      if (typeof hideZero === 'boolean' ? hideZero : hideZero(props)) {
        setDisplayValue('')
        return
      }
    }
    setDisplayValue(
      numeral(calcValue)
        .format(numberFormat)
        .concat(unitLabel ?? '')
    )
  }, [
    valueFormatted,
    value,
    denominator,
    numberFormat,
    unitLabel,
    hideZero,
    props,
  ])

  return (
    <Tooltip message={message ?? tooltipText}>
      <div
        id={`column-${column?.getColId()}-${rowIndex}`}
        className={'sevend-ag-cell'}
        style={{
          justifyContent: 'flex-end',
          color:
            typeof value === 'number' && value < 0 ? Color.ALERT : undefined,
          backgroundColor: !!message ? '#FADBD8' : undefined,
        }}
      >
        {displayValue}
        <FloatingEditableIcon {...props} />
      </div>
    </Tooltip>
  )
}
