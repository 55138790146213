import { styled } from '@mui/system'
import { intl } from '../../../i18n'
import {
  LoginButton,
  LoginCheckbox,
  LoginContainer,
  LoginInput,
  LoginTitle,
} from './components'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useChangePassword } from './hooks/changePassword'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'
import { AuthError } from '../../../error/AuthError'
import { ErrorMessage } from './components/AuthErrorMessage'
import { useInitialPage } from './hooks/initialPage'
import Auth from '../../../lib/commons/auth'

const EmailArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'center',
  height: '72px',
  padding: '0 16px',
  gap: '4px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
  backgroundColor: '#F7F8FA',
  width: '444px',
})

const EmailTitle = styled('div')({
  display: 'flex',
  color: '#7B8CAA',
  fontSize: '14px',
  fontWeight: '400',
})

const Email = styled('div')({
  display: 'flex',
  fontSize: '14px',
  color: '#243050',
})

const LoginPassword = styled(LoginInput)({
  width: '444px',
})
const ShowPassword = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})

export const ChangePasswordForNewUser = () => {
  const { completeNewPassword } = useAuthenticateUseCase()
  const match = useRouteMatch<{ email: string }>()
  const email = useMemo(() => match.params.email, [match.params.email])
  const {
    newPassword,
    newPasswordConfirm,
    showPassword,
    inputType,
    onChangeNewPassword,
    onChangeNewPasswordConfirm,
    toggleShowPassword,
  } = useChangePassword()
  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)
  const history = useHistory()

  useEffect(() => {
    const caller = async () => {
      const tenant = Auth.getCurrentTenant()
      if (tenant) {
        const isLoggedIn = await tenant.isLoggedin()
        if (isLoggedIn) {
          history.push('/')
        }
      }
      if (!Auth.hasTemporaryPassword()) {
        history.push('/')
      }
    }
    caller()
  }, [history])
  const onSubmit = useCallback(async () => {
    if (!newPassword || !newPasswordConfirm) {
      setAuthError(new AuthError('NO_PASSWORD_INPUT'))
      return
    }
    if (newPassword.match(/\s/) || newPasswordConfirm.match(/\s/)) {
      setAuthError(new AuthError('INVALID_CHARACTOR_FOR_PASSWORD'))
      return
    }
    if (newPassword !== newPasswordConfirm) {
      setAuthError(new AuthError('PASSWORD_CONFIRM_UNMATCH'))
      return
    }
    completeNewPassword(email, newPassword).catch(err => {
      if (!(err instanceof AuthError)) {
        throw err
      }
      setAuthError(err)
    })
  }, [newPassword, newPasswordConfirm, completeNewPassword, email])
  const toLogin = useCallback(() => {
    history.push('/')
  }, [history])

  return (
    <LoginContainer>
      <LoginTitle>
        {intl.formatMessage({ id: 'changePasswordNewUser.setNewPassword' })}
      </LoginTitle>
      {authError && <ErrorMessage authError={authError} />}
      <EmailArea>
        <EmailTitle>
          {intl.formatMessage({ id: 'changePasswordNewUser.username' })}
        </EmailTitle>
        <Email>{email}</Email>
      </EmailArea>

      <LoginPassword
        label={intl.formatMessage({ id: 'changePassword.newPassword' })}
        type={inputType}
        value={newPassword}
        onChange={onChangeNewPassword}
      />
      <LoginPassword
        label={intl.formatMessage({
          id: 'changePassword.newPasswordForConfirm',
        })}
        type={inputType}
        autoComplete="off"
        value={newPasswordConfirm}
        onChange={onChangeNewPasswordConfirm}
      />
      <ShowPassword>
        <LoginCheckbox checked={showPassword} onChange={toggleShowPassword} />
        {intl.formatMessage({ id: 'login.displayPassword' })}
      </ShowPassword>
      <LoginButton color={'skyBlue'} onClick={onSubmit}>
        {intl.formatMessage({ id: 'forgotPasswordSubmit.setPassword' })}
      </LoginButton>
      <LoginButton color={'monotone'} onClick={toLogin}>
        {intl.formatMessage({ id: 'back' })}
      </LoginButton>
    </LoginContainer>
  )
}
