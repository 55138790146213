import { useCallback } from 'react'
import { BlankCondition } from '../../../../../../domain/value-object/WbsItemSearchConditionVo'
import { WbsItemSearchSearchCondition } from '../../../hooks/useSearchCondition'

export const useOnSingleValueChangedGenerator = (
  searchCondition: WbsItemSearchSearchCondition,
  onChange: (updated: Partial<WbsItemSearchSearchCondition>) => void
) => {
  const onChangeValueGenerator = useCallback(
    (key: keyof WbsItemSearchSearchCondition) => {
      return (value: WbsItemSearchSearchCondition[typeof key] | undefined) => {
        const updated = {}
        updated[key] = value
        onChange(updated)
      }
    },
    []
  )
  const onChangeBlankGenerator = useCallback(
    (key: keyof BlankCondition) => {
      const currentCondition = searchCondition.blank || {}
      return (value: boolean) => {
        const newCondition = {
          ...currentCondition,
        }
        newCondition[key] = value
        onChange({ blank: newCondition })
      }
    },
    [searchCondition]
  )

  return {
    onChangeValueGenerator,
    onChangeBlankGenerator,
  }
}
