import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { Box } from '@mui/material'
import { gridRootStyle } from '../../../../commons/AgGrid'
import { MultiInputDialog } from './MultiInputDialog'
import { WbsItemAdditionalPropertySelectOption } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'

interface Props extends ICellEditorParams {
  value: WbsItemAdditionalPropertySelectOption[]
}

export const WbsItemAdditionalPropertySelectOptionsCellEditor = forwardRef(
  (props: Props, ref) => {
    const { value, api, stopEditing } = props
    const [open, setOpen] = useState(true)
    const [inputItems, setInputItems] = useState(value)

    useEffect(() => {
      if (!open) {
        stopEditing()
      }
    }, [open])

    useImperativeHandle(
      ref,
      () => {
        return {
          getValue() {
            return inputItems
          },
        }
      },
      [inputItems]
    )

    return (
      <Box sx={gridRootStyle}>
        <MultiInputDialog
          open={open}
          inputItems={inputItems}
          onSubmit={newInputItems => {
            setInputItems(newInputItems)
            setOpen(false)
          }}
          onClose={() => {
            api.stopEditing(true)
          }}
        />
      </Box>
    )
  }
)
