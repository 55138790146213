import React from 'react'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import equal from 'fast-deep-equal'
import * as d3 from 'd3'
import _ from 'lodash'
import { select } from 'd3-selection'
import { scaleLinear, scaleTime } from 'd3-scale'
import { axisBottom, axisLeft } from 'd3-axis'
import 'd3-transition'
import { format } from 'd3-format'
import moment from 'moment'
import { roundNumber } from '../../../../utils/number'
import { intl } from '../../../../i18n'
import './styles.scss'
import {
  ChartConfigurationBase,
  DataPerDim,
  getTimeUnitByGrain,
  getTimeUnitSizeByGrain,
  GraphConfigurationBase,
  mapDayOfWeekToNumber,
  ReportDataManager,
  ReportDataSeries,
  TimeGrain,
  ViewConfigurationBase,
} from '../../../../lib/functions/report'
import { FontSize, FontWeight } from '../../../../styles/commonStyles'

d3.selection.prototype.bringElementAsTopLayer = function () {
  return this.each(() => {
    this.parentNode.appendChild(this)
  })
}

// Styles
const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
const ChartContainer = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  border: '1px solid #DDDDDD',
})
const Chart = styled('div')({
  flexGrow: 1,
})
const ControlPanel = styled('div')({
  justifyContent: 'center',
  width: '250px',
  borderLeft: '1px solid #DDDDDD',
  padding: '10px',
  height: '100%',
  overflowY: 'scroll',
})
const Toolbar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  width: '100%',
  padding: '3px',
})
const ControlPanelRow = styled('div')({
  width: '100%',
  padding: '3px 0',
})

export const formatDate = (v: any, timeGrain: TimeGrain) => {
  switch (timeGrain) {
    case TimeGrain.DAY:
    case TimeGrain.WEEK:
    case TimeGrain.TWO_WEEKS:
      return moment(v).format(
        intl.formatMessage({
          id: 'progressReport.axis.date',
        })
      )
    case TimeGrain.MONTH:
      return moment(v).format(
        intl.formatMessage({
          id: 'progressReport.axis.month',
        })
      )
  }
}

export const formatNumber = (
  value?: number,
  options?: { round?: boolean; tooltip?: boolean }
): string => {
  if (value === undefined || value === null) {
    return options?.tooltip ? '0' : ''
  }
  return options?.tooltip
    ? value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : `${roundNumber(value, 1)}` // Do not use comma to reduce text width
}

export interface ChartContext {
  chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
  height: number
  width: number
  xScale: d3.ScaleTime<number, number>
  yScale: d3.ScaleLinear<number, number>
  xAxis: d3.Axis<any>
  xNum: number
  xMin: number
  xMax: number
  yMax: number
}

export interface ChartApi<
  K,
  D,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  props: OwnProps<K, D, V, C, G>
  showTooltip: (text: string, event: any, dx?: number, dy?: number) => void
  moveTooltip: (pos: [number, number]) => void
  closeTooltip: (elem: SVGElement) => void
  renderChart: () => void
}

export abstract class ChartSpec<
  K,
  D,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  protected dataManager: ReportDataManager<K, D, V>
  protected chartConfigSpec: ChartConfigSpec<K, C, G>

  constructor(
    _dataManager: ReportDataManager<K, D, V>,
    _chartConfigSpec: ChartConfigSpec<K, C, G>
  ) {
    this.dataManager = _dataManager
    this.chartConfigSpec = _chartConfigSpec
  }
  abstract drawGraph(
    data: ReportDataSeries<K, D>[],
    ctx: ChartContext,
    api: ChartApi<K, D, V, C, G>,
    viewConfig: V,
    chartConfig: C
  ): void
  abstract getMinTickWidth(
    data: ReportDataSeries<K, D>[],
    chartConfig: C
  ): number
  getVerticalBorders: () => VerticalBorder[] = () => []
  abstract getYmax(
    data: ReportDataSeries<K, D>[],
    viewConfig: V,
    chartConfig: C
  ): number
  getLegend?(
    data: ReportDataSeries<K, D>[],
    chartConfig: C,
    api: ChartApi<K, D, V, C, G>,
    onChangeConfig: (newConfig: C) => void
  ): JSX.Element
}

export abstract class ChartConfigSpec<
  K,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  abstract getGraphConfig(chartConfig: C, key: K): G | undefined
}

export class ChartOptions<V extends ViewConfigurationBase> {
  eventListeners: {
    selector: string
    eventType: string
    listener: (e: any, d: any) => void
  }[] = []
  getMinScale?(viewConfig: V): number
}

interface Props<
  K,
  D,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> extends WrappedComponentProps,
    OwnProps<K, D, V, C, G> {}

export interface OwnProps<
  K,
  D,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> {
  viewConfig: V
  chartConfig: C
  dataSeries: ReportDataSeries<K, D>[]
  chartSpec: ChartSpec<K, D, V, C, G>
  chartOptions: ChartOptions<V>
  dataManager: ReportDataManager<K, D, V>
  controlPanels(ctx: ChartApi<K, D, V, C, G>): JSX.Element[]
  toolbarItems: JSX.Element[]
  onChangeProps(params: { [key: string]: V | C }): void
}

export type VerticalBorder = {
  x: number
  label: string
  style: { padding?: number; color: string; width?: number }
}

const style = {
  chart: {
    padding: 5,
  },
  controlPanel: {
    width: 250,
  },
  g: {
    margin: { right: 30, bottom: 60 },
  },
  yAxis: {
    width: 60,
    margin: { top: 0, left: 50, bottom: 60 },
  },
  tickLabel: {
    width: 35,
    marginHorizontal: 3,
  },
}

const CANONICAL_TICK_COUNT = 20
const TICK_MARGIN = 3

class ProgressReportChart<
  K,
  D,
  V extends ViewConfigurationBase,
  C extends ChartConfigurationBase<G>,
  G extends GraphConfigurationBase<K>
> extends React.Component<Props<K, D, V, C, G>> {
  now = moment().valueOf()
  tooltip?: d3.Selection<SVGGElement, unknown, HTMLElement, any>

  componentDidMount(): void {
    this.renderChart()
  }

  componentDidUpdate(prevProps: Props<K, D, V, C, G>): void {
    const data = this.props.dataSeries
    const viewConfig = this.props.viewConfig
    const chartConfig = this.props.chartConfig
    if (
      !equal(data, prevProps.dataSeries) ||
      !equal(viewConfig, prevProps.viewConfig) ||
      !equal(chartConfig, prevProps.chartConfig)
    ) {
      this.renderChart()
    }
  }

  renderChart() {
    const { dataSeries, chartSpec, viewConfig, chartConfig } = this.props
    const ctx = this.drawContainer(chartSpec, dataSeries)
    if (!ctx) {
      return
    }
    chartSpec.drawGraph(dataSeries, ctx, this, viewConfig, chartConfig)
    this.addEventListeners()
  }

  private drawContainer = (
    chartSpec: ChartSpec<K, D, V, C, G>,
    dataSeries: ReportDataSeries<K, D>[]
  ): ChartContext | undefined => {
    const params = this.getParams(chartSpec, dataSeries)
    const quadrant = this.drawQuadrant(chartSpec, dataSeries, params)
    const xAxis = this.drawXAxis({ ...params, ...quadrant })
    if (params.yMax === 0) {
      return
    }
    const yAxis = this.drawYAxis({ ...params, ...quadrant })
    this.drawBorders(chartSpec, {
      ...params,
      ...quadrant,
      ...xAxis,
    })
    this.drawTooltip(quadrant)

    return {
      ...params,
      ...quadrant,
      ...xAxis,
      ...yAxis,
    }
  }

  private getParams = (
    chartSpec: ChartSpec<K, D, V, C, G>,
    dataSeries: ReportDataSeries<K, D>[]
  ): {
    xNum: number
    xMin: number
    xMax: number
    yMax: number
  } => {
    let allData: DataPerDim<D>[] = []
    dataSeries.forEach(d => allData.push(...d.value))
    const momentUnit = getTimeUnitByGrain(this.props.viewConfig.timeGrain)
    const unitSize = getTimeUnitSizeByGrain(this.props.viewConfig.timeGrain)
    const xMin = this.getXmin(allData)
    const xMax = this.getXmax(allData)
    const xNum = Math.ceil(
      moment(xMax).diff(moment(xMin), momentUnit) / unitSize
    )
    const yMax = chartSpec.getYmax(
      dataSeries,
      this.props.viewConfig,
      this.props.chartConfig
    )
    return {
      xNum,
      xMin,
      xMax,
      yMax,
    }
  }

  private drawQuadrant = (
    chartSpec: ChartSpec<K, D, V, C, G>,
    dataSeries: ReportDataSeries<K, D>[],
    ctx: {
      xNum: number
      xMin: number
      xMax: number
      yMax: number
    }
  ): {
    chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    yAxisSvg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    height: number
    width: number
    svgWidth: number
  } => {
    const { xNum } = ctx
    const wrapperWidth =
      document.getElementById('chartContainer')!.offsetWidth -
      style.controlPanel.width
    const svgHeight =
      document.getElementById('chartContainer')!.offsetHeight - 20
    const height = svgHeight - style.g.margin.bottom
    const minTickWidth = chartSpec.getMinTickWidth(
      dataSeries,
      this.props.chartConfig
    )
    const svgWidth =
      Math.max(
        minTickWidth * xNum,
        wrapperWidth - style.yAxis.width - 2 * style.chart.padding
      ) - style.g.margin.right
    const width = svgWidth
    d3.selectAll('#chart > div').remove()
    const root = select('#chart')
      .style('width', `${wrapperWidth}px`)
      .style('padding', `${style.chart.padding}px`)
      .style('display', 'flex')
    const yAxisSvg = root
      .append('div')
      .style('width', `${style.yAxis.width}px`)
      .style('height', `${svgHeight}px`)
      .style('top', `${style.chart.padding}px`)
      .append('svg')
      .style('width', `${style.yAxis.width}px`)
      .style('height', `${svgHeight}px`)
      .append('g')
    const chart = root
      .append('div')
      .attr('id', 'chartMain')
      .style('overflow-x', 'scroll')
      .style('overflow-y', 'hidden')
      .style('width', `${svgWidth}px`)
      .style('height', `${svgHeight}px`)
      .append('svg')
      .style('width', `${svgWidth}px`)
      .style('height', `${svgHeight}px`)
      .append('g')
    return {
      chart,
      yAxisSvg,
      height,
      width,
      svgWidth,
    }
  }

  private drawXAxis = (ctx: {
    chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    yAxisSvg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    height: number
    width: number
    svgWidth: number
    xNum: number
    xMin: number
    xMax: number
  }): {
    xScale: d3.ScaleTime<number, number>
    xAxis: d3.Axis<any>
  } => {
    const { chart, height, width, svgWidth, xNum, xMin, xMax } = ctx
    const momentUnit = getTimeUnitByGrain(this.props.viewConfig.timeGrain)
    const unitSize = getTimeUnitSizeByGrain(this.props.viewConfig.timeGrain)
    const halfGrain = moment().add(unitSize, momentUnit).diff(moment()) / 2
    // @ts-ignore
    const xScale = scaleTime()
      .domain([xMin - halfGrain, xMax])
      .range([0, width])
    const xAxis = axisBottom(xScale)
      .tickValues(
        _.range(xNum).map(v => moment(xMin).add(unitSize * v, momentUnit))
      )
      .tickFormat(v => formatDate(v, this.props.viewConfig.timeGrain))
    const tickWidth = Math.floor(svgWidth / (xNum + 0.5))
    const needRotate =
      tickWidth < style.tickLabel.width + 2 * style.tickLabel.marginHorizontal
    const rotateDeg = needRotate ? 45 : 0
    const translateX = needRotate ? 30 : Math.floor(tickWidth / 2)
    const translateY = needRotate ? 5 : 0
    chart
      .append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('y', 16)
      .attr('x', 16)
      .style('text-anchor', 'end')
      .style(
        'transform',
        `translate(${translateX}px, ${translateY}px) rotate(${rotateDeg}deg)`
      )
    return {
      xScale,
      xAxis,
    }
  }

  private drawYAxis = (ctx: {
    chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    yAxisSvg: d3.Selection<SVGGElement, unknown, HTMLElement, any>
    height: number
    width: number
    yMax: number
  }): {
    yScale: d3.ScaleLinear<number, number>
  } => {
    const { chart, yAxisSvg, height, width, yMax } = ctx
    const scale = this.getScale(yMax)
    const tickCount = this.getTicksCount(yMax, scale)
    const yScale =
      yMax === 0
        ? scaleLinear().domain([0]).range([height, 0])
        : scaleLinear()
            .domain([0, (yMax + TICK_MARGIN * scale) * 1.05])
            .range([height, 0])
    const yAxis = axisLeft(yScale)
      .ticks(tickCount)
      .tickFormat(() => '')
      .tickSize(-width)
    chart
      .append('g')
      .attr('class', 'chart-grid')
      .attr('transform', `translate(0,0)`)
      .call(yAxis)
    const yAxisTick = axisLeft(yScale)
      .ticks(tickCount)
      .tickFormat(format(this.getFloatFormat()))
    yAxisSvg
      .append('g')
      .attr('class', 'y axis')
      .attr('transform', `translate(${style.yAxis.width},0)`)
      .call(yAxisTick)
    return {
      yScale,
    }
  }

  private getScale = (yMax: number): number => {
    let minScale = 0.1
    if (this.props.chartOptions.getMinScale) {
      minScale = this.props.chartOptions.getMinScale(this.props.viewConfig)
    }
    return Math.max(
      Math.ceil(yMax / minScale / CANONICAL_TICK_COUNT) * minScale,
      minScale
    )
  }

  private drawBorders = (
    chartSpec: ChartSpec<K, D, V, C, G>,
    ctx: {
      chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
      height: number
      xScale: d3.ScaleTime<number, number>
      xMin: number
      xMax: number
    }
  ) => {
    const { chart, height, xScale, xMin, xMax } = ctx
    const verticalBorders = chartSpec.getVerticalBorders()
    verticalBorders
      .filter(v => xMin <= v.x && v.x < xMax)
      .forEach(v => {
        const px = xScale(v.x)
        if (typeof px !== 'undefined') {
          chart
            .append('line')
            .attr('x1', px + (v.style.padding || 0))
            .attr('x2', px + (v.style.padding || 0))
            .attr('y1', height)
            .attr('y2', 12)
            .attr('stroke', v.style.color)
            .attr('stroke-width', `${v.style.width || 2}px`)
          chart
            .append('text')
            .attr('x', px + (v.style.padding || 0))
            .attr('y', 8)
            .text(v.label)
            .style('fill', 'black')
            .style('font-size', FontSize.SMALL)
            .style('font-weight', FontWeight.BOLD)
            .style('text-anchor', 'middle')
        }
      })
  }

  private drawTooltip = (ctx: {
    chart: d3.Selection<SVGGElement, unknown, HTMLElement, any>
  }) => {
    const { chart } = ctx
    // 1-6. draw tooltip
    this.tooltip = chart
      .append('g')
      .attr('class', 'tooltip')
      .style('display', 'none')

    this.tooltip
      .append('text')
      .attr('y', 16)
      .style('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('font-weight', 'bold')
  }

  private addEventListeners = () => {
    const eventListeners = this.props.chartOptions.eventListeners
    eventListeners.forEach(l => {
      d3.selectAll(l.selector).on(l.eventType, l.listener)
    })
  }

  private getXmin = (data: DataPerDim<D>[]): number => {
    const xMin =
      this.props.chartConfig.viewTime.from?.toNumberValue() || this.now
    if (this.props.viewConfig.timeGrain === TimeGrain.WEEK) {
      const dayOfWeek = moment(xMin).day()
      const startDayOfWeek = this.props.viewConfig.startDayOfWeek
      const startDayOfWeekNum = mapDayOfWeekToNumber(startDayOfWeek)
      const diff = (dayOfWeek - startDayOfWeekNum + 7) % 7
      return moment(xMin).subtract(diff, 'd').valueOf()
    }
    if (this.props.viewConfig.timeGrain === TimeGrain.MONTH) {
      return moment(xMin).startOf('M').valueOf()
    }
    return xMin
  }

  private getXmax = (data: DataPerDim<D>[]): number => {
    const xMax = this.props.chartConfig.viewTime.to?.toNumberValue() || this.now
    if (this.props.viewConfig.timeGrain === TimeGrain.WEEK) {
      const dayOfWeek = moment(xMax).day()
      const startDayOfWeek = this.props.viewConfig.startDayOfWeek
      const startDayOfWeekNum = mapDayOfWeekToNumber(startDayOfWeek)
      const diff = (startDayOfWeekNum - dayOfWeek + 7) % 7
      return moment(xMax).add(diff, 'd').add(1, 'w').valueOf()
    }
    if (this.props.viewConfig.timeGrain === TimeGrain.MONTH) {
      return moment(xMax).add(1, 'M').startOf('M').valueOf()
    }
    return moment(xMax).add(1, 'd').valueOf()
  }

  private getFloatFormat = () => {
    if (this.props.chartOptions.getMinScale) {
      const minScale = this.props.chartOptions.getMinScale(
        this.props.viewConfig
      )
      return `.${-Math.log10(minScale)}f`
    }
    return '.1f'
  }

  private getTicksCount = (yMax: number, scale: number) => {
    return Math.ceil(yMax / scale) + TICK_MARGIN
  }

  showTooltip = (text: string, event: any, dx: number = 0, dy: number = 0) => {
    d3.select(event.currentTarget).style('opacity', 0.5)
    const chart = select('#chartMain').node()
    // @ts-ignore
    const scrollTop = chart.scrollTop
    // @ts-ignore
    const scrollLeft = chart.scrollLeft
    const pos = d3.pointer(event, document.getElementById('chart')!)
    this.tooltip!.attr(
      'transform',
      `translate(${scrollLeft + pos[0] + dx},${scrollTop + pos[1] + dy})`
    )
      .style('display', 'block')
      .select('text')
      .text(text)
    const node = this.tooltip!.node()!
    node.parentNode!.appendChild(node)
  }

  moveTooltip = (pos: [number, number]) => {
    this.tooltip!.attr('transform', `translate(${pos[0]},${pos[1]})`)
  }

  closeTooltip = (elem: SVGElement) => {
    this.tooltip!.style('display', 'none')
    d3.select(elem).style('opacity', 1)
  }

  getLegend = () => {
    if (this.props.chartSpec.getLegend) {
      const onChangeConfig = (newConfig: C): void => {
        this.props.onChangeProps({ chartConfig: newConfig })
      }
      return this.props.chartSpec.getLegend(
        this.props.dataSeries,
        this.props.chartConfig,
        this,
        onChangeConfig
      )
    }
  }

  render() {
    return (
      <RootContainer>
        {this.props.toolbarItems.length > 0 && (
          <Toolbar>{this.props.toolbarItems}</Toolbar>
        )}
        <ChartContainer id="chartContainer">
          <Chart id="chart" />
          <ControlPanel>
            {this.props.controlPanels(this).map((v, i) => (
              <ControlPanelRow key={i}>{v}</ControlPanelRow>
            ))}
            <ControlPanelRow>{this.getLegend()}</ControlPanelRow>
          </ControlPanel>
        </ChartContainer>
      </RootContainer>
    )
  }
}

export default injectIntl(ProgressReportChart)
