import { Typography } from '@mui/material'
import { Button } from '../../../../components/buttons'
import { intl } from '../../../../../i18n'
import { openKanban } from '../../Kanban'
import styled from 'styled-components'
import { projectPrivate } from '../../../../higher-order-components/projectPrivate'
import ViewKanbanIcon from '@mui/icons-material/ViewKanban'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { StatusKanbanSearchCondition } from '../../hooks/useSearchCondition'

interface Props {
  query?: Partial<StatusKanbanSearchCondition>
}

const OpenKanbanButton = ({ query = {} }: Props) => {
  const { projectCode } = useProjectPrivateContext()
  return (
    <StyledButton
      onClick={() => openKanban(projectCode!, query)}
      variant="outlined"
      colorPattern="monotone"
    >
      <ViewKanbanIcon />
      <Typography>{intl.formatMessage({ id: 'kanban' })}</Typography>
    </StyledButton>
  )
}

export default projectPrivate(OpenKanbanButton)

const StyledButton = styled(Button)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: 'fit-content',
  cursor: 'pointer',
})
