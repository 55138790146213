import { ChangeEvent, useCallback, useState } from 'react'

type ChangePassword = {
  newPassword: string
  newPasswordConfirm: string
  showPassword: boolean
  inputType: string
  onChangeNewPassword: (event: ChangeEvent<HTMLInputElement>) => void
  onChangeNewPasswordConfirm: (event: ChangeEvent<HTMLInputElement>) => void
  toggleShowPassword: () => void
}

export const useChangePassword = (): ChangePassword => {
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const type = showPassword ? 'text' : 'password'
  const onChangeNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setNewPassword(event.target.value),
    []
  )
  const onChangeNewPasswordConfirm = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setNewPasswordConfirm(event.target.value),
    []
  )
  const toggleShowPassword = useCallback(() => {
    setShowPassword(prev => !prev)
  }, [])
  return {
    newPassword,
    newPasswordConfirm,
    showPassword,
    inputType: type,
    onChangeNewPassword,
    onChangeNewPasswordConfirm,
    toggleShowPassword,
  }
}
