import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea } from '..'
import { TicketListDetail } from '../../../lib/functions/ticketList'
import { AllState } from '../../../store'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import TicketListsOptions, {
  ColumnQuickFilterKey,
  TicketListsRow,
  TicketListsState,
} from './TicketListsOptions'
import { ColDef, ColumnState } from 'ag-grid-community'
import { UiStateKey } from '../../../lib/commons/uiStates'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { Box } from '@mui/material'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { usePageState } from '../../hooks/usePageState'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { WorkLoadUnitState, useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import TicketListsWbsHeader from './Header/TicketListsWbsHeader'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'

type TicketListsPageState = {
  toolbar: ToolbarToggleValue | undefined
  workloadUnit: WorkloadUnit
  dateFormat: string
}

const ticketListsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  workloadUnit: WorkloadUnit.HOUR,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
}

const TicketLists = props => {
  const fn = props.functions.find(v => v.externalId === props.externalId)

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        TicketListDetail,
        TicketListsRow,
        TicketListsState
      >
    >()

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const { workloadUnit, dateFormat, updatePageState } =
    usePageState<TicketListsPageState>(
      props.uuid,
      ticketListsDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const [currentColumnState, setCurrentColumnState] = useState<ColumnState[]>(
    []
  )

  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])

  const ticketListsOptions = useMemo(() => {
    const options = new TicketListsOptions()
    options.onColumnVisible = (e, ctx) => {
      setCurrentColumnState(bulkSheet?.columnApi?.getColumnState() ?? [])
    }
    return options
  }, [bulkSheet])

  const onChangeWorkloadUnit = useCallback((value: WorkloadUnit) => {
    updatePageState({ workloadUnit: value })
  }, [])

  const refreshCellsChangeWorkloadUnit = useCallback(
    (workloadUnit: WorkloadUnit, workloadUnitState: WorkLoadUnitState) => {
      if (!bulkSheet) return
      bulkSheet.setContext({ workloadUnit, workloadUnitState })
      bulkSheet.gridApi?.refreshCells({
        columns: ['wbsItem.estimatedWorkload', 'wbsItem.actualHour'],
      })
    },
    [bulkSheet]
  )

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ workloadUnit, workloadUnitState })
  }, [bulkSheet, workloadUnit, workloadUnitState])

  useEffect(() => {
    refreshCellsChangeWorkloadUnit(workloadUnit, workloadUnitState)
  }, [refreshCellsChangeWorkloadUnit, workloadUnit, workloadUnitState])

  const refreshCellsChangeDateFormat = useCallback(
    (value: string) => {
      if (!bulkSheet) return
      bulkSheet.setContext({ dateFormat: value })
      bulkSheet.gridApi?.refreshCells({
        columns: [
          'wbsItem.scheduledDate.startDate',
          'wbsItem.scheduledDate.endDate',
          'wbsItem.actualDate.startDate',
          'wbsItem.actualDate.endDate',
          'wbsItem.deadline',
        ],
        force: true,
      })
    },
    [bulkSheet]
  )

  useEffect(() => {
    refreshCellsChangeDateFormat(dateFormat)
  }, [refreshCellsChangeDateFormat, dateFormat])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const columnSetting = useColumnSetting()
  const ref = useRef<HTMLDivElement>(null)

  return (
    <PageArea>
      <SavePopper loading={isLoading} onSubmit={() => bulkSheet?.onSubmit()} />
      <TicketListsWbsHeader
        workloadUnit={workloadUnit}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        onCancel={() => bulkSheet?.onCancel()}
        isLoading={isLoading}
        onDeleteSortedColumn={bulkSheet?.onDeleteSortedColumn}
        onDeleteSortedAllColumns={bulkSheet?.onDeleteSortedAllColumns}
        onChangeSortColumnState={bulkSheet?.onChangeSortColumnState}
        rowHeight={rowHeight}
        onChangeHeight={value => bulkSheet?.onChangeHeight(value)}
        onClickExportExcel={() =>
          bulkSheet?.openExcelOutputColumnSelectDialog()
        }
        onClickFavoriteColumnFilterButton={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        filteredColumns={filteredColumns}
        resetFilters={() => bulkSheet?.resetColumnAndFilterState()}
        onDeleteFilteredColumn={column =>
          bulkSheet?.resetSpecificColumnFilter(column as string)
        }
        sortColumnsState={sortColumnsState}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        columnApi={bulkSheet?.columnApi ?? undefined}
        gridApi={bulkSheet?.gridApi ?? undefined}
        close={columnSetting.close}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.RESTORE)
        }
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={fn.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={currentColumnState}
        offset={90}
      />
      <Box sx={{ height: '100%' }} ref={ref}>
        <BulkSheetComponent
          {...props}
          options={ticketListsOptions}
          hideHeader={true}
          hideToolbar={true}
          setBulkSheet={ctx => setBulkSheet(ctx)}
          setFilteredColumns={columns => setFilteredColumns(columns)}
          setRowHeight={height => setRowHeight(height)}
          setSubmitDisabled={value => setSubmitDisabled(value)}
          setIsLoading={value => setIsLoading(value)}
          setSortColumnsState={value => setSortColumnsState(value)}
        />
      </Box>
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(TicketLists))
)
