import BaseError from '../BaseError'

export class AuthError extends BaseError {
  errorCode: AuthErrorCode
  constructor(errorCode: AuthErrorCode) {
    super({ errorCode, message: '' })
    this.errorCode = errorCode
  }
}

type AuthErrorCode =
  | 'TENANT_NOT_EXIST'
  | 'NO_TENANT_ALIAS'
  | 'NO_EMAIL_OR_PASSWORD'
  | 'INCORRECT_EMAIL_OR_PASSWORD'
  | 'ATTEMPT_LIMIT_EXCEEDED'
  | 'NO_EMAIL_INPUT'
  | 'NO_RESET_CODE'
  | 'NO_PASSWORD_INPUT'
  | 'PASSWORD_CONFIRM_UNMATCH'
  | 'INVALID_CHARACTOR_FOR_PASSWORD'
  | 'RESET_CODE_MISMATCH'
  | 'RESET_CODE_EXPIRED'
  | 'NOT_SATISFY_PASSWORD_POLICY'
  | 'NEED_RESCUE'
