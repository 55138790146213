import React from 'react'

export const RootDiv = ({
  children,
  id,
  className,
  style,
}: {
  children?: React.ReactNode
  id?: string
  className?: string
  style?: React.CSSProperties
}) => {
  let rootDivClassName = 'sevend-ag-cell'
  if (className) {
    rootDivClassName += ' ' + className
  }
  return (
    <div id={id} className={rootDivClassName} style={style}>
      {children}
    </div>
  )
}
