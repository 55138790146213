import { IItemDelta } from '../../../domain/value-object/ItemDeltaInputVO'
import { intl } from '../../../i18n'
import API, { APIResponse } from '../../../lib/commons/api'
import { UserBasic } from '../../../lib/functions/user'
import { generateUuid } from '../../../utils/uuids'
import {
  TreeSource,
  getParentUuid,
} from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'

export enum LedgerAccountsType {
  FinancialStatementAccounts = 'FINANCIAL_STATEMENT_ACCOUNTS',
  GeneralLedgerAccounts = 'GENERAL_LEDGER_ACCOUNTS',
  SubsidiaryAccounts = 'SUBSIDIARY_ACCOUNTS',
}

export enum FinancialStatementAccountsType {
  Sales = 'SALES',
  CostOfSales = 'COST_OF_SALES',
  SGAExpense = 'SGA_EXPENSE',
  GrossProfit = 'GROSS_PROFIT',
  OperatingIncome = 'OPERATING_INCOME',
}

export const getLedgerAccountsTypeLabel = (
  type: LedgerAccountsType
): string | undefined => {
  let messageId: string | undefined = undefined
  switch (type) {
    case LedgerAccountsType.FinancialStatementAccounts:
      messageId = 'ledgerAccounts.type.financialStatementAccounts'
      break
    case LedgerAccountsType.GeneralLedgerAccounts:
      messageId = 'ledgerAccounts.type.generalLedgerAccounts'
      break
    case LedgerAccountsType.SubsidiaryAccounts:
      messageId = 'ledgerAccounts.type.subsidiaryAccounts'
      break
  }
  return messageId ? intl.formatMessage({ id: messageId }) : undefined
}

/**
 * API response
 */
export class LedgerAccountsTree implements TreeSource<LedgerAccountsTree> {
  uuid: string
  code: string
  ledgerAccountsType: LedgerAccountsType
  displayName: string
  officialName?: string
  financialStatementAccountsType?: FinancialStatementAccountsType
  displayOrder: number
  children: LedgerAccountsTree[]

  lockVersion: number
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

/**
 * API request
 */
export interface GeneralLedgerAccountsBatchInput {
  uuid: string
  code?: string
  financialStatementAccountUuid: string
  displayName: string
  officialName?: string
  displayOrder: number
}

export interface SubsidairyAccountsBatchInput {
  uuid: string
  code?: string
  financialStatementAccountUuid: string
  generalLedgerAccountUuid: string
  displayName: string
  officialName?: string
  displayOrder: number
}

export interface LedgerAccountsBatchInput {
  uuid: string
  lockVersion: number
  displayName: string
  officialName?: string
  displayOrder: number
}

export interface LedgerAccountsBatchDeleteInput {
  uuid: string
  lockVersion: number
}

export interface LedgerAccountsBatchRequest {
  editedFinancialStatementAccounts: LedgerAccountsBatchInput[]
  addedGeneralLedgerAccounts: GeneralLedgerAccountsBatchInput[]
  editedGeneralLedgerAccounts: LedgerAccountsBatchInput[]
  deletedGeneralLedgerAccounts: LedgerAccountsBatchDeleteInput[]
  addedSubsidiaryAccounts: SubsidairyAccountsBatchInput[]
  editedSubsidiaryAccounts: LedgerAccountsBatchInput[]
  deletedSubsidiaryAccounts: LedgerAccountsBatchDeleteInput[]
}

/**
 * RowData definition
 */
export interface LedgerAccountsRow extends TreeRow {
  body: LedgerAccountsRowBody
}

export class LedgerAccountsRowBody {
  uuid: string
  code?: string
  ledgerAccountsType: LedgerAccountsType
  displayOrder: number
  displayName: string
  officialName?: string
  financialStatementAccountsType?: FinancialStatementAccountsType

  constructor(src: LedgerAccountsTree) {
    this.uuid = src.uuid
    this.code = src.code
    this.ledgerAccountsType = src.ledgerAccountsType
    this.displayOrder = src.displayOrder
    this.displayName = src.displayName
    this.officialName = src.officialName
    this.financialStatementAccountsType = src.financialStatementAccountsType
  }
}

/**
 * Row data functionality
 */
export const createNewLedgerAccountsRow = (
  type: LedgerAccountsType,
  displayOrder?: number
): LedgerAccountsRow => {
  const newUuid = generateUuid()
  return {
    uuid: newUuid,
    treeValue: [],
    body: {
      uuid: newUuid,
      ledgerAccountsType: type,
      displayOrder: displayOrder ?? 0,
      displayName: '',
    },
  }
}

/**
 * API
 */
export const fetchLedgerAccounts = async (params: {
  prentUuid?: string
}): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/ledger_accounts/tree', params)
}

export const updateBatch = async (
  request: LedgerAccountsBatchRequest
): Promise<APIResponse> => {
  return API.functional.request(
    'POST',
    '/api/v1/ledger_accounts/batch',
    request
  )
}

/**
 *
 * @param parent
 * @param child
 */
export const acceptChild = (
  parent: LedgerAccountsRow,
  child: LedgerAccountsRow
): boolean => {
  return parent.uuid === getParentUuid(child)
}
