import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { Button } from '../../../../components/buttons'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { StoredUiStateFilteringInput } from '../../../../components/dialogs/stored-ui-state-dialog/stored-ui-state-list'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import SvgFilterIcon from '../../../../components/icons/FilterIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import { ScopeIcon } from '../../../../components/dialogs/SavedUIStateDialog/contents/ScopeIcon'
import { UiStateScope } from '../../../../../lib/commons/uiStates'
import { PermanentUiState } from '../../../../../services/model/uiState'
import { SearchConditionAndColumnState } from '../../../../../services/model/searchConditionAndColumnState'
import {
  SearchConditionAndColumnStateDraft,
  StoredSearchConditionAndColumnStateEditorDialog,
} from '../../../../components/dialogs/stored-ui-state-dialog/stored-ui-state-editor-dialog/StoredSearchConditionAndColumnStateEditorDialog'
import { SavePermanentUiStateRequest } from '../../../../../services/storage-services/ui-states/permanentUiStateStorageService'
import {
  SearchConditionAndColumnStateStorageService,
  useSearchConditionAndColumnStateStorageService,
} from '../../../../../services/storage-services/ui-states/searchConditionAndColumnStateStorageService'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { WbsItemSearchConditionVO } from '../../../../../domain/value-object/WbsItemSearchConditionVo'
import { BulkSheetColumnState } from '../../../../model/bulkSheetColumnAndFilterState'
import { WbsItemSearchSearchCondition } from '../../hooks/useSearchCondition'
import { intl } from '../../../../../i18n'

type Props = {
  open: boolean
  handleSavedSearchConditionPanelClose: () => void
  filteringInput: string
  setFilteringInput: (newValue: string) => void
  onSelectState: (
    selected: PermanentUiState<SearchConditionAndColumnState>
  ) => void
  functionUuid: string
  searchCondition: WbsItemSearchSearchCondition
}

const SaveSearchConditionListDialog = ({
  open,
  handleSavedSearchConditionPanelClose,
  filteringInput,
  setFilteringInput,
  onSelectState,
  functionUuid,
  searchCondition,
}: Props) => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false)
  const closeCreateDialog = useCallback(() => {
    setOpenCreateDialog(false)
  }, [])
  const [editorDraft, setEditorDraft] = useState<
    SearchConditionAndColumnStateDraft | undefined
  >(undefined)
  const onCreate = useCallback(() => {
    setOpenCreateDialog(true)
    setEditorDraft({
      type: 'Create',
      searchCondition: searchCondition,
    })
  }, [searchCondition])

  const { projectUuid } = useProjectPrivateContext()
  const storageService = useSearchConditionAndColumnStateStorageService(
    functionUuid,
    projectUuid
  )
  useEffect(() => {
    if (open) {
      storageService.fetch()
    }
  }, [open])

  const onSubmit = useCallback(
    (request: SavePermanentUiStateRequest<SearchConditionAndColumnState>) => {
      storageService.save(request)
      setEditorDraft(undefined)
    },
    [storageService.save]
  )
  const onDelete = useCallback(
    (uuid: string) => {
      storageService.delete(uuid)
    },
    [storageService.delete]
  )

  return (
    <Dialog
      open={open}
      onClose={handleSavedSearchConditionPanelClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiPaper-root': { maxWidth: '820px', minHeight: '500px' },
      }}
    >
      <DialogTitle sx={{ padding: '25px 24px 0 30px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '770px',
          }}
        >
          <Typography
            sx={{ color: colorPalette.monotone[10], fontSize: '15px' }}
          >
            {intl.formatMessage({ id: 'storedSearchCondition' })}
          </Typography>
          <Button
            colorPattern="monotone"
            variant="outlined"
            sx={{
              margin: 'auto 20px auto auto',
              cursor: 'pointer',
            }}
            onClick={handleSavedSearchConditionPanelClose}
          >
            <ClearRoundedIcon />
            <Typography>
              {intl.formatMessage({ id: 'dialog.close' })}
            </Typography>
          </Button>
        </Box>
        <Divider sx={{ margin: '20px 0' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '770px',
          }}
        >
          <Typography
            sx={{ color: colorPalette.monotone[10], fontSize: '16px' }}
          >
            {intl.formatMessage({ id: 'savedUIState.content.title.list' })}
          </Typography>
          <Button
            colorPattern="skyBlue"
            variant="outlined"
            sx={{
              marginLeft: '20px',
              cursor: 'pointer',
            }}
            onClick={onCreate}
          >
            <AddRoundedIcon />
            <Typography>
              {intl.formatMessage({ id: 'savedUIState.save' })}
            </Typography>
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              margin: 'auto 20px auto auto',
            }}
          >
            <StoredUiStateFilteringInput
              value={filteringInput}
              onChange={setFilteringInput}
              placeholder={intl.formatMessage({
                id: 'savedUIState.search.condition.name',
              })}
              isSearchIcon={true}
              width={250}
              onClear={() => setFilteringInput('')}
            />
          </Box>
          <StoredSearchConditionAndColumnStateEditorDialog
            functionUuid={functionUuid}
            open={openCreateDialog}
            draft={editorDraft}
            onClose={closeCreateDialog}
            onSubmit={onSubmit}
            currentColumnState={[]}
            onDelete={onDelete}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '770px',
            marginTop: '20px',
          }}
        >
          <List
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: colorPalette.monotone[5],
              fontSize: FontSize.MEDIUM,
              fontWeight: FontWeight.NORMAL,
              width: '100%',
            }}
          >
            <ListItem
              sx={{
                width: '200px',
                padding: 0,
              }}
            >
              <Typography>
                {intl.formatMessage({
                  id: 'savedUIState.saved.search.condition.name',
                })}
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                width: '90px',
                padding: 0,
              }}
            >
              <SvgFilterIcon
                style={{
                  fontSize: 21,
                  paddingRight: '8px',
                  width: 'fit-contnt',
                }}
              />
              <Typography>
                {intl.formatMessage({ id: 'savedUIState.content.list.scope' })}
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                width: '150px',
                padding: 0,
              }}
            >
              <SvgFilterIcon
                style={{
                  fontSize: 21,
                  paddingRight: '8px',
                }}
              />
              <Typography>
                {intl.formatMessage({
                  id: 'savedUIState.content.list.updatedBy',
                })}
              </Typography>
            </ListItem>
            <ListItem
              sx={{
                width: '100px',
                padding: 0,
              }}
            >
              <Typography>
                {intl.formatMessage({
                  id: 'savedUIState.content.list.updatedAt',
                })}
              </Typography>
            </ListItem>
          </List>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '20px 24px 20px 30px', maxHeight: '400px' }}
      >
        <SaveSearchConditionList
          onSelect={onSelectState}
          functionUuid={functionUuid}
          currentSearchCondition={searchCondition}
          filteringInput={filteringInput}
          currentColumnState={[]}
          storageService={storageService}
        />
      </DialogContent>
    </Dialog>
  )
}

type SaveSearchConditionListProps = {
  onSelect: (selected: PermanentUiState<SearchConditionAndColumnState>) => void
  functionUuid: string
  currentSearchCondition: WbsItemSearchConditionVO
  currentColumnState: BulkSheetColumnState
  filteringInput: string
  storageService: SearchConditionAndColumnStateStorageService
}

interface StateProps {
  projectUuid: string | undefined
  projectIconUrl: string | undefined
  tenantIconUrl: string | undefined
  hasAllPrivileges: boolean | undefined
}

const SaveSearchConditionList = ({
  onSelect,
  functionUuid,
  currentSearchCondition,
  currentColumnState,
  filteringInput,
  storageService,
}: SaveSearchConditionListProps) => {
  const { projectUuid } = useProjectPrivateContext()

  const { projectIconUrl, tenantIconUrl } = useSelector<AllState, StateProps>(
    state => ({
      projectUuid: state.project.selected,
      projectIconUrl: state.project.current?.iconUrl,
      tenantIconUrl: state.tenant.organization?.iconUrl,
      hasAllPrivileges: state.user.user?.hasAllPrivileges,
    })
  )

  const scopeIcon = useCallback(
    (scope: 'CROSS_PROJECT' | 'TENANT' | 'USER') => {
      return scope ? <ScopeIcon scope={scope as UiStateScope} /> : <></>
    },
    [projectUuid, projectIconUrl, tenantIconUrl]
  )

  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
  }

  const [editorDraft, setEditorDraft] = useState<
    SearchConditionAndColumnStateDraft | undefined
  >(undefined)

  const onUpdate = useCallback(
    (target: PermanentUiState<SearchConditionAndColumnState>) => {
      setOpenEditDialog(true)
      setEditorDraft({
        type: 'Update',
        uuid: target.uuid,
        name: target.name,
        scope: target.scope,
        searchCondition: currentSearchCondition,
      })
    },
    [currentSearchCondition]
  )
  const onDelete = useCallback(
    (uuid: string) => {
      storageService.delete(uuid)
    },
    [storageService.delete]
  )
  const onSubmit = useCallback(
    (request: SavePermanentUiStateRequest<SearchConditionAndColumnState>) => {
      storageService.save(request)
      setEditorDraft(undefined)
    },
    [storageService.save]
  )

  const filteredStates = useMemo(
    () => storageService.stored.filter(s => s.name.includes(filteringInput)),
    [storageService, filteringInput]
  )

  return (
    <List>
      {filteredStates.map(storedState => (
        <ListItem key={storedState.uuid} sx={{ margin: '10px 0 ', padding: 0 }}>
          <ListItem sx={{ maxWidth: '200px', padding: 0 }}>
            <ListItemText
              sx={{
                color: colorPalette.monotone[10],
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                fontWeight: 500,
              }}
            >
              {storedState.name}
            </ListItemText>
          </ListItem>
          <ListItem
            sx={{
              maxWidth: '90px',
              display: 'flex',
              justifyContent: 'center',
              padding: 0,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '64px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'start',
              }}
            >
              {scopeIcon(storedState.scope)}
            </ListItemIcon>
          </ListItem>
          <ListItem sx={{ maxWidth: '150px', padding: 0 }}>
            <ListItemText
              sx={{ fontWeight: 500, color: colorPalette.monotone[10] }}
            >
              {storedState.updatedBy}
            </ListItemText>
          </ListItem>
          <ListItem sx={{ padding: '0 20px 0 0' }}>
            <ListItemText
              sx={{ color: colorPalette.monotone[4], fontWeight: 400 }}
            >
              {storedState.updatedAt}
            </ListItemText>
          </ListItem>
          <ListItem sx={{ maxWidth: '60px', paddingRight: '10px', padding: 0 }}>
            <Button
              colorPattern="monotone"
              variant="outlined"
              onClick={() => onUpdate(storedState)}
              sx={{ cursor: 'pointer' }}
            >
              <Typography>
                {intl.formatMessage({ id: 'savedUIState.content.button.edit' })}
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={{ maxWidth: '60px', paddingRight: '10px', padding: 0 }}>
            <Button
              colorPattern="skyBlue"
              variant="filled"
              sx={{ cursor: 'pointer' }}
              onClick={() => onSelect(storedState)}
            >
              <Typography>
                {intl.formatMessage({
                  id: 'savedUIState.content.button.apply',
                })}
              </Typography>
            </Button>
          </ListItem>
        </ListItem>
      ))}
      <StoredSearchConditionAndColumnStateEditorDialog
        functionUuid={functionUuid}
        open={openEditDialog}
        draft={editorDraft}
        onClose={handleEditDialogClose}
        onSubmit={onSubmit}
        currentColumnState={currentColumnState}
        onDelete={onDelete}
      />
    </List>
  )
}

export default SaveSearchConditionListDialog
