import { styled } from '@mui/material'
import { DateTerm } from '../../../../../utils/date'
import { DateSelect } from '../../../toolbars/common'
import { DateSelectType } from '../../../toolbars/common/DateSelect'
import { useCallback, useEffect, useRef, useState } from 'react'
import DateVO from '../../../../../vo/DateVO'

type Props = {
  dateTerm: DateTerm
  onSearch: (term: DateTerm) => void
}

export const MonthRangeSelector = (props: Props) => {
  const getDefaultDate = useCallback((value: string | undefined) => {
    if (!value) {
      return DateVO.now()
    }
    return new DateVO(value)
  }, [])

  const [startDate, setStartDate] = useState<DateVO>(
    getDefaultDate(props.dateTerm.startDate)
  )
  const [endDate, setEndDate] = useState<DateVO>(
    getDefaultDate(props.dateTerm.endDate)
  )
  const [execSearch, setExecSearch] = useState(false)
  const prevTerm = useRef<DateTerm>({
    startDate: startDate.formatForApi(),
    endDate: endDate.formatForApi(),
  })

  const onChangeStartMonth = useCallback(value => {
    if (value) {
      const date = new DateVO(value)
      setStartDate(date.getFirstDayOfMonth())
      setExecSearch(true)
    }
  }, [])

  const onChangeEndMonth = useCallback(value => {
    if (value) {
      const date = new DateVO(value)
      setEndDate(date.getLastDayOfMonth())
      setExecSearch(true)
    }
  }, [])

  const search = useCallback(() => {
    props.onSearch({
      startDate: startDate.formatForApi(),
      endDate: endDate.formatForApi(),
    })
  }, [endDate, props, startDate])

  useEffect(() => {
    if (!execSearch) return
    search()
    setExecSearch(false)
  }, [execSearch, search])

  useEffect(() => {
    if (props.dateTerm.startDate !== prevTerm.current.startDate) {
      prevTerm.current.startDate = props.dateTerm.startDate
      setStartDate(new DateVO(props.dateTerm.startDate))
    }
    if (props.dateTerm.endDate !== prevTerm.current.endDate) {
      prevTerm.current.endDate = props.dateTerm.endDate
      setEndDate(new DateVO(props.dateTerm.endDate))
    }
  }, [props.dateTerm.endDate, props.dateTerm.startDate])

  return (
    <RootDiv>
      <StartMonthDiv>
        <DateSelect
          type={DateSelectType.YEAR_MONTH}
          value={startDate}
          onChange={onChangeStartMonth}
        />
      </StartMonthDiv>
      <TermDiv>〜</TermDiv>
      <EndMonthDiv>
        <DateSelect
          type={DateSelectType.YEAR_MONTH}
          value={endDate}
          onChange={onChangeEndMonth}
        />
      </EndMonthDiv>
    </RootDiv>
  )
}

const RootDiv = styled('div')({
  width: '100%',
  padding: '10px 0',
  marginRight: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const StartMonthDiv = styled('div')({
  width: '90px',
  zIndex: 3,
})

const EndMonthDiv = styled('div')({
  width: '90px',
  zIndex: 3,
  marginRight: '6px',
})

const TermDiv = styled('div')({
  padding: '5px',
})
