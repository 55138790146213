import { styled } from '@mui/system'
import { useCallback } from 'react'
import MuiOpenInNewIcon from '@mui/icons-material/OpenInNewRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { IconButton } from './IconButton'
import {
  CheckResult,
  FunctionLayer,
  getUrlParamsFromLayer,
} from '../../../store/functionLayer'
import { getPathByExternalId } from '../../pages'
import { open } from '../../router'
import store from '../../../store'
import { addScreenMessage, MessageLevel } from '../../../store/messages'

type IconSize = 'xs' | 's' | 'm'
const Icon = styled(MuiOpenInNewIcon)<{ iconSize?: IconSize }>(
  ({ iconSize }) => {
    switch (iconSize) {
      case 'xs':
        return {
          width: '12px',
          height: '12px',
        }
      case 's':
        return {
          width: '14px',
          height: '14px',
        }
      case 'm':
        return {
          width: '16px',
          height: '16px',
        }
    }
  }
)
export const OpenInNewIcon = injectIntl(
  (
    props: {
      layer: FunctionLayer | (() => Promise<FunctionLayer>)
      size?: 'xs' | 's' | 'm'
    } & WrappedComponentProps
  ) => {
    const { intl, size, layer } = props
    const openInNew = useCallback(async (_layer: FunctionLayer) => {
      const result: CheckResult | undefined = _layer.canOpenInNew
        ? await _layer.canOpenInNew()
        : undefined
      if (result && !result.ok) {
        if (result.message) {
          store.dispatch(
            addScreenMessage(_layer.externalId, {
              type: MessageLevel.WARN,
              title: result.message,
            })
          )
        }
        return
      }
      if (_layer.onPreOpenInNew) {
        _layer.onPreOpenInNew()
      }
      const path = getPathByExternalId(_layer.externalId)
      const urlParams = getUrlParamsFromLayer(_layer)
      const url = urlParams
        ? `${path}/${_layer.code}?${urlParams}`
        : `${path}/${_layer.code}`

      open(url, undefined, undefined, true)
    }, [])
    const onClick = useCallback(async () => {
      if (typeof layer === 'function') {
        openInNew(await layer())
      } else {
        openInNew(layer)
      }
    }, [layer])
    return (
      <IconButton
        onClick={onClick}
        size={'small'}
        title={intl.formatMessage({
          id: 'open.inNewTab',
        })}
      >
        <Icon iconSize={size || 's'} color="action" />
      </IconButton>
    )
  }
)
