import React from 'react'
import { styled } from '@mui/system'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import WbsItem, { WbsItemDetail } from '../../../../../../lib/functions/wbsItem'
import Team, { TeamProps } from '../../../../../../lib/functions/team'
import ProjectMember, {
  ProjectMemberDetail,
} from '../../../../../../lib/functions/projectMember'
import Paper from '@mui/material/Paper'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Sprint, {
  SprintDetail,
  SprintStatus,
} from '../../../../../../lib/functions/sprint'
import { AutocompleteOption } from '../../../../DataCell'
import {
  AggregateBy,
  AggregateTargetUnit,
  WbsProgressLogChartType,
} from '../../../../../../lib/functions/wbsProgressLog'
import { WbsItemStatus } from '../../../../commons/AgGrid/components/cell/custom/wbsItemStatus'
import objects from '../../../../../../utils/objects'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../../../lib/functions/customEnumValue'
import { CustomEnumValue } from '../../../../../../lib/commons/appFunction'
import DateVO from '../../../../../../vo/DateVO'
import * as SearchConditions from '../../../../SearchConditions'
import SearchButton from '../../../../../components/buttons/SearchButton'
import { IconButton, Popover } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { BackgroundColor } from '../../../../../../styles/commonStyles'
import {
  DayOfWeek,
  getTimeGrainLabel,
  SearchConditionBase,
  TimeGrain,
} from '../../../../../../lib/functions/report'
import { muiTheme } from '../../../../../../styles/muiTheme'
import store from '../../../../../../store'

export const SearchConditionProgress = {
  with: (args: Partial<WbsProgressLogSearchCondition>) => ({
    aggregateTargetUnit: AggregateTargetUnit.HOUR,
    timeGrain: TimeGrain.DAY,
    wbsItemType: store.getState().project.wbsItemTypes.task.rootType,
    ...args,
  }),
}

// Styles
const theme = muiTheme
const SearchIconButton = styled(IconButton)({
  background: BackgroundColor.BLUE_GRADATION,
  width: '24px',
  height: '24px',
  padding: 0,
  boxShadow: '0px 1px 1px #00000033',
})
const StyledSearchIcon = styled(SearchIcon)({
  color: '#ffffff',
  width: '14px',
  height: '14px',
})
const StyledPopover = styled(Popover)({
  overflow: 'visible',
  width: '400px',
  '& .MuiPopover-paper': {
    overflow: 'visible',
    width: '400px',
  },
})
const SearchConditionContainer = styled('div')({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const SearchButtonBox = styled('div')({
  margin: `${theme.spacing(1)} 0 0 0`,
})
const StyledTable = styled(Table)({
  width: '100%',
  height: '100%',
})

export interface WbsProgressLogSearchCondition extends SearchConditionBase {
  aggregateBy?: AggregateBy[]
  time?: {
    from?: DateVO
    to?: DateVO
  }
  timeGrain?: TimeGrain
  startDayOfWeek?: DayOfWeek
  rootWbsItemUuid?: string
  wbsItemCodes?: string
  wbsItemType?: string
  wbsItemStatusList?: WbsItemStatus[]
  teamUuid?: string
  sprintUuid?: string
  accountableUuid?: string
  responsibleUuid?: string
}

interface EntitySearchCondition {
  rootWbsItem?: AutocompleteOption
  team?: AutocompleteOption
  sprint?: AutocompleteOption
  accountable?: AutocompleteOption
  responsible?: AutocompleteOption
}

export interface Props extends WrappedComponentProps {
  projectUuid: string
  chartType: WbsProgressLogChartType
  searchConditionKeys: SearchConditionKey[]
  condition: WbsProgressLogSearchCondition
  onSearch: () => void
}

export interface State {
  condition: WbsProgressLogSearchCondition
  wbsItemStatusList: CustomEnumValue[]
  entitySearchCondition: EntitySearchCondition
  anchorEl: any
}

export enum SearchConditionKey {
  TIME = 'time',
  TIME_GRAIN = 'timeGrain',
  WBS_ITEM_TYPE = 'wbsItemType',
  ROOT_WBS_ITEM = 'rootWbsItem',
  WBS_ITEM_CODES = 'wbsItemCodes',
  STATUS = 'wbsItemStatusList',
  TEAM = 'team',
  SPRINT = 'sprint',
  ACCOUNTABLE = 'accountable',
  RESPONSIBLE = 'responsible',
}

class ReportSearchCondition extends React.Component<Props, State> {
  state = {
    condition: this.props.condition,
    wbsItemStatusList: [],
    entitySearchCondition: {} as EntitySearchCondition,
    anchorEl: null,
  }

  shouldComponentUpdate(nextProps: Props): boolean {
    if (!this.props.condition || this.props.condition !== nextProps.condition) {
      this.updateEsCondition(nextProps.condition)
    }
    return true
  }

  async componentDidMount() {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
    })
    this.setState({
      wbsItemStatusList: (response.json || []).map(s => ({
        value: s.value,
        label: s.name,
      })),
    })

    let condition: WbsProgressLogSearchCondition = this.state.condition
    await this.updateEsCondition(condition)
  }

  private updateEsCondition = async (condition: any) => {
    let esCondition: EntitySearchCondition = {}
    if (condition.rootWbsItemUuid) {
      const wbsItemResponse = await WbsItem.getDetail({
        uuid: condition.rootWbsItemUuid,
      })
      const wbsItem = wbsItemResponse.json as WbsItemDetail
      esCondition = {
        ...esCondition,
        rootWbsItem: { uuid: wbsItem.uuid, name: wbsItem.displayName },
      }
    }
    if (condition.teamUuid) {
      const teamResponse = await Team.entitySearchApi({
        projectUuid: this.props.projectUuid,
      })
      const teams: TeamProps[] = teamResponse.json
      const team = teams.find(v => v.uuid === condition.teamUuid)
      if (team) {
        let sprint: SprintDetail | undefined
        if (condition.sprintUuid) {
          const sprintsResponse = await Sprint.getSprints({
            teamUuid: team.uuid,
            statusList: Object.values(SprintStatus),
          })
          const sprints = sprintsResponse.json as SprintDetail[]
          sprint = sprints.find(v => v.uuid === condition.sprintUuid)
        }
        esCondition = {
          ...esCondition,
          team: { uuid: team.uuid, name: team.displayName },
          sprint,
        }
      }
    }
    if (condition.accountableUuid || condition.responsibleUuid) {
      const memberResponse = await ProjectMember.getProjectMembers({
        projectUuid: this.props.projectUuid,
      })
      const members: ProjectMemberDetail[] = memberResponse.json
      const users = members.map(v => v.user)
      if (condition.accountableUuid) {
        const accountable = users.find(
          v => v.uuid === condition.accountableUuid
        )
        esCondition = { ...esCondition, accountable }
      }
      if (condition.responsibleUuid) {
        const responsible = users.find(
          v => v.uuid === condition.responsibleUuid
        )
        esCondition = { ...esCondition, responsible }
      }
    }
    this.setState({ entitySearchCondition: esCondition })
  }

  private updateCondition = (params: { [key: string]: any }) => {
    for (const [key, value] of Object.entries(params)) {
      objects.setValue(this.props.condition, key, value)
    }
    this.setState({ condition: this.props.condition })
  }

  private updateAutoCompleteCondition = (params: { [key: string]: any }) => {
    for (const [key, value] of Object.entries(params)) {
      const entitySearchCondition = this.state.entitySearchCondition
      objects.setValue(entitySearchCondition, key, value)
      this.setState({ entitySearchCondition })
      this.updateCondition({ [`${key}Uuid`]: value ? value.uuid : undefined })
    }
  }

  getHeader = (key: SearchConditionKey) => {
    return this.props.intl.formatMessage({
      id: `progressReport.${key}`,
    })
  }

  renderSelectCondition = (
    key: SearchConditionKey,
    options: any[],
    clearable: boolean
  ) => {
    return (
      <SearchConditions.SelectCondition
        id={key}
        value={objects.getValue(this.state.condition, key)}
        options={options}
        onChange={this.updateCondition}
        header={this.getHeader(key)}
        clearable={clearable}
      />
    )
  }

  renderEntitySearchCondition = (
    key: SearchConditionKey,
    referenceEntity: string
  ) => {
    return (
      <SearchConditions.EntitySearchCondition
        id={key}
        value={objects.getValue(this.state.entitySearchCondition, key)}
        onChange={this.updateAutoCompleteCondition}
        referenceEntity={referenceEntity}
        header={this.getHeader(key)}
      />
    )
  }

  renderCondition = (key: SearchConditionKey) => {
    switch (key) {
      case SearchConditionKey.TIME:
        return (
          <SearchConditions.DateRangeCondition
            id={key}
            onChange={this.updateCondition}
            rangeValueFrom={this.state.condition.time?.from}
            rangeValueTo={this.state.condition.time?.to}
            header={this.getHeader(key)}
          />
        )
      case SearchConditionKey.TIME_GRAIN:
        const isWeek = this.state.condition.timeGrain === TimeGrain.WEEK
        return (
          <SearchConditions.DoubleSelectCondition
            header={this.getHeader(SearchConditionKey.TIME_GRAIN)}
            idLeft={'timeGrain'}
            valueLeft={this.state.condition.timeGrain}
            optionsLeft={Object.values(TimeGrain).map(timeGrain => {
              return {
                label: getTimeGrainLabel(timeGrain),
                value: timeGrain,
              }
            })}
            idRight={'startDayOfWeek'}
            valueRight={
              !isWeek ? undefined : this.state.condition.startDayOfWeek
            }
            optionsRight={Object.values(DayOfWeek).map(value => ({
              label: this.props.intl.formatMessage({
                id: `progressReport.startDayOfWeek.${value}`,
              }),
              value,
            }))}
            disabledRight={!isWeek}
            onChange={this.updateCondition}
          />
        )
      case SearchConditionKey.WBS_ITEM_TYPE:
        return this.renderSelectCondition(
          key,
          [
            store.getState().project.wbsItemTypes.deliverable,
            store.getState().project.wbsItemTypes.task,
            ...store.getState().project.ticketTypes,
          ].map(v => {
            return { value: v.code, label: v.name }
          }),
          false
        )
      case SearchConditionKey.ROOT_WBS_ITEM:
        return this.renderEntitySearchCondition(key, 'WbsItem')
      case SearchConditionKey.WBS_ITEM_CODES:
        return (
          <SearchConditions.TextCondition
            id={key}
            value={objects.getValue(this.state.condition, key)}
            header={this.getHeader(key)}
            onChange={this.updateCondition}
          />
        )
      case SearchConditionKey.STATUS:
        return this.renderSelectCondition(
          key,
          this.state.wbsItemStatusList,
          true
        )
      case SearchConditionKey.TEAM:
        return this.renderEntitySearchCondition(key, 'Team')
      case SearchConditionKey.SPRINT:
        return this.renderEntitySearchCondition(key, 'Sprint')
      case SearchConditionKey.ACCOUNTABLE:
      case SearchConditionKey.RESPONSIBLE:
        return this.renderEntitySearchCondition(key, 'ProjectMember')
    }
  }

  onButtonClick = (event: any) =>
    this.setState({
      anchorEl: this.state.anchorEl ? null : event.currentTarget,
    })
  onClose = () =>
    this.setState({
      anchorEl: null,
    })

  render() {
    return (
      <div>
        <SearchIconButton
          onClick={this.onButtonClick}
          aria-describedby="progress-report-view-configuration"
        >
          <StyledSearchIcon color="action" />
        </SearchIconButton>
        <StyledPopover
          id="progress-report-view-configuration"
          open={Boolean(this.state.anchorEl)}
          onClose={this.onClose}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SearchConditionContainer>
            <TableContainer component={Paper}>
              <StyledTable size="small" aria-label="customized table">
                <TableBody>
                  {this.props.searchConditionKeys.map(key =>
                    this.renderCondition(key)
                  )}
                </TableBody>
              </StyledTable>
            </TableContainer>
            <SearchButtonBox>
              <SearchButton
                onClick={() => {
                  this.props.onSearch()
                  this.onClose()
                }}
              />
            </SearchButtonBox>
          </SearchConditionContainer>
        </StyledPopover>
      </div>
    )
  }
}

export default injectIntl(ReportSearchCondition)
