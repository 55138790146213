import { ColDef } from 'ag-grid-community'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import { Button } from '../../../../components/buttons'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import { Box } from '@mui/material'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import { DateTerm } from '../../../../../utils/date'
import { useEffect, useState } from 'react'
import MonthPicker from '../../../../containers/BulkSheetView/components/header/datePicker/MonthPicker'
import { RowGroupColumnType } from '../../ResourcePlanCrossProjects'
import { RowGroupColumnToggleGroup } from './RowGroupColumnToggleGroup'

type Props = {
  fromDate?: string
  toDate?: string
  onChangeDateTerm: (term: DateTerm) => void
  onReload: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn?: (column: ColDef) => void
  resetFilters: () => void
  onDeleteSortedColumn?: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState?:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  sortColumnsState: SortedColumnState[]
  onClickColumnSettingButton: (e) => void
  columnSettingOpen: boolean
  rowHeight: number | undefined
  onChangeHeight: (value: number) => void
  onClickExportExcel: () => void
  rowGroupColumn: RowGroupColumnType | undefined
  onChangeRowGroupColumn: (type: RowGroupColumnType | undefined) => void
  getDisplayColumnName: (colDef: ColDef) => string | undefined
}

const ResourcePlanCrossProjectsHeader = ({
  fromDate,
  toDate,
  onChangeDateTerm,
  onReload,
  filteredColumns,
  onDeleteFilteredColumn,
  resetFilters,
  onDeleteSortedColumn,
  onDeleteSortedAllColumns,
  onChangeSortColumnState,
  sortColumnsState,
  onClickColumnSettingButton,
  columnSettingOpen,
  rowHeight,
  onChangeHeight,
  onClickExportExcel,
  rowGroupColumn,
  onChangeRowGroupColumn,
  getDisplayColumnName,
}: Props) => {
  const [internalFromDate, setFromDate] = useState<string | undefined>(fromDate)
  const [internalToDate, setToDate] = useState<string | undefined>(toDate)

  useEffect(() => {
    if (fromDate !== internalFromDate) {
      setFromDate(fromDate)
    }
    if (toDate !== internalToDate) {
      setToDate(toDate)
    }
  }, [internalFromDate, internalToDate, fromDate, toDate])

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '4px',
          background: colorPalette.monotone[0],
          margin: '12px 10px 0px',
          overflowX: 'auto',
          overflowY: 'hidden',
          minHeight: '48px',
        }}
      >
        <MonthPicker
          dateTerm={{
            startDate: fromDate,
            endDate: toDate,
          }}
          onChangeDateTerm={(dateTerm: DateTerm) => {
            setFromDate(dateTerm.startDate)
            setToDate(dateTerm.endDate)
            onChangeDateTerm({
              startDate: dateTerm.startDate,
              endDate: dateTerm.endDate,
            })
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '5px 10px 5px',
          overflowX: 'auto',
          overflowY: 'hidden',
          minHeight: '47px',
        }}
      >
        <Button
          onClick={onReload}
          sx={{
            background: colorPalette.monotone[1],
            border: `1px solid ${colorPalette.monotone[3]}`,
            borderRadius: '50%',
            height: '28px',
            width: '28px',
            marginRight: '10px',
            padding: '5px 8px',
            cursor: 'pointer',
            '&:hover': { background: colorPalette.monotone[1] },
          }}
        >
          <ReloadIcon />
        </Button>
        <RowGroupColumnToggleGroup
          value={rowGroupColumn}
          onChange={onChangeRowGroupColumn}
        />
        <FilterButton
          filteredColumns={filteredColumns}
          onDeleteFilteredColumn={onDeleteFilteredColumn}
          resetFilters={resetFilters}
          getDisplayColumnName={getDisplayColumnName}
        />
        <SortButton
          onDeleteSortedColumn={onDeleteSortedColumn}
          onDeleteSortedAllColumns={onDeleteSortedAllColumns}
          onChangeSortColumnState={onChangeSortColumnState}
          sortColumnsState={sortColumnsState}
          getDisplayColumnName={getDisplayColumnName}
        />
        <ColumnSettingButton
          onClick={onClickColumnSettingButton}
          open={columnSettingOpen}
        />
        <DisplayFormatButton
          height={rowHeight}
          onChangeHeight={onChangeHeight}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
            marginRight: '15px',
          }}
        >
          <FileExportButton onClickExport={onClickExportExcel} />
        </Box>
      </div>
    </>
  )
}

export default ResourcePlanCrossProjectsHeader
