import { ColDef } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PageArea } from '..'
import { UserDetail } from '../../../lib/functions/user'
import { AllState } from '../../../store'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import UserOptions, {
  ColumnQuickFilterKey,
  UserRow,
  UserState,
} from './UserOptions'
import UsersHeader from './Header'
import UsersToolbar from './Toolbar'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Collapse } from '@mui/material'
import { intl } from '../../../i18n'
import { usePageState } from '../../hooks/usePageState'
import { pageComponent } from '../../higher-order-components/pageComponent'

type UsersPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const usersDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const Users = props => {
  const fn = props.functions.find(v => v.externalId === props.externalId)

  const usersOptions = useMemo(() => new UserOptions(), [])

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<BulkSheetSpecificProps, UserDetail, UserRow, UserState>
    >()

  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [searchText, setSearchText] = useState('')

  const { toolbar, updatePageState } = usePageState<UsersPageState>(
    props.uuid,
    usersDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onSearch = useCallback(() => {
    if (!bulkSheet) return
    bulkSheet.setState(
      {
        searchText: searchText,
      },
      () => bulkSheet?.refreshDataWithLoading()
    )
  }, [bulkSheet, searchText])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
        setSearchText('')
        return
      }
      if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
        return
      }
    },
    [bulkSheet]
  )

  const onClickAddUser = useCallback(() => {
    bulkSheet?.setState({
      addRowCountInputDialogState: {
        open: true,
        title: intl.formatMessage({
          id: 'user.insert.multipleRow.title',
        }),
        submitHandler: addRowCount => {
          if (!addRowCount) {
            throw new Error('addRowCount is 0 or undefined.')
          }
          Array.from({ length: addRowCount }).forEach(value =>
            bulkSheet.addRow()
          )
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
        closeHandler: () => {
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
      },
    })
  }, [bulkSheet])

  useEffect(() => {
    const bulkSearchText = bulkSheet?.state.searchText || ''
    if (bulkSearchText !== searchText) {
      setSearchText(bulkSearchText)
    }
  }, [bulkSheet?.state.searchText])

  return (
    <PageArea>
      <UsersHeader
        title={fn.name}
        onSubmit={() => bulkSheet?.onSubmit()}
        onCancel={() => bulkSheet?.onCancel()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        searchText={searchText}
        onChangeSearchText={value => setSearchText(value)}
        onSearch={onSearch}
        hit={bulkSheet?.state.hit || 0}
        total={bulkSheet?.state.total || 0}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        rowHeight={rowHeight}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        onClickImport={data => bulkSheet?.importExcel(data)}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        onRestoreSearchCondition={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateSearchCondition
          )
        }
        onClickAddUser={onClickAddUser}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <UsersToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={usersOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
})

export default connect(mapStateToProps)(pageComponent(Users))
