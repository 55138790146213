import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { styled } from '@mui/system'
import Tabs from './Tabs'
import ManagementNotice from './ManagementNotice'
import Message from './Message'
import News from './News'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Comment from './Comment'
import { FONT_FAMILY } from '../../../styles/commonStyles'
import { AllState } from '../../../store'
import { connect, useDispatch } from 'react-redux'
import _ from 'lodash'
import Drawer from '../../components/drawers/Drawer'
import {
  closeInformation,
  CommentProps,
  setTabKey,
  TabKey,
  ViewType,
} from '../../../store/information'
import WbsitemCommentUserSummaries from './WbsitemCommentUserSummaries'
import { ResizableArea } from '../../components/draggers/ResizableArea'
import { UiStateKey } from '../../../lib/commons/uiStates'
import useRememberedDrawerState from './hooks/useRememberedDrawerState'
import { colorPalette } from '../../style/colorPallete'

interface InformationProps {
  applicationFunctionUuid?: string
  children?: any
}

interface StateProps {
  open: boolean
  type: ViewType
  commentProps?: CommentProps
  tabKey: TabKey
}

interface Props extends WrappedComponentProps, InformationProps, StateProps {}

const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  background: theme.palette.background.paper,
  overflow: 'hidden',
  width: '100%',
  height: 'calc(100% - 56px)',
  fontFamily: FONT_FAMILY,
  display: 'flex',
}))
const ContentsContainer = styled('div')({
  height: '100%',
})
const ContentsBox = styled('div')({
  height: 'calc(100% - 40px)',
  overflow: 'auto',
  background: colorPalette.monotone[1],
})
const InformationContents = {
  [TabKey.COMMENT]: <Comment />,
  [TabKey.NEWS]: <News />,
  [TabKey.MESSAGE]: <Message />,
  [TabKey.MANAGEMENT_NOTICE]: <ManagementNotice />,
}
const WbsItemCommentSummaryContents = {
  [TabKey.COMMENT]: <Comment />,
}

const ResizableContentsContainer = ({
  id,
  children,
  width,
  maxWidth,
  minWidth,
  onResize,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <ContentsContainer
      id={id}
      ref={ref}
      style={{ borderRight: '1px solid #DDDDDD' }}
    >
      <ResizableArea
        width={width}
        maxWidth={maxWidth}
        minWidth={minWidth}
        height={ref.current?.clientHeight || 0}
        onResize={onResize}
      >
        {children}
      </ResizableArea>
    </ContentsContainer>
  )
}

const InformationContentsWidth = {
  default: 540,
  min: 360,
}
const WbsItemCommentUserSummaryWidth = {
  default: 550,
  min: 500,
}

const RightSideDrawer = ({
  drawerContainerId,
  open,
  defaultWidth,
  minWidth,
  maxWidth,
  onClose,
  onChangeDrawerWidth,
  children,
}: PropsWithChildren<{
  drawerContainerId: string
  open: boolean
  defaultWidth: number
  minWidth: number
  maxWidth: number
  onClose: () => void
  onChangeDrawerWidth: (newWidth: number) => void
}>) => {
  return (
    <Drawer
      elevation={true}
      open={open}
      onClose={onClose}
      defaultWidth={defaultWidth}
      minWidth={minWidth}
      maxWidth={maxWidth}
      anchor={'right'}
      isResizable={true}
      closeOnClickAway={true}
      onChangeWidth={onChangeDrawerWidth}
    >
      <Container id={drawerContainerId}>{children}</Container>
    </Drawer>
  )
}

type InformationUiState = {
  drawerContainerWidth: number
}
const InformationView = ({
  intl,
  open,
  tabKey,
  commentProps,
}: Omit<Props, 'type'>) => {
  const dispatch = useDispatch()
  const drawerWidthConfig = useMemo(() => {
    const maxWidth = window.innerWidth - 50
    const defaultWidth = InformationContentsWidth.default
    return {
      default: defaultWidth,
      min: InformationContentsWidth.min,
      max: maxWidth,
    }
  }, [])
  const tabs = useMemo(() => {
    const tabsValue: {
      key: TabKey
      label: string
      value: TabKey
    }[] = []
    if (commentProps) {
      tabsValue.push({
        key: TabKey.COMMENT,
        label: intl.formatMessage({
          id: 'information.comment',
        }),
        value: TabKey.COMMENT,
      })
    }
    tabsValue.push(
      {
        key: TabKey.NEWS,
        label: intl.formatMessage({
          id: 'information.news',
        }),
        value: TabKey.NEWS,
      },
      {
        key: TabKey.MANAGEMENT_NOTICE,
        label: intl.formatMessage({
          id: 'information.management.notice',
        }),
        value: TabKey.MANAGEMENT_NOTICE,
      }
    )

    return tabsValue
  }, [intl, commentProps])

  const { rememberedDrawerState, rememberDrawerState } =
    useRememberedDrawerState<InformationUiState>(
      UiStateKey.InformationViewState
    )
  const onChangeDrawerWidth = useCallback(
    _.debounce((newWidth: number) => {
      rememberDrawerState({
        drawerContainerWidth: newWidth,
      })
    }, 300),
    [rememberDrawerState]
  )

  return (
    <RightSideDrawer
      drawerContainerId="information-view-drawer-container"
      open={open}
      defaultWidth={
        rememberedDrawerState?.drawerContainerWidth || drawerWidthConfig.default
      }
      minWidth={drawerWidthConfig.min}
      maxWidth={drawerWidthConfig.max}
      onClose={() => open && dispatch(closeInformation())}
      onChangeDrawerWidth={onChangeDrawerWidth}
    >
      <ContentsContainer
        style={{
          width: '100%',
        }}
      >
        <Tabs
          selectedValue={tabKey}
          tabs={tabs}
          onClickTab={selectedTabKey =>
            dispatch(setTabKey(selectedTabKey as TabKey))
          }
        />
        <ContentsBox>{InformationContents[tabKey]}</ContentsBox>
      </ContentsContainer>
    </RightSideDrawer>
  )
}

type WbsItemCommentUserSummaryUiState = {
  drawerContainerWidth: number
  wbsItemCommentUserSummaryWidth: number
}
const WbsItemCommentUserSummaryView = ({
  intl,
  open,
  tabKey,
}: Omit<Props, 'type' | 'commentProps'>) => {
  const dispatch = useDispatch()
  const [wbsItemCommentUserSummaryWidth, setWbsItemCommentUserSummaryWidth] =
    useState(WbsItemCommentUserSummaryWidth.default)
  const [
    wbsItemCommentUserSummaryMaxWidth,
    setWbsItemCommentUserSummaryMaxWidth,
  ] = useState(WbsItemCommentUserSummaryWidth.default)
  const drawerWidthConfig = useMemo(() => {
    const maxWidth = window.innerWidth - 50
    const defaultWidth =
      InformationContentsWidth.default + WbsItemCommentUserSummaryWidth.default
    return {
      default: defaultWidth,
      min: InformationContentsWidth.min + WbsItemCommentUserSummaryWidth.min,
      max: maxWidth,
    }
  }, [])
  const tabs = useMemo(
    () => [
      {
        key: TabKey.COMMENT,
        label: intl.formatMessage({
          id: 'information.comment',
        }),
        value: TabKey.COMMENT,
      },
    ],
    [intl]
  )

  const { rememberedDrawerState, rememberDrawerState, fetched } =
    useRememberedDrawerState<WbsItemCommentUserSummaryUiState>(
      UiStateKey.WbsItemCommentUserSummaryViewState
    )

  const drawerDefaultWidth =
    rememberedDrawerState?.drawerContainerWidth || drawerWidthConfig.default
  const [commentContainerWidth, setCommentContainerWidth] = useState(
    drawerDefaultWidth - wbsItemCommentUserSummaryWidth
  )

  const rememberDrawerStateDebounce = useCallback(
    _.debounce(rememberDrawerState, 300),
    [rememberDrawerState]
  )
  const onChangeDrawerWidth = useCallback(
    (newWidth: number) => {
      const wbsItemCommentUserSummaryContainer = document.getElementById(
        'wbs-item-comment-user-summary-container'
      )
      if (!wbsItemCommentUserSummaryContainer) {
        return
      }
      const wbsItemCommentUserSummaryWidth =
        wbsItemCommentUserSummaryContainer.clientWidth
      const informationContentsWidth = newWidth - wbsItemCommentUserSummaryWidth
      setWbsItemCommentUserSummaryMaxWidth(
        newWidth - InformationContentsWidth.min
      )
      setCommentContainerWidth(informationContentsWidth)
      if (informationContentsWidth < InformationContentsWidth.min) {
        setWbsItemCommentUserSummaryWidth(
          newWidth - InformationContentsWidth.min
        )
      }
      rememberDrawerStateDebounce({
        drawerContainerWidth: newWidth,
        wbsItemCommentUserSummaryWidth: wbsItemCommentUserSummaryWidth,
      })
    },
    [rememberDrawerStateDebounce]
  )
  const onResizeWbsItemCommentUserSummaryWidth = useCallback(
    (newSize: { width: number; height: number }) => {
      const drawerContainer = document.getElementById(
        'wbs-item-comment-user-summary-view-drawer-container'
      )
      if (!drawerContainer) {
        return
      }
      setWbsItemCommentUserSummaryMaxWidth(
        drawerContainer.clientWidth - InformationContentsWidth.min
      )
      setCommentContainerWidth(drawerContainer.clientWidth - newSize.width)
      rememberDrawerStateDebounce({
        drawerContainerWidth: drawerContainer.clientWidth,
        wbsItemCommentUserSummaryWidth: newSize.width,
      })
    },
    [rememberDrawerStateDebounce]
  )

  useEffect(() => {
    if (rememberedDrawerState) {
      setWbsItemCommentUserSummaryWidth(
        rememberedDrawerState.wbsItemCommentUserSummaryWidth
      )
      setCommentContainerWidth(
        rememberedDrawerState.drawerContainerWidth -
          rememberedDrawerState.wbsItemCommentUserSummaryWidth
      )
    }
  }, [rememberedDrawerState])

  if (!fetched) return <></>

  return (
    <RightSideDrawer
      drawerContainerId="wbs-item-comment-user-summary-view-drawer-container"
      open={open}
      defaultWidth={drawerDefaultWidth}
      minWidth={drawerWidthConfig.min}
      maxWidth={drawerWidthConfig.max}
      onClose={() => open && dispatch(closeInformation())}
      onChangeDrawerWidth={onChangeDrawerWidth}
    >
      <ResizableContentsContainer
        id="wbs-item-comment-user-summary-container"
        width={wbsItemCommentUserSummaryWidth}
        maxWidth={wbsItemCommentUserSummaryMaxWidth}
        minWidth={WbsItemCommentUserSummaryWidth.min}
        onResize={onResizeWbsItemCommentUserSummaryWidth}
      >
        <WbsitemCommentUserSummaries
          open={open}
          onClose={() => open && dispatch(closeInformation())}
        />
      </ResizableContentsContainer>
      <ContentsContainer
        style={{
          width: commentContainerWidth,
        }}
      >
        <Tabs
          selectedValue={tabKey}
          tabs={tabs}
          onClickTab={selectedTabKey =>
            dispatch(setTabKey(selectedTabKey as TabKey))
          }
        />
        <ContentsBox>{WbsItemCommentSummaryContents[tabKey]}</ContentsBox>
      </ContentsContainer>
    </RightSideDrawer>
  )
}

const Information = ({ intl, open, type, tabKey, commentProps }: Props) => {
  if (!type) return <></>
  return (
    <>
      <InformationView
        intl={intl}
        open={open && type === ViewType.INFORMATION}
        tabKey={tabKey}
        commentProps={commentProps}
      />
      <WbsItemCommentUserSummaryView
        intl={intl}
        open={open && type === ViewType.WBS_COMMENT_USER_SUMMARIES}
        tabKey={tabKey}
      />
    </>
  )
}

const mapStateToProps = (state: AllState) => ({
  open: state.information.open,
  type: state.information.type,
  tabKey: state.information.tabKey,
  commentProps: state.information.commentProps,
})

export default connect(mapStateToProps)(injectIntl(Information))
