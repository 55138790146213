import React, { useEffect, useMemo, useState } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { DayColor } from '../../../../../../../../styles/commonStyles'
import { Fade } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import {
  GanttDisplayUnit,
  GanttParameterVO,
} from '../../../../../../../../domain/value-object/GanttParameterVO'
import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import { intl } from '../../../../../../../../i18n'

const DAY_OF_WEEK_LABEL_KEYS = [
  'dayOfWeek.short.sunday',
  'dayOfWeek.short.monday',
  'dayOfWeek.short.tuesday',
  'dayOfWeek.short.wednesday',
  'dayOfWeek.short.thursday',
  'dayOfWeek.short.friday',
  'dayOfWeek.short.saturday',
]

type HeaderLabel = {
  date: CalendarDateVO
  label: string
  length: number
}

const GanttHeader = injectIntl(
  (props: IHeaderParams & WrappedComponentProps) => {
    const [fade, setFade] = useState(false)
    useEffect(() => {
      setTimeout(() => setFade(true), 50)
    })

    const timeScale: CalendarDateVO[] = props.context.timeScale ?? []
    const displayTimeScale: CalendarDateVO[] =
      props.context.ganttDisplayTimeScale ?? []
    const parameter: GanttParameterVO = props.context.ganttParameter
    const today = CalendarDateVO.of(new Date())

    const dayOfWeekLabels = useMemo(
      () =>
        DAY_OF_WEEK_LABEL_KEYS.map(key =>
          props.intl.formatMessage({ id: key })
        ),
      [parameter?.unit]
    )

    const monthTimeScale: HeaderLabel[] = useMemo(() => {
      if (!parameter?.unit) return []
      const { unit } = parameter
      if (unit !== GanttDisplayUnit.MONTH) {
        return displayTimeScale.map(group => ({
          date: group,
          label: group.format('D'),
          length: 0,
        }))
      }
      return displayTimeScale.map((group, i) => {
        const daysOfMonth = timeScale.filter(
          date =>
            group.isEqual(date) ||
            (displayTimeScale[i + 1] &&
              date.isBetween(group, displayTimeScale[i + 1]))
        )
        return {
          date: group,
          label:
            group.date.getMonth() + 1 + intl.formatMessage({ id: 'month.ja' }),
          length: daysOfMonth.length,
        }
      })
    }, [timeScale, parameter])

    return (
      <Fade in={fade} timeout={300}>
        <div className="sevend-ag-cell-gantt-header">
          {monthTimeScale.map(
            ({ date, label, length }: HeaderLabel, i: number) => {
              const isToday =
                today.isEqual(date) ||
                (parameter.unit !== GanttDisplayUnit.DAY &&
                  displayTimeScale[i + 1] &&
                  today.isBetween(date, displayTimeScale[i + 1]) &&
                  !today.isEqual(displayTimeScale[i + 1]))
              const isHoliday =
                parameter.unit !== GanttDisplayUnit.MONTH && date.isHoliday()
              const color = isHoliday ? DayColor.HOLIDAY : undefined
              return (
                <div
                  key={`gantt-header-${i}`}
                  className={`sevend-ag-cell-gantt-header__date-header ${
                    isToday
                      ? 'sevend-ag-cell-gantt-header__date-header--today'
                      : ''
                  } ${
                    isHoliday
                      ? 'sevend-ag-cell-gantt-header__date-header--holiday'
                      : ''
                  }`}
                  style={{
                    width:
                      parameter.unit === GanttDisplayUnit.MONTH
                        ? `${(length / timeScale.length) * 100}%`
                        : '100%',
                  }}
                >
                  <span style={{ color }}>{label}</span>
                  {parameter.unit === GanttDisplayUnit.DAY && (
                    <span style={{ color }}>
                      {dayOfWeekLabels[date.getDayOfWeek()]}
                    </span>
                  )}
                </div>
              )
            }
          )}
        </div>
      </Fade>
    )
  }
)

export default GanttHeader
