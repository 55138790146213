import { GridApi } from 'ag-grid-community'
import React from 'react'
import { intl } from '../../../../i18n'
import MultipleToggleButton from '../../../components/editors/toggle/MultipleToggleGroup'
import { QuickFilterKeys } from '../projectPlanOptions'
import DateVO from '../../../../vo/DateVO'
import { WbsItemStatus } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { WbsItemRow } from '../../../../lib/functions/wbsItem'

const QuickFilters = (props: {
  gridApi?: GridApi
  onChangeFilteredUuids: (uuids: string[]) => void
}) => {
  const [quickFilters, setQuickFilters] = React.useState<QuickFilterKeys[]>([])
  const { gridApi, onChangeFilteredUuids } = props
  return (
    <MultipleToggleButton
      values={quickFilters}
      options={[
        {
          value: QuickFilterKeys.START_BY_TODAY,
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.quickFilter.label.startByToday',
          }),
        },
        {
          value: QuickFilterKeys.END_BY_TODAY,
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.quickFilter.label.endByToday',
          }),
        },
        {
          value: QuickFilterKeys.DELAY,
          label: intl.formatMessage({
            id: 'bulksheet.toolbar.quickFilter.label.delay',
          }),
        },
      ]}
      onChange={(values: string[]) => {
        setQuickFilters(values as QuickFilterKeys[])
        if (!gridApi) return
        try {
          if (!values || values.length === 0) {
            onChangeFilteredUuids([])
            return
          }
          const filteredUuids: string[] = []
          gridApi.forEachNode(node => {
            const wbsItem = node.data.wbsItem
            if (
              (!values.includes(QuickFilterKeys.DELAY) || isDelayed(wbsItem)) &&
              (!values.includes(QuickFilterKeys.START_BY_TODAY) ||
                isScheduledToStartByToday(wbsItem)) &&
              (!values.includes(QuickFilterKeys.END_BY_TODAY) ||
                isScheduledToCompleteByToday(wbsItem))
            ) {
              filteredUuids.push(wbsItem.uuid)
            }
          })
          onChangeFilteredUuids(filteredUuids)
        } finally {
          setTimeout(() => gridApi.onFilterChanged(), 300)
        }
      }}
    />
  )
}

const isDelayed = (wbsItem: WbsItemRow) =>
  [WbsItemStatus.TODO, WbsItemStatus.DOING, WbsItemStatus.REVIEW].includes(
    wbsItem.status!
  ) &&
  wbsItem.scheduledDate!.endDate &&
  new DateVO(wbsItem.scheduledDate!.endDate).isBefore(DateVO.now())

const isScheduledToStartByToday = (wbsItem: WbsItemRow) =>
  wbsItem.scheduledDate!.startDate &&
  new DateVO(wbsItem.scheduledDate!.startDate).isSameOrBefore(DateVO.now())

const isScheduledToCompleteByToday = (wbsItem: WbsItemRow) =>
  wbsItem.scheduledDate!.endDate &&
  new DateVO(wbsItem.scheduledDate!.endDate).isSameOrBefore(DateVO.now())

export default QuickFilters
