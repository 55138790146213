import React from 'react'
import FormatBoldIcon from '@mui/icons-material/FormatBoldRounded'
import FormatItalicIcon from '@mui/icons-material/FormatItalicRounded'
import CodeIcon from '@mui/icons-material/Crop75Rounded'
import StrikethroughSIcon from '@mui/icons-material/StrikethroughSRounded'
import QuoteIcon from '@mui/icons-material/AlignHorizontalLeftRounded'
import CodeBlockIcon from '@mui/icons-material/ListAltRounded'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulletedRounded'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumberedRounded'
import HorizontalRuleRoundedIcon from '@mui/icons-material/HorizontalRuleRounded'
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded'
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded'
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded'
import { styled } from '@mui/system'
import { intl } from '../../../../i18n'
import Tooltip from '../../tooltips/Tooltip'
import { fromSnakeToCamel } from '../../../../utils/string'
import { BackgroundColor } from '../../../../styles/commonStyles'

import { StyleType } from '../../../../utils/markdown'

const iconStyle = { height: '18px' }

const StringIconDiv = styled('div')({
  fontSize: '12px',
  fontWeight: 600,
})

const StringIcon = React.forwardRef((props: { label: string }, ref: any) => {
  const { label } = props
  return (
    <StringIconDiv {...props} ref={ref}>
      {label}
    </StringIconDiv>
  )
})

const getIcon = (styleType: StyleType) => {
  switch (styleType) {
    case StyleType.HEADER_ONE:
      return <StringIcon label={'H1'} />
    case StyleType.HEADER_TWO:
      return <StringIcon label={'H2'} />
    case StyleType.HEADER_THREE:
      return <StringIcon label={'H3'} />
    case StyleType.HEADER_FOUR:
      return <StringIcon label={'H4'} />
    case StyleType.HEADER_FIVE:
      return <StringIcon label={'H5'} />
    case StyleType.HEADER_SIX:
      return <StringIcon label={'H6'} />
    case StyleType.BOLD:
      return <FormatBoldIcon style={iconStyle} />
    case StyleType.ITALIC:
      return <FormatItalicIcon style={iconStyle} />
    case StyleType.STRIKETHROUGH:
      return <StrikethroughSIcon style={iconStyle} />
    case StyleType.CODE:
      return <CodeIcon style={iconStyle} />
    case StyleType.BLOCKQUOTE:
      return <QuoteIcon style={iconStyle} />
    case StyleType.CODE_BLOCK:
      return <CodeBlockIcon style={iconStyle} />
    case StyleType.UNORDERED_LIST_ITEM:
      return <FormatListBulletedIcon style={iconStyle} />
    case StyleType.ORDERED_LIST_ITEM:
      return <FormatListNumberedIcon style={iconStyle} />
    case StyleType.HORIZONTAL_RULE:
      return <HorizontalRuleRoundedIcon style={iconStyle} />
    case StyleType.TASK_LIST:
      return <CheckBoxRoundedIcon style={iconStyle} />
    case StyleType.TABLE:
      return <GridOnRoundedIcon style={iconStyle} />
    case StyleType.FILE:
      return <AddPhotoAlternateRoundedIcon style={iconStyle} />
    default:
      return <></>
  }
}

const getTooltipMessage = (styleType: StyleType): string => {
  return intl.formatMessage({
    id: `editor.styleButton.${fromSnakeToCamel(styleType)}`,
  })
}

interface OwnProps {
  styleType: StyleType
  onMouseDown: (event: any) => void
}

export interface StyleButtonBaseProps {
  className?: string
  disabled?: boolean
  highlightItems?: string[]
}

type Props = OwnProps & StyleButtonBaseProps

const StyleButtonDiv = styled('div')<{ highlight?: boolean }>(
  ({ highlight }) => ({
    margin: 'auto 0',
    cursor: 'pointer',
    height: '20px',
    '&:hover': {
      opacity: 0.5,
    },
    backgroundColor: highlight ? BackgroundColor.HIGHLIGHT_BLUE : undefined,
  })
)

const StyleButtonBase = ({
  styleType,
  className,
  highlightItems,
  onMouseDown,
}: Props) => {
  const icon = getIcon(styleType)
  const tooltipMessage = getTooltipMessage(styleType)

  return (
    <StyleButtonDiv
      className={className}
      onMouseDown={onMouseDown}
      highlight={highlightItems?.includes(styleType)}
    >
      <Tooltip message={tooltipMessage}>{icon}</Tooltip>
    </StyleButtonDiv>
  )
}

export default StyleButtonBase
