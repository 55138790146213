import { useCallback } from 'react'
import { useWbsItemRepository } from '../../../../services/adaptors/wbsItemRepositoryAdaptor'
import {
  Attachment,
  AttachmentCandidate,
} from '../../../../domain/value-object/Attachment'

export const useAttachmentHandlers = (uuid: string) => {
  const {
    addAttachments: _addAttachments,
    deleteAttachments: _deleteAttachments,
  } = useWbsItemRepository()
  const addAttachments = useCallback(
    async (attachments: AttachmentCandidate[]) => {
      await _addAttachments({
        wbsItemUuid: uuid,
        attachmentType: 'DELIVERABLE',
        attachments,
      })
    },
    [_addAttachments, uuid]
  )
  const deleteAttachments = useCallback(
    async (attachments: Attachment[]) => {
      await _deleteAttachments({
        wbsItemUuid: uuid,
        attachmentType: 'DELIVERABLE',
        attachmentItemUuids: attachments.map(attachment => attachment.uuid),
      })
    },
    [_deleteAttachments, uuid]
  )
  return {
    addAttachments,
    deleteAttachments,
  }
}
