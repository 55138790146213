import { useCallback, useEffect, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'

type StoredTagsPageState = {
  rowHeight: ROW_HEIGHT
}

export const usePageState = ({ functionUuid }: { functionUuid: string }) => {
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [initialized, setInitialized] = useState(false)

  const storePageState = useCallback(
    async (value: StoredTagsPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    if (!initialized) return
    storePageState({
      rowHeight,
    })
  }, [initialized, storePageState, rowHeight])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const state = JSON.parse(stored.value)
      state.rowHeight && setRowHeight(state.rowHeight)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  useEffect(() => {
    restorePageState()
  }, [restorePageState])

  return {
    initialized,
    rowHeight,
    setRowHeight,
  }
}
