import {
  ColDef,
  FilterChangedEvent,
  GridOptions,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import { useBulkSheetState as useBulkSheetStateBase } from '../../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { useCallback } from 'react'
import _ from 'lodash'

export const useBulkSheetState = (
  gridOptions: GridOptions,
  functionUuid: string,
  uiStateKey: UiStateKey
) => {
  const base = useBulkSheetStateBase(functionUuid, uiStateKey)

  const restoreColumnState = useCallback(() => {
    if (!gridOptions.columnApi || _.isEmpty(base.columnState)) return
    gridOptions.columnApi.applyColumnState({
      state: base.columnState,
      applyOrder: true,
    })
  }, [base.columnState, gridOptions.columnApi])

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && base.saveColumnState(columnState)
  }, [base, gridOptions.columnApi])

  const restoreExpandedRows = useCallback(() => {
    if (_.isEmpty(base.expandedRowIds) || !gridOptions.api) return
    setTimeout(() => {
      base.expandedRowIds.forEach(id => {
        gridOptions.api?.getRowNode(id)?.setExpanded(true)
      })
    }, 200)
  }, [base.expandedRowIds, gridOptions.api])

  const onRowGroupOpended = useCallback(
    (e: RowGroupOpenedEvent) => {
      const key = e.node.key
      if (!key) return
      if (e.expanded) {
        base.expandRows([key])
      } else {
        base.collapseRows([key])
      }
    },
    [base]
  )

  const onFirstDataRendered = useCallback(
    e => {
      restoreExpandedRows()
      restoreColumnState()
      if (!_.isEmpty(base.filterState)) {
        gridOptions.api?.setFilterModel(base.filterState)
      }
      base.finishRestoring()
    },
    [base, gridOptions.api, restoreColumnState, restoreExpandedRows]
  )

  const onGridReady = useCallback(() => {
    restoreColumnState()
  }, [restoreColumnState])

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = e.api.getFilterModel()
      delete filter['uuid']
      base.saveFilterState(filter)
      return Object.keys(filter)
        .map(col => e.api.getColumnDef(col))
        .filter(v => !!v && v.field !== 'uuid') as ColDef[]
    },
    [base]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      const sortedCols = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedCols.length)
      return sortedCols
    },
    [rememberColumnState]
  )

  return {
    restoreColumnState,
    rememberColumnState,
    restoreExpandedRows,
    onRowGroupOpended,
    onFirstDataRendered,
    onGridReady,
    onFilterChanged,
    onSortChanged,
    base,
  }
}
