import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
  TextField,
} from '@mui/material'
import { ChangeEvent, PropsWithChildren, useCallback } from 'react'
import { intl } from '../../../../../i18n'
import {
  toUiStateScope,
  UiStateScope,
} from '../../../../../services/model/uiState'
import {
  CrossProjectScopeIcon,
  TenantScopeIcon,
  UserScopeIcon,
} from '../../../icons/UiStateScopeIcon'
import { connect } from 'react-redux'
import { AllState } from '../../../../../store'

type StoredUiStateEditorDialogProps = PropsWithChildren<{
  open: boolean
  onClose: () => void
}>

export const StoredUiStateEditorDialog = ({
  open,
  onClose,
  children,
}: StoredUiStateEditorDialogProps) => (
  <Dialog maxWidth="lg" open={open} onClose={onClose}>
    {children}
  </Dialog>
)

export const StoredUiStateEditorDialogTitle = DialogTitle

export const StoredUiStateEditorDialogContent = styled(DialogContent)({
  width: '700px',
})

export const StoredUiStateEditorDialogActions = DialogActions

export const StoredUiStateEditorFormLabel = styled('p')({
  margin: '5px 0 3px 0',
})

type StoredUiStateEditorNameFormProps = {
  name: string
  onChange: (newValue: string) => void
}

export const StoredUiStateEditorNameForm = ({
  name,
  onChange,
}: StoredUiStateEditorNameFormProps) => {
  const onChangeValue = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <>
      <StoredUiStateEditorFormLabel>
        {intl.formatMessage({
          id: 'template.name',
        })}
      </StoredUiStateEditorFormLabel>
      <TextField
        id="name"
        variant="outlined"
        required={true}
        fullWidth={true}
        value={name}
        onChange={onChangeValue}
        size="small"
        autoFocus={true}
      />
    </>
  )
}

const UiStateScopeSelect = ({
  children,
  ...other
}: SelectProps<UiStateScope>) => (
  <Select<UiStateScope> {...other}>{children}</Select>
)

const StoredUiStateEditorScopeSelect = styled(UiStateScopeSelect)({
  height: '30px',
  width: '100%',
})

const StoredUiStateEditorScopeSelectOption = styled('p')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
})

type StoredUiStateEditorScopeFormProps = {
  scope: UiStateScope
  onChange: (newValue: UiStateScope) => void
  hasAllPrivileges?: boolean
}

const mapStateToProps = (state: AllState) => ({
  hasAllPrivileges: state.user.user?.hasAllPrivileges,
})

export const StoredUiStateEditorScopeForm = connect(mapStateToProps)(
  ({
    scope,
    onChange,
    hasAllPrivileges,
  }: StoredUiStateEditorScopeFormProps) => {
    const onChangeValue = useCallback(
      (event: SelectChangeEvent<UiStateScope>) => {
        const value = toUiStateScope(event.target.value) || 'USER'
        onChange(value)
      },
      [onChange]
    )

    return (
      <>
        <StoredUiStateEditorFormLabel>
          {intl.formatMessage({
            id: 'savedUIState.scope',
          })}
        </StoredUiStateEditorFormLabel>
        <StoredUiStateEditorScopeSelect
          value={scope}
          onChange={onChangeValue}
          variant="outlined"
        >
          {hasAllPrivileges && (
            <MenuItem value={'CROSS_PROJECT'}>
              <StoredUiStateEditorScopeSelectOption>
                <CrossProjectScopeIcon color={'action'} />
                {intl.formatMessage({
                  id: 'uiState.scope.crossProject',
                })}
              </StoredUiStateEditorScopeSelectOption>
            </MenuItem>
          )}
          <MenuItem value={'TENANT'}>
            <StoredUiStateEditorScopeSelectOption>
              <TenantScopeIcon color={'action'} />
              {intl.formatMessage({
                id: 'uiState.scope.tenant',
              })}
            </StoredUiStateEditorScopeSelectOption>
          </MenuItem>
          <MenuItem value={'USER'}>
            <StoredUiStateEditorScopeSelectOption>
              <UserScopeIcon color={'action'} />
              {intl.formatMessage({
                id: 'uiState.scope.user',
              })}
            </StoredUiStateEditorScopeSelectOption>
          </MenuItem>
        </StoredUiStateEditorScopeSelect>
      </>
    )
  }
)
