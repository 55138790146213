import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { PageProps } from '..'
import { useProjectPrivateContext } from '../../context/projectContext'
import { useCallback, useEffect, useState } from 'react'
import Auth from '../../../lib/commons/auth'
import UnitPricePerProjectPartnersView from './view'
import { intl } from '../../../i18n'
import { showAlert } from '../../../store/globalAlert'
import { useDispatch } from 'react-redux'

type Props = PageProps

const UnitPricePerProjectPartners = (props: Props) => {
  const { project } = useProjectPrivateContext()
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  const [certificated, setCertificated] = useState(false)

  const checkAuth = useCallback(async () => {
    try {
      const result = await Auth.checkAccountingAuth(
        project.uuid,
        props.externalId
      )
      setCertificated(result)
      if (!result) {
        dispatch(
          showAlert({
            message: intl.formatMessage({
              id: 'global.warning.unauthorized.function',
            }),
          })
        )
      }
    } finally {
      setInitialized(true)
    }
  }, [project.uuid, props.externalId])

  useEffect(() => {
    if (!initialized) {
      checkAuth()
    }
  }, [checkAuth, initialized])

  if (!certificated) return <></>
  return (
    <UnitPricePerProjectPartnersView {...props} projectUuid={project.uuid} />
  )
}

export default projectPrivate(UnitPricePerProjectPartners)
