import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Popper,
  styled,
  Typography,
} from '@mui/material'
import store, { AllState } from '../../../../store'
import { open } from '../../../router'
import { intl } from '../../../../i18n'
import {
  BackgroundColor,
  BorderColor,
  Color,
  FontSize,
  TextColor,
} from '../../../../styles/commonStyles'
import { ProjectDetail } from '../../../../lib/functions/project'
import { connect, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { changeProject } from '../../../../store/project'
import AlertDialog from '../../../components/dialogs/AlertDialog'
import { doNotRequireSave } from '../../../../store/requiredSaveData'
import Button from '@mui/material/Button'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { colorPalette } from '../../../style/colorPallete'

type Props = {
  currentProject?: ProjectDetail
  assigned: ProjectDetail[]
  hasRequiredSaveData?: boolean
  openMenu: boolean
  anchorEl: any
  onChange?: (projectUuid?: string | undefined) => void | Promise<void>
  isNewsProjectFilter?: boolean
  selectedProjectUuid?: string | undefined
}

const ProjectMenu = ({
  hasRequiredSaveData,
  openMenu,
  anchorEl,
  onChange,
  isNewsProjectFilter,
  selectedProjectUuid,
}: Props) => {
  const [attemptingProjectUuid, setAttemptingProjectUuid] = useState<
    string | undefined
  >()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const [assigned, setAssigned] = useState<ProjectDetail[]>([])
  const { assignedProjects, currentProject } = useSelector(
    (state: AllState) => ({
      assignedProjects: state.project.assigned,
      currentProject: state.project.current,
    })
  )
  useEffect(() => {
    // If currentProject is not included in assignedProject, it means that the user has administrator priviledge.
    // In that case, ProjectMenu should display currentProject within the list of the projects.
    if (
      currentProject &&
      assignedProjects.every(p => p.uuid !== currentProject.uuid)
    ) {
      // Administrator logged in
      currentProject.assigned = false // TODO Implemented it on the server side
      setAssigned([currentProject, ...assignedProjects])
      return
    }
    setAssigned(assignedProjects)
  }, [assignedProjects, currentProject])

  const onSelect = useCallback(
    (projectUuid: string | undefined) => {
      if (!projectUuid) {
        return
      }
      if (hasRequiredSaveData) {
        setAttemptingProjectUuid(projectUuid)
        setDialogOpen(true)
        return
      }
      store.dispatch(changeProject(projectUuid))
    },
    [hasRequiredSaveData]
  )

  const onSubmit = useCallback(() => {
    store.dispatch(doNotRequireSave())
    if (attemptingProjectUuid) {
      store.dispatch(changeProject(attemptingProjectUuid))
    }
    setDialogOpen(false)
  }, [attemptingProjectUuid])

  return (
    <Box>
      <Popper
        open={openMenu}
        anchorEl={anchorEl}
        placement={isNewsProjectFilter ? 'bottom-end' : 'right-start'}
        modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]}
        sx={{
          paddingBottom: '0px',
          backgroundColor: BackgroundColor.WHITE,
          boxShadow:
            'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
          borderRadius: '4px',
          zIndex: 1300,
          display: 'flex',
        }}
      >
        <List sx={{ padding: '2px 10px' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              margin: '7px 0',
              padding: '0px 8px',
              border: '0px 0px 1px 0px',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: colorPalette.monotone[10],
                fontWeight: 500,
                fontSize: '15px',
                margin: '3px',
              }}
            >
              {intl.formatMessage({ id: 'projectSelect.empty' })}
            </Typography>
            {!isNewsProjectFilter && (
              <Button
                onClick={event => {
                  open('/projects', event)
                }}
                sx={{
                  border: `1px solid ${colorPalette.monotone[2]}`,
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: colorPalette.skyBlue[0],
                    border: `1px solid ${colorPalette.skyBlue[7]}`,
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: Color.MONOTONE,
                    fontWeight: 500,
                    fontSize: FontSize.MEDIUM,
                    margin: '0 10px',
                    '&:hover': {
                      color: Color.FLAGXS_BLUE,
                    },
                  }}
                >
                  {intl.formatMessage({ id: 'menu.searchProjects' })}
                </Typography>
              </Button>
            )}
            {isNewsProjectFilter && (
              <Button
                sx={{
                  color: Color.MONOTONE,
                  '&:hover': { background: 'transparent' },
                }}
                onClick={() => onChange!(undefined)}
              >
                <ClearRoundedIcon
                  sx={{ height: '14px', width: '14px', marginRight: '5px' }}
                />
                <Typography sx={{ fontSize: '12px' }}>
                  {intl.formatMessage({ id: 'reset' })}
                </Typography>
              </Button>
            )}
          </Box>
          <ProjectMenuDivider />
          <Box sx={{ overflow: 'scroll', maxHeight: '400px' }}>
            {isNewsProjectFilter && (
              <>
                {assigned.map((p, i) => (
                  <ProjectMenuListItemButton
                    key={i}
                    onClick={() => onChange!(p.uuid)}
                    sx={
                      selectedProjectUuid && selectedProjectUuid === p.uuid
                        ? () => ({
                            backgroundColor: colorPalette.monotone[1],
                            '&:hover': {
                              backgroundColor: BackgroundColor.FOCUS_BULE,
                              color: TextColor.FOCUS_BULE,
                            },
                          })
                        : () => ({
                            '&:hover': {
                              backgroundColor: BackgroundColor.FOCUS_BULE,
                              color: TextColor.FOCUS_BULE,
                            },
                          })
                    }
                  >
                    <Avatar
                      variant="rounded"
                      src={p.iconUrl}
                      sx={{
                        width: 24,
                        height: 24,
                        border: `1px solid ${colorPalette.monotone[2]}`,
                      }}
                    />
                    <ListItemText
                      primary={p.displayName}
                      sx={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        width: '400px',
                      }}
                    />
                  </ProjectMenuListItemButton>
                ))}
              </>
            )}
            {!isNewsProjectFilter && (
              <>
                {assigned.map((p, i) => (
                  <ProjectMenuListItemButton
                    key={i}
                    onClick={() => onSelect(p.uuid)}
                    sx={
                      currentProject?.uuid === p.uuid
                        ? () => ({
                            backgroundColor: colorPalette.monotone[1],
                            '&:hover': {
                              backgroundColor: BackgroundColor.FOCUS_BULE,
                              color: TextColor.FOCUS_BULE,
                            },
                          })
                        : () => ({
                            '&:hover': {
                              backgroundColor: BackgroundColor.FOCUS_BULE,
                              color: TextColor.FOCUS_BULE,
                            },
                          })
                    }
                  >
                    <Avatar
                      variant="rounded"
                      src={p.iconUrl}
                      sx={{
                        width: 24,
                        height: 24,
                        border: `1px solid ${colorPalette.monotone[2]}`,
                      }}
                    />
                    <ListItemText
                      primary={p.displayName}
                      sx={{
                        marginLeft: '10px',
                        marginRight: '10px',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        width: '400px',
                      }}
                    />
                  </ProjectMenuListItemButton>
                ))}
              </>
            )}
          </Box>
        </List>
      </Popper>
      <AlertDialog
        isOpen={dialogOpen}
        title={intl.formatMessage({ id: 'project.change.confirm.title' })}
        message={intl.formatMessage({ id: 'project.change.confirm.message' })}
        submitButtonTitle={intl.formatMessage({ id: 'yes' })}
        submitHandler={onSubmit}
        closeButtonTitle={intl.formatMessage({ id: 'no' })}
        closeHandler={() => setDialogOpen(false)}
      />
    </Box>
  )
}

const ProjectMenuListItemButton = styled(ListItemButton)({
  padding: '2px 0px',
  color: TextColor.BLACK,
})

const ProjectMenuDivider = styled(Divider)({
  borderTop: `1px solid ${BorderColor.GREY}`,
  background: 'transparent',
  margin: '5px 0',
})

const mapStateToProject = (state: AllState) => ({
  currentProject: state.project.current,
  assigned: state.project.assigned,
  hasRequiredSaveData: state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProject)(ProjectMenu)
