import React from 'react'
import { intl } from '../../../../i18n'
import TimelineIcon from '@mui/icons-material/TimelineRounded'
import { IconButtonProps } from '@mui/material'
import {
  BlueActionIconButton,
  blueActionIconStyle,
} from '../../icons/IconButton/BlueActionIconButton'
import { DateTerm } from '../../../../utils/date'
import {
  dateTermToViewConfig,
  openProgressReport,
} from '../../../pages/ProgressReport'

const ProgressReportIconButton = (
  props: {
    projectUuid: string
    rootWbsItemUuid?: string
    ticketListUuid?: string
    scheduledDate?: DateTerm
  } & IconButtonProps
) => {
  const {
    projectUuid,
    rootWbsItemUuid,
    ticketListUuid,
    scheduledDate,
    ...other
  } = props
  const openProgressReportInNewTab = (
    projectUuid: string,
    rootWbsItemUuid?: string,
    ticketListUuid?: string,
    scheduledDate?: DateTerm
  ) => {
    if (!rootWbsItemUuid && !ticketListUuid) {
      openProgressReport(projectUuid, {}, {})
      return
    }
    const searchCondition = rootWbsItemUuid
      ? {
          rootWbsItemUuid: rootWbsItemUuid,
        }
      : {
          ticketListUuid: ticketListUuid,
        }
    const viewConfig = dateTermToViewConfig(scheduledDate)
    openProgressReport(projectUuid, searchCondition, viewConfig)
  }

  return (
    <BlueActionIconButton
      {...other}
      title={intl.formatMessage({
        id: 'report.showProgressReport',
      })}
      onClick={() =>
        openProgressReportInNewTab(
          projectUuid,
          rootWbsItemUuid,
          ticketListUuid,
          scheduledDate
        )
      }
    >
      <TimelineIcon style={blueActionIconStyle} />
    </BlueActionIconButton>
  )
}

export default ProgressReportIconButton
