import * as d3 from 'd3'
import { useEffect, useMemo, useState } from 'react'

export type DateTickIntervalType =
  | 'DAY'
  | 'WEEK'
  | 'MONTH'
  | 'THREE_MONTH'
  | 'HALF_YEAR'
  | 'YEAR'

export const useDateXTicks = (
  from: Date,
  to: Date,
  width: number,
  minTickSize: number
): { xTicks: Date[]; intervalType: DateTickIntervalType } => {
  const maxTickIntervalCount = Math.floor(width / minTickSize)
  const [intervalType, setIntervalType] = useState<DateTickIntervalType>('DAY')

  useEffect(() => {
    setIntervalType(prev => {
      const newType = calculateIntervalType(from, to, maxTickIntervalCount)
      if (prev === newType) return prev
      return newType
    })
  }, [from, to, maxTickIntervalCount])

  const xTicks = useMemo(() => {
    return calculateDateTicks(from, to, intervalType)
  }, [from, to, intervalType])

  return { xTicks, intervalType }
}

const calculateIntervalType = (
  from: Date,
  to: Date,
  maxCount: number
): DateTickIntervalType => {
  const offsetTo = d3.timeDay.offset(to, 1)
  if (d3.timeDay.count(from, offsetTo) <= maxCount) {
    return 'DAY'
  }
  const weekFrom = d3.timeMonday.floor(from)
  const weekTo = d3.timeMonday.ceil(offsetTo)
  if (d3.timeMonday.count(weekFrom, weekTo) <= maxCount) {
    return 'WEEK'
  }
  const monthFrom = d3.timeMonth.floor(from)
  const monthTo = d3.timeMonth.ceil(to)
  if (d3.timeMonth.count(monthFrom, monthTo) <= maxCount) {
    return 'MONTH'
  }
  const threeMonthFrom = d3.timeMonth.offset(
    monthFrom,
    -1 * (monthFrom.getMonth() % 3)
  )
  const threeMonthTo = monthTo
  if (
    Math.ceil(d3.timeMonth.count(threeMonthFrom, threeMonthTo) / 3) <= maxCount
  ) {
    return 'THREE_MONTH'
  }
  const sixMonthFrom = d3.timeMonth.offset(
    monthFrom,
    -1 * (monthFrom.getMonth() % 6)
  )
  const sixMonthTo = monthTo
  if (Math.ceil(d3.timeMonth.count(sixMonthFrom, sixMonthTo) / 6) <= maxCount) {
    return 'HALF_YEAR'
  }
  return 'YEAR'
}

const calculateDateTicks = (
  from: Date,
  to: Date,
  intervalType: DateTickIntervalType
): Date[] => {
  const offsetTo = d3.timeDay.offset(to, 1)
  const monthFrom = d3.timeMonth.floor(from)
  const monthTo = d3.timeMonth.ceil(to)
  switch (intervalType) {
    case 'DAY':
      return d3.timeDay.range(from, offsetTo)
    case 'WEEK':
      const weekFrom = d3.timeMonday.floor(from)
      const weekTo = d3.timeMonday.ceil(offsetTo)
      return d3.timeMonday.range(weekFrom, weekTo)
    case 'MONTH':
      return d3.timeMonth.range(monthFrom, monthTo)
    case 'THREE_MONTH':
      const threeMonthFrom = d3.timeMonth.offset(
        monthFrom,
        -1 * (monthFrom.getMonth() % 3)
      )
      const threeMonthTo = monthTo
      return d3.timeMonth.range(threeMonthFrom, threeMonthTo, 3)
    case 'HALF_YEAR':
      const sixMonthFrom = d3.timeMonth.offset(
        monthFrom,
        -1 * (monthFrom.getMonth() % 6)
      )
      const sixMonthTo = monthTo
      return d3.timeMonth.range(sixMonthFrom, sixMonthTo, 6)
    case 'YEAR':
      const yearFrom = d3.timeYear.floor(from)
      const yearTo = d3.timeYear.ceil(to)
      return d3.timeYear.range(yearFrom, yearTo)
  }
}
