import React from 'react'
import {
  addImageText,
  createTableStyle,
  deleteTableStyle,
  StyleType,
} from '../../../../../utils/markdown'
import MarkdownStyleButton from './MarkdownStyleButton'
import TableStyleButtonBase from '../../common/TableStyleButtonBase'
import { StyleButtonBaseProps } from '../../common/StyleButtonBase'
import { UploadedFile } from '../../../../../utils/file'
import FileSelectStyleButtonBase from '../../common/FileSelectStyleButtonBase'

interface OwnProps {
  markdownText?: string
  setMarkdownText?: (text: string) => void
  selectionStart?: number
  selectionEnd?: number
}

type Props = OwnProps & StyleButtonBaseProps

export const HeaderOneButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_ONE} />
)

export const HeaderTwoButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_TWO} />
)

export const HeaderThreeButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_THREE} />
)

export const HeaderFourButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_FOUR} />
)

export const HeaderFiveButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_FIVE} />
)

export const HeaderSixButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HEADER_SIX} />
)

export const BoldButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.BOLD} />
)

export const ItalicButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.ITALIC} />
)

export const StrikethroughButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.STRIKETHROUGH} />
)

export const CodeButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.CODE} />
)

export const UnorderedListItemButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.UNORDERED_LIST_ITEM} />
)

export const OrderedListItemButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.ORDERED_LIST_ITEM} />
)

export const CodeBlockButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.CODE_BLOCK} />
)

export const BlockquoteButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.BLOCKQUOTE} />
)

export const HorizontalRuleButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.HORIZONTAL_RULE} />
)

export const TaskListButton = (props: Props) => (
  <MarkdownStyleButton {...props} styleType={StyleType.TASK_LIST} />
)

export const TableButton = (props: Props) => {
  const { selectionStart, setMarkdownText, markdownText } = props
  const setTable = React.useCallback(
    (rows: number, columns: number) => {
      if (!rows || !columns || !setMarkdownText) {
        return
      }
      const text = markdownText || ''
      const position = selectionStart || 0
      setMarkdownText(createTableStyle(text, position, rows, columns))
    },
    [selectionStart, markdownText]
  )
  const deleteTable = React.useCallback(() => {
    if (!setMarkdownText) return
    const text = markdownText || ''
    const position = selectionStart || 0
    setMarkdownText(deleteTableStyle(text, position))
  }, [selectionStart, markdownText])
  return (
    <TableStyleButtonBase
      {...props}
      setTableValue={setTable}
      deleteTable={deleteTable}
    />
  )
}

export const FileButton = (props: Props) => {
  const { selectionStart, setMarkdownText, markdownText } = props
  const onChangeInput = React.useCallback(
    (files: UploadedFile[]) => {
      if (!setMarkdownText) return
      const position = selectionStart || -1
      const text = markdownText || ''
      addImageText(files, position, text, setMarkdownText)
    },
    [selectionStart, markdownText]
  )
  return (
    <FileSelectStyleButtonBase
      {...props}
      multiple={true}
      onChange={onChangeInput}
    />
  )
}
