import { useCallback, useMemo, useState } from 'react'
import { TeamDetail } from '../../../lib/functions/team'
import TeamsOptions, { ColumnQuickFilterKey, TeamRow } from './TeamsOptions'
import BulkSheetComponent, {
  BulkSheetState,
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import { PageArea, PageProps } from '..'
import TeamsHeader from './Header'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ColDef } from 'ag-grid-community'
import { intl } from '../../../i18n'
import { Collapse } from '@mui/material'
import TeamsToolbar from './Toolbar'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { usePageState } from '../../hooks/usePageState'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { pageComponent } from '../../higher-order-components/pageComponent'

type TeamsPageState = {
  toolbar: ToolbarToggleValue | undefined
}

const teamsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
}

const Teams = (props: PageProps) => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<BulkSheetSpecificProps, TeamDetail, TeamRow, BulkSheetState>
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)

  const teamOptions = useMemo(() => new TeamsOptions(), [])

  const { toolbar, updatePageState } = usePageState<TeamsPageState>(
    props.uuid,
    teamsDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onClickAddUser = useCallback(() => {
    bulkSheet?.setState({
      addRowCountInputDialogState: {
        open: true,
        title: intl.formatMessage({
          id: 'project.member.insert.multipleRow.title',
        }),
        submitHandler: addRowCount => {
          if (!addRowCount) {
            throw new Error('addRowCount is 0 or undefined.')
          }
          Array.from({ length: addRowCount }).forEach(value =>
            bulkSheet.addRow()
          )
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
        closeHandler: () => {
          bulkSheet.setState({
            addRowCountInputDialogState: { open: false },
          })
        },
      },
    })
  }, [bulkSheet])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  return (
    <PageArea>
      <TeamsHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onCancel={() => bulkSheet?.onCancel()}
        onClickAddUser={onClickAddUser}
        rowHeight={rowHeight}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <TeamsToolbar
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={teamOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

export default pageComponent(projectPrivate(Teams))
