import { ColDef } from 'ag-grid-community'
import { WbsItemAdditionalPropertyEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import { ColumnType } from '../../../../commons/AgGrid'
import { DateCellEditor } from '../../../components/cellEditor'
import { DateFilter, ServerSideDateFilter } from '../../../components/filter'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'

export const dateColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  column.type = [ColumnType.date]
  column.width = 120
  column.cellEditor = DateCellEditor
  setFilterParams(column, wbsItemAdditionalProperty, option)
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = 'dateCellFilter'
      column.floatingFilter = !option.disableFloatingFilter
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideDateFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.filterParams = {
        fetch: (v: DateFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                operator: v.operator,
                value: v.value,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
