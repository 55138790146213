import { useCallback } from 'react'
import { intl } from '../../../../../i18n'
import { useTeamRepository } from '../../../../../services/adaptors/teamRepositoryAdaptor'
import { AutocompleteConditionItemLabel } from '../AutocompleteConditionItemLabel'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { TeamAutocompleteConditionProps } from '../../search-components/wbs-item-search-components/TeamAutocompleteCondition'

type TeamAutocompleteConditionItemLabelProps = Omit<
  TeamAutocompleteConditionProps,
  'colSpan' | 'onEnter'
> & {
  search: () => void
  onClose?: () => void
}

export const TeamAutocompleteConditionItemLabel = ({
  value,
  onChange,
  onClose,
  ...other
}: TeamAutocompleteConditionItemLabelProps) => {
  const { projectUuid } = useProjectPrivateContext()
  const teamRepository = useTeamRepository()

  const fetchOptions = useCallback(async () => {
    if (!projectUuid) return []
    const teams = await teamRepository.fetchList(projectUuid)
    return teams.map(team => ({
      value: team.uuid,
      name: team.displayName,
      iconUrl: team.iconUrl,
    }))
  }, [projectUuid, teamRepository])
  const fetchSelectedOption = useCallback(
    async (value: string) => {
      try {
        const team = await teamRepository.fetch(value)
        return team
          ? {
              value: team.uuid,
              name: team.displayName,
              iconUrl: team.iconUrl,
            }
          : undefined
      } catch (err) {
        return undefined
      }
    },
    [teamRepository]
  )

  return (
    <AutocompleteConditionItemLabel
      {...other}
      header={intl.formatMessage({
        id: 'wbsItemSearchFilterPanel.team',
      })}
      value={value}
      onChange={onChange}
      onClose={onClose}
      options={[]}
      fetchOptions={fetchOptions}
      fetchSelectedOption={fetchSelectedOption}
    />
  )
}
