import React from 'react'
import { InputBase } from '@mui/material'
import { styled } from '@mui/system'
import DatePicker from 'react-datepicker'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import DateTimeVO from '../../../../../../vo/DateTimeVO'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
})
const StyledInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
})

interface DateTimeCellProps extends CellPropsBase<DateTimeVO> {}

interface DateTimeCellState extends CellStateBase {}

class DateTimeCell extends DataCellBase<
  DateTimeVO,
  DateTimeCellProps,
  DateTimeCellState
> {
  validate = (value?: DateTimeVO) => {
    return undefined
  }

  onChange = selectedValue => {
    this.props.onChange(
      selectedValue ? new DateTimeVO(selectedValue) : undefined
    )
  }

  render() {
    const { value, cellDef } = this.props
    return (
      <RootDiv>
        <DatePicker
          selected={value ? value.getValue() : undefined}
          dateFormat={'yyyy/MM/dd HH:mm:ss'}
          onChange={this.onChange}
          placeholderText={cellDef.placeholder || 'YYYY/MM/DD HH:mm:ss'}
          customInput={<StyledInput />}
          popperPlacement="bottom-end"
          disabled={!cellDef.editable}
          showTimeInput={true}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.stopPropagation()
              e.currentTarget.blur()
            }
          }}
        />
      </RootDiv>
    )
  }
}

export default DateTimeCell
