import { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../../store'
import ReloadButton from '../../../components/buttons/ReloadButton'
import SubmitButton from '../../../components/buttons/SubmitButton'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import { useDialogState } from '../../../components/dialogs/useDialogState'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  onSubmit: () => void
  submitDisabled: boolean
  isLoading: boolean
  onCancel: () => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps & SettingSpeedDialProps

const IdProvidersHeader = ({
  toolbar,
  onChangeToolbar,
  isNotice,
  onSubmit,
  submitDisabled,
  isLoading,
  onCancel,
  notEdited,
  onClickExport,
  rowHeight,
  onClickChangeRowHeight,
}: Props) => {
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()
  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <SubmitButton
            onClick={onSubmit}
            disabled={submitDisabled || isLoading || notEdited}
            notEdited={notEdited}
          />
          <ReloadButton onClick={openCancelDialog} disabled={isLoading} />
          <CancelConfirmDialog
            open={showCancelDialog}
            onConfirm={() => {
              closeCancelDialog()
              onCancel()
            }}
            onClose={closeCancelDialog}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={onClickExport}
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
      </HeaderContent>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(IdProvidersHeader)
