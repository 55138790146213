import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { TicketListBasic } from '../../../lib/functions/ticketList'
import { WbsItemBasic } from '../../../lib/functions/wbsItem'
import Workload from '../../../lib/functions/workload'
import { CommentSummary } from '../../../store/comments'
import { AttachmentSummary } from '../../../utils/attachment'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { EntityExtensionValue } from '../../containers/meta/entityExtension'
import { NewWbsItemRow } from '../ProjectPlanNew/projectPlanNew'

export const MULTI_PROPERTY_DELIMITER: string = ','

/**
 * RowData definition
 */
// Add estimatedWorkload to use the TicketsReport component.
// TODO: Should TicketsReport support NewWbsItemRow?
export class TicketWbsItemRow extends NewWbsItemRow {
  estimatedWorkload?: Workload
}

export interface TicketRow extends TreeRow {
  isTotal?: boolean
  uuid: string
  lockVersion: number

  parentUuid?: string
  prevSiblingUuid?: string

  ticketList?: TicketListBasic

  parentWbsItem?: WbsItemBasic
  wbsItem?: TicketWbsItemRow
  cumulation?: ProjectPlanCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary

  extensions?: EntityExtensionValue[]
  parentPath?: string
}
