import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  SortChangedEvent,
} from 'ag-grid-community'
import { usePageState } from './hooks/pageState'
import { useBulkSheetState } from './hooks/bulkSheetState'
import {
  isUnitPriceColumn,
  refreshDynamicColumnDef,
  unitPricePerUsersGridOptions,
} from './gridOptions'
import { useUnitPricePerUsersData } from './hooks/unitPricePerUsersData'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { UnitPricePerUserRow } from './UnitPricePerUsers'
import { DateTerm } from '../../../utils/date'
import store from '../../../store'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import { getRowNumber } from '../../containers/BulkSheetView/lib/gridApi'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import UnitPricePerUsersHeader, {
  ColumnQuickFilterKey,
} from './components/Header'
import { BulkSheetView } from '../../containers/BulkSheetView'
import Loading from '../../components/process-state-notifications/Loading'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { exportExcel } from '../../containers/BulkSheet/excel'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { dateVoService } from '../../../domain/value-object/DateVO'

const UnitPricePerUsers = (props: PageProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openUiState, setOpenUiState] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const columnSetting = useColumnSetting()
  const pageState = usePageState(props.uuid)
  const gridOptions = useMemo(() => {
    return unitPricePerUsersGridOptions()
  }, [])
  const { data, refresh, save } = useUnitPricePerUsersData()
  const bulkSheetState = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.UnitPricePerUsers
  )

  const prevData = useRef<UnitPricePerUserRow[]>([])
  prevData.current = data
  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.edited)
  }, [data])

  const refreshAll = useCallback(async () => {
    try {
      setLoading(true)
      await refresh()
    } finally {
      setLoading(false)
    }
  }, [refresh])

  const onChangeDateTerm = useCallback(
    (term: DateTerm) => {
      if (!!term.startDate) {
        pageState.setStartDate(term.startDate)
      }
      if (!!term.endDate) {
        pageState.setEndDate(term.endDate)
      }
      const states = gridOptions.columnApi
        ?.getColumns()
        ?.filter(v => {
          if (v.isVisible()) return false
          const parent = v.getParent()
          if (!parent || !isUnitPriceColumn(parent.getGroupId())) return false
          return dateVoService.isBetween(
            dateVoService.construct(v.getColId()),
            dateVoService.construct(term.startDate),
            dateVoService.construct(term.endDate),
            '[]'
          )
        })
        .map(v => ({
          colId: v.getColId(),
          hide: false,
        }))
      gridOptions.columnApi?.applyColumnState({ state: states })
    },
    [gridOptions.columnApi, pageState]
  )

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      gridOptions.api?.stopEditing()
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }
      const response = await save()
      if (!response.hasError && !response.hasWarning) {
        store.dispatch(
          addScreenMessage(props.uuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refreshAll()
      }
    } finally {
      setLoading(false)
    }
  }, [
    gridOptions.api,
    gridOptions.context.errors,
    props.uuid,
    refreshAll,
    save,
  ])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      setOpenCancel(true)
    } else {
      refreshAll()
    }
  }, [hasChanged, refreshAll])

  const onFirstDataRendered = useCallback(
    e => {
      bulkSheetState.onFirstDataRendered(e)
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onExportExcel = useCallback(() => {
    const colIds = gridOptions.columnApi?.getColumns() ?? []
    if (!colIds || colIds.length === 0) return
    exportExcel({
      fileNamePrefix: 'unit_price_per_users',
      gridOptions,
      exportColIds: colIds.map(v => v.getColId()),
    })
  }, [gridOptions])

  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    return []
  }, [])

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [gridOptions.api])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions.columnApi]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions.columnApi])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions.columnApi]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = bulkSheetState.onFilterChanged(e)
      setFilteredColumns(filter)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      const sorted = bulkSheetState.onSortChanged(e)
      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedState[state.colId] = state
          }
        })
      const sortedList: SortedColumnState[] = sorted.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedState[col.colId]?.sort : null,
        }
      })
      setSortColumnsState(sortedList)
    },
    [bulkSheetState, gridOptions.columnApi]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else {
        setOpenUiState(true)
      }
    },
    [gridOptions.api, gridOptions.columnApi]
  )

  const callbackSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      if (columnState) {
        bulkSheetState.base.saveColumnState(columnState)
        gridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        bulkSheetState.base.saveFilterState(filterState)
        gridOptions.api?.setFilterModel(filterState)
      }
      setOpenUiState(false)
    },
    [bulkSheetState.base, gridOptions.api, gridOptions.columnApi]
  )

  const onCancelConfirm = useCallback(() => {
    setOpenCancel(false)
    refreshAll()
  }, [refreshAll])

  useEffect(() => {
    if (pageState.initialized) {
      refreshAll()
      refreshDynamicColumnDef({
        api: gridOptions.api,
        dateTerm: {
          startDate: pageState.startDate,
          endDate: pageState.endDate,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.initialized])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  useEffect(() => {
    refreshDynamicColumnDef({
      api: gridOptions.api,
      dateTerm: {
        startDate: pageState.startDate,
        endDate: pageState.endDate,
      },
    })
  }, [gridOptions.api, pageState.endDate, pageState.startDate])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    [data, save, pageState]
  )

  return (
    <PageArea>
      <UnitPricePerUsersHeader
        loading={loading}
        dateTerm={{
          startDate: pageState.startDate,
          endDate: pageState.endDate,
        }}
        rowHeight={pageState.rowHeight}
        onSearch={onChangeDateTerm}
        onSubmit={onSubmit}
        onReload={onReload}
        onClickExport={onExportExcel}
        onChangeRowHeight={pageState.setRowHeight}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        sortColumnState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        columnSettingOpen={columnSetting.isOpen}
        onClickColumnSettingButton={columnSetting.toggle}
      />
      <BulkSheetView
        ref={ref}
        rowData={data}
        gridOptions={gridOptions}
        getContextMenuItems={contextMenu}
        rowHeight={pageState.rowHeight}
        onRowDataUpdated={bulkSheetState.onRowDataUpdated}
        onColumnResized={bulkSheetState.rememberColumnState}
        onColumnVisible={bulkSheetState.rememberColumnState}
        onGridReady={bulkSheetState.onGridReady}
        onRowGroupOpened={bulkSheetState.onRowGroupOpened}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <CancelConfirmDialog
        open={openCancel}
        onClose={() => setOpenCancel(false)}
        onConfirm={onCancelConfirm}
      />
      <SavedUIStateDialog
        applicationFunctionUuid={props.uuid}
        open={openUiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.base.columnState,
          filterState: bulkSheetState.base.filterState,
        }}
        onSelect={callbackSelectUiState}
        onClose={() => setOpenUiState(false)}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() => setOpenUiState(true)}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={props.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.base.columnState}
        offset={90}
      />
    </PageArea>
  )
}

export default UnitPricePerUsers
