import { useCallback, useEffect, useState } from 'react'
import { DateTerm } from '../../../../utils/date'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import DateVO from '../../../../vo/DateVO'
import { SearchUnit } from '../../../components/headers/HeaderBar/DateRangeSelector'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'

type StoredActualWorkingHoursPageState = {
  searchUnit: SearchUnit
  dateTerm: DateTerm
  rowHeight: number
}

export const usePageState = ({
  functionUuid,
  params,
}: {
  functionUuid: string
  params: any
}) => {
  const [searchUnit, setSearchUnit] = useState<SearchUnit>(SearchUnit.MONTH)
  const [dateTerm, setDateTerm] = useState<DateTerm>({
    startDate: DateVO.now().getFirstDayOfMonth().format(),
    endDate: DateVO.now().getLastDayOfMonth().format(),
  })
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [initialized, setInitialized] = useState(false)

  const storePageState = useCallback(
    async (value: StoredActualWorkingHoursPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    if (!initialized) return
    storePageState({
      searchUnit,
      dateTerm,
      rowHeight,
    })
  }, [initialized, dateTerm, storePageState, searchUnit, rowHeight])

  const restorePageState = useCallback(async () => {
    if (params.term) {
      const date = new DateVO(`${params.term}-01`)
      setDateTerm({
        startDate: date.getFirstDayOfMonth().format(),
        endDate: date.getLastDayOfMonth().format(),
      })
      setSearchUnit(SearchUnit.MONTH)
      setInitialized(true)
      return
    }
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const state = JSON.parse(stored.value)
      state.dateTerm && setDateTerm(state.dateTerm)
      state.searchUnit && setSearchUnit(state.searchUnit)
      state.rowHeight && setRowHeight(state.rowHeight)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid, params.term])

  useEffect(() => {
    restorePageState()
  }, [restorePageState])

  return {
    initialized,
    dateTerm,
    setDateTerm,
    searchUnit,
    setSearchUnit,
    rowHeight,
    setRowHeight,
  }
}
