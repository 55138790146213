import { createIntl, createIntlCache } from 'react-intl'
import translations from './locales'

const cache = createIntlCache()
const language = navigator.language.split(/[-_]/)[0]
export const intl = createIntl(
  {
    locale: navigator.language.split(/[-_]/)[0],
    messages: translations[language] || translations.en,
  },
  cache
)
