import { useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../../store'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import BaseButton from '../../../components/buttons/BaseButton'
import ReloadButton from '../../../components/buttons/ReloadButton'
import SubmitButton from '../../../components/buttons/SubmitButton'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { intl } from '../../../../i18n'
import { useDialogState } from '../../../components/dialogs/useDialogState'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  onSubmit: () => void
  submitDisabled: boolean
  isLoading: boolean
  onCancel: () => void
  onClickAddUser: () => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps & SettingSpeedDialProps

const ProjectMemberHeader = ({
  toolbar,
  onChangeToolbar,
  onClickExport,
  rowHeight,
  onClickChangeRowHeight,
  isNotice,
  onSubmit,
  submitDisabled,
  isLoading,
  notEdited,
  onCancel,
  onClickAddUser,
}: Props) => {
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()

  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <SubmitButton
            onClick={onSubmit}
            disabled={submitDisabled || isLoading || notEdited}
            notEdited={notEdited}
          />
          <ReloadButton onClick={openCancelDialog} disabled={isLoading} />
          <CancelConfirmDialog
            open={showCancelDialog}
            onConfirm={() => {
              closeCancelDialog()
              onCancel()
            }}
            onClose={closeCancelDialog}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={onClickExport}
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
        <HeaderGroup>
          <BaseButton
            sx={{
              background: BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
              color: TextColor.BLUE,
              width: '100%',
              padding: '0 10px',
              border: '1px solid transparent',
            }}
            variant="outlined"
            startIcon={
              <PersonAddAltRoundedIcon color="primary" fontSize="small" />
            }
            onClick={onClickAddUser}
          >
            {intl.formatMessage({ id: 'project.member.addButton' })}
          </BaseButton>
        </HeaderGroup>
      </HeaderContent>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(ProjectMemberHeader)
