import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { ProjectNotificationSettingRepository } from '../../applications/ports/ProjectNotificationSettingRepository'
import { ProjectNotificationSettingDeltaBatchRequest } from '../../view/pages/ProjectNotificationSettings/projectNotificationSettings'

export const useProjectNotificationSettingRepository =
  (): ProjectNotificationSettingRepository => {
    const get = useCallback(async (projectUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/notification_settings',
        { projectUuid }
      )
      return response.json
    }, [])
    const updateDeltaBatch = useCallback(
      async (delta: ProjectNotificationSettingDeltaBatchRequest) => {
        const response = await api.functional.request(
          'POST',
          '/api/v1/projects/notification_settings/delta/batch',
          delta
        )
        return response
      },
      []
    )
    return {
      get,
      updateDeltaBatch,
    }
  }
