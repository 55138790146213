import { IFilterParams } from 'ag-grid-community'
import { Box } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { intl } from '../../../../../../../../../i18n'
import { DetailFilter, useDetailFilter } from './useDetailFilter'

export const DetailCellFilter = forwardRef((props: IFilterParams, ref) => {
  const filter = useDetailFilter()

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        // Don't filter new rows
        if (params.data.isAdded) return true
        if (!filter.isFiltered()) return true
        if (filter.isEmpty()) return false
        return filter.pass(
          params.data.commentSummary ?? params.data.body?.commentSummary
        )
      },

      isFilterActive() {
        return filter.isFiltered()
      },

      getModel() {
        return filter.isFiltered() ? filter.values : undefined
      },

      setModel(model) {
        if (!model) {
          filter.reset()
          return
        }
        // TODO Remove it. migration from old implementation
        if (model.value) {
          filter.setValues(model.value)
          return
        }
        filter.setValues(model)
      },
    }
  })

  const onChange = (key: DetailFilter, checked) => {
    const values = filter.values
    if (checked) {
      filter.setValues([...values, key])
    } else {
      filter.setValues(values.filter(v => v !== key))
    }
  }

  useEffect(() => {
    setTimeout(props.filterChangedCallback, 0)
  }, [filter.values])

  return (
    <Box sx={{ minHeight: 100, padding: '2px' }}>
      {props.colDef?.cellRendererParams?.openComment && (
        <>
          <FilterCheckBox
            option={DetailFilter.HAS_COMMENT}
            checked={filter.values.includes(DetailFilter.HAS_COMMENT)}
            label={intl.formatMessage({ id: 'openDetail.hasComment' })}
            onChange={e => onChange(DetailFilter.HAS_COMMENT, e.target.checked)}
          />
          <FilterCheckBox
            option={DetailFilter.NO_COMMENT}
            checked={filter.values.includes(DetailFilter.NO_COMMENT)}
            label={intl.formatMessage({ id: 'openDetail.noComment' })}
            onChange={e => onChange(DetailFilter.NO_COMMENT, e.target.checked)}
          />
        </>
      )}
    </Box>
  )
})

type FilterCheckBoxProps = {
  option: DetailFilter
  checked: boolean
  label: string
  onChange: (e) => void
}

const FilterCheckBox = ({
  option,
  checked,
  label,
  onChange,
}: FilterCheckBoxProps) => {
  return (
    <CheckBoxItem key={`detail-filter-${option}`}>
      <FilterInput
        key={`detail-filter-${option}-checkbox`}
        checked={checked}
        onChange={onChange}
      />
      <FilterCheckBoxLabel htmlFor={`detail-filter-${option}-checkbox`}>
        {label}
      </FilterCheckBoxLabel>
    </CheckBoxItem>
  )
}

const CheckBoxItem = ({ children }) => (
  <div style={{ height: 23 }}>{children}</div>
)

const FilterInput = ({ checked, onChange }) => (
  <input
    type="checkbox"
    style={{
      width: 15,
      height: 15,
      color: '#0091ea',
      backgroundColor: '#ffffff',
      verticalAlign: 'middle',
    }}
    checked={checked}
    onChange={onChange}
  />
)

const FilterCheckBoxLabel = ({ children, htmlFor }) => (
  <label style={{ verticalAlign: 'middle' }} htmlFor={htmlFor}>
    {children}
  </label>
)
