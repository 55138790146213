import { styled } from '@mui/system'
import { useCallback } from 'react'
import MuiPinDropIcon from '@mui/icons-material/PinDropRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { IconButton } from './IconButton'
import { BreadCrumb } from '../../../store/breadcrumb'

type IconSize = 'xs' | 's' | 'm'
const Icon = styled(MuiPinDropIcon)<{ iconSize?: IconSize }>(({ iconSize }) => {
  switch (iconSize) {
    case 'xs':
      return {
        width: '12px',
        height: '12px',
      }
    case 's':
      return {
        width: '14px',
        height: '14px',
      }
    case 'm':
      return {
        width: '16px',
        height: '16px',
      }
  }
})

export const PinDropIcon = injectIntl(
  (
    props: {
      breadcrumbs: BreadCrumb
      size?: 'xs' | 's' | 'm'
      itemName?: string
    } & WrappedComponentProps
  ) => {
    const { intl, size, breadcrumbs, itemName } = props

    const onClick = useCallback(() => {
      let breadcrumbNames: string[] = []
      let breadcrumb = breadcrumbs
      while (breadcrumb?.next) {
        breadcrumbNames.push(breadcrumb.name)
        breadcrumb = breadcrumb.next
      }
      const nameStr = breadcrumbNames.length === 0 ? itemName : `/${itemName}`
      const breadcrumbStr = `/${breadcrumbNames.join('/')}${nameStr}`
      navigator.clipboard.writeText(breadcrumbStr)
    }, [breadcrumbs])
    return (
      <IconButton
        onClick={onClick}
        size={'small'}
        title={intl.formatMessage({
          id: 'copy.path',
        })}
      >
        <Icon iconSize={size || 's'} color="action" />
      </IconButton>
    )
  }
)
