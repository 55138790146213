import {
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Paper,
  Popper,
  styled,
} from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded'
import { intl } from '../../../../i18n'
import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  TeamSelectOption,
  TeamType,
  teamTotal,
  teamUnselected,
} from '../model/team'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import { useSelectOptions } from '../../../hooks/useSelectOptions'
import { Checkbox } from '../../../components/inputs/Checkbox'

type TeamSelectorBoxProps = {
  active: boolean
  onClick: () => void
}

const TeamSelectorButton = styled('button')<
  Omit<TeamSelectorBoxProps, 'onClick'>
>(({ active }) => ({
  border: `1px solid ${
    active ? colorPalette.skyBlue[7] : colorPalette.monotone[2]
  }`,
  borderRadius: '4px',
  backgroundColor: active ? colorPalette.skyBlue[0] : colorPalette.monotone[0],
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  height: '32px',
}))

const TeamSelectorChecklistIcon = styled(ChecklistRoundedIcon)<
  Omit<TeamSelectorBoxProps, 'onClick'>
>(({ active }) => ({
  color: active ? colorPalette.skyBlue[8] : colorPalette.monotone[4],
  width: '16px',
  height: '16px',
}))

const TeamSelectorBoxTitle = styled('div')<
  Omit<TeamSelectorBoxProps, 'onClick'>
>(({ active }) => ({
  fontSize: '12px',
  fontWeight: 600,
  color: active ? colorPalette.skyBlue[8] : colorPalette.monotone[4],
}))

const TeamSelectorBox = forwardRef(
  (
    { active, onClick }: TeamSelectorBoxProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <TeamSelectorButton
        active={active}
        onClick={onClick}
        ref={ref}
        sx={{ cursor: 'pointer' }}
      >
        <TeamSelectorChecklistIcon active={active} />
        <TeamSelectorBoxTitle active={active}>
          {intl.formatMessage({ id: 'team' })}
        </TeamSelectorBoxTitle>
      </TeamSelectorButton>
    )
  }
)

const TeamSelectorOptionBoxWrapper = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  borderRadius: '4px',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  width: '200px',
})

const TeamSelectorOptionBoxHeader = styled('div')({
  color: colorPalette.monotone[5],
  fontSize: '12px',
  fontWeight: 600,
  padding: '8px',
})

const TeamSelectorOptionBoxDivider = styled(Divider)({
  borderColor: colorPalette.monotone[2],
})

const TeamSelectorOptionBoxList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
})

const TeamSelectorOptionBoxListWithIndent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
})

const TeamSelectorOptionBoxListItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  padding: '2px 0',
})

const TeamSelectorOptionBoxListItemLabel = styled(FormControlLabel)({
  color: colorPalette.monotone[4],
  fontSize: '12px',
  margin: 0,
  '.MuiFormControlLabel-label': { padding: '5px' },
})

const TeamSelectorOptionBoxListItemCheckbox = styled(Checkbox)({
  padding: 0,
})

type TeamSelectorOptionBoxProps = {
  options: TeamSelectOption[]
  selected: string[]
  addOption: (...v: string[]) => void
  removeOption: (...v: string[]) => void
  toggleOption: (v: string) => void
}

const TeamSelectorOptionBox = ({
  options,
  selected,
  addOption,
  removeOption,
  toggleOption,
}: TeamSelectorOptionBoxProps) => {
  return (
    <TeamSelectorOptionBoxWrapper>
      <TeamSelectorOptionBoxHeader>
        {intl.formatMessage({ id: 'projectReport.selectTeam' })}
      </TeamSelectorOptionBoxHeader>
      <TeamSelectorOptionBoxDivider />
      <TeamSelectorOptionBoxList>
        <TeamSelectorOptionBoxListItem>
          <TeamSelectorOptionBoxListItemLabel
            label={intl.formatMessage({ id: 'selectAll' })}
            control={
              <TeamSelectorOptionBoxListItemCheckbox
                indeterminate={
                  selected.length > 0 && options.length !== selected.length
                }
                checked={options.length === selected.length}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    addOption(...options.map(v => v.value))
                  } else {
                    removeOption(...options.map(v => v.value))
                  }
                }}
              />
            }
          />
        </TeamSelectorOptionBoxListItem>
        <TeamSelectorOptionBoxListWithIndent>
          {options.map((option, i) => (
            <TeamSelectorOptionBoxListItem key={i}>
              <TeamSelectorOptionBoxListItemLabel
                label={option.name}
                control={
                  <TeamSelectorOptionBoxListItemCheckbox
                    checked={selected.some(v => v === option.value)}
                    onChange={() => toggleOption(option.value)}
                  />
                }
              />
            </TeamSelectorOptionBoxListItem>
          ))}
        </TeamSelectorOptionBoxListWithIndent>
      </TeamSelectorOptionBoxList>
    </TeamSelectorOptionBoxWrapper>
  )
}

type TeamSelectorProps = {
  projectUuid: string
  updateSelection: (selected: TeamSelectOption[]) => void
  disableTeamUnselected?: boolean
}

export const TeamSelector = ({
  projectUuid,
  updateSelection,
  disableTeamUnselected,
}: TeamSelectorProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const active = useMemo(() => {
    return Boolean(anchorEl)
  }, [anchorEl])
  const onClickButton = useCallback(() => {
    if (!active) {
      setAnchorEl(buttonRef.current)
    } else {
      setAnchorEl(null)
    }
  }, [active])
  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>([
    teamTotal,
    teamUnselected,
  ])
  const [
    selectedTeams,
    addSelectedTeam,
    removeSelectedTeam,
    toggleSelectedTeam,
  ] = useSelectOptions(['TEAM_TOTAL'])
  const teamRepository = useTeamRepository()
  useEffect(() => {
    const fn = async () => {
      const teams = await teamRepository.fetchList(projectUuid)
      setTeamOptions(
        [
          teamTotal,
          ...teams.map(team => ({
            value: team.uuid,
            name: team.displayName,
            iconUrl: team.iconUrl,
            type: 'SPECIFIC_TEAM' as TeamType,
          })),
          ...[disableTeamUnselected ? undefined : teamUnselected],
        ].filter(v => v) as TeamSelectOption[]
      )
    }
    fn()
  }, [projectUuid])

  useEffect(() => {
    const selected = teamOptions.filter(t => selectedTeams.includes(t.value))
    updateSelection(selected)
  }, [selectedTeams, teamOptions])
  return (
    <>
      <TeamSelectorBox
        ref={buttonRef}
        active={active}
        onClick={onClickButton}
      />
      <Popper open={active} anchorEl={anchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={onClickButton}>
          <Paper>
            <TeamSelectorOptionBox
              options={teamOptions}
              selected={selectedTeams}
              addOption={addSelectedTeam}
              removeOption={removeSelectedTeam}
              toggleOption={toggleSelectedTeam}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
