/* eslint-disable no-underscore-dangle */
import ProjectAPI, {
  ProjectDetail,
  ProjectStatus,
} from '../lib/functions/project'
import ProjectMemberAPI from '../lib/functions/projectMember'
import { Epic, ofType } from 'redux-observable'
import { mergeMap, map, tap, ignoreElements } from 'rxjs/operators'
import { showAlert } from './globalAlert'
import store from './index'
import { clearHeaderComponents } from './functionLayer'
import { wbsItemTypeRepository } from '../domain/repository'
import { WbsItemType } from '../domain/entity/WbsItemEntity'
import { WbsItemTypeVO } from '../domain/value-object/WbsItemTypeVO'
import { GanttParameterVO } from '../domain/value-object/GanttParameterVO'
import uiStates, { UiStateKey, UiStateScope } from '../lib/commons/uiStates'
import Auth from '../lib/commons/auth'
import { history } from '../view/router'

export class BaseWbsItemType {
  private readonly _source: WbsItemTypeVO[] = []
  private readonly _workgroup: WbsItemTypeVO
  private readonly _process: WbsItemTypeVO
  private readonly _deliverableList: WbsItemTypeVO
  private readonly _deliverable: WbsItemTypeVO
  private readonly _task: WbsItemTypeVO

  constructor(wbsItemTypes?: WbsItemTypeVO[]) {
    if (!wbsItemTypes) return
    const findType = (type: WbsItemType) =>
      wbsItemTypes.find(v => v.rootType === type)
    this._source = wbsItemTypes
    this._workgroup = findType(WbsItemType.WORKGROUP)!
    this._process = findType(WbsItemType.PROCESS)!
    this._deliverableList = findType(WbsItemType.DELIVERABLE_LIST)!
    this._deliverable = findType(WbsItemType.DELIVERABLE)!
    this._task = findType(WbsItemType.TASK)!
  }

  isEmpty(): boolean {
    return [
      this._workgroup,
      this._process,
      this._deliverableList,
      this._deliverable,
      this._task,
    ].some(v => !v)
  }

  get workgroup(): WbsItemTypeVO {
    return this._workgroup
  }

  get process(): WbsItemTypeVO {
    return this._process
  }

  get deliverableList(): WbsItemTypeVO {
    return this._deliverableList
  }

  get deliverable(): WbsItemTypeVO {
    return this._deliverable
  }

  get task(): WbsItemTypeVO {
    return this._task
  }

  get(type: WbsItemType): WbsItemTypeVO | undefined {
    return this._source.find(v => v.rootType === type)
  }

  getAll(): WbsItemTypeVO[] {
    return this._source
  }
}

enum ActionType {
  SET_ASSIGNED_PROJECTS = 'SET_ASSIGNED_PROJECTS',
  LOGIN_TO_PROJECT = 'LOGIN_TO_PROJECT',
  CHANGE_PROJECT = 'CHANGE_PROJECT',
  LOGOUT_FROM_PROJECT = 'LOGOUT_FROM_PROJECT',
  RECEIVED_PROJECT = 'RECEIVED_PROJECT',
  CHANGE_GANTT_PARAMETER = 'CHANGE_GANTT_PARAMETER',
}

type State = {
  selected?: string
  current?: ProjectDetail
  assigned: ProjectDetail[]
  isClosed?: boolean
  wbsItemTypes: BaseWbsItemType
  ticketListTypes: WbsItemTypeVO[]
  ticketTypes: WbsItemTypeVO[]

  ganttParameter?: GanttParameterVO
}

export const setAssignedProject = (projects: ProjectDetail[]) => ({
  type: ActionType.SET_ASSIGNED_PROJECTS,
  projects,
})

export const loginToProject = (uuid: string) => ({
  type: ActionType.LOGIN_TO_PROJECT,
  projectUuid: uuid,
  isUserAction: false,
})

export const loginToProjectByCode = (projectCode: string) => ({
  type: ActionType.LOGIN_TO_PROJECT,
  projectCode,
  isUserAction: false,
})

export const changeProject = (uuid: string) => ({
  type: ActionType.CHANGE_PROJECT,
  projectUuid: uuid,
})

export const loginToProjectByUserAction = (uuid: string) => ({
  type: ActionType.LOGIN_TO_PROJECT,
  projectUuid: uuid,
  isUserAction: true,
})

export const logoutFromProject = () => ({
  type: ActionType.LOGOUT_FROM_PROJECT,
})

export const changeGanttParameter = (
  projectUuid: string,
  ganttParameter: GanttParameterVO
) => ({
  type: ActionType.CHANGE_GANTT_PARAMETER,
  projectUuid,
  ganttParameter,
})

export const receivedProject = (
  project: ProjectDetail,
  loggedInToProject?: boolean,
  isClosed?: boolean,
  wbsItemTypes?: BaseWbsItemType,
  ticketListTypes?: WbsItemTypeVO[],
  ticketTypes?: WbsItemTypeVO[],
  ganttParameter?: GanttParameterVO
) => ({
  type: ActionType.RECEIVED_PROJECT,
  project,
  loggedInToProject,
  isClosed,
  wbsItemTypes,
  ticketListTypes,
  ticketTypes,
  ganttParameter,
})

const getGanttParameterFromUiState = async (
  projectUuid: string
): Promise<GanttParameterVO | undefined> => {
  const uiStateResponse = await uiStates.get({
    applicationFunctionUuid: '',
    key: `${UiStateKey.BulkSheetState}-${projectUuid}`,
    scope: UiStateScope.User,
  })
  const uiState = uiStateResponse.json.value
    ? JSON.parse(uiStateResponse.json.value)
    : {}
  return uiState.ganttParameter
    ? GanttParameterVO.deserialize(uiState.ganttParameter)
    : undefined
}
const getBaseWbsItemType = async (
  projectUuid: string
): Promise<BaseWbsItemType> => {
  const wbsItemTypeList = await wbsItemTypeRepository.getWbsItemTypes(
    projectUuid
  )
  return new BaseWbsItemType(wbsItemTypeList)
}
const getTicketTypes = async (
  projectUuid: string
): Promise<WbsItemTypeVO[]> => {
  const ticketTypeList = await wbsItemTypeRepository.getTicketTypes(projectUuid)
  return ticketTypeList
}
const getProjectByUuid = async (uuid: string): Promise<ProjectDetail> => {
  const projectResponse = await ProjectAPI.getDetail({
    uuid,
  })
  return projectResponse.json as ProjectDetail
}
const getProjectByCode = async (code: string): Promise<ProjectDetail> => {
  const projectResponse = await ProjectAPI.getDetailByCode({
    code,
  })
  return projectResponse.json as ProjectDetail
}

export const fetchProjectEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.LOGIN_TO_PROJECT),
    mergeMap(async action => {
      try {
        const assigned = store.getState().project.assigned
        let project = (assigned || []).find(
          v => v.uuid === action.projectUuid || v.code === action.projectCode
        )
        if (project) {
          const index = assigned.findIndex(v => v.uuid === project!.uuid)
          assigned.splice(index, 1)
          assigned.unshift(project)
          store.dispatch(setAssignedProject(assigned))
        } else if (action.projectUuid) {
          project = await getProjectByUuid(action.projectUuid)
        } else if (action.projectCode) {
          project = await getProjectByCode(action.projectCode)
        } else {
          throw new Error('Can not get the project when login to the project.')
        }

        const [_, wbsItemTypes, ticketTypes, ganttParameter] =
          await Promise.all([
            ProjectMemberAPI.loginToProject({
              projectUuid: project.uuid,
            }),
            getBaseWbsItemType(project.uuid),
            getTicketTypes(project.uuid),
            getGanttParameterFromUiState(project.uuid),
          ])
        return {
          isUserAction: action.isUserAction,
          project: project,
          isClosed:
            project.status === ProjectStatus.COMPLETED ||
            project.status === ProjectStatus.SUSPENDED,
          wbsItemTypes,
          ticketListTypes: ticketTypes.filter(v => !!v.child),
          ticketTypes: ticketTypes.filter(v => !v.child),
          ganttParameter: ganttParameter || GanttParameterVO.defaultValue(),
        }
      } catch (err) {
        return { err }
      }
    }),
    map(result => {
      const {
        isUserAction,
        project,
        isClosed,
        wbsItemTypes,
        ticketListTypes,
        ticketTypes,
        ganttParameter,
      } = result
      const err: any = result.err
      if (err && err.status === 400) {
        store.dispatch(
          showAlert({
            message: err.detail,
            init: () => history.push('/projects'),
          })
        )
        return logoutFromProject()
      }
      return receivedProject(
        project!,
        isUserAction,
        isClosed,
        wbsItemTypes,
        ticketListTypes,
        ticketTypes,
        ganttParameter
      )
    })
  )

export const changeProjectEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.CHANGE_PROJECT),
    tap(action => {
      store.dispatch(clearHeaderComponents())
      store.dispatch(loginToProjectByUserAction(action.projectUuid))
    }),
    ignoreElements()
  )

export const receiveProjectEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.RECEIVED_PROJECT),
    tap(({ project, loggedInToProject }) => {
      if (loggedInToProject) {
        if (Auth.isAccountingUser()) {
          history.push(`/profitLossSummary/${project.code}`)
        } else {
          history.push(`/projectPlanLL/${project.code}`)
        }
      }
    }),
    ignoreElements()
  )

export const changeGanttParameterEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.CHANGE_GANTT_PARAMETER),
    tap(({ projectUuid, ganttParameter }) => {
      uiStates.update(
        {
          key: `${UiStateKey.BulkSheetState}-${projectUuid}`,
          scope: UiStateScope.User,
          value: JSON.stringify({
            ganttParameter: ganttParameter.serialize(),
          }),
        },
        ''
      )
    }),
    ignoreElements()
  )

export const reducer = (
  state: State = {
    assigned: [],
    wbsItemTypes: new BaseWbsItemType(),
    ticketListTypes: [],
    ticketTypes: [],
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.SET_ASSIGNED_PROJECTS:
      return { ...state, assigned: action.projects }
    case ActionType.LOGOUT_FROM_PROJECT:
      return {
        ...state,
        selected: undefined,
        current: undefined,
        isClosed: undefined,
        ticketListTypes: [],
        ticketTypes: [],
      }
    case ActionType.RECEIVED_PROJECT:
      return {
        ...state,
        selected: action.project?.uuid,
        current: action.project,
        isClosed: action.isClosed,
        wbsItemTypes: action.wbsItemTypes,
        ticketListTypes: action.ticketListTypes,
        ticketTypes: action.ticketTypes,
        ganttParameter: action.ganttParameter,
      }
    case ActionType.CHANGE_GANTT_PARAMETER:
      return {
        ...state,
        ganttParameter: action.ganttParameter,
      }
    default:
      return state
  }
}
