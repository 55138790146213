import { RefObject, useCallback } from 'react'
import { useDragTreeStyle } from '../../../containers/BulkSheetView/hooks/gridEvents/treeRowDrag'
import { GridOptions, RowDragEvent } from 'ag-grid-community'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  WbsItemAdditionalPropertyRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { sortByRowIndex } from '../../../containers/BulkSheetView/lib/rowNode'
import {
  moveRowsToLastChild,
  slideRowsAfter,
  slideRowsBefore,
} from '../../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import { focusRow } from '../../../containers/BulkSheetView/lib/gridApi'

const acceptChild = (
  parent: WbsItemAdditionalPropertyRow,
  child: WbsItemAdditionalPropertyRow
): boolean => {
  return isGroupHeaderRow(parent) && isAdditionalPropertyRow(child)
}
export const useRowDragAndDrop = (
  ref: RefObject<HTMLDivElement>,
  data: WbsItemAdditionalPropertyRow[],
  setData: (data: WbsItemAdditionalPropertyRow[]) => void,
  gridOptions: GridOptions
) => {
  const { onRowDragEnter, onRowDragMove, refreshDragStyle } = useDragTreeStyle(
    ref,
    acceptChild,
    gridOptions
  )
  const onRowDragEnd = useCallback(
    (event: RowDragEvent) => {
      const ids = event.nodes.map(v => v.id)
      const topLevelNodes = event.nodes.filter(n => !ids.includes(n.parent?.id))
      const movedData = sortByRowIndex(topLevelNodes).map(node => node.data)
      const overNode = event.overNode

      if (overNode && overNode.id === gridOptions.context.draggableNodeId) {
        try {
          if (gridOptions.context.onTree) {
            const result = moveRowsToLastChild(data, movedData, overNode.id)
            setData(result)
            overNode.setExpanded(true)
            // Focus new position
            topLevelNodes[0].id && focusRow(event.api, topLevelNodes[0].id)

            return
          } else {
            // If moved toward down, move after overNode.
            if (event.overIndex > (event.node.rowIndex || 0)) {
              setData(slideRowsAfter(data, movedData, overNode.id!))
              return
            }
            setData(slideRowsBefore(data, movedData, overNode.id!))
          }
        } finally {
          // Clear drag cell style
          refreshDragStyle()
        }
      }
    },
    [data, setData, refreshDragStyle]
  )

  return {
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    refreshDragStyle,
  }
}
