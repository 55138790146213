import { styled } from '@mui/system'
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded'
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'
import Checkbox from '../../../../components/editors/checkbox/Checkbox'
import { IconButton } from '@mui/material'
import { useCallback } from 'react'
import objects from '../../../../../utils/objects'
import { Color, FontSize } from '../../../../../styles/commonStyles'
import { AccumulateDirection, ProgressReportGraphConfig } from '../..'
import ColorLegend from './ColorLegend'

const RootDiv = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '17px',
})
const Label = styled('span')({
  fontSize: FontSize.SMALL,
  width: '90px',
  margin: '0 3px',
})
const StyledIconButton = styled('span')({
  padding: 0,
}) as typeof IconButton
const styleIcon = ({ on }: { on: boolean }) => {
  return {
    color: on ? Color.MAIN : Color.DISABLED,
    width: '16px',
    height: '16px',
  }
}
const StyledTrendingUpRoundedIcon = styled(TrendingUpRoundedIcon)(styleIcon)
const StyledTrendingDownRoundedIcon = styled(TrendingDownRoundedIcon)(styleIcon)
const StyledBarChartRoundedIcon = styled(BarChartRoundedIcon)(styleIcon)

type LegendItemProps = {
  color: string
  title: string
  config: ProgressReportGraphConfig
  onChange: (newConfig: ProgressReportGraphConfig) => void
}

const LegendItem = ({ color, title, config, onChange }: LegendItemProps) => {
  const { show, showBar, direction, disabled } = config
  const isBurnUp = direction === AccumulateDirection.BURN_UP
  const isBurnDown = !isBurnUp
  const onChangeItem = useCallback(
    (key: 'show' | 'showBar' | 'direction') => {
      const newConfig = {
        ...config,
      }
      let newValue
      if (key === 'show') {
        newValue = !show
      } else if (key === 'showBar') {
        newValue = !showBar
      } else {
        newValue = isBurnUp
          ? AccumulateDirection.BURN_DOWN
          : AccumulateDirection.BURN_UP
      }
      objects.setValue(newConfig, key, newValue)
      onChange(newConfig)
    },
    [config, onChange]
  )
  return (
    <RootDiv>
      <Checkbox
        checked={show}
        color="primary"
        disabled={disabled}
        size="small"
        onChange={() => onChangeItem('show')}
      />
      <ColorLegend color={color} />
      <Label>{title}</Label>
      <StyledIconButton
        onClick={() => onChangeItem('direction')}
        disabled={!show}
      >
        <StyledTrendingUpRoundedIcon on={isBurnUp} />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => onChangeItem('direction')}
        disabled={!show}
      >
        <StyledTrendingDownRoundedIcon on={isBurnDown} />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => onChangeItem('showBar')}
        disabled={!show}
      >
        <StyledBarChartRoundedIcon on={showBar} />
      </StyledIconButton>
    </RootDiv>
  )
}

export default LegendItem
