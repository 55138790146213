import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ReferencedEntity } from '../../../../../domain/value-object/ReferencedEntity'
import { MultiAutocomplete } from '../../../../components/inputs/MultiAutocomplete'

type MultiAutocompleteCellProps<Entity extends ReferencedEntity> = {
  value: Entity[]
  onChange: (v: Entity[]) => void
  search: (input: string) => Promise<Entity[]>
  displayIcon?: boolean
} & CellPropsBase

export const MultiAutocompleteCell = <Entity extends ReferencedEntity>({
  cellWidth,
  label,
  value,
  onChange,
  search,
  editable = true,
  required = false,
  displayIcon = false,
}: MultiAutocompleteCellProps<Entity>) => {
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <MultiAutocomplete value={value} onChange={onChange} search={search} />
      </CellValueArea>
    </FormCell>
  )
}
