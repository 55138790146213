import { styled } from '@mui/material'
import { EditConfigButton, PageHeader, PageRoot } from '.'
import { ProgressOverviewPageConfig } from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'
import {
  ProgressOverviewPageContent,
  VersionReportStatus,
} from '../../../../../domain/entity/version-report/VersionReportVersionEntity'
import { colorPalette } from '../../../../style/colorPallete'
import { ReportStatusIcon } from '../status-icon'
import { TextInput } from '../inputs/TextInput'
import { useCallback, useState } from 'react'
import { ReportStatusSelect } from '../inputs/ReportStatusSelect'
import { MasterSchedule } from '../master-schedule'
import { useProjectPrivateContext } from '../../../../context/projectContext'
import { MasterScheduleConfigDialog } from '../dialog/MasterScheduleConfigDialog'

type ProgressOverviewProps = {
  editing: boolean
  snapshotUuid: string
  config: ProgressOverviewPageConfig
  content: ProgressOverviewPageContent
  updatePageConfig: <
    K extends keyof ProgressOverviewPageConfig,
    V extends ProgressOverviewPageConfig[K]
  >(
    path: K,
    value: V
  ) => void
  updatePageContent: <
    K extends keyof ProgressOverviewPageContent,
    V extends ProgressOverviewPageContent[K]
  >(
    path: K,
    value: V
  ) => void
}
export const ProgressOverview = ({
  snapshotUuid,
  editing,
  config,
  content,
  updatePageConfig,
  updatePageContent,
}: ProgressOverviewProps) => {
  const { projectUuid } = useProjectPrivateContext()
  const onChangeStatus = useCallback(
    (v: VersionReportStatus) => {
      updatePageContent('status', v)
    },
    [updatePageContent]
  )
  const onChangeComment = useCallback(
    (v: string | undefined) => {
      updatePageContent('comment', v || '')
    },
    [updatePageContent]
  )
  const updateConfig = useCallback(
    (leafUuids: string[]) => {
      updatePageConfig('targetUuids', leafUuids)
    },
    [updatePageConfig]
  )
  const [open, setOpen] = useState<boolean>(false)
  const openDialog = useCallback(() => setOpen(true), [])
  const closeDialog = useCallback(() => setOpen(false), [])
  return (
    <>
      <StatusAndCommentRow>
        <StatusArea>
          {!editing && <ReportStatusIcon status={content.status} />}
          {editing && (
            <ReportStatusSelect
              status={content.status}
              onChange={onChangeStatus}
            />
          )}
        </StatusArea>
        <CommentArea>
          {!editing && <CommentBody>{content.comment}</CommentBody>}
          {editing && (
            <TextInput
              value={content.comment}
              onChange={onChangeComment}
              variant="comment"
              multiline={true}
            />
          )}
        </CommentArea>
      </StatusAndCommentRow>
      <MasterSchedule
        projectUuid={projectUuid}
        targetUuids={config.targetUuids}
        rootUuid=""
        snapshotUuid={snapshotUuid}
      />
      <EditConfigButton editing={editing} onClick={openDialog} />
      <MasterScheduleConfigDialog
        open={open}
        onClose={closeDialog}
        updateConfig={updateConfig}
        config={config}
        snapshotUuid={snapshotUuid}
      />
    </>
  )
}

const StatusAndCommentRow = styled('div')({
  display: 'flex',
  gap: '30px',
  height: '60px',
})
const StatusArea = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
})
const CommentArea = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  display: 'flex',
  width: 'calc(100% - 90px)',
  padding: '6px',
})
const CommentBody = styled('span')({
  color: colorPalette.monotone[9],
  fontSize: '14px',
  lineHeight: 1.5,
  overflowWrap: 'break-word',
  height: '100%',
  overflowY: 'scroll',
})
