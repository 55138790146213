import { generateUuid } from '../../../utils/uuids'
import API, { APIResponse } from '../../../lib/commons/api'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { WbsItemDetail } from '../../../lib/functions/wbsItem'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'
import { ProjectMemberProps } from '../../../lib/functions/projectMember'
import { DateTerm } from '../../../utils/date'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { TeamProps } from '../../../lib/functions/team'
import { UserBasic } from '../../../lib/functions/user'
import { AttachmentSummary } from '../../../utils/attachment'
import { CommentSummary } from '../../../store/comments'
import { SprintDetail } from '../../../lib/functions/sprint'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import store from '../../../store'
import {
  BoolFilter,
  CommentCreatedAtFilter,
  CommentTextFilter,
  DateFilter,
  NumberFilter,
  SelectFilter,
  TextFilter,
} from '../../containers/BulkSheetView/components/filter'
import { EntityExtensionValue } from '../../containers/meta/entityExtension'
import { ExtensionFilter } from '../../containers/BulkSheetView/components/filter/extensions'
import { TicketCumulation } from '../../../lib/functions/ticketList'
import { TagForWbsItem } from '../../../lib/functions/tag'
import {
  WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemAdditionalPropertyValueFilter } from '../../containers/BulkSheetView/components/filter/wbsItemAdditionalPropertyValues'

/**
 * API response
 */
export class ProjectPlanSkeleton implements TreeSource<ProjectPlanSkeleton> {
  uuid: string
  lockVersion: number
  children: ProjectPlanSkeleton[]
  type: WbsItemType
  wbsItemUuid: string
  body?: ProjectPlanBody

  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic
}

export interface ProjectPlanBody {
  wbsItem: WbsItemDetail
  sprint?: SprintDetail[]
  cumulation?: ProjectPlanCumulation
  ticketCumulation?: TicketCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
}

/**
 * RowData definition
 */
export interface ProjectPlanNewRow extends TreeRow {
  type: WbsItemType
  wbsItemUuid: string
  body?: ProjectPlanRowBody
}

export class ProjectPlanRowBody {
  wbsItem: NewWbsItemRow
  cumulation?: ProjectPlanCumulation
  ticketCumulation?: TicketCumulation
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  sprint?: SprintDetail
  extensions?: EntityExtensionValue[]

  constructor(src: ProjectPlanBody) {
    const w = src.wbsItem
    this.wbsItem = {
      projectUuid: w.projectUuid,
      uuid: w.uuid,
      lockVersion: w.lockVersion,

      code: w.code,
      type: w.type,
      wbsItemType: w.typeDto
        ? new WbsItemTypeVO(w.typeDto)
        : store.getState().project.wbsItemTypes.get(w.type)!,
      baseWbsItemType: w.baseTypeDto
        ? new WbsItemTypeVO(w.baseTypeDto)
        : store.getState().project.wbsItemTypes.get(w.type)!,
      displayName: w.displayName,
      status: w.status ? (w.status as WbsItemStatus) : undefined,
      substatus: w.substatus === CUSTOM_ENUM_NONE ? undefined : w.substatus,
      priority: w.priority === CUSTOM_ENUM_NONE ? undefined : w.priority,
      difficulty: w.difficulty === CUSTOM_ENUM_NONE ? undefined : w.difficulty,
      description: w.description ?? '',
      team: w.team,
      accountable: w.accountable,
      responsible: w.responsible,
      assignee: w.assignee,
      estimatedStoryPoint: w.estimatedStoryPoint,
      estimatedHour: w.estimatedHour,
      scheduledDate: {
        startDate: w.scheduledDate.startDate,
        endDate: w.scheduledDate.endDate,
      },
      actualDate: {
        startDate: w.actualDate.startDate,
        endDate: w.actualDate.endDate,
      },

      estimatedAmount: w.estimatedAmount,
      actualAmount: w.actualAmount,

      ticketType: w.ticketType === CUSTOM_ENUM_NONE ? undefined : w.ticketType,
      ticketListUuid: w.ticketListUuid,

      watchers: w.watchers,
      tags: w.tags,

      sprint: w.sprint,

      additionalPropertyValues: w.additionalPropertyValues
        ? wbsItemAdditionalPropertyValuesVoService.of(
            w.uuid,
            w.additionalPropertyValues
          )
        : undefined,

      revision: w.revision,
      updatedAt: w.updatedAt,
      updatedBy: w.updatedBy,
      createdAt: w.createdAt,
      createdBy: w.createdBy,
    }
    this.sprint = w.sprint
    this.cumulation = src.cumulation
    this.ticketCumulation = src.ticketCumulation
    this.commentSummary = src.commentSummary
    this.deliverableAttachmentSummary = src.deliverableAttachmentSummary
    // TODO Deserialize
    this.extensions = w.extensions
  }
}

export class NewWbsItemRow {
  uuid?: string
  lockVersion?: number
  projectUuid?: string

  code?: string
  type: WbsItemType
  wbsItemType: WbsItemTypeVO
  baseWbsItemType: WbsItemTypeVO
  displayName?: string
  description?: string
  team?: TeamProps
  accountable?: ProjectMemberProps
  responsible?: ProjectMemberProps
  assignee?: ProjectMemberProps
  estimatedStoryPoint?: number
  estimatedHour?: number
  priority?: string
  difficulty?: string
  scheduledDate?: DateTerm
  actualDate?: DateTerm
  status?: WbsItemStatus
  substatus?: string

  estimatedAmount?: number
  actualAmount?: number

  ticketType?: string
  ticketListUuid?: string

  watchers?: ProjectMemberProps[]
  revision?: string
  createdAt?: number
  createdBy?: UserBasic
  updatedAt?: number
  updatedBy?: UserBasic

  tags?: TagForWbsItem[]
  sprint?: SprintDetail
  additionalPropertyValues?: WbsItemAdditionalPropertyValuesVO
}

/**
 * Row data functionality
 */
export const createNewProjectPlanNewRow = (
  type: WbsItemTypeVO,
  ticketListUuid?: string
): ProjectPlanNewRow => {
  const wbsItemUuid = generateUuid()
  const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
  return {
    uuid: generateUuid(),
    type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
    wbsItemUuid: wbsItemUuid,
    treeValue: [],
    body: {
      wbsItem: {
        uuid: wbsItemUuid,
        code: createRandomCode,
        type: type.isWorkgroup() ? WbsItemType.PROCESS : type.rootType,
        wbsItemType: type,
        baseWbsItemType: type,
        ticketType: type.isTicket()
          ? type.code.replace('_LIST', '')
          : undefined,
        ticketListUuid,
        status: WbsItemStatus.TODO,
        scheduledDate: { startDate: undefined, endDate: undefined },
        actualDate: { startDate: undefined, endDate: undefined },
      },
      cumulation: new ProjectPlanCumulation(),
    },
  }
}

export const duplicateProjectPlanNewRow = (row: ProjectPlanNewRow) => {
  const wbsItemUuid = generateUuid()
  return {
    ...row,
    uuid: generateUuid(),
    wbsItemUuid: wbsItemUuid,
    treeValue: [],
    body: {
      wbsItem: {
        ...(row.body!.wbsItem || {}),
        uuid: wbsItemUuid,
        code: '',
      },
    },
  }
}

export const duplicateProjectPlanNewRowNameOnly = (row: ProjectPlanNewRow) => {
  const wbsItemUuid = generateUuid()
  const createRandomCode = Math.random().toString(36).slice(-8).toUpperCase()
  return {
    ...row,
    uuid: generateUuid(),
    wbsItemUuid: wbsItemUuid,
    treeValue: [],
    body: {
      wbsItem: {
        uuid: wbsItemUuid,
        code: createRandomCode,
        status: WbsItemStatus.TODO,
        displayName: row.body?.wbsItem.displayName,
        type: row.body?.wbsItem.type,
        wbsItemType: row.body?.wbsItem.wbsItemType,
        baseWbsItemType: row.body?.wbsItem.baseWbsItemType,
        ticketType: row.body?.wbsItem.ticketType,
        ticketListUuid: row.body?.wbsItem.ticketListUuid,
        scheduledDate: { startDate: undefined, endDate: undefined },
        actualDate: { startDate: undefined, endDate: undefined },
      },
      cumulation: new ProjectPlanCumulation(),
    },
  } as ProjectPlanNewRow
}

export const acceptChild = (
  parent: ProjectPlanNewRow,
  child: ProjectPlanNewRow
): boolean => {
  const parentType = parent.body!.wbsItem.wbsItemType
  const childType = child.body!.wbsItem.wbsItemType
  return (
    childType.canBeChildOf(parentType) ||
    (childType.isWorkgroup() && parentType.isWorkgroup())
  )
}

/**
 *
 * @param params
 */
export const fetchProjectPlanSkeleton = async (params: {
  projectUuid: string
  rootProjectPlanUuid?: string
  fetchBodyUuid?: string[]
  wbsItemTypes?: string[]
}): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/plans/skeleton',
    params
  )
}

export const fetchProjectPlanBody = async (
  projectPlanUuid: string[]
): Promise<APIResponse> => {
  return API.functional.request('GET', '/api/v1/projects/plans/body', {
    projectPlanUuid,
  })
}

export type ProjectPlanFilterRequest = {
  projectUuid: string
  type?: SelectFilter
  code?: TextFilter
  displayName?: TextFilter
  description?: TextFilter
  estimatedHour?: NumberFilter
  scheduledStartDate?: DateFilter
  scheduledEndDate?: DateFilter
  actualStartDate?: DateFilter
  actualEndDate?: DateFilter
  status?: SelectFilter
  substatus?: SelectFilter
  priority?: SelectFilter
  difficulty?: SelectFilter
  team?: SelectFilter
  accountable?: SelectFilter
  responsible?: SelectFilter
  assignee?: SelectFilter

  estimatedAmount?: NumberFilter
  actualAmount?: NumberFilter

  actualHour?: NumberFilter
  watcher?: SelectFilter
  attachment?: BoolFilter
  sprint?: SelectFilter

  commentExist?: BoolFilter
  commentText?: CommentTextFilter
  commentCreatedAt?: CommentCreatedAtFilter
  extensions?: ExtensionFilter[]
  additionalPropertyValues?: WbsItemAdditionalPropertyValueFilter[]

  tags?: SelectFilter
}

export const filter = async (
  request: ProjectPlanFilterRequest
): Promise<APIResponse> => {
  return API.functional.request(
    'GET',
    '/api/v1/projects/plans/filter_by_wbs_item',
    request
  )
}
