import BulkSheetComponent, { BulkSheet } from '../../containers/BulkSheet'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import { PageArea, PageProps } from '../index'
import { ProjectPlanLazyLoadOptions } from './projectPlanLazyLoadOptions'
import ProjectPlanHeader from './Header'
import ProjectPlanToolBar from './Toolbar'
import { ProjectPlanDetail } from '../../../lib/functions/projectPlan'
import {
  ColumnQuickFilterKey,
  getGanttChartWidth,
  ProjectPlanProps,
  ProjectPlanRow,
  ProjectPlanState,
} from '../ProjectPlan/projectPlanOptions'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { ColDef, ColumnVisibleEvent, GridOptions } from 'ag-grid-community'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { Function } from '../../../lib/commons/appFunction'
import { Collapse } from '@mui/material'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'
import { useProjectPlanPageState } from '../ProjectPlan/usePageState'
import { ProjectPlanAgGridContext } from '../ProjectPlan'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { generateGanttScale } from '../../containers/commons/AgGrid/components/cell/custom/gantt/ganttUtil'
import { useProjectPrivateContext } from '../../context/projectContext'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

type StateProps = {
  functions: Function[]
  ganttParameter?: GanttParameterVO
}

const mapStateToProps = (state: AllState, props) => ({
  functions: state.appFunction.functions,
  ganttParameter: state.project.ganttParameter,
})

const ProjectPlanLazyLoad = (props: PageProps & StateProps) => {
  const { project } = useProjectPrivateContext()
  const fn = props.functions.find(v => v.externalId === props.externalId)
  if (!fn || !props.ganttParameter) {
    return <></>
  }
  return (
    <ProjectPlanLazyLoadContent
      {...props}
      fn={fn}
      projectUuid={project.uuid}
      ganttParameter={props.ganttParameter}
    />
  )
}

type Props = PageProps & {
  fn: Function
  projectUuid: string
  ganttParameter: GanttParameterVO
}

const ProjectPlanLazyLoadContent = (props: Props) => {
  const projectPlanLazyLoadOptions = useMemo(
    () => new ProjectPlanLazyLoadOptions(),
    []
  )

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [ganttChartVisible, setGanttChartVisible] = useState(false)

  // TODO Delete it in the future
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        ProjectPlanProps,
        ProjectPlanDetail,
        ProjectPlanRow,
        ProjectPlanState
      >
    >()
  const agGridContextHandler = useCallback(
    (context: Partial<ProjectPlanAgGridContext>) => {
      if (!bulkSheet) return
      bulkSheet.setContext(context)
      bulkSheet.gridApi?.refreshCells()
      bulkSheet.gridApi?.refreshCells({
        // FIXME Fix bug the cells of tasks having child tasks do not refresh.
        columns: ['wbsItem.estimatedWorkload.task', 'wbsItem.actualHour'],
        force: true,
      })
    },
    [bulkSheet]
  )

  const {
    wbsItemType,
    aggregateTargetType,
    workloadUnit,
    dateFormat,
    toolbar,
    updatePageState,
  } = useProjectPlanPageState(props.uuid)

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    agGridContextHandler({
      wbsItemType,
      aggregateTargetType,
      workloadUnit,
      dateFormat,
      workloadUnitState,
    })
  }, [
    agGridContextHandler,
    wbsItemType,
    aggregateTargetType,
    workloadUnit,
    dateFormat,
    workloadUnitState,
  ])
  const onChangeWbsItemType = useCallback(
    (wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK) => {
      updatePageState({ wbsItemType })
    },
    []
  )
  const onChangeAggregateType = useCallback((aggregateType: AggregateField) => {
    updatePageState({ aggregateTargetType: aggregateType })
  }, [])
  const onChangeWorkloadUnit = useCallback((workloadUnit: WorkloadUnit) => {
    updatePageState({ workloadUnit })
  }, [])
  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])
  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar })
    },
    []
  )

  const onSubmit = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.onSubmit()
    }
  }, [bulkSheet])
  const onCancel = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.onCancel()
    }
  }, [bulkSheet])
  const openExcelOutputColumnSelectDialog = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.openExcelOutputColumnSelectDialog()
    }
  }, [bulkSheet])
  const onResetColumnState = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.resetColumnAndFilterState()
    }
  }, [bulkSheet])

  const gridOptions: GridOptions = useMemo(
    () => ({
      onColumnVisible: (e: ColumnVisibleEvent) => {
        const onDisplayGanttChart = (visible: boolean) => {
          setGanttChartVisible(visible)
          e.api.ensureColumnVisible(
            'ganttChart',
            props.ganttParameter.calcNearestPosition()
          )
        }
        if (e.column && e.column.getColDef().field === 'ganttChart') {
          onDisplayGanttChart(!!e.visible)
        }
        if (
          e.columns &&
          e.columns.some(v => v.getColDef().field === 'ganttChart')
        ) {
          const ganttChart = e.columns.find(
            v => v.getColDef().field === 'ganttChart'
          )
          onDisplayGanttChart(!!ganttChart?.isVisible())
        }
      },
    }),
    [props.ganttParameter]
  )

  const updateGanttChartByParams = useCallback(
    async (parameter: GanttParameterVO) => {
      if (
        !bulkSheet ||
        !bulkSheet.gridApi ||
        !bulkSheet.columnApi ||
        !parameter
      ) {
        return
      }
      const { gridApi, columnApi } = bulkSheet

      // Change context
      const [ganttDisplayTimeScale, ganttTimeScale] = await generateGanttScale(
        parameter
      )
      agGridContextHandler({
        ganttParameter: parameter,
        ganttDisplayTimeScale,
        ganttTimeScale,
      })

      // Restore column state
      const colState = columnApi.getColumnState()
      const resetState = (colDef: ColDef) => {
        const state = colState.find(v => v.colId === colDef.field)
        if (!state) return
        colDef['hide'] = state.hide || undefined
        colDef['pinned'] = state.pinned
        colDef['width'] = state.width
      }
      if (gridOptions?.columnDefs) {
        gridOptions.columnDefs.forEach(colDef => {
          'field' in colDef && resetState(colDef)
          'children' in colDef && colDef.children.forEach(resetState)
        })

        // Refresh columns
        gridApi.setColumnDefs(gridOptions.columnDefs)
      }
      columnApi.applyColumnState({ state: colState, applyOrder: true })

      // Reset width
      columnApi.setColumnWidth('ganttChart', getGanttChartWidth(parameter))

      gridApi.refreshCells({ columns: ['ganttChart'], force: true })
      gridApi.refreshHeader()
      setTimeout(() => {
        gridApi.ensureColumnVisible(
          'ganttChart',
          parameter.calcNearestPosition()
        )
      }, 300)
    },
    [bulkSheet]
  )
  useEffect(() => {
    if (!ganttChartVisible) return
    updateGanttChartByParams(props.ganttParameter)
  }, [bulkSheet, ganttChartVisible, props.ganttParameter])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
        return
      }
      if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
        return
      }
    },
    [bulkSheet]
  )
  const restoreColumnState = useCallback(() => {
    if (bulkSheet) {
      bulkSheet.openSavedBulkSheetUIStateDialog(
        UiStateKey.BulkSheetUIStateColumnAndFilter
      )
    }
  }, [bulkSheet])

  return (
    <PageArea>
      <ProjectPlanHeader
        projectUuid={props.projectUuid}
        title={props.fn.name}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onClickExport={openExcelOutputColumnSelectDialog}
        onResetColumnState={onResetColumnState}
        onRestoreColumnState={restoreColumnState}
        currentFormat={dateFormat}
        onClickChangeDateFormat={onChangeDateFormat}
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <ProjectPlanToolBar
          toolbar={toolbar}
          wbsItemType={wbsItemType}
          onChangeWbsItemType={onChangeWbsItemType}
          aggregateType={aggregateTargetType}
          onChangeAggregateType={onChangeAggregateType}
          workloadUnit={workloadUnit}
          onChangeWorkloadUnit={onChangeWorkloadUnit}
          showGanttParameter={ganttChartVisible}
          onChangeColumnFilter={onChangeColumnFilter}
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={projectPlanLazyLoadOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={setBulkSheet}
        setSubmitDisabled={setSubmitDisabled}
        setIsLoading={setIsLoading}
        gridOptions={gridOptions}
      />
    </PageArea>
  )
}

export default connect(mapStateToProps)(projectPrivate(ProjectPlanLazyLoad))
