import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import {
  BorderColor,
  FontSize,
  TextColor,
} from '../../../../../styles/commonStyles'
import { ClickAwayListener } from '@mui/material'
import TextInput from '../../../editors/input/TextInput'
import objects from '../../../../../utils/objects'
import store from '../../../../../store'
import { applyDiff } from '../../../../../store/singleSheet'
import MuiTooltip from '@mui/material/Tooltip'
import { requireSave } from '../../../../../store/requiredSaveData'

const RootContainer = styled('div')({
  display: 'flex',
  minWidth: '600px',
  maxWidth: '600px',
  alignItems: 'center',
})
const Title = styled('div')({
  width: '95%',
  minHeight: '2em',
  border: `solid 1px ${BorderColor.GREY}`,
  borderRadius: '5px',
  cursor: 'pointer',
})
const TitleTooltipMessage = styled(Typography)({
  margin: '0 7px',
  fontSize: FontSize.XX_LARGE,
  color: TextColor.DARK_BLACK,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
const TitleEditor = styled(TextInput)({
  marginRight: '7px',
  border: `solid 1px ${BorderColor.GREY}`,
  borderRadius: '5px',
})
const Icon = styled('span')({
  display: 'flex',
  alignItems: 'center',
})

interface OwnProps {
  path: string
  icon?: JSX.Element
  data: any
}

const TitleTooltip = styled(MuiTooltip)({
  '& .MuiTooltip-tooltip': {
    fontSize: FontSize.X_LARGE,
    maxWidth: '600px',
    marginTop: '3px',
  },
})

const WbsItemTitle = (props: OwnProps) => {
  const { path, icon, data } = props
  const [editing, setEditing] = useState<boolean>(false)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  useEffect(() => {
    setTitle(objects.getValue(data, path))
  }, [path, data])
  const updateTitle = useCallback(
    (newValue: any) => {
      setTitle(newValue || title)
      store.dispatch(applyDiff({ [path]: newValue }))
      store.dispatch(requireSave())
    },
    [path]
  )
  if (!data) return <></>
  const tooltip = !title ? '' : title
  return (
    <RootContainer>
      {icon && <Icon>{icon}</Icon>}
      {editing && (
        <ClickAwayListener onClickAway={() => setEditing(false)}>
          <TitleEditor
            defaultValue={title}
            onBlur={e => {
              setEditing(false)
              updateTitle(e.target.value)
            }}
            autoFocus={true}
            fullWidth={true}
            variant="standard"
          />
        </ClickAwayListener>
      )}
      {!editing && (
        <TitleTooltip
          title={tooltip}
          placement={'bottom-start'}
          disableHoverListener={true}
          open={tooltipOpen}
        >
          <Title onMouseLeave={() => setTooltipOpen(false)}>
            <TitleTooltipMessage
              onClick={e => setTooltipOpen(!tooltipOpen)}
              onDoubleClick={e => {
                setTooltipOpen(false)
                setEditing(true)
              }}
              variant={'subtitle1'}
            >
              {title}
            </TitleTooltipMessage>
          </Title>
        </TitleTooltip>
      )}
    </RootContainer>
  )
}

export default WbsItemTitle
