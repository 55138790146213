import { intl } from '../../../../i18n'
import { WbsItemSearchConditionKey } from './WbsItemSearchConditions/WbsItemSearchCondition'

export const getWbsItemSearchConditionItemLabel = ({
  wbsItemSearchConditionKey,
}: {
  wbsItemSearchConditionKey: WbsItemSearchConditionKey
}) => {
  return intl.formatMessage({
    id: `wbsItemSearchFilterPanel.${wbsItemSearchConditionKey}`,
  })
}
