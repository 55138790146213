import { InputAdornment, InputBase, Typography, styled } from '@mui/material'
import { intl } from '../../../../../i18n'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import Workload, { round } from '../../../../../lib/functions/workload'
import {
  parseFullNumberToHalf,
  parseToHalfNumberOrEmptyString,
} from '../../../../../utils/number'
import numeral from 'numeral'
import { colorPalette } from '../../../../style/colorPallete'

type Props = {
  workload?: Workload
  onChange: (hour: number) => void
}

export const EstimatedWorkloadItem = memo(({ workload, onChange }: Props) => {
  const [actualHourStr, setActualHourStr] = useState(
    workload?.hour.toString() || '0'
  )
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>()

  const onChangeEstimatedWorkload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const halfNum = parseToHalfNumberOrEmptyString(e.target.value)
      setActualHourStr(halfNum || '0')
    },
    []
  )

  const onBlurEstimatedWorkload = useCallback(
    e => {
      const normalizedHour = convertStringToNormalizedHour(e.target.value)
      onChange(normalizedHour)
      setActualHourStr(String(normalizedHour))
      setIsEditing(false)
    },
    [workload]
  )

  const onfocusEstimatedWorkload = useCallback(() => {
    actualHourStr === '0' && setActualHourStr('')
  }, [actualHourStr])

  const convertStringToNormalizedHour = useCallback((v: string) => {
    const inputStr = parseFullNumberToHalf(v)
    const num = numeral(inputStr).value()
    return num > 0 ? round(num) : 0
  }, [])

  const blurBySpecialKey = useCallback(e => {
    if ((e.key === 'Escape' || e.key === 'Enter') && inputRef.current) {
      inputRef.current.blur()
    }
  }, [])

  useEffect(() => {
    isEditing && inputRef.current?.focus()
  }, [isEditing])

  return isEditing ? (
    <InputBase
      inputRef={inputRef}
      type={'text'}
      value={actualHourStr}
      onFocus={onfocusEstimatedWorkload}
      onChange={onChangeEstimatedWorkload}
      onBlur={onBlurEstimatedWorkload}
      onKeyDown={blurBySpecialKey}
      endAdornment={
        <InputAdornment position="end" sx={{ marginLeft: '2px' }}>
          <Typography
            sx={{
              fontSize: '10px',
              color: colorPalette.monotone[5],
              minWidth: '20px',
            }}
          >
            {intl.formatMessage({ id: 'workloadUnit.hour' })}
          </Typography>
        </InputAdornment>
      }
      sx={{
        input: { textAlign: 'right', width: '36px' },
        padding: '3px 0 0 0',
      }}
    />
  ) : (
    <StyledRenderer
      onClick={() => setIsEditing(true)}
      sx={{ marginRight: '2px' }}
    >
      <Typography sx={{ width: '36px', textAlign: 'end' }}>
        {actualHourStr}
      </Typography>
      <Typography
        sx={{
          fontSize: '10px',
          color: colorPalette.monotone[5],
          minWidth: '20px',
        }}
      >
        {intl.formatMessage({ id: 'workloadUnit.hour' })}
      </Typography>
    </StyledRenderer>
  )
})

const StyledRenderer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  justifyContent: 'flex-end',
  padding: '3px 0 0 0',
})
