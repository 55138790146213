import {
  ColDef,
  EditableCallbackParams,
  CellClassParams,
} from 'ag-grid-community'
import { required } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { CheckBoxCellRenderer } from '../../../../containers/BulkSheetView/components/cellRenderer'
import { CheckBoxCellEditor } from '../../../../containers/BulkSheetView/components/cellEditor'

export const requiredColumnDef: ColDef = {
  ...required,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data),
  cellRenderer: params =>
    isGroupHeaderRow(params.node.data) ? undefined : (
      <CheckBoxCellRenderer {...params} />
    ),
  cellEditor: CheckBoxCellEditor,
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}
