import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import parseHtmlString, { DOMNode, Element, Text } from 'html-react-parser'
import Auth from '../../../../lib/commons/auth'
import {
  convertStringToHtmlNewLine,
  isLink,
  splitStringByHtmlLink,
} from '../../../../utils/html'
import {
  FileEntityAttributeKey,
  MentionEntityAttributeKey,
} from '../CommentEditor/lib/Converter'
import { MentionType } from '../../../../store/comments'

const Comment = styled('div')({
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  '& p': {
    margin: '0 0 !important',
    minHeight: '14px',
  },
  '& ul, ol': {
    margin: '0',
    padding: '0',
    paddingInlineStart: '.3rem !important',
    '& li': {
      marginLeft: '1.5em',
    },
  },
  '& ol li': {
    listStyleType: 'none',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    '&:first-child': {
      counterReset: 'ol0',
    },
    '&::before': {
      content: 'counter(ol0) ". "',
      counterIncrement: 'ol0',
      left: '-36px',
      position: 'absolute',
      textAlign: 'right',
      width: '30px',
    },
  },
})

const htmlToElement = ({
  commentText,
  onClickImage,
}: {
  commentText: string
  onClickImage?: (file: { url: string; name: string }) => void
}): string | JSX.Element | JSX.Element[] => {
  const html = convertStringToHtmlNewLine(commentText)
  const elements = parseHtmlString(html, {
    replace: (node: DOMNode) => {
      if (node.type === 'text') {
        let textNode = node as Text
        const splitted = splitStringByHtmlLink(textNode.data)
        return (
          <>
            {splitted.map(v => {
              if (isLink(v)) {
                return (
                  <a
                    key={`link-${v}`}
                    href={v}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {v}
                  </a>
                )
              }
              return v
            })}
          </>
        )
      }
      if (node.type === 'tag') {
        const element = node as Element
        if (element.tagName === 'img') {
          const file = {
            url: element.attributes.find(
              attr => attr.name === FileEntityAttributeKey.fileUrl
            )?.value,
            name: element.attributes.find(
              attr => attr.name === FileEntityAttributeKey.fileName
            )?.value,
            mimeType: element.attributes.find(
              attr => attr.name === FileEntityAttributeKey.fileType
            )?.value,
          }
          if (!file.url) {
            return node
          }
          return (
            <img
              src={file.url}
              className="uploaded-image"
              data-file-url={file.url}
              data-file-name={file.name}
              data-file-type={file.mimeType}
              onClick={
                onClickImage
                  ? () =>
                      onClickImage({
                        url: file.url!,
                        name: file.name!,
                      })
                  : undefined
              }
            />
          )
        }
        if (element.tagName === 'span') {
          let uuidAttr = element.attributes.find(attr => {
            return attr.name === MentionEntityAttributeKey.mentionUuid
          })
          if (!uuidAttr) {
            return node
          }
          let typeAttr = element.attributes.find(attr => {
            return attr.name === MentionEntityAttributeKey.mentionType
          })
          const mentionType = typeAttr ? typeAttr.value : MentionType.USER
          const uuid = Auth.getCurrentTenant()?.user?.uuid
          const loginUserMention =
            uuid === uuidAttr.value ||
            MentionType.PROJECT_MEMBER === mentionType
          element.attribs = {
            ...element.attribs,
            className: clsx(
              'mention-base',
              !loginUserMention && 'mention',
              loginUserMention && 'mention-login-user'
            ),
          }
          return element
        }
      }
    },
  })
  return elements
}

const CommentMessage = ({
  commentText,
  onClickImage,
}: {
  commentText: string
  onClickImage?: (file: { url: string; name: string }) => void
}) => {
  return (
    <Box flexDirection="column">
      <Comment>{htmlToElement({ commentText, onClickImage })}</Comment>
    </Box>
  )
}

export default CommentMessage
