import { useCallback, useEffect, useState } from 'react'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { parse } from '../../../../../lib/commons/i18nLabel'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../../../lib/functions/customEnumValue'
import projectMemberApi from '../../../../../lib/functions/projectMember'
import sprintApi from '../../../../../lib/functions/sprint'
import teamApi from '../../../../../lib/functions/team'
import ticketListApi from '../../../../../lib/functions/ticketList'
import { TicketType } from '../../../../../lib/functions/ticket'
import { getRiskSelectColumnOptions } from './risk'
import { getRefinementSelectColumnOptions } from './refinement'

export const useSelectColumnOptions = (
  projectUuid: string,
  ticketType: TicketType | undefined
) => {
  const [selectColumnOptions, setSelectColumnOptions] = useState<
    | {
        [key: string]: CustomEnumValue[]
      }
    | undefined
  >(undefined)

  const fetchStatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchSubstatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchPriorityOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchDifficultyOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_DIFFICULTY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchTeamOptions = useCallback(async projectUuid => {
    const response = await teamApi.getTeams(projectUuid)
    return response.json
  }, [])

  const fetchMemberOptions = useCallback(async projectUuid => {
    const response = await projectMemberApi.getProjectMembers({ projectUuid })
    return response.json.map(v => v.user)
  }, [])

  const fetchSprintOptions = useCallback(async () => sprintApi.searchAll(), [])

  const fetchTicketListOptions = useCallback(
    async () => ticketListApi.searchAll(),
    []
  )

  const getOptionsByTicket = useCallback(
    async (projectUuid: string, ticketType: TicketType | undefined) => {
      switch (ticketType) {
        case TicketType.RISK:
          const riskOptions = await getRiskSelectColumnOptions(projectUuid)
          return riskOptions
        case TicketType.REFINEMENT_NEW:
          const refinementOptions = await getRefinementSelectColumnOptions(
            projectUuid
          )
          return refinementOptions
        default:
          break
      }
    },
    []
  )

  const fetchSelectColumnOptions = useCallback(
    async (projectUuid: string, ticketType: TicketType | undefined) => {
      const [
        status,
        substatus,
        priority,
        difficulty,
        team,
        member,
        sprint,
        ticketList,
      ] = await Promise.all([
        fetchStatusOptions(projectUuid),
        fetchSubstatusOptions(projectUuid),
        fetchPriorityOptions(projectUuid),
        fetchDifficultyOptions(projectUuid),
        fetchTeamOptions(projectUuid),
        fetchMemberOptions(projectUuid),
        fetchSprintOptions(),
        fetchTicketListOptions(),
      ])
      const typeSpecificOptions = await getOptionsByTicket(
        projectUuid,
        ticketType
      )
      setSelectColumnOptions({
        status,
        substatus,
        priority,
        difficulty,
        team,
        member,
        sprint,
        ticketList,
        ...typeSpecificOptions,
      })
    },
    [
      fetchStatusOptions,
      fetchSubstatusOptions,
      fetchPriorityOptions,
      fetchDifficultyOptions,
      fetchTeamOptions,
      fetchMemberOptions,
      fetchSprintOptions,
      fetchTicketListOptions,
      getOptionsByTicket,
    ]
  )

  useEffect(() => {
    fetchSelectColumnOptions(projectUuid, ticketType)
  }, [projectUuid, ticketType, fetchSelectColumnOptions])

  const clearSelectColumnOptions = useCallback(
    () => setSelectColumnOptions(undefined),
    []
  )

  return {
    selectColumnOptions,
    clearSelectColumnOptions,
  }
}
