let store: any = undefined

export default async () => {
  if (store) {
    return store
  }
  // Load initialized store module dynamically to prevent initializing it unexpectedly.
  store = (await import('.')).default
  return store
}
