import _ from 'lodash'
import SearchCondition, { SearchConditionProps } from '.'
import { InputCell } from '../../../../containers/DataCell'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import { CUSTOM_ENUM_NONE } from '../../../../../lib/commons/customEnum'

type Option = {
  code: string
  name: string
  iconUrl?: string
}

interface Props extends SearchConditionProps {
  value: string[]
  options: Option[]
  renderOption?: (option: Option) => JSX.Element
  none?: boolean
}

export const CheckBoxCondition = (props: Props) => {
  if (_.isEmpty(props.options)) {
    return <></>
  }
  const options = [...props.options]
  if (props.none) {
    options.push({
      code: CUSTOM_ENUM_NONE,
      name: intl.formatMessage({ id: 'none' }),
      iconUrl: '',
    })
  }
  return (
    <SearchCondition conditionKey={props.conditionKey}>
      <InputCell align="left" colSpan={props.colSpan}>
        <FormGroup
          sx={{
            display: 'inline-block',
            overflowY: 'auto',
            width: '100%',
            maxHeight: '84px',
          }}
        >
          {options.map(option => (
            <CheckBox
              key={`${props.conditionKey}-${option.code}`}
              option={option}
              checked={props.value.includes(option.code)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  props.onChange([...props.value, option.code])
                } else {
                  props.onChange(props.value.filter(v => v !== option.code))
                }
              }}
              renderOption={props.renderOption}
            />
          ))}
        </FormGroup>
      </InputCell>
    </SearchCondition>
  )
}

const CheckBox = (props: {
  option: Option
  checked: boolean
  onChange: (value) => void
  renderOption?: (option: Option) => JSX.Element
}) => {
  return (
    <FormControlLabel
      label={
        props.renderOption ? (
          props.renderOption(props.option)
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {props.option.iconUrl && (
              <img
                src={props.option.iconUrl}
                style={{ verticalAlign: 'middle' }}
              />
            )}
            <Typography
              sx={{
                fontSize: FontSize.SMALL,
                fontWeight: FontWeight.BOLD,
              }}
            >
              {props.option.name}
            </Typography>
          </Box>
        )
      }
      sx={{ width: '125px', overflow: 'hidden' }}
      control={
        <Checkbox
          color="default"
          name={props.option.name}
          checked={props.checked}
          onChange={props.onChange}
          sx={{
            padding: '3px 3px 3px 9px',
          }}
        />
      }
    />
  )
}
