import styled from 'styled-components'
import { StatusKanbanSearchCondition } from '../../hooks/useSearchCondition'
import { intl } from '../../../../../i18n'
import { useCallback, useContext } from 'react'
import KanbanContext from '../../KanbanContext'
import { Button } from '../../../../components/buttons'
import { useDialogState } from '../../../../components/dialogs/useDialogState'
import { colorPalette } from '../../../../style/colorPallete'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { Typography } from '@mui/material'
import { FontSize } from '../../../../../styles/commonStyles'
import SearchConditionSelect from './SearchConditionSelect'
import { DateRangeItem } from '../Card/DateRangeItem'
import { DateTermVO } from '../../../../../domain/value-object/DateTermVO'
import CancelConfirmDialog from '../../../../components/dialogs/CancelConfirmDialog'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import Filters from '../Filter'
import { FilterModel } from '../Filter/filterModel'
import { SortConfig } from '../../hooks/useSort'
import { SortButton } from '../Sort'
import { ProjectPlanBreadcrumbs } from '../../../../components/navigation/project-plan-breadcrumbs'
import { getWbsItemUuidByProjectPlanUuid } from '../../../../../lib/functions/projectPlan'
import { StoredWbsItemSearchCondition } from '../../../../../services/model/storedWbsItemSearchCondition'
import { useWbsItemSearchConditionSerializeService } from '../../../../../services/transform-service/wbsItemSearchConditionSerializeService'
import { toUrlQuery } from '../../../../../lib/commons/api'

type Props = {
  searchCondition: StatusKanbanSearchCondition
  onChange: (patch: Partial<StatusKanbanSearchCondition>) => void
  onCancel: () => void
  filterModels: FilterModel[]
  updateFilterModel: (filterModel: FilterModel) => void
  applySort: (sortConfig: SortConfig) => void
  rootProjectPlanUuid?: string
}

const HeaderComponent = ({
  searchCondition,
  onChange,
  onCancel,
  filterModels,
  updateFilterModel,
  applySort,
  rootProjectPlanUuid,
}: Props) => {
  const context = useContext(KanbanContext)
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()
  const hasRequiredSaveData = useSelector<AllState, boolean>(
    state => state.hasRequiredSaveData.hasRequiredSaveData
  )

  const onReload = useCallback(() => {
    if (hasRequiredSaveData) {
      openCancelDialog()
    } else {
      onCancel()
    }
  }, [hasRequiredSaveData, onCancel])
  const onTeamChange = useCallback((uuid?: string) => {
    onChange({ teamUuid: uuid })
  }, [])
  const onScheduledEndDateChange = useCallback((v?: DateTermVO) => {
    onChange({ scheduledEndDate: v })
  }, [])

  const transformer = useWbsItemSearchConditionSerializeService()
  const switchRootWbsItem = useCallback(
    async (newProjectPlanRootUuid: string | undefined) => {
      const newRootUuid = newProjectPlanRootUuid
        ? await getWbsItemUuidByProjectPlanUuid(newProjectPlanRootUuid)
        : undefined
      const plainObject: StoredWbsItemSearchCondition & { rootUuid?: string } =
        transformer.fromVoToStoredObject(searchCondition)
      plainObject.rootUuid = newRootUuid
      const query = toUrlQuery(plainObject)

      onChange({ rootUuid: newRootUuid })
      history.replaceState(undefined, '', `?${query}`)
    },
    [searchCondition]
  )

  return (
    <HeaderRoot>
      <ProjectPlanBreadcrumbs
        rootProjectPlanUuid={rootProjectPlanUuid}
        onSelectBreadcrumb={switchRootWbsItem}
      />
      <OtherHeaderArea>
        <ReloadButton onClick={onReload}>
          <ReloadIcon />
        </ReloadButton>
        <SearchConditionArea>
          <StyledSearchCondition>
            <SearchConditionLabel>
              {intl.formatMessage({ id: 'wbsItemSearchFilterPanel.team' })}
            </SearchConditionLabel>
            <SearchConditionSelect
              value={searchCondition.teamUuid}
              options={context.teams}
              onChange={onTeamChange}
            />
          </StyledSearchCondition>
          <StyledSearchCondition>
            <SearchConditionLabel>
              {intl.formatMessage({
                id: 'wbsItemSearchFilterPanel.scheduledEndDate',
              })}
            </SearchConditionLabel>
            <SearchConditionDateRange>
              <DateRangeItem
                value={searchCondition.scheduledEndDate}
                onChange={onScheduledEndDateChange}
              />
            </SearchConditionDateRange>
          </StyledSearchCondition>
        </SearchConditionArea>
        <Filters
          filterModels={filterModels}
          updateFilterModel={updateFilterModel}
        />
        <SortButton applySort={applySort} />
      </OtherHeaderArea>
      <CancelConfirmDialog
        open={showCancelDialog}
        onConfirm={() => {
          closeCancelDialog()
          onCancel()
        }}
        onClose={closeCancelDialog}
      />
    </HeaderRoot>
  )
}

export default HeaderComponent

const HeaderRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  padding: '8px 8px 0',
  gap: '16px',
  overflowX: 'auto',
  minHeight: '96px',
})

const OtherHeaderArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  minWidth: 'fit-content',
})

const ReloadButton = styled(Button)({
  background: colorPalette.monotone[1],
  border: `1px solid ${colorPalette.monotone[3]}`,
  borderRadius: '50%',
  height: '28px',
  width: '28px',
  padding: '5px 8px',
  cursor: 'pointer',
  '&:hover': { background: colorPalette.monotone[1] },
})

const SearchConditionArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '16px',
  minWidth: 'fit-content',
})

const StyledSearchCondition = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  minWidth: 'fit-content',
})

const SearchConditionLabel = styled(Typography)({
  color: colorPalette.monotone[5],
  fontSize: FontSize.MEDIUM,
  minWidth: 'fit-content',
})

const SearchConditionDateRange = styled('div')({
  minWidth: 'fit-content',
  padding: '4px 8px',
  backgroundColor: colorPalette.monotone[0],
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
})
