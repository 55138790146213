import { useCallback, useEffect, useMemo, useState } from 'react'
import { useVersionReportVersionRepository } from '../../../../services/adaptors/versionReportVersionRepositoryAdaptor'
import {
  VersionReportVersionEntity,
  VersionReportVersionPageContent,
  VersionReportVersionPageEntity,
} from '../../../../domain/entity/version-report/VersionReportVersionEntity'
import { VersionReportPageConfig } from '../../../../domain/entity/version-report/VersionReportTemplateEntity'

export type UseCurrentVersionReturnValue = ReturnType<typeof useCurrentVersion>
export type CurrentReport = {
  versionUuid: string | undefined
  updateVersionUuid: (v: string | undefined) => void
  currentVersion: VersionReportVersionEntity | undefined
  currentPage: VersionReportVersionPageEntity | undefined
}
export const useCurrentVersion = () => {
  const [versionUuid, setVersionUuid] = useState<string | undefined>()
  const [currentVersion, setCurrentVersion] = useState<
    VersionReportVersionEntity | undefined
  >()
  const { fetch, deleteVersion } = useVersionReportVersionRepository()
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
  const currentPage = useMemo(() => {
    if (!currentVersion) return undefined
    return currentVersion.pages[currentPageIndex]
  }, [currentPageIndex, currentVersion])
  useEffect(() => {
    const fn = async () => {
      if (!versionUuid) {
        setCurrentPageIndex(0)
        return
      }
      const response = await fetch(versionUuid)
      setCurrentVersion(response)
      setCurrentPageIndex(0)
    }
    fn()
  }, [fetch, versionUuid])

  const updatePage = useCallback(
    <
      K extends keyof VersionReportVersionPageEntity,
      V extends VersionReportVersionPageEntity[K]
    >(
      path: K,
      value: V
    ) => {
      setCurrentVersion(prev => {
        if (!prev) return prev
        const newPages = prev.pages.map((page, index) => {
          if (index === currentPageIndex) {
            const newPage = {
              ...page,
            }
            newPage[path] = value
            return newPage
          }
          return page
        })
        return {
          ...prev,
          pages: newPages,
        }
      })
    },
    [currentPageIndex]
  )
  const updatePageTitle = useCallback(
    (newValue: string) => {
      updatePage('pageTitle', newValue)
    },
    [updatePage]
  )
  const updateLeadSentence = useCallback(
    (newValue: string) => {
      updatePage('leadSentence', newValue)
    },
    [updatePage]
  )
  const updatePageConfig = useCallback(
    <
      K extends keyof VersionReportPageConfig,
      T extends VersionReportPageConfig[K]
    >(
      path: K,
      value: T
    ) => {
      setCurrentVersion(prev => {
        if (!prev) return prev
        const newPages = prev.pages.map((page, index) => {
          if (index === currentPageIndex) {
            const newConfig = {
              ...page.config,
            }
            newConfig[path] = value
            const newPage = {
              ...page,
              config: newConfig,
            }
            return newPage
          }
          return page
        })
        return {
          ...prev,
          pages: newPages,
        }
      })
    },
    [currentPageIndex]
  )

  const updatePageContent = useCallback(
    <
      K extends keyof VersionReportVersionPageContent,
      T extends VersionReportVersionPageContent[K]
    >(
      path: K,
      value: T
    ) => {
      setCurrentVersion(prev => {
        if (!prev) return prev
        const newPages = prev.pages.map((page, index) => {
          if (index === currentPageIndex) {
            const newContent = {
              ...page.content,
            }
            newContent[path] = value
            const newPage = {
              ...page,
              content: newContent,
            }
            return newPage
          }
          return page
        })
        return {
          ...prev,
          pages: newPages,
        }
      })
    },
    [currentPageIndex]
  )

  const toNextPage = useCallback(() => {
    if (!currentVersion) return
    setCurrentPageIndex(prev => {
      if (prev === currentVersion.pages.length - 1) {
        return 0
      }
      return prev + 1
    })
  }, [currentVersion])
  const toPrevPage = useCallback(() => {
    if (!currentVersion) return
    setCurrentPageIndex(prev => {
      if (prev === 0) {
        return currentVersion.pages.length - 1
      }
      return prev - 1
    })
  }, [currentVersion])
  return {
    versionUuid,
    updateVersionUuid: setVersionUuid,
    currentPage,
    currentVersion,
    updatePageTitle,
    updateLeadSentence,
    updatePageConfig,
    updatePageContent,
    toNextPage,
    toPrevPage,
  }
}
