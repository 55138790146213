import React from 'react'
import WbsItemAPI from '../../../../../../../../lib/functions/wbsItem'
import ProjectAPI from '../../../../../../../../lib/functions/project'
import { ICellRendererParams } from 'ag-grid-community'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import * as ProjectPlanAPI from '../../../../../../../../lib/functions/projectPlan'
import { AgGridRowResizeDragger } from '../../../dragger/rowResizeDragger'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

const SequenceNoLink = ({ rowNumber, onClick }) => (
  <a className="sevend-ag-cell-sequence-no__link" onClick={onClick}>
    {rowNumber}
  </a>
)

const SequenceNoLabel = ({ rowNumber }) => (
  <p className="sevend-ag-cell-sequence-no__label">{rowNumber}</p>
)

interface OwnProps {
  value: string
  data: {
    uuid: string
    wbsItemType: WbsItemTypeVO
    wbsItem?: {
      uuid: string
      wbsItemType: WbsItemTypeVO
    }
    task: {
      wbsItemType: WbsItemTypeVO
    }
    isAdded: boolean
  }
  viewMeta: ViewMeta
  uiMeta: FunctionProperty
}

type Props = OwnProps & ICellRendererParams

const WbsItemSequenceNoCellRenderer = (props: Props) => {
  const openProjectPlan = async () => {
    const wbsItemUuid = props.data.wbsItem?.uuid || props.data.uuid
    const wbsItem = await WbsItemAPI.getByUuid(wbsItemUuid)
    if (wbsItem) {
      const [projectPlanUuid, getProjectResponse] = await Promise.all([
        ProjectPlanAPI.getProjectPlanUuidByWbsItemUuid(wbsItemUuid),
        ProjectAPI.getDetail({ uuid: wbsItem.projectUuid }),
      ])
      const project = getProjectResponse.json
      if (project) {
        window.open(
          `${window.location.origin}/projectPlan/${project.code}?treeRootUuid=${projectPlanUuid}`
        )
      }
    }
  }

  const canOpenProjectPlan = !props.data.isAdded
  const rowNumber = props.value

  return (
    <div className="sevend-ag-cell-sequence-no">
      {canOpenProjectPlan && (
        <SequenceNoLink rowNumber={rowNumber} onClick={openProjectPlan} />
      )}
      {!canOpenProjectPlan && <SequenceNoLabel rowNumber={rowNumber} />}
      {!props.data?.isTotal && (
        <AgGridRowResizeDragger node={props.node} api={props.api} />
      )}
    </div>
  )
}

export default WbsItemSequenceNoCellRenderer
