import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dropzone from 'react-dropzone'
import React from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import AlertDialog from './AlertDialog'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibraryRounded'
import { intl } from '../../../i18n'
import mime from 'mime'

// Styles
const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})
const DialogTitle = styled(MuiDialogTitle)({
  width: '500px',
  padding: '0px 0px',
  margin: '10px',
})
const DialogContent = styled(MuiDialogContent)({
  backgroundColor: 'lightgrey',
  width: '100%',
  height: '300px',
  padding: '0px 0px',
})
const DropzoneContent = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})
const PhotoIcon = styled(PhotoLibraryIcon)({
  width: '100px',
  height: '100px',
})

interface Props {
  open: boolean
  title?: string
  message: any
  dropAcceptedHandler: Function
  multiple: boolean
  accept: string[]
  closeHandler: Function
}

interface Status {
  openMessage: boolean
  message: string
}

class FileUploadDialog extends React.PureComponent<Props, Status> {
  constructor(props: Props) {
    super(props)
    this.state = {
      openMessage: false,
      message: '',
    }
  }

  private dropAccepted = acceptedFiles => {
    this.props.dropAcceptedHandler(acceptedFiles)
  }

  private dropRejected = rejectedFiles => {
    let message = intl.formatMessage({ id: 'fileUpload.cannotUpload' })
    for (let i = 0; i < rejectedFiles.length; i++) {
      message = message + '\n' + rejectedFiles[i].path
    }
    this.setState({ message: message })
    this.setState({ openMessage: true })
  }

  private closeDialog = () => {
    this.props.closeHandler()
  }

  private closeMessage = () => {
    this.setState({ openMessage: false })
    this.setState({ message: '' })
  }

  render() {
    return (
      <RootContainer>
        <Dialog
          open={this.props.open}
          onClose={this.closeDialog}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle id="simple-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <Dropzone
              onDropAccepted={this.dropAccepted}
              onDropRejected={this.dropRejected}
              multiple={this.props.multiple}
              accept={this.props.accept?.map(v => mime.getType(v)).join(',')}
            >
              {({ getRootProps, getInputProps }) => (
                <DropzoneContent {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div>
                    <PhotoIcon color="action" />
                  </div>
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {this.props.message}
                  </div>
                </DropzoneContent>
              )}
            </Dropzone>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog
          isOpen={this.state.openMessage}
          message={this.state.message}
          closeButtonTitle="OK"
          closeHandler={this.closeMessage}
        />
      </RootContainer>
    )
  }
}

export default FileUploadDialog
