import {
  CellStyle,
  ColDef,
  ColGroupDef,
  RowNode,
  ValueGetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import { UserBasic } from '../../../../lib/functions/user'
import DateVO from '../../../../vo/DateVO'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import {
  ColumnType,
  CURRENT_MONTH_BACKGROUND_COLOR,
} from '../../../containers/commons/AgGrid'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { ClientSideSelectFilter } from '../../../containers/BulkSheetView/components/filter'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import {
  MemberWorkMonthBody,
  ResourcePlanCrossProjectType,
  ResourcePlanCrossProjectsRow,
} from '../ResourcePlanCrossProjects'

const INTERNAL_YEARMONTH_FORMAT: string = 'YYYY/MM'
const NUMBER_DECIMAL_POINT: number = 2

export const ALERT_UPPER_LIMIT_FOR_MAN_MONTH: number = 1.0
export const COLUMN_FIELD_TOTAL: string = 'total'
export const WORKMONTHS_FIELD_PREFIX: string = 'body.memberWorkMonths.'

export const ROW_NUMBER_COL_DEF: ColDef = {
  field: 'rowNumber',
  type: [ColumnType.sequenceNo],
  resizable: false,
  cellRenderer: SequenceNoCellRenderer,
  valueGetter: params => {
    if (params.data.isTotal) {
      return 'Total'
    }
    return getRowNumber(params.node)
  },
}

export const INFORMATION_COLUMN_GROUP_DEF_BASE = {
  groupId: 'information',
  headerName: intl.formatMessage({
    id: 'resourcePlan.crossProjects.information',
  }),
}

export const MEMBER_COLUMN_GROUP_DEF_BASE = {
  groupId: 'member',
  headerName: intl.formatMessage({
    id: 'resourcePlan.crossProjects.member',
  }),
}

const getManMonth = (workMonth: MemberWorkMonthBody | undefined): number => {
  if (workMonth && workMonth.manMonth) {
    const manMonth = Number(workMonth.manMonth)
    return Number.isNaN(manMonth) ? 0 : manMonth
  }
  return 0
}

export const generateTotalColumnDef = (
  summaryTargetRowType: ResourcePlanCrossProjectType,
  getRowGroupColumnKey: (
    row: ResourcePlanCrossProjectsRow
  ) => string | undefined
): ColDef => {
  return {
    field: COLUMN_FIELD_TOTAL,
    headerName: intl.formatMessage({
      id: 'resourcePlan.crossProjects.total',
    }),
    width: 85,
    hide: false,
    pinned: true,
    cellRenderer: NumberCellRenderer,
    cellRendererParams: {
      numberWithCommas: true,
      decimalPoints: NUMBER_DECIMAL_POINT,
    },
    valueGetter: (params: ValueGetterParams) => {
      const row: ResourcePlanCrossProjectsRow = params.data
      if (!params.api || !row) return 0
      if (row.isTotal) {
        let grandTotal = 0
        params.api.forEachNode(rowNode => {
          const nodeData: ResourcePlanCrossProjectsRow = rowNode.data
          if (
            nodeData &&
            !nodeData.isTotal &&
            nodeData.type === summaryTargetRowType
          ) {
            nodeData.body.memberWorkMonths?.forEach(value => {
              grandTotal += getManMonth(value)
            })
          }
        })
        return grandTotal
      }
      let total = 0
      if (row.type === row.rowGroupColumnType?.toString()) {
        const uuid = getRowGroupColumnKey(row)
        params.api.forEachNode(node => {
          if (!node || !node.data) return
          const nodeData: ResourcePlanCrossProjectsRow = node.data
          if (
            getRowGroupColumnKey(nodeData) === uuid &&
            nodeData.type === summaryTargetRowType
          ) {
            nodeData.body?.memberWorkMonths?.forEach(work => {
              total += getManMonth(work)
            })
          }
        })
      } else {
        row.body.memberWorkMonths?.forEach(value => {
          total += getManMonth(value)
        })
      }
      return total
    },
    floatingFilter: true,
    filter: 'clientSideNumberFilter',
    cellClass: 'numberStyle',
  }
}

const userBasicComparator = (
  valueA: string | UserBasic,
  valueB: string | UserBasic
): number => {
  const strA: string = typeof valueA === 'string' ? valueA : valueA?.name || ''
  const strB: string = typeof valueB === 'string' ? valueB : valueB?.name || ''
  return strA.localeCompare(strB)
}

export const CHANGE_LOG_COLUMN_GROUP: ColGroupDef = {
  headerName: intl.formatMessage({ id: 'changeLog' }),
  children: [
    {
      field: 'revision',
      headerName: intl.formatMessage({ id: 'revision' }),
      hide: true,
      width: 90,
      floatingFilter: true,
      filter: 'clientSideTextFilter',
    },
    {
      field: 'createdBy',
      headerName: intl.formatMessage({ id: 'createdBy' }),
      hide: true,
      width: 150,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.createdBy
      },
      type: [ColumnType.autocomplete],
      cellRendererParams: {
        uiMeta: {},
      },
      floatingFilter: true,
      filter: ClientSideSelectFilter,
      comparator: userBasicComparator,
    },
    {
      field: 'createdAt',
      headerName: intl.formatMessage({ id: 'createdAt' }),
      hide: true,
      width: 120,
      type: [ColumnType.dateTime],
      floatingFilter: true,
      cellClass: 'dateTimeStyle',
    },
    {
      field: 'updatedBy',
      headerName: intl.formatMessage({ id: 'updatedBy' }),
      hide: true,
      width: 150,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.updatedBy
      },
      type: [ColumnType.autocomplete],
      cellRendererParams: {
        uiMeta: {},
      },
      floatingFilter: true,
      filter: ClientSideSelectFilter,
      comparator: userBasicComparator,
    },
    {
      field: 'updatedAt',
      headerName: intl.formatMessage({ id: 'updatedAt' }),
      hide: true,
      width: 120,
      type: [ColumnType.dateTime],
      floatingFilter: true,
      cellClass: 'dateTimeStyle',
    },
  ],
}

export const iconShowCheckUser = (type: string): boolean => {
  return type !== ResourcePlanCrossProjectType.MEMBER ? false : true
}

export const generateWorkMonthColDef = (
  yearMonth: string,
  getWorkingTimeTotal: (yearMonth: string) => string,
  summaryTargetRowType: ResourcePlanCrossProjectType,
  getRowGroupColumnKey: (
    row: ResourcePlanCrossProjectsRow
  ) => string | undefined
): ColDef => {
  return {
    colId: yearMonth,
    field: yearMonth,
    headerName: getWorkingTimeTotal(yearMonth),
    width: 78,
    suppressMovable: true,
    valueGetter: (params: ValueGetterParams) => {
      const row: ResourcePlanCrossProjectsRow = params.data
      if (!params.api || !row) return
      if (row.isTotal) {
        let total = 0
        params.api.forEachNode(
          (node: RowNode<ResourcePlanCrossProjectsRow>) => {
            const nodeData: ResourcePlanCrossProjectsRow | undefined = node.data
            if (
              nodeData &&
              !nodeData.isTotal &&
              nodeData.body.user &&
              nodeData.type === summaryTargetRowType
            ) {
              const memberWorkMonth = nodeData.body.memberWorkMonths?.find(
                cell => cell.yearMonth === params.colDef.colId!
              )
              total += getManMonth(memberWorkMonth)
            }
          }
        )
        return total
      }
      if (row.type === row.rowGroupColumnType?.toString()) {
        const uuid = getRowGroupColumnKey(row)
        let total = 0
        let isSum = false
        params.api.forEachNode(node => {
          const nodeData: ResourcePlanCrossProjectsRow | undefined = node.data
          if (!nodeData) return 0
          if (
            getRowGroupColumnKey(node.parent?.data) === uuid &&
            nodeData.type === summaryTargetRowType
          ) {
            const work = nodeData.body.memberWorkMonths?.find(
              cell => cell.yearMonth === params.colDef.colId!
            )
            total += getManMonth(work)
            if (!isSum) {
              isSum = work ? true : false
            }
          }
        })
        return isSum ? total : undefined
      }
      const memberWorkMonth =
        !params.node?.group && !!row.body.memberWorkMonths
          ? row.body.memberWorkMonths.find(
              cell =>
                cell.yearMonth === params.colDef.colId! &&
                cell.manMonth !== undefined
            )
          : undefined
      return memberWorkMonth ? memberWorkMonth.manMonth : undefined
    },
    cellRenderer: NumberCellRenderer,
    cellRendererParams: {
      numberWithCommas: true,
      decimalPoints: NUMBER_DECIMAL_POINT,
    },
    floatingFilter: true,
    filter: 'clientSideNumberFilter',
    cellStyle: _ => {
      let style: CellStyle = {
        justifyContent: 'flex-end',
        color: 'black',
      }
      const isCurrentMonth =
        yearMonth === DateVO.now().format(INTERNAL_YEARMONTH_FORMAT)
      if (isCurrentMonth) {
        style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
      }
      return style
    },
    cellClass: 'numberStyle',
  }
}
