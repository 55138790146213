import {
  Box,
  Button as MuiButton,
  Dialog,
  InputBase,
  Typography,
  DialogActions,
  DialogContent,
  ClickAwayListener,
  Avatar,
} from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { WbsItemSearchSearchCondition } from '../useSearchCondition'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { useOnSingleValueChangedGenerator } from './WbsItemSearchSearchHeader/useOnSingleValueChangedGenerator'
import { FocusableCellProps } from '../../../components/tables/table-cells'
import { Button } from '../../../components/buttons'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { SelectOption } from '../../../model/selectOption'
import WbsItemSearchCheckboxSelectConditionPopper from '../WbsItemSearchTypesCheckboxSelectCondition'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { intl } from '../../../../i18n'
import { TicketType } from '../../../../lib/functions/ticket'
import { getWbsItemStatusDeepColorCode } from '../../../../lib/functions/wbsItem'
import CodeVO from '../../../../domain/value-object/CodeVO'
import { customEnumRepository } from '../../../../domain/repository'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'
import { useTeamRepository } from '../../../../services/adaptors/teamRepositoryAdaptor'
import SearchConditionSelectPopper from './SearchConditionSelectPopper'
import projectMember from '../../../../lib/functions/projectMember'
import sprint, { SprintStatus } from '../../../../lib/functions/sprint'
import DateInput from '../../../components/editors/input/DateInput'
import { DateVO } from '../../../../domain/value-object/DateVO'
import { DateTermVO } from '../../../../domain/value-object/DateTermVO'
import DateTimeInput from '../../../components/editors/input/DateTimeInput'
import DateTimeVO from '../../../../domain/value-object/DateTimeVO'
import DateTimeTermVO from '../../../../domain/value-object/DateTimeTermVO'
import SaveSearchConditionListDialog from './SaveSearchConditionListDialog'
import { SearchConditionAndColumnState } from '../../../../services/model/searchConditionAndColumnState'
import { PermanentUiState } from '../../../../services/model/uiState'
import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../../../store'
import { fetchTagsByProject } from '../../../../store/tag'
import { TagForWbsItem } from '../../../../lib/functions/tag'
import user, { UserDetail } from '../../../../lib/functions/user'

type Props = {
  open: boolean
  onClose: () => void
  searchCondition: WbsItemSearchSearchCondition
  onChangeCondition: (updated: Partial<WbsItemSearchSearchCondition>) => void
  onEnter: (() => void) | undefined
  resetCondition: () => void
  search: () => void
  filteringInput: string
  setFilteringInput: (newValue: string) => void
  onSelectState: (
    selected: PermanentUiState<SearchConditionAndColumnState>
  ) => void
  functionUuid: string
}
const WbsSearchSearchConditionDialog = ({
  open,
  onClose,
  onChangeCondition,
  searchCondition,
  onEnter,
  resetCondition,
  search,
  filteringInput,
  setFilteringInput,
  onSelectState,
  functionUuid,
}: Props) => {
  const { wbsItemTypes, ticketTypes, projectUuid } = useProjectPrivateContext()

  const { onChangeValueGenerator, onChangeBlankGenerator } =
    useOnSingleValueChangedGenerator(searchCondition, onChangeCondition)

  const onTeamChange = onChangeValueGenerator('teamUuid')
  const onSprintChange = onChangeValueGenerator('sprintUuid')
  const onTagChange = onChangeValueGenerator('tagUuids')

  const [priorityOptions, setPriorityOptions] = useState<SelectOption[]>([])
  const [tagOptions, setTagOptions] = useState<SelectOption[]>([])

  const teamRepository = useTeamRepository()

  const [openSavedSearchConditionPanel, setOpenSavedSearchConditionPanel] =
    useState<boolean>(false)

  const handleSavedSearchConditionPanelClose = () => {
    setOpenSavedSearchConditionPanel(false)
  }

  const handleSavedSearchConditionPanelOpen = () => {
    setOpenSavedSearchConditionPanel(true)
  }

  const fetchTeam = useCallback(
    async (value: string) => {
      try {
        const team = await teamRepository.fetch(value)
        return team
          ? {
              value: team.uuid,
              name: team.displayName,
              iconUrl: team.iconUrl,
            }
          : undefined
      } catch (err) {
        return undefined
      }
    },
    [teamRepository]
  )
  const fetchTeams = useCallback(async () => {
    if (!projectUuid) return []
    const teams = await teamRepository.fetchList(projectUuid)
    return teams.map(team => ({
      value: team.uuid,
      name: team.displayName,
      iconUrl: team.iconUrl,
    }))
  }, [teamRepository, projectUuid])

  const fetchProjectMember = useCallback(async (value: string) => {
    try {
      const response = await user.getDetail({ uuid: value })
      const userDetail = response.json as UserDetail
      return {
        value: userDetail.uuid,
        name: userDetail.name,
        iconUrl: userDetail.iconUrl,
      }
    } catch (err) {
      return undefined
    }
  }, [])
  const fetchProjectMembers = useCallback(async () => {
    if (!projectUuid) return []
    const projectMembers = await projectMember.getProjectMembers({
      projectUuid,
    })
    return projectMembers.json.map(projectMember => ({
      value: projectMember.user.uuid,
      name: projectMember.user.name,
      iconUrl: projectMember.user.iconUrl,
    }))
  }, [projectUuid])

  const getSortIndex = (status: SprintStatus) => {
    const sortedStatusList = [
      SprintStatus.INPROGRESS,
      SprintStatus.STANDBY,
      SprintStatus.FINISHED,
      SprintStatus.CANCELED,
    ]
    return sortedStatusList.findIndex(v => v === status)
  }
  const fetchSprint = useCallback(async (value: string) => {
    if (!value) return
    const sprintDetail = await sprint.getSingleByUuid(value)
    return sprintDetail
      ? {
          value: sprintDetail.uuid,
          name: sprintDetail.name,
          groupBy: sprintDetail.status,
        }
      : undefined
  }, [])
  const fetchSprints = useCallback(async () => {
    if (!searchCondition.teamUuid) return []
    const sprints = await sprint.searchByText(searchCondition.teamUuid)
    return sprints
      .sort((a, b) => getSortIndex(a.status) - getSortIndex(b.status))
      .map(v => ({ value: v.uuid, name: v.name, groupBy: v.status }))
  }, [searchCondition.teamUuid])

  const typesOptions = useMemo(() => {
    return wbsItemTypes.getAll().map(vo => ({
      name: vo.name,
      value: vo.code,
      iconUrl: vo.iconUrl,
    }))
  }, [wbsItemTypes])

  const ticketTypesOptions = useMemo(() => {
    const selectOptions: SelectOption[] = ticketTypes.map(t => ({
      name: t.name,
      value: t.code,
      iconUrl: t.iconUrl,
    }))
    selectOptions.push({
      name: intl.formatMessage({ id: 'none' }),
      value: 'NONE',
    })
    return selectOptions
  }, [ticketTypes])

  const statusOptions = useMemo(() => {
    return Object.keys(WbsItemStatus).map(k => ({
      name: k,
      value: k,
    }))
  }, [])

  useEffect(() => {
    if (!projectUuid) return
    const getAndSetOptions = async () => {
      const customEnum = await customEnumRepository.getByCodeAndGroupKeys(
        new CodeVO('WBS_PRIORITY'),
        [projectUuid]
      )
      const values = customEnum.values.map(v => v.toPlainValue())
      if (!values.some(v => v.value === CUSTOM_ENUM_NONE)) {
        values.push({
          name: intl.formatMessage({
            id: 'none',
          }),
          value: CUSTOM_ENUM_NONE,
          foregroundColor: undefined,
          backgroundColor: undefined,
          iconUrl: undefined,
        })
      }
      setPriorityOptions(values)
    }
    getAndSetOptions()
  }, [projectUuid])

  const tags = useSelector<AllState, TagForWbsItem[] | undefined>(state =>
    projectUuid ? state.tag[projectUuid] : undefined
  )
  useEffect(() => {
    if (!tags || tags.length === 0) return
    setTagOptions(
      tags.map(v => ({
        value: v.uuid,
        name: v.name,
        backgroundColor: v.backgroundColor,
      }))
    )
  }, [tags])
  const dispatch = useDispatch()
  useEffect(() => {
    if (!tags || tags.length === 0) {
      dispatch(fetchTagsByProject(projectUuid))
    }
  }, [projectUuid, tags])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiPaper-root': { maxWidth: '800px' },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            height: '500px',
            gap: '10px',
          }}
        >
          <SearchConditionTextBox
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.displayName',
            })}
            placeholder={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.input.displayName',
            })}
            value={searchCondition.displayName}
            onChange={onChangeValueGenerator('displayName')}
            onEnter={onEnter}
          />
          <SearchConditionTextBox
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.keyword',
            })}
            placeholder={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.input.keyword',
            })}
            value={searchCondition.searchText}
            onChange={onChangeValueGenerator('searchText')}
            onEnter={onEnter}
          />
          <SearchConditionTextBox
            title={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.code' })}
            placeholder={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.input.code',
            })}
            value={searchCondition.code}
            onChange={onChangeValueGenerator('code')}
            onEnter={onEnter}
          />
          <SearchConditionTextBox
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.description',
            })}
            placeholder={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.input.description',
            })}
            value={searchCondition.description}
            onChange={onChangeValueGenerator('description')}
            onEnter={onEnter}
          />
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.types}
            options={typesOptions}
            onChange={onChangeValueGenerator('types')}
            title={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.types' })}
          />
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.ticketTypes}
            options={ticketTypesOptions}
            onChange={onChangeValueGenerator('ticketTypes')}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.ticketTypes',
            })}
          />
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.status}
            options={statusOptions}
            onChange={onChangeValueGenerator('status')}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.status',
            })}
          />
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.priority}
            options={priorityOptions}
            onChange={onChangeValueGenerator('priority')}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.priority',
            })}
          />
          <SearchConditionSelectBox
            value={searchCondition.teamUuid}
            fetchOptions={fetchTeams}
            fetchSelectedOption={fetchTeam}
            title={intl.formatMessage({ id: 'wbsItemSearchFilterPanel.team' })}
            onChange={(newValue: string | undefined) => {
              onTeamChange(newValue)
              onSprintChange(undefined)
            }}
            isDisplayAvatar={false}
          />
          <SearchConditionSelectBox
            value={searchCondition.accountableUuid}
            fetchOptions={fetchProjectMembers}
            fetchSelectedOption={fetchProjectMember}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.accountable',
            })}
            onChange={onChangeValueGenerator('accountableUuid')}
            isDisplayAvatar={true}
          />
          <SearchConditionSelectBox
            value={searchCondition.responsibleUuid}
            fetchOptions={fetchProjectMembers}
            fetchSelectedOption={fetchProjectMember}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.responsible',
            })}
            onChange={onChangeValueGenerator('responsibleUuid')}
            isDisplayAvatar={true}
          />
          <SearchConditionSelectBox
            value={searchCondition.assigneeUuid}
            fetchOptions={fetchProjectMembers}
            fetchSelectedOption={fetchProjectMember}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.assignee',
            })}
            onChange={onChangeValueGenerator('assigneeUuid')}
            isDisplayAvatar={true}
          />
          <SearchConditionSelectBox
            value={searchCondition.sprintUuid}
            fetchOptions={fetchSprints}
            fetchSelectedOption={fetchSprint}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.sprint',
            })}
            onChange={onSprintChange}
            isDisplayAvatar={false}
            teamUuid={searchCondition.teamUuid}
            isSprintSearchCondition={true}
          />
          <SearchConditionSelectBoxCheckmarks
            value={searchCondition.tagUuids}
            options={tagOptions}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.tags',
            })}
            onChange={onTagChange}
          />
          <SearchConditionDate
            value={searchCondition.scheduledStartDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledStartDate',
            })}
            onChange={onChangeValueGenerator('scheduledStartDate')}
            blank={!!searchCondition.blank?.scheduledStartDate}
            onChangeBlank={onChangeBlankGenerator('scheduledStartDate')}
          />
          <SearchConditionDate
            value={searchCondition.scheduledEndDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.scheduledEndDate',
            })}
            onChange={onChangeValueGenerator('scheduledEndDate')}
            blank={!!searchCondition.blank?.scheduledEndDate}
            onChangeBlank={onChangeBlankGenerator('scheduledEndDate')}
          />
          <SearchConditionDate
            value={searchCondition.actualStartDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualStartDate',
            })}
            onChange={onChangeValueGenerator('actualStartDate')}
            blank={!!searchCondition.blank?.actualStartDate}
            onChangeBlank={onChangeBlankGenerator('actualStartDate')}
          />
          <SearchConditionDate
            value={searchCondition.actualEndDate}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.actualEndDate',
            })}
            onChange={onChangeValueGenerator('actualEndDate')}
            blank={!!searchCondition.blank?.actualEndDate}
            onChangeBlank={onChangeBlankGenerator('actualEndDate')}
          />
          <SearchConditionSelectBox
            value={searchCondition.createdByUuid}
            fetchOptions={fetchProjectMembers}
            fetchSelectedOption={fetchProjectMember}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.createdBy',
            })}
            onChange={onChangeValueGenerator('createdByUuid')}
            isDisplayAvatar={true}
          />
          <SearchConditionDateTime
            value={searchCondition.createdAt}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.createdAt',
            })}
            onChange={onChangeValueGenerator('createdAt')}
          />
          <SearchConditionSelectBox
            value={searchCondition.updatedByUuid}
            fetchOptions={fetchProjectMembers}
            fetchSelectedOption={fetchProjectMember}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.updatedBy',
            })}
            onChange={onChangeValueGenerator('updatedByUuid')}
            isDisplayAvatar={true}
          />
          <SearchConditionDateTime
            value={searchCondition.updatedAt}
            title={intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.updatedAt',
            })}
            onChange={onChangeValueGenerator('updatedAt')}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '20px 10px', justifyContent: 'center' }}>
        <Button
          size="m"
          colorPattern="monotone"
          variant="outlined"
          sx={{
            cursor: 'pointer',
          }}
          onClick={resetCondition}
        >
          <ClearRoundedIcon />
          <Typography>
            {intl.formatMessage({ id: 'wbsItemSearchFilterPanel.reset' })}
          </Typography>
        </Button>
        <Button
          size="m"
          colorPattern="skyBlue"
          variant="outlined"
          sx={{ cursor: 'pointer' }}
          onClick={handleSavedSearchConditionPanelOpen}
        >
          <Typography>
            {intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.saved.searchCondition',
            })}
          </Typography>
        </Button>
        <SaveSearchConditionListDialog
          open={openSavedSearchConditionPanel}
          handleSavedSearchConditionPanelClose={
            handleSavedSearchConditionPanelClose
          }
          filteringInput={filteringInput}
          setFilteringInput={setFilteringInput}
          onSelectState={onSelectState}
          functionUuid={functionUuid}
          searchCondition={searchCondition}
          currentColumnState={[]}
        />
        <Button
          size="m"
          colorPattern="skyBlue"
          variant="filled"
          onClick={search}
          sx={{ cursor: 'pointer' }}
        >
          <Typography>
            {intl.formatMessage({ id: 'wbsItemSearchFilterPanel.search' })}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type SearchConditionBoxProps = {
  title: string
  placeholder?: string
  value?: string
  onChange: (value: string | undefined) => void
}

const SearchConditionTextBox = ({
  title,
  placeholder,
  value,
  onChange,
  onEnter,
  onBlur,
}: SearchConditionBoxProps & FocusableCellProps) => {
  const clear = useCallback(() => {
    onChange && onChange('')
  }, [])

  return (
    <Box
      sx={{
        height: '40px',
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
      }}
    >
      <Typography sx={{ color: colorPalette.monotone[4], minWidth: '80px' }}>
        {title}
      </Typography>
      <InputBase
        sx={{
          marginLeft: '20px',
          border: 'none',
          width: '400px',
          padding: '0 8px',
          color: colorPalette.monotone[10],
        }}
        value={value}
        placeholder={placeholder}
        onChange={event => {
          onChange(event.target.value ? event.target.value : '')
        }}
        onKeyPress={event => {
          if (onEnter && event.key === 'Enter') {
            onEnter()
          }
        }}
        onBlur={onBlur}
      />
      <MuiButton
        sx={{ minWidth: '20px', margin: '0 0 0 auto' }}
        onClick={clear}
      >
        <ClearRoundedIcon sx={{ color: colorPalette.monotone[4] }} />
      </MuiButton>
    </Box>
  )
}

type SelectedConditionProps = {
  options: SelectOption[]
  value: string[] | undefined
  onChange: (newValue: string[] | string | undefined) => void
}

const SelectedConditionItem = ({
  options,
  value,
  onChange,
}: SelectedConditionProps) => {
  const onChangeValue = useCallback(
    (v: any) => {
      const newValue = [...(value || [])]
      if (newValue.includes(v)) {
        newValue.splice((value || []).indexOf(v), 1)
      } else {
        newValue.push(v)
      }
      onChangeNewValue(newValue)
    },
    [value]
  )

  const onChangeNewValue = useCallback((newValueStr: string[] | undefined) => {
    if (!newValueStr) {
      onChange && onChange(undefined)
      return
    }

    onChange && onChange(newValueStr)
  }, [])

  const clear = useCallback(() => {
    if (onChange) {
      onChange('')
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      {options.map(option => (
        <Box
          key={option.value}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {(value || []).some(v => v === option.value) ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  padding: '3px 5px',
                  borderRadius: '15px',
                  margin: '3px 5px',
                  flexWrap: 'wrap',
                  background:
                    getWbsItemStatusDeepColorCode(
                      option.value as WbsItemStatus
                    ) || colorPalette.monotone[1],
                }}
              >
                <img src={option.iconUrl} />
                <Typography
                  key={option.value}
                  sx={{
                    margin: 'auto 5px',
                    color: colorPalette.monotone[5],
                  }}
                >
                  {option.name}
                </Typography>
                <Button
                  colorPattern="monotone"
                  sx={{
                    border: 'none',
                    background: colorPalette.monotone[0],
                    width: '10px',
                    height: '24px',
                    borderRadius: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => onChangeValue(option.value)}
                >
                  <ClearRoundedIcon
                    sx={{
                      color: colorPalette.monotone[4],
                      height: '13px',
                      width: '13px',
                    }}
                  />
                </Button>
              </Box>
            </>
          ) : null}
        </Box>
      ))}
      <MuiButton
        sx={{ minWidth: '20px', margin: '3px auto 3px 0' }}
        onClick={clear}
      >
        <ClearRoundedIcon sx={{ color: colorPalette.monotone[4] }} />
      </MuiButton>
    </Box>
  )
}

type SearchConditionSelectBoxCheckmarksProps = {
  value: string[] | undefined
  options: SelectOption[]
  title: string
  onChange: (newValue: string[] | string | undefined) => void
}

const SearchConditionSelectBoxCheckmarks = ({
  value,
  options,
  title,
  onChange,
}: SearchConditionSelectBoxCheckmarksProps) => {
  const [openPullDown, setOpenPullDown] = useState<boolean>(false)
  const [pullDownAnchorEl, setPullDownAnchorEl] = useState<Element>()
  const openPullDownRef = useRef<HTMLSpanElement>(null)
  const [openExpandIconPullDown, setOpenExpandIconPullDown] =
    useState<boolean>(false)
  const [expandIconAnchorEl, setExpandIconAnchorEl] = useState<Element>()
  const openExpandIconTypeRef = useRef<HTMLSpanElement>(null)

  const handlePullDownClose = () => {
    setPullDownAnchorEl(undefined)
    setOpenPullDown(false)
  }

  const handleExpandIconPullDownClose = () => {
    setExpandIconAnchorEl(undefined)
    setOpenExpandIconPullDown(false)
  }

  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <ClickAwayListener onClickAway={handlePullDownClose}>
        <span
          ref={openPullDownRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '416px',
          }}
        >
          {value && value.length >= 1 ? (
            <SelectedConditionItem
              options={options}
              value={value}
              onChange={onChange}
            />
          ) : (
            <Button
              colorPattern="monotone"
              size="s"
              sx={{
                borderRadius: '15px',
                border: `1px dashed ${colorPalette.monotone[2]}`,
                background: colorPalette.monotone[0],
                color: colorPalette.monotone[3],
                margin: '4px auto 4px 15px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (openPullDown) {
                  setPullDownAnchorEl(undefined)
                  setOpenPullDown(false)
                } else {
                  setPullDownAnchorEl(openPullDownRef.current || undefined)
                  setOpenPullDown(true)
                }
              }}
            >
              <Typography>
                {intl.formatMessage({
                  id: 'wbsItemSearchFilterPanel.click.select',
                })}
              </Typography>
              <AddRoundedIcon sx={{ height: '12px', width: '12px' }} />
            </Button>
          )}
          {openPullDown ? (
            <WbsItemSearchCheckboxSelectConditionPopper
              open={openPullDown}
              anchorEl={pullDownAnchorEl}
              options={options}
              value={value}
              onChange={onChange}
              offset={20}
              placement={'bottom-start'}
            />
          ) : null}
        </span>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleExpandIconPullDownClose}>
        <span
          ref={openExpandIconTypeRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '450px',
            margin: 'auto 0 auto auto',
          }}
        >
          <MuiButton
            sx={{ minWidth: '20px', margin: '0 0 0 auto' }}
            onClick={() => {
              if (openExpandIconPullDown) {
                setExpandIconAnchorEl(undefined)
                setOpenExpandIconPullDown(false)
              } else {
                setExpandIconAnchorEl(
                  openExpandIconTypeRef.current || undefined
                )
                setOpenExpandIconPullDown(true)
              }
            }}
          >
            {openExpandIconPullDown ? (
              <ExpandLess sx={{ color: colorPalette.monotone[4] }} />
            ) : (
              <ExpandMore sx={{ color: colorPalette.monotone[4] }} />
            )}
          </MuiButton>
          {openExpandIconPullDown ? (
            <WbsItemSearchCheckboxSelectConditionPopper
              open={openExpandIconPullDown}
              anchorEl={expandIconAnchorEl}
              options={options}
              value={value}
              onChange={onChange}
              offset={18}
              placement={'bottom-end'}
            />
          ) : null}
        </span>
      </ClickAwayListener>
    </Box>
  )
}

type SearchConditionSelectBoxProps = {
  value: string | undefined
  fetchOptions: () => Promise<SelectOption[]>
  fetchSelectedOption: (value: string) => Promise<SelectOption | undefined>
  title: string
  onChange: (newValue: string | undefined) => void
  isDisplayAvatar?: boolean
  teamUuid?: string
  isSprintSearchCondition?: boolean
}

const SearchConditionSelectBox = ({
  value,
  fetchOptions,
  fetchSelectedOption,
  title,
  onChange,
  isDisplayAvatar,
  teamUuid,
  isSprintSearchCondition,
}: SearchConditionSelectBoxProps) => {
  const [openPullDown, setOpenPullDown] = useState<boolean>(false)
  const [pullDownAnchorEl, setPullDownAnchorEl] = useState<Element>()
  const [options, setOptions] = useState<SelectOption[]>([])
  const openPullDownRef = useRef<HTMLSpanElement>(null)
  const [openExpandIconPullDown, setOpenExpandIconPullDown] =
    useState<boolean>(false)
  const [expandIconAnchorEl, setExpandIconAnchorEl] = useState<Element>()
  const openExpandIconTypeRef = useRef<HTMLSpanElement>(null)

  const [selectedOption, setSelectedOption] = useState<
    SelectOption | undefined
  >(undefined)
  useEffect(() => {
    if (!value) return
    fetchSelectedOption(value).then(option => {
      setSelectedOption(option)
    })
  }, [value, setSelectedOption, fetchSelectedOption])

  const handlePullDownClose = () => {
    setPullDownAnchorEl(undefined)
    setOpenPullDown(false)
  }

  const handleExpandIconPullDownClose = () => {
    setExpandIconAnchorEl(undefined)
    setOpenExpandIconPullDown(false)
  }

  const clear = useCallback(() => {
    onChange('')
  }, [onChange])

  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <ClickAwayListener onClickAway={handlePullDownClose}>
        <span
          ref={openPullDownRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '416px',
          }}
        >
          {value && value.length >= 1 ? (
            <Box
              sx={{
                display: 'flex',
                padding: '3px 5px',
                borderRadius: '15px',
                margin: '3px 5px',
                flexWrap: 'wrap',
                background: colorPalette.monotone[1],
              }}
            >
              {isDisplayAvatar && (
                <Avatar
                  variant="circular"
                  src={selectedOption?.iconUrl || undefined}
                  style={{
                    width: 20,
                    height: 20,
                    margin: 'auto 5px auto 5px',
                  }}
                />
              )}
              <Typography
                sx={{
                  margin: 'auto 5px',
                  color: colorPalette.monotone[5],
                }}
              >
                {selectedOption?.name || null}
              </Typography>
              <Button
                colorPattern="monotone"
                sx={{
                  border: 'none',
                  background: colorPalette.monotone[0],
                  width: '10px',
                  height: '24px',
                  borderRadius: '15px',
                  cursor: 'pointer',
                }}
                onClick={clear}
              >
                <ClearRoundedIcon
                  sx={{
                    color: colorPalette.monotone[4],
                    height: '13px',
                    width: '13px',
                  }}
                />
              </Button>
            </Box>
          ) : (
            <Box>
              {isSprintSearchCondition && !teamUuid ? (
                <Typography
                  sx={{
                    color: colorPalette.monotone[3],
                    margin: '10px 0 10px 10px',
                  }}
                >
                  {intl.formatMessage({
                    id: 'wbsItemSearchFilterPanel.selectTeam',
                  })}
                </Typography>
              ) : (
                <Button
                  colorPattern="monotone"
                  size="s"
                  sx={{
                    borderRadius: '15px',
                    border: `1px dashed ${colorPalette.monotone[2]}`,
                    background: colorPalette.monotone[0],
                    color: colorPalette.monotone[3],
                    margin: '4px auto 4px 15px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (openPullDown) {
                      setPullDownAnchorEl(undefined)
                      setOpenPullDown(false)
                    } else {
                      fetchOptions().then(fetchedOptions => {
                        setOptions(fetchedOptions)
                      })
                      setPullDownAnchorEl(openPullDownRef.current || undefined)
                      setOpenPullDown(true)
                    }
                  }}
                >
                  <Typography>
                    {intl.formatMessage({
                      id: 'wbsItemSearchFilterPanel.click.select',
                    })}
                  </Typography>
                  <AddRoundedIcon sx={{ height: '12px', width: '12px' }} />
                </Button>
              )}
            </Box>
          )}
        </span>
      </ClickAwayListener>
      {openPullDown ? (
        <SearchConditionSelectPopper
          open={openPullDown}
          anchorEl={pullDownAnchorEl}
          options={options}
          value={value}
          onChange={onChange}
          offset={20}
          placement={'bottom-start'}
          isDisplayAvatar={isDisplayAvatar}
          isSprintSearchCondition={isSprintSearchCondition}
        />
      ) : null}
      <ClickAwayListener onClickAway={handleExpandIconPullDownClose}>
        <span
          ref={openExpandIconTypeRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '450px',
            margin: 'auto 0 auto auto',
          }}
        >
          {(!isSprintSearchCondition || teamUuid) && (
            <MuiButton
              sx={{ minWidth: '20px', margin: '0 0 0 auto' }}
              onClick={() => {
                if (openExpandIconPullDown) {
                  setExpandIconAnchorEl(undefined)
                  setOpenExpandIconPullDown(false)
                } else {
                  fetchOptions().then(fetchedOptions => {
                    setOptions(fetchedOptions)
                  })
                  setExpandIconAnchorEl(
                    openExpandIconTypeRef.current || undefined
                  )
                  setOpenExpandIconPullDown(true)
                }
              }}
            >
              {openExpandIconPullDown ? (
                <ExpandLess sx={{ color: colorPalette.monotone[4] }} />
              ) : (
                <ExpandMore sx={{ color: colorPalette.monotone[4] }} />
              )}
            </MuiButton>
          )}
        </span>
      </ClickAwayListener>
      {openExpandIconPullDown ? (
        <SearchConditionSelectPopper
          open={openExpandIconPullDown}
          anchorEl={expandIconAnchorEl}
          options={options}
          value={value}
          onChange={onChange}
          offset={18}
          placement={'bottom-end'}
          isDisplayAvatar={isDisplayAvatar}
          isSprintSearchCondition={isSprintSearchCondition}
        />
      ) : null}
    </Box>
  )
}

type SearchConditionDateProps = {
  value: DateTermVO | undefined
  title: string
  onChange: (value: DateTermVO | undefined) => void
  blank: boolean
  onChangeBlank: (value: boolean) => void
}

const SearchConditionDate = ({
  value,
  title,
  onChange,
  blank,
  onChangeBlank,
}: SearchConditionDateProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <SearchConditionDateCell
        value={value}
        onChange={onChange}
        blank={blank}
        onChangeBlank={onChangeBlank}
      />
    </Box>
  )
}

type SearchConditionDateCellProps = {
  value: DateTermVO | undefined
  onChange: (value: DateTermVO | undefined) => void
  blank: boolean
  onChangeBlank: (value: boolean) => void
}

const SearchConditionDateCell = ({
  value,
  onChange,
  blank,
  onChangeBlank,
}: SearchConditionDateCellProps) => {
  const clearFromDate = useCallback(() => {
    if (!value?.to) {
      onChange(undefined)
      return
    }
    const newValue = {
      ...value,
      from: undefined,
    }
    onChange(newValue)
  }, [onChange, value])

  const clearToDate = useCallback(() => {
    if (!value?.from) {
      onChange(undefined)
      return
    }
    const newValue = {
      ...value,
      to: undefined,
    }
    onChange(newValue)
  }, [onChange, value])

  const clear = useCallback(() => {
    onChange(undefined)
  }, [onChange])

  const onChangeFrom = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.to) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        from: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )
  const onChangeTo = useCallback(
    (v: DateVO | undefined) => {
      if (!v && !value?.from) {
        onChange(undefined)
        return
      }
      const newValue = {
        ...value,
        to: v,
      }
      onChange(newValue)
    },
    [onChange, value]
  )

  return (
    <Box
      sx={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box sx={{ maxWidth: '110px' }}>
        <DateInput
          value={value && value.from}
          onChange={onChangeFrom}
          isWbsSearchCondition={true}
          placeholder={'YYYY/MM/DD'}
          onChangeBlank={onChangeBlank}
          checkedBlank={blank}
          onChangedSwitchButton={clear}
        />
      </Box>
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearFromDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <Box sx={{ maxWidth: '110px' }}>
        <DateInput
          value={value && value.to}
          onChange={onChangeTo}
          isWbsSearchCondition={true}
          placeholder={'YYYY/MM/DD'}
          onChangeBlank={onChangeBlank}
          checkedBlank={blank}
          onChangedSwitchButton={clear}
        />
      </Box>
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearToDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
    </Box>
  )
}

type SearchConditionDateTimeProps = {
  value: DateTimeTermVO | undefined
  title: string
  onChange: (newValue: DateTimeTermVO | undefined) => void
}

const SearchConditionDateTime = ({
  value,
  title,
  onChange,
}: SearchConditionDateTimeProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.monotone[2]}`,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 10px',
        height: '100%',
      }}
    >
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          minWidth: '80px',
          marginRight: '10px',
        }}
      >
        {title}
      </Typography>
      <SearchConditionDateTimeCell value={value} onChange={onChange} />
    </Box>
  )
}

type SearchConditionDateTimeCellProps = {
  value: DateTimeTermVO | undefined
  onChange: (newValue: DateTimeTermVO | undefined) => void
}

const SearchConditionDateTimeCell = ({
  value,
  onChange,
}: SearchConditionDateTimeCellProps) => {
  const fromVo = useMemo(() => {
    const from = value && value.toPlainValue().startDateTime
    return from && new DateTimeVO(from)
  }, [value])

  const toVo = useMemo(() => {
    const to = value && value.toPlainValue().endDateTime
    return to && new DateTimeVO(to)
  }, [value])

  const clearFromDate = useCallback(() => {
    if (!value?.toPlainValue().endDateTime) {
      onChange(undefined)
      return
    }
    const newValue = new DateTimeTermVO(undefined, toVo)
    onChange(newValue)
  }, [onChange, toVo, value])

  const clearToDate = useCallback(() => {
    if (!value?.toPlainValue().startDateTime) {
      onChange(undefined)
      return
    }
    const newValue = new DateTimeTermVO(fromVo, undefined)
    onChange(newValue)
  }, [fromVo, onChange, value])

  const onChangeFrom = useCallback(
    (v: DateTimeVO | undefined) => {
      if (!v && !value?.toPlainValue().endDateTime) {
        onChange(undefined)
        return
      }
      if (!v && !toVo) {
        onChange(undefined)
        return
      }
      const newValue = new DateTimeTermVO(v, toVo)
      onChange(newValue)
    },
    [onChange, toVo, value]
  )

  const onChangeTo = useCallback(
    (v: DateTimeVO | undefined) => {
      if (!v && !value?.toPlainValue().startDateTime) {
        onChange(undefined)
        return
      }
      if (!v && !fromVo) {
        onChange(undefined)
        return
      }
      const newValue = new DateTimeTermVO(fromVo, v)
      onChange(newValue)
    },
    [fromVo, onChange, value]
  )

  return (
    <Box
      sx={{
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <DateTimeInput
        placeholder={'YYYY/MM/DD HH:mm:ss'}
        value={fromVo}
        onChange={onChangeFrom}
      />
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearFromDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
      <Typography sx={{ margin: 'auto 10px', color: colorPalette.monotone[3] }}>
        ～
      </Typography>
      <DateTimeInput
        placeholder={'YYYY/MM/DD HH:mm:ss'}
        value={toVo}
        onChange={onChangeTo}
      />
      <Button
        colorPattern="monotone"
        sx={{
          border: 'none',
          background: colorPalette.monotone[0],
          width: '10px',
          height: '24px',
          borderRadius: '15px',
          cursor: 'pointer',
        }}
        onClick={clearToDate}
      >
        <ClearRoundedIcon
          sx={{
            color: colorPalette.monotone[4],
          }}
        />
      </Button>
    </Box>
  )
}

export default WbsSearchSearchConditionDialog
