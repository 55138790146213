enum ActionType {
  TOGGLE_INFORMATION = 'TOGGLE_INFORMATION',
  OPEN_INFORMATION = 'OPEN_INFORMATION',
  CLOSE_INFORMATION = 'CLOSE_INFORMATION',
  SET_TAB_KEY = 'SET_TAB_KEY',
  OPEN_COMMENT = 'OPEN_COMMENT',
  CLOSE_COMMENT = 'CLOSE_COMMENT',
  OPEN_WBS_COMMENT_USER_SUMMARIES = 'OPEN_WBS_COMMENT_USER_SUMMARIES',
  TOGGLE_WBS_COMMENT_USER_SUMMARIES = 'TOGGLE_WBS_COMMENT_USER_SUMMARIES',
  CLOSE_WBS_COMMENT_USER_SUMMARIES = 'CLOSE_WBS_COMMENT_USER_SUMMARIES',
}

export enum TabKey {
  MANAGEMENT_NOTICE = 'MANAGEMENT_NOTICE',
  NEWS = 'NEWS',
  MESSAGE = 'MESSAGE',
  COMMENT = 'COMMENT',
}

export enum ViewType {
  INFORMATION = 'INFORMATION',
  WBS_COMMENT_USER_SUMMARIES = 'WBS_COMMENT_USER_SUMMARIES',
}

type State = {
  open: boolean
  type: ViewType
  tabKey: TabKey
  commentProps?: CommentProps
}

export const toggleInformation = () => ({
  type: ActionType.TOGGLE_INFORMATION,
})

export const openInformation = () => ({
  type: ActionType.OPEN_INFORMATION,
})

export const closeInformation = () => ({
  type: ActionType.CLOSE_INFORMATION,
})

export const setTabKey = (tabKey: TabKey) => ({
  type: ActionType.SET_TAB_KEY,
  tabKey,
})

export const openComment = (commentProps: CommentProps) => ({
  type: ActionType.OPEN_COMMENT,
  commentProps,
})

export const closeComment = () => ({
  type: ActionType.CLOSE_COMMENT,
})

export const openWbsItemCommentSummaries = (commentProps?: CommentProps) => ({
  type: ActionType.OPEN_WBS_COMMENT_USER_SUMMARIES,
  commentProps,
})
export const toggleWbsItemCommentSummaries = () => ({
  type: ActionType.TOGGLE_WBS_COMMENT_USER_SUMMARIES,
})
export const closeWbsItemCommentSummaries = () => ({
  type: ActionType.CLOSE_WBS_COMMENT_USER_SUMMARIES,
})

export interface CommentProps {
  applicationFunctionUuid: string
  dataUuid: string
  projectUuid: string
  headerComponents?: JSX.Element[] | undefined
}

const DEFAULT_TAB_KEY = TabKey.NEWS
const DEFAULT_VIEW_TYPE = ViewType.INFORMATION
export const reducer = (
  state: State = {
    open: false,
    type: DEFAULT_VIEW_TYPE,
    tabKey: DEFAULT_TAB_KEY,
    commentProps: undefined,
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.TOGGLE_INFORMATION: {
      return {
        ...state,
        open: !state.open,
        type: ViewType.INFORMATION,
        tabKey:
          !state.open && state.tabKey === TabKey.COMMENT
            ? DEFAULT_TAB_KEY
            : state.tabKey,
      }
    }
    case ActionType.OPEN_INFORMATION: {
      return {
        ...state,
        open: true,
        type: ViewType.INFORMATION,
        tabKey:
          state.tabKey === TabKey.COMMENT ? DEFAULT_TAB_KEY : state.tabKey,
        commentProps: undefined,
      }
    }
    case ActionType.CLOSE_INFORMATION: {
      return {
        ...state,
        open: false,
      }
    }
    case ActionType.SET_TAB_KEY: {
      return {
        ...state,
        tabKey: action.tabKey,
      }
    }
    case ActionType.OPEN_COMMENT: {
      return {
        ...state,
        open: true,
        type: ViewType.INFORMATION,
        tabKey: TabKey.COMMENT,
        commentProps: action.commentProps,
      }
    }
    case ActionType.CLOSE_COMMENT: {
      return {
        ...state,
        commentProps: undefined,
        open: false,
      }
    }
    case ActionType.OPEN_WBS_COMMENT_USER_SUMMARIES: {
      return {
        ...state,
        open: true,
        type: ViewType.WBS_COMMENT_USER_SUMMARIES,
        tabKey: TabKey.COMMENT,
        commentProps: action.commentProps,
      }
    }
    case ActionType.TOGGLE_WBS_COMMENT_USER_SUMMARIES: {
      return {
        ...state,
        open: !state.open,
        type: ViewType.WBS_COMMENT_USER_SUMMARIES,
        tabKey: TabKey.COMMENT,
      }
    }
    case ActionType.CLOSE_WBS_COMMENT_USER_SUMMARIES: {
      return {
        ...state,
        type: DEFAULT_VIEW_TYPE,
      }
    }
    default:
      return state
  }
}
