import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useData } from '../../ProjectOverview/useData'
import { masterScheduleGridOptions } from './masterScheduleGridOptions'
import {
  AggregateRoot,
  MasterScheduleParameter,
} from '../hooks/useMasterScheduleParameter'
import DateVO from '../../../../vo/DateVO'
import {
  changeProgressHeaderByBaseDate,
  getProgressData,
} from '../../ProjectOverview/projectOverviewGridOptions'
import {
  BodyScrollEndEvent,
  CellClickedEvent,
  GetContextMenuItemsParams,
  GridApi,
  ModelUpdatedEvent,
  RowGroupOpenedEvent,
} from 'ag-grid-community'
import { getDisplayedRow } from '../../../containers/BulkSheetView/lib/gridApi'
import { MasterScheduleHeader } from './Header'
import { BulkSheetView } from '../../../containers/BulkSheetView'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'
import {
  openWbsItemSearch,
  SearchConditionEndDelayed,
  SearchConditionStartDelayed,
} from '../../WbsItemSearch/wbsItemSearchOptions'
import { DateBound } from '../../../components/toggleGroups/StartAndEndDateToggleGroup'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import Loading from '../../../components/process-state-notifications/Loading'
import { Box } from '@mui/material'
import { ProjectDetail } from '../../../../lib/functions/project'
import _ from 'lodash'
import { ProjectOverviewRow } from '../../ProjectOverview/projectOverview'
import { ProjectOverviewDialog } from './projectOverviewDialog'
import { ProgressDashboardParameter } from '../hooks/useProgressDashboardParameter'
import { intl } from '../../../../i18n'
import {
  generateGanttScale,
  generateGanttParameter,
} from '../../../containers/commons/AgGrid/components/cell/custom/ganttReport/ganttUtil'
import { ProjectReportConfig, UpdateProjectReportConfig } from '../model/config'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'

type Props = {
  project: ProjectDetail
} & ProjectReportConfig &
  Pick<UpdateProjectReportConfig, 'updateAggregateRoot'>

export const MasterSchedule: FC<Props> = ({
  project,
  aggregateTarget,
  aggregateField,
  workloadUnit,
  aggregateBaseDate,
  updateAggregateRoot,
}: Props) => {
  // Grid data
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingElem, setLoadingElem] = useState<HTMLDivElement>()
  const [openOverview, setOpenOverview] = useState<boolean>(false)

  const [rootUuid, setRootUuid] = useState<string | undefined>()
  const [rootWbsItemName, setRootWbsItemName] = useState<string>()
  // TODO: Consider
  const workloadUnitState = useWorkloadUnit(workloadUnit)
  const { rows, enqueue } = useData({
    projectUuid: project.uuid,
    treeRootUuid: rootUuid,
    useCache: true,
  })
  const [ganttDisplayTerm, setGanttDisplayTerm] = useState<{
    start: DateVO
    end: DateVO
  }>({
    start: new DateVO(project.scheduledDate.startDate),
    end: new DateVO(project.scheduledDate.endDate),
  })

  const prevRootUuid = useRef<string>()
  useEffect(() => {
    setLoading(false)
    const rootWbsItem = rows?.find(v => v.uuid === rootUuid)?.wbsItem
    setRootWbsItemName(rootWbsItem?.displayName)
    if (!rows || rows.length === 0 || prevRootUuid.current === rootUuid) {
      return
    }
    const start = new DateVO(
      rootWbsItem?.scheduledDate?.startDate || project.scheduledDate.startDate
    )
    const end = new DateVO(
      rootWbsItem?.scheduledDate?.endDate || project.scheduledDate.endDate
    )
    setGanttDisplayTerm({
      start: start.isBefore(end) ? start : end,
      end: start.isBefore(end) ? end : start,
    })
    prevRootUuid.current = rootUuid
  }, [project, rootUuid, rows])

  // Grid definition
  const gridOptions = useMemo(
    () =>
      masterScheduleGridOptions(project, (data: ProjectOverviewRow) => {
        setLoading(true)
        setRootUuid(data.uuid)
        updateAggregateRoot({
          uuid: data.uuid,
          wbsItemUuid: data.wbsItem.uuid!,
          wbsItemName: data.wbsItem.displayName!,
          scheduledDate: data.wbsItem.scheduledDate!,
        })
      }),
    []
  )

  const ganttParameter = useMemo(
    () => generateGanttParameter(ganttDisplayTerm),
    [ganttDisplayTerm]
  )

  const initGanttParam = useCallback(async () => {
    const ganttTimeScale = await generateGanttScale(ganttParameter)
    gridOptions.context = {
      ...gridOptions.context,
      ganttParameter,
      ganttTimeScale,
    }
    gridOptions.api?.refreshHeader()
    gridOptions.api?.refreshCells()
    gridOptions.columnApi?.setColumnWidth(
      'ganttChart',
      ganttTimeScale.length * 40
    )
  }, [ganttParameter])

  useEffect(() => {
    initGanttParam()
  }, [initGanttParam])

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      aggregateTarget: aggregateTarget,
      aggregateField: aggregateField,
      workloadUnit: workloadUnit,
      workloadUnitState: workloadUnitState,
    }
    gridOptions.api?.refreshCells()
  }, [aggregateTarget, aggregateField, workloadUnit, workloadUnitState])

  useEffect(() => {
    if (gridOptions.api) {
      changeProgressHeaderByBaseDate(gridOptions.api, aggregateBaseDate)
      gridOptions.api.refreshHeader()
    }
    gridOptions.context = {
      ...gridOptions.context,
      aggregateBaseDate: aggregateBaseDate,
    }
    gridOptions.api?.refreshCells()
  }, [aggregateBaseDate])

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      const data: ProjectOverviewRow = params.node?.data
      if (!data) return []
      return [
        {
          name: intl.formatMessage({
            id: 'masterSchedule.chooseRowAsAggregateRoot',
          }),
          disabled:
            data.wbsItem.wbsItemType?.isTask() ||
            data.wbsItem.wbsItemType?.isDeliverable() ||
            ((data.progress?.countTaskEnd?.total ?? 0) === 0 &&
              (data.progress?.countDeliverableEnd?.total ?? 0) === 0),
          action: () => {
            updateAggregateRoot({
              uuid: data.uuid,
              wbsItemUuid: data.wbsItem.uuid!,
              wbsItemName: data.wbsItem.displayName!,
              scheduledDate: data.wbsItem.scheduledDate!,
            })
          },
        },
      ]
    },
    [rows]
  )

  // Event
  const onDisplayedRowUpdated = useMemo(
    () =>
      _.debounce((api: GridApi) => {
        if (!ref.current) return
        const fetchBodyUuid = getDisplayedRow(api)
          .filter(node => !node.data.wbsItem)
          .map(node => node.data.uuid)
        enqueue(fetchBodyUuid)
      }, 500),
    []
  )

  const onBodyScrollEnd = useCallback(
    (event: BodyScrollEndEvent) => {
      if (event.direction === 'horizontal') return
      onDisplayedRowUpdated(event.api)
    },
    [onDisplayedRowUpdated]
  )

  const onModelUpdated = useCallback(
    (event: ModelUpdatedEvent) => {
      onDisplayedRowUpdated(event.api)
    },
    [rows, onDisplayedRowUpdated]
  )

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        // Fetch body
        const children = event.node.allLeafChildren
          .filter(
            childNode =>
              childNode.parent?.id === event.node.id && !childNode.data.body
          )
          .map(childNode => childNode.data.uuid)
        enqueue(children)
        setTimeout(
          () => event.api.refreshCells({ columns: ['rowNumber'], force: true }),
          300
        )
      }
    },
    [enqueue]
  )

  return (
    <Box
      ref={setLoadingElem}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <MasterScheduleHeader
        project={project}
        rootUuid={rootUuid}
        rootWbsItemName={rootWbsItemName}
        onClickBreadCrumb={uuid => {
          if (!rows || rows.length === 0) return
          setLoading(true)
        }}
        onOpenOverview={() => setOpenOverview(true)}
      />
      {rows && (
        <BulkSheetView
          ref={ref}
          gridOptions={gridOptions}
          rowData={rows}
          getContextMenuItems={contextMenu}
          suppressContextMenu={false}
          // Event
          onGridReady={() => {
            gridOptions.columnApi?.setColumnWidth(
              'ganttChart',
              (gridOptions?.context?.ganttTimeScale?.length ?? 0) * 40
            )
          }}
          onRowGroupOpened={onRowGroupOpened}
          onCellClicked={(e: CellClickedEvent) => {
            const type: WbsItemTypeVO = e.data.wbsItem?.wbsItemType
            if (
              !type ||
              type.isTask() ||
              (type.isDeliverable() &&
                e.context.aggregateTarget === WbsItemType.DELIVERABLE)
            ) {
              return undefined
            }
            if (
              ['progressRate', 'delayed'].includes(e.colDef.field ?? '') &&
              0 < getProgressData(e.data, e.context).delayed
            ) {
              const conditions = {
                types: [aggregateTarget],
                rootUuid: e.data.wbsItem.uuid,
              }
              openWbsItemSearch(
                project.uuid,
                aggregateBaseDate === DateBound.START
                  ? SearchConditionStartDelayed.with(conditions)
                  : SearchConditionEndDelayed.with(conditions)
              )
            }
          }}
          onBodyScrollEnd={onBodyScrollEnd}
          onModelUpdated={onModelUpdated}
        />
      )}
      {loadingElem && <Loading isLoading={loading} elem={loadingElem} />}
      {openOverview && (
        <ProjectOverviewDialog
          project={project}
          treeRootUuid={rootUuid}
          ganttParameter={ganttParameter}
          onClose={() => setOpenOverview(false)}
        />
      )}
    </Box>
  )
}
