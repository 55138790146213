import { RowNode, ValueSetterParams } from 'ag-grid-community'
import { getParentNode, refreshAncestors } from '../../../..'
import objects from '../../../../../../../../utils/objects'
import { WbsItemStatus } from '../wbsItemStatus'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

export default (params: ValueSetterParams) => {
  // TODO Update ancestor nodes
  if (!params.api || params.oldValue === params.newValue) {
    return false
  }

  // Refresh aggregate at ancestor nodes
  const statusFieldName = params.colDef.field!
  const refreshFieldNames = [
    ...(params.colDef.cellEditorParams.refreshFieldNames || []),
    params.colDef.field,
  ]
  objects.setValue(params.data, statusFieldName, params.newValue)
  params.node &&
    params.api.refreshCells({
      rowNodes: [params.node],
      columns: refreshFieldNames,
      force: true,
    })

  const wbsItemType: WbsItemTypeVO = objects.getValue(
    params.data,
    'wbsItem.wbsItemType'
  )
  const estimatedHour = objects.getValue(params.data, 'wbsItem.estimatedHour')
  let updateParent = true
  refreshAncestors(
    params.api,
    refreshFieldNames,
    getParentNode(params.node),
    (node: RowNode) => {
      const data = node.data
      if (estimatedHour && !data.cumulation) {
        return
      }
      // Update count of status
      if (wbsItemType?.isDeliverable()) {
        subtractDeliverableStatusCount(data, params.oldValue)
        addDeliverableStatusCount(data, params.newValue)
      } else if (wbsItemType?.isTask()) {
        subtractTaskStatusCount(data, params.oldValue)
        addTaskStatusCount(data, params.oldValue)
        // TODO Merge deliverable ofDirectChildren field
        if (updateParent) {
          subtractTaskStatusCountOfDirectChildren(data, params.oldValue)
          addTaskStatusCountOfDirectChildren(data, params.newValue)
          if (data.wbsItem.wbsItemType?.isDeliverable()) {
            updateParent = false
          }
        }
      }

      // Update workload cumulations
      if (wbsItemType?.isDeliverable()) {
        data.cumulation.sumDeliverableEstimatedHourDone += getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DONE,
          estimatedHour
        )
        data.cumulation.sumDeliverableEstimatedHourDiscard += getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DISCARD,
          estimatedHour
        )
      } else if (wbsItemType?.isTask()) {
        data.cumulation.sumTaskEstimatedHourDone += getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DONE,
          estimatedHour
        )
        data.cumulation.sumTaskEstimatedHourDiscard += getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DISCARD,
          estimatedHour
        )
      }
      node.setData(data)
    }
  )

  // Update cumulation of parent deliverable
  if (wbsItemType?.isTask()) {
    let parent: RowNode | null = params.node?.parent || null
    while (parent && parent.data && parent.data.cumulation) {
      const parentWbsItemType = objects.getValue(
        parent.data,
        'wbsItem.wbsItemType'
      )
      if (parentWbsItemType?.isDeliverable()) {
        parent = parent.parent
        continue
      }
      const deliverableData = parent.data
      deliverableData.cumulation.sumTaskEstimatedHourDoneOfDirectChildren +=
        getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DONE,
          estimatedHour
        )
      deliverableData.cumulation.sumTaskEstimatedHourDiscardOfDirectChildren +=
        getHourByStatus(
          params.newValue,
          params.oldValue,
          WbsItemStatus.DISCARD,
          estimatedHour
        )
      parent.setData(deliverableData)
      break
    }
  }

  return true
}

const getHourByStatus = (
  status: WbsItemStatus,
  oldStatus: WbsItemStatus,
  target: WbsItemStatus,
  estimatedHour: number
) => {
  if (status === target) {
    return estimatedHour || 0
  } else if (oldStatus === target) {
    return -(estimatedHour || 0)
  }
  return 0
}

export const subtractDeliverableStatusCount = (
  data: any,
  status: WbsItemStatus
) => {
  data.cumulation.countStatusDeliverableTodo -= countIfTodo(status)
  data.cumulation.countStatusDeliverableDoing -= countIfDoing(status)
  data.cumulation.countStatusDeliverableReview -= countIfReview(status)
  data.cumulation.countStatusDeliverableDone -= countIfDone(status)
  data.cumulation.countStatusDeliverableDiscard -= countIfDiscard(status)
}

export const addDeliverableStatusCount = (data: any, status: WbsItemStatus) => {
  data.cumulation.countStatusDeliverableTodo += countIfTodo(status)
  data.cumulation.countStatusDeliverableDoing += countIfDoing(status)
  data.cumulation.countStatusDeliverableReview += countIfReview(status)
  data.cumulation.countStatusDeliverableDone += countIfDone(status)
  data.cumulation.countStatusDeliverableDiscard += countIfDiscard(status)
}

export const subtractTaskStatusCount = (data: any, status: WbsItemStatus) => {
  data.cumulation.countStatusTaskTodo -= countIfTodo(status)
  data.cumulation.countStatusTaskDoing -= countIfDoing(status)
  data.cumulation.countStatusTaskReview -= countIfReview(status)
  data.cumulation.countStatusTaskDone -= countIfDone(status)
  data.cumulation.countStatusTaskDiscard -= countIfDiscard(status)
}

export const addTaskStatusCount = (data: any, status: WbsItemStatus) => {
  data.cumulation.countStatusTaskTodo += countIfTodo(status)
  data.cumulation.countStatusTaskDoing += countIfDoing(status)
  data.cumulation.countStatusTaskReview += countIfReview(status)
  data.cumulation.countStatusTaskDone += countIfDone(status)
  data.cumulation.countStatusTaskDiscard += countIfDiscard(status)
}

export const subtractTaskStatusCountOfDirectChildren = (
  data: any,
  status: WbsItemStatus
) => {
  data.cumulation.countTaskTodoOfDirectChildren -= countIfTodo(status)
  data.cumulation.countTaskDoingOfDirectChildren -= countIfDoing(status)
  data.cumulation.countTaskReviewOfDirectChildren -= countIfReview(status)
  data.cumulation.countTaskDoneOfDirectChildren -= countIfDone(status)
  data.cumulation.countTaskDiscardOfDirectChildren -= countIfDiscard(status)
}

export const addTaskStatusCountOfDirectChildren = (
  data: any,
  status: WbsItemStatus
) => {
  data.cumulation.countTaskTodoOfDirectChildren += countIfTodo(status)
  data.cumulation.countTaskDoingOfDirectChildren += countIfDoing(status)
  data.cumulation.countTaskReviewOfDirectChildren += countIfReview(status)
  data.cumulation.countTaskDoneOfDirectChildren += countIfDone(status)
  data.cumulation.countTaskDiscardOfDirectChildren += countIfDiscard(status)
}

const countIfTodo = v => (v === WbsItemStatus.TODO ? 1 : 0)
const countIfDoing = v => (v === WbsItemStatus.DOING ? 1 : 0)
const countIfReview = v => (v === WbsItemStatus.REVIEW ? 1 : 0)
const countIfDone = v => (v === WbsItemStatus.DONE ? 1 : 0)
const countIfDiscard = v => (v === WbsItemStatus.DISCARD ? 1 : 0)
