import { TicketRow } from '../tickets'
import { ExcelParserBase } from './base'

export class ExcelParser extends ExcelParserBase {
  public getColumnMap = (columnKeyMap: {
    [key: string]: string
  }): {
    [key: string]: string
  } => {
    return this.getCommonColumnKeyMap(columnKeyMap)
  }

  public mergeUpdatedRow = (
    embodied: TicketRow,
    excelSrc: object,
    columnKeyMap: { [key: string]: string }
  ): TicketRow => {
    return {
      ...embodied,
      wbsItem: embodied.wbsItem
        ? this.mergeWbsItemColumnUpdatedRow(
            embodied.wbsItem,
            excelSrc,
            columnKeyMap
          )
        : undefined,
    }
  }
}
