import { ListItem, ListItemText, Box, Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { colorPalette } from '../../../../style/colorPallete'
import SvgFilterIcon from '../../../icons/FilterIcon'
import { UiStateScope } from '../../../../../lib/commons/uiStates'
import { useMemo } from 'react'

interface SavedUIStateListHeaderProps {
  checkedScope?: UiStateScope[]
  onChangeScopeFilter: (scope: UiStateScope[]) => void
  checkedUser?: string[]
  onChangeUserFilter: (user: string[]) => void
}

const SavedUIStateListHeader = ({
  checkedScope,
  onChangeScopeFilter,
  checkedUser,
  onChangeUserFilter,
}: SavedUIStateListHeaderProps) => {
  const scopeFiltered = useMemo(
    () => !!checkedScope && checkedScope.length !== 3,
    [checkedScope]
  )
  const privateScopeFiltered = useMemo(
    () =>
      !!checkedScope &&
      checkedScope.length === 1 &&
      checkedScope.find(v => v === UiStateScope.User),
    [checkedScope]
  )
  const userFiltered = useMemo(
    () => !!checkedUser && checkedUser.length > 0,
    [checkedUser]
  )
  return (
    <ListItem
      style={{ width: '100%', alignItems: 'center', padding: '10px 0' }}
    >
      {/* TODO: Delete when implement favorite feature
    <Box style={{ width: '32px' }}></Box> */}
      <ListItemText
        sx={{
          color: colorPalette.monotone[5],
          minWidth: '200px',
          maxWidth: '200px',
        }}
      >
        {intl.formatMessage({
          id: 'savedUIState.content.list.columnSettingName',
        })}
      </ListItemText>
      <ListItem
        sx={{
          maxWidth: '64px',
          padding: '4px 8px',
          color: scopeFiltered
            ? colorPalette.skyBlue[5]
            : colorPalette.monotone[5],
          backgroundColor: scopeFiltered
            ? colorPalette.skyBlue[0]
            : colorPalette.monotone[0],
          display: 'flex',
          alignItems: 'center',
          flex: 'row',
        }}
      >
        <SvgFilterIcon
          style={{
            fontSize: 21,
            paddingRight: '8px',
            color: scopeFiltered
              ? colorPalette.skyBlue[7]
              : colorPalette.monotone[4],
          }}
        />
        <Typography>
          {intl.formatMessage({
            id: 'savedUIState.content.list.scope',
          })}
        </Typography>
      </ListItem>
      <ListItem
        sx={{
          minWidth: '160px',
          maxWidth: '160px',
          padding: '4px 8px',
          color: userFiltered
            ? colorPalette.skyBlue[5]
            : colorPalette.monotone[5],
          backgroundColor: userFiltered
            ? colorPalette.skyBlue[0]
            : colorPalette.monotone[0],
          display: 'flex',
          alignItems: 'center',
          flex: 'row',
        }}
      >
        <SvgFilterIcon
          style={{
            fontSize: 21,
            paddingRight: '8px',
            color: privateScopeFiltered
              ? colorPalette.monotone[2]
              : userFiltered
              ? colorPalette.skyBlue[7]
              : colorPalette.monotone[4],
          }}
        />
        <Typography>
          {intl.formatMessage({
            id: 'savedUIState.content.list.updatedBy',
          })}
        </Typography>
      </ListItem>
      <ListItemText
        sx={{
          color: colorPalette.monotone[5],
          minWidth: '120px',
          maxWidth: '120px',
        }}
      >
        {intl.formatMessage({
          id: 'savedUIState.content.list.updatedAt',
        })}
      </ListItemText>
      <Box style={{ width: '136px' }}></Box>
    </ListItem>
  )
}

export default SavedUIStateListHeader
