import { useCallback, useState } from 'react'
import _ from 'lodash'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import {
  ChatChannelMessageTicketCreationMappingRow,
  createNewChatChannelMessageTicketCreationMappingRow,
} from '../chatChannelMessageTicketCreationMapping'
import { useDispatch } from 'react-redux'
import { useChatChannelMessageTicketCreationMappingRepository } from '../../../../services/adaptors/chatChannelMessageTicketCreationMappingRepositoryAdaptor'

export const useChatChannelMessageTicketCreationMappingsData = (
  projectUuid: string,
  chatWorkspaceId?: string
) => {
  const [data, setDataInternal] = useState<
    ChatChannelMessageTicketCreationMappingRow[]
  >([])
  const [deletedRows, setDeletedRows] = useState<
    ChatChannelMessageTicketCreationMappingRow[]
  >([])
  const dispatch = useDispatch()

  const clearData = useCallback(() => {
    setDataInternal([])
  }, [])

  const chatChannelMessageTicketCreationMappingRepository =
    useChatChannelMessageTicketCreationMappingRepository()

  const fetchRecords = useCallback(async () => {
    if (!chatWorkspaceId) return
    const entities =
      await chatChannelMessageTicketCreationMappingRepository.getByProjectUuid(
        projectUuid,
        chatWorkspaceId
      )

    const rows: ChatChannelMessageTicketCreationMappingRow[] = entities.map(
      v => {
        return {
          uuid: v.uuid,
          treeValue: [v.uuid],
          lockVersion: v.lockVersion,
          projectUuid: v.projectUuid,
          ticketType: v.ticketType,
          ticketList: v.ticketList,
          chatChannel: v.chatChannel,
          revision: v.revision,
          createdAt: v.createdAt,
          createdBy: v.createdBy,
          updatedAt: v.updatedAt,
          updatedBy: v.updatedBy,
        }
      }
    )
    setDataInternal(rows)
    setDeletedRows([])
    if (rows.length === 0) {
      const firstRow =
        createNewChatChannelMessageTicketCreationMappingRow(projectUuid)
      setDataInternal([
        { ...firstRow, added: true, treeValue: [firstRow.uuid] },
      ])
    }
  }, [chatWorkspaceId, projectUuid])

  const refresh = useCallback(async () => {
    await fetchRecords()
    dispatch(doNotRequireSave())
  }, [fetchRecords, dispatch])

  const save = useCallback(async (): Promise<{
    hasError: boolean
    hasWarning: boolean
  }> => {
    const addedRows = data.filter(row => row.added)
    const editedRows = data.filter(row => row.edited && !row.added)
    return chatChannelMessageTicketCreationMappingRepository.save({
      added: addedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        ticketType: v.ticketType!,
        ticketList: v.ticketList!,
        chatChannel: v.chatChannel!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
      edited: editedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        ticketType: v.ticketType!,
        ticketList: v.ticketList!,
        chatChannel: v.chatChannel!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
      deleted: deletedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        ticketType: v.ticketType!,
        ticketList: v.ticketList!,
        chatChannel: v.chatChannel!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
    })
  }, [data, deletedRows])

  const setData = useCallback(
    (d: ChatChannelMessageTicketCreationMappingRow[]) => {
      setDataInternal(d)
      dispatch(requireSave())
    },
    []
  )

  const deleteRows = useCallback(
    (rows: ChatChannelMessageTicketCreationMappingRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
    },
    [deletedRows]
  )

  const hasDuplicatedData = useCallback(() => {
    const isDuplicated = (
      a: ChatChannelMessageTicketCreationMappingRow,
      b: ChatChannelMessageTicketCreationMappingRow
    ) =>
      a.uuid !== b.uuid &&
      a.projectUuid === b.projectUuid &&
      a.chatChannel?.slackChannelId === b.chatChannel?.slackChannelId

    return data.some(v => data.some(other => isDuplicated(v, other)))
  }, [data])

  return {
    data,
    setData,
    refresh,
    save,
    clearData,
    deleteRows,
    hasDuplicatedData,
  }
}
