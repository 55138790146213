import { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import { Box, Slide, Tooltip, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { Button } from '../../../../components/buttons'
import { FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'

type Props = {
  loading: boolean
  hasRequiredSaveData: boolean
  onSubmit: () => void
}

const SavePopper = ({ loading, hasRequiredSaveData, onSubmit }: Props) => {
  return (
    <Slide
      style={{
        top: '8px',
        position: 'fixed',
        width: '320px',
      }}
      direction="right"
      in={!(!hasRequiredSaveData || loading)}
      {...{ timeout: 500 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          height: '44px',
          width: '100%',
          backgroundColor: colorPalette.green[0],
          border: `1px solid ${colorPalette.green[6]}`,
          borderLeft: 'none',
          boxShadow: '0px 4px 16px 0px #7B8CAA80',
          borderRadius: '0 4px 4px 0',
        }}
      >
        <Typography
          sx={{
            color: colorPalette.green[7],
            fontSize: FontSize.MEDIUM,
            fontWeight: 700,
            marginRight: '20px',
            marginLeft: '20px',
          }}
        >
          {intl.formatMessage({ id: 'wbs.header.save.title' })}
        </Typography>
        <Button
          onClick={onSubmit}
          colorPattern="green"
          sx={{
            border: 'none',
            cursor: 'pointer',
            padding: '8px 0px',
            height: '28px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{ color: colorPalette.monotone[0], margin: '0 2px 0 15px' }}
          >
            {intl.formatMessage({ id: 'wbs.header.save.button' })}
          </Typography>
          <Typography
            sx={{
              color: colorPalette.green[2],
              margin: '0 10px 0 2px',
              fontSize: '11px',
            }}
          >
            {intl.formatMessage({ id: 'wbs.header.save.button.shortcutKey' })}
          </Typography>
        </Button>
      </Box>
    </Slide>
  )
}

const mapStateToProps = (state: AllState) => ({
  hasRequiredSaveData: state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(SavePopper)
