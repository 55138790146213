import { SelectOption } from '../../../../model/selectOption'
import WbsSearchConditionCheckBoxPopper from './WbsSearchConditionCheckBoxPopper'
import { Placement } from 'react-overlays/cjs/usePopper'

type CheckBoxProps = {
  open: boolean
  anchorEl: Element | undefined
  options: SelectOption[]
  value: string[] | undefined
  onChange: (newValue: string[] | undefined) => void
  offset?: number
  placement?: Placement | undefined
}

const WbsItemSearchCheckboxSelectConditionPopper = ({
  open,
  anchorEl,
  options,
  value,
  onChange,
  offset,
  placement,
}: CheckBoxProps) => {
  return (
    <WbsSearchConditionCheckBoxPopper
      open={open}
      anchorEl={anchorEl}
      options={options}
      value={value}
      onChange={onChange}
      offset={offset}
      placement={placement}
    />
  )
}

export default WbsItemSearchCheckboxSelectConditionPopper
