import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  Paper,
  Popper,
  Slider,
  Typography,
} from '@mui/material'
import { FontSize } from '../../../../../styles/commonStyles'
import { intl } from '../../../../../i18n'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { useState } from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { ROW_HEIGHT } from '../../../BulkSheet'
import { Checkbox } from '../../../../components/inputs/Checkbox'
import {
  DISPLAY_DATE_FORMAT_WITH_YEAR_DAY,
  DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
} from '../../../../../utils/date'

type Props = {
  height: number | undefined
  onChangeHeight: (value: number) => void
  currentFormat?: string | undefined
  onChangeDateFormat?: (value: string) => void
}

const DisplayFormatButton = ({
  height,
  onChangeHeight,
  currentFormat,
  onChangeDateFormat,
}: Props) => {
  const [openDisplayFormat, setOpenDisplayFormat] = useState<boolean>(false)
  const [displayFormatAnchorEl, setDisplayFormatAnchorEl] = useState<Element>()

  const [openRowHeight, setOpenRowHeight] = useState<boolean>(false)
  const [rowHeightAnchorEl, setRowHeightAnchorEl] = useState<Element>()

  const [openDateFormat, setOpenDateFormat] = useState<boolean>(false)
  const [dateFormatAnchorEl, setDateFormatAnchorEl] = useState<Element>()

  const handleClose = () => {
    setDisplayFormatAnchorEl(undefined)
    setOpenDisplayFormat(false)
    setRowHeightAnchorEl(undefined)
    setOpenRowHeight(false)
    setOpenDateFormat(false)
    setDateFormatAnchorEl(undefined)
  }

  const handleRowHeightClose = () => {
    setRowHeightAnchorEl(undefined)
    setOpenRowHeight(false)
  }

  const handleDateFormatClose = () => {
    setDateFormatAnchorEl(undefined)
    setOpenDateFormat(false)
  }

  return (
    <div style={{ minWidth: 'fit-content' }}>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <button
            style={{
              background: 'transparent',
              border: 0,
              fontSize: FontSize.MEDIUM,
              fontWeight: 500,
              display: 'flex',
              flexDirection: 'row',
              padding: '5px 10px',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={event => {
              if (openDisplayFormat) {
                setDisplayFormatAnchorEl(undefined)
                setOpenDisplayFormat(false)
              } else {
                setDisplayFormatAnchorEl(event.currentTarget)
                setOpenDisplayFormat(true)
              }
            }}
          >
            <Typography sx={{ color: colorPalette.monotone[5] }}>
              {intl.formatMessage({ id: 'wbs.header.display.format' })}
            </Typography>
            <NavigateNextRoundedIcon
              sx={{
                marginLeft: '5px',
                color: colorPalette.monotone[4],
                cursor: 'pointer',
                transform: () =>
                  openDisplayFormat ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            />
          </button>
          <Popper
            open={openDisplayFormat}
            anchorEl={displayFormatAnchorEl}
            placement={'bottom-start'}
            sx={{ zIndex: 1300, boxShadow: '0px 4px 16px 0px #7B8CAA80' }}
          >
            <Paper sx={{ padding: '0 10px 0 10px' }}>
              <List
                sx={{
                  color: colorPalette.monotone[4],
                  fontSize: '14px',
                  fontWeight: 400,
                }}
              >
                <ClickAwayListener onClickAway={handleRowHeightClose}>
                  <div>
                    <ListItemButton
                      sx={{ borderRadius: '4px' }}
                      onClick={event => {
                        if (openRowHeight) {
                          setRowHeightAnchorEl(undefined)
                          setOpenRowHeight(false)
                        } else {
                          setRowHeightAnchorEl(event.currentTarget)
                          setOpenRowHeight(true)
                        }
                      }}
                    >
                      <Typography sx={{ marginRight: '10px' }}>
                        {intl.formatMessage({ id: 'wbs.header.change.height' })}
                      </Typography>
                      <NavigateNextRoundedIcon
                        sx={{
                          marginLeft: '5px',
                          color: colorPalette.monotone[4],
                          cursor: 'pointer',
                          transform: () =>
                            openRowHeight ? 'rotate(90deg)' : 'rotate(0deg)',
                        }}
                      />
                    </ListItemButton>
                    {openRowHeight && (
                      <RowHeightPopper
                        openRowHeight={openRowHeight}
                        rowHeightAnchorEl={rowHeightAnchorEl}
                        onChangeHeight={onChangeHeight}
                        height={height}
                      />
                    )}
                  </div>
                </ClickAwayListener>
                {currentFormat && onChangeDateFormat && (
                  <ClickAwayListener onClickAway={handleDateFormatClose}>
                    <div>
                      <ListItemButton
                        sx={{ borderRadius: '4px' }}
                        onClick={event => {
                          if (openDateFormat) {
                            setDateFormatAnchorEl(undefined)
                            setOpenDateFormat(false)
                          } else {
                            setDateFormatAnchorEl(event.currentTarget)
                            setOpenDateFormat(true)
                          }
                        }}
                      >
                        <Typography sx={{ marginRight: '10px' }}>
                          {intl.formatMessage({ id: 'wbs.header.date.format' })}
                        </Typography>
                        <NavigateNextRoundedIcon
                          sx={{
                            marginLeft: '5px',
                            color: colorPalette.monotone[4],
                            cursor: 'pointer',
                            transform: () =>
                              openDateFormat ? 'rotate(90deg)' : 'rotate(0deg)',
                          }}
                        />
                      </ListItemButton>
                      {openDateFormat && (
                        <DateFormatPopper
                          openDateFormat={openDateFormat}
                          dateFormatAnchorEl={dateFormatAnchorEl}
                          currentFormat={currentFormat}
                          onChangeDateFormat={onChangeDateFormat}
                        />
                      )}
                    </div>
                  </ClickAwayListener>
                )}
              </List>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  )
}

type RowHeightPopperProps = {
  openRowHeight: boolean
  rowHeightAnchorEl: Element | undefined
  onChangeHeight: (value: number) => void
  height: number | undefined
}

const RowHeightPopper = ({
  openRowHeight,
  rowHeightAnchorEl,
  onChangeHeight,
  height,
}: RowHeightPopperProps) => {
  const minHeight = ROW_HEIGHT.SMALL // 32
  const maxHeight = 202

  const marks = [
    { value: 0, label: '0%' },
    { value: 85, label: '50%' },
    { value: 170, label: '100%' },
  ]

  return (
    <Popper
      open={openRowHeight}
      anchorEl={rowHeightAnchorEl}
      placement={'bottom-end'}
      modifiers={[{ name: 'offset', options: { offset: [75, 5] } }]}
      sx={{
        boxShadow: '0px 4px 16px 0px #7B8CAA80',
        background: colorPalette.monotone[0],
        height: '180px',
        width: '90px',
        display: 'flex',
        margin: '10px',
        borderRadius: '4px',
        zIndex: 1300,
      }}
    >
      <Box sx={{ display: 'flex', margin: 'auto 10px' }}>
        <Slider
          orientation="vertical"
          onChange={(event: any, value: number | number[]) => {
            if (Array.isArray(value)) return
            onChangeHeight(value + ROW_HEIGHT.SMALL)
            event.stopPropagation()
          }}
          onChangeCommitted={e => e.stopPropagation()}
          defaultValue={height && height - ROW_HEIGHT.SMALL}
          min={0}
          max={maxHeight - minHeight}
          step={17}
          marks={marks}
          size="small"
          sx={{
            width: '6px',
            height: '110px',
            padding: '10px 0',
            margin: '0 20px 0 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: colorPalette.skyBlue[4],
            '& .MuiSlider-rail': {
              opacity: 1,
              color: colorPalette.monotone[1],
            },
            '& .MuiSlider-thumb': {
              height: '8px',
              width: '12px',
              backgroundColor: colorPalette.skyBlue[7],
              borderRadius: '33% / 50%',
              '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
              },
            },
            '& .MuiSlider-track': {
              backgroundColor: colorPalette.skyBlue[4],
              color: colorPalette.skyBlue[4],
            },
            '& .MuiSlider-root': {
              color: colorPalette.monotone[1],
            },
            '& .MuiSlider-mark': {
              display: 'none',
            },
            '& .MuiSlider-markLabel': {
              color: colorPalette.monotone[5],
              left: '18px',
              textAlign: 'center',
              width: '32px',
            },
          }}
        />
      </Box>
    </Popper>
  )
}

type DateFormatPopperProps = {
  openDateFormat: boolean
  dateFormatAnchorEl: Element | undefined
  currentFormat: string | undefined
  onChangeDateFormat: (value: string) => void
}

const DateFormatPopper = ({
  openDateFormat,
  dateFormatAnchorEl,
  currentFormat,
  onChangeDateFormat,
}: DateFormatPopperProps) => {
  return (
    <Popper
      open={openDateFormat}
      anchorEl={dateFormatAnchorEl}
      placement={'bottom-end'}
      modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
      sx={{
        boxShadow: '0px 4px 16px 0px #7B8CAA80',
        background: colorPalette.monotone[0],
        height: '90px',
        width: '180px',
        display: 'flex',
        margin: '10px',
        borderRadius: '4px',
        zIndex: 1300,
        padding: '15px 10px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: 'auto 0',
          }}
        >
          <Checkbox
            size={'m'}
            style={{ padding: '0 8px', cursor: 'pointer' }}
            checked={currentFormat === DISPLAY_DATE_SHORT_FORMAT_WITH_DAY}
            onClick={() =>
              onChangeDateFormat(DISPLAY_DATE_SHORT_FORMAT_WITH_DAY)
            }
          />
          <Typography
            sx={{
              color: colorPalette.monotone[4],
              fontSize: '14px',
            }}
          >
            {intl.formatMessage({
              id: 'wbs.header.date.short.format.with.day',
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: 'auto 0',
          }}
        >
          <Checkbox
            size={'m'}
            style={{ padding: '0 8px', cursor: 'pointer' }}
            checked={currentFormat === DISPLAY_DATE_FORMAT_WITH_YEAR_DAY}
            onClick={() =>
              onChangeDateFormat(DISPLAY_DATE_FORMAT_WITH_YEAR_DAY)
            }
          />
          <Typography
            sx={{
              color: colorPalette.monotone[4],
              fontSize: '14px',
            }}
          >
            {intl.formatMessage({ id: 'wbs.header.date.format.with.year.day' })}
          </Typography>
        </Box>
      </Box>
    </Popper>
  )
}

export default DisplayFormatButton
