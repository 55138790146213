import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { BulkSheetFilter, FilterFooter, FilterInput } from '../common'
import { useSelectFilter } from './useSelectFilter'
import {
  styled,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'
import { ICON_SIZE } from '../../../../BulkSheet/const'

export type SelectFilter = {
  values: string[]
  includeBlank: boolean
}

type Props = IFilterParams & {
  filterChangedCallback: () => void
  getValue: (option: any) => string
  getLabel: (option: any) => string
  valueGetter: (params) => any
  valueFormatter: (params) => string
  sortValues: (uiMeta, options, context) => any
}

export const ClientSideSelectFilter = forwardRef(
  (
    {
      filterChangedCallback,
      getValue,
      getLabel,
      valueGetter,
      valueFormatter,
      sortValues,
      colDef,
      context,
      api,
    }: Props,
    ref
  ) => {
    // State
    const filter = useSelectFilter()
    const [text, setText] = useState('')
    const [options, setOptions] = useState<any[]>([])
    const restoredFilterState = useRef<boolean>()

    // Ag-grid custom filter
    useImperativeHandle(
      ref,
      () => {
        return {
          doesFilterPass(params: IDoesFilterPassParams) {
            // Don't filter new rows
            if (params.data.isAdded) return true
            if (!filter.values) return true

            const selectValue = valueGetter({
              ...params,
              context,
            })
            if (filter.includeBlank && !selectValue) return true
            if (
              Array.isArray(selectValue) &&
              selectValue.some(v => filter.values.includes(getValue(v)))
            ) {
              return true
            } else if (filter.values.includes(getValue(selectValue))) {
              return true
            }
            return false
          },

          isFilterActive() {
            return filter.isActive()
          },

          getModel() {
            return filter.model()
          },

          setModel(model) {
            if (!model) {
              filter.reset()
              return
            }
            restoredFilterState.current = true
            const newOptions = getOptions()
            setOptions(newOptions)
            filter.setOptions(newOptions.map(getValue) ?? [])
            if (model.filterType) {
              filter.setValues(model.values.filter(v => v !== null))
              filter.setIncludeBlank(model.values.includes(null) ? true : false)
            } else {
              filter.setValues(model.values)
              filter.setIncludeBlank(model.includeBlank)
            }
          },

          afterGuiAttached() {
            setOptions(getOptions())
          },

          getModelAsString() {
            if (!filter.isActive()) return ''
            let labelList: string[] = []
            filter.includeBlank && labelList.push('(Blank)')
            labelList = labelList.concat(
              options
                .filter(option => filter.values.includes(getValue(option)))
                .map(option => getLabel(option))
            )
            return `${labelList.join(',')}`
          },
        }
      },
      [filter.values, filter.includeBlank]
    )

    useEffect(() => {
      if (filter.isActive()) {
        filter.setOptions(options.map(getValue) ?? [])
      } else {
        if (restoredFilterState.current) {
          filter.setOptions(options.map(getValue) ?? [])
        } else {
          filter.init(options.map(getValue) ?? [])
        }
      }
    }, [options])

    useEffect(() => {
      api.onFilterChanged()
      filterChangedCallback()
    }, [filter.values, filter.includeBlank])

    const getOptions = () => {
      const initialOptions: any[] = []
      api.forEachNode(node => {
        initialOptions.push(valueGetter({ node, context }))
      })
      const newOptionValues: string[] = []
      const newOptions: any[] = []
      initialOptions
        .filter(option => !!option)
        .forEach(option => {
          if (Array.isArray(option)) {
            option.forEach(o => {
              if (!newOptionValues.includes(getValue(o))) {
                newOptions.push(o)
                newOptionValues.push(getValue(o))
              }
            })
          } else if (!newOptionValues.includes(getValue(option))) {
            newOptions.push(option)
            newOptionValues.push(getValue(option))
          }
        })
      if (sortValues) {
        return sortValues(
          colDef.cellRendererParams?.uiMeta,
          newOptions,
          context
        )
      } else {
        return newOptions
      }
    }

    return (
      <BulkSheetFilter>
        <FilterInput
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder={'Search...'}
          sx={{ height: 22 }}
        />
        <SelectFilterArea>
          <SelectFilterFormControl
            label="(Select All)"
            control={
              <Checkbox
                checked={filter.isAllSelected()}
                indeterminate={
                  !filter.isAllSelected() && !filter.isAllDeselected()
                }
                onChange={e =>
                  e.target.checked ? filter.selectAll() : filter.deselectAll()
                }
                disableRipple={true}
              />
            }
          />
          <CheckboxArea>
            {!text && (
              <SelectFilterFormControl
                label="(Blanks)"
                control={
                  <Checkbox
                    checked={filter.includeBlank}
                    onChange={e => filter.setIncludeBlank(e.target.checked)}
                    disableRipple={true}
                  />
                }
              />
            )}
            {options
              .filter(
                option =>
                  !text ||
                  (valueFormatter
                    ? valueFormatter({
                        colDef,
                        value: getLabel(option),
                      }).includes(text)
                    : getLabel(option).includes(text))
              )
              .map(option => {
                const value = getValue(option)
                return (
                  <SelectFilterFormControl
                    key={`select-filter-${value}`}
                    label={
                      <FilterLabel
                        sx={{
                          backgroundColor: option.backgroundColor ?? 'inherit',
                        }}
                      >
                        {option.iconUrl && (
                          <img
                            src={option.iconUrl}
                            style={{
                              height: `${ICON_SIZE}px`,
                              width: `${ICON_SIZE}px`,
                              borderRadius: '50%',
                            }}
                          />
                        )}
                        <Typography sx={{ padding: '0 3px' }}>
                          {valueFormatter
                            ? valueFormatter({
                                colDef,
                                value: getLabel(option),
                              })
                            : getLabel(option)}
                        </Typography>
                      </FilterLabel>
                    }
                    control={
                      <Checkbox
                        checked={filter.values.includes(value)}
                        onChange={e => {
                          if (e.target.checked) {
                            filter.setValues([...filter.values, value])
                          } else {
                            filter.setValues(
                              filter.values.filter(v => v !== value)
                            )
                          }
                        }}
                        disableRipple={true}
                      />
                    }
                  />
                )
              })}
          </CheckboxArea>
        </SelectFilterArea>
        <FilterFooter loading={false} onClick={() => filter.reset()} />
      </BulkSheetFilter>
    )
  }
)

export const SelectFilterFormControl = styled(FormControlLabel)({
  margin: 0,
})

export const SelectFilterArea = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  margin: '0 3px',
})

export const CheckboxArea = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'column',
  marginLeft: '20px',
})

export const FilterLabel = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '2px',
})
