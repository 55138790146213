import { ICellEditorParams } from 'ag-grid-community'
import { InputBase } from '@mui/material'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { parseFullNumberToHalf } from '../../../../../utils/number'

export const NumberCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [value, setValue] = useState(props.charPress ?? props.value ?? '')

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (value === undefined || value === null) return undefined
        const str = parseFullNumberToHalf(value.toString())
        const num = parseFloat(str)
        return Number.isFinite(num) ? Number(num.toFixed(2)) : undefined
      },
    }
  })

  return (
    <InputBase
      inputRef={inputRef}
      value={value}
      onChange={e => {
        setValue(e.target.value)
      }}
    />
  )
})
