import { IconButton as MuiIconButton, Tooltip } from '@mui/material'
import { PropsWithChildren } from 'react'

interface OwnProps {
  onClick: () => void
  size?: 'small' | 'medium'
  title?: string
}

export const IconButton = (props: PropsWithChildren<OwnProps>) => {
  const { children, onClick, size, title } = props
  return (
    <Tooltip title={title || ''}>
      <MuiIconButton onClick={onClick} size={size || 'small'}>
        {children}
      </MuiIconButton>
    </Tooltip>
  )
}
