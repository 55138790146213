import BaseButton, { Props as BaseButtonProps } from '../BaseButton'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import CheckIcon from '@mui/icons-material/CheckRounded'
import { intl } from '../../../../i18n'

const SaveButton = ({ ...other }: BaseButtonProps) => {
  return (
    <BaseButton
      {...other}
      startIcon={<CheckIcon color={'inherit'} />}
      sx={{
        '&.MuiButton-root': {
          background: BackgroundColor.BLUE_GRADATION,
          color: TextColor.WHITE,
        },
      }}
    >
      {intl.formatMessage({ id: 'dialog.ok' })}
    </BaseButton>
  )
}

export default SaveButton
