import _ from 'lodash'
import {
  ColDef,
  GetContextMenuItemsParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { CSSProperties } from 'react'
import { intl } from '../../../i18n'
import { APIResponse, successDummyResponse } from '../../../lib/commons/api'
import { Tree } from '../../../lib/commons/tree'
import { UiStateKey } from '../../../lib/commons/uiStates'
import projectMember from '../../../lib/functions/projectMember'
import {
  getResourcePlan,
  GetResourcePlanNewProps,
  ResourcePlanNewDetail,
  ResourcePlanNewInput,
  ResourcePlanNewMemberInput,
  ResourcePlanMemberSiblingInput,
  ResourcePlanType,
  updateBatch,
  updateMemberWork,
} from '../../../lib/functions/resourcePlanNew'
import team, { TeamProps } from '../../../lib/functions/team'
import { UserDetail } from '../../../lib/functions/user'
import {
  DateTerm,
  formatDateTime,
  formatMonth,
  generateYearMonthArray,
} from '../../../utils/date'
import { generateUuid } from '../../../utils/uuids'
import store from '../../../store'
import ContextMenu, {
  ContextMenuGroup,
  ContextMenuGroupId,
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import {
  AgGridValueGetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetProps,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import ViewMeta from '../../containers/meta/ViewMeta'
import { roundNumber } from '../../../utils/number'
import * as OrganizationWorkingDayCalendarAPI from '../../../lib/functions/organizationWorkingDayCalendar'
import DateVO from '../../../vo/DateVO'
import { requireSave } from '../../../store/requiredSaveData'
import { EntitySearchValue } from '../../containers/meta/repositories'
import WorkTimeCellRenderer from './CellRenderer/WorkTimeCellRenderer'
import { DefaultCellRenderer } from '../../containers/BulkSheetView/components/cellRenderer'
import { CURRENT_MONTH_BACKGROUND_COLOR } from '../../containers/commons/AgGrid'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class ResourcePlanNewRow extends RowData {
  type?: ResourcePlanType
  user?: UserDetail
  team?: TeamProps
  displayName?: string
  projectReleasedDate?: string
  memberWorkMonths?: MemberWorkMonthCell[]
  revision?: string
  rowType: ResourcePlanType
}

interface MemberWorkMonthCell {
  yearMonth?: string
  manMonth?: number
}

interface WorkingDay {
  businessDays: number
  workHours: number
}

interface WorkingDayCalendar {
  [yearMonth: string]: WorkingDay
}

export interface ResourcePlanNewState extends BulkSheetState {
  resourcePlanUuid?: string
  lockVersion: number
  workingDayCalendars: WorkingDayCalendar
  allCanAddMemberList: UserDetail[]
  teamList: MultiSelectDialogSelection[]
  dateTerm: DateTerm
}

interface ResourcePlanNewContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    ResourcePlanNewDetail,
    ResourcePlanNewRow,
    ResourcePlanNewState
  > {}

interface ResourcePlanNewBulkSheetProps
  extends BulkSheetProps<
    BulkSheetSpecificProps,
    ResourcePlanNewDetail,
    ResourcePlanNewRow,
    ResourcePlanNewState
  > {}

const userNameValueGetter = (params: ValueGetterParams): string => {
  if (!params.data) return ''
  if (params.data.user) return params.data.user.name
  return ''
}

const userNameValueFormatter = params => {
  let name = '(Blanks)'
  if (params.value) name = params.value
  return name
}

class ResourcePlanNewRowSpec extends RowDataSpec<
  ResourcePlanNewDetail,
  ResourcePlanNewRow
> {
  columnTypes(): { [key: string]: ColDef } {
    const normalize = (value: any) => {
      if (typeof value === 'object') {
        return new EntitySearchValue(value).toString()
      }
      return value ? value : ''
    }
    return {
      workMonth: {
        editable: params => params.data.type !== ResourcePlanType.UNSET,
        // TODO :Allows man-hours to be entered even in hours and man-days
        // && getSelectedWorkloadUnit() === WorkloadUnit.MONTH,
      },
      userName: {
        cellStyle: params => {
          if (params.data.type !== ResourcePlanType.MEMBER) {
            return { color: 'transparent' }
          } else {
            return { color: 'inherit' }
          }
        },
        valueGetter: userNameValueGetter,
        filterParams: {
          buttons: ['reset'],
          valueFormatter: userNameValueFormatter,
        },
      },
      teams: {
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          const result = compareUnsetRow(nodeA, nodeB, isInverted)
          if (result !== 0) return result
          const normalA = normalize(valueA)
          const normalB = normalize(valueB)
          if (normalA < normalB) {
            return -1
          } else if (normalA > normalB) {
            return 1
          }
          return 0
        },
      },
    }
  }
  createNewRow(
    ctx: ResourcePlanNewContext,
    params: any = {
      type: ResourcePlanType.MEMBER,
    }
  ): ResourcePlanNewRow {
    const result = new ResourcePlanNewRow(generateUuid())
    result.type = params.type
    result.memberWorkMonths = []
    if (params.type === ResourcePlanType.UNSET) {
      const teamLabel: TeamProps = createLabelTeam('project.resourcePlan.none')
      result.team = teamLabel
      result.displayName = teamLabel.displayName
    }
    return result
  }
  overwriteRowItemsWithParents(params: {
    child: ResourcePlanNewRow
    parent: ResourcePlanNewRow
  }): ResourcePlanNewRow {
    return params.child
  }
  createRowByResponse(response: ResourcePlanNewDetail): ResourcePlanNewRow {
    if (response.type === ResourcePlanType.UNSET) {
      const teamLabel: TeamProps = createLabelTeam('project.resourcePlan.none')
      return {
        uuid: response.uuid,
        type: response.type,
        parentUuid: response.parentUuid,
        user: response.user,
        team: teamLabel,
        projectReleasedDate: response.projectReleasedDate,
        displayName: teamLabel.displayName,
        memberWorkMonths: response.memberWorkMonths,
        rowType: ResourcePlanType.UNSET,
      }
    }
    let displayName: string | undefined = undefined
    const teamTotal: TeamProps = createLabelTeam('project.resourcePlan.total')
    if (response.type === ResourcePlanType.MEMBER && response.user) {
      displayName = response.user.name
    } else if (response.type === ResourcePlanType.TEAM && response.team) {
      displayName = response.team.displayName
    }
    return {
      uuid: response.uuid,
      type: response.type,
      parentUuid: response.parentUuid,
      prevSiblingUuid: response.prevSiblingUuid,
      user: response.user,
      team: response.type === ResourcePlanType.TEAM ? response.team : teamTotal,
      projectReleasedDate: response.projectReleasedDate,
      memberWorkMonths: response.memberWorkMonths.map(memberWorkMonth => ({
        yearMonth: formatMonth(memberWorkMonth.month),
        manMonth: memberWorkMonth.manMonth,
      })),
      displayName: displayName,
      revision: response.revision,
      rowType: response.type,
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
}

export default class ResourcePlanNewOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  ResourcePlanNewDetail,
  ResourcePlanNewRow,
  ResourcePlanNewState
> {
  addable = true
  draggable = true
  enableExcelExport = true
  displayNameField = 'displayName'
  rowDataSpec = new ResourcePlanNewRowSpec()
  getRowsToRemove = (
    nodes: RowNode[],
    ctx: ResourcePlanNewContext
  ): {
    rows: RowNode[]
    unremovableReasonMessageId?: string
  } => {
    let result: RowNode[] = []
    const canRemoveRow = (node: RowNode) => {
      if (node.data.type === ResourcePlanType.UNSET) {
        return false
      }
      if (
        node.data.type === ResourcePlanType.MEMBER &&
        node.allChildrenCount &&
        node.allChildrenCount > 1
      ) {
        return false
      }
      return true
    }
    nodes.forEach(node => {
      if (canRemoveRow(node)) {
        node.data.displayName =
          node.data.type === ResourcePlanType.MEMBER
            ? node.data.user?.name
            : node.data.team?.displayName
        result.push(...node.allLeafChildren)
      }
    })
    result = result.filter((node, index, self) => self.indexOf(node) === index)
    return { rows: result }
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ResourcePlanColumnAndFilterState}-${ctx.state.uuid}`
  generateContextMenuItems = (
    params: GetContextMenuItemsParams,
    ctx: ResourcePlanNewContext
  ): ContextMenu | undefined => {
    if (!params.node || !params.node.data || params.node.data.isTotal) return

    let addChild = (params, type: ResourcePlanType, defaultItem, orderList) => {
      const addingUuid = defaultItem.value
      const allUuid = orderList.map(m => m.value)
      let addToUuid: string | undefined = undefined

      if (type === ResourcePlanType.TEAM) {
        const allLeafChildren =
          params.node.data.type === ResourcePlanType.MEMBER
            ? params.node.allLeafChildren
            : params.node.parent.allLeafChildren
        const teamList = allLeafChildren.filter(f => f.level === 1)
        const addedUuid = teamList.map(m => m.data.team.uuid)

        let targetIndex = 0
        allUuid.map((uuid, index) => {
          if (uuid === addingUuid) {
            targetIndex = index
          }
        })

        addToUuid = teamList.find(
          team => team.data.type === ResourcePlanType.UNSET
        ).data.uuid
        for (let i = targetIndex - 1; i >= 0; i--) {
          if (addedUuid.includes(allUuid[i])) {
            if (teamList.some(s => s.data.team.uuid === allUuid[i])) {
              const parent = teamList.find(f => f.data.team.uuid === allUuid[i])
              addToUuid = parent.data.uuid
              break
            }
          }
        }
        const parentUuid =
          params.node.data.type !== ResourcePlanType.MEMBER
            ? params.node.data.parentUuid
            : params.node.id
        const newRowData = this.rowDataSpec.createNewRow(ctx, {
          type: ResourcePlanType.TEAM,
        })
        newRowData.displayName = defaultItem.displayName
        newRowData.user = params.node.data.user
        newRowData.team = defaultItem as TeamProps
        newRowData.team.uuid = defaultItem.value
        ctx.addRow(addToUuid, parentUuid, newRowData)
        return newRowData
      } else if (type === ResourcePlanType.MEMBER) {
        const allLeafChildren = params.node.parent.allLeafChildren
        const memberList = allLeafChildren.filter(f => f.level === 0)
        let addedUuid: string[] = []
        addedUuid = memberList
          .filter(f => f.data.user)
          .map(m => m.data.user.uuid)

        let targetIndex = 0
        allUuid.map((uuid, index) => {
          if (uuid === addingUuid) {
            targetIndex = index
          }
        })

        addToUuid = generateUuid()
        for (let i = targetIndex - 1; i >= 0; i--) {
          if (addedUuid.includes(allUuid[i])) {
            if (memberList.some(s => s.data.user.uuid === allUuid[i])) {
              const parent = memberList.find(
                f => f.data.user.uuid === allUuid[i]
              )
              addToUuid = parent.data.uuid
              break
            }
          }
        }

        const allRows = ctx.rowDataManager.getAllRows()
        allRows.forEach(row => {
          if (row.type === ResourcePlanType.MEMBER && row.user === undefined) {
            ctx.rowDataManager.removeRows(allRows)
          }
        })
        const newRowData = this.rowDataSpec.createNewRow(ctx, {
          type: ResourcePlanType.MEMBER,
        })
        newRowData.user = defaultItem as UserDetail
        newRowData.user.name = defaultItem.displayName
        newRowData.user.uuid = defaultItem.value
        newRowData.team = createLabelTeam('project.resourcePlan.total')
        ctx.addRow(addToUuid, undefined, newRowData)

        const newUnsetRow = this.rowDataSpec.createNewRow(ctx, {
          type: ResourcePlanType.UNSET,
        })
        newUnsetRow.parentUuid = newRowData.uuid
        newUnsetRow.user = newRowData.user
        ctx.addRow(newRowData.uuid, newRowData.uuid, newUnsetRow)
        newRowData.children = [newUnsetRow]
        return newRowData
      }
    }

    const getMemberDialog = () => {
      return (
        <MultiSelectDialog
          onClose={() => ctx.setState({ children: null })}
          onSubmit={onDialogSubmit}
          dialogTitle={intl.formatMessage({
            id: 'project.resourcePlan.addMember',
          })}
          getSelectList={getMemberList}
          allCheckBoxLabel={intl.formatMessage({
            id: 'multiSelectDialog.allCkeck.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'add',
          })}
        />
      )
    }

    const getMemberList = () => {
      const allCanAddMemberList = ctx.state.allCanAddMemberList
      const exclude: string[] = []
      params.api.forEachNode(n => {
        if (n.data.type === ResourcePlanType.MEMBER && n.data.user) {
          exclude.push(n.data.user.uuid)
        }
      })
      const list: MultiSelectDialogSelection[] = allCanAddMemberList
        .filter(d => !exclude.includes(d.uuid))
        .map(m => ({
          value: m.uuid,
          iconUrl: m.iconUrl,
          displayName: m.name,
        }))
      return list
    }

    const onDialogSubmit = (selectList: any[], selected: any[]) => {
      selected.map(m =>
        addChild(params, ResourcePlanType.MEMBER, m, selectList)
      )
    }

    const showTeamDialog = (excludeList: string[]) => {
      return (
        <MultiSelectDialog
          onClose={() => ctx.setState({ children: null })}
          onSubmit={onTeamDialogSubmit}
          dialogTitle={intl.formatMessage({
            id: 'project.resourcePlan.addTeam',
          })}
          excludeValues={excludeList}
          getSelectList={getTeamList}
          allCheckBoxLabel={intl.formatMessage({
            id: 'multiSelectDialog.allCkeck.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'add',
          })}
        />
      )
    }

    const onTeamDialogSubmit = (selectList: any[], selected: any[]) => {
      selected.map(m => addChild(params, ResourcePlanType.TEAM, m, selectList))
    }

    const row = params.node.data
    const canAddMember =
      row.type === ResourcePlanType.MEMBER && getMemberList().length > 0

    const getExcludeTeamList = () => {
      let root = params.node!
      if (
        params.node!.data.type === ResourcePlanType.TEAM ||
        params.node!.data.type === ResourcePlanType.UNSET
      ) {
        root = params.node!.parent!
      }
      const exclude = root.allLeafChildren
        .filter(f => f.data.type === ResourcePlanType.TEAM)
        .map(m => m.data.team.uuid)
      return exclude
    }
    const excludeTeamList = getExcludeTeamList()
    const canAddTeam =
      (row.type !== ResourcePlanType.MEMBER || row.user) &&
      ctx.state.teamList.length > excludeTeamList.length

    return new ContextMenu(
      [
        {
          id: ContextMenuGroupId.ADD_ROW_GROUP,
          items: [
            {
              name: intl.formatMessage({
                id: 'project.resourcePlan.addMember',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_MEMBER),
              disabled: !canAddMember,
              action: () => {
                ctx.setState({ children: getMemberDialog() })
              },
            },
            {
              name: intl.formatMessage({
                id: 'project.resourcePlan.addTeam',
              }),
              icon: getMenuIconHtml(ContextMenuItemId.ADD_TEAM),
              disabled: !canAddTeam,
              action: () => {
                ctx.setState({ children: showTeamDialog(excludeTeamList) })
              },
            },
          ],
        },
        ctx.generateEditContextMenu(params, [ContextMenuItemId.REMOVE_ROW]),
      ].filter(v => !!v) as ContextMenuGroup[]
    )
  }
  getRowStyle = params => {
    if (
      params.data &&
      params.data.projectReleasedDate &&
      new DateVO(params.data.projectReleasedDate).isBefore(
        DateVO.now().getStartOfDay()
      )
    ) {
      return { backgroundColor: '#e0e0e0' }
    }
  }
  groupColumnWidth = 150
  lockedColumns = ['Total', 'project.resourcePlanNew.user.code']
  pinnedColumns = [
    'project.resourcePlanNew.user.code',
    'project.resourcePlanNew.user',
    'project.resourcePlanNew.team',
  ]
  customColumnTypes = {
    'project.resourcePlanNew.memberWorkMonth': ['workMonth'],
    'project.resourcePlanNew.user': ['userName'],
    'project.resourcePlanNew.team': ['teams'],
  }
  customRenderers = {
    'project.resourcePlanNew.user': 'iconCellRenderer',
  }
  dynamicColumns = {
    'project.resourcePlanNew.memberWorkMonth': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: ResourcePlanNewContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        const columnComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
          const result = compareUnsetRow(nodeA, nodeB, isInverted)
          if (result !== 0) return result
          if (valueA === valueB) {
            return 0
          } else if (valueA === '-' || valueB === undefined) {
            return -1
          } else if (valueB === '-' || valueA === undefined) {
            return 1
          } else if (valueA < valueB) {
            return -1
          } else if (valueA > valueB) {
            return 1
          }
          return 0
        }
        const yearMonthArray = ctx.state.dateTerm
          ? generateYearMonthArray(ctx.state.dateTerm)
          : []
        const workingDayCalendars: WorkingDayCalendar =
          ctx.state.workingDayCalendars ?? {}
        const calendarKeys: string[] = Object.keys(workingDayCalendars)
        if (
          !_.isEmpty(yearMonthArray) &&
          yearMonthArray.some(yearMonth => !calendarKeys.includes(yearMonth))
        ) {
          const getWorkingDayCalendarResponse =
            await OrganizationWorkingDayCalendarAPI.getOrganizationWorkingDayCalendar(
              {
                startDate: new DateVO(ctx.state.dateTerm?.startDate).toDate(),
                endDate: new DateVO(ctx.state.dateTerm?.endDate).toDate(),
              }
            )
          const workingDays =
            getWorkingDayCalendarResponse.json as OrganizationWorkingDayCalendarAPI.OrganizationWorkingDayCalendarDetail[]
          yearMonthArray.forEach(yearMonth => {
            const targetYearMonth = workingDays.filter(
              wd => new DateVO(wd.date).format('YYYY/MM') === yearMonth
            )
            const workHours = targetYearMonth.reduce((pre, value) => {
              return pre + value.workHour
            }, 0)
            const businessDays = targetYearMonth.filter(
              ym => ym.workHour > 0
            ).length
            workingDayCalendars[yearMonth] = {
              businessDays: businessDays,
              workHours: workHours,
            }
          })
          ctx.setState({ workingDayCalendars })
        }
        const getRateToMonth = (yearMonth: string = '') => {
          // TODO :Allows man-hours to be entered even in hours and man-days
          return 1
        }
        const getWorkingTimeTotal = (yearMonth: string) => {
          const targetWorkingDays: WorkingDay = workingDayCalendars[yearMonth]
          return `${targetWorkingDays?.businessDays}d/${targetWorkingDays?.workHours}h`
        }
        const columns: any[] = []
        const totalValueGetter = (params: ValueGetterParams) => {
          if (!params.api || !params.data) {
            return 0
          }
          if (params.data.isTotal) {
            let grandTotal = 0
            params.api.forEachNode(rowNode => {
              if (
                rowNode.data &&
                !rowNode.data.isTotal &&
                rowNode.data.type === ResourcePlanType.MEMBER
              ) {
                rowNode.data.memberWorkMonths.forEach(memberWorkMonth => {
                  grandTotal += getManMonth(memberWorkMonth)
                })
              }
            })
            return roundNumber(grandTotal * getRateToMonth(), 2)
          }
          let total = 0
          if (params.data.type === ResourcePlanType.UNSET) {
            let memberTotal = 0
            let teamTotal = 0
            const parentUuid = params.data.parentUuid
            params.api.forEachNode(rowNode => {
              if (!rowNode || !rowNode.data) return
              if (
                rowNode.data.type === ResourcePlanType.MEMBER &&
                rowNode.data.uuid === parentUuid
              ) {
                rowNode.data.memberWorkMonths.forEach(memberWorkMonth => {
                  memberTotal += getManMonth(memberWorkMonth)
                })
              }
              if (
                rowNode.data.type === ResourcePlanType.TEAM &&
                rowNode.data.parentUuid === parentUuid
              ) {
                rowNode.data.memberWorkMonths.forEach(teamWorkMonth => {
                  teamTotal += getManMonth(teamWorkMonth)
                })
              }
            })

            const columnTotal = memberTotal - teamTotal
            return roundNumber(columnTotal * getRateToMonth(), 2)
          } else {
            params.data.memberWorkMonths.forEach(memberWorkMonth => {
              total += getManMonth(memberWorkMonth)
            })
            return roundNumber(total * getRateToMonth(), 2)
          }
        }
        const totalColumn = {
          ...baseColumnDef,
          headerName: 'Total',
          lockPosition: true,
          children: [
            {
              ...baseColumnDef,
              cellRendererParams: {
                ...baseColumnDef.cellRendererParams,
                uiMeta: {
                  ...baseColumnDef.cellRendererParams.uiMeta,
                  minNumber: undefined,
                  maxNumber: undefined,
                },
              },
              headerName: '',
              pinned: true,
              editable: false,
              valueGetter: totalValueGetter,
              comparator: columnComparator,
            } as ColDef,
          ],
        }
        columns.push(totalColumn)
        let invalidTotal = false
        let yearList: number[] = []
        yearMonthArray.forEach(yearMonth =>
          yearList.push(new DateVO(yearMonth).getYear())
        )
        yearList = yearList.filter((cur, i, self) => self.indexOf(cur) === i)
        yearMonthArray.forEach(yearMonth => {
          const createMonthCol = () => {
            return yearMonthArray
              .filter(f => f === yearMonth)
              .map(targetYearMonth => {
                const valueGetter = (params: ValueGetterParams) => {
                  if (!params.api || !params.data) {
                    return
                  }
                  if (params.data.isTotal) {
                    let total = 0
                    params.api.forEachNode(rowNode => {
                      if (
                        rowNode.data &&
                        !rowNode.data.isTotal &&
                        rowNode.data.user &&
                        rowNode.data.type === ResourcePlanType.MEMBER
                      ) {
                        const memberWorkMonth =
                          rowNode.data.memberWorkMonths.find(
                            cell => cell.yearMonth === params.colDef.colId!
                          )
                        total += getManMonth(memberWorkMonth)
                      }
                    })
                    return roundNumber(total * getRateToMonth(yearMonth), 2)
                  }
                  if (params.data.type === ResourcePlanType.UNSET) {
                    const parentUuid = params.data.parentUuid
                    let memberMonth = 0
                    let teamTotal = 0
                    params.api.forEachNode(rowNode => {
                      if (!rowNode.data) return 0
                      if (
                        rowNode.data.type === ResourcePlanType.MEMBER &&
                        rowNode.data.uuid === parentUuid
                      ) {
                        const memberWorkMonth =
                          rowNode.data.memberWorkMonths.find(
                            cell => cell.yearMonth === params.colDef.colId!
                          )
                        memberMonth = getManMonth(memberWorkMonth)
                      }
                      if (
                        rowNode.data.type === ResourcePlanType.TEAM &&
                        rowNode.data.parentUuid === parentUuid
                      ) {
                        const teamWorkMonth =
                          rowNode.data.memberWorkMonths.find(
                            cell => cell.yearMonth === params.colDef.colId!
                          )
                        teamTotal += getManMonth(teamWorkMonth)
                      }
                    })
                    const unsetTotal = Number(memberMonth - teamTotal)
                    invalidTotal = unsetTotal < 0
                    return roundNumber(
                      unsetTotal * getRateToMonth(yearMonth),
                      2
                    )
                  }
                  const memberWorkMonth =
                    !params.node?.group && !!params.data.memberWorkMonths
                      ? params.data.memberWorkMonths.find(
                          cell =>
                            cell.yearMonth === params.colDef.colId! &&
                            cell.manMonth !== undefined
                        )
                      : undefined
                  return memberWorkMonth
                    ? roundNumber(
                        memberWorkMonth.manMonth * getRateToMonth(yearMonth),
                        2
                      )
                    : undefined
                }
                const valueSetter = (params: ValueSetterParams) => {
                  store.dispatch(requireSave())
                  const memberWorkMonth = params.data.memberWorkMonths.find(
                    cell => cell.yearMonth === params.colDef.colId!
                  )
                  if (
                    memberWorkMonth &&
                    memberWorkMonth.manMonth !== params.newValue
                  ) {
                    memberWorkMonth.manMonth = params.newValue
                    params.data.isEdited = true
                    return true
                  }
                  if (!memberWorkMonth) {
                    params.data.memberWorkMonths.push({
                      yearMonth: params.colDef.colId!,
                      manMonth: params.newValue,
                    })
                    params.data.isEdited = true
                    return true
                  }
                  return false
                }

                return {
                  ...baseColumnDef,
                  colId: yearMonth,
                  field: yearMonth,
                  headerName: getWorkingTimeTotal(yearMonth),
                  lockPosition: true,
                  valueGetter: valueGetter,
                  valueSetter: valueSetter,
                  cellRenderer: params => {
                    if (params.data.isTotal) {
                      return <WorkTimeCellRenderer {...params} />
                    }
                    return <DefaultCellRenderer {...params} />
                  },
                  cellRendererParams: {
                    ...baseColumnDef.cellRendererParams,
                    highlightForZero: true,
                    uiMeta: {
                      ...baseColumnDef.cellRendererParams.uiMeta,
                      minNumber: undefined,
                      maxNumber: undefined,
                    },
                  },
                  cellStyle: params => {
                    let style: CSSProperties = {
                      justifyContent: 'flex-end',
                    }
                    const isCurrentMonth =
                      yearMonth === DateVO.now().format('YYYY/MM')
                    if (isCurrentMonth) {
                      style.color = 'blue'
                      style.backgroundColor = CURRENT_MONTH_BACKGROUND_COLOR
                    } else {
                      style.color = 'black'
                    }
                    if (
                      params.data.type === ResourcePlanType.UNSET &&
                      invalidTotal
                    ) {
                      style.color = 'red'
                    }
                    return style
                  },
                  comparator: columnComparator,
                }
              })
          }

          const monthHeader = {
            ...baseColumnDef,
            cellRendererParams: {
              ...baseColumnDef.cellRendererParams,
              highlightForZero: true,
              uiMeta: {
                ...baseColumnDef.cellRendererParams.uiMeta,
                minNumber: undefined,
                maxNumber: undefined,
              },
            },
            headerName: intl.formatMessage(
              { id: 'project.resourcePlan.month' },
              { month: new DateVO(yearMonth).getMonth() }
            ),
            colId: yearMonth,
            field: yearMonth,
            lockPosition: true,
            children: [{}],
            valueGetter: 0,
            valueSetter: 0,
          }

          const year = new DateVO(yearMonth).getYear()
          const header = {
            ...baseColumnDef,
            headerName: intl.formatMessage(
              { id: 'project.resourcePlan.year' },
              { year: year }
            ),
            colId: year,
            field: year,
            lockPosition: true,
            children: [{}],
          }
          monthHeader.children = createMonthCol()
          if (columns.some(h => h.colId === new DateVO(yearMonth).getYear())) {
            const yearHeader = columns.find(
              f => f.colId === new DateVO(yearMonth).getYear()
            )
            yearHeader.children.push(monthHeader)
          } else {
            header.children = [monthHeader]
            columns.push(header)
          }
        })
        return columns
      },
    },
  }

  focusColumn = (ctx: ResourcePlanNewContext) => {
    return DateVO.now().addMonths(10).format('YYYY/MM')
  }

  updateState = (
    root: Tree<ResourcePlanNewDetail>,
    state: ResourcePlanNewState
  ) => {
    return {
      ...state,
      resourcePlanUuid: root.uuid,
      lockVersion: root.lockVersion,
    }
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    return undefined
  }

  onSearch(ctx: ResourcePlanNewContext, dateTerm: DateTerm) {
    ctx.setState({ dateTerm }, async () => {
      await ctx.refreshDataWithLoading(true)
    })
  }

  async getAll(
    state: ResourcePlanNewState,
    props: ResourcePlanNewBulkSheetProps,
    ctx: ResourcePlanNewContext
  ): Promise<APIResponse> {
    if (!state.dateTerm?.startDate || !state.dateTerm?.endDate) {
      return successDummyResponse
    }
    const startDate = new DateVO(state.dateTerm?.startDate).formatForApi()
    const endDate = new DateVO(state.dateTerm?.endDate).formatForApi()
    if (!startDate || !endDate) return successDummyResponse

    const memberlist = await getAllCanAddMemberList()
    const teamList = await getTeamList()
    ctx.setState({ allCanAddMemberList: memberlist, teamList: teamList })

    const getResourcePlanProps: GetResourcePlanNewProps = {
      projectUuid: state.uuid,
      startDate: startDate,
      endDate: endDate,
    }
    const response = await getResourcePlan(getResourcePlanProps)
    return response
  }

  onSubmit = async (
    ctx: ResourcePlanNewContext,
    data: {
      added: ResourcePlanNewRow[]
      edited: {
        before: ResourcePlanNewRow
        after: ResourcePlanNewRow
      }[]
      deleted: ResourcePlanNewRow[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: ResourcePlanNewInput = this.createRequest(
      data,
      ctx.rowDataManager.getAllRows(),
      ctx.state
    )
    const [respose, memberResponse] = await Promise.all([
      updateBatch(input),
      updateMemberWork(input),
    ])
    return respose
  }

  private createRequest(
    data: {
      added: ResourcePlanNewRow[]
      edited: {
        before: ResourcePlanNewRow
        after: ResourcePlanNewRow
      }[]
      deleted: ResourcePlanNewRow[]
    },
    allRows: ResourcePlanNewRow[],
    state: ResourcePlanNewState
  ): ResourcePlanNewInput {
    const added: ResourcePlanNewMemberInput[] = data.added
      .filter(
        row =>
          (row.type === ResourcePlanType.MEMBER && !!row.user) ||
          (row.type === ResourcePlanType.TEAM && !!row.team)
      )
      .map(row => {
        return {
          uuid: row.uuid,
          userUuid: row.user
            ? row.user.uuid
            : data.added.find(f => f.uuid === row.parentUuid)!.user!.uuid,
          teamUuid:
            row.team && row.team.projectUuid !== '' ? row.team.uuid : undefined,
          deltaMemberWorkMonths: row.memberWorkMonths!.map(memberWorkMonth => {
            const yearMonth = new DateVO(memberWorkMonth.yearMonth)
            return {
              year: yearMonth.getYear(),
              month: yearMonth.getMonth(),
              manMonth: memberWorkMonth.manMonth,
            }
          }),
        } as ResourcePlanNewMemberInput
      })
    const deleted: ResourcePlanNewMemberInput[] = data.deleted
      .filter(
        row =>
          (row.type === ResourcePlanType.MEMBER && !!row.user) ||
          (row.type === ResourcePlanType.TEAM && !!row.team)
      )
      .map(row => {
        return {
          uuid: row.uuid,
          userUuid: row.user
            ? row.user.uuid
            : data.added.find(f => f.uuid === row.parentUuid)!.user!.uuid,
          teamUuid:
            row.team && row.team.projectUuid !== '' ? row.team.uuid : undefined,
          deltaMemberWorkMonths: [],
        } as ResourcePlanNewMemberInput
      })
    const edited: ResourcePlanNewMemberInput[] = data.edited
      .filter(
        row =>
          (row.after.type === ResourcePlanType.MEMBER && !!row.after.user) ||
          (row.after.type === ResourcePlanType.TEAM && !!row.after.team)
      )
      .map(row => {
        const deltaWorkMonths = row.after.memberWorkMonths!.filter(after => {
          const before = row.before.memberWorkMonths?.find(
            workMonth => workMonth.yearMonth === after.yearMonth
          )
          return !before || before.manMonth !== after.manMonth
        })
        return {
          uuid: row.after.uuid,
          userUuid: row.after.user
            ? row.after.user.uuid
            : data.edited.find(f => f.after.uuid === row.after.parentUuid)!
                .after.user!.uuid,
          teamUuid:
            row.after.team && row.after.team.projectUuid !== ''
              ? row.after.team.uuid
              : undefined,
          deltaMemberWorkMonths: deltaWorkMonths.map(memberWorkMonth => {
            const yearMonth = new DateVO(memberWorkMonth.yearMonth)
            return {
              year: yearMonth.getYear(),
              month: yearMonth.getMonth(),
              manMonth: memberWorkMonth.manMonth,
            }
          }),
        } as ResourcePlanNewMemberInput
      })
    const resourcePlanMembers: ResourcePlanNewMemberInput[] = allRows
      .filter(
        row =>
          (row.type === ResourcePlanType.MEMBER && !!row.user) ||
          (row.type === ResourcePlanType.TEAM && !!row.team)
      )
      .map(row => {
        const workMonths = row.memberWorkMonths!.map(memberWorkMonth => {
          const yearMonth = new DateVO(memberWorkMonth.yearMonth)
          return {
            year: yearMonth.getYear(),
            month: yearMonth.getMonth(),
            manMonth: memberWorkMonth.manMonth,
          }
        })
        return {
          uuid: row.uuid,
          userUuid: row.user
            ? row.user.uuid
            : data.added.find(f => f.uuid === row.parentUuid)!.user!.uuid,
          teamUuid:
            row.team && row.team.projectUuid !== '' ? row.team.uuid : undefined,
          deltaMemberWorkMonths: workMonths,
          memberWorkMonths: workMonths,
        } as ResourcePlanNewMemberInput
      })
    const resourcePlanSiblings: ResourcePlanMemberSiblingInput[] = allRows
      .filter(row => row.type === ResourcePlanType.MEMBER)
      .map(row => {
        return {
          uuid: row.uuid,
          userUuid: row.user?.uuid,
          lockVersion: row.lockVersion,
          prevSiblingUuid: row.prevSiblingUuid,
        } as ResourcePlanMemberSiblingInput
      })
    return {
      projectUuid: store.getState().project.selected!,
      uuid: state.resourcePlanUuid,
      lockVersion: state.lockVersion,
      resourcePlanMembers: resourcePlanMembers,
      added: added,
      edited: edited,
      deleted: deleted,
      memberSiblings: resourcePlanSiblings,
      teamSiblings: [],
    }
  }

  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'user',
        'user.code',
        'user.division.displayName',
        'user.position.displayName',
        'team',
      ].includes(field)
    ) {
      return 120
    }
    if (field === 'memberWorkMonth') {
      return 78
    }
    return undefined
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'user') {
      return {
        labelField: 'user.name',
        iconUrlField: 'user.iconUrl',
        iconShowCheckField: 'type',
        iconShowCheck: iconShowCheck,
      }
    }
  }

  pinnedTopRowData: ResourcePlanNewRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      memberWorkMonths: [],
      treeValue: [],
      isTotal: true,
      isViewOnly: true,
      rowType: ResourcePlanType.UNSET,
    },
  ]

  rowDrag = params => {
    const data: ResourcePlanNewRow = params.data
    return data && data.rowType === ResourcePlanType.MEMBER
  }

  onWorkloadUnitChanged = (ctx: ResourcePlanNewContext) => {
    ctx.gridApi!.refreshCells()
  }

  // TODO :Allows man-hours to be entered even in hours and man-days
  // toolBarItems = ctx => [
  //   <WorkloadUnitToggleGroup
  //     key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}
  //     refresh={value => ctx.onWorkloadUnitChanged(value)}
  //     value={ctx.state.workLoadUnit}
  //   />,
  // ]
}

function getManMonth(workMonth: any): number {
  if (workMonth && workMonth.manMonth) {
    const manMonth = Number(workMonth.manMonth)
    return Number.isNaN(manMonth) ? 0 : manMonth
  }
  return 0
}

function createLabelTeam(labelId: string): TeamProps {
  const labelDisplayName = intl.formatMessage({
    id: labelId,
  })
  const labelTeam: TeamProps = {
    uuid: generateUuid(),
    iconUrl: '',
    projectUuid: '',
    displayName: labelDisplayName,
    officialName: labelDisplayName,
  }
  return labelTeam
}

function iconShowCheck(type: string): boolean {
  let res = true
  if (type !== ResourcePlanType.MEMBER) res = false
  return res
}

async function getAllCanAddMemberList() {
  const today = DateVO.now().getStartOfDay()
  const list = await projectMember
    .getProjectMembers({
      projectUuid: store.getState().project.selected!,
    })
    .then(res => res.json)
    .then((json: any[]) =>
      json
        .filter(
          m =>
            (!m.user.validTo || new DateVO(m.user.validTo).isAfter(today)) &&
            (!m.projectReleasedDate ||
              new DateVO(m.projectReleasedDate).isAfter(today))
        )
        .map(m => m.user)
        .sort(function (a, b) {
          if (a.nameKana > b.nameKana) {
            return 1
          } else {
            return -1
          }
        })
    )
  return list
}

async function getTeamList() {
  const data = await team.searchAll()
  const list: MultiSelectDialogSelection[] = data.map(m => ({
    value: m.uuid,
    iconUrl: m.iconUrl,
    displayName: m.name,
  }))
  return list
}

function compareUnsetRow(nodeA, nodeB, isInverted) {
  if (nodeA && nodeA.data && nodeA.data.type === ResourcePlanType.UNSET) {
    return isInverted ? -1 : 1
  }
  if (nodeB && nodeB.data && nodeB.data.type === ResourcePlanType.UNSET) {
    return isInverted ? 1 : -1
  }
  return 0
}
