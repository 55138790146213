import { useCallback, useEffect, useState } from 'react'
import { NewsGroupForUI } from '../../../../../store/news'
import { Box, Button, ClickAwayListener, Typography } from '@mui/material'
import { BackgroundColor, Color } from '../../../../../styles/commonStyles'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import ProjectUnselectedIcon from '../../../../../assets/flagxs_tenant_icon.png'
import ProjectSelectedIcon from '../../../../../assets/project_selected_icon.png'
import { intl } from '../../../../../i18n'
import FilterNewsTypeCheckBox from './FilterNewsTypeCheckBox'
import ProjectMenu from '../../../SideMenu/commons/ProjectMenu'
import { colorPalette } from '../../../../style/colorPallete'

type NewsFilterProps = {
  selectedNewsGroups: NewsGroupForUI[]
  onChangeNewsGroups: (newsGroups: NewsGroupForUI[]) => void | Promise<void>
  onChangeProjectUuid: (selectedProjectUuid?: string) => void | Promise<void>
  selectedProjectUuid: string | undefined
}

const NewsFilter = ({
  selectedNewsGroups,
  onChangeNewsGroups,
  onChangeProjectUuid,
  selectedProjectUuid,
}: NewsFilterProps) => {
  const [projectFilterAnchorEl, setProjectFilterAnchorEl] = useState<Element>()
  const [filterAnchorEl, setFilterAnchorEl] = useState<Element>()
  const [openConditionFilter, setOpenConditionFilter] = useState<boolean>(false)
  const [openProjectFilter, setOpenProjectFilter] = useState<boolean>(false)
  const [isConditionfilter, setIsConditionFilter] = useState<boolean>(false)
  const [isProjectfilter, setIsProjectFilter] = useState<boolean>(false)

  const handleProjectMenuClose = () => {
    setProjectFilterAnchorEl(undefined)
    setOpenProjectFilter(false)
  }
  const handleFilterClose = () => {
    setFilterAnchorEl(undefined)
    setOpenConditionFilter(false)
  }

  const handleFilterChange = useCallback(
    newValue => {
      setIsConditionFilter(newValue)
    },
    [isConditionfilter]
  )

  useEffect(() => {
    if (!!selectedProjectUuid) {
      setIsProjectFilter(true)
    } else {
      setIsProjectFilter(false)
    }
  }, [selectedProjectUuid])

  return (
    <Box
      sx={{
        padding: '12px',
        display: 'flex',
        background: colorPalette.monotone[1],
      }}
    >
      <Box
        sx={{
          marginRight: 0,
          marginLeft: 'auto',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <ClickAwayListener onClickAway={handleFilterClose}>
          <div>
            <Button
              sx={{
                margin: '0 12px',
                minHeight: '32px',
                minWidth: '110px',
                background: isConditionfilter
                  ? colorPalette.skyBlue[0]
                  : BackgroundColor.WHITE,
                '&:hover': {
                  background: colorPalette.skyBlue[0],
                  border: `1px solid ${colorPalette.skyBlue[7]}`,
                  color: colorPalette.skyBlue[7],
                },
                color: () =>
                  isConditionfilter
                    ? colorPalette.skyBlue[7]
                    : colorPalette.monotone[4],
                border: () =>
                  isConditionfilter
                    ? `1px solid ${colorPalette.skyBlue[7]}`
                    : `1px solid ${colorPalette.monotone[2]}`,
              }}
              onClick={event => {
                if (openConditionFilter) {
                  setFilterAnchorEl(undefined)
                  setOpenConditionFilter(false)
                } else {
                  setFilterAnchorEl(event.currentTarget)
                  setOpenConditionFilter(true)
                }
              }}
            >
              <FilterAltOutlinedIcon
                sx={{ height: '20px', width: '20px', marginRight: '5px' }}
              />
              <Typography>
                {isConditionfilter
                  ? intl.formatMessage({ id: 'information.filter.active' })
                  : intl.formatMessage({ id: 'information.filter' })}
              </Typography>
            </Button>
            {openConditionFilter ? (
              <FilterNewsTypeCheckBox
                openMenu={!!filterAnchorEl}
                filterAnchorEl={filterAnchorEl}
                selectedNewsGroups={selectedNewsGroups}
                onChangeNewsGroups={onChangeNewsGroups}
                handleFilterChange={handleFilterChange}
              />
            ) : null}
          </div>
        </ClickAwayListener>
        <ClickAwayListener onClickAway={handleProjectMenuClose}>
          <Button
            sx={{
              minHeight: '32px',
              minWidth: () => (isProjectfilter ? '180px' : '130px'),
              background: isProjectfilter
                ? colorPalette.skyBlue[0]
                : BackgroundColor.WHITE,
              '&:hover': {
                background: colorPalette.skyBlue[0],
                border: `1px solid ${colorPalette.skyBlue[7]}`,
                color: colorPalette.skyBlue[7],
              },
              color: () =>
                isProjectfilter ? colorPalette.skyBlue[7] : Color.MONOTONE,
              border: () =>
                isProjectfilter
                  ? `1px solid ${colorPalette.skyBlue[7]}`
                  : `1px solid ${colorPalette.monotone[2]}`,
            }}
            onClick={event => {
              if (openProjectFilter) {
                setProjectFilterAnchorEl(undefined)
                setOpenProjectFilter(false)
              } else {
                setProjectFilterAnchorEl(event.currentTarget)
                setOpenProjectFilter(true)
              }
            }}
          >
            <img
              src={
                openProjectFilter ? ProjectSelectedIcon : ProjectUnselectedIcon
              }
              style={{ height: '16px', width: '16px', marginRight: '5px' }}
            />
            <Typography>
              {isProjectfilter
                ? intl.formatMessage({
                    id: 'information.filter.selected.project',
                  })
                : intl.formatMessage({ id: 'information.filter.project' })}
            </Typography>
          </Button>
        </ClickAwayListener>
        <ProjectMenu
          openMenu={!!projectFilterAnchorEl}
          anchorEl={projectFilterAnchorEl}
          onChange={onChangeProjectUuid}
          isNewsProjectFilter={true}
          selectedProjectUuid={selectedProjectUuid}
        />
      </Box>
    </Box>
  )
}

export default NewsFilter
