import api from '../../lib/commons/api'
import { VersionReportTemplateRepository } from '../../applications/ports/versionReportTemplateRepository'
import { useCallback } from 'react'

export const useVersionReportTemplateRepository =
  (): VersionReportTemplateRepository => {
    const fetchOne = useCallback(async (projectUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/version-report/template',
        { projectUuid }
      )
      return response.json
    }, [])
    return {
      fetchOne,
    }
  }
