import {
  CellClassParams,
  GridOptions,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import _ from 'lodash'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  AccountCategory,
  BudgetResultType,
  ProfitLossItemAmountRowBody,
  ProfitLossItemRow,
} from '../profitLossItems'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { intl } from '../../../../i18n'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import objects from '../../../../utils/objects'
import './styles.scss'
import { AUTO_COLUMN_ID } from '../../../containers/BulkSheet/const'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import {
  financialStatementAccountsColDef,
  generalLedgerAccountsColDef,
  subsidiaryAccountsColDef,
  processCellForClipboard,
  processCellFromClipboard,
  remarksColDef,
} from '.'
import { ClientSideTextFilter } from '../../../containers/BulkSheetView/components/filter'
import MultiLineTextCell from '../../../containers/commons/AgGrid/components/cell/custom/multiLineText'

export const PROFIT_LOSS_COLGROUP_ID_INFOMATION: string = 'information'

export const profitLossItemsSimpleGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: false,
    rowDragManaged: false,
    rowDragMultiRow: false,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        profit_loss_root_infomation_group_column: (
          params: CellClassParams<ProfitLossItemRow>
        ) =>
          isRootRow(params.data) &&
          (params.column.getParent()?.getGroupId() ===
            PROFIT_LOSS_COLGROUP_ID_INFOMATION ||
            params.colDef.colId === AUTO_COLUMN_ID),
        profit_loss_root_row: params => isRootRow(params.data),
      },
    },
    columnDefs: [
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        valueGetter: params => {
          if (params.data.isTotal) {
            return isRootRow(params.node?.data)
              ? intl.formatMessage({ id: 'total' })
              : ''
          }
          return (!!params.node?.rowIndex ? params.node?.rowIndex : 0) + 1
        },
      },
      {
        groupId: PROFIT_LOSS_COLGROUP_ID_INFOMATION,
        headerName: intl.formatMessage({ id: 'profitLossItems.information' }),
        children: [
          {
            ...financialStatementAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.financialState?.displayName ?? '',
              }
            },
            editable: params => {
              const category = params.context.accountCategory
              return (
                !params.data.isTotal &&
                isRootRow(params.data) &&
                category === AccountCategory.OperatingExpenses
              )
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.financialState?.uuid
            },
            valueSetter: (params: ValueSetterParams) => {
              const { colDef, data, oldValue, newValue } = params
              const field = colDef.field || colDef.colId
              if (!field || (!oldValue && !newValue) || oldValue === newValue) {
                return false
              }
              const masters = params.context.masters
              const masterItem = masters?.find(v => v.uuid === newValue)
              if (!masterItem) return false
              objects.setValue(data, field, masterItem)
              if (!data.editedData) {
                data.editedData = { [field]: oldValue }
              } else if (!data.editedData.hasOwnProperty(field)) {
                data.editedData[field] = oldValue
              }

              const oldGeneralLedgerValue = data.body.generalLedger
              data.body.generalLedger = undefined
              const generalLedgerField = 'body.generalLedger'
              if (!data.editedData) {
                data.editedData = {
                  [generalLedgerField]: oldGeneralLedgerValue,
                }
              } else if (!data.editedData.hasOwnProperty(generalLedgerField)) {
                data.editedData[generalLedgerField] = oldGeneralLedgerValue
              }

              const oldSubsidiaryValue = data.body.subsidiary
              data.body.subsidiary = undefined
              const subsidiaryField = 'body.subsidiary'
              if (!data.editedData) {
                data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
              } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
                data.editedData[subsidiaryField] = oldSubsidiaryValue
              }
              params.api.forEachNode(node => {
                if (
                  node.data.parentUuid === data.uuid &&
                  !isRootRow(node.data)
                ) {
                  objects.setValue(node.data, field, masterItem)
                  node.data.body.generalLedger = undefined
                  node.data.body.subsidiary = undefined
                }
              })
              store.dispatch(requireSave())
              params.api.redrawRows()
              return true
            },
          },
          {
            ...generalLedgerAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.generalLedger?.displayName ?? '',
              }
            },
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.generalLedger?.uuid
            },
            valueSetter: (params: ValueSetterParams) => {
              const { colDef, data, oldValue, newValue } = params
              const field = colDef.field || colDef.colId
              if (!field || (!oldValue && !newValue) || oldValue === newValue) {
                return false
              }
              const masters = params.context.masters
              const masterItem = masters
                ?.find(v => v.uuid === data.body.financialState.uuid)
                ?.children.find(v => v.uuid === newValue)
              if (!masterItem) return false
              objects.setValue(data, field, masterItem)
              if (!data.editedData) {
                data.editedData = { [field]: oldValue }
              } else if (!data.editedData.hasOwnProperty(field)) {
                data.editedData[field] = oldValue
              }

              const oldSubsidiaryValue = data.body.subsidiary
              data.body.subsidiary = undefined
              const subsidiaryField = 'body.subsidiary'
              if (!data.editedData) {
                data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
              } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
                data.editedData[subsidiaryField] = oldSubsidiaryValue
              }

              params.api.forEachNode(node => {
                if (
                  node.data.parentUuid === data.uuid &&
                  !isRootRow(node.data)
                ) {
                  objects.setValue(node.data, field, masterItem)
                  node.data.body.subsidiary = undefined
                }
              })

              store.dispatch(requireSave())
              return true
            },
          },
          {
            ...subsidiaryAccountsColDef,
            cellRendererParams: params => {
              return {
                value: params.data?.body?.subsidiary?.displayName ?? '',
              }
            },
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) return ''
              return params.data.body?.subsidiary?.uuid
            },
          },
          remarksColDef,
          {
            field: 'body.name',
            headerName: intl.formatMessage({ id: 'profitLossItems.name' }),
            width: 250,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            editable: params => {
              return !params.data.isTotal && isRootRow(params.data)
            },
            cellClassRules: {
              profit_loss_same_row: params => isBeforeSameData(params),
            },
            valueGetter: params => {
              return params.data.body.name
            },
            valueSetter: params => {
              const { colDef, data, oldValue, newValue } = params
              const field = colDef.field || colDef.colId
              if (!field || (!oldValue && !newValue) || oldValue === newValue) {
                return false
              }
              objects.setValue(data, field, newValue)
              if (!data.editedData) {
                data.editedData = { [field]: oldValue }
              } else if (!data.editedData.hasOwnProperty(field)) {
                data.editedData[field] = oldValue
              }
              params.api.forEachNode((node, index) => {
                if (
                  node.data.treeValue.length === 2 &&
                  node.data.treeValue.includes(data.treeValue[0])
                ) {
                  objects.setValue(node.data, field, newValue)
                }
              })
              store.dispatch(requireSave())
              return true
            },
          },
          {
            field: 'type',
            headerName: intl.formatMessage({ id: 'profitLossItems.type' }),
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            valueGetter: params => {
              if (isRootRow(params.data)) {
                return intl.formatMessage({ id: 'profitLossItems.forecast' })
              } else if (params.data.body.type === BudgetResultType.Budget) {
                return intl.formatMessage({ id: 'profitLossItems.budget' })
              } else if (params.data.body.type === BudgetResultType.Result) {
                return intl.formatMessage({ id: 'profitLossItems.result' })
              } else if (
                params.data.body.type === BudgetResultType.BudgetaryAmount
              ) {
                return intl.formatMessage({
                  id: 'profitLossItems.budgetaryAmount',
                })
              }
              return ''
            },
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'total' }),
            hide: false,
            sortable: true,
            pinned: true,
            lockPosition: 'right',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) {
                let sumValue = 0
                const summaryType = params.data.body.type
                params.api.forEachNode(node => {
                  if (
                    !!node.data &&
                    !node.data.isTotal &&
                    node.data.body?.type === summaryType
                  ) {
                    const amounts = node.data.body?.amounts ?? []
                    const tempSum = amounts.reduce((prev, current) => {
                      return prev + (current.amount ?? 0)
                    }, 0)
                    sumValue += tempSum
                  }
                })
                return sumValue
              }
              const amounts: ProfitLossItemAmountRowBody[] =
                params.data?.body?.amounts ?? []
              const sum = amounts.reduce((prev, current) => {
                return prev + (current.amount ?? 0)
              }, 0)
              return sum
            },
            cellClass: 'numberStyle',
          },
          {
            field: 'ratio',
            headerName: intl.formatMessage({
              id: 'profitLossItems.achievement.ratio',
            }),
            width: 60,
            hide: false,
            sortable: false,
            pinned: true,
            lockPosition: 'right',
            editable: false,
            cellRenderer: NumberCellRenderer,
            valueGetter: (params: ValueGetterParams) => {
              const data: ProfitLossItemRow = params.data
              if (!data) return undefined
              let sumTotal = 0
              if (!!data.isTotal && isRootRow(data)) {
                let sumBudget = 0
                let sumForecast = 0
                params.api.forEachNode(node => {
                  if (!!node.data && !node.data.isTotal) {
                    if (isRootRow(node.data)) {
                      const sumTemp = (node.data.body?.amounts ?? []).reduce(
                        (prev, current) => {
                          return prev + (current.amount ?? 0)
                        },
                        0
                      )
                      sumForecast += sumTemp
                    } else if (
                      node.data.body?.type === BudgetResultType.BudgetaryAmount
                    ) {
                      const sumTemp = (node.data.body?.amounts ?? []).reduce(
                        (prev, current) => {
                          return prev + (current.amount ?? 0)
                        },
                        0
                      )
                      sumBudget += sumTemp
                    }
                  }
                })
                if (!!sumBudget && !!sumForecast) {
                  sumTotal = sumForecast / sumBudget
                }
              } else if (
                params.node?.data?.body?.type ===
                BudgetResultType.BudgetaryAmount
              ) {
                return undefined
              } else if (
                !!data.isTotal &&
                (data.body?.type === BudgetResultType.Budget ||
                  data.body?.type === BudgetResultType.Result)
              ) {
                let sumForecast = 0
                let sumTarget = 0
                params.api.forEachNode((node, index) => {
                  if (
                    node.data.body.type === BudgetResultType.BudgetaryAmount
                  ) {
                    sumForecast += (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  } else if (node.data.body.type === data.body?.type) {
                    sumTarget += (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  }
                })
                if (!!sumForecast && !!sumTarget) {
                  sumTotal = sumTarget / sumForecast
                }
              } else if (
                !data.isTotal &&
                (data.body?.type === BudgetResultType.Budget ||
                  data.body?.type === BudgetResultType.Result)
              ) {
                let sumForecast = 0
                params.api.forEachNode((node, index) => {
                  if (
                    node.data.treeValue.includes(
                      params.node?.data.treeValue[0]
                    ) &&
                    node.data.body.type === BudgetResultType.BudgetaryAmount
                  ) {
                    sumForecast = (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  }
                })
                const sumTarget = (data.body?.amounts ?? []).reduce(
                  (prev, current) => {
                    return prev + (current.amount ?? 0)
                  },
                  0
                )
                if (!!sumForecast && !!sumTarget) {
                  sumTotal = sumTarget / sumForecast
                }
              } else if (!data.isTotal && !!params.node?.childrenMapped) {
                const children: RowNode<ProfitLossItemRow>[] = Object.values(
                  params.node.childrenMapped
                ).filter(v => !!v)
                const budgetRow = children.find(
                  v => BudgetResultType.BudgetaryAmount === v.data?.body?.type
                )?.data
                if (!!budgetRow) {
                  const sumForecast = (data.body?.amounts ?? []).reduce(
                    (prev, current) => {
                      return prev + (current.amount ?? 0)
                    },
                    0
                  )
                  const sumBudget = (budgetRow.body.amounts ?? []).reduce(
                    (prev, current) => {
                      return prev + (current.amount ?? 0)
                    },
                    0
                  )
                  if (!!sumForecast && !!sumBudget) {
                    sumTotal = sumForecast / sumBudget
                  }
                }
              }
              return `${(sumTotal * 100).toFixed(2)}%`
            },
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0',
        },
      },
    ],
    processCellForClipboard,
    processCellFromClipboard,
  }
}

export const isRootRow = data => {
  return !Object.values(BudgetResultType).includes(data?.body?.type)
}

const isBeforeSameData = params => {
  return isBeforeSameNode(params.api, params.node, params.data)
}

const isBeforeSameNode = (api, node, data) => {
  let isSame = false
  let beforeRow: RowNode | undefined = undefined
  if (!data) return false
  api.forEachNodeAfterFilterAndSort((v, _) => {
    if (v.id === node.id) {
      isSame = !!(
        !!beforeRow && beforeRow.data.treeValue.includes(data.treeValue[0])
      )
      if (isSame) return
    }
    beforeRow = v
  })
  return isSame
}
