import { useCallback, useEffect, useRef, useState } from 'react'
import { StatusKanbanSearchCondition } from './useSearchCondition'
import { useStatusKanbanRepository } from '../../../../services/adaptors/KanbanRepositoryAdaptor'
import {
  CardContent,
  CardData,
  ColumnDef,
  Column,
  SelectOption,
  EditableCardItem,
} from '../Kanban'
import { WbsItemStatus } from '../../../../domain/entity/WbsItemEntity'
import WbsItemApi, {
  WbsItemBatchDeltaRequest,
  WbsItemDeltaInput,
  WbsItemSearchDetail,
  createRowByResponse,
  getWbsItemStatusColorCode,
} from '../../../../lib/functions/wbsItem'
import { colorPalette } from '../../../style/colorPallete'
import { useWbsItemSearchConditionApiRequestTransformService } from '../../../../services/transform-service/wbsItemSearchConditionApiRequestTransformService'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { formatDateForApi, hasDiffDateTerm } from '../../../../utils/date'
import { APIResponse } from '../../../../lib/commons/api'
import { useDispatch } from 'react-redux'
import { MessageLevel, addScreenMessage } from '../../../../store/messages'
import { intl } from '../../../../i18n'
import useSort, { SortConfig } from './useSort'
import Workload from '../../../../lib/functions/workload'

export interface Props {
  functionUuid: string
  projectUuid: string
  searchCondition: StatusKanbanSearchCondition
  searchConditionInitialized: boolean
  priorityOptions: SelectOption[]
}

export const useStatusKanbanData = ({
  functionUuid,
  projectUuid,
  searchCondition,
  searchConditionInitialized,
  priorityOptions,
}: Props): {
  columns: Column[]
  setColumns: React.Dispatch<React.SetStateAction<Column[]>>
  cards: { [id: string]: CardData }
  updateCard: (id: string, field: EditableCardItem, changed: any) => void
  refresh: () => void
  refreshSingle: (code: string) => void
  save: () => Promise<APIResponse>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  sortCards: (sortConfig: SortConfig) => void
} => {
  const dispatch = useDispatch()
  const [cards, setCards] = useState<{ [id: string]: CardData }>({})
  const [columns, setColumns] = useState<Column[]>([])
  const [columnDefs] = useState<ColumnDef[]>(
    [
      WbsItemStatus.DISCARD,
      ...Object.values(WbsItemStatus).filter(v => v !== WbsItemStatus.DISCARD),
    ].map(status => ({
      id: status,
      title: status,
      backgroundColor:
        status === WbsItemStatus.TODO
          ? colorPalette.monotone[0]
          : getWbsItemStatusColorCode(status),
    }))
  )
  const [isLoading, setIsLoading] = useState(true)
  const originalData = useRef<Map<string, CardData>>(new Map())

  const { sort } = useSort(priorityOptions)

  const { get, getCount } = useStatusKanbanRepository()
  const { toApiRequest } =
    useWbsItemSearchConditionApiRequestTransformService(projectUuid)

  const refresh = useCallback(async () => {
    setIsLoading(true)
    const request = toApiRequest(searchCondition)
    const itemCount = await getCount(request)
    // resopnse.countの合計が2000を超える場合はワーニングを表示する
    if (itemCount.hit > 2000) {
      dispatch(
        addScreenMessage(functionUuid, {
          type: MessageLevel.WARN,
          title: intl.formatMessage({ id: 'global.warning.businessError' }),
          text: intl.formatMessage({ id: 'kanban.count.over.limit' }),
        })
      )
      setIsLoading(false)
      return
    }

    const response = await get(request)

    setColumns([])
    setCards({})
    originalData.current.clear()

    columnDefs.forEach(columnDef => {
      const column = response.find(res => res.id === columnDef.id)
      const cards = column?.cards.map(createCardByResponse) ?? []
      const cardIds = cards.map(card => card.id)
      setColumns(prev => [
        ...prev,
        {
          ...columnDef,
          cardIds,
          cumulationData: {
            count: column?.count ?? 0,
            estimatedHour: column?.estimatedHour ?? 0,
            actualHour: column?.actualHour ?? 0,
          },
        },
      ])
      setCards(prev => ({
        ...prev,
        ...cards.reduce((acc, card) => ({ ...acc, [card.id]: card }), {}),
      }))
      cards.forEach(card => {
        originalData.current.set(card.id, { ...card })
      })
    })

    setIsLoading(false)
  }, [searchCondition])

  const generateCardDelta = useCallback(
    (before: CardContent, after: CardContent): Partial<WbsItemDeltaInput> => {
      return {
        uuid: before.uuid,
        type: before.type,
        status: createDelta(before.status, after.status),
        displayName: createDelta(before.displayName, after.displayName),
        teamUuid: createDelta(before.team?.uuid, after.team?.uuid),
        accountableUuid: createDelta(
          before.accountable?.uuid,
          after.accountable?.uuid
        ),
        responsibleUuid: createDelta(
          before.responsible?.uuid,
          after.responsible?.uuid
        ),
        assigneeUuid: createDelta(before.assignee?.uuid, after.assignee?.uuid),
        estimatedHour: createDelta(
          before.estimatedWorkload?.hour,
          after.estimatedWorkload?.hour
        ),
        priority: createDelta(before.priority, after.priority),
        scheduledDate: hasDiffDateTerm(
          before.scheduledDate,
          after.scheduledDate
        )
          ? {
              oldValue: before.scheduledDate
                ? {
                    startDate: formatDateForApi(before.scheduledDate.startDate),
                    endDate: formatDateForApi(before.scheduledDate.endDate),
                  }
                : undefined,
              newValue: after.scheduledDate ?? {
                startDate: undefined,
                endDate: undefined,
              },
            }
          : undefined,
        sprintUuid: createDelta(before.sprint?.uuid, after.sprint?.uuid),
      } as WbsItemDeltaInput
    },
    []
  )

  const refreshInner = useCallback(() => {
    originalData.current.clear()
    setCards(prev => {
      const newCards = Object.values(prev).map(card => {
        if (card.edited) {
          return { ...card, edited: false }
        }
        return card
      })
      newCards.forEach(card => {
        originalData.current.set(card.id, { ...card })
      })
      return newCards.reduce((acc, card) => ({ ...acc, [card.id]: card }), {})
    })
  }, [])

  const save = useCallback(async (): Promise<APIResponse> => {
    const cardDeltaInputs = Object.values(cards)
      .filter(card => card.edited && originalData.current.has(card.id))
      .map(card => {
        const original = originalData.current.get(card.id)
        const originalContent = original!.content
        const editedContent = card.content
        return generateCardDelta(originalContent, editedContent)
      })
    const request: WbsItemBatchDeltaRequest = {
      wbsItems: {
        projectUuid: projectUuid,
        added: [],
        edited: cardDeltaInputs as WbsItemDeltaInput[],
        deleted: [],
      },
      watchers: [],
      tags: [],
    }
    const response = await WbsItemApi.updateBatchDelta(request)
    refreshInner()
    return response
  }, [cards, refreshInner, projectUuid])

  const updateCumulationDataByStatus = useCallback(
    (prevCard: CardContent, updatedValue: WbsItemStatus) => {
      setColumns(prevColumns => {
        return prevColumns.map(column => {
          if (column.id === prevCard.status) {
            const subtractedCumulationData = {
              count: column.cumulationData.count - 1,
              estimatedHour:
                column.cumulationData.estimatedHour -
                (prevCard.estimatedWorkload?.hour ?? 0),
              actualHour:
                column.cumulationData.actualHour - (prevCard.actualHour ?? 0),
            }
            column.cumulationData = subtractedCumulationData
          }
          if (column.id === updatedValue) {
            const addedCumulationData = {
              count: column.cumulationData.count + 1,
              estimatedHour:
                column.cumulationData.estimatedHour +
                (prevCard.estimatedWorkload?.hour ?? 0),
              actualHour:
                column.cumulationData.actualHour + (prevCard.actualHour ?? 0),
            }
            column.cumulationData = addedCumulationData
          }
          return column
        })
      })
    },
    []
  )
  const updateCumulationDataByEstimatedWorkload = useCallback(
    (prevCard: CardContent, updatedValue: Workload) => {
      setColumns(prevColumns => {
        return prevColumns.map(column => {
          if (column.id === prevCard.status) {
            const calculatedCumulationData = {
              count: column.cumulationData.count,
              estimatedHour:
                column.cumulationData.estimatedHour -
                (prevCard.estimatedWorkload?.hour ?? 0) +
                updatedValue.hour,
              actualHour: column.cumulationData.actualHour,
            }
            column.cumulationData = calculatedCumulationData
          }
          return column
        })
      })
    },
    []
  )

  const updateCard = useCallback(
    (id: string, field: EditableCardItem, changed: any) => {
      setCards(prev => {
        const card = prev[id]
        if (!card) {
          return prev
        }
        if (card.content[field] === changed) {
          return prev
        }
        // update StatusColumnCumulationData
        field === 'status' &&
          updateCumulationDataByStatus(card.content, changed)
        field === 'estimatedWorkload' &&
          updateCumulationDataByEstimatedWorkload(card.content, changed)
        const newContent = { ...card.content, [field]: changed }
        return {
          ...prev,
          [id]: { id, content: newContent, edited: true },
        }
      })
    },
    [updateCumulationDataByStatus, updateCumulationDataByEstimatedWorkload]
  )

  const refreshSingle = useCallback(
    async (code: string) => {
      setIsLoading(true)
      const request = toApiRequest(searchCondition)
      request.code = code
      const response = await get(request)
      if (!response.length) {
        setIsLoading(false)
        return
      }
      const cardFromApi = response[0].cards[0]
      if (!cardFromApi) {
        setIsLoading(false)
        return
      }
      const originalStatus = originalData.current.get(cardFromApi.uuid)?.content
        .status
      if (cardFromApi.status !== originalStatus) {
        setColumns(prev => {
          const fromColumn = prev.find(column => column.id === originalStatus)
          const deletedIndex = fromColumn?.cardIds.indexOf(cardFromApi.uuid)
          fromColumn?.cardIds.splice(deletedIndex!, 1)

          const toColumn = prev.find(column => column.id === cardFromApi.status)
          toColumn?.cardIds.push(cardFromApi.uuid)
          return prev
        })
      }
      const newCard = createCardByResponse(cardFromApi)
      setCards(prev => ({ ...prev, [cardFromApi.uuid]: newCard }))
      originalData.current.set(cardFromApi.uuid, { ...newCard })

      setIsLoading(false)
    },
    [searchCondition]
  )

  const sortCards = useCallback(
    (sortConfig: SortConfig) => {
      setColumns(prev => {
        return prev.map(column => {
          if (column.cardIds.length <= 1) {
            return column
          }
          const cardContents = column.cardIds.map(id => cards[id].content)
          const sortedCardIds = sort(sortConfig, cardContents)
          column.cardIds = sortedCardIds
          return column
        })
      })
    },
    [cards, sort]
  )

  useEffect(() => {
    searchConditionInitialized && refresh()
  }, [searchConditionInitialized, refresh])

  return {
    columns,
    setColumns,
    cards,
    updateCard,
    save,
    refresh,
    refreshSingle,
    isLoading,
    setIsLoading,
    sortCards,
  }
}

const createCardByResponse = (wbsItem: WbsItemSearchDetail): CardData => {
  const cardContent: CardContent = createRowByResponse(
    wbsItem,
    wbsItem.cumulation
  )
  cardContent.path = wbsItem.path

  return {
    id: wbsItem.uuid,
    content: cardContent,
  }
}
