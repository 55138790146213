import React, { useCallback, useEffect, useState } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { DayColor } from '../../../../../../../../styles/commonStyles'
import { Fade } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { CalendarDateVO } from '../../../../../../../../domain/value-object/CalendarDateVO'
import { GanttReportParameter } from './ganttUtil'
import DateVO from '../../../../../../../../vo/DateVO'
import { intl } from '../../../../../../../../i18n'

export const GanttHeader = injectIntl(
  (props: IHeaderParams & WrappedComponentProps) => {
    const [fade, setFade] = useState(false)
    useEffect(() => {
      setTimeout(() => setFade(true), 50)
    })

    const timeScale: CalendarDateVO[] =
      props.context.ganttTimeScale?.slice(
        0,
        props.context.ganttTimeScale?.length - 1
      ) ?? []
    const parameter: GanttReportParameter = props.context.ganttParameter
    const today = CalendarDateVO.of(new Date())

    const format = useCallback(
      (date: CalendarDateVO): string => {
        const { step, unit } = parameter
        const value = new DateVO(date.date)
        if (unit === 'day') return value.format('D')
        if (unit === 'week') {
          return value.format('D') + intl.formatMessage({ id: 'day.ja' })
        }
        if (unit === 'month') {
          if (step === 1) {
            return value.format('M') + intl.formatMessage({ id: 'month' })
          } else if (step === 3) {
            return `${Math.floor(value.getMonth() / step) + 1}Q`
          } else {
            return value.getMonth() === 1
              ? intl.formatMessage({ id: 'firstHalf' })
              : intl.formatMessage({ id: 'secondHalf' })
          }
        }
        if (unit === 'year') return value.format('YYYY')
        return ''
      },
      [parameter]
    )

    return (
      <Fade in={fade} timeout={300}>
        <div className="sevend-ag-cell-gantt-header">
          {timeScale.map((v: CalendarDateVO, i: number) => {
            const color =
              parameter.unit === 'day' && v.isHoliday()
                ? DayColor.HOLIDAY
                : undefined
            return (
              <GanttDateHeader
                key={`gantt-header-${i}`}
                today={
                  +(
                    today.isEqual(v) ||
                    (parameter.unit !== 'day' &&
                      timeScale[i + 1] &&
                      today.isBetween(v, timeScale[i + 1]) &&
                      !today.isEqual(timeScale[i + 1]))
                  )
                }
                holiday={+v.isHoliday()}
              >
                <span style={{ color }}>{format(v)}</span>
              </GanttDateHeader>
            )
          })}
        </div>
      </Fade>
    )
  }
)

export const GanttDateHeader = ({ children, today, holiday }) => {
  let className = 'sevend-ag-cell-gantt-header__date-header'
  if (today) {
    className += ' sevend-ag-cell-gantt-header__date-header--today'
  } else if (holiday) {
    className += ' sevend-ag-cell-gantt-header__date-header--holiday'
  }
  return <div className={className}>{children}</div>
}
