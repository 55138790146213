import { createContext, Provider, useContext } from 'react'

/**
 *
 * @returns
 */
export const createStrictContext = <ContextType>(): [
  () => NonNullable<ContextType>,
  Provider<ContextType | undefined>
] => {
  const context = createContext<ContextType | undefined>(undefined)
  const useStrictContext = () => {
    const ctx = useContext(context)
    if (!ctx) {
      throw new Error(
        'useStrictContext must be used inside a Provider which assure the existence of value'
      )
    }
    return ctx
  }
  return [useStrictContext, context.Provider]
}
