import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Paper,
  PaperProps,
} from '@mui/material'
import { styled } from '@mui/system'
import Draggable from 'react-draggable'
import { ResizableBox } from 'react-resizable'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { Revision } from '../../../../lib/functions/changeLog'
import { formatDateTimeWithDay } from '../../../../utils/date'
import Markdown from '../../editors/markdown/Markdown'
import { MarkdownType } from '../../editors/multiline-text/MultilineTextEditor'
import { muiTheme } from '../../../../styles/muiTheme'

const initialWidth = Math.min(500, globalThis.innerWidth)

const theme = muiTheme
const DialogTitleWrapper = styled('div')({
  display: 'flex',
  flexFlow: 'column',
  height: '100%',
})
const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  flex: '0 1 auto',
  cursor: 'move',
  padding: theme.spacing(1),
})
const StyledDialogContent = styled(DialogContent)({
  flex: '1 1 auto',
  borderTop: 'solid 1px #C4C4C4',
  padding: 0,
})

const DraggablePaper = (props: PaperProps) => {
  return (
    <Draggable
      handle="#revisionList-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}
interface Props extends WrappedComponentProps {
  text: string
  revision: Revision
}

class MultilineTextDialog extends React.Component<Props> {
  state = {
    open: false,
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <>
        <Link onClick={this.handleClickOpen} style={{ cursor: 'pointer' }}>
          {this.props.intl.formatMessage({
            id: 'revisionList.label.view',
          })}
        </Link>
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          PaperComponent={DraggablePaper}
          maxWidth={false}
          aria-labelledby="revisionList-dialog-title"
          sx={{
            '& .MuiPaper-root': {
              position: 'absolute',
              top: 60,
              left: (globalThis.innerWidth - initialWidth) / 2,
              overflow: 'hidden',
            },
          }}
        >
          <ResizableBox
            height={Math.min(600, globalThis.innerHeight)}
            width={initialWidth}
            className="react-resizable"
          >
            <DialogTitleWrapper>
              <StyledDialogTitle id="revisionList-dialog-title">
                <div>
                  {this.props.intl.formatMessage({
                    id: 'revisionList.header.updatedBy',
                  })}
                  {` ： ${this.props.revision.updatedBy?.name || ''}`}
                </div>
                <div>
                  {this.props.intl.formatMessage({
                    id: 'revisionList.header.updatedAt',
                  })}
                  {` ： ${formatDateTimeWithDay(
                    this.props.revision.updatedAt
                  )}`}
                </div>
              </StyledDialogTitle>
              <StyledDialogContent>
                <Markdown
                  value={this.props.text}
                  onChange={() => {
                    // do nothing
                    // Consider component separation.
                  }}
                  markdownType={MarkdownType.Preview}
                />
              </StyledDialogContent>
            </DialogTitleWrapper>
          </ResizableBox>
        </Dialog>
      </>
    )
  }
}

export default injectIntl(MultilineTextDialog)
