import {
  RefinementEntity,
  UpdateRefinementDeltaInput,
} from '../../../../domain/entity/ticket/RefinementEntity'
import { generateUuid } from '../../../../utils/uuids'
import {
  isBooleanValueDiffered,
  isReferencedEntityValueDiffered,
  isSelectValueDiffered,
  isTextValueDiffered,
  isDateValueDiffered,
  toBooleanDeltaValue,
  toReferencedEntityDeltaValue,
  toSelectDeltaValue,
  toTextDeltaValue,
  toDateDeltaValue,
} from './properties'

export type RefinementFormModel = Omit<RefinementEntity, 'ticket'>
type RefinementField = keyof Omit<RefinementFormModel, 'uuid'>

export const createRefinementInitialValue = (): RefinementFormModel => {
  const uuid = generateUuid()
  return {
    uuid,
  }
}
export const toRefinementDeltaInput = (
  entity: RefinementEntity,
  model: RefinementFormModel
): Omit<UpdateRefinementDeltaInput, 'ticket'> => {
  const input: Omit<UpdateRefinementDeltaInput, 'ticket'> = {
    uuid: entity.uuid,
  }
  // TODO: Consider to simplify, maybe by defining field properties.
  const updatableFields: RefinementField[] = [
    'knownRefinement',
    'originalRefinement',
    'detectionPhase',
    'environment',
    'domain',
    'featureType',
    'feature',
    'defectEvent',
    'occurrenceProcedure',
    'testSpecification',
    'testCase',
    'importance',
    'impact',
    'desiredDeliveryDate',
    'cause',
    'directCause',
    'rootCause',
    'properDetectionPhase',
    'interimAction',
    'permanentAction',
    'documentsToBeCorrected',
    'horizontalDeploymentTarget',
    'horizontalDeploymentContent',
  ]
  for (let field of updatableFields) {
    if (isValueDiffered(entity, model, field)) {
      input[field] = toDeltaValue(entity, model, field)
    }
  }
  return input
}

const isValueDiffered = (
  entity: RefinementEntity,
  model: RefinementFormModel,
  path: RefinementField
) => {
  switch (path) {
    case 'knownRefinement':
    case 'horizontalDeploymentTarget':
      return isBooleanValueDiffered(entity[path], model[path])
    case 'originalRefinement':
      return isReferencedEntityValueDiffered(entity[path], model[path])
    case 'detectionPhase':
    case 'environment':
    case 'domain':
    case 'featureType':
    case 'importance':
    case 'impact':
    case 'cause':
    case 'properDetectionPhase':
      return isSelectValueDiffered(entity[path], model[path])
    case 'feature':
    case 'defectEvent':
    case 'occurrenceProcedure':
    case 'testSpecification':
    case 'testCase':
    case 'directCause':
    case 'rootCause':
    case 'interimAction':
    case 'permanentAction':
    case 'documentsToBeCorrected':
    case 'horizontalDeploymentContent':
      return isTextValueDiffered(entity[path], model[path])
    case 'desiredDeliveryDate':
      return isDateValueDiffered(entity[path], model[path])
  }
  return false
}

const toDeltaValue = (
  entity: RefinementEntity,
  model: RefinementFormModel,
  path: RefinementField
) => {
  switch (path) {
    case 'knownRefinement':
    case 'horizontalDeploymentTarget':
      return toBooleanDeltaValue(entity[path], model[path])
    case 'originalRefinement':
      return toReferencedEntityDeltaValue(entity[path], model[path])
    case 'detectionPhase':
    case 'environment':
    case 'domain':
    case 'featureType':
    case 'importance':
    case 'impact':
    case 'cause':
    case 'properDetectionPhase':
      return toSelectDeltaValue(entity[path], model[path])
    case 'feature':
    case 'defectEvent':
    case 'occurrenceProcedure':
    case 'testSpecification':
    case 'testCase':
    case 'directCause':
    case 'rootCause':
    case 'interimAction':
    case 'permanentAction':
    case 'documentsToBeCorrected':
    case 'horizontalDeploymentContent':
      return toTextDeltaValue(entity[path], model[path])
    case 'desiredDeliveryDate':
      return toDateDeltaValue(entity[path], model[path])
  }
  return undefined
}
