import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import moment from 'moment'
import DateVO from '../../../../vo/DateVO'
import DateSelect, { DateSelectType } from './DateSelect'

const RootDiv = styled('div')({
  width: '100px',
  padding: '10px 0',
  display: 'flex',
  zIndex: 3,
  margin: '0px 8px',
})

interface Props {
  year: number
  month: number
  search: (searchCondition: any) => void
}

const MonthSelect = (props: Props) => {
  const [year, setYear] = useState<number>(props.year ?? moment().year())
  const [month, setMonth] = useState<number>(
    props.month ?? moment().month() + 1
  )
  useEffect(() => {
    if (props.year && props.year !== year) setYear(props.year)
    if (props.month && props.month !== month) setMonth(props.month)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.year, props.month]) // Make eslint disable because the bug can not change year and month occur if we add them to deps.

  const search = useCallback(_.debounce(props.search, 300), [props.search])
  const onChange = useCallback(
    value => {
      if (!value) return
      const date = new DateVO(value)
      const newYear = date.getYear()
      const newMonth = date.getMonth()
      setYear(newYear)
      setMonth(newMonth)

      search({ year: newYear, month: newMonth })
    },
    [search]
  )

  return (
    <RootDiv>
      <DateSelect
        type={DateSelectType.YEAR_MONTH}
        value={new DateVO(`${year}-${month}-1`)}
        onChange={onChange}
      />
    </RootDiv>
  )
}

export default MonthSelect
