import { styled } from '@mui/system'
import { colorPalette } from '../../../../style/colorPallete'
import MoreVertIcon from '@mui/icons-material/MoreVertRounded'
import { useCallback, useMemo, useState, MouseEvent, useRef } from 'react'
import {
  ClickAwayListener,
  Divider,
  ListItemButton,
  Paper,
  Popper,
} from '@mui/material'
import { intl } from '../../../../../i18n'
import { HeaderIconButton } from './common'

type VersionReportMenuProps = {
  onDelete: () => void
}

const StyledMoreVertIcon = styled(MoreVertIcon)({
  color: colorPalette.monotone[4],
  height: '16px',
  width: '16px',
})

const MenuItemListWrapper = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  display: 'flex',
  flexDirection: 'column',
  padding: '4px',
  borderRadius: '4px',
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  width: '200px',
})

const MenuItemListHeader = styled('div')({
  color: colorPalette.monotone[5],
  fontSize: '12px',
  fontWeight: 600,
  padding: '8px',
})

const MenuItemListDivider = styled(Divider)({
  borderColor: colorPalette.monotone[2],
})

const MenuItemListArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  gap: '4px',
})

const MenuItem = styled(ListItemButton)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
  color: colorPalette.monotone[4],
  fontSize: '12px',
})

const MenuItemList = ({ onDelete }: VersionReportMenuProps) => {
  return (
    <MenuItemListWrapper>
      <MenuItemListHeader>
        {intl.formatMessage({ id: 'menu' })}
      </MenuItemListHeader>
      <MenuItemListDivider />
      <MenuItemListArea>
        <MenuItem onClick={onDelete}>報告書を削除する</MenuItem>
      </MenuItemListArea>
    </MenuItemListWrapper>
  )
}

export const VersionReportMenu = ({ onDelete }: VersionReportMenuProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const active = useMemo(() => Boolean(anchorEl), [anchorEl])
  const onClick = useCallback(() => {
    if (!active) {
      setAnchorEl(buttonRef.current)
    } else {
      setAnchorEl(null)
    }
  }, [active])
  return (
    <>
      <HeaderIconButton ref={buttonRef} onClick={onClick}>
        <StyledMoreVertIcon />
      </HeaderIconButton>
      <Popper open={active} anchorEl={anchorEl} placement="bottom-end">
        <ClickAwayListener onClickAway={onClick}>
          <Paper>
            <MenuItemList onDelete={onDelete} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
