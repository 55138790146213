import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  IDoesFilterPassParams,
  ProcessCellForExportParams,
  RowDragCallbackParams,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import _ from 'lodash'
import {
  ColumnType,
  columnTypes,
  CURRENT_MONTH_BACKGROUND_COLOR_STYLE,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import {
  AccountCategory,
  BudgetResultType,
  ProfitLossItemAmountRowBody,
  ProfitLossItemRow,
  ProfitLossItemRowBody,
  ProfitLossRowBase,
  ProfitLossRowBodyBase,
} from '../profitLossItems'
import { DateTerm } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { intl } from '../../../../i18n'
import {
  FinancialStatementCellEditor,
  GeneralLedgerCellEditor,
  SubsidiaryCellEditor,
} from '../components/CellEditor'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { getRowNumber } from '../../../containers/BulkSheetView/lib/gridApi'
import objects from '../../../../utils/objects'
import {
  ClientSideSelectFilter,
  ClientSideTextFilter,
} from '../../../containers/BulkSheetView/components/filter'
import {
  LedgerAccountsTree,
  LedgerAccountsType,
} from '../../LedgerAccounts/ledgerAccounts'
import BoolExpression from '../../../../utils/boolExpression'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import './styles.scss'
import {
  isHalfOrFullWidthNumber,
  parseFullNumberToHalf,
} from '../../../../utils/number'
import { AUTO_COLUMN_ID } from '../../../containers/BulkSheet/const'
import { NumberCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/NumberCellRenderer'
import MultiLineTextCell from '../../../containers/commons/AgGrid/components/cell/custom/multiLineText'
import { normalize } from '../../../../utils/multilineText'

export const PROFIT_LOSS_COLGROUP_ID_INFOMATION: string = 'information'

export const profitLossItemsTreeGridOptions = (): GridOptions => {
  return {
    context: {},
    // Row
    treeData: true,
    excludeChildrenWhenTreeDataFiltering: true,
    rowDragManaged: false,
    rowDragMultiRow: true,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    autoGroupColumnDef: {
      field: 'body.name',
      headerName: intl.formatMessage({ id: 'profitLossItems.name' }),
      width: 250,
      pinned: true,
      suppressMovable: true,
      sortable: true,
      resizable: true,
      cellRendererParams: params => {
        return {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          innerRenderer: DefaultCellRenderer,
          uiMeta: {
            requiredIf:
              !params.data.isTotal && !params.data?.body?.type
                ? BoolExpression.of(true)
                : BoolExpression.of(false),
          } as Partial<FunctionProperty>,
        }
      },
      editable: params => {
        return !params.data.isTotal && isRootRow(params.data)
      },
      valueGetter: (params: ValueGetterParams) => {
        if (params.data.isTotal && isRootRow(params.data)) {
          return intl.formatMessage({ id: 'profitLossItems.forecast' })
        }
        if (isRootRow(params.data)) {
          return params.data?.body?.name
        }
        if (params.data.body.type === BudgetResultType.Budget) {
          return intl.formatMessage({ id: 'profitLossItems.budget' })
        } else if (params.data.body.type === BudgetResultType.Result) {
          return intl.formatMessage({ id: 'profitLossItems.result' })
        } else if (params.data.body.type === BudgetResultType.BudgetaryAmount) {
          return intl.formatMessage({ id: 'profitLossItems.budgetaryAmount' })
        }
        return ''
      },
      valueSetter: params => {
        const { colDef, data, oldValue, newValue } = params
        const field = colDef.field || colDef.colId
        if (!field || (!oldValue && !newValue) || oldValue === newValue) {
          return false
        }
        objects.setValue(data, field, newValue)
        if (!data.editedData) {
          data.editedData = { [field]: oldValue }
        } else if (!data.editedData.hasOwnProperty(field)) {
          data.editedData[field] = oldValue
        }
        params.api.forEachNode((node, index) => {
          if (
            node.data.treeValue.length === 2 &&
            node.data.treeValue.includes(data.treeValue[0])
          ) {
            objects.setValue(node.data, field, newValue)
            console.log(node.data)
          }
        })
        store.dispatch(requireSave())
        return true
      },
      filter: ClientSideTextFilter,
      floatingFilter: true,
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 100,
      editable: false,
      enableValue: false,
      sortable: false,
      suppressMenu: true,
      suppressSizeToFit: false,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      cellClassRules: {
        'grid-edited-cell': params => {
          const { colDef, data, value } = params
          const field = colDef.field ?? colDef.colId
          if (
            !data.editedData ||
            !field ||
            !colDef.editable ||
            (typeof colDef.editable === 'function' && !colDef.editable(params))
          ) {
            return false
          }
          return (
            data.editedData.hasOwnProperty(field) &&
            value !== data.editedData[field]
          )
        },
        profit_loss_root_infomation_group_column: (
          params: CellClassParams<ProfitLossItemRow>
        ) =>
          isRootRow(params.data) &&
          (params.column.getParent()?.getGroupId() ===
            PROFIT_LOSS_COLGROUP_ID_INFOMATION ||
            params.colDef.colId === AUTO_COLUMN_ID),
        profit_loss_root_row: params => isRootRow(params.data),
      },
    },
    columnDefs: [
      {
        field: 'drag',
        headerName: '',
        type: [ColumnType.drag],
        rowDrag: (params: RowDragCallbackParams) => {
          return isRootRow(params.node?.data)
        },
        cellClassRules: {
          'hover-over-can-drop': params => {
            return (
              !params.context?.onTree &&
              params.context?.draggableNodeId &&
              params.node?.id === params.context?.draggableNodeId
            )
          },
        },
      },
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        cellRenderer: SequenceNoCellRenderer,
        valueGetter: params => {
          if (params.data.isTotal) {
            return isRootRow(params.node?.data)
              ? intl.formatMessage({ id: 'total' })
              : ''
          }
          return getRowNumber(params.node)
        },
      },
      {
        groupId: PROFIT_LOSS_COLGROUP_ID_INFOMATION,
        headerName: intl.formatMessage({ id: 'profitLossItems.information' }),
        children: [
          financialStatementAccountsColDef,
          generalLedgerAccountsColDef,
          subsidiaryAccountsColDef,
          remarksColDef,
          {
            field: 'body.name',
            headerName: intl.formatMessage({ id: 'profitLossItems.name' }),
            width: 300,
            hide: true,
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
            valueGetter: params => {
              if (params.data.isTotal && isRootRow(params.data)) {
                return intl.formatMessage({ id: 'profitLossItems.forecast' })
              }
              if (isRootRow(params.data)) {
                return params.data?.body?.name
              }
              if (params.data.body.type === BudgetResultType.Budget) {
                return intl.formatMessage({ id: 'profitLossItems.budget' })
              } else if (params.data.body.type === BudgetResultType.Result) {
                return intl.formatMessage({ id: 'profitLossItems.result' })
              } else if (
                params.data.body.type === BudgetResultType.BudgetaryAmount
              ) {
                return intl.formatMessage({
                  id: 'profitLossItems.budgetaryAmount',
                })
              }
              return ''
            },
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'total' }),
            hide: false,
            sortable: true,
            pinned: true,
            lockPosition: 'right',
            cellRenderer: NumberCellRenderer,
            cellRendererParams: {
              numberWithCommas: true,
            },
            valueGetter: (params: ValueGetterParams) => {
              if (params.data.isTotal) {
                let sumValue = 0
                const summaryType = params.data.body.type
                params.api.forEachNode(node => {
                  if (
                    !!node.data &&
                    !node.data.isTotal &&
                    node.data.body?.type === summaryType
                  ) {
                    const amounts = node.data.body?.amounts ?? []
                    const tempSum = amounts.reduce((prev, current) => {
                      return prev + (current.amount ?? 0)
                    }, 0)
                    sumValue += tempSum
                  }
                })
                return sumValue
              }
              const amounts: ProfitLossItemAmountRowBody[] =
                params.data?.body?.amounts ?? []
              const sum = amounts.reduce((prev, current) => {
                return prev + (current.amount ?? 0)
              }, 0)
              return sum
            },
            cellClass: 'numberStyle',
          },
          {
            field: 'ratio',
            headerName: intl.formatMessage({
              id: 'profitLossItems.achievement.ratio',
            }),
            width: 60,
            hide: false,
            sortable: false,
            pinned: true,
            lockPosition: 'right',
            editable: false,
            cellRenderer: NumberCellRenderer,
            valueGetter: (params: ValueGetterParams) => {
              const data: ProfitLossItemRow = params.data
              if (!data) return undefined
              let sumTotal = 0
              if (!!data.isTotal && isRootRow(data)) {
                let sumBudget = 0
                let sumForecast = 0
                params.api.forEachNode(node => {
                  if (!!node.data && !node.data.isTotal) {
                    if (isRootRow(node.data)) {
                      const sumTemp = (node.data.body?.amounts ?? []).reduce(
                        (prev, current) => {
                          return prev + (current.amount ?? 0)
                        },
                        0
                      )
                      sumForecast += sumTemp
                    } else if (
                      node.data.body?.type === BudgetResultType.BudgetaryAmount
                    ) {
                      const sumTemp = (node.data.body?.amounts ?? []).reduce(
                        (prev, current) => {
                          return prev + (current.amount ?? 0)
                        },
                        0
                      )
                      sumBudget += sumTemp
                    }
                  }
                })
                if (!!sumBudget && !!sumForecast) {
                  sumTotal = sumForecast / sumBudget
                }
              } else if (
                params.node?.data?.body?.type ===
                BudgetResultType.BudgetaryAmount
              ) {
                return undefined
              } else if (
                !!data.isTotal &&
                (data.body?.type === BudgetResultType.Budget ||
                  data.body?.type === BudgetResultType.Result)
              ) {
                let sumForecast = 0
                let sumTarget = 0
                params.api.forEachNode((node, index) => {
                  if (
                    node.data.body.type === BudgetResultType.BudgetaryAmount
                  ) {
                    sumForecast += (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  } else if (node.data.body.type === data.body?.type) {
                    sumTarget += (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  }
                })
                if (!!sumForecast && !!sumTarget) {
                  sumTotal = sumTarget / sumForecast
                }
              } else if (
                !data.isTotal &&
                (data.body?.type === BudgetResultType.Budget ||
                  data.body?.type === BudgetResultType.Result)
              ) {
                let sumForecast = 0
                params.api.forEachNode((node, index) => {
                  if (
                    node.data.treeValue.includes(
                      params.node?.data.treeValue[0]
                    ) &&
                    node.data.body.type === BudgetResultType.BudgetaryAmount
                  ) {
                    sumForecast = (node.data?.body?.amounts ?? []).reduce(
                      (prev, current) => {
                        return prev + (current.amount ?? 0)
                      },
                      0
                    )
                  }
                })
                const sumTarget = (data.body?.amounts ?? []).reduce(
                  (prev, current) => {
                    return prev + (current.amount ?? 0)
                  },
                  0
                )
                if (!!sumForecast && !!sumTarget) {
                  sumTotal = sumTarget / sumForecast
                }
              } else if (!data.isTotal && !!params.node?.childrenMapped) {
                const children: RowNode<ProfitLossItemRow>[] = Object.values(
                  params.node.childrenMapped
                ).filter(v => !!v)
                const budgetRow = children.find(
                  v => BudgetResultType.BudgetaryAmount === v.data?.body?.type
                )?.data
                if (!!budgetRow) {
                  const sumForecast = (data.body?.amounts ?? []).reduce(
                    (prev, current) => {
                      return prev + (current.amount ?? 0)
                    },
                    0
                  )
                  const sumBudget = (budgetRow.body.amounts ?? []).reduce(
                    (prev, current) => {
                      return prev + (current.amount ?? 0)
                    },
                    0
                  )
                  if (!!sumForecast && !!sumBudget) {
                    sumTotal = sumForecast / sumBudget
                  }
                }
              }
              return `${(sumTotal * 100).toFixed(2)}%`
            },
          },
        ],
      },
    ],
    excelStyles: [
      {
        id: 'numberStyle',
        dataType: 'Number',
        numberFormat: {
          format: '#,##0',
        },
      },
    ],
    processCellForClipboard,
    processCellFromClipboard,
  }
}

export const financialStatementAccountsColDef: ColDef = {
  field: 'body.financialState',
  headerName: intl.formatMessage({
    id: 'ledgerAccounts.type.financialStatementAccounts',
  }),
  width: 85,
  hide: false,
  pinned: true,
  resizable: true,
  sortable: true,
  lockPosition: 'left',
  editable: params => {
    const category = params.context.accountCategory
    return (
      !params.data.isTotal && category === AccountCategory.OperatingExpenses
    )
  },
  cellEditor: FinancialStatementCellEditor,
  cellEditorParams: params => {
    return {
      masters: params.context?.masters,
    }
  },
  cellRendererParams: params => {
    return {
      value: !params.data?.body?.type
        ? params.data?.body?.financialState?.displayName
        : undefined,
      uiMeta: {
        requiredIf:
          !params.data.isTotal && !params.data?.body?.type
            ? BoolExpression.of(true)
            : BoolExpression.of(false),
      } as Partial<FunctionProperty>,
    }
  },
  valueGetter: params => {
    if (params.data.isTotal) return ''
    if (!!params.data.body?.type) return ''
    return params.data.body?.financialState?.uuid
  },
  valueSetter: params => {
    const { colDef, data, oldValue, newValue } = params
    const field = colDef.field || colDef.colId
    if (!field || (!oldValue && !newValue) || oldValue === newValue) {
      return false
    }
    const masters = params.context.masters
    const masterItem = masters?.find(v => v.uuid === newValue)
    if (!masterItem) return false
    objects.setValue(data, field, masterItem)
    if (!data.editedData) {
      data.editedData = { [field]: oldValue }
    } else if (!data.editedData.hasOwnProperty(field)) {
      data.editedData[field] = oldValue
    }

    const oldGeneralLedgerValue = data.body.generalLedger
    data.body.generalLedger = undefined
    const generalLedgerField = 'body.generalLedger'
    if (!data.editedData) {
      data.editedData = { [generalLedgerField]: oldGeneralLedgerValue }
    } else if (!data.editedData.hasOwnProperty(generalLedgerField)) {
      data.editedData[generalLedgerField] = oldGeneralLedgerValue
    }

    const oldSubsidiaryValue = data.body.subsidiary
    data.body.subsidiary = undefined
    const subsidiaryField = 'body.subsidiary'
    if (!data.editedData) {
      data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
    } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
      data.editedData[subsidiaryField] = oldSubsidiaryValue
    }
    store.dispatch(requireSave())
    return true
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: params => {
    const masters = params.context?.masters
    return {
      getValue: option => option,
      getLabel: option => {
        if (!masters || masters.length === 0) return ''
        const item: LedgerAccountsTree = masters.find(v => v?.uuid === option)
        return item?.displayName ?? item?.officialName ?? option
      },
    }
  },
}

export const generalLedgerAccountsColDef: ColDef = {
  field: 'body.generalLedger',
  headerName: intl.formatMessage({
    id: 'ledgerAccounts.type.generalLedgerAccounts',
  }),
  width: 85,
  hide: false,
  pinned: true,
  sortable: true,
  resizable: true,
  lockPosition: 'left',
  cellEditor: GeneralLedgerCellEditor,
  cellEditorParams: params => {
    return {
      masters: params.context?.masters,
    }
  },
  cellRendererParams: params => {
    return {
      value: !params.data?.body?.type
        ? params.data?.body?.generalLedger?.displayName
        : undefined,
      uiMeta: {
        requiredIf:
          !params.data.isTotal && !params.data?.body?.type
            ? BoolExpression.of(true)
            : BoolExpression.of(false),
      } as Partial<FunctionProperty>,
    }
  },
  editable: params => {
    return !params.data.isTotal && isRootRow(params.data)
  },
  valueGetter: params => {
    if (params.data.isTotal) return ''
    if (!!params.data.body?.type) return ''
    return params.data.body?.generalLedger?.uuid
  },
  valueSetter: params => {
    const { colDef, data, oldValue, newValue } = params
    const field = colDef.field || colDef.colId
    if (!field || (!oldValue && !newValue) || oldValue === newValue) {
      return false
    }
    const masters = params.context.masters
    const masterItem = masters
      ?.find(v => v.uuid === data.body.financialState.uuid)
      ?.children.find(v => v.uuid === newValue)
    if (!masterItem) return false
    objects.setValue(data, field, masterItem)
    if (!data.editedData) {
      data.editedData = { [field]: oldValue }
    } else if (!data.editedData.hasOwnProperty(field)) {
      data.editedData[field] = oldValue
    }

    const oldSubsidiaryValue = data.body.subsidiary
    data.body.subsidiary = undefined
    const subsidiaryField = 'body.subsidiary'
    if (!data.editedData) {
      data.editedData = { [subsidiaryField]: oldSubsidiaryValue }
    } else if (!data.editedData.hasOwnProperty(subsidiaryField)) {
      data.editedData[subsidiaryField] = oldSubsidiaryValue
    }
    store.dispatch(requireSave())
    return true
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: params => {
    const masters = params.context?.masters
    return {
      getValue: option => option,
      getLabel: option => {
        if (!masters || masters.length === 0) return ''
        const items = masters.map(v => v.children).flat() ?? []
        const item: LedgerAccountsTree = items.find(v => v?.uuid === option)
        return item?.displayName ?? item?.officialName ?? option
      },
    }
  },
}

export const subsidiaryAccountsColDef: ColDef = {
  field: 'body.subsidiary',
  headerName: intl.formatMessage({
    id: 'ledgerAccounts.type.subsidiaryAccounts',
  }),
  width: 85,
  hide: false,
  pinned: true,
  sortable: true,
  resizable: true,
  lockPosition: 'left',
  cellEditor: SubsidiaryCellEditor,
  cellEditorParams: params => {
    return {
      masters: params.context?.masters,
    }
  },
  cellRendererParams: params => {
    return {
      value:
        !params.data.isTotal && !params.data?.body?.type
          ? params.data?.body?.subsidiary?.displayName
          : undefined,
    }
  },
  editable: params => {
    return !params.data.isTotal && isRootRow(params.data)
  },
  valueGetter: params => {
    if (params.data.isTotal) return ''
    if (!!params.data.body?.type) return ''
    return params.data.body?.subsidiary?.uuid
  },
  valueSetter: params => {
    const { colDef, data, oldValue, newValue } = params
    const field = colDef.field || colDef.colId
    if (!field || (!oldValue && !newValue) || oldValue === newValue) {
      return false
    }
    const masters = params.context.masters
    const masterItem = masters
      ?.find(v => v.uuid === data.body.financialState?.uuid)
      ?.children?.find(v => v.uuid === data.body.generalLedger?.uuid)
      ?.children?.find(v => v.uuid === newValue)
    if (!masterItem) return false
    objects.setValue(data, field, masterItem)
    if (!data.editedData) {
      data.editedData = { [field]: oldValue }
    } else if (!data.editedData.hasOwnProperty(field)) {
      data.editedData[field] = oldValue
    }
    store.dispatch(requireSave())
    return true
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: params => {
    return {
      getValue: option => option,
      getLabel: option => {
        const masters = params.context?.masters
        if (!masters || masters.length === 0) return ''
        const items =
          masters.map(v => v.children?.map(vv => vv.children).flat()).flat() ??
          []
        const item: LedgerAccountsTree = items.find(v => v?.uuid === option)
        return item?.displayName ?? item?.officialName ?? option
      },
    }
  },
}

export const remarksColDef: ColDef = {
  field: 'body.remarks',
  headerName: intl.formatMessage({ id: 'profitLossItems.remarks' }),
  hide: false,
  sortable: true,
  pinned: true,
  lockPosition: 'left',
  resizable: true,
  editable: params => {
    return !params.data.isTotal && isRootRow(params.data)
  },
  type: [ColumnType.multiLineText],
  cellEditor: MultiLineTextCell.cellEditor,
  cellRenderer: MultiLineTextCell.cellRenderer,
  floatingFilter: true,
  filter: ClientSideTextFilter,
  filterParams: {
    valueGetter: (params: IDoesFilterPassParams<ProfitLossItemRow>) => {
      return params.data.body.remarks
    },
  },
  valueGetter: (params: ValueGetterParams<ProfitLossItemRow>) => {
    if (isRootRow(params.data)) {
      return params.data?.body.remarks
    } else {
      return ''
    }
  },
  valueSetter: params => {
    const { colDef, data, oldValue, newValue } = params
    const field = colDef.field || colDef.colId
    if (!field || (!oldValue && !newValue) || oldValue === newValue) {
      return false
    }
    objects.setValue(data, field, newValue)
    if (!data.editedData) {
      data.editedData = { [field]: oldValue }
    } else if (!data.editedData.hasOwnProperty(field)) {
      data.editedData[field] = oldValue
    }
    store.dispatch(requireSave())
    params.api.forEachNode(node => {
      if (node.data.treeValue[0] === params.data.uuid) {
        node.data.body.remarks = newValue
      }
    })
    return true
  },
}

export type RefreshDynamicColumnDefProps = {
  api: GridApi | null | undefined
  dateTerm: DateTerm
}

const AMOUNT_FIELD_PREFIX = 'body.amounts.'

export const isAmountColumn = (id: string | undefined) => {
  return !!id && id.startsWith(AMOUNT_FIELD_PREFIX)
}

export const generateYearMonthField = (date: DateVO) => {
  return AMOUNT_FIELD_PREFIX + date.format('YYYY/MM')
}

export const refreshDynamicColumnDef = ({
  api,
  dateTerm,
}: RefreshDynamicColumnDefProps) => {
  if (!api) return
  const yearMonths = generateYearMonthList(dateTerm)
  const yearList = Array.from(
    new Set(
      yearMonths.map(value => {
        return new DateVO(value).getYear()
      })
    )
  )
  const columnDefs = api?.getColumnDefs()
  const newColDefs = columnDefs?.filter(def => {
    const field = (def as ColDef)?.field
    const groupId = (def as ColGroupDef)?.groupId
    return (
      (!!field && !field.startsWith(AMOUNT_FIELD_PREFIX)) ||
      (!!groupId && !groupId.startsWith(AMOUNT_FIELD_PREFIX))
    )
  })
  if (!_.isEmpty(yearMonths)) {
    const yearCols: ColGroupDef[] = yearList.map(year => {
      return {
        groupId: `${AMOUNT_FIELD_PREFIX}${year}`,
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.year' },
          { year }
        ),
        children: [],
      }
    })
    const firstDayOfMonth = DateVO.now().getFirstDayOfMonth()
    yearMonths.forEach(month => {
      const date = new DateVO(month)
      const isCurrentMonth = date.isEqual(firstDayOfMonth)
      const col: ColDef = {
        field: generateYearMonthField(date),
        headerName: intl.formatMessage(
          { id: 'profitLossItems.header.month' },
          { month: date.getMonth() }
        ),
        width: 80,
        hide: false,
        pinned: false,
        suppressMovable: true,
        cellRenderer: NumberCellRenderer,
        cellClassRules: {
          profit_loss_item_past_month: params => {
            return (
              isRootRow(params.data) &&
              date.isBefore(DateVO.now().getFirstDayOfMonth())
            )
          },
        },
        cellStyle: (params: CellClassParams) => {
          return isCurrentMonth ? CURRENT_MONTH_BACKGROUND_COLOR_STYLE : {}
        },
        cellRendererParams: {
          numberWithCommas: true,
        },
        editable: (params: EditableCallbackParams) => {
          return !params.data.isTotal && !isRootRow(params.data)
        },
        valueGetter: (params: ValueGetterParams) => {
          if (params.data.isTotal) {
            let sumValue = 0
            const summaryType = params.data.body.type
            params.api.forEachNode(row => {
              if (
                !!row.data &&
                !row.data.isTotal &&
                row.data.body.type === summaryType
              ) {
                const amounts = row.data.body.amounts
                const target = amounts.find(
                  v => v.yearMonth === date.formatForApi()
                )
                sumValue += target?.amount ?? 0
              }
            })
            return sumValue
          }
          const amounts = params.data.body?.amounts ?? []
          const target = amounts.find(v => v.yearMonth === date.formatForApi())
          return target?.amount ?? ''
        },
        valueSetter: (params: ValueSetterParams) => {
          const { colDef, data, oldValue, newValue } = params
          const field = colDef.field || colDef.colId
          const parseVal = parseFullNumberToHalf(newValue ?? '')
          if (
            (!!newValue && !isHalfOrFullWidthNumber(newValue)) ||
            parseVal.startsWith('.')
          ) {
            return false
          }
          const numVal = Number(parseVal ?? 0)
          if (!field || oldValue === newValue || Number.isNaN(numVal)) {
            return false
          }
          const formatDate = date.formatForApi()
          const amount = data.body.amounts.find(
            cell => cell.yearMonth === formatDate
          )
          if (
            amount &&
            amount.amount === (_.isEmpty(newValue) ? undefined : numVal)
          ) {
            return false
          }
          store.dispatch(requireSave())
          if (_.isEmpty(newValue)) {
            data.body.amounts = data.body.amounts.filter(
              v => v.yearMonth !== formatDate
            )
            data.edited = !data.added
          } else if (!amount) {
            data.body.amounts.push({
              yearMonth: formatDate,
              amount: numVal,
            })
            data.edited = !data.added
          } else {
            amount.amount = numVal
            data.edited = !data.added
          }
          if (!data.editedData) {
            data.editedData = { [field]: oldValue }
          } else if (!data.editedData.hasOwnProperty(field)) {
            data.editedData[field] = oldValue
          }

          const rootUuid = data.treeValue[0]
          api.forEachNode((node, index) => {
            if (node.data?.uuid === rootUuid) {
              const rootAmount = node.data?.body.amounts.find(
                cell => cell.yearMonth === formatDate
              )
              let forecastAmounts: ProfitLossItemAmountRowBody[] = []
              let budgetAmounts: ProfitLossItemAmountRowBody[] = []
              let resultAmounts: ProfitLossItemAmountRowBody[] = []
              api.forEachNode((subNode, subIndex) => {
                if (subNode.data.treeValue.includes(rootUuid)) {
                  if (subNode.data?.body.type === BudgetResultType.Budget) {
                    budgetAmounts = subNode.data.body.amounts
                  } else if (
                    subNode.data?.body.type === BudgetResultType.Result
                  ) {
                    resultAmounts = subNode.data.body.amounts
                  } else if (
                    subNode.data?.body.type === BudgetResultType.BudgetaryAmount
                  ) {
                    forecastAmounts = subNode.data.body.amounts
                  }
                }
              })
              const resultAmount = resultAmounts.find(
                cell => cell.yearMonth === formatDate
              )
              if (resultAmount) {
                if (!rootAmount) {
                  node.data.body.amounts.push({
                    yearMonth: formatDate,
                    amount: resultAmount.amount,
                  })
                } else if (
                  rootAmount &&
                  rootAmount.amount !== resultAmount.amount
                ) {
                  rootAmount.amount = resultAmount.amount
                }
                return
              }
              const budgetAmount = budgetAmounts.find(
                cell => cell.yearMonth === formatDate
              )
              if (budgetAmount) {
                if (!rootAmount) {
                  node.data.body.amounts.push({
                    yearMonth: formatDate,
                    amount: budgetAmount.amount,
                  })
                } else if (
                  rootAmount &&
                  rootAmount.amount !== budgetAmount.amount
                ) {
                  rootAmount.amount = budgetAmount.amount
                }
                return
              }
              const forecastAmount = forecastAmounts.find(
                cell => cell.yearMonth === formatDate
              )
              if (forecastAmount) {
                if (!rootAmount) {
                  node.data.body.amounts.push({
                    yearMonth: formatDate,
                    amount: forecastAmount.amount,
                  })
                } else if (
                  rootAmount &&
                  rootAmount.amount !== forecastAmount.amount
                ) {
                  rootAmount.amount = forecastAmount.amount
                }
                return
              }
              if (_.isEmpty(newValue)) {
                node.data.body.amounts = node.data.body.amounts.filter(
                  v => v.yearMonth !== formatDate
                )
              }
            }
          })
          return true
        },
        cellClass: 'numberStyle',
      }
      const yearHeader = yearCols.find(
        v => v.groupId === `${AMOUNT_FIELD_PREFIX}${date.getYear()}`
      )
      yearHeader?.children.push(col)
    })
    yearCols.forEach(year => {
      newColDefs?.push(year)
    })
  }
  api.setColumnDefs(newColDefs!)
}

export const generateYearMonthList = (term: DateTerm): Array<string> => {
  if (!term.startDate || !term.endDate) {
    return []
  }
  const startDate = new DateVO(term.startDate)
  const endDate = new DateVO(term.endDate).addMonths(1)
  const duration = endDate.getValue().diff(startDate.toDate(), 'months')
  return Array.from({ length: duration }).map((_, index) => {
    return startDate.addMonths(index).format('YYYY/MM')
  })
}

export const isRootRow = data => {
  return !Object.values(BudgetResultType).includes(data?.body?.type)
}

export const processCellForClipboard = ({
  column,
  value,
  node,
}: ProcessCellForExportParams) => {
  const colDef = column.getColDef()
  const { field } = colDef
  if (!value) return value

  if (field === 'body.financialState') {
    if (node?.data.isTotal) return ''
    if (!!node?.data.body?.type) return ''
    return node?.data.body?.financialState?.displayName
  }
  if (field === 'body.generalLedger') {
    if (node?.data.isTotal) return ''
    if (!!node?.data.body?.type) return ''
    return node?.data.body?.generalLedger?.displayName
  }
  if (field === 'body.subsidiary') {
    if (node?.data.isTotal) return ''
    if (!!node?.data.body?.type) return ''
    return node?.data.body?.subsidiary?.displayName
  }
  if (field === 'body.remarks') {
    return `"${normalize(node?.data.body.remarks || '')}"`
  }
  return value
}

export const processCellFromClipboard = <
  TRow extends ProfitLossRowBase<ProfitLossItemRowBody>
>({
  column,
  context,
  node,
  value,
}: ProcessCellForExportParams<TRow>) => {
  const colDef = column.getColDef()
  const { field } = colDef
  const row: TRow | undefined = node?.data
  if (!row || row.isTotal) return value
  const masters: LedgerAccountsTree[] = context.masters

  if (field === 'body.financialState') {
    const accounts = masters.find(
      m =>
        LedgerAccountsType.FinancialStatementAccounts ===
          m.ledgerAccountsType && m.displayName === value
    )
    return accounts?.uuid
  }
  if (field === 'body.generalLedger') {
    const financialState = masters.find(
      m => row.body.financialState?.uuid === m.uuid
    )
    const accounts = financialState?.children.find(
      c =>
        LedgerAccountsType.GeneralLedgerAccounts === c.ledgerAccountsType &&
        c.displayName === value
    )
    return accounts?.uuid
  }
  if (field === 'body.subsidiary') {
    const financialState = masters.find(
      m => row.body.financialState?.uuid === m.uuid
    )
    const generalLedger = financialState?.children.find(
      c => row.body.generalLedger?.uuid === c.uuid
    )
    const accounts = generalLedger?.children.find(
      c =>
        LedgerAccountsType.SubsidiaryAccounts === c.ledgerAccountsType &&
        c.displayName === value
    )
    return accounts?.uuid
  }
  return value
}

export const distinctRowNodes = <
  TRow extends ProfitLossRowBase<TBody>,
  TBody extends ProfitLossRowBodyBase
>(
  nodes: (RowNode<TRow> | undefined)[]
): RowNode<TRow>[] => {
  return Array.from(
    new Map(
      nodes
        .filter(node => !!node?.data)
        .map(node => [node!.data!.uuid, node as RowNode<TRow>])
    ).values()
  )
}

export const getSummaryRowNode = <
  TRow extends ProfitLossRowBase<TBody>,
  TBody extends ProfitLossRowBodyBase
>(
  api: GridApi<TRow>,
  targetRowTypes: (string | undefined)[]
): RowNode<TRow>[] => {
  const topRowNodes: RowNode<TRow>[] = []
  for (let i = 0; i < api.getPinnedTopRowCount(); i++) {
    const sumRowNode: RowNode<TRow> | undefined = api.getPinnedTopRow(i)
    if (
      sumRowNode?.data &&
      targetRowTypes.includes(sumRowNode.data.body.type)
    ) {
      topRowNodes.push(sumRowNode)
    }
  }
  return topRowNodes
}

export const refreshPinnedRow = (api: GridApi | null | undefined) => {
  if (!api) return

  const refreshRowNodes: RowNode[] = []
  const pinnedRowCount: number = api.getPinnedTopRowCount() || 0
  for (let i = 0; i < pinnedRowCount; i++) {
    const node = api.getPinnedTopRow(i)
    if (node) refreshRowNodes.push(node)
  }
  api.refreshCells({ rowNodes: refreshRowNodes, force: true })
}
