import { memo, useMemo } from 'react'
import {
  CheckboxProps as MuiCheckboxProps,
  Checkbox as MuiCheckbox,
  styled,
} from '@mui/material'
import checkboxActiveMIcon from '../../../../assets/checkbox_active_m.svg'
import checkboxInactiveMIcon from '../../../../assets/checkbox_inactive_m.svg'
import checkboxIndeterminateMIcon from '../../../../assets/checkbox_indeterminate_m.svg'
import checkboxActiveSIcon from '../../../../assets/checkbox_active_s.svg'
import checkboxInactiveSIcon from '../../../../assets/checkbox_inactive_s.svg'
import checkboxIndeterminateSIcon from '../../../../assets/checkbox_indeterminate_s.svg'

type CheckboxProps = Omit<
  MuiCheckboxProps,
  'size' | 'icon' | 'checkedIcon' | 'indeterminateIcon'
> & {
  size?: 's' | 'm'
}

// Wrap the img tag in order not to change dimensions after the image is loaded.
const IconImgWrapper = styled('div')(({ size }: { size: 's' | 'm' }) => {
  const width = useMemo(() => {
    switch (size) {
      case 's':
        return 18
      case 'm':
        return 21
    }
  }, [size])
  const height = useMemo(() => {
    switch (size) {
      case 's':
        return 18
      case 'm':
        return 20
    }
  }, [size])
  return {
    width,
    height,
  }
})

const CheckboxIconS = memo(() => (
  <IconImgWrapper size="s">
    <img src={checkboxInactiveSIcon} />
  </IconImgWrapper>
))
const CheckboxCheckedIconS = memo(() => (
  <IconImgWrapper size="s">
    <img src={checkboxActiveSIcon} />
  </IconImgWrapper>
))
const CheckboxIndeterminateIconS = memo(() => (
  <IconImgWrapper size="s">
    <img src={checkboxIndeterminateSIcon} />
  </IconImgWrapper>
))
const CheckboxIconM = memo(() => (
  <IconImgWrapper size="m">
    <img src={checkboxInactiveMIcon} />
  </IconImgWrapper>
))
const CheckboxCheckedIconM = memo(() => (
  <IconImgWrapper size="m">
    <img src={checkboxActiveMIcon} />
  </IconImgWrapper>
))
const CheckboxIndeterminateIconM = memo(() => (
  <IconImgWrapper size="m">
    <img src={checkboxIndeterminateMIcon} />
  </IconImgWrapper>
))

// TODO: replace indeterminate icon.
export const Checkbox = styled(({ size = 'm', ...other }: CheckboxProps) => {
  const { icon, checkedIcon, indeterminateIcon } = useMemo(() => {
    switch (size) {
      case 's':
        return {
          icon: <CheckboxIconS />,
          checkedIcon: <CheckboxCheckedIconS />,
          indeterminateIcon: <CheckboxIndeterminateIconS />,
        }
      case 'm':
        return {
          icon: <CheckboxIconM />,
          checkedIcon: <CheckboxCheckedIconM />,
          indeterminateIcon: <CheckboxIndeterminateIconM />,
        }
    }
  }, [size])
  return (
    <MuiCheckbox
      {...other}
      icon={icon}
      checkedIcon={checkedIcon}
      indeterminateIcon={indeterminateIcon}
    />
  )
})({
  '&.MuiCheckbox-root': {
    padding: '3px',
  },
})
