import {
  AttachmentHandlerParams,
  PathProps,
  SingleSheetContext,
  SingleSheetOptions,
} from '../../containers/SingleSheet'
import wbsItem, {
  getAttachmentType,
  WbsItemBasic,
} from '../../../lib/functions/wbsItem'
import { SubmitType } from '../../containers/meta/ViewMeta'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import TextVO from '../../../vo/TextVO'
import { TableData } from '../../containers/SingleSheet/DataTable'
import SelectVO from '../../../vo/SelectVO'
import EntitySearchVO from '../../../vo/EntitySearchVO'
import NumberVO from '../../../vo/NumberVO'
import DateRangeVO from '../../../vo/DateRangeVO'
import FavoriteIcon from '../../components/icons/FavoriteIcon'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import CommentHeaderWbsItem, {
  mapTableDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import WbsItemToolBar from './WbsItemToolBar'
import WbsItemTitle from '../../components/headers/HeaderBar/Title/WbsItemTitle'
import ProjectPlanBreadcrumbs from '../../components/headers/HeaderBar/Breadcrumbs/ProjectPlanBreadcrumbs'
import Auth from '../../../lib/commons/auth'
import MultiEntitySearchVO from '../../../vo/MultiEntitySearchVO'
import { toggleWatchers } from '../../../lib/functions/watchWbsItem'
import objects from '../../../utils/objects'
import { CSSProperties } from 'react'
import { Box } from '@mui/material'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { WbsItemIcon } from '../../components/icons/WbsItemIcon'
import { RouteComponentProps } from 'react-router-dom'
import TagVO from '../../../vo/TagVO'

class WbsItemConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return {
      ...dest,
      wbsItemType: src.typeDto ? new WbsItemTypeVO(src.typeDto) : undefined,
      watchers: new MultiEntitySearchVO(src.watchers || []),
      projectUuid: src.projectUuid,
    }
  }

  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    delta?: any
  }) {
    return {
      before: initialData
        ? {
            input: { ...initialData },
            watchers: initialData.watchers,
            sprint: initialData.sprintUuid,
          }
        : undefined,
      after: {
        input: { ...dest },
        watchers: dest.watchers,
        sprints: dest.sprintUuid,
        tags: dest.tags,
      },
      delta: delta
        ? {
            input: {
              uuid: dest.uuid,
              type: dest.type,
              lockVersion: dest.lockVersion,
              ...delta,
            },
            watchers: dest.watchers,
            sprints: delta.sprintUuid
              ? {
                  wbsItemUuid: dest.uuid,
                  wbsItemLockVersion: dest.lockVersion,
                  added: delta.sprintUuid.newValue,
                  deleted: !delta.sprintUuid.newValue
                    ? delta.sprintUuid.oldValue
                    : undefined,
                }
              : undefined,
            tags: delta.tags
              ? {
                  wbsItemUuid: dest.uuid,
                  tagUuids: dest.tags.map(v => v.uuid),
                }
              : undefined,
          }
        : undefined,
    }
  }

  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}

export class WbsItemData extends TableData {
  code?: TextVO
  displayName?: TextVO
  type?: SelectVO
  status?: SelectVO
  substatus?: SelectVO
  ticketType?: string
  team?: EntitySearchVO
  accountable?: EntitySearchVO
  responsible?: EntitySearchVO
  assignee?: EntitySearchVO
  difficulty?: SelectVO
  priority?: SelectVO
  estimatedHour?: NumberVO
  scheduledDate?: DateRangeVO
  actualDate?: DateRangeVO

  watchers?: MultiEntitySearchVO
  wbsItemType?: WbsItemTypeVO
  sprint?: EntitySearchVO
  tags?: TagVO

  projectUuid?: string

  getCode() {
    return this.code
  }

  getName() {
    return this.displayName
  }
}

export default class WbsItem extends SingleSheetOptions<WbsItemData> {
  redirect = (props: RouteComponentProps<PathProps>, basic: WbsItemBasic) => {
    if (
      props.match?.path?.startsWith('/wbsItem/') &&
      basic.ticketType === 'RISK' &&
      basic.typeDto?.rootType === WbsItemType.TASK
    ) {
      props.history.replace(`/risk/${props.match.params.code}`)
    }
    if (
      props.match?.path?.startsWith('/wbsItem/') &&
      basic.ticketType === 'REFINEMENT_NEW' &&
      basic.typeDto?.rootType === WbsItemType.TASK
    ) {
      props.history.replace(`/refinement/${props.match.params.code}`)
    }
  }
  converterSpec = new WbsItemConverterSpec()
  getToolbarProps = (ctx: SingleSheetContext<WbsItemData>) => {
    return {
      wbsItemType: ctx.state.data.wbsItemType,
    }
  }

  onUploadWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<WbsItemData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.createAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachments,
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  onUploadAttachments = async (
    params: AttachmentHandlerParams<WbsItemData>
  ): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    return this.onUploadWbsItemAttachments(params)
  }

  onDeleteWbsItemAttachments = async ({
    externalId,
    uuid,
    lockVersion,
    attachments,
    ctx,
  }: AttachmentHandlerParams<WbsItemData>) => {
    if (lockVersion === undefined) {
      // Save attachment on click submit button if create wbs item.
      return
    }
    if (!ctx.state.data.type) {
      return
    }
    const {
      json: { wbsItemUuid, wbsItemLockVersion },
    } = await wbsItem.deleteAttachments({
      wbsItemUuid: uuid,
      attachmentType: getAttachmentType(externalId),
      attachmentItemUuids: attachments.map(v => v.uuid),
    })
    return {
      uuid: wbsItemUuid,
      lockVersion: wbsItemLockVersion,
    }
  }

  onDeleteAttachments = async (
    params: AttachmentHandlerParams<WbsItemData>
  ): Promise<{
    uuid: string
    lockVersion: number
  } | void> => {
    return this.onDeleteWbsItemAttachments(params)
  }

  toolBarItems = ctx => {
    if (ctx.state.submitType === SubmitType.Create) {
      return []
    }
    const item = ctx.state.data
    const status = item.status ? item.status.getValue() : WbsItemStatus.TODO
    const cumulation = ctx.state.cumulation
    const cellDefs = ctx.state.cellDefs

    return [
      <WbsItemToolBar
        key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}
        item={item}
        status={status}
        ctx={ctx}
        cumulation={cumulation}
        cellDefs={cellDefs}
      />,
    ]
  }
  ignoreDataChangeCheckKeys = ['deliverableAttachments']

  TitleComponent = props => {
    const { data } = props
    const isTicket = data?.wbsItem && data.wbsItem?.type === WbsItemType.TASK
    const type = isTicket ? data.wbsItem.typeDto : data?.typeDto
    return (
      <WbsItemTitle
        path={isTicket ? 'wbsItem.displayName' : 'displayName'}
        data={data}
        icon={<WbsItemIcon type={new WbsItemTypeVO(type)} />}
      />
    )
  }

  HeaderComponent = props => {
    const { data } = props
    const isTicket = data.wbsItem && data.wbsItem?.type === WbsItemType.TASK
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FavoriteIcon
          key="header-favorite-icon"
          checked={data.watchers?.some(
            v => v.uuid === Auth.getCurrentTenant()?.user?.uuid
          )}
          onClick={(checked: boolean) => {
            const watchers = data.watchers || []
            toggleWatchers(watchers, 'watchers', checked)
          }}
        />
        <Box sx={{ marginLeft: '16px' }}>
          <ProjectPlanBreadcrumbs
            key="header-breadcrumb"
            rootUuid={isTicket ? data.wbsItem.uuid : data.uuid}
            itemName={isTicket ? data.wbsItem.displayName : data.displayName}
          />
        </Box>
      </Box>
    )
  }

  commentHeaderComponents = ctx => {
    if (ctx.state.submitType === SubmitType.Create) {
      return []
    }
    return [
      <CommentHeaderWbsItem
        key={'1'}
        wbsItem={mapTableDataForCommentHeader(ctx.state.data)}
        onAfterUpdate={() => {
          ctx.refreshAndMergeUncommitData({})
        }}
        readonly={true}
      />,
    ]
  }

  isDateDelayed = (
    data: WbsItemData,
    target:
      | 'scheduledStartDate'
      | 'scheduledEndDate'
      | 'actualStartDate'
      | 'actualEndDate'
  ): boolean => {
    const delayedJudgmentItem = data
    if (!delayedJudgmentItem) return false
    const today = new Date(Date.now())
    const scheduledDate = objects.getValue(delayedJudgmentItem, 'scheduledDate')
    const actualDate = objects.getValue(delayedJudgmentItem, 'actualDate')
    if (target === 'scheduledStartDate') {
      return (
        scheduledDate?.getValue().startDate! <= today &&
        actualDate?.getValue().startDate === undefined
      )
    } else if (target === 'scheduledEndDate') {
      return (
        scheduledDate?.getValue().endDate! <= today &&
        actualDate?.getValue().endDate === undefined
      )
    } else if (target === 'actualStartDate') {
      return (
        scheduledDate?.getValue().startDate! < actualDate?.getValue().startDate!
      )
    } else if (target === 'actualEndDate') {
      return (
        scheduledDate?.getValue().endDate! < actualDate?.getValue().endDate!
      )
    }
    return false
  }

  getDelayTarget = (externalId: string, target) => {
    if (externalId.includes('scheduledDate') && target === 'start') {
      return 'scheduledStartDate'
    } else if (externalId.includes('scheduledDate') && target === 'end') {
      return 'scheduledEndDate'
    } else if (externalId.includes('actualDate') && target === 'start') {
      return 'actualStartDate'
    } else if (externalId.includes('actualDate') && target === 'end') {
      return 'actualEndDate'
    }
    return undefined
  }

  getStyleRule = (
    externalId: string
  ):
    | ((data: WbsItemData, target?: string) => CSSProperties | undefined)
    | undefined => {
    if (
      externalId.includes('scheduledDate') ||
      externalId.includes('actualDate')
    ) {
      return (data: WbsItemData, target?: string) => {
        if (!target) return undefined
        const delayTarget = this.getDelayTarget(externalId, target)
        if (!delayTarget) return undefined
        const isDelayed = this.isDateDelayed(data, delayTarget)
        if (isDelayed) {
          return {
            color: '#ff6b50',
          }
        }
      }
    }
  }
}
