import {
  getWbsItemStatusColorCode,
  getWbsItemTreeStatusColorCode,
} from '../../../../../../../../lib/functions/wbsItem'
import objects from '../../../../../../../../utils/objects'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'

const STYLE_BASE = {
  backgroundSize: 'calc(100% + 1px)',
}

export default function (params) {
  if (params.colDef.colId === 'ag-Grid-AutoColumn') {
    return {
      backgroundColor: getWbsItemStatusColorCode(params.value),
      ...STYLE_BASE,
    }
  }
  if (!params.node.data || params.node.data.isViewOnly) {
    return { ...STYLE_BASE, backgroundColor: '#F7F7F7' }
  }
  const statusFieldName = params.colDef.field || ''
  const wbsItemFieldName = statusFieldName.substring(
    0,
    statusFieldName.lastIndexOf('.')
  )
  const wbsItemTypeFieldName = `${wbsItemFieldName}.wbsItemType`
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    params.data,
    wbsItemTypeFieldName
  )
  if (wbsItemType?.isTask()) {
    return {
      backgroundColor: getWbsItemStatusColorCode(params.value),
      ...STYLE_BASE,
    }
  }
  return {
    background: getWbsItemTreeStatusColorCode(params.value),
    ...STYLE_BASE,
  }
}
