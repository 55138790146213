import { ProjectProvider } from '../context/projectContext'

export const projectPrivate = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps>
) => {
  return (props: OriginalProps) => (
    <ProjectProvider>
      <WrappedComponent {...props} />
    </ProjectProvider>
  )
}
