import { MentionType } from '../../../../../store/comments'

export enum MentionEntityAttributeKey {
  mentionUuid = 'data-mention-uuid',
  mentionType = 'data-mention-type',
}
export enum FileEntityAttributeKey {
  fileUrl = 'data-file-url',
  fileName = 'data-file-name',
  fileType = 'data-file-type',
}

export interface MentionSuggestionData {
  avatar?: string
  name?: string
  description?: string
  userUuid: string
  mentionType: MentionType
}
