import { useWbsItemSearchConditionSerializeService } from './wbsItemSearchConditionSerializeService'
import { WbsItemSearchConditionVO } from '../../domain/value-object/WbsItemSearchConditionVo/index'
import { getUrlQueryObject } from '../../utils/urls'
import objects from '../../utils/objects'
import { dateTermVoService } from '../../domain/value-object/DateTermVO'
import DateTimeTermVO from '../../domain/value-object/DateTimeTermVO'
import DateTimeVO from '../../domain/value-object/DateTimeVO'

type WbsItemSearchConditionUrlTransformService = {
  restoreFromUrl: () => WbsItemSearchConditionVO
  toUrl: (condition: WbsItemSearchConditionVO) => string
}

export const useWbsItemSearchConditionUrlTransformService =
  (): WbsItemSearchConditionUrlTransformService => {
    const transformer = useWbsItemSearchConditionSerializeService()

    const restoreFromUrl = () => {
      const urlQueryObject = getUrlQueryObject()
      if (!urlQueryObject) return {}
      const dest: WbsItemSearchConditionVO = {}
      for (let [key, value] of Object.entries<any>(urlQueryObject)) {
        if (!value) continue
        if (['code'].includes(key)) {
          const v = value.split(',').join(' ')
          objects.setValue(dest, key, v)
          continue
        }
        if (['types', 'ticketTypes', 'status', 'priority'].includes(key)) {
          const v = value.split(',')
          objects.setValue(dest, key, v)
          continue
        }
        if (
          [
            'scheduledStartDate',
            'scheduledEndDate',
            'actualStartDate',
            'actualEndDate',
          ].includes(key)
        ) {
          if (typeof value !== 'object') continue
          const v = dateTermVoService.construct(value['from'], value['to'])
          objects.setValue(dest, key, v)
          continue
        }
        if (['createdAt', 'updatedAt'].includes(key)) {
          const v = new DateTimeTermVO(
            !!value['from'] ? new DateTimeVO(value['from']) : undefined,
            !!value['to'] ? new DateTimeVO(value['to']) : undefined
          )
          objects.setValue(dest, key, v)
          continue
        }
        objects.setValue(dest, key, value)
      }
      return dest
    }
    const toUrl = (condition: WbsItemSearchConditionVO): string => {
      const plainObject = transformer.fromVoToStoredObject(condition)
      // TODO: implement.
      return ''
    }
    return { restoreFromUrl, toUrl }
  }
