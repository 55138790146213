import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  InputBase,
  Autocomplete as MuiAutocomplete,
  Popper,
  PopperProps,
  styled,
} from '@mui/material'
import { ReferencedEntity } from '../../../../domain/value-object/ReferencedEntity'
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDebounce } from '../../../hooks/useDebounce'
import { MenuItem, MenuItemText } from '../../menu'
import { colorPalette } from '../../../style/colorPallete'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'
import CloseRounded from '@mui/icons-material/CloseRounded'

type PureOnChange<Entity extends ReferencedEntity> = (
  v: Entity | undefined
) => void
type OnChange<
  Entity extends ReferencedEntity,
  Disabled extends boolean | undefined = false
> = Disabled extends false
  ? PureOnChange<Entity>
  : PureOnChange<Entity> | undefined
type AutocompleteProps<
  Entity extends ReferencedEntity,
  Disabled extends boolean | undefined = false
> = {
  value: Entity | undefined
  onChange: OnChange<Entity, Disabled>
  search: (searchText: string) => Promise<Entity[]>
  disabled: Disabled

  renderInput?: (
    params: AutocompleteRenderInputParams,
    value: Entity | undefined
  ) => ReactNode
}

export const Autocomplete = <
  Entity extends ReferencedEntity,
  Disabled extends boolean | undefined = false
>({
  value: _value,
  onChange,
  search,
  disabled,
  renderInput: _renderInput,
}: AutocompleteProps<Entity, Disabled>) => {
  const value = useMemo(() => _value || null, [_value])
  const [inputValue, setInputValue] = useState<string>(value ? value.name : '')
  const [options, setOptions] = useState<Entity[]>(value ? [value] : [])
  const [inputChangedBy, setInputChangedBy] =
    useState<AutocompleteInputChangeReason>('reset')
  const onChangeValue = useCallback(
    (_, v: Entity | null, reason: AutocompleteChangeReason) => {
      onChange && onChange(v || undefined)
    },
    [onChange]
  )
  const onChangeInput = useCallback(
    (_, value: string, reason: AutocompleteInputChangeReason) => {
      setInputValue(value)
      setInputChangedBy(reason)
    },
    []
  )
  const onFocus = useCallback(async () => {
    const response = await search('')
    setOptions(response)
  }, [search])

  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    const fn = async () => {
      const response = await search(debouncedValue)
      setOptions(response)
    }
    if (['input', 'clear'].includes(inputChangedBy)) {
      fn()
    }
  }, [debouncedValue, inputChangedBy, search])
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      if (_renderInput) {
        return _renderInput(params, value || undefined)
      }
      return <TextInput {...params.InputProps} inputProps={params.inputProps} />
    },
    [_renderInput, value]
  )
  const renderOption = useCallback((props, value: ReferencedEntity) => {
    return (
      <MenuItem {...props}>
        <MenuItemText>{value.name}</MenuItemText>
      </MenuItem>
    )
  }, [])
  const isOptionEqualToValue = useCallback(
    (option: ReferencedEntity, value: ReferencedEntity) => {
      return option.uuid === value.uuid
    },
    []
  )
  const getOptionLabel = useCallback((option: ReferencedEntity) => {
    return option.name
  }, [])
  const PopperComponent = memo((props: PopperProps) => (
    <Popper {...props} placement="bottom-start" />
  ))
  return (
    <MuiAutocomplete
      // Use sx props since making Autocomplete styled component takes a bit effort.
      sx={{ width: '100%' }}
      disabled={disabled}
      value={value}
      onChange={onChangeValue}
      isOptionEqualToValue={isOptionEqualToValue}
      options={options}
      renderInput={renderInput}
      inputValue={inputValue}
      onInputChange={onChangeInput}
      onFocus={onFocus}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      PopperComponent={PopperComponent}
      popupIcon={<PopupIcon />}
      clearIcon={<CloseIcon />}
    />
  )
}

const TextInput = styled(InputBase)({
  width: '100%',
  color: colorPalette.monotone[10],
})
const PopupIcon = styled(ExpandMoreRounded)({
  color: colorPalette.monotone[4],
})
const CloseIcon = styled(CloseRounded)({
  color: colorPalette.monotone[4],
  fontSize: '14px',
})
