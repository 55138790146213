import { useCallback, useMemo } from 'react'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { intl } from '../../../../i18n'
import { WbsPivotValue } from '../../../../domain/value-object/WbsPivot'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'

export type ValueGetters = {
  getValue: (v: WbsPivotValue) => number
  getWorkloadValue: (v: number) => number
}
export type ValueFormatters = {
  formatValue: (v: number) => string
  formatWorkload: (v: number) => string
  formatRate: (v: number) => string
  formatRatePercent: (v: number) => string
  workloadUnitLabel: string
  unitLabel: string
}
export type ValueGetterInverse = {
  getValueInverse: (v: number) => number
}
export const useValueFormatters = (
  aggregateField: AggregateField,
  workloadUnit: WorkloadUnit
): ValueGetters & ValueFormatters & ValueGetterInverse => {
  const { convertWorkloadFromHourToSelectedUnit, hoursPerSelectedUnit } =
    useWorkloadUnit(workloadUnit)
  const getValue = (v: WbsPivotValue) => {
    switch (aggregateField) {
      case AggregateField.WBS_ITEM_WORKLOAD:
        return convertWorkloadFromHourToSelectedUnit(v.workload)
      case AggregateField.WBS_ITEM_COUNT:
        return v.count
    }
  }
  const getWorkloadValue = (v: number) =>
    convertWorkloadFromHourToSelectedUnit(v)
  const formatValue = useCallback(
    (v: number): string => {
      switch (aggregateField) {
        case AggregateField.WBS_ITEM_WORKLOAD:
          return v.toFixed(1)
        case AggregateField.WBS_ITEM_COUNT:
          return v.toFixed(0)
      }
    },
    [aggregateField]
  )
  const getValueInverse = (v: number) => {
    switch (aggregateField) {
      case AggregateField.WBS_ITEM_WORKLOAD:
        return v * hoursPerSelectedUnit
      case AggregateField.WBS_ITEM_COUNT:
        return v
    }
  }
  const formatWorkload = useCallback((v: number): string => v.toFixed(1), [])
  const workloadUnitLabel = useMemo(() => {
    switch (workloadUnit) {
      case WorkloadUnit.HOUR:
        return intl.formatMessage({ id: 'unit.workload.hour' })
      case WorkloadUnit.DAY:
        return intl.formatMessage({ id: 'unit.workload.day' })
      case WorkloadUnit.MONTH:
        return intl.formatMessage({ id: 'unit.workload.month' })
    }
  }, [workloadUnit])
  const unitLabel = useMemo(() => {
    switch (aggregateField) {
      case AggregateField.WBS_ITEM_WORKLOAD:
        return workloadUnitLabel
      case AggregateField.WBS_ITEM_COUNT:
        return intl.formatMessage({ id: 'unit.count' })
    }
  }, [aggregateField, workloadUnitLabel])
  const formatRate = useCallback((v: number) => {
    if (Number.isNaN(v)) return '0.00' // 0/0 means the velocity is 0
    if (!Number.isFinite(v)) return '-'
    return v.toFixed(2)
  }, [])
  const formatRatePercent = useCallback((v: number) => {
    if (Number.isNaN(v)) return '0.00' // 0/0 means the velocity is 0
    if (!Number.isFinite(v)) return '-'
    return (v * 100).toFixed(1)
  }, [])

  return {
    getValue,
    getWorkloadValue,
    getValueInverse,
    formatValue,
    formatWorkload,
    formatRate,
    formatRatePercent,
    workloadUnitLabel,
    unitLabel,
  }
}
