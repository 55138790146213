import MuiBox from '@mui/material/Box'
import MuiLink from '@mui/material/Link'
import MuiCloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { Color } from '../../../../styles/commonStyles'

const RootContainer = styled(MuiBox)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const Link = styled(MuiLink)({
  fontSize: 11,
  color: Color.MAIN,
  verticalAlign: 'middle',
}) as typeof MuiLink
const CloseIcon = styled(MuiCloseIcon)({
  fontSize: 11,
  color: Color.MAIN,
  verticalAlign: 'middle',
})

interface Props {
  className?: string
  label: string
  onClick: () => void
}

const ResetButton = (props: Props) => {
  const { className, label, onClick } = props
  return (
    <RootContainer pr="8px" className={className}>
      <Link component="button" onClick={onClick}>
        <CloseIcon />
        {label}
      </Link>
    </RootContainer>
  )
}

export default ResetButton
