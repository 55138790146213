import * as d3 from 'd3'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetResourceBalance } from '../../../../../applications/usecases/resourceBalance/getResourceBalance'
import {
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../../components'
import {
  ResourceBalanceByTeamVO,
  ResourceBalanceVO,
  resourceBalanceService,
} from '../../../../../domain/value-object/ResourceBalanceVO'
import { TeamEntity } from '../../../../../domain/entity/TeamEntity'
import { intl } from '../../../../../i18n'
import { styled } from '@mui/material'
import { ProjectReportConfig } from '../../model/config'
import { aggregatedWorkloadService } from '../../../../../domain/value-object/AggregatedValueVO'
import { colorPalette } from '../../../../style/colorPallete'
import { TeamSelectOption } from '../../model/team'
import { AggregateTarget } from '../../../../../domain/entity/WbsItemEntity'
// import { AVAILABLE_RESOURCE_COLOR, PLANNED_WORKLOAD_COLOR } from '.'

type ResourceBalanceFromNowOnProps = {
  projectUuid: string
  selectedTeam: TeamSelectOption[]
  aggregateTarget: AggregateTarget
} & Pick<ProjectReportConfig, 'aggregateTarget'>

export const ResourceBalanceFromNowOn = ({
  projectUuid,
  selectedTeam,
  aggregateTarget,
}: ResourceBalanceFromNowOnProps) => {
  const { getResourceBalanceFromNowOn } = useGetResourceBalance()
  const [resourceBalance, setResourceBalance] = useState<
    ResourceBalanceByTeamVO[]
  >([])
  useEffect(() => {
    const f = async () => {
      if (!projectUuid) return
      const balance = await getResourceBalanceFromNowOn(projectUuid)
      setResourceBalance(balance)
    }
    f()
  }, [projectUuid])
  const totalBalance = useMemo(
    () => resourceBalanceService.sum(resourceBalance.map(b => b.balance)),
    [resourceBalance]
  )
  const maxBalance = useMemo(() => {
    return Math.max(
      aggregatedWorkloadService.getTargetValue(
        totalBalance.plannedWorkload,
        aggregateTarget
      ),
      totalBalance.availableResource
    )
  }, [totalBalance, aggregateTarget])
  return (
    <ResourceBalanceWidgetWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({ id: 'resourceDashboard.resourceBalance' })}
      </WidgetTitle>
      <ResourceBalanceWidgetRoot>
        <ResourceBalanceLegend />
        {selectedTeam.some(t => t.type === 'TEAM_TOTAL') && (
          <TotalResourceBalance
            balance={totalBalance}
            maxBalance={maxBalance}
            aggregateTarget={aggregateTarget}
          />
        )}
        {selectedTeam.map((t, i) => {
          const balance =
            t.type === 'TEAM_UNSELECTED'
              ? resourceBalance.find(b => !b.team)
              : resourceBalance.find(b => b.team?.uuid === t.value)
          if (!balance) return <></>
          return (
            <ResourceBalanceFromNowOnByTeam
              key={i}
              team={balance.team}
              balance={balance.balance}
              maxBalance={maxBalance}
              aggregateTarget={aggregateTarget}
            />
          )
        })}
      </ResourceBalanceWidgetRoot>
    </ResourceBalanceWidgetWithTitleWrapper>
  )
}

type ResourceBalanceFromNowOnByTeamProps = {
  team: TeamEntity | undefined
  balance: ResourceBalanceVO
  maxBalance: number
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const ResourceBalanceFromNowOnByTeam = ({
  team,
  balance,
  maxBalance,
  aggregateTarget,
}: ResourceBalanceFromNowOnByTeamProps) => {
  // const x = d3.scaleLinear().domain([0, maxBalance])
  const teamName = useMemo(() => {
    if (team) return team.displayName
    return intl.formatMessage({ id: 'teamUnselected' })
  }, [team])

  return (
    <ResourceBalanceArea>
      <ResourceBalanceTeamName>{teamName}</ResourceBalanceTeamName>
      <ResourceBalanceBarChartArea>
        <AvailableResourceBar
          workload={balance.availableResource}
          maxWorkload={maxBalance}
        />
        <PlannedWorkloadBar
          workload={aggregatedWorkloadService.getTargetValue(
            balance.plannedWorkload,
            aggregateTarget
          )}
          maxWorkload={maxBalance}
        />
      </ResourceBalanceBarChartArea>
    </ResourceBalanceArea>
  )
}

type TotalResourceBalanceProps = {
  balance: ResourceBalanceVO
  maxBalance: number
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const TotalResourceBalance = ({
  balance,
  maxBalance,
  aggregateTarget,
}: TotalResourceBalanceProps) => {
  const teamName = useMemo(() => {
    return intl.formatMessage({ id: 'teamTotal' })
  }, [])
  return (
    <ResourceBalanceArea>
      <ResourceBalanceTeamName>{teamName}</ResourceBalanceTeamName>
      <ResourceBalanceBarChartArea>
        <AvailableResourceBar
          workload={balance.availableResource}
          maxWorkload={maxBalance}
        />
        <PlannedWorkloadBar
          workload={aggregatedWorkloadService.getTargetValue(
            balance.plannedWorkload,
            aggregateTarget
          )}
          maxWorkload={maxBalance}
        />
      </ResourceBalanceBarChartArea>
    </ResourceBalanceArea>
  )
}

const ResourceBalanceLegend = () => (
  <ResourceBalanceLegendArea>
    <ResourceBalanceLegendItem>
      <ResourceBalanceLegendTitle>
        {intl.formatMessage({
          id: 'resourceDashboard.availableResource',
        })}
      </ResourceBalanceLegendTitle>
      <AvailableResourceColorLegend />
    </ResourceBalanceLegendItem>
    <ResourceBalanceLegendItem>
      <ResourceBalanceLegendTitle>
        {intl.formatMessage({ id: 'resourceDashboard.plannedWorkload' })}
      </ResourceBalanceLegendTitle>
      <PlannedWorkloadColorLegend />
    </ResourceBalanceLegendItem>
    <ResourceBalanceLegendItem>
      <ResourceBalanceLegendTitle>
        {`(${intl.formatMessage({ id: 'unit' })}:${intl.formatMessage({
          id: 'workloadUnit.month',
        })})`}
      </ResourceBalanceLegendTitle>
    </ResourceBalanceLegendItem>
  </ResourceBalanceLegendArea>
)

const ResourceBalanceWidgetWithTitleWrapper = styled(WidgetWithTitleWrapper)({
  width: '30%',
})

const ResourceBalanceWidgetRoot = styled(WidgetArea)({
  flexDirection: 'column',
})

const ResourceBalanceArea = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '6px',
})

const ResourceBalanceTeamName = styled('div')({
  fontSize: '10px',
  fontWeight: 600,
  width: '100px',
  display: 'flex',
  alignItems: 'center',
})

const ResourceBalanceBarChartArea = styled('div')({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
})

type BarChartProps = {
  workload: number
  maxWorkload: number
}

const AvailableResourceBar = ({ workload, maxWorkload }: BarChartProps) => (
  <AvailableResourceBarBackground workload={workload} maxWorkload={maxWorkload}>
    <BarchartValue>{Number(workload).toFixed(1)}</BarchartValue>
  </AvailableResourceBarBackground>
)

const PlannedWorkloadBar = ({ workload, maxWorkload }: BarChartProps) => (
  <PlannedWorkloadBarBackground workload={workload} maxWorkload={maxWorkload}>
    <BarchartValue>{Number(workload).toFixed(1)}</BarchartValue>
  </PlannedWorkloadBarBackground>
)

const BarChartBackground = styled('div')(
  ({ workload, maxWorkload }: BarChartProps) => ({
    width: `${(workload / maxWorkload) * 100}%`,
    height: '28px',
    display: 'flex',
    alignItems: 'center',
  })
)

const AvailableResourceBarBackground = styled(BarChartBackground)({
  backgroundColor: '#009AFF',
})

const PlannedWorkloadBarBackground = styled(BarChartBackground)({
  backgroundColor: colorPalette.pink[6],
})

const BarchartValue = styled('span')({
  color: '#fff',
  fontSize: '11px',
  fontWeight: 600,
  padding: '0 0 0 6px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const ResourceBalanceLegendArea = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '4px 0',
})

const ResourceBalanceLegendItem = styled('div')({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
})

const ResourceBalanceLegendTitle = styled('span')({
  fontSize: '10px',
})

const ResourceBalanceColorLegend = styled('div')({
  width: '24px',
  height: '10px',
})

const AvailableResourceColorLegend = styled(ResourceBalanceColorLegend)({
  backgroundColor: colorPalette.skyBlue[7],
})

const PlannedWorkloadColorLegend = styled(ResourceBalanceColorLegend)({
  backgroundColor: colorPalette.pink[6],
})
