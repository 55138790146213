import { plainToInstance } from 'class-transformer'
import ManagementNoticeEntity from '../../entity/ManagementNoticeEntity'
import { DateTimeTerm } from '../../value-object/DateTimeTermVO'
import { UserBasic } from '../../value-object/UserBasicVO'
import DomainEntityFactoryBase, {
  DomainEntityCreateParams,
  DomainEntityReconstractParams,
} from '../Base'

export interface ManagementNoticeFactoryCreateParams
  extends DomainEntityCreateParams {}

export interface ManagementNoticeFactoryReconstructParams
  extends DomainEntityReconstractParams {
  uuid: string
  type: string
  title: string
  message: string
  publishPeriod: {
    startDateTime: string | number
    endDateTime: string | number | undefined
  }

  lockVersion: number
  revision: string
  createdAt: string | number
  createdBy: UserBasic
  updatedAt: string | number
  updatedBy: UserBasic
}

export default class ManagementNoticeFactory extends DomainEntityFactoryBase<ManagementNoticeEntity> {
  create(params: ManagementNoticeFactoryCreateParams): ManagementNoticeEntity {
    throw new Error('Method not implemented.')
  }
  reconstract(
    params: ManagementNoticeFactoryReconstructParams
  ): ManagementNoticeEntity {
    return plainToInstance(
      ManagementNoticeEntity,
      {
        ...params,
      },
      {
        excludeExtraneousValues: true,
      }
    )
  }
  reconstractList(
    params: ManagementNoticeFactoryReconstructParams[]
  ): ManagementNoticeEntity[] {
    return params.map(v => this.reconstract(v))
  }
}
