import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import DeleteButton from '../../../components/buttons/DeleteButton'
import { Attachment, FileType, formatBytes } from '../../../../utils/attachment'
import { formatDateTime } from '../../../../utils/date'
import { logDownloadAttachment } from '../../../../utils/file'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined'
import FileViewerDialog, {
  canPreview,
} from '../../../components/dialogs/FileViewerDialog'
import { useCallback, useState } from 'react'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  overflowX: 'scroll',
})
const StyledTable = styled(Table)({
  backgroundColor: 'white',
})
const TableRowHeader = styled(TableRow)({
  width: '100%',
  height: '22px',
  backgroundColor: '#F5F5F5',
})
const IconColumnCell = styled(TableCell)({
  maxWidth: '18px',
})
const NameColumnCell = styled(TableCell)({
  maxWidth: '300px',
  wordWrap: 'break-word',
})
const NowrapColumnCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  textAlign: 'left',
})
const SizeColumnCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  textAlign: 'right',
})
const DeleteButtonColumnCell = styled(TableCell)({
  width: '40px',
})
const AttachmentUrl = styled(Typography)({
  cursor: 'pointer',
  color: '#1D85B4',
  textDecoration: 'underline',
})

export interface AttachmentListProps {
  attachments: Attachment[]
  onDelete?: (attachment: Attachment, index: number) => void | Promise<void>
}

interface Props extends WrappedComponentProps, AttachmentListProps {}

const onDownload = async (url: string, source: string) => {
  if (source === FileType.LOCAL) {
    // Call api to log downloading file, only when the attachment is file.
    await logDownloadAttachment(url)
  }
  window.open(url)
}

const AttachmentList = ({ attachments, onDelete, intl }: Props) => {
  const [fileViewerDialogState, setFileViewerDialogState] = useState<{
    open: boolean
    name: string
    source: string
    size?: number
    url: string
    updatedBy: string
    updatedAt?: number
  }>({
    open: false,
    name: '',
    source: '',
    url: '',
    updatedBy: '',
  })

  const openFileViewerDialog = useCallback((attachment: Attachment) => {
    setFileViewerDialogState({
      open: true,
      name: attachment.name,
      source: attachment.source,
      size: attachment.size,
      url: attachment.url,
      updatedBy: attachment.updatedBy ? attachment.updatedBy.name : '',
      updatedAt: attachment.updatedAt,
    })
  }, [])
  const closeFileViewerDialog = useCallback(() => {
    setFileViewerDialogState({
      open: false,
      name: '',
      source: '',
      size: undefined,
      url: '',
      updatedBy: '',
      updatedAt: undefined,
    })
  }, [])
  const getPreviewIcon = useCallback((attachment: Attachment) => {
    if (
      !(canPreview(attachment.name) && attachment.source === FileType.LOCAL)
    ) {
      return <></>
    }

    return (
      <IconButton
        size="small"
        onClick={() => {
          openFileViewerDialog(attachment)
        }}
      >
        <PanoramaOutlinedIcon />
      </IconButton>
    )
  }, [])

  const getExtension = (source: string, name: string): string => {
    if (source === FileType.LOCAL) {
      const splitted = name.split('.')
      if (splitted.length === 1) {
        return intl.formatMessage({ id: 'attachment.unknown' })
      }
      const extension = splitted[splitted.length - 1]
      return extension
    }
    return ''
  }

  return (
    <RootDiv>
      <StyledTable
        size="small"
        aria-label="dense table"
        onClick={e => e.stopPropagation()}
      >
        <TableHead style={{ width: '100%' }}>
          <TableRowHeader>
            <IconColumnCell align="left" />
            <NameColumnCell align="left">
              {intl.formatMessage({ id: 'attachment.name' })}
            </NameColumnCell>
            <IconColumnCell align="left" />
            <NowrapColumnCell align="left">
              {intl.formatMessage({ id: 'attachment.extension' })}
            </NowrapColumnCell>
            <NowrapColumnCell align="left">
              {intl.formatMessage({ id: 'attachment.updatedBy' })}
            </NowrapColumnCell>
            <NowrapColumnCell align="left">
              {intl.formatMessage({ id: 'attachment.updatedAt' })}
            </NowrapColumnCell>
            <SizeColumnCell align="left">
              {intl.formatMessage({ id: 'attachment.size' })}
            </SizeColumnCell>
            <NowrapColumnCell align="left">&nbsp;</NowrapColumnCell>
          </TableRowHeader>
        </TableHead>
        <TableBody style={{ width: '100%' }}>
          {attachments.map((attachment: Attachment, index: number) => (
            <TableRow key={index} style={{ width: '100%', height: '36px' }}>
              <NowrapColumnCell align="right">
                <IconButton
                  size="small"
                  onClick={() => {
                    onDownload(attachment.url, attachment.source)
                  }}
                >
                  <CloudDownloadOutlinedIcon />
                </IconButton>
              </NowrapColumnCell>
              <NameColumnCell align="left">
                <AttachmentUrl
                  onClick={() => onDownload(attachment.url, attachment.source)}
                >
                  {attachment.name}
                </AttachmentUrl>
              </NameColumnCell>
              <NowrapColumnCell align="right">
                {getPreviewIcon(attachment)}
              </NowrapColumnCell>
              <NowrapColumnCell align="right">
                {getExtension(attachment.source, attachment.name)}
              </NowrapColumnCell>
              <NowrapColumnCell align="left">
                {attachment.updatedBy ? attachment.updatedBy.name : ''}
              </NowrapColumnCell>
              <NowrapColumnCell align="left">
                {formatDateTime(attachment.updatedAt) || ''}
              </NowrapColumnCell>
              <SizeColumnCell align="left">
                {attachment.size !== undefined
                  ? formatBytes(attachment.size)
                  : ''}
              </SizeColumnCell>
              <DeleteButtonColumnCell align="center">
                {onDelete && (
                  <DeleteButton
                    onClick={event => onDelete(attachment, index)}
                  />
                )}
              </DeleteButtonColumnCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <FileViewerDialog
        {...fileViewerDialogState}
        closeHandler={closeFileViewerDialog}
        downloadHandler={onDownload}
      />
    </RootDiv>
  )
}

export default injectIntl(AttachmentList)
