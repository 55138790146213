import { Divider, styled } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'

const StyledDivider = styled(Divider)({
  width: '2px',
  height: '11px',
  margin: '0 5px',
  backgroundColor: colorPalette.skyBlue[8],
})
const HeaderRoot = styled('div')({
  width: '100%',
  height: '24px',
  backgroundColor: colorPalette.skyBlue[0],
  display: 'flex',
  gap: '4px',
  alignItems: 'center',
})
const HeaderTitle = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '11px',
  fontWeight: 600,
  lineHeight: 1.5,
})
type TableHeaderProps = {
  title: string
}
export const TableHeader = ({ title }: TableHeaderProps) => {
  return (
    <HeaderRoot>
      <StyledDivider />
      <HeaderTitle>{title}</HeaderTitle>
    </HeaderRoot>
  )
}
