import React from 'react'
import { Box, InputBase } from '@mui/material'
import { styled } from '@mui/system'
import store from '../../../../store'
import { applyDiff } from '../../../../store/singleSheet'
import { CellDef } from '../../../containers/meta/ViewMeta/SingleSheetViewMeta'
import { TextColor } from '../../../../styles/commonStyles'
import { requireSave } from '../../../../store/requiredSaveData'

const HideWheelStyle = {
  margin: 0,
  '-webkit-appearance': 'none',
  appearance: 'none',
}

const RootBox = styled(Box)({
  width: '3ch',
  textAlign: 'right',
  cursor: 'pointer',
})
const Label = styled('div')({
  width: '100%',
  height: '100%',
})
const NumberTextField = styled(InputBase)({
  width: '100%',
  height: '10px',
  paddingBottom: '4px',
  borderBottom: `solid 2px ${TextColor.BLUE}`,
  '&> input::-webkit-inner-spin-button': HideWheelStyle,
  '&> input::-webkit-outer-spin-button': HideWheelStyle,
  '& .MuiInputBase-input': {
    width: '100%',
    textAlign: 'right',
  },
})

interface Props {
  storeKey: string
  data: any
  defs: CellDef | undefined
  value: any
}

interface State {
  editing: boolean
  inputValue: string
}

class NumberInput extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      inputValue: '',
    }
  }

  isEditable = (): boolean => {
    if (!this.props.defs) {
      return false
    }
    return this.state.editing
  }

  setEditing = (value: boolean) => {
    if (!this.props.defs) {
      return
    }
    this.setState({ editing: value })
    if (value === true) {
      this.setState({ inputValue: this.props.value ? this.props.value : '' })
    }
  }

  onChange = event => {
    this.setState({ inputValue: event.target.value })
    store.dispatch(requireSave())
  }

  onBlur = () => {
    const num = Number(this.state.inputValue)
    const diff = {}
    diff[this.props.storeKey] = isNaN(num) ? 0 : num
    store.dispatch(applyDiff({ ...diff }))
    this.setEditing(false)
  }

  getLabelValue = () => {
    return this.props.value ? this.props.value : '0'
  }

  render() {
    return (
      <RootBox>
        {this.state.editing && (
          <NumberTextField
            type="number"
            value={this.state.inputValue}
            onChange={this.onChange}
            onBlur={this.onBlur}
            autoFocus={true}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.currentTarget.blur()
              }
            }}
          />
        )}
        {!this.state.editing && (
          <Label onClick={e => this.setEditing(true)}>
            {this.getLabelValue()}
          </Label>
        )}
      </RootBox>
    )
  }
}

export default NumberInput
