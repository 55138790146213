import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'
import store from '../../../../../../../../store'

export interface WbsItemTypeCellValue {
  wbsItemType?: WbsItemTypeVO
  typeIndex: number
}

interface CellRendererProps extends ICellRendererParams {}

export const cellRenderer = (params: CellRendererProps) => {
  return (
    <div
      id={`column-${params.column?.getColId()}-${params.rowIndex}`}
      className="sevend-ag-cell"
    >
      <p>{params.valueFormatted}</p>
    </div>
  )
}

export const comparator = (
  a: WbsItemTypeCellValue,
  b: WbsItemTypeCellValue
): number => {
  if (a?.typeIndex === undefined || b?.typeIndex === undefined) return 0
  return a.typeIndex - b.typeIndex
}

export const valueFormatter = (params: ValueFormatterParams) => {
  const cellValue = params.value
  if (typeof cellValue === 'string') return cellValue
  return cellValue.wbsItemType?.getNameWithSuffix() || ''
}

export const getTypeIndex = (wbsItemType: WbsItemTypeVO): number => {
  const options = [
    ...store.getState().project.wbsItemTypes.getAll(),
    ...store.getState().project.ticketListTypes,
    ...store.getState().project.ticketTypes,
  ]
  return options.findIndex(t => t.code === wbsItemType.code) ?? -1
}

export default {
  cellRenderer,
  comparator,
  valueFormatter,
}
