import { useCallback } from 'react'
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { intl } from '../../../../../../i18n'
import { colorPalette } from '../../../../../style/colorPallete'
import { DateTerm } from '../../../../../../utils/date'
import DateVO from '../../../../../../vo/DateVO'
import {
  DateArea,
  DateAreaDiv,
  DatePickerAreaDiv,
  DateTermAreaDiv,
  DateTermDivider,
} from './common'

// TODO multilingual localization
type Props = {
  displayUnit: DisplayUnit
  dateTerm: DateTerm | undefined
  onChangeTerm: (displayUnit: DisplayUnit) => void
  shiftDisplayTerm: (value: ShiftType) => void
}

type TermSelectProps = {
  displayUnit: DisplayUnit
  onChangeTerm: (displayUnit: DisplayUnit) => void
}

type DateTermAreaProps = {
  displayUnit: DisplayUnit
  dateTerm: DateTerm
}

export enum DisplayUnit {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum ShiftType {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
  THIS_TERM = 'THIS_TERM',
}

const TermSelect = ({ displayUnit, onChangeTerm }: TermSelectProps) => {
  const onChange = useCallback(
    e => onChangeTerm(e.target.value),
    [onChangeTerm]
  )
  return (
    <Select
      value={displayUnit}
      onChange={onChange}
      sx={{
        width: '72px',
        height: '32px',
        fontSize: '12px',
        color: colorPalette.monotone[4],
      }}
    >
      <MenuItem
        sx={{ color: colorPalette.monotone[4] }}
        value={DisplayUnit.WEEK}
      >
        {intl.formatMessage({ id: 'week' })}
      </MenuItem>
      <MenuItem
        sx={{ color: colorPalette.monotone[4] }}
        value={DisplayUnit.MONTH}
      >
        {intl.formatMessage({ id: 'month' })}
      </MenuItem>
    </Select>
  )
}

const DateTermArea = ({ displayUnit, dateTerm }: DateTermAreaProps) => {
  const startDateVO = new DateVO(dateTerm.startDate)
  const endDateVO = new DateVO(dateTerm.endDate)
  return (
    <div>
      {displayUnit === DisplayUnit.WEEK ? (
        <DateTermAreaDiv>
          <DateArea
            year={startDateVO.format('YYYY')}
            month={startDateVO.format('M')}
            day={startDateVO.format('D')}
            dayOfWeek={startDateVO.format('ddd')}
          />
          <DateTermDivider />
          <DateArea
            month={endDateVO.format('M')}
            day={endDateVO.format('D')}
            dayOfWeek={endDateVO.format('ddd')}
          />
        </DateTermAreaDiv>
      ) : (
        <DateAreaDiv>
          <Typography
            fontSize={'15px'}
            fontWeight={'700'}
            sx={{ color: colorPalette.monotone[5] }}
          >
            {intl.formatMessage(
              { id: 'header.termSelector.yearMonth' },
              {
                year: startDateVO.format('YYYY'),
                month: startDateVO.format('M'),
              }
            )}
          </Typography>
        </DateAreaDiv>
      )}
    </div>
  )
}

const ShiftButton = styled(IconButton)({
  backgroundColor: colorPalette.monotone[0],
  borderRadius: '0%',
  width: '24px',
  height: '24px',
})

const TermSelector = ({
  displayUnit,
  dateTerm,
  onChangeTerm,
  shiftDisplayTerm,
}: Props) => {
  return (
    <DatePickerAreaDiv>
      <TermSelect displayUnit={displayUnit} onChangeTerm={onChangeTerm} />
      <ShiftButton>
        <ArrowBackIos
          sx={{ color: colorPalette.monotone[5] }}
          onClick={() => shiftDisplayTerm(ShiftType.BACKWARD)}
        />
      </ShiftButton>
      {dateTerm && (
        <DateTermArea displayUnit={displayUnit} dateTerm={dateTerm} />
      )}
      <ShiftButton>
        <ArrowForwardIos
          sx={{ color: colorPalette.monotone[5] }}
          onClick={() => shiftDisplayTerm(ShiftType.FORWARD)}
        />
      </ShiftButton>
      <Button
        sx={{
          width: '48px',
          minWidth: '48px',
          height: '32px',
          borderRadius: '4px',
          border: '1px solid',
          borderColor: colorPalette.monotone[2],
          fontSize: '12px',
          color: colorPalette.monotone[4],
        }}
        onClick={() => shiftDisplayTerm(ShiftType.THIS_TERM)}
      >
        {intl.formatMessage({
          id: displayUnit === DisplayUnit.WEEK ? 'this.week' : 'this.month',
        })}
      </Button>
    </DatePickerAreaDiv>
  )
}

export default TermSelector
