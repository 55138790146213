import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import { intl } from '../../../../i18n'
import { DateBound } from '../../toggleGroups/StartAndEndDateToggleGroup'

type AggregateBaseDateToggleButtonGroupProps = {
  value: DateBound
  onChange: (_: DateBound) => void
}

export const AggregateBaseDateToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: AggregateBaseDateToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={DateBound.START}>
        {intl.formatMessage({
          id: 'toggle.startDate',
        })}
      </ToggleButton>
      <ToggleButton value={DateBound.END}>
        {intl.formatMessage({
          id: 'toggle.endDate',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
