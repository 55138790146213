import React from 'react'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { styled } from '@mui/system'

const Button = styled(BaseButton)({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  color: '#868686',
  border: '1px solid #DDDDDD',
}) as React.FC<BaseButtonProps>

interface OwnProps {
  title?: string
  width?: string
}
type Props = OwnProps & WrappedComponentProps & BaseButtonProps

const CancelButton = (props: Props) => {
  const { title } = props
  return (
    <Button {...props} variant={'outlined'}>
      {title || props.intl.formatMessage({ id: 'dialog.cancel' })}
    </Button>
  )
}

export default injectIntl(CancelButton)
