import React, { CSSProperties, useState } from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import { TableCell } from '@mui/material'
import {
  Component,
  CustomEnumValue,
  PropertyType,
} from '../../../../../lib/commons/appFunction'
import { CellDef } from '../../../meta/ViewMeta/SingleSheetViewMeta'
import TextCell from './Input/TextCell'
import NumberCell from './Input/NumberCell'
import DateCell from './Input/DateCell'
import DateRangeCell from './Input/DateRangeCell'
import DateTimeCell from './Input/DateTimeCell'
import { ValidationError } from '../../../meta/validator'
import DataCellTooltip from './Tooltip'
import { VO } from '../../../../../vo'
import { TableData } from '..'
import SelectCell from './Input/SelectCell'
import EntitySearchCell from './Input/EntitySearchCell'
import SwitchCell from './Input/SwitchCell'
import IconCell from './Input/IconCell'
import WorkloadCell from './Input/WorkloadCell'
import { Color } from '../../../../../styles/commonStyles'
import WbsStatusCell from './Input/WbsStatusCell'
import DateTimeRangeCell from './Input/DateTimeRangeCell'
import MultiSelectCell from './Input/MultiSelectCell'
import MultiEntitySearchCell from './Input/MultiEntitySearchCell'
import MultiLineTextCell from './Input/MultiLineTextCell'
import TagCell from './Input/TagCell'
import SlackMentionUserCell from './Input/SlackMentionUserCell'

export interface DataCellProps {
  value: VO
  initialValue: VO
  data: TableData
  colSpan: number
  cellDef: CellDef
  onChange: (value: VO) => void
  setError?: (error: ValidationError | undefined) => void
}

const DataCell = (props: DataCellProps) => {
  const [error, setErrorState] = useState<ValidationError | undefined>(
    undefined
  )
  const [focused, setFocused] = useState<boolean>(false)

  const { colSpan, cellDef, setError, ...others } = props

  const component = getComponentByType(cellDef)

  const editEnd = _.debounce(() => setFocused(false), 100)

  // 編集可能なセルならeditableはtrueになっている
  const editable =
    typeof cellDef.editable === 'function'
      ? cellDef.editable(props.data)
      : cellDef.editable

  const edited =
    !props.value || !props.initialValue
      ? props.value !== props.initialValue
      : !props.value.isEqual(props.initialValue)

  return (
    <Cell
      align="left"
      colSpan={colSpan}
      style={{
        backgroundColor: error ? '#FFEAEA' : '#FFFFFF',
      }}
    >
      <DataCellTooltip title={cellDef.uiMeta.tooltipText} error={error}>
        <WrapperDiv
          focused={focused}
          editable={editable}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {component ? (
            React.createElement(component, {
              ...others,
              colSpan,
              cellDef,
              setError: (error: ValidationError | undefined) => {
                setErrorState(error)
                setError && setError(error)
              },
              editEnd: editEnd,
            })
          ) : (
            <></>
          )}
          {edited ? <EditedMark /> : <></>}
        </WrapperDiv>
      </DataCellTooltip>
    </Cell>
  )
}

const getComponentByType = (
  cellDef: CellDef
): React.ComponentType<any> | undefined => {
  const type: PropertyType = cellDef.type
  const component: Component = cellDef.component
  const valuesAllowed: CustomEnumValue[] = cellDef.uiMeta.valuesAllowed
    ? cellDef.uiMeta.valuesAllowed
    : []
  switch (type) {
    case PropertyType.Date:
      return DateCell
    case PropertyType.DateRange:
      return DateRangeCell
    case PropertyType.DateTime:
      return DateTimeCell
    case PropertyType.DateTimeRange:
      return DateTimeRangeCell
    case PropertyType.Number:
      return NumberCell
    case PropertyType.Text:
      return TextCell
    case PropertyType.Select:
      if (Component.WbsItemStatus === component) {
        return WbsStatusCell
      }
      return SelectCell
    case PropertyType.EntitySearch:
      return EntitySearchCell
    case PropertyType.Switch:
    case PropertyType.Checkbox:
      return SwitchCell
    case PropertyType.Icon:
      return IconCell
    case PropertyType.Custom:
      if (Component.Workload === component) {
        return WorkloadCell
      }
      if (Component.Sprint === component) {
        return EntitySearchCell
      }
      if (Component.Tag === component) {
        return TagCell
      }
      if (Component.SlackUser === component) {
        return SlackMentionUserCell
      }
      return undefined
    case PropertyType.MultiSelect:
      if (valuesAllowed) {
        return MultiSelectCell
      }
      return undefined
    case PropertyType.MultiEntitySearch:
      return MultiEntitySearchCell
    case PropertyType.MultiLineText:
      return MultiLineTextCell
    default:
      return undefined
  }
}

const WrapperDiv = styled('div')<{ focused?: boolean; editable?: boolean }>(
  ({ focused, editable }) => {
    let style: CSSProperties = {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      border: 'solid 2px transparent',
      padding: '1px 3px',
      display: 'flex',
      position: 'relative',
    }
    if (editable) {
      style['&:hover'] = {
        border: `solid 2px ${Color.MAIN}80`, // '80' means opacity 50%
      }
    }
    if (focused) {
      style = {
        ...style,
        border: `solid 2px ${Color.MAIN} !important`,
      }
    }
    return style as any
  }
)
const EditedMark = styled('div')({
  width: 0,
  height: 0,
  top: 2,
  right: -1,
  borderStyle: 'solid',
  borderWidth: '0 10px 10px 0',
  borderColor: `transparent ${Color.ALERT} transparent transparent`,
  marginTop: '-3px',
  position: 'absolute',
})

const Cell = styled(TableCell)({
  border: 'solid 1px #cccccc',
  padding: '0px !important',
})

export default DataCell
