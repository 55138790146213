import {
  ClickAwayListener,
  ListItemButton,
  Typography,
  styled,
} from '@mui/material'

import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { useCallback, useContext, useMemo } from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import useAnchorButton from './useAnchorButton'
import { FilterModel } from './filterModel'
import { TextFilter } from './TextFilter'
import { TextFilter as TextFilterModel } from '../../../../containers/BulkSheetView/components/filter/TextFilter'
import { SelectFilter } from './SelectFilter'
import KanbanContext from '../../KanbanContext'
import { SelectFilter as SelectFilterModel } from '../../../../containers/BulkSheetView/components/filter/CustomEnumFilter'

type Props = {
  filterModels: FilterModel[]
  updateFilterModel: (filter: FilterModel) => void
}

const Filters = ({ filterModels, updateFilterModel }: Props) => {
  const { projectMembersForFilter, prioritiesForFilter } =
    useContext(KanbanContext)

  const {
    isOpen: isOpenDisplayName,
    anchorEl: displayNameAnchorEl,
    handleClose: handleCloseDisplayName,
    toggleOpen: toggleOpenDisplayName,
  } = useAnchorButton()
  const {
    isOpen: isOpenResponsible,
    anchorEl: responsibleAnchorEl,
    handleClose: handleCloseResponsible,
    toggleOpen: toggleOpenResponsible,
  } = useAnchorButton()
  const {
    isOpen: isOpenPriority,
    anchorEl: priorityAnchorEl,
    handleClose: handleClosePriority,
    toggleOpen: toggleOpenPriority,
  } = useAnchorButton()

  const displayNameFilterModel = useMemo(
    () =>
      filterModels.find(m => m.key === 'DISPLAY_NAME')
        ?.value as TextFilterModel,
    [filterModels]
  )
  const responsibleFilterModel = useMemo(
    () =>
      filterModels.find(m => m.key === 'RESPONSIBLE')
        ?.value as SelectFilterModel,
    [filterModels]
  )
  const priorityFilterModel = useMemo(
    () =>
      filterModels.find(m => m.key === 'PRIORITY')?.value as SelectFilterModel,
    [filterModels]
  )

  const displayNameFilterChangedCallback = useCallback(
    (changed?: TextFilterModel) => {
      updateFilterModel({
        key: 'DISPLAY_NAME',
        value: changed,
      })
    },
    [updateFilterModel]
  )
  const responsibleFilterChangedCallback = useCallback(
    (changed?: SelectFilterModel) => {
      updateFilterModel({
        key: 'RESPONSIBLE',
        value: changed,
      })
    },
    [updateFilterModel]
  )
  const priorityFilterChangedCallback = useCallback(
    (changed?: SelectFilterModel) => {
      updateFilterModel({
        key: 'PRIORITY',
        value: changed,
      })
    },
    [updateFilterModel]
  )

  return (
    <StyledFilterButtonArea>
      <ClickAwayListener onClickAway={handleCloseDisplayName}>
        <div>
          <StyledListItemButton onClick={toggleOpenDisplayName}>
            <ListItemButtonTitle>
              {intl.formatMessage({ id: 'projectPlan.displayName' })}
            </ListItemButtonTitle>
            <StyledNavigateNextRoundedIcon isOpen={isOpenDisplayName} />
          </StyledListItemButton>
          {isOpenDisplayName && (
            <TextFilter
              anchorEl={displayNameAnchorEl}
              valueGetter={params => params.data.displayName}
              filterChangedCallback={displayNameFilterChangedCallback}
              defaultValue={displayNameFilterModel}
            />
          )}
        </div>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleCloseResponsible}>
        <div>
          <StyledListItemButton onClick={toggleOpenResponsible}>
            <ListItemButtonTitle>
              {intl.formatMessage({ id: 'projectPlan.responsible' })}
            </ListItemButtonTitle>
            <StyledNavigateNextRoundedIcon isOpen={isOpenResponsible} />
          </StyledListItemButton>
          {isOpenResponsible && (
            <SelectFilter
              anchorEl={responsibleAnchorEl}
              options={projectMembersForFilter}
              filterChangedCallback={responsibleFilterChangedCallback}
              values={responsibleFilterModel}
            />
          )}
        </div>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={handleClosePriority}>
        <div>
          <StyledListItemButton onClick={toggleOpenPriority}>
            <ListItemButtonTitle>
              {intl.formatMessage({ id: 'projectPlan.priority' })}
            </ListItemButtonTitle>
            <StyledNavigateNextRoundedIcon isOpen={isOpenPriority} />
          </StyledListItemButton>
          {isOpenPriority && (
            <SelectFilter
              anchorEl={priorityAnchorEl}
              options={prioritiesForFilter}
              filterChangedCallback={priorityFilterChangedCallback}
              values={priorityFilterModel}
            />
          )}
        </div>
      </ClickAwayListener>
    </StyledFilterButtonArea>
  )
}

const StyledFilterButtonArea = styled('div')({
  minWidth: 'fit-content',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '14px',
  color: colorPalette.monotone[4],
})

const StyledNavigateNextRoundedIcon = styled(NavigateNextRoundedIcon)<{
  isOpen: boolean
}>(props => ({
  marginLeft: '5px',
  color: colorPalette.monotone[4],
  cursor: 'pointer',
  transform: props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const StyledListItemButton = styled(ListItemButton)({ borderRadius: '4px' })

const ListItemButtonTitle = styled(Typography)({ marginRight: '0px' })

export default Filters
