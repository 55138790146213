import { useCallback, useEffect, useState } from 'react'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WbsSubStatus } from '../../../../domain/value-object/WbsSubStatus'
import { SelectOption } from '../../../model/selectOption'
import { useWbsSubStatusRepository } from '../../../../services/adaptors/wbsSubStatusRepositoryAdaptor'
import { useCustomEnumRepository } from '../../../../services/adaptors/customEnumRepositoryAdaptor'

export type WbsItemSelectOptions = {
  subStatuses: SelectOption[]
  difficulties: SelectOption[]
  priorities: SelectOption[]
}
export type StatusAndSubStatusRelation = {
  getStatusBySubStatus: (substatus: string | undefined) => WbsItemStatus
  getSubStatusByStatus: (
    status: WbsItemStatus | undefined
  ) => WbsSubStatus | undefined
}
export const useWbsItemSelectOptions = (
  projectUuid: string,
  type: WbsItemType
): WbsItemSelectOptions & StatusAndSubStatusRelation => {
  const [subStatuses, setSubStatuses] = useState<WbsSubStatus[]>([])
  const [subStatusOptions, setSubStatusOptions] = useState<SelectOption[]>([])
  const [difficulties, setDifficulties] = useState<SelectOption[]>([])
  const [priorities, setPriorities] = useState<SelectOption[]>([])
  const { getByType } = useWbsSubStatusRepository()
  const { fetchAsVO } = useCustomEnumRepository()
  useEffect(() => {
    const fn = async () => {
      const [subStatusResponse, difficultyResponse, priorityResponse] =
        await Promise.all([
          getByType(projectUuid, type, undefined),
          fetchAsVO('WBS_DIFFICULTY', projectUuid),
          fetchAsVO('WBS_PRIORITY', projectUuid),
        ])
      setSubStatuses(subStatusResponse)
      setSubStatusOptions(subStatusResponse)
      setDifficulties(difficultyResponse)
      setPriorities(priorityResponse)
    }
    fn()
  }, [fetchAsVO, getByType, projectUuid, type])
  const getSubStatusByStatus = useCallback(
    (status: WbsItemStatus | undefined): WbsSubStatus | undefined => {
      return subStatuses.find(v => v.status === status)
    },
    [subStatuses]
  )
  const getStatusBySubStatus = useCallback(
    (substatus: string | undefined) => {
      return (
        subStatuses.find(v => v.value === substatus)?.status ||
        WbsItemStatus.TODO
      )
    },
    [subStatuses]
  )
  return {
    subStatuses: subStatusOptions,
    difficulties,
    priorities,
    getSubStatusByStatus,
    getStatusBySubStatus,
  }
}
