import { useCallback, useEffect, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../../utils/date'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'

type StoredProjectPlanNewPageState = {
  aggregateField: AggregateField
  aggregateTarget: AggregateTarget
  workloadUnit: WorkloadUnit
  dateFormat: string
  rowHeight: ROW_HEIGHT
  toolbar?: ToolbarToggleValue
  report: boolean
}

export const usePageState = (
  functionUuid
): {
  initialized: boolean
  aggregateField: AggregateField
  setAggregateField: (_: AggregateField) => void
  aggregateTarget: AggregateTarget
  setAggregateTarget: (_: AggregateTarget) => void
  workloadUnit: WorkloadUnit
  setWorkloadUnit: (_: WorkloadUnit) => void
  dateFormat: string
  setDateFormat: (_: string) => void
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
  toolbar?: ToolbarToggleValue
  setToolbar: (_?: ToolbarToggleValue) => void
  report: boolean
  setReport: (_: boolean) => void
} => {
  const [aggregateField, setAggregateField] = useState<AggregateField>(
    AggregateField.WBS_ITEM_WORKLOAD
  )
  const [aggregateTarget, setAggregateTarget] = useState<AggregateTarget>(
    WbsItemType.TASK
  )
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.HOUR
  )
  const [dateFormat, setDateFormat] = useState(
    DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
  )
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [toolbar, setToolbar] = useState<ToolbarToggleValue>()
  const [initialized, setInitialized] = useState(false)
  const [report, setReport] = useState<boolean>(false)

  useEffect(() => {
    restorePageState()
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      aggregateField,
      aggregateTarget,
      workloadUnit,
      dateFormat,
      rowHeight,
      toolbar,
      report,
    })
  }, [
    initialized,
    aggregateField,
    aggregateTarget,
    workloadUnit,
    dateFormat,
    rowHeight,
    toolbar,
    report,
  ])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const payload: StoredProjectPlanNewPageState = JSON.parse(stored.value)
      payload.aggregateField && setAggregateField(payload.aggregateField)
      payload.aggregateTarget && setAggregateTarget(payload.aggregateTarget)
      payload.workloadUnit && setWorkloadUnit(payload.workloadUnit)
      payload.dateFormat && setDateFormat(payload.dateFormat)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.toolbar && setToolbar(payload.toolbar)
      payload.report && setReport(payload.report)
    } finally {
      setInitialized(true)
    }
  }, [])

  const rememberPageState = useCallback(
    (value: StoredProjectPlanNewPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    []
  )

  return {
    initialized,
    aggregateField,
    setAggregateField,
    aggregateTarget,
    setAggregateTarget,
    workloadUnit,
    setWorkloadUnit,
    dateFormat,
    setDateFormat,
    rowHeight,
    setRowHeight,
    toolbar,
    setToolbar,
    report,
    setReport,
  }
}
