import { styled } from '@mui/system'
import { ColorPalette, ProgressReportGraphConfig } from '../..'
import { useEffect, useState } from 'react'
import { WbsItemTypeForWbsProgressLog } from '../../../../../lib/functions/wbsProgressLog'
import { AggregateField } from '../../../../../domain/entity/WbsItemEntity'
import LegendGroup from './LegendGroup'
import AddLegendButton from './AddLegendButton'

const RootDiv = styled('div')({
  display: `flex`,
  width: '100%',
  height: '100%',
  flexDirection: 'column',
})

interface LegendProps {
  config: ProgressReportGraphConfig[]
  onChange: (newConfigs: ProgressReportGraphConfig[]) => void
  onAdd: (added: ProgressReportGraphConfig[]) => void
  onRemove: (removed: ProgressReportGraphConfig[]) => void
  types: WbsItemTypeForWbsProgressLog[]
  colorPalettes: ColorPalette[]
  popColorPalette: () => ColorPalette
  changeColorPalette: (curr: ColorPalette, next: ColorPalette) => void
  aggregateType: AggregateField
}

const Legend = ({
  config,
  onChange,
  onAdd,
  onRemove,
  types,
  colorPalettes,
  popColorPalette,
  changeColorPalette,
  aggregateType,
}: LegendProps) => {
  const [configGroup, setConfigGroup] = useState<ProgressReportGraphConfig[][]>(
    []
  )
  useEffect(() => {
    const allTypeValues = new Set(config.map(c => c.key.type.value))
    const newGroup = Array.from(allTypeValues).map(v =>
      config.filter(c => c.key.type.value === v)
    )
    setConfigGroup(newGroup)
  }, [config])
  return (
    <RootDiv>
      {configGroup.map((group, index) => (
        <LegendGroup
          key={index}
          type={group[0].key.type}
          config={group}
          onChange={onChange}
          onRemove={onRemove}
          colorPalettes={colorPalettes}
          changeColorPalette={changeColorPalette}
        />
      ))}
      <AddLegendButton
        onAdd={onAdd}
        types={types}
        popColorPalette={popColorPalette}
        aggregateType={aggregateType}
      />
    </RootDiv>
  )
}

export default Legend
