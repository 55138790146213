import React from 'react'
import BaseButton, {
  Props as BaseButtonProps,
} from '../../../../components/buttons/BaseButton'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const Button = styled(BaseButton)({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  color: '#868686',
  border: '1px solid #DDDDDD',
}) as React.FC<BaseButtonProps>

interface OwnProps extends WrappedComponentProps {}
type Props = OwnProps & BaseButtonProps

const SaveConditionButton = (props: Props) => {
  const { intl, title } = props
  return (
    <Button {...props} variant="outlined">
      {title || intl.formatMessage({ id: 'wbsItemSearchFilterPanel.save' })}
    </Button>
  )
}

export default injectIntl(SaveConditionButton)
