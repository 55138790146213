import {
  CellKeyDownEvent,
  GridOptions,
  ICellEditorParams,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import {
  DefaultCellRenderer,
  EntitySearchCellRenderer,
  WbsItemTypeCellRenderer,
} from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { intl } from '../../../../i18n'
import BoolExpression from '../../../../utils/boolExpression'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import {
  EntitySearchCellEditor,
  SelectCellEditor,
  SlackChannelCellEditor,
} from '../../../containers/BulkSheetView/components/cellEditor'
import { ChatChannelMessageTicketCreationMappingRow } from '../chatChannelMessageTicketCreationMapping'
import { DevelopmentEvent } from '../../../../domain/value-object/development/DevelopmentEventVO'
import TicketList from '../../../../lib/functions/ticketList'

export const ChatChannelMessageTicketCreationMappingGridOptions =
  (): GridOptions => {
    return {
      groupHeaderHeight: 25,
      headerHeight: 45,
      rowHeight: 32,
      treeData: false,
      context: {},
      suppressLastEmptyLineOnPaste: true,
      // Row
      rowDragManaged: false,
      rowDragMultiRow: false,
      suppressMoveWhenRowDragging: true,
      enterMovesDownAfterEdit: true,
      onCellKeyDown: (params: CellKeyDownEvent) => {
        // @ts-ignore
        if (!['Delete', 'Backspace'].includes(params.event.key)) return
        const cellRanges = params.api.getCellRanges() || []
        cellRanges.forEach(range => {
          const start = Math.min(
            range.startRow!.rowIndex,
            range.endRow!.rowIndex
          )
          const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
          range.columns.forEach(column => {
            const colDef = column.getColDef()
            if (
              typeof colDef.editable === 'function'
                ? colDef.editable(params)
                : colDef.editable
            ) {
              for (let i = start; i <= end; i++) {
                const rowNode = params.api.getDisplayedRowAtIndex(i)
                rowNode?.setDataValue(column, undefined)
              }
            }
          })
        })
      },
      // Column
      columnTypes: columnTypes(),
      components: frameworkComponents,
      defaultColDef: {
        width: 85,
        editable: false,
        enableValue: false,
        sortable: true,
        resizable: true,
        suppressMenu: true,
        suppressSizeToFit: true,
        singleClickEdit: false,
        cellEditor: 'textEditor',
        cellStyle: { justifyContent: 'left' },
        cellRenderer: DefaultCellRenderer,
        onCellClicked: defaultOnCellClicked,
        suppressPaste: params => !_.isEmpty(params.context?.copied),
        suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
          return (
            !params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)
          )
        },
        valueSetter: (params: ValueSetterParams) => {
          const field = params.colDef.field || params.colDef.colId
          if (
            !field ||
            (!params.oldValue && !params.newValue) ||
            params.oldValue === params.newValue
          ) {
            return false
          }
          objects.setValue(params.data, field, params.newValue)
          params.data.edited = true
          store.dispatch(requireSave())
          return true
        },
      },
      columnDefs: [
        {
          field: 'uuid',
          hide: true,
          suppressColumnsToolPanel: true,
        },
        {
          colId: 'blank',
          hide: true,
          width: 35,
          suppressColumnsToolPanel: true,
        },
        {
          field: 'rowNumber',
          type: [ColumnType.sequenceNo],
          resizable: false,
          width: 35,
          cellRenderer: SequenceNoCellRenderer,
          cellRendererParams: params => {
            return {
              value: params.node.rowIndex + 1,
            }
          },
        },
        {
          headerName: intl.formatMessage({
            id: 'chatChannelMessageTicketCreationMapping.column.groupHeader.setting',
          }),
          children: [
            {
              field: 'chatChannel',
              headerName: intl.formatMessage({
                id: 'chatChannelMessageTicketCreationMapping.column.header.chatChannel',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 250,
              editable: true,
              valueFormatter: (
                params: ValueFormatterParams<ChatChannelMessageTicketCreationMappingRow>
              ) => {
                return params.value?.slackChannelName ?? ''
              },
              cellEditor: SlackChannelCellEditor,
            },
            {
              field: 'ticketType',
              headerName: intl.formatMessage({
                id: 'chatChannelMessageTicketCreationMapping.column.header.ticketType',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 200,
              editable: true,
              cellEditor: SelectCellEditor,
              cellEditorParams: {
                options: (params: ICellEditorParams) => {
                  const ticketTypes = params.context['ticketType']
                  if (!ticketTypes) {
                    return []
                  }
                  return ticketTypes.map(v => ({
                    value: v,
                    label: v.getNameWithSuffix(),
                    iconUrl: v.iconUrl,
                  }))
                },
              },
              valueFormatter: (
                params: ValueFormatterParams<ChatChannelMessageTicketCreationMappingRow>
              ) => (params.value ? params.value.getNameWithSuffix() : ''),
              valueSetter: (
                params: ValueSetterParams<ChatChannelMessageTicketCreationMappingRow>
              ) => {
                if (!params.newValue) {
                  return false
                }
                params.data.ticketType = params.newValue
                params.data.ticketList = undefined
                params.data.edited = true
                return true
              },
              cellRenderer: WbsItemTypeCellRenderer,
              cellRendererParams: {
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
            },
            {
              field: 'ticketList',
              headerName: intl.formatMessage({
                id: 'chatChannelMessageTicketCreationMapping.column.header.ticketList',
              }),
              hide: false,
              pinned: true,
              lockPosition: 'left',
              width: 200,
              editable: true,
              type: [ColumnType.autocomplete],
              cellRenderer: EntitySearchCellRenderer,
              cellEditor: EntitySearchCellEditor,
              cellEditorParams: {
                fetch: async data => {
                  const ticketType = data?.ticketType
                  if (!ticketType) return []
                  return TicketList.search('', {
                    ticketType: ticketType.code,
                  })
                },
              },
              suppressKeyboardEvent: params =>
                (!params.editing &&
                  ['Delete', 'Backspace'].includes(params.event.key)) ||
                (params.editing && params.event.key === 'Enter'),
              cellRendererParams: {
                uiMeta: {
                  requiredIf: BoolExpression.of(true),
                } as Partial<FunctionProperty>,
              },
            },
          ],
        },
        {
          headerName: intl.formatMessage({ id: 'changeLog' }),
          children: [
            {
              field: 'revision',
              headerName: intl.formatMessage({ id: 'revision' }),
              hide: true,
              width: 90,
            },
            {
              field: 'createdBy',
              headerName: intl.formatMessage({ id: 'createdBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams) => {
                return params.data.createdBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'createdBy.name',
                iconUrlField: 'createdBy.iconUrl',
              },
            },
            {
              field: 'createdAt',
              headerName: intl.formatMessage({ id: 'createdAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
            },
            {
              field: 'updatedBy',
              headerName: intl.formatMessage({ id: 'updatedBy' }),
              hide: true,
              width: 110,
              valueGetter: (params: ValueGetterParams) => {
                return params.data.updatedBy?.name
              },
              cellRenderer: IconCellRenderer,
              cellRendererParams: {
                labelField: 'updatedBy.name',
                iconUrlField: 'updatedBy.iconUrl',
              },
            },
            {
              field: 'updatedAt',
              headerName: intl.formatMessage({ id: 'updatedAt' }),
              hide: true,
              width: 120,
              type: [ColumnType.dateTime],
            },
          ],
        },
      ],
    }
  }

const AllDevelopmentEvents = [
  DevelopmentEvent.PUSH,
  DevelopmentEvent.CREATE_BRANCH,
  DevelopmentEvent.CREATE_PULL_REQUEST,
  DevelopmentEvent.MERGE_PULL_REQUEST,
]
export const DevelopmentEventLabels = {
  [DevelopmentEvent.PUSH]: intl.formatMessage({ id: 'development.event.push' }),
  [DevelopmentEvent.CREATE_BRANCH]: intl.formatMessage({
    id: 'development.event.createBranch',
  }),
  [DevelopmentEvent.CREATE_PULL_REQUEST]: intl.formatMessage({
    id: 'development.event.createPullRequest',
  }),
  [DevelopmentEvent.MERGE_PULL_REQUEST]: intl.formatMessage({
    id: 'development.event.mergePullRequest',
  }),
}
