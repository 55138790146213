import {
  BulkSheetOptions,
  BulkSheetState,
  BulkSheetContext,
  BulkSheetSpecificProps,
} from '../../containers/BulkSheet'
import ViewMeta from '../../containers/meta/ViewMeta'
import { APIResponse } from '../../../lib/commons/api'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { UserDetail } from '../../../lib/functions/user'
import moment from 'moment'
import {
  formatDateWithDay,
  DISPLAY_DATE_FORMAT,
  isSaturday,
} from '../../../utils/date'
import {
  ValueSetterParams,
  ColDef,
  ValueGetterParams,
  ColumnFunctionCallbackParams,
} from 'ag-grid-community'
import { TeamProps } from '../../../lib/functions/team'
import { Tree } from '../../../lib/commons/tree'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import {
  ScheduledOperationTimeDetail,
  GetScheduledOperationTimeProps,
  getScheduledOperationTime,
  updateBatch,
  ScheduledOperationTimeUpdateBatchRequest,
  SetMemberWorkDateInput,
  DeleteMemberWorkDateInput,
} from '../../../lib/functions/scheduledOperationTime'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendar,
} from '../../../lib/functions/organizationWorkingDayCalendar'
import { generateUuid } from '../../../utils/uuids'
import { intl } from '../../../i18n'
import store from '../../../store'
import { requireSave } from '../../../store/requiredSaveData'
import DateVO from '../../../vo/DateVO'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export class ScheduledOperationTimeRow extends RowData {
  user?: UserDetail
  team?: TeamProps
  projectReleasedDate?: string
  memberWorkDates?: MemberWorkDateCell[]
  revision?: string
  isTotal?: boolean
}

interface MemberWorkDateCell {
  uuid?: string
  lockVersion?: number
  date?: string
  hour?: number
  isEdited?: boolean
}

export interface ScheduledOperationTimeContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    ScheduledOperationTimeDetail,
    ScheduledOperationTimeRow,
    ScheduledOperationTimeState
  > {}

export interface ScheduledOperationTimeState extends BulkSheetState {
  year?: number
  month?: number
}

class ScheduledOperationTimeRowSpec extends RowDataSpec<
  ScheduledOperationTimeDetail,
  ScheduledOperationTimeRow
> {
  createNewRow(): ScheduledOperationTimeRow {
    throw new Error('Row cannot be added.')
  }
  overwriteRowItemsWithParents(params: {
    child: ScheduledOperationTimeRow
    parent: ScheduledOperationTimeRow
  }): ScheduledOperationTimeRow {
    throw new Error('overwriteRowItemsWithParents is not implemented.')
  }
  createRowByResponse(
    response: ScheduledOperationTimeDetail
  ): ScheduledOperationTimeRow {
    return {
      uuid: response.user.uuid,
      user: response.user,
      team: response.team,
      projectReleasedDate: response.projectReleasedDate,
      memberWorkDates: response.memberWorkDates.map(memberWorkDate => {
        return {
          ...memberWorkDate,
          date: moment(memberWorkDate.date).format(DISPLAY_DATE_FORMAT),
          isEdited: memberWorkDate.edited,
        }
      }),
      isTotal: false,
    }
  }
}

export default class ScheduledOperationTimeOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  ScheduledOperationTimeDetail,
  ScheduledOperationTimeRow,
  ScheduledOperationTimeState
> {
  addable = false
  draggable = false
  enableExcelExport = true
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ScheduledOperationTimeColumnAndFilterState}-${ctx.state.uuid}`
  getRowStyle = params => {
    if (
      params.data &&
      params.data.projectReleasedDate &&
      new DateVO(params.data.projectReleasedDate).isBefore(
        DateVO.now().getStartOfDay()
      )
    ) {
      return { backgroundColor: '#e0e0e0' }
    }
  }
  rowDataSpec = new ScheduledOperationTimeRowSpec()
  pinnedColumns = [
    'project.scheduledOperationTime.user.code',
    'project.scheduledOperationTime.user',
    'project.scheduledOperationTime.user.division.displayName',
    'project.scheduledOperationTime.user.position.displayName',
    'project.scheduledOperationTime.team',
  ]
  dynamicColumns = {
    'project.scheduledOperationTime.memberWorkDate': {
      getColumn: async (
        baseColumnDef: ColDef,
        ctx: ScheduledOperationTimeContext,
        viewMeta: ViewMeta
      ): Promise<ColDef[]> => {
        const columns: any[] = []
        const year = ctx.state.year ? ctx.state.year : moment().year()
        const month = ctx.state.month ? ctx.state.month - 1 : moment().month()
        const yearMonth = moment([year, month, 1])
        const lengthOfMonth = moment(yearMonth)
          .add(1, 'months')
          .diff(yearMonth, 'days')
        const dateArray = Array.from({ length: lengthOfMonth }).map(
          (_, day) => {
            return (
              moment(yearMonth).add(day, 'days').format(DISPLAY_DATE_FORMAT) ||
              ''
            )
          }
        )
        const response = await getOrganizationWorkingDayCalendar({
          startDate: new Date(dateArray[0]),
          endDate: new Date(dateArray[dateArray.length - 1]),
        })
        const workingDayCalendar = new OrganizationWorkingDayCalendar(
          response.json
        )

        const totalValueGetter = (params: ValueGetterParams) => {
          if (!params.api || !params.data) {
            return
          }
          if (params.data.isTotal) {
            let grandTotal = 0
            params.api.forEachNode(rowNode => {
              if (rowNode.data && !rowNode.data.isTotal) {
                rowNode.data.memberWorkDates &&
                  rowNode.data.memberWorkDates.forEach(memberWorkDate => {
                    const hour = Number(memberWorkDate.hour)
                    grandTotal += Number.isNaN(hour) ? 0 : hour
                  })
              }
            })
            return grandTotal
          }
          let total = 0
          params.data.memberWorkDates &&
            params.data.memberWorkDates.forEach(memberWorkDate => {
              const hour = Number(memberWorkDate.hour)
              total += Number.isNaN(hour) ? 0 : hour
            })
          return total
        }
        const totalColumn = [
          {
            ...baseColumnDef,
            // Disable number validation for total column
            cellRendererParams: {
              ...baseColumnDef.cellRendererParams,
              uiMeta: {
                ...baseColumnDef.cellRendererParams.uiMeta,
                minNumber: undefined,
                maxNumber: undefined,
              },
            },
            headerName: 'Total',
            lockPosition: true,
            pinned: true,
            editable: false,
            valueGetter: totalValueGetter,
          } as ColDef,
        ]
        const editable = (params: ColumnFunctionCallbackParams) => {
          return !params.data.isTotal
        }
        columns.push({
          ...baseColumnDef,
          colId: baseColumnDef.colId! + yearMonth.format('YYYY-MM'),
          headerName: yearMonth.format(
            intl.formatMessage({
              id: 'dateFormat.yyyy.mm',
            })
          ),
          lockPosition: true,
          children: totalColumn.concat(
            dateArray.map(date => {
              const valueGetter = (params: ValueGetterParams) => {
                if (params.data && params.data.isTotal) {
                  let total = 0
                  params.api &&
                    params.api.forEachNode(rowNode => {
                      if (
                        rowNode.data &&
                        rowNode.data.memberWorkDates &&
                        !rowNode.data.isTotal
                      ) {
                        const memberWorkDate =
                          rowNode.data.memberWorkDates.find(
                            cell => cell.date === params.colDef.field!
                          )
                        if (memberWorkDate && memberWorkDate.hour) {
                          const hour = Number(memberWorkDate.hour)
                          total += Number.isNaN(hour) ? 0 : hour
                        }
                      }
                    })
                  return total
                }
                if (
                  !(params.node && params.node.group) &&
                  !!params.data.memberWorkDates
                ) {
                  const memberWorkDate = params.data.memberWorkDates.find(
                    cell => cell.date === params.colDef.field!
                  )
                  return memberWorkDate ? memberWorkDate.hour : undefined
                }
                return undefined
              }
              const valueSetter = (params: ValueSetterParams) => {
                store.dispatch(requireSave())
                const memberWorkDate = params.data.memberWorkDates.find(
                  cell => cell.date === params.colDef.field!
                )
                if (memberWorkDate && memberWorkDate.hour !== params.newValue) {
                  memberWorkDate.hour = Number(params.newValue)
                  params.data.isEdited = true
                  memberWorkDate.isEdited = true
                  return true
                }
                if (!memberWorkDate) {
                  const added = {
                    date: params.colDef.field!,
                    hour: Number(params.newValue),
                    isEdited: true,
                  }
                  params.data.memberWorkDates.push(added)
                  params.data.isEdited = true
                  return true
                }
                return false
              }
              let headerClass = ''
              if (workingDayCalendar.isHoliday(date)) {
                // for sunday or holiday, set color red
                headerClass = 'sevend-ag-grid-date-header-holiday'
              }
              if (isSaturday(date)) {
                // for saturday, set color blue
                headerClass = 'sevend-ag-grid-date-header-saturday'
              }
              return {
                ...baseColumnDef,
                headerName: formatDateWithDay(moment(date)),
                colId: date,
                field: date,
                lockPosition: true,
                editable: editable,
                headerClass: headerClass,
                valueGetter: valueGetter,
                valueSetter: valueSetter,
                cellRendererParams: {
                  ...baseColumnDef.cellRendererParams,
                  highlightForZero: true,
                  zeroHighlightColor: !workingDayCalendar.isHoliday(date)
                    ? 'red'
                    : '',
                },
              }
            })
          ),
        })
        return columns
      },
    },
  }

  updateState = (
    root: Tree<ScheduledOperationTimeDetail>,
    state: ScheduledOperationTimeState
  ) => {
    return {
      ...state,
      resourcePlanUuid: root.uuid,
      lockVersion: root.lockVersion,
    }
  }

  updateDefaultState = (
    state: ScheduledOperationTimeState
  ): Promise<ScheduledOperationTimeState> => {
    const now = moment()
    return Promise.resolve({
      ...state,
      year: now.year(),
      month: now.month() + 1,
    })
  }

  getAll(state: ScheduledOperationTimeState): Promise<APIResponse> {
    const getScheduledOperationTimeProps: GetScheduledOperationTimeProps = {
      projectUuid: state.uuid,
      year: state.year || moment().year(),
      month: state.month || moment().month() + 1,
    }
    return getScheduledOperationTime(getScheduledOperationTimeProps)
  }
  onSubmit = (
    ctx: ScheduledOperationTimeContext,
    data: {
      edited: {
        before: ScheduledOperationTimeRow
        after: ScheduledOperationTimeRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    return updateBatch(this.createRequest(ctx.state))
  }

  private createRequest(
    state: ScheduledOperationTimeState
  ): ScheduledOperationTimeUpdateBatchRequest {
    let edited: SetMemberWorkDateInput[] = []
    let deleted: DeleteMemberWorkDateInput[] = []
    state.rowData.forEach((row: ScheduledOperationTimeRow) => {
      return row.memberWorkDates
        ? row.memberWorkDates
            .filter(cell => cell.isEdited)
            .forEach(cell => {
              const date = moment(cell.date)
              // the empty text is set when empty cell is copied and pasted.
              // @ts-ignore
              if (typeof cell.hour !== 'undefined' && cell.hour !== '') {
                edited.push({
                  projectUuid: state.uuid,
                  userUuid: row.user!.uuid,
                  lockVersion: cell.lockVersion,
                  year: date.year(),
                  month: date.month() + 1,
                  date: date.date(),
                  hour: cell.hour,
                })
              } else {
                deleted.push({
                  projectUuid: state.uuid,
                  userUuid: row.user!.uuid,
                  lockVersion: cell.lockVersion,
                  year: date.year(),
                  month: date.month() + 1,
                  date: date.date(),
                })
              }
            })
        : []
    })
    return {
      setMemberWorkDates: edited,
      deleteMemberWorkDates: deleted,
    }
  }

  onSearch(
    ctx: ScheduledOperationTimeContext,
    searchCondition: ScheduledOperationTimeState
  ) {
    ctx.setState(
      {
        year: searchCondition.year,
        month: searchCondition.month,
      },
      ctx.refreshDataWithLoading
    )
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'user.name') {
      return {
        labelField: 'user.name',
        iconUrlField: 'user.iconUrl',
      }
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'user.division.displayName',
        'user.position.displayName',
        'team',
      ].includes(field)
    ) {
      return 120
    }
    if (field === 'memberWorkDate') {
      return 90
    }
    return undefined
  }

  pinnedTopRowData: ScheduledOperationTimeRow[] = [
    {
      rowNumber: 'Total',
      uuid: generateUuid(),
      memberWorkDates: [],
      treeValue: [],
      isTotal: true,
      isViewOnly: true,
    },
  ]
}
