import { TextColor } from '../../../../../../../../styles/commonStyles'

const defaultStyle = {
  justifyContent: 'flex-end',
}

export default function (params) {
  if (!params || !params.node) {
    return defaultStyle
  }
  const link = params.colDef.cellRendererParams?.link
  if (
    (typeof link === 'boolean' && link) ||
    (typeof link === 'function' && link(params.node))
  ) {
    return {
      ...defaultStyle,
      cursor: 'pointer',
      color: 'blue',
    }
  }
  const highlightForZero =
    params.colDef.cellRendererParams &&
    params.colDef.cellRendererParams.highlightForZero
  return {
    ...defaultStyle,
    color:
      highlightForZero && !parseFloat(params.value)
        ? (params.colDef.cellRendererParams &&
            params.colDef.cellRendererParams.zeroHighlightColor) ||
          TextColor.GREY
        : '#000000',
  }
}
