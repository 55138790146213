export type I18nLabel = {
  languageCode: string
  label: string
}
export type I18nLabelVO = {
  labelId: string
  labels: I18nLabel[]
}
export type I18nLabelObject = {
  labelId: string
  labels: I18nLabel[]
}

const DEFAULT_LANGUAGE_CODE = 'en'
/** VO service functions */
// Getters
const getCurrentLanguageCode = (): string => {
  return navigator.language.split(/[-_]/)[0]
}
const getLabel = (vo: I18nLabelVO): string => {
  const languageCode = navigator.language.split(/[-_]/)[0]
  const label = vo.labels.find(label => label.languageCode === languageCode)
  if (label) {
    return label.label
  }

  const labelEn = vo.labels.find(
    label => label.languageCode === DEFAULT_LANGUAGE_CODE
  )
  if (labelEn) {
    return labelEn.label
  }
  return ''
}
// Setters
const setLabels = (vo: I18nLabelVO, labels: I18nLabel[]): I18nLabelVO => {
  return {
    labelId: vo.labelId,
    labels,
  }
}
// Others
const hasDiff = (a: I18nLabelVO, b: I18nLabelVO): boolean => {
  if (a.labelId !== b.labelId) {
    return true
  }
  const aLabels = a.labels
  const bLabels = b.labels
  return (
    aLabels.length !== bLabels.length ||
    aLabels.some(aLabel => {
      const bLabel = bLabels.find(v => v.languageCode === aLabel.languageCode)
      if (!bLabel) {
        return true
      }
      return aLabel.label !== bLabel.label
    })
  )
}

export const i18nLabelVoService = {
  getCurrentLanguageCode,
  getLabel,
  setLabels,
  hasDiff,
}
