import React, { useCallback, useEffect, useState } from 'react'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { connect } from 'react-redux'
import { styled } from '@mui/system'
import { AllState } from '../../../../store'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { List } from 'immutable'
import { CustomComponent, FunctionLayer } from '../../../../store/functionLayer'
import { Divider as MuiDivider } from '@mui/material'

const AppBar = styled(MuiAppBar)({
  background: '#fff',
  color: '#000',
  height: '40px',
})
const Custom = styled('div')<{ end?: boolean }>(({ end }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  margin: end ? '0 0 0 auto' : undefined,
}))
const Divider = styled(MuiDivider)({
  height: '17px',
  margin: 3,
})

interface ComponentOwnProps {
  depth: number
  TitleComponent?: JSX.Element
  HeaderComponent?: JSX.Element
}

interface Props extends ComponentOwnProps, WrappedComponentProps, StateProps {}

interface StateProps {
  layer?: FunctionLayer
}

const HeaderBar = (props: Props) => {
  const { depth, layer } = props
  const [leftComponents, setLeftComponents] = useState<JSX.Element[]>([])
  const [rightComponents, setRightComponents] = useState<JSX.Element[]>([])
  const [endComponents, setEndComponents] = useState<JSX.Element[]>([])
  const extractComponent = useCallback(
    (components: List<CustomComponent>, position: 'left' | 'right' | 'end') => {
      return components
        .filter(c => c.layout.position === position)
        .sort((a, b) => a.layout.index - b.layout.index)
        .map(c => c.component)
        .toArray()
    },
    [layer]
  )
  useEffect(() => {
    setLeftComponents(
      extractComponent(layer?.customHeaderComponents || List(), 'left')
    )
    setRightComponents(
      extractComponent(layer?.customHeaderComponents || List(), 'right')
    )
    setEndComponents(
      extractComponent(layer?.customHeaderComponents || List(), 'end')
    )
  }, [layer])

  return (
    <AppBar position="static" id="headerBar" elevation={0}>
      <Toolbar variant="dense">
        {leftComponents && leftComponents.length > 0 && (
          <>
            <Custom>{leftComponents}</Custom>
            <Divider orientation="vertical" />
          </>
        )}
        {props.TitleComponent}
        {props.HeaderComponent}
        {rightComponents && <Custom>{rightComponents}</Custom>}
        {endComponents && <Custom end={true}>{endComponents}</Custom>}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state: AllState, props: ComponentOwnProps) => ({
  layer: state.functionLayer.layers.get(props.depth),
})

export default connect(mapStateToProps)(injectIntl(HeaderBar))
