import { LineStyle } from '../../view/components/charts/Chart/TwoDimChart/LineGraph/index'
import { WbsProgressLogViewConfiguration } from '../../view/containers/ReportContainer/ReportComponent/ViewConfiguration/WbsProgressLog'
import API, { APIResponse } from '../commons/api'
import { WbsItemStatus } from '../../view/containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { intl } from '../../i18n'
import { Tree } from '../commons/tree'
import { fromSnakeToCamel } from '../../utils/string'
import objects from '../../utils/objects'
import {
  DayOfWeek,
  TimeGrain,
  DataPerDim,
  ReportDataManager,
  ReportDataSeries,
  MAX_DIM_TIME,
} from './report'
import { AggregateField, WbsItemType } from '../../domain/entity/WbsItemEntity'
import { getHoursPerWorkloadUnit } from '../../view/hooks/useWorkloadUnit'
import { WorkloadUnit } from './workload'
import store from '../../store'

export interface WbsProgressLogKey {
  type: WbsItemTypeForWbsProgressLog
  aggregateColumn: AggregateColumn
}

export type WbsProgressLogDataSeries = ReportDataSeries<
  WbsProgressLogKey,
  WbsProgressLogData
>

export type WbsProgressLogDataPerDim = DataPerDim<WbsProgressLogData>

export class WbsProgressLogData {
  workloadBy: { [key: string]: number } = {}
  countBy: { [key: string]: number } = {}
  wbsItemCodes: string[] = []
}

export class WbsProgressLogDataManager extends ReportDataManager<
  WbsProgressLogKey,
  WbsProgressLogData,
  WbsProgressLogViewConfiguration
> {
  getValue(
    data: WbsProgressLogDataPerDim,
    viewConfig: WbsProgressLogViewConfiguration,
    keys?: string[]
  ): number {
    let targetData = {} as { [key: string]: number }
    const isCount =
      viewConfig.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
    if (isCount) {
      targetData = data.data.countBy || ({} as { [key: string]: number })
    }
    if (viewConfig.aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD) {
      targetData = data.data.workloadBy || ({} as { [key: string]: number })
    }
    const value =
      Object.entries(targetData)
        .filter(e => !keys || keys.includes(e[0]))
        .map(e => e[1])
        .reduce((acc, curr) => (acc += curr), 0) || 0
    let denominator = 1
    if (!isCount) {
      const organization = store.getState().tenant.organization!
      denominator = getHoursPerWorkloadUnit(
        WorkloadUnit[viewConfig.aggregateTargetUnit],
        {
          dailyWorkHours: organization.dailyWorkHours,
          monthlyWorkDays: organization.monthlyWorkDays,
        }
      )
    }
    return value / denominator
  }

  getFormattedValue(
    data: WbsProgressLogDataPerDim,
    viewConfig: WbsProgressLogViewConfiguration,
    key?: string
  ): string {
    const value = this.getValue(data, viewConfig, key ? [key] : undefined)
    if (viewConfig.aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
      return Number(value || 0).toFixed(0)
    } else if (
      viewConfig.aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD
    ) {
      return Number(value || 0).toFixed(2)
    }
    return ''
  }

  add = (a: WbsProgressLogData, b: WbsProgressLogData): WbsProgressLogData => {
    const addEach = (
      a: { [key: string]: number },
      b: { [key: string]: number }
    ): { [key: string]: number } => {
      const keySet = new Set([...Object.keys(a), ...Object.keys(b)])
      const getValue = (v: { [key: string]: number }, k): number => v[k] || 0
      let sum = {}
      keySet.forEach(k =>
        objects.setValue(sum, k, getValue(a, k) + getValue(b, k))
      )
      return sum
    }
    return {
      workloadBy: addEach(a.workloadBy, b.workloadBy),
      countBy: addEach(a.countBy, b.countBy),
      wbsItemCodes: [...a.wbsItemCodes, ...b.wbsItemCodes],
    }
  }
  // a - b
  subtract = (
    a: WbsProgressLogData,
    b: WbsProgressLogData
  ): WbsProgressLogData => {
    const subtractEach = (
      a: { [key: string]: number },
      b: { [key: string]: number }
    ): { [key: string]: number } => {
      const keySet = new Set([...Object.keys(a), ...Object.keys(b)])
      const getValue = (v: { [key: string]: number }, k): number => v[k] || 0
      let sum = {}
      keySet.forEach(k =>
        objects.setValue(sum, k, getValue(a, k) - getValue(b, k))
      )
      return sum
    }
    return {
      workloadBy: subtractEach(a.workloadBy, b.workloadBy),
      countBy: subtractEach(a.countBy, b.countBy),
      wbsItemCodes: [
        ...a.wbsItemCodes.filter(code => !b.wbsItemCodes.includes(code)),
      ],
    }
  }
  accumulate = (
    data: WbsProgressLogDataPerDim[],
    dimTimes: number[],
    option: 'none' | 'burnUp' | 'burnDown'
  ): WbsProgressLogDataPerDim[] => {
    const unplanned = data.filter(d => typeof d.dimTime === 'undefined')
    const previous = data.find(d => d.dimTime === 0) || {
      dimTime: 0,
      data: new WbsProgressLogData(),
    }
    const aggregated: WbsProgressLogDataPerDim[] = []
    if (option === 'none') {
      const targetData = data.filter(d => !!d.dimTime)
      let k = 0
      for (let j = 0; j < dimTimes.length; j++) {
        const dimTime = dimTimes[j]
        const nextDimTime =
          j < dimTimes.length - 1 ? dimTimes[j + 1] : MAX_DIM_TIME
        let sum = new WbsProgressLogData()
        for (let i = k; i < targetData.length; i++) {
          const d = targetData[i]
          if (dimTime <= d.dimTime && d.dimTime < nextDimTime) {
            sum = this.add(sum, d.data)
          } else {
            k = i
            break
          }
        }
        aggregated.push({ dimTime, data: sum })
      }
    } else if (option === 'burnUp') {
      const targetData = data.filter(d => typeof d.dimTime !== 'undefined')
      let k = 0
      for (let j = 0; j < dimTimes.length; j++) {
        const dimTime = dimTimes[j]
        const nextDimTime =
          j < dimTimes.length - 1 ? dimTimes[j + 1] : MAX_DIM_TIME
        let sum =
          aggregated.length === 0
            ? previous.data
            : aggregated[aggregated.length - 1].data
        for (let i = k; i < targetData.length; i++) {
          const d = targetData[i]
          if (dimTime <= d.dimTime && d.dimTime < nextDimTime) {
            sum = this.add(sum, d.data)
          } else {
            k = i
            break
          }
        }
        aggregated.push({ dimTime, data: sum })
      }
    } else if (option === 'burnDown') {
      const targetData = data.filter(d => !!d.dimTime)
      const total = data
        .map(d => d.data)
        .reduce(
          (acc, curr) => (acc = this.add(acc, curr)),
          new WbsProgressLogData()
        )
      let k = 0
      for (let j = 0; j < dimTimes.length; j++) {
        const dimTime = dimTimes[j]
        const nextDimTime =
          j < dimTimes.length - 1 ? dimTimes[j + 1] : MAX_DIM_TIME
        let sum =
          aggregated.length === 0
            ? total
            : aggregated[aggregated.length - 1].data
        for (let i = k; i < targetData.length; i++) {
          const d = targetData[i]
          if (dimTime <= d.dimTime && d.dimTime < nextDimTime) {
            sum = this.subtract(sum, d.data)
          } else {
            k = i
            break
          }
        }
        aggregated.push({ dimTime, data: sum })
      }
    }
    aggregated.push(...unplanned)
    return aggregated
  }
}

// For table.
export interface WbsProgressLogAsTree extends Tree<WbsProgressLogAsTree> {
  key: WbsProgressLogKey
  data: WbsProgressLogDataPerDim[]
  graphType: GraphType
}

export enum WbsProgressLogChartType {
  PROGRESS = 'PROGRESS',
  REPORT = 'REPORT',
}

export enum GraphType {
  BAR = 'BAR',
  BURN_UP = 'BURN_UP',
  BURN_DOWN = 'BURN_DOWN',
}

export const getGraphTypeLabel = (type: GraphType) => {
  switch (type) {
    case GraphType.BURN_UP:
      return intl.formatMessage({ id: 'progressReport.graphType.burnUp' })
    case GraphType.BURN_DOWN:
      return intl.formatMessage({ id: 'progressReport.graphType.burnDown' })
  }
}

export type WbsItemTypeForWbsProgressLog = {
  value: string
  name: string
  iconUrl?: string
}

export enum AggregateTargetUnit {
  HOUR = 'HOUR',
  DAY = 'DAY',
  MONTH = 'MONTH',
}

export interface AggregateBy {
  type: string
  aggregateColumn: AggregateColumn[]
}

export enum AggregateColumn {
  SCHEDULED_START_DATE = 'SCHEDULED_START_DATE',
  ACTUAL_START_DATE = 'ACTUAL_START_DATE',
  SCHEDULED_END_DATE = 'SCHEDULED_END_DATE',
  ACTUAL_END_DATE = 'ACTUAL_END_DATE',
  CREATED_AT = 'CREATED_AT',
  ACTUAL_HOUR_RECORDED_AT = 'ACTUAL_HOUR_RECORDED_AT',
}

export const getAggregateColumnLabel = (aggregateColumn: AggregateColumn) => {
  return intl.formatMessage({
    id: `progressReport.aggregateColumn.${fromSnakeToCamel(aggregateColumn)}`,
  })
}

export const getLineStyleForAggregateColumn = (
  aggregateColumn: AggregateColumn
): LineStyle => {
  if (
    [
      AggregateColumn.SCHEDULED_START_DATE,
      AggregateColumn.SCHEDULED_END_DATE,
    ].includes(aggregateColumn)
  ) {
    return LineStyle.DASHED
  }
  return LineStyle.STRAIGHT
}

// API
export type WbsProgressLogResponse = {
  key: AggregateKey
  data: WbsProgressLogPerDim[]
}[]

export interface AggregateKey {
  type: WbsItemTypeForWbsProgressLog
  aggregateColumn: AggregateColumn
}

export interface WbsProgressLogPerDim {
  dimTime: number
  estimatedBy: { [key: string]: number }
  actualBy: { [key: string]: number }
  countBy: { [key: string]: number }
  wbsItemCodes: string[]
}

export interface WbsProgressLogRequestCommonCondition {
  projectUuid?: string
  teamUuid?: string
  sprintUuid?: string
  accountableUuid?: string
  responsibleUuid?: string
  priority?: string
}

export interface WbsProgressLogRequestBase
  extends WbsProgressLogRequestCommonCondition {
  timeGrain: TimeGrain
  startDayOfWeek?: DayOfWeek
  useWriteModel: boolean
  aggregateBy: {
    wbsItemType?: WbsItemType
    ticketType?: string
    aggregateColumn: AggregateColumn[]
  }[]
  time?: {
    from?: number
    to?: number
  }
  projectUuid?: string
  rootWbsItemUuid?: string
  wbsItemCodes?: string[]
  aggregate?: boolean
  wbsItemStatusList: WbsItemStatus[]
}

export interface WbsProgressLogRequest extends WbsProgressLogRequestBase {
  useWriteModel: boolean
}

export function getWbsProgressLog(
  request: WbsProgressLogRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    `/api/v1/projects/chart/wbs_progress_logs`,
    request
  )
}

export interface WbsItemProgressLogRequest extends WbsProgressLogRequestBase {}

export type WbsItemProgressLogResponseWbsItemData = {
  code: string
  status: WbsItemStatus
  actual: number
  estimated: number
}

export type WbsItemProgressLogResponseData = {
  dimTime: number
  wbsItemDatas: WbsItemProgressLogResponseWbsItemData[]
}

export type WbsItemProgressLogResponse = {
  key: WbsProgressLogKey
  data: WbsItemProgressLogResponseData[]
}[]

export function getWbsItemProgressLog(
  request: WbsItemProgressLogRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    `/api/v1/projects/chart/wbs_progress_logs/wbs_items`,
    request
  )
}

export interface TicketWbsItemProgressLogRequest
  extends WbsProgressLogRequestCommonCondition {
  ticketListUuid: string
}

export function getTicketWbsItemProgressLog(
  request: TicketWbsItemProgressLogRequest
): Promise<APIResponse> {
  return API.functional.request(
    'GET',
    `/api/v1/projects/chart/wbs_progress_logs/ticket/wbs_items`,
    request
  )
}
