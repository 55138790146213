import { ColDef } from 'ag-grid-community'
import { Collapse } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PageArea, PageProps } from '..'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { ResourcePlanNewDetail } from '../../../lib/functions/resourcePlanNew'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import ResourcePlanNewHeader from './Header'
import ResourcePlanNewOptions, {
  ColumnQuickFilterKey,
  ResourcePlanNewRow,
  ResourcePlanNewState,
} from './ResourcePlanNewOptions'
import ResourcePlanNewToolbar from './Toolbar'
import { usePageState } from '../../hooks/usePageState'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { DateTerm, getFinancialYear } from '../../../utils/date'
import store from '../../../store'

type ResourcePlanNewPageState = {
  toolbar: ToolbarToggleValue | undefined
  dateTerm: DateTerm
}

const ResourcePlanNew = (props: PageProps) => {
  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        ResourcePlanNewDetail,
        ResourcePlanNewRow,
        ResourcePlanNewState
      >
    >()

  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortedColumns, setSortedColumns] = useState<string[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const resourcePlanNewOptions = useMemo(() => new ResourcePlanNewOptions(), [])

  const resourcePlanNewDefaultPageState = useMemo(() => {
    const organization = store.getState()?.tenant.organization
    return {
      toolbar: ToolbarToggleValue.DISPLAY,
      dateTerm: {
        startDate: getFinancialYear(organization).formatForApi(),
        endDate: getFinancialYear(organization)
          .addYears(1)
          .addDays(-1)
          .formatForApi(),
      },
    }
  }, [])
  const { toolbar, dateTerm, updatePageState } =
    usePageState<ResourcePlanNewPageState>(
      props.uuid,
      resourcePlanNewDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const onChangeToolbar = useCallback(
    (toolbar: ToolbarToggleValue | undefined) => {
      updatePageState({ toolbar, dateTerm })
    },
    []
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const search = useCallback(
    (term: DateTerm) => {
      if (!bulkSheet) return
      resourcePlanNewOptions.onSearch(bulkSheet, term)
    },
    [bulkSheet, resourcePlanNewOptions]
  )

  const onChangeDateTerm = useCallback(
    (dateTerm: DateTerm) => {
      updatePageState({ toolbar, dateTerm })
    },
    [toolbar, updatePageState]
  )

  useEffect(() => {
    if (bulkSheet) {
      search(dateTerm)
    }
  }, [bulkSheet, search, dateTerm])

  return (
    <PageArea>
      <ResourcePlanNewHeader
        toolbar={toolbar}
        onChangeToolbar={onChangeToolbar}
        onClickExport={() => bulkSheet?.openExcelOutputColumnSelectDialog()}
        rowHeight={rowHeight}
        onClickChangeRowHeight={value => bulkSheet?.onChangeHeight(value)}
        isNotice={filteredColumns.length > 0 || sortedColumns.length > 0}
        onSubmit={() => bulkSheet?.onSubmit()}
        submitDisabled={submitDisabled}
        isLoading={isLoading}
        onCancel={() => bulkSheet?.onCancel()}
        dateTerm={dateTerm}
        onChangeDateTerm={onChangeDateTerm}
      />
      <Collapse in={!!toolbar} timeout={100}>
        <ResourcePlanNewToolbar
          toolbar={toolbar}
          onChangeColumnFilter={onChangeColumnFilter}
          filteredColumns={filteredColumns}
          sortedColumns={sortedColumns}
          onDeletedFilterColumn={column =>
            bulkSheet?.resetSpecificColumnFilter(column || '')
          }
        />
      </Collapse>
      <BulkSheetComponent
        {...props}
        options={resourcePlanNewOptions}
        hideHeader={true}
        hideToolbar={true}
        setBulkSheet={ctx => setBulkSheet(ctx)}
        setFilteredColumns={columns => setFilteredColumns(columns)}
        setSortedColumns={columns => setSortedColumns(columns)}
        setRowHeight={height => setRowHeight(height)}
        setSubmitDisabled={value => setSubmitDisabled(value)}
        setIsLoading={value => setIsLoading(value)}
      />
    </PageArea>
  )
}

export default pageComponent(projectPrivate(ResourcePlanNew))
