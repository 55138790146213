import PageHeader, {
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import SearchWindow from '../../../components/toolbars/ContainerToolBar/SearchWindow'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  onSearch: () => void
  onChangeSearchText: (value: string) => void
  searchText: string
  total: number
  hit: number
}

type Props = OwnProps & SettingSpeedDialProps

const ProjectsHeader = ({
  onClickExport,
  rowHeight,
  onClickChangeRowHeight,
  onChangeToolbar,
  toolbar,
  isNotice,
  onRestoreSearchCondition,
  onSearch,
  onChangeSearchText,
  searchText,
  total,
  hit,
  currentFormat,
  onClickChangeDateFormat,
}: Props) => {
  return (
    <PageHeader>
      <HeaderContent>
        <HeaderGroup>
          <SearchWindow
            reloadHandler={onSearch}
            onChange={onChangeSearchText}
            searchText={searchText}
            total={total}
            hit={hit}
          />
        </HeaderGroup>
        <HeaderDivider />
        <HeaderGroup>
          <IconGroup>
            <SettingSpeedDial
              onClickExport={onClickExport}
              rowHeight={rowHeight}
              onClickChangeRowHeight={onClickChangeRowHeight}
              onRestoreSearchCondition={onRestoreSearchCondition}
              currentFormat={currentFormat}
              onClickChangeDateFormat={onClickChangeDateFormat}
            />
          </IconGroup>
        </HeaderGroup>
        <ToolbarToggleGroup
          toggleItems={[ToolbarToggleValue.DISPLAY]}
          value={toolbar}
          onChange={onChangeToolbar}
          noticeItem={ToolbarToggleValue.DISPLAY}
          isNotice={isNotice}
        />
      </HeaderContent>
    </PageHeader>
  )
}

export default ProjectsHeader
