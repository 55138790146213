const skyBlue = {
  0: '#F6FCFE', // frequently used
  1: '#E4F5FD',
  2: '#B3E6FC', // frequently used
  3: '#82D7FC',
  4: '#50C6FA',
  5: '#29BAF9',
  7: '#00A0E9', // primary
  8: '#008CD5', // text color
  10: '#005B9F',
}

const blue = {
  0: '#F7FCFF',
  2: '#BBE0FF',
  3: '#8DCDFF',
  4: '#5BB9FF',
  5: '#2FA9FF',
  7: '#008BFD',
  8: '#0079E9', // secondary color
  10: '#0D47B8',
}

const pink = {
  0: '#FCF4F7',
  2: '#F8B6D0',
  3: '#F486B0',
  4: '#F05291',
  5: '#ED1E79', // accent color
  6: '#EA005F',
  7: '#DA005C',
  10: '#89004E',
}

const green = {
  0: '#F2FAF7',
  2: '#B7E2D1',
  3: '#8AD0B3',
  4: '#5EBC97',
  5: '#40AD82',
  6: '#2D9E70',
  7: '#289064',
  8: '#228056',
  10: '#105430',
}

const yellow = {
  0: '#FFFAF2',
  2: '#FDC7A0',
  3: '#E2A77F',
  4: '#C4885B',
  7: '#8A4D1F',
  10: '#5C2307',
}

const monotone = {
  0: '#FFFFFF', // frequently used
  1: '#F7F8FA', // background
  2: '#D8DDE5', // border
  3: '#9FACC3',
  4: '#7B8CAA', // light gray text color
  5: '#5F7399',
  6: '#425B8A',
  7: '#3C5482',
  8: '#334A76',
  9: '#2D4069', // gray text color
  10: '#243050', // default text color
}

const overlay = {
  50: '#9FACC380',
}

export const colorPalette = {
  skyBlue,
  blue,
  pink,
  green,
  yellow,
  monotone,
  overlay,
}

export const isDesaturatedColor = (color: string): boolean => {
  if (!color || color.length < 6) return false
  // Remove hash if it exists
  const hex = color.charAt(0) === '#' ? color.substring(1, 7) : color

  // Convert to RGB values
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Check if the color is desaturated
  return r === g && g === b
}
