import { ValueGetterParams, ValueSetterParams } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { CustomEnumValue } from '../../../../../lib/commons/appFunction'
import { EntitySearchOption } from '../../../../../lib/commons/entitySearch'
import store from '../../../../../store'
import { requireSave } from '../../../../../store/requiredSaveData'
import {
  DateCellEditor,
  EntitySearchCellEditor,
} from '../../../../containers/BulkSheetView/components/cellEditor'
import { EntitySearchCellRenderer } from '../../../../containers/BulkSheetView/components/cellRenderer'
import { ClientSideSelectFilter } from '../../../../containers/BulkSheetView/components/filter'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import { ColumnType } from '../../../../containers/commons/AgGrid'
import { RefinementRow } from '../../dataManager/refinement'
import { RefinementSelectOptionType } from '../../hooks/selectOptions/refinement'
import {
  DEFAULT_COLUMN_GROUP_DEF,
  editableExceptSummaryRow,
  useEstimateColumnGroupDef,
  useEexplanationColumnGroupDef,
  generateCheckboxColumnDef,
  generateCustomEnumColumnDef,
  generateMultiLineTextColumnDef,
  useInformationColumnGroupDef,
  useNameColumnGroupDef,
  getProductivityColumnGroupDef,
  PATH_COLUMN_GROUP_DEF,
  PROGRESS_COLUMN_GROUP_DEF,
  PropsForGetColumnDefs,
  useStoryPointColumnGroupDef,
  CHANGE_LOG_COLUMN_GROUP_DEF,
  useAssignmentColumnGroupDef,
  useTermColumnGroupDef,
} from './common'
import { useMemo } from 'react'

const getRefinementColumnGroupDefs = (
  context:
    | {
        [key: string]: CustomEnumValue[]
      }
    | undefined
): FlagxsColumnDef[] => {
  return [
    {
      externalId: 'refinement.event',
      headerName: intl.formatMessage({ id: 'refinement.event' }),
      children: [
        generateCheckboxColumnDef(
          'refinement.knownRefinement',
          'knownRefinement',
          'refinement.knownRefinement'
        ),
        {
          externalId: 'refinement.originalRefinement',
          field: 'originalRefinement',
          headerName: intl.formatMessage({
            id: 'refinement.originalRefinement',
          }),
          editable: editableExceptSummaryRow,
          valueGetter: (params: ValueGetterParams<RefinementRow>) => {
            const row: RefinementRow | undefined = params.node?.data
            return row?.originalRefinement?.uuid
          },
          valueSetter: (params: ValueSetterParams<RefinementRow>) => {
            if (params.oldValue === params.newValue || !params.data.wbsItem) {
              return false
            }
            params.data.originalRefinement = params.newValue
            params.data.edited = true
            const field = params.colDef.field ?? params.colDef.colId ?? ''
            if (!params.data.editedData) {
              params.data.editedData = { [field]: params.oldValue }
            } else if (!params.data.editedData.hasOwnProperty(field)) {
              params.data.editedData[field] = params.oldValue
            }
            store.dispatch(requireSave())
            return true
          },
          cellRenderer: EntitySearchCellRenderer,
          cellRendererParams: {
            context,
            entity: RefinementSelectOptionType.REFINEMENT,
          },
          cellEditor: EntitySearchCellEditor,
          cellEditorParams: {
            entity: RefinementSelectOptionType.REFINEMENT,
            search: (text: string, data) => {
              if (!context) return
              const options = context[RefinementSelectOptionType.REFINEMENT]
              return options.filter(o => 0 <= o.name?.indexOf(text))
            },
          },
          suppressKeyboardEvent: params =>
            (!params.editing &&
              ['Delete', 'Backspace'].includes(params.event.key)) ||
            (params.editing && params.event.key === 'Enter'),
          floatingFilter: true,
          filter: ClientSideSelectFilter,
          filterParams: {
            valueGetter: params => {
              if (!!params.node.data?.isTotal) {
                return
              }
              const options =
                params.context[RefinementSelectOptionType.REFINEMENT]
              const value = (params.node.data as RefinementRow)
                .originalRefinement?.uuid

              const option = options.find(o => o.uuid === value)
              return option
            },
            getValue: (option: EntitySearchOption) => {
              return option?.uuid
            },
            getLabel: (option: EntitySearchOption) => {
              return option?.name
            },
          },
        },
        generateCustomEnumColumnDef(
          'refinement.detectionPhase',
          'detectionPhase',
          'refinement.detectionPhase',
          RefinementSelectOptionType.DETECTION_PHASE
        ),
        generateCustomEnumColumnDef(
          'refinement.environment',
          'environment',
          'refinement.environment',
          RefinementSelectOptionType.ENVIRONMENT
        ),
        generateCustomEnumColumnDef(
          'refinement.domain',
          'domain',
          'refinement.domain',
          RefinementSelectOptionType.DOMAIN
        ),
        generateCustomEnumColumnDef(
          'refinement.featureType',
          'featureType',
          'refinement.featureType',
          RefinementSelectOptionType.FEATURE_TYPE
        ),
        {
          externalId: 'refinement.feature',
          field: 'feature',
          headerName: intl.formatMessage({ id: 'refinement.feature' }),
          editable: editableExceptSummaryRow,
          floatingFilter: true,
        },
        generateMultiLineTextColumnDef(
          'refinement.defectEvent',
          'defectEvent',
          'refinement.defectEvent'
        ),
        generateMultiLineTextColumnDef(
          'refinement.occurrenceProcedure',
          'occurrenceProcedure',
          'refinement.occurrenceProcedure'
        ),
        {
          externalId: 'refinement.testSpecification',
          field: 'testSpecification',
          headerName: intl.formatMessage({
            id: 'refinement.testSpecification',
          }),
          editable: editableExceptSummaryRow,
          floatingFilter: true,
        },
        {
          externalId: 'refinement.testCase',
          field: 'testCase',
          headerName: intl.formatMessage({ id: 'refinement.testCase' }),
          editable: editableExceptSummaryRow,
          floatingFilter: true,
        },
        generateCustomEnumColumnDef(
          'refinement.importance',
          'importance',
          'refinement.importance',
          RefinementSelectOptionType.IMPORTANCE
        ),
        generateCustomEnumColumnDef(
          'refinement.impact',
          'impact',
          'refinement.impact',
          RefinementSelectOptionType.IMPACT
        ),
        {
          externalId: 'refinement.desiredDeliveryDate',
          field: 'desiredDeliveryDate',
          headerName: intl.formatMessage({
            id: 'refinement.desiredDeliveryDate',
          }),
          width: 125,
          editable: editableExceptSummaryRow,
          cellEditor: DateCellEditor,
          type: [ColumnType.date],
          valueSetter: (params: ValueSetterParams<RefinementRow>) => {
            if (params.oldValue === params.newValue) return false
            params.data.desiredDeliveryDate = params.newValue
            params.data.edited = true
            const field = params.colDef.field ?? params.colDef.colId ?? ''
            if (!params.data.editedData) {
              params.data.editedData = { [field]: params.oldValue }
            } else if (!params.data.editedData.hasOwnProperty(field)) {
              params.data.editedData[field] = params.oldValue
            }
            store.dispatch(requireSave())
            return true
          },
          floatingFilter: editableExceptSummaryRow,
        },
      ],
    } as FlagxsColumnDef,
    {
      externalId: 'refinement.bugAnalysis',
      headerName: intl.formatMessage({ id: 'refinement.bugAnalysis' }),
      children: [
        generateCustomEnumColumnDef(
          'refinement.cause',
          'cause',
          'refinement.cause',
          RefinementSelectOptionType.REFINEMENT_CAUSE
        ),
        generateMultiLineTextColumnDef(
          'refinement.directCause',
          'directCause',
          'refinement.directCause'
        ),
        generateMultiLineTextColumnDef(
          'refinement.rootCause',
          'rootCause',
          'refinement.rootCause'
        ),
        generateCheckboxColumnDef(
          'refinement.internalManagement',
          'internalManagement',
          'refinement.internalManagement'
        ),
        generateCustomEnumColumnDef(
          'refinement.properDetectionPhase',
          'properDetectionPhase',
          'refinement.properDetectionPhase',
          RefinementSelectOptionType.DETECTION_PHASE
        ),
      ],
    } as FlagxsColumnDef,
    {
      externalId: 'refinement.fixContents',
      headerName: intl.formatMessage({ id: 'refinement.fixContents' }),
      children: [
        generateMultiLineTextColumnDef(
          'refinement.interimAction',
          'interimAction',
          'refinement.interimAction'
        ),
        generateMultiLineTextColumnDef(
          'refinement.permanentAction',
          'permanentAction',
          'refinement.permanentAction'
        ),
        {
          externalId: 'refinement.documentsToBeCorrected',
          field: 'documentsToBeCorrected',
          headerName: intl.formatMessage({
            id: 'refinement.documentsToBeCorrected',
          }),
          editable: editableExceptSummaryRow,
          floatingFilter: true,
        },
        generateCheckboxColumnDef(
          'refinement.horizontalDeploymentTarget',
          'horizontalDeploymentTarget',
          'refinement.horizontalDeploymentTarget'
        ),
        generateMultiLineTextColumnDef(
          'refinement.horizontalDeploymentContent',
          'horizontalDeploymentContent',
          'refinement.horizontalDeploymentContent'
        ),
      ],
    } as FlagxsColumnDef,
  ] as FlagxsColumnDef[]
}

export const useRefinementColumnDefs = ({
  projectUuid,
  onClickedStatusCell,
  addRow,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  context,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const informationColumnGroupDef = useInformationColumnGroupDef({
    onClickedStatusCell,
    addRow,
  })
  const nameColumnGroupDef = useNameColumnGroupDef({
    reloadSingleRow,
    submitSingleRow,
    context,
  })
  const explanationColumnGroupDef = useEexplanationColumnGroupDef()
  const assignmentColumnGroupDef = useAssignmentColumnGroupDef(
    projectUuid,
    context
  )
  const storyPointColumnGroupDef = useStoryPointColumnGroupDef()
  const estimateColumnGroupDef = useEstimateColumnGroupDef()
  const termColumnGroupDef = useTermColumnGroupDef()
  const columnDefs = useMemo(
    () => [
      DEFAULT_COLUMN_GROUP_DEF,
      informationColumnGroupDef,
      PATH_COLUMN_GROUP_DEF,
      nameColumnGroupDef,
      explanationColumnGroupDef,
      assignmentColumnGroupDef,
      storyPointColumnGroupDef,
      estimateColumnGroupDef,
      PROGRESS_COLUMN_GROUP_DEF,
      getProductivityColumnGroupDef(onClickedActualHourCell),
      termColumnGroupDef,
      ...getRefinementColumnGroupDefs(context),
      CHANGE_LOG_COLUMN_GROUP_DEF,
    ],
    [
      onClickedActualHourCell,
      context,
      informationColumnGroupDef,
      nameColumnGroupDef,
      explanationColumnGroupDef,
      assignmentColumnGroupDef,
      storyPointColumnGroupDef,
      estimateColumnGroupDef,
      termColumnGroupDef,
    ]
  )
  return columnDefs
}
