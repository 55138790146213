import API, { APIResponse } from '../commons/api'
import { Tree } from '../commons/tree'
import { CustomEnumValue } from '../commons/appFunction'
import { I18n } from '../commons/i18nLabel'

export enum CustomEnumCode {
  TAG = 'TAG',
  WBS_STATUS = 'WBS_STATUS',
  WBS_SUBSTATUS = 'WBS_SUBSTATUS',
  WBS_PRIORITY = 'WBS_PRIORITY',
  WBS_DIFFICULTY = 'WBS_DIFFICULTY',
  WBS_TYPE = 'WBS_TYPE',
  TICKET_TYPE = 'TICKET_TYPE',
  WORKING_HOURS_TYPE = 'WORKING_HOURS_TYPE',
  ACCOUNT_CATEGORY = 'ACCOUNT_CATEGORY',
  RISK_PROBABILITY = 'RISK_PROBABILITY',
  RISK_IMPACT = 'RISK_IMPACT',
  RISK_STRATEGY = 'RISK_STRATEGY',
  RISK_TYPE = 'RISK_TYPE',
  ENVIRONMENT = 'ENVIRONMENT',
  IMPACT = 'IMPACT',
  REFINEMENT_CAUSE = 'REFINEMENT_CAUSE',
  DETECTION_PHASE = 'DETECTION_PHASE',
  DOMAIN = 'DOMAIN',
  FEATURE_TYPE = 'FEATURE_TYPE',
  IMPORTANCE = 'IMPORTANCE',
}

export interface GetCustomEnumValueProps {
  customEnumCode: CustomEnumCode
  groupUuid?: string
  groupUuids?: string[]
  groupKeys?: string[]
}

export interface GetByCodeAndGroupKeysProps {
  customEnumCode: CustomEnumCode
  groupKeys?: string[]
}

export interface GetAllCustomEnumValuesByCodeProps {
  customEnumCode: CustomEnumCode
}

export interface GetMultiCustomEnumValuesProps {
  customEnumCode: CustomEnumCode
  groupUuids?: string[]
}

export interface CustomEnumValueInput {
  customEnumCode: string
  uuid?: string // 更新時のみ
  lockVersion?: number // 更新時のみ
  groupUuid?: string
  value?: string
  prevSiblingUuid?: string
  name: string
  validFrom?: string
  validTo?: string
  backgroundColor?: string
  foregroundColor?: string
}

export interface CustomEnumValueDetail extends Tree<CustomEnumValueDetail> {
  value?: string
  prevSiblingUuid?: string
  name: string
  nameI18n: I18n
  validFrom: string
  validTo: string
  backgroundColor?: string
  foregroundColor?: string
  iconUrl?: string
}

export interface CustomEnumValueBatchInput {
  added: CustomEnumValueInput[]
  edited: CustomEnumValueInput[]
  deleted: {
    customEnumCode: CustomEnumCode
    uuid: string
    lockVersion: number
  }[]
}

export async function getCustomEnumValues({
  customEnumCode,
  groupUuid,
}: GetCustomEnumValueProps): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/custom_enums/values', {
    customEnumCode,
    groupKeys: [groupUuid],
  })
}

export async function getByCodeAndGroupKeys({
  customEnumCode,
  groupKeys,
}: GetByCodeAndGroupKeysProps): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/custom_enums/values', {
    customEnumCode,
    groupKeys,
  })
}

export async function getAllCustomEnumValuesByCode({
  customEnumCode,
}: GetAllCustomEnumValuesByCodeProps): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/custom_enums/all_values', {
    customEnumCode,
  })
}

export async function getMultiCustomEnumValues({
  customEnumCode,
  groupUuids,
}: GetMultiCustomEnumValuesProps): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/custom_enums/multi_values', {
    customEnumCode,
    groupKeys: groupUuids,
  })
}

export async function updateCustomEnumValues(
  requestBody: CustomEnumValueBatchInput
): Promise<APIResponse> {
  return API.functional.request(
    'POST',
    '/api/v1/custom_enums/values/batch',
    requestBody
  )
}

export const getCustomEnumName = (
  value: string,
  customEnums: CustomEnumValue[]
) => {
  const customEnum = customEnums.find(customEnum => customEnum.value === value)
  return customEnum ? customEnum.name : ''
}

export const getCustomEnumValue = (
  name: string,
  customEnums: CustomEnumValue[]
) => {
  const normalized = name ? name.trim() : ''
  const customEnum = customEnums.find(
    customEnum => customEnum.name === normalized
  )
  return customEnum ? customEnum.value : ''
}
