import { TreeRow } from '../model'

export interface TreeSource<T extends TreeSource<T>> {
  uuid: string
  children: T[]
}

export class AgGridTreeHelper {
  public static convert<T extends TreeSource<T>, D extends TreeRow>(
    source: T,
    map: (s: T) => D,
    treeValue?: string[]
  ): D[] {
    const root = map(source)
    root.treeValue = [...(treeValue ?? []), root.uuid]
    let data = [root]
    source.children?.forEach(c => {
      const res = AgGridTreeHelper.convert(c, map, root.treeValue)
      data = [...data, ...res]
    })
    return data
  }
}

export const getParentUuid = <R extends TreeRow>(
  row: R
): string | undefined => {
  const treeValue = row.treeValue || []
  if (treeValue.length < 2) return undefined
  return treeValue[treeValue.length - 2]
}

export const isDescendantOf = <R extends TreeRow>(
  row: R,
  potentialAncestor: R
) => {
  const treeValue = row.treeValue || [row.uuid]
  return treeValue
    .slice(0, treeValue.length - 1)
    .includes(potentialAncestor.uuid)
}

export const getSiblings = <R extends TreeRow>(
  rows: R[],
  parentUuid: string | undefined
): R[] => {
  if (!parentUuid) {
    return rows.filter(v => v.treeValue.length <= 1)
  }
  return rows.filter(d => {
    const treeValue = d.treeValue || []
    // If treeValue has length 2, it means the row has no parent.
    if (treeValue.length < 2) {
      return false
    }
    return treeValue[treeValue.length - 2] === parentUuid
  })
}

export const getSiblingUuids = <R extends TreeRow>(
  rows: R[],
  parentUuid: string | undefined
): string[] => {
  return getSiblings(rows, parentUuid).map(row => row.uuid)
}

export const getParent = <R extends TreeRow>(
  rows: R[],
  row: R
): R | undefined => {
  const parentUuid = row.treeValue[row.treeValue.length - 2]
  if (!parentUuid) return undefined
  return rows.find(r => r.uuid === parentUuid)
}

export const getPrevSiblings = <R extends TreeRow>(rows: R[], row: R): R[] => {
  const parentUuid = getParent(rows, row)?.uuid
  const siblings = parentUuid
    ? rows.filter(r => r.treeValue[r.treeValue.length - 2] === parentUuid)
    : rows.filter(r => r.treeValue.length === 1) // Top level rows
  const index = siblings.findIndex(v => v.uuid === row.uuid)
  return siblings.filter((r, i) => r.uuid !== row.uuid && i < index)
}

export const getChildCount = <R extends TreeRow>(rows: R[], row: R): number => {
  const parentUuid = row.uuid
  return rows.filter(
    r =>
      r.treeValue.length >= 2 && // Exclude root node.
      r.treeValue[r.treeValue.length - 2] === parentUuid
  ).length
}

export const getChildren = <R extends TreeRow>(rows: R[], row: R): R[] => {
  const parentUuid = row.uuid
  return rows.filter(
    r =>
      r.treeValue.length >= 2 && // Exclude root node.
      r.treeValue[r.treeValue.length - 2] === parentUuid
  )
}

export const getDescendants = <R extends TreeRow>(
  rows: R[],
  rootUuid: string | undefined
): R[] => {
  if (!rootUuid) return rows
  return rows.filter(row => {
    const treeValue = row.treeValue || []
    const rootUuidIndex = treeValue.findIndex(nodeUuid => nodeUuid === rootUuid)
    // The row is independent of root.
    if (rootUuidIndex < 0) return false
    // If root is the last of treeValue, it means the row is the root.
    if (rootUuidIndex === treeValue.length - 1) return false
    return true
  })
}

export const completeTree = <R extends TreeRow>(
  allRows: R[],
  fragmentUuids: string[]
): R[] => {
  return allRows.filter(row =>
    fragmentUuids.some(fragmentUuid =>
      (row.treeValue || [row.uuid]).includes(fragmentUuid)
    )
  )
}

export const filterTopLevelRows = <R extends TreeRow>(rows: R[]): R[] => {
  const sortedByLevel = rows.sort(
    (a, b) => (a.treeValue || []).length - (b.treeValue || []).length
  )
  const topLevelRows: R[] = []
  for (let row of sortedByLevel) {
    const treeValue = row.treeValue || [row.uuid]
    const treeValueExcludeSelf = treeValue.slice(0, treeValue.length - 1)
    const isDescendantOfSome = topLevelRows.some(topLevelRow =>
      treeValueExcludeSelf.includes(topLevelRow.uuid)
    )
    if (isDescendantOfSome) continue
    topLevelRows.push(row)
  }
  return topLevelRows
}
