import { useCallback } from 'react'
import { Column, ColumnApi } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../../../components/dialogs/MultiSelectDialog'

type Props = {
  onClose: () => void
  onSubmit: (colIds: string[]) => void
  columnApi?: ColumnApi | null
}

export const ExcelExportDialog = ({ onClose, onSubmit, columnApi }: Props) => {
  const getSelectionList = useCallback(
    (columnApi?: ColumnApi): MultiSelectDialogSelection[] => {
      return (columnApi?.getColumns() || [])
        .filter(
          (column: Column) =>
            ![
              'uuid',
              'drag',
              'action',
              'deliverableAttachmentSummary',
            ].includes(column.getColId())
        )
        .map((column: Column) => {
          return {
            value: column.getColId(),
            displayName: columnApi?.getDisplayNameForColumn(column, null),
            defaultChecked:
              column.isVisible() ||
              column.getColDef().cellRendererParams?.uiMeta?.tree ||
              [
                'wbsItem.code',
                'wbsItem.ticketType',
                'wbsItem.displayName',
                'ticketList',
              ].includes(column.getColId()),
          }
        }) as MultiSelectDialogSelection[]
    },
    []
  )

  return (
    <MultiSelectDialog
      onClose={onClose}
      onSubmit={(allList, selectedItem) => {
        const selectedColIds = selectedItem.map(col => col.value)
        onSubmit(selectedColIds)
      }}
      dialogTitle={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.title',
      })}
      submitButtonTitle={intl.formatMessage({
        id: 'dialog.exceloutput.submit',
      })}
      allCheckBoxLabel={intl.formatMessage({
        id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
      })}
      getSelectList={() => getSelectionList(columnApi ?? undefined)}
      hideIcon={true}
    />
  )
}
