import _ from 'lodash'
import store, { AllState } from '../../../store'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { PageArea, PageProps } from '..'
import { BulkSheetView } from '../../containers/BulkSheetView'
import Loading from '../../components/process-state-notifications/Loading'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { intl } from '../../../i18n'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DevelopmentEventWbsItemStatusMappingRow } from './developmentEventWbsItemStatusMapping'
import {
  DevelopmentEventLabels,
  DevelopmentEventWbsItemStatusMappingGridOptions,
} from './gridOptions'
import { InputError } from '../../containers/BulkSheetView/lib/validation'
import {
  GetContextMenuItemsParams,
  RowDataUpdatedEvent,
} from 'ag-grid-community'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { useDialog } from './hooks/dialog'
import { addRowMenuItems, deleteRowMenuItems } from './gridOptions/contextMenu'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { getSelectedNode } from '../../containers/BulkSheetView/lib/gridApi'
import { removeRows } from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useDevelopmentEventWbsItemStatusMappingsData } from './hooks/data'
import { useSelector } from 'react-redux'
import { BaseWbsItemType } from '../../../store/project'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../lib/functions/customEnumValue'
import { parse } from '../../../lib/commons/i18nLabel'
import Header from './components/Header'

type WrapperProps = PageProps

export const DevelopmentEventWbsItemMapping = projectPrivate(
  ({ uuid: functionUuid }: WrapperProps) => {
    const { project } = useProjectPrivateContext()
    const [loading, setLoading] = useState<boolean>(true)
    const [initializedSelectOptions, setInitializedSelectOptions] =
      useState<boolean>(false)
    const ref = useRef<HTMLDivElement>(null)

    const { data, setData, refresh, save, deleteRows, hasDuplicatedData } =
      useDevelopmentEventWbsItemStatusMappingsData(project.uuid)

    const gridOptions = useMemo(() => {
      return DevelopmentEventWbsItemStatusMappingGridOptions()
    }, [])

    const fetchStatusOptions = useCallback(async projectUuid => {
      const response = await getCustomEnumValues({
        customEnumCode: CustomEnumCode.WBS_STATUS,
        groupUuid: projectUuid,
      })
      return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
    }, [])

    const fetchSubstatusOptions = useCallback(async projectUuid => {
      const response = await getCustomEnumValues({
        customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
        groupUuid: projectUuid,
      })
      return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
    }, [])
    const baseWbsItemType = useSelector<AllState, BaseWbsItemType>(
      state => state.project.wbsItemTypes
    )
    const ticketListTypes = useSelector<AllState, WbsItemTypeVO[]>(
      state => state.project.ticketListTypes
    )
    const ticketTypes = useSelector<AllState, WbsItemTypeVO[]>(
      state => state.project.ticketTypes
    )
    const fetchSelectColumnOptions = useCallback(
      async projectUuid => {
        const [wbsItemStatus, wbsItemSubstatus] = await Promise.all([
          fetchStatusOptions(projectUuid),
          fetchSubstatusOptions(projectUuid),
        ])
        const wbsItemType = [
          ...baseWbsItemType.getAll(),
          ...ticketListTypes,
          ...ticketTypes,
        ]
        gridOptions.context = {
          ...gridOptions.context,
          wbsItemType,
          wbsItemStatus,
          wbsItemSubstatus,
        }
        setInitializedSelectOptions(true)
      },
      [
        baseWbsItemType,
        fetchStatusOptions,
        fetchSubstatusOptions,
        gridOptions,
        ticketListTypes,
        ticketTypes,
      ]
    )

    useEffect(() => {
      // Initial data fetch
      fetchSelectColumnOptions(project.uuid)
    }, [project.uuid, fetchSelectColumnOptions])

    const submit = useRef<Function>()
    submit.current = save
    const reload = useRef<Function>()
    reload.current = refresh

    const refreshAll = useCallback(async () => {
      if (!gridOptions.api) return
      setLoading(true)
      try {
        const refreshRows = reload.current ?? refresh
        await refreshRows(project.uuid)
      } finally {
        gridOptions.context = {
          ...gridOptions.context,
          errors: new InputError(),
        }
        setLoading(false)
      }
    }, [gridOptions, project.uuid, refresh])

    const onSubmit = useCallback(async () => {
      setLoading(true)
      try {
        gridOptions.api?.stopEditing()
        if (gridOptions.context.errors.hasMessage()) {
          store.dispatch(
            addGlobalMessage({
              type: MessageLevel.WARN,
              title: intl.formatMessage({ id: 'global.warning.businessError' }),
              text: gridOptions.context.errors.toMessage(id => {
                const node = gridOptions.api?.getRowNode(id)
                return !!node
                  ? (
                      data.findIndex(v => v.uuid === node.data.uuid) + 1
                    ).toString()
                  : ''
              }),
            })
          )
          return
        }
        if (hasDuplicatedData()) {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.WARN,
              title: intl.formatMessage({
                id: 'developmentEventWbsItemStatusMapping.validation.message.duplicatedRows',
              }),
            })
          )
          return
        }

        const response = submit.current ? await submit.current() : await save()
        if (!response.hasError && !response.hasWarning) {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.SUCCESS,
              title: intl.formatMessage({ id: 'registration.complete' }),
            })
          )
          await refreshAll()
        }
      } finally {
        setLoading(false)
      }
    }, [
      data,
      functionUuid,
      gridOptions.api,
      gridOptions.context.errors,
      hasDuplicatedData,
      refreshAll,
      save,
    ])

    const hasChanged = useCallback((): boolean => {
      return (prevData.current || data).some(v => v.added || v.edited)
    }, [data])

    const dialog = useDialog()

    const onReload = useCallback(() => {
      if (hasChanged()) {
        dialog.openCancel()
      } else {
        refreshAll()
      }
    }, [dialog, hasChanged, refreshAll])

    const onDeleteRows = useCallback(() => {
      const rows = getSelectedNode(gridOptions.api!).map(
        v => v.data
      ) as DevelopmentEventWbsItemStatusMappingRow[]
      const target = _.uniqBy(rows, 'uuid')
      const newData = removeRows(data, target)
      if (gridOptions.context.errors) {
        target.forEach(v => gridOptions.context.errors.removeErrorsById(v.uuid))
      }
      setData(newData)
      deleteRows(target)
      dialog.closeDeleteConfirmation()
    }, [data, deleteRows, dialog, gridOptions.api, setData])

    const bulkSheetState = useBulkSheetState(functionUuid, project.uuid)

    const prevData = useRef<DevelopmentEventWbsItemStatusMappingRow[]>([])
    prevData.current = data

    const contextMenu = useCallback(
      (params: GetContextMenuItemsParams) => {
        const selectedNodes = getSelectedNode(params.api)
        if (!params.node) return []
        const items = [
          ...addRowMenuItems(params, data, setData, project.uuid),
          ...deleteRowMenuItems(selectedNodes, dialog.openDeleteConfirmation),
        ]
        return items
      },
      [data, dialog.openDeleteConfirmation, project.uuid, setData]
    )

    useEffect(() => {
      if (!initializedSelectOptions || !bulkSheetState.initialized) {
        return
      }
      refreshAll()
    }, [refreshAll, initializedSelectOptions, bulkSheetState.initialized])

    useKeyBind(
      [{ key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true }],
      [onSubmit]
    )

    return (
      <PageArea>
        <Header loading={loading} onSubmit={onSubmit} onReload={onReload} />
        <BulkSheetView
          ref={ref}
          gridOptions={gridOptions}
          rowData={data}
          getContextMenuItems={contextMenu}
          onRowDataUpdated={(
            e: RowDataUpdatedEvent<DevelopmentEventWbsItemStatusMappingRow>
          ) => e.api?.refreshCells({ columns: ['rowNumber'], force: true })}
        />
        <Loading isLoading={loading} elem={ref.current} />
        {dialog.deleteConfirmation && gridOptions.api && (
          <DeleteRowConfirmationDialog
            target={getSelectedNode(gridOptions.api).map(v => {
              const develomentEventLabel = v.data.developmentEvent
                ? DevelopmentEventLabels[v.data.developmentEvent]
                : intl.formatMessage({
                    id: 'developmentEventWbsItemStatusMapping.dialog.deleteRowsConfirmation.message.developmentEvent.undefined',
                  })
              const wbsItemTypeLabel = v.data.wbsItemType
                ? v.data.wbsItemType.getNameWithSuffix()
                : intl.formatMessage({
                    id: 'developmentEventWbsItemStatusMapping.dialog.deleteRowsConfirmation.message.wbsItemType.undefined',
                  })
              return `[${develomentEventLabel}]-[${wbsItemTypeLabel}]`
            })}
            onConfirm={onDeleteRows}
            onClose={dialog.closeDeleteConfirmation}
          />
        )}
        {dialog.cancel && (
          <CancelConfirmDialog
            open={true}
            onConfirm={() => {
              dialog.closeCancel()
              refreshAll()
            }}
            onClose={dialog.closeCancel}
          />
        )}
      </PageArea>
    )
  }
)
