import moment from 'moment'
import 'moment/locale/ja'
import RelativeDate from './RelativeDate'
import VOBase from '../base'

moment.locale(window.navigator.language)

export default class DateVO extends VOBase {
  private readonly value: moment.Moment
  public readonly relativeDate: RelativeDate | undefined

  private readonly FORMAT = 'YYYY/MM/DD'
  private readonly API_FORMAT = 'YYYY-MM-DD'
  private readonly FORMAT_YYYYMMDD_HHmm = 'YYYY/MM/DD HH:mm'
  private readonly FORMAT_MMDD_HHmm = 'MM/DD HH:mm'
  private readonly FORMAT_HHmm = 'HH:mm'

  constructor(_value?: number | string | Date | RelativeDate | DateVO) {
    super()
    if (!_value) {
      this.value = moment().startOf('day')
    } else if (typeof _value === 'object') {
      if (_value instanceof DateVO) {
        this.value = _value.getStartOfDay().value.clone()
        this.relativeDate = _value.relativeDate
      } else if (RelativeDate.of((_value as RelativeDate).value)) {
        this.relativeDate = new RelativeDate((_value as RelativeDate).value)
        this.value = moment(this.relativeDate.toDate()).startOf('day')
      } else {
        const m = moment(_value as Date).startOf('day')
        if (m.isValid()) {
          this.value = m
          return
        }
        throw new Error(
          `Can not create DateVO instance because not supported params(params: ${_value}).`
        )
      }
    } else {
      const m = moment(_value).startOf('day')
      if (m.isValid()) {
        this.value = m
      } else if (RelativeDate.allowText(_value as string)) {
        this.relativeDate = new RelativeDate(_value as string)
        this.value = moment(this.relativeDate.toDate()).startOf('day')
      } else {
        throw new Error(
          `Can not create DateVO instance because not supported params(params: ${_value}).`
        )
      }
    }
  }

  static isSupportedValue(_value: number | string | Date) {
    return (
      _value &&
      (moment(_value).isValid() || RelativeDate.allowText(_value as string))
    )
  }

  static isRelativeDate(_value: string) {
    return RelativeDate.allowText(_value)
  }

  static now(): DateVO {
    return new DateVO()
  }

  static min(list: DateVO[]) {
    return new DateVO(moment.min(list.map(v => moment(v.toDate()))).toDate())
  }

  static max(list: DateVO[]) {
    return new DateVO(moment.max(list.map(v => moment(v.toDate()))).toDate())
  }

  static parse(
    _value?: number | string | Date | RelativeDate | DateVO
  ): DateVO | undefined {
    try {
      return new DateVO(_value)
    } catch (err) {
      console.debug(err)
      // Ignore parse error
      return undefined
    }
  }

  // Getter
  getValue = () => {
    return this.value
  }

  isRelativeDateInstance(): boolean {
    return !!this.relativeDate
  }

  getYear(): number {
    return this.value.get('year')
  }

  getMonth(): number {
    return this.value.get('month') + 1
  }

  getDay(): number {
    return this.value.get('day')
  }

  // Format
  formatForApi(): string | undefined {
    return this.value.format(this.API_FORMAT)
  }

  format = (fmt?: string) => {
    return this.value.format(fmt ?? this.FORMAT)
  }

  formatYYYYMMDDHHmm = () => {
    return this.value.format(this.FORMAT_YYYYMMDD_HHmm)
  }

  formatMMDDHHmm = () => {
    return this.value.format(this.FORMAT_MMDD_HHmm)
  }

  formatHHmm = () => {
    return this.value.format(this.FORMAT_HHmm)
  }

  // Conversion
  serialize = () => this.value.format(this.API_FORMAT)

  toDate(): Date {
    return this.value.toDate()
  }

  toLabel(): string {
    if (!!this.relativeDate) return this.relativeDate.toLabel()
    return this.value.format('YYYY/MM/DD')
  }

  toNumberValue(): number {
    return this.value.toDate().valueOf()
  }

  // Calculation
  getStartOfDay(): DateVO {
    return new DateVO(this.value.clone().startOf('day').toDate())
  }

  getEndOfDay(): DateVO {
    return new DateVO(this.value.clone().endOf('day').toDate())
  }

  getFirstDayOfWeek(): DateVO {
    return new DateVO(this.value.clone().startOf('week').toDate())
  }

  getLastDayOfWeek(): DateVO {
    return new DateVO(this.value.clone().endOf('week').toDate())
  }

  getFirstDayOfMonth(): DateVO {
    return new DateVO(this.value.clone().startOf('month').toDate())
  }

  getLastDayOfMonth(): DateVO {
    return new DateVO(this.value.clone().endOf('month').toDate())
  }

  getFirstDayOfYear(): DateVO {
    return new DateVO(this.value.clone().startOf('year').toDate())
  }

  getLastDayOfYear(): DateVO {
    return new DateVO(this.value.clone().endOf('year').toDate())
  }

  addDays(days: number): DateVO {
    return new DateVO(this.value.clone().add(days, 'days').toDate())
  }

  subtractDays(days: number): DateVO {
    return new DateVO(this.value.clone().subtract(days, 'days').toDate())
  }

  addWeeks(weeks: number): DateVO {
    return new DateVO(this.value.clone().add(weeks, 'weeks').toDate())
  }

  subtractWeeks(weeks: number): DateVO {
    return new DateVO(this.value.clone().subtract(weeks, 'weeks').toDate())
  }

  addMonths(months: number): DateVO {
    return new DateVO(this.value.clone().add(months, 'months').toDate())
  }

  subtractMonths(months: number): DateVO {
    return new DateVO(this.value.clone().subtract(months, 'months').toDate())
  }

  addYears(years: number): DateVO {
    return new DateVO(this.value.clone().add(years, 'year').toDate())
  }

  // Comparison
  isSame(other: DateVO): boolean {
    return this.value.isSame(other.getValue())
  }

  isAfter(other: DateVO): boolean {
    return this.value.isAfter(other.getValue())
  }

  isBefore(other: DateVO): boolean {
    return this.value.isBefore(other.getValue())
  }

  isSameOrAfter(other: DateVO): boolean {
    return this.value.isSameOrAfter(other.getValue())
  }

  isSameOrBefore(other: DateVO): boolean {
    return this.value.isSameOrBefore(other.getValue())
  }

  isBetween(other1: DateVO, other2: DateVO): boolean {
    return this.value.isBetween(other1.getValue(), other2.getValue())
  }

  isToday(): boolean {
    return this.value.format(this.FORMAT) === DateVO.now().format(this.FORMAT)
  }

  isSaturday(): boolean {
    return this.value.isoWeekday() === 6
  }

  isSunday(): boolean {
    return this.value.isoWeekday() === 7
  }

  isThisYear(): boolean {
    return this.value.format('YYYY') === DateVO.now().format('YYYY')
  }

  // Diff
  diff(other: DateVO): number {
    return this.value.diff(other.getValue(), 'days')
  }
  diffMonth(other: DateVO): number {
    return this.value.diff(other.getValue(), 'months')
  }
  diffYear(other: DateVO): number {
    return this.value.diff(other.getValue(), 'years')
  }

  public isEqual(target: VOBase): boolean {
    return this.format() === target.format()
  }
}
