import {
  styled,
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@mui/material'
import { useCallback, MouseEvent } from 'react'
import { colorPalette } from '../../../style/colorPallete'

type ToggleButtonGroupProps = Omit<
  MuiToggleButtonGroupProps,
  'size' | 'exclusive'
>

export const ToggleButtonGroup = styled((props: ToggleButtonGroupProps) => (
  <MuiToggleButtonGroup {...props} size="small" exclusive={true} />
))({
  height: '26px',
  border: `1px solid ${colorPalette.monotone[2]}`,
  backgroundColor: colorPalette.monotone[0],
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    borderRadius: '4px',
    marginTop: '2px',
    marginBottom: '2px',
    fontSize: '12px',
    padding: '1px 5px',
    color: colorPalette.monotone[4],
    '&.Mui-selected': {
      color: colorPalette.monotone[0],
      backgroundColor: colorPalette.skyBlue[7],
      '&:hover': {
        backgroundColor: colorPalette.skyBlue[7],
      },
    },
    '&.Mui-disabled': {
      color: colorPalette.monotone[2],
    },
    '&:not(:first-of-type)': {
      borderRadius: '4px',
    },
    '&:not(:last-of-type)': {
      borderRadius: '4px',
    },
    '&:first-of-type': {
      marginLeft: '3px',
    },
    '&:last-of-type': {
      marginRight: '3px',
    },
  },
})

type ToggleButtonProps = Omit<MuiToggleButtonProps, 'size'>

export const ToggleButton = (props: ToggleButtonProps) => (
  <MuiToggleButton {...props} size="small" />
)

export const useOnChangeToggleButtonGroup = (_onChange: (_: any) => void) => {
  const onChange = useCallback(
    (_: MouseEvent<HTMLElement>, value: any) => {
      if (!value) return
      _onChange(value)
    },
    [_onChange]
  )
  return [onChange]
}
