import { UiStateKey } from '../../../lib/commons/uiStates'
import TicketListOptions, {
  TicketListRowDataSpec,
} from '../TicketList/TicketListOptions'

const REFINEMENT = 'REFINEMENT'

export default class RefinementListOptions extends TicketListOptions {
  constructor() {
    super(REFINEMENT)
  }
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.RefinementListColumnAndFilterState}-${ctx.state.uuid}`
  searchConditionStateKeySuffix = ctx =>
    `${UiStateKey.RefinementListSearchConditionState}-${ctx.state.uuid}`
  rowDataSpec = new TicketListRowDataSpec(REFINEMENT)
}
