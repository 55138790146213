import { useCallback } from 'react'
import { Editor } from '@tiptap/react'
import { StyleType } from '../../../../../utils/markdown'
import StyleButtonBase, {
  StyleButtonBaseProps,
} from '../../common/StyleButtonBase'

interface OwnProps {
  editor: Editor | undefined
  styleType: StyleType
}

type Props = OwnProps & StyleButtonBaseProps

const RichTextStyleButton = (props: Props) => {
  const { disabled, editor, styleType } = props
  const onMouseDown = useCallback(
    event => {
      event.preventDefault()
      if (disabled || !editor) return
      switch (styleType) {
        case StyleType.HEADER_ONE:
          editor.chain().focus().toggleHeading({ level: 1 }).run()
          break
        case StyleType.HEADER_TWO:
          editor.chain().focus().toggleHeading({ level: 2 }).run()
          break
        case StyleType.HEADER_THREE:
          editor.chain().focus().toggleHeading({ level: 3 }).run()
          break
        case StyleType.HEADER_FOUR:
          editor.chain().focus().toggleHeading({ level: 4 }).run()
          break
        case StyleType.HEADER_FIVE:
          editor.chain().focus().toggleHeading({ level: 5 }).run()
          break
        case StyleType.HEADER_SIX:
          editor.chain().focus().toggleHeading({ level: 6 }).run()
          break
        case StyleType.BOLD:
          editor.chain().focus().toggleBold().run()
          break
        case StyleType.ITALIC:
          editor.chain().focus().toggleItalic().run()
          break
        case StyleType.STRIKETHROUGH:
          editor.chain().focus().toggleStrike().run()
          break
        case StyleType.CODE:
          editor.chain().focus().toggleCode().run()
          break
        case StyleType.CODE_BLOCK:
          editor.chain().focus().toggleCodeBlock().run()
          break
        case StyleType.BLOCKQUOTE:
          editor.chain().focus().toggleBlockquote().run()
          break
        case StyleType.UNORDERED_LIST_ITEM:
          editor.chain().focus().toggleBulletList().run()
          break
        case StyleType.ORDERED_LIST_ITEM:
          editor.chain().focus().toggleOrderedList().run()
          break
        case StyleType.HORIZONTAL_RULE:
          if (editor.isActive('horizontalRule')) {
            editor.commands.deleteSelection()
          } else {
            editor.chain().focus().setHorizontalRule().run()
          }
          break
        case StyleType.TASK_LIST:
          editor.chain().focus().toggleTaskList().run()
          break
      }
    },
    [disabled, editor]
  )
  return <StyleButtonBase {...props} onMouseDown={onMouseDown} />
}

export default RichTextStyleButton
