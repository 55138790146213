import API, { APIResponse } from '../commons/api'
import { UserBasic } from './user'

export interface AgreementDetail {
  uuid: string
  lockVersion: number
  version: number
  name: string
  termUrl: string
  validFrom: string
  latestVersion: boolean

  updatedAt: string
  updatedBy: UserBasic
  createdAt: string
  createdBy: UserBasic
}

class Agreement {
  public getAll = (): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/agreements/list', {}, true)
  }

  public getAgreementLogs = (): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/agreements/logs', {}, true)
  }
}

export default new Agreement()
