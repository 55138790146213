import { ColDef } from 'ag-grid-community'
import { WbsItemAdditionalPropertyEntity } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import {
  ClientSideTextFilter,
  ServerSideTextFilter,
  TextFilter,
} from '../../../components/filter'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'
import { ServerSideTextFloatingFilter } from '../../../components/floatingFilter'

export const textColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  setFilterParams(column, wbsItemAdditionalProperty, option)
  return
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = ClientSideTextFilter
      column.floatingFilter = !option.disableFloatingFilter
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideTextFilter
      column.floatingFilter = !option.disableFloatingFilter
      column.floatingFilterComponent = ServerSideTextFloatingFilter
      column.filterParams = {
        fetch: (v: TextFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                operator: v.operator,
                value: v.value,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
