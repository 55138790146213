import numeral from 'numeral'
import {
  FunctionProperty,
  isNumberProperty,
} from '../../../lib/commons/appFunction'
import { parseFullNumberToHalf } from '../../../utils/number'

const format = (val: any, prop: FunctionProperty): any => {
  if (!prop || !prop.displayFormat) {
    return val
  }
  try {
    // Adjust floating number error E.g. Convert 123.449999998 into 123.45
    if (val > 0) {
      val += 0.000001
    } else if (val < 0) {
      val -= 0.000001
    }
    const num = numeral(val)
    if (num.value() === null || num.value === undefined) {
      return val
    }
    return num.format(prop.displayFormat)
  } catch {
    return val
  }
}

const parse = (val: any, prop: FunctionProperty): any => {
  if (!prop) {
    return val
  }
  if (isNumberProperty(prop)) {
    return parserNumber(val)
  }
  return val
}

const parserNumber = (
  value: string | number | null | undefined
): number | undefined => {
  if (value === 0) {
    return 0
  }

  if (typeof value === 'string') {
    value = parseFullNumberToHalf(value)
  }
  const num = Number(value)
  return Number.isNaN(num) ? undefined : num
}

export default {
  format,
  parse,
}
