const SeparatorIcon = () => {
  return (
    <svg
      width="5"
      height="13"
      viewBox="0 0 5 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.132 12.148L3.732 0.472H4.524L0.936 12.148H0.132Z"
        fill="#7B8CAA"
      />
    </svg>
  )
}

export default SeparatorIcon
