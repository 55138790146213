import { useCallback, useState } from 'react'
import { TextFilterOperator } from './index'

export const useTextFilter = (): {
  operator: TextFilterOperator
  setOperator: (_: TextFilterOperator) => void
  value: string
  setValue: (_: string) => void
  isActive: () => boolean
  model: () => { operator: TextFilterOperator; value: string } | undefined
  reset: () => void
} => {
  const [operator, setOperator] = useState<TextFilterOperator>(
    TextFilterOperator.CONTAINS
  )
  const [value, setValue] = useState<string>('')

  const reset = useCallback(() => {
    setOperator(TextFilterOperator.CONTAINS)
    setValue('')
  }, [])

  const isActive = useCallback(
    () =>
      [TextFilterOperator.BLANK, TextFilterOperator.NOT_BLANK].includes(
        operator
      ) || !!value,
    [operator, value]
  )

  const model = useCallback(() => {
    if (isActive()) {
      return {
        operator,
        value,
      }
    }
    return undefined
  }, [operator, value])

  return { operator, setOperator, value, setValue, isActive, model, reset }
}
