import { useCallback, useState } from 'react'
import { intl } from '../../../../../i18n'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { TeamSelectOption } from '../../model/team'
import {
  ComponentDivider,
  ComponentHeader,
  ComponentHeaderEndDiv,
  ComponentTitle,
  ComponentWrapper,
  ProjectReportBreadCrumbs,
  TeamName,
  TeamwiseComponent,
  TeamwiseInnerComponent,
} from '../../components'
import { TeamSelector } from '../../components/TeamSelector'
import { styled } from '@mui/material'
import { EvmValuePanel } from './EvmValuePanel'
import { CpiTimeSeries } from './CpiTimeSeries'
import { AggregateRoot } from '../../hooks/useMasterScheduleParameter'
import { ProjectReportConfig } from '../../model/config'

type ProductivityProps = {
  project: ProjectDetail
} & Pick<
  ProjectReportConfig,
  'aggregateRoot' | 'aggregateTarget' | 'workloadUnit'
>

const ProductivityRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 24px 12px 12px',
  gap: '24px',
})

export const Productivity = ({
  project,
  aggregateRoot,
  aggregateTarget,
  workloadUnit,
}: ProductivityProps) => {
  const [selectedTeam, setSelectedTeam] = useState<TeamSelectOption[]>([])
  const [selectedCpi, setSelectedCpi] = useState<number | undefined>()
  const onCpiSelected = useCallback((cpi: number) => {
    setSelectedCpi(cpi)
  }, [])
  return (
    <ComponentWrapper>
      <ComponentHeader>
        <ComponentTitle>
          {intl.formatMessage({ id: 'productivity' })}
        </ComponentTitle>
      </ComponentHeader>
      <ComponentHeader>
        <ProjectReportBreadCrumbs
          project={project}
          rootUuid={aggregateRoot?.uuid}
          rootWbsItemName={aggregateRoot?.wbsItemName}
          onClick={_ => {
            // TODO: implement.
          }}
        />
        <ComponentHeaderEndDiv>
          <TeamSelector
            projectUuid={project.uuid}
            updateSelection={setSelectedTeam}
            disableTeamUnselected={true}
          />
        </ComponentHeaderEndDiv>
      </ComponentHeader>
      <ProductivityRoot>
        {selectedTeam.map((team, i) => (
          <>
            {i > 0 && <ComponentDivider />}
            <TeamwiseComponent key={i}>
              <TeamName>{team.name}</TeamName>
              <TeamwiseInnerComponent>
                <EvmValuePanel
                  project={project}
                  rootProjectPlanUuid={aggregateRoot?.uuid}
                  teamUuid={team.type === 'TEAM_TOTAL' ? undefined : team.value}
                  selectedCpi={selectedCpi}
                  workloadUnit={workloadUnit}
                  aggregateTarget={aggregateTarget}
                />
                <CpiTimeSeries
                  project={project}
                  root={aggregateRoot}
                  aggregateTarget={aggregateTarget}
                  teamUuid={team.type === 'TEAM_TOTAL' ? undefined : team.value}
                  onCpiSelected={onCpiSelected}
                />
              </TeamwiseInnerComponent>
            </TeamwiseComponent>
          </>
        ))}
      </ProductivityRoot>
    </ComponentWrapper>
  )
}
