import { useCallback, useState } from 'react'
import _ from 'lodash'
import {
  focusRow,
  getSelectedNode,
} from '../../../containers/BulkSheetView/lib/gridApi'
import {
  AdditionalPropertyRow,
  GroupHeaderRow,
  WbsItemAdditionalPropertyRow,
  createNewAdditionalPropertyRow,
  createNewGroupHeaderRow,
  entityToRow,
  getGroupHeaderUuidOfAdditionalProperty,
  isAdditionalPropertyRow,
  isGroupHeaderRow,
} from '../rowModel/wbsItemAdditionalPropertyRow'
import { removeRows } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { GridOptions } from 'ag-grid-community'
import { useWbsItemAdditionalPropertyRepository } from '../../../../services/adaptors/wbsItemAdditionalPropertyRepositoryAdaptor'
import {
  AddedGroupHeaderRequest,
  AddedWbsItemAdditionalPropertyRequest,
  EditedGroupHeaderRequest,
  EditedWbsItemAdditionalPropertyRequest,
  WbsItemAdditionalPropertiesSaveRequest,
} from '../../../../applications/ports/wbsItemAdditionalPropertyRepository'

export const useWbsItemAdditionalPropertyRowData = ({
  projectUuid,
  wbsItemTypes,
  gridOptions,
}: {
  projectUuid: string
  wbsItemTypes: WbsItemTypeVO[]
  gridOptions: GridOptions<WbsItemAdditionalPropertyRow>
}): {
  data: WbsItemAdditionalPropertyRow[]
  setData: (data: WbsItemAdditionalPropertyRow[]) => void
  fetchWbsItemAdditionalProperties: () => Promise<void>
  saveWbsItemAdditionalProperties: () => Promise<void>
  addGroupHeaderRow: (selectedRow: WbsItemAdditionalPropertyRow) => void
  addAdditionalPropertyRow: (selectedRow: WbsItemAdditionalPropertyRow) => void
  deleteRows: () => void
  hasChanged: () => boolean
} => {
  const [data, setData] = useState<WbsItemAdditionalPropertyRow[]>([])
  const [deletedRows, setDeletedRows] = useState<
    WbsItemAdditionalPropertyRow[]
  >([])
  const {
    getByProjectUuid: getWbsItemAdditionalProperties,
    save: _saveWbsItemAdditionalProperties,
  } = useWbsItemAdditionalPropertyRepository()

  const fetchWbsItemAdditionalProperties = useCallback(async () => {
    const wbsItemAdditionalPropertiesWithLayout =
      await getWbsItemAdditionalProperties(projectUuid)
    sortWbsItemTypesOfAdditionalProperties(
      wbsItemAdditionalPropertiesWithLayout,
      wbsItemTypes
    )
    const rows = entityToRow(projectUuid, wbsItemAdditionalPropertiesWithLayout)
    if (rows.length === 0) {
      rows.push(createNewGroupHeaderRow(projectUuid))
    }
    setData(rows)
    setDeletedRows([])
  }, [projectUuid, getWbsItemAdditionalProperties, wbsItemTypes])

  const saveWbsItemAdditionalProperties = useCallback(async () => {
    const request: WbsItemAdditionalPropertiesSaveRequest = {
      projectUuid,
      groupHeaders: {
        projectUuid,
        added: [],
        edited: [],
        deleted: [],
      },
      wbsItemAdditionalProperties: {
        projectUuid,
        added: [],
        edited: [],
        deleted: [],
      },
      layout: {
        projectUuid,
        groupHeaderLayouts: [],
      },
    }

    const groupHeaderRows = data.filter(v =>
      isGroupHeaderRow(v)
    ) as GroupHeaderRow[]
    const additionalPropertyRows = data.filter(v =>
      isAdditionalPropertyRow(v)
    ) as AdditionalPropertyRow[]
    groupHeaderRows.forEach((groupHeaderRow, index) => {
      if (groupHeaderRow.added) {
        request.groupHeaders.added.push(
          addedGroupHeaderRowToSaveRequest(groupHeaderRow)
        )
      } else if (groupHeaderRow.edited) {
        request.groupHeaders.edited.push(
          editedGroupHeaderRowToSaveRequest(groupHeaderRow)
        )
      }
      request.layout.groupHeaderLayouts.push({
        groupHeaderUuid: groupHeaderRow.uuid,
        displayOrder: index + 1,
        propertyLayouts: [],
      })
    })
    additionalPropertyRows.forEach((additionalPropertyRow, index) => {
      if (additionalPropertyRow.added) {
        request.wbsItemAdditionalProperties.added.push(
          addedAdditionalPropertyRowToSaveRequest(additionalPropertyRow)
        )
      } else if (additionalPropertyRow.edited) {
        request.wbsItemAdditionalProperties.edited.push(
          editedAdditionalPropertyRowToSaveRequest(additionalPropertyRow)
        )
      }
      const groupHeaderLayout = request.layout.groupHeaderLayouts.find(
        v =>
          v.groupHeaderUuid ===
          getGroupHeaderUuidOfAdditionalProperty(additionalPropertyRow)
      )
      if (!groupHeaderLayout) return

      groupHeaderLayout.propertyLayouts.push({
        wbsItemAdditionalPropertyUuid: additionalPropertyRow.uuid,
        displayOrder: groupHeaderLayout.propertyLayouts.length + 1,
      })
    })
    deletedRows.forEach(row => {
      if (isGroupHeaderRow(row)) {
        request.groupHeaders.deleted.push({ uuid: row.uuid })
      } else if (isAdditionalPropertyRow(row)) {
        request.wbsItemAdditionalProperties.deleted.push({ uuid: row.uuid })
      }
    })

    await _saveWbsItemAdditionalProperties(request)
  }, [projectUuid, data, deletedRows, _saveWbsItemAdditionalProperties])

  const addGroupHeaderRow = useCallback(
    (selectedRow: WbsItemAdditionalPropertyRow) => {
      if (!gridOptions.api) return
      const newRow = createNewGroupHeaderRow(projectUuid)
      const newData = [...data, newRow]
      setData(newData)
      focusRow(gridOptions.api, newRow.uuid)
    },
    [data, projectUuid]
  )
  const addAdditionalPropertyRow = useCallback(
    (selectedRow: WbsItemAdditionalPropertyRow) => {
      if (!gridOptions.api) return
      let newRow: WbsItemAdditionalPropertyRow
      if (isGroupHeaderRow(selectedRow)) {
        const groupHeaderRow = selectedRow as GroupHeaderRow
        newRow = createNewAdditionalPropertyRow(
          projectUuid,
          groupHeaderRow.uuid
        )
      } else if (isAdditionalPropertyRow(selectedRow)) {
        const additionalPropertyRow = selectedRow as AdditionalPropertyRow
        const groupHeaderUuid = additionalPropertyRow.treeValue[0]
        newRow = createNewAdditionalPropertyRow(projectUuid, groupHeaderUuid)
      } else {
        return
      }
      const newData = [...data, newRow]
      setData(newData)
      focusRow(gridOptions.api, newRow.uuid)
    },
    [data, projectUuid, gridOptions]
  )
  const deleteRows = useCallback(() => {
    const rows = getSelectedNode(gridOptions.api!).map(
      v => v.data
    ) as WbsItemAdditionalPropertyRow[]
    const target = _.uniqBy(rows, 'uuid')
    const newData = removeRows(data, target)
    if (gridOptions.context.errors) {
      target.forEach(v => gridOptions.context.errors.removeErrorsById(v.uuid))
    }
    setDeletedRows([...deletedRows, ...target])
    setData(newData)
  }, [data, gridOptions.api, gridOptions.context.errors, deletedRows])
  const hasChanged = useCallback(() => {
    return data.some(v => v.added || v.edited) || deleteRows.length > 0
  }, [data, deletedRows])

  return {
    data,
    setData,
    fetchWbsItemAdditionalProperties,
    saveWbsItemAdditionalProperties,
    addGroupHeaderRow,
    addAdditionalPropertyRow,
    deleteRows,
    hasChanged,
  }
}

const sortWbsItemTypesOfAdditionalProperties = (
  entity: WbsItemAdditionalPropertyLayoutEntity,
  wbsItemTypes: WbsItemTypeVO[]
) => {
  entity.groupHeaderLayouts.forEach(({ propertyLayouts }) => {
    propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
      if (!wbsItemAdditionalProperty.wbsItemTypes) {
        return
      }
      wbsItemAdditionalProperty.wbsItemTypes =
        wbsItemAdditionalProperty.wbsItemTypes.sort((a, b) => {
          return (
            wbsItemTypes.findIndex(o => o.uuid === a.uuid) -
            wbsItemTypes.findIndex(o => o.uuid === b.uuid)
          )
        })
    })
  })
}
const addedGroupHeaderRowToSaveRequest = (
  row: GroupHeaderRow
): AddedGroupHeaderRequest => {
  return {
    uuid: row.uuid,
    projectUuid: row.projectUuid,
    headerNameI18n: row.headerNameI18n,
  }
}
const editedGroupHeaderRowToSaveRequest = (
  row: GroupHeaderRow
): EditedGroupHeaderRequest => {
  return {
    uuid: row.uuid,
    projectUuid: row.projectUuid,
    headerNameI18n: row.headerNameI18n,
  }
}
const addedAdditionalPropertyRowToSaveRequest = (
  row: AdditionalPropertyRow
): AddedWbsItemAdditionalPropertyRequest => {
  return {
    uuid: row.uuid,
    projectUuid: row.projectUuid,
    wbsItemTypeUuids: row.wbsItemTypes
      ? row.wbsItemTypes.map(v => v.uuid)
      : undefined,
    propertyNameI18n: row.propertyNameI18n,
    propertyType: row.propertyType,
    required: row.required,
    selectOptions: row.selectOptions,
    entitySearchReferenceEntity: row.entitySearchReferenceEntity,
  }
}
const editedAdditionalPropertyRowToSaveRequest = (
  row: AdditionalPropertyRow
): EditedWbsItemAdditionalPropertyRequest => {
  return {
    uuid: row.uuid,
    projectUuid: row.projectUuid,
    wbsItemTypeUuids: row.wbsItemTypes
      ? row.wbsItemTypes.map(v => v.uuid)
      : undefined,
    propertyNameI18n: row.propertyNameI18n,
    propertyType: row.propertyType,
    required: row.required,
    selectOptions: row.selectOptions,
    entitySearchReferenceEntity: row.entitySearchReferenceEntity,
  }
}
