import API, { APIResponse } from '../commons/api'
import EntitySearch from '../commons/entitySearch'
import { Tree } from '../commons/tree'
import { FunctionProperty } from '../commons/appFunction'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

// positions
export interface ResponseOfGetTree {
  data: PositionDetail[]
}

export interface PositionBatchDeltaInput {
  added: PositionInput[]
  edited: PositionDeltaInput[]
  deleted: { uuid: string; lockVersion: number }[]
}

export interface PositionBatchResponse {
  added: { uuid: string; lockVersion: number }[]
  edited: { uuid: string; lockVersion: number }[]
  deleted: { uuid: string }[]
}

export interface PositionDetail extends Tree<PositionDetail> {
  code: string
  displayName: string
  officialName: string
}

export interface PositionInput {
  uuid: string
  lockVersion?: number
  revision?: string
  parentUuid?: string
  prevSiblingUuid?: string
  code?: string
  displayName: string
  officialName?: string
}

export interface PositionDeltaInput {
  uuid: string
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
}

export interface GetPositionsResponse {
  total: number
  hit: number
  data: PositionProps[]
}

export interface PositionProps {
  uuid: string
  displayName: string
  officialName: string
}

class Position extends EntitySearch {
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => undefined, // Do not use the query to get all position.
      (position: PositionProps) => ({
        uuid: position.uuid,
        name: position.displayName,
      }),
      true
    )
  }

  public searchAll = async () => {
    return this.search('')
  }

  entitySearchApi = (props: {}, signal?: AbortSignal): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/positions/list',
      props,
      true,
      signal
    )
  }

  public getAll = async () => {
    return this.searchRequest.switch(signal =>
      API.functional.request(
        'GET',
        '/api/v1/positions/descendant',
        {},
        true,
        signal
      )
    )
  }

  updateBatchDelta(request: PositionBatchDeltaInput): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/positions/delta/batch',
      request
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.searchAll()
    const position = response.json.find(v => v.uuid === uuid)
    return { status: 200, json: this.toResponse(position) }
  }
}

export default new Position()
