import {
  getUiState,
  UiStateKey,
  UiStateScope,
  updateUiState,
} from '../../lib/commons/uiStates'
import { useState, useCallback, useEffect } from 'react'

export const usePageState = <S>(
  functionUuid: string,
  defaultState: S,
  storedStateAdaptor: (storedState: any) => S = s => s
): S & { updatePageState: (updated: Partial<S>) => void } => {
  const [pageState, setPageState] = useState<S>(defaultState)
  const updatePageState = useCallback((updated: Partial<S>) => {
    setPageState(prev => {
      const newPageState = {
        ...prev,
        ...updated,
      }
      updateUiState(newPageState, functionUuid)
      return newPageState
    })
  }, [])

  useEffect(() => {
    const fetchAndSetPageState = async () => {
      const pageState = await getUiState(
        functionUuid,
        UiStateKey.PageState,
        UiStateScope.User
      )
      if (!pageState) return
      setPageState({ ...defaultState, ...storedStateAdaptor(pageState) })
    }
    fetchAndSetPageState()
  }, [])

  return { ...pageState, updatePageState }
}
