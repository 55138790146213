import _ from 'lodash'
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../../containers/commons/AgGrid/lib/contextMenu'
import {
  FinancialStatementAccountsType,
  LedgerAccountsRow,
  LedgerAccountsType,
  createNewLedgerAccountsRow,
} from '../ledgerAccounts'
import { addRowsToLastChild } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { focusRow } from '../../../containers/BulkSheetView/lib/gridApi'
import { AddMultipeLedgerAccountsRowState } from '../hooks/dialogState'
import { getSiblingUuids } from '../../../containers/BulkSheetView/lib/tree'

const canAddGeneralLedgerAccountsRow = (
  ledgerAccountsType: LedgerAccountsType | undefined,
  financialStatementAccountsType: FinancialStatementAccountsType | undefined
) => {
  return (
    LedgerAccountsType.FinancialStatementAccounts === ledgerAccountsType &&
    !!financialStatementAccountsType &&
    ![
      FinancialStatementAccountsType.GrossProfit,
      FinancialStatementAccountsType.OperatingIncome,
    ].includes(financialStatementAccountsType)
  )
}

export const addGeneralLedgerAccountsRowMenuItem = (
  params: GetContextMenuItemsParams,
  data: LedgerAccountsRow[],
  setData: (data: LedgerAccountsRow[]) => void
): MenuItemDef | undefined => {
  const row: LedgerAccountsRow = params.node?.data
  if (
    !canAddGeneralLedgerAccountsRow(
      row?.body?.ledgerAccountsType,
      row?.body?.financialStatementAccountsType
    )
  ) {
    return undefined
  }
  return {
    name: intl.formatMessage({
      id: 'ledgerAccounts.contextMenu.add.generalLedgerAccounts.row',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
    action: () => {
      addRow(LedgerAccountsType.GeneralLedgerAccounts, params, data, setData)
    },
  }
}

export const addGeneralLedgerAccountsRowsMenuItem = (
  params: GetContextMenuItemsParams,
  openAddRowsDialog: (parentUuid: string, type: LedgerAccountsType) => void
): MenuItemDef | undefined => {
  const row: LedgerAccountsRow = params.node?.data
  if (
    !canAddGeneralLedgerAccountsRow(
      row?.body?.ledgerAccountsType,
      row?.body?.financialStatementAccountsType
    )
  ) {
    return undefined
  }

  return {
    name: intl.formatMessage({
      id: 'ledgerAccounts.contextMenu.add.generalLedgerAccounts.rows',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
    action: () => {
      openAddRowsDialog(row.uuid, LedgerAccountsType.GeneralLedgerAccounts)
    },
  }
}

const canAddSubsidiaryAccountsRow = (
  ledgerAccountsType: LedgerAccountsType | undefined
) => {
  return LedgerAccountsType.GeneralLedgerAccounts === ledgerAccountsType
}

export const addSubsidiaryAccountsRowMenuItem = (
  params: GetContextMenuItemsParams,
  data: LedgerAccountsRow[],
  setData: (data: LedgerAccountsRow[]) => void
): MenuItemDef | undefined => {
  const row: LedgerAccountsRow = params.node?.data
  if (!canAddSubsidiaryAccountsRow(row?.body?.ledgerAccountsType)) {
    return undefined
  }

  return {
    name: intl.formatMessage({
      id: 'ledgerAccounts.contextMenu.add.subsidiaryAccounts.row',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
    action: () => {
      addRow(LedgerAccountsType.SubsidiaryAccounts, params, data, setData)
    },
  }
}

export const addSubsidiaryAccountsRowsMenuItem = (
  params: GetContextMenuItemsParams,
  openAddRowsDialog: (parentUuid: string, type: LedgerAccountsType) => void
): MenuItemDef | undefined => {
  const row: LedgerAccountsRow = params.node?.data
  if (!canAddSubsidiaryAccountsRow(row?.body?.ledgerAccountsType)) {
    return undefined
  }

  return {
    name: intl.formatMessage({
      id: 'ledgerAccounts.contextMenu.add.subsidiaryAccounts.rows',
    }),
    icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
    action: () => {
      openAddRowsDialog(row.uuid, LedgerAccountsType.SubsidiaryAccounts)
    },
  }
}

const getMaxDisplayOrder = (
  rows: LedgerAccountsRow[],
  parentUuid: string
): number => {
  const siblingUuids = getSiblingUuids(rows, parentUuid)
  if (_.isEmpty(siblingUuids)) return 0
  const orders = rows
    .filter(row => siblingUuids.includes(row.uuid))
    .map(row => row.body.displayOrder ?? 0)
  return Math.max(...orders)
}

const addRow = (
  type: LedgerAccountsType,
  params: GetContextMenuItemsParams,
  data: LedgerAccountsRow[],
  setData: (data: LedgerAccountsRow[]) => void
) => {
  const row: LedgerAccountsRow = params.node?.data
  const newRow = createNewLedgerAccountsRow(
    type,
    getMaxDisplayOrder(data, row.uuid) + 1
  )
  const newData = addRowsToLastChild(data, [newRow], row.uuid)
  setData(newData)
  params.node?.setExpanded(true)
  focusRow(params.api, newRow.uuid)
}

export const addMultipleRows = (
  addRowCount: number | undefined,
  state: AddMultipeLedgerAccountsRowState,
  closeDialog: () => void,
  data: LedgerAccountsRow[],
  setData: (data: LedgerAccountsRow[]) => void,
  api: GridApi | null | undefined
) => {
  if (!state.type || !state.parentUuid || !api) return

  const maxDisplayOrder = getMaxDisplayOrder(data, state.parentUuid)
  const rows = Array.from({ length: addRowCount ?? 0 }).map((_, index) =>
    createNewLedgerAccountsRow(state.type!, maxDisplayOrder + index + 1)
  )
  const newData = addRowsToLastChild(data, rows, state.parentUuid)
  setData(newData)
  api?.getRowNode(state.parentUuid)?.setExpanded(true)
  closeDialog()
  focusRow(api, rows[0].uuid)
}

export const deleteRowMenuItems = (
  params: GetContextMenuItemsParams,
  openDeleteConfirm: () => void
): MenuItemDef | undefined => {
  const row: LedgerAccountsRow = params.node?.data
  if (
    !row ||
    LedgerAccountsType.FinancialStatementAccounts ===
      row?.body.ledgerAccountsType ||
    !!params.node?.hasChildren()
  ) {
    return undefined
  }
  const selectedNodes = params.api.getSelectedNodes()
  return {
    name: intl.formatMessage(
      { id: 'bulksheet.contextMenu.delete' },
      { count: selectedNodes.length }
    ),
    icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
    action: openDeleteConfirm,
  }
}
