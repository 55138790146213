import React, { useEffect, useState } from 'react'
import { Box, styled } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { ProgressReportViewConfig } from '..'
import { AggregateTargetUnit } from '../../../../lib/functions/wbsProgressLog'
import ExclusiveToggleGroup from '../../../components/editors/toggle/ExclusiveToggleGroup'
import objects from '../../../../utils/objects'
import { FontSize } from '../../../../styles/commonStyles'
import DateSelect, {
  DateSelectType,
} from '../../../components/toolbars/common/DateSelect'
import DateVO from '../../../../vo/DateVO'
import moment from 'moment'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { TimeGrainToggleGroup } from '../../../components/editors/toggle/TimeGrainToggleGroup'
import SelectStartDayOfWeekButton from '../../../components/buttons/SelectStartDayOfWeekButton'
import SelectStartDayOfWeekDialog from '../../../components/dialogs/SelectStartDayOfWeekDialog'

const ItemLabel = styled('span')({
  fontSize: FontSize.SMALL,
})

const Item = styled('div')({
  padding: '0 5px',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
})

const ItemGroup = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const DateSelectDiv = styled('div')({
  width: '100px',
  zIndex: 3,
  alignItems: 'center',
  display: 'flex',
})

export interface Props extends WrappedComponentProps {
  initialConfig: ProgressReportViewConfig
  onChange(newCondition: ProgressReportViewConfig): void
  hideLabel?: boolean
}

const ProgressReportViewConfigurationToolbar = injectIntl(
  ({ intl, onChange, initialConfig, hideLabel = false }: Props) => {
    const [config, setConfig] =
      useState<ProgressReportViewConfig>(initialConfig)
    const [startDayOfWeekAnchorEl, setStartDayOfWeekAnchorEl] =
      useState<Element | null>(null)
    useEffect(() => {
      setConfig(initialConfig)
    }, [initialConfig])
    const onChangeConfig = (key: string, value: any) => {
      const newConfig = { ...config }
      objects.setValue(newConfig, key, value)
      setConfig(newConfig)
      onChange(newConfig)
    }

    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
        }}
      >
        <Item>
          {!hideLabel && (
            <ItemLabel>
              {intl.formatMessage({ id: 'progressReport.viewTime' })}
            </ItemLabel>
          )}
          <ItemGroup>
            <DateSelectDiv>
              <DateSelect
                value={new DateVO(config?.viewTime?.from || moment().valueOf())}
                type={DateSelectType.DATE}
                onChange={value =>
                  onChangeConfig('viewTime.from', moment(value).valueOf())
                }
              />
            </DateSelectDiv>
            <Box sx={{ padding: '5px' }}>〜</Box>
            <DateSelectDiv>
              <DateSelect
                value={new DateVO(config?.viewTime?.to || moment().valueOf())}
                type={DateSelectType.DATE}
                onChange={value =>
                  onChangeConfig('viewTime.to', moment(value).valueOf())
                }
              />
            </DateSelectDiv>
          </ItemGroup>
        </Item>
        <Item sx={{ paddingRight: 0 }}>
          {!hideLabel && (
            <ItemLabel>
              {intl.formatMessage({ id: `progressReport.aggregateTarget` })}
            </ItemLabel>
          )}
          <ItemGroup>
            <ExclusiveToggleGroup
              value={config?.aggregateTargetType}
              options={Object.values(AggregateField).map(v => ({
                label: intl.formatMessage({
                  id: `progressReport.${v}`,
                }),
                value: v,
              }))}
              onChange={v => onChangeConfig('aggregateTargetType', v)}
            />
            <ExclusiveToggleGroup
              value={config?.aggregateTargetUnit}
              options={Object.values(AggregateTargetUnit).map(v => ({
                label: intl.formatMessage({
                  id: `progressReport.${v}`,
                }),
                value: v,
              }))}
              onChange={v => onChangeConfig('aggregateTargetUnit', v)}
              disabled={
                config?.aggregateTargetType === AggregateField.WBS_ITEM_COUNT
              }
            />
          </ItemGroup>
        </Item>
        <Item sx={{ paddingLeft: 0 }}>
          {!hideLabel && (
            <ItemLabel>
              {intl.formatMessage({ id: `progressReport.timeGrain` })}
            </ItemLabel>
          )}
          <ItemGroup>
            <TimeGrainToggleGroup
              value={config?.timeGrain}
              onChange={newValue => onChangeConfig('timeGrain', newValue)}
            />
            <SelectStartDayOfWeekButton
              onClick={event => setStartDayOfWeekAnchorEl(event.currentTarget)}
            />
            <SelectStartDayOfWeekDialog
              popoverAnchorElement={startDayOfWeekAnchorEl}
              startDayOfWeek={config?.startDayOfWeek}
              timeGrain={config?.timeGrain}
              onClose={() => setStartDayOfWeekAnchorEl(null)}
              onChange={newValue => onChangeConfig('startDayOfWeek', newValue)}
            />
          </ItemGroup>
        </Item>
      </Box>
    )
  }
)

export default ProgressReportViewConfigurationToolbar
