import { useCallback, useState } from 'react'
import {
  UserPositionCrudParams,
  UserPositionMappingInput,
  UserPositionRow,
  UserPositionRowBody,
  UserPositionSkeleton,
  UserPositionsCreateInput,
  UserPositionsUpdateInput,
  crudUserPositions,
  fetchMasterPositions,
  fetchUserPositions,
} from '../UserPositions'
import { AgGridTreeHelper } from '../../../containers/BulkSheetView/lib/tree'
import { generateUuid } from '../../../../utils/uuids'
import store from '../../../../store'
import { doNotRequireSave } from '../../../../store/requiredSaveData'

export const useUserPositionsData = () => {
  const [data, setDataInternal] = useState<UserPositionRow[]>([])

  const fetchRecords = useCallback(async () => {
    setDataInternal([])
    const response = await fetchUserPositions()
    const source: UserPositionSkeleton[] = response.json
    const rows = source
      .map(skeleton =>
        AgGridTreeHelper.convert(
          skeleton,
          (s: UserPositionSkeleton): UserPositionRow => {
            return {
              uuid: s.uuid ?? generateUuid(),
              lockVersion: s.lockVersion,
              added: !s.uuid,
              body: new UserPositionRowBody(s),
            } as UserPositionRow
          }
        )
      )
      .flat()
    setDataInternal(rows)
  }, [])

  const refresh = useCallback(async () => {
    await fetchRecords()
    store.dispatch(doNotRequireSave())
  }, [fetchRecords])

  const fetchPositions = useCallback(async () => {
    const response = await fetchMasterPositions()
    return response.json
  }, [])

  const createCrudParams = useCallback((): UserPositionCrudParams => {
    const added: UserPositionsCreateInput[] = data
      .filter(v => !!v.added && !!v.edited)
      .map(v => {
        return {
          uuid: v.uuid,
          userUuid: v.body.user?.uuid ?? '',
          mappings: v.body.mappings.map(sub => {
            return {
              positionUuid: sub.position.uuid,
              validFrom: sub.validFrom,
            } as UserPositionMappingInput
          }),
        } as UserPositionsCreateInput
      })
    const edited: UserPositionsUpdateInput[] = data
      .filter(v => !v.added && !!v.edited)
      .map(v => {
        return {
          uuid: v.uuid,
          lockVersion: v.lockVersion ?? 0,
          mappings: v.body.mappings.map(sub => {
            return {
              positionUuid: sub.position.uuid,
              validFrom: sub.validFrom,
            } as UserPositionMappingInput
          }),
        } as UserPositionsUpdateInput
      })
    return {
      added,
      edited,
      deleted: [],
    }
  }, [data])

  const save = useCallback(async () => {
    const params = createCrudParams()
    const response = await crudUserPositions(params)
    return response
  }, [createCrudParams])

  return {
    data,
    refresh,
    fetchPositions,
    save,
  }
}
