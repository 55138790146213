import { useCallback, useEffect, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'

type StoredProjectNotificationSettingPageState = {
  rowHeight: ROW_HEIGHT
}

export const usePageState = (
  functionUuid
): {
  initialized: boolean
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
} => {
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    restorePageState()
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      rowHeight,
    })
  }, [rowHeight])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const payload: StoredProjectNotificationSettingPageState = JSON.parse(
        stored.value
      )
      payload.rowHeight && setRowHeight(payload.rowHeight)
    } finally {
      setInitialized(true)
    }
  }, [])

  const rememberPageState = useCallback(
    (value: StoredProjectNotificationSettingPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    []
  )

  return {
    initialized,
    rowHeight,
    setRowHeight,
  }
}
