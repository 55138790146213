import { useRef } from 'react'
import { useProjectPlanSelectGridOptions } from './gridOptions'
import { BulkSheetView } from '../../../../../containers/BulkSheetView'
import { useProjectPlanSelectData } from './hooks/data'

type ProjectPlanSelectProps = {
  snapshotUuid: string
  selectedUuids: string[]
  updateSelectedUuid: (uuid: string, selected: boolean) => void
}
export const ProjectPlanSelect = ({
  snapshotUuid,
  selectedUuids,
  updateSelectedUuid,
}: ProjectPlanSelectProps) => {
  const gridOptions = useProjectPlanSelectGridOptions(updateSelectedUuid)
  const ref = useRef<HTMLDivElement>(null)
  const { rows } = useProjectPlanSelectData(snapshotUuid, selectedUuids)
  return <BulkSheetView ref={ref} gridOptions={gridOptions} rowData={rows} />
}
