import React from 'react'
import { InputBase } from '@mui/material'
import { styled } from '@mui/system'
import DatePicker from 'react-datepicker'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import DateTimeRangeVO from '../../../../../../vo/DateTimeRangeVO'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
  display: 'flex',
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
})
const StyledInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
  display: 'flex',
  '& > .react-datepicker__tab-loop': {
    width: 0,
  },
})
const Divider = styled('span')({
  backgroundColor: '#CCCCCC',
  width: '1px',
  margin: '0 3px',
})

interface DateTimeRangeCellProps extends CellPropsBase<DateTimeRangeVO> {}

interface DateTimeRangeCellState extends CellStateBase {}

class DateTimeRangeCell extends DataCellBase<
  DateTimeRangeVO,
  DateTimeRangeCellProps,
  DateTimeRangeCellState
> {
  validate = (value?: DateTimeRangeVO) => {
    return undefined
  }

  onChange = (key: 'startDateTime' | 'endDateTime', selectedValue) => {
    const { value } = this.props
    const dateTimeRange = value ? { ...value.getValue() } : {}
    dateTimeRange[key] = selectedValue
    this.props.onChange(new DateTimeRangeVO(dateTimeRange))
  }

  render() {
    const { value, cellDef } = this.props
    const dateTimeRange = value ? value.getValue() : {}
    return (
      <RootDiv>
        <DatePicker
          selected={dateTimeRange.startDateTime}
          dateFormat={'yyyy/MM/dd HH:mm:ss'}
          onChange={selectedDate =>
            this.onChange('startDateTime', selectedDate)
          }
          placeholderText={cellDef.placeholder || 'YYYY/MM/DD hh:mm:ss'}
          customInput={<StyledInput />}
          popperPlacement="bottom-end"
          disabled={!cellDef.editable}
          showTimeInput={true}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.stopPropagation()
              e.currentTarget.blur()
            }
          }}
        />
        <Divider />
        <DatePicker
          selected={dateTimeRange.endDateTime}
          dateFormat={'yyyy/MM/dd HH:mm:ss'}
          onChange={selectedDate => this.onChange('endDateTime', selectedDate)}
          placeholderText={cellDef.placeholder || 'YYYY/MM/DD hh:mm:ss'}
          customInput={<StyledInput />}
          popperPlacement="bottom-end"
          disabled={!cellDef.editable}
          showTimeInput={true}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.stopPropagation()
              e.currentTarget.blur()
            }
          }}
        />
      </RootDiv>
    )
  }
}

export default DateTimeRangeCell
