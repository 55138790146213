import { TableCell } from '@mui/material'
import { styled } from '@mui/system'
import { colorPalette } from '../../../style/colorPallete'

export type CellProps = {
  colSpan?: number
  placeholder?: string
}

export type InputCellProps<T> = {
  value: T | undefined
  onChange: (value: T | undefined) => void
  disabled?: boolean
}

export type FocusableCellProps = {
  onEnter?: () => void
  onBlur?: () => void
}

export const InputCell = styled(TableCell)({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  '&:last-child': {
    paddingRight: '5px',
  },
})
