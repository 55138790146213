import {
  ClickAwayListener,
  ListItemButton,
  Popper,
  Typography,
} from '@mui/material'
import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { SortConfig, SortItem } from '../../hooks/useSort'
import AscIcon from '../../../../components/icons/AscIcon'
import DescIcon from '../../../../components/icons/DescIcon'
import useAnchorButton from '../Filter/useAnchorButton'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'

export type Props = {
  applySort: (sortConfig: SortConfig) => void
}

export const SortButton = memo(({ applySort }: Props) => {
  const { isOpen, anchorEl, handleClose, toggleOpen } = useAnchorButton()
  const applySortWithClose = useCallback(
    (sortConfig: SortConfig) => {
      applySort(sortConfig)
      handleClose()
    },
    [applySort, handleClose]
  )
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <StyledListItemButton onClick={toggleOpen}>
          <StyledSortLabelTypography variant={'body2'}>
            {intl.formatMessage({ id: 'toolbar.label.sort' })}
          </StyledSortLabelTypography>
          <StyledNavigateNextRoundedIcon isOpen={isOpen} />
        </StyledListItemButton>
        {isOpen && (
          <SortDialog anchorEl={anchorEl} applySort={applySortWithClose} />
        )}
      </div>
    </ClickAwayListener>
  )
})

const StyledSortLabelTypography = styled(Typography)({
  minWidth: 'fit-content',
  marginRight: '0px',
})
const StyledListItemButton = styled(ListItemButton)({
  borderRadius: '4px',
  minWidth: 'fit-content',
  color: colorPalette.monotone[4],
})
const StyledNavigateNextRoundedIcon = styled(NavigateNextRoundedIcon)<{
  isOpen: boolean
}>(props => ({
  marginLeft: '5px',
  color: colorPalette.monotone[4],
  cursor: 'pointer',
  transform: props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
}))

const SortDialog = memo(
  ({
    anchorEl,
    applySort,
  }: {
    anchorEl: Element | undefined
    applySort: (sortConfig: SortConfig) => void
  }) => {
    return (
      <StyledPopper open={true} anchorEl={anchorEl} placement={'bottom-start'}>
        <SortItemButton
          sortItem={'scheduledDate.startDate'}
          applySort={applySort}
        />
        <SortItemButton
          sortItem={'scheduledDate.endDate'}
          applySort={applySort}
        />
        <SortItemButton sortItem={'priority'} applySort={applySort} />
        <SortItemButton sortItem={'responsible.name'} applySort={applySort} />
      </StyledPopper>
    )
  }
)

const StyledPopper = styled(Popper)({
  boxShadow: '0px 4px 16px 0px #7B8CAA80',
  background: colorPalette.monotone[0],
  display: 'flex',
  margin: '10px',
  borderRadius: '4px',
  zIndex: 1300,
  flexDirection: 'column',
  padding: '16px',
  gap: '12px',
})

const SortItemButton = memo(
  ({
    sortItem,
    applySort,
  }: {
    sortItem: SortItem
    applySort: (sortConfig: SortConfig) => void
  }) => {
    const title = intl.formatMessage({ id: `kanban.sort.itemname.${sortItem}` })
    return (
      <ItemContainer>
        <StyledSortLabelTypography>{title}</StyledSortLabelTypography>
        <AscDescContainer>
          <AscDescButton
            onClick={() => applySort({ key: sortItem, isAsc: true })}
          >
            <AscIcon />
          </AscDescButton>
          <AscDescButton
            onClick={() => applySort({ key: sortItem, isAsc: false })}
          >
            <DescIcon />
          </AscDescButton>
        </AscDescContainer>
      </ItemContainer>
    )
  }
)

const ItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  justifyContent: 'space-between',
})
const AscDescContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '4px',
})
const AscDescButton = styled('button')({
  backgroundColor: colorPalette.monotone[0],
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colorPalette.monotone[2],
  },
  padding: '8px',
  borderRadius: '4px',
})
