import React from 'react'
import Avatar from '@mui/material/Avatar'
import { RowNode, ColDef } from 'ag-grid-community'
import objects from '../../../../../../../../utils/objects'
import { ICON_SIZE } from '../../../../../../BulkSheet/const'
import { Box } from '@mui/material'
import { RootDiv } from '../../CellRendererRoot'

// Styles

interface Props {
  value: string
  colDef: ColDef
  node: RowNode
}

interface Status {
  iconShow: boolean
}

class IconCellRenderer extends React.Component<Props, Status> {
  constructor(props: Props) {
    super(props)
    let iconShow = true
    if (
      props.colDef.cellRendererParams.iconShowCheck &&
      props.colDef.cellRendererParams.iconShowCheckField
    ) {
      const checkValue = objects.getValue(
        props.node.data,
        props.colDef.cellRendererParams.iconShowCheckField
      )
      const iconShowCheck = props.colDef.cellRendererParams.iconShowCheck
      iconShow = iconShowCheck(checkValue)
    }
    this.state = {
      iconShow: iconShow,
    }
  }

  render() {
    const { value, colDef, node } = this.props

    if (!value || !this.state.iconShow) {
      return null
    }
    const rendererParams =
      typeof colDef.cellRendererParams === 'function'
        ? colDef.cellRendererParams(this.props)
        : colDef.cellRendererParams

    const label = objects.getValue(node.data, rendererParams.labelField)
    const iconUrl = objects.getValue(node.data, rendererParams.iconUrlField)
    if (rendererParams.iconShow !== undefined && !rendererParams.iconShow) {
      return (
        <RootDiv className={`column-${colDef.field}`}>
          <Box
            component="span"
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {label}
          </Box>
        </RootDiv>
      )
    }
    return (
      <RootDiv className={`column-${colDef.field}`}>
        <Avatar
          sx={{
            backgroundColor: '#DCDCDC',
            height: ICON_SIZE,
            width: ICON_SIZE,
            mr: '10px',
          }}
          src={iconUrl}
          variant="rounded"
        />
        <Box
          component="span"
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {label}
        </Box>
      </RootDiv>
    )
  }
}

export default IconCellRenderer
