import { Tooltip, Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
import { intl } from '../../../../../i18n'
import { UiStateScope } from '../../../../../lib/commons/uiStates'
import { AllState } from '../../../../../store'
import ApartmentIcon from '@mui/icons-material/Apartment'
import GroupIcon from '@mui/icons-material/GroupRounded'
import LockIcon from '@mui/icons-material/LockRounded'

interface Props {
  scope: UiStateScope
}

interface StateProps {
  projectUuid: string | undefined
  projectIconUrl: string | undefined
  tenantIconUrl: string | undefined
}

export const ScopeIcon = ({ scope }: Props) => {
  const { projectUuid, projectIconUrl, tenantIconUrl } = useSelector<
    AllState,
    StateProps
  >(state => ({
    projectUuid: state.project.selected,
    projectIconUrl: state.project.current?.iconUrl,
    tenantIconUrl: state.tenant.organization?.iconUrl,
  }))

  const isCrossProjectScope =
    scope === UiStateScope.CrossProject ||
    (scope === UiStateScope.Tenant && !projectUuid)
  const isProjectScope = scope === UiStateScope.Tenant && !!projectUuid
  const ownIcon =
    (isCrossProjectScope && tenantIconUrl) || (isProjectScope && projectIconUrl)
  const tooltipTitle = isCrossProjectScope
    ? intl.formatMessage({ id: 'template.scope.tenant' })
    : isProjectScope
    ? intl.formatMessage({ id: 'template.scope.project' })
    : intl.formatMessage({ id: 'template.scope.private' })

  return (
    <Tooltip title={tooltipTitle}>
      <Avatar
        variant="square"
        src={ownIcon || undefined}
        sx={{
          width: 24,
          height: 24,
          borderRadius: '4px',
          ...(!ownIcon && { backgroundColor: 'transparent' }),
        }}
      >
        {!!ownIcon ? (
          <></>
        ) : isCrossProjectScope ? (
          <ApartmentIcon color="action" />
        ) : isProjectScope ? (
          <GroupIcon color="action" />
        ) : (
          <LockIcon color="action" />
        )}
      </Avatar>
    </Tooltip>
  )
}
