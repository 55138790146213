import React from 'react'
import './DataCell.scss'
import _ from 'lodash'
import { Portal } from 'react-overlays'
import { styled } from '@mui/system'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import DatePicker from 'react-datepicker'
import MenuItem from '@mui/material/MenuItem'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  FormGroup,
  IconButton,
  InputBase,
  Popper,
} from '@mui/material'
import { CustomEnumValue } from '../../lib/commons/appFunction'
import repositories from './meta/repositories'
import ClearIcon from '@mui/icons-material/ClearRounded'
import DateVO from '../../vo/DateVO'
import TextInput from '../components/editors/input/TextInput'
import Checkbox from '../components/editors/checkbox/Checkbox'
import { BackgroundColor, Color, TextColor } from '../../styles/commonStyles'
import Select from '../components/editors/select/Select'
import DateInput from '../components/editors/input/DateInput'
import { muiTheme } from '../../styles/muiTheme'
import DateInputWithClassVO from '../components/editors/input/DateInput/DateInputWithClassVO'

const theme = muiTheme
export const RowHeaderCell = styled(TableCell)({
  backgroundColor: BackgroundColor.GREY,
  whiteSpace: 'nowrap',
  color: TextColor.BLACK,
  fontSize: 11,
  width: 90,
})

export const InputCell = styled(TableCell)({
  maxWidth: 100,
  width: '100px',
  '&:last-child': {
    paddingRight: '5px',
  },
})

export const StyledTableRow = styled(TableRow)({
  height: '34px',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.background.default,
  },
})

export const StyledDatePicker = styled(DatePicker)({
  width: '180px',
  '@global': {
    '.react-datepicker-popper': {
      zIndex: 10000,
    },
  },
})

export const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    color: Color.MAIN,
    backgroundColor: `transparent !important`,
  },
})

export const StyledClearIconButton = styled(IconButton)({
  padding: '4px',
  color: 'rgba(0, 0, 0, 0.54)',
  left: '4px',
})

export const StyledClearSelectIconButton = styled(IconButton)({
  padding: '4px',
  color: 'rgba(0, 0, 0, 0.54)',
  right: '45px',
  position: 'absolute',
})

interface DateCellProps {
  colSpan?: number
  value?: DateVO
  onChange: (value: DateVO | undefined) => void
  enterHandler?: Function
  isClearable?: boolean
  placeholderText?: string
  disabled?: boolean
  className?: string
}

interface DateCellState {
  editing: boolean
  label: string
}

interface DateTimeCellProps {
  colSpan?: number
  value?: DateVO
  onChange: (value: DateVO | undefined) => void
  enterHandler?: Function
  isClearable?: boolean
  placeholderText?: string
  disabled?: boolean
  open?: boolean
  className?: string
}

interface DateTimeCellState {
  editing: boolean
  label: string
}

const CalendarContainer = ({ children }) => {
  return <Portal container={null}>{children}</Portal>
}

export class DateCell extends React.PureComponent<
  DateCellProps,
  DateCellState
> {
  constructor(props: DateCellProps) {
    super(props)
  }

  render() {
    const {
      colSpan,
      value,
      onChange,
      placeholderText,
      enterHandler,
      isClearable,
      disabled,
    } = this.props
    return (
      <InputCell align="left" colSpan={colSpan}>
        <DateInputWithClassVO
          value={value}
          onChange={onChange}
          placeholder={placeholderText || 'YYYY/MM/DD'}
          enterHandler={() => {
            if (enterHandler) {
              enterHandler()
            }
          }}
          isClearable={isClearable}
          disabled={disabled}
        />
      </InputCell>
    )
  }
}

export class DateTimeCell extends React.PureComponent<
  DateTimeCellProps,
  DateTimeCellState
> {
  datePickerRef = React.createRef<any>()

  constructor(props: DateTimeCellProps) {
    super(props)
    this.state = {
      editing: false,
      label: props.value ? props.value.formatYYYYMMDDHHmm() : '',
    }
  }

  componentDidUpdate(prevProps: DateTimeCellProps) {
    this.setState({
      label: this.props.value ? this.props.value.formatYYYYMMDDHHmm() : '',
    })
  }

  openCalendar = () => {
    this.setState({ editing: true }, () => {
      if (this.datePickerRef.current) {
        this.datePickerRef.current.setOpen(true)
      }
    })
  }

  closeCalendar = () => {
    this.setState({ editing: false }, () => {
      if (this.datePickerRef.current) {
        this.datePickerRef.current.setOpen(false)
      }
    })
  }

  render() {
    const props = this.props
    const placeholderText = props.placeholderText
      ? props.placeholderText
      : 'YYYY/MM/DD HH:mm'
    return (
      <InputCell align="left" colSpan={props.colSpan}>
        {this.state.editing ? (
          <StyledDatePicker
            showTimeInput={true}
            ref={this.datePickerRef}
            autoFocus={true}
            selected={props.value && props.value.toDate && props.value.toDate()}
            dateFormat={'yyyy/MM/dd HH:mm'}
            onChange={date => {
              props.onChange(date ? new DateVO(date) : undefined)
            }}
            placeholderText={placeholderText}
            customInput={<InputBase />}
            popperPlacement="bottom-end"
            onKeyPress={event => {
              if (props.enterHandler && event.key === 'Enter') {
                props.enterHandler()
              }
            }}
            isClearable={props.isClearable}
            disabled={props.disabled}
            open={props.open}
            onChangeRaw={event => {
              const text = event.target.value
              if (DateVO.isRelativeDate(text)) {
                props.onChange(new DateVO(text))
                this.closeCalendar()
              }
            }}
            popperContainer={CalendarContainer}
          />
        ) : (
          <InputBase
            placeholder={placeholderText}
            onFocus={this.openCalendar}
            value={this.state.label}
          />
        )}
      </InputCell>
    )
  }
}

export interface AutocompleteOption {
  uuid: string
  name?: string
}

export const AutoCompleteCell = (props: {
  colSpan?: number
  value: any
  options: AutocompleteOption[]
  referenceEntity: string
  fixedSearchCondition?: any
  orderFunction?: (options: any[]) => any[]
  onChange: (value?: AutocompleteOption | string) => void
  enterHandler?: Function
  disabled?: boolean
  placeholder?: string
}) => {
  const [rendered, setRendered] = React.useState(false)
  const [options, setOptions]: [AutocompleteOption[], any] = React.useState([])
  const search = async () => {
    if (props.disabled) {
      return
    }
    const repository = repositories[props.referenceEntity]
    const entities = await repository.search('', {
      ...(props.fixedSearchCondition || {}),
    })
    const ordered = props.orderFunction
      ? props.orderFunction(entities)
      : entities
    setOptions(ordered)
  }
  React.useEffect(() => {
    if (!rendered) {
      if (_.isEmpty(props.options)) {
        search()
        setRendered(true)
      } else {
        setOptions(props.options)
      }
    }
  }, [rendered])
  React.useEffect(() => {
    if (props.fixedSearchCondition) {
      search()
    }
  }, [props.fixedSearchCondition])
  const valueChanged = (event, value) => {
    props.onChange(value)
  }
  return (
    <InputCell align="left" colSpan={props.colSpan}>
      <Autocomplete
        // [WORKAROUND] Requires "null" instead of "undefined" to hide clear button.
        value={_.isEmpty(props.value) ? null : props.value}
        options={options}
        getOptionLabel={option => option.name || ''}
        size="small"
        onChange={valueChanged}
        isOptionEqualToValue={(
          option: AutocompleteOption,
          value: AutocompleteOption
        ) => {
          return value && option.uuid === value.uuid
        }}
        renderInput={params => (
          <TextInput
            {...params}
            variant="standard"
            fullWidth={true}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            placeholder={props.placeholder}
          />
        )}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        onKeyPress={event => {
          if (props.enterHandler && event.key === 'Enter') {
            props.enterHandler()
          }
        }}
        noOptionsText=""
        disabled={props.disabled}
        autoHighlight={true}
      />
    </InputCell>
  )
}

export const EntitySearchCell = (props: {
  colSpan?: number
  value?: any
  onChange: (value?: AutocompleteOption | string) => void
  onInputChange?: (value?: string) => void
  enterHandler?: Function
  referenceEntity: string
  fixedSearchCondition?: any
  orderFunction?: (options: any[]) => any[]
  disabled?: boolean
  placeholder?: string
  autoHighlight?: boolean
}) => {
  // Execute only once.
  const [rendered, setRendered] = React.useState(false)
  const [options, setOptions]: [AutocompleteOption[], any] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [value, setValue]: [AutocompleteOption | null, any] = React.useState(
    {} as AutocompleteOption
  )
  const convertToAutoComplete = (value: any) => {
    if (
      !_.isEmpty(value) &&
      // If autocomplete is free solo, name is required to detect value is not null
      (!props.onInputChange || value.name || value.displayName)
    ) {
      return {
        uuid: value.uuid,
        name: value.name || value.displayName || '',
      }
    }
    return {}
  }
  React.useEffect(() => {
    if (!rendered) {
      if (!_.isEmpty(props.value)) {
        const value = convertToAutoComplete(props.value)
        setValue(value)
        if (value.uuid) {
          setOptions([value])
        }
      }
      setRendered(true)
    }
  }, [rendered])
  React.useEffect(() => {
    if (rendered) {
      if (!props.value || _.isEmpty(props.value)) {
        setValue(null)
        search('', false)
      } else {
        const value = convertToAutoComplete(props.value)
        setValue(value)
        if (value.uuid) {
          setOptions([value])
        }
      }
    }
  }, [props.value])
  const search = async (value: string, openOption: boolean = true) => {
    if (props.disabled) {
      return
    }
    const repository = repositories[props.referenceEntity]
    const entities = await repository.search(value, {
      ...(props.fixedSearchCondition || {}),
    })
    const ordered = props.orderFunction
      ? props.orderFunction(entities)
      : entities
    setOptions(ordered)
    openOption && setOpen(entities.length > 0)
  }
  const focused = () => {
    if (_.isEmpty(options)) {
      // Fetch all after focus if the value is empty otherwise input changed event will fetch instead.
      search(props.onInputChange ? value?.name || '' : '')
    } else {
      setOpen(options.length > 0)
    }
  }
  const blurred = () => {
    setOpen(false)
    props.onInputChange && props.onInputChange(value?.name)
  }
  const inputSearch = React.useRef(
    _.debounce(value => {
      search(value)
    }, 300)
  ).current
  const inputChanged = async (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      setValue({ name: value })
      inputSearch(value)
    }
  }
  const valueChanged = (event, value, reason) => {
    setValue(value)
    setOpen(false)
    props.onChange(value)
  }
  const keyPressed = event => {
    if (props.enterHandler && event.key === 'Enter') {
      props.enterHandler()
    }
  }
  const opened = event => {
    setOpen(options.length > 0)
  }
  const closed = event => {
    setOpen(false)
  }

  return (
    <InputCell align="left" colSpan={props.colSpan}>
      <Autocomplete
        value={
          // [WORKAROUND] Requires "null" instead of "undefined" to show/hide clear button.
          _.isEmpty(value) ? null : value
        }
        options={options}
        noOptionsText=""
        getOptionLabel={option => option.name || ''}
        filterOptions={options => options}
        open={open}
        size="small"
        disabled={props.disabled}
        autoHighlight={!!props.autoHighlight}
        onFocus={focused}
        onBlur={blurred}
        onInputChange={inputChanged}
        onChange={valueChanged}
        onKeyPress={keyPressed}
        onOpen={opened}
        onClose={closed}
        isOptionEqualToValue={(
          option: AutocompleteOption,
          value: AutocompleteOption
        ) => {
          return value && option.uuid === value.uuid
        }}
        renderInput={params => (
          <TextInput
            {...params}
            variant="standard"
            fullWidth={true}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            placeholder={props.placeholder}
          />
        )}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
      />
    </InputCell>
  )
}

export const SelectCell = (props: {
  colSpan?: number
  value?: any
  options: {
    label: string
    value?: any
  }[]
  onChange: (value: any) => void
  clearable?: boolean
  disabled?: boolean
  valueIsNotObject?: boolean
  MenuProps?: any
}) => {
  const clear = () => {
    props.onChange('')
  }
  const displayClearIcon = () => {
    if (props.valueIsNotObject) {
      return props.clearable && props.value !== ''
    } else {
      return (
        props.clearable && props.value !== undefined && props.value.length > 0
      )
    }
  }
  return (
    <InputCell align="left" colSpan={props.colSpan}>
      <Select
        fullWidth={true}
        value={props.value}
        disabled={props.disabled}
        onChange={e => props.onChange(e.target.value)}
        disableUnderline={true}
        MenuProps={props.MenuProps}
        options={props.options}
      />
      {displayClearIcon() && (
        <StyledClearSelectIconButton onClick={clear}>
          <ClearIcon color="action" />
        </StyledClearSelectIconButton>
      )}
    </InputCell>
  )
}

export const FilterInputCell = (props: {
  colSpan?: number
  value?: any
  onChange?: (value: any) => void
  type: string
  options?: any[]
  enterHandler?: Function
  clearable?: boolean
  disabled?: boolean
  placeholder?: string
  onBlur?: (event) => void
}) => {
  const clear = () => {
    props.onChange && props.onChange('')
  }
  const displayClearIcon = () => {
    return (
      props.clearable && props.value !== undefined && props.value.length > 0
    )
  }
  return (
    <InputCell align="left" colSpan={props.colSpan}>
      <InputBase
        style={{
          width: 'calc(100% - 26px)',
          paddingRight: 0,
        }}
        value={props.value}
        onChange={event => {
          props.onChange &&
            props.onChange(event.target.value ? event.target.value : undefined)
        }}
        type={props.type}
        onKeyPress={event => {
          if (props.enterHandler && event.key === 'Enter') {
            props.enterHandler()
          }
        }}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
      />
      {displayClearIcon() && (
        <StyledClearIconButton onClick={clear}>
          <ClearIcon fontSize="small" />
        </StyledClearIconButton>
      )}
    </InputCell>
  )
}

export const CheckBoxCell = (props: {
  colSpan?: number
  value: string[]
  onChange: (value: any) => void
  options: CustomEnumValue[]
  readOnly?: boolean
  renderOption?: (option: CustomEnumValue) => JSX.Element
}) => {
  const { colSpan, value, onChange, options, readOnly, renderOption } = props
  const onChangeValue = (v: string, checked: boolean) => {
    const newValue = [...value]
    if (newValue.includes(v)) {
      newValue.splice(value.indexOf(v), 1)
    } else {
      newValue.push(v)
    }
    onChange(newValue)
  }
  return (
    <InputCell align="left" colSpan={props.colSpan}>
      <FormGroup
        style={{
          display: 'inline-block',
          overflowY: 'auto',
          width: '100%',
          maxHeight: '84px',
        }}
      >
        {options.map(item => (
          <Checkbox
            className="data-cell__checkbox-cell"
            key={item.name}
            label={
              renderOption ? (
                renderOption(item)
              ) : (
                <span style={{ fontSize: 11, color: TextColor.BLACK }}>
                  {item.name}
                </span>
              )
            }
            value={item.value}
            name={item.name}
            checked={value.some(v => v === item.value)}
            onChange={onChangeValue}
            disabled={readOnly}
            labelClassName="data-cell__checkbox-cell__label"
            color="default"
          />
        ))}
      </FormGroup>
    </InputCell>
  )
}

export const SingleCheckBoxCell = (props: {
  colSpan?: number
  value: boolean
  onChange: (value: any) => void
  readOnly?: boolean
}) => {
  const { colSpan, value, onChange, readOnly } = props
  const onChangeValue = (_, checked) => {
    onChange(checked)
  }
  return (
    <InputCell align="left" colSpan={colSpan}>
      <FormGroup style={{ flexDirection: 'row' }}>
        <Checkbox
          value={value}
          checked={value}
          onChange={onChangeValue}
          disabled={readOnly}
        />
      </FormGroup>
    </InputCell>
  )
}
