import TextField, { TextFieldProps } from '@mui/material/TextField'

interface OwnProps {
  autoFill?: string
}

type Props = OwnProps & TextFieldProps

const TextInput = (props: Props) => {
  const { autoFill, ...textFieldProps } = props
  return <TextField {...textFieldProps} autoComplete={autoFill || 'off'} />
}

export default TextInput
