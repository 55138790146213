import API, { APIResponse } from '../commons/api'
import EntitySearch from '../commons/entitySearch'
import store from '../../store'
import { Tree } from '../commons/tree'
import { TeamProps } from './team'
import { UserDetail } from './user'
import { FunctionProperty } from '../commons/appFunction'
import User from '../../lib/functions/user'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

export type GetProjectMemberResponse = ProjectMemberDetail[]

export enum ProjectMemberStatus {
  TBA = 'TBA',
  ASSIGNED = 'ASSIGNED',
  RELEASED = 'RELEASED',
}

export interface ProjectMemberDetail extends Tree<ProjectMemberDetail> {
  projectUuid: string
  user: UserDetail
  team: TeamProps
  projectAssignedDate: string
  projectReleasedDate: string
}

export interface ProjectMemberInput {
  projectUuid: string
  userUuid: string
  uuid: string
  lockVersion?: number
  projectMemberRoleUuid?: string
  teamUuid?: string
  projectAssignedDate: string
  projectReleasedDate: string
  revision?: string
  prevSiblingUuid?: string
}

export interface ProjectMemberDeltaInput {
  projectUuid: string
  uuid: string
  prevSiblingUuid?: IItemDelta<string>
  projectMemberRoleUuid?: IItemDelta<string>
  teamUuid?: IItemDelta<string>
  projectAssignedDate?: IItemDelta<string>
  projectReleasedDate?: IItemDelta<string>
}

export interface ProjectMemberRole {
  uuid: string
  code: string
  name: string
}

export interface ProjectMemberBatchDeltaInput {
  added: ProjectMemberInput[]
  edited: ProjectMemberDeltaInput[]
  deleted: {
    userUuid: string
    lockVersion: number
  }[]
}

export interface GetProjectMembersProps {
  projectUuid: string
  all?: string
  name?: string
  offset?: number
  limit?: number
}

export interface GetProjectMembersResponse {
  total: number
  hit: number
  data: ProjectMemberProps[]
}

export interface ProjectMemberProps {
  uuid: string
  name: string
  iconUrl: string
  projectUuid?: string
}

export interface GetAssignedMemberFilterOptionsProps {
  projectUuid: string
  rootProjectPlanUuid?: string
}

class ProjectMember extends EntitySearch {
  hasIcon = true
  public search = async (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => {
    const uuid = searchOptions?.projectUuid || store.getState().project.selected
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        projectUuid: uuid,
        all: query,
      }),
      this.toResponse
    )
  }

  public searchAll = async (boundary?: any) => {
    const projectUuid =
      boundary?.projectUuid || store.getState().project.selected
    return this.searchInternal(
      '',
      (query: string) => ({
        projectUuid,
      }),
      this.toResponse
    )
  }

  toResponse = (projectMember: ProjectMemberProps) => ({
    uuid: projectMember.uuid,
    name: projectMember.name,
    iconUrl: projectMember.iconUrl || '',
  })

  entitySearchApi = (
    props: GetProjectMembersProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/members/find',
      props,
      true,
      signal
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const project = store.getState().project
    if (!project || !project.selected) {
      const response = await User.getDetail({ uuid: uuid })
      return {
        status: 200,
        json: User.toResponse(response.json),
      }
    }
    const response = await this.searchAll()
    const projectMember = response.find(member => member.uuid === uuid)
    return {
      status: 200,
      json: projectMember,
    }
  }

  getProjectMembers({
    projectUuid,
  }: GetProjectMembersProps): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/members', {
      projectUuid,
    })
  }

  async getProjectMembersCrossProjects(
    projectUuids: string[]
  ): Promise<ProjectMemberDetail[]> {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/members/cross_projects',
      {
        projectUuids,
      }
    )
    return response.json
  }

  updateBatchDelta(
    request: ProjectMemberBatchDeltaInput
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/members/delta/batch',
      request
    )
  }

  loginToProject(request: { projectUuid: string }): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/members/login',
      request
    )
  }

  getAccountableFilterOptionUuids({
    projectUuid,
    rootProjectPlanUuid,
  }: GetAssignedMemberFilterOptionsProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/members/filter_options/accountable',
      {
        projectUuid,
        rootProjectPlanUuid,
      }
    )
  }

  getResponsibleFilterOptionUuids({
    projectUuid,
    rootProjectPlanUuid,
  }: GetAssignedMemberFilterOptionsProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/members/filter_options/responsible',
      {
        projectUuid,
        rootProjectPlanUuid,
      }
    )
  }

  getAssigneeFilterOptionUuids({
    projectUuid,
    rootProjectPlanUuid,
  }: GetAssignedMemberFilterOptionsProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/members/filter_options/assignee',
      {
        projectUuid,
        rootProjectPlanUuid,
      }
    )
  }

  getWatcherFilterOptionUuids({
    projectUuid,
    rootProjectPlanUuid,
  }: GetAssignedMemberFilterOptionsProps): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/members/filter_options/watcher',
      {
        projectUuid,
        rootProjectPlanUuid,
      }
    )
  }
}

export default new ProjectMember()
