import { aggregatedWorkloadService } from '../AggregatedValueVO/index'
import { TeamEntity } from '../../entity/TeamEntity'
import { AggregatedWorkload } from '../AggregatedValueVO'
import { YearMonthVO } from '../YearMonthVO'

export type ResourceBalanceVO = {
  plannedWorkload: AggregatedWorkload
  availableResource: number
}

export type ResourceBalanceByTeamVO = {
  team: TeamEntity | undefined // undefined means the team is unset.
  balance: ResourceBalanceVO
}

export type MonthlyResourceBalanceVO = {
  yearMonth: YearMonthVO
  balance: ResourceBalanceVO
}

// Constructors.
const newResourceBalanceVO = (
  plannedWorkload: AggregatedWorkload,
  availableResource: number
): ResourceBalanceVO => {
  return {
    plannedWorkload,
    availableResource,
  }
}

const zero = (): ResourceBalanceVO => {
  return {
    plannedWorkload: aggregatedWorkloadService.zero(),
    availableResource: 0,
  }
}

// Calculator functions.
const sum = (balanceList: ResourceBalanceVO[]): ResourceBalanceVO => {
  return {
    plannedWorkload: balanceList
      .map(b => b.plannedWorkload)
      .reduce(
        (sum, curr) => aggregatedWorkloadService.sum(sum, curr),
        aggregatedWorkloadService.zero()
      ),
    availableResource: balanceList
      .map(b => b.availableResource)
      .reduce((sum, curr) => sum + curr, 0),
  }
}

export const resourceBalanceService = {
  sum,
  newResourceBalanceVO,
}
