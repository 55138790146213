import { ICellEditorParams } from 'ag-grid-community'
import {
  FinancialStatementAccountsType,
  LedgerAccountsTree,
} from '../../../LedgerAccounts/ledgerAccounts'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { MenuItem, MenuProps, Select, Typography, styled } from '@mui/material'
import { Color } from '../../../../../styles/commonStyles'
import { moveToNextRow } from '../../../../containers/BulkSheetView/components/cellEditor'

interface CellEditorProps extends ICellEditorParams {
  masters: LedgerAccountsTree[]
}

export const GeneralLedgerCellEditor = forwardRef(
  (props: CellEditorProps, ref) => {
    const { api, colDef, rowIndex, data, column, masters } = props
    const refInput = useRef<HTMLSelectElement>(null)
    const [val, setVal] = useState(props.value ?? '')
    const [options, setOptions] = useState<LedgerAccountsTree[]>([])
    const firstRendered = useRef(true)

    useEffect(() => {
      const root = masters.find(v => v.uuid === data.body?.financialState?.uuid)
      setOptions(root?.children ?? [])
    }, [data.body?.financialState?.uuid, masters])

    useEffect(() => {
      refInput.current?.focus()
    }, [])

    useEffect(() => {
      if (firstRendered.current) {
        firstRendered.current = false
        return
      }
      api.stopEditing()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return val
        },
      }
    })

    return (
      <EditorSelect
        ref={refInput}
        variant="standard"
        value={val}
        disableUnderline={true}
        open={true}
        onChange={e => setVal(e.target.value)}
        onClose={() => setTimeout(() => api.stopEditing(), 100)}
        MenuProps={selectCellMenuProps}
      >
        {options.map((option: LedgerAccountsTree, i: number) => {
          return (
            <EditorSelectMenuItem
              key={`${colDef.field}-${rowIndex}-${i}`}
              value={option.uuid}
              onKeyDown={e =>
                setTimeout(() => {
                  if (e.key === 'Enter') {
                    // Enter: Move to next row
                    moveToNextRow(api, rowIndex, column)
                  } else if (e.key === 'Tab') {
                    // Tab: Move to next cell
                    api.tabToNextCell()
                  }
                }, 100)
              }
            >
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {option.displayName}
              </Typography>
            </EditorSelectMenuItem>
          )
        })}
      </EditorSelect>
    )
  }
)

const EditorSelect = styled(Select)({
  width: '100%',
  height: '100%',
  '& .MuiSelect-select': {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

const EditorSelectMenuItem = styled(MenuItem)(
  (props: { backgroundColor?: string }) => ({
    display: 'flex',
    margin: '2px',
    height: '26px',
    backgroundColor: props.backgroundColor
      ? `${props.backgroundColor}66`
      : 'inherit',
    transparent: '50%',
    borderRadius: '3px',
    '&:focus': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
    '&:hover': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
  })
)

const selectCellMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}

export const SubsidiaryCellEditor = forwardRef(
  (props: CellEditorProps, ref) => {
    const { api, colDef, rowIndex, data, column, masters } = props
    const refInput = useRef<HTMLSelectElement>(null)
    const [val, setVal] = useState(props.value ?? '')
    const [options, setOptions] = useState<LedgerAccountsTree[]>([])
    const firstRendered = useRef(true)

    useEffect(() => {
      const financialState = masters.find(
        v => v.uuid === data.body?.financialState?.uuid
      )
      const generalLedger = financialState?.children.find(
        v => v.uuid === data.body?.generalLedger?.uuid
      )
      setOptions(generalLedger?.children ?? [])
    }, [
      data.body?.financialState?.uuid,
      data.body?.generalLedger?.uuid,
      masters,
    ])

    useEffect(() => {
      refInput.current?.focus()
    }, [])

    useEffect(() => {
      if (firstRendered.current) {
        firstRendered.current = false
        return
      }
      api.stopEditing()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return val
        },
      }
    })

    return (
      <EditorSelect
        ref={refInput}
        variant="standard"
        value={val}
        disableUnderline={true}
        open={true}
        onChange={e => setVal(e.target.value)}
        onClose={() => setTimeout(() => api.stopEditing(), 100)}
        MenuProps={selectCellMenuProps}
      >
        {options.map((option: LedgerAccountsTree, i: number) => {
          return (
            <EditorSelectMenuItem
              key={`${colDef.field}-${rowIndex}-${i}`}
              value={option.uuid}
              onKeyDown={e =>
                setTimeout(() => {
                  if (e.key === 'Enter') {
                    // Enter: Move to next row
                    moveToNextRow(api, rowIndex, column)
                  } else if (e.key === 'Tab') {
                    // Tab: Move to next cell
                    api.tabToNextCell()
                  }
                }, 100)
              }
            >
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {option.displayName}
              </Typography>
            </EditorSelectMenuItem>
          )
        })}
      </EditorSelect>
    )
  }
)

export const FinancialStatementCellEditor = forwardRef(
  (props: CellEditorProps, ref) => {
    const { api, colDef, rowIndex, data, column, masters } = props
    const refInput = useRef<HTMLSelectElement>(null)
    const [val, setVal] = useState(props.value ?? '')
    const [options, setOptions] = useState<LedgerAccountsTree[]>([])
    const firstRendered = useRef(true)

    useEffect(() => {
      const filterOption = masters.filter(
        v =>
          v.financialStatementAccountsType ===
            FinancialStatementAccountsType.CostOfSales ||
          v.financialStatementAccountsType ===
            FinancialStatementAccountsType.SGAExpense
      )
      setOptions(filterOption ?? [])
    }, [masters])

    useEffect(() => {
      refInput.current?.focus()
    }, [])

    useEffect(() => {
      if (firstRendered.current) {
        firstRendered.current = false
        return
      }
      api.stopEditing()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [val])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return val
        },
      }
    })

    return (
      <EditorSelect
        ref={refInput}
        variant="standard"
        value={val}
        disableUnderline={true}
        open={true}
        onChange={e => setVal(e.target.value)}
        onClose={() => setTimeout(() => api.stopEditing(), 100)}
        MenuProps={selectCellMenuProps}
      >
        {options.map((option: LedgerAccountsTree, i: number) => {
          return (
            <EditorSelectMenuItem
              key={`${colDef.field}-${rowIndex}-${i}`}
              value={option.uuid}
              onKeyDown={e =>
                setTimeout(() => {
                  if (e.key === 'Enter') {
                    // Enter: Move to next row
                    moveToNextRow(api, rowIndex, column)
                  } else if (e.key === 'Tab') {
                    // Tab: Move to next cell
                    api.tabToNextCell()
                  }
                }, 100)
              }
            >
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {option.displayName}
              </Typography>
            </EditorSelectMenuItem>
          )
        })}
      </EditorSelect>
    )
  }
)
