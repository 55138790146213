import { useCallback, useMemo } from 'react'
import * as d3 from 'd3'
import {
  calculateDateBucket,
  getBucketRepresentativeGetter,
  getIsBucketPassed,
} from '../../../components/charts/utils/timeSeries'
import {
  DateBucket,
  DateBucketType,
} from '../../../components/charts/model/timeSeries'
import { WeekDay } from '../../../../domain/value-object/DayOfWeek'
import { formatDate, formatMonthDate, formatYearMonth } from '../utils/date'
import { DateTerm } from '../model'

export type DateBucketHandlers = {
  isBucketPassed: (bucket: DateBucket) => boolean
  getBucketRepresentative: (bucket: DateBucket) => Date
  formatBucket: (bucket: DateBucket) => string
  isBucketIncluded: (bucket: DateBucket) => boolean
}
export const useDateBuckets = (
  from: Date,
  to: Date,
  dateBucketType: DateBucketType,
  startDayOfWeek: WeekDay
): DateBucket[] => {
  const dateBuckets = useMemo(
    () =>
      calculateDateBucket(from, to, dateBucketType, startDayOfWeek, {
        from: 1,
        to: 0,
      }),
    [dateBucketType, startDayOfWeek, from, to]
  )
  return dateBuckets
}

export const useDateBucketHandlers = (
  dateBucketType: DateBucketType,
  displayDateTerm: DateTerm
): DateBucketHandlers => {
  const isBucketPassed = useMemo(
    () => getIsBucketPassed(dateBucketType),
    [dateBucketType]
  )
  const getBucketRepresentative = useMemo(
    () => getBucketRepresentativeGetter(dateBucketType),
    [dateBucketType]
  )

  const formatBucket = useCallback(
    (bucket: DateBucket) => {
      switch (dateBucketType) {
        case 'DAY':
          return formatDate(getBucketRepresentative(bucket))
        case 'WEEK':
          return `${formatDate(bucket.from)} - ${formatDate(
            d3.timeDay.offset(bucket.to, -1)
          )}`
        case 'MONTH':
          return `${formatYearMonth(d3.timeDay.ceil(bucket.to))}`
      }
    },
    [dateBucketType, getBucketRepresentative]
  )
  const isBucketIncluded = useCallback(
    (bucket: DateBucket) => {
      const representative = getBucketRepresentative(bucket)
      return (
        displayDateTerm.from <= representative &&
        representative <= displayDateTerm.to
      )
    },
    [displayDateTerm.from, displayDateTerm.to, getBucketRepresentative]
  )
  return {
    isBucketPassed,
    getBucketRepresentative,
    formatBucket,
    isBucketIncluded,
  }
}
