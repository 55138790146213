import API from '../../../lib/commons/api'

export const useDevelopmentExternalServiceStorageService = () => {
  const setupGitHubConnect = async (
    projectUuid: string,
    secret: string
  ): Promise<void> => {
    const request = {
      projectUuid,
      secret,
    }
    API.functional.request('POST', `/api/v1/development/github/setup`, request)
  }

  const isGithubConnected = async (projectUuid: string): Promise<boolean> => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/development/github/connected',
      {
        projectUuid,
      }
    )
    return Boolean(response.json.isConnected)
  }

  return {
    setupGitHubConnect,
    isGithubConnected,
  }
}
