import React from 'react'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'

const Button = styled(BaseButton)({
  background: BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
  color: TextColor.BLUE,
  width: 'auto',
  paddingLeft: '2px',
  paddingRight: '2px',
  marginTop: '10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '300px',
  minWidth: '175px',
}) as React.FC<BaseButtonProps>

type Props = BaseButtonProps & WrappedComponentProps

const AddAttachmentButton = (props: Props) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<AddCircleOutlineIcon color="primary" fontSize="small" />}
    >
      {props.intl.formatMessage({ id: 'attachment.add' })}
    </Button>
  )
}

export default injectIntl(AddAttachmentButton)
