import { useCallback, useState } from 'react'
import { CommentTextFilterOperator } from './index'

export const useCommentTextFilter = (): {
  operator: CommentTextFilterOperator
  setOperator: (_: CommentTextFilterOperator) => void
  value: string
  setValue: (_: string) => void
  reset: () => void
  isActive: () => boolean
  model: () =>
    | { operator: CommentTextFilterOperator; value: string }
    | undefined
} => {
  const [operator, setOperator] = useState<CommentTextFilterOperator>(
    CommentTextFilterOperator.CONTAINS
  )
  const [value, setValue] = useState<string>('')

  const reset = useCallback(() => {
    setOperator(CommentTextFilterOperator.CONTAINS)
    setValue('')
  }, [])

  const isActive = useCallback(() => !!value, [value])

  const model = useCallback(() => {
    if (isActive()) {
      return { operator, value }
    }
    return undefined
  }, [value, operator])

  return { operator, setOperator, value, setValue, reset, isActive, model }
}
