import { useCallback, useMemo } from 'react'
import AggregateInformation, {
  AggregatedValueGroup,
  AggregateValueType,
} from '.'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { AggregateFieldToggleGroup } from '../../components/toggleGroups'
import { RowNode } from 'ag-grid-community'
import { WorkloadUnit } from '../../../lib/functions/workload'
import WbsItemRowApi from '../commons/AgGrid/lib/wbsItem'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

type MyTasksAggregateInformationProps = {
  rowNodes?: RowNode[]
  workloadUnit: WorkloadUnit
  aggregateTargetType: AggregateField
  onChangeAggregateTargetType: (value: AggregateField) => void
}

const MyTasksAggregateInformation = ({
  rowNodes,
  workloadUnit: unit,
  aggregateTargetType,
  onChangeAggregateTargetType,
}: MyTasksAggregateInformationProps) => {
  const { convertWorkloadFromHourToSelectedUnit } = useWorkloadUnit(unit)
  const getWorkload = useCallback(
    (nodes: RowNode[]): number => {
      const total = nodes
        .map(
          node =>
            WbsItemRowApi.getEstimatedWorkload(
              node.data.wbsItem,
              WorkloadUnit.HOUR
            ) || 0
        )
        .reduce((sum, curr) => (sum += curr), 0)
      return convertWorkloadFromHourToSelectedUnit(total)
    },
    [unit, convertWorkloadFromHourToSelectedUnit]
  )

  const getValue = useCallback(
    (nodes: RowNode[]): number => {
      return aggregateTargetType === AggregateField.WBS_ITEM_WORKLOAD
        ? getWorkload(nodes)
        : nodes.length
    },
    [rowNodes, aggregateTargetType, getWorkload]
  )

  const aggregate = useCallback((): AggregatedValueGroup => {
    if (!rowNodes) return []
    const budgetAtCompletion = getValue(rowNodes)
    const plannedValue = getValue(
      rowNodes.filter(node =>
        WbsItemRowApi.isScheduledToBeDone(node.data.wbsItem)
      )
    )
    const delayed = getValue(
      rowNodes.filter(node => WbsItemRowApi.isDelayed(node.data.wbsItem))
    )
    const estimatedProgressRate = plannedValue / budgetAtCompletion

    return [
      [
        {
          type: AggregateValueType.ESTIMATED_PROGRESS_RATE,
          value: estimatedProgressRate,
        },
      ],
      [
        {
          type: AggregateValueType.BUDGET_AT_COMPLETION,
          value: budgetAtCompletion,
        },
        {
          type: AggregateValueType.PLANNED_VALUE,
          value: plannedValue,
        },
        {
          type: AggregateValueType.DELAYED,
          value: delayed,
        },
      ],
    ]
  }, [rowNodes, getValue])

  const aggregatedValueGroups = useMemo(() => {
    return aggregate()
  }, [aggregate])

  return (
    <>
      <AggregateInformation
        aggregatedValueGroups={aggregatedValueGroups}
        aggregateType={aggregateTargetType}
        wbsItemType={WbsItemType.TASK}
      />
      <AggregateFieldToggleGroup
        value={aggregateTargetType}
        onChange={onChangeAggregateTargetType}
      />
    </>
  )
}

export default MyTasksAggregateInformation
