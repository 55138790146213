import { intl } from '../../../../i18n'
import ColumnFilterButton from '../../../components/buttons/ColumnFilterButtonGroup/ColumnFilterButton'
import ToolbarFilterButtonGroup, {
  ToolbarFilterGroupProps,
} from '../../../components/buttons/ToolbarFilterButtonGroup'
import { HeaderGroup } from '../../../components/headers/HeaderBar/PageHeader'
import { Toolbar } from '../../../components/toolbars/Toolbar'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ColumnQuickFilterKey } from '../IdProvidersOptions'

interface OwnProps {
  toolbar: ToolbarToggleValue | undefined
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

type Props = OwnProps & ToolbarFilterGroupProps

const IdProvidersToolbar = ({
  toolbar,
  filteredColumns,
  sortedColumns,
  onDeletedFilterColumn,
  onChangeColumnFilter,
}: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === toolbar && (
        <>
          <HeaderGroup>
            <ToolbarFilterButtonGroup
              filteredColumns={filteredColumns}
              sortedColumns={sortedColumns}
              onDeletedFilterColumn={onDeletedFilterColumn}
            />
          </HeaderGroup>
          <HeaderGroup>
            <ColumnFilterButton
              title={intl.formatMessage({
                id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
              })}
              onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)}
            />
            <ColumnFilterButton
              title={intl.formatMessage({
                id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
              })}
              onClick={() => onChangeColumnFilter(ColumnQuickFilterKey.RESTORE)}
            />
          </HeaderGroup>
        </>
      )}
    </Toolbar>
  )
}

export default IdProvidersToolbar
