import Auth from '../../../lib/commons/auth'
import {
  ConnectedService,
  RegistrationStatus,
  UserDetail,
  isEmailChangable,
} from '../../../lib/functions/user'
import store from '../../../store'
import DateRangeVO from '../../../vo/DateRangeVO'
import TextVO from '../../../vo/TextVO'
import { SingleSheetConverterSpec } from '../../containers/meta/DataConverter/SingleSheetDataConverter'
import {
  SingleSheetContext,
  SingleSheetOptions,
} from '../../containers/SingleSheet'
import { TableData } from '../../containers/SingleSheet/DataTable'
import DateTimeRangeVO from '../../../vo/DateTimeRangeVO'
import { SubmitType } from '../../containers/meta/ViewMeta'
import { AttemptChangeUserEmailButton } from './AttemptChangeUserEmailButton'
import {
  ToolBarItemPosition,
  generateToolBarItemKey,
} from '../../components/toolbars/ContainerToolBar'

class UserConverterSpec extends SingleSheetConverterSpec {
  convertDeserialized(src: any, dest: any) {
    return {
      ...dest,
      hireLeaveDate: new DateRangeVO({
        startDate: src.hireDate,
        endDate: src.leaveDate,
      }),
      expirationDate: new DateTimeRangeVO({
        startDateTime: src.validFrom,
        endDateTime: src.validTo,
      }),
    }
  }
  convertSerialized({
    dest,
    initialData,
    delta,
  }: {
    src: any
    dest: any
    initialData?: any
    submitType: SubmitType
    delta?: any
  }): {
    before?: any
    after: any
    delta?: any
  } {
    return {
      before: initialData,
      after: dest,
      delta: delta
        ? {
            uuid: dest.uuid,
            ...delta,
          }
        : undefined,
    }
  }
  convertInitialData(dest: any, auxiliaries: any) {
    return dest
  }
}

class UserData extends TableData {
  code?: TextVO
  name?: TextVO
  getCode() {
    return this.code
  }
  getName() {
    return this.name
  }
}

export default class User extends SingleSheetOptions<UserData> {
  converterSpec = new UserConverterSpec()
  registrationStatus: RegistrationStatus = RegistrationStatus.PROVISIONAL

  afterGet = (data: UserDetail) => {
    const currentUser = store.getState().user.user
    if (currentUser && currentUser.uuid === data.uuid) {
      const tenant = Auth.getCurrentTenant()!
      tenant.refreshUser()
    }
    this.registrationStatus = data.registrationStatus
    return data
  }

  canDelete = (data: UserDetail) => !data.lastLoginAt

  toolBarItems = (ctx: SingleSheetContext<UserData>) => {
    const userUuid = ctx.state.data.uuid
    if (!userUuid) return []
    const registrationStatus = ctx.state.data['registrationStatus']
      .value as RegistrationStatus
    if (!isEmailChangable(registrationStatus)) return []
    return [
      <div key={generateToolBarItemKey(1, ToolBarItemPosition.END)}>
        <AttemptChangeUserEmailButton
          uuid={userUuid}
          key={'attempt-change-user-email-button'}
        />
      </div>,
    ]
  }
}
