import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'

export const HeaderIconButton = styled('button')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  borderRadius: '4px',
  backgroundColor: colorPalette.monotone[0],
  width: '24px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
})
