import { IconButton } from '@mui/material'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import { open } from '../../../router'
import { intl } from '../../../../i18n'
import { Color } from '../../../../styles/commonStyles'
import { BootstrapTooltip } from '../../../components/tooltips/Tooltip/BootStrapToopTip'

const ActualWorkingHoursIcon = () => {
  return (
    <BootstrapTooltip
      arrow={true}
      title={intl.formatMessage({
        id: 'menu.actualWorkingHours',
      })}
    >
      <IconButton
        onClick={event => open('/actualWorkingHours', event)}
        sx={{ padding: '6px', marginRight: '10px' }}
      >
        <PunchClockIcon style={{ fontSize: 20, color: Color.MONOTONE }} />
      </IconButton>
    </BootstrapTooltip>
  )
}

export default ActualWorkingHoursIcon
