import { useState } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../../store'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import BaseButton from '../../../components/buttons/BaseButton'
import ReloadButton from '../../../components/buttons/ReloadButton'
import SubmitButton from '../../../components/buttons/SubmitButton'
import CancelConfirmDialog from '../../../components/dialogs/CancelConfirmDialog'
import PageHeader, {
  Header,
  HeaderContent,
  HeaderDivider,
  HeaderGroup,
  IconGroup,
} from '../../../components/headers/HeaderBar/PageHeader'
import SettingSpeedDial, {
  SettingSpeedDialProps,
} from '../../../components/headers/HeaderBar/SettingSpeedDial'
import SearchWindow from '../../../components/toolbars/ContainerToolBar/SearchWindow'
import {
  ToolbarToggleGroup,
  ToolbarToggleValue,
} from '../../../components/toolbars/Toolbar/ToolbarToggle'
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded'
import { intl } from '../../../../i18n'
import { useDialogState } from '../../../components/dialogs/useDialogState'

interface OwnProps {
  title: string
  onSubmit: () => void
  onCancel: () => void
  submitDisabled: boolean
  isLoading: boolean
  toolbar: ToolbarToggleValue | undefined
  onChangeToolbar: (value: ToolbarToggleValue | undefined) => void
  isNotice: boolean
  searchText: string
  onSearch: () => void
  onChangeSearchText: (value: string) => void
  total: number
  hit: number
  onClickAddUser: () => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps & SettingSpeedDialProps

const UsersHeader = (props: Props) => {
  const [showCancelDialog, openCancelDialog, closeCancelDialog] =
    useDialogState()
  return (
    <PageHeader>
      <Header>
        <HeaderContent>
          <HeaderGroup>
            <SearchWindow
              reloadHandler={props.onSearch}
              searchText={props.searchText}
              onChange={props.onChangeSearchText}
              total={props.total}
              hit={props.hit}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <SubmitButton
              onClick={props.onSubmit}
              disabled={
                props.submitDisabled || props.isLoading || props.notEdited
              }
              notEdited={props.notEdited}
            />
            <ReloadButton
              onClick={openCancelDialog}
              disabled={props.isLoading}
            />
            <CancelConfirmDialog
              open={showCancelDialog}
              onConfirm={() => {
                closeCancelDialog()
                props.onCancel()
              }}
              onClose={closeCancelDialog}
            />
          </HeaderGroup>
          <HeaderDivider />
          <HeaderGroup>
            <IconGroup>
              <SettingSpeedDial
                onClickImport={props.onClickImport}
                onClickExport={props.onClickExport}
                onRestoreSearchCondition={props.onRestoreSearchCondition}
                rowHeight={props.rowHeight}
                onClickChangeRowHeight={props.onClickChangeRowHeight}
              />
            </IconGroup>
          </HeaderGroup>
          <ToolbarToggleGroup
            toggleItems={[ToolbarToggleValue.DISPLAY]}
            value={props.toolbar}
            onChange={props.onChangeToolbar}
            noticeItem={ToolbarToggleValue.DISPLAY}
            isNotice={props.isNotice}
          />
          <HeaderGroup>
            <BaseButton
              sx={{
                background:
                  BackgroundColor.BLUE + ' 0% 0% no-repeat padding-box',
                color: TextColor.BLUE,
                width: '100%',
                padding: '0 10px',
                border: '1px solid transparent',
              }}
              variant="outlined"
              startIcon={
                <PersonAddAltRoundedIcon color="primary" fontSize="small" />
              }
              onClick={props.onClickAddUser}
            >
              {intl.formatMessage({ id: 'user.registration.create.addButton' })}
            </BaseButton>
          </HeaderGroup>
        </HeaderContent>
      </Header>
    </PageHeader>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(UsersHeader)
