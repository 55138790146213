import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { AllState } from '../../../store'
import { PageArea, PageProps } from '../index'
import { ProjectOverviewHeader } from './Header'
import { BulkSheetView } from '../../containers/BulkSheetView'
import {
  BodyScrollEndEvent,
  CellClickedEvent,
  ColumnVisibleEvent,
  FirstDataRenderedEvent,
  RowGroupOpenedEvent,
} from 'ag-grid-community'
import '../../../styles/agGrid.scss'
import { getGanttChartWidth } from '../ProjectPlan/projectPlanOptions'
import {
  changeProgressHeaderByBaseDate,
  getProgressData,
  projectOverviewGridOptions,
} from './projectOverviewGridOptions'
import Loading from '../../components/process-state-notifications/Loading'
import { usePageState } from './usePageState'
import { ExcelExportDialog } from '../../containers/BulkSheet/ExcelExportDialog'
import { exportExcel } from '../../containers/BulkSheet/excel'
import {
  openWbsItemSearch,
  SearchConditionEndDelayed,
  SearchConditionStartDelayed,
} from '../WbsItemSearch/wbsItemSearchOptions'
import { DateBound } from '../../components/toggleGroups/StartAndEndDateToggleGroup'
import { useData } from './useData'
import { getDisplayedRow } from '../../containers/BulkSheetView/lib/gridApi'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import { connect } from 'react-redux'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { generateGanttScale } from '../../containers/commons/AgGrid/components/cell/custom/gantt/ganttUtil'
import { getUrlQueryStringParams } from '../../../utils/urls'
import { useProjectPrivateContext } from '../../context/projectContext'
import { ProjectDetail } from '../../../lib/functions/project'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'

const mapStateToProps = (state: AllState) => ({
  ganttParameter: state.project.ganttParameter,
})

type StateProps = {
  ganttParameter?: GanttParameterVO
}

export const ProjectOverview = connect(mapStateToProps)(
  projectPrivate((props: PageProps & StateProps) => {
    const { project } = useProjectPrivateContext()
    if (!props.ganttParameter) {
      return <></>
    }
    return (
      <ProjectOverviewContent
        {...props}
        project={project}
        ganttParameter={props.ganttParameter}
        treeRootUuid={getUrlQueryStringParams()?.treeRootUuid}
      />
    )
  })
)

type Props = PageProps & {
  project: ProjectDetail
  ganttParameter: GanttParameterVO
  treeRootUuid?: string
}

export const ProjectOverviewContent = (props: Props) => {
  // Grid data
  const { rows, enqueue, refresh } = useData({
    projectUuid: props.project.uuid,
    treeRootUuid: props.treeRootUuid,
  })

  // Page state
  const pageState = usePageState(props.uuid)
  const bulkSheetState = useBulkSheetState(props.uuid, props.project.uuid)

  const [loading, setLoading] = useState<boolean>(true)
  const loadingElem = useRef<HTMLDivElement>(null)
  const [isGridReady, setIsGridReady] = useState<boolean>(false)
  const [gantt, showGantt] = useState<boolean>(false)
  const [excelDialog, openExcelDialog] = useState<boolean>(false)

  // Grid definition
  const gridOptions = useMemo(
    () => projectOverviewGridOptions(props.project, props.ganttParameter),
    []
  )

  const workloadUnitState = useWorkloadUnit(pageState.workloadUnit)
  useEffect(() => {
    if (!isGridReady) return
    if (gridOptions.context.aggregateBaseDate !== pageState.aggregateBaseDate) {
      refreshProgressHeader()
    }
    if (pageState.initialized) {
      gridOptions.context = {
        ...gridOptions.context,
        aggregateTarget: pageState.aggregateTarget,
        aggregateField: pageState.aggregateField,
        workloadUnit: pageState.workloadUnit,
        aggregateBaseDate: pageState.aggregateBaseDate,
        workloadUnitState,
      }
      gridOptions.api?.refreshCells()
    }
  }, [
    isGridReady,
    pageState.initialized,
    pageState.aggregateTarget,
    pageState.aggregateField,
    pageState.workloadUnit,
    pageState.aggregateBaseDate,
    workloadUnitState,
  ])

  const refreshProgressHeader = useCallback(() => {
    if (gridOptions.api) {
      changeProgressHeaderByBaseDate(
        gridOptions.api,
        pageState.aggregateBaseDate
      )
      gridOptions.api.refreshHeader()
    }
  }, [pageState.aggregateBaseDate])

  const onGanttParameterChanged = useCallback(async () => {
    const ganttParameter = props.ganttParameter
    const [ganttDisplayTimeScale, ganttTimeScale] = await generateGanttScale(
      ganttParameter
    )
    gridOptions.context = {
      ...gridOptions.context,
      ganttParameter,
      ganttDisplayTimeScale,
      ganttTimeScale,
    }
    gridOptions.api?.refreshCells({ columns: ['ganttChart'], force: true })
    gridOptions.api?.refreshHeader()
    gridOptions.columnApi?.setColumnWidth(
      'ganttChart',
      getGanttChartWidth(ganttParameter)
    )
  }, [gridOptions.api, props.ganttParameter])

  useEffect(() => {
    onGanttParameterChanged()
  }, [gridOptions.api, props.ganttParameter])

  useEffect(() => {
    gridOptions.context = { ...gridOptions.context, ...pageState }
    gridOptions.api?.refreshCells()
  }, [pageState.dateFormat])

  const restoreColumnState = () => {
    if (!_.isEmpty(bulkSheetState.columnState)) {
      gridOptions.columnApi?.applyColumnState({
        state: bulkSheetState.columnState,
        applyOrder: true,
      })
    }
  }
  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [])

  const restoreExpandedRows = useCallback(() => {
    bulkSheetState.expandedRowIds.forEach(id => {
      gridOptions.api?.getRowNode(id)?.setExpanded(true)
    })
  }, [bulkSheetState.expandedRowIds])

  // Event
  const onRefresh = useCallback(async () => {
    if (!gridOptions.api) return
    setLoading(true)
    try {
      const fetchBodyUuid = getDisplayedRow(gridOptions.api).map(
        node => node.data.uuid
      )
      await refresh(fetchBodyUuid)
    } finally {
      setLoading(false)
    }
  }, [])

  const onBodyScrollEnd = useCallback(
    (event: BodyScrollEndEvent) => {
      if (event.direction === 'horizontal') return
      const fetchBodyUuid = getDisplayedRow(event.api)
        .filter(node => !node.data.body)
        .map(node => node.data.uuid)
      enqueue(fetchBodyUuid)
    },
    [enqueue]
  )

  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        bulkSheetState.expandRows([key])

        // Fetch body
        const children = event.node.allLeafChildren
          .filter(
            childNode =>
              childNode.parent?.id === event.node.id && !childNode.data.body
          )
          .map(childNode => childNode.data.uuid)
        enqueue(children)
        setTimeout(
          () => event.api.refreshCells({ columns: ['rowNumber'], force: true }),
          300
        )
      } else {
        bulkSheetState.collapseRows([key])
      }
    },
    [enqueue, bulkSheetState.expandedRowIds]
  )

  if (!pageState.initialized) {
    return <></>
  }

  return (
    <PageArea>
      <ProjectOverviewHeader
        externalId={props.externalId}
        onRefresh={() => onRefresh()}
        onClickExport={() => openExcelDialog(true)}
        aggregateTarget={pageState.aggregateTarget}
        onAggregateTargetChanged={v => pageState.setAggregateTarget(v)}
        aggregateField={pageState.aggregateField}
        onAggregateFieldChanged={v => pageState.setAggregateField(v)}
        workloadUnit={pageState.workloadUnit}
        onWorkloadUnitChanged={v => pageState.setWorkloadUnit(v)}
        aggregateBaseDate={pageState.aggregateBaseDate}
        onAggregateBaseDateChanged={v => pageState.setAggregateBaseDate(v)}
        onColumnReset={() => {
          gridOptions.columnApi!.resetColumnState()
          gridOptions.columnApi!.setColumnWidth(
            'ganttChart',
            getGanttChartWidth(props.ganttParameter)
          )
        }}
        showGanttParameter={gantt}
        currentFormat={pageState.dateFormat}
        onClickChangeDateFormat={v => {
          pageState.setDateFormat(v)
        }}
      />
      {rows && (
        <BulkSheetView
          ref={loadingElem}
          gridOptions={gridOptions}
          rowData={rows}
          // Event
          onGridReady={() => {
            restoreExpandedRows()
            restoreColumnState()
            setIsGridReady(true)
            setLoading(false)
          }}
          onFirstDataRendered={(e: FirstDataRenderedEvent) => {
            const columnState = e.columnApi.getColumnState()
            if (!columnState?.find(v => v.colId === 'ganttChart')?.hide) {
              showGantt(true)
            }
          }}
          onRowGroupOpened={onRowGroupOpened}
          onRowDataUpdated={() => setLoading(false)}
          onCellClicked={(e: CellClickedEvent) => {
            const type: WbsItemTypeVO = e.data.wbsItem?.wbsItemType
            if (
              !type ||
              type.isTask() ||
              (type.isDeliverable() &&
                e.context.aggregateTarget === WbsItemType.DELIVERABLE)
            ) {
              return undefined
            }
            if (
              ['progressRate', 'delayed'].includes(e.colDef.field ?? '') &&
              0 < getProgressData(e.data, e.context).delayed
            ) {
              const conditions = {
                types: [pageState.aggregateTarget],
                rootUuid: e.data.wbsItem.uuid,
              }
              openWbsItemSearch(
                props.project.uuid,
                pageState.aggregateBaseDate === DateBound.START
                  ? SearchConditionStartDelayed.with(conditions)
                  : SearchConditionEndDelayed.with(conditions)
              )
            }
          }}
          onColumnVisible={(e: ColumnVisibleEvent) => {
            if (e.column?.getColDef().field === 'ganttChart') {
              showGantt(!!e.visible)
            }
            rememberColumnState()
          }}
          onColumnResized={rememberColumnState}
          onColumnMoved={rememberColumnState}
          onBodyScrollEnd={onBodyScrollEnd}
        />
      )}
      <Loading isLoading={loading} elem={loadingElem.current} />
      {excelDialog && (
        <ExcelExportDialog
          onClose={() => openExcelDialog(false)}
          onSubmit={colIds =>
            exportExcel({
              fileNamePrefix: 'project_overview',
              gridOptions,
              exportColIds: colIds,
              shouldRowBeSkipped: params =>
                !params.node.data.progress || !params.node.displayed,
            })
          }
          columnApi={gridOptions.columnApi}
        />
      )}
    </PageArea>
  )
}
