import {
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueParserParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ApplicationFunctionPropertyConfiguration,
  CustomEnumValue,
  FunctionProperty,
  PropertyType,
} from '../../../../lib/commons/appFunction'
import _ from 'lodash'
import numeral from 'numeral'
import { toNumber } from '../../../../utils/number'
import { ColumnType, dateValueParser } from '../../commons/AgGrid'
import {
  CheckBoxCellEditor,
  CustomEnumCellEditor,
  DateCellEditor,
  DateTimeCellEditor,
  EntitySearchCellEditor,
} from '../components/cellEditor'
import { formatDateTime } from '../../../../utils/date'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  CheckBoxCellRenderer,
  CustomEnumCellRenderer,
  DefaultCellRenderer,
  EntitySearchCellRenderer,
} from '../components/cellRenderer'
import { CUSTOM_ENUM_NONE } from '../../../../lib/commons/customEnum'
import { EntityExtensionValue } from '../../meta/entityExtension'
import DateVO from '../../../../vo/DateVO'
import repositories from '../../meta/repositories'
import MultiLineText from '../../commons/AgGrid/components/cell/custom/multiLineText'
import {
  BoolFilter,
  ClientSideNumberFilter,
  ClientSideSelectFilter,
  ClientSideTextFilter,
  DateFilter,
  NumberFilter,
  SelectFilter,
  ServerSideBoolFilter,
  ServerSideDateFilter,
  ServerSideNumberFilter,
  ServerSideSelectFilter,
  ServerSideTextFilter,
  TextFilter,
} from '../components/filter'
import { filter } from '../../../pages/ProjectPlanNew/projectPlanNew'
import { intl } from '../../../../i18n'
import { ServerSideTextFloatingFilter } from '../components/floatingFilter'
import { overwriteExtensionPropertiesByPropertyConfiguration } from './propertyConfiguration'
import { customHeaderTemplate } from '../../commons/AgGrid/components/header/CustomHeader'
import { EntitySearchOption } from '../../../../lib/commons/entitySearch'

export type FlagxsColumnDef = (ColDef | ColGroupDef) & { externalId: string }
type ColumnsAfterMergedExtensionColumns = (ColDef | ColGroupDef)[]

const filterTypeTuple = ['SERVER_SIDE', 'CLIENT_SIDE'] as const
export type FilterType = (typeof filterTypeTuple)[number]

interface GenerateColumnDefOptions {
  disableFloatingFilter?: boolean
  filterType?: FilterType
}

export const mergeExtensionColumns = ({
  projectUuid,
  defaultColumns,
  extensionProperties,
  propertyConfigurations,
  generateColDefOptions,
}: {
  projectUuid: string
  defaultColumns: FlagxsColumnDef[]
  extensionProperties: FunctionProperty[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
  generateColDefOptions?: GenerateColumnDefOptions
}): ColumnsAfterMergedExtensionColumns => {
  overwriteExtensionPropertiesByPropertyConfiguration({
    extensionProperties,
    propertyConfigurations,
  })
  const extensionColumns: Record<
    string, // Key is the external id.
    {
      order: number
      externalId: string
      headerName: string
      children: {
        order: number
        extensionColumnDef: FlagxsColumnDef
      }[]
    }
  > = {}
  extensionProperties.forEach(v => {
    if (hasChildExtensionProperty(v, extensionProperties)) {
      extensionColumns[v.externalId] = {
        order: Number(v.propertyLayout.split('-')[0]),
        externalId: v.externalId,
        headerName: v.name,
        children: [],
      }
    } else if (!v.parentProperty) {
      const extensionColumnDef = generateExtensionColumnDef(
        projectUuid,
        v,
        generateColDefOptions
      )
      if (!extensionColumnDef) return
      extensionColumns[v.externalId] = {
        order: Number(v.propertyLayout.split('-')[0]),
        externalId: v.externalId,
        headerName: '',
        children: [
          {
            order: Number(v.propertyLayout.split('-')[0]),
            extensionColumnDef,
          },
        ],
      }
    }
  })

  const childrenOfDefaultColumns: Record<
    string, // Key is the parent default column.
    {
      order: number
      extensionColumnDef: ColDef
    }[]
  > = {}
  // Create the ColDefs of the children.
  extensionProperties.forEach(extensionProperty => {
    if (extensionColumns[extensionProperty.externalId]) return // Skip the group header extension property.

    const extensionColumnDef = generateExtensionColumnDef(
      projectUuid,
      extensionProperty,
      generateColDefOptions
    )
    if (!extensionColumnDef) return // Skip the invalid extension property.

    if (extensionProperty.parentProperty) {
      if (extensionColumns[extensionProperty.parentProperty]) {
        extensionColumns[extensionProperty.parentProperty].children.push({
          order: Number(extensionProperty.propertyLayout.split('-')[1]),
          extensionColumnDef,
        })
        return
      }

      const parentDefaultColumnDef = defaultColumns.find(
        defaultColumnDef =>
          defaultColumnDef.externalId === extensionProperty.parentProperty
      )
      if (parentDefaultColumnDef && parentDefaultColumnDef.externalId) {
        const parentExternalId = parentDefaultColumnDef.externalId
        let children = childrenOfDefaultColumns[parentExternalId]
        if (!children) {
          children = []
        }
        children.push({
          order: Number(extensionProperty.propertyLayout.split('-')[1]),
          extensionColumnDef,
        })
        childrenOfDefaultColumns[parentExternalId] = children
      }

      console.error(
        `There is the invalid extension property has no parent. entityExtensionUuid=${extensionProperty.entityExtensionUuid}, externalId=${extensionProperty.externalId}`
      )
    }
  })

  // Add the extension columns to the default columns.
  Object.keys(childrenOfDefaultColumns).forEach(defaultColumnExternalId => {
    const childExtensionColumns =
      childrenOfDefaultColumns[defaultColumnExternalId]
    const targetDefaultColumn = defaultColumns.find(
      defaultColumn => defaultColumn.externalId === defaultColumnExternalId
    ) as ColGroupDef | undefined
    if (targetDefaultColumn && childExtensionColumns) {
      childExtensionColumns.sort((a, b) => a.order - b.order)
      childExtensionColumns.forEach(childExtensionColumn => {
        if (!targetDefaultColumn.children) {
          targetDefaultColumn.children = []
        }
        targetDefaultColumn.children.splice(
          childExtensionColumn.order - 1,
          0,
          childExtensionColumn.extensionColumnDef
        )
      })
    }
  })

  // Add the extension columns.
  const orderedExtensionColumns = Object.values(extensionColumns).sort(
    (a, b) => a.order - b.order
  )
  orderedExtensionColumns.forEach(extensionColumn => {
    defaultColumns.splice(extensionColumn.order - 1, 0, {
      ...extensionColumn,
      children: extensionColumn.children
        .sort((a, b) => a.order - b.order)
        .map(v => v.extensionColumnDef),
    })
  })

  return defaultColumns
}

const hasChildExtensionProperty = (
  parentCandidate: FunctionProperty,
  otherExtensionProperties: FunctionProperty[]
) =>
  otherExtensionProperties.some(
    other =>
      other.parentProperty &&
      other.parentProperty === parentCandidate.externalId
  )

export const generateExtensionColumnDef = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
): FlagxsColumnDef | undefined => {
  const commonColDef = {
    externalId: extension.externalId,
    field: extension.externalId,
    headerName: extension.name,
    floatingFilter: !option?.disableFloatingFilter,
    hide: extension.hiddenIfC.isTrue || extension.hiddenIfU.isTrue,
    editable: (params: EditableCallbackParams) => {
      if (!!params.data?.isTotal) return false
      return extension.editableIfC.isTrue || extension.editableIfU.isTrue
    },
    valueGetter: (params: ValueGetterParams) => {
      if (!!params.data?.isTotal) return
      return (params.data?.body?.extensions ?? params.data?.extensions)?.find(
        v => v.uuid === extension.entityExtensionUuid
      )?.value
    },
    valueSetter: (params: ValueSetterParams) => {
      const { oldValue, newValue, colDef, data } = params
      const value = valueParser(extension.propertyType, newValue)
      const field = colDef.field || colDef.colId
      if (
        !field ||
        (_.isNil(oldValue) && _.isNil(value)) ||
        oldValue === value ||
        !data
      ) {
        return false
      }
      if (data.body) {
        if (!data.body?.extensions) {
          data.body.extensions = []
        }
      } else if (!data.extensions) {
        data.extensions = []
      }
      const val = {
        uuid: extension.entityExtensionUuid,
        value: value,
      } as EntityExtensionValue
      const index = (data.body?.extensions ?? data.extensions).findIndex(
        v => v.uuid === extension.entityExtensionUuid
      )
      if (0 <= index) {
        if (data.body) {
          data.body.extensions.splice(index, 1, val)
        } else {
          data.extensions.splice(index, 1, val)
        }
      } else {
        if (data.body) {
          data.body.extensions.push(val)
        } else {
          data.extensions.push(val)
        }
      }
      data.edited = true
      if (!params.data.editedData) {
        data.editedData = { [field]: params.oldValue }
      } else if (!data.editedData.hasOwnProperty(field)) {
        data.editedData[field] = params.oldValue
      }
      store.dispatch(requireSave())
      return true
    },
    cellRenderer: DefaultCellRenderer,
    cellRendererParams: { uiMeta: extension },
  }
  if (extension.tooltipText) {
    commonColDef['headerTooltip'] = extension.tooltipText
    commonColDef['headerComponentParams'] = {
      template: customHeaderTemplate({
        tooltip: extension.tooltipText,
      }),
    }
  }

  // Property type specific definition
  switch (extension.propertyType) {
    case PropertyType.Text:
      return {
        ...commonColDef,
        ...getTextFilterParams(projectUuid, extension, option),
      }
    case PropertyType.Number:
      return {
        ...commonColDef,
        cellClass: 'ag-numeric-cell',
        cellStyle: { justifyContent: 'flex-end' },
        valueParser: (params: ValueParserParams) => {
          return toNumber(params.newValue)
        },
        valueFormatter: (params: ValueFormatterParams) => {
          const num = numeral(params.value)
          if (
            params.value === null ||
            params.value === undefined ||
            num.value?.() === null ||
            num.value?.() === undefined
          ) {
            return ''
          }
          return num.format(extension.displayFormat ?? '0,0')
        },
        ...getNumberFilterParams(projectUuid, extension, option),
      }
    case PropertyType.Date:
      return {
        ...commonColDef,
        type: [ColumnType.date],
        width: 120,
        cellEditor: DateCellEditor,
        valueParser: (params: ValueParserParams) =>
          dateValueParser(params.newValue),
        valueFormatter: (params: ValueFormatterParams) =>
          params.value
            ? new DateVO(params.value).format(params.context?.dateFormat)
            : '',
        ...getDateFilterParams(projectUuid, extension, option),
      }
    case PropertyType.DateTime:
      return {
        ...commonColDef,
        type: [ColumnType.dateTime],
        width: 175,
        cellEditor: DateTimeCellEditor,
        valueGetter: (params: ValueGetterParams) => {
          const value = commonColDef.valueGetter(params)
          return typeof value === 'string' ? Number(value) : value
        },
        valueFormatter: (params: ValueFormatterParams) =>
          formatDateTime(params.value) ?? '',
        ...getDateFilterParams(projectUuid, extension, option),
      }
    case PropertyType.Checkbox:
      return {
        ...commonColDef,
        cellEditor: CheckBoxCellEditor,
        cellRendererSelector: (params: ICellRendererParams) => {
          return !!params.data?.isTotal
            ? { component: DefaultCellRenderer }
            : { component: CheckBoxCellRenderer }
        },
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === undefined ? undefined : params.value.toString(),
        ...getCheckboxFilterParams(projectUuid, extension, option),
      }
    case PropertyType.Select:
      const cellParams = {
        ...commonColDef.cellRendererParams,
        valuesAllowed: extension.valuesAllowed.filter(
          v => v.value !== CUSTOM_ENUM_NONE
        ),
      }
      return {
        ...commonColDef,
        cellEditor: CustomEnumCellEditor,
        cellEditorParams: cellParams,
        cellRenderer: CustomEnumCellRenderer,
        cellRendererParams: cellParams,
        ...getSelectFilterParams(projectUuid, extension, option),
        comparator: (a: string, b: string) => {
          if (_.isEmpty(extension.valuesAllowed)) {
            return 0
          }
          return (
            extension.valuesAllowed.findIndex(o => o.value === a) -
            extension.valuesAllowed.findIndex(o => o.value === b)
          )
        },
      }
    case PropertyType.MultiSelect:
      return {
        ...commonColDef,
        type: [ColumnType.multiSelect],
        cellEditorParams: {
          uiMeta: extension,
        },
        comparator: (valueA: CustomEnumValue[], valueB: CustomEnumValue[]) => {
          // If there are options, compare in order of options; if not, compare in order of name
          const comp = extension.valuesAllowed
            ? (valA: CustomEnumValue, valB: CustomEnumValue) =>
                extension.valuesAllowed.findIndex(
                  e => e.value === valA?.value
                ) -
                extension.valuesAllowed.findIndex(e => e.value === valB?.value)
            : (valA: CustomEnumValue, valB: CustomEnumValue) =>
                valA.name.localeCompare(valB.name)

          // Compare elements in order starting from the first
          const minIndex = Math.min(valueA?.length || 0, valueB?.length || 0)
          for (let i = 0; i < minIndex; i++) {
            const diff = comp(valueA[i], valueB[i])
            if (diff !== 0) return diff
          }
          // If there is no difference, compare the number of elements.
          return (valueA?.length || 0) - (valueB?.length || 0)
        },
        filterParams: {
          valueFormatter: (params: ValueFormatterParams) => {
            return params.value
          },
          getLabel: option => option?.name,
          sortValues: (uiMeta, options) => {
            options.sort((o1, o2) => {
              const o1index = extension.valuesAllowed.findIndex(
                v => v.value === o1.value
              )
              const o2index = extension.valuesAllowed.findIndex(
                v => v.value === o2.value
              )
              return o1index - o2index
            })
            return options
          },
        },
      }
    case PropertyType.EntitySearch:
      return {
        ...commonColDef,
        cellRenderer: EntitySearchCellRenderer,
        cellRendererParams: {
          ...commonColDef.cellRendererParams,
          entity: extension.referenceEntity,
        },
        cellEditor: EntitySearchCellEditor,
        cellEditorParams: {
          entity: extension.referenceEntity,
          search: (text: string, data) => {
            const searchOptions = extension.searchOptions.build(data)
            const repository = repositories[extension.referenceEntity!]
            return repository.search(text, searchOptions)
          },
        },
        suppressKeyboardEvent: params =>
          (!params.editing &&
            ['Delete', 'Backspace'].includes(params.event.key)) ||
          (params.editing && params.event.key === 'Enter'),
        ...getEntitySearchFilterParams(projectUuid, extension, option),
      }
    case PropertyType.MultiLineText:
      return {
        ...commonColDef,
        type: [ColumnType.multiLineText],
        cellEditor: MultiLineText.cellEditor,
        cellRenderer: MultiLineText.cellRenderer,
        ...getTextFilterParams(projectUuid, extension, option),
      }
  }
  return undefined
}

export type ExtensionValueType = string | number | undefined

export const serializeExtensionValue = (
  uuid: string,
  value: ExtensionValueType,
  functionProp?: FunctionProperty
) => {
  return { uuid, value: serialize(value, functionProp) }
}

export const serializeExtensionValueDelta = (
  uuid: string,
  {
    oldValue,
    newValue,
  }: { oldValue: ExtensionValueType; newValue: ExtensionValueType },
  functionProp?: FunctionProperty
) => {
  const o = serialize(oldValue, functionProp)
  const n = serialize(newValue, functionProp)
  if ((!o && !n) || _.isEqual(o, n)) {
    return undefined
  }
  return { uuid, oldValue: o, newValue: n }
}

const serialize = (val, functionProp?: FunctionProperty) => {
  if (val === undefined || typeof val === 'string') {
    return val
  }
  if (functionProp && functionProp.propertyType === PropertyType.MultiSelect) {
    return val && val.map(v => v.value).join(',')
  }
  if (typeof val === 'object' && val.uuid) {
    return val.uuid
  }
  return JSON.stringify(val)
}

const valueParser = (type: PropertyType, value: any): any => {
  if (value === undefined || value === null) return undefined
  const v = value
  switch (type) {
    case PropertyType.Checkbox:
      if (v.toString().match(/^([tT]|check|1)/)) return true
      if (v.toString().match(/^[fF]|0/)) return false
      return undefined
    case PropertyType.Number:
      return toNumber(v)
  }
  return v
}

const getTextFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: ClientSideTextFilter,
        floatingFilter: !option?.disableFloatingFilter,
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideTextFilter,
        floatingFilter: !option?.disableFloatingFilter,
        floatingFilterComponent: ServerSideTextFloatingFilter,
        filterParams: {
          fetch: (v: TextFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  operator: v.operator,
                  value: v.value,
                },
              ],
            })
          },
        },
      }
  }
}

const getNumberFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: ClientSideNumberFilter,
        floatingFilter: !option?.disableFloatingFilter,
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideNumberFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          fetch: (v: NumberFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  operator: v.operator,
                  value: v.value?.toString(),
                },
              ],
            })
          },
        },
      }
  }
}

const getDateFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: 'dateCellFilter',
        floatingFilter: !option?.disableFloatingFilter,
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideDateFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          fetch: (v: DateFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  operator: v.operator,
                  value: v.value,
                },
              ],
            })
          },
        },
      }
  }
}

const getCheckboxFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: 'clientSideSelectFilter',
        floatingFilter: !option?.disableFloatingFilter,
        keyCreator: (params): string => {
          return params.value ? 'checked' : ''
        },
        filterParams: {
          getValue: option => (option ? 'checked' : ''),
          getLabel: option => (option ? 'checked' : ''),
        },
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideBoolFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          labelExist: intl.formatMessage({ id: 'exist' }),
          labelNotExist: intl.formatMessage({ id: 'not.exist' }),
          fetch: (v: BoolFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  operator: v.operator,
                },
              ],
            })
          },
        },
      }
  }
}

const getSelectFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: ClientSideSelectFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          valueGetter: params => {
            if (!!params.node.data?.isTotal) return
            const value = (
              params.node.data?.body?.extensions ?? params.node.data?.extensions
            )?.find(v => v.uuid === extension.entityExtensionUuid)?.value
            return value
              ? extension.valuesAllowed.find(v => v.value === value)
              : value
          },
          getValue: option => option?.value,
          getLabel: option =>
            extension.valuesAllowed.find(
              (v: CustomEnumValue) => v.value === option.value
            )?.name ?? option.value,
          sortValues: (uiMeta, options) => {
            options.sort((o1, o2) => {
              const o1index = extension.valuesAllowed.findIndex(
                v => v.value === o1.value
              )
              const o2index = extension.valuesAllowed.findIndex(
                v => v.value === o2.value
              )
              return o1index - o2index
            })
            return options
          },
        },
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideSelectFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          optionKey: extension.entityExtensionUuid,
          getValue: option => option.value,
          getLabel: option => option.name,
          fetch: (v: SelectFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  values: v.values,
                  includeBlank: v.includeBlank,
                },
              ],
            })
          },
        },
      }
  }
}

const getEntitySearchFilterParams = (
  projectUuid: string,
  extension: FunctionProperty,
  option?: GenerateColumnDefOptions
) => {
  switch (option?.filterType) {
    case 'CLIENT_SIDE':
      return {
        filter: ClientSideSelectFilter,
        filterParams: {
          valueGetter: params => {
            if (
              !!params.node.data?.isTotal ||
              !params.context ||
              !(
                extension.referenceEntity &&
                extension.referenceEntity in params.context
              )
            ) {
              return
            }
            const options = params.context[extension.referenceEntity]
            const value = (
              params.node.data?.body?.extensions ?? params.node.data?.extensions
            )?.find(v => v.uuid === extension.entityExtensionUuid)?.value

            const key = typeof value === 'object' ? value.uuid : value
            const option = options.find(o => o.uuid === key)
            return option
          },
          getValue: (option: EntitySearchOption) => {
            return option?.uuid
          },
          getLabel: (option: EntitySearchOption) => {
            return option?.name
          },
        },
      }
    case 'SERVER_SIDE':
    default:
      return {
        filter: ServerSideSelectFilter,
        floatingFilter: !option?.disableFloatingFilter,
        filterParams: {
          optionKey: 'member',
          getValue: option => option.uuid,
          getLabel: option => option.name,
          fetch: (v: SelectFilter) => {
            return filter({
              projectUuid,
              extensions: [
                {
                  extensionUuid: extension.entityExtensionUuid,
                  extensionType: extension.propertyType,
                  values: v.values,
                  includeBlank: v.includeBlank,
                },
              ],
            })
          },
        },
      }
  }
}
