import React from 'react'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  InputAdornment,
  MenuItem,
  Popper,
} from '@mui/material'
import { styled } from '@mui/system'
import EntitySearchVO from '../../../../vo/EntitySearchVO'
import repositories from '../../../containers/meta/repositories'
import TextInput from '../../../components/editors/input/TextInput'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import _ from 'lodash'
import { CellDef } from '../../../containers/meta/ViewMeta/SingleSheetViewMeta'

const Option = styled(MenuItem)({
  display: 'flex',
})
const Icon = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '0 5px',
})
const StyledTextInput = styled(TextInput)({
  minWidth: 130,
})

interface Props {
  storeKey: string
  data: any
  defs: CellDef | undefined
  value: any
  onChange: (vo: EntitySearchVO | undefined) => void
  onBlur: () => void
}

interface State {
  options: EntitySearchVO[]
  inputValue: string
  open: boolean
}

class EntitySearchVOSelect extends React.Component<Props, State> {
  private readonly repository: any
  constructor(props) {
    super(props)
    this.repository = repositories[props.defs.uiMeta.referenceEntity]
    this.state = {
      options: [],
      inputValue: '',
      open: true,
    }
  }

  private search = async (inputValue: string) => {
    if (!this.props.defs) {
      this.setState({ options: [] })
      return
    }
    const uiMeta = this.props.defs.uiMeta
    const searchOptions = uiMeta.searchOptions.build(this.props.data)
    const options = await this.repository.search(
      inputValue,
      searchOptions,
      uiMeta
    )
    this.setState({ options: options.map(option => EntitySearchVO.of(option)) })
  }

  private searchOnInputChange = _.debounce(this.search, 300)

  private onFocus = event => {
    this.search(this.state.inputValue ? this.state.inputValue : '')
  }

  private onClose = () => {
    this.setState({ open: false })
    this.props.onBlur()
  }

  private onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      this.setState({ inputValue: value })
      this.searchOnInputChange(value)
    } else if (reason === 'reset') {
      this.setState({ inputValue: value })
    } else if (reason === 'clear') {
      this.searchOnInputChange('')
    }
  }

  onChange = (event: any, value: EntitySearchVO | null) => {
    this.props.onChange(value || undefined)
  }

  renderOption = (props, option: EntitySearchVO) => {
    return (
      <Option {...props}>
        {this.repository.getHasIcon() && (
          <Icon variant="circular" src={option.getIconUrl()} />
        )}
        {option.formatWithCode()}
      </Option>
    )
  }

  renderInput = (params: AutocompleteRenderInputParams) => {
    const startAdornment =
      this.repository.getHasIcon() && this.props.value ? (
        <InputAdornment position="start" style={{ marginRight: 0 }}>
          <Icon variant="circular" src={this.props.value.getIconUrl()} />
        </InputAdornment>
      ) : undefined
    const InputProps = {
      ...params.InputProps,
      disableUnderline: true,
      startAdornment: startAdornment,
    }
    return (
      <StyledTextInput
        {...params}
        variant="standard"
        fullWidth={true}
        InputProps={InputProps}
        inputProps={{
          ...params.inputProps,
          style: {
            textOverflow: 'inherit',
          },
        }}
        placeholder={this.props.defs?.uiMeta.placeHolder}
        autoFocus={true}
      />
    )
  }

  render() {
    const { value } = this.props
    return (
      <Autocomplete
        value={_.isEmpty(value) ? null : value}
        inputValue={this.state.inputValue}
        options={this.state.options}
        getOptionLabel={option => option.format() || ''}
        size="small"
        onChange={this.onChange}
        isOptionEqualToValue={(option: EntitySearchVO, value: EntitySearchVO) =>
          value && option.isEqual(value)
        }
        renderOption={this.renderOption}
        renderInput={this.renderInput}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        popupIcon={<ExpandMoreIcon />}
        noOptionsText=""
        autoHighlight={true}
        onFocus={this.onFocus}
        onInputChange={this.onInputChange}
        onBlur={this.props.onBlur}
        onClose={this.onClose}
        open={this.state.open}
      />
    )
  }
}

export default EntitySearchVOSelect
