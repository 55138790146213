import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, InputBase } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import './styles.scss'
import { BorderColor } from '../../../../../styles/commonStyles'
import {
  BlockStyles,
  findMarkdownItemNames,
  InlineStyles,
  shortcutKeyBinding,
  StyleType,
  toggleMarkdownStyle,
} from '../../../../../utils/markdown'
import _ from 'lodash'
import { isImageFile, UploadedFile } from '../../../../../utils/file'
import TenantStorage from '../../../../../utils/storage'
import store from '../../../../../store'
import { showAlert } from '../../../../../store/globalAlert'
import { MarkdownType } from '../../multiline-text/MultilineTextEditor'
import { ResizableArea } from '../../../draggers/ResizableArea'
import Toggle from '../../toggle/Toggle'
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react'
import Dropzone, { DropEvent } from 'react-dropzone'
import Loading from '../../../process-state-notifications/Loading'
import { muiTheme } from '../../../../../styles/muiTheme'
import { MarkdownPreview } from './MarkdownPreview'

interface CustomDropzoneProps {
  loading: boolean
  onDropAccepted?: <T extends File>(files: T[], event: DropEvent) => void
  children: JSX.Element
}

const CustomDropzone = (props: CustomDropzoneProps) => {
  return (
    <>
      <Loading isLoading={props.loading} />
      <Dropzone onDropAccepted={props.onDropAccepted} multiple={true}>
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps({
              onClick: e => {
                e.stopPropagation()
              },
            })}
            style={{ width: '100%', height: '100%', margin: 0, padding: 0 }}
          >
            <input {...getInputProps()} />
            {props.children}
          </Box>
        )}
      </Dropzone>
    </>
  )
}

interface Props extends WrappedComponentProps {
  value: string
  onChange: Function
  markdownType?: MarkdownType
  style?: string
  offset?: number
  lineNo?: number
  setHighlightItems?: (items: string[]) => void
  onSelectionChange?: (start: number, end: number) => void
}

export const Markdown = (props: Props) => {
  const ref = useRef<HTMLInputElement>()
  const inputRef = useRef<HTMLInputElement>()
  const resizeRef = useRef<HTMLDivElement>()
  const previewRef = useRef<HTMLDivElement>()

  const [text, setText] = useState(props.value)
  const [resizeWidth, setResizeWidth] = useState(0)
  const [currentWidth, setCurrentWidth] = useState(0)
  const [currentHeight, setCurrentHeight] = useState(0)
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)

  const isTyping = useRef(false)
  const rendererd = useRef(false)
  const prevOffset = useRef<number>()
  prevOffset.current = props.offset ?? 0

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const valueChanged = useMemo(
    () =>
      _.debounce(t => {
        if (isTyping.current) {
          valueChanged(t)
        } else if (inputRef.current?.value !== t) {
          setText(t)
        }
      }, 100),
    []
  )

  useEffect(() => {
    if (rendererd.current) {
      rendererd.current = true
      setText(props.value)
    } else {
      valueChanged(props.value)
    }
  }, [props.value])

  const inputEnd = useMemo(
    () =>
      _.debounce(() => {
        isTyping.current = false
      }, 300),
    []
  )

  const onChangeText = useCallback(event => {
    try {
      isTyping.current = true
      setText(event.target.value)
      props.onChange(event.target.value)
    } finally {
      inputEnd()
    }
  }, [])

  useEffect(() => {
    if (
      props.offset !== undefined &&
      prevOffset.current !== props.offset &&
      props.offset > -1
    ) {
      moveCursorPosition(props.offset, props.lineNo)
    }
  }, [props.offset, props.lineNo])

  useEffect(() => {
    if (resizeRef.current && resizeWidth !== resizeRef.current.offsetWidth) {
      setResizeWidth(resizeRef.current.offsetWidth)
      setCurrentWidth(resizeRef.current.offsetWidth / 2)
      setCurrentHeight(resizeRef.current.clientHeight)
    }
  }, [props.markdownType, resizeRef.current?.clientHeight])

  const selectionChanged = useMemo(
    () =>
      _.debounce(() => {
        if (props.setHighlightItems && inputRef.current) {
          const startPos = !inputRef.current.selectionStart
            ? 0
            : inputRef.current.selectionStart
          const endPos = !inputRef.current.selectionEnd
            ? 0
            : inputRef.current.selectionEnd
          props.setHighlightItems(
            findMarkdownItemNames(inputRef.current.value, startPos, endPos)
          )
          if (props.onSelectionChange) {
            props.onSelectionChange(startPos, endPos)
          }
        }
      }, 100),
    []
  )

  const onBlur = useMemo(
    () =>
      _.debounce(() => {
        if (props.setHighlightItems) {
          const clearItems: string[] = []
          props.setHighlightItems(clearItems)
        }
      }, 100),
    []
  )

  const addImageText = useCallback(
    (files: UploadedFile[]) => {
      let strText = files.reduce((acc, value) => {
        return acc + `![](${value.url})\n\n`
      }, '')
      const cursor = inputRef.current?.selectionEnd || -1
      if (cursor > 0) {
        const beforeCursor = text.substring(0, cursor)
        const afterCursor = text.substring(cursor)
        strText = beforeCursor + strText + afterCursor
      } else {
        strText = strText + text
      }
      setLoading(false)
      props.onChange(strText)
    },
    [props, text]
  )

  const executeUpload = useCallback(async (file): Promise<UploadedFile> => {
    const response = await TenantStorage.uploadFile(file)
    return {
      url: response.json.downloadUrl,
      name: file.name,
      mimeType: file.type,
    }
  }, [])

  const uploadImages = useCallback(
    async (files): Promise<UploadedFile[]> => {
      setLoading(true)
      const imgFiles = files.filter(file => isImageFile(file))
      if (files.length !== imgFiles.length) {
        store.dispatch(
          showAlert({
            message: props.intl.formatMessage({
              id: 'editor.canNotUploadFile',
            }),
          })
        )
      }
      const results: UploadedFile[] = await Promise.all(
        imgFiles.map(file => executeUpload(file))
      )
      return results.filter(v => v.url)
    },
    [executeUpload, props.intl]
  )

  const onPaste = useCallback(
    async event => {
      if (event.clipboardData.files.length) {
        const files = await uploadImages(Array.from(event.clipboardData.files))
        addImageText(files)
      }
    },
    [uploadImages, addImageText]
  )

  const onKeyEvent = useCallback(event => {
    selectionChanged(event)
    const strType = shortcutKeyBinding(event)
    if (strType) {
      const styleType = StyleType[strType.toUpperCase()]
      if (InlineStyles.includes(styleType) || BlockStyles.includes(styleType)) {
        event.preventDefault()
        addShortcut(styleType, event.target.value)
        return false
      }
    }
    return true
  }, [])

  const addShortcut = useMemo(
    () =>
      _.debounce((styleType, eventText) => {
        if (inputRef.current) {
          const startPos = !inputRef.current.selectionStart
            ? 0
            : inputRef.current.selectionStart
          const endPos = !inputRef.current.selectionEnd
            ? 0
            : inputRef.current.selectionEnd
          const val = toggleMarkdownStyle(
            eventText,
            startPos,
            endPos,
            styleType
          )
          setText(val)
          props.onChange(val)
        }
      }, 100),
    []
  )

  const toggle = useCallback(() => {
    const nowOpenState = !open
    const baseWidth = resizeRef.current?.offsetWidth || resizeWidth
    let newWidth = baseWidth - 15
    if (nowOpenState) {
      newWidth = baseWidth / 2
    }
    setOpen(nowOpenState)
    setCurrentWidth(newWidth)
    setResizeWidth(baseWidth)
  }, [open, resizeWidth])

  const moveCursorPosition = useCallback(
    (offset?: number, lineNo?: number) => {
      if (
        offset === undefined ||
        offset < 0 ||
        lineNo === undefined ||
        lineNo < 0
      ) {
        return
      }
      switch (props.markdownType) {
        case MarkdownType.Edit:
          scrollEditor(offset, lineNo)
          break
        case MarkdownType.Preview:
          scrollPreview(lineNo)
          break
        case MarkdownType.SideBySide:
          scrollPreview(lineNo)
          scrollEditor(offset, lineNo)
          break
      }
    },
    [props.markdownType]
  )

  const scrollEditor = useCallback((offset: number, lineNo: number) => {
    if (!inputRef.current) return
    inputRef.current.setSelectionRange(offset, offset)
    inputRef.current.blur()
    inputRef.current.focus()
  }, [])

  const scrollPreview = useCallback((lineNo: number) => {
    if (!previewRef.current) return
    const lineId = `header-line${lineNo}`
    let children = Array.from(previewRef.current.children)
    if (!children || children.length < 0) return
    if (children[0].tagName.toUpperCase() === 'DIV') {
      children = Array.from(children[0].children)
    }
    const element: any = children.find(item => item.id === lineId)
    if (!element) return
    const top = element.offsetTop - previewRef.current.offsetTop
    previewRef.current.scrollTo({ top })
    previewRef.current.blur()
    previewRef.current.focus()
  }, [])

  const onDropAccepted = useCallback(
    async acceptedFiles => {
      const files = await uploadImages(acceptedFiles)
      addImageText(files)
    },
    [text]
  )

  const inputComponent = useMemo(
    () => (
      <InputBase
        id="markdown"
        inputRef={inputRef}
        multiline={true}
        value={text}
        onChange={onChangeText}
        onKeyDown={onKeyEvent}
        onSelect={selectionChanged}
        onBlur={onBlur}
        onPaste={onPaste}
        classes={{
          root: 'markdown-custom-root',
          input: 'markdown-custom-input',
        }}
      />
    ),
    [text]
  )

  return (
    <Box ref={ref} sx={{ height: '100%' }}>
      {props.markdownType === MarkdownType.Edit && (
        <CustomDropzone loading={loading} onDropAccepted={onDropAccepted}>
          {inputComponent}
        </CustomDropzone>
      )}
      {props.markdownType === MarkdownType.Preview && (
        <Box
          className={props.style}
          ref={previewRef}
          sx={{
            padding: '0',
            overflow: 'scroll',
            width: '100%',
            height: '100%',
            flex: '1 1 0%',
          }}
        >
          <MarkdownPreview text={text} markdownType={props.markdownType} />
        </Box>
      )}
      {props.markdownType === MarkdownType.SideBySide && (
        <ScrollSync>
          <Box
            ref={resizeRef}
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <ResizableArea
              width={currentWidth}
              height={currentHeight}
              maxWidth={resizeWidth}
            >
              <>
                <CustomDropzone
                  loading={loading}
                  onDropAccepted={onDropAccepted}
                >
                  <ScrollSyncNode group={'markdown'}>
                    {inputComponent}
                  </ScrollSyncNode>
                </CustomDropzone>
                <Box
                  sx={{
                    zIndex: 1000,
                    position: 'absolute',
                    top: '4px',
                    right: 0,
                    flexShrink: 0,
                    transition: muiTheme.transitions.create(
                      ['right'],
                      open
                        ? {
                            easing: muiTheme.transitions.easing.easeOut,
                            duration:
                              muiTheme.transitions.duration.enteringScreen,
                          }
                        : {
                            easing: muiTheme.transitions.easing.sharp,
                            duration:
                              muiTheme.transitions.duration.leavingScreen,
                          }
                    ),
                  }}
                >
                  <Toggle
                    toggle={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      toggle()
                    }}
                    isOpen={open}
                    attach={'right'}
                  />
                </Box>
              </>
            </ResizableArea>
            <ScrollSyncNode group={'markdown'}>
              <Box
                className={props.style}
                sx={{
                  padding: 0,
                  overflow: 'scroll',
                  width: '100%',
                  height: '100%',
                  flex: '1 1 0%',
                  borderLeft: `1px solid ${BorderColor.LIGHT_BLACK}`,
                }}
              >
                <MarkdownPreview
                  text={text}
                  markdownType={props.markdownType}
                />
              </Box>
            </ScrollSyncNode>
          </Box>
        </ScrollSync>
      )}
    </Box>
  )
}

export default injectIntl(Markdown)
