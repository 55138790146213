import { Row } from '../model'

export const changeOrderByPrev = <R extends Row>(
  allData: R[],
  moved: R[],
  prevUuid: string | undefined
): R[] => {
  // Update row order.
  const result: R[] = []
  // If prevUuid is undefined, it means inserting first.
  if (!prevUuid) {
    result.push(...moved)
  }
  for (let d of allData) {
    // Skip moved rows.
    if (moved.some(row => row.uuid === d.uuid)) continue
    result.push(d)
    if (prevUuid && d.uuid === prevUuid) {
      result.push(...moved)
    }
  }
  return result
}

export const changeOrderByFollowing = <R extends Row>(
  allData: R[],
  moved: R[],
  followingUuid: string | undefined
): R[] => {
  const result: R[] = []
  for (let d of allData) {
    // Skip moved rows.
    if (moved.some(row => row.uuid === d.uuid)) continue
    if (followingUuid && d.uuid === followingUuid) {
      result.push(...moved)
    }
    result.push(d)
  }
  // If prevUuid is undefined, it means inserting last.
  if (!followingUuid) {
    result.push(...moved)
  }
  return result
}
