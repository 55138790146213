import { useCallback, useEffect, useState } from 'react'
import { EditorType, MarkdownType } from '../index'
import UiStateApi, {
  UiStateKey,
  UiStateScope,
} from '../../../../../../lib/commons/uiStates'

type GlobalEditorUiState = {
  editor: EditorType
}

type EditorUiState = {
  templateUuid?: string
  markdownType?: MarkdownType
}

export const useEditorState = (
  dataUuid?: string,
  defaultMarkdownType?: MarkdownType,
  disableSbsMode?: boolean
) => {
  const [initialized, setInitialized] = useState(false)
  const [editor, setEditor] = useState<EditorType>(EditorType.Draft)
  const [markdownType, setMarkdownType] = useState<MarkdownType>(
    defaultMarkdownType ?? MarkdownType.Edit
  )
  const [templateUuid, setTemplateUuid] = useState<string>()

  useEffect(() => {
    restoreState()
  }, [])

  const restoreState = useCallback(async () => {
    try {
      if (dataUuid) {
        const stateRes = await UiStateApi.get({
          applicationFunctionUuid: '',
          key: `${UiStateKey.EditorUiState}-${dataUuid}`,
          scope: UiStateScope.User,
        })
        const editorState: EditorUiState = stateRes.json.value
          ? JSON.parse(stateRes.json.value)
          : { editor: EditorType.Draft }
        setTemplateUuid(editorState.templateUuid)
        let type = editorState.markdownType ?? MarkdownType.Edit
        if (disableSbsMode && type === MarkdownType.SideBySide) {
          type = MarkdownType.Preview
        }
        setMarkdownType(type)
      }

      const res = await UiStateApi.get({
        applicationFunctionUuid: '',
        key: UiStateKey.EditorUiState,
        scope: UiStateScope.User,
      })
      const globalEditorUiState = res.json.value
        ? (JSON.parse(res.json.value) as GlobalEditorUiState)
        : undefined
      setEditor(globalEditorUiState?.editor || EditorType.Draft)
    } finally {
      setInitialized(true)
    }
  }, [])

  const updateEditorState = useCallback(
    (params: EditorUiState) => {
      if (!dataUuid) return
      UiStateApi.update(
        {
          key: `${UiStateKey.EditorUiState}-${dataUuid}`,
          value: JSON.stringify(params),
          scope: UiStateScope.User,
        },
        ''
      )
    },
    [dataUuid, templateUuid, markdownType]
  )

  const updateGlobalEditorState = useCallback(
    (params: GlobalEditorUiState) => {
      UiStateApi.update(
        {
          key: UiStateKey.EditorUiState,
          value: JSON.stringify(params),
          scope: UiStateScope.User,
        },
        ''
      )
    },
    [editor]
  )

  const changeMarkdownType = useCallback(
    (type: MarkdownType) => {
      setMarkdownType(type)
      updateEditorState({ templateUuid, markdownType: type })
    },
    [templateUuid, markdownType]
  )

  const changeTemplateUuid = useCallback(
    (tempId: string) => {
      setTemplateUuid(tempId)
      updateEditorState({ templateUuid: tempId, markdownType })
    },
    [templateUuid, markdownType]
  )

  const changeEditor = useCallback(
    (editorType: EditorType) => {
      setEditor(editorType)
      updateGlobalEditorState({ editor: editorType })
    },
    [editor]
  )

  return {
    initialized,
    editor,
    changeEditor,
    changeTemplateUuid,
    markdownType,
    changeMarkdownType,
    templateUuid,
  }
}
