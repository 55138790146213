import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

export default class MultiLineTestVO extends DomainValueObjectBase<string> {
  constructor(_value: string) {
    if (!_value) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'Code value is required.',
      })
    }
    if (typeof _value !== 'string') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: `Text value(${_value}) must be string value.`,
      })
    }
    super(_value)
  }
}
