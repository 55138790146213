import React from 'react'
import { styled } from '@mui/system'
import Comment from '../Comment'
import { AllState } from '../../../store'
import { connect } from 'react-redux'

const Container = styled('div')({
  width: '100%',
  height: 'calc(100% - 3px)',
})

const InformationComment = ({ commentProps }) => {
  if (!commentProps) {
    return <></>
  }
  return (
    <Container>
      <Comment {...commentProps} />
    </Container>
  )
}

const mapStateToProps = (state: AllState) => ({
  commentProps: state.information.commentProps,
})

export default connect(mapStateToProps)(InformationComment)
