import { RowHeaderCell } from '../../../../containers/DataCell'
import { getWbsItemSearchConditionItemLabel } from '../utils'
import { WbsItemSearchConditionKey } from './WbsItemSearchCondition'

export interface SearchConditionProps {
  conditionKey: WbsItemSearchConditionKey
  value: any
  onChange: (value) => void
  colSpan?: number
}

const SearchCondition = (props: {
  conditionKey: WbsItemSearchConditionKey
  children: JSX.Element
}) => {
  const { conditionKey, children } = props
  return (
    <>
      <RowHeaderCell align="left">{toRowHeader(conditionKey)}</RowHeaderCell>
      {children}
    </>
  )
}

export default SearchCondition

export const toRowHeader = (key: WbsItemSearchConditionKey) => {
  return getWbsItemSearchConditionItemLabel({
    wbsItemSearchConditionKey: key,
  })
}
