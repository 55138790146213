import { plainToInstance } from 'class-transformer'
import { getLabel, parse } from '../../../lib/commons/i18nLabel'
import CustomEnumEntity from '../../entity/CustomEnumEntity'
import DomainEntityFactoryBase, {
  DomainEntityCreateParams,
  DomainEntityReconstractParams,
} from '../Base'

export interface CustomEnumFactoryCreateParams
  extends DomainEntityCreateParams {}
export interface CustomEnumFactoryReconstructParams
  extends DomainEntityReconstractParams {
  customEnumCode: string
  groupKeys: string[]
  values: {
    uuid: string
    lockVersion: number
    revision: string
    prevSiblingUuid: string | undefined
    groupUuid: string | undefined
    name: string
    nameI18n: string[]
    value: string
    validFrom: string | number
    validTo: string | number | undefined
    backgroundColor: string | undefined
    foregroundColor: string | undefined
    iconUrl: string | undefined
    createdAt: string | number
    updatedAt: string | number
  }[]
}

export default class CustomEnumFactory extends DomainEntityFactoryBase<CustomEnumEntity> {
  create(params: CustomEnumFactoryCreateParams): CustomEnumEntity {
    throw new Error('Method not implemented.')
  }
  reconstract(params: CustomEnumFactoryReconstructParams): CustomEnumEntity {
    return plainToInstance(
      CustomEnumEntity,
      {
        ...params,
        values: params.values.map(v => ({
          ...v,
          name: getLabel(parse(v.nameI18n)),
        })),
      },
      {
        excludeExtraneousValues: true,
      }
    )
  }
  reconstractList(
    params: CustomEnumFactoryReconstructParams[]
  ): CustomEnumEntity[] {
    return params.map(v => this.reconstract(v))
  }
}
