import React from 'react'
import { styled } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import ClearIcon from '@mui/icons-material/ClearRounded'
import AddIcon from '@mui/icons-material/AddRounded'
import { getLanguages, LanguageProps } from '../../../lib/functions/language'
import Select from '../editors/select/Select'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { I18nLabel } from '../../../lib/commons/i18nLabel'
import TextInput from '../editors/input/TextInput'

// Styles
const DialogContent = styled(MuiDialogContent)({
  width: '510px',
})
const Table = styled(MuiTable)({
  width: '100%',
  height: '100%',
})
const TableHead = styled(MuiTableHead)({
  backgroundColor: '#F0F0F0',
})
const Footer = styled('div')({
  paddingTop: 10,
  paddingLeft: 10,
  display: 'flex',
  alignItems: 'center',
})
const FooterMessage = styled('div')({
  color: 'red',
  marginLeft: 20,
})

interface Props extends WrappedComponentProps {
  open: boolean
  onClose: (labels) => void
  i18nLabels: I18nLabel[]
}

interface State {
  languages: LanguageProps[]
  i18nLabels: I18nLabel[]
  isNameEmpty: boolean
}

class I18nLabelDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      languages: [],
      i18nLabels: props.i18nLabels,
      isNameEmpty: false,
    }
  }

  async componentDidMount() {
    const response = await getLanguages()
    const languages: LanguageProps[] = response.json
    this.setState({ languages })
  }

  private close = () => {
    const i18nLabels = this.state.i18nLabels.filter(v => v.name)
    this.setState({ i18nLabels }, () => {
      this.props.onClose(this.state.i18nLabels)
    })
  }

  private addI18nLabel = () => {
    if (this.state.languages.length === this.state.i18nLabels.length) {
      return
    }
    const i18nLabels = this.state.i18nLabels
    i18nLabels.push({
      languageCode: this.state.languages.filter(
        v => !i18nLabels.map(l => l.languageCode).includes(v.standardCode)
      )[0].standardCode,
      name: '',
    })
    this.setState({
      i18nLabels,
      isNameEmpty: true,
    })
  }

  private removeI18nLabel = (index: number) => {
    const i18nLabels = this.state.i18nLabels.concat()
    i18nLabels.splice(index, 1)
    this.setState({
      i18nLabels,
      isNameEmpty: i18nLabels.some(v => !v.name),
    })
  }

  private nameChanged = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const i18nLabels = this.state.i18nLabels.concat()
    const i18nLabel = this.state.i18nLabels[index]
    i18nLabels[index] = {
      ...i18nLabel,
      name: event.target.value,
    }
    this.setState({
      i18nLabels,
      isNameEmpty: i18nLabels.some(v => !v.name),
    })
  }

  private languageChanged = (index: number, value: any) => {
    const i18nLabels = this.state.i18nLabels.concat()
    const i18nLabel = this.state.i18nLabels[index]
    i18nLabels[index] = {
      ...i18nLabel,
      languageCode: value,
    }
    this.setState({ i18nLabels })
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.close}>
        <DialogTitle>
          {this.props.intl.formatMessage({
            id: 'nameI18n.description',
          })}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {this.props.intl.formatMessage({ id: 'nameI18n.language' })}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {this.props.intl.formatMessage({ id: 'nameI18n.name' })}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.i18nLabels.map(
                  (i18nLabel: I18nLabel, index: number) => {
                    return (
                      <TableRow key={i18nLabel.languageCode}>
                        <TableCell>
                          <Select
                            fullWidth={true}
                            value={i18nLabel.languageCode}
                            onChange={e => {
                              this.languageChanged(index, e.target.value)
                            }}
                            options={this.state.languages.filter(
                              v =>
                                !this.state.i18nLabels
                                  .map(v => v.languageCode)
                                  .includes(v.standardCode) ||
                                i18nLabel.languageCode === v.standardCode
                            )}
                            getOptionValue={o => o.standardCode}
                            getOptionLabel={o =>
                              `${o.name} (${o.standardCode})`
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextInput
                            id={i18nLabel.languageCode}
                            variant="outlined"
                            size="small"
                            value={i18nLabel.name}
                            onChange={e => this.nameChanged(e, index)}
                          />
                        </TableCell>
                        <TableCell style={{ width: 10 }}>
                          <ClearIcon
                            color="action"
                            onClick={e => this.removeI18nLabel(index)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Footer>
            <AddIcon color="action" onClick={this.addI18nLabel} />
            {this.state.isNameEmpty && (
              <FooterMessage>
                {this.props.intl.formatMessage({
                  id: 'nameI18n.name.empty.message',
                })}
              </FooterMessage>
            )}
          </Footer>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.isNameEmpty}
            onClick={this.close}
            color="primary"
          >
            {this.props.intl.formatMessage({ id: 'dialog.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default injectIntl(I18nLabelDialog)
