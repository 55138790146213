import { ClickAwayListener, Paper, Popper } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { WrappedComponentProps } from 'react-intl'
import {
  DISPLAY_DATE_FORMAT_WITH_YEAR_DAY,
  DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
} from '../../../utils/date'
import ExclusiveToggleGroup from '../editors/toggle/ExclusiveToggleGroup'

export interface Props extends WrappedComponentProps {
  anchorEl: any
  currentFormat?: string
  onChangeFormat: (value: string) => void
}

const DATE_FORMAT_CANDIDATE = [
  DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  DISPLAY_DATE_FORMAT_WITH_YEAR_DAY,
]

const DateFormatChangePopper = (props: Props) => {
  const { anchorEl, currentFormat, onChangeFormat, intl } = props
  const [format, setFormat] = useState<string>(
    currentFormat || DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
  )

  useEffect(() => {
    if (currentFormat && currentFormat !== format) {
      setFormat(currentFormat)
    }
  }, [currentFormat])

  const dateCandidates = useMemo(() => {
    return Object.values(DATE_FORMAT_CANDIDATE).map(v => {
      return {
        value: v,
        label: intl.formatMessage({
          id: `dateformat.candidate.${v.toLowerCase()}`,
        }),
      }
    })
  }, [intl])

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement={'bottom'}
      disablePortal={true}
    >
      <Paper
        style={{
          margin: 5,
          padding: '5px 5px 0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ExclusiveToggleGroup
          value={format}
          options={dateCandidates}
          onChange={value => {
            setFormat(value)
            onChangeFormat(value)
          }}
        />
      </Paper>
    </Popper>
  )
}

export default DateFormatChangePopper
