import React from 'react'
import _ from 'lodash'
import { styled } from '@mui/system'
import Popover from '@mui/material/Popover'
import WbsItemSearchCondition, {
  SearchConditionProps,
  SearchFilter,
} from '../../WbsItemSearchConditions/WbsItemSearchCondition'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import objects from '../../../../../../utils/objects'

interface ComponentOwnProps {
  anchorEl?: any
  open: boolean
  onClose: () => void
  wbsItemSearchCondition: SearchConditionProps
  searchFilter: SearchFilter
  onChange: (searchFilter: SearchFilter) => Promise<void> | void
}

interface Props extends ComponentOwnProps {}

const SearchConditionEditorPopup: React.FC<Props> = ({
  open,
  anchorEl,
  onClose,
  wbsItemSearchCondition,
  searchFilter,
  onChange,
}: Props) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Editor>
        <TableContainer component={Paper}>
          <StyledTable size="small" aria-label="customized table">
            <TableBody>
              <WbsItemSearchCondition
                conditionKey={wbsItemSearchCondition}
                updateSearchFilter={params => {
                  const newSearchFilter = _.cloneDeep(searchFilter)
                  Object.keys(params).forEach(changedItemKey => {
                    objects.setValue(
                      newSearchFilter,
                      changedItemKey,
                      params[changedItemKey]
                    )
                  })
                  onChange(newSearchFilter)
                }}
                search={() => {
                  // do nothing
                }}
                searchFilter={searchFilter}
              />
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Editor>
    </Popover>
  )
}

const Editor = styled('div')({
  padding: 10,
})
const StyledTable = styled(Table)({
  width: 600,
  height: '100%',
})

export default SearchConditionEditorPopup
