import { useCallback, useMemo, useState } from 'react'

const useAnchorButton = (): {
  isOpen: boolean
  anchorEl: Element | undefined
  setAnchorEl: React.Dispatch<React.SetStateAction<Element | undefined>>
  handleClose: () => void
  toggleOpen: (event: React.MouseEvent<Element, MouseEvent>) => void
} => {
  const [anchorEl, setAnchorEl] = useState<Element>()
  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const handleClose = useCallback(() => setAnchorEl(undefined), [])
  const openPopper = useCallback(event => setAnchorEl(event.currentTarget), [])
  const toggleOpen = useCallback(
    e => {
      if (isOpen) {
        handleClose()
      } else {
        openPopper(e)
      }
    },
    [isOpen, handleClose, openPopper]
  )
  return { isOpen, anchorEl, setAnchorEl, handleClose, toggleOpen }
}

export default useAnchorButton
