import { TicketType } from '../../../../../lib/functions/ticket'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import { PropsForGetColumnDefs } from './common'
import { useGeneralColumnDefs } from './general'
import { useRefinementColumnDefs } from './refinement'
import { useRiskColumnDefs } from './risk'

export type Props = PropsForGetColumnDefs & {
  ticketType: TicketType | undefined
}

export const useColumnDefs = (props: Props): FlagxsColumnDef[] => {
  const riskColumnDef = useRiskColumnDefs(props)
  const refinementColumnDef = useRefinementColumnDefs(props)
  const generalColumnDefs = useGeneralColumnDefs(props)

  switch (props.ticketType) {
    case TicketType.RISK:
      return riskColumnDef
    case TicketType.REFINEMENT_NEW:
      return refinementColumnDef
    default:
      return generalColumnDefs
  }
}
