import { useCallback, useEffect, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../../utils/date'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { TicketListDetail } from '../../../../lib/functions/ticketList'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'

type StoredRiskPageState = {
  ticketList?: TicketListDetail
  workloadUnit: WorkloadUnit
  dateFormat: string
  rowHeight: ROW_HEIGHT
  toolbar?: ToolbarToggleValue
  aggregateField: AggregateField
}

export const usePageState = (
  functionUuid
): {
  initialized: boolean
  ticketList: TicketListDetail | undefined
  setTicketList: (_?: TicketListDetail) => void
  workloadUnit: WorkloadUnit
  setWorkloadUnit: (_: WorkloadUnit) => void
  dateFormat: string
  setDateFormat: (_: string) => void
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
  toolbar?: ToolbarToggleValue
  setToolbar: (_?: ToolbarToggleValue) => void
  aggregateField: AggregateField
  setAggregateField: (_: AggregateField) => void
} => {
  const [ticketList, setTicketList] = useState<TicketListDetail>()
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.HOUR
  )
  const [dateFormat, setDateFormat] = useState(
    DISPLAY_DATE_SHORT_FORMAT_WITH_DAY
  )
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [toolbar, setToolbar] = useState<ToolbarToggleValue>()
  const [initialized, setInitialized] = useState(false)
  const [aggregateField, setAggregateField] = useState<AggregateField>(
    AggregateField.WBS_ITEM_WORKLOAD
  )

  useEffect(() => {
    restorePageState()
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      ticketList,
      workloadUnit,
      dateFormat,
      rowHeight,
      toolbar,
      aggregateField,
    })
  }, [
    initialized,
    ticketList,
    workloadUnit,
    dateFormat,
    rowHeight,
    toolbar,
    aggregateField,
  ])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const payload: StoredRiskPageState = JSON.parse(stored.value)
      payload.ticketList && setTicketList(payload.ticketList)
      payload.workloadUnit && setWorkloadUnit(payload.workloadUnit)
      payload.dateFormat && setDateFormat(payload.dateFormat)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.toolbar && setToolbar(payload.toolbar)
      payload.aggregateField && setAggregateField(payload.aggregateField)
    } finally {
      setInitialized(true)
    }
  }, [])

  const rememberPageState = useCallback((value: StoredRiskPageState) => {
    uiStates.update(
      {
        key: UiStateKey.PageState,
        scope: UiStateScope.User,
        value: JSON.stringify(value),
      },
      functionUuid
    )
  }, [])

  return {
    initialized,
    ticketList,
    setTicketList,
    workloadUnit,
    setWorkloadUnit,
    dateFormat,
    setDateFormat,
    rowHeight,
    setRowHeight,
    toolbar,
    setToolbar,
    aggregateField,
    setAggregateField,
  }
}
