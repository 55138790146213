import { SlackWorkspace } from '../WorkspaceVO'

export type SlackUser = {
  uuid?: string
  userUuid?: string
  slackCollaborationWorkspaceUuid?: string
  slackWorkspaceId?: string
  slackWorkspaceName?: string
  slackUserId?: string
  slackUserName?: string
}

export const extractSlackWorkspace = (user: SlackUser): SlackWorkspace => ({
  uuid: user.slackCollaborationWorkspaceUuid,
  slackWorkspaceId: user.slackWorkspaceId,
  slackWorkspaceName: user.slackWorkspaceName,
})

export const fromSlackWorkspace = (workspace: SlackWorkspace): SlackUser => ({
  slackCollaborationWorkspaceUuid: workspace?.uuid,
  slackWorkspaceId: workspace?.slackWorkspaceId,
  slackWorkspaceName: workspace?.slackWorkspaceName,
})
