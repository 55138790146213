import { RowNode } from 'ag-grid-community'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import TicketAggregateInformation from '../../../containers/AggregateInformation/TicketAggregateInformation'
import { colorPalette } from '../../../style/colorPallete'
import ProgressReportButton from '../../ProjectPlanNew/components/Header/ProgressReportButton'
import { TicketListDetail } from '../../../../lib/functions/ticketList'
import { Collapse, Typography } from '@mui/material'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'
import { Button } from '../../../components/buttons'
import { intl } from '../../../../i18n'

type Props = {
  aggregateField: AggregateField
  workloadUnit: WorkloadUnit
  rowNodes?: RowNode[]
  projectUuid: string
  ticketList?: TicketListDetail
  open: boolean
  closeReport: (value: boolean) => void
}

const TicketsReport = ({
  aggregateField,
  workloadUnit,
  rowNodes,
  projectUuid,
  ticketList,
  open,
  closeReport,
}: Props) => {
  return (
    <Collapse
      in={open}
      sx={{
        minHeight: 'auto !important',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '4px',
          padding: '10px',
          background: colorPalette.monotone[0],
          margin: '10px 0 0',
          overflowX: 'auto',
          overflowY: 'hidden',
          minHeight: '47px',
        }}
      >
        <TicketAggregateInformation
          aggregateType={aggregateField}
          workloadUnit={workloadUnit}
          rowNodes={rowNodes}
        />
        <ProgressReportButton
          projectUuid={projectUuid}
          ticketListUuid={ticketList?.uuid}
          scheduledDate={ticketList?.wbsItem.scheduledDate}
        />
        <Button
          onClick={() => closeReport(false)}
          sx={{
            margin: '0 0 0 auto',
            color: colorPalette.monotone[5],
            background: 'transparent',
            cursor: 'pointer',
            '&:hover': { background: 'transparent' },
            minWidth: 'fit-content',
          }}
        >
          <Typography>
            {intl.formatMessage({ id: 'wbs.header.close.report' })}
          </Typography>
          <ClearRoundedIcon />
        </Button>
      </div>
    </Collapse>
  )
}

export default TicketsReport
