import { FC } from 'react'
import { ProjectDetail } from '../../../../lib/functions/project'
import { styled } from '@mui/material'
import { intl } from '../../../../i18n'
import {
  Header,
  HeaderContent,
} from '../../../components/headers/HeaderBar/PageHeader'
import { DashboardContent, DashboardRoot, DashboardTitle } from '../components'
import { ProjectReportConfig } from '../model/config'
import { SummaryInformation } from './SummaryInformation'

type Props = {
  project: ProjectDetail
} & ProjectReportConfig

export const Summary: FC<Props> = ({
  project,
  aggregateRoot,
  ...other
}: Props) => {
  return (
    <DashboardRoot>
      <Header sx={{ height: '36px', paddingLeft: '11px' }}>
        <HeaderContent>
          <DashboardTitle>
            {intl.formatMessage({ id: 'summary' })}
          </DashboardTitle>
        </HeaderContent>
      </Header>
      <DashboardContent>
        <ProgressGroupRoot>
          <SummaryInformation
            {...other}
            project={project}
            aggregateRoot={aggregateRoot}
          />
        </ProgressGroupRoot>
      </DashboardContent>
    </DashboardRoot>
  )
}

const ProgressGroupRoot = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})
