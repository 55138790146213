import { List } from 'immutable'
import { Epic, ofType } from 'redux-observable'
import { map, mergeMap } from 'rxjs/operators'
import { WbsItemStatus } from '../domain/entity/WbsItemEntity'
import { WbsItemTypeObject } from '../domain/value-object/WbsItemTypeVO'
import WbsItemApi from '../lib/functions/wbsItem'
import UiStateApi, { UiStateKey, UiStateScope } from '../lib/commons/uiStates'
import { Observable, Subscriber } from 'rxjs'
import API from '../lib/commons/api'
import Auth from '../lib/commons/auth'

enum WbsItemCommentType {
  COMMENTED_BY_ME = 'COMMENTED_BY_ME',
  MENTIONED_TO_ME = 'MENTIONED_TO_ME',
  COMMENTED_BY_OTHER = 'COMMENTED_BY_OTHER',
}

type WbsItem = {
  uuid: string
  code: string
  displayName: string
  status: WbsItemStatus
  type: WbsItemTypeObject
}
type Project = {
  uuid: string
  displayName: string
  iconUrl: string
}

export type WbsItemCommentUserSummary = {
  commentType: WbsItemCommentType
  latestCommentedAt: number
  project: Project
  parentWbsItem?: WbsItem
  wbsItem: WbsItem
}

type WbsItemCommentUserSummarySearchCondition = {
  searchText: string
}
type State = {
  hasNew: boolean
  lastOpenedAt: number | undefined
  searchCondition: WbsItemCommentUserSummarySearchCondition
  wbsItemCommentUserSummaries: List<WbsItemCommentUserSummary>
  filteredWbsItemCommentUserSummaries: List<WbsItemCommentUserSummary>
}

// Actions
enum ActionType {
  INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES = 'INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES',
  INITIALIZED_WBS_ITEM_COMMENT_USER_SUMMARIES = 'INITIALIZED_WBS_ITEM_COMMENT_USER_SUMMARIES',
  OPENED_WBS_ITEM_COMMENT_USER_SUMMARIES = 'OPENED_WBS_ITEM_COMMENT_USER_SUMMARIES',
  FILTER_WBS_ITEM_COMMENT_USER_SUMMARIES = 'FILTER_WBS_ITEM_COMMENT_USER_SUMMARIES',
  EDIT_WBS_ITEM_COMMENT_USER_SUMMARIES_SEARCH_CONDITION = 'EDIT_WBS_ITEM_COMMENT_USER_SUMMARIES_SEARCH_CONDITION',
  CONFIRM_WBS_ITEM_COMMENT_USER_SUMMARY = 'CONFIRM_WBS_ITEM_COMMENT_USER_SUMMARY',
  CONFIRMED_WBS_ITEM_COMMENT_USER_SUMMARY = 'CONFIRMED_WBS_ITEM_COMMENT_USER_SUMMARY',
  SET_WBS_ITEM_COMMENT_USER_SUMMARIES_LAST_OPENED_AT = 'SET_WBS_ITEM_COMMENT_USER_SUMMARIES_LAST_OPENED_AT',

  // Actions for Subscribers
  WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_APPEARED = 'WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_APPEARED',
  WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_DISAPPEARED = 'WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_DISAPPEARED',
}

export const initializeWbsItemCommentUserSummaries = (userUuid: string) => ({
  type: ActionType.INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES,
  userUuid,
})
const initializedWbsItemCommentUserSummaries = (
  wbsItemCommentUserSummaries: WbsItemCommentUserSummary[],
  lastOpenedAt: number | undefined
) => ({
  type: ActionType.INITIALIZED_WBS_ITEM_COMMENT_USER_SUMMARIES,
  wbsItemCommentUserSummaries,
  lastOpenedAt,
})
export const openedWbsItemCommentUserSummaries = () => ({
  type: ActionType.OPENED_WBS_ITEM_COMMENT_USER_SUMMARIES,
})
const setLastOpenedAt = (lastOpenedAt: number) => ({
  type: ActionType.SET_WBS_ITEM_COMMENT_USER_SUMMARIES_LAST_OPENED_AT,
  lastOpenedAt,
})
export const filterWbsItemCommentUserSummaries = (searchText: string) => ({
  type: ActionType.FILTER_WBS_ITEM_COMMENT_USER_SUMMARIES,
  searchText,
})
export const editWbsItemCommentUserSummariesSearchCondition = (
  searchText: string
) => ({
  type: ActionType.EDIT_WBS_ITEM_COMMENT_USER_SUMMARIES_SEARCH_CONDITION,
  searchText,
})
export const confirmWbsItemCommentUserSummary = (
  wbsItemCommentUserSummary: WbsItemCommentUserSummary
) => ({
  type: ActionType.CONFIRM_WBS_ITEM_COMMENT_USER_SUMMARY,
  wbsItemCommentUserSummary,
})
const confirmedWbsItemCommentUserSummary = (
  wbsItemCommentUserSummary: WbsItemCommentUserSummary
) => ({
  type: ActionType.CONFIRMED_WBS_ITEM_COMMENT_USER_SUMMARY,
  wbsItemCommentUserSummary,
})

// Actions for subscribers.
const upsertWbsItemCommentUserSummary = (
  userUuid: string,
  wbsItemCommentUserSummary: WbsItemCommentUserSummary
) => ({
  type: ActionType.WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_APPEARED,
  userUuid,
  wbsItemCommentUserSummary,
})
const deleteWbsItemCommentUserSummary = (
  userUuid: string,
  wbsItemUuid: string
) => ({
  type: ActionType.WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_DISAPPEARED,
  userUuid,
  wbsItemUuid,
})

// Epics
export const initializeWbsItemCommentUserSummariesEpic: Epic<
  any,
  any
> = action$ =>
  action$.pipe(
    ofType(ActionType.INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES),
    mergeMap(async action => {
      const [searchResponse, uiStateResponse] = await Promise.all([
        WbsItemApi.searchCommentUserSummaries({
          offset: 0,
          limit: 100,
          displayName: action.searchText,
        }),
        UiStateApi.get({
          key: UiStateKey.WbsItemCommentUserSummaryLastOpenedAt,
          scope: UiStateScope.User,
        }),
      ])

      return {
        wbsItemCommentUserSummaries: searchResponse.json.data,
        lastOpenedAt: uiStateResponse.json.value
          ? Number(uiStateResponse.json.value)
          : undefined,
      }
    }),
    map(result => {
      return initializedWbsItemCommentUserSummaries(
        result.wbsItemCommentUserSummaries,
        result.lastOpenedAt
      )
    })
  )
export const openedWbsItemCommentUserSummariesEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.OPENED_WBS_ITEM_COMMENT_USER_SUMMARIES),
    mergeMap(async action => {
      const now = new Date().getTime()
      await UiStateApi.update({
        key: UiStateKey.WbsItemCommentUserSummaryLastOpenedAt,
        scope: UiStateScope.User,
        value: '' + now,
      })

      return { lastOpenedAt: now }
    }),
    map(result => {
      return setLastOpenedAt(result.lastOpenedAt)
    })
  )
export const confirmWbsItemCommentUserSummaryEpic: Epic<any, any> = action$ =>
  action$.pipe(
    ofType(ActionType.CONFIRM_WBS_ITEM_COMMENT_USER_SUMMARY),
    mergeMap(async action => {
      await WbsItemApi.confirmCommentUserSummary({
        wbsItemUuid: action.wbsItemCommentUserSummary.wbsItem.uuid,
      })

      return { wbsItemCommentUserSummary: action.wbsItemCommentUserSummary }
    }),
    map(result => {
      return confirmedWbsItemCommentUserSummary(
        result.wbsItemCommentUserSummary
      )
    })
  )

// Subscribers
type NotificationBody = {
  wbsItemUuid: string
}
type Notification = {
  userUuid: string
  wbsItemCommentUserSummary: NotificationBody
}
const subscribeNotification =
  (eventName: string) =>
  (action): Observable<Notification> => {
    let subscriber: Subscriber<Notification>
    const observable = new Observable<Notification>(sub => {
      subscriber = sub
    })
    const tenant = Auth.getCurrentTenant()!
    if (!tenant) return observable
    API.notification.subscribe<NotificationBody>(
      `${tenant.tenantUuid}/${eventName}/${action.userUuid}`,
      wbsItemCommentUserSummary => {
        subscriber.next({
          userUuid: action.userUuid,
          wbsItemCommentUserSummary,
        })
      }
    )
    return observable
  }
export const subscribeWbsItemCommentUserSummaryNewCommentAppearedEpic: Epic<
  any,
  any
> = action$ =>
  action$.pipe(
    ofType(ActionType.INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES),
    mergeMap(
      subscribeNotification('WbsItemCommentUserSummaryNewCommentAppeared')
    ),
    mergeMap(async ({ userUuid, wbsItemCommentUserSummary }) => {
      const response = await WbsItemApi.getCommentUserSummary({
        wbsItemUuid: wbsItemCommentUserSummary.wbsItemUuid,
      })
      return {
        userUuid,
        wbsItemCommentUserSummary: response.json as WbsItemCommentUserSummary,
      }
    }),
    map(({ userUuid, wbsItemCommentUserSummary }) =>
      upsertWbsItemCommentUserSummary(userUuid, wbsItemCommentUserSummary)
    )
  )

export const subscribeWbsItemCommentUserSummaryNewCommentDisappearedEpic: Epic<
  any,
  any
> = action$ =>
  action$.pipe(
    ofType(ActionType.INITIALIZE_WBS_ITEM_COMMENT_USER_SUMMARIES),
    mergeMap(
      subscribeNotification('WbsItemCommentUserSummaryNewCommentDisappeared')
    ),
    map(({ userUuid, wbsItemCommentUserSummary }) =>
      deleteWbsItemCommentUserSummary(
        userUuid,
        wbsItemCommentUserSummary.wbsItemUuid
      )
    )
  )

// Reducers
const filterBySearchCondition = (
  wbsItemCommentUserSummaries: List<WbsItemCommentUserSummary>,
  searchCondition: WbsItemCommentUserSummarySearchCondition
): List<WbsItemCommentUserSummary> => {
  return wbsItemCommentUserSummaries.filter(value => {
    if (searchCondition.searchText) {
      return value.wbsItem.displayName.includes(searchCondition.searchText)
    }
    return true
  })
}
const hasNew = (
  wbsItemCommentUserSummaries: List<WbsItemCommentUserSummary>,
  lastOpenedAt: number | undefined
): boolean => {
  const latestSummary = wbsItemCommentUserSummaries.find(
    v => v.commentType !== WbsItemCommentType.COMMENTED_BY_ME
  )
  if (latestSummary && lastOpenedAt) {
    return latestSummary.latestCommentedAt > lastOpenedAt
  }
  if (latestSummary && !lastOpenedAt) {
    return true
  }
  return false
}
export const reducer = (
  state: State = {
    hasNew: false,
    lastOpenedAt: undefined,
    searchCondition: {
      searchText: '',
    },
    wbsItemCommentUserSummaries: List(),
    filteredWbsItemCommentUserSummaries: List(),
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.INITIALIZED_WBS_ITEM_COMMENT_USER_SUMMARIES: {
      const wbsItemCommentUserSummaries: List<WbsItemCommentUserSummary> = List(
        action.wbsItemCommentUserSummaries
      )
      const lastOpenedAt = action.lastOpenedAt
      return {
        ...state,
        hasNew: hasNew(wbsItemCommentUserSummaries, lastOpenedAt),
        lastOpenedAt,
        wbsItemCommentUserSummaries,
        filteredWbsItemCommentUserSummaries: wbsItemCommentUserSummaries,
      }
    }
    case ActionType.SET_WBS_ITEM_COMMENT_USER_SUMMARIES_LAST_OPENED_AT: {
      const lastOpenedAt = action.lastOpenedAt
      return {
        ...state,
        hasNew: hasNew(state.wbsItemCommentUserSummaries, lastOpenedAt),
        lastOpenedAt,
      }
    }
    case ActionType.FILTER_WBS_ITEM_COMMENT_USER_SUMMARIES: {
      return {
        ...state,
        filteredWbsItemCommentUserSummaries: filterBySearchCondition(
          state.wbsItemCommentUserSummaries,
          state.searchCondition
        ),
      }
    }
    case ActionType.CONFIRMED_WBS_ITEM_COMMENT_USER_SUMMARY: {
      const index = state.wbsItemCommentUserSummaries.findIndex(
        v => v.wbsItem.uuid === action.wbsItemCommentUserSummary.wbsItem.uuid
      )
      if (index >= 0) {
        const wbsItemCommentUserSummaries =
          state.wbsItemCommentUserSummaries.delete(index)
        return {
          ...state,
          hasNew: hasNew(wbsItemCommentUserSummaries, state.lastOpenedAt),
          wbsItemCommentUserSummaries,
          filteredWbsItemCommentUserSummaries: filterBySearchCondition(
            wbsItemCommentUserSummaries,
            state.searchCondition
          ),
        }
      }
      return state
    }
    case ActionType.EDIT_WBS_ITEM_COMMENT_USER_SUMMARIES_SEARCH_CONDITION: {
      return {
        ...state,
        searchCondition: {
          ...state.searchCondition,
          searchText: action.searchText,
        },
      }
    }
    case ActionType.WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_APPEARED: {
      const targetIndex = state.wbsItemCommentUserSummaries.findIndex(
        v => v.wbsItem.uuid === action.wbsItemCommentUserSummary.wbsItem.uuid
      )
      let wbsItemCommentUserSummaries = state.wbsItemCommentUserSummaries
      if (targetIndex < 0) {
        wbsItemCommentUserSummaries = state.wbsItemCommentUserSummaries.unshift(
          action.wbsItemCommentUserSummary
        )
      } else {
        wbsItemCommentUserSummaries = state.wbsItemCommentUserSummaries.update(
          targetIndex,
          () => action.wbsItemCommentUserSummary
        )
      }
      return {
        ...state,
        hasNew: hasNew(wbsItemCommentUserSummaries, state.lastOpenedAt),
        wbsItemCommentUserSummaries,
        filteredWbsItemCommentUserSummaries: filterBySearchCondition(
          wbsItemCommentUserSummaries,
          state.searchCondition
        ),
      }
    }
    case ActionType.WBS_ITEM_COMMENT_USER_SUMMARY_NEW_COMMENT_DISAPPEARED: {
      const targetIndex = state.wbsItemCommentUserSummaries.findIndex(
        v => v.wbsItem.uuid === action.wbsItemUuid
      )
      if (targetIndex >= 0) {
        const wbsItemCommentUserSummaries =
          state.wbsItemCommentUserSummaries.delete(targetIndex)
        return {
          ...state,
          hasNew: hasNew(wbsItemCommentUserSummaries, state.lastOpenedAt),
          wbsItemCommentUserSummaries,
          filteredWbsItemCommentUserSummaries: filterBySearchCondition(
            wbsItemCommentUserSummaries,
            state.searchCondition
          ),
        }
      }
      return state
    }
    default:
      return state
  }
}
