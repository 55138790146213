import { styled, TextField, TextFieldProps } from '@mui/material'
import {
  HeaderText,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  Title,
} from '.'
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { Button } from '../../../../components/buttons'
import { ProjectPlanSelect } from './ProjectPlanSelect'
import { ProgressReportByProcessPageConfig } from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'

type ProgressReportByProcessConfigDialogProps = {
  open: boolean
  onClose: () => void
  config: ProgressReportByProcessPageConfig
  updateConfig: (leafUuids: string[]) => void
  snapshotUuid: string
}

export const ProgressReportByProcessConfigDialog = ({
  open,
  onClose,
  config,
  updateConfig,
  snapshotUuid,
}: ProgressReportByProcessConfigDialogProps) => {
  const [leafUuids, setLeafUuids] = useState<string[]>(config.tableRows)

  const updateLeafUuid = useCallback((uuid: string, selected: boolean) => {
    setLeafUuids(prev => {
      const filtered = prev.filter(v => v !== uuid)
      if (selected) {
        return [...filtered, uuid]
      }
      return filtered
    })
  }, [])
  const onSubmit = useCallback(() => {
    updateConfig(leafUuids)
    onClose()
  }, [leafUuids, onClose, updateConfig])

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <Title>工程別進捗報告の設定変更</Title>
      </StyledDialogTitle>
      <StyledDialogContent>
        <GridWithHeader>
          <HeaderText>集計対象の工程</HeaderText>
          <GridWrapper>
            <ProjectPlanSelect
              snapshotUuid={snapshotUuid}
              selectedUuids={leafUuids}
              updateSelectedUuid={updateLeafUuid}
            />
          </GridWrapper>
        </GridWithHeader>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onSubmit} colorPattern="skyBlue">
          設定を変更する
        </Button>
        <Button onClick={onClose} colorPattern="monotone" variant="outlined">
          {intl.formatMessage({ id: 'dialog.cancel' })}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

const GridWrapper = styled('div')({
  width: '600px',
  height: '500px',
})

const GridWithHeader = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
})
