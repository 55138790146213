import { Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { ValidationError } from '../../../meta/validator'

const TooltipTitle = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const ErrorIcon = styled(ErrorOutlineOutlinedIcon)({
  fontSize: '11px',
  marginRight: '5px',
})

type Props = {
  title: string | undefined
  error?: ValidationError
  children: React.ReactElement<any, any>
  PopperComponent?: any
}

const DataCellTooltip = (props: Props) => {
  const { title, error } = props
  const renderTitle = (
    title: string | undefined,
    error: ValidationError | undefined
  ) => {
    if (error) {
      return (
        <TooltipTitle>
          <ErrorIcon />
          {error.getMessage()}
        </TooltipTitle>
      )
    }
    return title || ''
  }
  return (
    <Tooltip
      {...props}
      title={renderTitle(title, error)}
      placement="bottom-start"
      PopperProps={{
        style: {
          zIndex: 1200,
        },
      }}
    />
  )
}

export default DataCellTooltip
