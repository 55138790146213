import { styled } from '@mui/system'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material'
import { ColorPalette, ProgressReportGraphConfig } from '../..'
import { getAggregateColumnGroupLabel } from '../../utils'
import { useCallback, useState } from 'react'
import { WbsItemTypeForWbsProgressLog } from '../../../../../lib/functions/wbsProgressLog'
import {
  BackgroundColor,
  FontSize,
  FontWeight,
} from '../../../../../styles/commonStyles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import LegendItem from './LegendItem'
import SelectColorPalette from './SelectColorPalette'

const RootDiv = styled('div')({
  display: `flex`,
  width: '100%',
  flexDirection: 'column',
})
const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '& .MuiAccordionDetails-root': {
    padding: 0,
  },
})
const TypeName = styled('div')({
  flexGrow: 1,
})
const StyledAccordionSummary = styled(AccordionSummary)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  fontSize: FontSize.SMALL,
  fontWeight: FontWeight.BOLD,
  minHeight: '23px !important',
  backgroundColor: BackgroundColor.GREY,
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiIconButton-root': {
    padding: '0 6px',
  },
})
const IconStyle = {
  fontSize: '16px !important',
}
const StyledCloseRoundedIcon = styled(CloseRoundedIcon)(IconStyle)
const StyledExpandMoreRoundedIcon = styled(ExpandMoreRoundedIcon)(IconStyle)
const BodyDiv = styled('div')({
  padding: '6px 0',
})

interface LegendGroupProps {
  type: WbsItemTypeForWbsProgressLog
  config: ProgressReportGraphConfig[]
  onChange: (newConfig: ProgressReportGraphConfig[]) => void
  onRemove: (removed: ProgressReportGraphConfig[]) => void
  colorPalettes: ColorPalette[]
  changeColorPalette: (curr: ColorPalette, next: ColorPalette) => void
}

const LegendGroup = ({
  type,
  config,
  onChange,
  onRemove,
  colorPalettes,
  changeColorPalette,
}: LegendGroupProps) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const onChangeColorPalette = useCallback(
    (prevPalette: ColorPalette, palette: ColorPalette) => {
      const newConfigs = [
        ...config.map((oldConfig, i) => ({
          ...oldConfig,
          color: palette[i],
        })),
      ]
      onChange(newConfigs)
      changeColorPalette(prevPalette, palette)
    },
    [config, onChange, changeColorPalette]
  )
  return (
    <RootDiv>
      <StyledAccordion
        expanded={expanded}
        onChange={event => {
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <StyledAccordionSummary
          expandIcon={
            <StyledExpandMoreRoundedIcon
              onClick={() => setExpanded(!expanded)}
            />
          }
        >
          <>
            <TypeName>{type.name}</TypeName>
            <SelectColorPalette
              colorPalettes={colorPalettes}
              currentPalette={config.map(c => c.color)}
              onChangeColorPalette={onChangeColorPalette}
            />
            <IconButton onClick={() => onRemove(config)}>
              <StyledCloseRoundedIcon />
            </IconButton>
          </>
        </StyledAccordionSummary>
        <AccordionDetails>
          <BodyDiv>
            {config.map((graphConfig, index) => {
              const aggregateColumnGroup = graphConfig.key.aggregateColumnGroup
              return (
                <LegendItem
                  key={index}
                  color={graphConfig.color}
                  title={getAggregateColumnGroupLabel(aggregateColumnGroup)}
                  config={graphConfig}
                  onChange={(newConfig: ProgressReportGraphConfig) =>
                    onChange([newConfig])
                  }
                />
              )
            })}
          </BodyDiv>
        </AccordionDetails>
      </StyledAccordion>
    </RootDiv>
  )
}

export default LegendGroup
