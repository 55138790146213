import {
  ChartConfigSpec,
  formatDate,
  VerticalBorder,
  ChartApi,
  ChartContext,
  ChartSpec,
} from '../index'
import {
  ChartConfigurationBase,
  DataPerDim,
  GraphConfigurationBase,
  ReportDataSeries,
  ViewConfigurationBase,
} from '../../../../../lib/functions/report'
import * as d3 from 'd3'
import { Color, FontSize } from '../../../../../styles/commonStyles'
import moment from 'moment'
import { StackedBarLegendWrapper } from './Legend'

const style = {
  fontSize: FontSize.SMALL,
  minBarWidth: 12,
  maxBarWidth: 36,
  maxBarInterval: 2,
  minBarPadding: 2,
  barInterval: 0,
}

export abstract class StackedBarChartConfigSpec<K> extends ChartConfigSpec<
  K,
  StackedBarChartConfiguration,
  StackedBarGraphConfiguration
> {}

export type StackedBarChartKey = {
  key: string
  label: string
}
export interface StackedBarChartConfiguration
  extends ChartConfigurationBase<StackedBarGraphConfiguration> {
  displayKeys: StackedBarChartKey[]
}
export interface StackedBarGraphConfiguration
  extends GraphConfigurationBase<any> {
  color: { [key: string]: string }
  borderColor: { [key: string]: string }
}

export default class StackedBarGraphSpec<
  K,
  D,
  V extends ViewConfigurationBase
> extends ChartSpec<
  K,
  D,
  V,
  StackedBarChartConfiguration,
  StackedBarGraphConfiguration
> {
  getBarWidth = (
    data: ReportDataSeries<K, D>[],
    chartConfig: StackedBarChartConfiguration,
    ctx: ChartContext
  ): number => {
    const tickWidth = Math.floor(ctx.width / ctx.xNum)
    const countKeys = data.length
    const naiveWidth = Math.floor(
      (tickWidth -
        (2 * style.minBarPadding + (countKeys - 1) * style.barInterval)) /
        countKeys
    )
    if (naiveWidth < style.minBarWidth) {
      return style.minBarWidth
    }
    if (naiveWidth > style.maxBarWidth) {
      return style.maxBarWidth
    }
    return naiveWidth
  }
  getBarPadding = (
    data: ReportDataSeries<K, D>[],
    chartConfig: StackedBarChartConfiguration,
    ctx: ChartContext,
    barWidth: number
  ): number => {
    const tickWidth = Math.floor(ctx.width / ctx.xNum)
    const countKeys = data.length
    return Math.floor((tickWidth - barWidth * countKeys) / 2)
  }
  getMinTickWidth = (
    data: ReportDataSeries<K, D>[],
    chartConfig: StackedBarChartConfiguration
  ): number => {
    const countKeys = data.length
    return (
      countKeys * style.minBarWidth +
      2 * style.minBarPadding +
      (countKeys - 1) * style.barInterval
    )
  }
  drawGraph = (
    data: [],
    ctx: ChartContext,
    api: ChartApi<
      K,
      D,
      V,
      StackedBarChartConfiguration,
      StackedBarGraphConfiguration
    >,
    viewConfig: V,
    chartConfig: StackedBarChartConfiguration
  ): void => {
    this.drawBar(data, ctx, api, viewConfig, chartConfig)
  }

  private drawBar = (
    dataSeries: ReportDataSeries<K, D>[],
    ctx: ChartContext,
    api: ChartApi<
      K,
      D,
      V,
      StackedBarChartConfiguration,
      StackedBarGraphConfiguration
    >,
    viewConfig: V,
    chartConfig: StackedBarChartConfiguration
  ) => {
    const displayKeys = chartConfig.displayKeys
    const xScaleData = (point: DataPerDim<D>) => {
      return ctx.xScale(new Date(point.dimTime)) || 0
    }
    const yScaleData = (d: DataPerDim<D>, key: StackedBarChartKey) => {
      const keysPrev = displayKeys.slice(0, displayKeys.indexOf(key) + 1)
      return (
        ctx.yScale(
          this.dataManager.getValue(
            d,
            viewConfig,
            keysPrev.map(k => k.key)
          )
        ) || 0
      )
    }
    const getBarHeight = (d: DataPerDim<D>, key: StackedBarChartKey) => {
      return (
        ctx.height -
        (ctx.yScale(this.dataManager.getValue(d, viewConfig, [key.key])) ||
          ctx.height)
      )
    }
    const barWidth = this.getBarWidth(dataSeries, chartConfig, ctx)
    const barPadding = this.getBarPadding(
      dataSeries,
      chartConfig,
      ctx,
      barWidth
    )
    dataSeries.forEach((data, index) => {
      for (let i = 0; i < displayKeys.length; i++) {
        const currKey = displayKeys[i]
        const prevKey = i > 0 ? displayKeys[i - 1] : undefined
        const graphConfig = this.chartConfigSpec.getGraphConfig(
          chartConfig,
          data.key
        )
        const color = (graphConfig?.color || {})[currKey.key] || Color.MAIN
        const borderColor =
          (graphConfig?.borderColor || {})[currKey.key] || 'transparent'
        ctx.chart
          .selectAll()
          .data(data.value)
          .enter()
          .append('rect')
          .attr('x', d => xScaleData(d) + barPadding)
          .attr('width', barWidth)
          .style('fill', color)
          .style('stroke', borderColor)
          .attr('y', ctx.height)
          .attr('height', 0)
          .on('mouseover', (e, d) => {
            api.showTooltip(
              this.dataManager.getFormattedValue(d, viewConfig, currKey.key),
              e
            )
          })
          .on('mouseout', e => {
            api.closeTooltip(e.currentTarget)
          })
          .on('mousemove', e => {
            const pos = d3.pointer(e, document.getElementById('chart')!)
            api.moveTooltip([pos[0] - 20, pos[1] - 30])
          })
          .transition()
          .attr('y', d => yScaleData(d, currKey))
          .attr('height', d => getBarHeight(d, currKey))

        ctx.chart
          .selectAll()
          .data(data.value)
          .enter()
          .append('text')
          .attr('x', d => xScaleData(d) + barWidth / 2 + barPadding)
          .attr('y', d => yScaleData(d, currKey) + 14)
          .text(d => {
            const barHeight = getBarHeight(d, currKey)
            if (barHeight > 15) {
              return this.dataManager.getFormattedValue(
                d,
                viewConfig,
                currKey.key
              )
            }
            return ''
          })
          .style('fill', 'white')
          .style('font-size', style.fontSize)
          .style('text-anchor', 'middle')
      }
      const lastKey = displayKeys[displayKeys.length - 1]
      ctx.chart
        .selectAll()
        .data(data.value)
        .enter()
        .append('text')
        .attr('x', d => xScaleData(d) + barWidth / 2 + barPadding)
        .attr('y', d => yScaleData(d, lastKey) - 5)
        .text(d => this.dataManager.getFormattedValue(d, viewConfig))
        .on('mouseover', (e, d) => {
          api.showTooltip(
            `[${formatDate(
              d.dimTime,
              viewConfig.timeGrain
            )}] ${this.dataManager.getFormattedValue(d, viewConfig)}`,
            e,
            -40,
            -30
          )
        })
        .on('mouseout', e => {
          api.closeTooltip(e.currentTarget)
        })
        .style('fill', 'black')
        .style('font-size', style.fontSize)
        .style('text-anchor', 'middle')
    })
  }

  getYmax(
    data: ReportDataSeries<K, D>[],
    viewConfig: V,
    chartConfig: StackedBarChartConfiguration
  ): number {
    return data.reduce(
      (max, curr) =>
        Math.max(
          max,
          curr.value.reduce(
            (acc, curr) =>
              Math.max(acc, this.dataManager.getValue(curr, viewConfig)),
            0
          )
        ),
      0
    )
  }

  getVerticalBorders = (): VerticalBorder[] => {
    return [
      {
        x: moment().valueOf(),
        label: moment().format('YYYY/MM/DD'),
        style: {
          color: Color.FLAGXS_RED,
        },
      },
    ]
  }

  getLegend(
    data: ReportDataSeries<K, D>[],
    chartConfig: StackedBarChartConfiguration,
    api: ChartApi<
      K,
      D,
      V,
      StackedBarChartConfiguration,
      StackedBarGraphConfiguration
    >,
    onChangeConfig: (newConfig: StackedBarChartConfiguration) => void
  ): JSX.Element {
    const onChange = (newConfig: StackedBarChartConfiguration) => {
      onChangeConfig(newConfig)
      api.renderChart()
    }
    return (
      <StackedBarLegendWrapper
        data={data}
        dataManager={this.dataManager}
        chartConfigSpec={this.chartConfigSpec}
        chartConfig={chartConfig}
        onChangeConfig={onChange}
      />
    )
  }
}
