import { intl } from '../../../../i18n'
import {
  Header,
  HeaderContent,
} from '../../../components/headers/HeaderBar/PageHeader'
import { ResourceBalance } from './ResourceBalance'
import { ProjectDetail } from '../../../../lib/functions/project'
import { Productivity } from './Productivity'
import { ResourceDashboardParameter } from '../hooks/useResourceDashboardParameter'
import { DashboardContent, DashboardRoot, DashboardTitle } from '../components'
import { WorkReport } from './WorkReport'
import { ProjectReportConfig } from '../model/config'

type ResourceDashboardProps = {
  project: ProjectDetail
} & Pick<
  ProjectReportConfig,
  'aggregateRoot' | 'aggregateTarget' | 'workloadUnit'
>

export const ResourceDashboard = ({
  project,
  aggregateRoot,
  aggregateTarget,
  workloadUnit,
}: ResourceDashboardProps) => {
  return (
    <DashboardRoot>
      <Header sx={{ height: '36px', paddingLeft: '11px' }}>
        <HeaderContent>
          <DashboardTitle>
            {intl.formatMessage({ id: 'resourceDashboard' })}
          </DashboardTitle>
        </HeaderContent>
      </Header>
      <DashboardContent>
        <ResourceBalance project={project} aggregateTarget={aggregateTarget} />
        <Productivity
          project={project}
          aggregateRoot={aggregateRoot}
          aggregateTarget={aggregateTarget}
          workloadUnit={workloadUnit}
        />
        <WorkReport project={project} />
      </DashboardContent>
    </DashboardRoot>
  )
}
