import { useCallback, useState } from 'react'
import { ProjectReportVO } from '../../../../domain/value-object/ProjectReportVO'
import { IconButton, styled } from '@mui/material'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import { ProjectSummaryWidget } from './ProjectSummaryWidget'
import { colorPalette } from '../../../style/colorPallete'
import { ProjectProgressWidget } from './ProjectProgressWidget'
import { EnterpriseProjectReportConfig } from '../model/config'
import { ProjectResourceWidget } from './ProjectResourceWidget'
import { ProjectProductivityWidget } from './ProjectProductitityWidget'
import projectDefaultIcon from '../../../../assets/project_icon_default.svg'
import { ProjectProfitLossWidget } from './ProjectProfitLossWidget'
import { ProjectRiskWidget } from './ProjectRiskWidget'
import { EnterpriseProjectReportMenu } from './EnterpriseProjectReportMenu'

type AccordionProps = {
  open: boolean
}

const EnterpriseProjectReportRoot = styled('div')(
  ({ open }: AccordionProps) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colorPalette.monotone[1],
    border: `1px solid ${colorPalette.monotone[2]}`,
    borderRadius: '4px',
    minHeight: open ? '400px' : '100px',
    transition: 'height .3s',
  })
)

const EnterpriseProjectReportCommonArea = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '200px',
  height: '100%',
})

const EnterpriseProjectReportMainArea = styled('div')(
  ({ open }: AccordionProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    padding: '0 16px',
    position: 'relative',
    height: open ? '360px' : '100px',
    width: '100%',
  })
)

const EnterpriseProjectReportMainCollapsed = styled('div')(
  ({ open }: AccordionProps) => ({
    position: 'absolute',
    display: open ? 'none' : 'flex',
    top: '50%',
    transform: 'translateY(-50%)',
  })
)

const EnterpriseProjectReportMainExpanded = styled('div')(
  ({ open }: AccordionProps) => ({
    flexDirection: 'column',
    gap: '16px',
    position: 'absolute',
    display: open ? 'flex' : 'none',
    top: '50%',
    transform: 'translateY(-50%)',
  })
)

const EnterpriseProjectReportWidgetRow = styled('div')({
  display: 'flex',
  gap: '16px',
})

const EnterpriseProjectReportMenuArea = styled('div')({
  display: 'flex',
  height: '100%',
  padding: '6px',
})

const ToggleIcon = styled(KeyboardArrowRightRoundedIcon)(
  ({ open }: AccordionProps) => ({
    transform: `rotate(${open ? '90deg' : '0deg'})`,
    transition: 'transform .3s',
  })
)

const StyledProjectIcon = styled('img')({
  width: '24px',
  height: '24px',
})

type ProjectIconProps = { iconUrl: string | undefined }
const ProjectIcon = ({ iconUrl }: ProjectIconProps) => {
  if (!iconUrl) {
    return <StyledProjectIcon src={projectDefaultIcon} />
  }
  return <StyledProjectIcon src={iconUrl} />
}

const ProjectNameWithIcon = styled('div')({
  display: 'flex',
  gap: '4px',
})

const ProjectName = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
})

type EnterpriseProjectReportRowProps = {
  report: ProjectReportVO
  config: EnterpriseProjectReportConfig
}

export const EnterpriseProjectReportRow = ({
  report: { basic, progress, resource, profitLoss, risk },
  config,
}: EnterpriseProjectReportRowProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])
  return (
    <EnterpriseProjectReportRoot open={open}>
      <EnterpriseProjectReportCommonArea>
        <IconButton onClick={toggle}>
          <ToggleIcon open={open} />
        </IconButton>
        <ProjectNameWithIcon>
          <ProjectIcon iconUrl={basic.iconUrl} />
          <ProjectName>{basic.displayName}</ProjectName>
        </ProjectNameWithIcon>
      </EnterpriseProjectReportCommonArea>
      <EnterpriseProjectReportMainArea open={open}>
        <EnterpriseProjectReportMainCollapsed open={open}>
          <ProjectSummaryWidget
            scheduledStartDate={basic.scheduledStartDate}
            scheduledEndDate={basic.scheduledEndDate}
            {...progress}
            {...resource}
            {...profitLoss}
            {...risk}
            {...config}
          />
        </EnterpriseProjectReportMainCollapsed>
        <EnterpriseProjectReportMainExpanded open={open}>
          <EnterpriseProjectReportWidgetRow>
            <ProjectProgressWidget {...basic} {...progress} {...config} />
            <ProjectProfitLossWidget {...profitLoss} />
          </EnterpriseProjectReportWidgetRow>
          <EnterpriseProjectReportWidgetRow>
            <ProjectResourceWidget ac={progress.ac} {...resource} />
            <ProjectProductivityWidget {...progress} {...config} />
            <ProjectRiskWidget {...risk} />
          </EnterpriseProjectReportWidgetRow>
        </EnterpriseProjectReportMainExpanded>
      </EnterpriseProjectReportMainArea>
      <EnterpriseProjectReportMenuArea>
        <EnterpriseProjectReportMenu projectCode={basic.code} />
      </EnterpriseProjectReportMenuArea>
    </EnterpriseProjectReportRoot>
  )
}
