import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popper,
} from '@mui/material'
import { UserDetail } from '../../../lib/functions/user'
import { intl } from '../../../i18n'
import store from '../../../store'
import { showAlert } from '../../../store/globalAlert'
import { BackgroundColor, Color } from '../../../styles/commonStyles'
import { open } from '../../router'

type Props = {
  openMenu: boolean
  anchorEl: Element | undefined
  loginUser?: UserDetail
}

const UserMenu = ({ openMenu, anchorEl, loginUser }: Props) => {
  return (
    <Popper
      open={openMenu}
      anchorEl={anchorEl}
      placement={'bottom-start'}
      modifiers={[{ name: 'offset', options: { offset: [0, 20] } }]}
      sx={{
        backgroundColor: BackgroundColor.WHITE,
        boxShadow:
          'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
        borderRadius: '4px',
        zIndex: 1300,
        minWidth: '200px',
      }}
    >
      <List sx={{ padding: '10px' }}>
        <ListItem sx={{ padding: '0px' }}>
          <ListItemButton
            sx={{ borderRadius: '4px' }}
            onClick={event => {
              open(`/userEdit/${loginUser?.code}`, event)
            }}
          >
            <ListItemText
              sx={{ color: Color.MONOTONE }}
              primary={intl.formatMessage({ id: 'menu.userInformation' })}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ padding: '0px' }}>
          <ListItemButton
            sx={{ borderRadius: '4px' }}
            onClick={event => {
              open('/changePassword', event)
            }}
          >
            <ListItemText
              sx={{ color: Color.MONOTONE }}
              primary={intl.formatMessage({ id: 'changePassword' })}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ padding: '0px' }}>
          <ListItemButton
            sx={{ borderRadius: '4px' }}
            onClick={() => {
              store.dispatch(
                showAlert({
                  message: intl.formatMessage({ id: 'menu.confirmLogout' }),
                  action: () => open('/loggedout'),
                  cancel: () => {
                    // Close dialog
                  },
                })
              )
            }}
          >
            <ListItemText
              sx={{ color: Color.MONOTONE }}
              primary={intl.formatMessage({ id: 'menu.account.logout' })}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Popper>
  )
}

export default UserMenu
