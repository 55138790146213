import React from 'react'
import { styled } from '@mui/system'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'

const Button = styled(BaseButton)({
  color: '#888888',
  '&:hover': {
    background: '#FFFFFF',
    border: '1px solid #1D85B4',
    boxShadow: 'none',
  },
  borderRadius: '8px',
  border: '1px solid #D9DCDE',
  background: '#FFFFFF',
  height: '16px',
  font: 'normal normal normal 10px/2px Hiragino Sans',
  letterSpacing: '0.5px',
  minWidth: '41px',
}) as React.FC<BaseButtonProps>

type Props = WrappedComponentProps & BaseButtonProps

const DeleteButton = (props: Props) => {
  return (
    <Button {...props}>
      {props.intl.formatMessage({ id: 'dialog.delete' })}
    </Button>
  )
}

export default injectIntl(DeleteButton)
