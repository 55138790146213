import React from 'react'
import { styled } from '@mui/system'
import I18nDialog from '../../../../../../../components/dialogs/I18nLabelDialog'
import LanguageIcon from '@mui/icons-material/LanguageRounded'
import { GridApi, RowNode } from 'ag-grid-community'
import { RowData } from '../../../../../../BulkSheet/RowDataManager/rowDataManager'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import objects from '../../../../../../../../utils/objects'
import { I18n, I18nLabel } from '../../../../../../../../lib/commons/i18nLabel'

// Styles
const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  overflowX: 'auto',
  justifyContent: 'space-between',
})
const Label = styled('p')({
  height: '100%',
  margin: 0,
})

interface Props {
  value: string
  node: RowNode
  api: GridApi
  data: RowData

  field: string
  uiMeta: FunctionProperty
}

interface State {
  i18nLabels: I18nLabel[]
  open: boolean
}

class I18nLabelCellRenderer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const i18n: I18n = objects.getValue(
      this.props.data,
      `${this.props.field}I18n`
    )
    this.state = {
      i18nLabels: i18n ? i18n.labels : [],
      open: false,
    }
  }

  private openDialog = event => {
    this.setState({ open: true })
  }

  private closed = (labels: I18nLabel[]) => {
    this.setState({
      open: false,
      i18nLabels: labels,
    })
    const data = this.props.data
    data.isEdited = true
    data[`${this.props.field}I18n`].labels = labels
    this.props.node.setData(data)
  }

  render() {
    return (
      <RootDiv>
        <Label>{this.props.value}</Label>
        {this.state.open && (
          <I18nDialog
            open={this.state.open}
            onClose={this.closed}
            i18nLabels={this.state.i18nLabels}
          />
        )}
        <LanguageIcon color="action" onClick={this.openDialog} />
      </RootDiv>
    )
  }
}

export default I18nLabelCellRenderer
