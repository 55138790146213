import api, { APIResponse } from '../../../lib/commons/api'
import {
  UnitPricePerUserCreateInput,
  UnitPricePerUserDeleteInput,
  UnitPricePerUserUpdateInput,
} from '../UnitPricePerUsers/UnitPricePerUsers'

export const fetchUnitPricePerProjectPartners = async (
  projectUuid: string
): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/unit_prices/users/projects', {
    projectUuid,
  })
}

export interface UnitPricePerProjectPartnerCrudParams {
  projectUuid: string
  added: UnitPricePerUserCreateInput[]
  edited: UnitPricePerUserUpdateInput[]
  deleted: UnitPricePerUserDeleteInput[]
}

export const crudUnitPricePerProjectPartners = async (
  params: UnitPricePerProjectPartnerCrudParams
): Promise<APIResponse> => {
  return api.functional.request(
    'POST',
    '/api/v1/unit_prices/users/projects/batch',
    params
  )
}
