import React from 'react'
import { TableData } from '.'
import { StyledTableRow } from '../../DataCell'
import { ValidationError } from '../../meta/validator'
import { CellDef } from '../../meta/ViewMeta/SingleSheetViewMeta'
import FormCell from './Cells'

export interface DataRowProps<D extends TableData> {
  key: string
  data: D
  initialData: D
  cellDefs: CellDef[]
  onCellChange: (value: any, cellDef: CellDef) => void
  setCellError?: (error: ValidationError | undefined, celldef: CellDef) => void
}

const DataRow = React.memo(<D extends TableData>(props: DataRowProps<D>) => {
  const { key, data, cellDefs, onCellChange, setCellError, initialData } = props
  return (
    <StyledTableRow key={key}>
      {cellDefs.map(def => (
        <FormCell
          value={def.valueGetter(data)}
          initialValue={def.valueGetter(initialData)}
          key={def.cellId}
          data={data}
          cellDef={def}
          colSpan={2 * def.cellPosition.size - 1}
          onChange={(value: any) => onCellChange(value, def)}
          setError={(error: ValidationError | undefined) =>
            setCellError && setCellError(error, def)
          }
        />
      ))}
    </StyledTableRow>
  )
})

export default DataRow
