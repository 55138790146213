import { DialogActions, DialogTitle, Paper, PaperProps } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Draggable from 'react-draggable'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import TemplateList from '../../containers/DescriptionTemplate/TemplateList'
import { EditorType } from '../editors/multiline-text/MultilineTextEditor'

// Styles
const DialogContent = styled(MuiDialogContent)({
  minHeight: '375px',
  width: '600px',
})

interface Props extends WrappedComponentProps {
  open: boolean
  externalId: string
  onClose: () => void
  editor?: EditorType
}

const DraggablePaper = (props: PaperProps) => {
  return (
    <Draggable
      handle="#template-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

class TemplateDialog extends React.Component<Props> {
  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        PaperComponent={DraggablePaper}
        aria-labelledby="template-dialog-title"
      >
        <DialogTitle id="template-dialog-title">
          {this.props.intl.formatMessage({ id: 'template' })}
        </DialogTitle>
        <DialogContent>
          <TemplateList
            externalId={this.props.externalId}
            editor={this.props.editor}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="primary">
            {this.props.intl.formatMessage({ id: 'dialog.close' })}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default injectIntl(TemplateDialog)
