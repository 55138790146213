import { Typography } from '@mui/material'
import { Button } from '../../../../components/buttons'
import {
  dateTermToViewConfig,
  openProgressReport,
} from '../../../ProgressReport'
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded'
import { intl } from '../../../../../i18n'
import { DateTerm } from '../../../../../utils/date'

type Props = {
  projectUuid: string
  rootWbsItemUuid?: string
  ticketListUuid?: string
  scheduledDate?: DateTerm
}

const ProgressReportButton = ({
  projectUuid,
  rootWbsItemUuid,
  ticketListUuid,
  scheduledDate,
}: Props) => {
  return (
    <Button
      sx={{
        margin: '0 5px 0 15px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 'fit-content',
      }}
      onClick={() => {
        const searchCondition = rootWbsItemUuid
          ? {
              rootWbsItemUuid: rootWbsItemUuid,
            }
          : {
              ticketListUuid: ticketListUuid,
            }
        const viewConfig = dateTermToViewConfig(scheduledDate)
        openProgressReport(projectUuid, searchCondition, viewConfig)
      }}
      variant="outlined"
      colorPattern="monotone"
    >
      <TimelineRoundedIcon sx={{ height: '16px', width: '16px' }} />
      <Typography>
        {intl.formatMessage({ id: 'wbs.header.progressReport' })}
      </Typography>
    </Button>
  )
}

export default ProgressReportButton
