import React, { useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import ClearIcon from '@mui/icons-material/ClearRounded'
import AddIcon from '@mui/icons-material/AddRounded'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import TextInput from '../../../../../components/editors/input/TextInput'
import { Button } from '../../../../../components/buttons'
import { generateUuid } from '../../../../../../utils/uuids'
import { WbsItemAdditionalPropertySelectOption } from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'

type Props = {
  open: boolean
  inputItems: WbsItemAdditionalPropertySelectOption[]
  onSubmit: (newSelectOptions: WbsItemAdditionalPropertySelectOption[]) => void
  onClose: () => void
} & WrappedComponentProps
const SelectOptionEditor = React.memo(
  ({
    uuid,
    index,
    selectOption,
    onChangeInputItem,
    deleteInputItem,
  }: {
    uuid: string
    index: number
    selectOption: string
    onChangeInputItem: (index: number, newSelectOption: string) => void
    deleteInputItem: (index: number) => void
  }) => {
    return (
      <TableRow key={uuid}>
        <TableCell>
          <TextInput
            id={uuid}
            variant="outlined"
            size="small"
            fullWidth={true}
            value={selectOption}
            onChange={e => onChangeInputItem(index, e.target.value)}
          />
        </TableCell>
        <TableCell style={{ width: 10 }}>
          <ClearIcon color="action" onClick={e => deleteInputItem(index)} />
        </TableCell>
      </TableRow>
    )
  }
)
export const MultiInputDialog = injectIntl(
  ({ open, inputItems, onSubmit: _onSubmit, onClose, intl }: Props) => {
    const [items, setItems] = useState(inputItems)
    const onSubmit = useCallback(() => {
      _onSubmit(items)
    }, [_onSubmit, items])
    const onChangeInputItem = useCallback(
      (index, selectOption) => {
        items[index].selectOption = selectOption
        setItems([...items])
      },
      [items]
    )
    const addInputItem = useCallback(() => {
      setItems([
        ...items,
        {
          uuid: generateUuid(),
          selectOption: '',
        },
      ])
    }, [items])
    const deleteInputItem = useCallback(
      index => {
        items.splice(index, 1)
        setItems([...items])
      },
      [items]
    )
    const hasError = useMemo(() => items.some(v => !v.selectOption), [items])
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'wbsItemAdditionalProperties.cellEditor.selectOptions.dialog.title',
          })}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {intl.formatMessage({
                      id: 'wbsItemAdditionalProperties.cellEditor.selectOptions.dialog.label.selectOption',
                    })}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(
                  (
                    item: WbsItemAdditionalPropertySelectOption,
                    index: number
                  ) => (
                    <SelectOptionEditor
                      key={item.uuid}
                      index={index}
                      uuid={item.uuid}
                      selectOption={item.selectOption}
                      onChangeInputItem={onChangeInputItem}
                      deleteInputItem={deleteInputItem}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Footer>
            <AddIcon color="action" onClick={addInputItem} />
            {hasError && (
              <FooterMessage>
                {intl.formatMessage({
                  id: 'wbsItemAdditionalProperties.cellEditor.selectOptions.dialog.selectOption.emptyMessage',
                })}
              </FooterMessage>
            )}
          </Footer>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            label={intl.formatMessage({ id: 'dialog.ok' })}
            disabled={hasError}
            onClick={onSubmit}
          />
          <CloseButton
            label={intl.formatMessage({ id: 'dialog.close' })}
            disabled={false}
            onClick={onClose}
          />
        </DialogActions>
      </Dialog>
    )
  }
)
const SubmitButton = ({
  label,
  disabled,
  onClick,
}: {
  label: string
  disabled: boolean
  onClick: () => void
}) => {
  return (
    <StyledButton
      variant="filled"
      colorPattern="skyBlue"
      size="s"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </StyledButton>
  )
}
const CloseButton = ({
  label,
  disabled,
  onClick,
}: {
  label: string
  disabled: boolean
  onClick: () => void
}) => {
  return (
    <StyledButton
      variant="outlined"
      colorPattern="monotone"
      size="s"
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </StyledButton>
  )
}
// Styles
const StyledButton = styled(Button)({
  width: '100px',
})
const DialogContent = styled(MuiDialogContent)({
  width: '510px',
})
const Table = styled(MuiTable)({
  width: '100%',
  height: '100%',
})
const TableHead = styled(MuiTableHead)({
  backgroundColor: '#F0F0F0',
})
const Footer = styled('div')({
  paddingTop: 10,
  paddingLeft: 10,
  display: 'flex',
  alignItems: 'center',
})
const FooterMessage = styled('div')({
  color: 'red',
  marginLeft: 20,
})
