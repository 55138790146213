import { useCallback, useState } from 'react'
import { EnterpriseProjectReportConfig } from '../model/config'
import {
  AggregateField,
  AggregateTarget,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'

export const useEnterpriseProjectReportConfig = () => {
  const [config, setConfig] = useState<EnterpriseProjectReportConfig>({
    aggregateTarget: WbsItemType.DELIVERABLE,
    aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.MONTH,
  })
  const updateAggregateTarget = useCallback((newValue: AggregateTarget) => {
    setConfig(prev => ({
      ...prev,
      aggregateTarget: newValue,
    }))
  }, [])
  const updateAggregateField = useCallback((newValue: AggregateField) => {
    setConfig(prev => ({
      ...prev,
      aggregateField: newValue,
    }))
  }, [])
  const updateWorkloadUnit = useCallback((newValue: WorkloadUnit) => {
    setConfig(prev => ({
      ...prev,
      workloadUnit: newValue,
    }))
  }, [])
  return {
    config,
    updateAggregateTarget,
    updateAggregateField,
    updateWorkloadUnit,
  }
}
