import { useState, useCallback, useMemo } from 'react'
import {
  SidebarPanelRoot,
  SidebarPanelHeader,
  SidebarPanelTitle,
  SidebarPanelHeaderIconButton,
  SidebarHeaderArrowIcon,
  SidebarPanelMainArea,
} from '.'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { BurndownChartResourceSummary } from '../../model'
import { ValueFormatters } from '../../hooks/valueFormatters'

type ReferenceDataPanelProps = {
  resourceSummary: BurndownChartResourceSummary
  valueFormatters: ValueFormatters
}
export const ReferenceDataPanel = ({
  resourceSummary: { velocity, resourceDevotionRate, remainingResource },
  valueFormatters: { formatRate, formatWorkload, workloadUnitLabel },
}: ReferenceDataPanelProps) => {
  const [open, setOpen] = useState<boolean>(true)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const [formattedVelocity, formattedResourceRate, formattedRemainingResource] =
    useMemo(
      () => [
        formatRate(velocity),
        formatRate(resourceDevotionRate),
        formatWorkload(remainingResource),
      ],
      [
        formatRate,
        formatWorkload,
        remainingResource,
        resourceDevotionRate,
        velocity,
      ]
    )

  return (
    <SidebarPanelRoot>
      <SidebarPanelHeader>
        <SidebarPanelTitle>参考データ</SidebarPanelTitle>
        <SidebarPanelHeaderIconButton onClick={toggle}>
          <SidebarHeaderArrowIcon open={open} />
        </SidebarPanelHeaderIconButton>
      </SidebarPanelHeader>
      <SidebarPanelMainArea in={open}>
        <ValueContainer>
          <ValueRow>
            <ValueLabel>Velocity</ValueLabel>
            <ValueWithoutUnit>{formattedVelocity}</ValueWithoutUnit>
          </ValueRow>
          <ValueRow>
            <ValueLabel>投入工数比率</ValueLabel>
            <ValueWithoutUnit>{formattedResourceRate}</ValueWithoutUnit>
          </ValueRow>
          <ValueRow>
            <ValueLabel>残工数</ValueLabel>
            <ValueWithUnit>
              <Value>{formattedRemainingResource}</Value>
              <Unit>{workloadUnitLabel}</Unit>
            </ValueWithUnit>
          </ValueRow>
        </ValueContainer>
      </SidebarPanelMainArea>
    </SidebarPanelRoot>
  )
}

const ValueContainer = styled('div')({
  border: `1px solid ${colorPalette.monotone[2]}`,
  display: 'flex',
  flexDirection: 'column',
  padding: '12px',
  gap: '12px',
  width: '100%',
})
const ValueRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const ValueLabel = styled('div')({
  fontSize: '12px',
  lineHeight: 1,
  color: colorPalette.monotone[6],
})
const ValueWithUnit = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'flex-end',
})
const Value = styled('div')({
  fontSize: '15px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
  lineHeight: 1,
})
const Unit = styled('div')({
  fontSize: '10px',
  color: colorPalette.monotone[10],
  lineHeight: 1,
  width: '20px',
})
const ValueWithoutUnit = styled('div')({
  fontSize: '15px',
  fontWeight: 600,
  color: colorPalette.monotone[10],
  lineHeight: 1,
  padding: '0 24px 0 0',
})
