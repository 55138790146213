import { useCallback } from 'react'
import { SelectOption } from '../../../model/selectOption'
import { Avatar, List, ListItem, Popper, Typography } from '@mui/material'
import { Button } from '../../../components/buttons'
import { colorPalette } from '../../../style/colorPallete'
import { Placement } from 'react-overlays/cjs/usePopper'

type SearchConditionSelectProps = {
  open: boolean
  anchorEl: Element | undefined
  options: SelectOption[]
  value: string | undefined
  onChange: (newValue: string | undefined) => void
  offset?: number
  placement?: Placement | undefined
  isDisplayAvatar?: boolean
  isSprintSearchCondition?: boolean
}

const SearchConditionSelectPopper = ({
  open,
  anchorEl,
  options,
  value,
  onChange,
  offset,
  placement,
  isDisplayAvatar,
  isSprintSearchCondition,
}: SearchConditionSelectProps) => {
  const onChangeValue = useCallback(
    (v: any) => {
      let newValue = value
      if (newValue !== v.value) {
        newValue = v.value
        onChange(newValue)
      }
    },
    [value]
  )

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      sx={{
        zIndex: 1300,
        background: colorPalette.monotone[0],
        boxShadow:
          'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
        borderRadius: '4px',
        padding: '10px',
        minWidth: '445px',
        maxHeight: '200px',
        overflowX: 'auto',
      }}
      placement={placement}
      modifiers={[
        { name: 'offset', options: { offset: [offset ? offset : 0, 20] } },
      ]}
    >
      <List>
        {options.map(option => (
          <ListItem key={option.value} sx={{ margin: '3px 0', padding: 0 }}>
            <Button
              colorPattern="monotone"
              sx={{
                border: 'none',
                background: colorPalette.monotone[0],
                width: '100%',
                textAlign: 'start',
                justifyContent: 'start',
                '&:hover': { background: colorPalette.monotone[1] },
              }}
              onClick={() => onChangeValue(option)}
            >
              {isDisplayAvatar && (
                <Avatar
                  variant="circular"
                  src={option.iconUrl}
                  style={{
                    width: 20,
                    height: 20,
                    margin: 'auto 5px auto 5px',
                  }}
                />
              )}
              <Typography
                sx={{
                  color: colorPalette.monotone[4],
                  fontWeight: 500,
                  fontSize: '14px',
                  padding: '5px 0 !important',
                  marginRight: '5px',
                }}
              >
                {option.name}
              </Typography>
              {isSprintSearchCondition && (
                <Typography
                  sx={{
                    color: colorPalette.monotone[4],
                    fontWeight: 500,
                    fontSize: '12px',
                    padding: '5px 0 !important',
                  }}
                >
                  ({option.groupBy})
                </Typography>
              )}
            </Button>
          </ListItem>
        ))}
      </List>
    </Popper>
  )
}

export default SearchConditionSelectPopper
