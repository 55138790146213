import DomainError, { DomainErrorCode } from '../../../error/DomainError'
import DomainValueObjectBase from '../Base'

const FORMAT = /[0-9]{1,}.[0-9]{1,}.[0-9]{1,}/

export default class RevisionVO extends DomainValueObjectBase<string> {
  constructor(_value: string) {
    if (!_value) {
      throw new DomainError({
        errorCode: DomainErrorCode.REQUIRED_VALUE_ERROR,
        message: 'Revision value is required.',
      })
    }
    if (typeof _value !== 'string') {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_TYPE_ERROR,
        message: `Revision value(${_value}) must be string value.`,
      })
    }
    if (!FORMAT.test(_value)) {
      throw new DomainError({
        errorCode: DomainErrorCode.VALUE_FORMAT_ERROR,
        message: `Revision format(${_value}) must be [0.0.0].`,
      })
    }
    super(_value)
  }
}
