import { Avatar, Box, Typography } from '@mui/material'
import { gridRootStyle } from '../../../..'
import { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community'
import store from '../../../../../../../../store'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'
import { ICON_SIZE } from '../../../../../../BulkSheet/const'

interface OwnProps {
  value?: WbsItemTypeVO
}

export type Props = OwnProps & ICellRendererParams

export const cellRenderer = (props: Props) => {
  const ticketType = getTicketType(props.value)
  if (!ticketType) return <></>

  return (
    <Box sx={gridRootStyle}>
      <Avatar
        src={ticketType.iconUrl}
        sx={{ width: ICON_SIZE, height: ICON_SIZE }}
      />
      <Typography>{ticketType.name}</Typography>
    </Box>
  )
}

export const getTicketType = (value: WbsItemTypeVO) => {
  if (!value || !value.isTicket()) return undefined
  if (!value.baseTicketUuid) return value
  return value.isDeliverable()
    ? store
        .getState()
        .project.ticketListTypes.find(
          v => value.baseTicketUuid === v.uuid || value.code === v.code
        )
    : store
        .getState()
        .project.ticketTypes.find(
          v => value.baseTicketUuid === v.uuid || value.code === v.code
        )
}

export const valueFormatter = (params: ValueFormatterParams) => {
  const cellValue = params.value
  if (typeof cellValue === 'string') return cellValue
  const ticketType = getTicketType(params.value)
  return ticketType?.name || ''
}

export default {
  cellRenderer,
  valueFormatter,
}
