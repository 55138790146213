import { useEffect, useState, useCallback } from 'react'
import uiState, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'

const useRememberedDrawerState = <T>(uiStateKey: UiStateKey) => {
  const [rememberedDrawerState, setRememberedDrawerState] = useState<
    T | undefined
  >()
  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    uiState
      .get({
        key: uiStateKey,
        scope: UiStateScope.User,
      })
      .then(response => {
        try {
          if (!response.json?.value) return
          const state = JSON.parse(response.json.value) as T
          setRememberedDrawerState(state)
        } finally {
          setFetched(true)
        }
      })
  }, [uiStateKey])
  const rememberDrawerState = useCallback(
    (state: T) => {
      uiState.update({
        key: uiStateKey,
        scope: UiStateScope.User,
        value: JSON.stringify(state),
      })
    },
    [uiStateKey]
  )

  return {
    rememberedDrawerState,
    rememberDrawerState,
    fetched,
  }
}

export default useRememberedDrawerState
