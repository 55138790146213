import React from 'react'
import { styled } from '@mui/system'
import Select from '../../../components/editors/select/Select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import SelectVO from '../../../../vo/SelectVO'
import VOBase from '../../../../vo/base'
import { filterByCombination } from '../../../../lib/commons/customEnum'
import objects from '../../../../utils/objects'
import equal from 'fast-deep-equal'
import { CellDef } from '../../../containers/meta/ViewMeta/SingleSheetViewMeta'
import { FontSize, FontWeight } from '../../../../styles/commonStyles'

const StyledSelect = styled(Select)({
  padding: '2px',
  display: 'inline-flex',
  width: '100%',
})
const Option = styled('span')({
  padding: '2px 8px',
  width: 'auto',
  borderRadius: 4,
  fontWeight: FontWeight.BOLD,
  fontSize: FontSize.MEDIUM,
})

interface Props {
  storeKey: string
  data: any
  defs: CellDef | undefined
  value: any
  displayIcon?: boolean
  onChange: (vo: SelectVO | undefined) => void
  onBlur: () => void
}

interface State {
  options: SelectVO[]
  open: boolean
}

class SelectVOSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      options: this.getOptions(),
      open: true,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.data, prevProps.data)) {
      this.resetOptions()
    }
  }
  private getOptions = (): SelectVO[] => {
    if (!this.props.defs) return []
    const getCombinedValue = (path: string) => {
      const value = objects.getValue(this.props.data, path)
      if (value instanceof VOBase) {
        return value.getValue()
      }
      return value
    }
    const filtered = filterByCombination(
      this.props.defs.uiMeta.valuesAllowed,
      getCombinedValue
    ).filter(option => option.value !== 'NONE')
    return filtered.map(option => SelectVO.fromCustomEnum(option))
  }
  private resetOptions = () => {
    this.setState({ options: this.getOptions() })
  }
  onSelectChange = e => {
    if (!this.props.defs) return
    this.props.onChange(
      SelectVO.of(e.target.value, this.props.defs.uiMeta.valuesAllowed)
    )
  }
  onClose = () => {
    this.setState({ open: false })
    this.props.onBlur()
  }
  createOptionLabel = opt => {
    if (!this.props.defs) {
      return opt?.format()
    }
    const item = this.props.defs.uiMeta.valuesAllowed.find(
      v => v.value === opt?.getValue()
    )
    if (!item || !item.backgroundColor) {
      return opt?.format()
    }
    return (
      <Option
        style={{
          background: item.backgroundColor,
        }}
      >
        {opt?.format()}
      </Option>
    )
  }
  createOptionIconUrl = opt => {
    if (!this.props.displayIcon) {
      return undefined
    }
    return opt.iconUrl
  }
  getOptIconFunction = this.props.displayIcon
    ? this.createOptionIconUrl
    : undefined

  render() {
    const { value } = this.props
    return (
      <StyledSelect
        fullWidth={true}
        value={value ? value.getValue() : ''}
        onChange={this.onSelectChange}
        IconComponent={ExpandMoreIcon}
        disableUnderline={true}
        options={this.state.options}
        getOptionIconUrl={this.getOptIconFunction}
        getOptionLabel={o => this.createOptionLabel(o)}
        getOptionValue={o => o?.getValue()}
        MenuProps={{ disablePortal: true }}
        onBlur={this.props.onBlur}
        onClose={this.onClose}
        open={this.state.open}
      />
    )
  }
}

export default SelectVOSelect
