import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useWorkloadUnit } from '../../../../hooks/useWorkloadUnit'
import { WorkloadUnit } from '../../../../../lib/functions/workload'

type WorkloadCellProps = {
  value: number | null
  onChange?: (v: number | null) => void
} & CellPropsBase
type WorkloadInput = {
  hour: number | null
  day: number | null
  month: number | null
}
const EMPTY_INPUT: WorkloadInput = {
  hour: null,
  day: null,
  month: null,
}
export const WorkloadCell = ({
  label,
  cellWidth,
  value,
  onChange,
  editable = true,
  required = false,
}: WorkloadCellProps) => {
  const {
    convertWorkloadFromHourToSelectedUnit: toDay,
    hoursPerSelectedUnit: hoursPerDay,
  } = useWorkloadUnit(WorkloadUnit.DAY)
  const {
    convertWorkloadFromHourToSelectedUnit: toMonth,
    hoursPerSelectedUnit: hoursPerMonth,
  } = useWorkloadUnit(WorkloadUnit.MONTH)
  const valueToInputValue = useCallback(
    (v: number | null) => {
      return {
        hour: value,
        day: value ? toDay(value) : null,
        month: value ? toMonth(value) : null,
      }
    },
    [toDay, toMonth, value]
  )
  const [inputValue, setInputValue] = useState<WorkloadInput>({
    hour: value,
    day: value ? toDay(value) : null,
    month: value ? toMonth(value) : null,
  })
  useEffect(() => {
    setInputValue(valueToInputValue(value))
  }, [value, valueToInputValue])
  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    onChange && onChange(debouncedValue.hour)
  }, [debouncedValue, onChange])
  const parse = useCallback((v: string): number => {
    const parsed = parseFloat(v)
    if (parsed < 0) return 0
    return parsed
  }, [])
  const roundInput = useCallback((v: WorkloadInput): WorkloadInput => {
    const roundNumber = (u: number) => Math.round(u * 100) / 100
    return {
      hour: v.hour && roundNumber(v.hour),
      day: v.day && roundNumber(v.day),
      month: v.month && roundNumber(v.month),
    }
  }, [])
  const onChangeHour = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const normalized = parse(value)
      setInputValue(
        roundInput({
          hour: normalized,
          day: toDay(normalized),
          month: toMonth(normalized),
        })
      )
    },
    [parse, roundInput, toDay, toMonth]
  )
  const onChangeDay = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const normalized = parse(value)
      setInputValue(
        roundInput({
          hour: normalized / hoursPerDay,
          day: normalized,
          month: normalized * (hoursPerMonth / hoursPerDay),
        })
      )
    },
    [hoursPerDay, hoursPerMonth, parse, roundInput]
  )
  const onChangeMonth = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const normalized = parse(value)
      setInputValue(
        roundInput({
          hour: normalized / hoursPerMonth,
          day: toDay(normalized / hoursPerMonth),
          month: normalized,
        })
      )
    },
    [hoursPerMonth, parse, roundInput, toDay]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea>
        <TextInput
          value={inputValue.hour}
          onChange={onChangeHour}
          disabled={!editable}
        />
        /
        <TextInput
          value={inputValue.day}
          onChange={onChangeDay}
          disabled={!editable}
        />
        /
        <TextInput
          value={inputValue.month}
          onChange={onChangeMonth}
          disabled={!editable}
        />
      </CellValueArea>
    </FormCell>
  )
}

const TextInput = styled(InputBase)({})
