import './index.scss'
import { List } from 'immutable'
import React from 'react'
import { styled } from '@mui/system'
import store, { AllState } from '../../../store'
import {
  Comment,
  fetchComments,
  generateEditingCommentUuidKey,
  subscribeComments,
} from '../../../store/comments'
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import CommentGroup from './CommentGroup'
import CommentEditor from './CommentEditor'
import objectUtil from '../../../utils/objects'
import { generateUuid } from '../../../utils/uuids'
import DateTimeVO from '../../../domain/value-object/DateTimeVO'

interface ComponentOwnProps {
  applicationFunctionUuid: string
  dataUuid?: string
  suffix?: string
  projectUuid?: string
}

interface CommentListScrollState {
  scrollHeightUnderScrollBar: number
  scrollHeight: number
  scrollTop: number
  commentGroupCount: number
  commentGroupsUnderScrollBar: {
    commentGroupId: string
    height: number
  }[]
}

interface StateProps {
  comments: List<Comment>
  hasEditingComment: boolean
}

interface Props extends ComponentOwnProps, StateProps {
  headerComponents?: JSX.Element[]
}

const RootContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  flexDirection: 'column',
  overflow: 'hidden',
  display: 'flex',
})
const CommentEditorContainer = styled('div')<{ hasHeaderComponents: boolean }>(
  ({ hasHeaderComponents }) => {
    if (hasHeaderComponents) {
      return {
        width: '100%',
        padding: '0px 8px 10px',
        backgroundColor: '#f5f5f5',
      }
    } else {
      return {
        width: '100%',
        padding: '10px 10px 20px',
        backgroundColor: '#f5f5f5',
      }
    }
  }
)

interface State {
  count: number
  isMenuOpen: boolean
  commentGroups: {
    id: string
    comments: Comment[]
  }[]
  commentListElementId: string
  commentGroupIdMapByCommentUuid: { [commentUuid: string]: string }
}

class CommentList extends React.PureComponent<Props, State> {
  private scrollParentRef: HTMLDivElement | null = null
  private subscribed = false

  state: State = {
    count: 0,
    isMenuOpen: false,
    commentGroups: [],
    commentListElementId: generateUuid(),
    commentGroupIdMapByCommentUuid: {},
  }

  async componentDidMount() {
    this.subscribe()
    this.updateCommentGroup()
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.applicationFunctionUuid !==
        this.props.applicationFunctionUuid ||
      prevProps.dataUuid !== this.props.dataUuid
    ) {
      this.subscribed = false
      this.subscribe()
    }
    if (objectUtil.hasDiff(prevProps.comments, this.props.comments)) {
      this.updateCommentGroup()
    }
  }

  private subscribe = () => {
    if (!this.props.dataUuid || this.subscribed) {
      return
    }
    const { applicationFunctionUuid, dataUuid, comments } = this.props
    this.subscribed = true
    store.dispatch(subscribeComments(applicationFunctionUuid, dataUuid))
    if (comments.size === 0) {
      store.dispatch(fetchComments(applicationFunctionUuid, dataUuid, 0))
    }
  }

  private loadItems = () => {
    const { applicationFunctionUuid, dataUuid, comments } = this.props
    if (
      dataUuid &&
      this.scrollParentRef &&
      this.scrollParentRef.scrollHeight !== this.scrollParentRef.clientHeight &&
      this.state.count !== comments.size
    ) {
      this.setState({ count: comments.size }, () => {
        store.dispatch(
          fetchComments(applicationFunctionUuid, dataUuid, comments.size)
        )
      })
    }
  }

  private getCommentListElement = (): Element | null => {
    return document.getElementById(this.state.commentListElementId)
  }

  private goToPrevScroll = ({
    scrollHeightUnderScrollBar: oldScrollHeightUnderScrollBar,
    scrollHeight: oldScrollHeight,
    scrollTop: oldScrollTop,
    commentGroupCount: oldCommentGroupCount,
    commentGroupsUnderScrollBar: oldCommentGroupsUnderScrollBar,
  }: CommentListScrollState) => {
    const commentListElem = this.getCommentListElement()
    if (!commentListElem) {
      return
    }

    const currentScrollHeight = commentListElem.scrollHeight
    if (
      oldScrollTop === 0 ||
      oldCommentGroupsUnderScrollBar.length === 0 ||
      currentScrollHeight === oldScrollHeight
    ) {
      commentListElem.scrollTop = oldScrollTop
      return
    }

    const currentCommentGroupCount = this.state.commentGroups.length
    if (currentCommentGroupCount > oldCommentGroupCount) {
      commentListElem.scrollTop =
        oldScrollTop + (oldScrollHeight - currentScrollHeight)
      return
    }

    let scrollHeightUnderScrollBar = 0
    for (let i = 0, { length } = commentListElem.children; i < length; i++) {
      const commentGroup = commentListElem.children.item(i)
      if (commentGroup) {
        const oldCommentGroupUnderScrollBar =
          oldCommentGroupsUnderScrollBar.find(
            v => v.commentGroupId === commentGroup.id
          )
        if (oldCommentGroupUnderScrollBar) {
          scrollHeightUnderScrollBar +=
            commentGroup.getBoundingClientRect().height
        }

        if (i >= oldCommentGroupsUnderScrollBar.length) {
          break
        }
      }
    }
    if (scrollHeightUnderScrollBar > oldScrollHeightUnderScrollBar) {
      commentListElem.scrollTop =
        oldScrollTop -
        (scrollHeightUnderScrollBar - oldScrollHeightUnderScrollBar)
    } else {
      commentListElem.scrollTop =
        oldScrollTop +
        (oldScrollHeightUnderScrollBar - scrollHeightUnderScrollBar)
    }
  }

  private getCurrentScrollState = (): CommentListScrollState => {
    const commentListElem = this.getCommentListElement()
    if (!commentListElem) {
      return {
        scrollHeightUnderScrollBar: 0,
        scrollHeight: 0,
        scrollTop: 0,
        commentGroupCount: 0,
        commentGroupsUnderScrollBar: [],
      }
    }

    const commentGroupsUnderScrollBar: {
      commentGroupId: string
      height: number
    }[] = []
    const scrollHeight = commentListElem.scrollHeight || 0
    const scrollTop = commentListElem.scrollTop || 0
    let scrollHeightUnderScrollBar = 0
    if (scrollTop < 0) {
      const currentScrollPosition =
        commentListElem.clientHeight + scrollTop * -1
      for (let i = 0, { length } = commentListElem.children; i < length; i++) {
        const commentGroupElem = commentListElem.children.item(i)
        if (commentGroupElem) {
          const height = commentGroupElem.getBoundingClientRect().height
          if (scrollHeightUnderScrollBar + height > currentScrollPosition) {
            break
          }
          scrollHeightUnderScrollBar += height
          const commentGroup = this.state.commentGroups.find(v => v.id)
          commentGroupsUnderScrollBar.push({
            commentGroupId: commentGroupElem.id,
            height,
          })
        }
      }
    }

    return {
      scrollHeight,
      scrollTop,
      scrollHeightUnderScrollBar,
      commentGroupCount: this.state.commentGroups.length,
      commentGroupsUnderScrollBar,
    }
  }

  private generateCommentGroupId = () => {
    return generateUuid()
  }

  private sameCommentGroup = (a: Comment, b: Comment): boolean => {
    const aCreatedBy = a.createdBy?.uuid || 'unknown'
    const bCreatedBy = b.createdBy?.uuid || 'unknown'
    return (
      aCreatedBy === bCreatedBy &&
      new DateTimeVO(a.createdAt).toString('YYYY/MM/DD HH:mm') ===
        new DateTimeVO(b.createdAt).toString('YYYY/MM/DD HH:mm')
    )
  }

  private updateCommentGroup = () => {
    let prevComment: Comment | undefined = undefined
    let group: {
      id: string
      comments: Comment[]
    }[] = []
    const commentGroupIdMapByCommentUuid: { [commentUuid: string]: string } = {}
    this.state.commentGroups.forEach(commentGroup => {
      commentGroup.comments.forEach(comment => {
        commentGroupIdMapByCommentUuid[comment.uuid] = commentGroup.id
      })
    })
    for (let i = 0; i < this.props.comments.size; i++) {
      const current = this.props.comments.get(i)
      if (!current) {
        continue
      }

      if (
        prevComment &&
        prevComment.createdBy?.uuid === current.createdBy?.uuid &&
        this.sameCommentGroup(prevComment, current)
      ) {
        prevComment = current
        group[group.length - 1].id =
          commentGroupIdMapByCommentUuid[current.uuid] ||
          this.generateCommentGroupId()
        group[group.length - 1].comments.push(current)
        continue
      }
      prevComment = current
      group.push({
        id:
          commentGroupIdMapByCommentUuid[current.uuid] ||
          this.generateCommentGroupId(),
        comments: [current],
      })
    }

    const currentScrollState = this.getCurrentScrollState()
    this.setState(
      {
        commentGroups: group,
      },
      () => {
        this.goToPrevScroll({ ...currentScrollState })
      }
    )
  }

  render() {
    const { headerComponents } = this.props

    return (
      <RootContainer>
        <InfiniteScroll
          id={this.state.commentListElementId}
          className={'wbs-item-comments-infinite-scroll flagxs-markup-root'}
          loadMore={this.loadItems}
          hasMore={true}
          isReverse={true}
          useWindow={false}
          threshold={10}
          getScrollParent={() => this.scrollParentRef}
        >
          {this.state.commentGroups.map((v, i) => {
            return (
              <CommentGroup
                commentGroupId={v.id}
                applicationFunctionUuid={this.props.applicationFunctionUuid}
                dataUuid={this.props.dataUuid}
                suffix={this.props.suffix}
                projectUuid={this.props.projectUuid}
                comments={v.comments}
                index={i}
                key={v.id}
                checkIsMenuOpen={() => this.state.isMenuOpen}
                setIsMenuOpen={isMenuOpen =>
                  this.setState({ isMenuOpen: isMenuOpen })
                }
                hasEditingComment={this.props.hasEditingComment}
              />
            )
          })}
        </InfiniteScroll>
        <CommentEditorContainer hasHeaderComponents={!!headerComponents}>
          {headerComponents}
          <CommentEditor
            className="wbs-item-comment-editor"
            applicationFunctionUuid={this.props.applicationFunctionUuid}
            dataUuid={this.props.dataUuid}
            suffix={this.props.suffix}
            projectUuid={this.props.projectUuid}
            isEditing={false}
          />
        </CommentEditorContainer>
      </RootContainer>
    )
  }
}

const mapStateToProps = (state: AllState, ownProps: ComponentOwnProps) => ({
  comments: state.comments.comments.get(
    `${ownProps.applicationFunctionUuid}-${ownProps.dataUuid}`,
    List<Comment>()
  ),
  hasEditingComment: state.comments.editingCommentUuids.has(
    generateEditingCommentUuidKey(
      ownProps.applicationFunctionUuid,
      ownProps.dataUuid!,
      ownProps.suffix
    )
  ),
})

export default connect<StateProps, void, ComponentOwnProps, AllState>(
  mapStateToProps
)(CommentList)
