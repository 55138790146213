import { styled } from '@mui/system'
import { AuthError } from '../../../../error/AuthError'
import { useMemo } from 'react'
import { intl } from '../../../../i18n'
import { useAuthErrorMessage } from '../hooks/authErrorMessage'

type AuthErrorMessageProps = {
  authError: AuthError
}

export const ErrorMessage = ({ authError }: AuthErrorMessageProps) => {
  const message = useAuthErrorMessage(authError)
  if (!message) return <></>
  if (message.detail) {
    return (
      <ErrorMessageContainer>
        <ErrorMessageWithDetail>
          <ErrorMessageTitle>{message.title}</ErrorMessageTitle>
          <ErrorMessageDetailArea>
            {message.detail.map((m, i) => (
              <div key={i}>{`・${m}`}</div>
            ))}
          </ErrorMessageDetailArea>
        </ErrorMessageWithDetail>
      </ErrorMessageContainer>
    )
  }
  return (
    <ErrorMessageContainer>
      <ErrorMessageOnlyTitle>
        <ErrorMessageTitle>{message.title}</ErrorMessageTitle>
      </ErrorMessageOnlyTitle>
    </ErrorMessageContainer>
  )
}
const ErrorMessageContainer = styled('div')({
  display: 'flex',
  backgroundColor: '#FCF4F7',
  border: '1px solid #ED1E79',
  width: '476px',
  padding: '12px 24px',
  borderRadius: '4px',
  justifyContent: 'center',
  gap: '12px',
  color: '#ED1E79',
})

const ErrorMessageOnlyTitle = styled('div')({
  alignItems: 'center',
})
const ErrorMessageWithDetail = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: '12px',
})

const ErrorMessageTitle = styled('div')({
  fontSize: '15px',
  fontWeight: '700',
  textAlign: 'center',
})

const ErrorMessageDetailArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '13px',
  gap: '2px',
})
