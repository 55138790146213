import { useMemo } from 'react'
import {
  Kpi,
  WidgetContent,
  WidgetForExpanded,
  WidgetTitle,
  WidgetWithTitle,
} from '.'
import { ProjectRisk } from '../../../../domain/value-object/ProjectReportVO'
import { useI18n } from '../../../hooks/useI18n'

type ProjectRiskWidgetProps = ProjectRisk

export const ProjectRiskWidget = ({
  veryHigh,
  high,
  middle,
  low,
  veryLow,
}: ProjectRiskWidgetProps) => {
  const {
    formattedVeryHigh,
    formattedHigh,
    formattedMiddle,
    formattedLow,
    formattedVeryLow,
  } = useMemo(() => {
    return {
      formattedVeryHigh: `${veryHigh}`,
      formattedHigh: `${high}`,
      formattedMiddle: `${middle}`,
      formattedLow: `${low}`,
      formattedVeryLow: `${veryLow}`,
    }
  }, [high, low, middle, veryHigh, veryLow])
  const [
    title,
    veryHighLabel,
    highLabel,
    middleLabel,
    lowLabel,
    veryLowLabel,
    unit,
  ] = useI18n([
    'enterpriseProjectReport.risk',
    'enterpriseProjectReport.risk.veryHigh',
    'enterpriseProjectReport.risk.high',
    'enterpriseProjectReport.risk.middle',
    'enterpriseProjectReport.risk.low',
    'enterpriseProjectReport.risk.veryLow',
    'unit.count',
  ])
  return (
    <WidgetWithTitle>
      <WidgetTitle>{title}</WidgetTitle>
      <WidgetForExpanded>
        <WidgetContent>
          <Kpi title={veryHighLabel} value={formattedVeryHigh} unit={unit} />
          <Kpi title={highLabel} value={formattedHigh} unit={unit} />
          <Kpi title={middleLabel} value={formattedMiddle} unit={unit} />
          <Kpi title={lowLabel} value={formattedLow} unit={unit} />
          <Kpi title={veryLowLabel} value={formattedVeryLow} unit={unit} />
        </WidgetContent>
      </WidgetForExpanded>
    </WidgetWithTitle>
  )
}
