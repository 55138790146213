import { ValueGetterParams } from 'ag-grid-community'
import { getUnitByFieldName, getWorkloadFieldName } from '.'
import objects from '../../../../../../../../utils/objects'

export default (params: ValueGetterParams) => {
  const fieldName =
    params.colDef.cellEditorParams.thisFieldName || params.colDef.field || ''
  const workloadUnit = getUnitByFieldName(fieldName)
  return objects.getValue(
    params.data,
    `${getWorkloadFieldName(fieldName)}.${workloadUnit.toLowerCase()}`
  )
}
