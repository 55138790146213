import TeamSelect from '../../components/editors/select/TeamSelect'
import moment from 'moment'
import React from 'react'
import { styled } from '@mui/system'
import { InputBase } from '@mui/material'
import DatePicker from 'react-datepicker'
import MenuItem from '@mui/material/MenuItem'
import objectUtil from '../../../utils/objects'
import { formatDateForApi } from '../../../utils/date'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SearchButton from '../../components/buttons/SearchButton'
import Select from '../../components/editors/select/Select'
import {
  getSprintReportGroupKeyLabel,
  SprintReportGroupKey,
} from '../../../lib/functions/sprintReport'
import { WbsItemSelect } from '../../components/editors/select/WbsItemSelect'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'

const DateSelect = props => {
  return (
    <DatePicker
      selected={props.value && moment(props.value).toDate()}
      dateFormat={'yyyy/MM/dd'}
      onChange={date =>
        props.onChange(props.conditionItemKey, formatDateForApi(date))
      }
      placeholderText={props.placeholder || ''}
      customInput={<InputBase />}
      popperPlacement="top-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '5px, 10px',
        },
        preventOverflow: {
          enabled: true,
        },
      }}
      fixedHeight={true}
    />
  )
}

const SearchConditionBox = styled('div')({
  width: '100%',
  padding: '10px 0',
  display: 'flex',
  alignItems: 'center',
})
const GroupKeySelectBox = styled('div')({
  border: 'solid 1px #C4C4C4',
  borderRadius: '4px',
  padding: '0 5px',
  marginRight: '6px',
})
const DateSelectBox = styled('div')({
  border: 'solid 1px #C4C4C4',
  borderRadius: '4px',
  padding: '0 5px',
  width: '100px',
  zIndex: 3,
})
const TermLabel = styled('div')({
  padding: '5px',
})
const SearchButtonBox = styled('div')({
  marginLeft: '20px',
})
const StyledSelect = styled(Select)({
  height: '28px',
  fontSize: '11px',
})
const StyledMenuItem = styled(MenuItem)({
  fontSize: '11px',
}) as typeof MenuItem

interface Props extends WrappedComponentProps {
  projectUuid: string
  rootWbsItemUuid?: string
  teamUuid?: string
  groupKey: SprintReportGroupKey
  dateTerm: {
    startDate?: number
    endDate?: number
  }
  search: (searchCondition: SearchCondition) => void
  isLoading: boolean
}

export interface SearchCondition {
  rootWbsItemUuid?: string
  teamUuid?: string
  groupKey: SprintReportGroupKey
  dateTerm: {
    startDate?: number
    endDate?: number
  }
}

export const getDefaultDateTerm = () => {
  return {
    startDate: moment().startOf('month').toDate().getTime(),
    endDate: moment().add(1, 'month').endOf('month').toDate().getTime(),
  }
}

class SearchConditionToolBar extends React.Component<Props, SearchCondition> {
  constructor(props) {
    super(props)
    this.state = {
      rootWbsItemUuid: props.rootWbsItemUuid,
      teamUuid: props.teamUuid,
      groupKey: props.groupKey || SprintReportGroupKey.SCHEDULED_END_DATE,
      dateTerm: {
        startDate: props.dateTerm ? props.dateTerm.startDate : undefined,
        endDate: props.dateTerm ? props.dateTerm.endDate : undefined,
      },
    }

    if (!this.state.dateTerm.startDate && !this.state.dateTerm.endDate) {
      const defaultDateTerm = getDefaultDateTerm()
      this.state.dateTerm.startDate = defaultDateTerm.startDate
      this.state.dateTerm.endDate = defaultDateTerm.endDate
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (objectUtil.hasDiff(prevProps, this.props)) {
      this.setState({
        rootWbsItemUuid: this.props.rootWbsItemUuid,
        teamUuid: this.props.teamUuid,
        groupKey: this.props.groupKey,
        dateTerm: {
          startDate: this.props.dateTerm
            ? this.props.dateTerm.startDate
            : undefined,
          endDate: this.props.dateTerm
            ? this.props.dateTerm.endDate
            : undefined,
        },
      })
    }
  }

  onChangeSearchConditionItem(key: string, value: any): void {
    const newCondition = Object.assign({}, this.state)
    objectUtil.setValue(newCondition, key, value)

    this.setState(newCondition)
  }

  render() {
    return (
      <SearchConditionBox>
        <WbsItemSelect
          value={this.state.rootWbsItemUuid}
          onChange={(rootWbsItemUuid: string | undefined) => {
            this.onChangeSearchConditionItem('rootWbsItemUuid', rootWbsItemUuid)
          }}
          types={[WbsItemType.PROCESS]}
        />
        <TeamSelect
          projectUuid={this.props.projectUuid}
          onTeamChanged={(teamUuid: string) => {
            this.onChangeSearchConditionItem('teamUuid', teamUuid)
          }}
          teamUuid={this.state.teamUuid}
        />
        <GroupKeySelectBox>
          <StyledSelect
            fullWidth={true}
            value={this.state.groupKey}
            MenuItem={StyledMenuItem}
            onChange={event => {
              this.onChangeSearchConditionItem('groupKey', event.target.value)
            }}
            disableUnderline={true}
            options={[
              SprintReportGroupKey.SCHEDULED_START_DATE,
              SprintReportGroupKey.SCHEDULED_END_DATE,
            ]}
            getOptionLabel={o => getSprintReportGroupKeyLabel(o)}
            getOptionValue={o => o}
          />
        </GroupKeySelectBox>
        <DateSelectBox>
          <DateSelect
            value={this.state.dateTerm.startDate}
            conditionItemKey={'dateTerm.startDate'}
            onChange={(key, value) =>
              this.onChangeSearchConditionItem(key, value)
            }
            placeholder={
              this.state.groupKey === SprintReportGroupKey.SCHEDULED_START_DATE
                ? this.props.intl.formatMessage({
                    id: 'sprintReport.scheduledStartDate.start',
                  })
                : this.props.intl.formatMessage({
                    id: 'sprintReport.startDate.start',
                  })
            }
          />
        </DateSelectBox>
        <TermLabel>〜</TermLabel>
        <DateSelectBox>
          <DateSelect
            value={this.state.dateTerm.endDate}
            conditionItemKey={'dateTerm.endDate'}
            onChange={(key, value) =>
              this.onChangeSearchConditionItem(key, value)
            }
            placeholder={
              this.state.groupKey === SprintReportGroupKey.SCHEDULED_START_DATE
                ? this.props.intl.formatMessage({
                    id: 'sprintReport.scheduledStartDate.end',
                  })
                : this.props.intl.formatMessage({
                    id: 'sprintReport.startDate.end',
                  })
            }
          />
        </DateSelectBox>
        <SearchButtonBox>
          <SearchButton onClick={() => this.props.search(this.state)} />
        </SearchButtonBox>
      </SearchConditionBox>
    )
  }
}

export default injectIntl(SearchConditionToolBar)
