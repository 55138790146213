import { useCallback, useState } from 'react'
import { MyWbsItemRowBody, MyWbsItemRow } from '../myWbsItems'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'

export const useTimerPopper = () => {
  const [anchorEl, setAnchorEl] = useState<EventTarget>()
  const [wbsItem, setWbsItem] = useState<MyWbsItemRow>()
  const [timer, setTimer] = useState<MyWbsItemRowBody>()
  const [wbsItemDelta, setWbsItemDelta] = useState<Partial<WbsItemDeltaInput>>(
    {}
  )

  const open = useCallback(
    (
      el: EventTarget,
      wbsItem: MyWbsItemRow,
      timer: MyWbsItemRowBody,
      delta: Partial<WbsItemDeltaInput>
    ) => {
      setAnchorEl(el)
      setWbsItem(wbsItem)
      setWbsItemDelta(delta)
      setTimer(timer)
    },
    []
  )

  const close = useCallback(() => {
    setAnchorEl(undefined)
    setTimer(undefined)
    setWbsItem(undefined)
    setWbsItemDelta({})
  }, [])

  return {
    anchorEl,
    wbsItemDelta,
    timer,
    wbsItem,

    open,
    close,
  }
}
