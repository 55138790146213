import objects from '../../utils/objects'

export enum WorkloadUnit {
  MONTH = 'MONTH',
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export const getWorkloadUnitValue = (
  workload?: Workload,
  workloadUnit: WorkloadUnit = WorkloadUnit.HOUR
): number => {
  return workload ? objects.getValue(workload, workloadUnit.toLowerCase()) : 0
}

const toNumber = (value: any) => {
  if (isNaN(value)) {
    return 0
  }
  return typeof value === 'string' ? parseFloat(value) : value
}
export const round = (value: number) => Number(value.toFixed(2))

export default class Workload {
  hour: number
  day: number
  month: number

  constructor(
    hour: number | undefined,
    day: number | undefined,
    month: number | undefined,
    standard: {
      dailyWorkHours: number
      monthlyWorkDays: number
    }
  ) {
    this.hour = 0
    this.day = 0
    this.month = 0
    if (hour) {
      this.hour = hour
      this.day = Number(hour / standard.dailyWorkHours)
      this.month = Number(
        hour / (standard.monthlyWorkDays * standard.dailyWorkHours)
      )
    } else if (day) {
      this.hour = day * standard.dailyWorkHours
      this.day = day
      this.month = Number(day / standard.monthlyWorkDays)
    } else if (month) {
      this.hour = month * standard.dailyWorkHours * standard.monthlyWorkDays
      this.day = month * standard.monthlyWorkDays
      this.month = month
    }
  }

  getFixedHour(): number {
    return round(this.hour)
  }

  static from(param: {
    hour?: number
    day?: number
    month?: number
    standard: {
      dailyWorkHours: number
      monthlyWorkDays: number
    }
  }) {
    return new Workload(
      toNumber(param.hour),
      toNumber(param.day),
      toNumber(param.month),
      param.standard
    )
  }
}
