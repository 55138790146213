import API, { APIResponse } from '../commons/api'
import { WbsItemDetail } from './wbsItem'
import { ProjectMemberProps } from './projectMember'
import { Tree } from '../commons/tree'
import _ from 'lodash'
import objectUtil from '../../utils/objects'
import { ProjectPlanCumulation } from './projectPlan'
import {
  SearchFilter,
  toPlainSearchFilterForApi,
} from '../../view/pages/WbsItemSearch/WbsItemSearchToolBar/WbsItemSearchConditions/WbsItemSearchCondition'
import { CommentSummary } from '../../store/comments'
import { ProjectBasic, ProjectDetail } from './project'
import { WbsItemStatus } from '../../domain/entity/WbsItemEntity'
import { WbsItemTypeVO } from '../../domain/value-object/WbsItemTypeVO'
import {
  isHalfOrFullWidthNumber,
  parseFullNumberToHalf,
} from '../../utils/number'

export interface TaskActualWorkDetail extends Tree<TaskActualWorkDetail> {
  deliverableName: string
  projectPlanPath: string
  task: TaskDetail
  cumulation: ProjectPlanCumulation
  commentSummary: CommentSummary
  actualWorks: ActualWorkDetail[]
  ticketType?: string
}

export interface TaskDetail extends WbsItemDetail {}

export interface ActualWorkDetail {
  uuid: string
  lockVersion: number
  projectMember: ProjectMemberProps
  hour: number
  actualDate: string
  actualDateTime?: DateTimeRange
}

export interface TaskActualWorkList {
  total: number
  taskActualWorks: ActualWork[]
}

export interface ActualWork {
  project?: ProjectDetail
  taskCode: string
  taskName: string
  taskStatus: WbsItemStatus
  deliverableName: string
  hour: number
  endDate: string
  ticketType: string
  wbsItemType: WbsItemTypeVO
}

export interface TaskActualWorkHour {
  projectUuid: string
  actualDate: string
  hour: number
}

export interface TaskActualWorkUpdateBatchDeltaRequest {
  taskActualWorks: TaskActualWorkUpdateRequest[]
}

export interface TaskActualWorkUpdateRequest {
  taskUuid: string
  hour: number
  actualDate: string
  actualDateTime?: DateTimeRange
}

export interface GetActualHourByDateRequest {
  taskUuid: string
  actualDate: string
}

export interface GetActualHoursByUserRequest {
  taskUuid: string
}

export interface GetTaskActualWorkListRequest {
  userUuid: string
  targetDate: string
  projectUuid?: string
}

export interface GetTaskActualWorkHoursRequest {
  userUuid: string
  startDate: string
  endDate: string
  projectUuid?: string
}

export interface ActualWorkCreateInput {
  taskUuid: string
  hour: number
  actualDate: string
  actualDateTime?: DateTimeRange
}

export interface ActualWorkUpdateInput {
  uuid: string
  lockVersion: number
  hour: number
  actualDateTime?: DateTimeRange
}

interface DateTimeRange {
  startDateTime: number
  endDateTime: number
}

export interface ActualWorkFindProps {
  projectUuid: string
  all?: string
  team?: {
    uuid?: string
    code?: string
    officialName?: string
    shortName?: string
  }
  searchFilter?: SearchFilter
}

class TaskActualWorkApi {
  findTaskActualWork(props: ActualWorkFindProps): Promise<APIResponse> {
    let param = {
      projectUuid: props.projectUuid,
      all: props.all,
      team: props.team,
      ...toPlainSearchFilterForApi(props.searchFilter),
    }
    if (props.searchFilter && props.searchFilter.task) {
      objectUtil.setValue(param, 'displayName', props.searchFilter.task)
    }
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/find',
      this.removeNameField(param)
    )
  }

  private removeNameField(param) {
    let result = _.cloneDeep(param)
    if (result.deliverable) {
      delete result.deliverable.name
    }
    if (result.accountable) {
      delete result.accountable.name
    }
    if (result.responsible) {
      delete result.responsible.name
    }
    if (result.assignee) {
      delete result.assignee.name
    }
    if (result.sprint) {
      delete result.sprint.name
    }
    if (result.team) {
      delete result.team.name
    }
    if (result.task) {
      delete result.task.name
      delete result.task.displayName
      delete result.task.uuid
    }
    if (result.disableRestore) {
      delete result.disableRestore
    }
    return result
  }

  getTaskActualWorkByTaskUuid(taskUuid: string): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/task',
      {
        taskUuid,
      }
    )
  }

  updateBatchDelta(
    request: TaskActualWorkUpdateBatchDeltaRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/task_actual_works/delta/batch',
      request
    )
  }

  update(request: TaskActualWorkUpdateRequest): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/projects/task_actual_works/save',
      request
    )
  }

  getActualHourByDate(
    request: GetActualHourByDateRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/hour',
      request
    )
  }

  getActualHoursByUser(
    request: GetActualHoursByUserRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/user',
      request
    )
  }

  getTaskActualWorkList(
    request: GetTaskActualWorkListRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/list',
      request
    )
  }

  getTaskActualWorkHours(
    request: GetTaskActualWorkHoursRequest
  ): Promise<APIResponse> {
    return API.functional.request(
      'GET',
      '/api/v1/projects/task_actual_works/hours',
      request
    )
  }
}

export default new TaskActualWorkApi()
