import { useCallback, useState } from 'react'
import { Tooltip } from '@mui/material'
import * as validator from '../../../../lib/validator'
import { EditableIcon } from '../text'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import ViewMeta from '../../../../../../meta/ViewMeta'
import { GridApi } from 'ag-grid-community'
import './style.scss'

interface Props {
  id?: string
  value: string
  icon: JSX.Element
  data: any
  uiMeta: FunctionProperty
  viewMeta: ViewMeta
  api?: GridApi
  enableTooltip?: boolean
  editable?: boolean
  hasDetail?: boolean
  iconDisabled?: boolean
  onIconClicked?: (e: any) => void
}

const AnnotationHasData = () => {
  return <p className="sevend-ag-cell-icon-text__annotation-has-data" />
}

const IconField = ({
  icon,
  disabled,
  hasDetail,
  onIconClicked,
}: {
  icon: JSX.Element
  disabled?: boolean
  hasDetail?: boolean
  onIconClicked?: (e: any) => void
}) => {
  return (
    <div className="sevend-ag-cell-icon-text__icon-field">
      {onIconClicked ? (
        <button
          className="sevend-ag-cell-icon-text__icon--button"
          disabled={disabled}
          onClick={onIconClicked}
        >
          {icon}
        </button>
      ) : (
        <div className="sevend-ag-cell-icon-text__icon--not-button">{icon}</div>
      )}
      {hasDetail && <AnnotationHasData />}
    </div>
  )
}

const TextField = ({
  value,
  hasError,
  onClick,
  onMouseLeave,
}: {
  value: string
  hasError: boolean
  onClick: () => void
  onMouseLeave: () => void
}) => {
  return (
    <div
      className="sevend-ag-cell-icon-text__text-field"
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      style={{
        backgroundColor: hasError ? validator.inputErrorColor : undefined,
      }}
    >
      {value}
    </div>
  )
}

const validate = (value, data, uiMeta, viewMeta, api): string | undefined => {
  const message = validator.validate(value, data, uiMeta, viewMeta, api)
  if (message) {
    return message
  } else if (data.errorMessages && uiMeta.externalId in data.errorMessages) {
    return data.errorMessages[uiMeta.externalId]
  }
  return undefined
}

const IconTextField = ({
  id,
  onIconClicked,
  icon,
  editable,
  value,
  data,
  uiMeta,
  viewMeta,
  api,
  hasDetail,
  iconDisabled,
  enableTooltip,
}: Props) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  const openTooltip = useCallback(() => {
    setTooltipIsOpen(!!enableTooltip && true)
  }, [enableTooltip, setTooltipIsOpen])
  const closeTooltip = useCallback(() => {
    setTooltipIsOpen(false)
  }, [setTooltipIsOpen])

  const errorMessage = validate(value, data, uiMeta, viewMeta, api)

  return (
    <Tooltip
      id={id}
      className="sevend-ag-cell-icon-text"
      title={errorMessage || value}
      placement="bottom-start"
      open={tooltipIsOpen}
      classes={{
        tooltip: 'sevend-ag-cell-icon-text__tooltip',
      }}
    >
      <div className="sevend-ag-cell-icon-text__icon-and-text">
        <IconField
          icon={icon}
          disabled={iconDisabled}
          hasDetail={hasDetail}
          onIconClicked={onIconClicked}
        />
        <TextField
          value={value}
          hasError={!!errorMessage}
          onClick={openTooltip}
          onMouseLeave={closeTooltip}
        />
        {editable && <EditableIcon />}
      </div>
    </Tooltip>
  )
}

export default IconTextField
