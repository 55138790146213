import { useMemo } from 'react'
import {
  Kpi,
  KpiValuesColumn,
  WidgetContent,
  WidgetForExpanded,
  WidgetTitle,
  WidgetWithTitle,
} from '.'
import {
  ProjectProgress,
  ProjectResource,
} from '../../../../domain/value-object/ProjectReportVO'
import { intl } from '../../../../i18n'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { useWorkloadUnit } from '../../../hooks/useWorkloadUnit'

type ProjectResourceWidgetProps = Pick<ProjectProgress, 'ac'> & ProjectResource

export const ProjectResourceWidget = ({
  total,
  devoted,
  planned,
  ac,
}: ProjectResourceWidgetProps) => {
  const workloadUnitState = useWorkloadUnit(WorkloadUnit.MONTH)
  const { totalResource, devotedResource, plannedResource } = useMemo(() => {
    return {
      totalResource: total.toFixed(1),
      devotedResource: devoted.toFixed(1),
      plannedResource: planned.toFixed(1),
    }
  }, [devoted, planned, total])
  const acByMonth = useMemo(() => {
    return workloadUnitState
      .convertWorkloadFromHourToSelectedUnit(ac)
      .toFixed(1)
  }, [ac, workloadUnitState])
  const unit = useMemo(() => {
    return intl.formatMessage({ id: 'unit.workload.month' })
  }, [])
  return (
    <WidgetWithTitle>
      <WidgetTitle>
        {intl.formatMessage({ id: 'enterpriseProjectReport.resource' })}
      </WidgetTitle>
      <WidgetForExpanded>
        <WidgetContent>
          <Kpi
            title={intl.formatMessage({
              id: 'enterpriseProjectReport.totalResource',
            })}
            value={totalResource}
            unit={unit}
            size="l"
          />
          <KpiValuesColumn>
            <Kpi
              title={intl.formatMessage({
                id: 'enterpriseProjectReport.devotedResource',
              })}
              value={devotedResource}
              unit={unit}
              size="l"
            />
            <Kpi
              title={intl.formatMessage({
                id: 'enterpriseProjectReport.actualCost',
              })}
              value={acByMonth}
              unit={unit}
              size="l"
            />
          </KpiValuesColumn>
          <Kpi
            title={intl.formatMessage({
              id: 'enterpriseProjectReport.plannedResource',
            })}
            value={plannedResource}
            unit={unit}
            size="l"
          />
        </WidgetContent>
      </WidgetForExpanded>
    </WidgetWithTitle>
  )
}
