import React, { CSSProperties } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLessRounded'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
import Typography from '@mui/material/Typography'
import components, { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../../../pages'
import SideMenuCategoryBase, { Props, State } from './SideMenuCategoryBase'
import {
  Directory,
  Function as AppFunction,
} from '../../../../../lib/commons/appFunction'
import { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import { ProjectDetail } from '../../../../../lib/functions/project'
import DateRangeIcon from '@mui/icons-material/DateRangeRounded'
import ScheduleIcon from '@mui/icons-material/ScheduleRounded'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import AccountCircleIcon from '@mui/icons-material/AccountCircleRounded'
import DescriptionIcon from '@mui/icons-material/DescriptionRounded'
import AssignmentIcon from '@mui/icons-material/AssignmentRounded'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { styled } from '@mui/system'
import {
  BackgroundColor,
  Color,
  FontSize,
  TextColor,
} from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import { open } from '../../../../router'
import { fromCamelToSnake } from '../../../../../utils/string'
import { isProduction } from '../../../../../utils/urls'
import {
  convertTicketExternalIdToTicketType,
  isUsingTicketComponent,
} from '../../../../../lib/functions/ticket'

const listItemStyle: CSSProperties = {
  borderRadius: '4px',
  height: '33px',
  padding: '0',
}
const DirectoryItem = styled(ListItemButton)({
  ...listItemStyle,
  '&:hover': {
    background: 'transparent',
  },
})
const StyledListItem = styled(ListItem)({ ...listItemStyle })
const FunctionItem = styled(ListItemButton)<{ selected: boolean }>(
  ({ selected }) => {
    const defaultStyle = {
      ...listItemStyle,
      minHeight: '23px',
    }
    if (selected) {
      return {
        ...defaultStyle,
        background: colorPalette.monotone[1],
        color: Color.MONOTONE,
      }
    } else {
      return {
        ...defaultStyle,
        '&:hover': {
          background: BackgroundColor.FOCUS_BULE,
          color: TextColor.FOCUS_BULE,
        },
      }
    }
  }
) as typeof ListItemButton
const FunctionItemName = styled(ListItemText)({
  letterSpacing: 0.52,
  fontSize: FontSize.MEDIUM,
  marginLeft: '15px',
  padding: '5px 0',
})

export interface DirectoryProps extends Props {
  directory: Directory
  selectedFunction?: string
  project?: ProjectDetail
  defaultOpen?: boolean
}

interface MenuItem {
  isDirectory: boolean
  item: Directory | AppFunction
}

// The use of React.forwardRef will no longer be required for react-router-dom v6.
// See https://github.com/ReactTraining/react-router/issues/6056
const NavLinkRef = React.forwardRef((props: any, ref: any) => (
  <NavLink innerRef={ref} {...props} />
))

class DirectoryMenu extends SideMenuCategoryBase<DirectoryProps, State> {
  private isSelected = (externalId: string): boolean =>
    (this.props.selectedFunction || '').replace(/\.(create|update)$/, '') ===
    externalId

  render() {
    const { directory } = this.props

    const renderMenuItem = (item: MenuItem) => {
      if (item.isDirectory) {
        const dir = item.item as Directory
        return (
          <div key={dir.name} style={{ paddingLeft: '14px !important' }}>
            <DirectoryMenu {...this.props} directory={dir} />
          </div>
        )
      } else {
        const func = item.item as AppFunction
        if (func.hidden) {
          return null
        }
        const component = components[func.externalId]
        if (!component) {
          return (
            <StyledListItem key={func.externalId} disabled={true}>
              <FunctionItemName primary={`${func.name}`} />
            </StyledListItem>
          )
        }

        // If the component is a TICKETS, add a ticket type query parameter to the URL
        const ticketTypeQueryParam = isUsingTicketComponent(component)
          ? convertTicketExternalIdToTicketType(func.externalId)
          : ''

        const pathname =
          component.defaultPath +
          (this.props.project ? '/' + this.props.project.code : '') +
          ticketTypeQueryParam
        return (
          <FunctionItem
            alignItems="flex-start"
            id={`menu-${func.externalId}`}
            key={func.externalId}
            selected={this.isSelected(func.externalId)}
            component={NavLinkRef}
            to={{
              pathname,
            }}
            onClick={e => {
              e.preventDefault()
              open(pathname, e)
            }}
          >
            <FunctionItemName primary={`${func.name}`} />
          </FunctionItem>
        )
      }
    }

    let menuItems: MenuItem[] = []
    directory.directories.forEach(dir => {
      menuItems.push({
        isDirectory: true,
        item: dir,
      })
    })
    directory.functions?.forEach(func => {
      menuItems.push({
        isDirectory: false,
        item: func,
      })
    })
    menuItems.sort((a, b) => {
      if (a.item.displayOrder === b.item.displayOrder) {
        return a.isDirectory ? 1 : 0
      }
      return a.item.displayOrder - b.item.displayOrder
    })

    const getDirectoryIcon = (dir: Directory) => {
      const iconStyles = { color: Color.MONOTONE }
      switch (dir.externalId) {
        case 'project.planning':
          return <DateRangeIcon sx={iconStyles} />
        case 'project.workPlan':
          return <ScheduleIcon sx={iconStyles} />
        case 'project.management':
          return <SettingsIcon sx={iconStyles} />
        case 'project.members':
          return <AccountCircleIcon sx={iconStyles} />
        case 'project.tickets':
          return <DescriptionIcon sx={iconStyles} />
        case 'project.reports':
          return <AssignmentIcon sx={iconStyles} />
        case 'project.cost.management':
          return <MonetizationOnIcon sx={iconStyles} />
        default:
          return
      }
    }

    return (
      <>
        <DirectoryItem onClick={this.handleToggleExpand}>
          {getDirectoryIcon(directory)}
          <ListItemText
            disableTypography={true}
            primary={
              <Typography
                sx={{
                  letterSpacing: '0.52px',
                  color: colorPalette.monotone[10],
                  fontWeight: 500,
                  fontSize: '14px',
                  marginLeft: '5px',
                }}
              >
                {directory.name}
              </Typography>
            }
            sx={{ padding: '16px 0' }}
          />
          {this.state.isOpened ? (
            <ExpandLess sx={{ color: Color.MONOTONE }} />
          ) : (
            <ExpandMore sx={{ color: Color.MONOTONE }} />
          )}
        </DirectoryItem>
        <Collapse in={this.state.isOpened} timeout="auto" unmountOnExit={true}>
          <List
            component="div"
            disablePadding={true}
            sx={{
              gap: '4px',
              display: 'grid',
              paddingLeft: '20px',
              fontSize: '14px',
              fontWeight: 500,
              color: Color.MONOTONE,
              padding: '16px 0 16px 0',
            }}
          >
            {menuItems.map(item => renderMenuItem(item))}
          </List>
        </Collapse>
      </>
    )
  }
}

const mapStateToProps = (state: AllState) => ({
  selectedFunction: state.appFunction.currentExternalId,
  project: state.project.current,
})

export default connect(mapStateToProps)(withRouter(DirectoryMenu))
