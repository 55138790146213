import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import _ from 'lodash'
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community'
import { APIResponse } from '../../../../../../lib/commons/api'
import { BulkSheetFilter, FilterFooter } from '../common'
import { Checkbox, Typography } from '@mui/material'
import { useWbsItemTypeFilter } from './useWbsItemTypeFilter'
import {
  CheckboxArea,
  FilterLabel,
  SelectFilter,
  SelectFilterArea,
  SelectFilterFormControl,
} from '../SelectFilter/index'
import { WbsItemIcon } from '../../../../../components/icons/WbsItemIcon'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../../store'
import usePrevious from '../../../../../hooks/usePrevious'

type Props = IFilterParams & {
  fetch: (v: SelectFilter) => Promise<APIResponse>
}

export const ServerSideWbsItemTypeFilter = forwardRef(
  ({ fetch, filterChangedCallback, context }: Props, ref) => {
    const hasRequiredSaveData = useSelector<AllState>(
      state => state.hasRequiredSaveData.hasRequiredSaveData
    )
    const prevHasRequiredSaveData = usePrevious(hasRequiredSaveData)

    // State
    const filter = useWbsItemTypeFilter(context['wbsItemType'])
    const [loading, isLoading] = useState(false)
    const [filteredIds, setFilteredIds] = useState<string[] | undefined>(
      undefined
    )

    const addedRowIds = useRef<string[]>() // Need to remember added row ids until re-fetch filtered ids.
    useEffect(() => {
      if (addedRowIds.current) return
      addedRowIds.current = []
    }, [])
    const fetchFilteredIds = async (v: SelectFilter) => {
      const response = await fetch(v)
      setFilteredIds(response.json)
    }
    const search = useCallback(
      _.debounce(async (v: SelectFilter) => {
        try {
          isLoading(true)
          await fetchFilteredIds(v)
        } finally {
          isLoading(false)
        }
      }, 300),
      []
    )

    // Ag-grid custom filter
    useImperativeHandle(ref, () => {
      return {
        doesFilterPass(params: IDoesFilterPassParams) {
          // Don't filter new rows
          if (params.data.added) {
            if (addedRowIds.current && params.node.id) {
              addedRowIds.current.push(params.node.id)
            }
            return true
          }
          if (
            addedRowIds.current &&
            params.node.id &&
            addedRowIds.current.includes(params.node.id)
          ) {
            return true
          }
          if (!filteredIds) return true
          if (filter.isAllDeselected()) return false
          return params.node.id && filteredIds.includes(params.node.id)
        },

        isFilterActive() {
          return !!filteredIds
        },

        getModel() {
          return filter.model()
        },

        setModel(model?: { values: string[] }) {
          if (!model) {
            filter.reset()
            return
          }
          filter.setValues(model.values)
        },

        getModelAsString() {
          return filter.getModelAsString()
        },

        onNewRowsLoaded() {
          if (hasRequiredSaveData) return // Re-fetch filtered ids only when BulkSheet refreshed.
          const filterModel = filter.model()
          if (filterModel) {
            // @ts-ignore
            fetchFilteredIds(filterModel)
          }
        },
      }
    })

    // Filter
    useEffect(() => {
      if (!filter.isActive()) {
        setFilteredIds(undefined)
        return
      }
      if (filter.isAllDeselected()) {
        setFilteredIds([])
        return
      }
      search(filter.model())
    }, [filter.values])

    useEffect(() => {
      const filterModel = filter.model()
      const isAfterSavedOrRefreshed =
        prevHasRequiredSaveData && !hasRequiredSaveData
      if (isAfterSavedOrRefreshed && filter.isActive() && filterModel) {
        // @ts-ignore
        fetchFilteredIds(filterModel).then(() => {
          addedRowIds.current = []
        })
      }
    }, [hasRequiredSaveData])

    useEffect(() => {
      filterChangedCallback()
    }, [filteredIds])

    return (
      <BulkSheetFilter>
        <SelectFilterArea>
          <SelectFilterFormControl
            label="(Select All)"
            control={
              <Checkbox
                checked={filter.isAllSelected()}
                indeterminate={
                  !filter.isAllSelected() && !filter.isAllDeselected()
                }
                onChange={e =>
                  e.target.checked ? filter.selectAll() : filter.deselectAll()
                }
                disableRipple={true}
              />
            }
          />
          <CheckboxArea>
            {filter.options.map(option => {
              return (
                <SelectFilterFormControl
                  key={`type-select-filter-${option.code}`}
                  label={
                    <FilterLabel>
                      <WbsItemIcon type={option} />
                      <Typography sx={{ padding: '0 3px' }}>
                        {option.getNameWithSuffix()}
                      </Typography>
                    </FilterLabel>
                  }
                  control={
                    <Checkbox
                      checked={filter.values.includes(
                        option.baseTicketUuid ?? option.uuid
                      )}
                      onChange={e => {
                        if (e.target.checked) {
                          filter.setValues([
                            ...filter.values,
                            option.baseTicketUuid ?? option.uuid,
                          ])
                        } else {
                          filter.setValues(
                            filter.values.filter(
                              v => v !== (option.baseTicketUuid ?? option.uuid)
                            )
                          )
                        }
                      }}
                      disableRipple={true}
                    />
                  }
                />
              )
            })}
          </CheckboxArea>
        </SelectFilterArea>
        <FilterFooter loading={loading} onClick={() => filter.reset()} />
      </BulkSheetFilter>
    )
  }
)
