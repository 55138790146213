import { useEffect, useState } from 'react'
import {
  getProjectPlanPath,
  getProjectPlanSiblings,
  getTree,
} from '../../../../lib/functions/projectPlan'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'

export type ProjectPlanBreadcrumb = {
  uuid: string
  name: string
  type: {
    iconUrl: string
  }
}

const mapDtoToModel = (src: any): ProjectPlanBreadcrumb => {
  return {
    uuid: src.uuid,
    name: src.wbsItem.name,
    type: {
      iconUrl: src.wbsItem.typeDto.iconUrl,
    },
  }
}
const flattenNestToArray = (src: any) => {
  const array: any[] = []
  const pushRecursive = (parent: any) => {
    array.push(parent)
    if (parent.child) {
      pushRecursive(parent.child)
    }
  }
  pushRecursive(src)
  return array
}

export const useProjectPlanBreadcrumbs = (
  rootProjectPlanUuid: string | undefined
): ProjectPlanBreadcrumb[] => {
  const [breadcrumbs, setBreadcrumbs] = useState<ProjectPlanBreadcrumb[]>([])
  useEffect(() => {
    const fn = async () => {
      if (!rootProjectPlanUuid) {
        setBreadcrumbs([])
        return
      }
      const response = await getProjectPlanPath(rootProjectPlanUuid)
      const dtoArray = flattenNestToArray(response.json)
      setBreadcrumbs(dtoArray.map(dto => mapDtoToModel(dto)))
    }
    fn()
  }, [rootProjectPlanUuid])
  return breadcrumbs
}

export const useProjectPlanSiblings = (projectPlanUuid: string) => {
  const [siblings, setSiblings] = useState<ProjectPlanBreadcrumb[]>([])
  useEffect(() => {
    const fn = async () => {
      const response = await getProjectPlanSiblings(projectPlanUuid)
      setSiblings(response.json)
    }
    fn()
  }, [projectPlanUuid])
  return siblings
}

export const useProjectPlanDirectChildren = (
  projectPlanUuid: string | undefined
) => {
  const { projectUuid } = useProjectPrivateContext()
  const [children, setChildren] = useState<ProjectPlanBreadcrumb[]>([])
  useEffect(() => {
    const fn = async () => {
      const response = await getTree(
        projectUuid,
        [
          WbsItemType.WORKGROUP,
          WbsItemType.PROCESS,
          WbsItemType.DELIVERABLE_LIST,
          WbsItemType.DELIVERABLE,
          WbsItemType.TASK,
        ],
        projectPlanUuid,
        1
      )
      setChildren(
        response.json.children.map(child => ({
          uuid: child.uuid,
          name: child.wbsItem.displayName,
          type: child.wbsItem.typeDto,
        }))
      )
    }
    fn()
  }, [projectPlanUuid, projectUuid])
  return children
}
