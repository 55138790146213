import React from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Checkbox,
  MenuItem,
  TextField,
} from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import MultiSelectVO from '../../../../../../vo/MultiSelectVO'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { CustomEnumValue } from '../../../../../../lib/commons/appFunction'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
})
const Option = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& .MuiCheckbox-root': { marginRight: '0px !important' },
  '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
})

interface MultiSelectCellProps extends CellPropsBase<MultiSelectVO> {}

interface MultiSelectCellState extends CellStateBase {}

class MultiSelectCell extends DataCellBase<
  MultiSelectVO,
  MultiSelectCellProps,
  MultiSelectCellState
> {
  constructor(props) {
    super(props)
  }
  private ref = React.createRef<HTMLInputElement>()

  validate = (value: MultiSelectVO) => {
    return undefined
  }

  onChange = (event: any, value: any) => {
    this.props.onChange(new MultiSelectVO(value))
  }

  render() {
    const { value, data, cellDef, colSpan } = this.props
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    return (
      <RootDiv>
        <Autocomplete
          multiple={true}
          size={'small'}
          options={cellDef.uiMeta.valuesAllowed}
          getOptionLabel={option => (option.name ? option.name : '')}
          renderOption={(props, option, { selected }) => {
            return (
              <Option {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color={'primary'}
                />
                <span>{option.name}</span>
              </Option>
            )
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              variant={'standard'}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
          isOptionEqualToValue={(value, option) => {
            return value.value === option.value
          }}
          filterOptions={(options: CustomEnumValue[]) => {
            return options.filter(option => {
              return option.value !== 'NONE'
            })
          }}
          forcePopupIcon={true}
          autoSelect={false}
          blurOnSelect={false}
          value={value ? value.getValue() : []}
          onChange={this.onChange}
          disableCloseOnSelect={true}
        />
      </RootDiv>
    )
  }
}

export default MultiSelectCell
