import { useCallback } from 'react'
import { WorkReportRepository } from '../../applications/ports/workReportRepository'
import { MonthlyWorkReportVO } from '../../domain/value-object/WorkReportVO'
import {
  YearMonthVO,
  yearMonthService,
} from '../../domain/value-object/YearMonthVO'
import API from '../../lib/commons/api'

export const useWorkReportRepositroy = (): WorkReportRepository => {
  const getMonthlyWorkReport = useCallback(
    async (
      projectUuid: string,
      yearMonth: YearMonthVO,
      teamUuid?: string
    ): Promise<MonthlyWorkReportVO[]> => {
      const response = await API.functional.request(
        'GET',
        '/api/v1/projects/work_report/monthly',
        { projectUuid, year: yearMonth.year, month: yearMonth.month, teamUuid },
        true
      )
      return response.json.map(res => ({
        yearMonth: yearMonthService.newYearMonthVO(res.year, res.month)!,
        user: {
          uuid: res.user.uuid,
          name: res.user.name,
          iconUrl: res.user.iconUrl,
        },
        plannedHour: res.plannedHour,
        actualHour: res.actualHour,
      }))
    },
    []
  )
  return { getMonthlyWorkReport }
}
