import rehypeParser from 'rehype-parse'
import rehypeRemark, { all as rehypeAll, one } from 'rehype-remark'
import rehypeStringify from 'rehype-stringify'
import remarkParser from 'remark-parse'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'
import remarkRehype, { all as remarkAll } from 'remark-rehype'
import remarkStringify from 'remark-stringify'
import { unified } from 'unified'
import { u } from 'unist-builder'
import { list } from 'mdast-util-to-markdown/lib/handle/list'
import { listItem } from 'mdast-util-to-markdown/lib/handle/list-item'
import { li as listToMdast } from 'hast-util-to-mdast/lib/handlers/li'
import { root } from 'mdast-util-to-markdown/lib/handle/root'
import { table as tableToMdast } from 'hast-util-to-mdast/lib/handlers/table'
import { wrap as wrapMdast } from 'hast-util-to-mdast/lib/util/wrap'
import { ElementContent } from 'hast'
import { escapeBreakText, tableHandler } from './markdown'
import { Node } from 'mdast-util-to-markdown/lib'
import { Content, Parent } from 'mdast-util-to-markdown/lib/types'
import { Node as NodeHastToMadst } from 'hast-util-to-mdast/lib/'

export const convertMarkdownToHtml = (src: string): string => {
  const empty = '${empty_row}'
  const replacedSrc = escapeBreakText(src, empty)
  const value = unified()
    .use(remarkParser)
    .use(remarkBreaks)
    .use(remarkGfm)
    .use(remarkRehype, {
      handlers: {
        code(h, node) {
          let value = node.value || ''
          const lang = node.lang
            ? node.lang.match(/^[^ \t]+(?=[ \t]|$)/)
            : 'text'
          const code = h(node, 'code', { className: 'language-' + lang }, [
            u('text', value),
          ])
          if (node.meta) {
            code.data = { meta: node.meta }
          }
          return h(node, 'pre', [code])
        },
        list(h, node) {
          const props: {
            [PropertyName: string]:
              | boolean
              | number
              | string
              | null
              | undefined
              | Array<string | number>
          } = {}
          const name = node.ordered ? 'ol' : 'ul'
          const items = remarkAll(h, node)
          let index = -1
          if (typeof node.start === 'number' && node.start !== 1) {
            props.start = node.start
          }
          const wrapped: Array<ElementContent> = []
          wrapped.push(u('text', '\n'))
          while (++index < items.length) {
            const item = items[index]
            if (
              item.type === 'element' &&
              item.tagName === 'li' &&
              item.properties &&
              item.properties.dataType === 'taskItem'
            ) {
              props.dataType = 'taskList'
            }
            if (index) wrapped.push(u('text', '\n'))
            wrapped.push(item)
          }
          if (items.length > 0) {
            wrapped.push(u('text', '\n'))
          }
          return h(node, name, props, wrapped)
        },
        listItem(h, node, parent) {
          const props: {
            [PropertyName: string]:
              | boolean
              | number
              | string
              | null
              | undefined
              | Array<string | number>
          } = {}
          const result = remarkAll(h, node)
          const spread = parent ? listSpread(parent) : listItemSpread(node)
          if (typeof node.checked === 'boolean') {
            props.dataType = 'taskItem'
            props.dataChecked = node.checked ? 'true' : 'false'
          }
          const wrapped: Array<ElementContent> = []
          let index = -1
          while (++index < result.length) {
            const child = result[index]
            if (
              spread ||
              index !== 0 ||
              child.type !== 'element' ||
              child.tagName !== 'p'
            ) {
              wrapped.push(u('text', '\n'))
            }
            if (child.type === 'element' && child.tagName === 'p' && !spread) {
              let isOutput = true
              if (child.children[0]) {
                const childValue: any = child.children[0]
                const checkValue = childValue.value?.match(/\[(.)\]/)
                if (checkValue) {
                  props.dataType = 'taskItem'
                  props.dataChecked = checkValue[1] === 'x' ? 'true' : 'false'
                  isOutput = false
                }
              }
              if (isOutput) {
                wrapped.push(...child.children)
              }
            } else {
              wrapped.push(child)
            }
          }
          const tail = result[result.length - 1]
          if (
            tail &&
            (spread || !('tagName' in tail) || tail.tagName !== 'p')
          ) {
            wrapped.push(u('text', '\n'))
          }
          return h(node, 'li', props, wrapped)
        },
        table(h, node) {
          return tableHandler(h, node, false)
        },
      },
      allowDangerousHtml: true,
    })
    .use(rehypeStringify)
    .processSync(replacedSrc)
  return String(value).split(empty).join('')
}

export const convertHtmlToMarkdown = (src: string): string => {
  const value = unified()
    .use(rehypeParser)
    .use(remarkGfm, {
      tableCellPadding: false,
      tablePipeAlign: false,
    })
    .use(rehypeRemark, {
      handlers: {
        li(h, node) {
          if (node.properties && node.properties.dataType === 'taskItem') {
            return listItemToMdast(h, node)
          }
          return listToMdast(h, node)
        },
        table(h, node) {
          return makeMarkdownTable(h, node)
        },
        p(h, code) {
          const nodes: NodeHastToMadst[] = code.children || []
          const values: Content[] = []
          let index = -1
          let length = nodes.length
          while (++index < length) {
            const result = one(h, nodes[index], code)
            if (Array.isArray(result)) {
              values.push(...result)
            } else if (result) {
              values.push(result)
            }
          }
          const paragraph = h(code, 'paragraph', values)
          if (!paragraph) {
            return {
              type: 'paragraph',
              children: [],
              position: code.position,
            }
          }
          return paragraph
        },
      },
    })
    .use(remarkStringify, {
      bullet: '-',
      strong: '_',
      resourceLink: true,
      tightDefinitions: true,
      listItemIndent: 'one',
      handlers: {
        code(node, parent, context, options) {
          const lang = node.lang || ''
          const raw = node.value || ''
          return `\`\`\`${lang}\n${raw}\n\`\`\``
        },
        break(node, parent, context, options) {
          return '\n'
        },
        list(node, parent, context, options) {
          const item = list(node, parent, context, options)
          return item.replace(/\n\n/g, '\n')
        },
        listItem(node, parent, context, options) {
          if (node.checked) {
            return makeMarkdownTaskListItem(node, parent, 0)
          }
          return listItem(node, parent, context, options)
        },
        root(node, parent, context, options) {
          context.join.push(
            (left: Node, right: Node, parent: Parent, context) => {
              return 0
            }
          )
          const r = root(node, parent, context, options)
          return r
        },
      },
    })
    .processSync(src)
  let md = String(value)
  if (md.slice(-1) === '\n') {
    md = md.slice(0, -1)
  }
  return md
}

export const listSpread = node => {
  let spread = node.spread
  const children = node.children
  let index = -1
  while (!spread && ++index < children.length) {
    spread = listItemSpread(children[index])
  }
  return Boolean(spread)
}

export const listItemSpread = node => {
  const spread = node.spread
  return spread === undefined || spread === null
    ? node.children.length > 1
    : spread
}

const makeMarkdownTaskListItem = (node, parent, count: number) => {
  const checked = node.checked
  const spaces = ' '.repeat(count)
  let value = ''
  node.children.map(child => {
    if (child.type === 'paragraph') {
      child.children.map(gchild => {
        if (gchild.type === 'text') {
          value = value + gchild.value
        } else if (gchild.type === 'break') {
          value = value + '\n'
        }
      })
    } else if (child.type === 'list') {
      value =
        value +
        '\n' +
        makeMarkdownTaskListItem(child.children[0], node, count + 2)
    }
  })
  const checkValue = checked === 'true' ? 'x' : ' '
  return `${spaces}- [${checkValue}] ${value}`
}

const listItemToMdast = (h, node) => {
  const head = node.children[0]
  const checked = node.properties.dataChecked
  const clone = {
    ...node,
    children: [
      { ...head, children: head.children.slice(1) },
      ...node.children.slice(1),
    ],
  }
  const content = wrapMdast(rehypeAll(h, clone))
  const item = h(
    node,
    'listItem',
    { spread: content.length > 1, checked },
    content
  )
  return item
}

const makeMarkdownTable = (h, node) => {
  const tbl: any = tableToMdast(h, node)
  tbl.children?.map(rowItem => {
    rowItem.children?.map(cellItem => {
      if (!cellItem.children || cellItem.children.length < 1) return
      concatCellText(h, node, cellItem)
    })
  })
  return tbl
}

const concatCellText = (h, node, child) => {
  const wrapped: Array<ElementContent> = []
  let index = -1
  let value = ''
  while (++index < child.children.length) {
    const gchild = child.children[index]
    let subIndex = -1
    while (gchild.children && ++subIndex < gchild.children.length) {
      const subChild = gchild.children[subIndex]
      if (subChild.type !== 'text') {
        if (value.trim().length > 0) {
          wrapped.push(u('text', value))
          value = ''
        }
        wrapped.push(subChild)
      } else {
        let subValue = gchild.children[subIndex].value
        if (!subValue || subValue.trim().length === 0) subValue = '  '
        value = value + subValue
      }
    }
    if (index !== child.children.length - 1) {
      value = value + '  '
    }
  }
  if (value.trim().length !== 0) {
    wrapped.push(u('text', value))
  }
  child.children = wrapped
}
