import {
  ToggleButtonGroup,
  ToggleButton,
  useOnChangeToggleButtonGroup,
} from '.'
import { intl } from '../../../../i18n'
import { WorkloadUnit } from '../../../../lib/functions/workload'

type WorkloadUnitToggleButtonGroupProps = {
  value: WorkloadUnit
  onChange: (_: WorkloadUnit) => void
}

export const WorkloadUnitToggleButtonGroup = ({
  value,
  onChange: _onChange,
}: WorkloadUnitToggleButtonGroupProps) => {
  const [onChange] = useOnChangeToggleButtonGroup(_onChange)
  return (
    <ToggleButtonGroup value={value} onChange={onChange}>
      <ToggleButton value={WorkloadUnit.HOUR}>
        {intl.formatMessage({
          id: 'workloadUnit.hour',
        })}
      </ToggleButton>
      <ToggleButton value={WorkloadUnit.DAY}>
        {intl.formatMessage({
          id: 'workloadUnit.day',
        })}
      </ToggleButton>
      <ToggleButton value={WorkloadUnit.MONTH}>
        {intl.formatMessage({
          id: 'workloadUnit.month',
        })}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
