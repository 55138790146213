import { FilterSelect } from '../common'
import {
  EntitySearchFilterType,
  getEntitySearchFilterTypeLabel,
} from './EntitySearchFilterModel'

export const FilterTypeSelect = ({
  filterType,
  onChange,
}: {
  filterType: EntitySearchFilterType
  onChange: (value: EntitySearchFilterType) => void
}) => {
  return (
    <FilterSelect
      variant="outlined"
      value={filterType}
      onChange={e => onChange(e.target.value as EntitySearchFilterType)}
      sx={{ width: '100px' }}
    >
      {Object.values(EntitySearchFilterType).map(v => (
        <option key={`text-filter-option-${v}`} value={v}>
          {getEntitySearchFilterTypeLabel(v)}
        </option>
      ))}
    </FilterSelect>
  )
}
