import VOBase from '../base'
import moment from 'moment'

export default class DateTimeVO extends VOBase {
  private readonly value: Date
  private readonly FORMAT = 'YYYY/MM/DD HH:mm:ss'
  private readonly API_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

  constructor(_value: number | string | Date) {
    super()
    if (!_value) {
      throw new Error(
        'Can not create DateTimeVO instance because params is empty.'
      )
    }

    const numberVal = typeof _value === 'string' ? Number(_value) : NaN
    const val = !isNaN(numberVal) ? numberVal : _value

    if (moment(val).isValid()) {
      this.value = moment(val).toDate()
    } else {
      throw new Error(
        `Can not create DateTimeVO instance because not supported params(params: ${_value}).`
      )
    }
  }

  static now = (): DateTimeVO => {
    return new DateTimeVO(new Date())
  }

  getValue = (): Date => {
    return this.value
  }

  format = (fmt?: string): string => {
    return moment(this.value).format(fmt ?? this.FORMAT)
  }
  serialize = () => Date.parse(moment(this.value).format(this.API_FORMAT))

  toNumberValue(): number {
    return this.value.valueOf()
  }

  isEqual(target: VOBase): boolean {
    const datetime = this.format()
    const targetDatetime = target.format()
    return datetime === targetDatetime
  }
}
