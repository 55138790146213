import api, { APIResponse } from '../../../lib/commons/api'
import { UserDetail } from '../../../lib/functions/user'
import { TreeSource } from '../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../containers/BulkSheetView/model'

export class PositionBasic {
  uuid: string
  code: string
  displayName: string
  officialName: string
}

export class UserPositionMappingBody {
  position: PositionBasic
  validFrom: string
}

export class UserPositionSkeleton implements TreeSource<UserPositionSkeleton> {
  uuid: string
  lockVersion: number
  children: UserPositionSkeleton[]
  rootUuid: string

  user: UserDetail
  mappings: UserPositionMappingBody[]
}

export class UserPositionRowBody {
  user: UserDetail | undefined
  mappings: UserPositionMappingBody[]

  constructor(src?: UserPositionSkeleton) {
    this.user = src?.user
    this.mappings = src?.mappings ?? []
  }
}

export interface UserPositionRow extends TreeRow {
  body: UserPositionRowBody
}

export interface UserPositionMappingInput {
  positionUuid: string
  validFrom: string
}

export interface UserPositionsCreateInput {
  uuid: string
  userUuid: string
  mappings: UserPositionMappingInput[]
}

export interface UserPositionsUpdateInput {
  uuid: string
  lockVersion: number
  mappings: UserPositionMappingInput[]
}

export interface UserPositionsDeleteInput {
  uuid: string
  lockVersion: number
}

export interface UserPositionCrudParams {
  added: UserPositionsCreateInput[]
  edited: UserPositionsUpdateInput[]
  deleted: UserPositionsDeleteInput[]
}

export const fetchUserPositions = async (): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/user_positions')
}

export const fetchMasterPositions = async (): Promise<APIResponse> => {
  return api.functional.request('GET', '/api/v1/positions/list')
}

export const crudUserPositions = async (
  params: UserPositionCrudParams
): Promise<APIResponse> => {
  return api.functional.request('POST', '/api/v1/user_positions/batch', params)
}
