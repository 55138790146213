import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { intl } from '../../../../../i18n'
import CancelButton from '../../../../components/buttons/CancelButton'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import { useSlackExternalServiceStorageService } from '../../../../../services/storage-services/external-services/slackExternalServiceStorageService'
import { PreAuthorizationVO } from '../../../../../domain/value-object/Slack/PreAuthorizationVO'
import { useSelector } from 'react-redux'
import { AllState } from '../../../../../store'
import { ProjectDetail } from '../../../../../lib/functions/project'
import LaunchIcon from '@mui/icons-material/Launch'
import {
  hasConnectedData,
  hasFailedData,
} from '../../../../../domain/value-object/Slack/WorkspaceVO'
import { UserDetail } from '../../../../../lib/functions/user'

type SetupSlackProps = {
  open: boolean
  close: () => void
}

const StepArea = styled('div')({
  padding: '18px 6px',
})

const StepTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: '#555555',
  textAlign: 'center',
  padding: '12px 0',
})

const StepContent = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  margin: '12px 12px',
  width: '100%',
  gap: '4px',
})

const StepContentRow = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
})

const StepContentRowSmall = styled('div')({
  fontSize: '14px',
  fontWeight: 400,
  margin: '8px 16px',
})

const StepContentList = styled('div')({
  margin: '8px 16px',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  gap: '4px',
})

const StepContentListRow = styled('div')({
  fontSize: '12px',
  fontWeight: 400,
})

const FormIconButton = styled(IconButton)({
  padding: '2px',
})

enum Steps {
  Authorize = 0,
  SetSlackAppToChannel = 1,
  Complete = 2,
}

export const SetupSlack = ({ open, close }: SetupSlackProps) => {
  const [step, setStep] = useState<number>(Steps.Authorize)
  const incrementStep = useCallback(() => {
    setStep(prev => prev + 1)
  }, [])

  // Show alert when secret is already registered.
  const { getSlackWorkspace, getForPreparingSlackAuthorization } =
    useSlackExternalServiceStorageService()
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [isPreviousFailed, setIsPreviousFailed] = useState<boolean>(false)
  const [isStartedAuthorization, setIsStartedAuthorization] =
    useState<boolean>(false)
  const [isFailed, setIsFailed] = useState<boolean>(false)
  useEffect(() => {
    if (open) {
      // Every time opening the dialog, reset the step.
      setStep(Steps.Authorize)
      const fn = async () => {
        const workspaces = await getSlackWorkspace()
        setIsConnected(hasConnectedData(workspaces))
        setIsPreviousFailed(hasFailedData(workspaces))
        setIsStartedAuthorization(false)
        setIsFailed(false)
      }
      fn()
    }
  }, [open])

  const openSlackAuthorizationPage = useCallback(() => {
    const fn = async () => {
      const { clientId, scope, state, redirectUri }: PreAuthorizationVO =
        await getForPreparingSlackAuthorization()
      const url = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`
      window.open(url, '_blank')
      setIsStartedAuthorization(true)
    }
    fn()
  }, [])

  const checkAndIncrementStep = useCallback(() => {
    const fn = async () => {
      const workspaces = await getSlackWorkspace()
      const failed = hasFailedData(workspaces)
      setIsFailed(failed)
      if (!failed) {
        incrementStep()
      }
    }
    fn()
  }, [])

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{ sx: { height: '450px' } }}
    >
      <DialogTitle>
        {intl.formatMessage({
          id: 'externalService.slack.dialog.title',
        })}
      </DialogTitle>
      <DialogContent>
        {step === Steps.Authorize && (
          <>
            {isConnected && (
              <Alert severity="warning">
                {intl.formatMessage({
                  id: 'externalService.slack.dialog.warning.alreadyConnected',
                })}
              </Alert>
            )}
            {!isFailed && isPreviousFailed && (
              <Alert severity="warning">
                {intl.formatMessage({
                  id: 'externalService.slack.dialog.warning.alreadyStarted',
                })}
              </Alert>
            )}
            {isFailed && (
              <Alert severity="warning">
                {intl.formatMessage({
                  id: 'externalService.slack.dialog.warning.failed',
                })}
              </Alert>
            )}
          </>
        )}
        <Stepper activeStep={step} alternativeLabel={true}>
          <Step key={1} sx={{ flex: 1 }}>
            <StepLabel>
              {intl.formatMessage({
                id: 'externalService.slack.dialog.authorization',
              })}
            </StepLabel>
          </Step>
          <Step key={2} sx={{ flex: 1 }}>
            <StepLabel>
              {intl.formatMessage({
                id: 'externalService.slack.dialog.setSlackAppToChannel',
              })}
            </StepLabel>
          </Step>
          <Step key={3} sx={{ flex: 1 }}>
            <StepLabel>
              {intl.formatMessage({
                id: 'externalService.slack.dialog.setupNotificationSetting',
              })}
            </StepLabel>
          </Step>
        </Stepper>
        {step === Steps.Authorize && <AuthorizeSlackWorkspace />}
        {step === Steps.SetSlackAppToChannel && <SetSlackAppToChannel />}
        {step === Steps.Complete && <SetupComplete />}
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: 'center', padding: '12px' }}
      >
        {step === Steps.Authorize && (
          <>
            {isStartedAuthorization && (
              <SubmitButton
                title={intl.formatMessage({
                  id: 'continue',
                })}
                onClick={checkAndIncrementStep}
                hideIcon={true}
                hideTooltip={true}
                disabled={isFailed}
              />
            )}
            {!isStartedAuthorization && (
              <SubmitButton
                title={intl.formatMessage({
                  id: 'externalService.slack.dialog.authorization.button.startConnection',
                })}
                onClick={openSlackAuthorizationPage}
                hideIcon={true}
                hideTooltip={true}
              />
            )}
            <CancelButton
              onClick={close}
              title={intl.formatMessage({ id: 'close' })}
            />
          </>
        )}
        {step === Steps.SetSlackAppToChannel && (
          <>
            <SubmitButton
              title={intl.formatMessage({
                id: 'continue',
              })}
              onClick={incrementStep}
              hideIcon={true}
              hideTooltip={true}
            />
            <CancelButton
              onClick={close}
              title={intl.formatMessage({ id: 'close' })}
            />
          </>
        )}
        {step === Steps.Complete && (
          <CancelButton
            onClick={close}
            title={intl.formatMessage({ id: 'close' })}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

const AuthorizeSlackWorkspace = () => {
  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.slack.dialog.authorization.description.title',
        })}
      </StepTitle>
      <StepContent>
        <StepContentRow>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.authorization.description1',
          })}
        </StepContentRow>
      </StepContent>
    </StepArea>
  )
}

const SetSlackAppToChannel = () => {
  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.slack.dialog.setSlackAppToChannel.description.title',
        })}
      </StepTitle>
      <StepContent>
        <StepContentRow>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.setSlackAppToChannel.description1',
          })}
        </StepContentRow>
        <StepContentList>
          <StepContentListRow>
            {intl.formatMessage({
              id: 'externalService.slack.dialog.setSlackAppToChannel.description1.list1',
            })}
          </StepContentListRow>
          <StepContentListRow>
            {intl.formatMessage({
              id: 'externalService.slack.dialog.setSlackAppToChannel.description1.list2',
            })}
          </StepContentListRow>
          <StepContentListRow>
            {intl.formatMessage({
              id: 'externalService.slack.dialog.setSlackAppToChannel.description1.list3',
            })}
          </StepContentListRow>
        </StepContentList>
        <StepContentRow sx={{ margin: '4px 0' }}>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.setSlackAppToChannel.description2',
          })}
        </StepContentRow>
      </StepContent>
    </StepArea>
  )
}

const SetupComplete = () => {
  const { currentProject, user } = useSelector<
    AllState,
    { currentProject: ProjectDetail | undefined; user: UserDetail | undefined }
  >(state => ({ currentProject: state.project.current, user: state.user.user }))
  const openSearchProject = useCallback(() => {
    window.open(`${window.location.origin}/projects`)
  }, [])
  const openNotificationSetting = useCallback(() => {
    if (!currentProject) return
    window.open(
      `${window.location.origin}/project/notification/${currentProject.code}`
    )
  }, [currentProject])
  const openUserInfo = useCallback(() => {
    if (!user) return
    window.open(`${window.location.origin}/userEdit/${user.code}`)
  }, [user])

  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.slack.dialog.setupNotificationSetting.description.title',
        })}
      </StepTitle>
      <StepContent>
        <StepContentRow>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.setupNotificationSetting.description1',
          })}
        </StepContentRow>
        {!!currentProject && (
          <StepContentRowSmall>
            {intl.formatMessage({
              id: 'externalService.slack.dialog.setupNotificationSetting.description1.loggedin',
            })}
            <FormIconButton size="small" onClick={openNotificationSetting}>
              <LaunchIcon sx={{ height: '16px', width: '16px' }} />
            </FormIconButton>
          </StepContentRowSmall>
        )}
        {!currentProject && (
          <>
            <StepContentRow>
              {intl.formatMessage({
                id: 'externalService.slack.dialog.setupNotificationSetting.description1.notloggedin1',
              })}
            </StepContentRow>
            <StepContentRowSmall>
              {intl.formatMessage({
                id: 'externalService.slack.dialog.setupNotificationSetting.description1.notloggedin2',
              })}
              <FormIconButton size="small" onClick={openSearchProject}>
                <LaunchIcon sx={{ height: '16px', width: '16px' }} />
              </FormIconButton>
            </StepContentRowSmall>
          </>
        )}
        <StepContentRow>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.setupNotificationSetting.description2',
          })}
        </StepContentRow>
        <StepContentRowSmall>
          {intl.formatMessage({
            id: 'externalService.slack.dialog.setupNotificationSetting.description3',
          })}
          <FormIconButton size="small" onClick={openUserInfo}>
            <LaunchIcon sx={{ height: '16px', width: '16px' }} />
          </FormIconButton>
        </StepContentRowSmall>
      </StepContent>
    </StepArea>
  )
}
