import {
  LoginInput,
  LoginButton,
  LoginCheckbox,
  LoginTitle,
  LoginContainer,
} from './components'
import { intl } from '../../../i18n'
import { TextField, TextFieldProps, styled } from '@mui/material'
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
} from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useChangePassword } from './hooks/changePassword'
import { AuthError } from '../../../error/AuthError'
import { ErrorMessage } from './components/AuthErrorMessage'
import { useAuthenticateUseCase } from '../../../applications/usecases/authentication/authenticationUseCases'

const LoginText = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
const LoginPassword = styled(LoginInput)({
  width: '444px',
})
const CodeBox = styled('div')({
  display: 'flex',
  gap: '8px',
})

const SingleNumberInput = styled((props: TextFieldProps) => (
  <TextField variant="outlined" autoComplete="off" {...props} />
))({
  width: '42px',
  '& .MuiOutlinedInput-input': {
    height: '24px',
    padding: '20px 16px',
    color: '#2D4069',
    caretColor: '#00A0E9',
    fontSize: '16px !important',
    fontWeight: 600,
    '&:focus': {
      backgroundColor: '#F7FCFF',
    },
  },
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      borderColor: '#D8DDE5',
    },
    '&:hover fieldset': {
      borderColor: '#00A0E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00A0E9',
      borderWidth: '1px',
    },
  },
})

const ShowPassword = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '21px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#7B8CAA',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  whiteSpace: 'pre-line',
})
export const ForgotPasswordSubmit = () => {
  const match = useRouteMatch<{ email: string }>()
  const email = useMemo(() => {
    return match.params.email
  }, [match])
  const [resetCode, setResetCode] = useState<string>('')
  const [v0, v1, v2, v3, v4, v5] = useMemo(() => {
    return [
      resetCode.charAt(0),
      resetCode.charAt(1),
      resetCode.charAt(2),
      resetCode.charAt(3),
      resetCode.charAt(4),
      resetCode.charAt(5),
    ]
  }, [resetCode])

  const ref0 = useRef<HTMLInputElement>(null)
  const ref1 = useRef<HTMLInputElement>(null)
  const ref2 = useRef<HTMLInputElement>(null)
  const ref3 = useRef<HTMLInputElement>(null)
  const ref4 = useRef<HTMLInputElement>(null)
  const ref5 = useRef<HTMLInputElement>(null)
  const refs = [ref0, ref1, ref2, ref3, ref4, ref5]

  const validateInput = useCallback((value: string): boolean => {
    return value === '' || /[0-9]/.test(value)
  }, [])
  const onChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ) => {
      setResetCode(prev => {
        const value = event.target.value.charAt(event.target.value.length - 1)
        const validated = validateInput(value)
        const deleted = value === ''
        if (validated) {
          if (!deleted) {
            refs[index + 1]?.current?.focus()
          } else {
            refs[index - 1]?.current?.focus()
          }
          return `${prev.substring(0, index)}${value}${prev.substring(
            index + 1
          )}`
        }
        return prev
      })
    },
    [validateInput, refs]
  )
  const {
    newPassword,
    newPasswordConfirm,
    showPassword,
    inputType,
    onChangeNewPassword,
    onChangeNewPasswordConfirm,
    toggleShowPassword,
  } = useChangePassword()

  const [authError, setAuthError] = useState<AuthError | undefined>(undefined)
  const { resetPasswordSubmit } = useAuthenticateUseCase()
  const history = useHistory()
  const onSubmit = useCallback(async () => {
    if (resetCode.length !== 6) {
      setAuthError(new AuthError('NO_RESET_CODE'))
      return
    }
    if (!newPassword || !newPasswordConfirm) {
      setAuthError(new AuthError('NO_PASSWORD_INPUT'))
      return
    }
    if (newPassword.match(/\s/) || newPasswordConfirm.match(/\s/)) {
      setAuthError(new AuthError('INVALID_CHARACTOR_FOR_PASSWORD'))
      return
    }
    if (newPassword !== newPasswordConfirm) {
      setAuthError(new AuthError('PASSWORD_CONFIRM_UNMATCH'))
      return
    }
    resetPasswordSubmit(email, resetCode, newPassword)
      .then(() => history.push('/changedPassword'))
      .catch(err => {
        if (!(err instanceof AuthError)) {
          throw err
        }
        setAuthError(err)
      })
  }, [
    email,
    history,
    newPassword,
    newPasswordConfirm,
    resetCode,
    resetPasswordSubmit,
  ])
  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )
  const back = useCallback(() => {
    history.push('/forgotPassword')
  }, [history])
  return (
    <>
      <LoginContainer>
        <LoginTitle>
          {intl.formatMessage({ id: 'forgotPassword.resetPassword' })}
        </LoginTitle>
        <LoginText>
          {intl.formatMessage({
            id: 'forgotPasswordSubmit.ifNotReceiveAuthCode',
          })}
        </LoginText>
        {authError && <ErrorMessage authError={authError} />}
        <LoginText>
          {intl.formatMessage({ id: 'forgotPasswordSubmit.authCode' })}
        </LoginText>
        <CodeBox>
          <SingleNumberInput
            inputRef={ref0}
            value={v0}
            onChange={e => onChange(e, 0)}
          />
          <SingleNumberInput
            inputRef={ref1}
            value={v1}
            onChange={e => onChange(e, 1)}
          />
          <SingleNumberInput
            inputRef={ref2}
            value={v2}
            onChange={e => onChange(e, 2)}
          />
          <SingleNumberInput
            inputRef={ref3}
            value={v3}
            onChange={e => onChange(e, 3)}
          />
          <SingleNumberInput
            inputRef={ref4}
            value={v4}
            onChange={e => onChange(e, 4)}
          />
          <SingleNumberInput
            inputRef={ref5}
            value={v5}
            onChange={e => onChange(e, 5)}
          />
        </CodeBox>
        <LoginPassword
          label={intl.formatMessage({ id: 'changePassword.newPassword' })}
          type={inputType}
          value={newPassword}
          onChange={onChangeNewPassword}
          onKeyDown={onEnter}
        />
        <LoginPassword
          label={intl.formatMessage({
            id: 'changePassword.newPasswordForConfirm',
          })}
          type={inputType}
          autoComplete="off"
          value={newPasswordConfirm}
          onChange={onChangeNewPasswordConfirm}
          onKeyDown={onEnter}
        />
        <ShowPassword>
          <LoginCheckbox checked={showPassword} onChange={toggleShowPassword} />
          {intl.formatMessage({ id: 'login.displayPassword' })}
        </ShowPassword>
        <LoginButton color={'skyBlue'} onClick={onSubmit}>
          {intl.formatMessage({ id: 'forgotPasswordSubmit.setPassword' })}
        </LoginButton>
        <LoginButton color={'monotone'} onClick={back}>
          {intl.formatMessage({ id: 'back' })}
        </LoginButton>
      </LoginContainer>
    </>
  )
}
