import React from 'react'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  Checkbox,
  MenuItem,
  Paper,
  Popper,
} from '@mui/material'
import { styled } from '@mui/system'
import InputBase from '@mui/material/InputBase'
import {
  Column,
  ColumnApi,
  GridApi,
  RowNode,
  ValueFormatterParams,
} from 'ag-grid-community'
import { RowData } from '../../../../../../BulkSheet/RowDataManager/rowDataManager'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import repositories from '../../../../../../meta/repositories'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import _ from 'lodash'
import CancelButton from '../../../../../../../components/buttons/CancelButton'
import SaveButton from '../../../../../../../components/buttons/SaveButton'

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  padding: 0,
  display: 'flex',
  alignItems: 'center',
})
const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  '& .MuiCheckbox-root': { marginRight: '0px !important' },
  '& .MuiAvatar-root': { margin: '0px 6px 0px 0px !important' },
})
const CheckboxLabel = styled('span')({
  whiteSpace: 'nowrap',
})
const StyledPaper = styled(Paper)({
  '& .MuiAutocomplete-option': { paddingLeft: '1px' },
})
const ButtonGroup = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '36px',
})
const StyledAvatar = styled(Avatar)({
  marginRight: '5px',
  height: '23px',
  width: '23px',
  borderRadius: '50%',
})

const iconDefined = ['ProjectMember', 'User']

interface AutocompleteOption {
  uuid: string
  name: string
  iconUrl?: string
}

interface Props {
  value: AutocompleteOption[]
  node: RowNode
  api: GridApi
  columnApi: ColumnApi
  keyPress: number
  rowIndex: number
  column: Column
  data: RowData

  field: string
  uiMeta: FunctionProperty
}

interface State {
  value?: AutocompleteOption[]
  text?: string
  suggestions: AutocompleteOption[]
  open: boolean
}

export const multiAutocompleteCellFilterValueFormatter = (
  params: ValueFormatterParams
) => {
  const value = params.value
  if (!value || !value.trim()) {
    return '(Blanks)'
  }
  return value
}

class cellEditor extends React.PureComponent<Props, State> {
  private ref = React.createRef<HTMLInputElement>()
  constructor(props: Props) {
    super(props)
    this.state = this.createInitialState(props)
  }

  private createInitialState(props) {
    let startText: string
    if (props.charPress) {
      startText = props.charPress
    } else {
      startText = ''
    }
    return {
      text: startText,
      suggestions: [],
      open: false,
      value: props.value,
    }
  }

  public getValue = () => {
    return this.state.value
  }

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.focus()
    }
  }

  private getIcon = (
    referenceEntity: string | undefined,
    iconUrl: string | undefined
  ) => {
    if (!referenceEntity || !iconDefined.includes(referenceEntity)) {
      return <></>
    }
    return <StyledAvatar variant="circular" src={iconUrl} />
  }

  private setOptions = async (value: string) => {
    const cellEditorParams = this.props.column.getColDef().cellEditorParams
    const searchOptions = this.props.uiMeta.searchOptions.build(this.props.data)
    let options: AutocompleteOption[] = await repositories[
      this.props.uiMeta.referenceEntity!
    ].search(value, searchOptions, this.props.uiMeta)
    const selectOptionsFilter = cellEditorParams.selectOptionsFilter
    options = selectOptionsFilter ? selectOptionsFilter(options) : options
    this.setState({ suggestions: options }, () => {
      this.setState({ open: this.state.suggestions.length > 0 })
    })
  }

  private search = _.debounce(this.setOptions, 300)

  private focused = event => {
    this.search(this.state.text ? this.state.text : '')
  }

  private inputChanged = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      this.setState({ text: value })
      this.search(value)
    }
  }

  private valueChanged = (
    event: React.ChangeEvent<{}>,
    value: AutocompleteOption[] | null,
    reason: AutocompleteChangeReason
  ) => {
    this.setState({
      value: value || [],
      text: '',
    })
  }

  private onSubmit = () => {
    this.setState({ open: false }, () => this.props.api.stopEditing())
  }

  private onCancel = e => {
    this.setState({ open: false }, () => this.props.api.stopEditing(true))
  }

  private onClose = (e, reason) => {
    if (['blur', 'toggleInput'].includes(reason)) {
      return
    }
    let cancel = false
    if (reason === 'escape') {
      cancel = true
    }
    this.setState({ open: false }, () => this.props.api.stopEditing(cancel))
  }

  isCancelAfterEnd() {
    this.setState({ open: false })
  }

  render() {
    const { uiMeta } = this.props
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    return (
      <RootDiv>
        <Autocomplete
          multiple={true}
          options={this.state.suggestions}
          getOptionLabel={option => (option.name ? option.name : '')}
          renderOption={(props, option: AutocompleteOption, { selected }) => {
            return (
              <StyledMenuItem {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color={'primary'}
                />
                {this.getIcon(uiMeta.referenceEntity, option.iconUrl)}
                <CheckboxLabel>
                  {repositories[uiMeta.referenceEntity!].getLabel(option)}
                </CheckboxLabel>
              </StyledMenuItem>
            )
          }}
          isOptionEqualToValue={(value, option) => {
            return value.uuid === option.uuid
          }}
          filterOptions={(options: AutocompleteOption[]) => {
            return options
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <InputBase
              inputRef={this.ref}
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus={true}
            />
          )}
          PopperComponent={props => (
            <Popper {...props} placement="bottom-start" />
          )}
          PaperComponent={props => (
            <StyledPaper {...props}>
              {props.children}
              <ButtonGroup>
                <SaveButton onClick={this.onSubmit} />
                <CancelButton onClick={this.onCancel} />
              </ButtonGroup>
            </StyledPaper>
          )}
          autoSelect={false}
          blurOnSelect={false}
          open={this.state.open}
          noOptionsText={''}
          value={this.state.value}
          onFocus={this.focused}
          onInputChange={this.inputChanged}
          onChange={this.valueChanged}
          onClose={this.onClose}
          disableCloseOnSelect={true}
        />
      </RootDiv>
    )
  }
}

export default cellEditor
