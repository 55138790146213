import { useRef } from 'react'
import { Collapse, IconButton, Popper, styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { AggregateFieldToggleButtonGroup } from '../../../../components/inputs/ToggleButtonGroup/AggregateFieldToggleButtonGroup'
import { WorkloadUnitToggleButtonGroup } from '../../../../components/inputs/ToggleButtonGroup/WorkloadUnitToggleButtonGroup'
import { Select } from '../../../../components/inputs/Select'
import { SelectOption } from '../../../../model/selectOption'
import { WeekDay } from '../../../../../domain/value-object/DayOfWeek'
import {
  BurndownChartViewConfig,
  UpdateBurndownChartViewConfig,
} from '../../model/viewConfig'
import { useWbsItemTypeSelectOptions } from '../../hooks/wbsItemTypeSelectOptions'
import { DateBucketToggleButtonGroup } from '../../../../components/inputs/ToggleButtonGroup/DateBucketToggleButtonGroup'
import { StartDayOfWeekSelect } from './StartDayOfWeekSelect'
import { ViewTypeToggleButtonGroup } from './ViewTypeToggleButtonGroup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'

type BurndownChartViewConfigPanelProps = {
  type: string | undefined
  onChangeType: (v: SelectOption | undefined) => void
  startDayOfWeek: WeekDay
  updateStartDayOfWeek: (v: WeekDay) => void
  open: boolean
  toggle: () => void
} & BurndownChartViewConfig &
  UpdateBurndownChartViewConfig

export const BurndownChartViewConfigPanel = ({
  type,
  onChangeType,
  startDayOfWeek,
  updateStartDayOfWeek,
  aggregateField,
  workloadUnit,
  dateBucketType,
  viewType,
  updateAggregateField,
  updateWorkloadUnit,
  updateDateBucketType,
  updateViewType,
  open,
  toggle,
}: BurndownChartViewConfigPanelProps) => {
  const typeOptions = useWbsItemTypeSelectOptions()
  const ref = useRef<HTMLButtonElement | null>(null)
  return (
    <>
      <ViewConfigRoot>
        <Collapse in={open} style={{ width: '100%' }}>
          <ViewConfigArea>
            <Title>
              {intl.formatMessage({
                id: 'progressReport.viewConfig',
              })}
            </Title>
            <MainArea>
              <ConfigItem>
                <ItemName>
                  {intl.formatMessage({
                    id: 'progressReport.viewConfig.aggregateTarget',
                  })}
                </ItemName>
                <Select
                  options={typeOptions}
                  value={type}
                  onSelect={onChangeType}
                  height={26}
                />
              </ConfigItem>
              <ConfigItem>
                <ItemName>
                  {intl.formatMessage({
                    id: 'progressReport.viewConfig.viewType',
                  })}
                </ItemName>
                <ViewTypeToggleButtonGroup
                  value={viewType}
                  onChange={updateViewType}
                />
              </ConfigItem>
              <ConfigItem>
                <ItemName>
                  {intl.formatMessage({
                    id: 'progressReport.viewConfig.aggregateField',
                  })}
                </ItemName>
                <AggregateFieldToggleButtonGroup
                  value={aggregateField}
                  onChange={updateAggregateField}
                />
              </ConfigItem>
              <ConfigItem>
                <ItemName>
                  {intl.formatMessage({
                    id: 'progressReport.viewConfig.workloadUnit',
                  })}
                </ItemName>
                <WorkloadUnitToggleButtonGroup
                  value={workloadUnit}
                  onChange={updateWorkloadUnit}
                />
              </ConfigItem>
              <ConfigItem>
                <ItemName>
                  {intl.formatMessage({
                    id: 'progressReport.viewConfig.dateBucketType',
                  })}
                </ItemName>
                <ConfigItemGroup>
                  <DateBucketToggleButtonGroup
                    value={dateBucketType}
                    onChange={updateDateBucketType}
                  />
                  <StartDayOfWeekSelect
                    startDayOfWeek={startDayOfWeek}
                    updateStartDayOfWeek={updateStartDayOfWeek}
                  />
                </ConfigItemGroup>
              </ConfigItem>
            </MainArea>
          </ViewConfigArea>
        </Collapse>
        <CloseViewConfig>
          {intl.formatMessage({
            id: 'progressReport.viewConfig',
          })}
          <ViewConfigToggleIconButton ref={ref} onClick={toggle}>
            <ViewConfigToggleIcon open={open} />
          </ViewConfigToggleIconButton>
        </CloseViewConfig>
      </ViewConfigRoot>
    </>
  )
}
const ViewConfigRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'relative',
})
const ViewConfigArea = styled('div')({
  height: '78px',
  width: '100%',
  display: 'flex',
  boxShadow: `0px 12px 16px -8px ${colorPalette.monotone[4]}40`,
  flexDirextion: 'colmn',
})
const Title = styled('div')({
  height: '100%',
  width: '84px',
  backgroundColor: colorPalette.monotone[1],
  color: colorPalette.monotone[10],
  fontWeight: '700',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
const MainArea = styled('div')({
  height: '100%',
  width: '100%',
  backgroundColor: colorPalette.monotone[0],
  display: 'flex',
  padding: '12px',
  gap: '20px',
})
const ConfigItem = styled('div')({
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
})
const ItemName = styled('div')({
  fontSize: '11px',
  color: colorPalette.monotone[7],
})
const ConfigItemGroup = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})
const CloseViewConfig = styled('div')({
  height: '28px',
  width: '100px',
  display: 'flex',
  color: colorPalette.monotone[4],
  backgroundColor: colorPalette.monotone[0],
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: ' 0px 0px 4px 4px',
  fontSize: '12px',
  fontWeight: '400',
  boxShadow: `0px 12px 16px -8px ${colorPalette.monotone[4]}40`,
  position: 'absolute',
  right: '100px',
  bottom: '-28px',
})
const ViewConfigToggleIcon = styled(ExpandMoreIcon)(
  ({ open = false }: { open: boolean }) => ({
    color: colorPalette.monotone[4],
    transform: open ? 'rotate(180deg)' : '',
  })
)
const ViewConfigToggleIconButton = styled(IconButton)({
  padding: '0',
})
