import _ from 'lodash'
import { useCallback, useRef, useState } from 'react'
import {
  ResourcePlanNewInput,
  ResourcePlanNewMemberInput,
  ResourcePlanMemberSiblingInput,
  ResourcePlanType,
  updateBatch,
  updateMemberWork,
  ResourcePlanTeamSiblingInput,
} from '../../../../lib/functions/resourcePlanNew'
import { DateTerm, formatMonth } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import {
  GetResourcePlanProps,
  MemberWorkMonthSkeleton,
  ResourcePlanRow,
  ResourcePlanSkeleton,
  createLabelTeam,
  createNewUnsetRow,
  createTeamGroupRow,
  createUnsetTeamGroupRow,
  getMemberUuids,
  getResourcePlan,
  getTeamUuids,
  setUserManMonth,
  sortByUuid,
} from '../resourcePlan'
import { TeamProps } from '../../../../lib/functions/team'

const DEFAULT_SUMMARY_ROW: ResourcePlanRow = {
  uuid: '',
  isTotal: true,
  body: {},
  treeValue: [],
}

export const useResourcePlantData = (projectUuid: string) => {
  const resourcePlanUuid = useRef<string>()
  const resourcePlanLockVersion = useRef<number>(0)
  const [rowsGroupByMember, setRowsGroupByMemberInternal] = useState<
    ResourcePlanRow[]
  >([])
  const [rowsGroupByTeam, setRowsGroupByTeamInternal] = useState<
    ResourcePlanRow[]
  >([])
  const [summaryRows, setSummaryRows] = useState<ResourcePlanRow[]>([])
  const [deletedRows, setDeletedRows] = useState<ResourcePlanRow[]>([])

  const [memberOrder, setMemberOrder] = useState<string[]>([])
  const [teamOrder, setTeamOrder] = useState<string[]>([])

  const createRowByResponce = useCallback(
    (
      source: ResourcePlanSkeleton,
      parentUuid?: string,
      type?: ResourcePlanType
    ): ResourcePlanRow => {
      return {
        uuid: source.uuid,
        type: type ?? source.type,
        body: {
          user: source.user,
          team: source.team ?? createLabelTeam('project.resourcePlan.total'),
          projectReleasedDate: source.projectReleasedDate,
          memberWorkMonths: source.memberWorkMonths.map(memberWorkMonth => {
            const yearMonth = formatMonth(memberWorkMonth.month)
            return {
              yearMonth,
              manMonth: memberWorkMonth.manMonth,
            }
          }),
        },
        revision: source.revision,
        createdBy: source.createdBy,
        createdAt: source.createdAt,
        updatedBy: source.updatedBy,
        updatedAt: source.updatedAt,
        treeValue: parentUuid ? [parentUuid, source.uuid] : [source.uuid],
      } as ResourcePlanRow
    },
    []
  )

  const setUserManMonths = useCallback(
    (rows: ResourcePlanRow[] | undefined, source: ResourcePlanSkeleton) => {
      rows?.forEach((memberRow: ResourcePlanRow) => {
        const userPlan: ResourcePlanSkeleton | undefined = source.children.find(
          (plan: ResourcePlanSkeleton) =>
            plan.type === ResourcePlanType.MEMBER &&
            plan.user?.uuid &&
            plan.user.uuid === memberRow.body.user?.uuid
        )
        userPlan?.memberWorkMonths?.forEach(
          (workMonth: MemberWorkMonthSkeleton) => {
            setUserManMonth(
              memberRow,
              formatMonth(workMonth.month),
              workMonth.manMonth
            )
          }
        )
      })
    },
    []
  )

  const createRowGroupByMember = useCallback(
    (source: ResourcePlanSkeleton, teamOrder: string[]): ResourcePlanRow[] => {
      const members = source.children.filter(
        planMember => planMember.type === ResourcePlanType.MEMBER
      )
      const memberRows = members.map(member => {
        return createRowByResponce(member)
      })
      const rows: ResourcePlanRow[] = []
      memberRows.forEach(memberRow => {
        const rowsPerMember: ResourcePlanRow[] = []
        rowsPerMember.push(memberRow)
        const teamRows: ResourcePlanRow[] = source.children
          .filter(
            planMember =>
              planMember.type === ResourcePlanType.MEMBER_PER_TEAM &&
              planMember.user?.uuid &&
              planMember.user.uuid === memberRow.body.user?.uuid
          )
          .map(team =>
            createRowByResponce(team, memberRow.uuid, ResourcePlanType.TEAM)
          )
        const sortedTeamRows = teamRows.sort(
          (a: ResourcePlanRow, b: ResourcePlanRow) => {
            const aIndex = teamOrder.findIndex(
              (uuid: string) => uuid === a.body.team?.uuid
            )
            const bIndex = teamOrder.findIndex(
              (uuid: string) => uuid === b.body.team?.uuid
            )
            return aIndex - bIndex
          }
        )
        rowsPerMember.push(...sortedTeamRows)
        setUserManMonths(rowsPerMember, source)
        rowsPerMember.push(
          createNewUnsetRow(memberRow.uuid, memberRow.body.user)
        )
        rows.push(...rowsPerMember)
      })
      return rows
    },
    [createRowByResponce, setUserManMonths]
  )

  const createTeamviewMemberRows = useCallback(
    (
      parentUuid: string,
      sourcePlans: ResourcePlanSkeleton[] | undefined,
      projectReleasedDateMap: Map<string, string>,
      memberOrder: string[],
      team?: TeamProps
    ): ResourcePlanRow[] | undefined => {
      return sourcePlans
        ?.sort((a: ResourcePlanSkeleton, b: ResourcePlanSkeleton) =>
          sortByUuid(a.user, b.user, memberOrder)
        )
        .map((plan: ResourcePlanSkeleton) => {
          const teamMemberRow = createRowByResponce(
            plan,
            parentUuid,
            ResourcePlanType.MEMBER
          )
          if (team) {
            teamMemberRow.body.team = team
          }
          const memberUuid = teamMemberRow.body.user?.uuid
          if (memberUuid && projectReleasedDateMap.has(memberUuid)) {
            teamMemberRow.body.projectReleasedDate =
              projectReleasedDateMap.get(memberUuid)
          }
          return teamMemberRow
        })
    },
    [createRowByResponce]
  )

  const createRowGroupByTeam = useCallback(
    (
      source: ResourcePlanSkeleton,
      memberOrder: string[]
    ): ResourcePlanRow[] => {
      const teamRows = source.children
        .filter(
          (planMember: ResourcePlanSkeleton) =>
            planMember.type === ResourcePlanType.TEAM
        )
        .map((planMember: ResourcePlanSkeleton) => {
          return createTeamGroupRow(planMember.team)
        })

      const projectReleasedDateMap = source.children
        .filter(
          (plan: ResourcePlanSkeleton) => plan.type === ResourcePlanType.MEMBER
        )
        .reduce((map: Map<string, string>, plan: ResourcePlanSkeleton) => {
          if (
            plan.user?.uuid &&
            !map.has(plan.user.uuid) &&
            plan.projectReleasedDate
          ) {
            map.set(plan.user.uuid, plan.projectReleasedDate)
          }
          return map
        }, new Map<string, string>())

      const rows: ResourcePlanRow[] = []
      teamRows.forEach(teamRow => {
        rows.push(teamRow)
        const plans: ResourcePlanSkeleton[] = source.children.filter(
          planMember =>
            planMember.type === ResourcePlanType.MEMBER_PER_TEAM &&
            planMember.team?.uuid &&
            planMember.team.uuid === teamRow.body.team?.uuid
        )
        const memberRows = createTeamviewMemberRows(
          teamRow.uuid,
          plans,
          projectReleasedDateMap,
          memberOrder
        )
        setUserManMonths(memberRows, source)
        if (memberRows) {
          rows.push(...memberRows)
        }
      })

      // add unset rows
      const unsetRow: ResourcePlanRow = createUnsetTeamGroupRow()
      const membersWithUnset = new Set<string>(
        source.children
          .map((plan: ResourcePlanSkeleton) => plan.user?.uuid)
          .filter(uuid => uuid)
      )
      const unsetMemberRows = createTeamviewMemberRows(
        unsetRow.uuid,
        source.children.filter(
          (plan: ResourcePlanSkeleton) =>
            plan.type === ResourcePlanType.MEMBER &&
            membersWithUnset.has(plan.user?.uuid)
        ),
        projectReleasedDateMap,
        memberOrder,
        unsetRow.body.team
      )
      if (!_.isEmpty(unsetMemberRows)) {
        rows.push(unsetRow)
        rows.push(...unsetMemberRows!)
      }
      return rows
    },
    [createTeamviewMemberRows, setUserManMonths]
  )

  const fetchRecords = useCallback(
    async (dateTerm: DateTerm) => {
      if (!dateTerm || !dateTerm.startDate || !dateTerm.endDate) {
        return
      }
      const getResourcePlanProps: GetResourcePlanProps = {
        projectUuid,
        startDate: new DateVO(dateTerm.startDate).formatForApi()!,
        endDate: new DateVO(dateTerm.endDate).formatForApi()!,
      }
      const source: ResourcePlanSkeleton = await getResourcePlan(
        getResourcePlanProps
      )
      resourcePlanUuid.current = source.uuid
      resourcePlanLockVersion.current = source.lockVersion

      const memberUuids = source.children
        .filter(
          (plan: ResourcePlanSkeleton) =>
            plan.type === ResourcePlanType.MEMBER && !!plan.user
        )
        .map((plan: ResourcePlanSkeleton) => plan.user.uuid)
      const teamUuids = source.children
        .filter(
          (plan: ResourcePlanSkeleton) => plan.type === ResourcePlanType.TEAM
        )
        .map((plan: ResourcePlanSkeleton) => plan.team.uuid)

      setMemberOrder(memberUuids)
      setTeamOrder(teamUuids)
      setRowsGroupByMemberInternal(createRowGroupByMember(source, teamUuids))
      setRowsGroupByTeamInternal(createRowGroupByTeam(source, memberUuids))
      setSummaryRows([DEFAULT_SUMMARY_ROW])
      setDeletedRows([])
      store.dispatch(doNotRequireSave())
    },
    [projectUuid, createRowGroupByMember, createRowGroupByTeam]
  )

  const setRowsGroupByMember = useCallback((rows: ResourcePlanRow[]) => {
    setRowsGroupByMemberInternal(rows)
    setMemberOrder(getMemberUuids(rows))
    store.dispatch(requireSave())
  }, [])

  const setRowsGroupByTeam = useCallback((rows: ResourcePlanRow[]) => {
    setRowsGroupByTeamInternal(rows)
    setTeamOrder(getTeamUuids(rows))
    store.dispatch(requireSave())
  }, [])

  const rememberDeletedRows = useCallback(
    (rows: ResourcePlanRow[]) => {
      const appendDeletedRows = rows.filter(r => !r.added)
      if (_.isEmpty(appendDeletedRows)) return
      setDeletedRows([...deletedRows, ...appendDeletedRows])
      store.dispatch(requireSave())
    },
    [deletedRows]
  )

  const createRequest = useCallback((): ResourcePlanNewInput => {
    const added: ResourcePlanNewMemberInput[] = rowsGroupByMember
      .filter(
        (row: ResourcePlanRow) =>
          row.added &&
          !!row.body.user &&
          (row.type === ResourcePlanType.MEMBER ||
            (row.type === ResourcePlanType.TEAM && !!row.body.team))
      )
      .map((row: ResourcePlanRow) => {
        return {
          uuid: row.uuid,
          userUuid: row.body.user!.uuid,
          teamUuid:
            row.body.team && row.body.team.projectUuid !== ''
              ? row.body.team.uuid
              : undefined,
          deltaMemberWorkMonths:
            row.body.memberWorkMonths?.map(memberWorkMonth => {
              const yearMonth = new DateVO(memberWorkMonth.yearMonth)
              return {
                year: yearMonth.getYear(),
                month: yearMonth.getMonth(),
                manMonth: memberWorkMonth.manMonth,
              }
            }) || [],
        } as ResourcePlanNewMemberInput
      })
      .filter(input => input.userUuid)

    const deleted: ResourcePlanNewMemberInput[] = deletedRows
      .filter(
        (row: ResourcePlanRow) =>
          !!row.body.user &&
          (row.type === ResourcePlanType.MEMBER ||
            (row.type === ResourcePlanType.TEAM && !!row.body.team))
      )
      .map((row: ResourcePlanRow) => {
        return {
          uuid: row.uuid,
          userUuid: row.body.user!.uuid,
          teamUuid:
            row.body.team && row.body.team.projectUuid !== ''
              ? row.body.team.uuid
              : undefined,
          deltaMemberWorkMonths: [],
        } as ResourcePlanNewMemberInput
      })

    const edited: ResourcePlanNewMemberInput[] = rowsGroupByMember
      .filter(
        (row: ResourcePlanRow) =>
          !row.added &&
          row.edited &&
          !!row.body.user &&
          (row.type === ResourcePlanType.MEMBER ||
            (row.type === ResourcePlanType.TEAM && !!row.body.team))
      )
      .map((row: ResourcePlanRow) => {
        const deltaWorkMonths = row.body.memberWorkMonths?.filter(after => {
          return (
            after.yearMonth &&
            row.editedData?.hasOwnProperty(after.yearMonth) &&
            row.editedData[after.yearMonth] !== after.manMonth
          )
        })
        return {
          uuid: row.uuid,
          userUuid: row.body.user!.uuid,
          teamUuid:
            row.body.team && row.body.team.projectUuid !== ''
              ? row.body.team.uuid
              : undefined,
          deltaMemberWorkMonths:
            deltaWorkMonths?.map(memberWorkMonth => {
              const yearMonth = new DateVO(memberWorkMonth.yearMonth)
              return {
                year: yearMonth.getYear(),
                month: yearMonth.getMonth(),
                manMonth: memberWorkMonth.manMonth,
              }
            }) || [],
        } as ResourcePlanNewMemberInput
      })

    const resourcePlanMembers: ResourcePlanNewMemberInput[] = rowsGroupByMember
      .filter(
        row =>
          !!row.body.user &&
          (row.type === ResourcePlanType.MEMBER ||
            (row.type === ResourcePlanType.TEAM && !!row.body.team))
      )
      .map(row => {
        const workMonths =
          row.body.memberWorkMonths?.map(memberWorkMonth => {
            const yearMonth = new DateVO(memberWorkMonth.yearMonth)
            return {
              year: yearMonth.getYear(),
              month: yearMonth.getMonth(),
              manMonth: memberWorkMonth.manMonth,
            }
          }) || []
        return {
          uuid: row.uuid,
          userUuid: row.body.user!.uuid,
          teamUuid:
            row.body.team && row.body.team.projectUuid !== ''
              ? row.body.team.uuid
              : undefined,
          deltaMemberWorkMonths: workMonths,
          memberWorkMonths: workMonths,
        } as ResourcePlanNewMemberInput
      })
    const memberSiblings: ResourcePlanMemberSiblingInput[] = rowsGroupByMember
      .filter(row => row.type === ResourcePlanType.MEMBER && row.body.user)
      .map((row: ResourcePlanRow, index: number, rows: ResourcePlanRow[]) => {
        return {
          uuid: row.uuid,
          userUuid: row.body.user?.uuid,
          lockVersion: row.lockVersion,
          prevSiblingUuid: 0 < index ? rows[index - 1].uuid : undefined,
        } as ResourcePlanMemberSiblingInput
      })
    const teamSiblings: ResourcePlanTeamSiblingInput[] = rowsGroupByTeam
      .filter(
        (row: ResourcePlanRow) =>
          row.type === ResourcePlanType.TEAM && row.body.team
      )
      .map((row: ResourcePlanRow, index: number, rows: ResourcePlanRow[]) => {
        return {
          uuid: row.uuid,
          teamUuid: row.body.team!.uuid,
          prevSiblingUuid: 0 < index ? rows[index - 1].uuid : undefined,
        }
      })
    return {
      projectUuid,
      uuid: resourcePlanUuid.current,
      lockVersion: resourcePlanLockVersion.current,
      resourcePlanMembers: resourcePlanMembers,
      added: added,
      edited: edited,
      deleted: deleted,
      memberSiblings,
      teamSiblings,
    }
  }, [
    projectUuid,
    resourcePlanUuid,
    resourcePlanLockVersion,
    rowsGroupByMember,
    rowsGroupByTeam,
    deletedRows,
  ])

  const save = useCallback(async () => {
    const request: ResourcePlanNewInput = createRequest()
    const [response, menberResponce] = await Promise.all([
      updateBatch(request),
      updateMemberWork(request),
    ])
    return response
  }, [createRequest])

  return {
    rowsGroupByMember,
    rowsGroupByTeam,
    summaryRows,
    memberOrder,
    teamOrder,
    fetchRecords,
    setRowsGroupByMember,
    setRowsGroupByTeam,
    rememberDeletedRows,
    save,
  }
}
