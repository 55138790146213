import { useCallback, useEffect, useMemo, useState } from 'react'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { RowGroupColumnType } from '../ResourcePlanCrossProjects'
import { DateTerm, getFinancialYear } from '../../../../utils/date'
import store from '../../../../store'

export type StoredPageState = {
  fromDate: string
  toDate: string
  rowHeight: ROW_HEIGHT
  toolbar?: ToolbarToggleValue
  rowGroupColumn?: RowGroupColumnType
}

export const usePageState = (
  functionUuid: string
): {
  fromDate: string
  setFromDate: (_: string) => void
  toDate: string
  setToDate: (_: string) => void
  rowHeight: ROW_HEIGHT
  setRowHeight: (_: ROW_HEIGHT) => void
  rowGroupColumn?: RowGroupColumnType
  setRowGroupColumn: (_?: RowGroupColumnType) => void
  initialized: boolean
} => {
  const defaultDateTerm: DateTerm = useMemo(() => {
    const startMonthOfFinancialYear = getFinancialYear(
      store.getState()?.tenant.organization
    )
    return {
      startDate: startMonthOfFinancialYear.formatForApi(),
      endDate: startMonthOfFinancialYear.addYears(1).addDays(-1).formatForApi(),
    }
  }, [])
  const [fromDate, setFromDate] = useState<string>(defaultDateTerm.startDate!)
  const [toDate, setToDate] = useState<string>(defaultDateTerm.endDate!)
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [rowGroupColumn, setRowGroupColumn] = useState<
    RowGroupColumnType | undefined
  >(RowGroupColumnType.MEMBER)
  const [initialized, setInitialized] = useState<boolean>(false)

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) return
      const payload: StoredPageState = JSON.parse(stored.value)
      payload.fromDate && setFromDate(payload.fromDate)
      payload.toDate && setToDate(payload.toDate)
      payload.rowHeight && setRowHeight(payload.rowHeight)
      payload.rowGroupColumn && setRowGroupColumn(payload.rowGroupColumn)
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  const rememberPageState = useCallback(
    (value: StoredPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    restorePageState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!initialized) return
    rememberPageState({
      fromDate,
      toDate,
      rowHeight,
      rowGroupColumn,
    })
  }, [
    rememberPageState,
    fromDate,
    toDate,
    rowHeight,
    rowGroupColumn,
    initialized,
  ])

  return {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    rowHeight,
    setRowHeight,
    rowGroupColumn,
    setRowGroupColumn,
    initialized,
  }
}
