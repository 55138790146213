import { ValueFormatterParams } from 'ag-grid-community'
import { AggregateField } from '../../../../../../../../domain/entity/WbsItemEntity'

export const format = (params: ValueFormatterParams): string => {
  if (
    typeof params.value === 'undefined' ||
    Number.isNaN(Number.parseFloat(params.value))
  ) {
    return ''
  }
  if (params.context.aggregateTargetType === AggregateField.WBS_ITEM_COUNT) {
    return Number.parseFloat(params.value).toFixed(0)
  } else {
    return Number.parseFloat(params.value).toFixed(2)
  }
}
