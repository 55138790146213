import React, { useCallback, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/SearchRounded'
import ClearIcon from '@mui/icons-material/ClearRounded'
import { IconButton } from '@mui/material'
import SearchButton from '../../buttons/SearchButton'
import { muiTheme } from '../../../../styles/muiTheme'
import { intl } from '../../../../i18n'

const theme = muiTheme

interface Props {
  reloadHandler: () => void
  onChange: (v: string) => void
  total?: number
  hit?: number
  hideButton?: boolean
  searchText?: string
  searchTextPlaceholder?: string
  setSearchWindowRef?: (ref?: any) => void
  children?: React.ReactNode
}

const RootDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const SearchSpan = styled('span')({
  border: '1px solid #DDDDDD',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  marginRight: '5px',
})
const SearchTextInput = styled(InputBase)({
  color: 'inherit',
  width: '180px',
})
const RenderDiv = styled('div')({
  display: 'inline-flex',
})
const TotalHitsSpan = styled('span')({
  whiteSpace: 'nowrap',
  marginRight: '5px',
})

const SearchWindow = (props: Props) => {
  const {
    setSearchWindowRef,
    children,
    hideButton,
    hit,
    total,
    searchText,
    searchTextPlaceholder,
  } = props

  const valueChange = useCallback(
    event => {
      const text = event.target.value
      props.onChange(text)
    },
    [props.onChange]
  )

  const keyPressed = useCallback(
    event => {
      if (event.key === 'Enter') {
        props.reloadHandler()
      }
    },
    [props.reloadHandler]
  )

  const searchButtonClick = useCallback(() => {
    props.reloadHandler()
  }, [props.reloadHandler])

  const clearSearchText = useCallback(() => {
    props.onChange('')
  }, [props.onChange])

  return (
    <RootDiv ref={setSearchWindowRef}>
      <SearchSpan>
        <SearchIcon color="action" sx={{ margin: '10px' }} />
        <SearchTextInput
          placeholder={
            searchTextPlaceholder ||
            intl.formatMessage({ id: 'search.full.text' })
          }
          value={searchText}
          onChange={valueChange}
          onKeyPress={keyPressed}
        />
        {!!searchText && (
          <IconButton onClick={clearSearchText} sx={{ padding: '4px' }}>
            <ClearIcon color="action" />
          </IconButton>
        )}
        {children && <RenderDiv>{children}</RenderDiv>}
      </SearchSpan>
      {!hideButton && (
        <Box pr="8px">
          <SearchButton onClick={searchButtonClick} />
        </Box>
      )}
      {hit !== undefined && total !== undefined && (
        <TotalHitsSpan>
          {intl.formatMessage({ id: 'search.hitsTotal' }, { hit, total })}
        </TotalHitsSpan>
      )}
    </RootDiv>
  )
}

export default SearchWindow
