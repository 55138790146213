import {
  ClickAwayListener,
  SpeedDial as MuiSpeedDial,
  SpeedDialAction as MuiSpeedDialAction,
  SpeedDialActionProps,
  styled,
} from '@mui/material'
import React, { LegacyRef, useRef, useState } from 'react'
import { intl } from '../../../../../i18n'
import { blueActionIconStyle } from '../../../../components/icons/IconButton/BlueActionIconButton'
import SettingsIcon from '@mui/icons-material/SettingsRounded'
import { BackgroundColor, IconColor } from '../../../../../styles/commonStyles'
import FilterIcon from '../../../../components/icons/FilterIcon'
import LineWeightRoundedIcon from '@mui/icons-material/LineWeightRounded'
import ViewColumnRoundedIcon from '@mui/icons-material/ViewColumnRounded'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded'
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt'
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded'
import { ColDef } from 'ag-grid-community'
import ColumnListPopper, {
  Props as ColumnListPopperProps,
} from '../../../../components/poppers/ColumnListPopper'
import RowHeightChangePopper, {
  Props as RowHeightChangePopperProps,
} from '../../../../components/poppers/RowHeightChangePopper'
import PublishRoundedIcon from '@mui/icons-material/PublishRounded'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'
import Tooltip from '../../../tooltips/Tooltip'
import DateFormatChangePopper, {
  Props as DateFormatChangePopperProps,
} from '../../../poppers/DateFormatChangePopper'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

export interface SettingSpeedDialProps {
  onResetColumnState?: () => void
  onClickExport?: () => void
  onClickImport?: (data: Uint8Array) => Promise<any> | void
  onRestoreSearchCondition?: () => void
  onRestoreColumnState?: () => void
  filteredColumns?: (ColDef | string)[]
  onDeletedFilterColumn?: (column?: string) => void
  sortedColumns?: (ColDef | string)[]
  rowHeight?: number
  onClickChangeRowHeight?: (value: number) => void
  currentFormat?: string
  onClickChangeDateFormat?: (value: string) => void
}

const SpeedDial = styled(MuiSpeedDial)(props => ({
  '& .MuiSpeedDial-fab': {
    height: '24px',
    width: '24px',
    minHeight: '24px',
  },
  '& .MuiSpeedDial-actions': {
    padding: 0,
    marginLeft: 5,
    ...(props.open
      ? {
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '10px',
        }
      : undefined),
  },
}))

const SpeedDialAction = styled(MuiSpeedDialAction)({
  height: '24px',
  width: '24px',
  minHeight: '24px',
  margin: '2px 4px',
})

type ColumnListFragmentProps = SpeedDialActionProps & ColumnListPopperProps

const ColumnsListSpeedDialAction = (props: ColumnListFragmentProps) => {
  return (
    <>
      <SpeedDialAction
        icon={props.icon}
        tooltipTitle={props.tooltipTitle}
        tooltipPlacement={props.tooltipPlacement}
        onClick={props.onClick}
        open={props.open}
      />
      <ColumnListPopper
        anchorEl={props.anchorEl}
        columns={props.columns}
        onDelete={props.onDelete}
      />
    </>
  )
}

type RowHeightFragmentProps = SpeedDialActionProps & RowHeightChangePopperProps

const RowHeightSpeedDialAction = (props: RowHeightFragmentProps) => {
  return (
    <>
      <SpeedDialAction
        icon={props.icon}
        tooltipTitle={props.tooltipTitle}
        tooltipPlacement={props.tooltipPlacement}
        onClick={props.onClick}
        open={props.open}
      />
      <RowHeightChangePopper
        anchorEl={props.anchorEl}
        height={props.height}
        onChangeHeight={props.onChangeHeight}
      />
    </>
  )
}

interface FileImportProps {
  inputRef: LegacyRef<HTMLInputElement> | undefined
  onChangeInput: (e) => void
}

type FileImportFragmentProps = SpeedDialActionProps & FileImportProps

const FileImportSpeedDialAction = (props: FileImportFragmentProps) => {
  return (
    <>
      <SpeedDialAction
        icon={props.icon}
        tooltipTitle={props.tooltipTitle}
        tooltipPlacement={props.tooltipPlacement}
        onClick={props.onClick}
        open={props.open}
      />
      <input
        type="file"
        aria-hidden="true"
        style={{ display: 'none' }}
        ref={props.inputRef}
        onChange={props.onChangeInput}
      />
    </>
  )
}

type DateFormatFragmentProps = SpeedDialActionProps &
  DateFormatChangePopperProps

const DateFormatSpeedDialAction = (props: DateFormatFragmentProps) => {
  return (
    <>
      <SpeedDialAction
        icon={props.icon}
        tooltipTitle={props.tooltipTitle}
        tooltipPlacement={props.tooltipPlacement}
        onClick={props.onClick}
        open={props.open}
      />
      <DateFormatChangePopper
        anchorEl={props.anchorEl}
        currentFormat={props.currentFormat}
        onChangeFormat={props.onChangeFormat}
        intl={props.intl}
      />
    </>
  )
}

const SettingSpeedDial = (props: SettingSpeedDialProps) => {
  const [open, setOpen] = useState(false)
  const [anchorElForFilter, setAnchorElForFilter] = useState<HTMLDivElement>()
  const [anchorElForSort, setAnchorElForSort] = useState<HTMLDivElement>()
  const [anchorElForChangeRowHeight, setAnchorElForChangeRowHeight] =
    useState<HTMLDivElement>()
  const [anchorElForChangeDateFormat, setAnchorElForChangeDateFormat] =
    useState<HTMLDivElement>()
  const importInput = useRef<HTMLInputElement>()

  const close = () => {
    setOpen(false)
    setAnchorElForFilter(undefined)
    setAnchorElForSort(undefined)
    setAnchorElForChangeRowHeight(undefined)
    setAnchorElForChangeDateFormat(undefined)
  }

  const actionIconStyle = {
    width: '14px',
    height: '14px',
    color: IconColor.GREY,
  }

  return (
    <ClickAwayListener onClickAway={() => close()}>
      <SpeedDial
        ariaLabel="toolbar"
        icon={
          <Tooltip
            message={intl.formatMessage({
              id: 'dialog.setting',
            })}
          >
            <SettingsIcon style={blueActionIconStyle} />
          </Tooltip>
        }
        onClick={() => {
          if (open) {
            close()
          } else {
            setOpen(true)
          }
        }}
        open={open}
        direction="right"
        FabProps={{
          size: 'small',
          style: {
            background: BackgroundColor.BLUE_GRADATION,
            boxShadow: '0px 1px 1px #00000033',
          },
        }}
      >
        {props.onResetColumnState && (
          <SpeedDialAction
            key={'resetColumnState'}
            icon={<ViewColumnRoundedIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({
              id: 'toolbar.initialize.columnState',
            })}
            onClick={props.onResetColumnState}
            tooltipPlacement={'bottom-start'}
          />
        )}
        {props.onClickExport && (
          <SpeedDialAction
            key={'export'}
            icon={<GetAppRoundedIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({ id: 'toolbar.excel.export' })}
            onClick={props.onClickExport}
            tooltipPlacement={'bottom-start'}
          />
        )}
        {props.onClickImport && (
          <FileImportSpeedDialAction
            icon={<PublishRoundedIcon color="action" sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({ id: 'toolbar.excel.import' })}
            tooltipPlacement={'bottom-start'}
            inputRef={ref => {
              importInput.current = ref || undefined
            }}
            onClick={() => {
              if (importInput && importInput.current) {
                importInput.current.click()
              }
            }}
            onChangeInput={e => {
              const { files } = e.target
              if (!files || !files[0]) return
              const reader = new FileReader()
              reader.onload = e => {
                const data = new Uint8Array(e.target!['result'] as ArrayBuffer)
                props.onClickImport && props.onClickImport(data)
              }
              reader.readAsArrayBuffer(files[0])
              e.target.value = ''
            }}
          />
        )}
        {props.onRestoreSearchCondition && (
          <SpeedDialAction
            key={'restoreSearchCondition'}
            icon={<FilterListRoundedIcon color="action" sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({
              id: 'toolbar.restore.searchCondition',
            })}
            tooltipPlacement={'bottom-start'}
            onClick={props.onRestoreSearchCondition}
          />
        )}
        {props.onRestoreColumnState && (
          <SpeedDialAction
            key={'restoreColumnState'}
            icon={<ViewQuiltIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({
              id: 'toolbar.restore.columnState',
            })}
            onClick={props.onRestoreColumnState}
            tooltipPlacement={'bottom-start'}
          />
        )}
        {props.onDeletedFilterColumn && (
          <ColumnsListSpeedDialAction
            key={'filter'}
            icon={
              <FilterIcon
                style={{
                  ...actionIconStyle,
                  color:
                    props.filteredColumns && props.filteredColumns.length > 0
                      ? 'red'
                      : '',
                }}
              />
            }
            tooltipTitle={intl.formatMessage({ id: 'toolbar.filter' })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              setAnchorElForFilter(
                !!anchorElForFilter ? undefined : e.currentTarget
              )
              e.stopPropagation()
            }}
            tooltipPlacement={'bottom-start'}
            anchorEl={anchorElForFilter}
            columns={props.filteredColumns}
            onDelete={props.onDeletedFilterColumn}
          />
        )}
        {props.sortedColumns && (
          <ColumnsListSpeedDialAction
            key={'sort'}
            icon={
              <ImportExportRoundedIcon
                color="action"
                style={{
                  ...actionIconStyle,
                  color: props.sortedColumns.length > 0 ? 'red' : '',
                }}
              />
            }
            tooltipTitle={intl.formatMessage({ id: 'toolbar.sort' })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              setAnchorElForSort(
                !!anchorElForSort ? undefined : e.currentTarget
              )
              e.stopPropagation()
            }}
            tooltipPlacement={'bottom-start'}
            anchorEl={anchorElForSort}
            columns={props.sortedColumns}
          />
        )}
        {props.onClickChangeDateFormat && (
          <DateFormatSpeedDialAction
            key={'changeDateFormat'}
            icon={<EventAvailableIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({
              id: 'toolbar.changeDateFormat',
            })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              setAnchorElForChangeDateFormat(
                !!anchorElForChangeDateFormat ? undefined : e.currentTarget
              )
              e.stopPropagation()
            }}
            tooltipPlacement={'bottom-start'}
            anchorEl={anchorElForChangeDateFormat}
            currentFormat={props.currentFormat}
            onChangeFormat={props.onClickChangeDateFormat}
            intl={intl}
          />
        )}
        {props.onClickChangeRowHeight && (
          <RowHeightSpeedDialAction
            key={'changeRowHeight'}
            icon={<LineWeightRoundedIcon sx={actionIconStyle} />}
            tooltipTitle={intl.formatMessage({ id: 'toolbar.changeHeight' })}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              setAnchorElForChangeRowHeight(
                !!anchorElForChangeRowHeight ? undefined : e.currentTarget
              )
              e.stopPropagation()
            }}
            tooltipPlacement={'bottom-start'}
            anchorEl={anchorElForChangeRowHeight}
            height={props.rowHeight}
            onChangeHeight={props.onClickChangeRowHeight}
          />
        )}
      </SpeedDial>
    </ClickAwayListener>
  )
}

export default SettingSpeedDial
