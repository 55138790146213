import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '../../'
import { useProfitLossSummaryData } from '../hooks/profitLossSummaryData'
import ProfitLossSummaryHeader, {
  ColumnQuickFilterKey,
} from '../components/Header'
import { StoredPageState, usePageState } from '../hooks/pageState'
import { BulkSheetView } from '../../../containers/BulkSheetView'
import {
  isAmountColumn,
  profitLossSummaryGridOptions,
  refreshDynamicColumnDef,
} from '../gridOptions'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'
import {
  ColDef,
  ColumnState,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  SortChangedEvent,
} from 'ag-grid-community'
import { UiStateKey } from '../../../../lib/commons/uiStates'
import SavedUIStateDialog from '../../../components/dialogs/SavedUIStateDialog'
import { intl } from '../../../../i18n'
import { SavedUIState } from '../../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useExportExcel } from '../hooks/excel'
import { collapseAllMenu, expandAllMenu } from '../gridOptions/contextMenu'
import { DateTerm } from '../../../../utils/date'
import { useBulkSheetState } from '../hooks/bulkSheetState'
import {
  collapseAllRows,
  expandAllRows,
} from '../../../containers/BulkSheetView/gridOptions/contextMenu'
import { ProjectDetail } from '../../../../lib/functions/project'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'
import { useColumnSetting } from '../../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { dateVoService } from '../../../../domain/value-object/DateVO'
import { PredictionType } from '../ProfitLossSummary'

interface OwnProps {
  currentProject: ProjectDetail
}

type Props = OwnProps & PageProps

const ProfitLossSummaryView = (props: Props) => {
  const [loading, setLoading] = useState(true)
  const [openUiState, setOpenUiState] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const columnSetting = useColumnSetting()
  const { data, fetchRecords, masterTree, fetchMasterRecords } =
    useProfitLossSummaryData()
  const ref = useRef<HTMLDivElement>(null)
  const pageState = usePageState(props.uuid)
  const gridOptions = useMemo(() => {
    return profitLossSummaryGridOptions()
  }, [])
  const onExcelExport = useExportExcel(gridOptions)
  const bulkSheetState = useBulkSheetState(
    gridOptions,
    props.uuid,
    UiStateKey.ProfitLossSummary
  )

  const prevPageState = useRef<StoredPageState>({
    startDate: '',
    endDate: '',
    rowHeight: ROW_HEIGHT.SMALL,
    predictionType: PredictionType.Forecast,
  })

  const contextMenu = useCallback((params: GetContextMenuItemsParams) => {
    const menuItems = [
      expandAllMenu(params),
      collapseAllMenu(params),
      expandAllRows(params),
      collapseAllRows(params),
    ]
    return menuItems
  }, [])

  const refreshAll = useCallback(
    async (
      startDate: string,
      endDate: string,
      predictionType: string | undefined
    ) => {
      try {
        setLoading(true)
        await fetchRecords(
          props.currentProject.uuid,
          startDate,
          endDate,
          predictionType
        )
        bulkSheetState.restoreExpandedRows()
        refreshDynamicColumnDef({
          api: gridOptions.api,
          dateTerm: {
            startDate,
            endDate,
          },
        })
      } finally {
        setLoading(false)
      }
    },
    [bulkSheetState, fetchRecords, gridOptions.api, props.currentProject.uuid]
  )

  const onChangeDateTerm = useCallback(
    (term: DateTerm) => {
      if (!!term.startDate) {
        pageState.setStartDate(term.startDate)
      }
      if (!!term.endDate) {
        pageState.setEndDate(term.endDate)
      }
      const states = gridOptions.columnApi
        ?.getColumns()
        ?.filter(v => {
          if (v.isVisible()) return false
          const parent = v.getParent()
          if (!parent || !isAmountColumn(parent.getGroupId())) return false
          return dateVoService.isBetween(
            dateVoService.construct(v.getColId()),
            dateVoService.construct(term.startDate),
            dateVoService.construct(term.endDate),
            '[]'
          )
        })
        .map(v => ({
          colId: v.getColId(),
          hide: false,
        }))
      gridOptions.columnApi?.applyColumnState({ state: states })
    },
    [gridOptions.columnApi, pageState]
  )

  const onChangePredictionType = useCallback(
    (type: string | undefined) => {
      pageState.setPredictionType(type)
    },
    [pageState]
  )

  const onReload = useCallback(() => {
    refreshAll(pageState.startDate, pageState.endDate, pageState.predictionType)
  }, [
    pageState.endDate,
    pageState.predictionType,
    pageState.startDate,
    refreshAll,
  ])

  const isConditionChanged = useCallback(() => {
    if (
      !prevPageState.current ||
      prevPageState.current.startDate !== pageState.startDate ||
      prevPageState.current.endDate !== pageState.endDate ||
      prevPageState.current.predictionType !== pageState.predictionType
    ) {
      prevPageState.current = {
        startDate: pageState.startDate,
        endDate: pageState.endDate,
        rowHeight: pageState.rowHeight,
        predictionType: pageState.predictionType,
      }
      return !!pageState.startDate && !!pageState.endDate
    }
    return false
  }, [
    pageState.endDate,
    pageState.predictionType,
    pageState.rowHeight,
    pageState.startDate,
  ])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        gridOptions.columnApi?.resetColumnState()
        gridOptions.api?.setFilterModel(null)
        gridOptions.api?.onFilterChanged()
      } else {
        setOpenUiState(true)
      }
    },
    [gridOptions.api, gridOptions.columnApi]
  )

  const onDeletedFilterColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridOptions.api) return
      const filterModel = gridOptions.api.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridOptions.api.setFilterModel(filterModel)
    },
    [gridOptions.api]
  )

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [gridOptions.api])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [gridOptions.columnApi]
  )

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [gridOptions.columnApi])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    [gridOptions.columnApi]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filter = bulkSheetState.onFilterChanged(e)
      setFilteredColumns(filter)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      const sorted = bulkSheetState.onSortChanged(e)
      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedState[state.colId] = state
          }
        })
      const sortedList: SortedColumnState[] = sorted.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedState[col.colId]?.sort : null,
        }
      })
      setSortColumnsState(sortedList)
    },
    [bulkSheetState, gridOptions.columnApi]
  )

  const onFirstDataRendered = useCallback(
    e => {
      bulkSheetState.onFirstDataRendered(e)
      setLoading(false)
    },
    [bulkSheetState]
  )

  const closeUiStateDialog = useCallback(() => {
    setOpenUiState(false)
  }, [])

  const callbackSelectUiState = useCallback(
    (uiState: SavedUIState) => {
      const { columnState, filterState } = uiState.UIState
      if (columnState) {
        bulkSheetState.base.saveColumnState(columnState)
        gridOptions.columnApi?.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      if (filterState) {
        bulkSheetState.base.saveFilterState(filterState)
        gridOptions.api?.setFilterModel(filterState)
      }
      closeUiStateDialog()
    },
    [
      bulkSheetState.base,
      closeUiStateDialog,
      gridOptions.api,
      gridOptions.columnApi,
    ]
  )

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.rowHeight])

  useEffect(() => {
    if (pageState.initialized && isConditionChanged()) {
      refreshAll(
        pageState.startDate,
        pageState.endDate,
        pageState.predictionType
      )
    }
  }, [
    isConditionChanged,
    pageState.endDate,
    pageState.initialized,
    pageState.predictionType,
    pageState.startDate,
    refreshAll,
  ])

  useEffect(() => {
    fetchMasterRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (masterTree && masterTree.length > 0) {
      gridOptions.context = {
        ...gridOptions.context,
        masters: masterTree,
      }
    }
  }, [gridOptions, masterTree])

  return (
    <PageArea>
      <ProfitLossSummaryHeader
        loading={loading}
        dateTerm={{
          startDate: pageState.startDate,
          endDate: pageState.endDate,
        }}
        rowHeight={pageState.rowHeight}
        predictionType={pageState.predictionType}
        onSearch={onChangeDateTerm}
        onReload={onReload}
        onClickExport={onExcelExport}
        onChangeRowHeight={pageState.setRowHeight}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeletedFilterColumn}
        resetFilters={resetFilters}
        sortColumnState={sortColumnsState}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        columnSettingOpen={columnSetting.isOpen}
        onClickColumnSettingButton={columnSetting.toggle}
        onChangePredictionType={onChangePredictionType}
      />
      <BulkSheetView
        ref={ref}
        rowData={data}
        gridOptions={gridOptions}
        rowHeight={pageState.rowHeight}
        getContextMenuItems={contextMenu}
        onColumnResized={bulkSheetState.rememberColumnState}
        onColumnVisible={bulkSheetState.rememberColumnState}
        onGridReady={bulkSheetState.onGridReady}
        onRowGroupOpened={bulkSheetState.onRowGroupOpened}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
      />
      <SavedUIStateDialog
        applicationFunctionUuid={props.uuid}
        open={openUiState}
        title={intl.formatMessage({
          id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
        })}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        sharable={false}
        currentUIState={{
          columnState: bulkSheetState.base.columnState,
          filterState: bulkSheetState.base.filterState,
        }}
        onSelect={callbackSelectUiState}
        onClose={closeUiStateDialog}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() => setOpenUiState(true)}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={props.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.base.columnState}
        offset={90}
      />
    </PageArea>
  )
}

export default ProfitLossSummaryView
