import { ListItem, ListItemText, ListItemIcon, Box } from '@mui/material'
import { UiStateScope } from '../../../../../lib/commons/uiStates'
import { FontSize } from '../../../../../styles/commonStyles'
import { colorPalette } from '../../../../style/colorPallete'
import { useSelector } from 'react-redux'
import { intl } from '../../../../../i18n'
import { AllState } from '../../../../../store'
import { useMemo } from 'react'
import { ScopeIcon } from './ScopeIcon'
import { Button } from '../../../buttons'

interface SavedUIStateListItemProps {
  name?: string
  scope?: UiStateScope
  updateUserIconUrl?: string
  updatedBy?: string
  updatedAt?: string
  hiddenSecondaryAction?: boolean
  onClickEditButton?: () => void
  onClickApplyButton?: () => void
}

interface StateProps {
  projectUuid: string | undefined
  projectIconUrl: string | undefined
  tenantIconUrl: string | undefined
  hasAllPrivileges: boolean | undefined
}

const SavedUIStateListItem = ({
  name,
  scope,
  updateUserIconUrl,
  updatedBy,
  updatedAt,
  hiddenSecondaryAction,
  onClickEditButton,
  onClickApplyButton,
}: SavedUIStateListItemProps) => {
  const { projectUuid, projectIconUrl, tenantIconUrl, hasAllPrivileges } =
    useSelector<AllState, StateProps>(state => ({
      projectUuid: state.project.selected,
      projectIconUrl: state.project.current?.iconUrl,
      tenantIconUrl: state.tenant.organization?.iconUrl,
      hasAllPrivileges: state.user.user?.hasAllPrivileges,
    }))
  const scopeIcon = useMemo(() => {
    return scope ? <ScopeIcon scope={scope} /> : <></>
  }, [scope, projectUuid, projectIconUrl, tenantIconUrl])

  return (
    <ListItem
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
        padding: 0,
      }}
    >
      {/* TODO: implement favorite feature
        <Box
          style={{
            width: '32px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <FavoriteIcon />
        </Box> */}
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          color: colorPalette.monotone[10],
          fontSize: FontSize.MEDIUM,
          fontWeight: 500,
        }}
        sx={{
          maxWidth: '200px',
          minWidth: '200px',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
        }}
      />
      <ListItemIcon
        sx={{
          minWidth: '64px',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        {scopeIcon}
      </ListItemIcon>
      <Box
        sx={{
          width: '145px',
          color: colorPalette.monotone[10],
          display: 'flex',
          alignItems: 'center',
          marginLeft: '10px',
        }}
      >
        <ListItemText
          primary={updatedBy || ''}
          primaryTypographyProps={{
            fontSize: FontSize.MEDIUM,
            fontWeight: 500,
            color: colorPalette.monotone[10],
          }}
        />
      </Box>
      <ListItemText
        primary={updatedAt || ''}
        primaryTypographyProps={{
          fontSize: FontSize.SMALL,
          fontWeight: 400,
          color: colorPalette.monotone[4],
        }}
        sx={{
          width: '120px',
          flex: 'initial',
        }}
      />
      {!hiddenSecondaryAction && (
        <Box
          sx={{
            paddingLeft: '16px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 'auto',
            gap: '10px',
          }}
        >
          <Button
            colorPattern="monotone"
            variant="outlined"
            disabled={scope === UiStateScope.CrossProject && !hasAllPrivileges}
            sx={{
              textAlign: 'center',
            }}
            onClick={onClickEditButton}
          >
            {intl.formatMessage({
              id: 'savedUIState.content.button.edit',
            })}
          </Button>
          <Button
            colorPattern="skyBlue"
            variant="filled"
            sx={{
              textAlign: 'center',
            }}
            onClick={onClickApplyButton}
          >
            {intl.formatMessage({
              id: 'savedUIState.content.button.apply',
            })}
          </Button>
          {/* TODO: Delete if we implement new edit UI
          //   <Tooltip
          //     title={props.intl.formatMessage({
          //       id: 'dialog.delete',
          //     })}
          //   >
          //     <IconButton
          //       size="small"
          //       edge="end"
          //       aria-label="edit"
          //       onClick={_ =>
          //         openAlert(
          //           index,
          //           props.intl.formatMessage({
          //             id: 'savedUIState.confirmDelete',
          //           })
          //         )
          //       }
          //     >
          //       <DeleteIcon
          //         color="action"
          //         sx={{ width: '17px' }}
          //       />
          //     </IconButton>
          //   </Tooltip> */}
        </Box>
      )}
    </ListItem>
  )
}

export default SavedUIStateListItem
