import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { WorkloadUnit } from '../../../lib/functions/workload'
import ExclusiveToggleGroup from '../editors/toggle/ExclusiveToggleGroup'

interface Props extends WrappedComponentProps {
  defaultValue?: WorkloadUnit
  refresh: any
  value?: any
  height?: number
  disabled?: boolean
}

export interface State {
  unit?: WorkloadUnit
}

// TODO Delete it
let instance: WorkloadUnitSelect
export const getSelectedWorkloadUnit = () => {
  if (instance) {
    return instance.state.unit || WorkloadUnit.HOUR
  }
  return WorkloadUnit.HOUR
}

class WorkloadUnitSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      unit: this.props.value,
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({ unit: this.props.value })
    }
  }

  componentDidMount() {
    instance = this
    this.setState({
      unit: this.props.value || this.props.defaultValue || WorkloadUnit.HOUR,
    })
  }

  private optionChanged = value => {
    this.setState({ unit: value }, () => {
      this.props.refresh(this.state.unit)
    })
  }

  render() {
    return (
      <ExclusiveToggleGroup
        value={this.state.unit}
        onChange={this.optionChanged}
        options={[
          {
            label: this.props.intl.formatMessage({
              id: 'workloadUnit.hour',
            }),
            value: WorkloadUnit.HOUR,
          },
          {
            label: this.props.intl.formatMessage({
              id: 'workloadUnit.day',
            }),
            value: WorkloadUnit.DAY,
          },
          {
            label: this.props.intl.formatMessage({
              id: 'workloadUnit.month',
            }),
            value: WorkloadUnit.MONTH,
          },
        ]}
        height={this.props.height}
        disabled={this.props.disabled}
      />
    )
  }
}

export const WorkloadUnitToggleGroup = injectIntl(WorkloadUnitSelect)
