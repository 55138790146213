import React, { CSSProperties, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { connect } from 'react-redux'
import store, { AllState } from '../../../store'
import ExpandedMenu from './ExpandedMenu'
import Toggle from '../../components/editors/toggle/Toggle'
import { Drawer } from '@mui/material'
import { toggleSideMenu } from '../../../store/sideMenu'
import ProjectAPI, { ProjectDetail } from '../../../lib/functions/project'
import { setAssignedProject } from '../../../store/project'
import { muiTheme } from '../../../styles/muiTheme'
import MinimizedMenu from './MinimizedMenu'

const theme = muiTheme
const StyledDrawer = styled(Drawer)<{ open: boolean }>(({ open }) => {
  const defaultStyle = {
    display: 'flex',
    flexShrink: 0,
    overflow: 'hidden',
    height: '100%',
    width: `${MINIMIZED_MENU_WIDTH}px`,
  } as CSSProperties
  const paperDefaultStyle = {
    top: 'auto',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    borderRight: 'none !important',
  } as CSSProperties
  if (open) {
    return {
      ...defaultStyle,
      '& .MuiPaper-root': {
        ...paperDefaultStyle,
        width: `${EXPANDED_MENU_WIDTH}px`,
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: '0 0 10px 1px #aaaaaa !important',
      },
    }
  } else {
    return {
      ...defaultStyle,
      '& .MuiPaper-root': {
        ...paperDefaultStyle,
        width: `${MINIMIZED_MENU_WIDTH}px`,
        transition: theme.transitions.create(['width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    }
  }
})
const ToggleWrapper = styled('div')<{ open: boolean }>(({ open }) => {
  const defaultStyle: CSSProperties = {
    zIndex: 1000,
    top: '0px',
    position: 'absolute',
  }
  if (open) {
    return {
      ...defaultStyle,
      left: `${EXPANDED_MENU_WIDTH}px`,
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
  } else {
    return {
      ...defaultStyle,
      left: `${MINIMIZED_MENU_WIDTH}px`,
      transition: theme.transitions.create(['left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }
  }
})

export const MINIMIZED_MENU_WIDTH = 40
export const EXPANDED_MENU_WIDTH = 240

type Props = StateProps

interface StateProps {
  open: boolean
  assignedProjects: ProjectDetail[]
  loggedInProject?: ProjectDetail
}

const SideMenu = ({ open }: Props) => {
  useEffect(() => {
    const fetchAssignedProjects = async () => {
      const response = await ProjectAPI.getAssignedProjects()
      const assigned = response.json as ProjectDetail[]
      store.dispatch(setAssignedProject(assigned))
    }
    fetchAssignedProjects()
  }, [])

  return (
    <>
      <StyledDrawer variant="permanent" anchor="left" open={open}>
        {open ? <ExpandedMenu /> : <MinimizedMenu />}
      </StyledDrawer>
      <ToggleWrapper open={open}>
        <Toggle
          isOpen={open}
          attach="left"
          toggle={e => {
            e.preventDefault()
            e.stopPropagation()
            store.dispatch(toggleSideMenu())
          }}
        />
      </ToggleWrapper>
    </>
  )
}

const mapStateToProps = (state: AllState) => ({
  open: state.sideMenu.open,
  assignedProjects: state.project.assigned || [],
  loggedInProject: state.project.current,
})

export default connect(mapStateToProps)(SideMenu)
