interface State {
  hasRequiredSaveData: boolean
}

// Actions
enum ActionType {
  REQUIRE_SAVE_DATA = 'REQUIRE_SAVE_DATA',
  DO_NOT_REQUIRE_SAVE_DATA = 'DO_NOT_REQUIRE_SAVE_DATA',
}

export function requireSave() {
  return {
    type: ActionType.REQUIRE_SAVE_DATA,
  }
}

export function doNotRequireSave() {
  return {
    type: ActionType.DO_NOT_REQUIRE_SAVE_DATA,
  }
}

// Reducers
export const reducer = (
  state: State = {
    hasRequiredSaveData: false,
  },
  action: any
): State => {
  switch (action.type) {
    case ActionType.REQUIRE_SAVE_DATA:
      return {
        hasRequiredSaveData: true,
      }
    case ActionType.DO_NOT_REQUIRE_SAVE_DATA:
      return {
        hasRequiredSaveData: false,
      }
    default:
      return state
  }
}
