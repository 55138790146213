import { Box } from '@mui/material'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import { GanttDisplayParameter } from '../../../containers/commons/AgGrid/components/cell/custom/gantt/GanttDisplayParameter'
import { Toolbar, ToolbarDivider } from '../../../components/toolbars/Toolbar'
import {
  AggregateField,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import {
  AggregateFieldToggleGroup,
  AggregateTargetToggleGroup,
  WorkloadUnitToggleGroup,
} from '../../../components/toggleGroups'
import { ToolbarToggleValue } from '../../../components/toolbars/Toolbar/ToolbarToggle'
import { ColumnFilterButtonGroup } from '../../../components/buttons/ColumnFilterButtonGroup'
import { intl } from '../../../../i18n'
import { ColumnQuickFilterKey } from '../../ProjectPlan/projectPlanOptions'

interface Props {
  toolbar: ToolbarToggleValue | undefined
  wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK
  onChangeWbsItemType: (value) => void
  aggregateType: AggregateField
  onChangeAggregateType: (value: AggregateField) => void
  workloadUnit: WorkloadUnit
  onChangeWorkloadUnit: (value: WorkloadUnit) => void
  showGanttParameter: boolean
  onChangeColumnFilter: (value: ColumnQuickFilterKey) => void
}

const GROUP_STYLE = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '0 3px',
}
const COLUMN_FILTER_OPTIONS = [
  {
    label: intl.formatMessage({
      id: 'bulksheet.toolbar.columnQuickFilter.label.initial',
    }),
    value: ColumnQuickFilterKey.INITIAL,
  },
  {
    label: intl.formatMessage({
      id: 'bulksheet.toolbar.columnQuickFilter.label.restore',
    }),
    value: ColumnQuickFilterKey.RESTORE,
  },
]

const ProjectPlanToolbar = (props: Props) => {
  return (
    <Toolbar>
      {ToolbarToggleValue.DISPLAY === props.toolbar && (
        <>
          <Box sx={GROUP_STYLE}>
            <AggregateTargetToggleGroup
              value={props.wbsItemType}
              onChange={props.onChangeWbsItemType}
            />
            <AggregateFieldToggleGroup
              value={props.aggregateType}
              onChange={props.onChangeAggregateType}
            />
            <WorkloadUnitToggleGroup
              value={props.workloadUnit}
              refresh={props.onChangeWorkloadUnit}
            />
          </Box>
          <ToolbarDivider />
          <ColumnFilterButtonGroup
            options={COLUMN_FILTER_OPTIONS}
            onChange={props.onChangeColumnFilter}
          />
          <Box sx={GROUP_STYLE}>
            <GanttDisplayParameter visible={props.showGanttParameter} />
          </Box>
        </>
      )}
    </Toolbar>
  )
}

export default ProjectPlanToolbar
