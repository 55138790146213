import moment from 'moment'
import _ from 'lodash'
import { generateUuid } from '../../../utils/uuids'
import {
  getOrganizationWorkingDayCalendar,
  OrganizationWorkingDayCalendarBatchInput,
  OrganizationWorkingDayCalendarDetail,
  OrganizationWorkingDayCalendarInput,
  updateBatch,
} from '../../../lib/functions/organizationWorkingDayCalendar'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import { DISPLAY_DATE_FORMAT, formatDateTime } from '../../../utils/date'
import { UiStateKey } from '../../../lib/commons/uiStates'
import {
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetSpecificProps,
  BulkSheetState,
} from '../../containers/BulkSheet'
import { APIResponse } from '../../../lib/commons/api'
import ViewMeta from '../../containers/meta/ViewMeta'
import { ColDef, NewValueParams } from 'ag-grid-community'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface OrganizationWorkingDayCalendarState extends BulkSheetState {
  year?: number
  month?: number
}

export class OrganizationWorkingDayCalendarRow extends RowData {
  revision: string = ''
  code: string = ''
  date: string = ''
  workHour: number = 0
  name?: string
  legal: boolean = false
}

interface OrganizationWorkingDayCalendarBulkSheetContext
  extends BulkSheetContext<
    BulkSheetSpecificProps,
    OrganizationWorkingDayCalendarDetail,
    OrganizationWorkingDayCalendarRow,
    OrganizationWorkingDayCalendarState
  > {}

class OrganizationWorkingDayCalendarRowDataSpec extends RowDataSpec<
  OrganizationWorkingDayCalendarDetail,
  OrganizationWorkingDayCalendarRow
> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      calendarDateColumn: {
        cellClass: params => {
          if (params.data.legal) {
            return 'sevend-ag-grid-date-header-holiday'
          } else if (moment(params.value).day() === 6) {
            return 'sevend-ag-grid-date-header-saturday'
          }
          return ''
        },
      },
      workHourColumn: {
        cellStyle: params => {
          const isSaturday = moment(params.data.date).day() === 6
          const isHoliday = params.data.legal || isSaturday
          if (!isHoliday && !params.data.workHour) {
            return {
              color: 'red',
            }
          } else {
            return {
              color: 'black',
            }
          }
        },
      },
    }
  }
  createNewRow(): OrganizationWorkingDayCalendarRow {
    return new OrganizationWorkingDayCalendarRow(generateUuid())
  }
  overwriteRowItemsWithParents(params: {
    child: OrganizationWorkingDayCalendarRow
    parent: OrganizationWorkingDayCalendarRow
  }): OrganizationWorkingDayCalendarRow {
    return params.child
  }
  createRowByResponse(
    response: OrganizationWorkingDayCalendarDetail
  ): OrganizationWorkingDayCalendarRow {
    return {
      ...response,
      date: moment(response.date).format(DISPLAY_DATE_FORMAT),
      createdBy: response.createdBy,
      createdAt: formatDateTime(response.createdAt),
      updatedBy: response.updatedBy,
      updatedAt: formatDateTime(response.updatedAt),
    }
  }
  duplicateRow(
    original: OrganizationWorkingDayCalendarRow
  ): OrganizationWorkingDayCalendarRow {
    return {
      ...original,
      code: '',
    }
  }
}

export default class OrganizationWorkingDayCalendarOptions extends BulkSheetOptions<
  BulkSheetSpecificProps,
  OrganizationWorkingDayCalendarDetail,
  OrganizationWorkingDayCalendarRow,
  OrganizationWorkingDayCalendarState
> {
  fetchDataOnInit = true
  addable = false
  draggable = false
  columnAndFilterStateKey =
    UiStateKey.OrganizationWorkingDayCalendarColumnAndFilterState
  rowDataSpec = new OrganizationWorkingDayCalendarRowDataSpec()
  pinnedColumns = ['organizationWorkingDayCalendar.code']
  lockedColumns = ['organizationWorkingDayCalendar.code']
  customColumnTypes = {
    'organizationWorkingDayCalendar.date': ['calendarDateColumn'],
    'organizationWorkingDayCalendar.workHour': ['workHourColumn'],
  }
  getOnCellValueChanged = (field: string) => {
    if (field === 'legal') {
      return (event: NewValueParams) => {
        if (event.oldValue !== event.newValue && event.node) {
          event.api.refreshCells({
            rowNodes: [event.node],
            force: true,
          })
        }
      }
    }
    return undefined
  }

  getAll(state: OrganizationWorkingDayCalendarState): Promise<APIResponse> {
    const startDate =
      state.year && state.month
        ? moment(
            `${state.year}-${_.padStart(`${state.month}`, 2, '0')}-01`
          ).toDate()
        : moment().startOf('month').toDate()
    const endDate = moment(startDate).endOf('month').toDate()
    return getOrganizationWorkingDayCalendar({
      startDate,
      endDate,
    })
  }
  onSubmit = (
    ctx: OrganizationWorkingDayCalendarBulkSheetContext,
    data: {
      edited: {
        before: OrganizationWorkingDayCalendarRow
        after: OrganizationWorkingDayCalendarRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const input: OrganizationWorkingDayCalendarBatchInput = {
      edited: data.edited.map(row => {
        return this.createRequestByRow(row.after, viewMeta)
      }),
    }
    return updateBatch(input)
  }

  private createRequestByRow(
    row: OrganizationWorkingDayCalendarRow,
    viewMeta: ViewMeta
  ): OrganizationWorkingDayCalendarInput {
    return {
      uuid: row.uuid,
      lockVersion: row.lockVersion,
      revision: row.revision,
      code: row.code,
      date: viewMeta.serializeInputForApi(
        row.date,
        viewMeta.functionMeta.properties.byId.get(
          'organizationWorkingDayCalendar.date'
        )!
      ),
      workHour: row.workHour,
      name: row.name!,
      legal: row.legal,
    }
  }

  onSearch(
    ctx: OrganizationWorkingDayCalendarBulkSheetContext,
    searchCondition: any
  ) {
    ctx.setState(
      {
        year: searchCondition.year,
        month: searchCondition.month,
      },
      ctx.refreshDataWithLoading
    )
  }

  getSearchCondition = (state: OrganizationWorkingDayCalendarState) => {
    return {
      projectUuid: state.uuid,
      year: state.year,
      month: state.month,
    }
  }

  restoreSearchCondition = (
    searchCondition: any,
    ctx: OrganizationWorkingDayCalendarBulkSheetContext
  ) => {
    this.onSearch(ctx, searchCondition)
  }

  refreshConditionAndColumn = (
    ctx: OrganizationWorkingDayCalendarBulkSheetContext
  ) => {
    this.onSearch(ctx, {
      ...ctx.state,
      year: moment().year(),
      month: moment().month() + 1,
    })
  }

  getCellRendererParams = (
    field: string
  ): { [key: string]: any } | undefined => {
    if (field === 'workHour') {
      return {
        highlightForZero: true,
      }
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (field === 'workHour') {
      return 100
    }
    return undefined
  }
}
