import { useCallback, useEffect, useState } from 'react'
import {
  WbsItemType,
  AggregateField,
} from '../../../domain/entity/WbsItemEntity'
import {
  getUiState,
  UiStateKey,
  UiStateScope,
  updateUiState,
} from '../../../lib/commons/uiStates'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { ToolbarToggleValue } from '../../components/toolbars/Toolbar/ToolbarToggle'

export type ProjectPlanPageState = {
  wbsItemType: WbsItemType.DELIVERABLE | WbsItemType.TASK
  aggregateTargetType: AggregateField
  workloadUnit: WorkloadUnit
  dateFormat: string
  toolbar: ToolbarToggleValue | undefined
}

type ProjectPlanPageStateHandler = (
  updated: Partial<ProjectPlanPageState>
) => void

const projectPlanDefaultPageState: ProjectPlanPageState = {
  wbsItemType: WbsItemType.DELIVERABLE,
  aggregateTargetType: AggregateField.WBS_ITEM_COUNT,
  workloadUnit: WorkloadUnit.DAY,
  toolbar: ToolbarToggleValue.DISPLAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
}

/**
 * Adapt old stored page state to the newest structure.
 */
const projectPlanStoredPageStateAdaptor = (src: any): ProjectPlanPageState => {
  return {
    wbsItemType: src.wbsItemType || projectPlanDefaultPageState.wbsItemType,
    aggregateTargetType:
      src.aggregateTargetType ||
      // adapt old field name to new one.
      src.aggregateType ||
      projectPlanDefaultPageState.aggregateTargetType,
    workloadUnit: src.workloadUnit || projectPlanDefaultPageState.workloadUnit,
    toolbar:
      typeof src.toolbar === 'undefined'
        ? undefined
        : src.toolbar || projectPlanDefaultPageState.toolbar,
    dateFormat: src.dateFormat || projectPlanDefaultPageState.dateFormat,
  }
}

export const useProjectPlanPageState = (
  functionUuid: string
): ProjectPlanPageState & {
  updatePageState: ProjectPlanPageStateHandler
} => {
  const [pageState, setPageState] = useState<ProjectPlanPageState>(
    projectPlanDefaultPageState
  )
  const updatePageState = useCallback(
    (updated: Partial<ProjectPlanPageState>) => {
      setPageState(prev => {
        const newPageState = {
          ...prev,
          ...updated,
        }
        updateUiState({ ...newPageState }, functionUuid)
        return newPageState
      })
    },
    []
  )
  useEffect(() => {
    const fetchAndSetPageState = async () => {
      const storedPageState = await getUiState(
        functionUuid,
        UiStateKey.PageState,
        UiStateScope.User
      )
      if (!storedPageState) return
      const pageState = projectPlanStoredPageStateAdaptor(storedPageState)
      setPageState(pageState)
    }
    fetchAndSetPageState()
  }, [])
  return {
    ...pageState,
    updatePageState,
  }
}
