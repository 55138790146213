import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import {
  Avatar,
  MenuProps,
  MenuItem,
  styled,
  Typography,
  Select,
} from '@mui/material'
import { moveToNextRow } from './index'
import { Color } from '../../../../../styles/commonStyles'

interface SelectOption {
  value: string
  label: string
  iconUrl?: string
}
interface Props extends ICellEditorParams {
  options: SelectOption[] | ((params: Props) => SelectOption[])
}

export const SelectCellEditor = forwardRef((props: Props, ref) => {
  const { value, options, api, colDef, column, rowIndex } = props
  const [val, setVal] = useState(value || '')
  const firstRendered = useRef(true)
  const refInput = useRef<HTMLSelectElement>(null)

  useEffect(() => {
    refInput.current?.focus()
  }, [])

  useEffect(() => {
    if (firstRendered.current) {
      firstRendered.current = false
      return
    }
    api.stopEditing()
  }, [val])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return val
      },
    }
  })

  if (!options) return <></>

  const selectOptions = typeof options === 'function' ? options(props) : options

  return (
    <EditorSelect
      ref={refInput}
      variant="standard"
      value={val}
      disableUnderline={true}
      open={true}
      onChange={e => setVal(e.target.value)}
      onClose={() => setTimeout(() => api.stopEditing(), 100)}
      MenuProps={selectCellMenuProps}
    >
      {selectOptions.map((option: SelectOption, i: number) => {
        return (
          <EditorSelectMenuItem
            key={`${colDef.field}-${rowIndex}-${i}`}
            value={option.value}
            onKeyDown={e =>
              setTimeout(() => {
                if (e.key === 'Enter') {
                  // Enter: Move to next row
                  if (api && rowIndex !== api.getLastDisplayedRow()) {
                    moveToNextRow(api, rowIndex, column)
                  }
                } else if (e.key === 'Tab') {
                  // Tab: Move to next cell
                  api.tabToNextCell()
                }
              }, 100)
            }
          >
            {option.iconUrl && (
              <Avatar
                variant="circular"
                src={option.iconUrl}
                sx={{ margin: '0px 5px', width: 20, height: 20 }}
              />
            )}
            <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
              {option.label}
            </Typography>
          </EditorSelectMenuItem>
        )
      })}
    </EditorSelect>
  )
})

const EditorSelect = styled(Select)({
  width: '100%',
  height: '100%',
  '& .MuiSelect-select': {
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

const EditorSelectMenuItem = styled(MenuItem)(
  (props: { backgroundColor?: string }) => ({
    display: 'flex',
    margin: '2px',
    height: '26px',
    backgroundColor: props.backgroundColor
      ? `${props.backgroundColor}66`
      : 'inherit',
    transparent: '50%',
    borderRadius: '3px',
    '&:focus': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
    '&:hover': {
      color: Color.MAIN,
      backgroundColor: props.backgroundColor ?? 'inherit',
    },
  })
)

const selectCellMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
}
