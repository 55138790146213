import { ColGroupDef, GridOptions } from 'ag-grid-community'
import useDefaultColumns, {
  useDeliverableEstimatedColumnDef,
} from './defaultColumns'
import defaultGridOptions from './defaultGridOptions'
import { useMemo } from 'react'

const useDeliverableTabColumnDef = () => {
  const defaultColDef = useDefaultColumns()
  const deliverableEstimatedColumnDef = useDeliverableEstimatedColumnDef()
  const columnDefs = useMemo(() => {
    const estimatedGroup = defaultColDef.find(
      colDef => colDef.externalId === 'myWbsItems.estimated'
    ) as ColGroupDef | undefined
    if (estimatedGroup && estimatedGroup.children) {
      estimatedGroup.children.push(deliverableEstimatedColumnDef)
    }
    return defaultColDef
  }, [defaultColDef, deliverableEstimatedColumnDef])
  return columnDefs
}

export const useMyWbsItemDeliverableTabGridOptions = (): GridOptions => {
  const columnDefs = useDeliverableTabColumnDef()
  const gridOptions = useMemo(
    () => ({
      ...defaultGridOptions(),
      columnDefs,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return gridOptions
}
