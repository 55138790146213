import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import { ColDef, ColumnState } from 'ag-grid-community'
import { WbsItemSearchDetail } from '../../../lib/functions/wbsItem'
import { AllState } from '../../../store'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import {
  QuickFilterKeys,
  WbsItemSearchOptions,
  WbsItemSearchRow,
  WbsItemSearchState,
  ColumnQuickFilterKey,
} from './wbsItemSearchOptions'
import { connect, useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { usePageState } from '../../hooks/usePageState'
import { useWbsItemSearchSearchCondition } from './useSearchCondition'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useWbsItemSearchConditionApiRequestTransformService } from '../../../services/transform-service/wbsItemSearchConditionApiRequestTransformService'
import { useProjectPrivateContext } from '../../context/projectContext'
import { SearchConditionAndColumnState } from '../../../services/model/searchConditionAndColumnState'
import { WbsItemSearchConditionVO } from '../../../domain/value-object/WbsItemSearchConditionVo'
import { Function } from '../../../lib/commons/appFunction'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { fetchTagsByProject } from '../../../store/tag'
import WbsItemSearchWbsHeader from './Header/WbsItemSearchWbsHeader'
import WbsItemSearchSearchConditionHeader from './Header/WbsItemSearchSearchConditionHeader'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { FunctionLayer } from '../../../store/functionLayer'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import ProjectPlanNewBreadcrumb from '../ProjectPlanNew/components/Header/ProjectPlanNewBreadcrumb'
import project from '../../../lib/functions/project'
import { needToOpenInNewTab } from '../../router'
import {
  getProjectPlanUuidByWbsItemUuid,
  getWbsItemUuidByProjectPlanUuid,
} from '../../../lib/functions/projectPlan'
import { useWbsItemSearchConditionSerializeService } from '../../../services/transform-service/wbsItemSearchConditionSerializeService'
import { StoredWbsItemSearchCondition } from '../../../services/model/storedWbsItemSearchCondition'
import { toUrlQuery } from '../../../lib/commons/api'

type WbsItemSearchPageState = {
  toolbar: ToolbarToggleValue | undefined
  workloadUnit: WorkloadUnit
  dateFormat: string
}

const wbsItemSearchDefaultPageState: WbsItemSearchPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  workloadUnit: WorkloadUnit.HOUR,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
}

type SearchResult = {
  hit: number
  total: number
}

type StateProps = {
  functions: Function[]
  functionLayers: Immutable.Map<number, FunctionLayer>
}

const WbsItemSearch = (props: PageProps & StateProps) => {
  const fn = useMemo(
    () => props.functions.find(v => v.externalId === props.externalId)!,
    [props.functions, props.externalId]
  )

  const { projectUuid } = useProjectPrivateContext()

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<
        BulkSheetSpecificProps,
        WbsItemSearchDetail,
        WbsItemSearchRow,
        WbsItemSearchState
      >
    >()
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [searchResult, setSearchResult] = useState<SearchResult>({
    hit: 0,
    total: 0,
  })
  const [quickFilters, setQuickFilters] = useState<
    QuickFilterKeys[] | undefined
  >(undefined)

  const [currentColumnState, setCurrentColumnState] = useState<ColumnState[]>(
    []
  )

  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const [rootProjectPlanUuid, setRootProjectPlanUuid] = useState<
    string | undefined
  >()

  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])

  const wbsItemSearchOptions = useMemo(() => {
    const options = new WbsItemSearchOptions()
    const originalGetAll = options.getAll
    options.getAll = async state => {
      const response = await originalGetAll(state)
      setSearchResult({
        hit: response.json.hit || 0,
        total: response.json.total || 0,
      })
      return response
    }
    options.onColumnVisible = (e, ctx) => {
      setCurrentColumnState(bulkSheet?.columnApi?.getColumnState() ?? [])
    }
    return options
  }, [bulkSheet])

  const {
    initialized: searchConditionInitialized,
    searchCondition,
    saveSearchCondition,
    updateSearchCondition,
    overrideSearchCondition,
    resetSearchCondition,
    columnState,
  } = useWbsItemSearchSearchCondition(props.uuid, projectUuid)

  const searchConditionApiRequestTransformer =
    useWbsItemSearchConditionApiRequestTransformService(projectUuid)

  const isChangedRootUuidByBreadcrumbs = useRef<boolean>(false)
  const changeRootUuidCallback = useCallback(() => {
    if (isChangedRootUuidByBreadcrumbs.current) {
      bulkSheet?.refreshDataWithLoading()
      isChangedRootUuidByBreadcrumbs.current = false
    }
  }, [isChangedRootUuidByBreadcrumbs.current, bulkSheet])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setState(
      {
        searchCondition:
          searchConditionApiRequestTransformer.toApiRequest(searchCondition),
      },
      changeRootUuidCallback
    )
  }, [bulkSheet, searchCondition, changeRootUuidCallback])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setState(
      {
        searchConditionInitialized,
      },
      () => {
        bulkSheet.columnApi?.applyColumnState({ state: columnState })
        bulkSheet.refreshDataWithLoading()
      }
    )
  }, [bulkSheet, searchConditionInitialized])

  useEffect(() => {
    const fetchProjectPlanUuid = async () => {
      if (searchCondition.rootUuid) {
        const converted = await getProjectPlanUuidByWbsItemUuid(
          searchCondition.rootUuid
        )
        setRootProjectPlanUuid(converted)
      }
    }
    fetchProjectPlanUuid()
  }, [searchCondition.rootUuid])

  const { toolbar, workloadUnit, dateFormat, updatePageState } =
    usePageState<WbsItemSearchPageState>(
      fn.uuid,
      wbsItemSearchDefaultPageState,
      storedUiStateWithToolbarToggleAdaptor
    )

  const workloadUnitState = useWorkloadUnit(workloadUnit)

  const onChangeWorkloadUnit = useCallback((workloadUnit: WorkloadUnit) => {
    updatePageState({ workloadUnit })
  }, [])

  /**
   * Search related functions.
   * MEMO: These functions may be merged after migration of BulkSheet is completed.
   */

  // This is used when clicked `Search button`.
  const onSearch = useCallback(() => {
    if (!bulkSheet) return
    bulkSheet.refreshDataWithLoading()
    saveSearchCondition()
  }, [bulkSheet, saveSearchCondition])

  // This is used when applying permanent ui state.
  const overrideSearchConditionAndSearch = useCallback(
    (newSearchCondition: WbsItemSearchConditionVO) => {
      if (!bulkSheet) return
      overrideSearchCondition(newSearchCondition)
      bulkSheet.setState(
        {
          searchCondition:
            searchConditionApiRequestTransformer.toApiRequest(
              newSearchCondition
            ),
        },
        bulkSheet.refreshDataWithLoading
      )
    },
    [bulkSheet, overrideSearchCondition]
  )

  const onChangeQuickFilters = useCallback(
    (values: string[]) => {
      if (!bulkSheet) return
      const filters: QuickFilterKeys[] = values.map(v => QuickFilterKeys[v])
      bulkSheet.setState({ quickFilters: filters }, () => {
        bulkSheet.refreshFilteredRowDataUuid()
      })
      setQuickFilters(values as QuickFilterKeys[])
    },
    [bulkSheet]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
        return
      }
      if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
        return
      }
    },
    [bulkSheet]
  )

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ workloadUnit, workloadUnitState })
    bulkSheet.gridApi?.refreshCells({
      columns: [
        'wbsItem.estimatedWorkload.deliverable',
        'wbsItem.estimatedWorkload.task',
        'plannedValue',
        'earnedValue',
        'preceding',
        'delayedWorkload',
        'remainingWorkload',
        'wbsItem.actualHour',
        'costVariance',
        'costPerformanceIndex',
        'scheduleVariance',
        'schedulePerformanceIndex',
        'estimateToComplete',
        'estimateAtCompletion',
        'varianceAtCompletion',
      ],
      force: true,
    })
  }, [workloadUnit, workloadUnitState, bulkSheet])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ dateFormat })
    bulkSheet.gridApi?.refreshCells({
      columns: [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
      ],
      force: true,
    })
  }, [dateFormat, bulkSheet])

  const applyStoredState = useCallback(
    (storedState: SearchConditionAndColumnState) => {
      if (!bulkSheet) return
      overrideSearchConditionAndSearch(storedState.searchCondition)
      bulkSheet.columnApi?.applyColumnState({ state: storedState.columnState })
    },
    [bulkSheet, overrideSearchConditionAndSearch]
  )

  const transformer = useWbsItemSearchConditionSerializeService()
  const switchRootWbsItem = useCallback(
    async (
      newRootProjectPlanUuid: string | undefined,
      openInNewTab?: boolean
    ) => {
      const newRootUuid = await getNewRootUuid(newRootProjectPlanUuid)
      const plainObject: StoredWbsItemSearchCondition & { rootUuid?: string } =
        transformer.fromVoToStoredObject(searchCondition)
      plainObject.rootUuid = newRootUuid
      const query = toUrlQuery(plainObject)

      if (openInNewTab) {
        const res = await project.getDetail({ uuid: projectUuid })
        const url = `${window.location.origin}/wbsItemSearch/${res.json.code}${
          query ? `?${query}` : ''
        }`
        window.open(url)
        return
      }
      updateSearchCondition({ rootUuid: newRootUuid })
      setRootProjectPlanUuid(newRootProjectPlanUuid)
      history.replaceState(undefined, '', `?${query}`)
      isChangedRootUuidByBreadcrumbs.current = true
    },
    [projectUuid, searchCondition]
  )

  const getNewRootUuid = useCallback(
    async (
      newRootProjectPlanUuid: string | undefined
    ): Promise<string | undefined> => {
      if (!newRootProjectPlanUuid) return undefined
      const path = await getWbsItemUuidByProjectPlanUuid(newRootProjectPlanUuid)
      return path
    },
    []
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchTagsByProject(projectUuid))
  }, [projectUuid])
  const columnSetting = useColumnSetting()
  const ref = useRef<HTMLDivElement>(null)

  return (
    <PageArea>
      {props.functionLayers.size === 1 && (
        <SavePopper
          loading={isLoading}
          onSubmit={() => bulkSheet?.onSubmit()}
        />
      )}
      {rootProjectPlanUuid && (
        <ProjectPlanNewBreadcrumb
          rootProjectPlanUuid={rootProjectPlanUuid}
          onSelectBreadcrumb={(event, breadcrumb) =>
            switchRootWbsItem(breadcrumb.uuid, needToOpenInNewTab(event))
          }
        />
      )}
      <WbsItemSearchSearchConditionHeader
        searchCondition={searchCondition}
        search={onSearch}
        hit={searchResult.hit}
        total={searchResult.total}
        onChange={updateSearchCondition}
        resetCondition={resetSearchCondition}
        functionUuid={fn.uuid}
        applyStoredState={applyStoredState}
        currentColumnState={[]} // TODO: refactor
      />
      <WbsItemSearchWbsHeader
        workloadUnit={workloadUnit}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        onCancel={() => bulkSheet?.onCancel()}
        isLoading={isLoading}
        onChangeQuickFilters={onChangeQuickFilters}
        quickFilters={quickFilters ?? []}
        onDeleteSortedColumn={bulkSheet?.onDeleteSortedColumn}
        onDeleteSortedAllColumns={bulkSheet?.onDeleteSortedAllColumns}
        onChangeSortColumnState={bulkSheet?.onChangeSortColumnState}
        rowHeight={rowHeight}
        onChangeHeight={value => bulkSheet?.onChangeHeight(value)}
        onClickExportExcel={() =>
          bulkSheet?.openExcelOutputColumnSelectDialog()
        }
        onClickFavoriteColumnFilterButton={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={column =>
          bulkSheet?.resetSpecificColumnFilter(column as string)
        }
        resetFilters={() => bulkSheet?.resetColumnAndFilterState()}
        sortColumnsState={sortColumnsState}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={bulkSheet?.columnApi ?? undefined}
        gridApi={bulkSheet?.gridApi ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.RESTORE)
        }
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={fn.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={currentColumnState}
        offset={90}
      />
      <Box sx={{ height: '100%' }} ref={ref}>
        <BulkSheetComponent
          {...props}
          options={wbsItemSearchOptions}
          hideHeader={true}
          hideToolbar={true}
          setBulkSheet={ctx => setBulkSheet(ctx)}
          setFilteredColumns={columns => setFilteredColumns(columns)}
          setRowHeight={height => setRowHeight(height)}
          setSubmitDisabled={value => setSubmitDisabled(value)}
          setIsLoading={value => setIsLoading(value)}
          setSortColumnsState={value => setSortColumnsState(value)}
        />
      </Box>
    </PageArea>
  )
}

const mapStateToProps = (state: AllState, props) => ({
  functions: state.appFunction.functions,
  functionLayers: state.functionLayer.layers,
})

export default connect(mapStateToProps)(
  pageComponent(projectPrivate(WbsItemSearch))
)
