import { useCallback, useRef, useState } from 'react'
import _ from 'lodash'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import {
  DevelopmentEventWbsItemStatusMappingRow,
  createNewDevelopmentEventWbsItemStatusMappingRow,
} from '../developmentEventWbsItemStatusMapping'
import { useDispatch } from 'react-redux'
import { useDevelopmentEventWbsItemStatusMappingRepository } from '../../../../services/adaptors/developmentEventWbsItemStatusMappingRepositoryAdaptor'

export const useDevelopmentEventWbsItemStatusMappingsData = projectUuid => {
  const [data, setDataInternal] = useState<
    DevelopmentEventWbsItemStatusMappingRow[]
  >([])
  const [deletedRows, setDeletedRows] = useState<
    DevelopmentEventWbsItemStatusMappingRow[]
  >([])
  const dispatch = useDispatch()

  const clearData = useCallback(() => {
    setDataInternal([])
  }, [])

  const developmentEventRepository =
    useDevelopmentEventWbsItemStatusMappingRepository()

  const fetchRecords = useCallback(async () => {
    setDataInternal([])
    const entities = await developmentEventRepository.getByProjectUuid(
      projectUuid
    )

    const rows: DevelopmentEventWbsItemStatusMappingRow[] = entities.map(v => {
      const wbsItemType = v.wbsItemType
      return {
        uuid: v.uuid,
        treeValue: [v.uuid],
        lockVersion: v.lockVersion,
        projectUuid: v.projectUuid,
        wbsItemType,
        ticketType: wbsItemType.isTicket()
          ? wbsItemType.code.replace('_LIST', '')
          : undefined,
        developmentEvent: v.developmentEvent,
        wbsItemStatus: v.wbsItemStatus,
        wbsItemSubstatus: v.wbsItemSubstatus,
        revision: v.revision,
        createdAt: v.createdAt,
        createdBy: v.createdBy,
        updatedAt: v.updatedAt,
        updatedBy: v.updatedBy,
      }
    })
    setDataInternal(rows)
    setDeletedRows([])
    if (rows.length === 0) {
      const firstRow =
        createNewDevelopmentEventWbsItemStatusMappingRow(projectUuid)
      setDataInternal([
        { ...firstRow, added: true, treeValue: [firstRow.uuid] },
      ])
    }
  }, [])

  const refresh = useCallback(async () => {
    await fetchRecords()
    dispatch(doNotRequireSave())
  }, [fetchRecords])

  const save = useCallback(async (): Promise<{
    hasError: boolean
    hasWarning: boolean
  }> => {
    const addedRows = data.filter(row => row.added)
    const editedRows = data.filter(row => row.edited && !row.added)
    return developmentEventRepository.save({
      added: addedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        wbsItemType: v.wbsItemType!,
        developmentEvent: v.developmentEvent!,
        wbsItemStatus: v.wbsItemStatus!,
        wbsItemSubstatus: v.wbsItemSubstatus!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
      edited: editedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        wbsItemType: v.wbsItemType!,
        developmentEvent: v.developmentEvent!,
        wbsItemStatus: v.wbsItemStatus!,
        wbsItemSubstatus: v.wbsItemSubstatus!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
      deleted: deletedRows.map(v => ({
        uuid: v.uuid,
        lockVersion: v.lockVersion || 0,
        revision: v.revision!,
        projectUuid: v.projectUuid,
        wbsItemType: v.wbsItemType!,
        developmentEvent: v.developmentEvent!,
        wbsItemStatus: v.wbsItemStatus!,
        wbsItemSubstatus: v.wbsItemSubstatus!,
        createdAt: v.createdAt!,
        createdBy: v.createdBy!,
        updatedAt: v.updatedAt!,
        updatedBy: v.updatedBy!,
      })),
    })
  }, [data, deletedRows])

  const setData = useCallback(
    (d: DevelopmentEventWbsItemStatusMappingRow[]) => {
      setDataInternal(d)
      dispatch(requireSave())
    },
    []
  )

  const deleteRows = useCallback(
    (rows: DevelopmentEventWbsItemStatusMappingRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
    },
    [deletedRows]
  )

  const hasDuplicatedData = useCallback(() => {
    const isDuplicated = (
      a: DevelopmentEventWbsItemStatusMappingRow,
      b: DevelopmentEventWbsItemStatusMappingRow
    ) =>
      a.uuid !== b.uuid &&
      a.projectUuid === b.projectUuid &&
      a.wbsItemType?.uuid === b.wbsItemType?.uuid &&
      a.developmentEvent === b.developmentEvent

    return data.some(v => data.some(other => isDuplicated(v, other)))
  }, [data])

  return {
    data,
    setData,
    refresh,
    save,
    clearData,
    deleteRows,
    hasDuplicatedData,
  }
}
