import { CommitVO } from '../../../domain/value-object/development/CommitVO'
import { useDevelopmentRepository } from '../../../services/adaptors/developmentRepositoryAdaptor'

export type DevelopmentInformation = {
  commits: CommitVO[]
}

type DevelopmentByWbsItem = {
  getDevelopmentByWbsItem: (
    wbsItemUuid: string
  ) => Promise<DevelopmentInformation>
}

export const useDevelopmentByWbsItem = (): DevelopmentByWbsItem => {
  const repository = useDevelopmentRepository()
  const getDevelopmentByWbsItem = async (
    wbsItemUuid: string
  ): Promise<DevelopmentInformation> => {
    const commits = await repository.getCommitsByWbsItemUuid(wbsItemUuid)
    return {
      commits,
    }
  }
  return { getDevelopmentByWbsItem }
}
