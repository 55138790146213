import { ClickAwayListener, IconButton, Popper, styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import {
  DateBucket,
  DateBucketType,
} from '../../../../components/charts/model/timeSeries'
import {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BurndownChartCondition,
  BurndownChartData,
  BurndownChartSummary,
} from '../../model'
import { BurndownChartDiffDetail } from '../../hooks/diffDetail'
import { ValueFormatters } from '../../hooks/valueFormatters'
import { intl } from '../../../../../i18n'
import {
  FormattedValueDiff,
  ValueDiffType,
  useDiffFormatter,
} from './formatter'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { useValueDetail } from '../../hooks/valueDetail'
import { getBucketRepresentativeGetter } from '../../../../components/charts/utils/timeSeries'

type BurndownChartCardPanelProps = {
  xBucket: DateBucket
  formatBucket: (v: DateBucket) => string
  summary: BurndownChartSummary
  data: BurndownChartData
  diffDetail: BurndownChartDiffDetail | undefined
  formatters: ValueFormatters
  dateBucketType: DateBucketType
  condition: BurndownChartCondition
  teamUuid?: string
}
export const BurndownChartCardPanel = ({
  xBucket,
  formatBucket,
  summary,
  data: {
    scheduleDataBurnDown,
    scheduleData,
    actualAndProspectDataBurnDown,
    actualAndProspectData,
  },
  diffDetail,
  formatters: { formatValue, formatRatePercent, unitLabel },
  dateBucketType,
  condition,
  teamUuid,
}: BurndownChartCardPanelProps) => {
  const xLabel = useMemo(() => {
    return formatBucket(xBucket)
  }, [formatBucket, xBucket])
  const getBucketRepresentative = useMemo(
    () => getBucketRepresentativeGetter(dateBucketType),
    [dateBucketType]
  )
  const x = useMemo(
    () => getBucketRepresentative(xBucket),
    [getBucketRepresentative, xBucket]
  )
  const isPassed = useMemo(() => x < new Date(), [x])

  const { scheduleRemaining, scheduleDiff, scheduleValue } = useMemo(() => {
    const scheduleRemaining =
      scheduleDataBurnDown.find(
        d => getBucketRepresentative(d.x).valueOf() === x.valueOf()
      )?.y || 0
    const scheduleDiff =
      scheduleData.find(
        d => getBucketRepresentative(d.x).valueOf() === x.valueOf()
      )?.y || 0
    const scheduleValue = summary.total - scheduleRemaining
    return {
      scheduleRemaining,
      scheduleDiff,
      scheduleValue,
    }
  }, [
    scheduleDataBurnDown,
    scheduleData,
    summary.total,
    getBucketRepresentative,
    x,
  ])

  const { actualRemaining, actualDiff, actualValue } = useMemo(() => {
    const actualRemaining =
      actualAndProspectDataBurnDown.find(
        d => getBucketRepresentative(d.x).valueOf() === x.valueOf()
      )?.y || 0
    const actualDiff =
      actualAndProspectData.find(
        d => getBucketRepresentative(d.x).valueOf() === x.valueOf()
      )?.y || 0
    const actualValue = summary.total - actualRemaining
    return {
      actualRemaining,
      actualDiff,
      actualValue,
    }
  }, [
    actualAndProspectDataBurnDown,
    actualAndProspectData,
    summary.total,
    getBucketRepresentative,
    x,
  ])

  const { delay, delayDiff, preceding } = useMemo(() => {
    if (!diffDetail) {
      return {
        delay: 0,
        delayDiff: 0,
        preceding: 0,
      }
    }
    const delayIndex = diffDetail.delayed.findIndex(
      d => d.date.valueOf() === x.valueOf()
    )
    const preceding =
      diffDetail.preceding.find(d => d.date.valueOf() === x.valueOf())?.value ||
      0
    if (delayIndex === -1) {
      return {
        delay: 0,
        delayDiff: 0,
        preceding,
      }
    }
    const delay = diffDetail.delayed[delayIndex].value
    const delayPrev =
      delayIndex > 0 ? diffDetail.delayed[delayIndex - 1].value : 0
    const delayDiff = delay - delayPrev
    return {
      delay,
      delayDiff,
      preceding,
    }
  }, [diffDetail, x])
  const {
    scheduleProgressRate,
    scheduleProgressRateDiff,
    progressRate,
    progressRateDiff,
  } = useMemo(() => {
    const scheduleProgressRate = scheduleValue / summary.total
    const scheduleProgressRateDiff = scheduleDiff / summary.total
    const progressRate = actualValue / summary.total
    const progressRateDiff = actualDiff / summary.total
    return {
      scheduleProgressRate,
      progressRate,
      scheduleProgressRateDiff,
      progressRateDiff,
    }
  }, [scheduleValue, scheduleDiff, actualValue, actualDiff, summary.total])

  const {
    openScheduledDetail,
    openScheduledDiffDetail,
    openScheduledRemainingDetail,
    openActualDetail,
    openActualDiffDetail,
    openActualRemainingDetail,
    openDiffScheduledAndActualDetail,
    openDelayedDetail,
    openDelayedDiffDetail,
    openPrecedingDetail,
  } = useValueDetail(condition, teamUuid, xBucket)

  const [formatDiff, formatDiffInverted] = useDiffFormatter(formatValue)
  const [formatRatePercentDiff] = useDiffFormatter(formatRatePercent)
  const [
    formattedScheduleProgressRate,
    formattedScheduleProgressRateDiff,
    formattedProgressRate,
    formattedProgressRateDiff,
    formattedTotal,
    formattedScheduleValue,
    formattedScheduleDiff,
    formattedActualValue,
    formattedActualDiff,
    formattedDelay,
    formattedDelayDiff,
    formattedPreceding,
    formattedActualRemaining,
    formattedActualRemainingDiff,
    formattedScheduleRemaining,
  ] = useMemo(
    () => [
      formatRatePercent(scheduleProgressRate),
      formatRatePercentDiff(scheduleProgressRateDiff),
      formatRatePercent(progressRate),
      formatRatePercentDiff(progressRateDiff),
      formatValue(summary.total),
      formatValue(scheduleValue),
      formatDiff(scheduleDiff),
      formatValue(actualValue),
      formatDiff(actualDiff),
      formatValue(delay),
      formatDiffInverted(delayDiff),
      formatValue(preceding),
      formatValue(actualRemaining),
      formatDiffInverted(-actualDiff),
      formatValue(scheduleRemaining),
    ],
    [
      formatRatePercent,
      scheduleProgressRate,
      formatRatePercentDiff,
      scheduleProgressRateDiff,
      progressRate,
      progressRateDiff,
      formatValue,
      summary.total,
      scheduleValue,
      formatDiff,
      scheduleDiff,
      actualValue,
      actualDiff,
      delay,
      formatDiffInverted,
      delayDiff,
      preceding,
      actualRemaining,
      scheduleRemaining,
    ]
  )
  const { scheduleDetail, actualDetail } = useMemo(() => {
    const scheduleDetail = {
      formattedValue: formattedScheduleRemaining,
      label: intl.formatMessage({
        id: 'progressReport.scheduleRemaining',
      }),
      openDetail: openScheduledDetail,
    }
    const actualDetail = isPassed
      ? {
          formattedValue: formattedPreceding,
          label: intl.formatMessage({
            id: 'progressReport.preceding',
          }),
          openDetail: openPrecedingDetail,
        }
      : undefined
    return {
      scheduleDetail,
      actualDetail,
    }
  }, [
    formattedPreceding,
    formattedScheduleRemaining,
    isPassed,
    openPrecedingDetail,
    openScheduledDetail,
  ])

  return (
    <>
      <MainArea>
        <CardTitle>{xLabel}</CardTitle>
        <CardGroup>
          <CardWithDiff
            label={intl.formatMessage({
              id: 'progressReport.summary.plannedProgressRate',
            })}
            formattedValue={formattedScheduleProgressRate}
            diff={formattedScheduleProgressRateDiff}
            dateBucketType={dateBucketType}
            unitLabel="%"
          />
          <CardWithDiff
            label={intl.formatMessage({
              id: 'projectOverview.progressRate',
            })}
            formattedValue={formattedProgressRate}
            diff={formattedProgressRateDiff}
            dateBucketType={dateBucketType}
            unitLabel="%"
          />
          <CardWithoutDiff
            label={intl.formatMessage({
              id: 'progressReport.summary.total',
            })}
            formattedValue={formattedTotal}
            unitLabel={unitLabel}
          />
          <CardWithDetail
            label={intl.formatMessage({
              id: 'progressReport.tooltip.schedule',
            })}
            formattedValue={formattedScheduleValue}
            diff={formattedScheduleDiff}
            dateBucketType={dateBucketType}
            unitLabel={unitLabel}
            openDetail={openScheduledRemainingDetail}
            openDiffDetail={openScheduledDiffDetail}
            detail={scheduleDetail}
          />
          <CardWithDetail
            label={intl.formatMessage({
              id: 'progressReport.summary.completed',
            })}
            formattedValue={formattedActualValue}
            diff={formattedActualDiff}
            dateBucketType={dateBucketType}
            unitLabel={unitLabel}
            openDetail={openActualDetail}
            openDiffDetail={openActualDiffDetail}
            detail={actualDetail}
          />
          {isPassed && (
            <CardWithDiff
              label={intl.formatMessage({
                id: 'progressReport.summary.delayed',
              })}
              formattedValue={formattedDelay}
              diff={formattedDelayDiff}
              dateBucketType={dateBucketType}
              unitLabel={unitLabel}
              openDetail={openDelayedDetail}
              openDiffDetail={openDelayedDiffDetail}
            />
          )}
          <CardWithDiff
            label={intl.formatMessage({
              id: 'enterpriseProjectReport.remaining.count',
            })}
            formattedValue={formattedActualRemaining}
            diff={formattedActualRemainingDiff}
            dateBucketType={dateBucketType}
            unitLabel={unitLabel}
            openDetail={openActualRemainingDetail}
            openDiffDetail={openActualDiffDetail}
          />
        </CardGroup>
      </MainArea>
    </>
  )
}

type CardWithDiffProps = {
  label: string
  formattedValue: string
  diff: FormattedValueDiff
  dateBucketType: DateBucketType
  unitLabel: string
  openDetail?: () => void
  openDiffDetail?: () => void
}
const CardWithDiff = ({
  label,
  formattedValue,
  diff,
  dateBucketType,
  unitLabel,
  openDetail,
  openDiffDetail,
}: CardWithDiffProps) => {
  return (
    <CardItem>
      <CardMainArea>
        <CardItemTitle>{label}</CardItemTitle>
        <CardItemSummary>
          <ValueWithUnit>
            <CardItemValue isClickable={!!openDetail} onClick={openDetail}>
              {formattedValue}
            </CardItemValue>
            <CardUnitLabel>{unitLabel}</CardUnitLabel>
          </ValueWithUnit>
          <ValueWithUnit>
            <CardItemDiff
              diff={diff}
              dateBucketType={dateBucketType}
              openDetail={openDiffDetail}
            />
          </ValueWithUnit>
        </CardItemSummary>
      </CardMainArea>
    </CardItem>
  )
}

type CardWithoutDiffProps = {
  label: string
  formattedValue: string
  unitLabel: string
}
const CardWithoutDiff = ({
  label,
  formattedValue,
  unitLabel,
}: CardWithoutDiffProps) => {
  return (
    <CardItem>
      <CardMainArea>
        <CardItemTitle>{label}</CardItemTitle>
        <CardItemSummary>
          <ValueWithUnit>
            <CardItemValue>{formattedValue}</CardItemValue>
            <CardUnitLabel>{unitLabel}</CardUnitLabel>
          </ValueWithUnit>
        </CardItemSummary>
      </CardMainArea>
    </CardItem>
  )
}

type CardWithDetailProps = {
  label: string
  formattedValue: string
  diff: FormattedValueDiff
  dateBucketType: DateBucketType
  unitLabel: string
  openDetail?: () => void
  openDiffDetail?: () => void
  detail?: {
    formattedValue: string
    label: string
    openDetail?: () => void
  }
}
const CardWithDetail = ({
  label,
  formattedValue,
  diff,
  dateBucketType,
  unitLabel,
  openDetail,
  openDiffDetail,
  detail,
}: CardWithDetailProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const toggle = useCallback(() => setOpen(prev => !prev), [])
  const onClose = useCallback(() => setOpen(false), [])
  const ref = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <CardItem>
        <CardMainArea>
          <CardItemTitle>
            {label}
            {detail && (
              <PopperIconButton onClick={toggle} ref={ref}>
                <ArrowIcon active={open} />
              </PopperIconButton>
            )}
          </CardItemTitle>
          <CardItemSummary>
            <ValueWithUnit>
              <CardItemValue isClickable={!!openDetail} onClick={openDetail}>
                {formattedValue}
              </CardItemValue>
              <CardUnitLabel>{unitLabel}</CardUnitLabel>
            </ValueWithUnit>
            <CardItemDiff
              diff={diff}
              dateBucketType={dateBucketType}
              openDetail={openDiffDetail}
            />
          </CardItemSummary>
        </CardMainArea>
      </CardItem>
      {detail && (
        <Popper open={open} anchorEl={ref.current} placement="bottom-start">
          <ClickAwayListener onClickAway={onClose}>
            <CardDetailItem>
              <CardDetail>
                <CardDetailHeadder>
                  {intl.formatMessage({
                    id: 'progressReport.detail',
                  })}
                  <PopperIconButton onClick={onClose}>
                    <CloseIcon />
                  </PopperIconButton>
                </CardDetailHeadder>
              </CardDetail>
              <CardDetailValueArea>
                <CardDetailArea>
                  <CardDetailTitle>{detail.label}</CardDetailTitle>
                  <ValueWithUnit>
                    <CardDetailItemValue
                      isClickable={!!detail.openDetail}
                      onClick={detail.openDetail}
                    >
                      {detail?.formattedValue}
                    </CardDetailItemValue>
                    <CardDetailUnitLabel> {unitLabel}</CardDetailUnitLabel>
                  </ValueWithUnit>
                </CardDetailArea>
              </CardDetailValueArea>
            </CardDetailItem>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  )
}

const MainArea = styled('div')({
  display: 'flex',
  width: '100%',
  gap: '4px',
  flexDirection: 'column',
})
const CardTitle = styled('div')({
  color: colorPalette.monotone[10],
  fontSize: '15px',
  fontWeight: 700,
  flexDirection: 'column',
  whiteSpace: 'nowrap',
})
const CardGroup = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '18px',
})
const CardItem = styled('div')({
  flexDirection: 'column',
  backgroundColor: colorPalette.monotone[0],
  height: '75px',
  padding: '16px',
  gap: ' 12px',
  borderRadius: '4px',
  border: '1px solid #D8DDE5',
})
const CardMainArea = styled('div')({
  height: '43px',
  gap: '6px',
})
const CardItemTitle = styled('div')({
  display: 'flex',
  color: colorPalette.monotone[10],
  fontSize: '13px',
  fontWeight: 400,
  alignItems: 'center',
  gap: '4px',
})
const CardItemSummary = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})
const ValueWithUnit = styled('div')({
  display: 'flex',
  gap: '4px',
  alignItems: 'baseline',
})
const CardItemValue = styled('div')(
  ({ isClickable = false }: { isClickable?: boolean }) => ({
    color: colorPalette.monotone[10],
    fontSize: '20px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    cursor: isClickable ? 'pointer' : 'auto',
    textDecoration: isClickable ? 'underline' : 'none',
  })
)
const CardUnitLabel = styled('div')({
  color: colorPalette.monotone[4],
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '100%',
})
const CardDetailItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  borderRadius: '4px',
  boxShadow: '0px 4px 16px 0px rgba(123, 140, 170, 0.50)',
  width: '200px',
})
const CardDetail = styled('div')({
  display: 'flex',
  borderRadius: '4px 4px 0px 0px',
  backgroundColor: colorPalette.monotone[1],
  width: '200px',
})
const CardDetailValueArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '200px',
  backgroundColor: colorPalette.monotone[0],
})
const CardDetailHeadder = styled('div')({
  display: 'flex',
  color: colorPalette.monotone[6],
  fontSize: '13px',
  fontWeight: '700',
  padding: '4px 12px',
  borderRadius: '4px 4px 0px 0px',
  backgroundColor: colorPalette.monotone[1],
  width: '200px',
  justifyContent: 'space-between',
})
const CardDetailArea = styled('div')({
  display: 'flex',
  gap: '8px',
  padding: '12px 16px',
  width: '200px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
})
const CardDetailTitle = styled('div')({
  display: 'flex',
  color: colorPalette.monotone[4],
  fontSize: '13px',
  fontWeight: '400',
  whiteSpace: 'nowrap',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
})
const CloseIcon = styled(CloseRoundedIcon)({
  display: 'flex',
  padding: '4px 2px',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  alignSelf: 'stretch',
})
const CardDetailItemValue = styled('div')(
  ({ isClickable = false }: { isClickable?: boolean }) => ({
    whiteSpace: 'nowrap',
    color: colorPalette.monotone[10],
    fontSize: '18px',
    fontWeight: 400,
    cursor: isClickable ? 'pointer' : 'auto',
    textDecoration: isClickable ? 'underline' : 'none',
  })
)
const CardDetailUnitLabel = styled('div')({
  display: 'flex',
  color: colorPalette.monotone[4],
  fontSize: '10px',
  fontWeight: 400,
})
const PopperIconButton = styled(IconButton)({
  padding: '0',
})
const ArrowIcon = styled(KeyboardArrowDownRoundedIcon)(
  ({ active = false }: { active?: boolean }) => ({
    cursor: 'pointer',
    transform: active ? 'rotate(180deg)' : '',
  })
)

const CardItemDiff = styled(
  ({
    dateBucketType,
    diff,
    openDetail,
    ...others
  }: {
    dateBucketType: DateBucketType
    diff: FormattedValueDiff
    openDetail?: () => void
  } & ComponentProps<'div'>) => {
    const diffLabel = useMemo(() => {
      // TODO: implement.
      switch (dateBucketType) {
        case 'DAY':
          return intl.formatMessage({
            id: 'progressReport.tooltip.diff.day',
          })
        case 'WEEK':
          return intl.formatMessage({
            id: 'progressReport.tooltip.diff.week',
          })
        case 'MONTH':
          return intl.formatMessage({
            id: 'progressReport.tooltip.diff.month',
          })
      }
    }, [dateBucketType])
    return (
      <div {...others}>
        <div>{`${diffLabel}`}</div>
        <LinkWhenClickable
          isClickable={!!openDetail}
          onClick={openDetail}
        >{`${diff.formatted}`}</LinkWhenClickable>
      </div>
    )
  }
)(({ diff: { type } }: { diff: FormattedValueDiff }) => {
  const color = useMemo(() => {
    switch (type) {
      case 'NEUTRAL':
        return colorPalette.monotone[5]
      case 'POSITIVE':
        return colorPalette.green[5]
      case 'NEGATIVE':
        return colorPalette.pink[5]
    }
  }, [type])
  const backgroundColor = useMemo(() => {
    switch (type) {
      case 'NEUTRAL':
        return colorPalette.monotone[1]
      case 'POSITIVE':
        return colorPalette.green[0]
      case 'NEGATIVE':
        return colorPalette.pink[0]
    }
  }, [type])
  return {
    color: color,
    fontSize: '12px',
    fontWeight: 400,
    backgroundColor: backgroundColor,
    height: '24px',
    padding: '4px 6px 4px 6px',
    display: 'flex',
    gap: '4px',
    borderRadius: '4px',
    whiteSpace: 'nowrap',
  }
})

const LinkWhenClickable = styled('div')(
  ({ isClickable = false }: { isClickable?: boolean }) => ({
    cursor: isClickable ? 'pointer' : 'auto',
    textDecoration: isClickable ? 'underline' : 'none',
  })
)
