import { styled } from '@mui/material'
import {
  KpiContainer,
  KpiTitle,
  KpiValue,
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../../components'
import { ProjectDetail } from '../../../../../lib/functions/project'
import { useGetProductivity } from '../../../../../applications/usecases/productivity/getProductivity'
import { useEffect, useMemo, useState } from 'react'
import { EvmVO } from '../../../../../domain/value-object/EvmVO'
import { intl } from '../../../../../i18n'
import { useWorkloadUnit } from '../../../../hooks/useWorkloadUnit'
import { ProjectReportConfig } from '../../model/config'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { AggregatedWorkload } from '../../../../../domain/value-object/AggregatedValueVO'

type EvmValuePanelProps = {
  project: ProjectDetail
  rootProjectPlanUuid?: string
  teamUuid?: string
  selectedCpi: number | undefined
} & Pick<ProjectReportConfig, 'aggregateTarget' | 'workloadUnit'>

const EvmValuePanelRoot = styled(WidgetArea)({
  width: '660px',
  height: '100%',
})

const EvmValueContentRoot = styled('div')({
  height: '60px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
})

export const EvmValuePanel = ({
  project,
  rootProjectPlanUuid,
  teamUuid,
  selectedCpi,
  aggregateTarget,
  workloadUnit,
}: EvmValuePanelProps) => {
  const { convertWorkloadFromHourToSelectedUnit } =
    useWorkloadUnit(workloadUnit)
  const { getEvmValue } = useGetProductivity()
  const [evmValue, setEvmValue] = useState<EvmVO>({
    bac: {
      deliverable: 0,
      task: 0,
    },
    pv: {
      deliverable: 0,
      task: 0,
    },
    ev: {
      deliverable: 0,
      task: 0,
    },
    ac: 0,
  })
  useEffect(() => {
    const fn = async () => {
      const evm = await getEvmValue(project.uuid, rootProjectPlanUuid, teamUuid)
      setEvmValue(evm)
    }
    fn()
  }, [project.uuid, rootProjectPlanUuid, teamUuid])
  const { bac, pv, ev, ac } = useMemo(() => {
    const getValue = (v: AggregatedWorkload) => {
      switch (aggregateTarget) {
        case WbsItemType.DELIVERABLE:
          return v.deliverable
        case WbsItemType.TASK:
          return v.task
      }
    }
    return {
      bac: convertWorkloadFromHourToSelectedUnit(getValue(evmValue.bac)),
      pv: convertWorkloadFromHourToSelectedUnit(getValue(evmValue.pv)),
      ev: convertWorkloadFromHourToSelectedUnit(getValue(evmValue.ev)),
      ac: convertWorkloadFromHourToSelectedUnit(evmValue.ac),
    }
  }, [evmValue, aggregateTarget, convertWorkloadFromHourToSelectedUnit])
  const cpi = useMemo(() => {
    if (typeof selectedCpi === 'number') {
      return selectedCpi
    }
    if (!ac) return Number.POSITIVE_INFINITY
    return ev / ac
  }, [ev, ac, selectedCpi])
  const etc = useMemo(() => {
    if (cpi === 0) {
      return Number.POSITIVE_INFINITY
    }
    if (Number.isFinite(cpi)) {
      return (bac - ev) / cpi
    }
    // if cpi is infinite, return remaining workload.
    return bac - ev
  }, [bac, cpi, ev])
  const eac = useMemo(() => {
    if (Number.isFinite(etc)) {
      return ac + etc
    }
    return Number.POSITIVE_INFINITY
  }, [etc, ac])
  const formattedBac = useMemo(() => formatValue(bac), [bac])
  const formattedPv = useMemo(() => formatValue(pv), [pv])
  const formattedEv = useMemo(() => formatValue(ev), [ev])
  const formattedAc = useMemo(() => formatValue(ac), [ac])
  const formattedCpi = useMemo(() => formatPercentage(cpi), [cpi])
  const formattedEtc = useMemo(() => formatValue(etc), [etc])
  const formattedEac = useMemo(() => formatValue(eac), [eac])

  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({ id: 'resourceDashboard.productivity.evmValue' })}
      </WidgetTitle>
      <EvmValuePanelRoot>
        <EvmValueContentRoot>
          <KpiContainer>
            <KpiTitle>BAC</KpiTitle>
            <KpiValue>{formattedBac}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>PV</KpiTitle>
            <KpiValue>{formattedPv}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>EV</KpiTitle>
            <KpiValue>{formattedEv}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>AC</KpiTitle>
            <KpiValue>{formattedAc}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>CPI</KpiTitle>
            <KpiValue>{formattedCpi}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>ETC</KpiTitle>
            <KpiValue>{formattedEtc}</KpiValue>
          </KpiContainer>
          <KpiContainer>
            <KpiTitle>EAC</KpiTitle>
            <KpiValue>{formattedEac}</KpiValue>
          </KpiContainer>
        </EvmValueContentRoot>
      </EvmValuePanelRoot>
    </WidgetWithTitleWrapper>
  )
}

const formatPercentage = (v: number) => {
  if (Number.isFinite(v)) {
    return `${(v * 100).toFixed(1)}%`
  }
  return '-'
}
const formatValue = (v: number) => {
  if (Number.isFinite(v)) {
    return v.toFixed(1)
  }
  return '-'
}
