import _ from 'lodash'
import { Tree } from '../../../../lib/commons/tree'
import { BulkSheetState } from '../index'
import RowDataManager, { RowData } from './rowDataManager'
import objects from '../../../../utils/objects'
import {
  RowNode,
  RowGroupOpenedEvent,
  IServerSideGetRowsParams,
} from 'ag-grid-community'
import { getTree } from '../../../../lib/functions/projectPlan'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'

class ServerSideData<R extends RowData> {
  // The data is not sorted, but only the data in the top level is sorted.
  // Each data has sorted children.
  private data: R[]
  private initialData: R[]
  private rootUuid: string

  constructor() {
    this.data = []
    this.rootUuid = ''
  }

  setRootUuid = (uuid: string) => (this.rootUuid = uuid)

  // Get
  getAll = () => {
    return this.data.concat()
  }

  get = (uuid: string): R | undefined => {
    return this.data.find(v => v.uuid === uuid)
  }

  getParent = (uuid: string): R | undefined => {
    const row = this.get(uuid)
    return row && row.parentUuid ? this.get(row.parentUuid) : undefined
  }

  getChildren = (uuid: string): R[] => {
    const parent = this.get(uuid)
    return parent?.children || []
  }

  getTopLevelRows = (): R[] => {
    return this.data.filter(
      v => !v.parentUuid || v.parentUuid === this.rootUuid
    )
  }

  getPreviousSibling = (uuid: string): R | undefined => {
    return this.getSibling(uuid, true)
  }

  private getSibling = (uuid: string, prev: boolean = true) => {
    const parent = this.getParent(uuid)
    if (parent && parent.children) {
      const siblings = parent.children
      const index = siblings.findIndex(v => v.uuid === uuid)
      return siblings[index + (prev ? -1 : 1)]
    }
    const topLevelRows = this.getTopLevelRows()
    const index = topLevelRows.findIndex(v => v.uuid === uuid)
    return topLevelRows[index + (prev ? -1 : 1)]
  }

  getSiblings = (uuid: string): R[] => {
    const parent = this.getParent(uuid)
    if (parent) {
      return parent.children || []
    }
    return this.getTopLevelRows()
  }

  // Add / Replace / Remove
  add = (rows: R[]): void => {
    rows.forEach(row => {
      this.data.push(row)
      if (row.parentUuid && row.parentUuid !== this.rootUuid) {
        // Add to children
        const parent = this.get(row.parentUuid)
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(row)
          parent.numberOfChildren = parent.children.length
        }
      }
    })
  }

  addBefore = (row: R, uuid: string): void => {
    const index = this.data.findIndex(v => v.uuid === uuid)
    this.data.splice(index, 0, row)
    if (row.parentUuid && row.parentUuid !== this.rootUuid) {
      const parent = this.get(row.parentUuid)
      if (parent && parent.children) {
        const index = parent.children.findIndex(v => v.uuid === uuid)
        parent.children.splice(index, 0, row)
        parent.numberOfChildren = parent.children.length
      }
    }
  }

  replace = (rows: R[]): void => {
    rows.forEach(row => {
      const index = this.data.findIndex(v => v.uuid === row.uuid)
      this.data.splice(index, 1, row)
      if (row.parentUuid && row.parentUuid !== this.rootUuid) {
        const parent = this.get(row.parentUuid)
        if (parent && parent.children) {
          const index = parent.children.findIndex(v => v.uuid === row.uuid)
          index >= 0 && parent.children.splice(index, 1, row)
        }
      }
    })
  }

  remove = (rows: R[]): void => {
    rows.forEach(row => {
      const index = this.data.findIndex(v => v.uuid === row.uuid)
      this.data.splice(index, 1)
      if (row.parentUuid && row.parentUuid !== this.rootUuid) {
        const parent = this.get(row.parentUuid)
        if (parent && parent.children) {
          const index = parent.children.findIndex(v => v.uuid === row.uuid)
          parent.children.splice(index, 1)
          parent.numberOfChildren = parent.children.length
        }
      }
    })
  }

  removeAll = (): void => {
    this.data = []
  }

  move = (rows: R[], toUuid: string): void => {
    if (rows.length === 0) return
    if (!rows[0].parentUuid || rows[0].parentUuid === this.rootUuid) {
      // Move top level rows
      const moveDown =
        this.data.findIndex(v => v.uuid === rows[0].uuid) <
        this.data.findIndex(v => v.uuid === toUuid)
      this.remove(rows)
      let index =
        this.data.findIndex(v => v.uuid === toUuid) + (moveDown ? 1 : 0)
      rows.forEach(row => {
        this.data.splice(index, 0, row)
        index++
      })
      return
    }
    const parent = this.getParent(rows[0].uuid)
    if (!parent) return
    const siblings = this.getSiblings(rows[0].uuid).concat()
    const moveDown =
      siblings.findIndex(v => v.uuid === rows[0].uuid) <
      siblings.findIndex(v => v.uuid === toUuid)
    // Remove rows from children
    rows.forEach(row => {
      const index = siblings.findIndex(v => v.uuid === row.uuid)
      siblings.splice(index, 1)
    })
    // Add rows to children
    let i = siblings.findIndex(v => v.uuid === toUuid)
    rows.forEach(row => {
      siblings.splice(i + (moveDown ? 1 : 0), 0, row)
      i++
    })
    parent.children = siblings
    parent.numberOfChildren = siblings.length
    this.replace([...rows, parent])
  }
}

export default class ServerSideRowDataManager<
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
> extends RowDataManager<T, R, S> {
  private data: ServerSideData<R>
  private oldData: R[]
  private removedRows: R[]
  private rowsToAddAfterFetching: R[]
  private uuidToFocus: string

  constructor(readonly ctx) {
    super(ctx)
    this.data = new ServerSideData<R>()
    this.initialData = {}
    this.oldData = []
    this.removedRows = []
    this.rowsToAddAfterFetching = []
    this.uuidToFocus = ''
  }

  public init = (trees: T[]) => {
    this.data.removeAll()
    this.ctx.treeRoot.uuid && this.data.setRootUuid(this.ctx.treeRoot.uuid)
    if (trees.length === 0 && this.ctx.options.addable) {
      // Add first row data
      const initialData = this.ctx.rowDataSpec.createNewRow(this.ctx)
      initialData.isAdded = true
      this.data.add([initialData])
      return
    }

    trees.forEach(child => {
      this.recursiveAddRowData(child)
    })
  }

  private recursiveAddRowData(tree: T): void {
    let rowData = this.createRowByResponse(tree)
    this.data.add([rowData])
    this.initialData[rowData.uuid] = _.cloneDeep(rowData)

    tree.children = tree.children || []
    tree.children.forEach(child => {
      if (!this.ctx.rowDataSpec.addChild(rowData, child)) {
        return
      }
      this.recursiveAddRowData(child)
    })
  }

  public createRowByResponse = (tree: T): R => {
    let rowData = super.createRowByResponse(tree, this.ctx.viewMeta)
    rowData.isAdded = false
    rowData.isEdited = false
    rowData.children = []
    return rowData
  }

  getAllRows = () => {
    return this.data.getAll()
  }

  getPreviousSibling = (uuid: string): R | undefined => {
    const siblings = this.getSiblings(uuid)
    if (siblings && siblings.length > 1) {
      const index = siblings.findIndex(v => v.uuid === uuid)
      return siblings[index - 1]
    }
    return undefined
  }

  getSiblings = (uuid: string): R[] => {
    return this.data.getSiblings(uuid)
  }

  getChildren = (uuid: string): R[] => {
    return this.data.getChildren(uuid)
  }

  countChildren = (uuid: string): number => {
    const rows = this.getFlatDescendants(uuid)
    // rows include target row
    return rows.length
  }

  getAncestors = (uuid: string): R[] => {
    let ancestors: R[] = []
    let parent: R | undefined = this.data.getParent(uuid)
    while (parent) {
      ancestors.push(parent)
      parent = this.data.getParent(parent.uuid)
    }
    return ancestors
  }

  getParent = (uuid: string): R | undefined => {
    return this.data.getParent(uuid)
  }

  getGrandParent = (uuid: string): R | undefined => {
    const parent = this.data.getParent(uuid)
    if (!parent) {
      return
    }
    return this.data.getParent(parent.uuid)
  }

  getFlatDescendants = (uuid: string): R[] => {
    let rows: R[] = []
    const target: R | undefined = this.data.get(uuid)
    if (!target) {
      return rows
    }
    rows.push(target)
    const children = target.children
    if (children && children.length > 0) {
      children.forEach(child => {
        rows = [...rows, ...this.getFlatDescendants(child.uuid)]
      })
    }
    return rows
  }

  getDataForBatchUpdate = () => {
    this.ctx.gridApi!.clearFocusedCell()
    const added: R[] = []
    const edited: {
      before: R
      after: R
    }[] = []
    this.ctx.gridApi!.forEachNode((node: RowNode) => {
      const row = this.data.get(node.data.uuid)
      if (!row || !row.isEdited) {
        // Prevent to save rows that is added but not edited
        return
      }
      const rowNode = this.ctx.gridApi!.getRowNode(row.uuid)
      // Clean up rows
      ;(this.ctx.columnApi!.getColumns() || []).forEach(column => {
        const editable = column.getColDef().editable
        const type = column.getColDef().type
        const aggregateColumn =
          type &&
          (type === 'aggregateColumn' || type.includes('aggregateColumn'))
        if (
          aggregateColumn &&
          // @ts-ignore
          ((typeof editable === 'function' && !editable(rowNode)) ||
            (typeof editable === 'boolean' && !editable))
        ) {
          objects.setValue(row, column.getColDef().field!, undefined)
        }
      })
      const previousSibling = this.data.getPreviousSibling(row.uuid)
      row.prevSiblingUuid = previousSibling?.uuid
      if (row.isAdded) {
        added.push(row)
      } else {
        edited.push({
          before: this.initialData[row.uuid],
          after: { ...row },
        })
      }
    })

    return {
      added: added,
      edited: edited,
      deleted: this.getRemovedRows(),
    }
  }

  getSingleRowDataForBatchUpdate = (uuid: string) => {
    this.ctx.gridApi!.clearFocusedCell()
    const added: R[] = []
    const edited: {
      before: R
      after: R
    }[] = []
    const row = this.data.get(uuid)
    if (row && row.isEdited) {
      const rowNode = this.ctx.gridApi!.getRowNode(row.uuid)
      // Clean up rows
      ;(this.ctx.columnApi!.getColumns() || []).forEach(column => {
        const editable = column.getColDef().editable
        const type = column.getColDef().type
        const aggregateColumn =
          type &&
          (type === 'aggregateColumn' || type.includes('aggregateColumn'))
        if (
          aggregateColumn &&
          // @ts-ignore
          ((typeof editable === 'function' && !editable(rowNode)) ||
            (typeof editable === 'boolean' && !editable))
        ) {
          objects.setValue(row, column.getColDef().field!, undefined)
        }
      })
      const previousSibling = this.data.getPreviousSibling(row.uuid)
      row.prevSiblingUuid = previousSibling?.uuid
      if (row.isAdded) {
        added.push(row)
      } else {
        edited.push({
          before: this.initialData[row.uuid],
          after: { ...row },
        })
      }
    } else if (row && row.isAdded) {
      // added row but not edited
      added.push(row)
    }

    return {
      added: added,
      edited: edited,
      deleted: this.getRemovedRows(),
    }
  }

  getRemovedRows = () => {
    return this.removedRows.concat()
  }

  refreshRowNumber = (indexFrom: number, indexTo: number) => {
    const min = indexFrom < 0 ? 0 : indexFrom
    const max = indexTo < 0 ? this.data.getAll().length : indexTo
    this.ctx.gridApi!.forEachNode((rowNode: RowNode, index: number) => {
      if (min <= index && index <= max) {
        const rowData = rowNode.data
        if (!rowData) {
          return
        }
        rowData.rowNumber = index + 1
        rowNode.setData(rowData)
      }
    })
  }

  private refreshNode = (row: R) => {
    const node = this.ctx.gridApi!.getRowNode(row.uuid)
    node && node.setData({ ...node.data, ...row })
  }

  refresh = (tree: T[], parentId?: string): string[] | undefined => {
    this.removedRows = []

    if (!parentId) {
      this.oldData = this.data.getAll()
      this.init(tree)
      const rootNode: RowNode[] = []
      this.ctx.gridApi!.forEachNode(node => {
        if (node.level === 0) {
          rootNode.push(node)
        }
      })
      const oldRows: R[] = rootNode.map(v => v.data)
      const rows = this.data.getTopLevelRows()
      // Purge all if tree structure is changed
      if (this.isRowsChanged(rows, oldRows)) {
        return []
      }
      // Update node when row is updated
      for (let i = 0; i < rows.length; i++) {
        this.refreshNode(rows[i])
      }
      return undefined
    }

    const oldParent = this.oldData.find(v => v.uuid === parentId)
    const oldRows: R[] = oldParent?.children || []
    if (!oldRows) {
      return this.getRoute(parentId)
    }
    const rows = this.data.getChildren(parentId)
    if (rows.length === 0) {
      this.add(tree, parentId)
    }
    // Purge all if tree structure is changed
    if (this.isRowsChanged(rows, oldRows)) {
      return this.getRoute(parentId)
    }
    // Update node
    for (let i = 0; i < rows.length; i++) {
      this.refreshNode(rows[i])
    }
    return undefined
  }

  private isRowsChanged = (data: R[], oldData: R[]) => {
    if (data.length !== oldData.length) {
      return true
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].uuid !== oldData[i].uuid) {
        return true
      }
      const node = this.ctx.gridApi!.getRowNode(oldData[i].uuid)
      if (node && node.group && !data[i].numberOfChildren) {
        return true
      }
      if (node && !node.group && data[i].numberOfChildren) {
        node.id && this.expandedGroupIds.delete(node.id)
        return true
      }
    }
    return false
  }

  addRowsTo = (
    rows: R[],
    params: { parentUuid?: string; prevSiblingUuid?: string }
  ): void => {
    const { parentUuid, prevSiblingUuid } = params
    rows.forEach(row => {
      row.isAdded = true
      row.parentUuid = parentUuid || this.ctx.treeRoot.uuid
    })
    const parent = parentUuid ? this.data.get(parentUuid) : undefined
    if (!parent) {
      this.data.add(rows)
      this.ctx.gridApi!.refreshServerSideStore({})
      this.focus(rows[0].uuid)
      return
    }
    const parentNode = this.ctx.gridApi!.getRowNode(parent.uuid)
    if (!parentNode) return

    rows.forEach(row => this.data.addBefore(row, prevSiblingUuid!))
    const route = this.getRoute(
      parent.numberOfChildren === rows.length
        ? parentNode.parent?.id
        : parentNode.id
    )
    this.ctx.gridApi!.clearFocusedCell()
    this.ctx.gridApi!.clearRangeSelection()
    this.ctx.gridApi!.refreshServerSideStore({
      route,
      purge: !parentNode.group,
    })
    this.expandedGroupIds.add(parent.uuid)
    setTimeout(() => this.focus(rows[0].uuid), 300)
  }

  addRows = (
    rows: R | R[],
    addIndex: number,
    parentId?: string,
    // Control refresh when added to multiple parents
    skipRefreshing?: boolean
  ) => {
    const added = Array.isArray(rows) ? rows : [rows]
    added.forEach(row => {
      row.isAdded = true
      row.parentUuid = parentId || this.ctx.treeRoot.uuid
    })
    const parent = parentId ? this.data.get(parentId) : undefined
    if (!parent) {
      this.data.add(added)
      this.ctx.gridApi!.refreshServerSideStore({})
      this.focus(added[0].uuid)
      return
    }
    const parentNode = this.ctx.gridApi!.getRowNode(parent.uuid)
    if (!parentNode) return
    if (
      !!parentNode.group &&
      !parentNode.expanded && // undefined = The parent node has not fetched the children from server yet.
      (!parent || !parent.children || parent.children.length === 0)
    ) {
      this.uuidToFocus = added[0].uuid
      added.forEach(row => this.rowsToAddAfterFetching.push(row))
      parentNode.setExpanded(true)
      return
    }

    this.data.add(added)
    const route = this.getRoute(
      parent.numberOfChildren === added.length
        ? parentNode.parent?.id
        : parentNode.id
    )
    // Clear focus before purge rows
    this.ctx.gridApi!.clearFocusedCell()
    this.ctx.gridApi!.clearRangeSelection()
    // It requires re-expanding the children since this collapses the children of the route.
    if (!skipRefreshing) {
      this.ctx.gridApi!.refreshServerSideStore({
        route,
        purge: !parentNode.group,
      })
    }
    this.expandedGroupIds.add(parent.uuid)
    setTimeout(() => this.focus(added[0].uuid), 300)
  }

  restoreRowExpandedState = (data: R[]) => {
    data.forEach(row => {
      const node = this.ctx.gridApi!.getRowNode(row.uuid)
      if (
        node &&
        node.id &&
        this.expandedGroupIds.has(node.id) &&
        node.group &&
        !node.expanded
      ) {
        node.setExpanded(true)
      }
    })
  }

  private getRoute = (uuid?: string) => {
    const route: string[] = []
    if (!uuid) return route
    let row = this.data.get(uuid)
    while (row) {
      route.push(row.uuid)
      row = this.data.getParent(row.uuid)
    }
    return route.reverse()
  }

  moveRows = (rows: R[], toUuid?: string): void => {
    if (!toUuid) return
    rows.forEach(row => (row.isEdited = true))
    this.data.move(rows, toUuid)
    const route = this.getRoute(rows[0].parentUuid)
    this.ctx.gridApi!.refreshServerSideStore({ route })
  }

  moveToChild = (rows: R[], parentUuid: string) => {
    // Remove from old position
    this.data.remove(rows)

    const oldRouteStrs: string[] = []
    let refreshRoot: boolean = false
    for (const row of rows) {
      const parent = row.parentUuid ? this.data.get(row.parentUuid) : undefined
      if (!parent) {
        refreshRoot = true
        continue
      }
      const hasChildren = parent.children && 0 < parent.children.length
      if (!refreshRoot) {
        const joinedRouteStr = this.getRoute(
          hasChildren ? parent.uuid : parent.parentUuid
        ).join()
        if (!oldRouteStrs.some(r => joinedRouteStr.startsWith(r))) {
          oldRouteStrs.push(joinedRouteStr)
        }
      }
      if (!hasChildren) {
        this.expandedGroupIds.delete(parent.uuid)
      }
    }

    // Add to new position
    const newParent = this.data.get(parentUuid)
    const newParentNode = this.ctx.gridApi!.getRowNode(parentUuid)
    const siblingsNotFetched =
      !!newParentNode &&
      !!newParentNode.group &&
      !newParentNode.expanded &&
      !(newParent && newParent.children && newParent.children.length > 0)
    rows.forEach(row => {
      row.parentUuid = parentUuid
      row.isEdited = true
    })
    if (siblingsNotFetched) {
      rows.forEach(row => this.rowsToAddAfterFetching.push(row))
    } else {
      this.data.add(rows)
    }
    let newRoute: string[]
    if (!newParent || !newParentNode) {
      newRoute = []
    } else if (!newParentNode.group) {
      newRoute = this.getRoute(newParent.parentUuid)
    } else {
      newRoute = this.getRoute(parentUuid)
    }
    newParentNode &&
      newParentNode.id &&
      this.expandedGroupIds.add(newParentNode.id)

    // Refresh
    this.uuidToFocus = rows[0].uuid
    this.ctx.gridApi!.clearFocusedCell()
    this.ctx.gridApi!.clearRangeSelection()

    let refreshNewRoute: boolean = false
    if (refreshRoot) {
      this.ctx.gridApi!.refreshServerSideStore({
        route: null,
        purge: true,
      })
    } else {
      const newRouteStr = newRoute.join()
      oldRouteStrs.forEach(oldRouteStr => {
        if (
          !oldRouteStr.startsWith(newRouteStr) ||
          oldRouteStr === newRouteStr
        ) {
          this.ctx.gridApi!.refreshServerSideStore({
            route: oldRouteStr.split(','),
            purge: true,
          })
        }
        refreshNewRoute =
          refreshNewRoute || !newRouteStr.startsWith(oldRouteStr)
      })
    }

    if (refreshNewRoute) {
      if (siblingsNotFetched) {
        newParentNode.setExpanded(true)
      } else {
        this.ctx.gridApi!.refreshServerSideStore({
          route: newRoute,
          purge: true,
        })
      }
    }
    store.dispatch(requireSave())
  }

  updateRow = (row: R) => {
    const original = this.data.get(row.uuid)
    if (!original) {
      return
    }
    this.refreshRows([original], [{ ...original, ...row }], true)
  }

  private refreshRows = (rows: R[], newRows: R[], force: boolean = false) => {
    rows.forEach((row: R, index: number) => {
      if (this.ctx.rowDataSpec.hasDiff(row, newRows[index]) || force) {
        const node: RowNode = this.ctx.gridApi!.getRowNode(row.uuid)
        if (node && node.data) {
          newRows[index].rowNumber = node.data.rowNumber
          node.setData(newRows[index])
          this.data.replace([newRows[index]])
        }
      }
    })
  }

  updateRowDirectly = (row: R) => {
    this.data.replace([row])
  }

  removeRows = (rows: R[]) => {
    let routes: string[][] | undefined = []
    rows.forEach(row => {
      this.data.remove([row])
      const parent = row.parentUuid ? this.data.get(row.parentUuid) : undefined
      if (!row.isAdded) {
        this.removedRows.push(row)
      }
      if (!parent) {
        // if root node is updated, need to purge all the cache.
        routes = undefined
        return
      }
      const parentNode = this.ctx.gridApi!.getRowNode(parent.uuid)
      if (routes && parentNode) {
        routes.push(
          this.getRoute(
            (parent.numberOfChildren || 0) <= 0
              ? parentNode.parent?.id
              : parent.uuid
          )
        )
      }
    })
    const merged = this.mergeRoutes(routes)
    this.ctx.gridApi!.clearFocusedCell()
    this.ctx.gridApi!.clearRangeSelection()
    if (!merged || merged.length === 0 || merged.every(v => v.length === 0)) {
      this.ctx.gridApi!.refreshServerSideStore({ purge: true })
    } else {
      merged.forEach(r =>
        this.ctx.gridApi!.refreshServerSideStore({ route: r, purge: true })
      )
    }
  }

  private mergeRoutes = (
    routes: string[][] | undefined
  ): string[][] | undefined => {
    if (!routes) {
      return undefined
    }
    const mergedRoutes: string[][] = []
    const ancestors = routes
      .map(route => route[0])
      .filter((value, index, self) => self.indexOf(value) === index)
    ancestors.forEach(ancestor => {
      const merged: string[] = []
      const successors = routes.filter(route => route.includes(ancestor))
      successors.forEach(route => {
        route.forEach(value => {
          if (
            successors.every(r => r.includes(value)) &&
            !merged.includes(value)
          ) {
            merged.push(value)
          }
        })
      })
      mergedRoutes.push(merged)
    })
    return mergedRoutes
  }

  // cache expanded group ids when groups opened
  onRowGroupOpened = (event: RowGroupOpenedEvent) => {
    const id = event.node.id
    if (!id) return
    if (event.node.expanded) {
      this.expandedGroupIds.add(id)
      this.collapsedGroupIds.delete(id)
    } else {
      this.collapsedGroupIds.add(id)
      this.expandedGroupIds.delete(id)
    }
  }

  add = (trees: T[], parentId: string) => {
    if (trees.length === 0) {
      return
    }
    const parent = this.data.get(parentId)
    if (!parent) {
      return
    }
    trees.forEach(child => {
      this.recursiveAddRowData(child)
    })
    // Move data after parent row is expanded. see moveToChild method
    this.rowsToAddAfterFetching.forEach(row => {
      if (row.parentUuid === parent.uuid) {
        this.data.add([row])
      }
    })
    this.rowsToAddAfterFetching = this.rowsToAddAfterFetching.filter(
      row => row.parentUuid !== parent.uuid
    )
  }

  focus(uuid?: string) {
    const target = uuid || this.uuidToFocus
    if (target) {
      this.ctx.gridApi!.clearFocusedCell()
      this.ctx.gridApi!.clearRangeSelection()
      this.focusRow(target)
      this.uuidToFocus = ''
    }
  }
}

export const ServerSideDataSource = <
  T extends Tree<T>,
  R extends RowData,
  S extends BulkSheetState
>(
  ctx,
  manager: ServerSideRowDataManager<T, R, S>
) => {
  const getInitRowData = async () => {
    if (manager.getAllRows().length > 0) {
      return manager
        .getAllRows()
        .filter(
          v => v.parentUuid === ctx.treeRoot.uuid || v.parentUuid === undefined
        )
    }
    const response = await getTree(
      ctx.state.uuid,
      Object.values(WbsItemType),
      undefined,
      2
    )

    ctx.treeRoot.uuid = response.json.uuid
    ctx.treeRoot.lockVersion = response.json.lockVersion
    ctx.treeRoot.revision = response.json.revision
    ctx.setState(
      {
        lockVersion: response.json.lockVersion,
      },
      () => {
        ctx.options.updateState(response.json, ctx.state)
      }
    )
    manager.init(response.json.children)
    if (manager.getAllRows().length === 1) {
      return manager.getAllRows()
    }
    return manager
      .getAllRows()
      .filter(v => !v.parentUuid || v.parentUuid === ctx.treeRoot.uuid)
  }

  const getChildRowData = async (id: string) => {
    const childRowData = manager.getChildren(id)
    if (childRowData && childRowData.length > 0) {
      return childRowData
    } else {
      const response = await getTree(
        ctx.state.uuid,
        Object.values(WbsItemType),
        id,
        1
      )
      manager.add(response.json.children, id)
      const parent = manager.getAllRows().find(v => v.uuid === id)
      return !!parent ? parent.children : []
    }
  }

  const disableSubmit = () => ctx.setState({ submitDisabled: true })
  const enableSubmit = _.debounce(
    () => ctx.setState({ submitDisabled: false }),
    1000
  )

  return {
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        disableSubmit()
        const rows =
          params.parentNode.level < 0
            ? await getInitRowData()
            : await getChildRowData(params.parentNode.id || '')
        if (!rows) {
          return
        }
        params.success({ rowData: rows })
        const startIndex =
          params.parentNode.data && params.parentNode.data.rowNumber
            ? params.parentNode.data.rowNumber
            : 0
        manager.refreshRowNumber(startIndex, -1)

        if (manager.expandedGroupIds.size === 0) {
          // Default expanded rows
          params.api.forEachNode(node => {
            if (node.group && node.data && node.level === 0) {
              node.setData(node.data)
              node.setExpanded(true)
            }
          })
        } else {
          const data = rows.concat()
          if (data[0]?.parentUuid) {
            const parent = manager
              .getAllRows()
              .find(v => v.uuid === data[0].parentUuid)
            parent && manager.restoreRowExpandedState([parent])
          }
          manager.restoreRowExpandedState(data)
        }
        manager.focus()
      } finally {
        enableSubmit()
      }
    },
  }
}
