import { intl } from '../../../../i18n'
import { BackgroundColor, TextColor } from '../../../../styles/commonStyles'
import BaseButton, { Props as BaseButtonProps } from '../BaseButton'

const ProjectLoginButton = ({ disabled, ...other }: BaseButtonProps) => {
  return (
    <BaseButton
      {...other}
      disabled={disabled}
      sx={{
        '&.MuiButton-root': {
          background: disabled
            ? BackgroundColor.WHITE
            : BackgroundColor.BLUE_GRADATION,
          color: TextColor.WHITE,
        },
        '&.Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.12)',
          color: 'rgba(0, 0, 0, 0.26)',
          boxShadow: 'none',
        },
      }}
    >
      {intl.formatMessage({ id: 'project.loginToProject' })}
    </BaseButton>
  )
}

export default ProjectLoginButton
