import { WbsItemRow } from '../../../../../../lib/functions/wbsItem'
import objects from '../../../../../../utils/objects'
import { RowNode } from 'ag-grid-community'
import { WbsItemTypeVO } from '../../../../../../domain/value-object/WbsItemTypeVO'

export enum AggregateTargetValue {
  ESTIMATED_HOUR,
  ACTUAL_HOUR,
  COUNT,
  STATUS_COUNT,
  WBS_ITEM_CODE,
}

const getTargetFieldName = (
  target: AggregateTargetValue,
  wbsItemFieldName: string
) => {
  switch (target) {
    case AggregateTargetValue.ESTIMATED_HOUR:
      return `${wbsItemFieldName}.estimatedWorkload.hour`
    case AggregateTargetValue.ACTUAL_HOUR:
      return `cumulation.actualHour`
    case AggregateTargetValue.WBS_ITEM_CODE:
      return `${wbsItemFieldName}.code`
    default:
      return ''
  }
}

const getChildTaskNodes = (
  node: RowNode,
  wbsItemTypeFieldName: string,
  includeUnderDeliverable: boolean = true
): RowNode[] => {
  const children: RowNode[] = []
  node.allLeafChildren &&
    node.allLeafChildren.forEach(childNode => {
      const childWbsItemType: WbsItemTypeVO = objects.getValue(
        childNode.data,
        wbsItemTypeFieldName
      )
      if (
        node.id === childNode.id ||
        childNode.parent?.id !== node.id ||
        (!includeUnderDeliverable && childWbsItemType.isDeliverable())
      ) {
        return
      }
      childWbsItemType.isTask() && children.push(childNode)
      const descendants: RowNode[] = getChildTaskNodes(
        childNode,
        wbsItemTypeFieldName,
        includeUnderDeliverable
      )
      descendants.forEach(v => children.push(v))
    })
  return children
}

export const taskAggregator = (
  root: RowNode,
  aggregateTarget: AggregateTargetValue,
  wbsItemFieldName: string = 'wbsItem',
  filterCondition: (node: RowNode) => boolean = node => true,
  denominator: number = 1
) => {
  const wbsItemTypeFieldName = `${wbsItemFieldName}.wbsItemType`
  const targetFieldName = getTargetFieldName(aggregateTarget, wbsItemFieldName)
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    root.data,
    wbsItemTypeFieldName
  )
  let value = 0
  if (
    wbsItemType?.isTask() &&
    aggregateTarget !== AggregateTargetValue.STATUS_COUNT
  ) {
    if (!filterCondition(root)) return 0
    return aggregateTarget === AggregateTargetValue.COUNT
      ? 1
      : (objects.getValue(root.data, targetFieldName) || 0) / denominator
  } else {
    const includeUnderDeliverable =
      wbsItemType?.isWorkgroup() ||
      wbsItemType?.isProcess() ||
      wbsItemType?.isDeliverableList()
    const targetNodes = getChildTaskNodes(
      root,
      wbsItemTypeFieldName,
      includeUnderDeliverable
    )
    targetNodes.forEach(node => {
      if (filterCondition(node)) {
        if (
          aggregateTarget === AggregateTargetValue.COUNT ||
          aggregateTarget === AggregateTargetValue.STATUS_COUNT
        ) {
          value++
        } else {
          value += objects.getValue(node.data, targetFieldName) || 0
        }
      }
    })
  }
  return value / denominator
}

export const deliverableAggregator = (
  root: RowNode,
  aggregateTarget: AggregateTargetValue,
  wbsItemFieldName: string = 'wbsItem',
  filterCondition: (node: RowNode) => boolean = node => true,
  denominator: number = 1
) => {
  const wbsItemTypeFieldName = `${wbsItemFieldName}.wbsItemType`
  const targetFieldName = getTargetFieldName(aggregateTarget, wbsItemFieldName)
  const rootWbsItemType: WbsItemTypeVO = objects.getValue(
    root.data,
    wbsItemTypeFieldName
  )
  if (rootWbsItemType?.isDeliverable()) {
    if (!filterCondition(root)) return 0
    return aggregateTarget === AggregateTargetValue.COUNT
      ? 1
      : (objects.getValue(root.data, targetFieldName) || 0) / denominator
  }
  let aggregatedValue = 0
  root.allLeafChildren &&
    root.allLeafChildren.forEach(childNode => {
      const wbsItemType: WbsItemTypeVO = objects.getValue(
        childNode.data,
        wbsItemTypeFieldName
      )
      if (
        root.id === childNode.id ||
        !wbsItemType?.isDeliverable() ||
        !filterCondition(childNode)
      ) {
        return
      }
      if (
        aggregateTarget === AggregateTargetValue.COUNT ||
        aggregateTarget === AggregateTargetValue.STATUS_COUNT
      ) {
        aggregatedValue++
      } else {
        const value = objects.getValue(childNode.data, targetFieldName)
        aggregatedValue += value || 0
      }
    })
  return aggregatedValue / denominator
}

export const getDescendantDeliverableCodes = (
  root: RowNode,
  filterCondition: (wbsItem: WbsItemRow) => boolean
): string[] => {
  return getDescendantDeliverables(root)
    .filter(filterCondition)
    .map(wbsItem => wbsItem.code!)
}

export const getDescendantTaskCodes = (
  root: RowNode,
  filterCondition: (wbsItem: WbsItemRow) => boolean
): string[] => {
  return getDescendantTasks(root)
    .filter(filterCondition)
    .map(wbsItem => wbsItem.code!)
}

const getDescendantTasks = (
  root: RowNode,
  wbsItemPath: string = 'wbsItem'
): WbsItemRow[] => {
  const wbsItemType: WbsItemTypeVO = objects.getValue(
    root.data,
    `${wbsItemPath}.wbsItemType`
  )
  const descendants: WbsItemRow[] = []
  if (wbsItemType?.isTask()) {
    descendants.push(objects.getValue(root.data, wbsItemPath))
  } else {
    const includeUnderDeliverable =
      wbsItemType?.isWorkgroup() ||
      wbsItemType?.isProcess() ||
      wbsItemType?.isDeliverableList()
    const targetNodes = getChildTaskNodes(
      root,
      'wbsItem.wbsItemType',
      includeUnderDeliverable
    )
    targetNodes.forEach(node => {
      descendants.push(objects.getValue(node.data, wbsItemPath))
    })
  }
  return descendants
}

const getDescendantDeliverables = (
  root: RowNode,
  wbsItemPath: string = 'wbsItem'
): WbsItemRow[] => {
  const rootWbsItemType: WbsItemTypeVO = objects.getValue(
    root.data,
    `${wbsItemPath}.wbsItemType`
  )
  const descendants: WbsItemRow[] = []
  if (rootWbsItemType?.isDeliverable()) {
    descendants.push(objects.getValue(root.data, wbsItemPath))
  }
  root.allLeafChildren &&
    root.allLeafChildren.forEach(node => {
      const wbsItemType: WbsItemTypeVO = objects.getValue(
        node.data,
        'wbsItem.wbsItemType'
      )
      if (root.id === node.id || !wbsItemType?.isDeliverable()) {
        return
      }
      descendants.push(objects.getValue(node.data, wbsItemPath))
    })
  return descendants
}
