export default function (params) {
  const style = {
    justifyContent: 'flex-end',
  }
  if (!params || !params.data || !params.colDef.cellRendererParams) {
    return style
  }
  const link = params.colDef.cellRendererParams.link
  if (
    (typeof link === 'boolean' && link) ||
    (typeof link === 'function' && link(params.data))
  ) {
    return {
      justifyContent: 'flex-end',
      cursor: 'pointer',
      color: 'blue',
    }
  }
  return style
}
