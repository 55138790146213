import { Box, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { FontSize, FontWeight } from '../../../../../styles/commonStyles'
import { Button } from '../../../../components/buttons'
import { useCallback, useState } from 'react'
import { intl } from '../../../../../i18n'
import { WbsItemSearchSearchCondition } from '../../hooks/useSearchCondition'
import WbsSearchSearchConditionDialog from './WbsSearchSearchConditionDialog'
import { WbsItemSearchSearchItemLabels } from './WbsItemSearchSearchHeader/WbsItemSearchSearchItemLabels'
import { PermanentUiState } from '../../../../../services/model/uiState'
import { SearchConditionAndColumnState } from '../../../../../services/model/searchConditionAndColumnState'
import SaveSearchConditionListDialog from './SaveSearchConditionListDialog'

type Props = {
  hit: number
  total: number
  searchCondition: WbsItemSearchSearchCondition
  onChange: (patch: Partial<WbsItemSearchSearchCondition>) => void
  search: () => void
  resetCondition: () => void
  functionUuid: string
  applyStoredState: (storedState: SearchConditionAndColumnState) => void
  onCloseSearchItemLabel: () => void
}

const WbsItemSearchSearchConditionHeader = ({
  hit,
  total,
  searchCondition,
  search,
  onChange,
  resetCondition,
  functionUuid,
  applyStoredState,
  onCloseSearchItemLabel,
}: Props) => {
  const [openSearchConditionPanel, setOpenSearchConditionPanel] =
    useState<boolean>(false)
  const [openSavedSearchConditionPanel, setOpenSavedSearchConditionPanel] =
    useState<boolean>(false)

  const onSearch = useCallback(() => {
    search()
    setOpenSearchConditionPanel(false)
  }, [search])

  const handleSearchConditionPanelClose = () => {
    setOpenSearchConditionPanel(false)
    search()
  }

  const handleSearchConditionPanelOpen = () => {
    setOpenSearchConditionPanel(true)
  }

  const handleSavedSearchConditionPanelClose = () => {
    setOpenSavedSearchConditionPanel(false)
  }

  const handleSavedSearchConditionPanelOpen = () => {
    setOpenSavedSearchConditionPanel(true)
  }

  const onSelectState = useCallback(
    (selected: PermanentUiState<SearchConditionAndColumnState>) => {
      applyStoredState(selected.value)
    },
    [applyStoredState]
  )

  const [filteringInput, setFilteringInput] = useState<string>('')

  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '4px',
        padding: '10px',
        backgroundColor: 'rgba(216, 221, 229, 0.3)',
        margin: '10px 20px 10px 20px',
        flexDirection: 'column',
        height: 'fit-content',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: FontSize.LARGE,
            fontWeight: 700,
            color: colorPalette.monotone[10],
            marginRight: '20px',
            marginLeft: '10px',
          }}
        >
          {intl.formatMessage({
            id: 'wbsItemSearchFilterPanel.filteringItems.label',
          })}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '20px',
            marginLeft: '10px',
          }}
        >
          <Typography
            sx={{
              color: colorPalette.monotone[4],
              fontSize: FontSize.MEDIUM,
              fontWeight: FontWeight.NORMAL,
              marginTop: '1px',
              marginRight: '10px',
            }}
          >
            {intl.formatMessage({
              id: 'wbs.search.header.search.panel.result',
            })}
          </Typography>
          <Typography
            sx={{
              color: colorPalette.monotone[10],
              fontWeight: 700,
              fontSize: FontSize.LARGE,
            }}
          >
            {intl.formatMessage(
              { id: 'wbs.search.header.search.hits' },
              { hit }
            )}
          </Typography>
          <Typography
            sx={{
              color: colorPalette.monotone[4],
              fontSize: FontSize.MEDIUM,
              fontWeight: 500,
              margin: '5px 5px 0 5px',
            }}
          >
            /
          </Typography>
          <Typography
            sx={{
              color: colorPalette.monotone[4],
              fontSize: FontSize.MEDIUM,
              fontWeight: 500,
              margin: '5px 0 0 0',
            }}
          >
            {intl.formatMessage(
              { id: 'wbs.search.header.search.total' },
              { total }
            )}
          </Typography>
        </Box>
        <div>
          <Button
            onClick={handleSearchConditionPanelOpen}
            colorPattern="skyBlue"
            sx={{ border: 'none', margin: '0 5px', cursor: 'pointer' }}
          >
            <Typography sx={{ fontSize: '12px' }}>
              {intl.formatMessage({
                id: 'wbsItemSearchFilterPanel.add.condition',
              })}
            </Typography>
          </Button>
          <WbsSearchSearchConditionDialog
            open={openSearchConditionPanel}
            onClose={handleSearchConditionPanelClose}
            searchCondition={searchCondition}
            onEnter={search}
            onChangeCondition={onChange}
            resetCondition={resetCondition}
            search={onSearch}
            filteringInput={filteringInput}
            setFilteringInput={setFilteringInput}
            onSelectState={onSelectState}
            functionUuid={functionUuid}
          />
        </div>
        <Button
          onClick={handleSavedSearchConditionPanelOpen}
          colorPattern="monotone"
          variant="outlined"
          sx={{ margin: '0 5px', cursor: 'pointer' }}
        >
          <Typography sx={{ fontSize: '12px' }}>
            {intl.formatMessage({
              id: 'wbsItemSearchFilterPanel.saved.condition',
            })}
          </Typography>
        </Button>
        <SaveSearchConditionListDialog
          open={openSavedSearchConditionPanel}
          handleSavedSearchConditionPanelClose={
            handleSavedSearchConditionPanelClose
          }
          filteringInput={filteringInput}
          setFilteringInput={setFilteringInput}
          onSelectState={onSelectState}
          functionUuid={functionUuid}
          searchCondition={searchCondition}
        />
      </Box>
      <WbsItemSearchSearchItemLabels
        searchCondition={searchCondition}
        onChange={onChange}
        search={search}
        onClose={onCloseSearchItemLabel}
      />
    </div>
  )
}

export default WbsItemSearchSearchConditionHeader
