import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import {
  Scale,
  TwoDimensionalPoint,
  TwoDimensionalPoints,
} from '../../../../components/charts/model/chart'
import { DateBucket } from '../../../../components/charts/model/timeSeries'
import { LineGraph, LineNode } from '../../../../components/charts/LineChart'
import { useMemo } from 'react'

export const GraphRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
})

export const GraphManipulationArea = styled('div')({
  display: 'flex',
  height: '62px',
  padding: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const GraphLegendArea = styled('div')({
  display: 'flex',
  gap: '16px',
})

export const GraphLegendGroup = styled('div')({
  display: 'flex',
})
export const GraphLegend = styled('div')({
  display: 'flex',
  gap: '8px',
  padding: '8px',
  alignItems: 'center',
})
export const GraphLegendText = styled('div')({
  fontSize: '13px',
  color: colorPalette.monotone[4],
})
export const GraphLegendColorDiv = styled('div')(
  ({ color }: { color: string }) => ({
    width: '12px',
    height: '4px',
    borderRadius: '4px',
    backgroundColor: color,
  })
)

export const GraphArea = styled('div')({
  width: '100%',
  height: 'calc(100% - 62px)',
})

type BurndownChartLineGraphProps = {
  xScale: Scale<DateBucket>
  yScale: Scale<number>
  points: TwoDimensionalPoints<DateBucket, number>
  node?: TwoDimensionalPoint<DateBucket, number>
  color?: string
}
export const BurndownChartScheduleGraph = ({
  xScale,
  yScale,
  points,
  node,
  color = colorPalette.blue[2],
}: BurndownChartLineGraphProps) => (
  <>
    <LineGraph<DateBucket, number>
      xScale={xScale}
      yScale={yScale}
      points={points}
      color={color}
      width={3}
    />
  </>
)

export const BurndownChartActualGraph = ({
  xScale,
  yScale,
  points,
  node,
  color = colorPalette.blue[7],
}: BurndownChartLineGraphProps) => {
  return (
    <LineGraph<DateBucket, number>
      xScale={xScale}
      yScale={yScale}
      points={points}
      color={color}
      width={3}
    />
  )
}
export const BurndownChartProspectGraph = ({
  xScale,
  yScale,
  points,
  node,
  color = colorPalette.blue[7],
}: BurndownChartLineGraphProps) => {
  return (
    <LineGraph<DateBucket, number>
      xScale={xScale}
      yScale={yScale}
      points={points}
      color={color}
      lineStyle={'DASHED'}
      width={3}
    />
  )
}

export const BurndownChartSimulatedGraph = ({
  xScale,
  yScale,
  points,
  node,
  color = colorPalette.monotone[2],
}: BurndownChartLineGraphProps) => (
  <LineGraph<DateBucket, number>
    xScale={xScale}
    yScale={yScale}
    points={points}
    color={color}
    width={3}
  />
)

export const BurndownChartIdealGraph = ({
  xScale,
  yScale,
  points,
  node,
  color = colorPalette.monotone[4],
}: BurndownChartLineGraphProps) => (
  <LineGraph<DateBucket, number>
    xScale={xScale}
    yScale={yScale}
    points={points}
    color={color}
    width={3}
  />
)
