import { Tree } from '../commons/tree'
import EntitySearch from '../commons/entitySearch'
import { FunctionProperty } from '../commons/appFunction'
import API, { APIResponse } from '../commons/api'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import { UserBasic } from './user'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'

export interface GetBusinessClientsProps {
  displayName?: string
  officialName?: string
  offset?: number
  limit?: number
}
export interface ResponseOfGetTree {
  data: BusinessClientDetail[]
}

export interface BusinessClientBatchDeltaInput {
  added: BusinessClientInput[]
  edited: BusinessClientDeltaInput[]
  deleted: { uuid: string; lockVersion: number }[]
}

export interface BusinessClientBatchResponse {
  added: { uuid: string; lockVersion: number }[]
  edited: { uuid: string; lockVersion: number }[]
  deleted: { uuid: string }[]
}

export interface BusinessClientDetail extends Tree<BusinessClientDetail> {
  code: string
  displayName: string
  officialName: string
}

export interface BusinessClientInput {
  uuid: string
  lockVersion?: number
  revision?: string
  parentUuid?: string
  prevSiblingUuid?: string
  code?: string
  displayName: string
  officialName?: string
}

export interface BusinessClientDeltaInput {
  uuid: string
  parentUuid?: IItemDelta<string>
  prevSiblingUuid?: IItemDelta<string>
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
}

export interface GetBusinessClientsResponse {
  total: number
  hit: number
  data: BusinessClientProps[]
}

export interface BusinessClientProps {
  uuid: string
  displayName: string
  officialName: string
}

export interface BusinessClientCreateProps {
  uuid: string
  lockVersion: number
}

export interface BusinessClientUpdatePorps {
  code: string
  displayName: string
  officialName: string
}

export interface BusinessClientDetailProps {
  uuid: string
  code: string
  prevSiblingId: number
  lockVersion: number
  displayName: string
  officialName: string
  revision: string
  createdAt: string
  updatedAt: string
  createdBy: UserBasic
  updatedBy: UserBasic
}

class BusinessClient extends EntitySearch implements SingleSheetRepository {
  public getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/business_clients/basic', {
      code,
    })
  }

  public create = (props: BusinessClientCreateProps): Promise<APIResponse> => {
    return API.functional.request('POST', '/api/v1/business_clients', props)
  }

  public update = (props: BusinessClientUpdatePorps): Promise<APIResponse> => {
    return API.functional.request('PUT', '/api/v1/business_clients', props)
  }

  getDetail = (props: { uuid: string }, options?: any): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/business_clients/detail',
      {
        businessClientUuid: props.uuid,
      },
      true
    )
  }

  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        name: query,
      }),
      (division: BusinessClientProps) => ({
        uuid: division.uuid,
        name: division.displayName,
      })
    )
  }

  public searchAll = async () => {
    return this.search('')
  }

  entitySearchApi = (
    props: GetBusinessClientsProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/business_clients/find',
      props,
      true,
      signal
    )
  }

  public getAll = async () => {
    return this.searchRequest.switch(signal =>
      API.functional.request(
        'GET',
        '/api/v1/business_clients/descendant',
        {},
        true,
        signal
      )
    )
  }

  updateBatchDelta(
    request: BusinessClientBatchDeltaInput
  ): Promise<APIResponse> {
    return API.functional.request(
      'POST',
      '/api/v1/business_clients/delta/batch',
      request
    )
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }
}

export default new BusinessClient()
