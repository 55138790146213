import {
  addKeyBindListeners as addKeyBindListenersAction,
  removeKeyBindListeners as removeKeyBindListenersAction,
} from './../../store/keyBind'
import { KeyBindListener } from '../model/keyBind'
import { DependencyList, useCallback, useEffect, useMemo } from 'react'
import store from '../../store'

export const useKeyBind = (
  listeners: KeyBindListener[],
  _deps?: DependencyList
) => {
  const deps = useMemo(() => _deps || [], [_deps])
  const addKeyBindListeners = useCallback((listeners: KeyBindListener[]) => {
    store.dispatch(addKeyBindListenersAction(listeners))
  }, [])
  const removeKeyBindListeners = useCallback(() => {
    store.dispatch(removeKeyBindListenersAction())
  }, [])
  useEffect(() => {
    addKeyBindListeners(listeners)
    return () => removeKeyBindListeners()
  }, deps)
}
