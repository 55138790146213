import { useCallback, useMemo } from 'react'
import { RiskFormModel } from '../../../model/risk'

export const useRiskModelModifier = (
  onChange: <K extends keyof RiskFormModel, T extends RiskFormModel[K]>(
    path: K,
    value: T
  ) => void
) => {
  const onChangeRiskType = useCallback(
    (value: RiskFormModel['riskType']) => {
      onChange('riskType', value)
    },
    [onChange]
  )
  const onChangeProbability = useCallback(
    (value: RiskFormModel['probability']) => {
      onChange('probability', value)
    },
    [onChange]
  )
  const onChangeImpact = useCallback(
    (value: RiskFormModel['impact']) => {
      onChange('impact', value)
    },
    [onChange]
  )
  const onChangeStrategy = useCallback(
    (value: RiskFormModel['strategy']) => {
      onChange('strategy', value)
    },
    [onChange]
  )
  const modifiers = useMemo(
    () => ({
      onChangeRiskType,
      onChangeProbability,
      onChangeImpact,
      onChangeStrategy,
    }),
    [onChangeRiskType, onChangeProbability, onChangeImpact, onChangeStrategy]
  )
  return modifiers
}
