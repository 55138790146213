import {
  Autocomplete,
  Avatar,
  InputAdornment,
  InputBase,
  MenuItem,
  Popper,
  Typography,
  styled,
} from '@mui/material'
import {
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SelectOption } from '../../Kanban'
import { Color } from '../../../../../styles/commonStyles'
import _ from 'lodash'

type Props = {
  options?: SelectOption[]
  value: SelectOption | null | undefined
  id: string
  onChange: (value?: SelectOption) => void
}

export const AutocompleteItem = memo(
  ({ options, value, id, onChange }: Props) => {
    const inputRef = useRef<HTMLSelectElement>(null)
    const [inputValue, setInputValue] = useState<string>('')
    const [isEditing, setIsEditing] = useState(false)

    const blurBySpecialKey = useCallback(
      e => {
        if (e.key === 'Escape' || e.key === 'Enter') {
          isEditing && setIsEditing(false)
          inputValue && setInputValue('')
        }
      },
      [isEditing, inputValue]
    )

    useEffect(() => {
      isEditing && inputRef.current?.focus()
    }, [isEditing])

    return isEditing ? (
      <Autocomplete
        openOnFocus={true}
        size="small"
        id={id}
        value={value}
        renderInput={params => (
          <InputBase
            inputRef={inputRef}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus={true}
            startAdornment={
              <InputAdornment position="start" sx={{ marginRight: '1px' }}>
                {value?.iconUrl && (
                  <Avatar
                    variant="circular"
                    src={value.iconUrl}
                    sx={{ width: 20, height: 20, margin: '1px' }}
                  />
                )}
              </InputAdornment>
            }
            sx={{ maxWidth: '160px', minWidth: '100px' }}
          />
        )}
        // Option
        options={options ?? []}
        noOptionsText=""
        isOptionEqualToValue={(o, v) => v && o.value === v.value}
        getOptionLabel={option => option.name}
        PopperComponent={params => {
          return (
            <Popper
              {...params}
              placement="bottom-start"
              modifiers={[
                { name: 'offset', options: { offset: () => [5, 5] } },
              ]}
              sx={{
                boxShadow: '0px 8px 10px 1px #00000020',
              }}
            />
          )
        }}
        renderOption={(params, option) => {
          return (
            <MenuItem
              {...params}
              key={`$card-option-${option.value}`}
              value={option.value}
              sx={{
                display: 'flex',
                margin: '2px',
                height: '26px',
                borderRadius: '3px',
                '&:focus': {
                  color: Color.MAIN,
                },
                '&:hover': {
                  color: Color.MAIN,
                },
              }}
            >
              {option.iconUrl && (
                <Avatar
                  variant="circular"
                  src={option.iconUrl}
                  sx={{ margin: '0px 5px', width: 20, height: 20 }}
                />
              )}
              <Typography variant={'subtitle2'} sx={{ margin: 'auto 0px' }}>
                {option.name ?? ''}
              </Typography>
            </MenuItem>
          ) as ReactNode
        }}
        // Event
        onChange={(e, option) => option && onChange(option)}
        sx={{ maxWidth: '160px' }}
        inputValue={inputValue}
        onInputChange={(e, v) => {
          setInputValue(v)
          if (v === '') {
            onChange(undefined)
          }
        }}
        onBlur={() => setIsEditing(false)}
        onKeyDown={blurBySpecialKey}
      />
    ) : (
      <RendererWithIcon onClick={() => setIsEditing(true)}>
        {value?.iconUrl && (
          <Avatar
            variant="circular"
            src={value.iconUrl}
            sx={{ width: 20, height: 20, margin: '1px' }}
          />
        )}
        <Typography sx={{ padding: '3px 0 0 0' }}>
          {value?.name ?? ''}
        </Typography>
      </RendererWithIcon>
    )
  }
)

const RendererWithIcon = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px',
  width: '100%',
  padding: '4px 0 5px',
  minWidth: '100px',
})
