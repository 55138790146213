import { useCallback, useState } from 'react'
import { AggregateField } from '../../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../../lib/functions/workload'
import {
  BurndownChartViewConfig,
  UpdateBurndownChartViewConfig,
  ViewType,
} from '../model/viewConfig'
import { DateBucketType } from '../../../components/charts/model/timeSeries'
import { WeekDay } from '../../../../domain/value-object/DayOfWeek'

export const useViewConfig = (): BurndownChartViewConfig &
  UpdateBurndownChartViewConfig => {
  const [aggregateField, setAggregateField] = useState<AggregateField>(
    AggregateField.WBS_ITEM_WORKLOAD
  )
  const [workloadUnit, setWorkloadUnit] = useState<WorkloadUnit>(
    WorkloadUnit.DAY
  )
  const [dateBucketType, setDateBucketType] = useState<DateBucketType>('DAY')
  const [startDayOfWeek, setStartDayOfWeek] = useState<WeekDay>('MONDAY')
  const [viewType, setViewType] = useState<ViewType>('GRAPH')
  const updateAggregateField = useCallback((v: AggregateField) => {
    setAggregateField(v)
  }, [])
  const updateWorkloadUnit = useCallback((v: WorkloadUnit) => {
    setWorkloadUnit(v)
  }, [])
  const updateDateBucketType = useCallback((v: DateBucketType) => {
    setDateBucketType(v)
  }, [])
  const updateStartDayOfWeek = useCallback((v: WeekDay) => {
    setStartDayOfWeek(v)
  }, [])
  const updateViewType = useCallback((v: ViewType) => {
    setViewType(v)
  }, [])
  return {
    aggregateField,
    workloadUnit,
    dateBucketType,
    startDayOfWeek,
    viewType,
    updateAggregateField,
    updateWorkloadUnit,
    updateDateBucketType,
    updateStartDayOfWeek,
    updateViewType,
  }
}
