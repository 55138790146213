import { styled } from '@mui/material'
import {
  ReferencedEntity,
  ReferencedEntityWithIcon,
} from '../../../../../../domain/value-object/ReferencedEntity'
import { ValuePanelInputRoot } from './commons'
import { Select } from '../../../../../components/select'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { SelectOptionWithIcon } from '../../../../../components/select/model'
import {
  renderOptionWithIcon,
  renderValueOnlyIcon,
  wrapRenderUnselectedExplicitly,
} from '../../../../../components/select/variations'
import { useTeamRepository } from '../../../../../../services/adaptors/teamRepositoryAdaptor'

type TeamInputProps = {
  projectUuid: string
  value: ReferencedEntityWithIcon | undefined
  onChange: (v: ReferencedEntityWithIcon | undefined) => void
}

export const TeamInput = ({
  projectUuid,
  value: _value,
  onChange: _onChange,
}: TeamInputProps) => {
  const [entities, setEntities] = useState<ReferencedEntityWithIcon[]>([])
  const [options, setOptions] = useState<SelectOptionWithIcon<string>[]>([])
  const value = useMemo(() => _value?.uuid, [_value?.uuid])
  const onChange = useCallback(
    (v: string | undefined) => {
      const selectedOption = entities.find(entity => entity.uuid === v)
      _onChange(selectedOption)
    },
    [_onChange, entities]
  )
  const { fetchList } = useTeamRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await fetchList(projectUuid)
      setEntities(
        response.map(entity => ({
          uuid: entity.uuid,
          name: entity.displayName,
          iconUrl: entity.iconUrl,
        }))
      )
      setOptions(
        response.map(entity => ({
          value: entity.uuid,
          name: entity.displayName,
          iconUrl: entity.iconUrl,
        }))
      )
    }
    fn()
  }, [fetchList, projectUuid])
  return (
    <Root>
      <Select
        value={value}
        onChange={onChange}
        options={options}
        renderOption={renderOptionWithIcon}
        renderValue={wrapRenderUnselectedExplicitly(renderValueOnlyIcon)}
      />
    </Root>
  )
}

const Root = styled(ValuePanelInputRoot)({
  width: '40px',
})
