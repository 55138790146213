import { createTheme } from '@mui/material/styles'
import {
  BackgroundColor,
  Color,
  FONT_FAMILY,
  FontSize,
  IconColor,
  TextColor,
} from './commonStyles'

// @see https://mui.com/material-ui/customization/theming/

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#4072C4',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: FontSize.MEDIUM,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          touchAction: 'manipulation',
          overflow: 'hidden',
        },
        '*::-webkit-scrollbar': {
          width: '11px',
          height: '11px',
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#bbb',
          borderRadius: '10px',
          border: '3px solid #fff',
        },
        '*::-webkit-scrollbar-corner': {
          background: 'transparent',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: Color.MAIN,
            backgroundColor: `transparent !important`,
          },
        },
        inputRoot: {
          padding: '3px !important',
        },
        popper: {
          width: 'auto !important',
        },
        option: {
          fontSize: FontSize.MEDIUM,
          '&.Mui-focused': {
            color: Color.MAIN,
            backgroundColor: `transparent !important`,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.43 + ' !important',
        },
        h6: {
          fontSize: FontSize.LARGE,
          fontWeight: 'bold',
          color: TextColor.GREY,
        },
        body1: {
          fontSize: FontSize.MEDIUM,
          fontWeight: 'inherit',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: 100,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM,
          '&.Mui-selected': {
            backgroundColor: BackgroundColor.GREY,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM,
          paddingTop: 0,
          paddingBottom: 0,
        },
        gutters: {
          fontSize: FontSize.MEDIUM,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM,
        },
        primary: {
          fontSize: FontSize.MEDIUM,
        },
        secondary: {
          fontSize: FontSize.MEDIUM,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          color: IconColor.BLUE,
        },
        colorSecondary: {
          color: IconColor.WHITE,
        },
        colorAction: {
          color: IconColor.GREY,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: 'initial',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        gutters: {
          '@media (min-width: 600px)': {
            paddingLeft: 11,
          },
        },
        dense: {
          minHeight: 40,
          alignItems: 'center',
          display: 'flex',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM + 'px !important',
          fontFamily: FONT_FAMILY + ' !important',
        },
        input: {
          fontSize: FontSize.MEDIUM + 'px !important',
          fontFamily: FONT_FAMILY + ' !important',
          '&.Mui-disabled': {
            WebkitTextFillColor: '#000',
          },
        },
        inputMultiline: {
          fontSize: FontSize.LARGE + 'px !important',
          lineHeight: '1.43 !important',
          padding: '5px 10px 5px 10px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '0 3px',
          minWidth: 90,
          height: 30,
        },
        contained: {
          boxShadow: 'initial',
          color: '#fff',
        },
        outlined: {
          padding: '0 9px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          margin: '5px',
        },
        colorDefault: {
          backgroundColor: '#DCDCDC',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingTop: '3px',
          paddingBottom: '3px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingTop: '3px',
          paddingBottom: '3px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          width: '50%',
          margin: '5px 20px 5px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          width: '100%',
          marginTop: 10,
          padding: 0,
          display: 'flex',
          alignItems: 'flex-start',
          overflowX: 'auto',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        alternativeLabel: {
          flex: 'none',
          width: 100,
        },
        horizontal: {
          paddingRight: 0,
          paddingLeft: 0,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.MuiStepLabel-alternativeLabel': {
            marginTop: '5px',
          },
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: FontSize.MEDIUM + 'px !important',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: '3px 5px',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        ol: {
          height: '100%',
        },
        li: {
          fontWeight: 'bold',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: TextColor.GREY,
          },
        },
      },
    },
  },
})
