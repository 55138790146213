import cellStyle from './cellStyle'
import cellRenderer, {
  SimpleCellRenderer as simpleCellRenderer,
} from './cellRenderer'
import valueSetter from './valueSetter'

export enum WbsItemStatus {
  TODO = 'TODO',
  DOING = 'DOING',
  REVIEW = 'REVIEW',
  DONE = 'DONE',
  DISCARD = 'DISCARD',
}

export default { cellRenderer, simpleCellRenderer, valueSetter, cellStyle }
