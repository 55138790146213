import { GridApi, RowNode, ColumnApi, Column } from 'ag-grid-community'

export const moveFocusByArrowKey = (
  event: any,
  node: RowNode,
  column: Column,
  api: GridApi,
  columnApi: ColumnApi
) => {
  if (node.rowIndex === null) return
  if (event.key === 'ArrowLeft') {
    const beforeCol = columnApi.getDisplayedColBefore(column)
    beforeCol && api.setFocusedCell(node.rowIndex, beforeCol.getColId())
  }
  if (event.key === 'ArrowUp') {
    api.setFocusedCell(node.rowIndex - 1, column.getColId())
  }
  if (event.key === 'ArrowRight') {
    const afterCol = columnApi.getDisplayedColAfter(column)
    afterCol && api.setFocusedCell(node.rowIndex, afterCol.getColId())
  }
  if (event.key === 'ArrowDown') {
    api.setFocusedCell(node.rowIndex + 1, column.getColId())
  }
}

export default {
  moveFocusByArrowKey,
}
