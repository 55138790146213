import { useMemo } from 'react'
import { intl } from '../../i18n'

export const useI18n = (ids: string[]): string[] => {
  const labels = useMemo(() => {
    const labels: string[] = []
    for (let id of ids) {
      labels.push(intl.formatMessage({ id }))
    }
    return labels
  }, [ids])
  return labels
}
