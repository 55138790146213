import { useState, useEffect, useCallback } from 'react'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { WbsItemFormModel, createInitialValue, toDeltaInput } from '../../model'
import { useDispatch } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../../../store/messages'
import { intl } from '../../../../../i18n'
import { ReferencedTicketListEntity } from '../../../../../domain/entity/TicketListEntity'
import {
  isReferencedEntityValueDiffered,
  toReferencedEntityDeltaValue,
} from '../../model/properties'
import { createTicketInitialValue, TicketFormModel } from '../../model/ticket'
import { TicketEntity } from '../../../../../domain/entity/ticket'
import { useTicketRepository } from '../../../../../services/adaptors/ticketRepositoryAdaptor'

export const useTicketSingleSheetData = (uuid: string) => {
  const { fetchByWbsItemUuid, updateDelta } = useTicketRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<TicketEntity | undefined>()
  const [wbsItemFormModel, setWbsItemFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK)
  )
  const [ticketFormModel, setTicketFormModel] = useState<TicketFormModel>(
    createTicketInitialValue('')
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const response = await fetchByWbsItemUuid(uuid)
    setEntity(response)
    setWbsItemFormModel(response.wbsItem)
    setTicketFormModel(response)
    setIsLoading(false)
  }, [fetchByWbsItemUuid, uuid])
  useEffect(() => {
    fetchData()
    setInitialized(true)
  }, [fetchData, uuid])
  const onChangeWbsItem = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setWbsItemFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
    },
    []
  )
  const onChangeTicketList = useCallback(
    (ticketList: ReferencedTicketListEntity | undefined) => {
      if (!ticketList) return
      setTicketFormModel(prev => ({
        ...prev,
        ticketList,
        ticketType: ticketList.ticketType,
      }))
    },
    []
  )
  const dispatch = useDispatch()
  const submit = useCallback(async () => {
    if (!entity) return
    setIsLoading(true)
    const wbsItem = toDeltaInput(entity.wbsItem, wbsItemFormModel)
    const input = {
      uuid: entity.uuid,
      ticketListUuid: isReferencedEntityValueDiffered(
        entity.ticketList,
        ticketFormModel.ticketList
      )
        ? toReferencedEntityDeltaValue(
            entity.ticketList,
            ticketFormModel.ticketList
          )
        : undefined,
      wbsItem,
    }
    const request = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
    await fetchData()
    setIsLoading(false)
  }, [
    dispatch,
    entity,
    fetchData,
    ticketFormModel.ticketList,
    updateDelta,
    uuid,
    wbsItemFormModel,
  ])
  return {
    initialized,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    reload: fetchData,
    submit,
  }
}
