import { RowNode } from 'ag-grid-community'

export const getTopLevelNodes = (nodes: RowNode[]): RowNode[] => {
  const topLevelNodes: RowNode[] = []
  const sortedNodes = nodes.sort((a, b) => a.level - b.level)
  for (let node of sortedNodes) {
    const isChildOfSome = topLevelNodes.some(topLevelNode =>
      node.isParentOfNode(topLevelNode)
    )
    if (!isChildOfSome) {
      topLevelNodes.push(node)
    }
  }
  return topLevelNodes
}

export const sortByRowIndex = (nodes: RowNode[]): RowNode[] => {
  return nodes.sort((a, b) => (a.rowIndex || 0) - (b.rowIndex || 0))
}
