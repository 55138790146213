import { APIResponse } from './api'
import { FunctionProperty } from './appFunction'
import { SwitchableRequest } from './switchableRequest'

export default abstract class EntitySearch {
  abstract entitySearchApi: (
    query: any,
    signal?: AbortSignal
  ) => Promise<APIResponse>
  abstract search: (
    rawQuery: string,
    searchOptions?: any,
    uiMeta?: FunctionProperty
  ) => Promise<any>
  abstract searchAll: (boundary?: any) => Promise<any>
  abstract getByUuidInternal: (uuid: string) => Promise<any>
  protected hasIcon: boolean = false

  public getCacheByName(name: string) {
    if (!name) {
      return
    }
    for (const k in this.cachedResponses) {
      const cache = this.cachedResponses[k]
      const data = cache.result.data.find(
        response =>
          name === this.toResponse(response).name ||
          name === this.toResponse(response).displayName
      )
      if (data) {
        return this.toResponse(data)
      }
    }

    return
  }

  public getByUuid = async (uuid: string) => {
    if (!uuid) {
      return
    }
    const result = await this.getByUuidInternal(uuid)
    return result.json
  }

  toResponse = (response: any) => response

  getHasIcon = () => this.hasIcon

  searchRequest = new SwitchableRequest()
  private cachedResponses: {
    [query: string]: {
      at: number
      result: {
        total: number
        hit: number
        data: []
      }
    }
  } = {}

  async searchInternal<I, R, O>(
    query: string,
    toRequest: (query: string) => I,
    toResponse: (response: R) => O,
    cacheFirstResponse: boolean = false
  ) {
    const trimmedQuery = query ? query.trim() : ''
    const request = toRequest(trimmedQuery)
    const requestAsCacheKey = JSON.stringify(request)
    // Use cache:
    const cache = this.cachedResponses[requestAsCacheKey]
    if (cache && cache.result && cache.result.data) {
      // a. When a previous request was within 5 sec from now.
      if (new Date().getTime() < 5000 + cache.at) {
        return cache.result.data.map(toResponse)
      }
      if (
        // do not reuse cache when the query is '' to fetch the latest list from API.
        trimmedQuery.length > 0 &&
        // b. When 'cacheFirstResponse' is true.
        cacheFirstResponse
      ) {
        return cache.result.data.map(toResponse)
      } else {
        delete this.cachedResponses[requestAsCacheKey]
      }
    }

    const response = await this.searchRequest.switch(signal =>
      this.entitySearchApi(request, signal)
    )
    let data = response.json
    if (Array.isArray(data)) {
      data = {
        total: data.length,
        hit: data.length,
        data,
      }
    }
    if (!data.data) {
      data = {
        total: 0,
        hit: 0,
        data: [],
      }
    }
    this.cachedResponses[requestAsCacheKey] = {
      at: new Date().getTime(),
      result: data,
    }
    return data.data.map(toResponse)
  }

  getLabel(option: any): string {
    return option.name
  }

  filter(entity: any, data: any): boolean {
    return true
  }
}

export interface EntitySearchOption {
  uuid: string
  name: string
}
