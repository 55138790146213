import React, { PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Tooltip from '../../components/tooltips/Tooltip'
import store from '../../../store'
import { applyDiff } from '../../../store/singleSheet'
import SelectVOSelect from './Input/SelectVOSelect'
import EntitySearchVOSelect from './Input/EntitySearchVOSelect'
import { CellDef } from '../../containers/meta/ViewMeta/SingleSheetViewMeta'
import { PropertyType } from '../../../lib/commons/appFunction'
import { FontSize, FontWeight } from '../../../styles/commonStyles'
import { requireSave } from '../../../store/requiredSaveData'

const RootBox = styled(Box)<{ hasBackgroundColor?: boolean }>(
  ({ hasBackgroundColor }) => {
    if (hasBackgroundColor) {
      return {
        borderRadius: '2px',
        display: 'inline-block',
        boxShadow: '0 1px 3px 0.5px #00000033',
        cursor: 'pointer',
        '&:hover': {
          filter: 'brightness(95%)',
        },
      }
    }
    return {
      display: 'flex',
      margin: '0 8px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    }
  }
)
const Label = styled(Typography)({
  fontSize: FontSize.SMALL,
  fontWeight: FontWeight.BOLD,
  margin: '1px 8px',
})

interface Props extends PropsWithChildren {
  tooltipTitle: string
  storeKey: string
  value: any
  defs: CellDef | undefined
  data: any
  displayIcon?: boolean
}

interface State {
  editing: boolean
}

class ToolBarItem extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
    }
  }

  isEditing = (): boolean => {
    if (!this.props.defs) {
      return false
    }
    return this.state.editing
  }

  setEditing = (value: boolean) => {
    if (!this.props.defs) {
      return
    }
    this.setState({ editing: value })
  }

  onChange = (newValue: any) => {
    const diff = this.props.defs?.getChangeValue(
      newValue,
      this.props.data,
      this.props.defs
    )
    store.dispatch(
      applyDiff({
        ...diff,
      })
    )
    store.dispatch(requireSave())
    this.setState({ editing: false })
  }

  onBlur = () => {
    this.setState({ editing: false })
  }

  getBackgroundColor = () => {
    if (!this.props.defs || !this.props.defs.uiMeta.valuesAllowed) {
      return 'transparent'
    }
    const item = this.props.defs.uiMeta.valuesAllowed.find(
      v => v.value === this.props.value?.getValue()
    )
    if (!item || !item.backgroundColor) {
      return 'transparent'
    }
    return item.backgroundColor
  }

  createComponent = () => {
    const { defs } = this.props
    if (defs && defs.type === PropertyType.Select) {
      return (
        <SelectVOSelect
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      )
    } else if (defs && defs.type === PropertyType.EntitySearch) {
      return (
        <EntitySearchVOSelect
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
        />
      )
    } else {
      return this.props.children
    }
  }

  hasBackgroundColor = (): boolean => {
    const background = this.getBackgroundColor()
    return !!background && background !== 'transparent'
  }

  createLabel = () => {
    if (!this.hasBackgroundColor()) {
      return this.props.children
    }
    return <Label>{this.props.children}</Label>
  }

  render() {
    return (
      <>
        {this.isEditing() && (
          <RootBox alignItems="center">{this.createComponent()}</RootBox>
        )}
        {!this.isEditing() && (
          <Tooltip message={this.props.tooltipTitle}>
            <RootBox
              hasBackgroundColor={this.hasBackgroundColor()}
              alignItems="center"
              onClick={e => this.setEditing(true)}
              style={{
                backgroundColor: this.getBackgroundColor(),
              }}
            >
              {this.createLabel()}
            </RootBox>
          </Tooltip>
        )}
      </>
    )
  }
}

export default ToolBarItem
