import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { CheckBoxCellRenderer } from '../../../components/cellRenderer'
import {
  isAssociatedWithTheWbsItemType,
  WbsItemAdditionalPropertyEntity,
} from '../../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { Option } from '..'
import { intl } from '../../../../../../i18n'
import { filter } from '../../../../../pages/ProjectPlanNew/projectPlanNew'
import { BoolFilter, ServerSideBoolFilter } from '../../../components/filter'

export const checkboxColumnDef = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
): void => {
  column.cellRenderer = CheckBoxCellRenderer
  column.cellRendererParams = {
    ...column.cellRendererParams,
    hide: (rowData: RowData) =>
      !isAssociatedWithTheWbsItemType(
        wbsItemAdditionalProperty,
        option.getWbsItemType(rowData)
      ),
    valueSetter: (
      params: ICellRendererParams<RowData>,
      oldValue: boolean,
      newValue: boolean
    ) => {
      if (!params.node) return
      option.setAdditionalPropertyValues(
        params.node,
        wbsItemAdditionalProperty,
        oldValue,
        newValue
      )
    },
  }
  setFilterParams(column, wbsItemAdditionalProperty, option)
}
const setFilterParams = <RowData>(
  column: ColDef,
  wbsItemAdditionalProperty: WbsItemAdditionalPropertyEntity,
  option: Option<RowData>
) => {
  switch (option.columnFilterType) {
    case 'CLIENT_SIDE':
      column.filter = 'clientSideSelectFilter'
      column.floatingFilter = !option?.disableFloatingFilter
      column.keyCreator = (params): string => {
        return params.value ? 'checked' : ''
      }
      column.filterParams = {
        getValue: option => (option ? 'checked' : ''),
        getLabel: option => (option ? 'checked' : ''),
      }
      break
    case 'SERVER_SIDE':
      column.filter = ServerSideBoolFilter
      column.floatingFilter = !option?.disableFloatingFilter
      column.filterParams = {
        labelExist: intl.formatMessage({ id: 'exist' }),
        labelNotExist: intl.formatMessage({ id: 'not.exist' }),
        fetch: (v: BoolFilter) => {
          return filter({
            projectUuid: wbsItemAdditionalProperty.projectUuid,
            additionalPropertyValues: [
              {
                wbsItemAdditionalPropertyUuid: wbsItemAdditionalProperty.uuid,
                propertyType: wbsItemAdditionalProperty.propertyType,
                operator: v.operator,
              },
            ],
          })
        },
      }
      break
    default:
      break
  }
}
