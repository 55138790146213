import { styled, TextField, TextFieldProps } from '@mui/material'
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  Title,
} from '.'
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { useVersionReportVersionRepository } from '../../../../../services/adaptors/versionReportVersionRepositoryAdaptor'
import { Button } from '../../../../components/buttons'
import {
  ProgressOverviewPageConfig,
  ProgressReportByProcessPageConfig,
  ProgressReportByTeamPageConfig,
} from '../../../../../domain/entity/version-report/VersionReportTemplateEntity'
import Loading from '../../../../components/process-state-notifications/Loading'

type NewVersionDialogProps = {
  open: boolean
  templateUuid: string | undefined
  onAfterSubmit: (uuid: string, name: string) => void
  onClose: () => void
}

export const NewVersionDialog = ({
  open,
  templateUuid,
  onAfterSubmit,
  onClose,
}: NewVersionDialogProps) => {
  const {
    fetch,
    createVersion,
    updatePages,
    evaluateOverview,
    evaluateProgressByProcess,
    evaluateProgressByTeam,
  } = useVersionReportVersionRepository()
  const [value, setValue] = useState<string>('')
  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }, [])
  const [loading, setLoading] = useState<boolean>(false)
  const onSubmit = useCallback(async () => {
    if (!templateUuid) return
    setLoading(true)
    // 1. Create version
    const response = await createVersion(templateUuid, value)

    // 2. Fetch the created version
    const version = await fetch(response.uuid)

    // 3. Evaluate each pages.
    const evaluateRequests = version.pages.map(page => {
      switch (page.pageType) {
        case 'PROGRESS_OVERVIEW':
          return evaluateOverview({
            snapshotUuid: version.snapshotUuid,
            leafUuids: (page.config as ProgressOverviewPageConfig).targetUuids,
          })
        case 'PROGRESS_REPORT_BY_PROCESS':
          return evaluateProgressByProcess({
            snapshotUuid: version.snapshotUuid,
            projectPlanUuids: (page.config as ProgressReportByProcessPageConfig)
              .tableRows,
          })
        case 'PROGRESS_REPORT_BY_TEAM':
          return evaluateProgressByTeam({
            snapshotUuid: version.snapshotUuid,
            rootUuid: (page.config as ProgressReportByTeamPageConfig).rootUuid,
            teamUuids: (page.config as ProgressReportByTeamPageConfig)
              .tableRows,
          })
      }
    })
    const evaluateResults = await Promise.all(evaluateRequests)

    // 4. Update each pages with evaluation result.
    for (let i = 0; i < version.pages.length; i++) {
      const page = version.pages[i]
      const result = evaluateResults[i]
      switch (page.pageType) {
        case 'PROGRESS_OVERVIEW':
          page.content['status'] = result.result['status']
          page.content['comment'] = result.result['comment']
          break
        case 'PROGRESS_REPORT_BY_PROCESS':
          page.content['tableContents'] = result.result
          break
        case 'PROGRESS_REPORT_BY_TEAM':
          page.content['tableContents'] = result.result
      }
    }
    await updatePages(version)

    onAfterSubmit(response.uuid, value)
    setLoading(false)
    onClose()
  }, [createVersion, onAfterSubmit, onClose, templateUuid, value])

  const onEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSubmit()
      }
    },
    [onSubmit]
  )

  const disabled = useMemo(() => !value, [value])
  return (
    <>
      <StyledDialog open={open} onClose={onClose}>
        <StyledDialogTitle>
          <Title>版の新規作成</Title>
        </StyledDialogTitle>
        <StyledDialogContent>
          <Input label={'版の名称'} onChange={onChange} onKeyDown={onEnter} />
        </StyledDialogContent>
        <StyledDialogActions>
          <Button onClick={onSubmit} disabled={disabled} colorPattern="skyBlue">
            報告書を作成する
          </Button>
          <Button onClick={onClose} colorPattern="monotone" variant="outlined">
            {intl.formatMessage({ id: 'dialog.cancel' })}
          </Button>
        </StyledDialogActions>
      </StyledDialog>
      <Loading isLoading={loading} />
    </>
  )
}

const Input = styled((props: TextFieldProps) => (
  <TextField variant="outlined" {...props} />
))({
  width: '360px',
  '& .MuiOutlinedInput-input': {
    height: '14px',
    padding: '20px 16px',
    color: '#2D4069',
    caretColor: '#00A0E9',
    '&:focus': {
      backgroundColor: '#F7FCFF',
    },
    fontSize: '16px !important',
  },
  '& .MuiOutlinedInput-root': {
    height: '54px',
    '& fieldset': {
      borderColor: '#D8DDE5',
    },
    '&:hover fieldset': {
      borderColor: '#00A0E9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#00A0E9',
      borderWidth: '1px',
    },
  },
})
