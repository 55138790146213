import {
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community'
import { intl } from '../../../../i18n'
import {
  ColumnType,
  columnTypes,
  defaultColDef,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { ProjectOverviewSequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/ProjectOverviewSequenceNoCellRenderer'
import WbsItemTypeCell, {
  WbsItemTypeCellValue,
} from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemType'
import WbsItemStatusCell from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { ProjectOverviewRow } from '../../ProjectOverview/projectOverview'
import DateVO from '../../../../vo/DateVO'
import { WbsItemType } from '../../../../domain/entity/WbsItemEntity'
import { customHeaderTemplate } from '../../../containers/commons/AgGrid/components/header/CustomHeader'
import { DISPLAY_DATE_FORMAT } from '../../../../utils/date'
import { ProjectPlanTreeCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/wbsItemName/projectPlanTreeCellRenderer'
import {
  cumulationCellType,
  evmFormatter,
  getEvmData,
  getProgressData,
  getProgressDetailData,
  percentageValueFormatter,
  progressValueFormatter,
} from '../../ProjectOverview/projectOverviewGridOptions'
import { ProjectDetail } from '../../../../lib/functions/project'
import {
  GanttReportCellRenderer,
  GanttHeader,
  GanttHeaderGroup,
} from '../../../containers/commons/AgGrid/components/cell/custom/ganttReport'

// Ag grid options
export const masterScheduleGridOptions = (
  project: ProjectDetail,
  onClickNumber: (data: ProjectOverviewRow) => void
): GridOptions => {
  const evmTooltipHeader = {
    template: customHeaderTemplate({ tooltip: 'true' }),
  }
  const today = DateVO.now()

  return {
    // Grid context. Define grid specific properties
    context: {
      dateFormat: DISPLAY_DATE_FORMAT,
    },
    // Styling
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 45,
    // Row grouping
    treeData: true,
    // TODO: delete this option if the expanded condition is saved.
    groupDefaultExpanded: 1,
    autoGroupColumnDef: {
      field: 'wbsItem.displayName',
      headerName: intl.formatMessage({
        id: 'projectOverview.displayName',
      }),
      width: 300,
      hide: false,
      pinned: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: ProjectPlanTreeCellRenderer,
      },
    },
    // Column definition
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: { ...defaultColDef(), hide: true },
    tooltipShowDelay: 100,
    suppressContextMenu: true,
    columnDefs: [
      {
        headerName: intl.formatMessage({ id: 'projectOverview.information' }),
        children: [
          {
            field: 'rowNumber',
            headerName: '#',
            pinned: true,
            lockPosition: 'left',
            suppressMovable: true,
            width: 50,
            hide: false,
            cellClass: 'ag-numeric-cell',
            cellStyle: { justifyContent: 'center' },
            cellRenderer: ProjectOverviewSequenceNoCellRenderer,
            cellRendererParams: { onClick: onClickNumber },
          },
          {
            field: 'wbsItem.code',
            headerName: intl.formatMessage({ id: 'projectOverview.code' }),
            pinned: true,
            lockPosition: 'left',
            width: 90,
          },
          {
            field: 'wbsItem.type',
            headerName: intl.formatMessage({ id: 'projectOverview.type' }),
            type: [ColumnType.wbsItemType],
            cellRenderer: WbsItemTypeCell.cellRenderer,
            valueGetter: (params: ValueGetterParams) => {
              if (!params.data.wbsItem.baseWbsItemType) return {}
              return {
                wbsItemType: params.data.wbsItem.baseWbsItemType,
              } as WbsItemTypeCellValue
            },
            valueFormatter: WbsItemTypeCell.valueFormatter,
            pinned: true,
            lockPosition: 'left',
            width: 90,
          },
          {
            field: 'wbsItem.status',
            headerName: intl.formatMessage({ id: 'projectOverview.status' }),
            type: [ColumnType.wbsItemStatus],
            cellRenderer: WbsItemStatusCell.simpleCellRenderer,
            pinned: true,
            lockPosition: 'left',
            width: 90,
          },
          {
            // Column for exporting excel
            field: 'wbsItem.displayName',
            headerName: intl.formatMessage({
              id: 'projectOverview.displayName',
            }),
            pinned: true,
            suppressMovable: true,
            suppressColumnsToolPanel: true,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.ganttChart' }),
        headerGroupComponent: GanttHeaderGroup,
        children: [
          {
            field: 'ganttChart',
            headerName: intl.formatMessage({
              id: 'projectOverview.ganttChart',
            }),
            cellRenderer: GanttReportCellRenderer,
            headerComponent: GanttHeader,
            cellRendererParams: {
              editable: false,
            },
            hide: false,
            cellStyle: {
              borderColor: 'transparent',
              backgroundColor: 'transparent',
            },
            valueGetter: (params: ValueGetterParams) => {
              const w = params.data?.wbsItem
              if (!w) return undefined
              return JSON.stringify([w.scheduledDate, w.status, w.actualDate])
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.term' }),
        children: [
          {
            field: 'wbsItem.scheduledDate.startDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledStartDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
            width: 90,
          },
          {
            field: 'wbsItem.scheduledDate.endDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledEndDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemScheduledDate],
            width: 90,
          },
          {
            field: 'wbsItem.actualDate.startDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualStartDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemActualDate],
            width: 90,
          },
          {
            field: 'wbsItem.actualDate.endDate',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualEndDate',
            }),
            type: [ColumnType.date, ColumnType.wbsItemActualDate],
            width: 90,
          },
          {
            field: 'startDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.startDelayDays',
            }),
            width: 100,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectOverviewRow>) => {
              const w = params.data?.wbsItem
              const scheduled = w?.scheduledDate?.startDate
              const actual = w?.actualDate?.startDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
          {
            field: 'endDelayDays',
            headerName: intl.formatMessage({
              id: 'projectOverview.endDelayDays',
            }),
            width: 100,
            cellStyle: { justifyContent: 'flex-end' },
            valueGetter: (params: ValueGetterParams<ProjectOverviewRow>) => {
              const w = params.data?.wbsItem
              const scheduled = w?.scheduledDate?.endDate
              const actual = w?.actualDate?.endDate
              if (!scheduled) return
              const diff = (actual ? new DateVO(actual) : today).diff(
                new DateVO(scheduled)
              )
              return 0 < diff ? diff : undefined
            },
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.progress' }),
        children: [
          {
            field: 'scheduledProgressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledProgressRate',
            }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.scheduledProgressRate ??
              getProgressData(params.data, params.context)
                ?.scheduledProgressRate,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'progressRate',
            headerName: intl.formatMessage({
              id: 'projectOverview.progressRate',
            }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.progressRate ??
              getProgressData(params.data, params.context)?.progressRate,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'total',
            headerName: intl.formatMessage({ id: 'projectOverview.total' }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.total ??
              getProgressData(params.data, params.context)?.total,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'scheduledToBe',
            headerName: intl.formatMessage({
              id: 'projectOverview.scheduledToBeCompleted',
            }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)
                ?.scheduledToBe ??
              getProgressData(params.data, params.context)?.scheduledToBe,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'completed',
            headerName: intl.formatMessage({
              id: 'projectOverview.completed',
            }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.completed ??
              getProgressData(params.data, params.context)?.completed,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'preceding',
            headerName: intl.formatMessage({
              id: 'projectOverview.preceding',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.preceding ??
              getProgressData(params.data, params.context)?.preceding,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'delayed',
            headerName: intl.formatMessage({ id: 'projectOverview.delayed' }),
            hide: false,
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.delayed ??
              getProgressData(params.data, params.context)?.delayed,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'remaining',
            headerName: intl.formatMessage({
              id: 'projectOverview.remaining',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.remaining ??
              getProgressData(params.data, params.context)?.remaining,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'unplanned',
            headerName: intl.formatMessage({
              id: 'projectOverview.endUnplanned',
            }),
            valueGetter: params =>
              getProgressDetailData(params.data, params.context)?.unplanned ??
              getProgressData(params.data, params.context)?.unplanned,
            valueFormatter: progressValueFormatter,
            ...cumulationCellType,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'projectOverview.evm' }),
        children: [
          {
            field: 'actualHour',
            headerName: intl.formatMessage({
              id: 'projectOverview.actualHour',
            }),
            valueGetter: params => {
              if (
                params.data?.wbsItem?.wbsItemType?.isTask() &&
                params.context.aggregateTarget === WbsItemType.TASK
              ) {
                return params.data?.cumulation?.actualHour
              }
              return getEvmData(params.data, params.context)?.actualHour
            },
            valueFormatter: (params: ValueFormatterParams) => {
              const type = params.data.wbsItem?.wbsItemType
              if (type) {
                if (
                  type.isDeliverable() &&
                  params.context.aggregateTarget === WbsItemType.DELIVERABLE
                ) {
                  return '-'
                }
                if (
                  type.isTask() &&
                  params.context.aggregateTarget !== WbsItemType.TASK
                ) {
                  return ''
                }
              }
              const value: number | undefined = params.value
              if (value === undefined) return '-'
              return (
                Number(value) /
                (params.context.workloadUnitState?.hoursPerSelectedUnit || 1)
              ).toFixed(1)
            },
            ...cumulationCellType,
          },
          {
            field: 'costVariance',
            headerName: 'CV',
            headerTooltip: intl.formatMessage({ id: 'evm.description.CV' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.costVariance,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'costPerformanceIndex',
            headerName: 'CPI',
            headerTooltip: intl.formatMessage({ id: 'evm.description.CPI' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.costPerformanceIndex,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'scheduleVariance',
            headerName: 'SV',
            headerTooltip: intl.formatMessage({ id: 'evm.description.SV' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.scheduleVariance,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'schedulePerformanceIndex',
            headerName: 'SPI',
            headerTooltip: intl.formatMessage({ id: 'evm.description.SPI' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.schedulePerformanceIndex,
            valueFormatter: percentageValueFormatter,
            ...cumulationCellType,
          },
          {
            field: 'estimateToComplete',
            headerName: 'ETC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.ETC' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.estimateToComplete,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'estimateAtCompletion',
            headerName: 'EAC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.EAC' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.estimateAtCompletion,
            valueFormatter: evmFormatter,
            ...cumulationCellType,
          },
          {
            field: 'varianceAtCompletion',
            headerName: 'VAC',
            headerTooltip: intl.formatMessage({ id: 'evm.description.VAC' }),
            headerComponentParams: evmTooltipHeader,
            valueGetter: params =>
              getEvmData(params.data, params.context)?.varianceAtCompletion,
            ...cumulationCellType,
            valueFormatter: evmFormatter,
          },
        ],
      },
    ],
  }
}
