import { Avatar, Box, MenuItem, Select, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'
import { intl } from '../../../../i18n'
import TicketListApi, {
  TicketListDetail,
} from '../../../../lib/functions/ticketList'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { projectPrivate } from '../../../higher-order-components/projectPrivate'
import { RoundedCheckbox } from '../../../components/inputs/Checkbox/RoundedCheckbox'
import { useCallback, useEffect, useState } from 'react'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'

type Props = {
  ticketType?: string
  onChangeTicketType?: (value?: string) => void
  ticketListUuid: string | undefined
  onChangeTicketList: (value: TicketListDetail | undefined) => void
}

export const TicketListSelector = projectPrivate(
  ({
    ticketType,
    onChangeTicketType,
    ticketListUuid,
    onChangeTicketList,
  }: Props) => {
    const { projectUuid, ticketTypes } = useProjectPrivateContext()
    const [ticketLists, setTicketLists] = useState<TicketListDetail[]>([])

    const fetchTicketLists = useCallback(async () => {
      let lists: TicketListDetail[] = []
      if (projectUuid && ticketType) {
        const response = await TicketListApi.getAll({
          projectUuid: projectUuid,
          ticketType: ticketType,
        })
        lists = response.json
      }
      setTicketLists(lists)
      const list = lists.find(v => v.uuid === ticketListUuid)
      onChangeTicketList(list)
    }, [projectUuid, ticketType, ticketListUuid, onChangeTicketList])

    const onChangeTicketListUuid = useCallback(
      event => {
        const targetUuid = event.target.value
        const targetDetail = ticketLists.find(v => v.uuid === targetUuid)
        onChangeTicketList(targetDetail)
      },
      [ticketLists, onChangeTicketList]
    )

    useEffect(() => {
      fetchTicketLists()
    }, [projectUuid, ticketType, ticketListUuid])

    return (
      <Box
        sx={{
          margin: '10px 10px 0',
          padding: '10px',
          backgroundColor: '#D8DDE54D',
          display: 'flex',
          flexFlow: 'column',
          gap: '8px',
        }}
      >
        {onChangeTicketType && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{ minWidth: '128px', color: colorPalette.monotone[4] }}
            >
              {intl.formatMessage({ id: 'ticketType' })}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {ticketTypes.map(v => (
                <TicketTypeCheckbox
                  key={v.code}
                  ticketType={ticketType}
                  onChangeTicketType={onChangeTicketType}
                  ticketTypeVO={v}
                />
              ))}
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            sx={{ minWidth: '128px', color: colorPalette.monotone[4] }}
          >
            {intl.formatMessage({ id: 'ticketList' })}
          </Typography>
          <Select
            value={ticketListUuid || ''}
            sx={{
              border: `1px solid ${colorPalette.monotone[2]}`,
              borderRadius: '4px',
              width: '370px',
              height: '40px',
              color: colorPalette.monotone[4],
              backgroundColor: colorPalette.monotone[0],
            }}
            MenuProps={{
              sx: {
                width: '370px',
                height: '540px',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '21px',
              },
            }}
            onChange={onChangeTicketListUuid}
          >
            {ticketLists.map(v => {
              return (
                <MenuItem
                  key={v.uuid}
                  value={v.uuid}
                  sx={{
                    color: colorPalette.monotone[4],
                    fontSize: '14px',
                    fontWeight: 500,
                    lineHeight: '21px',
                  }}
                >
                  {v.wbsItem.displayName}
                </MenuItem>
              )
            })}
          </Select>
        </Box>
      </Box>
    )
  }
)

type TicketTypeCheckboxProps = Omit<
  Props,
  'ticketListUuid' | 'onChangeTicketList' | 'onChangeTicketType'
> & {
  ticketTypeVO: WbsItemTypeVO
  onChangeTicketType: (value?: string) => void
}
const TicketTypeCheckbox = ({
  ticketType,
  onChangeTicketType,
  ticketTypeVO,
}: TicketTypeCheckboxProps) => {
  return (
    <Box key={ticketTypeVO.code} sx={{ display: 'flex', alignItems: 'center' }}>
      <RoundedCheckbox
        sx={{ width: '20px', height: '20px' }}
        checked={ticketTypeVO.code === ticketType}
        onChange={e => onChangeTicketType(ticketTypeVO.code)}
      />
      <Avatar
        variant={'circular'}
        src={ticketTypeVO.iconUrl}
        sx={{ width: '20px', height: '20px', marginRight: '4px' }}
      />
      <Typography
        sx={{
          color: colorPalette.monotone[4],
          fontSize: '14px',
          fontWeight: 400,
        }}
      >
        {ticketTypeVO.name}
      </Typography>
    </Box>
  )
}
