import { ICellRendererParams } from 'ag-grid-community'
import { useCallback, useEffect, useState } from 'react'
import { FunctionProperty } from '../../../../../lib/commons/appFunction'
import Checkbox from '../../../../components/editors/checkbox/Checkbox'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'

type Props = ICellRendererParams & {
  extension?: FunctionProperty
  hide?: boolean | ((rowData) => boolean)
  enableDisabledByContext?: boolean
  valueSetter?: (
    params: ICellRendererParams,
    oldValue: boolean,
    newValue: boolean
  ) => void
}

export const CheckBoxCellRenderer = (props: Props) => {
  const [value, setValue] = useState<boolean>(!!props.value)
  const hide =
    props.hide && typeof props.hide === 'function'
      ? props.hide(props.data)
      : props.hide
  const disabled =
    !!props.enableDisabledByContext && props.context.checkboxDisabled

  useEffect(() => {
    if (typeof props.value === 'boolean') {
      setValue(props.value)
    } else if (props.value === undefined && value) {
      // Unchecked by delete key
      setValue(false)
    } else if (props.value && typeof props.value === 'string') {
      setValue(!!props.value.match(/^([tT]|check|1)/))
    }
  }, [value, props.value])

  const onChange = useCallback((_, checked) => {
    setValue(checked)
    const { node, colDef, extension, valueSetter } = props
    const data = node.data
    if (!node || !colDef || !data) return
    if (valueSetter) {
      return valueSetter(props, props.value, checked)
    }
    if (data.body && extension) {
      if (!data.body.extensions) {
        data.body.extensions = []
      }
      const val = {
        uuid: extension.entityExtensionUuid,
        value: checked,
      }
      const index = data.body.extensions.findIndex(
        v => v.uuid === extension.entityExtensionUuid
      )
      if (0 <= index) {
        data.body.extensions.splice(index, 1, val)
      } else {
        data.body.extensions.push(val)
      }
      data.edited = true
      node.setData(data)
    } else {
      node.setDataValue(colDef.field!, checked)
    }
  }, [])

  if (hide) {
    return <></>
  }

  return (
    <RootDiv>
      <Checkbox
        disabled={disabled}
        value={value}
        checked={!!value}
        color="primary"
        onChange={onChange}
      />
      <FloatingEditableIcon {...props} />
    </RootDiv>
  )
}
