import * as React from 'react'

const SvgSmokeIcon = React.forwardRef(
  (
    props: { style?: React.CSSProperties },
    ref: React.LegacyRef<SVGSVGElement>
  ) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.style?.fontSize || 25}
      height={props.style?.fontSize || 25}
      viewBox="0 0 300 300"
      ref={ref}
    >
      <path
        fill={props.style?.color || 'none'}
        stroke="currentColor"
        strokeWidth={1}
        d="M271.31 242.07v48.7H238.7v-48.48c0-48.7-38.27-88.49-86.97-88.49v-32.61c22.18 0 40.22-18.04 40.22-40.22s-18.04-40.22-40.22-40.22V8.14c40.22 0 72.84 32.61 72.84 72.83 0 20.22-8.27 38.48-21.75 51.74 40.66 19.35 68.49 61.1 68.49 109.36Zm-54.35 13.05v35.65h-32.62v-28.26c0-28.48-20-44.57-42.83-44.57h-33.26c-40.22 0-72.83-32.61-72.83-72.83s32.61-72.84 72.83-72.84v32.62c-22.18 0-40.22 15.87-40.22 38.04 0 22.18 18.04 43.49 40.22 43.49h33.26c40.66 0 75.45 29.35 75.45 68.7Z"
      />
    </svg>
  )
)

export default SvgSmokeIcon
