import { ColDef } from 'ag-grid-community'
import { DateTerm } from '../../../../../utils/date'
import { SortedColumnState } from '../../../../model/bulkSheetColumnSortState'
import {
  AccountCategory,
  RowFilterKey,
  RowFilterKeyType,
  RowStyle,
  RowStyleType,
} from '../../profitLossItems'
import { AllState } from '../../../../../store'
import { connect } from 'react-redux'
import { Button } from '../../../../components/buttons'
import { Box, Typography } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import ReloadIcon from '../../../../components/icons/ReloadIcon'
import { MonthRangeSelector } from '../../../../components/headers/HeaderBar/MonthRangeSelector'
import FilterButton from '../../../../containers/BulkSheetView/components/header/FilterButton'
import SortButton from '../../../../containers/BulkSheetView/components/header/SortButton'
import ColumnSettingButton from '../../../../containers/BulkSheetView/components/header/ColumnSettingButton'
import DisplayFormatButton from '../../../../containers/BulkSheetView/components/header/DisplayFormatButton'
import FileExportButton from '../../../../containers/BulkSheetView/components/header/FileExportButton'
import ExclusiveToggleGroup from '../../../../components/editors/toggle/ExclusiveToggleGroup'
import { HeaderGroup } from '../../../../components/headers/HeaderBar/PageHeader'
import {
  ToolBarItemPosition,
  generateToolBarItemKey,
} from '../../../../components/toolbars/ContainerToolBar'
import MultipleToggleButton from '../../../../components/editors/toggle/MultipleToggleGroup'
import { useCallback } from 'react'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface RowFilterProps {
  enableKeys: RowFilterKeyType[]
  values: RowFilterKeyType[]
  onChange: (values: RowFilterKeyType[]) => void
}

interface OwnProps {
  loading: boolean
  dateTerm: DateTerm
  rowHeight: number
  accountCategory: string | undefined
  rowFilters?: RowFilterProps[]
  rowStyle?: RowStyleType | undefined
  onSearch: (dateTerm: DateTerm) => void
  onSubmit: () => void
  onReload: () => void
  filteredColumns: ColDef[]
  onDeleteFilteredColumn: (column: ColDef) => void
  resetFilters: () => void
  sortColumnState: SortedColumnState[]
  onDeleteSortedColumn: ((column: string | ColDef<any>) => void) | undefined
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState:
    | ((column: string | ColDef<any>, sort: 'asc' | 'desc' | null) => void)
    | undefined
  columnSettingOpen: boolean
  onClickColumnSettingButton: (e) => void
  onChangeRowHeight: (value: number) => void
  onClickExport: () => void
  onChangeAccountCategory: (value: string | undefined) => void
  onChangeRowStyle?: (value: RowStyleType | undefined) => void
}

interface StateProps {
  notEdited: boolean
}

type Props = OwnProps & StateProps

const getQuickFilterLabelByKey = (key: RowFilterKeyType) => {
  switch (key) {
    case RowFilterKey.Scheduled:
      return intl.formatMessage({ id: 'profitLossItems.budget' })
    case RowFilterKey.Actual:
      return intl.formatMessage({ id: 'profitLossItems.result' })
    case RowFilterKey.WorkMonth:
      return intl.formatMessage({ id: 'profitLossMembers.manmonth' })
    case RowFilterKey.Amount:
      return intl.formatMessage({ id: 'profitLossMembers.amount' })
    case RowFilterKey.Forecast:
      return intl.formatMessage({ id: 'profitLossItems.forecast' })
    case RowFilterKey.BudgetaryAmount:
      return intl.formatMessage({ id: 'profitLossItems.budgetaryAmount' })
    default:
      return ''
  }
}

const ProfitLossItemsHeader = (props: Props) => {
  const onChangeRowStyle = useCallback(
    (value: string) => {
      props.onChangeRowStyle && props.onChangeRowStyle(value as RowStyleType)
    },
    [props]
  )

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: '10px 10px 5px 10px',
        overflowX: 'auto',
        overflowY: 'hidden',
        minHeight: '47px',
      }}
    >
      <Button
        disabled={props.loading || props.notEdited}
        onClick={props.onSubmit}
        colorPattern="green"
        sx={{
          border: 'none',
          cursor: 'pointer',
          padding: '8px 0px',
          height: '28px',
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <Typography
          sx={{ color: colorPalette.monotone[0], margin: '0 2px 0 15px' }}
        >
          {intl.formatMessage({ id: 'wbs.header.save.button' })}
        </Typography>
        <Typography
          sx={{
            color:
              props.loading || props.notEdited
                ? colorPalette.monotone[0]
                : colorPalette.green[2],
            margin: '0 10px 0 2px',
            fontSize: '11px',
          }}
        >
          {intl.formatMessage({ id: 'wbs.header.save.button.shortcutKey' })}
        </Typography>
      </Button>
      <Button
        onClick={props.onReload}
        disabled={props.loading}
        sx={{
          background: colorPalette.monotone[1],
          border: `1px solid ${colorPalette.monotone[3]}`,
          borderRadius: '50%',
          height: '28px',
          width: '28px',
          marginRight: '10px',
          padding: '5px 8px',
          cursor: 'pointer',
          '&:hover': { background: colorPalette.monotone[1] },
        }}
      >
        <ReloadIcon />
      </Button>
      {!!props.onChangeRowStyle && (
        <div style={{ paddingRight: '5px' }}>
          <ExclusiveToggleGroup
            value={props.rowStyle?.toString()}
            options={Object.values(RowStyle).map(v => ({
              label: intl.formatMessage({
                id: `profitLoss.row.style.${v}`,
              }),
              value: v,
            }))}
            onChange={onChangeRowStyle}
          />
        </div>
      )}
      <div style={{ paddingRight: '5px' }}>
        <ExclusiveToggleGroup
          value={props.accountCategory}
          options={Object.values(AccountCategory).map(v => ({
            label: intl.formatMessage({
              id: `profitLoss.account.category.${v}`,
            }),
            value: v,
          }))}
          onChange={props.onChangeAccountCategory}
        />
      </div>
      <div>
        <MonthRangeSelector
          dateTerm={props.dateTerm}
          onSearch={props.onSearch}
        />
      </div>
      {props.rowFilters && (
        <>
          {props.rowFilters.map((filter, index) => {
            return (
              <HeaderGroup
                key={generateToolBarItemKey(index, ToolBarItemPosition.RIGHT)}
              >
                <MultipleToggleButton
                  values={filter.values}
                  options={filter.enableKeys.map(key => {
                    return {
                      value: key,
                      label: getQuickFilterLabelByKey(key),
                    }
                  })}
                  onChange={(values: string[]) => {
                    filter.onChange(values as RowFilterKeyType[])
                  }}
                />
              </HeaderGroup>
            )
          })}
        </>
      )}
      <FilterButton
        filteredColumns={props.filteredColumns}
        onDeleteFilteredColumn={props.onDeleteFilteredColumn}
        resetFilters={props.resetFilters}
      />
      <SortButton
        sortColumnsState={props.sortColumnState}
        onDeleteSortedColumn={props.onDeleteSortedColumn}
        onDeleteSortedAllColumns={props.onDeleteSortedAllColumns}
        onChangeSortColumnState={props.onChangeSortColumnState}
      />
      <ColumnSettingButton
        open={props.columnSettingOpen}
        onClick={props.onClickColumnSettingButton}
      />
      <DisplayFormatButton
        height={props.rowHeight}
        onChangeHeight={props.onChangeRowHeight}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <FileExportButton onClickExport={props.onClickExport} />
      </Box>
    </div>
  )
}

const mapStateToProps = (state: AllState) => ({
  notEdited: !state.hasRequiredSaveData.hasRequiredSaveData,
})

export default connect(mapStateToProps)(ProfitLossItemsHeader)
