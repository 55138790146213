import {
  SearchConditionProps,
  FocusableSearchConditionProps,
  SearchConditionRowHeaderCell,
} from '.'
import DateTimeTermVO from '../../../../domain/value-object/DateTimeTermVO'
import { DateTimeTermCell } from '../../tables/table-cells/DateTimeTermCell'

export type DateTimeTermConditionProps = SearchConditionProps<
  DateTimeTermVO | undefined
> &
  FocusableSearchConditionProps

export const DateTimeTermCondition = ({
  header,
  ...other
}: DateTimeTermConditionProps) => {
  return (
    <>
      <DateTimeTermCell {...other} />
    </>
  )
}
