import {
  ColDef,
  EditableCallbackParams,
  CellClassParams,
  ValueSetterParams,
  ICellRendererParams,
} from 'ag-grid-community'
import { ClientSideSelectFilter } from '../../../../containers/BulkSheetView/components/filter'
import { propertyType } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  isAdditionalPropertyRow,
  isGroupHeaderRow,
  AdditionalPropertyRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { SelectCellEditor } from '../../../../containers/BulkSheetView/components/cellEditor'
import { WbsItemAdditionalPropertyType } from '../../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import store from '../../../../../store'
import { requireSave } from '../../../../../store/requiredSaveData'
import { selectOptionsColumnDef } from './selectOptions'
import { entitySearchReferenceEntityColumnDef } from './entitySearchReferenceEntity'
import { intl } from '../../../../../i18n'

export const propertyTypeColumnDef: ColDef = {
  ...propertyType,
  editable: (params: EditableCallbackParams) =>
    isAdditionalPropertyRow(params.node.data),
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
  valueSetter: ({
    oldValue,
    newValue,
    data,
    node,
    api,
  }: ValueSetterParams<AdditionalPropertyRow>) => {
    if (!node || oldValue === newValue) {
      return false
    }
    data.edited = true
    data.propertyType = newValue
    if (WbsItemAdditionalPropertyType.SELECT === oldValue) {
      data.selectOptions = undefined
    }
    if (WbsItemAdditionalPropertyType.SELECT === newValue) {
      data.selectOptions = []
    }
    if (
      WbsItemAdditionalPropertyType.SELECT === oldValue ||
      WbsItemAdditionalPropertyType.SELECT === newValue
    ) {
      // To refresh cell style.
      api.refreshCells({
        rowNodes: [node],
        columns: [selectOptionsColumnDef.field!],
        force: true,
      })
    }

    if (WbsItemAdditionalPropertyType.ENTITY_SEARCH === oldValue) {
      data.entitySearchReferenceEntity = undefined
    }
    if (
      WbsItemAdditionalPropertyType.ENTITY_SEARCH === oldValue ||
      WbsItemAdditionalPropertyType.ENTITY_SEARCH === newValue
    ) {
      // To refresh cell style.
      api.refreshCells({
        rowNodes: [node],
        columns: [entitySearchReferenceEntityColumnDef.field!],
        force: true,
      })
    }

    store.dispatch(requireSave())
    return true
  },
  cellRendererParams: {
    validate: (
      value: WbsItemAdditionalPropertyType | undefined,
      params: ICellRendererParams
    ): string | undefined => {
      if (!isAdditionalPropertyRow(params.data)) return
      if (!value) {
        return intl.formatMessage({
          id: 'boolExpression.requiredField',
        })
      }
      return undefined
    },
  },
  cellEditor: SelectCellEditor,
  cellEditorParams: {
    options: Object.keys(WbsItemAdditionalPropertyType).map(key => ({
      label: key,
      value: WbsItemAdditionalPropertyType[key],
    })),
  },
  filter: ClientSideSelectFilter,
  floatingFilter: true,
  filterParams: {
    valueGetter: (params: { node; context }) => {
      if (!params.node || isGroupHeaderRow(params.node.data)) {
        return undefined
      }
      const additionalPropertyRow = params.node.data as AdditionalPropertyRow
      return additionalPropertyRow.propertyType
    },
    getValue: o => o,
    getLabel: o => o,
    filterChangedCallback: () => {
      return
    },
  },
}
