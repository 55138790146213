import { useCallback } from 'react'
import { MonthlyWorkReportVO } from '../../../domain/value-object/WorkReportVO'
import { YearMonthVO } from '../../../domain/value-object/YearMonthVO'
import { useWorkReportRepositroy } from '../../../services/adaptors/workReportRepositoryAdaptor'

type GetWorkReport = {
  getMonthlyWorkReport: (
    projectUuid: string,
    yearMonth: YearMonthVO,
    teamUuid?: string
  ) => Promise<MonthlyWorkReportVO[]>
}

export const useGetWorkReport = (): GetWorkReport => {
  const workReportRepository = useWorkReportRepositroy()
  const getMonthlyWorkReport = useCallback(
    async (projectUuid: string, yearMonth: YearMonthVO, teamUuid?: string) =>
      workReportRepository.getMonthlyWorkReport(
        projectUuid,
        yearMonth,
        teamUuid
      ),
    []
  )
  return { getMonthlyWorkReport }
}
