import { ICellRendererParams } from 'ag-grid-community'
import React, { useEffect, useState } from 'react'
import { ICON_SIZE } from '../../../BulkSheet/const'
import { RootDiv } from '../../../commons/AgGrid/components/cell/CellRendererRoot'
import { CustomEnumValueDetail } from '../../../../../lib/functions/customEnumValue'
import { getLabel } from '../../../../../lib/commons/i18nLabel'
import { FloatingEditableIcon } from '../../../commons/AgGrid/components/cell/common/text'
import {
  CustomEnumValue,
  FunctionProperty,
} from '../../../../../lib/commons/appFunction'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { setError } from '../../lib/validation'

interface Props extends ICellRendererParams {
  customEnumCode?: string
  getOptionKey?: (data) => string
  valuesAllowed?: CustomEnumValue[]
  uiMeta?: FunctionProperty
}

export const MyWbsItemsCustomEnumCellRenderer = (props: Props) => {
  const {
    context,
    value,
    data,
    valueFormatted,
    customEnumCode,
    getOptionKey,
    valuesAllowed,
    uiMeta,
  } = props
  const [message, setMessage] = useState<string | undefined>()

  useEffect(() => {
    if (uiMeta?.requiredIf) {
      const result = uiMeta.requiredIf.check(value, () => ({}))
      const err = result.ok ? undefined : result.message
      setError(data, err, props)
      setMessage(err)
    }
  }, [value])

  let options: any
  if (customEnumCode) {
    options = context[customEnumCode]
    if (options.length !== 0 && getOptionKey) {
      options = options.get(getOptionKey(data))
    }
  } else if (valuesAllowed) {
    options = valuesAllowed
  }
  const option: CustomEnumValueDetail | undefined = options?.find(
    option => option.value === value
  )

  return (
    <Tooltip message={message}>
      <RootDiv style={{ backgroundColor: !!message ? '#FADBD8' : undefined }}>
        {option?.iconUrl && (
          <img
            src={option.iconUrl}
            style={{
              height: `${ICON_SIZE}px`,
              width: `${ICON_SIZE}px`,
              margin: '5px',
              borderRadius: '50%',
            }}
          />
        )}
        {option &&
          (valueFormatted ?? getLabel(option?.nameI18n) ?? option?.name)}
        <FloatingEditableIcon {...props} />
      </RootDiv>
    </Tooltip>
  )
}
