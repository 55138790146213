import Mousetrap from 'mousetrap'
import { KeyBindListener, KeyBindListeners } from '../view/model/keyBind'

const mousetrap = new Mousetrap()
mousetrap.stopCallback = () => false

type KeyBindListenersStack = KeyBindListeners[]

type State = {
  stack: KeyBindListenersStack
}

type ActionType = 'ADD_KEY_BIND_LISTENERS' | 'REMOVE_KEY_BIND_LISTENERS'

type Action = {
  type: ActionType
} & any

export const addKeyBindListeners = (listeners: KeyBindListeners) => ({
  type: 'ADD_KEY_BIND_LISTENERS',
  listeners,
})

export const removeKeyBindListeners = () => ({
  type: 'REMOVE_KEY_BIND_LISTENERS',
})

const bindListener = (listener: KeyBindListener) => {
  const { key, fn, stopDefaultBehavior } = listener
  if (!fn) return
  if (stopDefaultBehavior) {
    mousetrap.bind(key, (e: KeyboardEvent) => {
      e.stopImmediatePropagation && e.stopImmediatePropagation()
      e.preventDefault && e.preventDefault()
      e.stopPropagation && e.stopPropagation()
      fn()
    })
  } else {
    mousetrap.bind(key, fn)
  }
}

const updateKeyBindListeners = (stack: KeyBindListenersStack) => {
  mousetrap.reset()
  if (stack.length === 0) return
  const listeners = stack[0]
  listeners.forEach(listener => {
    bindListener(listener)
  })
}

export const reducer = (
  state: State = { stack: [] },
  action: Action
): State => {
  switch (action.type) {
    case 'ADD_KEY_BIND_LISTENERS':
      const added = [action.listeners, ...state.stack]
      updateKeyBindListeners(added)
      return { stack: added }
    case 'REMOVE_KEY_BIND_LISTENERS':
      const removed = state.stack.slice(1)
      updateKeyBindListeners(removed)
      return { stack: removed }
    default:
      return state
  }
}
