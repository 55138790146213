import { useCallback, useEffect } from 'react'
import _ from 'lodash'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { Cockpit, Directory } from '../../../../lib/commons/appFunction'
import { AllState } from '../../../../store'
import { connect } from 'react-redux'
import components from '../../../pages'
import {
  BackgroundColor,
  Color,
  FontWeight,
  TextColor,
} from '../../../../styles/commonStyles'
import { open } from '../../../router'
import { ProjectDetail } from '../../../../lib/functions/project'

type MenuProps = {
  directory: Directory
  project?: ProjectDetail
}

type OwnProps = {
  currentProject?: ProjectDetail
  directories: Directory[]
  openMenu: boolean
  anchorEl: any
}

type Props = OwnProps & MenuProps

const TenantDirectoryMenu = ({
  currentProject,
  directories,
  directory,
  project,
}: Props) => {
  useEffect(() => {
    const currentCockpit = currentProject ? Cockpit.Project : Cockpit.Tenant
    const directory = directories.find(v => v.name === currentCockpit)
    if (!directory) {
      return
    }
  }, [currentProject, directories])
  const nestedFunctions = directory.directories.map(d => d.functions).flat()

  const displayFunctions = [
    ...(directory.functions || []),
    ...nestedFunctions,
  ].filter(v => !!v && !v.hidden)

  const onClick = useCallback(
    (event, externalId: string) => {
      const component = components[externalId]
      if (!component) return
      const pathname =
        component.cockpit === Cockpit.Project && project
          ? `${component.defaultPath}/${project.code}`
          : component.defaultPath
      open(pathname, event)
    },
    [project]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        padding: '16px 16px 8px 16px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginBottom: '5px',
          padding: '0 3px 8px 3px',
          gap: '16px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: TextColor.BLACK,
            fontWeight: FontWeight.BOLD,
            margin: '0 3px',
          }}
        >
          {directory.name}
        </Typography>
      </Box>
      <List
        sx={{
          borderRadius: '4px',
          padding: '0px',
        }}
      >
        <>
          {displayFunctions.map((p, i) => (
            <ListItem
              sx={{
                color: Color.MONOTONE,
                '&:hover': {
                  color: TextColor.FOCUS_BULE,
                  backgroundColor: BackgroundColor.FOCUS_BULE,
                },
                padding: '6px 8px 6px 18px',
                borderRadius: '4px',
                gap: '4px',
              }}
              key={`menu-${p.externalId.replaceAll('.', '-')}-${i}`}
            >
              <ListItemButton
                sx={{
                  padding: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={event => onClick(event, p.externalId)}
                disabled={!components[p.externalId]}
              >
                <ListItemText primary={p.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </>
      </List>
    </Box>
  )
}

const mapStateToProps = (state: AllState) => ({
  currentProject: state.project.current,
  directories: state.appFunction.directories,
})

export default connect(mapStateToProps)(TenantDirectoryMenu)
