/* eslint-disable no-underscore-dangle */
import VOBase from '../base'

export default class EntitySearchVO extends VOBase {
  private readonly value: string
  private readonly name: string
  private readonly code?: string
  private readonly iconUrl?: string

  constructor(
    _value: string,
    _name: string,
    _code?: string,
    _iconUrl?: string
  ) {
    super()
    this.value = _value
    this.name = _name
    this.code = _code
    this.iconUrl = _iconUrl
  }
  public static of = (prop: {
    uuid: string
    name: string
    code: string
    displayName: string
    iconUrl?: string
  }): EntitySearchVO | undefined => {
    if (!prop) {
      return undefined
    }
    const { uuid, name, displayName, code, iconUrl } = prop
    const _name = displayName || name || ''
    return new EntitySearchVO(uuid, _name, code, iconUrl)
  }
  getValue = () => {
    return this.value
  }
  format = () => {
    return this.name
  }
  formatWithCode = () => {
    return this.code ? `[${this.code}] ${this.name}` : this.name
  }
  getIconUrl = () => {
    return this.iconUrl
  }
  serialize = () => this.value
  getJson = () => {
    return {
      uuid: this.value,
      name: this.name,
      iconUrl: this.iconUrl,
    }
  }
}
