import { useCallback, useMemo } from 'react'
import { WbsItemFormModel } from '../model'
import { StatusAndSubStatusRelation } from './wbsItemSelectOptions'

export const useWbsItemModelModifier = (
  onChange: <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
    path: K,
    value: T
  ) => void,
  { getStatusBySubStatus, getSubStatusByStatus }: StatusAndSubStatusRelation
) => {
  const onChangeDisplayName = useCallback(
    (value: WbsItemFormModel['displayName']) => {
      onChange('displayName', value)
    },
    [onChange]
  )
  const onChangeStatus = useCallback(
    (value: WbsItemFormModel['status']) => {
      onChange('status', value)
      onChange('substatus', getSubStatusByStatus(value)?.value)
    },
    [getSubStatusByStatus, onChange]
  )
  const onChangeSubStatus = useCallback(
    (value: WbsItemFormModel['substatus']) => {
      onChange('substatus', value)
      onChange('status', getStatusBySubStatus(value))
    },
    [getStatusBySubStatus, onChange]
  )
  const onChangeTags = useCallback(
    (value: WbsItemFormModel['tags']) => {
      onChange('tags', value)
    },
    [onChange]
  )
  const onChangeTeam = useCallback(
    (value: WbsItemFormModel['team']) => {
      onChange('team', value)
    },
    [onChange]
  )
  const onChangeAccountable = useCallback(
    (value: WbsItemFormModel['accountable']) => {
      onChange('accountable', value)
    },
    [onChange]
  )
  const onChangeResponsible = useCallback(
    (value: WbsItemFormModel['responsible']) => {
      onChange('responsible', value)
    },
    [onChange]
  )
  const onChangeAssignee = useCallback(
    (value: WbsItemFormModel['assignee']) => {
      onChange('assignee', value)
    },
    [onChange]
  )
  const onChangeWatchers = useCallback(
    (value: WbsItemFormModel['watchers']) => {
      onChange('watchers', value)
    },
    [onChange]
  )
  const onChangeDifficulty = useCallback(
    (value: WbsItemFormModel['difficulty']) => {
      onChange('difficulty', value)
    },
    [onChange]
  )
  const onChangePriority = useCallback(
    (value: WbsItemFormModel['priority']) => {
      onChange('priority', value)
    },
    [onChange]
  )
  const onChangeSprint = useCallback(
    (value: WbsItemFormModel['sprint']) => {
      onChange('sprint', value)
    },
    [onChange]
  )
  const onChangeScheduledDate = useCallback(
    (value: WbsItemFormModel['scheduledDate']) => {
      onChange('scheduledDate', value)
    },
    [onChange]
  )
  const onChangeActualDate = useCallback(
    (value: WbsItemFormModel['actualDate']) => {
      onChange('actualDate', value)
    },
    [onChange]
  )
  const onChangeDescription = useCallback(
    (value: WbsItemFormModel['description']) => {
      onChange('description', value)
    },
    [onChange]
  )
  const modifiers = useMemo(
    () => ({
      onChangeDisplayName,
      onChangeStatus,
      onChangeSubStatus,
      onChangeTags,
      onChangeTeam,
      onChangeAccountable,
      onChangeResponsible,
      onChangeAssignee,
      onChangeWatchers,
      onChangeDifficulty,
      onChangePriority,
      onChangeSprint,
      onChangeScheduledDate,
      onChangeActualDate,
      onChangeDescription,
    }),
    [
      onChangeAccountable,
      onChangeActualDate,
      onChangeAssignee,
      onChangeDescription,
      onChangeDifficulty,
      onChangeDisplayName,
      onChangePriority,
      onChangeResponsible,
      onChangeScheduledDate,
      onChangeSprint,
      onChangeStatus,
      onChangeSubStatus,
      onChangeTags,
      onChangeTeam,
      onChangeWatchers,
    ]
  )
  return modifiers
}
