import { useMemo } from 'react'
import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import { colorPalette } from '../../style/colorPallete'

export const TabPanelArea = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

export const Tabs = styled(MuiTabs)({})
export const Tab = styled(MuiTab)({
  minHeight: '32px',
  height: '32px',
})

export const TabMainArea = styled('div')({
  height: 'calc(100% - 32px)',
  width: '100%',
})
