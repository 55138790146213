import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import _ from 'lodash'
import { intl } from '../../../../../../i18n'
import { IDoesFilterPassParams } from 'ag-grid-community'
import {
  BulkSheetFilter,
  FilterSelect,
  FilterInput,
  FilterFooter,
} from '../common'
import { useTextFilter } from './useTextFilter'

export type TextFilter = {
  operator: TextFilterOperator
  value: string
}

export enum TextFilterOperator {
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  BLANK = 'BLANK',
  NOT_BLANK = 'NOT_BLANK',
}

export const TextFilterOperatorLabel = {
  [TextFilterOperator.CONTAINS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.contain',
  }),
  [TextFilterOperator.NOT_CONTAINS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.notContain',
  }),
  [TextFilterOperator.EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.equals',
  }),
  [TextFilterOperator.NOT_EQUALS]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.notEquals',
  }),
  [TextFilterOperator.STARTS_WITH]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.startsWith',
  }),
  [TextFilterOperator.ENDS_WITH]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.endsWith',
  }),
  [TextFilterOperator.BLANK]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.blank',
  }),
  [TextFilterOperator.NOT_BLANK]: intl.formatMessage({
    id: 'bulksheet.filter.textFilter.operator.notBlank',
  }),
}

interface Props {
  filterChangedCallback: () => void
  valueGetter: (params) => string
}

export const ClientSideTextFilter = forwardRef((props: Props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  // State
  const filter = useTextFilter()

  // Ag-grid custom filter
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.isAdded) return true
        const textValue = props.valueGetter(params)
        if (!textValue && filter.value) return false
        switch (filter.operator) {
          case TextFilterOperator.CONTAINS:
            return textValue.indexOf(filter.value) >= 0
          case TextFilterOperator.NOT_CONTAINS:
            return textValue.indexOf(filter.value) < 0
          case TextFilterOperator.EQUALS:
            return textValue === filter.value
          case TextFilterOperator.NOT_EQUALS:
            return textValue !== filter.value
          case TextFilterOperator.STARTS_WITH:
            return textValue.indexOf(filter.value) === 0
          case TextFilterOperator.ENDS_WITH:
            const index = textValue.lastIndexOf(filter.value)
            return (
              index >= 0 && index === textValue.length - filter.value.length
            )
          case TextFilterOperator.BLANK:
            return !textValue
          case TextFilterOperator.NOT_BLANK:
            return !!textValue
          default:
            // should never happen
            console.warn('invalid filter type ' + filter.operator)
            return false
        }
      },

      isFilterActive() {
        return filter.isActive()
      },

      getModel() {
        return filter.model()
      },

      setModel(model) {
        if (!model) {
          filter.reset()
          return
        }
        if (model.filterType) {
          filter.setOperator(model.type?.toUpperCase())
          filter.setValue(model.filter)
        } else {
          filter.setOperator(model.operator)
          filter.setValue(model.value)
        }
      },

      onFloatingFilterChanged(_, model: TextFilter) {
        filter.setOperator(model.operator)
        filter.setValue(model.value)
      },

      afterGuiAttached() {
        inputRef.current && inputRef.current.focus()
      },

      getModelAsString() {
        return filter.isActive()
          ? TextFilterOperatorLabel[filter.operator] +
              (filter.value ? ` : ${filter.value}` : '')
          : ''
      },
    }
  })

  useEffect(() => {
    props.filterChangedCallback()
  }, [filter.value, filter.operator])

  // Event
  const onOperatorChange = useCallback(v => filter.setOperator(v), [])
  const onTextChange = useCallback(v => filter.setValue(v), [])
  const clearFilter = useCallback(() => filter.reset(), [])

  return (
    <BulkSheetFilter>
      <FilterSelect
        variant="outlined"
        value={filter.operator}
        onChange={e => onOperatorChange(e.target.value as TextFilterOperator)}
        sx={{ width: '100%' }}
      >
        {Object.values(TextFilterOperator).map(v => (
          <option key={`text-filter-option-${v}`} value={v}>
            {TextFilterOperatorLabel[v]}
          </option>
        ))}
      </FilterSelect>
      <FilterInput
        inputRef={inputRef}
        value={filter.value}
        onChange={e => {
          onTextChange(e.target.value)
        }}
        placeholder={'Filter...'}
        sx={{ height: 22 }}
      />
      <FilterFooter loading={false} onClick={clearFilter} />
    </BulkSheetFilter>
  )
})
