import React from 'react'
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  Avatar,
  InputAdornment,
  MenuItem,
  Popper,
} from '@mui/material'
import { styled } from '@mui/system'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import EntitySearchVO from '../../../../../../vo/EntitySearchVO'
import { CellDef } from '../../../../meta/ViewMeta/SingleSheetViewMeta'
import { TableData } from '../..'
import TextInput from '../../../../../components/editors/input/TextInput'
import repositories from '../../../../meta/repositories'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import _ from 'lodash'
import { FontSize } from '../../../../../../styles/commonStyles'

const StyledAutocomplete = styled(Autocomplete)({
  width: '100%',
  height: '100%',
}) as typeof Autocomplete
const Option = styled(MenuItem)({
  fontSize: FontSize.MEDIUM,
  display: 'flex',
  padding: 5,
})
const Icon = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '0 5px',
})

interface EntitySearchCellProps extends CellPropsBase<EntitySearchVO> {}

interface EntitySearchCellState extends CellStateBase {
  options: EntitySearchVO[]
  inputValue: string
}

class EntitySearchCell extends DataCellBase<
  EntitySearchVO,
  EntitySearchCellProps,
  EntitySearchCellState
> {
  private readonly repository: any
  constructor(props) {
    super(props)
    this.repository = repositories[props.cellDef.uiMeta.referenceEntity]
    this.state = {
      options: [],
      inputValue: '',
    }
  }

  private search = async (inputValue: string) => {
    const uiMeta = this.props.cellDef.uiMeta
    const searchOptions = uiMeta.searchOptions.build(this.props.data)
    const options = await this.repository.search(
      inputValue,
      searchOptions,
      uiMeta
    )
    this.setState({ options: options.map(option => EntitySearchVO.of(option)) })
  }

  private searchOnInputChange = _.debounce(this.search, 300)

  private onFocus = event => {
    this.search(this.state.inputValue ? this.state.inputValue : '')
  }

  private onInputChange = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === 'input') {
      this.setState({ inputValue: value })
      this.searchOnInputChange(value)
    } else if (reason === 'reset') {
      this.setState({ inputValue: value })
    } else if (reason === 'clear') {
      this.searchOnInputChange('')
    }
  }

  validate = (value: EntitySearchVO) => {
    return undefined
  }

  onChange = (event: any, value: EntitySearchVO | null) => {
    this.props.onChange(value || undefined)
  }

  isDisabled = (cellDef: CellDef, data: TableData): boolean => {
    const editable = cellDef.editable
    let disable = false
    if (typeof editable === 'boolean') {
      disable = !editable
    } else {
      disable = editable(data)
    }
    return disable
  }

  renderOption = (props, option: EntitySearchVO) => (
    <Option {...props}>
      {this.repository.getHasIcon() && (
        <Icon variant="circular" src={option.getIconUrl()} />
      )}
      {option.formatWithCode()}
    </Option>
  )

  renderInput = (params: AutocompleteRenderInputParams) => {
    const startAdornment =
      this.repository.getHasIcon() && this.props.value ? (
        <InputAdornment position="start" style={{ marginRight: 0 }}>
          <Icon variant="circular" src={this.props.value.getIconUrl()} />
        </InputAdornment>
      ) : undefined
    const InputProps = {
      ...params.InputProps,
      disableUnderline: true,
      startAdornment: startAdornment,
    }
    return (
      <TextInput
        {...params}
        variant="standard"
        fullWidth={true}
        InputProps={InputProps}
        inputProps={{
          ...params.inputProps,
          style: {
            textOverflow: 'inherit',
          },
        }}
        placeholder={this.props.cellDef.uiMeta.placeHolder}
      />
    )
  }

  render() {
    const { value, data, cellDef } = this.props
    const disabled = this.isDisabled(cellDef, data)
    return (
      <StyledAutocomplete
        value={_.isEmpty(value) ? null : value}
        inputValue={this.state.inputValue}
        options={this.state.options}
        getOptionLabel={option => option.format() || ''}
        size="small"
        isOptionEqualToValue={(option: EntitySearchVO, value: EntitySearchVO) =>
          value && option.isEqual(value)
        }
        renderOption={this.renderOption}
        renderInput={this.renderInput}
        PopperComponent={props => {
          return <Popper {...props} placement="bottom-start" />
        }}
        popupIcon={<ExpandMoreIcon />}
        noOptionsText=""
        disabled={disabled}
        autoHighlight={true}
        onFocus={this.onFocus}
        onInputChange={this.onInputChange}
        onChange={this.onChange}
        filterOptions={x => x} // avoid using default autocomplete filter
      />
    )
  }
}

export default EntitySearchCell
