import { Template } from '../../view/containers/DescriptionTemplate/TemplateList'
import uiStates, { UiStateKey, UiStateScope } from '../../lib/commons/uiStates'

export async function getTemplates(scope: UiStateScope, projectUuid?: string) {
  const key =
    scope === UiStateScope.CrossProject
      ? UiStateKey.Template
      : `${UiStateKey.Template}-${projectUuid || ''}`
  const response = await uiStates.get({
    applicationFunctionUuid: '',
    key: key,
    scope: scope,
  })
  return response.json.value
    ? (JSON.parse(response.json.value) as Template[])
    : []
}
