import React, { useEffect } from 'react'
import { Color, IconColor } from '../../../../../styles/commonStyles'
import { ICellRendererParams } from 'ag-grid-community'
import { Comment, CommentSummary } from '../../../../../store/comments'
import RoundChatIcon from '../../../../components/icons/RoundChatIcon'
import ActionDial, {
  SpeedDialActionProps,
} from '../../../commons/AgGrid/components/cell/custom/detail/ActionDial'
import store, { AllState } from '../../../../../store'
import { closeComment, openComment } from '../../../../../store/information'
import { connect } from 'react-redux'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../../Comment/CommentHeaderWbsItem'
import { getWbsItemFunctionUuid } from '../../../../../lib/functions/wbsItem'
import { intl } from '../../../../../i18n'
import { fromSnakeToCamel } from '../../../../../utils/string'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import { List, Map } from 'immutable'

type StateProps = {
  informationIsOpen: boolean
  currentDataUuid?: string
  comments: Map<string, List<Comment>>
}

const mapStateToProps = (state: AllState) => ({
  informationIsOpen: state.information.open,
  currentDataUuid: state.information.commentProps?.dataUuid,
  comments: state.comments.comments,
})

interface Props extends StateProps, ICellRendererParams {
  getCommentSummary: (row) => CommentSummary | undefined
  getWbsItem: (row) => any
  addRowToChild?: (type: WbsItemTypeVO, parentUuid: string) => void
}

export const ProjectPlanActionCellRenderer = connect(mapStateToProps)(
  ({
    node,
    data,
    informationIsOpen,
    currentDataUuid,
    comments,
    getCommentSummary,
    getWbsItem,
    addRowToChild,
  }: Props) => {
    const commentSummary = getCommentSummary(data)
    const wbsItem = getWbsItem(data)
    const commentOpened = !!currentDataUuid && currentDataUuid === wbsItem?.uuid
    const commentsInThisWbsItem = wbsItem
      ? comments.get(getWbsItemFunctionUuid() + '-' + wbsItem.uuid)
      : undefined

    const hasComment =
      commentSummary?.hasComment ||
      (commentsInThisWbsItem && commentsInThisWbsItem.size > 0)

    return (
      <div className="sevend-ag-cell-detail">
        <div>
          <RoundChatIcon
            className="sevend-ag-cell-detail__chat-icon"
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              if (commentOpened) {
                store.dispatch(closeComment())
              } else {
                store.dispatch(
                  openComment({
                    applicationFunctionUuid: getWbsItemFunctionUuid()!,
                    dataUuid: wbsItem?.uuid,
                    projectUuid: wbsItem?.projectUuid,
                    headerComponents: [
                      <CommentHeaderWbsItem
                        key={1}
                        wbsItem={mapRowDataForCommentHeader(wbsItem)}
                      />,
                    ],
                  })
                )
              }
            }}
            style={{
              color: commentOpened ? Color.MAIN : IconColor.GREY,
              fill: commentOpened ? Color.MAIN : 'none',
            }}
          />
          {hasComment && (
            <div className="sevend-ag-cell-detail__annotation-has-comment-mark" />
          )}
        </div>
        {addRowToChild && (
          <ActionDial
            node={node}
            getSpeedDialActions={(): SpeedDialActionProps[] => {
              if (!wbsItem) return []
              const types = store.getState().project.wbsItemTypes
              return [
                types.task,
                types.deliverable,
                types.deliverableList,
                types.process,
              ]
                .filter(type => type.canBeChildOf(wbsItem.wbsItemType))
                .map(type => ({
                  icon: <img src={type.iconUrl} />,
                  tooltipTitle: intl.formatMessage({
                    id: `bulksheet.speedDial.add.${fromSnakeToCamel(
                      type.code
                    )}`,
                  }),
                  onClick: () => addRowToChild(type, data.uuid),
                }))
            }}
          />
        )}
      </div>
    )
  }
)
