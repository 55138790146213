import { useMemo } from 'react'
import { AllState } from '../../../../store'
import { useSelector } from 'react-redux'
import { OrganizationDetail } from '../../../../lib/functions/tenant'
import { isProduction } from '../../../../utils/urls'

const TENANT_ALIAS_TO_USE_TIMER = 'horizonone'

export const useTmpFeatureFlag = () => {
  const tenant = useSelector<AllState, OrganizationDetail>(
    state => state.tenant.organization!
  )

  const featureFlag = useMemo(
    () =>
      !isProduction ||
      (isProduction && tenant.alias === TENANT_ALIAS_TO_USE_TIMER),
    [tenant]
  )

  return featureFlag
}
