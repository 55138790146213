import { PropsWithChildren, memo, useMemo } from 'react'
import {
  BoundDimensions,
  MarginDimensions,
  TwoDimensionalPoint,
  WrapperDimensions,
} from '../../model/chart'
import * as d3 from 'd3'
import { colorPalette } from '../../../../style/colorPallete'

type ChartContainerProps = PropsWithChildren<
  WrapperDimensions & Pick<MarginDimensions, 'marginLeft' | 'marginTop'>
>

export const ChartContainer = memo(
  ({ width, height, marginLeft, marginTop, children }: ChartContainerProps) => {
    return (
      <svg width={width} height={height}>
        <g transform={`translate(${[marginLeft, marginTop].join(',')})`}>
          {children}
        </g>
      </svg>
    )
  }
)

type AxisPoint = {
  scale: number
  label: string
  emphasis?: boolean
}

type XAxisProps = {
  points: AxisPoint[]
} & BoundDimensions

export const XAxis = ({ points, boundedHeight, boundedWidth }: XAxisProps) => {
  return (
    <g
      transform={`translate(${[0, boundedHeight].join(',')})`}
      color={colorPalette.monotone[3]}
    >
      <path
        d={['M', 0, 6, 'v', -6, 'H', boundedWidth, 'v', 6].join(' ')}
        fill="none"
        stroke={'currentColor'}
      />
      {points.map((p, i) => (
        <g key={i} transform={`translate(${[p.scale, 0].join(',')})`}>
          <line y2={6} stroke="currentColor" />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'middle',
              transform: 'translateY(20px)',
              fill: colorPalette.monotone[9],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type XAxisWithLineProps = XAxisProps & {}

export const XAxisWithLine = ({
  points,
  boundedHeight,
  boundedWidth,
}: XAxisWithLineProps) => {
  return (
    <g
      transform={`translate(${[0, boundedHeight].join(',')})`}
      color={colorPalette.monotone[3]}
    >
      <path
        d={['M', 0, 6, 'v', -6, 'H', boundedWidth, 'v', 6].join(' ')}
        fill="none"
        stroke={'currentColor'}
      />
      {points.map((p, i) => (
        <g key={i} transform={`translate(${[p.scale, 0].join(',')})`}>
          <line y2={6} stroke="currentColor" />
          <line
            y2={-boundedHeight}
            stroke={
              p.emphasis ? colorPalette.pink[5] : colorPalette.monotone[2]
            }
          />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'middle',
              transform: 'translateY(20px)',
              fill: colorPalette.monotone[9],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type YAxisProps = {
  points: AxisPoint[]
}

export const YAxis = ({ points }: YAxisProps) => {
  return (
    <g>
      {points.map((p, i) => (
        <g
          key={i}
          transform={`translate(0,${p.scale})`}
          color={colorPalette.monotone[3]}
        >
          <line x2="-6" stroke="currentColor" />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'end',
              transform: 'translate(-8px, 3px)',
              fill: colorPalette.monotone[9],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type YAxisWithLineProps = YAxisProps & Pick<BoundDimensions, 'boundedWidth'>

export const YAxisWithLine = ({ points, boundedWidth }: YAxisWithLineProps) => {
  return (
    <g>
      {points.map((p, i) => (
        <g
          key={i}
          transform={`translate(0,${p.scale})`}
          color={colorPalette.monotone[3]}
        >
          <line x2="-6" stroke="currentColor" />
          <line
            x2={boundedWidth}
            stroke={
              p.emphasis ? colorPalette.pink[5] : colorPalette.monotone[2]
            }
          />
          <text
            style={{
              fontSize: '10px',
              textAnchor: 'end',
              transform: 'translate(-8px, 3px)',
              fill: colorPalette.monotone[9],
            }}
          >
            {p.label}
          </text>
        </g>
      ))}
    </g>
  )
}

type TodayVerticalLineProps = {
  xScale: (x: Date) => number
  boundedHeight: number
}

export const TodayVerticalLine = ({
  xScale,
  boundedHeight,
}: TodayVerticalLineProps) => {
  const now = useMemo(() => new Date(), [])
  const { x1, x2 } = useMemo(() => {
    return {
      x1: xScale(now),
      x2: xScale(now),
    }
  }, [now, xScale])
  return (
    <line
      x1={x1}
      x2={x2}
      y1={boundedHeight}
      y2={0}
      stroke={colorPalette.pink[5]}
      strokeWidth="1px"
    />
  )
}
