import { DailyWorkloadData } from '../../../../domain/value-object/BurndownChart'
import {
  WbsPivotAggregationDomain,
  WbsPivotAggregationDomainType,
} from '../../../../domain/value-object/WbsPivot'
import { TwoDimensionalPoint } from '../../../components/charts/model/chart'
import { DateBucket } from '../../../components/charts/model/timeSeries'

export type TeamSelectOption = {
  value: string | undefined
  label: string
  iconUrl?: string
}

export type DateTerm = {
  from: Date
  to: Date
}
export type BurndownChartData = {
  // Schedule
  scheduleDataBurnDown: TwoDimensionalPoint<DateBucket, number>[]
  scheduleData: TwoDimensionalPoint<DateBucket, number>[]
  resourceData: TwoDimensionalPoint<DateBucket, number>[]
  scheduleVelocityData: TwoDimensionalPoint<DateBucket, number>[]
  // Actual
  actualAndProspectDataBurnDown: TwoDimensionalPoint<DateBucket, number>[]
  actualAndProspectData: TwoDimensionalPoint<DateBucket, number>[]
  actualCostData: TwoDimensionalPoint<DateBucket, number>[]
  actualAndProspectVelocityData: TwoDimensionalPoint<DateBucket, number>[]
  // Simulated
  simulatedDataBurnDown: TwoDimensionalPoint<DateBucket, number>[]
  simulatedData: TwoDimensionalPoint<DateBucket, number>[]
  simulatedResourceData: TwoDimensionalPoint<DateBucket, number>[]
  simulatedVelocityData: TwoDimensionalPoint<DateBucket, number>[]
  // Ideal
  idealDataBurnDown: TwoDimensionalPoint<DateBucket, number>[]
  idealData: TwoDimensionalPoint<DateBucket, number>[]
}

export type BurndownChartSummary = {
  total: number
  planned: number
  completed: number
  delayed: number
  preceding: number
  unplanned: number
  remaining: number
  plannedProgressRate: number
  progressRate: number
  actualCost: number
}

export type BurndownChartResourceSummary = {
  velocity: number
  totalResource: number
  devotedResource: number
  remainingResource: number
  resourceDevotionRate: number
}

export const EMPTY_SUMMARY: BurndownChartSummary = {
  total: 0,
  planned: 0,
  completed: 0,
  delayed: 0,
  preceding: 0,
  unplanned: 0,
  remaining: 0,
  plannedProgressRate: 0,
  progressRate: 0,
  actualCost: 0,
}

export const EMPTY_RESOURCE_SUMMARY: BurndownChartResourceSummary = {
  velocity: 0,
  totalResource: 0,
  devotedResource: 0,
  remainingResource: 0,
  resourceDevotionRate: 0,
}

export type BurndownChartResourceData = {
  data: DailyWorkloadData[]
  sumBefore: number
}
export type BurndownChartCalendar = {
  data: DailyWorkloadData[]
  sumBefore: number
  sumAfter: number
}

export type BurndownChartSimulationVariables = {
  prospectVelocity: number
  prospectResourceRate: number
  simulatedVelocity: number
  simulatedResourceRate: number
  simulatedSimulationFromDate: Date | undefined
  idealSimulationFromDate: Date | undefined
  idealSimulationToDate: Date | undefined
}

export type BurndownChartCondition = {
  projectUuid: string
  domain: WbsPivotAggregationDomain | undefined
  wbsItemType: 'DELIVERABLE' | 'TASK' | undefined
  ticketTypeUuid: string | undefined
  teamUuid: string | undefined
}
