import * as d3 from 'd3'
import { styled } from '@mui/material'
import { ProjectDetail } from '../../../../../lib/functions/project'
import {
  WidgetArea,
  WidgetTitle,
  WidgetWithTitleWrapper,
} from '../../components'
import { useGetProductivity } from '../../../../../applications/usecases/productivity/getProductivity'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DailyCpiVO } from '../../../../../domain/value-object/EvmVO'
import { useChartDimensions } from '../../hooks/chart/useChartDimensions'
import { useDateXTicks } from '../../hooks/chart/useXTicks'
import { TwoDimensionalPoint } from '../../model/chart'
import { ChartContainer, XAxis, YAxisWithLine } from '../../components/chart'
import { colorPalette } from '../../../../style/colorPallete'
import { intl } from '../../../../../i18n'
import { LineChart } from '../../components/chart/LineChart'
import { AggregateRoot } from '../../hooks/useMasterScheduleParameter'
import { ProjectReportConfig } from '../../model/config'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'

type CpiTimeSeriesProps = {
  project: ProjectDetail
  root?: AggregateRoot
  teamUuid?: string
  onCpiSelected: (cpi: number) => void
} & Pick<ProjectReportConfig, 'aggregateTarget'>

const CpiTimeSeriesRoot = styled(WidgetArea)({
  width: '100%',
})

type DailyCpi = {
  date: Date
  ev: number
  ac: number
}

export const CpiTimeSeries = ({
  project,
  root,
  teamUuid,
  onCpiSelected,
  aggregateTarget,
}: CpiTimeSeriesProps) => {
  const [
    ref,
    { width, height, marginTop, marginLeft, boundedWidth, boundedHeight },
  ] = useChartDimensions({
    height: 300,
    marginLeft: 30,
    marginRight: 20,
    marginTop: 10,
    marginBottom: 30,
  })

  const { from, to } = useMemo(() => {
    return {
      from: new Date(
        root?.scheduledDate.startDate || project.scheduledDate.startDate
      ),
      to: new Date(
        root?.scheduledDate.endDate || project.scheduledDate.endDate
      ),
    }
  }, [project, root])
  const { xTicks } = useDateXTicks(from, to, boundedWidth, 30)

  const { getDailyCpi } = useGetProductivity()
  const [fetchedData, setFetchedData] = useState<DailyCpiVO[]>([])
  useEffect(() => {
    const fn = async () => {
      const dailyCpi = await getDailyCpi(project.uuid, root?.uuid, teamUuid)
      setFetchedData(dailyCpi)
    }
    fn()
  }, [project.uuid, root?.uuid, teamUuid])
  const data = useMemo(
    () =>
      fetchedData.map(d => ({
        date: d.date,
        ev:
          aggregateTarget === WbsItemType.DELIVERABLE
            ? d.ev.deliverable
            : d.ev.task,
        ac: d.ac,
      })),
    [fetchedData, aggregateTarget]
  )
  // const [data, setData] = useState<DailyCpi[]>([])

  const points = useMemo(() => {
    const points: TwoDimensionalPoint<Date, number>[] = []
    for (let i = 0; i < xTicks.length; i++) {
      const x = xTicks[i]
      const nextX =
        i + 1 < xTicks.length ? xTicks[i + 1] : new Date(9999, 11, 31)
      const { ev, ac } = data
        .filter(d => d.date >= x && d.date < nextX)
        .reduce(
          (sum, curr) => ({ ev: sum.ev + curr.ev, ac: sum.ac + curr.ac }),
          { ev: 0, ac: 0 }
        )
      if (!ac) continue
      points.push({ x, y: ev / ac })
    }
    return points
  }, [data, xTicks])

  const xScale = useMemo(
    () =>
      d3
        .scaleTime()
        .domain([xTicks[0], xTicks[xTicks.length - 1]])
        .range([0, boundedWidth]),
    [boundedWidth, xTicks]
  )

  const { yScale, yTicks } = useMemo(() => {
    const maxY = Math.max(...points.map(p => p.y))
    const yScale = d3.scaleLinear().domain([0, maxY]).range([boundedHeight, 0])
    return {
      yScale,
      yTicks: yScale.ticks(6),
    }
  }, [boundedHeight, points])

  const xAxisPoints = useMemo(
    () =>
      xTicks.map(x => ({
        scale: xScale(x),
        label: `${x.getMonth() + 1}/${x.getDate()}`,
      })),
    [xScale, xTicks]
  )
  const yAxisPoints = useMemo(
    () =>
      yTicks.map(y => ({
        scale: yScale(y),
        label: `${(y * 100).toFixed(0)}`,
      })),
    [yScale, yTicks]
  )

  const onPointClicked = useCallback(
    (point: TwoDimensionalPoint<Date, number>) => {
      onCpiSelected(point.y)
    },
    [onCpiSelected]
  )

  return (
    <WidgetWithTitleWrapper>
      <WidgetTitle>
        {intl.formatMessage({
          id: 'resourceDashboard.productivity.cpiTimeSeries',
        })}
      </WidgetTitle>
      <CpiTimeSeriesRoot>
        <div ref={ref} style={{ height: '300px', width: '100%' }}>
          <ChartContainer
            width={width}
            height={height}
            marginLeft={marginLeft}
            marginTop={marginTop}
          >
            <XAxis
              points={xAxisPoints}
              boundedHeight={boundedHeight}
              boundedWidth={boundedWidth}
            />
            <YAxisWithLine points={yAxisPoints} boundedWidth={boundedWidth} />
            <LineChart<Date, number>
              xScale={xScale}
              yScale={yScale}
              points={points}
              color={colorPalette.skyBlue[7]}
              onPointClicked={onPointClicked}
            />
          </ChartContainer>
        </div>
      </CpiTimeSeriesRoot>
    </WidgetWithTitleWrapper>
  )
}
