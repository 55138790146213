import React, { useLayoutEffect, useCallback } from 'react'
import { createBrowserHistory } from 'history'
import { Router as ReactRouter, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { logoutFromProject } from '../../store/project'
import { clearBreadcrumbs } from '../../store/breadcrumb'
import { closeSideMenu } from '../../store/sideMenu'
import { doNotRequireSave } from '../../store/requiredSaveData'
import { selectFunction } from '../../store/appFunction'
import { deleteScreenMessages } from '../../store/messages'
import { State } from '../../store/globalAlert'
import { Cockpit } from '../../lib/commons/appFunction'
import { generateUuid } from '../../utils/uuids'
import {
  clearHeaderComponents,
  fromUrlToLayer,
  replaceOrPushFunctionLayer,
} from '../../store/functionLayer'
import { closeComment } from '../../store/information'
import Auth from '../../lib/commons/auth'
import { AllState } from '../../store'
import usePrevious from '../hooks/usePrevious'
import { getFunctionByPath } from '../pages'

export const history = createBrowserHistory()

export const needToOpenInNewTab = (
  event?: Event | MouseEvent | React.MouseEvent | null
) => {
  return event && (event['ctrlKey'] || event['metaKey'])
}
export const open = (
  path: string,
  event?: Event | MouseEvent | React.MouseEvent | null,
  title?: string,
  openInNewTab?: boolean
) => {
  if ((event && (event['ctrlKey'] || event['metaKey'])) || openInNewTab) {
    window.open(`${window.location.origin}${path}`, title)
  } else {
    const isSamePath = window.location.pathname === path
    let locationState = history.location.state as Record<string, any>
    if (isSamePath) {
      const forceRefreshKey = generateUuid()
      locationState = {
        ...locationState,
        forceRefreshKey,
      }
    }
    history.push(path, locationState)
  }
}

export const Router = ({
  history,
  children,
}: {
  history: ReturnType<typeof useHistory>
  children: React.ReactNode
}) => {
  const dispatch = useDispatch()
  const globalAlert = useSelector<AllState, State>(state => state.globalAlert)
  const prevLocation = usePrevious(history.location)
  const onChangeLocation = useCallback(
    async nextLocation => {
      // Delete screen message.
      const currentFn = prevLocation
        ? getFunctionByPath(prevLocation.pathname)
        : undefined
      const fn = getFunctionByPath(nextLocation.pathname)
      dispatch(clearHeaderComponents())
      dispatch(closeComment())
      const layer = fromUrlToLayer()
      if (layer) {
        dispatch(replaceOrPushFunctionLayer(layer))
      }
      if (fn) {
        const tenant = Auth.getCurrentTenant()
        if (!tenant) {
          return history.push(`/login?redirect_uri=${window.location.href}`)
        } else if (!(await tenant.isLoggedin())) {
          return history.push(`/?redirect_uri=${window.location.href}`)
        }
        if (currentFn?.cockpit !== fn.cockpit) {
          if (fn.cockpit === Cockpit.Tenant) {
            // Logged out from project already if error messages exists
            if (!globalAlert.alertAction) {
              dispatch(logoutFromProject())
            }
          }
        }
        if (!fn.breadcrumbs) {
          dispatch(clearBreadcrumbs())
        }
        if (currentFn !== fn) {
          if (fn.hideSideMenu) {
            dispatch(closeSideMenu())
          }
          dispatch(doNotRequireSave())
          dispatch(selectFunction(fn.externalId))
          return deleteScreenMessages(fn.externalId)
        }
      }
    },
    [dispatch, globalAlert.alertAction, history, prevLocation]
  )

  useLayoutEffect(() => {
    // For the initial page.
    onChangeLocation(history.location)
  }, [])
  useLayoutEffect(
    () => history.listen(onChangeLocation),
    [history, onChangeLocation]
  )

  return <ReactRouter history={history} children={children} />
}
