import { VO } from '.'
import {
  Component,
  FunctionProperty,
  PropertyType,
} from '../lib/commons/appFunction'
import EntitySearchVO from './EntitySearchVO'
import SelectVO from './SelectVO'
import TextVO from './TextVO'
import NumberVO from './NumberVO'
import DateVO from './DateVO'
import DateRangeVO from './DateRangeVO'
import DateTimeVO from './DateTimeVO'
import DateTimeRangeVO from './DateTimeRangeVO'
import SwitchVO from './SwitchVO'
import IconVO from './IconVO'
import WorkloadVO from './WorkloadVO'
import MultiSelectVO from './MultiSelectVO'
import MultiEntitySearchVO from './MultiEntitySearchVO'
import MultiLineTextVO from './MultiLineTextVO'
import store from '../store'
import TagVO from './TagVO'
import SlackUserVO from './Slack/UserVO'

const create = (value: any, prop: FunctionProperty): VO | undefined => {
  switch (prop.propertyType) {
    case PropertyType.Text:
      return new TextVO(value)
    case PropertyType.Number:
      return new NumberVO(value)
    case PropertyType.Date:
      return new DateVO(value)
    case PropertyType.DateRange:
      return new DateRangeVO(value)
    case PropertyType.DateTime:
      return new DateTimeVO(value)
    case PropertyType.DateTimeRange:
      return new DateTimeRangeVO(value)
    case PropertyType.Select:
      return SelectVO.of(value, prop.valuesAllowed || [])
    case PropertyType.MultiSelect:
      return new MultiSelectVO(value)
    case PropertyType.EntitySearch:
      return EntitySearchVO.of(value)
    case PropertyType.Switch:
    case PropertyType.Checkbox:
      return new SwitchVO(value)
    case PropertyType.Icon:
      return new IconVO(value)
    case PropertyType.MultiEntitySearch:
      return new MultiEntitySearchVO(value)
    case PropertyType.MultiLineText:
      return new MultiLineTextVO(value || '')
    case PropertyType.Custom: {
      if (prop.component === Component.Workload) {
        const organization = store.getState().tenant.organization
        return new WorkloadVO(value, {
          daylyWorkingHours: organization?.dailyWorkHours!,
          monthlyWorkingDays: organization?.monthlyWorkDays!,
        })
      }
      if (prop.component === Component.TicketType) {
        return value
      }
      if (prop.component === Component.Sprint) {
        return EntitySearchVO.of(
          Array.isArray(value) ? value.sort((a, b) => b.num - a.num)[0] : value
        )
      }
      if (prop.component === Component.Tag) {
        return new TagVO(value)
      }
      if (prop.component === Component.SlackUser) {
        return new SlackUserVO(value)
      }
      return undefined
    }
    default:
      return undefined
  }
}

export default { create }
