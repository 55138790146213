import { StoredWbsItemSearchCondition } from '../model/storedWbsItemSearchCondition'
import { WbsItemStatus, WbsItemType } from '../../domain/entity/WbsItemEntity'
import { BlankCondition } from '../../domain/value-object/WbsItemSearchConditionVo'
import objects from '../../utils/objects'

type StoredWbsItemSearchConditionBefore20230125 = {
  searchText?: string
  searchFilter?: {
    code?: string
    types?: WbsItemType[]
    ticketTypes?: string[]
    status?: WbsItemStatus[]
    priority?: { value: string }[]
    displayName?: string
    description?: string
    team?: { uuid: string }
    accountable?: { uuid: string }
    responsible?: { uuid: string }
    assignee?: { uuid: string }
    deliverableUuid?: string
    scheduledStartDate?: { from?: string; to?: string }
    scheduledEndDate?: { from?: string; to?: string }
    actualStartDate?: { from?: string; to?: string }
    actualEndDate?: { from?: string; to?: string }
    blank?: BlankCondition
    createdAt?: { from?: string; to?: string }
    updatedAt?: { from?: string; to?: string }
    createdByUuid?: string
    updatedByUuid?: string
  }
}

export const adaptSearchConditionBefore20230125 = (
  src: StoredWbsItemSearchConditionBefore20230125 | StoredWbsItemSearchCondition
): StoredWbsItemSearchCondition => {
  if (!('searchFilter' in src)) {
    // Before this fix, rootUuid was stored, so remove it from condition when restore.
    const dest = {}
    for (let [key, value] of Object.entries(src)) {
      if (key === 'rootUuid') continue
      objects.setValue(dest, key, value)
    }
    return dest
  }
  return {
    searchText: src.searchText,
    code: src.searchFilter?.code,
    types: src.searchFilter?.types,
    ticketTypes: src.searchFilter?.ticketTypes,
    status: src.searchFilter?.status,
    priority:
      src.searchFilter?.priority &&
      src.searchFilter?.priority.map(p => p.value),
    displayName: src.searchFilter?.displayName,
    description: src.searchFilter?.description,
    teamUuid: src.searchFilter?.team?.uuid,
    accountableUuid: src.searchFilter?.accountable?.uuid,
    responsibleUuid: src.searchFilter?.responsible?.uuid,
    assigneeUuid: src.searchFilter?.assignee?.uuid,
    deliverableUuid: src.searchFilter?.deliverableUuid,
    scheduledStartDate: src.searchFilter?.scheduledStartDate,
    scheduledEndDate: src.searchFilter?.scheduledEndDate,
    actualStartDate: src.searchFilter?.actualStartDate,
    actualEndDate: src.searchFilter?.actualEndDate,
    blank: src.searchFilter?.blank,
    createdAt: src.searchFilter?.createdAt,
    updatedAt: src.searchFilter?.updatedAt,
    createdByUuid: src.searchFilter?.createdByUuid,
    updatedByUuid: src.searchFilter?.updatedByUuid,
  }
}
