import { useCallback, useEffect, useState } from 'react'
import { useProjectPlanSnapshotRepository } from '../../../../../../services/adaptors/projectPlanSnapshotRepositoryAdaptor'
import { MasterScheduleRowData } from '../model'
import { useProjectPrivateContext } from '../../../../../context/projectContext'
import { ProjectPlanSnapshot } from '../../../../../../domain/value-object/ProjectPlanSnapshot'
import { AgGridTreeHelper } from '../../../../../containers/BulkSheetView/lib/tree'
import { WbsItemTypeVO } from '../../../../../../domain/value-object/WbsItemTypeVO'

export const useMasterScheduleData = (
  projectUuid: string,
  snapshotUuid: string,
  leafUuids: string[]
) => {
  const [rows, setRows] = useState<MasterScheduleRowData[]>([])
  const converter = useRowDataConverter()
  const { fetchByLeafUuid } = useProjectPlanSnapshotRepository()
  useEffect(() => {
    const fn = async () => {
      const response = await fetchByLeafUuid(snapshotUuid, leafUuids)
      setRows(response.map(resp => converter(resp)))
    }
    fn()
  }, [converter, fetchByLeafUuid, leafUuids, projectUuid, snapshotUuid])
  return {
    rows,
  }
}

const useRowDataConverter = () => {
  const { wbsItemTypes } = useProjectPrivateContext()
  const converter = useCallback(
    (src: ProjectPlanSnapshot): MasterScheduleRowData => {
      return {
        uuid: src.uuid,
        type: src.type,
        treeValue: src.path,
        wbsItem: {
          ...src.wbsItem,
          type: src.type,
          wbsItemType: wbsItemTypes.getAll().find(v => v.rootType === src.type),
        },
        progressValues: {
          scheduledProgressRate:
            src.progressValue.planned.count.task /
            src.progressValue.total.count.task,
          progressRate:
            src.progressValue.completed.count.task /
            src.progressValue.total.count.task,
        },
      }
    },
    [wbsItemTypes]
  )
  return converter
}
