import { connect, useDispatch } from 'react-redux'
import { ColDef, ColumnState, RowNode } from 'ag-grid-community'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PageArea, PageProps } from '..'
import { TicketDetail } from '../../../lib/functions/ticket'
import { WorkloadUnit } from '../../../lib/functions/workload'
import { AllState } from '../../../store'
import {
  storedUiStateWithToolbarToggleAdaptor,
  ToolbarToggleValue,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import BulkSheetComponent, {
  BulkSheet,
  BulkSheetSpecificProps,
  ROW_HEIGHT,
} from '../../containers/BulkSheet'
import TicketOptions, {
  ColumnQuickFilterKey,
  TicketRow,
  TicketState,
} from './TicketsOptions'
import { Box } from '@mui/material'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { TicketListDetail } from '../../../lib/functions/ticketList'
import { AggregateField } from '../../../domain/entity/WbsItemEntity'
import { usePageState } from '../../hooks/usePageState'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import { Function } from '../../../lib/commons/appFunction'
import { pageComponent } from '../../higher-order-components/pageComponent'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { receivedTags } from '../../../store/tag'
import { fetchTags } from '../../../lib/functions/tag'
import TicketsWbsHeader from './Header/TicketsWbsHeader'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import TicketsReport from './Header/TicketsReport'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { TicketListSelector } from './Header/TicketListSelector'
import { FunctionLayer } from '../../../store/functionLayer'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'

type TicketsPageState = {
  toolbar: ToolbarToggleValue | undefined
  aggregateType: AggregateField
  workloadUnit: WorkloadUnit
  dateFormat: string
  isReportOpen: boolean
}

const ticketsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  aggregateType: AggregateField.WBS_ITEM_WORKLOAD,
  workloadUnit: WorkloadUnit.DAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  isReportOpen: false,
}

type StateProps = {
  functions: Function[]
  functionLayers: Immutable.Map<number, FunctionLayer>
}

const Tickets = (props: PageProps & StateProps) => {
  const { project } = useProjectPrivateContext()
  const fn = useMemo(
    () => props.functions.find(v => v.externalId === props.externalId)!,
    [props.functions, props.externalId]
  )
  const ref = useRef<HTMLDivElement>(null)

  const [bulkSheet, setBulkSheet] =
    useState<
      BulkSheet<BulkSheetSpecificProps, TicketDetail, TicketRow, TicketState>
    >()
  const ticketOptions = useMemo(() => {
    const options = new TicketOptions()
    options.onColumnVisible = () =>
      setCurrentColumnState(bulkSheet?.columnApi?.getColumnState() ?? [])
    return options
  }, [bulkSheet])
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [rowHeight, setRowHeight] = useState<number>(ROW_HEIGHT.SMALL)
  const [ticketType, setTicketType] = useState<string>()
  const [ticketListUuid, setTicketListUuid] = useState<string>()
  const [rowNodes, setRowNodes] = useState<RowNode[]>([])
  const {
    workloadUnit,
    aggregateType,
    dateFormat,
    isReportOpen,
    updatePageState,
  } = usePageState<TicketsPageState>(
    props.uuid,
    ticketsDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )

  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const [ticketList, setTicketList] = useState<TicketListDetail | undefined>(
    undefined
  )
  const columnSetting = useColumnSetting()
  const [currentColumnState, setCurrentColumnState] = useState<ColumnState[]>(
    []
  )

  const onChangeDateFormat = useCallback((value: string) => {
    updatePageState({ dateFormat: value })
  }, [])
  const onChangeAggregateType = useCallback((value: AggregateField) => {
    updatePageState({ aggregateType: value })
  }, [])
  const onChangeWorkloadUnit = useCallback((value: WorkloadUnit) => {
    updatePageState({ workloadUnit: value })
  }, [])
  const onChangeReportOpen = useCallback((value: boolean) => {
    updatePageState({ isReportOpen: value })
  }, [])

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (!bulkSheet) return
      if (value === ColumnQuickFilterKey.INITIAL) {
        bulkSheet.resetColumnAndFilterState()
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        bulkSheet.openSavedBulkSheetUIStateDialog(
          UiStateKey.BulkSheetUIStateColumnAndFilter
        )
      }
    },
    [bulkSheet]
  )

  const resetFilters = useCallback(() => {
    if (!bulkSheet?.gridApi) return
    bulkSheet?.gridApi.setFilterModel([])
  }, [bulkSheet?.gridApi])

  const onDeleteSortedColumn = useCallback(
    (colId: string | ColDef<any>) => {
      bulkSheet?.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort: null }],
      })
    },
    [bulkSheet?.columnApi]
  )

  const onReload = useCallback(() => bulkSheet?.onCancel(), [bulkSheet])

  const onChangeTicketType = useCallback(
    async (value?: string) => {
      setTicketType(value)
      if (bulkSheet) {
        const lastTicketListUuid =
          value &&
          bulkSheet.state.lastTicketListUuidMap &&
          value in bulkSheet.state.lastTicketListUuidMap
            ? bulkSheet.state.lastTicketListUuidMap[value]
            : undefined
        setTicketListUuid(lastTicketListUuid)
        bulkSheet.setState({
          ticketType: value,
          ticketList: undefined,
          lastTicketListUuidMap: bulkSheet.state.lastTicketListUuidMap,
          editable: false,
        })
      }
    },
    [bulkSheet]
  )

  const onChangeTicketList = useCallback(
    (value: TicketListDetail | undefined) => {
      if (ticketListUuid !== value?.uuid) {
        setTicketListUuid(value?.uuid)
      }
      if (ticketList?.uuid !== value?.uuid) {
        setTicketList(value)
      }
      if (bulkSheet) {
        const lastTicketListUuidMap = Object.assign(
          {},
          bulkSheet?.state.lastTicketListUuidMap
        )
        if (ticketType) {
          lastTicketListUuidMap[ticketType] = value?.uuid
        }
        bulkSheet?.setState(
          {
            ticketType,
            ticketList: value,
            lastTicketListUuidMap,
            editable: !!value,
          },
          () => bulkSheet.refreshGrid()
        )
      }
    },
    [bulkSheet, ticketType, ticketListUuid]
  )

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ workloadUnit, workloadUnitState })
    bulkSheet.gridApi!.refreshCells({
      columns: [
        'wbsItem.estimatedWorkload',
        'wbsItem.actualHour',
        'plannedValue',
        'wbsItem.earnedValue',
        'preceding',
        'delayed',
        'remaining',
        'productivity',
      ],
      force: true,
    })
  }, [bulkSheet, workloadUnit, workloadUnitState])

  useEffect(() => {
    if (!bulkSheet) return
    bulkSheet.setContext({ dateFormat })
    bulkSheet.gridApi?.refreshCells({
      columns: [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
      ],
      force: true,
    })
  }, [bulkSheet, dateFormat])

  useEffect(() => {
    const bulkTicketType = bulkSheet?.state.ticketType
    const bulkTicketListUuid =
      bulkSheet?.state.lastTicketListUuidMap && bulkTicketType
        ? bulkSheet?.state.lastTicketListUuidMap[bulkTicketType]
        : undefined

    if (bulkTicketType !== ticketType) {
      setTicketType(bulkTicketType)
    }
    if (bulkTicketListUuid !== ticketListUuid) {
      setTicketListUuid(bulkTicketListUuid)
    }
  }, [bulkSheet?.state.ticketType, bulkSheet?.state.lastTicketListUuidMap])

  const dispatch = useDispatch()
  useEffect(() => {
    const initTags = async () => {
      const response = await fetchTags({ projectUuid: project.uuid })
      const tags = response.json
      bulkSheet?.setState({ tags }, () =>
        dispatch(receivedTags(project.uuid, tags))
      )
    }
    initTags()
  }, [project.uuid, bulkSheet])

  return (
    <PageArea>
      {props.functionLayers.size === 1 && (
        <SavePopper
          loading={isLoading}
          onSubmit={() => bulkSheet?.onSubmit()}
        />
      )}
      <TicketListSelector
        ticketType={ticketType}
        onChangeTicketType={onChangeTicketType}
        ticketListUuid={ticketListUuid}
        onChangeTicketList={onChangeTicketList}
      />
      <TicketsReport
        aggregateField={aggregateType}
        workloadUnit={workloadUnit}
        rowNodes={rowNodes}
        projectUuid={project.uuid}
        ticketList={ticketList}
        open={isReportOpen}
        closeReport={() => onChangeReportOpen(false)}
      />
      <TicketsWbsHeader
        onChangeAggregateField={onChangeAggregateType}
        aggregateField={aggregateType}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        workloadUnit={workloadUnit}
        rowHeight={rowHeight}
        onChangeHeight={value => bulkSheet?.onChangeHeight(value)}
        onClickImportExcel={bulkSheet?.importExcel}
        onClickExportExcel={() =>
          bulkSheet?.openExcelOutputColumnSelectDialog()
        }
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={column =>
          bulkSheet?.resetSpecificColumnFilter(
            column.colId || column.field || ''
          )
        }
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={bulkSheet?.onDeleteSortedAllColumns}
        onChangeSortColumnState={bulkSheet?.onChangeSortColumnState}
        onReload={onReload}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        onClickFavoriteColumnFilterButton={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        sortColumnsState={sortColumnsState}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
        isReportOpen={isReportOpen}
        onChangeReportOpen={onChangeReportOpen}
      />
      <Box ref={ref} sx={{ height: '100%' }}>
        <BulkSheetComponent
          {...props}
          options={ticketOptions}
          hideHeader={true}
          hideToolbar={true}
          setBulkSheet={ctx => setBulkSheet(ctx)}
          setFilteredColumns={columns => setFilteredColumns(columns)}
          setRowHeight={height => setRowHeight(height)}
          setSubmitDisabled={value => setSubmitDisabled(value)}
          setIsLoading={value => setIsLoading(value)}
          specificProps={{
            updateAggregationRowNodes: (nodes: RowNode[]) => {
              setRowNodes(nodes)
            },
          }}
          setSortColumnsState={value => setSortColumnsState(value)}
        />
      </Box>
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={bulkSheet?.columnApi}
        gridApi={bulkSheet?.gridApi}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={() =>
          bulkSheet?.openSavedBulkSheetUIStateDialog(
            UiStateKey.BulkSheetUIStateColumnAndFilter
          )
        }
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={fn.uuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={currentColumnState}
        offset={90}
      />
    </PageArea>
  )
}

const mapStateToProps = (state: AllState) => ({
  functions: state.appFunction.functions,
  functionLayers: state.functionLayer.layers,
})

export default connect(mapStateToProps)(pageComponent(projectPrivate(Tickets)))
