import {
  CellClickedEvent,
  CellStyle,
  CellStyleFunc,
  ColDef,
  RowNode,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import { APIResponse } from '../../../lib/commons/api'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import WbsItemApi, {
  createDeltaRequestByRow,
  createRowByResponse,
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
  taskActualResultExists,
  WbsItemBasic,
  WbsItemDeltaInput,
  WbsItemDetail,
  WbsItemRow,
} from '../../../lib/functions/wbsItem'
import Workload, {
  getWorkloadUnitValue,
  WorkloadUnit,
} from '../../../lib/functions/workload'
import store from '../../../store'
import { CommentSummary } from '../../../store/comments'
import { openComment } from '../../../store/information'
import { ColumnType } from '../../containers/commons/AgGrid'
import formatter from '../../containers/commons/AgGrid/lib/formatter'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
} from '../../containers/Comment/CommentHeaderWbsItem'
import {
  AgGridValueGetter,
  AgGridValueSetter,
  BulkSheetContext,
  BulkSheetOptions,
  BulkSheetProps,
  BulkSheetSpecificProps,
  BulkSheetState,
  OpenDetailSpec,
} from '../../containers/BulkSheet'
import {
  RowData,
  RowDataSpec,
} from '../../containers/BulkSheet/RowDataManager/rowDataManager'
import ViewMeta from '../../containers/meta/ViewMeta'
import { SprintStatus } from '../../../lib/functions/sprint'
import {
  getAll,
  getByUuid,
  MyTaskBatchInput,
  MyTaskDetail,
  RequestOfUpdateBatch,
  updateBatch,
} from '../../../lib/functions/myTasks'
import Auth from '../../../lib/commons/auth'
import { AttachmentSummary } from '../../../utils/attachment'
import { intl } from '../../../i18n'
import { ProjectDetail } from '../../../lib/functions/project'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { getSelectedWorkloadUnit } from '../../components/toggleGroups'
import uiStates, {
  RequestOfGetStates,
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import SprintBacklogApi, {
  createSprintBacklogUpdateBatchRequest,
} from '../../../lib/functions/sprintBacklog'
import { createWatchers } from '../ProjectPlan/projectPlanOptions'
import { formatWorkload } from '../ProjectPlanLazyLoad/projectPlanLazyLoadOptions'

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export interface MyTasksState extends BulkSheetState {
  quickFilters: string[]
  workloadUnit: WorkloadUnit
}

export class MyTaskRow extends RowData {
  project: ProjectDetail
  projectName: string
  projectIconUrl: string
  parentDeliverable?: WbsItemBasic
  type?: WbsItemType
  wbsItem: WbsItemRow = {}
  commentSummary?: CommentSummary
  deliverableAttachmentSummary?: AttachmentSummary
  cumulation?: ProjectPlanCumulation
  projectPlanPath?: string
  parentWbsItem?: WbsItemBasic
}

export class MyTasksRowDataSpec extends RowDataSpec<MyTaskDetail, MyTaskRow> {
  columnTypes(): { [key: string]: ColDef } {
    return {
      taskWorkload: {
        editable: params =>
          params.data.wbsItem.type &&
          params.data.wbsItem.type === WbsItemType.TASK,
      },
      actualResultColumn: {
        valueFormatter: formatter.format,
      },
      priorityColumn: {
        valueFormatter: () => {
          return ''
        },
      },
    }
  }

  createNewRow(
    ctx: MyTasksBulkSheetContext,
    params: any = {
      type: WbsItemType.TASK,
      wbsItem: new WbsItemRow(),
    }
  ): MyTaskRow {
    throw new Error('Not implemented')
  }

  overwriteRowItemsWithParents(params: {
    child: MyTaskRow
    parent: MyTaskRow
  }): MyTaskRow {
    throw new Error('overwriteRowItemsWithParents is not implemented.')
  }

  createRowByResponse(response: MyTaskDetail): MyTaskRow {
    // Response can be the imported data from excel sheet
    const wbsItem: WbsItemDetail = response.wbsItem
    const base = {
      uuid: response.uuid,
      lockVersion: response.lockVersion,
      type: wbsItem.type,
      project: response.project,
      projectName: response.project.displayName,
      projectIconUrl: response.project.iconUrl,
      parentDeliverable: response.parentDeliverable,
      displayName: '',
      wbsItem: createRowByResponse(wbsItem),
      cumulation: response.cumulation,
      prevSiblingUuid: response.prevSiblingUuid,
      commentSummary: response.commentSummary,
      deliverableAttachmentSummary: response.deliverableAttachmentSummary,
      projectPlanPath: response.projectPlanPath,
      parentWbsItem: response.parentWbsItem,
    }
    return base
  }
}

export interface MyTasksProps extends BulkSheetSpecificProps {
  updateAggregationRowNodes: (nodes: RowNode[]) => void
}

interface MyTasksBulkSheetContext
  extends BulkSheetContext<
    MyTasksProps,
    MyTaskDetail,
    MyTaskRow,
    MyTasksState
  > {}

interface MyTasksBulkSheetProps
  extends BulkSheetProps<MyTasksProps, MyTaskDetail, MyTaskRow, MyTasksState> {}

export default class MyTasksOptions extends BulkSheetOptions<
  MyTasksProps,
  MyTaskDetail,
  MyTaskRow,
  MyTasksState
> {
  addable = false
  displayNameField = 'wbsItem.displayName'
  draggable = true
  enableExcelExport = true
  enableExcelImport = false
  enableExpandAllRow = false
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.MyTaskColumnAndFilterState}-${ctx.state.uuid}`
  fieldRefreshedAfterMove = ['wbsItem.status', 'wbsItem.estimatedWorkload.task']
  rowDataSpec = new MyTasksRowDataSpec()
  pinnedColumns = [
    'myTasks.wbsItem.code',
    'myTasks.wbsItem.type',
    'myTasks.wbsItem.ticketType',
    'myTasks.wbsItem.status',
    'myTasks.wbsItem.substatus',
    'myTasks.action',
    'myTasks.project.displayName',
    'myTasks.projectPlanPath',
    'myTasks.parentDeliverable.displayName',
    'myTasks.parentWbsItem.displayName',
    'myTasks.wbsItem.displayName',
    'myTasks.wbsItem.priority',
    'myTasks.attachment',
  ]
  lockedColumns = [
    'myTasks.wbsItem.code',
    'myTasks.wbsItem.type',
    'myTasks.wbsItem.ticketType',
    'myTasks.wbsItem.status',
    'myTasks.wbsItem.substatus',
    'myTasks.action',
    'myTasks.attachment',
  ]
  customRenderers = {
    'myTasks.wbsItem.displayName': 'wbsItemTreeCellRenderer',
    'myTasks.project.displayName': 'iconCellRenderer',
  }
  customColumnTypes = {
    'myTasks.wbsItem.type': [ColumnType.wbsItemType],
    'myTasks.wbsItem.status': [ColumnType.wbsItemStatus],
    'myTasks.wbsItem.estimatedWorkload.task': ['taskWorkload'],
    'myTasks.wbsItem.currentSprint': ['currentSprint'],
    'myTasks.wbsItem.scheduledDate.startDate': [
      ColumnType.wbsItemScheduledDate,
    ],
    'myTasks.wbsItem.scheduledDate.endDate': [ColumnType.wbsItemScheduledDate],
    'myTasks.wbsItem.actualDate.startDate': [ColumnType.wbsItemActualDate],
    'myTasks.wbsItem.actualDate.endDate': [ColumnType.wbsItemActualDate],
    'myTasks.wbsItem.priority': ['priorityColumn'],
    'myTasks.commentSummary.latestComment': [ColumnType.comment],
  }
  getRowDataUuidForFilter = rowNode => rowNode.data.wbsItem.uuid
  getOpenDetailSpec = (row: MyTaskRow): Promise<OpenDetailSpec> => {
    return getOpenWbsItemDetailSpec(true, row.wbsItem)
  }

  async getAll(
    state: MyTasksState,
    props?: MyTasksBulkSheetProps
  ): Promise<APIResponse> {
    const tenant = Auth.getCurrentTenant()
    const userUuid = tenant!.user!.uuid
    return getAll(userUuid)
  }

  getUpdatedRowAncestors = async (uuid: string): Promise<MyTaskDetail> => {
    const response = await getByUuid(uuid)
    return response.json
  }

  updateDefaultState = async (
    s: MyTasksState,
    applicationFunctionUuid: string
  ) => {
    let pageState
    const pageStateProp = await this.getPageState(applicationFunctionUuid)
    if (pageStateProp && pageStateProp.value) {
      pageState = JSON.parse(pageStateProp.value)
    }
    return {
      ...s,
      workloadUnit: pageState ? pageState.workloadUnit : WorkloadUnit.HOUR,
    }
  }

  private getPageState = async uuid => {
    const request: RequestOfGetStates = {
      applicationFunctionUuid: uuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    }
    const response = await uiStates.get(request)
    return response.json
  }

  onSubmit = async (
    ctx: MyTasksBulkSheetContext,
    data: {
      edited: {
        before: MyTaskRow
        after: MyTaskRow
      }[]
    },
    viewMeta: ViewMeta
  ): Promise<APIResponse> => {
    const myTaskInputs: MyTaskBatchInput[] = data.edited.map(v =>
      this.createRequestByRow(v, viewMeta)
    )
    const request: RequestOfUpdateBatch = {
      myTasks: myTaskInputs,
      watchers: createWatchers(undefined, data.edited),
    }
    const response = await updateBatch(request)

    // Update sprint backlogs
    const sprintBacklogRequest = createSprintBacklogUpdateBatchRequest(
      data.edited?.map(v => v.after?.wbsItem).filter(v => !!v),
      Object.fromEntries(
        response.json.map(v => [v.wbsItemUuid, v.wbsItemLockVersion])
      ),
      data.edited?.map(v => v.before?.wbsItem).filter(v => !!v)
    )
    await SprintBacklogApi.updateBatchSprintBacklog(sprintBacklogRequest)

    return response
  }

  createWbsItemDeltaRequestByRow = (
    editedRow: {
      before: MyTaskRow
      after: MyTaskRow
    },
    viewMeta: ViewMeta
  ): WbsItemDeltaInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    return createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'projectPlan.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
  }

  private createRequestByRow = (
    editedRow: {
      before: MyTaskRow
      after: MyTaskRow
    },
    viewMeta: ViewMeta
  ): MyTaskBatchInput => {
    const { before: editedRowBefore, after: editedRowAfter } = editedRow
    const wbsItemInput = createDeltaRequestByRow(
      {
        before: editedRowBefore.wbsItem,
        after: editedRowAfter.wbsItem,
      },
      viewMeta,
      'myTasks.wbsItem',
      {
        before: editedRowBefore.extensions,
        after: editedRowAfter.extensions,
      }
    )
    return {
      uuid: editedRowAfter.uuid,
      prevSiblingUuid:
        editedRowBefore.prevSiblingUuid !== editedRowAfter.prevSiblingUuid
          ? {
              oldValue: editedRowBefore.prevSiblingUuid,
              newValue: editedRowAfter.prevSiblingUuid,
            }
          : undefined,
      wbsItem: wbsItemInput,
    }
  }

  getValueGetter = (field: string): AgGridValueGetter | undefined => {
    if (['wbsItem.estimatedWorkload.task'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: MyTaskRow = params.data
        if (data.wbsItem.type === WbsItemType.TASK) {
          return getWorkloadUnitValue(
            data.wbsItem.estimatedWorkload,
            params.context?.workloadUnit
          )
        }
        return undefined
      }
    }
    if (['productivity.actualHour'].includes(field)) {
      return (params: ValueGetterParams) => {
        const data: MyTaskRow = params.data
        return formatWorkload(
          data.cumulation?.actualHour,
          params.context?.workloadUnitState?.hoursPerSelectedUnit!
        )
      }
    }
    if (field === 'project.displayName') {
      return (params: ValueGetterParams) => {
        let data: MyTaskRow = params.data
        return data?.project.displayName || ''
      }
    }
    if (field === 'parentDeliverable.displayName') {
      return (params: ValueGetterParams) => {
        const data: MyTaskRow = params.data
        return (
          data?.parentDeliverable ||
          ({
            displayName: intl.formatMessage({
              id: 'iconCell.parentDeliverable.notFound',
            }),
          } as WbsItemBasic)
        )
      }
    }
    if (field === 'parentWbsItem.displayName') {
      return (params: ValueGetterParams) => {
        const data: MyTaskRow = params.data
        return (
          data?.parentWbsItem ||
          ({
            displayName: intl.formatMessage({
              id: 'iconCell.parentDeliverable.notFound',
            }),
          } as WbsItemBasic)
        )
      }
    }
    if (field === 'wbsItem.ticketType') {
      return (params: ValueGetterParams) => {
        return params.data?.wbsItem?.baseWbsItemType
      }
    }
    return undefined
  }

  getValueSetter = (field: string): AgGridValueSetter | undefined => {
    if (field === 'wbsItem.estimatedWorkload.task') {
      return (params: ValueSetterParams) => {
        if (params.oldValue === params.newValue) {
          return false
        }
        params.data.isEdited = true
        const unit = getSelectedWorkloadUnit()
        const { dailyWorkHours, monthlyWorkDays } =
          store.getState().tenant.organization!
        let workload: Workload
        switch (unit) {
          case WorkloadUnit.MONTH:
            workload = Workload.from({
              month: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.DAY:
            workload = Workload.from({
              day: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          case WorkloadUnit.HOUR:
            workload = Workload.from({
              hour: Number(params.newValue),
              standard: { dailyWorkHours, monthlyWorkDays },
            })
            break
          default:
            workload = new Workload(0, 0, 0, {
              dailyWorkHours,
              monthlyWorkDays,
            })
        }
        params.data.wbsItem.estimatedWorkload = workload
        return true
      }
    }
    return undefined
  }

  getCellEditorParams = (field: string): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.status') {
      return {
        refreshFieldNames: ['wbsItem.status', 'wbsItem.estimatedWorkload.task'],
      }
    }
    if (field === 'wbsItem.currentSprint') {
      return {
        validatePaste: value =>
          [SprintStatus.INPROGRESS, SprintStatus.STANDBY].includes(
            value.status!
          ),
      }
    }
    return undefined
  }

  getCellRendererParams = (
    field: string
    // ctx?: MyTasksBulkSheetContext
  ): { [key: string]: any } | undefined => {
    if (field === 'productivity.actualHour') {
      return {
        link: (row: MyTaskRow) => row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    if (field === 'project.displayName') {
      return {
        labelField: 'projectName',
        iconUrlField: 'projectIconUrl',
      }
    }
    return undefined
  }

  getAttachmentCellRendererParams = () => {
    return {
      getAttachmentList: async (row: MyTaskRow) => {
        if (row.wbsItem.uuid) {
          const response = await WbsItemApi.getDetail({
            uuid: row.wbsItem.uuid,
          })
          const wbsItemDetail = response.json
          return wbsItemDetail.deliverableAttachments || []
        }
        return []
      },
      getAttachmentSummary: (row: MyTaskRow) => {
        return row.deliverableAttachmentSummary
      },
    }
  }

  getDetailColumnCellRendererParams = () => {
    return {
      path: 'wbsItem.description',
      openComment: (row: MyTaskRow, ctx: MyTasksBulkSheetContext) => {
        const applicationFunctionUuid = getWbsItemFunctionUuid()
        const wbsItemUuid = row.wbsItem.uuid
        if (!applicationFunctionUuid || !wbsItemUuid) {
          return
        }
        store.dispatch(
          openComment({
            applicationFunctionUuid,
            dataUuid: wbsItemUuid,
            projectUuid: row.wbsItem.projectUuid!,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(row.wbsItem)}
                onAfterUpdate={() => {
                  ctx.refreshAfterUpdateSingleRow(row.uuid)
                }}
              />,
            ],
          })
        )
      },
      getCommentSummary: (row: MyTaskRow) => row.commentSummary,
    }
  }

  getTaskActualResultKey = (params: CellClickedEvent): string | undefined => {
    const row: MyTaskRow = params.data
    if (row && taskActualResultExists(row.wbsItem)) {
      return row.wbsItem.uuid
    }
    return undefined
  }

  customColumnWidth = (field: string): number | undefined => {
    if (
      [
        'wbsItem.accountable',
        'wbsItem.responsible',
        'wbsItem.assignee',
        'wbsItem.priority',
      ].includes(field)
    ) {
      return 65
    }
    if (
      [
        'wbsItem.estimatedWorkload.task',
        'productivity.actualHour',
        'wbsItem.difficulty',
        'wbsItem.remainingWorkload',
      ].includes(field)
    ) {
      return 80
    }
    if (
      [
        'wbsItem.scheduledDate.startDate',
        'wbsItem.scheduledDate.endDate',
        'wbsItem.actualDate.startDate',
        'wbsItem.actualDate.endDate',
      ].includes(field)
    ) {
      return 90
    }
    if (
      [
        'action',
        'wbsItem.status',
        'project.displayName',
        'wbsItem.code',
        'wbsItem.type',
        'action',
      ].includes(field)
    ) {
      return 100
    }
    if (['attachment'].includes(field)) {
      return 55
    }
    return undefined
  }

  getCellStyle = (field: string): CellStyle | CellStyleFunc | undefined => {
    if (field === 'wbsItem.priority') {
      return {
        justifyContent: 'center',
      }
    }
    return undefined
  }

  getOnCellValueChanged = (field: string, ctx: MyTasksBulkSheetContext) => {
    if (
      [
        'wbsItem.estimatedWorkload.task',
        'wbsItem.scheduledDate.endDate',
      ].includes(field)
    ) {
      return () => {
        if (ctx.props.specificProps?.updateAggregationRowNodes) {
          ctx.props.specificProps?.updateAggregationRowNodes(
            ctx.getAllRowNodes()
          )
        }
      }
    }
    return undefined
  }

  onRowDataChanged = (ctx: MyTasksBulkSheetContext) => {
    if (ctx.props.specificProps?.updateAggregationRowNodes) {
      ctx.props.specificProps?.updateAggregationRowNodes(ctx.getAllRowNodes())
    }
  }
}
