import { useCallback, useEffect, useState } from 'react'
import {
  OrganizationWorkingDayCalendarDetail,
  getOrganizationWorkingDayCalendar,
} from '../../../../lib/functions/organizationWorkingDayCalendar'
import { DateTerm } from '../../../../utils/date'
import DateVO from '../../../../vo/DateVO'

export const useMasterData = (dateTerm: DateTerm) => {
  const [workingDays, setWorkingDays] = useState<
    OrganizationWorkingDayCalendarDetail[]
  >([])

  const fetchWorkingDayCalendars = useCallback(async (dateTerm: DateTerm) => {
    const workingDayCalendarResponse = await getOrganizationWorkingDayCalendar({
      startDate: new DateVO(dateTerm.startDate).toDate(),
      endDate: new DateVO(dateTerm.endDate).toDate(),
    })
    setWorkingDays(
      workingDayCalendarResponse.json as OrganizationWorkingDayCalendarDetail[]
    )
  }, [])

  useEffect(() => {
    fetchWorkingDayCalendars(dateTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTerm.startDate, dateTerm.endDate])

  return {
    workingDays,
  }
}
