import API, { APIResponse } from '../commons/api'

export interface LanguageProps {
  standardCode: string
  supportCode: string
  name: string
}

export async function getLanguages(): Promise<APIResponse> {
  return API.functional.request('GET', '/api/v1/languages')
}
