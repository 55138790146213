import React, { Fragment } from 'react'
import { AllState } from '../../../../store'
import { useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { SnackbarProvider, useSnackbar, VariantType } from 'notistack'
import { MessageLevel } from '../../../../store/messages'
import { IconButton } from '@mui/material'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/CloseRounded'
import AnnouncementIcon from '@mui/icons-material/AnnouncementRounded'
import WarningIcon from '@mui/icons-material/WarningRounded'
import { muiTheme } from '../../../../styles/muiTheme'

const theme = muiTheme
const StyledSnackbarProvider = styled(SnackbarProvider)({
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  '& .SnackbarItem-variantSuccess': {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.common.white,
  },
  '& .SnackbarItem-variantError': {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
  },
  '& .SnackbarItem-variantWarning': {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
  '& .SnackbarItem-variantInfo': {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
})

const Notifier = injectIntl(props => {
  const message = useSelector((state: AllState) => state.messages.latest)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  React.useEffect(() => {
    if (!message || message.type === MessageLevel.DEBUG) {
      return
    }
    let displayText: string
    if (message.type === MessageLevel.WARN && message.text) {
      const errCodeMessage = message.code
        ? `\n${props.intl.formatMessage(
            { id: 'global.warning.errorCode' },
            { text: '', errorCode: message.code }
          )}`
        : ''
      displayText = `${message.text}${errCodeMessage}`
    } else if (message.type === MessageLevel.ERROR) {
      displayText = `${message.title}\n${props.intl.formatMessage({
        id: 'snackBar.seeDetail',
      })}`
    } else {
      displayText = message.title || ''
    }
    const action = key => (
      <Fragment>
        <IconButton
          style={{ paddingRight: 4 }}
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon color="action" />
        </IconButton>
      </Fragment>
    )
    enqueueSnackbar(displayText, {
      variant: convertMessageTypeToVariant(message.type),
      style: { whiteSpace: 'pre-line' },
      action,
    })
  }, [message])

  const convertMessageTypeToVariant = (level: MessageLevel): VariantType => {
    switch (level) {
      case MessageLevel.ERROR:
        return 'error'
      case MessageLevel.WARN:
        return 'warning'
      case MessageLevel.SUCCESS:
        return 'success'
      default:
        return 'info'
    }
  }

  return null
})

const SnackBar = () => {
  return (
    <StyledSnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      iconVariant={{
        error: <AnnouncementIcon color="action" />,
        warning: <WarningIcon color="action" />,
      }}
    >
      <Notifier />
    </StyledSnackbarProvider>
  )
}

export default SnackBar
