import { Divider, styled } from '@mui/material'
import { WbsItemSelectOptions } from '../../../hooks/wbsItemSelectOptions'
import { UpdateFormModelValue, WbsItemFormModel } from '../../../model'
import { StatusInput } from './StatusInput'
import { SubStatusInput } from './SubStatusInput'
import { PriorityInput } from './PriorityInput'
import { TeamInput } from './TeamInput'
import { ProjectMemberInput } from './ProjectMemberInput'
import { DateTermInput } from './DateTermInput'
import { SubHeaderDivider } from '../commons'

export type ValuePanelProps = {
  projectUuid: string
  model: WbsItemFormModel
  onChangeStatus: UpdateFormModelValue<'status'>
  onChangeSubStatus: UpdateFormModelValue<'substatus'>
  onChangePriority: UpdateFormModelValue<'priority'>
  onChangeTeam: UpdateFormModelValue<'team'>
  onChangeAccountable: UpdateFormModelValue<'accountable'>
  onChangeResponsible: UpdateFormModelValue<'responsible'>
  onChangeAssignee: UpdateFormModelValue<'assignee'>
  onChangeScheduledDate: UpdateFormModelValue<'scheduledDate'>
  onChangeActualDate: UpdateFormModelValue<'actualDate'>
} & Omit<WbsItemSelectOptions, 'difficulties'>
export const ValuePanel = ({
  projectUuid,
  model,
  onChangeStatus,
  onChangeSubStatus,
  onChangePriority,
  onChangeTeam,
  onChangeAccountable,
  onChangeResponsible,
  onChangeAssignee,
  onChangeScheduledDate,
  onChangeActualDate,
  subStatuses,
  priorities,
}: ValuePanelProps) => {
  return (
    <>
      <StatusInput value={model.status} onChange={onChangeStatus} />
      <SubStatusInput
        value={model.substatus}
        onChange={onChangeSubStatus}
        options={subStatuses}
      />
      <SubHeaderDivider />
      <PriorityInput
        value={model.priority}
        onChange={onChangePriority}
        options={priorities}
      />
      <SubHeaderDivider />
      <TeamInput
        projectUuid={projectUuid}
        value={model.team}
        onChange={onChangeTeam}
      />
      <SubHeaderDivider />
      <ProjectMemberInput
        projectUuid={projectUuid}
        value={model.accountable}
        onChange={onChangeAccountable}
      />
      <ProjectMemberInput
        projectUuid={projectUuid}
        value={model.responsible}
        onChange={onChangeResponsible}
      />
      <ProjectMemberInput
        projectUuid={projectUuid}
        value={model.assignee}
        onChange={onChangeAssignee}
      />
      <SubHeaderDivider />
      <DoubleDateTermContainer>
        <DateTermInput
          value={model.scheduledDate}
          onChange={onChangeScheduledDate}
        />
        <DateTermInput value={model.actualDate} onChange={onChangeActualDate} />
      </DoubleDateTermContainer>
    </>
  )
}

const DoubleDateTermContainer = styled('div')({})
