import {
  CellKeyDownEvent,
  GridOptions,
  SuppressKeyboardEventParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import {
  ColumnType,
  columnTypes,
  defaultOnCellClicked,
  frameworkComponents,
} from '../../../containers/commons/AgGrid'
import { DefaultCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer'
import _ from 'lodash'
import objects from '../../../../utils/objects'
import store from '../../../../store'
import { requireSave } from '../../../../store/requiredSaveData'
import {
  ClientSideTextFilter,
  ServerSideUuidFilter,
} from '../../../containers/BulkSheetView/components/filter'
import { intl } from '../../../../i18n'
import { ColorChartCellEditor } from '../../../containers/BulkSheetView/components/cellEditor/ColorChartCellEditor'
import { ColorChartCellRenderer } from '../../../containers/BulkSheetView/components/cellRenderer/ColorChartCellRenderer'
import BoolExpression from '../../../../utils/boolExpression'
import { FunctionProperty } from '../../../../lib/commons/appFunction'
import IconCellRenderer from '../../../containers/commons/AgGrid/components/cell/common/iconCell'
import { SequenceNoCellRenderer } from '../../../containers/commons/AgGrid/components/cell/custom/sequenceNo/SequenceNoCellRenderer'
import { TAG_CHARACTERS_PROHIBITED_LIST } from '../../../../lib/functions/tag'

export const TagsGridOptions = (): GridOptions => {
  return {
    groupHeaderHeight: 25,
    headerHeight: 45,
    rowHeight: 32,
    treeData: false,
    context: {},
    suppressLastEmptyLineOnPaste: true,
    // Row
    rowDragManaged: false,
    rowDragMultiRow: true,
    suppressMoveWhenRowDragging: true,
    enterMovesDownAfterEdit: true,
    onCellKeyDown: (params: CellKeyDownEvent) => {
      // @ts-ignore
      if (!['Delete', 'Backspace'].includes(params.event.key)) return
      const cellRanges = params.api.getCellRanges() || []
      cellRanges.forEach(range => {
        const start = Math.min(range.startRow!.rowIndex, range.endRow!.rowIndex)
        const end = Math.max(range.startRow!.rowIndex, range.endRow!.rowIndex)
        range.columns.forEach(column => {
          const colDef = column.getColDef()
          if (
            typeof colDef.editable === 'function'
              ? colDef.editable(params)
              : colDef.editable
          ) {
            for (let i = start; i <= end; i++) {
              const rowNode = params.api.getDisplayedRowAtIndex(i)
              rowNode?.setDataValue(column, undefined)
            }
          }
        })
      })
    },
    // Column
    columnTypes: columnTypes(),
    components: frameworkComponents,
    defaultColDef: {
      width: 85,
      editable: false,
      enableValue: false,
      sortable: true,
      resizable: true,
      suppressMenu: true,
      suppressSizeToFit: true,
      singleClickEdit: false,
      cellEditor: 'textEditor',
      cellStyle: { justifyContent: 'left' },
      cellRenderer: DefaultCellRenderer,
      onCellClicked: defaultOnCellClicked,
      suppressPaste: params => !_.isEmpty(params.context?.copied),
      suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => {
        return (
          !params.editing && ['Delete', 'Backspace'].includes(params.event.key)
        )
      },
      valueSetter: (params: ValueSetterParams) => {
        const field = params.colDef.field || params.colDef.colId
        if (
          !field ||
          (!params.oldValue && !params.newValue) ||
          params.oldValue === params.newValue
        ) {
          return false
        }
        objects.setValue(params.data, field, params.newValue)
        params.data.edited = true
        store.dispatch(requireSave())
        return true
      },
    },
    columnDefs: [
      {
        field: 'uuid',
        hide: true,
        suppressColumnsToolPanel: true,
        filter: ServerSideUuidFilter,
      },
      {
        colId: 'blank',
        hide: true,
        width: 35,
        suppressColumnsToolPanel: true,
      },
      {
        field: 'drag',
        headerName: '',
        type: [ColumnType.drag],
        rowDrag: _ => true,
        cellClassRules: {
          'hover-over-can-drop': params => {
            return (
              params.context?.draggableNodeId &&
              params.node?.id === params.context?.draggableNodeId
            )
          },
        },
      },
      {
        field: 'rowNumber',
        type: [ColumnType.sequenceNo],
        resizable: false,
        width: 35,
        cellRenderer: SequenceNoCellRenderer,
        cellRendererParams: params => {
          return {
            value: params.node.rowIndex + 1,
          }
        },
      },
      {
        headerName: intl.formatMessage({ id: 'tags.information' }),
        children: [
          {
            field: 'name',
            headerName: intl.formatMessage({ id: 'tags.name' }),
            hide: false,
            pinned: true,
            lockPosition: 'left',
            width: 350,
            editable: true,
            cellRendererParams: {
              uiMeta: {
                requiredIf: BoolExpression.of(true),
                charactersProhibited: TAG_CHARACTERS_PROHIBITED_LIST,
              } as Partial<FunctionProperty>,
            },
            filter: ClientSideTextFilter,
            floatingFilter: true,
          },
          {
            field: 'backgroundColor',
            headerName: intl.formatMessage({
              id: 'tags.backgroundColor',
            }),
            hide: false,
            pinned: true,
            lockPosition: 'left',
            width: 90,
            editable: true,
            cellEditor: ColorChartCellEditor,
            cellRenderer: ColorChartCellRenderer,
            cellRendererParams: {
              uiMeta: {
                requiredIf: BoolExpression.of(true),
              } as Partial<FunctionProperty>,
            },
            filter: ClientSideTextFilter,
            floatingFilter: true,
          },
        ],
      },
      {
        headerName: intl.formatMessage({ id: 'changeLog' }),
        children: [
          {
            field: 'revision',
            headerName: intl.formatMessage({ id: 'revision' }),
            hide: true,
            width: 90,
          },
          {
            field: 'createdBy',
            headerName: intl.formatMessage({ id: 'createdBy' }),
            hide: true,
            width: 110,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.createdBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'createdBy.name',
              iconUrlField: 'createdBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            field: 'createdAt',
            headerName: intl.formatMessage({ id: 'createdAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
          {
            field: 'updatedBy',
            headerName: intl.formatMessage({ id: 'updatedBy' }),
            hide: true,
            width: 110,
            valueGetter: (params: ValueGetterParams) => {
              return params.data.updatedBy?.name
            },
            cellRenderer: IconCellRenderer,
            cellRendererParams: {
              labelField: 'updatedBy.name',
              iconUrlField: 'updatedBy.iconUrl',
            },
            floatingFilter: true,
            filter: 'agSetColumnFilter',
          },
          {
            field: 'updatedAt',
            headerName: intl.formatMessage({ id: 'updatedAt' }),
            hide: true,
            width: 120,
            type: [ColumnType.dateTime],
            floatingFilter: true,
          },
        ],
      },
    ],
  }
}
