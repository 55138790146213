import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { styled } from '@mui/system'
import DateVO from '../../../../../../../../vo/DateVO'
import DateCellOperatorSelect, {
  DateCellFilterOperator,
  DateCellFilterOperatorLabel,
} from './dateCellOperatorSelect'
import ResetButton from '../../../../../../../components/buttons/ResetButton'
import { intl } from '../../../../../../../../i18n'
import { IDoesFilterPassParams } from 'ag-grid-community'
import DateInputWithClassVO from '../../../../../../../components/editors/input/DateInput/DateInputWithClassVO'

export const useDateFilter = () => {
  const [value, setValue] = useState<DateVO>()
  const [operator, setOperator] = useState<DateCellFilterOperator>(
    DateCellFilterOperator.EQ
  )

  const reset = () => {
    setValue(undefined)
    setOperator(DateCellFilterOperator.EQ)
  }

  const isActive = () => operator === DateCellFilterOperator.NOT_SET || !!value

  const model = () => {
    if (isActive()) {
      return {
        value: value?.formatForApi(),
        operator: operator,
      }
    }
    return undefined
  }

  return { operator, setOperator, value, setValue, isActive, model, reset }
}

const RootDiv = styled('div')({
  padding: '3px 8px',
  display: 'flex',
})
const StyledDateCellOperatorSelect = styled(DateCellOperatorSelect)({
  minWidth: 70,
  margin: 'auto 5px auto 0',
})
const StyledDateInputWithClassVO = styled(DateInputWithClassVO)({
  width: 110,
  margin: 'auto 5px auto 0',
})
const StyledResetButton = styled(ResetButton)({
  whiteSpace: 'nowrap',
  margin: 'auto 0',
})

const DateCellFilter = ({
  key,
  dateValue,
  onChangeDateValue,
  operator,
  onChangeOperator,
  clearFilter,
}: {
  key: string
  dateValue?: DateVO
  onChangeDateValue: (dateValue?: DateVO) => void
  operator: DateCellFilterOperator
  onChangeOperator: (operator: DateCellFilterOperator) => void
  clearFilter: () => void
}) => (
  <RootDiv>
    <StyledDateCellOperatorSelect
      id={`${key}-${operator}`}
      value={operator}
      onChange={onChangeOperator}
    />
    <StyledDateInputWithClassVO
      value={dateValue}
      onChange={onChangeDateValue}
      disabled={operator === DateCellFilterOperator.NOT_SET}
      isClearable={operator === DateCellFilterOperator.NOT_SET}
    />
    <StyledResetButton
      label={intl.formatMessage({ id: 'clear' })}
      onClick={clearFilter}
    />
  </RootDiv>
)

interface Props {
  filterChangedCallback: () => void
  colDef: {
    field: string
  }
  valueGetter: (params: IDoesFilterPassParams) => string
}

export default forwardRef((props: Props, ref) => {
  const state = useDateFilter()

  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params: IDoesFilterPassParams) {
        // Don't filter new rows
        if (params.data.isAdded) return true
        const dateValue = props.valueGetter(params)
        const rowDataValue = dateValue ? new DateVO(dateValue) : undefined
        if (!rowDataValue) {
          return state.operator === DateCellFilterOperator.NOT_SET
        }
        if (!state.value) return false
        switch (state.operator) {
          case DateCellFilterOperator.EQ:
            return rowDataValue.isEqual(state.value)
          case DateCellFilterOperator.GTE:
            return rowDataValue.isSameOrAfter(state.value)
          case DateCellFilterOperator.LTE:
            return rowDataValue.isSameOrBefore(state.value)
          default:
            return false
        }
      },

      isFilterActive() {
        return state.isActive()
      },

      getModel() {
        if (!state.isActive()) return undefined
        return {
          value: {
            dateValue: state.value ? state.value.toLabel() : undefined,
            operator: state.operator,
          },
        }
      },

      setModel(model) {
        if (model?.value) {
          state.setValue(
            model.value.dateValue
              ? new DateVO(model.value.dateValue)
              : undefined
          )
          state.setOperator(model.value.operator)
        } else {
          state.reset()
        }
      },

      getModelAsString() {
        return state.isActive()
          ? DateCellFilterOperatorLabel[state.operator] +
              (state.value ? ` : ${state.value.toLabel()}` : '')
          : ''
      },
    }
  })

  useEffect(() => {
    props.filterChangedCallback()
  }, [state.value, state.operator])

  return (
    <DateCellFilter
      key={`date-cell-filter-${props.colDef.field}`}
      dateValue={state.value}
      operator={state.operator}
      onChangeOperator={operator => {
        state.setOperator(operator)
        if (operator === DateCellFilterOperator.NOT_SET) {
          state.setValue(undefined)
        }
      }}
      onChangeDateValue={value => state.setValue(value)}
      clearFilter={() => state.reset()}
    />
  )
})
