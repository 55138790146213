import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { AllState } from '../../../store'
import SelectVO from '../../../vo/SelectVO'
import { getLabel } from '../../../lib/commons/i18nLabel'
import ToolbarSelect from '../../components/editors/select/ToolbarSelect'
import { CustomEnumValue } from '../../../lib/commons/appFunction'
import { Box } from '@mui/material'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'

interface StateProps {
  ticketTypes: WbsItemTypeVO[]
}

interface Props extends StateProps {
  value?: string
  onChange: (value?: string) => void
}

const mapStateToProps = (state: AllState) => ({
  ticketTypes: state.project.ticketTypes,
})

export const TicketTypeSelect = connect(mapStateToProps)((props: Props) => {
  const { ticketTypes, value, onChange } = props
  const options = ticketTypes.map(
    ticketType =>
      new SelectVO(
        ticketType.code,
        ticketType.name || '',
        undefined,
        undefined,
        ticketType.iconUrl
      )
  )
  const onChangeValue = useCallback(
    (event: any) => {
      const selected = ticketTypes.find(
        option => option.code === event.target.value
      )
      onChange(selected?.code)
    },
    [ticketTypes, onChange]
  )

  return (
    <Box sx={{ width: '240px', height: '30px' }}>
      <ToolbarSelect
        key={value}
        value={value}
        options={options}
        getOptionValue={option => option.getValue()}
        getOptionLabel={option => option.format()}
        onChange={onChangeValue}
        style={{
          border: '1px solid #DDDDDD',
          boxShadow: '0px 1px 1px #00000033',
        }}
      />
    </Box>
  )
})
