import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { createStrictContext } from '.'
import store, { AllState } from '../../store'
import { BaseWbsItemType, loginToProjectByCode } from '../../store/project'
import Project, { ProjectDetail } from '../../lib/functions/project'
import { connect } from 'react-redux'
import { WbsItemTypeVO } from '../../domain/value-object/WbsItemTypeVO'

type ProjectProviderProps = StateProps & RouteComponentProps<PathProps>

type StateProps = {
  project: ProjectDetail | undefined
  projectUuid: string | undefined
  projectCode: string | undefined
  wbsItemTypes: BaseWbsItemType
  ticketListTypes: WbsItemTypeVO[]
  ticketTypes: WbsItemTypeVO[]
  isFirstFunctionLayer: boolean
}

type PathProps = {
  code?: string
}

type ProjectPrivateContext = {
  project: ProjectDetail
  // TODO Remove it
  projectUuid: string
  projectCode: string | undefined
  wbsItemTypes: BaseWbsItemType
  ticketListTypes: WbsItemTypeVO[]
  ticketTypes: WbsItemTypeVO[]
}

const [useContext, Provider] = createStrictContext<ProjectPrivateContext>()

export const useProjectPrivateContext = useContext

const mapStateToProps = (state: AllState) => ({
  project: state.project.current,
  projectUuid: state.project.selected,
  projectCode: state.project.current?.code,
  wbsItemTypes: state.project.wbsItemTypes,
  ticketListTypes: state.project.ticketListTypes,
  ticketTypes: state.project.ticketTypes,
  isFirstFunctionLayer: state.functionLayer.currentDepth === 0,
})

export const ProjectProvider = connect(mapStateToProps)(
  withRouter(
    ({
      children,
      project,
      projectUuid,
      projectCode,
      wbsItemTypes,
      ticketListTypes,
      ticketTypes,
      match,
      isFirstFunctionLayer,
    }: PropsWithChildren<ProjectProviderProps>) => {
      const projectPrivateProps = useMemo(() => {
        if (
          !project ||
          !projectUuid ||
          (isFirstFunctionLayer && projectCode !== match.params.code)
        ) {
          return undefined
        }
        return {
          project,
          projectUuid,
          projectCode,
          wbsItemTypes,
          ticketListTypes,
          ticketTypes,
        }
      }, [
        project,
        projectUuid,
        projectCode,
        wbsItemTypes,
        ticketListTypes,
        ticketTypes,
        match.params.code,
        isFirstFunctionLayer,
      ])

      const fetchProject = useCallback(async () => {
        if (!match.params.code) return undefined
        store.dispatch(loginToProjectByCode(match.params.code))
      }, [match])
      useEffect(() => {
        if (
          !project ||
          !projectUuid ||
          (isFirstFunctionLayer && projectCode !== match.params.code)
        ) {
          fetchProject()
        }
      }, [
        project,
        projectUuid,
        projectCode,
        match.params.code,
        fetchProject,
        isFirstFunctionLayer,
      ])

      if (!projectPrivateProps) return <></>

      return <Provider value={projectPrivateProps}>{children}</Provider>
    }
  )
)
