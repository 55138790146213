import {
  generateGanttChartCellRendererParams,
  ProjectPlanBulkSheetContext,
  ProjectPlanOptions,
  ProjectPlanRow,
} from '../ProjectPlan/projectPlanOptions'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { APPLICATION_FUNCTION_EXTERNAL_ID } from '../index'
import {
  generateToolBarItemKey,
  ToolBarItemPosition,
} from '../../components/toolbars/ContainerToolBar'
import { OpenInNewIcon } from '../../components/icons/OpenInNewIcon'
import ProjectApi from '../../../lib/functions/project'
import { FunctionLayer } from '../../../store/functionLayer'
import {
  AggregateField,
  WbsItemType,
} from '../../../domain/entity/WbsItemEntity'
import { WorkloadUnit } from '../../../lib/functions/workload'
import store from '../../../store'
import { taskActualResultExists } from '../../../lib/functions/wbsItem'

export default class ProjectPlanTab extends ProjectPlanOptions {
  columnAndFilterStateKey = ctx =>
    `${UiStateKey.ProjectPlanTabColumnAndFilterState}-${ctx.state.uuid}`

  private toLayer = async (props: {
    treeRootUuid: string
    projectUuid: string
  }): Promise<FunctionLayer> => {
    const response = await ProjectApi.getDetail({ uuid: props.projectUuid })
    const project = response.json
    return {
      externalId: APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_EDIT,
      code: project.code,
      params: {
        treeRootUuid: props.treeRootUuid,
      },
    }
  }

  getCellRendererParams = (
    field: string,
    ctx?: ProjectPlanBulkSheetContext
  ): { [key: string]: any } | undefined => {
    if (field === 'wbsItem.displayName') {
      return {
        isRootUuid: (uuid: string) => {
          if (ctx?.props.treeRootUuid === uuid) return true
        },
      }
    }
    if (field === 'wbsItem.actualHour') {
      return {
        link: (row: ProjectPlanRow) =>
          row && taskActualResultExists(row.wbsItem),
      }
    }
    if (field === 'wbsItem.watchers') {
      return {
        showIcon: true,
      }
    }
    if (field === 'ganttChart') {
      if (!ctx) return {}
      return generateGanttChartCellRendererParams(
        ctx,
        ctx.props.ganttParameter || store.getState().project.ganttParameter!
      )
    }
    return undefined
  }

  headerComponents = ctx => {
    return undefined
  }

  toolBarItems = ctx => [
    <div key={generateToolBarItemKey(1, ToolBarItemPosition.RIGHT)}>
      <OpenInNewIcon size="s" layer={() => this.toLayer(ctx.props)} />
    </div>,
  ]

  hiddenColumns = [
    'wbsItem.estimatedWorkload.deliverable',
    'wbsItem.estimatedWorkload.task',
    'wbsItem.actualHour',
    'progress.progressRate',
    'wbsItem.priority',
    'wbsItem.scheduledDate.startDate',
    'wbsItem.scheduledDate.endDate',
    'wbsItem.actualDate.startDate',
    'wbsItem.actualDate.endDate',
    'wbsItem.team',
    'wbsItem.accountable',
    'wbsItem.responsible',
    'wbsItem.assignee',
    'wbsItem.watchers',
  ]

  getDefaultContext = (): any => ({
    wbsItemType: WbsItemType.TASK,
    aggregateTargetType: AggregateField.WBS_ITEM_WORKLOAD,
    workloadUnit: WorkloadUnit.HOUR,
  })
}
