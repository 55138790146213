import _ from 'lodash'
import { useMemo } from 'react'
import { ValueFormatterParams } from 'ag-grid-community'

import { CustomEnumValue } from '../../../lib/commons/appFunction'
import { getLabel } from '../../../lib/commons/i18nLabel'
import { CustomEnumCellEditor } from '../../containers/BulkSheetView/components/cellEditor'
import { CustomEnumCellRenderer } from '../../containers/BulkSheetView/components/cellRenderer'
import {
  ClientSideTextFilter,
  CustomEnumFilter,
} from '../../containers/BulkSheetView/components/filter'
import { ColumnType } from '../../containers/commons/AgGrid'
import { BulkSheetProperty } from './bulksheetProperty'

// The following methods should be used in src/view/page-properties/bulksheet-properties

export const floatValueFormatter = (
  params: ValueFormatterParams,
  nanString: string,
  digits: number
) => {
  const value: number | undefined = parseFloat(params.value)
  if (!isFinite(value)) return nanString
  return value.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

export const useCustomEnumColumn = ({
  field,
  customEnumCode,
  customEnumOptions,
}: {
  field: string
  customEnumCode: string
  customEnumOptions?: CustomEnumValue[] // TODO: GridOption.context cannot be obtained from ColDef.comprator, so pass it as an argument.
}): BulkSheetProperty => {
  const property = useMemo(
    () => ({
      field,
      width: 90,
      editable: true,
      type: [ColumnType.customEnum],
      cellEditor: CustomEnumCellEditor,
      cellEditorParams: { customEnumCode },
      cellRenderer: CustomEnumCellRenderer,
      cellRendererParams: { customEnumCode },
      filter: CustomEnumFilter,
      floatingFilter: true,
      filterParams: {
        customEnumCode,
        getValue: (option: CustomEnumValue) => option?.value,
        getLabel: (option: CustomEnumValue) =>
          option ? getLabel(option.nameI18n) ?? option.name : '',
        sortValues: (options, context) => {
          const customEnums = context[customEnumCode]
          if (!customEnums) return options
          return options.sort((a, b) => {
            return (
              customEnums.findIndex(e => e.value === a?.value) -
              customEnums.findIndex(e => e.value === b?.value)
            )
          })
        },
      },
      comparator: (valueA: string, valueB: string) => {
        if (!customEnumOptions || _.isEmpty(customEnumOptions)) return 0
        return (
          customEnumOptions.findIndex(o => o.value === valueA) -
          customEnumOptions.findIndex(o => o.value === valueB)
        )
      },
    }),
    [field, customEnumCode, customEnumOptions]
  )
  return property
}

export const useMultiLineTextColum = (field: string) => {
  const property = useMemo(
    () => ({
      field,
      editable: true,
      type: [ColumnType.multiLineText],
      floatingFilter: true,
      filter: ClientSideTextFilter,
    }),
    [field]
  )
  return property
}
