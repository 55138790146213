import {
  ColDef,
  CellClassParams,
  ValueFormatterParams,
} from 'ag-grid-community'
import { using } from '../../../../page-properties/bulksheet-properties/wbsItemAdditionalProperty'
import {
  AdditionalPropertyRow,
  isGroupHeaderRow,
} from '../../rowModel/wbsItemAdditionalPropertyRow'
import { DISABLE_COLOR } from './style'
import { intl } from '../../../../../i18n'

export const usingColumnDef: ColDef = {
  ...using,
  editable: false,
  valueFormatter: (params: ValueFormatterParams) => {
    if (!params.node || isGroupHeaderRow(params.node.data)) {
      return ''
    }
    const additionalPropertyRow = params.node.data as AdditionalPropertyRow
    return additionalPropertyRow.using
      ? intl.formatMessage({
          id: 'wbsItemAdditionalProperties.using',
        })
      : ''
  },
  cellStyle: (params: CellClassParams) =>
    isGroupHeaderRow(params.node.data)
      ? {
          backgroundColor: DISABLE_COLOR,
        }
      : undefined,
}
