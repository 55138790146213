import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { InputBase } from '@mui/material'
import { styled } from '@mui/system'
import DatePicker from 'react-datepicker'
import DataCellBase, { CellPropsBase, CellStateBase } from '.'
import DateVO from '../../../../../../vo/DateVO'
import { completeDateString } from '../../../../../commons/inputSupporter'

interface DateCellProps extends CellPropsBase<DateVO> {}

interface DateCellState extends CellStateBase {}

export const DateInput = React.memo(
  ({
    value,
    initialValueOnCalendar,
    onChange,
    onSelect,
    placeholder,
    CustomInput,
    editable,
    style,
  }: {
    value?: Date
    initialValueOnCalendar?: Date
    onChange?: (value?: Date) => void
    onSelect: (value?: Date) => void
    placeholder?: string
    CustomInput?: React.ElementType
    editable?: boolean
    style?: React.CSSProperties
  }) => {
    const [changedByInput, setChangedByInput] = useState(false)
    const [calendarOpened, setCalendarOpened] = useState(false)

    useEffect(() => {
      setChangedByInput(false)
    }, [value])

    const onSelectCallback = useCallback(
      (value?: Date) => {
        if (!changedByInput) {
          onSelect(value)
        } else {
          setChangedByInput(false)
        }
      },
      [onSelect, changedByInput]
    )
    const onChangeCaptureCallback = useCallback(
      _.debounce(event => {
        const inputValue = event.target.value
        setChangedByInput(true)

        if (!inputValue) {
          // 値をクリアした時
          onSelect(undefined)
        }
        const value = completeDateString(inputValue)
        if (!value) {
          return
        }
        const newValue = new Date(value.replace(/\//g, '-'))
        onSelect(newValue)
      }, 200),
      [changedByInput, onSelect]
    )

    return (
      <DatePicker
        selected={
          value || (calendarOpened ? initialValueOnCalendar : undefined)
        }
        dateFormat={'yyyy/MM/dd'}
        onChange={onChange}
        onSelect={onSelectCallback}
        placeholderText={placeholder || 'YYYY/MM/DD'}
        customInput={
          CustomInput ? (
            <CustomInput
              style={style}
              onChangeCapture={onChangeCaptureCallback}
              onFocusCapture={() => setCalendarOpened(true)}
              onBlurCapture={() => setCalendarOpened(false)}
            />
          ) : (
            <InputBase
              style={style}
              onChangeCapture={onChangeCaptureCallback}
              onFocusCapture={() => setCalendarOpened(true)}
              onBlurCapture={() => setCalendarOpened(false)}
            />
          )
        }
        popperPlacement="bottom-end"
        disabled={!editable}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.stopPropagation()
            e.currentTarget.blur()
          }
        }}
        fixedHeight={true}
      />
    )
  }
)

const RootDiv = styled('div')({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
})
const CustomInput = styled(InputBase)({
  width: '100%',
  height: '100%',
  '& > div': {
    width: '100%',
  },
})

class DateCell extends DataCellBase<DateVO, DateCellProps, DateCellState> {
  validate = (value?: DateVO) => {
    return undefined
  }

  onSelect = selectedValue => {
    this.props.onChange(selectedValue ? new DateVO(selectedValue) : undefined)
  }

  render() {
    const { value, cellDef } = this.props
    return (
      <RootDiv>
        <DateInput
          value={value ? value.toDate() : undefined}
          onSelect={this.onSelect}
          placeholder={cellDef.placeholder || 'YYYY/MM/DD'}
          CustomInput={CustomInput}
          editable={!!cellDef.editable}
        />
      </RootDiv>
    )
  }
}

export default DateCell
