import styled from 'styled-components'
import { colorPalette } from '../../../../style/colorPallete'
import { StatusColumnCumulationData } from '../../Kanban'
import { Typography } from '@mui/material'
import { intl } from '../../../../../i18n'
import { memo } from 'react'
import { roundNumber } from '../../../../../utils/number'

export type Props = {
  title: string
  cumulationData: StatusColumnCumulationData
}

export const StatusColumnHeader = memo(({ title, cumulationData }: Props) => {
  const { count, estimatedHour, actualHour } = cumulationData
  const formattedEstimatedHour = roundNumber(estimatedHour, 2)
  const formattedActualHour = roundNumber(actualHour, 2)
  return (
    <Header>
      <Title variant="h5" sx={{ marginBottom: '8px' }}>
        {title}
      </Title>
      <Cumulation variant="h6" sx={{ marginBottom: '4px' }}>
        {`${intl.formatMessage(
          { id: 'kanban.column.header.hit' },
          { total: count }
        )}`}
      </Cumulation>
      <Cumulation variant="h6">
        {`${intl.formatMessage(
          { id: 'kanban.column.header.estimatedHour' },
          { estimatedHour: formattedEstimatedHour }
        )} / ${intl.formatMessage(
          { id: 'kanban.column.header.actualHour' },
          { actualHour: formattedActualHour }
        )}`}
      </Cumulation>
    </Header>
  )
})

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
})

const Title = styled(Typography)({
  textAlign: 'center',
  fontWeight: '700',
  color: colorPalette.monotone[10],
})

const Cumulation = styled(Typography)({
  textAlign: 'center',
  fontWeight: '500',
  color: colorPalette.monotone[10],
})
