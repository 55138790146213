import DomainValueObjectBase from '../Base'

export interface IItemDelta<T> {
  oldValue?: T
  newValue?: T
}

export default class ItemDeltaInputVO<T> extends DomainValueObjectBase<
  IItemDelta<T>
> {
  constructor(oldValue?: T, newValue?: T) {
    super({ oldValue, newValue })
  }
}

export const createDelta = <T>(
  oldValue?: T,
  newValue?: T
): IItemDelta<T> | undefined => {
  if (oldValue !== newValue) return { oldValue, newValue }
  return undefined
}
