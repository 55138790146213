import { RiskRow } from '../dataManager/risk'
import { TicketRow } from '../tickets'
import { ExcelParserBase } from './base'

export class RiskExcelParser extends ExcelParserBase {
  public getColumnMap = (columnKeyMap: {
    [key: string]: string
  }): {
    [key: string]: string
  } => {
    return {
      ...this.getCommonColumnKeyMap(columnKeyMap),
      riskType: columnKeyMap[this.columnName('risks.riskType')],
      probability: columnKeyMap[this.columnName('risks.probability')],
      impact: columnKeyMap[this.columnName('risks.impact')],
      strategy: columnKeyMap[this.columnName('risks.strategy')],
    }
  }

  private mergeRiskEnumValues = (
    columnKey: string,
    excelSrc: object,
    customEnumKey: string,
    embodiedValue: string
  ) => {
    return columnKey
      ? this.findFromContext(customEnumKey, excelSrc[columnKey])?.value ??
          embodiedValue
      : embodiedValue
  }

  public mergeUpdatedRow = (
    embodied: TicketRow,
    excelSrc: object,
    columnKeyMap: { [key: string]: string }
  ): RiskRow => {
    const riskEmbodied = embodied as RiskRow
    return {
      ...embodied,
      riskType: this.mergeRiskEnumValues(
        columnKeyMap.riskType,
        excelSrc,
        'riskType',
        riskEmbodied.riskType
      ),
      probability: this.mergeRiskEnumValues(
        columnKeyMap.probability,
        excelSrc,
        'riskProbability',
        riskEmbodied.probability
      ),
      impact: this.mergeRiskEnumValues(
        columnKeyMap.impact,
        excelSrc,
        'riskImpact',
        riskEmbodied.impact
      ),
      strategy: this.mergeRiskEnumValues(
        columnKeyMap.strategy,
        excelSrc,
        'riskStrategy',
        riskEmbodied.strategy
      ),
      wbsItem: this.mergeWbsItemColumnUpdatedRow(
        embodied.wbsItem!,
        excelSrc,
        columnKeyMap
      ),
    } as RiskRow
  }
}
