import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import styled from 'styled-components'
import { colorPalette } from '../../../style/colorPallete'

export const BootstrapTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow={true} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: colorPalette.monotone[1],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colorPalette.monotone[1],
    color: colorPalette.monotone[4],
    fontSize: '14px',
    boxShadow: '0px 2px 6px 0px #7B8CAA66',
  },
}))
