import _ from 'lodash'
import { ColGroupDef } from 'ag-grid-community'
import { intl } from '../../../../../i18n'
import { ClientSideNumberFilter } from '../../../../containers/BulkSheetView/components/filter'
import { FlagxsColumnDef } from '../../../../containers/BulkSheetView/gridOptions/extension'
import {
  DEFAULT_COLUMN_GROUP_DEF,
  useEstimateColumnGroupDef,
  useEexplanationColumnGroupDef,
  generateCustomEnumColumnDef,
  useDisplayNameColumnDef,
  useInformationColumnGroupDef,
  useNameColumnGroupDef,
  getProductivityColumnGroupDef,
  PATH_COLUMN_GROUP_DEF,
  PROGRESS_COLUMN_GROUP_DEF,
  PropsForGetColumnDefs,
  useStoryPointColumnGroupDef,
  TAG_EXTERNAL_ID,
  CHANGE_LOG_COLUMN_GROUP_DEF,
  useAssignmentColumnGroupDef,
  useTermColumnGroupDef,
  useDescriptionColumnDef,
} from './common'
import { useMemo } from 'react'

const useRiskColumnGroupDef = (
  descriptionColDef: FlagxsColumnDef
): FlagxsColumnDef => {
  const groupDef = useMemo(
    () => ({
      externalId: 'risk.riskInformation',
      headerName: intl.formatMessage({ id: 'risk' }),
      children: [
        generateCustomEnumColumnDef(
          'risks.riskType',
          'riskType',
          'risks.riskType',
          'riskType'
        ),
        {
          field: 'score',
          headerName: intl.formatMessage({ id: 'risks.score' }),
          width: 90,
          editable: false,
          cellClass: 'ag-numeric-cell',
          cellStyle: { justifyContent: 'flex-end' },
          filter: ClientSideNumberFilter,
          floatingFilter: true,
        },
        generateCustomEnumColumnDef(
          'risks.probability',
          'probability',
          'risks.probability',
          'riskProbability'
        ),
        generateCustomEnumColumnDef(
          'risks.impact',
          'impact',
          'risks.impact',
          'riskImpact'
        ),
        generateCustomEnumColumnDef(
          'risks.strategy',
          'strategy',
          'risks.strategy',
          'riskStrategy'
        ),
        descriptionColDef,
      ],
    }),
    [descriptionColDef]
  )
  return groupDef
}

const removeChildDef = (
  src: ColGroupDef,
  targetChildExternalId: string | undefined
) => {
  if (!targetChildExternalId) return src
  const targetGroupDef = _.cloneDeep(src)
  const targetIndex = targetGroupDef.children.findIndex(
    (c: FlagxsColumnDef) => {
      return c.externalId === targetChildExternalId
    }
  )
  if (0 <= targetIndex) {
    targetGroupDef.children.splice(targetIndex, 1)
  }
  return targetGroupDef
}

export const useRiskColumnDefs = ({
  projectUuid,
  onClickedStatusCell,
  addRow,
  onClickedActualHourCell,
  reloadSingleRow,
  submitSingleRow,
  context,
}: PropsForGetColumnDefs): FlagxsColumnDef[] => {
  const informationColumnGroupDef = useInformationColumnGroupDef({
    onClickedStatusCell,
    addRow,
  })
  const descriptionColDef = useDescriptionColumnDef()

  const riskColumnGroupDef = useRiskColumnGroupDef(descriptionColDef)

  const dispNameColDef = useDisplayNameColumnDef({
    reloadSingleRow,
    submitSingleRow,
  })
  const nameColumnGroupDef = useNameColumnGroupDef({
    reloadSingleRow,
    submitSingleRow,
    context,
  })
  const explanationColumnGroupDef = useEexplanationColumnGroupDef()
  const assignmentColumnGroupDef = useAssignmentColumnGroupDef(
    projectUuid,
    context
  )
  const storyPointColumnGroupDef = useStoryPointColumnGroupDef()
  const estimateColumnGroupDef = useEstimateColumnGroupDef()
  const termColumnGroupDef = useTermColumnGroupDef()

  const columnDefs = useMemo(() => {
    const infoGroupDef = _.cloneDeep(informationColumnGroupDef)
    const tagColumnIndex = infoGroupDef.children.findIndex(
      (c: FlagxsColumnDef) => c.externalId === TAG_EXTERNAL_ID
    )
    if (0 <= tagColumnIndex) {
      infoGroupDef.children.splice(tagColumnIndex, 0, dispNameColDef)
    }
    const nameColGroupDef = removeChildDef(
      nameColumnGroupDef as ColGroupDef,
      dispNameColDef.externalId
    )
    const explanationColGroupDef = removeChildDef(
      explanationColumnGroupDef as ColGroupDef,
      descriptionColDef.externalId
    )
    return [
      DEFAULT_COLUMN_GROUP_DEF,
      infoGroupDef,
      PATH_COLUMN_GROUP_DEF,
      riskColumnGroupDef,
      nameColGroupDef,
      explanationColGroupDef,
      assignmentColumnGroupDef,
      storyPointColumnGroupDef,
      estimateColumnGroupDef,
      PROGRESS_COLUMN_GROUP_DEF,
      getProductivityColumnGroupDef(onClickedActualHourCell),
      termColumnGroupDef,
      CHANGE_LOG_COLUMN_GROUP_DEF,
    ]
  }, [
    informationColumnGroupDef,
    riskColumnGroupDef,
    dispNameColDef,
    nameColumnGroupDef,
    explanationColumnGroupDef,
    assignmentColumnGroupDef,
    storyPointColumnGroupDef,
    estimateColumnGroupDef,
    termColumnGroupDef,
    onClickedActualHourCell,
  ])
  return columnDefs as FlagxsColumnDef[]
}
