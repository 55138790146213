import { ClickAwayListener, IconButton, Popper, styled } from '@mui/material'
import { DateTerm } from '../../model'
import { colorPalette } from '../../../../style/colorPallete'
import { formatFullDate } from '../../utils/date'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import { DatePicker } from '../../../../components/inputs/DatePicker'
import { useCallback, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'

type DateTermSelectProps = {
  value: DateTerm
  onChange: (newValue: DateTerm) => void
}

export const DateTermSelect = ({ value, onChange }: DateTermSelectProps) => {
  const onChangeFrom = useCallback(
    (newValue: Date) => {
      onChange({
        from: newValue,
        to: value.to,
      })
    },
    [onChange, value.to]
  )
  const onChangeTo = useCallback(
    (newValue: Date) => {
      onChange({
        from: value.from,
        to: newValue,
      })
    },
    [onChange, value.from]
  )

  return (
    <RootDiv>
      <DateSelect value={value.from} onChange={onChangeFrom} />
      <TildeDiv>~</TildeDiv>
      <DateSelect value={value.to} onChange={onChangeTo} />
    </RootDiv>
  )
}
type DateSelectProps = {
  value: Date
  onChange: (newValue: Date) => void
}
const DateSelect = ({ value, onChange }: DateSelectProps) => {
  const formatted = useMemo(() => formatFullDate(value), [value])
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef<HTMLButtonElement>(null)
  const toggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])
  const close = useCallback(() => setOpen(false), [])
  return (
    <>
      <DateSelectRoot>
        <DateDiv>{formatted}</DateDiv>
        <StyledIconButton onClick={toggle} ref={ref}>
          <CalendarIcon />
        </StyledIconButton>
      </DateSelectRoot>
      <Popper open={open} anchorEl={ref.current}>
        <ClickAwayListener onClickAway={close}>
          <PopperDiv>
            <DatePicker value={value} onChange={onChange} />
          </PopperDiv>
        </ClickAwayListener>
      </Popper>
    </>
  )
}
const RootDiv = styled('div')({
  padding: '8px 16px',
  gap: '12px',
  display: 'flex',
  alignItems: 'center',
})

const DateSelectRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
})

const DateDiv = styled('div')({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: 1.5,
  color: colorPalette.monotone[5],
})

const TildeDiv = styled('div')({
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: 1,
  color: colorPalette.monotone[4],
})

const CalendarIcon = styled(CalendarMonthRoundedIcon)({
  height: '16px',
  width: '16px',
  color: colorPalette.monotone[4],
})

const StyledIconButton = styled(IconButton)({})

const PopperDiv = styled('div')({
  backgroundColor: colorPalette.monotone[0],
  boxShadow: `0px 4px 16px 0px ${colorPalette.monotone[4]}80`,
  borderRadius: '4px',
})
