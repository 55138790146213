import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { Box, Link, Typography } from '@mui/material'
import { WbsItemTypeVO } from '../../../../../../../../domain/value-object/WbsItemTypeVO'
import { ProjectOverviewRow } from '../../../../../../../pages/ProjectOverview/projectOverview'

type Props = ICellRendererParams & {
  onClick: (data: ProjectOverviewRow) => void
}

export const ProjectOverviewSequenceNoCellRenderer = (props: Props) => {
  const { data } = props
  const wbsItemType: WbsItemTypeVO = data?.wbsItem.wbsItemType

  const isLink =
    (wbsItemType?.isWorkgroup() ||
      wbsItemType?.isProcess() ||
      wbsItemType?.isDeliverableList()) &&
    props.node.hasChildren()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {isLink && (
        <Link
          underline="always"
          sx={{
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          onClick={() => props.onClick(data)}
        >
          {props.value}
        </Link>
      )}
      {!isLink && (
        <Typography
          sx={{
            fontWeight: 'normal',
            margin: '0',
          }}
        >
          {props.value}
        </Typography>
      )}
    </Box>
  )
}
