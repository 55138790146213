import { ICellRendererParams } from 'ag-grid-community'
import { getRowNumber } from '../../../../containers/BulkSheetView/lib/gridApi'
import { AgGridRowResizeDragger } from '../../../../containers/commons/AgGrid/components/dragger/rowResizeDragger'

type Props = ICellRendererParams

export const LedgerAccountsSequenceNoCellRenderer = ({ node, api }: Props) => {
  const rowNumber = getRowNumber(node)

  return (
    <div className="sevend-ag-cell-sequence-no">
      <p className="sevend-ag-cell-sequence-no__label">{rowNumber}</p>
      <AgGridRowResizeDragger node={node} api={api} />
    </div>
  )
}
