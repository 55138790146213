import API from '../../../lib/commons/api'
import IWbsItemTypeRepository from '../../../domain/repository/WbsItemRepository'
import {
  WbsItemTypeObject,
  WbsItemTypeVO,
} from '../../../domain/value-object/WbsItemTypeVO'

export default class WbsItemTypeRepository implements IWbsItemTypeRepository {
  async getWbsItemTypes(projectUuid: string): Promise<WbsItemTypeVO[]> {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/wbs_item_types',
      { projectUuid }
    )
    return (response.json as WbsItemTypeObject[]).map(v => new WbsItemTypeVO(v))
  }
  async getTicketTypes(projectUuid: string): Promise<WbsItemTypeVO[]> {
    const response = await API.functional.request(
      'GET',
      '/api/v1/projects/wbs_item_types/tickets',
      { projectUuid }
    )
    return (response.json as WbsItemTypeObject[]).map(v => new WbsItemTypeVO(v))
  }
}
