import { InputBase, styled } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { colorPalette } from '../../../../style/colorPallete'

type TextInputProps = {
  value: string | undefined
  onChange: (v: string | undefined) => void
  variant?: ReportInputVariant
  multiline?: boolean
}
export const TextInput = ({
  value,
  onChange,
  variant,
  multiline,
}: TextInputProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value)
  const [changedByInput, setChangedByInput] = useState<boolean>(false)
  useEffect(() => {
    setInputValue(value)
    setChangedByInput(false)
  }, [value])
  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (changedByInput) {
      onChange && onChange(debouncedValue)
    }
  }, [changedByInput, debouncedValue, onChange])
  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setChangedByInput(true)
  }, [])
  return (
    <StyledInput
      value={inputValue}
      onChange={onChangeInput}
      variant={variant}
      multiline={multiline}
      minRows={2}
      maxRows={2}
    />
  )
}

type ReportInputVariant = 'header' | 'leadSentence' | 'comment'
type StyledInputProps = {
  variant?: 'header' | 'leadSentence' | 'comment'
}
const StyledInput = styled(InputBase)(
  ({ variant = 'comment' }: StyledInputProps) => {
    const fontStyle = useMemo(() => {
      switch (variant) {
        case 'header':
          return {
            fontSize: '18px !important',
            fontWeight: 600,
            color: colorPalette.monotone[10],
          }
        case 'leadSentence':
          return {
            fontSize: '14px !important',
            color: colorPalette.monotone[9],
          }
        case 'comment':
          return {
            fontSize: '14px !important',
            color: colorPalette.monotone[9],
          }
      }
      return {}
    }, [variant])
    return {
      width: '100%',
      padding: 0,
      alignItems: 'flex-start',
      '& .MuiInputBase-input': { ...fontStyle, padding: 0 },
    }
  }
)
