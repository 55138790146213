import { useCallback, useEffect, useRef, useState } from 'react'
import { DateTerm } from '../../../../utils/date'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../../lib/commons/uiStates'
import DateVO from '../../../../vo/DateVO'
import { SearchUnit } from '../../../components/headers/HeaderBar/DateRangeSelector'
import { ROW_HEIGHT } from '../../../containers/BulkSheet'

type StoredActualWorkingHoursPageState = {
  searchUnit: SearchUnit
  dateTerm: DateTerm
  rowHeight: ROW_HEIGHT
}

export const usePageState = ({ functionUuid }: { functionUuid: string }) => {
  const now = DateVO.now()
  const [searchUnit, setSearchUnit] = useState<SearchUnit>(SearchUnit.MONTH)
  const [rowHeight, setRowHeight] = useState<ROW_HEIGHT>(ROW_HEIGHT.SMALL)
  const [initialized, setInitialized] = useState(false)
  const [displayDateTerm, setDisplayDateTerm] = useState<DateTerm>({
    startDate: now.getFirstDayOfMonth().format(),
    endDate: now.getLastDayOfMonth().format(),
  })
  const dateTerm = useRef<DateTerm>({
    startDate: now.getFirstDayOfMonth().format(),
    endDate: now.getLastDayOfMonth().format(),
  })
  const monthDateTerm = useRef<DateTerm>({
    startDate: now.getFirstDayOfMonth().format(),
    endDate: now.getLastDayOfMonth().format(),
  })

  const storePageState = useCallback(
    async (value: StoredActualWorkingHoursPageState) => {
      uiStates.update(
        {
          key: UiStateKey.PageState,
          scope: UiStateScope.User,
          value: JSON.stringify(value),
        },
        functionUuid
      )
    },
    [functionUuid]
  )

  useEffect(() => {
    if (!initialized) return
    if (searchUnit === SearchUnit.MONTH) {
      monthDateTerm.current = displayDateTerm
    } else {
      dateTerm.current = displayDateTerm
      storePageState({
        searchUnit,
        dateTerm: displayDateTerm,
        rowHeight,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, storePageState, displayDateTerm])

  useEffect(() => {
    if (!initialized) return
    if (searchUnit === SearchUnit.MONTH) {
      setDisplayDateTerm(monthDateTerm.current)
    } else {
      setDisplayDateTerm(dateTerm.current)
    }
    storePageState({
      searchUnit,
      dateTerm: dateTerm.current,
      rowHeight,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, storePageState, searchUnit])

  useEffect(() => {
    if (!initialized) return
    storePageState({
      searchUnit,
      dateTerm: dateTerm.current,
      rowHeight,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, storePageState, rowHeight])

  const restorePageState = useCallback(async () => {
    const response = await uiStates.get({
      applicationFunctionUuid: functionUuid,
      key: UiStateKey.PageState,
      scope: UiStateScope.User,
    })
    const stored = response.json
    try {
      if (!stored.value) {
        return
      }
      const state = JSON.parse(stored.value)
      state.dateTerm && (dateTerm.current = state.dateTerm)
      state.searchUnit && setSearchUnit(state.searchUnit)
      state.rowHeight && setRowHeight(state.rowHeight)
      if (state.searchUnit === SearchUnit.DATE_RANGE) {
        setDisplayDateTerm(state.dateTerm)
      }
    } finally {
      setInitialized(true)
    }
  }, [functionUuid])

  useEffect(() => {
    restorePageState()
  }, [restorePageState])

  return {
    initialized,
    searchUnit,
    setSearchUnit,
    rowHeight,
    setRowHeight,
    displayDateTerm,
    setDisplayDateTerm,
  }
}
