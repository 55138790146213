export const convertDecimalToTime = (decimal: number): string | undefined => {
  if (typeof decimal !== 'number') {
    return undefined
  }
  const hour = Math.floor(decimal)
  const min = Math.round((decimal - hour) * 60)
  return `${hour}:${min.toString().padStart(2, '0')}`
}

export const convertUnixToTime = (milliseconds: number): string => {
  const seconds = Math.floor(milliseconds / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${hours}:${minutes.toString().padStart(2, '0')}`
}
