import API, { APIResponse } from '../commons/api'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import EntitySearch from '../commons/entitySearch'
import { DivisionProps } from './division'
import { PositionProps } from './position'
import { FunctionProperty } from '../commons/appFunction'
import { RoleBasic } from './role'
import { Tree } from '../commons/tree'
import { AgreementDetail } from './agreement'
import { ExternalServiceCode } from './idProvider'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import Auth from '../commons/auth'
import { SlackUser } from '../../domain/value-object/Slack/UserVO'

export enum RegistrationStatus {
  PROVISIONAL = 'PROVISIONAL',
  PROVISIONAL_EXPIRED = 'PROVISIONAL_EXPIRED',
  TEMPORARY_PASSWORD = 'TEMPORARY_PASSWORD',
  TEMPORARY_PASSWORD_EXPIRED = 'TEMPORARY_PASSWORD_EXPIRED',
  CHANGE_EMAIL_VERIFICATION_ONGOING = 'CHANGE_EMAIL_VERIFICATION_ONGOING',
  CHANGE_EMAIL_VERIFICATION_EXPIRED = 'CHANGE_EMAIL_VERIFICATION_EXPIRED',
  EXPIRED = 'EXPIRED',
  FINAL = 'FINAL',
}

export interface UserProps {
  uuid: string
  lockVersion: number
  name: string
  code: string
  email: string
  iconUrl: string
  lastLoginAt: number
  lastLogoutAt: number
  connectedWithCognito?: boolean
}

export interface UserCreateProps {
  uuid: string
  lockVersion: number
}

export interface UserUpdateDeltaProps {
  uuid: string
  code?: IItemDelta<string>
  email?: IItemDelta<string>
  name?: IItemDelta<string>
  nameAlphabet?: IItemDelta<string>
  nameKana?: IItemDelta<string>
  iconUrl?: IItemDelta<string>
  phoneNumber?: IItemDelta<string>
  birthday?: IItemDelta<string>
  hireDate?: IItemDelta<string>
  leaveDate?: IItemDelta<string>
  validFrom?: IItemDelta<string>
  validTo?: IItemDelta<string>
  emailNotification?: IItemDelta<boolean>
  divisionUuid?: IItemDelta<string>
  positionUuid?: IItemDelta<string>
  roleUuid?: IItemDelta<string>
  timezoneId?: IItemDelta<string>
  partnerMember?: IItemDelta<boolean>
  slackUsers?: IItemDelta<SlackUser[]>
}

export interface UserDeleteProps {
  uuid: string
  lockVersion: number
}

export interface UserResetProps {
  uuid: string
  temporaryPassword: string
}

export interface UserInput {
  uuid: string
  lockVersion?: number
  revision?: string
  code?: string
  name?: string
  email?: string
  phoneNumber?: string
  birthday?: string
  hireDate?: string
  leaveDate?: string
  validFrom?: string
  validTo?: string
  timezoneId?: string
  division?: DivisionProps
  position?: PositionProps
  role?: RoleBasic
  divisionUuid?: string
  positionUuid?: string
  roleUuid?: string
  emailNotification: boolean
  partnerMember: boolean
}

export interface UserBatchInput {
  added: UserInput[]
  edited: UserInput[]
}

export interface UserBatchDeltaInput {
  added: UserInput[]
  edited: UserUpdateDeltaProps[]
}

export interface UserGetUsersProps {
  all?: string
  code?: string
  name?: string
  email?: string
  phoneNumber?: string
  BirthDate?: string
  hireDate?: string
  leaveDate?: string
  division?: string
  position?: string
  updatedAt?: string
  offset?: number
  limit?: number
  mustMatch?: boolean
  validOnly?: boolean
  sortField?: string
  sortOrder?: string
}

export interface UserGetDetailProps {
  uuid: string
}

export interface UserGetUuidProps {
  code: string
}

export interface UserCreateResponse {
  code: string
}

export interface UserUpdateResponse {
  uuid: string
  lockVersion: number
}

export interface UserGetUsersResponse {
  total: number
  hit: number
  data: UserDetail[]
}

export interface UserGetDetailResponse {
  uuid: string
  revision: string
  lockVersion: number
}

export interface UserDetail extends Tree<UserDetail> {
  code: string
  iconUrl: string
  name: string
  nameKana: string
  nameAlphabet: string
  email: string
  phoneNumber: string
  birthday: string
  division: DivisionProps
  position: PositionProps
  hireDate: string
  leaveDate: string
  validFrom: number
  validTo: number
  emailNotification: boolean
  revision: string
  role: RoleBasic
  timezoneId: string
  oneTimeTokenExpiredAt: number
  temporaryPasswordExpiredAt: number
  lastLoginAt: string
  registrationStatus: RegistrationStatus
  hasAllPrivileges?: boolean
  partnerMember?: boolean
  slackUsers?: SlackUser[]
  connectedWithCognito?: boolean
}

export interface UserBasic {
  uuid: string
  code: string
  iconUrl: string
  name: string
}

export interface ConnectedService {
  externalId: string
  name: string
  code: ExternalServiceCode
}

class User extends EntitySearch implements SingleSheetRepository {
  hasIcon = true
  public search = async (
    rawQuery: string,
    data?: any,
    uiMeta?: FunctionProperty
  ) => {
    return this.searchInternal(
      rawQuery,
      (query: string) => ({
        all: query,
        mustMatch: true,
        validOnly: false,
        limit: query.length === 0 ? 10 : undefined,
      }),
      (user: UserBasic) => ({
        ...user,
      })
    )
  }

  public searchAll = async () => {
    return this.searchInternal(
      '',
      (query: string) => ({
        validOnly: false,
      }),
      this.toResponse
    )
  }

  toResponse = (user: UserProps) => ({
    uuid: user.uuid,
    name: user.name,
    iconUrl: user.iconUrl || '',
    code: user.code,
  })

  entitySearchApi = (
    props: UserGetUsersProps,
    signal?: AbortSignal
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/users/find',
      props,
      true,
      signal
    )
  }

  public create = (props: UserCreateProps): Promise<APIResponse> => {
    return API.functional.request('POST', '/api/v1/users', props)
  }

  public createByExternalService = async (props): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/users/external_service',
      props,
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }

  public updateExternalService = async (props): Promise<APIResponse> => {
    return API.functional.request(
      'PUT',
      '/api/v1/users/external_service',
      props,
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }

  public update = (props: unknown): Promise<APIResponse> => {
    throw new Error('Can not use user.update method.')
  }

  public updateDelta = (props: UserUpdateDeltaProps): Promise<APIResponse> => {
    return API.functional.request('PUT', '/api/v1/users/delta', props)
  }

  public delete = (props: UserDeleteProps): Promise<APIResponse> => {
    return API.functional.request('DELETE', '/api/v1/users', props)
  }

  public reset = (props: UserResetProps): Promise<APIResponse> => {
    return API.functional.request('POST', '/api/v1/users/reset', props)
  }

  public resendRegistrationUrl = (
    props: UserCreateProps
  ): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/users/resend_registration_url',
      props
    )
  }

  public getUsers = this.entitySearchApi

  public getDetail = (props: UserGetDetailProps): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/users/detail',
      {
        userUuid: props.uuid,
      },
      true
    )
  }

  public getUserExternalServices = async (
    email: string
  ): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/users/external_services',
      { email },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }

  public getExternalId = async (code: string): Promise<APIResponse> => {
    return API.functional.request(
      'GET',
      '/api/v1/users/external_id',
      { email: code },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }

  public me = (): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/users/me')
  }

  public updateBatchDelta(request: UserBatchDeltaInput): Promise<APIResponse> {
    return API.functional.request('POST', '/api/v1/users/delta/batch', request)
  }

  getLabel(option: UserDetail): string {
    const nameKana = option.nameKana ? `(${option.nameKana})` : ''
    const divisionName = option.division?.officialName
      ? `[${option.division.officialName}]`
      : ''
    const positionName = option.position?.displayName
      ? `[${option.position.displayName}]`
      : ''
    return `[${option.name}${nameKana}][${option.code}]${divisionName}${positionName}`
  }
  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/users/basic', { code })
  }

  getByUuidInternal = async (uuid: string): Promise<APIResponse> => {
    const response = await this.getDetail({ uuid })
    return { status: 200, json: this.toResponse(response.json) }
  }

  public agreeTerms = (agreements: AgreementDetail[]): Promise<APIResponse> => {
    return API.functional.request('POST', '/api/v1/users/agree_terms', {
      agreements: agreements.map(v => ({ uuid: v.uuid, version: v.version })),
    })
  }

  public confirmResetPassword = async (code: string): Promise<APIResponse> => {
    return API.functional.request(
      'POST',
      '/api/v1/users/confirm_reset_password',
      { email: code },
      false,
      null,
      { 'x-7d-userpoolid': Auth.getCurrentTenant()?.userPoolId || '' }
    )
  }
}

export default new User()

export const isEmailChangable = (status: RegistrationStatus) =>
  [
    RegistrationStatus.FINAL,
    RegistrationStatus.CHANGE_EMAIL_VERIFICATION_ONGOING,
    RegistrationStatus.CHANGE_EMAIL_VERIFICATION_EXPIRED,
  ].includes(status)
