import {
  ColDef,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import { useCallback, useState } from 'react'
import { SortedColumnState } from '../../../model/bulkSheetColumnSortState'

export const useColumnState = ({
  gridOptions,
  bulkSheetState,
}): {
  filteredColumns: ColDef[]
  sortColumnsState: SortedColumnState[]
  rememberColumnState: () => void
  resetColumnState: () => void
  onColumnVisible: (e: ColumnVisibleEvent) => void
  onFilterChanged: (e: FilterChangedEvent) => void
  onDeleteFilteredColumn: (column: ColDef) => void
  resetFilters: () => void
  onSortChanged: (e: SortChangedEvent) => void
  onDeleteSortedColumn: (colId: string | ColDef<any>) => void
  onDeleteSortedAllColumns: () => void
  onChangeSortColumnState: (
    colId: string | ColDef<any>,
    sort: 'asc' | 'desc' | null
  ) => void
} => {
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    const autoColumnState = columnState?.find(
      c => c.colId === 'ag-Grid-AutoColumn'
    )
    if (autoColumnState && gridOptions.autoGroupColumnDef) {
      gridOptions.autoGroupColumnDef.width = autoColumnState?.width
    }
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [bulkSheetState, gridOptions.autoGroupColumnDef, gridOptions.columnApi])

  const resetColumnState = useCallback(() => {
    if (!gridOptions.columnApi || !gridOptions.api) return
    gridOptions.columnApi.resetColumnState()
    gridOptions.api.setFilterModel(null)
  }, [gridOptions.columnApi, gridOptions.api])

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onDeleteFilteredColumn = useCallback((column: ColDef) => {
    if (!column || !gridOptions.api) return
    const filterModel = gridOptions.api.getFilterModel()
    delete filterModel[column.colId || column.field || '']
    gridOptions.api.setFilterModel(filterModel)
  }, [])

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [])

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onSortedColumnsStateChanged = useCallback(
    (e: SortChangedEvent) => {
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)

      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedColumnState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedColumnState[state.colId] = state
          }
        })
      const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
        }
      })

      setSortColumnsState(sortedColumnsState)
    },
    [bulkSheetState]
  )

  const onDeleteSortedColumn = useCallback((colId: string | ColDef<any>) => {
    gridOptions.columnApi?.applyColumnState({
      state: [{ colId: colId.toString(), sort: null }],
    })
  }, [])

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    []
  )

  return {
    filteredColumns,
    sortColumnsState,
    rememberColumnState,
    resetColumnState,
    onColumnVisible,
    onFilterChanged,
    onDeleteFilteredColumn,
    resetFilters,
    onSortChanged,
    onDeleteSortedColumn,
    onDeleteSortedAllColumns,
    onChangeSortColumnState,
  }
}
